import { Button, Intent } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import React, { useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { TableStylesDeprecated } from "../../../../alpaca/base/deprecated";
import { AppToaster } from "../../../../helpers/toaster";
import { ComplianceRequirementTable } from "./compliance-requirement-table";
import { EditWorkPaperDialog } from "./edit-work-paper-dialog";
import type {
  IComplianceRequirement,
  IWorkPaperRow,
} from "./work-paper-detail-container";

interface IWorkPaperDetailsProps {
  complianceRequirements: IComplianceRequirement[];
  domainDisplayName: string;
  updateWorkPaper: (workPaperTests: IWorkPaperRow[]) => Promise<void>;
  name: string;
  rows: IWorkPaperRow[];
  createdAt: string;
}

export const WorkPaperDetails: React.FC<IWorkPaperDetailsProps> = ({
  complianceRequirements,
  domainDisplayName,
  updateWorkPaper,
  name,
  rows,
  createdAt,
}) => {
  const memoizedWorkPaperContents = useMemo(
    () => sortWorkPaperContents(complianceRequirements, rows),
    [rows]
  );
  const [workPaperContents, setWorkPaperContents] = useState(
    memoizedWorkPaperContents
  );
  const [selectedTest, setSelectedTest] = useState<Maybe<IWorkPaperRow>>(null);

  const requirementTables = complianceRequirements.map((requirement, i) => (
    <ComplianceRequirementTable
      key={`requirement-${requirement.criterion}-i`}
      requirement={requirement}
      workPaperContents={workPaperContents}
      onRowClick={test => setSelectedTest(test)}
    />
  ));
  const updateWorkPaperContents = async (updatedRow: IWorkPaperRow) => {
    // update all rows with the same testId
    const updatedWorkPaperTests = workPaperContents.map(test => {
      if (test.testId === updatedRow.testId) {
        // replace contents except criterion, controlName
        return {
          ...updatedRow,
          criterion: test.criterion,
          controlName: test.controlName,
        };
      }
      return test;
    });
    setWorkPaperContents(updatedWorkPaperTests);
    await updateWorkPaper(updatedWorkPaperTests);
    AppToaster.show({
      icon: "tick",
      intent: Intent.SUCCESS,
      message: "Successfully saved work paper",
      timeout: 1000,
    });
  };
  const editWorkPaperDialog = isSome(selectedTest) ? (
    <EditWorkPaperDialog
      isOpen={true}
      closeDialog={() => setSelectedTest(null)}
      workPaperRow={selectedTest}
      updateWorkPaperTest={updateWorkPaperContents}
    />
  ) : null;
  return (
    <Container>
      {editWorkPaperDialog}
      <h2> {name} </h2>
      <Subheading>
        Work paper for {domainDisplayName}. Vanta data as of {createdAt}.
      </Subheading>
      <Link to="/#auditor-documents"> &#8592;&nbsp;Back </Link>
      <CSVLink
        headers={[
          { label: "Criterion", key: "criterion" },
          { label: "Control name", key: "controlName" },
          { label: "Control description", key: "controlDescription" },
          { label: "Test applied", key: "testApplied" },
          { label: "Test results (auditor)", key: "testResultsAuditor" },
          { label: "Comments (auditor)", key: "commentsAuditor" },
          { label: "Test status (Vanta)", key: "testStatusVanta" },
          { label: "Comments (Vanta)", key: "commentsVanta" },
        ]}
        data={workPaperContents}
        filename={`${domainDisplayName}-${name}.csv`}
        target="_blank"
      >
        <BlockButton> Export as .csv </BlockButton>
      </CSVLink>
      {requirementTables}
    </Container>
  );
};

const sortWorkPaperContents = (
  complianceRequirements: IComplianceRequirement[],
  workPaperTest: IWorkPaperRow[]
) =>
  complianceRequirements.flatMap(requirement =>
    workPaperTest
      .filter(test => test.criterion === requirement.criterion)
      .sort((testA, testB) =>
        testA.controlName.localeCompare(testB.controlName)
      )
  );

const Container = styled.div`
  font-size: 14px;
  ${TableStylesDeprecated}
  margin-bottom: 80px;
  table {
    width: 100%;
  }
`;

const Subheading = styled.h6`
  margin-bottom: 30px;
`;

const BlockButton = styled(Button)`
  display: block;
  margin: 12px 0;
`;
