import moment from "moment";

import { RiskCSV, RiskReportCSVGenerator } from "./csv-generator";
import type { Domain } from "./risk-report";

export function downloadStringToFile(
  data: string,
  fileName: string,
  type = "text/plain"
) {
  const a = document.createElement("a");
  a.style.display = "none";
  document.body.appendChild(a);

  a.href = window.URL.createObjectURL(new Blob([data], { type }));
  a.setAttribute("download", fileName);
  a.click();

  window.URL.revokeObjectURL(a.href);
  document.body.removeChild(a);
}

export const downloadCSV = (domain: Domain, type: RiskCSV) => {
  const csvContent = new RiskReportCSVGenerator(domain).getCSV(type);
  const dateString = moment().format("mm-dd-yyyy");
  const filePrefix = `${domain.displayName}-${dateString}`;
  downloadStringToFile(
    csvContent,
    FILENAME_FOR_TYPE[type](filePrefix),
    "text/csv"
  );
};

const FILENAME_FOR_TYPE: { [k in RiskCSV]: (prefix: string) => string } = {
  [RiskCSV.Task]: prefix => `${prefix}-tasks.csv`,
  [RiskCSV.Scoring]: prefix => `${prefix}-risk-evaluations.csv`,
};
