import { Classes } from "@blueprintjs/core";
import { dropNothing } from "common/base/types/maybe";
import gql from "graphql-tag";
import React from "react";

import { LogError } from "../../../errors";
import type { FetchUsersQuery } from "../../../gen/components";
import { useFetchUsersQuery } from "../../../gen/components";
import { MaybeProfileImage } from "../../helpers/MaybeProfileImage";
import { FailDataRow } from "../fail-data-row";

const IMAGE_DIMENSION = 32;

export const UserTestEntities: React.FC<{
  entityIds: string[];
  testId: string;
  entityType: "User";
  allowWhitelisting: boolean;
  first: number;
}> = ({ entityType, entityIds, testId, allowWhitelisting, first }) => {
  const { loading, error, data } = useFetchUsersQuery({
    variables: { userIds: entityIds },
  });

  if (loading) {
    return <div />;
  }
  if (error) {
    LogError(error);
    return null;
  }
  if (!data?.organization.usersByIds) {
    return <div />;
  }

  const elements = dropNothing(data.organization.usersByIds)
    .slice(0, first)
    .map(u => (
      <FailDataRow
        key={u.id}
        entityId={u.id}
        entityType={entityType}
        testId={testId}
        allowWhitelisting={allowWhitelisting}
      >
        <User user={u} />
      </FailDataRow>
    ));

  return <>{elements}</>;
};

gql`
  query fetchUsers($userIds: [ID!]!) {
    organization {
      id
      usersByIds(ids: $userIds) {
        id
        displayName
        givenName
        email
        imageUrl
      }
    }
  }
`;

const User: React.FC<{
  user: NonNullable<
    NonNullable<FetchUsersQuery["organization"]["usersByIds"]>[number]
  >;
}> = ({ user }) => (
  <div className="fail-data-user-row">
    <div className="fail-data-user-image">
      <MaybeProfileImage
        url={user.imageUrl}
        circle={true}
        dimension={IMAGE_DIMENSION}
      />
    </div>
    <div className="name-email-display">
      <div>{user.displayName}</div>
      <div className={`${Classes.TEXT_MUTED}`}>
        <small>{user.email}</small>
      </div>
    </div>
  </div>
);
