import { Spinner } from "@blueprintjs/core";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import React from "react";
import styled from "styled-components";

import type { GetUserInfoForOnboardingListQuery } from "../../../../gen/components";
import { useGetUserInfoForOnboardingListQuery } from "../../../../gen/components";
import { OnboardingReminderButton } from "./email-alert-button";
import { OnboardingRoleChecklists } from "./onboarding-role-checklists";
import { SecurityRequirementsList } from "./security-requirements-list";

interface IProps {
  userId: string;
}

export type OnboardingListUser = NonNullable<
  GetUserInfoForOnboardingListQuery["user"]
>;

export type TrainingRequirement =
  OnboardingListUser["trainingRequirements"][number];

export type CompletedTrainingRequirement = TrainingRequirement & {
  completionDate: string;
};

export const UserOnboardingList: React.FC<IProps> = ({ userId }) => {
  const { loading, data } = useGetUserInfoForOnboardingListQuery({
    variables: { userId },
  });
  if (loading || !data) {
    return <Spinner size={Spinner.SIZE_LARGE} />;
  }
  const user = data.user;

  if (!isSome(user)) {
    return <div>No user found.</div>;
  }

  return (
    <SpacedList>
      {user.isActive ? <OnboardingReminderButton user={user} /> : null}
      <SecurityRequirementsList user={user} />
      <OnboardingRoleChecklists user={user} />
    </SpacedList>
  );
};

gql`
  query GetUserInfoForOnboardingList($userId: ID!) {
    user(id: $userId) {
      id
      backgroundChecks {
        id
        status
      }
      displayName
      domain {
        id
        productDescriptionInfo {
          id
          ...SatPreferences
        }
        externalSATIntegrations: credentials(services: ["knowbe4"]) {
          id
        }
      }
      email
      familyName
      givenName
      hasCompletedBackgroundCheck
      hasCompletedSecurityTraining
      hasAcceptedAllSecurityPolicies
      isActive
      isFromScan
      isNotHuman
      mostRecentGdprSecurityTraining {
        ...OnboardingListTrainingCompletion
      }
      mostRecentHipaaSecurityTraining {
        ...OnboardingListTrainingCompletion
      }
      mostRecentSecurityTraining {
        ...OnboardingListTrainingCompletion
      }
      mostRecentPciSecurityTraining {
        ...OnboardingListTrainingCompletion
      }
      endpointState
      inOnboardingSla
      onboardingCompletionDate
      securityRequirements {
        ...SecurityRequirementsMap
      }
      trainingRequirements {
        id
        externalURL
        displayName
        completionDate
        service
        vantaAttributes {
          key
          value
          managedExternally
        }
      }
      role {
        id
        name
      }
      roleCompletionRecord {
        id
        name
        adminCompletionDate
        employeeCompletionDate
        adminTaskCompletions {
          id
          ...completionFields
        }
        employeeTaskCompletions {
          id
          ...completionFields
        }
      }
      hrUser {
        service
      }
      startDate
    }
  }

  fragment OnboardingListTrainingCompletion on securityTrainingCompletion {
    __typename
    category
    completionDate
    ... on securityTrainingDocumentCompletion {
      uploadedDocument {
        id
        url
      }
    }
  }

  fragment completionFields on roleTaskCompletionRecord {
    id
    completionDate
    responseText
    task {
      id
      ...taskFields
    }
    file {
      id
      url
      filename
    }
    approver {
      id
      displayName
    }
  }

  fragment taskFields on roleTask {
    id
    textInputLabel
    fileInputLabel
    instructions
    title
    type
  }
`;

const SpacedList = styled.div`
  & > div {
    margin-bottom: 12px;
  }
`;
