import styled from "styled-components";

import { BASE_PALETTE, VANTA_COLORS } from "../../base/colors";
import { BASE_TYPOGRAPHY, TypographyFactory } from "../../base/typography";

export const H1 = TypographyFactory();
H1.defaultProps = {
  as: "h1",
  fontSize: BASE_TYPOGRAPHY.FONT_SIZES.H1,
  lineHeight: BASE_TYPOGRAPHY.LINE_HEIGHTS.H1,
  fontFamily: BASE_TYPOGRAPHY.FONT_FAMILIES.CHELTENHAM,
  fontWeight: BASE_TYPOGRAPHY.FONT_WEIGHTS.LIGHT,
};

export const H2 = TypographyFactory();
H2.defaultProps = {
  as: "h2",
  fontSize: BASE_TYPOGRAPHY.FONT_SIZES.H2,
  lineHeight: BASE_TYPOGRAPHY.LINE_HEIGHTS.H2,
  fontFamily: BASE_TYPOGRAPHY.FONT_FAMILIES.CHELTENHAM,
  fontWeight: BASE_TYPOGRAPHY.FONT_WEIGHTS.LIGHT,
};

export const H3 = TypographyFactory();
H3.defaultProps = {
  as: "h3",
  fontSize: BASE_TYPOGRAPHY.FONT_SIZES.H3,
  lineHeight: BASE_TYPOGRAPHY.LINE_HEIGHTS.H3,
  fontFamily: BASE_TYPOGRAPHY.FONT_FAMILIES.INTER,
  fontWeight: BASE_TYPOGRAPHY.FONT_WEIGHTS.NORMAL,
};

export const H4 = TypographyFactory();
H4.defaultProps = {
  as: "h4",
  fontSize: BASE_TYPOGRAPHY.FONT_SIZES.H4,
  lineHeight: BASE_TYPOGRAPHY.LINE_HEIGHTS.H4,
  fontFamily: BASE_TYPOGRAPHY.FONT_FAMILIES.INTER,
  fontWeight: BASE_TYPOGRAPHY.FONT_WEIGHTS.BOLD,
};

export const BodyShortText = TypographyFactory();
BodyShortText.defaultProps = {
  as: "p",
  fontSize: BASE_TYPOGRAPHY.FONT_SIZES.BODY_SHORT_TEXT,
  lineHeight: BASE_TYPOGRAPHY.LINE_HEIGHTS.BODY_SHORT_TEXT,
  fontFamily: BASE_TYPOGRAPHY.FONT_FAMILIES.INTER,
  fontWeight: BASE_TYPOGRAPHY.FONT_WEIGHTS.NORMAL,
  letterSpacing: BASE_TYPOGRAPHY.LETTER_SPACINGS.BODY_SHORT_TEXT,
};

export const BodyText = TypographyFactory();
BodyText.defaultProps = {
  as: "p",
  fontSize: BASE_TYPOGRAPHY.FONT_SIZES.BODY_TEXT,
  lineHeight: BASE_TYPOGRAPHY.LINE_HEIGHTS.BODY_TEXT,
  fontFamily: BASE_TYPOGRAPHY.FONT_FAMILIES.INTER,
  fontWeight: BASE_TYPOGRAPHY.FONT_WEIGHTS.NORMAL,
  letterSpacing: BASE_TYPOGRAPHY.LETTER_SPACINGS.BODY_TEXT,
};

export const MetaText = TypographyFactory();
MetaText.defaultProps = {
  as: "span",
  fontSize: BASE_TYPOGRAPHY.FONT_SIZES.META_TEXT,
  lineHeight: BASE_TYPOGRAPHY.LINE_HEIGHTS.META_TEXT,
  fontFamily: BASE_TYPOGRAPHY.FONT_FAMILIES.INTER,
  fontWeight: BASE_TYPOGRAPHY.FONT_WEIGHTS.NORMAL,
  letterSpacing: BASE_TYPOGRAPHY.LETTER_SPACINGS.META_TEXT,
};

export const HelperText = TypographyFactory();
HelperText.defaultProps = {
  as: "span",
  fontSize: BASE_TYPOGRAPHY.FONT_SIZES.HELPER_TEXT,
  lineHeight: BASE_TYPOGRAPHY.LINE_HEIGHTS.HELPER_TEXT,
  fontFamily: BASE_TYPOGRAPHY.FONT_FAMILIES.INTER,
  fontWeight: BASE_TYPOGRAPHY.FONT_WEIGHTS.NORMAL,
  letterSpacing: BASE_TYPOGRAPHY.LETTER_SPACINGS.HELPER_TEXT,
  textTransform: "uppercase",
};

/**
 * - Vanta purple text
 * - pointer cursor on hover
 * - underline only on hover
 */
export const TextLinkLike = styled.span`
  color: ${VANTA_COLORS.VANTA_PURPLE};
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

/**
 * - Black text
 * - pointer cursor on hover
 * - always underlined
 */
export const TextLinkLikeBlack = styled.span`
  color: ${BASE_PALETTE.INK};
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
`;
