import { Tab } from "@blueprintjs/core";
import { HR_SERVICES_SET } from "common/base/types/helpers";
import { dropNothing, isSome } from "common/base/types/maybe";
import {
  PERSONNEL_PAGE_TAB_IDS,
  VIEWING_HR_DIALOG_SEARCH_PARAM,
} from "common/constants/hr";
import gql from "graphql-tag";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

import {
  Button,
  DefaultView,
  HeaderlessTabs,
} from "../../../alpaca/components";
import {
  useAllLinkedCredentialsQuery,
  useFetchDataForPeoplePageQuery,
  useGetRoleNamesQuery,
} from "../../../gen/components";
import { FullPageSpinner } from "../../helpers/FullPageSpinner";
import { GroupsContext } from "../people/groups-context";
import { AddGroupButton } from "./add-group-button";
import { AddPersonButton } from "./add-person-button";
import { AllUsersView } from "./all-users-view";
import { HrServicesContext } from "./hr-services-context";
import { ManageHrUsersDialog } from "./manage-hr-users-dialog";
import { RolesTable } from "./roles-table";
import { SatServicesContext } from "./sat-services-context";
import { UnlinkedHrUsersCallout } from "./unlinked-hr-users-callout";
import { UserDetailDrawer } from "./user-drawer/user-detail-drawer";

export const PeoplePage: React.FC = () => {
  const { loading: roleLoading, data: roleData } = useGetRoleNamesQuery();
  const { loading: credentialsLoading, data: credentialsData } =
    useAllLinkedCredentialsQuery();
  const query = useFetchDataForPeoplePageQuery();
  const searchParams = new URLSearchParams(window.location.search);
  const selectedTab =
    searchParams.get("tab") ?? PERSONNEL_PAGE_TAB_IDS.EVERYBODY;
  const history = useHistory();
  const [hrUsersDialogOpen, setHrUsersDialogOpen] = useState(
    searchParams.get(VIEWING_HR_DIALOG_SEARCH_PARAM) === "1"
  );
  useEffect(
    () =>
      setHrUsersDialogOpen(
        new URLSearchParams(window.location.search).get(
          VIEWING_HR_DIALOG_SEARCH_PARAM
        ) === "1"
      ),
    [window.location.search]
  );
  const openHrUsersDialog = () => {
    const newParams = new URLSearchParams(window.location.search);
    newParams.set(VIEWING_HR_DIALOG_SEARCH_PARAM, "1");
    history.push({ search: newParams.toString() });
  };

  if (
    roleLoading ||
    credentialsLoading ||
    !isSome(roleData?.organization) ||
    !isSome(credentialsData?.organization)
  ) {
    return <FullPageSpinner />;
  }
  const linkedHrServices = new Set(
    credentialsData!.organization.credentials
      .map(credential => credential.service)
      .filter(service => HR_SERVICES_SET.has(service))
  );
  const hasHrService = linkedHrServices.size > 0;

  const linkedSatServices = new Set(
    credentialsData!.organization.credentials
      .map(credential => credential.service)
      .filter(service => service === "knowbe4")
  );

  const manageHrUsersDialog =
    hrUsersDialogOpen && hasHrService ? (
      <ManageHrUsersDialog
        isOpen={hrUsersDialogOpen}
        onClose={() => {
          const params = new URLSearchParams(window.location.search);
          params.delete(VIEWING_HR_DIALOG_SEARCH_PARAM);
          history.push({ search: params.toString() });
        }}
        peopleQuery={query}
      />
    ) : null;

  return (
    <GroupsContext.Provider value={{ groups: roleData!.organization.roles }}>
      <HrServicesContext.Provider value={{ linkedHrServices }}>
        <SatServicesContext.Provider value={{ linkedSatServices }}>
          <DefaultView
            altMinWidth={1456}
            headerProps={{
              title: "People",
              rightControls: dropNothing([
                hasHrService ? (
                  <Button key="merge-hr" onClick={openHrUsersDialog}>
                    Merge HR data
                  </Button>
                ) : null,
                selectedTab === PERSONNEL_PAGE_TAB_IDS.GROUPS ? (
                  <AddGroupButton key="add-group" />
                ) : (
                  <AddPersonButton key="add-person" />
                ),
              ]),
              tabProps: {
                id: "personnel-tabs",
                tabIds: [
                  { id: PERSONNEL_PAGE_TAB_IDS.EVERYBODY, title: "People" },
                  { id: PERSONNEL_PAGE_TAB_IDS.GROUPS, title: "Groups" },
                ],
                selectedTabId: selectedTab,
                onChange: newId => {
                  history.push(
                    `${window.location.pathname}?tab=${String(newId)}`
                  );
                },
              },
            }}
          >
            {isSome(query.data?.organization) &&
            query.data!.organization.numUnlinkedHrUsers > 0 ? (
              <div style={{ marginBottom: "20px" }}>
                <UnlinkedHrUsersCallout
                  numUnlinkedHrUsers={
                    query.data!.organization.numUnlinkedHrUsers
                  }
                  onView={openHrUsersDialog}
                />
              </div>
            ) : null}
            <div>
              <HeaderlessTabs
                id="personnel-tabs-hidden"
                selectedTabId={selectedTab}
                renderActiveTabPanelOnly={true}
              >
                <Tab
                  id={PERSONNEL_PAGE_TAB_IDS.EVERYBODY}
                  title="People"
                  panel={<AllUsersView query={query} />}
                />
                <Tab
                  id={PERSONNEL_PAGE_TAB_IDS.GROUPS}
                  title="Groups"
                  panel={<RolesTable />}
                />
              </HeaderlessTabs>
            </div>
            <UserDetailDrawer />
          </DefaultView>
          {manageHrUsersDialog}
        </SatServicesContext.Provider>
      </HrServicesContext.Provider>
    </GroupsContext.Provider>
  );
};

gql`
  query getRoleNames {
    organization {
      id
      roles {
        id
        name
      }
    }
  }
`;

gql`
  query fetchDataForPeoplePage {
    organization {
      id
      audits(pastAudits: true) {
        id
        auditStart
        auditPeriodDays
      }
      credentials(includeDisabled: true) {
        id
        service
      }
      numUnlinkedHrUsers
      users(includeRemovedUsers: true, includeNonHumanUsers: true) {
        id
        ...peoplePageFields
      }
    }
  }

  fragment peoplePageFields on user {
    id
    backgroundChecks {
      id
      service
      completedAt
    }
    displayName
    email
    endDate
    endpointState
    familyName
    givenName
    hasAcceptedAllSecurityPolicies
    hasCompletedBackgroundCheck
    hasCompletedSecurityTraining
    hasOSQueryEndpoint
    hrUser {
      endDate
      jobTitle
      service
      startDate
      uniqueId
    }
    inOnboardingSla
    isActive
    isContractor
    isFromScan
    isNotHuman
    mostRecentGdprSecurityTraining {
      completionDate
    }
    mostRecentHipaaSecurityTraining {
      completionDate
    }
    mostRecentSecurityTraining {
      completionDate
    }
    mostRecentPciSecurityTraining {
      completionDate
    }
    onboardingCompletionDate
    permissionLevel
    requiresOffboarding
    role {
      id
      name
    }
    roleCompletionRecord {
      id
      adminCompletionDate
      employeeCompletionDate
      hasNoTasks
    }
    securityRequirements {
      ...SecurityRequirementsMap
    }
    startDate
    trainingRequirements {
      id
      externalURL
      displayName
      completionDate
      service
      vantaAttributes {
        key
        value
        managedExternally
      }
    }
  }
`;
