import type { TestEntityTypeClientSafe } from "common/constants/displayNames";
import React from "react";

import { FailDataRow } from "../fail-data-row";
import { FailDataRowAcknowledge } from "../fail-data-row-acknowledge";

export const StringTestEntities: React.FC<{
  entityType: TestEntityTypeClientSafe;
  entityIds: string[];
  testId: string;
  allowWhitelisting: boolean;
  first: number;
}> = ({ entityType, entityIds, testId, allowWhitelisting, first }) => {
  if (testId === "infra-unique-accounts-root") {
    const elements = entityIds.slice(0, first).map(id => (
      <FailDataRowAcknowledge
        acknowledgeAllEnabled={true}
        acknowledgeAllCheckboxLabel={
          "Apply this reason to all other incidents where this occurred."
        }
        key={id}
        entity={{ entityId: id, entityType }}
        testId={testId}
        dialogText={
          <>
            Please provide a reason <code>root</code> was used to log in on this
            date.
          </>
        }
      >
        {id}
      </FailDataRowAcknowledge>
    ));
    return <>{elements}</>;
  } else {
    const elements = entityIds.slice(0, first).map(id => (
      <FailDataRow
        key={id}
        entityId={id}
        entityType={entityType}
        testId={testId}
        allowWhitelisting={allowWhitelisting}
      >
        {id}
      </FailDataRow>
    ));
    return <>{elements}</>;
  }
};
