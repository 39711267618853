import type { DomainDescriptionInfo, Maybe } from "../../../../gen/components";
import { SecurityTrainingCategoryId } from "../../../../gen/components";

export function getTrainingPreferencesMap(
  info?: Maybe<
    Pick<
      DomainDescriptionInfo,
      | "generalSatPreference"
      | "hipaaSatPreference"
      | "pciSatPreference"
      | "gdprSatPreference"
    >
  >
) {
  return {
    [SecurityTrainingCategoryId.general]: info?.generalSatPreference,
    [SecurityTrainingCategoryId.hipaa]: info?.hipaaSatPreference,
    [SecurityTrainingCategoryId.pci]: info?.pciSatPreference,
    [SecurityTrainingCategoryId.gdpr]: info?.gdprSatPreference,
  };
}
