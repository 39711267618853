import "./vaq.scss";

import { Spinner } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import React, { useEffect, useState } from "react";
import type { match } from "react-router";

import type { GetVaqAnswersQuery } from "../../gen/components";
import { useGetVaqAnswersQuery } from "../../gen/components";
import type { IQuestionSchema } from "../forms/interfaces";
import type { VAQState } from "./schemas/vaq-state-helpers";
import {
  getStateOfVAQ,
  getVAQFormsForVendor,
  initialFormForVaq,
  nextFormForVaq,
  vendorHasCompletedForm,
} from "./schemas/vaq-state-helpers";
import { TestVAQBanner } from "./test-vaq-banner";
import { VAQMainBody } from "./vaq-main-body";
import { VAQSidebar } from "./vaq-sidebar";

interface IProps {
  vaq: NonNullable<GetVaqAnswersQuery["vaqAnswers"]>;
  vaqState: VAQState;
  formList: IQuestionSchema[];
  // eslint-disable-next-line
  match?: Maybe<match<{ uuid?: string | undefined }>>;
}

const VAQComponent: React.FC<IProps> = props => {
  const [selectedId, setSelectedId] = useState<Maybe<string>>(null);
  const [currentState, setCurrentState] = useState(props.vaqState);

  useEffect(() => {
    if (!isSome(selectedId) || props.vaqState !== currentState) {
      setSelectedId(initialFormForVaq(props.vaq));
      setCurrentState(props.vaqState);
    } else {
      gotoNextForm();
    }
  }, [props.vaqState, props.vaq.answersJSON]);

  function gotoNextForm() {
    if (!isSome(selectedId)) return;
    setSelectedId(nextFormForVaq(props.vaq, selectedId, props.formList));
  }

  const { vaq, formList } = props;
  const menuList = formList.map(form => {
    return {
      id: form.id,
      title: form.title ?? form.id,
      isCompleted: vendorHasCompletedForm(vaq, form.id),
    };
  });
  return (
    <div className="vaq-container">
      {vaq.dryRun ? <TestVAQBanner /> : null}
      <VAQSidebar
        vaq={props.vaq}
        onSelectItem={id => setSelectedId(id)}
        formList={menuList}
        selectedId={selectedId}
      />
      <VAQMainBody
        selectedFormId={selectedId}
        vaq={props.vaq}
        gotoNextForm={gotoNextForm}
      />
    </div>
  );
};

gql`
  query getVAQAnswers($uuid: String!) {
    vaqAnswers(uuid: $uuid) {
      id
      answersJSON
      domainDisplayName
      dryRun
      files {
        id
        filename
        url
        createdAt
        title
      }
      requesterName
      servicesProvided {
        cloud
        software
        professional
      }
      infrastructureType
      vendorLegalName
      uuid
      url
    }
  }
`;

export const VAQPage: React.FC<{ uuid: string }> = props => {
  const uuid = props.uuid;
  const { loading, error, data } = useGetVaqAnswersQuery({
    variables: { uuid },
  });
  if (loading) {
    return <Spinner />;
  }
  if (error || !isSome(data) || !isSome(data.vaqAnswers)) {
    return <div>ERROR</div>;
  }
  const vaq = data.vaqAnswers;
  const vaqState = getStateOfVAQ(vaq);
  const formList = getVAQFormsForVendor(vaq);
  return <VAQComponent vaq={vaq} vaqState={vaqState} formList={formList} />;
};
