import { AnchorButton, Tooltip } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import moment from "moment";
import React, { useState } from "react";
import styled from "styled-components";

import { BASE_PALETTE } from "../../../../alpaca/base/colors";
import { GRID_SPACING } from "../../../../alpaca/base/grid";
import { BASE_TYPOGRAPHY } from "../../../../alpaca/base/typography";
import { BodyText } from "../../../../alpaca/components";
import { InfoNotAvailable } from "../../access/access-components/styles";
import { COLUMN_CLASSES, DataTable } from "../../components/data-table";
import { ComputerOwnerAssignDialog } from "../../computer-owner-assign-dialog";
import { WorkstationReminderButton } from "../../people/user-drawer/email-alert-button";
import { ApplicationsNumber } from "../applications-number";
import {
  AVProgramEvaluationIcon,
  EncryptedEvaluationIcon,
  PasswordManagerEvaluationIcon,
} from "../evaluation-icons";
import { OSInfo } from "../os-info";
import { RemoveComputerDialog } from "../remove-computer-dialog";
import type { IUserWorkstation } from "../utils";
import { ManagedComputerTypes, monitoredByFromWorkstation } from "../utils";

interface IProps {
  domainId: string;
  workstation: IUserWorkstation;
}

interface IAssignOwnerProps {
  workstation: Maybe<IUserWorkstation>;
  isOpen: boolean;
}

const TableContainer = styled.div`
  border: 1px solid #d6d6d6;
  border-radius: 2px;
  border-top: none;
  margin-top: 12px;
  margin-bottom: 12px;
  table {
    width: 100%;
    tr:nth-child(odd) {
      background-color: #f7f8fa88;
    }
  }
`;

const Text = styled(BodyText)`
  margin-bottom: 0px;
`;

const ButtonBar = styled.div`
  display: flex;
  margin: 12px;
  justify-content: center;
  > * {
    margin-left: 4px;
    margin-right: 4px;
  }
`;

const StyledButton = styled(AnchorButton)`
  height: 36px;
  padding-left: ${2 * GRID_SPACING}px;
  padding-right: ${2 * GRID_SPACING}px;
  border-radius: 4px;

  & > .bp3-button-text {
    font-size: 12px;
    line-height: 12px;
    color: ${BASE_PALETTE.INK};
    font-weight: ${BASE_TYPOGRAPHY.FONT_WEIGHTS.SLIGHTLY_BOLD};

    * svg {
      fill: ${BASE_PALETTE.SMOKE};
    }
  }

  &&&.bp3-intent-primary {
    .bp3-button-text {
      color: ${BASE_PALETTE.SNOW};
    }

    &.bp3-disabled {
      .bp3-button-text {
        color: ${BASE_PALETTE.SNOW}66;
      }
    }
  }

  &&&.bp3-disabled {
    border: 1px solid ${BASE_PALETTE.FOG}B0;
    & > .bp3-button-text {
      color: ${BASE_PALETTE.INK}66;
    }
  }
`;

export const ComputerDetails: React.FC<IProps> = ({
  workstation,
  domainId,
}) => {
  const [assignOwnerProps, setAssignOwnerProps] = useState<IAssignOwnerProps>({
    workstation: null,
    isOpen: false,
  });
  const [showRemoveLaptopCancelConfirm, setShowRemoveLaptopCancelConfirm] =
    useState(false);
  const rows = [
    [
      "Computer",
      <Text key={"computer"}>{workstation.name ?? <InfoNotAvailable />}</Text>,
    ],
    [
      "Serial Number",
      <Text key={"serial"}>
        {workstation.serialNumber ?? <InfoNotAvailable />}
      </Text>,
    ],
    [
      "UDID",
      <Text key={"udid"}>{workstation.udid ?? <InfoNotAvailable />}</Text>,
    ],
    [
      "OS Version",
      <OSInfo key={"os"} workstation={workstation} iconOnRight={true} />,
    ],
    [
      "Last Check",
      <Text key={"lastCheck"}>
        {isSome(workstation.lastData)
          ? moment(workstation.lastData).fromNow()
          : ""}
      </Text>,
    ],
    [
      "PW Manager",
      <PasswordManagerEvaluationIcon
        workstation={workstation}
        key={workstation.id}
      />,
    ],
    [
      "HD Encrypted",
      <EncryptedEvaluationIcon key={"hdEncrypted"} workstation={workstation} />,
    ],
    [
      "AV Installed",
      <AVProgramEvaluationIcon key={"avProgram"} workstation={workstation} />,
    ],
    [
      "Monitored By",
      <Text key={"monitoredBy"}>
        {monitoredByFromWorkstation(workstation)}
      </Text>,
    ],
    [
      "Applications",
      <ApplicationsNumber key={"applications"} workstation={workstation} />,
    ],
  ];

  const monitorService = monitoredByFromWorkstation(workstation);
  const shouldDisableActions = ManagedComputerTypes.includes(
    workstation.__typename
  );
  const disabledText = `To edit this computer, update it in ${monitorService}.`;

  return (
    <>
      <TableContainer>
        <DataTable
          createRow={row => {
            return {
              header: (
                <Text fontWeight={BASE_TYPOGRAPHY.FONT_WEIGHTS.BOLD}>
                  {row[0]}
                </Text>
              ),
              data: row[1],
            };
          }}
          data={rows}
          columnClasses={{
            header: COLUMN_CLASSES.LEFT_ALIGN,
            data: COLUMN_CLASSES.RIGHT_ALIGN,
          }}
          columnOrder={["header", "data"]}
        />
        <ButtonBar>
          <WorkstationReminderButton
            workstation={workstation}
            user={workstation.user}
          />
          <Tooltip disabled={!shouldDisableActions} content={disabledText}>
            <StyledButton
              onClick={async () => {
                setAssignOwnerProps({
                  workstation,
                  isOpen: true,
                });
              }}
              disabled={shouldDisableActions || !workstation.isReassignable}
            >
              Reassign
            </StyledButton>
          </Tooltip>
          <Tooltip disabled={!shouldDisableActions} content={disabledText}>
            <StyledButton
              onClick={async () => setShowRemoveLaptopCancelConfirm(true)}
              disabled={shouldDisableActions}
            >
              Remove
            </StyledButton>
          </Tooltip>
        </ButtonBar>
      </TableContainer>

      <RemoveComputerDialog
        workstation={workstation}
        domainId={domainId}
        isOpen={showRemoveLaptopCancelConfirm}
        onClose={() => {
          setShowRemoveLaptopCancelConfirm(false);
        }}
      />

      <ComputerOwnerAssignDialog
        workstation={assignOwnerProps.workstation}
        isOpen={assignOwnerProps.isOpen}
        onClose={() => {
          setAssignOwnerProps({ isOpen: false, workstation: null });
        }}
      />
    </>
  );
};
