{
  "standardInfo": {
    "standard": "gdpr"
  },
  "controls": [
    {
      "id": "access-correction-erasure",
      "name": "Access, correction and/or erasure",
      "description": "Defined process and procedure for data subjects to access and correct  their PII.\n\nGuidance: This is typically the data subject access request process.",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "privacy-policy"
        }
      ],
      "role": "Controller"
    },
    {
      "id": "access-production-network-restricted",
      "name": "Production network access restricted",
      "description": "The company restricts privileged access to the production network to authorized users with a business need.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "access-granted-infra"
        },
        {
          "id": "infra-access-requires-approval-config"
        }
      ],
      "manualEvidenceRequests": [],
      "role": "Controller"
    },
    {
      "id": "access-reviews",
      "name": "Access reviews conducted",
      "description": "The company conducts quarterly access reviews for the in-scope system components to help ensure that access is restricted appropriately. Required changes are tracked to completion.",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "access-reviews"
        }
      ],
      "role": "Controller"
    },
    {
      "id": "access-revoked-termination",
      "name": "Access revoked upon termination",
      "description": "The company completes termination checklists to ensure that access is revoked for terminated employees within SLAs.",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "employee-termination-checklist"
        },
        {
          "id": "policy-exists-termination"
        }
      ],
      "role": "Controller"
    },
    {
      "id": "access-role-based",
      "name": "Access requests required",
      "description": "The company ensures that user access to in-scope system components is based on job role and function or requires a documented access request form and manager approval prior to access being provisioned.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "access-granted-infra"
        },
        {
          "id": "access-granted-versioncontrol"
        },
        {
          "id": "approved-access-control-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [],
      "role": "Controller"
    },
    {
      "id": "access-ssh-required",
      "name": "Unique network system authentication enforced",
      "description": "The company requires authentication to the \"production network\" to use unique usernames and passwords or authorized Secure Socket Shell (SSH) keys.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "require-admin-encrypted-iaas"
        },
        {
          "id": "require-admin-encrypted-iaas-gcp"
        },
        {
          "id": "require-admin-encrypted-iaas-heroku"
        },
        {
          "id": "require-ssh-config"
        }
      ],
      "manualEvidenceRequests": [],
      "role": "Controller"
    },
    {
      "id": "accuracy-and-quality",
      "name": "Accuracy and quality",
      "description": "The company has a process to ensure that PII is complete, accurate, and up-to-date",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [],
      "role": "Controller"
    },
    {
      "id": "asset-disposal-procedures-utilized",
      "name": "Asset disposal procedures utilized",
      "description": "The company has electronic media containing confidential information purged or destroyed in accordance with best practices, and certificates of destruction are issued for each device destroyed.",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "media-disposal"
        }
      ],
      "role": "Controller"
    },
    {
      "id": "assets-anti-malware",
      "name": "Anti-malware technology utilized",
      "description": "The company deploys anti-malware technology to environments commonly susceptible to malicious attacks and configures this to be updated routinely, logged, and installed on all relevant systems.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "employees-without-laptops"
        },
        {
          "id": "malware-detection-workstations"
        },
        {
          "id": "malware-detection-workstations-jamf"
        },
        {
          "id": "malware-detection-workstations-kandji"
        },
        {
          "id": "malware-detection-workstations-microsoft-endpoint-manager"
        },
        {
          "id": "security-vulnerabilities-monitored-laptops"
        }
      ],
      "manualEvidenceRequests": [],
      "role": "Controller"
    },
    {
      "id": "assets-portable-media-encrypted",
      "name": "Portable media encrypted",
      "description": "The company encrypts portable and removable media devices when used.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-cryptography-policy-bsi-exists"
        },
        {
          "id": "cryptography-policies-policies"
        },
        {
          "id": "full-disk-encryption-records"
        },
        {
          "id": "full-disk-encryption-records-jamf"
        },
        {
          "id": "full-disk-encryption-records-kandji"
        },
        {
          "id": "full-disk-encryption-records-microsoft-endpoint-manager"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "removable-media-encryption"
        }
      ],
      "role": "Controller"
    },
    {
      "id": "assist-controller-with-privacy-obligations",
      "name": "Assist controllers with privacy obligations",
      "description": "Processor agreements with your customers (controllers) commit to assisting them with privacy obligations.",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "customer-data-processing-agreement-exists"
        }
      ],
      "role": "Processor"
    },
    {
      "id": "automated-decision-making",
      "name": "Automated decision making",
      "description": "Identify and address obligations to data subjects resulting from decisions made from automated processing (if applicable)",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "data-protection-impact-assessment-exists"
        }
      ],
      "role": "Controller"
    },
    {
      "id": "basis-for-pii-transfer-between-jurisdictions",
      "name": "Basis for PII transfer between jurisdictions",
      "description": "Master Services Agreement informs customer of the legal basis for transfers between jurisdictions and allow customers to object to changes or terminate service.",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "customer-data-processing-agreement-exists"
        }
      ],
      "role": "Processor"
    },
    {
      "id": "breach-policy-and-procedure",
      "name": "Breach policy and procedure",
      "description": "Establish policies and procedures to respond to data breaches including notification procedures",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-incident-response-plan-bsi-exists"
        },
        {
          "id": "employees-accepted-incident-response-plan"
        },
        {
          "id": "employees-accepted-incident-response-plan-bsi"
        },
        {
          "id": "incident-response-follow-ups-policies"
        },
        {
          "id": "incident-response-lessons-learned-policies"
        },
        {
          "id": "incident-response-plan-policies"
        },
        {
          "id": "incident-response-team-policies"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "incident-report-example"
        },
        {
          "id": "incident-response-plan-test"
        }
      ],
      "role": "Controller"
    },
    {
      "id": "change-of-subcontractor-to-process-pii",
      "name": "Change of sub-processor to process PII",
      "description": "Changes to sub-processors are communicated to the customer in writing with the opportunity to object.",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "customer-data-processing-agreement-exists"
        },
        {
          "id": "msa-template"
        },
        {
          "id": "sub-processor-change-communication-exists"
        }
      ],
      "role": "Processor"
    },
    {
      "id": "changes-approval-required",
      "name": "Production deployment access restricted",
      "description": "The company restricts access to migrate changes to production to authorized personnel.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-operations-security-policy-bsi-exists"
        },
        {
          "id": "approved-secure-development-policy-bsi-exists"
        },
        {
          "id": "code-review-application-config"
        },
        {
          "id": "version-control-tool-records"
        }
      ],
      "manualEvidenceRequests": [],
      "role": "Controller"
    },
    {
      "id": "consent-obtained",
      "name": "Consent obtained",
      "description": "Determine and document when and how consent was obtained.",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "data-inventory-map-exists"
        },
        {
          "id": "privacy-policy"
        }
      ],
      "role": "Controller"
    },
    {
      "id": "continuity-and-disaster-recovery-plans-established",
      "name": "Continuity and Disaster Recovery plans established",
      "description": "The company has Business Continuity and Disaster Recovery Plans in place that outline communication plans in order to maintain information security continuity in the event of the unavailability of key personnel.",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "disaster-recovery-tabletop"
        }
      ],
      "role": "Controller"
    },
    {
      "id": "continuity-disaster-recovery-plans-tested",
      "name": "Continuity and disaster recovery plans tested annually",
      "description": "The company has a documented business continuity/disaster recovery (BC/DR) plan and tests it annually.",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "disaster-recovery-tabletop"
        }
      ],
      "role": "Controller"
    },
    {
      "id": "contracts-with-pii-processors",
      "name": "Contracts with PII processors",
      "description": "Implement a written contract with all PII processors which includes their requirements.",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [],
      "role": "Controller"
    },
    {
      "id": "countries-and-international-organizations-to-which-pii-can-be-transferred",
      "name": "Countries and international organizations to which PII can be transferred",
      "description": "The company specifies and documents the countries and international organizations where PII is transferred",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "data-inventory-map-exists"
        },
        {
          "id": "privacy-policy"
        }
      ],
      "role": "Controller"
    },
    {
      "id": "countries-international-organizations-pii-transferred",
      "name": "Countries and international organizations to which PII can be transferred",
      "description": "All countries where PII is stored are documented.",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "data-inventory-map-exists"
        }
      ],
      "role": "Processor"
    },
    {
      "id": "customer-data-deleted upon-leave",
      "name": "Customer data deleted upon leave",
      "description": "The company purges or removes customer data containing confidential information from the application environment, in accordance with best practices, when customers leave the service.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "activity-log-blob-containers-have-soft-delete-config-azure"
        },
        {
          "id": "approved-data-management-policy-bsi-exists"
        },
        {
          "id": "logging-buckets-have-MFA-delete-config"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "customer-data-deletion-record"
        }
      ],
      "role": "Controller"
    },
    {
      "id": "customer-obligations",
      "name": "Customer obligations",
      "description": "The company provides their customer with information sufficient for them to demonstrate their privacy compliance. (SCC 8.9(b))",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "customer-data-processing-agreement-exists"
        }
      ],
      "role": "Processor"
    },
    {
      "id": "data-classification-policy",
      "name": "Data classification policy established",
      "description": "The company has a data classification policy in place to help ensure that confidential data is properly secured and restricted to authorized personnel.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-data-management-policy-bsi-exists"
        },
        {
          "id": "data-classification-policy-policies"
        },
        {
          "id": "employees-accepted-data-classification-policy"
        },
        {
          "id": "employees-accepted-data-management-policy-bsi"
        }
      ],
      "manualEvidenceRequests": [],
      "role": "Controller"
    },
    {
      "id": "data-encrypted",
      "name": "Data encryption utilized",
      "description": "The company's datastores housing sensitive customer data are encrypted at rest.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "aws-dynamodb-encryption"
        },
        {
          "id": "azure-cosmosdb-encryption"
        },
        {
          "id": "customer-data-encrypted-at-rest-config"
        },
        {
          "id": "customer-data-encrypted-at-rest-config-azure"
        },
        {
          "id": "customer-data-encrypted-at-rest-config-gcp"
        },
        {
          "id": "customer-data-encrypted-at-rest-config-heroku"
        },
        {
          "id": "digitalocean-app-relational-database-encryption"
        },
        {
          "id": "digitalocean-redis-cluster-encryption"
        },
        {
          "id": "digitalocean-relational-database-encryption"
        },
        {
          "id": "gcp-bigtable-encryption"
        },
        {
          "id": "gcp-datastore-encryption"
        },
        {
          "id": "gcp-firestore-encryption"
        },
        {
          "id": "mongo-atlas-cluster-encryption"
        },
        {
          "id": "storage-buckets-are-encrypted-config"
        },
        {
          "id": "storage-buckets-are-encrypted-config-digitaloceanspaces"
        },
        {
          "id": "storage-buckets-are-encrypted-config-gcp"
        }
      ],
      "manualEvidenceRequests": [],
      "role": "Controller"
    },
    {
      "id": "data-encrypted-in-transit",
      "name": "Data transmission encrypted",
      "description": "The company uses secure data transmission protocols to encrypt confidential and sensitive data when transmitted over public networks.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-cryptography-policy-bsi-exists"
        },
        {
          "id": "cryptography-policies-policies"
        },
        {
          "id": "load-balancer-http-to-https"
        },
        {
          "id": "load-balancer-http-to-https-azure"
        },
        {
          "id": "load-balancer-http-to-https-digitalocean"
        },
        {
          "id": "require-admin-encrypted-iaas"
        },
        {
          "id": "require-admin-encrypted-iaas-gcp"
        },
        {
          "id": "require-admin-encrypted-iaas-heroku"
        },
        {
          "id": "ssl-good-ciphers"
        },
        {
          "id": "ssl-no-warnings"
        },
        {
          "id": "ssl-used-expiry"
        },
        {
          "id": "ssl-used-unittest"
        }
      ],
      "manualEvidenceRequests": [],
      "role": "Controller"
    },
    {
      "id": "data-inventory",
      "name": "Data inventory",
      "description": "Create a PII data inventory\n\nFor controllers:\n\\- the name and contact details of the controller\n        \\- the purpose behind the processing of data\n        \\- a description of the categories of data that will be processed\n        \\- who will receive the data including data\n        \\- documentation of suitable safeguards for data transfers to a third country or an international organization\n        \\- the retention period of the different categories of data\n        \\- a general description of the technical and organizational security measures\n\nFor processors:\n\\- the name and contact details of the processor or processors and of each controller on behalf of which the processor is acting, and, where applicable, of the controller’s or the processor’s representative, and the data protection officer\n        \\- the categories of processing carried out on behalf of each controller\n        \\- documentation of suitable safeguards for data transfers to a third country or an international organization\n        \\- a general description of the technical and organizational security measures",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "data-inventory-map-exists"
        }
      ],
      "role": "Controller"
    },
    {
      "id": "determine-needs-and-perform-transfer-impact-assessment",
      "name": "Determine needs and perform transfer impact assessment",
      "description": "If processing includes: \n- systematic and extensive evaluation of personal aspects relating to natural persons which is based on automated processing, including profiling, and on which decisions are based that produce legal effects concerning the natural person or similarly significantly affect the natural person;\n- processing on a large scale of special categories of data referred to in Article 9(1), or of personal data relating to criminal convictions and offences referred to in Article 10; or\n- systematic monitoring of a publicly accessible area on a large scale.",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "data-protection-impact-assessment-exists"
        }
      ],
      "role": "Controller"
    },
    {
      "id": "determining-information-for-pii-data-subjects",
      "name": "Determining information for PII data subjects",
      "description": "Determine and document requirements for notice to data subjects and timing of the notice.",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "data-inventory-map-exists"
        }
      ],
      "role": "Controller"
    },
    {
      "id": "disclosure-of-sub-contractors-used-to-process-pii",
      "name": "Disclosure of subcontractors used to process PII",
      "description": "All PII sub processors are disclosed to the customer.",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "customer-data-processing-agreement-exists"
        }
      ],
      "role": "Processor"
    },
    {
      "id": "disposal",
      "name": "Disposal of PII",
      "description": "The company documents policies, procedures and mechanism for disposal of PII",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-data-management-policy-bsi-exists"
        },
        {
          "id": "employees-accepted-data-management-policy-bsi"
        }
      ],
      "manualEvidenceRequests": [],
      "role": "Controller"
    },
    {
      "id": "dpo",
      "name": "Appoint Data Protection Officer",
      "description": "If processing meets one of these conditions then appoint a Data Protection Officer\n1. you are a public authority or body, \n2. the core activities of the controller or the processor consist of processing operations which, by virtue of their nature, their scope and/or their purposes, require regular and systematic monitoring of data subjects on a large scale; or\n3. the core activities of the controller or the processor consist of processing on a large scale of special categories of data, or personal data relating to criminal convictions and offences\n-",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "data-inventory-map-exists"
        },
        {
          "id": "privacy-policy"
        }
      ],
      "role": "Controller"
    },
    {
      "id": "eu-representatitve",
      "name": "Appoint EU representative",
      "description": "The company shall appoint an EU based representative",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "eu-representative-appointed"
        }
      ],
      "role": "Controller"
    },
    {
      "id": "gdpr-training-completed",
      "name": "GDPR training is implemented",
      "description": "The company requires employees to complete GDPR awareness training within thirty days of hire and annually thereafter.",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [],
      "role": "Both"
    },
    {
      "id": "handling-requests",
      "name": "Handling DSAR requests",
      "description": "Define and document procedures for handling Data Subject Access Requests (DSAR)",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [],
      "role": "Controller"
    },
    {
      "id": "identify-and-document-purpose",
      "name": "Identity and document purpose",
      "description": "Document the purpose for which PII is processed.",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "data-inventory-map-exists"
        },
        {
          "id": "privacy-policy"
        }
      ],
      "role": "Controller"
    },
    {
      "id": "identify-basis-for-pii-transfer-between-jurisdictions",
      "name": "Identify basis for PII transfer between jurisdictions",
      "description": "The company identifies and documents its legal basis for transferring between jurisdictions",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "data-inventory-map-exists"
        },
        {
          "id": "privacy-policy"
        }
      ],
      "role": "Controller"
    },
    {
      "id": "identify-lawful-basis",
      "name": "Identify lawful basis",
      "description": "Document the lawful basis for PII processing.",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "data-inventory-map-exists"
        },
        {
          "id": "privacy-policy"
        }
      ],
      "role": "Controller"
    },
    {
      "id": "incident-response-policies-established",
      "name": "Incident response policies established",
      "description": "The company has security and privacy incident response policies and procedures that are documented and communicated to authorized users.",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "incident-report-example"
        },
        {
          "id": "incident-response-plan-test"
        }
      ],
      "role": "Controller"
    },
    {
      "id": "infra-network-segregation",
      "name": "Network segmentation implemented",
      "description": "The company's network is segmented to prevent unauthorized access to customer data.",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "network-segregation"
        }
      ],
      "role": "Controller"
    },
    {
      "id": "infringing-instruction",
      "name": "Infringing instruction",
      "description": "The company informs the customer if processing instructions are illegal. (SCC 8.1(b))",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [],
      "role": "Processor"
    },
    {
      "id": "intrusion-detection-system",
      "name": "Intrusion detection system utilized",
      "description": "The company uses an intrusion detection system to provide continuous monitoring of the company's network and early detection of potential security breaches.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "activity-log-blob-container-has-logging-azure"
        },
        {
          "id": "employees-without-laptops"
        },
        {
          "id": "infra-activity-tracked-config"
        },
        {
          "id": "infra-activity-tracked-config-azure"
        },
        {
          "id": "infra-activity-tracked-config-heroku"
        },
        {
          "id": "security-vulnerabilities-monitored-laptops"
        }
      ],
      "manualEvidenceRequests": [],
      "role": "Controller"
    },
    {
      "id": "lead-supervisory-authority",
      "name": "Appoint EU lead supervisory authority",
      "description": "If operating in more than one EU state identify a lead Data Protection Authority",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "lead-supervisory-authority-appointed-exists"
        }
      ],
      "role": "Controller"
    },
    {
      "id": "legally-binding-pii-disclosures",
      "name": "Legally binding PII disclosures",
      "description": "Non binding PII disclosure requests are rejected. (SCC 15.2)",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "customer-data-processing-agreement-exists"
        }
      ],
      "role": "Processor"
    },
    {
      "id": "limit-collection",
      "name": "Limit collection",
      "description": "The company limits collection of PII to the minimum that is necessary for it's purposes",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [],
      "role": "Controller"
    },
    {
      "id": "marketing-and-advertising-use",
      "name": "Marketing and advertising use",
      "description": "PII collected for the services is not used for marketing and advertising without consent\n\nConsent for marketing is not a required for using services.",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "customer-data-processing-agreement-exists"
        }
      ],
      "role": "Processor"
    },
    {
      "id": "MDM-system-utilized",
      "name": "MDM system utilized",
      "description": "The company has a mobile device management (MDM) system in place to centrally manage mobile devices supporting the service.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "malware-detection-workstations"
        },
        {
          "id": "malware-detection-workstations-jamf"
        },
        {
          "id": "malware-detection-workstations-kandji"
        },
        {
          "id": "malware-detection-workstations-microsoft-endpoint-manager"
        },
        {
          "id": "password-manager-records-jamf"
        },
        {
          "id": "password-manager-records-kandji"
        },
        {
          "id": "password-manager-records-microsoft-endpoint-manager"
        }
      ],
      "manualEvidenceRequests": [],
      "role": "Controller"
    },
    {
      "id": "network-firewalls-reviewed",
      "name": "Network firewalls reviewed",
      "description": "The company reviews its firewall rulesets at least annually. Required changes are tracked to completion.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "digitalocean-droplets-firewalls-restrict-port-22"
        },
        {
          "id": "digitalocean-droplets-firewalls-used"
        },
        {
          "id": "firewall-config-acl"
        },
        {
          "id": "firewall-config-acl-gcp"
        },
        {
          "id": "firewall-config-acl-heroku"
        },
        {
          "id": "firewall-config-default-deny"
        },
        {
          "id": "firewall-config-default-deny-gcp"
        },
        {
          "id": "firewall-config-default-deny-heroku"
        },
        {
          "id": "firewall-config-server"
        },
        {
          "id": "firewall-config-server-heroku"
        },
        {
          "id": "firewall-endpoint-policies"
        },
        {
          "id": "ssh-denied-azure"
        }
      ],
      "manualEvidenceRequests": [],
      "role": "Controller"
    },
    {
      "id": "network-firewalls-utilized",
      "name": "Network firewalls utilized",
      "description": "The company uses firewalls and configures them to prevent unauthorized access.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "digitalocean-droplets-firewalls-restrict-port-22"
        },
        {
          "id": "digitalocean-droplets-firewalls-used"
        },
        {
          "id": "firewall-config-acl"
        },
        {
          "id": "firewall-config-acl-gcp"
        },
        {
          "id": "firewall-config-acl-heroku"
        },
        {
          "id": "firewall-config-default-deny"
        },
        {
          "id": "firewall-config-default-deny-gcp"
        },
        {
          "id": "firewall-config-default-deny-heroku"
        },
        {
          "id": "firewall-config-server"
        },
        {
          "id": "firewall-config-server-heroku"
        },
        {
          "id": "firewall-endpoint-policies"
        },
        {
          "id": "ssh-denied-azure"
        }
      ],
      "manualEvidenceRequests": [],
      "role": "Controller"
    },
    {
      "id": "notifications-of-pii-disclosure-requests",
      "name": "Notification of PII disclosure requests",
      "description": "Legally binding disclosures for PII are communicated to the customer prior to disclosure where possible. (SCC 15.1-2)",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "customer-data-processing-agreement-exists"
        }
      ],
      "role": "Processor"
    },
    {
      "id": "PII-controllers-obligations-to-inform-third-parties",
      "name": "PII controllers' obligations to inform third parties",
      "description": "Establish a process, policies and procedures for notifying sub processors of corrections, deletions or withdrawals of PII",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [],
      "role": "Controller"
    },
    {
      "id": "pii-deidentification-deletion-at-the-end-of-processing",
      "name": "PII de-identification and deletion at the end of processing",
      "description": "The company deletes or de-identifies when no longer needed",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-data-management-policy-bsi-exists"
        },
        {
          "id": "employees-accepted-data-management-policy-bsi"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "customer-data-deletion-record"
        }
      ],
      "role": "Controller"
    },
    {
      "id": "pii-minimization-objectives",
      "name": "PII minimization",
      "description": "The company ensures that it only collects and processes data which it needs for its purposes.",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "data-inventory-map-exists"
        }
      ],
      "role": "Controller"
    },
    {
      "id": "pii-transmission-controlls",
      "name": "PII transmission controls",
      "description": "The company implements technical controls to ensure data transmitted to third parties reaches its destination.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-cryptography-policy-bsi-exists"
        },
        {
          "id": "cryptography-policies-policies"
        },
        {
          "id": "load-balancer-http-to-https"
        },
        {
          "id": "load-balancer-http-to-https-azure"
        },
        {
          "id": "load-balancer-http-to-https-digitalocean"
        },
        {
          "id": "require-admin-encrypted-iaas"
        },
        {
          "id": "require-admin-encrypted-iaas-gcp"
        },
        {
          "id": "require-admin-encrypted-iaas-heroku"
        },
        {
          "id": "ssl-good-ciphers"
        },
        {
          "id": "ssl-no-warnings"
        },
        {
          "id": "ssl-used-expiry"
        },
        {
          "id": "ssl-used-unittest"
        }
      ],
      "manualEvidenceRequests": [],
      "role": "Controller"
    },
    {
      "id": "pii-transmission-controls",
      "name": "PII transmission controls",
      "description": "PII is encrypted in transit",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-cryptography-policy-bsi-exists"
        },
        {
          "id": "cryptography-policies-policies"
        },
        {
          "id": "load-balancer-http-to-https"
        },
        {
          "id": "load-balancer-http-to-https-azure"
        },
        {
          "id": "load-balancer-http-to-https-digitalocean"
        },
        {
          "id": "require-admin-encrypted-iaas"
        },
        {
          "id": "require-admin-encrypted-iaas-gcp"
        },
        {
          "id": "require-admin-encrypted-iaas-heroku"
        },
        {
          "id": "ssl-good-ciphers"
        },
        {
          "id": "ssl-no-warnings"
        },
        {
          "id": "ssl-used-expiry"
        },
        {
          "id": "ssl-used-unittest"
        }
      ],
      "manualEvidenceRequests": [],
      "role": "Processor"
    },
    {
      "id": "policies-incident-management",
      "name": "Incident management procedures followed",
      "description": "The company's security and privacy incidents are logged, tracked, resolved, and communicated to affected or relevant parties by management according to the company's security incident response policy and procedures.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-incident-response-plan-bsi-exists"
        },
        {
          "id": "approved-operations-security-policy-bsi-exists"
        },
        {
          "id": "employees-accepted-incident-response-plan"
        },
        {
          "id": "employees-accepted-incident-response-plan-bsi"
        },
        {
          "id": "employees-accepted-operations-security-policy-bsi"
        },
        {
          "id": "incident-response-follow-ups-policies"
        },
        {
          "id": "incident-response-lessons-learned-policies"
        },
        {
          "id": "incident-response-plan-policies"
        },
        {
          "id": "sla-for-security-bugs-policies"
        },
        {
          "id": "sla-for-security-bugs-records"
        }
      ],
      "manualEvidenceRequests": [],
      "role": "Controller"
    },
    {
      "id": "policies-password-complexity",
      "name": "Password policy enforced",
      "description": "The company requires passwords for in-scope system components to be configured according to the company's policy.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "internal-password-policy-config"
        },
        {
          "id": "internal-password-policy-config-heroku"
        },
        {
          "id": "password-manager-records"
        },
        {
          "id": "password-manager-records-jamf"
        },
        {
          "id": "password-manager-records-kandji"
        },
        {
          "id": "password-manager-records-microsoft-endpoint-manager"
        }
      ],
      "manualEvidenceRequests": [],
      "role": "Controller"
    },
    {
      "id": "privacy-impact-assessment",
      "name": "Privacy impact assessment",
      "description": "Perform a privacy impact assessment for processing or changes to processing which represent a high risk to the rights and freedoms of data subjects.",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "data-protection-impact-assessment-exists"
        }
      ],
      "role": "Controller"
    },
    {
      "id": "process-as-per-processor-agreements",
      "name": "Process as per processor agreements",
      "description": "PII is only processed for the purposes expressed in contract (SCCs 8.1 and 8.2).",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "customer-data-processing-agreement-exists"
        },
        {
          "id": "data-inventory-map-exists"
        }
      ],
      "role": "Processor"
    },
    {
      "id": "production-inventory-maintained",
      "name": "Production inventory maintained",
      "description": "The company maintains a formal inventory of production system assets.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "inventory-list-descriptions"
        },
        {
          "id": "inventory-list-owners"
        },
        {
          "id": "inventory-list-user-data"
        }
      ],
      "manualEvidenceRequests": [],
      "role": "Controller"
    },
    {
      "id": "providing-copy-of-pii-processed",
      "name": "Providing copy of PII processed",
      "description": "Establish a process of providing a copy of PII to data subjects upon verified request",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [],
      "role": "Controller"
    },
    {
      "id": "providing-information-to-pii-data-subjects",
      "name": "Provide information to PII data subjects",
      "description": "Provide data subjects clear and easily accessible information identifying the controller and describing the PII processing.",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "privacy-policy"
        }
      ],
      "role": "Controller"
    },
    {
      "id": "providing-mechanism-to-modify-or-withdraw-consent",
      "name": "Providing mechanism to modify or withdraw consent",
      "description": "Provide mechanism to modify or withdraw consent.\n\nGuidance: This is typically the data subject access request process.",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "cookie-consent-manager-exists"
        },
        {
          "id": "privacy-policy"
        }
      ],
      "role": "Controller"
    },
    {
      "id": "providing-mechanism-to-object-to-pii-processing",
      "name": "Providing mechanism to object to PII processing",
      "description": "Provide mechanism for data subjects to object to processing.\n\nGuidance: This is typically the data subject access request process.",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "privacy-policy"
        }
      ],
      "role": "Controller"
    },
    {
      "id": "pseudonymization",
      "name": "Pseudonymization",
      "description": "Determine any need for pseudonymization and implement it as needed",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "data-protection-impact-assessment-exists"
        },
        {
          "id": "pseudonymization-procedure-implemented"
        }
      ],
      "role": "Controller"
    },
    {
      "id": "records-of-pii-disclosure-to-third-parties",
      "name": "Records of PII disclosure to third parties",
      "description": "The company should record disclosure of PII to third parties including what has been disclosed and what time",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "data-inventory-map-exists"
        }
      ],
      "role": "Controller"
    },
    {
      "id": "records-of-transfer-of-pii",
      "name": "Records of transfer of PII",
      "description": "All transfers of PII to third parties are documented.",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "data-inventory-map-exists"
        }
      ],
      "role": "Processor"
    },
    {
      "id": "records-of-transfer-of-pii",
      "name": "Records of transfer of PII",
      "description": "The company documents transfers of PII to or from third parties and ensures cooperation with the requests from data subjects",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "customer-data-processing-agreement-exists"
        },
        {
          "id": "data-inventory-map-exists"
        }
      ],
      "role": "Controller"
    },
    {
      "id": "records-related-to-processing-pii",
      "name": "Records related to processing PII",
      "description": "The company maintains necessary privacy records.",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "data-inventory-map-exists"
        }
      ],
      "role": "Processor"
    },
    {
      "id": "remote-access-mfa-enforced",
      "name": "Remote access MFA enforced",
      "description": "The company's production systems can only be remotely accessed by authorized employees possessing a valid multi-factor authentication (MFA) method.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "bitbucket-account-mfa-enabled"
        },
        {
          "id": "github-account-mfa-enabled"
        },
        {
          "id": "heroku-account-mfa-enabled"
        },
        {
          "id": "mfa-on-accounts-gsuite"
        },
        {
          "id": "mfa-on-accounts-infra"
        },
        {
          "id": "mfa-on-accounts-infra-root"
        },
        {
          "id": "mfa-on-accounts-okta"
        },
        {
          "id": "mfa-on-accounts-policy"
        },
        {
          "id": "mfa-on-accounts-version-control"
        },
        {
          "id": "mfa-on-groups-version-control"
        }
      ],
      "manualEvidenceRequests": [],
      "role": "Controller"
    },
    {
      "id": "remote-access-vpn-enforced",
      "name": "Remote access encrypted enforced",
      "description": "The company's production systems can only be remotely accessed by authorized employees via an approved encrypted connection.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "require-admin-encrypted-iaas"
        },
        {
          "id": "require-admin-encrypted-iaas-gcp"
        },
        {
          "id": "require-admin-encrypted-iaas-heroku"
        }
      ],
      "manualEvidenceRequests": [],
      "role": "Controller"
    },
    {
      "id": "retention",
      "name": "Retention of PII",
      "description": "The company does not retain PII longer than necessary for its purposes",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-data-management-policy-bsi-exists"
        },
        {
          "id": "employees-accepted-data-management-policy-bsi"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "customer-data-deletion-record"
        }
      ],
      "role": "Controller"
    },
    {
      "id": "return-transfer-or-disposal-of-pii",
      "name": "Return, transfer or disposal of PII",
      "description": "The company returns, transfers, disposes PII in accordance to its policies and commitments. (SCC 8.5)",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-data-management-policy-bsi-exists"
        },
        {
          "id": "employees-accepted-data-management-policy-bsi"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "customer-data-deletion-record"
        },
        {
          "id": "customer-data-processing-agreement-exists"
        }
      ],
      "role": "Processor"
    },
    {
      "id": "support-infra-patched",
      "name": "Service infrastructure maintained",
      "description": "The company has infrastructure supporting the service patched as a part of routine maintenance and as a result of identified vulnerabilities to help ensure that servers supporting the service are hardened against security threats.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "container-packages-checked-for-vulnerabilities-records-closed-aws"
        },
        {
          "id": "container-packages-checked-for-vulnerabilities-records-closed-azure"
        },
        {
          "id": "container-packages-checked-for-vulnerabilities-records-closed-gcp"
        },
        {
          "id": "infra-packages-checked-for-vulnerabilities-records-closed"
        },
        {
          "id": "packages-checked-for-vulnerabilities-records-closed-snyk"
        },
        {
          "id": "process-for-install-os-updates-and-patches-config-automation"
        },
        {
          "id": "process-for-install-os-updates-and-patches-config-month"
        },
        {
          "id": "security-issues-assigned-records"
        },
        {
          "id": "security-issues-prioritized-records"
        },
        {
          "id": "security-issues-tracked-records"
        },
        {
          "id": "sla-for-security-bugs-records"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "vulnerabilities-remediated-sample"
        },
        {
          "id": "vulnerability-scan"
        }
      ],
      "role": "Controller"
    },
    {
      "id": "system-network-hardening",
      "name": "Network and system hardening standards maintained",
      "description": "The company's network and system hardening standards are documented, based on industry best practices, and reviewed at least annually.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-operations-security-policy-bsi-exists"
        },
        {
          "id": "employees-accepted-operations-security-policy-bsi"
        }
      ],
      "manualEvidenceRequests": [],
      "role": "Controller"
    },
    {
      "id": "unique-account-authentication-enforced",
      "name": "Unique account authentication enforced",
      "description": "The company requires authentication to systems and applications to use unique username and password or authorized Secure Socket Shell (SSH) keys.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "infra-unique-accounts-group"
        },
        {
          "id": "infra-unique-accounts-records-email"
        },
        {
          "id": "infra-unique-accounts-records-infra"
        },
        {
          "id": "infra-unique-accounts-records-versioncontrol"
        },
        {
          "id": "infra-unique-accounts-roles"
        },
        {
          "id": "infra-unique-accounts-roles-gcp"
        },
        {
          "id": "infra-unique-accounts-roles-heroku"
        },
        {
          "id": "infra-unique-accounts-root"
        },
        {
          "id": "infra-unique-accounts-unused"
        },
        {
          "id": "infra-unique-accounts-user"
        },
        {
          "id": "infra-unique-accounts-user-heroku"
        },
        {
          "id": "require-ssh-config"
        },
        {
          "id": "unique-ssh-keys"
        }
      ],
      "manualEvidenceRequests": [],
      "role": "Controller"
    }
  ],
  "sections": [
    {
      "id": "Article 6",
      "controls": [
        {
          "id": "identify-and-document-purpose"
        },
        {
          "id": "identify-lawful-basis"
        }
      ]
    },
    {
      "id": "Article 7",
      "controls": [
        {
          "id": "consent-obtained"
        },
        {
          "id": "providing-mechanism-to-modify-or-withdraw-consent"
        },
        {
          "id": "providing-mechanism-to-object-to-pii-processing"
        }
      ]
    },
    {
      "id": "Article 12",
      "controls": [
        {
          "id": "determining-information-for-pii-data-subjects"
        },
        {
          "id": "handling-requests"
        }
      ]
    },
    {
      "id": "Article 13",
      "controls": [
        {
          "id": "determining-information-for-pii-data-subjects"
        },
        {
          "id": "handling-requests"
        },
        {
          "id": "providing-information-to-pii-data-subjects"
        }
      ]
    },
    {
      "id": "Article 14",
      "controls": [
        {
          "id": "determining-information-for-pii-data-subjects"
        },
        {
          "id": "handling-requests"
        }
      ]
    },
    {
      "id": "Article 15",
      "controls": [
        {
          "id": "access-correction-erasure"
        },
        {
          "id": "handling-requests"
        },
        {
          "id": "providing-copy-of-pii-processed"
        },
        {
          "id": "records-of-transfer-of-pii"
        }
      ]
    },
    {
      "id": "Article 16",
      "controls": [
        {
          "id": "access-correction-erasure"
        },
        {
          "id": "accuracy-and-quality"
        },
        {
          "id": "handling-requests"
        }
      ]
    },
    {
      "id": "Article 17",
      "controls": [
        {
          "id": "handling-requests"
        }
      ]
    },
    {
      "id": "Article 18",
      "controls": [
        {
          "id": "handling-requests"
        }
      ]
    },
    {
      "id": "Article 19",
      "controls": [
        {
          "id": "handling-requests"
        },
        {
          "id": "PII-controllers-obligations-to-inform-third-parties"
        }
      ]
    },
    {
      "id": "Article 20",
      "controls": [
        {
          "id": "handling-requests"
        }
      ]
    },
    {
      "id": "Article 21",
      "controls": [
        {
          "id": "automated-decision-making"
        },
        {
          "id": "handling-requests"
        },
        {
          "id": "providing-mechanism-to-modify-or-withdraw-consent"
        },
        {
          "id": "providing-mechanism-to-object-to-pii-processing"
        }
      ]
    },
    {
      "id": "Article 22",
      "controls": [
        {
          "id": "automated-decision-making"
        },
        {
          "id": "handling-requests"
        }
      ]
    },
    {
      "id": "Article 23",
      "controls": [
        {
          "id": "handling-requests"
        }
      ]
    },
    {
      "id": "Article 24",
      "controls": [
        {
          "id": "access-correction-erasure"
        },
        {
          "id": "access-production-network-restricted"
        },
        {
          "id": "access-reviews"
        },
        {
          "id": "access-revoked-termination"
        },
        {
          "id": "access-role-based"
        },
        {
          "id": "access-ssh-required"
        },
        {
          "id": "accuracy-and-quality"
        },
        {
          "id": "asset-disposal-procedures-utilized"
        },
        {
          "id": "assets-anti-malware"
        },
        {
          "id": "assets-portable-media-encrypted"
        },
        {
          "id": "changes-approval-required"
        },
        {
          "id": "continuity-and-disaster-recovery-plans-established"
        },
        {
          "id": "continuity-disaster-recovery-plans-tested"
        },
        {
          "id": "data-classification-policy"
        },
        {
          "id": "data-encrypted"
        },
        {
          "id": "data-encrypted-in-transit"
        },
        {
          "id": "gdpr-training-completed"
        },
        {
          "id": "incident-response-policies-established"
        },
        {
          "id": "infra-network-segregation"
        },
        {
          "id": "intrusion-detection-system"
        },
        {
          "id": "MDM-system-utilized"
        },
        {
          "id": "network-firewalls-reviewed"
        },
        {
          "id": "network-firewalls-utilized"
        },
        {
          "id": "pii-transmission-controlls"
        },
        {
          "id": "pii-transmission-controls"
        },
        {
          "id": "policies-password-complexity"
        },
        {
          "id": "production-inventory-maintained"
        },
        {
          "id": "remote-access-mfa-enforced"
        },
        {
          "id": "remote-access-vpn-enforced"
        },
        {
          "id": "support-infra-patched"
        },
        {
          "id": "system-network-hardening"
        },
        {
          "id": "unique-account-authentication-enforced"
        }
      ]
    },
    {
      "id": "Article 25",
      "controls": [
        {
          "id": "customer-data-deleted upon-leave"
        },
        {
          "id": "data-classification-policy"
        },
        {
          "id": "disposal"
        },
        {
          "id": "limit-collection"
        },
        {
          "id": "pii-deidentification-deletion-at-the-end-of-processing"
        },
        {
          "id": "pii-minimization-objectives"
        },
        {
          "id": "retention"
        },
        {
          "id": "return-transfer-or-disposal-of-pii"
        }
      ]
    },
    {
      "id": "Article 27",
      "controls": [
        {
          "id": "eu-representatitve"
        }
      ]
    },
    {
      "id": "Article 28",
      "controls": [
        {
          "id": "access-production-network-restricted"
        },
        {
          "id": "access-reviews"
        },
        {
          "id": "access-revoked-termination"
        },
        {
          "id": "access-role-based"
        },
        {
          "id": "access-ssh-required"
        },
        {
          "id": "asset-disposal-procedures-utilized"
        },
        {
          "id": "assets-anti-malware"
        },
        {
          "id": "assets-portable-media-encrypted"
        },
        {
          "id": "assist-controller-with-privacy-obligations"
        },
        {
          "id": "basis-for-pii-transfer-between-jurisdictions"
        },
        {
          "id": "change-of-subcontractor-to-process-pii"
        },
        {
          "id": "changes-approval-required"
        },
        {
          "id": "continuity-and-disaster-recovery-plans-established"
        },
        {
          "id": "continuity-disaster-recovery-plans-tested"
        },
        {
          "id": "contracts-with-pii-processors"
        },
        {
          "id": "customer-obligations"
        },
        {
          "id": "data-classification-policy"
        },
        {
          "id": "data-encrypted"
        },
        {
          "id": "data-encrypted-in-transit"
        },
        {
          "id": "disclosure-of-sub-contractors-used-to-process-pii"
        },
        {
          "id": "gdpr-training-completed"
        },
        {
          "id": "incident-response-policies-established"
        },
        {
          "id": "infra-network-segregation"
        },
        {
          "id": "infringing-instruction"
        },
        {
          "id": "intrusion-detection-system"
        },
        {
          "id": "marketing-and-advertising-use"
        },
        {
          "id": "MDM-system-utilized"
        },
        {
          "id": "network-firewalls-reviewed"
        },
        {
          "id": "network-firewalls-utilized"
        },
        {
          "id": "pii-transmission-controlls"
        },
        {
          "id": "pii-transmission-controls"
        },
        {
          "id": "policies-password-complexity"
        },
        {
          "id": "process-as-per-processor-agreements"
        },
        {
          "id": "production-inventory-maintained"
        },
        {
          "id": "remote-access-mfa-enforced"
        },
        {
          "id": "remote-access-vpn-enforced"
        },
        {
          "id": "support-infra-patched"
        },
        {
          "id": "system-network-hardening"
        },
        {
          "id": "unique-account-authentication-enforced"
        }
      ]
    },
    {
      "id": "Article 30",
      "controls": [
        {
          "id": "countries-and-international-organizations-to-which-pii-can-be-transferred"
        },
        {
          "id": "countries-international-organizations-pii-transferred"
        },
        {
          "id": "data-inventory"
        },
        {
          "id": "identify-basis-for-pii-transfer-between-jurisdictions"
        },
        {
          "id": "notifications-of-pii-disclosure-requests"
        },
        {
          "id": "records-of-pii-disclosure-to-third-parties"
        },
        {
          "id": "records-of-transfer-of-pii"
        },
        {
          "id": "records-of-transfer-of-pii"
        },
        {
          "id": "records-related-to-processing-pii"
        }
      ]
    },
    {
      "id": "Article 32",
      "controls": [
        {
          "id": "access-production-network-restricted"
        },
        {
          "id": "access-reviews"
        },
        {
          "id": "access-revoked-termination"
        },
        {
          "id": "access-role-based"
        },
        {
          "id": "access-ssh-required"
        },
        {
          "id": "accuracy-and-quality"
        },
        {
          "id": "asset-disposal-procedures-utilized"
        },
        {
          "id": "assets-anti-malware"
        },
        {
          "id": "assets-portable-media-encrypted"
        },
        {
          "id": "changes-approval-required"
        },
        {
          "id": "continuity-and-disaster-recovery-plans-established"
        },
        {
          "id": "continuity-disaster-recovery-plans-tested"
        },
        {
          "id": "data-classification-policy"
        },
        {
          "id": "data-encrypted"
        },
        {
          "id": "data-encrypted-in-transit"
        },
        {
          "id": "incident-response-policies-established"
        },
        {
          "id": "infra-network-segregation"
        },
        {
          "id": "intrusion-detection-system"
        },
        {
          "id": "MDM-system-utilized"
        },
        {
          "id": "network-firewalls-reviewed"
        },
        {
          "id": "network-firewalls-utilized"
        },
        {
          "id": "pii-transmission-controlls"
        },
        {
          "id": "pii-transmission-controls"
        },
        {
          "id": "policies-password-complexity"
        },
        {
          "id": "production-inventory-maintained"
        },
        {
          "id": "pseudonymization"
        },
        {
          "id": "remote-access-mfa-enforced"
        },
        {
          "id": "remote-access-vpn-enforced"
        },
        {
          "id": "support-infra-patched"
        },
        {
          "id": "system-network-hardening"
        },
        {
          "id": "unique-account-authentication-enforced"
        }
      ]
    },
    {
      "id": "Article 33",
      "controls": [
        {
          "id": "breach-policy-and-procedure"
        },
        {
          "id": "incident-response-policies-established"
        },
        {
          "id": "policies-incident-management"
        }
      ]
    },
    {
      "id": "Article 34",
      "controls": [
        {
          "id": "breach-policy-and-procedure"
        },
        {
          "id": "incident-response-policies-established"
        },
        {
          "id": "policies-incident-management"
        }
      ]
    },
    {
      "id": "Article 35",
      "controls": [
        {
          "id": "determine-needs-and-perform-transfer-impact-assessment"
        },
        {
          "id": "privacy-impact-assessment"
        }
      ]
    },
    {
      "id": "Article 37",
      "controls": [
        {
          "id": "dpo"
        }
      ]
    },
    {
      "id": "Article 38",
      "controls": [
        {
          "id": "dpo"
        }
      ]
    },
    {
      "id": "Article 39",
      "controls": [
        {
          "id": "dpo"
        }
      ]
    },
    {
      "id": "Article 44",
      "controls": [
        {
          "id": "identify-basis-for-pii-transfer-between-jurisdictions"
        }
      ]
    },
    {
      "id": "Article 45",
      "controls": [
        {
          "id": "identify-basis-for-pii-transfer-between-jurisdictions"
        }
      ]
    },
    {
      "id": "Article 46",
      "controls": [
        {
          "id": "basis-for-pii-transfer-between-jurisdictions"
        },
        {
          "id": "identify-basis-for-pii-transfer-between-jurisdictions"
        }
      ]
    },
    {
      "id": "Article 48",
      "controls": [
        {
          "id": "legally-binding-pii-disclosures"
        }
      ]
    },
    {
      "id": "Article 51",
      "controls": [
        {
          "id": "lead-supervisory-authority"
        }
      ]
    }
  ]
}
