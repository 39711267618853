{
  "standardInfo": {
    "standard": "vanta"
  },
  "controls": [
    {
      "id": "acceptable-use-policy",
      "name": "Acceptable Use Policy",
      "description": "%COMPANY has policies and procedures in place to establish acceptable use of information assets approved by management, posted on the company wiki, and accessible to all employees. All employees must agree to the Acceptable Use Policy on hire.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "acceptable-use-policy-agree"
        },
        {
          "id": "acceptable-use-policy-policies"
        },
        {
          "id": "approved-information-security-policy-bsi-exists"
        },
        {
          "id": "employees-accepted-information-security-policy-bsi"
        }
      ]
    },
    {
      "id": "access-granted",
      "name": "System access granted",
      "description": "Access to infrastructure and code review tools is granted to new employees within one week of their start date.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "access-granted-infra"
        },
        {
          "id": "access-granted-versioncontrol"
        }
      ]
    },
    {
      "id": "access-removed",
      "name": "Terminated employee access revoked within SLA",
      "description": "Access to infrastructure and code review tools is removed from terminated employees within SLA.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "access-removed-infra"
        },
        {
          "id": "access-removed-versioncontrol"
        }
      ]
    },
    {
      "id": "admin-access-logged",
      "name": "Internal admin access logged",
      "description": "The company ensures that employee use of the internal admin tool is logged, and those logs are stored in a central location.",
      "categories": ["Administrative"],
      "tests": []
    },
    {
      "id": "admin-access-requires-approval",
      "name": "Administrators grant employee access to internal admin tools",
      "description": "A system administrator must approve access to the internal admin tool, and access is removed when appropriate. Access approval and modification to access list are logged and reviewed on a quarterly basis.",
      "categories": ["Administrative"],
      "tests": []
    },
    {
      "id": "admin-multi-factor-authentication",
      "name": "Multi-factor authentication",
      "description": "Access to the internal admin tool requires two factor authentication in the form of user ID, password, OTP and/or certificate.",
      "categories": ["Administrative"],
      "tests": []
    },
    {
      "id": "antivirus",
      "name": "Antivirus",
      "description": "Company management ensures that company-issued laptops run antivirus software.",
      "categories": ["Administrative"],
      "tests": []
    },
    {
      "id": "app-changes-tested-before-deploys",
      "name": "App changes tested before deploys",
      "description": "An automated system or authorized engineer reviews, tests, and approves application changes before the changes are deployed to production.",
      "categories": ["Technical"],
      "tests": []
    },
    {
      "id": "app-secrets-encrypted-decrypted",
      "name": "Credentials encrypted and decrypted securely",
      "description": "%COMPANY has an established cryptography policy in place to support the secure encryption and decryption of app secrets.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "app-secrets-decrypted-config"
        },
        {
          "id": "app-secrets-encrypted-config"
        }
      ]
    },
    {
      "id": "app-secrets-stored-securely",
      "name": "App secrets stored securely",
      "description": "%COMPANY has an established process in place to support the organization's storage of app secrets, including OAuth Tokens.",
      "categories": ["Administrative"],
      "tests": []
    },
    {
      "id": "background-checks",
      "name": "Background checks",
      "description": "Background checks are performed on new hires before the new hire's start date, as permitted by local laws. The results are reviewed by HR and appropriate action is taken if deemed necessary.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "background-checks-records"
        }
      ]
    },
    {
      "id": "bug-bounty-program",
      "name": "Bug bounty program",
      "description": "%COMPANY has an established bug bounty program that provides a channel with clear expectations for external users to report potential information security incidents.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "bug-bounty-program-records"
        }
      ]
    },
    {
      "id": "building-alarms",
      "name": "Security alarms in office",
      "description": "Company management has installed a security alarm in the office and confirmed that it is operational. If someone unauthorized were to enter the office outside of work hours, the alarm would go off.",
      "categories": ["Physical"],
      "tests": []
    },
    {
      "id": "building-cameras",
      "name": "Security cameras in office",
      "description": "Security cameras have been implemented to record physical activity at entrances into the office space. An archive of video activity is retained.",
      "categories": ["Physical"],
      "tests": []
    },
    {
      "id": "change-management-policy",
      "name": "Change management policy",
      "description": "%COMPANY has developed policies and procedures governing the system development lifecycle, including documented policies for tracking, testing, approving, and validating changes are documented.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-operations-security-policy-bsi-exists"
        },
        {
          "id": "change-management-policy-policies"
        }
      ]
    },
    {
      "id": "client-packages-checked-for-vulnerabilities",
      "name": "Client packages checked for vulnerabilities",
      "description": "%COMPANY has implemented a vulnerability management program to detect and remediate system vulnerabilities in software packages used to build the client.",
      "categories": ["Administrative"],
      "tests": []
    },
    {
      "id": "code-of-conduct",
      "name": "Code of Conduct",
      "description": "%COMPANY has established a code of conduct and requires all employees to agree to it on hire. Management monitors employees' acceptance of the code.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-code-of-conduct-bsi-exists"
        },
        {
          "id": "code-of-conduct-agree"
        },
        {
          "id": "code-of-conduct-policies"
        },
        {
          "id": "employees-accepted-code-of-conduct-bsi"
        }
      ]
    },
    {
      "id": "code-review",
      "name": "System changes must be approved",
      "description": "System changes must be approved by an independent technical resource prior to deployment to production",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "code-review-application-config"
        }
      ]
    },
    {
      "id": "commitments-external",
      "name": "Company commitments explained to customers",
      "description": "Security commitments are communicated to external users, as appropriate.",
      "categories": ["Administrative"],
      "tests": []
    },
    {
      "id": "company-goals",
      "name": "Company goals",
      "description": "Company management develops company goals on a quarterly basis. Management also evaluates the prior quarter's goals. New goals, plus prior evaluations, are communicated to employees each quarter.",
      "categories": ["Administrative"],
      "tests": []
    },
    {
      "id": "corp-resources-protected",
      "name": "Corporate resources protected",
      "description": "Users can only access internal admin tools remotely through the use of encrypted communication systems.",
      "categories": ["Administrative"],
      "tests": []
    },
    {
      "id": "corp-resources-protected-individual-accounts",
      "name": "Individual accounts to access corp network",
      "description": "Company employees can only access internal admin tools remotely through the use of individual accounts.",
      "categories": ["Administrative"],
      "tests": []
    },
    {
      "id": "corp-resources-protected-multi-factor-authentication",
      "name": "Multi-factor authentication needed to access corporate resources",
      "description": "Company employees must use two factor authentication, in the form of user ID, password, OTP and/or certificate, to access corporate resources.",
      "categories": ["Administrative"],
      "tests": []
    },
    {
      "id": "corp-resources-protected-production",
      "name": "VPN required for production access",
      "description": "Users can only access the production system remotely through the use of encrypted communication systems.",
      "categories": ["Administrative"],
      "tests": []
    },
    {
      "id": "credential-keys-managed",
      "name": "Credential keys managed",
      "description": "%COMPANY has an established key management process in place to support the organization’s use of cryptographic techniques.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "credential-keys-managed-config"
        },
        {
          "id": "credential-keys-managed-policy"
        }
      ]
    },
    {
      "id": "cryptography-policies",
      "name": "Cryptography policies",
      "description": "%COMPANY has established policies and procedures that govern the use of cryptographic controls.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-cryptography-policy-bsi-exists"
        },
        {
          "id": "cryptography-policies-policies"
        }
      ]
    },
    {
      "id": "customer-data-database",
      "name": "Restrict access to customer data",
      "description": "Databases that store customer data only accept programmatic connections from pre-defined service accounts. Only authorized infrastructure administrators may add new service accounts to the list of acceptable connectors",
      "categories": ["Administrative"],
      "tests": []
    },
    {
      "id": "customer-data-encrypted-at-rest",
      "name": "Customer data encrypted at rest",
      "description": "Customer data stored in databases is encrypted at rest.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "customer-data-encrypted-at-rest-config"
        },
        {
          "id": "customer-data-encrypted-at-rest-config-azure"
        },
        {
          "id": "customer-data-encrypted-at-rest-config-gcp"
        },
        {
          "id": "customer-data-encrypted-at-rest-config-heroku"
        },
        {
          "id": "storage-buckets-are-encrypted-config"
        },
        {
          "id": "storage-buckets-are-encrypted-config-gcp"
        }
      ]
    },
    {
      "id": "customer-data-policies",
      "name": "Customer data policies",
      "description": "Company management has approved %COMPANY policies that detail how customer data may be made accessible and should be handled. These policies are accessible to all employees and contractors.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-data-management-policy-bsi-exists"
        },
        {
          "id": "customer-data-policies-database"
        },
        {
          "id": "customer-data-policies-policies"
        },
        {
          "id": "data-classification-policy-policies"
        }
      ]
    },
    {
      "id": "customer-disclosure-process",
      "name": "Disclosure process for customers",
      "description": "%COMPANY provides a process to external users for reporting security, confidentiality, integrity and availability failures, incidents, concerns, and other complaints.",
      "categories": ["Administrative"],
      "tests": []
    },
    {
      "id": "customer-monitoring-changes",
      "name": "Customers informed of changes",
      "description": "System changes that may affect security, availability, processing integrity, or confidentiality are communicated to customers and users who will be affected.",
      "categories": ["Administrative"],
      "tests": []
    },
    {
      "id": "customer-password-policy",
      "name": "Customer password policy",
      "description": "%COMPANY has established formal guidelines for external users' passwords that govern all authentication attempts by external users.",
      "categories": ["Administrative"],
      "tests": []
    },
    {
      "id": "daily-database-backups",
      "name": "Daily database backups",
      "description": "Backups are performed daily and retained in accordance with a pre-defined schedule in the Backup Policy.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "daily-database-backups-config"
        },
        {
          "id": "daily-database-backups-config-azure"
        },
        {
          "id": "daily-database-backups-config-gcp"
        },
        {
          "id": "daily-database-backups-config-heroku"
        },
        {
          "id": "daily-database-backups-policy"
        }
      ]
    },
    {
      "id": "data-protection-policy",
      "name": "Data protection policy",
      "description": "%COMPANY has established a Data Protection Policy and requires all employees to agree to it on hire. Management monitors employees' acceptance of the policy.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-data-management-policy-bsi-exists"
        },
        {
          "id": "data-protection-policy-policies"
        }
      ]
    },
    {
      "id": "ddos-protection",
      "name": "DDOS protection",
      "description": "There are mechanisms in place to protect the Production environment against a variety of application layer denial of service attacks.",
      "categories": ["Technical"],
      "tests": []
    },
    {
      "id": "employee-disclosure-process",
      "name": "Employee disclosure process",
      "description": "%COMPANY provides a process to employees for reporting security, confidentiality, integrity and availability failures, incidents, and concerns, and other complaints to company management.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "employee-disclosure-process-policies"
        }
      ]
    },
    {
      "id": "employee-laptops",
      "name": "Employee laptops monitored",
      "description": "Ensured every employee in the organization has a monitored computer.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "employees-without-laptops"
        }
      ]
    },
    {
      "id": "external-service-provider-approval",
      "name": "External service providers approved",
      "description": "%COMPANY reviews security practices and security postures of its external security providers",
      "categories": ["Administrative"],
      "tests": []
    },
    {
      "id": "file-integrity-monitoring",
      "name": "File integrity monitoring",
      "description": "%COMPANY has deployed file integrity monitoring (FIM) software to identify and evaluate ongoing system performance, security threats, changing resource utilization needs, and unusual system activity. The software alerts on abnormal activity, and a ticket is created in %COMPANY's task tracker to triage the potential issue.",
      "categories": ["Technical"],
      "tests": []
    },
    {
      "id": "firewall-endpoint",
      "name": "Personal firewalls",
      "description": "Company management ensures that company-issued laptops have a personal firewall.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "firewall-endpoint-policies"
        }
      ]
    },
    {
      "id": "firewalls",
      "name": "Firewalls",
      "description": "Management uses configurations that ensure only approved networking ports and protocols are implemented, including firewalls.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "firewall-config-acl"
        },
        {
          "id": "firewall-config-acl-gcp"
        },
        {
          "id": "firewall-config-acl-heroku"
        },
        {
          "id": "firewall-config-default-deny"
        },
        {
          "id": "firewall-config-default-deny-gcp"
        },
        {
          "id": "firewall-config-default-deny-heroku"
        },
        {
          "id": "firewall-config-server"
        },
        {
          "id": "firewall-config-server-heroku"
        },
        {
          "id": "ssh-denied-azure"
        }
      ]
    },
    {
      "id": "flow-logs-on",
      "name": "Network flow logs enabled",
      "description": "Management has implemented tools to log network traffic into a system that allows monitoring and ad hoc queries.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "flow-logs-on-azure"
        },
        {
          "id": "flow-logs-on-config"
        },
        {
          "id": "flow-logs-on-config-gcp"
        }
      ]
    },
    {
      "id": "full-disk-encryption",
      "name": "Full-disk encryption",
      "description": "Company management ensures that all company-issued laptop hard drives are encrypted using full disk encryption.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "full-disk-encryption-records"
        }
      ]
    },
    {
      "id": "incident-response-follow-ups",
      "name": "Follow-ups tracked",
      "description": "%COMPANY has implemented an incident response policy that includes creating, prioritizing, assigning, and tracking follow-ups to completion.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "incident-response-follow-ups-policies"
        }
      ]
    },
    {
      "id": "incident-response-lessons-learned",
      "name": "Lessons learned",
      "description": "%COMPANY has implemented an incident response policy that includes writing \"lessons learned\" documents after incidents and sharing them with the broader engineering team.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "incident-response-lessons-learned-policies"
        }
      ]
    },
    {
      "id": "incident-response-plan",
      "name": "Incident Response plan",
      "description": "%COMPANY has an established incident response policy that outlines management responsibilities and procedures to ensure a quick, effective, and orderly response to information security incidents.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "incident-response-plan-policies"
        }
      ]
    },
    {
      "id": "incident-response-team",
      "name": "Incident Response team",
      "description": "%COMPANY has identified an incident response team that quantifies and monitors incidents involving security, availability, processing integrity and confidentiality at %COMPANY.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "incident-response-team-policies"
        }
      ]
    },
    {
      "id": "incidents-reported-within-twenty-four-hours",
      "name": "Incidents reported within 24 hours",
      "description": "%COMPANY alerts external users of incidents affecting the system within twenty-four hours of those issues becoming known internally via various mediums, including an uptime page, emails, support pages, or the company's blog.",
      "categories": ["Administrative"],
      "tests": []
    },
    {
      "id": "infra-access-requires-approval",
      "name": "Access to the infrastructure provider requires approval, is logged, and is updated as needed",
      "description": "An administrator must approve new-employee access to the infrastructure provider, and access is restricted to authorized personnel. Access approval and modification to access list are logged. Access is removed when appropriate.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "infra-access-requires-approval-config"
        },
        {
          "id": "no-allusers-policy-bindings-gcp"
        },
        {
          "id": "no-nonindividual-primitive-roles-gcp"
        }
      ]
    },
    {
      "id": "infra-activity-tracked",
      "name": "CloudTrail enabled",
      "description": "Management has implemented tools to log application state into a system that allows monitoring and ad hoc queries.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "ephi-blob-containers-have-access-logs-azure"
        },
        {
          "id": "ephi-buckets-have-access-logs"
        },
        {
          "id": "infra-activity-tracked-config"
        },
        {
          "id": "infra-activity-tracked-config-azure"
        },
        {
          "id": "infra-activity-tracked-config-heroku"
        }
      ]
    },
    {
      "id": "infra-checked-for-vulnerabilities-external",
      "name": "External network vulnerability scans",
      "description": "%COMPANY runs external network vulnerability scans of the production environment at least annually. Results are reviewed by management and high priority findings are tracked to resolution.",
      "categories": ["Administrative"],
      "tests": []
    },
    {
      "id": "infra-packages-checked-for-vulnerabilities",
      "name": "Infrastructure packages checked for vulnerabilities",
      "description": "%COMPANY has implemented a vulnerability management program to detect and remediate system vulnerabilities in software packages used in company infrastructure.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "container-packages-checked-for-vulnerabilities-records-closed-aws"
        },
        {
          "id": "container-packages-checked-for-vulnerabilities-records-closed-azure"
        },
        {
          "id": "container-packages-checked-for-vulnerabilities-records-closed-gcp"
        },
        {
          "id": "infra-packages-checked-for-vulnerabilities-records-closed"
        },
        {
          "id": "packages-checked-for-vulnerabilities-records-closed-snyk"
        }
      ]
    },
    {
      "id": "infra-unique-accounts",
      "name": "Unique accounts",
      "description": "Access to corporate network, production machines, network devices, and support tools requires a unique ID.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "infra-unique-accounts-group"
        },
        {
          "id": "infra-unique-accounts-records-email"
        },
        {
          "id": "infra-unique-accounts-records-infra"
        },
        {
          "id": "infra-unique-accounts-records-versioncontrol"
        },
        {
          "id": "infra-unique-accounts-roles"
        },
        {
          "id": "infra-unique-accounts-roles-gcp"
        },
        {
          "id": "infra-unique-accounts-roles-heroku"
        },
        {
          "id": "infra-unique-accounts-root"
        },
        {
          "id": "infra-unique-accounts-unused"
        },
        {
          "id": "infra-unique-accounts-user"
        },
        {
          "id": "infra-unique-accounts-user-heroku"
        }
      ]
    },
    {
      "id": "internal-password-policy",
      "name": "Password policy",
      "description": "%COMPANY has established formal guidelines for passwords to govern the management and use of authentication mechanisms.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "internal-password-policy-config"
        },
        {
          "id": "internal-password-policy-config-heroku"
        },
        {
          "id": "internal-password-policy-policies"
        }
      ]
    },
    {
      "id": "intrusion-detection-service",
      "name": "Intrusion detection service",
      "description": "%COMPANY has deployed a host-based intrusion detection system across system infrastructure components to collect and analyze data to identify unusual activity, potential security threats, and vulnerabilities.",
      "categories": ["Administrative"],
      "tests": []
    },
    {
      "id": "job-descriptions",
      "name": "Job descriptions",
      "description": "All positions have a detailed job description that lists qualifications, such as requisite skills and experience, which candidates must meet in order to be hired by %COMPANY.",
      "categories": ["Administrative"],
      "tests": []
    },
    {
      "id": "key-needed",
      "name": "Key needed for company office",
      "description": "A key is needed to open the company office. Furthermore, the office's main door is kept locked during non-business hours.",
      "categories": ["Physical"],
      "tests": []
    },
    {
      "id": "least-privileged-policy",
      "name": "Least-privileged policy for customer data access",
      "description": "The company authorizes access to information resources, including data and the systems that store or process customer data, based on the principle of least privilege.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "least-privileged-policy-policies"
        }
      ]
    },
    {
      "id": "load-balancer",
      "name": "Load balancer",
      "description": "Load balancers are used to distribute traffic in a way that increases the reliability and availability of the system.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "load-balancer-config"
        },
        {
          "id": "load-balancer-config-azure"
        },
        {
          "id": "load-balancer-config-heroku"
        },
        {
          "id": "load-balancer-http-to-https"
        },
        {
          "id": "load-balancer-http-to-https-azure"
        }
      ]
    },
    {
      "id": "load-balancers-monitored-and-alarmed",
      "name": "Load balancers monitored and alarmed",
      "description": "Management has implemented tools to monitor %COMPANY load balancers and notify appropriate personnel of any events or incidents based on predetermined criteria. Incidents are escalated per policy.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "load-balancers-monitored-and-alarmed-config-healthy"
        },
        {
          "id": "load-balancers-monitored-and-alarmed-config-healthy-azure"
        },
        {
          "id": "load-balancers-monitored-and-alarmed-config-latency"
        },
        {
          "id": "load-balancers-monitored-and-alarmed-config-latency-azure"
        },
        {
          "id": "load-balancers-monitored-and-alarmed-config-servererrors"
        },
        {
          "id": "load-balancers-monitored-and-alarmed-config-servererrors-azure"
        }
      ]
    },
    {
      "id": "location-services",
      "name": "Location service",
      "description": "Company management ensures that location services are enabled on all company-issued Apple laptops.",
      "categories": ["Administrative"],
      "tests": []
    },
    {
      "id": "log-permissions",
      "name": "Log permissions",
      "description": "The company uses a log-storage system that permits only administrative users to modify or delete logs.",
      "categories": ["Administrative"],
      "tests": []
    },
    {
      "id": "logging-buckets-are-versioned",
      "name": "Storage buckets have versioning enabled",
      "description": "Storage buckets that contain logging data are versioned.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "activity-log-blob-containers-are-versioned-azure"
        },
        {
          "id": "activity-log-blob-containers-have-soft-delete-config-azure"
        },
        {
          "id": "logging-bucket-permissions"
        }
      ]
    },
    {
      "id": "logging-buckets-have-MFA-delete",
      "name": "Storage buckets have MFA delete",
      "description": "Deleting data from a storage bucket requires the root account and two factor authentication in the form of user ID, password, OTP and/or certificate.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "logging-buckets-have-MFA-delete-config"
        }
      ]
    },
    {
      "id": "logging-enabled",
      "name": "Logging enabled",
      "description": "The company uses logging and monitoring software to collect data from system infrastructure components and endpoint systems, monitor system performance, detect potential security threats and vulnerabilities or unusual system activity, and track resource utilization.",
      "categories": ["Administrative"],
      "tests": []
    },
    {
      "id": "logs-alerting",
      "name": "Logs reviewed for anomalies on a regular schedule",
      "description": "Audit logs are continuously monitored for events related to security, availability, processing integrity threats. Alerts are generated for further investigation based on pre-defined thresholds and sent to qualified personnel.",
      "categories": ["Administrative"],
      "tests": []
    },
    {
      "id": "logs-centrally-stored",
      "name": "Logs centrally stored",
      "description": "The company uses a system that collects and stores server logs in a central location. The system can be queried in an ad hoc fashion by authorized users.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "activity-log-blob-container-has-logging-azure"
        },
        {
          "id": "logs-centrally-stored-authorization"
        },
        {
          "id": "logs-centrally-stored-authorization-gcp"
        },
        {
          "id": "logs-centrally-stored-config"
        },
        {
          "id": "logs-centrally-stored-config-gcp"
        }
      ]
    },
    {
      "id": "logs-retained-for-twelve-months",
      "name": "Logs retained for 12 months",
      "description": "Logging software retains log entries for at least 12 months.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "heroku-logs-drained"
        },
        {
          "id": "logs-retained-for-twelve-months-config"
        },
        {
          "id": "logs-retained-for-twelve-months-config-gcp"
        }
      ]
    },
    {
      "id": "management-reviews-risks",
      "name": "Management reviews risks",
      "description": "A formal risk assessment is performed at least annually to determine the likelihood and impact of all identified risks, using qualitative and quantitative methods. The likelihood and impact associated with each risk is determined independently, considering each risk category.",
      "categories": ["Administrative"],
      "tests": []
    },
    {
      "id": "mfa-on-accounts",
      "name": "MFA on accounts",
      "description": "Access to sensitive systems and applications requires two factor authentication in the form of user ID, password, OTP and/or certificate.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "mfa-on-accounts-gsuite"
        },
        {
          "id": "mfa-on-accounts-infra"
        },
        {
          "id": "mfa-on-accounts-infra-root"
        },
        {
          "id": "mfa-on-accounts-okta"
        },
        {
          "id": "mfa-on-accounts-policy"
        },
        {
          "id": "mfa-on-accounts-version-control"
        },
        {
          "id": "mfa-on-groups-version-control"
        }
      ]
    },
    {
      "id": "multi-factor-authentication-offered-to-customers",
      "name": "Multi-factor authentication offered to customers",
      "description": "%COMPANY offers its users/customers the ability to protect their %COMPANY accounts with either two-factor authentication or a Single Sign-On (SSO) provider.",
      "categories": ["Administrative"],
      "tests": []
    },
    {
      "id": "mysql-database-monitored-and-alarmed",
      "name": "Databases monitored and alarmed",
      "description": "Management has implemented tools to monitor %COMPANY SQL databases and notify appropriate personnel of any events or incidents based on predetermined criteria. Incidents are escalated per policy.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "mysql-database-monitored-and-alarmed-config-cpu"
        },
        {
          "id": "mysql-database-monitored-and-alarmed-config-cpu-gcp"
        },
        {
          "id": "mysql-database-monitored-and-alarmed-config-free-memory"
        },
        {
          "id": "mysql-database-monitored-and-alarmed-config-free-memory-gcp"
        },
        {
          "id": "mysql-database-monitored-and-alarmed-config-io"
        },
        {
          "id": "mysql-database-monitored-and-alarmed-config-io-gcp"
        },
        {
          "id": "mysql-database-monitored-and-alarmed-config-storage-space"
        },
        {
          "id": "mysql-database-monitored-and-alarmed-config-storage-space-gcp"
        },
        {
          "id": "sql-database-monitored-and-alarmed-config-cpu-azure"
        },
        {
          "id": "sql-database-monitored-and-alarmed-config-free-memory-azure"
        },
        {
          "id": "sql-database-monitored-and-alarmed-config-io-azure"
        },
        {
          "id": "sql-database-monitored-and-alarmed-config-storage-space-azure"
        }
      ]
    },
    {
      "id": "nda-used",
      "name": "Company uses NDAs",
      "description": "%COMPANY requires external parties to sign a nondisclosure agreement prior to disclosing confidential information about the company.",
      "categories": ["Technical"],
      "tests": []
    },
    {
      "id": "network-configuration-changes-tested-before-deploys",
      "name": "Network configuration changes tested before deploys",
      "description": "An authorized engineer reviews, tests, and approves network configuration changes before the changes are deployed to production.",
      "categories": ["Technical"],
      "tests": []
    },
    {
      "id": "network-diagram",
      "name": "Network diagram",
      "description": "%COMPANY maintains an accurate network diagram that is accessible to the engineering team and is reviewed by management on an annual basis.",
      "categories": ["Administrative"],
      "tests": []
    },
    {
      "id": "nosql-database-monitored-and-alarmed",
      "name": "NoSQL database monitored and alarmed",
      "description": "Management has implemented tools to monitor %COMPANY NoSQL databases and notify appropriate personnel of any events or incidents based on predetermined criteria. Incidents are escalated per policy.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "bigtable-cluster-monitored-and-alarmed-config-cpu-gcp"
        },
        {
          "id": "bigtable-cluster-monitored-and-alarmed-config-storage-gcp"
        },
        {
          "id": "nosql-database-monitored-and-alarmed-config-read"
        },
        {
          "id": "nosql-database-monitored-and-alarmed-config-write"
        },
        {
          "id": "nosql-datastore-monitored-and-alarmed-config-read-gcp"
        },
        {
          "id": "nosql-datastore-monitored-and-alarmed-config-write-gcp"
        },
        {
          "id": "nosql-firestore-monitored-and-alarmed-config-read-gcp"
        },
        {
          "id": "nosql-firestore-monitored-and-alarmed-config-write-gcp"
        }
      ]
    },
    {
      "id": "password-manager",
      "name": "Password manager",
      "description": "Company management ensures that a password manager is installed on all company-issued laptops.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "password-manager-policies"
        },
        {
          "id": "password-manager-records"
        },
        {
          "id": "password-manager-records-jamf"
        }
      ]
    },
    {
      "id": "passwords-hashed-and-salted",
      "name": "Passwords hashed and salted",
      "description": "Production passwords are hashed in storage.",
      "categories": ["Administrative"],
      "tests": []
    },
    {
      "id": "penetration-tests",
      "name": "Annual penetration tests",
      "description": "%COMPANY engages third-parties to conduct penetration tests of the production environment at least annually. Results are reviewed by management and high priority findings are tracked to resolution.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "penetration-tests-records"
        }
      ]
    },
    {
      "id": "performance-evaluations",
      "name": "Annual performance evaluations",
      "description": "The company evaluates the performance of all employees through a formal, annual performance evaluation.",
      "categories": ["Administrative"],
      "tests": []
    },
    {
      "id": "physical-security",
      "name": "Physical security",
      "description": "%COMPANY has security policies that have been approved by management and detail how physical security for the company's headquarters is maintained. These policies are accessible to all employees and contractors.",
      "categories": ["Physical"],
      "tests": [
        {
          "id": "approved-physical-security-policy-bsi-exists"
        },
        {
          "id": "physical-security-policies"
        }
      ]
    },
    {
      "id": "privacy-policy",
      "name": "Company has a Privacy Policy",
      "description": "%COMPANY maintains a Privacy Policy that is available to all external users and internal employees, and it details the company's confidentiality and privacy commitments.",
      "categories": ["Administrative"],
      "tests": []
    },
    {
      "id": "private-subnets",
      "name": "Private subnets",
      "description": "Company infrastructure allows for only approved networking ports and protocols, including private subnets to restrict information flow between different networks.",
      "categories": ["Administrative"],
      "tests": []
    },
    {
      "id": "process-for-install-os-updates-and-patches-servers",
      "name": "Process for installing OS updates and patches on production servers",
      "description": "%COMPANY has a standard process for installing OS updates and patches on production servers.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "process-for-install-os-updates-and-patches-config-automation"
        },
        {
          "id": "process-for-install-os-updates-and-patches-config-month"
        }
      ]
    },
    {
      "id": "process-for-installing-os-updates",
      "name": "Process for installing OS updates",
      "description": "The company has a process for installing OS updates on all company-issued laptops.",
      "categories": ["Administrative"],
      "tests": []
    },
    {
      "id": "publicly-available-commitments",
      "name": "Publicly-available commitments",
      "description": "%COMPANY's commitments to security, availability, processing integrity, and confidentiality are communicated to external users via publications such as the %COMPANY Apps Terms of Service",
      "categories": ["Administrative"],
      "tests": []
    },
    {
      "id": "recruiting-process",
      "name": "Formal recruiting process",
      "description": "New hires or internal transfers are required to go through an official recruiting process during which their qualifications and experience are screened to ensure that they are competent to fulfill their responsibilities.",
      "categories": ["Administrative"],
      "tests": []
    },
    {
      "id": "require-admin-encrypted",
      "name": "Require encryption of web-based admin access",
      "description": "Encryption is used to protect user authentication and administrator sessions of the internal admin tool transmitted over the Internet.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "require-admin-encrypted-iaas"
        },
        {
          "id": "require-admin-encrypted-iaas-gcp"
        },
        {
          "id": "require-admin-encrypted-iaas-heroku"
        }
      ]
    },
    {
      "id": "require-ssh",
      "name": "Require SSH",
      "description": "Only authorized users with the correct SSH key may gain access to production machines.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "require-ssh-config"
        }
      ]
    },
    {
      "id": "require-ssh-mfa",
      "name": "SSH authentication requires MFA",
      "description": "Access to SSH requires two factor authentication in the form of user ID, password, OTP and/or certificate.",
      "categories": ["Administrative"],
      "tests": []
    },
    {
      "id": "responsibilities-external",
      "name": "External users understand their responsibilities when using the system",
      "description": "The responsibilities of external users of %COMPANY systems are available to authorized external users and customers.",
      "categories": ["Administrative"],
      "tests": []
    },
    {
      "id": "responsible-disclosure-policy",
      "name": "Responsible Disclosure Policy",
      "description": "%COMPANY has an established responsible disclosure policy that outlines management responsibilities and procedures to ensure the quick, effective, and orderly disclosure of information security incidents.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "responsible-disclosure-policy-policies"
        }
      ]
    },
    {
      "id": "risk-management-framework",
      "name": "Risk management framework",
      "description": "At least annually, %COMPANY performs a formal risk assessment, which includes the identification of relevant internal and external threats, an analysis of risks associated with those threats, a determination of appropriate risk mitigation strategies, and the development or modification of controls consistent with the risk mitigation strategy.",
      "categories": ["Administrative"],
      "tests": []
    },
    {
      "id": "risk-management-framework-updated",
      "name": "Risk management framework updated",
      "description": "A formal review of the risk management framework is performed at least annually to ensure management has up-to-date information with which to prioritize risks and mitigations. Mitigation strategies are documented and tracked to resolution.",
      "categories": ["Administrative"],
      "tests": []
    },
    {
      "id": "screensaver-lock",
      "name": "Login password",
      "description": "Company management ensures that all company-issued laptops use a screensaver lock with a timeout of no more than 60 seconds.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "screensaver-lock-policies"
        }
      ]
    },
    {
      "id": "secure-data-delete-processes",
      "name": "Secure data delete processes",
      "description": "%COMPANY has policies in place that govern the secure data delete process. These policies are made available to employees and contractors.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "secure-data-delete-policies"
        }
      ]
    },
    {
      "id": "security-issues-prioritized",
      "name": "Security issues prioritized",
      "description": "Security deficiencies tracked through internal tools are prioritized according to their severity by an independent technical resource.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "security-issues-prioritized-records"
        }
      ]
    },
    {
      "id": "security-issues-tracked",
      "name": "Security issues tracked",
      "description": "Remediation of security deficiencies are tracked through internal tools.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "security-issues-tracked-records"
        }
      ]
    },
    {
      "id": "security-policies",
      "name": "Security policies",
      "description": "Management has approved %COMPANY security policies, and all employees agree to these procedures when hired. Management also ensures that security policies are accessible to all employees and contractors.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "approved-human-resource-security-policy-bsi-exists"
        },
        {
          "id": "approved-incident-response-plan-bsi-exists"
        },
        {
          "id": "approved-information-security-policy-bsi-exists"
        },
        {
          "id": "approved-information-security-roles-and-responsibilities-bsi-exists"
        },
        {
          "id": "employees-accepted-access-control-policy-bsi"
        },
        {
          "id": "employees-accepted-human-resource-security-policy-bsi"
        },
        {
          "id": "employees-accepted-incident-response-plan-bsi"
        },
        {
          "id": "employees-accepted-information-security-policy-bsi"
        },
        {
          "id": "employees-accepted-information-security-roles-and-responsibilities-bsi"
        },
        {
          "id": "security-policies-accepted"
        }
      ]
    },
    {
      "id": "security-policies-reviewed-reviewed",
      "name": "Security policies reviewed",
      "description": "Security policies are reviewed at least annually. Policies, procedures and guidelines are created/updated as needed.",
      "categories": ["Administrative"],
      "tests": []
    },
    {
      "id": "security-team",
      "name": "Security team",
      "description": "%COMPANY has an assigned security team that is responsible for the design, implementation, management, and review of the organization’s security policies, standards, baselines, procedures, and guidelines.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "security-team-policies"
        }
      ]
    },
    {
      "id": "security-team-has-communication-channel-to-the-CEO",
      "name": "Security team has communication channel to the CEO",
      "description": "The security team communicates important information security events to company management in a timely manner.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "security-team-has-communication-channel-to-the-CEO-policies"
        }
      ]
    },
    {
      "id": "security-training",
      "name": "Security training",
      "description": "%COMPANY has established training programs for privacy and information security to help employees understand their obligations and responsibilities to comply with the Company’s security policies and procedures, including the identification and reporting of incidents. All full-time employees are required to complete these trainings annually.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "security-training-policies"
        },
        {
          "id": "security-training-records"
        },
        {
          "id": "security-training-tracked-records"
        }
      ]
    },
    {
      "id": "segregation-of-duties",
      "name": "Maintain segregation of duties between development and production environments",
      "description": "System users who make changes to the development system are unable to deploy those changes to production without independent approval. All deployments are logged, including who deployed the change and at what time it was deployed.",
      "categories": ["Technical"],
      "tests": []
    },
    {
      "id": "server-continuous-availability",
      "name": "Resources distributed across geographically-dispersed data centers",
      "description": "Redundant architecture exists such that resources are distributed across geographically dispersed data centers to support continuous availability.",
      "categories": ["Technical"],
      "tests": []
    },
    {
      "id": "server-packages-checked-for-vulnerabilities",
      "name": "Server packages checked for vulnerabilities",
      "description": "%COMPANY has implemented a vulnerability management program to detect and remediate system vulnerabilities in software packages used on company servers.",
      "categories": ["Administrative"],
      "tests": []
    },
    {
      "id": "servers-monitored-and-alarmed",
      "name": "Servers monitored and alarmed",
      "description": "Management has implemented tools to monitor %COMPANY servers and notify appropriate personnel of any events or incidents based on predetermined criteria. Incidents are escalated per policy.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "servers-monitored-and-alarmed-config-cpu"
        },
        {
          "id": "servers-monitored-and-alarmed-config-cpu-azure"
        },
        {
          "id": "servers-monitored-and-alarmed-config-cpu-azure-vm-scaleset"
        },
        {
          "id": "servers-monitored-and-alarmed-config-cpu-gcp"
        }
      ]
    },
    {
      "id": "sla-for-security-bugs",
      "name": "SLA for security bugs",
      "description": "Security deficiencies tracked through internal tools are closed within an SLA that management has pre-specified.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "security-issues-assigned-records"
        },
        {
          "id": "sla-for-security-bugs-policies"
        },
        {
          "id": "sla-for-security-bugs-records"
        }
      ]
    },
    {
      "id": "sqs-queues-monitored-and-alarmed",
      "name": "Message queues monitored and alarmed",
      "description": "Management has implemented tools to monitor %COMPANY messaging queues and notify appropriate personnel of any events or incidents based on predetermined criteria. Incidents are escalated per policy.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "pubsub-subscriptions-monitored-and-alarmed-config-age"
        },
        {
          "id": "sqs-queues-monitored-and-alarmed-config-age"
        }
      ]
    },
    {
      "id": "ssl-tls-private-keys",
      "name": "SSL/TLS private keys protected and recognized",
      "description": "%COMPANY has an established key management process in place to support the protection and use of SSL/TLS private keys.",
      "categories": ["Administrative"],
      "tests": []
    },
    {
      "id": "ssl-used",
      "name": "SSL used",
      "description": "The company ensures that all connections to its web application from its users are encrypted.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "ssl-good-ciphers"
        },
        {
          "id": "ssl-no-warnings"
        },
        {
          "id": "ssl-used-expiry"
        },
        {
          "id": "ssl-used-unittest"
        }
      ]
    },
    {
      "id": "sso",
      "name": "SSO",
      "description": "The company's HR team provisions new-user accounts for new hires through a Single Sign-On (SSO) tool.",
      "categories": ["Administrative"],
      "tests": []
    },
    {
      "id": "staff-changes",
      "name": "Staff changes announced",
      "description": "Major changes to roles and responsibilities and changes to key personnel are communicated to affected internal and external users as part of the change management process.",
      "categories": ["Administrative"],
      "tests": []
    },
    {
      "id": "standard-server-config",
      "name": "Standard server config",
      "description": "The company uses a tool that enforces the use of standard production images for %COMPANY servers.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "standard-server-config-config"
        },
        {
          "id": "standard-server-config-updates"
        }
      ]
    },
    {
      "id": "storage-buckets-are-versioned",
      "name": "Storage buckets are versioned",
      "description": "Storage buckets that contain customer data are versioned.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "blob-containers-are-versioned-config-azure"
        },
        {
          "id": "storage-buckets-are-versioned-config"
        },
        {
          "id": "storage-buckets-are-versioned-config-gcp"
        }
      ]
    },
    {
      "id": "terms-of-service",
      "name": "Company has a Terms of Service",
      "description": "%COMPANY maintains a Terms of Service that is available to all external users and internal employees, and the terms detail the company's security and availability commitments regarding the systems. Where the Terms of Service may not apply, the company has Client Agreements or Master Service Agreements in place.",
      "categories": ["Administrative"],
      "tests": []
    },
    {
      "id": "third-party-security-audit",
      "name": "Third-party security audit",
      "description": "%COMPANY engages third-parties to conduct security audits of the production environment at least annually. Results are reviewed by management and high priority findings are tracked to resolution.",
      "categories": ["Administrative"],
      "tests": []
    },
    {
      "id": "unique-ssh",
      "name": "Unique SSH",
      "description": "SSH users use unique accounts to access to production machines. Furthermore, the use of the `root` account is not used.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "unique-ssh-keys"
        }
      ]
    },
    {
      "id": "version-control-tool",
      "name": "Version control tool",
      "description": "%COMPANY uses a version control system to manage source code, documentation, release labeling, and other change management tasks. Access to the system must be approved by a system administrator.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "version-control-tool-records"
        }
      ]
    },
    {
      "id": "vulnerability-management-program",
      "name": "Vulnerability management program",
      "description": "%COMPANY has implemented a vulnerability management program to detect and fix system vulnerabilities.",
      "categories": ["Administrative"],
      "tests": []
    },
    {
      "id": "wifi-encrypted",
      "name": "Wireless networks implement strong encryption",
      "description": "The company's corporate wireless network encrypts traffic using strong encryption, in line with the company's Cryptography Policy.",
      "categories": ["Administrative"],
      "tests": []
    },
    {
      "id": "wifi-password",
      "name": "Wireless networks require a password that was not the default password",
      "description": "The company's corporate wireless network authenticates access only when given valid credentials. The network's password has been changed from the default password.",
      "categories": ["Administrative"],
      "tests": []
    },
    {
      "id": "wifi-unprivileged",
      "name": "Wifi access grants no extra privileges",
      "description": "The company has configured its corporate wireless network so that it grants no privileges or authentication to corporate resources.",
      "categories": ["Administrative"],
      "tests": []
    }
  ],
  "sections": [
    {
      "id": "V 1.1",
      "controls": [
        {
          "id": "customer-data-policies"
        },
        {
          "id": "least-privileged-policy"
        }
      ]
    },
    {
      "id": "V 1.2",
      "controls": [
        {
          "id": "customer-data-database"
        }
      ]
    },
    {
      "id": "V 1.3",
      "controls": [
        {
          "id": "admin-access-logged"
        },
        {
          "id": "admin-access-requires-approval"
        },
        {
          "id": "admin-multi-factor-authentication"
        },
        {
          "id": "require-admin-encrypted"
        }
      ]
    },
    {
      "id": "V 1.4",
      "controls": []
    },
    {
      "id": "V 1.5",
      "controls": [
        {
          "id": "secure-data-delete-processes"
        }
      ]
    },
    {
      "id": "V 1.6",
      "controls": []
    },
    {
      "id": "V 1.7",
      "controls": [
        {
          "id": "external-service-provider-approval"
        }
      ]
    },
    {
      "id": "V 2.1",
      "controls": [
        {
          "id": "code-review"
        },
        {
          "id": "segregation-of-duties"
        },
        {
          "id": "version-control-tool"
        }
      ]
    },
    {
      "id": "V 2.2",
      "controls": [
        {
          "id": "app-changes-tested-before-deploys"
        },
        {
          "id": "network-configuration-changes-tested-before-deploys"
        }
      ]
    },
    {
      "id": "V 2.3",
      "controls": [
        {
          "id": "management-reviews-risks"
        },
        {
          "id": "risk-management-framework"
        },
        {
          "id": "risk-management-framework-updated"
        }
      ]
    },
    {
      "id": "V 2.4",
      "controls": [
        {
          "id": "customer-disclosure-process"
        },
        {
          "id": "employee-disclosure-process"
        },
        {
          "id": "responsible-disclosure-policy"
        }
      ]
    },
    {
      "id": "V 2.5",
      "controls": [
        {
          "id": "bug-bounty-program"
        },
        {
          "id": "client-packages-checked-for-vulnerabilities"
        },
        {
          "id": "infra-checked-for-vulnerabilities-external"
        },
        {
          "id": "infra-packages-checked-for-vulnerabilities"
        },
        {
          "id": "network-diagram"
        },
        {
          "id": "penetration-tests"
        },
        {
          "id": "server-packages-checked-for-vulnerabilities"
        },
        {
          "id": "third-party-security-audit"
        },
        {
          "id": "vulnerability-management-program"
        }
      ]
    },
    {
      "id": "V 2.6",
      "controls": [
        {
          "id": "security-issues-prioritized"
        },
        {
          "id": "security-issues-tracked"
        },
        {
          "id": "sla-for-security-bugs"
        }
      ]
    },
    {
      "id": "V 2.7",
      "controls": [
        {
          "id": "incident-response-follow-ups"
        },
        {
          "id": "incident-response-lessons-learned"
        },
        {
          "id": "incident-response-plan"
        },
        {
          "id": "incident-response-team"
        }
      ]
    },
    {
      "id": "V 3.1",
      "controls": [
        {
          "id": "change-management-policy"
        },
        {
          "id": "nda-used"
        },
        {
          "id": "security-policies"
        },
        {
          "id": "security-policies-reviewed-reviewed"
        }
      ]
    },
    {
      "id": "V 3.2",
      "controls": [
        {
          "id": "security-team"
        },
        {
          "id": "security-team-has-communication-channel-to-the-CEO"
        },
        {
          "id": "security-training"
        }
      ]
    },
    {
      "id": "V 3.3",
      "controls": [
        {
          "id": "acceptable-use-policy"
        },
        {
          "id": "background-checks"
        },
        {
          "id": "code-of-conduct"
        },
        {
          "id": "company-goals"
        },
        {
          "id": "data-protection-policy"
        },
        {
          "id": "job-descriptions"
        },
        {
          "id": "performance-evaluations"
        },
        {
          "id": "recruiting-process"
        }
      ]
    },
    {
      "id": "V 3.4",
      "controls": []
    },
    {
      "id": "V 3.5",
      "controls": [
        {
          "id": "antivirus"
        },
        {
          "id": "employee-laptops"
        },
        {
          "id": "firewall-endpoint"
        },
        {
          "id": "full-disk-encryption"
        },
        {
          "id": "location-services"
        },
        {
          "id": "password-manager"
        },
        {
          "id": "process-for-installing-os-updates"
        },
        {
          "id": "screensaver-lock"
        }
      ]
    },
    {
      "id": "V 4.1",
      "controls": [
        {
          "id": "customer-password-policy"
        },
        {
          "id": "multi-factor-authentication-offered-to-customers"
        },
        {
          "id": "passwords-hashed-and-salted"
        }
      ]
    },
    {
      "id": "V 4.2",
      "controls": [
        {
          "id": "cryptography-policies"
        },
        {
          "id": "customer-data-encrypted-at-rest"
        },
        {
          "id": "ssl-tls-private-keys"
        },
        {
          "id": "ssl-used"
        }
      ]
    },
    {
      "id": "V 4.3",
      "controls": [
        {
          "id": "commitments-external"
        },
        {
          "id": "incidents-reported-within-twenty-four-hours"
        },
        {
          "id": "privacy-policy"
        },
        {
          "id": "responsibilities-external"
        },
        {
          "id": "terms-of-service"
        }
      ]
    },
    {
      "id": "V 5.1",
      "controls": [
        {
          "id": "access-granted"
        },
        {
          "id": "access-removed"
        },
        {
          "id": "infra-access-requires-approval"
        },
        {
          "id": "infra-unique-accounts"
        },
        {
          "id": "internal-password-policy"
        },
        {
          "id": "mfa-on-accounts"
        },
        {
          "id": "require-ssh"
        },
        {
          "id": "require-ssh-mfa"
        },
        {
          "id": "sso"
        },
        {
          "id": "unique-ssh"
        }
      ]
    },
    {
      "id": "V 5.2",
      "controls": [
        {
          "id": "customer-monitoring-changes"
        },
        {
          "id": "ddos-protection"
        },
        {
          "id": "load-balancer"
        },
        {
          "id": "publicly-available-commitments"
        },
        {
          "id": "server-continuous-availability"
        },
        {
          "id": "staff-changes"
        }
      ]
    },
    {
      "id": "V 5.3",
      "controls": [
        {
          "id": "daily-database-backups"
        },
        {
          "id": "logging-buckets-are-versioned"
        },
        {
          "id": "logging-buckets-have-MFA-delete"
        },
        {
          "id": "storage-buckets-are-versioned"
        }
      ]
    },
    {
      "id": "V 5.4",
      "controls": [
        {
          "id": "flow-logs-on"
        },
        {
          "id": "infra-activity-tracked"
        },
        {
          "id": "log-permissions"
        },
        {
          "id": "logging-enabled"
        },
        {
          "id": "logs-alerting"
        },
        {
          "id": "logs-centrally-stored"
        },
        {
          "id": "logs-retained-for-twelve-months"
        }
      ]
    },
    {
      "id": "V 5.5",
      "controls": [
        {
          "id": "file-integrity-monitoring"
        },
        {
          "id": "intrusion-detection-service"
        },
        {
          "id": "load-balancers-monitored-and-alarmed"
        },
        {
          "id": "mysql-database-monitored-and-alarmed"
        },
        {
          "id": "nosql-database-monitored-and-alarmed"
        },
        {
          "id": "servers-monitored-and-alarmed"
        },
        {
          "id": "sqs-queues-monitored-and-alarmed"
        }
      ]
    },
    {
      "id": "V 5.6",
      "controls": [
        {
          "id": "corp-resources-protected"
        },
        {
          "id": "corp-resources-protected-individual-accounts"
        },
        {
          "id": "corp-resources-protected-multi-factor-authentication"
        },
        {
          "id": "corp-resources-protected-production"
        },
        {
          "id": "firewalls"
        },
        {
          "id": "private-subnets"
        }
      ]
    },
    {
      "id": "V 5.7",
      "controls": [
        {
          "id": "app-secrets-encrypted-decrypted"
        },
        {
          "id": "app-secrets-stored-securely"
        },
        {
          "id": "credential-keys-managed"
        }
      ]
    },
    {
      "id": "V 5.8",
      "controls": [
        {
          "id": "process-for-install-os-updates-and-patches-servers"
        },
        {
          "id": "standard-server-config"
        }
      ]
    },
    {
      "id": "V 6.1",
      "controls": [
        {
          "id": "physical-security"
        }
      ]
    },
    {
      "id": "V 6.2",
      "controls": [
        {
          "id": "building-alarms"
        },
        {
          "id": "building-cameras"
        },
        {
          "id": "key-needed"
        },
        {
          "id": "wifi-encrypted"
        },
        {
          "id": "wifi-password"
        },
        {
          "id": "wifi-unprivileged"
        }
      ]
    }
  ]
}
