import { Spinner, SpinnerSize } from "@blueprintjs/core";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import React from "react";

import type { GetUserInfoForOnboardingTabQuery } from "../../../../../gen/components";
import { useGetUserInfoForOnboardingTabQuery } from "../../../../../gen/components";
import { AdminRoleTaskList, EmployeeRoleTaskList } from "./role-task-lists";
import { SecurityRequirementsList } from "./security-requirements-list";
import { TaskCompletionHistory } from "./task-completion-history";

export type OnboardingTabUser = NonNullable<
  GetUserInfoForOnboardingTabQuery["user"]
>;

interface IProps {
  userId: string;
}

export const OnboardingTab: React.FC<IProps> = ({ userId }) => {
  const { loading, data } = useGetUserInfoForOnboardingTabQuery({
    variables: { userId },
  });
  if (loading || !isSome(data)) {
    return <Spinner size={SpinnerSize.LARGE} />;
  }
  const user = data.user;

  if (!isSome(user)) {
    return <div>No user found.</div>;
  }

  return (
    <>
      <SecurityRequirementsList user={user} />
      <EmployeeRoleTaskList user={user} />
      <AdminRoleTaskList user={user} />
      <TaskCompletionHistory user={user} />
    </>
  );
};

gql`
  query getUserInfoForOnboardingTab($userId: ID!) {
    user(id: $userId) {
      id
      backgroundChecks {
        id
        completedAt
        status
      }
      displayName
      domain {
        id
        onboardingSla
        policies {
          id
        }
        productDescriptionInfo {
          id
          ...SatPreferences
        }
        externalSATIntegrations: credentials(services: ["knowbe4"]) {
          id
        }
      }
      email
      familyName
      givenName
      hasCompletedAnnualSecurityTraining
      hasCompletedAnnualHipaaTraining
      hasCompletedBackgroundCheck
      hasCompletedSecurityTraining
      hasAcceptedAllSecurityPolicies
      isActive
      isFromScan
      isNotHuman
      mostRecentGdprSecurityTraining {
        ...OnboardingListTrainingCompletion
      }
      mostRecentHipaaSecurityTraining {
        ...OnboardingListTrainingCompletion
      }
      mostRecentPciSecurityTraining {
        ...OnboardingListTrainingCompletion
      }
      mostRecentSecurityTraining {
        ...OnboardingListTrainingCompletion
      }
      endpointState
      inOnboardingSla
      onboardingCompletionDate
      securityPolicyAcceptances {
        id
        createdAt
      }
      securityRequirements {
        ...SecurityRequirementsMap
      }
      securityTrainingCompletions {
        __typename
        category
        completionDate
        ... on securityTrainingDocumentCompletion {
          uploadedDocument {
            id
            url
          }
        }
      }
      trainingRequirements {
        id
        externalURL
        displayName
        completionDate
        service
        vantaAttributes {
          key
          value
          managedExternally
        }
      }
      role {
        id
        name
      }
      roleCompletionRecord {
        id
        name
        adminCompletionDate
        employeeCompletionDate
        adminTaskCompletions {
          id
          ...completionFields
        }
        employeeTaskCompletions {
          id
          ...completionFields
        }
      }
      hrUser {
        service
      }
      startDate
      workstations {
        id
        createdAt
      }
    }
  }

  fragment OnboardingListTrainingCompletion on securityTrainingCompletion {
    __typename
    category
    completionDate
    ... on securityTrainingDocumentCompletion {
      uploadedDocument {
        id
        url
      }
    }
  }

  fragment completionFields on roleTaskCompletionRecord {
    id
    completionDate
    responseText
    task {
      id
      ...taskFields
    }
    file {
      id
      url
      filename
    }
    approver {
      id
      displayName
    }
  }

  fragment taskFields on roleTask {
    id
    textInputLabel
    fileInputLabel
    instructions
    title
    type
  }
`;
