import { dateStringToDate, toPossibleDate } from "common/base/dateUtils";
import { dropNothing, isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import React from "react";

import { LogError, LogErrorMessage } from "../../../errors";
import type { FetchAgentVulnerabilitiesForFailDataQuery } from "../../../gen/components";
import { useFetchAgentVulnerabilitiesForFailDataQuery } from "../../../gen/components";
import { vulnToTitle } from "../../pages/vulns/utils";
import type { ISLAMissRow } from "../fail-data-from-sla-miss";
import { FailDataFromSLAMiss } from "../fail-data-from-sla-miss";

export const OsqueryVulnerabilitySLATestEntities: React.FC<{
  entityType: "OsqueryVulnerability";
  entityIds: string[];
  testId: string;
  allowWhitelisting: boolean;
  renderingIndividually: boolean;
  first: number;
}> = ({
  entityType,
  entityIds,
  testId,
  allowWhitelisting,
  renderingIndividually,
  first,
}) => {
  const { error, loading, data } = useFetchAgentVulnerabilitiesForFailDataQuery(
    {
      variables: { ids: entityIds },
    }
  );
  if (error) {
    LogError(error);
    return <div />;
  }
  if (loading) {
    return <div />;
  }
  if (!data?.organization) {
    LogErrorMessage("Bad fetch");
    return <div />;
  }
  return (
    <FailDataFromVulnerabilityInternal
      testId={testId}
      vulnerabilityHistory={data.organization.osqueryVulnerabilities}
      allowWhitelisting={allowWhitelisting}
      renderingIndividually={renderingIndividually}
      first={first}
    />
  );
};

interface IInternalProps {
  vulnerabilityHistory: NonNullable<
    FetchAgentVulnerabilitiesForFailDataQuery["organization"]
  >["osqueryVulnerabilities"];
  testId: string;
  allowWhitelisting: boolean;
  renderingIndividually: boolean;
  first: number;
}

class FailDataFromVulnerabilityInternal extends React.Component<IInternalProps> {
  public constructor(props: IInternalProps) {
    super(props);
    this.state = {};

    this.renderVulnerability = this.renderVulnerability.bind(this);
  }

  public render() {
    const openVulnerabilities: IInternalProps["vulnerabilityHistory"] = [];
    const closedVulnerabilities: IInternalProps["vulnerabilityHistory"] = [];

    dropNothing(this.props.vulnerabilityHistory).forEach(vh => {
      if (isSome(vh.resolvedAt)) {
        closedVulnerabilities.push(vh);
      } else {
        openVulnerabilities.push(vh);
      }
    });

    const openItems: ISLAMissRow[] = openVulnerabilities
      .map(v => this.renderVulnerability(v))
      .flat();

    const closedItems: ISLAMissRow[] = closedVulnerabilities
      .map(v => this.renderVulnerability(v))
      .flat();

    return (
      <FailDataFromSLAMiss
        header={
          this.props.renderingIndividually ? undefined : "Vulnerabilities"
        }
        testId={this.props.testId}
        openItems={openItems}
        closedItems={closedItems}
        type="vulnerability"
        allowWhitelisting={this.props.allowWhitelisting}
        first={this.props.first}
      />
    );
  }

  // map a db vuln entry (package + version to upgrade to + open/close dates)
  // to UI vuln entries (same package and version, plus affected machines + title)
  private renderVulnerability(
    osqueryVulnerability: IInternalProps["vulnerabilityHistory"][number]
  ) {
    const prettyName = osqueryVulnerability.osquery?.prettyName;

    return {
      element: (
        <span>
          {vulnToTitle(osqueryVulnerability)}
          {` on ${prettyName ?? "unknown"}`}
        </span>
      ),
      entity: {
        entityId: osqueryVulnerability.id,
        entityType: "OsqueryVulnerability",
      },
      openDate: dateStringToDate(osqueryVulnerability.createdAt),
      closeDate: toPossibleDate(osqueryVulnerability.resolvedAt),
    };
  }
}

gql`
  query fetchAgentVulnerabilitiesForFailData($ids: [String!]!) {
    organization {
      id
      osqueryVulnerabilities(ids: $ids) {
        id
        packageName
        packageVersion
        os
        securityVersion
        createdAt
        resolvedAt
        osquery {
          id
          prettyName
        }
      }
    }
  }
`;
