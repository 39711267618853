import {
  Button,
  FormGroup,
  InputGroup,
  Intent,
  MenuItem,
  Spinner,
} from "@blueprintjs/core";
import { Suggest } from "@blueprintjs/select";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import React, { useEffect, useState } from "react";

import { LogError, LogErrorMessage } from "../../errors";
import type { AuditFirmsToSuggestQuery } from "../../gen/components";
import {
  useAuditFirmsToSuggestQuery,
  useMakeUserAuditorMutation,
} from "../../gen/components";
import { AppToaster } from "../../helpers/toaster";

type AuditFirmInfo = NonNullable<
  NonNullable<AuditFirmsToSuggestQuery["allAuditFirms"]>[number]
> & { new?: Maybe<boolean> };

const TypedSuggest = Suggest.ofType<AuditFirmInfo>();

const AuditFirmInfosSuggest: React.FC<{
  auditFirmInfoId?: Maybe<string>;
  onSelect: (auditFirmInfo: AuditFirmInfo) => void;
  submittedAt?: Maybe<Date>;
}> = ({ auditFirmInfoId, onSelect, submittedAt }) => {
  const [newAuditFirmInfo, setNewAuditFirmInfo] =
    useState<Maybe<AuditFirmInfo>>(null);
  const { data, error, loading, refetch } = useAuditFirmsToSuggestQuery();

  useEffect(() => {
    setNewAuditFirmInfo(null);
    refetch().catch(e => LogError(e));
  }, [submittedAt]);

  if (error) {
    LogError(error);
    return null;
  }

  if (loading) {
    return <Spinner />;
  }

  if (!data?.allAuditFirms) {
    LogErrorMessage("Bad fetch for auditFirmsToSuggest");
    return null;
  }

  const firmInfos = isSome(newAuditFirmInfo)
    ? [...data.allAuditFirms, newAuditFirmInfo]
    : data.allAuditFirms;

  return (
    <TypedSuggest
      createNewItemFromQuery={query => {
        return {
          domain: {
            id: "NEW_AUDIT_FIRM",
          },
          firmName: query,
          id: "NEW_AUDIT_FIRM",
          new: true,
        };
      }}
      createNewItemRenderer={(query, active, handleClick) => (
        <MenuItem onClick={handleClick} text={`Create "${query}"`}></MenuItem>
      )}
      closeOnSelect
      fill={true}
      inputValueRenderer={info => info.firmName}
      itemPredicate={(query, info) =>
        info.firmName.toLowerCase().includes(query.toLowerCase())
      }
      itemRenderer={(info, { handleClick, modifiers }) => (
        <MenuItem
          key={info.id}
          onClick={handleClick}
          text={info.firmName}
          active={modifiers.active}
        />
      )}
      items={firmInfos}
      itemsEqual={(a, b) => a.id === b.id}
      onItemSelect={info => {
        onSelect(info);
        if (info.new) setNewAuditFirmInfo(info);
      }}
      popoverProps={{ minimal: true }}
      resetOnQuery
      selectedItem={
        isSome(auditFirmInfoId)
          ? firmInfos.find(info => info.id === auditFirmInfoId)
          : null
      }
    ></TypedSuggest>
  );
};

export const NewAuditorPanel: React.FC = () => {
  const [auditFirmInfo, setAuditFirmInfo] =
    useState<Maybe<AuditFirmInfo>>(null);
  const [displayName, setDisplayName] = useState<Maybe<string>>(null);
  const [email, setEmail] = useState<Maybe<string>>(null);
  const [submittedAt, setSubmittedAt] = useState<Maybe<Date>>(null);

  const [makeUserAuditor] = useMakeUserAuditorMutation({
    onCompleted: () => {
      setSubmittedAt(new Date());
      AppToaster.show({
        message: `Created new auditor ${displayName} ${email} ${auditFirmInfo?.firmName}.`,
        intent: Intent.SUCCESS,
      });
    },
  });

  return (
    <div>
      <FormGroup
        label="Email address"
        labelFor="text-input"
        labelInfo="(required)"
      >
        <InputGroup
          autoCapitalize="off"
          autoComplete="off"
          autoCorrect="off"
          autoFocus={false}
          name="email"
          placeholder="email"
          required={true}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setEmail(e.currentTarget.value)
          }
          type="text"
        />
      </FormGroup>
      <FormGroup
        label="Auditor fullname"
        labelFor="text-input"
        labelInfo="(required)"
      >
        <InputGroup
          autoCapitalize="off"
          autoComplete="off"
          autoCorrect="off"
          autoFocus={false}
          name="fullname"
          placeholder="full name"
          required={true}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setDisplayName(e.currentTarget.value)
          }
          type="text"
        />
      </FormGroup>
      <FormGroup label="Firm name" labelFor="text-input" labelInfo="(required)">
        <AuditFirmInfosSuggest
          auditFirmInfoId={auditFirmInfo?.id}
          onSelect={setAuditFirmInfo}
          submittedAt={submittedAt}
        ></AuditFirmInfosSuggest>
      </FormGroup>
      <Button
        intent={Intent.PRIMARY}
        onClick={() => {
          if (isSome(email) && isSome(displayName) && isSome(auditFirmInfo)) {
            makeUserAuditor({
              variables: {
                email,
                displayName,
                ...(auditFirmInfo.new
                  ? { maybeFirmName: auditFirmInfo.firmName }
                  : { domainId: auditFirmInfo.domain.id }),
              },
            }).catch(e => {
              AppToaster.show({
                message: e.message,
                intent: Intent.WARNING,
              });
            });
          } else {
            AppToaster.show({
              message: "Please fill all required fields",
              intent: Intent.WARNING,
            });
          }
        }}
      >
        Submit
      </Button>
    </div>
  );
};

gql`
  query auditFirmsToSuggest {
    allAuditFirms {
      domain {
        id
      }
      firmName
      id
    }
  }

  mutation makeUserAuditor(
    $displayName: String!
    $email: String!
    $domainId: ID
    $maybeFirmName: String
  ) {
    makeUserAuditor(
      displayName: $displayName
      domainId: $domainId
      email: $email
      maybeFirmName: $maybeFirmName
    )
  }
`;
