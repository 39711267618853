import { Callout, Intent } from "@blueprintjs/core";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import React from "react";

import { LogError } from "../../../errors";
import { useFetchGithubMetadataQuery } from "../../../gen/components";
import { DefaultLink } from "../../../helpers/links";

export const GithubRepoCallout: React.FC = () => {
  const { data, loading, error } = useFetchGithubMetadataQuery();
  if (error) {
    LogError(error);
    return null;
  }

  if (loading) {
    return null;
  }

  if (!data) {
    LogError(new Error("No data for GitHub repo callout"));
    return null;
  }

  if (
    data.organization.credentials.length !== 1 ||
    !isSome(data.organization.credentials[0].metadata)
  ) {
    return null;
  }

  const parsedMetadata = JSON.parse(data.organization.credentials[0].metadata);

  if (isSome(parsedMetadata.scanAll) && !parsedMetadata.scanAll) {
    const installationLink = `https://github.com/organizations/${parsedMetadata.organization}/settings/installations/${parsedMetadata.installationId}`;

    return (
      <Callout
        intent={Intent.PRIMARY}
        title={"Why don't I see my GitHub repo?"}
      >
        Vanta is currently monitoring the GitHub repositories listed below. If
        there is a GitHub repository that you would like us to monitor and it is
        not listed below, please update this in the{" "}
        <DefaultLink
          href={installationLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          app settings.
        </DefaultLink>
      </Callout>
    );
  }

  return null;
};

gql`
  query fetchGithubMetadata {
    organization {
      id
      credentials(services: ["github"]) {
        id
        metadata
      }
    }
  }
`;
