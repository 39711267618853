import { useApolloClient } from "@apollo/client";
import { isSome } from "common/base/types/maybe";
import React, { useState } from "react";
import { useHistory } from "react-router";

import { BASE_PALETTE } from "../../../alpaca/base/colors";
import { GRID_SPACING } from "../../../alpaca/base/grid";
import { Button, Icon, IconNames } from "../../../alpaca/components";
import type { FetchDataForPeoplePageQuery } from "../../../gen/components";
import { FetchDataForPeoplePageDocument } from "../../../gen/components";
import { CreateUserDialog } from "../people/create-user-dialog";

export const AddPersonButton: React.FC = () => {
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const client = useApolloClient();
  const history = useHistory();

  return (
    <>
      <Button onClick={() => setDialogIsOpen(true)}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Icon
            icon={IconNames.PERSON_ALERT}
            color={BASE_PALETTE.INK}
            style={{ marginRight: `${GRID_SPACING}px` }}
          />
          <span>Add a person</span>
        </div>
      </Button>
      <CreateUserDialog
        isOpen={dialogIsOpen}
        onClose={user => {
          if (isSome(user)) {
            const allUserData = client.readQuery<FetchDataForPeoplePageQuery>({
              query: FetchDataForPeoplePageDocument,
            })!.organization!;
            client.writeQuery<FetchDataForPeoplePageQuery>({
              query: FetchDataForPeoplePageDocument,
              data: {
                organization: {
                  ...allUserData,
                  users: allUserData.users.concat(user),
                },
              },
            });
            history.push(`${window.location.pathname}?userId=${user.id}`);
          }
          setDialogIsOpen(false);
        }}
      />
    </>
  );
};
