import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import React, { useContext } from "react";

import { LogError, LogErrorMessage } from "../../../../errors";
import type { GetAuditorDocumentsQuery } from "../../../../gen/components";
import {
  ReportStandard,
  useEditWorkPaperMutation,
  useGetComplianceStandardQuery,
  useGetWorkPaperContentsQuery,
} from "../../../../gen/components";
import { FullPageSpinner } from "../../../helpers/FullPageSpinner";
import { UserContext } from "../../user-context";
import { getDateStringFromTimestampString } from "./auditor-documents-view";
import { WorkPaperDetails } from "./work-paper-details";

interface IWorkPaperProps {
  customerDomainDisplayName: string;
  workPaper: NonNullable<
    GetAuditorDocumentsQuery["organization"]
  >["auditorDocuments"][number];
}

export interface IWorkPaperRow {
  testId: string;
  criterion: string;
  controlName: string;
  controlDescription: string;
  testApplied: string;
  testResultsAuditor: string;
  commentsAuditor: string;
  testStatusVanta: string;
  commentsVanta: string;
}

export const WorkPaperDetailContainer: React.FC<IWorkPaperProps> = ({
  customerDomainDisplayName,
  workPaper,
}) => {
  const { user } = useContext(UserContext);
  const [editWorkPaper] = useEditWorkPaperMutation();
  if (!isSome(user)) {
    LogErrorMessage("No user context");
  }
  const domainId = user!.domain.id;
  const complianceQuery = useGetComplianceStandardQuery({
    variables: {
      standard: ReportStandard.soc2,
      domainId: workPaper.customerDomainId,
    },
  });
  const workPaperContentsQuery = useGetWorkPaperContentsQuery({
    variables: {
      domainId,
      workPaperId: workPaper.id as string, // can safely assert id
    },
  });
  if (complianceQuery.error) {
    LogError(complianceQuery.error);
    return null;
  }
  if (workPaperContentsQuery.error) {
    LogError(workPaperContentsQuery.error);
    return null;
  }
  if (complianceQuery.loading || workPaperContentsQuery.loading) {
    return <FullPageSpinner />;
  }
  const workPaperContentsData = workPaperContentsQuery.data!.workPaperContents;
  const complianceData = complianceQuery.data!.complianceStandard!;
  const complianceRequirements: IComplianceRequirement[] =
    complianceData.principles.flatMap(p =>
      p.requirements.map(r => {
        return { criterion: r.section, description: r.name };
      })
    );
  const updateWorkPaper = async (workPaperTests: IWorkPaperRow[]) => {
    await editWorkPaper({
      variables: {
        workPaperId: workPaper.id as string,
        workPaperTests,
      },
    }).catch(LogError);
  };

  // remove dangling typename for proper typing and processing through graphql
  // when we use the update mutation, with this data, __typename can't be there
  const workPaperContentsCopy = [
    ...workPaperContentsData.map(x => {
      const newContent = { ...x }; // need to copy before deletion since __typename isn't configurable
      delete newContent.__typename;
      return newContent;
    }),
  ];

  return (
    <WorkPaperDetails
      domainDisplayName={customerDomainDisplayName}
      complianceRequirements={complianceRequirements}
      name={workPaper.name}
      updateWorkPaper={updateWorkPaper}
      rows={workPaperContentsCopy}
      createdAt={getDateStringFromTimestampString(workPaper.created.timestamp)}
    />
  );
};

export interface IComplianceRequirement {
  criterion: string;
  description: string;
}

gql`
  query GetWorkPaperContents($domainId: ID!, $workPaperId: String!) {
    workPaperContents(domainId: $domainId, workPaperId: $workPaperId) {
      criterion
      testId
      controlName
      controlDescription
      testApplied
      testResultsAuditor
      commentsAuditor
      testStatusVanta
      commentsVanta
    }
  }
`;

gql`
  mutation editWorkPaper(
    $workPaperId: String!
    $workPaperTests: [workPaperTestInput!]!
  ) {
    editWorkPaper(workPaperId: $workPaperId, workPaperTests: $workPaperTests)
  }
`;
