import { Spinner } from "@blueprintjs/core";
import { ErrorOption } from "common/base/types/gen";
import { isSome } from "common/base/types/maybe";
import {
  specificResourceGraphQLTypeToEnumValue,
  SpecificResourceToInventoryType,
} from "common/utils/inventory";
import {
  deserializeVantaAttributes,
  hasExternallyManagedVantaAttributes,
} from "common/utils/vantaAttributes";
import gql from "graphql-tag";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import type { BucketsQuery } from "../../../../gen/components";
import {
  useBucketsQuery,
  useSetInventoryResourceVantaAttributeMutation,
} from "../../../../gen/components";
import { ErrorToggle } from "../error-toggle";
import { InventoryCard } from "../inventory-card";
import { InventoryErrorCard } from "../inventory-error-card";
import {
  AccountTag,
  EncryptionTag,
  EPHITag,
  RegionTag,
  VersionedTag,
} from "../tags";
import {
  fetchMoreResources,
  getDebounceContext,
  getResourceFilterParams,
  invalidInventoryType,
  PAGE_SIZE,
  setResourceVantaAttributeFn,
} from "../utils";
import { SearchResultsSummary } from "./search-results-summary";
import type { IInventoryTabProps } from "./shared-interface";

export const Buckets: React.FC<IInventoryTabProps> = ({
  errorCount,
  searchString,
}) => {
  const [showError, setShowError] = React.useState(false);
  const { data, fetchMore, loading } = useBucketsQuery({
    variables: {
      first: PAGE_SIZE,
      errorOpt: showError ? ErrorOption.ERROR_ONLY : ErrorOption.NONERROR_ONLY,
      filterParams: getResourceFilterParams(searchString),
    },
    context: getDebounceContext("bucket"),
  });

  if (loading) {
    return <Spinner />;
  }

  const cards = data?.organization.resources.edges?.map(item => (
    <BucketCard key={item.node.id} item={item} domain={data.organization} />
  ));

  return (
    <>
      <SearchResultsSummary
        searchString={searchString}
        numberResults={data?.organization.resources?.totalCount ?? 0}
      />
      <ErrorToggle
        onToggle={setShowError}
        isError={showError}
        errorCount={errorCount}
        resource={"bucket"}
      />
      <InfiniteScroll
        className="inventory-list-card-group"
        dataLength={data?.organization.resources.edges.length ?? 0}
        next={async () => fetchMoreResources(data, fetchMore)}
        hasMore={data?.organization.resources.pageInfo.hasNextPage ?? false}
        loader={<Spinner />}
      >
        {cards}
      </InfiniteScroll>
    </>
  );
};

const BucketCard: React.FC<{
  item: NonNullable<BucketsQuery["organization"]>["resources"]["edges"][number];
  domain: NonNullable<BucketsQuery["organization"]>;
}> = ({ item, domain }) => {
  const [setResourceVantaAttribute] =
    useSetInventoryResourceVantaAttributeMutation();

  const specificResourceKind = specificResourceGraphQLTypeToEnumValue(
    item.node.__typename
  );
  const type = SpecificResourceToInventoryType[specificResourceKind](
    item.node as any
  );
  const resourceMongoId = item.node.id;

  let uid;
  let labels;
  const vantaAttributes = deserializeVantaAttributes(
    item.node.vantaAttributes ?? []
  );
  switch (item.node.__typename) {
    case "SpecificS3Resource": {
      uid = item.node.name;
      labels = [
        <AccountTag key="account" cloudAccountId={item.node.account} />,
        <VersionedTag
          key="versioned"
          versioned={item.node.versioningEnabled}
          hasUserData={vantaAttributes.containsUserData ?? false}
        />,
        <EncryptionTag
          key="encryption"
          encrypted={item.node.encryptionRulesCount > 0}
        />,
      ];
      if (vantaAttributes.containsEPHI) {
        labels.push(<EPHITag key="ephi" />);
      }
      break;
    }
    case "SpecificAzureBlobContainerResource": {
      labels = [<RegionTag key="region" region={item.node.location} />];
      uid = item.node.uniqueId;
      labels.push(
        <AccountTag
          key="account"
          cloudAccountId={item.node.storageAccountName}
        />,
        <EncryptionTag
          key="encryption"
          encrypted={true} // Azure encrypts by default, and it can't be disabled
        />
      );
      if (vantaAttributes.containsEPHI) {
        labels.push(<EPHITag key="ephi" />);
      }
      break;
    }
    case "SpecificGCPStorageBucketResource": {
      uid = item.node.uniqueId;
      labels = [
        <AccountTag key="account" cloudAccountId={item.node.projectId} />,
        <VersionedTag
          key="versioned"
          versioned={item.node.versioningEnabled}
          hasUserData={vantaAttributes.containsUserData ?? false}
        />,
        <EncryptionTag
          key="encryption"
          encrypted={true} // GCP encrypts by default. It can be encrypted with a customer-provided key, but there's still encryption no matter what
        />,
      ];
      if (vantaAttributes.containsEPHI) {
        labels.push(<EPHITag key="ephi" />);
      }
      break;
    }
    case "SpecificDigitalOceanSpaceResource": {
      uid = item.node.uniqueId;
      labels = [
        <VersionedTag
          key="versioned"
          versioned={item.node.versioningEnabled}
          hasUserData={vantaAttributes.containsUserData ?? false}
        />,
        <EncryptionTag
          key="encryption"
          encrypted={true} // DigitalOcean encrypts by default
        />,
        <RegionTag key="region" region={item.node.region} />,
      ];
      if (vantaAttributes.containsEPHI) {
        labels.push(<EPHITag key="ephi" />);
      }
      break;
    }
    default:
      return invalidInventoryType(item.node.__typename);
  }

  const locked = hasExternallyManagedVantaAttributes(
    item.node.vantaAttributes ?? []
  );
  const setVantaAttribute = setResourceVantaAttributeFn(
    setResourceVantaAttribute,
    specificResourceKind,
    resourceMongoId,
    item.node.__typename
  );
  return isSome(item.node.fetchError) ? (
    <InventoryErrorCard
      type={type}
      uid={uid}
      labels={labels}
      key={item.node.id}
      name={item.node.name}
      error={item.node.fetchError}
    ></InventoryErrorCard>
  ) : (
    <InventoryCard
      type={type}
      uid={uid}
      labels={labels}
      key={item.node.id}
      name={item.node.name}
      description={vantaAttributes.description}
      owner={vantaAttributes.ownerId}
      canContainUserData={true}
      containsUserData={vantaAttributes.containsUserData}
      containsEPHI={vantaAttributes.containsEPHI}
      userDataDescription={vantaAttributes.userDataStored}
      locked={locked}
      setVantaAttribute={setVantaAttribute}
    />
  );
};

gql`
  query buckets(
    $first: Int!
    $after: String
    $errorOpt: errorOption
    $filterParams: filterParams
  ) {
    organization {
      id
      name
      resources(
        first: $first
        after: $after
        genericResourceType: StorageBucket
        sortParams: { field: "createdAt", direction: -1 }
        filterParams: $filterParams
        options: { errorOption: $errorOpt }
      ) {
        totalCount
        edges {
          node {
            id
            __typename
            uniqueId
            fetchError
            vantaAttributes {
              key
              value
              managedExternally
            }
            ... on SpecificS3Resource {
              account
              encryptionRulesCount
              name
              versioningEnabled
            }
            ... on SpecificAzureBlobContainerResource {
              storageAccountName
              location
              name
            }
            ... on SpecificGCPStorageBucketResource {
              projectId
              name
              versioningEnabled
            }
            ... on SpecificDigitalOceanSpaceResource {
              name
              region
              versioningEnabled
            }
          }
        }
        pageInfo {
          hasNextPage
          endCursor
          startCursor
          hasPreviousPage
        }
      }
    }
  }
`;
