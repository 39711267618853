import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";

import { MarkdownRenderer } from "../components/pages/components/markdown-renderer";

export const UI_DATE_FORMAT = "MMM D YYYY, h:mm A";
export const UI_DATE_FORMAT_TO_SECONDS = "MMM DD YYYY, HH:mm:ss";
export const UI_TEST_TIMESTAMP_FORMAT = "MMM D YYYY, h:mm A";
export const UI_DATE_FORMAT_WITHOUT_TIME = "MMM D YYYY";
export const UI_DATE_FORMAT_MONTH_YEAR = "MMM YYYY";
export const FILE_TIMESTAMP_FORMAT = "YYYY-MM-DD-HHmmss";

export const renderControlDescription = (
  controlDescription: string,
  companyName?: Maybe<string>
) =>
  MarkdownRenderer(
    controlDescriptionWithVariablesReplaced(controlDescription, companyName)
  );

function controlDescriptionWithVariablesReplaced(
  controlDescription: string,
  companyName?: Maybe<string>
) {
  return isSome(companyName)
    ? controlDescription.replace(/%COMPANY/g, companyName)
    : controlDescription;
}
