import "./email-subscription.scss";

import { Classes } from "@blueprintjs/core";
import type { EmailCategoryForUnsubscribe } from "common/base/types/gen";
import React from "react";

import type { UserEmailSubscriptionSettingQuery } from "../../../gen/components";
import { EmailSubscriptionBox } from "./email-subscription-box";

interface IProps {
  settings: Array<
    NonNullable<
      NonNullable<
        UserEmailSubscriptionSettingQuery["userEmailSubscriptionSettings"]
      >[0]
    >
  >;
  category: EmailCategoryForUnsubscribe;
}

export const EmailSubscriptionCategory: React.FC<IProps> = props => {
  const orderedSettings = props.settings.sort((a, b) =>
    a.friendlyName.localeCompare(b.friendlyName)
  );
  const components = orderedSettings.map((s, ix) => (
    <div key={ix}>
      <EmailSubscriptionBox setting={s} />
    </div>
  ));

  return (
    <div className={`${Classes.RUNNING_TEXT} app-container-page`}>
      <h3>{props.category}</h3>
      {components}
    </div>
  );
};
