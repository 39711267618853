import "./InfoIcon.scss";

import { Classes, Icon } from "@blueprintjs/core";
import type { Placement } from "@blueprintjs/popover2";
import type { Maybe } from "common/base/types/maybe";
import React from "react";
import { Tooltip } from "../../alpaca/components";

interface IProps {
  className?: Maybe<string>;
  content: string | JSX.Element;
  position?: Maybe<Placement>;
  large?: Maybe<boolean>;
}

export class InfoIcon extends React.Component<IProps> {
  public static defaultProps: IProps = {
    className: "",
    content: "",
    large: true,
    position: "top",
  };

  public constructor(props: IProps) {
    super(props);
  }

  public render() {
    if (this.props.content === "") {
      return null;
    }
    return (
      <Tooltip
        content={this.props.content}
        popoverClassName={`tooltip-fixedwidth ${Classes.DARK}`}
        placement={this.props.position ?? undefined}
      >
        <Icon
          className={`${this.props.className} ${Classes.TEXT_MUTED} table-header-info-icon`}
          icon="info-sign"
          iconSize={this.props.large ? 16 : 12}
        />
      </Tooltip>
    );
  }
}
