import { Feature, ReportStandard } from "../base/types/gen";
import { getImmutableSet } from "../utils/immutable-set";

// NOTE: Ordering determines what's shown on the Standards View
export const PCI_STANDARDS = [
  ReportStandard.pciSaqA,
  ReportStandard.pciSaqAEP,
  ReportStandard.pciSaqDMerchant,
  ReportStandard.pciSaqDSP,
] as const;

export const PCI_STANDARD_SET = getImmutableSet<ReportStandard>(PCI_STANDARDS);

export const PCI_STANDARD_FEATURES = [
  Feature.PCISaqAEPStandard,
  Feature.PCISaqAStandard,
  Feature.PCISaqDMerchantStandard,
  Feature.PCISaqDSPStandard,
] as const;
