import { FormGroup, Spinner, UL } from "@blueprintjs/core";
import { FilterOperator } from "common/base/types/gen";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import { EMPLOYEE_COMPUTER_DEFAULT_DESCRIPTION } from "common/constants/inventory-list";
import { SpecificResourceToInventoryType } from "common/utils/inventory";
import type {
  DeserializedVantaAttributes,
  ReservedVantaAttribute,
} from "common/utils/vantaAttributes";
import { deserializeVantaAttributes } from "common/utils/vantaAttributes";
import gql from "graphql-tag";
import _ from "lodash";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Tag, Tooltip } from "../../../../alpaca/components";

import { LogError } from "../../../../errors";
import type {
  AllMdmManagedComputersQuery,
  EmployeeComputersQuery,
} from "../../../../gen/components";
import {
  useAllMdmManagedComputersQuery,
  useEmployeeComputersQuery,
  useSetInventoryOsqueryVantaAttributeMutation,
  useSetInventoryResourceVantaAttributeMutation,
  useSetLaptopOwnerMutation,
} from "../../../../gen/components";
import {
  managedByFromType,
  specificResourceFromType,
} from "../../computers/utils";
import { ErrorToggle } from "../error-toggle";
import { InventoryCard } from "../inventory-card";
import { InventoryErrorCard } from "../inventory-error-card";
import { SshAccessTables } from "../ssh-access/ssh-access-tables";
import { FullWidthFormGroup } from "../ssh-access/ssh-config-tables";
import { EncryptionTag } from "../tags";
import {
  getDebounceContext,
  getResourceFilterParams,
  invalidInventoryType,
  PAGE_SIZE,
  setOsqueryVantaAttributeFn,
  setResourceVantaAttributeFn,
} from "../utils";
import { SearchResultsSummary } from "./search-results-summary";
import type { IInventoryTabProps } from "./shared-interface";

export const EmployeeComputers: React.FC<IInventoryTabProps> = ({
  errorCount,
  searchString,
}) => {
  const [showError, setShowError] = React.useState(false);
  const {
    data: agentsData,
    fetchMore,
    loading: employeeComputersLoading,
  } = useEmployeeComputersQuery({
    variables: {
      first: PAGE_SIZE,
      filterParams: getMachinesFilterParams(searchString),
    },
    context: getDebounceContext("employee-computer"),
  });

  const {
    error,
    data: mdmComputersData,
    loading,
  } = useAllMdmManagedComputersQuery({
    variables: { filterParams: getResourceFilterParams(searchString) },
    context: getDebounceContext("employee-mdm"),
  });

  if (loading || employeeComputersLoading) {
    return <Spinner />;
  } else if (isSome(error)) {
    LogError(error);
  }

  const mdmComputers = mdmComputersData?.organization.resources.edges ?? [];

  // Vanta Agent installations on computers that are NOT managed with an MDM
  // tool.
  const agentOnlyComputers = agentsData?.organization.machines.edges ?? [];

  const cards = [
    ...mdmComputers
      .filter(item => {
        if (showError) {
          return isSome(item.node.fetchError);
        } else {
          return !isSome(item.node.fetchError);
        }
      })
      .map(item => <ManagedComputerCard key={item.node.id} item={item} />),
    ...agentOnlyComputers
      .filter(_item => !showError) // agent only computers are never error resources
      .map(item => (
        <OsqueryEmployeeComputerCard key={item.node.id} item={item} />
      )),
  ];

  return (
    <>
      <SearchResultsSummary
        searchString={searchString}
        numberResults={
          (mdmComputersData?.organization.resources?.totalCount ?? 0) +
          (agentsData?.organization.machines?.totalCount ?? 0)
        }
      />
      <ErrorToggle
        onToggle={setShowError}
        isError={showError}
        errorCount={errorCount}
        resource={"computer"}
      />
      <InfiniteScroll
        className="inventory-list-card-group"
        dataLength={agentsData?.organization.machines.edges.length ?? 0}
        next={async () =>
          fetchMore({
            variables: {
              after: agentsData?.organization.machines.pageInfo.endCursor,
              first: PAGE_SIZE,
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
              if (!previousResult?.organization.machines.pageInfo.hasNextPage) {
                return previousResult;
              }
              const newResult = _.cloneDeep(previousResult);

              newResult.organization.machines.edges = [
                ...(previousResult.organization.machines.edges ?? []),
                ...(fetchMoreResult?.organization.machines.edges ?? []),
              ];
              newResult.organization.machines.pageInfo =
                fetchMoreResult!.organization.machines.pageInfo!;

              return newResult;
            },
          })
        }
        hasMore={
          agentsData?.organization.machines.pageInfo.hasNextPage ?? false
        }
        loader={<Spinner />}
      >
        {cards}
      </InfiniteScroll>
    </>
  );
};

const ManagedComputerCard: React.FC<{
  item: NonNullable<
    AllMdmManagedComputersQuery["organization"]
  >["resources"]["edges"][number];
}> = ({ item }) => {
  const [setResourceVantaAttribute] =
    useSetInventoryResourceVantaAttributeMutation();

  switch (item.node.__typename) {
    case "SpecificJamfManagedComputerResource":
      break;
    case "SpecificKandjiManagedComputerResource":
      break;
    case "SpecificMicrosoftEndpointManagerManagedComputerResource":
      break;
    default:
      return invalidInventoryType(item.node.__typename);
  }

  const specificResourceKind = specificResourceFromType(item.node.__typename);
  const managedBy = managedByFromType(item.node.__typename);

  if (!isSome(specificResourceKind) || !isSome(managedBy)) {
    return invalidInventoryType(item.node.__typename);
  }

  const type = SpecificResourceToInventoryType[specificResourceKind](
    item.node as any
  );
  const vantaAttributes = deserializeVantaAttributes(
    item.node.vantaAttributes ?? []
  );
  const setVantaAttribute = setResourceVantaAttributeFn(
    setResourceVantaAttribute,
    specificResourceKind,
    item.node.id,
    item.node.__typename
  );

  const labels = [
    <Tooltip
      key={managedBy}
      content={`This device is administered with ${managedBy}`}
    >
      <Tag text={managedBy} />
    </Tooltip>,
    <EncryptionTag key={"encryption"} encrypted={item.node.isEncrypted} />,
  ];

  const adminsComponent = UserAdmins({
    admins: item.node.localUserAccounts
      ?.filter(a => a.admin)
      .map(a => {
        return { username: a.fullName };
      }),
  });

  return isSome(item.node.fetchError) ? (
    <InventoryErrorCard
      type={type}
      uid={item.node.uniqueId}
      labels={labels}
      key={item.node.uniqueId}
      name={item.node.name}
      error={item.node.fetchError}
    ></InventoryErrorCard>
  ) : (
    <InventoryCard
      type={type}
      uid={item.node.hardware?.serialNumber ?? item.node.uniqueId}
      key={item.node.uniqueId}
      name={item.node.name}
      description={
        vantaAttributes.description ??
        (isSome(vantaAttributes.ownerId)
          ? EMPLOYEE_COMPUTER_DEFAULT_DESCRIPTION
          : null)
      }
      owner={vantaAttributes.ownerId}
      lockOwner={item.node.vantaAttributes?.some(
        attr => attr.key === "ownerid" && attr.managedExternally
      )}
      canContainUserData={false}
      labels={labels}
      setVantaAttribute={async (
        key: ReservedVantaAttribute,
        value: Maybe<string>
      ) => {
        if (shouldUpdateAttribute(key, value, vantaAttributes)) {
          return setVantaAttribute(key, value);
        } else {
          return undefined;
        }
      }}
    >
      {adminsComponent ? (
        <div>
          <hr />
          <FormGroup
            label="Admins"
            helperText="Accounts with admin access to this machine"
          >
            {adminsComponent}
          </FormGroup>
        </div>
      ) : null}
    </InventoryCard>
  );
};

const OsqueryEmployeeComputerCard: React.FC<{
  item: NonNullable<
    EmployeeComputersQuery["organization"]
  >["machines"]["edges"][number];
}> = ({ item }) => {
  const [setLaptopOwner] = useSetLaptopOwnerMutation();
  const [setOsqueryVantaAttribute] =
    useSetInventoryOsqueryVantaAttributeMutation();

  let type: Maybe<string>;
  let admins;
  const osqueryMongoId = item.node.id;
  switch (item.node.data.__typename) {
    case "linuxWorkstationData":
      type = "Employee Computer (Linux)";
      admins = item.node.data.adminAccounts;
      break;
    case "macosWorkstationData":
      type = "Employee Computer (Mac)";
      admins = item.node.data.adminAccounts;
      break;
    case "windowsWorkstationData":
      type = "Employee Computer (Windows)";
      break;
    default:
      return invalidInventoryType(item.node.data.__typename);
  }

  const uid = item.node.data.serialNumber ?? item.node.hostIdentifier;

  const vantaAttributes = deserializeVantaAttributes(
    item.node.vantaAttributes ?? []
  );
  const setVantaAttribute = setOsqueryVantaAttributeFn(
    setOsqueryVantaAttribute,
    osqueryMongoId
  );

  const labels = [];
  if (isSome(item.node.data.isEncrypted)) {
    labels.push(<EncryptionTag encrypted={item.node.data.isEncrypted} />);
  }

  const onSetOwner = async (ownerId: string) => {
    await setLaptopOwner({
      variables: { userId: ownerId, uuid: item.node.hostIdentifier },
      update: (dataProxy, result) => {
        if (!result.data) {
          return;
        }
        const osqueryUserDoc = gql`
          fragment osqueryOwner on osquery {
            id
            user {
              id
            }
          }
        `;
        const id = `osquery:${item.node.id}`;
        const newData = {
          __typename: "osquery",
          id: item.node.id,
          user: {
            __typename: "user",
            id: ownerId,
          },
        };
        dataProxy.writeFragment({
          fragment: osqueryUserDoc,
          id,
          data: newData,
        });
      },
    });
  };

  const adminsComponent = UserAdmins({ admins });
  const accessTablesComponent = SshAccessTables({
    data: item.node.data,
    osqueryId: item.node.id,
  });

  return (
    <InventoryCard
      type={type}
      uid={uid}
      key={item.node.id}
      name={item.node.prettyName}
      description={
        vantaAttributes.description ??
        (isSome(item.node.user?.id)
          ? EMPLOYEE_COMPUTER_DEFAULT_DESCRIPTION
          : null)
      }
      owner={item.node.user?.id}
      setOwner={onSetOwner}
      labels={labels}
      canContainUserData={false}
      setVantaAttribute={async (
        key: ReservedVantaAttribute,
        value: Maybe<string>
      ) => {
        if (shouldUpdateAttribute(key, value, vantaAttributes)) {
          return setVantaAttribute(key, value);
        } else {
          return undefined;
        }
      }}
    >
      {adminsComponent ? (
        <div>
          <hr />
          <FormGroup
            label="Admins"
            helperText="Accounts with admin access to this machine"
          >
            {adminsComponent}
          </FormGroup>
        </div>
      ) : null}
      {accessTablesComponent ? (
        <div>
          <hr />

          <FullWidthFormGroup
            label="SSH Access"
            helperText="What machines can communicate with this one over SSH?"
          >
            {accessTablesComponent}
          </FullWidthFormGroup>
        </div>
      ) : null}
    </InventoryCard>
  );
};

const UserAdmins: React.FC<{
  admins: Maybe<Array<{ username: string; description?: Maybe<string> }>>;
}> = ({ admins }) => {
  if (!isSome(admins)) {
    return null;
  }
  const adminListItems = admins
    .map(
      admin =>
        `${admin.username}` +
        (isSome(admin.description) && admin.description.length > 0
          ? ` (${admin.description})`
          : "")
    )
    .map((adminString, i) => <li key={i}>{adminString}</li>);

  return <UL>{adminListItems}</UL>;
};

/**
 * Returns false if a description is the default and there is no previous value,
 * true otherwise
 */
function shouldUpdateAttribute(
  key: ReservedVantaAttribute,
  value: Maybe<string>,
  currentAttributes: DeserializedVantaAttributes
) {
  return (
    key !== "description" ||
    isSome(currentAttributes.description) ||
    value !== EMPLOYEE_COMPUTER_DEFAULT_DESCRIPTION
  );
}

/**
 * Returns filter parameters for the machines field on domains.
 * This is similar to getResourceFilterParams, but the osqueries
 * model doesn't have several of those fields
 */
function getMachinesFilterParams(searchString: string) {
  const trimmedString = searchString?.trim() ?? "";
  if (trimmedString === "") {
    return null;
  } else {
    return {
      filters: [
        { condition: { STRING_INCLUDES: searchString }, field: "name" },
      ],
      operator: FilterOperator.OR,
    };
  }
}

// NOTE Dec. 3, 2020: loading all MDM-managed computers at once may cause perf
// issues for customers with lots of computers, but handling doubly-monitored
// computers will require a joint osqueries/resources paginated interface.
//
// In the future we can write osqueries to the ManagedComputers collection,
// deduplicating computers by UDID at fetch/enrollment, and then paginate them
// all through the resources resolver.
gql`
  query allMDMManagedComputers($filterParams: filterParams) {
    organization {
      id
      resources(
        first: 1000
        genericResourceType: ManagedComputer
        sortParams: { field: "createdAt", direction: -1 }
        filterParams: $filterParams
        options: { errorOption: ALL }
      ) {
        totalCount
        edges {
          node {
            id
            __typename
            uniqueId
            fetchError
            vantaAttributes {
              key
              value
              managedExternally
            }
            ... on SpecificJamfManagedComputerResource {
              name
              udid
              isEncrypted
              localUserAccounts {
                fullName
                admin
                homeDirectory
              }
              hardware {
                serialNumber
              }
            }
            ... on SpecificKandjiManagedComputerResource {
              name
              udid
              isEncrypted
              localUserAccounts {
                fullName
                admin
                homeDirectory
              }
              hardware {
                serialNumber
              }
            }
            ... on SpecificMicrosoftEndpointManagerManagedComputerResource {
              name
              udid
              isEncrypted
              localUserAccounts {
                fullName
                admin
                homeDirectory
              }
              hardware {
                serialNumber
              }
              operatingSystem {
                name
              }
            }
          }
        }
      }
    }
  }
`;

gql`
  query employeeComputers(
    $first: Int!
    $after: String
    $filterParams: filterParams
  ) {
    organization {
      id
      machines(
        first: $first
        after: $after
        workstationsOnly: true
        activeOnly: true
        filterParams: $filterParams
      ) {
        totalCount
        edges {
          node {
            id
            platform
            user {
              id
            }
            prettyName
            hostIdentifier
            vantaAttributes {
              key
              value
              managedExternally
            }
            data {
              __typename
              id
              hostname
              lastPing
              osVersion
              serialNumber
              ... on macosWorkstationData {
                isEncrypted
                adminAccounts {
                  username
                  description
                }
                authorizedKeys {
                  algorithm
                  comment
                  error
                  key_file
                  key_type
                  sha256_fingerprint
                  machinesWithAccessToThisOne(first: 0) {
                    totalCount
                  }
                }
                sshKeys {
                  comment
                  encrypted
                  error
                  key_type
                  sha256_fingerprint
                  path
                  machinesThisCanAccess(first: 0) {
                    totalCount
                  }
                }
              }
              ... on linuxWorkstationData {
                isEncrypted
                adminAccounts {
                  username
                  description
                }
                authorizedKeys {
                  algorithm
                  comment
                  error
                  key_file
                  key_type
                  sha256_fingerprint
                  machinesWithAccessToThisOne(first: 0) {
                    totalCount
                  }
                }
                sshKeys {
                  comment
                  encrypted
                  error
                  key_type
                  sha256_fingerprint
                  path
                  machinesThisCanAccess(first: 0) {
                    totalCount
                  }
                }
              }
              ... on windowsWorkstationData {
                isEncrypted
              }
            }
          }
        }
        pageInfo {
          hasNextPage
          endCursor
          startCursor
          hasPreviousPage
        }
      }
    }
  }
`;
