import { dropNothing, isSome } from "common/base/types/maybe";

import type { GetVaqAnswersQuery } from "../../../gen/components";
import type { IQuestionSchema } from "../../forms/interfaces";
import { changeManagement } from "./changeManagement";
import { dataManagementDisasterRecovery } from "./dataManagementDisasterRecovery";
import { generalPrivacy } from "./generalPrivacy";
import { generalSecurity2 } from "./generalSecurity2";
import { generalSecurity3 } from "./generalSecurity3";
import { generalSecurity4 } from "./generalSecurity4";
import { logging } from "./logging";
import * as basicInformation from "./vendorBasicInformation";
import { vendorCloudHosted } from "./vendorCloudHosted";
import { vendorProfessionalServices } from "./vendorProfessionalServices";

export enum VAQState {
  NO_BASIC_INFO,
  NO_SERVICES,
  SERVICES_SPECIFIED,
}

export const vaqForms: { [k: string]: IQuestionSchema } = {
  ...basicInformation,
  generalSecurity2,
  generalSecurity3,
  generalSecurity4,
  changeManagement,
  logging,
  dataManagementDisasterRecovery,
  generalPrivacy,
  vendorCloudHosted,
  vendorProfessionalServices,
};

type VaqAnswers = NonNullable<GetVaqAnswersQuery["vaqAnswers"]>;

export const getStateOfVAQ = (vaq: VaqAnswers) => {
  if (!isSome(vaq.vendorLegalName)) {
    return VAQState.NO_BASIC_INFO;
  }

  const services = vaq.servicesProvided;

  if (!isSome(services)) {
    return VAQState.NO_SERVICES;
  }
  return VAQState.SERVICES_SPECIFIED;
};

export const initialFormForVaq = (vaq: VaqAnswers) => {
  if (!isSome(vaq.vendorLegalName))
    return basicInformation.vendorBasicInformation.id;
  const services = vaq.servicesProvided;

  if (!isSome(services)) {
    return basicInformation.vendorProvidedServices.id;
  }
  if (services.software || services.cloud) {
    return generalSecurity2.id;
  }
  if (services.professional) {
    return vendorProfessionalServices.id;
  }
  return basicInformation.vendorBasicInformation.id;
};

export const getSchemaWithVendorAndCompanyNames = (
  formId: string,
  company: string,
  vendor: string
) => {
  const vaqForm = vaqForms[formId];

  function replaceNames(input: string) {
    return input.replace(/%COMPANY/g, company).replace(/%VENDOR/g, vendor);
  }
  return JSON.parse(replaceNames(JSON.stringify(vaqForm))) as IQuestionSchema;
};

const initialForms = [basicInformation.vendorBasicInformation];

const serviceForms = [basicInformation.vendorProvidedServices];

const softwareAndPlatformForms = [
  generalSecurity2,
  generalSecurity3,
  generalSecurity4,
  changeManagement,
  logging,
  dataManagementDisasterRecovery,
  generalPrivacy,
];

const cloudHostedVAQForms = [vendorCloudHosted];

const professionalServicesForms = [vendorProfessionalServices];

export const getVAQFormsForVendor = (vaq: VaqAnswers) => {
  const forms = [...initialForms];
  if (!isSome(vaq.vendorLegalName)) return forms;
  forms.push(...serviceForms);
  const services = vaq.servicesProvided;

  if (!isSome(services)) return forms;

  if (services.software || services.cloud) {
    forms.push(...softwareAndPlatformForms);
  }
  if (services.cloud) forms.push(...cloudHostedVAQForms);
  if (services.professional) forms.push(...professionalServicesForms);

  return forms;
};

export const vaqFormListIsNavigable = (vaq: VaqAnswers) =>
  isSome(vaq.vendorLegalName) && isSome(vaq.servicesProvided);

export const nextFormForVaq = (
  vaq: VaqAnswers,
  currentId: string,
  formList: IQuestionSchema[] = getVAQFormsForVendor(vaq)
) => {
  const idList = formList.map(form => form.id);
  const currentIndex = idList.indexOf(currentId);
  return currentIndex < idList.length - 1
    ? idList[currentIndex + 1]
    : "SUBMIT_VAQ";
};

export const vendorHasCompletedForm = (vaq: VaqAnswers, formId: string) => {
  const form = vaqForms[formId];
  if (!isSome(form)) {
    throw new Error("No form for id");
  }
  const answers = JSON.parse(vaq.answersJSON)[formId];
  return isSome(answers);
};

export const vendorCanSubmitVAQ = (
  vaq: VaqAnswers,
  formList = getVAQFormsForVendor(vaq)
) => {
  if (!isSome(vaq.vendorLegalName) || !isSome(vaq.servicesProvided)) {
    return false;
  }

  return formList.every(form => vendorHasCompletedForm(vaq, form.id));
};

export const getVAQAnswersToSubmit = (
  vaq: VaqAnswers,
  formList = getVAQFormsForVendor(vaq)
) => {
  if (!vendorCanSubmitVAQ(vaq, formList)) {
    throw new Error(
      "Tried To Get Vaq Answers to submit, but vendor has not completed VAQ."
    );
  }
  const filesToSubmit = new Set<string>();
  const answers: { [k: string]: string } = {};
  const allAnswers = JSON.parse(vaq.answersJSON);
  const fileTitles = dropNothing(vaq.files.map(f => f.title));
  formList.forEach(form => {
    fileTitles
      .filter(title => form.questions.some(q => q.name === title))
      .forEach(title => filesToSubmit.add(title));

    answers[form.id] = allAnswers[form.id];
  });
  return { answers, files: Array.from(filesToSubmit) };
};
