/* eslint-disable react/forbid-elements */
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { BASE_PALETTE } from "../alpaca/base/colors";

const HTTP_PROTOCOL_REGEX = /^https?:/;

/**
 * Drop-in replacement for an <a> tag for a link with Vanta's default behavior.
 *
 * If the link points to an external resource (i.e. starts with http/https), then open in a new tab.
 * Otherwise, open in the same tab.
 * @param props
 */
export const DefaultLink: React.FC<
  React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  >
> = props => {
  if (props.href?.startsWith("http")) {
    return <a {...props} target="_blank" rel="noopener noreferrer" />;
  }
  return <a {...props} />;
};

export const DefaultLinkBlack = styled(DefaultLink)`
  text-decoration: underline;
  &,
  &:hover {
    color: ${BASE_PALETTE.INK};
  }
`;

export const LinkBlack = styled(Link)`
  text-decoration: underline;
  &,
  &:hover {
    color: ${BASE_PALETTE.INK};
  }
`;

// Prepend `https://` if the URL does not contain it, so that the resulting
// URL will be treated by an absolute path if used as `href`.
export const getAbsolutePathURL = (url: string) =>
  HTTP_PROTOCOL_REGEX.test(url) ? url : `https://${url}`;
