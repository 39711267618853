import type { IQuestionSchema } from "../../forms/interfaces";

export const changeManagement: IQuestionSchema = {
  id: "changeManagement",
  title: "Change management",
  questions: [
    {
      id: "changeManagementHeader-2018-09-10",
      label: "Change management",
      name: "changeManagementHeader",
      required: false,
      type: "Header",
    },
    {
      id: "pullRequestsRequired-2018_09_11",
      label: "Pull requests required",
      name: "pullRequestsRequired",
      required: false,
      type: "CheckboxInput",
    },
    {
      choices: [
        "Before the feature branch is merged",
        "Within one day of the feature branch being merged",
        "Within one week of the feature branch being merged",
        "Within two weeks of the feature branch being merged",
        "Code reviews are not required",
      ],
      id: "codeReviewRequired-2018_09_11",
      label: "Frequency of required code review",
      name: "codeReviewRequired",
      required: false,
      type: "SelectInput",
    },
    {
      id: "staging-2018_09_11",
      label: "Staging environment used",
      name: "staging",
      required: false,
      type: "CheckboxInput",
    },
    {
      choices: ["Less than 10%", "10-25%", "25-50%", "50-75%", "75%+"],
      id: "automatedTestCoverage-2018_09_11",
      label: "Automated test coverage",
      name: "automatedTestCoverage",
      required: false,
      type: "SelectInput",
    },
  ],
  submitText: "Submit change management info",
  version: "2018-09-10",
};
