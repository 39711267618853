import { Intent } from "@blueprintjs/core";
import type { IRiskCategory } from "common/schemas/riskRegister/risk-register-content";
import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { VANTA_COLORS } from "../../../alpaca/base/colors";
import { VantaButtonDeprecated } from "../../../alpaca/base/deprecated";
import { GRID_SPACING } from "../../../alpaca/base/grid";
import { BodyShortText, H4 } from "../../../alpaca/components";
import CompletionCheck from "../../../static/images/icons/completion-check.svg";
import { StandardLabels } from "../../helpers/StandardLabels";
import { PATHS } from "../../vanta-chrome/paths";

interface IProps {
  category: IRiskCategory;
  visited: boolean;
  stepsCompleted: number;
}

export const RiskCategoryCard: React.FC<IProps> = ({
  category,
  visited,
  stepsCompleted,
}) => {
  const history = useHistory();

  const totalNumberOfSteps = category.risks.length;
  const allStepsCompleted = stepsCompleted === totalNumberOfSteps;
  const buttonText = allStepsCompleted ? "View" : visited ? "Resume" : "Start";
  const progressText = `${stepsCompleted} / ${totalNumberOfSteps}`;
  const showProgressOnCard = category.showProgressOnCard ?? true;

  return (
    <Container>
      <div>
        <H4>{category.breadcrumbText}</H4>
        <BodyShortText color={VANTA_COLORS.TEXT_DESCRIPTION}>
          {category.description}
        </BodyShortText>
        <StandaloneLabelsContainer>
          <StandardLabels
            tagMargin={GRID_SPACING}
            standards={category.standards}
          />
        </StandaloneLabelsContainer>
      </div>
      <FlexFooter>
        <VantaButtonDeprecated
          intent={Intent.PRIMARY}
          onClick={() => {
            history.push(`${PATHS.RISK_ASSESSMENT}/${category.url}`);
          }}
        >
          {buttonText}
        </VantaButtonDeprecated>
        <InnerFlex>
          {showProgressOnCard && allStepsCompleted ? <CompletionCheck /> : null}
          {showProgressOnCard ? progressText : null}
        </InnerFlex>
      </FlexFooter>
    </Container>
  );
};

const styles = {
  BUTTON_VERTICAL_PADDING: 8,
  BUTTON_HORIZONTAL_PADDING: 16,
  HEADER_MARGIN_BOTTOM: 12,
  SVG_MARGIN_RIGHT: 8,
  SVG_TOP_NUDGE: 2,
  SVG_SIZE: 20,
};

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${VantaButtonDeprecated} {
    padding: ${styles.BUTTON_VERTICAL_PADDING}px
      ${styles.BUTTON_HORIZONTAL_PADDING}px;
  }
  ${H4} {
    margin-bottom: ${styles.HEADER_MARGIN_BOTTOM}px;
  }
`;

const FlexFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const InnerFlex = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-right: ${styles.SVG_MARGIN_RIGHT}px;
    position: relative;
    top: ${styles.SVG_TOP_NUDGE}px;
    height: ${styles.SVG_SIZE}px;
    width: ${styles.SVG_SIZE}px;
  }
`;

const StandaloneLabelsContainer = styled.div`
  margin-left: ${-1 * GRID_SPACING}px;
`;
