import { Intent, Spinner } from "@blueprintjs/core";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import moment from "moment";
import React from "react";
import styled from "styled-components";

import { Icon, IconNames, Tooltip } from "../../../../alpaca/components";
import { LogError } from "../../../../errors";
import type { GetInfoForRiskAssessmentReportQuery } from "../../../../gen/components";
import {
  GetInfoForRiskAssessmentReportDocument,
  useCompleteRiskAssessmentTaskMutation,
} from "../../../../gen/components";
import { AppToaster } from "../../../../helpers/toaster";
import { TIME_FORMAT } from "../table-styles";

type Task = NonNullable<
  GetInfoForRiskAssessmentReportQuery["organization"]
>["rrv3RiskScenarios"][number]["tasks"][number];

interface IProps {
  task: Task;
}

export const RiskTaskCompletionCheckbox: React.FC<IProps> = ({ task }) => {
  const [completeTask, mutationResult] = useCompleteRiskAssessmentTaskMutation({
    update: (cache, result) => {
      const taskToUpdate = result.data?.rrv3CompleteTask;
      if (!isSome(taskToUpdate)) {
        return;
      }
      const previousData = cache.readQuery<GetInfoForRiskAssessmentReportQuery>(
        {
          query: GetInfoForRiskAssessmentReportDocument,
        }
      );
      const organization = previousData?.organization;
      if (!isSome(organization)) {
        return;
      }
      cache.writeQuery<GetInfoForRiskAssessmentReportQuery>({
        query: GetInfoForRiskAssessmentReportDocument,
        data: {
          organization: {
            ...organization,
            rrv3RiskScenarios: organization.rrv3RiskScenarios.map(scenario => {
              return {
                ...scenario,
                tasks: scenario.tasks.map(t => {
                  if (t.id === taskToUpdate.id) {
                    return { ...t, ...taskToUpdate };
                  } else {
                    return t;
                  }
                }),
              };
            }),
          },
        },
      });
      AppToaster.show({
        message: "Task marked as completed",
        intent: Intent.SUCCESS,
      });
    },
  });

  const taskIsCompleted = isSome(task.completedAt);
  const tooltipText = taskIsCompleted
    ? `Completed ${moment(task.completedAt ?? undefined).format(TIME_FORMAT)}`
    : `Mark as complete`;
  const loading = mutationResult.called;
  return (
    <Container>
      {loading ? (
        <Spinner size={Spinner.SIZE_SMALL} />
      ) : (
        <Tooltip content={tooltipText}>
          {taskIsCompleted ? (
            <Icon
              icon={IconNames.SUCCESS}
              intent={Intent.SUCCESS}
              iconSize={Icon.SIZE_LARGE}
            />
          ) : (
            <HoverIcon
              iconSize={Icon.SIZE_LARGE}
              icon={IconNames.SUCCESS}
              onClick={() => {
                completeTask({ variables: { taskId: task.id } }).catch(
                  LogError
                );
              }}
            />
          )}
        </Tooltip>
      )}
    </Container>
  );
};

const styles = {
  HEIGHT: 30,
  WIDTH: 30,
};

const Container = styled.div`
  width: ${styles.WIDTH}px;
  height: ${styles.HEIGHT}px;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const HoverIcon = styled(Icon)`
  opacity: 20%;
  &:hover {
    opacity: 1;
    cursor: pointer;
  }
`;

gql`
  mutation completeRiskAssessmentTask($taskId: String!) {
    rrv3CompleteTask(taskId: $taskId) {
      id
      completedAt
    }
  }
`;
