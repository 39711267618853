import { InputGroup, Intent } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { isSome, nothing } from "common/base/types/maybe";
import gql from "graphql-tag";
import React, { useState } from "react";
import styled from "styled-components";

import { GRID_SPACING } from "../../alpaca/base/grid";
import { Button, H2, IconNames } from "../../alpaca/components";
import { LogError } from "../../errors";
import type { PeopleQueryPageQuery } from "../../gen/components";
import {
  MutationStatus,
  PeopleQueryPageDocument,
  useDeleteUserMutation,
  usePeopleQueryPageQuery,
  useUndoOffboardingMutation,
} from "../../gen/components";
import { BORDER_LINE } from "../../helpers/borders";
import { AppToaster } from "../../helpers/toaster";
import { CancelConfirmDialog } from "../helpers/CancelConfirmDialog";
import { FullPageSpinner } from "../helpers/FullPageSpinner";

export const PeopleQueryPage: React.FC<{ domainId: string }> = ({
  domainId,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [userToBeDeleted, setUserToBeDeleted] =
    useState<
      Maybe<
        NonNullable<
          PeopleQueryPageQuery["internal"]["domainById"]
        >["users"][number]
      >
    >(nothing);
  const { data, error, loading } = usePeopleQueryPageQuery({
    variables: { domainId },
  });
  const [undoOffboarding] = useUndoOffboardingMutation();
  const [deleteUser] = useDeleteUserMutation({
    refetchQueries: [
      { query: PeopleQueryPageDocument, variables: { domainId } },
    ],
  });
  if (error) {
    LogError(error);
    return null;
  }
  if (loading) {
    return <FullPageSpinner />;
  }
  if (!data) {
    LogError(new Error("Bad fetch for GetDomainInfoForQueryPage"));
    return null;
  }
  return (
    <Container>
      <H2>People</H2>
      <InputGroup
        leftIcon={IconNames.SEARCH}
        placeholder="Search people"
        type="search"
        value={searchTerm}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setSearchTerm(e.target.value)
        }
      />
      {data.internal.domainById.users
        .filter(u => searchTerm.length === 0 || u.email.includes(searchTerm))
        .map(user => (
          <Row key={user.id}>
            <div>{user.email}</div>
            <div>
              {!user.isFromScan ? (
                <Button onClick={() => setUserToBeDeleted(user)}>
                  Delete user
                </Button>
              ) : null}
              {!user.isFromScan && !user.isActive ? (
                <Button
                  onClick={() => {
                    undoOffboarding({ variables: { userId: user.id } }).catch(
                      e => {
                        AppToaster.show({
                          message: e.message,
                          intent: Intent.WARNING,
                        });
                      }
                    );
                  }}
                >
                  Undo offboarding
                </Button>
              ) : null}
            </div>
          </Row>
        ))}
      <CancelConfirmDialog
        body={
          <p>You are about to delete user "{userToBeDeleted?.displayName}".</p>
        }
        confirmText="Delete"
        isOpen={isSome(userToBeDeleted)}
        onClose={() => setUserToBeDeleted(nothing)}
        onConfirm={() => {
          if (!isSome(userToBeDeleted)) {
            return;
          }
          deleteUser({
            variables: { domainId, input: { userId: userToBeDeleted?.id } },
          })
            .then(x => {
              if (x.data?.deleteUser.status === MutationStatus.SUCCESS) {
                setUserToBeDeleted(nothing);
              } else {
                AppToaster.show({
                  message:
                    x.data?.deleteUser.message ?? "User could not be deleted",
                  intent: Intent.WARNING,
                });
              }
            })
            .catch(e => {
              AppToaster.show({
                message: e.message,
                intent: Intent.WARNING,
              });
            });
        }}
        title="Are you sure?"
      />
    </Container>
  );
};

const Container = styled.div`
  padding: ${2 * GRID_SPACING}px ${2 * GRID_SPACING}px ${GRID_SPACING}px 0;
`;

const Row = styled.div`
  border-bottom: ${BORDER_LINE};
  display: flex;
  justify-content: space-between;
  padding: ${2 * GRID_SPACING}px 0;
`;

gql`
  query PeopleQueryPage($domainId: ID!) {
    internal {
      domainById(id: $domainId) {
        id
        users(includeRemovedUsers: true, includeNonHumanUsers: true) {
          id
          displayName
          email
          isActive
          isFromScan
        }
      }
    }
  }

  mutation UndoOffboarding($userId: ID!) {
    unremoveUser(userId: $userId) {
      id
      isActive
    }
  }

  mutation DeleteUser($domainId: ID!, $input: DeleteUserInput!) {
    deleteUser(domainId: $domainId, input: $input) {
      status
      message
    }
  }
`;
