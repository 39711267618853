import React from "react";
import type { FlattenSimpleInterpolation } from "styled-components";
import styled, { css } from "styled-components";

import { renderControlDescription } from "../../../../helpers/common";
import { DataTable, OverflowTableStyle } from "../../components/data-table";
import { formatWhitelistingComments } from "./edit-work-paper-dialog";
import type {
  IComplianceRequirement,
  IWorkPaperRow,
} from "./work-paper-detail-container";

interface IComplianceRequirementTableProps {
  requirement: IComplianceRequirement;
  workPaperContents: IWorkPaperRow[];
  onRowClick: (test: IWorkPaperRow) => void;
}

const COLUMN_WIDTHS: { [colName: string]: string[] } = {
  controlName: ["192px", "192px"],
  controlDescription: ["512px", "288px"],
  testApplied: ["512px", "288px"],
  testResultsAuditor: ["192px", "148px"],
  commentsAuditor: ["384px", "148px"],
  testStatusVanta: ["192px", "148px"],
  commentsVanta: ["384px", "148px"],
};

const COLUMN_STYLES: { [colName: string]: FlattenSimpleInterpolation } = {};
Object.keys(COLUMN_WIDTHS).forEach(key => {
  COLUMN_STYLES[key] = css`
    &&& {
      max-width: ${COLUMN_WIDTHS[key][0]};
      min-width: ${COLUMN_WIDTHS[key][1]};
    }
  `;
});

const TABLE_STYLE = css`
  &&& {
    // need this for cell widths to be respected
    width: inherit;
    table-layout: fixed;
    border-collapse: collapse;
  }
  tbody tr {
    &:hover {
      cursor: pointer;
      background: #f7f7f7;
    }
  }
`;

export const ComplianceRequirementTable: React.FC<IComplianceRequirementTableProps> =
  ({ onRowClick, requirement, workPaperContents: workPaperTests }) => {
    const requirementTests = workPaperTests.filter(
      test => requirement.criterion === test.criterion
    );

    return (
      <RequirementContainer>
        <h4> {requirement.criterion} </h4>
        <h5> {requirement.description} </h5>
        <DataTable
          data={requirementTests}
          columnStyles={COLUMN_STYLES}
          columnOrder={[
            "controlName",
            "controlDescription",
            "testApplied",
            "testResultsAuditor",
            "commentsAuditor",
            "testStatusVanta",
            "commentsVanta",
          ]}
          header={{
            controlName: "Control name",
            controlDescription: "Control description",
            testApplied: "Test applied",
            testResultsAuditor: "Test results (auditor)",
            commentsAuditor: "Comments (auditor)",
            testStatusVanta: "Test status (Vanta)",
            commentsVanta: "Comments (Vanta)",
          }}
          createRow={createWorkPaperRow}
          tableStyle={[OverflowTableStyle, TABLE_STYLE]}
          onRowClick={onRowClick}
          emptyDefault={
            "Vanta is not currently monitoring this requirement for this customer."
          }
        />
      </RequirementContainer>
    );
  };

const RequirementContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 20px;
`;

const createWorkPaperRow = (test: IWorkPaperRow) => {
  return {
    controlName: test.controlName,
    controlDescription: renderControlDescription(test.controlDescription),
    testApplied: test.testApplied,
    testResultsAuditor: test.testResultsAuditor,
    commentsAuditor: test.commentsAuditor,
    testStatusVanta: test.testStatusVanta,
    commentsVanta: formatWhitelistingComments(test.commentsVanta),
  };
};
