/**
 * Helper functions and internal schema information for Vanta Attributes.
 */
import { TypedObjectKeys } from "../base/types/helpers";
import type { Maybe, Nothing } from "../base/types/maybe";
import { isSome } from "../base/types/maybe";
import type { VantaAttributes } from "../resources/declare-resource";

export enum VantaAttributeSentinelValues {
  TRUE = "1",
  FALSE = "0",
}

type Deserializer<T> = (value: string) => T;
const BooleanDeserializer: Deserializer<boolean> = (value: string) =>
  ["true", VantaAttributeSentinelValues.TRUE].includes(
    value.toLocaleLowerCase()
  );
// no-op
const StringDeserializer: Deserializer<string> = (value: string) => value;

interface AttributeDefinition {
  key: string;
  deserializer: Deserializer<any>;
}

/**
 * For each reserved attribute, include a key that specifies the
 * corresponding key value in the list of VantaAttributes and a
 * deserializer function to be used when deserializing into an object
 * (see deserializeVantaAttributes below).
 *
 */
export const ReservedVantaAttributes = {
  excluded: {
    key: "excluded",
    deserializer: BooleanDeserializer,
  },
  description: {
    key: "description",
    deserializer: StringDeserializer,
  },
  containsUserData: {
    key: "containsuserdata",
    deserializer: BooleanDeserializer,
  },
  nonProd: {
    key: "nonprod",
    deserializer: BooleanDeserializer,
  },
  noAlert: {
    key: "noalert",
    deserializer: StringDeserializer,
  },
  containsEPHI: {
    key: "containsephi",
    deserializer: BooleanDeserializer,
  },
  userDataStored: {
    key: "userdatastored",
    deserializer: StringDeserializer,
  },
  // The value of this field can be either the userId (if set from inventory
  // page) or the user's email (if set from resource fetch)
  ownerId: {
    key: "ownerid",
    deserializer: StringDeserializer,
  },
  // For container repositories: allows users to configure fetches to pull
  // vulnerabilities from images with a tag containing the specified string
  targetImageScanTag: {
    key: "targetimagescantag",
    deserializer: StringDeserializer,
  },
  // For trainings: identifies a specific training as fulfilling general/HIPAA SAT
  isGeneralSat: {
    key: "generalsat",
    deserializer: BooleanDeserializer,
  },
  isHipaaSat: {
    key: "hipaasat",
    deserializer: BooleanDeserializer,
  },
  isPciSat: {
    key: "pcisat",
    deserializer: BooleanDeserializer,
  },
  isGdprSat: {
    key: "gdprsat",
    deserializer: BooleanDeserializer,
  },
} as const;

type ReservedVantaAttributesType = typeof ReservedVantaAttributes;
export type ReservedVantaAttribute =
  ReservedVantaAttributesType[keyof ReservedVantaAttributesType]["key"];
export type DeserializedVantaAttributes = {
  -readonly [F in keyof ReservedVantaAttributesType]?: Maybe<
    ReservedVantaAttributesType[F]["deserializer"] extends Deserializer<infer U>
      ? U
      : Nothing
  >;
};

/**
 * Deserialize a given a list of Vanta Attributes into an object with
 * field names mirroring those of ReservedVantaAttributes and using each
 * attribute's optionally defined deserializer.
 *
 * NOTE: VantaAttributes should not be accessed directly in their raw form,
 *   instead always call this deserialize function first
 */
export function deserializeVantaAttributes(
  vantaAttributes: VantaAttributes
): DeserializedVantaAttributes {
  const deserialized: DeserializedVantaAttributes = {};
  TypedObjectKeys(ReservedVantaAttributes).map(fieldName => {
    const { key, deserializer } = ReservedVantaAttributes[
      fieldName
    ] as AttributeDefinition;
    const stringValue = vantaAttributes.find(
      attribute => attribute.key === key
    )?.value;
    if (!isSome(stringValue)) {
      return;
    }
    const value = deserializer(stringValue);
    deserialized[fieldName] = value;
  });
  return deserialized;
}

export function getExternallyManagedVantaAttributes(
  vantaAttributes: VantaAttributes
): Set<string> {
  return new Set(
    vantaAttributes
      .filter(attribute => attribute.managedExternally)
      .map(attribute => attribute.key)
  );
}

/**
 * Get the deserialized value for the specified reserved VantaAttribute,
 * or null if the list of attributes doesn't contain it.
 */
export function getValueForReservedVantaAttribute<
  T extends AttributeDefinition
>(
  reservedVantaAttribute: T,
  vantaAttributes: VantaAttributes
): Maybe<ReturnType<T["deserializer"]>> {
  const { key, deserializer } = reservedVantaAttribute;
  const stringValue = vantaAttributes.find(
    attribute => attribute.key === key
  )?.value;
  if (!isSome(stringValue)) {
    return null;
  }
  return deserializer(stringValue);
}

export function hasExternallyManagedVantaAttributes(
  vantaAttributes: VantaAttributes
) {
  return vantaAttributes.some(attribute => attribute.managedExternally);
}

export const IN_SCOPE_CRITERIA = {
  vantaAttributes: {
    $not: {
      $elemMatch: {
        key: ReservedVantaAttributes.excluded.key,
        value: VantaAttributeSentinelValues.TRUE,
      },
    },
  },
};
