import type { IQuestionSchema } from "../../forms/interfaces";

export const generalSecurity4: IQuestionSchema = {
  id: "generalSecurity4",
  title: "Product authentication",
  questions: [
    {
      id: "productAuthHeader-2018-09-10",
      label: "Product authentication",
      name: "productAuthHeader",
      required: false,
      type: "Header",
    },
    {
      id: "encryptionAtRest-2018_09_10",
      label: "Data encrypted at rest",
      name: "encryptionAtRest",
      required: false,
      type: "CheckboxInput",
    },
    {
      id: "vendorAccessAuthentication-2018_09_10",
      label: "Log in with OAuth, SSO, or SAML provided",
      name: "vendorAccessAuthentication",
      required: false,
      type: "CheckboxInput",
    },
    {
      id: "mfaOffered-2018_09_10",
      label: "Two-factor authentication offered",
      name: "mfaOffered",
      required: false,
      type: "CheckboxInput",
    },
  ],
  submitText: "Submit authentication info",
  version: "2018-09-10",
};
