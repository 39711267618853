import type { IQuestionSchema } from "../../forms/interfaces";

export const dataManagementDisasterRecovery: IQuestionSchema = {
  id: "dataManagementDisasterRecovery",
  title: "Backups and disaster recovery",
  questions: [
    {
      id: "backupsDRHeader-2018-09-10",
      label: "Backups and disaster recovery",
      name: "backupsDRHeader",
      required: false,
      type: "Header",
    },
    {
      choices: [
        "Daily",
        "Weekly",
        "Monthly",
        "Quarterly",
        "Databases are not backed up",
      ],
      id: "dataBackups1-2018_07_26",
      label: "At least how often are databases backed up?",
      name: "dbBackupFrequency",
      required: true,
      type: "SelectInput",
    },
    {
      choices: [
        "Daily",
        "Weekly",
        "Monthly",
        "Quarterly",
        "Databases are not backed up",
      ],
      id: "dataBackups3-2018_07_26",
      label: "How often are data backups tested for readability and recovery?",
      name: "dbBackupTestsFrequency",
      required: true,
      type: "SelectInput",
    },
    {
      id: "disasterRecovery2-2018_07_26",
      label: "Upload your Disaster Recovery Plan, if the company has one.",
      name: "vaqDrpFile",
      required: false,
      type: "File",
    },
    {
      id: "disasterRecovery3-2018_07_26",
      label: "Date the Disaster Recovery Plan was last reviewed.",
      name: "drpReviewDate",
      required: false,
      type: "Date",
    },
    {
      id: "disasterRecovery4-2018_07_26",
      label: "Date of last Disaster Recovery test.",
      name: "drpTestDate",
      required: false,
      type: "Date",
    },
  ],
  submitText: "Submit backup and disaster recovery info",
  version: "2018-07-26",
};
