import { simplePlural } from "common/grammar/plurals";
import React from "react";
import styled from "styled-components";
import { BASE_PALETTE } from "../../base/colors";
import { Button2 } from "../button2/button2";
import { SpacedList } from "../primitives/spaced-list";

interface IPaginationRowProps {
  totalNumItems: number;
  /**
   * index should be zero-indexed
   */
  startIndex: number;

  onNextPage(): void;
  onPreviousPage(): void;
}

export const TABLE_PAGINATION_DEFAULT = 20;

export const PaginationRow: React.FC<IPaginationRowProps> = ({
  totalNumItems,
  startIndex,
  onNextPage,
  onPreviousPage,
}) => {
  const endIndex = Math.min(
    startIndex + TABLE_PAGINATION_DEFAULT - 1,
    totalNumItems - 1
  );

  const canGoBack = startIndex > 0;
  const canGoForward = endIndex < totalNumItems - 1;
  return (
    <StyledDiv>
      <StyledSpan>
        {simplePlural(
          totalNumItems === 0 ? 0 : endIndex - startIndex + 1,
          "result"
        )}
      </StyledSpan>
      <SpacedList>
        <Button2 disabled={!canGoBack} onClick={onPreviousPage}>
          Previous
        </Button2>
        <Button2 disabled={!canGoForward} onClick={onNextPage}>
          Next
        </Button2>
      </SpacedList>
    </StyledDiv>
  );
};

const StyledSpan = styled.span`
  font-size: 14px;
  line-height: 16px;
  color: ${BASE_PALETTE.INK};
`;

const StyledDiv = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  border-top: 1px solid ${BASE_PALETTE.WIND};
`;
