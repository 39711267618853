import { isSome, nothing } from "common/base/types/maybe";
import React from "react";
import { VulnerabilityPageInfo } from "../common/vulnerability-page-info";
import type { Instance } from "./instance-detail";

interface IProps {
  instance: Instance;
}

export const InstanceInfo: React.FC<IProps> = ({ instance }) => {
  const leftTablePairs: Array<{
    key: string;
    value: string;
  }> = [];
  const rightTablePairs: Array<{ key: string; value: string }> = [];

  if (instance.__typename !== "SpecificEC2InstanceResource") {
    return <div />;
  }

  const instanceId = instance.instanceId;
  leftTablePairs.push({ key: "instance id", value: instanceId });

  const instanceName = instance.name ?? nothing;
  if (isSome(instanceName)) {
    leftTablePairs.push({ key: "instance name", value: instanceName });
  }

  const publicIp = instance.publicIPAddress ?? nothing;
  if (isSome(publicIp)) {
    leftTablePairs.push({
      key: "public ip address",
      value: publicIp,
    });
  }

  const privateIp = instance.privateIPAddress ?? nothing;
  if (isSome(privateIp)) {
    leftTablePairs.push({
      key: "private ip address",
      value: privateIp,
    });
  }

  const awsAccountId = instance.account;
  rightTablePairs.push({
    key: "aws account id",
    value: awsAccountId,
  });

  return (
    <VulnerabilityPageInfo
      leftTablePairs={leftTablePairs}
      rightTablePairs={rightTablePairs}
      leftColumnWidths={["140px", "320px"]}
      rightColumnWidths={["140px", "320px"]}
    />
  );
};
