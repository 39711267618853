import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import React from "react";

import { H2, TextLinkLike } from "../../../alpaca/components";
import { LogError } from "../../../errors";
import type { GetInventoryItemsForRiskAssessmentQuery } from "../../../gen/components";
import {
  GetInventoryItemsForRiskAssessmentDocument,
  useUnDismissRiskMutation,
} from "../../../gen/components";

interface IProps {
  riskId: string;
}

export const DismissedRiskPanel: React.FC<IProps> = ({ riskId }) => {
  const [revisitRisk] = useUnDismissRiskMutation({
    update: (cache, result) => {
      const dismissalToDelete = result.data?.removeRiskDismissal;
      if (!isSome(dismissalToDelete)) {
        return;
      }
      const previousData =
        cache.readQuery<GetInventoryItemsForRiskAssessmentQuery>({
          query: GetInventoryItemsForRiskAssessmentDocument,
        });
      if (!isSome(previousData) || !isSome(previousData.organization)) {
        return;
      }

      cache.writeQuery<GetInventoryItemsForRiskAssessmentQuery>({
        query: GetInventoryItemsForRiskAssessmentDocument,
        data: {
          organization: {
            ...previousData.organization,
            riskDismissals: previousData.organization.riskDismissals.filter(
              item => item.id !== dismissalToDelete.id
            ),
          },
        },
      });
    },
  });
  return (
    <div>
      <H2>This risk has been dismissed</H2>
      <TextLinkLike
        as="div"
        onClick={() => {
          revisitRisk({ variables: { riskId } }).catch(LogError);
        }}
      >
        I would like to revisit this risk
      </TextLinkLike>
    </div>
  );
};

gql`
  mutation unDismissRisk($riskId: String!) {
    removeRiskDismissal(riskId: $riskId) {
      id
      riskId
    }
  }
`;
