import "./panel.scss";
import "./query-page.scss";

import type { IPanelProps } from "@blueprintjs/core";
import { Button, H5, HTMLTable, Icon, Intent } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import moment from "moment";
import React from "react";

import { LogError, LogErrorMessage } from "../../errors";
import type { GetPendingAuditsQuery } from "../../gen/components";
import { useGetPendingAuditsQuery } from "../../gen/components";
import { FullPageSpinner } from "../helpers/FullPageSpinner";
import { InfoIcon } from "../helpers/InfoIcon";
import { AuditDateRangePanel } from "./audit-date-range-panel";
import { ScheduleAuditPanel } from "./schedule-audit-panel";

const AUDIT_DATE_FORMAT = "M/D/YYYY";

interface IProps {
  pendingAudits: GetPendingAuditsQuery["audits"];
}

export type IDatedAudit = GetPendingAuditsQuery["audits"][number] & {
  startMoment: moment.Moment;
  endMoment: moment.Moment;
};

interface IState {
  audits: { [key: string]: IDatedAudit };
  auditDomainsVisibleFor: Maybe<string>;
}

class AuditCalendarComponent extends React.Component<
  IProps & IPanelProps,
  IState
> {
  public constructor(props: IProps & IPanelProps) {
    super(props);
    this.state = {
      audits: this.makeAudits(),
      auditDomainsVisibleFor: null,
    };
  }

  public render() {
    const openScheduleAuditPanel = () => {
      this.props.openPanel({
        component: ScheduleAuditPanel,
        title: "Schedule Audit",
        props: {},
      });
    };

    const scheduleButton = (
      <Button
        className="qp-section-bottom"
        type="button"
        intent={Intent.PRIMARY}
        rightIcon="timeline-events"
        onClick={openScheduleAuditPanel}
      >
        Schedule Audit
      </Button>
    );

    return (
      <div>
        {scheduleButton}
        {this.renderAllAudits()}
      </div>
    );
  }

  private renderAllAudits() {
    const today = moment(new Date()).startOf("day");
    const twoWeeksLater = moment(today).add(2, "weeks");

    const isOngoingAudit = (audit: IDatedAudit) =>
      audit.startMoment.isSameOrBefore(today, "days") &&
      audit.endMoment.isSameOrAfter(today, "days");
    const isAuditInTwoWeeks = (audit: IDatedAudit) =>
      audit.startMoment.isBetween(today, twoWeeksLater, "days", "(]");
    const isAuditAfterTwoWeeks = (audit: IDatedAudit) =>
      audit.startMoment.isAfter(twoWeeksLater, "days");

    const ongoingAudits: IDatedAudit[] = [];
    const inTwoWeeksAudits: IDatedAudit[] = [];
    const afterTwoWeeksAudits: IDatedAudit[] = [];

    Object.values(this.state.audits).forEach((audit: IDatedAudit) => {
      if (isOngoingAudit(audit)) {
        ongoingAudits.push(audit);
      } else if (isAuditInTwoWeeks(audit)) {
        inTwoWeeksAudits.push(audit);
      } else if (isAuditAfterTwoWeeks(audit)) {
        afterTwoWeeksAudits.push(audit);
      }
    });

    return (
      <div>
        {this.renderAudits(ongoingAudits, "Under audit")}
        {this.renderAudits(inTwoWeeksAudits, "In two weeks")}
        {this.renderAudits(afterTwoWeeksAudits, "Later")}
      </div>
    );
  }

  private renderAudits(audits: IDatedAudit[], title: string) {
    audits.sort((x, y) => {
      if (!x.endMoment.isSame(y.endMoment, "day")) {
        return x.endMoment.isBefore(y.endMoment) ? -1 : 1;
      }
      return x.domain.name.localeCompare(y.domain.name);
    });

    const showAudit = (audit: IDatedAudit) => {
      const coloredIcon =
        audit.domain.numFailingTestResults === 0 ? (
          <Icon icon="tick-circle" color="green" />
        ) : (
          <Icon icon="issue" color="red" />
        );

      return (
        <tr key={audit.id} id={audit.id}>
          <td> {coloredIcon} </td>
          <td> {audit.domain.name} </td>
          <td onClick={() => this.onDateRange(audit)} className="qp-pointed">
            {audit.startMoment.format(AUDIT_DATE_FORMAT)}
            {" - "}
            {audit.endMoment.format(AUDIT_DATE_FORMAT)}
          </td>
          <td> {audit.domain.numFailingTestResults} issues </td>
          <td>
            {audit.auditType}{" "}
            <InfoIcon
              large={false}
              content="Audit SOC 2 domains are managed with beta features."
            />
          </td>
          <td>
            {/* once we get rid of "dummy_val" we can remove this isSome check */}
            {isSome(audit.auditFirmInfo)
              ? audit.auditFirmInfo.firmName
              : "Unknown"}
          </td>
        </tr>
      );
    };

    const noAudits =
      audits.length === 0 ? (
        <tr>
          <td> No audits found. </td>
        </tr>
      ) : undefined;

    return (
      <div>
        <H5> {title} </H5>
        <HTMLTable className="qp-section-bottom">
          <tbody>
            {audits.map(showAudit)}
            {noAudits}
          </tbody>
        </HTMLTable>
      </div>
    );
  }

  private addMoments(audit: GetPendingAuditsQuery["audits"][number]) {
    return {
      ...audit,
      startMoment: moment(parseInt(audit.auditStart, 10)).startOf("days"),
      endMoment: moment(parseInt(audit.auditStart, 10))
        .endOf("days")
        .add(audit.auditPeriodDays, "days"),
    };
  }

  private makeAudits() {
    return this.props.pendingAudits
      .map(this.addMoments)
      .reduce(
        (
          map: { [id: string]: GetPendingAuditsQuery["audits"][number] },
          audit: IDatedAudit
        ) => {
          map[audit.id] = audit;
          return map;
        },
        {}
      ) as { [key: string]: IDatedAudit };
  }

  private onDateRange(audit: IDatedAudit) {
    this.props.openPanel({
      component: AuditDateRangePanel,
      title: "Modify Audit Date Range",
      props: {
        audit,
      },
    });
  }
}

gql`
  query GetPendingAudits {
    audits(getPendingAudits: true) {
      id
      auditorUserIds
      auditPeriodDays
      auditStart
      auditType
      domain {
        id
        name
        numFailingTestResults
      }
      auditFirmInfo {
        id
        firmName
      }
    }
  }
`;

export const AuditCalendarPanel: React.FC<IProps & IPanelProps> = props => {
  const { error, loading, data } = useGetPendingAuditsQuery({
    fetchPolicy: "network-only",
  });
  if (error) {
    LogError(error);
    return null;
  }
  if (loading) {
    return <FullPageSpinner />;
  }
  if (!data) {
    LogErrorMessage("Bad fetch");
    return null;
  }
  return <AuditCalendarComponent {...props} pendingAudits={data.audits} />;
};
