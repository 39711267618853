import { dropNothing, isSome } from "common/base/types/maybe";
import { TestEntityTypeDisplayNames } from "common/constants/displayNames";
import gql from "graphql-tag";
import React from "react";

import { GRID_SPACING } from "../../../alpaca/base/grid";
import { LogError, LogErrorMessage } from "../../../errors";
import type {
  FetchNonAgentVulnerabilitiesForFailDataQuery,
  SpecificResource,
} from "../../../gen/components";
import { useFetchNonAgentVulnerabilitiesForFailDataQuery } from "../../../gen/components";
import type { ISLAMissRow } from "../fail-data-from-sla-miss";
import { FailDataFromSLAMiss } from "../fail-data-from-sla-miss";

const MAX_INSPECTOR_PACKAGE_NAMES_TO_SHOW = 4;
const INSPECTOR_PACKAGE_LIST_INDENT = `${GRID_SPACING * 3}px`;

export const VulnerabilitySLATestEntities: React.FC<{
  entityType: SpecificResource;
  entityIds: string[];
  testId: string;
  allowWhitelisting: boolean;
  renderingIndividually: boolean;
  first: number;
}> = ({
  entityType,
  entityIds,
  testId,
  allowWhitelisting,
  renderingIndividually,
  first,
}) => {
  const { error, loading, data } =
    useFetchNonAgentVulnerabilitiesForFailDataQuery({
      variables: { ids: entityIds, specificResourceType: entityType },
    });
  if (error) {
    LogError(error);
    return <div />;
  }
  if (loading) {
    return <div />;
  }
  if (!data?.organization) {
    LogErrorMessage("Bad fetch");
    return <div />;
  }
  return (
    <FailDataFromVulnerabilityInternal
      testId={testId}
      entityType={entityType}
      vulnerabilityHistory={data.organization.resourcesByIds}
      allowWhitelisting={allowWhitelisting}
      renderingIndividually={renderingIndividually}
      first={first}
    />
  );
};

interface IInternalProps {
  vulnerabilityHistory: NonNullable<
    FetchNonAgentVulnerabilitiesForFailDataQuery["organization"]
  >["resourcesByIds"];
  testId: string;
  allowWhitelisting: boolean;
  renderingIndividually: boolean;
  entityType: SpecificResource;
  first: number;
}

type InternalVulnerability = IInternalProps["vulnerabilityHistory"][number];

const FailDataFromVulnerabilityInternal: React.FC<IInternalProps> = ({
  vulnerabilityHistory,
  testId,
  entityType,
  allowWhitelisting,
  renderingIndividually,
  first,
}) => {
  const openVulnerabilities: IInternalProps["vulnerabilityHistory"] = [];
  const closedVulnerabilities: IInternalProps["vulnerabilityHistory"] = [];

  dropNothing(vulnerabilityHistory).forEach(vh => {
    if (isSome(vh.deletedAt)) {
      closedVulnerabilities.push(vh);
    } else {
      openVulnerabilities.push(vh);
    }
  });

  const openItems: ISLAMissRow[] = openVulnerabilities
    .map(v => renderVulnerability(v))
    .flat();

  const closedItems: ISLAMissRow[] = closedVulnerabilities
    .map(v => renderVulnerability(v))
    .flat();

  return (
    <FailDataFromSLAMiss
      header={
        renderingIndividually
          ? undefined
          : TestEntityTypeDisplayNames[entityType]
      }
      testId={testId}
      openItems={openItems}
      closedItems={closedItems}
      type="vulnerability"
      allowWhitelisting={allowWhitelisting}
      first={first}
    />
  );

  // map a db vuln entry (package + version to upgrade to + open/close dates)
  // to UI vuln entries (same package and version, plus affected machines + title)
  function renderVulnerability(vulnerability: InternalVulnerability) {
    return {
      element: (
        <span>
          {internalVulnToTitle(vulnerability)}
          {` on ${internalVulnToSource(vulnerability)}`}
        </span>
      ),
      entity: {
        entityId: vulnerability.id,
        entityType,
      },
      openDate: new Date(vulnerability.createdAt),
      closeDate: isSome(vulnerability.deletedAt)
        ? new Date(vulnerability.deletedAt)
        : null,
    };
  }

  // AWS Inspector vuln packageIdentifier field is a string of comma-separated
  // package IDs; limit the output to 5 items (including ellipsis)
  function listOfPackagesForAwsInspectorVulns(pkgIdentifier: string) {
    const idArray = pkgIdentifier.split(", ");
    const numTotalPkgs = idArray.length;
    const samplePkgs = idArray.slice(0, MAX_INSPECTOR_PACKAGE_NAMES_TO_SHOW);
    const numNotShowing = numTotalPkgs - samplePkgs.length;
    const list = samplePkgs.map(pkgId => (
      <div style={{ marginLeft: INSPECTOR_PACKAGE_LIST_INDENT }} key={pkgId}>
        {pkgId}
      </div>
    ));
    if (numNotShowing > 0) {
      list.push(
        <div style={{ marginLeft: INSPECTOR_PACKAGE_LIST_INDENT }} key="more">
          and {numNotShowing} more...
        </div>
      );
    }
    // Add 'For:' to the front of the list to make output more readable
    return [<div key="for">For:</div>, ...list];
  }

  function internalVulnToTitle(v: InternalVulnerability) {
    if (
      v.__typename === "SpecificAwsContainerVulnerabilityResource" ||
      v.__typename === "SpecificGCPContainerVulnerabilityResource"
    ) {
      return v.packageIdentifier;
    } else if (v.__typename === "SpecificAwsInspectorVulnerabilityResource") {
      return listOfPackagesForAwsInspectorVulns(v.packageIdentifier);
    } else if (v.__typename === "SpecificAzureContainerVulnerabilityResource") {
      return v.displayName;
    } else if (v.__typename === "SpecificSnykVulnerabilityResource") {
      return `${v.packageName} - ${v.title}`;
    } else {
      return "Vulnerability";
    }
  }
  function internalVulnToSource(v: InternalVulnerability) {
    if (
      v.__typename === "SpecificAwsContainerVulnerabilityResource" ||
      v.__typename === "SpecificGCPContainerVulnerabilityResource" ||
      v.__typename === "SpecificAzureContainerVulnerabilityResource"
    ) {
      return v.repositoryName;
    } else if (v.__typename === "SpecificAwsInspectorVulnerabilityResource") {
      return v.instanceId;
    } else if (v.__typename === "SpecificSnykVulnerabilityResource") {
      return v.projectName;
    } else {
      return "unknown";
    }
  }
};

gql`
  query fetchNonAgentVulnerabilitiesForFailData(
    $ids: [String!]!
    $specificResourceType: SpecificResource!
  ) {
    organization {
      id
      resourcesByIds(ids: $ids, specificResourceType: $specificResourceType) {
        id
        createdAt
        deletedAt
        displayName
        domainId
        externalURL
        fetchId
        service
        uniqueId
        updatedAt
        ... on SpecificAwsContainerVulnerabilityResource {
          packageIdentifier
          repositoryName
          slaDeadline
          resolvedWithinSLA
        }
        ... on SpecificAwsInspectorVulnerabilityResource {
          packageIdentifier
          instanceId
          slaDeadline
          resolvedWithinSLA
        }
        ... on SpecificAzureContainerVulnerabilityResource {
          displayName
          repositoryName
          slaDeadline
          resolvedWithinSLA
        }
        ... on SpecificGCPContainerVulnerabilityResource {
          packageIdentifier
          repositoryName
          slaDeadline
          resolvedWithinSLA
        }
        ... on SpecificSnykVulnerabilityResource {
          title
          packageName
          projectName
          slaDeadline
          resolvedWithinSLA
        }
      }
    }
  }
`;
