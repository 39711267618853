export const IconNames = {
  ARROW_LEFT: "arrowleft",
  ATTACHMENT: "attachment",
  BRIEFCASE: "briefcase",
  CALENDAR: "calendar",
  CARET_LEFT: "caret-left",
  CARET_RIGHT: "caret-right",
  CARET_UP: "caret-up",
  CARET_DOWN: "caret-down",
  CAUTION: "caution",
  CHECKMARK: "checkmark",
  CHEVRON_DOWN: "chevron-down",
  CLOCK: "clock",
  CLOSE: "close",
  CONNECT: "connect",
  COPY: "copy",
  DANGER: "danger",
  DELETE: "delete",
  DOOR: "door",
  DOWNLOAD: "download",
  EDIT: "edit",
  EMAIL: "email",
  FINGERPRINT: "fingerprint",
  FLAG_HIGH: "flag-high",
  FLAG_LOW: "flag-low",
  FLAG_MEDIUM: "flag-medium",
  HISTORY: "history",
  IGNORE: "ignore",
  INFO: "info",
  KEY_ALERT: "key-alert",
  KEY_ROTATE: "key-rotate",
  LINK: "link",
  LOCK_ALERT: "lock-alert",
  LOCK_CIRCLE: "lock-circle",
  LOCK_OPEN: "lock-open",
  LOCK: "lock",
  PERSON_ALERT: "person-alert",
  PERSON_INFO: "person-info",
  PLUS: "plus",
  RENEW: "renew",
  SEARCH: "search",
  SUCCESS: "success",
  UPLOAD: "upload",
  VIEW: "view",
} as const;

export type IconName = typeof IconNames[keyof typeof IconNames];
