import { InputGroup, Spinner } from "@blueprintjs/core";
import type { SortParams } from "common/base/types/gen";
import type { Maybe } from "common/base/types/maybe";
import { dropNothing, isSome, nothing } from "common/base/types/maybe";
import gql from "graphql-tag";
import React, { useState } from "react";
import { useHistory } from "react-router";

import { LogError } from "../../../../errors";
import type { FetchVulnerablePackagesQuery } from "../../../../gen/components";
import {
  FetchVulnerablePackagesDocument,
  useFetchVulnerablePackagesQuery,
} from "../../../../gen/components";
import type { PaginationParams } from "../../../helpers/generic-paginator";
import {
  DEFAULT_ITEMS_PER_PAGE_OPTION,
  applyPaginationParams,
  GenericPaginator,
  getInitialPaginationParams,
} from "../../../helpers/generic-paginator";
import {
  AgentViewByButtons,
  ControlLabel,
  ControlsTopPanel,
  EntryListContainer,
  InfoCalloutContainer,
  InfoCalloutHeading,
  InfoCalloutText,
  LeftControls,
  SortFilterButton,
  TableContainer,
} from "../common/components";
import {
  earliestSlaDeadline,
  severityLevelForNumber,
  vulnToTitle,
  getDebounceContext,
  getFilterParams,
} from "../utils";
import { VulnSearchResultSummary } from "../vuln-search-result-summary";
import { IgnoreVulnDialog } from "./ignore-vuln-dialog";
import { PackageVulnEntry } from "./package-vuln-entry";

export type VulnerablePackage = NonNullable<
  NonNullable<
    FetchVulnerablePackagesQuery["organization"]
  >["packageVulnerabilities"]["edges"][0]["node"]
>;

const GetEarliestDeadlineForVulnerablePackage = (
  vulnerablePackage: VulnerablePackage
) => {
  if (!isSome(vulnerablePackage?.machineInstances)) {
    return nothing;
  }
  const vulnerabilityInstances = dropNothing(
    vulnerablePackage!.machineInstances.map(m => m.vulnData)
  );
  return earliestSlaDeadline(vulnerabilityInstances);
};

export const AgentPackageVulnerabilitiesList: React.FC = () => {
  const history = useHistory();
  const [vulnToIgnore, setVulnToIgnore] =
    useState<Maybe<VulnerablePackage>>(null);
  const [paginationParams, setPaginationParams] = useState<PaginationParams>(
    getInitialPaginationParams()
  );
  const [searchString, setSearchString] = useState<string>("");
  const [sortParams, setSortParams] = useState<SortParams>({
    direction: 1,
    field: "minSlaDeadline",
  });
  const { error, loading, data, fetchMore } = useFetchVulnerablePackagesQuery({
    variables: {
      first: DEFAULT_ITEMS_PER_PAGE_OPTION,
      sortParams,
      filterParams: getFilterParams(searchString, [
        "packageVersion",
        "securityVersion",
        "packageSource",
      ]),
    },
    context: getDebounceContext("agent-package-vulnerabilities-list"),
  });

  if (error) {
    LogError(error);
    return <div />;
  }

  const vulnerablePackages = applyPaginationParams(
    data?.organization.packageVulnerabilities?.edges.map(e => e.node) ?? [],
    paginationParams
  );

  const noVulnerabilitiesCallout = (
    <InfoCalloutContainer>
      <InfoCalloutHeading>
        All monitored servers are free of known package vulnerabilities
      </InfoCalloutHeading>
      <InfoCalloutText>
        No known package vulnerabilities were detected on your connected
        servers.
      </InfoCalloutText>
    </InfoCalloutContainer>
  );

  const pageInfo = data?.organization.packageVulnerabilities?.pageInfo;
  return (
    <TableContainer>
      <ControlsTopPanel>
        <LeftControls>
          <InputGroup
            type="search"
            value={searchString}
            onChange={e => setSearchString(e.target.value)}
            placeholder="Search by name"
          />
          <AgentViewByButtons />
          <ControlLabel>Sort by:</ControlLabel>
          <SortFilterButton
            key="minSlaDeadline"
            secondary={sortParams.field === "minSlaDeadline"}
            onClick={() => {
              setSortParams({ direction: 1, field: "minSlaDeadline" });
              setPaginationParams(getInitialPaginationParams());
            }}
          >
            Due date
          </SortFilterButton>
          <SortFilterButton
            key="maxSeverity"
            secondary={sortParams.field === "maxSeverity"}
            onClick={() => {
              setSortParams({ direction: -1, field: "maxSeverity" });
              setPaginationParams(getInitialPaginationParams());
            }}
          >
            Severity
          </SortFilterButton>
        </LeftControls>

        <GenericPaginator
          pageInfo={pageInfo}
          itemsLoaded={vulnerablePackages.length}
          totalItems={data?.organization.packageVulnerabilities.totalCount ?? 0}
          paginationParams={paginationParams}
          setPaginationParams={setPaginationParams}
          paginationId={"paginator-agent-package-vulns"}
          fetchMore={fetchMore}
        />
      </ControlsTopPanel>

      {loading || paginationParams.loading ? (
        <Spinner />
      ) : vulnerablePackages.length === 0 && searchString === "" ? (
        noVulnerabilitiesCallout
      ) : (
        <>
          <VulnSearchResultSummary
            searchString={searchString}
            numberResults={vulnerablePackages.length}
          />
          <EntryListContainer>
            {vulnerablePackages.map(vuln => (
              <PackageVulnEntry
                key={vuln.id}
                title={vulnToTitle(vuln)}
                numMachinesAffected={(vuln.machineInstances ?? []).length}
                severity={severityLevelForNumber(vuln.maxSeverity)}
                slaDeadline={GetEarliestDeadlineForVulnerablePackage(vuln)}
                handleClick={() =>
                  history.push({ search: `?vulnId=${vuln.id}` })
                }
                handleIgnore={() => {
                  setVulnToIgnore(vuln);
                }}
              />
            ))}
          </EntryListContainer>
        </>
      )}
      {isSome(vulnToIgnore) ? (
        <IgnoreVulnDialog
          refetchQueries={[
            {
              query: FetchVulnerablePackagesDocument,
              variables: {
                first: DEFAULT_ITEMS_PER_PAGE_OPTION,
                sortParams,
              },
            },
          ]}
          vuln={vulnToIgnore}
          onClose={() => setVulnToIgnore(nothing)}
        />
      ) : null}
    </TableContainer>
  );
};

gql`
  query fetchVulnerablePackages(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $sortParams: sortParams
    $filterParams: filterParams
    $applyWhitelist: Boolean
  ) {
    organization {
      id
      packageVulnerabilities(
        first: $first
        after: $after
        last: $last
        before: $before
        sortParams: $sortParams
        filterParams: $filterParams
        applyWhitelist: $applyWhitelist
      ) {
        totalCount
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            id
            packageName
            packageVersion
            packageSource
            os
            securityVersion
            maxSeverity
            machineInstances(applyWhitelist: $applyWhitelist) {
              id
              createdAt
              vulnData {
                id
                slaDeadline
                osquery {
                  id
                  cloudProviderId
                  prettyName
                }
              }
            }
          }
        }
      }
    }
  }
`;
