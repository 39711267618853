import React from "react";

import { DefaultLink } from "../../../../helpers/links";
import {
  InfoCalloutContainer,
  InfoCalloutHeading,
  InfoCalloutText,
} from "../common/components";

export const SnykProjectsEmptyState: React.FC = () => (
  <InfoCalloutContainer>
    <InfoCalloutHeading>No Snyk projects detected</InfoCalloutHeading>
    <InfoCalloutText>
      Vulnerabilities from Snyk projects should appear here.
    </InfoCalloutText>
    <InfoCalloutText>
      Vanta should start displaying projects from your organization on this page
      within an hour of connecting Snyk. If you expect to see projects here,
      navigate to the{" "}
      <DefaultLink href="/connections">Connections page</DefaultLink> to check
      if Vanta is having issues fetching data from Snyk.
    </InfoCalloutText>
  </InfoCalloutContainer>
);
