import "./ellipsify.scss";

import { PopoverInteractionKind } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import React, { useRef, useState } from "react";
import styled from "styled-components";
import { Tooltip } from "../alpaca/components";

interface IEllipsifyProps {
  className?: Maybe<string>;
  /**
   * On hover, if true, display full text in place of the ellipsified text.
   * Otherwise, full text displays in a tooltip.
   */
  revealInPlace?: Maybe<boolean>;
  text: string;
}

export const Ellipsify: React.FC<IEllipsifyProps> = props => {
  const { className, revealInPlace, text } = props;
  const overflowRef = useRef<HTMLDivElement>(null);
  const [tooltipVisible, setTooltipVisibility] = useState(false);

  return revealInPlace ? (
    <EllipsedDivNoWrap className={className ?? ""} revealOnHover>
      {text}
    </EllipsedDivNoWrap>
  ) : (
    // Use block divs instead of the default inline spans so overflowing works
    <Tooltip
      isOpen={tooltipVisible}
      targetTagName="div"
      className="ellipsify-fullwidth" // At least the wrapper div needs width:100%
      interactionKind={PopoverInteractionKind.HOVER}
      content={<EllipsedDivWithWrap>{text}</EllipsedDivWithWrap>}
    >
      <EllipsedDivNoWrap
        className={className ?? ""}
        ref={overflowRef}
        onMouseEnter={() => {
          // https://developer.mozilla.org/en-US/docs/Web/CSS/overflow
          // An overflow:hidden box is still a scroll container, even though it doesn't have scroll bars.
          if (
            (overflowRef?.current?.scrollWidth ?? 0) >
            (overflowRef?.current?.offsetWidth ?? 0)
          ) {
            setTooltipVisibility(true);
          } else {
            setTooltipVisibility(false);
          }
        }}
        onMouseLeave={() => setTooltipVisibility(false)}
      >
        {text}
      </EllipsedDivNoWrap>
    </Tooltip>
  );
};

const EllipsedDivNoWrap = styled.div<{ revealOnHover?: Maybe<boolean> }>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${({ revealOnHover }) =>
    revealOnHover &&
    `&:hover {
      overflow: visible;
      white-space: normal;
      word-wrap: break-word;
  }`}
`;

// Cap the width and allow long strings to break, even if there are no spaces.
// Cap the height and use ellipses, but hope that we never have to display a string that long.
const EllipsedDivWithWrap = styled.div`
  max-height: 30em;
  max-width: 30em;
  overflow-wrap: anywhere;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
`;
