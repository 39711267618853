import { Callout, Intent } from "@blueprintjs/core";
import type { IdentityProviderService } from "common/base/types/helpers";
import {
  IDENTITY_PROVIDER_SERVICES,
  serviceToDisplayName,
} from "common/base/types/helpers";
import { isSome } from "common/base/types/maybe";
import React, { useContext } from "react";

import { LinkedServicesContext } from "./linked-services-context";

interface IProps {
  newProvider: IdentityProviderService;
}

export const IdentityProviderDisclaimer: React.FC<IProps> = ({
  newProvider,
}) => {
  const { linkedServices } = useContext(LinkedServicesContext);
  const currentProvider = IDENTITY_PROVIDER_SERVICES.find(
    service => linkedServices.has(service) && service !== newProvider
  );
  if (!isSome(currentProvider)) {
    return null;
  }
  return (
    <Callout intent={Intent.DANGER}>
      <p>
        You already connected {serviceToDisplayName(currentProvider)} to Vanta
        as your identity provider.
      </p>
      <p>
        Vanta supports one identity provider, so if you connect{" "}
        {serviceToDisplayName(newProvider)}, personnel who are only{" "}
        {serviceToDisplayName(currentProvider)} members will be removed and
        marked as ex-employees.
      </p>
      <p>
        Carefully check and compare all users as personnel in Vanta will only be
        migrated from {serviceToDisplayName(currentProvider)} to{" "}
        {serviceToDisplayName(newProvider)} if the email address matches
        exactly. All punctuation and capitalization must be the same.
      </p>
    </Callout>
  );
};
