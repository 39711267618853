import { Button, Intent, MenuItem, Switch } from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";
import type { Maybe } from "common/base/types/maybe";
import { isSome, nothing } from "common/base/types/maybe";
import gql from "graphql-tag";
import moment from "moment";
import React, { useState } from "react";

import { LogError } from "../../errors";
import type {
  ActiveEndpointsQuery,
  GetQueriesQuery,
} from "../../gen/components";
import {
  GetQueriesDocument,
  useActiveEndpointsQuery,
  useDeleteQueryMutation,
  useGetQueriesQuery,
  useScheduleQueryMutation,
} from "../../gen/components";
import { UI_DATE_FORMAT } from "../../helpers/common";
import { AppToaster } from "../../helpers/toaster";
import {
  booleanToEvaluationEnum,
  EvaluationIcon,
} from "../helpers/EvaluationIcon";
import { FullPageSpinner } from "../helpers/FullPageSpinner";
import { OverlayHelper } from "../helpers/overlay-helper";
import { DataTable } from "../pages/components/data-table";

interface IProps {
  domainId: string;
}

const DeviceSelect =
  Select.ofType<
    NonNullable<
      ActiveEndpointsQuery["internal"]["domainById"]["osqueryEndpoints"]
    >[number]
  >();

const COLUMN_ORDER = [
  "queryName",
  "normalizedQuery",
  "ownerName",
  "succeeded",
  "result",
  "numAttempts",
  "createdAt",
  "scheduledBy",
  "delete",
];

const HEADERS: { [k in typeof COLUMN_ORDER[number]]: string } = {
  queryName: "Query Name",
  normalizedQuery: "Query",
  result: "Result",
  succeeded: "Status",
  createdAt: "Created",
  numAttempts: "Times Attempted",
  ownerName: "Device Owner",
  scheduledBy: "Scheduled By",
  delete: "Delete",
};

export const OSQueryPanel: React.FunctionComponent<IProps> = props => {
  const [queryString, setQueryString] = useState("");
  const [queryName, setQueryName] = useState("");
  const [notifyOnResult, setNotifyOnResult] = useState(false);
  const [nodeKey, setNodeKey] = useState("");
  const [nodeName, setNodeName] = useState(nothing as Maybe<string>);
  const [refreshText, setRefreshText] = useState("Refresh");
  const {
    loading: queryLoading,
    error: queryError,
    data: allQueries,
    refetch: refetchQueries,
  } = useGetQueriesQuery({
    variables: {
      domainId: props.domainId,
    },
  });

  if (queryError) {
    LogError(queryError);
  }

  const {
    loading: endpointsLoading,
    error: endpointsError,
    data: ActiveEndpoints,
  } = useActiveEndpointsQuery({
    variables: {
      domainId: props.domainId,
    },
  });

  if (endpointsError) {
    LogError(endpointsError);
  }

  const [scheduleQuery] = useScheduleQueryMutation({
    refetchQueries: [
      {
        query: GetQueriesDocument,
        variables: {
          domainId: props.domainId,
        },
      },
    ],
  });

  const [deleteQuery] = useDeleteQueryMutation({
    refetchQueries: [
      {
        query: GetQueriesDocument,
        variables: {
          domainId: props.domainId,
        },
      },
    ],
  });

  const handleSubmit = (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();

    scheduleQuery({
      variables: {
        query: queryString,
        queryName,
        nodeKey,
        notifyOnResult,
      },
    }).catch(LogError);

    setQueryString("");
    setQueryName("");
    setNotifyOnResult(false);
    setNodeKey("");
    setNodeName("");

    AppToaster.show({
      icon: "tick",
      intent: Intent.SUCCESS,
      message: `Query scheduled`,
      timeout: 2500,
    });
  };

  const endpointDisplayText = (
    endpoint: NonNullable<
      ActiveEndpointsQuery["internal"]["domainById"]["osqueryEndpoints"]
    >[number]
  ) =>
    isSome(endpoint.user) && isSome(endpoint.user.displayName)
      ? `${endpoint.user.displayName} (${endpoint.node_key})`
      : endpoint.node_key;

  const endpointSearch = (
    query: string,
    endpoint: NonNullable<
      ActiveEndpointsQuery["internal"]["domainById"]["osqueryEndpoints"]
    >[number]
  ) =>
    (isSome(endpoint.user) &&
      isSome(endpoint.user.displayName) &&
      endpoint.user.displayName.toLocaleLowerCase().includes(query)) ||
    endpoint.node_key.toLocaleLowerCase().includes(query);

  const renderEndpoint = (
    endpoint: NonNullable<
      ActiveEndpointsQuery["internal"]["domainById"]["osqueryEndpoints"]
    >[number],
    params: { handleClick: any }
  ) => (
    <MenuItem
      key={endpoint.node_key ?? ""}
      text={endpointDisplayText(endpoint)}
      onClick={params.handleClick}
    />
  );

  const onEndpointSelect = (
    endpoint: NonNullable<
      ActiveEndpointsQuery["internal"]["domainById"]["osqueryEndpoints"]
    >[number]
  ) => {
    setNodeKey(endpoint.node_key ?? "");
    setNodeName(endpointDisplayText(endpoint));
  };

  const formatJSON = (json: string) => {
    try {
      const obj = JSON.parse(json);
      return JSON.stringify(obj, null, 2);
    } catch (e) {
      return json;
    }
  };

  const getRowData = (
    query: GetQueriesQuery["internal"]["domainById"]["distributedQueries"][number]
  ) => {
    const createdDate = moment(query.createdAt).format(UI_DATE_FORMAT);

    let resultOverlay = <span>Pending</span>;
    if (isSome(query.succeeded) && !query.succeeded) {
      resultOverlay = <span>Failed</span>;
    } else if (isSome(query.result)) {
      resultOverlay = (
        <OverlayHelper
          buttonText={"View Result"}
          minimal={false}
          overlayContent={
            <div>
              <h3>Query result</h3>
              <pre>{formatJSON(query.result)}</pre>
            </div>
          }
        />
      );
    }

    let ownerName = "";
    const endpoint = endpoints.find(e => e.node_key === query.node_key);
    if (endpoint?.user && isSome(endpoint.user.displayName)) {
      ownerName = endpoint.user.displayName;
    }

    const rowData = {
      queryName: query.queryName,
      normalizedQuery: query.normalizedQuery,
      result: resultOverlay,
      createdAt: createdDate,
      numAttempts: query.numAttempts,
      scheduledBy: query.scheduler.email,
      succeeded: (
        <span>
          <EvaluationIcon
            evaluation={booleanToEvaluationEnum(query.succeeded)}
          />
        </span>
      ),
      ownerName,
      delete: (
        <Button
          minimal={false}
          text="Delete"
          onClick={() => onDeleteQuery(query.id)}
        />
      ),
    };
    return rowData;
  };

  const onDeleteQuery = (id: string) => {
    deleteQuery({
      variables: {
        id,
      },
    }).catch(LogError);

    AppToaster.show({
      icon: "tick",
      intent: Intent.SUCCESS,
      message: `Query deleted`,
      timeout: 2500,
    });
  };

  if (
    queryLoading ||
    endpointsLoading ||
    !isSome(allQueries) ||
    !isSome(ActiveEndpoints) ||
    !isSome(ActiveEndpoints.internal.domainById.osqueryEndpoints)
  ) {
    return <FullPageSpinner />;
  }

  const allQueriesSorted = allQueries.internal.domainById.distributedQueries
    .slice()
    .sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    );

  const queries = allQueriesSorted.filter(query => !isSome(query.deletedAt));

  const endpoints = ActiveEndpoints.internal.domainById.osqueryEndpoints;

  const querySearchFilter = (
    query: GetQueriesQuery["internal"]["domainById"]["distributedQueries"][number],
    searchText: string
  ) => {
    if (searchText.trim() === "") return true;
    const searchTextLowerCase = searchText.toLocaleLowerCase();
    return (
      query.queryName.toLocaleLowerCase().includes(searchTextLowerCase) ||
      query.normalizedQuery.toLocaleLowerCase().includes(searchTextLowerCase) ||
      (isSome(query.result) &&
        query.result.toLocaleLowerCase().includes(searchTextLowerCase))
    );
  };

  return (
    <div>
      <h5>Schedule device query</h5>

      <form
        className="form-group form-group-inline"
        style={{ marginTop: 12 }}
        onSubmit={handleSubmit}
      >
        <label>
          <div>Device</div>
          <DeviceSelect
            items={endpoints}
            itemPredicate={endpointSearch}
            itemRenderer={renderEndpoint}
            noResults={<MenuItem disabled={true} text="No results." />}
            onItemSelect={onEndpointSelect}
          >
            <Button
              text={Boolean(nodeName) ? nodeName : "Select a device"}
              rightIcon="caret-down"
            />
          </DeviceSelect>
        </label>

        <label>
          <div>Query</div>
          <input
            type="text"
            value={queryString}
            name="query"
            onChange={e => setQueryString(e.target.value)}
            required={true}
          />
        </label>

        <label>
          <div>Query Name</div>
          <input
            type="text"
            value={queryName}
            name="queryName"
            onChange={e => setQueryName(e.target.value)}
            required={true}
          />
        </label>

        <label>
          <div>Notify me on result</div>
          <Switch
            checked={notifyOnResult}
            onChange={() => setNotifyOnResult(!notifyOnResult)}
          />
        </label>
        <br />
        <input type="submit" value="Schedule Query" />
      </form>
      <hr />
      <h5>Device Queries</h5>

      <Button
        text={refreshText}
        onClick={() => {
          setRefreshText("...");
          refetchQueries()
            .then(() => setRefreshText("Refresh"))
            .catch(e => {
              LogError(e);
              setRefreshText("Refresh");
            });
        }}
        className="device-query-refresh-button"
      />

      <DataTable
        classes="manage-table"
        columnOrder={COLUMN_ORDER}
        createRow={getRowData}
        data={queries}
        header={HEADERS}
        searchFilter={querySearchFilter}
      />
    </div>
  );
};

gql`
  query GetQueries($domainId: ID!) {
    internal {
      domainById(id: $domainId) {
        id
        distributedQueries {
          id
          queryName
          normalizedQuery
          deletedAt
          createdAt
          result
          statusCode
          numAttempts
          succeeded
          node_key
          scheduler {
            id
            email
          }
        }
      }
    }
  }
`;

gql`
  mutation DeleteQuery($id: ID!) {
    deleteQuery(id: $id)
  }
`;

gql`
  mutation ScheduleQuery(
    $query: String!
    $queryName: String!
    $nodeKey: String!
    $notifyOnResult: Boolean!
  ) {
    scheduleQuery(
      query: $query
      queryName: $queryName
      nodeKey: $nodeKey
      notifyOnResult: $notifyOnResult
    )
  }
`;

gql`
  query ActiveEndpoints($domainId: ID!) {
    internal {
      domainById(id: $domainId) {
        id
        osqueryEndpoints {
          id
          node_key
          hostIdentifier
          user {
            id
            displayName
          }
          platform
          cloudProviderId
        }
      }
    }
  }
`;
