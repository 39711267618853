import { Spinner } from "@blueprintjs/core";
import { isSome } from "common/base/types/maybe";
import type { TestEntityTypeClientSafe } from "common/constants/displayNames";
import gql from "graphql-tag";
import React from "react";

import { LogError, LogErrorMessage } from "../../../errors";
import type { FetchHerokuAddOnsQuery } from "../../../gen/components";
import { useFetchHerokuAddOnsQuery } from "../../../gen/components";
import { FailDataRow } from "../fail-data-row";

export function getHerokuAppNameAndAddons(data: FetchHerokuAddOnsQuery) {
  return data.organization.herokuDBs.edges
    ?.map(edge => {
      if (edge.node?.__typename === "SpecificHerokuAppResource") {
        return {
          addons: edge.node.addons,
          name: edge.node.name,
        };
      } else {
        return null;
      }
    })
    ?.filter(isSome);
}

export const HerokuDbTestEntity: React.FC<{
  entityType: TestEntityTypeClientSafe;
  entityIds: string[];
  testId: string;
  allowWhitelisting: boolean;
  first: number;
}> = ({ entityType, entityIds, testId, allowWhitelisting, first }) => {
  const { loading, error, data } = useFetchHerokuAddOnsQuery();
  if (loading) {
    return <Spinner />;
  }
  if (error) {
    LogError(error);
    return null;
  }
  if (!data) {
    LogErrorMessage("Bad fetch");
    return null;
  }

  const allHerokuAppAddOns = getHerokuAppNameAndAddons(data)?.flatMap(
    addonAndName => addonAndName.addons
  );
  const elements = entityIds.slice(0, first).map(entityId => {
    const name = allHerokuAppAddOns?.find(addon => addon.id === entityId)?.name;
    const displayName = isSome(name) ? `${name} (${entityId})` : entityId;
    return (
      <FailDataRow
        key={entityId}
        entityId={entityId}
        entityType={entityType}
        testId={testId}
        allowWhitelisting={allowWhitelisting}
      >
        {displayName}
      </FailDataRow>
    );
  });
  return <>{elements}</>;
};

gql`
  query fetchHerokuAddOns {
    organization {
      id
      herokuDBs: resources(specificResourceType: HerokuApp, first: 1000) {
        edges {
          node {
            id
            ... on SpecificHerokuAppResource {
              name
              addons {
                id
                name
                serviceName
                planName
                actionLabels
              }
            }
          }
        }
      }
    }
  }
`;
