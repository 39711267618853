import { gql } from "@apollo/client";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import type { IRiskSchema } from "common/schemas/riskRegister/risk-register-content";

import {
  useGetDatabaseNamesQuery,
  useGetResourceNamesQuery,
} from "../../../gen/components";

interface IResourceData {
  name?: Maybe<string>;
  uniqueId: string;
}

interface IReturnValue {
  loading: boolean;
  data?: Maybe<IResourceData[]>;
}

interface IResourceNode {
  uniqueId: string;
  displayName?: Maybe<string>;
  instanceId?: Maybe<string>;
  storageAccountName?: Maybe<string>;
}

export function useFetchResourceNames(risk: IRiskSchema): Maybe<IReturnValue> {
  if (!isSome(risk.resourceKind)) {
    return null;
  } else if (risk.resourceKind !== "DATABASE") {
    /**
     * We are deliberately disabling the rule of hooks here and below
     * as we can be reasonably certain the risk parameter for this hook
     * will never change within the life cycle of its parent
     * component.
     */
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const resourceQuery = useGetResourceNamesQuery({
      variables: { genericResource: risk.resourceKind },
    });
    if (resourceQuery.loading) {
      return { loading: true, data: null };
    } else {
      return {
        loading: false,
        data:
          resourceQuery.data?.organization.resources.edges.map(
            resourceDataMapper
          ) ?? [],
      };
    }
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const dbqueryResult = useGetDatabaseNamesQuery();
    if (dbqueryResult.loading) {
      return { loading: true, data: null };
    }
    const nosql = dbqueryResult.data?.organization.nosql.edges ?? [];
    const sql = dbqueryResult.data?.organization.sql.edges ?? [];
    const datawarehouse =
      dbqueryResult.data?.organization.warehouse.edges ?? [];

    const dbs = [...nosql, ...sql, ...datawarehouse].map(resourceDataMapper);
    return { loading: false, data: dbs };
  }

  function resourceDataMapper<T extends { node: IResourceNode }>(edge: T) {
    let name: Maybe<string> = null;
    if (isSome(edge.node.instanceId)) {
      name = edge.node.instanceId;
      if (isSome(edge.node.displayName)) {
        name += ` (${edge.node.displayName})`;
      }
    } else if (isSome(edge.node.storageAccountName)) {
      // All Azure Blob Containers must have display names.
      name = `${edge.node.displayName!} (${edge.node.storageAccountName})`;
    } else {
      name = edge.node.displayName;
    }
    return { name, uniqueId: edge.node.uniqueId };
  }
}

gql`
  query GetResourceNames($genericResource: GenericResource!) {
    organization {
      id
      resources(genericResourceType: $genericResource, first: 1000) {
        edges {
          node {
            id
            displayName
            __typename
            uniqueId
            ... on SpecificEC2InstanceResource {
              instanceId
            }
            ... on SpecificGCPComputeInstanceResource {
              instanceId
            }
            ... on SpecificAzureBlobContainerResource {
              storageAccountName
            }
          }
        }
      }
    }
  }
`;

gql`
  query GetDatabaseNames {
    organization {
      id
      nosql: resources(genericResourceType: NoSQLDatabase, first: 1000) {
        edges {
          node {
            id
            displayName
            __typename
            uniqueId
          }
        }
      }
      sql: resources(genericResourceType: SQLDatabase, first: 1000) {
        edges {
          node {
            id
            displayName
            __typename
            uniqueId
          }
        }
      }
      warehouse: resources(genericResourceType: DataWarehouse, first: 1000) {
        edges {
          node {
            id
            displayName
            __typename
            uniqueId
          }
        }
      }
    }
  }
`;
