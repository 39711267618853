import "./query-page.scss";

import { Menu, MenuDivider, MenuItem } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import React from "react";
import { Link, Route, Switch } from "react-router-dom";

import { AdminNavbar } from "../nav/admin-navbar";

export interface IPanelRoute {
  name: string;
  path: string;
  component?: Maybe<React.ComponentType<any>>;
  render?: Maybe<() => React.ReactNode>;
}

export type IPageSchema = { [heading: string]: IPanelRoute[] };

interface IProps {
  additionalRoutes?: Maybe<JSX.Element>;
  navbar?: Maybe<JSX.Element>;
  schema: IPageSchema;
  sidebarHeading?: Maybe<JSX.Element>;
}

/**
 * A component for an interal page in the style of the `/query` page,
 * i.e. with a side navigation menu with each menu item corresponding to
 * a specified panel/sub-page component.
 */
export const InternalPage: React.FC<IProps> = ({
  additionalRoutes,
  navbar,
  schema,
  sidebarHeading,
}) => {
  const sidebar = (
    <div className="column sidebar nav-sidebar">
      {isSome(sidebarHeading) ? (
        <div className="upper">{sidebarHeading}</div>
      ) : null}
      <div className="lower">
        <Menu className="app-menu">
          {Object.entries(schema).map(([heading, routes]) => (
            <React.Fragment key={heading}>
              <MenuDivider title={heading} />
              {routes.map(item => (
                <Link className="app-menu" to={item.path} key={item.name}>
                  <MenuItem
                    active={window.location.pathname === item.path}
                    text={item.name}
                    tagName="span"
                  />
                </Link>
              ))}
            </React.Fragment>
          ))}
        </Menu>
      </div>
    </div>
  );
  const routes = (
    <Switch>
      {Object.values(schema)
        .flat()
        .map(({ path, component, render }) => (
          <Route
            key={path}
            exact
            path={path}
            component={component ?? undefined}
            render={render ?? undefined}
          />
        ))}
      {additionalRoutes}
    </Switch>
  );
  return (
    <div className="app-container">
      {navbar ?? <AdminNavbar />}
      <div className="app-container-page query-page">
        {sidebar}
        <div className="query-page-query-results">{routes}</div>
      </div>
    </div>
  );
};
