import moment from "moment";
import styled from "styled-components";
import { GRID_SPACING } from "../../../alpaca/base/grid";
import type { GetAuditorEngagementInfoQuery } from "../../../gen/components";

export type AuditorPageEngagement = NonNullable<
  GetAuditorEngagementInfoQuery["user"]
>["auditEngagements"][number];

export type NonNullDateRange = [Date, Date];

export const STARTER_ENGAGEMENT_DATE_RANGE: NonNullDateRange = [
  new Date(),
  moment().add(1, "day").toDate(),
];

export const StyledDialogFooterDiv = styled.div`
  margin-top: ${3 * GRID_SPACING}px;
  display: flex;
  justify-content: flex-end;
`;
