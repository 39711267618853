{
  "standardInfo": {
    "standard": "soc2"
  },
  "controls": [
    {
      "id": "access-application-restricted",
      "name": "Production application access restricted",
      "description": "The company restricts privileged access to the application to authorized users with a business need.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "access-removed-infra"
        },
        {
          "id": "access-removed-versioncontrol"
        },
        {
          "id": "infra-unique-accounts-group"
        },
        {
          "id": "infra-unique-accounts-records-email"
        },
        {
          "id": "infra-unique-accounts-records-infra"
        },
        {
          "id": "infra-unique-accounts-records-versioncontrol"
        },
        {
          "id": "infra-unique-accounts-roles"
        },
        {
          "id": "infra-unique-accounts-roles-gcp"
        },
        {
          "id": "infra-unique-accounts-roles-heroku"
        },
        {
          "id": "infra-unique-accounts-root"
        },
        {
          "id": "infra-unique-accounts-unused"
        },
        {
          "id": "infra-unique-accounts-user"
        },
        {
          "id": "infra-unique-accounts-user-heroku"
        },
        {
          "id": "mfa-on-accounts-version-control"
        },
        {
          "id": "no-allusers-policy-bindings-gcp"
        },
        {
          "id": "no-nonindividual-primitive-roles-gcp"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "access-control-procedures",
      "name": "Access control procedures established",
      "description": "The company's access control policy documents the requirements for the following access control functions: \n- adding new users;\n- modifying users; and/or \n- removing an existing user's access.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "approved-information-security-roles-and-responsibilities-bsi-exists"
        },
        {
          "id": "employees-accepted-access-control-policy-bsi"
        },
        {
          "id": "employees-accepted-information-security-roles-and-responsibilities-bsi"
        },
        {
          "id": "employees-accepted-system-access-control-policy"
        },
        {
          "id": "least-privileged-policy-policies"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "access-database-restricted-users",
      "name": "Production database access restricted",
      "description": "The company restricts privileged access to databases to authorized users with a business need.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "access-granted-infra"
        },
        {
          "id": "infra-access-requires-approval-config"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "access-datastores-ssh",
      "name": "Unique production database authentication enforced",
      "description": "The company requires authentication to production datastores to use authorized secure authentication mechanisms, such as unique SSH key.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "bitbucket-account-mfa-enabled"
        },
        {
          "id": "github-account-mfa-enabled"
        },
        {
          "id": "heroku-account-mfa-enabled"
        },
        {
          "id": "infra-access-requires-approval-config"
        },
        {
          "id": "mfa-on-accounts-gsuite"
        },
        {
          "id": "mfa-on-accounts-infra"
        },
        {
          "id": "mfa-on-accounts-infra-root"
        },
        {
          "id": "mfa-on-accounts-okta"
        },
        {
          "id": "mfa-on-accounts-version-control"
        },
        {
          "id": "mfa-on-groups-version-control"
        },
        {
          "id": "require-ssh-config"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "access-encryption-keys",
      "name": "Encryption key access restricted",
      "description": "The company restricts privileged access to encryption keys to authorized users with a business need.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-cryptography-policy-bsi-exists"
        },
        {
          "id": "credential-keys-managed-config"
        },
        {
          "id": "credential-keys-managed-policy"
        },
        {
          "id": "cryptography-policies-policies"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "access-firewalls",
      "name": "Firewall access restricted",
      "description": "The company restricts privileged access to the firewall to authorized users with a business need.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-asset-management-policy-bsi-exists"
        },
        {
          "id": "aws-ec2instances-ports-restricted"
        },
        {
          "id": "digitalocean-droplets-firewalls-restrict-port-22"
        },
        {
          "id": "digitalocean-droplets-firewalls-used"
        },
        {
          "id": "digitalocean-firewalls-ports-restricted"
        },
        {
          "id": "firewall-config-acl"
        },
        {
          "id": "firewall-config-acl-gcp"
        },
        {
          "id": "firewall-config-acl-heroku"
        },
        {
          "id": "firewall-config-default-deny"
        },
        {
          "id": "firewall-config-default-deny-gcp"
        },
        {
          "id": "firewall-config-default-deny-heroku"
        },
        {
          "id": "firewall-config-server"
        },
        {
          "id": "firewall-config-server-heroku"
        },
        {
          "id": "firewall-endpoint-policies"
        },
        {
          "id": "gcp-computeinstance-ports-restricted"
        },
        {
          "id": "require-ssh-config"
        },
        {
          "id": "ssh-denied-azure"
        },
        {
          "id": "unique-ssh-keys"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "access-operating-system-restricted",
      "name": "Production OS access restricted",
      "description": "The company restricts privileged access to the operating system to authorized users with a business need.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "access-granted-infra"
        },
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "atlas-account-linked-in-vanta"
        },
        {
          "id": "azuredevops-account-linked-in-vanta"
        },
        {
          "id": "heroku-account-linked-in-vanta"
        },
        {
          "id": "infra-access-requires-approval-config"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "access-physical-datacenter",
      "name": "Physical access processes established",
      "description": "The company has processes in place for granting, changing, and terminating physical access to company data centers based on an authorization from control owners.",
      "categories": ["Physical"],
      "tests": [
        {
          "id": "approved-physical-security-policy-bsi-exists"
        },
        {
          "id": "employees-accepted-physical-security-policy"
        },
        {
          "id": "employees-accepted-physical-security-policy-bsi"
        },
        {
          "id": "physical-security-policies"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "access-physical-review",
      "name": "Data center access reviewed",
      "description": "The company reviews access to the data centers at least annually.",
      "categories": ["Physical"],
      "tests": [
        {
          "id": "approved-physical-security-policy-bsi-exists"
        },
        {
          "id": "employees-accepted-physical-security-policy"
        },
        {
          "id": "employees-accepted-physical-security-policy-bsi"
        },
        {
          "id": "physical-security-policies"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "access-physical-visitors",
      "name": "Visitor procedures enforced",
      "description": "The company requires visitors to sign-in, wear a visitor badge, and be escorted by an authorized employee when accessing the data center or secure areas.",
      "categories": ["Physical"],
      "tests": [
        {
          "id": "approved-physical-security-policy-bsi-exists"
        },
        {
          "id": "employees-accepted-physical-security-policy"
        },
        {
          "id": "employees-accepted-physical-security-policy-bsi"
        },
        {
          "id": "physical-security-policies"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "access-production-network-restricted",
      "name": "Production network access restricted",
      "description": "The company restricts privileged access to the production network to authorized users with a business need.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "access-granted-infra"
        },
        {
          "id": "infra-access-requires-approval-config"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "access-reviews",
      "name": "Access reviews conducted",
      "description": "The company conducts access reviews at least quarterly for the in-scope system components to help ensure that access is restricted appropriately. Required changes are tracked to completion.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "access-removed-infra"
        },
        {
          "id": "access-removed-versioncontrol"
        },
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "asana-account-linked-in-vanta"
        },
        {
          "id": "atlas-account-linked-in-vanta"
        },
        {
          "id": "azuredevops-account-linked-in-vanta"
        },
        {
          "id": "bitbucket-account-linked-in-vanta"
        },
        {
          "id": "clickup-account-linked-in-vanta"
        },
        {
          "id": "clubhouse-account-linked-in-vanta"
        },
        {
          "id": "datadog-account-linked-in-vanta"
        },
        {
          "id": "github-account-linked-in-vanta"
        },
        {
          "id": "gitlab-account-linked-in-vanta"
        },
        {
          "id": "heroku-account-linked-in-vanta"
        },
        {
          "id": "hr-users-linked-in-vanta"
        },
        {
          "id": "idp-linked-to-vanta"
        },
        {
          "id": "infra-access-requires-approval-config"
        },
        {
          "id": "infra-linked-to-vanta"
        },
        {
          "id": "jamf-account-linked-in-vanta"
        },
        {
          "id": "jira-account-linked-in-vanta"
        },
        {
          "id": "knowbe4-account-linked-in-vanta"
        },
        {
          "id": "linear-account-linked-in-vanta"
        },
        {
          "id": "pivotaltracker-account-linked-in-vanta"
        },
        {
          "id": "slack-account-linked-in-vanta"
        },
        {
          "id": "snyk-account-linked-in-vanta"
        },
        {
          "id": "trello-account-linked-in-vanta"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "access-reviews"
        }
      ]
    },
    {
      "id": "access-revoked-termination",
      "name": "Access revoked upon termination",
      "description": "The company completes termination checklists to ensure that access is revoked for terminated employees within SLAs.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "access-removed-infra"
        },
        {
          "id": "access-removed-versioncontrol"
        },
        {
          "id": "asana-account-access-removed-on-termination"
        },
        {
          "id": "aws-account-access-removed-on-termination"
        },
        {
          "id": "azuredevops-account-access-removed-on-termination"
        },
        {
          "id": "bitbucket-account-access-removed-on-termination"
        },
        {
          "id": "clickup-account-access-removed-on-termination"
        },
        {
          "id": "clubhouse-account-access-removed-on-termination"
        },
        {
          "id": "datadog-account-access-removed-on-termination"
        },
        {
          "id": "github-account-access-removed-on-termination"
        },
        {
          "id": "gitlab-account-access-removed-on-termination"
        },
        {
          "id": "heroku-account-access-removed-on-termination"
        },
        {
          "id": "jamf-account-access-removed-on-termination"
        },
        {
          "id": "jira-account-access-removed-on-termination"
        },
        {
          "id": "knowbe4-account-access-removed-on-termination"
        },
        {
          "id": "linear-account-access-removed-on-termination"
        },
        {
          "id": "mongoatlas-account-access-removed-on-termination"
        },
        {
          "id": "pivotaltracker-account-access-removed-on-termination"
        },
        {
          "id": "slack-account-access-removed-on-termination"
        },
        {
          "id": "snyk-account-access-removed-on-termination"
        },
        {
          "id": "trello-account-access-removed-on-termination"
        },
        {
          "id": "vanta-users-offboarded-within-sla"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "employee-termination-checklist"
        },
        {
          "id": "policy-exists-termination"
        }
      ]
    },
    {
      "id": "access-role-based",
      "name": "Access requests required",
      "description": "The company ensures that user access to in-scope system components is based on job role and function or requires a documented access request form and manager approval prior to access being provisioned.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "access-granted-infra"
        },
        {
          "id": "access-granted-versioncontrol"
        },
        {
          "id": "approved-access-control-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "access-ssh-required",
      "name": "Unique network system authentication enforced",
      "description": "The company requires authentication to the \"production network\" to use unique usernames and passwords or authorized Secure Socket Shell (SSH) keys.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "require-admin-encrypted-iaas"
        },
        {
          "id": "require-admin-encrypted-iaas-gcp"
        },
        {
          "id": "require-admin-encrypted-iaas-heroku"
        },
        {
          "id": "require-ssh-config"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "asset-disposal-procedures-utilized",
      "name": "Asset disposal procedures utilized",
      "description": "The company has electronic media containing confidential information purged or destroyed in accordance with best practices, and certificates of destruction are issued for each device destroyed.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-asset-management-policy-bsi-exists"
        },
        {
          "id": "employees-accepted-asset-management-policy"
        },
        {
          "id": "employees-accepted-asset-management-policy-bsi"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "media-disposal"
        }
      ]
    },
    {
      "id": "assets-anti-malware",
      "name": "Anti-malware technology utilized",
      "description": "The company deploys anti-malware technology to environments commonly susceptible to malicious attacks and configures this to be updated routinely, logged, and installed on all relevant systems.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "employees-without-laptops"
        },
        {
          "id": "malware-detection-workstations"
        },
        {
          "id": "malware-detection-workstations-jamf"
        },
        {
          "id": "malware-detection-workstations-kandji"
        },
        {
          "id": "malware-detection-workstations-microsoft-endpoint-manager"
        },
        {
          "id": "security-vulnerabilities-monitored-laptops"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "assets-portable-media-encrypted",
      "name": "Portable media encrypted",
      "description": "The company encrypts portable and removable media devices when used.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-cryptography-policy-bsi-exists"
        },
        {
          "id": "cryptography-policies-policies"
        },
        {
          "id": "full-disk-encryption-records"
        },
        {
          "id": "full-disk-encryption-records-jamf"
        },
        {
          "id": "full-disk-encryption-records-kandji"
        },
        {
          "id": "full-disk-encryption-records-microsoft-endpoint-manager"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "removable-media-encryption"
        }
      ]
    },
    {
      "id": "background-checks-performed",
      "name": "Employee background checks performed",
      "description": "The company performs background checks on new employees.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-human-resource-security-policy-bsi-exists"
        },
        {
          "id": "background-checks-records"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "bulk-background-check"
        }
      ]
    },
    {
      "id": "board-of-directors-briefing",
      "name": "Board oversight briefings conducted",
      "description": "The company's board of directors or a relevant subcommittee is briefed by senior management at least annually on the state of the company's cybersecurity and privacy risk. The board provides feedback and direction to management as needed.",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "board-meeting-record"
        }
      ]
    },
    {
      "id": "board-of-directors-charter",
      "name": "Board charter documented",
      "description": "The company's board of directors has a documented charter that outlines its oversight responsibilities for internal control.",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "board-of-directors-charter"
        }
      ]
    },
    {
      "id": "board-of-directors-expertise",
      "name": "Board expertise developed",
      "description": "The company's board members have sufficient expertise to oversee management's ability to design, implement and operate information security controls. The board engages third-party information security experts and consultants as needed.",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "board-of-director-cvs"
        },
        {
          "id": "board-of-directors-charter"
        }
      ]
    },
    {
      "id": "board-of-directors-meeting",
      "name": "Board meetings conducted",
      "description": "The company's board of directors meets at least annually and maintains formal meeting minutes. The board includes directors that are independent of the company.",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "board-meeting-record"
        }
      ]
    },
    {
      "id": "change-management-procedures",
      "name": "Change management procedures enforced",
      "description": "The company requires changes to software and infrastructure components of the service to be authorized, formally documented, tested, reviewed, and approved prior to being implemented in the production environment.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-operations-security-policy-bsi-exists"
        },
        {
          "id": "code-review-application-config"
        },
        {
          "id": "code-review-template-present-azuredevops"
        },
        {
          "id": "code-review-template-present-gitlab"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "ci-cd-implemented"
        }
      ]
    },
    {
      "id": "changes-approval-required",
      "name": "Production deployment access restricted",
      "description": "The company restricts access to migrate changes to production to authorized personnel.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-operations-security-policy-bsi-exists"
        },
        {
          "id": "approved-secure-development-policy-bsi-exists"
        },
        {
          "id": "code-review-application-config"
        },
        {
          "id": "version-control-tool-records"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "code-of-conduct-acknowledged-contractors",
      "name": "Code of Conduct acknowledged by contractors",
      "description": "The company requires contractor agreements to include a code of conduct or reference to the company code of conduct.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-code-of-conduct-bsi-exists"
        },
        {
          "id": "code-of-conduct-agree"
        },
        {
          "id": "code-of-conduct-policies"
        },
        {
          "id": "employees-accepted-code-of-conduct-bsi"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "contractor-agreement"
        }
      ]
    },
    {
      "id": "code-of-conduct-acknowledged-employees",
      "name": "Code of Conduct acknowledged by employees and enforced",
      "description": "The company requires employees to acknowledge a code of conduct at the time of hire. Employees who violate the code of conduct are subject to disciplinary actions in accordance with a disciplinary policy.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "acceptable-use-policy-agree"
        },
        {
          "id": "acceptable-use-policy-policies"
        },
        {
          "id": "approved-code-of-conduct-bsi-exists"
        },
        {
          "id": "approved-human-resource-security-policy-bsi-exists"
        },
        {
          "id": "code-of-conduct-agree"
        },
        {
          "id": "code-of-conduct-policies"
        },
        {
          "id": "employees-accepted-code-of-conduct-bsi"
        },
        {
          "id": "employees-accepted-human-resource-security-policy-bsi"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "commitments-externally-communicated",
      "name": "Company commitments externally communicated",
      "description": "The company's security commitments are communicated to customers in Master Service Agreements (MSA) or Terms of Service (TOS).",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "msa-template"
        },
        {
          "id": "security-page"
        },
        {
          "id": "terms-of-service"
        }
      ]
    },
    {
      "id": "confidentiality-agreement-acknowledged-contractors",
      "name": "Confidentiality Agreement acknowledged by contractors",
      "description": "The company requires contractors to sign a confidentiality agreement at the time of engagement.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-human-resource-security-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "contractor-agreement"
        }
      ]
    },
    {
      "id": "confidentiality-agreement-acknowledged-employees",
      "name": "Confidentiality Agreement acknowledged by employees",
      "description": "The company requires employees to sign a confidentiality agreement during onboarding.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-human-resource-security-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "employee-agreements"
        },
        {
          "id": "employee-signed-confidentiality"
        }
      ]
    },
    {
      "id": "configuration-management-system-established",
      "name": "Configuration management system established",
      "description": "The company has a configuration management procedure in place to ensure that system configurations are deployed consistently throughout the environment.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-operations-security-policy-bsi-exists"
        },
        {
          "id": "standard-server-config-config"
        },
        {
          "id": "standard-server-config-updates"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "ci-cd-implemented"
        }
      ]
    },
    {
      "id": "continuity-and-disaster-recovery-plans-established",
      "name": "Continuity and Disaster Recovery plans established",
      "description": "The company has Business Continuity and Disaster Recovery Plans in place that outline communication plans in order to maintain information security continuity in the event of the unavailability of key personnel.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-business-continuity-and-disaster-recovery-plan-bsi-exists"
        },
        {
          "id": "business-continuity-plan-policy"
        },
        {
          "id": "disaster-recovery-policy-policies"
        },
        {
          "id": "employees-accepted-business-continuity-and-disaster-recovery-plan-bsi"
        },
        {
          "id": "employees-accepted-business-continuity-plan"
        },
        {
          "id": "employees-accepted-disaster-recovery-plan"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "continuity-disaster-recovery-plans-tested",
      "name": "Continuity and disaster recovery plans tested",
      "description": "The company has a documented business continuity/disaster recovery (BC/DR) plan and tests it at least annually.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-business-continuity-and-disaster-recovery-plan-bsi-exists"
        },
        {
          "id": "employees-accepted-business-continuity-and-disaster-recovery-plan-bsi"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "disaster-recovery-tabletop"
        }
      ]
    },
    {
      "id": "control-self-assessments-conducted",
      "name": "Control self-assessments conducted",
      "description": "The company performs control self-assessments at least annually to gain assurance that controls are in place and operating effectively. Corrective actions are taken based on relevant findings.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "internal-control-tool-vanta"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "customer-data-deleted upon-leave",
      "name": "Customer data deleted upon leave",
      "description": "The company purges or removes customer data containing confidential information from the application environment, in accordance with best practices, when customers leave the service.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "activity-log-blob-containers-have-soft-delete-config-azure"
        },
        {
          "id": "approved-data-management-policy-bsi-exists"
        },
        {
          "id": "logging-buckets-have-MFA-delete-config"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "customer-data-deletion-record"
        }
      ]
    },
    {
      "id": "customer-data-retained",
      "name": "Customer data retained",
      "description": "The company retains customer transaction data for the life of a customer account. No historic transaction data is purged until the customer account is deleted.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-data-management-policy-bsi-exists"
        },
        {
          "id": "customer-data-policies-database"
        },
        {
          "id": "customer-data-policies-policies"
        },
        {
          "id": "data-classification-policy-policies"
        },
        {
          "id": "employees-accepted-data-classification-policy"
        },
        {
          "id": "employees-accepted-data-management-policy-bsi"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "customer-transaction-modifications-restricted",
      "name": "Customer transaction modifications restricted",
      "description": "The company ensures that neither customers nor administrators have the ability to modify customer transaction data within the production application.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "activity-log-blob-containers-have-soft-delete-config-azure"
        },
        {
          "id": "logging-buckets-have-MFA-delete-config"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "customer-transactions-portal-available",
      "name": "Customer transaction portal available",
      "description": "The company provides customers with customer portals that contain dashboards, reports, or history for transactional and account information.",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": []
    },
    {
      "id": "cybersecurity-insurance-maintained",
      "name": "Cybersecurity insurance maintained",
      "description": "The company maintains cybersecurity insurance to mitigate the financial impact of business disruptions.",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "cybersecurity-insurance-policy"
        }
      ]
    },
    {
      "id": "data-backup-procedures",
      "name": "Backup processes established",
      "description": "The company's data backup policy documents requirements for backup and recovery of customer data.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-data-management-policy-bsi-exists"
        },
        {
          "id": "approved-operations-security-policy-bsi-exists"
        },
        {
          "id": "daily-database-backups-policy"
        },
        {
          "id": "employees-accepted-backup-policy"
        },
        {
          "id": "employees-accepted-data-management-policy-bsi"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "disaster-recovery-tabletop"
        }
      ]
    },
    {
      "id": "data-classification-policy",
      "name": "Data classification policy established",
      "description": "The company has a data classification policy in place to help ensure that confidential data is properly secured and restricted to authorized personnel.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-data-management-policy-bsi-exists"
        },
        {
          "id": "data-classification-policy-policies"
        },
        {
          "id": "employees-accepted-data-classification-policy"
        },
        {
          "id": "employees-accepted-data-management-policy-bsi"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "data-deletion-requests-handled",
      "name": "Data deletion requests handled",
      "description": "The company validates deletion requests and once confirmed are flagged and the requested information is deleted, in accordance with applicable laws and regulations.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "activity-log-blob-containers-have-soft-delete-config-azure"
        },
        {
          "id": "logging-buckets-have-MFA-delete-config"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "data-encrypted",
      "name": "Data encryption utilized",
      "description": "The company's datastores housing sensitive customer data are encrypted at rest.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "aws-dynamodb-encryption"
        },
        {
          "id": "azure-cosmosdb-encryption"
        },
        {
          "id": "customer-data-encrypted-at-rest-config"
        },
        {
          "id": "customer-data-encrypted-at-rest-config-azure"
        },
        {
          "id": "customer-data-encrypted-at-rest-config-gcp"
        },
        {
          "id": "customer-data-encrypted-at-rest-config-heroku"
        },
        {
          "id": "digitalocean-app-relational-database-encryption"
        },
        {
          "id": "digitalocean-redis-cluster-encryption"
        },
        {
          "id": "digitalocean-relational-database-encryption"
        },
        {
          "id": "gcp-bigtable-encryption"
        },
        {
          "id": "gcp-datastore-encryption"
        },
        {
          "id": "gcp-firestore-encryption"
        },
        {
          "id": "mongo-atlas-cluster-encryption"
        },
        {
          "id": "storage-buckets-are-encrypted-config"
        },
        {
          "id": "storage-buckets-are-encrypted-config-digitaloceanspaces"
        },
        {
          "id": "storage-buckets-are-encrypted-config-gcp"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "data-encrypted-in-transit",
      "name": "Data transmission encrypted",
      "description": "The company uses secure data transmission protocols to encrypt confidential and sensitive data when transmitted over public networks.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-cryptography-policy-bsi-exists"
        },
        {
          "id": "cryptography-policies-policies"
        },
        {
          "id": "load-balancer-http-to-https"
        },
        {
          "id": "load-balancer-http-to-https-azure"
        },
        {
          "id": "load-balancer-http-to-https-digitalocean"
        },
        {
          "id": "require-admin-encrypted-iaas"
        },
        {
          "id": "require-admin-encrypted-iaas-gcp"
        },
        {
          "id": "require-admin-encrypted-iaas-heroku"
        },
        {
          "id": "ssl-good-ciphers"
        },
        {
          "id": "ssl-no-warnings"
        },
        {
          "id": "ssl-used-expiry"
        },
        {
          "id": "ssl-used-unittest"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "data-retention-procedures-established",
      "name": "Data retention procedures established",
      "description": "The company has formal retention and disposal procedures in place to guide the secure retention and disposal of company and customer data.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-data-management-policy-bsi-exists"
        },
        {
          "id": "data-protection-policy-policies"
        },
        {
          "id": "employees-accepted-data-delete-policy"
        },
        {
          "id": "employees-accepted-data-management-policy-bsi"
        },
        {
          "id": "employees-accepted-data-protection-policy"
        },
        {
          "id": "secure-data-delete-policies"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "database-replication-utilized",
      "name": "Database replication utilized",
      "description": "The company's databases are replicated to a secondary data center in real-time. Alerts are configured to notify administrators if replication fails.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "aws-dynamodb-pitr-backups"
        },
        {
          "id": "azure-cosmosdb-backups"
        },
        {
          "id": "daily-app-database-backups-config-digitalocean"
        },
        {
          "id": "daily-database-backups-config"
        },
        {
          "id": "daily-database-backups-config-azure"
        },
        {
          "id": "daily-database-backups-config-digitalocean"
        },
        {
          "id": "daily-database-backups-config-gcp"
        },
        {
          "id": "daily-database-backups-config-heroku"
        },
        {
          "id": "mongo-atlas-backups"
        },
        {
          "id": "mongo-atlas-replication"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "disaster-recovery-tabletop"
        }
      ]
    },
    {
      "id": "development-lifecycle-established",
      "name": "Development lifecycle established",
      "description": "The company has a formal systems development life cycle (SDLC) methodology in place that governs the development, acquisition, implementation, changes (including emergency changes), and maintenance of information systems and related technology requirements.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-operations-security-policy-bsi-exists"
        },
        {
          "id": "change-management-policy-policies"
        },
        {
          "id": "employees-accepted-change-management-policy"
        },
        {
          "id": "employees-accepted-operations-security-policy-bsi"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "environmental-monitoring-devices-implemented",
      "name": "Environmental monitoring devices implemented",
      "description": "The company has environmental monitoring devices in place and configured to automatically generate an alert to management for environmental incidents.",
      "categories": ["Physical"],
      "tests": [
        {
          "id": "high-severity-vendor-compliance-reports"
        },
        {
          "id": "vendor-management-records"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "environmental-security-inspected-annually",
      "name": "Environmental security inspected",
      "description": "The company has maintenance inspections of environmental security measures at the company data centers performed at least annually.",
      "categories": ["Physical"],
      "tests": [
        {
          "id": "approved-physical-security-policy-bsi-exists"
        },
        {
          "id": "employees-accepted-physical-security-policy"
        },
        {
          "id": "employees-accepted-physical-security-policy-bsi"
        },
        {
          "id": "physical-security-policies"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "external-service-impact-notifications",
      "name": "System changes externally communicated",
      "description": "The company notifies customers of critical system changes that may affect their processing.",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "customer-support-site"
        },
        {
          "id": "product-documentation"
        },
        {
          "id": "public-change-log"
        },
        {
          "id": "status-page"
        }
      ]
    },
    {
      "id": "external-support-resources",
      "name": "External support resources available",
      "description": "The company provides guidelines and technical support resources relating to system operations to customers.",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "customer-support-site"
        },
        {
          "id": "public-change-log"
        }
      ]
    },
    {
      "id": "incident-response-plan-tested",
      "name": "Incident response plan tested",
      "description": "The company tests their incident response plan at least annually.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-incident-response-plan-bsi-exists"
        },
        {
          "id": "incident-response-follow-ups-policies"
        },
        {
          "id": "incident-response-lessons-learned-policies"
        },
        {
          "id": "incident-response-plan-policies"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "incident-report-example"
        },
        {
          "id": "incident-response-plan-test"
        }
      ]
    },
    {
      "id": "incident-response-policies-established",
      "name": "Incident response policies established",
      "description": "The company has security and privacy incident response policies and procedures that are documented and communicated to authorized users.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-incident-response-plan-bsi-exists"
        },
        {
          "id": "employees-accepted-incident-response-plan"
        },
        {
          "id": "employees-accepted-incident-response-plan-bsi"
        },
        {
          "id": "incident-response-follow-ups-policies"
        },
        {
          "id": "incident-response-lessons-learned-policies"
        },
        {
          "id": "incident-response-plan-policies"
        },
        {
          "id": "incident-response-team-policies"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "infra-network-segregation",
      "name": "Network segmentation implemented",
      "description": "The company's network is segmented to prevent unauthorized access to customer data.",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "network-segregation"
        }
      ]
    },
    {
      "id": "intrusion-detection-system",
      "name": "Intrusion detection system utilized",
      "description": "The company uses an intrusion detection system to provide continuous monitoring of the company's network and early detection of potential security breaches.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "activity-log-blob-container-has-logging-azure"
        },
        {
          "id": "employees-without-laptops"
        },
        {
          "id": "infra-activity-tracked-config"
        },
        {
          "id": "infra-activity-tracked-config-azure"
        },
        {
          "id": "infra-activity-tracked-config-heroku"
        },
        {
          "id": "security-vulnerabilities-monitored-laptops"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "legal-personal-information-tracked",
      "name": "Legal personal information tracked",
      "description": "The company has personal information that is disclosed for legal purposes logged, tracked, and maintained in the company's designated tracking system for historical and audit purposes.",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": []
    },
    {
      "id": "log-management-utilized",
      "name": "Log management utilized",
      "description": "The company utilizes a log management tool to identify events that may have a potential impact on the company's ability to achieve its security objectives.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "activity-log-blob-container-has-logging-azure"
        },
        {
          "id": "activity-log-blob-containers-are-versioned-azure"
        },
        {
          "id": "flow-logs-on-azure"
        },
        {
          "id": "flow-logs-on-config"
        },
        {
          "id": "flow-logs-on-config-gcp"
        },
        {
          "id": "heroku-logs-drained"
        },
        {
          "id": "infra-activity-tracked-config"
        },
        {
          "id": "infra-activity-tracked-config-azure"
        },
        {
          "id": "infra-activity-tracked-config-heroku"
        },
        {
          "id": "infra-linked-to-vanta"
        },
        {
          "id": "logs-centrally-stored-authorization"
        },
        {
          "id": "logs-centrally-stored-authorization-gcp"
        },
        {
          "id": "logs-centrally-stored-config"
        },
        {
          "id": "logs-centrally-stored-config-gcp"
        },
        {
          "id": "logs-retained-for-twelve-months-config"
        },
        {
          "id": "logs-retained-for-twelve-months-config-gcp"
        },
        {
          "id": "logs-sink-destinations-authorization-gcp"
        },
        {
          "id": "logs-sink-destinations-tracked-gcp"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "management-roles-responsibilities-defined",
      "name": "Management roles and responsibilities defined",
      "description": "The company management has established defined roles and responsibilities to oversee the design and implementation of information security controls.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-information-security-policy-bsi-exists"
        },
        {
          "id": "approved-information-security-roles-and-responsibilities-bsi-exists"
        },
        {
          "id": "employees-accepted-information-security-policy-bsi"
        },
        {
          "id": "employees-accepted-information-security-roles-and-responsibilities-bsi"
        },
        {
          "id": "security-officer-role-assigned"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "MDM-system-utilized",
      "name": "MDM system utilized",
      "description": "The company has a mobile device management (MDM) system in place to centrally manage mobile devices supporting the service.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "malware-detection-workstations"
        },
        {
          "id": "malware-detection-workstations-jamf"
        },
        {
          "id": "malware-detection-workstations-kandji"
        },
        {
          "id": "malware-detection-workstations-microsoft-endpoint-manager"
        },
        {
          "id": "password-manager-records-jamf"
        },
        {
          "id": "password-manager-records-kandji"
        },
        {
          "id": "password-manager-records-microsoft-endpoint-manager"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "network-firewalls-reviewed",
      "name": "Network firewalls reviewed",
      "description": "The company reviews its firewall rulesets at least annually. Required changes are tracked to completion.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "digitalocean-droplets-firewalls-restrict-port-22"
        },
        {
          "id": "digitalocean-droplets-firewalls-used"
        },
        {
          "id": "firewall-config-acl"
        },
        {
          "id": "firewall-config-acl-gcp"
        },
        {
          "id": "firewall-config-acl-heroku"
        },
        {
          "id": "firewall-config-default-deny"
        },
        {
          "id": "firewall-config-default-deny-gcp"
        },
        {
          "id": "firewall-config-default-deny-heroku"
        },
        {
          "id": "firewall-config-server"
        },
        {
          "id": "firewall-config-server-heroku"
        },
        {
          "id": "firewall-endpoint-policies"
        },
        {
          "id": "ssh-denied-azure"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "network-firewalls-utilized",
      "name": "Network firewalls utilized",
      "description": "The company uses firewalls and configures them to prevent unauthorized access.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "digitalocean-droplets-firewalls-restrict-port-22"
        },
        {
          "id": "digitalocean-droplets-firewalls-used"
        },
        {
          "id": "firewall-config-acl"
        },
        {
          "id": "firewall-config-acl-gcp"
        },
        {
          "id": "firewall-config-acl-heroku"
        },
        {
          "id": "firewall-config-default-deny"
        },
        {
          "id": "firewall-config-default-deny-gcp"
        },
        {
          "id": "firewall-config-default-deny-heroku"
        },
        {
          "id": "firewall-config-server"
        },
        {
          "id": "firewall-config-server-heroku"
        },
        {
          "id": "firewall-endpoint-policies"
        },
        {
          "id": "ssh-denied-azure"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "organization-structure-documented",
      "name": "Organization structure documented",
      "description": "The company maintains an organizational chart that describes the organizational structure and reporting lines.",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "org-chart"
        }
      ]
    },
    {
      "id": "penetration-testing",
      "name": "Penetration testing performed",
      "description": "The company's penetration testing is performed at least annually. A remediation plan is developed and changes are implemented to remediate vulnerabilities in accordance with SLAs.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-risk-management-policy-bsi-exists"
        },
        {
          "id": "penetration-tests-records"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pen-test-remediation"
        }
      ]
    },
    {
      "id": "performance-evaluations-conducted",
      "name": "Performance evaluations conducted",
      "description": "The company managers are required to complete performance evaluations for direct reports at least annually.",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "employee-performance-evaluations"
        }
      ]
    },
    {
      "id": "personal-data-updates",
      "name": "Personal data update mechanisms available",
      "description": "Individuals, customers, or designated account holders can update their personal information to ensure it is accurate and complete through the use of a customer web portal or by contacting the organization using the methods provided in the privacy policy.",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": []
    },
    {
      "id": "personal-information-changes-communicated",
      "name": "Personal information changes communicated",
      "description": "The company communicates corrections or erasures of an individual's personal information to authorized users and relevant third parties to whom the personal information has been shared or transferred.",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": []
    },
    {
      "id": "personal-information-collection-changes-communicated",
      "name": "Personal information collection changes communicated",
      "description": "The company provides notice when or before personal information is directly collected from the individual and/or used for a new purpose not previously identified in the privacy policies, and within a reasonable time after new information is acquired or any changes to their privacy policies or practices have been approved.",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "privacy-policy"
        }
      ]
    },
    {
      "id": "personal-information-collection-reviewed",
      "name": "Personal information collection reviewed",
      "description": "The company's management and/or legal counsel reviews and approves the methods for the collection of personal information prior to implementation to ensure that information is obtained in a fair and lawful manner.",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": []
    },
    {
      "id": "personal-information-reliability-verified",
      "name": "Personal information reliability verified",
      "description": "The company's management reviews and approves the sources of personal information, other than the individual data subject (third-parties), to ensure that sources are reliable and that the information obtained by the third-parties has been collected in a fair and lawful manner.",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": []
    },
    {
      "id": "policies-incident-management",
      "name": "Incident management procedures followed",
      "description": "The company's security and privacy incidents are logged, tracked, resolved, and communicated to affected or relevant parties by management according to the company's security incident response policy and procedures.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-incident-response-plan-bsi-exists"
        },
        {
          "id": "approved-operations-security-policy-bsi-exists"
        },
        {
          "id": "employees-accepted-incident-response-plan"
        },
        {
          "id": "employees-accepted-incident-response-plan-bsi"
        },
        {
          "id": "employees-accepted-operations-security-policy-bsi"
        },
        {
          "id": "incident-response-follow-ups-policies"
        },
        {
          "id": "incident-response-lessons-learned-policies"
        },
        {
          "id": "incident-response-plan-policies"
        },
        {
          "id": "sla-for-security-bugs-policies"
        },
        {
          "id": "sla-for-security-bugs-records"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "policies-incident-monitoring",
      "name": "Vulnerability and system monitoring procedures established",
      "description": "The company's formal policies outline the requirements for the following functions related to IT / Engineering: \n- vulnerability management;\n- system monitoring.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-operations-security-policy-bsi-exists"
        },
        {
          "id": "approved-vulnerability-management-policy-exists"
        },
        {
          "id": "employees-accepted-vulnerability-management-policy"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "policies-password-complexity",
      "name": "Password policy enforced",
      "description": "The company requires passwords for in-scope system components to be configured according to the company's policy.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "internal-password-policy-config"
        },
        {
          "id": "internal-password-policy-config-heroku"
        },
        {
          "id": "password-manager-records"
        },
        {
          "id": "password-manager-records-jamf"
        },
        {
          "id": "password-manager-records-kandji"
        },
        {
          "id": "password-manager-records-microsoft-endpoint-manager"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "policies-passwords-enforced",
      "name": "Password policies enforced",
      "description": "The company has established password policies with minimum system password requirements. The system validates that requirements are met or provides an error message.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "internal-password-policy-config"
        },
        {
          "id": "internal-password-policy-config-heroku"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "policies-privacy-policy",
      "name": "Privacy policy maintained",
      "description": "The company has established a privacy policy that uses plain and simple language, is clearly dated, and provides information related to the company's practices and purposes for collecting, processing, handling, and disclosing personal information including:\n- organizational operating jurisdictions;\n- an individual's choice and consent for the collection, use, and disclosure of personal information;\n- an individual's right to access, update or remove personal information;\n- a process for individuals to exercise their rights;\n- requirements to only provide the essential information needed for the service;\n- types or categories of information collected;\n- purposes for the collection of information;\n- methods of collection (cookies or other tracking techniques, etc.);\n- consequences for not providing or withdrawing the essential information;\n- sources of information (third parties, direct collection, etc.);\n- types or categories of third parties (sources and disclosures);\n- the purpose for disclosure of information to third parties.",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "privacy-policy"
        }
      ]
    },
    {
      "id": "privacy-complaints-procedures",
      "name": "Privacy compliant procedures established",
      "description": "The company has documented processes and procedures in place to ensure that any privacy-related complaints are addressed, and the resolution is documented in the company's designated tracking system and communicated to the individual.",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "privacy-policy"
        }
      ]
    },
    {
      "id": "privacy-data-retained",
      "name": "Privacy data retained",
      "description": "The company's retention requirements are documented, and personal information is retained, as required, for business purposes, including to fulfill the purposes related to its collection, and/or by applicable laws or regulations.",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "privacy-policy"
        }
      ]
    },
    {
      "id": "privacy-data-sharing",
      "name": "Private data shared upon request",
      "description": "The company provides requested information, after verification, in a timely manner in either a portable electronic format or by mail, in accordance with applicable law.",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": []
    },
    {
      "id": "privacy-explicity-consent-obtained",
      "name": "Explicit consent obtained",
      "description": "The company obtains, documents, and retains the explicit consent, in accordance with applicable legal and regulatory requirements, directly from an individual prior to the collection, use, or disclosure of sensitive information.\nConsent may be obtained through the use of either a check box, signed form.",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": []
    },
    {
      "id": "privacy-identity-verification",
      "name": "Identity verification conducted",
      "description": "The company, prior to granting an individual the ability to access and review personal information, authenticates the individuals or their authorized representative's identity, with an appropriate level of assurance, and verifies such access is not prohibited by law.",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": []
    },
    {
      "id": "privacy-individual-consent-obtained",
      "name": "User data collection consent obtained",
      "description": "The company obtains an individual’s consent and preferences at or before the time of collection, maintains documentation in electronic and/or written format, and implements the individuals selected preferences, for information collection, use, and disclosure, in a timely fashion.",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "privacy-policy"
        },
        {
          "id": "terms-of-service"
        }
      ]
    },
    {
      "id": "privacy-inquiries-handled",
      "name": "Privacy inquiries handled",
      "description": "The company has processes and procedures in place to capture, log and verify requests, inquires, complaints, or disputes related to the individual's privacy rights of access, review, modification, and/or deletion of personal information. Access requests for personal information are logged within the company's designated tracking system for historical and audit purposes.",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": []
    },
    {
      "id": "privacy-new-information-purpose-consent",
      "name": "Privacy information purpose communicated",
      "description": "The company documents new purposes for previously collected information, notifies individuals of the new purpose and use of previously collected information, obtains and records an individual’s consent for use or withdrawal, and ensures consented information is being used in accordance with the documented new purpose.",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": []
    },
    {
      "id": "privacy-opt-out",
      "name": "Non-essential privacy information opt-out available",
      "description": "Individuals are able to opt-in and/or out of the use of non-essential cookies, and the company does not store, alter, or copy any personal or other information for which consent has not been obtained.",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": []
    },
    {
      "id": "privacy-policies-personal-information-use-reviewed",
      "name": "Personal information policies and procedures established",
      "description": "The company reviews policies and procedures as needed or when changes occur and updates them accordingly to ensure that personal information collected is: \n- identified as either essential or optional; \n- performed with consent (implicit or explicit) in accordance with legal and regulatory requirements; and\n- used in alignment with and limited to the purposes identified in the privacy notice.",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "privacy-policy"
        }
      ]
    },
    {
      "id": "privacy-policy-available",
      "name": "Privacy policy available",
      "description": "The company has a privacy policy available to customers, employees, and/or relevant third parties who need them before and/or at the time information is collected from the individual.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "internal-control-tool-vanta"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "privacy-policy"
        }
      ]
    },
    {
      "id": "privacy-policy-established",
      "name": "Privacy policy established",
      "description": "The company has a privacy policy is in place that documents and clearly communicates to individuals the extent of personal information collected, the company's obligations, the individual's rights to access, update, or erase their personal information, and an up-to-date point of contact where individuals can direct their questions, requests or concerns.",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "privacy-policy"
        }
      ]
    },
    {
      "id": "privacy-policy-reviewed",
      "name": "Privacy policy reviewed",
      "description": "The company reviews the privacy policy as needed or when changes occur and updates it accordingly to ensure it is consistent with the applicable laws, regulations, and appropriate standards.",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "privacy-policy"
        }
      ]
    },
    {
      "id": "privacy-sensitive-data-disposal",
      "name": "Personal information securely disposed",
      "description": "The company securely disposes of personal information in accordance with documented policies and procedures. Personal information is either anonymized, securely erased and/or destroyed when no longer required.",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": []
    },
    {
      "id": "processing-alerts-available",
      "name": "Processing alerts available",
      "description": "The company's customers can opt to receive alert emails for account transactions or modifications to help ensure that they are processed correctly.",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": []
    },
    {
      "id": "processing-issues-automatically-alerted",
      "name": "Processing issues automatically alerted",
      "description": "The company has automated transactions to move files and data between different system components. Errors that occur during the creation or transmission of critical data generate an alert to system administrators.",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": []
    },
    {
      "id": "production-data-backups-conducted",
      "name": "Production data backups conducted",
      "description": "The company performs periodic backups for production data. Data is backed up to a different location than the production system.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "activity-log-blob-containers-are-versioned-azure"
        },
        {
          "id": "aws-dynamodb-pitr-backups"
        },
        {
          "id": "azure-cosmosdb-backups"
        },
        {
          "id": "blob-containers-are-versioned-config-azure"
        },
        {
          "id": "daily-app-database-backups-config-digitalocean"
        },
        {
          "id": "daily-database-backups-config"
        },
        {
          "id": "daily-database-backups-config-azure"
        },
        {
          "id": "daily-database-backups-config-digitalocean"
        },
        {
          "id": "daily-database-backups-config-gcp"
        },
        {
          "id": "daily-database-backups-config-heroku"
        },
        {
          "id": "mongo-atlas-backups"
        },
        {
          "id": "storage-buckets-are-versioned-config"
        },
        {
          "id": "storage-buckets-are-versioned-config-digitaloceanspaces"
        },
        {
          "id": "storage-buckets-are-versioned-config-gcp"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "disaster-recovery-tabletop"
        }
      ]
    },
    {
      "id": "production-data-segmented",
      "name": "Production data segmented",
      "description": "The company prohibits confidential or sensitive customer data, by policy, from being used or stored in non-production systems/environments.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-data-management-policy-bsi-exists"
        },
        {
          "id": "customer-data-policies-policies"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "production-inventory-maintained",
      "name": "Production inventory maintained",
      "description": "The company maintains a formal inventory of production system assets.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "inventory-list-descriptions"
        },
        {
          "id": "inventory-list-owners"
        },
        {
          "id": "inventory-list-user-data"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "remote-access-mfa-enforced",
      "name": "Remote access MFA enforced",
      "description": "The company's production systems can only be remotely accessed by authorized employees possessing a valid multi-factor authentication (MFA) method.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "bitbucket-account-mfa-enabled"
        },
        {
          "id": "github-account-mfa-enabled"
        },
        {
          "id": "heroku-account-mfa-enabled"
        },
        {
          "id": "mfa-on-accounts-gsuite"
        },
        {
          "id": "mfa-on-accounts-infra"
        },
        {
          "id": "mfa-on-accounts-infra-root"
        },
        {
          "id": "mfa-on-accounts-okta"
        },
        {
          "id": "mfa-on-accounts-policy"
        },
        {
          "id": "mfa-on-accounts-version-control"
        },
        {
          "id": "mfa-on-groups-version-control"
        },
        {
          "id": "office-user-mfa-enabled"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "remote-access-vpn-enforced",
      "name": "Remote access encrypted enforced",
      "description": "The company's production systems can only be remotely accessed by authorized employees via an approved encrypted connection.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "require-admin-encrypted-iaas"
        },
        {
          "id": "require-admin-encrypted-iaas-gcp"
        },
        {
          "id": "require-admin-encrypted-iaas-heroku"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "risk-assessment-objectives",
      "name": "Risk assessment objectives specified",
      "description": "The company specifies its objectives to enable the identification and assessment of risk related to the objectives.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-risk-management-policy-bsi-exists"
        },
        {
          "id": "risk-assessment-complete"
        },
        {
          "id": "risk-register-complete"
        },
        {
          "id": "risk-register-questions-complete"
        },
        {
          "id": "risk-register-scenario-tasks-created"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "risk-assessment-performed",
      "name": "Risks assessments performed",
      "description": "The company's risk assessments are performed at least annually. As part of this process, threats and changes (environmental, regulatory, and technological) to service commitments are identified and the risks are formally assessed. The risk assessment includes a consideration of the potential for fraud and how fraud may impact the achievement of objectives.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "risk-assessment-complete"
        },
        {
          "id": "risk-register-complete"
        },
        {
          "id": "risk-register-questions-complete"
        },
        {
          "id": "risk-register-scenario-tasks-created"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "risk-assessment-program",
      "name": "Risk management program established",
      "description": "The company has a documented risk management program is in place that includes guidance on the identification of potential threats, rating the significance of the risks associated with the identified threats, and mitigation strategies for those risks.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-risk-management-policy-bsi-exists"
        },
        {
          "id": "employees-accepted-risk-assessment-program"
        },
        {
          "id": "employees-accepted-risk-management-policy-bsi"
        },
        {
          "id": "risk-assessment-policy-policies"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "role-responsibilities-specified",
      "name": "Roles and responsibilities specified",
      "description": "Roles and responsibilities for the design, development, implementation, operation, maintenance, and monitoring of information security controls are formally assigned in job descriptions and/or the Roles and Responsibilities policy.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-information-security-roles-and-responsibilities-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "security-job-descriptions"
        }
      ]
    },
    {
      "id": "security-awareness-training-completed",
      "name": "Security awareness training implemented",
      "description": "The company requires employees to complete security awareness training within thirty days of hire and at least annually thereafter.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-human-resource-security-policy-bsi-exists"
        },
        {
          "id": "security-training-policies"
        },
        {
          "id": "security-training-records"
        },
        {
          "id": "security-training-tracked-records"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "sat-completion-bulk"
        }
      ]
    },
    {
      "id": "security-policies-established-reviewed",
      "name": "Security policies established and reviewed",
      "description": "The company's information security policies and procedures are documented and reviewed at least annually.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "acceptable-use-policy-policies"
        },
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "approved-asset-management-policy-bsi-exists"
        },
        {
          "id": "approved-business-continuity-and-disaster-recovery-plan-bsi-exists"
        },
        {
          "id": "approved-code-of-conduct-bsi-exists"
        },
        {
          "id": "approved-cryptography-policy-bsi-exists"
        },
        {
          "id": "approved-data-management-policy-bsi-exists"
        },
        {
          "id": "approved-human-resource-security-policy-bsi-exists"
        },
        {
          "id": "approved-incident-response-plan-bsi-exists"
        },
        {
          "id": "approved-information-security-policy-bsi-exists"
        },
        {
          "id": "approved-information-security-roles-and-responsibilities-bsi-exists"
        },
        {
          "id": "approved-operations-security-policy-bsi-exists"
        },
        {
          "id": "approved-physical-security-policy-bsi-exists"
        },
        {
          "id": "approved-risk-management-policy-bsi-exists"
        },
        {
          "id": "approved-secure-development-policy-bsi-exists"
        },
        {
          "id": "approved-third-party-management-policy-bsi-exists"
        },
        {
          "id": "business-continuity-plan-policy"
        },
        {
          "id": "change-management-policy-policies"
        },
        {
          "id": "code-of-conduct-policies"
        },
        {
          "id": "credential-keys-managed-policy"
        },
        {
          "id": "cryptography-policies-policies"
        },
        {
          "id": "customer-data-policies-database"
        },
        {
          "id": "customer-data-policies-policies"
        },
        {
          "id": "daily-database-backups-policy"
        },
        {
          "id": "data-classification-policy-policies"
        },
        {
          "id": "data-protection-policy-policies"
        },
        {
          "id": "disaster-recovery-policy-policies"
        },
        {
          "id": "employee-disclosure-process-policies"
        },
        {
          "id": "employees-accepted-access-control-policy-bsi"
        },
        {
          "id": "employees-accepted-asset-management-policy"
        },
        {
          "id": "employees-accepted-asset-management-policy-bsi"
        },
        {
          "id": "employees-accepted-backup-policy"
        },
        {
          "id": "employees-accepted-business-continuity-and-disaster-recovery-plan-bsi"
        },
        {
          "id": "employees-accepted-business-continuity-plan"
        },
        {
          "id": "employees-accepted-change-management-policy"
        },
        {
          "id": "employees-accepted-code-of-conduct-bsi"
        },
        {
          "id": "employees-accepted-cryptography-policy"
        },
        {
          "id": "employees-accepted-cryptography-policy-bsi"
        },
        {
          "id": "employees-accepted-data-classification-policy"
        },
        {
          "id": "employees-accepted-data-delete-policy"
        },
        {
          "id": "employees-accepted-data-management-policy-bsi"
        },
        {
          "id": "employees-accepted-data-protection-policy"
        },
        {
          "id": "employees-accepted-disaster-recovery-plan"
        },
        {
          "id": "employees-accepted-human-resource-security-policy-bsi"
        },
        {
          "id": "employees-accepted-incident-response-plan-bsi"
        },
        {
          "id": "employees-accepted-information-security-policy"
        },
        {
          "id": "employees-accepted-information-security-policy-bsi"
        },
        {
          "id": "employees-accepted-information-security-roles-and-responsibilities-bsi"
        },
        {
          "id": "employees-accepted-operations-security-policy-bsi"
        },
        {
          "id": "employees-accepted-password-policy"
        },
        {
          "id": "employees-accepted-physical-security-policy"
        },
        {
          "id": "employees-accepted-physical-security-policy-bsi"
        },
        {
          "id": "employees-accepted-responsible-disclosure-policy"
        },
        {
          "id": "employees-accepted-risk-assessment-program"
        },
        {
          "id": "employees-accepted-risk-management-policy-bsi"
        },
        {
          "id": "employees-accepted-secure-development-policy-bsi"
        },
        {
          "id": "employees-accepted-system-access-control-policy"
        },
        {
          "id": "employees-accepted-third-party-management-policy-bsi"
        },
        {
          "id": "employees-accepted-vendor-management-policy"
        },
        {
          "id": "internal-password-policy-policies"
        },
        {
          "id": "least-privileged-policy-policies"
        },
        {
          "id": "physical-security-policies"
        },
        {
          "id": "responsible-disclosure-policy-policies"
        },
        {
          "id": "risk-assessment-policy-policies"
        },
        {
          "id": "secure-data-delete-policies"
        },
        {
          "id": "security-policies-accepted"
        },
        {
          "id": "security-team-has-communication-channel-to-the-CEO-policies"
        },
        {
          "id": "security-team-policies"
        },
        {
          "id": "security-training-policies"
        },
        {
          "id": "sla-for-security-bugs-policies"
        },
        {
          "id": "vendor-management-policy"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "service-description-communicated",
      "name": "Service description communicated",
      "description": "The company provides a description of its products and services to internal and external users.",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "network-diagram"
        },
        {
          "id": "product-documentation"
        }
      ]
    },
    {
      "id": "support-infra-patched",
      "name": "Service infrastructure maintained",
      "description": "The company has infrastructure supporting the service patched as a part of routine maintenance and as a result of identified vulnerabilities to help ensure that servers supporting the service are hardened against security threats.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "container-packages-checked-for-vulnerabilities-records-closed-aws"
        },
        {
          "id": "container-packages-checked-for-vulnerabilities-records-closed-azure"
        },
        {
          "id": "container-packages-checked-for-vulnerabilities-records-closed-gcp"
        },
        {
          "id": "infra-packages-checked-for-vulnerabilities-records-closed"
        },
        {
          "id": "packages-checked-for-vulnerabilities-records-closed-snyk"
        },
        {
          "id": "process-for-install-os-updates-and-patches-config-automation"
        },
        {
          "id": "process-for-install-os-updates-and-patches-config-month"
        },
        {
          "id": "security-issues-assigned-records"
        },
        {
          "id": "security-issues-prioritized-records"
        },
        {
          "id": "security-issues-tracked-records"
        },
        {
          "id": "sla-for-security-bugs-records"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "vulnerabilities-remediated-sample"
        },
        {
          "id": "vulnerability-scan"
        }
      ]
    },
    {
      "id": "support-system-available",
      "name": "Support system available",
      "description": "The company has an external-facing support system in place that allows users to report system information on failures, incidents, concerns, and other complaints to appropriate personnel.",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "customer-support-site"
        },
        {
          "id": "product-documentation"
        },
        {
          "id": "public-change-log"
        }
      ]
    },
    {
      "id": "system-activity-logged",
      "name": "System activity logged",
      "description": "The company captures system activity, including user activity, in transaction logs.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "activity-log-blob-container-has-logging-azure"
        },
        {
          "id": "activity-log-blob-containers-are-versioned-azure"
        },
        {
          "id": "activity-log-blob-containers-have-soft-delete-config-azure"
        },
        {
          "id": "flow-logs-on-azure"
        },
        {
          "id": "flow-logs-on-config"
        },
        {
          "id": "flow-logs-on-config-gcp"
        },
        {
          "id": "heroku-logs-drained"
        },
        {
          "id": "infra-activity-tracked-config"
        },
        {
          "id": "infra-activity-tracked-config-azure"
        },
        {
          "id": "infra-activity-tracked-config-heroku"
        },
        {
          "id": "logging-bucket-permissions"
        },
        {
          "id": "logs-centrally-stored-authorization"
        },
        {
          "id": "logs-centrally-stored-authorization-gcp"
        },
        {
          "id": "logs-centrally-stored-config"
        },
        {
          "id": "logs-centrally-stored-config-gcp"
        },
        {
          "id": "logs-retained-for-twelve-months-config"
        },
        {
          "id": "logs-retained-for-twelve-months-config-gcp"
        },
        {
          "id": "logs-sink-destinations-tracked-gcp"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "system-capacity-reviewed",
      "name": "System capacity reviewed",
      "description": "The company evaluates system capacity on an ongoing basis, and system changes are implemented to help ensure that processing capacity can meet demand.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-operations-security-policy-bsi-exists"
        },
        {
          "id": "bigtable-cluster-monitored-and-alarmed-config-cpu-gcp"
        },
        {
          "id": "bigtable-cluster-monitored-and-alarmed-config-storage-gcp"
        },
        {
          "id": "load-balancer-config"
        },
        {
          "id": "load-balancer-config-azure"
        },
        {
          "id": "load-balancer-config-digitalocean"
        },
        {
          "id": "load-balancer-config-heroku"
        },
        {
          "id": "load-balancers-monitored-and-alarmed-config-healthy"
        },
        {
          "id": "load-balancers-monitored-and-alarmed-config-healthy-azure"
        },
        {
          "id": "load-balancers-monitored-and-alarmed-config-latency"
        },
        {
          "id": "load-balancers-monitored-and-alarmed-config-latency-azure"
        },
        {
          "id": "load-balancers-monitored-and-alarmed-config-servererrors"
        },
        {
          "id": "load-balancers-monitored-and-alarmed-config-servererrors-azure"
        },
        {
          "id": "mongo-atlas-autoscale-compute"
        },
        {
          "id": "mongo-atlas-autoscale-disk"
        },
        {
          "id": "mongo-atlas-cluster-tier"
        },
        {
          "id": "mysql-database-monitored-and-alarmed-config-cpu"
        },
        {
          "id": "mysql-database-monitored-and-alarmed-config-cpu-gcp"
        },
        {
          "id": "mysql-database-monitored-and-alarmed-config-free-memory"
        },
        {
          "id": "mysql-database-monitored-and-alarmed-config-free-memory-gcp"
        },
        {
          "id": "mysql-database-monitored-and-alarmed-config-io"
        },
        {
          "id": "mysql-database-monitored-and-alarmed-config-io-gcp"
        },
        {
          "id": "mysql-database-monitored-and-alarmed-config-storage-space"
        },
        {
          "id": "mysql-database-monitored-and-alarmed-config-storage-space-gcp"
        },
        {
          "id": "nosql-database-monitored-and-alarmed-config-read"
        },
        {
          "id": "nosql-database-monitored-and-alarmed-config-write"
        },
        {
          "id": "nosql-datastore-monitored-and-alarmed-config-read-gcp"
        },
        {
          "id": "nosql-datastore-monitored-and-alarmed-config-write-gcp"
        },
        {
          "id": "nosql-firestore-monitored-and-alarmed-config-read-gcp"
        },
        {
          "id": "nosql-firestore-monitored-and-alarmed-config-write-gcp"
        },
        {
          "id": "pubsub-subscriptions-monitored-and-alarmed-config-age"
        },
        {
          "id": "servers-monitored-and-alarmed-config-cpu"
        },
        {
          "id": "servers-monitored-and-alarmed-config-cpu-azure"
        },
        {
          "id": "servers-monitored-and-alarmed-config-cpu-azure-vm-scaleset"
        },
        {
          "id": "servers-monitored-and-alarmed-config-cpu-gcp"
        },
        {
          "id": "sql-database-monitored-and-alarmed-config-cpu-azure"
        },
        {
          "id": "sql-database-monitored-and-alarmed-config-free-memory-azure"
        },
        {
          "id": "sql-database-monitored-and-alarmed-config-io-azure"
        },
        {
          "id": "sql-database-monitored-and-alarmed-config-storage-space-azure"
        },
        {
          "id": "sqs-queues-monitored-and-alarmed-config-age"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "system-activity-log-settings"
        }
      ]
    },
    {
      "id": "system-changes-communicated",
      "name": "System changes communicated",
      "description": "The company communicates system changes to authorized internal users.",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "system-update-internal-communication"
        }
      ]
    },
    {
      "id": "system-customer-input-validation",
      "name": "Processed transactions verified",
      "description": "The company's system validates that completed customer transactions align with the customer's scheduled transaction. Customers and/or account managers are notified of any errors or delinquencies.",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": []
    },
    {
      "id": "system-data-input-monitoring",
      "name": "Processing data inputs validated",
      "description": "The company's system assesses data inputs for compliance with input requirements. On-screen alerts are generated whenever there are issues with transaction inputs or processing.",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": []
    },
    {
      "id": "system-multiple-availability-zones",
      "name": "Production multi-availability zones established",
      "description": "The company has a multi-location strategy for production environments employed to permit the resumption of operations at other company data centers in the event of loss of a facility.",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "network-diagram"
        }
      ]
    },
    {
      "id": "system-network-hardening",
      "name": "Network and system hardening standards maintained",
      "description": "The company's network and system hardening standards are documented, based on industry best practices, and reviewed at least annually.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "access-granted-infra"
        },
        {
          "id": "access-granted-versioncontrol"
        },
        {
          "id": "access-removed-infra"
        },
        {
          "id": "access-removed-versioncontrol"
        },
        {
          "id": "digitalocean-droplets-firewalls-restrict-port-22"
        },
        {
          "id": "digitalocean-droplets-firewalls-used"
        },
        {
          "id": "firewall-config-acl"
        },
        {
          "id": "firewall-config-acl-gcp"
        },
        {
          "id": "firewall-config-acl-heroku"
        },
        {
          "id": "firewall-config-default-deny"
        },
        {
          "id": "firewall-config-default-deny-gcp"
        },
        {
          "id": "firewall-config-default-deny-heroku"
        },
        {
          "id": "firewall-config-server"
        },
        {
          "id": "firewall-config-server-heroku"
        },
        {
          "id": "firewall-endpoint-policies"
        },
        {
          "id": "infra-access-requires-approval-config"
        },
        {
          "id": "ssh-denied-azure"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "system-threshold-monitoring",
      "name": "Infrastructure performance monitored",
      "description": "An infrastructure monitoring tool is utilized to monitor systems, infrastructure, and performance and generates alerts when specific predefined thresholds are met.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "bigtable-cluster-monitored-and-alarmed-config-cpu-gcp"
        },
        {
          "id": "bigtable-cluster-monitored-and-alarmed-config-storage-gcp"
        },
        {
          "id": "load-balancer-config"
        },
        {
          "id": "load-balancer-config-azure"
        },
        {
          "id": "load-balancer-config-digitalocean"
        },
        {
          "id": "load-balancer-config-heroku"
        },
        {
          "id": "load-balancer-http-to-https"
        },
        {
          "id": "load-balancer-http-to-https-azure"
        },
        {
          "id": "load-balancer-http-to-https-digitalocean"
        },
        {
          "id": "load-balancers-monitored-and-alarmed-config-healthy"
        },
        {
          "id": "load-balancers-monitored-and-alarmed-config-healthy-azure"
        },
        {
          "id": "load-balancers-monitored-and-alarmed-config-latency"
        },
        {
          "id": "load-balancers-monitored-and-alarmed-config-latency-azure"
        },
        {
          "id": "load-balancers-monitored-and-alarmed-config-servererrors"
        },
        {
          "id": "load-balancers-monitored-and-alarmed-config-servererrors-azure"
        },
        {
          "id": "mongo-atlas-autoscale-compute"
        },
        {
          "id": "mongo-atlas-autoscale-disk"
        },
        {
          "id": "mongo-atlas-cluster-tier"
        },
        {
          "id": "mysql-database-monitored-and-alarmed-config-cpu"
        },
        {
          "id": "mysql-database-monitored-and-alarmed-config-cpu-gcp"
        },
        {
          "id": "mysql-database-monitored-and-alarmed-config-free-memory"
        },
        {
          "id": "mysql-database-monitored-and-alarmed-config-free-memory-gcp"
        },
        {
          "id": "mysql-database-monitored-and-alarmed-config-io"
        },
        {
          "id": "mysql-database-monitored-and-alarmed-config-io-gcp"
        },
        {
          "id": "mysql-database-monitored-and-alarmed-config-storage-space"
        },
        {
          "id": "mysql-database-monitored-and-alarmed-config-storage-space-gcp"
        },
        {
          "id": "nosql-database-monitored-and-alarmed-config-read"
        },
        {
          "id": "nosql-database-monitored-and-alarmed-config-write"
        },
        {
          "id": "nosql-datastore-monitored-and-alarmed-config-read-gcp"
        },
        {
          "id": "nosql-datastore-monitored-and-alarmed-config-write-gcp"
        },
        {
          "id": "nosql-firestore-monitored-and-alarmed-config-read-gcp"
        },
        {
          "id": "nosql-firestore-monitored-and-alarmed-config-write-gcp"
        },
        {
          "id": "pubsub-subscriptions-monitored-and-alarmed-config-age"
        },
        {
          "id": "servers-monitored-and-alarmed-config-cpu"
        },
        {
          "id": "servers-monitored-and-alarmed-config-cpu-azure"
        },
        {
          "id": "servers-monitored-and-alarmed-config-cpu-azure-vm-scaleset"
        },
        {
          "id": "servers-monitored-and-alarmed-config-cpu-gcp"
        },
        {
          "id": "sql-database-monitored-and-alarmed-config-cpu-azure"
        },
        {
          "id": "sql-database-monitored-and-alarmed-config-free-memory-azure"
        },
        {
          "id": "sql-database-monitored-and-alarmed-config-io-azure"
        },
        {
          "id": "sql-database-monitored-and-alarmed-config-storage-space-azure"
        },
        {
          "id": "sqs-queues-monitored-and-alarmed-config-age"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "third-party-agreements",
      "name": "Third-party agreements established",
      "description": "The company has written agreements in place with vendors and related third-parties. These agreements include confidentiality and privacy commitments applicable to that entity.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "vendor-management-records"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "cloud-service-provider-agreements"
        },
        {
          "id": "privacy-policy"
        },
        {
          "id": "terms-of-service"
        }
      ]
    },
    {
      "id": "third-party-privacy-documentation-reviewed",
      "name": "Third party privacy documentation reviewed",
      "description": "The company has established, maintains, and reviews, at least annually, documentation on the nature, extent, and purpose of personal information collected, processed, stored, and/or disclosed to third parties.",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "privacy-policy"
        },
        {
          "id": "terms-of-service"
        }
      ]
    },
    {
      "id": "unique-account-authentication-enforced",
      "name": "Unique account authentication enforced",
      "description": "The company requires authentication to systems and applications to use unique username and password or authorized Secure Socket Shell (SSH) keys.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "infra-unique-accounts-group"
        },
        {
          "id": "infra-unique-accounts-records-email"
        },
        {
          "id": "infra-unique-accounts-records-infra"
        },
        {
          "id": "infra-unique-accounts-records-versioncontrol"
        },
        {
          "id": "infra-unique-accounts-roles"
        },
        {
          "id": "infra-unique-accounts-roles-gcp"
        },
        {
          "id": "infra-unique-accounts-roles-heroku"
        },
        {
          "id": "infra-unique-accounts-root"
        },
        {
          "id": "infra-unique-accounts-unused"
        },
        {
          "id": "infra-unique-accounts-user"
        },
        {
          "id": "infra-unique-accounts-user-heroku"
        },
        {
          "id": "require-ssh-config"
        },
        {
          "id": "unique-ssh-keys"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "vendor-management-program-established",
      "name": "Vendor management program established",
      "description": "The company has a vendor management program in place. Components of this program include:\n- critical third-party vendor inventory;\n- vendor's security and privacy requirements; and\n- review of critical third-party vendors at least annually.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-third-party-management-policy-bsi-exists"
        },
        {
          "id": "employees-accepted-third-party-management-policy-bsi"
        },
        {
          "id": "employees-accepted-vendor-management-policy"
        },
        {
          "id": "high-severity-vendor-compliance-reports"
        },
        {
          "id": "subservice-organizations-soc-policies"
        },
        {
          "id": "vendor-management-policy"
        },
        {
          "id": "vendor-management-records"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "vulnerability-scans-conducted",
      "name": "Vulnerabilities scanned and remediated",
      "description": "Host-based vulnerability scans are performed at least quarterly on all external-facing systems. Critical and high vulnerabilities are tracked to remediation.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "container-packages-checked-for-vulnerabilities-records-closed-aws"
        },
        {
          "id": "container-packages-checked-for-vulnerabilities-records-closed-azure"
        },
        {
          "id": "container-packages-checked-for-vulnerabilities-records-closed-gcp"
        },
        {
          "id": "employees-without-laptops"
        },
        {
          "id": "infra-packages-checked-for-vulnerabilities-records-closed"
        },
        {
          "id": "packages-checked-for-vulnerabilities-records-closed-snyk"
        },
        {
          "id": "security-issues-tracked-records"
        },
        {
          "id": "security-vulnerabilities-monitored-laptops"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "vulnerabilities-remediated-sample"
        },
        {
          "id": "vulnerability-scan"
        }
      ]
    },
    {
      "id": "whistleblower-policy-established",
      "name": "Whistleblower policy established",
      "description": "The company has established a formalized whistleblower policy, and an anonymous communication channel is in place for users to report potential issues or fraud concerns.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-information-security-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "anonymous-communication-channel"
        }
      ]
    }
  ],
  "sections": [
    {
      "id": "A 1.1",
      "controls": [
        {
          "id": "system-capacity-reviewed"
        },
        {
          "id": "system-threshold-monitoring"
        }
      ]
    },
    {
      "id": "A 1.2",
      "controls": [
        {
          "id": "continuity-disaster-recovery-plans-tested"
        },
        {
          "id": "data-backup-procedures"
        },
        {
          "id": "database-replication-utilized"
        },
        {
          "id": "environmental-monitoring-devices-implemented"
        },
        {
          "id": "environmental-security-inspected-annually"
        },
        {
          "id": "production-data-backups-conducted"
        },
        {
          "id": "risk-assessment-performed"
        },
        {
          "id": "risk-assessment-program"
        },
        {
          "id": "system-multiple-availability-zones"
        }
      ]
    },
    {
      "id": "A 1.3",
      "controls": [
        {
          "id": "continuity-and-disaster-recovery-plans-established"
        },
        {
          "id": "continuity-disaster-recovery-plans-tested"
        },
        {
          "id": "data-backup-procedures"
        },
        {
          "id": "intrusion-detection-system"
        }
      ]
    },
    {
      "id": "C 1.1",
      "controls": [
        {
          "id": "data-classification-policy"
        },
        {
          "id": "data-retention-procedures-established"
        },
        {
          "id": "production-data-segmented"
        },
        {
          "id": "third-party-agreements"
        },
        {
          "id": "unique-account-authentication-enforced"
        }
      ]
    },
    {
      "id": "C 1.2",
      "controls": [
        {
          "id": "asset-disposal-procedures-utilized"
        },
        {
          "id": "customer-data-deleted upon-leave"
        }
      ]
    },
    {
      "id": "CC 1.1",
      "controls": [
        {
          "id": "background-checks-performed"
        },
        {
          "id": "code-of-conduct-acknowledged-contractors"
        },
        {
          "id": "code-of-conduct-acknowledged-employees"
        },
        {
          "id": "confidentiality-agreement-acknowledged-contractors"
        },
        {
          "id": "confidentiality-agreement-acknowledged-employees"
        },
        {
          "id": "performance-evaluations-conducted"
        }
      ]
    },
    {
      "id": "CC 1.2",
      "controls": [
        {
          "id": "board-of-directors-briefing"
        },
        {
          "id": "board-of-directors-charter"
        },
        {
          "id": "board-of-directors-expertise"
        },
        {
          "id": "board-of-directors-meeting"
        }
      ]
    },
    {
      "id": "CC 1.3",
      "controls": [
        {
          "id": "board-of-directors-charter"
        },
        {
          "id": "management-roles-responsibilities-defined"
        },
        {
          "id": "organization-structure-documented"
        },
        {
          "id": "role-responsibilities-specified"
        }
      ]
    },
    {
      "id": "CC 1.4",
      "controls": [
        {
          "id": "background-checks-performed"
        },
        {
          "id": "performance-evaluations-conducted"
        },
        {
          "id": "role-responsibilities-specified"
        },
        {
          "id": "security-awareness-training-completed"
        }
      ]
    },
    {
      "id": "CC 1.5",
      "controls": [
        {
          "id": "code-of-conduct-acknowledged-employees"
        },
        {
          "id": "performance-evaluations-conducted"
        },
        {
          "id": "role-responsibilities-specified"
        }
      ]
    },
    {
      "id": "CC 2.1",
      "controls": [
        {
          "id": "control-self-assessments-conducted"
        },
        {
          "id": "log-management-utilized"
        },
        {
          "id": "vulnerability-scans-conducted"
        }
      ]
    },
    {
      "id": "CC 2.2",
      "controls": [
        {
          "id": "incident-response-policies-established"
        },
        {
          "id": "management-roles-responsibilities-defined"
        },
        {
          "id": "role-responsibilities-specified"
        },
        {
          "id": "security-awareness-training-completed"
        },
        {
          "id": "security-policies-established-reviewed"
        },
        {
          "id": "service-description-communicated"
        },
        {
          "id": "system-changes-communicated"
        },
        {
          "id": "whistleblower-policy-established"
        }
      ]
    },
    {
      "id": "CC 2.3",
      "controls": [
        {
          "id": "commitments-externally-communicated"
        },
        {
          "id": "external-service-impact-notifications"
        },
        {
          "id": "external-support-resources"
        },
        {
          "id": "service-description-communicated"
        },
        {
          "id": "support-system-available"
        },
        {
          "id": "third-party-agreements"
        }
      ]
    },
    {
      "id": "CC 3.1",
      "controls": [
        {
          "id": "risk-assessment-objectives"
        },
        {
          "id": "risk-assessment-program"
        }
      ]
    },
    {
      "id": "CC 3.2",
      "controls": [
        {
          "id": "continuity-disaster-recovery-plans-tested"
        },
        {
          "id": "risk-assessment-performed"
        },
        {
          "id": "risk-assessment-program"
        },
        {
          "id": "vendor-management-program-established"
        }
      ]
    },
    {
      "id": "CC 3.3",
      "controls": [
        {
          "id": "risk-assessment-performed"
        },
        {
          "id": "risk-assessment-program"
        }
      ]
    },
    {
      "id": "CC 3.4",
      "controls": [
        {
          "id": "configuration-management-system-established"
        },
        {
          "id": "penetration-testing"
        },
        {
          "id": "risk-assessment-performed"
        },
        {
          "id": "risk-assessment-program"
        }
      ]
    },
    {
      "id": "CC 4.1",
      "controls": [
        {
          "id": "control-self-assessments-conducted"
        },
        {
          "id": "penetration-testing"
        },
        {
          "id": "vendor-management-program-established"
        },
        {
          "id": "vulnerability-scans-conducted"
        }
      ]
    },
    {
      "id": "CC 4.2",
      "controls": [
        {
          "id": "control-self-assessments-conducted"
        },
        {
          "id": "vendor-management-program-established"
        }
      ]
    },
    {
      "id": "CC 5.1",
      "controls": [
        {
          "id": "risk-assessment-program"
        },
        {
          "id": "security-policies-established-reviewed"
        }
      ]
    },
    {
      "id": "CC 5.2",
      "controls": [
        {
          "id": "access-control-procedures"
        },
        {
          "id": "development-lifecycle-established"
        },
        {
          "id": "security-policies-established-reviewed"
        }
      ]
    },
    {
      "id": "CC 5.3",
      "controls": [
        {
          "id": "change-management-procedures"
        },
        {
          "id": "data-backup-procedures"
        },
        {
          "id": "data-retention-procedures-established"
        },
        {
          "id": "development-lifecycle-established"
        },
        {
          "id": "incident-response-policies-established"
        },
        {
          "id": "risk-assessment-objectives"
        },
        {
          "id": "risk-assessment-program"
        },
        {
          "id": "role-responsibilities-specified"
        },
        {
          "id": "security-policies-established-reviewed"
        },
        {
          "id": "vendor-management-program-established"
        }
      ]
    },
    {
      "id": "CC 6.1",
      "controls": [
        {
          "id": "access-application-restricted"
        },
        {
          "id": "access-control-procedures"
        },
        {
          "id": "access-database-restricted-users"
        },
        {
          "id": "access-datastores-ssh"
        },
        {
          "id": "access-encryption-keys"
        },
        {
          "id": "access-firewalls"
        },
        {
          "id": "access-operating-system-restricted"
        },
        {
          "id": "access-production-network-restricted"
        },
        {
          "id": "access-role-based"
        },
        {
          "id": "access-ssh-required"
        },
        {
          "id": "changes-approval-required"
        },
        {
          "id": "data-classification-policy"
        },
        {
          "id": "data-encrypted"
        },
        {
          "id": "infra-network-segregation"
        },
        {
          "id": "policies-password-complexity"
        },
        {
          "id": "production-inventory-maintained"
        },
        {
          "id": "remote-access-mfa-enforced"
        },
        {
          "id": "remote-access-vpn-enforced"
        },
        {
          "id": "unique-account-authentication-enforced"
        }
      ]
    },
    {
      "id": "CC 6.2",
      "controls": [
        {
          "id": "access-control-procedures"
        },
        {
          "id": "access-reviews"
        },
        {
          "id": "access-revoked-termination"
        },
        {
          "id": "access-role-based"
        },
        {
          "id": "access-ssh-required"
        }
      ]
    },
    {
      "id": "CC 6.3",
      "controls": [
        {
          "id": "access-control-procedures"
        },
        {
          "id": "access-reviews"
        },
        {
          "id": "access-revoked-termination"
        },
        {
          "id": "access-role-based"
        },
        {
          "id": "access-ssh-required"
        }
      ]
    },
    {
      "id": "CC 6.4",
      "controls": [
        {
          "id": "access-physical-datacenter"
        },
        {
          "id": "access-physical-review"
        },
        {
          "id": "access-physical-visitors"
        },
        {
          "id": "access-reviews"
        }
      ]
    },
    {
      "id": "CC 6.5",
      "controls": [
        {
          "id": "access-revoked-termination"
        },
        {
          "id": "asset-disposal-procedures-utilized"
        },
        {
          "id": "customer-data-deleted upon-leave"
        },
        {
          "id": "data-retention-procedures-established"
        }
      ]
    },
    {
      "id": "CC 6.6",
      "controls": [
        {
          "id": "access-ssh-required"
        },
        {
          "id": "data-encrypted-in-transit"
        },
        {
          "id": "intrusion-detection-system"
        },
        {
          "id": "network-firewalls-reviewed"
        },
        {
          "id": "network-firewalls-utilized"
        },
        {
          "id": "remote-access-mfa-enforced"
        },
        {
          "id": "remote-access-vpn-enforced"
        },
        {
          "id": "support-infra-patched"
        },
        {
          "id": "system-network-hardening"
        }
      ]
    },
    {
      "id": "CC 6.7",
      "controls": [
        {
          "id": "assets-portable-media-encrypted"
        },
        {
          "id": "data-encrypted-in-transit"
        },
        {
          "id": "MDM-system-utilized"
        }
      ]
    },
    {
      "id": "CC 6.8",
      "controls": [
        {
          "id": "assets-anti-malware"
        },
        {
          "id": "development-lifecycle-established"
        },
        {
          "id": "support-infra-patched"
        }
      ]
    },
    {
      "id": "CC 7.1",
      "controls": [
        {
          "id": "change-management-procedures"
        },
        {
          "id": "configuration-management-system-established"
        },
        {
          "id": "policies-incident-monitoring"
        },
        {
          "id": "risk-assessment-performed"
        },
        {
          "id": "vulnerability-scans-conducted"
        }
      ]
    },
    {
      "id": "CC 7.2",
      "controls": [
        {
          "id": "intrusion-detection-system"
        },
        {
          "id": "log-management-utilized"
        },
        {
          "id": "penetration-testing"
        },
        {
          "id": "policies-incident-monitoring"
        },
        {
          "id": "support-infra-patched"
        },
        {
          "id": "system-threshold-monitoring"
        },
        {
          "id": "vulnerability-scans-conducted"
        }
      ]
    },
    {
      "id": "CC 7.3",
      "controls": [
        {
          "id": "incident-response-policies-established"
        },
        {
          "id": "policies-incident-management"
        }
      ]
    },
    {
      "id": "CC 7.4",
      "controls": [
        {
          "id": "incident-response-plan-tested"
        },
        {
          "id": "incident-response-policies-established"
        },
        {
          "id": "policies-incident-management"
        },
        {
          "id": "support-infra-patched"
        },
        {
          "id": "vulnerability-scans-conducted"
        }
      ]
    },
    {
      "id": "CC 7.5",
      "controls": [
        {
          "id": "continuity-disaster-recovery-plans-tested"
        },
        {
          "id": "incident-response-plan-tested"
        },
        {
          "id": "incident-response-policies-established"
        },
        {
          "id": "policies-incident-management"
        }
      ]
    },
    {
      "id": "CC 8.1",
      "controls": [
        {
          "id": "change-management-procedures"
        },
        {
          "id": "changes-approval-required"
        },
        {
          "id": "development-lifecycle-established"
        },
        {
          "id": "penetration-testing"
        },
        {
          "id": "support-infra-patched"
        },
        {
          "id": "system-network-hardening"
        },
        {
          "id": "vulnerability-scans-conducted"
        }
      ]
    },
    {
      "id": "CC 9.1",
      "controls": [
        {
          "id": "continuity-and-disaster-recovery-plans-established"
        },
        {
          "id": "cybersecurity-insurance-maintained"
        },
        {
          "id": "risk-assessment-performed"
        },
        {
          "id": "risk-assessment-program"
        }
      ]
    },
    {
      "id": "CC 9.2",
      "controls": [
        {
          "id": "third-party-agreements"
        },
        {
          "id": "vendor-management-program-established"
        }
      ]
    },
    {
      "id": "P 1.1",
      "controls": [
        {
          "id": "personal-information-collection-changes-communicated"
        },
        {
          "id": "policies-privacy-policy"
        },
        {
          "id": "privacy-policy-available"
        },
        {
          "id": "privacy-policy-reviewed"
        }
      ]
    },
    {
      "id": "P 2.1",
      "controls": [
        {
          "id": "policies-privacy-policy"
        },
        {
          "id": "privacy-explicity-consent-obtained"
        },
        {
          "id": "privacy-individual-consent-obtained"
        },
        {
          "id": "privacy-new-information-purpose-consent"
        },
        {
          "id": "privacy-opt-out"
        }
      ]
    },
    {
      "id": "P 3.1",
      "controls": [
        {
          "id": "personal-information-collection-changes-communicated"
        },
        {
          "id": "personal-information-collection-reviewed"
        },
        {
          "id": "personal-information-reliability-verified"
        },
        {
          "id": "privacy-policies-personal-information-use-reviewed"
        }
      ]
    },
    {
      "id": "P 3.2",
      "controls": [
        {
          "id": "privacy-explicity-consent-obtained"
        }
      ]
    },
    {
      "id": "P 4.1",
      "controls": [
        {
          "id": "privacy-policies-personal-information-use-reviewed"
        }
      ]
    },
    {
      "id": "P 4.2",
      "controls": [
        {
          "id": "data-retention-procedures-established"
        },
        {
          "id": "privacy-data-retained"
        }
      ]
    },
    {
      "id": "P 4.3",
      "controls": [
        {
          "id": "data-deletion-requests-handled"
        },
        {
          "id": "privacy-inquiries-handled"
        },
        {
          "id": "privacy-sensitive-data-disposal"
        }
      ]
    },
    {
      "id": "P 5.1",
      "controls": [
        {
          "id": "policies-privacy-policy"
        },
        {
          "id": "privacy-identity-verification"
        },
        {
          "id": "privacy-policy-established"
        }
      ]
    },
    {
      "id": "P 5.2",
      "controls": [
        {
          "id": "personal-information-changes-communicated"
        },
        {
          "id": "privacy-policy-established"
        }
      ]
    },
    {
      "id": "P 6.1",
      "controls": [
        {
          "id": "legal-personal-information-tracked"
        },
        {
          "id": "policies-privacy-policy"
        },
        {
          "id": "privacy-individual-consent-obtained"
        },
        {
          "id": "privacy-new-information-purpose-consent"
        },
        {
          "id": "third-party-agreements"
        },
        {
          "id": "third-party-privacy-documentation-reviewed"
        },
        {
          "id": "vendor-management-program-established"
        }
      ]
    },
    {
      "id": "P 6.2",
      "controls": [
        {
          "id": "third-party-privacy-documentation-reviewed"
        }
      ]
    },
    {
      "id": "P 6.3",
      "controls": [
        {
          "id": "incident-response-policies-established"
        },
        {
          "id": "policies-incident-management"
        }
      ]
    },
    {
      "id": "P 6.4",
      "controls": [
        {
          "id": "incident-response-policies-established"
        },
        {
          "id": "policies-incident-management"
        },
        {
          "id": "third-party-agreements"
        },
        {
          "id": "third-party-privacy-documentation-reviewed"
        },
        {
          "id": "vendor-management-program-established"
        }
      ]
    },
    {
      "id": "P 6.5",
      "controls": [
        {
          "id": "incident-response-policies-established"
        },
        {
          "id": "policies-incident-management"
        },
        {
          "id": "third-party-agreements"
        }
      ]
    },
    {
      "id": "P 6.6",
      "controls": [
        {
          "id": "incident-response-policies-established"
        },
        {
          "id": "policies-incident-management"
        }
      ]
    },
    {
      "id": "P 6.7",
      "controls": [
        {
          "id": "privacy-data-sharing"
        },
        {
          "id": "privacy-identity-verification"
        },
        {
          "id": "privacy-inquiries-handled"
        },
        {
          "id": "third-party-privacy-documentation-reviewed"
        }
      ]
    },
    {
      "id": "P 7.1",
      "controls": [
        {
          "id": "personal-data-updates"
        },
        {
          "id": "privacy-policy-established"
        },
        {
          "id": "third-party-privacy-documentation-reviewed"
        }
      ]
    },
    {
      "id": "P 8.1",
      "controls": [
        {
          "id": "board-of-directors-briefing"
        },
        {
          "id": "control-self-assessments-conducted"
        },
        {
          "id": "policies-incident-management"
        },
        {
          "id": "privacy-complaints-procedures"
        },
        {
          "id": "privacy-policy-established"
        },
        {
          "id": "risk-assessment-program"
        },
        {
          "id": "third-party-privacy-documentation-reviewed"
        }
      ]
    },
    {
      "id": "PI 1.1",
      "controls": [
        {
          "id": "commitments-externally-communicated"
        },
        {
          "id": "external-support-resources"
        },
        {
          "id": "service-description-communicated"
        }
      ]
    },
    {
      "id": "PI 1.2",
      "controls": [
        {
          "id": "customer-data-retained"
        },
        {
          "id": "development-lifecycle-established"
        },
        {
          "id": "policies-passwords-enforced"
        },
        {
          "id": "system-activity-logged"
        },
        {
          "id": "system-data-input-monitoring"
        }
      ]
    },
    {
      "id": "PI 1.3",
      "controls": [
        {
          "id": "development-lifecycle-established"
        },
        {
          "id": "processing-issues-automatically-alerted"
        },
        {
          "id": "system-activity-logged"
        },
        {
          "id": "system-customer-input-validation"
        },
        {
          "id": "system-data-input-monitoring"
        },
        {
          "id": "system-threshold-monitoring"
        }
      ]
    },
    {
      "id": "PI 1.4",
      "controls": [
        {
          "id": "customer-data-retained"
        },
        {
          "id": "customer-transactions-portal-available"
        },
        {
          "id": "data-encrypted"
        },
        {
          "id": "processing-alerts-available"
        },
        {
          "id": "processing-issues-automatically-alerted"
        },
        {
          "id": "system-data-input-monitoring"
        }
      ]
    },
    {
      "id": "PI 1.5",
      "controls": [
        {
          "id": "access-production-network-restricted"
        },
        {
          "id": "continuity-disaster-recovery-plans-tested"
        },
        {
          "id": "customer-data-retained"
        },
        {
          "id": "customer-transaction-modifications-restricted"
        }
      ]
    }
  ]
}
