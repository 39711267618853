import React from "react";

import type { SortParams } from "../../../../gen/components";
import { ControlLabel, SortFilterButton } from "./components";

export enum VulnerabilityTargetSortType {
  DUE_DATE,
  MOST_ISSUES,
}

export const SORT_PARAMS_BY_TYPE: {
  [k in VulnerabilityTargetSortType]: SortParams;
} = {
  [VulnerabilityTargetSortType.DUE_DATE]: {
    field: "minSlaDeadline",
    direction: 1,
  },
  [VulnerabilityTargetSortType.MOST_ISSUES]: {
    field: "totalVulnCount",
    direction: -1,
  },
};

interface IProps {
  currentSortType: VulnerabilityTargetSortType;
  onNewSortType(sortType: VulnerabilityTargetSortType): void;
}

export const VulnerabilityTargetSortControl: React.FC<IProps> = ({
  currentSortType,
  onNewSortType,
}) => (
  <div>
    <ControlLabel>Sort by:</ControlLabel>
    <SortFilterButton
      secondary={currentSortType === VulnerabilityTargetSortType.DUE_DATE}
      onClick={() => {
        onNewSortType(VulnerabilityTargetSortType.DUE_DATE);
      }}
    >
      Due date
    </SortFilterButton>
    <SortFilterButton
      secondary={currentSortType === VulnerabilityTargetSortType.MOST_ISSUES}
      onClick={() => {
        onNewSortType(VulnerabilityTargetSortType.MOST_ISSUES);
      }}
    >
      Most issues
    </SortFilterButton>
  </div>
);
