import { Button, Classes, Intent } from "@blueprintjs/core";
import { isSome } from "common/base/types/maybe";
import { ROLE_ARN_REGEX } from "common/resources/specific-resources/aws/aws-helpers-client-safe";
import gql from "graphql-tag";
import React, { useState } from "react";

import { LogError } from "../../../../errors";
import {
  AllLinkedCredentialsDocument,
  useSetCredentialMetadataForAwsMutation,
  useSetCredentialsMutation,
  useTestCredentialsMutation,
} from "../../../../gen/components";
import { AppToaster } from "../../../../helpers/toaster";
import { DisconnectCredentialDialog } from "../disconnect-credentials";
import type { IFormCredentialProps } from "../services/common-interface";
import { AccountsList } from "./accounts-list";
import { GetStarted } from "./get-started";
import { LinkRole } from "./link-role";
import { NewPolicy } from "./new-policy";
import { NewRole } from "./new-role";
import { RegionSelect } from "./region-select";

export enum LinkingSteps {
  ACCOUNTS = "ACCOUNTS",
  GET_STARTED = "GET_STARTED",
  NEW_POLICY = "NEW_POLICY",
  NEW_ROLE = "NEW_ROLE",
  LINK_ROLE = "LINK_ROLE",
  REGION = "REGION",
}

export const AWSCredentialLinker: React.FC<IFormCredentialProps> = ({
  credentials,
  domain,
  onCredentialsLinked,
}) => {
  const [pane, setPane] = useState(
    credentials.length > 0 ? LinkingSteps.ACCOUNTS : LinkingSteps.GET_STARTED
  );
  const [arn, setArn] = useState("");
  const [edit, setEdit] = useState(false);
  const [regions, setRegions] = useState([
    "us-east-2",
    "us-east-1",
    "us-west-1",
    "us-west-2",
  ]);

  const [setCredentials, { loading, error }] = useSetCredentialsMutation({
    onCompleted: () => {
      AppToaster.show({
        intent: Intent.SUCCESS,
        message: "AWS connection created",
      });
      onCredentialsLinked();
    },
    refetchQueries: [{ query: AllLinkedCredentialsDocument }],
    awaitRefetchQueries: true,
    variables: {
      credentials: JSON.stringify({
        assumeRoleArn: arn,
        regions,
      }),
      service: "aws",
      externalAccountId: arn,
    },
  });
  if (isSome(error)) {
    LogError(error);
  }

  const [setMetadata, { loading: metadataLoading, error: metadataError }] =
    useSetCredentialMetadataForAwsMutation({
      variables: {
        externalAccountId: arn,
        metadata: JSON.stringify({ regions }),
      },
      onCompleted: () => {
        AppToaster.show({
          intent: Intent.SUCCESS,
          message: "AWS regions updated",
        });
        onCredentialsLinked();
      },
      awaitRefetchQueries: true,
      refetchQueries: [{ query: AllLinkedCredentialsDocument }],
    });
  if (isSome(metadataError)) {
    LogError(metadataError);
  }

  const [testCredentials, { loading: testLoading, error: testError }] =
    useTestCredentialsMutation({
      variables: {
        credentials: JSON.stringify({ arn }),
        service: "aws",
      },
    });

  if (testError) {
    LogError(testError);
  }

  const [credentialId, setCredentialIdToDelete] = useState("");

  const [disconnectDialogOpen, setDisconnectDialogOpen] = useState(false);
  const disconnectDialog = (
    <DisconnectCredentialDialog
      externalAccountId={arn}
      isOpen={disconnectDialogOpen}
      closeDialog={() => setDisconnectDialogOpen(false)}
      credentialId={credentialId}
      service={"aws"}
    />
  );

  const panes: {
    [pane in LinkingSteps]: {
      content: React.ReactNode;
      footer: React.ReactNode;
    };
  } = {
    ACCOUNTS: {
      content: (
        <div>
          <AccountsList
            credentials={credentials}
            setArn={setArn}
            setEdit={setEdit}
            setCredentialIdToDelete={setCredentialIdToDelete}
            setPane={setPane}
            setRegions={setRegions}
            setDisconnectDialogOpen={setDisconnectDialogOpen}
          />
          {disconnectDialog}
        </div>
      ),
      footer: (
        <Button
          intent={Intent.PRIMARY}
          onClick={() => setPane(LinkingSteps.GET_STARTED)}
        >
          Add new account
        </Button>
      ),
    },
    GET_STARTED: {
      content: <GetStarted />,
      footer: (
        <Button
          intent={Intent.PRIMARY}
          onClick={() => setPane(LinkingSteps.NEW_POLICY)}
        >
          Get Started
        </Button>
      ),
    },
    NEW_POLICY: {
      content: <NewPolicy />,
      footer: (
        <>
          <Button onClick={() => setPane(LinkingSteps.GET_STARTED)}>
            Back
          </Button>
          <Button
            intent={Intent.PRIMARY}
            onClick={() => setPane(LinkingSteps.NEW_ROLE)}
          >
            Next
          </Button>
        </>
      ),
    },
    NEW_ROLE: {
      content: <NewRole domainId={domain.id} />,
      footer: (
        <>
          <Button onClick={() => setPane(LinkingSteps.NEW_POLICY)}>Back</Button>
          <Button
            intent={Intent.PRIMARY}
            onClick={() => setPane(LinkingSteps.LINK_ROLE)}
          >
            Next
          </Button>
        </>
      ),
    },
    LINK_ROLE: {
      content: <LinkRole arn={arn} setArn={setArn} />,
      footer: (
        <>
          <Button onClick={() => setPane(LinkingSteps.NEW_ROLE)}>Back</Button>
          <Button
            disabled={!ROLE_ARN_REGEX.test(arn)}
            intent={Intent.PRIMARY}
            loading={testLoading}
            onClick={async () => {
              const { data, errors } = await testCredentials();
              if ((errors && errors.length > 0) || !isSome(data)) {
                return;
              }
              if (!data.testCredentials.success) {
                AppToaster.show({
                  intent: Intent.WARNING,
                  message:
                    data.testCredentials.message ??
                    "Uh oh, something went wrong! Contact support@vanta.com for help.",
                  timeout: 5000,
                });
              } else {
                setPane(LinkingSteps.REGION);
              }
            }}
          >
            Choose Regions
          </Button>
        </>
      ),
    },
    REGION: {
      content: (
        <RegionSelect
          domain={domain}
          arn={arn}
          regions={regions}
          setRegions={setRegions}
        />
      ),
      footer: (
        <Button
          intent={Intent.PRIMARY}
          loading={loading || metadataLoading}
          onClick={async () => {
            if (edit) {
              await testCredentials();
              await setMetadata();
            } else {
              await setCredentials();
            }
          }}
        >
          Finish
        </Button>
      ),
    },
  };

  return (
    <div>
      <div className={Classes.DIALOG_HEADER}>Link AWS</div>
      <div className={Classes.DIALOG_BODY}>{panes[pane].content}</div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          {panes[pane].footer}
        </div>
      </div>
    </div>
  );
};

gql`
  mutation setCredentialMetadataForAws(
    $externalAccountId: String
    $metadata: String!
  ) {
    setCredentialMetadata(
      enable: true
      externalAccountId: $externalAccountId
      merge: true
      metadata: $metadata
      service: "aws"
    ) {
      id
    }
  }
`;
