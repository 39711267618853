import { Dialog } from "@blueprintjs/core";
import qs from "qs";
import React, { useState } from "react";

import { AllLinkableServiceGroups } from "../credentials/service-groups/all-linkable-service-groups";
import {
  PageHeadingInfo,
  VantaDashboardPage,
} from "../vanta-chrome/page-content/vanta-dashboard-page";
import { PostAuthDialog } from "./credential/post-auth-forms/post-auth-dialog";
import { shouldShowPostAuthForm } from "./credential/post-auth-forms/post-auth-form";
import { AMISecure } from "./docs/AmISecure";

export const ManageCredentialsPage: React.FunctionComponent = () => {
  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  const query = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
  const service = query.service;
  const postAuthDialogIsOpen = shouldShowPostAuthForm(service);

  return (
    <VantaDashboardPage
      headingInfo={PageHeadingInfo.CONNECTIONS}
      buttonProps={{ text: "Security", onClick: () => setDialogIsOpen(true) }}
    >
      <Dialog
        isOpen={dialogIsOpen}
        onClose={() => setDialogIsOpen(false)}
        title="Are my credentials stored securely?"
      >
        <AMISecure concern="credentials" />
      </Dialog>
      <AllLinkableServiceGroups />
      <PostAuthDialog service={service} dialogIsOpen={postAuthDialogIsOpen} />
    </VantaDashboardPage>
  );
};
