import { dateStringToDate } from "common/base/dateUtils";
import type { GenericVulnerabilityResource } from "common/base/types/gen";
import type { Maybe } from "common/base/types/maybe";
import {
  dropNothing,
  getTransformedOrElse,
  isSome,
  nothing,
} from "common/base/types/maybe";
import moment from "moment";
import React from "react";

import type { FetchVulnHistoryQuery } from "../../../../gen/components";
import { DataTable } from "../../components/data-table";
import { Caption, Heading } from "../common/components";
import { SeverityPill } from "../common/severity-pill";
import { getDateString } from "../utils";
import { TimeToResolution } from "./time-to-resolution";

type VulnHistoryItem = NonNullable<
  FetchVulnHistoryQuery["organization"]
>["resources"]["edges"][number]["node"];
interface IProps {
  history: VulnHistoryItem[];
  violations?: Maybe<boolean>;
}

interface IVulnHistoryTableData {
  title: string;
  vulnSource?: Maybe<string>;
  source: string;
  openedAt: Date;
  closedAt: Date;
  severity: number;
  slaInDays?: Maybe<number>;
}

function tableDataMapper(
  history: VulnHistoryItem
): Maybe<IVulnHistoryTableData> {
  if (!isSome(history.deletedAt)) {
    return nothing;
  }
  if (history.__typename === "SpecificAwsContainerVulnerabilityResource") {
    return {
      source: "AWS ECR",
      title: history.packageIdentifier,
      vulnSource: history.repositoryName,
      openedAt: dateStringToDate(history.createdAt),
      closedAt: dateStringToDate(history.deletedAt),
      severity: history.severity,
      slaInDays: getSLAInDays(history),
    };
  } else if (
    history.__typename === "SpecificAwsInspectorVulnerabilityResource"
  ) {
    return {
      source: "AWS Inspector",
      title: history.packageName,
      vulnSource: history.instanceId,
      openedAt: dateStringToDate(history.createdAt),
      closedAt: dateStringToDate(history.deletedAt),
      severity: history.severity,
      slaInDays: getSLAInDays(history),
    };
  } else if (
    history.__typename === "SpecificAzureContainerVulnerabilityResource"
  ) {
    return {
      source: "Azure Containers",
      title: history.displayName ?? history.packageIdentifier,
      vulnSource: history.repositoryName,
      openedAt: dateStringToDate(history.createdAt),
      closedAt: dateStringToDate(history.deletedAt),
      severity: history.severity,
      slaInDays: getSLAInDays(history),
    };
  } else if (
    history.__typename === "SpecificGCPContainerVulnerabilityResource"
  ) {
    return {
      source: "GCP Containers",
      title: history.packageIdentifier,
      vulnSource: history.repositoryName,
      openedAt: dateStringToDate(history.createdAt),
      closedAt: dateStringToDate(history.deletedAt),
      severity: history.severity,
      slaInDays: getSLAInDays(history),
    };
  } else if (history.__typename === "SpecificOsqueryVulnerabilityResource") {
    return {
      source: "Vanta Agent",
      title:
        history.vulnData.packageSource ??
        history.vulnData.packageName ??
        "unknown",
      vulnSource: history.vulnData.osquery?.prettyName,
      openedAt: dateStringToDate(history.createdAt),
      closedAt: dateStringToDate(history.deletedAt),
      severity: history.severity,
      slaInDays: getSLAInDays(history),
    };
  } else if (history.__typename === "SpecificSnykVulnerabilityResource") {
    return {
      source: "Snyk",
      title: getTransformedOrElse(
        history.snykPackageName,
        packageName => `${packageName} - ${history.title}`,
        history.title
      ),
      vulnSource: history.projectName,
      openedAt: dateStringToDate(history.createdAt),
      closedAt: dateStringToDate(history.deletedAt),
      severity: history.severity,
      slaInDays: getSLAInDays(history),
    };
  } else {
    return nothing;
  }

  function getSLAInDays(
    resource: Pick<GenericVulnerabilityResource, "createdAt" | "slaDeadline">
  ) {
    return isSome(resource.slaDeadline)
      ? moment
          .duration(
            new Date(resource.slaDeadline).getTime() -
              new Date(resource.createdAt).getTime(),
            "milliseconds"
          )
          .asDays()
      : nothing;
  }
}

export const VulnHistoryTable: React.FC<IProps> = ({ history, violations }) => (
  <DataTable
    data={dropNothing(history.map(tableDataMapper))}
    columnOrder={["source", "package", "sla", "timeline", "severity"]}
    columnWidths={["120px", "410px", "160px", "240px", "120px"]}
    createRow={h => {
      return {
        source: h.source,
        package: (
          <div>
            <Heading>{h.title}</Heading>
            {isSome(h.vulnSource) ? <Caption>{h.vulnSource}</Caption> : null}
          </div>
        ),
        sla: (
          <TimeToResolution
            openDurationMS={moment(h.closedAt).diff(moment(h.openedAt))}
            slaInDays={h.slaInDays}
          />
        ),
        timeline: (
          <div>
            <Heading>{getDateString(h.openedAt)}</Heading>
            <Caption>{getDateString(h.closedAt)}</Caption>
          </div>
        ),
        severity: <SeverityPill severity={h.severity} />,
      };
    }}
    emptyDefault={
      violations
        ? "No SLA violations to display on this page."
        : "No remediated vulnerabilities to display on this page."
    }
    header={{
      source: "Source",
      package: "Package/Identifier",
      sla: `Time ${violations ? "over SLA" : "to resolution"}`,
      timeline: "First / last detected",
      severity: "CVE Severity",
    }}
  />
);
