import { Button, Classes, HTMLSelect, Intent } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import React, { useContext, useState } from "react";

import { LogError } from "../../../../errors";
import {
  AllLinkedCredentialsDocument,
  useSetOrganizationForCredentialsMutation,
} from "../../../../gen/components";
import { DefaultLink } from "../../../../helpers/links";
import { AppToaster } from "../../../../helpers/toaster";
import { SERVICE_DETAILS } from "../../../credentials/service-groups";
import { UserContext } from "../../user-context";
import type { IOrgselectionProps } from "../services/common-interface";

const AZURE_DEVOPS_PERMISSIONS_DETAILS =
  "To be able to link an organization, you must have access to all of its repositories.";
const details: {
  [k: string]: {
    instructions: string;
    termForOrg: string;
    additionalDetails?: Maybe<string>;
    additionalErrorDetails?: Maybe<string>;
  };
} = {
  trello: {
    instructions: "https://help.trello.com/article/705-creating-a-new-team",
    termForOrg: "team",
  },
  bitbucket: {
    instructions:
      "https://support.atlassian.com/bitbucket-cloud/docs/create-your-workspace/",
    termForOrg: "workspace",
  },
  gitlab: {
    instructions: "https://docs.gitlab.com/ee/user/group/",
    termForOrg: "group",
    additionalDetails:
      "Vanta will scan the selected group and all of its subgroups.",
  },
  azuredevops: {
    instructions:
      "https://docs.microsoft.com/en-us/azure/devops/organizations/accounts/organization-management",
    termForOrg: "organization",
    additionalDetails: AZURE_DEVOPS_PERMISSIONS_DETAILS,
    additionalErrorDetails: AZURE_DEVOPS_PERMISSIONS_DETAILS,
  },
};

/**
 * On the backend we call every named group of people an "organization"
 */
export const OrgSelect: React.FunctionComponent<IOrgselectionProps> = ({
  organizations,
  selectedOrg: currentOrg,
  onClose,
  service,
}) => {
  const { domainDisplayName } = useContext(UserContext);
  const [setOrganization] = useSetOrganizationForCredentialsMutation({
    refetchQueries: [{ query: AllLinkedCredentialsDocument }],
  });
  const [selectedOrg, setSelectedOrg] = useState(currentOrg);
  const [mutationInFlight, setInFlight] = useState(false);
  const {
    instructions,
    termForOrg,
    additionalDetails,
    additionalErrorDetails,
  } = details[service];
  const displayService = SERVICE_DETAILS[service].displayName;

  if (isSome(organizations)) {
    if (organizations.length === 0) {
      return (
        <div className={Classes.RUNNING_TEXT}>
          <div className={Classes.DIALOG_HEADER}>
            Error: no {termForOrg}s found
          </div>
          <div className={Classes.DIALOG_BODY}>
            <p>
              It doesn't look like you're a member of any {displayService}{" "}
              {termForOrg}.{" "}
              <DefaultLink href={instructions}>Here's</DefaultLink> advice from{" "}
              {displayService} on setting up a {termForOrg}.
            </p>
            {isSome(additionalErrorDetails) ? (
              <p>{additionalErrorDetails}</p>
            ) : null}
          </div>
        </div>
      );
    }

    const orgChoices = organizations.map((org: string, i: number) => (
      <option key={i} value={org} selected={org === selectedOrg}>
        {org}
      </option>
    ));

    return (
      <div className={Classes.RUNNING_TEXT}>
        <div className={Classes.DIALOG_HEADER}>
          Select {displayService} {termForOrg}
        </div>
        <div className={Classes.DIALOG_BODY}>
          <p>
            Which {displayService} {termForOrg} does {domainDisplayName} use?
          </p>
          {isSome(additionalDetails) ? <p>{additionalDetails}</p> : null}
          <HTMLSelect
            onChange={e => {
              setSelectedOrg(e.target.value);
            }}
          >
            <option value="">Select a {termForOrg}</option>
            {orgChoices}
          </HTMLSelect>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              loading={mutationInFlight}
              intent={Intent.PRIMARY}
              onClick={() => {
                if (isSome(selectedOrg)) {
                  setInFlight(true);
                  setOrganization({
                    variables: {
                      metadata: JSON.stringify({ organization: selectedOrg }),
                      service,
                    },
                  })
                    .then(() => {
                      AppToaster.show({
                        icon: "tick",
                        intent: Intent.SUCCESS,
                        message: `Set the ${termForOrg} to ${selectedOrg}`,
                        timeout: 2500,
                      });
                      if (isSome(onClose)) {
                        onClose();
                      }
                    })
                    .catch(e => LogError(e));
                }
              }}
              disabled={selectedOrg === ""}
            >
              Link {displayService} account
            </Button>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

gql`
  mutation setOrganizationForCredentials(
    $metadata: String!
    $service: String!
  ) {
    setCredentialMetadata(metadata: $metadata, service: $service, merge: true) {
      id
    }
  }
`;
