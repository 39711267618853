import { ErrorWithMetadata } from "../base/types/errors";
import { SecurityTrainingCategoryId } from "../base/types/gen";
import type { SecurityRequirements } from "../base/types/gen";
import type { Maybe } from "../base/types/maybe";
import { VantaTaskId } from "../constants/vantaTasks";
import type { TrainingCategoryAccess } from "../schemas/betaFeatureAccess";

export function getSecurityRequirementsFomVantaTaskIds(
  requiredVantaTaskIds: string[]
) {
  const securityRequirements: SecurityRequirements = {
    mustAcceptPolicies: false,
    mustBeBackgroundChecked: false,
    mustCompleteHipaaSecurityTraining: false,
    mustCompletePciSecurityTraining: false,
    mustCompleteSecurityTraining: false,
    mustInstallLaptopMonitoring: false,
  };
  requiredVantaTaskIds.forEach(vantaTaskId => {
    switch (vantaTaskId) {
      case VantaTaskId.AGENT_INSTALLED_TASK_ID: {
        securityRequirements.mustInstallLaptopMonitoring = true;
        break;
      }
      case VantaTaskId.BACKGROUND_CHECK_TASK_ID: {
        securityRequirements.mustBeBackgroundChecked = true;
        break;
      }
      case VantaTaskId.POLICY_ACCEPTANCE_TASK_ID: {
        securityRequirements.mustAcceptPolicies = true;
        break;
      }
      case VantaTaskId.SECURITY_TRAINING_TASK_ID: {
        securityRequirements.mustCompleteSecurityTraining = true;
        break;
      }
      case VantaTaskId.HIPAA_SECURITY_TRAINING_TASK_ID: {
        securityRequirements.mustCompleteHipaaSecurityTraining = true;
        break;
      }
      case VantaTaskId.GDPR_SECURITY_TRAINING_TASK_ID: {
        securityRequirements.mustCompleteGdprSecurityTraining = true;
        break;
      }
      case VantaTaskId.PCI_SECURITY_TRAINING_TASK_ID: {
        securityRequirements.mustCompletePciSecurityTraining = true;
        break;
      }
      default: {
        throw new ErrorWithMetadata("Invalid vanta task id found.", {
          vantaTaskId,
        });
      }
    }
  });
  return securityRequirements;
}

export function getVantaTaskIdsFromSecurityRequirements(
  securityRequirements: SecurityRequirements
) {
  const taskIds: string[] = [];
  if (securityRequirements.mustAcceptPolicies) {
    taskIds.push(VantaTaskId.POLICY_ACCEPTANCE_TASK_ID);
  }
  if (securityRequirements.mustBeBackgroundChecked) {
    taskIds.push(VantaTaskId.BACKGROUND_CHECK_TASK_ID);
  }
  if (securityRequirements.mustInstallLaptopMonitoring) {
    taskIds.push(VantaTaskId.AGENT_INSTALLED_TASK_ID);
  }
  if (securityRequirements.mustCompleteSecurityTraining) {
    taskIds.push(VantaTaskId.SECURITY_TRAINING_TASK_ID);
  }
  if (securityRequirements.mustCompleteHipaaSecurityTraining) {
    taskIds.push(VantaTaskId.HIPAA_SECURITY_TRAINING_TASK_ID);
  }
  if (securityRequirements.mustCompletePciSecurityTraining) {
    taskIds.push(VantaTaskId.PCI_SECURITY_TRAINING_TASK_ID);
  }
  return taskIds;
}

export function getContractorRoleName(userId: string) {
  return `${userId} Contractor Preferences`;
}

type RequirementsMap = Omit<SecurityRequirements, "__typename">;

const ALL_VANTA_TASKS: RequirementsMap = {
  mustAcceptPolicies: true,
  mustBeBackgroundChecked: true,
  mustCompleteGdprSecurityTraining: true,
  mustCompleteHipaaSecurityTraining: true,
  mustCompletePciSecurityTraining: true,
  mustCompleteSecurityTraining: true,
  mustInstallLaptopMonitoring: true,
} as const;

export function getAllVantaTasks(access?: Maybe<TrainingCategoryAccess>) {
  const tasks = { ...ALL_VANTA_TASKS };
  if (!access?.hipaa) {
    delete tasks.mustCompleteHipaaSecurityTraining;
  }
  if (!access?.pci) {
    delete tasks.mustCompletePciSecurityTraining;
  }
  if (!access?.gdpr) {
    delete tasks.mustCompleteGdprSecurityTraining;
  }
  return tasks;
}

export function mustCompleteSat(
  requirementsMap: SecurityRequirements,
  categoryId: SecurityTrainingCategoryId
): boolean {
  return categoryId === SecurityTrainingCategoryId.general
    ? !!requirementsMap.mustCompleteSecurityTraining
    : categoryId === SecurityTrainingCategoryId.hipaa
    ? !!requirementsMap.mustCompleteHipaaSecurityTraining
    : categoryId === SecurityTrainingCategoryId.pci
    ? !!requirementsMap.mustCompletePciSecurityTraining
    : categoryId === SecurityTrainingCategoryId.gdpr
    ? !!requirementsMap.mustCompleteGdprSecurityTraining
    : false;
}

const SECURITY_REQUIREMENTS = Object.keys(ALL_VANTA_TASKS) as Array<
  keyof RequirementsMap
>;

export function getSecurityRequirements(access: TrainingCategoryAccess) {
  return SECURITY_REQUIREMENTS.filter(req => {
    if (!access.hipaa && req === "mustCompleteHipaaSecurityTraining") {
      return false;
    }
    if (!access.pci && req === "mustCompletePciSecurityTraining") {
      return false;
    }
    if (!access.gdpr && req === "mustCompleteGdprSecurityTraining") {
      return false;
    }
    return true;
  });
}
