import { OL } from "@blueprintjs/core";
import React from "react";

import { DefaultLink } from "../../../../helpers/links";

export const NewPolicy: React.FC = () => {
  const policyComponent = (
    <pre>
      {`{
"Version": "2012-10-17",
"Statement": [
    {
        "Effect": "Allow",
        "Action": [
            "ecr:DescribeImageScanFindings",
            "ecr:DescribeImages",
            "dynamodb:ListTagsOfResource",
            "ecr:ListTagsForResource",
            "sqs:ListQueueTags"
        ],
        "Resource": "*"
    },
    {
        "Effect": "Deny",
        "Action": [
            "datapipeline:EvaluateExpression",
            "datapipeline:QueryObjects",
            "rds:DownloadDBLogFilePortion"
        ],
        "Resource": "*"
    }
]
}`}
    </pre>
  );
  return (
    <div>
      <OL>
        <li>
          Navigate to the{" "}
          <DefaultLink
            href="https://console.aws.amazon.com/iam/home?#/policies$new?step=edit"
            target="_blank"
            rel="noopener noreferrer"
          >
            AWS policy creator
          </DefaultLink>{" "}
          and click on the <strong> JSON </strong> tab
        </li>
        <li>
          Paste the policy below into the editor
          {policyComponent}
        </li>
        <li>
          Click <strong> Review policy </strong> and name the policy{" "}
          <code>VantaAdditionalPermissions</code>
        </li>
        <li>
          Click <strong> Create policy </strong>
        </li>
      </OL>
    </div>
  );
};
