import type { Maybe } from "common/base/types/maybe";
import React from "react";

import { getVulnCountTags, NotBeenScannedTag } from "./components";
import { VulnerabilityPageEntry } from "./vulnerability-page-entry";

interface IProps {
  primaryId: string;
  secondaryId?: Maybe<string>;
  slaDeadline?: Maybe<Date>;
  hasBeenScanned?: Maybe<boolean>;
  vulnerabilityCounts: {
    low: number;
    medium: number;
    high: number;
  };
  handleClick: Function;
}

export const ContainerRepositoryEntry: React.FC<IProps> = ({
  primaryId,
  secondaryId,
  slaDeadline,
  vulnerabilityCounts,
  hasBeenScanned,
  handleClick,
}) => {
  const { low, medium, high } = vulnerabilityCounts;
  const tags =
    (hasBeenScanned ?? true) || low + medium + high > 0
      ? getVulnCountTags(vulnerabilityCounts)
      : [<NotBeenScannedTag key="notBeenScanned" />];
  return (
    <VulnerabilityPageEntry
      primaryInfo={primaryId}
      secondaryInfo={secondaryId}
      slaDeadline={slaDeadline}
      handleClick={handleClick}
      tags={tags}
    />
  );
};
