import type { Maybe } from "common/base/types/maybe";
import React from "react";
import styled, { css } from "styled-components";

import { VANTA_COLORS } from "../../../alpaca/base/colors";
import { Dimensions } from "../constants";
import { SideNavMenu } from "../side-nav-menu";
import { useCheckWideRoute } from "../vanta-chrome";
import type { IHeadingInfo } from "./info";
import type { IButtonProps } from "./page-heading";
import { PageHeading } from "./page-heading";
export { PageHeadingInfo } from "./info";

interface IProps {
  headingInfo: IHeadingInfo;
  buttonProps?: Maybe<IButtonProps>;
  children?: Maybe<React.ReactNode>;
}

export const VantaDashboardPage: React.FC<IProps> = ({
  headingInfo,
  buttonProps,
  children,
}) => {
  const wide = useCheckWideRoute();

  return (
    <VantaDashboardPageContainer wide={wide}>
      <SideNavMenu />
      <PageContainer>
        <PageHeading headingInfo={headingInfo} buttonProps={buttonProps} />
        <ContentContainer>{children}</ContentContainer>
      </PageContainer>
    </VantaDashboardPageContainer>
  );
};

const VantaDashboardPageContainer = styled.div<{
  wide?: Maybe<boolean>;
  widthOverride?: Maybe<number>;
}>`
  display: flex;
  width: ${({ widthOverride }) => widthOverride ?? Dimensions.PAGE_WIDTH}px;
  min-height: calc(
    100vh - ${2 * Dimensions.PAGE_TOP_MARGIN + Dimensions.TOP_BAR_HEIGHT}px
  );
  background-color: ${VANTA_COLORS.BACKGROUND_WHITE}00;
  margin: ${Dimensions.PAGE_TOP_MARGIN}px auto;
  ${({ wide }) => {
    if (wide) {
      return css`
        min-width: calc(100vw - 2 * ${Dimensions.SIDE_MARGINS}px);
        margin-left: ${Dimensions.SIDE_MARGINS}px;
        margin-right: ${Dimensions.SIDE_MARGINS}px;
      `;
    } else {
      return "";
    }
  }}

  @media (max-width: ${({ widthOverride }) =>
    Dimensions.SIDE_MARGINS * 2 + (widthOverride ?? Dimensions.PAGE_WIDTH)}px) {
    margin-left: ${Dimensions.SIDE_MARGINS}px;
    margin-right: ${Dimensions.SIDE_MARGINS}px;
  }
`;

/**
 * This HOC is for newer dashboard pages which do not make use of the VantaDashboardPage component,
 * but still need to fit within the same container, such as the new personnel pages and the new
 * risk register pages.
 */
export function wrapInDashboardContainer<T extends object>(
  Component: React.ComponentType<T>,
  widthOverride?: Maybe<number>
) {
  return class extends React.Component<T> {
    public render() {
      return (
        <VantaDashboardPageContainer widthOverride={widthOverride}>
          <Component {...this.props} />
        </VantaDashboardPageContainer>
      );
    }
  };
}

const PageContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  min-height: calc(
    100vh - ${2 * Dimensions.PAGE_TOP_MARGIN + Dimensions.TOP_BAR_HEIGHT}px
  );
  & > *:last-child {
    flex-grow: 1;
  }
`;

const ContentContainer = styled.div`
  background-color: ${VANTA_COLORS.BACKGROUND_WHITE}ff;
  padding: ${Dimensions.PAGE_PADDING}px;
  border-radius: 4px;
  flex-grow: 1;
`;
