import { ReportStandard } from "../../base/types/gen";
import type { IStandard } from "../complianceTypes";

export const GDPR_STANDARD: IStandard = {
  standard: ReportStandard.gdpr,
  version: "2021",
  updated: "09/17/2021",
  canonical: "GDPR",
  // manually taken from https://gdpr-info.eu/
  principles: [
    {
      section: "Chapter 2",
      name: "Principles",
      optional: false,
      requirements: [
        {
          name: "Lawfulness of processing",
          section: "Article 6",
        },
        {
          name: "Conditions for consent",
          section: "Article 7",
        },
      ],
    },
    {
      section: "Chapter 3",
      name: "Rights of the data subject",
      optional: false,
      requirements: [
        {
          name: "Transparent information, communication and modalities for the exercise of the rights of the data subject",
          section: "Article 12",
        },
        {
          name: "Information to be provided where personal data are collected from the data subject",
          section: "Article 13",
        },
        {
          name: "Information to be provided where personal data have not been obtained from the data subject",
          section: "Article 14",
        },
        {
          name: "Right of access by the data subject",
          section: "Article 15",
        },
        {
          name: "Right to rectification",
          section: "Article 16",
        },
        {
          name: "Right to erasure (‘right to be forgotten’)",
          section: "Article 17",
        },
        {
          name: "Right to restriction of processing",
          section: "Article 18",
        },
        {
          name: "Notification obligation regarding rectification or erasure of personal data or restriction of processing",
          section: "Article 19",
        },
        {
          name: "Right to data portability",
          section: "Article 20",
        },
        {
          name: "Right to object",
          section: "Article 21",
        },
        {
          name: "Automated individual decision-making, including profiling",
          section: "Article 22",
        },
        {
          name: "Restrictions",
          section: "Article 23",
        },
      ],
    },
    {
      section: "Chapter 4",
      name: "Controller and Processor",
      optional: false,
      requirements: [
        {
          name: "Responsibility of the controller",
          section: "Article 24",
        },
        {
          name: "Data protection by design and by default",
          section: "Article 25",
        },
        {
          name: "Representatives of controllers or processors not established in the Union",
          section: "Article 27",
        },
        {
          name: "Processor",
          section: "Article 28",
        },
        {
          name: "Records of processing activities",
          section: "Article 30",
        },
        {
          name: "Security of processing",
          section: "Article 32",
        },
        {
          name: "Notification of a personal data breach to the supervisory authority",
          section: "Article 33",
        },
        {
          name: "Communication of a personal data breach to the data subject",
          section: "Article 34",
        },
        {
          name: "Data protection impact assessmen",
          section: "Article 35",
        },
        {
          name: "Designation of the data protection officer",
          section: "Article 37",
        },
        {
          name: "Position of the data protection officer",
          section: "Article 38",
        },
        {
          name: "Tasks of the data protection officer",
          section: "Article 39",
        },
      ],
    },
    {
      section: "Chapter 5",
      name: "Transfers of personal data to third countries or international organizations",
      optional: false,
      requirements: [
        {
          name: "General principle for transfers",
          section: "Article 44",
        },
        {
          name: "Transfers on the basis of an adequacy decision",
          section: "Article 45",
        },
        {
          name: "Transfers subject to appropriate safeguard",
          section: "Article 46",
        },
        {
          name: "Transfers or disclosures not authorised by Union law",
          section: "Article 48",
        },
      ],
    },
    {
      section: "Chapter 6",
      name: "Independent supervisory authorities",
      optional: false,
      requirements: [
        {
          name: "Supervisory authority",
          section: "Article 51",
        },
      ],
    },
  ],
};
