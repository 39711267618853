import { Dialog, PopoverInteractionKind } from "@blueprintjs/core";
import React, { useState } from "react";
import styled from "styled-components";
import { Tooltip } from "../../alpaca/components";

import { CredentialComponent } from "../pages/credential/credential";
import type { ServiceDetails } from "./service-groups";

interface IServiceBadgeProps {
  service: ServiceDetails;
  large: boolean;
  clickToConnect: boolean;
}

/**
 * ServiceBadge displays the logo for a service in a box. It wraps a
 * ServiceBadgeBox in a Tooltip as appropriate.
 *
 * For the visual elements, @see ServiceBadgeBox.
 *
 * @param service - service metadata.
 * @param large - controls the size of the badge and its drop shadow
 * @param clickToConnect - if true, the drop shadow darkens on hover and
 *    clicking on the badge opens a modal with the service linking flow.
 */
export const ServiceBadge: React.FC<IServiceBadgeProps> = ({
  service,
  large,
  clickToConnect,
}) => {
  const inner = (
    <ServiceBadgeBox
      service={service}
      large={large}
      clickToConnect={clickToConnect}
    />
  );
  return clickToConnect ? (
    <Tooltip
      interactionKind={PopoverInteractionKind.HOVER}
      content={`Connect ${service.abbreviation ?? service.displayName}`}
    >
      {inner}
    </Tooltip>
  ) : (
    inner
  );
};

const BadgeContainerStyles = {
  DEFAULT_EDGE_LENGTH: 54,
  LARGE_EDGE_LENGTH: 80,
  MARGINS: 16,
  BORDER_RADIUS: 8,
  DEFAULT_SHADOW: "0px 4px 16px rgba(0, 0, 0, 0.12)",
  DEFAULT_SHADOW_HOVER: "0px 4px 16px rgba(0, 0, 0, 0.18)",
  LARGE_SHADOW: "0px 8px 24px rgba(0, 0, 0, 0.06)",
  LARGE_SHADOW_HOVER: "0px 8px 24px rgba(0, 0, 0, 0.12)",
};

const BadgeContainer = styled.div<{ large: boolean; clickToConnect: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  box-sizing: border-box;
  height: ${({ large }) =>
    large
      ? BadgeContainerStyles.LARGE_EDGE_LENGTH
      : BadgeContainerStyles.DEFAULT_EDGE_LENGTH}px;
  min-height: ${({ large }) =>
    large
      ? BadgeContainerStyles.LARGE_EDGE_LENGTH
      : BadgeContainerStyles.DEFAULT_EDGE_LENGTH}px;
  width: ${({ large }) =>
    large
      ? BadgeContainerStyles.LARGE_EDGE_LENGTH
      : BadgeContainerStyles.DEFAULT_EDGE_LENGTH}px;
  min-width: ${({ large }) =>
    large
      ? BadgeContainerStyles.LARGE_EDGE_LENGTH
      : BadgeContainerStyles.DEFAULT_EDGE_LENGTH}px;
  margin: ${BadgeContainerStyles.MARGINS}px;
  border-radius: ${BadgeContainerStyles.BORDER_RADIUS}px;
  box-shadow: ${({ large }) =>
    large
      ? BadgeContainerStyles.LARGE_SHADOW
      : BadgeContainerStyles.DEFAULT_SHADOW};
  ${({ large, clickToConnect }) => {
    // If the badge is clickable, change the box-shadow on hover.
    if (clickToConnect) {
      return `:hover {
        box-shadow: ${
          large
            ? BadgeContainerStyles.LARGE_SHADOW_HOVER
            : BadgeContainerStyles.DEFAULT_SHADOW_HOVER
        };
      }`;
    }
    return undefined;
  }}}
`;

const BadgeLogoStyles = {
  DEFAULT_EDGE_LENGTH: 32,
  LARGE_EDGE_LENGTH: 50,
};

const BadgeLogo = styled.img<{ large: boolean }>`
  max-height: ${({ large }) =>
    large
      ? BadgeLogoStyles.LARGE_EDGE_LENGTH
      : BadgeLogoStyles.DEFAULT_EDGE_LENGTH}px;
  max-width: ${({ large }) =>
    large
      ? BadgeLogoStyles.LARGE_EDGE_LENGTH
      : BadgeLogoStyles.DEFAULT_EDGE_LENGTH}px;
`;

/**
 * ServiceBadgeBox is the visual component of a ServiceBadge: the image and
 * enclosing container.
 */
const ServiceBadgeBox = ({
  service,
  large,
  clickToConnect,
}: IServiceBadgeProps) => {
  const [linkingDialogOpen, setLinkingDialogOpen] = useState(false);

  let linkingDialog;
  if (clickToConnect) {
    linkingDialog = (
      <Dialog
        isOpen={linkingDialogOpen}
        onClose={() => setLinkingDialogOpen(false)}
      >
        <CredentialComponent
          service={service.id}
          onCredentialsLinked={() => {
            setLinkingDialogOpen(false);
          }}
        />
      </Dialog>
    );
  }

  return (
    <BadgeContainer
      large={large}
      onClick={clickToConnect ? () => setLinkingDialogOpen(true) : undefined}
      clickToConnect={clickToConnect}
    >
      <BadgeLogo src={service.logo} large={large} />
      {linkingDialog}
    </BadgeContainer>
  );
};
