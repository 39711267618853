import type { PolicyType } from "common/constants/policyInfoCommon";
import { PolicyTypes } from "common/constants/policyInfoCommon";
import gql from "graphql-tag";
import React from "react";

import {
  ManagePoliciesV2Document,
  useDeletePolicyTypeMutation,
} from "../../../gen/components";
import { CancelConfirmDialog } from "../../helpers/CancelConfirmDialog";
import type { Policy } from "./common";

interface IProps {
  domainId: string;
  onClose: () => void;
  policy: Policy;
}

export const DeletePolicyTypeDialog: React.FC<IProps> = props => {
  const [deletePolicyType] = useDeletePolicyTypeMutation({
    refetchQueries: [{ query: ManagePoliciesV2Document }],
  });

  const isVantaType = PolicyTypes.includes(
    props.policy.policyType as PolicyType
  );

  const dialogBody = (
    <div>
      Are you sure you want to delete the following policy?
      <ul>
        <li>{props.policy.title}</li>
      </ul>
      This will remove all versions of this policy from Vanta
      {isVantaType
        ? "."
        : ` and remove ${props.policy.title} from the list of policies.`}
    </div>
  );

  return (
    <CancelConfirmDialog
      isOpen={true}
      body={dialogBody}
      confirmText={"Delete"}
      title="Delete policy"
      onClose={() => props.onClose()}
      onConfirm={async () => {
        await deletePolicyType({
          variables: {
            domainId: props.domainId,
            policyType: props.policy.policyType,
          },
        });
        props.onClose();
      }}
    ></CancelConfirmDialog>
  );
};

gql`
  mutation deletePolicyType($domainId: ID!, $policyType: String!) {
    deletePoliciesByType(domainId: $domainId, policyType: $policyType)
  }
`;
