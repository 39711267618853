// Values are in pixels
export const Dimensions = {
  ACCOUNT_MENU_INSETS: 18,
  ACCOUNT_MENU_ITEM_HEIGHT: 45,
  ACCOUNT_MENU_SIDE_PADDING: 40,
  ACCOUNT_MENU_SPACER_MARGIN: 8,
  ACCOUNT_MENU_TOP_MARGIN: 8,
  ACCOUNT_MENU_TOP_PADDING: 24,
  ACCOUNT_MENU_WIDTH: 266,
  BOTTOM_MARGIN: 60,
  HIGHLIGHT_BORDER_WIDTH: 2,
  ICON_DIMENSION: 34,
  MAX_CONTENT_WIDTH: 1620,
  MINIMUM_GUTTER: 30,
  NAVBAR_TOP_PADDING: 40,
  NAVBAR_MENU_MARGIN: 16,
  NAVBAR_MENU_HEADING_TOP_PADDING: 9,
  NAVBAR_SUBMENU_INDENT: 32,
  NAVBAR_SUBMENU_LINE_HEIGHT: 24,
  NAVBAR_WIDTH: 176,
  PAGE_HEADING_BOTTOM_MARGIN: 20,
  PAGE_HEADING_HEIGHT: 76,
  PAGE_PADDING: 40,
  PAGE_TOP_MARGIN: 40,
  PAGE_WIDTH: 1150,
  SIDE_MARGINS: 40,
  SIDE_MENU_LINE_HEIGHT: 28,
  SIDE_MENU_WIDTH: 240,
  SIDE_MENU_RIGHT_MARGIN: 48,
  SUBMENU_MIN_WIDTH: 150,
  TOP_BAR_HEIGHT: 62,
  TOP_BAR_LOGO_HEIGHT: 20,
  TOP_NAV_WIDTH: 700,
} as const;

export const DASHBOARD_PAGE_HEIGHT = `calc(100vh - ${Dimensions.TOP_BAR_HEIGHT}px)`;
