import { nothing } from "../base/types/maybe";

export enum VulnSeverity {
  LOW,
  MEDIUM,
  HIGH,
  CRITICAL,
}

const LOW_THRESHOLD = 3.9;
const MEDIUM_THRESHOLD = 6.9;
const HIGH_THRESHOLD = 10;

export const severityLevelForNumber = (num: number) => {
  if (num <= LOW_THRESHOLD) {
    return VulnSeverity.LOW;
  }
  if (num <= MEDIUM_THRESHOLD) {
    return VulnSeverity.MEDIUM;
  }
  if (num <= HIGH_THRESHOLD) {
    return VulnSeverity.HIGH;
  }
  return VulnSeverity.CRITICAL;
};

const textForSeverity = (severity: VulnSeverity) => {
  switch (severity) {
    case VulnSeverity.LOW:
      return "Low";
    case VulnSeverity.MEDIUM:
      return "Medium";
    case VulnSeverity.HIGH:
      return "High";
    case VulnSeverity.CRITICAL:
      return "Critical";
    default:
      return "Unknown";
  }
};

export const textForSeverityNumber = (severity: number) =>
  textForSeverity(severityLevelForNumber(severity));

export const countVulnsBySeverity = (vulnSeverities: number[]) => {
  const result = {
    low: 0,
    medium: 0,
    high: 0,
    critical: 0,
    maxSeverity:
      vulnSeverities.length === 0 ? nothing : Math.max(...vulnSeverities),
  };

  vulnSeverities.forEach(severity => {
    switch (severityLevelForNumber(severity)) {
      case VulnSeverity.LOW:
        result.low++;
        break;
      case VulnSeverity.MEDIUM:
        result.medium++;
        break;
      case VulnSeverity.HIGH:
        result.high++;
        break;
      case VulnSeverity.CRITICAL:
        result.critical++;
        break;
      default:
        break;
    }
  });

  return result;
};
