import type { Maybe } from "common/base/types/maybe";
import React from "react";
import styled from "styled-components";

import { MaybeProfileImage } from "../../helpers/MaybeProfileImage";
import { NameEmailDisplay } from "../../helpers/NameEmailDisplay";
interface IProps {
  imageUrl: Maybe<string>;
  primaryText: Maybe<string>;
  secondaryText: string;
  isExEmployee?: Maybe<boolean>;
}

const StyledContainerDiv = styled.div`
  display: flex;
  align-items: center;
`;

const StyledTextContainer = styled.div`
  margin-left: 8px;
`;

export const UserProfile: React.FC<IProps> = ({
  imageUrl,
  primaryText,
  secondaryText,
  isExEmployee,
}) => (
  <StyledContainerDiv>
    <MaybeProfileImage
      url={imageUrl}
      circle={true}
      inactiveUser={isExEmployee ?? false}
      dimension={36}
    />
    <StyledTextContainer>
      <NameEmailDisplay displayName={primaryText} email={secondaryText} />
    </StyledTextContainer>
  </StyledContainerDiv>
);
