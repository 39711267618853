import { Feature, GenericResource, ReportStandard } from "../../base/types/gen";
import type { Maybe } from "../../base/types/maybe";

export interface IRiskSchema {
  id: string;
  shortHeading: string;
  icon: string;
  itemSelectionPrompt: string;
  likelihoodPrompt: string;
  introductoryText: string;
  placeholderText?: Maybe<string>;
  resourceKind?: Maybe<GenericResource | "DATABASE">;
}

type RiskSchemaIdentifierType = [
  "AUTHENTICATION_MECHANISMS",
  "CODEBASE_SECURITY",
  "CODEBASE_SENSITIVE_DATA",
  "CUSTOM",
  "CUSTOMER_DATA_TOOLS",
  "ENTRANCES",
  "EPHI_AVAILABILITY",
  "EPHI_CONFIDENTIALITY",
  "EPHI_INTEGRITY",
  "FINANCIAL_TOOLS",
  "PHYSICAL_HARDWARE",
  "PHYSICAL_ITEMS_AND_INFORMATION",
  "PRODUCTION_AUTOSCALE",
  "PRODUCTION_CONTAINERS",
  "PRODUCTION_INSTANCES",
  "SENSITIVE_DATA_DATABASES",
  "SENSITIVE_DATA_NON_PROD",
  "SENSITIVE_DATA_STORAGE_BUCKETS"
];

const RISK_SCHEMAS: {
  [identifier in RiskSchemaIdentifierType[number]]: IRiskSchema;
} = {
  AUTHENTICATION_MECHANISMS: {
    id: "risk-auth-mechanism",
    shortHeading: "Authentication mechanisms",
    icon: "authentication",
    introductoryText:
      "In this section, you'll identify various ways that users prove their identity to your service",
    itemSelectionPrompt: "List out authentication mechanisms in your codebase",
    likelihoodPrompt:
      "How likely could this mechanism be implemented incorrectly or broken by a future change",
    placeholderText: `Passwords, API keys, …`,
  },
  CODEBASE_SECURITY: {
    id: "risk-codebase-security",
    shortHeading: "Codebase security",
    introductoryText:
      "For this section, you'll inventory sensitive code that, if compromised, could lead to a security incident",
    icon: "encryption",
    itemSelectionPrompt:
      "List out notable parts of your codebase with security implications",
    likelihoodPrompt:
      "How likely could this mechanism be implemented incorrectly or broken by a future change",
    placeholderText: `Sign-in service, payment service, …`,
  },
  CODEBASE_SENSITIVE_DATA: {
    id: "risk-codebase-sensitive-data",
    shortHeading: "Sensitive data in codebase",
    introductoryText:
      "Information like OAuth secrets, keys, or passwords sometimes make their way into your codebase. You'll inventory anything you're storing in code and assess its risk.",
    icon: "passwordLock",
    itemSelectionPrompt: "List out any sensitive data in your codebase",
    likelihoodPrompt: "How likely is a leak of this data",
    placeholderText: `OAuth secrets, passwords, …`,
  },
  CUSTOM: {
    id: "risk-custom",
    shortHeading: "Custom risks",
    introductoryText:
      "Let's record risks that are relevant for your business but have not been covered in the previous sections of this risk assessment",
    icon: "idea",
    itemSelectionPrompt:
      "List vulnerable parts of your system not covered in previous sections",
    likelihoodPrompt: "How likely is misuse of this part of your system",
    placeholderText: "",
  },
  CUSTOMER_DATA_TOOLS: {
    id: "risk-customer-data-internal-tools",
    shortHeading: "Customer data access",
    introductoryText:
      "In this section, we'll look at internal tools used by employees on teams like support, engineering, product management, or customer success",
    icon: "spreadsheet",
    itemSelectionPrompt: `List out any internal tools that provide access to customer data`,
    likelihoodPrompt: "How likely is misuse of this tool",
    placeholderText: `Support tool, superuser tool, databases, …`,
  },
  ENTRANCES: {
    id: "risk-office-entrances",
    shortHeading: "Entrances",
    introductoryText:
      "In this section, you'll be asked to list the entrances to your office and identify potential risks",
    icon: "entrance",
    itemSelectionPrompt: `List out every entrance to your office space`,
    likelihoodPrompt: `How likely do you feel someone is to break into this entrance`,
    placeholderText: "Side doors, lobbies, windows, fire escapes, …",
  },
  EPHI_AVAILABILITY: {
    id: "risk-ephi-availability",
    shortHeading: "ePHI Availability",
    introductoryText:
      "Let's identify vulnerabilities in your system that could prevent ePHI data from being accessible and usable upon demand by an authorized person",
    icon: "twentyFourHours",
    itemSelectionPrompt:
      "List mechanisms in your system by which ePHI is accessed by authorized persons or services",
    likelihoodPrompt:
      "How likely is this means of access to be unavailable or inaccessible to authorized persons",
    placeholderText: "Workstations, VPNs, web portals, …",
  },
  EPHI_CONFIDENTIALITY: {
    id: "risk-ephi-confidentiality",
    shortHeading: "ePHI Confidentiality",
    introductoryText:
      "Let's assess your system to find places where ePHI data could be available to unauthorized persons or processes",
    icon: "confidential",
    itemSelectionPrompt:
      "List mechanisms in your system that ensure confidentiality of ePHI",
    likelihoodPrompt: "How likely is this mechanism to fail or be abused",
    placeholderText: "Encryption, role-based access control, monitoring, …",
  },
  EPHI_INTEGRITY: {
    id: "risk-ephi-integrity",
    shortHeading: "ePHI Integrity",
    introductoryText:
      "Let's identify points in your system where ePHI data could be compromised, altered, or destroyed in an unauthorized manner",
    icon: "successDatabase",
    itemSelectionPrompt: "List points in your system where ePHI is stored",
    likelihoodPrompt:
      "How likely is ePHI stored at this point in the system to be improperly altered or destroyed",
    placeholderText: "Queues, Databases, …",
  },
  FINANCIAL_TOOLS: {
    id: "risk-financial-tools",
    shortHeading: "Financial tools",
    introductoryText:
      "Risk assessments cover financial as well as security risks. This step will inventory ways to access financial tools at your organization.",
    icon: "bankAccount",
    itemSelectionPrompt:
      "List out any tools or vendors that provide access to financial data",
    likelihoodPrompt: "How likely is misuse of this tool",
    placeholderText: `Payment processing, invoicing, …`,
  },
  PHYSICAL_HARDWARE: {
    id: "risk-physical-hardware",
    shortHeading: "Hardware",
    icon: "router",
    introductoryText:
      "To understand your physical security, let's inventory the types of devices in your office that an attacker could access",
    itemSelectionPrompt: `List notable types of physical hardware devices in your physical office`,
    likelihoodPrompt: `How likely do you feel someone is to steal this device`,
    placeholderText: "Network switches, external hard drives, …",
  },
  PHYSICAL_ITEMS_AND_INFORMATION: {
    id: "risk-physical-items-and-information",
    shortHeading: "Sensitive items/information",
    introductoryText:
      "Not all sensitive information lives on an electronic device. Let's explore any sensitive data that you may have in file cabinets, desks, or other parts of your office space.",
    icon: "information",
    itemSelectionPrompt: `List notable sensitive items or information in your physical office space`,
    likelihoodPrompt: `How likely do you feel someone could inappropriately access this item`,
    placeholderText: `Financial documents, legal documents, …`,
  },
  PRODUCTION_AUTOSCALE: {
    id: "risk-production-autoscale-groups",
    shortHeading: "Autoscaling groups",
    introductoryText:
      "Let's assess the groups of servers that run in your product environment",
    icon: "serverRack",
    itemSelectionPrompt:
      "Select the autoscale groups most critical to your production environment",
    likelihoodPrompt: "What is the risk of this group being compromised",
    resourceKind: GenericResource.ManagedInstanceGroup,
  },
  PRODUCTION_CONTAINERS: {
    id: "risk-production-containers",
    shortHeading: "Containers",
    introductoryText:
      "This step will have you assess any container images that we have found in your linked services",
    icon: "containerShip",
    itemSelectionPrompt:
      "Select the containers most critical to your production environment",
    likelihoodPrompt: "What is the risk of this container being compromised",
    placeholderText: `web-server, auth-service, …`,
    resourceKind: GenericResource.ContainerRepository,
  },
  PRODUCTION_INSTANCES: {
    id: "risk-production-instances",
    shortHeading: "Instances",
    introductoryText:
      "In this step, we will score servers that we have discovered in your linked services",
    icon: "singleServer",
    itemSelectionPrompt:
      "Select the instances most critical to your production environment",
    likelihoodPrompt: "What is the risk of this instance being compromised",
    resourceKind: GenericResource.ComputeInstance,
  },
  SENSITIVE_DATA_DATABASES: {
    id: "risk-senstive-data-databases",
    shortHeading: "Databases",
    introductoryText:
      "Your databases vary in risk depending on their contents and security controls. In this step, we will score databases that we have discovered in your linked services.",
    icon: "database",
    itemSelectionPrompt: "Select your databases storing sensitive data",
    likelihoodPrompt:
      "What is the risk of this database being inappropriately accessed",
    resourceKind: "DATABASE",
  },
  SENSITIVE_DATA_NON_PROD: {
    id: "risk-sensitive-data-non-prod",
    shortHeading: "Non-production environments",
    introductoryText:
      "Staging or development environments may still contain information with associated risk. In this step, we will assess that information.",
    icon: "system",
    itemSelectionPrompt:
      "List the data stored in your non-production environments",
    likelihoodPrompt: "What is the likelihood of a breach or leak of this data",
    placeholderText: `Customer names, email addresses, …`,
  },
  SENSITIVE_DATA_STORAGE_BUCKETS: {
    id: "risk-senstive-data-storage-buckets",
    shortHeading: "Storage buckets",
    itemSelectionPrompt: "Select your storage buckets storing sensitive data",
    introductoryText:
      "In addition to databases, we will examine the unstructured storage systems that we have found in your linked services",
    icon: "storage",
    likelihoodPrompt:
      "What is the risk of this bucket being inappropriately accessed",
    resourceKind: GenericResource.StorageBucket,
  },
} as const;

// Lists the different risk register tests. Used to specify which
// categories are required to be completed for which tests.
export enum RiskRegisterTestGroup {
  Base = "Base",
  HIPAA = "HIPAA",
}

export interface IRiskCategory {
  id: string;
  url: string;
  breadcrumbText: string;
  description: string;
  risks: IRiskSchema[];
  standards: ReportStandard[];
  feature?: Maybe<Feature>;
  testGroups: RiskRegisterTestGroup[];
  showProgressOnCard?: Maybe<boolean>;
}

type RiskCategoryIdentifierType = [
  "PHYSICAL_SECURITY",
  "SECURE_DEVELOPMENT",
  "PEOPLE",
  "PRODUCTION_ENVIRONMENT",
  "SENSITIVE_DATA",
  "HIPAA",
  "CUSTOM"
];

export const RISK_CATEGORIES: {
  [identifier in RiskCategoryIdentifierType[number]]: IRiskCategory;
} = {
  SENSITIVE_DATA: {
    id: "risk-category-sensitive-data",
    url: "sensitive-data",
    breadcrumbText: "Sensitive data",
    description:
      "Document storage systems that may hold important data that requires extra protection",
    risks: [
      RISK_SCHEMAS.SENSITIVE_DATA_STORAGE_BUCKETS,
      RISK_SCHEMAS.SENSITIVE_DATA_DATABASES,
      RISK_SCHEMAS.SENSITIVE_DATA_NON_PROD,
    ],
    standards: [ReportStandard.soc2, ReportStandard.iso27001],
    testGroups: [RiskRegisterTestGroup.Base],
  },
  SECURE_DEVELOPMENT: {
    id: "risk-category-secure-development",
    url: "secure-development",
    breadcrumbText: "Your codebase",
    description:
      "Inventory high-risk areas of your codebase to focus your product security efforts",
    risks: [
      RISK_SCHEMAS.AUTHENTICATION_MECHANISMS,
      RISK_SCHEMAS.CODEBASE_SECURITY,
      RISK_SCHEMAS.CODEBASE_SENSITIVE_DATA,
    ],
    standards: [ReportStandard.soc2, ReportStandard.iso27001],
    testGroups: [RiskRegisterTestGroup.Base],
  },
  PEOPLE: {
    id: "risk-category-people",
    url: "people",
    breadcrumbText: "People",
    description:
      "Explore the risk of insider threats against user data and company resources",
    risks: [RISK_SCHEMAS.CUSTOMER_DATA_TOOLS, RISK_SCHEMAS.FINANCIAL_TOOLS],
    standards: [ReportStandard.soc2, ReportStandard.iso27001],
    testGroups: [RiskRegisterTestGroup.Base],
  },
  PRODUCTION_ENVIRONMENT: {
    id: "risk-category-production-environment",
    url: "production-environment",
    breadcrumbText: "Production services",
    description:
      "Examine your production environment to identify high-risk services",
    risks: [
      RISK_SCHEMAS.PRODUCTION_AUTOSCALE,
      RISK_SCHEMAS.PRODUCTION_INSTANCES,
      RISK_SCHEMAS.PRODUCTION_CONTAINERS,
    ],
    standards: [ReportStandard.soc2, ReportStandard.iso27001],
    testGroups: [RiskRegisterTestGroup.Base],
  },
  PHYSICAL_SECURITY: {
    id: "risk-category-physical-security",
    url: "physical-security",
    breadcrumbText: "Physical security",
    description:
      "Assess your physical environment to protect important data, equipment, assets, and personnel",
    risks: [
      RISK_SCHEMAS.ENTRANCES,
      RISK_SCHEMAS.PHYSICAL_HARDWARE,
      RISK_SCHEMAS.PHYSICAL_ITEMS_AND_INFORMATION,
    ],
    standards: [ReportStandard.soc2, ReportStandard.iso27001],
    testGroups: [RiskRegisterTestGroup.Base],
  },
  HIPAA: {
    id: "risk-category-hipaa",
    url: "hipaa",
    breadcrumbText: "Protected health info",
    description:
      "Document risks to the confidentiality, integrity and availability of ePHI in your system. ",
    risks: [
      RISK_SCHEMAS.EPHI_CONFIDENTIALITY,
      RISK_SCHEMAS.EPHI_INTEGRITY,
      RISK_SCHEMAS.EPHI_AVAILABILITY,
    ],
    standards: [ReportStandard.hipaa],
    feature: Feature.BetaHIPAA,
    testGroups: [RiskRegisterTestGroup.HIPAA],
  },
  CUSTOM: {
    id: "risk-category-custom",
    url: "custom",
    breadcrumbText: "Custom risks",
    description:
      "Optional: Document risks unique to your business that are not covered by other sections.",
    risks: [RISK_SCHEMAS.CUSTOM],
    standards: [
      ReportStandard.hipaa,
      ReportStandard.soc2,
      ReportStandard.iso27001,
    ],
    testGroups: [],
    showProgressOnCard: false,
  },
};

export interface IRiskItem {
  id: string;
  riskId: string;
  itemName: string;
  likelihood?: Maybe<number>;
  impact?: Maybe<number>;
}

export const RISK_ID_TO_HEADING = Object.values(RISK_SCHEMAS).reduce<{
  [k: string]: string;
}>((dictionary, schema) => {
  dictionary[schema.id] = schema.shortHeading;
  return dictionary;
}, {});

export const RISK_ID_TO_SECTION_HEADING = Object.values(
  RISK_CATEGORIES
).reduce<{
  [k: string]: string;
}>((dictionary, category) => {
  category.risks.forEach(
    risk => (dictionary[risk.id] = category.breadcrumbText)
  );
  return dictionary;
}, {});
