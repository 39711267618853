import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import { useContext } from "react";

import { LogErrorMessage } from "../../errors";
import type { Feature } from "../../gen/components";
import type { IFeatureContext } from "./context";
import { FeatureContext } from "./context";

export function useFeatureCheck(feature: Feature) {
  const featureContext = useContext(FeatureContext);
  return domainHasFeature(featureContext, feature);
}
/**
 * Returns all available beta features
 */
export function useAvailableBetaFeatures() {
  const featureContext = useContext(FeatureContext);
  return new Set([...(featureContext?.betaFeatures ?? [])]) as Set<Feature>;
}

/**
 * This function is intended for internal use for feature components.
 * Use the useFeatureCheck hook or the FeatureGate and related components
 */
export const domainHasFeature = (
  featureContext: Maybe<IFeatureContext>,
  feature: Feature
) => {
  if (!isSome(featureContext)) {
    LogErrorMessage(
      "Checking for a feature outside a feature context. This feature will never be available!"
    );
    return false;
  }

  return featureContext.betaFeatures.includes(feature);
};
