import React, { useEffect, useState } from "react";

import {
  AgentViewBy,
  parseAgentViewBy,
  TabContainer,
} from "../common/components";
import { AgentMonitoredServerList } from "./agent-monitored-server-list";
import { AgentPackageVulnerabilitiesList } from "./agent-package-vulnerabilities-list";
import { VantaAgentDeprecationCallout } from "./deprecation-callout";
interface IProps {
  hasAwsCredentials: boolean;
}

export const AgentVulnDisplay: React.FC<IProps> = ({ hasAwsCredentials }) => {
  const [viewBy, setViewBy] = useState(AgentViewBy.SERVER);
  useEffect(() => {
    setViewBy(parseAgentViewBy());
  }, [location.hash]);

  return (
    <>
      <VantaAgentDeprecationCallout hasAwsCredentials={hasAwsCredentials} />
      <TabContainer style={{ marginTop: "16px" }}>
        {viewBy === AgentViewBy.SERVER ? <AgentMonitoredServerList /> : null}
        {viewBy === AgentViewBy.PACKAGE ? (
          <AgentPackageVulnerabilitiesList />
        ) : null}
      </TabContainer>
    </>
  );
};
