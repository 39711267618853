import {
  Button,
  ControlGroup,
  Divider,
  FormGroup,
  Intent,
  NumericInput,
} from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import { DEFAULT_COMPUTER_SETUP_SLA } from "common/constants/vantaTasks";
import gql from "graphql-tag";
import React, { useState } from "react";
import styled from "styled-components";

import { VANTA_COLORS } from "../../../alpaca/base/colors";
import { MetaText, Dialog } from "../../../alpaca/components";
import { LogError, LogErrorMessage } from "../../../errors";
import type { GetSlaInfoQuery } from "../../../gen/components";
import {
  GetSlaInfoDocument,
  useGetSlaInfoQuery,
  useSetMdmSlasMutation,
  useSetOnboardingSlaMutation,
  useUpdateAccessSlasMutation,
  useUpdateSecurityIssueSlasMutation,
  useUpdateVulnerabilitySlasMutation,
} from "../../../gen/components";
import { AppToaster } from "../../../helpers/toaster";
import { HTMLSelect } from "../../form-controls/html-select";
import { FullPageSpinner } from "../../helpers/FullPageSpinner";
import { SLAHistoryComponent } from "./sla-version-history-list";

const PaddedDivider = styled(Divider)`
  margin: 24px 5px 12px;
`;

const StyledDialog = styled(Dialog)`
  button {
    align-self: flex-end;
    max-width: 50%;
    margin-top: 12px;
  }
`;

const StyledSelect = styled(HTMLSelect)`
  width: 350px;
`;

const ButtonWithMargin = styled(Button)`
  margin-right: 12px;
`;

export const ManageProceduresComponent: React.FC = () => {
  const { error, loading, data } = useGetSlaInfoQuery();
  const [historyDialogOpen, setHistoryDialogOpen] = useState(false);
  const [historyDialogData, setHistoryDialogData] = useState<
    Array<{ createdAt: string; creatorName: string }>
  >([]);
  const [historyDialogTitle, setHistoryDialogTitle] = useState("");

  if (error) {
    LogError(error);
    return null;
  }
  if (loading) {
    return <FullPageSpinner />;
  }
  if (!isSome(data)) {
    LogErrorMessage("Bad fetch");
    return null;
  }

  const openDialog = (
    type: "access" | "onboarding" | "security" | "vulnerability" | "mdm"
  ) => {
    switch (type) {
      case "access":
        setHistoryDialogData(data.organization.accessSlasHistory);
        setHistoryDialogTitle("Access SLAs");
        break;
      case "onboarding":
        setHistoryDialogData(data.organization.onboardingSlasHistory);
        setHistoryDialogTitle("Onboarding SLAs");
        break;
      case "security":
        setHistoryDialogData(data.organization.securityIssueSlasHistory);
        setHistoryDialogTitle("Security issue SLAs");
        break;
      case "vulnerability":
        setHistoryDialogData(data.organization.vulnerabilitySlasHistory);
        setHistoryDialogTitle("Vulnerability SLAs");
        break;
      case "mdm":
        setHistoryDialogData(data.organization.mdmSlasHistory);
        setHistoryDialogTitle("MDM SLAs");
        break;
      default:
        return;
    }
    setHistoryDialogOpen(true);
  };

  return (
    <div>
      <StyledDialog
        isOpen={historyDialogOpen}
        onClose={() => setHistoryDialogOpen(false)}
      >
        <SLAHistoryComponent
          title={historyDialogTitle}
          history={historyDialogData}
        />
      </StyledDialog>
      <AccessSlas
        accessSlas={data.organization.accessSlas}
        viewHistory={() => openDialog("access")}
      />
      <PaddedDivider />
      <VulnerabilitySlas
        vulnerabilitySlas={data.organization.vulnerabilitySlas}
        viewHistory={() => openDialog("vulnerability")}
      />
      <PaddedDivider />
      <SecurityIssueSlas
        securityIssueSlas={data.organization.securityIssueSlas}
        viewHistory={() => openDialog("security")}
      />
      <PaddedDivider />
      <OnboardingSlas
        onboardingSla={data.organization.onboardingSla}
        viewHistory={() => openDialog("onboarding")}
      />
      {data.organization.mdmCredentials.length > 0 ? (
        <>
          <PaddedDivider />
          <MdmSlas
            mdmSlas={data.organization.mdmSlas}
            viewHistory={() => openDialog("mdm")}
          />
        </>
      ) : null}
    </div>
  );
};

const SLA_DURATIONS = {
  SIX_HOURS: { label: "6 hours", id: "21600000" },
  TWENTY_FOUR_HOURS: { label: "24 hours", id: "86400000" },
  ONE_DAY: { label: "1 day", id: "86400000" },
  THREE_DAYS: { label: "3 days", id: "259200000" },
  FIVE_DAYS: { label: "5 days", id: "432000000" },
  SEVEN_DAYS: { label: "7 days", id: "604800000" },
  FOURTEEN_DAYS: { label: "14 days", id: "1209600000" },
  TWENTY_DAYS: { label: "20 days", id: "1728000000" },
  THIRTY_DAYS: { label: "30 days", id: "2592000000" },
  FORTY_FIVE_DAYS: { label: "45 days", id: "3888000000" },
  SIXTY_DAYS: { label: "60 days", id: "5184000000" },
  NINETY_DAYS: { label: "90 days", id: "7776000000" },
  ONE_HUNDRED_TWENTY_DAYS: { label: "120 days", id: "10368000000" },
  ONE_HUNDRED_FIFTY_DAYS: { label: "150 days", id: "12960000000" },
  NO_TIMELINE: { label: "No timeline", id: "null" },
};

export const UNSELECTED_VALUE = "internal-unselected";

const stringOrUnselected = (value: Maybe<number>) =>
  isSome(value) ? String(value) : UNSELECTED_VALUE;

const anyUnselected = (vals: string[]) =>
  vals.some(val => val === UNSELECTED_VALUE);

const ACCESS_SLA_CHOICES = [
  SLA_DURATIONS.SIX_HOURS,
  SLA_DURATIONS.TWENTY_FOUR_HOURS,
  SLA_DURATIONS.THREE_DAYS,
  SLA_DURATIONS.FIVE_DAYS,
  SLA_DURATIONS.SEVEN_DAYS,
];

const AccessSlas: React.FC<{
  accessSlas: Maybe<NonNullable<GetSlaInfoQuery["organization"]>["accessSlas"]>;
  viewHistory: () => void;
}> = ({ accessSlas, viewHistory }) => {
  const [setSlas] = useUpdateAccessSlasMutation();
  const [review, setReview] = useState(
    stringOrUnselected(accessSlas?.accessReviewed)
  );

  const [revoke, setRevoke] = useState(
    stringOrUnselected(accessSlas?.accessRevoked)
  );

  return (
    <div>
      <h5>Access SLAs</h5>
      <FormGroup
        label="Re-assess employees with role changes"
        helperText={"Recommended: 3 days"}
      >
        <StyledSelect
          choices={ACCESS_SLA_CHOICES}
          value={review}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
            setReview(e.target.value)
          }
        />
      </FormGroup>
      <FormGroup
        label="Revoke terminated employees' access"
        helperText={"Recommended: 3 days"}
      >
        <StyledSelect
          choices={ACCESS_SLA_CHOICES}
          value={revoke}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
            setRevoke(e.target.value)
          }
        />
      </FormGroup>
      <ButtonWithMargin
        onClick={async () => {
          await setSlas({
            variables: {
              reviewed: parseFloat(review),
              revoked: parseFloat(revoke),
            },
            refetchQueries: [{ query: GetSlaInfoDocument }],
          });
          AppToaster.show({
            icon: "tick",
            intent: Intent.SUCCESS,
            message: "Access SLAs updated",
            timeout: 2500,
          });
        }}
        intent={Intent.PRIMARY}
        disabled={anyUnselected([review, revoke])}
      >
        Save
      </ButtonWithMargin>
      <Button onClick={viewHistory}>View history</Button>
    </div>
  );
};

const VulnerabilitySlas: React.FC<{
  vulnerabilitySlas: Maybe<
    NonNullable<GetSlaInfoQuery["organization"]>["vulnerabilitySlas"]
  >;
  viewHistory: () => void;
}> = ({ vulnerabilitySlas, viewHistory }) => {
  const [setSlas] = useUpdateVulnerabilitySlasMutation();

  const [highSeveritySLA, setHighSeveritySLA] = useState(
    stringOrUnselected(vulnerabilitySlas?.vulnHighSeverity)
  );
  const [mediumSeveritySLA, setMediumSeveritySLA] = useState(
    stringOrUnselected(vulnerabilitySlas?.vulnMediumSeverity)
  );
  const [lowSeveritySLA, setLowSeveritySLA] = useState(
    stringOrUnselected(vulnerabilitySlas?.vulnLowSeverity)
  );
  return (
    <div>
      <h5>Vulnerability SLAs</h5>
      <FormGroup
        helperText={"Recommended: 90 days"}
        label={"Address low-severity vulnerabilities"}
      >
        <StyledSelect
          choices={[
            SLA_DURATIONS.THREE_DAYS,
            SLA_DURATIONS.SEVEN_DAYS,
            SLA_DURATIONS.FOURTEEN_DAYS,
            SLA_DURATIONS.THIRTY_DAYS,
            SLA_DURATIONS.SIXTY_DAYS,
            SLA_DURATIONS.NINETY_DAYS,
            SLA_DURATIONS.ONE_HUNDRED_TWENTY_DAYS,
            SLA_DURATIONS.ONE_HUNDRED_FIFTY_DAYS,
          ]}
          value={lowSeveritySLA}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
            setLowSeveritySLA(e.target.value)
          }
        />
      </FormGroup>
      <FormGroup
        helperText={"Recommended: 60 days"}
        label={"Address medium-severity vulnerabilities"}
      >
        <StyledSelect
          choices={[
            SLA_DURATIONS.THREE_DAYS,
            SLA_DURATIONS.SEVEN_DAYS,
            SLA_DURATIONS.FOURTEEN_DAYS,
            SLA_DURATIONS.THIRTY_DAYS,
            SLA_DURATIONS.FORTY_FIVE_DAYS,
            SLA_DURATIONS.SIXTY_DAYS,
            SLA_DURATIONS.NINETY_DAYS,
            SLA_DURATIONS.ONE_HUNDRED_TWENTY_DAYS,
          ]}
          value={mediumSeveritySLA}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
            setMediumSeveritySLA(e.target.value)
          }
        />
      </FormGroup>
      <FormGroup
        helperText={"Recommended: 30 days"}
        label={"Address high-severity vulnerabilities"}
      >
        <StyledSelect
          choices={[
            SLA_DURATIONS.THREE_DAYS,
            SLA_DURATIONS.SEVEN_DAYS,
            SLA_DURATIONS.FOURTEEN_DAYS,
            SLA_DURATIONS.THIRTY_DAYS,
            SLA_DURATIONS.FORTY_FIVE_DAYS,
            SLA_DURATIONS.SIXTY_DAYS,
            SLA_DURATIONS.NINETY_DAYS,
            SLA_DURATIONS.ONE_HUNDRED_TWENTY_DAYS,
          ]}
          value={highSeveritySLA}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
            setHighSeveritySLA(e.target.value)
          }
        />
      </FormGroup>
      <ButtonWithMargin
        onClick={async () => {
          await setSlas({
            variables: {
              low: parseFloat(lowSeveritySLA),
              medium: parseFloat(mediumSeveritySLA),
              high: parseFloat(highSeveritySLA),
            },
            refetchQueries: [{ query: GetSlaInfoDocument }],
          });
          AppToaster.show({
            icon: "tick",
            intent: Intent.SUCCESS,
            message: "Vulnerability SLAs updated",
            timeout: 2500,
          });
        }}
        disabled={anyUnselected([
          lowSeveritySLA,
          mediumSeveritySLA,
          highSeveritySLA,
        ])}
        intent={Intent.PRIMARY}
      >
        Save
      </ButtonWithMargin>
      <Button onClick={viewHistory}>View history</Button>
      <br />
      <br />
      <p>
        <MetaText color={VANTA_COLORS.TEXT_DESCRIPTION}>
          New SLA settings will only apply to vulnerabilities discovered in the
          future. Deadlines for already-discovered vulnerabilities will not
          change.
        </MetaText>
      </p>
    </div>
  );
};

const SecurityIssueSlas: React.FC<{
  securityIssueSlas: Maybe<
    NonNullable<GetSlaInfoQuery["organization"]>["securityIssueSlas"]
  >;
  viewHistory: () => void;
}> = ({ securityIssueSlas, viewHistory }) => {
  // security issue SLAs are weird because they have "no timeline" as an option
  // only go to "unselected" if there is no SLA object; otherwise map null => no timeline
  const fnForState = isSome(securityIssueSlas) ? String : stringOrUnselected;
  const [setSlas] = useUpdateSecurityIssueSlasMutation();
  const [securityP0, setSecurityP0] = useState(
    fnForState(securityIssueSlas?.securityBugP0)
  );
  const [securityP1, setSecurityP1] = useState(
    fnForState(securityIssueSlas?.securityBugP1)
  );
  const [securityP2, setSecurityP2] = useState(
    fnForState(securityIssueSlas?.securityBugP2)
  );
  const [securityP3, setSecurityP3] = useState(
    fnForState(securityIssueSlas?.securityBugP3)
  );

  return (
    <div>
      <h5>Security issue SLAs</h5>
      <FormGroup
        style={{ width: "375px" }}
        helperText={"Recommended: 90 days"}
        label={
          <p>
            Close tickets tagged with <code>security</code> and <code>p3</code>{" "}
            (or {">"} 3)
          </p>
        }
      >
        <StyledSelect
          choices={[
            SLA_DURATIONS.ONE_DAY,
            SLA_DURATIONS.THREE_DAYS,
            SLA_DURATIONS.FIVE_DAYS,
            SLA_DURATIONS.SEVEN_DAYS,
            SLA_DURATIONS.FOURTEEN_DAYS,
            SLA_DURATIONS.THIRTY_DAYS,
            SLA_DURATIONS.SIXTY_DAYS,
            SLA_DURATIONS.NINETY_DAYS,
            SLA_DURATIONS.ONE_HUNDRED_TWENTY_DAYS,
            SLA_DURATIONS.ONE_HUNDRED_FIFTY_DAYS,
            SLA_DURATIONS.NO_TIMELINE,
          ]}
          value={securityP3}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
            setSecurityP3(e.target.value)
          }
        />
      </FormGroup>
      <FormGroup
        helperText={"Recommended: 90 days"}
        label={
          <p>
            Close tickets tagged with <code>security</code> and <code>p2</code>
          </p>
        }
      >
        <StyledSelect
          choices={[
            SLA_DURATIONS.ONE_DAY,
            SLA_DURATIONS.THREE_DAYS,
            SLA_DURATIONS.FIVE_DAYS,
            SLA_DURATIONS.SEVEN_DAYS,
            SLA_DURATIONS.FOURTEEN_DAYS,
            SLA_DURATIONS.THIRTY_DAYS,
            SLA_DURATIONS.SIXTY_DAYS,
            SLA_DURATIONS.NINETY_DAYS,
            SLA_DURATIONS.ONE_HUNDRED_TWENTY_DAYS,
            SLA_DURATIONS.ONE_HUNDRED_FIFTY_DAYS,
            SLA_DURATIONS.NO_TIMELINE,
          ]}
          value={securityP2}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
            setSecurityP2(e.target.value)
          }
        />
      </FormGroup>
      <FormGroup
        helperText={"Recommended: 14 days"}
        label={
          <p>
            Close tickets tagged with <code>security</code> and <code>p1</code>
          </p>
        }
      >
        <StyledSelect
          choices={[
            SLA_DURATIONS.ONE_DAY,
            SLA_DURATIONS.THREE_DAYS,
            SLA_DURATIONS.FIVE_DAYS,
            SLA_DURATIONS.SEVEN_DAYS,
            SLA_DURATIONS.FOURTEEN_DAYS,
            SLA_DURATIONS.THIRTY_DAYS,
            SLA_DURATIONS.SIXTY_DAYS,
            SLA_DURATIONS.NINETY_DAYS,
            SLA_DURATIONS.ONE_HUNDRED_TWENTY_DAYS,
            SLA_DURATIONS.ONE_HUNDRED_FIFTY_DAYS,
            SLA_DURATIONS.NO_TIMELINE,
          ]}
          value={securityP1}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
            setSecurityP1(e.target.value)
          }
        />
      </FormGroup>
      <FormGroup
        helperText={"Recommended: 7 days"}
        label={
          <p>
            Close tickets tagged with <code>security</code> and <code>p0</code>
          </p>
        }
      >
        <StyledSelect
          choices={[
            SLA_DURATIONS.ONE_DAY,
            SLA_DURATIONS.THREE_DAYS,
            SLA_DURATIONS.FIVE_DAYS,
            SLA_DURATIONS.SEVEN_DAYS,
            SLA_DURATIONS.FOURTEEN_DAYS,
            SLA_DURATIONS.THIRTY_DAYS,
            SLA_DURATIONS.SIXTY_DAYS,
            SLA_DURATIONS.NINETY_DAYS,
            SLA_DURATIONS.ONE_HUNDRED_TWENTY_DAYS,
            SLA_DURATIONS.ONE_HUNDRED_FIFTY_DAYS,
            SLA_DURATIONS.NO_TIMELINE,
          ]}
          value={securityP0}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
            setSecurityP0(e.target.value)
          }
        />
      </FormGroup>
      <ButtonWithMargin
        onClick={async () => {
          await setSlas({
            variables: {
              p0: parseFloat(securityP0),
              p1: parseFloat(securityP1),
              p2: parseFloat(securityP2),
              p3: parseFloat(securityP3),
            },
            refetchQueries: [{ query: GetSlaInfoDocument }],
          });
          AppToaster.show({
            icon: "tick",
            intent: Intent.SUCCESS,
            message: "Security issue SLAs updated",
            timeout: 2500,
          });
        }}
        disabled={anyUnselected([
          securityP3,
          securityP2,
          securityP1,
          securityP0,
        ])}
        intent={Intent.PRIMARY}
      >
        Save
      </ButtonWithMargin>
      <Button onClick={viewHistory}>View history</Button>
    </div>
  );
};

const ONBOARDING_SLA_MIN = 3;
const ONBOARDING_SLA_MAX = 60;

const OnboardingSlas: React.FC<{
  onboardingSla: Maybe<
    NonNullable<GetSlaInfoQuery["organization"]>["onboardingSla"]
  >;
  viewHistory: () => void;
}> = ({ onboardingSla, viewHistory }) => {
  const [setSla] = useSetOnboardingSlaMutation();
  const [value, setValue] = useState(onboardingSla);

  const canSubmit =
    isSome(value) && ONBOARDING_SLA_MIN <= value && value <= ONBOARDING_SLA_MAX;

  return (
    <div>
      <h5>Onboarding SLA</h5>
      <FormGroup
        style={{ width: "375px" }}
        helperText={
          !canSubmit
            ? `Select a value between ${ONBOARDING_SLA_MIN} and ${ONBOARDING_SLA_MAX}`
            : undefined
        }
        label={
          <p>
            Specify an SLA for onboarding employees. Vanta will not flag users
            as "onboarding incomplete" or fail tests for incomplete security
            requirements before the SLA. If no SLA is specified, Vanta defaults
            to 14 days.
          </p>
        }
      >
        <ControlGroup>
          <NumericInput
            style={{ width: "60px" }}
            value={value ?? ""}
            onValueChange={newValue => setValue(Math.floor(newValue))}
          />
          <span style={{ alignSelf: "center" }}>&nbsp;days</span>
        </ControlGroup>
      </FormGroup>
      <ButtonWithMargin
        onClick={async () => {
          await setSla({
            variables: {
              slaInDays: value!,
            },
            refetchQueries: [{ query: GetSlaInfoDocument }],
          });
          AppToaster.show({
            icon: "tick",
            intent: Intent.SUCCESS,
            message: "Onboarding SLA updated",
            timeout: 2500,
          });
        }}
        disabled={!canSubmit}
        intent={Intent.PRIMARY}
      >
        Save
      </ButtonWithMargin>
      <Button onClick={viewHistory}>View history</Button>
    </div>
  );
};

const MDM_SLA_MIN = 1;
const MDM_SLA_MAX = 24;

const MdmSlas: React.FC<{
  mdmSlas: Maybe<NonNullable<GetSlaInfoQuery["organization"]>["mdmSlas"]>;
  viewHistory: () => void;
}> = ({ mdmSlas, viewHistory }) => {
  const [setSla] = useSetMdmSlasMutation();
  const [value, setValue] = useState(mdmSlas?.computerSetup);

  const canSubmit =
    isSome(value) && MDM_SLA_MIN <= value && value <= MDM_SLA_MAX;

  return (
    <div>
      <h5>Computer setup SLA</h5>
      <FormGroup
        style={{ width: "375px" }}
        helperText={
          !canSubmit
            ? `Select a value between ${MDM_SLA_MIN} and ${MDM_SLA_MAX}`
            : undefined
        }
        label={
          <p>
            Specify an SLA for completing setup of MDM-managed computers. Vanta
            will not fail tests for non-compliant devices before the SLA. If no
            SLA is specified, Vanta defaults to {DEFAULT_COMPUTER_SETUP_SLA}{" "}
            hours.
          </p>
        }
      >
        <ControlGroup>
          <NumericInput
            style={{ width: "60px" }}
            value={value ?? ""}
            onValueChange={newValue => setValue(Math.floor(newValue))}
          />
          <span style={{ alignSelf: "center" }}>&nbsp;hours</span>
        </ControlGroup>
      </FormGroup>
      <ButtonWithMargin
        onClick={async () => {
          await setSla({
            variables: {
              slaInHours: value!,
            },
          });
          AppToaster.show({
            icon: "tick",
            intent: Intent.SUCCESS,
            message: "MDM SLAs updated",
            timeout: 2500,
          });
        }}
        disabled={!canSubmit}
        intent={Intent.PRIMARY}
      >
        Save
      </ButtonWithMargin>
      <Button onClick={viewHistory}>View history</Button>
    </div>
  );
};

gql`
  query GetSlaInfo {
    organization {
      id
      mdmCredentials: credentials(
        services: ["jamf", "kandji", "microsoft_endpoint_manager"]
      ) {
        id
      }
      accessSlas {
        accessReviewed
        accessRevoked
      }
      accessSlasHistory {
        createdAt
        creatorName
      }
      mdmSlas {
        computerSetup
      }
      mdmSlasHistory {
        createdAt
        creatorName
      }
      onboardingSla
      onboardingSlasHistory {
        createdAt
        creatorName
      }
      securityIssueSlas {
        securityBugP0
        securityBugP1
        securityBugP2
        securityBugP3
      }
      securityIssueSlasHistory {
        createdAt
        creatorName
      }
      vulnerabilitySlas {
        vulnHighSeverity
        vulnMediumSeverity
        vulnLowSeverity
      }
      vulnerabilitySlasHistory {
        createdAt
        creatorName
      }
    }
  }

  mutation updateAccessSlas($reviewed: Float!, $revoked: Float!) {
    setAccessSlas(reviewed: $reviewed, revoked: $revoked) {
      createdAt
    }
  }

  mutation updateVulnerabilitySlas(
    $low: Float!
    $medium: Float!
    $high: Float!
  ) {
    setVulnerabilitySlas(low: $low, medium: $medium, high: $high) {
      createdAt
    }
  }

  mutation updateSecurityIssueSlas(
    $p0: Float
    $p1: Float
    $p2: Float
    $p3: Float
  ) {
    setSecurityIssueSlas(
      securityBugP0: $p0
      securityBugP1: $p1
      securityBugP2: $p2
      securityBugP3: $p3
    ) {
      createdAt
    }
  }

  mutation setOnboardingSla($slaInDays: Int!) {
    setOnboardingSla(gracePeriodInDays: $slaInDays) {
      id
      onboardingSla
    }
  }

  mutation setMdmSlas($slaInHours: Int!) {
    setMdmSlas(computerSetupInHours: $slaInHours) {
      id
      mdmSlas {
        computerSetup
      }
      mdmSlasHistory {
        createdAt
        creatorName
      }
    }
  }
`;
