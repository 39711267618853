import type { Maybe } from "common/base/types/maybe";
import React from "react";

interface IProps {
  showBadEmail: boolean;
  showWrongPassword: boolean;
  onSubmit(email: string, password: string): void;
}

interface IState {
  formControls: { [x: string]: string };
  proceed?: Maybe<boolean>;
  showErrors: boolean;
}

export class ReportSignIn extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
    this.state = {
      formControls: {
        email: "",
        password: "",
      },
      showErrors: true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  public handleChange(event: React.FormEvent<HTMLInputElement>) {
    const target = event.currentTarget;
    const newControls = {
      ...this.state.formControls,
      [target.name]: target.value,
    };
    this.setState({
      formControls: newControls,
      showErrors: false,
    });
  }

  public handleSubmit(event: React.SyntheticEvent<HTMLFormElement>) {
    event.preventDefault();
    this.props.onSubmit(
      this.state.formControls.email,
      this.state.formControls.password
    );
    this.setState({
      showErrors: true,
    });
  }

  public maybeRenderError() {
    if (
      this.state.showErrors &&
      (this.props.showBadEmail || this.props.showWrongPassword)
    ) {
      return (
        <div className="error">
          {this.props.showWrongPassword
            ? "Wrong password"
            : "Bad email address"}
        </div>
      );
    }
    return undefined;
  }

  public render() {
    return (
      <div className="flex-center-container">
        <div className="form-modal form-group">
          <h2>Welcome</h2>
          <form onSubmit={this.handleSubmit}>
            <label>
              <div>Email</div>
              <input
                autoCapitalize={"off"}
                autoComplete={"off"}
                autoCorrect={"off"}
                autoFocus={true}
                name="email"
                onChange={this.handleChange}
                placeholder="sally@example.com"
                required={true}
                type="email"
              />
            </label>
            <label>
              {this.maybeRenderError()}
              <div>Password</div>
              <input
                autoCapitalize={"off"}
                autoComplete={"off"}
                autoCorrect={"off"}
                name="password"
                onChange={this.handleChange}
                required={true}
                type="password"
              />
            </label>
            <input type="submit" value="See report" />
          </form>
        </div>
      </div>
    );
  }
}
