import type { Maybe } from "common/base/types/maybe";
import styled from "styled-components";

import { GRID_SPACING } from "../../base/grid";

export const SpacedList = styled.div<{ marginOverride?: Maybe<number> }>`
  display: flex;
  align-items: center;
  & > *:not(:first-child) {
    margin-left: ${({ marginOverride }) =>
      marginOverride ?? 2 * GRID_SPACING}px;
  }
`;

export const SpacedListFlexedItem = styled.div`
  flex: 1 1 0;
  width: 0;
  height: 100%;
`;
