import { Intent } from "@blueprintjs/core";
import { DateInput } from "@blueprintjs/datetime";
import { IconNames } from "@blueprintjs/icons";
import type { HR_SERVICES } from "common/base/types/helpers";
import { serviceToDisplayName } from "common/base/types/helpers";
import type { Maybe } from "common/base/types/maybe";
import gql from "graphql-tag";
import moment from "moment";
import React, { useEffect, useState } from "react";

import { BodyText } from "../../../../alpaca/components";
import { LogError } from "../../../../errors";
import { useSetEndDateCutoffForHrServiceMutation } from "../../../../gen/components";
import { UI_DATE_FORMAT_WITHOUT_TIME } from "../../../../helpers/common";
import { AppToaster } from "../../../../helpers/toaster";
import { CancelConfirmDialog } from "../../../helpers/CancelConfirmDialog";

interface IProps {
  endDateCutoff?: Maybe<Date>;
  initialLinking: boolean;
  isOpen: boolean;
  onClose(): void;
  service: typeof HR_SERVICES[number];
}

export const ConfigureHrDateFilter: React.FC<IProps> = ({
  endDateCutoff,
  initialLinking,
  isOpen,
  onClose,
  service,
}) => {
  // If this isn't the initial linking, then the end date cutoff was explicitly
  // set to nothing. Don't supply a default date of 3 months before now.
  const defaultDate = initialLinking ? new Date() : null;
  const [dateInput, setDateInput] = useState(endDateCutoff ?? defaultDate);
  useEffect(() => {
    if (!isOpen) {
      return;
    }
    setDateInput(endDateCutoff ?? defaultDate);
  }, [isOpen]);
  const [setEndDateCutoffForHrService, { loading }] =
    useSetEndDateCutoffForHrServiceMutation({
      onCompleted() {
        AppToaster.show({
          icon: "tick",
          intent: Intent.SUCCESS,
          message: "End date cutoff saved",
          timeout: 2500,
        });
        onClose();
      },
    });

  return (
    <CancelConfirmDialog
      isOpen={isOpen} // This is rendering a dialog within another dialog; the
      // outer dialog has the controlled `isOpen` input
      onClose={onClose}
      title={`People import settings for ${serviceToDisplayName(service)}`}
      body={
        <div>
          <BodyText>
            Choose which people you’d like to import from{" "}
            {serviceToDisplayName(service)}. If you’re in audit, make sure to
            import people who’ve left and set the date to the beginning of your
            audit period.
          </BodyText>
          <BodyText>
            Import people who are active in {serviceToDisplayName(service)} or
            who left after:
          </BodyText>
          <DateInput
            value={dateInput}
            onChange={date => setDateInput(date)}
            formatDate={date =>
              moment(date).format(UI_DATE_FORMAT_WITHOUT_TIME)
            }
            parseDate={str => new Date(str)}
            maxDate={new Date()}
            minDate={new Date("1975-01-01")}
            inputProps={{
              leftIcon: IconNames.CALENDAR,
            }}
          />
        </div>
      }
      confirmText="Save"
      onConfirm={() => {
        setEndDateCutoffForHrService({
          variables: {
            metadata: JSON.stringify({ endDateCutoff: dateInput }),
            service,
          },
        }).catch(LogError);
      }}
      loading={loading}
      enforceFocus={false}
    />
  );
};

gql`
  mutation setEndDateCutoffForHrService($metadata: String!, $service: String!) {
    setCredentialMetadata(metadata: $metadata, service: $service, merge: true) {
      id
      credentials(services: [$service]) {
        id
        metadata
      }
    }
  }
`;
