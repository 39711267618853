import { Button } from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";
import moment from "moment";
import React, { useState } from "react";

interface IMonth {
  month: number;
  year: number;
}

const MonthSelect = Select.ofType<moment.Moment>();

interface IProps {
  monthsToShow: number;
  onSelect(item: IMonth): void;
}

export const MonthSelector: React.FC<IProps> = ({ monthsToShow, onSelect }) => {
  const [buttonText, setButtonText] = useState("Select a month");
  const stepper = moment();
  const items: moment.Moment[] = [];
  let monthsToAdd = monthsToShow;
  while (monthsToAdd > 0) {
    items.push(moment(stepper));
    stepper.subtract(1, "month");
    monthsToAdd -= 1;
  }

  return (
    <MonthSelect
      filterable={false}
      items={items}
      itemRenderer={(item, { handleClick }) => {
        const text = item.format("MMMM, YYYY");
        return (
          <div onClick={handleClick} key={text} className="month-selector-item">
            {text}
          </div>
        );
      }}
      onItemSelect={item => {
        onSelect({ month: item.month(), year: item.year() });
        setButtonText(item.format("MMMM, YYYY"));
      }}
    >
      <Button>{buttonText}</Button>
    </MonthSelect>
  );
};
