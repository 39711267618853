import type { SpecificGcpSubnetResource } from "common/base/types/gen";
import { TestOutcome } from "common/base/types/gen";
import gql from "graphql-tag";
import React from "react";

import { EvaluationIcon } from "../../../helpers/EvaluationIcon";

export const gcpDataTableParameters = {
  columnOrder: ["projectId", "name", "networkId", "flowLogsEnabled"],
  displayName: "GCP Subnets",
  header: {
    projectId: "Project",
    name: "Name",
    networkId: "Network ID",
    flowLogsEnabled: "Flow logs enabled",
  },
  createRow: (subnet: Partial<SpecificGcpSubnetResource>) => {
    return {
      projectId: subnet.projectId,
      name: subnet.name,
      networkId: subnet.networkUniqueId,
      flowLogsEnabled: subnet.flowLogsEnabled ? (
        <EvaluationIcon evaluation={TestOutcome.PASS} />
      ) : (
        <EvaluationIcon evaluation={TestOutcome.FAIL} />
      ),
    };
  },
};

type DomainWithGcpData = {
  subnets: {
    edges: Array<{ node: Partial<SpecificGcpSubnetResource> }>;
  };
};

export const processGcpData = (domain: DomainWithGcpData) =>
  domain.subnets.edges.map(e => e.node);

gql`
  query fetchGcpSubnetInfo {
    organization {
      id
      displayName
      # 07/27/2021 The first: 1000 below is an arbitrarily high number so that we load all the resources at once.
      # We should modify this query (and the others) to eventually be paginated, but we're holding off on doing this for now.
      # See https://app.shortcut.com/vanta/story/33413
      subnets: resources(first: 1000, specificResourceType: GCPSubnet) {
        totalCount
        edges {
          node {
            id
            ... on SpecificGCPSubnetResource {
              projectId
              name
              networkUniqueId
              flowLogsEnabled
            }
          }
        }
      }
    }
  }
`;
