import { Intent } from "@blueprintjs/core";
import { BINARY_MIME_TYPES } from "common/base/types/helpers";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import moment from "moment";
import React, { useState } from "react";
import styled from "styled-components";
import { BASE_PALETTE } from "../../../alpaca/base/colors";
import { Button, DateInput, Dialog } from "../../../alpaca/components";
import { useCompleteAuditEngagementMutation } from "../../../gen/components";
import { AppToaster } from "../../../helpers/toaster";
import { FileUploadCard } from "../components/file-upload-card";
import type { AuditorPageEngagement } from "./utils";

interface IProps {
  onClose(): void;
  engagement?: Maybe<AuditorPageEngagement>;
}

export const CompleteEngagementDialog: React.FC<IProps> = ({
  engagement,
  onClose,
}) => {
  const [fileToUpload, setFileToUpload] = useState<Maybe<File>>(null);
  const [completionDate, setCompletionDate] = useState<Date>(new Date());
  const [completeEngagement] = useCompleteAuditEngagementMutation({
    onCompleted: () => {
      AppToaster.show({
        intent: Intent.SUCCESS,
        message: "Engagement completed",
      });
    },
  });
  const [inFlight, setInFlight] = useState(false);

  return (
    <Dialog
      isOpen={isSome(engagement)}
      onClose={closeDialog}
      title="Mark observation complete"
    >
      <FileUploadCard
        allowedMimeTypes={[BINARY_MIME_TYPES.PDF]}
        file={fileToUpload}
        onFileSelected={setFileToUpload}
      />
      <div style={{ marginTop: 24 }}>
        <StyledDateInput
          label="Select completion date"
          onChange={setCompletionDate}
          value={completionDate}
          formatDate={d => moment(d).format("MM / DD / YYYY")}
          parseDate={s => new Date(s)}
        />
      </div>
      <StyledFooterDiv>
        <Button
          disabled={!isSome(fileToUpload)}
          intent={Intent.PRIMARY}
          loading={inFlight}
          onClick={async () => {
            setInFlight(true);
            await completeEngagement({
              variables: {
                input: {
                  auditId: engagement!.id,
                  customerDomainId: engagement!.domainId,
                  report: fileToUpload!,
                  completionDate: completionDate.toISOString(),
                },
              },
            });
            closeDialog();
          }}
        >
          Upload
        </Button>
      </StyledFooterDiv>
    </Dialog>
  );

  function closeDialog() {
    setFileToUpload(null);
    setCompletionDate(new Date());
    setInFlight(false);
    onClose();
  }
};

const StyledDateInput = styled(DateInput)`
  .bp3-icon {
    display: none;
  }
  .bp3-input {
    text-align: center;
    padding-left: 10px;
    color: ${BASE_PALETTE.SLATE};
  }
`;

const StyledFooterDiv = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
`;

gql`
  mutation completeAuditEngagement($input: SetAuditCompletionDateInput!) {
    setAuditCompletionDate(input: $input) {
      ... on SetAuditCompletionDateSuccess {
        audit {
          id
          completionDate
        }
      }
    }
  }
`;
