import { isSome } from "common/base/types/maybe";
import type { IRiskCategory } from "common/schemas/riskRegister/risk-register-content";
import React, { useEffect, useMemo, useState } from "react";
import { Redirect, useHistory, useRouteMatch } from "react-router";
import styled from "styled-components";

import { VANTA_COLORS } from "../../../alpaca/base/colors";
import { CancelConfirmDialog } from "../../helpers/CancelConfirmDialog";
import { MasterDetailView } from "../../master-detail-view/master-detail-view";
import { WizardFooter } from "../../master-detail-view/wizard-footer";
import { PATHS } from "../../vanta-chrome/paths";
import type { RiskAssessmentDomain } from "./queries-and-types";
import { RiskCategoryNavList } from "./risk-category-nav-list";
import { RiskFormController } from "./risk-form-controller";
import { RiskFormDetail } from "./risk-form-detail";

interface IProps {
  category: IRiskCategory;
  domain: RiskAssessmentDomain;
}

export const RiskCategoryForm: React.FC<IProps> = ({ category, domain }) => {
  const history = useHistory();
  const [promptForNewScenario, setPromptForNewScenario] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

  const match = useRouteMatch<{
    // eslint-disable-next-line vanta/prefer-maybe
    stepNumber: string | undefined;
    // eslint-disable-next-line vanta/prefer-maybe
    substepNumber: string | undefined;
  }>();
  const stepParam = match?.params.stepNumber;
  const substepParam = match?.params.substepNumber;

  useEffect(() => {
    setPromptForNewScenario(false);
  }, [stepParam, category.id]);

  const formController = useMemo(() => {
    try {
      return new RiskFormController({
        urlSearchString: window.location.search,
        history,
        domain,
        category,
        stepParam,
        substepParam,
      });
    } catch (e) {
      return null;
    }
  }, [window.location.href, category, domain]);
  if (!isSome(formController)) {
    return <Redirect to={`${PATHS.RISK_ASSESSMENT}/${category.url}/0`} />;
  }

  return (
    <Container>
      <FormContainer>
        <MasterDetailView
          masterElement={
            <MasterContainer>
              <RiskCategoryNavList
                category={category}
                domain={domain}
                formController={formController}
              />
            </MasterContainer>
          }
          detailElement={
            <DetailContainer>
              <RiskFormDetail
                category={category}
                domain={domain}
                formController={formController}
              />
            </DetailContainer>
          }
          footerElement={
            <WizardFooter
              onClickSaveAndClose={() => formController.exitForm()}
              onClickNext={() => {
                if (formController.isFirstPass) {
                  setPromptForNewScenario(true);
                } else if (
                  formController.isTaskEditor &&
                  promptForNewScenario
                ) {
                  setShowDialog(true);
                  return;
                }
                formController.gotoNextPage();
              }}
              nextIsDisabled={!formController.canProceed()}
              disabledTooltipText={formController.tooltipText}
            ></WizardFooter>
          }
        />
      </FormContainer>
      <CancelConfirmDialog
        title="Add another scenario?"
        body="Would you like to add another scenario?"
        canClickOutsideToClose={false}
        cancelText="No"
        confirmText="Yes"
        isOpen={showDialog}
        onConfirm={() => {
          setShowDialog(false);
          history.push(`${window.location.pathname}?scenarioId=new`);
        }}
        onClose={() => {
          setPromptForNewScenario(false);
          setShowDialog(false);
          formController.gotoCompleteStep();
        }}
      />
    </Container>
  );
};

const styles = {
  DETAIL_MIN_HEIGHT: 400,
  DETAIL_WIDTH: 500,
  FORM_TOP_PADDING: 92,
  FORM_BOTTOM_PADDING: 24,
  FORM_LEFT_PADDING: 12,
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  word-break: break-word;
  & > *:last-child {
    flex-grow: 1;
  }
  width: 100%;
`;

const FormContainer = styled.div`
  background-color: ${VANTA_COLORS.BACKGROUND_WHITE};
`;

const MasterContainer = styled.div`
  padding-top: ${styles.FORM_TOP_PADDING}px;
  padding-bottom: ${styles.FORM_BOTTOM_PADDING}px;
`;

const DetailContainer = styled.div`
  padding-top: ${styles.FORM_TOP_PADDING}px;
  padding-bottom: ${styles.FORM_BOTTOM_PADDING}px;
  padding-left: ${styles.FORM_LEFT_PADDING}px;
  width: ${styles.DETAIL_WIDTH}px;
  min-height: ${styles.DETAIL_MIN_HEIGHT}px;
`;
