/**
 * For referral links we want to list in multiple places (e.g. in remediation
 * text and in a UI component elsewhere).
 */

type ReferralLink = {
  displayName: string;
  link: string;
};

/**
 * toMarkdownLink pairs well with toCommaSeparatedList.
 */
export const toMarkdownLink = (rl: ReferralLink) =>
  `[${rl.displayName}](${rl.link})`;

export const PenTestVendors: ReferralLink[] = [
  {
    displayName: "Cobalt",
    link: "https://hubs.ly/H0kQ7kp0",
  },
  {
    displayName: "Federacy",
    link: "https://www.federacy.com/?utm_source=v&utm_medium=v&utm_campaign=v",
  },
  {
    displayName: "HackerOne",
    link: "https://www.hackerone.com/product/pentest?utm_source=vanta&utm_medium=referral",
  },
  {
    displayName: "Illumant",
    link: "https://www.illumant.com/SOC2-appsa-01/",
  },
  {
    displayName: "Prescient Security",
    link: "https://prescientsecurity.com/vanta",
  },
  {
    displayName: "NCC Group",
    link: "https://www.nccgroup.trust/us/our-services/cyber-security/penetration-testing-and-security-assessments/",
  },
  {
    displayName: "Rhymetec",
    link: "https://www.rhymetec.com/",
  },
];
