import "./vanta-icon.scss";

import { Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React from "react";

export class VantaIcon extends React.Component {
  public render() {
    return (
      <span className="vanta-icon-container">
        <Icon icon={IconNames.FULL_CIRCLE} className="vanta-icon-circle" />
        <span className="vanta-icon-v">V</span>
      </span>
    );
  }
}
