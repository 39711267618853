import { Radio, RadioGroup, Spinner } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import { findLast } from "lodash";
import type { Dispatch } from "react";
import React, { useState } from "react";
import styled from "styled-components";
import { EmptyStateCard } from "../../../alpaca/components";
import { LogError, LogErrorMessage } from "../../../errors";
import {
  Feature,
  useGetPolicyVersionHistoryQuery,
  useRenewPolicyMutation,
} from "../../../gen/components";
import { useFeatureCheck } from "../../../helpers/feature-gating/feature-check";
import { DefaultLink } from "../../../helpers/links";
import { CancelConfirmDialog } from "../../helpers/CancelConfirmDialog";
import type { Policy } from "./common";

export const RenewPolicyDialog: React.FC<{
  onClose: () => void;
  policy: Policy;
  groups: string[];
}> = ({ policy, groups, onClose }) => {
  const [isMajorChange, setMajorChange] = useState<Maybe<boolean>>(null);
  const { data, error, loading } = useGetPolicyVersionHistoryQuery({
    fetchPolicy: "network-only",
    variables: { policyType: policy.policyType },
  });

  const [renewPolicy] = useRenewPolicyMutation();
  const isEmployeeGroupsOnPoliciesPageCustomer = useFeatureCheck(
    Feature.EmployeeGroupsOnPoliciesPage
  );

  if (error) {
    LogError(error);
    return null;
  }
  let dialogBody: string | JSX.Element = "";
  let confirmDisabled = true;

  // policy version is sorted earliest to latest so we reverse it
  const lastApprovedVersion = findLast(
    data?.organization.policyVersionHistory,
    p => isSome(p.approvedAt)
  );

  if (loading) {
    dialogBody = <Spinner />;
  } else if (!isSome(lastApprovedVersion)) {
    dialogBody = (
      <div>
        You do not have an approved "{policy.title}" yet. Please approve a
        policy first using "Approve".
      </div>
    );
  } else {
    confirmDisabled = !isSome(isMajorChange);
    dialogBody = isEmployeeGroupsOnPoliciesPageCustomer ? (
      <div>
        <p>
          You are about to renew the approved policy{" "}
          <DefaultLink href={lastApprovedVersion.uploadedDoc.url}>
            {policy.title}
          </DefaultLink>
          .
        </p>
        <p>
          If this new version will affect the way employees perform their jobs,
          we recommend requiring reacceptance. Once approved, employees
          belonging to the following groups will see this appear as a task:
        </p>
        <EmployeeGroupList groups={groups} />
        <p>
          To see or update policy acceptance settings for your employee groups,
          go to{" "}
          <DefaultLink href="/checklists/settings">
            onboarding settings
          </DefaultLink>
          .
        </p>
        <ReacceptDialog
          isMajorChange={isMajorChange}
          setMajorChange={setMajorChange}
        />
      </div>
    ) : (
      <div>
        <p>You are about to renew the following currently approved policy</p>
        <ul>
          <li>
            {/* Titles are consistent across versions */}
            <DefaultLink href={lastApprovedVersion.uploadedDoc.url}>
              {policy.title}
            </DefaultLink>
          </li>
        </ul>
        <p>
          This will set a new approval date for your current version of{" "}
          {policy.title}.
        </p>
        <ReacceptDialog
          isMajorChange={isMajorChange}
          setMajorChange={setMajorChange}
        />
      </div>
    );
  }

  return (
    <CancelConfirmDialog
      isOpen={true}
      body={dialogBody}
      confirmText={"Renew"}
      title="Renew current policy version"
      confirmDisabled={confirmDisabled}
      onClose={onClose}
      onConfirm={async () => {
        try {
          if (!isSome(isMajorChange)) {
            // If isMajorChange isn't defined the button should be
            // disabled, this should never happen
            LogErrorMessage("isMajorChange is not defined");
            return;
          }
          if (isSome(lastApprovedVersion)) {
            // if confirm is enabled, this is always true
            await renewPolicy({
              variables: {
                policyId: lastApprovedVersion.id,
                majorChange: isMajorChange,
              },
            });
          }
          onClose();
        } catch (e) {
          LogError(e);
        }
      }}
    ></CancelConfirmDialog>
  );
};

function ReacceptDialog(props: {
  isMajorChange: Maybe<boolean>;
  setMajorChange: Dispatch<boolean>;
}) {
  return (
    <div>
      <p>
        <strong>Do your employees need to reaccept this policy?</strong> If
        "Yes", your employees will need to go to{" "}
        <DefaultLink href="app.vanta.com/onboarding">
          app.vanta.com/onboarding
        </DefaultLink>{" "}
        to reaccept this policy.
      </p>
      <StyledRadioGroup
        onChange={e => props.setMajorChange(e.currentTarget.value === "Yes")}
        selectedValue={
          !isSome(props.isMajorChange)
            ? undefined
            : props.isMajorChange
            ? "Yes"
            : "No"
        }
      >
        <Radio style={RADIO_BUTTON_STYLES} label="Yes" value="Yes" />
        <Radio style={RADIO_BUTTON_STYLES} label="No" value="No" />
      </StyledRadioGroup>
    </div>
  );
}

function EmployeeGroupList(props: { groups: string[] }) {
  const listItems = props.groups.map(group => <li key={group}>{group}</li>);
  return listItems.length === 0 ? (
    <div>
      <EmptyStateCard
        mainText={"No employee groups\n"}
        style={{ margin: "6 12px 12px" }}
      />
    </div>
  ) : (
    <ul>{listItems}</ul>
  );
}

const StyledRadioGroup = styled(RadioGroup)`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const RADIO_BUTTON_STYLES = {
  padding: "10px 30px",
  marginBottom: "0px",
};

gql`
  mutation renewPolicy($policyId: String!, $majorChange: Boolean!) {
    renewPolicy(policyId: $policyId, majorChange: $majorChange) {
      id
      active
      approvedAt
      approverName
      createdAt
      title
    }
  }
`;
