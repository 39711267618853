import {
  IDENTITY_PROVIDER_SERVICES_SET,
  SERVICES_WITH_ROLE_GRANTS,
} from "common/base/types/helpers";
import React from "react";

import type { AccountsAccessCredential, VantaUser } from "./access-page";
import { AccountsAccessContainer } from "./accounts-access-container";
import { RoleGrantsAccessTable } from "./role-grants-access-table";
import { UsersAccessContainer } from "./users-access-container";

interface IAccessContainerProps {
  credential: AccountsAccessCredential;
  users: VantaUser[];
}

export const ServiceAccessContainer: React.FC<IAccessContainerProps> = ({
  credential,
  users,
}) => {
  const { service } = credential;
  if (IDENTITY_PROVIDER_SERVICES_SET.has(service)) {
    return <UsersAccessContainer credential={credential} />;
  } else if (SERVICES_WITH_ROLE_GRANTS.has(service)) {
    return <RoleGrantsAccessTable credential={credential} />;
  }
  return <AccountsAccessContainer credential={credential} users={users} />;
};
