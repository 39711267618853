import { OL } from "@blueprintjs/core";
import React from "react";

import { DefaultLink } from "../../../../helpers/links";
import {
  InfoCalloutContainer,
  InfoCalloutHeading,
  InfoCalloutText,
} from "../common/components";

export const GCPContainerRepositoriesEmptyState: React.FC = () => (
  <InfoCalloutContainer>
    <InfoCalloutHeading>No container repositories detected</InfoCalloutHeading>
    <InfoCalloutText>
      If you've enabled container scanning, vulnerabilities from Google
      Container Registry (GCR) repositories will appear here.
    </InfoCalloutText>
    <InfoCalloutText>
      If you use containers, Vanta recommends you start scanning your containers
      for vulnerabilities. You can do so by enabling the following APIs in each
      GCP project:
      <OL>
        <li>
          <DefaultLink
            target="_blank"
            rel="noopener noreferrer"
            href="https://console.cloud.google.com/flows/enableapi?apiid=containeranalysis.googleapis.com"
          >
            Container Analysis
          </DefaultLink>{" "}
          - this lets Vanta fetch container metadata. This API is free.
        </li>
        <li>
          <DefaultLink
            target="_blank"
            rel="noopener noreferrer"
            href="https://console.cloud.google.com/flows/enableapi?apiid=containerscanning.googleapis.com"
          >
            Container Scanning
          </DefaultLink>{" "}
          - this enables vulnerability scanning on each container image. This
          may incur additional charges from GCP.
        </li>
      </OL>
    </InfoCalloutText>
    <InfoCalloutText>
      Additionally, if you've set up GCP so that the project containing the
      Vanta service account is different from the project(s) containing GCP
      repositories, you must enable these APIs in the Vanta service account
      project as well.
    </InfoCalloutText>
    <InfoCalloutText>
      Within an hour of enabling these APIs, GCR repositories should start being
      displayed on this page.
    </InfoCalloutText>
    <InfoCalloutText>
      To learn more about container analysis, refer to GCP's{" "}
      <DefaultLink
        target="_blank"
        rel="noopener noreferrer"
        href="https://cloud.google.com/container-analysis/docs/container-analysis"
      >
        support overview
      </DefaultLink>{" "}
      and{" "}
      <DefaultLink
        target="_blank"
        rel="noopener noreferrer"
        href="https://cloud.google.com/container-analysis/docs/enabling-disabling-container-analysis"
      >
        enablement instructions
      </DefaultLink>
      .
    </InfoCalloutText>
  </InfoCalloutContainer>
);
