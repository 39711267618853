import "./inventory-card.scss";

import { Card, Elevation, FormGroup } from "@blueprintjs/core";
import { MDM_SERVICES } from "common/base/types/helpers";
import type { Maybe } from "common/base/types/maybe";
import { replaceUTF8MultiByteHexEscapes } from "common/base/utils";
import React from "react";

import { SmallCaption } from "./inventory-card";

interface IProps {
  labels?: Maybe<JSX.Element[]>;
  type: string;
  uid: string;
  name: string;
  error: string;
}

export const InventoryErrorCard: React.FC<IProps> = props => {
  // drop all labels except for account/mdm, since we can't be sure about
  // the other info
  const labels = props.labels?.map((l, i) => {
    if (
      l.key === "account" ||
      (typeof l.key === "string" &&
        MDM_SERVICES.map(service => service as string).includes(l.key))
    ) {
      return (
        <div key={i} className="inventory-list-card-float">
          {l}
        </div>
      );
    } else {
      return null;
    }
  });
  return (
    <Card
      className="inventory-list-card"
      interactive={false}
      elevation={Elevation.ZERO}
    >
      <div>
        {labels}
        <FormGroup label={props.type === "Other" ? "Name" : props.type}>
          <div>
            {replaceUTF8MultiByteHexEscapes(props.name)}
            <SmallCaption>
              {props.uid !== props.name ? props.uid : null}
            </SmallCaption>
          </div>
        </FormGroup>
        <hr></hr>
        <FormGroup label={"Error"}>{props.error}</FormGroup>
      </div>
    </Card>
  );
};
