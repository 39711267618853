import { Intent } from "@blueprintjs/core";
import { IconNames as BPIconNames } from "@blueprintjs/icons";
import { Popover2 } from "@blueprintjs/popover2";
import { TestOutcome } from "common/base/types/gen";
import type { Maybe } from "common/base/types/maybe";
import React, { useContext } from "react";

import { BASE_PALETTE } from "../../../../alpaca/base/colors";
import { GRID_SPACING } from "../../../../alpaca/base/grid";
import { BASE_TYPOGRAPHY } from "../../../../alpaca/base/typography";
import {
  Button,
  Icon,
  IconNames,
  Menu,
  MenuItem,
  MetaText,
} from "../../../../alpaca/components";
import { LogError } from "../../../../errors";
import type {
  ClientPlatform,
  PeoplePageFieldsFragment,
} from "../../../../gen/components";
import { useSendReminderEmailMutation } from "../../../../gen/components";
import { AppToaster } from "../../../../helpers/toaster";
import { booleanToEvaluationEnum } from "../../../helpers/EvaluationIcon";
import type {
  IUserWorkstation,
  UserFromFetchDomainEndpointsQuery,
} from "../../computers/utils";
import {
  clientPlatformFromWorkstation,
  COMPUTER_FLAG,
} from "../../computers/utils";
import { UserContext } from "../../user-context";
import { useIsExternalSatLinkedCheck } from "../sat-services-context";
import type { OnboardingListUser } from "./user-onboarding-list";

export function getOnboardingEmailReminderMenuItemsForUser(
  user: Pick<
    PeoplePageFieldsFragment,
    | "endpointState"
    | "hasAcceptedAllSecurityPolicies"
    | "hasCompletedSecurityTraining"
    | "mostRecentGdprSecurityTraining"
    | "mostRecentHipaaSecurityTraining"
    | "mostRecentPciSecurityTraining"
    | "securityRequirements"
  >,
  isExternalSatLinked: boolean,
  sendEmail: (flag: string) => void
): JSX.Element[] {
  const result: JSX.Element[] = [];
  if (
    user.securityRequirements.mustAcceptPolicies &&
    !user.hasAcceptedAllSecurityPolicies
  ) {
    result.push(
      <MenuItem
        key="policy"
        text="Send email to accept policies"
        onClick={() => sendEmail(REMINDER_EMAIL_FLAGS.POLICY)}
      />
    );
  }
  if (
    user.securityRequirements.mustInstallLaptopMonitoring &&
    user.endpointState === "none"
  ) {
    result.push(
      <MenuItem
        key="agent"
        text="Send email to install agent"
        onClick={() => sendEmail(REMINDER_EMAIL_FLAGS.APP)}
      />
    );
  }
  if (
    (user.securityRequirements.mustCompleteSecurityTraining &&
      !user.hasCompletedSecurityTraining) ||
    (user.securityRequirements.mustCompleteHipaaSecurityTraining &&
      !user.mostRecentHipaaSecurityTraining) ||
    (user.securityRequirements.mustCompletePciSecurityTraining &&
      !user.mostRecentPciSecurityTraining) ||
    (user.securityRequirements.mustCompleteGdprSecurityTraining &&
      !user.mostRecentGdprSecurityTraining)
  ) {
    if (
      // for now don't allow reminders if using an integration for SAT
      !isExternalSatLinked
    ) {
      result.push(
        <MenuItem
          key="sat"
          text="Send email to complete security training"
          onClick={() => sendEmail(REMINDER_EMAIL_FLAGS.SAT)}
        />
      );
    }
  }
  return result;
}

export function getWorkstationReminderMenuItems(
  workstation: IUserWorkstation,
  sendEmail: (flag: string, platform: Maybe<ClientPlatform>) => void
) {
  const platform = clientPlatformFromWorkstation(workstation);
  const result: JSX.Element[] = [];

  if (
    workstation.__typename !== "linuxServerData" &&
    booleanToEvaluationEnum(workstation.isEncrypted) === TestOutcome.FAIL
  ) {
    result.push(
      <MenuItem
        key="encrypt"
        alpacaIcon={IconNames.EMAIL}
        text="Send email to encrypt hard drive"
        onClick={() => sendEmail(COMPUTER_FLAG.ENCRYPT, platform)}
      />
    );
  }

  return result;
}

export function getAgentInstallationReminderMenuItems(
  user: UserFromFetchDomainEndpointsQuery,
  sendEmail: (flag: string) => void
) {
  const result: JSX.Element[] = [];

  const name = user.givenName ?? user.email;
  result.push(
    <MenuItem
      key="install"
      text={`Email ${name} to install Vanta Agent`}
      onClick={() => sendEmail(COMPUTER_FLAG.INSTALL_APP)}
    />
  );

  return result;
}

interface IOnboardingReminderProps {
  user: OnboardingListUser;
}
export const OnboardingReminderButton: React.FC<IOnboardingReminderProps> = ({
  user,
}) => {
  const { user: sendingUser } = useContext(UserContext);
  const isExternalSatLinked = useIsExternalSatLinkedCheck();
  const [sendReminder] = useSendReminderEmailMutation({
    onCompleted() {
      AppToaster.show({
        message: `Email sent to ${user.displayName}`,
        intent: Intent.SUCCESS,
      });
    },
  });
  const menuItems = getOnboardingEmailReminderMenuItemsForUser(
    user,
    isExternalSatLinked,
    fireEmail
  );

  function fireEmail(flag: string) {
    sendReminder({
      variables: { userId: user.id, flag, domainId: sendingUser!.domain.id },
    }).catch(LogError);
  }

  return <ReminderButton menuItems={menuItems} />;
};

interface IWorkstationReminderProps {
  user: UserFromFetchDomainEndpointsQuery;
  workstation: IUserWorkstation;
}
export const WorkstationReminderButton: React.FC<IWorkstationReminderProps> = ({
  user,
  workstation,
}) => {
  const { user: sendingUser } = useContext(UserContext);
  const [sendReminder] = useSendReminderEmailMutation({
    onCompleted() {
      AppToaster.show({
        message: `Email sent to ${user.displayName}`,
        intent: Intent.SUCCESS,
      });
    },
  });

  const menuItems = getWorkstationReminderMenuItems(workstation, fireEmail);

  function fireEmail(flag: string, platform: Maybe<ClientPlatform>) {
    sendReminder({
      variables: {
        userId: user.id,
        flag,
        domainId: sendingUser!.domain.id,
        platform,
      },
    }).catch(LogError);
  }

  return <ReminderButton menuItems={menuItems} />;
};

interface IAgentInstallationReminderProps {
  user: UserFromFetchDomainEndpointsQuery;
}
export const AgentInstallationReminderButton: React.FC<IAgentInstallationReminderProps> =
  ({ user }) => {
    const { user: sendingUser } = useContext(UserContext);
    const [sendReminder] = useSendReminderEmailMutation({
      onCompleted() {
        AppToaster.show({
          message: `Email sent to ${user.displayName}`,
          intent: Intent.SUCCESS,
        });
      },
    });

    const menuItems = getAgentInstallationReminderMenuItems(user, fireEmail);

    function fireEmail(flag: string) {
      sendReminder({
        variables: {
          userId: user.id,
          flag,
          domainId: sendingUser!.domain.id,
        },
      }).catch(LogError);
    }

    return <ReminderButton menuItems={menuItems} />;
  };

interface IReminderButtonProps {
  menuItems: JSX.Element[];
}
const ReminderButton: React.FC<IReminderButtonProps> = ({ menuItems }) =>
  menuItems.length > 0 ? (
    <div>
      <Popover2
        minimal
        placement={"bottom-start"}
        content={<Menu>{menuItems}</Menu>}
      >
        <Button rightIcon={BPIconNames.CHEVRON_DOWN}>
          <div style={{ display: "flex" }}>
            <Icon
              icon={IconNames.EMAIL}
              style={{ marginRight: GRID_SPACING }}
              color={BASE_PALETTE.INK}
            />
            <MetaText
              fontWeight={BASE_TYPOGRAPHY.FONT_WEIGHTS.SLIGHTLY_BOLD}
              lineHeight={"14px"}
            >
              Remind
            </MetaText>
          </div>
        </Button>
      </Popover2>
    </div>
  ) : null;

const REMINDER_EMAIL_FLAGS = {
  POLICY: "POLICY_ACCEPTANCE_FLAG",
  APP: "INSTALL_APP",
  SAT: "SECURITY_TRAINING_FLAG",
};
