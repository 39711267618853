import { Callout, Classes, Intent, Spinner, UL } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import { SnykStatusCodes } from "common/resources/specific-resources/snyk/constants";
import gql from "graphql-tag";
import { keyBy } from "lodash";
import React, { useState } from "react";

import { GRID_SPACING } from "../../../../alpaca/base/grid";
import { BodyText, Button } from "../../../../alpaca/components";
import {
  AllLinkedCredentialsDocument,
  useListAccessibleSnykOrganizationsQuery,
  useSetCredentialsMutation,
} from "../../../../gen/components";
import { AppToaster } from "../../../../helpers/toaster";
import { DropdownButton } from "../../components/dropdown-button";

interface IProps {
  token: string;
  existingOrganizationId?: Maybe<string>;
  onCredentialsLinked?(): void;
}

export const SelectOrganization: React.FC<IProps> = ({
  token,
  existingOrganizationId,
  onCredentialsLinked,
}) => {
  const { loading, data } = useListAccessibleSnykOrganizationsQuery({
    variables: { token },
    fetchPolicy: "network-only",
    skip: token === "",
  });
  const [selectedOrganization, setSelectedOrganization] = useState(
    existingOrganizationId
  );
  const [setCredentials, { loading: setCredentialsLoading }] =
    useSetCredentialsMutation({
      refetchQueries: [{ query: AllLinkedCredentialsDocument }],
      awaitRefetchQueries: true,
      onCompleted: () => {
        AppToaster.show({
          message: "Snyk connection created.",
          intent: Intent.SUCCESS,
        });
        onCredentialsLinked?.();
      },
    });

  if (token === "") {
    return null;
  }

  if (loading || !isSome(data) || !isSome(data.accessibleSnykOrganizations)) {
    return (
      <div style={{ padding: `${2 * GRID_SPACING}` }}>
        <Spinner />
      </div>
    );
  }

  const { success, statusCode, organizations } =
    data.accessibleSnykOrganizations;
  if (!success) {
    let content: JSX.Element;
    switch (statusCode) {
      case SnykStatusCodes.NO_API_ACCESS:
        content = (
          <div>
            <p>Failed to access Snyk API.</p>
            <p>
              This may be because the token provided belongs to a
              user/organization on Snyk's free plan (API access is only
              available on the paid plan).
            </p>
          </div>
        );
        break;
      case SnykStatusCodes.NO_ACCESSIBLE_ORGANIZATIONS:
        content = (
          <div>
            <p>No organizations accessible by this API token.</p>
            <p>For an organization to be accessible by an API token:</p>
            <UL>
              <li>the organization must be on a paid plan</li>
              <li>
                the API token must have read access to the organizations members
              </li>
            </UL>
          </div>
        );
        break;
      default:
        throw new Error("Unknown Snyk linking error code");
    }
    return (
      <div className={Classes.DIALOG_BODY}>
        <Callout intent={Intent.DANGER}>{content}</Callout>
      </div>
    );
  }

  const organizationsById = keyBy(organizations, "id");
  const organizationIds = Object.keys(organizationsById);
  return (
    <>
      <div className={Classes.DIALOG_BODY}>
        <BodyText>
          Select the organization that you would like Vanta to monitor.
        </BodyText>
        <DropdownButton
          options={organizationIds}
          selectedOption={selectedOrganization}
          onOptionSelect={organizationId =>
            setSelectedOrganization(organizationId)
          }
          optionRenderer={organizationId => {
            const { name, id } = organizationsById[organizationId];
            return `${name} (${id})`;
          }}
          defaultText="Select an organization"
          buttonWidth={320}
          menuWidth={320}
        />
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button
            intent={Intent.PRIMARY}
            onClick={async () => {
              const credentials = JSON.stringify({
                token,
                organizationId: selectedOrganization,
              });
              await setCredentials({
                variables: {
                  credentials,
                  service: "snyk",
                },
              });
            }}
            disabled={!isSome(selectedOrganization)}
            loading={setCredentialsLoading}
          >
            Save
          </Button>
        </div>
      </div>
    </>
  );
};

gql`
  query listAccessibleSnykOrganizations($token: String!) {
    accessibleSnykOrganizations(token: $token) {
      success
      statusCode
      organizations {
        id
        name
      }
    }
  }
`;
