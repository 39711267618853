import type { Maybe } from "common/base/types/maybe";
import type React from "react";

import type { IQuestionSchema } from "../../forms/interfaces";

export enum ProfilePageType {
  ENGINEERING = "engineering",
  INFORMATION = "information",
}

export type ProfilePageInternalProps = {
  graphqlQuery: any;
  questionSchema: IQuestionSchema;
  mapQueryToSchemaInfo: (domain: any) => { [k: string]: any };

  /**
   * Appears above the schema form in the company settings page.
   */
  additionalContent?: Maybe<React.ReactNode>;
};
