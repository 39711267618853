import { Classes, Dialog, Intent } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import React from "react";

import { Button } from "../../alpaca/components";

interface IProps {
  body: JSX.Element | string;
  cancelText?: Maybe<string>;
  /**
   * Default behavior is true
   */
  // eslint-disable-next-line vanta/prefer-maybe,vanta/optional-always-maybe
  canClickOutsideToClose?: boolean | undefined;
  confirmText: string;
  confirmDisabled?: Maybe<boolean>;
  confirmDisabledMessage?: Maybe<string>;
  confirmIntent?: Maybe<Intent>;
  // Default behavior is true
  enforceFocus?: Maybe<boolean>;
  loading?: Maybe<boolean>;
  isOpen: boolean;
  title: string;
  onClose(): void;
  onConfirm: (() => void) | (() => Promise<void>);
  portalClassName?: Maybe<string>;
}

export class CancelConfirmDialog extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
  }
  public render() {
    return (
      <Dialog
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
        title={this.props.title}
        canOutsideClickClose={this.props.canClickOutsideToClose}
        enforceFocus={this.props.enforceFocus ?? true}
        portalClassName={this.props.portalClassName ?? undefined}
      >
        <form onSubmit={this.onSubmit}>
          <div className={`${Classes.DIALOG_BODY}`}>{this.props.body}</div>
          <div className={`${Classes.DIALOG_FOOTER}`}>
            <div className={`${Classes.DIALOG_FOOTER_ACTIONS}`}>
              <Button
                onClick={this.props.onClose}
                text={this.props.cancelText ?? "Cancel"}
              />
              <Button
                disabled={this.props.confirmDisabled ?? false}
                intent={this.props.confirmIntent ?? Intent.PRIMARY}
                type="submit"
                loading={this.props.loading ?? undefined}
                text={this.props.confirmText}
                tooltipContent={this.props.confirmDisabledMessage ?? undefined}
              />
            </div>
          </div>
        </form>
      </Dialog>
    );
  }

  private async onSubmit(e: React.SyntheticEvent<any>) {
    e.preventDefault();
    await this.props.onConfirm();
  }
}
