import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import React from "react";
import styled from "styled-components";

import { BASE_PALETTE } from "../../../../alpaca/base/colors";
import { GRID_SPACING } from "../../../../alpaca/base/grid";
import { BodyText } from "../../../../alpaca/components";
import { LogErrorMessage } from "../../../../errors";
import type { IPolicyWizardStep } from "../beta-policies-wizard-page";

export const PoliciesWizardStepProgress: React.FC<{
  allSteps: IPolicyWizardStep[];
  currStepIdx: number;
  onStepClick: (step: number) => void;
}> = ({ allSteps, currStepIdx, onStepClick }) => {
  const currStep = allSteps[currStepIdx];
  if (!isSome(currStep)) {
    LogErrorMessage(`Policies wizard step undefined for index: ${currStepIdx}`);
    throw new Error("Policies wizard step undefined");
  }
  return (
    <StyledProgressContainer>
      {allSteps.map((step, idx) => (
        <StyledStep key={idx}>
          {idx <= currStepIdx ? (
            <StyledClickable
              color={
                idx === currStepIdx ? BASE_PALETTE.MULBERRY : BASE_PALETTE.SMOKE
              }
              onClick={() => onStepClick(idx)}
            >
              {step.stepName}
            </StyledClickable>
          ) : (
            <BodyText color={BASE_PALETTE.SMOKE}>{step.stepName}</BodyText>
          )}
          <StyledCircle active={idx <= currStepIdx}>
            {idx > 0 ? (
              <StyledProgressLine active={idx <= currStepIdx} />
            ) : null}
          </StyledCircle>
        </StyledStep>
      ))}
    </StyledProgressContainer>
  );
};

const StyledCircle = styled.div<{ active?: Maybe<boolean> }>`
  background-color: ${({ active }) =>
    active ? BASE_PALETTE.MULBERRY : BASE_PALETTE.FOG};
  border-radius: 10px;
  height: ${GRID_SPACING}px;
  margin: 0 auto;
  position: relative;
  width: ${GRID_SPACING}px;
`;

const StyledClickable = styled(BodyText)`
  &:hover {
    color: ${BASE_PALETTE.MULBERRY};
    cursor: pointer;
  }
`;

const StyledProgressContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${4 * GRID_SPACING}px;
`;

const StyledProgressLine = styled.div<{ active?: Maybe<boolean> }>`
  background-color: ${({ active }) =>
    active ? BASE_PALETTE.MULBERRY : BASE_PALETTE.FOG};
  height: 1px;
  left: -${10.5 * GRID_SPACING}px;
  position: absolute;
  top: ${0.5 * GRID_SPACING}px;
  width: ${10 * GRID_SPACING}px;
`;

const StyledStep = styled.div`
  text-align: center;
  width: ${12 * GRID_SPACING}px;
`;
