import { Button, FormGroup, H2, InputGroup, Intent } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import React, { useState } from "react";

import {
  useChangeDomainNameMutation,
  AllDomainsMinimalDocument,
  GetDomainInfoForQueryPageDocument,
} from "../../gen/components";
import { AppToaster } from "../../helpers/toaster";

interface IProps {
  domainId: string;
  name: string;
}

export const ChangeDomainNamePanel: React.FC<IProps> = ({ domainId, name }) => {
  const [newName, setNewName] = useState<Maybe<string>>(name);

  const [changeDomainName] = useChangeDomainNameMutation({
    refetchQueries: [
      {
        query: GetDomainInfoForQueryPageDocument,
        variables: { domainId },
      },
      {
        query: AllDomainsMinimalDocument,
      },
    ],
    onCompleted: () => {
      AppToaster.show({
        message: `Change domain name to ${newName}.`,
        intent: Intent.SUCCESS,
      });
    },
  });

  return (
    <div className="panel-container">
      <div className="mid-panel">
        <div className="form-group">
          <H2>Change domain name</H2>
          <FormGroup
            label="new domain name"
            labelFor="text-input"
            labelInfo="(required)"
          >
            <InputGroup
              autoCapitalize="off"
              autoComplete="off"
              autoCorrect="off"
              autoFocus={false}
              name="fullname"
              placeholder={name}
              required={true}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setNewName(e.currentTarget.value)
              }
              type="text"
            />
          </FormGroup>
          <Button
            intent={Intent.PRIMARY}
            onClick={() => {
              if (isSome(newName)) {
                changeDomainName({
                  variables: {
                    domainId,
                    newName,
                  },
                }).catch(e => {
                  AppToaster.show({
                    message: e.message,
                    intent: Intent.WARNING,
                  });
                });
              } else {
                AppToaster.show({
                  message: "Please fill all required fields",
                  intent: Intent.WARNING,
                });
              }
            }}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

gql`
  mutation changeDomainName($domainId: ID!, $newName: String!) {
    setDomainName(domainId: $domainId, domainName: $newName) {
      id
    }
  }
`;
