import { InputGroup, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import {
  PERSONNEL_PAGE_QUERY_PARAMS,
  PERSONNEL_PAGE_TAB_IDS,
} from "common/constants/hr";
import gql from "graphql-tag";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

import { LogError } from "../../../errors";
import {
  GetInfoForRolesTableDocument,
  useChangeRoleNameMutation,
  useDeleteRoleMutation,
  useGetInfoForRolesTableQuery,
} from "../../../gen/components";
import { AppToaster } from "../../../helpers/toaster";
import { CancelConfirmDialog } from "../../helpers/CancelConfirmDialog";
import { FullPageSpinner } from "../../helpers/FullPageSpinner";
import { Card } from "../components/card";
import type { IMenuOption } from "../components/data-table";
import { DataTable } from "../components/data-table";
import { PEOPLE_PAGE_DEFAULT_PAGE_SIZE } from "./shared/common";

const MENU_FLAGS = {
  EDIT: "EDIT",
  DELETE: "DELETE",
};

interface IRole {
  id: string;
  name: string;
}

export const RolesTable: React.FC = () => {
  const { loading, data } = useGetInfoForRolesTableQuery();
  const [roleToEdit, setRoleToEdit] = useState<Maybe<IRole>>(null);
  const [inputText, setInputText] = useState("");
  const [mutationInFlight, setMutationInFlight] = useState(false);
  const history = useHistory();
  useEffect(() => {
    setInputText(roleToEdit?.name ?? "");
  }, [roleToEdit]);
  const [roleToDelete, setRoleToDelete] = useState<Maybe<IRole>>(null);
  const [deleteRole] = useDeleteRoleMutation({
    refetchQueries: [{ query: GetInfoForRolesTableDocument }],
    onCompleted() {
      AppToaster.show({
        message: "Group deleted",
        intent: Intent.SUCCESS,
      });
      setMutationInFlight(false);
      setRoleToDelete(null);
    },
  });
  const [changeRoleName] = useChangeRoleNameMutation({
    refetchQueries: [{ query: GetInfoForRolesTableDocument }],
    onCompleted() {
      AppToaster.show({
        message: "Group name updated",
        intent: Intent.SUCCESS,
      });
      setMutationInFlight(false);
      setRoleToEdit(null);
    },
  });

  if (loading || !data) {
    return <FullPageSpinner />;
  }

  const roles = data.organization.roles.map(r => {
    return {
      ...r,
      number: r.users.length,
    };
  });

  const table = (
    <DataTable
      useDefaultStyling
      stickyHeaders
      paginate={{
        paginationId: "people-page-roles",
        defaultPageSize: PEOPLE_PAGE_DEFAULT_PAGE_SIZE,
      }}
      columnOrder={["name", "number"]}
      header={{
        name: "Group",
        number: "Number of people",
      }}
      data={roles}
      emptyDefault={"You haven't added any groups"}
      createRow={role => {
        return {
          name: role.name,
          number: role.number,
        };
      }}
      columnSortFunctions={{
        name: (r1, r2) => r1.name.localeCompare(r2.name),
        number: (r1, r2) => r1.number - r2.number,
      }}
      searchFilter={(role, searchInput) =>
        role.name.toLocaleLowerCase().includes(searchInput.toLocaleLowerCase())
      }
      onRowClick={role => {
        history.push(
          `${window.location.pathname}?tab=${PERSONNEL_PAGE_TAB_IDS.EVERYBODY}&${PERSONNEL_PAGE_QUERY_PARAMS.GROUP_FILTER}=${role.id}`
        );
      }}
      menuOptions={role => {
        const baseOptions: IMenuOption[] = [
          {
            flag: MENU_FLAGS.EDIT,
            iconName: IconNames.EDIT,
            text: "Edit",
          },
        ];

        if (role.number === 0) {
          baseOptions.push({
            flag: MENU_FLAGS.DELETE,
            iconName: IconNames.DELETE,
            text: "Delete",
          });
        }
        return baseOptions;
      }}
      onMenuItemClick={(flag, role) => {
        switch (flag) {
          case MENU_FLAGS.EDIT:
            setRoleToEdit(role);
            break;
          case MENU_FLAGS.DELETE:
            setRoleToDelete(role);
            break;
          default:
            throw new Error(`Unrecognized menu flag: ${flag}`);
        }
      }}
    />
  );

  const deleteDialog = isSome(roleToDelete) ? (
    <CancelConfirmDialog
      title="Delete Group"
      body={<div>Delete the group {roleToDelete.name}?</div>}
      confirmText="Yes"
      onClose={() => setRoleToDelete(null)}
      isOpen={true}
      loading={mutationInFlight}
      onConfirm={() => {
        deleteRole({
          variables: {
            roleId: roleToDelete.id,
          },
        }).catch(LogError);
        setMutationInFlight(true);
      }}
    />
  ) : null;

  const editDialog = isSome(roleToEdit) ? (
    <CancelConfirmDialog
      title="Edit Group"
      body={
        <div>
          <InputGroup
            value={inputText}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setInputText(e.target.value)
            }
          />
        </div>
      }
      confirmDisabled={inputText.trim() === ""}
      confirmText="Edit"
      onClose={() => setRoleToEdit(null)}
      loading={mutationInFlight}
      isOpen={true}
      onConfirm={() => {
        const name = inputText.trim();
        changeRoleName({
          variables: {
            name,
            roleId: roleToEdit!.id,
          },
        }).catch(LogError);
        setMutationInFlight(true);
      }}
    />
  ) : null;

  return (
    <Card>
      {table}
      {deleteDialog}
      {editDialog}
    </Card>
  );
};

gql`
  query GetInfoForRolesTable {
    organization {
      id
      roles {
        id
        name
        users {
          id
          displayName
        }
      }
    }
  }
`;

gql`
  mutation deleteRole($roleId: String!) {
    removeRole(roleId: $roleId) {
      id
    }
  }
`;

gql`
  mutation changeRoleName($roleId: String!, $name: String!) {
    changeRoleName(roleId: $roleId, name: $name) {
      id
    }
  }
`;
