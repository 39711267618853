import "./manage-billing.scss";

import { Divider, InputGroup, Intent } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import { simplePlural } from "common/grammar/plurals";
import gql from "graphql-tag";
import React from "react";
import { Elements, StripeProvider } from "react-stripe-elements";
import styled from "styled-components";

import { Config } from "../../config";
import { LogError, LogErrorMessage } from "../../errors";
import type {
  GetPaymentInfoQuery,
  LinkCreditCardMutationFn,
  PaymentInterval,
  SetBillingEmailMutationFn,
  Soc2ContactUsMutationFn,
  UpdatePaymentMethodMutationFn,
} from "../../gen/components";
import {
  GetPaymentInfoDocument,
  useGetPaymentInfoQuery,
  useLinkCreditCardMutation,
  useSetBillingEmailMutation,
  useSoc2ContactUsMutation,
  useUpdatePaymentMethodMutation,
} from "../../gen/components";
import { DefaultLink } from "../../helpers/links";
import { AppToaster } from "../../helpers/toaster";
import { CancelConfirmDialog } from "../helpers/CancelConfirmDialog";
import { FullPageSpinner } from "../helpers/FullPageSpinner";
import { ReactStripeForm } from "../payments/credit-card-form";
import {
  PageHeadingInfo,
  VantaDashboardPage,
} from "../vanta-chrome/page-content/vanta-dashboard-page";

const PaddedDivider = styled(Divider)`
  margin: 24px 5px 12px;
`;

interface IProps {
  payment: GetPaymentInfoQuery["organization"]["payment"];
  linkCreditCardMutation: LinkCreditCardMutationFn;
  setBillingEmailMutation: SetBillingEmailMutationFn;
  updatePaymentMethodMutation: UpdatePaymentMethodMutationFn;
  soc2ContactUsMutation: Soc2ContactUsMutationFn;
}

interface IState {
  pendingBillingEmail: Maybe<string>;
  pendingPaymentMethod: Maybe<string>;
  pendingPaymentInterval: Maybe<PaymentInterval>;
  showingUpdateBillingEmail: boolean;
  showingUpdatePaymentMethod: boolean;
  savingBillingEmail: boolean;
  savingPaymentMethod: boolean;
  sendingSOC2ContactUsEmail: boolean;
  sentSOC2ContactUsEmail: boolean;
  editingCC: boolean;
  savingCC: boolean;
}

class ManageBillingPageInternal extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
    this.state = {
      pendingBillingEmail: null,
      pendingPaymentMethod: null,
      pendingPaymentInterval: null,
      showingUpdateBillingEmail: false,
      showingUpdatePaymentMethod: false,
      savingBillingEmail: false,
      savingPaymentMethod: false,
      editingCC: false,
      savingCC: false,
      sendingSOC2ContactUsEmail: false,
      sentSOC2ContactUsEmail: false,
    };

    this.handleBillingEmailChange = this.handleBillingEmailChange.bind(this);
    this.onCCStateChange = this.onCCStateChange.bind(this);
    this.updateBillingEmailClicked = this.updateBillingEmailClicked.bind(this);
    this.updatePaymentMethodClicked =
      this.updatePaymentMethodClicked.bind(this);
    this.onRequestCChange = this.onRequestCChange.bind(this);
    this.dismissDialog = this.dismissDialog.bind(this);
    this.saveBillingEmail = this.saveBillingEmail.bind(this);
    this.savePaymentMethod = this.savePaymentMethod.bind(this);
    this.soc2ContactUsClicked = this.soc2ContactUsClicked.bind(this);
  }
  public render() {
    const payment = this.props.payment;

    const vantaSubscription = payment.vantaSubscription;

    let vantaSubscriptionOverview: Maybe<JSX.Element> = null;

    if (isSome(vantaSubscription)) {
      const interval = vantaSubscription.paymentInterval;

      const renewalDateText = isSome(vantaSubscription.billingPeriodEnd)
        ? new Date(vantaSubscription.billingPeriodEnd).toLocaleDateString(
            "en-US",
            {
              year: "numeric",
              month: "long",
              day: "numeric",
            }
          )
        : null;

      vantaSubscriptionOverview = (
        <span>
          Your plan is billed{" "}
          <strong>${this.getTotalPrice(vantaSubscription)}</strong> every{" "}
          {simplePlural(vantaSubscription.intervalCount, interval)} and will be
          billed on <strong>{renewalDateText}</strong>.
        </span>
      );
    }

    const updateBillingEmailBody = isSome(
      this.state.showingUpdateBillingEmail
    ) ? (
      <div>
        <InputGroup
          name="billingEmail"
          onChange={this.handleBillingEmailChange}
          placeholder="sandra@example.com"
          type="email"
          value={
            isSome(this.state.pendingBillingEmail)
              ? this.state.pendingBillingEmail
              : isSome(payment.billingEmail)
              ? payment.billingEmail
              : undefined
          }
        />
      </div>
    ) : (
      ""
    );

    const updatePaymentMethodBody = isSome(
      this.state.showingUpdatePaymentMethod
    ) ? (
      <div>{this.renderCreditCardForm()}</div>
    ) : (
      ""
    );

    return (
      <VantaDashboardPage headingInfo={PageHeadingInfo.BILLING}>
        <CancelConfirmDialog
          body={updateBillingEmailBody}
          confirmText="Update"
          isOpen={this.state.showingUpdateBillingEmail}
          onClose={this.dismissDialog}
          onConfirm={this.saveBillingEmail}
          loading={this.state.savingBillingEmail}
          title="Update billing email"
        />
        <CancelConfirmDialog
          body={updatePaymentMethodBody}
          confirmText="Update"
          isOpen={this.state.showingUpdatePaymentMethod}
          onClose={this.dismissDialog}
          loading={this.state.savingCC || this.state.savingPaymentMethod}
          onConfirm={this.savePaymentMethod}
          title="Update payment method"
        />
        {isSome(vantaSubscription) ? <p>{vantaSubscriptionOverview}</p> : null}
        For assistance, please reach out to{" "}
        <DefaultLink href="mailto:billing@vanta.com">
          billing@vanta.com
        </DefaultLink>
        <PaddedDivider />
        <table className="billing-settings">
          <tbody>
            <tr>
              <th>Payment method</th>
              <td>
                {isSome(vantaSubscription) &&
                vantaSubscription.paymentMethod === "send_invoice" ? (
                  "ACH"
                ) : payment.defaultCreditCard ? (
                  <span>
                    {payment.defaultCreditCard.brand} ending in{" "}
                    {payment.defaultCreditCard.last4}
                  </span>
                ) : (
                  "none"
                )}
              </td>
              {/* Only show the option to change credit card info if the account already use a credit card */}
              {vantaSubscription?.paymentMethod === "charge_automatically" ? (
                <td>
                  <DefaultLink
                    href="javascript:void(0)"
                    onClick={this.updatePaymentMethodClicked}
                  >
                    Update
                  </DefaultLink>
                </td>
              ) : null}
            </tr>
            <tr>
              <th>Billed every</th>
              <td>
                {isSome(vantaSubscription) &&
                vantaSubscription.intervalCount > 0
                  ? simplePlural(
                      vantaSubscription.intervalCount,
                      vantaSubscription.paymentInterval
                    )
                  : "n/a"}
              </td>
            </tr>
            <tr>
              <th>Billing email</th>
              <td>{payment.billingEmail}</td>
              <td>
                <DefaultLink
                  href="javascript:void(0)"
                  onClick={this.updateBillingEmailClicked}
                >
                  Update
                </DefaultLink>
              </td>
            </tr>
          </tbody>
        </table>
      </VantaDashboardPage>
    );
  }

  private dismissDialog() {
    this.setState({
      showingUpdateBillingEmail: false,
      showingUpdatePaymentMethod: false,
    });
  }

  private updatePaymentMethodClicked() {
    const payment = this.props.payment;
    this.setState({
      showingUpdatePaymentMethod: true,
      editingCC: isSome(payment) && !isSome(payment.defaultCreditCard),
    });
  }

  private updateBillingEmailClicked() {
    this.setState({
      showingUpdateBillingEmail: true,
    });
  }

  private saveBillingEmail() {
    if (isSome(this.state.pendingBillingEmail)) {
      this.setState({
        savingBillingEmail: true,
      });

      const newEmail = this.state.pendingBillingEmail;

      this.props
        .setBillingEmailMutation({
          variables: {
            email: newEmail,
          },
        })
        .then(() => {
          AppToaster.show({
            icon: "tick",
            intent: Intent.SUCCESS,
            message: "Billing email changed",
            timeout: 2500,
          });

          this.setState({
            savingBillingEmail: false,
            showingUpdateBillingEmail: false,
            pendingBillingEmail: undefined,
          });
        })
        .catch(e => LogError(e));
    } else {
      this.setState({ showingUpdateBillingEmail: false });
    }
  }

  private soc2ContactUsClicked() {
    this.setState({ sendingSOC2ContactUsEmail: true });

    this.props
      .soc2ContactUsMutation({
        variables: {},
      })
      .then(() => {
        AppToaster.show({
          icon: "tick",
          intent: Intent.SUCCESS,
          message: "Email sent! We'll get back to you shortly.",
          timeout: 2500,
        });

        this.setState({
          sentSOC2ContactUsEmail: true,
          sendingSOC2ContactUsEmail: false,
        });
      })
      .catch(e => LogError(e));
  }

  private savePaymentMethod() {
    const payment = this.props.payment;
    const paymentMethod = isSome(this.state.pendingPaymentMethod)
      ? this.state.pendingPaymentMethod
      : isSome(payment) && isSome(payment.vantaSubscription)
      ? payment.vantaSubscription.paymentMethod
      : null;

    if (paymentMethod !== "send_invoice" && this.state.editingCC) {
      // need to save credit card first.
      // if saving credit card is successful, savePaymentMethod will be called again.
      this.setState({
        savingCC: true,
      });
      return;
    }

    if (isSome(this.state.pendingPaymentMethod)) {
      this.setState({
        savingPaymentMethod: true,
      });
      this.props
        .updatePaymentMethodMutation({
          variables: {
            paymentMethod: this.state.pendingPaymentMethod,
          },
        })
        .then(() => {
          AppToaster.show({
            icon: "tick",
            intent: Intent.SUCCESS,
            message: "Payment method changed",
            timeout: 2500,
          });
          this.setState({
            savingPaymentMethod: false,
            showingUpdatePaymentMethod: false,
          });
        })
        .catch(e => LogError(e));
    } else {
      this.setState({ showingUpdatePaymentMethod: false });
    }
  }

  private getTotalPrice(vantaSubscription: any) {
    return vantaSubscription.pricesPerPaymentInterval.base ?? 0;
  }

  private renderCreditCardForm() {
    const payment = this.props.payment;
    const linkedCreditCardInfo = isSome(payment)
      ? payment.defaultCreditCard
      : null;

    if (this.state.editingCC) {
      return (
        <StripeProvider apiKey={Config.stripeApiKey}>
          <Elements>
            <ReactStripeForm
              loading={false}
              needsSubmit={this.state.savingCC}
              onStateChange={this.onCCStateChange}
            />
          </Elements>
        </StripeProvider>
      );
    } else {
      return (
        <div>
          {linkedCreditCardInfo ? (
            <span>
              Currently storing a {linkedCreditCardInfo.brand} that ends in{" "}
              {linkedCreditCardInfo.last4}.
            </span>
          ) : (
            <span>No card stored.</span>
          )}
          <DefaultLink
            className="credit-card-form-change-card-link"
            href="javascript:void(0)"
            onClick={this.onRequestCChange}
          >
            Change Card
          </DefaultLink>
        </div>
      );
    }
  }

  private onCCStateChange(loading: boolean, token?: Maybe<string>) {
    this.setState({ pendingPaymentMethod: "charge_automatically" });
    if (isSome(token)) {
      this.props
        .linkCreditCardMutation({
          variables: {
            stripeToken: token,
          },
        })
        .then(() => {
          AppToaster.show({
            icon: "tick",
            intent: Intent.SUCCESS,
            message: "Credit card changed",
            timeout: 2500,
          });
          this.setState(
            { savingCC: false, editingCC: false },
            this.savePaymentMethod
          );
        })
        .catch(e => LogError(e));
    } else if (!loading && this.state.savingCC) {
      this.setState({ savingCC: false });
    }
  }

  private onRequestCChange() {
    this.setState({ editingCC: true });
  }

  private handleBillingEmailChange(e: React.SyntheticEvent<HTMLInputElement>) {
    this.setState({ pendingBillingEmail: e.currentTarget.value });
  }
}

gql`
  mutation linkCreditCard($stripeToken: String!) {
    updateCard(stripeToken: $stripeToken) {
      id
      payment {
        id
        billingEmail
        defaultCreditCard {
          brand
          last4
        }
      }
    }
  }
`;

gql`
  mutation setBillingEmail($email: String!) {
    setBillingEmail(email: $email) {
      id
    }
  }
`;

gql`
  mutation soc2ContactUs {
    soc2ContactUs
  }
`;

gql`
  mutation updatePaymentMethod($paymentMethod: String!) {
    updatePaymentMethod(paymentMethod: $paymentMethod) {
      id
    }
  }
`;

gql`
  query getPaymentInfo {
    organization {
      id
      payment {
        id
        defaultCreditCard {
          brand
          last4
        }
        billingEmail
        vantaSubscription {
          intervalCount
          paymentInterval
          paymentMethod
          billingPeriodEnd
          status
          pricesPerPaymentInterval {
            base
          }
        }
      }
    }
  }
`;

const COMMON_OPTIONS = {
  refetchQueries: [{ query: GetPaymentInfoDocument, variables: {} }],
};

export const ManageBillingPage: React.FC = () => {
  const mutations = {
    linkCreditCardMutation: useLinkCreditCardMutation()[0],
    soc2ContactUsMutation: useSoc2ContactUsMutation()[0],
    setBillingEmailMutation: useSetBillingEmailMutation(COMMON_OPTIONS)[0],
    updatePaymentMethodMutation:
      useUpdatePaymentMethodMutation(COMMON_OPTIONS)[0],
  };
  const { error, loading, data } = useGetPaymentInfoQuery();
  if (error) {
    LogError(error);
    return null;
  }
  if (loading) {
    return <FullPageSpinner />;
  }
  if (!data) {
    LogErrorMessage("Bad fetch");
    return null;
  }

  return (
    <ManageBillingPageInternal
      {...mutations}
      payment={data.organization.payment}
    />
  );
};
