import { NonIdealState } from "@blueprintjs/core";
import {
  HR_SERVICES_SET,
  IDENTITY_PROVIDER_SERVICES_SET,
  SERVICES_WITH_ACCOUNTS,
  SERVICES_WITH_ROLE_GRANTS,
} from "common/base/types/helpers";
import gql from "graphql-tag";
import React, { useState } from "react";
import { Link } from "react-router-dom";

import { DefaultView } from "../../../alpaca/components";
import { LogError } from "../../../errors";
import type { AllAccessInfoQuery } from "../../../gen/components";
import { useAllAccessInfoQuery } from "../../../gen/components";
import { FullPageSpinner } from "../../helpers/FullPageSpinner";
import { GroupsContext } from "../people/groups-context";
import { HrServicesContext } from "../people/hr-services-context";
import { AccessPageWithService } from "./access-page-with-service";
import { BulkLinkAccountsDialog } from "./bulk-link/bulk-link-account-dialog";
import { UnlinkedAccountsCallout } from "./bulk-link/unlinked-accounts-callout";

export type AccountsAccessCredential = NonNullable<
  AllAccessInfoQuery["organization"]
>["credentials"][number];

export type VantaUser = NonNullable<
  AllAccessInfoQuery["organization"]
>["users"][number];

export const AccessPage: React.FC = () => {
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const { error, loading, data } = useAllAccessInfoQuery();
  if (loading) {
    return <FullPageSpinner />;
  }
  if (error || !data?.organization) {
    LogError(
      error ?? new Error("No domain fetched for linked credentials query")
    );
    return null;
  }

  // only some integrations have relevant info- filter out services without accounts
  // unless they have role grants
  const credentials = data.organization.credentials.filter(
    cred =>
      IDENTITY_PROVIDER_SERVICES_SET.has(cred.service) ||
      SERVICES_WITH_ACCOUNTS.has(cred.service) ||
      SERVICES_WITH_ROLE_GRANTS.has(cred.service)
  );
  const linkedHrServices = new Set(
    data.organization.credentials
      .map(credential => credential.service)
      .filter(service => HR_SERVICES_SET.has(service))
  );

  if (credentials.length === 0) {
    return (
      <NonIdealState
        title="No services linked."
        description={`You don't have any services connected that Vanta can manage access for.`}
        action={
          <p>
            {" "}
            Please add a connection on the{" "}
            <Link to="/connections">Connections page</Link> to manage access.
          </p>
        }
      />
    );
  }

  const linkAccountsDialog = (
    <BulkLinkAccountsDialog
      isOpen={dialogIsOpen}
      onClose={() => setDialogIsOpen(false)}
      accounts={data.organization.unlinkedServiceAccounts}
      users={data.organization.users}
    />
  );

  const linkAccountsCallout = (
    <div style={{ marginBottom: "20px" }}>
      <UnlinkedAccountsCallout
        numUnlinked={data.organization.unlinkedServiceAccounts.length}
        onClick={() => setDialogIsOpen(true)}
      />
    </div>
  );

  return (
    <GroupsContext.Provider value={{ groups: data.organization.roles }}>
      <HrServicesContext.Provider value={{ linkedHrServices }}>
        <DefaultView altMinWidth={1360} headerProps={{ title: "Access" }}>
          {linkAccountsDialog}
          {linkAccountsCallout}
          <AccessPageWithService
            credentials={credentials}
            users={data.organization.users}
          />
        </DefaultView>
      </HrServicesContext.Provider>
    </GroupsContext.Provider>
  );
};

gql`
  query allAccessInfo {
    organization {
      id
      displayName
      credentials(includeDisabled: true) {
        id
        isDisabled
        disableCause
        lastUpdated
        metadata
        service
      }
      unlinkedServiceAccounts {
        id
        isActive
        accountName
        accountId
        service
        uniqueId
      }
      users {
        id
        displayName
        email
        familyName
        givenName
        hrUser {
          jobTitle
        }
        imageUrl
        isActive
        isContractor
        isNotHuman
      }
      # get roles in case customer wants to create new users to link to acounts
      roles {
        id
        name
      }
    }
  }
`;
