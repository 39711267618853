import { Button, Divider, Intent } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import moment from "moment";
import React, { useState } from "react";

import { LogError } from "../../errors";
import type { GetNewCustomersQuery } from "../../gen/components";
import {
  GetNewCustomersDocument,
  useDeleteNewCustomerMutation,
  useGetNewCustomersQuery,
} from "../../gen/components";
import { UI_DATE_FORMAT_WITHOUT_TIME } from "../../helpers/common";
import { AppToaster } from "../../helpers/toaster";
import { CancelConfirmDialog } from "../helpers/CancelConfirmDialog";
import { FullPageSpinner } from "../helpers/FullPageSpinner";
import { DataTable } from "../pages/components/data-table";
import { CreateNewCustomerDialog } from "./create-new-customer-dialog";
import { QueryPanelContainer } from "./query-panel-container";
import { SendInvitationDialog } from "./send-invitation-dialog";

type Customer = NonNullable<GetNewCustomersQuery>["newCustomers"][number];

export const NewCustomersPanel: React.FC = () => {
  const { loading, data, error } = useGetNewCustomersQuery();
  const [billingDialogIsOpen, setBillingDialogIsOpen] = useState(false);
  const [inviteDomainName, setInviteDomainName] = useState<Maybe<string>>(null);
  const [customerToDelete, setCustomerToDelete] =
    useState<Maybe<Customer>>(null);

  const [deleteNewCustomer] = useDeleteNewCustomerMutation({
    refetchQueries: [
      {
        query: GetNewCustomersDocument,
      },
    ],
    onCompleted: () => {
      AppToaster.show({
        message: `New customer deleted.`,
        intent: Intent.SUCCESS,
      });
    },
  });

  if (error) {
    LogError(error);
    return <div />;
  }

  if (loading || !data) {
    return <FullPageSpinner />;
  }

  return (
    <QueryPanelContainer>
      <h1>New Customers</h1>
      <Button
        intent={Intent.PRIMARY}
        onClick={() => setBillingDialogIsOpen(true)}
      >
        Create new customer
      </Button>
      {billingDialogIsOpen ? (
        <CreateNewCustomerDialog
          onClose={() => setBillingDialogIsOpen(false)}
        />
      ) : null}
      <Divider />
      <QueryPanelContainer>
        <h3>New Customers Awaiting Invitations</h3>
        <DataTable
          columnOrder={["newCustomer", "createdAt", "sendInvite", "delete"]}
          data={data.newCustomers}
          header={{
            newCustomer: "New customer",
            createdAt: "Created at",
            sendInvite: "Send invite",
            delete: "Delete",
          }}
          createRow={nc => {
            return {
              newCustomer: (
                <div>
                  <strong>{nc.domainName}</strong>
                  {isSome(nc.contactEmail)
                    ? ` (invitation sent to ${nc.contactEmail})`
                    : ""}
                </div>
              ),
              createdAt: isSome(nc.createdAt)
                ? `${moment(nc.createdAt).format(UI_DATE_FORMAT_WITHOUT_TIME)}`
                : "-",
              sendInvite: (
                <Button
                  intent={Intent.PRIMARY}
                  onClick={() => setInviteDomainName(nc.domainName)}
                >
                  {isSome(nc.contactEmail)
                    ? `Send new login invitation`
                    : `Send login invitation`}
                </Button>
              ),
              delete: (
                <Button
                  intent={Intent.DANGER}
                  onClick={() => {
                    setCustomerToDelete(nc);
                  }}
                >
                  Delete
                </Button>
              ),
            };
          }}
        />
        {isSome(inviteDomainName) ? (
          <SendInvitationDialog
            domainName={inviteDomainName}
            onClose={() => setInviteDomainName(null)}
          />
        ) : null}
      </QueryPanelContainer>
      {isSome(customerToDelete) ? (
        <CancelConfirmDialog
          body={<div>Are you sure you want to remove this customer?</div>}
          confirmText="Confirm"
          title="Remove new customer"
          isOpen={true}
          onClose={() => {
            setCustomerToDelete(null);
          }}
          onConfirm={() => {
            deleteNewCustomer({
              variables: {
                domainName: customerToDelete.domainName,
              },
            }).catch(e => {
              AppToaster.show({
                message: e.message,
                intent: Intent.WARNING,
              });
            });
            setCustomerToDelete(null);
          }}
        />
      ) : null}
    </QueryPanelContainer>
  );
};

gql`
  query getNewCustomers {
    newCustomers {
      contactEmail
      domainName
      stripeSubscriptionId
      createdAt
    }
  }
`;

gql`
  mutation deleteNewCustomer($domainName: String!) {
    deleteNewCustomer(domainName: $domainName) {
      domainName
    }
  }
`;
