import { Callout, Tab } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import gql from "graphql-tag";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { BASE_TYPOGRAPHY } from "../../../alpaca/base/typography";
import { BodyText, Tabs } from "../../../alpaca/components";
import { LogError } from "../../../errors";
import { useGetHostEnrollSecretQuery } from "../../../gen/components";
import { CalloutWithClipBoard } from "../../helpers/callout-with-clipboard";
import { FullPageSpinner } from "../../helpers/FullPageSpinner";

const MAC_INSTALL_SCRIPT = (secret: string) =>
  `VANTA_KEY="${secret}" bash -c "$(curl -L https://raw.githubusercontent.com/VantaInc/vanta-agent-scripts/master/install-macos.sh)"`;
const LINUX_INSTALL_SCRIPT = (secret: string) =>
  `VANTA_KEY="${secret}" bash -c "$(curl -L https://raw.githubusercontent.com/VantaInc/vanta-agent-scripts/master/install-linux.sh)"`;
const MAC_INSTALL_SCRIPT_AUTO = (secret: string) =>
  `VANTA_OWNER_EMAIL="NAME@EXAMPLE.COM" ${MAC_INSTALL_SCRIPT(secret)}`;
const LINUX_INSTALL_SCRIPT_AUTO = (
  secret: string
) => `VANTA_OWNER_EMAIL="NAME@EXAMPLE.COM" ${LINUX_INSTALL_SCRIPT(secret)}
`;
const WINDOWS_INSTALL_SCRIPT = (secret: string, debug?: Maybe<boolean>) =>
  `msiexec ${
    debug ? "/l*v vanta.log" : ""
  } /i vanta.msi /passive /qn VANTA_KEY="${secret}" VANTA_OWNER_EMAIL="name@example.com"`;
const AUTO_REG_TAB_IDS = {
  mac: "auto-reg-mac",
  linux: "auto-reg-linux",
  windows: "auto-reg-windows",
};

export const MDMInstructionsTab: React.FC = () => {
  const { error, loading, data } = useGetHostEnrollSecretQuery();
  if (error) {
    LogError(error);
    return null;
  }
  if (loading || !data) {
    return <FullPageSpinner />;
  }

  const secret = data.organization.hostEnrollSecret;
  return (
    <div>
      <FirstMDMInstallInstructionsContainer>
        <FirstMDMInstallInstructionsList>
          <FirstMDMInstallInstructionsListItem as={"li"}>
            Run the following script one time on your MDM provisioned computers.
          </FirstMDMInstallInstructionsListItem>
          <FirstMDMInstallInstructionsListItem as={"li"}>
            After the Agent is installed, assign the computer an account within
            Vanta from the <Link to={"/inventory#mac"}>Inventory page</Link> or
            by having the employee click on the toolbar icon and select
            "Register Vanta Agent."
          </FirstMDMInstallInstructionsListItem>
        </FirstMDMInstallInstructionsList>
        <Tabs
          id="prompt-for-install-tabs"
          defaultSelectedTabId={"prompt-install-mac"}
        >
          <Tab
            id="prompt-install-mac"
            title="Mac"
            panel={<CalloutWithClipBoard text={MAC_INSTALL_SCRIPT(secret)} />}
          />
          <Tab
            id="prompt-install-linux"
            title="Linux"
            panel={<CalloutWithClipBoard text={LINUX_INSTALL_SCRIPT(secret)} />}
          />
        </Tabs>
      </FirstMDMInstallInstructionsContainer>
      <BodyText>
        Alternatively: to automatically register employee computers, capture the
        employee's email address and provide it when running our download and
        register script.
      </BodyText>

      <Tabs id="auto-register-tabs" defaultSelectedTabId={AUTO_REG_TAB_IDS.mac}>
        <Tab
          id={AUTO_REG_TAB_IDS.mac}
          title="Mac"
          panel={
            <CalloutWithClipBoard text={MAC_INSTALL_SCRIPT_AUTO(secret)} />
          }
        />
        <Tab
          id={AUTO_REG_TAB_IDS.linux}
          title="Linux"
          panel={
            <CalloutWithClipBoard text={LINUX_INSTALL_SCRIPT_AUTO(secret)} />
          }
        />
        <Tab
          id={AUTO_REG_TAB_IDS.windows}
          title="Windows"
          panel={
            <ScriptBlock>
              <Comment># Open cmd.exe as an administrator</Comment>
              <Comment># Download the current version of the installer</Comment>
              <Script>
                curl -Lo vanta.msi
                https://app.vanta.com/osquery/download/windows
              </Script>
              <Comment>
                # Run silent installation. In the command below, replace
                "name@example.com" with the employee's email address.
              </Comment>
              <Comment>
                # Note: Don't share this key, it's specific to your organization
              </Comment>
              <Script>{WINDOWS_INSTALL_SCRIPT(secret)}</Script>
              <Comment>
                # If there are any issues, you can get more logs in
                `./vanta.log` by adding the following flags. Please send this
                file to support@vanta.com if you're encountering continual
                installation issues.
              </Comment>
              <Comment># {WINDOWS_INSTALL_SCRIPT(secret, true)}</Comment>
              <Comment># Remove the installer</Comment>
              <Script>del vanta.msi</Script>
            </ScriptBlock>
          }
        />
      </Tabs>
    </div>
  );
};

const FIRST_MDM_INSTALL_INSTRUCTIONS_CONTAINER_STYLES = {
  MARGIN_BOTTOM: 16,
};

const FirstMDMInstallInstructionsContainer = styled.div`
  margin-bottom: ${FIRST_MDM_INSTALL_INSTRUCTIONS_CONTAINER_STYLES.MARGIN_BOTTOM}px;
`;

const FIRST_MDM_INSTALL_INSTRUCTIONS_LIST_STYLES = {
  MARGIN_TOP: 0,
  MARGIN_BOTTOM: 10,
  PADDING_LEFT: 20,
};

const FirstMDMInstallInstructionsList = styled.ol`
  margin-top: ${FIRST_MDM_INSTALL_INSTRUCTIONS_LIST_STYLES.MARGIN_TOP};
  margin-bottom: ${FIRST_MDM_INSTALL_INSTRUCTIONS_LIST_STYLES.MARGIN_BOTTOM}px;
  padding-left: ${FIRST_MDM_INSTALL_INSTRUCTIONS_LIST_STYLES.PADDING_LEFT}px;
`;

const FIRST_MDM_INSTALL_INSTRUCTIONS_LIST_ITEM_STYLES = {
  MARGIN_BOTTOM: 11,
};

const FirstMDMInstallInstructionsListItem = styled(BodyText)`
  margin-bottom: ${FIRST_MDM_INSTALL_INSTRUCTIONS_LIST_ITEM_STYLES.MARGIN_BOTTOM}px;
`;

const ScriptBlock = styled(Callout)`
  font-family: ${BASE_TYPOGRAPHY.FONT_FAMILIES.MONACO};
`;

const Comment = styled.p`
  opacity: 0.6;
`;

const Script = styled.p``;

gql`
  query getHostEnrollSecret {
    organization {
      id
      hostEnrollSecret
    }
  }
`;
