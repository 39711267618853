import { dropNothing } from "common/base/types/maybe";
import React from "react";

import { LogError, LogErrorMessage } from "../../../errors";
import {
  useFetchAzureMonitorInfoQuery,
  useFetchCloudwatchAlarmInfoQuery,
  useFetchDatadogMonitorInfoQuery,
  useFetchGcpMonitorInfoQuery,
} from "../../../gen/components";
import { FullPageSpinner } from "../../helpers/FullPageSpinner";
import {
  PageHeadingInfo,
  VantaDashboardPage,
} from "../../vanta-chrome/page-content/vanta-dashboard-page";
import { DataTable, OverflowTableStyle } from "../components/data-table";
import { awsDataTableParameters } from "./alarms/aws";
import { azureDataTableParameters } from "./alarms/azure";
import { datadogDataTableParameters } from "./alarms/datadog";
import { gcpDataTableParameters } from "./alarms/gcp";
import { Container } from "./helpers";

const MONITOR_SERVICES = ["aws", "datadog", "gcp", "azure"] as const;

const serviceToParameters = {
  aws: {
    dataTableParameters: awsDataTableParameters,
    query: useFetchCloudwatchAlarmInfoQuery,
  },
  gcp: {
    dataTableParameters: gcpDataTableParameters,
    query: useFetchGcpMonitorInfoQuery,
  },
  azure: {
    dataTableParameters: azureDataTableParameters,
    query: useFetchAzureMonitorInfoQuery,
  },
  datadog: {
    dataTableParameters: datadogDataTableParameters,
    query: useFetchDatadogMonitorInfoQuery,
  },
};

export const ManageAlarmsPage: React.FC = () => {
  const tables = MONITOR_SERVICES.map(service => {
    const { error, loading, data } = serviceToParameters[service].query();
    if (error) {
      LogError(error);
      return {
        status: "error",
        table: null,
      };
    }
    if (loading) {
      return {
        status: "loading",
        table: null,
      };
    }
    if (!data) {
      LogErrorMessage("Bad fetch");
      return {
        status: "error",
        table: null,
      };
    }

    const monitorData: any[] = data.organization.alarms.edges.map(e => e.node);
    if (monitorData.length === 0) {
      return {
        status: "success",
        table: null,
      };
    }

    const { columnOrder, displayName, header, createRow } =
      serviceToParameters[service].dataTableParameters;

    return {
      status: "success",
      table: (
        <Container key={service}>
          <h3>{displayName}</h3>
          <DataTable
            key={service}
            columnOrder={columnOrder}
            createRow={createRow}
            data={monitorData}
            header={header}
            tableStyle={OverflowTableStyle}
            emptyDefault={"No data available"}
          />
        </Container>
      ),
    };
  });

  if (!tables.some(t => t.status === "success")) {
    return <FullPageSpinner />;
  }
  return (
    <VantaDashboardPage headingInfo={PageHeadingInfo.ALARMS}>
      {dropNothing(tables.map(t => t.table))}
    </VantaDashboardPage>
  );
};
