import type { IconName } from "@blueprintjs/core";
import { Button, Dialog } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import React from "react";

interface IProps {
  buttonText: string;
  dialogContent: JSX.Element;
  dialogHeader?: Maybe<string>;
  icon: IconName;
  isDialogOpen: boolean;
  buttonOnClick(): void;
  dialogClose(): void;
}

export class DialogWithButton extends React.Component<IProps> {
  public render() {
    return (
      <div>
        <Button icon={this.props.icon} onClick={this.props.buttonOnClick}>
          {this.props.buttonText}
        </Button>
        <Dialog
          icon={this.props.icon}
          title={
            isSome(this.props.dialogHeader)
              ? this.props.dialogHeader
              : this.props.buttonText
          }
          isOpen={this.props.isDialogOpen}
          onClose={this.props.dialogClose}
        >
          {this.props.dialogContent}
        </Dialog>
      </div>
    );
  }
}
