import styled from "styled-components";

import {
  BodyShortText,
  BodyText,
  H1,
  H2,
  H3,
  H4,
  HelperText,
} from "../../alpaca/components";

export const ReportTitle = styled(H1)`
  margin-bottom: 24px;
`;

export const ReportHeading = styled(H2)`
  margin: 10px 0px;
`;
export const ReportContent = styled(BodyShortText)``;

export const ReportCompanyName = styled(HelperText)``;

export const RequirementHeading = styled(H3)`
  font-size: 20px;
  line-height: 24px;
  margin: 10px 0px;
`;

export const ControlHeading = styled(H4)`
  font-size: 18px;
  line-height: 24px;
`;

export const ControlDescription = styled(BodyText)`
  line-height: 18px;
`;

export const ControlListItemDescription = styled(BodyText)`
  line-height: 18px;
`;
