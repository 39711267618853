import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import React from "react";

import { VulnerabilityPageInfo } from "../common/vulnerability-page-info";

interface IProps {
  projectName: string;
  projectId: string;
  source: string;
  imageId?: Maybe<string>;
  imageTag?: Maybe<string>;
  imageBaseImage?: Maybe<string>;
}

export const SnykProjectInfo: React.FC<IProps> = ({
  projectName,
  projectId,
  source,
  imageId,
  imageTag,
  imageBaseImage,
}) => {
  const leftTablePairs = [
    { key: "project name", value: projectName },
    { key: "project id", value: projectId },
    { key: "source", value: source },
  ];
  if (isSome(imageId) && imageId.length > 0) {
    leftTablePairs.push({ key: "image id", value: imageId });
  }
  if (isSome(imageTag) && imageTag.length > 0) {
    leftTablePairs.push({ key: "image tag", value: imageTag });
  }
  if (isSome(imageBaseImage) && imageBaseImage.length > 0) {
    leftTablePairs.push({ key: "base image", value: imageBaseImage });
  }
  return (
    <VulnerabilityPageInfo
      leftTablePairs={leftTablePairs}
      leftColumnWidths={["120px", "600px"]}
    />
  );
};
