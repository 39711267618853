import { isSome } from "common/base/types/maybe";
import React from "react";

import { BodyShortText, H4 } from "../../../alpaca/components";
import { LogError } from "../../../errors";
import type { GetInfoForRiskAssessmentQuery } from "../../../gen/components";
import {
  GetInfoForRiskAssessmentDocument,
  useRrv3AddTaskMutation,
  useRrv3DeleteTaskMutation,
} from "../../../gen/components";
import { TaskEditorPanel } from "../../master-detail-view/detail-panels/task-editor-panel";
import type { RiskAssessmentDomain } from "./queries-and-types";

interface IProps {
  riskCategoryId: string;
  scenario: RiskAssessmentDomain["rrv3RiskScenarios"][number];
}

const TASK_CREATION_COPY =
  "What could you do to make this risk less likely to occur?";

export const RiskTaskEditor: React.FC<IProps> = ({
  riskCategoryId,
  scenario,
}) => {
  const tasks = scenario.tasks;
  const [addTask] = useRrv3AddTaskMutation({
    update: (cache, result) => {
      const taskToAdd = result.data?.rrv3AddTask;
      if (!isSome(taskToAdd)) {
        return;
      }
      const previousData = cache.readQuery<GetInfoForRiskAssessmentQuery>({
        query: GetInfoForRiskAssessmentDocument,
      });
      if (!isSome(previousData) || !isSome(previousData.organization)) {
        return;
      }

      cache.writeQuery<GetInfoForRiskAssessmentQuery>({
        query: GetInfoForRiskAssessmentDocument,
        data: {
          organization: {
            ...previousData.organization,
            rrv3RiskScenarios: previousData.organization.rrv3RiskScenarios.map(
              s => {
                if (s.id === scenario.id) {
                  return {
                    ...s,
                    tasks: s.tasks.concat(taskToAdd),
                  };
                } else {
                  return s;
                }
              }
            ),
          },
        },
      });
    },
  });
  const [removeTask] = useRrv3DeleteTaskMutation({
    update: (cache, result) => {
      const taskToDelete = result.data?.rrv3DeleteTask;
      if (!isSome(taskToDelete)) {
        return;
      }
      const previousData = cache.readQuery<GetInfoForRiskAssessmentQuery>({
        query: GetInfoForRiskAssessmentDocument,
      });
      if (!isSome(previousData) || !isSome(previousData.organization)) {
        return;
      }

      cache.writeQuery<GetInfoForRiskAssessmentQuery>({
        query: GetInfoForRiskAssessmentDocument,
        data: {
          organization: {
            ...previousData.organization,
            rrv3RiskScenarios: previousData.organization.rrv3RiskScenarios.map(
              s => {
                if (s.id === scenario.id) {
                  return {
                    ...s,
                    tasks: s.tasks.filter(t => t.id !== taskToDelete.id),
                  };
                } else {
                  return s;
                }
              }
            ),
          },
        },
      });
    },
  });
  return (
    <TaskEditorPanel
      assigneeRequired={true}
      tasks={tasks}
      textContent={
        <>
          <H4>{TASK_CREATION_COPY}</H4>
          <BodyShortText>{scenario.description}</BodyShortText>
        </>
      }
      onCreateTask={(description, assignee, dueDate) => {
        if (isSome(dueDate)) {
          addTask({
            variables: {
              scenarioId: scenario.id,
              description,
              assigneeId: assignee!,
              dueDate: dueDate.toISOString(),
            },
          }).catch(LogError);
        }
      }}
      onDeleteTask={taskId => {
        removeTask({ variables: { taskId } }).catch(LogError);
      }}
    />
  );
};
