import { Spinner } from "@blueprintjs/core";
import { dateStringToDate, toPossibleDate } from "common/base/dateUtils";
import type { Service } from "common/base/types/helpers";
import {
  IDENTITY_PROVIDER_SERVICES_SET,
  HR_SERVICES_SET,
  SERVICES_WITH_ACCOUNTS,
  serviceToDisplayName,
} from "common/base/types/helpers";
import type { Maybe } from "common/base/types/maybe";
import { nothing, isSome } from "common/base/types/maybe";
import moment from "moment";
import React from "react";
import { Redirect } from "react-router";
import styled from "styled-components";

import { GRID_SPACING } from "../../../../alpaca/base/grid";
import { BASE_TYPOGRAPHY } from "../../../../alpaca/base/typography";
import {
  BodyShortText,
  BodyText,
  Button,
  Icon,
  IconNames,
  Tooltip,
} from "../../../../alpaca/components";
import { LogError } from "../../../../errors";
import type { GetUserInfoForOffboardingQuery } from "../../../../gen/components";
import {
  GetUserInfoForOffboardingDocument,
  useBulkAcknowledgeNoVendorAccessMutation,
  useCompleteOffboardingMutation,
  useGetUserInfoForOffboardingQuery,
} from "../../../../gen/components";
import { UI_DATE_FORMAT_WITHOUT_TIME } from "../../../../helpers/common";
import { DATE_FORMAT_FULL_MONTH } from "../shared/common";
import { InfoIcon } from "../shared/info-icon";
import { asDateOnlyDate } from "../utils";
import type { LinkedAccount } from "./offboard-vendor-display";
import { OffboardVendorDisplay } from "./offboard-vendor-display";
import type { OffboardingUser, Vendor } from "./offboarding-types";

interface IProps {
  userId: string;
}

const SEVERITY_ORDERING = ["high", "medium", "low"];

export const OffboardingListView: React.FC<IProps> = ({ userId }) => {
  const [completeOffboarding, mutationResult] = useCompleteOffboardingMutation({
    variables: { employeeId: userId },
    update: (cache, result) => {
      const userToUpdate = result.data?.acknowledgeOffboardingComplete;
      if (!isSome(userToUpdate)) {
        return;
      }
      const prevData = cache.readQuery<GetUserInfoForOffboardingQuery>({
        query: GetUserInfoForOffboardingDocument,
        variables: { userId },
      });
      const user = prevData?.user;
      const domain = prevData?.organization;
      if (!isSome(user) || !isSome(domain)) {
        return;
      }
      cache.writeQuery<GetUserInfoForOffboardingQuery>({
        query: GetUserInfoForOffboardingDocument,
        variables: { userId },
        data: {
          organization: domain,
          user: {
            ...user,
            ...userToUpdate,
          },
        },
      });
    },
  });
  const [bulkOffboard] = useBulkAcknowledgeNoVendorAccessMutation({
    update: (cache, result) => {
      const newAccessRemovals =
        result.data?.acknowledgeNoVendorAccessForOffboardingBulk;
      if (!isSome(newAccessRemovals)) {
        return;
      }
      const oldData = cache.readQuery<GetUserInfoForOffboardingQuery>({
        query: GetUserInfoForOffboardingDocument,
        variables: { userId },
      });
      const user = oldData?.user;
      const organization = oldData?.organization;
      if (!isSome(organization) || !isSome(user)) {
        return;
      }
      cache.writeQuery<GetUserInfoForOffboardingQuery>({
        query: GetUserInfoForOffboardingDocument,
        data: {
          organization,
          user: {
            ...user,
            vendorAccessRemovals:
              user.vendorAccessRemovals.concat(newAccessRemovals),
          },
        },
      });
    },
  });
  const { loading, data } = useGetUserInfoForOffboardingQuery({
    variables: { userId },
  });
  if (loading || !data) {
    return <Spinner size={Spinner.SIZE_LARGE} />;
  }
  if (!data.user) {
    return <Redirect to={window.location.pathname} />;
  }

  const { user, organization } = data;

  const convertedEndDate = isSome(user.endDate)
    ? asDateOnlyDate(
        dateStringToDate(user.endDate),
        isSome(user.hrUser?.endDate)
      )
    : nothing;

  let relevantVendors = organization.vendors.map(vendorMapper);
  const accessRemovalsByVendorId = new Map(
    user.vendorAccessRemovals.map(accessRemoval => [
      accessRemoval.vendorId,
      accessRemoval,
    ])
  );
  // For offboarded users, don't show vendors that were added after
  // offboarding was completed.
  if (isSome(user.offboardedDate)) {
    relevantVendors = relevantVendors.filter(
      vendor =>
        isSome(vendor.associatedCredential) ||
        isSome(accessRemovalsByVendorId.get(vendor.id)) ||
        vendor.name === "Vanta"
    );
  }

  const serviceAccounts = getLinkedServiceAccounts(user);

  const offboardStatus = getOffboardStatus({
    accessRemovalsByVendorId,
    linkedAccounts: serviceAccounts,
    user,
    vendors: relevantVendors,
  });

  // Don't show list for users removed long before we had this feature
  if (offboardStatus.reason === Reason.OFFBOARDING_NOT_NEEDED) {
    return (
      <FlexWithSpacing>
        <BodyShortText as="div">
          This person was removed{" "}
          {convertedEndDate
            ? moment(convertedEndDate).format(UI_DATE_FORMAT_WITHOUT_TIME)
            : ""}
        </BodyShortText>
        <Tooltip
          content={
            <TooltipContent>
              Vanta does not track offboarding for people who were removed prior
              to June 16, 2020 or prior to being added to Vanta.
            </TooltipContent>
          }
          placement="bottom-end"
        >
          <Icon icon={IconNames.INFO} />
        </Tooltip>
      </FlexWithSpacing>
    );
  }

  const vendorMapBySeverity = relevantVendors.reduce<{
    [k: string]: Maybe<Vendor[]>;
  }>((map, vendor) => {
    map[vendor.severity] = (map[vendor.severity] ?? []).concat(vendor);
    return map;
  }, {});

  return (
    <Container>
      {offboardStatus.reason === Reason.ALREADY_OFFBOARDED ? (
        <BodyShortText fontWeight={BASE_TYPOGRAPHY.FONT_WEIGHTS.BOLD}>
          Completed offboarding on{" "}
          {moment(user.offboardedDate!).format(DATE_FORMAT_FULL_MONTH)}
        </BodyShortText>
      ) : null}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: `${2 * GRID_SPACING}px`,
        }}
      >
        <div style={{ display: "flex" }}>
          <BodyText
            style={{ marginBottom: "0px", marginRight: `${GRID_SPACING}px` }}
            lineHeight={"14px"}
          >
            Person was removed{" "}
            {convertedEndDate
              ? moment(convertedEndDate).format(DATE_FORMAT_FULL_MONTH)
              : ""}
          </BodyText>
          <InfoIcon
            tooltipContent={
              isSome(user.hrUser)
                ? `Terminated in ${serviceToDisplayName(
                    user.hrUser.service as Service
                  )}`
                : isSome(user.idp)
                ? `Terminated in ${serviceToDisplayName(user.idp.service)}`
                : "Terminated in Vanta"
            }
          />
        </div>
        {offboardStatus.reason === Reason.ALREADY_OFFBOARDED ? null : (
          <Button
            disabled={!offboardStatus.canOffboard}
            loading={mutationResult.called}
            onClick={() => {
              completeOffboarding().catch(LogError);
            }}
            tooltipContent={
              offboardStatus.reason === Reason.VENDORS_ACTIVE
                ? "This person has active vendor accounts. Please deactivate those accounts to continue offboarding."
                : "Invalid state. Please contact Support."
            }
          >
            Confirm completion
          </Button>
        )}
      </div>
      {SEVERITY_ORDERING.map(severity => {
        const vendors = vendorMapBySeverity[severity] ?? [];
        if (vendors.length === 0) {
          return null;
        }

        /**
         * Places vendors with linked account before vendors that need to
         * be manually acknowledged of access removal.
         */
        const sortedVendors = vendors.sort((v1, v2) => {
          const credentialCompare =
            (isSome(v1.associatedCredential) ? 0 : 1) -
            (isSome(v2.associatedCredential) ? 0 : 1);
          if (credentialCompare !== 0) {
            return credentialCompare;
          } else {
            return v1.name.localeCompare(v2.name);
          }
        });

        const unacknowledgedVendors = vendors
          .filter(
            vendor =>
              !isSome(vendor.associatedCredential) &&
              !isSome(accessRemovalsByVendorId.get(vendor.id))
          )
          .map(vendor => vendor.id);

        const bulkOffboardSection = async () => {
          await bulkOffboard({
            variables: { employeeId: userId, vendorIds: unacknowledgedVendors },
          });
        };

        return (
          <div
            key={`vendor-sev-${severity}`}
            style={{ marginBottom: `${styles.SECTION_MARGIN_BOTTOM}px` }}
          >
            <BodyText
              style={{
                marginBottom: `${styles.VENDOR_HEADING_MARGIN_BOTTOM}px`,
              }}
              fontWeight={BASE_TYPOGRAPHY.FONT_WEIGHTS.BOLD}
            >
              {severity[0].toUpperCase() + severity.slice(1)} risk
            </BodyText>
            {sortedVendors.map(vendor => {
              if (!isSome(vendor.associatedCredential)) {
                return (
                  <TaskContainer>
                    <OffboardVendorDisplay
                      accessRemoval={accessRemovalsByVendorId.get(vendor.id)}
                      employee={user}
                      vendor={vendor}
                    />
                  </TaskContainer>
                );
              }

              const maybeAccounts: Array<Maybe<LinkedAccount>> =
                serviceAccounts.filter(
                  a => a.service === vendor.associatedCredential
                );

              // This conditional populates the checklist with green checks
              // in the case of a vendor for which we pull account data, and
              // the offboarding employee has no account.
              if (maybeAccounts.length === 0) {
                maybeAccounts.push(null);
              }

              return (
                <React.Fragment key={vendor.id}>
                  {maybeAccounts.map((maybeAccount, index) => (
                    <TaskContainer key={`${vendor.id}_${index}`}>
                      <OffboardVendorDisplay
                        employee={user}
                        vendor={vendor}
                        linkedAccount={maybeAccount}
                      />
                    </TaskContainer>
                  ))}
                </React.Fragment>
              );
            })}

            {unacknowledgedVendors.length > 1 ? (
              <div
                style={{
                  marginTop: `${styles.BULK_BUTTON_MARGIN_TOP}px`,
                  marginBottom: `${styles.BULK_BUTTON_MARGIN_BOTTOM}px`,
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-around",
                }}
              >
                <Button onClick={async () => bulkOffboardSection()}>
                  Mark {severity}-risk deprovisioned
                </Button>
              </div>
            ) : null}
          </div>
        );
      })}
    </Container>
  );
};

function getLinkedServiceAccounts(user: OffboardingUser): LinkedAccount[] {
  const serviceAccounts: LinkedAccount[] = [...user.serviceAccounts].map(
    account => {
      return {
        ...account,
        deactivationDate: toPossibleDate(account.deactivationDate),
      };
    }
  );
  if (isSome(user.idp)) {
    serviceAccounts.push({
      deactivationDate: toPossibleDate(user.idp.deactivatedAt),
      service: user.idp.service,
    });
  }
  return serviceAccounts;
}

const styles = {
  PROFILE_IMAGE_DIMENSION: 180,
  PROFILE_IMAGE_MARGIN_BOTTOM: 24,
  HEADING_MARGIN_BOTTOM: 24,
  TASK_MARGIN_BOTTOM: GRID_SPACING,
  VENDOR_HEADING_MARGIN_BOTTOM: 12,
  SVG_SIZE: 30,
  INFO_ICON_SIZE: 10,
  SLA_TEXT_PADDING_TOP: 4,
  SLA_TEXT_PADDING_BOTTOM: 20,
  SECTION_MARGIN_BOTTOM: 3 * GRID_SPACING,
  BULK_BUTTON_MARGIN_TOP: 2 * GRID_SPACING,
  BULK_BUTTON_MARGIN_BOTTOM: GRID_SPACING,
};

const TaskContainer = styled.div`
  margin-bottom: ${styles.TASK_MARGIN_BOTTOM}px;
`;

const Container = styled.div`
  background-color: white;
  padding-bottom: 0;
`;

const TooltipContent = styled.span`
  display: block;
  max-width: 350px;
`;

const FlexWithSpacing = styled.div`
  display: flex;
  align-items: center;
  & > *:last-child {
    margin-left: 8px;
  }
`;

// Treats vendors from linked services for which we don't fetch accounts
// as vendor to manually acknowledge as offboarded.
const vendorMapper: (vendor: Vendor) => Vendor = vendor => {
  if (
    isSome(vendor.associatedCredential) &&
    !HR_SERVICES_SET.has(vendor.associatedCredential) &&
    !IDENTITY_PROVIDER_SERVICES_SET.has(vendor.associatedCredential) &&
    !SERVICES_WITH_ACCOUNTS.has(vendor.associatedCredential)
  ) {
    return { ...vendor, associatedCredential: null };
  }
  return vendor;
};

enum Reason {
  ALLOWED,
  ALREADY_OFFBOARDED,
  OFFBOARDING_NOT_NEEDED,
  VENDORS_ACTIVE,
}

function getOffboardStatus(options: {
  accessRemovalsByVendorId: Map<
    string,
    OffboardingUser["vendorAccessRemovals"][0]
  >;
  linkedAccounts: LinkedAccount[];
  user: OffboardingUser;
  vendors: Vendor[];
}): { canOffboard: boolean; reason: Reason } {
  const { accessRemovalsByVendorId, linkedAccounts, user, vendors } = options;
  if (isSome(user.offboardedDate)) {
    return {
      canOffboard: false,
      reason: Reason.ALREADY_OFFBOARDED,
    };
  }

  if (!user.requiresOffboarding) {
    return {
      canOffboard: false,
      reason: Reason.OFFBOARDING_NOT_NEEDED,
    };
  }

  const vendorsRemoved = vendors.every(vendor => {
    if (isSome(vendor.associatedCredential)) {
      const accounts = linkedAccounts.filter(
        a => a.service === vendor.associatedCredential
      );
      return (
        accounts.length === 0 || accounts.every(a => isSome(a.deactivationDate))
      );
    } else {
      // Vanta access is revoked when the user is removed, so treat the
      // Vanta vendor as automatically de-provisioned.
      return (
        isSome(accessRemovalsByVendorId.get(vendor.id)) ||
        vendor.name === "Vanta"
      );
    }
  });

  if (!vendorsRemoved) {
    return { canOffboard: false, reason: Reason.VENDORS_ACTIVE };
  }

  return { canOffboard: true, reason: Reason.ALLOWED };
}
