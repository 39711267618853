import "./raw-data-component.scss";

import {
  Button,
  Card,
  Divider,
  Elevation,
  H5,
  Icon,
  Intent,
} from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import React from "react";

import { writeToClipboard } from "../../helpers";
import { AppToaster } from "../../helpers/toaster";

interface IProps {
  title: string;
  json: any;
  loading?: Maybe<boolean>;
}

interface IState {
  expandState: Set<string>;
}

const DEFAULT_COLLAPSE_DEPTH = 6;

export class RawDataComponent extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
    this.state = {
      expandState: new Set<string>(),
    };
    this.onExpand = this.onExpand.bind(this);
  }

  public render() {
    return (
      <div>
        <Card elevation={Elevation.TWO}>
          <H5>{this.props.title}</H5>
          <Divider />
          {this.renderJsonData(this.props.json, "root")}
        </Card>
        {this.renderCopyJson()}
      </div>
    );
  }

  private onExpand(e: React.SyntheticEvent<HTMLElement>) {
    const elem = e.currentTarget;
    const expandState = new Set(this.state.expandState).add(elem.id);
    this.setState({
      expandState,
    });
  }

  private renderCopyJson() {
    const onClickCopyToClipboard = async () => {
      if (!this.props.json || this.props.loading) {
        AppToaster.show({
          icon: !this.props.json ? "warning-sign" : "time",
          intent: Intent.WARNING,
          message: `Nothing to copy: ${
            !this.props.json ? "data is undefined." : "waiting for data."
          }`,
          timeout: 1000,
        });
        return;
      }
      const data = JSON.stringify(this.props.json);
      await writeToClipboard(data);
    };
    return (
      <Button
        type="button"
        intent={Intent.PRIMARY}
        loading={this.props.loading ?? undefined}
        rightIcon={"clipboard"}
        onClick={onClickCopyToClipboard}
      >
        Copy JSON
      </Button>
    );
  }

  private renderStringifiedData(
    json: any,
    collapseKey: string
  ): Maybe<JSX.Element> {
    if (!isSome(json)) {
      return <div>null</div>;
    }
    return (
      <div id={collapseKey} onClick={this.onExpand}>
        <Icon icon="expand-all" intent={Intent.PRIMARY} />
        <span className="object-value">{JSON.stringify(json, null, 2)}</span>
      </div>
    );
  }

  private renderJsonData(
    json: any,
    collapseKey: string,
    inDepth?: Maybe<number>
  ): Maybe<JSX.Element> {
    if (!isSome(json)) {
      return <div>null</div>;
    }

    const depth = !isSome(inDepth) ? 0 : inDepth;

    switch (typeof json) {
      case "boolean":
      case "string":
      case "number":
        return <div>{json.toString()}</div>;

      default:
    }

    if (
      depth >= DEFAULT_COLLAPSE_DEPTH &&
      !this.state.expandState.has(collapseKey) &&
      Object.keys(json).length > 0
    ) {
      return (
        <div id={collapseKey} className="collapse" onClick={this.onExpand}>
          <Icon icon="expand-all" intent={Intent.PRIMARY} />
          {" ..."}
        </div>
      );
    }

    if (json.constructor === Array) {
      const arrayElems = (json as any[]).map((elem: object, idx) => (
        <div className="array-elem" key={idx}>
          {this.renderJsonData(elem, `${collapseKey}.${idx}`, depth + 1)}
        </div>
      ));

      return (
        <div id={collapseKey}>
          {"["}
          {arrayElems}
          {"]"}
        </div>
      );
    }

    const displayValue = (key: string) => {
      /* Stringify data, because the field can be large and may freeze the page if enumerated */
      if (
        key === "data" &&
        !this.state.expandState.has(collapseKey) &&
        Object.keys(json[key]).length !== 0
      ) {
        return this.renderStringifiedData(json[key], collapseKey);
      } else {
        return this.renderJsonData(
          json[key],
          `${collapseKey}.${key}`,
          depth + 1
        );
      }
    };

    const elems = Object.keys(json).map((key, idx) => (
      <div key={idx}>
        <div className="object-key">{key} </div>
        <div className="object-value">{displayValue(key)}</div>
      </div>
    ));

    return (
      <div id={collapseKey}>
        {"{"}
        {elems}
        {"}"}
      </div>
    );
  }
}
