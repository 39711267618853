import React from "react";
import styled from "styled-components";

interface IProps {
  masterElement: React.ReactNode;
  detailElement: React.ReactNode;
  footerElement: React.ReactNode;

  // eslint-disable-next-line vanta/optional-always-maybe, vanta/prefer-maybe
  className?: string | undefined;
}

export const MasterDetailView: React.FC<IProps> = ({
  masterElement,
  detailElement,
  footerElement,
  className,
}) => (
  <Container className={className}>
    <MasterDetailContainer>
      <MasterViewContainer>{masterElement}</MasterViewContainer>
      <DetailViewContainer>{detailElement}</DetailViewContainer>
    </MasterDetailContainer>
    <FooterContainer>{footerElement}</FooterContainer>
  </Container>
);

const styles = {
  MASTER_VIEW_WIDTH: 320,
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const MasterDetailContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  position: relative;
`;

const MasterViewContainer = styled.div`
  width: ${styles.MASTER_VIEW_WIDTH}px;
  flex-shrink: 0;
  flex-grow: 0;
`;

const DetailViewContainer = styled.div`
  flex-grow: 1;
`;

const FooterContainer = styled.div`
  flex-shrink: 0;
  flex-grow: 0;
`;
