import "./vanta-report-appendices.scss";

import { GENERIC_REPORT_DATA } from "common/standards/STARTER_REPORT_DATA";
import React from "react";

import { MarkdownRenderer } from "../../../../pages/components/markdown-renderer";
import { ReportContent, ReportHeading } from "../../../common";
import type { IReportProps } from "../../../vanta-report-page";

export const VantaReportAppendices: React.FunctionComponent<IReportProps> =
  () => {
    const appendices = Object.values(GENERIC_REPORT_DATA.APPENDICES).map(
      appendix => (
        <div key={appendix.id} id={appendix.name} className="vrp-appendix">
          <ReportHeading>{appendix.name}</ReportHeading>
          <ReportContent>{MarkdownRenderer(appendix.text)}</ReportContent>
        </div>
      )
    );
    return <div className="vrp-appendices-list">{appendices}</div>;
  };
