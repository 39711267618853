import { Tag } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import React from "react";
import styled from "styled-components";

import { BASE_PALETTE } from "../../../../alpaca/base/colors";
import { GRID_SPACING } from "../../../../alpaca/base/grid";
import { Tooltip } from "../../../../alpaca/components";
import { Ellipsify } from "../../../../helpers/ellipsify";

interface IProps {
  tags: Array<string | JSX.Element>;
  numTagsBeforeOverflow: number;
  maxTagWidth?: Maybe<number>;
}

export const TagList: React.FC<IProps> = ({
  tags,
  numTagsBeforeOverflow,
  maxTagWidth,
}) => {
  const tagsToShow = tags.slice(0, numTagsBeforeOverflow);
  const numOverflowTags = tags.length - numTagsBeforeOverflow;
  const overflowTags = tags.slice(numTagsBeforeOverflow);
  const overflowTooltipContent = (
    <StyledOverflowTooltipDiv>
      {overflowTags.map((tag, index) => (
        <div key={`overflow-tag-${index}`}>{tag}</div>
      ))}
    </StyledOverflowTooltipDiv>
  );

  return (
    <StyledTagsContainerDiv>
      {tagsToShow.map((tag, index) => (
        <StyledTag key={`${tag}-${index}`} maxWidth={maxTagWidth}>
          {typeof tag === "string" ? <Ellipsify text={tag} /> : tag}
        </StyledTag>
      ))}
      {numOverflowTags > 0 ? (
        <Tooltip
          content={overflowTooltipContent}
          interactionKind="hover"
          hoverCloseDelay={200}
        >
          <StyledTag maxWidth={maxTagWidth} style={{ flexShrink: 0 }}>
            +{numOverflowTags}
          </StyledTag>
        </Tooltip>
      ) : null}
    </StyledTagsContainerDiv>
  );
};

const StyledTagsContainerDiv = styled.div`
  display: flex;
  align-items: center;
  & > *:not(:first-child) {
    margin-left: ${GRID_SPACING}px;
  }
`;

const StyledTag = styled(Tag).attrs({ minimal: true })<{
  maxWidth?: Maybe<number>;
}>`
  height: ${GRID_SPACING * 3}px;
  ${({ maxWidth }) => (isSome(maxWidth) ? `max-width: ${maxWidth}px` : "")}
`;

const StyledOverflowTooltipDiv = styled.div`
  // Child spans of passed in elements may have different color, so
  // override the child's styling when in overflow tooltip
  & span {
    color: ${BASE_PALETTE.SNOW};
  }
`;
