import { getImmutableSet } from "../../utils/immutable-set";
import type { Maybe, Nothing } from "./maybe";
import { dropNothing, isSome, nothing } from "./maybe";

// unwrap promise type
export type Await<T> = T extends PromiseLike<infer U> ? Await<U> : T;

/**
 * isKeyOf checks if key is a key of obj, and if so it gets the type keyof T
 */
export const isKeyOf = <T>(key: any, obj: T): key is keyof T => key in obj;

// Takes a request and returns
//   vantaUserId: the ID of the assuming user, otherwise null.
//   userId: the ID of the user if it was not assumed, otherwise null.
//   domainId: the domainId associated with the request.
export const getActingUserInfo = (req: any) => {
  const vantaUserId: Maybe<string> = req.session?.preAssumedUserId;
  const domainId: string = req.authInfo.user.domainId;
  const userId: Maybe<string> = isSome(vantaUserId)
    ? undefined
    : req.authInfo.user.id;
  return { vantaUserId, userId, domainId };
};

// Do not change this value unless you want everyone's AWS credentials to get unlinked :)
export const VANTA_CONSTANT = "VANTA";

export enum TEXT_MIME_TYPES {
  CSV = "text/csv",
  MD = "plain/markdown",
  JSON = "application/json",
  XLS = "application/vnd.ms-excel",
}

export enum BINARY_MIME_TYPES {
  BMP = "image/bmp",
  DOCX = "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  JPG = "image/jpeg",
  PDF = "application/pdf",
  PNG = "image/png",
  WEBP = "image/webp",
  XLSX = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ZIP = "application/zip",
}

export type MIME_TYPES = BINARY_MIME_TYPES | TEXT_MIME_TYPES;

export const MIME_TYPE_FILE_EXTENSIONS: {
  [k in MIME_TYPES]: string;
} = {
  "application/json": "json",
  "application/pdf": "pdf",
  "application/vnd.ms-excel": "xls",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xlsx",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    "docx",
  "application/zip": "zip",
  "image/bmp": "bmp",
  "image/jpeg": "jpg",
  "image/png": "png",
  "image/webp": "webp",
  "plain/markdown": "md",
  "text/csv": "csv",
};

/**
 * mimeTypesToAcceptProp converts an array of acceptable MIME types into a
 * single string for use as the accept prop on a JSX file input.
 */
export function mimeTypesToAcceptProp(mimeTypes: MIME_TYPES[]): string {
  return [
    ...mimeTypes,
    ...mimeTypes.map(mt => MIME_TYPE_FILE_EXTENSIONS[mt]),
  ].join(",");
}

export const ALLOWED_RENDER_MIME_TYPES = [
  BINARY_MIME_TYPES.JPG,
  BINARY_MIME_TYPES.PNG,
  BINARY_MIME_TYPES.PDF,
  BINARY_MIME_TYPES.WEBP,
  BINARY_MIME_TYPES.BMP,
  // currently not accepting text/plain or application/octet-stream
  // because the server may infer a different mime type and try to render
  // that. We want to be 100% sure that any file uploaded can be displayed.
];

export const ALLOWED_EVIDENCE_MIME_TYPES = [
  TEXT_MIME_TYPES.CSV,
  TEXT_MIME_TYPES.XLS,
  BINARY_MIME_TYPES.DOCX,
  BINARY_MIME_TYPES.JPG,
  BINARY_MIME_TYPES.PDF,
  BINARY_MIME_TYPES.PNG,
  BINARY_MIME_TYPES.WEBP,
  BINARY_MIME_TYPES.XLSX,
  BINARY_MIME_TYPES.ZIP,
];

export const IMAGE_MIME_TYPES = [
  BINARY_MIME_TYPES.JPG,
  BINARY_MIME_TYPES.PNG,
  BINARY_MIME_TYPES.WEBP,
];

/**
 * POLICY_RENDERABLE_MIME_TYPES were those logo MIME types that we could render
 * in a policy PDF generated with Pandoc.
 *
 * We no longer use Pandoc or render images in policies, but without a compelling
 * reason to change this decided to leave as-is
 */
export const POLICY_RENDERABLE_MIME_TYPES = [
  BINARY_MIME_TYPES.JPG,
  BINARY_MIME_TYPES.PNG,
  // No support for WEBP, which would require intermediate decoding.
  // No support for PDF because it can't be rendered as a logo on app.vanta.com.
  // No support for BMP: causes 'LaTeX Error: Unknown graphics extension: .bmp.'
];

// encryptionType needs to reserve returning null for osqueries that haven't sent
// encryption info yet, so we need a string value that means unencrypted
export const UNENCRYPTED = "__NONE__";

// Object.keys() returns string[], because of the discussion here:
// https://github.com/microsoft/TypeScript/pull/12253#issuecomment-263132208
// but sometimes it's really useful to have a typed Object.keys
export const TypedObjectKeys = <T>(obj: T) =>
  Object.keys(obj) as Array<keyof T>;

// TypedObjectValues behaves like TypedObjectKeys, but it's useful for typing
// schemas with string enums specifically: the enum keys should be changeable
// identifiers.
export const TypedObjectValues = <T>(obj: T) =>
  Object.values(obj) as Array<T[keyof T]>;

export const IDENTITY_PROVIDER_SERVICES = [
  "gsuiteadmin",
  "okta",
  "office",
] as const;

export const IDENTITY_PROVIDER_SERVICES_SET = getImmutableSet<string>(
  IDENTITY_PROVIDER_SERVICES
);

export type IdentityProviderService = typeof IDENTITY_PROVIDER_SERVICES[number];

export const USER_LISTS: Array<{
  service: IdentityProviderService;
  idField: string;
}> = [
  { service: "gsuiteadmin", idField: "gsuiteId" }, // Google Workspace in UI
  { service: "okta", idField: "oktaId" },
  { service: "office", idField: "microsoftOfficeId" },
];
export const USER_LIST_ID_FIELDS = USER_LISTS.map(u => u.idField);

export const BACKGROUND_CHECK_SERVICES_WITH_CUSTOMER_CREDENTIALS = [
  "certn",
  "customercheckr",
  "vetty",
] as const;

export const INFRA_SERVICES = [
  "aws",
  "azure",
  "digitalocean",
  "digitaloceanspaces",
  "gcp",
  "heroku",
] as const;

export const FINCH_SERVICES = [
  "adp_run",
  "adp_workforce_now",
  "insperity",
  "justworks",
  "namely",
  "paychex_flex",
  "paycor",
  "paylocity",
  "quickbooks",
  "square_payroll",
  "trinet",
  "zenefits",
] as const;

export const HR_SERVICES = [
  "bamboo_hr",
  "gusto",
  "rippling",
  ...FINCH_SERVICES,
] as const;

export const HR_SERVICES_SET = getImmutableSet<string>(HR_SERVICES);

export const VERSION_CONTROL_SERVICES = [
  "azuredevops",
  "bitbucket",
  "github",
  "gitlab",
] as const;

export const MDM_SERVICES = [
  "jamf",
  "kandji",
  "microsoft_endpoint_manager",
] as const;

export const TASK_TRACKERS = [
  "airtable",
  "asana",
  "clickup",
  "clubhouse",
  "jira",
  "linear",
  "pivotaltracker",
  "trello",
] as const;

// external services
export const SERVICES_WITH_CUSTOMER_CREDENTIALS = [
  "datadog",
  "knowbe4",
  "mongoatlas",
  "slack",
  "snyk",
  ...HR_SERVICES,
  ...MDM_SERVICES,
  ...IDENTITY_PROVIDER_SERVICES,
  ...BACKGROUND_CHECK_SERVICES_WITH_CUSTOMER_CREDENTIALS,
  ...INFRA_SERVICES,
  ...VERSION_CONTROL_SERVICES,
  ...TASK_TRACKERS,
] as const;

export function isServiceWithCustomerCredentials(
  service: string
): service is typeof SERVICES_WITH_CUSTOMER_CREDENTIALS[number] {
  return SERVICES_WITH_CUSTOMER_CREDENTIALS.some(
    validService => service === validService
  );
}

// used for offboarding and the access page
export const SERVICES_WITH_ROLE_GRANTS = getImmutableSet(["azure", "gcp"]);

export const SERVICES_WITH_ACCOUNTS = getImmutableSet([
  ...VERSION_CONTROL_SERVICES,
  "asana",
  "aws",
  "clickup",
  "clubhouse",
  "datadog",
  "heroku",
  "jamf",
  "jira",
  "knowbe4",
  "linear",
  "mongoatlas",
  "pivotaltracker",
  "slack",
  "snyk",
  "trello",
]);

// rename to googledrivebackgroundchecks when this is unified
export const SERVICES_WITHOUT_CUSTOMER_CREDENTIALS = [
  "backgroundChecks",
  "checkr",
  "documents",
  "EMPTY",
  "inventoryList",
  "nvd",
  "packageChecker",
  "ssl",
] as const;
/*
List of existing places where we define services without customer credentials
common fetch-credentials.ts
*/

// This is the master list of services from which we're pulling resources.
// We have several other services lists throughout the codebase, but once we move
// everything to resource-fetcher, this will be the real list.
export const SERVICES = [
  ...SERVICES_WITH_CUSTOMER_CREDENTIALS,
  ...SERVICES_WITHOUT_CUSTOMER_CREDENTIALS,
];

/*
List of existing places where we define services
common          testResults.ts
*/

export type Service = typeof SERVICES[number];

export function isService(service: string): service is Service {
  return SERVICES.some(validService => service === validService);
}

/**
 * serviceToDisplayName takes a common service identifier (lower-case, no
 * spaces or punctuation) and returns a string corresponding to how that
 * service should be represented in text.
 *
 * The default behavior is to capitalize the first character in the service ID.
 * Any service that deviates from the default behavior (because we refer to it
 * by an acronym; because their branding requires non-sentence casing) should
 * be represented with a case below.
 *
 * @param service - A service to which Vanta connects.
 */
export function serviceToDisplayName(service: Service) {
  const displayNames: { [k in Service]: string } = {
    adp_run: "ADP RUN",
    adp_workforce_now: "ADP Workforce Now",
    airtable: "Airtable",
    asana: "Asana",
    aws: "AWS",
    azure: "Azure",
    azuredevops: "Azure DevOps",
    bamboo_hr: "BambooHR",
    bitbucket: "Bitbucket",
    certn: "Certn",
    clickup: "ClickUp",
    clubhouse: "Shortcut",
    customercheckr: "Checkr",
    datadog: "Datadog",
    digitalocean: "DigitalOcean",
    digitaloceanspaces: "DigitalOcean Spaces",
    gcp: "GCP",
    github: "GitHub",
    gitlab: "GitLab",
    gsuiteadmin: "Google Workspace",
    gusto: "Gusto",
    heroku: "Heroku",
    insperity: "Insperity",
    jamf: "Jamf Pro",
    jira: "Jira",
    justworks: "Justworks",
    kandji: "Kandji",
    knowbe4: "KnowBe4",
    linear: "Linear",
    microsoft_endpoint_manager: "Microsoft Endpoint Manager",
    mongoatlas: "MongoDB Atlas",
    namely: "Namely",
    office: "Office 365",
    okta: "Okta",
    paychex_flex: "Paychex Flex",
    paycor: "Paycor",
    paylocity: "Paylocity",
    pivotaltracker: "Pivotal Tracker",
    quickbooks: "QuickBooks",
    rippling: "Rippling",
    slack: "Slack",
    snyk: "Snyk",
    square_payroll: "Square Payroll",
    trello: "Trello",
    trinet: "TriNet",
    vetty: "Vetty",
    zenefits: "Zenefits",

    // The below should never be seen in product, but giving them sane defaults just in case
    backgroundChecks: "Background Checks",
    checkr: "Checkr",
    documents: "Documents",
    EMPTY: "None",
    inventoryList: "Inventory List",
    nvd: "NVD",
    packageChecker: "Package Checker",
    ssl: "SSL",
  };

  return displayNames[service] ?? service[0].toUpperCase() + service.substr(1); // should never be null, but if we get rid of a service we need a sensible default.
}

// construct a policyType to parallel our existing policy policyTypes
export const getPolicyTypeForTitle = (title: string) =>
  title.toLowerCase().trim().split(" ").join("-");

export const dropNothingAndErrors = <T>(xs: Array<T | Error | Nothing>) =>
  dropNothing(xs.map(x => (x instanceof Error ? nothing : x)));
