import type { Maybe } from "common/base/types/maybe";
import { applyIfSome } from "common/base/types/maybe";
import React from "react";
import styled from "styled-components";

import { BASE_PALETTE } from "../../base/colors";
import type { ICardTitleRowProps } from "./card-title-row";
import { CardTitleRow } from "./card-title-row";

interface IProps {
  titleProps?: Maybe<ICardTitleRowProps>;
  // eslint-disable-next-line vanta/optional-always-maybe, vanta/prefer-maybe
  className?: string | undefined;
  // eslint-disable-next-line vanta/optional-always-maybe, vanta/prefer-maybe
  style?: React.CSSProperties | undefined;
  // eslint-disable-next-line vanta/optional-always-maybe, vanta/prefer-maybe
  id?: string | undefined;
}

export const ContentCard: React.FC<IProps> = ({
  titleProps,
  children,
  className,
  style,
  id,
}) => (
  <EmptyCard className={className} style={style} id={id}>
    {applyIfSome(titleProps, props => (
      <CardTitleRow {...props} />
    ))}
    {children}
  </EmptyCard>
);

const EmptyCard = styled.div`
  background: ${BASE_PALETTE.SNOW};
  border: 1px solid ${BASE_PALETTE.WIND};
  box-shadow: 0px 1px 4px rgba(51, 51, 51, 0.08);
  border-radius: 4px;
  margin-bottom: 24px;
`;
