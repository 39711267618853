import "./ViewsDisplay.scss";

import { Classes, PopoverInteractionKind } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import moment from "moment";
import React from "react";
import styled from "styled-components";

import { BASE_PALETTE } from "../../alpaca/base/colors";
import { GRID_SPACING } from "../../alpaca/base/grid";
import { BodyText, TextLinkLikeBlack } from "../../alpaca/components";
import type { GetCustomerMaterialsQuery } from "../../gen/components";
import { UI_DATE_FORMAT } from "../../helpers/common";

interface IProps {
  item: Maybe<
    NonNullable<
      GetCustomerMaterialsQuery["user"]
    >["domain"]["presentedReports"][number]
  >;
}

export const ViewsDisplay: React.FC<IProps> = props => {
  if (!isSome(props.item)) {
    return null;
  }
  const views = props.item.views ?? [];
  const viewCountText = (
    <span>
      {views.length > 0 ? (
        <TextLinkLikeBlack>{views.length}</TextLinkLikeBlack>
      ) : (
        <span>{views.length}</span>
      )}
    </span>
  );

  if (views.length === 0) {
    return viewCountText;
  }
  return (
    <Popover2
      interactionKind={PopoverInteractionKind.HOVER}
      popoverClassName={`${Classes.POPOVER_CONTENT_SIZING} popover-font-small`}
      placement={"left"}
      minimal
      modifiers={{ offset: { enabled: true } }}
      content={
        <PopoverContent>
          <ul className={`${Classes.LIST_UNSTYLED}`}>
            {views.map(renderView)}
          </ul>
        </PopoverContent>
      }
    >
      {viewCountText}
    </Popover2>
  );

  function renderView(
    view: NonNullable<
      NonNullable<
        NonNullable<
          GetCustomerMaterialsQuery["user"]
        >["domain"]["presentedReports"][number]["views"]
      >[number]
    >
  ) {
    return (
      <li key={view.id} style={{ display: "flex", marginBottom: GRID_SPACING }}>
        <BodyText as="div" style={{ width: 168 }} color={BASE_PALETTE.SMOKE}>
          {moment(view.lastUpdated).format(UI_DATE_FORMAT)}
        </BodyText>
        <BodyText as="div">{view.email}</BodyText>
      </li>
    );
  }
};

const styles = {
  POPOVER_PADDING: 3 * GRID_SPACING,
};

const PopoverContent = styled.div`
  padding: ${styles.POPOVER_PADDING}px;
  padding-bottom: ${2 * GRID_SPACING}px;
`;
