import type { Maybe } from "common/base/types/maybe";
import gql from "graphql-tag";
import React from "react";
import type { match } from "react-router";

import { LogError } from "../../../errors";
import type { GetLatestPolicyQuery } from "../../../gen/components";
import { useGetLatestPolicyQuery } from "../../../gen/components";
import { Lockout } from "../lockout";
import { UploadedDocComponent } from "../uploadedDocComponent";

interface IProps {
  document?: Maybe<GetLatestPolicyQuery["latestPolicy"]>;
  match: match<{ staticPolicySlug: string; policyType: string }>;
}

gql`
  query getLatestPolicy($staticPolicySlug: String!, $policyType: String!) {
    latestPolicy(staticPolicySlug: $staticPolicySlug, policyType: $policyType) {
      id
      uploadedDoc {
        id
        domainDisplayName
        domainLocked
        slugId
        title
      }
    }
  }
`;

export const ViewLatestPolicyPage: React.FunctionComponent<IProps> = props => {
  const { data, loading, error } = useGetLatestPolicyQuery({
    variables: {
      staticPolicySlug: props.match.params.staticPolicySlug,
      policyType: decodeURIComponent(props.match.params.policyType),
    },
  });
  if (error) {
    LogError(error);
    return null;
  }

  if (loading) {
    return <div />;
  }

  if (!data || !data.latestPolicy) {
    LogError(new Error("No data for latest policy"));
    return null;
  }

  if (data.latestPolicy.uploadedDoc.domainLocked) {
    return (
      <Lockout
        domainName={data.latestPolicy.uploadedDoc.domainDisplayName}
        canTakeAction={false}
      />
    );
  }

  return <UploadedDocComponent document={data.latestPolicy.uploadedDoc} />;
};
