import { RISK_ID_TO_HEADING } from "common/schemas/riskRegister/risk-register-content";
import moment from "moment";
import React from "react";
import styled from "styled-components";

import { VANTA_COLORS } from "../../../alpaca/base/colors";
import { BodyText, H4 } from "../../../alpaca/components";
import type { GetInfoForRiskReportQuery } from "../../../gen/components";
import { Ellipsify } from "../../../helpers/ellipsify";
import { lastNameSort } from "../../../helpers/user-sort-functions";
import { DataTable } from "../components/data-table";
import { RiskEvaluationMeter } from "./risk-evaluation-meter";
import {
  TABLE_STYLES,
  TableHeading,
  TableNormalText,
  TableTitle,
  TIME_FORMAT,
} from "./table-styles";

type Evaluation = NonNullable<
  GetInfoForRiskReportQuery["organization"]
>["riskEvaluations"][number];

interface IProps {
  categoryTitle: string;
  evaluations: Evaluation[];
}

const TABLE_COLUMN_ORDER = [
  "name",
  "type",
  "likelihood",
  "impact",
  "updatedAt",
  "updatedBy",
];

const TABLE_COLUMN_WIDTHS = [
  "250px",
  "230px",
  "110px",
  "110px",
  "150px",
  "150px",
];

const TABLE_HEADERS = {
  name: <TableHeading>Name</TableHeading>,
  type: <TableHeading>Type</TableHeading>,
  likelihood: <TableHeading>Likelihood</TableHeading>,
  impact: <TableHeading>Impact</TableHeading>,
  updatedAt: <TableHeading>Updated</TableHeading>,
  updatedBy: <TableHeading>Updated by</TableHeading>,
};

type EvaluationSortFn = (e1: Evaluation, e2: Evaluation) => number;

const TABLE_SORT_FUNCTIONS: { [k: string]: EvaluationSortFn } = {
  name: (e1, e2) => e1.itemName.localeCompare(e2.itemName),
  type: (e1, e2) =>
    RISK_ID_TO_HEADING[e1.riskId].localeCompare(RISK_ID_TO_HEADING[e2.riskId]),
  likelihood: (e1, e2) => (e1.likelihood ?? -1) - (e2.likelihood ?? -1),
  impact: (e1, e2) => (e1.impact ?? -1) - (e2.impact ?? -1),
  updatedAt: (e1, e2) =>
    moment(e1.updatedAt).valueOf() - moment(e2.updatedAt).valueOf(),
  updatedBy: (e1, e2) =>
    lastNameSort(
      e1.evaluator ?? { displayName: "" },
      e2.evaluator ?? { displayName: "" }
    ),
};

const rowCreator = (evaluation: Evaluation) => {
  return {
    name: (
      <TableNormalText>
        <Ellipsify text={evaluation.itemName} />
      </TableNormalText>
    ),
    type: (
      <TableNormalText>{RISK_ID_TO_HEADING[evaluation.riskId]}</TableNormalText>
    ),
    likelihood: <RiskEvaluationMeter value={evaluation.likelihood} />,
    impact: <RiskEvaluationMeter value={evaluation.impact} />,
    updatedAt: (
      <TableNormalText>
        {moment(evaluation.updatedAt).format(TIME_FORMAT)}
      </TableNormalText>
    ),
    updatedBy: (
      <TableNormalText>{evaluation.evaluator?.displayName}</TableNormalText>
    ),
  };
};

export const RiskEvaluationTable: React.FC<IProps> = ({
  categoryTitle,
  evaluations,
}) => {
  const table =
    evaluations.length > 0 ? (
      <DataTable
        columnOrder={TABLE_COLUMN_ORDER}
        columnWidths={TABLE_COLUMN_WIDTHS}
        columnSortFunctions={TABLE_SORT_FUNCTIONS}
        data={evaluations}
        header={TABLE_HEADERS}
        createRow={rowCreator}
      />
    ) : (
      <BodyText color={VANTA_COLORS.TEXT_DESCRIPTION}>
        No risks have been identified for this category
      </BodyText>
    );
  return (
    <Container>
      <TableTitle>
        <H4>{categoryTitle}</H4>
      </TableTitle>
      <div>{table}</div>
    </Container>
  );
};

const Container = styled.div`
  table {
    ${TABLE_STYLES}
  }
`;
