import "./sla-version-history-list.scss";

import { Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import moment from "moment";
import React from "react";

import { UI_DATE_FORMAT } from "../../../helpers/common";

interface IProps {
  title: string;
  history: Array<{ createdAt: string; creatorName: string }>;
}

export const SLAHistoryComponent: React.FC<IProps> = ({ title, history }) => {
  const content =
    history.length === 0 ? (
      <div>No version history found for these SLAs.</div>
    ) : (
      <div>
        {history.map((version, index) => {
          const createdTimeStamp = moment(version.createdAt).format(
            UI_DATE_FORMAT
          );

          return (
            <React.Fragment key={`policy-version-history-${index}`}>
              <div
                key={`${createdTimeStamp}`}
                className="policy-version-history-list-item"
              >
                <Icon icon={IconNames.BLANK} className="pvhl-icon" />
                <div className="pvhl-timestamp">{createdTimeStamp}</div>
                <div>{`${version.creatorName} set SLAs`}</div>
              </div>{" "}
            </React.Fragment>
          );
        })}
      </div>
    );
  return (
    <div className={"pvhl-container"}>
      <h5 className={"pvhl-title"}>{title} Version History</h5>
      {content}
    </div>
  );
};
