import "./collapse-indicator-icon.scss";

import React from "react";

import Caret from "../../../../static/images/reports/icon_caret.svg";

interface IProps {
  collapseIsOpen: boolean;
}

export const CollapseIndicatorIcon: React.FunctionComponent<IProps> = ({
  collapseIsOpen,
}) => (
  <Caret
    className={`vrp-collapse-indicator${
      collapseIsOpen ? " vrp-collapse-open" : ""
    }`}
  />
);
