import "./vanta-report-summary.scss";

import { dropNothing } from "common/base/types/maybe";
import React from "react";

import { getControlMap } from "../../../helpers/helpers";
import type { IReportProps } from "../../../vanta-report-page";
import { VantaReportControlList } from "../control-list/vanta-report-control-list";

const SUMMARY_CONTROLS = [
  "mfa-on-accounts",
  "password-manager",
  "full-disk-encryption",
  "daily-database-backups",
  "ssl-used",
  "infra-unique-accounts",
  "logging-enabled",
];

export const VantaReportSummary: React.FunctionComponent<IReportProps> = ({
  domain,
  complianceStandard,
}) => {
  const controlMap = getControlMap(complianceStandard);
  const controls = dropNothing(
    SUMMARY_CONTROLS.map(controlId => controlMap.get(controlId))
  );
  return (
    <div className="vrp-summary-container" id="Summary">
      <div className="vrp-summary-title vrp-bold">
        Priority security controls from Vanta
      </div>
      <div className="vrp-summary-text">
        These are the most critical controls to ensure {domain.displayName} is
        protected from common attacks.
      </div>
      <div className="vrp-control-list-separator" />
      <VantaReportControlList domain={domain} controls={controls} />
    </div>
  );
};
