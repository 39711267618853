import { isSome } from "common/base/types/maybe";
import React from "react";

import { CustomTaskItem } from "./custom-task-item";
import type { OnboardingTabUser } from "./onboarding-tab";
import { OnboardingTasksCard } from "./onboarding-tasks-card";

interface IInternalProps {
  user: OnboardingTabUser;
  title: string;
  isAdminList: boolean;
}

const RoleTaskList: React.FC<IInternalProps> = ({
  user,
  title,
  isAdminList,
}) => {
  const { roleCompletionRecord } = user;
  if (!isSome(roleCompletionRecord)) {
    return null;
  }

  const taskCompletionList = isAdminList
    ? roleCompletionRecord.adminTaskCompletions
    : roleCompletionRecord.employeeTaskCompletions;
  const incompleteTaskList = taskCompletionList.filter(
    tc => !isSome(tc.completionDate)
  );

  if (incompleteTaskList.length === 0) {
    return null;
  }

  return (
    <OnboardingTasksCard title={title}>
      {incompleteTaskList.map(tc => (
        <CustomTaskItem
          key={`custom-task-item-${tc.id}`}
          taskCompletion={tc}
          userId={user.id}
          isUserActive={user.isActive}
          roleCompletionId={roleCompletionRecord.id}
        />
      ))}
    </OnboardingTasksCard>
  );
};

interface IProps {
  user: OnboardingTabUser;
}

export const EmployeeRoleTaskList: React.FC<IProps> = ({ user }) => (
  <RoleTaskList
    title="Additional employee tasks"
    user={user}
    isAdminList={false}
  />
);

export const AdminRoleTaskList: React.FC<IProps> = ({ user }) => (
  <RoleTaskList title="Additional admin tasks" user={user} isAdminList={true} />
);
