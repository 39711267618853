import type { OsqueryVulnerability } from "common/base/types/gen";
import type { Maybe } from "common/base/types/maybe";
import { isSome, nothing } from "common/base/types/maybe";
import React from "react";

import { ServerOsFamily } from "../../../../gen/components";
import { CopyClipboardIcon } from "../../../helpers/CopyClipboardIcon";
import type { Machine } from "./server-detail";

interface IProps {
  server: Machine;
  vuln: OsqueryVulnerability;
}

const commandForOs = (packageName: string, os: Maybe<ServerOsFamily>) => {
  switch (os) {
    case ServerOsFamily.Debian:
      return `apt-get install ${packageName}`;
    case ServerOsFamily.RHEL:
      return `yum update ${packageName}`;
    default:
      return null;
  }
};

export const CopyVulnCommandIcon: React.FC<IProps> = ({ server, vuln }) => {
  const packageName = vuln.packageName;
  const osFamily =
    server.data.__typename === "linuxServerData"
      ? server.data.osFamily
      : nothing;

  const command = commandForOs(packageName, osFamily);
  if (!isSome(command)) {
    return null;
  }
  return <CopyClipboardIcon text={command} />;
};
