import {
  Button,
  ControlGroup,
  FormGroup,
  InputGroup,
  Intent,
  Spinner,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import React, { useState } from "react";
import styled from "styled-components";
import validator from "validator";

import { LogError } from "../../../errors";
import type { FetchAuditorsWithAccessQuery } from "../../../gen/components";
import {
  FetchAuditorsWithAccessDocument,
  useFetchAuditorsWithAccessQuery,
  useGrantAccessToAuditorMutation,
  useRevokeAccessFromAuditorMutation,
} from "../../../gen/components";
import { DataTable } from "../components/data-table";

export const AuditorAccessDisplay: React.FC = () => {
  const [inputText, setInputText] = useState("");
  const [showInputError, setShowInputError] = useState(false);
  const { loading, data } = useFetchAuditorsWithAccessQuery();
  const [grantAccess] = useGrantAccessToAuditorMutation({
    onError() {
      setShowInputError(true);
    },
    update: (cache, result) => {
      const newAuditor = result.data?.grantAuditorDomainAccess;
      if (!isSome(newAuditor)) {
        return;
      }
      const oldData = cache.readQuery<FetchAuditorsWithAccessQuery>({
        query: FetchAuditorsWithAccessDocument,
      })?.organization;
      if (
        !isSome(oldData) ||
        oldData.auditorsWithAccess.map(a => a.id).includes(newAuditor.id)
      ) {
        return;
      }
      cache.writeQuery<FetchAuditorsWithAccessQuery>({
        query: FetchAuditorsWithAccessDocument,
        data: {
          organization: {
            ...oldData,
            auditorsWithAccess: oldData.auditorsWithAccess.concat(newAuditor),
          },
        },
      });
      setInputText("");
      setShowInputError(false);
    },
  });
  const [revokeAccess] = useRevokeAccessFromAuditorMutation();

  if (loading || !data) {
    return <Spinner size={Spinner.SIZE_LARGE} />;
  }
  const auditors = data.organization.auditorsWithAccess;

  const form = (
    <form
      onSubmit={e => {
        e.preventDefault();
        if (auditors.some(a => a.email === inputText)) {
          setInputText("");
        } else {
          grantAccess({
            variables: {
              email: inputText,
            },
          }).catch(LogError);
        }
      }}
    >
      <FormGroup
        inline
        helperText={
          showInputError
            ? "We could not find an auditor associated with that email address. Please contact support@vanta.com for assistance."
            : undefined
        }
        intent={showInputError ? Intent.DANGER : Intent.NONE}
      >
        <ControlGroup>
          <InputGroup
            value={inputText}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setInputText(e.currentTarget.value)
            }
            placeholder="auditor@audit.com"
          ></InputGroup>
          <Button
            icon={IconNames.PLUS}
            disabled={!validator.isEmail(inputText)}
            intent={Intent.PRIMARY}
            type="submit"
          >
            Add
          </Button>
        </ControlGroup>
      </FormGroup>
    </form>
  );

  const table = (
    <DataTable
      data={auditors}
      columnOrder={["name", "auditFirm", "actions"]}
      header={{ name: "Name", auditFirm: "Audit firm", actions: "" }}
      createRow={auditor => {
        return {
          name: (
            <div>
              <div>{auditor.displayName}</div>
              <div>{auditor.email}</div>
            </div>
          ),
          auditFirm: <strong>{auditor.firmName}</strong>,
          actions: (
            <Button
              icon={IconNames.REMOVE}
              intent={Intent.DANGER}
              minimal
              onClick={() => {
                revokeAccess({
                  variables: {
                    auditorId: auditor.id,
                  },
                  update: (cache, result) => {
                    if (!Boolean(result.data?.revokeAuditorDomainAccess)) {
                      return;
                    }
                    const oldData =
                      cache.readQuery<FetchAuditorsWithAccessQuery>({
                        query: FetchAuditorsWithAccessDocument,
                      })?.organization;
                    if (!isSome(oldData)) {
                      return;
                    }
                    cache.writeQuery<FetchAuditorsWithAccessQuery>({
                      query: FetchAuditorsWithAccessDocument,
                      data: {
                        organization: {
                          ...oldData,
                          auditorsWithAccess: oldData.auditorsWithAccess.filter(
                            prevAuditor => prevAuditor.id !== auditor.id
                          ),
                        },
                      },
                    });
                  },
                }).catch(LogError);
              }}
            >
              Remove
            </Button>
          ),
        };
      }}
    />
  );

  return (
    <Container>
      {form}
      {auditors.length > 0 ? (
        table
      ) : (
        <p>No auditors have access to your account</p>
      )}
    </Container>
  );
};

gql`
  query fetchAuditorsWithAccess {
    organization {
      id
      auditorsWithAccess {
        id
        displayName
        email
        firmName
      }
    }
  }
`;

gql`
  mutation grantAccessToAuditor($email: String!) {
    grantAuditorDomainAccess(auditorEmail: $email) {
      id
      displayName
      email
      firmName
    }
  }
`;

gql`
  mutation revokeAccessFromAuditor($auditorId: String!) {
    revokeAuditorDomainAccess(auditorId: $auditorId)
  }
`;

const styles = {
  TABLE_WIDTH: 500,
};

const Container = styled.div`
  table {
    width: ${styles.TABLE_WIDTH}px;
  }
`;
