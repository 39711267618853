import {
  AnchorButton,
  Callout,
  Collapse,
  Intent,
  Radio,
  RadioGroup,
} from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { isSome, nothing } from "common/base/types/maybe";
import { VANTA_AGENT_VERSION } from "common/base/utils";
import gql from "graphql-tag";
import React, { useState } from "react";
import styled from "styled-components";

import { GRID_SPACING } from "../../alpaca/base/grid";
import { BodyShortText, H3, H4 } from "../../alpaca/components";
import { LogError, LogErrorMessage } from "../../errors";
import type { GetUserQueryDownloadQuery } from "../../gen/components";
import {
  PermissionLevel,
  useGetLinuxDownloadInfoQuery,
  useGetUserQueryDownloadQuery,
} from "../../gen/components";
import { getOS } from "../../helpers/osdetection";
import { CalloutWithClipBoard } from "../helpers/callout-with-clipboard";
import { FullPageSpinner } from "../helpers/FullPageSpinner";
import { VANTA_LOGO_SIZES, VantaLogo } from "./components/vanta-logo";
import { Lockout } from "./lockout";

interface IProps {
  enteredCode?: Maybe<string>;
  loading?: Maybe<boolean>;
  user?: Maybe<GetUserQueryDownloadQuery["user"]>;
}

export const DownloadPage: React.FC<IProps> = props => {
  const { error, loading, data } = useGetUserQueryDownloadQuery();

  if (error) {
    LogError(error);
    return null;
  }
  if (loading) {
    return <FullPageSpinner />;
  }
  if (!data) {
    LogErrorMessage("Bad fetch");
    return null;
  }
  const { user } = data;
  if (isSome(user) && user.domain.lockedAndPastGracePeriod) {
    return (
      <Lockout
        domainName={user.domain.displayName}
        canTakeAction={user.permissionLevel !== PermissionLevel.Onboarding}
      />
    );
  }

  if (!isSome(user)) {
    return (
      <div className="app-no-container-page">
        <h1>
          <VantaLogo height={VANTA_LOGO_SIZES.HEADING} />
        </h1>
        <BodyShortText>
          Your company partners with Vanta to ensure that your computer is set
          up correctly.
        </BodyShortText>
        <AboutInstructions />
        <br />
        <AnchorButton href={"/login/downloads"} intent={Intent.PRIMARY}>
          Sign in to download the Vanta Agent
        </AnchorButton>
      </div>
    );
  }

  // logged in or code splash
  const maybeReinstallWarning =
    isSome(user) && user.hasOSQueryEndpoint ? (
      <Callout
        intent={Intent.WARNING}
        style={{ textAlign: "left" }}
        title="You already installed the Vanta Agent on a computer. Do you need to re-install?"
      >
        If you're on a new computer, continue re-installing the Vanta Agent.
        <br />
        Otherwise, you've already got the agent; no need to re-install.
      </Callout>
    ) : null;

  return (
    <div className="app-no-container-page">
      <h1>
        <VantaLogo height={VANTA_LOGO_SIZES.HEADING} />
      </h1>
      {maybeReinstallWarning}
      <div style={{ textAlign: "left" }}>
        <DownloadInfo />
      </div>
    </div>
  );
};

const Section = styled.div`
  margin: ${3 * GRID_SPACING}px ${2 * GRID_SPACING}px ${2 * GRID_SPACING}px;
`;

const LinuxDownloadInfo: React.FC = () => {
  const { loading, error, data } = useGetLinuxDownloadInfoQuery();
  const [detailOpen, setDetailOpen] = useState(false);
  const [debianSelected, setDebianSelected] = useState(true);
  if (loading) {
    return <FullPageSpinner />;
  }
  if (error) {
    LogError(error);
    return null;
  }
  if (!isSome(data)) {
    LogErrorMessage("Bad fetch");
    return null;
  }
  const user = data.user;
  if (!isSome(user)) {
    LogErrorMessage("User not logged in");
    return null;
  }
  const secret = user.domain.hostEnrollSecret;
  const email = user.email;

  const packageName = debianSelected ? "vanta-amd64.deb" : "vanta-amd64.rpm";
  const installCommand = debianSelected ? "dpkg -Ei" : "rpm -i";
  return (
    <>
      <H4>One-step install</H4>
      <BodyShortText>
        Paste the following line into your terminal to quickly install the Vanta
        Agent.
      </BodyShortText>
      <BodyShortText>
        <CalloutWithClipBoard
          text={`VANTA_KEY="${secret}" VANTA_OWNER_EMAIL="${email}" bash -c "$(curl -L https://raw.githubusercontent.com/VantaInc/vanta-agent-scripts/master/install-linux.sh)"`}
        />
      </BodyShortText>
      <BodyShortText>
        After installing the agent, you can run{" "}
        <code>/var/vanta/vanta-cli --help</code> to view details about the
        installation.
      </BodyShortText>
      <br />
      <H4>Manual install</H4>
      <BodyShortText>
        If you'd like to install the agent yourself instead of running our
        script, follow the instructions below.
      </BodyShortText>
      <AnchorButton onClick={() => setDetailOpen(d => !d)}>
        {detailOpen ? "Hide" : "Show"} instructions
      </AnchorButton>

      <Collapse isOpen={detailOpen}>
        <br />
        <RadioGroup
          label="View instructions for:"
          onChange={e => setDebianSelected(e.currentTarget.value === "debian")}
          selectedValue={debianSelected ? "debian" : "redhat"}
          inline={true}
        >
          <Radio label="Debian (Ubuntu, etc.)" value="debian" />
          <Radio label="Red Hat (Fedora, etc.)" value="redhat" />
        </RadioGroup>
        <ol>
          <li>
            Download <code>{packageName}</code>:
            <CalloutWithClipBoard
              text={`curl --progress-bar https://vanta-agent.s3.amazonaws.com/v${VANTA_AGENT_VERSION}/${packageName} > /tmp/${packageName}`}
            />
          </li>
          <li>
            Set environment variables:
            <CalloutWithClipBoard
              text={`export VANTA_KEY="${secret}" export VANTA_OWNER_EMAIL="${email}"`}
            />
          </li>
          <li>
            Install app:
            <CalloutWithClipBoard
              text={`sudo -E ${installCommand} /tmp/${packageName}`}
            />
          </li>
          <li>
            Check that it's running:
            <CalloutWithClipBoard text={`/var/vanta/vanta-cli status`} />
          </li>
          <li>
            Check that everything is set up correctly:
            <CalloutWithClipBoard text={`sudo /var/vanta/vanta-cli doctor`} />
          </li>
        </ol>
      </Collapse>
    </>
  );
};

const InstallInstructions: React.FC<{ os: string }> = ({ os }) => {
  let installationInstructions: Maybe<JSX.Element> = nothing;
  switch (os) {
    case "Mac":
      installationInstructions = (
        <>
          <BodyShortText>
            Click the button below to install the Vanta Agent.
          </BodyShortText>

          <BodyShortText>
            After installation, a “V” should appear in your toolbar. A browser
            window may also open asking you to register your computer with
            Vanta.
          </BodyShortText>
          <BodyShortText>
            If the window doesn't appear, you may need to register manually:
            <ol>
              <li>Click on the "V" in your toolbar.</li>
              <li>
                Select "Register Vanta Agent," and log into Vanta if you haven't
                already.
              </li>
            </ol>
            Don’t worry if that option is greyed out; it means you’ve already
            registered!
          </BodyShortText>
          <AnchorButton
            href={"/osquery/download/osx"}
            intent={Intent.PRIMARY}
            target="_blank"
          >
            Download Vanta Agent for macOS
          </AnchorButton>
        </>
      );
      break;
    case "Windows":
      installationInstructions = (
        <>
          <BodyShortText>
            Click on the button below to install the Vanta Agent.
          </BodyShortText>
          <BodyShortText>
            After installing the app, you might not see any indication that it’s
            running. That’s by design! The Vanta Agent is designed to run in the
            background.
          </BodyShortText>
          <AnchorButton
            href={"/osquery/download/windows"}
            intent={Intent.PRIMARY}
            target="_blank"
          >
            Download Vanta Agent for Windows
          </AnchorButton>
        </>
      );
      break;
    case "Linux":
      installationInstructions = <LinuxDownloadInfo />;
      break;
    default:
      installationInstructions = nothing;
  }
  return isSome(installationInstructions) ? (
    <Section>{installationInstructions}</Section>
  ) : (
    <BodyShortText>
      We don't have an app for your platform yet. Please contact your Vanta
      Admin to ask them to disable requiring installation of the agent on your
      computer.
    </BodyShortText>
  );
};

const SystemRequirements: React.FC<{ os: string }> = ({ os }) => {
  let systemRequirements: Maybe<JSX.Element> = nothing;
  switch (os) {
    case "Mac":
      systemRequirements = (
        <BodyShortText>
          The Vanta Agent is supported on macOS 10.14 (Mojave) and above.
        </BodyShortText>
      );
      break;
    case "Windows":
      systemRequirements = (
        <BodyShortText>
          The Vanta Agent is supported on Windows 10 Home and Windows 10 Pro.
        </BodyShortText>
      );
      break;
    case "Linux":
      systemRequirements = (
        <>
          <BodyShortText>
            The Vanta Agent is supported on most Debian and RedHat variants,
            with the exception of ARM and SELinux builds.
          </BodyShortText>
          <BodyShortText>
            Vanta officially guarantees support for Ubuntu 16.04 and above, as
            well as Amazon Linux.
          </BodyShortText>
        </>
      );
      break;
    default:
      systemRequirements = nothing;
  }

  return isSome(systemRequirements) ? (
    <Section>
      <H3>System Requirements</H3>
      {systemRequirements}{" "}
    </Section>
  ) : (
    <BodyShortText>We don't have an app for your platform yet.</BodyShortText>
  );
};

export const AboutInstructions: React.FC = () => (
  <Section>
    <H3>About the Vanta Agent</H3>
    <BodyShortText>
      The Vanta Agent is a lightweight program designed to run in the background
      of your computer. It has a very low performance impact: once you install
      the app and register your device, you shouldn’t have to think about it.
    </BodyShortText>
    <BodyShortText>
      The app checks several security-relevant settings, such as whether the
      hard drive is encrypted or a password manager is installed. The app will
      never send sensitive data like passwords, messages, or browsing history.
      It also won’t change any settings on your computer.
    </BodyShortText>
  </Section>
);

export const DownloadInfo: React.FC = () => {
  const os = getOS();
  return (
    <>
      <InstallInstructions os={os} />
      <SystemRequirements os={os} />
      <AboutInstructions />
    </>
  );
};

gql`
  query GetLinuxDownloadInfo {
    user {
      id
      email
      domain {
        id
        hostEnrollSecret
      }
    }
  }
`;

gql`
  query GetUserQueryDownload {
    user {
      id
      email
      givenName
      hasOSQueryEndpoint
      permissionLevel
      domain {
        id
        displayName
        hostEnrollSecret
        name
        lockedAndPastGracePeriod
      }
    }
  }
`;
