import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import React, { useContext } from "react";
import type { match } from "react-router";
import { Redirect } from "react-router";

import { LogError } from "../../errors";
import { useGetUploadedDocumentQuery } from "../../gen/components";
import { Lockout } from "./lockout";
import { UploadedDocComponent } from "./uploadedDocComponent";
import { UserContext } from "./user-context";

interface IProps {
  match: match<{ slug: string; slugId: string; title: string }>;
}

gql`
  query GetUploadedDocument($maybeSlugId: String!) {
    uploadedDocument(maybeSlugId: $maybeSlugId) {
      id
      domainDisplayName
      domainLocked
      slugId
      title
    }
  }
`;

export const UploadedDocPage: React.FunctionComponent<IProps> = props => {
  const { user } = useContext(UserContext);
  const { data, loading, error } = useGetUploadedDocumentQuery({
    variables: {
      maybeSlugId: props.match.params.slugId,
    },
  });
  if (error) {
    if (!isSome(user)) {
      return (
        <Redirect
          to={`/login?continue=${encodeURIComponent(window.location.href)}`}
        />
      );
    }
    LogError(error);
    return null;
  }

  if (loading) {
    return <div />;
  }

  if (!data || !data.uploadedDocument) {
    LogError(new Error("No document for document viewer"));
    return null;
  }

  if (data.uploadedDocument.domainLocked) {
    return (
      <Lockout
        domainName={data.uploadedDocument.domainDisplayName}
        canTakeAction={false}
      />
    );
  }

  return <UploadedDocComponent document={data.uploadedDocument} />;
};
