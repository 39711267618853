import {
  AnchorButton,
  Divider,
  Intent,
  NonIdealState,
} from "@blueprintjs/core";
import type { Service } from "common/base/types/helpers";
import { serviceToDisplayName } from "common/base/types/helpers";
import type { Maybe } from "common/base/types/maybe";
import { isSome, nothing } from "common/base/types/maybe";
import gql from "graphql-tag";
import React, { useState } from "react";

import { LogError } from "../../../errors";
import { useFetchDomainReposQuery } from "../../../gen/components";
import { HTMLSelect } from "../../form-controls/html-select";
import { FullPageSpinner } from "../../helpers/FullPageSpinner";
import {
  PageHeadingInfo,
  VantaDashboardPage,
} from "../../vanta-chrome/page-content/vanta-dashboard-page";
import { ChangeTableByMonth } from "./change-table-by-month";
import { MonthSelector } from "./month-selector";

const MONTHS_TO_DISPLAY = 12;

interface IMonthAndYear {
  month: number;
  year: number;
}

export const ChangesByMonth: React.FC = () => {
  const [monthAndYear, setMonthAndYear] =
    useState<Maybe<IMonthAndYear>>(nothing);
  const [repo, setRepo] = useState<string>("internal-unselected");
  const { loading, error, data } = useFetchDomainReposQuery({});
  if (loading) {
    return <FullPageSpinner />;
  }
  if (error) {
    LogError(error);
    return <div>An error occurred.</div>;
  }
  if (!data) {
    LogError(new Error("bad fetch for fetchDomainRepos"));
    return <div>Could not fetch data.</div>;
  }
  if (
    !isSome(data.organization.credentials) ||
    data.organization.credentials.length === 0
  ) {
    const action = (
      <AnchorButton href="/connections" icon="add" intent={Intent.PRIMARY}>
        Link version control
      </AnchorButton>
    );

    return (
      <NonIdealState
        action={action}
        icon="th-disconnect"
        title="Version control not linked to Vanta"
      />
    );
  }
  if (
    !isSome(data.organization.repoInfos) ||
    data.organization.repoInfos.length === 0
  ) {
    return (
      <VantaDashboardPage headingInfo={PageHeadingInfo.CHANGES}>
        <div>No repos for organization</div>
      </VantaDashboardPage>
    );
  }

  const multipleSystemsLinked = data.organization.credentials.length > 1;

  const table =
    isSome(monthAndYear) && repo !== "internal-unselected" ? (
      <ChangeTableByMonth
        month={monthAndYear.month}
        year={monthAndYear.year}
        repo={data.organization.repoInfos.find(r => r.uniqueId === repo)!}
      />
    ) : (
      <div>Select a month and a repository to view changes</div>
    );
  return (
    <VantaDashboardPage headingInfo={PageHeadingInfo.CHANGES}>
      Changes for:
      <HTMLSelect
        name={"Select a repository"}
        choices={data.organization.repoInfos.map(r => {
          return {
            id: r.uniqueId,
            label: multipleSystemsLinked
              ? `${r.name} (${serviceToDisplayName(r.service as Service)})`
              : r.name,
          };
        })}
        value={repo}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
          setRepo(e.target.value)
        }
      />
      <MonthSelector
        monthsToShow={MONTHS_TO_DISPLAY}
        onSelect={({ month, year }) => setMonthAndYear({ month, year })}
      />
      <Divider />
      {table}
    </VantaDashboardPage>
  );
};

gql`
  query fetchDomainRepos {
    organization {
      id
      credentials(services: ["github", "gitlab", "bitbucket", "azuredevops"]) {
        id
        service
      }
      repoInfos {
        name
        defaultBranch
        service
        uniqueId
      }
    }
  }
`;
