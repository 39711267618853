import React from "react";

export class TextArea extends React.PureComponent<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>
> {
  public render() {
    return (
      <textarea className="bp3-input bp3-fill" dir="auto" {...this.props} />
    );
  }
}
