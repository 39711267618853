import type { PureQueryOptions } from "@apollo/client";
import {
  Button,
  Classes,
  Dialog,
  InputGroup,
  Intent,
  Spinner,
} from "@blueprintjs/core";
import { dropNothing, isSome, nothing } from "common/base/types/maybe";
import { simplePlural } from "common/grammar/plurals";
import type { VulnerabilityServiceClientSafe } from "common/schemas/vulnerablePackageWhitelistingMetadata";
import { whitelistingMetadataByService } from "common/schemas/vulnerablePackageWhitelistingMetadata";
import gql from "graphql-tag";
import React, { useState } from "react";
import { Tooltip } from "../../../../alpaca/components";

import { LogError } from "../../../../errors";
import {
  FilterOperator,
  SpecificResource,
  useBulkWhitelistFailDataMutationMutation,
  useFindContainerVulnerabilitiesByIdentifierQuery,
  useWhitelistForAllRepositoriesMutation,
} from "../../../../gen/components";
import { AppToaster } from "../../../../helpers/toaster";

const MAX_SAMPLES_TO_SHOW = 10;

const SERVICE_TO_VULNERABILITY_RESOURCE = {
  aws: SpecificResource.AwsContainerVulnerability,
  // We need to differentiate between aws (containers) and inspector
  aws_inspector: SpecificResource.AwsInspectorVulnerability,
  azure: SpecificResource.AzureContainerVulnerability,
  gcp: SpecificResource.GCPContainerVulnerability,
};

interface IProps {
  refetchQueries: PureQueryOptions[];
  packageIdentifier: string;
  entityId: string;
  service: VulnerabilityServiceClientSafe;
  onClose(): void;
}

export const IgnoreContainerVulnDialog: React.FC<IProps> = ({
  refetchQueries,
  packageIdentifier,
  entityId,
  service,
  onClose,
}) => {
  const specificResource = SERVICE_TO_VULNERABILITY_RESOURCE[service];
  const sourceNoun =
    service === "aws_inspector"
      ? { singular: "instance", plural: "instances" }
      : { singular: "repository", plural: "repositories" };
  const { testId, entityType } = whitelistingMetadataByService[service];
  const [inputText, setInputText] = useState("");
  const { error, loading, data } =
    useFindContainerVulnerabilitiesByIdentifierQuery({
      variables: {
        specificResource,
        filterParams: {
          operator: FilterOperator.AND,
          filters: [
            {
              field: "packageIdentifier",
              condition: {
                STRING_EQ: packageIdentifier,
              },
            },
          ],
        },
      },
    });
  const [whitelistIndividualVulnerability, whitelistMutationResult] =
    useBulkWhitelistFailDataMutationMutation({
      refetchQueries,
      onCompleted: () => {
        AppToaster.show({
          intent: Intent.SUCCESS,
          message: "Monitoring of this vulnerability has been disabled",
        });
        onClose();
      },
    });
  const [whitelistForAllRepositories, whitelistAllMutationResult] =
    useWhitelistForAllRepositoriesMutation({
      refetchQueries,
      onCompleted: () => {
        AppToaster.show({
          intent: Intent.SUCCESS,
          message: `Monitoring of this vulnerability disabled for all ${sourceNoun.plural}`,
        });
        onClose();
      },
    });

  if (error) {
    LogError(error);
    return <div />;
  }

  if (loading || !isSome(data)) {
    return <Spinner />;
  }

  const repositoriesWithPackageCount = data.organization.resources.totalCount;
  const repositoriesWithPackageSample = dropNothing(
    data.organization.resources.edges.map(e => {
      if (
        e.node?.__typename === "SpecificAwsContainerVulnerabilityResource" ||
        e.node?.__typename === "SpecificGCPContainerVulnerabilityResource" ||
        e.node?.__typename === "SpecificAzureContainerVulnerabilityResource"
      ) {
        return e.node?.repositoryName;
      } else if (
        e.node?.__typename === "SpecificAwsInspectorVulnerabilityResource"
      ) {
        return e.node?.instanceId;
      }
      return nothing;
    })
  ).slice(0, MAX_SAMPLES_TO_SHOW);

  const repositoriesNotShowing =
    repositoriesWithPackageCount - repositoriesWithPackageSample.length;
  const repositoryList = (
    <ul>
      {repositoriesWithPackageSample.map((repository, i) => (
        <li key={`${repository}_${i}`}>{repository}</li>
      ))}
      {repositoriesNotShowing > 0 ? (
        <li>and {repositoriesNotShowing} more...</li>
      ) : (
        nothing
      )}
    </ul>
  );

  return (
    <Dialog
      isOpen={true}
      onClose={onClose}
      title={`Disable monitoring of this vulnerability?`}
    >
      <div className={Classes.DIALOG_BODY}>
        <p>
          Monitoring will be disabled for this vulnerability. Once disabled,
          Vanta will not alert on or track remediation of this vulnerability.
        </p>
        <p>
          This vulnerability affects{" "}
          {simplePlural(repositoriesWithPackageCount, sourceNoun.singular)}.
          {repositoriesWithPackageCount > 1 ? (
            <>
              {" "}
              Monitoring can be disabled across all {sourceNoun.plural} by
              clicking <strong>Disable monitoring for all</strong> below.
            </>
          ) : (
            nothing
          )}
        </p>

        {repositoryList}
        <h5>Provide a reason for disabling monitoring.</h5>
        <InputGroup
          autoFocus
          placeholder={
            "This vulnerability does not need to be monitored because…"
          }
          value={inputText}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setInputText(e.target.value)
          }
        />
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button
            intent={Intent.NONE}
            onClick={onClose}
            disabled={
              whitelistMutationResult.called ||
              whitelistAllMutationResult.called
            }
          >
            Cancel
          </Button>
          <Tooltip
            content={"Disable monitoring for this individual vulnerability"}
          >
            <Button
              loading={whitelistMutationResult.called}
              disabled={whitelistAllMutationResult.called}
              intent={Intent.DANGER}
              onClick={() => {
                const reason = inputText.trim();
                whitelistIndividualVulnerability({
                  variables: {
                    reason,
                    entityType,
                    entityIds: [entityId],
                    testId,
                  },
                }).catch(LogError);
              }}
            >
              Disable monitoring
            </Button>
          </Tooltip>
          {repositoriesWithPackageCount > 1 ? (
            <Tooltip
              content={`Disable monitoring on ${simplePlural(
                repositoriesWithPackageCount,
                sourceNoun.singular
              )}`}
            >
              <Button
                loading={whitelistAllMutationResult.called}
                disabled={whitelistMutationResult.called}
                intent={Intent.DANGER}
                onClick={() => {
                  const reason = inputText.trim();
                  whitelistForAllRepositories({
                    variables: {
                      reason,
                      service,
                      packageIdentifier,
                    },
                  }).catch(LogError);
                }}
              >
                Disable monitoring for all
              </Button>
            </Tooltip>
          ) : (
            nothing
          )}
        </div>
      </div>
    </Dialog>
  );
};

gql`
  query findContainerVulnerabilitiesByIdentifier(
    $filterParams: filterParams
    $specificResource: SpecificResource
  ) {
    organization {
      id
      resources(
        specificResourceType: $specificResource
        first: 10
        filterParams: $filterParams
      ) {
        totalCount
        edges {
          node {
            id
            uniqueId
            ... on SpecificAwsContainerVulnerabilityResource {
              repositoryName
            }
            ... on SpecificAwsInspectorVulnerabilityResource {
              instanceId
            }
            ... on SpecificAzureContainerVulnerabilityResource {
              repositoryName
            }
            ... on SpecificGCPContainerVulnerabilityResource {
              repositoryName
            }
          }
        }
      }
    }
  }
`;

gql`
  mutation whitelistForAllRepositories(
    $packageIdentifier: String!
    $reason: String!
    $service: String!
  ) {
    whitelistVulnerabilityForAllRepositories(
      packageIdentifier: $packageIdentifier
      reason: $reason
      service: $service
    )
  }
`;
