/**
 * @file we require that these permission sets be assembled into custom roles
 * and assigned to a service account for a customer to link GCP.
 *
 * These permission lists are here (in common) because they're jointly used to
 * generate the GCP linking instructions and specify the api-service validation
 * process.
 *
 * NOTE: if these lists change, update web/client/src/static/provision-vanta-gcp-connection.sh
 * accordingly.
 */

// The full set of GCP permissions we require in the VantaProjectScanner role.
export const projectCustomRolePermissions = [
  "bigquery.datasets.get",
  "bigtable.instances.get",
  "compute.instances.get",
  "compute.instances.getEffectiveFirewalls",
  "compute.subnetworks.get",
  "pubsub.topics.get",
  "resourcemanager.projects.get",
  "storage.buckets.get",
];

// The full set of GCP permissions we require in the VantaOrganizationScanner
// role.
export const organizationCustomRolePermissions = [
  "iam.roles.list",
  "resourcemanager.organizations.getIamPolicy",
  "resourcemanager.folders.getIamPolicy",
];
