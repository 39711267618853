import { H2, HTMLTable } from "@blueprintjs/core";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import React, { useState } from "react";

import { GRID_SPACING } from "../../alpaca/base/grid";
import { Button, Dialog } from "../../alpaca/components";
import { useAllAuditorUsersQuery } from "../../gen/components";
import { NewAuditorPanel } from "./new-auditor-panel";

export const AuditorsPanel: React.FC = props => {
  const [isOpen, setIsOpen] = useState(false);
  const { data } = useAllAuditorUsersQuery();

  return (
    <div style={{ margin: 4 * GRID_SPACING }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <H2>Auditors</H2>

        <Button
          text="New Auditor"
          icon="add"
          intent="primary"
          onClick={() => setIsOpen(true)}
        />
      </div>
      <HTMLTable style={{ marginTop: 2 * GRID_SPACING }} striped>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Firm Name</th>
            <th>Created</th>
          </tr>
        </thead>
        <tbody>
          {data?.allAuditFirms.map(d =>
            d.domain.users.map(u => (
              <tr key={u.id}>
                <td>{u.displayName}</td>
                <td>{u.email}</td>
                <td>{d.firmName}</td>
                <td>
                  {isSome(u.createdAt)
                    ? new Date(u.createdAt).toLocaleDateString("en-us", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })
                    : "Missing"}
                </td>
              </tr>
            ))
          )}
        </tbody>
      </HTMLTable>
      <Dialog
        title="Create a new auditor"
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <NewAuditorPanel />
      </Dialog>
    </div>
  );
};

gql`
  query allAuditorUsers {
    allAuditFirms {
      id
      firmName
      domain {
        id
        displayName
        users {
          id
          displayName
          email
          createdAt
        }
      }
    }
  }
`;
