import type { DataProxy } from "@apollo/client";
import { Intent } from "@blueprintjs/core";
import type { Service } from "common/base/types/helpers";
import { serviceToDisplayName } from "common/base/types/helpers";
import { isSome } from "common/base/types/maybe";
import React from "react";

import { BASE_PALETTE, VANTA_COLORS } from "../../../../alpaca/base/colors";
import { BASE_TYPOGRAPHY } from "../../../../alpaca/base/typography";
import {
  BodyText,
  Button,
  Icon,
  IconNames,
  MetaText,
} from "../../../../alpaca/components";
import { LogError } from "../../../../errors";
import type {
  GetAccessAccountsQuery,
  GetUserAccessQuery,
} from "../../../../gen/components";
import {
  GetAccessAccountsDocument,
  GetUserAccessDocument,
  useSetServiceAccountMappingMutation,
} from "../../../../gen/components";
import { AppToaster } from "../../../../helpers/toaster";
import type { AccessTabUser } from "./access-tab";

type ServiceAccount = AccessTabUser["serviceAccounts"][number];

interface IProps {
  user: AccessTabUser;
}

export const LinkedServiceAccounts: React.FC<IProps> = ({ user }) => {
  const [setServiceAccountMapping] = useSetServiceAccountMappingMutation();
  const unassignAccount = async (serviceAccount: ServiceAccount) => {
    try {
      await setServiceAccountMapping({
        variables: {
          serviceAccountId: serviceAccount.id,
          userId: null,
        },
        update: (cache, result) => {
          if (Boolean(result.data?.setServiceAccountMapping)) {
            updateAccountAccessTableCache(
              cache,
              serviceAccount.id,
              serviceAccount.service
            );
            updateUserServiceAccountsCache(cache, user, serviceAccount);
          }
        },
      });
      AppToaster.show({
        icon: "tick",
        intent: Intent.SUCCESS,
        message: `Account ${serviceAccount.accountName} unlinked`,
        timeout: 2500,
      });
    } catch (e) {
      LogError(e);
    }
  };
  const sortedServiceAccounts = user.serviceAccounts
    .slice()
    .sort((a1, a2) => a1.service.localeCompare(a2.service));

  const maybeEmptyState =
    sortedServiceAccounts.length === 0 ? (
      <BodyText>No linked accounts</BodyText>
    ) : null;
  return (
    <>
      <BodyText
        style={{ marginBottom: "6px" }}
        fontWeight={BASE_TYPOGRAPHY.FONT_WEIGHTS.BOLD}
      >
        Linked via Vanta
      </BodyText>
      {maybeEmptyState}
      {sortedServiceAccounts.map((serviceAccount, idx) => (
        <div
          key={`service-account-${idx}`}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "4px",
          }}
        >
          <BodyText as="div">
            <span>
              {serviceToDisplayName(serviceAccount.service as Service)}:{" "}
            </span>
            <BodyText as={"span"} color={VANTA_COLORS.TEXT_MUTED}>
              {serviceAccount.accountName}
            </BodyText>
          </BodyText>
          <UnlinkButton onClick={async () => unassignAccount(serviceAccount)} />
        </div>
      ))}
    </>
  );
};

interface IUnlinkButtonProps {
  onClick(): void;
}

const UnlinkButton: React.FC<IUnlinkButtonProps> = ({ onClick }) => (
  <Button onClick={onClick}>
    <div style={{ display: "flex" }}>
      <Icon
        icon={IconNames.LINK}
        style={{ marginRight: "4px" }}
        color={BASE_PALETTE.SMOKE}
      />
      <MetaText
        fontWeight={BASE_TYPOGRAPHY.FONT_WEIGHTS.SLIGHTLY_BOLD}
        lineHeight={"14px"}
      >
        Unlink
      </MetaText>
    </div>
  </Button>
);

function updateUserServiceAccountsCache(
  cache: DataProxy,
  user: AccessTabUser,
  serviceAccount: ServiceAccount
) {
  const userToUpdate = cache.readQuery<GetUserAccessQuery>({
    query: GetUserAccessDocument,
    variables: {
      userId: user.id,
    },
  })?.user;
  if (isSome(userToUpdate)) {
    cache.writeQuery<GetUserAccessQuery>({
      query: GetUserAccessDocument,
      data: {
        user: {
          ...userToUpdate,
          serviceAccounts: userToUpdate.serviceAccounts.filter(
            account => account.id !== serviceAccount.id
          ),
        },
      },
    });
  }
}

function updateAccountAccessTableCache(
  cache: DataProxy,
  serviceAccountId: string,
  service: string
) {
  let domainToUpdate;
  try {
    domainToUpdate = cache.readQuery<GetAccessAccountsQuery>({
      query: GetAccessAccountsDocument,
      variables: {
        service,
      },
    })?.organization;
  } catch (e) {
    // GraphQL throws an error if there is nothing in this cache
    // i.e. the access accounts query has not been made for this service
    return;
  }
  if (isSome(domainToUpdate) && isSome(domainToUpdate.serviceAccounts)) {
    cache.writeQuery<GetAccessAccountsQuery>({
      query: GetAccessAccountsDocument,
      data: {
        organization: {
          ...domainToUpdate,
          serviceAccounts: domainToUpdate.serviceAccounts.map(account =>
            account.id === serviceAccountId
              ? {
                  ...account,
                  owner: null,
                }
              : account
          ),
        },
      },
    });
  }
}
