import { DateRangePicker } from "@blueprintjs/datetime";
import type { Maybe } from "common/base/types/maybe";
import moment from "moment";
import React from "react";

interface IProps {
  endDate: Maybe<Date>;
  startDate: Maybe<Date>;
  onChange(range: [Maybe<Date>, Maybe<Date>]): void;
}

export class AuditDatePicker extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.onPickedDates = this.onPickedDates.bind(this);
    this.onChangeDays = this.onChangeDays.bind(this);
  }

  public render() {
    const dayDiff =
      this.props.startDate && this.props.endDate
        ? moment(this.props.endDate).diff(this.props.startDate, "days")
        : 0;

    return (
      <div>
        <div>
          Days
          <input type="text" onChange={this.onChangeDays} value={dayDiff} />
        </div>
        <DateRangePicker
          contiguousCalendarMonths={false}
          shortcuts={false}
          value={[this.props.startDate ?? null, this.props.endDate ?? null]}
          onChange={this.onPickedDates}
          maxDate={moment().add(20, "years").toDate()}
        />
      </div>
    );
  }

  private onPickedDates(range: [Maybe<Date>, Maybe<Date>]) {
    this.props.onChange(range);
  }

  private onChangeDays(e: React.SyntheticEvent<HTMLInputElement>) {
    if (!this.props.startDate) {
      return;
    }

    const days = parseInt(e.currentTarget.value, 10);
    if (isNaN(days) || days <= 0 || days >= 1000) {
      return;
    }

    this.onPickedDates([
      this.props.startDate,
      moment(this.props.startDate).add(days, "days").toDate(),
    ]);
  }
}
