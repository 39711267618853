import { Classes, Label, TextArea } from "@blueprintjs/core";
import gql from "graphql-tag";
import React, { useState } from "react";
import { Link } from "react-router-dom";

import { LogError } from "../../../errors";
import {
  CheckSuspendedOrRemovedDocument,
  GetDataForUserDetailHeadingDocument,
  PermissionLevel,
  useSetNonHumanMutation,
} from "../../../gen/components";
import { CancelConfirmDialog } from "../../helpers/CancelConfirmDialog";
import type { DetailHeadingUser } from "./user-drawer/user-detail-heading";

interface IProps {
  user: Omit<DetailHeadingUser, "imageUrl">;
  isOpen: boolean;
  onClose(): void;
}

export const NotHumanDialog: React.FC<IProps> = ({ user, isOpen, onClose }) => {
  const [text, setText] = useState("");
  const [setNonHuman] = useSetNonHumanMutation({
    refetchQueries: [
      {
        query: GetDataForUserDetailHeadingDocument,
        variables: { userId: user.id },
      },
      {
        query: CheckSuspendedOrRemovedDocument,
        variables: { userId: user.id },
      },
    ],
    onCompleted() {
      onClose();
    },
  });

  const isAdmin = user.permissionLevel === PermissionLevel.Admin;
  const canSubmit = !isAdmin && text.trim() !== "";

  return (
    <CancelConfirmDialog
      body={renderBody()}
      confirmDisabled={!canSubmit}
      confirmText="Mark as not a person"
      isOpen={isOpen}
      title={user.email}
      onClose={onClose}
      onConfirm={async () =>
        setNonHuman({
          variables: {
            userId: user.id,
            isNotHuman: true,
            isNotHumanReason: text,
          },
        }).catch(LogError)
      }
    />
  );

  function renderBody() {
    if (user.permissionLevel === PermissionLevel.Admin) {
      return (
        <div>
          <p>{user.displayName} is an admin of your Vanta account.</p>
          <p>
            Go to the <Link to="/users">Users page</Link> and revoke admin
            status before marking this account as not a person.
          </p>
          <p>This requires admin level permissions.</p>
        </div>
      );
    }
    return (
      <div>
        <Label>
          Why isn't {user.email} a person?
          <TextArea
            className={Classes.FILL}
            large={true}
            value={text}
            onChange={e => setText(e.currentTarget.value)}
          />
        </Label>
        <Label>
          If {user.email} is marked as "not a person," the email won't be
          checked for device management, email configuration, etc.
        </Label>
      </div>
    );
  }
};

gql`
  mutation setNonHuman(
    $userId: ID!
    $isNotHuman: Boolean!
    $isNotHumanReason: String!
  ) {
    setNonHuman(
      userId: $userId
      isNotHuman: $isNotHuman
      isNotHumanReason: $isNotHumanReason
    ) {
      id
    }
  }
`;
