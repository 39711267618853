import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import { keyBy } from "lodash";
import moment from "moment";
import React, { useMemo, useState } from "react";
import styled from "styled-components";

import { GRID_SPACING } from "../../../alpaca/base/grid";
import { ContentCard } from "../../../alpaca/components";
import type { PeoplePageFieldsFragment } from "../../../gen/components";
import {
  FetchDataForPeoplePageDocument,
  GetAllLinkableHrUsersDocument,
} from "../../../gen/components";
import { Ellipsify } from "../../../helpers/ellipsify";
import {
  compareNullableStrings,
  lastNameSort,
} from "../../../helpers/user-sort-functions";
import { DataTable } from "../components/data-table";
import { CreateUserDialog } from "./create-user-dialog";
import { HrUserActionsDropdown } from "./hr-user-actions-dropdown";
import type {
  Action,
  HrUser,
  HrUserActionsMap,
} from "./manage-hr-users-dialog";
import { DATE_FORMAT } from "./shared/common";
import { NotApplicable } from "./shared/not-applicable";

interface IProps {
  hrUsers: HrUser[];
  linkableUsers: PeoplePageFieldsFragment[];
  hrUserActionsMap: HrUserActionsMap;
  setActionForHrUser(hrUser: HrUser, action: Action): void;
}

const COLUMN_SORT_FUNCTIONS: {
  [k: string]: (u1: HrUser, u2: HrUser) => number;
} = {
  name: lastNameSort,
  email: (u1, u2) => compareNullableStrings(u1.email, u2.email),
  startDate: (u1, u2) => moment(u1.startDate).diff(moment(u2.startDate)),
};

export const ManageHrUsersTable: React.FC<IProps> = ({
  hrUsers,
  linkableUsers,
  hrUserActionsMap,
  setActionForHrUser,
}) => {
  const [hrUserToAdd, setHrUserToAdd] = useState<Maybe<HrUser>>(null);
  const sortedHrUsers = useMemo(
    () =>
      hrUsers.sort((u1, u2) => moment(u1.startDate).diff(moment(u2.startDate))),
    [hrUsers]
  );
  const linkableUsersById = useMemo(
    () => keyBy(linkableUsers, "id"),
    [linkableUsers]
  );
  const sortedLinkableUsers = useMemo(
    () => linkableUsers.sort(lastNameSort),
    [linkableUsers]
  );
  return (
    <>
      <StyledContentCard>
        <DataTable
          useDefaultStyling
          stickyHeaders="top"
          columnOrder={["name", "email", "startDate", "actions"]}
          columnWidths={["245px", "260px", "140px", "640px"]}
          header={{
            name: "Name in HR system",
            email: "Email in HR system",
            startDate: "Start date in HR",
            actions: "Link to person",
          }}
          data={sortedHrUsers}
          createRow={hrUser => {
            return {
              name: <Ellipsify text={hrUser.displayName!} />,
              email: isSome(hrUser.email) ? (
                <Ellipsify text={hrUser.email} />
              ) : (
                <NotApplicable />
              ),
              startDate: moment(hrUser.startDate).utc().format(DATE_FORMAT),
              actions: (
                <HrUserActionsDropdown
                  hrUser={hrUser}
                  linkableUsersById={linkableUsersById}
                  sortedLinkableUsers={sortedLinkableUsers}
                  hrUserActionsMap={hrUserActionsMap}
                  setActionForHrUser={setActionForHrUser}
                  setHrUserToAdd={setHrUserToAdd}
                />
              ),
            };
          }}
          columnSortFunctions={COLUMN_SORT_FUNCTIONS}
          defaultSortColumn="startDate"
        />
      </StyledContentCard>
      {isSome(hrUserToAdd) ? (
        <CreateUserDialog
          isOpen={true}
          onClose={() => setHrUserToAdd(null)}
          refetchQueries={[
            { query: FetchDataForPeoplePageDocument },
            { query: GetAllLinkableHrUsersDocument },
          ]}
          hrUser={hrUserToAdd}
        />
      ) : null}
    </>
  );
};

const StyledContentCard = styled(ContentCard)`
  max-height: ${55 * GRID_SPACING}px;
  overflow-x: hidden;
  overflow-y: auto;
  width: 1296px;
`;
