import type { DataProxy } from "@apollo/client";
import { isSome } from "common/base/types/maybe";

import type {
  FetchTestResultInfoForDetailQuery,
  GetTestsForTestsPageQuery,
  WhitelistInformationFragment,
} from "../../../gen/components";
import {
  FetchTestResultInfoForDetailDocument,
  GetTestsForTestsPageDocument,
} from "../../../gen/components";

export const updateTestPageQueryCacheWithNewTestResult = (
  cache: DataProxy,
  testResult: WhitelistInformationFragment
) => {
  const cachedTestsPageQuery = cache.readQuery<GetTestsForTestsPageQuery>({
    query: GetTestsForTestsPageDocument,
  });
  if (isSome(cachedTestsPageQuery)) {
    const indexToReplace =
      cachedTestsPageQuery.organization.testResults.findIndex(
        t => t.testId === testResult.testId
      );
    if (!isSome(indexToReplace)) {
      return;
    }
    const newData = {
      ...cachedTestsPageQuery,
      organization: {
        ...cachedTestsPageQuery.organization,
        testResults: [...cachedTestsPageQuery.organization.testResults],
      },
    };
    newData.organization!.testResults[indexToReplace] = {
      ...testResult,
    };

    cache.writeQuery<GetTestsForTestsPageQuery>({
      query: GetTestsForTestsPageDocument,
      data: newData,
    });
  }

  const cachedSingleTestQuery =
    cache.readQuery<FetchTestResultInfoForDetailQuery>({
      query: FetchTestResultInfoForDetailDocument,
      variables: { testId: testResult.testId },
    });
  if (isSome(cachedSingleTestQuery?.organization.testResults[0])) {
    const oldResult = cachedSingleTestQuery!.organization.testResults[0];
    cache.writeQuery<FetchTestResultInfoForDetailQuery>({
      query: FetchTestResultInfoForDetailDocument,
      variables: { testId: testResult.testId },
      data: {
        organization: {
          ...cachedSingleTestQuery!.organization!,
          testResults: [{ ...testResult, history: oldResult.history }],
        },
      },
    });
  }
};
