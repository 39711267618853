import type { Maybe } from "../base/types/maybe";
import { isSome } from "../base/types/maybe";
import { Determiner, vowels } from "./utils";

const VOWELS = ["a", "e", "i", "o", "u"];

/**
 * returns "1 noun" or "n nouns"
 *
 * @param num how many things
 * @param noun what thing
 */
export function simplePlural(num: number, noun: string) {
  if (num === 1) {
    return `1 ${noun}`;
  } else {
    if (
      noun.length >= 2 &&
      noun.charAt(noun.length - 1) === "y" &&
      !VOWELS.includes(noun.charAt(noun.length - 2).toLowerCase())
    ) {
      return `${num} ${noun.substring(0, noun.length - 1)}ies`;
    }
    return `${num} ${noun}s`;
  }
}

/**
 * toSingular naively transforms a plural noun-phrase into a singular form.
 *
 * Some things it may not handle gracefully:
 *
 * + If `pluralNoun` begins with a silent consonant (e.g. "herb") this function
 *   will assign it an awkward indefinite article ("a herb").
 * + If `pluralNoun` does not end with an "s", this function will assume its
 *   singular variant is unchanged.
 *
 * @param pluralNoun - e.g. "load balancers" or "anteaters."
 * @param determiner - if provided, the specified category of singular
 *    determiner is prepended to the singularized noun.
 */
export function toSingular(pluralNoun: string, determiner?: Maybe<Determiner>) {
  const singular = pluralNoun.endsWith("s")
    ? pluralNoun.slice(0, -1)
    : pluralNoun;
  if (!isSome(determiner)) {
    return singular;
  }
  switch (determiner) {
    case Determiner.DefiniteArticle:
      return `the ${singular}`;
    case Determiner.IndefiniteArticle:
      return `${vowels.has(singular[0]) ? "an" : "a"} ${singular}`;
    case Determiner.ProximalDemonstrative:
      return `this ${singular}`;
    case Determiner.DistalDemonstrative:
      return `that ${singular}`;
    default:
      throw new Error(`Determiner ${determiner} isn't handled in switch.`);
  }
  return "";
}
