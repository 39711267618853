import type { PureQueryOptions } from "@apollo/client";
import {
  Button,
  Classes,
  Dialog,
  FormGroup,
  HTMLSelect,
  InputGroup,
  Intent,
  Tooltip,
} from "@blueprintjs/core";
import { DateInput } from "@blueprintjs/datetime";
import { dateStringToDate, toPossibleISOString } from "common/base/dateUtils";
import type { Service } from "common/base/types/helpers";
import { serviceToDisplayName } from "common/base/types/helpers";
import type { Maybe } from "common/base/types/maybe";
import { isSome, nothing } from "common/base/types/maybe";
import gql from "graphql-tag";
import moment from "moment";
import React from "react";

import type { IApolloError } from "../../../errors";
import { isApolloError, LogError } from "../../../errors";
import type {
  AddUserMutation,
  AddUserMutationFn,
} from "../../../gen/components";
import { useAddUserMutation } from "../../../gen/components";
import { UI_DATE_FORMAT_WITHOUT_TIME } from "../../../helpers/common";
import { GroupsContext } from "../people/groups-context";
import type { HrUser } from "./manage-hr-users-dialog";
import { asDateOnlyDate } from "./utils";

interface IProps {
  isOpen: boolean;
  onClose(newUser: Maybe<AddUserMutation["createUser"]>): void;
  refetchQueries?: Maybe<PureQueryOptions[]>;
  // Passed in if creating a user from an existing HR user
  hrUser?: Maybe<HrUser>;
}

interface IInternalProps extends IProps {
  addUserMutation: Maybe<AddUserMutationFn>;
}
interface IState {
  createdUser: Maybe<AddUserMutation["createUser"]>;
  familyName: string;
  givenName: string;
  email: string;
  displayNameError?: Maybe<JSX.Element>;
  emailError?: Maybe<JSX.Element>;
  loading: boolean;
  selectedRoleId?: Maybe<string>;
  startDate: Date;
}

function errorToElem(error: string) {
  return <div className="bp3-form-helper-text">{error}</div>;
}

const emptyState: IState = {
  createdUser: nothing,
  familyName: "",
  givenName: "",
  email: "",
  displayNameError: null,
  emailError: null,
  loading: false,
  startDate: new Date(),
  selectedRoleId: null,
};

class CreateUserDialogComponent extends React.Component<
  IInternalProps,
  IState
> {
  public constructor(props: IInternalProps) {
    super(props);
    this.state = {
      createdUser: nothing,
      familyName: this.props.hrUser?.familyName ?? "",
      givenName: this.props.hrUser?.givenName ?? "",
      email: this.props.hrUser?.email ?? "",
      displayNameError: null,
      emailError: null,
      loading: false,
      startDate: isSome(this.props.hrUser)
        ? asDateOnlyDate(dateStringToDate(this.props.hrUser?.startDate), true)
        : new Date(),
      selectedRoleId: null,
    };

    this.onCreate = this.onCreate.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onDone = this.onDone.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  public UNSAFE_componentWillReceiveProps(nextProps: IInternalProps) {
    if (!this.props.isOpen && nextProps.isOpen) {
      this.setState(emptyState);
    }
  }

  public render() {
    return (
      <Dialog
        isOpen={this.props.isOpen}
        title={"Add person"}
        onClose={this.onCancel}
      >
        {this.renderCreateDialog()}
      </Dialog>
    );
  }

  private handleInputChange(e: React.SyntheticEvent<HTMLInputElement>) {
    const name = e.currentTarget.name;
    if (name === "email") {
      this.setState({
        email: e.currentTarget.value,
      });
    } else if (name === "displayName") {
      this.setState({
        familyName: e.currentTarget.value,
      });
    } else if (name === "givenName") {
      this.setState({ givenName: e.currentTarget.value });
    }
  }

  private onCancel() {
    this.props.onClose(null);
  }

  private onCreate() {
    let hasError = false;
    const errors: {
      displayNameError: Maybe<JSX.Element>;
      emailError: Maybe<JSX.Element>;
    } = {
      displayNameError: null,
      emailError: null,
    };
    if (!Boolean(this.state.email)) {
      hasError = true;
      errors.emailError = errorToElem("This field is required");
    }
    if (!Boolean(this.state.familyName)) {
      hasError = true;
      errors.displayNameError = errorToElem("This field is required");
    }

    this.setState({ ...errors });

    if (hasError) {
      return;
    }

    this.setState({ loading: true });

    if (!this.props.addUserMutation) {
      throw new Error("No addUserMutation!");
    }

    let givenName: Maybe<string> = this.state.givenName.trim();
    if (givenName === "") {
      givenName = null;
    }

    const { familyName, email, selectedRoleId, startDate } = this.state;

    this.props
      .addUserMutation({
        variables: {
          email,
          familyName: familyName.trim(),
          givenName,
          hrUserUniqueId: this.props.hrUser?.uniqueId,
          roleId: selectedRoleId,
          startDate: toPossibleISOString(startDate),
        },
      })
      .then(res => {
        if (res.data) {
          this.props.onClose(res.data.createUser);
        }
      })
      .catch((e: Error | IApolloError) => {
        if (isApolloError(e)) {
          const error = e.graphQLErrors[0];
          let emailError: string;
          switch (error.extensions.code) {
            case "InvalidEmailAddressError":
              emailError = "Please enter a valid email address";
              break;
            case "BAD_USER_INPUT":
              emailError = "Please enter a valid email address";
              break;
            case "RESOURCE_NOT_FOUND":
              emailError = "This email address cannot be found";
              break;
            case "UserAlreadyExistsError":
              emailError =
                "This email address is already an employee at your company";
              break;
            case "UserBelongsToAnotherDomainError":
              emailError =
                "This email address already exists as a Vanta user at another company";
              break;
            default:
              emailError =
                "An unknown error has occurred. Please contact support@vanta.com for further help.";
          }

          this.setState({ emailError: errorToElem(emailError) });
        }
      })
      .then(() => {
        this.setState({ loading: false });
      })
      .catch(e => LogError(e));
  }

  private onDone() {
    this.props.onClose(this.state.createdUser);
  }

  private renderCreateDialog() {
    return (
      <form onSubmit={evt => evt.preventDefault()}>
        <div className={`${Classes.DIALOG_BODY}`}>
          <div>
            <p>
              Enter the name and email address of a person you'd like to add to
              your Vanta account.
            </p>
          </div>
          <FormGroup label="Given name" labelFor="givenName">
            <InputGroup
              autoFocus={true}
              name="givenName"
              id="givenName"
              onChange={this.handleInputChange}
              placeholder="Mamoru"
              type="text"
              value={this.state.givenName}
            />
          </FormGroup>
          <FormGroup
            intent={
              !isSome(this.state.displayNameError) ? undefined : Intent.DANGER
            }
            label="Family name"
            labelFor="displayName"
          >
            <InputGroup
              name="displayName"
              id="displayName"
              intent={
                !isSome(this.state.displayNameError) ? undefined : Intent.DANGER
              }
              onChange={this.handleInputChange}
              placeholder="Llamaski"
              type="text"
              value={this.state.familyName}
            />
            {this.state.displayNameError}
          </FormGroup>
          <FormGroup
            intent={!isSome(this.state.emailError) ? undefined : Intent.DANGER}
            label="Email"
            labelFor="email"
          >
            <InputGroup
              name="email"
              id="email"
              intent={
                !isSome(this.state.emailError) ? undefined : Intent.DANGER
              }
              onChange={this.handleInputChange}
              placeholder="llama@example.com"
              type="email"
              value={this.state.email}
            />
            {this.state.emailError}
          </FormGroup>
          <FormGroup label="Start date" labelFor="startDate">
            <Tooltip
              content={
                isSome(this.props.hrUser)
                  ? `Start date pulled from ${serviceToDisplayName(
                      this.props.hrUser.service as Service
                    )}`
                  : undefined
              }
              disabled={!isSome(this.props.hrUser)}
            >
              <DateInput
                value={this.state.startDate}
                parseDate={s => new Date(s)}
                maxDate={moment().add(20, "years").toDate()}
                popoverProps={{ usePortal: false }}
                formatDate={date =>
                  moment(date).format(UI_DATE_FORMAT_WITHOUT_TIME)
                }
                onChange={date => this.setState({ startDate: date })}
                disabled={isSome(this.props.hrUser)}
              />
            </Tooltip>
          </FormGroup>
          <GroupsContext.Consumer>
            {({ groups }) => (
              <FormGroup label="Group" labelFor="group">
                <HTMLSelect
                  value={this.state.selectedRoleId ?? "No group"}
                  onChange={e => {
                    const selectedRoleId =
                      e.target.value === "No group" ? null : e.target.value;
                    this.setState({ selectedRoleId });
                  }}
                >
                  <option value="No group">No group</option>
                  {groups.map(group => (
                    <option key={group.id} value={group.id}>
                      {group.name}
                    </option>
                  ))}
                </HTMLSelect>
              </FormGroup>
            )}
          </GroupsContext.Consumer>
        </div>
        <div className={`${Classes.DIALOG_FOOTER}`}>
          <div className={`${Classes.DIALOG_FOOTER_ACTIONS}`}>
            <Button onClick={this.onCancel} text="Cancel" />
            <Button
              loading={this.state.loading}
              intent={Intent.PRIMARY}
              disabled={this.state.familyName.trim() === ""}
              onClick={this.onCreate}
              text="Add"
              type="submit"
            />
          </div>
        </div>
      </form>
    );
  }
}

gql`
  mutation addUser(
    $familyName: String!
    $givenName: String
    $email: String!
    $roleId: String
    $startDate: DateTime
    $hrUserUniqueId: String
  ) {
    createUser(
      familyName: $familyName
      givenName: $givenName
      email: $email
      roleId: $roleId
      startDate: $startDate
      hrUserUniqueId: $hrUserUniqueId
    ) {
      id
      ...peoplePageFields
    }
  }
`;

export const CreateUserDialog: React.FC<IProps> = props => {
  const [addUserMutation] = useAddUserMutation({
    refetchQueries: props.refetchQueries ?? [],
  });
  return (
    <CreateUserDialogComponent {...props} addUserMutation={addUserMutation} />
  );
};
