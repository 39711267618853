import { Button, Spinner } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { dateStringToDate } from "common/base/dateUtils";
import { SpecificResource } from "common/base/types/gen";
import type { Maybe } from "common/base/types/maybe";
import { isSome, nothing } from "common/base/types/maybe";
import {
  getValueForReservedVantaAttribute,
  ReservedVantaAttributes,
} from "common/utils/vantaAttributes";
import gql from "graphql-tag";
import React, { useState } from "react";
import { useHistory } from "react-router";

import { BASE_TYPOGRAPHY } from "../../../../alpaca/base/typography";
import {
  BodyText,
  Button as AlpacaButton,
  DefaultView,
} from "../../../../alpaca/components";
import { LogError } from "../../../../errors";
import type { FetchEcrRepositoryVulnerabilityInfoQuery } from "../../../../gen/components";
import {
  FetchEcrRepositoryVulnerabilityInfoDocument,
  useFetchEcrRepositoryVulnerabilityInfoQuery,
} from "../../../../gen/components";
import { DataTable } from "../../components/data-table";
import {
  LoadingContainer,
  PackageIdentifier,
  RightButton,
  TabContainer,
  TableContainer,
  VulnerabilityFindingsHeading,
} from "../common/components";
import { ConfigureImageDialog } from "../common/configure-image-dialog";
import { IgnoreContainerVulnDialog } from "../common/ignore-container-vuln-dialog";
import { LinksForCVEs } from "../common/link-for-cve";
import { SeverityPill } from "../common/severity-pill";
import { SLADeadlineWithDate } from "../common/sla-deadline-with-date";
import { slaDeadlineComparator } from "../utils";
import { TAB_IDS } from "../vulns-page";
import { AWSContainerRepositoryInfo } from "./aws-container-repository-info";
import { ECRRepositoryNoScanState } from "./ecr-repository-no-scan";

interface IProps {
  resourceId: string;
}

type AWSContainerVulnerability = Extract<
  NonNullable<
    FetchEcrRepositoryVulnerabilityInfoQuery["organization"]
  >["resourcesByIds"][number],
  { __typename?: Maybe<"SpecificECRContainerRepositoryResource"> }
>["vulnerabilities"][number];

export const AWSContainerRepositoryDetail: React.FC<IProps> = ({
  resourceId,
}) => {
  const history = useHistory();
  const { error, loading, data } = useFetchEcrRepositoryVulnerabilityInfoQuery({
    variables: { resourceId },
  });
  const [configureDialogIsOpen, setConfigureDialogIsOpen] = useState(false);
  const [vulnToIgnore, setVulnToIgnore] =
    useState<Maybe<AWSContainerVulnerability>>(null);

  if (error) {
    LogError(error);
    history.push(`/vulnerabilities/#${TAB_IDS.awsContainers}`);
    return <div />;
  }

  if (loading) {
    return (
      <LoadingContainer>
        <Spinner />
      </LoadingContainer>
    );
  }

  const repository = data?.organization.resourcesByIds?.[0];
  if (
    !isSome(repository) ||
    repository.__typename !== "SpecificECRContainerRepositoryResource"
  ) {
    LogError(
      new Error("ECR repository not found or found with incorrect __typename")
    );
    return <div />;
  }

  const vulnerabilities = repository.vulnerabilities;
  const imageDigest =
    vulnerabilities.length !== 0 ? vulnerabilities[0].imageDigest : nothing;
  const imageTags =
    vulnerabilities.length !== 0 ? vulnerabilities[0].imageTags : nothing;
  const repoUrl = repository.externalURL!;
  const vulnUrl = vulnerabilities[0]?.externalURL;
  const targetImageScanTag = getValueForReservedVantaAttribute(
    ReservedVantaAttributes.targetImageScanTag,
    repository.vantaAttributes ?? []
  );

  const vulnerabilityDetails =
    vulnerabilities.length === 0 ? (
      <BodyText fontWeight={BASE_TYPOGRAPHY.FONT_WEIGHTS.BOLD}>
        Woohoo! No vulnerabilities have been identified in the image scan.
      </BodyText>
    ) : (
      <TableContainer>
        <VulnerabilityFindingsHeading
          numVulns={vulnerabilities.length}
          service="AWS"
          url={vulnUrl}
          tooltipText={`Vanta filters out "INFORMATIONAL" ECR findings and groups
                findings related to the same vulnerable package together. If
                findings have a CVSSv2 score, Vanta prefers that to the AWS-assigned
                severity value.
                `}
        />
        <DataTable
          columnWidths={["230px", "230px", "400px", "150px", "60px"]}
          columnOrder={["package", "slaDeadline", "findings", "severity"]}
          data={[...vulnerabilities].sort(slaDeadlineComparator)}
          header={{
            package: "Package",
            slaDeadline: "Time until SLA violation",
            findings: "Findings",
            severity: "Severity",
          }}
          menuOptions={() => [
            { text: "Ignore", iconName: IconNames.EYE_OFF, flag: "IGNORE" },
          ]}
          onMenuItemClick={(flag, clickedVuln) => {
            if (flag === "IGNORE") {
              setVulnToIgnore(clickedVuln);
            }
          }}
          createRow={vulnerability => {
            const {
              packageName,
              packageVersion,
              slaDeadline,
              findings,
              severity,
            } = vulnerability;
            return {
              // Although the component refers specifically to CVEs,
              // each finding doesn't necessarily refer to a CVE
              // e.g. could refer to an ALAS, Amazon's vulnerability record
              findings: <LinksForCVEs cves={findings} />,
              package: (
                <PackageIdentifier
                  packageName={packageName}
                  version={packageVersion}
                />
              ),
              slaDeadline: isSome(slaDeadline) ? (
                <SLADeadlineWithDate
                  slaDeadline={dateStringToDate(slaDeadline)}
                />
              ) : (
                <div>-</div>
              ),
              severity: <SeverityPill severity={severity} />,
            };
          }}
        />
      </TableContainer>
    );

  return (
    <DefaultView
      headerProps={{
        backLink: `/vulnerabilities#${TAB_IDS.awsContainers}`,
        title: repository.name,
        description: repository.uniqueId,
        rightControls: [
          <AlpacaButton
            key="view-aws"
            onClick={() => {
              window.open(repoUrl, "_blank");
            }}
          >
            View in AWS
          </AlpacaButton>,
        ],
      }}
    >
      <TabContainer>
        <RightButton>
          <Button icon="more" onClick={() => setConfigureDialogIsOpen(true)} />
        </RightButton>
        <AWSContainerRepositoryInfo
          repositoryName={repository.name}
          repositoryArn={repository.uniqueId}
          account={repository.account}
          imageDigest={imageDigest}
          imageTags={imageTags}
        />

        {(repository.hasBeenScanned ?? true) || vulnerabilities.length > 0 ? (
          vulnerabilityDetails
        ) : (
          <ECRRepositoryNoScanState />
        )}

        <ConfigureImageDialog
          configuredTag={targetImageScanTag}
          specificResource={SpecificResource.ECRContainerRepository}
          resourceMongoId={repository.id}
          isOpen={configureDialogIsOpen}
          onClose={() => setConfigureDialogIsOpen(false)}
        />

        {isSome(vulnToIgnore) ? (
          <IgnoreContainerVulnDialog
            refetchQueries={[
              {
                query: FetchEcrRepositoryVulnerabilityInfoDocument,
                variables: { resourceId },
              },
            ]}
            packageIdentifier={vulnToIgnore.packageIdentifier}
            entityId={vulnToIgnore.id}
            service={"aws"}
            onClose={() => setVulnToIgnore(nothing)}
          />
        ) : null}
      </TabContainer>
    </DefaultView>
  );
};

gql`
  query fetchECRRepositoryVulnerabilityInfo($resourceId: String!) {
    organization {
      id
      resourcesByIds(
        ids: [$resourceId]
        specificResourceType: ECRContainerRepository
      ) {
        id
        uniqueId
        externalURL
        vantaAttributes {
          key
          value
          managedExternally
        }
        ... on SpecificECRContainerRepositoryResource {
          name
          account
          hasBeenScanned
          vulnerabilities {
            id
            imageDigest
            imageTags
            packageName
            packageVersion
            packageIdentifier
            externalURL
            severity
            slaDeadline
            findings {
              name
              uri
            }
          }
        }
      }
    }
  }
`;
