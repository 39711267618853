import { Classes } from "@blueprintjs/core";
import { isSome } from "common/base/types/maybe";
import React, { useState } from "react";

import type { IFormCredentialProps } from "../services/common-interface";
import { SelectOrganization } from "./select-organization";
import { SubmitToken } from "./submit-token";

enum LinkingSteps {
  ADD_TOKEN = "ADD_TOKEN",
  SELECT_ORGANIZATION = "SELECT_ORGANIZATION",
}

export const Snyk: React.FC<IFormCredentialProps> = ({
  credentials,
  onCredentialsLinked,
}) => {
  const [pane, setPane] = useState(LinkingSteps.ADD_TOKEN);
  const [token, setToken] = useState("");
  const existingOrganizationId = isSome(credentials[0]?.metadata)
    ? JSON.parse(credentials[0].metadata).organizationId
    : undefined;
  const panes: {
    [step in LinkingSteps]: {
      content: React.ReactNode;
    };
  } = {
    ADD_TOKEN: {
      content: (
        <SubmitToken
          onSubmitToken={submittedToken => {
            setToken(submittedToken);
            setPane(LinkingSteps.SELECT_ORGANIZATION);
          }}
        />
      ),
    },
    SELECT_ORGANIZATION: {
      content: (
        <SelectOrganization
          token={token}
          existingOrganizationId={existingOrganizationId}
          onCredentialsLinked={onCredentialsLinked}
        />
      ),
    },
  };

  return (
    <div>
      <div className={Classes.DIALOG_HEADER}>Link Snyk</div>
      {panes[pane].content}
    </div>
  );
};
