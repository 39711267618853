import { Intent, TextArea } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import React from "react";
import styled from "styled-components";

import { Button, H2 } from "../../../alpaca/components";

interface IProps {
  textContent: React.ReactNode;
  currentText?: Maybe<string>;
  placeholder?: Maybe<string>;
  onNewValue: (text: string) => void;
  onContinue?: Maybe<() => void>;
}

export const TextAreaPanel: React.FC<IProps> = ({
  textContent,
  currentText,
  placeholder,
  onNewValue,
  onContinue,
}) => (
  <Container>
    <H2>{textContent}</H2>
    <TextArea
      value={currentText ?? undefined}
      placeholder={placeholder ?? undefined}
      onChange={e => {
        onNewValue(e.target.value);
      }}
      growVertically={false}
    />
    {isSome(onContinue) ? (
      <div>
        <StyledButton
          intent={Intent.PRIMARY}
          onClick={onContinue}
          rightIcon={IconNames.ARROW_RIGHT}
          disabled={(currentText ?? "").trim() === ""}
        >
          Continue
        </StyledButton>
      </div>
    ) : null}
  </Container>
);

const styles = {
  CONTAINER_PADDING_BOTTOM: 30,
  BUTTON_MARGIN_TOP: 18,
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 50%;
  padding-bottom: ${styles.CONTAINER_PADDING_BOTTOM}px;
`;

const StyledButton = styled(Button)`
  margin-top: ${styles.BUTTON_MARGIN_TOP}px;
  float: right;
`;
