import { Button, Classes, Dialog, FormGroup, Intent } from "@blueprintjs/core";
import { DateInput } from "@blueprintjs/datetime";
import {
  ALLOWED_RENDER_MIME_TYPES,
  BINARY_MIME_TYPES,
} from "common/base/types/helpers";
import type { Maybe } from "common/base/types/maybe";
import { isSome, nothing } from "common/base/types/maybe";
import React, { useContext, useState } from "react";
import styled from "styled-components";

import { LogError } from "../../../../errors";
import {
  GetPenTestHistoryDocument,
  useUpdateBusinessInfoMutationMutation,
} from "../../../../gen/components";
import { FileInput } from "../../components/file-input";
import { UserContext } from "../../user-context";

export const PenTestUploadButton: React.FC = () => {
  const [mutationInFlight, setMutationInFlight] = useState(false);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<Maybe<File>>(nothing);
  const [selectedDate, setSelectedDate] = useState<Maybe<Date>>(nothing);
  const { user } = useContext(UserContext);
  const [uploadTest] = useUpdateBusinessInfoMutationMutation({
    refetchQueries: [{ query: GetPenTestHistoryDocument }],
    onCompleted: () => {
      setMutationInFlight(false);
      setDialogIsOpen(false);
      setSelectedDate(nothing);
      setSelectedFile(nothing);
    },
  });

  return (
    <div>
      <Button intent={Intent.PRIMARY} onClick={() => setDialogIsOpen(true)}>
        Upload new pen test report
      </Button>
      <Dialog
        // Without this property, the month and year selects in the
        // Date Picker popover will dismiss automatically after presenting.
        enforceFocus={false}
        isOpen={dialogIsOpen}
        onClose={() => setDialogIsOpen(false)}
        title="Upload new pen test report"
      >
        <div className={Classes.DIALOG_BODY}>
          <FormGroup label="Pen test" className="business-info">
            <FileInput
              fileUploaded={(name, file) => setSelectedFile(file)}
              selectedFile={selectedFile}
              accept={[...ALLOWED_RENDER_MIME_TYPES, BINARY_MIME_TYPES.ZIP]}
              name="pen-test-file-upload"
              id="pen-test-file-upload"
            />
          </FormGroup>
          <DatePickerFormGroup
            label="Date of pen test"
            className="business-info"
          >
            <DateInput
              formatDate={date => date.toLocaleDateString()}
              parseDate={str => new Date(str)}
              onChange={val => setSelectedDate(val)}
              value={selectedDate}
            />
          </DatePickerFormGroup>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              onClick={() => {
                setDialogIsOpen(false);
              }}
              disabled={mutationInFlight}
            >
              Cancel
            </Button>
            <Button
              intent={Intent.PRIMARY}
              disabled={!isSome(selectedFile) || !isSome(selectedDate)}
              loading={mutationInFlight}
              onClick={() => {
                uploadTest({
                  variables: {
                    files: [{ file: selectedFile!, fieldName: "penTestFile" }],
                    section: "engineering",
                    answers: JSON.stringify({ penTestDate: selectedDate! }),
                    domainId: user!.domain.id,
                  },
                }).catch(LogError);
                setMutationInFlight(true);
              }}
            >
              Upload
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

const DatePickerFormGroup = styled(FormGroup)`
  & .bp3-html-select {
    width: inherit;
  }
`;
