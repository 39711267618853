import { Spinner } from "@blueprintjs/core";
import type { Service } from "common/base/types/helpers";
import { serviceToDisplayName } from "common/base/types/helpers";
import type { Maybe } from "common/base/types/maybe";
import { dropNothing, isSome } from "common/base/types/maybe";
import { TRAINING_CATEGORIES } from "common/constants/onboarding";
import { mustCompleteSat } from "common/employee-tasks/employee-tasks-utils";
import gql from "graphql-tag";
import { maxBy } from "lodash";
import moment from "moment";
import React from "react";

import { LogError, LogErrorMessage } from "../../../../errors";
import {
  SecurityTrainingCategoryId,
  useFetchDomainPoliciesQuery,
} from "../../../../gen/components";
import { UI_DATE_FORMAT } from "../../../../helpers/common";
import { getTrainingPreferencesMap } from "../../onboarding/employee-onboarding/get-training-preferences";
import { getMostRecentlyCompletedTrainingRequirementForCategory } from "../utils";
import { BackgroundCheckDetails } from "./background-check-details";
import { GenericTaskItem } from "./generic-task-item";
import { InProgressBgCheckButtonWithDialog } from "./in-progress-bg-check-button-with-dialog";
import { LinkBgCheckButtonWithDialog } from "./link-bg-check-button-with-dialog";
import { PolicyAcceptanceDetail } from "./policy-acceptance-detail";
import { SecurityTrainingDetail } from "./security-training-detail";
import { UploadSecurityTrainingButton } from "./upload-security-training-button";
import type { OnboardingListUser } from "./user-onboarding-list";

interface IProps {
  user: OnboardingListUser;
}

export const SecurityRequirementsList: React.FC<IProps> = ({ user }) => {
  const { error, loading, data } = useFetchDomainPoliciesQuery();
  if (error) {
    LogError(error);
    return null;
  }
  if (loading) {
    return <Spinner />;
  }
  if (!data) {
    LogErrorMessage("Bad fetch");
    return null;
  }

  const policiesAreConfigured = data.organization.policies.length > 0;
  const allTasks = getSecurityRequirementTaskList();

  return <>{allTasks.length > 0 ? allTasks : <p>No requirements</p>}</>;

  function getSecurityRequirementTaskList() {
    let backgroundCheckTask: Maybe<JSX.Element>;
    const reqs = user.securityRequirements;
    const hasCompletedBackgroundCheck = Boolean(
      user.hasCompletedBackgroundCheck
    );
    // show BG check task if user has check requirement or has a check
    if (reqs.mustBeBackgroundChecked || hasCompletedBackgroundCheck) {
      const hasBgCheckInProgress = user.backgroundChecks.some(
        check => check.status === "IN PROGRESS"
      );
      const rightControl = hasCompletedBackgroundCheck ? (
        <BackgroundCheckDetails
          userId={user.id}
          userName={user.displayName ?? ""}
        />
      ) : hasBgCheckInProgress ? (
        <InProgressBgCheckButtonWithDialog user={user} />
      ) : (
        <LinkBgCheckButtonWithDialog user={user} />
      );
      backgroundCheckTask = (
        <GenericTaskItem
          key="bg-check-task"
          mainText={"Background check completed"}
          isCompleted={hasCompletedBackgroundCheck}
          rightControl={rightControl}
        />
      );
    }

    let agentDownloadTask: Maybe<JSX.Element>;
    if (user.isActive && reqs.mustInstallLaptopMonitoring) {
      agentDownloadTask = (
        <GenericTaskItem
          key="agent-download-task"
          mainText="Vanta Agent installed"
          isCompleted={Boolean(user.endpointState !== "none")}
        />
      );
    }

    let policyTask: Maybe<JSX.Element>;
    if (reqs.mustAcceptPolicies) {
      policyTask = (
        <GenericTaskItem
          key="accept-policies-task"
          mainText={<span>Accepted policies</span>}
          isCompleted={
            Boolean(user.hasAcceptedAllSecurityPolicies) &&
            policiesAreConfigured
          }
          rightControl={
            <PolicyAcceptanceDetail
              userName={user.displayName ?? ""}
              userId={user.id}
            />
          }
        />
      );
    }

    const satPreferencesByCategory = getTrainingPreferencesMap(
      user.domain.productDescriptionInfo
    );
    const noExternalSATLinked =
      user.domain.externalSATIntegrations.length === 0;
    const securityTrainingTasks = TRAINING_CATEGORIES.map(category => {
      // only one pref per category allowed
      const pref = satPreferencesByCategory[category.id];
      if (
        // haven't set a preference for tracking in Vanta and not using an integration, nothing to track
        noExternalSATLinked &&
        !isSome(pref)
      ) {
        return null;
      }
      if (
        // user isn't required to do this category
        !mustCompleteSat(user.securityRequirements, category.id)
      ) {
        return null;
      }
      const inAppCompletion =
        category.id === SecurityTrainingCategoryId.general
          ? user.mostRecentSecurityTraining
          : category.id === SecurityTrainingCategoryId.hipaa
          ? user.mostRecentHipaaSecurityTraining
          : category.id === SecurityTrainingCategoryId.pci
          ? user.mostRecentPciSecurityTraining
          : category.id === SecurityTrainingCategoryId.gdpr
          ? user.mostRecentGdprSecurityTraining
          : null;

      const integrationCompletion =
        getMostRecentlyCompletedTrainingRequirementForCategory(
          category.id,
          user.trainingRequirements
        );

      const newestCompletion = maxBy(
        [inAppCompletion, integrationCompletion],
        c => c?.completionDate
      );

      const extraDetail =
        isSome(newestCompletion) && "service" in newestCompletion
          ? ` in ${serviceToDisplayName(newestCompletion.service as Service)}`
          : "";

      return (
        <GenericTaskItem
          detailText={
            isSome(newestCompletion)
              ? `Completed ${moment(newestCompletion.completionDate).format(
                  UI_DATE_FORMAT
                )}${extraDetail}`
              : ""
          }
          isCompleted={Boolean(newestCompletion)}
          key={`security-training-task-${category.id}`}
          mainText={CATEGORY_TO_MAIN_TEXT_MAP[category.id]}
          rightControl={
            newestCompletion ? (
              <SecurityTrainingDetail
                completion={newestCompletion}
                userId={user.id}
              />
            ) : noExternalSATLinked &&
              pref?.preferredSecurityTraining === category.customTrainingId ? (
              <UploadSecurityTrainingButton
                trainingId={category.customTrainingId}
                userId={user.id}
              />
            ) : null
          }
        />
      );
    });

    return dropNothing([
      backgroundCheckTask,
      agentDownloadTask,
      policyTask,
      ...securityTrainingTasks,
    ]);
  }
};

const CATEGORY_TO_MAIN_TEXT_MAP = {
  [SecurityTrainingCategoryId.general]: "General security training",
  [SecurityTrainingCategoryId.hipaa]: "HIPAA security training",
  [SecurityTrainingCategoryId.pci]: "PCI DSS security training",
  [SecurityTrainingCategoryId.gdpr]: "GDPR security training",
};

gql`
  query fetchDomainPolicies {
    organization {
      id
      policies {
        id
      }
    }
  }
`;
