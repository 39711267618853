import "./dropdown.scss";

import type { ItemRenderer, ItemListRenderer } from "@blueprintjs/select";
import { Select } from "@blueprintjs/select";
import type { Maybe } from "common/base/types/maybe";
import { getTransformedOrElse, isSome } from "common/base/types/maybe";
import React from "react";
import styled, { css } from "styled-components";

import { GRID_SPACING } from "../../base/grid";
import { Button2 } from "../button2/button2";
import { ButtonClasses } from "../button2/styles";
import type { IconName } from "../icon/iconNames";
import { IconNames } from "../icon/iconNames";
import { Menu2, MenuItem2 } from "../menu2/menu2";

export interface IDropdownProps<T> {
  /**
   * List of items in the dropdown menu.
   */
  items: T[];
  /**
   * Currently selected item.
   */
  selectedItem: Maybe<T>;
  /**
   * Callback invoked when an item from the list is selected.
   */
  onItemSelect: (item: Maybe<T>) => void;

  /**
   * Function invoked to render each item in the menu and in the button
   * when selected. Each rendered item in the menu is wrapped in a MenuItem.
   */
  itemRenderer: (item: T) => Maybe<React.ReactNode | string>;
  /**
   * The default behavior is to render each item with `itemRenderer`, and wrap
   * everything in a Menu. Passing in this prop allows for custom rendering
   * behavior for the contents in the menu, e.g. to add additional items or
   * headers to the menu.
   */
  menuContentRenderer?: Maybe<ItemListRenderer<Maybe<T>>>;
  getIconForItem?: Maybe<(item: T) => Maybe<IconName>>;

  nullItemProps?: Maybe<{
    nullItem?: Maybe<React.ReactNode | string>;
    nullIcon?: Maybe<IconName>;
    /**
     * Contents of the dropdown button when the selected item is null. If this
     * isn't specified, `nullItem` is used.
     */
    nullButtonContent?: Maybe<React.ReactNode | string>;
    /**
     * Whether to place the null item at the end of the menu. By default,
     * the null item is the first item in the menu.
     */
    nullItemLast?: Maybe<boolean>;
  }>;

  large?: Maybe<boolean>;
  width?: Maybe<string>;
  disabled?: Maybe<boolean>;
}

/**
 * Include the trailing comma in the generics to avoid being interpreted as JSX.
 *
 * Unfortunately functional components can't be typed generically with
 * React.FC<IProps<T>> since the type T is declare in another scope.
 */
export const Dropdown = <T,>({
  items,
  selectedItem,
  onItemSelect,
  itemRenderer,
  menuContentRenderer,
  getIconForItem,
  nullItemProps,
  width,
  large,
  disabled,
}: React.PropsWithChildren<IDropdownProps<T>>): JSX.Element => {
  const ItemSelect = Select.ofType<Maybe<T>>();

  const itemsWithNull = isSome(nullItemProps?.nullItem)
    ? Boolean(nullItemProps?.nullItemLast)
      ? [...items, null]
      : [null, ...items]
    : items;

  // Render functions for menu items and menus
  const menuItemRenderer: ItemRenderer<Maybe<T>> = (
    item,
    { handleClick, index }
  ) => {
    const maybeIconName = getTransformedOrElse(
      item,
      i => getIconForItem?.(i),
      nullItemProps?.nullIcon
    );
    return (
      <MenuItem2
        key={`item-${index}`}
        text={getTransformedOrElse(
          item,
          i => itemRenderer(i),
          nullItemProps?.nullItem
        )}
        icon={maybeIconName}
        onClick={handleClick}
      />
    );
  };
  const menuRenderer: ItemListRenderer<Maybe<T>> = args => {
    const { filteredItems, itemsParentRef, renderItem } = args;
    const menuContent = isSome(menuContentRenderer)
      ? menuContentRenderer(args)
      : filteredItems.map(renderItem);
    return (
      <StyledMenu ulRef={itemsParentRef} minWidth={width}>
        {menuContent}
      </StyledMenu>
    );
  };

  return (
    <StyledSelectContainer width={width}>
      <ItemSelect
        items={itemsWithNull}
        itemRenderer={menuItemRenderer}
        itemListRenderer={menuRenderer}
        onItemSelect={onItemSelect}
        popoverProps={{
          minimal: true,
          usePortal: true,
          placement: "bottom-start",
          popoverClassName: "alpaca-dropdown-popover",
        }}
        filterable={false}
      >
        <StyledButton
          rightIcon={IconNames.CHEVRON_DOWN}
          large={large}
          width={width}
          disabled={disabled}
        >
          {isSome(selectedItem)
            ? itemRenderer(selectedItem)
            : nullItemProps?.nullButtonContent ??
              nullItemProps?.nullItem ??
              "Select an option"}
        </StyledButton>
      </ItemSelect>
    </StyledSelectContainer>
  );
};

const StyledSelectContainer = styled.div<{ width?: Maybe<string> }>`
  ${({ width }) =>
    isSome(width)
      ? css`
          width: ${width};

          .bp3-popover-wrapper,
          .bp3-popover-target {
            width: ${width};
          }
        `
      : ""}

  // apply "focused" style to dropdown button when menu is open
  .bp3-popover-open .${ButtonClasses.BUTTON} {
    box-shadow: 0px 0px 0px 4px rgba(135, 92, 255, 0.12),
      0px 2px 6px rgba(51, 51, 51, 0.12);
  }
`;

const StyledMenu = styled(Menu2)<{ minWidth?: Maybe<string> }>`
  ${({ minWidth }) =>
    isSome(minWidth)
      ? css`
          min-width: ${minWidth};
        `
      : ""}
`;

const StyledButton = styled(Button2)<{ width?: Maybe<string> }>`
  ${({ width }) =>
    isSome(width)
      ? css`
          width: ${width};
        `
      : ""}

  &.${ButtonClasses.BUTTON} > div {
    width: 100%;
    justify-content: space-between;
  }

  .${ButtonClasses.ICON} {
    margin-left: ${2 * GRID_SPACING}px;
  }
`;
