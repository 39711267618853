import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { PermissionLevel } from "common/base/types/gen";
import gql from "graphql-tag";
import React, { useState } from "react";
import { Redirect } from "react-router";
import styled from "styled-components";

import { VANTA_COLORS } from "../../../alpaca/base/colors";
import { LogError } from "../../../errors";
import type { NewAdminInfo } from "../../../gen/components";
import {
  useCheckPermissionLevelQuery,
  useSendAdminInvitationsMutation,
} from "../../../gen/components";
import { DefaultLink } from "../../../helpers/links";
import { AppToaster } from "../../../helpers/toaster";
import { FullPageSpinner } from "../../helpers/FullPageSpinner";
import { NewAdminForm } from "./new-admin-form";

export const AdminInvitePage: React.FC = () => {
  const [newAdmins, setNewAdmins] = useState<NewAdminInfo[]>([]);
  const [sendInvitations, mutationResult] = useSendAdminInvitationsMutation({
    onCompleted() {
      AppToaster.show({
        message: "Invitations have been sent",
        intent: Intent.SUCCESS,
      });
      window.location.pathname = "/";
    },
  });
  const { loading, error, data } = useCheckPermissionLevelQuery();
  if (loading) {
    return <FullPageSpinner />;
  }
  if (error || !data) {
    LogError(error ?? new Error("Bad fetch"));
    return <Redirect to="/" />;
  }

  // No logged in user
  if (!data.user) {
    return <Redirect to="/" />;
  }

  if (data.user.permissionLevel !== PermissionLevel.Admin) {
    return <div>Vanta administrator access is required for this page.</div>;
  }

  const domainName = data.user.domain.name;

  const adminList = (
    <div>
      <h5>Prospective admin list</h5>
      {newAdmins.length > 0 ? (
        <UL>
          {newAdmins.map((adminInfo, i) => (
            <li key={`admin_email_${i}`}>
              {`${adminInfo.givenName} ${adminInfo.familyName} (${adminInfo.email}@${domainName})`}
              <Button
                icon={IconNames.DELETE}
                intent={Intent.DANGER}
                minimal
                onClick={() => {
                  setNewAdmins(
                    newAdmins.slice(0, i).concat(newAdmins.slice(i + 1))
                  );
                }}
              />
            </li>
          ))}
        </UL>
      ) : null}
    </div>
  );

  return (
    <FullPage>
      <h2>Invite admins to Vanta</h2>
      <Container>
        <Columned>
          <div>
            <p>Welcome!</p>
            <p>
              Use this page to invite team members to Vanta as administrators.
            </p>
            <p>
              If you are not an administrator for {domainName}'s identity
              provider (Google Workspace, Office 365, Okta), you'll want to
              include the admin for that service here. Once you have connected
              your identity provider to Vanta, you'll be able to create
              additional admins at any time from the Company Settings {">"}{" "}
              Users page.
            </p>
            <NewAdminForm
              domainName={domainName}
              onNewAdmin={adminInfo =>
                setNewAdmins(newAdmins.concat(adminInfo))
              }
            />
          </div>
          {adminList}
        </Columned>
        <Footer>
          {newAdmins.length > 0 ? (
            <Button
              intent={Intent.PRIMARY}
              loading={mutationResult.called}
              large
              onClick={() => {
                sendInvitations({
                  variables: {
                    newAdmins,
                  },
                }).catch(LogError);
              }}
            >
              Send Invitations
            </Button>
          ) : (
            <DefaultLink href="/">Skip this step</DefaultLink>
          )}
        </Footer>
      </Container>
    </FullPage>
  );
};

const FullPage = styled.div`
  background-color: ${VANTA_COLORS.BACKGROUND_APP};
  min-height: 100vh;
  width: 100%;
  padding: 100px 0;
  h2 {
    margin: 0 auto 24px;
    width: 900px;
  }
`;

const Container = styled.div`
  margin: 0 auto;
  width: 900px;
  background-color: ${VANTA_COLORS.BACKGROUND_WHITE};
  padding: 40px;
  border-radius: 4px;
`;

const Columned = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  & > div {
    width: 48%;
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const UL = styled.ul`
  list-style: none;
  padding-left: 0;
`;

gql`
  query CheckPermissionLevel {
    user {
      id
      permissionLevel
      domain {
        id
        name
      }
    }
  }
`;

gql`
  mutation SendAdminInvitations($newAdmins: [newAdminInfo!]!) {
    sendAdminInvitations(newAdmins: $newAdmins)
  }
`;
