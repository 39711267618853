import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import type {
  IRiskCategory,
  IRiskSchema,
} from "common/schemas/riskRegister/risk-register-content";
import React from "react";
import { Redirect } from "react-router";

import { VANTA_COLORS } from "../../../alpaca/base/colors";
import TaskSVG from "../../../static/images/svg/task_writing.svg";
import { StepCompletionPanel } from "../../master-detail-view/detail-panels/step-completion-panel";
import { StepIntroductionPanel } from "../../master-detail-view/detail-panels/step-intro-panel";
import { PATHS } from "../../vanta-chrome/paths";
import { DismissedRiskPanel } from "./dismissed-risk-panel";
import { RISK_ICONS } from "./icons";
import type { RiskAssessmentDomain } from "./queries-and-types";
import { RiskAssetSelectionFlow } from "./risk-asset-selection-flow";
import type { RiskFormController } from "./risk-form-controller";
import { RiskScenarioEvaluationFlow } from "./risk-scenario-evaluation-flow";
import { RiskScenarioList } from "./risk-scenario-list";
import { RiskTaskEditor } from "./risk-task-editor";

interface IProps {
  category: IRiskCategory;
  domain: RiskAssessmentDomain;
  formController: RiskFormController;
}

const TASK_INTRODUCTORY_COPY =
  "In this section you will create tasks —think of this like a to-do list— to help mitigate the risks you identified.";

const RiskIconForRisk = (risk?: Maybe<IRiskSchema>) => {
  if (!isSome(risk)) {
    return TaskSVG;
  } else {
    return RISK_ICONS[risk.icon as keyof typeof RISK_ICONS];
  }
};

export const RiskFormDetail: React.FC<IProps> = ({
  category,
  domain,
  formController,
}) => {
  const currentRisk = category.risks[formController.currentStepIndex];
  const dismissals = domain.riskDismissals;

  if (formController.isStepStart) {
    const SVG = RiskIconForRisk(currentRisk);
    return (
      <StepIntroductionPanel
        stepName={isSome(currentRisk) ? currentRisk.shortHeading : "Tasks"}
        stepIndex={formController.currentStepIndex}
        description={currentRisk?.introductoryText ?? TASK_INTRODUCTORY_COPY}
        image={<SVG fill={VANTA_COLORS.VANTA_PURPLE} />}
      />
    );
  }

  if (formController.isStepCompletion) {
    return (
      <StepCompletionPanel
        textContent={
          <span>
            Step {formController.currentStepIndex + 1} of your{" "}
            {category.breadcrumbText} assessment is complete!
          </span>
        }
      />
    );
  }

  if (formController.isTaskEditor) {
    const scenario = domain.rrv3RiskScenarios.find(
      s => s.id === formController.scenarioId
    );
    if (isSome(scenario)) {
      return (
        <RiskTaskEditor riskCategoryId={category.id} scenario={scenario} />
      );
    }
  }

  if (formController.showAssetList) {
    if (dismissals.find(d => d.riskId === currentRisk.id)) {
      return <DismissedRiskPanel riskId={currentRisk.id} />;
    }
    return (
      <RiskAssetSelectionFlow
        riskSchema={currentRisk}
        assets={domain.rrv3Assets.filter(
          i => i.riskCategoryId === currentRisk.id
        )}
      />
    );
  } else if (formController.isEditingScenario()) {
    return (
      <RiskScenarioEvaluationFlow
        schema={currentRisk}
        onComplete={scenarioId => formController.gotoTasks(scenarioId)}
        scenario={domain.rrv3RiskScenarios.find(
          s => s.id === formController.scenarioId
        )}
      />
    );
  } else if (formController.showScenarioList) {
    return <RiskScenarioList domain={domain} risk={currentRisk} />;
  } else {
    return <Redirect to={PATHS.RISK_ASSESSMENT} />;
  }
};
