import React from "react";
import { GRID_SPACING } from "../../../alpaca/base/grid";
import { DefaultView } from "../../../alpaca/components";
import { Card } from "../components/card";

export const DocsInventoryList: React.FC = () => (
  <DefaultView
    headerProps={{ backLink: "/inventory", title: "Inventory List" }}
  >
    <Card style={{ padding: 3 * GRID_SPACING }}>
      <p>
        Creating a complete inventory list is a good first step to a successful
        compliance project.
      </p>

      <p>
        Vanta gathers information about your cloud configuration and laptops
        with the Vanta app and pre-populates this information on the inventory
        page.
      </p>

      <p>
        You will need to manually enter components that are within your
        organization's control or on your corporate network. This can include
        office network devices, end user devices, or additional infrastructure
        components that are not hosted on a Vanta-supported cloud provider.
      </p>

      <h3>Examples</h3>

      <ul>
        <li>
          Corporate network devices: ISP modems, wireless routers, office
          switches, etc.
        </li>
        <li>
          Corporate office devices: copiers, printers and sign-in iPads that
          typically connect to the corporate network.
        </li>
        <li>
          End user devices not assigned to a person, e.g. laptops in storage for
          future employees.
        </li>
        <li>On-premise data center presence (servers, databases, etc.)</li>
        <li>Domains and subdomains that require vulnerability scanning</li>
      </ul>

      <h3>Optional, not required for an audit</h3>

      <ul>
        <li>
          Internet of Things (IoT) kitchen devices, such as an office
          coffeemaker
        </li>
      </ul>
    </Card>
  </DefaultView>
);
