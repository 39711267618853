import type { Maybe } from "common/base/types/maybe";
import React from "react";

import type { AccountsAccessCredential } from "./access-page";

interface ICredentialsContext {
  credentials: AccountsAccessCredential[];
  defaultCredential: Maybe<AccountsAccessCredential>;
}

export const CredentialsContext = React.createContext<ICredentialsContext>({
  credentials: [],
  defaultCredential: null,
});
