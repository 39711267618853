import { AnchorButton, Button, Intent, Spinner } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React, { useState } from "react";

import { LogError } from "../../../../errors";
import {
  GetPenTestHistoryDocument,
  useDeletePenTestDocMutation,
} from "../../../../gen/components";
import { DefaultLink } from "../../../../helpers/links";
import { CancelConfirmDialog } from "../../../helpers/CancelConfirmDialog";
import type { PenTest } from "./pen-test-component";

interface IProps {
  penTest: PenTest;
}

export const PenTestTableActions: React.FC<IProps> = ({ penTest }) => {
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [deletePenTest, mutationResult] = useDeletePenTestDocMutation({
    refetchQueries: [{ query: GetPenTestHistoryDocument }],
  });
  if (mutationResult.called) {
    return <Spinner size={Spinner.SIZE_SMALL} />;
  }
  return (
    <div>
      <AnchorButton
        href={`${location.origin}/doc?s=${penTest.slugId}`}
        download={penTest.filename ?? "Pen-test.pdf"}
        minimal
        intent={Intent.PRIMARY}
        icon={IconNames.DOWNLOAD}
      >
        Download
      </AnchorButton>
      <Button
        minimal
        onClick={() => setDialogIsOpen(true)}
        icon={IconNames.TRASH}
        intent={Intent.DANGER}
      >
        Remove
      </Button>
      {dialogIsOpen ? (
        <CancelConfirmDialog
          isOpen={true}
          onClose={() => setDialogIsOpen(false)}
          onConfirm={() => {
            deletePenTest({
              variables: {
                uploadedDocumentId: penTest.id,
              },
            }).catch(LogError);
            setDialogIsOpen(false);
          }}
          title="Delete pen test?"
          confirmText="Delete"
          body={
            <div>
              <p>Are you sure you want to delete this file?</p>
              <DefaultLink href={`${location.origin}/${penTest.url}`}>
                {penTest.filename ?? penTest.url}
              </DefaultLink>
            </div>
          }
        />
      ) : null}
    </div>
  );
};
