import type { Maybe } from "common/base/types/maybe";
import { isSome, nothing } from "common/base/types/maybe";
import React from "react";

import { Caption, Heading } from "../common/components";

interface IProps {
  text: string;
}

export const VulnRemediation: React.FC<IProps> = ({ text }) => {
  const maybeRichRemediation = maybeSplitRemediationText(text);
  if (!isSome(maybeRichRemediation)) {
    return <Heading>{text}</Heading>;
  }
  const { packageName, to, from } = maybeRichRemediation;
  return (
    <div>
      <Heading>Upgrade {packageName}</Heading>
      <Caption>
        {from} &#8594; {to}
      </Caption>
    </div>
  );
};

interface IRichRemediation {
  packageName: string;
  from: string;
  to: string;
}

const REMEDIATION_REGEX = /^(?:Upgrade )(.* .*)(?: to )(.*)$/;
const PACKAGE_REGEX = /(\(.*\)) to /;

const maybeSplitRemediationText: (text: string) => Maybe<IRichRemediation> =
  text => {
    const maybePackage = PACKAGE_REGEX.exec(text);
    let packageText = "";
    if (isSome(maybePackage)) {
      text = text.replace(maybePackage[1], "");
      packageText = ` ${maybePackage[1]}`;
    }
    const match = REMEDIATION_REGEX.exec(text);
    if (!isSome(match) || match.length !== 3) {
      return nothing;
    }
    const packageWithFrom = match[1];
    const firstSpace = packageWithFrom.indexOf(" ");

    return {
      packageName: `${packageWithFrom.slice(0, firstSpace)}${packageText}`,
      from: packageWithFrom.slice(firstSpace + 1),
      to: match[2],
    };
  };
