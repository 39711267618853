import "./query-page.scss";

import { InputGroup, PanelStack } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import React, { useState } from "react";
import { Redirect } from "react-router";
import { Route } from "react-router-dom";
import styled from "styled-components";

import { GRID_SPACING } from "../../alpaca/base/grid";
import { LogError, LogErrorMessage } from "../../errors";
import type { AllDomainsMinimalQuery } from "../../gen/components";
import {
  useAllDomainsMinimalQuery,
  useGetUserInfoQueryQuery,
} from "../../gen/components";
import { AllDomainSuggest } from "../helpers/all-domain-suggest";
import { CopyClipboardIcon } from "../helpers/CopyClipboardIcon";
import { FullPageSpinner } from "../helpers/FullPageSpinner";
import { CustomerInfoPage } from "../pages/admin/admin-customers";
import { TestRemediationViewer } from "../test-remediation-viewer/test-remediation-viewer";
import { AuditCalendarPanel } from "./audit-calendar-panel";
import { AuditorsPanel } from "./auditors-panel";
import { CronFilterPanel } from "./cron-filter-panel";
import { EmailKillSwitchPanel } from "./email-kill-panel/email-kill-switch-panel";
import { EmailsPanel } from "./emails";
import { FeaturePanel } from "./feature-panel";
import { IdLookupPanel } from "./id-lookup";
import type { IPageSchema, IPanelRoute } from "./internal-page";
import { InternalPage } from "./internal-page";
import { NewCustomersPanel } from "./new-customers-panel";
import { OSQueryEndpointsPanel } from "./osquery-endpoints-panel";
import { OSQueryPanel } from "./osquery-panel";
import { PeopleQueryPage } from "./people-query-page";
import { PolicyPanel } from "./policy-panel";
import { RiskRegisterPanel } from "./risk-register-panel";
import { RunTestPanel } from "./run-test-panel";
import { TestRolloutPanel } from "./test-rollout";
import { TestRunHistoryPanel } from "./test-run-history-panel";
import { TestSelectionPanel } from "./test-selection-panel";
import { TestsPanel } from "./tests-panel";
import { TriggerResourceFetchPanel } from "./trigger-resource-fetch-panel";

interface IRenderProps {
  domainId?: Maybe<string>;
  domains: NonNullable<AllDomainsMinimalQuery["internal"]["allDomains"]>;
  setDomain: (domainId: string) => void;
}

type QueryRenderFunction = (props: IRenderProps) => React.ReactNode;
type IQueryPanelRoute = Omit<IPanelRoute, "render"> & {
  render?: Maybe<QueryRenderFunction>;
};

const makeRenderFunction =
  (PageComponent: React.ComponentType<any>): QueryRenderFunction =>
  props =>
    isSome(props.domainId) ? (
      <PageComponent {...props} />
    ) : (
      <StyledNoDomainStateDiv>Select a domain.</StyledNoDomainStateDiv>
    );

const QUERY_PAGE_SCHEMA: { [heading: string]: IQueryPanelRoute[] } = {
  "Customer Access & Billing": [
    {
      name: "Customer information",
      path: "/query/customerInfo",
      render: makeRenderFunction(CustomerInfoPage),
    },
    {
      name: "New customers",
      path: "/query/newCustomers",
      component: NewCustomersPanel,
    },
    {
      name: "Features",
      path: "/query/features",
      render: makeRenderFunction(FeaturePanel),
    },
    {
      name: "People",
      path: "/query/people",
      render: makeRenderFunction(PeopleQueryPage),
    },
  ],
  "Core": [
    {
      name: "View test details",
      path: "/query/remediationText",
      component: TestRemediationViewer,
    },
    {
      name: "View test run history",
      path: "/query/testRunHistory",
      render: makeRenderFunction(TestRunHistoryPanel),
    },
    {
      name: "Roll out tests",
      path: "/query/rolloutTests",
      component: TestRolloutPanel,
    },
    {
      name: "Run tests",
      path: "/query/testRunner",
      render: makeRenderFunction(RunTestPanel),
    },
    {
      name: "Killswitch tests",
      path: "/query/tests",
      component: TestsPanel,
    },
    {
      name: "Test selection",
      path: "/query/testSelection",
      render: makeRenderFunction(TestSelectionPanel),
    },
    {
      name: "Fetch resources",
      path: "/query/resourceFetch",
      render: makeRenderFunction(TriggerResourceFetchPanel),
    },
    {
      name: "Cron filters",
      path: "/query/cronFilters",
      component: CronFilterPanel,
    },
    {
      name: "Policies",
      path: "/query/policies",
      render: makeRenderFunction(PolicyPanel),
    },
  ],
  "Vanta Agent": [
    {
      name: "Query devices",
      path: "/query/osquery",
      render: makeRenderFunction(OSQueryPanel),
    },
    {
      name: "Vanta agents",
      path: "/query/osqueryendpoints",
      render: makeRenderFunction(OSQueryEndpointsPanel),
    },
  ],
  "Audits": [
    {
      name: "Audit calendar",
      path: "/query/audit-calendar",
      render: () => (
        <PanelStack
          initialPanel={{
            component: AuditCalendarPanel,
            title: "Audit Calendar",
            props: {},
          }}
        />
      ),
    },
    {
      name: "Auditors",
      path: "/query/auditors",
      component: AuditorsPanel,
    },
  ],
  "Emails": [
    {
      name: "Emails",
      path: "/query/emailtext",
      component: EmailsPanel,
    },
    {
      name: "Disable emails",
      path: "/query/emailKillSwitch",
      component: EmailKillSwitchPanel,
    },
  ],
  "Global info": [
    {
      name: "ID lookup",
      path: "/query/lookup",
      render: ({ domains, setDomain }) => (
        <IdLookupPanel domains={domains} setDomain={setDomain} />
      ),
    },
    {
      name: "Risk register v1 questions",
      path: "/query/riskRegister",
      render: ({ domainId }) => <RiskRegisterPanel domainId={domainId} />,
    },
  ],
};

export const QueryPage: React.FC = () => {
  const [domainId, setDomainId] = useState<Maybe<string>>(null);
  const allDomainQuery = useAllDomainsMinimalQuery();
  const getUserInfoQuery = useGetUserInfoQueryQuery();
  if (allDomainQuery.error) {
    LogError(allDomainQuery.error);
    return null;
  }
  if (getUserInfoQuery.error) {
    LogError(getUserInfoQuery.error);
    return null;
  }
  if (allDomainQuery.loading || getUserInfoQuery.loading) {
    return <FullPageSpinner />;
  }
  if (
    !isSome(allDomainQuery.data) ||
    !isSome(allDomainQuery.data.internal.allDomains) ||
    !isSome(getUserInfoQuery.data) ||
    !isSome(getUserInfoQuery.data.user)
  ) {
    LogErrorMessage("Bad fetch");
    return null;
  }

  if (!Boolean(getUserInfoQuery.data.user.isAssumedSuperUser)) {
    return <Redirect to="/" />;
  }

  const domainSelector = (
    <div className="domain-selector-box">
      <p>Selected domain:</p>
      <AllDomainSuggest onSelect={newDomainId => setDomainId(newDomainId)} />
      {isSome(domainId) ? (
        <InputGroup
          value={domainId}
          readOnly={true}
          disabled={true}
          rightElement={<CopyClipboardIcon text={domainId} />}
        />
      ) : undefined}
    </div>
  );

  const domains = allDomainQuery.data.internal.allDomains;
  const schemaWithInjectedProps: IPageSchema = {};
  Object.entries(QUERY_PAGE_SCHEMA).forEach(([heading, routes]) => {
    const routesWithInjectedProps = routes.map(route => {
      return {
        ...route,
        render: isSome(route.render)
          ? () =>
              route.render!({
                domainId,
                domains,
                setDomain: setDomainId,
              })
          : undefined,
      };
    });
    schemaWithInjectedProps[heading] = routesWithInjectedProps;
  });
  const additionalRoutes = (
    <Route
      exact
      path="/query"
      render={
        isSome(domainId)
          ? () => <CustomerInfoPage domainId={domainId} />
          : () => <IdLookupPanel domains={domains} setDomain={setDomainId} />
      }
    />
  );
  return (
    <InternalPage
      additionalRoutes={additionalRoutes}
      schema={schemaWithInjectedProps}
      sidebarHeading={domainSelector}
    />
  );
};

gql`
  query AllDomainsMinimal {
    internal {
      allDomains {
        id
        name
      }
    }
  }
`;

const StyledNoDomainStateDiv = styled.div`
  padding: ${2 * GRID_SPACING}px ${2 * GRID_SPACING}px ${GRID_SPACING}px 0;
`;
