import { simplePlural } from "common/grammar/plurals";
import React from "react";

import type { GetDataForReportQuery } from "../../../../../gen/components";
import { ControlReportType } from "./vanta-report-list";

interface IProps {
  control: NonNullable<
    GetDataForReportQuery["complianceStandard"]
  >["principles"][number]["requirements"][number]["controls"][number];
  controlReportType: ControlReportType;
}

export const VantaReportCountDisplay: React.FunctionComponent<IProps> = ({
  control,
  controlReportType,
}) => {
  const isForTest = controlReportType === ControlReportType.Test;
  const count = isForTest ? control.tests.length : control.evidence.length;
  return (
    <div className="vrp-test-count-display">
      {`${simplePlural(count, isForTest ? "test" : "document").toUpperCase()}`}
    </div>
  );
};
