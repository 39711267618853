import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import qs from "qs";
import React, { useEffect, useState, useContext } from "react";
import type { RouteComponentProps } from "react-router";
import { Redirect, withRouter } from "react-router";
import styled from "styled-components";
import { isUUID } from "validator";

import { VANTA_COLORS } from "../../alpaca/base/colors";
import { LogError } from "../../errors";
import { useRegisterDeviceToUserMutation } from "../../gen/components";
import { FullPageSpinner } from "../helpers/FullPageSpinner";
import { UserContext } from "../pages/user-context";

gql`
  mutation registerDeviceToUser($uuid: String!, $userId: ID!) {
    registerDevice(uuid: $uuid, userId: $userId)
  }
`;

const Component: React.FunctionComponent<RouteComponentProps> = ({
  location,
}) => {
  const { user, error } = useContext(UserContext);
  const [registerDevice, registerResult] = useRegisterDeviceToUserMutation();
  const [registered, setRegistered] = useState(false);
  useEffect(() => {
    if (!registered && isSome(user)) {
      const doRegistration = async () => {
        const r = await registerDevice({
          variables: {
            uuid,
            userId: user.id,
          },
        });
        if (r.data) {
          setRegistered(true);
        }
      };
      doRegistration().catch(LogError);
    }
  }, [user, registerDevice, registered]);
  const params = qs.parse(location.search, { ignoreQueryPrefix: true });
  if (!("uuid" in params) || !isUUID(params.uuid)) {
    LogError(
      new Error("Attempt made to register with invalid identifier"),
      false
    );
    return <div>Cannot register. Please contact support@vanta.com</div>;
  }
  if (error) {
    LogError(error);
    return <div />;
  }

  const uuid: string = params.uuid;
  if (!isSome(user)) {
    // can't use <Redirect> component here because middleware
    // (re-redirecting to login) doesn't work.
    window.location.href = `/login/agent/register?uuid=${uuid}`;
  }

  if (registered) {
    return <Redirect to={`/agent/info?uuid=${uuid}`} />;
  }

  if (registerResult.error) {
    return (
      <Container>
        <p>
          We could not register your device. Try refreshing this page after
          running:
        </p>
        <p>
          <strong>MacOS:</strong>
        </p>
        <p>
          <code>sudo /usr/local/vanta/vanta-cli reset</code>
        </p>
        <p>
          <strong>Windows:</strong>
        </p>
        <p>
          <code>C:\ProgramData\Vanta\vanta-cli.exe reset</code>
        </p>
        <strong>Linux:</strong>
        <p>
          <code>sudo /var/vanta/vanta-cli reset</code>
        </p>
        <p>
          on your computer from the command line. If you continue to experience
          problems, contact support@vanta.com.
        </p>
      </Container>
    );
  }

  return <FullPageSpinner text="Registering device..." />;
};

export const AgentRegisterPage = withRouter(Component);

const Container = styled.div`
  max-width: 500px;
  margin: 150px auto;
  code {
    background-color: ${VANTA_COLORS.BACKGROUND_TAG};
  }
`;
