import React from "react";

import { DefaultLink } from "../../../../helpers/links";
import { SERVICE_DETAILS } from "../../../credentials/service-groups";
import { SimpleCredentialFormDialogBody } from "../simple-credential-form-dialog-body";
import type { IFormCredentialProps } from "./common-interface";

export const Certn: React.FunctionComponent<IFormCredentialProps> = ({
  onCredentialsLinked,
}) => {
  const helperText = (
    <span>
      Create a Certn{" "}
      <DefaultLink href="https://docs.certn.co/#section/Authentication">
        access token
      </DefaultLink>
      .
    </span>
  );
  return (
    <SimpleCredentialFormDialogBody
      labels={[{ name: "token", displayName: "Access Token", helperText }]}
      service={SERVICE_DETAILS.certn}
      onCredentialsLinked={onCredentialsLinked}
    />
  );
};
