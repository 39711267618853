import { Button } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { isSome, nothing } from "common/base/types/maybe";
import moment from "moment";
import React, { useState } from "react";

import type { FetchChangesQuery } from "../../../gen/components";
import { UI_DATE_FORMAT_WITHOUT_TIME } from "../../../helpers/common";
import { DefaultLink } from "../../../helpers/links";
import { DataTable } from "../components/data-table";
import { ChangeDialog } from "./change-dialog";

export type Change = NonNullable<
  NonNullable<FetchChangesQuery["changesForRepoInMonth"]["changeList"]>
>[0];

interface IProps {
  changes: Change[];
  nextPageUrl: Maybe<string>;
  service: string;
  onLoadMore: () => void;
}

const COLUMN_ORDER = [
  "id",
  "title",
  "author",
  "createdAt",
  "closedAt",
  "reviewers",
];
const HEADERS = {
  id: "ID",
  title: "Title",
  author: "Author",
  createdAt: "Created",
  closedAt: "Closed",
  reviewers: "Approved by",
};

export const ChangeTable: React.FC<IProps> = ({
  service,
  changes,
  nextPageUrl,
  onLoadMore,
}) => {
  const [activeChangeDetails, setActiveDetails] =
    useState<Maybe<Change>>(nothing);

  const createRow = (data: Change) => {
    return {
      id: (
        <DefaultLink
          href="javascript:void(0)"
          onClick={() => setActiveDetails(data)}
        >
          {data.id}
        </DefaultLink>
      ),
      title: data.title,
      author: data.authorAccountId,
      createdAt: moment(data.createdAt).format(UI_DATE_FORMAT_WITHOUT_TIME),
      closedAt: isSome(data.closedAt)
        ? moment(data.closedAt).format(UI_DATE_FORMAT_WITHOUT_TIME)
        : "Not closed",
      reviewers: isSome(data.reviewerAccountIds)
        ? data.reviewerAccountIds.join(", ")
        : "",
    };
  };

  return (
    <div>
      <DataTable
        columnOrder={COLUMN_ORDER}
        data={changes}
        header={HEADERS}
        createRow={createRow}
      />
      <ChangeDialog
        service={service}
        activeChangeDetails={activeChangeDetails}
        onClose={() => setActiveDetails(nothing)}
      />
      {isSome(nextPageUrl) ? (
        <Button onClick={onLoadMore}>Load more</Button>
      ) : null}
    </div>
  );
};
