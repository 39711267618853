import { Button, ControlGroup, Divider, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { getAccountIdFromArnAndRegions } from "common/resources/specific-resources/aws/aws-helpers-client-safe";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { RenderCredential } from "../render-credential";
import type { Credential } from "../services/common-interface";
import { LinkingSteps } from "./aws-credentials";
const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 8px;
`;
export const AccountsList: React.FC<{
  credentials: Credential[];
  setPane: (pane: LinkingSteps) => void;
  setRegions: (regions: string[]) => void;
  setArn: (arn: string) => void;
  setEdit: (edit: boolean) => void;
  setDisconnectDialogOpen: (open: boolean) => void;
  setCredentialIdToDelete: (id: string) => void;
}> = ({
  credentials,
  setRegions,
  setPane,
  setArn,
  setEdit,
  setCredentialIdToDelete,
  setDisconnectDialogOpen,
}) => (
  <div>
    <p>Connected AWS Accounts</p>
    <hr></hr>
    {credentials
      .map(credential => {
        const parsed = JSON.parse(credential.metadata!);
        const parsedArn = parsed.assumeRoleArn;
        const parsedRegions = parsed.regions;
        const accountId = getAccountIdFromArnAndRegions(
          parsedArn,
          parsedRegions
        );
        return { accountId, credential, parsed, parsedArn, parsedRegions };
      })
      .sort((a, b) => a.accountId.localeCompare(b.accountId))
      .map(({ accountId, credential, parsed, parsedArn, parsedRegions }) => {
        if ("assumeRoleArn" in parsed) {
          return (
            <RenderCredential
              key={credential.id}
              credential={credential}
              accountId={accountId}
              editCredential={() => {
                setArn(parsedArn);
                setRegions(parsedRegions);
                setEdit(true);
                setPane(LinkingSteps.REGION);
              }}
              deleteCredential={() => {
                setCredentialIdToDelete(credential.id);
                setArn(parsedArn);
                setDisconnectDialogOpen(true);
              }}
            />
          );
        } else {
          return (
            <Flex key={"govcloud"}>
              <div>{accountId}</div>
              <ControlGroup>
                <Link to={`/connectgovcloud`}>
                  <Button minimal intent={Intent.PRIMARY}>
                    Manage
                  </Button>
                </Link>
                <Divider></Divider>
                <Button
                  minimal
                  icon={IconNames.TRASH}
                  intent={Intent.DANGER}
                  onClick={() => {
                    setCredentialIdToDelete(credential.id);
                    setArn("govcloud");
                    setDisconnectDialogOpen(true);
                  }}
                ></Button>
              </ControlGroup>
            </Flex>
          );
        }
      })}
  </div>
);
