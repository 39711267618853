import "./vanta-report-main-body.scss";

import { isSome } from "common/base/types/maybe";
import { compact } from "lodash";
import React from "react";

import type { GetDataForReportQuery } from "../../../../gen/components";
import { TestOutcome } from "../../../../gen/components";
import { ShouldDisplayTestResult } from "../../../assessments/helpers";
import type { IDomainForReport } from "../../helpers/helpers";
import type { IReportProps } from "../../vanta-report-page";
import { VantaReportAboutText } from "./about-text/vanta-report-about-text";
import { VantaReportAppendices } from "./appendices/vanta-report-appendices";
import { VantaReportIntroduction } from "./introduction/vanta-report-introduction";
import { VantaReportPrincipleList } from "./principle-list/vanta-report-principle-list";
import { VantaReportSummary } from "./summary/vanta-report-summary";

export const VantaReportMainBody: React.FunctionComponent<IReportProps> =
  props => {
    const standard = props.complianceStandard.standard;

    // Only display principles, requirements, tests, and controls with passing tests
    // or required evidence
    const showableComplianceStandard = {
      ...props.complianceStandard,
      principles: compact(
        props.complianceStandard.principles.map(principle => {
          const stagedPrinciple = {
            ...principle,
            requirements: compact(
              principle.requirements.map(req => {
                const stagedReq = {
                  ...req,
                  controls: compact(
                    req.controls.map(control =>
                      maybeFilterEvidenceAndTests(control, props.domain)
                    )
                  ),
                };
                return stagedReq.controls.length > 0 ? stagedReq : null;
              })
            ),
          };
          return stagedPrinciple.requirements.length > 0
            ? stagedPrinciple
            : null;
        })
      ),
    };

    const propsToPass = {
      ...props,
      complianceStandard: showableComplianceStandard,
    };

    return (
      <>
        {standard === "vanta" ? <VantaReportSummary {...propsToPass} /> : null}
        <div className="vrp-main-body-report-body">
          <VantaReportIntroduction {...propsToPass} />
          <VantaReportPrincipleList {...propsToPass} />
          <VantaReportAppendices {...propsToPass} />
          <VantaReportAboutText {...propsToPass} />
        </div>
      </>
    );
  };

const maybeFilterEvidenceAndTests = (
  control: NonNullable<
    GetDataForReportQuery["complianceStandard"]
  >["principles"][number]["requirements"][number]["controls"][number],
  domain: IDomainForReport
) => {
  const shouldShowAll = !domain.showSuccessOnly;
  if (shouldShowAll) {
    return control.tests.length === 0 && control.evidence.length === 0
      ? null
      : control;
  }

  const tests = control.tests.filter(test =>
    ShouldDisplayTestResult(domain.testIdToTestMap.get(test.testId), true)
  );
  const evidence = control.evidence.filter(evidenceId => {
    const evidenceVal = domain.evidenceIdToEvidenceMap.get(evidenceId);
    return isSome(evidenceVal) && evidenceVal?.outcome === TestOutcome.PASS;
  });

  return tests.length === 0 && evidence.length === 0
    ? null
    : {
        ...control,
        tests,
        evidence,
      };
};
