import "./login.scss";

import {
  Button,
  FormGroup,
  InputGroup,
  Intent,
  Toaster,
} from "@blueprintjs/core";
import { isSome } from "common/base/types/maybe";
import {
  PREVIEW_HOSTNAME,
  STAGING_HOSTNAME,
} from "common/constants/client-safe";
import gql from "graphql-tag";
import React from "react";
import styled from "styled-components";
import { isEmail } from "validator";

import { VANTA_COLORS } from "../../alpaca/base/colors";
import { Tooltip } from "../../alpaca/components";
import { isApolloError, LogError, LogErrorMessage } from "../../errors";
import type { SendLoginLinkMutationFn } from "../../gen/components";
import {
  useGetUserLoginQuery,
  useSendLoginLinkMutation,
} from "../../gen/components";
import { FullPageSpinner } from "../helpers/FullPageSpinner";
import { GoogleLoginButton } from "../login-components/google/google-login-button";
import { MicrosoftLoginButton } from "../login-components/office/microsoft-login-button";
import { OktaLoginButton } from "../login-components/okta/okta-login-button";
import { VANTA_LOGO_SIZES, VantaLogo } from "./components/vanta-logo";

interface IProps {
  sendLoginLink: SendLoginLinkMutationFn;
}

interface IState {
  continueWithEmailExpanded: boolean;
  oktaExpanded: boolean;
  email: string;
  loginLinkLoading: boolean;
  loginLinkGenerated: boolean;
}

function getContinueParameter() {
  const urlParams = new URLSearchParams(window.location.search);
  // "next" exists for backwards compatibility
  return urlParams.get("continue") ?? urlParams.get("next");
}

function getGoogleLoginUrl(): string {
  const continueParameter = getContinueParameter();
  return isSome(continueParameter)
    ? `/auth/login/google?continue=${continueParameter}`
    : `/auth/login/google`;
}

function getMicrosoftLoginUrl(): string {
  const next = getContinueParameter();
  return isSome(next)
    ? `/auth/officelogin?continue=${next}`
    : `/auth/officelogin`;
}

class LoginPageComponent extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
    this.state = {
      continueWithEmailExpanded: false,
      oktaExpanded: false,
      email: "",
      loginLinkLoading: false,
      loginLinkGenerated: false,
    };

    this.handleContinueWithEmail = this.handleContinueWithEmail.bind(this);
    this.handleEmailAddressChange = this.handleEmailAddressChange.bind(this);
    this.sendLoginLink = this.sendLoginLink.bind(this);
  }

  public render() {
    const hasValidEmail = isEmail(this.state.email);
    const emailSubmitButton = (
      <StyledButton
        className="log-in-button"
        intent={Intent.PRIMARY}
        onClick={this.sendLoginLink}
        loading={this.state.loginLinkLoading}
        disabled={this.state.loginLinkGenerated || !hasValidEmail}
        large={true}
        type="submit"
      >
        Sign in with email
      </StyledButton>
    );
    const emailSubmitButtonMaybeWithTooltip = hasValidEmail ? (
      emailSubmitButton
    ) : (
      <Tooltip content="Please enter a valid email" placement="bottom">
        {emailSubmitButton}
      </Tooltip>
    );

    const continueWithEmailOrExpanded = this.state.oktaExpanded ? null : this
        .state.continueWithEmailExpanded ? (
      <div className="log-in-continue-with-email">
        <hr />
        <form>
          <FormGroup>
            <InputGroup
              autoFocus={true}
              id="email"
              onChange={this.handleEmailAddressChange}
              placeholder="sally@example.com"
              type="email"
              value={this.state.email}
            />
          </FormGroup>
          {emailSubmitButtonMaybeWithTooltip}
        </form>
      </div>
    ) : (
      <div className="log-in-email">
        <StyledLink
          href="javascript:void(0)"
          onClick={this.handleContinueWithEmail}
        >
          Sign in with email
        </StyledLink>
      </div>
    );

    const signInWithOktaOrExpanded = this.state.oktaExpanded ? (
      <div>
        <div>Work email address</div>
        <form>
          <CenteredDiv>
            <FormGroup>
              <InputGroup
                autoFocus={true}
                id="email"
                onChange={this.handleEmailAddressChange}
                placeholder="sally@example.com"
                type="email"
                value={this.state.email}
              />
            </FormGroup>
          </CenteredDiv>
          <Button
            intent={Intent.PRIMARY}
            disabled={!hasValidEmail}
            large={true}
            type="submit"
            onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
              e.preventDefault();
              window.location.href = `/auth/login/okta/callback?email=${this.state.email}`;
            }}
          >
            Sign in with Okta
          </Button>
        </form>
      </div>
    ) : (
      <LoginDiv onClick={() => this.setState({ oktaExpanded: true })}>
        <OktaLoginButton />
      </LoginDiv>
    );

    const signInWithGoogleOrHide = this.state.oktaExpanded ? null : (
      <LoginDiv>
        <GoogleLoginButton loginLink={getGoogleLoginUrl()}></GoogleLoginButton>
      </LoginDiv>
    );

    const signInWithMicrosoftOrHide = this.state.oktaExpanded ? null : (
      <LoginDiv>
        <MicrosoftLoginButton
          loginLink={getMicrosoftLoginUrl()}
        ></MicrosoftLoginButton>
      </LoginDiv>
    );

    return (
      <div
        className="flex-center-container"
        style={{ justifyContent: "space-between" }}
      >
        <div className="flex-center-content log-in-content">
          <h1>
            <VantaLogo height={VANTA_LOGO_SIZES.LOGIN} />
          </h1>
          {signInWithGoogleOrHide}
          {signInWithMicrosoftOrHide}
          {signInWithOktaOrExpanded}
          {continueWithEmailOrExpanded}
        </div>
      </div>
    );
  }

  private handleContinueWithEmail() {
    this.setState({ continueWithEmailExpanded: true });
  }

  private handleEmailAddressChange(e: React.SyntheticEvent<HTMLInputElement>) {
    this.setState({ email: e.currentTarget.value });
  }

  private sendLoginLink() {
    this.setState({ loginLinkLoading: true });

    const hostname = `${location.protocol}//${location.host}`;
    this.props
      .sendLoginLink({ variables: { email: this.state.email, hostname } })
      .then(() => {
        this.setState({ loginLinkLoading: false, loginLinkGenerated: true });
        const message =
          PREVIEW_HOSTNAME.test(hostname) || hostname === STAGING_HOSTNAME ? (
            <div>
              A sign-in link has been sent to the{" "}
              <StyledLink href="https://groups.google.com/a/vanta.com/g/staging-emails-receive">
                staging-emails-receive google group
              </StyledLink>
            </div>
          ) : hostname.includes("127.0.0.1") ? (
            "A sign-in link has been printed to the web server console"
          ) : (
            "A sign-in link has been sent to your email!"
          );

        Toaster.create({
          className: "bp3-minimal",
        }).show({
          icon: "tick",
          intent: Intent.SUCCESS,
          message,
          timeout: 5000,
        });
      })
      .catch((e: Error) => {
        if (isApolloError(e)) {
          const error = e.graphQLErrors[0];
          let errorText: string;

          switch (error.extensions.code) {
            case "TooManyOutstandingLoginLinks":
              errorText =
                "You've created too many links for that email address -- please wait before trying again.";
              break;
            default:
              errorText =
                "Something went wrong! Please contact support@vanta.com for help.";
          }

          Toaster.create({
            className: "bp3-minimal",
          }).show({
            icon: "error",
            intent: Intent.DANGER,
            message: errorText,
            timeout: 5000,
          });
        } else {
          throw e;
        }
      });
  }
}

gql`
  query getUserLogin {
    user {
      id
    }
  }
`;

gql`
  mutation sendLoginLink($email: String!, $hostname: String) {
    sendLoginLink(email: $email, hostname: $hostname)
  }
`;

export const LoginPage: React.FC = () => {
  const [sendLoginLink] = useSendLoginLinkMutation();
  const { error, loading, data } = useGetUserLoginQuery();
  if (error) {
    LogError(error);
    return null;
  }
  if (loading) {
    return <FullPageSpinner />;
  }
  if (!data) {
    LogErrorMessage("Bad fetch");
    return null;
  }
  if (isSome(data.user)) {
    const continueParameter = getContinueParameter();
    location.href = continueParameter ?? "/auth/redirectAfterLogin";
    return <div />;
  }

  return <LoginPageComponent sendLoginLink={sendLoginLink} />;
};

const LoginDiv = styled.div`
  margin-bottom: 20px;
`;

const CenteredDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`;

const StyledButton = styled(Button)`
  && {
    background-color: ${VANTA_COLORS.LOGIN_BUTTON};
    &:hover,
    &:active {
      background-color: ${VANTA_COLORS.LOGIN_BUTTON_ACTIVE};
    }
    &.bp3-button.bp3-intent-primary.bp3-disabled {
      background-color: ${VANTA_COLORS.LOGIN_BUTTON}cc;
    }
  }
`;

const StyledLink = styled.a`
  color: ${VANTA_COLORS.LOGIN_BUTTON};
  &:hover {
    color: ${VANTA_COLORS.LOGIN_BUTTON_ACTIVE};
  }
`;
