import {
  AnchorButton,
  Button,
  Classes,
  Dialog,
  Intent,
  MenuItem,
  Spinner,
} from "@blueprintjs/core";
import { Suggest } from "@blueprintjs/select";
import type { Maybe } from "common/base/types/maybe";
import { assertSome, isSome } from "common/base/types/maybe";
import { ReservedVantaAttributes } from "common/utils/vantaAttributes";
import React, { useState } from "react";

import { LogError } from "../../errors";
import type { AllUsersQuery } from "../../gen/components";
import {
  FetchDomainEndpointsDocument,
  useAllUsersQuery,
  useSetInventoryResourceVantaAttributeMutation,
  useSetLaptopOwnerMutation,
} from "../../gen/components";
import { AppToaster } from "../../helpers/toaster";
import type { IUserWorkstation } from "./computers/utils";
import {
  ManagementMethod,
  specificResourceFromWorkstation,
} from "./computers/utils";
import { setResourceVantaAttributeFn } from "./inventory-list/utils";

type User = NonNullable<AllUsersQuery["organization"]>["users"][number];

const UserSuggest = Suggest.ofType<User>();

interface IProps {
  workstation: Maybe<IUserWorkstation>;
  isOpen: boolean;
  onClose: () => void;
}

export const ComputerOwnerAssignDialog: React.FC<IProps> = ({
  workstation,
  isOpen,
  onClose,
}) => {
  const { data, loading, error } = useAllUsersQuery();

  const [selectedUser, setSelectedUser] = useState<Maybe<User>>(null);

  // Run a different mutation depending on whether the workstation is a Vanta
  // Agent-managed workstation or an MDM-managed workstation.
  const [setAgentWorkstationOwner] = useSetLaptopOwnerMutation({
    refetchQueries: [{ query: FetchDomainEndpointsDocument }],
    awaitRefetchQueries: true,
  });
  const [setResourceVantaAttribute] =
    useSetInventoryResourceVantaAttributeMutation({
      refetchQueries: [{ query: FetchDomainEndpointsDocument }],
      awaitRefetchQueries: true,
    });
  async function setOwner() {
    if (!isSome(workstation) || !isSome(selectedUser)) {
      return;
    }
    if (workstation.managedVia === ManagementMethod.VantaAgent) {
      assertSome(workstation.udid);
      onClose();
      await setAgentWorkstationOwner({
        variables: {
          userId: selectedUser.id,
          uuid: workstation.udid,
        },
      });
      AppToaster.show({
        intent: Intent.SUCCESS,
        message: "Computer reassigned",
      });
    } else {
      const specificResourceKind = specificResourceFromWorkstation(workstation);
      if (!isSome(specificResourceKind)) {
        LogError(
          new Error(
            "specific resource for workstation not found: " + workstation.id
          )
        );
        return;
      }
      const setResourceWorkstationOwner = setResourceVantaAttributeFn(
        setResourceVantaAttribute,
        specificResourceKind,
        workstation.id,
        workstation.__typename
      );
      onClose();
      await setResourceWorkstationOwner(
        ReservedVantaAttributes.ownerId.key,
        selectedUser.id
      );
    }
  }

  if (error) {
    LogError(error);
    return null;
  }

  if (loading) {
    return <div />;
  }

  if (!data?.organization) {
    LogError(new Error("No user data"));
    return null;
  }

  return (
    <Dialog isOpen={isOpen} onClose={onClose} title={"Assign computer owner"}>
      <div className={Classes.DIALOG_BODY}>
        {loading ? (
          <Spinner />
        ) : (
          <UserSuggest
            fill={true}
            inputValueRenderer={getName}
            itemPredicate={(query, choice) =>
              getName(choice).toLowerCase().includes(query.toLowerCase())
            }
            itemRenderer={(user, { handleClick }) => (
              <MenuItem
                key={user.id}
                onClick={handleClick}
                text={getName(user)}
              />
            )}
            items={data.organization.users
              .filter(u => u.isActive && !u.isNotHuman)
              .sort((a, b) => getName(a).localeCompare(getName(b)))}
            onItemSelect={user => setSelectedUser(user)}
            popoverProps={{ minimal: true }}
          ></UserSuggest>
        )}
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={onClose}>Close</Button>
          <AnchorButton intent={Intent.PRIMARY} onClick={setOwner}>
            Assign
          </AnchorButton>
        </div>
      </div>
    </Dialog>
  );
};

function getName(user: User) {
  return isSome(user.displayName) ? user.displayName : user.email;
}
