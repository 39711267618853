import { Classes, Intent } from "@blueprintjs/core";
import { isSome } from "common/base/types/maybe";
import React, { useState } from "react";
import { Button } from "../../../../alpaca/components";
import { useSetCredentialsMutation } from "../../../../gen/components";
import { DefaultLink } from "../../../../helpers/links";
import { AppToaster } from "../../../../helpers/toaster";
import { SERVICE_DETAILS } from "../../../credentials/service-groups";
import type { IFormCredentialProps } from "../services/common-interface";
import type { IInputValues } from "../simple-credential-form-dialog-body";
import { SimpleCredentialFormDialogBody } from "../simple-credential-form-dialog-body";
import { IdTrainings } from "./id-trainings";

enum LinkingSteps {
  ADD_TOKEN = "ADD_TOKEN",
  ID_TRAININGS = "ID_TRAININGS",
}

export const KnowBe4: React.FunctionComponent<IFormCredentialProps> = ({
  credentials,
  onCredentialsLinked,
}) => {
  const [lastUpdated, setLastUpdated] = useState(
    credentials.length === 0 ? "" : credentials[0].lastUpdated
  );
  const [pane, setPane] = useState(
    credentials.length === 0
      ? LinkingSteps.ADD_TOKEN
      : LinkingSteps.ID_TRAININGS
  );
  const [setCredentials] = useSetCredentialsMutation();

  const saveButton: React.FC<{
    canSubmit: boolean;
    inputValues: IInputValues;
  }> = ({ canSubmit, inputValues }) => (
    <Button
      intent={Intent.PRIMARY}
      disabled={!canSubmit}
      onClick={async () => {
        const { data } = await setCredentials({
          variables: {
            service: SERVICE_DETAILS.knowbe4.id,
            credentials: JSON.stringify(inputValues),
          },
        });
        const knowBe4Cred = data?.setCredentials?.credentials.find(
          c => c.service === "knowbe4"
        );
        if (isSome(knowBe4Cred)) {
          setLastUpdated(knowBe4Cred.lastUpdated);
          setPane(LinkingSteps.ID_TRAININGS);
        }
      }}
    >
      Store token
    </Button>
  );

  const panes: {
    [pane in LinkingSteps]: {
      header: string;
      content: React.ReactNode;
    };
  } = {
    ADD_TOKEN: {
      header: "Connect API Token",
      content: (
        <SimpleCredentialFormDialogBody
          labels={[
            {
              name: "token",
              displayName: "API token",
              helperText: (
                <span>
                  Find your API token on your{" "}
                  <DefaultLink href="https://training.knowbe4.com/ui/account/info#api-settings">
                    account settings
                  </DefaultLink>
                  .
                </span>
              ),
            },
          ]}
          OverrideSaveButton={saveButton}
          service={SERVICE_DETAILS.knowbe4}
          showTitle={false}
        />
      ),
    },
    ID_TRAININGS: {
      header: "Choose security campaigns",
      content: (
        <IdTrainings
          pollStart={new Date(lastUpdated)}
          onSave={onCredentialsLinked}
          onBack={() => setPane(LinkingSteps.ADD_TOKEN)}
          onFail={() => {
            AppToaster.show({
              icon: "error",
              intent: Intent.DANGER,
              message: "Unable to fetch training campaigns.",
              timeout: 2500,
            });
            setPane(LinkingSteps.ADD_TOKEN);
          }}
        />
      ),
    },
  };

  return (
    <div>
      <div className={Classes.DIALOG_HEADER}>{panes[pane].header}</div>
      <div className={Classes.DIALOG_BODY}>{panes[pane].content}</div>
    </div>
  );
};
