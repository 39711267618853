import type { SpecificDatadogMonitorResource } from "common/base/types/gen";
import gql from "graphql-tag";
import moment from "moment";

import { UI_DATE_FORMAT } from "../../../../helpers/common";

export const datadogDataTableParameters = {
  columnOrder: [
    "id",
    "overall_state",
    "name",
    "query",
    "type",
    "message",
    "created",
    "modified",
  ],
  displayName: "Datadog Monitors",
  header: {
    query: "Query",
    message: "Message",
    id: "ID",
    name: "Name",
    created: "Created",
    modified: "Modified",
    overall_state: "Overall State",
    type: "Type",
  },
  createRow: (monitor: Partial<SpecificDatadogMonitorResource>) => {
    return {
      query: monitor.query,
      message: monitor.message,
      id: monitor.uniqueId,
      name: monitor.name,
      created: moment(monitor.created).format(UI_DATE_FORMAT),
      modified: moment(monitor.modified).format(UI_DATE_FORMAT),
      overall_state: monitor.overall_state,
      type: monitor.type,
    };
  },
};

gql`
  query fetchDatadogMonitorInfo {
    organization {
      id
      displayName
      alarms: resources(first: 1000, specificResourceType: DatadogMonitor) {
        totalCount
        edges {
          node {
            id
            ... on SpecificDatadogMonitorResource {
              query
              message
              created
              modified
              overall_state
              type
              id
              name
            }
          }
        }
      }
    }
  }
`;
