import { HTMLTable, Spinner } from "@blueprintjs/core";
import React from "react";

import { LogError } from "../../../../errors";
import { useAccessibleMachinesQuery } from "../../../../gen/components";

export const AuthorizedKeysTable: React.FC<{ osqueryId: string }> = ({
  osqueryId,
}) => {
  const { loading, error, data } = useAccessibleMachinesQuery({
    variables: { endpointId: osqueryId },
  });

  if (loading) {
    return <Spinner />;
  }

  const endpoint = data?.organization.endpoints[0];

  if (error || !data || !endpoint || !("authorizedKeys" in endpoint.data)) {
    LogError(error ?? Error("no data found"));
    return <div>Error fetching keys</div>;
  }

  const table = (
    <HTMLTable
      striped={true}
      interactive={false}
      className="inventory-list-card-table"
    >
      <thead>
        <tr>
          <th>Access from</th>
          <th>Via key in file</th>
        </tr>
      </thead>
      <tbody>
        {endpoint.data.authorizedKeys?.flatMap((key, ixK) =>
          key.machinesWithAccessToThisOne?.edges.map((machine, ixM) => (
            <tr key={`${ixK}_${ixM}`}>
              <td>{machine.node.prettyName}</td>
              <td>{key.key_file}</td>
            </tr>
          ))
        )}
      </tbody>
    </HTMLTable>
  );

  return table;
};
