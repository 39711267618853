import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import type { IRiskCategory } from "common/schemas/riskRegister/risk-register-content";
import type { useHistory } from "react-router-dom";

import { LogError } from "../../../errors";
import { PATHS } from "../../vanta-chrome/paths";
import type { RiskAssessmentDomain } from "./queries-and-types";

const RISK_SCENARIO_ID_PARAM = "scenarioId";
const RISK_TASK_PARAMS = "tasks";

interface IArgs {
  urlSearchString: string;
  domain: RiskAssessmentDomain;
  history: ReturnType<typeof useHistory>;
  category: IRiskCategory;
  stepParam: Maybe<string>;
  substepParam: Maybe<string>;
}

/**
 * Utility class for handling navigation in the
 * risk assessment wizard flow.
 */
export class RiskFormController {
  public readonly scenarioId: Maybe<string> = null;
  public readonly isFirstPass: boolean = false;
  public readonly isTaskEditor: boolean = false;
  public readonly isStepStart: boolean = false;
  public readonly isStepCompletion: boolean = false;
  public readonly currentStepIndex: number = 0;
  public readonly showAssetList: boolean = false;
  public readonly showScenarioList: boolean = false;

  /**
   * Text to display in a tooltip over a disabled button if action is
   * required to continue.
   */
  public readonly tooltipText: Maybe<string> = null;

  private readonly riskIsDismissed: boolean = false;
  private readonly riskHasScenario: boolean = false;
  private readonly history: ReturnType<typeof useHistory>;
  private readonly numberOfSteps: number = 0;
  private readonly stepIsCompleted: boolean = false;
  private readonly categoryURL: string = "";

  constructor(args: IArgs) {
    const {
      history,
      urlSearchString,
      category,
      domain,
      stepParam,
      substepParam,
    } = args;

    // Validate the step and substep parameters
    const stepNumber = parseInt(stepParam ?? "NaN", 10);
    if (
      isNaN(stepNumber) ||
      stepNumber < 0 ||
      stepNumber > category.risks.length
    ) {
      throw new Error("Invalid step number");
    }

    let substepNumber: Maybe<number>;
    if (isSome(substepParam)) {
      substepNumber = parseInt(substepParam ?? "-1", 10);
      if (isNaN(substepNumber) || substepNumber < 0 || substepNumber > 2) {
        throw new Error("Invalid substepnumber");
      }
    }

    this.currentStepIndex = stepNumber;
    this.showAssetList = substepNumber === 0;
    this.isFirstPass =
      this.showAssetList &&
      domain.rrv3RiskScenarios.every(
        s => s.riskCategoryId !== category.risks[this.currentStepIndex].id
      );

    this.showScenarioList = substepNumber === 1;
    this.isStepCompletion = substepNumber === 2;
    this.isStepStart = !isSome(substepNumber);

    const searchParams = new URLSearchParams(urlSearchString);
    this.scenarioId = searchParams.get(RISK_SCENARIO_ID_PARAM);
    this.isTaskEditor = isSome(searchParams.get(RISK_TASK_PARAMS));

    this.history = history;
    this.categoryURL = category.url;
    this.numberOfSteps = category.risks.length;

    const riskId = category.risks[stepNumber].id;
    this.riskIsDismissed = domain.riskDismissals.some(d => d.riskId === riskId);
    this.riskHasScenario = domain.rrv3RiskScenarios.some(
      s => s.riskCategoryId === riskId
    );

    this.stepIsCompleted = true;
    if (isSome(this.scenarioId)) {
      this.tooltipText = "The scenario evaluation is incomplete";
      this.stepIsCompleted = this.isTaskEditor;
    }
  }

  public isEditingScenario() {
    return isSome(this.scenarioId) && !this.isTaskEditor;
  }

  public navigateToLocation(stepIndex: number, substepIndex?: Maybe<number>) {
    this.history.push(
      `${this.pathForCategoryAndStep(stepIndex)}${
        isSome(substepIndex) ? `/${substepIndex}` : ""
      }`
    );
  }

  public canProceed() {
    return this.stepIsCompleted || this.isStepCompletion || this.isStepStart;
  }

  public gotoCompleteStep() {
    this.history.push(this.pathForCategoryAndStep() + "/2");
  }

  public gotoNextStep() {
    if (this.currentStepIndex === this.numberOfSteps - 1) {
      this.exitForm();
    } else {
      this.history.push(this.pathForCategoryAndStep(this.currentStepIndex + 1));
    }
  }

  public gotoNextPage() {
    if (
      this.isStepCompletion &&
      this.currentStepIndex === this.numberOfSteps - 1
    ) {
      this.exitForm();
    } else if (this.showAssetList && this.riskIsDismissed) {
      this.gotoCompleteStep();
    } else {
      this.history.push(
        `${this.pathForCategoryAndStep(
          this.isStepCompletion
            ? this.currentStepIndex + 1
            : this.currentStepIndex
        )}${this.getSubpathAndSearchStringForNextPage()}`
      );
    }
  }

  public gotoTasks(scenarioId: string) {
    const search = `?${RISK_SCENARIO_ID_PARAM}=${scenarioId}&${RISK_TASK_PARAMS}=1`;
    this.history.push(`${this.pathForCategoryAndStep()}/1${search}`);
  }

  public exitForm() {
    this.history.push(PATHS.RISK_ASSESSMENT);
  }

  private getSubpathAndSearchStringForNextPage() {
    if (this.isStepCompletion) {
      return ``;
    } else if (this.isStepStart) {
      return `/0`;
    } else if (this.isTaskEditor) {
      return `/1`;
    } else if (this.showAssetList) {
      return `/1${
        this.riskHasScenario ? "" : `?${RISK_SCENARIO_ID_PARAM}=new`
      }`;
    } else if (isSome(this.scenarioId)) {
      return `/1?${RISK_SCENARIO_ID_PARAM}=${this.scenarioId}&${RISK_TASK_PARAMS}=1`;
    } else if (this.showScenarioList) {
      return `/2`;
    } else {
      LogError(
        new Error("Could not get URL search string for Risk register page"),
        false
      );
      return null;
    }
  }

  private pathForCategoryAndStep(stepIndex: number = this.currentStepIndex) {
    return `${PATHS.RISK_ASSESSMENT}/${this.categoryURL}/${stepIndex}`;
  }
}
