import classnames from "classnames";
import type { Maybe } from "common/base/types/maybe";
import { getTransformedOrElse } from "common/base/types/maybe";
import React from "react";
import styled from "styled-components";

import { Icon } from "../icon/icon";
import type { IconName } from "../icon/iconNames";
import type { ITooltipProps } from "../tooltip/tooltip";
import { Tooltip } from "../tooltip/tooltip";
import { ButtonClasses, ICON_BUTTON_CLASS_STYLES } from "./styles";

export interface IIconButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon: IconName;
  large?: Maybe<boolean>;
  small?: Maybe<boolean>;
  tooltipProps?: Maybe<ITooltipProps>;
}

function getClassNamesFromProps(props: IIconButtonProps) {
  return classnames(props.className, ButtonClasses.ICON_BUTTON, {
    [ButtonClasses.LARGE]: props.large,
    [ButtonClasses.SMALL]: props.small,
    [ButtonClasses.DISABLED]: props.disabled,
  });
}

export const IconButton: React.FC<IIconButtonProps> = ({
  tooltipProps,
  ...props
}) => {
  const button = (
    <StyledButton {...props} className={getClassNamesFromProps(props)}>
      <Icon icon={props.icon} className={ButtonClasses.ICON} />
    </StyledButton>
  );
  const buttonMaybeWithTooltip = getTransformedOrElse(
    tooltipProps,
    tp => <Tooltip {...tp}>{button}</Tooltip>,
    button
  );
  return buttonMaybeWithTooltip;
};

const StyledButton = styled.button`
  ${ICON_BUTTON_CLASS_STYLES}
`;
