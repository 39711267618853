import { Button, Intent } from "@blueprintjs/core";
import React from "react";

import type { FetchHerokuAppListQuery } from "../../../../gen/components";
import { Checkbox } from "../../../form-controls/checkbox";
import { SelectorDataTable } from "../../components/selector-data-table";
import type { IHerokuApp, IHerokuScanConfig } from "./heroku-apps-selector";
import { updateRowSelection } from "./utils";
interface IHerokuAppsTableProps {
  appList: FetchHerokuAppListQuery["organization"]["herokuApps"];
  previouslySelectedRows: any[];
  updateHerokuScanConfig: (scanConfig: IHerokuScanConfig) => Promise<void>;
  scanAllAppsToggle: boolean;
}

export const HerokuAppsTable: React.FC<IHerokuAppsTableProps> = props => {
  const {
    appList,
    previouslySelectedRows,
    updateHerokuScanConfig,
    scanAllAppsToggle,
  } = props;

  const herokuHeader = { name: "App Name", id: "App ID" };
  const herokuAppColumnOrder = ["name", "id"];
  const herokuAppColumnClasses = {
    checkbox: "heroku-apps-table-col heroku-apps-checkbox",
    name: "heroku-apps-table-col heroku-apps-name",
    id: "heroku-apps-table-col heroku-apps-id",
  };
  const [isAllAppsToggleSelected, setAllAppsToggleSelected] =
    React.useState(scanAllAppsToggle);
  const handleAllAppsToggleChange = (e: React.SyntheticEvent) => {
    setAllAppsToggleSelected(!isAllAppsToggleSelected);
  };
  const [selectedRows, setSelectedRows] = React.useState<IHerokuApp[]>(
    previouslySelectedRows
  );
  const handleRowSelected = (tableData: IHerokuApp[]) => (row: IHerokuApp) => {
    setSelectedRows(updateRowSelection(tableData, row, selectedRows));
  };
  const handleSelectAll = (tableData: IHerokuApp[]) => () => {
    if (tableData.length === selectedRows.length) {
      setSelectedRows([]);
    } else {
      setSelectedRows(tableData);
    }
  };

  const updateApps = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    await updateHerokuScanConfig({
      scanAllApps: isAllAppsToggleSelected,
      appIdsToScan: selectedRows.map(row => row.id),
    });
  };

  const createRow = (
    row: FetchHerokuAppListQuery["organization"]["herokuApps"][number]
  ) => {
    return {
      name: row.name,
      id: row.id,
    };
  };
  return (
    <React.Fragment>
      <div className="heroku-apps-selector-section">
        <div className="heroku-apps-selector-toggle">
          <Checkbox
            checked={isAllAppsToggleSelected}
            onChange={handleAllAppsToggleChange}
          >
            Monitor all apps
          </Checkbox>
        </div>
      </div>
      <div
        className={`heroku-apps-selector-section ${
          isAllAppsToggleSelected ? "disabled-text" : ""
        }`}
      >
        Select apps used in production or store customer data.
      </div>
      <div className="heroku-apps-selector-section">
        <div className="heroku-apps-table-container">
          <SelectorDataTable
            data={appList}
            disabled={isAllAppsToggleSelected}
            columnClasses={herokuAppColumnClasses}
            columnOrder={herokuAppColumnOrder}
            header={herokuHeader}
            selectedRows={selectedRows}
            createRow={createRow}
            handleRowSelected={handleRowSelected}
            handleSelectAll={handleSelectAll}
          />
        </div>
      </div>
      <Button
        className="heroku-apps-table-save-button"
        onClick={updateApps}
        intent={Intent.PRIMARY}
      >
        Save
      </Button>
    </React.Fragment>
  );
};
