import styled from "styled-components";

import { BASE_PALETTE } from "../../../alpaca/base/colors";

export const Card = styled.div`
  background: ${BASE_PALETTE.SNOW};
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.09);
  border-radius: 4px;
`;

export const FullWidthCard = styled(Card)`
  width: 100%;
`;
