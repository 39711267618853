import { Callout, Intent } from "@blueprintjs/core";
import { isSome, nothing } from "common/base/types/maybe";
import moment from "moment";
import React from "react";

import type { GetVendorsQuery } from "../../../gen/components";
import { UI_DATE_FORMAT } from "../../../helpers/common";
import { DefaultLink } from "../../../helpers/links";

interface IProps {
  vendor: NonNullable<GetVendorsQuery["organization"]>["vendors"][number];
}

export const VendorSecurityControlsDisplay = (props: IProps) => {
  const { vendor } = props;
  const pendingVAQDisplay = isSome(vendor.pendingVAQExpirationDate) ? (
    <Callout intent={Intent.PRIMARY} icon={null}>
      {moment(vendor.pendingVAQExpirationDate) > moment() ? (
        <p>
          You sent a questionnaire that needs to be answered before{" "}
          {moment(vendor.pendingVAQExpirationDate).format(UI_DATE_FORMAT)}.
        </p>
      ) : (
        <p>
          You sent a questionnaire that expired{" "}
          {moment(vendor.pendingVAQExpirationDate).format(UI_DATE_FORMAT)}.
          Click below to re-send the questionnaire.
        </p>
      )}
    </Callout>
  ) : (
    nothing
  );
  const submittedVAQS = vendor.submittedVAQs.map(vaq => (
    <li key={vaq.id}>
      {isSome(vaq.complianceUpload) ? (
        <p>
          <DefaultLink href={`${location.origin}/${vaq.complianceUpload.url}`}>
            {`${vendor.name}_${vaq.externalAssessmentType}` ??
              "External assessment"}
          </DefaultLink>{" "}
          (Uploaded by vendor on{" "}
          {moment(vaq.complianceUpload.createdAt).format(UI_DATE_FORMAT)}
        </p>
      ) : (
        <DefaultLink href={`/vendors/assessment/${vaq.id}`}>
          Answers received on {moment(vaq.submittedDate).format(UI_DATE_FORMAT)}
        </DefaultLink>
      )}
    </li>
  ));

  return (
    <div className="vendor-security-controls-display">
      {pendingVAQDisplay}
      <ul>{submittedVAQS}</ul>
    </div>
  );
};
