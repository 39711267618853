import type { Maybe } from "common/base/types/maybe";
import React, { useState } from "react";
import styled from "styled-components";

import { VANTA_COLORS } from "../../alpaca/base/colors";

export const IconDiv = styled.div<{ active: boolean }>`
  margin-right: 10;
  background: ${({ active }) => (active ? "#eee" : "#fff")};
  padding: 10;
  border-radius: 2px;
`;

const Button = styled.a<{ active: boolean; hovered: boolean }>`
  && {
    background-color: ${({ active, hovered }) =>
      active || hovered
        ? VANTA_COLORS.LOGIN_BUTTON_ACTIVE
        : VANTA_COLORS.LOGIN_BUTTON};
    display: flex;
    align-items: center;
    color: #fff;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 1px 0 rgba(0, 0, 0, 0.24);
    padding: 0;
    border-radius: 2px;
    border: 1px solid transparent;
    font-size: 14px;
    font-weight: 500;
    cursor: ${({ active, hovered }) =>
      !active && hovered ? "pointer" : "default"};
    width: 100%;
    position: relative;
    max-width: 350px;
    margin: 0 auto;
  }
`;

const LoginDiv = styled.div`
  position: absolute;
  width: 100%;
  text-align: center;
`;

export const LoginButton: React.FC<{
  Icon: React.FC<{ active: boolean }>;
  loginLink?: Maybe<string>;
  text?: Maybe<string>;
}> = ({ text, loginLink, Icon }) => {
  const [hovered, setHovered] = useState(false);
  const [active, setActive] = useState(false);
  return (
    <Button
      active={active}
      hovered={hovered}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => {
        setHovered(false);
        setActive(false);
      }}
      onMouseDown={() => {
        setActive(true);
      }}
      onMouseUp={() => setActive(true)}
      href={loginLink ?? "#"}
    >
      <Icon active={active}></Icon>
      <LoginDiv> {text} </LoginDiv>
    </Button>
  );
};
