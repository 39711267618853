import type { IQuestionSchema } from "../../forms/interfaces";

export const vendorCloudHosted: IQuestionSchema = {
  id: "vendorCloudHosted",
  title: "Cloud infrastructure configuration",
  questions: [
    {
      id: "cloudInfraConfigHeader-2018-09-10",
      label: "Cloud infrastructure configuration",
      name: "cloudInfraConfigHeader",
      required: false,
      type: "Header",
    },
    {
      choices: ["AWS", "Azure", "GCP", "Heroku", "Other"],
      id: "indicateCloudProviders-2018_07_25",
      label: "Cloud providers for %VENDOR ",
      name: "indicateCloudProviders",
      required: true,
      type: "SelectInput",
    },
    {
      // If "Other" selected in `indicateCloudProviders-2018_07_25`
      id: "otherCloudProvider-2018_07_25",
      label: "Cloud provider(s) used",
      name: "otherCloudProvider",
      required: true,
      type: "TextInput",
      visibleConditions: [
        { name: "indicateCloudProviders", visibleValues: ["Other"] },
      ],
    },
    {
      helper: "e.g. Terraform or CloudFormation",
      id: "infraAsCodeTool-2018_07_25",
      label: "Infrastructure as code tool used",
      name: "infraAsCodeTool",
      required: false,
      type: "CheckboxInput",
    },
    {
      helper: "e.g. Chef or Puppet",
      id: "configMgmtToolUsed-2018_07_25",
      label: "Configuration management tool used",
      name: "configMgmtToolUsed",
      required: false,
      type: "CheckboxInput",
    },
    {
      id: "cloudChangeManagement-2018_07_26",
      label: "Infrastructure changes reviewed before deployed",
      name: "cloudChangeManagement",
      required: false,
      type: "CheckboxInput",
    },
  ],
  submitText: "Submit cloud infastructure info",
  version: "2018-07-26",
};
