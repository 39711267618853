import type { IMenuItemProps } from "@blueprintjs/core";
import { Menu as BPMenu, MenuItem as BPMenuItem } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import React from "react";
import styled from "styled-components";

import { BASE_PALETTE } from "../../base/colors";
import { GRID_SPACING } from "../../base/grid";
import { BASE_TYPOGRAPHY } from "../../base/typography";
import type { IconName } from "../index";
import { Icon } from "../index";

interface IMenuProps {
  width?: Maybe<number>;
}

export const Menu: React.FC<IMenuProps> = ({ children, width }) => (
  <StyledBPMenu width={width}>{children}</StyledBPMenu>
);

const StyledBPMenu = styled(BPMenu)<{ width?: Maybe<number> }>`
  ${({ width }) => (isSome(width) ? `width: ${width}px` : "")}
  &.bp3-menu, & * .bp3-menu {
    min-width: 0px;
    padding: ${GRID_SPACING}px 0px;
    border-radius: 4px;
  }
`;

interface IWithAlpacaIcon {
  alpacaIcon?: Maybe<IconName>;
}

export const MenuItem: React.FC<IMenuItemProps & IWithAlpacaIcon> = ({
  alpacaIcon,
  ...props
}) => (
  <StyledBPMenuItem
    {...props}
    icon={
      isSome(alpacaIcon) ? (
        <Icon icon={alpacaIcon} color={BASE_PALETTE.SMOKE} />
      ) : (
        props.icon
      )
    }
  />
);

const StyledBPMenuItem = styled(BPMenuItem)`
  font-size: ${BASE_TYPOGRAPHY.FONT_SIZES.BODY_TEXT}px;
  line-height: ${BASE_TYPOGRAPHY.LINE_HEIGHTS.BODY_TEXT};
  align-items: center;
  padding: 4px ${2 * GRID_SPACING}px;

  .bp3-icon {
    color: ${BASE_PALETTE.SMOKE};
    margin-top: 0px;
    margin-right: 8px;
  }

  && :hover {
    background-color: ${BASE_PALETTE.VAPE};
    svg {
      fill: ${BASE_PALETTE.INK};
      color: ${BASE_PALETTE.INK};
    }
  }
`;
