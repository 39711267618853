import React from "react";

import type { IPolicyQuestionSchema } from "../../info/policies/policyDocSchema";
import type { AnswersMap } from "../beta-policies-wizard-page";
import { Row, RowAnswer, RowQuestion } from "../policies-v2-styles";

export const PolicyAnswersList: React.FC<{
  answersMap: AnswersMap;
  questionSchema: IPolicyQuestionSchema;
}> = ({ answersMap, questionSchema }) => (
  <>
    {questionSchema.questions.map(question => {
      const answer = answersMap[question.name];
      let displayedAnswer = answer;
      if (question.type === "CheckboxInput") {
        // yes if true, false otherwise (undefined/null or false)
        displayedAnswer = Boolean(answer) ? "Yes" : "No";
      }
      return (
        <Row key={question.id}>
          <RowQuestion>{question.label}</RowQuestion>
          <RowAnswer>{displayedAnswer}</RowAnswer>
        </Row>
      );
    })}
  </>
);
