import { Button, InputGroup } from "@blueprintjs/core";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import { VANTA_COLORS } from "./colors";
import { BASE_TYPOGRAPHY } from "./typography";

/**
 * This file contains non-standard components from the deleted vanta-ui folder.
 * These should either be expunged or rehabilitated.
 */

export const TableStylesDeprecated = css`
  &&& thead td {
    color: #00000088;
    font-size: 12px;
    line-height: 14px;
    font-weight: 600;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    padding-bottom: 6px;
    vertical-align: bottom;
  }
  &&& td {
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    box-shadow: none;
  }
  &&& .vuln-table-centered {
    text-align: center;
  }
`;

export const StyledLinkDeprecated = styled(Link)`
  color: #000;
  opacity: 0.65;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  &:hover {
    color: #000;
    opacity: 0.8;
    text-decoration: none;
  }
`;

export const VantaButtonDeprecated = styled(Button)`
  font-family: ${BASE_TYPOGRAPHY.FONT_FAMILIES.CHELTENHAM};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;

  background: #ffffff;
  border: 1px solid #dfdfdf;
  box-sizing: border-box;
  border-radius: 6px;

  padding: 11px 22px;

  display: flex;
  justify-content: center;

  &:not([class*="bp3-intent-"]) {
    background-image: none;
    background: #ffffff;
    border: 1px solid #dfdfdf;
    box-sizing: border-box;
    border-radius: 6px;
    box-shadow: none;
  }
  &.bp3-intent-primary {
    background-color: ${VANTA_COLORS.VANTA_PURPLE};
    box-shadow: none;
    border: none;
  }

  &.bp3-minimal {
    border: none;
    box-shadow: none;
  }
  &.bp3-button.bp3-intent-primary:disabled,
  &.bp3-button.bp3-intent-primary.bp3-disabled {
    background-color: ${VANTA_COLORS.VANTA_PURPLE}88;
  }
`;

export const StyledInputGroupDeprecated = styled(InputGroup)`
  & .bp3-input,
  & .bp3-input[type="text"],
  & .bp3-input[type="url"],
  & .bp3-input[type="email"] {
    box-shadow: none;
    padding: 16px 10px 16px 0;
    position: relative;
    border-radius: 0;
    border: none;
    border-bottom: 2px solid #e7e8e8;
    &:focus {
      border-bottom: 2px solid #5f4b8b;
    }
  }
`;
