import type { Maybe } from "common/base/types/maybe";
import React from "react";
import styled from "styled-components";

import { BASE_PALETTE, VANTA_COLORS } from "../../../alpaca/base/colors";

export const AssumedUserReadWriteIndicator: React.FC<{
  hasAssumedWriteAccess: Maybe<boolean>;
}> = ({ hasAssumedWriteAccess }) =>
  hasAssumedWriteAccess ? (
    <Danger>WRITE&nbsp;ACCESS</Danger>
  ) : (
    <ReadOnly>Read&nbsp;Only</ReadOnly>
  );

const Danger = styled.div`
  background: ${BASE_PALETTE.TOMATO};
  color: #fff;
  margin-left: 6px;
  padding: 2px 4px;
  border-radius: 4px;
`;

const ReadOnly = styled.div`
  color: ${BASE_PALETTE.TOMATO};
  text-align: center;
  background: ${VANTA_COLORS.BACKGROUND_ALERT};
  border-radius: 4px;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 24px;
`;
