import { Intent } from "@blueprintjs/core";
import { Feature } from "common/base/types/gen";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import { simplePlural } from "common/grammar/plurals";
import React from "react";

import { Tag, Tooltip } from "../../../alpaca/components";
import { FeatureGate } from "../../../helpers/feature-gating/feature-gate";

export const VantaAgentTag: React.FC<{ active: boolean }> = ({ active }) =>
  active ? (
    <FeatureGate features={[Feature.LegacyEnableServerAgent]}>
      <Tooltip content={"The Vanta app is enabled on this device"}>
        <Tag intent={Intent.SUCCESS} text="Agent active" />
      </Tooltip>
    </FeatureGate>
  ) : (
    <FeatureGate features={[Feature.LegacyEnableServerAgent]}>
      <Tooltip content="The Vanta app is not running on this device">
        <Tag intent={Intent.DANGER} text="Agent not active" />
      </Tooltip>
    </FeatureGate>
  );

export const EncryptionTag: React.FC<{ encrypted: boolean }> = ({
  encrypted,
}) =>
  encrypted ? (
    <Tag intent={Intent.SUCCESS} text="Encrypted" />
  ) : (
    <Tag intent={Intent.DANGER} text="Unencrypted" />
  );

export const VersionedTag: React.FC<{
  versioned: boolean;
  hasUserData: boolean;
}> = ({ versioned, hasUserData }) => {
  if (versioned) {
    return (
      <Tooltip content="Versioning is enabled for this bucket">
        <Tag intent={Intent.SUCCESS} text="Versioning on" />
      </Tooltip>
    );
  }
  if (!hasUserData) {
    return null;
  }
  return (
    <Tooltip content="Since this item contains user data, you need to enable versioning for SOC 2 compliance">
      <Tag intent={Intent.DANGER} text="Not Versioned" />
    </Tooltip>
  );
};

export const NotRunningTag: React.FC = () => (
  <Tooltip content={"This device is not currently running"}>
    <Tag intent={Intent.DANGER} text="Not Running" />
  </Tooltip>
);

export const EPHITag: React.FC = () => (
  <Tooltip
    content={"This resource contains electronic Protected Health Information"}
  >
    <Tag intent={Intent.PRIMARY} text="Contains ePHI" />
  </Tooltip>
);

export const AccountTag: React.FC<{ cloudAccountId: string }> = ({
  cloudAccountId,
}) => (
  <Tooltip content="The cloud account associated with this resource">
    <Tag text={cloudAccountId} />
  </Tooltip>
);

export const RegionTag: React.FC<{ region: string }> = ({ region }) => (
  <Tag text={region} />
);

export const BackupTag: React.FC<{ backupRetentionDays: Maybe<number> }> = ({
  backupRetentionDays,
}) => {
  if (isSome(backupRetentionDays) && backupRetentionDays > 0) {
    return (
      <Tooltip
        content={`Vanta has confirmed that backups are retained for ${simplePlural(
          backupRetentionDays,
          "day"
        )}`}
      >
        <Tag intent={Intent.SUCCESS} text="Backups enabled" />
      </Tooltip>
    );
  } else {
    return (
      <Tooltip content="If this contains important data, you'll likely want to enable backups for SOC 2 compliance">
        <Tag intent={Intent.DANGER} text="No backups" />
      </Tooltip>
    );
  }
};

export const AutoscaleTag: React.FC<{ asgName: string }> = ({ asgName }) => (
  <Tooltip content="The autoscale group this instance belongs to">
    <Tag text={asgName} />
  </Tooltip>
);

export const AutoScanTag: React.FC<{ autoScanEnabled: boolean }> = ({
  autoScanEnabled,
}) =>
  autoScanEnabled ? (
    <Tooltip
      content={
        "Images in this repository are automatically scanned for vulnerabilities on push"
      }
    >
      <Tag intent={Intent.SUCCESS} text="Automatic scan enabled" />
    </Tooltip>
  ) : (
    <Tooltip
      content={
        "Images in this repository are not automatically scanned for vulnerabilities on push"
      }
    >
      <Tag intent={Intent.NONE} text="Automatic scan disabled" />
    </Tooltip>
  );
