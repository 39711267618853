import moment from "moment";

import { SecurityTrainingCategoryId } from "../base/types/gen";
import { UploadedDocumentType } from "../schemas/uploadedDocument/docType";
import { ReservedVantaAttributes } from "../utils/vantaAttributes";

export const DEFAULT_ONBOARDING_GRACE_PERIOD = moment.duration(14, "days");

// how long after a task was completed the next one will be due
export const DEFAULT_RECURRENCE_DUE_MONTHS = 12;
// how long we allow for the completion of the recurring task
export const DEFAULT_RECURRENCE_TIME_TO_COMPLETE_MONTHS = 2;

export const SECURITY_AWARENESS_TRAINING_INFO = {
  ESET: {
    ID: "eset-cybersecurity-awareness-training",
    MARKETING_URL: "https://www.eset.com/us/cybertraining/",
    TRAINING_URL: "https://www.eset.com/us/cybertraining/#compare",
    DEFAULT_INSTRUCTIONS:
      `1. Create an account for ESET's free cybersecurity awareness training. Use your company email.\n` +
      `2. Complete all modules. This should take less than 2 hours. At any point, you can leave the training and pick it up again from the same place later.\n` +
      `3. Once you have completed of all modules, you will see “Congratulations!” on your dashboard. Now click the icon for your identity provider in the top right of your toolbar, so your name and email appear on your screen. Then take a screenshot of your screen, and upload that screenshot as your evidence that the training was completed.`,
  },
  CYBRARY: {
    ID: "cybrary-end-user-security-awareness",
    MARKETING_URL: "https://www.cybrary.it/course/end-user-cyber-fundamentals/",
    TRAINING_URL: "https://www.cybrary.it/course/end-user-cyber-fundamentals/",
    DEFAULT_INSTRUCTIONS:
      "After finishing the training, upload a screenshot showing that each section is complete.",
  },
  GDPR_GENERAL: {
    ID: "gdpr-general-security-awareness-training",
    LABEL:
      "Vanta's GDPR security awareness training (in partnership with Living Security)",
    DEFAULT_INSTRUCTIONS:
      "Please complete the security training by watching the video below. Please do not close this tab, or you would have to restart the video.",
  },
  GDPR_CUSTOM: {
    ID: "gdpr-custom-security-awareness-training",
    LABEL: "My own GDPR security training",
    DEFAULT_INSTRUCTIONS:
      "After finishing the training, upload a screenshot showing that training is complete.",
  },
  GENERAL: {
    ID: "general-security-awareness-training",
    LABEL:
      "Vanta's security awareness training (in partnership with Living Security)",
    DEFAULT_INSTRUCTIONS:
      "Please complete the security training by watching the thirty-minute video below and clicking the submit button at the end. Please do not close this tab, or you will have to restart the video.",
  },
  CUSTOM: {
    ID: "custom-security-awareness-training",
    LABEL: "My own security training",
    DEFAULT_INSTRUCTIONS:
      "After finishing the training, upload a screenshot showing that training is complete.",
  },
  HIPAA_GENERAL: {
    ID: "hipaa-general-security-awareness-training",
    LABEL:
      "Vanta's HIPAA security awareness training (in partnership with Living Security)",
    DEFAULT_INSTRUCTIONS:
      "Please complete the security training by watching the video below. Please do not close this tab, or you would have to restart the video.",
  },
  HIPAA_CUSTOM: {
    ID: "hipaa-custom-security-awareness-training",
    LABEL: "My own HIPAA security training",
    DEFAULT_INSTRUCTIONS:
      "After finishing the training, upload a screenshot showing that training is complete.",
  },
  PCI_CUSTOM: {
    ID: "pci-custom-security-awareness-training",
    DEFAULT_INSTRUCTIONS:
      "After finishing the training, upload a screenshot showing that training is complete.",
  },
};

export const ALL_TRAINING_IDS = Object.values(
  SECURITY_AWARENESS_TRAINING_INFO
).map(info => info.ID);

export const VIDEO_NAMES = [
  "GENERAL",
  "GDPR_GENERAL",
  "HIPAA_GENERAL",
] as const;

export type VIDEO_NAMES_TYPE = typeof VIDEO_NAMES[number];

export const TRAINING_ID_TO_VIDEO_NAMES_MAP: {
  [k: string]: VIDEO_NAMES_TYPE[];
} = {
  [SECURITY_AWARENESS_TRAINING_INFO.GENERAL.ID]: ["GENERAL"],
  [SECURITY_AWARENESS_TRAINING_INFO.GDPR_GENERAL.ID]: ["GDPR_GENERAL"],
  [SECURITY_AWARENESS_TRAINING_INFO.HIPAA_GENERAL.ID]: ["HIPAA_GENERAL"],
};

export const VIDEO_NAME_TO_S3_KEY: {
  [k in VIDEO_NAMES_TYPE]: string;
} = {
  GENERAL: "Vanta_General_InfoSec_Awareness.mp4",
  GDPR_GENERAL: "Vanta_GDPR_Training.mp4",
  HIPAA_GENERAL: "Vanta_HIPAA_Training.mp4",
};

export const TRAINING_CATEGORIES = [
  {
    customTrainingId: SECURITY_AWARENESS_TRAINING_INFO.CUSTOM.ID,
    displayName: "security training",
    docTypes: [
      UploadedDocumentType.CUSTOM_SECURITY_AWARENESS_TRAINING_ATTESTATION,
      UploadedDocumentType.CYBRARY_END_USER_SECURITY_AWARENESS_ATTESTATION,
      UploadedDocumentType.EEST_CYBERSECURITY_AWARENESS_ATTESTATION,
      UploadedDocumentType.GENERAL_SECURITY_AWARENESS_TRAINING_ATTESTATION,
    ],
    id: SecurityTrainingCategoryId.general,
    trainingIds: [
      SECURITY_AWARENESS_TRAINING_INFO.CUSTOM.ID,
      SECURITY_AWARENESS_TRAINING_INFO.CYBRARY.ID,
      SECURITY_AWARENESS_TRAINING_INFO.ESET.ID,
      SECURITY_AWARENESS_TRAINING_INFO.GENERAL.ID,
    ],
    vantaAttribute: ReservedVantaAttributes.isGeneralSat,
    videoName: "GENERAL",
  },
  {
    customTrainingId: SECURITY_AWARENESS_TRAINING_INFO.HIPAA_CUSTOM.ID,
    displayName: "HIPAA training",
    docTypes: [
      UploadedDocumentType.HIPAA_CUSTOM_SECURITY_AWARENESS_TRAINING_ATTESTATION,
    ],
    id: SecurityTrainingCategoryId.hipaa,
    trainingIds: [
      SECURITY_AWARENESS_TRAINING_INFO.HIPAA_CUSTOM.ID,
      SECURITY_AWARENESS_TRAINING_INFO.HIPAA_GENERAL.ID,
    ],
    vantaAttribute: ReservedVantaAttributes.isHipaaSat,
    videoName: "HIPAA_GENERAL",
  },
  {
    customTrainingId: SECURITY_AWARENESS_TRAINING_INFO.PCI_CUSTOM.ID,
    displayName: "PCI DSS training",
    docTypes: [
      UploadedDocumentType.PCI_CUSTOM_SECURITY_AWARENESS_TRAINING_ATTESTATION,
    ],
    id: SecurityTrainingCategoryId.pci,
    trainingIds: [SECURITY_AWARENESS_TRAINING_INFO.PCI_CUSTOM.ID],
    vantaAttribute: ReservedVantaAttributes.isPciSat,
  },
  {
    customTrainingId: SECURITY_AWARENESS_TRAINING_INFO.GDPR_CUSTOM.ID,
    displayName: "GDPR training",
    docTypes: [
      UploadedDocumentType.GDPR_CUSTOM_SECURITY_AWARENESS_TRAINING_ATTESTATION,
    ],
    id: SecurityTrainingCategoryId.gdpr,
    trainingIds: [
      SECURITY_AWARENESS_TRAINING_INFO.GDPR_CUSTOM.ID,
      SECURITY_AWARENESS_TRAINING_INFO.GDPR_GENERAL.ID,
    ],
    videoName: "GDPR_GENERAL",
    vantaAttribute: ReservedVantaAttributes.isGdprSat,
  },
];

type Category = typeof TRAINING_CATEGORIES[0];

export const TRAINING_CATEGORIES_BY_ID = new Map<
  SecurityTrainingCategoryId,
  Category
>(TRAINING_CATEGORIES.map(category => [category.id, category]));

export const TRAINING_CATEGORIES_BY_DOC_TYPE = new Map<string, Category>(
  TRAINING_CATEGORIES.flatMap(category =>
    category.docTypes.map(docType => [docType, category])
  )
);

export const TRAINING_CATEGORIES_BY_TRAINING_ID = new Map(
  TRAINING_CATEGORIES.flatMap(category =>
    category.trainingIds.map(trainingId => [trainingId, category])
  )
);

export const TRAINING_CATEGORIES_BY_VIDEO_NAME = new Map(
  TRAINING_CATEGORIES.map(category => [category.videoName, category])
);

export const userSecurityTrainingIdToUploadedDocType: {
  [trainingId: string]: UploadedDocumentType;
} = {
  [SECURITY_AWARENESS_TRAINING_INFO.CUSTOM.ID]:
    UploadedDocumentType.CUSTOM_SECURITY_AWARENESS_TRAINING_ATTESTATION,
  [SECURITY_AWARENESS_TRAINING_INFO.CYBRARY.ID]:
    UploadedDocumentType.CYBRARY_END_USER_SECURITY_AWARENESS_ATTESTATION,
  [SECURITY_AWARENESS_TRAINING_INFO.ESET.ID]:
    UploadedDocumentType.EEST_CYBERSECURITY_AWARENESS_ATTESTATION,
  [SECURITY_AWARENESS_TRAINING_INFO.GDPR_CUSTOM.ID]:
    UploadedDocumentType.GDPR_CUSTOM_SECURITY_AWARENESS_TRAINING_ATTESTATION,
  [SECURITY_AWARENESS_TRAINING_INFO.GENERAL.ID]:
    UploadedDocumentType.GENERAL_SECURITY_AWARENESS_TRAINING_ATTESTATION,
  [SECURITY_AWARENESS_TRAINING_INFO.HIPAA_CUSTOM.ID]:
    UploadedDocumentType.HIPAA_CUSTOM_SECURITY_AWARENESS_TRAINING_ATTESTATION,
  [SECURITY_AWARENESS_TRAINING_INFO.PCI_CUSTOM.ID]:
    UploadedDocumentType.PCI_CUSTOM_SECURITY_AWARENESS_TRAINING_ATTESTATION,
};

export const USER_SECURITY_UPLOADED_DOC_TYPES = Object.values(
  userSecurityTrainingIdToUploadedDocType
);
