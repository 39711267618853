import { MenuItem } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import moment from "moment";
import React from "react";
import styled from "styled-components";

import type { FetchDataForPeoplePageQuery } from "../../../gen/components";
import { UI_DATE_FORMAT_MONTH_YEAR } from "../../../helpers/common";
import { DropdownButton } from "../components/dropdown-button";

type Audit = NonNullable<
  FetchDataForPeoplePageQuery["organization"]
>["audits"][number];

interface IProps {
  audits: Audit[];
  onAuditSelect: (audit: Maybe<Audit>) => void;
  selectedAuditId: Maybe<string>;
}

export const AuditWindowDropdown: React.FC<IProps> = ({
  audits,
  onAuditSelect,
  selectedAuditId,
}) => {
  const addAuditMenuItem = (
    <StyledMenuItem
      key="add-audit-item"
      href="/audit-schedule"
      target="_blank"
      text="Add audit window"
    />
  );

  return (
    <DropdownButton
      options={audits}
      selectedOption={audits.find(a => a.id === selectedAuditId)}
      onOptionSelect={onAuditSelect}
      optionRenderer={audit => {
        const startDate = moment(+audit.auditStart).format(
          UI_DATE_FORMAT_MONTH_YEAR
        );
        const endDate = moment(+audit.auditStart)
          .add(audit.auditPeriodDays, "days")
          .format(UI_DATE_FORMAT_MONTH_YEAR);
        return `${startDate} - ${endDate}`;
      }}
      defaultText={"Filter by audit window"}
      isFilter={true}
      additionalMenuItems={!isSome(selectedAuditId) ? [addAuditMenuItem] : null}
      buttonWidth={224}
      menuWidth={224}
      styleOnSelect
    />
  );
};

const StyledMenuItem = styled(MenuItem)`
  font-size: 12px !important;
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
  }
`;
