import { InputGroup } from "@blueprintjs/core";
import { isSome } from "common/base/types/maybe";
import React, { useContext } from "react";
import styled from "styled-components";

import { BASE_PALETTE } from "../../../alpaca/base/colors";
import { LogError } from "../../../errors";
import { useUnassumeUserMutationMutation } from "../../../gen/components";
import { DefaultLink } from "../../../helpers/links";
import { CopyClipboardIcon } from "../../helpers/CopyClipboardIcon";
import { UserContext } from "../../pages/user-context";
import { Dimensions } from "../constants";

export const AssumedUserDisplay: React.FC = ({ children }) => {
  const { user } = useContext(UserContext);
  const [unassumeUser] = useUnassumeUserMutationMutation({
    onCompleted: () => (location.href = "/"),
  });
  if (!isSome(user)) {
    return null;
  }
  return (
    <Container>
      <TopBar>Currently assuming:</TopBar>
      <Padded>
        {children}
        <div>
          {user.hasAssumedWriteAccess ? "(WRITE ACCESS)" : "(Read only access)"}
        </div>
        <InputGroup
          value={user.domain.id}
          readOnly={true}
          disabled={true}
          rightElement={<CopyClipboardIcon text={user.domain.id} />}
        />
        {user.isAssumedSuperUser ? (
          <Alert>
            <DefaultLink href="/query" target="_blank">
              /query
            </DefaultLink>
          </Alert>
        ) : null}
        <Alert
          onClick={() => {
            unassumeUser().catch(LogError);
          }}
        >
          &#8249; Back to original user
        </Alert>
      </Padded>
    </Container>
  );
};

const Alert = styled.div`
  color: ${BASE_PALETTE.CITRUS};
  &:hover {
    cursor: pointer;
  }
  margin-top: 8px;
`;

const Container = styled.div`
  border: 1px solid ${BASE_PALETTE.CITRUS};
  border-radius: 4px;
  margin: ${Dimensions.ACCOUNT_MENU_INSETS}px;
`;

const Padded = styled.div`
  padding: 10px 10px ${Dimensions.ACCOUNT_MENU_INSETS}px;
`;

const TopBar = styled.div`
  border-bottom: 1px solid ${BASE_PALETTE.CITRUS};
  padding: 10px;
`;
