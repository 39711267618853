import type { IQuestionSchema } from "../../forms/interfaces";

export const vendorBasicInformation: IQuestionSchema = {
  id: "vendorBasicInformation",
  title: "Contact information",
  questions: [
    {
      label: "Company legal name",
      id: "legalName-2018_07_23",
      name: "vendorLegalName",
      required: true,
      type: "TextInput",
    },
    {
      // Pre-fill this with information from the form sender
      id: "urlVendor-2018_07_23",
      label: "Company URL",
      name: "urlVendor",
      required: true,
      type: "URLInput",
    },
    {
      helper: "Email address in case of support questions",
      id: "email-2018_07_23",
      label: "Support email",
      name: "vendorContactSupportEmail",
      required: true,
      type: "EmailInput",
    },
    {
      id: "assessmentCheckbox-2019-02-19",
      helper:
        "You may skip this questionnaire by uploading an external audit (SOC 2, ISO 27001/2, etc.)",
      name: "assessmentCheckbox",
      label: "We have completed an external assessment",
      type: "CheckboxInput",
      required: false,
    },
    {
      choices: [
        "SOC 2 Type I",
        "SOC 2 Type II",
        "ISO 27001/2",
        "PCI DSS Level 1",
        "HIPAA",
        "HITRUST",
        "GDPR Policies",
        "Other external audit",
      ],
      id: "assessmentSelect-2019-2-19",
      name: "assessmentSelect",
      required: true,
      label: "What type of compliance standard?",
      type: "SelectInput",
      visibleConditions: [{ name: "assessmentCheckbox" }],
    },
    {
      id: "assessmentUpload-2019-2-19",
      name: "vaqAssessmentFile",
      required: true,
      label: "Upload the assessment",
      type: "File",
      visibleConditions: [
        { name: "assessmentSelect" },
        { name: "assessmentCheckbox" },
      ],
    },
  ],
  submitText: "Submit contact info",
  version: "2018-07-23",
};

export const vendorProvidedServices: IQuestionSchema = {
  id: "vendorProvidedServices",
  title: "Service or product provided",
  questions: [
    {
      id: "vendorServicesHeader-2018-09-10",
      label: "Service or product provided",
      name: "vendorServicesHeader",
      required: false,
      type: "Header",
    },
    {
      id: "vendorServicesParagraph-2018-09-10",
      label: "What services does %VENDOR provide to %COMPANY?",
      name: "vendorServicesQuestion",
      required: false,
      type: "Paragraph",
    },
    {
      id: "vendorCloudServices-2018_09_10",
      label: "Cloud software",
      name: "vendorCloudService",
      required: false,
      type: "CheckboxInput",
    },
    {
      id: "vendorSoftwareServices-2018_09_10",
      label: "Software hosted by %COMPANY",
      name: "vendorSoftwareService",
      required: false,
      type: "CheckboxInput",
    },
    {
      id: "vendorProfessionalServices-2018_09_10",
      label: "Professional services",
      name: "vendorProfessionalService",
      required: false,
      type: "CheckboxInput",
    },
    {
      id: "vendorPhysicalHardware-2018_09_10",
      label: "Does %VENDOR requires physical hardware at %COMPANY offices?",
      name: "vendorPhysicalHardware",
      required: true,
      type: "Yes/No",
    },
    {
      id: "vendorSoftwareInstall-2018_09_10",
      label: "Does %VENDOR software run on %COMPANY infrastructure?",
      name: "vendorSoftwareInstall",
      required: true,
      type: "Yes/No",
    },
  ],
  requiredOr: [
    {
      questions: [
        "vendorCloudService",
        "vendorProfessionalService",
        "vendorSoftwareService",
      ],
      message: "You must specify at least one service",
    },
  ],
  submitText: "Submit Basic Vendor Information to continue",
  version: "2018-07-23",
};
