import { isSome } from "common/base/types/maybe";
import type * as React from "react";
import { useContext } from "react";

import { UserContext } from "../pages/user-context";

export const ZendeskWidget: React.FC = () => {
  const { user } = useContext(UserContext);

  const windowAny: any = window;
  if (windowAny.zE && isSome(user)) {
    windowAny.zE("webWidget", "prefill", {
      name: {
        value: user.displayName,
        readOnly: true,
      },
      email: {
        value: user.email,
        readOnly: true,
      },
    });
  }

  return null;
};
