import type { Maybe } from "common/base/types/maybe";
import React from "react";
import styled from "styled-components";

import { BASE_PALETTE } from "../../base/colors";

type ValueItem = { key: string; value: string };

export type IValueRowsProps = {
  valueItems: ValueItem[];
  columnWidths?: Maybe<string[]>;
};

export const ValueRows: React.FC<IValueRowsProps> = ({
  valueItems,
  columnWidths,
  ...props
}) => {
  const widths = columnWidths ?? [undefined, undefined];
  return (
    <StyledTable>
      <tbody>
        {valueItems.map((valueItem, i) => (
          <tr key={`${valueItem.key}_${i}`}>
            <LeftCell width={widths[0]}>{valueItem.key}</LeftCell>
            <RightCell width={widths[1]}>{valueItem.value}</RightCell>
          </tr>
        ))}
      </tbody>
    </StyledTable>
  );
};

const StyledTable = styled.table`
  font-size: 14px;
  line-height: 16px;
  font-family: Monaco;
  td {
    padding-bottom: 12px;
    border: none;
  }
  tr {
    background-color: transparent;
  }
`;

const LeftCell = styled.td<{ width?: Maybe<string> }>`
  text-align: right;
  vertical-align: top;
  color: ${BASE_PALETTE.INK};
  padding-right: 24px;
  width: ${props => props.width ?? "50%"};
`;

const RightCell = styled.td<{ width?: Maybe<string> }>`
  text-align: left;
  vertical-align: top;
  word-break: break-word;
  color: ${BASE_PALETTE.SMOKE};
  width: ${props => props.width ?? "50%"};
`;
