import { Icon, Menu, MenuItem } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Popover2 } from "@blueprintjs/popover2";
import { isSome } from "common/base/types/maybe";
import type { IRiskCategory } from "common/schemas/riskRegister/risk-register-content";
import { RISK_CATEGORIES } from "common/schemas/riskRegister/risk-register-content";
import gql from "graphql-tag";
import React from "react";
import styled from "styled-components";

import { VantaButtonDeprecated } from "../../../alpaca/base/deprecated";
import { LogError } from "../../../errors";
import type { GetInfoForRiskReportQuery } from "../../../gen/components";
import { useGetInfoForRiskReportQuery } from "../../../gen/components";
import { FeatureGate } from "../../../helpers/feature-gating/feature-gate";
import { FullPageSpinner } from "../../helpers/FullPageSpinner";
import {
  PageHeadingInfo,
  VantaDashboardPage,
} from "../../vanta-chrome/page-content/vanta-dashboard-page";
import { RiskCSV } from "./csv-generator";
import { RiskEvaluationTable } from "./risk-evaluation-table";
import { downloadCSV } from "./risk-report-download-helpers";
import { RiskTaskTable } from "./risk-task-table";

export type Domain = NonNullable<GetInfoForRiskReportQuery["organization"]>;

export const RiskReport: React.FC = () => {
  const { loading, data, error } = useGetInfoForRiskReportQuery();
  if (loading) {
    return <FullPageSpinner />;
  }

  if (error || !data) {
    LogError(error ?? new Error("Bad fetch"));
    return null;
  }

  const domain = data.organization;

  const csvDownloadMenu = (
    <Menu>
      <MenuItem
        text="Tasks"
        onClick={() => downloadCSV(domain, RiskCSV.Task)}
      />
      <MenuItem
        text="Scoring"
        onClick={() => downloadCSV(domain, RiskCSV.Scoring)}
      />
    </Menu>
  );

  const downloadButton = (
    <Popover2 content={csvDownloadMenu} placement={"bottom-end"} minimal>
      <VantaButtonDeprecated>
        Export CSV&nbsp;&nbsp;
        <Icon icon={IconNames.CARET_DOWN} />
      </VantaButtonDeprecated>
    </Popover2>
  );

  return (
    <VantaDashboardPage headingInfo={PageHeadingInfo.RISK_REPORT}>
      <Container>
        <DownloadButtonContainer>{downloadButton}</DownloadButtonContainer>
        <Section>
          <RiskTaskTable tasks={domain.riskMitigationTasks} />
        </Section>
        {Object.values(RISK_CATEGORIES).map((category: IRiskCategory) => {
          const riskIds = new Set(category.risks.map(r => r.id));

          const sectionJSX = (
            <Section key={category.id}>
              <RiskEvaluationTable
                categoryTitle={category.breadcrumbText}
                evaluations={domain.riskEvaluations.filter(e =>
                  riskIds.has(e.riskId)
                )}
              />
            </Section>
          );

          if (isSome(category.feature)) {
            return (
              <FeatureGate key={category.id} feature={category.feature}>
                {sectionJSX}
              </FeatureGate>
            );
          } else {
            return sectionJSX;
          }
        })}
      </Container>
    </VantaDashboardPage>
  );
};

const styles = {
  SECTION_BOTTOM_MARGIN: 30,
  CONTAINER_WIDTH: 1070,
  CONTAINER_PADDING: 80,
};

const Container = styled.div`
  width: ${styles.CONTAINER_WIDTH};
`;

const Section = styled.div`
  margin-bottom: ${styles.SECTION_BOTTOM_MARGIN}px;
`;

const DownloadButtonContainer = styled.div`
  float: right;
`;

gql`
  query getInfoForRiskReport {
    organization {
      id
      displayName
      riskDismissals {
        id
        riskId
        dismissingUser {
          id
          displayName
        }
        dismissedAt: createdAt
      }
      riskEvaluations {
        id
        evaluator {
          id
          displayName
        }
        riskId
        itemName
        impact
        likelihood
        updatedAt
      }
      riskMitigationTasks {
        id
        createdAt
        completedAt
        riskCategoryId
        description
        assignee {
          id
          displayName
        }
        updatedBy {
          id
          displayName
        }
        dueDate
      }
    }
  }
`;
