import deepequal from "fast-deep-equal";

export const updateRowSelection = (
  rows: any[],
  row: any,
  selectedRows: any[]
) => {
  const updatedSelection = Array.from(selectedRows);
  const idx = updatedSelection.findIndex(item => deepequal(item, row));
  if (idx !== -1) {
    updatedSelection.splice(idx, 1);
  } else {
    updatedSelection.unshift(row);
  }
  return updatedSelection;
};
