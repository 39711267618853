import { Intent } from "@blueprintjs/core";
import * as Sentry from "@sentry/browser";

import { AppToaster } from "./helpers/toaster";

interface IValidationError {
  extensions: {
    code: "ValidationError" | "UNAUTHENTICATED" | "FORBIDDEN";
    fields: { [k: string]: string };
  };
  // This field might no longer exist on Apollo errors
  fields: { [k: string]: string };
}
interface IUserNotFoundError {
  extensions: {
    code: "UserNotFoundError";
  };
  metadata: { [k: string]: any };
}

interface IResourceNotFoundError {
  extensions: {
    code: "RESOURCE_NOT_FOUND";
  };
  metadata: { [k: string]: any };
}

interface IInvalidInputError {
  extensions: {
    code: "BAD_USER_INPUT";
  };
  metadata: { [k: string]: any };
}

interface IOtherError {
  extensions: {
    code:
      | "InvalidEmailAddressError"
      | "PresentedReportAutomaticLoginFailed"
      | "UserAlreadyExistsError"
      | "UserBelongsToAnotherDomainError"
      | "TooManyOutstandingLoginLinks"
      | "InvalidDomain"
      | "AuditorNoBankAccount"
      | "ResourceNotFoundError"
      | "ShouldReloadError"
      | "ServerError"
      | "GRAPHQL_VALIDATION_FAILED";
  };
}

const ApolloErrorCodeToSentryMessage: {
  [k: string]: { message: string; severity: Sentry.Severity };
} = {
  ShouldReloadError: {
    message: "Client with outdated graphql schema",
    severity: Sentry.Severity.Info,
  },
  UNAUTHENTICATED: {
    message: "Permission error during graphql call",
    severity: Sentry.Severity.Warning,
  },
  FORBIDDEN: {
    message: "Permission error during graphql call",
    severity: Sentry.Severity.Warning,
  },
  RESOURCE_NOT_FOUND: {
    message: "Could not find resource during graphql call",
    severity: Sentry.Severity.Warning,
  },
  BAD_USER_INPUT: {
    message: "Graphql called with invalid argument",
    severity: Sentry.Severity.Warning,
  },
  ServerError: {
    message: "Server error during graphql call",
    severity: Sentry.Severity.Warning,
  },
};

const expectedApolloErrorCodes = new Set(
  Object.keys(ApolloErrorCodeToSentryMessage)
);

type IGraphQLError =
  | IValidationError
  | IUserNotFoundError
  | IResourceNotFoundError
  | IInvalidInputError
  | IOtherError;

export function isValidationError(
  error: IGraphQLError
): error is IValidationError {
  return error.extensions.code === "ValidationError";
}

export function isApolloError(error: any): error is IApolloError {
  return Boolean(error) && "graphQLErrors" in error;
}

export interface IApolloError {
  graphQLErrors: IGraphQLError[];
}

export function LogError(error: Error, withToast = true) {
  if (isApolloError(error)) {
    MaybeCaptureApolloError(error);
  } else {
    Sentry.captureException(error);
  }

  if (withToast) {
    ShowErrorToast(error);
  }
}

export const ShowErrorToast = (error: Error) => {
  if (
    isApolloError(error) &&
    error.graphQLErrors.some(ge =>
      ["UNAUTHENTICATED", "FORBIDDEN"].includes(ge.extensions.code)
    )
  ) {
    AppToaster.show({
      icon: "error",
      intent: Intent.DANGER,
      message: "You do not have permission to perform that action.",
      timeout: 2500,
    });
    return;
  }

  if (
    isApolloError(error) &&
    error.graphQLErrors.some(ge => ge.extensions.code === "ShouldReloadError")
  ) {
    AppToaster.show({
      icon: "error",
      intent: Intent.PRIMARY,
      message: "Refresh the page to get the latest version of Vanta!",
      action: {
        onClick: () => window.location.reload(),
        text: "Reload",
      },
      timeout: 30000,
    });
    return;
  }

  AppToaster.show({
    icon: "error",
    intent: Intent.DANGER,
    message: "Uh oh, something went wrong! Contact support@vanta.com for help.",
    timeout: 2500,
  });
};

export function LogErrorMessage(str: string) {
  Sentry.captureMessage(str, Sentry.Severity.Error);
}

const MaybeCaptureApolloError = (error: IApolloError) => {
  const { graphQLErrors } = error;
  Object.entries(ApolloErrorCodeToSentryMessage).forEach(
    ([errorCode, { message, severity }]) => {
      if (graphQLErrors.some(e => e.extensions.code === errorCode)) {
        Sentry.captureMessage(message, severity);
      }
    }
  );

  // We are scrubbing apollo errors in the backend
  // and so don't expect any other codes.
  const unexpectedErrors = graphQLErrors.filter(
    e => !expectedApolloErrorCodes.has(e.extensions.code)
  );
  if (unexpectedErrors.length > 0) {
    Sentry.setExtra(
      "codes",
      unexpectedErrors.map(e => e.extensions.code)
    );
    Sentry.captureMessage(
      `Unexpected graphql error codes`,
      Sentry.Severity.Error
    );
  }
};
