import { Classes, Dialog, HTMLSelect, Intent } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import { toSingular } from "common/grammar/plurals";
import { Determiner } from "common/grammar/utils";
import React, { useState } from "react";

import { LogError } from "../../../errors";
import { useAvailableBetaFeatures } from "../../../helpers/feature-gating/feature-check";
import { CredentialComponent } from "../../pages/credential/credential";
import type { ServiceDetails } from "../service-groups";
import { ServiceGroupName } from "../service-groups";
import { VantaButtonInter } from "./invitation-to-link-service-group";
import type { IServiceGroupProps } from "./service-group";

interface ILinkServiceGroupDialogProps extends IServiceGroupProps {
  isOpen: boolean;
  onClose: () => void;
}

/**
 * LinkServiceGroupEntryDialog is opened when a user indicates they want to
 * link a credential for a group, but they haven't yet specified a service.
 *
 * This dialog should never be opened if it is impossible for the user to link
 * another credential for this group.
 *
 * Once a service has been selected from the group, this dialog's body is that
 * service's linking component.
 *
 * @see AddAnotherCredentialButton
 * @see InvitationToLinkButton
 */
export const LinkServiceGroupDialog: React.FC<ILinkServiceGroupDialogProps> = ({
  serviceGroup,
  relevantCredentials,
  isOpen,
  onClose,
}) => {
  const availableBetaFeatures = useAvailableBetaFeatures();
  const linkedServices = new Set(relevantCredentials.map(c => c.service));

  const availableServices = serviceGroup.services.filter(
    service =>
      !isSome(service.feature) || availableBetaFeatures?.has(service.feature)
  );

  const [selectedService, setSelectedService] = useState<Maybe<ServiceDetails>>(
    (availableServices as ServiceDetails[]).find(
      service => !linkedServices.has(service.id)
    )
  );

  const [renderServiceLinkingComponent, setRenderServiceLinkingComponent] =
    useState(false);

  // selectedService can only be null if there was no default available, which
  // occurs if it's impossible for the user to link another credential for this
  // group.
  //
  // It's fine to render the dialog if it isn't open, but it should be
  // impossible for the user to open.
  if (!isSome(selectedService)) {
    if (isOpen) {
      LogError(
        new Error(
          "All linkable services are linked; service group entry dialog should be unavailable"
        )
      );
    }
    return null;
  }

  const closeDialog = () => {
    setRenderServiceLinkingComponent(false);
    onClose();
  };

  const onSelectionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const service = serviceGroup.services.find(
      s => s.id === event.target.value
    );
    setSelectedService(service as ServiceDetails);
  };

  // We only allow linking one identity provider
  const isChangingIdentityProvider =
    serviceGroup.name === ServiceGroupName.IdentityProviders &&
    relevantCredentials.length > 0;

  const connectAction = isChangingIdentityProvider ? "Switch to" : "Connect";
  const serviceSelectionBody = (
    <React.Fragment>
      <div className={Classes.DIALOG_BODY}>
        <HTMLSelect
          onChange={onSelectionChange}
          fill={true}
          value={selectedService.id}
          options={availableServices
            .map(s => {
              return {
                label: s.displayName,
                value: s.id,
                // We don't allow linking multiple credentials.
                disabled: linkedServices.has(s.id),
              };
            })
            // Sort the selectable options to the top.
            .sort((a, b) => (a.disabled ? 1 : 0) - (b.disabled ? 1 : 0))}
        />
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <VantaButtonInter
            disabled={!isSome(selectedService)}
            intent={Intent.PRIMARY}
            text={`${connectAction} ${selectedService.displayName}`}
            onClick={() => setRenderServiceLinkingComponent(true)}
          />
        </div>
      </div>
    </React.Fragment>
  );

  if (renderServiceLinkingComponent) {
    return (
      <Dialog isOpen={isOpen} onClose={closeDialog}>
        <CredentialComponent
          service={selectedService.id}
          onCredentialsLinked={() => {
            closeDialog();
          }}
        />
      </Dialog>
    );
  } else {
    return (
      <Dialog
        isOpen={isOpen}
        title={`Connect ${toSingular(
          serviceGroup.name.toLocaleLowerCase(),
          Determiner.IndefiniteArticle
        )}`}
        onClose={closeDialog}
      >
        {serviceSelectionBody}
      </Dialog>
    );
  }
};
