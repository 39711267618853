import { Spinner } from "@blueprintjs/core";
import { dateStringToDate } from "common/base/dateUtils";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import React from "react";
import { useHistory } from "react-router";

import { BASE_TYPOGRAPHY } from "../../../../alpaca/base/typography";
import { BodyText, Button, DefaultView } from "../../../../alpaca/components";
import { LogError } from "../../../../errors";
import { useFetchSnykVulnerabilityInfoQuery } from "../../../../gen/components";
import { DataTable } from "../../components/data-table";
import { NotApplicable } from "../../people/shared/not-applicable";
import {
  LoadingContainer,
  TabContainer,
  TableContainer,
  VulnerabilityFindingsHeading,
} from "../common/components";
import { SeverityPill } from "../common/severity-pill";
import { SLADeadlineWithDate } from "../common/sla-deadline-with-date";
import { slaDeadlineComparator } from "../utils";
import { TAB_IDS } from "../vulns-page";
import { SnykProjectInfo } from "./snyk-project-info";

interface IProps {
  resourceId: string;
}

export const SnykProjectDetail: React.FC<IProps> = ({ resourceId }) => {
  const history = useHistory();
  const { error, loading, data } = useFetchSnykVulnerabilityInfoQuery({
    variables: { resourceId },
  });

  if (error) {
    LogError(error);
    history.push(`/vulnerabilities/#${TAB_IDS.snyk}`);
    return <div />;
  }

  if (loading) {
    return (
      <LoadingContainer>
        <Spinner />
      </LoadingContainer>
    );
  }

  const project = data?.organization.resourcesByIds?.[0];
  if (
    !isSome(project) ||
    project.__typename !== "SpecificSnykProjectResource"
  ) {
    LogError(
      new Error("Snyk project not found or found with incorrect __typename")
    );
    return <div />;
  }

  const vulnerabilities = project.vulnerabilities;
  const projectUrl = project.externalURL!;

  const vulnerabilityDetails =
    vulnerabilities.length === 0 ? (
      <BodyText fontWeight={BASE_TYPOGRAPHY.FONT_WEIGHTS.BOLD}>
        Woohoo! No vulnerabilities have been identified by Snyk.
      </BodyText>
    ) : (
      <TableContainer>
        <VulnerabilityFindingsHeading
          numVulns={vulnerabilities.length}
          tooltipText={`Vanta does not track vulnerabilities that are marked
            as ignored in Snyk`}
        />
        <DataTable
          columnWidths={["280px", "230px", "200px", "150px"]}
          columnOrder={["title", "package", "slaDeadline", "severity"]}
          data={[...vulnerabilities].sort(slaDeadlineComparator)}
          header={{
            title: "Title",
            package: "Package",
            slaDeadline: "Time until SLA violation",
            severity: "Severity",
          }}
          createRow={vulnerability => {
            const { title, packageName, slaDeadline, severity } = vulnerability;
            return {
              title,
              package: packageName ?? <NotApplicable />,
              slaDeadline: isSome(slaDeadline) ? (
                <SLADeadlineWithDate
                  slaDeadline={dateStringToDate(slaDeadline)}
                />
              ) : (
                <div>-</div>
              ),
              severity: (
                <SeverityPill severity={severity} excludeNumericSeverity />
              ),
            };
          }}
        />
      </TableContainer>
    );

  return (
    <DefaultView
      headerProps={{
        backLink: `/vulnerabilities#${TAB_IDS.snyk}`,
        title: project.name,
        description: project.uniqueId,
        rightControls: [
          <Button
            key="view-aws"
            onClick={() => {
              window.open(projectUrl, "_blank");
            }}
          >
            View in Snyk
          </Button>,
        ],
      }}
    >
      <TabContainer>
        <SnykProjectInfo
          projectName={project.name}
          projectId={project.uniqueId}
          source={project.origin}
          imageId={project.imageId}
          imageTag={project.imageTag}
          imageBaseImage={project.imageBaseImage}
        />
        {vulnerabilityDetails}
      </TabContainer>
    </DefaultView>
  );
};

gql`
  query fetchSnykVulnerabilityInfo($resourceId: String!) {
    organization {
      id
      resourcesByIds(ids: [$resourceId], specificResourceType: SnykProject) {
        id
        uniqueId
        externalURL
        ... on SpecificSnykProjectResource {
          name
          origin
          imageId
          imageTag
          imageBaseImage
          vulnerabilities {
            id
            packageName
            packageVersions
            title
            severity
            slaDeadline
          }
        }
      }
    }
  }
`;
