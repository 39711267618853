import { Spinner } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import { ReservedVantaAttributes } from "common/utils/vantaAttributes";
import gql from "graphql-tag";
import React, { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import styled from "styled-components";

import { BASE_TYPOGRAPHY } from "../../alpaca/base/typography";
import { MetaText } from "../../alpaca/components";
import { LogError } from "../../errors";
import {
  FetchSpecificKindScopeSettingsDocument,
  useFetchSpecificKindScopeSettingsQuery,
  useSetSpecificResourceKindScopeMutation,
} from "../../gen/components";
import { DefaultLink } from "../../helpers/links";
import { CancelConfirmDialog } from "../helpers/CancelConfirmDialog";
import {
  fetchMoreResources,
  getResourceFilterParams,
} from "../pages/inventory-list/utils";
import { ConfigureScopesResourceItem } from "./configure-scopes-resource-item";
import type { ScopableResourceMetadata } from "./service-groups";

interface IConfigureScopesKindSection {
  scopableResourceMetadata: ScopableResourceMetadata;
  filter: Maybe<string>;
}

/**
 * ConfigureScopesKindSection is a section in a ConfigureScopes dialog that
 * provides scoping controls for resources of a particular specific kind.
 */
export const ConfigureScopesKindSection: React.FC<IConfigureScopesKindSection> =
  ({ scopableResourceMetadata, filter }) => {
    const { loading, error, data, fetchMore } =
      useFetchSpecificKindScopeSettingsQuery({
        variables: {
          kind: scopableResourceMetadata.kind,
          first: 20,
          filterParams: getResourceFilterParams(filter),
        },
        context: {
          debounceKey: `configure-scope-${scopableResourceMetadata.kind}`,
          debounceTimeout: 500,
        },
      });

    const [isToggleAllConfirmationOpen, setToggleAllConfirmationOpen] =
      useState(false);

    const [shouldScopeIn, setScopeIn] = useState(false);

    const [setSpecificResourceKindScope, { loading: toggleAllLoading }] =
      useSetSpecificResourceKindScopeMutation({
        refetchQueries: [
          {
            query: FetchSpecificKindScopeSettingsDocument,
            variables: {
              kind: scopableResourceMetadata.kind,
              first: data?.organization.resources.edges.length ?? 0,
              filterParams: getResourceFilterParams(filter),
            },
          },
        ],
      });

    if (error) {
      LogError(error);
    }

    const filteredResources = (data?.organization.resources.edges ?? []).map(
      r => {
        const prettyName =
          "name" in r.node
            ? r.node.name
            : "displayName" in r.node
            ? r.node.displayName
            : "accountName" in r.node
            ? r.node.accountName
            : "maybeName" in r.node
            ? r.node.maybeName
            : null;
        return {
          ...r.node, // pass the whole object through so the apollo cache can identify it
          prettyName,
          scopeSetting: r.node.vantaAttributes?.find(
            a => a.key === ReservedVantaAttributes.excluded.key
          ),
          secondaryText:
            "email" in r.node
              ? r.node.email
              : "maybeEmail" in r.node && isSome(r.node.maybeEmail)
              ? r.node.maybeEmail
              : "primaryUser" in r.node && isSome(getIntuneEmail(r.node))
              ? getIntuneEmail(r.node)
              : isSome(prettyName) && prettyName !== r.node.uniqueId
              ? r.node.uniqueId
              : null,
        };
      }
    );

    if (isSome(data) && filteredResources.length === 0) {
      return null;
    }

    const scrollDivId = `scroll-div-${scopableResourceMetadata.kind}`;

    return (
      <div>
        <Row>
          <Col size={1}>
            <ScopableResourceHeader>
              {scopableResourceMetadata.pluralDisplayName} (
              {data?.organization.resources.totalCount ?? 0})
            </ScopableResourceHeader>
          </Col>
          <Col>
            <ToggleAll as="div">
              Mark all{Boolean(filter?.trim()) ? " matches: " : ": "}
              <DefaultLink
                onClick={() => {
                  setToggleAllConfirmationOpen(true);
                  setScopeIn(true);
                }}
              >
                In scope
              </DefaultLink>{" "}
              |{" "}
              <DefaultLink
                onClick={() => {
                  setToggleAllConfirmationOpen(true);
                  setScopeIn(false);
                }}
              >
                Out of scope
              </DefaultLink>
            </ToggleAll>
          </Col>
        </Row>
        <FilteredSettingsDiv
          id={scrollDivId}
          style={{
            maxHeight: 225,
            overflow: "auto",
          }}
        >
          {loading ? (
            <LoadingResourcesSpinnerContainer>
              <Spinner />
            </LoadingResourcesSpinnerContainer>
          ) : (
            <InfiniteScroll
              dataLength={data?.organization.resources.edges.length ?? 0}
              next={async () => fetchMoreResources(data, fetchMore)}
              hasMore={
                data?.organization.resources.pageInfo.hasNextPage ?? false
              }
              loader={
                <LoadingResourcesSpinnerContainer>
                  <Spinner />
                </LoadingResourcesSpinnerContainer>
              }
              scrollableTarget={scrollDivId}
            >
              {filteredResources.map(resource => (
                <ConfigureScopesResourceItem
                  key={resource.id}
                  kind={scopableResourceMetadata.kind}
                  resource={resource}
                />
              ))}
            </InfiniteScroll>
          )}
        </FilteredSettingsDiv>
        <CancelConfirmDialog
          body={
            <p>
              You are about to mark all of these{" "}
              {scopableResourceMetadata.pluralDisplayName} as{" "}
              {shouldScopeIn ? "in scope" : "out of scope"}. This will overwrite
              existing scoping for these{" "}
              {scopableResourceMetadata.pluralDisplayName}.
            </p>
          }
          confirmText="Update"
          isOpen={isToggleAllConfirmationOpen}
          loading={toggleAllLoading}
          onClose={() => setToggleAllConfirmationOpen(false)}
          onConfirm={async () => {
            await setSpecificResourceKindScope({
              variables: {
                input: {
                  excluded: !shouldScopeIn,
                  filter: getResourceFilterParams(filter),
                  specificResourceKind: scopableResourceMetadata.kind,
                },
              },
            });
            setToggleAllConfirmationOpen(false);
          }}
          title="Are you sure?"
        />
      </div>
    );
  };

const ScopableResourceHeaderStyles = {
  MARGIN_TOP: 20,
  MARGIN_BOTTOM: 8,
  FONT_SIZE: 14,
  LINE_HEIGHT: 21,
};

const Row = styled.div`
  display: flex;
`;

const Col = styled.div`
  flex: ${(props: { size?: Maybe<number> }) => props.size ?? "0 0 auto"};
`;

const ToggleAll = styled(MetaText)`
  line-height: ${ScopableResourceHeaderStyles.LINE_HEIGHT}px;
  margin-bottom: ${ScopableResourceHeaderStyles.MARGIN_BOTTOM}px;
  margin-top: ${ScopableResourceHeaderStyles.MARGIN_TOP}px;
`;

const ScopableResourceHeader = styled.div`
  margin-top: ${ScopableResourceHeaderStyles.MARGIN_TOP}px;
  margin-bottom: ${ScopableResourceHeaderStyles.MARGIN_BOTTOM}px;

  font-size: ${ScopableResourceHeaderStyles.FONT_SIZE}px;
  line-height: ${ScopableResourceHeaderStyles.LINE_HEIGHT}px;
  font-weight: ${BASE_TYPOGRAPHY.FONT_WEIGHTS.BOLD};
`;

const ScopeResourcesResourceListStyles = {
  BORDER: "1px solid #d6d6d6",
  BORDER_RADIUS: 2,
  BACKGROUND: "#fdfdfd",
};

const FilteredSettingsDiv = styled.div`
  background: ${ScopeResourcesResourceListStyles.BACKGROUND};

  > div {
    border-top: ${ScopeResourcesResourceListStyles.BORDER};
    border-left: ${ScopeResourcesResourceListStyles.BORDER};
    border-right: ${ScopeResourcesResourceListStyles.BORDER};
  }

  > div:nth-child(1) {
    border-radius-top-left: ${ScopeResourcesResourceListStyles.BORDER_RADIUS}px;
    border-radius-top-right: ${ScopeResourcesResourceListStyles.BORDER_RADIUS}px;
  }

  > div:last-child {
    border-bottom: ${ScopeResourcesResourceListStyles.BORDER};
    border-radius-bottom-left: ${ScopeResourcesResourceListStyles.BORDER_RADIUS}px;
    border-radius-bottom-right: ${ScopeResourcesResourceListStyles.BORDER_RADIUS}px;
  }
`;

const LoadingResourcesSpinnerContainerStyles = {
  PADDING: 12,
};

const LoadingResourcesSpinnerContainer = styled.div`
  padding: ${LoadingResourcesSpinnerContainerStyles.PADDING}px;
  display: flex;
  border-radius: 2px;
  justify-content: center;
`;

function getIntuneEmail(node: {
  primaryUser?: Maybe<{
    emailAddress?: Maybe<string>;
    principalName?: Maybe<string>;
  }>;
}) {
  const { primaryUser } = node;
  if (!isSome(primaryUser)) {
    return null;
  }
  if (Boolean(primaryUser.emailAddress?.length)) {
    return primaryUser.emailAddress;
  }
  if (Boolean(primaryUser.principalName?.length)) {
    return primaryUser.principalName;
  }
  return null;
}

gql`
  query fetchSpecificKindScopeSettings(
    $kind: SpecificResource!
    $first: Int!
    $after: String
    $filterParams: filterParams
  ) {
    organization {
      id
      resources(
        specificResourceType: $kind
        options: { includeOutOfScope: true, errorOption: ALL }
        first: $first
        after: $after
        filterParams: $filterParams
      ) {
        totalCount
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            id
            uniqueId
            vantaAttributes {
              key
              value
              managedExternally
            }

            ## Get a prettyName for each specific resource that has a better one than
            ## its uniqueId. We could just store these at fetch-time and store a
            ## prettyName vantaAttribute instead, or add a prettyName field to every generic resource.
            ... on SpecificAdpRunHrUserResource {
              displayName
              maybeEmail: email
            }
            ... on SpecificAdpWorkforceNowHrUserResource {
              displayName
              maybeEmail: email
            }
            ... on SpecificALBResource {
              name
            }
            ... on SpecificAutoScalingGroupResource {
              name
            }
            ... on SpecificAtlasClusterResource {
              name
            }
            ... on SpecificAwsCredentialReportResource {
              name
            }
            ... on SpecificAwsFlowLogResource {
              name
            }
            ... on SpecificAwsGroupResource {
              name
            }
            ... on SpecificAwsRoleResource {
              name
            }
            ... on SpecificAwsRouteTableResource {
              name
            }
            ... on SpecificAwsSecurityGroupResource {
              name
            }
            ... on SpecificAwsSubnetResource {
              name
            }
            ... on SpecificAwsVPCResource {
              name
            }
            ... on SpecificAzureVirtualMachineScaleSetResource {
              name
            }
            ... on SpecificAzureVirtualMachineResource {
              name
            }
            ... on SpecificAzureBlobContainerResource {
              name
            }
            ... on SpecificAzureCosmosDBResource {
              name
            }
            ... on SpecificAzureApplicationGatewayResource {
              name
            }
            ... on SpecificAzureLoadBalancerResource {
              name
            }
            ... on SpecificAzureDatabaseForExternalSQLVariantResource {
              name
            }
            ... on SpecificAzureSecurityGroupResource {
              name
            }
            ... on SpecificAzureSQLDatabaseResource {
              name
            }
            ... on SpecificAzureSQLManagedInstanceResource {
              name
            }
            ... on SpecificAzureSQLServerOnVMsResource {
              name
            }
            ... on SpecificAzureSynapseWarehouseResource {
              name
            }
            ... on SpecificAzureQueueResource {
              name
            }
            ... on SpecificAzureDevOpsRepoResource {
              name
            }
            ... on SpecificBambooHrHrUserResource {
              displayName
              maybeEmail: email
            }
            ... on SpecificBigQueryDatasetResource {
              name
            }
            ... on SpecificBigtableInstanceResource {
              name
            }
            ... on SpecificBitbucketRepoResource {
              name
            }
            ... on SpecificCloudSQLInstanceResource {
              name
            }
            ... on SpecificCloudTrailResource {
              name
            }
            ... on SpecificCloudWatchLogGroupResource {
              name
            }
            ... on SpecificCloudWatchMetricAlarmResource {
              name
            }
            ... on SpecificDigitalOceanSpaceResource {
              name
            }
            ... on SpecificDatadogMonitorResource {
              name
            }
            ... on SpecificDatastoreProjectResource {
              name
            }
            ... on SpecificDigitalOceanAppResource {
              name
            }
            ... on SpecificDigitalOceanContainerRepositoryResource {
              name
            }
            ... on SpecificDigitalOceanDropletResource {
              name
            }
            ... on SpecificDigitalOceanLoadBalancerResource {
              name
            }
            ... on SpecificDigitalOceanRedisClusterResource {
              name
            }
            ... on SpecificDigitalOceanRelationalDbClusterResource {
              name
            }
            ... on SpecificDynamoDBTableResource {
              name
            }
            ... on SpecificEC2InstanceResource {
              maybeName: name
            }
            ... on SpecificECRContainerRepositoryResource {
              name
            }
            ... on SpecificFirestoreProjectResource {
              name
            }
            ... on SpecificGCPComputeInstanceResource {
              name
            }
            ... on SpecificGCPContainerRepositoryResource {
              name
            }
            ... on SpecificGCPLogBucketResource {
              name
            }
            ... on SpecificGCPLogSinkResource {
              name
            }
            ... on SpecificGCPMonitoringPolicyResource {
              name
            }
            ... on SpecificGCPNetworkResource {
              name
            }
            ... on SpecificGCPRoleResource {
              name
            }
            ... on SpecificGCPStorageBucketResource {
              name
            }
            ... on SpecificGCPSubnetResource {
              name
            }
            ... on SpecificGCPSubscriptionResource {
              name
            }
            ... on SpecificGCPTopicResource {
              name
            }
            ... on SpecificGithubRepoResource {
              name
            }
            ... on SpecificGitlabGroupResource {
              name
            }
            ... on SpecificGitlabRepoResource {
              name
            }
            ... on SpecificGsuiteUserResource {
              displayName
              email
            }
            ... on SpecificGustoHrUserResource {
              displayName
              maybeEmail: email
            }
            ... on SpecificHerokuAppResource {
              name
            }
            ... on SpecificInsperityHrUserResource {
              displayName
              maybeEmail: email
            }
            ... on SpecificJamfManagedComputerResource {
              name
            }
            ... on SpecificJustworksHrUserResource {
              displayName
              maybeEmail: email
            }
            ... on SpecificKandjiManagedComputerResource {
              name
            }
            ... on SpecificMicrosoftEndpointManagerManagedComputerResource {
              name
              primaryUser {
                id
                emailAddress
                principalName
              }
            }
            ... on SpecificNamelyHrUserResource {
              displayName
              maybeEmail: email
            }
            ... on SpecificOfficeUserResource {
              displayName
              email
            }
            ... on SpecificOktaUserResource {
              displayName
              email
            }
            ... on SpecificPaychexFlexHrUserResource {
              displayName
              maybeEmail: email
            }
            ... on SpecificPaycorHrUserResource {
              displayName
              maybeEmail: email
            }
            ... on SpecificPaylocityHrUserResource {
              displayName
              maybeEmail: email
            }
            ... on SpecificQuickBooksHrUserResource {
              displayName
              maybeEmail: email
            }
            ... on SpecificRDSInstanceResource {
              name
            }
            ... on SpecificRedshiftClusterResource {
              name
            }
            ... on SpecificRipplingHrUserResource {
              displayName
              maybeEmail: email
            }
            ... on SpecificSlackAccountResource {
              accountName
            }
            ... on SpecificS3Resource {
              name
            }
            ... on SpecificSQSResource {
              name
            }
            ... on SpecificSpannerInstanceResource {
              name
            }
            ... on SpecificSnykProjectResource {
              name
            }
            ... on SpecificSquarePayrollHrUserResource {
              displayName
              maybeEmail: email
            }
            ... on SpecificTrinetHrUserResource {
              displayName
              maybeEmail: email
            }
            ... on SpecificZenefitsHrUserResource {
              displayName
              maybeEmail: email
            }
          }
        }
      }
    }
  }

  mutation setSpecificResourceKindScope($input: BulkSetScopeInput!) {
    bulkSetScope(input: $input) {
      ... on BulkSetScopeSuccess {
        nMatched
      }
      ... on UserError {
        __typename
      }
    }
  }
`;
