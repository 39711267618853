import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import moment from "moment";
import type { ReactElement } from "react";
import React, { useState } from "react";

import { Button } from "../../alpaca/components";
import { useAllEmailsQuery } from "../../gen/components";
import { compareNullableStrings } from "../../helpers/user-sort-functions";
import { FullPageSpinner } from "../helpers/FullPageSpinner";
import { DataTable } from "../pages/components/data-table";
import { SampleEmailComponent } from "../sample-email-viewer/sample-email-overlay";
import { QueryPanelContainer } from "./query-panel-container";

export const EmailsPanel: React.FC = () => {
  const [sampleOverlay, setSampleOverlay] = useState<Maybe<ReactElement>>(null);
  const { loading, data } = useAllEmailsQuery();
  if (loading || !isSome(data)) {
    return <FullPageSpinner />;
  }
  return (
    <QueryPanelContainer>
      {sampleOverlay}
      <DataTable
        useDefaultStyling
        stickyHeaders={"top"}
        header={{
          emailKey: "emailKey",
          samples: "Samples",
          shouldBccVanta: "Vanta BCCed on all emails",
          isAutomatic: "Automated (only affects KillSwitch)",
          timeBasedEmailTriggers: "When sent",
          description: "Description (internal)",
          circuitBreakerTime: "Min time between emails",
          unSubDescription: "Description (frontend)",
          unSubfriendlyName: "Friendlyname (frontend)",
          unSubCategory: "Category (frontend)",
          unSubsensitive: "Sensitive (frontend)",
        }}
        columnOrder={[
          "emailKey",
          "samples",
          "shouldBccVanta",
          "isAutomatic",
          "timeBasedEmailTriggers",
          "description",
          "circuitBreakerTime",
          "unSubDescription",
          "unSubfriendlyName",
          "unSubCategory",
          "unSubsensitive",
        ]}
        data={data.emails}
        searchFilter={(email, searchText) => {
          if (searchText.trim() === "") {
            return true;
          }
          const searchTextLowerCase = searchText.toLocaleLowerCase();
          const fields: Array<keyof typeof email> = ["emailKey", "description"];
          return fields.some(field =>
            String(email[field])
              .toLocaleLowerCase()
              .includes(searchTextLowerCase)
          );
        }}
        tableFilters={{
          noFilterDropdownText: "All Emails",
          filters: [
            ["Sensitive", e => e.unsubscribeInfo?.sensitive ?? false],
            ["Automatic", e => e.isAutomatic ?? false],
            ["Vanta BCCed", e => e.shouldBccVanta],
          ],
          emptyStateText: "No emails found",
        }}
        columnSortFunctions={{
          emailKey: (e1, e2) => e1.emailKey.localeCompare(e2.emailKey),
          shouldBccVanta: (e1, e2) =>
            compareNullableStrings(
              e1.shouldBccVanta ? "Yes" : "No",
              e2.shouldBccVanta ? "Yes" : "No"
            ),
          unSubsensitive: (e1, e2) =>
            compareNullableStrings(
              e1.unsubscribeInfo?.sensitive ? "Yes" : "No",
              e2.unsubscribeInfo?.sensitive ? "Yes" : "No"
            ),
          isAutomatic: (e1, e2) =>
            compareNullableStrings(
              e1.isAutomatic ? "Yes" : "No",
              e2.isAutomatic ? "Yes" : "No"
            ),
          timeBasedEmailTriggers: (e1, e2) =>
            compareNullableStrings(
              e1.timeBasedEmailTriggers?.join(),
              e2.timeBasedEmailTriggers?.join()
            ),

          samples: (e1, e2) => e1.sampleEmails.length - e2.sampleEmails.length,
          description: (e1, e2) =>
            compareNullableStrings(e1.description, e2.description),
          circuitBreakerTime: (e1, e2) =>
            e1.circuitBreakerTime ?? 0 - (e2.circuitBreakerTime ?? 0),
          unSubDescription: (e1, e2) =>
            compareNullableStrings(
              e1.unsubscribeInfo?.description,
              e2.unsubscribeInfo?.description
            ),
          unSubfriendlyName: (e1, e2) =>
            compareNullableStrings(
              e1.unsubscribeInfo?.friendlyName,
              e2.unsubscribeInfo?.friendlyName
            ),
          unSubCategory: (e1, e2) =>
            compareNullableStrings(
              e1.unsubscribeInfo?.category,
              e2.unsubscribeInfo?.category
            ),
        }}
        defaultSortColumn={"emailKey"}
        createRow={row => {
          return {
            emailKey: row.emailKey,
            samples: (
              <>
                {row.sampleEmails.map(sample => (
                  <>
                    <Button
                      key={sample.identifier}
                      text={sample.identifier}
                      onClick={() =>
                        setSampleOverlay(
                          <SampleEmailComponent
                            title={sample.identifier}
                            subject={sample.subject}
                            text={sample.text}
                            htmlBody={sample.htmlBody}
                            htmlComplete={sample.htmlComplete}
                            onClose={() => setSampleOverlay(null)}
                          />
                        )
                      }
                    />
                    <br />
                  </>
                ))}
              </>
            ),
            timeBasedEmailTriggers:
              row.timeBasedEmailTriggers?.join() ?? "Events only",
            shouldBccVanta: row.shouldBccVanta ? "Yes" : "No",
            isAutomatic: row.isAutomatic ? "Yes" : "No",
            description: row.description,
            circuitBreakerTime: isSome(row.circuitBreakerTime)
              ? moment.duration(row.circuitBreakerTime).humanize()
              : "No circuit breaker",
            unSubDescription: row.unsubscribeInfo?.description,
            unSubfriendlyName: row.unsubscribeInfo?.friendlyName,
            unSubCategory: row.unsubscribeInfo?.category,
            unSubsensitive: row.unsubscribeInfo?.sensitive ? "Yes" : "No",
          };
        }}
      />
    </QueryPanelContainer>
  );
};

gql`
  query allEmails {
    emails {
      emailKey
      shouldBccVanta
      isAutomatic
      description
      circuitBreakerTime
      unsubscribeInfo {
        description
        friendlyName
        category
        sensitive
      }
      timeBasedEmailTriggers
      sampleEmails {
        identifier
        subject
        htmlBody
        htmlComplete
        text
      }
    }
  }
`;
