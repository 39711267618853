import { ErrorCode } from "common/base/types/gen";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import React, { useEffect, useMemo, useState } from "react";
import type { match } from "react-router";

import { isApolloError, LogError } from "../../errors";
import type { GetGeneratedReportQuery } from "../../gen/components";
import {
  useGetGeneratedReportAutologinQuery,
  useGetGeneratedReportLazyQuery,
  useGetComplianceStandardQuery,
} from "../../gen/components";
import { FullPageSpinner } from "../helpers/FullPageSpinner";
import { ReportSignIn } from "../reports/components/ReportSignIn";
import {
  filterStandard,
  presentedReportToDomainForReport,
} from "../vanta-reports/helpers/helpers";
import { VantaReportPage } from "../vanta-reports/vanta-report-page";

type GeneratedReport = GetGeneratedReportQuery["public"]["generatedReport"];

export type PresentedReportEvidenceResults = GeneratedReport["evidenceResults"];

gql`
  fragment GeneratedReportFields on presentedReport {
    id
    active
    domainDisplayName
    domainId
    domainLogoSlug
    evidenceResults {
      evidenceRequestId
      title
      outcome
    }
    latestTestResults {
      id
      outcome
      testId
    }
    showSuccessOnly
    standard
    viewerCompany
  }

  query GetGeneratedReport(
    $email: EmailAddress!
    $password: String!
    $slugId: String!
    $viewerCompany: String!
  ) {
    public {
      generatedReport(
        email: $email
        password: $password
        slugId: $slugId
        viewerCompany: $viewerCompany
      ) {
        id
        ...GeneratedReportFields
      }
    }
  }

  query GetGeneratedReportAutologin($slugId: String!, $viewerCompany: String!) {
    public {
      generatedReportAutologin(slugId: $slugId, viewerCompany: $viewerCompany) {
        ... on BaseUserError {
          code
          message
        }
        ... on GeneratedReportAutologinSuccess {
          report {
            id
            ...GeneratedReportFields
          }
        }
      }
    }
  }
`;

interface IReportLoginProps {
  slugId: string;
  viewerCompany: string;
}

const ReportViewerLogin: React.FC<IReportLoginProps> = ({
  slugId,
  viewerCompany,
}) => {
  const [getGeneratedReport, { data, error }] =
    useGetGeneratedReportLazyQuery();

  const [badEmail, setBadEmail] = useState(false);
  const [badPassword, setBadPassword] = useState(false);

  const onSubmit = useMemo(
    () => (email: string, password: string) => {
      getGeneratedReport({
        variables: {
          slugId,
          viewerCompany,
          email,
          password,
        },
      });
    },
    [getGeneratedReport]
  );

  useEffect(() => {
    if (error) {
      if (isApolloError(error)) {
        if (
          error.graphQLErrors.some(err => err.extensions?.code === "FORBIDDEN")
        ) {
          setBadPassword(true);
          setBadEmail(false);
        } else if (
          error.graphQLErrors.some(
            err => err.extensions?.code === "GRAPHQL_VALIDATION_FAILED"
          )
        ) {
          setBadPassword(false);
          setBadEmail(true);
        } else {
          LogError(error);
        }
      } else {
        LogError(error);
      }
    }
  });

  if (data) {
    return (
      <ReportPageDataInjector presentedReport={data.public.generatedReport} />
    );
  }

  return (
    <ReportSignIn
      onSubmit={onSubmit}
      showBadEmail={badEmail}
      showWrongPassword={badPassword}
    />
  );
};

export const ViewerAuthPage: React.FC<{
  match: match<{ slug: string; slugId: string; viewerCompany: string }>;
}> = props => {
  const slugId = props.match.params.slugId;
  const viewerCompany = props.match.params.viewerCompany;
  const { data, loading, error } = useGetGeneratedReportAutologinQuery({
    variables: {
      slugId,
      viewerCompany,
    },
  });

  if (loading) {
    return <FullPageSpinner />;
  }

  if (data) {
    if (
      data.public.generatedReportAutologin.__typename === "BaseUserError" &&
      data.public.generatedReportAutologin.code ===
        ErrorCode.GENERATED_REPORT_AUTOLOGIN_FAILURE_ERROR
    ) {
      return (
        <ReportViewerLogin slugId={slugId} viewerCompany={viewerCompany} />
      );
    } else if (
      data.public.generatedReportAutologin.__typename ===
      "GeneratedReportAutologinSuccess"
    ) {
      return (
        <ReportPageDataInjector
          presentedReport={data.public.generatedReportAutologin.report}
        />
      );
    }
  }

  if (error) {
    if (
      isApolloError(error) &&
      error.graphQLErrors.some(
        err => err.extensions?.code === "RESOURCE_NOT_FOUND"
      )
    ) {
      LogError(error, false);
      return (
        <ReportViewerLogin slugId={slugId} viewerCompany={viewerCompany} />
      );
    } else {
      LogError(error);
    }
  }

  return null;
};

interface IReportPageProps {
  presentedReport: GeneratedReport;
}

const ReportPageDataInjector: React.FC<IReportPageProps> = ({
  presentedReport,
}) => {
  const { standard, domainId } = presentedReport;
  const { error, loading, data } = useGetComplianceStandardQuery({
    variables: { standard, domainId },
  });
  if (error) {
    LogError(error);
    return null;
  }
  if (loading || !isSome(data) || !isSome(data.complianceStandard)) {
    return <FullPageSpinner />;
  }
  const domain = presentedReportToDomainForReport(presentedReport);
  const complianceStandard = filterStandard(data.complianceStandard, domain);
  return (
    <VantaReportPage domain={domain} complianceStandard={complianceStandard} />
  );
};
