import "./user-selector.scss";

import type { IconName } from "@blueprintjs/core";
import { Icon, MenuItem } from "@blueprintjs/core";
import { MultiSelect, Select } from "@blueprintjs/select";
import type { Maybe } from "common/base/types/maybe";
import { dropNothing, isSome, nothing } from "common/base/types/maybe";
import gql from "graphql-tag";
import React from "react";

import { LogError } from "../../errors";
import type { AllUsersQuery } from "../../gen/components";
import {
  useAllUsersInternalQuery,
  useAllUsersQuery,
} from "../../gen/components";
import { DefaultLink } from "../../helpers/links";
import { MaybeProfileImage } from "../helpers/MaybeProfileImage";
export interface INonUserChoice {
  icon: IconName;
  text: string;
  value: string;
}

type AllUsersUser = NonNullable<AllUsersQuery["organization"]>["users"][number];

const UserSelect = Select.ofType<AllUsersUser | INonUserChoice>();
const UserMultiSelect = MultiSelect.ofType<AllUsersUser | INonUserChoice>();

interface IProps {
  allowNonHumanUsers?: Maybe<boolean>;
  undeletable?: Maybe<boolean>;
  disabled?: Maybe<boolean>;
  multiselect?: Maybe<boolean>;
  nonUserChoices?: Maybe<INonUserChoice[]>;
  prompt?: Maybe<string>;
  selected?: Maybe<string | string[]>; // can be mongo ID(s) or email(s)
  usersToExclude?: Maybe<string[]>;
  includeRemovedUsers?: Maybe<boolean>;
  onSelect(choice: Maybe<string[]>): void;
}

interface IInternalProps extends IProps {
  allUsers: AllUsersUser[];
}

class Component extends React.Component<IInternalProps> {
  private selectedItems: Array<AllUsersUser | INonUserChoice> = [];

  public constructor(props: IInternalProps) {
    super(props);
    this.onUserSelect = this.onUserSelect.bind(this);
    this.onUserRemove = this.onUserRemove.bind(this);
    this.renderMultiSelectUser = this.renderMultiSelectUser.bind(this);

    this.setSelectedFromProps(props);
  }

  public UNSAFE_componentWillReceiveProps(props: IProps) {
    if (this.props.selected !== props.selected) {
      this.setSelectedFromProps(props);
    }
  }

  public render() {
    let allUsers: Maybe<Array<AllUsersUser | INonUserChoice>> =
      this.props.allUsers.filter(
        user =>
          (this.props.includeRemovedUsers || user.isActive) &&
          (!("isNotHuman" in user) ||
            (isSome(this.props.allowNonHumanUsers) &&
              this.props.allowNonHumanUsers) ||
            !user.isNotHuman) &&
          (!isSome(this.props.usersToExclude) ||
            !this.props.usersToExclude.includes(user.id))
      );

    allUsers = [
      ...allUsers,
      ...(this.props.nonUserChoices ? this.props.nonUserChoices : []),
    ];
    if (!this.props.multiselect && !this.props.undeletable) {
      allUsers = [
        { icon: "delete", text: "Remove selection", value: "_delete" },
        ...allUsers,
      ];
    }

    if (this.props.multiselect) {
      const multiSelected = this.getObjectsForKeys(
        !isSome(this.props.selected) || !isSome(this.props.selected)
          ? []
          : typeof this.props.selected === "string"
          ? [this.props.selected]
          : this.props.selected
      );

      return (
        <UserMultiSelect
          popoverProps={{
            autoFocus: true,
            className: "user-selector-popup",
            usePortal: true,
          }}
          items={allUsers}
          itemPredicate={this.filterUser}
          itemRenderer={this.renderUserForSuggest}
          tagRenderer={this.renderMultiSelectUser}
          noResults={<MenuItem disabled={true} text="No results." />}
          onItemSelect={this.onUserSelect}
          selectedItems={dropNothing(multiSelected)}
          tagInputProps={{
            onRemove: this.onUserRemove,
            tagProps: { minimal: true },
          }}
        />
      );
    }

    let maybeChoice = this.props.selected;
    if (isSome(maybeChoice) && typeof maybeChoice !== "string") {
      if (maybeChoice.length !== 1) {
        throw new Error(
          "Had not-one elements in a single element user selector"
        );
      }
      maybeChoice = maybeChoice[0];
    }

    const maybeChoiceObject = isSome(maybeChoice)
      ? this.getObjectsForKeys([maybeChoice])[0]
      : nothing;
    const selectedElement = this.getImageAndTextForItem(maybeChoiceObject);

    if (this.props.disabled) {
      return selectedElement;
    }

    return (
      <UserSelect
        popoverProps={{
          autoFocus: true,
          className: "user-selector-popup",
          usePortal: true,
        }}
        items={allUsers}
        itemPredicate={this.filterUser}
        itemRenderer={this.renderUserForSuggest}
        noResults={<MenuItem disabled={true} text="No results." />}
        onItemSelect={this.onUserSelect}
      >
        <DefaultLink href="javascript:void(0)">{selectedElement}</DefaultLink>
      </UserSelect>
    );
  }

  private filterUser(query: string, choice: AllUsersUser | INonUserChoice) {
    const val =
      "email" in choice
        ? isSome(choice.displayName)
          ? choice.displayName
          : choice.email
        : choice.text;
    return val.toLowerCase().includes(query.toLowerCase());
  }

  private getImageAndTextForItem(item: Maybe<AllUsersUser | INonUserChoice>) {
    if (isSome(item)) {
      return <UserAndProfileImage item={item} />;
    }
    const profileImage = (
      <MaybeProfileImage circle={true} dimension={24} url={null} />
    );
    const displayText = isSome(this.props.prompt)
      ? this.props.prompt
      : "Choose user";

    return (
      <div className="user-selector-user-image">
        {profileImage}
        <div className="user-selector-user-name">{displayText}</div>
      </div>
    );
  }

  private getObjectsForKeys(keys: string[]) {
    const users = this.props.allUsers;

    return keys.map(k => {
      const foundChoice = !this.props.nonUserChoices
        ? undefined
        : this.props.nonUserChoices.find(c => c.value === k);
      if (foundChoice) {
        return foundChoice;
      }

      const user = dropNothing(users).find(u => u.id === k || u.email === k);
      if (!user) {
        return null;
      }
      return user;
    });
  }

  private onUserRemove(item: any, index: number) {
    this.selectedItems = [
      ...this.selectedItems.slice(0, index),
      ...this.selectedItems.slice(index + 1),
    ];
    this.pushMultiselectChoice();
  }

  private onUserSelect(choice: AllUsersUser | INonUserChoice) {
    if (!this.props.multiselect) {
      const sentChoice =
        "id" in choice
          ? choice.id
          : choice.value === "_delete"
          ? undefined
          : choice.value;
      if (isSome(sentChoice)) {
        this.props.onSelect([sentChoice]);
      } else {
        this.props.onSelect(undefined);
      }
      return;
    }

    if (!this.selectedItems.find(c => c === choice)) {
      this.selectedItems.push(choice);
      this.pushMultiselectChoice();
    }
  }

  private pushMultiselectChoice() {
    this.props.onSelect(
      this.selectedItems.map(i => ("id" in i ? i.id : i.value))
    );
  }

  private renderMultiSelectUser(item: AllUsersUser | INonUserChoice) {
    return this.getImageAndTextForItem(item);
  }

  private renderUserForSuggest(
    choice: AllUsersUser | INonUserChoice,
    params: { handleClick: any }
  ) {
    if ("email" in choice) {
      return (
        <MenuItem
          key={choice.id}
          text={isSome(choice.displayName) ? choice.displayName : choice.email}
          icon={choice.isActive ? undefined : "blocked-person"}
          onClick={params.handleClick}
        />
      );
    }
    return (
      <MenuItem
        key={`${choice.value}_${choice.text}`}
        icon={choice.icon}
        text={choice.text}
        onClick={params.handleClick}
      />
    );
  }

  private setSelectedFromProps(props: IProps) {
    if (props.multiselect) {
      if (!isSome(props.selected)) {
        this.selectedItems = [];
      } else if (typeof props.selected === "string") {
        throw new Error(
          "In a multiselect, you must specify selected as an array"
        );
      } else {
        this.selectedItems = dropNothing(
          this.getObjectsForKeys(props.selected)
        );
      }
    }
  }
}

gql`
  query AllUsers {
    organization {
      id
      users(includeRemovedUsers: true, includeNonHumanUsers: true) {
        id
        displayName
        email
        imageUrl
        isActive
        isContractor
        isNotHuman
      }
    }
  }
`;

gql`
  query AllUsersInternal($domainId: ID!) {
    internal {
      domainById(id: $domainId) {
        id
        users(includeRemovedUsers: true, includeNonHumanUsers: true) {
          id
          displayName
          email
          imageUrl
          isActive
          isContractor
          isNotHuman
        }
      }
    }
  }
`;

export const UserSelectorInternal: React.FunctionComponent<
  IProps & { domainId: string }
> = props => {
  const { data, loading, error } = useAllUsersInternalQuery({
    variables: { domainId: props.domainId },
  });
  if (error) {
    LogError(error);
    return null;
  }

  if (loading) {
    return <div />;
  }

  if (!data) {
    LogError(new Error("No data for user selector"));
    return null;
  }

  return <Component {...props} allUsers={data.internal.domainById.users} />;
};

export const UserSelector: React.FunctionComponent<IProps> = props => {
  const { data, loading, error } = useAllUsersQuery();
  if (error) {
    LogError(error);
    return null;
  }

  if (loading) {
    return <div />;
  }

  if (!data) {
    LogError(new Error("No data for user selector"));
    return null;
  }

  return <Component {...props} allUsers={data.organization.users} />;
};

const UserAndProfileImage: React.FC<{
  item: AllUsersUser | INonUserChoice;
}> = ({ item }) => {
  let profileImage: React.ReactNode;
  let displayText: string;
  if ("value" in item) {
    profileImage = (
      <Icon icon={item.icon} iconSize={UserSelectorStyles.USER_ICON_SIZE} />
    );
    displayText = item.text;
  } else {
    profileImage = (
      <MaybeProfileImage
        circle={true}
        dimension={UserSelectorStyles.USER_ICON_SIZE}
        url={item.imageUrl}
      />
    );
    if (!item.isActive) {
      displayText = isSome(item.displayName)
        ? `Previous owner was ${item.displayName}`
        : "Unassigned";
    } else {
      displayText = item.displayName as string;
    }
  }

  return (
    <div className="user-selector-user-image">
      {profileImage}
      <div className="user-selector-user-name">{displayText}</div>
    </div>
  );
};

const UserSelectorStyles = {
  USER_ICON_SIZE: 24,
  CONTRACTOR_ICON_SIZE: 20,
};
