import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import React from "react";

import { LogErrorMessage } from "../../../errors";
import HighIndicator from "./icons/high-indicator.svg";
import LowIndicator from "./icons/low-indicator.svg";
import MidIndicator from "./icons/medium-indicator.svg";
import { TableNormalText } from "./table-styles";

interface IProps {
  value?: Maybe<number>;
}

const sourceForValue = (value: number) => {
  switch (value) {
    case 0:
      return LowIndicator;
    case 1:
      return MidIndicator;
    case 2:
      return HighIndicator;
    default:
      LogErrorMessage(`Unexpected value for RiskEvaluationMeter: ${value}`);
      return HighIndicator;
  }
};

export const RiskEvaluationMeter: React.FC<IProps> = ({ value }) => {
  if (!isSome(value)) {
    return <TableNormalText>not rated</TableNormalText>;
  } else {
    const SVG = sourceForValue(value);
    return (
      <div>
        <SVG />
      </div>
    );
  }
};
