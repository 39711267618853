import { DatePicker } from "@blueprintjs/datetime";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import moment from "moment";
import React, { useState } from "react";

import { LogError } from "../../../errors";
import type { FetchDataForPeoplePageQuery } from "../../../gen/components";
import {
  FetchDataForPeoplePageDocument,
  useChangeStartDateMutation,
} from "../../../gen/components";
import { CancelConfirmDialog } from "../../helpers/CancelConfirmDialog";
import type { DetailHeadingUser } from "./user-drawer/user-detail-heading";

interface IProps {
  isOpen: boolean;
  user: DetailHeadingUser;
  onClose(): void;
}

export const SetStartDateDialog: React.FC<IProps> = ({
  isOpen,
  user,
  onClose,
}) => {
  const [selectedDate, setSelectedDate] = useState<Date>(
    new Date(user.startDate)
  );
  const [setStartDate, mutationResult] = useChangeStartDateMutation({
    refetchQueries: [{ query: FetchDataForPeoplePageDocument }],
    update(cache, result) {
      const updatedUser = result.data?.changeUserStartDate;
      if (!isSome(user)) {
        return;
      }
      const allUserData = cache.readQuery<FetchDataForPeoplePageQuery>({
        query: FetchDataForPeoplePageDocument,
      })!.organization!;
      cache.writeQuery<FetchDataForPeoplePageQuery>({
        query: FetchDataForPeoplePageDocument,
        data: {
          organization: {
            ...allUserData,
            users: allUserData.users.map(u => {
              if (u.id === updatedUser!.id) {
                return { ...u, startDate: updatedUser!.startDate };
              } else {
                return u;
              }
            }),
          },
        },
      });
    },
  });

  return (
    <CancelConfirmDialog
      confirmText="Save"
      onConfirm={() => {
        setStartDate({
          variables: {
            userId: user.id,
            startDate: selectedDate!.toISOString(),
          },
        }).catch(LogError);
      }}
      loading={mutationResult.loading}
      confirmDisabled={
        selectedDate.valueOf() === new Date(user.startDate).valueOf()
      }
      isOpen={isOpen}
      onClose={onClose}
      title={`Set start date for ${user.displayName}`}
      body={
        <div>
          <DatePicker
            value={selectedDate}
            onChange={setSelectedDate}
            maxDate={moment().add(20, "years").toDate()}
          />
        </div>
      }
    />
  );
};

gql`
  mutation changeStartDate($userId: ID!, $startDate: DateTime!) {
    changeUserStartDate(userId: $userId, startDate: $startDate) {
      id
      startDate
    }
  }
`;
