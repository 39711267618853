import { Intent } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import {
  applyIfSome,
  getTransformedOrElse,
  isSome,
} from "common/base/types/maybe";
import React, { useState } from "react";
import styled from "styled-components";

import { BASE_PALETTE } from "../../../../../alpaca/base/colors";
import { GRID_SPACING } from "../../../../../alpaca/base/grid";
import { BASE_TYPOGRAPHY } from "../../../../../alpaca/base/typography";
import type { ITooltipProps } from "../../../../../alpaca/components";
import {
  BodyText,
  Icon,
  IconNames,
  SpacedList,
} from "../../../../../alpaca/components";
import EmptyCircle from "../../../../../static/images/icons/empty-circle.svg";
import { TaskDateDetail } from "./task-date-detail";

interface IProps {
  title: string;
  completionDate?: Maybe<Date>;
  dateTooltipProps?: Maybe<ITooltipProps>;
  /**
   * Action buttons show up directly to the left of the `rightElements`, and
   * only show up when the task row is being hovered over.
   */
  actionButtons?: Maybe<React.ReactNode[]>;
  rightElements?: Maybe<React.ReactNode[]>;
}

export const TaskListItem: React.FC<IProps> = ({
  title,
  completionDate,
  dateTooltipProps,
  actionButtons,
  rightElements,
}) => {
  const [hovered, setHovered] = useState(false);

  const icon = (
    <StyledIconContainer>
      {isSome(completionDate) ? (
        <Icon icon={IconNames.CHECKMARK} color={BASE_PALETTE.KALE} />
      ) : (
        <EmptyCircle />
      )}
    </StyledIconContainer>
  );
  const maybeCompletionDate = applyIfSome(completionDate, date => (
    <TaskDateDetail
      date={date}
      intent={Intent.SUCCESS}
      tooltipProps={dateTooltipProps}
    />
  ));
  const titleText = (
    <BodyText
      as="span"
      lineHeight="16px"
      fontWeight={BASE_TYPOGRAPHY.FONT_WEIGHTS.SLIGHTLY_BOLD}
    >
      {title}
    </BodyText>
  );
  const spacedRightElements = applyIfSome(rightElements, elements => (
    <SpacedList marginOverride={12}>{elements}</SpacedList>
  ));
  const rightElementsWithActions = getTransformedOrElse(
    actionButtons,
    actions => (
      <SpacedList>
        <SpacedList
          marginOverride={12}
          style={{ visibility: hovered ? "visible" : "hidden" }}
        >
          {actions}
        </SpacedList>
        {spacedRightElements}
      </SpacedList>
    ),
    spacedRightElements
  );

  return (
    <StyledTaskRow
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <SpacedList marginOverride={GRID_SPACING}>
        {icon}
        {maybeCompletionDate}
        {titleText}
      </SpacedList>
      {rightElementsWithActions}
    </StyledTaskRow>
  );
};

const StyledTaskRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${2 * GRID_SPACING}px ${2.5 * GRID_SPACING}px;
  min-height: ${7 * GRID_SPACING}px;

  border-top: 0.5px solid ${BASE_PALETTE.WIND};
`;

const StyledIconContainer = styled.div`
  svg {
    width: ${2 * GRID_SPACING}px;
    height: ${2 * GRID_SPACING}px;
  }
`;
