import { groupBy } from "lodash";
import { ReportStandard } from "../../base/types/gen";
import type { IStandard } from "../complianceTypes";
import type { StandardMapping } from "../standard-mapping";
import * as pciMapping from "./standard-mappings/1.0.json";

const base = {
  version: "3.2",
  updated: "05/2016",
  canonical:
    "https://www.pcisecuritystandards.org/documents/PCIDSS_QRGv3_2.pdf",
  principles: [
    {
      name: "Install and maintain a firewall configuration to protect data",
      optional: false,
      section: "Requirement 1",
      requirements: [],
    },
    {
      name: "Do not use vendor-supplied defaults for system passwords and other security parameters",
      optional: false,
      section: "Requirement 2",
      requirements: [],
    },
    {
      name: "Protect stored cardholder data",
      optional: false,
      section: "Requirement 3",
      requirements: [],
    },
    {
      name: "Encrypt transmission of cardholder data across open, public networks",
      optional: false,
      section: "Requirement 4",
      requirements: [],
    },
    {
      name: "Protect all systems against malware and regularly update anti-virus software or programs",
      optional: false,
      section: "Requirement 5",
      requirements: [],
    },
    {
      name: "Develop and maintain secure systems and applications",
      optional: false,
      section: "Requirement 6",
      requirements: [],
    },
    {
      name: "Restrict access to cardholder data by business need to know",
      optional: false,
      section: "Requirement 7",
      requirements: [],
    },
    {
      name: "Identify and authenticate access to system components",
      optional: false,
      section: "Requirement 8",
      requirements: [],
    },
    {
      name: "Restrict physical access to cardholder data",
      optional: false,
      section: "Requirement 9",
      requirements: [],
    },
    {
      name: "Track and monitor all access to network resources and cardholder data",
      optional: false,
      section: "Requirement 10",
      requirements: [],
    },
    {
      name: "Regularly test security systems and processes",
      optional: false,
      section: "Requirement 11",
      requirements: [],
    },
    {
      name: "Maintain a policy that addresses information security for all personnel",
      optional: false,
      section: "Requirement 12",
      requirements: [],
    },
  ],
};

export function getPciStandard(
  standard: ReportStandard,
  mapping: StandardMapping
): IStandard {
  const controlsByPrinciple = groupBy(
    mapping.controls,
    control => control.id.split(".")[0]
  );
  return {
    ...base,
    standard,
    principles: base.principles.map(principle => {
      const principleNumber = principle.section.split(" ")[1];
      return {
        ...principle,
        requirements: (controlsByPrinciple[principleNumber] ?? []).map(
          control => {
            return {
              // Because PCI is prescriptive, PCI standards have a 1:1 mapping
              // between requirements and controls. On the external security
              // report, there's no need to duplicate the requirement and
              // control copy.
              name: "",
              section: control.id,
            };
          }
        ),
      };
    }),
  };
}

export const PCI_DSS_SAQ_A_STANDARD = getPciStandard(
  ReportStandard.pciSaqA,
  pciMapping
);
