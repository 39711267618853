import { Button, Divider, Intent } from "@blueprintjs/core";
import { CronService } from "common/base/types/gen";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import React, { useMemo, useState } from "react";
import styled from "styled-components";

import {
  useGetAllDomainNamesQuery,
  useGetCronFiltersQuery,
} from "../../gen/components";
import { FullPageSpinner } from "../helpers/FullPageSpinner";
import { CronFilterBlacklistDialog } from "./cron-filter-blacklist-dialog";
import { CronFilterUnblacklistButton } from "./cron-filter-unblacklist-button";

const CRONFILTER_NAME_TO_CRONSERVICE: { [k: string]: CronService } = {
  "resource-fetcher": CronService.resourcefetcher,
  "test-runner": CronService.testrunner,
};

export const CronFilterPanel: React.FC = () => {
  const { loading, data } = useGetCronFiltersQuery();
  const [blacklistDialogIsOpen, setBlacklistDialogIsOpen] = useState(false);
  const domainNamesQuery = useGetAllDomainNamesQuery({
    fetchPolicy: "cache-first",
  });
  const domainNamesMap = useMemo(() => {
    if (
      isSome(domainNamesQuery.data) &&
      isSome(domainNamesQuery.data.internal.allDomains)
    ) {
      return domainNamesQuery.data.internal.allDomains.reduce((obj, domain) => {
        if (isSome(domain)) {
          obj[domain.id] = domain.name;
        }
        return obj;
      }, {} as { [k: string]: string });
    } else {
      return {};
    }
  }, [domainNamesQuery.data?.internal.allDomains]);

  if (loading || !data) {
    return <FullPageSpinner />;
  }
  const cronFilters = data.cronFilters;
  const displayTextForDomain = (domainId: string) => {
    const maybeName = domainNamesMap[domainId];
    if (isSome(maybeName)) {
      return `${maybeName} (${domainId})`;
    } else {
      return domainId;
    }
  };
  return (
    <Container>
      <h1>Cron Filters</h1>
      <ul>
        <li>
          Cron filters let you block a service from running at all for a
          specified domain / item pair. Use this if you want to disable a
          resource fetch or test run entirely.
        </li>
        <li>
          If you want to have a test still run but just not save the data, use
          the "Killswitch tests" tab instead.
        </li>
      </ul>

      <Section>
        <Button
          intent={Intent.PRIMARY}
          onClick={() => setBlacklistDialogIsOpen(true)}
        >
          Blacklist Domain and/or item
        </Button>
      </Section>
      <Divider />

      {cronFilters.map(cronFilter => {
        const queue = CRONFILTER_NAME_TO_CRONSERVICE[cronFilter.queueName];

        return (
          <Section key={cronFilter.id}>
            <h2>Cron filters for {cronFilter.queueName}</h2>
            {cronFilter.blacklistDomains.length > 0 ? (
              <>
                <h3>Blacklisted Domains</h3>
                <ul>
                  {cronFilter.blacklistDomains.map(domainId => (
                    <li key={domainId}>
                      <div>
                        {displayTextForDomain(domainId)}
                        <CronFilterUnblacklistButton
                          queue={queue}
                          domainId={domainId}
                        />
                      </div>
                    </li>
                  ))}
                </ul>
              </>
            ) : null}
            {cronFilter.blacklistItems.length > 0 ? (
              <>
                <h3>Blacklisted Items</h3>
                <ul>
                  {cronFilter.blacklistItems.map(item => (
                    <li key={item}>
                      <div>
                        {item}
                        <CronFilterUnblacklistButton
                          queue={queue}
                          item={item}
                        />
                      </div>
                    </li>
                  ))}
                </ul>
              </>
            ) : null}
            {cronFilter.blacklistDomainsAndItems.length > 0 ? (
              <>
                <h3>Blacklisted Domain/Item Pairs</h3>
                <ul>
                  {cronFilter.blacklistDomainsAndItems.map(domainItemPair => (
                    <li
                      key={`${domainItemPair.domainId}_${domainItemPair.item}`}
                    >
                      <div>
                        <span>
                          {displayTextForDomain(domainItemPair.domainId)} (
                          <strong>{domainItemPair.item}</strong>)
                        </span>
                        <CronFilterUnblacklistButton
                          queue={queue}
                          domainId={domainItemPair.domainId}
                          item={domainItemPair.item}
                        />
                      </div>
                    </li>
                  ))}
                </ul>
              </>
            ) : null}
            <Divider />
          </Section>
        );
      })}
      {blacklistDialogIsOpen ? (
        <CronFilterBlacklistDialog
          onClose={() => setBlacklistDialogIsOpen(false)}
        />
      ) : null}
    </Container>
  );
};

const styles = {
  PADDING: 40,
  LI_WIDTH: 600,
  SECTION_BOTTOM_MARGIN: 40,
  H2_BOTTOM_MARGIN: 20,
};

const Container = styled.div`
  padding: ${styles.PADDING}px;
  li > div {
    width: ${styles.LI_WIDTH}px;
    display: inline-flex;
    justify-content: space-between;
  }
`;

const Section = styled.div`
  margin-bottom: ${styles.SECTION_BOTTOM_MARGIN}px;
  h2 {
    margin-bottom: ${styles.H2_BOTTOM_MARGIN}px;
  }
`;

gql`
  query getCronFilters {
    cronFilters {
      id
      queueName
      blacklistDomains
      blacklistItems
      blacklistDomainsAndItems {
        domainId
        item
      }
    }
  }
`;
