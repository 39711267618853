import { Dialog } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import React from "react";
import { useHistory } from "react-router";

import { PostAuthForm } from "./post-auth-form";

export const PostAuthDialog: React.FunctionComponent<{
  service: Maybe<string>;
  dialogIsOpen: boolean;
}> = ({ service, dialogIsOpen }) => {
  const history = useHistory();

  if (!isSome(service) || service === "") {
    return null;
  }

  const closeDialog = () => {
    history.push(`/connections`);
  };

  return (
    <Dialog isOpen={dialogIsOpen} onClose={() => closeDialog()}>
      <PostAuthForm service={service} onClose={() => closeDialog()} />
    </Dialog>
  );
};
