import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import React from "react";
import styled from "styled-components";

import { VANTA_COLORS } from "../../../../alpaca/base/colors";
import { GRID_SPACING } from "../../../../alpaca/base/grid";
import { BodyText } from "../../../../alpaca/components";
import Completion from "../../../../static/images/icons/completion-check.svg";
import EmptyCircle from "../../../../static/images/icons/empty-circle.svg";
import { IconContainer } from "../shared/common";
import { InfoIcon } from "../shared/info-icon";

interface IProps {
  isCompleted: boolean;
  mainText: React.ReactNode;
  detailText?: Maybe<React.ReactText>;
  rightControl?: Maybe<JSX.Element>;
  tooltipContent?: Maybe<string>;
}

export const GenericTaskItem: React.FC<IProps> = ({
  isCompleted,
  mainText,
  detailText,
  rightControl,
  tooltipContent,
}) => {
  const maybeTooltip = isSome(tooltipContent) ? (
    <span style={{ marginLeft: "4px", verticalAlign: "middle" }}>
      <InfoIcon tooltipContent={tooltipContent} />
    </span>
  ) : null;
  const text = (
    <BodyText as="div" style={{ marginRight: `${2 * GRID_SPACING}px` }}>
      <span>{mainText}</span>
      {isSome(detailText) && detailText !== "" ? (
        <BodyText as="span" color={VANTA_COLORS.TEXT_MUTED}>
          {`: ${detailText}`}
        </BodyText>
      ) : null}
      {maybeTooltip}
    </BodyText>
  );
  const icon = isCompleted ? <Completion /> : <EmptyCircle />;

  return (
    <Container>
      <IconContainer isCompleted={isCompleted}>{icon}</IconContainer>
      <Flex>
        {text}
        {rightControl}
      </Flex>
    </Container>
  );
};

const styles = {
  CONTAINER_HEIGHT: 36,
};

const Container = styled.div`
  display: flex;
  align-items: center;
  min-height: ${styles.CONTAINER_HEIGHT}px;
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
`;
