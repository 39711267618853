import { Menu, MenuItem } from "@blueprintjs/core";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import React, { useCallback, useContext, useState } from "react";
import styled from "styled-components";

import { BASE_PALETTE, VANTA_COLORS } from "../../../alpaca/base/colors";
import {
  useAssumeUserMutationMutation,
  useUnassumeUserMutationMutation,
} from "../../../gen/components";
import { AssumeAdminDialog } from "../../nav/assume-admin-dialog";
import { UserContext } from "../../pages/user-context";
import { Dimensions } from "../constants";

export const AdminMenu: React.FC = () => {
  const { user } = useContext(UserContext);
  const unassumeIfAssumed = useCallback(async () => {
    if (Boolean(user?.isAssumedUser)) {
      await unassumeUser();
    }
  }, []);

  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [assumeUser] = useAssumeUserMutationMutation();
  const [unassumeUser] = useUnassumeUserMutationMutation();
  const getEmailAndAssume = async (writeAccess: boolean) => {
    await unassumeIfAssumed();
    const email = window.prompt(
      "Which email address would you like to assume?"
    );
    if (isSome(email)) {
      const mutationResult = await assumeUser({
        variables: { email, writeAccess },
      });
      if (Boolean(mutationResult.data?.assumeUser)) location.href = "/";
    }
  };
  return (
    <React.Fragment>
      <Container>
        <Menu>
          <MenuItem
            onClick={() => setDialogIsOpen(true)}
            text="Become domain admin (RO)"
          />
          <MenuItem
            onClick={async () => getEmailAndAssume(false)}
            text="Become user (RO)"
          />
          <MenuItem
            onClick={async () => getEmailAndAssume(true)}
            text="Become user (RW)"
          />
          <MenuItem
            onClick={async () => {
              await unassumeIfAssumed();
              const mutationResult = await assumeUser({
                variables: { email: "redpanda", writeAccess: false },
              });
              if (Boolean(mutationResult.data?.assumeUser)) {
                location.href = "/query";
              }
            }}
            text="Become redpanda (RO)"
          />
          <MenuItem
            onClick={async () => {
              await unassumeIfAssumed();
              const assumptionResult = await assumeUser({
                variables: { email: "redpanda", writeAccess: true },
              });
              if (Boolean(assumptionResult.data?.assumeUser)) {
                location.href = "/query";
              }
            }}
            text="Become redpanda (RW)"
          />
        </Menu>
      </Container>
      <AssumeAdminDialog
        isOpen={dialogIsOpen}
        onClose={() => setDialogIsOpen(false)}
      />
    </React.Fragment>
  );
};

const Container = styled.div`
  border: 1px solid ${VANTA_COLORS.MENU_SEPARATOR};
  && * {
    color: ${BASE_PALETTE.CITRUS};
  }
  font-size: 12px;
  line-height: 24px;
  border-radius: 4px;
  margin: 8px ${Dimensions.ACCOUNT_MENU_INSETS}px;
  padding: 1px;
  text-transform: uppercase;
`;

gql`
  mutation assumeUserMutation($email: String!, $writeAccess: Boolean!) {
    assumeUser(email: $email, writeAccess: $writeAccess)
  }
`;

gql`
  mutation unassumeUserMutation {
    unassumeUser
  }
`;
