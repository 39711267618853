import { Spinner } from "@blueprintjs/core";
import { dropNothing, isSome, nothing } from "common/base/types/maybe";
import gql from "graphql-tag";
import React from "react";
import { useHistory } from "react-router";

import { VANTA_COLORS } from "../../../../alpaca/base/colors";
import { StyledLinkDeprecated } from "../../../../alpaca/base/deprecated";
import { BASE_TYPOGRAPHY } from "../../../../alpaca/base/typography";
import { BodyText, DefaultView, H4 } from "../../../../alpaca/components";
import { LogError, LogErrorMessage } from "../../../../errors";
import { useFetchPackageVulnerabilityInfoQuery } from "../../../../gen/components";
import { DataTable } from "../../components/data-table";
import {
  LoadingContainer,
  TabContainer,
  TableContainer,
} from "../common/components";
import { LinksForCVEs } from "../common/link-for-cve";
import { getLastPingString, vulnToTitle } from "../utils";

interface IProps {
  vulnerablePackageId: string;
}

export const PackageVulnDetail: React.FC<IProps> = ({
  vulnerablePackageId,
}) => {
  const history = useHistory();

  const { error, loading, data } = useFetchPackageVulnerabilityInfoQuery({
    variables: {
      vulnerablePackageId,
    },
  });

  if (error) {
    LogError(error);
    history.push("/vulnerabilities");
    return <div />;
  }

  if (loading) {
    return (
      <LoadingContainer>
        <Spinner />
      </LoadingContainer>
    );
  }

  const vuln = data?.organization.packageVulnerabilities?.edges.map(
    e => e.node
  )?.[0];

  if (!isSome(vuln)) {
    history.push("/vulnerabilities");
    return <div />;
  }

  if ((vuln.machineInstances ?? []).length === 0) {
    LogErrorMessage(
      `Package vulnerability ${vuln.id} has no machines on which it would occur. This should never happen.`
    );
  }

  return (
    <DefaultView
      headerProps={{
        title: vulnToTitle(vuln),
        backLink: "/vulnerabilities#agent-vulns/packages",
        description: (
          <LinksForCVEs
            cves={dropNothing(
              vuln.cves.map(cve =>
                isSome(cve.cveId) ? { name: cve.cveId } : nothing
              )
            )}
          />
        ),
      }}
    >
      <TabContainer>
        <TableContainer>
          <H4 fontWeight={BASE_TYPOGRAPHY.FONT_WEIGHTS.SLIGHTLY_BOLD}>
            Machines affected
          </H4>
          <DataTable
            data={vuln.machineInstances ?? []}
            header={{
              title: "Instance",
              lastPing: "Last ping",
            }}
            columnOrder={["title", "lastPing"]}
            createRow={machine => {
              return {
                title: (
                  <StyledLinkDeprecated
                    to={`/vulnerabilities?serverId=${machine.vulnData.osquery?.id}`}
                  >
                    <BodyText color={VANTA_COLORS.VANTA_PURPLE}>
                      {machine?.vulnData?.osquery?.prettyName}
                    </BodyText>
                  </StyledLinkDeprecated>
                ),
                lastPing: getLastPingString(
                  machine?.vulnData?.osquery?.data?.lastPing
                ),
              };
            }}
            emptyDefault={"No machines were found with this vulnerability."}
          />
        </TableContainer>
      </TabContainer>
    </DefaultView>
  );
};

gql`
  query fetchPackageVulnerabilityInfo($vulnerablePackageId: String!) {
    organization {
      id
      packageVulnerabilities(
        first: 1
        vulnerablePackageIds: [$vulnerablePackageId]
      ) {
        edges {
          node {
            id
            packageName
            packageVersion
            packageSource
            os
            securityVersion
            cves {
              cveId
              score
            }
            maxSeverity
            machineInstances {
              id
              vulnData {
                id
                createdAt
                resolvedAt
                slaDeadline
                osquery {
                  id
                  prettyName
                  cloudProviderId
                  hostIdentifier
                  data {
                    id
                    lastPing
                    ... on linuxServerData {
                      osFamily
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
