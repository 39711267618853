import { Icon, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import gql from "graphql-tag";
import React from "react";
import styled from "styled-components";

import { LogError } from "../../errors";
import {
  ReportStandard,
  StandardsForDomainDocument,
  useOptIntoStandardMutation,
  useOptOutOfStandardMutation,
  useStandardsForDomainQuery,
} from "../../gen/components";
import { LoadingButton } from "./loading-button";

export const StandardSelector: React.FC<{ domainId: string }> = ({
  domainId,
}) => {
  const refetchQueries = [
    {
      query: StandardsForDomainDocument,
      variables: { domainId },
    },
  ];
  const [optIntoStandard, { loading: optingIn }] = useOptIntoStandardMutation({
    refetchQueries,
    awaitRefetchQueries: true,
  });
  const [optOutOfStandard, { loading: optingOut }] =
    useOptOutOfStandardMutation({
      refetchQueries,
      awaitRefetchQueries: true,
    });
  const { loading, error, data } = useStandardsForDomainQuery({
    variables: { domainId },
  });

  if (loading) {
    return <>Loading standard list...</>;
  }

  if (error || !data?.internal.domainById) {
    return <>Error fetching list of standards</>;
  }

  const mutationInFlight = optingIn || optingOut;

  const optedInStandards = data.internal.domainById.standards;

  const STANDARDS_TO_HIDE = [ReportStandard.vanta];

  const standardList = Object.values(ReportStandard)
    .filter(s => !STANDARDS_TO_HIDE.includes(s))
    .map(standard => {
      const standardSelected = optedInStandards.includes(standard);
      return (
        <React.Fragment key={standard}>
          <Flex>
            <div>
              <FlexHeading>
                <Icon
                  iconSize={Icon.SIZE_LARGE}
                  icon={
                    standardSelected ? IconNames.TICK_CIRCLE : IconNames.DISABLE
                  }
                  intent={standardSelected ? Intent.SUCCESS : Intent.DANGER}
                />
                <span>{standard}</span>
              </FlexHeading>
            </div>
            <div>
              <LoadingButton
                intent={standardSelected ? Intent.DANGER : Intent.PRIMARY}
                onClick={() => {
                  if (standardSelected) {
                    optOutOfStandard({
                      variables: { domainId, standard },
                    }).catch(LogError);
                  } else {
                    optIntoStandard({
                      variables: { domainId, standard },
                    }).catch(LogError);
                  }
                }}
                disabled={mutationInFlight}
              >
                {standardSelected ? "Revoke access" : "Grant access"}
              </LoadingButton>
            </div>
          </Flex>
          <hr />
        </React.Fragment>
      );
    });

  return <>{standardList}</>;
};

gql`
  mutation OptIntoStandard($domainId: ID!, $standard: reportStandard!) {
    optIntoStandard(domainId: $domainId, input: { standard: $standard }) {
      status
    }
  }
`;

gql`
  mutation OptOutOfStandard($domainId: ID!, $standard: reportStandard!) {
    optOutOfStandard(domainId: $domainId, input: { standard: $standard }) {
      status
    }
  }
`;

gql`
  query StandardsForDomain($domainId: ID!) {
    internal {
      domainById(id: $domainId) {
        id
        standards
      }
    }
  }
`;

export const FlexHeading = styled.h3`
  display: flex;
  flex-direction: row;
  align-items: center;
  & > * {
    margin-right: 12px;
  }
`;

export const Container = styled.div`
  padding: 48px;
  max-width: 800px;
  h1,
  h2 {
    margin: 24px 0;
  }
  p {
    color: #888;
  }
`;

export const Flex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
