import "./panel.scss";

import { H5, InputGroup } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import React, { useRef, useState } from "react";

import { Button } from "../../alpaca/components";
import { LogError } from "../../errors";
import type { AllDomainsMinimalQuery } from "../../gen/components";
import {
  useIdLookupNodeKeyQuery,
  useIdLookupUserByEmailQuery,
  useIdLookupUserByIdQuery,
} from "../../gen/components";
import { FullPageSpinner } from "../helpers/FullPageSpinner";
import { RawDataComponent } from "./raw-data-component";
import { reportError } from "./reportError";

interface IProps {
  domains: NonNullable<AllDomainsMinimalQuery["internal"]["allDomains"]>;
  setDomain(domainId: Maybe<string>): void;
}

interface IDomainProps {
  domain: { id: string; name: string };
  setDomain(domainId: Maybe<string>): void;
}

interface IUserProps {
  setDomain(domainId: Maybe<string>): void;
  email?: Maybe<string>;
  id?: Maybe<string>;
}

interface IEndpointProps {
  setDomain(domainId: Maybe<string>): void;
  nodeKey: string;
}

export const IdLookupPanel: React.FC<IProps> = ({ domains, setDomain }) => {
  const [searchString, setSearchString] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);

  function performLookup() {
    const domain = domains.find(
      d => d?.id === searchString || d?.name === searchString
    );

    // If we see 52 characters in between hypens then that's a full node_key, like what vanta-cli doctor outputs.
    const fullNodeKeyRegex = /\w+-(\S{52})-\w+/;
    const fullNodeKeyResult = fullNodeKeyRegex.exec(searchString);

    if (isSome(domain)) {
      return <DomainResult setDomain={setDomain} domain={domain} />;
    } else if (searchString.includes("@")) {
      return <UserResult setDomain={setDomain} email={searchString} />;
    } else if (isSome(fullNodeKeyResult)) {
      return (
        <EndpointResult setDomain={setDomain} nodeKey={fullNodeKeyResult[1]} />
      );
    } else if (searchString.length === 52) {
      // All node keys are 52 characters
      return <EndpointResult setDomain={setDomain} nodeKey={searchString} />;
    } else if (searchString.length === 24) {
      // IDs are 24 characters
      return <UserResult setDomain={setDomain} id={searchString} />;
    }

    if (searchString.length > 0) {
      reportError("Invalid search string");
    }
    return null;
  }

  const result = performLookup();

  return (
    <div className="panel-container">
      <div className="mid-panel">
        <div className="form-group">
          <H5>Lookup domain, user or Agent node_key</H5>
          <form
            onSubmit={event => {
              event.preventDefault();
              setSearchString(inputRef!.current!.value);
              inputRef!.current!.value = "";
            }}
          >
            <label>
              <div>Domain name, domain id, user name, user id or node key.</div>
              <InputGroup
                autoCapitalize={"off"}
                autoComplete={"off"}
                autoCorrect={"off"}
                autoFocus={true}
                name="formInput"
                placeholder="rocketship.com"
                required={true}
                inputRef={inputRef as any}
                type="text"
              />
            </label>
          </form>
        </div>
      </div>
      <div className="right-panel">{result}</div>
    </div>
  );
};

const DomainResult: React.FC<IDomainProps> = ({ domain, setDomain }) => {
  const result = {
    id: domain.id,
    name: domain.name,
  };

  return (
    <div>
      <RawDataComponent title={"Domain"} json={result} loading={false} />
      <Button onClick={() => setDomain(domain.id)} rightIcon="share">
        Set domain
      </Button>
    </div>
  );
};

const UserResult: React.FC<IUserProps> = ({ email, id, setDomain }) => {
  // The component will only ever have one of these set, so it's not really violating the rules-of-hooks
  const { error, loading, data } = isSome(email)
    ? // eslint-disable-next-line react-hooks/rules-of-hooks
      useIdLookupUserByEmailQuery({ variables: { email } })
    : // eslint-disable-next-line react-hooks/rules-of-hooks
      useIdLookupUserByIdQuery({ variables: { id: id! } });

  if (error) {
    LogError(error);
    return null;
  }
  if (loading || !data) {
    return <FullPageSpinner />;
  }
  const user = data.user;
  if (!isSome(user)) {
    reportError("User not found.");
    return null;
  }

  const result = {
    id: user.id,
    email: user.email,
    domain: {
      id: user.domain.id,
      name: user.domain.name,
    },
  };
  return (
    <div>
      <RawDataComponent title={"User"} json={result} loading={false} />
      <Button onClick={() => setDomain(user.domain.id)} rightIcon="share">
        Set domain
      </Button>
    </div>
  );
};

const EndpointResult: React.FC<IEndpointProps> = ({ setDomain, nodeKey }) => {
  const { error, loading, data } = useIdLookupNodeKeyQuery({
    variables: { id: nodeKey },
  });

  if (error) {
    LogError(error);
    return null;
  }
  if (loading || !data) {
    return <FullPageSpinner />;
  }
  const endpoint = data.osqueryEndpointMetadata;
  if (!endpoint.isEnrolled) {
    reportError("Node key not found.");
    return null;
  }

  const domainId = endpoint.domainId;
  const result = {
    isEnrolled: endpoint.isEnrolled,
    domainName: endpoint.domainName,
    domainId,
    cloudProviderId: endpoint.cloudProviderId,
    userEmail: endpoint.userEmail,
    platform: endpoint.platform,
    hostIdentifier: endpoint.hostIdentifier,
  };

  const maybeSetDomainTag = isSome(domainId) ? (
    <Button onClick={() => setDomain(domainId)} rightIcon="share">
      Set domain
    </Button>
  ) : null;

  return (
    <div>
      <RawDataComponent
        title={"Endpoint Metadata"}
        json={result}
        loading={false}
      />
      {maybeSetDomainTag}
    </div>
  );
};

gql`
  query IdLookupUserByEmail($email: String!) {
    user(email: $email) {
      id
      email
      displayName
      domain {
        id
        displayName
        name
      }
    }
  }
`;

gql`
  query IdLookupUserById($id: ID!) {
    user(id: $id) {
      id
      email
      displayName
      domain {
        id
        displayName
        name
      }
    }
  }
`;

gql`
  query IdLookupNodeKey($id: String!) {
    osqueryEndpointMetadata(nodeKey: $id) {
      isEnrolled
      domainName
      cloudProviderId
      userEmail
      platform
      hostIdentifier
      domainId
    }
  }
`;
