import type { SpecificAtlasClusterResource } from "common/base/types/gen";
import gql from "graphql-tag";

import type { TableParameters } from "../helpers";
import { booleanToEvaluationIcon } from "../helpers";
import { SHARED_DATABASE_TABLE_HEADERS } from "./helpers";

export const MONGOATLAS_DBS = ["atlasClusters"] as const;

export const mongoatlasDataTableParameters: { [k: string]: TableParameters } = {
  atlasClusters: {
    displayName: "MongoDB Atlas Clusters",
    columnOrder: [
      "name",
      "clusterTier",
      "encrypted",
      "backupsEnabled",
      "computeAutoscalingEnabled",
      "diskAutoscalingEnabled",
      "replicationFactor",
    ],
    header: {
      name: SHARED_DATABASE_TABLE_HEADERS.name,
      clusterTier: "Cluster tier",
      encrypted: SHARED_DATABASE_TABLE_HEADERS.encrypted,
      backupsEnabled: SHARED_DATABASE_TABLE_HEADERS.backups,
      computeAutoscalingEnabled: "Compute autoscaling",
      diskAutoscalingEnabled: "Disk autoscaling",
      replicationFactor: "Replication factor",
    },
    createRow: (instance: Partial<SpecificAtlasClusterResource>) => {
      return {
        name: instance.name,
        clusterTier: instance.clusterTier,
        encrypted: booleanToEvaluationIcon(true),
        backupsEnabled: booleanToEvaluationIcon(
          instance.backupEnabled || instance.pitEnabled
        ),
        computeAutoscalingEnabled: booleanToEvaluationIcon(
          instance.autoscalingComputeEnabled
        ),
        diskAutoscalingEnabled: booleanToEvaluationIcon(
          instance.autoscalingDiskEnabled
        ),
        replicationFactor: instance.replicationFactor,
      };
    },
  },
};

gql`
  query fetchMongoAtlasDBInfo {
    organization {
      id
      displayName
      atlasClusters: resources(
        first: 1000
        specificResourceType: AtlasCluster
      ) {
        totalCount
        edges {
          node {
            id
            ... on SpecificAtlasClusterResource {
              name
              backupEnabled
              pitEnabled
              clusterTier
              autoscalingComputeEnabled
              autoscalingDiskEnabled
              replicationFactor
            }
          }
        }
      }
    }
  }
`;
