import "./change-dialog.scss";

import {
  Button,
  Callout,
  Classes,
  Dialog,
  FormGroup,
  Intent,
  Spinner,
} from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import moment from "moment";
import React from "react";

import { useFetchChangeDetailsQuery } from "../../../gen/components";
import { UI_DATE_FORMAT } from "../../../helpers/common";
import { MarkdownRenderer } from "../components/markdown-renderer";
import type { Change } from "./change-table";

interface IChangeDetails {
  comments: Array<{
    body: string;
    createdAt: string;
    updatedAt: string;
    userId: string;
  }>;
  statuses: Array<{
    context: string;
    createdAt: string;
    updatedAt: string;
    description: string;
    state: string;
  }>;
  merged_by: {
    login: string;
    type: string;
    site_admin: boolean;
  };
  checksErrorMessage?: Maybe<string>;
}

interface IProps {
  service: string;
  activeChangeDetails?: Maybe<Change>;
  onClose(): void;
}

export const ChangeDialog: React.FC<IProps> = props => {
  const fetchVariables = isSome(props.activeChangeDetails)
    ? {
        prNumber: props.activeChangeDetails.id,
        repo: props.activeChangeDetails.repo,
        service: props.service,
      }
    : { prNumber: "", repo: "", service: "" };

  const { loading, data } = useFetchChangeDetailsQuery({
    variables: fetchVariables,
    skip: !isSome(props.activeChangeDetails),
  });

  return (
    <Dialog
      className="change-dialog"
      isOpen={isSome(props.activeChangeDetails)}
      onClose={props.onClose}
      title="View change details"
    >
      <div className={Classes.DIALOG_BODY}>{renderBody()}</div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button intent={Intent.PRIMARY} onClick={() => props.onClose()}>
            Close
          </Button>
        </div>
      </div>
    </Dialog>
  );

  function renderBody() {
    if (loading || !isSome(data) || !isSome(props.activeChangeDetails)) {
      return <Spinner />;
    }

    const extraChangeDetails: IChangeDetails = JSON.parse(data.changeDetails);

    return (
      <div className="change-dialog-body">
        <FormGroup label="Title">{props.activeChangeDetails.title}</FormGroup>
        <FormGroup label="Created by">
          {props.activeChangeDetails.authorAccountId}
        </FormGroup>
        <FormGroup label="Opened">
          {moment(props.activeChangeDetails.createdAt).format(UI_DATE_FORMAT)}
        </FormGroup>
        <FormGroup label="Closed">
          {isSome(props.activeChangeDetails.closedAt)
            ? moment(props.activeChangeDetails.closedAt).format(UI_DATE_FORMAT)
            : "Not closed"}
        </FormGroup>
        <FormGroup label="Approved by">
          {isSome(props.activeChangeDetails.reviewerAccountIds)
            ? props.activeChangeDetails.reviewerAccountIds.join(", ")
            : ""}
        </FormGroup>
        <FormGroup label="Merged by">
          {extraChangeDetails.merged_by.login}
        </FormGroup>
        <FormGroup label="Automated testing">
          <Callout className="change-dialog-description">
            {extraChangeDetails.statuses.map(s => (
              <div key={`${s.context}`}>
                {s.context}: {s.state} at{" "}
                {moment(s.updatedAt).format(UI_DATE_FORMAT)}
              </div>
            ))}
            {isSome(extraChangeDetails.checksErrorMessage) &&
            extraChangeDetails.checksErrorMessage.length > 0 ? (
              <p>{extraChangeDetails.checksErrorMessage}</p>
            ) : null}
          </Callout>
        </FormGroup>
        <FormGroup label="Description">
          <Callout className="change-dialog-description">
            {isSome(props.activeChangeDetails.body)
              ? MarkdownRenderer(props.activeChangeDetails.body)
              : null}
          </Callout>
        </FormGroup>
        <FormGroup label="Comments">
          <Callout>
            {extraChangeDetails.comments.map(c => (
              <div key={c.createdAt}>
                <div>
                  {c.userId} ({moment(c.createdAt).format(UI_DATE_FORMAT)})
                </div>
                <div>{c.body}</div>
              </div>
            ))}
          </Callout>
        </FormGroup>
      </div>
    );
  }
};

gql`
  query fetchChangeDetails(
    $repo: String!
    $prNumber: String!
    $service: String!
  ) {
    changeDetails(repo: $repo, prNumber: $prNumber, service: $service)
  }
`;
