import type { Maybe } from "common/base/types/maybe";
import React from "react";
import styled from "styled-components";

import { Dimensions as AppDimensions } from "../../../components/vanta-chrome/constants";
import type { IHeaderRowProps } from "../header-row/header-row";
import { HeaderRow } from "../header-row/header-row";

interface IDefaultViewProps {
  altMinWidth?: Maybe<number>;
  // eslint-disable-next-line vanta/optional-always-maybe, vanta/prefer-maybe
  className?: string | undefined;
  headerProps: IHeaderRowProps;
  // eslint-disable-next-line vanta/optional-always-maybe, vanta/prefer-maybe
  style?: React.CSSProperties | undefined;
}

export const DefaultView: React.FC<IDefaultViewProps> = ({
  altMinWidth,
  children,
  className,
  headerProps,
  style,
}) => (
  <DefaultViewResponsiveContainer
    altMinWidth={altMinWidth}
    className={className}
    style={style}
  >
    <HeaderRow {...headerProps} />
    {children}
  </DefaultViewResponsiveContainer>
);

const DIMENSIONS = {
  MARGIN: 48,
  MIN_VIEW_WIDTH: 1200,
  MAX_VIEW_WIDTH: 1520,
};

const DefaultViewResponsiveContainer = styled.div<{
  altMinWidth?: Maybe<number>;
}>`
  margin: ${DIMENSIONS.MARGIN}px;
  min-height: calc(
    100vh - ${AppDimensions.TOP_BAR_HEIGHT + 2 * DIMENSIONS.MARGIN}px
  );
  @media (min-width: 320px) {
    width: ${({ altMinWidth }) =>
      (altMinWidth ?? DIMENSIONS.MIN_VIEW_WIDTH) - 2 * DIMENSIONS.MARGIN}px;
  }
  @media (min-width: ${({ altMinWidth }) =>
      altMinWidth ?? DIMENSIONS.MIN_VIEW_WIDTH}px) {
    width: calc(100% - ${2 * DIMENSIONS.MARGIN}px);
  }
  @media (min-width: ${DIMENSIONS.MAX_VIEW_WIDTH}px) {
    width: ${DIMENSIONS.MAX_VIEW_WIDTH - 2 * DIMENSIONS.MARGIN}px;
    margin: ${DIMENSIONS.MARGIN}px auto;
  }
`;
