import type { SpecificGcpMonitoringPolicyResource } from "common/base/types/gen";
import gql from "graphql-tag";

export const gcpDataTableParameters = {
  columnOrder: ["projectId", "name", "enabled", "thresholdNames"],
  displayName: "GCP Monitors",
  header: {
    projectId: "Project ID",
    name: "Name",
    enabled: "Enabled",
    thresholdNames: "Threshold Names",
  },
  createRow: (policy: Partial<SpecificGcpMonitoringPolicyResource>) => {
    return {
      name: policy.name,
      enabled: policy.enabled ? "Yes" : "No",
      thresholdNames: policy.thresholdConditions?.map(t => t.name).join(", "),
      projectId: policy.projectId,
    };
  },
};

gql`
  query fetchGcpMonitorInfo {
    organization {
      id
      displayName
      alarms: resources(
        first: 1000
        specificResourceType: GCPMonitoringPolicy
      ) {
        totalCount
        edges {
          node {
            id
            ... on SpecificGCPMonitoringPolicyResource {
              projectId
              thresholdConditions {
                name
              }
              enabled
              name
            }
          }
        }
      }
    }
  }
`;
