import type { DialogProps as BPDialogProps } from "@blueprintjs/core";
import { Dialog as BPDialog } from "@blueprintjs/core";
import classnames from "classnames";
import type { Maybe } from "common/base/types/maybe";
import { applyIfSome } from "common/base/types/maybe";
import React from "react";
import styled from "styled-components";

import { BASE_PALETTE } from "../../base/colors";
import { IconButton } from "../button2/icon-button";
import { CardTitleRow } from "../cards/card-title-row";
import { IconNames } from "../icon/iconNames";
import { SpacedList } from "../primitives/spaced-list";

export const DialogClasses = {
  DIALOG: "alpaca-dialog",
  HEADER: "alpaca-dialog-header",
  BODY: "alpaca-dialog-body",
  FOOTER: "alpaca-dialog-footer",
  LARGE: "alpaca-dialog-large",
};

export interface IModalProps extends BPDialogProps {
  title: string;
  description?: Maybe<string>;
  footerActions?: Maybe<React.ReactNode[]>;
  large?: Maybe<boolean>;
}

function getClassNamesFromProps(props: IModalProps) {
  return classnames(props.className, DialogClasses.DIALOG, {
    [DialogClasses.LARGE]: props.large,
  });
}

export const Modal: React.FC<IModalProps> = ({ children, ...props }) => {
  const { title, description, footerActions, onClose } = props;

  return (
    <StyledBPDialog
      {...props}
      // Don't have Blueprint render the title as well
      title={null}
      className={getClassNamesFromProps(props)}
    >
      <CardTitleRow
        className={DialogClasses.HEADER}
        title={title}
        description={description}
        rightElements={[
          <IconButton
            key="close-button"
            icon={IconNames.CLOSE}
            onClick={onClose}
            small
          />,
        ]}
      />
      <div className={DialogClasses.BODY}>{children}</div>
      {applyIfSome(footerActions, actions => (
        <div className={DialogClasses.FOOTER}>
          <SpacedList>{actions}</SpacedList>
        </div>
      ))}
    </StyledBPDialog>
  );
};

const StyledBPDialog = styled(BPDialog)`
  &.${DialogClasses.DIALOG} {
    width: 480px;
    padding-bottom: 0px;
    background: ${BASE_PALETTE.VAPE};
    border-radius: 4px;
  }

  &.${DialogClasses.LARGE} {
    width: 640px;
  }

  .${DialogClasses.HEADER} {
    border-bottom: 1px solid ${BASE_PALETTE.WIND};
  }

  .${DialogClasses.BODY} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
  }

  .${DialogClasses.FOOTER} {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 24px;

    background: ${BASE_PALETTE.SNOW};
    border-top: 1px solid ${BASE_PALETTE.WIND};
    border-radius: 0px 0px 4px 4px;
  }
`;
