/**
 * Here's where you add a feature description for a feature you've added
 * to enums.graphql.
 * Unless you are adding a beta feature, you can just use the
 * identifiers for the generic feature descriptions.
 *
 * ex:
 *
 * export const MY_NEW_FEATURE: IFeatureDescription = {
 *   identifier: "my-feature-identifier",
 *   description: "A useful description for /query (some day)"
 * }
 */

import { Feature } from "../base/types/gen";

export enum FeatureKind {
  // BETA features are in a pre-release state. They're useful for opting in a
  // select group of domains for closely-supported QA.
  Beta,
  // DEPRECATION features are granted to all current domains at once. They're
  // useful for preventing all future domains from accessing a feature.
  Deprecation,
  // SOC 2 DOMAINS represent SOC 2 Trust Services Criteria. These are major
  // compliance commitments, which may involve additional tests and policies.
  SOC2Domain,
}

interface IFeatureDescription {
  identifier: string;
  description: string;
  kind: FeatureKind;
}

export const FeatureDescriptions: { [k in Feature]: IFeatureDescription } = {
  [Feature.AuditJourneyAuditor]: {
    identifier: "audit-journey-auditor",
    description: "Auditor facing audit journey changes",
    kind: FeatureKind.Beta,
  },
  [Feature.AuditJourneyCustomer]: {
    identifier: "audit-journey-customer",
    description: "Customer facing audit journey changes",
    kind: FeatureKind.Beta,
  },
  [Feature.AuditorStandardExport]: {
    identifier: "auditor-standard-export",
    description: "Access for an auditor to export an entire standard as a zip",
    kind: FeatureKind.Beta,
  },
  [Feature.AutoEmployeeEmails]: {
    identifier: "auto-employee-emails",
    description: "Beta for automatic employee email reminders",
    kind: FeatureKind.Beta,
  },
  [Feature.BetaBSIPolicies]: {
    identifier: "beta-bsi-policies",
    description: "Beta access to BSI template based policies",
    kind: FeatureKind.Beta,
  },
  [Feature.BetaHIPAA]: {
    identifier: "beta-hipaa",
    description:
      "Features related to HIPAA support (deprecated in favor of standard selection)",
    kind: FeatureKind.Deprecation,
  },
  [Feature.BetaKnowBe4]: {
    identifier: "beta-knowbe4",
    description: "Beta access to KnowBe4 on the connections page",
    kind: FeatureKind.Beta,
  },
  [Feature.BetaNewRiskRegister]: {
    identifier: "new-risk-register",
    description: "Risk register v2",
    kind: FeatureKind.Beta,
  },
  [Feature.BetaRecurringSAT]: {
    identifier: "recurring-sat",
    description: "Beta access to recurring security trainings",
    kind: FeatureKind.Beta,
  },
  [Feature.BetaAirtable]: {
    identifier: "airtable",
    description: "Access to deprecated Airtable task tracker connection",
    kind: FeatureKind.Deprecation,
  },
  [Feature.BetaVOERecurrence]: {
    identifier: "beta-voe-recurrence",
    description: "Features related to VOE recurrence",
    kind: FeatureKind.Beta,
  },
  [Feature.DisableAzureDevOpsTasks]: {
    identifier: "disable-azuredevops-tasks",
    description:
      "Disables AzureDevOps issue fetches. For preventing test flips for customers who had linked AzureDevOps before the task integration.",
    kind: FeatureKind.Beta,
  },
  [Feature.DisableGitLabTasks]: {
    identifier: "disable-gitlab-tasks",
    description:
      "Disables GitLab issue fetches and displays a message to this effect in the connections page. For preventing test flips for customers who had linked GitLab before the task integration.",
    kind: FeatureKind.Beta,
  },
  [Feature.EmployeeGroupsOnPoliciesPage]: {
    identifier: "employee-groups-on-policies-page",
    description:
      "Employee progress and groups to accept are listed on the policies page.",
    kind: FeatureKind.Beta,
  },
  [Feature.GDPRStandard]: {
    identifier: "gdpr-standard",
    description: "Features related to GDPR support",
    kind: FeatureKind.Deprecation,
  },
  [Feature.ISOStandard]: {
    identifier: "iso-27001-standard",
    description: "Features related to ISO 27001 support",
    kind: FeatureKind.Deprecation,
  },
  [Feature.LegacyEnableServerAgent]: {
    description: "Access to deprecated server agent",
    identifier: "legacy-enable-server-agent",
    kind: FeatureKind.Deprecation,
  },
  [Feature.LocationServicesColumnDeprecated]: {
    description:
      "Access to deprecated Location Services column on the computers page",
    identifier: "location-services-column-deprecated",
    kind: FeatureKind.Deprecation,
  },
  [Feature.PCISaqAStandard]: {
    identifier: "pci-dss-saq-a-standard",
    description: "Features related to PCI DSS support",
    kind: FeatureKind.Deprecation,
  },
  [Feature.PCISaqAEPStandard]: {
    identifier: "pci-dss-saq-a-ep-standard",
    description: "Features related to PCI DSS support",
    kind: FeatureKind.Deprecation,
  },
  [Feature.PCISaqDMerchantStandard]: {
    identifier: "pci-dss-saq-d-merchant-standard",
    description: "Features related to PCI DSS support",
    kind: FeatureKind.Deprecation,
  },
  [Feature.PCISaqDSPStandard]: {
    identifier: "pci-dss-d-sp-standard",
    description: "Features related to PCI DSS support",
    kind: FeatureKind.Deprecation,
  },
  [Feature.OfficeUserSuspendedDeprecated]: {
    description:
      "If enabled, then users backed by disabled Office 365 resources will NOT be marked as suspended. For legacy users who linked Office 365 prior to this functionality.",
    identifier: "office-user-suspended-deprecated",
    kind: FeatureKind.Beta,
  },
  [Feature.OktaAllowIncompleteMetadata]: {
    description:
      "If enabled, treat Okta creds as complete even without app metadata. For legacy users who didn't complete the app creation step before we starting treating that as incomplete credential.",
    identifier: "okta-allow-incomplete-metadata",
    kind: FeatureKind.Beta,
  },
  [Feature.RiskRegisterV3]: {
    description:
      "Access to v3 of the risk register (aka risk assessment). Overrides risk register v2 if that feature is also enabled.",
    identifier: "risk-register-v-3",
    kind: FeatureKind.Beta,
  },
  [Feature.Soc2Availability]: {
    identifier: "soc2-domain-availability",
    description: "Pursuing the Availability SOC 2 domain",
    kind: FeatureKind.SOC2Domain,
  },
  [Feature.Soc2Confidentiality]: {
    identifier: "soc2-domain-confidentiality",
    description: "Pursuing the Confidentiality SOC 2 domain",
    kind: FeatureKind.SOC2Domain,
  },
};

export const FeatureIdentifierToFeatureEnum = Object.entries(
  FeatureDescriptions
).reduce((obj, [feature, description]) => {
  obj[description.identifier] = feature as Feature;
  return obj;
}, {} as { [k: string]: Feature });
