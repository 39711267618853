import React from "react";

import { DefaultLink } from "../../../../helpers/links";
import { SERVICE_DETAILS } from "../../../credentials/service-groups";
import { SimpleCredentialFormDialogBody } from "../simple-credential-form-dialog-body";
import type { IFormCredentialProps } from "./common-interface";

const AirtableBaseUrlImage = require("url:../../../../static/images/services/airtable-base-url-example.png");

export const Airtable: React.FunctionComponent<IFormCredentialProps> = ({
  onCredentialsLinked,
}) => {
  const apiUrlHelper = (
    <React.Fragment>
      <span>
        You can find this on the{" "}
        <DefaultLink href="https://airtable.com/api">
          documentation page
        </DefaultLink>{" "}
        for your Airtable base: click into which base you'd like, and copy the
        URL from the "Authentication" section.
      </span>
      <img src={AirtableBaseUrlImage} className="credentials-example-image" />
    </React.Fragment>
  );
  const apiKeyHelper = (
    <span>
      Find your API key in your Airtable{" "}
      <DefaultLink href="https://support.airtable.com/hc/en-us/articles/219046777-How-do-I-get-my-API-key-">
        account
      </DefaultLink>
      .
    </span>
  );
  return (
    <SimpleCredentialFormDialogBody
      labels={[
        {
          name: "apiUrl",
          displayName: "Airtable Base API URL",
          helperText: apiUrlHelper,
        },
        { name: "apiKey", displayName: "API key", helperText: apiKeyHelper },
      ]}
      service={SERVICE_DETAILS.airtable}
      onCredentialsLinked={onCredentialsLinked}
    />
  );
};
