// https://docs.heap.io/reference

import {
  FeatureDescriptions,
  FeatureKind,
} from "common/features/featureDescriptions";

import { LogError, LogErrorMessage } from "./errors";

declare global {
  interface Window {
    heap: any;
  }
}

/**
 * Identify a user for heap with vanta properties
 * @param userId - user's userId
 * @param userEmail - user's email
 * @param domainId - user's domainId
 * @param domainName - user's domain's name
 * @param userType - "auditor", "customer", or "employee" - "customer" is an admin Vanta user whereas employee is a non-admin
 * @param betaFeatures - set of beta features this user has available
 */
export function identify(
  userId: string,
  userEmail: string,
  domainId: string,
  domainName: string,
  userType: "auditor" | "customer" | "employee",
  betaFeatures: string[]
) {
  try {
    const featureToHeapProperty = (featureId: string) =>
      `beta-feature-${featureId}`;
    const featuresAsProps: { [k: string]: boolean } = {};
    // only include beta features from our feature descriptions list
    Object.entries(FeatureDescriptions)
      .filter(([_, value]) => value.kind === FeatureKind.Beta)
      .forEach(
        ([feature]) => (featuresAsProps[featureToHeapProperty(feature)] = false)
      );
    betaFeatures.forEach(
      featureId => (featuresAsProps[featureToHeapProperty(featureId)] = true)
    );
    window.heap.identify(userId);
    /*
      These properties are associate over all events for a user
    */
    window.heap.addUserProperties({
      userEmail,
      userId,
      domainId,
      domainName,
      userType,
    });
    /*
      These properties are associated only with subsequent events
      We will add event properties where for each beta feature like:
      ...
      beta-feature-BetaAzure: true,
      beta-feature-BetaAirtable: false,
      ...
    */
    window.heap.addEventProperties(featuresAsProps);
  } catch (e) {
    LogErrorMessage("Error identifying user for heap analytics");
    LogError(e, false);
  }
}
