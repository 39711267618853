import { Intent } from "@blueprintjs/core";
import React from "react";

import { LogError } from "../../../../errors";
import type { CheckSuspendedOrRemovedQuery } from "../../../../gen/components";
import {
  CheckSuspendedOrRemovedDocument,
  useRemoveUserMutation,
} from "../../../../gen/components";
import { CancelConfirmDialog } from "../../../helpers/CancelConfirmDialog";

interface IProps {
  user: NonNullable<CheckSuspendedOrRemovedQuery["user"]>;
  isOpen: boolean;
  onClose(): void;
}

export const ConfirmOffboardUserDialog: React.FC<IProps> = ({
  user,
  isOpen,
  onClose,
}) => {
  const userId = user.id;
  const [removeUser] = useRemoveUserMutation({
    refetchQueries: [
      {
        query: CheckSuspendedOrRemovedDocument,
        variables: { userId },
      },
    ],
  });

  return (
    <CancelConfirmDialog
      isOpen={isOpen}
      body={<div>{user.displayName} will be permanently offboarded.</div>}
      title={`Offboard ${user.displayName}`}
      confirmText={"Offboard"}
      confirmIntent={Intent.DANGER}
      onClose={() => onClose()}
      onConfirm={() => {
        removeUser({
          variables: { userId },
        }).catch(LogError);
        onClose();
      }}
    />
  );
};
