import type { HerokuApp_Addons } from "common/base/types/gen";
import { isSome } from "common/base/types/maybe";
import {
  isHerokuDb,
  isHerokuDbBackedUp,
  isHerokuDbEncrypted,
} from "common/resources/specific-resources/heroku/db-helpers";

import { LogErrorMessage } from "../../../../errors";
import type { FetchHerokuAddOnsQuery } from "../../../../gen/components";
import { getHerokuAppNameAndAddons } from "../../../test-entities/test-entity-renderers/heroku-db";
import type { TableParameters } from "../helpers";
import { booleanToEvaluationIcon } from "../helpers";
import { SHARED_DATABASE_TABLE_HEADERS } from "./helpers";

export const HEROKU_DBS = ["herokuDBs"] as const;

export const herokuDatatableParametrs: { [k: string]: TableParameters } = {
  herokuDBs: {
    displayName: "Heroku Databases",
    columnOrder: ["appName", "name", "encrypted", "backupsEnabled"],
    header: {
      appName: "App Name",
      name: SHARED_DATABASE_TABLE_HEADERS.name,
      encrypted: SHARED_DATABASE_TABLE_HEADERS.encrypted,
      backupsEnabled: SHARED_DATABASE_TABLE_HEADERS.backups,
    },
    createRow: (db: { addon: HerokuApp_Addons; name: string }) => {
      return {
        appName: db.name,
        name: db.addon.name,
        encrypted: booleanToEvaluationIcon(isHerokuDbEncrypted(db.addon)),
        backupsEnabled: booleanToEvaluationIcon(isHerokuDbBackedUp(db.addon)),
      };
    },
  },
};

function isHerokuData(data: any): data is FetchHerokuAddOnsQuery {
  return isSome(data?.organization.herokuDBs);
}

export function getHerokuDbInfo(data: any) {
  if (!isHerokuData(data)) {
    LogErrorMessage("Non-Heroku data passed to Heroku data filter function");
    return null;
  }
  return getHerokuAppNameAndAddons(data)?.flatMap(nameAndAddon => {
    const filteredAddons = nameAndAddon.addons.filter(isHerokuDb);
    return filteredAddons.map(addon => {
      return {
        addon,
        name: nameAndAddon.name,
      };
    });
  });
}
