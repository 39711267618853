import { InputGroup, Spinner } from "@blueprintjs/core";
import { toPossibleDate } from "common/base/dateUtils";
import type { SortParams } from "common/base/types/gen";
import { isSome, nothing } from "common/base/types/maybe";
import {
  SERVER_VULNERABILITY_SORT_PARAMS_BY_TYPE,
  ServerVulnerabilitySortType,
} from "common/vulnerabilities/vulnerability-sort";
import gql from "graphql-tag";
import React, { useState } from "react";
import { useHistory } from "react-router";

import { LogError } from "../../../../errors";
import { useFetchEc2InstancesQuery } from "../../../../gen/components";
import type { PaginationParams } from "../../../helpers/generic-paginator";
import {
  DEFAULT_ITEMS_PER_PAGE_OPTION,
  applyPaginationParams,
  GenericPaginator,
  getInitialPaginationParams,
} from "../../../helpers/generic-paginator";
import {
  ControlLabel,
  ControlsTopPanel,
  EntryListContainer,
  LeftControls,
  SortFilterButton,
  TableContainer,
} from "../common/components";
import { getDebounceContext, getFilterParams } from "../utils";
import { VulnSearchResultSummary } from "../vuln-search-result-summary";
import { getInspectorRunStatus } from "./common";
import { makeInspectorInstanceSearchParam } from "./constants";
import { InstanceEntry } from "./instance-entry";
import { VulnTableEmptyState } from "./vuln-table-empty-state";

interface IProps {
  numUnmonitoredInstances: number;
}

export const AWSInspectorMonitoredServerList: React.FC<IProps> = ({
  numUnmonitoredInstances,
}) => {
  const history = useHistory();
  const [paginationParams, setPaginationParams] = useState<PaginationParams>(
    getInitialPaginationParams()
  );

  const [searchString, setSearchString] = useState<string>("");
  const [sortParams, setSortParams] = useState<SortParams>(
    SERVER_VULNERABILITY_SORT_PARAMS_BY_TYPE[
      ServerVulnerabilitySortType.DUE_DATE
    ]
  );

  const { error, loading, data, fetchMore } = useFetchEc2InstancesQuery({
    variables: {
      first: DEFAULT_ITEMS_PER_PAGE_OPTION,
      sortParams,
      filterParams: getFilterParams(searchString, ["name", "instanceId"]),
    },
    context: getDebounceContext("aws-inspector-instance-list"),
  });

  if (error) {
    LogError(error);
    return <div />;
  }

  const nodes = applyPaginationParams(
    data?.organization?.ec2VulnTargets?.edges.map(e => e.node) ?? [],
    paginationParams
  );

  const numTotal = data?.organization?.ec2VulnTargets?.totalCount ?? 0;
  const numMonitored = numTotal - numUnmonitoredInstances;

  const nodesWithVulnerabilities = nodes.filter(
    n => isSome(n) && (n.vulnSummary?.totalVulnCount ?? 0) > 0
  );
  const cleanNodes = nodes.filter(
    n => isSome(n) && (n.vulnSummary?.totalVulnCount ?? 0) === 0
  );

  const pageInfo = data?.organization?.ec2VulnTargets.pageInfo;

  return (
    <TableContainer>
      <ControlsTopPanel>
        <LeftControls>
          <InputGroup
            type="search"
            value={searchString}
            onChange={e => setSearchString(e.target.value)}
            placeholder="Search by name"
          />
          {/* // TODO: reenable view by buttons <AwsInspectorViewByButtons /> */}
          <ControlLabel>Sort by:</ControlLabel>
          <SortFilterButton
            key="minSlaDeadline"
            secondary={
              sortParams ===
              SERVER_VULNERABILITY_SORT_PARAMS_BY_TYPE[
                ServerVulnerabilitySortType.DUE_DATE
              ]
            }
            onClick={() => {
              setSortParams(
                SERVER_VULNERABILITY_SORT_PARAMS_BY_TYPE[
                  ServerVulnerabilitySortType.DUE_DATE
                ]
              );
              setPaginationParams(getInitialPaginationParams());
            }}
          >
            Due date
          </SortFilterButton>
          <SortFilterButton
            key="totalVulnCount"
            secondary={
              sortParams ===
              SERVER_VULNERABILITY_SORT_PARAMS_BY_TYPE[
                ServerVulnerabilitySortType.MOST_ISSUES
              ]
            }
            onClick={() => {
              setSortParams(
                SERVER_VULNERABILITY_SORT_PARAMS_BY_TYPE[
                  ServerVulnerabilitySortType.MOST_ISSUES
                ]
              );
              setPaginationParams(getInitialPaginationParams());
            }}
          >
            Most Issues
          </SortFilterButton>
        </LeftControls>

        <GenericPaginator
          pageInfo={pageInfo}
          itemsLoaded={nodes.length}
          totalItems={numTotal}
          paginationParams={paginationParams}
          setPaginationParams={setPaginationParams}
          paginationId={"paginator-aws-inspector-monitored-instances"}
          fetchMore={fetchMore}
        />
      </ControlsTopPanel>

      {loading || paginationParams.loading ? (
        <Spinner />
      ) : nodes.length === 0 && searchString === "" ? (
        <VulnTableEmptyState numMonitored={numMonitored} />
      ) : (
        <>
          <VulnSearchResultSummary
            searchString={searchString}
            numberResults={nodes.length}
          />
          <EntryListContainer>
            {[...nodesWithVulnerabilities, ...cleanNodes].map(node => {
              const nodeVulnSummary = node.vulnSummary;
              const ec2Resource = node.resource;
              if (ec2Resource.__typename !== "SpecificEC2InstanceResource") {
                return nothing;
              }

              const inspectorStatus = getInspectorRunStatus(
                ec2Resource.inspector?.latestRun?.assessmentStartDate
              );

              const lastAssessmentRanDate =
                ec2Resource.inspector?.latestRun?.assessmentStartDate ??
                nothing;

              const numHighSeverity = nodeVulnSummary.totalHighSeverity ?? 0;
              const numMidSeverity = nodeVulnSummary.totalMidSeverity ?? 0;
              const numLowSeverity = nodeVulnSummary.totalLowSeverity ?? 0;
              const minSlaDeadline = nodeVulnSummary.minSlaDeadline;

              return (
                <InstanceEntry
                  key={ec2Resource.id}
                  primaryId={ec2Resource.instanceId}
                  secondaryId={ec2Resource.name}
                  lastAssessmentRanDateString={lastAssessmentRanDate}
                  slaDeadline={toPossibleDate(minSlaDeadline)}
                  vulnerabilityCounts={{
                    low: numLowSeverity,
                    medium: numMidSeverity,
                    high: numHighSeverity,
                  }}
                  inspectorStatus={inspectorStatus}
                  handleClick={() =>
                    history.push({
                      search: makeInspectorInstanceSearchParam(ec2Resource.id),
                    })
                  }
                />
              );
            })}
          </EntryListContainer>
        </>
      )}
    </TableContainer>
  );
};

gql`
  query fetchEC2Instances(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $sortParams: sortParams
    $filterParams: filterParams
  ) {
    organization {
      id
      ec2VulnTargets: vulnerabilityTargets(
        specificResourceType: EC2Instance
        after: $after
        before: $before
        first: $first
        last: $last
        sortParams: $sortParams
        filterParams: $filterParams
      ) {
        totalCount
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            resource {
              id
              ... on SpecificEC2InstanceResource {
                id
                name
                instanceId
                inspector {
                  latestRun {
                    assessmentArn
                    assessmentStartDate
                  }
                }
              }
            }
            vulnSummary {
              totalVulnCount
              totalLowSeverity
              totalMidSeverity
              totalHighSeverity
              minSlaDeadline
            }
          }
        }
      }
    }
  }
`;
