import { isSome } from "common/base/types/maybe";
import React, { useContext, useMemo } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { FeatureContext } from "../../../helpers/feature-gating/context";
import Logo from "../../../static/images/logo/logo.svg";
import { UserContext } from "../../pages/user-context";
import { Dimensions } from "../constants";
import { AuditorLinks, NavLinks } from "../links";
import { NavSubMenu } from "./nav-submenu";

interface IProps {
  onMouseEnter?(e: React.MouseEvent): void;
}

export const MainNavMenu: React.FC<IProps> = ({
  onMouseEnter = () => {
    return {};
  },
}) => {
  const { user, domainStandards } = useContext(UserContext);
  const featureContext = useContext(FeatureContext);
  const navLinks = useMemo(() => {
    if (isSome(user?.auditorInfo)) {
      return AuditorLinks.filter(menu =>
        Boolean(user?.isAuditorAssumingUser)
          ? menu.auditorOnly
          : !menu.auditorOnly
      );
    } else {
      return NavLinks({
        featureContext,
        domainStandards: domainStandards ?? [],
      }).filter(menu => !menu.auditorOnly || user?.isAuditorAssumingUser);
    }
  }, [user]);

  const clickableVantaLogo = (
    <LogoLi>
      <Link to="/">
        <Logo />
      </Link>
    </LogoLi>
  );

  const subMenus = navLinks.map(menu => (
    <NavSubMenu menuOption={menu} key={`nav-menu-${menu.link.text}`} />
  ));

  return (
    <NAV onMouseEnter={onMouseEnter}>
      <ul>
        {clickableVantaLogo}
        {subMenus}
      </ul>
    </NAV>
  );
};

const LogoLi = styled.li`
  && {
    display: flex;
    align-items: center;
    margin-right: ${Dimensions.NAVBAR_SUBMENU_INDENT}px;
    &:hover {
      cursor: pointer;
    }
  }
  svg,
  img {
    height: ${Dimensions.TOP_BAR_LOGO_HEIGHT}px;
  }
`;

const NAV = styled.nav`
  display: flex;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 0.4;
  a {
    text-decoration: none;
  }

  & > ul {
    padding: 0;
    display: flex;
    flex-flow: row nowrap;
  }

  & > ul > li {
    display: block;
    height: ${Dimensions.TOP_BAR_HEIGHT}px;
  }
  ul {
    list-style: none;
  }
  li {
    text-decoration: none;
  }
`;
