import type { IHTMLSelectProps } from "@blueprintjs/core";
import { HTMLSelect } from "@blueprintjs/core";
import { isSome } from "common/base/types/maybe";
import React, { useEffect, useRef, useState } from "react";

import { StyledInputGroupDeprecated } from "../../alpaca/base/deprecated";

type SelectItem = { id: string; label: string };

interface IProps {
  choices: string[] | SelectItem[];
  onNewValue(value: string): void;
}

const OTHER_ID = "__internal_other";
const OTHER_LABEL = "Other";

export const HTMLSelectWithEditableOther: React.FC<IHTMLSelectProps & IProps> =
  props => {
    const { choices, onNewValue, ...selectProps } = props;

    const isString = choices.length > 0 && typeof choices[0] === "string";
    const selectOptions: SelectItem[] = isString
      ? (choices as string[]).map(c => {
          return { id: c, label: c };
        })
      : [...(choices as SelectItem[])];

    const isOtherValue =
      selectOptions.every(option => option.label !== props.value) &&
      props.value !== "internal-unselected";

    selectOptions.push({ id: OTHER_ID, label: OTHER_LABEL });

    const [inputValue, setInputValue] = useState(`${props.value}`);
    useEffect(() => {
      if (isOtherValue) {
        setInputValue(`${props.value}`);
      }
    }, [props.value]);

    const inputRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
      if (isOtherValue && isSome(inputRef.current) && props.value === "") {
        inputRef.current.focus();
      }
    }, [isOtherValue]);

    return (
      <React.Fragment>
        <HTMLSelect
          {...selectProps}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            if (e.target.value === OTHER_ID) {
              onNewValue("");
            } else {
              onNewValue(e.target.value);
            }
          }}
          value={isOtherValue ? OTHER_ID : props.value}
        >
          <option value="internal-unselected">Choose</option>
          {selectOptions.map((choice, i) => (
            <option key={i} value={choice.id}>
              {choice.label}
            </option>
          ))}
        </HTMLSelect>
        {isOtherValue ? (
          <StyledInputGroupDeprecated
            // Blueprint is typed to take the inputRef as a callback,
            // but this is just passed to the underlying <input> element,
            // and so the hook ref will work.
            inputRef={inputRef as any}
            dir="auto"
            type="text"
            value={inputValue}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setInputValue(e.target.value)
            }
            onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
              onNewValue(e.target.value)
            }
          />
        ) : null}
      </React.Fragment>
    );
  };
