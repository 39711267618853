import { Button, Callout, Classes, FormGroup, Intent } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { isSome, nothing } from "common/base/types/maybe";
import React, { useEffect, useState } from "react";

import { StyledInputGroupDeprecated } from "../../../../alpaca/base/deprecated";
import { LogError } from "../../../../errors";
import type { TestCredentialsResult } from "../../../../gen/components";
import {
  AllLinkedCredentialsDocument,
  useSetCredentialsMutation,
  useTestCredentialsMutation,
} from "../../../../gen/components";
import { AppToaster } from "../../../../helpers/toaster";
import type { IFormCredentialProps } from "./common-interface";

/** JAMF_CLOUD_DOMAIN_FORMAT matches `subdomain.jamfcloud.com`. */
const JAMF_CLOUD_DOMAIN_FORMAT = /.+\.jamfcloud\.com$/;

/**
 * useTrimmedString is a custom React hook for adding a nullable string to the
 * calling component state. The returned setValue function trims the new state
 * value before setting it.
 *
 * @param initial - the initial value for the state.
 */
const useTrimmedString = (
  initial: Maybe<string>
): [Maybe<string>, (newValue: Maybe<string>) => void] => {
  const [value, _setValue] = useState(initial);
  const setValue = (newValue: Maybe<string>) => _setValue(newValue?.trim());
  return [value, setValue];
};

export const Jamf: React.FC<IFormCredentialProps> = ({
  domain,
  credential,
  onCredentialsLinked,
}) => {
  const storedMetadata = isSome(credential?.metadata)
    ? JSON.parse(credential!.metadata)
    : {};

  const [username, setUsername] = useTrimmedString(storedMetadata.username);
  const [password, setPassword] = useTrimmedString(storedMetadata.password);
  const [jamfCloudSubdomain, setJamfCloudSubdomain] = useTrimmedString(
    storedMetadata.jamfCloudSubdomain
  );
  const isJamfCloudSubdomainMisformatted =
    isSome(jamfCloudSubdomain) &&
    !JAMF_CLOUD_DOMAIN_FORMAT.test(jamfCloudSubdomain);

  const [validationOutcome, setValidationOutcome] =
    React.useState<Maybe<TestCredentialsResult>>(nothing);
  const [saveCredentials] = useSetCredentialsMutation({
    variables: {
      service: "jamf",
      credentials: JSON.stringify({
        jamfCloudSubdomain,
        username,
        password,
      }),
    },
    onCompleted: onCredentialsLinked,
    refetchQueries: [{ query: AllLinkedCredentialsDocument }],
  });
  useEffect(() => {
    if (validationOutcome?.success) {
      AppToaster.show({
        intent: Intent.SUCCESS,
        message: "Jamf credentials linked and validated",
      });
      saveCredentials().catch(LogError);
    }
  }, [validationOutcome]);

  return (
    <div>
      <div className={Classes.DIALOG_HEADER}>Link Jamf Pro</div>
      <div className={Classes.DIALOG_BODY}>
        <JamfCredentialValidationCallout outcome={validationOutcome} />
        <p>
          Vanta uses Jamf credentials you manage to fetch data about your Jamf
          configuration. We recommend creating a dedicated read-only Jamf user
          for this purpose.
        </p>
        <ol>
          <li>Visit your Jamf Pro User Accounts & Groups dashboard.</li>
          <li>
            Create a new user with the <code>Auditor</code> privilege set. If
            you have the option to select access level, select{" "}
            <code>Full Access</code>.
          </li>
          <li>Enter the username and password for that user below.</li>
        </ol>
        <FormGroup
          label={"Jamf Cloud subdomain"}
          helperText={"Of the form yourdomain.jamfcloud.com"}
          intent={isJamfCloudSubdomainMisformatted ? Intent.DANGER : undefined}
        >
          <StyledInputGroupDeprecated
            placeholder={"yourdomain.jamfcloud.com"}
            value={jamfCloudSubdomain ?? ""}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setJamfCloudSubdomain(e.target.value)
            }
            intent={
              isJamfCloudSubdomainMisformatted ? Intent.DANGER : undefined
            }
          />
        </FormGroup>
        <FormGroup label={"Jamf account username"}>
          <StyledInputGroupDeprecated
            value={username ?? ""}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setUsername(e.target.value)
            }
          />
        </FormGroup>
        <FormGroup label={"Jamf account password"}>
          <StyledInputGroupDeprecated
            type={"password"} // Hide the input.
            value={password ?? ""}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setPassword(e.target.value)
            }
          />
        </FormGroup>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <ValidateJamfButton
            domainId={domain.id}
            jamfCloudSubdomain={jamfCloudSubdomain}
            username={username}
            password={password}
            handleOutcome={setValidationOutcome}
          />
        </div>
      </div>
    </div>
  );
};

const ValidateJamfButton: React.FC<{
  domainId: string;
  jamfCloudSubdomain: Maybe<string>;
  username: Maybe<string>;
  password: Maybe<string>;
  handleOutcome: (outcome: TestCredentialsResult) => void;
}> = ({
  domainId,
  jamfCloudSubdomain,
  username,
  password,
  handleOutcome: handleResult,
}) => {
  const [testCredentials, { data, loading }] = useTestCredentialsMutation({
    onError: LogError,
    fetchPolicy: "no-cache",
  });

  if (isSome(data)) {
    handleResult(data.testCredentials);
  }

  const isSet = (value: Maybe<string>): value is string =>
    isSome(value) && value.trim().length !== 0;

  return (
    <Button
      onClick={async () =>
        testCredentials({
          variables: {
            domainId,
            credentials: JSON.stringify({
              jamfCloudSubdomain,
              username,
              password,
            }),
            service: "jamf",
          },
        })
      }
      loading={loading}
      intent={Intent.PRIMARY}
      disabled={
        !isSet(jamfCloudSubdomain) || !isSet(username) || !isSet(password)
      }
    >
      Validate credentials
    </Button>
  );
};

const JamfCredentialValidationCallout: React.FC<{
  outcome: Maybe<TestCredentialsResult>;
}> = ({ outcome }) => {
  if (!isSome(outcome)) {
    return null;
  }
  return outcome.success ? (
    <Callout
      intent={Intent.SUCCESS}
      title={"These credentials are configured correctly"}
    />
  ) : (
    <Callout intent={Intent.DANGER} title={"Credential validation failed"}>
      {outcome.message}
    </Callout>
  );
};
