import type { IconName } from "@blueprintjs/core";
import { Card, Elevation, NonIdealState } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import qs from "qs";
import React from "react";
import type { RouteComponentProps } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { isUUID } from "validator";

import { LogError } from "../../errors";
import { useIsRegisteredQuery } from "../../gen/components";
import { FullPageSpinner } from "../helpers/FullPageSpinner";
import { UncontrolledDialogButton } from "../helpers/uncontrolled-dialog-button";
import { AMISecure } from "../pages/docs/AmISecure";
import { AboutInstructions } from "../pages/download";
import { NotFound404 } from "../pages/notfound404";

gql`
  query isRegistered($hostIdentifier: String!) {
    isRegistered(hostIdentifier: $hostIdentifier)
  }
`;

/**
 * Agent info page. For now, only reports if an agent is registered, and
 * so is open to the world, since that information is available anyway if
 * the UUID is known. In the future, will require login.
 */
const Component: React.FunctionComponent<RouteComponentProps> = ({
  location,
}) => {
  const params: { uuid?: Maybe<string> } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const { loading, error, data } = useIsRegisteredQuery({
    variables: { hostIdentifier: params.uuid ?? "" },
  });

  if (!("uuid" in params) || !isSome(params.uuid) || !isUUID(params.uuid)) {
    LogError(
      new Error("Attempt made to get info of invalid identifier"),
      false
    );
    return <NotFound404 />;
  }
  if (loading) {
    return <FullPageSpinner />;
  }
  if (error) {
    LogError(error);
    return <div />;
  }

  let icon: IconName = "cross";
  let title = "Not registered";
  if (data?.isRegistered) {
    icon = "tick";
    title = "Registered!";
  }

  return (
    <div>
      <Card
        elevation={Elevation.TWO}
        style={{
          margin: 100,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div style={{ width: "10%", margin: 20 }}>
          <NonIdealState icon={icon} title={title} />
        </div>
        <div style={{ width: "80%" }}>
          : <AboutInstructions />
          <UncontrolledDialogButton
            buttonText={"Security"}
            dialogContent={<AMISecure concern="osquery" />}
            dialogHeader={"Is the Vanta Agent secure?"}
            icon={"lock"}
          />
        </div>
      </Card>
    </div>
  );
};

export const AgentInfoPage = withRouter(Component);
