import { InputGroup, Spinner } from "@blueprintjs/core";
import { toPossibleDate } from "common/base/dateUtils";
import { nothing } from "common/base/types/maybe";
import gql from "graphql-tag";
import React, { useState } from "react";
import { useHistory } from "react-router";

import { LogError } from "../../../../errors";
import { useFetchEcrRepositoriesQuery } from "../../../../gen/components";
import type { PaginationParams } from "../../../helpers/generic-paginator";
import {
  DEFAULT_ITEMS_PER_PAGE_OPTION,
  applyPaginationParams,
  GenericPaginator,
  getInitialPaginationParams,
} from "../../../helpers/generic-paginator";
import {
  ControlsTopPanel,
  EntryListContainer,
  LeftControls,
  LoadingContainer,
} from "../common/components";
import { ContainerRepositoryEntry } from "../common/container-repository-entry";
import {
  SORT_PARAMS_BY_TYPE,
  VulnerabilityTargetSortControl,
  VulnerabilityTargetSortType,
} from "../common/vulnerability-target-sort-control";
import { getDebounceContext, getFilterParams } from "../utils";
import { VulnSearchResultSummary } from "../vuln-search-result-summary";
import { AWSContainerRepositoriesEmptyState } from "./aws-container-repositories-empty-state";

export const AWSContainerRepositoryList: React.FC = () => {
  const history = useHistory();
  const [sortType, setSortType] = useState(
    VulnerabilityTargetSortType.DUE_DATE
  );

  const [paginationParams, setPaginationParams] = useState<PaginationParams>(
    getInitialPaginationParams()
  );

  const [searchString, setSearchString] = useState<string>("");

  const { error, loading, data, fetchMore } = useFetchEcrRepositoriesQuery({
    variables: {
      first: DEFAULT_ITEMS_PER_PAGE_OPTION,
      sortParams: SORT_PARAMS_BY_TYPE[sortType],
      filterParams: getFilterParams(searchString, ["name", "uniqueId"]),
    },
    context: getDebounceContext("aws-container-repository-list"),
  });

  const repositories = applyPaginationParams(
    data?.organization.vulnerabilityTargets.edges.map(e => e.node) ?? [],
    paginationParams
  );
  const pageInfo = data?.organization.vulnerabilityTargets.pageInfo;

  if (error) {
    LogError(error);
    return <div />;
  }

  return (
    <>
      <ControlsTopPanel>
        <LeftControls>
          <InputGroup
            type="search"
            value={searchString}
            onChange={e => setSearchString(e.target.value)}
            placeholder="Search by name"
          />
          <VulnerabilityTargetSortControl
            currentSortType={sortType}
            onNewSortType={newSortType => {
              setSortType(newSortType);
              setPaginationParams(getInitialPaginationParams());
            }}
          />
        </LeftControls>
        <GenericPaginator
          pageInfo={pageInfo}
          itemsLoaded={repositories.length}
          totalItems={data?.organization.vulnerabilityTargets?.totalCount ?? 0}
          paginationParams={paginationParams}
          setPaginationParams={setPaginationParams}
          paginationId={"paginator-aws-container-repos"}
          fetchMore={fetchMore}
        />
      </ControlsTopPanel>
      {loading || paginationParams.loading ? (
        <LoadingContainer>
          <Spinner />
        </LoadingContainer>
      ) : repositories.length === 0 && searchString === "" ? (
        <AWSContainerRepositoriesEmptyState />
      ) : (
        <>
          <VulnSearchResultSummary
            searchString={searchString}
            numberResults={repositories.length}
          />
          <EntryListContainer>
            {repositories.map(repository => {
              const { resource, vulnSummary } = repository;
              if (
                resource.__typename !== "SpecificECRContainerRepositoryResource"
              ) {
                return nothing;
              }

              return (
                <ContainerRepositoryEntry
                  key={resource.id}
                  primaryId={resource.name}
                  secondaryId={resource.uniqueId}
                  slaDeadline={toPossibleDate(vulnSummary.minSlaDeadline)}
                  hasBeenScanned={resource.hasBeenScanned}
                  vulnerabilityCounts={{
                    low: vulnSummary.totalLowSeverity ?? 0,
                    medium: vulnSummary.totalMidSeverity ?? 0,
                    high: vulnSummary.totalHighSeverity ?? 0,
                  }}
                  handleClick={() =>
                    history.push({
                      search: `?awsRepositoryId=${resource.id}`,
                    })
                  }
                />
              );
            })}
          </EntryListContainer>
        </>
      )}
    </>
  );
};

gql`
  query fetchECRRepositories(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $sortParams: sortParams
    $filterParams: filterParams
  ) {
    organization {
      id
      vulnerabilityTargets(
        specificResourceType: ECRContainerRepository
        after: $after
        before: $before
        first: $first
        last: $last
        sortParams: $sortParams
        filterParams: $filterParams
      ) {
        totalCount
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            resource {
              id
              uniqueId
              ... on SpecificECRContainerRepositoryResource {
                name
                hasBeenScanned
              }
            }
            vulnSummary {
              maxSeverity
              totalVulnCount
              totalLowSeverity
              totalMidSeverity
              totalHighSeverity
              minSlaDeadline
            }
          }
        }
      }
    }
  }
`;
