import { css } from "styled-components";

import { BASE_PALETTE } from "../../base/colors";
import { GRID_SPACING } from "../../base/grid";

export const FormClasses = {
  FORM_GROUP: "alpaca-form-group",
  INPUT_GROUP: "alpaca-input-group",
};

export const FORM_GROUP_STYLES = css`
  &.${FormClasses.FORM_GROUP} {
    width: 100%;

    label {
      font-size: 14px;
      line-height: 16px;
    }

    .bp3-form-content .bp3-form-helper-text {
      font-size: 12px;
    }

    &:not([class*="bp3-intent"]) {
      .bp3-form-content .bp3-form-helper-text {
        color: ${BASE_PALETTE.SMOKE};
      }
    }
  }
`;

export const INPUT_GROUP_STYLES = css`
  &.${FormClasses.INPUT_GROUP} {
    width: 100%;
    input {
      border: 1px solid ${BASE_PALETTE.FOG};
      border-radius: 4px;
      box-shadow: none;
      height: ${5 * GRID_SPACING}px;
      width: 100%;

      font-size: 12px;
      line-height: 16px;

      &:focus {
        border-color: ${BASE_PALETTE.INK};
      }

      &::-webkit-input-placeholder {
        color: ${BASE_PALETTE.SMOKE};
      }
    }
  }
`;
