import "./manage-network.scss";

import { Card } from "@blueprintjs/core";
import { dropNothing } from "common/base/types/maybe";
import gql from "graphql-tag";
import React from "react";

import { LogError, LogErrorMessage } from "../../../errors";
import type { FetchDomainInfoNetworkQuery } from "../../../gen/components";
import { useFetchDomainInfoNetworkQuery } from "../../../gen/components";
import { FullPageSpinner } from "../../helpers/FullPageSpinner";
import {
  PageHeadingInfo,
  VantaDashboardPage,
} from "../../vanta-chrome/page-content/vanta-dashboard-page";
import { DataTable } from "../components/data-table";
import {
  COLUMN_CLASSES_SECURITY_RULES,
  COLUMN_ORDER_SECURITY_RULES,
  getRowDataForSecurityRule,
  HEADERS_SECURITY_RULES,
} from "./security-rules";

interface IProps {
  domain: NonNullable<FetchDomainInfoNetworkQuery["organization"]>;
}

type vpc = NonNullable<
  NonNullable<
    NonNullable<
      NonNullable<
        FetchDomainInfoNetworkQuery["organization"]
      >["networkConfiguration"]
    >["vpcs"]
  >[number]
>;

class ManageNetwork extends React.Component<IProps> {
  public render() {
    const network = this.props.domain.networkConfiguration;
    const maybeNetworkConfiguration = network?.vpcs?.map(vpc =>
      this.renderVPC(vpc)
    ) ?? (
      <div>
        {this.props.domain.displayName} has not linked credentials for their
        hosting provider, network settings are not available
      </div>
    );

    return (
      <VantaDashboardPage headingInfo={PageHeadingInfo.NETWORK}>
        {maybeNetworkConfiguration}
      </VantaDashboardPage>
    );
  }

  private renderSecurityGroups(
    sgs: Array<
      | NonNullable<
          NonNullable<
            NonNullable<
              NonNullable<vpc["autoscaleGroups"]>[number]
            >["securityGroups"]
          >[number]
        >
      // FetchDomainInfoNetwork.SecurityGroups
      | NonNullable<
          NonNullable<
            NonNullable<NonNullable<vpc["instances"]>[number]>["securityGroups"]
          >[number]
        >
    >
  ) {
    const inbound: typeof sgs[number]["inboundRules"] = [];
    const outbound: typeof sgs[number]["outboundRules"] = [];

    sgs.forEach(sg =>
      dropNothing(sg.inboundRules).forEach(rule => inbound.push(rule))
    );
    sgs.forEach(sg =>
      dropNothing(sg.outboundRules).forEach(rule => outbound.push(rule))
    );

    const maybeInbound =
      inbound.length > 0 ? (
        <div>
          <h6>Inbound</h6>
          <DataTable
            columnClasses={COLUMN_CLASSES_SECURITY_RULES}
            columnOrder={COLUMN_ORDER_SECURITY_RULES}
            createRow={getRowDataForSecurityRule}
            data={inbound}
            header={HEADERS_SECURITY_RULES}
          />
        </div>
      ) : null;

    const maybeOutbound =
      outbound.length > 0 ? (
        <div>
          <h6>Outbound</h6>
          <DataTable
            columnClasses={COLUMN_CLASSES_SECURITY_RULES}
            columnOrder={COLUMN_ORDER_SECURITY_RULES}
            createRow={getRowDataForSecurityRule}
            data={outbound}
            header={HEADERS_SECURITY_RULES}
          />
        </div>
      ) : null;

    return (
      <div>
        {maybeInbound}
        {maybeOutbound}
      </div>
    );
  }

  private renderVPC(vpc: vpc) {
    const autoscaleList = vpc.autoscaleGroups ?? [];
    const instanceList = vpc.instances ?? [];
    if (autoscaleList.length === 0 && instanceList.length === 0) {
      return null;
    }

    const autoscale = dropNothing(autoscaleList).map(asg => (
      <Card key={asg.name} className="manage-network-card" elevation={1}>
        <h5>Autoscale Group {asg.name}</h5>
        {this.renderSecurityGroups(dropNothing(asg.securityGroups))}
      </Card>
    ));
    const instances = dropNothing(instanceList).map((inst, ix) => (
      <Card
        key={`${inst.name}_${ix}`}
        className="manage-network-card"
        elevation={1}
      >
        <h5>Instance {inst.name}</h5>
        {this.renderSecurityGroups(dropNothing(inst.securityGroups))}
      </Card>
    ));

    return (
      <div>
        <h3>{vpc.name}</h3>
        {autoscale}
        {instances}
      </div>
    );
  }
}

gql`
  query fetchDomainInfoNetwork {
    organization {
      id
      displayName
      networkConfiguration {
        vpcs {
          name
          autoscaleGroups {
            name
            securityGroups {
              inboundRules {
                fromPort
                ipRanges
                ipProtocol
                toPort
                securityGroupIds
              }
              name
              outboundRules {
                fromPort
                ipRanges
                ipProtocol
                toPort
                securityGroupIds
              }
            }
          }
          instances {
            name
            securityGroups {
              inboundRules {
                fromPort
                ipRanges
                ipProtocol
                toPort
                securityGroupIds
              }
              name
              outboundRules {
                fromPort
                ipRanges
                ipProtocol
                toPort
                securityGroupIds
              }
            }
          }
        }
      }
    }
  }
`;

export const ManageNetworkPage: React.FC = () => {
  const { error, loading, data } = useFetchDomainInfoNetworkQuery();
  if (error) {
    LogError(error);
    return null;
  }
  if (loading) {
    return <FullPageSpinner />;
  }
  if (!data) {
    LogErrorMessage("Bad fetch");
    return null;
  }
  return <ManageNetwork domain={data.organization} />;
};
