import type { Maybe } from "common/base/types/maybe";
import React from "react";
import { Redirect, Route } from "react-router";

import type { Feature } from "../../gen/components";
import { useFeatureCheck } from "./feature-check";

interface IProps {
  exact: Maybe<boolean>;
  path: string;
  component: React.ComponentType<any>;
  feature: Feature;
}

// ts-prune-ignore-next
export const GatedRoute: React.FunctionComponent<IProps> = props => {
  const { exact, path, feature, component } = props;
  const hasFeature = useFeatureCheck(feature);
  if (hasFeature) {
    return (
      <Route exact={exact ?? undefined} path={path} component={component} />
    );
  } else {
    return <Redirect to="/" />;
  }
};
