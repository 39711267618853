import React from "react";

import { BodyText } from "../../../../alpaca/components";
import { TabContainer, TabDescription } from "../common/components";
import { AzureContainerRepositoryList } from "./azure-container-repository-list";

export const AzureVulnDisplay: React.FC = () => (
  <>
    <TabDescription>
      <BodyText>
        Monitor your Azure Container Registry repositories for vulnerabilities
        surfaced by Azure Defender.
      </BodyText>
    </TabDescription>
    <TabContainer>
      <AzureContainerRepositoryList />
    </TabContainer>
  </>
);
