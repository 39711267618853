import { Radio, RadioGroup } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import React from "react";
import styled from "styled-components";

import { BASE_PALETTE, VANTA_COLORS } from "../../../../alpaca/base/colors";
import { GRID_SPACING } from "../../../../alpaca/base/grid";
import { BodyText, HelperText } from "../../../../alpaca/components";
import { BORDER_LINE } from "../../../../helpers/borders";
import { DefaultLink } from "../../../../helpers/links";
import type { PolicyWizardError } from "../beta-policies-wizard-page";
import {
  PoliciesCondensedCardContent,
  PoliciesH5,
} from "../policies-v2-styles";

/**
 * If this is a submit of a new version of a policy, then we display
 * the reacceptance step. This prompts users to confirm whether or not
 * all their employees need to re-review and re-accept their policies.
 * This corresponds to a new submission being a "major change."
 */
export const PoliciesWizardReacceptanceStep: React.FC<{
  isMajorChange: Maybe<boolean>;
  setMajorChange: (isMajorChange: boolean) => void;
  errors?: Maybe<PolicyWizardError[]>;
}> = ({ isMajorChange, setMajorChange, errors }) => {
  const selectedValue = !isSome(isMajorChange)
    ? undefined
    : isMajorChange
    ? "Yes"
    : "No";
  const setRadioGroupValueAsBoolean = (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    if (event.currentTarget.value === "Yes") {
      setMajorChange(true);
    } else {
      setMajorChange(false);
    }
  };
  return (
    <StyledCardContent>
      <PoliciesH5>
        Do your employees need to read and sign this policy again after this
        change?
      </PoliciesH5>
      <BodyText color={BASE_PALETTE.SMOKE}>
        If this change will affect the the way employees perform their jobs, we
        recommend selecting Yes. Once this policy is approved, your employees
        will then need to go to{" "}
        <DefaultLink href="app.vanta.com/onboarding">
          app.vanta.com/onboarding
        </DefaultLink>{" "}
        to reaccept this policy.
      </BodyText>
      <StyledRadioGroup
        onChange={setRadioGroupValueAsBoolean}
        selectedValue={selectedValue}
      >
        <Radio style={RADIO_BUTTON_STYLES} label="Yes" value="Yes" />
        <Radio style={RADIO_BUTTON_STYLES} label="No" value="No" />
      </StyledRadioGroup>
      <HelperText color={VANTA_COLORS.VANTA_ALERT}>
        {errors?.[0]?.message}
      </HelperText>
    </StyledCardContent>
  );
};

const StyledCardContent = styled(PoliciesCondensedCardContent)`
  text-align: center;
`;

const StyledRadioGroup = styled(RadioGroup)`
  display: flex;
  justify-content: center;
  width: 100%;
`;

// Styled components breaks Blueprint's radio group's ability to manage state of the radio buttons
// These styles don't fit the 8px spacing as they try to mimic blueprint styles.
const RADIO_BUTTON_STYLES = {
  border: BORDER_LINE,
  padding: `10px 30px`,
  margin: `${GRID_SPACING}px`,
  borderRadius: "3px",
};
