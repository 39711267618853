import { Classes, Intent } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import React, { useState } from "react";

import { AppToaster } from "../../../../helpers/toaster";
import type { IFormCredentialProps } from "../services/common-interface";
import { SelectProjects } from "./select-projects";
import { SubmitCredentials } from "./submit-credentials";

enum LinkingSteps {
  ADD_CREDENTIALS = "ADD_CREDENTIALS",
  SELECT_PROJECTS = "SELECT_PROJECTS",
}

export const MongoAtlas: React.FC<IFormCredentialProps> = ({
  credentials,
  domain,
  onCredentialsLinked,
}) => {
  const [pane, setPane] = useState(
    credentials.length > 0
      ? LinkingSteps.SELECT_PROJECTS
      : LinkingSteps.ADD_CREDENTIALS
  );
  const [newCredentials, setCredentials] =
    useState<Maybe<{ privateKey: string; publicKey: string }>>(null);

  const panes: {
    [pane in LinkingSteps]: {
      content: React.ReactNode;
    };
  } = {
    ADD_CREDENTIALS: {
      content: (
        <SubmitCredentials
          onSubmit={() => setPane(LinkingSteps.SELECT_PROJECTS)}
          setCredentials={setCredentials}
        />
      ),
    },
    SELECT_PROJECTS: {
      content: (
        <SelectProjects
          onSave={onCredentialsLinked}
          credentials={newCredentials}
          onFail={() => {
            AppToaster.show({
              icon: "error",
              intent: Intent.DANGER,
              message: "Unable to validate credentials.",
              timeout: 2500,
            });
            setPane(LinkingSteps.ADD_CREDENTIALS);
          }}
        />
      ),
    },
  };

  return (
    <div>
      <div className={Classes.DIALOG_HEADER}>Link MongoDB Atlas</div>
      <div className={Classes.DIALOG_BODY}>{panes[pane].content}</div>
    </div>
  );
};
