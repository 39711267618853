import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import type {
  IRiskItem,
  IRiskSchema,
} from "common/schemas/riskRegister/risk-register-content";
import gql from "graphql-tag";
import React, { useState } from "react";

import { VANTA_COLORS } from "../../../alpaca/base/colors";
import { H2 } from "../../../alpaca/components";
import { LogError } from "../../../errors";
import type { GetInventoryItemsForRiskAssessmentQuery } from "../../../gen/components";
import {
  GetInventoryItemsForRiskAssessmentDocument,
  useEvaluateRiskItemMutation,
} from "../../../gen/components";
import { ButtonGroupPanel } from "../../master-detail-view/detail-panels/button-group-panel";
import {
  IMPACT_BUTTON_VALUES,
  IMPACT_COPY,
  LIKELIHOOD_BUTTON_VALUES,
} from "./risk-definitions/risks";

interface IProps {
  riskItem: IRiskItem;
  schema: IRiskSchema;
  onComplete: () => void;
}

enum EvaluationStep {
  SelectLikelihood,
  SelectImpact,
}

export const RiskItemEvaluationFlow: React.FC<IProps> = ({
  riskItem,
  onComplete,
  schema,
}) => {
  const [likelihood, setLikelihood] = useState<Maybe<number>>(null);
  const [evaluateItem] = useEvaluateRiskItemMutation({
    update: (cache, result) => {
      const itemToModify = result.data?.evaluateRiskItem;
      if (!isSome(itemToModify)) {
        return;
      }
      const previousData =
        cache.readQuery<GetInventoryItemsForRiskAssessmentQuery>({
          query: GetInventoryItemsForRiskAssessmentDocument,
        });
      if (!isSome(previousData) || !isSome(previousData.organization)) {
        return;
      }

      cache.writeQuery<GetInventoryItemsForRiskAssessmentQuery>({
        query: GetInventoryItemsForRiskAssessmentDocument,
        data: {
          organization: {
            ...previousData.organization,
            riskEvaluations: previousData.organization.riskEvaluations.map(
              item => {
                if (item.id === itemToModify.id) {
                  return itemToModify;
                } else {
                  return item;
                }
              }
            ),
          },
        },
      });
      setEvaluationStep(EvaluationStep.SelectLikelihood);
      onComplete();
    },
  });
  const [evaluationStep, setEvaluationStep] = useState(
    EvaluationStep.SelectLikelihood
  );

  if (evaluationStep === EvaluationStep.SelectLikelihood) {
    const textContent = (
      <H2>
        {schema.likelihoodPrompt}:&nbsp;
        <H2 as="span" color={VANTA_COLORS.VANTA_PURPLE}>
          &ldquo;{riskItem.itemName}&rdquo;
        </H2>
      </H2>
    );
    return (
      <ButtonGroupPanel
        textContent={textContent}
        buttonValues={LIKELIHOOD_BUTTON_VALUES}
        selectedValue={riskItem.likelihood}
        onNewValue={newValue => {
          setLikelihood(+newValue);
          setEvaluationStep(EvaluationStep.SelectImpact);
        }}
      />
    );
  } else {
    const textContent = (
      <H2>
        {IMPACT_COPY.PROLOG}&nbsp;
        <H2 as="span" color={VANTA_COLORS.VANTA_PURPLE}>
          &ldquo;{riskItem.itemName}&rdquo;
        </H2>
        {IMPACT_COPY.EPILOG}
      </H2>
    );
    return (
      <ButtonGroupPanel
        textContent={textContent}
        buttonValues={IMPACT_BUTTON_VALUES}
        selectedValue={riskItem.impact}
        onNewValue={newValue => {
          if (!isSome(likelihood)) {
            setEvaluationStep(EvaluationStep.SelectLikelihood);
          } else {
            evaluateItem({
              variables: {
                evaluationId: riskItem.id,
                likelihood,
                impact: +newValue,
              },
            }).catch(LogError);
          }
        }}
      />
    );
  }
};

gql`
  mutation evaluateRiskItem(
    $evaluationId: String!
    $likelihood: Int!
    $impact: Int!
  ) {
    evaluateRiskItem(
      evaluationId: $evaluationId
      likelihood: $likelihood
      impact: $impact
    ) {
      id
      itemName
      riskId
      likelihood
      impact
    }
  }
`;
