import { FormGroup, Intent } from "@blueprintjs/core";
import { DateInput } from "@blueprintjs/datetime";
import { simplePlural } from "common/grammar/plurals";
import gql from "graphql-tag";
import moment from "moment";
import React, { useState } from "react";

import { BodyShortText, BodyText, Button, H4 } from "../../alpaca/components";
import {
  AllEndpointsDocument,
  useDeactivateUnlinkedCloudAgentsMutation,
} from "../../gen/components";
import { UI_DATE_FORMAT_WITHOUT_TIME } from "../../helpers/common";
import { AppToaster } from "../../helpers/toaster";

interface IProps {
  domainId: string;
}

const A_WEEK_AGO = moment().subtract(1, "week").toDate();

export const DeactivateCloudAgentsWidget: React.FC<IProps> = ({ domainId }) => {
  const [createdBefore, setCreatedBefore] = useState(A_WEEK_AGO);
  const [deactivateAgents, mutationResult] =
    useDeactivateUnlinkedCloudAgentsMutation({
      refetchQueries: [
        {
          query: AllEndpointsDocument,
          variables: { domainId },
        },
      ],
      onCompleted: result => {
        const numDeactivated = result.deactivateUnlinkedCloudAgents;
        AppToaster.show({
          intent: Intent.SUCCESS,
          message: `Deactivated ${simplePlural(numDeactivated, "agent")}`,
        });
      },
    });

  return (
    <div>
      <H4>Deactivate unlinked cloud agents?</H4>
      <BodyShortText>
        Use this control to deactivate any active cloud agents not associated
        with a cloud resource created before the specified date.
      </BodyShortText>
      <BodyShortText>
        Agents without <code>cloudProviderId</code>s will not be deactivated.
      </BodyShortText>
      <BodyShortText>Deactivates at most 1000 agents at a time.</BodyShortText>
      <FormGroup>
        <BodyText as="span">Deactivate agents created before</BodyText>
        <DateInput
          value={createdBefore}
          onChange={newDate => {
            // For now, ensure that we only delete agents at least a day old.
            if (moment(newDate).add(1, "day").isBefore(moment())) {
              setCreatedBefore(newDate);
            }
          }}
          formatDate={d => moment(d).format(UI_DATE_FORMAT_WITHOUT_TIME)}
          parseDate={s => new Date(s)}
        />
        <Button
          intent={Intent.DANGER}
          onClick={async () => {
            await deactivateAgents({
              variables: {
                domainId,
                createdBefore: createdBefore.toISOString(),
              },
            });
          }}
          loading={mutationResult.loading}
        >
          Deactivate agents
        </Button>
      </FormGroup>
    </div>
  );
};

gql`
  mutation deactivateUnlinkedCloudAgents(
    $domainId: ID!
    $createdBefore: DateTime!
  ) {
    deactivateUnlinkedCloudAgents(
      domainId: $domainId
      createdBefore: $createdBefore
    )
  }
`;
