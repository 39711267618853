import type { IIconProps, MaybeElement } from "@blueprintjs/core";
import { Classes, Icon as BPIcon } from "@blueprintjs/core";
import classNames from "classnames";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import React from "react";

import type { IconName } from "./iconNames";
import { ALPACA_ICON_PATHS, ALPACA_ICON_PATHS_LARGE } from "./paths";

const VIEWBOX_DIMENSION_STANDARD = 16;
const VIEWBOX_DIMENSION_LARGE = 32;

type AlpacaIconProps = Omit<IIconProps, "icon"> & {
  icon: IconName | MaybeElement;
};

/**
 * The general structure of this class is structured on the icon.tsx
 * file from BlueprintJS core package, allowing for seamlessly switching between the two
 * components
 *
 * https://github.com/palantir/blueprint/blob/develop/packages/core/src/components/icon/icon.tsx
 */
export class Icon extends React.PureComponent<
  AlpacaIconProps & React.DOMAttributes<HTMLElement>
> {
  public static SIZE_STANDARD = BPIcon.SIZE_STANDARD;
  public static SIZE_LARGE = BPIcon.SIZE_LARGE;

  public render(): Maybe<JSX.Element> {
    const { icon } = this.props;
    if (!isSome(icon) || typeof icon === "boolean") {
      return null;
    } else if (typeof icon !== "string") {
      return icon;
    }

    const {
      className,
      color,
      htmlTitle,
      iconSize = BPIcon.SIZE_STANDARD,
      intent,
      title = icon,
      tagName = "span",
      ...htmlprops
    } = this.props;

    // choose which pixel grid is most appropriate for given icon size
    const pixelGridSize =
      iconSize >= BPIcon.SIZE_LARGE
        ? VIEWBOX_DIMENSION_LARGE
        : VIEWBOX_DIMENSION_STANDARD;
    const paths = this.renderSvgPaths(pixelGridSize, icon);

    const classes = classNames(
      Classes.ICON,
      `vanta-icon-${icon}`,
      Classes.intentClass(intent),
      className
    );
    const viewBox = `0 0 ${pixelGridSize} ${pixelGridSize}`;

    return React.createElement(
      tagName,
      {
        ...htmlprops,
        className: classes,
        title: htmlTitle,
      },
      <svg
        fill={color}
        data-icon={icon}
        width={iconSize}
        height={iconSize}
        viewBox={viewBox}
        color={color}
      >
        {title ?? <desc>{title}</desc>}
        {paths}
      </svg>
    );
  }

  /** Render `<path>` elements for the given icon name. Returns `null` if name is unknown. */
  private renderSvgPaths(
    pathsSize: number,
    iconName: IconName
  ): Maybe<JSX.Element> {
    const svgPathsRecord =
      pathsSize === BPIcon.SIZE_STANDARD
        ? ALPACA_ICON_PATHS
        : ALPACA_ICON_PATHS_LARGE;
    const pathStrings = svgPathsRecord[iconName];
    if (!isSome(pathStrings)) {
      return null;
    }
    return pathStrings;
  }
}
