import { isSome, nothing } from "common/base/types/maybe";
import React from "react";

import { VulnerabilityPageInfo } from "../common/vulnerability-page-info";
import {
  accountForMachine,
  humanReadableCloudProvider,
  privateIpForMachine,
  publicIpForMachine,
  uniqueIdForMachine,
} from "../utils";
import type { Machine } from "./server-detail";

interface IProps {
  server: Machine;
}

export const ServerInfo: React.FC<IProps> = ({ server }) => {
  const leftTablePairs: Array<{ key: string; value: string }> = [];
  const rightTablePairs: Array<{ key: string; value: string }> = [];

  const cloudProviderId = server.cloudProviderData?.instanceId;
  if (isSome(cloudProviderId)) {
    leftTablePairs.push({ key: "instance id", value: cloudProviderId });
  }

  const hostname =
    server.data.__typename === "linuxServerData"
      ? server?.data?.hostname
      : nothing;

  if (isSome(hostname)) {
    leftTablePairs.push({ key: "hostname", value: hostname });
  }

  leftTablePairs.push({
    key: "host identifier",
    value: server.hostIdentifier,
  });

  const publicIp = publicIpForMachine(server);
  if (isSome(publicIp)) {
    leftTablePairs.push({
      key: "public ip address",
      value: publicIp,
    });
  }

  const privateIp = privateIpForMachine(server);
  if (isSome(privateIp)) {
    leftTablePairs.push({
      key: "private ip address",
      value: privateIp,
    });
  }

  const uniqueId = uniqueIdForMachine(server);
  if (isSome(uniqueId)) {
    leftTablePairs.push({ key: "unique id", value: uniqueId });
  }

  const kernelVersion =
    server.data.__typename === "linuxServerData"
      ? server.data.kernelVersion
      : nothing;
  if (isSome(kernelVersion)) {
    leftTablePairs.push({ key: "kernel version", value: kernelVersion });
  }

  const cloudProvider = humanReadableCloudProvider(server.cloudProvider);
  if (isSome(cloudProvider)) {
    rightTablePairs.push({
      key: "cloud provider",
      value: cloudProvider,
    });
  }

  const cloudAccountId = accountForMachine(server);
  if (isSome(cloudAccountId)) {
    rightTablePairs.push({
      key: "cloud provider account id",
      value: cloudAccountId,
    });
  }

  return (
    <VulnerabilityPageInfo
      leftTablePairs={leftTablePairs}
      rightTablePairs={rightTablePairs}
      leftColumnWidths={["140px", "320px"]}
      rightColumnWidths={["140px", "320px"]}
    />
  );
};
