import { Classes, Dialog } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { getTransformedOrElse } from "common/base/types/maybe";
import React, { useState } from "react";

import { IconNames } from "../../../../alpaca/components";
import { AcceptedSecurityPolicyDetail } from "../../components/accepted-security-policy-detail";
import { IconButton } from "../shared/icon-button";

interface IProps {
  userId: string;
  userName: string;
  renderButton?: Maybe<(onClick: () => void) => JSX.Element>;
}

export const PolicyAcceptanceDetail: React.FC<IProps> = ({
  userId,
  userName,
  renderButton,
}) => {
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const onClick = () => setDialogIsOpen(true);
  const button = getTransformedOrElse(
    renderButton,
    render => render(onClick),
    <IconButton
      onClick={onClick}
      icon={IconNames.VIEW}
      tooltipContent={"View"}
    />
  );

  return (
    <>
      {button}
      <Dialog
        title={`Policy acceptances for ${userName}`}
        isOpen={dialogIsOpen}
        onClose={() => setDialogIsOpen(false)}
      >
        <div className={Classes.DIALOG_BODY}>
          <AcceptedSecurityPolicyDetail userId={userId} />
        </div>
      </Dialog>
    </>
  );
};
