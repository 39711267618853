import { Button, MenuItem } from "@blueprintjs/core";
import type { ItemRenderer } from "@blueprintjs/select";
import { Select } from "@blueprintjs/select";
import { PermissionLevel } from "common/base/types/gen";
import { isSome } from "common/base/types/maybe";
import React from "react";

const AccessManagementSelect = Select.ofType<PermissionLevel>();

interface IProps {
  selected: PermissionLevel;
  loading: boolean;
  onSelect(choice: PermissionLevel): void;
}

export class AccessManagementSelector extends React.Component<IProps> {
  public constructor(props: IProps) {
    super(props);
  }

  public render() {
    return (
      <AccessManagementSelect
        items={[PermissionLevel.Admin, PermissionLevel.Dashboard]} // Don't include onboarding in the list.
        onItemSelect={this.props.onSelect}
        itemRenderer={this.renderItem}
        filterable={false}
      >
        <Button
          loading={this.props.loading}
          icon="caret-down"
          text={this.renderPermissionLevel(
            isSome(this.props.selected)
              ? this.props.selected
              : PermissionLevel.Dashboard
          )}
        />
      </AccessManagementSelect>
    );
  }

  private renderPermissionLevel(item: PermissionLevel): string {
    if (item === PermissionLevel.Dashboard) {
      return "Access";
    }
    return item.toString();
  }
  private readonly renderItem: ItemRenderer<PermissionLevel> = (
    item,
    itemProps
  ) => (
    <div key={`${item}`}>
      <MenuItem
        active={false}
        key={item}
        text={this.renderPermissionLevel(item)}
        onClick={itemProps.handleClick}
      />
    </div>
  );
}
