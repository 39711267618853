import type { IDialogProps } from "@blueprintjs/core";
import { Classes, Dialog } from "@blueprintjs/core";
import { isSome } from "common/base/types/maybe";
import React from "react";
import styled from "styled-components";
import { GRID_SPACING } from "../../alpaca/base/grid";
import { Banner, IconNames } from "../../alpaca/components";
import type { Maybe } from "../../gen/components";

import { ConfigureScopes } from "./configure-scopes";
import type { ScopableResourceMetadata } from "./service-groups";

export interface IConfigureScopesProps {
  fetchCompletionProportion?: Maybe<number>;
  scopableResources: readonly ScopableResourceMetadata[];
  tips: JSX.Element;
}

export const ConfigureScopesDialog: React.FC<
  IDialogProps & IConfigureScopesProps
> = props => (
  <StyledDialog {...props} title={"Scope resources"}>
    {isSome(props.fetchCompletionProportion) &&
    props.fetchCompletionProportion < 1 ? (
      <StyledBannerContainer>
        <Banner
          icon={IconNames.CAUTION}
          title={"Not all resources are loaded"}
          description={`Only ${Math.floor(
            props.fetchCompletionProportion * 100
          )}% of this
          integration's resources are loaded. To configure scope for the remaing
          resources, please wait until Vanta completes the scan.`}
        />
      </StyledBannerContainer>
    ) : null}
    <div className={Classes.DIALOG_BODY}>
      {props.tips}
      <ConfigureScopes {...props} />
    </div>
  </StyledDialog>
);

const StyledDialog = styled(Dialog)`
  width: ${74 * GRID_SPACING}px;
`;

const BANNER_PADDING = `${2.5 * GRID_SPACING}px`;

const StyledBannerContainer = styled.div`
  padding: ${BANNER_PADDING} ${BANNER_PADDING} 0 ${BANNER_PADDING};
`;
