import { Callout, Dialog, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { dateFromObjectId } from "common/base/dateUtils";
import { isSome, nothing } from "common/base/types/maybe";
import gql from "graphql-tag";
import moment from "moment";
import React, { useState } from "react";

import { Button } from "../../../alpaca/components";
import { LogError } from "../../../errors";
import type { GetDomainInfoForQueryPageQuery } from "../../../gen/components";
import {
  GetDomainInfoForQueryPageDocument,
  useGetDomainInfoForQueryPageQuery,
  useLockDomainMutation,
  useReenableCredentialsMutation,
  useUnlockDomainMutation,
} from "../../../gen/components";
import { UI_DATE_FORMAT } from "../../../helpers/common";
import { Ellipsify } from "../../../helpers/ellipsify";
import { DefaultLink } from "../../../helpers/links";
import { AppToaster } from "../../../helpers/toaster";
import {
  booleanToEvaluationEnum,
  EvaluationIcon,
} from "../../helpers/EvaluationIcon";
import { FullPageSpinner } from "../../helpers/FullPageSpinner";
import { ChangeDomainNamePanel } from "../../query-page/change-domain-name-panel";
import { DataTable } from "../components/data-table";

const TABLE_HEADINGS = [
  ["id", "name", "displayName", "salesforceId", "admin", "vantaSubscriber"],
  ["domainAdmins"],
  [
    "testResults",
    "numPassingTests",
    "numFailingTests",
    "numNATests",
    "numDisabledTests",
    "numInProgressTests",
    "policies",
    "presentedReports",
  ],
  ["credentials", "whichCredentials"],
];

const HEADERS = {
  id: "ID",
  name: "Name",
  displayName: "Display",
  salesforceId: "Salesforce",
  admin: "Admin",
  vantaSubscriber: "Vanta plan",
  domainAdmins: "Admins",
  credentials: "Credential count",
  whichCredentials: "Which credentials",
  testResults: "Tests",
  numPassingTests: "# Passing",
  numFailingTests: "# Failing",
  numNATests: "# NA",
  numDisabledTests: "# Disabled",
  numInProgressTests: "# In progress",
  policies: "Policies made",
  presentedReports: "Reports made",
};

interface IInternalProps {
  domain: NonNullable<GetDomainInfoForQueryPageQuery["internal"]["domainById"]>;
}

const CustomerInfoComponent: React.FC<IInternalProps> = ({ domain }) => {
  const tableData = [domain];

  return (
    <div>
      <h1>Domain Information</h1>
      <LockUnlockDomainCallout domain={domain} />
      <ChangeDomainNamePanel domainId={domain.id} name={domain.name} />
      {TABLE_HEADINGS.map((c, index) => (
        <DataTable
          key={`qp-table-${index}`}
          columnOrder={c}
          data={tableData}
          header={HEADERS}
          createRow={getDomainInfo}
        />
      ))}
    </div>
  );
};

const LockUnlockDomainCallout: React.FC<IInternalProps> = ({ domain }) => {
  const [lockDomain, { loading: locking }] = useLockDomainMutation({
    variables: {
      domainId: domain.id,
    },
    onError: LogError,
  });
  const [unlockDomain, { loading: unlocking }] = useUnlockDomainMutation({
    variables: {
      domainId: domain.id,
    },
    onError: LogError,
  });

  const calloutContent =
    isSome(domain.lockoutDate) && new Date(domain.lockoutDate) <= new Date()
      ? {
          text: "This domain is locked. To allow this domain to access Vanta, use the 'Unlock domain' button.",
          button: (
            <Button
              icon={IconNames.UNLOCK}
              loading={locking || unlocking}
              text={"Unlock domain"}
              onClick={async () => unlockDomain()}
            />
          ),
        }
      : {
          text: "This domain is unlocked. To prevent this domain from accessing Vanta, use the 'Lock domain' button.",
          button: (
            <Button
              icon={IconNames.LOCK}
              loading={locking || unlocking}
              text={"Lock domain"}
              intent={Intent.DANGER}
              onClick={async () => lockDomain()}
            />
          ),
        };

  return (
    <Callout title={"Domain lockout"} style={{ maxWidth: "800px" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <p>{calloutContent.text}</p>
          {isSome(domain.lockoutDate) ? (
            <p>Lockout date: {domain.lockoutDate}</p>
          ) : null}
        </div>
        <div>{calloutContent.button}</div>
      </div>
    </Callout>
  );
};

const CREDENTIALS_TABLE_COLUMN_WIDTHS = ["100px", "175px", "175px", "250px"];

const CredentialsTable: React.FC<IInternalProps> = ({ domain }) => {
  const [reenableCredentials, { loading: reenableCredentialsLoading }] =
    useReenableCredentialsMutation({
      refetchQueries: [
        {
          query: GetDomainInfoForQueryPageDocument,
          variables: {
            domainId: domain.id,
          },
        },
      ],
      onCompleted: () => {
        AppToaster.show({
          intent: Intent.SUCCESS,
          message: "Credentials re-enabled",
        });
      },
    });

  const [isCredentialsHistoryDialogOpen, setCredentialsHistoryDialogOpen] =
    useState(false);

  return (
    <DataTable
      header={{
        service: "Service",
        createdAt: "Creation",
        updatedAt: "Last Update",
        active: "Active",
      }}
      columnOrder={["service", "createdAt", "updatedAt", "active"]}
      columnWidths={CREDENTIALS_TABLE_COLUMN_WIDTHS}
      classes="manage-table"
      data={domain.credentials}
      createRow={row => {
        return {
          service: `${row.service} ${row.accountId ?? ""}`,
          createdAt: moment(dateFromObjectId(row.id)).format(UI_DATE_FORMAT),
          updatedAt: moment(row.lastUpdated).format(UI_DATE_FORMAT),
          active: row.isDisabled ? (
            <React.Fragment>
              <Ellipsify text={`Cause: ${row.disableCause!}`} />
              <div>
                {isSome(row.disableHistory[0]?.disableDate)
                  ? `Disabled at ${moment(
                      row.disableHistory[0].disableDate
                    ).format(UI_DATE_FORMAT)}`
                  : null}
              </div>
              <Button
                disabled={reenableCredentialsLoading}
                onClick={async () =>
                  reenableCredentials({
                    variables: {
                      service: row.service,
                      domainId: domain.id,
                      externalAccountId: row.externalAccountId,
                    },
                  })
                }
              >
                Reenable
              </Button>
              <Button
                onClick={async () => {
                  setCredentialsHistoryDialogOpen(true);
                }}
              >
                Show Disable History
              </Button>
              <Dialog
                isOpen={isCredentialsHistoryDialogOpen}
                onClose={async () => {
                  setCredentialsHistoryDialogOpen(false);
                }}
              >
                <DataTable
                  header={{
                    cause: "Cause",
                    disableDate: "Disable Date",
                  }}
                  columnOrder={["cause", "disableDate"]}
                  data={row.disableHistory}
                  createRow={disableHistoryRow => {
                    return {
                      cause: disableHistoryRow.cause,
                      disableDate: isSome(disableHistoryRow.disableDate)
                        ? moment(disableHistoryRow.disableDate).format(
                            UI_DATE_FORMAT
                          )
                        : "",
                    };
                  }}
                />
              </Dialog>
            </React.Fragment>
          ) : (
            "Yes"
          ),
        };
      }}
    />
  );
};

const getDomainInfo = (
  domain: NonNullable<GetDomainInfoForQueryPageQuery["internal"]["domainById"]>
) => {
  const firstAdmin =
    isSome(domain.admins) && isSome(domain.admins.vantaAdmins)
      ? domain.admins.vantaAdmins[0]
      : nothing;
  return {
    admin: isSome(firstAdmin) ? firstAdmin.email : "",
    displayName: domain.displayName,
    salesforceId: isSome(domain.salesforceId) ? (
      <DefaultLink
        href={`https://vanta.lightning.force.com/lightning/r/Account/${domain.salesforceId}/view`}
      >
        Open in Salesforce
      </DefaultLink>
    ) : (
      "Not Connected"
    ),
    id: domain.id,
    name: domain.name,
    vantaSubscriber: (
      <EvaluationIcon
        evaluation={booleanToEvaluationEnum(
          isSome(domain.payment.vantaSubscription)
        )}
      />
    ),
    domainAdmins:
      isSome(domain.admins) && isSome(domain.admins.vantaAdmins)
        ? domain.admins.vantaAdmins
            .map(a => (isSome(a) ? a.email : ""))
            .join(", ")
        : nothing,
    credentials: domain.credentials.length,
    whichCredentials: <CredentialsTable domain={domain} />,
    testResults: domain.testResults.length,
    numPassingTests: domain.testRunStats.numPass,
    numFailingTests: domain.testRunStats.numFail,
    numNATests: domain.testRunStats.numNA,
    numDisabledTests: domain.testRunStats.numDisabled,
    numInProgressTests: domain.testRunStats.numInProgress,
    policies: domain.policies.length,
    presentedReports: domain.presentedReports.length,
  };
};

gql`
  query GetDomainInfoForQueryPage($domainId: ID!) {
    internal {
      domainById(id: $domainId) {
        id
        name
        displayName
        salesforceId
        admins {
          vantaAdmins {
            id
            email
          }
        }
        credentials(includeDisabled: true) {
          id
          service
          accountId
          externalAccountId
          isDisabled
          disableCause
          disableHistory {
            cause
            disableDate
          }
          lastUpdated
        }
        lockedAndPastGracePeriod
        lockoutDate
        payment {
          id
          vantaSubscription {
            subscriptionKey
          }
        }
        policies(onlyApproved: false) {
          id
          policyType
        }
        presentedReports {
          id
        }
        testResults {
          id
          outcome
        }
        testRunStats {
          numPass
          numFail
          numNA
          numDisabled
          numInProgress
        }
      }
    }
  }
`;

gql`
  mutation ReenableCredentials(
    $domainId: ID!
    $service: String!
    $externalAccountId: String
  ) {
    reenableCredentials(
      domainId: $domainId
      service: $service
      externalAccountId: $externalAccountId
    )
  }
`;

gql`
  mutation LockDomain($domainId: ID!) {
    lockDomain(domainId: $domainId) {
      id
      lockoutDate
      lockedAndPastGracePeriod
    }
  }
`;

gql`
  mutation UnlockDomain($domainId: ID!) {
    unlockDomain(domainId: $domainId) {
      id
      lockoutDate
      lockedAndPastGracePeriod
    }
  }
`;

interface IProps {
  domainId: string;
}

export const CustomerInfoPage: React.FC<IProps> = ({ domainId }) => {
  const { loading, error, data } = useGetDomainInfoForQueryPageQuery({
    variables: { domainId },
  });
  if (error) {
    LogError(error);
    return null;
  }
  if (loading) {
    return <FullPageSpinner />;
  }
  if (!data) {
    LogError(new Error("Bad fetch for GetDomainInfoForQueryPage"));
    return null;
  }
  return <CustomerInfoComponent domain={data.internal.domainById} />;
};
