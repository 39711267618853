import { ReportStandard } from "../../base/types/gen";
import type { IStandard } from "../complianceTypes";

export const VANTA_STANDARD: IStandard = {
  standard: ReportStandard.vanta,
  version: "2017",
  updated: "11/20/2017",
  canonical: "V 20171120",
  principles: [
    {
      name: "Data and privacy",
      section: "V 1.0",
      optional: false,
      requirements: [
        {
          name: "Customer data policies",
          section: "V 1.1",
        },
        {
          name: "Protect customer data",
          section: "V 1.2",
        },
        {
          name: "Internal admin tool",
          section: "V 1.3",
        },
        {
          name: "EU customers",
          section: "V 1.4",
        },
        {
          name: "Secure data delete",
          section: "V 1.5",
        },
        {
          name: "Sub-service organizations",
          section: "V 1.6",
        },
        {
          name: "External service providers",
          section: "V 1.7",
        },
      ],
    },
    {
      name: "Internal security procedures",
      section: "V 2.0",
      optional: false,
      requirements: [
        {
          name: "Software development life cycle",
          section: "V 2.1",
        },
        {
          name: "Software testing",
          section: "V 2.2",
        },
        {
          name: "Risk management",
          section: "V 2.3",
        },
        {
          name: "Responsible Disclosure Policy",
          section: "V 2.4",
        },
        {
          name: "Vulnerability management",
          section: "V 2.5",
        },
        {
          name: "Security issues",
          section: "V 2.6",
        },
        {
          name: "Incident Response Plan",
          section: "V 2.7",
        },
      ],
    },
    {
      name: "Organizational security",
      section: "V 3.0",
      optional: false,
      requirements: [
        {
          name: "Security policies",
          section: "V 3.1",
        },
        {
          name: "Security program",
          section: "V 3.2",
        },
        {
          name: "Personnel security",
          section: "V 3.3",
        },
        {
          name: "Network security",
          section: "V 3.4",
        },
        {
          name: "Endpoints (laptops)",
          section: "V 3.5",
        },
      ],
    },
    {
      name: "Product security",
      section: "V 4.0",
      optional: false,
      requirements: [
        {
          name: "Authentication and authorization",
          section: "V 4.1",
        },
        {
          name: "Data encryption",
          section: "V 4.2",
        },
        {
          name: "Customer communication",
          section: "V 4.3",
        },
      ],
    },
    {
      name: "Infrastructure security",
      section: "V 5.0",
      optional: false,
      requirements: [
        {
          name: "Authentication and authorization",
          section: "V 5.1",
        },
        {
          name: "Availability",
          section: "V 5.2",
        },
        {
          name: "Backups",
          section: "V 5.3",
        },
        {
          name: "Logging",
          section: "V 5.4",
        },
        {
          name: "Monitoring",
          section: "V 5.5",
        },
        {
          name: "Network",
          section: "V 5.6",
        },
        {
          name: "Protecting secrets",
          section: "V 5.7",
        },
        {
          name: "Servers",
          section: "V 5.8",
        },
      ],
    },
    {
      name: "Physical security",
      section: "V 6.0",
      optional: false,
      requirements: [
        {
          name: "Data center security",
          section: "V 6.1",
        },
        {
          name: "Office security",
          section: "V 6.2",
        },
      ],
    },
  ],
};
