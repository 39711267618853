import type { Maybe } from "common/base/types/maybe";
import React, { useState } from "react";

import type { GetVendorsQuery } from "../../../gen/components";
import { DeleteVendorDialog } from "./delete-vendor-dialog";
import { VendorForm } from "./vendor-form";
import { VendorTableRow } from "./vendor-table-row";

interface IProps {
  vendor: NonNullable<GetVendorsQuery["organization"]>["vendors"][number];
  users: NonNullable<GetVendorsQuery["organization"]>["users"];
  startOpen?: Maybe<boolean>;
  onUpdate(params: any): void;
  onDelete(): void;
}

export const CollapsibleVendorRow: React.FC<IProps> = ({
  vendor,
  users,
  onUpdate,
  onDelete,
  startOpen,
}) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  return (
    <>
      <VendorTableRow
        vendor={vendor}
        deleteHandler={() => setDeleteDialogOpen(true)}
        startOpen={startOpen}
      >
        <VendorForm vendor={vendor} users={users} onUpdate={onUpdate} />
      </VendorTableRow>
      <DeleteVendorDialog
        vendor={vendor}
        isOpen={deleteDialogOpen}
        onCancel={() => setDeleteDialogOpen(false)}
        onConfirmDelete={() => {
          onDelete();
          setDeleteDialogOpen(false);
        }}
      />
    </>
  );
};
