import { Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { isSome } from "common/base/types/maybe";
import { SERVICE_TO_HR_USER_KIND } from "common/resources/generic-resources/hr-user-constants";
import { groupBy } from "lodash";
import React, { useState } from "react";
import styled from "styled-components";

import { AnchorButton, Tooltip } from "../../../alpaca/components";
import { useAvailableBetaFeatures } from "../../../helpers/feature-gating/feature-check";
import { CredentialCard } from "../credential-card";
import { HrCredentialCard } from "../hr-credential-card";
import { MergedCredentialCard } from "../merged-credential-card";
import { ServiceGroupName } from "../service-groups";
import { LinkServiceGroupDialog } from "./link-service-group-dialog";
import type { IServiceGroupProps } from "./service-group";

/**
 * ServiceGroupCredentialsList renders an array of cards representing the
 * credentials for all services in serviceGroup, along with a button for for
 * adding a new credential for any of those services.
 *
 * If no credentials belong to the service group (i.e. relevantCredentials is
 * empty) this component is nonsensical.
 *
 * @param serviceGroup - the service group to display.
 * @param relevantCredentials - all credentials for services belonging to
 *    serviceGroup.
 */
export const ServiceGroupCredentialsList: React.FC<IServiceGroupProps> = ({
  serviceGroup,
  relevantCredentials,
}) => {
  const availableBetaFeatures = useAvailableBetaFeatures();
  const [linkingModalOpen, setLinkingModalOpen] = useState(false);
  const availableServiceGroupServices = serviceGroup.services.filter(
    service =>
      !isSome(service.feature) || availableBetaFeatures?.has(service.feature)
  );

  const credentialsByService = groupBy(relevantCredentials, c => c.service);
  const hrProviderAlreadyLinked =
    serviceGroup.name === ServiceGroupName.HRProviders &&
    relevantCredentials.length > 0;

  return (
    <div>
      {Object.entries(credentialsByService).map(([serviceId, credentials]) => {
        const service = serviceGroup.services.find(s => s.id === serviceId)!;
        return ["aws", "azure"].includes(serviceId) ? (
          <MergedCredentialCard
            service={service}
            credentials={credentials}
            key={serviceId}
          />
        ) : serviceId in SERVICE_TO_HR_USER_KIND ? (
          <HrCredentialCard
            credential={credentials[0]}
            key={serviceId}
            service={service}
          />
        ) : (
          <CredentialCard
            service={service}
            credential={credentials[0]}
            key={serviceId}
          />
        );
      })}
      <Row>
        <Tooltip
          content="Only a single HR integration can be linked"
          disabled={!hrProviderAlreadyLinked}
        >
          <AnchorButton
            intent={Intent.PRIMARY}
            icon={IconNames.PLUS}
            onClick={() => setLinkingModalOpen(true)}
            disabled={
              // If every service in the group has been linked, this "link another
              // service" dialog is incoherent as long as we don't allow multiple
              // credentials per service.
              Object.keys(credentialsByService).length >=
                availableServiceGroupServices.length || hrProviderAlreadyLinked
            }
          >
            {
              // Users can only link one identity provider.
              serviceGroup.name === ServiceGroupName.IdentityProviders
                ? "Change"
                : "Add"
            }
          </AnchorButton>
        </Tooltip>
      </Row>
      <LinkServiceGroupDialog
        serviceGroup={serviceGroup}
        relevantCredentials={relevantCredentials}
        isOpen={linkingModalOpen}
        onClose={() => setLinkingModalOpen(false)}
      />
    </div>
  );
};

const Row = styled.div`
  display: flex;
`;
