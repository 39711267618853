import { Button, Classes, Dialog, Intent, Spinner } from "@blueprintjs/core";
import { isSome } from "common/base/types/maybe";
import React, { useState } from "react";

import { LogError } from "../../../../errors";
import { useGetSingleCredentialQuery } from "../../../../gen/components";
import { CredentialComponent } from "../credential";

interface IProps {
  onClose(): void;
  service: "digitalocean" | "digitaloceanspaces";
}

const serviceToOtherService: {
  [k: string]: {
    otherServiceKey: "digitalocean" | "digitaloceanspaces";
    header: string;
    description: string;
  };
} = {
  digitalocean: {
    otherServiceKey: "digitaloceanspaces",
    header: "DigitalOcean Spaces",
    description: "DigitalOcean Spaces",
  },
  digitaloceanspaces: {
    otherServiceKey: "digitalocean",
    header: "DigitalOcean",
    description: "other DigitalOcean services",
  },
};

export const LinkOtherDigitalOcean: React.FunctionComponent<IProps> = ({
  onClose,
  service,
}) => {
  const { loading, error, data } = useGetSingleCredentialQuery({
    variables: { service: serviceToOtherService[service].otherServiceKey },
  });
  const [linkingDialogOpen, setLinkingDialogOpen] = useState(false);

  if (error) {
    LogError(error);
    return null;
  }
  if (loading) {
    return <Spinner />;
  }

  if (isSome(data) && data.organization.credentials.length > 0) {
    // other service already linked, don't prompt for linking
    if (isSome(onClose)) {
      onClose();
    }
    return null;
  }

  const otherServiceInfo = serviceToOtherService[service];

  const linkingDialog = (
    <Dialog
      isOpen={linkingDialogOpen}
      onClose={() => {
        setLinkingDialogOpen(false);
        onClose();
      }}
    >
      <CredentialComponent
        service={otherServiceInfo.otherServiceKey}
        onCredentialsLinked={() => {
          setLinkingDialogOpen(false);
          onClose();
        }}
      />
    </Dialog>
  );

  return (
    <div className={Classes.RUNNING_TEXT}>
      <div className={Classes.DIALOG_HEADER}>
        Link {otherServiceInfo.header}
      </div>
      <div className={Classes.DIALOG_BODY}>
        <p>Do you use {otherServiceInfo.description}?</p>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button
            intent={Intent.NONE}
            onClick={() => {
              onClose();
            }}
          >
            Not now
          </Button>
          <Button
            intent={Intent.PRIMARY}
            onClick={() => {
              setLinkingDialogOpen(true);
            }}
          >
            Connect
          </Button>
        </div>
      </div>
      {linkingDialog}
    </div>
  );
};
