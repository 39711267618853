import {
  Button,
  Checkbox,
  ControlGroup,
  Divider,
  EditableText,
  FormGroup,
  HTMLSelect,
  InputGroup,
  Intent,
  NumericInput,
  Radio,
  RadioGroup,
} from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { isSome, nothing } from "common/base/types/maybe";
import { AuthMethod } from "common/schemas/vendor/authMethod";
import React from "react";
import styled from "styled-components";

import { Tooltip } from "../../../alpaca/components";
import type { GetVendorsQuery } from "../../../gen/components";
import { Feature } from "../../../gen/components";
import { FeatureGate } from "../../../helpers/feature-gating/feature-gate";
import { AppToaster } from "../../../helpers/toaster";
import { UserSelector } from "../../form-controls/user-selector";
import { VENDOR_COPY } from "./copy";
import { RequestVAQButton } from "./send-vaq-button";
import { UploadDocumentsControl } from "./upload-documents-control";
import { VendorSecurityControlsDisplay } from "./vendor-security-controls-display";

interface IProps {
  vendor: NonNullable<GetVendorsQuery["organization"]>["vendors"][number];
  users: NonNullable<GetVendorsQuery["organization"]>["users"];
  startOpen?: Maybe<boolean>;
  onUpdate(params: any): void;
}

enum AdditionalAuthOption {
  OTHER = "Other",
  NOT_SPECIFIED = "Not specified",
}

type AuthOption = AuthMethod | AdditionalAuthOption;

interface IState {
  isEditing: boolean;
  assessmentCommentsText: string;
  authOption: AuthOption;
  authText: string;
  contactEmailText: string;
  contactNameText: string;
  containsUserData?: Maybe<boolean>;
  dataStoredText: string;
  fileToUpload?: Maybe<File>;
  nameText: string;
  otherInformationText: string;
  ownerId?: Maybe<string>;
  passwordMFA?: Maybe<boolean>;
  passwordMinLength?: Maybe<number>;
  passwordRequiresNumber?: Maybe<boolean>;
  passwordRequiresSymbol?: Maybe<boolean>;
  servicesProvidedText: string;
  severity: string;
  sharesEPHI?: Maybe<boolean>;
  sharesEUPII?: Maybe<boolean>;
  urlText: string;
  initialParams: any;
  documentUploadRadioValue?: Maybe<string>;
  fileDialogConfirmed?: Maybe<boolean>;
}

const AUTH_OPTIONS: Array<{ label: string; value: AuthOption }> = [
  {
    label: "Google Workspace",
    value: AuthMethod.G_SUITE,
  },
  {
    label: "Outlook Web App",
    value: AuthMethod.OWA,
  },
  {
    label: "Other OAuth provider",
    value: AuthMethod.O_AUTH,
  },
  {
    label: "Other Single Sign-On provider",
    value: AuthMethod.SSO,
  },
  {
    label: "Username/password",
    value: AuthMethod.USERNAME_PASSWORD,
  },
  {
    label: "Other",
    value: AdditionalAuthOption.OTHER,
  },
  {
    label: "Not specified",
    value: AdditionalAuthOption.NOT_SPECIFIED,
  },
];

export class VendorForm extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
    const defaultState = this.getDefaultState(props);
    this.state = {
      ...defaultState,
      isEditing: props.startOpen ?? false,
      initialParams: this.vendorParamsFromState(defaultState),
    };

    this.onCancelButtonClicked = this.onCancelButtonClicked.bind(this);
    this.onUpdateButtonClicked = this.onUpdateButtonClicked.bind(this);
    this.vendorParamsFromState = this.vendorParamsFromState.bind(this);
    this.onUserChange = this.onUserChange.bind(this);
    this.updateAndClose = this.updateAndClose.bind(this);
    this.getDefaultState = this.getDefaultState.bind(this);
    this.formIsValid = this.formIsValid.bind(this);
    this.formHasBeenModified = this.formHasBeenModified.bind(this);
  }

  public getDefaultState(props: IProps): IState {
    const { vendor } = props;
    const passwordMinLength = isSome(vendor.specifiedPasswordPolicy)
      ? vendor.specifiedPasswordPolicy.minLength
      : vendor.passwordMinLength;
    const passwordRequiresSymbol = isSome(vendor.specifiedPasswordPolicy)
      ? vendor.specifiedPasswordPolicy.requiresSymbol
      : vendor.passwordRequiresSymbol;
    const passwordRequiresNumber = isSome(vendor.specifiedPasswordPolicy)
      ? vendor.specifiedPasswordPolicy.requiresNumber
      : vendor.passwordRequiresNumber;
    const authOption = toAuthOption(
      isSome(vendor.specifiedPasswordPolicy)
        ? AuthMethod.USERNAME_PASSWORD
        : vendor.authMethod
    );
    return {
      fileToUpload: nothing,
      isEditing: false,
      assessmentCommentsText: getOrEmptyString(vendor.assessmentComments),
      authOption,
      authText:
        authOption === AdditionalAuthOption.OTHER && isSome(vendor.authMethod)
          ? vendor.authMethod
          : "",
      contactEmailText: getOrEmptyString(vendor.contactEmail),
      contactNameText: getOrEmptyString(vendor.contactName),
      containsUserData: vendor.containsUserData ?? false,
      dataStoredText: getOrEmptyString(vendor.dataStored),
      nameText: vendor.name,
      otherInformationText: getOrEmptyString(vendor.otherInformation),
      ownerId: vendor.ownerId,
      passwordMFA: vendor.passwordMFA ?? false,
      passwordMinLength,
      passwordRequiresNumber,
      passwordRequiresSymbol,
      servicesProvidedText: getOrEmptyString(vendor.servicesProvided),
      severity: vendor.severity,
      sharesEPHI: vendor.sharesEPHI ?? false,
      sharesEUPII: vendor.sharesEUPII ?? false,
      urlText: vendor.url,
      initialParams: {},
    };
  }

  public componentDidUpdate(prevProps: IProps) {
    if (this.state.fileDialogConfirmed) {
      this.updateAndClose();
      this.setState({ fileDialogConfirmed: false });
    }
    if (this.props !== prevProps) {
      const { vendor } = this.props;
      this.setState({
        nameText: getOrEmptyString(vendor.name),
        otherInformationText: getOrEmptyString(vendor.otherInformation),
        initialParams: this.vendorParamsFromState(),
      });
    }
  }

  public onUpdateButtonClicked() {
    this.updateAndClose();
  }

  public onCancelButtonClicked() {
    this.setState(this.getDefaultState(this.props));
  }

  public onUserChange(choice: Maybe<string[]>) {
    if (isSome(choice) && choice.length > 0) {
      this.setState({
        ownerId: choice[0],
      });
    }
  }

  public vendorParamsFromState(state: IState = this.state) {
    const authText = state.authText.trim();
    return {
      assessmentFile: state.fileToUpload,
      assessmentComments: state.assessmentCommentsText,
      authMethod:
        state.authOption === AdditionalAuthOption.NOT_SPECIFIED
          ? ""
          : state.authOption !== AdditionalAuthOption.OTHER
          ? state.authOption
          : authText.length > 0
          ? authText
          : AdditionalAuthOption.OTHER,
      contactEmail: state.contactEmailText.trim(),
      contactName: state.contactNameText.trim(),
      containsUserData: state.containsUserData,
      dataStored: state.dataStoredText.trim(),
      id: this.props.vendor.id,
      name: state.nameText.trim(),
      otherInformation: state.otherInformationText.trim(),
      ownerId: state.ownerId,
      passwordMFA: state.passwordMFA,
      passwordMinLength: state.passwordMinLength,
      passwordRequiresNumber: state.passwordRequiresNumber,
      passwordRequiresSymbol: state.passwordRequiresSymbol,
      servicesProvided: state.servicesProvidedText.trim(),
      severity: state.severity,
      sharesEPHI: state.sharesEPHI,
      sharesEUPII: state.sharesEUPII,
      url: state.urlText.trim(),
    };
  }

  public render() {
    return <div style={{ position: "relative" }}>{this.renderForm()}</div>;
  }

  // (Introducing complexity rule, should still fix)
  // eslint-disable-next-line complexity
  public renderForm() {
    const formHasChanged = this.formHasBeenModified();

    const hasNameText = this.state.nameText !== "";
    const hasUrlText = this.state.urlText !== "";

    const formIsValid = hasNameText && hasUrlText;

    const nameUrlControl = (
      <div className="vendor-form-control">
        <FormGroup
          label={<h6>Vendor name</h6>}
          intent={!hasNameText ? Intent.DANGER : Intent.NONE}
          helperText={!hasNameText ? "This field is required." : undefined}
        >
          <InputGroup
            disabled={this.props.vendor.isAssociatedWithCredentials}
            intent={!hasNameText ? Intent.DANGER : Intent.NONE}
            className="bp3-fill"
            type="text"
            placeholder="ACME Cloud Services"
            value={this.state.nameText}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              this.setState({ nameText: e.target.value });
            }}
          />
        </FormGroup>
        <FormGroup
          label={<h6>Website</h6>}
          intent={!hasUrlText ? Intent.DANGER : Intent.NONE}
          helperText={!hasUrlText ? "This field is required." : undefined}
        >
          <InputGroup
            disabled={this.props.vendor.isAssociatedWithCredentials}
            intent={!hasUrlText ? Intent.DANGER : Intent.NONE}
            type="text"
            placeholder="www.example.com"
            value={this.state.urlText}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              this.setState({ urlText: e.target.value });
            }}
          />
        </FormGroup>
      </div>
    );

    const nameUrlControlWithTooltip = this.props.vendor
      .isAssociatedWithCredentials ? (
      <Tooltip content="This tool is linked to Vanta" placement="right">
        {nameUrlControl}
      </Tooltip>
    ) : (
      nameUrlControl
    );

    const severityControl = (
      <div className="vendor-form-control">
        <h6>Risk</h6>
        <RadioGroup
          inline={true}
          selectedValue={this.state.severity}
          onChange={e => {
            this.setState({ severity: e.currentTarget.value });
          }}
        >
          <Radio
            labelElement={
              <span>
                <strong>High</strong>: A vendor is high risk if it is used to
                store or access sensitive customer/user data and if failure of
                vendor service would have a critical impact on your business'
                service.
              </span>
            }
            value="high"
          />
          <Radio
            labelElement={
              <span>
                <strong>Medium</strong>: A vendor is medium risk if it is used
                to store or access non-sensitive, already-public data (e.g email
                addresses) and if failure of vendor service would have a
                non-critical impact on your business's service.
              </span>
            }
            value="medium"
          />
          <Radio
            labelElement={
              <span>
                <strong>Low</strong>: A vendor should be considered as low risk
                if it is not used to store or access data and if failure of
                vendor system would have a nominal impact on your business'
                service.
              </span>
            }
            value="low"
          />
        </RadioGroup>
      </div>
    );

    const hasPasswordPolicySet = isSome(
      this.props.vendor.specifiedPasswordPolicy
    );

    const passwordPolicyControl =
      this.state.authOption === AuthMethod.USERNAME_PASSWORD ? (
        <div>
          <h6>Password Policy</h6>
          <ControlGroup vertical={true}>
            <div className="vendor-form-checkbox">
              <Checkbox
                disabled={hasPasswordPolicySet}
                checked={(this.state.passwordMinLength ?? 0) > 0}
                label="Minimum Length"
                onChange={e => {
                  const currentMin = this.state.passwordMinLength ?? 0;
                  const previousMin =
                    this.props.vendor.passwordMinLength ?? (1 as number);
                  const newMin =
                    currentMin === 0
                      ? previousMin === 0
                        ? 1
                        : previousMin
                      : 0;
                  this.setState({
                    passwordMinLength: newMin,
                  });
                }}
              />
            </div>
            {(this.state.passwordMinLength ?? 0) > 0 ? (
              <div className="vendor-form-numeric-input">
                <NumericInput
                  disabled={hasPasswordPolicySet}
                  min={1}
                  max={256}
                  stepSize={1}
                  value={this.state.passwordMinLength ?? undefined}
                  onValueChange={val =>
                    this.setState({ passwordMinLength: val })
                  }
                />
              </div>
            ) : (
              nothing
            )}
          </ControlGroup>
          <div className="vendor-form-checkbox">
            <Checkbox
              disabled={hasPasswordPolicySet}
              checked={this.state.passwordRequiresNumber ?? false}
              label="Requires number"
              onChange={e => {
                this.setState({
                  passwordRequiresNumber: e.currentTarget.checked,
                });
              }}
            />
          </div>
          <div className="vendor-form-checkbox">
            <Checkbox
              disabled={hasPasswordPolicySet}
              checked={this.state.passwordRequiresSymbol ?? false}
              label="Requires symbol"
              onChange={e => {
                this.setState({
                  passwordRequiresSymbol: e.currentTarget.checked,
                });
              }}
            />
          </div>
        </div>
      ) : (
        nothing
      );

    const { authOption, authText } = this.state;

    const authenticationControl = (
      <div className="vendor-form-control">
        <FormGroup
          label={
            <h6>
              Authentication{" "}
              {hasPasswordPolicySet ? "(specified by vendor)" : ""}
            </h6>
          }
        >
          <HTMLSelect
            disabled={hasPasswordPolicySet}
            options={AUTH_OPTIONS}
            value={authOption}
            onChange={e => {
              this.setState({
                authOption: e.target.value as AuthOption,
              });
            }}
          />
          {authOption === AdditionalAuthOption.OTHER ? (
            <InputGroup
              disabled={hasPasswordPolicySet}
              type="text"
              placeholder="OpenID, SAML"
              value={authText}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                this.setState({ authText: e.target.value });
              }}
            />
          ) : (
            nothing
          )}
        </FormGroup>
        {passwordPolicyControl}
        {authOption !== AdditionalAuthOption.NOT_SPECIFIED ? (
          <div className="vendor-form-checkbox">
            <Checkbox
              checked={this.state.passwordMFA ?? false}
              label="Two-factor authentication enabled"
              onChange={e => {
                this.setState({
                  passwordMFA: e.currentTarget.checked,
                });
              }}
            />
          </div>
        ) : null}
      </div>
    );

    const vaqStatusDisplay =
      isSome(this.props.vendor.pendingVAQExpirationDate) ||
      this.props.vendor.submittedVAQs.length > 0 ? (
        <VendorSecurityControlsDisplay vendor={this.props.vendor} />
      ) : (
        nothing
      );

    const vaqRequestButtonWithStatus = (
      <VAQSectionContainer>
        <p>
          In the case where a vendor does not have a formal security review
          (e.g. {VENDOR_COPY.EXAMPLE_REVIEW_DOCUMENTS}), send them a security
          questionnaire below.
        </p>
        {vaqStatusDisplay}
        <RequestVAQButton vendor={this.props.vendor} />
      </VAQSectionContainer>
    );

    const assessmentFileControl = (
      <div className="vendor-form-control">
        <FormGroup
          label={<h6>Record of vendor security and data assessments</h6>}
        />
        <p>
          Upload the most recent vendor security assessment (e.g.{" "}
          {VENDOR_COPY.EXAMPLE_REVIEW_DOCUMENTS}) and data processing agreements
          (e.g. BAA) for this vendor. This is particularly important for vendors
          with which you share sensitive data.
        </p>
        <UploadDocumentsControl vendor={this.props.vendor} />
        {vaqRequestButtonWithStatus}
      </div>
    );

    const accountAdminControl = (
      <div className="vendor-form-control">
        <FormGroup label={<h6>Person responsible for vendor</h6>}>
          <UserSelector
            selected={this.state.ownerId}
            onSelect={this.onUserChange}
          />
        </FormGroup>
      </div>
    );
    const servicesProvidedControl = (
      <div className="vendor-form-control">
        <FormGroup label={<h6>Services provided</h6>}>
          <EditableText
            multiline={true}
            minLines={2}
            maxLines={5}
            value={this.state.servicesProvidedText}
            placeholder="Cloud service that stores customer support tickets"
            onChange={value => {
              this.setState({ servicesProvidedText: value });
            }}
          />
        </FormGroup>
      </div>
    );
    const dataStoredControl = (
      <div className="vendor-form-control">
        <FormGroup label={<h6>Data shared with this vendor</h6>}>
          <EditableText
            multiline={true}
            minLines={2}
            maxLines={5}
            placeholder="Support tickets with users, user email addresses"
            value={this.state.dataStoredText}
            onChange={value => {
              this.setState({ dataStoredText: value });
            }}
          />
          <Checkbox
            checked={this.state.containsUserData ?? false}
            label="User data"
            onChange={e => {
              this.setState({
                containsUserData: e.currentTarget.checked,
                severity: e.currentTarget.checked
                  ? "high"
                  : this.state.severity,
              });
            }}
          ></Checkbox>
          <FeatureGate feature={Feature.BetaHIPAA}>
            <Checkbox
              checked={this.state.sharesEPHI ?? false}
              label="Electronic protected health information (ePHI)"
              onChange={e => {
                this.setState({
                  sharesEPHI: e.currentTarget.checked,
                  severity: e.currentTarget.checked
                    ? "high"
                    : this.state.severity,
                });
              }}
            ></Checkbox>
          </FeatureGate>
          <FeatureGate feature={Feature.GDPRStandard}>
            <Checkbox
              checked={this.state.sharesEUPII ?? false}
              label="EU PII"
              onChange={e => {
                this.setState({
                  sharesEUPII: e.currentTarget.checked,
                });
              }}
            ></Checkbox>
          </FeatureGate>
        </FormGroup>
      </div>
    );
    const assessmentCommentsControl = (
      <div className="vendor-form-control">
        <FormGroup label={<h6>Comments on vendor security controls</h6>}>
          <EditableText
            multiline={true}
            minLines={2}
            maxLines={5}
            placeholder="Notes about this company's security control exceptions and the impact this may have on your company"
            value={this.state.assessmentCommentsText}
            onChange={value => {
              this.setState({ assessmentCommentsText: value });
            }}
          />
        </FormGroup>
      </div>
    );
    const otherInformationControl = (
      <div className="vendor-form-control">
        <FormGroup label={<h6>Other information</h6>}>
          <EditableText
            multiline={true}
            minLines={2}
            maxLines={5}
            placeholder="Notes about your company's relationship with this vendor."
            value={this.state.otherInformationText}
            onChange={value => {
              this.setState({ otherInformationText: value });
            }}
          />
        </FormGroup>
      </div>
    );
    const vendorContactControl = (
      <div className="vendor-form-control">
        <FormGroup label={<h6>Account manager or other point of contact</h6>}>
          <FormGroup
            label="Name"
            inline={true}
            className="vendor-form-full-width-control"
          >
            <InputGroup
              type="text"
              placeholder="Val Vanta"
              value={this.state.contactNameText}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                this.setState({ contactNameText: e.target.value });
              }}
            />
          </FormGroup>
          <FormGroup
            label="Email"
            inline={true}
            className="vendor-form-full-width-control"
          >
            <InputGroup
              type="text"
              placeholder="support@example.com"
              value={this.state.contactEmailText}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                this.setState({
                  contactEmailText: e.target.value,
                });
              }}
            />
          </FormGroup>
        </FormGroup>
      </div>
    );

    const maybeButtonMenu =
      formIsValid && formHasChanged ? (
        <div className="vendor-form-submit-footer">
          <Button
            onClick={this.onCancelButtonClicked}
            intent={Intent.NONE}
            large={true}
          >
            Cancel
          </Button>
          <Button
            onClick={this.onUpdateButtonClicked}
            intent={Intent.PRIMARY}
            large={true}
          >
            Update
          </Button>
        </div>
      ) : (
        nothing
      );
    return (
      <div style={{ padding: "20px 24px" }}>
        {maybeButtonMenu}
        {nameUrlControlWithTooltip}
        <Divider />
        {servicesProvidedControl}
        <Divider />
        {dataStoredControl}
        <Divider />
        {authenticationControl}
        <Divider />
        {accountAdminControl}
        <Divider />
        {severityControl}
        <Divider />
        {vendorContactControl}
        <Divider />
        {assessmentFileControl}
        <Divider />
        {assessmentCommentsControl}
        <Divider />
        {otherInformationControl}
      </div>
    );
  }

  private formHasBeenModified(
    vendorParams: any = this.vendorParamsFromState()
  ) {
    const { initialParams } = this.state;
    return paramsHaveChanged(vendorParams, initialParams);
  }

  private updateAndClose() {
    if (this.formIsValid()) {
      const params = this.vendorParamsFromState();
      if (this.formHasBeenModified(params)) {
        this.props.onUpdate(params);
      }

      this.setState({ isEditing: false, fileToUpload: nothing });
    } else {
      AppToaster.show({
        icon: "warning-sign",
        intent: Intent.DANGER,
        message: "Your form is missing some required fields.",
        timeout: 1500,
      });
    }
  }

  private formIsValid() {
    return (
      this.state.nameText.trim() !== "" && this.state.urlText.trim() !== ""
    );
  }
}

function paramsHaveChanged(p1: any, p2: any) {
  return Object.keys(p1).some(key => p1[key] !== p2[key]);
}

function getOrEmptyString(str: Maybe<string>) {
  return str ?? ("" as string);
}

const VAQSectionContainer = styled.div`
  margin-top: 18px;
`;

function toAuthOption(authMethod?: Maybe<string>): AuthOption {
  if (isSome(authMethod)) {
    return Object.values(AuthMethod).includes(authMethod as AuthMethod)
      ? (authMethod as AuthMethod)
      : AdditionalAuthOption.OTHER;
  }
  return AdditionalAuthOption.NOT_SPECIFIED;
}
