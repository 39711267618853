import { Button, ControlGroup, FormGroup, Intent } from "@blueprintjs/core";
import type { DateRange } from "@blueprintjs/datetime";
import { DateRangeInput } from "@blueprintjs/datetime";
import { dateStringToDate, toPossibleDate } from "common/base/dateUtils";
import { isSome } from "common/base/types/maybe";
import moment from "moment";
import React, { useState } from "react";
import styled from "styled-components";

import { VANTA_COLORS } from "../../../alpaca/base/colors";
import { BodyText } from "../../../alpaca/components";
import type { GetInfoForRiskReportQuery } from "../../../gen/components";
import { momentWithRange } from "../../../helpers/moment-with-range";
import { lastNameSort } from "../../../helpers/user-sort-functions";
import { DataTable } from "../components/data-table";
import { RiskTaskCompletionCheckbox } from "./risk-task-completion-checkbox";
import {
  TABLE_STYLES,
  TableHeading,
  TableNormalText,
  TableTitle,
  TIME_FORMAT,
} from "./table-styles";

type Task = NonNullable<
  GetInfoForRiskReportQuery["organization"]
>["riskMitigationTasks"][number];

interface IProps {
  tasks: Task[];
}

const TABLE_COLUMN_ORDER = [
  "complete",
  "task",
  "created",
  "duedate",
  "assignee",
];

const TABLE_HEADERS = {
  complete: <TableHeading></TableHeading>,
  task: <TableHeading>Task</TableHeading>,
  created: <TableHeading>Created</TableHeading>,
  duedate: <TableHeading>Due Date</TableHeading>,
  assignee: <TableHeading>Assignee</TableHeading>,
};

type TaskSortFn = (t1: Task, t2: Task) => number;

const TABLE_SORT_FUNCTIONS: { [k: string]: TaskSortFn } = {
  task: (task1, task2) => task1.description.localeCompare(task2.description),
  created: (task1, task2) =>
    moment(task1.createdAt).valueOf() - moment(task2.createdAt).valueOf(),
  duedate: (task1, task2) =>
    moment(task1.dueDate ?? undefined).valueOf() -
    moment(task2.dueDate ?? undefined).valueOf(),
  assignee: (task1, task2) =>
    lastNameSort(
      task1.assignee ?? { displayName: "No user assigned" },
      task2.assignee ?? { displayName: "No user assigned" }
    ),
};

const TABLE_COLUMN_WIDTHS = ["60px", "580px", "100px", "100px", "180px"];

const rowCreator = (task: Task) => {
  const taskIsOpen = !isSome(task.completedAt);
  return {
    complete: <RiskTaskCompletionCheckbox task={task} />,
    task: (
      <TableNormalText active={taskIsOpen}>{task.description}</TableNormalText>
    ),
    created: (
      <TableNormalText>
        {moment(task.createdAt).format(TIME_FORMAT)}
      </TableNormalText>
    ),
    duedate: (
      <TableNormalText
        alert={
          taskIsOpen && moment(task.dueDate ?? undefined).isBefore(moment())
        }
      >
        {moment(task.dueDate ?? undefined).format(TIME_FORMAT)}
      </TableNormalText>
    ),
    assignee: <TableNormalText>{task.assignee?.displayName}</TableNormalText>,
  };
};

export const RiskTaskTable: React.FC<IProps> = ({ tasks }) => {
  const [selectedDateRange, setDateRange] = useState<DateRange>([null, null]);
  const hasDate = isSome(selectedDateRange[0]) && isSome(selectedDateRange[1]);

  const dateRangeInput = (
    <FormGroup>
      <ControlGroup>
        <DateRangeInput
          startInputProps={{
            placeholder: "Filter by date",
          }}
          endInputProps={{
            style:
              !isSome(selectedDateRange[0]) && !isSome(selectedDateRange[1])
                ? {
                    visibility: "hidden",
                  }
                : undefined,
          }}
          value={selectedDateRange}
          onChange={setDateRange}
          formatDate={date => moment(date).format(TIME_FORMAT)}
          parseDate={dateStr => new Date(dateStr)}
          shortcuts={false}
        />
        {hasDate ? (
          <Button
            minimal
            onClick={() => setDateRange([null, null])}
            intent={Intent.PRIMARY}
          >
            Clear Dates
          </Button>
        ) : null}
      </ControlGroup>
    </FormGroup>
  );

  const dateFilterFn = hasDate
    ? (task: Task) => {
        const dateRange = momentWithRange.range(
          selectedDateRange[0]!,
          selectedDateRange[1]!
        );
        const taskRange = momentWithRange.range(
          dateStringToDate(task.createdAt),
          toPossibleDate(task.dueDate) ?? new Date()
        );
        return dateRange.overlaps(taskRange);
      }
    : () => true;

  const table =
    tasks.filter(dateFilterFn).length > 0 ? (
      <DataTable
        data={tasks}
        header={TABLE_HEADERS}
        columnOrder={TABLE_COLUMN_ORDER}
        columnSortFunctions={TABLE_SORT_FUNCTIONS}
        columnWidths={TABLE_COLUMN_WIDTHS}
        createRow={rowCreator}
        rowClass={task =>
          isSome(task.completedAt) ? "risk-task-row-complete" : undefined
        }
      />
    ) : (
      <BodyText color={VANTA_COLORS.TEXT_DESCRIPTION}>
        No tasks have been created{hasDate ? " for the selected range" : ""}
      </BodyText>
    );

  return (
    <Container>
      <TableTitle>Tasks</TableTitle>
      {dateRangeInput}
      {table}
    </Container>
  );
};

const Container = styled.div`
  table {
    ${TABLE_STYLES}
    tr.risk-task-row-complete {
      background-color: #fbfbfb;
    }
  }
`;
