import { Intent } from "@blueprintjs/core";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import React from "react";

import { BASE_PALETTE } from "../../../alpaca/base/colors";
import { GRID_SPACING } from "../../../alpaca/base/grid";
import { BodyText, H4, RadioGroup } from "../../../alpaca/components";
import { LogError } from "../../../errors";
import type { GetCompanyNotificationSettingsQuery } from "../../../gen/components";
import {
  GetCompanyNotificationSettingsDocument,
  NotificationFrequency,
  useGetCompanyNotificationSettingsQuery,
  useSetEmployeeDigestFrequencyMutation,
} from "../../../gen/components";
import { AppToaster } from "../../../helpers/toaster";
import { FullPageSpinner } from "../../helpers/FullPageSpinner";
import { VantaDashboardPage } from "../../vanta-chrome/page-content/vanta-dashboard-page";

export const ManageNotificationsPage: React.FC = () => {
  const { loading, error, data } = useGetCompanyNotificationSettingsQuery();
  const [setEmployeeDigestFrequency] = useSetEmployeeDigestFrequencyMutation({
    onCompleted: () =>
      AppToaster.show({
        message: "Notification settings updated",
        intent: Intent.SUCCESS,
        timeout: 2500,
      }),
    update: (cache, result) => {
      const mutationResult =
        result.data?.setEmployeeDigestNotificationFrequency;
      if (
        !isSome(mutationResult) ||
        mutationResult.__typename !==
          "SetEmployeeDigestNotificationFrequencySuccess"
      ) {
        return;
      }
      const oldOrganization =
        cache.readQuery<GetCompanyNotificationSettingsQuery>({
          query: GetCompanyNotificationSettingsDocument,
        })?.organization;
      if (!isSome(oldOrganization)) {
        return;
      }
      cache.writeQuery<GetCompanyNotificationSettingsQuery>({
        query: GetCompanyNotificationSettingsDocument,
        data: {
          organization: {
            ...oldOrganization,
            notificationSettings: {
              employeeDigestFrequency:
                mutationResult.notificationSettings?.employeeDigestFrequency,
            },
          },
        },
      });
    },
  });

  if (loading) {
    return <FullPageSpinner />;
  }

  if (error || !isSome(data)) {
    LogError(error ?? new Error("Bad fetch on ManageNotificationsPage"));
    return null;
  }

  const { employeeDigestFrequency } = data.organization
    .notificationSettings ?? { employeeDigestFrequency: null };

  return (
    <VantaDashboardPage
      headingInfo={{ title: "Notifications", description: "" }}
    >
      <H4 style={{ marginBottom: GRID_SPACING }}>Employee Email Digests</H4>
      <BodyText color={BASE_PALETTE.CHARCOAL}>
        Emails are sent to your organization's employees and contain a list of
        tasks assigned to them.
      </BodyText>
      <BodyText>Choose your frequency</BodyText>
      <RadioGroup
        name="frequency"
        options={[
          { value: NotificationFrequency.WEEKLY, label: "Weekly" },
          { value: NotificationFrequency.DAILY, label: "Daily" },
          { value: NotificationFrequency.NEVER, label: "Never" },
        ]}
        selectedValue={employeeDigestFrequency ?? NotificationFrequency.NEVER}
        onChange={async e => {
          await setEmployeeDigestFrequency({
            variables: {
              frequency: e.currentTarget.value as NotificationFrequency,
            },
          }).catch(LogError);
        }}
      />
    </VantaDashboardPage>
  );
};

gql`
  query getCompanyNotificationSettings {
    organization {
      id
      notificationSettings {
        employeeDigestFrequency
      }
    }
  }
`;

gql`
  mutation setEmployeeDigestFrequency($frequency: NotificationFrequency!) {
    setEmployeeDigestNotificationFrequency(input: { frequency: $frequency }) {
      __typename
      ... on SetEmployeeDigestNotificationFrequencySuccess {
        notificationSettings {
          employeeDigestFrequency
        }
      }
    }
  }
`;
