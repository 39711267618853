import { Callout, Classes, Intent, Tab } from "@blueprintjs/core";
import type { GcpCredentialValidationOutcome } from "common/base/types/gen";
import type { Maybe } from "common/base/types/maybe";
import { isSome, nothing } from "common/base/types/maybe";
import React from "react";

import { Tabs } from "../../../../alpaca/components";
import { LogError } from "../../../../errors";
import {
  AllLinkedCredentialsDocument,
  useSetCredentialsMutation,
} from "../../../../gen/components";
import { DefaultLink } from "../../../../helpers/links";
import { AppToaster } from "../../../../helpers/toaster";
import type { IFormCredentialProps } from "../services/common-interface";
import {
  GCPConsoleLinkingInstructions,
  GCPScriptLinkingInstructions,
} from "./gcp-linking-instructions";
import { ValidateGCPButton } from "./validate-gcp-button";

export const GCP: React.FunctionComponent<IFormCredentialProps> = ({
  domain,
  credential,
  onCredentialsLinked,
}) => {
  const metadata = credential?.metadata;
  const [projectIds, _setProjectIds] = React.useState(
    isSome(metadata) ? JSON.parse(metadata) : []
  );
  // Wrap _setProjectIds in some string sanitization.
  const setProjectIds = (newProjectIds: string[]) => {
    const cleaned = Array.from(new Set(newProjectIds.map(v => v.trim())));
    _setProjectIds(cleaned);
  };

  const [keyData, setKeyData] = React.useState<{
    clientEmail: Maybe<string>;
    privateKey: Maybe<string>;
    serviceAccountProjectId: Maybe<string>;
  }>({
    clientEmail: undefined,
    privateKey: undefined,
    serviceAccountProjectId: undefined,
  });

  const hasAllUserInputs =
    isSome(projectIds) &&
    projectIds.length > 0 &&
    isSome(keyData.clientEmail) &&
    isSome(keyData.privateKey) &&
    isSome(keyData.serviceAccountProjectId);

  const domainId = domain.id;

  // Helpers for managing the validation to scan to close-dialog flow.
  const [validationOutcome, setValidationOutcome] =
    React.useState<Maybe<GcpCredentialValidationOutcome>>(nothing);

  const [saveCredential] = useSetCredentialsMutation({
    variables: {
      service: "gcp",
      credentials: JSON.stringify({
        projectIds: JSON.stringify(projectIds),
        ...keyData,
      }),
    },
    onCompleted: onCredentialsLinked,
    refetchQueries: [{ query: AllLinkedCredentialsDocument }],
  });

  React.useEffect(() => {
    if (isSome(validationOutcome) && validationOutcome.type === "Clean") {
      AppToaster.show({
        intent: Intent.SUCCESS,
        message: "GCP credentials linked and validated",
      });
      saveCredential().catch(LogError);
    }
  }, [validationOutcome]);

  const serviceAccountInUnmonitoredProject =
    isSome(keyData.serviceAccountProjectId) &&
    !projectIds.includes(keyData.serviceAccountProjectId);

  return (
    <div>
      <div className={Classes.DIALOG_HEADER}>Link Google Cloud Platform</div>
      <div className={Classes.DIALOG_BODY}>
        {serviceAccountInUnmonitoredProject ? (
          <Callout intent={Intent.DANGER}>
            The project <code>{keyData.serviceAccountProjectId}</code> hosting
            this service account must be in the list of projects to monitor.
          </Callout>
        ) : null}
        <GCPCredentialsValidationCallout outcome={validationOutcome} />
        <Tabs id="gcp-cred-tabs" defaultSelectedTabId="gcloud-instructions">
          <Tab
            title="gcloud"
            id="gcloud-instructions"
            panel={
              <GCPScriptLinkingInstructions
                projectIds={projectIds}
                setProjectIds={setProjectIds}
                setKeyData={setKeyData}
              />
            }
          />
          <Tab
            title="Console"
            id="console-instructions"
            panel={
              <GCPConsoleLinkingInstructions
                projectIds={projectIds}
                setProjectIds={setProjectIds}
                setKeyData={setKeyData}
              />
            }
          />
        </Tabs>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <ValidateGCPButton
            domainId={domainId}
            validatable={
              hasAllUserInputs && !serviceAccountInUnmonitoredProject
            }
            handleValidationResult={outcome => {
              setValidationOutcome(outcome);
            }}
            inputValues={{
              projectIds: JSON.stringify(projectIds),
              ...keyData,
            }}
          />
        </div>
      </div>
    </div>
  );
};

const GCPCredentialsValidationCallout: React.FunctionComponent<{
  outcome: Maybe<GcpCredentialValidationOutcome>;
}> = ({ outcome }) => {
  if (!isSome(outcome)) {
    return <div />;
  }
  const disabledApiNameToUrl = (projectId: string, api: string) =>
    `https://console.developers.google.com/apis/library/${api}?project=${projectId}`;
  switch (outcome.type) {
    case "Clean":
      return (
        <Callout
          intent={Intent.SUCCESS}
          title="These credentials are configured correctly"
        />
      );
    case "ServiceAccountProjectNotMonitoredError":
      return (
        <Callout
          intent={Intent.DANGER}
          title="The project hosting the GCP service account you're trying to connect is not included in the list of projects to monitor"
        >
          <p>
            Please ensure that you've included the id of the project containing
            this service account (
            {outcome.missingResourcesByProject[0].projectId}).
          </p>
        </Callout>
      );
    case "OrgPermissionsError":
      // Assume all of the projects are in the same org; just use the outcome
      // from the first project that had this issue.
      return (
        <Callout
          intent={Intent.DANGER}
          title="The GCP service account you're trying to connect has insufficient permissions"
        >
          <p>
            In the organization containing{" "}
            {outcome.missingResourcesByProject[0].projectId}, this service
            account is missing the following permissions:
          </p>
          <ul>
            {outcome.missingResourcesByProject[0].ids.map(permissionId => (
              <li key={permissionId}>
                <code>{permissionId}</code>
              </li>
            ))}
          </ul>
          <p>
            Please ensure that you've created the role documented in step 4 and
            assigned it, at the organization level, to the GCP service account
            you're trying to connect.
          </p>
        </Callout>
      );
    case "ProjectPermissionsError":
      return (
        <Callout
          intent={Intent.DANGER}
          title="The GCP service account you're trying to connect has insufficient permissions"
        >
          <p>
            This service account is missing the following permissions in these
            GCP projects:
          </p>
          <ul>
            {outcome.missingResourcesByProject.map(projectOutcome => (
              <li key={projectOutcome.projectId}>
                {projectOutcome.projectId}
                <ul>
                  {projectOutcome.ids.map(permissionId => (
                    <li key={permissionId}>
                      <code>{permissionId}</code>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
          <p>
            Please ensure that both <code>VantaProjectScanner</code> and{" "}
            <code>roles/iam.securityReviewer</code> are assigned to the service
            account you're trying to link in each of the projects you've listed.
          </p>
        </Callout>
      );
    case "APINotEnabledError":
      if (outcome.missingResourcesByProject.length === 0) {
        throw Error("Internal Server Error: validation failure data missing");
      } else if (outcome.missingResourcesByProject.length === 1) {
        // There's a single problematic project.
        const projectData = outcome.missingResourcesByProject[0];
        return (
          <Callout
            intent={Intent.DANGER}
            title="A required GCP API is disabled"
          >
            <p>
              Multiple APIs required for us to connect to GCP are disabled in{" "}
              {projectData.projectId}.
            </p>
            <p>Enable each API:</p>
            <ul>
              {projectData.ids.map(apiId => (
                <li key={`${projectData.projectId}-${apiId}`}>
                  <DefaultLink
                    href={disabledApiNameToUrl(projectData.projectId, apiId)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {apiId}
                  </DefaultLink>
                </li>
              ))}
            </ul>
          </Callout>
        );
      } else {
        return (
          <Callout
            intent={Intent.DANGER}
            title="A required GCP API is disabled"
          >
            <p>Multiple APIs required for us to connect to GCP are disabled.</p>
            <p>Enable each API:</p>
            <ul>
              {outcome.missingResourcesByProject.map(projectData => (
                <li key={projectData.projectId}>
                  {projectData.projectId}
                  <ul>
                    {projectData.ids.map(apiId => (
                      <li key={`${projectData.projectId}-${apiId}`}>
                        <DefaultLink
                          href={disabledApiNameToUrl(
                            projectData.projectId,
                            apiId
                          )}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {apiId}
                        </DefaultLink>
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </Callout>
        );
      }
    default:
      throw new Error(
        "Internal server error: unexpected GCP credential validation outcome type."
      );
  }
};
