import { ButtonGroup, Icon } from "@blueprintjs/core";
import type { DiscriminateUnion } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import React from "react";
import styled from "styled-components";

import { GRID_SPACING } from "../../../../alpaca/base/grid";
import { Tooltip } from "../../../../alpaca/components";
import type {
  EmployeeComputerItem,
  LinuxServerItem,
} from "./ssh-access-tables";

type AuthorizedKey =
  | NonNullable<
      DiscriminateUnion<
        EmployeeComputerItem,
        {
          __typename: "macosWorkstationData" | "linuxWorkstationData";
        }
      >["authorizedKeys"]
    >[number]
  | NonNullable<
      DiscriminateUnion<
        LinuxServerItem,
        { __typename: "linuxServerData" }
      >["authorizedKeys"]
    >[number];

type UserKey =
  | NonNullable<
      DiscriminateUnion<
        EmployeeComputerItem,
        { __typename: "macosWorkstationData" | "linuxWorkstationData" }
      >["sshKeys"]
    >[number]
  | NonNullable<
      DiscriminateUnion<
        LinuxServerItem,
        { __typename: "linuxServerData" }
      >["sshKeys"]
    >[number];

type TSuggestion =
  | "encrypt"
  | "noSuggestions"
  | "needPublicKey"
  | "otherError"
  | "useBetterAlgorithm"
  | "tooManyMachines";

/**
 * Warn if an SSH key is used to authenitcate to more than this many
 * machines. Not necessarily a bad thing, but shouldn't be using the same
 * key everywhere.
 */
const SSH_MACHINE_COUNT_WARNING = 10;

const getAuthorizedKeysSuggestions = (
  keyInfo: AuthorizedKey
): TSuggestion[] => {
  const suggestions: TSuggestion[] = [];
  if (keyInfo.sha256_fingerprint.length === 0) {
    suggestions.push("otherError");
  }
  if (keyInfo.key_type === "ssh-dsa") {
    suggestions.push("useBetterAlgorithm");
  }
  // maybe eventually an error if numMachinesUsing is 0 – suggests that there
  // is a key in authorized-keys that we don't know about.
  return suggestions.length > 0 ? suggestions : ["noSuggestions"];
};

const getUserSSHKeysSuggestions = (keyInfo: UserKey): TSuggestion[] => {
  const suggestions: TSuggestion[] = [];
  // for now, don't complain if ed25519 keys aren't encrypted. When we push out a version of osquery with
  // https://github.com/facebook/osquery/pull/5526
  if (!keyInfo.encrypted && !(keyInfo.key_type === "ssh-ed25519")) {
    suggestions.push("encrypt");
  }
  if (keyInfo.sha256_fingerprint.length === 0) {
    if (keyInfo.encrypted) {
      suggestions.push("needPublicKey");
    } else {
      suggestions.push("otherError");
    }
  }
  if (keyInfo.key_type === "ssh-dsa") {
    suggestions.push("useBetterAlgorithm");
  }
  return suggestions.length > 0 ? suggestions : ["noSuggestions"];
};

interface IProps {
  keyInfo: AuthorizedKey | UserKey;
}

export const SshSuggestionIcons: React.FC<IProps> = ({ keyInfo }) => {
  const iconFromSuggestion = {
    noSuggestions: (
      <Tooltip content="Looks good!" placement="right" key="nosuggestions">
        <Icon icon="tick-circle" color="green" iconSize={Icon.SIZE_LARGE} />
      </Tooltip>
    ),
    encrypt: (
      <Tooltip
        content="Vanta recommends that you encrypt your private keys."
        placement="right"
        key="encrypt"
      >
        <Icon icon="key" color="orange" iconSize={Icon.SIZE_LARGE} />
      </Tooltip>
    ),
    needPublicKey: (
      <Tooltip
        content={
          <TooltipContent>
            <p>
              Vanta couldn't find a public key for this encrypted private key.
            </p>
            <p>
              To let Vanta know which machines this key can access, you can
              generate its public key using OpenSSH:
            </p>
            <p>
              <code>
                ssh-keygen -y -f {(keyInfo as UserKey).path} {"> "}
                {(keyInfo as UserKey).path}
                .pub
              </code>
            </p>
          </TooltipContent>
        }
        placement="right"
        key="needpublickey"
        rootBoundary="document"
      >
        <Icon icon="wrench" color="red" iconSize={Icon.SIZE_LARGE} />
      </Tooltip>
    ),
    otherError: (
      <Tooltip
        content={
          <TooltipContent>
            <p>
              Vanta encountered an error when trying to fingerprint your key:
            </p>
            <p>{keyInfo.error}</p>
          </TooltipContent>
        }
        placement="right"
        key="othererror"
        rootBoundary="document"
      >
        <Icon icon="wrench" color="red" iconSize={Icon.SIZE_LARGE} />
      </Tooltip>
    ),
    useBetterAlgorithm: (
      <Tooltip
        content="This key was generated using an insecure algorithm and should no longer be used."
        placement="right"
        key="usebetteralgorithm"
      >
        <Icon icon="unlock" color="red" iconSize={Icon.SIZE_LARGE} />
      </Tooltip>
    ),
    tooManyMachines: (
      <Tooltip
        content={
          <TooltipContent>
            <p>
              This key is used on over {SSH_MACHINE_COUNT_WARNING} machines.
            </p>
            <p>
              Vanta recommends that you use different keys for different
              services"
            </p>
          </TooltipContent>
        }
        placement="right"
        key="toomanymachines"
        rootBoundary="document"
      >
        <Icon icon="unlock" color="red" iconSize={Icon.SIZE_LARGE} />
      </Tooltip>
    ),
  };
  const suggestions =
    "encrypted" in keyInfo
      ? getUserSSHKeysSuggestions(keyInfo)
      : getAuthorizedKeysSuggestions(keyInfo);
  const error = suggestions.find(s => s === "otherError");
  const suggestionIcons = (isSome(error) ? [error] : suggestions).map(
    suggestion => iconFromSuggestion[suggestion]
  );
  return <ButtonGroup>{suggestionIcons}</ButtonGroup>;
};

const TooltipContent = styled.div`
  max-width: ${70 * GRID_SPACING}px;
`;
