import "./github-linking-instructions.scss";

import type { IButtonProps, IPanel, IPanelProps } from "@blueprintjs/core";
import { AnchorButton, Button, Intent, PanelStack } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React from "react";

import { DefaultLink } from "../../../../helpers/links";
import type { IOAuthCredentialProps } from "./common-interface";

const OrgSettingScreenshot = require("url:../../../../static/images/services/github-instructions/GitHubOrgList.png");

type IPanelInternal = IPanel<IOAuthCredentialProps>;

export const GithubLinkingInstructions: React.FC<IOAuthCredentialProps> =
  () => (
    <div className="gli-stack-container">
      <PanelStack initialPanel={VerifyOrgOwnerPanel} />
    </div>
  );

const NextPanelButton: React.FC<
  IPanelProps & {
    panel: IPanelInternal;
  } & IButtonProps
> = props => (
  <Button
    intent={props.intent ?? Intent.PRIMARY}
    fill={props.fill}
    minimal={props.minimal}
    onClick={() => props.openPanel(props.panel)}
  >
    {props.children}
  </Button>
);

const LinkGithubButton: React.FC = () => (
  <AnchorButton
    target="_blank"
    rel="noopener noreferrer"
    href={`/auth/login/github`}
    icon={IconNames.LOG_IN}
  >
    Connect GitHub
  </AnchorButton>
);

const VerifyOrgOwnerPanel: IPanelInternal = {
  title: "Link your GitHub organization",
  component: props => (
    <div className="gli-panel-body">
      <p>Let's get started linking your GitHub organization.</p>
      <p>
        First, verify that you are an owner of the GitHub organization housing
        your company's codebase.
      </p>
      <p>
        An easy way to check if your account is an organization owner is to
        visit&nbsp;
        <DefaultLink href="https://github.com/settings/organizations">
          your account organizations page
        </DefaultLink>
        &nbsp;and verify the organization is listed{" "}
        <DefaultLink
          href={OrgSettingScreenshot}
          target="_blank"
          rel="noopener noreferrer"
        >
          (sample screenshot)
        </DefaultLink>
      </p>
      <NextPanelButton panel={BeginAuthPanel} {...props} fill>
        Continue
      </NextPanelButton>
    </div>
  ),
};

const BeginAuthPanel: IPanelInternal = {
  title: "Grant organization access",
  component: props => (
    <div className="gli-panel-body">
      <p>
        Clicking the "Connect" button below will request authorization from
        GitHub. This will open in a new tab.
      </p>
      <LinkGithubButton />
      <p>
        You should see a list of yourself and your organizations. If you don't
        see your company's GitHub organization, make sure that your account is
        an organization owner.
      </p>
      <p>
        Once you've selected your company's GitHub organization, you can select
        which repositories Vanta will monitor.
      </p>
      <ol>
        <li>
          If you select <strong>All repositories</strong>, Vanta will
          automatically monitor all repositories created within the
          organization, including those created in the future.
        </li>
        <li>
          If you select <strong>Only selected repositories</strong>, Vanta will
          only monitor those you have selected. Vanta will not know about new
          repositories unless you update this setting.
        </li>
        <li>
          The set of monitored repositories can be updated at any time through
          GitHub.
        </li>
      </ol>
      <p>After granting authorization, you'll be returned to Vanta.</p>
    </div>
  ),
};
