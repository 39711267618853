import { Button, Intent } from "@blueprintjs/core";
import React from "react";
import type { RouteComponentProps } from "react-router-dom";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

import { Dimensions } from "../vanta-chrome/constants";

const NotFound404Component: React.FunctionComponent<RouteComponentProps> = ({
  history,
}) => (
  <Container className="flex-center-container">
    <div className="flex-center-content">
      <h2 className="not-found-heading">Page not found</h2>
      <Button intent={Intent.PRIMARY} onClick={() => history.push("/")}>
        Go to the home page
      </Button>
    </div>
  </Container>
);

const Container = styled.div`
  width: 100%;
  height: calc(100vh-${Dimensions.TOP_BAR_HEIGHT});
`;

export const NotFound404 = withRouter(NotFound404Component);
