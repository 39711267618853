import { FormGroup, H5, HTMLTable } from "@blueprintjs/core";
import { isSome } from "common/base/types/maybe";
import React from "react";
import styled from "styled-components";

import { HelperText } from "../../../../alpaca/components";
import type {
  EmployeeComputerItem,
  LinuxServerItem,
} from "./ssh-access-tables";
import { SshSuggestionIcons } from "./ssh-suggestions";

export const FullWidthFormGroup = styled(FormGroup)`
  width: 100%;
`;

export const SshConfigTables: React.FC<{
  data: EmployeeComputerItem | LinuxServerItem;
}> = ({ data }) => {
  if (!("authorizedKeys" in data)) {
    return null;
  }

  const authorizedKeysTable = () => {
    if (!isSome(data.authorizedKeys) || data.authorizedKeys.length === 0) {
      return null;
    }
    const tableRows = data.authorizedKeys.map((keyInfo, i) => (
      <tr key={i}>
        <td>{keyInfo.key_file}</td>
        <td>
          <SshSuggestionIcons keyInfo={keyInfo} />
        </td>
        <td>{keyInfo.comment}</td>
        <td>{keyInfo.algorithm}</td>
        <td>{keyInfo.key_type}</td>
      </tr>
    ));
    return (
      <div>
        <H5>Authorized Keys</H5>
        <HTMLTable
          striped={true}
          interactive={false}
          className="inventory-list-card-table"
        >
          <thead>
            <tr>
              <th>Path and Fingerprint</th>
              <th>Suggestions</th>
              <th>Comment</th>
              <th>Algorithm</th>
              <th>Key Type</th>
            </tr>
          </thead>
          <tbody>{tableRows}</tbody>
        </HTMLTable>
      </div>
    );
  };

  const userKeysTable = () => {
    if (!isSome(data.sshKeys) || data.sshKeys.length === 0) {
      return null;
    }
    const tableRows = data.sshKeys.map((keyInfo, i) => (
      <tr key={i}>
        <td>
          <div>{keyInfo.path}</div>
          <HelperText as="div">{keyInfo.sha256_fingerprint}</HelperText>
        </td>
        <td>
          <SshSuggestionIcons keyInfo={keyInfo} />
        </td>
        <td>{keyInfo.comment}</td>
        <td>
          {keyInfo.encrypted
            ? "YES"
            : keyInfo.sha256_fingerprint.length === 0
            ? "N/A"
            : keyInfo.key_type === "ssh-ed25519"
            ? "UNKNOWN"
            : "NO"}
        </td>
        <td>{keyInfo.key_type}</td>
      </tr>
    ));
    return (
      <div>
        <H5>SSH Keys</H5>
        <HTMLTable
          striped={true}
          interactive={false}
          className="inventory-list-card-table"
        >
          <thead>
            <tr>
              <th>Path and Fingerprint</th>
              <th>Suggestions</th>
              <th>Comment</th>
              <th>Encrypted?</th>
              <th>Key Type</th>
            </tr>
          </thead>
          <tbody>{tableRows}</tbody>
        </HTMLTable>
      </div>
    );
  };

  return (
    <div>
      {authorizedKeysTable()}
      {userKeysTable()}
    </div>
  );
};
