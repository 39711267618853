import { Spinner } from "@blueprintjs/core";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { BodyText } from "../../../../alpaca/components";
import { LogError } from "../../../../errors";
import { useFetchEc2InstancesWithoutInspectorQuery } from "../../../../gen/components";
import {
  AgentViewBy,
  Link,
  parseAgentViewBy,
  TabContainer,
  TabDescription,
} from "../common/components";
import { AWSInspectorMonitoredServerList } from "./aws-inspector-monitored-server-list";
import { awsInspectorSetupInstructionsUrl } from "./constants";
import { UnmonitoredInstancesCallout } from "./unmonitored-instance-callout";

// Separate callout elements by 15px
const CalloutsWrapper = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 15px;
  }
`;

export const AWSInspectorVulnDisplay: React.FC = () => {
  const [viewBy, setViewBy] = useState(AgentViewBy.SERVER);

  const {
    error: error,
    loading: loading,
    data: data,
  } = useFetchEc2InstancesWithoutInspectorQuery({});

  useEffect(() => {
    setViewBy(parseAgentViewBy());
  }, [location.hash]);

  if (error) {
    LogError(error);
    return <div />;
  }

  if (loading || !isSome(data)) {
    return <Spinner />;
  }

  const numUnmonitoredInstances =
    data?.organization?.instancesWithoutInspector?.totalCount ?? 0;

  return (
    <>
      <TabDescription>
        <BodyText>
          Monitor your EC2 instances for package vulnerabilities detected by AWS
          Inspector.
        </BodyText>
      </TabDescription>
      <TabContainer>
        <CalloutsWrapper>
          {numUnmonitoredInstances > 0 ? (
            <UnmonitoredInstancesCallout
              instanceCount={numUnmonitoredInstances}
            />
          ) : (
            <Link
              onClick={() => {
                window.open(awsInspectorSetupInstructionsUrl, "_blank");
              }}
            >
              View AWS Inspector setup instructions
            </Link>
          )}
        </CalloutsWrapper>

        {/* TODO: fix up when implementing packages view */}
        {viewBy === AgentViewBy.SERVER ? (
          <AWSInspectorMonitoredServerList
            numUnmonitoredInstances={numUnmonitoredInstances}
          />
        ) : null}
        {viewBy === AgentViewBy.PACKAGE ? (
          // <AWSInspectorPackageVulnerabilitiesList />
          <AWSInspectorMonitoredServerList
            numUnmonitoredInstances={numUnmonitoredInstances}
          />
        ) : null}
      </TabContainer>
    </>
  );
};

gql`
  query fetchEC2InstancesWithoutInspector {
    organization {
      id
      instancesWithoutInspector: resources(
        specificResourceType: EC2Instance
        first: 0
        filterParams: {
          operator: AND
          filters: [{ field: "inspector", condition: { IS_NULL: true } }]
        }
      ) {
        totalCount
      }
    }
  }
`;
