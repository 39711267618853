import { dropNothing } from "common/base/types/maybe";
import React from "react";

import { LogError, LogErrorMessage } from "../../../errors";
import {
  useFetchAwsSubnetInfoQuery,
  useFetchGcpSubnetInfoQuery,
} from "../../../gen/components";
import { FullPageSpinner } from "../../helpers/FullPageSpinner";
import {
  PageHeadingInfo,
  VantaDashboardPage,
} from "../../vanta-chrome/page-content/vanta-dashboard-page";
import { DataTable } from "../components/data-table";
import { Container } from "./helpers";
import { awsDataTableParameters, processAwsData } from "./subnets/aws";
import { gcpDataTableParameters, processGcpData } from "./subnets/gcp";

const SUBNET_SERVICES = ["aws", "gcp"] as const;

const serviceToParameters = {
  aws: {
    dataTableParameters: awsDataTableParameters,
    processData: processAwsData,
    query: useFetchAwsSubnetInfoQuery,
  },
  gcp: {
    dataTableParameters: gcpDataTableParameters,
    processData: processGcpData,
    query: useFetchGcpSubnetInfoQuery,
  },
};

export const ManageSubnetSecurityPage: React.FC = () => {
  const tables = SUBNET_SERVICES.map(service => {
    const { error, loading, data } = serviceToParameters[service].query();
    if (error) {
      LogError(error);
      return {
        status: "error",
        table: null,
      };
    }
    if (loading) {
      return {
        status: "loading",
        table: null,
      };
    }
    if (!data) {
      LogErrorMessage("Bad fetch");
      return {
        status: "error",
        table: null,
      };
    }

    const subnetData: any[] = serviceToParameters[service].processData(
      data.organization as any
    );
    if (subnetData.length === 0) {
      return {
        status: "success",
        table: null,
      };
    }

    const { columnOrder, displayName, header, createRow } =
      serviceToParameters[service].dataTableParameters;

    return {
      status: "success",
      table: (
        <Container key={service}>
          <h3>{displayName}</h3>
          <DataTable
            key={service}
            columnOrder={columnOrder}
            createRow={createRow}
            data={subnetData}
            header={header}
            emptyDefault={"No data available"}
          />
        </Container>
      ),
    };
  });
  if (!tables.some(t => t.status === "success")) {
    return <FullPageSpinner />;
  }
  return (
    <VantaDashboardPage headingInfo={PageHeadingInfo.SUBNETS}>
      {dropNothing(tables.map(t => t.table))}
    </VantaDashboardPage>
  );
};
