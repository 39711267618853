import { HTMLTable, Spinner } from "@blueprintjs/core";
import gql from "graphql-tag";
import React from "react";

import { LogError } from "../../../../errors";
import {
  SshInboundAccessFragmentDoc,
  SshOutboundAccessFragmentDoc,
  useAccessibleMachinesQuery,
} from "../../../../gen/components";

export const UserKeysTable: React.FC<{ osqueryId: string }> = ({
  osqueryId,
}) => {
  const { loading, error, data } = useAccessibleMachinesQuery({
    variables: { endpointId: osqueryId },
  });

  if (loading) {
    return <Spinner />;
  }

  const endpoint = data?.organization.endpoints[0];

  if (error || !data || !endpoint || !("sshKeys" in endpoint.data)) {
    LogError(error ?? Error("no data found"));
    return <div>Error fetching keys</div>;
  }

  const table = (
    <HTMLTable
      striped={true}
      interactive={false}
      className="inventory-list-card-table"
    >
      <thead>
        <tr>
          <th>Access to</th>
          <th>Via key at path</th>
          <th>Encrypted?</th>
        </tr>
      </thead>
      <tbody>
        {endpoint.data.sshKeys?.flatMap((key, ixK) =>
          key.machinesThisCanAccess?.edges.map((machine, ixM) => (
            <tr key={`${ixK}_${ixM}`}>
              <td>{machine.node.prettyName}</td>
              <td>{key.path}</td>
              <td>
                {key.encrypted
                  ? "YES"
                  : key.key_type === "ssh-ed25519"
                  ? "UNKNOWN"
                  : "NO"}
              </td>
            </tr>
          ))
        )}
      </tbody>
    </HTMLTable>
  );

  return table;
};

gql`
  query accessibleMachines($endpointId: String!) {
    organization {
      id
      endpoints(activeOnly: true, endpointIds: [$endpointId]) {
        id
        data {
          id
          ... on linuxWorkstationData {
            sshKeys {
              ...sshOutboundAccess
            }
            authorizedKeys {
              ...sshInboundAccess
            }
          }
          ... on linuxServerData {
            sshKeys {
              ...sshOutboundAccess
            }
            authorizedKeys {
              ...sshInboundAccess
            }
          }
          ... on macosWorkstationData {
            sshKeys {
              ...sshOutboundAccess
            }
            authorizedKeys {
              ...sshInboundAccess
            }
          }
        }
      }
    }
  }
  ${SshOutboundAccessFragmentDoc}
  ${SshInboundAccessFragmentDoc}
`;

// Just take the first 10 for now; if totalCount is more we can consider adding lazy loading.
gql`
  fragment sshOutboundAccess on sshUserKey {
    key_type
    path
    encrypted
    sha256_fingerprint
    machinesThisCanAccess(first: 10) {
      edges {
        node {
          id
          prettyName
        }
      }
    }
  }
`;

// Just take the first 10 for now; if totalCount is more we can consider adding lazy loading.
gql`
  fragment sshInboundAccess on sshAuthorizedKey {
    key_type
    key_file
    sha256_fingerprint
    machinesWithAccessToThisOne(first: 10) {
      edges {
        node {
          id
          prettyName
        }
      }
    }
  }
`;
