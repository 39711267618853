import type { AvPolicy } from "common/base/types/gen";
import { SpecificResource } from "common/base/types/gen";
import type { Maybe } from "common/base/types/maybe";
import { isSome, nothing } from "common/base/types/maybe";
import {
  getExternallyManagedVantaAttributes,
  ReservedVantaAttributes,
} from "common/utils/vantaAttributes";
import gql from "graphql-tag";

import type { FetchDomainEndpointsQuery } from "../../../gen/components";
import { ClientPlatform } from "../../../gen/components";

export enum ManagementMethod {
  Jamf,
  Kandji,
  MicrosoftEndpointManager,
  VantaAgent,
}

export const ManagedComputerTypes = [
  "SpecificJamfManagedComputerResource",
  "SpecificKandjiManagedComputerResource",
  "SpecificMicrosoftEndpointManagerManagedComputerResource",
];

type ManagedComputerType = typeof ManagedComputerTypes[number];

// for places we need to return the MacOS icons and other platform specific pieces
const MacManagedComputerTypes = [
  "SpecificJamfManagedComputerResource",
  "SpecificKandjiManagedComputerResource",
];

const managementMethodForType = (t: ManagedComputerType) =>
  t === "SpecificJamfManagedComputerResource"
    ? ManagementMethod.Jamf
    : t === "SpecificKandjiManagedComputerResource"
    ? ManagementMethod.Kandji
    : ManagementMethod.MicrosoftEndpointManager;

export type UserFromFetchDomainEndpointsQuery = NonNullable<
  FetchDomainEndpointsQuery["organization"]
>["users"][number];

type AgentWorkstationFromFetchDomainEndpointsQuery =
  UserFromFetchDomainEndpointsQuery["workstations"][number]["data"];
type ManagedComputerFromFetchDomainEndpointsQuery =
  UserFromFetchDomainEndpointsQuery["managedComputers"][number];

export type IUserWorkstation = {
  __typename:
    | UserFromFetchDomainEndpointsQuery["workstations"][number]["data"]["__typename"]
    | ManagedComputerType;
  id: string;
  udid: Maybe<string>;
  name: Maybe<string>;
  managedVia: ManagementMethod;
  lastData: Maybe<Date>;
  osName?: Maybe<string>;
  osVersion: string;
  serialNumber: Maybe<string>;
  isEncrypted: Maybe<boolean>;
  numApplications: Maybe<number>;
  numBrowserExtensions: Maybe<number>;

  // applicationNames are null for Vanta Agent workstations: full application
  // lists are lazy-loaded.
  antivirusPrograms: string[];
  applicationNames: Maybe<string[]>;
  avPolicies: AvPolicy[];
  passwordManagers: string[];

  mdmVersion: Maybe<string>;

  isReassignable: boolean;
  user: UserFromFetchDomainEndpointsQuery;
};

export const COMPUTERS_PAGE_TAB_IDS = {
  MONITORED: "monitored",
  UNMONITORED: "unmonitored",
};

export const COMPUTER_FLAG = {
  ENCRYPT: "ENCRYPT",
  FIND_MY_MAC: "FIND_MY_MAC",
  INSTALL_APP: "INSTALL_APP",
  REASSIGN_OWNER: "REASSIGN_OWNER",
  REMOVE: "REMOVE",
};

export const agentWorkstationToIUserWorkstation = (
  agentWorkstation: AgentWorkstationFromFetchDomainEndpointsQuery,
  user: UserFromFetchDomainEndpointsQuery
) => {
  const antivirusPrograms: string[] = [];
  if ("installedAvPrograms" in agentWorkstation) {
    antivirusPrograms.push(...(agentWorkstation.installedAvPrograms ?? []));
  }
  const passwordManagers =
    "installedPasswordManagers" in agentWorkstation
      ? agentWorkstation.installedPasswordManagers ?? []
      : [];

  const numBrowserExtensions =
    "numBrowserExtensions" in agentWorkstation
      ? agentWorkstation.numBrowserExtensions
      : null;

  return {
    __typename: agentWorkstation.__typename,
    id: agentWorkstation.id,
    udid: agentWorkstation.hostIdentifier,
    name: agentWorkstation.hostname,
    managedVia: ManagementMethod.VantaAgent,
    mdmVersion: agentWorkstation.agentVersion,
    lastData: isSome(agentWorkstation.lastPing)
      ? new Date(parseInt(agentWorkstation.lastPing, 10) * 1000)
      : null,
    osVersion: agentWorkstation.osVersion ?? "",
    serialNumber: agentWorkstation.serialNumber,
    passwordManagers,
    isEncrypted:
      agentWorkstation.__typename !== "linuxServerData"
        ? agentWorkstation.isEncrypted
        : nothing,
    antivirusPrograms,
    avPolicies: [],
    numApplications:
      agentWorkstation.__typename === "macosWorkstationData" ||
      agentWorkstation.__typename === "windowsWorkstationData"
        ? agentWorkstation.numApplications
        : null,
    numBrowserExtensions,
    isReassignable: true,
    user,
    // These are lazy-loaded.
    applicationNames: nothing,
  };
};

export const managedWorkstationToIUserWorkstation = (
  managedWorkstation: ManagedComputerFromFetchDomainEndpointsQuery,
  user: UserFromFetchDomainEndpointsQuery
) => {
  if (!ManagedComputerTypes.includes(managedWorkstation.__typename)) {
    return nothing;
  }
  const externallyManagedAttributes = getExternallyManagedVantaAttributes(
    managedWorkstation.vantaAttributes!
  );

  return {
    __typename: managedWorkstation.__typename,
    id: managedWorkstation.id,
    udid: managedWorkstation.udid,
    name: managedWorkstation.name,
    managedVia: managementMethodForType(managedWorkstation.__typename),
    mdmVersion: nothing,
    user,
    isReassignable: !externallyManagedAttributes.has(
      ReservedVantaAttributes.ownerId.key
    ),
    lastData: new Date(managedWorkstation.updatedAt),
    osName: managedWorkstation.operatingSystem?.name,
    osVersion: `${managedWorkstation.operatingSystem?.name} ${managedWorkstation.operatingSystem?.version}`,
    serialNumber: managedWorkstation.hardware!.serialNumber,
    passwordManagers: managedWorkstation.passwordManagers.map(app => app.name),
    isEncrypted: managedWorkstation.isEncrypted,
    antivirusPrograms: managedWorkstation.antivirusNames,
    avPolicies:
      "avPolicies" in managedWorkstation ? managedWorkstation.avPolicies : [],
    numApplications: managedWorkstation.applications.length,
    numBrowserExtensions: null,
    applicationNames: managedWorkstation.applications.map(a => a.name),
    uniqueId: managedWorkstation.uniqueId,
  };
};

export const getEndpointList = (
  domain: NonNullable<FetchDomainEndpointsQuery["organization"]>
): IUserWorkstation[] => {
  // Merge Agent and Jamf-monitored computers by UDID, preferring non-Agent data.
  const workstations = domain.users.reduce((accumulation, user) => {
    user.workstations.forEach(({ data: agentWorkstation }) => {
      accumulation.set(
        agentWorkstation.hostIdentifier,
        agentWorkstationToIUserWorkstation(agentWorkstation, user)
      );
    });
    user.managedComputers.forEach(workstation => {
      const managedWorkstation = managedWorkstationToIUserWorkstation(
        workstation,
        user
      );
      if (
        isSome(managedWorkstation) &&
        ManagedComputerTypes.includes(managedWorkstation.__typename)
      ) {
        accumulation.set(managedWorkstation.uniqueId, managedWorkstation);
      }
    });
    return accumulation;
  }, new Map<string, IUserWorkstation>());

  return Array.from(workstations.values());
};

export const clientPlatformFromWorkstation = (
  workstation: IUserWorkstation
): Maybe<ClientPlatform> =>
  !isSome(workstation)
    ? undefined
    : MacManagedComputerTypes.includes(workstation.__typename) ||
      workstation.__typename === "macosWorkstationData" ||
      workstation.osName === "macOS"
    ? ClientPlatform.darwin
    : workstation.__typename === "linuxWorkstationData"
    ? ClientPlatform.linux
    : workstation.__typename === "windowsWorkstationData" ||
      workstation.osName === "Windows"
    ? ClientPlatform.windows
    : undefined;

export const monitoredByFromWorkstation = (workstation: IUserWorkstation) => {
  const managedVia = workstation.managedVia;
  switch (managedVia) {
    case ManagementMethod.Jamf:
      return "Jamf";
    case ManagementMethod.Kandji:
      return "Kandji";
    case ManagementMethod.MicrosoftEndpointManager:
      return "Microsoft Endpoint Manager";
    case ManagementMethod.VantaAgent:
      return isSome(workstation.mdmVersion)
        ? `Vanta Agent ${workstation.mdmVersion}`
        : "Vanta Agent";
    default:
      return nothing;
  }
};

export const specificResourceFromWorkstation = (
  workstation: IUserWorkstation
) => {
  const managedVia = workstation.managedVia;
  switch (managedVia) {
    case ManagementMethod.Jamf:
      return SpecificResource.JamfManagedComputer;
    case ManagementMethod.Kandji:
      return SpecificResource.KandjiManagedComputer;
    default:
      return nothing;
  }
};

export const managedByFromType = (typename: ManagedComputerType) => {
  switch (typename) {
    case "SpecificJamfManagedComputerResource":
      return "Jamf";
    case "SpecificKandjiManagedComputerResource":
      return "Kandji";
    case "SpecificMicrosoftEndpointManagerManagedComputerResource":
      return "Microsoft Endpoint Manager";
    default:
      return nothing;
  }
};

export const specificResourceFromType = (typename: ManagedComputerType) => {
  switch (typename) {
    case "SpecificJamfManagedComputerResource":
      return SpecificResource.JamfManagedComputer;
    case "SpecificKandjiManagedComputerResource":
      return SpecificResource.KandjiManagedComputer;
    case "SpecificMicrosoftEndpointManagerManagedComputerResource":
      return SpecificResource.MicrosoftEndpointManagerManagedComputer;
    default:
      return nothing;
  }
};

gql`
  fragment UserComputerFields on user {
    id
    workstations {
      id
      data {
        __typename
        id
        agentVersion
        osVersion
        lastPing
        serialNumber
        hostIdentifier
        hostname
        ... on macosWorkstationData {
          installedAvPrograms
          installedPasswordManagers
          isEncrypted
          isPasswordManagerInstalled
          isLocationServicesEnabled
          numApplications
          numBrowserExtensions
        }
        ... on windowsWorkstationData {
          installedAvPrograms
          installedPasswordManagers
          isEncrypted
          isPasswordManagerInstalled
          numApplications
          numBrowserExtensions
        }
        ... on linuxWorkstationData {
          installedAvPrograms
          isEncrypted
        }
      }
    }
    managedComputers {
      id
      udid
      __typename
      uniqueId
      updatedAt
      vantaAttributes {
        key
        value
        managedExternally
      }
      ... on SpecificJamfManagedComputerResource {
        name
        isEncrypted
        operatingSystem {
          name
          version
        }
        hardware {
          serialNumber
        }
        passwordManagers {
          name
        }
        antivirusNames
        applications {
          name
        }
      }
      ... on SpecificKandjiManagedComputerResource {
        name
        isEncrypted
        operatingSystem {
          name
          version
        }
        hardware {
          serialNumber
        }
        passwordManagers {
          name
        }
        antivirusNames
        applications {
          name
        }
      }
      ... on SpecificMicrosoftEndpointManagerManagedComputerResource {
        name
        isEncrypted
        operatingSystem {
          name
          version
        }
        hardware {
          serialNumber
        }
        passwordManagers {
          name
        }
        antivirusNames
        avPolicies {
          id
          name
        }
        applications {
          name
        }
      }
    }
  }
`;

gql`
  query fetchDomainEndpoints {
    organization {
      id
      requiresLocationServices
      users(includeRemovedUsers: true, includeNonHumanUsers: true) {
        id
        displayName
        email
        familyName
        givenName
        imageUrl
        isActive
        isNotHuman
        role {
          id
          name
        }
        securityRequirements {
          mustInstallLaptopMonitoring
        }
        ...UserComputerFields
      }
    }
  }
`;

gql`
  mutation sendReminderEmail(
    $domainId: ID!
    $platform: clientPlatform
    $userId: ID!
    $flag: String!
  ) {
    sendReminderEmail(
      domainId: $domainId
      platform: $platform
      userId: $userId
      flag: $flag
    )
  }
`;

gql`
  mutation removeLaptops($domainId: ID!, $ids: [String!]!) {
    disableOsqueriesByID(domainId: $domainId, ids: $ids)
  }
`;
