import { Spinner } from "@blueprintjs/core";
import { dropNothing, isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import React from "react";

import { BodyText } from "../../../../alpaca/components";
import { LogError } from "../../../../errors";
import { useGetUserComputersQuery } from "../../../../gen/components";
import { AgentInstallationReminderButton } from "../../people/user-drawer/email-alert-button";
import type { IUserWorkstation } from "../utils";
import {
  agentWorkstationToIUserWorkstation,
  managedWorkstationToIUserWorkstation,
} from "../utils";
import { ComputerDetails } from "./computer-details";

interface IProps {
  userId: string;
}

export const ComputersTab: React.FC<IProps> = ({ userId }) => {
  const { loading, error, data } = useGetUserComputersQuery({
    variables: { userId },
  });
  if (error) {
    LogError(error);
    return null;
  }

  if (loading || !isSome(data) || !isSome(data.user)) {
    return <Spinner />;
  }

  const workstations: IUserWorkstation[] = dropNothing([
    ...data.user.workstations.map(w =>
      agentWorkstationToIUserWorkstation(w.data, data.user!)
    ),
    ...data.user.managedComputers.map(w =>
      managedWorkstationToIUserWorkstation(w, data.user!)
    ),
  ]);

  return workstations.length > 0 ? (
    <>
      {workstations.map(workstation => (
        <ComputerDetails
          key={workstation.id}
          workstation={workstation}
          domainId={data.user!.domain.id}
        />
      ))}
    </>
  ) : (
    <>
      <BodyText>This user has no computers tracked by Vanta.</BodyText>
      <AgentInstallationReminderButton user={data.user} />
    </>
  );
};

gql`
  query GetUserComputers($userId: ID!) {
    user(id: $userId) {
      id
      displayName
      email
      familyName
      givenName
      imageUrl
      isActive
      isNotHuman
      domain {
        id
      }
      ...UserComputerFields
      securityRequirements {
        mustInstallLaptopMonitoring
      }
    }
  }
`;
