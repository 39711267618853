import { Spinner } from "@blueprintjs/core";
import {
  specificResourceGraphQLTypeToEnumValue,
  SpecificResourceToInventoryType,
} from "common/utils/inventory";
import {
  deserializeVantaAttributes,
  hasExternallyManagedVantaAttributes,
} from "common/utils/vantaAttributes";
import gql from "graphql-tag";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import type { WarehousesQuery } from "../../../../gen/components";
import {
  useSetInventoryResourceVantaAttributeMutation,
  useWarehousesQuery,
} from "../../../../gen/components";
import { InventoryCard } from "../inventory-card";
import { AccountTag, EncryptionTag, EPHITag, RegionTag } from "../tags";
import {
  fetchMoreResources,
  getDebounceContext,
  getResourceFilterParams,
  invalidInventoryType,
  PAGE_SIZE,
  setResourceVantaAttributeFn,
} from "../utils";
import type { IInventoryTabProps } from "./shared-interface";

export const Warehouses: React.FC<IInventoryTabProps> = ({ searchString }) => {
  const { data, fetchMore, loading } = useWarehousesQuery({
    variables: {
      first: PAGE_SIZE,
      filterParams: getResourceFilterParams(searchString),
    },
    context: getDebounceContext("warehouse"),
  });

  if (loading) {
    return <Spinner />;
  }

  const cards = data?.organization.resources.edges?.map(item => (
    <WarehouseCard key={item.node.id} item={item} domain={data.organization} />
  ));

  return (
    <InfiniteScroll
      className="inventory-list-card-group"
      dataLength={data?.organization.resources.edges.length ?? 0}
      next={async () => fetchMoreResources(data, fetchMore)}
      hasMore={data?.organization.resources.pageInfo.hasNextPage ?? false}
      loader={<Spinner />}
    >
      {cards}
    </InfiniteScroll>
  );
};

const WarehouseCard: React.FC<{
  item: NonNullable<
    WarehousesQuery["organization"]
  >["resources"]["edges"][number];
  domain: NonNullable<WarehousesQuery["organization"]>;
}> = ({ item, domain }) => {
  const [setResourceVantaAttribute] =
    useSetInventoryResourceVantaAttributeMutation();

  const specificResourceKind = specificResourceGraphQLTypeToEnumValue(
    item.node.__typename
  );
  const type = SpecificResourceToInventoryType[specificResourceKind](
    item.node as any
  );
  const resourceMongoId = item.node.id;

  let uid;
  let labels;
  const vantaAttributes = deserializeVantaAttributes(
    item.node.vantaAttributes ?? []
  );
  switch (item.node.__typename) {
    case "SpecificBigQueryDatasetResource": {
      uid = item.node.name;
      labels = [
        <AccountTag key="account" cloudAccountId={item.node.projectId} />,
        <EncryptionTag
          key="encryption"
          encrypted={true} // GCP encrypts by default. It can be encrypted with a customer-provided key, but there's still encryption no matter what
        />,
      ];
      break;
    }
    case "SpecificRedshiftClusterResource": {
      uid = item.node.uniqueId;
      labels = [
        <AccountTag key="account" cloudAccountId={item.node.account} />,
        <EncryptionTag
          key="encryption"
          encrypted={item.node.encryptionEnabled}
        />,
      ];
      break;
    }
    case "SpecificAzureSynapseWarehouseResource": {
      uid = item.node.uniqueId;
      labels = [
        <RegionTag key="region" region={item.node.location} />,
        <EncryptionTag
          key="encryption"
          encrypted={item.node.encryptionEnabled}
        />,
      ];
      if (vantaAttributes.containsEPHI) {
        labels.push(<EPHITag key="ephi" />);
      }
      break;
    }
    default:
      return invalidInventoryType(item.node.__typename);
  }

  const locked = hasExternallyManagedVantaAttributes(
    item.node.vantaAttributes ?? []
  );
  const setVantaAttribute = setResourceVantaAttributeFn(
    setResourceVantaAttribute,
    specificResourceKind,
    resourceMongoId,
    item.node.__typename
  );
  return (
    <InventoryCard
      type={type}
      uid={uid}
      key={item.node.id}
      name={item.node.name}
      description={vantaAttributes.description}
      owner={vantaAttributes.ownerId}
      labels={labels}
      canContainUserData={true}
      containsUserData={vantaAttributes.containsUserData}
      containsEPHI={vantaAttributes.containsEPHI}
      userDataDescription={vantaAttributes.userDataStored}
      locked={locked}
      setVantaAttribute={setVantaAttribute}
    />
  );
};

gql`
  query warehouses($first: Int!, $after: String, $filterParams: filterParams) {
    organization {
      id
      name
      resources(
        first: $first
        after: $after
        genericResourceType: DataWarehouse
        sortParams: { field: "createdAt", direction: -1 }
        filterParams: $filterParams
      ) {
        totalCount
        edges {
          node {
            id
            __typename
            uniqueId
            vantaAttributes {
              key
              value
              managedExternally
            }
            ... on SpecificBigQueryDatasetResource {
              name
              projectId
            }
            ... on SpecificRedshiftClusterResource {
              name
              account
              encryptionEnabled
            }
            ... on SpecificAzureSynapseWarehouseResource {
              name
              location
              encryptionEnabled
            }
          }
        }
        pageInfo {
          hasNextPage
          endCursor
          startCursor
          hasPreviousPage
        }
      }
    }
  }
`;
