import "./vanta-report-introduction.scss";

import {
  CONTINUOUS_INDICATION,
  REPORT_TEXTS,
} from "common/standards/STARTER_REPORT_DATA";
import React, { useContext } from "react";

import { ReportStandard } from "../../../../../gen/components";
import { MarkdownRenderer } from "../../../../pages/components/markdown-renderer";
import { ReportCompanyName, ReportContent, ReportTitle } from "../../../common";
import { replaceVarsinReportText } from "../../../helpers/helpers";
import { VantaReportContext } from "../../../vanta-report-context";
import type { IReportProps } from "../../../vanta-report-page";

export const VantaReportIntroduction: React.FunctionComponent<IReportProps> = ({
  domain,
  complianceStandard,
}) => {
  const { isPrintView } = useContext(VantaReportContext);

  const headingForComplianceStandard = (standard: ReportStandard) => {
    switch (standard) {
      case ReportStandard.gdpr:
        return "GDPR Gap Assessment from Vanta";
      case ReportStandard.soc2:
        return "SOC 2 Gap Assessment from Vanta";
      case ReportStandard.hipaa:
        return "HIPAA Gap Assessment from Vanta";
      case ReportStandard.pciSaqA:
      case ReportStandard.pciSaqAEP:
      case ReportStandard.pciSaqDMerchant:
      case ReportStandard.pciSaqDSP:
        return "PCI Gap Assessment from Vanta";
      case ReportStandard.iso27001:
        return "ISO 27001 Gap Assessment from Vanta";
      case ReportStandard.vanta:
        return "Vanta Report";
      default:
        throw new Error(`Unrecognized report standard: ${standard}`);
    }
  };

  const reportText = REPORT_TEXTS[complianceStandard.standard];
  return (
    <div
      className="vrp-introduction-container"
      id={complianceStandard.standard !== "vanta" ? "Summary" : undefined}
    >
      <ReportTitle>
        {headingForComplianceStandard(complianceStandard.standard)}
        <br />
        <ReportCompanyName>{`For ${domain.displayName}`}</ReportCompanyName>
      </ReportTitle>
      <ReportContent>
        {MarkdownRenderer(
          replaceVarsinReportText(reportText.SUMMARY.text, domain)
        )}
      </ReportContent>
      {!isPrintView ? (
        <ReportContent>
          {MarkdownRenderer(
            replaceVarsinReportText(CONTINUOUS_INDICATION, domain)
          )}
        </ReportContent>
      ) : null}
    </div>
  );
};
