export const whitelistingMetadataByService = {
  aws: {
    entityType: "AwsContainerVulnerability",
    testId: "container-packages-checked-for-vulnerabilities-records-closed-aws",
  },
  aws_inspector: {
    entityType: "AwsInspectorVulnerability",
    testId: "aws-inspector-packages-checked-for-vulnerabilities-records-closed",
  },
  azure: {
    entityType: "AzureContainerVulnerability",
    testId:
      "container-packages-checked-for-vulnerabilities-records-closed-azure",
  },
  gcp: {
    entityType: "GCPContainerVulnerability",
    testId: "container-packages-checked-for-vulnerabilities-records-closed-gcp",
  },
} as const;

export type VulnerabilityServiceClientSafe =
  keyof typeof whitelistingMetadataByService;
