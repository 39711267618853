import {
  Button,
  Classes,
  Dialog,
  FormGroup,
  HTMLSelect,
  Intent,
} from "@blueprintjs/core";
import { CronService } from "common/base/types/gen";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import React, { useState } from "react";

import { LogError } from "../../errors";
import type { GetCronFiltersQuery } from "../../gen/components";
import {
  GetCronFiltersDocument,
  useBlacklistForCronFilterMutation,
  useGetAllSpecificResourceKindsQuery,
  useGetAllTestIdsQuery,
} from "../../gen/components";
import { AppToaster } from "../../helpers/toaster";
import { AllDomainSuggest } from "../helpers/all-domain-suggest";

interface IProps {
  onClose(): void;
}

const UNSELECTED_VALUE = "__internal_unselected";

export const CronFilterBlacklistDialog: React.FC<IProps> = ({ onClose }) => {
  const resourceKindsQuery = useGetAllSpecificResourceKindsQuery({
    fetchPolicy: "cache-first",
  });
  const testIdsQuery = useGetAllTestIdsQuery({
    fetchPolicy: "cache-first",
  });

  const [blacklistMutation, mutationResult] = useBlacklistForCronFilterMutation(
    {
      onCompleted: () => {
        AppToaster.show({
          message: "Domain/item added to blacklist",
          intent: Intent.SUCCESS,
        });
        onClose();
      },
      onError: e => {
        LogError(e, false);
        AppToaster.show({
          message: `Error: ${e.message}`,
          intent: Intent.DANGER,
        });
        onClose();
      },
      update: (cache, result) => {
        const updatedCronFilter = result.data?.blacklistForCronFilter;
        if (!isSome(updatedCronFilter)) {
          return;
        }
        const previousData = cache.readQuery<GetCronFiltersQuery>({
          query: GetCronFiltersDocument,
        });
        if (!isSome(previousData?.cronFilters)) {
          return;
        }
        cache.writeQuery<GetCronFiltersQuery>({
          query: GetCronFiltersDocument,
          data: {
            cronFilters: previousData!.cronFilters.map(f => {
              if (f.id === updatedCronFilter.id) {
                return updatedCronFilter;
              } else {
                return f;
              }
            }),
          },
        });
      },
    }
  );
  const [selectedQueue, setQueue] = useState(CronService.resourcefetcher);
  const [selectedDomainId, setDomainId] = useState<Maybe<string>>(null);
  const [selectedItem, setItem] = useState<Maybe<string>>(null);

  const resourceFetcherItems =
    resourceKindsQuery.data?.specificResourceKinds ?? [];
  const testRunnerItems = testIdsQuery.data?.testRunnerTestIds ?? [];
  const cronServiceToItems = {
    [CronService.resourcefetcher]: resourceFetcherItems,
    [CronService.testrunner]: testRunnerItems,
  };

  const queueSelect = (
    <FormGroup label="Queue">
      <HTMLSelect
        value={selectedQueue}
        onChange={e => {
          setQueue(e.currentTarget.value as any);
          setItem(null);
        }}
      >
        <option value={CronService.resourcefetcher}>Resource Fetcher</option>
        <option value={CronService.testrunner}>Test Runner</option>
      </HTMLSelect>
    </FormGroup>
  );

  const domainSelect = (
    <FormGroup label="Domain">
      <AllDomainSuggest
        selectedId={selectedDomainId}
        onSelect={domainId => setDomainId(domainId)}
      />
      <Button onClick={() => setDomainId(null)}>Clear</Button>
    </FormGroup>
  );

  const itemSelect = (
    <FormGroup label="Item">
      <HTMLSelect
        value={selectedItem ?? UNSELECTED_VALUE}
        onChange={e => {
          const newValue = e.currentTarget.value;
          if (newValue === UNSELECTED_VALUE) {
            setItem(null);
          } else {
            setItem(newValue);
          }
        }}
      >
        <option value={UNSELECTED_VALUE}>Choose</option>
        {cronServiceToItems[selectedQueue]
          .slice()
          .sort()
          .map(item => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
      </HTMLSelect>
      <Button onClick={() => setItem(null)}>Clear</Button>
    </FormGroup>
  );

  return (
    <Dialog
      isOpen={true}
      onClose={onClose}
      title="Blacklist domain and/or item"
    >
      <div className={Classes.DIALOG_BODY}>
        {queueSelect}
        {domainSelect}
        {itemSelect}
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={onClose} disabled={mutationResult.called}>
            Cancel
          </Button>
          <Button
            intent={Intent.PRIMARY}
            loading={mutationResult.called}
            disabled={!isSome(selectedDomainId) && !isSome(selectedItem)}
            onClick={() => {
              blacklistMutation({
                variables: {
                  domainId: selectedDomainId ?? null,
                  queue: selectedQueue,
                  item: selectedItem,
                },
              }).catch(LogError);
              onClose();
            }}
          >
            Add to blacklist
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

gql`
  query getAllSpecificResourceKinds {
    specificResourceKinds
  }
`;

gql`
  query getAllTestIds {
    testRunnerTestIds
  }
`;

gql`
  mutation blacklistForCronFilter(
    $domainId: ID
    $item: String
    $queue: cronService!
  ) {
    blacklistForCronFilter(domainId: $domainId, item: $item, queue: $queue) {
      id
      queueName
      blacklistDomains
      blacklistItems
      blacklistDomainsAndItems {
        domainId
        item
      }
    }
  }
`;
