import "./manage-risks";

import { Button, Card, H4 } from "@blueprintjs/core";
import React, { useState } from "react";

import { LogError } from "../../../errors";
import type { FetchRiskRegisterQuery } from "../../../gen/components";
import {
  FetchRiskRegisterDocument,
  useDeleteRiskScenarioMutation,
} from "../../../gen/components";
import { CancelConfirmDialog } from "../../helpers/CancelConfirmDialog";
import { IMPACTS, LIKELIHOODS } from "./add-scenario-dialog";

interface IProps {
  scenario: NonNullable<
    FetchRiskRegisterQuery["organization"]
  >["riskRegister"]["scenarios"][0];
  editClick: () => void;
}

export const ScenarioCard: React.FC<IProps> = props => {
  const [deleteScenario] = useDeleteRiskScenarioMutation({
    refetchQueries: [{ query: FetchRiskRegisterDocument }],
  });
  const [deleteDialogOpen, openDeleteDialog] = useState(false);
  const { scenario, editClick } = props;
  return (
    <Card className="question-card">
      <div className="spead-components">
        <div>
          <H4 className="task-header">Scenario</H4>
          {scenario.text}
          <div className="spead-components scenario-card">
            <div>Likelihood: {LIKELIHOODS[scenario.likelihood]}</div>
            <div>Impact: {IMPACTS[scenario.impact]}</div>
          </div>
          <H4 className="task-header">Tasks</H4>
          {scenario.tasks.length === 0
            ? "None"
            : scenario.tasks.map(t => t.text)}
        </div>
        <div className="fixed-column">
          <Button text="Edit" onClick={editClick} />
          <Button text="Delete" onClick={() => openDeleteDialog(true)} />
          <CancelConfirmDialog
            body=""
            confirmText="Delete scenario"
            isOpen={deleteDialogOpen}
            title={`Delete scenario from list`}
            onClose={() => openDeleteDialog(false)}
            onConfirm={() => {
              deleteScenario({
                variables: { id: scenario.id },
              }).catch(LogError);
            }}
          />
        </div>
      </div>
    </Card>
  );
};
