import type { IQuestionSchema } from "../../forms/interfaces";

export const generalPrivacy: IQuestionSchema = {
  id: "generalPrivacy",
  title: "Data management",
  questions: [
    {
      id: "dataMgmtHeader-2018-09-10",
      label: "Data management",
      name: "dataMgmtHeader",
      required: false,
      type: "Header",
    },
    {
      id: "piiTypes-2018_09_14",
      label: "Types of PII the software may store, process, or transmit",
      name: "piiTypes",
      required: false,
      type: "TextArea",
    },
    {
      id: "canDataDelete-2018_09_14",
      label: "%COMPANY can request secure data delete",
      name: "canDataDelete",
      required: false,
      type: "CheckboxInput",
    },
    {
      choices: [
        "Within one week",
        "Within one month",
        "Within one quarter",
        "Within two quarters",
        "Within one year",
        "Data delete requests will not be honored",
      ],
      id: "dataDeleteSLA-2018_09_14",
      label: "Data deleted on company request",
      name: "dataDeleteSLA",
      required: false,
      type: "CheckboxInput",
    },
    {
      id: "thirdPartiesPII-2018_09_14",
      label: "Third parties with access to %COMPANY data",
      name: "thirdPartiesPII",
      required: false,
      type: "TextArea",
    },
  ],
  submitText: "Submit privacy info",
  version: "2018-09-14",
};
