import { Intent } from "@blueprintjs/core";
import React from "react";
import { Banner, IconNames } from "../../../../alpaca/components";

import { awsInspectorSetupInstructionsUrl } from "./constants";

interface IProps {
  instanceCount: number;
}

export const UnmonitoredInstancesCallout: React.FC<IProps> = ({
  instanceCount,
}) => {
  if (instanceCount < 1) {
    return null;
  }

  return (
    <Banner
      title={`${instanceCount} of your instances ${
        instanceCount === 1 ? "is" : "are"
      } not being monitored by AWS Inspector`}
      buttonProps={{
        text: "Set up AWS Inspecctor",
        onClick: () => {
          window.open(awsInspectorSetupInstructionsUrl, "_blank");
        },
      }}
      intent={Intent.WARNING}
      icon={IconNames.CAUTION}
    />
  );
};
