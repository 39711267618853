import * as moment from "moment";
import { extendMoment } from "moment-range";

/**
 * In theory, this should just extend moment's functionality for working with date ranges
 *
 * It does extend the functionality! But for unclear reasons, it does not achieve 100% typing
 * parity with moment. For example:
 *
 * const value = momentWithRange().valueOf()
 *
 * is not duly typed as a number, which will make Typescript angry when you try to use it
 * to do arithmetic.
 *
 */
export const momentWithRange = extendMoment(moment);
