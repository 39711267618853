import { Button, Intent } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import React from "react";

import { LogError } from "../../errors";
import type { CronService, GetCronFiltersQuery } from "../../gen/components";
import {
  GetCronFiltersDocument,
  useUnblacklistForCronFilterMutation,
} from "../../gen/components";
import { AppToaster } from "../../helpers/toaster";
interface IProps {
  domainId?: Maybe<string>;
  item?: Maybe<string>;
  queue: CronService;
}

export const CronFilterUnblacklistButton: React.FC<IProps> = ({
  domainId,
  item,
  queue,
}) => {
  const [unblacklist, mutationResult] = useUnblacklistForCronFilterMutation({
    variables: {
      domainId,
      item,
      queue,
    },
    onCompleted: () => {
      AppToaster.show({
        message: "Domain/item removed from blacklist",
        intent: Intent.SUCCESS,
      });
    },
    onError: e => {
      LogError(e, false);
      AppToaster.show({
        message: `Error: ${e.message}`,
        intent: Intent.DANGER,
      });
    },
    update: (cache, result) => {
      const updatedCronFilter = result.data?.unblacklistForCronFilter;
      if (!isSome(updatedCronFilter)) {
        return;
      }
      const previousData = cache.readQuery<GetCronFiltersQuery>({
        query: GetCronFiltersDocument,
      });
      if (!isSome(previousData?.cronFilters)) {
        return;
      }
      cache.writeQuery<GetCronFiltersQuery>({
        query: GetCronFiltersDocument,
        data: {
          cronFilters: previousData!.cronFilters.map(f => {
            if (f.id === updatedCronFilter.id) {
              return updatedCronFilter;
            } else {
              return f;
            }
          }),
        },
      });
    },
  });

  return (
    <Button
      loading={mutationResult.called}
      intent={Intent.PRIMARY}
      onClick={() => {
        unblacklist().catch(LogError);
      }}
    >
      Unblacklist
    </Button>
  );
};

gql`
  mutation unblacklistForCronFilter(
    $domainId: ID
    $item: String
    $queue: cronService!
  ) {
    unblacklistForCronFilter(domainId: $domainId, item: $item, queue: $queue) {
      id
      queueName
      blacklistDomains
      blacklistItems
      blacklistDomainsAndItems {
        domainId
        item
      }
    }
  }
`;
