import { Tab } from "@blueprintjs/core";
import gql from "graphql-tag";
import React from "react";
import { Redirect, useHistory } from "react-router";

import { BASE_PALETTE } from "../../../alpaca/base/colors";
import { GRID_SPACING } from "../../../alpaca/base/grid";
import { DefaultView, HeaderlessTabs } from "../../../alpaca/components";
import { useGetPolicyPacketQuery } from "../../../gen/components";
import { FullPageSpinner } from "../../helpers/FullPageSpinner";
import { Card } from "../components/card";
import { POLICY_PAGE_TAB_IDS } from "./common";
import { useGeneratePolicyPacket } from "./hooks";
import { ManageProceduresComponent } from "./manage-procedures";
import { PolicyTable } from "./policy-table";

// The current SLA tab used to be called procedures.
// For now, we redirect if we see the old hash.
const DEPRECATED_PROCEDURES_HASH = "procedures";

export const PoliciesPage: React.FC = () => {
  const history = useHistory();

  const { startPolling, stopPolling, data } = useGetPolicyPacketQuery();

  const { generatePolicyPacket, isGeneratingPacket } = useGeneratePolicyPacket(
    data?.organization.id,
    data?.organization.policyPacket,
    startPolling,
    stopPolling
  );

  if (isGeneratingPacket) {
    return (
      <FullPageSpinner text="Generating policy packet —- this might take up to 30 seconds." />
    );
  }

  const selectedTabId =
    location.hash.length > 0
      ? location.hash.substring(1)
      : POLICY_PAGE_TAB_IDS.POLICIES;

  if (selectedTabId === DEPRECATED_PROCEDURES_HASH) {
    return <Redirect to={`${location.pathname}#${POLICY_PAGE_TAB_IDS.SLAS}`} />;
  }

  return (
    <DefaultView
      altMinWidth={selectedTabId === POLICY_PAGE_TAB_IDS.POLICIES ? 1496 : null}
      headerProps={{
        title: "Policies",
        tabProps: {
          id: "policy-page-tabs",
          tabIds: [
            { id: POLICY_PAGE_TAB_IDS.POLICIES, title: "Policies" },
            { id: POLICY_PAGE_TAB_IDS.SLAS, title: "SLAs" },
          ],
          selectedTabId,
          onChange: newTabId =>
            history.push({ pathname: "/policies", hash: `${newTabId}` }),
        },
      }}
    >
      <HeaderlessTabs
        id="policy-page-tabs-hidden"
        renderActiveTabPanelOnly={true}
        selectedTabId={selectedTabId}
      >
        <Tab
          id={POLICY_PAGE_TAB_IDS.POLICIES}
          title="Policies"
          panel={
            <Card>
              <PolicyTable
                onGeneratePacket={() => {
                  generatePolicyPacket();
                }}
              />
            </Card>
          }
        />
        <Tab
          id={POLICY_PAGE_TAB_IDS.SLAS}
          panel={
            <Card
              style={{
                padding: 3 * GRID_SPACING,
                backgroundColor: BASE_PALETTE.SNOW,
              }}
            >
              <ManageProceduresComponent />
            </Card>
          }
          title="SLAs"
        />
      </HeaderlessTabs>
    </DefaultView>
  );
};

gql`
  query getPolicyPacket {
    organization {
      id
      policyPacket {
        createdAt
        generating
        id
        uploadedDoc {
          id
          slugId
        }
      }
    }
  }
`;

gql`
  query ManagePoliciesV2 {
    organization {
      ...PoliciesV2InfoSubQuery
      id
      defaultSecurityRequirements {
        mustAcceptPolicies
      }
      defaultNecessaryPolicies
      policies(onlyApproved: false) {
        approvedAt
        approver {
          id
          hrUser {
            jobTitle
          }
        }
        rolesToAccept {
          id
          name
        }
        approverName
        createdAt
        generating
        id
        numUsers
        numUsersAccepted
        policyType
        staticSlug
        title
        uploadedDoc {
          id
          slugId
          url
        }
      }
      policyDocStubs {
        description
        standards
        policyType
        title
      }
    }
  }
`;
