import { Popover2 } from "@blueprintjs/popover2";
import type { Maybe } from "common/base/types/maybe";
import React from "react";
import styled from "styled-components";

import { BASE_PALETTE } from "../../base/colors";
import { GRID_SPACING } from "../../base/grid";
import { Button } from "../index";
import { MENU_POPOVER_CLASSNAME } from "../menu/styles";

interface IProps {
  /**
   * Prevent events from propagating on clicks.
   */
  captureClickEvent?: Maybe<boolean>;

  // eslint-disable-next-line vanta/optional-always-maybe, vanta/prefer-maybe
  className?: string | undefined;
  // eslint-disable-next-line vanta/optional-always-maybe, vanta/prefer-maybe
  disabled?: boolean | undefined;
  // eslint-disable-next-line vanta/optional-always-maybe, vanta/prefer-maybe
  loading?: boolean | undefined;

  menu: JSX.Element;

  // eslint-disable-next-line vanta/optional-always-maybe, vanta/prefer-maybe
  small?: boolean | undefined;
  // eslint-disable-next-line vanta/optional-always-maybe, vanta/prefer-maybe
  tooltipContent?: string | JSX.Element | undefined;
}

export const MenuButton: React.FC<IProps> = ({
  captureClickEvent,
  className,
  disabled,
  loading,
  menu,
  small,
  tooltipContent,
}) => (
  <div
    className={className}
    onClick={
      Boolean(captureClickEvent) ? event => event.stopPropagation() : undefined
    }
  >
    <StyledPopover2
      content={menu}
      disabled={disabled}
      placement="bottom-end"
      popoverClassName={MENU_POPOVER_CLASSNAME}
      minimal
    >
      <StyledButton
        disabled={disabled}
        icon="more"
        small={small}
        tooltipContent={tooltipContent}
        loading={loading}
      />
    </StyledPopover2>
  </div>
);

const StyledButton = styled(Button)`
  ${({ small }) =>
    !Boolean(small)
      ? `
    width: ${6 * GRID_SPACING}px;
    svg {
      height: ${3 * GRID_SPACING}px;
      width: ${3 * GRID_SPACING}px;
    }
  `
      : `width: ${4 * GRID_SPACING}px`}
`;

const StyledPopover2 = styled(Popover2)`
  &&&.bp3-popover2-open {
    .alpaca-button {
      background-color: ${BASE_PALETTE.SMOKE};
      border: none;
      svg {
        color: ${BASE_PALETTE.SNOW};
        fill: ${BASE_PALETTE.SNOW};
      }
    }
  }
`;
