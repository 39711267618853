import "./query-page.scss";

import type { ApolloClient } from "@apollo/client";
import { ApolloConsumer } from "@apollo/client";
import type { IPanelProps } from "@blueprintjs/core";
import { Button, H5, Intent } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import moment from "moment";
import React from "react";

import type {
  ChangeAuditDatesMutation,
  ChangeAuditDatesMutationVariables,
  DeleteAuditMutation,
  DeleteAuditMutationVariables,
} from "../../gen/components";
import {
  ChangeAuditDatesDocument,
  DeleteAuditDocument,
} from "../../gen/components";
import type { IDatedAudit } from "./audit-calendar-panel";
import { AuditDatePicker } from "./audit-date-picker";
import { processError, reportError, reportSuccess } from "./reportError";

interface IProps {
  audit: IDatedAudit;
}

interface IState {
  startDate?: Maybe<Date>;
  endDate?: Maybe<Date>;
}

type IExtendedProps = IProps & { client: ApolloClient<any> } & IPanelProps;

class AuditDateRangeComponent extends React.Component<IExtendedProps, IState> {
  private mounted = false;

  public constructor(props: IExtendedProps) {
    super(props);
    this.state = {
      startDate: props.audit.startMoment.toDate(),
      endDate: props.audit.endMoment.toDate(),
    };
    this.onClickDelete = this.onClickDelete.bind(this);
  }

  public componentDidMount() {
    this.mounted = true;
  }

  public componentWillUnmount() {
    this.mounted = false;
  }

  public render() {
    const onPickedDates = ([startDate, endDate]: [
      Maybe<Date>,
      Maybe<Date>
    ]) => {
      if (this.mounted) {
        this.setState({
          startDate,
          endDate,
        });
      }
    };

    const dateRangePicker = (
      <div>
        <H5>Audit dates</H5>
        <AuditDatePicker
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          onChange={onPickedDates}
        />
      </div>
    );

    const saveAuditDatesButton = (
      <Button
        type="button"
        intent={Intent.PRIMARY}
        onClick={() => this.saveAuditDates()}
      >
        Save audit dates
      </Button>
    );

    const cancelButton = (
      <Button
        className="qp-align-right"
        type="button"
        intent={Intent.NONE}
        onClick={() => this.props.closePanel()}
      >
        Cancel
      </Button>
    );

    const midPanel = (
      <div>
        <H5>Schedule an audit</H5>
        <Button
          intent={Intent.DANGER}
          icon="delete"
          onClick={this.onClickDelete}
        >
          Delete
        </Button>
        {dateRangePicker}
        {saveAuditDatesButton}
        {cancelButton}
      </div>
    );
    return (
      <div className="panel-container">
        <div className="mid-panel">{midPanel}</div>
        <div className="right-panel" />
      </div>
    );
  }

  private saveAuditDates() {
    if (!isSome(this.state.startDate)) {
      return reportError("Audit start date is not selected");
    }
    if (!isSome(this.state.endDate)) {
      return reportError("Audit end date is not selected");
    }
    return this.props.client
      .mutate<ChangeAuditDatesMutation, ChangeAuditDatesMutationVariables>({
        mutation: ChangeAuditDatesDocument,
        variables: {
          auditId: this.props.audit.id,
          auditStart: this.state.startDate.getTime(),
          auditLengthDays: moment(this.state.endDate).diff(
            moment(this.state.startDate),
            "days"
          ),
          domainId: this.props.audit.domain.id,
        },
      })
      .then(() => reportSuccess("Audit date(s) changed!"))
      .catch(processError);
  }

  private onClickDelete() {
    if (!confirm("Are you sure you want to delete this audit?")) {
      return;
    }

    this.props.client
      .mutate<DeleteAuditMutation, DeleteAuditMutationVariables>({
        mutation: DeleteAuditDocument,
        variables: {
          auditId: this.props.audit.id,
          domainId: this.props.audit.domain.id,
        },
      })
      .then(() => {
        reportSuccess("Audit deleted");
        location.href = "/query/audit-calendar";
      })
      .catch(processError);
  }
}

gql`
  mutation DeleteAudit(
    $auditId: String!
    $domainId: ID
    $removeAccess: Boolean
  ) {
    deleteAudit(
      auditId: $auditId
      domainId: $domainId
      removeAccess: $removeAccess
    )
  }
`;

gql`
  mutation ChangeAuditDates(
    $auditId: ID!
    $domainId: ID
    $auditStart: Float!
    $auditLengthDays: Int!
  ) {
    changeAuditDates(
      auditId: $auditId
      auditStart: $auditStart
      auditLengthDays: $auditLengthDays
      domainId: $domainId
    )
  }
`;

export const AuditDateRangePanel: React.FC<IProps & IPanelProps> = props => (
  <ApolloConsumer>
    {client => <AuditDateRangeComponent client={client} {...props} />}
  </ApolloConsumer>
);
