import type { Maybe } from "common/base/types/maybe";
import { getTransformedOrElse } from "common/base/types/maybe";
import React from "react";
import styled from "styled-components";

import { BASE_PALETTE } from "../../../../../alpaca/base/colors";
import { BASE_TYPOGRAPHY } from "../../../../../alpaca/base/typography";
import { ContentCard, H4, SpacedList } from "../../../../../alpaca/components";

interface IProps {
  title: string;
  rightElements?: Maybe<React.ReactNode[]>;
}

export const OnboardingTasksCard: React.FC<IProps> = ({
  children,
  title,
  rightElements,
}) => {
  const titleElement = (
    <H4
      fontSize={18}
      lineHeight="24px"
      fontWeight={BASE_TYPOGRAPHY.FONT_WEIGHTS.SLIGHTLY_BOLD}
      style={{ margin: 0 }}
    >
      {title}
    </H4>
  );
  const titleElementWithRightElements = getTransformedOrElse(
    rightElements,
    elements => (
      <StyledFlexDiv>
        {titleElement}
        <SpacedList>{rightElements}</SpacedList>
      </StyledFlexDiv>
    ),
    titleElement
  );

  return (
    <ContentCard>
      <StyledTitleDiv>{titleElementWithRightElements}</StyledTitleDiv>
      {children}
    </ContentCard>
  );
};

const StyledFlexDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledTitleDiv = styled.div`
  width: 100%;
  padding: 16px 20px;
  margin-bottom: -1px;

  border-bottom: 1px solid ${BASE_PALETTE.WIND};
`;
