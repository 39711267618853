import type { Maybe } from "common/base/types/gen";
import { TestOutcome } from "common/base/types/gen";
import { isSome } from "common/base/types/maybe";

import type {
  GetDataForReportQuery,
  GetGeneratedReportQuery,
} from "../../../gen/components";
import type { PresentedReportEvidenceResults } from "../../pages/viewerAuth";

export type IDomainForReport = NonNullable<
  GetDataForReportQuery["user"]
>["domain"] & {
  testIdToTestMap: Map<
    string,
    Maybe<
      NonNullable<
        GetDataForReportQuery["user"]
      >["domain"]["testResults"][number]
    >
  >;
  evidenceIdToEvidenceMap: Map<string, PresentedReportEvidenceResults[number]>;
  showSuccessOnly?: Maybe<boolean>;
};

export const presentedReportToDomainForReport = (
  presentedReport: NonNullable<
    GetGeneratedReportQuery["public"]["generatedReport"]
  >
) => {
  const domain = {
    id: presentedReport.domainId,
    companyLogoSlug: presentedReport.domainLogoSlug,
    displayName: presentedReport.domainDisplayName,
    testResults: presentedReport.latestTestResults,
    slug: "slug",
  } as NonNullable<GetDataForReportQuery["user"]>["domain"];
  return {
    ...domain,
    testIdToTestMap: makeTestIdToTestMap(domain),
    evidenceIdToEvidenceMap: makeEvidenceIdEvidenceToMap(
      presentedReport.evidenceResults
    ),
    showSuccessOnly: presentedReport.showSuccessOnly,
  } as IDomainForReport;
};

const makeTestIdToTestMap = (
  domain: NonNullable<GetDataForReportQuery["user"]>["domain"]
) => {
  const testResults = domain.testResults;

  return testResults.reduce(
    (map, testResult) => map.set(testResult.testId, testResult),
    new Map<
      string,
      NonNullable<
        GetDataForReportQuery["user"]
      >["domain"]["testResults"][number]
    >()
  );
};

const makeEvidenceIdEvidenceToMap = (
  evidenceRequests: PresentedReportEvidenceResults
) =>
  evidenceRequests.reduce(
    (map, evidence) => map.set(evidence.evidenceRequestId, evidence),
    new Map<string, PresentedReportEvidenceResults[number]>()
  );

export const getControlMap = (
  complianceStandard: NonNullable<GetDataForReportQuery["complianceStandard"]>
) =>
  complianceStandard.principles
    .reduce(
      requirementReducer,
      [] as NonNullable<
        GetDataForReportQuery["complianceStandard"]
      >["principles"][number]["requirements"]
    )
    .reduce(
      controlReducer,
      new Map<
        string,
        NonNullable<
          GetDataForReportQuery["complianceStandard"]
        >["principles"][number]["requirements"][number]["controls"][number]
      >()
    );

const requirementReducer = (
  array: NonNullable<
    GetDataForReportQuery["complianceStandard"]
  >["principles"][number]["requirements"],
  principle: NonNullable<
    GetDataForReportQuery["complianceStandard"]
  >["principles"][number]
) => array.concat(...principle.requirements);

const controlReducer = (
  map: Map<
    string,
    NonNullable<
      GetDataForReportQuery["complianceStandard"]
    >["principles"][number]["requirements"][number]["controls"][number]
  >,
  requirement: NonNullable<
    GetDataForReportQuery["complianceStandard"]
  >["principles"][number]["requirements"][number]
) => {
  requirement.controls.forEach(control => {
    map.set(control.key, control);
  });
  return map;
};

export const filterStandard = (
  complianceStandard: NonNullable<GetDataForReportQuery["complianceStandard"]>,
  domain: IDomainForReport
) => filterPrinciplesWithNoRequirements(complianceStandard, domain);

const filterControlsWithNoTests = (
  requirement: NonNullable<
    GetDataForReportQuery["complianceStandard"]
  >["principles"][number]["requirements"][number],
  domain: IDomainForReport
) => {
  const maybeFilteredControls = domain.showSuccessOnly
    ? requirement.controls.map(control =>
        filterFailingTestsFromControl(control, domain)
      )
    : requirement.controls.slice();
  const newControls = maybeFilteredControls;
  return {
    ...requirement,
    controls: newControls,
  } as NonNullable<
    GetDataForReportQuery["complianceStandard"]
  >["principles"][number]["requirements"][number];
};

const filterFailingTestsFromControl = (
  control: NonNullable<
    GetDataForReportQuery["complianceStandard"]
  >["principles"][number]["requirements"][number]["controls"][number],
  domain: IDomainForReport
) => {
  const filteredTests = control.tests.filter(test => {
    const testResult = domain.testIdToTestMap.get(test.testId);
    return isSome(testResult) && testResult.outcome === TestOutcome.PASS;
  });
  return {
    ...control,
    tests: filteredTests,
  } as NonNullable<
    GetDataForReportQuery["complianceStandard"]
  >["principles"][number]["requirements"][number]["controls"][number];
};

const filterRequirementsWithNoControls = (
  principle: NonNullable<
    GetDataForReportQuery["complianceStandard"]
  >["principles"][number],
  domain: IDomainForReport
) => {
  const newRequirements = principle.requirements
    .map(requirement => filterControlsWithNoTests(requirement, domain))
    .filter(requirement => requirement.controls.length > 0);
  return {
    ...principle,
    requirements: newRequirements,
  } as NonNullable<
    GetDataForReportQuery["complianceStandard"]
  >["principles"][number];
};

const filterPrinciplesWithNoRequirements = (
  complianceStandard: NonNullable<GetDataForReportQuery["complianceStandard"]>,
  domain: IDomainForReport
) => {
  const newPrinciples = complianceStandard.principles
    .map(principle => filterRequirementsWithNoControls(principle, domain))
    .filter(principle => principle.requirements.length > 0);
  return { ...complianceStandard, principles: newPrinciples };
};

export const replaceVarsinReportText = (
  str: string,
  domain: IDomainForReport
) => str.replace(/%COMPANY/g, domain.displayName);
