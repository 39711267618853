import React from "react";

import { GRID_SPACING } from "../../../alpaca/base/grid";
import { SearchResultsSummary } from "../inventory-list/tab-groups/search-results-summary";

interface IProps {
  searchString: string;
  numberResults: number;
}

export const VulnSearchResultSummary: React.FC<IProps> = ({
  searchString,
  numberResults,
}) => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      marginTop: `${2 * GRID_SPACING}px`,
    }}
  >
    <SearchResultsSummary
      searchString={searchString}
      numberResults={numberResults}
    />
  </div>
);
