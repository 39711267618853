import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import React from "react";
import styled from "styled-components";

import { TextLinkLike } from "../../../alpaca/components";
import { ItemSelectionWidget } from "./item-selection-widget";

interface IProps {
  emptyState?: Maybe<React.ReactNode>;
  selectableItems: string[];
  selectedItems: string[];
  textContent: React.ReactNode;
  optOutTextContent?: Maybe<React.ReactNode>;
  onOptOut?: Maybe<() => void>;
  onItemAdded: (itemIdentifier: string) => void;
  onItemRemoved: (itemIdentifier: string) => void;
}

export const MultiItemSelectPanel: React.FC<IProps> = ({
  emptyState,
  selectableItems,
  selectedItems,
  textContent,
  onItemRemoved,
  onItemAdded,
  optOutTextContent,
  onOptOut,
}) => {
  const maybeOptOut =
    selectedItems.length === 0 &&
    isSome(optOutTextContent) &&
    isSome(onOptOut) ? (
      <TextLinkLike onClick={onOptOut} as="div">
        {optOutTextContent}
      </TextLinkLike>
    ) : null;

  const hasNoItems = selectableItems.length + selectedItems.length === 0;

  return (
    <div>
      {textContent}
      <WidgetContainer>
        {hasNoItems ? (
          emptyState
        ) : (
          <ItemSelectionWidget
            selectableItems={selectableItems}
            selectedItems={selectedItems}
            onSelectItem={onItemAdded}
            onDeselectItem={onItemRemoved}
          />
        )}
      </WidgetContainer>
      {maybeOptOut}
    </div>
  );
};

const styles = {
  WIDGET_MARGIN_TOP: 24,
};

const WidgetContainer = styled.div`
  margin-top: ${styles.WIDGET_MARGIN_TOP}px;
`;
