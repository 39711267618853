import { Callout, Intent } from "@blueprintjs/core";
import { IconNames as BPIconNames } from "@blueprintjs/icons";
import type { Maybe } from "common/base/types/maybe";
import gql from "graphql-tag";
import { isEqual } from "lodash";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { GRID_SPACING } from "../../../alpaca/base/grid";
import { Button } from "../../../alpaca/components";
import { LogError } from "../../../errors";
import {
  ManagePoliciesV2Document,
  useSetPolicyAnswersMutation,
} from "../../../gen/components";
import type {
  AnswersMap,
  PolicyWizardError,
} from "./beta-policies-wizard-page";
import {
  getPolicyQuestionSchema,
  getQuestionnaireErrors,
} from "./beta-policies-wizard-page";
import type { Policy, PolicyInfoMap } from "./common";
import { Dialog, DialogBody } from "./policies-v2-styles";
import { PolicyAnswersList } from "./policies-wizard/policies-answers-list";
import { PoliciesWizardQuestionnaireStep } from "./policies-wizard/policies-wizard-questionnaire-step";

export const ViewAnswersDialog: React.FC<{
  onClose: () => void;
  policy?: Maybe<Policy>;
  policyInfoMap?: Maybe<PolicyInfoMap>;
}> = ({ onClose, policy, policyInfoMap }) => {
  const questionSchema = getPolicyQuestionSchema(policy?.policyType);

  const answersMap = getAnswersMap(policy, policyInfoMap) ?? {};

  const [isEditing, setEditing] = useState<boolean>(false);

  const [stagedAnswersMap, setStagedAnswersMap] =
    useState<AnswersMap>(answersMap);

  const [errors, setErrors] = useState<PolicyWizardError[]>([]);

  const [setPolicyAnswers] = useSetPolicyAnswersMutation({
    refetchQueries: [{ query: ManagePoliciesV2Document }],
  });

  const reset = () => {
    setEditing(false);
    setStagedAnswersMap(answersMap);
  };

  // After policies load, we need to update our staged answers
  useEffect(() => reset(), [policy]);

  if (!policy || !questionSchema) return null;

  return (
    <Dialog
      isOpen={true}
      onClose={() => {
        if (
          !isEqual(stagedAnswersMap, answersMap) &&
          !confirm(
            "Are you sure you want to close? Doing so could lose your changes."
          )
        ) {
          return;
        }
        onClose();
      }}
      title={`${isEditing ? "Edit " : ""}Answers for ${policy.title}`}
    >
      <DialogBody>
        {isEditing ? (
          <>
            <PoliciesWizardQuestionnaireStep
              errors={errors}
              questionnaireAnswers={stagedAnswersMap}
              questionSchema={questionSchema}
              setQuestionnaireAnswers={questionName => value =>
                setStagedAnswersMap(a => {
                  return { ...a, [questionName]: value };
                })}
            />
            <Callout
              icon={BPIconNames.ERROR}
              intent={Intent.PRIMARY}
              title={"Reminder"}
            >
              Are you also editing the contents of your policy? If so, use the
              "Upload new version" in the "..." actions menu instead of editing
              these answers.
            </Callout>
            <StyledFooter>
              <StyledFooterButton onClick={() => reset()}>
                Cancel
              </StyledFooterButton>
              <StyledFooterButton
                intent={Intent.SUCCESS}
                onClick={async () => {
                  const newErrors = getQuestionnaireErrors(
                    questionSchema,
                    stagedAnswersMap
                  );
                  setErrors(newErrors);
                  if (newErrors.length > 0) return;
                  try {
                    await setPolicyAnswers({
                      variables: {
                        answers: JSON.stringify(stagedAnswersMap),
                        policyType: policy.policyType,
                      },
                    });
                    setEditing(false);
                  } catch (err) {
                    LogError(err);
                  }
                }}
              >
                Save
              </StyledFooterButton>
            </StyledFooter>
          </>
        ) : (
          <>
            <PolicyAnswersList
              answersMap={answersMap}
              questionSchema={questionSchema}
            />
            <StyledFooter>
              <StyledFooterButton onClick={() => setEditing(true)}>
                Edit Answers
              </StyledFooterButton>
            </StyledFooter>
          </>
        )}
      </DialogBody>
    </Dialog>
  );
};

function getAnswersMap(
  policy: Maybe<Policy>,
  map: Maybe<PolicyInfoMap>
): Maybe<AnswersMap> {
  if (!policy || !map) return null;
  const policyTypeCamel = policy.policyType
    .replace("-policy", "")
    .replace("-plan", "")
    .replace("-program", "")
    .replace(/-(.)/g, $1 => $1.toUpperCase())
    .replace(/-/g, "");
  return map[policyTypeCamel];
}

const StyledFooter = styled.div`
  justify-content: center;
  display: flex;
  margin-top: ${4 * GRID_SPACING}px;
  width: 100%;
`;

const StyledFooterButton = styled(Button).attrs({ large: true })`
  margin: 0 ${GRID_SPACING}px;
`;

gql`
  mutation setPolicyAnswers($answers: String!, $policyType: String!) {
    setPolicyAnswers(answers: $answers, policyType: $policyType)
  }
`;
