import { Intent } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import React from "react";

import { LogError } from "../../../../errors";
import type { Credentials } from "../../../../gen/components";
import { useFetchHerokuAppListQuery } from "../../../../gen/components";
import { apolloErrorHasErrorCode } from "../../../../helpers/errorHandling";
import { AppToaster } from "../../../../helpers/toaster";
import { FullPageSpinner } from "../../../helpers/FullPageSpinner";
import type { IHerokuApp, IHerokuScanConfig } from "./heroku-apps-selector";
import { HerokuAppsTable } from "./heroku-apps-table";

type herokuCredential = { __typename?: Maybe<"credentials"> } & Pick<
  Credentials,
  "id" | "service" | "lastUpdated" | "metadata"
>;
interface IHerokuAppsDataFetcherProps {
  herokuCredentials: herokuCredential;
  onClose?: Maybe<() => void>;
  updateHerokuScanConfig: (scanConfig: IHerokuScanConfig) => Promise<void>;
}

export function HerokuAppsDataFetcher(props: IHerokuAppsDataFetcherProps) {
  const { herokuCredentials, onClose, updateHerokuScanConfig } = props;
  const { metadata } = herokuCredentials;
  const metadataWithDefault = metadata ?? JSON.stringify({});
  const metadataObject = JSON.parse(metadataWithDefault);

  const {
    data: data,
    loading: loading,
    error: error,
  } = useFetchHerokuAppListQuery();

  if (error) {
    if (apolloErrorHasErrorCode("BAD_USER_INPUT", error)) {
      // with the new modal flow we can just close and toast with specific message
      AppToaster.show({
        icon: "tick",
        intent: Intent.DANGER,
        message: "Please reauthorize/reconnect your Heroku account",
        timeout: 2500,
      });
    } else {
      LogError(error);
    }
    onClose?.();
    return null;
  }

  if (loading) {
    return <FullPageSpinner />;
  }

  if (isSome(data) && isSome(data.organization.herokuApps)) {
    const appList = data.organization.herokuApps.map(app => {
      return {
        id: app.id,
        name: app.name,
      };
    });
    const previouslySelectedRows: IHerokuApp[] =
      metadataObject.appIdsToScan.map((scanAppId: string) =>
        appList.find(app => app.id === scanAppId)
      ) ?? appList;
    const scanAllAppsToggle = metadataObject.scanAllApps ?? true;

    return (
      <HerokuAppsTable
        appList={appList}
        previouslySelectedRows={previouslySelectedRows}
        updateHerokuScanConfig={updateHerokuScanConfig}
        scanAllAppsToggle={scanAllAppsToggle}
      />
    );
  }
  // There should never be no data returned if heroku credentials exist
  throw Error("Internal Server Error");
}
