import { InputGroup, Switch } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React, { useMemo, useState } from "react";
import styled from "styled-components";

interface IProps {
  selectedItems: string[];
  selectableItems: string[];
  onSelectItem: (item: string) => void;
  onDeselectItem: (item: string) => void;
}

export const ItemSelectionWidget: React.FC<IProps> = ({
  selectableItems,
  selectedItems,
  onSelectItem,
  onDeselectItem,
}) => {
  const [searchText, setSearchText] = useState("");
  const selectedButNoLongerAvailable = useMemo(() => {
    const availableSet = new Set(selectableItems);
    return selectedItems.filter(item => !availableSet.has(item));
  }, [selectableItems, selectedItems]);

  const allItems = useMemo(
    () => [...selectedButNoLongerAvailable, ...selectableItems],
    [selectedButNoLongerAvailable, selectableItems]
  );

  const selectedSet = useMemo(() => new Set(selectedItems), [selectedItems]);

  const itemList = allItems
    .filter(item => item.includes(searchText))
    .map(item => {
      const itemIsSelected = selectedSet.has(item);
      return (
        <Flex key={item}>
          <Switch
            checked={itemIsSelected}
            label={item}
            onChange={e => {
              if (e.currentTarget.checked) {
                onSelectItem(item);
              } else {
                onDeselectItem(item);
              }
            }}
          />
        </Flex>
      );
    });

  return (
    <Container>
      <InputGroup
        leftIcon={IconNames.SEARCH}
        type="search"
        value={searchText}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setSearchText(e.target.value)
        }
      />
      <ListContainer>{itemList}</ListContainer>
    </Container>
  );
};

const styles = {
  LIST_WIDTH: 400,
  LIST_ITEM_PADDING: 6,
  LIST_MAX_HEIGHT: 500,
};

const Container = styled.div`
  width: ${styles.LIST_WIDTH}px;
`;

const ListContainer = styled.div`
  max-height: ${styles.LIST_MAX_HEIGHT}px;
  overflow-y: auto;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  padding: ${styles.LIST_ITEM_PADDING}px;
`;
