import { Dialog, Spinner } from "@blueprintjs/core";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import React, { useState } from "react";

import { TextLinkLikeBlack } from "../../../alpaca/components";
import { LogError } from "../../../errors";
import { useFetchEndpointApplicationsQuery } from "../../../gen/components";
import { InfoNotAvailable } from "../access/access-components/styles";
import { ComputerApplicationList } from "./computer-application-list";
import type { IUserWorkstation } from "./utils";

interface IProps {
  workstation: IUserWorkstation;
}

export const ApplicationsNumber: React.FC<IProps> = ({ workstation }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { error, loading, data } = useFetchEndpointApplicationsQuery({
    variables: { endpointId: workstation.id },
    skip: isSome(workstation.applicationNames),
  });

  if (error) {
    LogError(error);
    return null;
  }
  if (loading) {
    return <Spinner />;
  }

  const applicationNames =
    workstation.applicationNames ??
    data?.organization.osqueryEndpointApplicationData;
  if (!isSome(applicationNames)) {
    return <InfoNotAvailable />;
  }

  return (
    <>
      <TextLinkLikeBlack onClick={() => setIsOpen(true)}>
        {applicationNames.length}
      </TextLinkLikeBlack>

      <Dialog
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title={"Applications"}
      >
        <ComputerApplicationList applicationNames={applicationNames} />
      </Dialog>
    </>
  );
};

gql`
  query fetchEndpointApplications($endpointId: String!) {
    organization {
      id
      osqueryEndpointApplicationData(endpointId: $endpointId)
    }
  }
`;
