import React from "react";

import { VANTA_COLORS } from "../../../alpaca/base/colors";
import { BodyShortText, H2 } from "../../../alpaca/components";
import CompletionTick from "../../../static/images/icons/completion-check.svg";
import { ImageContainer, InformationalContainer } from "./step-intro-panel";

interface IProps {
  textContent: React.ReactNode;
}

export const StepCompletionPanel: React.FC<IProps> = ({ textContent }) => (
  <InformationalContainer>
    <ImageContainer>
      <CompletionTick />
    </ImageContainer>
    <H2>Congrats!</H2>
    <BodyShortText color={VANTA_COLORS.TEXT_DESCRIPTION}>
      {textContent}
    </BodyShortText>
  </InformationalContainer>
);
