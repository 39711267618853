import { Intent, Spinner, Tab } from "@blueprintjs/core";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import React, { useState } from "react";
import styled, { createGlobalStyle } from "styled-components";

import { GRID_SPACING } from "../../../alpaca/base/grid";
import { Button, Tabs } from "../../../alpaca/components";
import { LogError } from "../../../errors";
import type {
  FetchDataForPeoplePageQueryHookResult,
  GetAllLinkableHrUsersQuery,
  HrUserActionInput,
} from "../../../gen/components";
import {
  FetchDataForPeoplePageDocument,
  GetAllLinkableHrUsersDocument,
  useApplyHrUserActionsMutation,
  useGetAllLinkableHrUsersQuery,
} from "../../../gen/components";
import { AppToaster } from "../../../helpers/toaster";
import { ManageHrUsersTable } from "./manage-hr-users-table";
import { Dialog } from "./shared/dialog";

export type HrUser = NonNullable<
  GetAllLinkableHrUsersQuery["organization"]
>["allLinkableHrUsers"][number];

export type Action = Omit<HrUserActionInput, "hrUserUniqueId">;
export type HrUserActionsMap = { [hrUserId: string]: Action };

enum TabId {
  UNLINKED = "UNLINKED",
  LINKED = "LINKED",
}

interface IProps {
  isOpen: boolean;
  onClose(): void;
  peopleQuery: FetchDataForPeoplePageQueryHookResult;
}

export const ManageHrUsersDialog: React.FC<IProps> = ({
  isOpen,
  onClose,
  peopleQuery,
}) => {
  const { data, loading } = useGetAllLinkableHrUsersQuery();
  const { data: peopleData, loading: peopleLoading } = peopleQuery;
  const [selectedTab, setSelectedTab] = useState<TabId>(TabId.UNLINKED);
  // For each tab, a mapping from HR user uniqueId to the
  // selected action for that HR user.
  const [hrUserActionsMaps, setHrUserActionsMaps] = useState<{
    [k in TabId]: HrUserActionsMap;
  }>({
    [TabId.UNLINKED]: {},
    [TabId.LINKED]: {},
  });
  // Given a tab, returns a function that sets an action for a
  // specific HR user on that tab.
  const setActionForHrUserFn =
    (tab: TabId) => (hrUser: HrUser, action: Action) =>
      setHrUserActionsMaps({
        ...hrUserActionsMaps,
        [selectedTab]: {
          ...hrUserActionsMaps[selectedTab],
          [hrUser.uniqueId]: action,
        },
      });

  const [applyHrUserActions, mutationResult] = useApplyHrUserActionsMutation({
    refetchQueries: [
      { query: GetAllLinkableHrUsersDocument },
      { query: FetchDataForPeoplePageDocument },
    ],
    onCompleted: () => {
      AppToaster.show({
        intent: Intent.SUCCESS,
        message: "All updates to HR data applied",
      });
      setHrUserActionsMaps({
        ...hrUserActionsMaps,
        [selectedTab]: {},
      });
    },
  });

  let dialogBody: JSX.Element;
  const footerActions: JSX.Element[] = [];
  if (loading || !isSome(data) || peopleLoading || !isSome(peopleData)) {
    dialogBody = (
      <StyledSpinnerContainerDiv>
        <Spinner size={Spinner.SIZE_LARGE} />
      </StyledSpinnerContainerDiv>
    );
  } else {
    const hrUsers = data.organization.allLinkableHrUsers;
    const linkableUsers = peopleData.organization.users.filter(
      user => !Boolean(user.isNotHuman)
    );
    dialogBody = (
      <Tabs
        id="manage-hr-users-tabs"
        selectedTabId={selectedTab}
        onChange={newId => {
          setSelectedTab(newId as TabId);
        }}
        renderActiveTabPanelOnly
      >
        <Tab
          id={TabId.UNLINKED}
          title="Unlinked"
          panel={
            <ManageHrUsersTable
              key="unlinked-table"
              hrUsers={hrUsers.filter(hrUser => !isSome(hrUser.linkedUser))}
              linkableUsers={linkableUsers}
              hrUserActionsMap={hrUserActionsMaps[TabId.UNLINKED]}
              setActionForHrUser={setActionForHrUserFn(TabId.UNLINKED)}
            />
          }
        />
        <Tab
          id={TabId.LINKED}
          title="Linked"
          panel={
            <ManageHrUsersTable
              key="linked-table"
              hrUsers={hrUsers.filter(hrUser => isSome(hrUser.linkedUser))}
              linkableUsers={linkableUsers}
              hrUserActionsMap={hrUserActionsMaps[TabId.LINKED]}
              setActionForHrUser={setActionForHrUserFn(TabId.LINKED)}
            />
          }
        />
      </Tabs>
    );
    footerActions.push(
      ...[
        <Button
          key="cancel-button"
          onClick={onClose}
          text="Cancel"
          style={{ width: 10 * GRID_SPACING }}
        />,
        <Button
          key="save-button"
          onClick={() => {
            const allActions: HrUserActionInput[] = Object.entries(
              hrUserActionsMaps[selectedTab]
            ).map(([hrUserUniqueId, action]) => {
              return {
                ...action,
                hrUserUniqueId,
              };
            });
            applyHrUserActions({
              variables: { actions: allActions },
            }).catch(LogError);
          }}
          intent={Intent.PRIMARY}
          disabled={Object.keys(hrUserActionsMaps[selectedTab]).length === 0}
          loading={mutationResult.loading}
          text="Save"
          style={{ width: 10 * GRID_SPACING }}
        />,
      ]
    );
  }
  return (
    <>
      <BulkManagementDialogStyle />
      <Dialog
        body={dialogBody}
        enforceFocus={false}
        footerActions={footerActions}
        isOpen={isOpen}
        onClose={onClose}
        title="Merge HR data"
        portalClassName={BULK_MANAGEMENT_DIALOG_CLASSNAME}
      />
    </>
  );
};

const styles = {
  DIALOG_WIDTH: 1360,
  BODY_MARGIN: 4 * GRID_SPACING,
  HEADER_HEIGHT: 5 * GRID_SPACING,
  FOOTER_HEIGHT: 6 * GRID_SPACING,
};

export const BULK_MANAGEMENT_DIALOG_CLASSNAME = "bulk-management-dialog";

export const BulkManagementDialogStyle = createGlobalStyle`
  .${BULK_MANAGEMENT_DIALOG_CLASSNAME} {
    & * .bp3-dialog {
      width: ${styles.DIALOG_WIDTH}px;
    }
    form {
      height: calc(100% - ${styles.HEADER_HEIGHT}px);
    }
    & * .bp3-dialog-header {
      height: ${styles.HEADER_HEIGHT}px;
    }
    & * .bp3-dialog-body {
      margin: ${styles.BODY_MARGIN}px;
      height: calc(100% - ${styles.HEADER_HEIGHT + styles.FOOTER_HEIGHT}px)
    }
  }
`;

const StyledSpinnerContainerDiv = styled.div`
  height: 600px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

gql`
  query getAllLinkableHrUsers {
    organization {
      id
      allLinkableHrUsers {
        id
        displayName
        email
        familyName
        givenName
        service
        startDate
        uniqueId
        linkedUser {
          id
          displayName
        }
      }
    }
  }
`;

gql`
  mutation applyHrUserActions($actions: [HrUserActionInput!]!) {
    bulkApplyHrUserActions(input: { actions: $actions }) {
      status
    }
  }
`;
