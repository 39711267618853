import { isSome } from "common/base/types/maybe";
import React from "react";

import type { GetDataForReportQuery } from "../../../../../gen/components";
import { ControlListItemDescription } from "../../../common";
import type { IDomainForReport } from "../../../helpers/helpers";
import { replaceVarsinReportText } from "../../../helpers/helpers";
import { VantaReportEvidenceListItemStatus } from "./vanta-report-evidence-list-item-status";

interface IProps {
  evidenceId: NonNullable<
    GetDataForReportQuery["complianceStandard"]
  >["principles"][number]["requirements"][number]["controls"][number]["evidence"][number];
  domain: IDomainForReport;
}

export const VantaReportEvidenceListItem: React.FunctionComponent<IProps> = ({
  evidenceId,
  domain,
}) => {
  const evidenceMap = domain.evidenceIdToEvidenceMap;
  const evidence = evidenceMap.get(evidenceId);
  if (!isSome(evidence)) {
    return <></>;
  }

  return (
    <div className="vrp-test-list-item vrp-test-list-item-no-description">
      <ControlListItemDescription>
        <strong>{replaceVarsinReportText(evidence.title, domain)}</strong>
      </ControlListItemDescription>
      <VantaReportEvidenceListItemStatus evidenceOutcome={evidence.outcome} />
    </div>
  );
};
