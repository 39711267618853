import { Intent } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import { groupBy, keyBy } from "lodash";
import React, { useMemo, useState } from "react";

import { GRID_SPACING } from "../../../../alpaca/base/grid";
import { Button } from "../../../../alpaca/components";
import { LogError } from "../../../../errors";
import type {
  AllAccessInfoQuery,
  GetAccessAccountsQuery,
} from "../../../../gen/components";
import {
  AllAccessInfoDocument,
  GetAccessAccountsDocument,
  useSetServiceAccountMappingsMutation,
} from "../../../../gen/components";
import { AppToaster } from "../../../../helpers/toaster";
import {
  BULK_MANAGEMENT_DIALOG_CLASSNAME,
  BulkManagementDialogStyle,
} from "../../people/manage-hr-users-dialog";
import { Dialog } from "../../people/shared/dialog";
import type { VantaUser } from "../access-page";
import { BulkLinkAccountsTable } from "./bulk-link-account-table";

export type LinkableAccount = NonNullable<
  AllAccessInfoQuery["organization"]
>["unlinkedServiceAccounts"][number];

export type AccountActionsMap = { [accountId: string]: string };

interface IProps {
  accounts: LinkableAccount[];
  isOpen: boolean;
  onClose(): void;
  users: VantaUser[];
}

export const BulkLinkAccountsDialog: React.FC<IProps> = ({
  accounts,
  users,
  isOpen,
  onClose,
}) => {
  const [accountIdToUserIdValue, setAccountIdToUserIdValue] =
    useState<AccountActionsMap>({});

  const [setAccountMappings, mutationResult] =
    useSetServiceAccountMappingsMutation({
      onCompleted: () => {
        AppToaster.show({
          intent: Intent.SUCCESS,
          message: "All updates to account links applied",
        });
        setAccountIdToUserIdValue({});
      },
    });

  const usersById = useMemo(() => keyBy(users, "id"), [users]);

  if (accounts.length === 0) {
    // if we update and there are no more accounts, close the dialog
    onClose();
  }

  const getOwnerAndUserId = (
    rawUserId: string
  ): { owner: Maybe<VantaUser>; userId: string } => {
    if (rawUserId === "NON_HUMAN") {
      return { owner: null, userId: "machine" };
    } else if (rawUserId === "EXT_USER") {
      return { owner: null, userId: "external" };
    } else {
      return { owner: usersById[rawUserId], userId: rawUserId };
    }
  };

  const applyAccountActions = () => {
    const serviceAccountIds = Array.from(Object.keys(accountIdToUserIdValue));
    const userIds = Array.from(Object.values(accountIdToUserIdValue));
    setAccountMappings({
      variables: { userIds, serviceAccountIds },
      refetchQueries: [{ query: AllAccessInfoDocument }],
      update: (cache, result) => {
        if (Boolean(result.data?.setServiceAccountMappings)) {
          const servicesToUpdate = groupBy(
            accounts.filter(account => serviceAccountIds.includes(account.id)),
            a => a.service
          );

          Object.keys(servicesToUpdate).map(async service => {
            const domainToUpdate = cache.readQuery<GetAccessAccountsQuery>({
              query: GetAccessAccountsDocument,
              variables: {
                service,
              },
            })?.organization;
            if (!isSome(domainToUpdate)) {
              return;
            }
            const updatedAccountIdsForService = new Set(
              servicesToUpdate[service].map(account => account.id)
            );
            const newServiceAccounts = domainToUpdate.serviceAccounts.map(
              account => {
                if (updatedAccountIdsForService.has(account.id)) {
                  const { owner, userId } = getOwnerAndUserId(
                    accountIdToUserIdValue[account.id]
                  );
                  return {
                    ...account,
                    owner,
                    userId,
                    userLinkDate: new Date().toISOString(),
                  };
                } else {
                  return account;
                }
              }
            );
            cache.writeQuery<GetAccessAccountsQuery>({
              query: GetAccessAccountsDocument,
              data: {
                organization: {
                  ...domainToUpdate,
                  serviceAccounts: newServiceAccounts,
                },
              },
            });
          });
        }
      },
    }).catch(LogError);
  };

  const setAccountIdMapping = (accountId: string, mapping: Maybe<string>) => {
    if (isSome(mapping)) {
      setAccountIdToUserIdValue({
        ...accountIdToUserIdValue,
        [accountId]: mapping,
      });
    } else {
      const copy = { ...accountIdToUserIdValue };
      delete copy[accountId];
      setAccountIdToUserIdValue(copy);
    }
  };

  const linkableUsers = users.filter(user => !Boolean(user.isNotHuman));
  const dialogBody = (
    <BulkLinkAccountsTable
      key="unlinked-accounts-table"
      accounts={accounts}
      linkableUsers={linkableUsers}
      accountIdsMap={accountIdToUserIdValue}
      setMapping={setAccountIdMapping}
    />
  );
  const footerActions = [
    <Button
      key="cancel-button"
      onClick={onClose}
      text="Cancel"
      style={{ width: 10 * GRID_SPACING }}
    />,
    <Button
      key="save-button"
      onClick={() => {
        applyAccountActions();
      }}
      intent={Intent.PRIMARY}
      disabled={Object.keys(accountIdToUserIdValue).length === 0}
      loading={mutationResult.loading}
      text="Save"
      style={{ width: 10 * GRID_SPACING }}
    />,
  ];
  return (
    <>
      <BulkManagementDialogStyle />
      <Dialog
        body={dialogBody}
        enforceFocus={false}
        footerActions={footerActions}
        isOpen={isOpen}
        onClose={onClose}
        title="Link accounts"
        portalClassName={BULK_MANAGEMENT_DIALOG_CLASSNAME}
      />
    </>
  );
};

gql`
  mutation setServiceAccountMappings(
    $serviceAccountIds: [String!]!
    $userIds: [String!]!
  ) {
    setServiceAccountMappings(
      serviceAccountIds: $serviceAccountIds
      userIds: $userIds
    )
  }
`;
