import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import React from "react";
import { Link } from "react-scroll";

import { SCROLL_DURATION } from "./vanta-report-sidebar";

interface IProps {
  linkToKey: string;
  text: string;
  offset?: Maybe<number>;
  onClick?: Maybe<() => void>;
}

export const VantaReportSidebarLink: React.FunctionComponent<IProps> = ({
  linkToKey,
  text,
  onClick,
  offset,
}) => (
  <Link
    activeClass="vrp-sidebar-active-item"
    className="vrp-menu-item"
    to={linkToKey}
    smooth={true}
    duration={SCROLL_DURATION}
    offset={offset ?? undefined}
    spy={true}
    onClick={() => {
      if (isSome(onClick)) {
        onClick();
      }
    }}
  >
    {text}
  </Link>
);
