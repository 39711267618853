import gql from "graphql-tag";
import React from "react";

import { LogError } from "../../../errors";
import { useFetchPoliciesQuery } from "../../../gen/components";
import { FailDataRow } from "../fail-data-row";

export const PolicyTestEntities: React.FC<{
  entityIds: string[];
  testId: string;
  entityType: "Policy";
  allowWhitelisting: boolean;
  first: number;
}> = ({ entityType, entityIds, testId, allowWhitelisting, first }) => {
  const { loading, error, data } = useFetchPoliciesQuery();

  if (loading) {
    return <div />;
  }
  if (error) {
    LogError(error);
    return null;
  }
  if (!data?.organization) {
    return <div />;
  }

  const entityIdSet = new Set(entityIds);
  const elements = data.organization.policies
    .filter(p => entityIdSet.has(p.id))
    .slice(0, first)
    .map(p => (
      <FailDataRow
        key={p.id}
        entityId={p.id}
        entityType={entityType}
        testId={testId}
        allowWhitelisting={allowWhitelisting}
      >
        {p.title}
      </FailDataRow>
    ));

  return <>{elements}</>;
};

gql`
  query fetchPolicies {
    organization {
      id
      policies {
        id
        title
      }
    }
  }
`;
