import type { IconName } from "@blueprintjs/core";
import { Button, Card, Classes, Overlay } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import React from "react";
import styled from "styled-components";

interface IProps {
  buttonText: string;
  className?: Maybe<string>;
  iconName?: Maybe<IconName>;
  minimal: boolean;
  overlayContent: JSX.Element;
}

interface IState {
  isOpen: boolean;
}

const StyledButton = styled(Button)`
  white-space: nowrap;
`;

export class OverlayHelper extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.toggleOverlay = this.toggleOverlay.bind(this);
  }

  public render() {
    return (
      <>
        <StyledButton
          className={this.props.className ?? undefined}
          icon={this.props.iconName ?? undefined}
          minimal={this.props.minimal}
          text={this.props.buttonText}
          onClick={this.toggleOverlay}
        />
        <Overlay
          className={`${Classes.OVERLAY_SCROLL_CONTAINER}`}
          isOpen={this.state.isOpen}
          onClose={this.toggleOverlay}
        >
          <Card className="overlay-transition" elevation={4}>
            {this.props.overlayContent}
          </Card>
        </Overlay>
      </>
    );
  }

  private toggleOverlay(
    event: Maybe<{ preventDefault: () => void; stopPropagation: () => void }>
  ) {
    if (isSome(event)) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }
}
