import type { ItemListRenderer } from "@blueprintjs/select";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import { partition } from "lodash";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { compareTwoStrings } from "string-similarity";
import styled from "styled-components";

import { BASE_PALETTE } from "../../../alpaca/base/colors";
import { GRID_SPACING } from "../../../alpaca/base/grid";
import { IconNames } from "../../../alpaca/components";
import { LogError } from "../../../errors";
import type { PeoplePageFieldsFragment } from "../../../gen/components";
import { HrUserAction } from "../../../gen/components";
import { DropdownButton } from "../components/dropdown-button";
import type {
  Action,
  HrUser,
  HrUserActionsMap,
} from "./manage-hr-users-dialog";
import { DATE_FORMAT } from "./shared/common";

// The threshold for whether to suggest to link a user or not. String similarity
// is measured with the Dice coefficient, which is on a scale from 0 to 1.
//
// The current threshold is pretty arbitrarily chosen; it's probably worth
// seeing what's being suggested/not suggested for customers and tweaking this.
const SUGGESTION_THRESHOLD = 0.5;

// Since users are added through the create user dialog and not in bulk,
// augment the type of the bulk apply actions mutation to include this option.
const ADD_NEW_USER_OPTION = "ADD_NEW_USER";
type MenuOption = Action | typeof ADD_NEW_USER_OPTION;

interface IProps {
  hrUser: HrUser;
  linkableUsersById: { [k: string]: PeoplePageFieldsFragment };
  sortedLinkableUsers: PeoplePageFieldsFragment[];
  hrUserActionsMap: HrUserActionsMap;
  setActionForHrUser(hrUser: HrUser, action: Action): void;
  setHrUserToAdd(hrUser: HrUser): void;
}

export const HrUserActionsDropdown: React.FC<IProps> = ({
  hrUser,
  linkableUsersById,
  sortedLinkableUsers,
  hrUserActionsMap,
  setActionForHrUser,
  setHrUserToAdd,
}) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const linkableUserSuggestionIds = useMemo(() => {
    // Compute linkable suggestions lazily when the menu is opened so that
    // we're not calculating it for all HR users at once when the dialog opens
    if (!menuIsOpen) {
      return new Set<string>();
    }
    return new Set(
      sortedLinkableUsers
        .filter(
          user =>
            compareTwoStrings(user.displayName!, hrUser.displayName!) >
            SUGGESTION_THRESHOLD
        )
        .map(user => user.id)
    );
  }, [sortedLinkableUsers, menuIsOpen]);

  const menuOptions: MenuOption[] = useMemo(() => {
    // Compute the dropdown values lazily too when the menu is opened so that
    // we're not calculating it for all HR users at once when the dialog opens
    if (!menuIsOpen) {
      return [];
    }
    return [
      ADD_NEW_USER_OPTION,
      { action: HrUserAction.markOutOfScope },
      ...sortedLinkableUsers.map(user => {
        return {
          action: HrUserAction.linkToUser,
          userId: user.id,
        };
      }),
    ];
  }, [sortedLinkableUsers, menuIsOpen]);

  const optionRenderer = (option: MenuOption) => {
    if (option === ADD_NEW_USER_OPTION) {
      return "Add a new person";
    } else if (option.action === HrUserAction.markOutOfScope) {
      return "Mark as out of scope";
    } else if (option.action === HrUserAction.linkToUser) {
      if (!isSome(option.userId) || !(option.userId in linkableUsersById)) {
        LogError(
          new Error("LINK_TO_USER HR user action did not specify a valid user"),
          false
        );
        return null;
      }
      const { displayName, email, startDate } =
        linkableUsersById[option.userId];
      const formattedStartDate = moment(startDate).utc().format(DATE_FORMAT);
      return `${displayName} (${email}), added on ${formattedStartDate}`;
    } else {
      LogError(new Error("Unknown HR user action"));
      return null;
    }
  };
  const getIconForOption = (option: MenuOption) => {
    if (option === ADD_NEW_USER_OPTION) {
      return IconNames.PLUS;
    }

    if (option.action === HrUserAction.markOutOfScope) {
      return IconNames.PERSON_ALERT;
    }

    return null;
  };

  const menuContentRenderer: ItemListRenderer<Maybe<MenuOption>> = ({
    filteredItems,
    renderItem,
  }) => {
    const [linkOptions, nonLinkOptions] = partition(
      filteredItems,
      option =>
        option !== ADD_NEW_USER_OPTION &&
        option?.action === HrUserAction.linkToUser
    );
    const suggestedLinkOptions = linkOptions.filter(option => {
      if (option === ADD_NEW_USER_OPTION || !isSome(option?.userId)) {
        LogError(
          new Error(
            "Got an unexpected HR user action, this should never happen"
          ),
          false
        );
        return false;
      }
      return linkableUserSuggestionIds.has(option!.userId);
    });

    return (
      <>
        {nonLinkOptions.map(renderItem)}
        {suggestedLinkOptions.length > 0 ? (
          <>
            <StyledMenuHeaderDiv>Suggestions</StyledMenuHeaderDiv>
            {suggestedLinkOptions.map(renderItem)}
          </>
        ) : null}
        {linkOptions.length > 0 ? (
          <>
            <StyledMenuHeaderDiv>Alphabetical</StyledMenuHeaderDiv>
            {linkOptions.map(renderItem)}
          </>
        ) : null}
      </>
    );
  };

  const actionItem = hrUserActionsMap[hrUser.uniqueId];
  const linkedUser = hrUser?.linkedUser;
  const selectedOption = isSome(actionItem)
    ? actionItem.action === HrUserAction.unlinkUser
      ? null
      : actionItem
    : isSome(linkedUser)
    ? { action: HrUserAction.linkToUser, userId: linkedUser.id }
    : null;

  return (
    <DropdownButton
      options={menuOptions}
      selectedOption={selectedOption}
      onOptionSelect={option => {
        if (!isSome(option)) {
          return setActionForHrUser(hrUser, {
            action: HrUserAction.unlinkUser,
          });
        }
        if (option === ADD_NEW_USER_OPTION) {
          return setHrUserToAdd(hrUser);
        }
        return setActionForHrUser(hrUser, option);
      }}
      optionRenderer={optionRenderer}
      nullIcon={IconNames.CLOSE}
      nullOption="Clear"
      filterable={true}
      menuContentRenderer={menuContentRenderer}
      getIconForOption={getIconForOption}
      defaultText={"Find or add a person in Vanta"}
      onOpening={() => setMenuIsOpen(true)}
      buttonWidth={612}
      menuWidth={612}
      styleOnSelect
    />
  );
};

export const StyledMenuHeaderDiv = styled.li`
  height: 24px;
  text-transform: uppercase;
  background-color: ${BASE_PALETTE.VAPE};
  padding-left: ${2 * GRID_SPACING}px;
  color: ${BASE_PALETTE.SMOKE};
  font-size: 12;
  display: flex;
  align-items: center;
`;
