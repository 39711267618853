import { RISK_ID_TO_HEADING } from "common/schemas/riskRegister/risk-register-content";
import moment from "moment";
import React from "react";
import styled from "styled-components";

import { VANTA_COLORS } from "../../../../alpaca/base/colors";
import { BodyText } from "../../../../alpaca/components";
import type { GetInfoForRiskAssessmentReportQuery } from "../../../../gen/components";
import { lastNameSort } from "../../../../helpers/user-sort-functions";
import { DataTable } from "../../components/data-table";
import { RiskEvaluationMeter } from "../risk-evaluation-meter";
import {
  TABLE_STYLES,
  TableHeading,
  TableNormalText,
  TIME_FORMAT,
} from "../table-styles";
import { getRiskScore } from "./risk-score";

type Scenario = NonNullable<
  GetInfoForRiskAssessmentReportQuery["organization"]
>["rrv3RiskScenarios"][number];

type TableScenario = Scenario & { score: number };

interface IProps {
  scenarios: Scenario[];
}

const TABLE_COLUMN_ORDER = [
  "section",
  "description",
  "likelihood",
  "impact",
  "risk",
  "updatedAt",
  "updatedBy",
];

const TABLE_COLUMN_WIDTHS = [
  "160px",
  "280px",
  "110px",
  "110px",
  "70px",
  "130px",
  "150px",
];

const TABLE_HEADERS = {
  section: <TableHeading>Section</TableHeading>,
  description: <TableHeading>Risk Scenario</TableHeading>,
  likelihood: <TableHeading>Likelihood</TableHeading>,
  impact: <TableHeading>Impact</TableHeading>,
  risk: <TableHeading>Risk</TableHeading>,
  updatedAt: <TableHeading>Updated</TableHeading>,
  updatedBy: <TableHeading>Updated by</TableHeading>,
};

type ScenarioSortFn = (s1: TableScenario, s2: TableScenario) => number;

const TABLE_SORT_FUNCTIONS: { [k: string]: ScenarioSortFn } = {
  description: (s1, s2) => s1.description.localeCompare(s2.description),
  section: (s1, s2) =>
    RISK_ID_TO_HEADING[s1.riskCategoryId].localeCompare(
      RISK_ID_TO_HEADING[s2.riskCategoryId]
    ),
  likelihood: (s1, s2) => s1.likelihood - s2.likelihood,
  impact: (s1, s2) => s1.impact - s2.impact,
  risk: (s1, s2) => s1.score - s2.score,
  updatedAt: (s1, s2) =>
    moment(s1.updatedAt).valueOf() - moment(s2.updatedAt).valueOf(),
  updatedBy: (s1, s2) =>
    lastNameSort(
      s1.evaluator ?? { displayName: "" },
      s2.evaluator ?? { displayName: "" }
    ),
};

const rowCreator = (scenario: TableScenario) => {
  return {
    description: <TableNormalText>{scenario.description}</TableNormalText>,
    section: (
      <TableNormalText>
        {RISK_ID_TO_HEADING[scenario.riskCategoryId]}
      </TableNormalText>
    ),
    likelihood: <RiskEvaluationMeter value={scenario.likelihood} />,
    impact: <RiskEvaluationMeter value={scenario.impact} />,
    risk: <TableNormalText>{scenario.score}</TableNormalText>,
    updatedAt: (
      <TableNormalText>
        {moment(scenario.updatedAt).format(TIME_FORMAT)}
      </TableNormalText>
    ),
    updatedBy: (
      <TableNormalText>{scenario.evaluator?.displayName}</TableNormalText>
    ),
  };
};

export const RiskScenarioTable: React.FC<IProps> = ({ scenarios }) => {
  const table =
    scenarios.length > 0 ? (
      <DataTable
        columnOrder={TABLE_COLUMN_ORDER}
        columnWidths={TABLE_COLUMN_WIDTHS}
        columnSortFunctions={TABLE_SORT_FUNCTIONS}
        data={scenarios.map(s => {
          return { ...s, score: getRiskScore(s) };
        })}
        header={TABLE_HEADERS}
        createRow={rowCreator}
      />
    ) : (
      <BodyText color={VANTA_COLORS.TEXT_DESCRIPTION}>
        No scenarios have been identified for this category
      </BodyText>
    );
  return <Container>{table}</Container>;
};

const Container = styled.div`
  table {
    ${TABLE_STYLES}
  }
`;
