import { Classes } from "@blueprintjs/core";
import { Feature } from "common/base/types/gen";
import {
  VANTA_KEBAB_LABEL_KEYS,
  VANTA_KEBAB_OWNER_DOT_SUBSTITUTE,
} from "common/utils/tags";
import React from "react";
import styled from "styled-components";

import { FeatureGate } from "../../../helpers/feature-gating/feature-gate";
import { InfoIcon } from "../../helpers/InfoIcon";

const MultilineTooltipContent = styled.div`
  max-width: 400px;
`;

/**
 * GCP labels have wacky restrictions that prevent them from being 1:1 with AWS
 *
 * no spaces or uppercase letters means descriptive-labels-written-like-this
 * no @ means no full email address for owner
 */
export const DocsGCPLabel: React.FC = () => (
  <div className={Classes.DIALOG_BODY}>
    <p>
      Apply these labels to your GCP resources, and Vanta will update your
      inventory list.
    </p>
    <code>
      labels &#123;
      <br />
      &nbsp;
      <strong>{VANTA_KEBAB_LABEL_KEYS.vantaOwner}</strong>
      <InfoIcon
        content={
          <MultilineTooltipContent>
            <>
              First part of the resource owner's company email address (e.g.{" "}
              <code>olivia</code> for <code>olivia@company.com</code>).
              <br />
              Replace dots in the email with{" "}
              <code>{VANTA_KEBAB_OWNER_DOT_SUBSTITUTE}</code> instead (e.g.{" "}
              <code>olivia.smith@company.com</code> becomes{" "}
              <code>olivia{VANTA_KEBAB_OWNER_DOT_SUBSTITUTE}smith</code>).
            </>
          </MultilineTooltipContent>
        }
      />
      &nbsp;&nbsp;= "olivia"
      <br />
      &nbsp;
      <strong>{VANTA_KEBAB_LABEL_KEYS.vantaNonProd}</strong>
      <InfoIcon
        content={
          <>
            <code>true</code> for staging/dev resources, <code>false</code> for
            prod
          </>
        }
      />
      &nbsp;&nbsp;= "true"
      <br />
      &nbsp;
      <strong>{VANTA_KEBAB_LABEL_KEYS.vantaDescription}</strong>
      &nbsp;&nbsp;= "archive-of-ingested-events-handled-by-logs-queue"
      <br />
      &nbsp;
      <strong>{VANTA_KEBAB_LABEL_KEYS.vantaUserData}</strong>
      <InfoIcon
        content={
          <>
            <code>true</code> if the resource contains user data
          </>
        }
      />
      &nbsp;&nbsp;= "true"
      <br />
      &nbsp;
      <strong>{VANTA_KEBAB_LABEL_KEYS.vantaDataStored}</strong>
      <InfoIcon
        content={"A description of what user data this resource stores"}
      />
      &nbsp;&nbsp;= "user-emails-and-phone-numbers"
      <br />
      &nbsp;
      <FeatureGate features={[Feature.BetaHIPAA]}>
        <strong>{VANTA_KEBAB_LABEL_KEYS.vantaEPHI}</strong>
        <InfoIcon
          content={
            <>
              <code>true</code> if the resource contains electronic Protected
              Health Information (ePHI)
            </>
          }
        />
        &nbsp;&nbsp;= "true"
        <br />
        &nbsp;
      </FeatureGate>
      <strong>{VANTA_KEBAB_LABEL_KEYS.vantaNoAlert}</strong>
      <InfoIcon
        content={
          <MultilineTooltipContent>
            <p>
              Vanta will not monitor the resource and will exclude it from SOC 2
              scope. Only add this tag if you’re sure the resource is irrelevant
              to SOC 2, and please specify the reason that it's being excluded.
            </p>
          </MultilineTooltipContent>
        }
      />
      &nbsp;&nbsp;=
      "this-stores-our-favorite-foods-and-isnt-part-of-our-production-systems"
      <br />
      &#125;
    </code>
    <br />
    <br />
    Note that GCP enforces these restrictions on labels:
    <ul>
      <li>Keys and values cannot be longer than 63 characters each.</li>
      <li>
        Keys and values can only contain:
        <ul>
          <li>Lowercase letters</li>
          <li>Numeric characters</li>
          <li>Underscores</li>
          <li>Hyphens</li>
        </ul>
      </li>
      <li>International characters are allowed.</li>
      <li>Label keys must start with a lowercase letter.</li>
      <li>Label keys cannot be empty.</li>
    </ul>
  </div>
);
