export const PERSONNEL_PAGE_TAB_IDS = {
  DASHBOARD: "dashboard",
  GROUPS: "groups",
  EVERYBODY: "everybody",
};

export enum StatusFilterId {
  CURRENTLY_EMPLOYEED = "currently-employeed",
  INCOMPLETE_ONBOARDING = "incomplete-onboarding",
  NEEDS_DEPROVISIONING = "needs-deprovisioning",
  NOT_PEOPLE = "not-people",
  PREVIOUSLY_UNEMPLOYED = "previously-employed",
}

export const VIEWING_HR_DIALOG_SEARCH_PARAM = "viewingHrData";

export const PERSONNEL_PAGE_QUERY_PARAMS = {
  STATUS_FILTER: "statusFilter",
  GROUP_FILTER: "groupId",
  AUDIT_FILTER: "auditId",
  USER_FILTER: "userId",
};
