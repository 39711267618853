// 11/17/2020 @chrismychen separate file for managing policy doc schemas for policies v2 so that we can modify content independently of policies v1 features.
// to centralize with https://app.shortcut.com/vanta/story/12377/remove-beta-feature-gates-for-policies-v2-ga-and-other-clean-up
import { baseResponsibilityChoices } from "common/constants/policyInfoCommon";

import type { IBaseQuestion } from "../../../forms/interfaces";

interface IPolicyQuestion extends IBaseQuestion {
  type:
    | "CheckboxInput"
    | "SelectInput"
    | "SelectInputWithEditableOther"
    | "TextArea";
}

// The policy question schema does not support file upload questions, unlike the generic question info schema and question schema form.
export interface IPolicyQuestionSchema {
  id: string;
  questions: IPolicyQuestion[];
  version: string;
}

// Remove readonly from type so it can be used as choices
const responsibilityChoices = [...baseResponsibilityChoices];

export const AcceptableUsePolicyInfo: IPolicyQuestionSchema = {
  id: "acceptable-use-policy",
  questions: [
    {
      choices: responsibilityChoices,
      helper: "",
      id: "aup_overall-2018_07_23",
      label: "Responsible for this policy",
      name: "overallResponsibilityAcceptableUse",
      type: "SelectInputWithEditableOther",
    },
  ],
  version: "2018-07-23",
};

export const AssetManagementPolicyInfo: IPolicyQuestionSchema = {
  id: "asset-management-policy",
  questions: [
    {
      helper:
        "Vanta recommends your company require employees to password-protect their computers",
      id: "asset_management_screensaver-2018_04_17",
      label: "Require password-protected screensavers",
      name: "maybeScreensaverLock",
      type: "CheckboxInput",
    },
    {
      helper: "",
      id: "asset_management_av-2018_04_17",
      label: "Require antivirus software",
      name: "maybeAV",
      type: "CheckboxInput",
    },
    {
      helper: "",
      id: "asset_management_firewall-2018_04_17",
      label: "Require personal firewall",
      name: "maybePersonalFirewall",
      type: "CheckboxInput",
    },
    {
      choices: responsibilityChoices,
      helper: "",
      id: "asset_management_overall-2018_04_17",
      label: "Responsible for this policy",
      name: "overallResponsibilityAssetManagement",
      type: "SelectInputWithEditableOther",
    },
  ],
  version: "2018-04-17",
};

export const BackupPolicyInfo: IPolicyQuestionSchema = {
  id: "backup-policy",
  questions: [
    {
      helper: "",
      id: "backup_tested-2018_04_17",
      label: "Backups tested periodically",
      name: "backupsTested",
      type: "CheckboxInput",
    },
    {
      helper: "",
      id: "backup_endpoints-2018_07_27",
      label: "Employee computers backed up",
      name: "endpointBackups",
      type: "CheckboxInput",
    },
    {
      choices: responsibilityChoices,
      helper: "",
      id: "backup_overall-2018_04_17",
      label: "Responsible for this policy",
      name: "overallResponsibilityBackup",
      type: "SelectInputWithEditableOther",
    },
  ],
  version: "2018-04-17",
};

export const BusinessContinuityPlanInfo: IPolicyQuestionSchema = {
  id: "business-continuity-plan",
  questions: [
    {
      choices: ["Annual", "Semi-annual", "Quarterly", "Monthly"],
      helper: "",
      id: "bcp_cadence-2018_10_07",
      label: "Frequency of Business Continuity Plan tests",
      name: "businessContinuityCadence",
      type: "SelectInput",
    },
    {
      choices: responsibilityChoices,
      helper: "",
      id: "bcp_overall-2018_10_07",
      label: "Responsible for this policy",
      name: "overallResponsibilityBusinessContinuity",
      type: "SelectInputWithEditableOther",
    },
  ],
  version: "2018-10-07",
};

export const ChangeManagementPolicyInfo: IPolicyQuestionSchema = {
  id: "change-management-policy",
  questions: [
    {
      helper:
        "Are pull requests required to merge changes into the master branch?",
      id: "changemgmt_prs-2018_04_17",
      label: "Pull requests used",
      name: "pullRequestsUsed",
      type: "CheckboxInput",
    },
    {
      helper:
        "Do automated tests run before changes can be merged into the master branch?",
      id: "changemgmt_mergetests-2018_04_17",
      label: "Merges require automated tests",
      name: "autoTestsOnMerge",
      type: "CheckboxInput",
    },
    {
      id: "changemgmt_codereviews-2018_04_17",
      label: "Regular code reviews",
      name: "maybeCodeReviews",
      type: "CheckboxInput",
    },
    {
      choices: responsibilityChoices,
      helper: "",
      id: "changemgmt_overall-2018_04_17",
      label: "Responsible for this policy",
      name: "overallResponsibilityChangeManagement",
      type: "SelectInputWithEditableOther",
    },
  ],
  version: "2018-04-17",
};

export const CodeOfConductPolicyInfo: IPolicyQuestionSchema = {
  id: "code-of-conduct-policy",
  questions: [],
  version: "2018-06-16",
};

export const CryptographyPolicyInfo: IPolicyQuestionSchema = {
  id: "cryptography-policy",
  questions: [
    {
      choices: ["TLS v1.2", "TLS v1.1", "TLS v1.0", "SSL v3"],
      helper:
        "Vanta recommends using TLS v1.2 only because it's the only version that offers modern authenticated encryption (also known as AEAD).",
      id: "crypto_protocols-2018_04_17",
      label: "Oldest acceptable protocol",
      name: "oldestAcceptableProtocolCryptography",
      type: "SelectInput",
    },
    {
      choices: responsibilityChoices,
      helper: "",
      id: "crypto_overall-2018_04_17",
      label: "Responsible for this policy",
      name: "overallResponsibilityCryptography",
      type: "SelectInputWithEditableOther",
    },
  ],
  version: "2018-04-17",
};

export const DataClassificationPolicyInfo: IPolicyQuestionSchema = {
  id: "data-classification-policy",
  questions: [
    {
      choices: [...responsibilityChoices, "General Counsel"],
      helper: "",
      id: "data_classification_overall-2019_05_14",
      label: "Responsible for this policy",
      name: "overallResponsibilityDataClassification",
      type: "SelectInputWithEditableOther",
    },
  ],
  version: "2019-05-16",
};

export const DataProtectionPolicyInfo: IPolicyQuestionSchema = {
  id: "data-protection-policy",
  questions: [
    {
      choices: responsibilityChoices,
      helper: "",
      id: "data_protection_overall-2018_04_17",
      label: "Responsible for this policy",
      name: "overallResponsibilityDataProtection",
      type: "SelectInputWithEditableOther",
    },
  ],
  version: "2018-04-17",
};

export const DataDeletePolicyInfo: IPolicyQuestionSchema = {
  id: "data-delete-policy",
  questions: [
    {
      dependency: ["whoDataDelete"],
      helper: "",
      id: "data_delete_maybedeleted-2018_04_17",
      label: "User data deleted after contract end",
      name: "everDataDelete",
      type: "CheckboxInput",
    },
    {
      dependency: ["slaDataDelete", "whoDataDelete"],
      helper: "",
      id: "data_delete_ever-2018_04_17",
      label: "Users may request their data be deleted",
      name: "maybeDataDelete",
      type: "CheckboxInput",
    },
    {
      choices: [
        "Within one week",
        "Within one month",
        "Within one quarter",
        "Within two quarters",
        "Within one year",
      ],
      helper: "",
      id: "data_delete_sla-2018_04_17",
      label: "Time period for data deletion on user request",
      name: "slaDataDelete",
      type: "SelectInput",
    },
    {
      choices: responsibilityChoices,
      helper: "",
      id: "data_delete_overall-2018_04_17",
      label: "Responsible for this policy",
      name: "overallResponsibilityDataDelete",
      type: "SelectInputWithEditableOther",
    },
  ],
  version: "2018-04-17",
};

export const DisasterRecoveryPlanInfo: IPolicyQuestionSchema = {
  id: "disaster-recovery-plan",
  questions: [
    {
      choices: ["Annually", "Semi-annually", "Quarterly"],
      helper: "",
      id: "drp_overall-2018_10_07",
      label: "Cadence to test the plan",
      name: "disasterRecoveryCadence",
      type: "SelectInput",
    },
    {
      choices: responsibilityChoices,
      helper: "",
      id: "drp_overall-2018_10_07",
      label: "Responsible for this policy",
      name: "overallResponsibilityDisasterRecovery",
      type: "SelectInputWithEditableOther",
    },
  ],
  version: "2018-10-07",
};

export const IncidentResponseInfo: IPolicyQuestionSchema = {
  id: "incident-response-plan",
  questions: [
    {
      helper:
        "Vanta recommends designating at least two people members of the incident response team",
      id: "incident_response_team-2018_04_17",
      label:
        "Incident response team members: Please list names and contact info",
      name: "incidentResponseTeam",
      type: "TextArea",
    },
    {
      helper:
        "Vanta recommends requiring that follow-ups are tracked after incidents of any severity.",
      id: "incident_response_follow_ups-2020_12_11",
      label: "Follow-ups must be tracked after security incidents",
      name: "incidentResponseFollowUps",
      type: "CheckboxInput",
    },
    {
      helper:
        "Vanta recommends requiring retrospectives and lessons learned documents after serious security incidents",
      id: "incident_response_retros-2018_04_17",
      label: "Retrospectives required for high and critical security incidents",
      name: "incidentResponseRetro",
      type: "CheckboxInput",
    },
    {
      choices: responsibilityChoices,
      helper: "",
      id: "incident_response_overall-2018_04_17",
      label: "Responsible for this policy",
      name: "overallResponsibilityIncidentResponse",
      type: "SelectInputWithEditableOther",
    },
  ],
  version: "2018-04-17",
};

export const InformationSecurityInfo: IPolicyQuestionSchema = {
  id: "information-security-policy",
  questions: [
    {
      helper: "",
      id: "infosec_teamceo-2018_04_17",
      label: "Security team has direct line of contact to CEO",
      name: "maybeSecurityTeamCEO",
      type: "CheckboxInput",
    },

    {
      helper:
        "Vanta can run background checks for your company if you're not already running them.",
      id: "infosec_bgcheck-2018_04_17",
      label: "Employee background checks required",
      name: "maybeBackgroundCheck",
      type: "CheckboxInput",
    },
    {
      helper: "",
      id: "infosec_training-2018_04_17",
      label: "New hire security training required",
      name: "maybeSecurityTraining",
      type: "CheckboxInput",
    },
    {
      helper: "",
      id: "infosec_vpn-2018_07_23",
      label: "VPN used",
      name: "maybeVPN",
      type: "CheckboxInput",
    },
    {
      choices: responsibilityChoices,
      helper: "",
      id: "infosec_overall-2018_04_17",
      label: "Responsible for this policy",
      name: "overallResponsibilityInformationSecurity",
      type: "SelectInputWithEditableOther",
    },
  ],
  version: "2018-04-17",
};

export const PasswordPolicyInfo: IPolicyQuestionSchema = {
  id: "password-policy",
  questions: [
    {
      helper:
        "Vanta recommends your company require employees to use a password manager",
      id: "password_manager-2018_04_17",
      label: "Password manager required",
      name: "maybePasswordManager",
      type: "CheckboxInput",
    },
    {
      helper:
        '"Complex" passwords have at least one uppercase letter, one lowercase letter, one number, and one non-alphanumeric character, and are at least 10 characters long. Vanta recommends your company require employees to use complex passwords.',
      id: "password_complex-2018_04_17",
      label: "Complex passwords required",
      name: "maybeComplexPasswords",
      type: "CheckboxInput",
    },
    {
      helper:
        "Vanta recommends your company require employees to use two-factor authentication where available",
      id: "password_2fa-2018_04_17",
      label: "Two-factor authentication required",
      name: "maybePassword2fa",
      type: "CheckboxInput",
    },
    {
      choices: responsibilityChoices,
      helper: "",
      id: "password_overall-2018_04_17",
      label: "Responsible for this policy",
      name: "overallResponsibilityPassword",
      type: "SelectInputWithEditableOther",
    },
  ],
  version: "2018-04-17",
};

export const PhysicalSecurityPolicyInfo: IPolicyQuestionSchema = {
  id: "physical-security-policy",
  questions: [
    {
      helper: "",
      id: "physical_security_camerasoffice-2018_04_17",
      label: "Office has security cameras",
      name: "maybeSecurityCamerasOffice",
      type: "CheckboxInput",
    },
    {
      helper: "",
      id: "physical_security_camerasbuilding-2018_04_17",
      label: "Building has security cameras",
      name: "maybeSecurityCamerasBuilding",
      type: "CheckboxInput",
    },
    {
      helper: "",
      id: "physical_security_alarmsoffice-2018_04_17",
      label: "Office has security alarms",
      name: "maybeSecurityAlarmsOffice",
      type: "CheckboxInput",
    },
    {
      helper: "",
      id: "physical_security_extinguishers-2018_04_17",
      label: "Office has fire extinguishers",
      name: "maybeFireAlarms",
      type: "CheckboxInput",
    },
    {
      helper:
        "A clean desk policy compels employees to wipe off whiteboards, shred company documents, and take other precautions to ensure visitors are not exposed to sensitive company information.",
      id: "physical_security_cleandesk-2018_07_17",
      label: "Clean desk policy enforced",
      name: "maybeCleanDeskPolicy",
      type: "CheckboxInput",
    },
    {
      choices: responsibilityChoices,
      helper: "",
      id: "physical_security_overall-2018_04_17",
      label: "Responsible for this policy",
      name: "overallResponsibilityPhysicalSecurity",
      type: "SelectInputWithEditableOther",
    },
  ],
  version: "2018-04-17",
};

export const ResponsibleDisclosurePolicyInfo: IPolicyQuestionSchema = {
  id: "responsible-disclosure-policy",
  questions: [
    {
      choices: responsibilityChoices,
      helper: "",
      id: "respdesc_overall-2018_04_17",
      label: "Responsible for this policy",
      name: "overallResponsibilityResponsibleDisclosure",
      type: "SelectInputWithEditableOther",
    },
  ],
  version: "2019-05-14",
};

export const RiskAssessmentProgramInfo: IPolicyQuestionSchema = {
  id: "risk-assessment-program",
  questions: [
    {
      choices: ["Three months", "Six months", "One year"],
      helper:
        "Vanta recommends setting as short of a time period as you're confident you can uphold.",
      id: "risk_cadence-2018_10_06",
      label: "You will perform a risk assessment every",
      name: "riskAssessmentCadence",
      type: "SelectInput",
    },
    {
      choices: responsibilityChoices,
      helper: "",
      id: "risk_overall-2018_10_06",
      label: "Responsible for this policy",
      name: "overallResponsibilityRiskAssessment",
      type: "SelectInputWithEditableOther",
    },
  ],
  version: "2018-10-06",
};

export const SystemAccessPolicyInfo: IPolicyQuestionSchema = {
  id: "system-access-control-policy",
  questions: [
    {
      choices: responsibilityChoices,
      helper: "",
      id: "system_overall-2018_04_17",
      label: "Responsible for this policy",
      name: "overallResponsibilitySystemAccess",
      type: "SelectInputWithEditableOther",
    },
  ],
  version: "2018-04-17",
};

export const VendorManagementInfo: IPolicyQuestionSchema = {
  id: "vendor-management-policy",
  questions: [
    {
      choices: responsibilityChoices,
      helper: "",
      id: "vendor_mgmt_reviewresp-2018_07_23",
      label: "Responsible for reviewing vendors",
      name: "vendorManagementReviewResponsible",
      type: "SelectInputWithEditableOther",
    },
    {
      choices: ["Annually", "Semi-annually", "Quarterly"],
      helper: "",
      id: "vendor_mgmt_cadence-2018_04_17",
      label: "Vendors reviewed",
      name: "vendorManagementCadence",
      type: "SelectInput",
    },
    {
      helper:
        "Vanta recommends collecting compliance reports (e.g. SOC 2 and ISO 27001) from vendors that have them",
      id: "vendor_mgmt_reports-2018_04_17",
      label: "Collect compliance reports from vendors",
      name: "complianceReports",
      type: "CheckboxInput",
    },
    {
      helper: "Vanta recommends requiring MFA on all services that offer it",
      id: "vendor_mgmt_reports-2018_04_17",
      label: "Require MFA for vendors",
      name: "requireMFA",
      type: "CheckboxInput",
    },
    {
      choices: responsibilityChoices,
      helper: "",
      id: "vendor_mgmt_overall-2018_04_17",
      label: "Responsible for this policy",
      name: "overallResponsibilityVendorManagement",
      type: "SelectInputWithEditableOther",
    },
  ],
  version: "2018-04-17",
};

export const VulnerabilityManagementInfo: IPolicyQuestionSchema = {
  id: "vulnerability-management-policy",
  questions: [
    {
      choices: responsibilityChoices,
      helper: "",
      id: "vuln_mgmt_overall-2018_05_27",
      label: "Responsible for this policy",
      name: "overallResponsibilityVulnerabilityManagement",
      type: "SelectInputWithEditableOther",
    },
  ],
  version: "2018-05-27",
};
