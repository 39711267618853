import type { Maybe } from "common/base/types/maybe";
import React from "react";

import { LoginButton } from "../login";
import { OktaIcon } from "./icon";

export const OktaLoginButton: React.FC<{ loginLink?: Maybe<string> }> = ({
  loginLink,
}) => (
  <LoginButton
    text={"Sign in with Okta"}
    Icon={OktaIcon}
    loginLink={loginLink}
  />
);
