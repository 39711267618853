import { AnchorButton, Button, Intent, NonIdealState } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import React from "react";
import type { RouteComponentProps } from "react-router-dom";
import { withRouter } from "react-router-dom";

import { LogError } from "../../errors";
import { useLockoutContactSalesMutation } from "../../gen/components";
import { AppToaster } from "../../helpers/toaster";

interface IProps {
  domainName?: Maybe<string>;
  canTakeAction: boolean;
}

const LockoutComponent: React.FunctionComponent<RouteComponentProps & IProps> =
  props => {
    const [sendEmail] = useLockoutContactSalesMutation();

    const contactSales = async () => {
      try {
        const sent = await sendEmail();
        if (sent.errors) {
          throw new Error(JSON.stringify(sent.errors));
        }
        AppToaster.show({
          icon: "tick",
          intent: Intent.SUCCESS,
          message: "Email sent successfully!",
          timeout: 2500,
        });
      } catch (e) {
        LogError(e);
      }
    };
    return (
      <NonIdealState
        title="Vanta trial ended."
        description={`You don't have access to Vanta any longer because ${
          isSome(props.domainName) ? props.domainName : "your company"
        }'s trial ended.`}
        icon="cross"
        action={
          props.canTakeAction ? (
            <div>
              <AnchorButton href={`/billing`} intent={Intent.PRIMARY}>
                Upgrade now
              </AnchorButton>
              <Button onClick={contactSales}>Contact sales</Button>
            </div>
          ) : undefined
        }
      />
    );
  };

gql`
  mutation lockoutContactSales {
    lockoutContactSales
  }
`;

export const Lockout = withRouter(LockoutComponent);
