import { HTMLSelect, Intent } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import {
  AuditTypeToHumanName,
  ReportStandardToAuditTypeEnum,
} from "common/constants/displayNames";
import { visibleStandardsForDomain } from "common/standards/utils";
import gql from "graphql-tag";
import React, { useMemo, useState } from "react";
import styled from "styled-components";

import {
  BodyText,
  Dialog,
  Button,
  RadioGroup,
} from "../../../alpaca/components";
import type {
  AuditTypeEnum,
  GetAuditorEngagementInfoQuery,
} from "../../../gen/components";
import {
  GetAuditorEngagementInfoDocument,
  useCreateEngagementMutation,
} from "../../../gen/components";
import { AppToaster } from "../../../helpers/toaster";
import { DateInputPair } from "./date-input-pair";
import type { NonNullDateRange } from "./utils";

import { STARTER_ENGAGEMENT_DATE_RANGE, StyledDialogFooterDiv } from "./utils";

interface IProps {
  isOpen: boolean;
  onClose(): void;
  assumableUsers: NonNullable<
    GetAuditorEngagementInfoQuery["user"]
  >["auditableUsers"];
}

export const CreateEngagementDialog: React.FC<IProps> = ({
  isOpen,
  onClose,
  assumableUsers,
}) => {
  const [domainIdToCreate, setDomainIdToCreate] = useState<Maybe<string>>(null);
  const [selectedAuditType, setSelectedAuditType] =
    useState<Maybe<AuditTypeEnum>>(undefined);

  const [dateRange, setDateRange] = useState<NonNullDateRange>(
    STARTER_ENGAGEMENT_DATE_RANGE
  );
  const [createEngagement] = useCreateEngagementMutation({
    refetchQueries: [{ query: GetAuditorEngagementInfoDocument }],
    onCompleted: () => {
      AppToaster.show({
        intent: Intent.SUCCESS,
        message: "Engagement created",
      });
    },
  });
  const [inFlight, setInFlight] = useState(false);

  const availableAuditTypes = useMemo(
    () =>
      visibleStandardsForDomain(
        assumableUsers.find(u => u.domain.id === domainIdToCreate)?.domain
          .standards ?? []
      )
        .map(s => ReportStandardToAuditTypeEnum[s])
        .flat()
        .sort((t1, t2) =>
          AuditTypeToHumanName[t1].localeCompare(AuditTypeToHumanName[t2])
        ),
    [assumableUsers, domainIdToCreate]
  );

  const sortedDomains = useMemo(
    () =>
      assumableUsers
        .map(u => u.domain)
        .sort((d1, d2) => d1.displayName.localeCompare(d2.displayName)),
    [assumableUsers]
  );

  return (
    <Dialog title="Create engagement" isOpen={isOpen} onClose={closeDialog}>
      <BodyText>Select a company</BodyText>
      <HTMLSelect
        fill
        value={domainIdToCreate ?? "__unselected__"}
        onChange={e => {
          setDomainIdToCreate(e.currentTarget.value);
          setDateRange(STARTER_ENGAGEMENT_DATE_RANGE);
          setSelectedAuditType(undefined);
        }}
      >
        <option value="__unselected__">Select</option>
        {sortedDomains.map(d => (
          <option key={d.id} value={d.id}>
            {d.displayName}
          </option>
        ))}
      </HTMLSelect>
      {isSome(domainIdToCreate) ? (
        <>
          <StyledBodyText>Select a standard</StyledBodyText>
          <RadioGroup
            name="audit-type-radio"
            options={availableAuditTypes.map(t => {
              return { label: AuditTypeToHumanName[t], value: t };
            })}
            selectedValue={selectedAuditType ?? undefined}
            onChange={e =>
              setSelectedAuditType(e.currentTarget.value as AuditTypeEnum)
            }
          />
          <StyledBodyText>Set the observation period</StyledBodyText>
          <DateInputPair dateRange={dateRange} onNewValue={setDateRange} />
        </>
      ) : null}
      <StyledDialogFooterDiv>
        <Button
          disabled={!isSome(domainIdToCreate) || !isSome(selectedAuditType)}
          intent={Intent.PRIMARY}
          loading={inFlight}
          onClick={async () => {
            setInFlight(true);
            await createEngagement({
              variables: {
                input: {
                  auditType: selectedAuditType!,
                  customerDomainId: domainIdToCreate!,
                  observationStartDate: dateRange[0].toISOString(),
                  observationEndDate: dateRange[1].toISOString(),
                },
              },
            });
            closeDialog();
          }}
        >
          Create
        </Button>
      </StyledDialogFooterDiv>
    </Dialog>
  );

  function closeDialog() {
    setDomainIdToCreate(null);
    setDateRange(STARTER_ENGAGEMENT_DATE_RANGE);
    setSelectedAuditType(undefined);
    setInFlight(false);
    onClose();
  }
};

const StyledBodyText = styled(BodyText)`
  margin-top: 24px;
`;

gql`
  mutation createEngagement($input: CreateAuditEngagementInput!) {
    createAuditEngagement(input: $input) {
      ... on CreateAuditEngagementSuccess {
        audit {
          id
          auditType
          completionDate
          observationEndDate
          observationStartDate
          domainName
          domainId
        }
      }
    }
  }
`;
