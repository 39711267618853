import {
  Button,
  Classes,
  Code,
  Collapse,
  Tab,
  Spinner,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { Tabs } from "../../../../alpaca/components";
import { Config } from "../../../../config";
import { LogError, LogErrorMessage } from "../../../../errors";
import type {
  FetchMonitoredCloudInstancesQuery,
  FetchUnmonitoredCloudInstancesQuery,
} from "../../../../gen/components";
import {
  useFetchHostEnrollSecretQuery,
  useFetchMonitoredCloudInstancesQuery,
  useFetchUnmonitoredCloudInstancesQuery,
  useRotateKeyMutation,
} from "../../../../gen/components";
import { CalloutWithClipBoard } from "../../../helpers/callout-with-clipboard";
import { FullPageSpinner } from "../../../helpers/FullPageSpinner";
import type {
  FetchMoreFn,
  PaginationParams,
} from "../../../helpers/generic-paginator";
import {
  DEFAULT_ITEMS_PER_PAGE_OPTION,
  GenericPaginator,
  getInitialPaginationParams,
  applyPaginationParams,
} from "../../../helpers/generic-paginator";
import { UncontrolledDialogButton } from "../../../helpers/uncontrolled-dialog-button";
import {
  PageHeadingInfo,
  VantaDashboardPage,
} from "../../../vanta-chrome/page-content/vanta-dashboard-page";
import { HostAgentTroubleshootingInstructions } from "../../components/debug-installation";
import { AMISecure } from "../../docs/AmISecure";
import { Section, Strong } from "../common/components";
import { BasicServerTable } from "./basic-server-table";

const TAB_IDS = { monitored: "monitored", unmonitored: "unmonitored" };

const Callout = styled.div`
  background: #3c82e014;
  border-radius: 6px;
  padding: 12px;
  flex-grow: 1;
  color: #fff;
  background-color: #ed4848;
`;

const KeyRotationDialog: React.FC<{
  setKey: React.Dispatch<React.SetStateAction<string>>;
  secret: string;
}> = ({ secret, setKey }) => {
  const [rotateKey, rotated] = useRotateKeyMutation();
  return (
    <div className={Classes.DIALOG_BODY}>
      <p>
        Vanta Key: <br />
        <br />
        <Code style={{ overflow: "auto" }}>{secret}</Code>
      </p>
      <p>
        The Vanta Key associates your servers with your Vanta account and should
        be kept private. Anyone with your key can enroll machines into your
        Vanta account.
      </p>

      <p>
        If you accidentally expose your key, that's OK! Just click the button
        below to get a new key. Machines that have already installed Vanta will
        continue to work, but any new installs will need to use the new key.
      </p>
      <p>
        Your old key will stop working once you click this button, so make sure
        that you update the key wherever you're installing or deploying Vanta.
      </p>
      <Button
        intent={"warning"}
        loading={rotated.loading}
        onClick={async () => {
          const newKey = await rotateKey();
          if (!isSome(newKey.data?.rotateKey)) {
            throw new Error("Unable to rotate key.");
          }
          setKey(newKey.data!.rotateKey!);
        }}
      >
        Rotate Key
      </Button>
    </div>
  );
};

const UnmonitoredList: React.FC<{
  data: Maybe<FetchUnmonitoredCloudInstancesQuery>;
  loading: boolean;
  paginationParams: PaginationParams;
  setPaginationParams: React.Dispatch<React.SetStateAction<PaginationParams>>;
  fetchMore: FetchMoreFn;
}> = ({ data, loading, paginationParams, setPaginationParams, fetchMore }) => {
  const pageInfo = data?.organization.cloudInstances?.pageInfo;
  const unmonitoredServers = applyPaginationParams(
    data?.organization.cloudInstances?.edges.map(e => e.node) ?? [],
    paginationParams
  );
  const totalUnmonitoredCount =
    data?.organization.cloudInstances?.totalCount ?? 0;

  const hasMonitoredServers = Boolean(
    data?.organization.monitoredInstances?.totalCount
  );

  return (
    <div>
      <GenericPaginator
        pageInfo={pageInfo}
        itemsLoaded={unmonitoredServers.length}
        totalItems={totalUnmonitoredCount}
        paginationParams={paginationParams}
        setPaginationParams={setPaginationParams}
        paginationId={"paginator-unmonitored-list"}
        fetchMore={fetchMore}
      />

      {loading || paginationParams.loading ? (
        <Spinner />
      ) : (
        <BasicServerTable
          servers={unmonitoredServers}
          emptyState={
            <div>
              <Heading>
                {hasMonitoredServers
                  ? `The Vanta Agent has been registered on all servers on your
                        connected cloud service accounts.`
                  : `Vanta has no information about your servers.`}
              </Heading>
              <Link to="/connections">
                Manage cloud service connections&nbsp;&#8594;
              </Link>
            </div>
          }
        />
      )}
    </div>
  );
};

const MonitoredList: React.FC<{
  data: Maybe<FetchMonitoredCloudInstancesQuery>;
  loading: boolean;
  paginationParams: PaginationParams;
  setPaginationParams: React.Dispatch<React.SetStateAction<PaginationParams>>;
  fetchMore: FetchMoreFn;
}> = ({ data, loading, paginationParams, setPaginationParams, fetchMore }) => {
  const pageInfo = data?.organization.cloudInstances?.pageInfo;
  const monitoredServers = applyPaginationParams(
    data?.organization.cloudInstances?.edges.map(e => e.node) ?? [],
    paginationParams
  );
  const totalMonitoredCount =
    data?.organization.cloudInstances?.totalCount ?? 0;

  return (
    <div>
      <GenericPaginator
        pageInfo={pageInfo}
        itemsLoaded={monitoredServers.length}
        totalItems={totalMonitoredCount}
        paginationParams={paginationParams}
        setPaginationParams={setPaginationParams}
        paginationId={"paginator-monitored-list"}
        fetchMore={fetchMore}
      />

      {loading || paginationParams.loading ? (
        <Spinner />
      ) : (
        <BasicServerTable
          servers={monitoredServers}
          emptyState={
            <div>
              <Heading>
                Vanta Agent has not been registered on any servers on your
                connected cloud service accounts. Install the Vanta Agent on all
                of your servers to monitor for package vulnerabilities.
              </Heading>
            </div>
          }
        />
      )}
    </div>
  );
};

const ServerList: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState(TAB_IDS.unmonitored);
  const [unmonitoredPaginationParams, setUnmonitoredPaginationParams] =
    useState<PaginationParams>(getInitialPaginationParams());
  const [monitoredPaginationParams, setMonitoredPaginationParams] =
    useState<PaginationParams>(getInitialPaginationParams());

  const {
    error: unmonitoredError,
    loading: unmonitoredLoading,
    data: unmonitoredData,
    fetchMore: unmonitoredDataFetchMore,
  } = useFetchUnmonitoredCloudInstancesQuery({
    variables: {
      first: DEFAULT_ITEMS_PER_PAGE_OPTION,
    },
  });

  const {
    error: monitoredError,
    loading: monitoredLoading,
    data: monitoredData,
    fetchMore: monitoredDataFetchMore,
  } = useFetchMonitoredCloudInstancesQuery({
    variables: {
      first: DEFAULT_ITEMS_PER_PAGE_OPTION,
    },
  });

  if (monitoredError || unmonitoredError) {
    LogError(monitoredError ?? unmonitoredError!);
    return null;
  }

  const totalMonitoredCount =
    monitoredData?.organization.cloudInstances?.totalCount ?? 0;
  const totalUnmonitoredCount =
    unmonitoredData?.organization.cloudInstances?.totalCount ?? 0;

  return (
    <Tabs
      id="track-agent-tabs"
      selectedTabId={selectedTab}
      onChange={tabId => setSelectedTab(tabId.toString())}
    >
      <Tab
        id={TAB_IDS.unmonitored}
        title={`${totalUnmonitoredCount} without registered agent`}
        panel={
          <UnmonitoredList
            data={unmonitoredData}
            loading={unmonitoredLoading}
            paginationParams={unmonitoredPaginationParams}
            setPaginationParams={setUnmonitoredPaginationParams}
            fetchMore={unmonitoredDataFetchMore}
          />
        }
      />

      <Tab
        id={TAB_IDS.monitored}
        title={`${totalMonitoredCount} with registered agent`}
        panel={
          <MonitoredList
            data={monitoredData}
            loading={monitoredLoading}
            paginationParams={monitoredPaginationParams}
            setPaginationParams={setMonitoredPaginationParams}
            fetchMore={monitoredDataFetchMore}
          />
        }
      />
    </Tabs>
  );
};

export const AgentInstallInstructions: React.FC = () => {
  const [showTroubleshooting, setShowTroubleshooting] = useState(false);
  const {
    error: errorKey,
    loading: loadingKey,
    data: dataKey,
  } = useFetchHostEnrollSecretQuery();

  const [key, setKey] = useState(dataKey?.organization.hostEnrollSecret ?? "");

  useEffect(
    () => setKey(dataKey?.organization.hostEnrollSecret ?? ""),
    [dataKey]
  );

  if (errorKey) {
    LogError(errorKey);
    return null;
  }
  if (loadingKey) {
    return <FullPageSpinner />;
  }
  if (!dataKey) {
    LogErrorMessage("Bad fetch");
    return null;
  }

  return (
    <VantaDashboardPage headingInfo={PageHeadingInfo.INSTALLATION_INSTRUCTIONS}>
      {(Config.isDev || Config.isStaging) && (
        <Section>
          <Callout>
            <Strong>{`You are on ${
              Config.isDev ? "DEV" : "STAGING"
            }.  Install the agent from S3 instead!`}</Strong>
          </Callout>
        </Section>
      )}
      <Section>
        <UncontrolledDialogButton
          buttonText={"Is Vanta Agent secure?"}
          dialogContent={<AMISecure concern="osquery" />}
          dialogHeader={"Is the Vanta Agent secure?"}
          icon={IconNames.LOCK}
        />
        <br />
        <UncontrolledDialogButton
          buttonText={"Rotate your key"}
          dialogContent={
            <KeyRotationDialog secret={key} setKey={setKey}></KeyRotationDialog>
          }
          dialogHeader={"Rotate Your Key"}
          icon={IconNames.PIVOT}
        />
      </Section>

      <Section>
        <Subheading>Install and clone to multiple instances</Subheading>
        <Description>
          Delay the Vanta Agent from registering until after a server restart
        </Description>
        <CalloutWithClipBoard text={INSTALLTEXT.noStart(key)} />
      </Section>
      <Section>
        <Subheading>Install to a single instance</Subheading>
        <Description>
          Register the Vanta Agent immediately upon install
        </Description>
        <CalloutWithClipBoard text={INSTALLTEXT.autoStart(key)} />
      </Section>
      <Section>
        <Subheading>Troubleshooting</Subheading>
        <Description>
          A few common misconfigurations can prevent your machines from showing
          as monitored even after you’ve installed the Vanta agent.
        </Description>
        <Button
          small={true}
          onClick={() => setShowTroubleshooting(!showTroubleshooting)}
        >
          {showTroubleshooting ? "Hide" : "Show"} troubleshooting instructions
        </Button>
        <Collapse isOpen={showTroubleshooting}>
          <HostAgentTroubleshootingInstructions />
        </Collapse>
      </Section>

      <Midheading>Track agent installation</Midheading>
      <ServerList />
    </VantaDashboardPage>
  );
};

const Heading = styled.div`
  width: 532px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 6px;
`;

const Subheading = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 4px;
  color: #333333;
`;

const Midheading = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 12px;
`;

const Description = styled.p`
  color: #000;
  opacity: 0.7;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 14px;
`;

const INSTALLTEXT = {
  noStart: (secret: string) =>
    `VANTA_NOSTART=1 VANTA_KEY="${secret}" bash -c "$(curl -L https://raw.githubusercontent.com/VantaInc/vanta-agent-scripts/master/install-linux.sh)"`,
  autoStart: (secret: string) =>
    `VANTA_KEY="${secret}" bash -c "$(curl -L https://raw.githubusercontent.com/VantaInc/vanta-agent-scripts/master/install-linux.sh)"`,
};

gql`
  query fetchHostEnrollSecret {
    organization {
      id
      hostEnrollSecret
    }
  }
`;

gql`
  mutation rotateKey {
    rotateKey
  }
`;

gql`
  query fetchUnmonitoredCloudInstances(
    $first: Int
    $after: String
    $last: Int
    $before: String
  ) {
    organization {
      id
      # Solely used to know if the user already has monitored instances in the prompt we display
      monitoredInstances: cloudInstances(first: 0, vantaAgentOnly: true) {
        totalCount
      }
      cloudInstances(
        first: $first
        after: $after
        last: $last
        before: $before
        activeOnly: true
        withoutVantaAgentOnly: true
      ) {
        totalCount
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            id
            instanceId
            ... on SpecificEC2InstanceResource {
              name
              publicIPAddress
              privateIPAddress
              account
              tags {
                key
                value
              }
              osquery {
                id
                data {
                  id
                  lastPing
                }
              }
            }
            ... on SpecificGCPComputeInstanceResource {
              gcpName: name
              projectId
              internalInstanceId
              interfaces {
                networkIP
                externalIP
              }
              labels {
                key
                value
              }
              osquery {
                id
                data {
                  id
                  lastPing
                }
              }
            }
            ... on SpecificAzureVirtualMachineResource {
              azureName: name
              subscriptionId
              instanceId
              privateIPAddress
              publicIPAddress
              uniqueId
              tags {
                key
                value
              }
              osquery {
                id
                data {
                  id
                  lastPing
                }
              }
            }

            ... on SpecificAzureScaleSetVirtualMachineResource {
              azureName: name
              subscriptionId
              instanceId
              privateIPAddress
              publicIPAddress
              uniqueId
              tags {
                key
                value
              }
              osquery {
                id
                data {
                  id
                  lastPing
                }
              }
            }
          }
        }
      }
    }
  }
`;

gql`
  query fetchMonitoredCloudInstances(
    $first: Int
    $after: String
    $last: Int
    $before: String
  ) {
    organization {
      id
      cloudInstances(
        first: $first
        after: $after
        last: $last
        before: $before
        activeOnly: true
        vantaAgentOnly: true
      ) {
        totalCount
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            id
            instanceId
            ... on SpecificEC2InstanceResource {
              name
              publicIPAddress
              privateIPAddress
              account
              tags {
                key
                value
              }
              osquery {
                id
                data {
                  id
                  lastPing
                }
              }
            }
            ... on SpecificGCPComputeInstanceResource {
              gcpName: name
              projectId
              internalInstanceId
              interfaces {
                networkIP
                externalIP
              }
              labels {
                key
                value
              }
              osquery {
                id
                data {
                  id
                  lastPing
                }
              }
            }
            ... on SpecificAzureVirtualMachineResource {
              azureName: name
              subscriptionId
              instanceId
              privateIPAddress
              publicIPAddress
              uniqueId
              tags {
                key
                value
              }
              osquery {
                id
                data {
                  id
                  lastPing
                }
              }
            }

            ... on SpecificAzureScaleSetVirtualMachineResource {
              azureName: name
              subscriptionId
              instanceId
              privateIPAddress
              publicIPAddress
              uniqueId
              tags {
                key
                value
              }
              osquery {
                id
                data {
                  id
                  lastPing
                }
              }
            }
          }
        }
      }
    }
  }
`;
