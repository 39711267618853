import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import moment from "moment";
import React from "react";
import styled from "styled-components";

import { Caption, Heading } from "../common/components";
import { textForDuration } from "../utils";

interface IProps {
  openDurationMS: number;
  slaInDays: Maybe<number>;
}

export const TimeToResolution: React.FC<IProps> = ({
  openDurationMS,
  slaInDays,
}) => {
  if (!isSome(slaInDays)) {
    return <Heading>{textForDuration(openDurationMS)}</Heading>;
  }

  // Only integers can be configured for slas, but this is calculated from
  // the deadline stamped on the resource, so ensure we are displaying only integer values
  const roundedSla = Math.floor(slaInDays + 0.5);

  const isOverSLA =
    openDurationMS > moment.duration(roundedSla, "day").asMilliseconds();
  const text = `${isOverSLA ? "over " : ""}${roundedSla}d SLA`;
  const duration = isOverSLA
    ? moment
        .duration(openDurationMS, "millisecond")
        .subtract(moment.duration(roundedSla, "day"))
    : moment.duration(openDurationMS, "millisecond");
  return (
    <div>
      <Heading>
        <Main isOverSLA={isOverSLA}>
          {textForDuration(duration.asMilliseconds())}
        </Main>
      </Heading>
      <Caption>{text}</Caption>
    </div>
  );
};

const Main = styled.div<{ isOverSLA: boolean }>`
  font-weight: 600;
  color: ${({ isOverSLA }) => (isOverSLA ? "red" : "black")};
`;
