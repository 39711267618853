import { Button, Card, H2, H3, Icon, Intent, Tab } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import React from "react";
import type { match } from "react-router";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { Tabs } from "../../../alpaca/components";
import { LogError, LogErrorMessage } from "../../../errors";
import type {
  ClearDismissStatusForRiskQuestionMutationFn,
  DismissRiskQuestionMutationFn,
  FetchRiskRegisterQuery,
} from "../../../gen/components";
import {
  FetchRiskRegisterDocument,
  useClearDismissStatusForRiskQuestionMutation,
  useDismissRiskQuestionMutation,
  useFetchRiskRegisterQuery,
} from "../../../gen/components";
import { FullPageSpinner } from "../../helpers/FullPageSpinner";
import { Dimensions } from "../../vanta-chrome/constants";
import {
  PageHeadingInfo,
  VantaDashboardPage,
} from "../../vanta-chrome/page-content/vanta-dashboard-page";
import { AddScenarioDialog } from "./add-scenario-dialog";
import { ScenarioCard } from "./scenario-card";

interface IProps {
  match: match<{ question: string }>; // Used in the URL matching
  history?: Maybe<any>; // comes from react-router
}

interface IInternalProps extends IProps {
  domain: NonNullable<FetchRiskRegisterQuery["organization"]>;
  dismissQuestion: DismissRiskQuestionMutationFn;

  clearDismissQuestion: ClearDismissStatusForRiskQuestionMutationFn;
}

interface IState {
  showScenarioDialog: boolean;
  scenarioToEdit?: Maybe<
    NonNullable<
      FetchRiskRegisterQuery["organization"]
    >["riskRegister"]["scenarios"][0]
  >;
}

class ManageQuestion extends React.Component<IInternalProps, IState> {
  public constructor(props: IInternalProps) {
    super(props);
    this.state = {
      showScenarioDialog: false,
    };
    this.handleQuestionAnswer = this.handleQuestionAnswer.bind(this);
  }
  public render() {
    const domain = this.props.domain;
    const question = domain.riskRegister.questions.find(q => {
      const questionId = this.props.match.params.question;
      return q.id === questionId;
    });

    if (!isSome(question)) {
      return <Redirect to="/risk-register" />;
    }

    const noRisk = (
      <div className="no-risk">
        <span>
          <Icon
            iconSize={Icon.SIZE_LARGE}
            icon={IconNames.TICK_CIRCLE}
            intent={Intent.SUCCESS}
          />
        </span>
        <span>Great! No risk has been identified by this answer.</span>
      </div>
    );

    const questionCard = (
      <Card className="question-card">
        <H2>{question.text}</H2>

        <div>
          <Button
            onClick={() => this.handleQuestionAnswer("Yes", question)}
            intent={
              (question.affirmative && question.complete) ||
              (!question.affirmative && question.dismissed)
                ? Intent.PRIMARY
                : undefined
            }
            text="Yes"
          />{" "}
          <Button
            intent={
              (question.affirmative && question.dismissed) ||
              (!question.affirmative && question.complete)
                ? Intent.PRIMARY
                : undefined
            }
            onClick={() => this.handleQuestionAnswer("No", question)}
            text="No"
          />
        </div>
      </Card>
    );

    const categories = domain.riskRegister.questions.reduce(
      (acc, cur) => {
        if (isSome(cur.category)) {
          acc[cur.category] = [...(acc[cur.category] ?? []), cur];
        }
        return acc;
      },
      {} as {
        [category: string]: NonNullable<
          FetchRiskRegisterQuery["organization"]
        >["riskRegister"]["questions"];
      }
    );

    const categoryTabs = Object.keys(categories)
      .sort()
      .map(c => {
        const qs = categories[c];

        const icon = qs.every(q => q.complete || q.dismissed) ? (
          <Icon icon={IconNames.TICK_CIRCLE} intent={Intent.SUCCESS} />
        ) : undefined;
        return (
          <Tab
            id={c}
            key={c}
            title={
              <span className="rr-tab-title">
                <span>{c}</span>
                {icon}
              </span>
            }
          />
        );
      });

    const questions = domain.riskRegister.questions.sort((a, b) =>
      a.category.localeCompare(b.category)
    );
    const catQuestions = categories[question.category];

    const questionIndexInCategory = catQuestions.findIndex(
      q => q.id === question.id
    );
    const questionIndex = questions.findIndex(q => q.id === question.id);

    const addScenarioDialog = this.state.showScenarioDialog ? (
      <AddScenarioDialog
        onCloseDialog={() => {
          this.setState({ showScenarioDialog: false, scenarioToEdit: null });
        }}
        domain={domain}
        question={question}
        scenarioToEdit={this.state.scenarioToEdit}
      />
    ) : undefined;

    const scenariosHeader = (
      <div className="spead-components scenarios-header">
        <H3>Scenarios</H3>
        <span>
          <b>Add another scenario</b>{" "}
          <Button
            onClick={() => this.setState({ showScenarioDialog: true })}
            minimal={true}
            rightIcon={
              <Icon icon={IconNames.PLUS} iconSize={Icon.SIZE_LARGE} />
            }
          />
        </span>
      </div>
    );

    const scenarioCards = question.scenarios.map(s => (
      <ScenarioCard
        key={s.id}
        scenario={s}
        editClick={() =>
          this.setState({
            showScenarioDialog: true,
            scenarioToEdit: s,
          })
        }
      />
    ));

    const scenariosList =
      question.scenarios.length === 0 ? undefined : (
        <div>
          {scenariosHeader}
          {scenarioCards}
        </div>
      );
    return (
      <VantaDashboardPage headingInfo={PageHeadingInfo.RISK_REGISTER}>
        <Container>
          <div>
            {addScenarioDialog}
            <h2>
              <Link to="/risk-register">Risk register</Link> / Questions
            </h2>
            <hr className="full-hr" />
            <Tabs
              id="CategoryTabs"
              onChange={(newTabId: string) => {
                const newUrl = `/risk-register/question/${categories[newTabId][0].id}`;
                this.props.history.push(newUrl);
              }}
              selectedTabId={question.category}
            >
              {categoryTabs}
            </Tabs>
            {questionCard}
            {question.dismissed ? noRisk : undefined}
            {scenariosList}
          </div>
          <div className="spead-components question-category-bar">
            <Link
              to={`/risk-register/question/${
                questions[
                  (((questionIndex - 1) % questions.length) +
                    questions.length) %
                    questions.length
                ].id
              }`}
            >
              <Button>Prev</Button>
            </Link>
            <div className="question-category-counter">
              <div>{question.category} Questions answered</div>
              <span>
                {questionIndexInCategory + 1} of {catQuestions.length}
              </span>
            </div>
            <Link
              to={`/risk-register/question/${
                questions[(questionIndex + 1) % questions.length].id
              }`}
            >
              <Button>Next</Button>
            </Link>
          </div>
        </Container>
      </VantaDashboardPage>
    );
  }

  private handleQuestionAnswer(
    answer: string,
    question: NonNullable<
      FetchRiskRegisterQuery["organization"]
    >["riskRegister"]["questions"][number]
  ) {
    if (
      (question.affirmative && answer === "Yes") ||
      (!question.affirmative && answer === "No")
    ) {
      if (question.dismissed && isSome(this.props.clearDismissQuestion)) {
        this.props
          .clearDismissQuestion({ variables: { questionId: question.id } })
          .catch(LogError);
      } else if (!question.complete) {
        this.setState({ showScenarioDialog: true });
      }
    } else if (!question.dismissed && isSome(this.props.dismissQuestion)) {
      this.props
        .dismissQuestion({ variables: { questionId: question.id } })
        .catch(LogError);
    }
  }
}

const options = {
  refetchQueries: [{ query: FetchRiskRegisterDocument }],
};

export const ManageQuestionPage: React.FC<IProps> = props => {
  const [dismissQuestion] = useDismissRiskQuestionMutation(options);
  const [clearDismissQuestion] =
    useClearDismissStatusForRiskQuestionMutation(options);
  const { error, loading, data } = useFetchRiskRegisterQuery();
  if (error) {
    LogError(error);
    return null;
  }
  if (loading) {
    return <FullPageSpinner />;
  }
  if (!data) {
    LogErrorMessage("Bad fetch");
    return null;
  }
  return (
    <ManageQuestion
      {...props}
      domain={data.organization}
      dismissQuestion={dismissQuestion}
      clearDismissQuestion={clearDismissQuestion}
    />
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(
    100vh -
      ${2 * (Dimensions.PAGE_TOP_MARGIN + Dimensions.PAGE_PADDING) +
      Dimensions.TOP_BAR_HEIGHT +
      Dimensions.PAGE_HEADING_HEIGHT}px
  );
`;
