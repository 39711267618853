import { isSome } from "common/base/types/maybe";
import { RISK_CATEGORIES } from "common/schemas/riskRegister/risk-register-content";
import React, { useMemo } from "react";
import { Redirect, useRouteMatch } from "react-router";
import styled from "styled-components";

import { VANTA_COLORS } from "../../../alpaca/base/colors";
import { DefaultView } from "../../../alpaca/components";
import { useGetInfoForRiskAssessmentQuery } from "../../../gen/components";
import { useAvailableBetaFeatures } from "../../../helpers/feature-gating/feature-check";
import { Breadcrumbs } from "../../helpers/breadcrumbs";
import { FullPageSpinner } from "../../helpers/FullPageSpinner";
import { PATHS } from "../../vanta-chrome/paths";
import { RiskCategoryCard } from "./risk-category-card";
import { RiskCategoryForm } from "./risk-category-form";

export const RiskRegisterV3Home: React.FC = () => {
  const { data } = useGetInfoForRiskAssessmentQuery();

  const match = useRouteMatch<{
    // eslint-disable-next-line vanta/prefer-maybe
    category: string | undefined;
  }>();
  const categoryFromParams = match?.params.category;

  const availableBetaFeatures = useAvailableBetaFeatures();
  const gatedRiskCategories = Object.values(RISK_CATEGORIES).filter(
    category =>
      !isSome(category.feature) || availableBetaFeatures?.has(category.feature)
  );
  const riskCategory = Object.values(gatedRiskCategories).find(
    info => info.url === categoryFromParams
  );

  const dismissals = useMemo(
    () => data?.organization.riskDismissals ?? [],
    [data?.organization.riskDismissals]
  );

  if (isSome(categoryFromParams) && !isSome(riskCategory)) {
    return <Redirect to={PATHS.RISK_ASSESSMENT} />;
  }

  const domain = data?.organization;

  if (!isSome(domain)) {
    return <FullPageSpinner />;
  }

  if (isSome(riskCategory)) {
    return (
      <DefaultView
        headerProps={{
          title: (
            <Breadcrumbs
              noMargin
              breadcrumbs={[
                {
                  text: "Risk assessment",
                  route: PATHS.RISK_ASSESSMENT,
                },
                {
                  text: riskCategory.breadcrumbText,
                  route: `${PATHS.RISK_ASSESSMENT}/${riskCategory.url}`,
                },
              ]}
            />
          ),
        }}
      >
        <RiskCategoryForm category={riskCategory} domain={domain} />
      </DefaultView>
    );
  }

  // When there are more than 6 categories, arrange the cards in
  // rows of 4 instead of rows of 3.
  const cardWidth =
    gatedRiskCategories.length > 6
      ? styles.CARD_WIDTH_SHORT
      : styles.CARD_WIDTH_LONG;

  return (
    <DefaultView headerProps={{ title: "Risk assessment" }}>
      <CardSet>
        {Object.values(gatedRiskCategories).map(category => {
          const numberOfCompletedSteps = category.risks.filter(risk => {
            if (dismissals.some(d => d.riskId === risk.id)) {
              return true;
            }
            const scenariosForRisk = domain.rrv3RiskScenarios.filter(
              s => s.riskCategoryId === risk.id
            );
            return scenariosForRisk.length > 0;
          }).length;

          const categoryHasBegun = numberOfCompletedSteps > 0;

          return (
            <CardContainer key={category.url} width={cardWidth}>
              <RiskCategoryCard
                category={category}
                visited={categoryHasBegun}
                stepsCompleted={numberOfCompletedSteps}
              />
            </CardContainer>
          );
        })}
      </CardSet>
    </DefaultView>
  );
};

const styles = {
  CARD_PADDING: 24,
  CARD_HEIGHT: 290,
  CARD_WIDTH_LONG: 33,
  CARD_WIDTH_SHORT: 25,
  CARD_MARGIN_BOTTOM: 18,
  CARD_MARGIN_RIGHT: 18,
};

const CardSet = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

interface ICardContainerProps {
  width: number;
}

const CardContainer = styled.div<ICardContainerProps>`
  background-color: ${VANTA_COLORS.BACKGROUND_WHITE};
  padding: ${styles.CARD_PADDING}px;
  height: ${styles.CARD_HEIGHT}px;
  width: calc(${props => props.width}% - ${styles.CARD_MARGIN_RIGHT}px);
  margin-bottom: ${styles.CARD_MARGIN_BOTTOM}px;
  margin-right: ${styles.CARD_MARGIN_RIGHT}px;
`;
