import type { ItemListRenderer } from "@blueprintjs/select";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import { partition } from "lodash";
import React, { useMemo, useState } from "react";
import { compareTwoStrings } from "string-similarity";

import { IconNames } from "../../../../alpaca/components";
import { LogError } from "../../../../errors";
import { DropdownButton } from "../../components/dropdown-button";
import { StyledMenuHeaderDiv } from "../../people/hr-user-actions-dropdown";
import type { VantaUser } from "../access-page";
import type {
  AccountActionsMap,
  LinkableAccount,
} from "./bulk-link-account-dialog";

// The threshold for whether to suggest to link a user or not. String similarity
// is measured with the Dice coefficient, which is on a scale from 0 to 1.
//
// The current threshold is pretty arbitrarily chosen; it's probably worth
// seeing what's being suggested/not suggested for customers and tweaking this.
const SUGGESTION_THRESHOLD = 0.5;

const ADD_NEW_USER_OPTION = "ADD_NEW_USER";
const MARK_EXTERNAL = "EXT_USER";
const MARK_NON_HUMAN = "NON_HUMAN";

const NON_LINK_OPTS = [ADD_NEW_USER_OPTION, MARK_EXTERNAL, MARK_NON_HUMAN];

interface IProps {
  account: LinkableAccount;
  linkableUsersById: { [k: string]: VantaUser };
  sortedLinkableUsers: VantaUser[];
  accountIdsMap: AccountActionsMap;
  setAddingUserForAccount: (accountId: string) => void;
  setMapping: (accountId: string, mapping: Maybe<string>) => void;
}

function isUserGoodSuggestionForAccount(
  user: VantaUser,
  account: LinkableAccount
) {
  const userEmailMinusDomain = user.email.split("@")[0];
  // if there's no @ in the string, this just returns the string
  const accountNameMinusDomain = account.accountName.split("@")[0];
  const accountIdMinusDomain = account.accountId.split("@")[0];
  return (
    compareTwoStrings(userEmailMinusDomain, accountNameMinusDomain) >
      SUGGESTION_THRESHOLD ||
    compareTwoStrings(userEmailMinusDomain, accountIdMinusDomain) >
      SUGGESTION_THRESHOLD ||
    compareTwoStrings(user.displayName!, account.accountName) >
      SUGGESTION_THRESHOLD ||
    compareTwoStrings(user.displayName!, account.accountId) >
      SUGGESTION_THRESHOLD
  );
}

export const AccountActionsDropdown: React.FC<IProps> = ({
  account,
  linkableUsersById,
  sortedLinkableUsers,
  accountIdsMap,
  setAddingUserForAccount,
  setMapping,
}) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const linkableUserSuggestionIds = useMemo(() => {
    // Compute linkable suggestions lazily when the menu is opened so that
    // we're not calculating it for all accounts at once when the dialog opens
    if (!menuIsOpen) {
      return new Set<string>();
    }
    return new Set(
      sortedLinkableUsers
        .filter(user => isUserGoodSuggestionForAccount(user, account))
        .map(user => user.id)
    );
  }, [sortedLinkableUsers, menuIsOpen]);

  const menuOptions: string[] = useMemo(() => {
    // Compute the dropdown values lazily too when the menu is opened so that
    // we're not calculating it for all accounts at once when the dialog opens
    if (!menuIsOpen) {
      return [];
    }
    return [
      ADD_NEW_USER_OPTION,
      MARK_EXTERNAL,
      MARK_NON_HUMAN,
      ...sortedLinkableUsers.map(user => user.id),
    ];
  }, [sortedLinkableUsers, menuIsOpen]);

  const optionRenderer = (option: string) => {
    if (option === ADD_NEW_USER_OPTION) {
      return "Add a new person";
    } else if (option === MARK_EXTERNAL) {
      return "Assign to external person";
    } else if (option === MARK_NON_HUMAN) {
      return "Mark as not a person";
    } else {
      if (!(option in linkableUsersById)) {
        LogError(
          new Error(
            `Link account action did not specify a valid user; option was: ${option}`
          ),
          false
        );
        return null;
      }
      const { displayName, email } = linkableUsersById[option];
      return `${displayName} (${email})`;
    }
  };
  const getIconForOption = (option: string) => {
    if (option === ADD_NEW_USER_OPTION) {
      return IconNames.PLUS;
    } else if (option === MARK_EXTERNAL) {
      return IconNames.BRIEFCASE;
    } else if (option === MARK_NON_HUMAN) {
      return IconNames.PERSON_ALERT;
    }

    return null;
  };

  const menuContentRenderer: ItemListRenderer<Maybe<string>> = ({
    filteredItems,
    renderItem,
  }) => {
    const [nonLinkOptions, linkOptions] = partition(
      filteredItems,
      option => !isSome(option) || NON_LINK_OPTS.includes(option)
    );
    const suggestedLinkOptions = linkOptions.filter(
      option => isSome(option) && linkableUserSuggestionIds.has(option)
    );

    return (
      <>
        {nonLinkOptions.map(renderItem)}
        {suggestedLinkOptions.length > 0 ? (
          <>
            <StyledMenuHeaderDiv>Suggestions</StyledMenuHeaderDiv>
            {suggestedLinkOptions.map(renderItem)}
          </>
        ) : null}
        {linkOptions.length > 0 ? (
          <>
            <StyledMenuHeaderDiv>Alphabetical</StyledMenuHeaderDiv>
            {linkOptions.map(renderItem)}
          </>
        ) : null}
      </>
    );
  };

  const actionItem = accountIdsMap[account.id];
  const selectedOption = isSome(actionItem) ? actionItem : null;

  return (
    <DropdownButton
      options={menuOptions}
      selectedOption={selectedOption}
      onOptionSelect={option => {
        if (!isSome(option) || option !== ADD_NEW_USER_OPTION) {
          setMapping(account.id, option);
        } else if (option === ADD_NEW_USER_OPTION) {
          setAddingUserForAccount(account.id);
        }
      }}
      optionRenderer={optionRenderer}
      nullIcon={IconNames.CLOSE}
      nullOption="Clear"
      filterable={true}
      menuContentRenderer={menuContentRenderer}
      getIconForOption={getIconForOption}
      defaultText={"Find or add a person in Vanta"}
      onOpening={() => setMenuIsOpen(true)}
      buttonWidth={620}
      menuWidth={620}
      styleOnSelect
    />
  );
};
