import { Intent } from "@blueprintjs/core";
import React from "react";

import { BodyText } from "../../../alpaca/components";
import { LogError } from "../../../errors";
import {
  FetchDomainEndpointsDocument,
  GetUserComputersDocument,
  useRemoveLaptopsMutation,
} from "../../../gen/components";
import { AppToaster } from "../../../helpers/toaster";
import { CancelConfirmDialog } from "../../helpers/CancelConfirmDialog";
import type { IUserWorkstation } from "./utils";
import { ManagementMethod, monitoredByFromWorkstation } from "./utils";

interface IProps {
  workstation: IUserWorkstation;
  isOpen: boolean;
  domainId: string;
  onClose: () => void;
}

export const RemoveComputerDialog: React.FC<IProps> = ({
  workstation,
  domainId,
  isOpen,
  onClose,
}) => {
  const [removeLaptopsMutation] = useRemoveLaptopsMutation({
    refetchQueries: [
      {
        query: GetUserComputersDocument,
        variables: { userId: workstation.user.id },
      },
      { query: FetchDomainEndpointsDocument },
    ],
    awaitRefetchQueries: true,
  });

  return (
    <CancelConfirmDialog
      body={
        <BodyText>
          {`Removing a computer is permanent. Once removed, to have the computer reappear, ${workstation.user.displayName} will need
            to reinstall the Vanta Agent.`}
        </BodyText>
      }
      confirmText="Remove computer"
      isOpen={isOpen}
      title={`Remove computer`}
      onClose={() => onClose()}
      onConfirm={async () => {
        if (workstation.managedVia !== ManagementMethod.VantaAgent) {
          const monitoredBy = monitoredByFromWorkstation(workstation);
          throw new Error(`Trying to remove ${monitoredBy}-managed computer`);
        }

        try {
          await removeLaptopsMutation({
            variables: {
              domainId,
              ids: [workstation.id],
            },
          });
          AppToaster.show({
            intent: Intent.SUCCESS,
            message: "Computer removed",
          });

          onClose();
        } catch (e) {
          LogError(e);
        }
      }}
    />
  );
};
