{
  "standardInfo": {
    "standard": "pciSaqAEP"
  },
  "controls": [
    {
      "id": "1.1.1",
      "name": "Firewall and router configuration process",
      "description": "Are firewall and router configuration standards established and implemented to include the following:\n\nIs there a formal process for approving and testing all network connections and changes to the firewall and router configurations?",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-operations-security-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "1.1.2.a",
      "name": "Network diagram",
      "description": "Are firewall and router configuration standards established and implemented to include the following:\n\nIs there a current network diagram that documents all connections between the cardholder data environment and other networks, including any wireless networks?",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "network-diagram"
        }
      ]
    },
    {
      "id": "1.1.2.b",
      "name": "Network diagram documentation process",
      "description": "Are firewall and router configuration standards established and implemented to include the following:\n\nIs there a process to ensure the diagram is kept current?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "1.1.3.a",
      "name": "Cardholder dataflow diagram",
      "description": "Are firewall and router configuration standards established and implemented to include the following:\n\nIs there a current diagram that shows all cardholder data flows across systems and networks?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "cardholder-dataflow-diagram-exists"
        }
      ]
    },
    {
      "id": "1.1.3.b",
      "name": "Cardholder dataflow documentation process",
      "description": "Are firewall and router configuration standards established and implemented to include the following:\n\nIs there a process to ensure the diagram is kept current?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "cardholder-dataflow-diagram-process"
        }
      ]
    },
    {
      "id": "1.1.4.a",
      "name": "Firewall in DMZ and internal network zones",
      "description": "Are firewall and router configuration standards established and implemented to include the following:\n\nIs a firewall required and implemented at each Internet connection and between any demilitarized zone (DMZ) and the internal network zone?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "firewall-config-dmz-exists"
        },
        {
          "id": "firewall-config-standard-exists"
        }
      ]
    },
    {
      "id": "1.1.4.b",
      "name": "Network diagram and firewall configuration consistency",
      "description": "Are firewall and router configuration standards established and implemented to include the following:\n\nIs the current network diagram consistent with the firewall configuration standards?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "cardholder-network-diagram-process"
        }
      ]
    },
    {
      "id": "1.1.6.a",
      "name": "Firewall and router configuration services and protocols documented",
      "description": "Are firewall and router configuration standards established and implemented to include the following:\n\nDo firewall and router configuration standards include a documented list of services, protocols, and ports, including business justification and approval for each?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": []
    },
    {
      "id": "1.1.6.b",
      "name": "Insecure services and protocols documented",
      "description": "Are firewall and router configuration standards established and implemented to include the following:\n\nAre all insecure services, protocols, and ports identified, and are security features documented and implemented for each identified service?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "1.1.7.a",
      "name": "Firewall and router configuration recurring review",
      "description": "Are firewall and router configuration standards established and implemented to include the following:\n\nDo firewall and router configuration standards require review of firewall and router rule sets at least every six months?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "1.1.7.b",
      "name": "Firewall and router rule sets recurring review",
      "description": "Are firewall and router configuration standards established and implemented to include the following:\n\nAre firewall and router rule sets reviewed at least every six months?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "traffic-authorized-and reviewed"
        }
      ]
    },
    {
      "id": "1.2.1.a",
      "name": "Inbound and outbound traffic restriction",
      "description": "Do firewall and router configurations restrict connections between untrusted networks and any system in the cardholder data environment as follows:\n\nNote: An “untrusted network” is any network that is external to the networks belonging to the entity under review, and/or which is out of the entity’s ability to control or manage.\n\nIs inbound and outbound traffic restricted to that which is necessary for the cardholder data environment?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "digitalocean-firewalls-ports-restricted"
        },
        {
          "id": "firewall-config-acl"
        },
        {
          "id": "firewall-config-acl-gcp"
        },
        {
          "id": "firewall-config-acl-heroku"
        },
        {
          "id": "firewall-config-default-deny"
        },
        {
          "id": "firewall-config-default-deny-gcp"
        },
        {
          "id": "firewall-config-default-deny-heroku"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "1.2.1.b",
      "name": "Inbound and outbound traffic denied",
      "description": "Do firewall and router configurations restrict connections between untrusted networks and any system in the cardholder data environment as follows:\n\nNote: An “untrusted network” is any network that is external to the networks belonging to the entity under review, and/or which is out of the entity’s ability to control or manage.\n\nIs all other inbound and outbound traffic specifically denied (for example by using an explicit “deny all” or an implicit deny after allow statement)?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "aws-s3-public-access-blocked"
        },
        {
          "id": "firewall-config-default-deny"
        },
        {
          "id": "firewall-config-default-deny-gcp"
        },
        {
          "id": "firewall-config-default-deny-heroku"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "1.2.2",
      "name": "Router config files secured and synchronized",
      "description": "Do firewall and router configurations restrict connections between untrusted networks and any system in the cardholder data environment as follows:\n\nNote: An “untrusted network” is any network that is external to the networks belonging to the entity under review, and/or which is out of the entity’s ability to control or manage.\n\nAre router configuration files secured from unauthorized access and synchronized—for example, the running (or active) configuration matches the start-up configuration (used when machines are booted)?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "routers-secure-syncronized"
        }
      ]
    },
    {
      "id": "1.2.3",
      "name": "Secure perimeter firewalls installed",
      "description": "Do firewall and router configurations restrict connections between untrusted networks and any system in the cardholder data environment as follows:\n\nNote: An “untrusted network” is any network that is external to the networks belonging to the entity under review, and/or which is out of the entity’s ability to control or manage.\n\nAre perimeter firewalls installed between all wireless networks and the cardholder data environment, and are these firewalls configured to deny or, if traffic is necessary for business purposes, permit only authorized traffic between the wireless environment and the cardholder data environment?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "firewall-config-acl"
        },
        {
          "id": "firewall-config-acl-gcp"
        },
        {
          "id": "firewall-config-acl-heroku"
        },
        {
          "id": "inventory-list-descriptions"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "1.3.1",
      "name": "Inbound traffic limited via DMZ",
      "description": "Is direct public access prohibited between the Internet and any system component in the cardholder data environment, as follows:\n\nIs a DMZ implemented to limit inbound traffic to only system components that provide authorized publicly accessible services, protocols, and ports?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "firewall-config-dmz-exists"
        }
      ]
    },
    {
      "id": "1.3.2",
      "name": "Inbound traffic limited to DMZ IP addresses",
      "description": "Is direct public access prohibited between the Internet and any system component in the cardholder data environment, as follows:\n\nIs inbound Internet traffic limited to IP addresses within the DMZ?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "firewall-config-dmz-exists"
        }
      ]
    },
    {
      "id": "1.3.3",
      "name": "Anti-spoofing measures implemented",
      "description": "Is direct public access prohibited between the Internet and any system component in the cardholder data environment, as follows:\n\nAre anti-spoofing measures implemented to detect and block forged sourced IP addresses from entering the network? \n(For example, block traffic originating from the internet with an internal address.)",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "anti-spoofing-measures"
        }
      ]
    },
    {
      "id": "1.3.4",
      "name": "Outbound traffic authorized",
      "description": "Is direct public access prohibited between the Internet and any system component in the cardholder data environment, as follows:\n\nIs outbound traffic from the cardholder data environment to the Internet explicitly authorized?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "acl-reviewed-approved"
        }
      ]
    },
    {
      "id": "1.3.5",
      "name": "Established connections restricted",
      "description": "Is direct public access prohibited between the Internet and any system component in the cardholder data environment, as follows:\n\nAre only established connections permitted into the network?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "stateful-inspection-not-bypassed"
        }
      ]
    },
    {
      "id": "1.3.7.a",
      "name": "Private IP address disclosure prevention",
      "description": "Is direct public access prohibited between the Internet and any system component in the cardholder data environment, as follows:\n\nAre methods in place to prevent the disclosure of private IP addresses and routing information to the Internet?\n\nNote: Methods to obscure IP addressing may include, but are not limited to:\n• Network Address Translation (NAT)\n• Placing servers containing cardholder data behind proxy servers/firewalls, \n• Removal or filtering of route advertisements for private networks that employ registered addressing,\n• Internal use of RFC1918 address space instead of registered addresses.",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "nat-not-bypassed"
        }
      ]
    },
    {
      "id": "1.3.7.b",
      "name": "Private IP address disclosure authorized",
      "description": "Is direct public access prohibited between the Internet and any system component in the cardholder data environment, as follows:\n\nIs any disclosure of private IP addresses and routing information to external entities authorized?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "internal-ip-disclosure-approved"
        }
      ]
    },
    {
      "id": "1.4.a",
      "name": "Personal firewall installed",
      "description": "Is personal firewall software (or equivalent functionality) installed and active on any portable computing devices (including company and/or employee-owned) that connect to the Internet when outside the network (for example, laptops used by employees), and which are also used to access the CDE?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pfw-standards-exist"
        }
      ]
    },
    {
      "id": "1.4.b",
      "name": "Personal firewall configured",
      "description": "Is the personal firewall software (or equivalent functionality) configured to specific configuration settings, actively running, and not alterable by users of mobile and/or employee-owned devices?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pfw-standards-exist"
        }
      ]
    },
    {
      "id": "1.5",
      "name": "Firewall management policies and procedures",
      "description": "Are security policies and operational procedures for managing firewalls: \n• Documented\n• In use\n• Known to all affected parties?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-operations-security-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "employees-accepted-operations-security-policy-bsi"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "2.1.a",
      "name": "Vendor defaults changed before installation",
      "description": "Are vendor-supplied defaults always changed before installing a system on the network?\n\nThis applies to ALL default passwords, including but not limited to those used by operating systems, software that provides security services, application and system accounts, point-of-sale (POS) terminals, payment applications, Simple Network Management Protocol (SNMP) community strings, etc.).",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "vendor-defaults-disabled"
        }
      ]
    },
    {
      "id": "2.1.b",
      "name": "Unnecessary default accounts removed",
      "description": "Are unnecessary default accounts removed or disabled before installing a system on the network?",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "vendor-defaults-disabled"
        }
      ]
    },
    {
      "id": "2.2.a",
      "name": "Configuration standards developed for system components",
      "description": "Are configuration standards developed for all system components and are they consistent with industry-accepted system hardening standards? \n\nSources of industry-accepted system hardening standards may include, but are not limited to, SysAdmin Audit Network Security (SANS) Institute, National Institute of Standards Technology (NIST), International Organization for Standardization (ISO), and Center for Internet Security (CIS).",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "2.2.b",
      "name": "System configuration standards updated along with newly discovered vulnerability issues",
      "description": "Are system configuration standards updated as new vulnerability issues are identified, as defined in Requirement 6.1?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pci-standards-updated"
        }
      ]
    },
    {
      "id": "2.2.c",
      "name": "System configuration standards are applied",
      "description": "Are system configuration standards applied when new systems are configured?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "2.2.d",
      "name": "System configuration requirements",
      "description": "Do system configuration standards include all of the following: \n• Changing of all vendor-supplied defaults and elimination of unnecessary default accounts?\n• Implementing only one primary function per server to prevent functions that require different security levels from co-existing on the same server?\n• Enabling only necessary services, protocols, daemons, etc., as required for the function of the system?\n• Implementing additional security features for any required services, protocols or daemons that are considered to be insecure?\n• Configuring system security parameters to prevent misuse?\n• Removing all unnecessary functionality, such as scripts, drivers, features, subsystems, file systems, and unnecessary web servers?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "2.2.1.a",
      "name": "One primary function implemented per server",
      "description": "Is only one primary function implemented per server, to prevent functions that require different security levels from co-existing on the same server?\n\nFor example, web servers, database servers, and DNS should be implemented on separate servers.",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "dedicated-primary-function"
        }
      ]
    },
    {
      "id": "2.2.1.b",
      "name": "One primary function implemented per virtualization technology",
      "description": "If virtualization technologies are used, is only one primary function implemented per virtual system component or device?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "dedicated-primary-function"
        }
      ]
    },
    {
      "id": "2.2.2.b",
      "name": "Enabled insecure services have justifications",
      "description": "Are all enabled insecure services, daemons, or protocols justified per documented configuration standards?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "2.2.3",
      "name": "Additional security features documented for installed insecure services",
      "description": "Are additional security features documented and implemented for any required services, protocols or daemons that are considered to be insecure?\n\nNote: Where SSL/early TLS is used, the requirements in Appendix A2 must be completed.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "2.2.4.a",
      "name": "Personnel knowledgeable about security settings for system components",
      "description": "Are system administrators and/or personnel that configure system components knowledgeable about common security parameter settings for those system components?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "system-admins-aware"
        }
      ]
    },
    {
      "id": "2.2.4.b",
      "name": "System configuration standards include common system settings",
      "description": "Are common system security parameters settings included in the system configuration standards?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "2.2.4.c",
      "name": "System parameter settings set",
      "description": "Are security parameter settings set appropriately on system components?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "systems-configured-securely"
        }
      ]
    },
    {
      "id": "2.2.5.a",
      "name": "Unnecessary functionality removed",
      "description": "Has all unnecessary functionality—such as scripts, drivers, features, subsystems, file systems, and unnecessary web servers—been removed?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "unnecesary-functions-removed"
        }
      ]
    },
    {
      "id": "2.2.5.b",
      "name": "Enabled functions documented",
      "description": "Are enabled functions documented and do they support secure configuration?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "2.2.5.c",
      "name": "Only documented functionality present",
      "description": "Is only documented functionality present on system components?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "systems-configured-securely"
        }
      ]
    },
    {
      "id": "2.3.a",
      "name": "Non-console admin access encrypted",
      "description": "Is non-console administrative access encrypted as follows: \n\nNote: Where SSL/early TLS is used, the requirements in Appendix A2 must be completed\n\nIs all non-console administrative access encrypted with strong cryptography, and is a strong encryption method invoked before the administrator’s password is requested?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "require-admin-encrypted-iaas"
        },
        {
          "id": "require-admin-encrypted-iaas-gcp"
        },
        {
          "id": "require-admin-encrypted-iaas-heroku"
        },
        {
          "id": "require-ssh-config"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "strong-cryptography-used"
        }
      ]
    },
    {
      "id": "2.3.b",
      "name": "Insecure remote login commands prevented",
      "description": "Is non-console administrative access encrypted as follows: \n\nNote: Where SSL/early TLS is used, the requirements in Appendix A2 must be completed\n\nAre system services and parameter files configured to prevent the use of Telnet and other insecure remote login commands?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "2.3.c",
      "name": "Web-based management admin access encrypted",
      "description": "Is non-console administrative access encrypted as follows: \n\nNote: Where SSL/early TLS is used, the requirements in Appendix A2 must be completed\n\nIs administrator access to web-based management interfaces encrypted with strong cryptography?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "require-admin-encrypted-iaas"
        },
        {
          "id": "require-admin-encrypted-iaas-gcp"
        },
        {
          "id": "require-admin-encrypted-iaas-heroku"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "strong-cryptography-used"
        }
      ]
    },
    {
      "id": "2.3.d",
      "name": "Strong cryptography implemented",
      "description": "Is non-console administrative access encrypted as follows: \n\nNote: Where SSL/early TLS is used, the requirements in Appendix A2 must be completed\n\nFor the technology in use, is strong cryptography implemented according to industry best practice and/or vendor recommendations?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-cryptography-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "strong-cryptography-used"
        }
      ]
    },
    {
      "id": "3.2.c",
      "name": "Deleted sensitive authentication data unrecoverable",
      "description": "For non issuers, is sensitive authentication data deleted or rendered unrecoverable upon completion of the authorization process?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "retention-of-sad"
        }
      ]
    },
    {
      "id": "3.2.2",
      "name": "Card verification code (CVE) not stored",
      "description": "Do all systems adhere to the following requirements regarding non-storage of sensitive authentication data after authorization (even if encrypted):\n\nThe card verification code or value (three-digit or four-digit number printed on the front or back of a payment card) is not stored after authorization?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "retention-of-sad"
        }
      ]
    },
    {
      "id": "3.2.3",
      "name": "Personal identification number (PIN) not stored",
      "description": "Do all systems adhere to the following requirements regarding non-storage of sensitive authentication data after authorization (even if encrypted):\n\nThe personal identification number (PIN) or the encrypted PIN block is not stored after authorization?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "retention-of-sad"
        }
      ]
    },
    {
      "id": "4.1.a",
      "name": "Protocols exist to protect cardholder data during transmission",
      "description": "Are strong cryptography and security protocols used to safeguard sensitive cardholder data during transmission over open, public networks? \n\nNote: Where SSL/early TLS is used, the requirements in Appendix A2 must be completed.\nExamples of open, public networks include but are not limited to the Internet; wireless technologies, including 802.11 and Bluetooth; cellular technologies, for example, Global System for Mobile communications (GSM), Code division multiple access (CDMA); and General Packet Radio Service (GPRS).",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "ssl-payment-pages"
        }
      ]
    },
    {
      "id": "4.1.b",
      "name": "Only trusted keys accepted\nfor transmission",
      "description": "Are only trusted keys and/or certificates accepted?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "ssl-payment-pages"
        }
      ]
    },
    {
      "id": "4.1.c",
      "name": "Secure configurations exist for transmission",
      "description": "Are security protocols implemented to use only secure configurations, and to not support insecure versions or configurations?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "ssl-payment-pages"
        }
      ]
    },
    {
      "id": "4.1.d",
      "name": "Encryption strength implemented for transmission",
      "description": "Is the proper encryption strength implemented for the encryption methodology in use (check vendor recommendations/best practices)?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-cryptography-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "ssl-payment-pages"
        }
      ]
    },
    {
      "id": "4.1.e",
      "name": "TLS enabled for cardholder data",
      "description": "For TLS implementations, is TLS enabled whenever cardholder data is transmitted or received? \n\nFor example, for browser-based implementations:\n• “HTTPS” appears as the browser Universal Record Locator (URL) protocol, and\n• Cardholder data is only requested if “HTTPS” appears as part of the URL.",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "ssl-payment-pages"
        }
      ]
    },
    {
      "id": "4.2.b",
      "name": "Policies to prevent transmission of unprotected PANs exist",
      "description": "Are policies in place that state that unprotected PANs are not to be sent via end-user messaging technologies?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "4.3",
      "name": "Security policies for encrypting cardholder data transmission exist",
      "description": "Are security policies and operational procedures for encrypting transmissions of cardholder data:\n• Documented\n• In use\n• Known to all affected parties?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-cryptography-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "employees-accepted-cryptography-policy-bsi"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "5.1",
      "name": "Anti-virus software deployed",
      "description": "Is anti-virus software deployed on all systems commonly affected by malicious software?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "malware-detection-workstations"
        },
        {
          "id": "malware-detection-workstations-jamf"
        },
        {
          "id": "malware-detection-workstations-kandji"
        },
        {
          "id": "malware-detection-workstations-microsoft-endpoint-manager"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "malware-configuration"
        }
      ]
    },
    {
      "id": "5.1.1",
      "name": "Anti-virus capabilities",
      "description": "Are anti-virus programs capable of detecting, removing, and protecting against all known types of malicious software (for example, viruses, Trojans, worms, spyware, adware, and rootkits)?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "malware-configuration"
        }
      ]
    },
    {
      "id": "5.1.2",
      "name": "Malware threats regularly evaluated",
      "description": "Are periodic evaluations performed to identify and evaluate evolving malware threats in order to confirm whether those systems considered to not be commonly affected by malicious software continue as such?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pci-malware-exception"
        }
      ]
    },
    {
      "id": "5.2.a",
      "name": "Anti-virus software definitions kept current",
      "description": "Are all anti-virus mechanisms maintained as follows:\n\nAre all anti-virus software and definitions kept current?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "malware-configuration"
        }
      ]
    },
    {
      "id": "5.2.b",
      "name": "Automatic updates and period scans performed",
      "description": "Are all anti-virus mechanisms maintained as follows:\n\nAre automatic updates and periodic scans enabled and being performed?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "malware-configuration"
        }
      ]
    },
    {
      "id": "5.2.c",
      "name": "Anti-virus audit logs retained",
      "description": "Are all anti-virus mechanisms maintained as follows:\n\nAre all anti-virus mechanisms generating audit logs, and are logs retained in accordance with PCI DSS Requirement 10.7?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "logs-retained-for-twelve-months-config"
        },
        {
          "id": "logs-retained-for-twelve-months-config-gcp"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "malware-configuration"
        }
      ]
    },
    {
      "id": "5.3",
      "name": "Anti-virus actively running and alteration restricted",
      "description": "Are all anti-virus mechanisms:\n• Actively running?\n• Unable to be disabled or altered by users?\n\nNote: Anti-virus solutions may be temporarily disabled only if there is legitimate technical need, as authorized by management on a case-by-case basis. If anti-virus protection needs to be disabled for a specific purpose, it must be formally authorized. Additional security measures may also need to be implemented for the period of time during which anti-virus protection is not active.",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "malware-configuration"
        }
      ]
    },
    {
      "id": "5.4",
      "name": "Security policies against malware exist",
      "description": "Are security policies and operational procedures for protecting systems against malware: \n• Documented\n• In use\n• Known to all affected parties?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-information-security-policy-bsi-exists"
        },
        {
          "id": "approved-operations-security-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "employees-accepted-information-security-policy-bsi"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "6.1",
      "name": "Security vulnerabilities identification process exists",
      "description": "Is there a process to identify security vulnerabilities, including the following:\n• Using reputable outside sources for vulnerability information? \n• Assigning a risk ranking to vulnerabilities that includes identification of all “high” risk and “critical” vulnerabilities? \n\nNote: Risk rankings should be based on industry best practices as well as consideration of potential impact. For example, criteria for ranking vulnerabilities may include consideration of the CVSS base score and/or the classification by the vendor, and/or type of systems affected.\nMethods for evaluating vulnerabilities and assigning risk ratings will vary based on an organization’s environment and risk assessment strategy.  Risk rankings should, at a minimum, identify all vulnerabilities considered to be a “high risk” to the environment. In addition to the risk ranking, vulnerabilities may be considered “critical” if they pose an imminent threat to the environment, impact critical systems, and/or would result in a potential compromise if not addressed. Examples of critical systems may include security systems, public-facing devices and systems, databases, and other systems that store, process or transmit cardholder data.",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "external-alerts-reviewed"
        }
      ]
    },
    {
      "id": "6.2.a",
      "name": "Vendor-supplied security patches installed",
      "description": "Are all system components and software protected from known vulnerabilities by installing applicable vendor-supplied security patches?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-operations-security-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "6.2.b",
      "name": "Security patches installed within one month",
      "description": "Are critical security patches installed within one month of release?\n\nNote: Critical security patches should be identified according to the risk ranking process defined in Requirement 6.1.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-operations-security-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pci-critical-security-patches"
        }
      ]
    },
    {
      "id": "6.4.5.a",
      "name": "Change control procedures documented",
      "description": "Are change-control procedures documented and require the following? \n• Documentation of impact\n• Documented change control approval by authorized parties\n• Functionality testing to verify that the change does not adversely impact the security of the system\n• Back-out procedures",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "approved-secure-development-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pci-application-change-control"
        }
      ]
    },
    {
      "id": "6.4.5.1",
      "name": "Change control documentation includes impact",
      "description": "Are the following performed and documented for all changes:\n\nDocumentation of impact?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "approved-secure-development-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pci-application-change-control"
        }
      ]
    },
    {
      "id": "6.4.5.2",
      "name": "Change control documentation approved",
      "description": "Are the following performed and documented for all changes:\n\nDocumented approval by authorized parties?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "approved-secure-development-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pci-application-change-control"
        }
      ]
    },
    {
      "id": "6.4.5.3.a",
      "name": "Functionality testing exists",
      "description": "Are the following performed and documented for all changes:\n\nFunctionality testing to verify that the change does not adversely impact the security of the system?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "approved-secure-development-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pci-application-change-control"
        }
      ]
    },
    {
      "id": "6.4.5.3.b",
      "name": "Custom code complies with PCI DSS Req 6.5",
      "description": "Are the following performed and documented for all changes:\n\nFor custom code changes, testing of updates for compliance with PCI DSS Requirement 6.5 before being deployed into production?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "approved-secure-development-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pci-application-change-control"
        }
      ]
    },
    {
      "id": "6.4.5.4",
      "name": "Back out procedures",
      "description": "Are the following performed and documented for all changes:\n\nBack-out procedures?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "approved-secure-development-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pci-application-change-control"
        }
      ]
    },
    {
      "id": "6.4.6",
      "name": "PCI DSS requirements implemented\nupon change",
      "description": "Upon completion of a significant change, are all relevant PCI DSS requirements implemented on all new or changed systems and networks, and documentation updated as applicable?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "approved-secure-development-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pci-application-change-control"
        }
      ]
    },
    {
      "id": "6.5.a",
      "name": "Common coding vulnerabilities addressed",
      "description": "Do software-development processes address common coding vulnerabilities?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "approved-secure-development-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pci-code-review-performed"
        }
      ]
    },
    {
      "id": "6.5.b",
      "name": "Developers trained annually",
      "description": "Are developers trained at least annually in up-to-date secure coding techniques, including how to avoid common coding vulnerabilities?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "approved-secure-development-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "developers-receive-pci-training"
        }
      ]
    },
    {
      "id": "6.5.1",
      "name": "Injection flaws addressed",
      "description": "Are applications developed based on secure coding guidelines to protect applications from, at a minimum, the following vulnerabilities:\n\nNote: The vulnerabilities listed at 6.5.1 through 6.5.10 were current with industry best practices when this version of PCI DSS was published. However, as industry best practices for vulnerability management are update d (for example, the Open Web Application Security Project (OWASP) Guide, SANS CWE Top 25, CERT Secure Coding, etc.), the current best practices must be used for these requirements.\n\nDo coding techniques address injection flaws, particularly SQL injection?\n\nNote: Also consider OS Command Injection, LDAP and XPath injection flaws as well as other injection flaws.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "approved-secure-development-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pci-code-review-performed"
        }
      ]
    },
    {
      "id": "6.5.2",
      "name": "Buffer overflow vulnerabilities addressed",
      "description": "Are applications developed based on secure coding guidelines to protect applications from, at a minimum, the following vulnerabilities:\n\nNote: The vulnerabilities listed at 6.5.1 through 6.5.10 were current with industry best practices when this version of PCI DSS was published. However, as industry best practices for vulnerability management are update d (for example, the Open Web Application Security Project (OWASP) Guide, SANS CWE Top 25, CERT Secure Coding, etc.), the current best practices must be used for these requirements.\n\nDo coding techniques address buffer overflow vulnerabilities?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "approved-secure-development-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pci-code-review-performed"
        }
      ]
    },
    {
      "id": "6.5.4",
      "name": "Insecure communications addressed",
      "description": "Are applications developed based on secure coding guidelines to protect applications from, at a minimum, the following vulnerabilities:\n\nNote: The vulnerabilities listed at 6.5.1 through 6.5.10 were current with industry best practices when this version of PCI DSS was published. However, as industry best practices for vulnerability management are update d (for example, the Open Web Application Security Project (OWASP) Guide, SANS CWE Top 25, CERT Secure Coding, etc.), the current best practices must be used for these requirements.\n\nDo coding techniques address insecure communications?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "approved-secure-development-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pci-code-review-performed"
        }
      ]
    },
    {
      "id": "6.5.5",
      "name": "Improper error handling addressed",
      "description": "Are applications developed based on secure coding guidelines to protect applications from, at a minimum, the following vulnerabilities:\n\nNote: The vulnerabilities listed at 6.5.1 through 6.5.10 were current with industry best practices when this version of PCI DSS was published. However, as industry best practices for vulnerability management are update d (for example, the Open Web Application Security Project (OWASP) Guide, SANS CWE Top 25, CERT Secure Coding, etc.), the current best practices must be used for these requirements.\n\nDo coding techniques address improper error handling?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "approved-secure-development-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pci-code-review-performed"
        }
      ]
    },
    {
      "id": "6.5.6",
      "name": "High risk vulnerabilities addressed",
      "description": "Are applications developed based on secure coding guidelines to protect applications from, at a minimum, the following vulnerabilities:\n\nNote: The vulnerabilities listed at 6.5.1 through 6.5.10 were current with industry best practices when this version of PCI DSS was published. However, as industry best practices for vulnerability management are update d (for example, the Open Web Application Security Project (OWASP) Guide, SANS CWE Top 25, CERT Secure Coding, etc.), the current best practices must be used for these requirements.\n\nDo coding techniques address all “high risk” vulnerabilities identified in the vulnerability identification process (as defined in PCI DSS Requirement 6.1)?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "approved-secure-development-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pci-code-review-performed"
        }
      ]
    },
    {
      "id": "6.5.7",
      "name": "Cross-site scripting (XSS) vulnerabilities addressed",
      "description": "Are applications developed based on secure coding guidelines to protect applications from, at a minimum, the following vulnerabilities:\n\nNote: The vulnerabilities listed at 6.5.1 through 6.5.10 were current with industry best practices when this version of PCI DSS was published. However, as industry best practices for vulnerability management are update d (for example, the Open Web Application Security Project (OWASP) Guide, SANS CWE Top 25, CERT Secure Coding, etc.), the current best practices must be used for these requirements.\n\nDo coding techniques address cross-site scripting (XSS) vulnerabilities?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "approved-secure-development-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pci-code-review-performed"
        }
      ]
    },
    {
      "id": "6.5.8",
      "name": "Improper access controls addressed",
      "description": "Are applications developed based on secure coding guidelines to protect applications from, at a minimum, the following vulnerabilities:\n\nNote: The vulnerabilities listed at 6.5.1 through 6.5.10 were current with industry best practices when this version of PCI DSS was published. However, as industry best practices for vulnerability management are update d (for example, the Open Web Application Security Project (OWASP) Guide, SANS CWE Top 25, CERT Secure Coding, etc.), the current best practices must be used for these requirements.\n\nDo coding techniques address improper access control such as insecure direct object references, failure to restrict URL access, directory traversal, and failure to restrict user access to functions?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "approved-secure-development-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pci-code-review-performed"
        }
      ]
    },
    {
      "id": "6.5.9",
      "name": "Cross-site request forgery (CSRF) addressed",
      "description": "Are applications developed based on secure coding guidelines to protect applications from, at a minimum, the following vulnerabilities:\n\nNote: The vulnerabilities listed at 6.5.1 through 6.5.10 were current with industry best practices when this version of PCI DSS was published. However, as industry best practices for vulnerability management are update d (for example, the Open Web Application Security Project (OWASP) Guide, SANS CWE Top 25, CERT Secure Coding, etc.), the current best practices must be used for these requirements.\n\nDo coding techniques address cross-site request forgery (CSRF)?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "approved-secure-development-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pci-code-review-performed"
        }
      ]
    },
    {
      "id": "6.5.10",
      "name": "Broken authentication and session management addressed",
      "description": "Are applications developed based on secure coding guidelines to protect applications from, at a minimum, the following vulnerabilities:\n\nNote: The vulnerabilities listed at 6.5.1 through 6.5.10 were current with industry best practices when this version of PCI DSS was published. However, as industry best practices for vulnerability management are update d (for example, the Open Web Application Security Project (OWASP) Guide, SANS CWE Top 25, CERT Secure Coding, etc.), the current best practices must be used for these requirements.\n\nDo coding techniques address broken authentication and session management?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "approved-secure-development-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pci-code-review-performed"
        }
      ]
    },
    {
      "id": "6.6",
      "name": "Threats and vulnerabilities regularly addressed",
      "description": "For public-facing web applications, are new threats and vulnerabilities addressed on an ongoing basis, and are these applications protected against known attacks by applying either of the following methods? \n• Reviewing public-facing web applications via manual or automated application vulnerability security assessment tools or methods, as follows:\n   \\- At least annually\n   \\- After any changes\n   \\- By an organization that specializes in application security\n   \\- That, at a minimum, all vulnerabilities in Requirement 6.5 are included in the assessment \n   \\- That all vulnerabilities are corrected\n   \\- That the application is re-evaluated after the corrections\n\nNote: This assessment is not the same as the vulnerability scans performed for Requirement 11.2.\n– OR – \n• Installing an automated technical solution that detects and prevents web-based attacks (for example, a web-application firewall) as follows: \n   \\- Is situated in front of public-facing web applications to detect and prevent web-based attacks.\n   \\- Is actively running and up to date as applicable.\n   \\- Is generating audit logs. \n   \\- Is configured to either block web-based attacks, or generate an alert that is immediately investigated.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "approved-secure-development-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "waf-enabled-and-configured"
        }
      ]
    },
    {
      "id": "6.7",
      "name": "Security policies and procedures exist",
      "description": "Are security policies and operational procedures for developing and maintaining secure systems and applications:\n• Documented\n• In use\n• Known to all affected parties?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "approved-secure-development-policy-bsi-exists"
        },
        {
          "id": "employees-accepted-secure-development-policy-bsi"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "7.1",
      "name": "Cardholder data access policy requirements",
      "description": "Is access to system components and cardholder data limited to only those individuals whose jobs require such access, as follows:\nIs there a written policy for access control that incorporates the following?  \n• Defining access needs and privilege assignments \nfor each role  \n• Restriction of access to privileged user IDs to \nleast privileges necessary to perform job \nresponsibilities,  \n• Assignment of access based on individual \npersonnel’s job classification and function  \n• Documented approval (electronically or in writing) \nby authorized parties for all access, including \nlisting of specific privileges approved",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "7.1.2",
      "name": "Privileged user ID access restricted",
      "description": "Is access to privileged user IDs restricted as follows: \n• To least privileges necessary to perform job responsibilities?\n• Assigned only to roles that specifically require that privileged access?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-access-defined"
        }
      ]
    },
    {
      "id": "7.1.3",
      "name": "Access assigned based on role",
      "description": "Is access assigned based on individual personnel’s job classification and function?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-access-defined"
        }
      ]
    },
    {
      "id": "7.1.4",
      "name": "Authorization for access exists",
      "description": "Is documented approval by authorized parties required, specifying required privileges?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "access-approval-required"
        }
      ]
    },
    {
      "id": "8.1.1",
      "name": "Users assigned unique ID",
      "description": "Are policies and procedures for user identification management controls defined and in place for non-consumer users and administrators on all system components, as follows:\n\nAre all users assigned a unique ID before allowing them to access system components or cardholder data?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "8.1.2",
      "name": "User IDs modified only as authorized",
      "description": "Are policies and procedures for user identification management controls defined and in place for non-consumer users and administrators on all system components, as follows:\n\nAre additions, deletions, and modifications of user IDs, credentials, and other identifier objects controlled such that user IDs are implemented only as authorized (including with specified privileges)?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pci-access-defined"
        }
      ]
    },
    {
      "id": "8.1.3",
      "name": "Terminated user access removed",
      "description": "Are policies and procedures for user identification management controls defined and in place for non-consumer users and administrators on all system components, as follows:\n\nIs access for any terminated users immediately deactivated or removed?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "access-removed-infra"
        },
        {
          "id": "access-removed-versioncontrol"
        },
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "asana-account-access-removed-on-termination"
        },
        {
          "id": "aws-account-access-removed-on-termination"
        },
        {
          "id": "azuredevops-account-access-removed-on-termination"
        },
        {
          "id": "bitbucket-account-access-removed-on-termination"
        },
        {
          "id": "clickup-account-access-removed-on-termination"
        },
        {
          "id": "clubhouse-account-access-removed-on-termination"
        },
        {
          "id": "datadog-account-access-removed-on-termination"
        },
        {
          "id": "github-account-access-removed-on-termination"
        },
        {
          "id": "gitlab-account-access-removed-on-termination"
        },
        {
          "id": "heroku-account-access-removed-on-termination"
        },
        {
          "id": "jamf-account-access-removed-on-termination"
        },
        {
          "id": "jira-account-access-removed-on-termination"
        },
        {
          "id": "knowbe4-account-access-removed-on-termination"
        },
        {
          "id": "linear-account-access-removed-on-termination"
        },
        {
          "id": "mongoatlas-account-access-removed-on-termination"
        },
        {
          "id": "pivotaltracker-account-access-removed-on-termination"
        },
        {
          "id": "slack-account-access-removed-on-termination"
        },
        {
          "id": "snyk-account-access-removed-on-termination"
        },
        {
          "id": "trello-account-access-removed-on-termination"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "8.1.4",
      "name": "Inactive accounts removed within 90 days",
      "description": "Are policies and procedures for user identification management controls defined and in place for non-consumer users and administrators on all system components, as follows:\n\nAre inactive user accounts either removed or disabled within 90 days?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "access-removed-infra"
        },
        {
          "id": "access-removed-versioncontrol"
        },
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "asana-account-access-removed-on-termination"
        },
        {
          "id": "aws-account-access-removed-on-termination"
        },
        {
          "id": "azuredevops-account-access-removed-on-termination"
        },
        {
          "id": "bitbucket-account-access-removed-on-termination"
        },
        {
          "id": "clickup-account-access-removed-on-termination"
        },
        {
          "id": "clubhouse-account-access-removed-on-termination"
        },
        {
          "id": "datadog-account-access-removed-on-termination"
        },
        {
          "id": "github-account-access-removed-on-termination"
        },
        {
          "id": "gitlab-account-access-removed-on-termination"
        },
        {
          "id": "heroku-account-access-removed-on-termination"
        },
        {
          "id": "jamf-account-access-removed-on-termination"
        },
        {
          "id": "jira-account-access-removed-on-termination"
        },
        {
          "id": "knowbe4-account-access-removed-on-termination"
        },
        {
          "id": "linear-account-access-removed-on-termination"
        },
        {
          "id": "mongoatlas-account-access-removed-on-termination"
        },
        {
          "id": "pivotaltracker-account-access-removed-on-termination"
        },
        {
          "id": "slack-account-access-removed-on-termination"
        },
        {
          "id": "snyk-account-access-removed-on-termination"
        },
        {
          "id": "trello-account-access-removed-on-termination"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "8.1.5.a",
      "name": "Third party access enabled only as needed",
      "description": "Are policies and procedures for user identification management controls defined and in place for non-consumer users and administrators on all system components, as follows:\n\nAre accounts used by third parties to access, support, or maintain system components via remote access enabled only during the time period needed and disabled when not in use?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "vendors-monitored-and-disabled"
        }
      ]
    },
    {
      "id": "8.1.5.b",
      "name": "Third party access monitored",
      "description": "Are policies and procedures for user identification management controls defined and in place for non-consumer users and administrators on all system components, as follows:\n\nAre third party remote access accounts monitored when in use?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "vendors-monitored-and-disabled"
        }
      ]
    },
    {
      "id": "8.1.6.a",
      "name": "Failed attempt lock out",
      "description": "Are policies and procedures for user identification management controls defined and in place for non-consumer users and administrators on all system components, as follows:\n\nAre repeated access attempts limited by locking out the user ID after no more than six attempts?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-password-settings-enabled"
        }
      ]
    },
    {
      "id": "8.1.7",
      "name": "Lock out duration",
      "description": "Are policies and procedures for user identification management controls defined and in place for non-consumer users and administrators on all system components, as follows:\n\nOnce a user account is locked out, is the lockout duration set to a minimum of 30 minutes or until an administrator enables the user ID?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-password-settings-enabled"
        }
      ]
    },
    {
      "id": "8.1.8",
      "name": "Session lock out re-authentication",
      "description": "Are policies and procedures for user identification management controls defined and in place for non-consumer users and administrators on all system components, as follows:\n\nIf a session has been idle for more than 15 minutes, are users required to re-authenticate (for example, re-enter the password) to re-activate the terminal or session?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "screenlock-configured-jamf"
        },
        {
          "id": "screenlock-configured-kandji"
        },
        {
          "id": "screenlock-configured-microsoft-endpoint-manager"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "8.2",
      "name": "Authentication methods",
      "description": "In addition to assigning a unique ID, is one or more of the following methods employed to authenticate all users?\n• Something you know, such as a password or passphrase\n• Something you have, such as a token device or smart card\n• Something you are, such as a biometric",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "internal-password-policy-config"
        },
        {
          "id": "internal-password-policy-config-heroku"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "8.2.1.a",
      "name": "Strong cryptography used for authentication",
      "description": "Is strong cryptography used to render all authentication credentials (such as passwords/passphrases) unreadable during transmission and storage on all system components?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "strong-cryptography-used"
        }
      ]
    },
    {
      "id": "8.2.2",
      "name": "User identity verified",
      "description": "Is user identity verified before modifying any authentication credential (for example, performing password resets, provisioning new tokens, or generating new keys)?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "verify-user-identiy"
        }
      ]
    },
    {
      "id": "8.2.4.a",
      "name": "Passwords changed regularly",
      "description": "Are user passwords/passphrases changed at least once every 90 days?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-password-settings-enabled"
        }
      ]
    },
    {
      "id": "8.2.5.a",
      "name": "New password parameter requirements",
      "description": "Must an individual submit a new password/passphrase that is different from any of the last four passwords/passphrases he or she has used?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-password-settings-enabled"
        }
      ]
    },
    {
      "id": "8.2.6",
      "name": "Unique values for each new password",
      "description": "Are passwords/passphrases set to a unique value for each user for first-time use and upon reset, and must each user change their password immediately after the first use?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-password-settings-enabled"
        }
      ]
    },
    {
      "id": "8.3.1",
      "name": "MFA exists for non-console access",
      "description": "Is all individual non-console administrative access and all remote access to the CDE secured using multi-factor authentication as follows:\n\nNote: Multi-factor authentication requires that a minimum of two of the three authentication methods (see PCI DSS Requirement 8.2 for descriptions of authentication methods) be used for authentication. Using one factor twice (for example, using two separate passwords) is not considered multi-factor authentication.\n\nIs multi-factor authentication incorporated for all non-console access into the CDE for personnel with administrative access?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "mfa-on-accounts-gsuite"
        },
        {
          "id": "mfa-on-accounts-infra"
        },
        {
          "id": "mfa-on-accounts-infra-root"
        },
        {
          "id": "mfa-on-accounts-okta"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "8.3.2",
      "name": "MFA exists for remote network access",
      "description": "Is all individual non-console administrative access and all remote access to the CDE secured using multi-factor authentication as follows:\n\nNote: Multi-factor authentication requires that a minimum of two of the three authentication methods (see PCI DSS Requirement 8.2 for descriptions of authentication methods) be used for authentication. Using one factor twice (for example, using two separate passwords) is not considered multi-factor authentication.\n\nIs multi-factor authentication incorporated for all remote network access (both user and administrator, and including third party access for support or maintenance) originating from outside the entity’s network?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "mfa-on-accounts-gsuite"
        },
        {
          "id": "mfa-on-accounts-infra"
        },
        {
          "id": "mfa-on-accounts-infra-root"
        },
        {
          "id": "mfa-on-accounts-okta"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "8.4.a",
      "name": "Authentication policies and procedures exist",
      "description": "Are authentication policies and procedures documented and communicated to all users?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "8.4.b",
      "name": "Authentication policies and procedure requirements",
      "description": "Do authentication policies and procedures include the following? \n• Guidance on selecting strong authentication credentials\n• Guidance for how users should protect their authentication credentials\n• Instructions not to reuse previously used passwords\n• Instructions that users should change passwords if there is any suspicion the password could be compromised",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "8.5",
      "name": "Shared account methods secure",
      "description": "Are group, shared, or generic accounts, passwords, or other authentication methods prohibited as follows: \n• Generic user IDs and accounts are disabled or removed;\n• Shared user IDs for system administration activities and other critical functions do not exist; and\n• Shared and generic user IDs are not used to administer any system components?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "no-shared-generic-accounts"
        }
      ]
    },
    {
      "id": "8.6",
      "name": "Other authentication methods",
      "description": "Where other authentication mechanisms are used (for example, physical or logical security tokens, smart cards, and certificates, etc.), is the use of these mechanisms assigned as follows?\n• Authentication mechanisms must be assigned to an individual account and not shared among multiple accounts\n• Physical and/or logical controls must be in place to ensure only the intended account can use that mechanism to gain access",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "no-shared-generic-accounts"
        }
      ]
    },
    {
      "id": "8.8",
      "name": "Security policies and procedures for identification and authentication exist",
      "description": "Are security policies and operational procedures for identification and authentication:\n• Documented\n• In use\n• Known to all affected parties?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "employees-accepted-access-control-policy-bsi"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "9.1",
      "name": "Facility entry controls exist",
      "description": "Are appropriate facility entry controls in place to limit and monitor physical access to systems in the cardholder data environment?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-physical-security-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "physical-security-covered-by-aoc"
        }
      ]
    },
    {
      "id": "9.5",
      "name": "Media physically secured",
      "description": "Are all media physically secured (including but not limited to computers, removable electronic media, paper receipts, paper reports, and faxes)?\n\nFor purposes of Requirement 9, “media” refers to all paper and electronic media containing cardholder data.",
      "categories": ["Physical"],
      "tests": [
        {
          "id": "vendors-attestation-of-compliance-documents"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "physical-security-covered-by-aoc"
        }
      ]
    },
    {
      "id": "9.6.a",
      "name": "Media distribution controlled",
      "description": "Is strict control maintained over the internal or external distribution of any kind of media?",
      "categories": ["Physical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-media-handled-securely"
        }
      ]
    },
    {
      "id": "9.6.1",
      "name": "Media classified",
      "description": "Do controls include the following:\n\nIs media classified so the sensitivity of the data can be determined?",
      "categories": ["Physical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-media-handled-securely"
        }
      ]
    },
    {
      "id": "9.6.2",
      "name": "Media securely delivered",
      "description": "Do controls include the following:\n\nIs media sent by secured courier or other delivery method that can be accurately tracked?",
      "categories": ["Physical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-media-handled-securely"
        }
      ]
    },
    {
      "id": "9.6.3",
      "name": "Media distribution authorized",
      "description": "Do controls include the following:\n\nIs management approval obtained prior to moving the media (especially when media is distributed to individuals)?",
      "categories": ["Physical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-media-handled-securely"
        }
      ]
    },
    {
      "id": "9.7",
      "name": "Media storage controls exist",
      "description": "Is strict control maintained over the storage and accessibility of media?",
      "categories": ["Physical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-media-handled-securely"
        }
      ]
    },
    {
      "id": "9.8.a",
      "name": "Stored media destroyed if no longer needed",
      "description": "Is all media destroyed when it is no longer needed for business or legal reasons?",
      "categories": ["Physical"],
      "tests": [
        {
          "id": "vendors-attestation-of-compliance-documents"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pci-media-handled-securely"
        }
      ]
    },
    {
      "id": "9.8.1.a",
      "name": "Hardcopy materials securely destroyed",
      "description": "Is media destruction performed as follows:\n\nAre hardcopy materials cross-cut shredded, incinerated, or pulped so that cardholder data cannot be reconstructed?",
      "categories": ["Physical"],
      "tests": [
        {
          "id": "vendors-attestation-of-compliance-documents"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pci-media-handled-securely"
        }
      ]
    },
    {
      "id": "9.8.1.b",
      "name": "Material containers physically secured",
      "description": "Is media destruction performed as follows:\n\nAre storage containers used for materials that contain information to be destroyed secured to prevent access to the contents?",
      "categories": ["Physical"],
      "tests": [
        {
          "id": "approved-physical-security-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pci-media-handled-securely"
        }
      ]
    },
    {
      "id": "10.1.a",
      "name": "System audit trails enabled and active",
      "description": "Are audit trails enabled and active for system components?",
      "categories": ["Physical"],
      "tests": [
        {
          "id": "flow-logs-on-azure"
        },
        {
          "id": "flow-logs-on-config-gcp"
        },
        {
          "id": "logs-centrally-stored-config"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "10.1.b",
      "name": "System access linked to individual users",
      "description": "Is access to system components linked to individual users?",
      "categories": ["Physical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-logging-configured-enabled"
        }
      ]
    },
    {
      "id": "10.2.2",
      "name": "Admin actions reconstructable",
      "description": "Are automated audit trails implemented for all system components to reconstruct the following events:\n\nAll actions taken by any individual with root or administrative privileges?",
      "categories": ["Physical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-logging-configured-enabled"
        }
      ]
    },
    {
      "id": "10.2.3",
      "name": "Audit trail access reconstructable",
      "description": "Are automated audit trails implemented for all system components to reconstruct the following events:\n\nAccess to all audit trails?",
      "categories": ["Physical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-logging-configured-enabled"
        }
      ]
    },
    {
      "id": "10.2.4",
      "name": "Invalid logical access attempts reconstructable",
      "description": "Are automated audit trails implemented for all system components to reconstruct the following events:\n\nInvalid logical access attempts?",
      "categories": ["Physical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-logging-configured-enabled"
        }
      ]
    },
    {
      "id": "10.2.5",
      "name": "Authentication changes reconstructable",
      "description": "Are automated audit trails implemented for all system components to reconstruct the following events:\n\nUse of and changes to identification and authentication mechanisms–including but not limited to creation of new accounts and elevation of privileges – and all changes, additions, or deletions to accounts with root or administrative privileges?",
      "categories": ["Physical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-logging-configured-enabled"
        }
      ]
    },
    {
      "id": "10.2.6",
      "name": "Audit log changes reconstructable",
      "description": "Are automated audit trails implemented for all system components to reconstruct the following events:\n\nInitialization, stopping, or pausing of the audit logs?",
      "categories": ["Physical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-logging-configured-enabled"
        }
      ]
    },
    {
      "id": "10.2.7",
      "name": "System level object changes reconstructable",
      "description": "Are automated audit trails implemented for all system components to reconstruct the following events:\n\nCreation and deletion of system-level objects?",
      "categories": ["Physical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-logging-configured-enabled"
        }
      ]
    },
    {
      "id": "10.3.1",
      "name": "User identification event recorded",
      "description": "Are the following audit trail entries recorded for all system components for each event:\n\nUser identification?",
      "categories": ["Physical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-logging-configured-enabled"
        }
      ]
    },
    {
      "id": "10.3.2",
      "name": "Event type recorded",
      "description": "Are the following audit trail entries recorded for all system components for each event:\n\nType of event?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-logging-configured-enabled"
        },
        {
          "id": "system-activity-log-settings"
        }
      ]
    },
    {
      "id": "10.3.3",
      "name": "Date and type recorded",
      "description": "Are the following audit trail entries recorded for all system components for each event:\n\nDate and time?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-logging-configured-enabled"
        }
      ]
    },
    {
      "id": "10.3.4",
      "name": "Success or failure recorded",
      "description": "Are the following audit trail entries recorded for all system components for each event:\n\nSuccess or failure indication?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-logging-configured-enabled"
        }
      ]
    },
    {
      "id": "10.3.5",
      "name": "Event origination recorded",
      "description": "Are the following audit trail entries recorded for all system components for each event:\n\nOrigination of event?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-logging-configured-enabled"
        }
      ]
    },
    {
      "id": "10.3.6",
      "name": "Affected data or resource name recorded",
      "description": "Are the following audit trail entries recorded for all system components for each event:\n\nIdentity or name of affected data, system component, or resource?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-logging-configured-enabled"
        }
      ]
    },
    {
      "id": "10.4",
      "name": "Time synchronization technology exists",
      "description": "Are all critical system clocks and times synchronized through use of time synchronization technology, and is the technology kept current?  \n\nNote: One example of time synchronization technology is Network Time Protocol (NTP).",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "infrastructure-timesync-config"
        },
        {
          "id": "infrastructure-timesync-config-heroku"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "10.4.1.a",
      "name": "External time signal receipt restricted",
      "description": "Are the following processes implemented for critical systems to have the correct and consistent time:\n\nDo only designated central time server(s) receive time signals from external sources, and are time signals from external sources based on International Atomic Time or UTC?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "infrastructure-timesync-config"
        },
        {
          "id": "infrastructure-timesync-config-heroku"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "10.4.1.b",
      "name": "Multiple designated time servers synchornized",
      "description": "Are the following processes implemented for critical systems to have the correct and consistent time:\n\nWhere there is more than one designated time server, do the time servers peer with each other to keep accurate time?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "infrastructure-timesync-config"
        },
        {
          "id": "infrastructure-timesync-config-heroku"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "10.4.1.c",
      "name": "Time received only from designated central time server(s)",
      "description": "Are the following processes implemented for critical systems to have the correct and consistent time:\n\nDo systems receive time only from designated central time server(s)?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "infrastructure-timesync-config"
        },
        {
          "id": "infrastructure-timesync-config-heroku"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "10.4.2.a",
      "name": "Time data access restricted",
      "description": "Is time data is protected as follows:\nIs access to time data restricted to only personnel with a business need to access time data?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "infrastructure-timesync-config"
        },
        {
          "id": "infrastructure-timesync-config-heroku"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "10.4.2.b",
      "name": "Changes to time settings logged",
      "description": "Are changes to time settings on critical systems logged, monitored, and reviewed?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "infrastructure-timesync-config"
        },
        {
          "id": "infrastructure-timesync-config-heroku"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pci-logging-configured-enabled"
        }
      ]
    },
    {
      "id": "10.4.3",
      "name": "Time settings based on industry-accepted sources",
      "description": "Are time settings received from specific, industry-accepted time sources? (This is to prevent a malicious individual from changing the clock). \n\nOptionally, those updates can be encrypted with a symmetric key, and access control lists can be created that specify the IP addresses of client machines that will be provided with the time updates (to prevent unauthorized use of internal time servers).",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "infrastructure-timesync-config"
        },
        {
          "id": "infrastructure-timesync-config-heroku"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "10.5.1",
      "name": "Audit trail acces restricted",
      "description": "Are audit trails secured so they cannot be altered, as follows:\n\nIs viewing of audit trails limited to those with a job-related need?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "access-requests-denied-log"
        }
      ]
    },
    {
      "id": "10.5.2",
      "name": "Audit trail protected from unauthorized modifications",
      "description": "Are audit trails secured so they cannot be altered, as follows:\n\nAre audit trail files protected from unauthorized modifications via access control mechanisms, physical segregation, and/or network segregation?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "logs-centrally-stored-authorization"
        },
        {
          "id": "logs-centrally-stored-authorization-gcp"
        },
        {
          "id": "logs-centrally-stored-config-gcp"
        },
        {
          "id": "logs-sink-destinations-authorization-gcp"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "10.5.3",
      "name": "Audit trail files backed up",
      "description": "Are audit trails secured so they cannot be altered, as follows:\n\nAre audit trail files promptly backed up to a centralized log server or media that is difficult to alter?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "logs-centrally-stored-authorization"
        },
        {
          "id": "logs-centrally-stored-authorization-gcp"
        },
        {
          "id": "logs-centrally-stored-config-gcp"
        },
        {
          "id": "logs-sink-destinations-tracked-gcp"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "10.5.4",
      "name": "Logs exist on secure servers",
      "description": "Are audit trails secured so they cannot be altered, as follows:\n\nAre logs for external-facing technologies (for example, wireless, firewalls, DNS, mail) written onto a secure, centralized, internal log server or media?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "externally-facing-systems-logged"
        }
      ]
    },
    {
      "id": "10.5.5",
      "name": "Log modifications create alerts",
      "description": "Are audit trails secured so they cannot be altered, as follows:\n\nIs file-integrity monitoring or change-detection software used on logs to ensure that existing log data cannot be changed without generating alerts (although new data being added should not cause an alert)?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "file-integrity-monitoring-cde"
        }
      ]
    },
    {
      "id": "10.6.1.b",
      "name": "System logs reviewed regularly",
      "description": "Are logs and security events for all system components reviewed to identify anomalies or suspicious activity as follows?\n\nNote: Log harvesting, parsing, and alerting tools may be used to achieve compliance with Requirement 10.6.\n\nAre the above logs and security events reviewed at least daily?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-logs-reviewed-daily"
        }
      ]
    },
    {
      "id": "10.6.2.b",
      "name": "System component reviews performed",
      "description": "Are logs and security events for all system components reviewed to identify anomalies or suspicious activity as follows?\n\nNote: Log harvesting, parsing, and alerting tools may be used to achieve compliance with Requirement 10.6.\n\nAre reviews of all other system components performed in accordance with organization’s policies and risk management strategy?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-operations-security-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "10.6.3.b",
      "name": "Anomalies and exceptions regularly addressed",
      "description": "Are logs and security events for all system components reviewed to identify anomalies or suspicious activity as follows?\n\nNote: Log harvesting, parsing, and alerting tools may be used to achieve compliance with Requirement 10.6.\n\nIs follow up to exceptions and anomalies performed?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-logs-reviewed-daily"
        }
      ]
    },
    {
      "id": "10.7.b",
      "name": "Audit logs retained for one year",
      "description": "Are audit logs retained for at least one year?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "logs-retained-for-twelve-months-config"
        },
        {
          "id": "logs-retained-for-twelve-months-config-gcp"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "10.7.c",
      "name": "Immediately available audit logs cover 3 months",
      "description": "Are at least the last three months’ logs immediately available for analysis?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "logs-online-three-months"
        }
      ]
    },
    {
      "id": "11.2.2.a",
      "name": "Quarterly external vulnerability scans performed",
      "description": "Are quarterly external vulnerability scans performed?\n\nNote: Quarterly external vulnerability scans must be performed by an Approved Scanning Vendor (ASV), approved by the Payment Card Industry Security Standards Council (PCI SSC). \nRefer to the ASV Program Guide published on the PCI SSC website for scan customer responsibilities, scan preparation, etc.",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "ASV-external-vulnerability-scan"
        }
      ]
    },
    {
      "id": "11.2.2.b",
      "name": "Quarterly external vulnerabilities scans performed within ASV guidelines",
      "description": "Do external quarterly scan and rescan results satisfy the ASV Program Guide requirements for a passing scan (for example, no vulnerabilities rated 4.0 or higher by the CVSS, and no automatic failures)?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "ASV-external-vulnerability-scan"
        }
      ]
    },
    {
      "id": "11.2.2.c",
      "name": "Quarterly external vulnerability scans performed by Approved Scanning Vendor (ASV)",
      "description": "Are quarterly external vulnerability scans performed by a PCI SSC Approved Scanning Vendor (ASV)?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "ASV-external-vulnerability-scan"
        }
      ]
    },
    {
      "id": "11.2.3.a",
      "name": "All scans re-performed after significant changes",
      "description": "Are internal and external scans, and rescans as needed, performed after any significant change?\n\nNote: Scans must be performed by qualified personnel.",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "ASV-external-vulnerability-scan"
        }
      ]
    },
    {
      "id": "11.2.3.b",
      "name": "Rescan process",
      "description": "Does the scan process include rescans until: \n• For external scans, no vulnerabilities exist that are scored 4.0 or higher by the CVSS,\n• For internal scans, a passing result is obtained or all “high-risk” vulnerabilities as defined in PCI DSS Requirement 6.1 are resolved?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "ASV-external-vulnerability-scan"
        }
      ]
    },
    {
      "id": "11.2.3.c",
      "name": "Scans performed by qualified parties",
      "description": "Are scans performed by a qualified internal resource(s) or qualified external third party, and if applicable, does organizational independence of the tester exist (not required to be a QSA or ASV)?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "penetration-tests-records"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "11.3",
      "name": "Penetration testing methodology requirements",
      "description": "Does the penetration-testing methodology include the following? \n• Is based on industry-accepted penetration testing approaches (for example, NIST SP800-115)\n• Includes coverage for the entire CDE perimeter and critical systems\n• Includes testing from both inside and outside the network\n• Includes testing to validate any segmentation and scope-reduction controls\n• Defines application-layer penetration tests to include, at a minimum, the vulnerabilities listed in Requirement 6.5\n• Defines network-layer penetration tests to include components that support network functions as well as operating systems\n• Includes review and consideration of threats and vulnerabilities experienced in the last 12 months\n• Specifies retention of penetration testing results and remediation activities results",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "penetration-tests-records"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "11.3.1.a",
      "name": "External penetration test requirements",
      "description": "Is external penetration testing performed per the defined methodology, at least annually, and after any significant infrastructure or application changes to the environment (such as an operating system upgrade, a sub-network added to the environment, or an added web server)?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "penetration-tests-records"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "11.3.1.b",
      "name": "Penetration tests performed by qualified party",
      "description": "Are tests performed by a qualified internal resource or qualified external third party, and if applicable, does organizational independence of the tester exist (not required to be a QSA or ASV)?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "penetration-tests-records"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "11.3.3",
      "name": "Found vulnerabilities corrected and fix verified",
      "description": "Are exploitable vulnerabilities found during penetration testing corrected, followed by repeated testing to verify the corrections?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "penetration-tests-records"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pen-test-remediation"
        }
      ]
    },
    {
      "id": "11.3.4.a",
      "name": "Penetration testing covers all segmentation methods",
      "description": "If segmentation is used to isolate the CDE from other networks:\n\nAre penetration-testing procedures defined to test all segmentation methods, to confirm they are operational and effective, and isolate all out-of-scope systems from systems in the CDE?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "penetration-tests-records"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "11.3.4.b",
      "name": "Penetration testing verifies segmentation controls",
      "description": "If segmentation is used to isolate the CDE from other networks:\n\nDoes penetration testing to verify segmentation controls meet the following?\n• Performed at least annually and after any changes to segmentation controls/methods\n• Covers all segmentation controls/methods in use\n• Verifies that segmentation methods are operational and effective, and isolate all out-of-scope systems from systems in the CDE.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "penetration-tests-records"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "11.3.4.c",
      "name": "Penetration tests performed by qualified party",
      "description": "If segmentation is used to isolate the CDE from other networks:\n\nAre tests performed by a qualified internal resource or qualified external third party, and if applicable, does organizational independence of the tester exist (not required to be a QSA or ASV)?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "penetration-tests-records"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "11.4.a",
      "name": "Intrusion detection or prevention exists",
      "description": "Are intrusion-detection and/or intrusion-prevention techniques that detect and/or prevent intrusions into the network in place to monitor all traffic: \n• At the perimeter of the cardholder data environment, and\n• At critical points in the cardholder data environment.",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "ids-ips-enabled-in-cde"
        }
      ]
    },
    {
      "id": "11.4.b",
      "name": "Intrusion alerts personnel",
      "description": "Are intrusion-detection and/or intrusion-prevention techniques configured to alert personnel of suspected compromises?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "ids-ips-alerts-enabled"
        }
      ]
    },
    {
      "id": "11.4.c",
      "name": "Intrusion detection configs up-to-date",
      "description": "Are all intrusion-detection and prevention engines, baselines, and signatures kept up-to-date?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "ids-ips-enabled-in-cde"
        }
      ]
    },
    {
      "id": "11.5.a",
      "name": "Change detection methods detect unauthorized acts",
      "description": "Is a change-detection mechanism (for example, file-integrity monitoring tools) deployed to detect unauthorized modification (including changes, additions, and deletions) of critical system files, configuration files, or content files?\n\nExamples of files that should be monitored include:\n• System executables\n• Application executables\n• Configuration and parameter files\n• Centrally stored, historical or archived, log, and audit files \n• Additional critical files determined by entity (for example, through risk assessment or other means)",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "file-integrity-monitoring-cde"
        }
      ]
    },
    {
      "id": "11.5.b",
      "name": "Change detection methods alert personnel",
      "description": "Is the change-detection mechanism configured to alert personnel to unauthorized modification (including changes, additions, and deletions) of critical system files, configuration files or content files, and do the tools perform critical file comparisons at least weekly?\n\nNote: For change detection purposes, critical files are usually those that do not regularly change, but the modification of which could indicate a system compromise or risk of compromise. Change detection mechanisms such as file-integrity monitoring products usually come pre-configured with critical files for the related operating system. Other critical files, such as those for custom applications, must be evaluated and defined by the entity (that is the merchant or service provider).",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "file-integrity-monitoring-cde"
        }
      ]
    },
    {
      "id": "11.5.1",
      "name": "Response plan exists for change detection alerts",
      "description": "Is a process in place to respond to any alerts generated by the change-detection solution?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "file-integrity-monitoring-cde"
        }
      ]
    },
    {
      "id": "12.1",
      "name": "Information security policy exists",
      "description": "Is a security policy established, published, maintained, and disseminated to all relevant personnel?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-information-security-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "12.1.1",
      "name": "Information security policy reviewed annually",
      "description": "Is the security policy reviewed at least annually and updated when the environment changes?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-information-security-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "12.4",
      "name": "Personnel's information security responsibilities defined",
      "description": "Do security policy and procedures clearly define information security responsibilities for all personnel?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-information-security-roles-and-responsibilities-bsi-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "12.5.3",
      "name": "Responsibility for creating escalating procedures",
      "description": "Are the following information security management responsibilities formally assigned to an individual or team:\n\nEstablishing, documenting, and distributing security incident response and escalation procedures to ensure timely and effective handling of all situations?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "incident-response-plan-policies"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "12.6.a",
      "name": "Cardholder data handling security training exists",
      "description": "Is a formal security awareness program in place to make all personnel aware of the cardholder data security policy and procedures?",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-human-resource-security-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "12.8.1",
      "name": "List of service providers",
      "description": "Are policies and procedures maintained and implemented to manage service providers with whom cardholder data is shared, or that could affect the security of cardholder data, as follows:\n\nIs a list of service providers maintained, including a description of the service(s) provided?",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-service-provider-inventory-exists"
        }
      ]
    },
    {
      "id": "12.8.2",
      "name": "Service providers' responsibility acknowledgement",
      "description": "Are policies and procedures maintained and implemented to manage service providers with whom cardholder data is shared, or that could affect the security of cardholder data, as follows:\n\nIs a written agreement maintained that includes an acknowledgement that the service providers are responsible for the security of cardholder data the service providers possess or otherwise store, process, or transmit on behalf of the customer, or to the extent that they could impact the security of the customer’s cardholder data environment?\n\nNote: The exact wording of an acknowledgement will depend on the agreement between the two parties, the details of the service being provided, and the responsibilities assigned to each party. The acknowledgement does not have to include the exact wording provided in this requirement.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "vendors-attestation-of-compliance-documents"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "12.8.3",
      "name": "Service provider engagement process exists",
      "description": "Are policies and procedures maintained and implemented to manage service providers with whom cardholder data is shared, or that could affect the security of cardholder data, as follows:\n\nIs there an established process for engaging service providers, including proper due diligence prior to engagement?",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-service-provider-inventory-exists"
        }
      ]
    },
    {
      "id": "12.8.4",
      "name": "Program exists to monitor service provider's PCI compliance status",
      "description": "Are policies and procedures maintained and implemented to manage service providers with whom cardholder data is shared, or that could affect the security of cardholder data, as follows:\n\nIs a program maintained to monitor service providers’ PCI DSS compliance status at least annually?",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-service-provider-inventory-exists"
        }
      ]
    },
    {
      "id": "12.8.5",
      "name": "Separation of responsibilities",
      "description": "Are policies and procedures maintained and implemented to manage service providers with whom cardholder data is shared, or that could affect the security of cardholder data, as follows:\n\nIs information maintained about which PCI DSS requirements are managed by each service provider, and which are managed by the entity?",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-service-provider-inventory-exists"
        }
      ]
    },
    {
      "id": "12.10.1.a",
      "name": "Incident response plan exists",
      "description": "Has an incident response plan been created to be implemented in the event of system breach?",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "incident-response-plan-policies"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "12.10.1.b.i",
      "name": "Roles, responsibilities, and communication",
      "description": "Does the plan address the following, at a minimum:\n\nRoles, responsibilities, and communication and contact strategies in the event of a compromise including notification of the payment brands, at a minimum?",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "12.10.1.b.ii",
      "name": "Specific incident response procedures",
      "description": "Does the plan address the following, at a minimum:\n\nSpecific incident response procedures?",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "incident-response-plan-policies"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "12.10.1.b.iii",
      "name": "Business recovery and continuity procedures",
      "description": "Does the plan address the following, at a minimum:\n\nBusiness recovery and continuity procedures?",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "business-continuity-plan-policy"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "12.10.1.b.iv",
      "name": "Data backup processes",
      "description": "Does the plan address the following, at a minimum:\n\nData backup processes?",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-business-continuity-and-disaster-recovery-plan-bsi-exists"
        },
        {
          "id": "approved-operations-security-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "12.10.1.b.v",
      "name": "Legal requirements for reporting compromises",
      "description": "Does the plan address the following, at a minimum:\n\nAnalysis of legal requirements for reporting compromises?",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "incident-response-plan-policies"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "12.10.1.b.vi",
      "name": "Coverage and responses of all critical system components",
      "description": "Does the plan address the following, at a minimum:\n\nCoverage and responses of all critical system components?",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "incident-response-plan-policies"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "12.10.1.b.vii",
      "name": "Payment brands' incident response",
      "description": "Does the plan address the following, at a minimum:\n\nReference or inclusion of incident response procedures from the payment brands?",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "vendors-attestation-of-compliance-documents"
        }
      ],
      "manualEvidenceRequests": []
    }
  ],
  "sections": [
    {
      "id": "1.1.1",
      "controls": [
        {
          "id": "1.1.1"
        }
      ]
    },
    {
      "id": "1.1.2.a",
      "controls": [
        {
          "id": "1.1.2.a"
        }
      ]
    },
    {
      "id": "1.1.2.b",
      "controls": [
        {
          "id": "1.1.2.b"
        }
      ]
    },
    {
      "id": "1.1.3.a",
      "controls": [
        {
          "id": "1.1.3.a"
        }
      ]
    },
    {
      "id": "1.1.3.b",
      "controls": [
        {
          "id": "1.1.3.b"
        }
      ]
    },
    {
      "id": "1.1.4.a",
      "controls": [
        {
          "id": "1.1.4.a"
        }
      ]
    },
    {
      "id": "1.1.4.b",
      "controls": [
        {
          "id": "1.1.4.b"
        }
      ]
    },
    {
      "id": "1.1.6.a",
      "controls": [
        {
          "id": "1.1.6.a"
        }
      ]
    },
    {
      "id": "1.1.6.b",
      "controls": [
        {
          "id": "1.1.6.b"
        }
      ]
    },
    {
      "id": "1.1.7.a",
      "controls": [
        {
          "id": "1.1.7.a"
        }
      ]
    },
    {
      "id": "1.1.7.b",
      "controls": [
        {
          "id": "1.1.7.b"
        }
      ]
    },
    {
      "id": "1.2.1.a",
      "controls": [
        {
          "id": "1.2.1.a"
        }
      ]
    },
    {
      "id": "1.2.1.b",
      "controls": [
        {
          "id": "1.2.1.b"
        }
      ]
    },
    {
      "id": "1.2.2",
      "controls": [
        {
          "id": "1.2.2"
        }
      ]
    },
    {
      "id": "1.2.3",
      "controls": [
        {
          "id": "1.2.3"
        }
      ]
    },
    {
      "id": "1.3.1",
      "controls": [
        {
          "id": "1.3.1"
        }
      ]
    },
    {
      "id": "1.3.2",
      "controls": [
        {
          "id": "1.3.2"
        }
      ]
    },
    {
      "id": "1.3.3",
      "controls": [
        {
          "id": "1.3.3"
        }
      ]
    },
    {
      "id": "1.3.4",
      "controls": [
        {
          "id": "1.3.4"
        }
      ]
    },
    {
      "id": "1.3.5",
      "controls": [
        {
          "id": "1.3.5"
        }
      ]
    },
    {
      "id": "1.3.7.a",
      "controls": [
        {
          "id": "1.3.7.a"
        }
      ]
    },
    {
      "id": "1.3.7.b",
      "controls": [
        {
          "id": "1.3.7.b"
        }
      ]
    },
    {
      "id": "1.4.a",
      "controls": [
        {
          "id": "1.4.a"
        }
      ]
    },
    {
      "id": "1.4.b",
      "controls": [
        {
          "id": "1.4.b"
        }
      ]
    },
    {
      "id": "1.5",
      "controls": [
        {
          "id": "1.5"
        }
      ]
    },
    {
      "id": "2.1.a",
      "controls": [
        {
          "id": "2.1.a"
        }
      ]
    },
    {
      "id": "2.1.b",
      "controls": [
        {
          "id": "2.1.b"
        }
      ]
    },
    {
      "id": "2.2.a",
      "controls": [
        {
          "id": "2.2.a"
        }
      ]
    },
    {
      "id": "2.2.b",
      "controls": [
        {
          "id": "2.2.b"
        }
      ]
    },
    {
      "id": "2.2.c",
      "controls": [
        {
          "id": "2.2.c"
        }
      ]
    },
    {
      "id": "2.2.d",
      "controls": [
        {
          "id": "2.2.d"
        }
      ]
    },
    {
      "id": "2.2.1.a",
      "controls": [
        {
          "id": "2.2.1.a"
        }
      ]
    },
    {
      "id": "2.2.1.b",
      "controls": [
        {
          "id": "2.2.1.b"
        }
      ]
    },
    {
      "id": "2.2.2.b",
      "controls": [
        {
          "id": "2.2.2.b"
        }
      ]
    },
    {
      "id": "2.2.3",
      "controls": [
        {
          "id": "2.2.3"
        }
      ]
    },
    {
      "id": "2.2.4.a",
      "controls": [
        {
          "id": "2.2.4.a"
        }
      ]
    },
    {
      "id": "2.2.4.b",
      "controls": [
        {
          "id": "2.2.4.b"
        }
      ]
    },
    {
      "id": "2.2.4.c",
      "controls": [
        {
          "id": "2.2.4.c"
        }
      ]
    },
    {
      "id": "2.2.5.a",
      "controls": [
        {
          "id": "2.2.5.a"
        }
      ]
    },
    {
      "id": "2.2.5.b",
      "controls": [
        {
          "id": "2.2.5.b"
        }
      ]
    },
    {
      "id": "2.2.5.c",
      "controls": [
        {
          "id": "2.2.5.c"
        }
      ]
    },
    {
      "id": "2.3.a",
      "controls": [
        {
          "id": "2.3.a"
        }
      ]
    },
    {
      "id": "2.3.b",
      "controls": [
        {
          "id": "2.3.b"
        }
      ]
    },
    {
      "id": "2.3.c",
      "controls": [
        {
          "id": "2.3.c"
        }
      ]
    },
    {
      "id": "2.3.d",
      "controls": [
        {
          "id": "2.3.d"
        }
      ]
    },
    {
      "id": "3.2.2",
      "controls": [
        {
          "id": "3.2.2"
        }
      ]
    },
    {
      "id": "3.2.3",
      "controls": [
        {
          "id": "3.2.3"
        }
      ]
    },
    {
      "id": "3.2.c",
      "controls": [
        {
          "id": "3.2.c"
        }
      ]
    },
    {
      "id": "4.1.a",
      "controls": [
        {
          "id": "4.1.a"
        }
      ]
    },
    {
      "id": "4.1.b",
      "controls": [
        {
          "id": "4.1.b"
        }
      ]
    },
    {
      "id": "4.1.c",
      "controls": [
        {
          "id": "4.1.c"
        }
      ]
    },
    {
      "id": "4.1.d",
      "controls": [
        {
          "id": "4.1.d"
        }
      ]
    },
    {
      "id": "4.1.e",
      "controls": [
        {
          "id": "4.1.e"
        }
      ]
    },
    {
      "id": "4.2.b",
      "controls": [
        {
          "id": "4.2.b"
        }
      ]
    },
    {
      "id": "4.3",
      "controls": [
        {
          "id": "4.3"
        }
      ]
    },
    {
      "id": "5.1",
      "controls": [
        {
          "id": "5.1"
        }
      ]
    },
    {
      "id": "5.1.1",
      "controls": [
        {
          "id": "5.1.1"
        }
      ]
    },
    {
      "id": "5.1.2",
      "controls": [
        {
          "id": "5.1.2"
        }
      ]
    },
    {
      "id": "5.2.a",
      "controls": [
        {
          "id": "5.2.a"
        }
      ]
    },
    {
      "id": "5.2.b",
      "controls": [
        {
          "id": "5.2.b"
        }
      ]
    },
    {
      "id": "5.2.c",
      "controls": [
        {
          "id": "5.2.c"
        }
      ]
    },
    {
      "id": "5.3",
      "controls": [
        {
          "id": "5.3"
        }
      ]
    },
    {
      "id": "5.4",
      "controls": [
        {
          "id": "5.4"
        }
      ]
    },
    {
      "id": "6.1",
      "controls": [
        {
          "id": "6.1"
        }
      ]
    },
    {
      "id": "6.2.a",
      "controls": [
        {
          "id": "6.2.a"
        }
      ]
    },
    {
      "id": "6.2.b",
      "controls": [
        {
          "id": "6.2.b"
        }
      ]
    },
    {
      "id": "6.4.5.1",
      "controls": [
        {
          "id": "6.4.5.1"
        }
      ]
    },
    {
      "id": "6.4.5.2",
      "controls": [
        {
          "id": "6.4.5.2"
        }
      ]
    },
    {
      "id": "6.4.5.3.a",
      "controls": [
        {
          "id": "6.4.5.3.a"
        }
      ]
    },
    {
      "id": "6.4.5.3.b",
      "controls": [
        {
          "id": "6.4.5.3.b"
        }
      ]
    },
    {
      "id": "6.4.5.4",
      "controls": [
        {
          "id": "6.4.5.4"
        }
      ]
    },
    {
      "id": "6.4.5.a",
      "controls": [
        {
          "id": "6.4.5.a"
        }
      ]
    },
    {
      "id": "6.4.6",
      "controls": [
        {
          "id": "6.4.6"
        }
      ]
    },
    {
      "id": "6.5.1",
      "controls": [
        {
          "id": "6.5.1"
        }
      ]
    },
    {
      "id": "6.5.2",
      "controls": [
        {
          "id": "6.5.2"
        }
      ]
    },
    {
      "id": "6.5.4",
      "controls": [
        {
          "id": "6.5.4"
        }
      ]
    },
    {
      "id": "6.5.5",
      "controls": [
        {
          "id": "6.5.5"
        }
      ]
    },
    {
      "id": "6.5.6",
      "controls": [
        {
          "id": "6.5.6"
        }
      ]
    },
    {
      "id": "6.5.7",
      "controls": [
        {
          "id": "6.5.7"
        }
      ]
    },
    {
      "id": "6.5.8",
      "controls": [
        {
          "id": "6.5.8"
        }
      ]
    },
    {
      "id": "6.5.9",
      "controls": [
        {
          "id": "6.5.9"
        }
      ]
    },
    {
      "id": "6.5.10",
      "controls": [
        {
          "id": "6.5.10"
        }
      ]
    },
    {
      "id": "6.5.a",
      "controls": [
        {
          "id": "6.5.a"
        }
      ]
    },
    {
      "id": "6.5.b",
      "controls": [
        {
          "id": "6.5.b"
        }
      ]
    },
    {
      "id": "6.6",
      "controls": [
        {
          "id": "6.6"
        }
      ]
    },
    {
      "id": "6.7",
      "controls": [
        {
          "id": "6.7"
        }
      ]
    },
    {
      "id": "7.1",
      "controls": [
        {
          "id": "7.1"
        }
      ]
    },
    {
      "id": "7.1.2",
      "controls": [
        {
          "id": "7.1.2"
        }
      ]
    },
    {
      "id": "7.1.3",
      "controls": [
        {
          "id": "7.1.3"
        }
      ]
    },
    {
      "id": "7.1.4",
      "controls": [
        {
          "id": "7.1.4"
        }
      ]
    },
    {
      "id": "8.1.1",
      "controls": [
        {
          "id": "8.1.1"
        }
      ]
    },
    {
      "id": "8.1.2",
      "controls": [
        {
          "id": "8.1.2"
        }
      ]
    },
    {
      "id": "8.1.3",
      "controls": [
        {
          "id": "8.1.3"
        }
      ]
    },
    {
      "id": "8.1.4",
      "controls": [
        {
          "id": "8.1.4"
        }
      ]
    },
    {
      "id": "8.1.5.a",
      "controls": [
        {
          "id": "8.1.5.a"
        }
      ]
    },
    {
      "id": "8.1.5.b",
      "controls": [
        {
          "id": "8.1.5.b"
        }
      ]
    },
    {
      "id": "8.1.6.a",
      "controls": [
        {
          "id": "8.1.6.a"
        }
      ]
    },
    {
      "id": "8.1.7",
      "controls": [
        {
          "id": "8.1.7"
        }
      ]
    },
    {
      "id": "8.1.8",
      "controls": [
        {
          "id": "8.1.8"
        }
      ]
    },
    {
      "id": "8.2",
      "controls": [
        {
          "id": "8.2"
        }
      ]
    },
    {
      "id": "8.2.1.a",
      "controls": [
        {
          "id": "8.2.1.a"
        }
      ]
    },
    {
      "id": "8.2.2",
      "controls": [
        {
          "id": "8.2.2"
        }
      ]
    },
    {
      "id": "8.2.4.a",
      "controls": [
        {
          "id": "8.2.4.a"
        }
      ]
    },
    {
      "id": "8.2.5.a",
      "controls": [
        {
          "id": "8.2.5.a"
        }
      ]
    },
    {
      "id": "8.2.6",
      "controls": [
        {
          "id": "8.2.6"
        }
      ]
    },
    {
      "id": "8.3.1",
      "controls": [
        {
          "id": "8.3.1"
        }
      ]
    },
    {
      "id": "8.3.2",
      "controls": [
        {
          "id": "8.3.2"
        }
      ]
    },
    {
      "id": "8.4.a",
      "controls": [
        {
          "id": "8.4.a"
        }
      ]
    },
    {
      "id": "8.4.b",
      "controls": [
        {
          "id": "8.4.b"
        }
      ]
    },
    {
      "id": "8.5",
      "controls": [
        {
          "id": "8.5"
        }
      ]
    },
    {
      "id": "8.6",
      "controls": [
        {
          "id": "8.6"
        }
      ]
    },
    {
      "id": "8.8",
      "controls": [
        {
          "id": "8.8"
        }
      ]
    },
    {
      "id": "9.1",
      "controls": [
        {
          "id": "9.1"
        }
      ]
    },
    {
      "id": "9.5",
      "controls": [
        {
          "id": "9.5"
        }
      ]
    },
    {
      "id": "9.6.1",
      "controls": [
        {
          "id": "9.6.1"
        }
      ]
    },
    {
      "id": "9.6.2",
      "controls": [
        {
          "id": "9.6.2"
        }
      ]
    },
    {
      "id": "9.6.3",
      "controls": [
        {
          "id": "9.6.3"
        }
      ]
    },
    {
      "id": "9.6.a",
      "controls": [
        {
          "id": "9.6.a"
        }
      ]
    },
    {
      "id": "9.7",
      "controls": [
        {
          "id": "9.7"
        }
      ]
    },
    {
      "id": "9.8.1.a",
      "controls": [
        {
          "id": "9.8.1.a"
        }
      ]
    },
    {
      "id": "9.8.1.b",
      "controls": [
        {
          "id": "9.8.1.b"
        }
      ]
    },
    {
      "id": "9.8.a",
      "controls": [
        {
          "id": "9.8.a"
        }
      ]
    },
    {
      "id": "10.1.a",
      "controls": [
        {
          "id": "10.1.a"
        }
      ]
    },
    {
      "id": "10.1.b",
      "controls": [
        {
          "id": "10.1.b"
        }
      ]
    },
    {
      "id": "10.2.2",
      "controls": [
        {
          "id": "10.2.2"
        }
      ]
    },
    {
      "id": "10.2.3",
      "controls": [
        {
          "id": "10.2.3"
        }
      ]
    },
    {
      "id": "10.2.4",
      "controls": [
        {
          "id": "10.2.4"
        }
      ]
    },
    {
      "id": "10.2.5",
      "controls": [
        {
          "id": "10.2.5"
        }
      ]
    },
    {
      "id": "10.2.6",
      "controls": [
        {
          "id": "10.2.6"
        }
      ]
    },
    {
      "id": "10.2.7",
      "controls": [
        {
          "id": "10.2.7"
        }
      ]
    },
    {
      "id": "10.3.1",
      "controls": [
        {
          "id": "10.3.1"
        }
      ]
    },
    {
      "id": "10.3.2",
      "controls": [
        {
          "id": "10.3.2"
        }
      ]
    },
    {
      "id": "10.3.3",
      "controls": [
        {
          "id": "10.3.3"
        }
      ]
    },
    {
      "id": "10.3.4",
      "controls": [
        {
          "id": "10.3.4"
        }
      ]
    },
    {
      "id": "10.3.5",
      "controls": [
        {
          "id": "10.3.5"
        }
      ]
    },
    {
      "id": "10.3.6",
      "controls": [
        {
          "id": "10.3.6"
        }
      ]
    },
    {
      "id": "10.4",
      "controls": [
        {
          "id": "10.4"
        }
      ]
    },
    {
      "id": "10.4.1.a",
      "controls": [
        {
          "id": "10.4.1.a"
        }
      ]
    },
    {
      "id": "10.4.1.b",
      "controls": [
        {
          "id": "10.4.1.b"
        }
      ]
    },
    {
      "id": "10.4.1.c",
      "controls": [
        {
          "id": "10.4.1.c"
        }
      ]
    },
    {
      "id": "10.4.2.a",
      "controls": [
        {
          "id": "10.4.2.a"
        }
      ]
    },
    {
      "id": "10.4.2.b",
      "controls": [
        {
          "id": "10.4.2.b"
        }
      ]
    },
    {
      "id": "10.4.3",
      "controls": [
        {
          "id": "10.4.3"
        }
      ]
    },
    {
      "id": "10.5.1",
      "controls": [
        {
          "id": "10.5.1"
        }
      ]
    },
    {
      "id": "10.5.2",
      "controls": [
        {
          "id": "10.5.2"
        }
      ]
    },
    {
      "id": "10.5.3",
      "controls": [
        {
          "id": "10.5.3"
        }
      ]
    },
    {
      "id": "10.5.4",
      "controls": [
        {
          "id": "10.5.4"
        }
      ]
    },
    {
      "id": "10.5.5",
      "controls": [
        {
          "id": "10.5.5"
        }
      ]
    },
    {
      "id": "10.6.1.b",
      "controls": [
        {
          "id": "10.6.1.b"
        }
      ]
    },
    {
      "id": "10.6.2.b",
      "controls": [
        {
          "id": "10.6.2.b"
        }
      ]
    },
    {
      "id": "10.6.3.b",
      "controls": [
        {
          "id": "10.6.3.b"
        }
      ]
    },
    {
      "id": "10.7.b",
      "controls": [
        {
          "id": "10.7.b"
        }
      ]
    },
    {
      "id": "10.7.c",
      "controls": [
        {
          "id": "10.7.c"
        }
      ]
    },
    {
      "id": "11.2.2.a",
      "controls": [
        {
          "id": "11.2.2.a"
        }
      ]
    },
    {
      "id": "11.2.2.b",
      "controls": [
        {
          "id": "11.2.2.b"
        }
      ]
    },
    {
      "id": "11.2.2.c",
      "controls": [
        {
          "id": "11.2.2.c"
        }
      ]
    },
    {
      "id": "11.2.3.a",
      "controls": [
        {
          "id": "11.2.3.a"
        }
      ]
    },
    {
      "id": "11.2.3.b",
      "controls": [
        {
          "id": "11.2.3.b"
        }
      ]
    },
    {
      "id": "11.2.3.c",
      "controls": [
        {
          "id": "11.2.3.c"
        }
      ]
    },
    {
      "id": "11.3",
      "controls": [
        {
          "id": "11.3"
        }
      ]
    },
    {
      "id": "11.3.1.a",
      "controls": [
        {
          "id": "11.3.1.a"
        }
      ]
    },
    {
      "id": "11.3.1.b",
      "controls": [
        {
          "id": "11.3.1.b"
        }
      ]
    },
    {
      "id": "11.3.3",
      "controls": [
        {
          "id": "11.3.3"
        }
      ]
    },
    {
      "id": "11.3.4.a",
      "controls": [
        {
          "id": "11.3.4.a"
        }
      ]
    },
    {
      "id": "11.3.4.b",
      "controls": [
        {
          "id": "11.3.4.b"
        }
      ]
    },
    {
      "id": "11.3.4.c",
      "controls": [
        {
          "id": "11.3.4.c"
        }
      ]
    },
    {
      "id": "11.4.a",
      "controls": [
        {
          "id": "11.4.a"
        }
      ]
    },
    {
      "id": "11.4.b",
      "controls": [
        {
          "id": "11.4.b"
        }
      ]
    },
    {
      "id": "11.4.c",
      "controls": [
        {
          "id": "11.4.c"
        }
      ]
    },
    {
      "id": "11.5.1",
      "controls": [
        {
          "id": "11.5.1"
        }
      ]
    },
    {
      "id": "11.5.a",
      "controls": [
        {
          "id": "11.5.a"
        }
      ]
    },
    {
      "id": "11.5.b",
      "controls": [
        {
          "id": "11.5.b"
        }
      ]
    },
    {
      "id": "12.1",
      "controls": [
        {
          "id": "12.1"
        }
      ]
    },
    {
      "id": "12.1.1",
      "controls": [
        {
          "id": "12.1.1"
        }
      ]
    },
    {
      "id": "12.4",
      "controls": [
        {
          "id": "12.4"
        }
      ]
    },
    {
      "id": "12.5.3",
      "controls": [
        {
          "id": "12.5.3"
        }
      ]
    },
    {
      "id": "12.6.a",
      "controls": [
        {
          "id": "12.6.a"
        }
      ]
    },
    {
      "id": "12.8.1",
      "controls": [
        {
          "id": "12.8.1"
        }
      ]
    },
    {
      "id": "12.8.2",
      "controls": [
        {
          "id": "12.8.2"
        }
      ]
    },
    {
      "id": "12.8.3",
      "controls": [
        {
          "id": "12.8.3"
        }
      ]
    },
    {
      "id": "12.8.4",
      "controls": [
        {
          "id": "12.8.4"
        }
      ]
    },
    {
      "id": "12.8.5",
      "controls": [
        {
          "id": "12.8.5"
        }
      ]
    },
    {
      "id": "12.10.1.a",
      "controls": [
        {
          "id": "12.10.1.a"
        }
      ]
    },
    {
      "id": "12.10.1.b.i",
      "controls": [
        {
          "id": "12.10.1.b.i"
        }
      ]
    },
    {
      "id": "12.10.1.b.ii",
      "controls": [
        {
          "id": "12.10.1.b.ii"
        }
      ]
    },
    {
      "id": "12.10.1.b.iii",
      "controls": [
        {
          "id": "12.10.1.b.iii"
        }
      ]
    },
    {
      "id": "12.10.1.b.iv",
      "controls": [
        {
          "id": "12.10.1.b.iv"
        }
      ]
    },
    {
      "id": "12.10.1.b.v",
      "controls": [
        {
          "id": "12.10.1.b.v"
        }
      ]
    },
    {
      "id": "12.10.1.b.vi",
      "controls": [
        {
          "id": "12.10.1.b.vi"
        }
      ]
    },
    {
      "id": "12.10.1.b.vii",
      "controls": [
        {
          "id": "12.10.1.b.vii"
        }
      ]
    }
  ]
}
