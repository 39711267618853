/**
 * vowels: a set containing the latin vowels (never "y").
 *
 * + No diacritics.
 * + Both upper and lower cases.
 */
export const vowels = new Set("AEIOUaeiou");

/**
 * Determiner is an enum of the english determiners that only have singular/
 * plural variants.
 *
 * Possessive determiners (e.g. "your" or "their") and quantifiers (1, 2, etc.)
 * are excluded because they have more variants.
 */
export enum Determiner {
  DefiniteArticle, // the (s.); the (pl.)
  IndefiniteArticle, // a, an (s.); no plural
  ProximalDemonstrative, // this (s.); these (pl.)
  DistalDemonstrative, // that (s.); those (pl.)
}
