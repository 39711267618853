import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import React from "react";

import { BASE_PALETTE } from "../../../../alpaca/base/colors";
import { GRID_SPACING } from "../../../../alpaca/base/grid";
import type { IconName } from "../../../../alpaca/components";
import { Tooltip, Button, Icon } from "../../../../alpaca/components";

interface IProps {
  onClick(): void;
  icon: IconName;
  tooltipContent?: Maybe<string | JSX.Element>;
}

export const IconButton: React.FC<IProps> = ({
  onClick,
  icon,
  tooltipContent,
}) => (
  <Tooltip content={tooltipContent ?? ""} disabled={!isSome(tooltipContent)}>
    <Button onClick={onClick} style={{ flexShrink: 0 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: `${2 * GRID_SPACING}px`,
        }}
      >
        <Icon icon={icon} color={BASE_PALETTE.SMOKE} />
      </div>
    </Button>
  </Tooltip>
);
