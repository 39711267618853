import { Icon, Menu, MenuItem, Tab } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Popover2 } from "@blueprintjs/popover2";
import { isSome } from "common/base/types/maybe";
import type { IRiskCategory } from "common/schemas/riskRegister/risk-register-content";
import {
  RISK_CATEGORIES,
  RISK_ID_TO_HEADING,
  RISK_ID_TO_SECTION_HEADING,
} from "common/schemas/riskRegister/risk-register-content";
import gql from "graphql-tag";
import { parse } from "json2csv";
import { groupBy } from "lodash";
import React, { useState } from "react";
import { CSVLink } from "react-csv";
import styled from "styled-components";

import { VantaButtonDeprecated } from "../../../../alpaca/base/deprecated";
import { GRID_SPACING } from "../../../../alpaca/base/grid";
import { DefaultView, H4, HeaderlessTabs } from "../../../../alpaca/components";
import { LogError } from "../../../../errors";
import { useGetInfoForRiskAssessmentReportQuery } from "../../../../gen/components";
import { FeatureGate } from "../../../../helpers/feature-gating/feature-gate";
import { FullPageSpinner } from "../../../helpers/FullPageSpinner";
import { Card } from "../../components/card";
import { TableTitle } from "../table-styles";
import { RiskScenarioTable } from "./risk-scenario-table";
import { getRiskScore } from "./risk-score";
import { RiskTaskTable } from "./risk-task-table";

const RISK_REPORT_TAB_IDS = {
  SCENARIOS: "scenarios",
  TASKS: "tasks",
};

export const RiskReport: React.FC = () => {
  const [selectedTabId, setSelectedTabId] = useState(
    RISK_REPORT_TAB_IDS.SCENARIOS
  );
  const { loading, data, error } = useGetInfoForRiskAssessmentReportQuery();
  if (loading) {
    return <FullPageSpinner />;
  }

  if (error || !data) {
    LogError(error ?? new Error("Bad fetch"));
    return null;
  }

  const domain = data.organization;
  const scenariosByRiskId = groupBy(
    domain.rrv3RiskScenarios,
    s => s.riskCategoryId
  );

  const hasScenarios = domain.rrv3RiskScenarios.length > 0;
  const hasTasks = domain.rrv3RiskScenarios.some(s => s.tasks.length > 0);

  const csvDownloadMenu = (
    <Menu>
      {hasScenarios ? (
        <CSVLink
          data={parse(
            domain.rrv3RiskScenarios.map(s => {
              return {
                "Section": `${RISK_ID_TO_SECTION_HEADING[s.riskCategoryId]} - ${
                  RISK_ID_TO_HEADING[s.riskCategoryId]
                }`,
                "Description": s.description,
                // 1 index likelihood and impact for CSV
                "Likelihood": s.likelihood + 1,
                "Impact": s.impact + 1,
                "Risk": getRiskScore(s),
                "Updated timestamp": s.updatedAt,
                "Updated by": s.evaluator?.displayName,
              };
            })
          )}
          filename="risk-assessment-scenarios.csv"
          target="_blank"
        >
          <MenuItem text="Scenarios" />
        </CSVLink>
      ) : null}
      {hasTasks ? (
        <CSVLink
          data={parse(
            domain.rrv3RiskScenarios.flatMap(s =>
              s.tasks.map(t => {
                return {
                  "Task": t.description,
                  "Scenario": s.description,
                  "Created timestamp": t.createdAt,
                  "Due date": t.dueDate,
                  "Completed timestamp": t.completedAt,
                  "Assignee": t.assignee?.displayName,
                };
              })
            )
          )}
          filename={`risk-assessment-tasks.csv`}
          target="_blank"
        >
          <MenuItem text="Tasks" />
        </CSVLink>
      ) : null}
    </Menu>
  );

  const downloadButton =
    hasScenarios || hasTasks ? (
      <Popover2
        key="csv-download"
        content={csvDownloadMenu}
        placement={"bottom-end"}
        minimal
      >
        <VantaButtonDeprecated>
          Export CSV&nbsp;&nbsp;
          <Icon icon={IconNames.CARET_DOWN} />
        </VantaButtonDeprecated>
      </Popover2>
    ) : null;

  return (
    <DefaultView
      headerProps={{
        title: "Risk report",
        rightControls: [downloadButton],
        tabProps: {
          id: "risk-report",
          tabIds: [
            { id: RISK_REPORT_TAB_IDS.SCENARIOS, title: "Scenarios" },
            { id: RISK_REPORT_TAB_IDS.TASKS, title: "Tasks" },
          ],
          selectedTabId,
          onChange: setSelectedTabId,
        },
      }}
    >
      <Card style={{ padding: 3 * GRID_SPACING }}>
        <HeaderlessTabs
          id="risk-assessment-report"
          selectedTabId={selectedTabId}
        >
          <Tab
            title="Scenarios"
            id={RISK_REPORT_TAB_IDS.SCENARIOS}
            panel={
              <div>
                {renderTab(c => (
                  <RiskScenarioTable
                    scenarios={c.risks.flatMap(
                      r => scenariosByRiskId[r.id] ?? []
                    )}
                  />
                ))}
              </div>
            }
          />
          <Tab
            title="Tasks"
            id={RISK_REPORT_TAB_IDS.TASKS}
            panel={
              <div>
                {renderTab(c => (
                  <RiskTaskTable
                    scenarios={c.risks.flatMap(
                      r => scenariosByRiskId[r.id] ?? []
                    )}
                  />
                ))}
              </div>
            }
          />
        </HeaderlessTabs>
      </Card>
    </DefaultView>
  );

  function renderTab(sectionContent: (category: IRiskCategory) => JSX.Element) {
    return Object.values(RISK_CATEGORIES).map((category: IRiskCategory) => {
      const sectionJSX = (
        <Section key={category.id}>
          <TableTitle>
            <H4>{category.breadcrumbText}</H4>
          </TableTitle>
          {sectionContent(category)}
        </Section>
      );

      if (isSome(category.feature)) {
        return (
          <FeatureGate key={category.id} feature={category.feature}>
            {sectionJSX}
          </FeatureGate>
        );
      } else {
        return sectionJSX;
      }
    });
  }
};

const Section = styled.div`
  margin-bottom: ${4 * GRID_SPACING}px;
`;

gql`
  query getInfoForRiskAssessmentReport {
    organization {
      id
      displayName
      riskDismissals {
        id
        riskId
        dismissingUser {
          id
          displayName
        }
        dismissedAt: createdAt
      }
      rrv3RiskScenarios {
        id
        description
        impact
        likelihood
        evaluator {
          id
          displayName
        }
        riskCategoryId
        updatedAt
        tasks {
          id
          createdAt
          completedAt
          description
          assignee {
            id
            displayName
          }
          updatedBy {
            id
            displayName
          }
          dueDate
        }
      }
    }
  }
`;
