import type { IQuestionSchema } from "../../forms/interfaces";

export const logging: IQuestionSchema = {
  id: "logging",
  title: "Logging",
  questions: [
    {
      id: "loggingHeader-2018-09-10",
      label: "Logging",
      name: "loggingHeader",
      required: false,
      type: "Header",
    },
    {
      id: "logsCentral-2018_09_11",
      label:
        "Application and infrastructure logs are stored in a central location",
      name: "logsCentral",
      required: false,
      type: "CheckboxInput",
    },
    {
      choices: [
        "One week",
        "One month",
        "One quarter",
        "Half year",
        "One year",
        "Longer than one year",
        "Application and/or infrastructure logs are not stored",
      ],
      id: "logsStorage-2018_09_11",
      label: "Application and infrastructure logs storage length",
      name: "logsStorage",
      required: false,
      type: "SelectInput",
    },
    {
      id: "logsAdminDelete-2018_09_07",
      label:
        "Application and infrastructure logs can only be deleted by administrators",
      name: "logsAdminDelete",
      required: false,
      type: "CheckboxInput",
    },
    {
      id: "logsMFADelete-2018_09_07",
      label:
        "Application and infrastructure logs can only be deleted with a second factor",
      name: "logsMFADelete",
      required: false,
      type: "CheckboxInput",
    },
    {
      id: "adminActionsLogged-2018_09_07",
      label:
        "All admin actions on infrastructure, databases, and networks are logged",
      name: "adminActionsLogged",
      required: false,
      type: "CheckboxInput",
    },
  ],
  submitText: "Submit logging info",
  version: "2018-09-10",
};
