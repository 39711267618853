import type { Maybe } from "common/base/types/maybe";
import deepequal from "fast-deep-equal";
import React from "react";

import { Checkbox } from "../../form-controls/checkbox";
import type { IMenuOption } from "./data-table";
import { DataTable } from "./data-table";

type SortFn<T> = (d1: T, d2: T) => number;

interface IProps<T> {
  classes?: Maybe<string>;
  columnClasses?: Maybe<{ [k: string]: string }>;
  columnOrder: string[];
  columnSortFunctions?: Maybe<{ [k: string]: SortFn<T> }>;
  data: T[];
  disabled?: Maybe<boolean>;
  header: Maybe<{ [k: string]: Maybe<string | JSX.Element> }>;
  handleRowSelected: (data: T[]) => (row: T) => void;
  handleSelectAll: (data: T[]) => () => void;
  selectedRows: T[];
  handleUpdateTable?: Maybe<(data: T[]) => Promise<void>>;
  createRow(
    data: T,
    index: number
  ): { [k: string]: Maybe<string | number | JSX.Element> };
  menuOptions?(data: T): IMenuOption[];
  onMenuItemClick?(flag: string, data: T): void;
  searchFilter?(data: T, searchText: string): boolean;
}

// wrapper for DataTable that adds checkbox/selector functionality
export const SelectorDataTable = <V extends {}>(props: IProps<V>) => {
  const {
    data,
    disabled,
    selectedRows,
    columnOrder,
    handleRowSelected,
    handleSelectAll,
  } = props;

  const handleRowSelectedWithData = handleRowSelected(data);
  const handleSelectAllWithData = handleSelectAll(data);
  const disabledWithDefault = disabled ?? false;
  const header = {
    ...props.header,
    checkbox: (
      <div className="data-table-cell-checkbox">
        <Checkbox
          disabled={disabledWithDefault}
          checked={selectedRows.length === data.length}
          onChange={handleSelectAllWithData}
        />
      </div>
    ),
  };

  const createRow = (row: V, idx: number) => {
    const updateRowState =
      (selectedRow: any) => (e: React.FormEvent<HTMLInputElement>) => {
        handleRowSelectedWithData(selectedRow);
      };
    return {
      checkbox: (
        <Checkbox
          disabled={disabledWithDefault}
          checked={selectedRows.findIndex(sRow => deepequal(sRow, row)) !== -1}
          onChange={updateRowState(row)}
        />
      ),
      ...props.createRow(row, idx),
    };
  };

  return (
    <div>
      <DataTable
        {...props}
        header={header}
        columnOrder={["checkbox"].concat(columnOrder)}
        createRow={createRow}
        handleRowSelected={handleRowSelectedWithData}
        handleSelectAll={handleSelectAllWithData}
      />
    </div>
  );
};
