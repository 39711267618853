import type { SortParams } from "../base/types/gen";

export enum ServerVulnerabilitySortType {
  DUE_DATE,
  MOST_ISSUES,
}

export const SERVER_VULNERABILITY_SORT_PARAMS_BY_TYPE: {
  [k in ServerVulnerabilitySortType]: SortParams;
} = {
  [ServerVulnerabilitySortType.DUE_DATE]: {
    field: "minSlaDeadline",
    direction: 1,
  },
  [ServerVulnerabilitySortType.MOST_ISSUES]: {
    field: "totalVulnCount",
    direction: -1,
  },
};
