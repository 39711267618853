import { Checkbox, Intent } from "@blueprintjs/core";
import { toPossibleDate } from "common/base/dateUtils";
import type { Maybe } from "common/base/types/maybe";
import { isSome, nothing } from "common/base/types/maybe";
import { getDefaultTestRolloutEndDate } from "common/base/utils";
import {
  compareTestVersions,
  testVersionsEqual,
  ZERO_VERSION,
} from "common/product-tests/test-version";
import { getStandardForVersion } from "common/standards/all_standards";
import { ALL_TSCS } from "common/standards/soc2/tsc-utils";
import gql from "graphql-tag";
import { parse } from "json2csv";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import styled from "styled-components";

import {
  BodyShortText,
  BodyText,
  Button,
  H2,
  H3,
  TextLinkLikeBlack,
  Tooltip,
} from "../../alpaca/components";
import { LogError } from "../../errors";
import type { FetchTestsQuery } from "../../gen/components";
import {
  FetchTestsDocument,
  useFetchTestsQuery,
  useSetTestsToAvailableMutation,
  useSetTestsToPendingMutation,
  useTriggerTestRunsForAllDomainsMutation,
} from "../../gen/components";
import {
  UI_DATE_FORMAT_WITHOUT_TIME,
  FILE_TIMESTAMP_FORMAT,
} from "../../helpers/common";
import { DefaultLink } from "../../helpers/links";
import { AppToaster } from "../../helpers/toaster";
import { CancelConfirmDialog } from "../helpers/CancelConfirmDialog";
import { FullPageSpinner } from "../helpers/FullPageSpinner";
import { COLUMN_CLASSES, DataTable } from "../pages/components/data-table";
import { DropdownButton } from "../pages/components/dropdown-button";
import { MarkdownRenderer } from "../pages/components/markdown-renderer";
import { TableControls } from "../pages/components/table-controls";
import { TABLE_STYLES } from "../pages/risk-report/table-styles";
import { VIEWABLE_STANDARDS_IDS } from "../pages/standards/shared/allowed-standard-ids";

type TestVersion = NonNullable<
  FetchTestsQuery["tests"]
>[number]["versions"][number];

const VERSION_TABLE_COLUMN_ORDER = [
  "version",
  "description",
  "rolloutState",
  "latestAvailabilityDate",
  "actions",
];

const VERSION_TABLE_COLUMN_HEADERS = {
  version: "Version",
  description: "Name + Description",
  rolloutState: "Customer Availability",
  latestAvailabilityDate: "Date Made Available",
  actions: "Actions",
};

const VERSION_TABLE_COLUMN_WIDTHS = [
  "60px",
  "400px",
  "140px",
  "140px",
  "140px",
];

const availabilityStateToText = (availableToCustomers: boolean): string => {
  if (availableToCustomers) {
    return "AVAILABLE";
  } else {
    return "NOT AVAILABLE / PENDING ROLLOUT";
  }
};

const availabilityDateToText = (latestAvailabilityDate: Maybe<Date>): string =>
  isSome(latestAvailabilityDate)
    ? moment(latestAvailabilityDate).format(UI_DATE_FORMAT_WITHOUT_TIME)
    : "N/A";

const TestContainer = styled.div`
  margin: 6px;
  padding: 6px;
  table {
    ${TABLE_STYLES}
  }
`;

const STANDARDS_TO_GENERATE_CONTROL_CHANGES = [...VIEWABLE_STANDARDS_IDS];

enum STATUS_FILTER_OPTIONS {
  AVAILABLE = "Available to customers",
  PENDING = "Not available / pending rollout",
}

export const TestRolloutPanel: React.FC = () => {
  const { loading, error, data } = useFetchTestsQuery();
  const [changelogClicked, setChangelogClicked] = useState(false);
  const [skipRolloutPeriod, setSkipRolloutPeriod] = useState(false);
  const [runPendingTests, setRunPendingTests] = useState<boolean>(false);
  const linkRef = useRef<any>();
  const [setTestsToAvailable, { loading: setTestsAvailableLoading }] =
    useSetTestsToAvailableMutation({
      refetchQueries: [
        {
          query: FetchTestsDocument,
        },
      ],
    });
  const [setTestsToPending, { loading: setTestsPendingLoading }] =
    useSetTestsToPendingMutation({
      refetchQueries: [
        {
          query: FetchTestsDocument,
        },
      ],
    });
  const [triggerTestRunsForAllDomains, { loading: triggerTestRunLoading }] =
    useTriggerTestRunsForAllDomainsMutation();
  const [searchString, setSearchString] = useState("");
  const [statusFilter, setStatusFilter] = useState<Maybe<string>>(null);
  const [testVersionsToMakeAvailable, setTestsToMakeAvailable] = useState<
    TestVersion[]
  >([]);
  const [testIdsToWithdraw, setTestIdsToWithdraw] = useState<string[]>([]);
  const [showRolloutConfirmation, setShowRolloutConfirmation] =
    useState<boolean>(false);

  useEffect(() => {
    if (!changelogClicked) {
      return;
    } else {
      linkRef?.current?.link?.click();
      setChangelogClicked(false);
    }
  }, [changelogClicked]);

  const tests = data?.tests ?? [];

  const filteredTests = useMemo(
    () =>
      tests
        .filter(test => {
          const sanitized = searchString.trim().toLowerCase();

          const searchCondition =
            test.id.toLowerCase().includes(sanitized) ||
            test.versions.some(
              v =>
                (v.name ?? "").toLowerCase().includes(sanitized) ||
                v.detailedDescription.toLowerCase().includes(sanitized)
            );

          let filterCondition = true;
          if (statusFilter === STATUS_FILTER_OPTIONS.PENDING) {
            filterCondition = test.versions.some(
              v => !v.availability.isAvailableToUsers
            );
          } else if (statusFilter === STATUS_FILTER_OPTIONS.AVAILABLE) {
            filterCondition = test.versions.every(
              v => v.availability.isAvailableToUsers
            );
          }

          return searchCondition && filterCondition;
        })
        .sort((a, b) => a.id.localeCompare(b.id)),
    [tests, searchString, statusFilter]
  );
  const pendingTestIds = tests
    .filter(test => test.versions.some(v => !v.availability.isAvailableToUsers))
    .map(t => t.id);

  const totalChanges =
    testVersionsToMakeAvailable.length + testIdsToWithdraw.length;

  const rolloutEndDateString = skipRolloutPeriod
    ? "immediately"
    : moment(getDefaultTestRolloutEndDate()).format(
        UI_DATE_FORMAT_WITHOUT_TIME
      );
  const [newTests, updatesToExistingTests] = _.partition(
    testVersionsToMakeAvailable,
    tv => testVersionsEqual(tv.version, ZERO_VERSION)
  );

  if (error) {
    LogError(error);
    return null;
  }

  if (loading || !data) {
    return <FullPageSpinner />;
  }

  function downloadChangelogCSV() {
    setChangelogClicked(true);
  }

  function getControlsAffectedColumns(testId: string) {
    return Object.fromEntries(
      new Map(
        STANDARDS_TO_GENERATE_CONTROL_CHANGES.map(s => {
          const standard = getStandardForVersion(s, nothing, {
            soc2Tscs: [...ALL_TSCS],
          });
          const controlsAffected = standard
            .controlsContainingTestId(testId)
            .map(c => `${c.id} (${c.name})`);
          return [
            `Controls affected (${standard.getDisplayName()})`,
            controlsAffected,
          ];
        })
      )
    );
  }

  return (
    <div style={{ margin: "36px 12px" }}>
      <H2>Roll out tests</H2>
      <BodyText>
        View the tests currently available to customers, and roll out new ones.
      </BodyText>
      <BodyText>
        These are <strong>GLOBAL</strong> settings across ALL CUSTOMERS.
        Modifying a test via this page will modify it for ALL CUSTOMERS.
      </BodyText>
      <BodyText>
        Consult the{" "}
        <DefaultLink href="https://paper.dropbox.com/doc/Test-Rollout-Playbook--BKbXQT_3riGK__PnNUVNMfNgAg-tME3WeXLuvbFkoRButntP">
          Test Rollout Playbook{" "}
        </DefaultLink>
        when rolling out new tests.
      </BodyText>
      <Tooltip
        content={
          <span>
            Run all pending tests for all domains. Test runs will not be
            <br />
            saved, but resulting logs will show up in Datadog.
          </span>
        }
        placement="bottom-end"
      >
        <Button onClick={() => setRunPendingTests(true)}>
          Run all pending tests
        </Button>
      </Tooltip>
      <br />
      <TableControls
        searchParams={{
          placeholder: "Search",
          searchString,
          onNewSearchString: setSearchString,
        }}
        leftElements={[
          <DropdownButton
            options={Object.values(STATUS_FILTER_OPTIONS)}
            selectedOption={statusFilter}
            onOptionSelect={filter => setStatusFilter(filter)}
            optionRenderer={filter => filter}
            defaultText={"Filter by customer availability"}
            isFilter={true}
            buttonWidth={260}
            menuWidth={260}
            key="status-filter"
            styleOnSelect
          />,
        ]}
        rightElements={[
          <BodyShortText key={"change-count"}>
            {totalChanges} change(s) to apply
          </BodyShortText>,
          <Button
            key={"download-changelog"}
            disabled={totalChanges === 0}
            onClick={() => downloadChangelogCSV()}
          >
            Download Changelog
          </Button>,
          <Button
            key={"roll-out"}
            disabled={totalChanges === 0}
            onClick={() => setShowRolloutConfirmation(true)}
          >
            Roll Out Changes
          </Button>,
        ]}
      />
      {filteredTests.map(test => (
        <TestContainer key={test.id}>
          <BodyShortText>
            <strong>{test.id}</strong>
          </BodyShortText>
          <DataTable
            data={test.versions}
            header={VERSION_TABLE_COLUMN_HEADERS}
            columnClasses={{
              version: COLUMN_CLASSES.CENTER_ALIGN,
              rolloutState: COLUMN_CLASSES.CENTER_ALIGN,
              latestAvailabilityDate: COLUMN_CLASSES.CENTER_ALIGN,
              actions: COLUMN_CLASSES.CENTER_ALIGN,
            }}
            columnOrder={VERSION_TABLE_COLUMN_ORDER}
            createRow={testVersion => {
              return {
                version: (
                  <BodyText>
                    {`v${testVersion.version.major}.${testVersion.version.minor}`}
                  </BodyText>
                ),
                description: (
                  <>
                    <BodyText>
                      <strong>{testVersion.name}</strong>
                    </BodyText>
                    <BodyText>
                      {MarkdownRenderer(testVersion.detailedDescription)}
                    </BodyText>
                    {!testVersionsEqual(testVersion.version, ZERO_VERSION) ? (
                      <BodyText>
                        What changed:{" "}
                        {testVersion.versionChangeDescription?.what ?? ""}{" "}
                        {testVersion.versionChangeDescription?.why ?? ""}
                      </BodyText>
                    ) : null}
                  </>
                ),
                rolloutState: (
                  <BodyText>
                    {availabilityStateToText(
                      testVersion.availability.isAvailableToUsers
                    )}
                  </BodyText>
                ),
                latestAvailabilityDate: (
                  <BodyText>
                    {availabilityDateToText(
                      toPossibleDate(
                        testVersion.availability.latestAvailabilityDate
                      )
                    )}
                  </BodyText>
                ),
                actions: testVersion.availability.isAvailableToUsers ? (
                  <Checkbox
                    label={"Mark for withdrawal"}
                    checked={testIdsToWithdraw.includes(testVersion.testId)}
                    disabled={
                      testVersionsToMakeAvailable.find(
                        tv => tv.testId === testVersion.testId
                      ) !== nothing
                    }
                    onChange={e => {
                      if (e.currentTarget.checked) {
                        setTestIdsToWithdraw([
                          ...testIdsToWithdraw,
                          testVersion.testId,
                        ]);
                      } else {
                        setTestIdsToWithdraw(
                          testIdsToWithdraw.filter(
                            t => t !== testVersion.testId
                          )
                        );
                      }
                    }}
                  />
                ) : (
                  <Checkbox
                    label={"Mark for rollout"}
                    checked={testVersionsToMakeAvailable.includes(testVersion)}
                    disabled={
                      testIdsToWithdraw.find(
                        testId => testId === testVersion.testId
                      ) !== nothing
                    }
                    onChange={e => {
                      if (e.currentTarget.checked) {
                        // Add any versioned tests that are the current version or earlier
                        const newTestVersionsToMakeAvailable = test.versions
                          .filter(tv => !tv.availability.isAvailableToUsers)
                          .filter(
                            tv =>
                              compareTestVersions(
                                tv.version,
                                testVersion.version
                              ) <= 0
                          );
                        setTestsToMakeAvailable([
                          ...testVersionsToMakeAvailable,
                          ...newTestVersionsToMakeAvailable,
                        ]);
                      } else {
                        setTestsToMakeAvailable(
                          // Remove any versioned tests that are the current version or later
                          testVersionsToMakeAvailable.filter(
                            tv =>
                              tv.testId !== testVersion.testId ||
                              (tv.testId === testVersion.testId &&
                                compareTestVersions(
                                  tv.version,
                                  testVersion.version
                                ) < 0)
                          )
                        );
                      }
                    }}
                  />
                ),
              };
            }}
            columnWidths={VERSION_TABLE_COLUMN_WIDTHS}
          />
        </TestContainer>
      ))}

      <CancelConfirmDialog
        body={
          <BodyText>
            This will queue the following tests:
            <ul>
              {pendingTestIds.map(i => (
                <li key={i}>{i}</li>
              ))}
            </ul>
            for all domains, regardless of rollout state. This utility should be
            used infrequently for tests that have not been rolled out so that we
            can understand whether the test will pass or fail.
          </BodyText>
        }
        confirmText="Run tests"
        isOpen={runPendingTests}
        onClose={() => setRunPendingTests(false)}
        onConfirm={async () => {
          await triggerTestRunsForAllDomains({
            variables: { testIds: pendingTestIds },
          });
          AppToaster.show({
            intent: Intent.SUCCESS,
            message: "Test runs triggered successfully!",
            timeout: 2500,
          });
          setRunPendingTests(false);
        }}
        loading={triggerTestRunLoading}
        title="Confirm test runs"
      />

      <CancelConfirmDialog
        body={
          <>
            <H3>Summary of changes</H3>
            <BodyText>
              <ul>
                <li>
                  Adding {newTests.length} test(s) - rollout period ends{" "}
                  <strong>{rolloutEndDateString}</strong>
                </li>
                <li>
                  Updating {updatesToExistingTests.length} test(s) - rollout
                  period ends <strong>{rolloutEndDateString}</strong>
                </li>
                <li>
                  Withdrawing {testIdsToWithdraw.length} existing test(s) -
                  effective <strong>immediately</strong>
                </li>
              </ul>
            </BodyText>
            <Checkbox
              label={"Skip rollout period for additions and updates"}
              checked={skipRolloutPeriod}
              onChange={e => {
                setSkipRolloutPeriod(e.currentTarget.checked);
              }}
            />
            <br />
            <H3>Instructions</H3>
            <BodyText>
              <ol>
                <li>
                  Download the changelog CSV and double-check the changes.{" "}
                  <TextLinkLikeBlack onClick={() => downloadChangelogCSV()}>
                    Download changelog
                  </TextLinkLikeBlack>
                </li>
                <li>Click the button to start the roll out for customers.</li>
                <li>
                  Keep the changelog. Upload it to this{" "}
                  <DefaultLink href="https://drive.google.com/drive/folders/1GSbO41ahmcVc6SnmIGN5mdGGWBo9b-2d?usp=sharing">
                    Google Drive Folder
                  </DefaultLink>
                  , and send to Vanta'n's and auditors as needed.
                </li>
              </ol>
            </BodyText>
          </>
        }
        confirmText="Roll out"
        isOpen={showRolloutConfirmation}
        onClose={() => setShowRolloutConfirmation(false)}
        onConfirm={async () => {
          downloadChangelogCSV();
          if (testVersionsToMakeAvailable.length > 0) {
            await setTestsToAvailable({
              variables: {
                tests: testVersionsToMakeAvailable.map(test => {
                  return {
                    testId: test.testId,
                    version: {
                      major: test.version.major,
                      minor: test.version.minor,
                    },
                  };
                }),
                skipRolloutPeriod,
              },
            });
          }
          if (testIdsToWithdraw.length > 0) {
            await setTestsToPending({
              variables: {
                testIds: testIdsToWithdraw,
              },
            });
          }
          AppToaster.show({
            intent: Intent.SUCCESS,
            message: "Rollout triggered successfully!",
            timeout: 2500,
          });
          setShowRolloutConfirmation(false);
          setTestsToMakeAvailable([]);
          setTestIdsToWithdraw([]);
          setStatusFilter(null);
        }}
        loading={setTestsAvailableLoading || setTestsPendingLoading}
        title="Confirm rollout"
      />

      <CSVLink
        style={{ display: "none" }}
        ref={linkRef}
        data={
          totalChanges > 0
            ? parse([
                ...testVersionsToMakeAvailable.map(versionedTest => {
                  const isNewTestVersion = !testVersionsEqual(
                    versionedTest.version,
                    ZERO_VERSION
                  );
                  return {
                    "Test ID": versionedTest.testId,
                    "Test Name": versionedTest.name,
                    "Action": isNewTestVersion ? "UPDATED" : "ADDED",
                    "Test Description": versionedTest.detailedDescription,
                    "Update Description": isNewTestVersion
                      ? `${
                          versionedTest.versionChangeDescription?.what ?? ""
                        } ${versionedTest.versionChangeDescription?.why ?? ""}`
                      : "Added new test",
                    "Date Change Visible to Auditors": rolloutEndDateString,
                    ...getControlsAffectedColumns(versionedTest.testId),
                  };
                }),
                ...testIdsToWithdraw.map(testId => {
                  const test = tests.find(t => t.id === testId);
                  const latestAvailableTestVersion = (test?.versions ?? [])
                    .filter(tv => tv.availability.isAvailableToUsers)
                    .sort((tv1, tv2) =>
                      compareTestVersions(tv1.version, tv2.version)
                    )
                    .reverse()[0];
                  const testName = latestAvailableTestVersion.name ?? "";
                  const testDescription =
                    latestAvailableTestVersion.detailedDescription ?? "";
                  return {
                    "Test ID": testId,
                    "Test Name": testName,
                    "Test Description": testDescription,
                    "Action": "REMOVED",
                    "Update Description": "Removed test",
                    "Date Change Visible to Auditors": "Immediately",
                    ...getControlsAffectedColumns(testId),
                  };
                }),
              ])
            : ""
        }
        filename={`test-rollout-${moment().format(FILE_TIMESTAMP_FORMAT)}.csv`}
        target="_blank"
      />
    </div>
  );
};

gql`
  query fetchTests {
    tests {
      id
      versions {
        testId
        version {
          major
          minor
        }
        versionChangeDescription {
          what
          why
        }

        availability {
          isAvailableToUsers
          latestAvailabilityDate
        }
        name
        detailedDescription
      }
    }
  }
`;

gql`
  mutation setTestsToAvailable(
    $tests: [testVersionAndIdInput!]!
    $skipRolloutPeriod: Boolean!
  ) {
    setTestStatusToAvailable(
      input: { tests: $tests, skipRolloutPeriod: $skipRolloutPeriod }
    ) {
      status
    }
  }
`;

gql`
  mutation setTestsToPending($testIds: [String!]!) {
    setTestStatusToPending(input: { testIds: $testIds }) {
      status
    }
  }
`;

gql`
  mutation triggerTestRunsForAllDomains($testIds: [String!]!) {
    triggerTestsForAllCustomerDomains(testIds: $testIds)
  }
`;
