import { Spinner } from "@blueprintjs/core";
import {
  specificResourceGraphQLTypeToEnumValue,
  SpecificResourceToInventoryType,
} from "common/utils/inventory";
import {
  deserializeVantaAttributes,
  hasExternallyManagedVantaAttributes,
} from "common/utils/vantaAttributes";
import gql from "graphql-tag";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import type { NosqldbsQuery } from "../../../../gen/components";
import {
  useNosqldbsQuery,
  useSetInventoryResourceVantaAttributeMutation,
} from "../../../../gen/components";
import { InventoryCard } from "../inventory-card";
import { AccountTag, BackupTag, EncryptionTag, EPHITag } from "../tags";
import {
  fetchMoreResources,
  getDebounceContext,
  getResourceFilterParams,
  invalidInventoryType,
  PAGE_SIZE,
  setResourceVantaAttributeFn,
} from "../utils";
import { SearchResultsSummary } from "./search-results-summary";
import type { IInventoryTabProps } from "./shared-interface";

export const NosqlDatabases: React.FC<IInventoryTabProps> = ({
  searchString,
}) => {
  const { data, fetchMore, loading } = useNosqldbsQuery({
    variables: {
      first: PAGE_SIZE,
      filterParams: getResourceFilterParams(searchString),
    },
    context: getDebounceContext("no-sql"),
  });

  if (loading) {
    return <Spinner />;
  }

  const cards = data?.organization.resources.edges?.map(item => (
    <NosqlCard key={item.node.id} item={item} domain={data.organization} />
  ));

  return (
    <>
      <SearchResultsSummary
        searchString={searchString}
        numberResults={data?.organization.resources?.totalCount ?? 0}
      />
      <InfiniteScroll
        className="inventory-list-card-group"
        dataLength={data?.organization.resources.edges.length ?? 0}
        next={async () => fetchMoreResources(data, fetchMore)}
        hasMore={data?.organization.resources.pageInfo.hasNextPage ?? false}
        loader={<Spinner />}
      >
        {cards}
      </InfiniteScroll>
    </>
  );
};

const NosqlCard: React.FC<{
  item: NonNullable<
    NosqldbsQuery["organization"]
  >["resources"]["edges"][number];
  domain: NonNullable<NosqldbsQuery["organization"]>;
}> = ({ item, domain }) => {
  const [setResourceVantaAttribute] =
    useSetInventoryResourceVantaAttributeMutation();

  const specificResourceKind = specificResourceGraphQLTypeToEnumValue(
    item.node.__typename
  );
  const type = SpecificResourceToInventoryType[specificResourceKind](
    item.node as any
  );
  const resourceMongoId = item.node.id;

  let uid;
  const labels: JSX.Element[] = [];
  const vantaAttributes = deserializeVantaAttributes(
    item.node.vantaAttributes ?? []
  );
  switch (item.node.__typename) {
    case "SpecificAzureCosmosDBResource": {
      uid = item.node.uniqueId;
      labels.push(
        <EncryptionTag
          key="encryption"
          encrypted={true} // Azure encrypts by default and it can't be disabled
        />
      );
      labels.push(
        <AccountTag key="account" cloudAccountId={item.node.dbAccountName} />
      );
      if (vantaAttributes.containsEPHI) {
        labels.push(<EPHITag key="ephi" />);
      }
      break;
    }
    case "SpecificDigitalOceanRedisClusterResource": {
      uid = item.node.uniqueId;
      labels.push(
        <EncryptionTag
          key="encryption"
          encrypted={true} // DigitalOcean encrypts by default and it can't be disabled
        />
      );
      if (vantaAttributes.containsEPHI) {
        labels.push(<EPHITag key="ephi" />);
      }
      break;
    }
    case "SpecificDynamoDBTableResource": {
      uid = item.node.uniqueId;
      labels.push(
        <EncryptionTag
          key="encryption"
          encrypted={true} // AWS encrypts by default. It can be encrypted with a customer-provided key, but there's still encryption no matter what
        />
      );

      if (vantaAttributes.containsEPHI) {
        labels.push(<EPHITag key="ephi" />);
      }
      break;
    }
    case "SpecificBigtableInstanceResource": {
      uid = item.node.name;
      if (vantaAttributes.containsEPHI) {
        labels.push(<EPHITag key="ephi" />);
      }
      labels.push(
        <EncryptionTag
          key="encryption"
          encrypted={true} // GCP encrypts by default. It can be encrypted with a customer-provided key, but there's still encryption no matter what
        />
      );
      break;
    }
    case "SpecificDatastoreProjectResource": {
      uid = item.node.uniqueId;
      labels.push(
        <EncryptionTag key="encryption" encrypted={true} />
        // GCP encrypts by default and it can't be disabled
      );
      break;
    }
    case "SpecificFirestoreProjectResource": {
      uid = item.node.uniqueId;
      labels.push(
        <EncryptionTag
          key="encryption"
          encrypted={true} // GCP encrypts by default and it can't be disabled
        />
      );
      break;
    }
    case "SpecificAtlasClusterResource": {
      uid = item.node.uniqueId;
      labels.push(
        <BackupTag backupRetentionDays={item.node.restoreWindowDays} />
      );
      break;
    }
    default:
      return invalidInventoryType(item.node.__typename);
  }

  const locked = hasExternallyManagedVantaAttributes(
    item.node.vantaAttributes ?? []
  );
  const setVantaAttribute = setResourceVantaAttributeFn(
    setResourceVantaAttribute,
    specificResourceKind,
    resourceMongoId,
    item.node.__typename
  );

  return (
    <InventoryCard
      type={type}
      uid={uid}
      key={item.node.id}
      name={item.node.name}
      description={vantaAttributes.description}
      owner={vantaAttributes.ownerId}
      canContainUserData={true}
      containsUserData={vantaAttributes.containsUserData}
      containsEPHI={vantaAttributes.containsEPHI}
      labels={labels}
      userDataDescription={vantaAttributes.userDataStored}
      locked={locked}
      setVantaAttribute={setVantaAttribute}
    />
  );
};

gql`
  query nosqldbs($first: Int!, $after: String, $filterParams: filterParams) {
    organization {
      id
      name
      resources(
        first: $first
        after: $after
        genericResourceType: NoSQLDatabase
        sortParams: { field: "createdAt", direction: -1 }
        filterParams: $filterParams
      ) {
        totalCount
        edges {
          node {
            id
            __typename
            uniqueId
            vantaAttributes {
              key
              value
              managedExternally
            }
            ... on SpecificAzureCosmosDBResource {
              dbAccountName
              name
            }
            ... on SpecificDigitalOceanRedisClusterResource {
              name
            }
            ... on SpecificDynamoDBTableResource {
              name
            }
            ... on SpecificBigtableInstanceResource {
              name
            }
            ... on SpecificDatastoreProjectResource {
              name
            }
            ... on SpecificFirestoreProjectResource {
              name
            }
            ... on SpecificAtlasClusterResource {
              name
              backupEnabled
              projectId
              restoreWindowDays
            }
          }
        }
        pageInfo {
          hasNextPage
          endCursor
          startCursor
          hasPreviousPage
        }
      }
    }
  }
`;
