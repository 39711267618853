/**
 * Helpers for cloud service provider tags/labels.
 */

import type { VantaAttribute } from "../base/types/gen";
import type { Maybe } from "../base/types/maybe";
import { isSome } from "../base/types/maybe";
import type { InfraTagsSchema } from "../resources/common-schemas";
import {
  ReservedVantaAttributes,
  VantaAttributeSentinelValues,
} from "./vantaAttributes";

export type InfraTags = typeof InfraTagsSchema;

const VANTA_TAG_TYPES = [
  "vantaDescription",
  "vantaOwner",
  "vantaUserData",
  "vantaNonProd",
  "vantaNoAlert",
  "vantaEPHI",
  "vantaDataStored",
] as const;

type vantaTagKeys = { [k in typeof VANTA_TAG_TYPES[number]]: string };

export const normalizeAttributeKey = (t: string) =>
  t.replace(/-/g, "").toLocaleLowerCase();

/**
 ******* Camel case tagging schemes ********
 */

// exported just for testing; don't use in other files
export const _CAMEL_CASE_VANTA_PREFIX = "Vanta";

export const _VANTA_CAMELCASE_TAG_KEYS: vantaTagKeys = {
  vantaDescription: _CAMEL_CASE_VANTA_PREFIX + "Description",
  vantaOwner: _CAMEL_CASE_VANTA_PREFIX + "Owner",
  vantaUserData: _CAMEL_CASE_VANTA_PREFIX + "ContainsUserData",
  vantaNonProd: _CAMEL_CASE_VANTA_PREFIX + "NonProd",
  vantaNoAlert: _CAMEL_CASE_VANTA_PREFIX + "NoAlert",
  vantaEPHI: _CAMEL_CASE_VANTA_PREFIX + "ContainsEPHI",
  vantaDataStored: _CAMEL_CASE_VANTA_PREFIX + "UserDataStored",
};

const vantaResourceCamelCaseTagKeys: Set<string> = new Set(
  Object.values(_VANTA_CAMELCASE_TAG_KEYS)
);

// exported for testing purposes. don't use in other places.
export const _getVantaCamelCaseTags = (tags: InfraTags) => {
  const vantaTags = new Map<string, string>();
  tags.forEach(({ key, value }) => {
    if (vantaResourceCamelCaseTagKeys.has(key)) {
      vantaTags.set(key, value);
    }
  });
  return vantaTags;
};

export function _camelCaseTagsToVantaAttributes(
  tags: InfraTags
): VantaAttribute[] {
  let outOfScope = false;
  const attrs = tags
    .filter(tag => tag.key.startsWith(_CAMEL_CASE_VANTA_PREFIX))
    .map(({ key, value }) => {
      if (
        key === _VANTA_CAMELCASE_TAG_KEYS.vantaNonProd &&
        tagValueMeansTrue(value)
      ) {
        outOfScope = true;
      }
      if (
        key === _VANTA_CAMELCASE_TAG_KEYS.vantaNoAlert &&
        isSome(reasonOrUndefined(value))
      ) {
        outOfScope = true;
      }
      // strip dashes from reserved labels and convert to lower so the attributes are consistent
      // between gcp and camel case tags
      let normalizedKey = normalizeAttributeKey(
        key.slice(_CAMEL_CASE_VANTA_PREFIX.length)
      );
      // The owner vantaAttribute has a different key, handle that case here
      // If more cases arise, might be nice to generalize a mapping from tag
      // key to corresponding vantaAttribute key.
      if (key === _VANTA_CAMELCASE_TAG_KEYS.vantaOwner) {
        normalizedKey = "ownerid";
      }
      return { key: normalizedKey, value, managedExternally: true };
    });
  if (outOfScope) {
    attrs.push({
      key: ReservedVantaAttributes.excluded.key,
      value: VantaAttributeSentinelValues.TRUE,
      managedExternally: true,
    });
  }
  return attrs;
}

// exported for testing purposes. don't use in other files.
export function _vantaDoesNotTrackCamelCase(tags: InfraTags) {
  const vantaTags = _getVantaCamelCaseTags(tags);
  const isNonProd = tagValueMeansTrue(
    vantaTags.get(_VANTA_CAMELCASE_TAG_KEYS.vantaNonProd)
  );
  const hasNoAlertReason = isSome(
    reasonOrUndefined(vantaTags.get(_VANTA_CAMELCASE_TAG_KEYS.vantaNoAlert))
  );
  return isNonProd || hasNoAlertReason;
}

/*
 ****** AWS SPECIFIC *********
 */

export const VANTA_AWS_TAG_KEYS = _VANTA_CAMELCASE_TAG_KEYS;
export const AWS_AUTOSCALE_TAG_KEY = "aws:autoscaling:groupName";

export const awsTagsToVantaAttributes = (tags: InfraTags) =>
  _camelCaseTagsToVantaAttributes(tags);

// Get autoscale group name from tags, or null if not an autoscale instance
export const getAWSAutoscaleGroupName = (tags: InfraTags) =>
  tags.find(t => t.key === AWS_AUTOSCALE_TAG_KEY)?.value;

/*
 ****** Azure SPECIFIC *********
 */
export const VANTA_AZURE_TAG_KEYS = _VANTA_CAMELCASE_TAG_KEYS;

export const azureTagsToVantaAttributes = (tags: InfraTags) =>
  _camelCaseTagsToVantaAttributes(tags);

/**
 ******* Kebab case tagging schemes ********
 */

export const _KEBAB_CASE_VANTA_PREFIX = "vanta-";

export const VANTA_KEBAB_OWNER_DOT_SUBSTITUTE = "_dot_";

export const VANTA_KEBAB_LABEL_KEYS: vantaTagKeys = {
  vantaDescription: _KEBAB_CASE_VANTA_PREFIX + "description",
  vantaOwner: _KEBAB_CASE_VANTA_PREFIX + "owner",
  vantaUserData: _KEBAB_CASE_VANTA_PREFIX + "contains-user-data",
  vantaNonProd: _KEBAB_CASE_VANTA_PREFIX + "non-prod",
  vantaNoAlert: _KEBAB_CASE_VANTA_PREFIX + "no-alert",
  vantaEPHI: _KEBAB_CASE_VANTA_PREFIX + "contains-ephi",
  vantaDataStored: _KEBAB_CASE_VANTA_PREFIX + "user-data-stored",
} as const;

export function kebabCaseLabelsToVantaAttributes(
  labels: InfraTags,
  domainName: string
): VantaAttribute[] {
  let outOfScope = false;
  const attrs = labels
    .filter(label => label.key.startsWith(_KEBAB_CASE_VANTA_PREFIX))
    .map(({ key, value }) => {
      if (
        key === VANTA_KEBAB_LABEL_KEYS.vantaNonProd &&
        tagValueMeansTrue(value)
      ) {
        outOfScope = true;
      }
      if (
        key === VANTA_KEBAB_LABEL_KEYS.vantaNoAlert &&
        isSome(reasonOrUndefined(value))
      ) {
        outOfScope = true;
      }
      // The owner vantaAttribute has a different key, handle that case here
      // If more cases arise, might be nice to generalize a mapping from tag
      // key to corresponding vantaAttribute key.
      if (key === VANTA_KEBAB_LABEL_KEYS.vantaOwner) {
        return {
          key: "ownerid",
          value: kebabOwnerLabelToEmail(value, domainName),
          managedExternally: true,
        };
      }
      // strip dashes from reserved labels and convert to lower so the attributes are consistent
      // between gcp and aws
      const normalizedKey = normalizeAttributeKey(
        key.slice(_KEBAB_CASE_VANTA_PREFIX.length)
      );
      // might be nice to also normalize the value so we don't have to call tagMeansTrue/reasonOrUndefined
      // on the values.
      return { key: normalizedKey, value, managedExternally: true };
    });
  if (outOfScope) {
    attrs.push({
      key: ReservedVantaAttributes.excluded.key,
      value: VantaAttributeSentinelValues.TRUE,
      managedExternally: true,
    });
  }
  return attrs;
}

function kebabOwnerLabelToEmail(ownerLabel: string, domainName: string) {
  return `${ownerLabel.replace(
    VANTA_KEBAB_OWNER_DOT_SUBSTITUTE,
    "."
  )}@${domainName}`;
}

/**
 * DigitalOcean specific helpers
 */

// DigitalOcean tags aren't key-value, just strings. We ask users to create tags
// like "key:value" as a workaround
export function digitalOceanTagsToInfraTags(digitalOceanTags: string[]) {
  return digitalOceanTags
    .filter(doTag => doTag.includes(":"))
    .map(doTag => {
      const [key, value] = doTag.split(":");
      return { key, value };
    });
}

/**
 * GENERIC HELPERS
 */

// A user may use terraform "true" which gets converted to "1" – but may also
// type in the string "true" to mean true.
const tagValueMeansTrue = (tagValue: Maybe<string>) =>
  isSome(tagValue) && ["1", "true"].includes(tagValue.toLocaleLowerCase());

const tagValueMeansFalse = (tagValue: string) =>
  ["0", "false", ""].includes(tagValue.toLocaleLowerCase());

// If a user gives a reason, we count that as the tag being active. Otherwise
// (or if they give a 0 or false) we turn it off.
const reasonOrUndefined = (tagValue: Maybe<string>) =>
  isSome(tagValue) && tagValue.length > 0 && !tagValueMeansFalse(tagValue)
    ? tagValue
    : undefined;
