import { OL } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { isSome, nothing } from "common/base/types/maybe";
import React from "react";

import { BASE_TYPOGRAPHY } from "../../../../alpaca/base/typography";
import { BodyText, H4 } from "../../../../alpaca/components";
import { ServerOsFamily } from "../../../../gen/components";
import { CalloutWithClipBoard } from "../../../helpers/callout-with-clipboard";
import type { Machine } from "./server-detail";

interface IProps {
  server: Machine;
}

const textForOS = (os: Maybe<ServerOsFamily>) => {
  switch (os) {
    case ServerOsFamily.Debian:
      return `apt-get update; apt-get -s dist-upgrade | grep "^Inst" | grep -i securi | awk -F " " {'print $2'} | xargs apt-get install`;
    case ServerOsFamily.RHEL:
      return `yum update-minimal --security -y`;
    default:
      return null;
  }
};

export const RemediationCommand: React.FC<IProps> = ({ server }) => {
  const osFamily =
    server.data.__typename === "linuxServerData"
      ? server.data.osFamily
      : nothing;
  const command = textForOS(osFamily);
  if (!isSome(command)) {
    return null;
  }
  return (
    <>
      <H4 fontWeight={BASE_TYPOGRAPHY.FONT_WEIGHTS.SLIGHTLY_BOLD}>
        Remediation Suggestions
      </H4>

      <BodyText fontWeight={BASE_TYPOGRAPHY.FONT_WEIGHTS.BOLD}>
        Upgrade outdated packages
      </BodyText>
      <BodyText>
        You can do this for each package individually, or attempt to upgrade all
        of them by running the following command:
      </BodyText>
      <CalloutWithClipBoard text={command} />
      <br />

      <BodyText fontWeight={BASE_TYPOGRAPHY.FONT_WEIGHTS.BOLD}>
        Upgrade kernel
      </BodyText>
      <BodyText>
        Vulnerabilities may also be caused by running an out-of-date kernel. To
        remediate these vulnerabilities, upgrade the machine's kernel:
      </BodyText>
      <BodyText>
        <OL>
          <li>
            Follow your distribution's instructions about how to upgrade the
            kernel.
          </li>
          <li>Restart your machine to begin running the latest version.</li>
        </OL>
      </BodyText>
      <br />
    </>
  );
};
