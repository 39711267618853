import React from "react";

import type { GetDataForReportQuery } from "../../../../../gen/components";

interface IProps {
  requirement: NonNullable<
    GetDataForReportQuery["complianceStandard"]
  >["principles"][number]["requirements"][number];
}

export const VantaReportRequirementListControlCountDisplay: React.FunctionComponent<IProps> =
  ({ requirement }) => (
    <div
      className="vrp-control-count"
      style={requirement.name.length === 0 ? { marginTop: 0 } : {}}
    >{`${requirement.controls.length} CONTROL${
      requirement.controls.length > 1 ? "S" : ""
    }`}</div>
  );
