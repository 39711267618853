import { dropNothing, isSome } from "common/base/types/maybe";
import React from "react";

import { VANTA_COLORS } from "../../../../alpaca/base/colors";
import { GRID_SPACING } from "../../../../alpaca/base/grid";
import { BASE_TYPOGRAPHY } from "../../../../alpaca/base/typography";
import { BodyText } from "../../../../alpaca/components";
import { InfoIcon } from "../../people/shared/info-icon";
import type { AccessTabUser } from "./access-tab";

const MAX_ITEMS_TO_DISPLAY = 10;

type Workstation = AccessTabUser["workstations"][number];
interface IProps {
  workstations: Workstation[];
}

export const SSHAccessibleResources: React.FC<IProps> = ({ workstations }) => {
  const accessibleMachines = dropNothing(
    workstations
      .map(w => {
        if (!("sshKeys" in w.data)) {
          return [];
        }
        return w.data.sshKeys?.map(s =>
          s.machinesThisCanAccess?.edges.map(e => e.node)
        );
      })
      .flat(2)
  );
  const accessibleMachinesToDisplay = accessibleMachines.slice(
    0,
    MAX_ITEMS_TO_DISPLAY
  );

  const maybeEmptyState =
    accessibleMachinesToDisplay.length === 0 ? (
      <BodyText>No accessible computers</BodyText>
    ) : null;
  return (
    <>
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "12px" }}
      >
        <BodyText
          style={{ marginBottom: "0px", marginRight: `${GRID_SPACING}px` }}
          fontWeight={BASE_TYPOGRAPHY.FONT_WEIGHTS.BOLD}
        >
          SSH accessible computers
        </BodyText>
        <InfoIcon
          tooltipContent={`SSH accessibility is only available for machines
          running the Vanta agent.`}
        />
      </div>
      {maybeEmptyState}
      {accessibleMachinesToDisplay.map((machine, idx) => {
        const maybeMachineHelperText =
          machine.cloudProviderData?.__typename ===
            "SpecificEC2InstanceResource" &&
          isSome(machine.cloudProviderData?.account) &&
          machine.cloudProviderData?.service === "aws" ? (
            <BodyText as="span" color={VANTA_COLORS.TEXT_MUTED}>
              {`(AWS account: ${machine.cloudProviderData!.account})`}
            </BodyText>
          ) : null;
        return (
          <div
            key={`ssh-machine-${idx}`}
            style={{ marginBottom: `${2 * GRID_SPACING}px` }}
          >
            <BodyText as="div">
              <span>{machine.prettyName}</span>
              {maybeMachineHelperText}
            </BodyText>
          </div>
        );
      })}
    </>
  );
};
