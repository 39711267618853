import type { DateRange } from "@blueprintjs/datetime";
import { TestOutcome } from "common/base/types/gen";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import _ from "lodash";
import moment from "moment";
import React, { useMemo, useRef, useState } from "react";
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import styled from "styled-components";

import { BASE_PALETTE } from "../../alpaca/base/colors";
import { GRID_SPACING } from "../../alpaca/base/grid";
import { BodyShortText, BodyText } from "../../alpaca/components";
import type {
  FetchTestResultInfoForDetailQuery,
  GetEvidenceForControlDetailQuery,
} from "../../gen/components";
import { UI_DATE_FORMAT } from "../../helpers/common";

export type VersionChangeHistoryEntry = {
  changeDescription: string;
  date: Date;
};

interface IProps {
  historyData: Array<{
    flipTimestamp: number;
    outcome: TestOutcome;
    invalidationReason?: Maybe<string>;
    testRun?: Maybe<{
      id: string;
    }>;
  }>;
  versionChangeHistory?: Maybe<VersionChangeHistoryEntry[]>;
  dateRange: DateRange;
  monitoringDisabledStatus:
    | NonNullable<
        FetchTestResultInfoForDetailQuery["organization"]
      >["testResults"][number]["monitoringDisabledStatus"]
    | NonNullable<
        GetEvidenceForControlDetailQuery["organization"]
      >["testResults"][number]["monitoringDisabledStatus"];
  onBarClick?: Maybe<Function>;
}

const testOutcomeToFillColor: { [k: string]: string } = {
  [TestOutcome.PASS]: BASE_PALETTE.KALE,
  [TestOutcome.FAIL]: BASE_PALETTE.TOMATO,
  [TestOutcome.IN_PROGRESS]: BASE_PALETTE.BANANA,
  [TestOutcome.DISABLED]: BASE_PALETTE.SMOKE,
  [TestOutcome.INVALID]: BASE_PALETTE.CHARCOAL,
  [TestOutcome.NA]: BASE_PALETTE.FOG,
};

const testOutcomeToVerbalDescription: { [k: string]: string } = {
  [TestOutcome.PASS]: "The test was passing during this period.",
  [TestOutcome.FAIL]: "The test was not passing during this period.",
  [TestOutcome.IN_PROGRESS]:
    "The test results were in-progress during this period.",
  [TestOutcome.DISABLED]: "The test was disabled during this period.",
  [TestOutcome.INVALID]: "The test was invalid during this period: ",
  [TestOutcome.NA]: "The test was not applicable during this period.",
};

const VERSION_HISTORY_TOOLTIP_WIDTH = 45 * GRID_SPACING;

const StyledTooltipDiv = styled.div`
  max-width: ${VERSION_HISTORY_TOOLTIP_WIDTH}px;
  padding: ${2 * GRID_SPACING}px;
  background-color: ${BASE_PALETTE.INK}DD;
  border-radius: 6px;
`;

const ElipsifiedBodyShortText = styled(BodyShortText)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const TestHistoryBarGraph: React.FC<IProps> = ({
  historyData,
  dateRange,
  versionChangeHistory,
  monitoringDisabledStatus,
  onBarClick,
}) => {
  // Tracks the bar being hovered over for use in tooltip.
  const [tooltipValue, setTooltipValue] = useState<Maybe<number>>(null);

  // Used to ensure the version history tooltip stays inside container
  const containerRef = useRef<any>(null);

  // Tracks the location the mouse is hovering to view version change metadata for use in tooltip.
  const [versionHoverPosition, setVersionHoverPosition] =
    useState<Maybe<number>>(null);

  const startTime = dateRange?.[0]?.getTime() ?? Date.now();
  const endTime = dateRange?.[1]?.getTime() ?? Date.now();
  const diffStartEndDays = moment(endTime).diff(startTime, "days");

  const [allData, barData, tooltipData] = useMemo(() => {
    const firstHistoryBeforeStart = [...historyData]
      .reverse()
      .find((h, index) => +h.flipTimestamp < startTime);

    const historyDataWithinRange = [
      {
        flipTimestamp: startTime,
        outcome: isSome(firstHistoryBeforeStart)
          ? firstHistoryBeforeStart.outcome
          : TestOutcome.NA,
      },
      ...historyData.filter(
        h => startTime <= +h.flipTimestamp && +h.flipTimestamp <= endTime
      ),
    ];

    const data: Array<{
      startTime: number;
      length: number;
      outcome: TestOutcome;
      invalidationReason?: Maybe<string>;
      testRunId?: Maybe<string>;
    }> = historyDataWithinRange.map((h, index) => {
      const flipTimestamp = h.flipTimestamp;
      const nextFlipTimestamp =
        index + 1 < historyDataWithinRange.length
          ? historyDataWithinRange[index + 1].flipTimestamp
          : endTime;
      return {
        startTime: flipTimestamp,
        length: nextFlipTimestamp - flipTimestamp,
        outcome: h.outcome,
        invalidationReason: h.invalidationReason,
        testRunId: h.testRun?.id,
      };
    });

    const dataForBars = Object.fromEntries(
      data.map(d => [d.startTime, d.length])
    );
    const dataForTooltip = Object.fromEntries(
      data.map(d => [
        d.startTime,
        {
          startDate: moment(d.startTime).format(UI_DATE_FORMAT),
          endDate: moment(d.startTime + d.length).format(UI_DATE_FORMAT),
          outcome: d.outcome,
          invalidationReason: d.invalidationReason,
        },
      ])
    );
    return [data, dataForBars, dataForTooltip];
  }, [startTime, endTime]);

  const versionChangesVisibleOnGraph = (versionChangeHistory ?? []).filter(
    entry => startTime < entry.date.getTime() && entry.date.getTime() < endTime
  );

  const shouldShowTick =
    versionChangesVisibleOnGraph.length > 0 ||
    monitoringDisabledStatus.isDisabled;

  // Compute fractional end position from 0 to 1
  const getTickPositionFromDate = (date: Date) =>
    (date.getTime() - startTime) / (endTime - startTime);

  const getFormattedDateFromMs = (dateNumber: number) =>
    moment(dateNumber).format("MMM D").toUpperCase();

  const dateTickPositions =
    diffStartEndDays < 5
      ? [
          0.015,
          ..._.map(
            _.range(1, diffStartEndDays),
            i => (1 / diffStartEndDays) * i
          ),
          0.985,
        ]
      : [0.015, 0.2, 0.4, 0.6, 0.8, 0.985];

  const deactivatedTicks =
    monitoringDisabledStatus.isDisabled &&
    isSome(monitoringDisabledStatus.createdAt)
      ? [
          {
            title: "Deactivated",
            description: monitoringDisabledStatus.testWhitelistReason ?? "",
            date: new Date(monitoringDisabledStatus.createdAt),
            secondLine: isSome(monitoringDisabledStatus.disabledBy)
              ? `Deactivated by ${monitoringDisabledStatus.disabledBy?.displayName}`
              : "",
            thirdLine: moment(
              new Date(monitoringDisabledStatus.createdAt).getTime()
            ).format(UI_DATE_FORMAT),
            position: getTickPositionFromDate(
              new Date(monitoringDisabledStatus.createdAt)
            ),
          },
        ]
      : [];
  const versionChangeTicks = versionChangesVisibleOnGraph.map(changeEntry => {
    return {
      title: "Updated",
      description: changeEntry.changeDescription ?? "",
      date: changeEntry.date,
      secondLine: `${getFormattedDateFromMs(changeEntry.date.getTime())}`,
      thirdLine: "",
      position: getTickPositionFromDate(changeEntry.date),
    };
  });
  const ticks = versionChangeTicks.concat(deactivatedTicks);

  return (
    <ResponsiveContainer ref={containerRef} width={"100%"} height={75}>
      <BarChart
        margin={{ top: 0, left: 24, right: 24, bottom: 0 }}
        data={[barData]}
        layout="vertical"
        stackOffset="expand"
      >
        {/* Displays the top line indicating version history */}
        <XAxis
          xAxisId="versionHistory"
          type="number"
          tickFormatter={f =>
            ticks.find(tick => tick.position === f)?.title ?? ""
          }
          orientation="top"
          tick={shouldShowTick ? { fontSize: 12 } : false}
          tickLine={shouldShowTick}
          axisLine={false}
          domain={[0, 1]}
          ticks={ticks.map(t => t.position)}
          onMouseEnter={param => {
            setVersionHoverPosition(param.coordinate);
          }}
          onMouseLeave={() => setVersionHoverPosition(null)}
        />

        {/* Displays the bottom line indicating dates */}
        <XAxis
          type="number"
          tickFormatter={f =>
            moment(f * (endTime - startTime) + startTime)
              .format("MMM D")
              .toUpperCase()
          }
          tick={{ fontSize: 12 }}
          tickLine={false}
          axisLine={false}
          ticks={dateTickPositions}
        />

        <YAxis type="category" hide />

        <Tooltip
          cursor={false}
          isAnimationActive={false}
          wrapperStyle={{
            zIndex: 3,
            top: 24,
            // Unfortunate hack to make tooltips visible when hovering over non-bar areas
            // https://github.com/recharts/recharts/issues/790
            visibility:
              isSome(tooltipValue) || isSome(versionHoverPosition)
                ? "visible"
                : "hidden",
          }}
          position={
            isSome(versionHoverPosition)
              ? {
                  // Clamp the tooltip position within its container
                  x: Math.max(
                    0,
                    Math.min(
                      versionHoverPosition,
                      ((containerRef.current?.container as HTMLDivElement)
                        ?.clientWidth ?? 1200) -
                        VERSION_HISTORY_TOOLTIP_WIDTH -
                        2 * GRID_SPACING
                    )
                  ),
                  y: 0,
                }
              : undefined
          }
          content={(item: any) => {
            // Render the version history or deactivated reason tooltip, if we should
            if (isSome(versionHoverPosition)) {
              // Find the version change corresponding to the hover position
              const tickHoveredOn = _.minBy(ticks, tick =>
                Math.abs(
                  ((tick.date.getTime() - startTime) / (endTime - startTime)) *
                    1000 - // Coordinates are from 0 to 1000 so compute the rough coordinate using the fractional position
                    versionHoverPosition
                )
              );
              if (!isSome(tickHoveredOn)) {
                return null;
              }
              return (
                <StyledTooltipDiv>
                  <ElipsifiedBodyShortText color={BASE_PALETTE.SNOW}>
                    {tickHoveredOn.description}
                  </ElipsifiedBodyShortText>
                  <BodyText color={BASE_PALETTE.FOG} style={{ margin: 0 }}>
                    {tickHoveredOn.secondLine}
                  </BodyText>
                  {tickHoveredOn.thirdLine !== "" ? (
                    <BodyText color={BASE_PALETTE.FOG} style={{ margin: 0 }}>
                      {tickHoveredOn.thirdLine}
                    </BodyText>
                  ) : (
                    <></>
                  )}
                </StyledTooltipDiv>
              );
            }

            // Render the test result tooltip, if we should
            if (isSome(tooltipValue) && isSome(tooltipData[tooltipValue])) {
              const data = tooltipData[tooltipValue];
              return (
                <StyledTooltipDiv>
                  <BodyShortText color={BASE_PALETTE.SNOW}>
                    {testOutcomeToVerbalDescription[data.outcome] +
                      (data.outcome === TestOutcome.INVALID
                        ? data.invalidationReason
                        : "")}
                  </BodyShortText>
                  <BodyText color={BASE_PALETTE.FOG} style={{ margin: 0 }}>
                    {data.startDate} - {data.endDate}
                  </BodyText>
                </StyledTooltipDiv>
              );
            }

            return null;
          }}
        />
        {allData.map((d, idx) => (
          <Bar
            key={d.startTime}
            dataKey={d.startTime}
            onMouseEnter={() => {
              setTooltipValue(d.startTime);
            }}
            onMouseLeave={() => {
              setTooltipValue(null);
            }}
            fill={testOutcomeToFillColor[d.outcome]}
            stackId="stack"
            barSize={6}
            radius={
              idx === 0
                ? [2, 0, 0, 2]
                : idx === allData.length - 1
                ? [0, 2, 2, 0]
                : undefined
            }
            {...(isSome(onBarClick)
              ? { onClick: () => onBarClick(d.testRunId) }
              : {})}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default TestHistoryBarGraph;
