import "./email-subscription.scss";

import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import React from "react";

import { LogError, LogErrorMessage } from "../../../errors";
import { useUserLevelQuery } from "../../../gen/components";
import { FullPageSpinner } from "../../helpers/FullPageSpinner";
import {
  PageHeadingInfo,
  VantaDashboardPage,
} from "../../vanta-chrome/page-content/vanta-dashboard-page";
import { EmailSubscriptionDetailsView } from "./email-subscription-details-view";

export const EmailSubscriptionPage: React.FC = () => {
  const { error, loading, data } = useUserLevelQuery();
  if (error) {
    LogError(error);
    return null;
  }
  if (loading) {
    return <FullPageSpinner />;
  }
  if (!data || !data.user) {
    LogErrorMessage("Bad fetch");
    return null;
  }

  if (!isSome(data.user.auditorInfo)) {
    return (
      <VantaDashboardPage headingInfo={PageHeadingInfo.NOTIFICATIONS}>
        <EmailSubscriptionDetailsView></EmailSubscriptionDetailsView>
      </VantaDashboardPage>
    );
  } else {
    return (
      <VantaDashboardPage headingInfo={PageHeadingInfo.NOTIFICATIONS}>
        Emails are not enabled for you; Vanta does not send emails to auditors.
      </VantaDashboardPage>
    );
  }
};

gql`
  query userLevel {
    user {
      id
      permissionLevel
      auditorInfo {
        id
        firmName
      }
    }
  }
`;
