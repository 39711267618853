import { ControlGroup, HTMLSelect, InputGroup } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import React, { useState } from "react";

import type { IConfigureScopesProps } from "./configure-scopes-dialog";
import { ConfigureScopesKindSection } from "./configure-scopes-kind-section";

/**
 * allKindsFilterOption is an option for displaying all resouorce kinds in a
 * given ConfigureScopes dialog.
 */
const allKindsFilterOption = { value: "ALL", label: "All" };

/**
 * ConfigureScopes is a dialog body for configuring resource scopes. If you
 * want a dialog, @see ConfigureScopesDialog.
 *
 * @param props.scopableResources - an array of resource kind metadata, each
 *    element of which is sufficient to render a ConfigureScopesKindSection.
 * @param props.tips - instructions to display at the top of the dialog. How
 *    should the customer think about scoping these resources?
 */
export const ConfigureScopes: React.FC<IConfigureScopesProps> = props => {
  const [resourceKindFilter, setResourceKindFilter] =
    useState<Maybe<string>>(undefined);

  const resourceKindFilterControl =
    props.scopableResources.length <= 1 ? null : (
      <HTMLSelect
        options={[
          allKindsFilterOption,
          ...props.scopableResources.map(md => {
            return {
              value: md.kind,
              label: md.pluralDisplayName,
            };
          }),
        ]}
        onChange={e =>
          e.currentTarget.value === allKindsFilterOption.value
            ? setResourceKindFilter(undefined)
            : setResourceKindFilter(e.currentTarget.value)
        }
      />
    );

  const [resourceFilter, setResourceFilter] =
    useState<Maybe<string>>(undefined);

  return (
    <div>
      <ControlGroup>
        {resourceKindFilterControl}
        <InputGroup
          placeholder="Search resources"
          fill={true}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setResourceFilter(e.currentTarget.value)
          }
        />
      </ControlGroup>
      {props.scopableResources
        .filter(
          md => !isSome(resourceKindFilter) || resourceKindFilter === md.kind
        )
        .map(md => (
          <ConfigureScopesKindSection
            key={md.kind}
            scopableResourceMetadata={md}
            filter={resourceFilter}
          />
        ))}
    </div>
  );
};
