import "./nav-sidebar.scss";

import { Button, Classes, Navbar } from "@blueprintjs/core";
import gql from "graphql-tag";
import React from "react";

import { TextLinkLike } from "../../alpaca/components";
import { Config } from "../../config";
import { LogError } from "../../errors";
import type { GetUserInfoQueryQuery } from "../../gen/components";
import {
  useGetUserInfoQueryQuery,
  useUnassumeUserMutationMutation,
} from "../../gen/components";
import { DefaultLink } from "../../helpers/links";
import { AssumedUserReadWriteIndicator } from "../vanta-chrome/top-bar/assumed-user-read-write-indicator";

interface IInternalProps {
  user: NonNullable<GetUserInfoQueryQuery["user"]>;
}

const AdminNavbarComponent: React.FC<IInternalProps> = ({ user }) => {
  const [unassumeUser] = useUnassumeUserMutationMutation({
    onCompleted: () => {
      location.href = "/";
    },
  });

  let maybeAssumedClass = Classes.DARK;
  if (user.isAssumedSuperUser) {
    if (Config.isDev) {
      maybeAssumedClass = "app-navbar-assumed localadmin";
    } else if (Config.isStaging) {
      maybeAssumedClass = "app-navbar-assumed stagingadmin";
    } else {
      maybeAssumedClass = "app-navbar-assumed admin";
    }
  } else if (user.isAssumedUser) {
    maybeAssumedClass = "app-navbar-assumed";
  }

  const maybeAssumedEmail = <span>{user.email}</span>;
  return (
    <Navbar className={maybeAssumedClass + " admin-navbar"}>
      <Navbar.Group className={Classes.ALIGN_LEFT}>
        <Navbar.Heading>Vanta admin</Navbar.Heading>
        {maybeAssumedEmail}&nbsp;
        <TextLinkLike
          onClick={async () => {
            await unassumeUser();
          }}
        >
          back to regular user
        </TextLinkLike>
      </Navbar.Group>
      <Navbar.Group className={Classes.ALIGN_RIGHT}>
        <DefaultLink href="/graphql">
          <Button minimal={true} icon="database">
            GraphQL
          </Button>
        </DefaultLink>
        <Navbar.Divider />
        <AssumedUserReadWriteIndicator
          hasAssumedWriteAccess={user.hasAssumedWriteAccess}
        />
      </Navbar.Group>
    </Navbar>
  );
};

gql`
  query GetUserInfoQuery {
    user {
      id
      email
      isAssumedUser
      isAssumedSuperUser
      givenName
      hasAssumedWriteAccess
    }
  }
`;

export const AdminNavbar: React.FC = () => {
  const { error, loading, data } = useGetUserInfoQueryQuery();
  if (error) {
    LogError(error);
  }
  if (loading || !data || !data.user) {
    return <div />;
  }
  return <AdminNavbarComponent user={data.user} />;
};
