import { TestOutcome } from "common/base/types/gen";
import React from "react";

import WhiteIcon from "../../../../../static/images/reports/icon_blank_space.svg";
import SuccessIcon from "../../../../../static/images/reports/icon_complete.svg";
import NeedsWorkIcon from "../../../../../static/images/reports/icon_needs_work.svg";

interface IProps {
  evidenceOutcome: TestOutcome;
}

export const VantaReportEvidenceListItemStatus: React.FunctionComponent<IProps> =
  ({ evidenceOutcome }) => {
    if (evidenceOutcome === TestOutcome.NA) {
      return (
        <span className="vrp-na-text vrp-na-text-no-description">N/A</span>
      );
    }
    const outcomeSvg = (outcomeStr: string) => {
      switch (outcomeStr) {
        case TestOutcome.PASS:
          return SuccessIcon;
        case TestOutcome.FAIL:
          return NeedsWorkIcon;
        default:
          return WhiteIcon;
      }
    };
    const Icon = outcomeSvg(evidenceOutcome);
    return <Icon />;
  };
