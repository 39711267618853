import { Callout } from "@blueprintjs/core";
import React from "react";

export const GetStarted: React.FC = () => (
  <>
    <div>How to connect Vanta to AWS</div>
    <Callout>
      Vanta needs read-only AWS credentials with limited permissions in order to
      monitor your AWS configurations. An AWS administrator for your
      organization can create the necessary IAM role through the AWS UI by
      following these instructions:
    </Callout>
  </>
);
