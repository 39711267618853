import type { IButtonProps } from "@blueprintjs/core";
import { Button } from "@blueprintjs/core";
import { isSome } from "common/base/types/maybe";
import React, { useEffect, useState } from "react";

/**
 * Button that will show a loading icon when clicked, which will be reset
 * if the OnClick handler changes. Augment the dependency array in useEffect
 * for more flexibility.
 * @param props
 */
export const LoadingButton: React.FC<IButtonProps> = props => {
  const [clicked, setClicked] = useState(false);
  useEffect(() => {
    if (clicked) {
      setClicked(false);
    }
  }, [clicked, props.onClick]);
  return (
    <Button
      {...props}
      loading={clicked}
      onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setClicked(true);
        if (isSome(props.onClick)) {
          props.onClick(e);
        }
      }}
    />
  );
};
