import { Button } from "@blueprintjs/core";
import { PermissionLevel } from "common/base/types/gen";
import gql from "graphql-tag";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { BodyText } from "../../../../alpaca/components";
import type { CheckSuspendedOrRemovedQuery } from "../../../../gen/components";
import { UserContext } from "../../user-context";
import { ConfirmOffboardUserDialog } from "./confirm-offboard-user-dialog";
import { OffboardingListView } from "./offboarding-list-view";

interface IProps {
  user: NonNullable<CheckSuspendedOrRemovedQuery["user"]>;
}

export const OffboardingTab: React.FC<IProps> = ({ user }) => {
  const userId = user.id;

  const { user: loggedInUser } = useContext(UserContext);
  const [offboardDialogOpen, setOffboardDialogOpen] = useState(false);

  if (!user.isActive) {
    return <OffboardingListView userId={userId} />;
  }

  if (user.hrUser) {
    if (user.hrUser.isActive) {
      return (
        <BodyText>
          We'll prompt you to offboard this person when we detect a status
          change in your HR system.
        </BodyText>
      );
    }
  } else {
    if (user.isFromScan) {
      return (
        <BodyText>
          We'll prompt you to offboard this person when we detect a status
          change in your identity provider.
        </BodyText>
      );
    }
  }

  if (user.id === loggedInUser?.id) {
    return (
      <BodyText>
        To begin the offboarding process for yourself, you must be removed by a
        different user of your Vanta account.
      </BodyText>
    );
  }

  if (user.permissionLevel === PermissionLevel.Admin) {
    return (
      <BodyText>
        This user is an admin of your Vanta account. An admin must revoke their
        admin status in the <Link to="/users">Users page</Link> before removing.
      </BodyText>
    );
  }

  return (
    <>
      <CenteredContent>
        <BodyText>
          Remove this person and start the offboarding process?
        </BodyText>
        <Button onClick={() => setOffboardDialogOpen(true)}>Offboard</Button>
      </CenteredContent>
      <ConfirmOffboardUserDialog
        user={user}
        isOpen={offboardDialogOpen}
        onClose={() => setOffboardDialogOpen(false)}
      />
    </>
  );
};

const CenteredContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

gql`
  mutation removeUser($userId: ID!) {
    removeUser(userId: $userId) {
      id
    }
  }
`;
