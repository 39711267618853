import React from "react";
import styled from "styled-components";

import { IconDiv } from "../login";
import OktaSvg from "./logo.svg";
export const OktaIcon: React.FC<{ active: boolean }> = ({ active }) => (
  <IconDiv active={active}>
    <IconImg>
      <OktaSvg />
    </IconImg>
  </IconDiv>
);

const IconImg = styled.div`
  width: 21px;
  height: 21px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  & svg {
    width: 100%;
  }
`;
