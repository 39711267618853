import { isSome } from "common/base/types/maybe";
import type { Maybe } from "common/base/types/maybe";
import moment from "moment";

export enum InspectorRunStatus {
  NONE,
  STALE,
  RECENT,
}

export function getInspectorRunStatus(
  assessmentDate: Maybe<string>
): InspectorRunStatus {
  if (!isSome(assessmentDate)) {
    return InspectorRunStatus.NONE;
  }
  return moment(new Date(assessmentDate)).isBefore(
    moment().subtract(30, "days")
  )
    ? InspectorRunStatus.STALE
    : InspectorRunStatus.RECENT;
}
