import "./all-domain-suggest.scss";

import { Spinner } from "@blueprintjs/core";
import { Suggest } from "@blueprintjs/select";
import type { Maybe } from "common/base/types/maybe";
import { dropNothing, isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import React, { useEffect, useRef } from "react";

import { LogError } from "../../errors";
import type { GetAllDomainNamesQuery } from "../../gen/components";
import { useGetAllDomainNamesQuery } from "../../gen/components";

interface IProps {
  requestFocus?: Maybe<boolean>;
  selectedId?: Maybe<string>;
  onSelect(domainId: string): void;
}

const DomainSuggest =
  Suggest.ofType<
    NonNullable<
      NonNullable<GetAllDomainNamesQuery["internal"]["allDomains"]>[number]
    >
  >();

export const AllDomainSuggest: React.FC<IProps> = ({
  onSelect,
  requestFocus,
  selectedId,
}) => {
  const { error, loading, data } = useGetAllDomainNamesQuery();
  const localRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (requestFocus && isSome(localRef.current)) {
      localRef.current.focus();
    }
  });

  if (error) {
    LogError(error);
    return null;
  }
  if (loading) {
    return <Spinner />;
  }
  if (!isSome(data) || !isSome(data.internal.allDomains)) {
    LogError(new Error("Bad fetch for GetAllDomainNames"));
    return null;
  }
  const domains = dropNothing(data.internal.allDomains);

  return (
    <DomainSuggest
      closeOnSelect
      resetOnQuery
      inputValueRenderer={d => d.name}
      inputProps={{
        // Needed to cast as any to set the ref to request
        // focus to the input after render. Not sure why.
        inputRef: input => {
          (localRef.current as any) = input;
        },
      }}
      itemPredicate={(query, d) => {
        const domainName = d.name.toLowerCase();
        const queryLowerCased = query.toLowerCase();
        return domainName.includes(queryLowerCased) || d.id === queryLowerCased;
      }}
      items={domains}
      itemsEqual={(d1, d2) => d1.id === d2.id}
      itemRenderer={(d, { modifiers, handleClick }) => (
        <div
          className={`domain-suggest-item${
            modifiers.active ? " domain-suggest-active" : ""
          }`}
          onClick={handleClick}
          key={d.id}
        >
          {d.name}
        </div>
      )}
      noResults={<div>No results.</div>}
      onItemSelect={item => {
        onSelect(item.id);
      }}
      popoverProps={{ minimal: true, placement: "bottom-end" }}
      selectedItem={
        isSome(selectedId) ? domains.find(d => d.id === selectedId) : selectedId
      }
      fill={true}
    />
  );
};

gql`
  query GetAllDomainNames {
    internal {
      allDomains {
        id
        name
      }
    }
  }
`;
