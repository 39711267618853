/**
 * ListCoordinator is an enum of coordinating conjunctions used before the last
 * item in lists:
 *  + Dog, cat, AND mouse.
 *  + Dog AND mouse.
 *  + Dog, cat, OR mouse.
 *  + Dog OR mouse.
 */
export enum ListCoordinator {
  Or = "or",
  And = "and",
}

/**
 * toCommaSeparatedList creates a comma-separated grammatical list with an
 * oxford comma.
 *
 * @param items - An array of strings to be joined in a comma-separated list.
 * @param combiner - The word that should follow the oxford comma.
 *
 * @example
 *
 *    // Returns "dog"
 *    toCommaSeparatedList(["dog"]); // dog
 *
 *    // Returns "dog or cat"
 *    toCommaSeparatedList(["dog", "cat"]);
 *
 *    // Returns "dog, cat, or mouse"
 *    toCommaSeparatedList(["dog", "cat", "mouse"]);
 *
 *    // Returns "dog, cat, and mouse"
 *    toCommaSeparatedList(["dog", "cat", "mouse"], ListCoordinator.And);
 */
export function toCommaSeparatedList(
  items: string[],
  conjunction: ListCoordinator
): string {
  if (items.length <= 2) {
    return items.join(` ${conjunction} `);
  } else {
    const count = items.length;
    return items.reduce((aggregation, current, i) => {
      if (i === 0) {
        aggregation += current;
      } else if (i === count - 1) {
        aggregation += `, ${conjunction} ${current}`;
      } else {
        aggregation += `, ${current}`;
      }
      return aggregation;
    }, "");
  }
}
