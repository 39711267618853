import type { Service } from "common/base/types/helpers";
import { serviceToDisplayName } from "common/base/types/helpers";
import { isSome } from "common/base/types/maybe";
import { TRAINING_CATEGORIES } from "common/constants/onboarding";
import { mustCompleteSat } from "common/employee-tasks/employee-tasks-utils";
import { format, isAfter } from "date-fns";
import { maxBy } from "lodash";
import React from "react";

import { IconButton, IconNames } from "../../../../../alpaca/components";
import {
  GetUserInfoForOnboardingTabDocument,
  SecurityTrainingCategoryId,
} from "../../../../../gen/components";
import { getTrainingPreferencesMap } from "../../../onboarding/employee-onboarding/get-training-preferences";
import {
  integrationTrainingTracked,
  getCurrentTrainingTaskDetails,
  getMostRecentlyCompletedTrainingRequirementForCategory,
} from "../../utils";
import { OnboardingReminderButton } from "../email-alert-button";
import { InProgressBgCheckButtonWithDialog } from "../in-progress-bg-check-button-with-dialog";
import { LinkBgCheckButtonWithDialog } from "../link-bg-check-button-with-dialog";
import { PolicyAcceptanceDetail } from "../policy-acceptance-detail";
import { UploadSecurityTrainingButton } from "../upload-security-training-button";
import type { OnboardingTabUser } from "./onboarding-tab";
import { OnboardingTasksCard } from "./onboarding-tasks-card";
import { TaskDateDetail } from "./task-date-detail";
import { TaskListItem } from "./task-list-item";

export const TOOLTIP_DATE_FORMAT = "M/D/YYYY";

interface IProps {
  user: OnboardingTabUser;
}

export const SecurityRequirementsList: React.FC<IProps> = ({ user }) => {
  const taskList: React.ReactNode[] = [];
  const {
    backgroundChecks,
    domain,
    endpointState,
    hasAcceptedAllSecurityPolicies,
    hasCompletedBackgroundCheck,
    isActive,
    mostRecentGdprSecurityTraining,
    mostRecentHipaaSecurityTraining,
    mostRecentPciSecurityTraining,
    mostRecentSecurityTraining,
    securityRequirements,
  } = user;

  // Background check
  if (
    securityRequirements.mustBeBackgroundChecked &&
    !Boolean(hasCompletedBackgroundCheck)
  ) {
    const hasBackgroundCheckInProgress = backgroundChecks.some(
      check => check.status === "IN PROGRESS"
    );
    const actionButton = hasBackgroundCheckInProgress ? (
      <InProgressBgCheckButtonWithDialog
        key="in-progress-bg-check-button"
        user={user}
        renderButton={onClick => (
          <IconButton
            icon={IconNames.VIEW}
            tooltipProps={{ content: "View" }}
            onClick={onClick}
            small
          />
        )}
      />
    ) : (
      <LinkBgCheckButtonWithDialog
        key="link-bg-check-button"
        user={user}
        renderButton={onClick => (
          <IconButton
            icon={IconNames.LINK}
            tooltipProps={{ content: "Link" }}
            onClick={onClick}
            small
          />
        )}
      />
    );
    taskList.push(
      <TaskListItem
        key="bg-check-task"
        title="Background check"
        actionButtons={[actionButton]}
      />
    );
  }

  // Agent install
  if (
    securityRequirements.mustInstallLaptopMonitoring &&
    isActive &&
    endpointState === "none"
  ) {
    taskList.push(
      <TaskListItem key="agent-download-task" title="Vanta Agent installed" />
    );
  }

  // Policy acceptance
  if (
    securityRequirements.mustAcceptPolicies &&
    (!Boolean(hasAcceptedAllSecurityPolicies) || domain.policies.length === 0)
  ) {
    const actionButtons = [
      <PolicyAcceptanceDetail
        key="policy-detail-button"
        userId={user.id}
        userName={user.displayName ?? ""}
        renderButton={onClick => (
          <IconButton
            onClick={onClick}
            icon={IconNames.VIEW}
            tooltipProps={{ content: "View" }}
            small
          />
        )}
      />,
    ];
    taskList.push(
      <TaskListItem
        key="accept-policies-task"
        title="Accepted policies"
        actionButtons={actionButtons}
      />
    );
  }

  const incompleteTrainingRequirements = user.trainingRequirements.filter(
    req =>
      !isSome(req.completionDate) &&
      integrationTrainingTracked(securityRequirements, req)
  );

  if (incompleteTrainingRequirements.length > 0) {
    // if they have incomplete trainings in the integrated SAT provider, just
    // show those
    incompleteTrainingRequirements.forEach((req, i) => {
      taskList.push(
        <TaskListItem
          key={`training-requirement-${i}`}
          title={`Complete ${
            req.displayName ?? "training"
          } in ${serviceToDisplayName(req.service as Service)}`}
        />
      );
    });
  } else {
    // Security trainings
    const satPreferencesByCategory = getTrainingPreferencesMap(
      user.domain.productDescriptionInfo
    );
    const noExternalSATLinked =
      user.domain.externalSATIntegrations.length === 0;
    TRAINING_CATEGORIES.forEach(category => {
      // only one pref per category allowed
      const pref = satPreferencesByCategory[category.id];
      if (
        // haven't set a preference for tracking in Vanta and not using an integration, nothing to track
        noExternalSATLinked &&
        !isSome(pref)
      ) {
        return;
      }
      if (
        // user isn't required to do this category
        !mustCompleteSat(user.securityRequirements, category.id)
      ) {
        return;
      }
      const inAppCompletion =
        category.id === SecurityTrainingCategoryId.hipaa
          ? mostRecentHipaaSecurityTraining
          : category.id === SecurityTrainingCategoryId.general
          ? mostRecentSecurityTraining
          : category.id === SecurityTrainingCategoryId.pci
          ? mostRecentPciSecurityTraining
          : category.id === SecurityTrainingCategoryId.gdpr
          ? mostRecentGdprSecurityTraining
          : null;

      const integrationCompletion =
        getMostRecentlyCompletedTrainingRequirementForCategory(
          category.id,
          user.trainingRequirements
        );

      const newestCompletion = maxBy(
        [inAppCompletion, integrationCompletion],
        c => c?.completionDate
      );

      const { taskCreationDate, taskDueDate } = getCurrentTrainingTaskDetails(
        newestCompletion?.completionDate,
        new Date(user.startDate),
        domain.onboardingSla
      );
      if (isAfter(new Date(), taskCreationDate)) {
        const actionButtons =
          noExternalSATLinked &&
          pref?.preferredSecurityTraining === category.customTrainingId
            ? [
                <UploadSecurityTrainingButton
                  key={`upload-security-training-${category.id}`}
                  trainingId={category.customTrainingId}
                  userId={user.id}
                  refetchQueries={[
                    {
                      query: GetUserInfoForOnboardingTabDocument,
                      variables: { userId: user.id },
                    },
                  ]}
                  renderButton={onClick => (
                    <IconButton
                      icon={IconNames.UPLOAD}
                      onClick={onClick}
                      tooltipProps={{ content: "Upload" }}
                      small
                    />
                  )}
                />,
              ]
            : null;

        const rightElements = [
          <TaskDateDetail
            key={`created-detail-${category.id}`}
            date={taskCreationDate}
            icon={IconNames.CLOCK}
            tooltipProps={{
              content: `Created on ${format(
                taskCreationDate,
                TOOLTIP_DATE_FORMAT
              )}`,
            }}
          />,
          <TaskDateDetail
            key={`due-detail-${category.id}`}
            date={taskDueDate}
            isDeadline
            icon={IconNames.FLAG_MEDIUM}
            tooltipProps={{
              content: `Due on ${format(taskDueDate, TOOLTIP_DATE_FORMAT)}`,
            }}
          />,
        ];
        taskList.push(
          <TaskListItem
            key={`${category.id}-training-task`}
            title={`Complete ${category.displayName}`}
            actionButtons={actionButtons}
            rightElements={rightElements}
          />
        );
      }
    });
  }

  if (taskList.length === 0) {
    return null;
  }

  return (
    <OnboardingTasksCard
      title="Default requirements"
      rightElements={
        isActive
          ? [<OnboardingReminderButton key="remind-button" user={user} />]
          : null
      }
    >
      {taskList}
    </OnboardingTasksCard>
  );
};
