import "./vanta-report-sidebar.scss";

import { isSome } from "common/base/types/maybe";
import { GENERIC_REPORT_DATA } from "common/standards/STARTER_REPORT_DATA";
import React, { useState } from "react";

import type { GetDataForReportQuery } from "../../../../gen/components";
import CloseIcon from "../../../../static/images/reports/icon_close.svg";
import MenuIcon from "../../../../static/images/reports/icon_menu_white.svg";
import type { IDomainForReport } from "../../helpers/helpers";
import { VantaReportSidebarDivider } from "./vanta-report-sidebar-divider";
import { VantaReportSidebarLink } from "./vanta-report-sidebar-link";

export const SCROLL_DURATION = 200;
const TOOLBAR_HEIGHT = 72;

interface ISidebarProps {
  domain: IDomainForReport;
  complianceStandard: NonNullable<GetDataForReportQuery["complianceStandard"]>;
  exportToPDFComponent: JSX.Element;
}

export const VantaReportSidebar: React.FunctionComponent<ISidebarProps> = ({
  complianceStandard,
  domain,
  exportToPDFComponent,
}) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const summaryHeadings = ["Summary"];
  const appendixHeadings = Object.values(GENERIC_REPORT_DATA.APPENDICES)
    .map(v => v.name)
    .sort();
  const aboutHeadings = ["About Vanta"];

  const maybeLogo = isSome(domain.companyLogoSlug) ? (
    <div>
      <img
        className="vrp-sidebar-company-logo"
        src={`/doc?s=${domain.companyLogoSlug}`}
      />
    </div>
  ) : (
    <div className="vrp-sidebar-no-logo-spacer" />
  );

  const MenuSVG = menuIsOpen ? CloseIcon : MenuIcon;
  const mobileClass = menuIsOpen ? "vrp-sidebar-open" : "vrp-sidebar-closed";
  const offset = menuIsOpen ? -TOOLBAR_HEIGHT : 0;
  const clickHandler = () => setMenuIsOpen(false);

  const linkForHeading = (heading: string) => (
    <VantaReportSidebarLink
      linkToKey={heading}
      key={heading}
      text={heading}
      onClick={clickHandler}
      offset={offset}
    />
  );

  const sidebarHeading = (
    <div className="vrp-sidebar-heading">
      {maybeLogo}
      <div className="vrp-sidebar-heading-menu-title">
        <MenuSVG
          onClick={() => setMenuIsOpen(!menuIsOpen)}
          className="vrp-menu-icon"
        />
        <div
          className={`vrp-company-name ${sizeClassForCompanyNameLength(
            domain.displayName
          )}`}
        >
          {domain.displayName}
        </div>
        <div className="vrp-updated-timestamp">CONTINUOUSLY UPDATED</div>
      </div>
      {exportToPDFComponent}
    </div>
  );

  return (
    <div className={`vrp-sidebar-container ${mobileClass}`}>
      {sidebarHeading}
      <div className="vrp-sidebar-links-container">
        {summaryHeadings.map(linkForHeading)}
        <VantaReportSidebarDivider />
        {complianceStandard.principles
          .map(principle => principle.name)
          .map(linkForHeading)}
        {appendixHeadings.length > 0 ? <VantaReportSidebarDivider /> : null}
        {appendixHeadings.map(linkForHeading)}
        <VantaReportSidebarDivider />
        {aboutHeadings.map(linkForHeading)}
      </div>
    </div>
  );
};

function sizeClassForCompanyNameLength(companyName: string) {
  const length = companyName.length;
  if (length > 20) {
    return "vrp-very-long";
  }
  if (length > 15) {
    return "vrp-long";
  }
  if (length > 10) {
    return "vrp-mid-length";
  }
  return "";
}
