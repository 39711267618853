import {
  Button,
  Classes,
  Dialog,
  FormGroup,
  Intent,
  Radio,
  RadioGroup,
  TextArea,
} from "@blueprintjs/core";
import { BINARY_MIME_TYPES } from "common/base/types/helpers";
import type { Maybe } from "common/base/types/maybe";
import { dropNothing, isSome } from "common/base/types/maybe";
import { UploadedDocumentType } from "common/schemas/uploadedDocument/docType";
import { PCI_STANDARD_SET } from "common/standards/pci";
import gql from "graphql-tag";
import moment from "moment";
import React, { useContext, useState } from "react";
import styled from "styled-components";

import { BASE_TYPOGRAPHY } from "../../../alpaca/base/typography";
import { LogError } from "../../../errors";
import type { GetVendorsQuery } from "../../../gen/components";
import {
  GetVendorsDocument,
  useUploadVendorDocMutation,
  VendorDocumentType,
} from "../../../gen/components";
import { UI_DATE_FORMAT_WITHOUT_TIME } from "../../../helpers/common";
import { DefaultLink } from "../../../helpers/links";
import { DataTable } from "../components/data-table";
import { FileInput } from "../components/file-input";
import { UserContext } from "../user-context";
import { VENDOR_COPY } from "./copy";
import type { VendorPageVendor } from "./manage-vendors";
import { VendorDocumentsTableActions } from "./vendor-documents-table-actions";

const VENDOR_DOCUMENT_TYPE_TO_TITLE: {
  [k in VendorDocumentType]: string;
} = {
  [VendorDocumentType.assessmentFile]: "Security",
  [VendorDocumentType.baaFile]: "BAA",
  [VendorDocumentType.dpaFile]: "DPA",
  [VendorDocumentType.attestationOfComplianceFile]: "Attestation of Compliance",
};

interface IProps {
  vendor: VendorPageVendor;
}

type VendorDocField =
  | "baaFile"
  | "dpaFile"
  | "assessmentFile"
  | "attestationOfComplianceFile";

export const UploadDocumentsControl: React.FC<IProps> = ({ vendor }) => {
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [inFlight, setInFlight] = useState(false);
  const [selectedFile, setSelectedFile] = useState<Maybe<File>>(null);
  const [assessmentComments, setAssessmentComments] = useState(
    vendor.assessmentComments ?? ""
  );
  const [uploadFn] = useUploadVendorDocMutation({
    update: (cache, result) => {
      const newVendor = result.data?.setVendor;
      if (!isSome(newVendor)) {
        return;
      }
      const previousDomain = cache.readQuery<GetVendorsQuery>({
        query: GetVendorsDocument,
      })?.organization;
      if (!isSome(previousDomain)) {
        return;
      }
      cache.writeQuery<GetVendorsQuery>({
        query: GetVendorsDocument,
        data: {
          organization: {
            ...previousDomain,
            vendors: previousDomain.vendors.map(v => {
              if (v.id === newVendor.id) {
                return { ...v, ...newVendor };
              } else {
                return v;
              }
            }),
          },
        },
      });
      resetAndCloseForm();
    },
  });

  const [selectedRadioButton, setSelectedRadioButton] =
    useState<VendorDocField>("assessmentFile");

  const { domainStandards } = useContext(UserContext);
  const pciEnabled = domainStandards.some(s => PCI_STANDARD_SET.has(s));

  const resetAndCloseForm = () => {
    setSelectedFile(null);
    setDialogIsOpen(false);
    setInFlight(false);
  };

  const dialog = (
    <Dialog
      title={`Upload documents for ${vendor.name}`}
      isOpen={dialogIsOpen}
      onClose={resetAndCloseForm}
    >
      <div className={Classes.DIALOG_BODY}>
        <Section>
          <p>
            Upload the most recent vendor security assessment (e.g.{" "}
            {VENDOR_COPY.EXAMPLE_REVIEW_DOCUMENTS}) and business associate
            agreements (BAA) for this vendor. This is particularly important for
            vendors with which you share sensitive data.
          </p>
        </Section>
        <Section>
          <FileInput
            accept={[BINARY_MIME_TYPES.PDF]}
            name={UploadedDocumentType.VENDOR_ASSESSMENT}
            id="vendor-assessment-upload"
            selectedFile={selectedFile}
            fileUploaded={(name, newFile) => {
              setSelectedFile(newFile);
            }}
          />
        </Section>
        <FormGroup>
          <RadioGroup
            label="What type of document is this?"
            selectedValue={selectedRadioButton}
            onChange={e =>
              setSelectedRadioButton(e.currentTarget.value as VendorDocField)
            }
          >
            <Radio label="Business Associate Agreement" value="baaFile" />
            <Radio label="Data Processing Agreement" value="dpaFile" />
            <Radio
              label={`Security assessment (${VENDOR_COPY.EXAMPLE_REVIEW_DOCUMENTS}), security questionnaire`}
              value="assessmentFile"
            />
            {pciEnabled ? (
              <Radio
                label="PCI DSS Attestation of Compliance"
                value="attestationOfComplianceFile"
              />
            ) : null}
          </RadioGroup>
        </FormGroup>
        {selectedRadioButton === "assessmentFile" ? (
          <Section>
            <p>
              Add comments on how the scope of the assessment and/or any
              exceptions noted in the report may impact your own company's
              security objectives.
            </p>
            <TextArea
              fill
              placeholder="Notes about this vendor's security assessment and the impact this may have on your company"
              value={assessmentComments}
              onChange={e => setAssessmentComments(e.target.value)}
            />
          </Section>
        ) : null}
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button disabled={inFlight} onClick={resetAndCloseForm}>
            Cancel
          </Button>
          <Button
            loading={inFlight}
            intent={Intent.PRIMARY}
            disabled={
              !isSome(selectedFile) ||
              (selectedRadioButton === "assessmentFile" &&
                assessmentComments.trim() === "")
            }
            onClick={() => {
              if (!isSome(selectedFile)) {
                return;
              }
              setInFlight(true);
              const variables = {
                vendorId: vendor.id,
                [selectedRadioButton]: selectedFile,
              };
              if (selectedRadioButton === "assessmentFile") {
                variables.assessmentComments = assessmentComments.trim();
              }
              uploadFn({
                variables,
              }).catch(LogError);
            }}
          >
            Upload and save
          </Button>
        </div>
      </div>
    </Dialog>
  );

  const tableData = dropNothing([
    isSome(vendor.assessmentFile)
      ? { ...vendor.assessmentFile, type: VendorDocumentType.assessmentFile }
      : null,
    isSome(vendor.baaFile)
      ? { ...vendor.baaFile, type: VendorDocumentType.baaFile }
      : null,
    isSome(vendor.dpaFile)
      ? { ...vendor.dpaFile, type: VendorDocumentType.dpaFile }
      : null,
    isSome(vendor.attestationOfComplianceFile)
      ? {
          ...vendor.attestationOfComplianceFile,
          type: VendorDocumentType.attestationOfComplianceFile,
        }
      : null,
  ]);
  const table =
    tableData.length > 0 ? (
      <DataTable
        data={tableData}
        columnOrder={["name", "type", "date", "uploader", "actions"]}
        header={{
          name: "Document name",
          type: "Type",
          date: "Date",
          uploader: "Uploaded by",
          actions: "Actions",
        }}
        createRow={fileData => {
          return {
            name: (
              <DefaultLink
                href={fileData.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {fileData.filename}
              </DefaultLink>
            ),
            type: VENDOR_DOCUMENT_TYPE_TO_TITLE[fileData.type],
            date: moment(fileData.createdAt).format(
              UI_DATE_FORMAT_WITHOUT_TIME
            ),
            uploader: fileData.creator?.displayName ?? "-",
            actions: (
              <VendorDocumentsTableActions
                document={fileData}
                name={VENDOR_DOCUMENT_TYPE_TO_TITLE[fileData.type]}
                type={fileData.type}
                vendorId={vendor.id}
              />
            ),
          };
        }}
      />
    ) : null;
  return (
    <Container>
      <Section>{table}</Section>
      <Button
        style={BUTTON_WIDTH_STYLE}
        intent={Intent.PRIMARY}
        onClick={() => setDialogIsOpen(true)}
      >
        Upload document
      </Button>
      {dialog}
    </Container>
  );
};

gql`
  mutation uploadVendorDoc(
    $vendorId: String!
    $baaFile: Upload
    $dpaFile: Upload
    $assessmentFile: Upload
    $attestationOfComplianceFile: Upload
    $assessmentComments: String
  ) {
    setVendor(
      id: $vendorId
      baaFile: $baaFile
      dpaFile: $dpaFile
      assessmentFile: $assessmentFile
      assessmentComments: $assessmentComments
      attestationOfComplianceFile: $attestationOfComplianceFile
    ) {
      id
      assessmentComments
      baaFile {
        id
        filename
        url
        createdAt
        creator {
          id
          displayName
        }
      }
      dpaFile {
        id
        filename
        url
        createdAt
        creator {
          id
          displayName
        }
      }
      assessmentFile {
        id
        filename
        url
        createdAt
        creator {
          id
          displayName
        }
      }
      attestationOfComplianceFile {
        id
        filename
        url
        createdAt
        creator {
          id
          displayName
        }
      }
    }
  }

  mutation deleteVendorDocument(
    $vendorId: String!
    $documentType: vendorDocumentType!
    $vendorDocumentId: String!
  ) {
    deleteVendorDocument(
      input: {
        vendorId: $vendorId
        documentType: $documentType
        vendorDocumentId: $vendorDocumentId
      }
    ) {
      status
    }
  }
`;

const Section = styled.div`
  margin-bottom: 18px;
`;

const Container = styled.div`
  table {
    thead {
      font-weight: ${BASE_TYPOGRAPHY.FONT_WEIGHTS.BOLD};
    }
  }
`;

export const BUTTON_WIDTH_STYLE = { width: "180px" };
