import React from "react";

import { BodyText } from "../../../../alpaca/components";
import { TabContainer, TabDescription } from "../common/components";
import { GCPContainerRepositoryList } from "./gcp-container-repository-list";

export const GCPVulnDisplay: React.FC = () => (
  <>
    <TabDescription>
      <BodyText>
        Monitor your GCR repositories for vulnerabilities surfaced by image
        scans.
      </BodyText>
    </TabDescription>
    <TabContainer>
      <GCPContainerRepositoryList />
    </TabContainer>
  </>
);
