import { Button } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React from "react";

import { Tooltip } from "../../alpaca/components";

import { writeToClipboard } from "../../helpers";

interface IProps {
  text: string;
}

export const CopyClipboardIcon: React.FC<IProps> = ({ text }) => (
  <Tooltip content="Copy to clipboard">
    <Button
      minimal
      icon={IconNames.CLIPBOARD}
      onClick={async () => {
        await writeToClipboard(text);
      }}
    />
  </Tooltip>
);
