import { simplePlural } from "common/grammar/plurals";
import React from "react";

import { H4 } from "../../../../alpaca/components";

interface IProps {
  searchString: string;
  numberResults: number;
}

export const SearchResultsSummary: React.FC<IProps> = ({
  searchString,
  numberResults,
}) => {
  if (searchString.trim() === "") {
    return null;
  } else {
    return (
      <H4>
        {simplePlural(numberResults, "result")} matching "{searchString}"
      </H4>
    );
  }
};
