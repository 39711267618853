import { AnchorButton, Dialog, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { isSome } from "common/base/types/maybe";
import React, { useState } from "react";
import { Tooltip } from "../../../alpaca/components";

import type { GetVendorsQuery } from "../../../gen/components";
import { UserContext } from "../user-context";
import { RequestVAQDialogForm } from "./request-vaq-dialog-form";
import { BUTTON_WIDTH_STYLE } from "./upload-documents-control";

interface IProps {
  vendor: NonNullable<GetVendorsQuery["organization"]>["vendors"][number];
}

export const RequestVAQButton = (props: IProps) => {
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const { vendor } = props;
  const buttonIsDisabled =
    !isSome(vendor.contactEmail) || !isSome(vendor.contactName);
  const requestButton = (
    <AnchorButton
      disabled={buttonIsDisabled}
      intent={Intent.PRIMARY}
      onClick={() => {
        setDialogIsOpen(true);
      }}
      style={BUTTON_WIDTH_STYLE}
      rightIcon={IconNames.SHARE}
    >
      Send questionnaire
    </AnchorButton>
  );
  const requestButtonMaybeWithTooltip = buttonIsDisabled ? (
    <Tooltip content="An account manager's contact information is necessary for the questionnaire">
      {requestButton}
    </Tooltip>
  ) : (
    requestButton
  );

  return (
    <React.Fragment>
      {requestButtonMaybeWithTooltip}
      <Dialog
        isOpen={dialogIsOpen}
        title={`Request security information from ${vendor.name}`}
        onClose={() => setDialogIsOpen(false)}
      >
        <UserContext.Consumer>
          {({ domainDisplayName }) => (
            <RequestVAQDialogForm
              vendor={vendor}
              domainDisplayName={domainDisplayName}
              onClose={() => setDialogIsOpen(false)}
            />
          )}
        </UserContext.Consumer>
      </Dialog>
    </React.Fragment>
  );
};
