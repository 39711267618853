import { css } from "styled-components";

import { BASE_PALETTE } from "../../base/colors";

export const ButtonClasses = {
  /**
   * The default button class.
   */
  BUTTON: "alpaca-button",
  /**
   * The default icon button class.
   */
  ICON_BUTTON: "alpaca-icon-button",
  /**
   * Classes that affect content in buttons: text, icons, spinners, and numbers.
   */
  TEXT: "alpaca-button-text",
  ICON: "alpaca-button-icon",
  SPINNER: "alpaca-button-spinner",
  NUMBER: "alpaca-button-number",
  /**
   * Classes that manage the button's size. These classes override the
   * button's padding and dimensions, as well as the font size of the text.
   */
  LARGE: "alpaca-button-large",
  SMALL: "alpaca-button-small",
  /**
   * Classes that manage the colors of the buttons. These classes override
   * the background and border color of the button and the color of the text,
   * and can specify these properties for hover states as well.
   */
  PRIMARY: "alpaca-button-primary",
  DANGER: "alpaca-button-danger",
  /**
   * Disabled state class. This modifies the opacity of the button.
   */
  DISABLED: "alpaca-button-disabled",
  /**
   * Loading state class. This hides any content.
   */
  LOADING: "alpaca-button-loading",
};

export const BUTTON_CLASS_STYLES = css`
  &.${ButtonClasses.BUTTON} {
    /* layout properties */
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 12px;
    height: 32px;
    border-radius: 4px;
    box-sizing: border-box;

    /* default button color properties */
    background: ${BASE_PALETTE.SNOW};
    border: 1px solid ${BASE_PALETTE.FOG};
    box-shadow: 0px 1px 4px rgba(51, 51, 51, 0.08);
    color: ${BASE_PALETTE.INK};

    /* hover & focus transition */
    transition: box-shadow 150ms ease-out;

    &:hover {
      box-shadow: 0px 2px 6px rgba(51, 51, 51, 0.12);
      text-decoration: none;
    }
    cursor: pointer;

    &:focus {
      box-shadow: 0px 0px 0px 4px rgba(135, 92, 255, 0.12),
        0px 2px 6px rgba(51, 51, 51, 0.12);
    }

    /* default text properties */
    .${ButtonClasses.TEXT} {
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      letter-spacing: 0.01em;

      flex: none;
      flex-grow: 0;
    }

    /* default icon properties */
    .${ButtonClasses.ICON} {
      flex: none;
      flex-grow: 0;

      svg {
        width: 12px;
        height: 12px;
        fill: ${BASE_PALETTE.CHARCOAL};
        color: ${BASE_PALETTE.CHARCOAL};
      }
    }

    /* default number properties */
    .${ButtonClasses.NUMBER} {
      display: flex;
      align-items: center;
      border-left: 1px solid ${BASE_PALETTE.WIND};
      height: 20px;
      padding-left: 8px;

      font-size: 14px;
      line-height: 16px;
      color: ${BASE_PALETTE.GRAPE};
    }
  }

  &.${ButtonClasses.SMALL} {
    padding: 4px 8px;
    height: 24px;

    .${ButtonClasses.TEXT} {
      font-size: 12px;
      line-height: 16px;
    }

    .${ButtonClasses.NUMBER} {
      height: 16px;

      font-size: 12px;
      line-height: 12px;
    }
  }

  &.${ButtonClasses.LARGE} {
    padding: 12px 16px;
    height: 40px;

    .${ButtonClasses.TEXT} {
      font-size: 14px;
      line-height: 16px;
    }

    .${ButtonClasses.NUMBER} {
      height: 24px;
    }
  }

  &.${ButtonClasses.PRIMARY} {
    border: none;
    background: ${BASE_PALETTE.GRAPE};
    color: ${BASE_PALETTE.SNOW};

    .${ButtonClasses.ICON} {
      svg {
        fill: ${BASE_PALETTE.SNOW};
        color: ${BASE_PALETTE.SNOW};
      }
    }
  }

  &.${ButtonClasses.DANGER} {
    border: none;
    background: ${BASE_PALETTE.TOMATO};
    color: ${BASE_PALETTE.SNOW};

    &:focus {
      box-shadow: 0px 0px 0px 4px rgba(237, 72, 72, 0.12),
        0px 2px 6px rgba(51, 51, 51, 0.12);
    }

    .${ButtonClasses.ICON} {
      svg {
        fill: ${BASE_PALETTE.SNOW};
        color: ${BASE_PALETTE.SNOW};
      }
    }
  }

  &.${ButtonClasses.DISABLED} {
    cursor: not-allowed;
    opacity: 0.6;
  }

  &.${ButtonClasses.LOADING} {
    .${ButtonClasses.SPINNER} {
      margin: 0px;
      // Center the Spinner within the button
      position: absolute;
    }

    // Hide anything that isn't the spinner
    > :not(.${ButtonClasses.SPINNER}) {
      visibility: hidden;
    }
  }
`;

export const ICON_BUTTON_CLASS_STYLES = css`
  &.${ButtonClasses.ICON_BUTTON} {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    width: 32px;
    height: 32px;

    background: none;
    border: none;

    .${ButtonClasses.ICON} {
      svg {
        width: 16px;
        height: 16px;
        fill: ${BASE_PALETTE.CHARCOAL};
        color: ${BASE_PALETTE.CHARCOAL};
      }
    }

    &:hover :not(.${ButtonClasses.DISABLED}) {
      background: rgba(145, 150, 158, 0.16);

      .${ButtonClasses.ICON} {
        svg {
          fill: ${BASE_PALETTE.INK};
          color: ${BASE_PALETTE.INK};
        }
      }
    }

    cursor: pointer;
  }

  &.${ButtonClasses.LARGE} {
    width: 40px;
    height: 40px;
    border-radius: 4px;

    .${ButtonClasses.ICON} {
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  &.${ButtonClasses.SMALL} {
    width: 24px;
    height: 24px;

    .${ButtonClasses.ICON} {
      svg {
        width: 12px;
        height: 12px;
      }
    }
  }

  &.${ButtonClasses.DISABLED} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
