import type { Maybe } from "common/base/types/maybe";
import styled, { css } from "styled-components";

import { VANTA_COLORS } from "../../../alpaca/base/colors";

export const TableHeading = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  color: #5b5b5b;
`;

export const TableNormalText = styled.div<{
  alert?: Maybe<boolean>;
  active?: Maybe<boolean>;
}>`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.012em;
  width: 100%;

  color: ${({ alert }) =>
    Boolean(alert) ? VANTA_COLORS.VANTA_ALERT : VANTA_COLORS.TEXT_DESCRIPTION};
  ${({ active }) =>
    Boolean(active)
      ? css`
          color: ${VANTA_COLORS.TEXT_NORMAL};
        `
      : ""}
`;

export const TIME_FORMAT = "M/D/YYYY";

const tableStyles = {
  MIN_ROW_HEIGHT: 30,
  CORNER_RADIUS: 4,
  TITLE_MARGIN_BOTTOM: 18,
  TABLE_HEAD_SVG_OFFSET: 4,
};

export const TABLE_STYLES = css`
  width: 100%;
  border: 1px solid ${VANTA_COLORS.BORDER_LIGHT};
  border-radius: ${tableStyles.CORNER_RADIUS}px;
  td > * {
    min-height: ${tableStyles.MIN_ROW_HEIGHT}px;
    display: inline-flex;
    align-items: center;
  }
  tr:not(:last-child) > td {
    border-bottom: 1px solid ${VANTA_COLORS.BORDER_LIGHT};
  }
  thead svg {
    position: relative;
    top: ${tableStyles.TABLE_HEAD_SVG_OFFSET}px;
  }
`;

export const TableTitle = styled.div`
  margin-bottom: ${tableStyles.TITLE_MARGIN_BOTTOM}px;
`;
