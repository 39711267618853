import React from "react";

import { DefaultLink } from "../../../../helpers/links";
import { SERVICE_DETAILS } from "../../../credentials/service-groups";
import { SimpleCredentialFormDialogBody } from "../simple-credential-form-dialog-body";
import type { IFormCredentialProps } from "./common-interface";

export const Kandji: React.FunctionComponent<IFormCredentialProps> = ({
  onCredentialsLinked,
}) => {
  const apiHelperText = (
    <div>
      Kandji makes the Vanta integration available for free. To activate your
      integration and obtain an API token, please reach out to your Kandji
      representative or contact support@kandji.io. Once you receive the token,
      configure it to have the following APIs enabled:{"\n\n"}
      Device list - {"/devices"}
      {"\n"}
      Device - {"/devices/{device_id}"}
      {"\n"}
      Device details - {"/devices/{device_id}/details"}
      {"\n"}
      Application list - {"/devices/{device_id}/apps"}
      {"\n"}
      Device library items - {"/devices/{device_id}/library-items"}
      {"\n\n"}
      Read the{" "}
      <DefaultLink href="https://support.kandji.io/api">
        Kandji support page
      </DefaultLink>{" "}
      for more information.
    </div>
  );
  const apiUrlHelperText = (
    <span>
      You can find your Kandji API URL in Settings {">"} Access in your Kandji
      dashboard. Typically your API URL will follow the format of:
      https://SubDomain.clients.us-1.kandji.io. Read the{" "}
      <DefaultLink href="https://api.kandji.io/">API page</DefaultLink> for more
      information.
    </span>
  );
  return (
    <SimpleCredentialFormDialogBody
      labels={[
        { name: "token", displayName: "API Token", helperText: apiHelperText },
        {
          name: "subdomain",
          displayName: "Kandji API URL",
          helperText: apiUrlHelperText,
        },
      ]}
      service={SERVICE_DETAILS.kandji}
      shouldTestCredentials={true}
      buttonLabel={"Validate and store"}
      onCredentialsLinked={onCredentialsLinked}
    />
  );
};
