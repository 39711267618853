import type { IPanelProps } from "@blueprintjs/core";
import { Button, Intent } from "@blueprintjs/core";
import React from "react";

import { LogError } from "../../../../errors";
import {
  useSetCredentialsMutation,
  useTestCredentialsMutation,
} from "../../../../gen/components";
import { DefaultLink } from "../../../../helpers/links";
import { AppToaster } from "../../../../helpers/toaster";
import { SERVICE_DETAILS } from "../../../credentials/service-groups";
import { IdentityProviderDisclaimer } from "../identity-provider-disclaimer";
import type { IInputValues } from "../simple-credential-form-dialog-body";
import { SimpleCredentialFormDialogBody } from "../simple-credential-form-dialog-body";
import type { IProps, OktaPanel } from "./okta";
import { CreateOktaApp } from "./okta-app";

const LinkOktaComponent: React.FC<IPanelProps & IProps> = props => {
  const [setCredentials, { loading: setCredsLoading }] =
    useSetCredentialsMutation();
  const [testCredentials, { loading: testCredsLoading }] =
    useTestCredentialsMutation();

  const saveButton: React.FC<{
    canSubmit: boolean;
    inputValues: IInputValues;
  }> = ({ canSubmit, inputValues }) => (
    <Button
      intent={Intent.PRIMARY}
      loading={setCredsLoading || testCredsLoading}
      disabled={!canSubmit || setCredsLoading || testCredsLoading}
      onClick={async () => {
        const userInput = inputValues;

        const credentials = JSON.stringify({
          credentials: {
            token: userInput.token,
          },
          metadata: {
            orgUrl: userInput.orgUrl,
          },
        });

        try {
          // Validate the info
          const result = await testCredentials({
            variables: { credentials, service: "okta" },
          });

          if (result.errors) {
            throw new Error(JSON.stringify(result.errors));
          }

          if (result.data?.testCredentials.success) {
            await setCredentials({
              variables: {
                credentials: JSON.stringify({
                  token: userInput.token,
                  orgUrl: userInput.orgUrl,
                }),
                service: "okta",
              },
            });
            AppToaster.show({
              icon: "tick",
              intent: Intent.SUCCESS,
              message: "Credentials validated",
              timeout: 2500,
            });

            props.openPanel(CreateOktaApp);
          } else {
            AppToaster.show({
              icon: "cross",
              intent: Intent.WARNING,
              message: result.data?.testCredentials.message,
              timeout: 5000,
            });
          }
        } catch (err) {
          LogError(err);
        }
      }}
    >
      Validate and store
    </Button>
  );

  return (
    <SimpleCredentialFormDialogBody
      showTitle={false}
      additionalContent={<IdentityProviderDisclaimer newProvider="okta" />}
      labels={[
        {
          name: "token",
          displayName: "API Token",
          type: "password",
          helperText: (
            <span>
              Create an API token by following this Okta{" "}
              <DefaultLink href="https://developer.okta.com/docs/guides/create-an-api-token/overview/">
                guide
              </DefaultLink>
            </span>
          ),
        },
        {
          name: "orgUrl",
          displayName: "Okta Domain",
          placeholder: "example.okta.com",
          helperText: (
            <span>
              Find your Okta domain on your developer console using this{" "}
              <DefaultLink href="https://developer.okta.com/docs/guides/find-your-domain/overview/">
                guide
              </DefaultLink>{" "}
              from Okta. It will typically be companyname.okta.com
            </span>
          ),
        },
      ]}
      OverrideSaveButton={saveButton}
      service={SERVICE_DETAILS.okta}
    />
  );
};

export const LinkOkta: OktaPanel = {
  title: "Link Okta",
  component: LinkOktaComponent,
};
