import { Classes } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import React from "react";

interface IProps {
  displayName: Maybe<string>;
  email: string;
}

export class NameEmailDisplay extends React.Component<IProps> {
  public render() {
    if (!isSome(this.props.displayName)) {
      return <span>{this.props.email}</span>;
    }
    return (
      <span className="name-email-display">
        {this.props.displayName}
        <br />
        <span className={`${Classes.TEXT_MUTED}`}>
          <small>{this.props.email}</small>
        </span>
      </span>
    );
  }
}
