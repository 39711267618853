import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import React from "react";
import styled from "styled-components";
import { Tooltip } from "../../../../alpaca/components";

import { DefaultLink } from "../../../../helpers/links";

const StyledTooltip = styled(Tooltip)`
  &:hover {
    text-decoration: underline;
  }
`;

const LinkForCVE: React.FC<{
  name: string;
  uri?: Maybe<string>;
  description?: Maybe<string>;
}> = ({ name, uri, description }) => (
  <DefaultLink
    target="_blank"
    rel="noopener noreferrer"
    // If there is no URI provided, assume it is a CVE so link to NIST
    href={isSome(uri) ? uri : `https://nvd.nist.gov/vuln/detail/${name}`}
  >
    {isSome(description) ? (
      <StyledTooltip content={description} interactionKind="hover">
        {name}
      </StyledTooltip>
    ) : (
      name
    )}
  </DefaultLink>
);

interface IProps {
  cves: Array<{
    name: string;
    uri?: Maybe<string>;
    description?: Maybe<string>;
  }>;
}

export const LinksForCVEs: React.FC<IProps> = ({ cves }) => (
  <div>
    {cves.map((cve, index) => (
      <React.Fragment key={`${cve}_${index}`}>
        <LinkForCVE
          name={cve.name}
          uri={cve.uri}
          description={cve.description}
        />
        {index < cves.length - 1 ? <span>, </span> : null}
      </React.Fragment>
    ))}
  </div>
);
