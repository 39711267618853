import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import React from "react";

import { VulnerabilityPageInfo } from "../common/vulnerability-page-info";

interface IProps {
  repositoryName: string;
  repositoryUniqueId: string;
  projectId: string;
  scannedImageDigest?: Maybe<string>;
  imageTags?: Maybe<string[]>;
}

export const GCPContainerRepositoryInfo: React.FC<IProps> = ({
  repositoryName,
  repositoryUniqueId,
  projectId,
  scannedImageDigest,
  imageTags,
}) => {
  const leftTablePairs = [
    { key: "repository name", value: repositoryName },
    { key: "repository id", value: repositoryUniqueId },
    { key: "gcp project id", value: projectId },
  ];
  if (isSome(scannedImageDigest)) {
    leftTablePairs.push({
      key: "image digest scanned",
      value: scannedImageDigest,
    });
  }
  if (isSome(imageTags) && imageTags.length > 0) {
    leftTablePairs.push({ key: "image tags", value: imageTags.join(", ") });
  }
  return (
    <VulnerabilityPageInfo
      leftTablePairs={leftTablePairs}
      leftColumnWidths={["160px", "600px"]}
    />
  );
};
