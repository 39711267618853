import { Menu, MenuItem, Overlay } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import { VANTA_COLORS } from "../../../alpaca/base/colors";
import { MetaText, Tooltip } from "../../../alpaca/components";
import { Dimensions } from "../constants";
import { Muted } from "./user-info";

interface IDropdownMenuProps {
  icon: JSX.Element;
  content: JSX.Element;
  activeOverride?: Maybe<boolean>;
  tooltipText?: Maybe<string>;
}

export const DropdownMenu: React.FC<IDropdownMenuProps> = ({
  icon,
  content,
  activeOverride,
  tooltipText,
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [hovering, setHovering] = useState(false);

  const affordance = (
    <HoverContainer
      isActive={showMenu || (activeOverride ?? false)}
      onMouseOver={() => {
        setHovering(true);
      }}
      onMouseLeave={() => {
        setHovering(false);
      }}
      onClick={() => {
        setShowMenu(!showMenu);
      }}
    >
      {icon}
    </HoverContainer>
  );

  return (
    <>
      {isSome(tooltipText) ? (
        <Tooltip
          placement="bottom"
          content={<MetaText>{tooltipText}</MetaText>}
        >
          {affordance}
        </Tooltip>
      ) : (
        affordance
      )}

      <Overlay
        isOpen={showMenu}
        onClose={e => {
          if (!hovering) {
            setShowMenu(false);
          }
        }}
        hasBackdrop={false}
        usePortal={false}
        transitionDuration={100}
      >
        <MenuContainer>{content}</MenuContainer>
      </Overlay>
    </>
  );
};

interface ISimpleMenuListProps {
  menuOptions: Array<
    | {
        text: string;
        path: string;
        isExternal: boolean;
      }
    | { text: string }
  >;
}

export const SimpleMenuList: React.FC<ISimpleMenuListProps> = ({
  menuOptions,
}) => (
  <MenuBox>
    <StyledMenu>
      {menuOptions.map(option => {
        if ("path" in option) {
          return (
            <StyledMenuItem
              key={`mi_${option.text}`}
              href={option.path}
              target={option.isExternal ? "_blank" : undefined}
              rel={option.isExternal ? "noopener noreferrer" : undefined}
              text={option.text}
            />
          );
        }
        return <StyledMenuItem key={`mi_${option.text}`} text={option.text} />;
      })}
    </StyledMenu>
  </MenuBox>
);

export const StyledLink = styled(Link)`
  && {
    color: ${VANTA_COLORS.TEXT_NORMAL};
  }
  &:hover {
    text-decoration: none;
    & > div {
      background-color: ${VANTA_COLORS.BACKGROUND_PURPLE};
    }
    ${Muted} {
      color: ${VANTA_COLORS.VANTA_PURPLE};
    }
  }
`;

export const Padded = styled.div`
  margin: 6px 0px;
  & > a > div {
    padding: ${Dimensions.ACCOUNT_MENU_TOP_PADDING / 3}px
      ${Dimensions.ACCOUNT_MENU_SIDE_PADDING}px;
  }
`;

export const SmallSpacer = styled.div`
  margin: ${Dimensions.ACCOUNT_MENU_SPACER_MARGIN}px
    ${Dimensions.ACCOUNT_MENU_SIDE_PADDING}px;
  height: 1px;
  background-color: ${VANTA_COLORS.MENU_BORDER};
`;

const MenuBox = styled.div`
  &&& > div:last-child {
    margin-bottom: 0px;
  }
`;

export const Container = styled.div`
  font-size: 12px;
  padding-bottom: 8px;
  max-height: calc(
    100vh - ${Dimensions.TOP_BAR_HEIGHT + Dimensions.ACCOUNT_MENU_TOP_MARGIN}px
  );
  overflow-y: auto;
`;

const StyledMenu = styled(Menu)`
  padding: 0px;
  text-transform: uppercase;
`;

const StyledMenuItem = styled(MenuItem)`
  padding: 8px ${Dimensions.ACCOUNT_MENU_SIDE_PADDING}px;
  display: flex;
  align-items: center;
  height: ${Dimensions.ACCOUNT_MENU_ITEM_HEIGHT}px;
  color: ${VANTA_COLORS.TEXT_MUTED};
  &:hover {
    color: ${VANTA_COLORS.VANTA_PURPLE};
    background-color: ${VANTA_COLORS.BACKGROUND_PURPLE};
  }
`;

const HoverContainer = styled.div<{ isActive?: Maybe<boolean> }>`
  height: 100%;
  display: flex;
  align-items: center;
  border-bottom: 2px solid transparent;
  padding: 0px 6px;
  &:hover {
    cursor: pointer;
    border-bottom: 2px solid ${VANTA_COLORS.TEXT_NORMAL};
  }
  ${({ isActive }) => {
    if (isActive) {
      return css`
        border-bottom: 2px solid ${VANTA_COLORS.VANTA_PURPLE};
      `;
    } else {
      return "";
    }
  }}
`;

const MenuContainer = styled.div`
  background: ${VANTA_COLORS.BACKGROUND_WHITE};
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.08),
    inset 0 1px 0px rgba(0, 0, 0, 0.12);
  width: ${Dimensions.ACCOUNT_MENU_WIDTH}px;
  position: absolute;
  top: ${Dimensions.TOP_BAR_HEIGHT}px;
  right: 0;
`;
