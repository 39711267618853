import type { Maybe } from "common/base/types/maybe";
import React from "react";

import { LoginButton } from "../login";
import { MicrosoftIcon } from "./icon";

export const MicrosoftLoginButton: React.FC<{ loginLink?: Maybe<string> }> = ({
  loginLink,
}) => (
  <LoginButton
    text={"Sign in with Microsoft"}
    Icon={MicrosoftIcon}
    loginLink={loginLink}
  />
);
