import { Intent } from "@blueprintjs/core";
import { getPolicyTypeForTitle } from "common/base/types/helpers";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import React, { useState } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";

import { VANTA_COLORS } from "../../../alpaca/base/colors";
import { GRID_SPACING } from "../../../alpaca/base/grid";
import { Button, HelperText } from "../../../alpaca/components";
import { LogError } from "../../../errors";
import {
  ManagePoliciesV2Document,
  useAddPolicyTypeMutation,
} from "../../../gen/components";
import { doubleEncodeURIComponent } from "../../../helpers";
import { SchemaFormControl } from "../../forms/schema-form-control";
import { Dialog, DialogBody } from "./policies-v2-styles";

export const AddPolicyDialog: React.FC<{
  domainId: string;
  isOpen: boolean;
  onClose: () => void;
  policyTypes: string[];
}> = ({ domainId, isOpen, onClose, policyTypes }) => {
  const [addPolicyType] = useAddPolicyTypeMutation({
    refetchQueries: [{ query: ManagePoliciesV2Document }],
  });
  const [description, setDescription] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [validationError, setValidationError] = useState<string>("");
  const history = useHistory();

  const _onClose = () => {
    setDescription("");
    setTitle("");
    setValidationError("");
    onClose();
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={() => _onClose()}
      title="Create a new policy"
    >
      <DialogBody>
        <SchemaFormControl
          currentValue={title}
          intent={isSome(validationError) ? Intent.DANGER : Intent.NONE}
          onNewValue={(value: string) => {
            setTitle(value);
            if (policyTypes.includes(getPolicyTypeForTitle(value))) {
              setValidationError("There is already a policy with this title");
            } else if (value.includes("%")) {
              setValidationError(
                `Policy titles cannot contain the "%" character`
              );
            } else {
              setValidationError("");
            }
          }}
          question={{
            helper: "",
            id: "policyTitle",
            label: "Policy title",
            name: "policyTitle",
            type: "TextInput",
          }}
        >
          <HelperText color={VANTA_COLORS.VANTA_ALERT}>
            {validationError}
          </HelperText>
        </SchemaFormControl>
        <SchemaFormControl
          currentValue={description}
          intent={Intent.NONE}
          onNewValue={v =>
            isSome(v) && typeof v === "string" && setDescription(v)
          }
          question={{
            helper: "",
            id: "description",
            label: "Description",
            name: "description",
            type: "TextArea",
          }}
        />
        <StyledFooter>
          <StyledButton large={true} onClick={() => _onClose()}>
            Cancel
          </StyledButton>
          <StyledButton
            disabled={
              description.trim() === "" ||
              title.trim() === "" ||
              validationError !== ""
            }
            intent={Intent.SUCCESS}
            large={true}
            onClick={async () => {
              try {
                const { data } = await addPolicyType({
                  variables: { description, title, domainId },
                });
                if (isSome(data)) {
                  history.push(
                    `/policies/${doubleEncodeURIComponent(
                      data.addPolicyDocStub.policyType
                    )}`
                  );
                }
              } catch (err) {
                LogError(err);
              }
            }}
          >
            Create
          </StyledButton>
        </StyledFooter>
      </DialogBody>
    </Dialog>
  );
};

const StyledButton = styled(Button)`
  margin: 0 ${GRID_SPACING}px;
`;

const StyledFooter = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: ${4 * GRID_SPACING}px;
`;

gql`
  mutation addPolicyType(
    $domainId: ID!
    $title: String!
    $description: String!
  ) {
    addPolicyDocStub(
      domainId: $domainId
      title: $title
      description: $description
    ) {
      policyType
      description
      title
    }
  }
`;
