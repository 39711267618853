import "./risk-register.scss";

import {
  Button,
  Card,
  Classes,
  Dialog,
  FormGroup,
  H2,
  H4,
  H5,
  Icon,
  InputGroup,
  Intent,
  Switch,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import React from "react";
import { Link } from "react-router-dom";

import { LogError, LogErrorMessage } from "../../../errors";
import type {
  FetchRiskRegisterQuery,
  MarkRiskTaskAsCompleteMutationFn,
  MarkRiskTaskAsDismissedMutationFn,
  SetSeenRiskRegisterSplashMutationFn,
} from "../../../gen/components";
import {
  useFetchRiskRegisterQuery,
  useMarkRiskTaskAsCompleteMutation,
  useMarkRiskTaskAsDismissedMutation,
  useSetSeenRiskRegisterSplashMutation,
} from "../../../gen/components";
import { CollapsibleSection } from "../../helpers/CollapsibleSection";
import { FullPageSpinner } from "../../helpers/FullPageSpinner";
import {
  PageHeadingInfo,
  VantaDashboardPage,
} from "../../vanta-chrome/page-content/vanta-dashboard-page";
import { AddScenarioDialog } from "./add-scenario-dialog";
import { ScenarioCard } from "./scenario-card";

interface IProps {
  domain: NonNullable<FetchRiskRegisterQuery["organization"]>;
  user: NonNullable<FetchRiskRegisterQuery["user"]>;
  completeTask: MarkRiskTaskAsCompleteMutationFn;
  dismissTask: MarkRiskTaskAsDismissedMutationFn;
  clearSplash: SetSeenRiskRegisterSplashMutationFn;
}

interface IState {
  showCompletedQuestions: boolean;
  showCompletedTasks: boolean;
  dismissDialog: boolean;
  dismissReason: string;
  dismissId: string;
  showScenarioDialog: boolean;
  scenarioToEdit?: Maybe<
    NonNullable<
      FetchRiskRegisterQuery["organization"]
    >["riskRegister"]["scenarios"][0]
  >;
}

class ManageRisks extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
    this.state = {
      showCompletedQuestions: false,
      showCompletedTasks: false,
      dismissDialog: false,
      dismissReason: "",
      dismissId: "",
      showScenarioDialog: false,
    };
  }

  public render() {
    if (!isSome(this.props.domain) || !isSome(this.props.user)) {
      return <FullPageSpinner />;
    }
    const user = this.props.user;

    // assumed users (vantans and auditors) don't need to see the splash
    if (
      !user.isAssumedUser &&
      (!isSome(user.seenRiskRegisterSplash) || !user.seenRiskRegisterSplash)
    ) {
      return (
        <VantaDashboardPage headingInfo={PageHeadingInfo.RISK_REGISTER}>
          <H2>Getting started</H2>
          <p>
            This tool will help you determine open risks, the problems that
            might arise from those risks, as well as the mitigation tasks that
            might solve those problems.
          </p>

          <div className="rr-steps-table">
            <div className="box-numbers">1</div>
            <div>
              <H4>Answer all questions</H4>
              These questions will help to identify any potential risks.
              Questions are organized by category and can be completed in any
              order.
            </div>
            <div className="box-numbers">2</div>
            <div>
              <H4>Identify risk scenarios and mitigation tasks</H4>
              Certain answers to questions with identify potential risks and
              guide you through describing those risks along with the tasks
              which could mitigate them
            </div>
            <div className="box-numbers">3</div>
            <div>
              <H4>Generate Risk Report</H4>
              When all questions are answered, the report brings together risks
              and tasks so your company knows where it stands, but the issues
              uncovered by your answers impact your SOC2 report.
            </div>
          </div>

          <Button
            intent={Intent.PRIMARY}
            large
            text="Get Started"
            onClick={() => {
              if (isSome(this.props.clearSplash)) {
                this.props
                  .clearSplash({ variables: { seen: true, userId: user.id } })
                  .catch(LogError);
              }
            }}
          />
        </VantaDashboardPage>
      );
    }

    const domain = this.props.domain;

    const questions = domain.riskRegister.questions;
    const questionsByCategory = questions.reduce((acc, cur) => {
      const category = cur.category ?? "No category";
      const existing = isSome(acc[category]) ? acc[category] : [];
      acc[category] = [...existing, cur];
      return acc;
    }, {} as { [category: string]: NonNullable<FetchRiskRegisterQuery["organization"]>["riskRegister"]["questions"] });

    const maybeDismissDialog = this.state.dismissDialog ? (
      <Dialog
        className="dismiss-scenario"
        isOpen={true}
        title="Won't Complete Task"
        usePortal={true}
        onOpened={() => this.setState({ dismissReason: "" })}
        onClose={() => this.setState({ dismissDialog: false })}
      >
        <form
          onSubmit={() => {
            if (isSome(this.props.dismissTask)) {
              this.props
                .dismissTask({
                  variables: {
                    id: this.state.dismissId,
                    dismissReason: this.state.dismissReason,
                  },
                })
                .then(() =>
                  this.setState({ dismissReason: "", dismissDialog: false })
                )
                .catch(LogError);
            }
          }}
        >
          <div className={Classes.DIALOG_BODY}>
            <FormGroup label="Reason for closing">
              <InputGroup
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  this.setState({ dismissReason: e.target.value });
                }}
                placeholder={
                  "Provide a reason why you won't implement this task"
                }
                value={this.state.dismissReason}
                autoFocus={true}
              />
            </FormGroup>
          </div>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              text="Close Task"
              type="submit"
              disabled={this.state.dismissReason === ""}
            />
          </div>
        </form>
      </Dialog>
    ) : null;

    const addScenarioDialog = this.state.showScenarioDialog ? (
      <AddScenarioDialog
        onCloseDialog={() => {
          this.setState({ showScenarioDialog: false, scenarioToEdit: null });
        }}
        domain={domain}
        scenarioToEdit={this.state.scenarioToEdit}
      />
    ) : undefined;

    const additionalScenarios = domain.riskRegister.scenarios.filter(
      s => !isSome(s.questionId)
    );

    const scenarios = (
      <CollapsibleSection
        startsOpen={true}
        headline="Other Scenarios"
        key="Other"
        content={
          <div>
            <div className="spead-components additional-scenarios-header">
              <H5>Additional scenarios</H5>
              <Button
                text="Add additional scenario"
                onClick={() => this.setState({ showScenarioDialog: true })}
              />
            </div>

            {additionalScenarios.length === 0 ? (
              <div>
                Please describe any (at least one) additional risks to your
                business that you have considered that were not included in the
                risk register. Create additional scenarios using the "Add
                additional scenario" button.
              </div>
            ) : (
              additionalScenarios.map(s => (
                <ScenarioCard
                  key={s.id}
                  scenario={s}
                  editClick={() =>
                    this.setState({
                      showScenarioDialog: true,
                      scenarioToEdit: s,
                    })
                  }
                />
              ))
            )}
          </div>
        }
      />
    );

    function remainingQuestions(category: string) {
      return questionsByCategory[category].filter(
        q => !(q.complete || q.dismissed)
      ).length;
    }
    const firstIncomplete = Object.keys(questionsByCategory).findIndex(
      c => remainingQuestions(c) > 0
    );

    const questionCards = Object.keys(questionsByCategory).map(
      (category, index) => {
        const remaining = remainingQuestions(category);
        const remainingText =
          remaining === 0 ? "Complete" : `${remaining} remaining`;

        const headline = (
          <span>
            <span>{category}</span>
            <span className="float-right">{remainingText}</span>
          </span>
        );

        return remaining === 0 && !this.state.showCompletedQuestions ? (
          <section key={category}>
            <h4 className={"cursor-pointer"}>
              <Button className={Classes.MINIMAL} icon="chevron-right" />
              {headline}
            </h4>
          </section>
        ) : (
          <div key={category}>
            <CollapsibleSection
              startsOpen={index === firstIncomplete}
              headline={headline}
              content={
                <div key={category}>
                  {questionsByCategory[category].map(q =>
                    (q.complete || q.dismissed) &&
                    !this.state.showCompletedQuestions ? undefined : (
                      <Card
                        className="spead-components risk-register-card"
                        key={q.id}
                      >
                        {q.text}
                        <Link
                          className="fixed-column"
                          to={`/risk-register/question/${q.id}`}
                        >
                          <Button>
                            {q.complete || q.dismissed ? "Edit" : "Start"}
                          </Button>
                        </Link>
                      </Card>
                    )
                  )}
                </div>
              }
            />
          </div>
        );
      }
    );

    const taskCards = domain.riskRegister.tasks.map(t => {
      const showCompleted = this.state.showCompletedTasks;
      const completedOrDismissed =
        isSome(t.completedAt) || isSome(t.dismissedAt);
      if (!showCompleted && completedOrDismissed) {
        return undefined;
      } else {
        const icon = isSome(t.completedAt) ? (
          <Icon icon={IconNames.TICK_CIRCLE} intent={Intent.SUCCESS} />
        ) : isSome(t.dismissedAt) ? (
          <Icon icon={IconNames.ISSUE} intent={Intent.WARNING} />
        ) : (
          <Icon icon={IconNames.CIRCLE} />
        );

        const completeButton = completedOrDismissed ? undefined : (
          <Button
            text="Complete"
            onClick={() => {
              if (isSome(this.props.completeTask)) {
                this.props
                  .completeTask({ variables: { id: t.id } })
                  .catch(LogError);
              }
            }}
          />
        );

        const dismissButton = completedOrDismissed ? undefined : (
          <Button
            text="Dismiss"
            onClick={() => {
              this.setState({
                dismissDialog: true,
                dismissId: t.id,
              });
            }}
          />
        );

        return (
          <Card className="spead-components risk-register-card" key={t.id}>
            {t.text}
            <span className="fixed-column-more-buttons">
              {completeButton}
              {dismissButton}
              {icon}
            </span>
          </Card>
        );
      }
    });

    return (
      <VantaDashboardPage headingInfo={PageHeadingInfo.RISK_REGISTER}>
        {addScenarioDialog}

        <div className="spead-components">
          <H2>Questions</H2>
          <Switch
            label="Show completed"
            checked={this.state.showCompletedQuestions}
            onChange={e => {
              this.setState({
                showCompletedQuestions: e.currentTarget.checked,
              });
            }}
          />
        </div>
        {questionCards}
        {scenarios}
        <div className="spead-components">
          <H2>Tasks</H2>
          <Switch
            label="Show completed"
            checked={this.state.showCompletedTasks}
            onChange={e => {
              this.setState({ showCompletedTasks: e.currentTarget.checked });
            }}
          />
        </div>
        {taskCards}
        {maybeDismissDialog}
      </VantaDashboardPage>
    );
  }
}

export const ManageRisksPage: React.FC<IProps> = props => {
  const [completeTask] = useMarkRiskTaskAsCompleteMutation();
  const [dismissTask] = useMarkRiskTaskAsDismissedMutation();
  const [clearSplash] = useSetSeenRiskRegisterSplashMutation();
  const { error, loading, data } = useFetchRiskRegisterQuery();
  if (error) {
    LogError(error);
    return null;
  }
  if (loading) {
    return <FullPageSpinner />;
  }
  if (!data || !data.user) {
    LogErrorMessage("Bad fetch");
    return null;
  }
  return (
    <ManageRisks
      {...props}
      completeTask={completeTask}
      dismissTask={dismissTask}
      clearSplash={clearSplash}
      domain={data.organization}
      user={data.user}
    />
  );
};
