import { FormGroup, InputGroup, Intent } from "@blueprintjs/core";
import gql from "graphql-tag";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { LogError } from "../../../errors";
import { useSetUserNameMutation } from "../../../gen/components";
import { AppToaster } from "../../../helpers/toaster";
import { CancelConfirmDialog } from "../../helpers/CancelConfirmDialog";
import type { DetailHeadingUser } from "./user-drawer/user-detail-heading";

interface IProps {
  isOpen: boolean;
  user: DetailHeadingUser;
  onClose(): void;
}

export const EditUserNameDialog: React.FC<IProps> = ({
  isOpen,
  onClose,
  user,
}) => {
  const [givenName, setGivenName] = useState(user.givenName ?? "");
  const [familyName, setFamilyName] = useState(
    user.familyName ?? user.displayName ?? ""
  );

  const [setUserName] = useSetUserNameMutation({
    onCompleted() {
      AppToaster.show({
        intent: Intent.SUCCESS,
        message: `Name has been updated to ${givenName.trim()} ${familyName.trim()}`,
      });
      onClose();
    },
  });

  useEffect(() => {
    setGivenName(user.givenName ?? "");
    setFamilyName(user.familyName ?? user.displayName ?? "");
  }, [user]);

  const canSubmit = familyName.trim() !== "";

  return (
    <CancelConfirmDialog
      title={`Change name for ${user.displayName ?? "person"}`}
      body={
        <Container>
          <FormGroup label="Given name">
            <InputGroup
              value={givenName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setGivenName(e.target.value)
              }
            />
          </FormGroup>
          <FormGroup label="Family name">
            <InputGroup
              value={familyName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFamilyName(e.target.value)
              }
            />
          </FormGroup>
        </Container>
      }
      isOpen={isOpen}
      onConfirm={() => {
        setUserName({
          variables: {
            userId: user.id,
            familyName: familyName.trim(),
            givenName: givenName.trim(),
          },
        }).catch(LogError);
      }}
      onClose={() => onClose()}
      confirmText="Save"
      confirmDisabled={!canSubmit}
    />
  );
};

gql`
  mutation setUserName(
    $userId: ID!
    $familyName: String!
    $givenName: String!
  ) {
    setUserName(
      userId: $userId
      familyName: $familyName
      givenName: $givenName
    ) {
      id
      familyName
      givenName
      displayName
    }
  }
`;

const Container = styled.div`
  & > * {
    margin-bottom: 12px;
  }
`;
