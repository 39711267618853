import { MenuItem } from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import React from "react";

import { Button, Icon, IconNames } from "../../../../alpaca/components";

interface IProps {
  options: userSelectTypes[];
  rowId: string;
  isInFlight: boolean;
  onUserSelect(user: userSelectTypes): void;
  buttonText: string;
}
export type User = {
  id: string;
  email: string;
  displayName?: Maybe<string>;
};

type userSelectTypes = User | "extUser" | "newUser" | "nonHuman";

export const UserSelectorForAccounts: React.FC<IProps> = ({
  rowId,
  isInFlight,
  onUserSelect,
  options,
  buttonText,
}) => {
  const UserSelect = Select.ofType<userSelectTypes>();

  const filterUser = (query: string, user: userSelectTypes) => {
    if (user === "extUser" || user === "newUser" || user === "nonHuman") {
      return true;
    }

    const val = isSome(user.displayName) ? user.displayName : user.email;
    return val.toLowerCase().includes(query.toLowerCase());
  };

  const userDisplayText = (user: User) =>
    isSome(user.displayName) ? (
      <div>
        {user.displayName}
        <br />
        <small className="text-muted">{user.email}</small>
      </div>
    ) : (
      user.email
    );

  const renderUserForSuggest = (
    user: userSelectTypes,
    params: { handleClick: any }
  ) => {
    switch (user) {
      case "nonHuman":
        return (
          <MenuItem
            key="NON_HUMAN"
            icon={<Icon icon={IconNames.PERSON_ALERT} />}
            text="Mark as not a person"
            onClick={params.handleClick}
          />
        );
      case "extUser":
        return (
          <MenuItem
            key="EXTERNAL_USER"
            icon={<Icon icon={IconNames.BRIEFCASE} />}
            text="Assign to external person"
            onClick={params.handleClick}
          />
        );
      case "newUser":
        return (
          <MenuItem
            key="NEW_USER"
            icon={<Icon icon={IconNames.PLUS} />}
            text="Add new person"
            onClick={params.handleClick}
          />
        );
      default:
        return (
          <MenuItem
            key={user.id}
            text={userDisplayText(user)}
            onClick={params.handleClick}
          />
        );
    }
  };

  const assignButton = (
    <UserSelect
      key={rowId}
      items={options}
      itemPredicate={filterUser}
      itemRenderer={renderUserForSuggest}
      noResults={<MenuItem disabled={true} text="No results." />}
      onItemSelect={onUserSelect}
      popoverProps={{
        usePortal: false,
        boundary: "window",
        placement: "left",
      }}
    >
      <Button
        disabled={isInFlight}
        loading={isInFlight}
        small
        style={{ width: styles.BUTTON_WIDTH }}
      >
        {buttonText}
      </Button>
    </UserSelect>
  );

  return assignButton;
};

const styles = {
  BUTTON_WIDTH: 70,
};
