/**
 * Programmatically fetches and downloads a static asset.
 *
 * Intended for use with requre("url:some-url") type values (as opposed to user input).
 *
 * @param url
 * @param name
 */
export async function downloadStaticUrl(url: string, filename: string) {
  const fetchResponse = await fetch(url);
  const blob = await fetchResponse.blob();
  const blobURL = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = blobURL;
  a.setAttribute("style", "display: none;");
  a.download = filename;
  document.body.appendChild(a);
  a.click();
}
