import { MenuItem } from "@blueprintjs/core";
import type { ItemRenderer } from "@blueprintjs/select";
import { Suggest } from "@blueprintjs/select";
import React from "react";

import type { FetchRiskRegisterQuery } from "../../../gen/components";

function escapeRegExpChars(text: string) {
  return text.replace(/([.*+?^=!:${}()|[\]/\\])/g, "\\$1");
}

function highlightText(text: string, query: string) {
  let lastIndex = 0;
  const words = query
    .split(/\s+/)
    .filter(word => word.length > 0)
    .map(escapeRegExpChars);
  if (words.length === 0) {
    return [text];
  }
  const regexp = new RegExp(words.join("|"), "gi");
  const tokens: React.ReactNode[] = [];
  while (true) {
    const matched = regexp.exec(text);
    if (!matched) {
      break;
    }
    const length = matched[0].length;
    const before = text.slice(lastIndex, regexp.lastIndex - length);
    if (before.length > 0) {
      tokens.push(before);
    }
    lastIndex = regexp.lastIndex;
    tokens.push(<strong key={lastIndex}>{matched[0]}</strong>);
  }
  const rest = text.slice(lastIndex);
  if (rest.length > 0) {
    tokens.push(rest);
  }
  return tokens;
}

type Task = NonNullable<
  FetchRiskRegisterQuery["organization"]
>["riskRegister"]["tasks"][number];

export const TaskSuggest = Suggest.ofType<Task>();

export const createNewItemRenderer = (
  query: string,
  active: boolean,
  handleClick: React.MouseEventHandler<HTMLElement>
) => (
  <MenuItem
    icon="add"
    text={`Create "${query}"`}
    active={active}
    onClick={handleClick}
    shouldDismissPopover={false}
  />
);
export const renderTask: ItemRenderer<Task> = (
  task,
  { handleClick, modifiers, query }
) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }
  const text = `${task.text}`;
  return (
    <MenuItem
      multiline
      className="task-menu-item"
      active={modifiers.active}
      disabled={modifiers.disabled}
      key={task.id}
      onClick={handleClick}
      text={highlightText(text, query)}
    />
  );
};
