import { Icon, Intent } from "@blueprintjs/core";
import type { MIME_TYPES } from "common/base/types/helpers";
import {
  ALLOWED_RENDER_MIME_TYPES,
  MIME_TYPE_FILE_EXTENSIONS,
  mimeTypesToAcceptProp,
} from "common/base/types/helpers";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import {
  ListCoordinator,
  toCommaSeparatedList,
} from "common/grammar/sequences";
import React from "react";
import styled from "styled-components";

import { VANTA_COLORS } from "../../../alpaca/base/colors";
import { DefaultLink } from "../../../helpers/links";
import { AppToaster } from "../../../helpers/toaster";

// Max file size is 25MB
const MAX_FILE_SIZE = 25 * 2 ** 20;

interface FileDetails {
  filename?: Maybe<string>;
  url: string;
  createdAt: string;
}

interface IProps {
  name: string;
  id: string;
  accept?: Maybe<MIME_TYPES[]>;
  selectedFile?: Maybe<File>;
  uploadedFile?: Maybe<FileDetails>;
  fileUploaded(name: string, file?: Maybe<File>): void;
}

/**
 * This stateless component is used for uploading and rendering files.
 * It provides client side checking for file types and file size.
 *
 * It can be in three main states
 * 1. Ready to accept a file. The user can hit browse and select a file.
 * 2. Contains a files ready to upload. The user can still reselect a file.
 * 3. Link to a previously uploaded file. The user can still select a new file to upload.
 */
export const FileInput: React.FC<IProps> = ({
  accept,
  name,
  id,
  uploadedFile,
  selectedFile,
  fileUploaded,
}) => {
  const acceptableMimeTypes = accept ?? ALLOWED_RENDER_MIME_TYPES;
  const acceptableFiles = acceptableFilesList(acceptableMimeTypes);

  const fileInput = (
    <StyledInput
      type="file"
      onChange={event =>
        fileUploadHandler(event, acceptableMimeTypes, (file: File) =>
          fileUploaded(name, file)
        )
      }
      accept={mimeTypesToAcceptProp(acceptableMimeTypes)}
      name={name}
      id={id}
      style={{ width: 100 }}
      /**
       * File inputs are notoriously difficult to control.
       * This check covers certain cases in which selectedFile is
       * set to something, changed to nothing, and then the user selects
       * the same file in the system dialog
       */
      value={isSome(selectedFile) ? undefined : ""}
    />
  );

  const browseFile = (
    <>
      <ContainerDiv>
        <StyledLabel>
          <TextAreaSpan>Select a file</TextAreaSpan>
          <StyledButton className="bp3-button">Browse</StyledButton>
          {fileInput}
        </StyledLabel>
      </ContainerDiv>
      <MimeTypeText>Upload a {acceptableFiles} file.</MimeTypeText>
    </>
  );

  const fileSelected = (
    <div>
      <SelectedContainerDiv>
        <StyledCancelIcon
          icon="cross"
          iconSize={25}
          onClick={(e: React.MouseEvent<unknown>) => {
            e.stopPropagation();
            fileUploaded(name, null);
          }}
        />
        <StyledLabel>
          <TextAreaSpan>{selectedFile?.name}</TextAreaSpan>
          <StyledButton className="bp3-button">Browse</StyledButton>
          {fileInput}
        </StyledLabel>
      </SelectedContainerDiv>
      <MimeTypeText>Upload a {acceptableFiles} file.</MimeTypeText>
    </div>
  );

  const renderUploadedFile = (file: FileDetails) => (
    <div>
      <ContainerDiv>
        <UploadedFileSpan>
          <DefaultLink href={`${location.origin}/${file.url}`}>
            <StyledAttachmentIcon icon="paperclip" />
            {isSome(file.filename) && file.filename.trim() !== ""
              ? file.filename
              : "Current File"}
          </DefaultLink>
        </UploadedFileSpan>
        <StyledSelectedLabel>
          <StyledButton className="bp3-button">Upload New File</StyledButton>
          {fileInput}
        </StyledSelectedLabel>
      </ContainerDiv>
      <MimeTypeText>Upload a {acceptableFiles} file.</MimeTypeText>
    </div>
  );
  return isSome(selectedFile)
    ? fileSelected
    : isSome(uploadedFile)
    ? renderUploadedFile(uploadedFile)
    : browseFile;
};

function acceptableFilesList(mimeTypes: MIME_TYPES[]) {
  return toCommaSeparatedList(
    mimeTypes.map(mt => MIME_TYPE_FILE_EXTENSIONS[mt].toUpperCase()),
    ListCoordinator.Or
  );
}

// Wrap the changeHandler to add checks for file types
export function fileUploadHandler(
  event: React.ChangeEvent<HTMLInputElement>,
  acceptableMimeTypes: MIME_TYPES[],
  fileUploaded: (file: File) => void
) {
  if (isSome(event.target.files) && event.target.files.length > 0) {
    const singleFile = event.target.files[0];
    if (checkFile(singleFile, acceptableMimeTypes)) {
      fileUploaded(singleFile);
    }
  }
}

export function checkFile(file: File, acceptableMimeTypes: MIME_TYPES[]) {
  if (!acceptableMimeTypes.includes(file.type as MIME_TYPES)) {
    AppToaster.show({
      intent: Intent.DANGER,
      message: `Unsupported file type. Try ${acceptableFilesList(
        acceptableMimeTypes
      )}.`,
    });
    return false;
  } else if (file.size > MAX_FILE_SIZE) {
    AppToaster.show({
      intent: Intent.DANGER,
      message: `File is too big. Maximum file size is 25MB.`,
    });
    return false;
  }
  return true;
}

const ContainerDiv = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  width: 400px;
`;

const SelectedContainerDiv = styled(ContainerDiv)`
  padding: 2px;
  border: 2px solid #baabbd;
  border-radius: 3px;
`;

const StyledCancelIcon = styled(Icon)`
  z-index: 3;
  color: #e4be9e;
  position: relative;
  top: 3px;
  &:hover {
    cursor: pointer;
  }
`;
const StyledAttachmentIcon = styled(Icon)`
  padding-right: 8px;
`;
const StyledButton = styled.span`
  flex: 0 0 auto;
  height: 30px !important;
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2),
    inset 0 1px 0 rgba(16, 22, 26, 0.1) !important;
`;

const UploadedFileSpan = styled.span`
  position: relative;
  top: 2px;
  height: 30px;
  line-height: 30px;
  margin-right: 10px;
  flex: 1 1 auto;
  overflow: hidden;

  a {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: 100%;
  }
`;

const StyledLabel = styled.label`
  display: flex !important;
  margin: 0px !important;
  flex-direction: row;
  flex: 1 1 auto;
  overflow: hidden;
`;

const StyledSelectedLabel = styled(StyledLabel)`
  flex: 0 0 auto;
`;

const StyledInput = styled.input`
  position: absolute;
  left: 100px;
  opacity: 0;
  width: 100%;
  z-index: -1;
`;

const TextAreaSpan = styled.span`
  border-radius: 3px;
  box-shadow: 0 0 0 0 rgba(95, 75, 139, 0), 0 0 0 0 rgba(95, 75, 139, 0),
    inset 0 0 0 1px rgba(16, 22, 26, 0.15),
    inset 0 1px 1px rgba(16, 22, 26, 0.2);
  margin-right: 10px;
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
  vertical-align: middle;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1 1 auto;
  overflow: hidden;
`;

const MimeTypeText = styled.p`
  color: ${VANTA_COLORS.TEXT_MUTED};
  font-size: 12px;
  margin-top: 6px;
`;
