import { useQuery } from "@apollo/client";
import { Intent } from "@blueprintjs/core";
import gql from "graphql-tag";
import React from "react";

import { LogError } from "../../../errors";
import { useUpdateBusinessProfileMutation } from "../../../gen/components";
import { FeatureGatedForm } from "../../../helpers/feature-gating/feature-gated-form";
import { AppToaster } from "../../../helpers/toaster";
import { FullPageSpinner } from "../../helpers/FullPageSpinner";
import type { IHeadingInfo } from "../../vanta-chrome/page-content/info";
import {
  PageHeadingInfo,
  VantaDashboardPage,
} from "../../vanta-chrome/page-content/vanta-dashboard-page";
import { ENGINEERING_INFO_FORM_PROPS } from "./form-schemas/engineering";
import { BUSINESS_INFO_FORM_PROPS } from "./form-schemas/information";
import type { ProfilePageInternalProps } from "./types";
import { ProfilePageType } from "./types";

const ProfilePageToInternalProps: {
  [k in ProfilePageType]: ProfilePageInternalProps;
} = {
  [ProfilePageType.ENGINEERING]: ENGINEERING_INFO_FORM_PROPS,
  [ProfilePageType.INFORMATION]: BUSINESS_INFO_FORM_PROPS,
};

const ProfilePageToHeadingInfo: {
  [k in ProfilePageType]: IHeadingInfo;
} = {
  [ProfilePageType.ENGINEERING]: PageHeadingInfo.ENGINEERING_INFORMATION,
  [ProfilePageType.INFORMATION]: PageHeadingInfo.COMPANY_INFORMATION,
};

interface IProps {
  page: ProfilePageType;
}

export const ProfilePage: React.FC<IProps> = ({ page }) => {
  const {
    additionalContent,
    graphqlQuery,
    mapQueryToSchemaInfo,
    questionSchema,
  } = ProfilePageToInternalProps[page];

  const [updateInfo] = useUpdateBusinessProfileMutation({
    onCompleted: () => {
      AppToaster.show({
        icon: "tick",
        intent: Intent.SUCCESS,
        message: "Thanks! We've saved your information.",
        timeout: 2500,
      });
    },
    refetchQueries: [{ query: graphqlQuery }],
  });

  const { error, loading, data } = useQuery(graphqlQuery);
  if (loading) {
    return <FullPageSpinner />;
  }
  if (error || !data) {
    LogError(error ?? new Error("Bad fetch"));
    return null;
  }

  return (
    <VantaDashboardPage headingInfo={ProfilePageToHeadingInfo[page]}>
      {additionalContent}
      <FeatureGatedForm
        priorInfo={mapQueryToSchemaInfo(data.organization)}
        questionSchema={questionSchema}
        onSubmit={(answers, fileObj) => {
          const files = Object.keys(fileObj).map(f => {
            return {
              fieldName: f,
              file: fileObj[f],
            };
          });
          updateInfo({
            variables: {
              answers,
              files,
            },
          }).catch(LogError);
        }}
      />
    </VantaDashboardPage>
  );
};

gql`
  mutation updateBusinessProfile($files: [FileFieldName], $answers: String!) {
    setDomainBusinessOrComplianceInfo(answers: $answers, files: $files) {
      id
    }
  }
`;
