import { Button, Intent } from "@blueprintjs/core";
import type { GcpCredentialValidationOutcome } from "common/base/types/gen";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import React from "react";

import { LogError } from "../../../../errors";
import { useValidateGcpCredentialsLazyQuery } from "../../../../gen/components";
import type { IInputValues } from "../simple-credential-form-dialog-body";

gql`
  query validateGcpCredentials($credentials: String!, $metadata: String!) {
    validateGcpCredentials(credentials: $credentials, metadata: $metadata) {
      type
      missingResourcesByProject {
        projectId
        ids
      }
    }
  }
`;

interface IValidateButtonProps {
  domainId: string;
  // validatable is true iff all the required inputValues are provided.
  validatable: boolean;
  handleValidationResult: (data: Maybe<GcpCredentialValidationOutcome>) => void;
  inputValues: IInputValues;
}

export const ValidateGCPButton: React.FunctionComponent<IValidateButtonProps> =
  ({ domainId, validatable, handleValidationResult, inputValues }) => {
    const [validate, { data, loading, error }] =
      useValidateGcpCredentialsLazyQuery({
        fetchPolicy: "no-cache",
      });

    if (isSome(error)) {
      LogError(error);
    }

    if (isSome(data)) {
      handleValidationResult(data.validateGcpCredentials);
    }

    return (
      <Button
        onClick={() => {
          validate({
            variables: {
              credentials: JSON.stringify({
                privateKey: inputValues.privateKey,
                clientEmail: inputValues.clientEmail,
                serviceAccountProjectId: inputValues.serviceAccountProjectId,
              }),
              // Project IDs are already stringified, and `validatable` indicates the
              // list is non-null and non-empty.
              metadata: inputValues.projectIds!,
            },
          });
        }}
        loading={loading}
        intent={Intent.PRIMARY}
        disabled={!validatable}
      >
        Validate credentials
      </Button>
    );
  };
