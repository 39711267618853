import { toPossibleDate } from "common/base/dateUtils";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import { sortBy } from "lodash";
import moment from "moment";
import React, { useMemo } from "react";
import styled from "styled-components";

import { UI_DATE_FORMAT_WITHOUT_TIME } from "../../../../helpers/common";
import { DefaultLink } from "../../../../helpers/links";
import { DataTable } from "../../components/data-table";
import type { PenTest } from "./pen-test-component";
import { PenTestTableActions } from "./pen-test-table-actions";

interface IProps {
  penTests: PenTest[];
}

export const PenTestHistoryTable: React.FC<IProps> = ({ penTests }) => {
  const sortedTests = useMemo(
    () =>
      sortBy(
        penTests.slice(),
        d => -1 * (toPossibleDate(d.effectiveAt)?.getTime() ?? 0)
      ),
    [penTests]
  );
  if (sortedTests.length === 0) {
    return null;
  }
  return (
    <Container>
      <DataTable
        columnOrder={["date", "file", "actions"]}
        columnWidths={["130px", "300px", "230px"]}
        header={{
          date: "Date of pen test",
          file: "Pen test report",
          actions: "Actions",
        }}
        data={sortedTests}
        createRow={penTest => {
          if (!isSome(penTest.effectiveAt)) {
            throw new Error("penTest date missing");
          }
          return {
            date: moment(penTest.effectiveAt).format(
              UI_DATE_FORMAT_WITHOUT_TIME
            ),
            file: (
              <div>
                <DefaultLink href={`${location.origin}/${penTest.url}`}>
                  {penTest.filename ?? penTest.url}
                </DefaultLink>
                <p>
                  (uploaded{" "}
                  {moment(penTest.createdAt).format(
                    UI_DATE_FORMAT_WITHOUT_TIME
                  )}
                  {isSome(penTest.createdAt)
                    ? ` by ${penTest.creator?.displayName}`
                    : ""}
                  )
                </p>
              </div>
            ),
            actions: <PenTestTableActions penTest={penTest} />,
          };
        }}
      />
    </Container>
  );
};

const Container = styled.div`
  word-break: break-word;
  & td:first-child {
    padding-left: 0px;
  }
`;

gql`
  mutation deletePenTestDoc($uploadedDocumentId: String!) {
    deleteUploadedDocument(uploadedDocumentId: $uploadedDocumentId) {
      id
    }
  }
`;
