import { Button, Classes, Intent } from "@blueprintjs/core";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import React from "react";

import type { GetVaqAnswersQuery } from "../../gen/components";
import {
  GetVaqAnswerStateDocument,
  useSubmitVaqMutation,
} from "../../gen/components";
import {
  getVAQAnswersToSubmit,
  getVAQFormsForVendor,
  vendorCanSubmitVAQ,
} from "./schemas/vaq-state-helpers";

interface IProps {
  vaq: NonNullable<GetVaqAnswersQuery["vaqAnswers"]>;
}

export const VAQSubmitForm: React.FC<IProps> = props => {
  const { vaq } = props;
  const canSubmit = vendorCanSubmitVAQ(vaq);

  const [submitVaq, { called }] = useSubmitVaqMutation({
    refetchQueries: [
      { query: GetVaqAnswerStateDocument, variables: { uuid: vaq.uuid } },
    ],
  });

  if (!canSubmit) {
    return (
      <div className="vaq-main-body-message">
        <p>Your Vendor Assessment Form is still incomplete. </p>
        <p>
          Please return to sections marked with a red circle and answer the
          required questions.
        </p>
      </div>
    );
  }

  const submitButton = (
    <Button
      intent={Intent.PRIMARY}
      loading={called}
      onClick={async () => {
        const { files } = getVAQAnswersToSubmit(vaq);
        const schemaIds = getVAQFormsForVendor(vaq).map(f => f.id);
        await submitVaq({
          variables: {
            uuid: vaq.uuid,
            schemaIds,
            files,
          },
        });
        setVAQSubmissionSessionStorage(vaq);
      }}
    >
      Submit answers
    </Button>
  );

  return (
    <div className={Classes.RUNNING_TEXT}>
      <p>
        You have completed the required questions, and your answers are ready to
        submit to {props.vaq.domainDisplayName}.
      </p>
      <p>Please take a moment to review them.</p>
      <p>You cannot change your answers after submission.</p>
      {submitButton}
    </div>
  );
};

export const setVAQSubmissionSessionStorage = (vaq: IProps["vaq"]) => {
  window.sessionStorage.setItem(
    `vaq-${vaq.uuid.substring(0, 6)}-submission`,
    "submitted"
  );
  if (isSome(vaq.domainDisplayName)) {
    window.sessionStorage.setItem(`vaq-requester`, vaq.domainDisplayName);
  }
};

gql`
  mutation submitVAQ(
    $uuid: String!
    $schemaIds: [String!]!
    $files: [String!]!
  ) {
    submitVAQ(uuid: $uuid, schemaIds: $schemaIds, files: $files)
  }
`;
