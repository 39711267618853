import { Button, FormGroup, Intent, TagInput } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import type { Maybe } from "common/base/types/gen";
import { TEXT_MIME_TYPES } from "common/base/types/helpers";
import { isSome } from "common/base/types/maybe";
import {
  organizationCustomRolePermissions,
  projectCustomRolePermissions,
} from "common/resources/specific-resources/gcp/required-permissions";
import moment from "moment";
import React from "react";

import { BodyShortText } from "../../../../alpaca/components";
import { DefaultLink } from "../../../../helpers/links";
import { AppToaster } from "../../../../helpers/toaster";
import { CopyableCode } from "../../../helpers/CopyableCode";
import { FileInput } from "../../components/file-input";

const LinkGCPScriptName = "provision-vanta-gcp-connection.sh";
const LinkGCPScript = require("url:../../../../static/provision-vanta-gcp-connection.sh");

const permissionsToList = (permissions: string[]) =>
  permissions.map((p, index) => (
    <React.Fragment key={index}>
      {p}
      {index === permissions.length - 1 ? null : <br />}
    </React.Fragment>
  ));

interface ILinkingInstructionsProps {
  projectIds: string[];
  setProjectIds: (projectIds: string[]) => void;
  setKeyData: (keyData: {
    clientEmail: Maybe<string>;
    privateKey: Maybe<string>;
    serviceAccountProjectId: Maybe<string>;
  }) => void;
}

export const GCPConsoleLinkingInstructions: React.FC<ILinkingInstructionsProps> =
  ({ projectIds, setProjectIds, setKeyData }) => (
    <div>
      <ol>
        <li>
          Create a service account in one of your production GCP projects.
        </li>
        <li>
          In your GCP organization, create a custom role named
          <code>VantaProjectScanner</code> with the following IAM permissions:
          <br />
          <pre>{permissionsToList(projectCustomRolePermissions)}</pre>
        </li>
        <li>
          In each of the projects you will link to Vanta, grant the new service
          account the <code>VantaProjectScanner</code> and
          <code>roles/iam.securityReviewer</code> roles.
        </li>
        <li>
          In your GCP organization, create a custom role named
          <code>VantaOrganizationScanner</code> with the following IAM
          permissions:
          <br />
          <pre>{permissionsToList(organizationCustomRolePermissions)}</pre>
        </li>
        <li>
          In your GCP organization, grant the new service account the
          <code>VantaOrganizationScanner</code> role.
        </li>
        <li>
          Download the service account credentials as a JSON file; upload the
          file below.
        </li>
      </ol>
      <FormGroup>
        <label>Project IDs</label>
        <ProjectIdInput projectIds={projectIds} setProjectIds={setProjectIds} />
        <label>JSON Key File</label>
        <JSONKeyInput setKeyData={setKeyData} />
      </FormGroup>
    </div>
  );

export const GCPScriptLinkingInstructions: React.FC<ILinkingInstructionsProps> =
  ({ projectIds, setProjectIds, setKeyData }) => (
    <ol>
      <li>
        <BodyShortText>
          Specify the GCP projects to be monitored in Vanta.
        </BodyShortText>
        <ProjectIdInput projectIds={projectIds} setProjectIds={setProjectIds} />
      </li>
      <li>
        <BodyShortText>
          Download{" "}
          <DefaultLink
            href={`${LinkGCPScript}?download=1`}
            download={LinkGCPScriptName}
          >
            {LinkGCPScriptName}
          </DefaultLink>
          . Run the script, passing it the GCP project IDs specified above as
          arguments:
        </BodyShortText>
        <CopyableCode
          code={`bash ./provision-vanta-gcp-connection.sh ${projectIds
            .map(pid => `"${pid}"`)
            .join(" ")}`}
        />
      </li>
      <li>
        <BodyShortText>Upload vanta-scanner-key.json below.</BodyShortText>
        <JSONKeyInput setKeyData={setKeyData} />
      </li>
    </ol>
  );

/**
 * ProjectIdInput is a Blueprint TagInput configured to tokenize GCP project
 * IDs.
 */
const ProjectIdInput: React.FunctionComponent<
  Pick<ILinkingInstructionsProps, "projectIds" | "setProjectIds">
> = ({ projectIds, setProjectIds }) => (
  <TagInput
    onChange={nodes => setProjectIds(nodes as string[])}
    placeholder="Separate project IDs with spaces..."
    rightElement={
      // X-button: clear the state.
      <Button
        icon={IconNames.CROSS}
        minimal={true}
        onClick={() => setProjectIds([])}
      />
    }
    values={projectIds}
    addOnBlur={true}
    separator={/[\s,]/}
  />
);

const JSONKeyInput: React.FC<Pick<ILinkingInstructionsProps, "setKeyData">> = ({
  setKeyData,
}) => {
  const [acceptedFile, setAcceptedFile] = React.useState<Maybe<File>>(null);
  // Parse the JSON key file and store the input values.
  const fileUploadedHandler = (name: string, file: Maybe<File>) => {
    if (isSome(file)) {
      const reader = new FileReader();
      reader.onload = (event: ProgressEvent) => {
        let jsonObj: any = null;
        if (isSome(event.target)) {
          try {
            jsonObj = JSON.parse(
              (event.target as unknown as { result: string }).result
            ); // Filthy.
          } catch (e) {
            AppToaster.show({
              intent: Intent.DANGER,
              message: "Uploaded key file is not parseable JSON.",
            });
          }
        }
        if (!isValidJSONKey(jsonObj)) {
          AppToaster.show({
            intent: Intent.DANGER,
            message:
              "Uploaded JSON key file is missing client email or private key.",
          });
        } else {
          setKeyData({
            clientEmail: jsonObj.client_email,
            privateKey: jsonObj.private_key,
            serviceAccountProjectId: jsonObj.project_id,
          });
        }
      };
      reader.readAsText(file);
      setAcceptedFile(file);
    }
  };
  return (
    <FileInput
      name="JSON Key File"
      id="key"
      accept={[TEXT_MIME_TYPES.JSON]}
      fileUploaded={(name: string, file: Maybe<File>) => {
        fileUploadedHandler(name, file);
      }}
      uploadedFile={
        isSome(acceptedFile)
          ? {
              filename: acceptedFile.name,
              createdAt: moment(acceptedFile.lastModified).toString(),
              url: "#",
            }
          : undefined
      }
    />
  );
};

function isValidJSONKey(jsonObj: any): jsonObj is {
  client_email: string;
  private_key: string;
  project_id: string;
} {
  // If these fields are undefined, their type is `undefined`.
  return (
    typeof jsonObj.client_email === "string" &&
    typeof jsonObj.private_key === "string" &&
    typeof jsonObj.project_id === "string"
  );
}
