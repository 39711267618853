{
  "standardInfo": {
    "standard": "pciSaqDSP"
  },
  "controls": [
    {
      "id": "1.1.1",
      "name": "Firewall and router configuration process",
      "description": "Are firewall and router configuration standards established and implemented to include the following:\n\nIs there a formal process for approving and testing all network connections and changes to the firewall and router configurations?",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-operations-security-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "1.1.2.a",
      "name": "Network diagram",
      "description": "Are firewall and router configuration standards established and implemented to include the following:\n\nIs there a current network diagram that documents all connections between the cardholder data environment and other networks, including any wireless networks?",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "network-diagram"
        }
      ]
    },
    {
      "id": "1.1.2.b",
      "name": "Network diagram documentation process",
      "description": "Are firewall and router configuration standards established and implemented to include the following:\n\nIs there a process to ensure the diagram is kept current?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "1.1.3.a",
      "name": "Cardholder dataflow diagram",
      "description": "Are firewall and router configuration standards established and implemented to include the following:\n\nIs there a current diagram that shows all cardholder data flows across systems and networks?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "cardholder-dataflow-diagram-exists"
        }
      ]
    },
    {
      "id": "1.1.3.b",
      "name": "Cardholder dataflow documentation process",
      "description": "Are firewall and router configuration standards established and implemented to include the following:\n\nIs there a process to ensure the diagram is kept current?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "cardholder-dataflow-diagram-process"
        }
      ]
    },
    {
      "id": "1.1.4.a",
      "name": "Firewall in DMZ and internal network zones",
      "description": "Are firewall and router configuration standards established and implemented to include the following:\n\nIs a firewall required and implemented at each Internet connection and between any demilitarized zone (DMZ) and the internal network zone?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "firewall-config-dmz-exists"
        },
        {
          "id": "firewall-config-standard-exists"
        }
      ]
    },
    {
      "id": "1.1.4.b",
      "name": "Network diagram and firewall configuration consistency",
      "description": "Are firewall and router configuration standards established and implemented to include the following:\n\nIs the current network diagram consistent with the firewall configuration standards?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "cardholder-network-diagram-process"
        }
      ]
    },
    {
      "id": "1.1.5",
      "name": "Firewall and router configuration standards owners documented",
      "description": "Are firewall and router configuration standards established and implemented to include the following:\n\nAre groups, roles, and responsibilities for logical management of network components assigned and documented in the firewall and router configuration standards?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "1.1.6.a",
      "name": "Firewall and router configuration services and protocols documented",
      "description": "Are firewall and router configuration standards established and implemented to include the following:\n\nDo firewall and router configuration standards include a documented list of services, protocols, and ports, including business justification and approval for each?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": []
    },
    {
      "id": "1.1.6.b",
      "name": "Insecure services and protocols documented",
      "description": "Are firewall and router configuration standards established and implemented to include the following:\n\nAre all insecure services, protocols, and ports identified, and are security features documented and implemented for each identified service?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "1.1.7.a",
      "name": "Firewall and router configuration recurring review",
      "description": "Are firewall and router configuration standards established and implemented to include the following:\n\nDo firewall and router configuration standards require review of firewall and router rule sets at least every six months?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "1.1.7.b",
      "name": "Firewall and router rule sets recurring review",
      "description": "Are firewall and router configuration standards established and implemented to include the following:\n\nAre firewall and router rule sets reviewed at least every six months?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "traffic-authorized-and reviewed"
        }
      ]
    },
    {
      "id": "1.2.1.a",
      "name": "Inbound and outbound traffic restriction",
      "description": "Do firewall and router configurations restrict connections between untrusted networks and any system in the cardholder data environment as follows:\n\nNote: An “untrusted network” is any network that is external to the networks belonging to the entity under review, and/or which is out of the entity’s ability to control or manage.\n\nIs inbound and outbound traffic restricted to that which is necessary for the cardholder data environment?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "digitalocean-firewalls-ports-restricted"
        },
        {
          "id": "firewall-config-acl"
        },
        {
          "id": "firewall-config-acl-gcp"
        },
        {
          "id": "firewall-config-acl-heroku"
        },
        {
          "id": "firewall-config-default-deny"
        },
        {
          "id": "firewall-config-default-deny-gcp"
        },
        {
          "id": "firewall-config-default-deny-heroku"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "1.2.1.b",
      "name": "Inbound and outbound traffic denied",
      "description": "Do firewall and router configurations restrict connections between untrusted networks and any system in the cardholder data environment as follows:\n\nNote: An “untrusted network” is any network that is external to the networks belonging to the entity under review, and/or which is out of the entity’s ability to control or manage.\n\nIs all other inbound and outbound traffic specifically denied (for example by using an explicit “deny all” or an implicit deny after allow statement)?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "aws-s3-public-access-blocked"
        },
        {
          "id": "firewall-config-default-deny"
        },
        {
          "id": "firewall-config-default-deny-gcp"
        },
        {
          "id": "firewall-config-default-deny-heroku"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "1.2.2",
      "name": "Router config files secured and synchronized",
      "description": "Do firewall and router configurations restrict connections between untrusted networks and any system in the cardholder data environment as follows:\n\nNote: An “untrusted network” is any network that is external to the networks belonging to the entity under review, and/or which is out of the entity’s ability to control or manage.\n\nAre router configuration files secured from unauthorized access and synchronized—for example, the running (or active) configuration matches the start-up configuration (used when machines are booted)?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "routers-secure-syncronized"
        }
      ]
    },
    {
      "id": "1.2.3",
      "name": "Secure perimeter firewalls installed",
      "description": "Do firewall and router configurations restrict connections between untrusted networks and any system in the cardholder data environment as follows:\n\nNote: An “untrusted network” is any network that is external to the networks belonging to the entity under review, and/or which is out of the entity’s ability to control or manage.\n\nAre perimeter firewalls installed between all wireless networks and the cardholder data environment, and are these firewalls configured to deny or, if traffic is necessary for business purposes, permit only authorized traffic between the wireless environment and the cardholder data environment?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "firewall-config-acl"
        },
        {
          "id": "firewall-config-acl-gcp"
        },
        {
          "id": "firewall-config-acl-heroku"
        },
        {
          "id": "inventory-list-descriptions"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "1.3.1",
      "name": "Inbound traffic limited via DMZ",
      "description": "Is direct public access prohibited between the Internet and any system component in the cardholder data environment, as follows:\n\nIs a DMZ implemented to limit inbound traffic to only system components that provide authorized publicly accessible services, protocols, and ports?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "firewall-config-dmz-exists"
        }
      ]
    },
    {
      "id": "1.3.2",
      "name": "Inbound traffic limited to DMZ IP addresses",
      "description": "Is direct public access prohibited between the Internet and any system component in the cardholder data environment, as follows:\n\nIs inbound Internet traffic limited to IP addresses within the DMZ?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "firewall-config-dmz-exists"
        }
      ]
    },
    {
      "id": "1.3.3",
      "name": "Anti-spoofing measures implemented",
      "description": "Is direct public access prohibited between the Internet and any system component in the cardholder data environment, as follows:\n\nAre anti-spoofing measures implemented to detect and block forged sourced IP addresses from entering the network? \n(For example, block traffic originating from the internet with an internal address.)",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "anti-spoofing-measures"
        }
      ]
    },
    {
      "id": "1.3.4",
      "name": "Outbound traffic authorized",
      "description": "Is direct public access prohibited between the Internet and any system component in the cardholder data environment, as follows:\n\nIs outbound traffic from the cardholder data environment to the Internet explicitly authorized?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "acl-reviewed-approved"
        }
      ]
    },
    {
      "id": "1.3.5",
      "name": "Established connections restricted",
      "description": "Is direct public access prohibited between the Internet and any system component in the cardholder data environment, as follows:\n\nAre only established connections permitted into the network?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "stateful-inspection-not-bypassed"
        }
      ]
    },
    {
      "id": "1.3.6",
      "name": "System components in internal network zone",
      "description": "Is direct public access prohibited between the Internet and any system component in the cardholder data environment, as follows:\n\nAre system components that store cardholder data (such as a database) placed in an internal network zone, segregated from the DMZ and other untrusted networks?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "aws-s3-public-access-blocked"
        },
        {
          "id": "firewall-config-default-deny"
        },
        {
          "id": "firewall-config-default-deny-gcp"
        },
        {
          "id": "firewall-config-default-deny-heroku"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "network-diagram"
        }
      ]
    },
    {
      "id": "1.3.7.a",
      "name": "Private IP address disclosure prevention",
      "description": "Is direct public access prohibited between the Internet and any system component in the cardholder data environment, as follows:\n\nAre methods in place to prevent the disclosure of private IP addresses and routing information to the Internet?\n\nNote: Methods to obscure IP addressing may include, but are not limited to:\n• Network Address Translation (NAT)\n• Placing servers containing cardholder data behind proxy servers/firewalls, \n• Removal or filtering of route advertisements for private networks that employ registered addressing,\n• Internal use of RFC1918 address space instead of registered addresses.",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "nat-not-bypassed"
        }
      ]
    },
    {
      "id": "1.3.7.b",
      "name": "Private IP address disclosure authorized",
      "description": "Is direct public access prohibited between the Internet and any system component in the cardholder data environment, as follows:\n\nIs any disclosure of private IP addresses and routing information to external entities authorized?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "internal-ip-disclosure-approved"
        }
      ]
    },
    {
      "id": "1.4.a",
      "name": "Personal firewall installed",
      "description": "Is personal firewall software (or equivalent functionality) installed and active on any portable computing devices (including company and/or employee-owned) that connect to the Internet when outside the network (for example, laptops used by employees), and which are also used to access the CDE?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pfw-standards-exist"
        }
      ]
    },
    {
      "id": "1.4.b",
      "name": "Personal firewall configured",
      "description": "Is the personal firewall software (or equivalent functionality) configured to specific configuration settings, actively running, and not alterable by users of mobile and/or employee-owned devices?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pfw-standards-exist"
        }
      ]
    },
    {
      "id": "1.5",
      "name": "Firewall management policies and procedures",
      "description": "Are security policies and operational procedures for managing firewalls: \n• Documented\n• In use\n• Known to all affected parties?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-operations-security-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "employees-accepted-operations-security-policy-bsi"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "2.1.a",
      "name": "Vendor defaults changed before installation",
      "description": "Are vendor-supplied defaults always changed before installing a system on the network?\n\nThis applies to ALL default passwords, including but not limited to those used by operating systems, software that provides security services, application and system accounts, point-of-sale (POS) terminals, payment applications, Simple Network Management Protocol (SNMP) community strings, etc.).",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "vendor-defaults-disabled"
        }
      ]
    },
    {
      "id": "2.1.1.a",
      "name": "Vendor default encryption keys changed",
      "description": "For wireless environments connected to the cardholder data environment or transmitting cardholder data, are ALL wireless vendor defaults changed at installations, as follows:\n\nAre encryption keys changed from default at installation, and changed anytime anyone with knowledge of the keys leaves the company or changes positions?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "wifi-encryption-changed"
        }
      ]
    },
    {
      "id": "2.1.1.b",
      "name": "Vendor default SNMP community strings changed",
      "description": "For wireless environments connected to the cardholder data environment or transmitting cardholder data, are ALL wireless vendor defaults changed at installations, as follows:\n\nAre default SNMP community strings on wireless devices changed at installation?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "wifi-snmp-changed"
        }
      ]
    },
    {
      "id": "2.1.1.c",
      "name": "Vendor default passwords / passphrases changed",
      "description": "For wireless environments connected to the cardholder data environment or transmitting cardholder data, are ALL wireless vendor defaults changed at installations, as follows:\n\nAre default passwords/passphrases on access points changed at installation?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "wifi-password-changed"
        }
      ]
    },
    {
      "id": "2.1.1.d",
      "name": "Strong encryption supported on firmware",
      "description": "For wireless environments connected to the cardholder data environment or transmitting cardholder data, are ALL wireless vendor defaults changed at installations, as follows:\n\nIs firmware on wireless devices updated to support strong encryption for authentication and transmission over wireless networks?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "wifi-configured-securely"
        }
      ]
    },
    {
      "id": "2.1.1.e",
      "name": "Other security-related vendor defaults changed",
      "description": "For wireless environments connected to the cardholder data environment or transmitting cardholder data, are ALL wireless vendor defaults changed at installations, as follows:\n\nAre other security-related wireless vendor defaults changed, if applicable?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "wifi-configured-securely"
        }
      ]
    },
    {
      "id": "2.2.a",
      "name": "Configuration standards developed for system components",
      "description": "Are configuration standards developed for all system components and are they consistent with industry-accepted system hardening standards? \n\nSources of industry-accepted system hardening standards may include, but are not limited to, SysAdmin Audit Network Security (SANS) Institute, National Institute of Standards Technology (NIST), International Organization for Standardization (ISO), and Center for Internet Security (CIS).",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "2.2.b",
      "name": "System configuration standards updated along with newly discovered vulnerability issues",
      "description": "Are system configuration standards updated as new vulnerability issues are identified, as defined in Requirement 6.1?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pci-standards-updated"
        }
      ]
    },
    {
      "id": "2.2.c",
      "name": "System configuration standards are applied",
      "description": "Are system configuration standards applied when new systems are configured?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "2.2.d",
      "name": "System configuration requirements",
      "description": "Do system configuration standards include all of the following: \n• Changing of all vendor-supplied defaults and elimination of unnecessary default accounts?\n• Implementing only one primary function per server to prevent functions that require different security levels from co-existing on the same server?\n• Enabling only necessary services, protocols, daemons, etc., as required for the function of the system?\n• Implementing additional security features for any required services, protocols or daemons that are considered to be insecure?\n• Configuring system security parameters to prevent misuse?\n• Removing all unnecessary functionality, such as scripts, drivers, features, subsystems, file systems, and unnecessary web servers?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "2.2.1.a",
      "name": "One primary function implemented per server",
      "description": "Is only one primary function implemented per server, to prevent functions that require different security levels from co-existing on the same server?\n\nFor example, web servers, database servers, and DNS should be implemented on separate servers.",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "dedicated-primary-function"
        }
      ]
    },
    {
      "id": "2.2.1.b",
      "name": "One primary function implemented per virtualization technology",
      "description": "If virtualization technologies are used, is only one primary function implemented per virtual system component or device?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "dedicated-primary-function"
        }
      ]
    },
    {
      "id": "2.2.2.a",
      "name": "Only necessary services enabled",
      "description": "Are only necessary services, protocols, daemons, etc. enabled as required for the function of the system (services and protocols not directly needed to perform the device’s specified function are disabled)?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "systems-configured-securely"
        }
      ]
    },
    {
      "id": "2.2.2.b",
      "name": "Enabled insecure services have justifications",
      "description": "Are all enabled insecure services, daemons, or protocols justified per documented configuration standards?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "2.2.3",
      "name": "Additional security features documented for installed insecure services",
      "description": "Are additional security features documented and implemented for any required services, protocols or daemons that are considered to be insecure?\n\nNote: Where SSL/early TLS is used, the requirements in Appendix A2 must be completed.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "2.2.4.a",
      "name": "Personnel knowledgeable about security settings for system components",
      "description": "Are system administrators and/or personnel that configure system components knowledgeable about common security parameter settings for those system components?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "system-admins-aware"
        }
      ]
    },
    {
      "id": "2.2.4.b",
      "name": "System configuration standards include common system settings",
      "description": "Are common system security parameters settings included in the system configuration standards?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "2.2.4.c",
      "name": "System parameter settings set",
      "description": "Are security parameter settings set appropriately on system components?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "systems-configured-securely"
        }
      ]
    },
    {
      "id": "2.2.5.a",
      "name": "Unnecessary functionality removed",
      "description": "Has all unnecessary functionality—such as scripts, drivers, features, subsystems, file systems, and unnecessary web servers—been removed?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "unnecesary-functions-removed"
        }
      ]
    },
    {
      "id": "2.2.5.b",
      "name": "Enabled functions documented",
      "description": "Are enabled functions documented and do they support secure configuration?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "2.2.5.c",
      "name": "Only documented functionality present",
      "description": "Is only documented functionality present on system components?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "systems-configured-securely"
        }
      ]
    },
    {
      "id": "2.3.a",
      "name": "Non-console admin access encrypted",
      "description": "Is non-console administrative access encrypted as follows: \n\nNote: Where SSL/early TLS is used, the requirements in Appendix A2 must be completed\n\nIs all non-console administrative access encrypted with strong cryptography, and is a strong encryption method invoked before the administrator’s password is requested?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "require-admin-encrypted-iaas"
        },
        {
          "id": "require-admin-encrypted-iaas-gcp"
        },
        {
          "id": "require-admin-encrypted-iaas-heroku"
        },
        {
          "id": "require-ssh-config"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "strong-cryptography-used"
        }
      ]
    },
    {
      "id": "2.3.b",
      "name": "Insecure remote login commands prevented",
      "description": "Is non-console administrative access encrypted as follows: \n\nNote: Where SSL/early TLS is used, the requirements in Appendix A2 must be completed\n\nAre system services and parameter files configured to prevent the use of Telnet and other insecure remote login commands?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "2.3.c",
      "name": "Web-based management admin access encrypted",
      "description": "Is non-console administrative access encrypted as follows: \n\nNote: Where SSL/early TLS is used, the requirements in Appendix A2 must be completed\n\nIs administrator access to web-based management interfaces encrypted with strong cryptography?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "require-admin-encrypted-iaas"
        },
        {
          "id": "require-admin-encrypted-iaas-gcp"
        },
        {
          "id": "require-admin-encrypted-iaas-heroku"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "strong-cryptography-used"
        }
      ]
    },
    {
      "id": "2.3.d",
      "name": "Strong cryptography implemented",
      "description": "Is non-console administrative access encrypted as follows: \n\nNote: Where SSL/early TLS is used, the requirements in Appendix A2 must be completed\n\nFor the technology in use, is strong cryptography implemented according to industry best practice and/or vendor recommendations?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-cryptography-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "strong-cryptography-used"
        }
      ]
    },
    {
      "id": "2.4.a",
      "name": "System components inventory maintained",
      "description": "Is an inventory maintained for systems components that are in scope for PCI DSS, including a list of hardware and software components and a description of function/use for each?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "inventory-list-descriptions"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "2.4.b",
      "name": "System components inventory kept current",
      "description": "Is the documented inventory kept current?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "inventory-list-descriptions"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "2.5",
      "name": "Security policies exist for managing vendor defaults",
      "description": "Are security policies and operational procedures for managing vendor defaults and other security parameters: \n• Documented\n• In use\n• Known to all affected parties?",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-operations-security-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "employees-accepted-operations-security-policy-bsi"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "2.6",
      "name": "Requirements for Shared Hosting Providers",
      "description": "If you are a shared hosting provider, are your systems configured to protect each entity’s (your customers’) hosted environment and cardholder data? See Appendix A: Additional PCI DSS Requirements for Shared Hosting Providers for specific requirements that must be met.",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "shared-hosting-config"
        }
      ]
    },
    {
      "id": "3.1.a",
      "name": "Data storage and retention time limited",
      "description": "Are data-retention and disposal policies, procedures, and processes implemented as follows:\n\nIs data storage amount and retention time limited to that required for legal, regulatory, and/or business requirements?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-data-management-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "3.1.b",
      "name": "Cardholder data deletion process exists",
      "description": "Are data-retention and disposal policies, procedures, and processes implemented as follows:\n\nAre there defined processes in place for securely deleting cardholder data when no longer needed for legal, regulatory, and/or business reasons?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "data-deletion-chd"
        }
      ]
    },
    {
      "id": "3.1.c",
      "name": "Cardholder data retention requirements documented",
      "description": "Are data-retention and disposal policies, procedures, and processes implemented as follows:\n\nAre there specific retention requirements for cardholder data?\n \nFor example, cardholder data needs to be held for X period for Y business reasons.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-data-management-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "3.1.d",
      "name": "Cardholder data deletion process exists for non-protocol deletions",
      "description": "Are data-retention and disposal policies, procedures, and processes implemented as follows:\n\nIs there a quarterly process for identifying and securely deleting stored cardholder data that exceeds defined retention requirements?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "data-deletion-chd"
        }
      ]
    },
    {
      "id": "3.1.e",
      "name": "Stored cardholder data meets policy requirements",
      "description": "Are data-retention and disposal policies, procedures, and processes implemented as follows:\n\nDoes all stored cardholder data meet the requirements defined in the data-retention policy?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "data-deletion-chd"
        }
      ]
    },
    {
      "id": "3.2.a",
      "name": "Business justification exists for storage of sensitive authentication data",
      "description": "For issuers and/or companies that support issuing services and store sensitive authentication data, is there a documented business justification for the storage of sensitive authentication data?",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "issuer-sad-protection"
        }
      ]
    },
    {
      "id": "3.2.b",
      "name": "Stored sensitive authentication data is secure",
      "description": "For issuers and/or companies that support issuing services and store sensitive authentication data: Is the data secured?",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "issuer-sad-protection"
        }
      ]
    },
    {
      "id": "3.2.c",
      "name": "Deleted sensitive authentication data unrecoverable",
      "description": "For non issuers, is sensitive authentication data deleted or rendered unrecoverable upon completion of the authorization process?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "retention-of-sad"
        }
      ]
    },
    {
      "id": "3.2.1",
      "name": "Cardholder data not stored after authorization",
      "description": "Do all systems adhere to the following requirements regarding non-storage of sensitive authentication data after authorization (even if encrypted):\n\nThe full contents of any track (from the magnetic stripe located on the back of a card, equivalent data contained on a chip, or elsewhere) are not stored after authorization?\nThis data is alternatively called full track, track, track 1, track 2, and magnetic-stripe data.\n\nNote: In the normal course of business, the following data elements from the magnetic stripe may need to be retained: \n• The cardholder’s name, \n• Primary account number (PAN), \n• Expiration date, and \n• Service code  \nTo minimize risk, store only these data elements as needed for business.",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "retention-of-sad"
        }
      ]
    },
    {
      "id": "3.2.2",
      "name": "Card verification code (CVE) not stored",
      "description": "Do all systems adhere to the following requirements regarding non-storage of sensitive authentication data after authorization (even if encrypted):\n\nThe card verification code or value (three-digit or four-digit number printed on the front or back of a payment card) is not stored after authorization?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "retention-of-sad"
        }
      ]
    },
    {
      "id": "3.2.3",
      "name": "Personal identification number (PIN) not stored",
      "description": "Do all systems adhere to the following requirements regarding non-storage of sensitive authentication data after authorization (even if encrypted):\n\nThe personal identification number (PIN) or the encrypted PIN block is not stored after authorization?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "retention-of-sad"
        }
      ]
    },
    {
      "id": "3.3",
      "name": "PAN masked on display",
      "description": "Is the PAN masked when displayed (the first six and last four digits are the maximum number of digits to be displayed) such that only personnel with a legitimate business need can see more than the first six/last four digits of the PAN?\n\nNote: This requirement does not supersede stricter requirements in place for displays of cardholder data—for example, legal or payment card brand requirements for point-of-sale (POS) receipts.",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pan-masked"
        }
      ]
    },
    {
      "id": "3.4",
      "name": "PAN rendered unreadable",
      "description": "Is PAN rendered unreadable anywhere it is stored (including data repositories, portable digital media, backup media, and in audit logs), by using any of the following approaches?\n• One-way hashes based on strong cryptography (hash must be of the entire PAN)\n• Truncation (hashing cannot be used to replace the truncated segment of PAN)\n• Index tokens and pads (pads must be securely stored)\n• Strong cryptography with associated key management processes and procedures.\n\nNote: It is a relatively trivial effort for a malicious individual to reconstruct original PAN data if they have access to both the truncated and hashed version of a PAN. Where hashed and truncated versions of the same PAN are present in an entity’s environment, additional controls must be in place to ensure that the hashed and truncated versions cannot be correlated to reconstruct the original PAN.",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pan-encrypted"
        }
      ]
    },
    {
      "id": "3.4.1.a",
      "name": "Encrypted file access managed separately",
      "description": "If disk encryption (rather than file- or column-level database encryption) is used, is access managed as follows:\n\nNote: This requirement applies in addition to all other PCI DSS encryption and key management requirements.\n\nIs logical access to encrypted file systems managed separately and independently of native operating system authentication and access control mechanisms (for example, by not using local user account databases or general network login credentials)?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "disk-encryption-config"
        }
      ]
    },
    {
      "id": "3.4.1.b",
      "name": "Cryptographic keys stored securely",
      "description": "If disk encryption (rather than file- or column-level database encryption) is used, is access managed as follows:\n\nNote: This requirement applies in addition to all other PCI DSS encryption and key management requirements.\n\nAre cryptographic keys stored securely (for example, stored on removable media that is adequately protected with strong access controls)?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "disk-encryption-config"
        }
      ]
    },
    {
      "id": "3.4.1.c",
      "name": "Encrypted storage on removable media",
      "description": "If disk encryption (rather than file- or column-level database encryption) is used, is access managed as follows:\n\nNote: This requirement applies in addition to all other PCI DSS encryption and key management requirements.\n\nIs cardholder data on removable media encrypted wherever stored?\n\nNote: If disk encryption is not used to encrypt removable media, the data stored on this media will need to be rendered unreadable through some other method.",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pan-encrypted"
        }
      ]
    },
    {
      "id": "3.5.1",
      "name": "Cryptographic architecture documented",
      "description": "Are keys used to secure stored cardholder data protected against disclosure and misuse as follows:\n\nNote: This requirement applies to keys used to encrypt stored cardholder data, and also applies to key-encrypting keys used to protect data-encrypting keys. Such key-encrypting keys must be at least as strong as the data-encrypting key.\n\n(b)\tFor service providers only: Is a documented description of the cryptographic architecture maintained that includes:\n•Details of all algorithms, protocols, and keys used for the protection of cardholder data, including key strength and expiry date,\n•Description of the key usage for each key,\n•Inventory of any HSMs and other SCDs used for key management?",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "sp-key-management"
        }
      ]
    },
    {
      "id": "3.5.2",
      "name": "Cryptographic keys access restricted",
      "description": "Are keys used to secure stored cardholder data protected against disclosure and misuse as follows:\n\nNote: This requirement applies to keys used to encrypt stored cardholder data, and also applies to key-encrypting keys used to protect data-encrypting keys. Such key-encrypting keys must be at least as strong as the data-encrypting key.\n\nIs access to cryptographic keys restricted to the fewest number of custodians necessary?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "key-custodians-limited"
        }
      ]
    },
    {
      "id": "3.5.3",
      "name": "Secret cryptographic keys used for encryption",
      "description": "Are keys used to secure stored cardholder data protected against disclosure and misuse as follows:\n\nNote: This requirement applies to keys used to encrypt stored cardholder data, and also applies to key-encrypting keys used to protect data-encrypting keys. Such key-encrypting keys must be at least as strong as the data-encrypting key.\n\nAre secret and private cryptographic keys used to encrypt/decrypt cardholder data stored in one (or more) of the following forms at all times?\n• Encrypted with a key-encrypting key that is at least as strong as the data-encrypting key, and that is stored separately from the data-encrypting key\n• Within a secure cryptographic device (such as a hardware (host) security module (HSM) or PTS-approved point-of-interaction device)\n• As at least two full-length key components or key shares, in accordance with an industry-accepted method.\n\nNote: It is not required that public keys be stored in one of these forms.",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "encryption-keys-protected"
        }
      ]
    },
    {
      "id": "3.5.4",
      "name": "Cryptographic key storage location",
      "description": "Are keys used to secure stored cardholder data protected against disclosure and misuse as follows:\n\nNote: This requirement applies to keys used to encrypt stored cardholder data, and also applies to key-encrypting keys used to protect data-encrypting keys. Such key-encrypting keys must be at least as strong as the data-encrypting key.\n\nAre cryptographic keys stored in the fewest possible locations?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "encryption-keys-protected"
        }
      ]
    },
    {
      "id": "3.6.a",
      "name": "Key management processes documented",
      "description": "Are all key-management processes and procedures fully documented and implemented for cryptographic keys used for encryption of cardholder data?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-cryptography-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "encryption-standard-developed"
        }
      ]
    },
    {
      "id": "3.6.b",
      "name": "Key sharing process documented",
      "description": "(b)\tFor service providers only: If keys are shared with customers for transmission or storage of cardholder data, is documentation provided to customers that includes guidance on how to securely transmit, store and update customer’s keys, in accordance with requirements 3.6.1 through 3.6.8 below?",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "sp-customer-key-management"
        }
      ]
    },
    {
      "id": "3.6.1",
      "name": "Key procedures include strength requirements",
      "description": "Are key-management processes and procedures implemented to require the following:\n\nDo cryptographic key procedures include the generation of strong cryptographic keys?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-cryptography-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "cryptography-policies-policies"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "3.6.2",
      "name": "Key procedures include secure distribution",
      "description": "Are key-management processes and procedures implemented to require the following:\n\nDo cryptographic key procedures include secure cryptographic key distribution?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-cryptography-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "3.6.3",
      "name": "Key procedures include secure storage",
      "description": "Are key-management processes and procedures implemented to require the following:\n\nDo cryptographic key procedures include secure cryptographic key storage?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-cryptography-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "3.6.4",
      "name": "Key procedures include secure changes",
      "description": "Are key-management processes and procedures implemented to require the following:\n\nDo cryptographic key procedures include cryptographic key changes for keys that have reached the end of their defined cryptoperiod (for example, after a defined period of time has passed and/or after a certain amount of cipher-text has been produced by a given key), as defined by the associated application vendor or key owner, and based on industry best practices and guidelines (for example, NIST Special Publication 800-57)?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "encryption-standard-developed"
        }
      ]
    },
    {
      "id": "3.6.5.a",
      "name": "Key procedures include retirement or replacement",
      "description": "Are key-management processes and procedures implemented to require the following:\n\nDo cryptographic key procedures include retirement or replacement (for example, archiving, destruction, and/or revocation) of cryptographic keys when the integrity of the key has been weakened (for example, departure of an employee with knowledge of a clear-text key)?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "encryption-standard-developed"
        }
      ]
    },
    {
      "id": "3.6.5.b",
      "name": "Key procedures include replacing compromised keys",
      "description": "Are key-management processes and procedures implemented to require the following:\n\nDo cryptographic key procedures include replacement of known or suspected compromised keys?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "encryption-standard-developed"
        }
      ]
    },
    {
      "id": "3.6.5.c",
      "name": "Retained retired keys are used only for decryption",
      "description": "Are key-management processes and procedures implemented to require the following:\n\nIf retired or replaced cryptographic keys are retained, are these keys only used for decryption/verification purposes, and not used for encryption operations?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "encryption-standard-developed"
        }
      ]
    },
    {
      "id": "3.6.6",
      "name": "Key procedures include split knowledge and dual control",
      "description": "Are key-management processes and procedures implemented to require the following:\n\nIf manual clear-text key-management operations are used, do cryptographic key procedures include split knowledge and dual control of cryptographic keys as follows:\n• Do split knowledge procedures require that key components are under the control of at least two people who only have knowledge of their own key components?\nAND\n• Do dual control procedures require that at least two people are required to perform any key management operations and no one person has access to the authentication materials (for example, passwords or keys) of another?\n\nNote: Examples of manual key management operations include, but are not limited to: key generation, transmission, loading, storage and destruction.",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "cleartext-key-management"
        }
      ]
    },
    {
      "id": "3.6.7",
      "name": "Key procedures include unauthorized substitution prevention",
      "description": "Are key-management processes and procedures implemented to require the following:\n\nDo cryptographic key procedures include the prevention of unauthorized substitution of cryptographic keys?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "cleartext-key-management"
        }
      ]
    },
    {
      "id": "3.6.8",
      "name": "Key custodians acknowledge responsibilities",
      "description": "Are key-management processes and procedures implemented to require the following:\n\nAre cryptographic key custodians required to formally acknowledge (in writing or electronically) that they understand and accept their key-custodian responsibilities?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "key-custodians-acknowledgement"
        }
      ]
    },
    {
      "id": "3.7",
      "name": "Security policies exist for protecting card holder data",
      "description": "Are security policies and operational procedures for protecting stored cardholder data:\n• Documented\n• In use\n• Known to all affected parties?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-cryptography-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "employees-accepted-cryptography-policy-bsi"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "4.1.a",
      "name": "Protocols exist to protect cardholder data during transmission",
      "description": "Are strong cryptography and security protocols used to safeguard sensitive cardholder data during transmission over open, public networks? \n\nNote: Where SSL/early TLS is used, the requirements in Appendix A2 must be completed.\nExamples of open, public networks include but are not limited to the Internet; wireless technologies, including 802.11 and Bluetooth; cellular technologies, for example, Global System for Mobile communications (GSM), Code division multiple access (CDMA); and General Packet Radio Service (GPRS).",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "ssl-payment-pages"
        }
      ]
    },
    {
      "id": "4.1.b",
      "name": "Only trusted keys accepted\nfor transmission",
      "description": "Are only trusted keys and/or certificates accepted?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "ssl-payment-pages"
        }
      ]
    },
    {
      "id": "4.1.c",
      "name": "Secure configurations exist for transmission",
      "description": "Are security protocols implemented to use only secure configurations, and to not support insecure versions or configurations?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "ssl-payment-pages"
        }
      ]
    },
    {
      "id": "4.1.d",
      "name": "Encryption strength implemented for transmission",
      "description": "Is the proper encryption strength implemented for the encryption methodology in use (check vendor recommendations/best practices)?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-cryptography-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "ssl-payment-pages"
        }
      ]
    },
    {
      "id": "4.1.e",
      "name": "TLS enabled for cardholder data",
      "description": "For TLS implementations, is TLS enabled whenever cardholder data is transmitted or received? \n\nFor example, for browser-based implementations:\n• “HTTPS” appears as the browser Universal Record Locator (URL) protocol, and\n• Cardholder data is only requested if “HTTPS” appears as part of the URL.",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "ssl-payment-pages"
        }
      ]
    },
    {
      "id": "4.1.1",
      "name": "Strong encryption for transmission implemented",
      "description": "Are industry best practices used to implement strong encryption for authentication and transmission for wireless networks transmitting cardholder data or connected to the cardholder data environment?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "ssl-no-warnings"
        },
        {
          "id": "ssl-used-expiry"
        },
        {
          "id": "ssl-used-unittest"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "4.2.a",
      "name": "PANs rendered unreadable in transmission",
      "description": "Are PANs rendered unreadable or secured with strong cryptography whenever they are sent via end-user messaging technologies (for example, e-mail, instant messaging, SMS, chat, etc.)?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "strong-encryption-used-msg"
        }
      ]
    },
    {
      "id": "4.2.b",
      "name": "Policies to prevent transmission of unprotected PANs exist",
      "description": "Are policies in place that state that unprotected PANs are not to be sent via end-user messaging technologies?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "4.3",
      "name": "Security policies for encrypting cardholder data transmission exist",
      "description": "Are security policies and operational procedures for encrypting transmissions of cardholder data:\n• Documented\n• In use\n• Known to all affected parties?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-cryptography-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "employees-accepted-cryptography-policy-bsi"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "5.1",
      "name": "Anti-virus software deployed",
      "description": "Is anti-virus software deployed on all systems commonly affected by malicious software?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "malware-detection-workstations"
        },
        {
          "id": "malware-detection-workstations-jamf"
        },
        {
          "id": "malware-detection-workstations-kandji"
        },
        {
          "id": "malware-detection-workstations-microsoft-endpoint-manager"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "malware-configuration"
        }
      ]
    },
    {
      "id": "5.1.1",
      "name": "Anti-virus capabilities",
      "description": "Are anti-virus programs capable of detecting, removing, and protecting against all known types of malicious software (for example, viruses, Trojans, worms, spyware, adware, and rootkits)?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "malware-configuration"
        }
      ]
    },
    {
      "id": "5.1.2",
      "name": "Malware threats regularly evaluated",
      "description": "Are periodic evaluations performed to identify and evaluate evolving malware threats in order to confirm whether those systems considered to not be commonly affected by malicious software continue as such?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pci-malware-exception"
        }
      ]
    },
    {
      "id": "5.2.a",
      "name": "Anti-virus software definitions kept current",
      "description": "Are all anti-virus mechanisms maintained as follows:\n\nAre all anti-virus software and definitions kept current?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "malware-configuration"
        }
      ]
    },
    {
      "id": "5.2.b",
      "name": "Automatic updates and period scans performed",
      "description": "Are all anti-virus mechanisms maintained as follows:\n\nAre automatic updates and periodic scans enabled and being performed?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "malware-configuration"
        }
      ]
    },
    {
      "id": "5.2.c",
      "name": "Anti-virus audit logs retained",
      "description": "Are all anti-virus mechanisms maintained as follows:\n\nAre all anti-virus mechanisms generating audit logs, and are logs retained in accordance with PCI DSS Requirement 10.7?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "logs-retained-for-twelve-months-config"
        },
        {
          "id": "logs-retained-for-twelve-months-config-gcp"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "malware-configuration"
        }
      ]
    },
    {
      "id": "5.3",
      "name": "Anti-virus actively running and alteration restricted",
      "description": "Are all anti-virus mechanisms:\n• Actively running?\n• Unable to be disabled or altered by users?\n\nNote: Anti-virus solutions may be temporarily disabled only if there is legitimate technical need, as authorized by management on a case-by-case basis. If anti-virus protection needs to be disabled for a specific purpose, it must be formally authorized. Additional security measures may also need to be implemented for the period of time during which anti-virus protection is not active.",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "malware-configuration"
        }
      ]
    },
    {
      "id": "5.4",
      "name": "Security policies against malware exist",
      "description": "Are security policies and operational procedures for protecting systems against malware: \n• Documented\n• In use\n• Known to all affected parties?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-information-security-policy-bsi-exists"
        },
        {
          "id": "approved-operations-security-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "employees-accepted-information-security-policy-bsi"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "6.1",
      "name": "Security vulnerabilities identification process exists",
      "description": "Is there a process to identify security vulnerabilities, including the following:\n• Using reputable outside sources for vulnerability information? \n• Assigning a risk ranking to vulnerabilities that includes identification of all “high” risk and “critical” vulnerabilities? \n\nNote: Risk rankings should be based on industry best practices as well as consideration of potential impact. For example, criteria for ranking vulnerabilities may include consideration of the CVSS base score and/or the classification by the vendor, and/or type of systems affected.\nMethods for evaluating vulnerabilities and assigning risk ratings will vary based on an organization’s environment and risk assessment strategy.  Risk rankings should, at a minimum, identify all vulnerabilities considered to be a “high risk” to the environment. In addition to the risk ranking, vulnerabilities may be considered “critical” if they pose an imminent threat to the environment, impact critical systems, and/or would result in a potential compromise if not addressed. Examples of critical systems may include security systems, public-facing devices and systems, databases, and other systems that store, process or transmit cardholder data.",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "external-alerts-reviewed"
        }
      ]
    },
    {
      "id": "6.2.a",
      "name": "Vendor-supplied security patches installed",
      "description": "Are all system components and software protected from known vulnerabilities by installing applicable vendor-supplied security patches?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-operations-security-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "6.2.b",
      "name": "Security patches installed within one month",
      "description": "Are critical security patches installed within one month of release?\n\nNote: Critical security patches should be identified according to the risk ranking process defined in Requirement 6.1.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-operations-security-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pci-critical-security-patches"
        }
      ]
    },
    {
      "id": "6.3.a",
      "name": "Software development best practices",
      "description": "Are software- development processes based on industry standards and/or best practices?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "approved-secure-development-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "6.3.b",
      "name": "Software development includes info sec",
      "description": "Is information security included throughout the software-development life cycle?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "approved-secure-development-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pci-code-review-performed"
        }
      ]
    },
    {
      "id": "6.3.c",
      "name": "Software development apps follow PCI DSS",
      "description": "Are software applications developed in accordance with PCI DSS (for example, secure authentication and logging)?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "approved-secure-development-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pci-code-review-performed"
        }
      ]
    },
    {
      "id": "6.3.1",
      "name": "Accesses removed before release",
      "description": "Do software development processes ensure the following at 6.3.1 - 6.3.2:\n\nAre development, test, and/or custom application accounts, user IDs, and passwords removed before applications become active or are released to customers?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "approved-secure-development-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pci-code-review-performed"
        }
      ]
    },
    {
      "id": "6.3.2",
      "name": "Custom code reviewed before release",
      "description": "Do software development processes ensure the following at 6.3.1 - 6.3.2:\n\nIs all custom code reviewed prior to release to production or customers to identify any potential coding vulnerability (using either manual or automated processes as follows:\n• Are code changes reviewed by individuals other than the originating code author, and by individuals who are knowledgeable about code review techniques and secure coding practices?\n• Do code reviews ensure code is developed according to secure coding guidelines?\n• Are appropriate corrections are implemented prior to release? \n• Are code review results are reviewed and approved by management prior to release?\n\nNote: This requirement for code reviews applies to all custom code (both internal and public-facing), as part of the system development life cycle. Code reviews can be conducted by knowledgeable internal personnel or third parties. Public-facing web applications are also subject to additional controls, to address ongoing threats and vulnerabilities after implementation, as defined at PCI DSS Requirement 6.6.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "approved-secure-development-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pci-code-review-performed"
        }
      ]
    },
    {
      "id": "6.4.1.a",
      "name": "Development and production environments separated",
      "description": "Are change control processes and procedures followed for all changes to system components to include the following:\n\nAre development/test environments separate from the production environment?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-operations-security-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "6.4.1.b",
      "name": "Access controls enforce separation",
      "description": "Are change control processes and procedures followed for all changes to system components to include the following:\n\nIs access control in place to enforce the separation between the development/test environments and the production environment?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-operations-security-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "6.4.2",
      "name": "Assigned personel separation of duty",
      "description": "Are change control processes and procedures followed for all changes to system components to include the following:\n\nIs there separation of duties between personnel assigned to the development/test environments and those assigned to the production environment?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "approved-secure-development-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pci-code-review-performed"
        }
      ]
    },
    {
      "id": "6.4.3",
      "name": "PAN not used for testing",
      "description": "Are change control processes and procedures followed for all changes to system components to include the following:\n\nAre production data (live PANs) not used for testing or development?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "approved-secure-development-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pci-code-review-performed"
        }
      ]
    },
    {
      "id": "6.4.4",
      "name": "Test accounts removed before release",
      "description": "Are change control processes and procedures followed for all changes to system components to include the following:\n\nAre test data and accounts removed from system components before the system becomes active / goes into production?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "approved-secure-development-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pci-code-review-performed"
        }
      ]
    },
    {
      "id": "6.4.5.a",
      "name": "Change control procedures documented",
      "description": "Are change control processes and procedures followed for all changes to system components to include the following:\n\nAre change-control procedures documented and require the following? \n• Documentation of impact\n• Documented change control approval by authorized parties\n• Functionality testing to verify that the change does not adversely impact the security of the system\n• Back-out procedures",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "approved-secure-development-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pci-application-change-control"
        }
      ]
    },
    {
      "id": "6.4.5.1",
      "name": "Change control documentation includes impact",
      "description": "Are the following performed and documented for all changes:\n\nDocumentation of impact?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "approved-secure-development-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pci-application-change-control"
        }
      ]
    },
    {
      "id": "6.4.5.2",
      "name": "Change control documentation approved",
      "description": "Are the following performed and documented for all changes:\n\nDocumented approval by authorized parties?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "approved-secure-development-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pci-application-change-control"
        }
      ]
    },
    {
      "id": "6.4.5.3.a",
      "name": "Functionality testing exists",
      "description": "Are the following performed and documented for all changes:\n\nFunctionality testing to verify that the change does not adversely impact the security of the system?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "approved-secure-development-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pci-application-change-control"
        }
      ]
    },
    {
      "id": "6.4.5.3.b",
      "name": "Custom code complies with PCI DSS Req 6.5",
      "description": "Are the following performed and documented for all changes:\n\nFor custom code changes, testing of updates for compliance with PCI DSS Requirement 6.5 before being deployed into production?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "approved-secure-development-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pci-application-change-control"
        }
      ]
    },
    {
      "id": "6.4.5.4",
      "name": "Back out procedures",
      "description": "Are the following performed and documented for all changes:\n\nBack-out procedures?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "approved-secure-development-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pci-application-change-control"
        }
      ]
    },
    {
      "id": "6.4.6",
      "name": "PCI DSS requirements implemented\nupon change",
      "description": "Upon completion of a significant change, are all relevant PCI DSS requirements implemented on all new or changed systems and networks, and documentation updated as applicable?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "approved-secure-development-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pci-application-change-control"
        }
      ]
    },
    {
      "id": "6.5.a",
      "name": "Common coding vulnerabilities addressed",
      "description": "Do software-development processes address common coding vulnerabilities?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "approved-secure-development-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pci-code-review-performed"
        }
      ]
    },
    {
      "id": "6.5.b",
      "name": "Developers trained annually",
      "description": "Are developers trained at least annually in up-to-date secure coding techniques, including how to avoid common coding vulnerabilities?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "approved-secure-development-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "developers-receive-pci-training"
        }
      ]
    },
    {
      "id": "6.5.1",
      "name": "Injection flaws addressed",
      "description": "Are applications developed based on secure coding guidelines to protect applications from, at a minimum, the following vulnerabilities:\n\nNote: The vulnerabilities listed at 6.5.1 through 6.5.10 were current with industry best practices when this version of PCI DSS was published. However, as industry best practices for vulnerability management are update d (for example, the Open Web Application Security Project (OWASP) Guide, SANS CWE Top 25, CERT Secure Coding, etc.), the current best practices must be used for these requirements.\n\nDo coding techniques address injection flaws, particularly SQL injection?\n\nNote: Also consider OS Command Injection, LDAP and XPath injection flaws as well as other injection flaws.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "approved-secure-development-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pci-code-review-performed"
        }
      ]
    },
    {
      "id": "6.5.2",
      "name": "Buffer overflow vulnerabilities addressed",
      "description": "Are applications developed based on secure coding guidelines to protect applications from, at a minimum, the following vulnerabilities:\n\nNote: The vulnerabilities listed at 6.5.1 through 6.5.10 were current with industry best practices when this version of PCI DSS was published. However, as industry best practices for vulnerability management are update d (for example, the Open Web Application Security Project (OWASP) Guide, SANS CWE Top 25, CERT Secure Coding, etc.), the current best practices must be used for these requirements.\n\nDo coding techniques address buffer overflow vulnerabilities?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "approved-secure-development-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pci-code-review-performed"
        }
      ]
    },
    {
      "id": "6.5.3",
      "name": "Insecure cryptographic storage addressed",
      "description": "Are applications developed based on secure coding guidelines to protect applications from, at a minimum, the following vulnerabilities:\n\nNote: The vulnerabilities listed at 6.5.1 through 6.5.10 were current with industry best practices when this version of PCI DSS was published. However, as industry best practices for vulnerability management are update d (for example, the Open Web Application Security Project (OWASP) Guide, SANS CWE Top 25, CERT Secure Coding, etc.), the current best practices must be used for these requirements.\n\nDo coding techniques address insecure cryptographic storage?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "approved-secure-development-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pci-code-review-performed"
        }
      ]
    },
    {
      "id": "6.5.4",
      "name": "Insecure communications addressed",
      "description": "Are applications developed based on secure coding guidelines to protect applications from, at a minimum, the following vulnerabilities:\n\nNote: The vulnerabilities listed at 6.5.1 through 6.5.10 were current with industry best practices when this version of PCI DSS was published. However, as industry best practices for vulnerability management are update d (for example, the Open Web Application Security Project (OWASP) Guide, SANS CWE Top 25, CERT Secure Coding, etc.), the current best practices must be used for these requirements.\n\nDo coding techniques address insecure communications?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "approved-secure-development-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pci-code-review-performed"
        }
      ]
    },
    {
      "id": "6.5.5",
      "name": "Improper error handling addressed",
      "description": "Are applications developed based on secure coding guidelines to protect applications from, at a minimum, the following vulnerabilities:\n\nNote: The vulnerabilities listed at 6.5.1 through 6.5.10 were current with industry best practices when this version of PCI DSS was published. However, as industry best practices for vulnerability management are update d (for example, the Open Web Application Security Project (OWASP) Guide, SANS CWE Top 25, CERT Secure Coding, etc.), the current best practices must be used for these requirements.\n\nDo coding techniques address improper error handling?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "approved-secure-development-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pci-code-review-performed"
        }
      ]
    },
    {
      "id": "6.5.6",
      "name": "High risk vulnerabilities addressed",
      "description": "Are applications developed based on secure coding guidelines to protect applications from, at a minimum, the following vulnerabilities:\n\nNote: The vulnerabilities listed at 6.5.1 through 6.5.10 were current with industry best practices when this version of PCI DSS was published. However, as industry best practices for vulnerability management are update d (for example, the Open Web Application Security Project (OWASP) Guide, SANS CWE Top 25, CERT Secure Coding, etc.), the current best practices must be used for these requirements.\n\nDo coding techniques address all “high risk” vulnerabilities identified in the vulnerability identification process (as defined in PCI DSS Requirement 6.1)?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "approved-secure-development-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pci-code-review-performed"
        }
      ]
    },
    {
      "id": "6.5.7",
      "name": "Cross-site scripting (XSS) vulnerabilities addressed",
      "description": "Are applications developed based on secure coding guidelines to protect applications from, at a minimum, the following vulnerabilities:\n\nNote: The vulnerabilities listed at 6.5.1 through 6.5.10 were current with industry best practices when this version of PCI DSS was published. However, as industry best practices for vulnerability management are update d (for example, the Open Web Application Security Project (OWASP) Guide, SANS CWE Top 25, CERT Secure Coding, etc.), the current best practices must be used for these requirements.\n\nDo coding techniques address cross-site scripting (XSS) vulnerabilities?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "approved-secure-development-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pci-code-review-performed"
        }
      ]
    },
    {
      "id": "6.5.8",
      "name": "Improper access controls addressed",
      "description": "Are applications developed based on secure coding guidelines to protect applications from, at a minimum, the following vulnerabilities:\n\nNote: The vulnerabilities listed at 6.5.1 through 6.5.10 were current with industry best practices when this version of PCI DSS was published. However, as industry best practices for vulnerability management are update d (for example, the Open Web Application Security Project (OWASP) Guide, SANS CWE Top 25, CERT Secure Coding, etc.), the current best practices must be used for these requirements.\n\nDo coding techniques address improper access control such as insecure direct object references, failure to restrict URL access, directory traversal, and failure to restrict user access to functions?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "approved-secure-development-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pci-code-review-performed"
        }
      ]
    },
    {
      "id": "6.5.9",
      "name": "Cross-site request forgery (CSRF) addressed",
      "description": "Are applications developed based on secure coding guidelines to protect applications from, at a minimum, the following vulnerabilities:\n\nNote: The vulnerabilities listed at 6.5.1 through 6.5.10 were current with industry best practices when this version of PCI DSS was published. However, as industry best practices for vulnerability management are update d (for example, the Open Web Application Security Project (OWASP) Guide, SANS CWE Top 25, CERT Secure Coding, etc.), the current best practices must be used for these requirements.\n\nDo coding techniques address cross-site request forgery (CSRF)?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "approved-secure-development-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pci-code-review-performed"
        }
      ]
    },
    {
      "id": "6.5.10",
      "name": "Broken authentication and session management addressed",
      "description": "Are applications developed based on secure coding guidelines to protect applications from, at a minimum, the following vulnerabilities:\n\nNote: The vulnerabilities listed at 6.5.1 through 6.5.10 were current with industry best practices when this version of PCI DSS was published. However, as industry best practices for vulnerability management are update d (for example, the Open Web Application Security Project (OWASP) Guide, SANS CWE Top 25, CERT Secure Coding, etc.), the current best practices must be used for these requirements.\n\nDo coding techniques address broken authentication and session management?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "approved-secure-development-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pci-code-review-performed"
        }
      ]
    },
    {
      "id": "6.6",
      "name": "Threats and vulnerabilities regularly addressed",
      "description": "For public-facing web applications, are new threats and vulnerabilities addressed on an ongoing basis, and are these applications protected against known attacks by applying either of the following methods? \n• Reviewing public-facing web applications via manual or automated application vulnerability security assessment tools or methods, as follows:\n   \\- At least annually\n   \\- After any changes\n   \\- By an organization that specializes in application security\n   \\- That, at a minimum, all vulnerabilities in Requirement 6.5 are included in the assessment \n   \\- That all vulnerabilities are corrected\n   \\- That the application is re-evaluated after the corrections\n\nNote: This assessment is not the same as the vulnerability scans performed for Requirement 11.2.\n– OR – \n• Installing an automated technical solution that detects and prevents web-based attacks (for example, a web-application firewall) as follows: \n   \\- Is situated in front of public-facing web applications to detect and prevent web-based attacks.\n   \\- Is actively running and up to date as applicable.\n   \\- Is generating audit logs. \n   \\- Is configured to either block web-based attacks, or generate an alert that is immediately investigated.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "approved-secure-development-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "waf-enabled-and-configured"
        }
      ]
    },
    {
      "id": "6.7",
      "name": "Security policies and procedures exist",
      "description": "Are security policies and operational procedures for developing and maintaining secure systems and applications:\n• Documented\n• In use\n• Known to all affected parties?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "approved-secure-development-policy-bsi-exists"
        },
        {
          "id": "employees-accepted-secure-development-policy-bsi"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "7.1",
      "name": "Cardholder data access policy requirements",
      "description": "Is there a written policy for access control that incorporates the following? \n• Defining access needs and privilege assignments for each role \n• Restriction of access to privileged user IDs to least privileges necessary to perform job responsibilities, \n• Assignment of access based on individual personnel’s job classification and function \n• Documented approval (electronically or in writing) by authorized parties for all access, including listing of specific privileges approved\n\nIs access to system components and cardholder data limited to only those individuals whose jobs require such access, as follows:\nIs there a written policy for access control that incorporates the following?  \n• Defining access needs and privilege assignments \nfor each role  \n• Restriction of access to privileged user IDs to \nleast privileges necessary to perform job \nresponsibilities,  \n• Assignment of access based on individual \npersonnel’s job classification and function  \n• Documented approval (electronically or in writing) \nby authorized parties for all access, including \nlisting of specific privileges approved",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "7.1.1",
      "name": "Access needs defined",
      "description": "Are access needs for each role defined, including:\n• System components and data resources that each role needs to access for their job function?\n• Level of privilege required (for example, user, administrator, etc.) for accessing resources?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-access-defined"
        }
      ]
    },
    {
      "id": "7.1.2",
      "name": "Privileged user ID access restricted",
      "description": "Is access to privileged user IDs restricted as follows: \n• To least privileges necessary to perform job responsibilities?\n• Assigned only to roles that specifically require that privileged access?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-access-defined"
        }
      ]
    },
    {
      "id": "7.1.3",
      "name": "Access assigned based on role",
      "description": "Is access assigned based on individual personnel’s job classification and function?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-access-defined"
        }
      ]
    },
    {
      "id": "7.1.4",
      "name": "Authorization for access exists",
      "description": "Is documented approval by authorized parties required, specifying required privileges?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "access-approval-required"
        }
      ]
    },
    {
      "id": "7.2.1",
      "name": "Access control exists on system components",
      "description": "Is an access control system(s) in place for system components to restrict access based on a user’s need to know, and is it set to “deny all” unless specifically allowed, as follows:\n\nIs the access control system(s) in place on all system components?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-access-defined"
        }
      ]
    },
    {
      "id": "7.2.2",
      "name": "Access control exists based on role",
      "description": "Is an access control system(s) in place for system components to restrict access based on a user’s need to know, and is it set to “deny all” unless specifically allowed, as follows:\n\nIs the access control system(s) configured to enforce privileges assigned to individuals based on job classification and function?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-access-defined"
        }
      ]
    },
    {
      "id": "7.2.3",
      "name": "Default \"deny-all\" setting exists",
      "description": "Is an access control system(s) in place for system components to restrict access based on a user’s need to know, and is it set to “deny all” unless specifically allowed, as follows:\n\nDoes the access control system(s) have a default “deny-all” setting?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-access-defined"
        }
      ]
    },
    {
      "id": "7.3",
      "name": "Security policy for cardholder data exists",
      "description": "Are security policies and operational procedures for restricting access to cardholder data:\n• Documented\n• In use\n• Known to all affected parties?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "employees-accepted-access-control-policy-bsi"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "8.1.1",
      "name": "Users assigned unique ID",
      "description": "Are policies and procedures for user identification management controls defined and in place for non-consumer users and administrators on all system components, as follows:\n\nAre all users assigned a unique ID before allowing them to access system components or cardholder data?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "8.1.2",
      "name": "User IDs modified only as authorized",
      "description": "Are policies and procedures for user identification management controls defined and in place for non-consumer users and administrators on all system components, as follows:\n\nAre additions, deletions, and modifications of user IDs, credentials, and other identifier objects controlled such that user IDs are implemented only as authorized (including with specified privileges)?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pci-access-defined"
        }
      ]
    },
    {
      "id": "8.1.3",
      "name": "Terminated user access removed",
      "description": "Are policies and procedures for user identification management controls defined and in place for non-consumer users and administrators on all system components, as follows:\n\nIs access for any terminated users immediately deactivated or removed?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "access-removed-infra"
        },
        {
          "id": "access-removed-versioncontrol"
        },
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "asana-account-access-removed-on-termination"
        },
        {
          "id": "aws-account-access-removed-on-termination"
        },
        {
          "id": "azuredevops-account-access-removed-on-termination"
        },
        {
          "id": "bitbucket-account-access-removed-on-termination"
        },
        {
          "id": "clickup-account-access-removed-on-termination"
        },
        {
          "id": "clubhouse-account-access-removed-on-termination"
        },
        {
          "id": "datadog-account-access-removed-on-termination"
        },
        {
          "id": "github-account-access-removed-on-termination"
        },
        {
          "id": "gitlab-account-access-removed-on-termination"
        },
        {
          "id": "heroku-account-access-removed-on-termination"
        },
        {
          "id": "jamf-account-access-removed-on-termination"
        },
        {
          "id": "jira-account-access-removed-on-termination"
        },
        {
          "id": "knowbe4-account-access-removed-on-termination"
        },
        {
          "id": "linear-account-access-removed-on-termination"
        },
        {
          "id": "mongoatlas-account-access-removed-on-termination"
        },
        {
          "id": "pivotaltracker-account-access-removed-on-termination"
        },
        {
          "id": "slack-account-access-removed-on-termination"
        },
        {
          "id": "snyk-account-access-removed-on-termination"
        },
        {
          "id": "trello-account-access-removed-on-termination"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "8.1.4",
      "name": "Inactive accounts removed within 90 days",
      "description": "Are policies and procedures for user identification management controls defined and in place for non-consumer users and administrators on all system components, as follows:\n\nAre inactive user accounts either removed or disabled within 90 days?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "access-removed-infra"
        },
        {
          "id": "access-removed-versioncontrol"
        },
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "asana-account-access-removed-on-termination"
        },
        {
          "id": "aws-account-access-removed-on-termination"
        },
        {
          "id": "azuredevops-account-access-removed-on-termination"
        },
        {
          "id": "bitbucket-account-access-removed-on-termination"
        },
        {
          "id": "clickup-account-access-removed-on-termination"
        },
        {
          "id": "clubhouse-account-access-removed-on-termination"
        },
        {
          "id": "datadog-account-access-removed-on-termination"
        },
        {
          "id": "github-account-access-removed-on-termination"
        },
        {
          "id": "gitlab-account-access-removed-on-termination"
        },
        {
          "id": "heroku-account-access-removed-on-termination"
        },
        {
          "id": "jamf-account-access-removed-on-termination"
        },
        {
          "id": "jira-account-access-removed-on-termination"
        },
        {
          "id": "knowbe4-account-access-removed-on-termination"
        },
        {
          "id": "linear-account-access-removed-on-termination"
        },
        {
          "id": "mongoatlas-account-access-removed-on-termination"
        },
        {
          "id": "pivotaltracker-account-access-removed-on-termination"
        },
        {
          "id": "slack-account-access-removed-on-termination"
        },
        {
          "id": "snyk-account-access-removed-on-termination"
        },
        {
          "id": "trello-account-access-removed-on-termination"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "8.1.5.a",
      "name": "Third party access enabled only as needed",
      "description": "Are policies and procedures for user identification management controls defined and in place for non-consumer users and administrators on all system components, as follows:\n\nAre accounts used by third parties to access, support, or maintain system components via remote access enabled only during the time period needed and disabled when not in use?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "vendors-monitored-and-disabled"
        }
      ]
    },
    {
      "id": "8.1.5.b",
      "name": "Third party access monitored",
      "description": "Are policies and procedures for user identification management controls defined and in place for non-consumer users and administrators on all system components, as follows:\n\nAre third party remote access accounts monitored when in use?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "vendors-monitored-and-disabled"
        }
      ]
    },
    {
      "id": "8.1.6.a",
      "name": "Failed attempt lock out",
      "description": "Are policies and procedures for user identification management controls defined and in place for non-consumer users and administrators on all system components, as follows:\n\nAre repeated access attempts limited by locking out the user ID after no more than six attempts?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-password-settings-enabled"
        }
      ]
    },
    {
      "id": "8.1.6.b",
      "name": "Failed password attempt log out",
      "description": "Are policies and procedures for user identification management controls defined and in place for non-consumer users and administrators on all system components, as follows:\n\n(b)\tFor service providers only: Are non-consumer customer passwords temporarily locked-out after not more than six invalid access attempts?",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "service-provider-passwords"
        }
      ]
    },
    {
      "id": "8.1.7",
      "name": "Lock out duration",
      "description": "Are policies and procedures for user identification management controls defined and in place for non-consumer users and administrators on all system components, as follows:\n\nOnce a user account is locked out, is the lockout duration set to a minimum of 30 minutes or until an administrator enables the user ID?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-password-settings-enabled"
        }
      ]
    },
    {
      "id": "8.1.8",
      "name": "Session lock out re-authentication",
      "description": "Are policies and procedures for user identification management controls defined and in place for non-consumer users and administrators on all system components, as follows:\n\nIf a session has been idle for more than 15 minutes, are users required to re-authenticate (for example, re-enter the password) to re-activate the terminal or session?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "screenlock-configured-jamf"
        },
        {
          "id": "screenlock-configured-kandji"
        },
        {
          "id": "screenlock-configured-microsoft-endpoint-manager"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "8.2",
      "name": "Authentication methods",
      "description": "In addition to assigning a unique ID, is one or more of the following methods employed to authenticate all users?\n• Something you know, such as a password or passphrase\n• Something you have, such as a token device or smart card\n• Something you are, such as a biometric",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "internal-password-policy-config"
        },
        {
          "id": "internal-password-policy-config-heroku"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "8.2.1.a",
      "name": "Strong cryptography used for authentication",
      "description": "Is strong cryptography used to render all authentication credentials (such as passwords/passphrases) unreadable during transmission and storage on all system components?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "strong-cryptography-used"
        }
      ]
    },
    {
      "id": "8.2.1.b",
      "name": "Cryptography used to render",
      "description": "(b)\tFor service providers only: Is strong cryptography used to render all non-consumer customers’ authentication credentials (such as passwords/passphrases) unreadable during transmission and storage on all system components? This testing procedure applies only to service providers.",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "service-provider-passwords"
        }
      ]
    },
    {
      "id": "8.2.2",
      "name": "User identity verified",
      "description": "Is user identity verified before modifying any authentication credential (for example, performing password resets, provisioning new tokens, or generating new keys)?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "verify-user-identiy"
        }
      ]
    },
    {
      "id": "8.2.3.a",
      "name": "Password parameter configurations",
      "description": "Are user password parameters configured to require passwords/passphrases meet the following?\n• A minimum password length of at least seven characters\n• Contain both numeric and alphabetic characters\nAlternatively, the passwords/passphrases must have complexity and strength at least equivalent to the parameters specified above.",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-password-settings-enabled"
        }
      ]
    },
    {
      "id": "8.2.3.b",
      "name": "Password parameters for non-consumers",
      "description": "(b)\tFor service providers only: Are non-consumer customer passwords required to meet the following minimum length and complexity requirements?\n\\-\tA minimum password length of at least seven characters\n\\-\tContain both numeric and alphabetic characters",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "service-provider-passwords"
        }
      ]
    },
    {
      "id": "8.2.4.a",
      "name": "Passwords changed regularly",
      "description": "Are user passwords/passphrases changed at least once every 90 days?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-password-settings-enabled"
        }
      ]
    },
    {
      "id": "8.2.4.b",
      "name": "Passwords changed regularly for non-consumers",
      "description": "(b)\tFor service providers only:  Are non-consumer customer passwords required to be changed periodically, and are non-consumer customers given guidance as to when, and under what circumstances, passwords must change.",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "service-provider-passwords"
        }
      ]
    },
    {
      "id": "8.2.5.a",
      "name": "New password parameter requirements",
      "description": "Must an individual submit a new password/passphrase that is different from any of the last four passwords/passphrases he or she has used?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-password-settings-enabled"
        }
      ]
    },
    {
      "id": "8.2.5.b",
      "name": "New password parameters for non-consumers",
      "description": "(b)\tFor service providers only:  Are new, non-consumer customer passwords required to be different from any of the last four passwords used?",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "service-provider-passwords"
        }
      ]
    },
    {
      "id": "8.2.6",
      "name": "Unique values for each new password",
      "description": "Are passwords/passphrases set to a unique value for each user for first-time use and upon reset, and must each user change their password immediately after the first use?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-password-settings-enabled"
        }
      ]
    },
    {
      "id": "8.3.1",
      "name": "MFA exists for non-console access",
      "description": "Is all individual non-console administrative access and all remote access to the CDE secured using multi-factor authentication as follows:\n\nNote: Multi-factor authentication requires that a minimum of two of the three authentication methods (see PCI DSS Requirement 8.2 for descriptions of authentication methods) be used for authentication. Using one factor twice (for example, using two separate passwords) is not considered multi-factor authentication.\n\nIs multi-factor authentication incorporated for all non-console access into the CDE for personnel with administrative access?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "mfa-on-accounts-gsuite"
        },
        {
          "id": "mfa-on-accounts-infra"
        },
        {
          "id": "mfa-on-accounts-infra-root"
        },
        {
          "id": "mfa-on-accounts-okta"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "8.3.2",
      "name": "MFA exists for remote network access",
      "description": "Is all individual non-console administrative access and all remote access to the CDE secured using multi-factor authentication as follows:\n\nNote: Multi-factor authentication requires that a minimum of two of the three authentication methods (see PCI DSS Requirement 8.2 for descriptions of authentication methods) be used for authentication. Using one factor twice (for example, using two separate passwords) is not considered multi-factor authentication.\n\nIs multi-factor authentication incorporated for all remote network access (both user and administrator, and including third party access for support or maintenance) originating from outside the entity’s network?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "mfa-on-accounts-gsuite"
        },
        {
          "id": "mfa-on-accounts-infra"
        },
        {
          "id": "mfa-on-accounts-infra-root"
        },
        {
          "id": "mfa-on-accounts-okta"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "8.4.a",
      "name": "Authentication policies and procedures exist",
      "description": "Are authentication policies and procedures documented and communicated to all users?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "8.4.b",
      "name": "Authentication policies and procedure requirements",
      "description": "Do authentication policies and procedures include the following? \n• Guidance on selecting strong authentication credentials\n• Guidance for how users should protect their authentication credentials\n• Instructions not to reuse previously used passwords\n• Instructions that users should change passwords if there is any suspicion the password could be compromised",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "8.5",
      "name": "Shared account methods secure",
      "description": "Are group, shared, or generic accounts, passwords, or other authentication methods prohibited as follows: \n• Generic user IDs and accounts are disabled or removed;\n• Shared user IDs for system administration activities and other critical functions do not exist; and\n• Shared and generic user IDs are not used to administer any system components?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "no-shared-generic-accounts"
        }
      ]
    },
    {
      "id": "8.5.1",
      "name": "Remove access requirements",
      "description": "For service providers only: Do service providers with remote access to customer premises (for example, for support of POS systems or servers) use a unique authentication credential (such as a password/passphrase) for each customer?\nNote: This requirement is not intended to apply to shared hosting providers accessing their own hosting environment, where multiple customer environments are hosted.",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "service-provider-unique-passwords"
        }
      ]
    },
    {
      "id": "8.6",
      "name": "Other authentication methods",
      "description": "Where other authentication mechanisms are used (for example, physical or logical security tokens, smart cards, and certificates, etc.), is the use of these mechanisms assigned as follows?\n• Authentication mechanisms must be assigned to an individual account and not shared among multiple accounts\n• Physical and/or logical controls must be in place to ensure only the intended account can use that mechanism to gain access",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "no-shared-generic-accounts"
        }
      ]
    },
    {
      "id": "8.7.a",
      "name": "Programmatic database access",
      "description": "Is all access to any database containing cardholder data (including access by applications, administrators, and all other users) restricted as follows:\n\nIs all user access to, user queries of, and user actions on (for example, move, copy, delete), the database through programmatic methods only (for example, through stored procedures)?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "database-access-restricted"
        }
      ]
    },
    {
      "id": "8.7.b",
      "name": "User direct access to database restricted",
      "description": "Is all access to any database containing cardholder data (including access by applications, administrators, and all other users) restricted as follows:\n\nIs user direct access to or queries to of databases restricted to database administrators?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "database-access-restricted"
        }
      ]
    },
    {
      "id": "8.7.c",
      "name": "Application IDs use restricted",
      "description": "Is all access to any database containing cardholder data (including access by applications, administrators, and all other users) restricted as follows:\n\nAre application IDs only able to be used by the applications (and not by individual users or other processes)?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "database-access-restricted"
        }
      ]
    },
    {
      "id": "8.8",
      "name": "Security policies and procedures for identification and authentication exist",
      "description": "Are security policies and operational procedures for identification and authentication:\n• Documented\n• In use\n• Known to all affected parties?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "employees-accepted-access-control-policy-bsi"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "9.1",
      "name": "Facility entry controls exist",
      "description": "Are appropriate facility entry controls in place to limit and monitor physical access to systems in the cardholder data environment?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-physical-security-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "physical-security-covered-by-aoc"
        }
      ]
    },
    {
      "id": "9.1.1.a",
      "name": "Video camera and monitoring exist",
      "description": "Are either video cameras or access-control mechanisms (or both) in place to monitor individual physical access to sensitive areas?\n\nNote: “Sensitive areas” refers to any data center, server room, or any area that houses systems that store, process, or transmit cardholder data. This excludes public-facing areas where only point-of-sale terminals are present such as the cashier areas in a retail store.",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "physical-security-covered-by-aoc"
        }
      ]
    },
    {
      "id": "9.1.1.b",
      "name": "Surveillance tampering preventions exit",
      "description": "Are either video cameras or access-control mechanisms (or both) protected from tampering or disabling?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-physical-security-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "physical-security-covered-by-aoc"
        }
      ]
    },
    {
      "id": "9.1.1.c",
      "name": "Collected data reviewed and correlated",
      "description": "Is data collected from video cameras and/or access control mechanisms reviewed and correlated with other entries?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "vendors-attestation-of-compliance-documents"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "physical-security-covered-by-aoc"
        }
      ]
    },
    {
      "id": "9.1.1.d",
      "name": "Data collected from surveillance stored",
      "description": "Is data collected from video cameras and/or access control mechanisms stored for at least three months unless otherwise restricted by law?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "vendors-attestation-of-compliance-documents"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "physical-security-covered-by-aoc"
        }
      ]
    },
    {
      "id": "9.1.2",
      "name": "Jack access restrictions exist",
      "description": "Are physical and/or logical controls in place to restrict access to publicly accessible network jacks? \n\nFor example, network jacks located in public areas and areas accessible to visitors could be disabled and only enabled when network access is explicitly authorized. Alternatively, processes could be implemented to ensure that visitors are escorted at all times in areas with active network jacks.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "vendors-attestation-of-compliance-documents"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "physical-security-covered-by-aoc"
        }
      ]
    },
    {
      "id": "9.1.3",
      "name": "Wireless gateway access restrictions exist",
      "description": "Is physical access to wireless access points, gateways, handheld devices, networking/communications hardware, and telecommunication lines restricted?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "vendors-attestation-of-compliance-documents"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "physical-security-covered-by-aoc"
        }
      ]
    },
    {
      "id": "9.2.a",
      "name": "Entry access restriction procedures exist",
      "description": "Are procedures developed to easily distinguish between onsite personnel and visitors, which include:\n• Identifying onsite personnel and visitors (for example, assigning badges), \n• Changing access requirements, and \n• Revoking terminated onsite personnel and expired visitor identification (such as ID badges)\n\nFor the purposes of Requirement 9, “onsite personnel” refers to full-time and part-time employees, temporary employees, contractors and consultants who are physically present on the entity’s premises. A “visitor” refers to a vendor, guest of any onsite personnel, service workers, or anyone who needs to enter the facility for a short duration, usually not more than one day.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "vendors-attestation-of-compliance-documents"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "physical-security-covered-by-aoc"
        }
      ]
    },
    {
      "id": "9.2.b",
      "name": "Identifications distinguish staff and visitors",
      "description": "Do identification methods (such as ID badges) clearly identify visitors and easily distinguish between onsite personnel and visitors?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-physical-security-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "physical-security-covered-by-aoc"
        }
      ]
    },
    {
      "id": "9.2.c",
      "name": "Badge system access limited",
      "description": "Is access to the badge system limited to authorized personnel?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-physical-security-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "physical-security-covered-by-aoc"
        }
      ]
    },
    {
      "id": "9.3",
      "name": "Physical access controls exist",
      "description": "Is physical access to sensitive areas controlled for onsite personnel, as follows:\n• Is access authorized and based on individual job function?\n• Is access revoked immediately upon termination\n• Upon termination, are all physical access mechanisms, such as keys, access cards, etc., returned or disabled?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-physical-security-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "physical-security-covered-by-aoc"
        }
      ]
    },
    {
      "id": "9.4.1",
      "name": "Visitors authorized and escorted",
      "description": "Is visitor identification and access handled as follows:\n\nAre visitors authorized before entering, and escorted at all times within, areas where cardholder data is processed or maintained?",
      "categories": ["Physical"],
      "tests": [
        {
          "id": "approved-physical-security-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "physical-security-covered-by-aoc"
        }
      ]
    },
    {
      "id": "9.4.2.a",
      "name": "Visitors identified visibly",
      "description": "Is visitor identification and access handled as follows:\n\nAre visitors identified and given a badge or other identification that visibly distinguishes the visitors from onsite personnel?",
      "categories": ["Physical"],
      "tests": [
        {
          "id": "vendors-attestation-of-compliance-documents"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "physical-security-covered-by-aoc"
        }
      ]
    },
    {
      "id": "9.4.2.b",
      "name": "Visitor badges expire",
      "description": "Is visitor identification and access handled as follows:\n\nDo visitor badges or other identification expire?",
      "categories": ["Physical"],
      "tests": [
        {
          "id": "approved-physical-security-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "physical-security-covered-by-aoc"
        }
      ]
    },
    {
      "id": "9.4.3",
      "name": "Visitors surrender badge before exit",
      "description": "Is visitor identification and access handled as follows:\n\nAre visitors asked to surrender the badge or other identification before leaving the facility or at the date of expiration?",
      "categories": ["Physical"],
      "tests": [
        {
          "id": "vendors-attestation-of-compliance-documents"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "physical-security-covered-by-aoc"
        }
      ]
    },
    {
      "id": "9.4.4.a",
      "name": "Visitor log usage of sensitive facilities",
      "description": "Is visitor identification and access handled as follows:\n\nIs a visitor log in use to record physical access to the facility as well as for computer rooms and data centers where cardholder data is stored or transmitted?",
      "categories": ["Physical"],
      "tests": [
        {
          "id": "vendors-attestation-of-compliance-documents"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "physical-security-covered-by-aoc"
        }
      ]
    },
    {
      "id": "9.4.4.b",
      "name": "Visitors log name and relevant information",
      "description": "Is visitor identification and access handled as follows:\n\nDoes the visitor log contain the visitor’s name, the firm represented, and the onsite personnel authorizing physical access?",
      "categories": ["Physical"],
      "tests": [
        {
          "id": "vendors-attestation-of-compliance-documents"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "physical-security-covered-by-aoc"
        }
      ]
    },
    {
      "id": "9.4.4.c",
      "name": "Visitor logs retained",
      "description": "Is visitor identification and access handled as follows:\n\nIs the visitor log retained for at least three months?",
      "categories": ["Physical"],
      "tests": [
        {
          "id": "vendors-attestation-of-compliance-documents"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "physical-security-covered-by-aoc"
        }
      ]
    },
    {
      "id": "9.5",
      "name": "Media physically secured",
      "description": "Are all media physically secured (including but not limited to computers, removable electronic media, paper receipts, paper reports, and faxes)?\n\nFor purposes of Requirement 9, “media” refers to all paper and electronic media containing cardholder data.",
      "categories": ["Physical"],
      "tests": [
        {
          "id": "vendors-attestation-of-compliance-documents"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "physical-security-covered-by-aoc"
        }
      ]
    },
    {
      "id": "9.5.1",
      "name": "Media back-up locations reviewed annually",
      "description": "Is the location where media back-ups are stored reviewed at least annually to confirm storage is secure?",
      "categories": ["Physical"],
      "tests": [
        {
          "id": "vendors-attestation-of-compliance-documents"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "physical-security-covered-by-aoc"
        }
      ]
    },
    {
      "id": "9.6.a",
      "name": "Media distribution controlled",
      "description": "Is strict control maintained over the internal or external distribution of any kind of media?",
      "categories": ["Physical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-media-handled-securely"
        }
      ]
    },
    {
      "id": "9.6.1",
      "name": "Media classified",
      "description": "Do controls include the following:\n\nIs media classified so the sensitivity of the data can be determined?",
      "categories": ["Physical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-media-handled-securely"
        }
      ]
    },
    {
      "id": "9.6.2",
      "name": "Media securely delivered",
      "description": "Do controls include the following:\n\nIs media sent by secured courier or other delivery method that can be accurately tracked?",
      "categories": ["Physical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-media-handled-securely"
        }
      ]
    },
    {
      "id": "9.6.3",
      "name": "Media distribution authorized",
      "description": "Do controls include the following:\n\nIs management approval obtained prior to moving the media (especially when media is distributed to individuals)?",
      "categories": ["Physical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-media-handled-securely"
        }
      ]
    },
    {
      "id": "9.7",
      "name": "Media storage controls exist",
      "description": "Is strict control maintained over the storage and accessibility of media?",
      "categories": ["Physical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-media-handled-securely"
        }
      ]
    },
    {
      "id": "9.7.1.a",
      "name": "Media inventory logs exist",
      "description": "Are inventory logs of all media properly maintained?",
      "categories": ["Physical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-media-handled-securely"
        }
      ]
    },
    {
      "id": "9.7.1.b",
      "name": "Media inventory conducted annually",
      "description": "Are periodic media inventories conducted at least annually?",
      "categories": ["Physical"],
      "tests": [
        {
          "id": "vendors-attestation-of-compliance-documents"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pci-media-handled-securely"
        }
      ]
    },
    {
      "id": "9.8.a",
      "name": "Stored media destroyed if no longer needed",
      "description": "Is all media destroyed when it is no longer needed for business or legal reasons?",
      "categories": ["Physical"],
      "tests": [
        {
          "id": "vendors-attestation-of-compliance-documents"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pci-media-handled-securely"
        }
      ]
    },
    {
      "id": "9.8.b",
      "name": "Period media destruction policy exists",
      "description": "Is there a periodic media destruction policy that defines requirements for the following?\n• Hard-copy materials must be crosscut shredded, incinerated, or pulped such that there is reasonable assurance the hard-copy materials cannot be reconstructed. \n• Storage containers used for materials that are to be destroyed must be secured. \n• Cardholder data on electronic media must be rendered unrecoverable (e.g. via a secure wipe program in accordance with industry-accepted standards for secure deletion, or by physically destroying the media).",
      "categories": ["Physical"],
      "tests": [
        {
          "id": "vendors-attestation-of-compliance-documents"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pci-media-handled-securely"
        }
      ]
    },
    {
      "id": "9.8.1.a",
      "name": "Hardcopy materials securely destroyed",
      "description": "Is media destruction performed as follows:\n\nAre hardcopy materials cross-cut shredded, incinerated, or pulped so that cardholder data cannot be reconstructed?",
      "categories": ["Physical"],
      "tests": [
        {
          "id": "vendors-attestation-of-compliance-documents"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pci-media-handled-securely"
        }
      ]
    },
    {
      "id": "9.8.1.b",
      "name": "Material containers physically secured",
      "description": "Is media destruction performed as follows:\n\nAre storage containers used for materials that contain information to be destroyed secured to prevent access to the contents?",
      "categories": ["Physical"],
      "tests": [
        {
          "id": "approved-physical-security-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pci-media-handled-securely"
        }
      ]
    },
    {
      "id": "9.8.2",
      "name": "Destroyed material reconstruction prevented",
      "description": "Is media destruction performed as follows:\n\nIs cardholder data on electronic media rendered unrecoverable (e.g. via a secure wipe program in accordance with industry-accepted standards for secure deletion, or otherwise by physically destroying the media), so that cardholder data cannot be reconstructed?",
      "categories": ["Physical"],
      "tests": [
        {
          "id": "approved-physical-security-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pci-media-handled-securely"
        }
      ]
    },
    {
      "id": "9.9.a",
      "name": "Capture device control policies exist",
      "description": "Do policies and procedures require that a list of such devices be maintained?",
      "categories": ["Physical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pos-poi-security-processes"
        }
      ]
    },
    {
      "id": "9.9.b",
      "name": "Capture device control policies require periodic inspection",
      "description": "Do policies and procedures require that devices are periodically inspected to look for tampering or substitution?",
      "categories": ["Physical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pos-poi-security-processes"
        }
      ]
    },
    {
      "id": "9.9.c",
      "name": "Capture device control policies require personnel training",
      "description": "Do policies and procedures require that personnel are trained to be aware of suspicious behavior and to report tampering or substitution of devices?",
      "categories": ["Physical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pos-poi-security-processes"
        }
      ]
    },
    {
      "id": "9.9.1.a",
      "name": "Capture device list requirements",
      "description": "Are devices that capture payment card data via direct physical interaction with the card protected against tampering and substitution as follows?\n\nNote: This requirement applies to card-reading devices used in card-present transactions (that is, card swipe or dip) at the point of sale. This requirement is not intended to apply to manual key-entry components such as computer keyboards and POS keypads.\n\nDoes the list of devices include the following?\n• Make, model of device \n• Location of device (for example, the address of the site or facility where the device is located) \n• Device serial number or other method of unique identification",
      "categories": ["Physical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pos-poi-security-processes"
        }
      ]
    },
    {
      "id": "9.9.1.b",
      "name": "Capture device list accurate",
      "description": "Are devices that capture payment card data via direct physical interaction with the card protected against tampering and substitution as follows?\n\nNote: This requirement applies to card-reading devices used in card-present transactions (that is, card swipe or dip) at the point of sale. This requirement is not intended to apply to manual key-entry components such as computer keyboards and POS keypads.\n\nIs the list accurate and up to date?",
      "categories": ["Physical"],
      "tests": [
        {
          "id": "vendors-attestation-of-compliance-documents"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pos-poi-security-processes"
        }
      ]
    },
    {
      "id": "9.9.1.c",
      "name": "Capture device lists updated",
      "description": "Are devices that capture payment card data via direct physical interaction with the card protected against tampering and substitution as follows?\n\nNote: This requirement applies to card-reading devices used in card-present transactions (that is, card swipe or dip) at the point of sale. This requirement is not intended to apply to manual key-entry components such as computer keyboards and POS keypads.\n\nIs the list of devices updated when devices are added, relocated, decommissioned, etc.?",
      "categories": ["Physical"],
      "tests": [
        {
          "id": "approved-physical-security-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pos-poi-security-processes"
        }
      ]
    },
    {
      "id": "9.9.2.a",
      "name": "Capture devices periodically inspected",
      "description": "Are devices that capture payment card data via direct physical interaction with the card protected against tampering and substitution as follows?\n\nNote: This requirement applies to card-reading devices used in card-present transactions (that is, card swipe or dip) at the point of sale. This requirement is not intended to apply to manual key-entry components such as computer keyboards and POS keypads.\n\nAre device surfaces periodically inspected to detect tampering (for example, addition of card skimmers to devices), or substitution (for example, by checking the serial number or other device characteristics to verify it has not been swapped with a fraudulent device) as follows?\n\nNote: Examples of signs that a device might have been tampered with or substituted include unexpected attachments or cables plugged into the device, missing or changed security labels, broken or differently colored casing, or changes to the serial number or other external markings.",
      "categories": ["Physical"],
      "tests": [
        {
          "id": "vendors-attestation-of-compliance-documents"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pos-poi-security-processes"
        }
      ]
    },
    {
      "id": "9.9.2.b",
      "name": "Capture device inspection training",
      "description": "Are devices that capture payment card data via direct physical interaction with the card protected against tampering and substitution as follows?\n\nNote: This requirement applies to card-reading devices used in card-present transactions (that is, card swipe or dip) at the point of sale. This requirement is not intended to apply to manual key-entry components such as computer keyboards and POS keypads.\n\nAre personnel aware of procedures for inspecting devices?",
      "categories": ["Physical"],
      "tests": [
        {
          "id": "approved-physical-security-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pos-poi-security-processes"
        }
      ]
    },
    {
      "id": "9.9.3.a",
      "name": "Point-of-sale personnel training requirements",
      "description": "Are personnel trained to be aware of attempted tampering or replacement of devices, to include the following? \n\nDo training materials for personnel at point-of-sale locations include the following? \n• Verify the identity of any third-party persons claiming to be repair or maintenance personnel, prior to granting them access to modify or troubleshoot devices.\n• Do not install, replace, or return devices without verification. \n• Be aware of suspicious behavior around devices (for example, attempts by unknown persons to unplug or open devices).\n• Report suspicious behavior and indications of device tampering or substitution to appropriate personnel (for example, to a manager or security officer).",
      "categories": ["Physical"],
      "tests": [
        {
          "id": "pci-security-training-records"
        },
        {
          "id": "pci-security-training-tracked-records"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pos-poi-security-processes"
        }
      ]
    },
    {
      "id": "9.9.3.b",
      "name": "Point-of-sale personnel training requirements",
      "description": "Are personnel trained to be aware of attempted tampering or replacement of devices, to include the following? \n\nHave personnel at point-of-sale locations received training, and are they aware of procedures to detect and report attempted tampering or replacement of devices?",
      "categories": ["Physical"],
      "tests": [
        {
          "id": "pci-security-training-records"
        },
        {
          "id": "pci-security-training-tracked-records"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pos-poi-security-processes"
        }
      ]
    },
    {
      "id": "9.10",
      "name": "Security policies and procedures exist for physical access restriction",
      "description": "Are security policies and operational procedures for restricting physical access to cardholder data:\n• Documented\n• In use\n• Known to all affected parties?",
      "categories": ["Physical"],
      "tests": [
        {
          "id": "approved-data-management-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "approved-physical-security-policy-bsi-exists"
        },
        {
          "id": "employees-accepted-data-management-policy-bsi"
        },
        {
          "id": "employees-accepted-physical-security-policy-bsi"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "10.1.a",
      "name": "System audit trails enabled and active",
      "description": "Are audit trails enabled and active for system components?",
      "categories": ["Physical"],
      "tests": [
        {
          "id": "flow-logs-on-azure"
        },
        {
          "id": "flow-logs-on-config-gcp"
        },
        {
          "id": "logs-centrally-stored-config"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "10.1.b",
      "name": "System access linked to individual users",
      "description": "Is access to system components linked to individual users?",
      "categories": ["Physical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-logging-configured-enabled"
        }
      ]
    },
    {
      "id": "10.2.1",
      "name": "Individual user access reconstructable",
      "description": "Are automated audit trails implemented for all system components to reconstruct the following events:\n\nAll individual user accesses to cardholder data?",
      "categories": ["Physical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-logging-configured-enabled"
        }
      ]
    },
    {
      "id": "10.2.2",
      "name": "Admin actions reconstructable",
      "description": "Are automated audit trails implemented for all system components to reconstruct the following events:\n\nAll actions taken by any individual with root or administrative privileges?",
      "categories": ["Physical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-logging-configured-enabled"
        }
      ]
    },
    {
      "id": "10.2.3",
      "name": "Audit trail access reconstructable",
      "description": "Are automated audit trails implemented for all system components to reconstruct the following events:\n\nAccess to all audit trails?",
      "categories": ["Physical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-logging-configured-enabled"
        }
      ]
    },
    {
      "id": "10.2.4",
      "name": "Invalid logical access attempts reconstructable",
      "description": "Are automated audit trails implemented for all system components to reconstruct the following events:\n\nInvalid logical access attempts?",
      "categories": ["Physical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-logging-configured-enabled"
        }
      ]
    },
    {
      "id": "10.2.5",
      "name": "Authentication changes reconstructable",
      "description": "Are automated audit trails implemented for all system components to reconstruct the following events:\n\nUse of and changes to identification and authentication mechanisms–including but not limited to creation of new accounts and elevation of privileges – and all changes, additions, or deletions to accounts with root or administrative privileges?",
      "categories": ["Physical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-logging-configured-enabled"
        }
      ]
    },
    {
      "id": "10.2.6",
      "name": "Audit log changes reconstructable",
      "description": "Are automated audit trails implemented for all system components to reconstruct the following events:\n\nInitialization, stopping, or pausing of the audit logs?",
      "categories": ["Physical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-logging-configured-enabled"
        }
      ]
    },
    {
      "id": "10.2.7",
      "name": "System level object changes reconstructable",
      "description": "Are automated audit trails implemented for all system components to reconstruct the following events:\n\nCreation and deletion of system-level objects?",
      "categories": ["Physical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-logging-configured-enabled"
        }
      ]
    },
    {
      "id": "10.3.1",
      "name": "User identification event recorded",
      "description": "Are the following audit trail entries recorded for all system components for each event:\n\nUser identification?",
      "categories": ["Physical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-logging-configured-enabled"
        }
      ]
    },
    {
      "id": "10.3.2",
      "name": "Event type recorded",
      "description": "Are the following audit trail entries recorded for all system components for each event:\n\nType of event?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-logging-configured-enabled"
        },
        {
          "id": "system-activity-log-settings"
        }
      ]
    },
    {
      "id": "10.3.3",
      "name": "Date and type recorded",
      "description": "Are the following audit trail entries recorded for all system components for each event:\n\nDate and time?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-logging-configured-enabled"
        }
      ]
    },
    {
      "id": "10.3.4",
      "name": "Success or failure recorded",
      "description": "Are the following audit trail entries recorded for all system components for each event:\n\nSuccess or failure indication?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-logging-configured-enabled"
        }
      ]
    },
    {
      "id": "10.3.5",
      "name": "Event origination recorded",
      "description": "Are the following audit trail entries recorded for all system components for each event:\n\nOrigination of event?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-logging-configured-enabled"
        }
      ]
    },
    {
      "id": "10.3.6",
      "name": "Affected data or resource name recorded",
      "description": "Are the following audit trail entries recorded for all system components for each event:\n\nIdentity or name of affected data, system component, or resource?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-logging-configured-enabled"
        }
      ]
    },
    {
      "id": "10.4",
      "name": "Time synchronization technology exists",
      "description": "Are all critical system clocks and times synchronized through use of time synchronization technology, and is the technology kept current?  \n\nNote: One example of time synchronization technology is Network Time Protocol (NTP).",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "infrastructure-timesync-config"
        },
        {
          "id": "infrastructure-timesync-config-heroku"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "10.4.1.a",
      "name": "External time signal receipt restricted",
      "description": "Are the following processes implemented for critical systems to have the correct and consistent time:\n\nDo only designated central time server(s) receive time signals from external sources, and are time signals from external sources based on International Atomic Time or UTC?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "infrastructure-timesync-config"
        },
        {
          "id": "infrastructure-timesync-config-heroku"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "10.4.1.b",
      "name": "Multiple designated time servers synchornized",
      "description": "Are the following processes implemented for critical systems to have the correct and consistent time:\n\nWhere there is more than one designated time server, do the time servers peer with each other to keep accurate time?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "infrastructure-timesync-config"
        },
        {
          "id": "infrastructure-timesync-config-heroku"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "10.4.1.c",
      "name": "Time received only from designated central time server(s)",
      "description": "Are the following processes implemented for critical systems to have the correct and consistent time:\n\nDo systems receive time only from designated central time server(s)?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "infrastructure-timesync-config"
        },
        {
          "id": "infrastructure-timesync-config-heroku"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "10.4.2.a",
      "name": "Time data access restricted",
      "description": "Is time data is protected as follows:\nIs access to time data restricted to only personnel with a business need to access time data?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "infrastructure-timesync-config"
        },
        {
          "id": "infrastructure-timesync-config-heroku"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "10.4.2.b",
      "name": "Changes to time settings logged",
      "description": "Are changes to time settings on critical systems logged, monitored, and reviewed?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "infrastructure-timesync-config"
        },
        {
          "id": "infrastructure-timesync-config-heroku"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pci-logging-configured-enabled"
        }
      ]
    },
    {
      "id": "10.4.3",
      "name": "Time settings based on industry-accepted sources",
      "description": "Are time settings received from specific, industry-accepted time sources? (This is to prevent a malicious individual from changing the clock). \n\nOptionally, those updates can be encrypted with a symmetric key, and access control lists can be created that specify the IP addresses of client machines that will be provided with the time updates (to prevent unauthorized use of internal time servers).",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "infrastructure-timesync-config"
        },
        {
          "id": "infrastructure-timesync-config-heroku"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "10.5.1",
      "name": "Audit trail acces restricted",
      "description": "Are audit trails secured so they cannot be altered, as follows:\n\nIs viewing of audit trails limited to those with a job-related need?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "access-requests-denied-log"
        }
      ]
    },
    {
      "id": "10.5.2",
      "name": "Audit trail protected from unauthorized modifications",
      "description": "Are audit trails secured so they cannot be altered, as follows:\n\nAre audit trail files protected from unauthorized modifications via access control mechanisms, physical segregation, and/or network segregation?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "logs-centrally-stored-authorization"
        },
        {
          "id": "logs-centrally-stored-authorization-gcp"
        },
        {
          "id": "logs-centrally-stored-config-gcp"
        },
        {
          "id": "logs-sink-destinations-authorization-gcp"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "10.5.3",
      "name": "Audit trail files backed up",
      "description": "Are audit trails secured so they cannot be altered, as follows:\n\nAre audit trail files promptly backed up to a centralized log server or media that is difficult to alter?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "logs-centrally-stored-authorization"
        },
        {
          "id": "logs-centrally-stored-authorization-gcp"
        },
        {
          "id": "logs-centrally-stored-config-gcp"
        },
        {
          "id": "logs-sink-destinations-tracked-gcp"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "10.5.4",
      "name": "Logs exist on secure servers",
      "description": "Are audit trails secured so they cannot be altered, as follows:\n\nAre logs for external-facing technologies (for example, wireless, firewalls, DNS, mail) written onto a secure, centralized, internal log server or media?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "externally-facing-systems-logged"
        }
      ]
    },
    {
      "id": "10.5.5",
      "name": "Log modifications create alerts",
      "description": "Are audit trails secured so they cannot be altered, as follows:\n\nIs file-integrity monitoring or change-detection software used on logs to ensure that existing log data cannot be changed without generating alerts (although new data being added should not cause an alert)?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "file-integrity-monitoring-cde"
        }
      ]
    },
    {
      "id": "10.6.1.a",
      "name": "Policies and procedures exist for regular log review",
      "description": "Are logs and security events for all system components reviewed to identify anomalies or suspicious activity as follows?\n\nNote: Log harvesting, parsing, and alerting tools may be used to achieve compliance with Requirement 10.6.\n\nAre written policies and procedures defined for reviewing the following at least daily, either manually or via log tools?\n• All security events \n• Logs of all system components that store, process, or transmit CHD and/or SAD \n• Logs of all critical system components\n• Logs of all servers and system components that perform security functions (for example, firewalls, intrusion-detection systems/intrusion-prevention systems (IDS/IPS), authentication servers, e-commerce redirection servers, etc.)",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-operations-security-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "10.6.1.b",
      "name": "System logs reviewed regularly",
      "description": "Are logs and security events for all system components reviewed to identify anomalies or suspicious activity as follows?\n\nNote: Log harvesting, parsing, and alerting tools may be used to achieve compliance with Requirement 10.6.\n\nAre the above logs and security events reviewed at least daily?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-logs-reviewed-daily"
        }
      ]
    },
    {
      "id": "10.6.2.a",
      "name": "Policies and procedures exist for reviewing system components",
      "description": "Are logs and security events for all system components reviewed to identify anomalies or suspicious activity as follows?\n\nNote: Log harvesting, parsing, and alerting tools may be used to achieve compliance with Requirement 10.6.\n\nAre written policies and procedures defined for reviewing logs of all other system components periodically—either manually or via log tools—based on the organization’s policies and risk management strategy?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-operations-security-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "10.6.2.b",
      "name": "System component reviews performed",
      "description": "Are logs and security events for all system components reviewed to identify anomalies or suspicious activity as follows?\n\nNote: Log harvesting, parsing, and alerting tools may be used to achieve compliance with Requirement 10.6.\n\nAre reviews of all other system components performed in accordance with organization’s policies and risk management strategy?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-operations-security-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "10.6.3.a",
      "name": "Policies and procedures exist to address anomalies",
      "description": "Are logs and security events for all system components reviewed to identify anomalies or suspicious activity as follows?\n\nNote: Log harvesting, parsing, and alerting tools may be used to achieve compliance with Requirement 10.6.\n\nAre written policies and procedures defined for following up on exceptions and anomalies identified during the review process?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-operations-security-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "10.6.3.b",
      "name": "Anomalies and exceptions regularly addressed",
      "description": "Are logs and security events for all system components reviewed to identify anomalies or suspicious activity as follows?\n\nNote: Log harvesting, parsing, and alerting tools may be used to achieve compliance with Requirement 10.6.\n\nIs follow up to exceptions and anomalies performed?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-logs-reviewed-daily"
        }
      ]
    },
    {
      "id": "10.7.a",
      "name": "Audit log retention policies exist",
      "description": "Are audit log retention policies and procedures in place and do they require that logs are retained for at least one year, with a minimum of three months immediately available for analysis (for example, online, archived, or restorable from backup)?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-operations-security-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "10.7.b",
      "name": "Audit logs retained for one year",
      "description": "Are audit logs retained for at least one year?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "logs-retained-for-twelve-months-config"
        },
        {
          "id": "logs-retained-for-twelve-months-config-gcp"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "10.7.c",
      "name": "Immediately available audit logs cover 3 months",
      "description": "Are at least the last three months’ logs immediately available for analysis?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "logs-online-three-months"
        }
      ]
    },
    {
      "id": "10.8.a",
      "name": "Processes exist for timely detection of system failures",
      "description": "For service providers only: Is a process implemented for the timely detection and reporting of failures of critical security control systems as follows:\n\n(a) \tAre processes implemented for the timely detection and reporting of failures of critical security control systems, including but not limited to failure of:\n\\-\tFirewalls \n\\-\tIDS/IPS \n\\-\tFIM \n\\-\tAnti-virus \n\\-\tPhysical access controls \n\\-\tLogical access controls \n\\-\tAudit logging mechanisms \n\\-\tSegmentation controls (if used)",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "critical-security-controls-failure"
        }
      ]
    },
    {
      "id": "10.8.b",
      "name": "System failures generate alerts",
      "description": "For service providers only: Is a process implemented for the timely detection and reporting of failures of critical security control systems as follows:\n\n(b) \tDoes the failure of a critical security control result in the generation of an alert?",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "critical-security-controls-failure"
        }
      ]
    },
    {
      "id": "10.9",
      "name": "Security policies and procedures exist for network access monitoring",
      "description": "Are security policies and operational procedures for monitoring all access to network resources and cardholder data:\n• Documented\n• In use\n• Known to all affected parties?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-operations-security-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "11.1.a",
      "name": "Unauthorized wireless access points detection exists",
      "description": "Are processes implemented for detection and identification of both authorized and unauthorized wireless access points on a quarterly basis? \n\nNote: Methods that may be used in the process include, but are not limited to, wireless network scans, physical/logical inspections of system components and infrastructure, network access control (NAC), or wireless IDS/IPS.\nWhichever methods are used, they must be sufficient to detect and identify any unauthorized devices.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "vendors-attestation-of-compliance-documents"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "physical-security-covered-by-aoc"
        }
      ]
    },
    {
      "id": "11.1.b",
      "name": "Unauthorized wireless access detection methodology",
      "description": "Does the methodology detect and identify any unauthorized wireless access points, including at least the following?\n• WLAN cards inserted into system components;\n• Portable or mobile devices attached to system components to create a wireless access point (for example, by USB, etc.); and\n• Wireless devices attached to a network port or network device.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "vendors-attestation-of-compliance-documents"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "physical-security-covered-by-aoc"
        }
      ]
    },
    {
      "id": "11.1.c",
      "name": "Wireless authorization scan conducted quarterly",
      "description": "If wireless scanning is utilized to identify authorized and unauthorized wireless access points, is the scan performed at least quarterly for all system components and facilities?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "vendors-attestation-of-compliance-documents"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "physical-security-covered-by-aoc"
        }
      ]
    },
    {
      "id": "11.1.d",
      "name": "Automated monitoring generates alerts",
      "description": "If automated monitoring is utilized (for example, wireless IDS/IPS, NAC, etc.), is monitoring configured to generate alerts to notify personnel?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "physical-security-covered-by-aoc"
        }
      ]
    },
    {
      "id": "11.1.1",
      "name": "Authorized wireless access point inventory exists",
      "description": "Is an inventory of authorized wireless access points maintained and a business justification documented for all authorized wireless access points?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "vendors-attestation-of-compliance-documents"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "physical-security-covered-by-aoc"
        }
      ]
    },
    {
      "id": "11.1.2.a",
      "name": "Incident response plan defines responses",
      "description": "Does the incident response plan define and require a response in the event that an unauthorized wireless access point is detected?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "vendors-attestation-of-compliance-documents"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "physical-security-covered-by-aoc"
        }
      ]
    },
    {
      "id": "11.1.2.b",
      "name": "Unauthorized wireless access response exists",
      "description": "Is action taken when unauthorized wireless access points are found?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "vendors-attestation-of-compliance-documents"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "physical-security-covered-by-aoc"
        }
      ]
    },
    {
      "id": "11.2.1.a",
      "name": "Quarterly internal vulnerability scans",
      "description": "Are internal and external network vulnerability scans run at least quarterly and after any significant change in the network (such as new system component installations, changes in network topology, firewall rule modifications, product upgrades), as follows?\n\nNote: Multiple scan reports can be combined for the quarterly scan process to show that all systems were scanned and all applicable vulnerabilities have been addressed.  Additional documentation may be required to verify non-remediated vulnerabilities are in the process of being addressed.  \nFor initial PCI DSS compliance, it is not required that four quarters of passing scans be completed if the assessor verifies 1) the most recent scan result was a passing scan, 2) the entity has documented policies and procedures requiring quarterly scanning, and 3) vulnerabilities noted in the scan results have been corrected as shown in a re-scan(s). For subsequent years after the initial PCI DSS review, four quarters of passing scans must have occurred.\n\nAre quarterly internal vulnerability scans performed?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "vulnerability-scan"
        }
      ]
    },
    {
      "id": "11.2.1.b",
      "name": "Quarterly internal vulnerability scan addresses \"high risk\" issues",
      "description": "Are internal and external network vulnerability scans run at least quarterly and after any significant change in the network (such as new system component installations, changes in network topology, firewall rule modifications, product upgrades), as follows?\n\nNote: Multiple scan reports can be combined for the quarterly scan process to show that all systems were scanned and all applicable vulnerabilities have been addressed.  Additional documentation may be required to verify non-remediated vulnerabilities are in the process of being addressed.  \nFor initial PCI DSS compliance, it is not required that four quarters of passing scans be completed if the assessor verifies 1) the most recent scan result was a passing scan, 2) the entity has documented policies and procedures requiring quarterly scanning, and 3) vulnerabilities noted in the scan results have been corrected as shown in a re-scan(s). For subsequent years after the initial PCI DSS review, four quarters of passing scans must have occurred.\n\nDoes the quarterly internal scan process address all “high risk” vulnerabilities and include rescans to verify all “high-risk” vulnerabilities (as defined in PCI DSS Requirement 6.1) are resolved?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "internal-rescans-performed"
        },
        {
          "id": "vulnerabilities-remediated-sample"
        }
      ]
    },
    {
      "id": "11.2.1.c",
      "name": "Quarterly internal scans performed by qualified party",
      "description": "Are internal and external network vulnerability scans run at least quarterly and after any significant change in the network (such as new system component installations, changes in network topology, firewall rule modifications, product upgrades), as follows?\n\nNote: Multiple scan reports can be combined for the quarterly scan process to show that all systems were scanned and all applicable vulnerabilities have been addressed.  Additional documentation may be required to verify non-remediated vulnerabilities are in the process of being addressed.  \nFor initial PCI DSS compliance, it is not required that four quarters of passing scans be completed if the assessor verifies 1) the most recent scan result was a passing scan, 2) the entity has documented policies and procedures requiring quarterly scanning, and 3) vulnerabilities noted in the scan results have been corrected as shown in a re-scan(s). For subsequent years after the initial PCI DSS review, four quarters of passing scans must have occurred.\n\nAre quarterly internal scans performed by a qualified internal resource(s) or qualified external third party, and if applicable, does organizational independence of the tester exist (not required to be a QSA or ASV)?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "vulnerability-scan"
        }
      ]
    },
    {
      "id": "11.2.2.a",
      "name": "Quarterly external vulnerability scans performed",
      "description": "Are internal and external network vulnerability scans run at least quarterly and after any significant change in the network (such as new system component installations, changes in network topology, firewall rule modifications, product upgrades), as follows?\n\nNote: Multiple scan reports can be combined for the quarterly scan process to show that all systems were scanned and all applicable vulnerabilities have been addressed.  Additional documentation may be required to verify non-remediated vulnerabilities are in the process of being addressed.  \nFor initial PCI DSS compliance, it is not required that four quarters of passing scans be completed if the assessor verifies 1) the most recent scan result was a passing scan, 2) the entity has documented policies and procedures requiring quarterly scanning, and 3) vulnerabilities noted in the scan results have been corrected as shown in a re-scan(s). For subsequent years after the initial PCI DSS review, four quarters of passing scans must have occurred.\n\nAre quarterly external vulnerability scans performed?\n\nNote: Quarterly external vulnerability scans must be performed by an Approved Scanning Vendor (ASV), approved by the Payment Card Industry Security Standards Council (PCI SSC). \nRefer to the ASV Program Guide published on the PCI SSC website for scan customer responsibilities, scan preparation, etc.",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "ASV-external-vulnerability-scan"
        }
      ]
    },
    {
      "id": "11.2.2.b",
      "name": "Quarterly external vulnerabilities scans performed within ASV guidelines",
      "description": "Are internal and external network vulnerability scans run at least quarterly and after any significant change in the network (such as new system component installations, changes in network topology, firewall rule modifications, product upgrades), as follows?\n\nNote: Multiple scan reports can be combined for the quarterly scan process to show that all systems were scanned and all applicable vulnerabilities have been addressed.  Additional documentation may be required to verify non-remediated vulnerabilities are in the process of being addressed.  \nFor initial PCI DSS compliance, it is not required that four quarters of passing scans be completed if the assessor verifies 1) the most recent scan result was a passing scan, 2) the entity has documented policies and procedures requiring quarterly scanning, and 3) vulnerabilities noted in the scan results have been corrected as shown in a re-scan(s). For subsequent years after the initial PCI DSS review, four quarters of passing scans must have occurred.\n\nDo external quarterly scan and rescan results satisfy the ASV Program Guide requirements for a passing scan (for example, no vulnerabilities rated 4.0 or higher by the CVSS, and no automatic failures)?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "ASV-external-vulnerability-scan"
        }
      ]
    },
    {
      "id": "11.2.2.c",
      "name": "Quarterly external vulnerability scans performed by Approved Scanning Vendor (ASV)",
      "description": "Are internal and external network vulnerability scans run at least quarterly and after any significant change in the network (such as new system component installations, changes in network topology, firewall rule modifications, product upgrades), as follows?\n\nNote: Multiple scan reports can be combined for the quarterly scan process to show that all systems were scanned and all applicable vulnerabilities have been addressed.  Additional documentation may be required to verify non-remediated vulnerabilities are in the process of being addressed.  \nFor initial PCI DSS compliance, it is not required that four quarters of passing scans be completed if the assessor verifies 1) the most recent scan result was a passing scan, 2) the entity has documented policies and procedures requiring quarterly scanning, and 3) vulnerabilities noted in the scan results have been corrected as shown in a re-scan(s). For subsequent years after the initial PCI DSS review, four quarters of passing scans must have occurred.\n\nAre quarterly external vulnerability scans performed by a PCI SSC Approved Scanning Vendor (ASV)?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "ASV-external-vulnerability-scan"
        }
      ]
    },
    {
      "id": "11.2.3.a",
      "name": "All scans re-performed after significant changes",
      "description": "Are internal and external network vulnerability scans run at least quarterly and after any significant change in the network (such as new system component installations, changes in network topology, firewall rule modifications, product upgrades), as follows?\n\nNote: Multiple scan reports can be combined for the quarterly scan process to show that all systems were scanned and all applicable vulnerabilities have been addressed.  Additional documentation may be required to verify non-remediated vulnerabilities are in the process of being addressed.  \nFor initial PCI DSS compliance, it is not required that four quarters of passing scans be completed if the assessor verifies 1) the most recent scan result was a passing scan, 2) the entity has documented policies and procedures requiring quarterly scanning, and 3) vulnerabilities noted in the scan results have been corrected as shown in a re-scan(s). For subsequent years after the initial PCI DSS review, four quarters of passing scans must have occurred.\n\nAre internal and external scans, and rescans as needed, performed after any significant change?\n\nNote: Scans must be performed by qualified personnel.",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "ASV-external-vulnerability-scan"
        }
      ]
    },
    {
      "id": "11.2.3.b",
      "name": "Rescan process",
      "description": "Are internal and external network vulnerability scans run at least quarterly and after any significant change in the network (such as new system component installations, changes in network topology, firewall rule modifications, product upgrades), as follows?\n\nNote: Multiple scan reports can be combined for the quarterly scan process to show that all systems were scanned and all applicable vulnerabilities have been addressed.  Additional documentation may be required to verify non-remediated vulnerabilities are in the process of being addressed.  \nFor initial PCI DSS compliance, it is not required that four quarters of passing scans be completed if the assessor verifies 1) the most recent scan result was a passing scan, 2) the entity has documented policies and procedures requiring quarterly scanning, and 3) vulnerabilities noted in the scan results have been corrected as shown in a re-scan(s). For subsequent years after the initial PCI DSS review, four quarters of passing scans must have occurred.\n\nDoes the scan process include rescans until: \n• For external scans, no vulnerabilities exist that are scored 4.0 or higher by the CVSS,\n• For internal scans, a passing result is obtained or all “high-risk” vulnerabilities as defined in PCI DSS Requirement 6.1 are resolved?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "ASV-external-vulnerability-scan"
        }
      ]
    },
    {
      "id": "11.2.3.c",
      "name": "Scans performed by qualified parties",
      "description": "Are internal and external network vulnerability scans run at least quarterly and after any significant change in the network (such as new system component installations, changes in network topology, firewall rule modifications, product upgrades), as follows?\n\nNote: Multiple scan reports can be combined for the quarterly scan process to show that all systems were scanned and all applicable vulnerabilities have been addressed.  Additional documentation may be required to verify non-remediated vulnerabilities are in the process of being addressed.  \nFor initial PCI DSS compliance, it is not required that four quarters of passing scans be completed if the assessor verifies 1) the most recent scan result was a passing scan, 2) the entity has documented policies and procedures requiring quarterly scanning, and 3) vulnerabilities noted in the scan results have been corrected as shown in a re-scan(s). For subsequent years after the initial PCI DSS review, four quarters of passing scans must have occurred.\n\nAre scans performed by a qualified internal resource(s) or qualified external third party, and if applicable, does organizational independence of the tester exist (not required to be a QSA or ASV)?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "penetration-tests-records"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "11.3",
      "name": "Penetration testing methodology requirements",
      "description": "Does the penetration-testing methodology include the following? \n• Is based on industry-accepted penetration testing approaches (for example, NIST SP800-115)\n• Includes coverage for the entire CDE perimeter and critical systems\n• Includes testing from both inside and outside the network\n• Includes testing to validate any segmentation and scope-reduction controls\n• Defines application-layer penetration tests to include, at a minimum, the vulnerabilities listed in Requirement 6.5\n• Defines network-layer penetration tests to include components that support network functions as well as operating systems\n• Includes review and consideration of threats and vulnerabilities experienced in the last 12 months\n• Specifies retention of penetration testing results and remediation activities results",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "penetration-tests-records"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "11.3.1.a",
      "name": "External penetration test requirements",
      "description": "Is external penetration testing performed per the defined methodology, at least annually, and after any significant infrastructure or application changes to the environment (such as an operating system upgrade, a sub-network added to the environment, or an added web server)?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "penetration-tests-records"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "11.3.1.b",
      "name": "Penetration tests performed by qualified party",
      "description": "Are tests performed by a qualified internal resource or qualified external third party, and if applicable, does organizational independence of the tester exist (not required to be a QSA or ASV)?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "penetration-tests-records"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "11.3.2.a",
      "name": "Penetration tests re-performed after significant changes and regularly",
      "description": "Is internal penetration testing performed per the defined methodology, at least annually, and after any significant infrastructure or application changes to the environment (such as an operating system upgrade, a sub-network added to the environment, or an added web server)?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "penetration-tests-records"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "11.3.2.b",
      "name": "Recurring penetration tests performed by qualified party",
      "description": "Are tests performed by a qualified internal resource or qualified external third party, and if applicable, does organizational independence of the tester exist (not required to be a QSA or ASV)?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "penetration-tests-records"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "11.3.3",
      "name": "Found vulnerabilities corrected and fix verified",
      "description": "Are exploitable vulnerabilities found during penetration testing corrected, followed by repeated testing to verify the corrections?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "penetration-tests-records"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pen-test-remediation"
        }
      ]
    },
    {
      "id": "11.3.4.a",
      "name": "Penetration testing covers all segmentation methods",
      "description": "If segmentation is used to isolate the CDE from other networks:\n\nAre penetration-testing procedures defined to test all segmentation methods, to confirm they are operational and effective, and isolate all out-of-scope systems from systems in the CDE?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "penetration-tests-records"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "11.3.4.b",
      "name": "Penetration testing verifies segmentation controls",
      "description": "If segmentation is used to isolate the CDE from other networks:\n\nDoes penetration testing to verify segmentation controls meet the following?\n• Performed at least annually and after any changes to segmentation controls/methods\n• Covers all segmentation controls/methods in use\n• Verifies that segmentation methods are operational and effective, and isolate all out-of-scope systems from systems in the CDE.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "penetration-tests-records"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "11.3.4.c",
      "name": "Penetration tests performed by qualified party",
      "description": "If segmentation is used to isolate the CDE from other networks:\n\nAre tests performed by a qualified internal resource or qualified external third party, and if applicable, does organizational independence of the tester exist (not required to be a QSA or ASV)?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "penetration-tests-records"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "11.3.4.1.a",
      "name": "Penetration tests on segmentation controls performed regularly",
      "description": "For service providers only: If segmentation is used:\n\n(a)\tIs PCI DSS scope confirmed by performing penetration tests on segmentation controls at least every six months and after any changes to segmentation controls/methods?",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "penetration-tests-records"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "11.3.4.1.b",
      "name": "Penetrating testing covers all segmentation controls",
      "description": "For service providers only: If segmentation is used:\n\n(b)\tDoes penetration testing cover all segmentation controls/methods in use?",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "penetration-tests-records"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "11.3.4.1.c",
      "name": "Penetration testing verifies segmentation effectiveness",
      "description": "For service providers only: If segmentation is used:\n\n(c)\tDoes penetration testing verify that segmentation controls/methods are operational and effective, and isolate all out-of-scope systems from systems in the CDE",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "penetration-tests-records"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "11.3.4.1.d",
      "name": "Penetration test for segmentation performed by qualified party",
      "description": "For service providers only: If segmentation is used:\n\n(d)\tAre tests performed by a qualified internal resource or qualified external third party, and if applicable, does organizational independence of the tester exist (not required to be a QSA or ASV)?",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "penetration-tests-records"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "11.4.a",
      "name": "Intrusion detection or prevention exists",
      "description": "Are intrusion-detection and/or intrusion-prevention techniques that detect and/or prevent intrusions into the network in place to monitor all traffic: \n• At the perimeter of the cardholder data environment, and\n• At critical points in the cardholder data environment.",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "ids-ips-enabled-in-cde"
        }
      ]
    },
    {
      "id": "11.4.b",
      "name": "Intrusion alerts personnel",
      "description": "Are intrusion-detection and/or intrusion-prevention techniques configured to alert personnel of suspected compromises?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "ids-ips-alerts-enabled"
        }
      ]
    },
    {
      "id": "11.4.c",
      "name": "Intrusion detection configs up-to-date",
      "description": "Are all intrusion-detection and prevention engines, baselines, and signatures kept up-to-date?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "ids-ips-enabled-in-cde"
        }
      ]
    },
    {
      "id": "11.5.a",
      "name": "Change detection methods detect unauthorized acts",
      "description": "Is a change-detection mechanism (for example, file-integrity monitoring tools) deployed to detect unauthorized modification (including changes, additions, and deletions) of critical system files, configuration files, or content files?\n\nExamples of files that should be monitored include:\n• System executables\n• Application executables\n• Configuration and parameter files\n• Centrally stored, historical or archived, log, and audit files \n• Additional critical files determined by entity (for example, through risk assessment or other means)",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "file-integrity-monitoring-cde"
        }
      ]
    },
    {
      "id": "11.5.b",
      "name": "Change detection methods alert personnel",
      "description": "Is the change-detection mechanism configured to alert personnel to unauthorized modification (including changes, additions, and deletions) of critical system files, configuration files or content files, and do the tools perform critical file comparisons at least weekly?\n\nNote: For change detection purposes, critical files are usually those that do not regularly change, but the modification of which could indicate a system compromise or risk of compromise. Change detection mechanisms such as file-integrity monitoring products usually come pre-configured with critical files for the related operating system. Other critical files, such as those for custom applications, must be evaluated and defined by the entity (that is the merchant or service provider).",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "file-integrity-monitoring-cde"
        }
      ]
    },
    {
      "id": "11.5.1",
      "name": "Response plan exists for change detection alerts",
      "description": "Is a process in place to respond to any alerts generated by the change-detection solution?",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "file-integrity-monitoring-cde"
        }
      ]
    },
    {
      "id": "11.6",
      "name": "Security policies and procedures exist for monitoring and testing",
      "description": "Are security policies and operational procedures for security monitoring and testing:\n• Documented\n• In use\n• Known to all affected parties?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-incident-response-plan-bsi-exists"
        },
        {
          "id": "approved-operations-security-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "approved-vulnerability-management-policy-exists"
        },
        {
          "id": "employees-accepted-vulnerability-management-policy"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "12.1",
      "name": "Information security policy exists",
      "description": "Is a security policy established, published, maintained, and disseminated to all relevant personnel?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-information-security-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "12.1.1",
      "name": "Information security policy reviewed annually",
      "description": "Is the security policy reviewed at least annually and updated when the environment changes?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-information-security-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "12.2.a",
      "name": "Annual risk assessment process exists",
      "description": "Is an annual risk assessment process implemented that \n• Identifies critical assets, threats, and vulnerabilities, and \n• Results in a formal, documented analysis of risk?\n\nExamples of risk assessment methodologies include but are not limited to OCTAVE, ISO 27005 and NIST SP 800-30.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "risk-assessment-policy-policies"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "12.2.b",
      "name": "Annual risk assessment performed annually",
      "description": "Is the risk assessment process performed at least annually and upon significant changes to the environment (for example, acquisition, merger, relocation, etc.)?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "risk-assessment-complete"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "12.3.1",
      "name": "Proper usage requires explicit approval",
      "description": "Are usage policies for critical technologies developed to define proper use of these technologies and require the following:\n\nNote: Examples of critical technologies include, but are not limited to, remote access and wireless technologies, laptops, tablets, removable electronic media, e-mail usage and Internet usage.\n\nExplicit approval by authorized parties to use the technologies?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-asset-management-policy-bsi-exists"
        },
        {
          "id": "approved-information-security-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "12.3.2",
      "name": "Proper usage requires authentication",
      "description": "Are usage policies for critical technologies developed to define proper use of these technologies and require the following:\n\nNote: Examples of critical technologies include, but are not limited to, remote access and wireless technologies, laptops, tablets, removable electronic media, e-mail usage and Internet usage.\n\nAuthentication for use of the technology?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-asset-management-policy-bsi-exists"
        },
        {
          "id": "approved-information-security-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "12.3.3",
      "name": "List of critical devices and access exists",
      "description": "Are usage policies for critical technologies developed to define proper use of these technologies and require the following:\n\nNote: Examples of critical technologies include, but are not limited to, remote access and wireless technologies, laptops, tablets, removable electronic media, e-mail usage and Internet usage.\n\nA list of all such devices and personnel with access?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "inventory-list-descriptions"
        },
        {
          "id": "inventory-list-owners"
        },
        {
          "id": "inventory-list-user-data"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "12.3.4",
      "name": "Device owner and inventory method",
      "description": "Are usage policies for critical technologies developed to define proper use of these technologies and require the following:\n\nNote: Examples of critical technologies include, but are not limited to, remote access and wireless technologies, laptops, tablets, removable electronic media, e-mail usage and Internet usage.\n\nA method to accurately and readily determine owner, contact information, and purpose (for example, labeling, coding, and/or inventorying of devices)?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "inventory-list-descriptions"
        },
        {
          "id": "inventory-list-owners"
        },
        {
          "id": "inventory-list-user-data"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "12.3.5",
      "name": "Acceptable usage defined",
      "description": "Are usage policies for critical technologies developed to define proper use of these technologies and require the following:\n\nNote: Examples of critical technologies include, but are not limited to, remote access and wireless technologies, laptops, tablets, removable electronic media, e-mail usage and Internet usage.\n\nAcceptable uses of the technologies?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-asset-management-policy-bsi-exists"
        },
        {
          "id": "approved-information-security-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "12.3.6",
      "name": "Acceptable network locations defined",
      "description": "Are usage policies for critical technologies developed to define proper use of these technologies and require the following:\n\nNote: Examples of critical technologies include, but are not limited to, remote access and wireless technologies, laptops, tablets, removable electronic media, e-mail usage and Internet usage.\n\nAcceptable network locations for the technologies?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-asset-management-policy-bsi-exists"
        },
        {
          "id": "approved-information-security-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "12.3.7",
      "name": "List of company-approved products",
      "description": "Are usage policies for critical technologies developed to define proper use of these technologies and require the following:\n\nNote: Examples of critical technologies include, but are not limited to, remote access and wireless technologies, laptops, tablets, removable electronic media, e-mail usage and Internet usage.\n\nList of company-approved products?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-asset-management-policy-bsi-exists"
        },
        {
          "id": "approved-information-security-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "12.3.8",
      "name": "Remote access technologies access lock out",
      "description": "Are usage policies for critical technologies developed to define proper use of these technologies and require the following:\n\nNote: Examples of critical technologies include, but are not limited to, remote access and wireless technologies, laptops, tablets, removable electronic media, e-mail usage and Internet usage.\n\nAutomatic disconnect of sessions for remote-access technologies after a specific period of inactivity?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "12.3.9",
      "name": "Remote access technologies access restricted",
      "description": "Are usage policies for critical technologies developed to define proper use of these technologies and require the following:\n\nNote: Examples of critical technologies include, but are not limited to, remote access and wireless technologies, laptops, tablets, removable electronic media, e-mail usage and Internet usage.\n\nActivation of remote-access technologies for vendors and business partners only when needed by vendors and business partners, with immediate deactivation after use?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "12.3.10.a",
      "name": "Cardholder handling policy specifies prohibition",
      "description": "Are usage policies for critical technologies developed to define proper use of these technologies and require the following:\n\nNote: Examples of critical technologies include, but are not limited to, remote access and wireless technologies, laptops, tablets, removable electronic media, e-mail usage and Internet usage.\n\nFor personnel accessing cardholder data via remote-access technologies, does the policy specify the prohibition of copying, moving, and storage of cardholder data onto local hard drives and removable electronic media, unless explicitly authorized for a defined business need? \n\nWhere there is an authorized business need, the usage policies must require the data be protected in accordance with all applicable PCI DSS Requirements.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "12.3.10.b",
      "name": "Cardholder handling policy requires protection",
      "description": "Are usage policies for critical technologies developed to define proper use of these technologies and require the following:\n\nNote: Examples of critical technologies include, but are not limited to, remote access and wireless technologies, laptops, tablets, removable electronic media, e-mail usage and Internet usage.\n\nFor personnel with proper authorization, does the policy require the protection of cardholder data in accordance with PCI DSS Requirements?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "12.4",
      "name": "Personnel's information security responsibilities defined",
      "description": "Do security policy and procedures clearly define information security responsibilities for all personnel?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-information-security-roles-and-responsibilities-bsi-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "12.4.1.a",
      "name": "Accountability assigned for PCI DSS compliance",
      "description": "(a)\tHas executive management assigned overall accountability for maintaining the entity’s PCI DSS compliance?",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "12.4.1.b",
      "name": "Charter for PCI DSS compliance exists",
      "description": "Has executive management defined a charter for the PCI DSS compliance program and communication to executive management?",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "12.5.a",
      "name": "Information security responsibility formally assigned",
      "description": "Is responsibility for information security formally assigned to a Chief Security Officer or other security-knowledgeable member of management?",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-information-security-roles-and-responsibilities-bsi-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "12.5.1",
      "name": "Responsibility for policy and procedure creation",
      "description": "Are the following information security management responsibilities formally assigned to an individual or team:\n\nEstablishing, documenting, and distributing security policies and procedures?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-information-security-roles-and-responsibilities-bsi-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "12.5.2",
      "name": "Responsibility for monitoring and analyzing",
      "description": "Are the following information security management responsibilities formally assigned to an individual or team:\n\nMonitoring and analyzing security alerts and information, and distributing to appropriate personnel?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-information-security-roles-and-responsibilities-bsi-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "12.5.3",
      "name": "Responsibility for creating escalating procedures",
      "description": "Are the following information security management responsibilities formally assigned to an individual or team:\n\nEstablishing, documenting, and distributing security incident response and escalation procedures to ensure timely and effective handling of all situations?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "incident-response-plan-policies"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "12.5.4",
      "name": "Responsibility for administering user accounts",
      "description": "Are the following information security management responsibilities formally assigned to an individual or team:\n\nAdministering user accounts, including additions, deletions, and modifications?",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-information-security-roles-and-responsibilities-bsi-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "12.5.5",
      "name": "Responsibility for controlling access",
      "description": "Are the following information security management responsibilities formally assigned to an individual or team:\n\nMonitoring and controlling all access to data?",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-information-security-roles-and-responsibilities-bsi-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "12.6.a",
      "name": "Cardholder data handling security training exists",
      "description": "Is a formal security awareness program in place to make all personnel aware of the cardholder data security policy and procedures?",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-human-resource-security-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "12.6.1.a",
      "name": "Security training program includes multiple training methods",
      "description": "Do security awareness program procedures include the following:\n\nDoes the security awareness program provide multiple methods of communicating awareness and educating personnel (for example, posters, letters, memos, web based training, meetings, and promotions)?\n\nNote:  Methods can vary depending on the role of the personnel and their level of access to the cardholder data.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "pci-security-training-records"
        },
        {
          "id": "pci-security-training-tracked-records"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "12.6.1.b",
      "name": "Personnel trained upon hire and annually",
      "description": "Do security awareness program procedures include the following:\n\nAre personnel educated upon hire and at least annually?",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "pci-security-training-records"
        },
        {
          "id": "pci-security-training-tracked-records"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "12.6.1.c",
      "name": "Competed security awareness training",
      "description": "Do security awareness program procedures include the following:\n\nHave employees completed awareness training and are they aware of the importance of cardholder data security?",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "pci-security-training-records"
        },
        {
          "id": "pci-security-training-tracked-records"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "12.6.2",
      "name": "Personnel accepts security policies and procedures",
      "description": "Do security awareness program procedures include the following:\n\nAre personnel required to acknowledge at least annually that they have read and understood the security policy and procedures?",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "employees-accepted-pci-dss-saq-d"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "12.7",
      "name": "Personnel screened",
      "description": "Are potential personnel (see definition of “personnel” above) screened prior to hire to minimize the risk of attacks from internal sources? \nExamples of background checks include previous employment history, criminal record, credit history and reference checks.\n\nNote: For those potential personnel to be hired for certain positions, such as store cashiers who only have access to one card number at a time when facilitating a transaction, this requirement is a recommendation only.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "background-checks-records"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "bulk-background-check"
        }
      ]
    },
    {
      "id": "12.8.1",
      "name": "List of service providers",
      "description": "Are policies and procedures maintained and implemented to manage service providers with whom cardholder data is shared, or that could affect the security of cardholder data, as follows:\n\nIs a list of service providers maintained, including a description of the service(s) provided?",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-service-provider-inventory-exists"
        }
      ]
    },
    {
      "id": "12.8.2",
      "name": "Service providers' responsibility acknowledgement",
      "description": "Are policies and procedures maintained and implemented to manage service providers with whom cardholder data is shared, or that could affect the security of cardholder data, as follows:\n\nIs a written agreement maintained that includes an acknowledgement that the service providers are responsible for the security of cardholder data the service providers possess or otherwise store, process, or transmit on behalf of the customer, or to the extent that they could impact the security of the customer’s cardholder data environment?\n\nNote: The exact wording of an acknowledgement will depend on the agreement between the two parties, the details of the service being provided, and the responsibilities assigned to each party. The acknowledgement does not have to include the exact wording provided in this requirement.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "vendors-attestation-of-compliance-documents"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "12.8.3",
      "name": "Service provider engagement process exists",
      "description": "Are policies and procedures maintained and implemented to manage service providers with whom cardholder data is shared, or that could affect the security of cardholder data, as follows:\n\nIs there an established process for engaging service providers, including proper due diligence prior to engagement?",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-service-provider-inventory-exists"
        }
      ]
    },
    {
      "id": "12.8.4",
      "name": "Program exists to monitor service provider's PCI compliance status",
      "description": "Are policies and procedures maintained and implemented to manage service providers with whom cardholder data is shared, or that could affect the security of cardholder data, as follows:\n\nIs a program maintained to monitor service providers’ PCI DSS compliance status at least annually?",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-service-provider-inventory-exists"
        }
      ]
    },
    {
      "id": "12.8.5",
      "name": "Separation of responsibilities",
      "description": "Are policies and procedures maintained and implemented to manage service providers with whom cardholder data is shared, or that could affect the security of cardholder data, as follows:\n\nIs information maintained about which PCI DSS requirements are managed by each service provider, and which are managed by the entity?",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-service-provider-inventory-exists"
        }
      ]
    },
    {
      "id": "12.9",
      "name": "Service providers acknowledgement of responsibility to customers",
      "description": "For service providers only: Do service providers acknowledge in writing to customers that they are responsible for the security of cardholder data the service provider possesses or otherwise stores, processes, or transmits on behalf of the customer, or to the extent that they could impact the security of the customer’s cardholder data environment?\nNote: The exact wording of an acknowledgement will depend on the agreement between the two parties, the details of the service being provided, and the responsibilities assigned to each party. The acknowledgement does not have to include the exact wording provided in this requirement.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "vendors-attestation-of-compliance-documents"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "12.10.1.a",
      "name": "Incident response plan exists",
      "description": "Has an incident response plan been implemented in preparation to respond immediately to a system breach, as follows:\n\nHas an incident response plan been created to be implemented in the event of system breach?",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "incident-response-plan-policies"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "12.10.1.b.i",
      "name": "Roles, responsibilities, and communication",
      "description": "Does the plan address the following, at a minimum:\n\nRoles, responsibilities, and communication and contact strategies in the event of a compromise including notification of the payment brands, at a minimum?",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "12.10.1.b.ii",
      "name": "Specific incident response procedures",
      "description": "Does the plan address the following, at a minimum:\n\nSpecific incident response procedures?",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "incident-response-plan-policies"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "12.10.1.b.iii",
      "name": "Business recovery and continuity procedures",
      "description": "Does the plan address the following, at a minimum:\n\nBusiness recovery and continuity procedures?",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "business-continuity-plan-policy"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "12.10.1.b.iv",
      "name": "Data backup processes",
      "description": "Does the plan address the following, at a minimum:\n\nData backup processes?",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-business-continuity-and-disaster-recovery-plan-bsi-exists"
        },
        {
          "id": "approved-operations-security-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "12.10.1.b.v",
      "name": "Legal requirements for reporting compromises",
      "description": "Does the plan address the following, at a minimum:\n\nAnalysis of legal requirements for reporting compromises?",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "incident-response-plan-policies"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "12.10.1.b.vi",
      "name": "Coverage and responses of all critical system components",
      "description": "Does the plan address the following, at a minimum:\n\nCoverage and responses of all critical system components?",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "incident-response-plan-policies"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "12.10.1.b.vii",
      "name": "Payment brands' incident response",
      "description": "Does the plan address the following, at a minimum:\n\nReference or inclusion of incident response procedures from the payment brands?",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "vendors-attestation-of-compliance-documents"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "12.10.2",
      "name": "Incident response plan reviewed annually",
      "description": "Is the plan reviewed and tested at least annually, including all elements listed in Requirement 12.10.1?",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "irp-tabletop-test"
        }
      ]
    },
    {
      "id": "12.10.3",
      "name": "Personnel on-call response availability",
      "description": "Are specific personnel designated to be available on a 24/7 basis to respond to alerts?",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "incident-response-plan-policies"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "12.10.4",
      "name": "Breach response training provided to personnel",
      "description": "Is appropriate training provided to staff with security breach response responsibilities?",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "pci-security-training-records"
        },
        {
          "id": "pci-security-training-tracked-records"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "12.10.5",
      "name": "Incident response plan includes alerts",
      "description": "Are alerts from security monitoring systems included in the incident response plan?",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "incident-response-plan-policies"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "12.10.6",
      "name": "Incident response plan upkeep",
      "description": "Is a process developed and in place to modify and evolve the incident response plan according to lessons learned and to incorporate industry developments?",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-vulnerability-management-policy-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "12.11.a",
      "name": "Personnel policy adherence reviews",
      "description": "For service providers only: Are reviews performed at least quarterly to confirm personnel are following security policies and operational procedures, as follows:\n\na) Do reviews cover the following processes: \n\\-\tDaily log reviews \n\\-\tFirewall rule-set reviews \n\\-\tApplying configuration standards to new systems \n\\-\tResponding to security alerts \n\\-\tChange management processes",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "employees-accepted-pci-dss-saq-d"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "12.11.b",
      "name": "Critical system review performed quarterly",
      "description": "For service providers only: Are reviews performed at least quarterly to confirm personnel are following security policies and operational procedures, as follows:\n\n(b) Are reviews performed at least quarterly?",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "incident-response-plan-policies"
        }
      ],
      "manualEvidenceRequests": []
    }
  ],
  "sections": [
    {
      "id": "1.1.1",
      "controls": [
        {
          "id": "1.1.1"
        }
      ]
    },
    {
      "id": "1.1.2.a",
      "controls": [
        {
          "id": "1.1.2.a"
        }
      ]
    },
    {
      "id": "1.1.2.b",
      "controls": [
        {
          "id": "1.1.2.b"
        }
      ]
    },
    {
      "id": "1.1.3.a",
      "controls": [
        {
          "id": "1.1.3.a"
        }
      ]
    },
    {
      "id": "1.1.3.b",
      "controls": [
        {
          "id": "1.1.3.b"
        }
      ]
    },
    {
      "id": "1.1.4.a",
      "controls": [
        {
          "id": "1.1.4.a"
        }
      ]
    },
    {
      "id": "1.1.4.b",
      "controls": [
        {
          "id": "1.1.4.b"
        }
      ]
    },
    {
      "id": "1.1.5",
      "controls": [
        {
          "id": "1.1.5"
        }
      ]
    },
    {
      "id": "1.1.6.a",
      "controls": [
        {
          "id": "1.1.6.a"
        }
      ]
    },
    {
      "id": "1.1.6.b",
      "controls": [
        {
          "id": "1.1.6.b"
        }
      ]
    },
    {
      "id": "1.1.7.a",
      "controls": [
        {
          "id": "1.1.7.a"
        }
      ]
    },
    {
      "id": "1.1.7.b",
      "controls": [
        {
          "id": "1.1.7.b"
        }
      ]
    },
    {
      "id": "1.2.1.a",
      "controls": [
        {
          "id": "1.2.1.a"
        }
      ]
    },
    {
      "id": "1.2.1.b",
      "controls": [
        {
          "id": "1.2.1.b"
        }
      ]
    },
    {
      "id": "1.2.2",
      "controls": [
        {
          "id": "1.2.2"
        }
      ]
    },
    {
      "id": "1.2.3",
      "controls": [
        {
          "id": "1.2.3"
        }
      ]
    },
    {
      "id": "1.3.1",
      "controls": [
        {
          "id": "1.3.1"
        }
      ]
    },
    {
      "id": "1.3.2",
      "controls": [
        {
          "id": "1.3.2"
        }
      ]
    },
    {
      "id": "1.3.3",
      "controls": [
        {
          "id": "1.3.3"
        }
      ]
    },
    {
      "id": "1.3.4",
      "controls": [
        {
          "id": "1.3.4"
        }
      ]
    },
    {
      "id": "1.3.5",
      "controls": [
        {
          "id": "1.3.5"
        }
      ]
    },
    {
      "id": "1.3.6",
      "controls": [
        {
          "id": "1.3.6"
        }
      ]
    },
    {
      "id": "1.3.7.a",
      "controls": [
        {
          "id": "1.3.7.a"
        }
      ]
    },
    {
      "id": "1.3.7.b",
      "controls": [
        {
          "id": "1.3.7.b"
        }
      ]
    },
    {
      "id": "1.4.a",
      "controls": [
        {
          "id": "1.4.a"
        }
      ]
    },
    {
      "id": "1.4.b",
      "controls": [
        {
          "id": "1.4.b"
        }
      ]
    },
    {
      "id": "1.5",
      "controls": [
        {
          "id": "1.5"
        }
      ]
    },
    {
      "id": "2.1.a",
      "controls": [
        {
          "id": "2.1.a"
        }
      ]
    },
    {
      "id": "2.1.1.a",
      "controls": [
        {
          "id": "2.1.1.a"
        }
      ]
    },
    {
      "id": "2.1.1.b",
      "controls": [
        {
          "id": "2.1.1.b"
        }
      ]
    },
    {
      "id": "2.1.1.c",
      "controls": [
        {
          "id": "2.1.1.c"
        }
      ]
    },
    {
      "id": "2.1.1.d",
      "controls": [
        {
          "id": "2.1.1.d"
        }
      ]
    },
    {
      "id": "2.1.1.e",
      "controls": [
        {
          "id": "2.1.1.e"
        }
      ]
    },
    {
      "id": "2.2.a",
      "controls": [
        {
          "id": "2.2.a"
        }
      ]
    },
    {
      "id": "2.2.b",
      "controls": [
        {
          "id": "2.2.b"
        }
      ]
    },
    {
      "id": "2.2.c",
      "controls": [
        {
          "id": "2.2.c"
        }
      ]
    },
    {
      "id": "2.2.d",
      "controls": [
        {
          "id": "2.2.d"
        }
      ]
    },
    {
      "id": "2.2.1.a",
      "controls": [
        {
          "id": "2.2.1.a"
        }
      ]
    },
    {
      "id": "2.2.1.b",
      "controls": [
        {
          "id": "2.2.1.b"
        }
      ]
    },
    {
      "id": "2.2.2.a",
      "controls": [
        {
          "id": "2.2.2.a"
        }
      ]
    },
    {
      "id": "2.2.2.b",
      "controls": [
        {
          "id": "2.2.2.b"
        }
      ]
    },
    {
      "id": "2.2.3",
      "controls": [
        {
          "id": "2.2.3"
        }
      ]
    },
    {
      "id": "2.2.4.a",
      "controls": [
        {
          "id": "2.2.4.a"
        }
      ]
    },
    {
      "id": "2.2.4.b",
      "controls": [
        {
          "id": "2.2.4.b"
        }
      ]
    },
    {
      "id": "2.2.4.c",
      "controls": [
        {
          "id": "2.2.4.c"
        }
      ]
    },
    {
      "id": "2.2.5.a",
      "controls": [
        {
          "id": "2.2.5.a"
        }
      ]
    },
    {
      "id": "2.2.5.b",
      "controls": [
        {
          "id": "2.2.5.b"
        }
      ]
    },
    {
      "id": "2.2.5.c",
      "controls": [
        {
          "id": "2.2.5.c"
        }
      ]
    },
    {
      "id": "2.3.a",
      "controls": [
        {
          "id": "2.3.a"
        }
      ]
    },
    {
      "id": "2.3.b",
      "controls": [
        {
          "id": "2.3.b"
        }
      ]
    },
    {
      "id": "2.3.c",
      "controls": [
        {
          "id": "2.3.c"
        }
      ]
    },
    {
      "id": "2.3.d",
      "controls": [
        {
          "id": "2.3.d"
        }
      ]
    },
    {
      "id": "2.4.a",
      "controls": [
        {
          "id": "2.4.a"
        }
      ]
    },
    {
      "id": "2.4.b",
      "controls": [
        {
          "id": "2.4.b"
        }
      ]
    },
    {
      "id": "2.5",
      "controls": [
        {
          "id": "2.5"
        }
      ]
    },
    {
      "id": "2.6",
      "controls": [
        {
          "id": "2.6"
        }
      ]
    },
    {
      "id": "3.1.a",
      "controls": [
        {
          "id": "3.1.a"
        }
      ]
    },
    {
      "id": "3.1.b",
      "controls": [
        {
          "id": "3.1.b"
        }
      ]
    },
    {
      "id": "3.1.c",
      "controls": [
        {
          "id": "3.1.c"
        }
      ]
    },
    {
      "id": "3.1.d",
      "controls": [
        {
          "id": "3.1.d"
        }
      ]
    },
    {
      "id": "3.1.e",
      "controls": [
        {
          "id": "3.1.e"
        }
      ]
    },
    {
      "id": "3.2.1",
      "controls": [
        {
          "id": "3.2.1"
        }
      ]
    },
    {
      "id": "3.2.2",
      "controls": [
        {
          "id": "3.2.2"
        }
      ]
    },
    {
      "id": "3.2.3",
      "controls": [
        {
          "id": "3.2.3"
        }
      ]
    },
    {
      "id": "3.2.a",
      "controls": [
        {
          "id": "3.2.a"
        }
      ]
    },
    {
      "id": "3.2.b",
      "controls": [
        {
          "id": "3.2.b"
        }
      ]
    },
    {
      "id": "3.2.c",
      "controls": [
        {
          "id": "3.2.c"
        }
      ]
    },
    {
      "id": "3.3",
      "controls": [
        {
          "id": "3.3"
        }
      ]
    },
    {
      "id": "3.4",
      "controls": [
        {
          "id": "3.4"
        }
      ]
    },
    {
      "id": "3.4.1.a",
      "controls": [
        {
          "id": "3.4.1.a"
        }
      ]
    },
    {
      "id": "3.4.1.b",
      "controls": [
        {
          "id": "3.4.1.b"
        }
      ]
    },
    {
      "id": "3.4.1.c",
      "controls": [
        {
          "id": "3.4.1.c"
        }
      ]
    },
    {
      "id": "3.5.1",
      "controls": [
        {
          "id": "3.5.1"
        }
      ]
    },
    {
      "id": "3.5.2",
      "controls": [
        {
          "id": "3.5.2"
        }
      ]
    },
    {
      "id": "3.5.3",
      "controls": [
        {
          "id": "3.5.3"
        }
      ]
    },
    {
      "id": "3.5.4",
      "controls": [
        {
          "id": "3.5.4"
        }
      ]
    },
    {
      "id": "3.6.1",
      "controls": [
        {
          "id": "3.6.1"
        }
      ]
    },
    {
      "id": "3.6.2",
      "controls": [
        {
          "id": "3.6.2"
        }
      ]
    },
    {
      "id": "3.6.3",
      "controls": [
        {
          "id": "3.6.3"
        }
      ]
    },
    {
      "id": "3.6.4",
      "controls": [
        {
          "id": "3.6.4"
        }
      ]
    },
    {
      "id": "3.6.5.a",
      "controls": [
        {
          "id": "3.6.5.a"
        }
      ]
    },
    {
      "id": "3.6.5.b",
      "controls": [
        {
          "id": "3.6.5.b"
        }
      ]
    },
    {
      "id": "3.6.5.c",
      "controls": [
        {
          "id": "3.6.5.c"
        }
      ]
    },
    {
      "id": "3.6.6",
      "controls": [
        {
          "id": "3.6.6"
        }
      ]
    },
    {
      "id": "3.6.7",
      "controls": [
        {
          "id": "3.6.7"
        }
      ]
    },
    {
      "id": "3.6.8",
      "controls": [
        {
          "id": "3.6.8"
        }
      ]
    },
    {
      "id": "3.6.a",
      "controls": [
        {
          "id": "3.6.a"
        }
      ]
    },
    {
      "id": "3.6.b",
      "controls": [
        {
          "id": "3.6.b"
        }
      ]
    },
    {
      "id": "3.7",
      "controls": [
        {
          "id": "3.7"
        }
      ]
    },
    {
      "id": "4.1.1",
      "controls": [
        {
          "id": "4.1.1"
        }
      ]
    },
    {
      "id": "4.1.a",
      "controls": [
        {
          "id": "4.1.a"
        }
      ]
    },
    {
      "id": "4.1.b",
      "controls": [
        {
          "id": "4.1.b"
        }
      ]
    },
    {
      "id": "4.1.c",
      "controls": [
        {
          "id": "4.1.c"
        }
      ]
    },
    {
      "id": "4.1.d",
      "controls": [
        {
          "id": "4.1.d"
        }
      ]
    },
    {
      "id": "4.1.e",
      "controls": [
        {
          "id": "4.1.e"
        }
      ]
    },
    {
      "id": "4.2.a",
      "controls": [
        {
          "id": "4.2.a"
        }
      ]
    },
    {
      "id": "4.2.b",
      "controls": [
        {
          "id": "4.2.b"
        }
      ]
    },
    {
      "id": "4.3",
      "controls": [
        {
          "id": "4.3"
        }
      ]
    },
    {
      "id": "5.1",
      "controls": [
        {
          "id": "5.1"
        }
      ]
    },
    {
      "id": "5.1.1",
      "controls": [
        {
          "id": "5.1.1"
        }
      ]
    },
    {
      "id": "5.1.2",
      "controls": [
        {
          "id": "5.1.2"
        }
      ]
    },
    {
      "id": "5.2.a",
      "controls": [
        {
          "id": "5.2.a"
        }
      ]
    },
    {
      "id": "5.2.b",
      "controls": [
        {
          "id": "5.2.b"
        }
      ]
    },
    {
      "id": "5.2.c",
      "controls": [
        {
          "id": "5.2.c"
        }
      ]
    },
    {
      "id": "5.3",
      "controls": [
        {
          "id": "5.3"
        }
      ]
    },
    {
      "id": "5.4",
      "controls": [
        {
          "id": "5.4"
        }
      ]
    },
    {
      "id": "6.1",
      "controls": [
        {
          "id": "6.1"
        }
      ]
    },
    {
      "id": "6.2.a",
      "controls": [
        {
          "id": "6.2.a"
        }
      ]
    },
    {
      "id": "6.2.b",
      "controls": [
        {
          "id": "6.2.b"
        }
      ]
    },
    {
      "id": "6.3.a",
      "controls": [
        {
          "id": "6.3.a"
        }
      ]
    },
    {
      "id": "6.3.b",
      "controls": [
        {
          "id": "6.3.b"
        }
      ]
    },
    {
      "id": "6.3.c",
      "controls": [
        {
          "id": "6.3.c"
        }
      ]
    },
    {
      "id": "6.3.1",
      "controls": [
        {
          "id": "6.3.1"
        }
      ]
    },
    {
      "id": "6.3.2",
      "controls": [
        {
          "id": "6.3.2"
        }
      ]
    },
    {
      "id": "6.4.1.a",
      "controls": [
        {
          "id": "6.4.1.a"
        }
      ]
    },
    {
      "id": "6.4.1.b",
      "controls": [
        {
          "id": "6.4.1.b"
        }
      ]
    },
    {
      "id": "6.4.2",
      "controls": [
        {
          "id": "6.4.2"
        }
      ]
    },
    {
      "id": "6.4.3",
      "controls": [
        {
          "id": "6.4.3"
        }
      ]
    },
    {
      "id": "6.4.4",
      "controls": [
        {
          "id": "6.4.4"
        }
      ]
    },
    {
      "id": "6.4.5.1",
      "controls": [
        {
          "id": "6.4.5.1"
        }
      ]
    },
    {
      "id": "6.4.5.2",
      "controls": [
        {
          "id": "6.4.5.2"
        }
      ]
    },
    {
      "id": "6.4.5.3.a",
      "controls": [
        {
          "id": "6.4.5.3.a"
        }
      ]
    },
    {
      "id": "6.4.5.3.b",
      "controls": [
        {
          "id": "6.4.5.3.b"
        }
      ]
    },
    {
      "id": "6.4.5.4",
      "controls": [
        {
          "id": "6.4.5.4"
        }
      ]
    },
    {
      "id": "6.4.5.a",
      "controls": [
        {
          "id": "6.4.5.a"
        }
      ]
    },
    {
      "id": "6.4.6",
      "controls": [
        {
          "id": "6.4.6"
        }
      ]
    },
    {
      "id": "6.5.1",
      "controls": [
        {
          "id": "6.5.1"
        }
      ]
    },
    {
      "id": "6.5.2",
      "controls": [
        {
          "id": "6.5.2"
        }
      ]
    },
    {
      "id": "6.5.3",
      "controls": [
        {
          "id": "6.5.3"
        }
      ]
    },
    {
      "id": "6.5.4",
      "controls": [
        {
          "id": "6.5.4"
        }
      ]
    },
    {
      "id": "6.5.5",
      "controls": [
        {
          "id": "6.5.5"
        }
      ]
    },
    {
      "id": "6.5.6",
      "controls": [
        {
          "id": "6.5.6"
        }
      ]
    },
    {
      "id": "6.5.7",
      "controls": [
        {
          "id": "6.5.7"
        }
      ]
    },
    {
      "id": "6.5.8",
      "controls": [
        {
          "id": "6.5.8"
        }
      ]
    },
    {
      "id": "6.5.9",
      "controls": [
        {
          "id": "6.5.9"
        }
      ]
    },
    {
      "id": "6.5.10",
      "controls": [
        {
          "id": "6.5.10"
        }
      ]
    },
    {
      "id": "6.5.a",
      "controls": [
        {
          "id": "6.5.a"
        }
      ]
    },
    {
      "id": "6.5.b",
      "controls": [
        {
          "id": "6.5.b"
        }
      ]
    },
    {
      "id": "6.6",
      "controls": [
        {
          "id": "6.6"
        }
      ]
    },
    {
      "id": "6.7",
      "controls": [
        {
          "id": "6.7"
        }
      ]
    },
    {
      "id": "7.1",
      "controls": [
        {
          "id": "7.1"
        }
      ]
    },
    {
      "id": "7.1.1",
      "controls": [
        {
          "id": "7.1.1"
        }
      ]
    },
    {
      "id": "7.1.2",
      "controls": [
        {
          "id": "7.1.2"
        }
      ]
    },
    {
      "id": "7.1.3",
      "controls": [
        {
          "id": "7.1.3"
        }
      ]
    },
    {
      "id": "7.1.4",
      "controls": [
        {
          "id": "7.1.4"
        }
      ]
    },
    {
      "id": "7.2.1",
      "controls": [
        {
          "id": "7.2.1"
        }
      ]
    },
    {
      "id": "7.2.2",
      "controls": [
        {
          "id": "7.2.2"
        }
      ]
    },
    {
      "id": "7.2.3",
      "controls": [
        {
          "id": "7.2.3"
        }
      ]
    },
    {
      "id": "7.3",
      "controls": [
        {
          "id": "7.3"
        }
      ]
    },
    {
      "id": "8.1.1",
      "controls": [
        {
          "id": "8.1.1"
        }
      ]
    },
    {
      "id": "8.1.2",
      "controls": [
        {
          "id": "8.1.2"
        }
      ]
    },
    {
      "id": "8.1.3",
      "controls": [
        {
          "id": "8.1.3"
        }
      ]
    },
    {
      "id": "8.1.4",
      "controls": [
        {
          "id": "8.1.4"
        }
      ]
    },
    {
      "id": "8.1.5.a",
      "controls": [
        {
          "id": "8.1.5.a"
        }
      ]
    },
    {
      "id": "8.1.5.b",
      "controls": [
        {
          "id": "8.1.5.b"
        }
      ]
    },
    {
      "id": "8.1.6.a",
      "controls": [
        {
          "id": "8.1.6.a"
        }
      ]
    },
    {
      "id": "8.1.7",
      "controls": [
        {
          "id": "8.1.7"
        }
      ]
    },
    {
      "id": "8.1.8",
      "controls": [
        {
          "id": "8.1.8"
        }
      ]
    },
    {
      "id": "8.2",
      "controls": [
        {
          "id": "8.2"
        }
      ]
    },
    {
      "id": "8.2.1.a",
      "controls": [
        {
          "id": "8.2.1.a"
        }
      ]
    },
    {
      "id": "8.2.2",
      "controls": [
        {
          "id": "8.2.2"
        }
      ]
    },
    {
      "id": "8.2.3.a",
      "controls": [
        {
          "id": "8.2.3.a"
        }
      ]
    },
    {
      "id": "8.2.4.a",
      "controls": [
        {
          "id": "8.2.4.a"
        }
      ]
    },
    {
      "id": "8.2.5.a",
      "controls": [
        {
          "id": "8.2.5.a"
        }
      ]
    },
    {
      "id": "8.2.6",
      "controls": [
        {
          "id": "8.2.6"
        }
      ]
    },
    {
      "id": "8.3.1",
      "controls": [
        {
          "id": "8.3.1"
        }
      ]
    },
    {
      "id": "8.3.2",
      "controls": [
        {
          "id": "8.3.2"
        }
      ]
    },
    {
      "id": "8.4.a",
      "controls": [
        {
          "id": "8.4.a"
        }
      ]
    },
    {
      "id": "8.4.b",
      "controls": [
        {
          "id": "8.4.b"
        }
      ]
    },
    {
      "id": "8.5",
      "controls": [
        {
          "id": "8.5"
        }
      ]
    },
    {
      "id": "8.6",
      "controls": [
        {
          "id": "8.6"
        }
      ]
    },
    {
      "id": "8.7.a",
      "controls": [
        {
          "id": "8.7.a"
        }
      ]
    },
    {
      "id": "8.7.b",
      "controls": [
        {
          "id": "8.7.b"
        }
      ]
    },
    {
      "id": "8.7.c",
      "controls": [
        {
          "id": "8.7.c"
        }
      ]
    },
    {
      "id": "8.8",
      "controls": [
        {
          "id": "8.8"
        }
      ]
    },
    {
      "id": "9.1",
      "controls": [
        {
          "id": "9.1"
        }
      ]
    },
    {
      "id": "9.1.1.a",
      "controls": [
        {
          "id": "9.1.1.a"
        }
      ]
    },
    {
      "id": "9.1.1.b",
      "controls": [
        {
          "id": "9.1.1.b"
        }
      ]
    },
    {
      "id": "9.1.1.c",
      "controls": [
        {
          "id": "9.1.1.c"
        }
      ]
    },
    {
      "id": "9.1.1.d",
      "controls": [
        {
          "id": "9.1.1.d"
        }
      ]
    },
    {
      "id": "9.1.2",
      "controls": [
        {
          "id": "9.1.2"
        }
      ]
    },
    {
      "id": "9.1.3",
      "controls": [
        {
          "id": "9.1.3"
        }
      ]
    },
    {
      "id": "9.2.a",
      "controls": [
        {
          "id": "9.2.a"
        }
      ]
    },
    {
      "id": "9.2.b",
      "controls": [
        {
          "id": "9.2.b"
        }
      ]
    },
    {
      "id": "9.2.c",
      "controls": [
        {
          "id": "9.2.c"
        }
      ]
    },
    {
      "id": "9.3",
      "controls": [
        {
          "id": "9.3"
        }
      ]
    },
    {
      "id": "9.4.1",
      "controls": [
        {
          "id": "9.4.1"
        }
      ]
    },
    {
      "id": "9.4.2.a",
      "controls": [
        {
          "id": "9.4.2.a"
        }
      ]
    },
    {
      "id": "9.4.2.b",
      "controls": [
        {
          "id": "9.4.2.b"
        }
      ]
    },
    {
      "id": "9.4.3",
      "controls": [
        {
          "id": "9.4.3"
        }
      ]
    },
    {
      "id": "9.4.4.a",
      "controls": [
        {
          "id": "9.4.4.a"
        }
      ]
    },
    {
      "id": "9.4.4.b",
      "controls": [
        {
          "id": "9.4.4.b"
        }
      ]
    },
    {
      "id": "9.4.4.c",
      "controls": [
        {
          "id": "9.4.4.c"
        }
      ]
    },
    {
      "id": "9.5",
      "controls": [
        {
          "id": "9.5"
        }
      ]
    },
    {
      "id": "9.5.1",
      "controls": [
        {
          "id": "9.5.1"
        }
      ]
    },
    {
      "id": "9.6.1",
      "controls": [
        {
          "id": "9.6.1"
        }
      ]
    },
    {
      "id": "9.6.2",
      "controls": [
        {
          "id": "9.6.2"
        }
      ]
    },
    {
      "id": "9.6.3",
      "controls": [
        {
          "id": "9.6.3"
        }
      ]
    },
    {
      "id": "9.6.a",
      "controls": [
        {
          "id": "9.6.a"
        }
      ]
    },
    {
      "id": "9.7",
      "controls": [
        {
          "id": "9.7"
        }
      ]
    },
    {
      "id": "9.7.1.a",
      "controls": [
        {
          "id": "9.7.1.a"
        }
      ]
    },
    {
      "id": "9.7.1.b",
      "controls": [
        {
          "id": "9.7.1.b"
        }
      ]
    },
    {
      "id": "9.8.1.a",
      "controls": [
        {
          "id": "9.8.1.a"
        }
      ]
    },
    {
      "id": "9.8.1.b",
      "controls": [
        {
          "id": "9.8.1.b"
        }
      ]
    },
    {
      "id": "9.8.2",
      "controls": [
        {
          "id": "9.8.2"
        }
      ]
    },
    {
      "id": "9.8.a",
      "controls": [
        {
          "id": "9.8.a"
        }
      ]
    },
    {
      "id": "9.8.b",
      "controls": [
        {
          "id": "9.8.b"
        }
      ]
    },
    {
      "id": "9.9.1.a",
      "controls": [
        {
          "id": "9.9.1.a"
        }
      ]
    },
    {
      "id": "9.9.1.b",
      "controls": [
        {
          "id": "9.9.1.b"
        }
      ]
    },
    {
      "id": "9.9.1.c",
      "controls": [
        {
          "id": "9.9.1.c"
        }
      ]
    },
    {
      "id": "9.9.2.a",
      "controls": [
        {
          "id": "9.9.2.a"
        }
      ]
    },
    {
      "id": "9.9.2.b",
      "controls": [
        {
          "id": "9.9.2.b"
        }
      ]
    },
    {
      "id": "9.9.3.a",
      "controls": [
        {
          "id": "9.9.3.a"
        }
      ]
    },
    {
      "id": "9.9.3.b",
      "controls": [
        {
          "id": "9.9.3.b"
        }
      ]
    },
    {
      "id": "9.9.a",
      "controls": [
        {
          "id": "9.9.a"
        }
      ]
    },
    {
      "id": "9.9.b",
      "controls": [
        {
          "id": "9.9.b"
        }
      ]
    },
    {
      "id": "9.9.c",
      "controls": [
        {
          "id": "9.9.c"
        }
      ]
    },
    {
      "id": "9.10",
      "controls": [
        {
          "id": "9.10"
        }
      ]
    },
    {
      "id": "10.1.a",
      "controls": [
        {
          "id": "10.1.a"
        }
      ]
    },
    {
      "id": "10.1.b",
      "controls": [
        {
          "id": "10.1.b"
        }
      ]
    },
    {
      "id": "10.2.1",
      "controls": [
        {
          "id": "10.2.1"
        }
      ]
    },
    {
      "id": "10.2.2",
      "controls": [
        {
          "id": "10.2.2"
        }
      ]
    },
    {
      "id": "10.2.3",
      "controls": [
        {
          "id": "10.2.3"
        }
      ]
    },
    {
      "id": "10.2.4",
      "controls": [
        {
          "id": "10.2.4"
        }
      ]
    },
    {
      "id": "10.2.5",
      "controls": [
        {
          "id": "10.2.5"
        }
      ]
    },
    {
      "id": "10.2.6",
      "controls": [
        {
          "id": "10.2.6"
        }
      ]
    },
    {
      "id": "10.2.7",
      "controls": [
        {
          "id": "10.2.7"
        }
      ]
    },
    {
      "id": "10.3.1",
      "controls": [
        {
          "id": "10.3.1"
        }
      ]
    },
    {
      "id": "10.3.2",
      "controls": [
        {
          "id": "10.3.2"
        }
      ]
    },
    {
      "id": "10.3.3",
      "controls": [
        {
          "id": "10.3.3"
        }
      ]
    },
    {
      "id": "10.3.4",
      "controls": [
        {
          "id": "10.3.4"
        }
      ]
    },
    {
      "id": "10.3.5",
      "controls": [
        {
          "id": "10.3.5"
        }
      ]
    },
    {
      "id": "10.3.6",
      "controls": [
        {
          "id": "10.3.6"
        }
      ]
    },
    {
      "id": "10.4",
      "controls": [
        {
          "id": "10.4"
        }
      ]
    },
    {
      "id": "10.4.1.a",
      "controls": [
        {
          "id": "10.4.1.a"
        }
      ]
    },
    {
      "id": "10.4.1.b",
      "controls": [
        {
          "id": "10.4.1.b"
        }
      ]
    },
    {
      "id": "10.4.1.c",
      "controls": [
        {
          "id": "10.4.1.c"
        }
      ]
    },
    {
      "id": "10.4.2.a",
      "controls": [
        {
          "id": "10.4.2.a"
        }
      ]
    },
    {
      "id": "10.4.2.b",
      "controls": [
        {
          "id": "10.4.2.b"
        }
      ]
    },
    {
      "id": "10.4.3",
      "controls": [
        {
          "id": "10.4.3"
        }
      ]
    },
    {
      "id": "10.5.1",
      "controls": [
        {
          "id": "10.5.1"
        }
      ]
    },
    {
      "id": "10.5.2",
      "controls": [
        {
          "id": "10.5.2"
        }
      ]
    },
    {
      "id": "10.5.3",
      "controls": [
        {
          "id": "10.5.3"
        }
      ]
    },
    {
      "id": "10.5.4",
      "controls": [
        {
          "id": "10.5.4"
        }
      ]
    },
    {
      "id": "10.5.5",
      "controls": [
        {
          "id": "10.5.5"
        }
      ]
    },
    {
      "id": "10.6.1.a",
      "controls": [
        {
          "id": "10.6.1.a"
        }
      ]
    },
    {
      "id": "10.6.1.b",
      "controls": [
        {
          "id": "10.6.1.b"
        }
      ]
    },
    {
      "id": "10.6.2.a",
      "controls": [
        {
          "id": "10.6.2.a"
        }
      ]
    },
    {
      "id": "10.6.2.b",
      "controls": [
        {
          "id": "10.6.2.b"
        }
      ]
    },
    {
      "id": "10.6.3.a",
      "controls": [
        {
          "id": "10.6.3.a"
        }
      ]
    },
    {
      "id": "10.6.3.b",
      "controls": [
        {
          "id": "10.6.3.b"
        }
      ]
    },
    {
      "id": "10.7.a",
      "controls": [
        {
          "id": "10.7.a"
        }
      ]
    },
    {
      "id": "10.7.b",
      "controls": [
        {
          "id": "10.7.b"
        }
      ]
    },
    {
      "id": "10.7.c",
      "controls": [
        {
          "id": "10.7.c"
        }
      ]
    },
    {
      "id": "10.8.a",
      "controls": [
        {
          "id": "10.8.a"
        }
      ]
    },
    {
      "id": "10.8.b",
      "controls": [
        {
          "id": "10.8.b"
        }
      ]
    },
    {
      "id": "10.9",
      "controls": [
        {
          "id": "10.9"
        }
      ]
    },
    {
      "id": "11.1.1",
      "controls": [
        {
          "id": "11.1.1"
        }
      ]
    },
    {
      "id": "11.1.2.a",
      "controls": [
        {
          "id": "11.1.2.a"
        }
      ]
    },
    {
      "id": "11.1.2.b",
      "controls": [
        {
          "id": "11.1.2.b"
        }
      ]
    },
    {
      "id": "11.1.a",
      "controls": [
        {
          "id": "11.1.a"
        }
      ]
    },
    {
      "id": "11.1.b",
      "controls": [
        {
          "id": "11.1.b"
        }
      ]
    },
    {
      "id": "11.1.c",
      "controls": [
        {
          "id": "11.1.c"
        }
      ]
    },
    {
      "id": "11.1.d",
      "controls": [
        {
          "id": "11.1.d"
        }
      ]
    },
    {
      "id": "11.2.1.a",
      "controls": [
        {
          "id": "11.2.1.a"
        }
      ]
    },
    {
      "id": "11.2.1.b",
      "controls": [
        {
          "id": "11.2.1.b"
        }
      ]
    },
    {
      "id": "11.2.1.c",
      "controls": [
        {
          "id": "11.2.1.c"
        }
      ]
    },
    {
      "id": "11.2.2.a",
      "controls": [
        {
          "id": "11.2.2.a"
        }
      ]
    },
    {
      "id": "11.2.2.b",
      "controls": [
        {
          "id": "11.2.2.b"
        }
      ]
    },
    {
      "id": "11.2.2.c",
      "controls": [
        {
          "id": "11.2.2.c"
        }
      ]
    },
    {
      "id": "11.2.3.a",
      "controls": [
        {
          "id": "11.2.3.a"
        }
      ]
    },
    {
      "id": "11.2.3.b",
      "controls": [
        {
          "id": "11.2.3.b"
        }
      ]
    },
    {
      "id": "11.2.3.c",
      "controls": [
        {
          "id": "11.2.3.c"
        }
      ]
    },
    {
      "id": "11.3",
      "controls": [
        {
          "id": "11.3"
        }
      ]
    },
    {
      "id": "11.3.1.a",
      "controls": [
        {
          "id": "11.3.1.a"
        }
      ]
    },
    {
      "id": "11.3.1.b",
      "controls": [
        {
          "id": "11.3.1.b"
        }
      ]
    },
    {
      "id": "11.3.2.a",
      "controls": [
        {
          "id": "11.3.2.a"
        }
      ]
    },
    {
      "id": "11.3.2.b",
      "controls": [
        {
          "id": "11.3.2.b"
        }
      ]
    },
    {
      "id": "11.3.3",
      "controls": [
        {
          "id": "11.3.3"
        }
      ]
    },
    {
      "id": "11.3.4.1.a",
      "controls": [
        {
          "id": "11.3.4.1.a"
        }
      ]
    },
    {
      "id": "11.3.4.1.b",
      "controls": [
        {
          "id": "11.3.4.1.b"
        }
      ]
    },
    {
      "id": "11.3.4.1.c",
      "controls": [
        {
          "id": "11.3.4.1.c"
        }
      ]
    },
    {
      "id": "11.3.4.1.d",
      "controls": [
        {
          "id": "11.3.4.1.d"
        }
      ]
    },
    {
      "id": "11.3.4.a",
      "controls": [
        {
          "id": "11.3.4.a"
        }
      ]
    },
    {
      "id": "11.3.4.b",
      "controls": [
        {
          "id": "11.3.4.b"
        }
      ]
    },
    {
      "id": "11.3.4.c",
      "controls": [
        {
          "id": "11.3.4.c"
        }
      ]
    },
    {
      "id": "11.4.a",
      "controls": [
        {
          "id": "11.4.a"
        }
      ]
    },
    {
      "id": "11.4.b",
      "controls": [
        {
          "id": "11.4.b"
        }
      ]
    },
    {
      "id": "11.4.c",
      "controls": [
        {
          "id": "11.4.c"
        }
      ]
    },
    {
      "id": "11.5.1",
      "controls": [
        {
          "id": "11.5.1"
        }
      ]
    },
    {
      "id": "11.5.a",
      "controls": [
        {
          "id": "11.5.a"
        }
      ]
    },
    {
      "id": "11.5.b",
      "controls": [
        {
          "id": "11.5.b"
        }
      ]
    },
    {
      "id": "11.6",
      "controls": [
        {
          "id": "11.6"
        }
      ]
    },
    {
      "id": "12.1",
      "controls": [
        {
          "id": "12.1"
        }
      ]
    },
    {
      "id": "12.1.1",
      "controls": [
        {
          "id": "12.1.1"
        }
      ]
    },
    {
      "id": "12.2.a",
      "controls": [
        {
          "id": "12.2.a"
        }
      ]
    },
    {
      "id": "12.2.b",
      "controls": [
        {
          "id": "12.2.b"
        }
      ]
    },
    {
      "id": "12.3.1",
      "controls": [
        {
          "id": "12.3.1"
        }
      ]
    },
    {
      "id": "12.3.2",
      "controls": [
        {
          "id": "12.3.2"
        }
      ]
    },
    {
      "id": "12.3.3",
      "controls": [
        {
          "id": "12.3.3"
        }
      ]
    },
    {
      "id": "12.3.4",
      "controls": [
        {
          "id": "12.3.4"
        }
      ]
    },
    {
      "id": "12.3.5",
      "controls": [
        {
          "id": "12.3.5"
        }
      ]
    },
    {
      "id": "12.3.6",
      "controls": [
        {
          "id": "12.3.6"
        }
      ]
    },
    {
      "id": "12.3.7",
      "controls": [
        {
          "id": "12.3.7"
        }
      ]
    },
    {
      "id": "12.3.8",
      "controls": [
        {
          "id": "12.3.8"
        }
      ]
    },
    {
      "id": "12.3.9",
      "controls": [
        {
          "id": "12.3.9"
        }
      ]
    },
    {
      "id": "12.3.10.a",
      "controls": [
        {
          "id": "12.3.10.a"
        }
      ]
    },
    {
      "id": "12.3.10.b",
      "controls": [
        {
          "id": "12.3.10.b"
        }
      ]
    },
    {
      "id": "12.4",
      "controls": [
        {
          "id": "12.4"
        }
      ]
    },
    {
      "id": "12.4.1.a",
      "controls": [
        {
          "id": "12.4.1.a"
        }
      ]
    },
    {
      "id": "12.4.1.b",
      "controls": [
        {
          "id": "12.4.1.b"
        }
      ]
    },
    {
      "id": "12.5.1",
      "controls": [
        {
          "id": "12.5.1"
        }
      ]
    },
    {
      "id": "12.5.2",
      "controls": [
        {
          "id": "12.5.2"
        }
      ]
    },
    {
      "id": "12.5.3",
      "controls": [
        {
          "id": "12.5.3"
        }
      ]
    },
    {
      "id": "12.5.4",
      "controls": [
        {
          "id": "12.5.4"
        }
      ]
    },
    {
      "id": "12.5.5",
      "controls": [
        {
          "id": "12.5.5"
        }
      ]
    },
    {
      "id": "12.5.a",
      "controls": [
        {
          "id": "12.5.a"
        }
      ]
    },
    {
      "id": "12.6.1.a",
      "controls": [
        {
          "id": "12.6.1.a"
        }
      ]
    },
    {
      "id": "12.6.1.b",
      "controls": [
        {
          "id": "12.6.1.b"
        }
      ]
    },
    {
      "id": "12.6.1.c",
      "controls": [
        {
          "id": "12.6.1.c"
        }
      ]
    },
    {
      "id": "12.6.2",
      "controls": [
        {
          "id": "12.6.2"
        }
      ]
    },
    {
      "id": "12.6.a",
      "controls": [
        {
          "id": "12.6.a"
        }
      ]
    },
    {
      "id": "12.7",
      "controls": [
        {
          "id": "12.7"
        }
      ]
    },
    {
      "id": "12.8.1",
      "controls": [
        {
          "id": "12.8.1"
        }
      ]
    },
    {
      "id": "12.8.2",
      "controls": [
        {
          "id": "12.8.2"
        }
      ]
    },
    {
      "id": "12.8.3",
      "controls": [
        {
          "id": "12.8.3"
        }
      ]
    },
    {
      "id": "12.8.4",
      "controls": [
        {
          "id": "12.8.4"
        }
      ]
    },
    {
      "id": "12.8.5",
      "controls": [
        {
          "id": "12.8.5"
        }
      ]
    },
    {
      "id": "12.9",
      "controls": [
        {
          "id": "12.9"
        }
      ]
    },
    {
      "id": "12.10.1.a",
      "controls": [
        {
          "id": "12.10.1.a"
        }
      ]
    },
    {
      "id": "12.10.1.b.i",
      "controls": [
        {
          "id": "12.10.1.b.i"
        }
      ]
    },
    {
      "id": "12.10.1.b.ii",
      "controls": [
        {
          "id": "12.10.1.b.ii"
        }
      ]
    },
    {
      "id": "12.10.1.b.iii",
      "controls": [
        {
          "id": "12.10.1.b.iii"
        }
      ]
    },
    {
      "id": "12.10.1.b.iv",
      "controls": [
        {
          "id": "12.10.1.b.iv"
        }
      ]
    },
    {
      "id": "12.10.1.b.v",
      "controls": [
        {
          "id": "12.10.1.b.v"
        }
      ]
    },
    {
      "id": "12.10.1.b.vi",
      "controls": [
        {
          "id": "12.10.1.b.vi"
        }
      ]
    },
    {
      "id": "12.10.1.b.vii",
      "controls": [
        {
          "id": "12.10.1.b.vii"
        }
      ]
    },
    {
      "id": "12.10.2",
      "controls": [
        {
          "id": "12.10.2"
        }
      ]
    },
    {
      "id": "12.10.3",
      "controls": [
        {
          "id": "12.10.3"
        }
      ]
    },
    {
      "id": "12.10.4",
      "controls": [
        {
          "id": "12.10.4"
        }
      ]
    },
    {
      "id": "12.10.5",
      "controls": [
        {
          "id": "12.10.5"
        }
      ]
    },
    {
      "id": "12.10.6",
      "controls": [
        {
          "id": "12.10.6"
        }
      ]
    },
    {
      "id": "12.11.a",
      "controls": [
        {
          "id": "12.11.a"
        }
      ]
    },
    {
      "id": "12.11.b",
      "controls": [
        {
          "id": "12.11.b"
        }
      ]
    }
  ]
}
