// This file should be safe to import from web/client

import createHash from "create-hash";

import { VANTA_CONSTANT } from "../../../base/types/helpers";
import type { Maybe } from "../../../base/types/maybe";
import { isSome } from "../../../base/types/maybe";

/**
 * Extract an account ID from a credentials metadata.
 *
 * If govcloud is being used then a fixed string is returned.
 * @param metadata
 */
export function getAccountId(metadata: string) {
  const parsed = JSON.parse(metadata);
  return getAccountIdFromArnAndRegions(parsed.assumeRoleArn, parsed.regions);
}

const govCloudRegex = /us-gov.*/;
const ARN_REGEX = /^arn:aws:iam::(\d{12}):.+\/.+$/;
export const ROLE_ARN_REGEX = /^arn:aws:iam::\d{12}:role\/.+$/;

export function getAccountIdFromArnAndRegions(
  arn: Maybe<string>,
  regions: string[]
): string {
  const match = isSome(arn) ? ARN_REGEX.exec(arn) : false;
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (match) {
    return match[1];
  } else {
    if (regions.some(region => govCloudRegex.test(region))) {
      return "GovCloud account";
    } else {
      return "AWS account not linked with ARN";
    }
  }
}

export const makeExternalId = (domainId: string) =>
  createHash("sha256")
    .update(domainId + VANTA_CONSTANT)
    .digest("hex")
    .substr(0, 15)
    .toUpperCase();
