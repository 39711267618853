import { isSome } from "common/base/types/maybe";
import React from "react";
import styled from "styled-components";

import { GRID_SPACING } from "../../../../alpaca/base/grid";
import { BASE_TYPOGRAPHY } from "../../../../alpaca/base/typography";
import { BodyText } from "../../../../alpaca/components";
import { CustomTaskItem } from "./custom-task-item";
import type { OnboardingListUser } from "./user-onboarding-list";

interface IInternalProps {
  user: OnboardingListUser;
  title: string;
  isAdminList: boolean;
}

const OnboardingTaskList: React.FC<IInternalProps> = ({
  user,
  title,
  isAdminList,
}) => {
  const { roleCompletionRecord } = user;
  if (!isSome(roleCompletionRecord)) {
    return null;
  }

  const taskCompletionList = isAdminList
    ? roleCompletionRecord.adminTaskCompletions
    : roleCompletionRecord.employeeTaskCompletions;

  if (taskCompletionList.length === 0) {
    return null;
  }

  return (
    <>
      <BodyText
        style={{ marginBottom: `${GRID_SPACING}px` }}
        fontWeight={BASE_TYPOGRAPHY.FONT_WEIGHTS.BOLD}
      >
        {title}
      </BodyText>
      {taskCompletionList.map(tc => (
        <div key={tc.id}>
          <CustomTaskItem
            taskCompletion={tc}
            userId={user.id}
            isUserActive={user.isActive}
            roleCompletionId={roleCompletionRecord.id}
          />
        </div>
      ))}
    </>
  );
};

interface IProps {
  user: OnboardingListUser;
}

export const OnboardingRoleChecklists: React.FC<IProps> = ({ user }) => (
  <>
    <SectionContainer>
      <OnboardingTaskList
        title="Additional employee tasks"
        user={user}
        isAdminList={false}
      />
    </SectionContainer>
    <SectionContainer>
      <OnboardingTaskList
        title="Additional admin tasks"
        user={user}
        isAdminList={true}
      />
    </SectionContainer>
  </>
);

const SectionContainer = styled.div`
  margin-top: 20px;
  & > div {
    margin-bottom: 12px;
  }
`;
