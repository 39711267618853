/**
 * This is a terrible stopgap page for customers who need to connect to GovCloud.
 *
 * We can't assume a GovCloud role from our non govcloud account so we need them to
 * connect an actual IAM role.
 */

import { Button, FormGroup, InputGroup, Intent } from "@blueprintjs/core";
import React, { useState } from "react";
import { Redirect } from "react-router";

import { LogError } from "../../../../errors";
import { useSetCredentialsMutation } from "../../../../gen/components";
import { AppToaster } from "../../../../helpers/toaster";
import { VantaDashboardPage } from "../../../vanta-chrome/page-content/vanta-dashboard-page";

export const ConnectToGovCloudPage: React.FunctionComponent = () => {
  const [accessKeyId, setAccessKeyId] = useState("");
  const [secretAccessKey, setSecretAccessKey] = useState("");
  const [readyToRedirect, setReadyToRedirect] = useState(false);
  const [setCredentials] = useSetCredentialsMutation();

  const submitGovcloudCredentials = async () => {
    try {
      const didSetCredentials = await setCredentials({
        variables: {
          credentials: JSON.stringify({
            accessId: accessKeyId,
            accessSecret: secretAccessKey,
            regions: ["us-gov-east-1", "us-gov-west-1"],
          }),
          service: "awsgovcloud",
        },
      });
      if (didSetCredentials.errors) {
        throw new Error(JSON.stringify(didSetCredentials.errors));
      }
      AppToaster.show({
        icon: "tick",
        intent: Intent.SUCCESS,
        message: "Credentials connected successfully!",
        timeout: 2500,
      });
      setReadyToRedirect(true);
    } catch (e) {
      LogError(e);
    }
  };

  if (readyToRedirect) {
    return <Redirect to="/" />;
  }

  return (
    <VantaDashboardPage
      headingInfo={{ title: "Connect AWS GovCloud", description: "" }}
    >
      <FormGroup
        label="AWS Credentials"
        labelFor="text-input"
        helperText="Your credentials are encrypted and stored securely."
      >
        <InputGroup
          placeholder="AWS Access Key ID"
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            setAccessKeyId(e.currentTarget.value)
          }
        />
        <InputGroup
          placeholder="AWS Secret Access Key"
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            setSecretAccessKey(e.currentTarget.value)
          }
        />
      </FormGroup>
      <Button onClick={submitGovcloudCredentials} intent={Intent.PRIMARY}>
        Submit
      </Button>
    </VantaDashboardPage>
  );
};
