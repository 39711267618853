import { Callout, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import React from "react";
import styled from "styled-components";

import { GRID_SPACING } from "../../../../alpaca/base/grid";
import type { IPolicyQuestionSchema } from "../../info/policies/policyDocSchema";
import type { AnswersMap } from "../beta-policies-wizard-page";
import { PoliciesH5, Row, RowAnswer, RowQuestion } from "../policies-v2-styles";
import { PolicyAnswersList } from "./policies-answers-list";

// @see https://app.shortcut.com/vanta/story/12332/implement-styles-and-remove-inline-styling-for-the-policy-upload-flow
// To implement styles and remove inline styles
// Copy may also change
/**
 * Given already valid questionnaire answers (input validation is performed prior to this step),
 * render a condensed display of the questionnaire answers and file
 */
export const PoliciesWizardReviewStep: React.FC<{
  policyFile: File;
  policyTitle: string;
  questionSchema?: Maybe<IPolicyQuestionSchema>;
  questionnaireAnswers: AnswersMap;
  isMajorChange?: Maybe<boolean>;
}> = ({
  policyFile,
  policyTitle,
  questionSchema,
  questionnaireAnswers,
  isMajorChange,
}) => (
  <>
    <StyledTitle>{policyTitle}</StyledTitle>
    <Row>
      <RowQuestion>Policy file</RowQuestion>
      <RowAnswer>{policyFile.name}</RowAnswer>
    </Row>
    {questionSchema ? (
      <PolicyAnswersList
        answersMap={questionnaireAnswers}
        questionSchema={questionSchema}
      />
    ) : null}
    {isSome(isMajorChange) ? (
      <Row>
        <RowQuestion>Employees must reaccept</RowQuestion>
        <RowAnswer>{isMajorChange ? "Yes" : "No"}</RowAnswer>
      </Row>
    ) : null}
    <StyledCallout
      icon={IconNames.ERROR}
      intent={Intent.PRIMARY}
      title={"Reminder"}
    >
      <StyledCalloutBody>
        Make sure that your policy reflects your organization's beliefs and
        practices. The best policies are the policies that you follow.
      </StyledCalloutBody>
    </StyledCallout>
  </>
);

const StyledCallout = styled(Callout)`
  margin-top: ${4 * GRID_SPACING}px;
`;

const StyledCalloutBody = styled.div`
  margin-top: ${1.5 * GRID_SPACING}px;
`;

const StyledTitle = styled(PoliciesH5)`
  text-align: center;
  margin-bottom: ${2 * GRID_SPACING}px;
`;
