import React from "react";

import Logo from "../../../static/images/logo/logo.svg";

/**
 * Height is a css size string
 */
export const VantaLogo: React.FC<{ height: string }> = ({ height }) => (
  <Logo height={height} />
);

export enum VANTA_LOGO_SIZES {
  HEADING = "48px",
  LOGIN = "64px",
}
