import { Button, H2, H6, Intent } from "@blueprintjs/core";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import moment from "moment";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";

import { TableStylesDeprecated } from "../../../../alpaca/base/deprecated";
import { LogError, LogErrorMessage } from "../../../../errors";
import type {
  CreateWorkPaperMutationFn,
  GetAuditingInfoQuery,
  GetAuditorDocumentsQuery,
} from "../../../../gen/components";
import {
  GetAuditorDocumentsDocument,
  useCreateWorkPaperMutation,
  useGetAuditorDocumentsQuery,
} from "../../../../gen/components";
import { UI_DATE_FORMAT_TO_SECONDS } from "../../../../helpers/common";
import { AppToaster } from "../../../../helpers/toaster";
import { FullPageSpinner } from "../../../helpers/FullPageSpinner";
import { DataTable } from "../../components/data-table";
import { UserContext } from "../../user-context";
import { CreateWorkPaperDialog } from "./create-work-paper-dialog";
import { WorkPaperDetailContainer } from "./work-paper-detail-container";

interface IAuditorDocumentsProps {
  auditorDocuments: NonNullable<
    GetAuditorDocumentsQuery["organization"]
  >["auditorDocuments"];
  auditableUsers: NonNullable<GetAuditingInfoQuery["user"]>["auditableUsers"];
  createWorkPaper: CreateWorkPaperMutationFn;
}

const AuditorDocumentsComponent: React.FC<IAuditorDocumentsProps> = ({
  auditorDocuments,
  auditableUsers,
  createWorkPaper,
}) => {
  const { user } = useContext(UserContext);
  if (!isSome(user)) {
    LogErrorMessage("No user context");
  }
  const domainId = user!.domain.id;
  const [createWorkPaperDialogOpen, setWorkPaperDialogOpen] = useState(false);
  const history = useHistory();
  const selectedDocId = new URLSearchParams(location.search).get(
    "auditorDocumentId"
  );
  if (isSome(selectedDocId)) {
    const selectedDoc = auditorDocuments.find(doc => doc.id === selectedDocId);
    if (isSome(selectedDoc)) {
      switch (selectedDoc.type) {
        case "work-paper": {
          return (
            <WorkPaperDetailContainer
              customerDomainDisplayName={selectedDoc.customerDomainName}
              workPaper={selectedDoc}
            />
          );
        }
        default: {
          // unsupported types, do nothing
        }
      }
    }
  }
  return (
    <Container>
      <CreateWorkPaperDialog
        isOpen={createWorkPaperDialogOpen}
        auditableUsers={auditableUsers}
        onCloseDialog={() => setWorkPaperDialogOpen(false)}
        onCreateWorkPaper={async (
          workPaperName: string,
          customerDomainId: string
        ) => {
          const workPaper = await createWorkPaper({
            variables: {
              domainId,
              customerDomainId,
              name: workPaperName,
            },
          });
          AppToaster.show({
            intent: Intent.SUCCESS,
            message: "Work paper generated.",
            timeout: 2500,
            action: {
              onClick: () =>
                history.push({
                  search: `?auditorDocumentId=${
                    workPaper.data!.createWorkPaper.id
                  }`,
                }),
              text: "Go",
            },
          });
        }}
      />
      <div id="auditor-documents">
        <H2> Auditor documents </H2>
        <H6> Manage all of your audit documents</H6>
      </div>
      <WorkPaperContainer>
        <Button onClick={() => setWorkPaperDialogOpen(true)}>
          Create Work Paper
        </Button>
        <DataTable
          data={auditorDocuments}
          columnOrder={[
            "name",
            "customerDomainName",
            "createdAt",
            "createdBy",
            "lastUpdatedAt",
            "lastUpdatedBy",
          ]}
          header={{
            name: "Document name",
            customerDomainName: "Company",
            createdAt: "Created",
            createdBy: "Created by",
            lastUpdatedAt: "Last updated",
            lastUpdatedBy: "Last updated by",
          }}
          createRow={createAuditorDocumentRow}
          onRowClick={doc => {
            history.push({
              search: `?auditorDocumentId=${doc.id}`,
            });
          }}
        ></DataTable>
      </WorkPaperContainer>
    </Container>
  );
};

const WorkPaperContainer = styled.div`
  margin: 40px 0 80px 0;
`;

interface IAuditorDocumentsViewProps {
  auditableUsers: NonNullable<GetAuditingInfoQuery["user"]>["auditableUsers"];
}
export const AuditorDocumentsView: React.FC<IAuditorDocumentsViewProps> = ({
  auditableUsers,
}) => {
  const { error, loading, data } = useGetAuditorDocumentsQuery();
  const [createWorkPaper] = useCreateWorkPaperMutation({
    refetchQueries: [
      {
        query: GetAuditorDocumentsDocument,
      },
    ],
  });
  if (loading) {
    return <FullPageSpinner />;
  }
  if (error) {
    LogError(error);
    return null;
  }
  if (!isSome(data?.organization.auditorDocuments)) {
    LogErrorMessage("Bad fetch");
    return null;
  }
  return (
    <AuditorDocumentsComponent
      auditableUsers={auditableUsers}
      auditorDocuments={data!.organization.auditorDocuments}
      createWorkPaper={createWorkPaper}
    />
  );
};

const createAuditorDocumentRow = (
  doc: NonNullable<
    GetAuditorDocumentsQuery["organization"]
  >["auditorDocuments"][number]
) => {
  // all auditor users are required to have a displayName
  const lastUpdatedBy = isSome(doc.lastUpdate.user)
    ? doc.lastUpdate.user.displayName
    : "Removed user";
  const createdBy = isSome(doc.created.user)
    ? doc.created.user.displayName
    : "Removed user";
  return {
    name: doc.name,
    customerDomainName: doc.customerDomainName,
    type: doc.type,
    createdBy,
    createdAt: getDateStringFromTimestampString(doc.created.timestamp),
    lastUpdatedBy,
    lastUpdatedAt: getDateStringFromTimestampString(doc.lastUpdate.timestamp),
  };
};

export const getDateStringFromTimestampString = (timestamp: string) =>
  moment(timestamp).format(UI_DATE_FORMAT_TO_SECONDS);

const Container = styled.div`
  font-size: 14px;
  margin-bottom: 160px;
  ${TableStylesDeprecated}
  table {
    width: 100%;
    tbody tr {
      &:hover {
        cursor: pointer;
        background: #f7f7f7;
      }
    }
  }
`;

gql`
  query getAuditorDocuments {
    organization {
      id
      auditorDocuments {
        id
        name
        type
        customerDomainId
        customerDomainName
        created {
          user {
            id
            displayName
          }
          timestamp
        }
        lastUpdate {
          user {
            id
            displayName
          }
          timestamp
        }
      }
    }
  }
`;

gql`
  mutation createWorkPaper(
    $domainId: ID!
    $customerDomainId: String!
    $name: String!
  ) {
    createWorkPaper(
      domainId: $domainId
      customerDomainId: $customerDomainId
      name: $name
    ) {
      id
      name
    }
  }
`;
