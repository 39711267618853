import { Intent } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { getTransformedOrElse } from "common/base/types/maybe";
import { distanceInWordsStrict, format, isPast } from "date-fns";
import React from "react";

import { BASE_PALETTE } from "../../../../../alpaca/base/colors";
import { GRID_SPACING } from "../../../../../alpaca/base/grid";
import { BASE_TYPOGRAPHY } from "../../../../../alpaca/base/typography";
import type { IconName, ITooltipProps } from "../../../../../alpaca/components";
import {
  Icon,
  MetaText,
  SpacedList,
  Tooltip,
} from "../../../../../alpaca/components";

const DATE_FORMAT = "MMM D";

type DateIntent =
  | typeof Intent.SUCCESS
  | typeof Intent.DANGER
  | typeof Intent.NONE;

const INTENT_TO_COLOR: { [k in DateIntent]: string } = {
  [Intent.SUCCESS]: BASE_PALETTE.KALE,
  [Intent.DANGER]: BASE_PALETTE.TOMATO,
  [Intent.NONE]: BASE_PALETTE.SLATE,
};

interface IProps {
  date: Date;
  isDeadline?: Maybe<boolean>;
  icon?: Maybe<IconName>;
  intent?: Maybe<DateIntent>;
  tooltipProps?: Maybe<ITooltipProps>;
}

/**
 * Renders a date for a task (due date, completion date, etc.). Can
 * optionally also take in an icon or tooltip.
 */
export const TaskDateDetail: React.FC<IProps> = ({
  date,
  isDeadline,
  icon,
  intent,
  tooltipProps,
}) => {
  const pastDue = Boolean(isDeadline) && isPast(date);
  const finalIntent = intent ?? (pastDue ? Intent.DANGER : Intent.NONE);
  const color = INTENT_TO_COLOR[finalIntent];

  const dateText = pastDue
    ? distanceInWordsStrict(new Date(), date, { addSuffix: true })
    : format(date, DATE_FORMAT);
  const dateElement = (
    <MetaText
      lineHeight="12px"
      color={color}
      fontWeight={BASE_TYPOGRAPHY.FONT_WEIGHTS.SLIGHTLY_BOLD}
    >
      {dateText}
    </MetaText>
  );
  const dateMaybeWithIcon = getTransformedOrElse(
    icon,
    iconName => (
      <SpacedList marginOverride={GRID_SPACING}>
        <Icon icon={iconName} iconSize={12} color={color} />
        {dateElement}
      </SpacedList>
    ),
    dateElement
  );
  const dateDetailMaybeWithTooltip = getTransformedOrElse(
    tooltipProps,
    props => <Tooltip {...props}>{dateMaybeWithIcon}</Tooltip>,
    dateMaybeWithIcon
  );
  return dateDetailMaybeWithTooltip;
};
