import type { IInputGroupProps2 } from "@blueprintjs/core";
import { InputGroup } from "@blueprintjs/core";
import classnames from "classnames";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import React from "react";
import styled from "styled-components";

import { BASE_PALETTE } from "../../base/colors";
import type { IconName } from "../index";
import { Icon } from "../index";
import { FormGroup } from "./form-group";
import { FormClasses, INPUT_GROUP_STYLES } from "./styles";

interface IAdditionalProps {
  alpacaIcon?: Maybe<IconName>;
  label?: Maybe<React.ReactNode>;
  helperText?: Maybe<React.ReactNode>;
}

export type ITextInputProps = IInputGroupProps2 & IAdditionalProps;

export const TextInput: React.FC<ITextInputProps> = ({
  alpacaIcon,
  label,
  helperText,
  ...props
}) => {
  const inputGroup = (
    <StyledInputGroup
      {...props}
      className={classnames(FormClasses.INPUT_GROUP, props.className)}
      leftIcon={
        isSome(alpacaIcon) ? (
          <Icon icon={alpacaIcon} color={BASE_PALETTE.SMOKE} />
        ) : (
          props.leftIcon
        )
      }
    />
  );
  if (!isSome(label) && !isSome(helperText)) {
    return inputGroup;
  }
  return (
    <FormGroup
      label={label}
      helperText={helperText}
      intent={props.intent}
      className={props.className}
    >
      {inputGroup}
    </FormGroup>
  );
};

const StyledInputGroup = styled(InputGroup)`
  ${INPUT_GROUP_STYLES}
`;
