import { Spinner } from "@blueprintjs/core";
import {
  specificResourceGraphQLTypeToEnumValue,
  SpecificResourceToInventoryType,
} from "common/utils/inventory";
import {
  deserializeVantaAttributes,
  hasExternallyManagedVantaAttributes,
} from "common/utils/vantaAttributes";
import gql from "graphql-tag";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import type { SqldbsQuery } from "../../../../gen/components";
import {
  useSetInventoryResourceVantaAttributeMutation,
  useSqldbsQuery,
} from "../../../../gen/components";
import { InventoryCard } from "../inventory-card";
import {
  AccountTag,
  BackupTag,
  EncryptionTag,
  EPHITag,
  RegionTag,
} from "../tags";
import {
  fetchMoreResources,
  getDebounceContext,
  getResourceFilterParams,
  invalidInventoryType,
  PAGE_SIZE,
  setResourceVantaAttributeFn,
} from "../utils";
import type { IInventoryTabProps } from "./shared-interface";

export const SqlDatabases: React.FC<IInventoryTabProps> = ({
  searchString,
}) => {
  const { data, fetchMore, loading } = useSqldbsQuery({
    variables: {
      first: PAGE_SIZE,
      filterParams: getResourceFilterParams(searchString),
    },
    context: getDebounceContext("sql"),
  });

  if (loading) {
    return <Spinner />;
  }

  const cards = data?.organization.resources.edges?.map(item => (
    <SqlCard key={item.node.id} item={item} domain={data.organization} />
  ));

  return (
    <InfiniteScroll
      className="inventory-list-card-group"
      dataLength={data?.organization.resources.edges.length ?? 0}
      next={async () => fetchMoreResources(data, fetchMore)}
      hasMore={data?.organization.resources.pageInfo.hasNextPage ?? false}
      loader={<Spinner />}
    >
      {cards}
    </InfiniteScroll>
  );
};

const SqlCard: React.FC<{
  item: NonNullable<SqldbsQuery["organization"]>["resources"]["edges"][number];
  domain: NonNullable<SqldbsQuery["organization"]>;
}> = ({ item, domain }) => {
  const [setResourceVantaAttribute] =
    useSetInventoryResourceVantaAttributeMutation();

  const specificResourceKind = specificResourceGraphQLTypeToEnumValue(
    item.node.__typename
  );
  const type = SpecificResourceToInventoryType[specificResourceKind](
    item.node as any
  );
  const resourceMongoId = item.node.id;
  const vantaAttributes = deserializeVantaAttributes(
    item.node.vantaAttributes ?? []
  );

  let uid;
  let labels;
  switch (item.node.__typename) {
    case "SpecificAzureDatabaseForExternalSQLVariantResource": {
      uid = item.node.uniqueId;
      labels = [
        <RegionTag key="region" region={item.node.location} />,
        <EncryptionTag
          key="encryption"
          // Azure MariaDB, MySQL, and PostgreSQL DBs are encrypted
          // by default and encryption can't be disabled
          encrypted={true}
        />,
        <BackupTag
          key="backup"
          backupRetentionDays={item.node.backupRetentionDays}
        />,
      ];
      if (vantaAttributes.containsEPHI) {
        labels.push(<EPHITag key="ephi" />);
      }
      break;
    }
    case "SpecificAzureSQLDatabaseResource": {
      uid = item.node.uniqueId;
      labels = [
        <RegionTag key="region" region={item.node.location} />,
        <EncryptionTag
          key="encryption"
          encrypted={item.node.encryptionEnabled}
        />,
        <BackupTag
          key="backup"
          backupRetentionDays={item.node.shortTermBackupRetentionDays}
        />,
      ];
      if (vantaAttributes.containsEPHI) {
        labels.push(<EPHITag key="ephi" />);
      }
      break;
    }
    case "SpecificAzureSQLServerOnVMsResource": {
      uid = item.node.uniqueId;
      labels = [
        <RegionTag key="region" region={item.node.location} />,
        // SQL Server on VMs have disks encrypted in 2 ways:
        //
        // - Server-side encryption with platform-managed-key (default)
        // - Azure disk encryption (with Key Vault)
        //
        // I don't think it's possible for these disks to not be encrypted-at-rest.
        //
        // @see https://docs.microsoft.com/en-us/azure/azure-sql/virtual-machines/windows/security-considerations-best-practices#encryption
        <EncryptionTag key="encryption" encrypted={true} />,
      ];
      if (vantaAttributes.containsEPHI) {
        labels.push(<EPHITag key="ephi" />);
      }
      break;
    }
    case "SpecificAzureSQLManagedInstanceResource": {
      uid = item.node.uniqueId;
      labels = [
        <RegionTag key="region" region={item.node.location} />,
        <EncryptionTag
          key="encryption"
          encrypted={true} // For all databases created since 2019, Azure encrypts by default.
          // Customer can provide their own key, but there's still encryption no matter what.
        />,
        <BackupTag
          key="backup"
          backupRetentionDays={item.node.shortTermBackupRetentionDays}
        />,
      ];
      if (vantaAttributes.containsEPHI) {
        labels.push(<EPHITag key="ephi" />);
      }
      break;
    }
    case "SpecificCloudSQLInstanceResource": {
      uid = item.node.name;
      labels = [
        <AccountTag key="account" cloudAccountId={item.node.projectId} />,
        <EncryptionTag
          key="encryption"
          encrypted={true} // GCP encrypts by default, and it can't be disabled
        />,
      ];
      if (vantaAttributes.containsEPHI) {
        labels.push(<EPHITag key="ephi" />);
      }
      break;
    }
    case "SpecificDigitalOceanRelationalDbClusterResource": {
      uid = item.node.name;
      labels = [
        <RegionTag key="region" region={item.node.region} />,
        // https://docs.digitalocean.com/products/databases/mysql/#managed-database-cluster-features
        // https://docs.digitalocean.com/products/databases/postgresql/#managed-database-cluster-features
        // encrypted by default
        // automatic daily backups retained for 7 days
        <EncryptionTag key="encryption" encrypted={true} />,
        <BackupTag
          key="backup"
          backupRetentionDays={7} //
        />,
      ];
      if (vantaAttributes.containsEPHI) {
        labels.push(<EPHITag key="ephi" />);
      }
      break;
    }
    case "SpecificRDSInstanceResource": {
      uid = item.node.dbInstanceIdentifier;
      labels = [
        <AccountTag key="account" cloudAccountId={item.node.account} />,
        <RegionTag key="region" region={item.node.region} />,
        <BackupTag
          key="backup"
          backupRetentionDays={item.node.backupRetentionPeriod}
        />,
        <EncryptionTag
          key="encryption"
          encrypted={item.node.storageEncrypted}
        />,
      ];
      if (vantaAttributes.containsEPHI) {
        labels.push(<EPHITag key="ephi" />);
      }
      break;
    }
    case "SpecificSpannerInstanceResource": {
      uid = item.node.uniqueId;
      labels = [
        <AccountTag key="account" cloudAccountId={item.node.projectId} />,
        <EncryptionTag
          key="encryption"
          encrypted={true} // GCP encrypts by default, and it can't be disabled
        />,
      ];
      if (vantaAttributes.containsEPHI) {
        labels.push(<EPHITag key="ephi" />);
      }
      break;
    }
    default:
      return invalidInventoryType(item.node.__typename);
  }

  const locked = hasExternallyManagedVantaAttributes(
    item.node.vantaAttributes ?? []
  );
  const setVantaAttribute = setResourceVantaAttributeFn(
    setResourceVantaAttribute,
    specificResourceKind,
    resourceMongoId,
    item.node.__typename
  );

  return (
    <InventoryCard
      type={type}
      uid={uid}
      key={item.node.id}
      name={item.node.name}
      labels={labels}
      description={vantaAttributes.description}
      owner={vantaAttributes.ownerId}
      canContainUserData={true}
      containsUserData={vantaAttributes.containsUserData}
      containsEPHI={vantaAttributes.containsEPHI}
      userDataDescription={vantaAttributes.userDataStored}
      locked={locked}
      setVantaAttribute={setVantaAttribute}
    />
  );
};

gql`
  query sqldbs($first: Int!, $after: String, $filterParams: filterParams) {
    organization {
      id
      name
      resources(
        first: $first
        after: $after
        genericResourceType: SQLDatabase
        sortParams: { field: "createdAt", direction: -1 }
        filterParams: $filterParams
      ) {
        totalCount
        edges {
          node {
            id
            __typename
            uniqueId
            vantaAttributes {
              key
              value
              managedExternally
            }
            ... on SpecificAzureDatabaseForExternalSQLVariantResource {
              name
              databaseType
              location
              backupRetentionDays
            }
            ... on SpecificAzureSQLDatabaseResource {
              name
              encryptionEnabled
              location
              shortTermBackupRetentionDays
            }
            ... on SpecificAzureSQLManagedInstanceResource {
              name
              location
              shortTermBackupRetentionDays
            }
            ... on SpecificAzureSQLServerOnVMsResource {
              name
              location
            }
            ... on SpecificCloudSQLInstanceResource {
              name
              backupsEnabled
              projectId
            }
            ... on SpecificDigitalOceanRelationalDbClusterResource {
              name
              region
            }
            ... on SpecificRDSInstanceResource {
              name
              account
              region
              storageEncrypted
              backupRetentionPeriod
              engine
              dbInstanceIdentifier
            }
            ... on SpecificSpannerInstanceResource {
              name
              projectId
            }
          }
        }
        pageInfo {
          hasNextPage
          endCursor
          startCursor
          hasPreviousPage
        }
      }
    }
  }
`;
