import "./panel.scss";
import "./query-page.scss";

import {
  Button,
  Callout,
  Card,
  Checkbox,
  Divider,
  EditableText,
  Intent,
} from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import { groupBy } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";

import { H1 } from "../../alpaca/components";
import { escapeStringForCSV } from "../../components/pages/risk-report/csv-generator";
import { downloadStringToFile } from "../../components/pages/risk-report/risk-report-download-helpers";
import { LogError, LogErrorMessage } from "../../errors";
import type { FetchRiskRegisterQuery } from "../../gen/components";
import {
  FetchAllRiskQuestionsDocument,
  useAddRiskQuestionMutation,
  useDeleteRiskQuestionMutation,
  useFetchAllRiskQuestionsQuery,
  useFetchRiskRegisterInternalLazyQuery,
  useUpdateRiskQuestionMutation,
} from "../../gen/components";
import { UI_DATE_FORMAT } from "../../helpers/common";
import { FullPageSpinner } from "../helpers/FullPageSpinner";

const COMMON_OPTIONS = {
  refetchQueries: [
    {
      query: FetchAllRiskQuestionsDocument,
    },
  ],
};

interface IProps {
  domainId: Maybe<string>;
}

export const RiskRegisterPanel: React.FC<IProps> = ({ domainId }) => {
  const [downloaded, setDownloaded] = useState(false);
  const [addQuestion] = useAddRiskQuestionMutation(COMMON_OPTIONS);
  const [deleteQuestion] = useDeleteRiskQuestionMutation(COMMON_OPTIONS);
  const [updateQuestion] = useUpdateRiskQuestionMutation(COMMON_OPTIONS);
  const { loading, error, data } = useFetchAllRiskQuestionsQuery();
  const [fetchReportQuery, queryResult] =
    useFetchRiskRegisterInternalLazyQuery();

  useEffect(() => {
    const maybeDomain = queryResult.data?.internal.domainById;
    if (!isSome(maybeDomain)) {
      return;
    }

    generateAndDownloadRiskReport(maybeDomain);
    generateAndDownloadRiskSurvey(maybeDomain);
    setDownloaded(true);
  }, [queryResult.data?.internal.domainById]);

  useEffect(() => {
    setDownloaded(false);
  }, [domainId]);

  if (error) {
    LogError(error);
    return null;
  }

  if (loading) {
    return <FullPageSpinner />;
  }

  if (!data) {
    LogErrorMessage("Bad fetch");
    return null;
  }
  const questions = data.riskQuestions;

  return (
    <div>
      <H1>Risk register v1 data</H1>
      <div>
        <Button
          disabled={!isSome(domainId) || downloaded}
          loading={queryResult.loading}
          onClick={() => {
            fetchReportQuery({
              variables: { domainId: domainId! },
            });
          }}
        >
          {isSome(domainId) && !downloaded
            ? "Export risk survey and report"
            : "Select a domain to export data"}
        </Button>
      </div>
      <Divider />
      <H1>Risk register questions</H1>
      <Callout
        intent={Intent.WARNING}
        title="Changing this changes the risk register!"
      >
        Only click a checkbox if you are changing the "no-risk" answer of a risk
        register question.
      </Callout>
      <Button
        onClick={() => {
          addQuestion().catch(LogError);
        }}
      >
        Add question
      </Button>
      {questions.map(question => (
        <Card key={question.id}>
          <h2>
            <EditableText
              multiline={true}
              minLines={1}
              maxLines={5}
              onConfirm={value => {
                updateQuestion({
                  variables: {
                    id: question.id,
                    category: question.category,
                    text: value,
                    affirmative: true,
                  },
                }).catch(LogError);
              }}
              defaultValue={question.text ?? ("" as string)}
            />

            <Button
              style={{ float: "right" }}
              onClick={() => {
                deleteQuestion({ variables: { id: question.id } }).catch(
                  LogError
                );
              }}
            >
              &times;
            </Button>
          </h2>
          <div>
            Category:{" "}
            <EditableText
              onConfirm={value => {
                updateQuestion({
                  variables: {
                    id: question.id,
                    category: value,
                    text: question.text,
                    affirmative: question.affirmative,
                  },
                }).catch(LogError);
              }}
              defaultValue={question.category}
            />
          </div>
          <Checkbox
            checked={question.affirmative}
            label="Affirmative"
            onChange={value => {
              updateQuestion({
                variables: {
                  id: question.id,
                  category: question.category,
                  text: question.text,
                  affirmative: value.currentTarget.checked,
                },
              }).catch(LogError);
            }}
          />
        </Card>
      ))}
    </div>
  );
};

gql`
  mutation DeleteRiskQuestion($id: ID!) {
    deleteRiskQuestion(id: $id)
  }
`;

gql`
  mutation AddRiskQuestion {
    addRiskQuestion(text: "TBD", category: "Unknown", affirmative: true)
  }
`;

gql`
  mutation UpdateRiskQuestion(
    $id: ID!
    $text: String!
    $category: String!
    $affirmative: Boolean!
  ) {
    updateRiskQuestion(
      id: $id
      text: $text
      category: $category
      affirmative: $affirmative
    )
  }
`;

gql`
  query FetchAllRiskQuestions {
    riskQuestions {
      id
      text
      category
      affirmative
    }
  }
`;

function generateAndDownloadRiskReport(
  domain: NonNullable<FetchRiskRegisterQuery["organization"]>
) {
  const headings = `scenarioNumber,scenarioText,likelihood,impact,taskText,completedAt,dismissedAt,dismissedReason\n`;

  const reportText = domain.riskRegister.scenarios
    .map((scenario, scenarioIndex) => {
      const scenarioProlog = `${scenarioIndex + 1},${escapeStringForCSV(
        scenario.text
      )},${scenario.likelihood},${scenario.impact}`;

      return scenario.tasks.length > 0
        ? scenario.tasks
            .map(
              task =>
                `${scenarioProlog},${escapeStringForCSV(
                  task.text
                )},${momentOrNull(task.completedAt)},${momentOrNull(
                  task.dismissedAt
                )},${
                  isSome(task.dismissReason)
                    ? escapeStringForCSV(task.dismissReason)
                    : ""
                }`
            )
            .join("\n")
        : `${scenarioProlog},,,,`;
    })
    .join("\n");
  downloadStringToFile(
    headings + reportText,
    `${domain.displayName}-risk-report-data.csv`
  );
}

function generateAndDownloadRiskSurvey(
  domain: NonNullable<FetchRiskRegisterQuery["organization"]>
) {
  const byCategory = groupBy(domain.riskRegister.questions, q => q.category);

  const surveyText = Object.entries(byCategory)
    .map(
      ([group, questions]) =>
        `${group}\n\n${questions
          .map(q => {
            let response = "unanswered";
            if (q.complete || q.dismissed) {
              if (q.affirmative) {
                response = q.dismissed ? "No" : "Yes";
              } else {
                response = q.dismissed ? "Yes" : "No";
              }
            }
            return `${q.text}\n${response}\n`;
          })
          .join("\n")}`
    )
    .join("\n");

  downloadStringToFile(
    surveyText,
    `${domain.displayName}-risk-survey-data.txt`
  );
}

function momentOrNull(maybeDate: Maybe<string>) {
  return isSome(maybeDate)
    ? escapeStringForCSV(moment(maybeDate).format(UI_DATE_FORMAT))
    : "";
}

gql`
  query FetchRiskRegisterInternal($domainId: ID!) {
    internal {
      domainById(id: $domainId) {
        id
        displayName
        riskRegister {
          questions {
            id
            complete
            dismissed
            text
            category
            affirmative
            scenarios {
              id
              text
              questionId
              likelihood
              impact
              ...tasks
            }
          }
          scenarios {
            id
            text
            questionId
            likelihood
            impact
            ...tasks
          }
          tasks {
            id
            text
            dismissedAt
            completedAt
            dismissReason
          }
        }
      }
    }
  }
`;
