import React from "react";
import styled from "styled-components";

import { BASE_PALETTE } from "../../../alpaca/base/colors";
import { GRID_SPACING } from "../../../alpaca/base/grid";
import { BodyText } from "../../../alpaca/components";

export const TableEmptyState: React.FC<{
  content: string | React.ReactNode;
}> = ({ content }) => (
  <StyledEmptyStateDiv>
    {typeof content === "string" ? (
      <BodyText as="div">{content}</BodyText>
    ) : (
      content
    )}
  </StyledEmptyStateDiv>
);

const StyledEmptyStateDiv = styled.div`
  color: ${BASE_PALETTE.SMOKE};
  width: 100%;
  height: ${20 * GRID_SPACING}px;
  display: flex;
  background-color: ${BASE_PALETTE.VAPE}88;
  justify-content: space-around;
  text-align: center;
  align-items: center;
`;
