import type { IdentityProviderService } from "common/base/types/helpers";
import gql from "graphql-tag";
import React from "react";

import { LogError } from "../../../errors";
import type { GetAccessUsersQuery } from "../../../gen/components";
import { useGetAccessUsersQuery } from "../../../gen/components";
import { FullPageSpinner } from "../../helpers/FullPageSpinner";
import { Card } from "../components/card";
import type { AccountsAccessCredential } from "./access-page";
import { accessPageCredentialToKey } from "./access-page-with-service";
import { UsersAccessTable } from "./users-access-table";

interface IAccessContainerProps {
  credential: AccountsAccessCredential;
}

type AccessQueryUser = NonNullable<
  GetAccessUsersQuery["organization"]
>["users"][number];
export type AccessUser = AccessQueryUser & {
  idp: NonNullable<AccessQueryUser["idp"]>;
};

export const UsersAccessContainer: React.FC<IAccessContainerProps> = ({
  credential,
}) => {
  const { service } = credential;
  const { error, loading, data } = useGetAccessUsersQuery();

  if (loading) {
    return <FullPageSpinner />;
  }
  if (error || !data?.organization) {
    LogError(error ?? new Error("No domain fetched for access accounts query"));
    return null;
  }
  const domainInfo = data.organization;
  const { users } = domainInfo;
  const selectedUsers = users.filter(u =>
    isUserFromIdentityProvider(u, service as IdentityProviderService)
  );
  return (
    <Card>
      <UsersAccessTable
        credentialKey={accessPageCredentialToKey(credential)}
        users={selectedUsers as AccessUser[]}
      />
    </Card>
  );
};

// Filters out users not from the appropriate fetch
function isUserFromIdentityProvider(
  user: AccessQueryUser,
  service: IdentityProviderService
) {
  if (user.isFromScan) {
    return service === user.idp?.service;
  }
  return false;
}

gql`
  query getAccessUsers {
    organization {
      id
      users(includeRemovedUsers: true) {
        id
        displayName
        email
        familyName
        givenName
        imageUrl
        isActive
        isContractor
        isFromScan
        isNotHuman
        hrUser {
          jobTitle
        }
        idp {
          creationTime
          deactivatedAt
          hasMFA
          isAdmin
          service
        }
      }
    }
  }
`;

gql`
  mutation setServiceAccountMapping($serviceAccountId: String!, $userId: ID) {
    setServiceAccountMapping(
      serviceAccountId: $serviceAccountId
      userId: $userId
    )
  }
`;
