import { OL } from "@blueprintjs/core";
import React from "react";
import styled from "styled-components";

import { DefaultLink } from "../../../../helpers/links";
import {
  InfoCalloutContainer,
  InfoCalloutHeading,
  InfoCalloutText,
} from "../common/components";

export const AWSContainerRepositoriesEmptyState: React.FC = () => (
  <InfoCalloutContainer>
    <InfoCalloutHeading>No container repositories detected</InfoCalloutHeading>
    <InfoCalloutText>
      Container vulnerabilities from ECR repositories should appear here.
    </InfoCalloutText>
    <InfoCalloutText>
      If you already use AWS ECR container image scanning, Vanta may be unable
      to fetch the vulnerability information due to insufficient permissions. To
      enable Vanta to fetch these findings from ECR:
      <OL>
        <li>
          Navigate to the{" "}
          <DefaultLink href="https://console.aws.amazon.com/iam/home#/policies">
            IAM Policies
          </DefaultLink>{" "}
          page in the AWS console.
        </li>
        <li>
          Search for the <code>VantaAdditionalPermissions</code> IAM policy
          created during AWS credential linking.
        </li>
        <li>
          Click on <strong>Edit policy</strong>.
        </li>
        <li>
          Add the following permissions
          <PermissionsContainer>
            <pre>
              {`ecr:DescribeImages
ecr:DescribeImageScanFindings
ecr:ListTagsForResource`}
            </pre>
          </PermissionsContainer>
        </li>
        <li>
          Click <strong>Review policy</strong> and <strong>Save changes</strong>
        </li>
      </OL>
    </InfoCalloutText>
    <InfoCalloutText>
      Within an hour of updating the policy, ECR repositories should start being
      displayed on this page.
    </InfoCalloutText>
  </InfoCalloutContainer>
);

const PermissionsContainer = styled.div`
  margin-left: 12px;
`;
