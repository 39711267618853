import { Button, Classes, Intent } from "@blueprintjs/core";
import type { DateRange } from "@blueprintjs/datetime";
import { DateRangePicker } from "@blueprintjs/datetime";
import { isSome } from "common/base/types/maybe";
import moment from "moment";
import React, { useState } from "react";

import { LogError } from "../../../errors";
import {
  GetAuditInfoDocument,
  useChangeAuditDatesMutation,
} from "../../../gen/components";
import { AppToaster } from "../../../helpers/toaster";
import {
  MAX_AUDIT_DATE,
  MIN_AUDIT_DATE,
  stringForDateRange,
  WideDialog,
} from "./audit-scheduler-dialog";

interface IProps {
  auditId: string;
  auditStartDate: Date;
  auditLength: number;
  onClose(): void;
}

export const ChangeAuditDateDialog: React.FC<IProps> = ({
  auditId,
  auditLength,
  auditStartDate,
  onClose,
}) => {
  const [changeAuditDates, mutationResult] = useChangeAuditDatesMutation({
    refetchQueries: [{ query: GetAuditInfoDocument }],
    onCompleted() {
      AppToaster.show({
        message: "Audit dates changed",
        intent: Intent.SUCCESS,
      });
      onClose();
    },
    onError() {
      AppToaster.show({
        message: `We could not change audit dates. Please contact audits@vanta.com`,
        intent: Intent.DANGER,
      });
      onClose();
    },
  });
  const [dateRange, setDateRange] = useState<DateRange>([
    auditStartDate,
    moment(auditStartDate).add(auditLength, "days").toDate(),
  ]);

  return (
    <WideDialog
      isOpen={true}
      title="Change your audit period"
      onClose={onClose}
    >
      <div className={Classes.DIALOG_BODY}>
        <p>{stringForDateRange(dateRange)}</p>
        <DateRangePicker
          value={dateRange}
          onChange={setDateRange}
          maxDate={MAX_AUDIT_DATE}
          minDate={MIN_AUDIT_DATE}
          shortcuts={false}
        />
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={() => onClose()}>Cancel</Button>
          <Button
            disabled={!dateRange.every(isSome)}
            intent={Intent.PRIMARY}
            loading={mutationResult.called}
            onClick={() => {
              const [startDate, endDate] = dateRange;
              if (!isSome(startDate) || !isSome(endDate)) {
                return;
              }
              const auditLengthDays = Math.floor(
                moment
                  .duration(
                    endDate.valueOf() - startDate.valueOf(),
                    "millisecond"
                  )
                  .asDays()
              );
              changeAuditDates({
                variables: {
                  auditId,
                  auditStart: startDate.valueOf(),
                  auditLengthDays,
                },
              }).catch(LogError);
            }}
          >
            Change Dates
          </Button>
        </div>
      </div>
    </WideDialog>
  );
};
