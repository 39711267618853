import { InputGroup } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import React, { useState } from "react";
import styled, { keyframes } from "styled-components";

import { H2, MetaText, TextLinkLike } from "../../../alpaca/components";
import { DeletableTag } from "./deletable-tag";

interface IProps {
  textContent: React.ReactNode;
  placeholder: string;
  values: string[];
  optOutTextContent?: Maybe<React.ReactNode>;
  onOptOut?: Maybe<() => void>;
  onValueAdded: (value: string) => void;
  onValueRemoved: (value: string) => void;
}

export const MultiTextInputPanel: React.FC<IProps> = ({
  textContent,
  placeholder,
  values,
  onValueAdded,
  onValueRemoved,
  optOutTextContent,
  onOptOut,
}) => {
  const [inputValue, setInputValue] = useState("");

  const taglist = (
    <TagListContainer>
      {values.map((value, index) => (
        <DeletableTag
          key={`${value}_${index}`}
          text={value}
          onDelete={() => {
            onValueRemoved(value);
          }}
        ></DeletableTag>
      ))}
    </TagListContainer>
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const trimmedAnswers = inputValue.trim();
    if (trimmedAnswers === "") {
      return;
    }
    onValueAdded(trimmedAnswers);
    setInputValue("");
  };

  const maybeOptOut =
    values.length === 0 && isSome(optOutTextContent) && isSome(onOptOut) ? (
      <TextLinkLike as="div" onClick={onOptOut}>
        {optOutTextContent}
      </TextLinkLike>
    ) : null;

  return (
    <div>
      <H2>{textContent}</H2>
      <form onSubmit={handleSubmit}>
        <InputGroup
          type="text"
          autoFocus
          placeholder={placeholder}
          value={inputValue}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setInputValue(e.target.value)
          }
        />
        {hiddenSubmitButton}
      </form>
      {values.length === 0 ? (
        <FadeInText as="p">Start typing, press return to save</FadeInText>
      ) : null}
      {taglist}
      {maybeOptOut}
    </div>
  );
};

const hiddenSubmitButton = <button type="submit" style={{ display: "none" }} />;

const styles = {
  TAG_MARGIN: 8,
};

const fadein = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const FadeInText = styled(MetaText)`
  animation: ${fadein} 0.5s;
`;

const TagListContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  & > * {
    margin-bottom: ${styles.TAG_MARGIN}px;
    margin-right: ${styles.TAG_MARGIN}px;
  }
`;
