import { Intent, MenuItem } from "@blueprintjs/core";
import { IconNames as BPIconNames } from "@blueprintjs/icons";
import { toPossibleDate } from "common/base/dateUtils";
import type { HR_SERVICES } from "common/base/types/helpers";
import {
  PERSONNEL_PAGE_TAB_IDS,
  VIEWING_HR_DIALOG_SEARCH_PARAM,
} from "common/constants/hr";
import gql from "graphql-tag";
import React, { useState } from "react";
import { useHistory } from "react-router";

import { Banner, IconNames } from "../../alpaca/components";
import { LogError } from "../../errors";
import { useHrCredentialCardLazyQuery } from "../../gen/components";
import { ConfigureHrDateFilter } from "../pages/credential/post-auth-forms/configure-hr-date-filter";
import type { Credential } from "./credential-card";
import { CredentialCard } from "./credential-card";
import type { ServiceDetails } from "./service-groups";

export const HrCredentialCard: React.FC<{
  credential: Credential;
  service: ServiceDetails;
}> = ({ credential, service }) => {
  const history = useHistory();
  const [fetch, { data, error }] = useHrCredentialCardLazyQuery({
    fetchPolicy: "no-cache",
    variables: {
      service: service.id,
    },
  });

  const [editCutoffDateDialogOpen, setEditCutoffDateDialogOpen] =
    useState(false);

  if (error) {
    LogError(error);
    return null;
  }

  const endDateCutoff = toPossibleDate(
    JSON.parse(credential.metadata ?? "{}")?.endDateCutoff
  );

  const numUnlinkedHrUsers = data?.organization.numUnlinkedHrUsers ?? 0;

  return (
    <>
      <CredentialCard
        calloutSection={
          numUnlinkedHrUsers > 0 ? (
            <Banner
              icon={IconNames.CAUTION}
              intent={Intent.WARNING}
              title={`${numUnlinkedHrUsers} ${
                numUnlinkedHrUsers > 1 ? "people" : "person"
              } found in your HR system could not be matched to accounts in Vanta`}
              description="Either manually connect them to existing accounts in Vanta, create a new Vanta account for them, or do not monitor them by marking them as 'out of scope'"
              buttonProps={{
                onClick: () => {
                  history.push(
                    `/people?tab=${PERSONNEL_PAGE_TAB_IDS.EVERYBODY}&${VIEWING_HR_DIALOG_SEARCH_PARAM}=1`
                  );
                },
                text: "View",
              }}
            />
          ) : null
        }
        credential={credential}
        customEditMenuItemText="Reconnect"
        customMenuItems={
          <MenuItem
            icon={BPIconNames.CALENDAR}
            onClick={() => setEditCutoffDateDialogOpen(true)}
            text="Edit Cutoff Date"
          />
        }
        onConfigureScopesDialogClosed={() => fetch()}
        onFullFetchProgressDetected={() => fetch()}
        service={service}
      />
      <ConfigureHrDateFilter
        endDateCutoff={endDateCutoff}
        initialLinking={false}
        isOpen={editCutoffDateDialogOpen}
        onClose={() => setEditCutoffDateDialogOpen(false)}
        service={service.id as typeof HR_SERVICES[number]}
      />
    </>
  );
};

gql`
  query HrCredentialCard($service: String!) {
    organization {
      id
      numUnlinkedHrUsers(service: $service)
    }
  }
`;
