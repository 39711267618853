import { gql } from "@apollo/client";
import type { Maybe } from "common/base/types/maybe";
import { getAllVantaTasks } from "common/employee-tasks/employee-tasks-utils";
import React from "react";

import type {
  GetChecklistPageInfoQuery,
  UpdateOrCreateRoleTaskMutationVariables,
} from "../../../gen/components";

export const CHECKLIST_PAGE_ROOT_PATH = "/checklists";

export type ChecklistPageRole = NonNullable<
  GetChecklistPageInfoQuery["organization"]
>["roles"][number];

export type ChecklistPageTask = NonNullable<
  GetChecklistPageInfoQuery["organization"]
>["roles"][number]["adminTasks"][number];

export type UpdateRoleTaskVars = Omit<
  UpdateOrCreateRoleTaskMutationVariables,
  "roleId" | "isAdminTask"
>;

type DomainSecurityRequirements = NonNullable<
  GetChecklistPageInfoQuery["organization"]
>["defaultSecurityRequirements"];

export const RolesContext = React.createContext<{
  roles: ChecklistPageRole[];
  defaultSecurityRequirements: DomainSecurityRequirements;
  defaultNecessaryPolicies?: Maybe<string[]>;
}>({
  defaultNecessaryPolicies: null,
  defaultSecurityRequirements: getAllVantaTasks(),
  roles: [],
});

gql`
  query getChecklistPageInfo {
    organization {
      id
      defaultSecurityRequirements {
        ...SecurityRequirementsMap
      }
      defaultNecessaryPolicies
      roles {
        id
        name
        description
        adminTasks {
          id
          ...roleTaskFields
        }
        employeeTasks {
          id
          ...roleTaskFields
        }
        securityRequirements {
          ...SecurityRequirementsMap
        }
        necessaryPolicies
      }
    }
  }
  fragment roleTaskFields on roleTask {
    id
    requiredInfo
    title
    type
    fileInputLabel
    instructions
    textInputLabel
  }
`;

gql`
  mutation updateOrCreateRoleTask(
    $fileInputLabel: String
    $textInputLabel: String
    $instructions: String
    $roleId: String!
    $title: String!
    $taskIdToReplace: String
    $isAdminTask: Boolean!
  ) {
    addTaskToRole(
      fileInputLabel: $fileInputLabel
      textInputLabel: $textInputLabel
      instructions: $instructions
      roleId: $roleId
      title: $title
      taskIdToReplace: $taskIdToReplace
      isAdminTask: $isAdminTask
    ) {
      id
      ...roleTaskFields
    }
  }
`;

gql`
  mutation deleteRoleTask($roleId: String!, $taskId: String!) {
    removeTaskFromRole(roleId: $roleId, taskId: $taskId) {
      id
      ...roleTaskFields
    }
  }
`;

gql`
  mutation setAdminTaskOrder($roleId: String!, $taskIds: [String!]!) {
    setAdminTaskIdsForRole(roleId: $roleId, adminTaskIds: $taskIds) {
      id
    }
  }
  mutation setEmployeeTaskOrder($roleId: String!, $taskIds: [String!]!) {
    setEmployeeTaskIdsForRole(roleId: $roleId, employeeTaskIds: $taskIds) {
      id
    }
  }
`;

gql`
  mutation setRoleSecurityRequirements(
    $roleId: String!
    $securityRequirements: roleSecurityRequirementsInput!
    $necessaryPolicies: [String!]
  ) {
    setRoleSecurityRequirements(
      roleId: $roleId
      securityRequirements: $securityRequirements
      necessaryPolicies: $necessaryPolicies
    ) {
      id
    }
  }
`;
