import { Classes } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import React from "react";

import type { GetUploadedDocumentQuery } from "../../gen/components";
import { VantaLogo } from "./components/vanta-logo";
import { NotFound404 } from "./notfound404";

interface IProps {
  document?: Maybe<GetUploadedDocumentQuery["uploadedDocument"]>;
}

export const UploadedDocComponent: React.FC<IProps> = ({ document }) => {
  if (!isSome(document)) {
    return <NotFound404 />;
  }

  return (
    <div className="uploaded-document-container">
      <iframe className="uploaded-document" src={`/doc?s=${document.slugId}`} />
      <div id="footer">
        <div>
          {document.title} from {document.domainDisplayName}
        </div>
        <div>
          <span className={`${Classes.TEXT_MUTED}`}>
            <small>Document shared from </small>
          </span>
          <VantaLogo height="12px" />
        </div>
      </div>
    </div>
  );
};
