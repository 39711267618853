import type { SpecificAzureMetricAlertRuleResource } from "common/base/types/gen";
import gql from "graphql-tag";

import { booleanToEvaluationIcon } from "../helpers";

export const azureDataTableParameters = {
  columnOrder: [
    "subscriptionId",
    "name",
    "description",
    "enabled",
    "targetResourceType",
    "metricNames",
    "evaluationFrequency",
    "windowSize",
  ],
  displayName: "Azure Monitors",
  header: {
    subscriptionId: "Subscription ID",
    name: "Name",
    description: "Description",
    enabled: "Enabled",
    targetResourceType: "Target Resource",
    metricNames: "Metric Names",
    evaluationFrequency: "Evaluation Frequency",
    windowSize: "Window Size",
  },
  createRow: (monitor: Partial<SpecificAzureMetricAlertRuleResource>) => {
    return {
      subscriptionId: monitor.subscriptionId,
      name: monitor.name,
      description: monitor.description,
      enabled: booleanToEvaluationIcon(monitor.enabled),
      targetResourceType: monitor.targetResourceType,
      metricNames: monitor.criteria?.map(c => c.metricName).join(", "),
      evaluationFrequency: monitor.evaluationFrequency,
      windowSize: monitor.windowSize,
    };
  },
};

gql`
  query fetchAzureMonitorInfo {
    organization {
      id
      displayName
      alarms: resources(
        first: 1000
        specificResourceType: AzureMetricAlertRule
      ) {
        totalCount
        edges {
          node {
            id
            ... on SpecificAzureMetricAlertRuleResource {
              name
              description
              enabled
              evaluationFrequency
              subscriptionId
              targetResourceType
              windowSize
              criteria {
                metricName
              }
            }
          }
        }
      }
    }
  }
`;
