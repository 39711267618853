import { Tab } from "@blueprintjs/core";
import { isSome } from "common/base/types/maybe";
import React from "react";
import { useHistory } from "react-router";

import { DefaultView, HeaderlessTabs } from "../../../alpaca/components";
import { LogError, LogErrorMessage } from "../../../errors";
import { useFetchDomainEndpointsQuery } from "../../../gen/components";
import { FullPageSpinner } from "../../helpers/FullPageSpinner";
import { AllComputersView } from "./all-computers-view";
import { DebuggingInfo } from "./debugging-info";
import { UsersWithoutComputersView } from "./users-without-computers-view";
import { COMPUTERS_PAGE_TAB_IDS, getEndpointList } from "./utils";

export const ComputersPage: React.FC = () => {
  const history = useHistory();
  const { error, loading, data } = useFetchDomainEndpointsQuery();

  const searchParams = new URLSearchParams(window.location.search);
  const selectedTab =
    searchParams.get("tab") ?? COMPUTERS_PAGE_TAB_IDS.MONITORED;

  if (error) {
    LogError(error);
    return null;
  }

  if (loading) {
    return <FullPageSpinner />;
  }

  if (!isSome(data)) {
    LogErrorMessage("Bad Fetch");
    return null;
  }

  const workstations = getEndpointList(data.organization);
  const userIdsFromWorkstations = new Set(workstations.map(w => w.user.id));
  const usersWithoutWorkstations = data.organization.users.filter(
    u =>
      u.securityRequirements.mustInstallLaptopMonitoring &&
      u.isActive &&
      !u.isNotHuman &&
      !userIdsFromWorkstations.has(u.id)
  );

  return (
    <DefaultView
      altMinWidth={1408}
      headerProps={{
        title: "Computers",
        tabProps: {
          id: "cp-tabs",
          tabIds: [
            {
              id: COMPUTERS_PAGE_TAB_IDS.MONITORED,
              title: "Monitored",
            },
            {
              id: COMPUTERS_PAGE_TAB_IDS.UNMONITORED,
              title: "Unmonitored",
            },
          ],
          selectedTabId: selectedTab,
          onChange: newId => {
            history.push(`${window.location.pathname}?tab=${String(newId)}`);
          },
        },
      }}
    >
      <HeaderlessTabs
        id="computers-tabs"
        selectedTabId={selectedTab}
        renderActiveTabPanelOnly={true}
      >
        <Tab
          id={COMPUTERS_PAGE_TAB_IDS.MONITORED}
          title="Monitored"
          panel={
            <AllComputersView
              domainId={data.organization.id}
              workstations={workstations}
            />
          }
        />
        <Tab
          id={COMPUTERS_PAGE_TAB_IDS.UNMONITORED}
          title="Unmonitored"
          panel={
            <UsersWithoutComputersView
              usersWithoutWorkstations={usersWithoutWorkstations}
            />
          }
        />
      </HeaderlessTabs>
      <DebuggingInfo />
    </DefaultView>
  );
};
