import { Intent, ProgressBar as BPProgressBar } from "@blueprintjs/core";
import React from "react";
import styled from "styled-components";

import { BASE_PALETTE } from "../../../alpaca/base/colors";
import { BodyText, Tooltip } from "../../../alpaca/components";

interface IProps {
  numTotal: number;
  numSatisfied: number;
  noTotalText: string;
}

export const ProgressBar: React.FC<IProps> = ({
  numTotal,
  numSatisfied,
  noTotalText,
}) => {
  const content = (
    <ProgressBarContainer width={96} height={5}>
      <BPProgressBar
        animate={false}
        stripes={false}
        intent={Intent.SUCCESS}
        value={numTotal === 0 ? 0 : numSatisfied / numTotal}
      />
      <BodyText
        as="div"
        style={{
          marginLeft: 6,
          color: numTotal === 0 ? BASE_PALETTE.SMOKE : BASE_PALETTE.INK,
        }}
      >
        {numSatisfied}/{numTotal}
      </BodyText>
    </ProgressBarContainer>
  );
  if (numTotal === 0) {
    return (
      <Tooltip
        large={false}
        content={
          <BodyText as="span" style={{ color: BASE_PALETTE.FOG }}>
            {noTotalText}
          </BodyText>
        }
        placement="top"
      >
        {content}
      </Tooltip>
    );
  } else {
    return content;
  }
};

export const ProgressBarContainer = styled.div<{
  width: number;
  height: number;
}>`
  display: flex;
  align-items: center;
  .bp3-progress-bar {
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    &.bp3-intent-success > .bp3-progress-meter {
      background-color: ${BASE_PALETTE.KALE};
    }
  }
`;
