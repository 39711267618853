import { isSome } from "common/base/types/maybe";
import type {
  IRiskCategory,
  IRiskItem,
} from "common/schemas/riskRegister/risk-register-content";
import React, { useMemo } from "react";
import { Redirect, useHistory } from "react-router";
import styled from "styled-components";

import { VANTA_COLORS } from "../../../alpaca/base/colors";
import { Breadcrumbs } from "../../helpers/breadcrumbs";
import { MasterDetailView } from "../../master-detail-view/master-detail-view";
import { WizardFooter } from "../../master-detail-view/wizard-footer";
import type { RiskDismissal, RiskMitigationTask } from "./queries-and-types";
import { RiskCategoryNavList } from "./risk-category-nav-list";
import { RiskFormDetail } from "./risk-form-detail";
import { RiskFormLocation } from "./risk-form-location";
import { RISK_REGISTER_URL } from "./risk-register-home";

interface IProps {
  category: IRiskCategory;
  dismissals: RiskDismissal[];
  items: IRiskItem[];
  tasks: RiskMitigationTask[];
}

export const RiskCategoryForm: React.FC<IProps> = ({
  category,
  dismissals,
  items,
  tasks,
}) => {
  const history = useHistory();

  const formLocation = useMemo(() => {
    try {
      return new RiskFormLocation({
        urlSearchString: window.location.search,
        history,
        category,
        items,
        dismissals,
        tasks,
      });
    } catch (e) {
      return null;
    }
  }, [window.location.search, category, items, dismissals, tasks]);
  if (!isSome(formLocation)) {
    return <Redirect to={RiskFormLocation.urlForFormStart()} />;
  }

  const currentRisk = category.risks[formLocation.getStepIndex()];
  const currentRiskItems = isSome(currentRisk)
    ? items.filter(item => item.riskId === currentRisk.id)
    : [];

  return (
    <Container>
      <Breadcrumbs
        breadcrumbs={[
          {
            text: "Risk register",
            route: RISK_REGISTER_URL,
          },
          {
            text: category.breadcrumbText,
            route: `${RISK_REGISTER_URL}/${category.url}`,
          },
        ]}
      />

      <FormContainer>
        <MasterDetailView
          masterElement={
            <MasterContainer>
              <RiskCategoryNavList
                category={category}
                dismissals={dismissals}
                tasks={tasks}
                riskItems={items}
                formLocation={formLocation}
              />
            </MasterContainer>
          }
          detailElement={
            <DetailContainer>
              <RiskFormDetail
                category={category}
                dismissals={dismissals}
                currentRiskItems={currentRiskItems}
                tasks={tasks}
                formLocation={formLocation}
              />
            </DetailContainer>
          }
          footerElement={
            <WizardFooter
              onClickSaveAndClose={() => formLocation.exitForm()}
              onClickNext={() => formLocation.gotoNextStep()}
              nextIsDisabled={!formLocation.canProceed()}
              disabledTooltipText={formLocation.tooltipText}
            ></WizardFooter>
          }
        />
      </FormContainer>
    </Container>
  );
};

const styles = {
  DETAIL_WIDTH: 500,
  FORM_TOP_PADDING: 92,
  FORM_BOTTOM_PADDING: 24,
  FORM_LEFT_PADDING: 12,
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  word-break: break-word;
  & > *:last-child {
    flex-grow: 1;
  }
  width: 100%;
`;

const FormContainer = styled.div`
  background-color: ${VANTA_COLORS.BACKGROUND_WHITE};
`;

const MasterContainer = styled.div`
  padding-top: ${styles.FORM_TOP_PADDING}px;
  padding-bottom: ${styles.FORM_BOTTOM_PADDING}px;
`;

const DetailContainer = styled.div`
  padding-top: ${styles.FORM_TOP_PADDING}px;
  padding-bottom: ${styles.FORM_BOTTOM_PADDING}px;
  padding-left: ${styles.FORM_LEFT_PADDING}px;
  width: ${styles.DETAIL_WIDTH}px;
`;
