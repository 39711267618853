import { Dialog } from "@blueprintjs/core";
import type {
  SpecificAwsRouteTableResource,
  SpecificAwsSubnetResource,
} from "common/base/types/gen";
import { TestOutcome } from "common/base/types/gen";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import React from "react";
import styled from "styled-components";

import { EvaluationIcon } from "../../../helpers/EvaluationIcon";
import { DataTable } from "../../components/data-table";

const COLUMN_ORDER_ROUTE_TABLE = ["destination", "target"];
const HEADERS_ROUTE_TABLE = { destination: "Destination", target: "Target" };

type SubnetWithRouteTable = Partial<SpecificAwsSubnetResource> & {
  routeTable?: Maybe<Partial<SpecificAwsRouteTableResource>>;
};

export const awsDataTableParameters = {
  columnOrder: [
    "uniqueId",
    "name",
    "state",
    "vpcId",
    "routeTable",
    "mapPublicIpOnLaunch",
  ],
  displayName: "AWS Subnets",
  header: {
    uniqueId: "ARN",
    name: "Subnet ID",
    state: "State",
    vpcId: "VPC",
    routeTable: "Route table",
    mapPublicIpOnLaunch: "Auto-assign public IPv4 address",
  },
  createRow: (subnet: SubnetWithRouteTable) => {
    return {
      uniqueId: subnet.uniqueId,
      name: subnet.name,
      state: subnet.state,
      vpcId: subnet.vpcId,
      routeTable: isSome(subnet.routeTable) ? (
        <RouteTableDialogSpan routeTable={subnet.routeTable} />
      ) : (
        "No route table for this subnet"
      ),
      mapPublicIpOnLaunch: subnet.mapPublicIpOnLaunch ? (
        <EvaluationIcon evaluation={TestOutcome.PASS} />
      ) : (
        <EvaluationIcon evaluation={TestOutcome.FAIL} />
      ),
    };
  },
};

type DomainWithAwsData = {
  subnets: {
    edges: Array<{ node: Partial<SpecificAwsSubnetResource> }>;
  };
  routeTables: {
    edges: Array<{ node: Partial<SpecificAwsRouteTableResource> }>;
  };
};

export const processAwsData = (
  domain: DomainWithAwsData
): SubnetWithRouteTable[] => {
  const subnets = domain.subnets.edges.map(e => e.node);
  const routeTables = domain.routeTables.edges.map(e => e.node);

  return subnets.map(s => {
    const subnetName = s.name;
    if (isSome(subnetName)) {
      return {
        ...s,
        routeTable:
          routeTables.find(rt => rt.subnetIds?.includes(subnetName)) ??
          // Fall back to the VPC's default route table.
          routeTables.find(rt => rt.vpcId === s.vpcId),
      };
    }
    return s;
  });
};

interface IRouteTableProps {
  routeTable: Partial<SpecificAwsRouteTableResource>;
}

const RouteTableDialogSpan: React.FC<IRouteTableProps> = ({ routeTable }) => {
  const [isOpen, setOpen] = React.useState(false);
  return (
    <React.Fragment>
      <DialogSpan onClick={() => setOpen(true)}> {routeTable.id} </DialogSpan>
      <Dialog
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        title={`Route table: ${routeTable.id}`}
      >
        <RouteTable routeTable={routeTable} />
      </Dialog>
    </React.Fragment>
  );
};
const RouteTable: React.FC<IRouteTableProps> = ({ routeTable }) => {
  const getRowDataForRouteTable = (
    route: SpecificAwsRouteTableResource["routes"][number]
  ) => {
    return {
      destination: route.destinationId,
      target: route.targetId,
    };
  };
  if (!isSome(routeTable.routes)) {
    return <span>"No routes for this route table"</span>;
  }

  return (
    <DataTable
      createRow={getRowDataForRouteTable}
      columnOrder={COLUMN_ORDER_ROUTE_TABLE}
      header={HEADERS_ROUTE_TABLE}
      data={routeTable.routes}
      emptyDefault={"No data available"}
    />
  );
};

const DialogSpan = styled.span`
  text-decoration: underline;
  :hover {
    cursor: pointer;
  }
`;

gql`
  query fetchAwsSubnetInfo {
    organization {
      id
      displayName
      # 07/27/2021 The first: 1000 below is an arbitrarily high number so that we load all the resources at once.
      # We should modify this query (and the others) to eventually be paginated, but we're holding off on doing this for now.
      # See https://app.shortcut.com/vanta/story/33413
      subnets: resources(first: 1000, specificResourceType: AwsSubnet) {
        totalCount
        edges {
          node {
            id
            ... on SpecificAwsSubnetResource {
              availabilityZone
              state
              name
              uniqueId
              mapPublicIpOnLaunch
            }
          }
        }
      }
      routeTables: resources(first: 1000, specificResourceType: AwsRouteTable) {
        totalCount
        edges {
          node {
            id
            ... on SpecificAwsRouteTableResource {
              vpcId
              name
              subnetIds
              routes {
                destinationId
                targetId
              }
            }
          }
        }
      }
    }
  }
`;
