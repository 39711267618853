import "./manage-vendors.scss";

import { ButtonGroup, Callout, Divider, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import type { Maybe } from "common/base/types/maybe";
import { isSome, nothing } from "common/base/types/maybe";
import gql from "graphql-tag";
import React from "react";
import { Link } from "react-router-dom";

import { Button, ContentCard, DefaultView } from "../../../alpaca/components";
import { LogError, LogErrorMessage } from "../../../errors";
import type {
  AddVendorMutationFn,
  GetVendorsQuery,
  RemoveVendorMutationFn,
  UpdateVendorMutationFn,
} from "../../../gen/components";
import {
  GetVendorsDocument,
  useAddVendorMutation,
  useGetVendorsQuery,
  useRemoveVendorMutation,
  useUpdateVendorMutation,
} from "../../../gen/components";
import { AppToaster } from "../../../helpers/toaster";
import { FullPageSpinner } from "../../helpers/FullPageSpinner";
import { TableSearchInput } from "../components/table-controls";
import { AddVendorDialog } from "./add-vendor-dialog";
import { CollapsibleVendorRow } from "./collapsible-vendor-row";

const RISK_LEVELS: { [k: string]: number } = { high: 0, medium: 1, low: 2 };

export type VendorPageVendor = NonNullable<
  GetVendorsQuery["organization"]
>["vendors"][number];

interface IProps {
  domain: NonNullable<GetVendorsQuery["organization"]>;
  vendors: NonNullable<GetVendorsQuery["organization"]>["vendors"];
  addVendor: AddVendorMutationFn;
  removeVendor: RemoveVendorMutationFn;
  updateVendor: UpdateVendorMutationFn;
}

interface IState {
  addVendorDialogOpen: boolean;
  addingNewVendor: boolean;
  newlyAddedVendorId?: Maybe<string>;
  searchText: string;
  vendorIdToDelete?: Maybe<string>;
  orderByRiskLevel: boolean;
}

const toaster = AppToaster;

class ManageVendorsComponent extends React.Component<IProps, IState> {
  public newlyAddedVendor: Maybe<HTMLElement>;
  public newVendorRef: (e: any) => void;
  public constructor(props: IProps) {
    super(props);

    this.state = {
      addVendorDialogOpen: false,
      addingNewVendor: false,
      searchText: "",
      vendorIdToDelete: nothing,
      orderByRiskLevel: true,
    };

    this.newlyAddedVendor = nothing;
    this.newVendorRef = element => {
      this.newlyAddedVendor = element;
    };

    this.openAddVendorDialog = this.openAddVendorDialog.bind(this);
    this.closeAddVendorDialog = this.closeAddVendorDialog.bind(this);
    this.onAddVendor = this.onAddVendor.bind(this);
    this.onUpdate = this.onUpdate.bind(this);
    this.setOrderByName = this.setOrderByName.bind(this);
    this.setOrderByRisk = this.setOrderByRisk.bind(this);
  }

  public componentDidUpdate(prevProps: IProps, prevState: IState) {
    const vendors = this.props.vendors;
    const prevVendors = prevProps.vendors;
    if (vendors.length === prevVendors.length + 1) {
      this.setState({ addingNewVendor: false });
    }

    if (!this.state.addingNewVendor && prevState.addingNewVendor) {
      if (isSome(this.newlyAddedVendor)) {
        this.newlyAddedVendor.scrollIntoView({
          block: "start",
          inline: "nearest",
          behavior: "smooth",
        });
        toaster.show({
          icon: "tick",
          intent: Intent.SUCCESS,
          message: "New vendor added!",
          timeout: 2500,
        });
      }
    }
  }

  public setOrderByRisk() {
    this.setState({ orderByRiskLevel: true });
  }

  public setOrderByName() {
    this.setState({ orderByRiskLevel: false });
  }

  public openAddVendorDialog() {
    this.setState({
      addVendorDialogOpen: true,
    });
  }

  public closeAddVendorDialog() {
    this.setState({
      addVendorDialogOpen: false,
    });
  }

  public onAddVendor(name: string, url: string) {
    this.props
      .addVendor({ variables: { name, url } })
      .then(result => {
        if (isSome(result.data) && isSome(result.data.addVendor)) {
          this.setState({
            newlyAddedVendorId: result.data.addVendor.id,
          });
        }
      })
      .catch(LogError);
    this.setState({
      addingNewVendor: true,
      searchText: "",
    });

    this.closeAddVendorDialog();
  }

  public onUpdate(params: any) {
    this.props.updateVendor({ variables: params }).catch(LogError);
  }

  public onDelete(vendorId: string) {
    this.props.removeVendor({ variables: { vendorId } }).catch(LogError);
  }

  public render() {
    const users = isSome(this.props.domain) ? this.props.domain.users : [];
    const { vendors } = this.props;

    const maybeNoVendors =
      vendors.length === 0 ? (
        <Callout className="vendor-list-callout" title="Add a vendor">
          <p>
            No vendors have been added. Add a vendor by clicking the above
            button or by linking a service account on the{" "}
            <Link to={"/connections"}>Connections Page.</Link>{" "}
          </p>
        </Callout>
      ) : (
        nothing
      );

    const filteredVendors = vendors.filter(
      v => v.name.search(new RegExp(this.state.searchText, "i")) > -1
    );

    const sortedVendors = filteredVendors.sort((v1, v2) => {
      if (this.state.orderByRiskLevel) {
        const severityCompare =
          RISK_LEVELS[v1.severity] - RISK_LEVELS[v2.severity];
        if (severityCompare !== 0) {
          return severityCompare;
        }
      }
      return v1.name.localeCompare(v2.name);
    });

    const maybeNoResults =
      !isSome(maybeNoVendors) &&
      this.state.searchText !== "" &&
      filteredVendors.length === 0 ? (
        <Callout className="vendor-list-callout" title="No Matches">
          <p>
            No vendors found whose name matches {`"${this.state.searchText}"`}
          </p>
        </Callout>
      ) : (
        nothing
      );

    const orderbyMenu = (
      <div className="manage-vender-filter-widget" key="order-by">
        <span>Order by:</span>
        <ButtonGroup vertical={false}>
          <Button
            minimal
            className={
              this.state.orderByRiskLevel
                ? "vendor-list-order-highlight"
                : "vendor-list-order-no-highlight span"
            }
            onClick={this.setOrderByRisk}
          >
            Risk Level
          </Button>
          <Divider />
          <Button
            minimal
            className={
              !this.state.orderByRiskLevel
                ? "vendor-list-order-highlight"
                : "vendor-list-order-no-highlight span"
            }
            onClick={this.setOrderByName}
          >
            Name
          </Button>
        </ButtonGroup>
      </div>
    );

    const addVendorDialog = (
      <AddVendorDialog
        isOpen={this.state.addVendorDialogOpen}
        onCloseDialog={this.closeAddVendorDialog}
        onAddVendor={this.onAddVendor}
      />
    );

    const collapsibleList = sortedVendors.map(vendor => (
      <div
        key={vendor.id}
        ref={
          vendor.id === this.state.newlyAddedVendorId
            ? this.newVendorRef
            : undefined
        }
      >
        <CollapsibleVendorRow
          startOpen={vendor.id === this.state.newlyAddedVendorId}
          vendor={vendor}
          users={users}
          onUpdate={this.onUpdate}
          onDelete={() => {
            this.onDelete(vendor.id);
          }}
        />
      </div>
    ));

    return (
      <DefaultView
        headerProps={{
          title: "Vendors",
          rightControls: [
            <TableSearchInput
              key="search-bar"
              placeholder="Search Vendors"
              leftIcon={IconNames.SEARCH}
              value={this.state.searchText}
              onChange={(e: any) => {
                this.setState({ searchText: e.target.value });
              }}
            />,
            orderbyMenu,
            <Button
              key="add-vendor"
              onClick={this.openAddVendorDialog}
            >{`Add a vendor`}</Button>,
          ],
        }}
      >
        {!isSome(vendors) || this.state.addingNewVendor ? (
          <FullPageSpinner />
        ) : (
          <div>
            {maybeNoVendors}
            {maybeNoResults}
            <ContentCard>{collapsibleList}</ContentCard>
            {addVendorDialog}
          </div>
        )}
      </DefaultView>
    );
  }
}

gql`
  mutation updateVendor(
    $assessmentComments: String
    $assessmentFile: Upload
    $attestationOfComplianceFile: Upload
    $authMethod: String
    $baaFile: Upload
    $contactEmail: String
    $contactName: String
    $containsUserData: Boolean
    $dataStored: String
    $domainId: ID
    $dpaFile: Upload
    $id: String!
    $name: String
    $otherInformation: String
    $ownerId: String
    $passwordMFA: Boolean
    $passwordMinLength: Int
    $passwordRequiresNumber: Boolean
    $passwordRequiresSymbol: Boolean
    $servicesProvided: String
    $url: String
    $severity: String
    $sharesEPHI: Boolean
    $sharesEUPII: Boolean
  ) {
    setVendor(
      assessmentComments: $assessmentComments
      attestationOfComplianceFile: $attestationOfComplianceFile
      assessmentFile: $assessmentFile
      authMethod: $authMethod
      baaFile: $baaFile
      dataStored: $dataStored
      domainId: $domainId
      dpaFile: $dpaFile
      contactEmail: $contactEmail
      contactName: $contactName
      containsUserData: $containsUserData
      id: $id
      name: $name
      otherInformation: $otherInformation
      ownerId: $ownerId
      passwordMFA: $passwordMFA
      passwordMinLength: $passwordMinLength
      passwordRequiresNumber: $passwordRequiresNumber
      passwordRequiresSymbol: $passwordRequiresSymbol
      servicesProvided: $servicesProvided
      severity: $severity
      sharesEPHI: $sharesEPHI
      sharesEUPII: $sharesEUPII
      url: $url
    ) {
      id
      url
    }
  }
`;

gql`
  mutation addVendor($name: String!, $url: String!) {
    addVendor(name: $name, url: $url) {
      id
    }
  }
`;

gql`
  mutation removeVendor($vendorId: String!) {
    removeVendor(vendorId: $vendorId)
  }
`;

gql`
  query getVendors {
    organization {
      users {
        id
        displayName
        imageUrl
      }
      id
      vendors {
        authMethod
        assessmentComments
        assessmentFile {
          id
          filename
          url
          createdAt
          creator {
            id
            displayName
          }
        }
        attestationOfComplianceFile {
          id
          filename
          url
          createdAt
          creator {
            id
            displayName
          }
        }
        baaFile {
          id
          filename
          url
          createdAt
          creator {
            id
            displayName
          }
        }
        dpaFile {
          id
          filename
          url
          createdAt
          creator {
            id
            displayName
          }
        }
        contactName
        contactEmail
        containsUserData
        dataStored
        id
        isAssociatedWithCredentials
        name
        otherInformation
        owner {
          id
          displayName
          imageUrl
        }
        ownerId
        passwordMFA
        passwordMinLength
        passwordRequiresNumber
        passwordRequiresSymbol
        pendingVAQExpirationDate
        servicesProvided
        severity
        sharesEPHI
        sharesEUPII
        specifiedPasswordPolicy {
          minLength
          requiresNumber
          requiresSymbol
        }
        submittedVAQs {
          id
          submittedDate
          externalAssessmentType
          complianceUpload {
            id
            filename
            url
            createdAt
          }
        }
        url
      }
    }
  }
`;

const COMMON_OPTIONS = {
  refetchQueries: [{ query: GetVendorsDocument }],
};

export const ManageVendorsPage: React.FC = () => {
  const [addVendor] = useAddVendorMutation(COMMON_OPTIONS);
  const [removeVendor] = useRemoveVendorMutation(COMMON_OPTIONS);
  const [updateVendor] = useUpdateVendorMutation(COMMON_OPTIONS);
  const { error, loading, data } = useGetVendorsQuery();
  if (error) {
    LogError(error);
    return null;
  }
  if (loading) {
    return <FullPageSpinner />;
  }
  if (!data) {
    LogErrorMessage("Bad fetch");
    return null;
  }
  return (
    <ManageVendorsComponent
      domain={data.organization}
      vendors={data.organization.vendors}
      addVendor={addVendor}
      updateVendor={updateVendor}
      removeVendor={removeVendor}
    />
  );
};
