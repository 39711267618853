import "../../yc-signup-page.scss";

import { AnchorButton, Classes, Intent } from "@blueprintjs/core";
import React from "react";

import { H2 } from "../../../../alpaca/components";
import { VantaLogo } from "../../components/vanta-logo";

export class OnboardingSignInPage extends React.Component {
  public render() {
    return (
      <div>
        <div className="app-no-container-bar">
          <div style={{ margin: "10px 0" }}>
            <VantaLogo height={"36px"} />
          </div>
          <H2>Security onboarding</H2>
        </div>
        <div className={`${Classes.RUNNING_TEXT} app-no-container`}>
          <p>
            Your company has partnered with Vanta for security and compliance
            tools.
          </p>
          <p>
            It needs your assistance to keep company and customer data secure.
            See how you can help below.
          </p>
          <p style={{ width: 135, margin: "24px auto" }}>
            <AnchorButton
              intent={Intent.PRIMARY}
              large={true}
              href="/login/onboarding"
              text="Get started &rarr;"
            />
          </p>
        </div>
      </div>
    );
  }
}
