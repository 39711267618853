/**
 * Shared components, styles, and constants used on the People page.
 */
import type { Maybe } from "common/base/types/maybe";
import styled, { css } from "styled-components";

import { BASE_PALETTE } from "../../../../alpaca/base/colors";
import { GRID_SPACING } from "../../../../alpaca/base/grid";

export const DATE_FORMAT = "MMM D, YYYY";
export const DATE_FORMAT_FULL_MONTH = "MMMM D, YYYY";
export const DATETIME_FORMAT = "MMMM D, YYYY, h:mm A";

export const IconContainer = styled.div<{ isCompleted?: Maybe<boolean> }>`
  svg {
    width: ${3 * GRID_SPACING}px;
    height: ${3 * GRID_SPACING}px;
    ${({ isCompleted }) =>
      Boolean(isCompleted)
        ? css`
            circle {
              fill: ${BASE_PALETTE.KALE};
              stroke: ${BASE_PALETTE.KALE};
            }
          `
        : ""}
  }
  margin-right: ${2 * GRID_SPACING}px;
  flex-shrink: 0;
`;

export const PEOPLE_PAGE_DEFAULT_PAGE_SIZE = 100;
