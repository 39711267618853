import { Spinner } from "@blueprintjs/core";
import {
  specificResourceGraphQLTypeToEnumValue,
  SpecificResourceToInventoryType,
} from "common/utils/inventory";
import { deserializeVantaAttributes } from "common/utils/vantaAttributes";
import gql from "graphql-tag";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import type { PaasAppsQuery } from "../../../../gen/components";
import {
  usePaasAppsQuery,
  useSetInventoryResourceVantaAttributeMutation,
} from "../../../../gen/components";
import { InventoryCard } from "../inventory-card";
import {
  fetchMoreResources,
  getDebounceContext,
  getResourceFilterParams,
  invalidInventoryType,
  PAGE_SIZE,
  setResourceVantaAttributeFn,
} from "../utils";
import { SearchResultsSummary } from "./search-results-summary";
import type { IInventoryTabProps } from "./shared-interface";

export const PaaSApps: React.FC<IInventoryTabProps> = ({ searchString }) => {
  const { data, fetchMore, loading } = usePaasAppsQuery({
    variables: {
      first: PAGE_SIZE,
      filterParams: getResourceFilterParams(searchString),
    },
    context: getDebounceContext("paas-app"),
  });

  if (loading) {
    return <Spinner />;
  }

  const cards = data?.organization.resources.edges?.map(item => (
    <PaasAppCard key={item.node.id} item={item} domain={data.organization} />
  ));

  return (
    <>
      <SearchResultsSummary
        searchString={searchString}
        numberResults={data?.organization.resources?.totalCount ?? 0}
      />
      <InfiniteScroll
        className="inventory-list-card-group"
        dataLength={data?.organization.resources.edges.length ?? 0}
        next={async () => fetchMoreResources(data, fetchMore)}
        hasMore={data?.organization.resources.pageInfo.hasNextPage ?? false}
        loader={<Spinner />}
      >
        {cards}
      </InfiniteScroll>
    </>
  );
};

const PaasAppCard: React.FC<{
  item: NonNullable<
    PaasAppsQuery["organization"]
  >["resources"]["edges"][number];
  domain: NonNullable<PaasAppsQuery["organization"]>;
}> = ({ item, domain }) => {
  const [setResourceVantaAttribute] =
    useSetInventoryResourceVantaAttributeMutation();

  if (
    item.node.__typename !== "SpecificHerokuAppResource" &&
    item.node.__typename !== "SpecificDigitalOceanAppResource"
  ) {
    return invalidInventoryType(item.node.__typename);
  }

  const uid = item.node.name;
  const specificResourceKind = specificResourceGraphQLTypeToEnumValue(
    item.node.__typename
  );
  const type = SpecificResourceToInventoryType[specificResourceKind](
    item.node as any
  );
  const resourceMongoId = item.node.id;

  const vantaAttributes = deserializeVantaAttributes(
    item.node.vantaAttributes ?? []
  );
  const setVantaAttribute = setResourceVantaAttributeFn(
    setResourceVantaAttribute,
    specificResourceKind,
    resourceMongoId,
    item.node.__typename
  );

  return (
    <InventoryCard
      type={type}
      uid={uid}
      key={item.node.id}
      name={item.node.name}
      description={vantaAttributes.description}
      owner={vantaAttributes.ownerId}
      canContainUserData={true}
      containsUserData={vantaAttributes.containsUserData}
      userDataDescription={vantaAttributes.userDataStored}
      setVantaAttribute={setVantaAttribute}
    />
  );
};

gql`
  query paasApps($first: Int!, $after: String, $filterParams: filterParams) {
    organization {
      id
      resources(
        first: $first
        after: $after
        genericResourceType: PaaSApp
        sortParams: { field: "createdAt", direction: -1 }
        filterParams: $filterParams
      ) {
        totalCount
        edges {
          node {
            id
            __typename
            uniqueId
            vantaAttributes {
              key
              value
              managedExternally
            }
            ... on SpecificDigitalOceanAppResource {
              name
            }
            ... on SpecificHerokuAppResource {
              name
            }
          }
        }
        pageInfo {
          hasNextPage
          endCursor
          startCursor
          hasPreviousPage
        }
      }
    }
  }
`;
