import "./access-management.scss";

import { Button, Card, Spinner, UL } from "@blueprintjs/core";
import { PermissionLevel } from "common/base/types/gen";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import React, { useState } from "react";

import { LogError } from "../../../errors";
import type { AllUsersForAccessQuery } from "../../../gen/components";
import {
  useAllUsersForAccessQuery,
  useSetPermissionLevelMutation,
} from "../../../gen/components";
import { UserSelector } from "../../form-controls/user-selector";
import { MaybeProfileImage } from "../../helpers/MaybeProfileImage";
import { AuditorAccessDisplay } from "../audit-scheduler/auditor-access-display";
import { AccessManagementSelector } from "./access-management-selector";

export const AccessManagement: React.FC = () => {
  const [accessForNewUsers, setAccessForNewUsers] = useState(
    PermissionLevel.Dashboard
  );
  const [userRemoved, setUserRemoved] = useState<Maybe<string>>(null);
  const [userModified, setUserModified] = useState<Maybe<string>>(null);
  const {
    loading: queryLoading,
    error: queryError,
    data,
  } = useAllUsersForAccessQuery();
  const [setPermissionLevel] = useSetPermissionLevelMutation({
    onCompleted() {
      setUserRemoved(null);
      setUserModified(null);
    },
  });

  if (queryLoading || !isSome(data)) {
    return <Spinner />;
  }
  if (isSome(queryError)) {
    LogError(queryError);
  }
  const allUsers = data.organization.users;

  const usersWithPermissions: NonNullable<
    AllUsersForAccessQuery["organization"]
  >["users"] = [];
  const usersWithoutPermissions: NonNullable<
    AllUsersForAccessQuery["organization"]
  >["users"] = [];

  for (const u of allUsers) {
    (u.permissionLevel === PermissionLevel.Onboarding
      ? usersWithoutPermissions
      : usersWithPermissions
    ).push(u);
  }

  const usersWithPermsCards = usersWithPermissions.map(u => (
    <Card key={u.id} className="user-perm-card">
      <Button
        icon="cross"
        minimal
        loading={u.id === userRemoved}
        onClick={async () => {
          setUserRemoved(u.id);
          await setPermissionLevel({
            variables: {
              userId: u.id,
              level: PermissionLevel.Onboarding,
            },
          });
        }}
      />
      <MaybeProfileImage circle={true} dimension={24} url={u.imageUrl} />
      <div className="name">{u.displayName}</div>
      <div className="email">{u.email}</div>
      <div className="selector">
        <AccessManagementSelector
          loading={userModified === u.id}
          selected={u.permissionLevel}
          onSelect={async choice => {
            setUserModified(u.id);
            await setPermissionLevel({
              variables: {
                userId: u.id,
                level: choice,
              },
            });
          }}
        />
      </div>
    </Card>
  ));

  return (
    <div className="access-management">
      <strong>Admin</strong> is the most privileged role for Vanta. Admins can:
      <UL>
        <li>Modify tests and other data within Vanta</li>
        <li>Grant and modify user access to Vanta</li>
      </UL>
      All email notifications are enabled for admins by default.
      <br></br>
      <br></br>
      <strong>Access</strong> users can modify tests and other data within
      Vanta. They cannot view sensitive data, such as background check
      information and sensitive documents, or grant/modify user access to Vanta.
      <br></br>
      <br></br>
      <Card className="user-selector">
        <div className="flex">
          <Button icon="plus" minimal style={{ pointerEvents: "none" }} />
          <UserSelector
            undeletable={true}
            onSelect={async (choice: string[]) =>
              setPermissionLevel({
                variables: {
                  userId: choice[0],
                  level: accessForNewUsers,
                },
              })
            }
            usersToExclude={usersWithPermissions.map(u => u.id)}
          />
        </div>
        <div className="selector">
          <AccessManagementSelector
            loading={false}
            selected={accessForNewUsers}
            onSelect={choice => setAccessForNewUsers(choice)}
          />
        </div>
      </Card>
      <div>{usersWithPermsCards}</div>
      <strong>Auditor</strong> users have read-only access to data in Vanta.
      They cannot view sensitive data, receive email notifications, or
      grant/modify user access to Vanta.
      <br></br>
      <br></br>
      <AuditorAccessDisplay />
    </div>
  );
};

gql`
  query AllUsersForAccess {
    organization {
      id
      users(includeRemovedUsers: false, includeNonHumanUsers: false) {
        id
        displayName
        imageUrl
        email
        permissionLevel
      }
    }
  }
`;

gql`
  mutation SetPermissionLevel($userId: ID!, $level: permissionLevel!) {
    setPermissionLevel(userId: $userId, level: $level) {
      id
      permissionLevel
    }
  }
`;
