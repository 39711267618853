import type {
  SpecificAzureCosmosDbResource,
  SpecificAzureDatabaseForExternalSqlVariantResource,
  SpecificAzureSqlDatabaseResource,
  SpecificAzureSqlManagedInstanceResource,
  SpecificAzureSqlServerOnVMsResource,
  SpecificAzureSynapseWarehouseResource,
} from "common/base/types/gen";
import gql from "graphql-tag";

import type { TableParameters } from "../helpers";
import { booleanToEvaluationIcon } from "../helpers";
import { SHARED_DATABASE_TABLE_HEADERS } from "./helpers";

export const AZURE_DBS = [
  "azureDatabases",
  "azureSQLDatabases",
  "azureSQLManagedInstances",
  "azureSQLServerOnVMs",
  "azureCosmosDBs",
  "azureSynapseWarehouses",
] as const;

export const azureDataTableParameters: { [k: string]: TableParameters } = {
  azureDatabases: {
    displayName: "Azure Databases",
    columnOrder: ["name", "backupRetentionPeriod", "databaseType"],
    header: {
      backupRetentionPeriod: SHARED_DATABASE_TABLE_HEADERS.retentionPeriod,
      databaseType: SHARED_DATABASE_TABLE_HEADERS.type,
      name: SHARED_DATABASE_TABLE_HEADERS.name,
    },
    createRow: (
      instance: Partial<SpecificAzureDatabaseForExternalSqlVariantResource>
    ) => {
      return {
        backupRetentionPeriod: instance.backupRetentionDays,
        databaseType: instance.databaseType,
        name: instance.name,
      };
    },
  },
  azureSQLDatabases: {
    displayName: "Azure SQL Databases",
    columnOrder: ["name", "backupRetentionPeriod", "encrypted"],
    header: {
      name: SHARED_DATABASE_TABLE_HEADERS.name,
      backupRetentionPeriod: SHARED_DATABASE_TABLE_HEADERS.retentionPeriod,
      encrypted: SHARED_DATABASE_TABLE_HEADERS.encrypted,
    },
    createRow: (instance: Partial<SpecificAzureSqlDatabaseResource>) => {
      return {
        backupRetentionPeriod: instance.shortTermBackupRetentionDays,
        name: instance.name,
        encrypted: booleanToEvaluationIcon(instance.encryptionEnabled),
      };
    },
  },
  azureSQLManagedInstances: {
    displayName: "Azure SQL Managed Instances",
    columnOrder: ["name", "backupRetentionPeriod"],
    header: {
      backupRetentionPeriod: SHARED_DATABASE_TABLE_HEADERS.retentionPeriod,
      name: SHARED_DATABASE_TABLE_HEADERS.name,
    },
    createRow: (instance: Partial<SpecificAzureSqlManagedInstanceResource>) => {
      return {
        backupRetentionPeriod: instance.shortTermBackupRetentionDays,
        name: instance.name,
      };
    },
  },
  azureSQLServerOnVMs: {
    displayName: "Azure SQL Server on Virtual Machines",
    columnOrder: ["name"],
    header: {
      name: SHARED_DATABASE_TABLE_HEADERS.name,
    },
    createRow: (instance: Partial<SpecificAzureSqlServerOnVMsResource>) => {
      return {
        name: instance.name,
      };
    },
  },
  azureCosmosDBs: {
    displayName: "Azure CosmosDBs",
    columnOrder: ["name", "encrypted"],
    header: {
      name: SHARED_DATABASE_TABLE_HEADERS.name,
      encrypted: SHARED_DATABASE_TABLE_HEADERS.encrypted,
    },
    createRow: (instance: Partial<SpecificAzureCosmosDbResource>) => {
      return {
        name: instance.name,
        encrypted: booleanToEvaluationIcon(true),
      };
    },
  },
  azureSynapseWarehouses: {
    displayName: "Azure Synapse Warehouses",
    columnOrder: ["name", "encrypted"],
    header: {
      name: SHARED_DATABASE_TABLE_HEADERS.name,
      encrypted: SHARED_DATABASE_TABLE_HEADERS.encrypted,
    },
    createRow: (instance: Partial<SpecificAzureSynapseWarehouseResource>) => {
      return {
        name: instance.name,
        encrypted: booleanToEvaluationIcon(instance.encryptionEnabled),
      };
    },
  },
};

gql`
  query fetchAzureDbInfo {
    organization {
      id
      displayName
      azureDatabases: resources(
        first: 1000
        specificResourceType: AzureDatabaseForExternalSQLVariant
      ) {
        totalCount
        edges {
          node {
            id
            ... on SpecificAzureDatabaseForExternalSQLVariantResource {
              backupRetentionDays
              databaseType
              name
            }
          }
        }
      }
      azureSQLDatabases: resources(
        first: 1000
        specificResourceType: AzureSQLDatabase
      ) {
        totalCount
        edges {
          node {
            id
            ... on SpecificAzureSQLDatabaseResource {
              encryptionEnabled
              name
              shortTermBackupRetentionDays
            }
          }
        }
      }
      azureSQLManagedInstances: resources(
        first: 1000
        specificResourceType: AzureSQLManagedInstance
      ) {
        totalCount
        edges {
          node {
            id
            ... on SpecificAzureSQLManagedInstanceResource {
              name
              shortTermBackupRetentionDays
            }
          }
        }
      }
      azureSQLServerOnVMs: resources(
        first: 1000
        specificResourceType: AzureSQLServerOnVMs
      ) {
        totalCount
        edges {
          node {
            id
            ... on SpecificAzureSQLServerOnVMsResource {
              name
            }
          }
        }
      }
      azureCosmosDBs: resources(
        first: 1000
        specificResourceType: AzureCosmosDB
      ) {
        totalCount
        edges {
          node {
            id
            ... on SpecificAzureCosmosDBResource {
              name
            }
          }
        }
      }
      azureSynapseWarehouses: resources(
        first: 1000
        specificResourceType: AzureSynapseWarehouse
      ) {
        totalCount
        edges {
          node {
            id
            ... on SpecificAzureSynapseWarehouseResource {
              name
              encryptionEnabled
            }
          }
        }
      }
    }
  }
`;
