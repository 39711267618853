import { Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import type { Maybe } from "common/base/types/maybe";
import React from "react";
import { CSVLink } from "react-csv";
import styled from "styled-components";

import { Button } from "../../../alpaca/components";
import { TABLE_CONTROLS_STYLES } from "./table-controls";

interface IProps {
  disabled?: Maybe<boolean>;
  data: string;
  filename: string;
  buttonText?: Maybe<string>;
}

export const CSVExportButton: React.FC<IProps> = ({
  disabled,
  data,
  filename,
  buttonText,
}) => (
  <StyledCSVLink
    onClick={() => !Boolean(disabled)}
    data={data}
    filename={filename}
    target="_blank"
  >
    <Button
      disabled={Boolean(disabled)}
      icon={
        <Icon
          icon={IconNames.DOWNLOAD}
          iconSize={TABLE_CONTROLS_STYLES.ICON_SIZE}
        />
      }
    >
      {buttonText ?? "Export"}
    </Button>
  </StyledCSVLink>
);

const StyledCSVLink = styled(CSVLink)`
  &:hover {
    text-decoration: none;
  }
`;
