import { Spinner } from "@blueprintjs/core";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import React from "react";
import styled from "styled-components";

import { LogError, LogErrorMessage } from "../../errors";
import type {
  AssumeUserMutationAuditorMutationFn,
  GetAuditingInfoQuery,
} from "../../gen/components";
import {
  useAssumeUserMutationAuditorMutation,
  useGetAuditingInfoQuery,
} from "../../gen/components";
import { DefaultLink } from "../../helpers/links";
import { FullPageSpinner } from "../helpers/FullPageSpinner";
import {
  PageHeadingInfo,
  VantaDashboardPage,
} from "../vanta-chrome/page-content/vanta-dashboard-page";
import { AuditorDocumentsView } from "./auditor-view/auditor-documents/auditor-documents-view";

interface IProps {
  user: NonNullable<GetAuditingInfoQuery["user"]>;
  assumeUser: AssumeUserMutationAuditorMutationFn;
}

const AuditorHomepageComponent: React.FC<IProps> = ({
  user,
  assumeUser: assumeUserMutation,
}) => {
  if (!isSome(user)) {
    return <Spinner />;
  }
  if (!user.auditorInfo) {
    throw new Error("No auditor info but we're on the auditor page");
  }
  const assumeUser = (email: string) => {
    assumeUserMutation({
      variables: {
        email,
      },
    })
      .then(() => location.reload())
      .catch(LogError);
  };

  const auditableDomains = user.auditableUsers.reduce((mapping, nextUser) => {
    const initial = nextUser.domain.displayName[0].toLocaleUpperCase();
    if (!isSome(mapping[initial])) {
      mapping[initial] = [];
    }
    mapping[initial].push(nextUser);
    return mapping;
  }, {} as { [k: string]: Array<NonNullable<GetAuditingInfoQuery["user"]>["auditableUsers"][number]> });

  const listMapper = (initial: string) => (
    <ListContainer key={`domain_list_${initial}`}>
      <ListHeading>{initial}</ListHeading>
      {auditableDomains[initial]
        .sort((u1, u2) =>
          u1.domain.displayName.localeCompare(u2.domain.displayName)
        )
        .map(u => (
          <DefaultLink
            key={u.id}
            href="javascript:void(0)"
            onClick={() => assumeUser(u.email)}
          >
            {u.domain.displayName}
          </DefaultLink>
        ))}
    </ListContainer>
  );

  const auditList =
    user.auditableUsers.length > 0 ? (
      <Container>
        {Object.keys(auditableDomains).sort().map(listMapper)}
      </Container>
    ) : (
      <div>No active audits are associated with your account.</div>
    );

  return (
    <VantaDashboardPage headingInfo={PageHeadingInfo.AUDITOR_DASHBOARD}>
      <DivWithMargins>
        <DefaultLink href="#auditor-documents">
          {" "}
          Jump to Auditor Documents{" "}
        </DefaultLink>
      </DivWithMargins>
      {auditList}
      <AuditorDocumentsView auditableUsers={user.auditableUsers} />
    </VantaDashboardPage>
  );
};

const DivWithMargins = styled.div`
  margin: 0 0 40px;
`;

const Container = styled.div`
  border: 1px solid #dbdbdc;
  padding: 40px 48px 0;
  margin-top: 12px;
  margin-bottom: 48px;
`;

const ListContainer = styled.div`
  margin-bottom: 40px;
  a {
    display: block;
  }
`;

const ListHeading = styled.div`
  font-size: 28px;
  font-weight: 900;
  margin-bottom: 18px;
`;

// Add the risks query to domains
gql`
  query getAuditingInfo {
    user {
      id
      auditableUsers {
        id
        domain {
          id
          displayName
        }
        email
      }
      auditorInfo {
        id
        firmName
        hasBankAccountConnected
      }
    }
  }
`;

gql`
  mutation assumeUserMutationAuditor($email: String!) {
    assumeUser(email: $email, writeAccess: false, isAuditor: true)
  }
`;

export const AuditorHomepage: React.FC = () => {
  const { error, data, loading } = useGetAuditingInfoQuery();
  const [assumeUser] = useAssumeUserMutationAuditorMutation();
  if (error) {
    LogError(error);
    return null;
  }
  if (loading) {
    return <FullPageSpinner />;
  }
  if (!data || !data.user) {
    LogErrorMessage("Bad fetch");
    return null;
  }

  return <AuditorHomepageComponent user={data.user} assumeUser={assumeUser} />;
};
