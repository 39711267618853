import type { Maybe } from "common/base/types/maybe";
import React from "react";

import { getVulnCountTags } from "../common/components";
import { VulnerabilityPageEntry } from "../common/vulnerability-page-entry";

interface IProps {
  name: string;
  projectId: string;
  slaDeadline?: Maybe<Date>;
  vulnerabilityCounts: {
    low: number;
    medium: number;
    high: number;
  };
  handleClick: Function;
}

export const SnykProjectEntry: React.FC<IProps> = ({
  name,
  projectId,
  slaDeadline,
  vulnerabilityCounts,
  handleClick,
}) => {
  const tags = getVulnCountTags(vulnerabilityCounts);
  return (
    <VulnerabilityPageEntry
      primaryInfo={name}
      secondaryInfo={projectId}
      slaDeadline={slaDeadline}
      handleClick={handleClick}
      tags={tags}
    />
  );
};
