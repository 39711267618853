import { Classes } from "@blueprintjs/core";
import { Feature } from "common/base/types/gen";
import { VANTA_AWS_TAG_KEYS, VANTA_AZURE_TAG_KEYS } from "common/utils/tags";
import React from "react";

import { FeatureGate } from "../../../helpers/feature-gating/feature-gate";
import { InfoIcon } from "../../helpers/InfoIcon";

interface IProps {
  service: string;
}

export const DocsTerraformTag: React.FC<IProps> = ({ service }) => {
  // we only have AWS and Azure 9/4/2020
  const infraAsCodeService =
    service === "AWS" ? "Cloudformation" : "Azure Resource Manager";
  const tagKeys = service === "AWS" ? VANTA_AWS_TAG_KEYS : VANTA_AZURE_TAG_KEYS;
  return (
    <div className={Classes.DIALOG_BODY}>
      <p>
        {`Apply these tags to your ${service} resources using Terraform or
         ${infraAsCodeService}, and Vanta will update your inventory list.`}
      </p>
      <code>
        tags &#123;
        <br />
        &nbsp;
        <strong>{tagKeys.vantaOwner}</strong>
        <InfoIcon content="Email address of the resource owner" />
        &nbsp;&nbsp;= "olivia@company.com"
        <br />
        &nbsp;
        <strong>{tagKeys.vantaNonProd}</strong>
        <InfoIcon
          content={
            <>
              <code>true</code> for staging/dev resources, <code>false</code>{" "}
              for prod
            </>
          }
        />
        &nbsp;&nbsp;= true
        <br />
        &nbsp;
        <strong>{tagKeys.vantaDescription}</strong>
        &nbsp;&nbsp;= "Archive of ingested events handled by logs queue"
        <br />
        &nbsp;
        <strong>{tagKeys.vantaUserData}</strong>
        <InfoIcon
          content={
            <>
              <code>true</code> if the resource contains user data
            </>
          }
        />
        &nbsp;&nbsp;= true
        <br />
        &nbsp;
        <strong>{tagKeys.vantaDataStored}</strong>
        <InfoIcon
          content={"A description of what user data this resource stores"}
        />
        &nbsp;&nbsp;= "User emails and phone numbers"
        <br />
        &nbsp;
        <FeatureGate features={[Feature.BetaHIPAA]}>
          <strong>{tagKeys.vantaEPHI}</strong>
          <InfoIcon
            content={
              <>
                <code>true</code> if the resource contains electronic Protected
                Health Information (ePHI)
              </>
            }
          />
          &nbsp;&nbsp;= true
          <br />
          &nbsp;
        </FeatureGate>
        <strong>VantaNoAlert</strong>
        <InfoIcon
          content={
            "Vanta will not monitor the resource and will exclude it from SOC 2 scope. Only add this \
          tag if you’re sure the resource is irrelevant to SOC 2, and please specify the reason that it's being \
          excluded."
          }
        />
        &nbsp;&nbsp;= "This stores our favorite foods and isn't part of our
        production systems."
        <br />
        &#125;
      </code>
    </div>
  );
};
