import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React, { useState } from "react";

import type {
  GetVendorsQuery,
  VendorDocumentType,
} from "../../../gen/components";
import {
  GetVendorsDocument,
  useDeleteVendorDocumentMutation,
} from "../../../gen/components";
import { DefaultLink } from "../../../helpers/links";
import { CancelConfirmDialog } from "../../helpers/CancelConfirmDialog";

type AcceptedFileTypes = Pick<
  NonNullable<GetVendorsQuery["organization"]>["vendors"][number],
  "assessmentFile" | "attestationOfComplianceFile" | "baaFile" | "dpaFile"
>;
type VendorDocument = NonNullable<AcceptedFileTypes[keyof AcceptedFileTypes]>;

interface IProps {
  document: VendorDocument;
  name: string;
  type: VendorDocumentType;
  vendorId: string;
}

export const VendorDocumentsTableActions: React.FC<IProps> = ({
  document,
  name,
  type,
  vendorId,
}) => {
  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  const [deleteVendorDocument, deleteVendorDocumentResult] =
    useDeleteVendorDocumentMutation({
      refetchQueries: [{ query: GetVendorsDocument }],
    });

  return (
    <div>
      <Button
        minimal
        onClick={() => setDialogIsOpen(true)}
        icon={IconNames.TRASH}
        intent={Intent.DANGER}
      >
        Remove
      </Button>
      {dialogIsOpen ? (
        <CancelConfirmDialog
          isOpen={true}
          onClose={() => {
            setDialogIsOpen(false);
          }}
          onConfirm={async () => {
            await deleteVendorDocument({
              variables: {
                vendorId,
                documentType: type,
                vendorDocumentId: document.id,
              },
            });
            setDialogIsOpen(false);
          }}
          title={`Delete ${name} Document?`}
          confirmText="Delete"
          body={
            <div>
              <p>Are you sure you want to delete this document?</p>
              <DefaultLink href={document.url} target="_blank">
                {document.filename ?? name + ".pdf"}
              </DefaultLink>
            </div>
          }
          loading={deleteVendorDocumentResult.loading}
        />
      ) : null}
    </div>
  );
};
