import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import React from "react";

import { H4 } from "../../../alpaca/components";
import { LogError } from "../../../errors";
import type { GetInventoryItemsForRiskAssessmentQuery } from "../../../gen/components";
import {
  GetInventoryItemsForRiskAssessmentDocument,
  useCreateRiskMitigationTaskMutation,
  useRemoveRiskMitigationTaskMutation,
} from "../../../gen/components";
import { TaskEditorPanel } from "../../master-detail-view/detail-panels/task-editor-panel";
import type { RiskMitigationTask } from "./queries-and-types";
import { TASK_CREATION_COPY } from "./risk-definitions/risks";

interface IProps {
  riskCategoryId: string;
  tasks: RiskMitigationTask[];
}

export const RiskTaskEditor: React.FC<IProps> = ({ tasks, riskCategoryId }) => {
  const [addTask] = useCreateRiskMitigationTaskMutation({
    update: (cache, result) => {
      const taskToAdd = result.data?.addRiskMitigationTask;
      if (!isSome(taskToAdd)) {
        return;
      }
      const previousData =
        cache.readQuery<GetInventoryItemsForRiskAssessmentQuery>({
          query: GetInventoryItemsForRiskAssessmentDocument,
        });
      if (!isSome(previousData) || !isSome(previousData.organization)) {
        return;
      }

      cache.writeQuery<GetInventoryItemsForRiskAssessmentQuery>({
        query: GetInventoryItemsForRiskAssessmentDocument,
        data: {
          organization: {
            ...previousData.organization,
            riskMitigationTasks:
              previousData.organization.riskMitigationTasks.concat(taskToAdd),
          },
        },
      });
    },
  });
  const [removeTask] = useRemoveRiskMitigationTaskMutation({
    update: (cache, result) => {
      const taskToDelete = result.data?.deleteRiskMitigationTask;
      if (!isSome(taskToDelete)) {
        return;
      }
      const previousData =
        cache.readQuery<GetInventoryItemsForRiskAssessmentQuery>({
          query: GetInventoryItemsForRiskAssessmentDocument,
        });
      if (!isSome(previousData) || !isSome(previousData.organization)) {
        return;
      }

      cache.writeQuery<GetInventoryItemsForRiskAssessmentQuery>({
        query: GetInventoryItemsForRiskAssessmentDocument,
        data: {
          organization: {
            ...previousData.organization,
            riskMitigationTasks:
              previousData.organization.riskMitigationTasks.filter(
                task => task.id !== taskToDelete.id
              ),
          },
        },
      });
    },
  });
  return (
    <TaskEditorPanel
      assigneeRequired={true}
      tasks={tasks}
      textContent={<H4>{TASK_CREATION_COPY}</H4>}
      onCreateTask={(description, assignee, dueDate) => {
        addTask({
          variables: {
            riskCategoryId,
            description,
            assignee,
            dueDate: dueDate?.toISOString(),
          },
        }).catch(LogError);
      }}
      onDeleteTask={taskId => {
        removeTask({ variables: { taskId } }).catch(LogError);
      }}
    />
  );
};

gql`
  mutation createRiskMitigationTask(
    $description: String!
    $riskCategoryId: String!
    $assignee: String
    $dueDate: DateTime
  ) {
    addRiskMitigationTask(
      description: $description
      riskCategoryId: $riskCategoryId
      assignee: $assignee
      dueDate: $dueDate
    ) {
      id
      description
      dueDate
      riskCategoryId
      assignee {
        id
        displayName
      }
    }
  }
`;

gql`
  mutation removeRiskMitigationTask($taskId: String!) {
    deleteRiskMitigationTask(taskId: $taskId) {
      id
    }
  }
`;
