import type { Maybe } from "common/base/types/maybe";
import React from "react";
import ReactMarkdown from "react-markdown";
import styled from "styled-components";

import { BASE_PALETTE } from "../../../alpaca/base/colors";
import { GRID_SPACING } from "../../../alpaca/base/grid";
import { DefaultLink } from "../../../helpers/links";

export const MarkdownRenderer = (
  markdown: string,
  options?: Maybe<{
    // Styles li's as list components from the design system
    styleOrderedList?: Maybe<boolean>;
  }>
) => {
  const components = {
    a({ href, children }: { [key: string]: unknown }) {
      return (
        <DefaultLink href={href as string}>{children as string[]}</DefaultLink>
      );
    },
    ...(Boolean(options?.styleOrderedList)
      ? {
          ol: (props: any) => <StyledOl>{props?.children}</StyledOl>,
        }
      : {}),
  };

  return <ReactMarkdown components={components}>{markdown}</ReactMarkdown>;
};

const CONSTANTS = {
  LI_DIMENSIONS: 18,
};

const StyledOl = styled.ol`
  list-style: none;
  counter-reset: markdown-counter;
  padding: 0;
  display: grid;
  grid-gap: ${2 * GRID_SPACING}px;
  padding-left: ${CONSTANTS.LI_DIMENSIONS + GRID_SPACING}px;

  & li {
    counter-increment: markdown-counter;
    position: relative;

    &::before {
      position: absolute;
      left: -${CONSTANTS.LI_DIMENSIONS + GRID_SPACING}px;
      content: counter(markdown-counter);
      font-size: 14px;
      line-height: 17px;
      height: ${CONSTANTS.LI_DIMENSIONS}px;
      width: ${CONSTANTS.LI_DIMENSIONS}px;
      color: ${BASE_PALETTE.SNOW};
      background-color: ${BASE_PALETTE.SMOKE};
      text-align: center;
      border-radius: 100%;
    }
  }
`;
