import { isKeyOf } from "common/base/types/helpers";
import { dropNothing, isSome } from "common/base/types/maybe";
import React from "react";

import { LogError, LogErrorMessage } from "../../../errors";
import {
  useFetchAwsDbInfoQuery,
  useFetchAzureDbInfoQuery,
  useFetchDigitalOceanDbInfoQuery,
  useFetchGcpdbInfoQuery,
  useFetchHerokuAddOnsQuery,
  useFetchMongoAtlasDbInfoQuery,
} from "../../../gen/components";
import { FullPageSpinner } from "../../helpers/FullPageSpinner";
import {
  PageHeadingInfo,
  VantaDashboardPage,
} from "../../vanta-chrome/page-content/vanta-dashboard-page";
import { DataTable } from "../components/data-table";
import { AWS_DBS, awsDataTableParameters } from "./databases/aws";
import { AZURE_DBS, azureDataTableParameters } from "./databases/azure";
import {
  DIGITALOCEAN_DBS,
  digitaloceanDataTableParameters,
} from "./databases/digitalocean";
import { GCP_DBS, gcpDataTableParameters } from "./databases/gcp";
import {
  getHerokuDbInfo,
  HEROKU_DBS,
  herokuDatatableParametrs,
} from "./databases/heroku";
import {
  MONGOATLAS_DBS,
  mongoatlasDataTableParameters,
} from "./databases/mongoatlas";
import { Container } from "./helpers";

const DB_SERVICES = [
  "aws",
  "azure",
  "digitalocean",
  "gcp",
  "mongoatlas",
  "heroku",
] as const;
const serviceToParameters = {
  aws: {
    dataTableParameters: awsDataTableParameters,
    dbTypes: AWS_DBS,
    query: useFetchAwsDbInfoQuery,
    dataFilter: null,
  },
  azure: {
    dataTableParameters: azureDataTableParameters,
    dbTypes: AZURE_DBS,
    query: useFetchAzureDbInfoQuery,
    dataFilter: null,
  },
  digitalocean: {
    dataTableParameters: digitaloceanDataTableParameters,
    dbTypes: DIGITALOCEAN_DBS,
    query: useFetchDigitalOceanDbInfoQuery,
    dataFilter: null,
  },
  gcp: {
    dataTableParameters: gcpDataTableParameters,
    dbTypes: GCP_DBS,
    query: useFetchGcpdbInfoQuery,
    dataFilter: null,
  },
  mongoatlas: {
    dataTableParameters: mongoatlasDataTableParameters,
    dbTypes: MONGOATLAS_DBS,
    query: useFetchMongoAtlasDbInfoQuery,
    dataFilter: null,
  },
  heroku: {
    dataTableParameters: herokuDatatableParametrs,
    dbTypes: HEROKU_DBS,
    query: useFetchHerokuAddOnsQuery,
    dataFilter: getHerokuDbInfo,
  },
};

export const ManageDBSecurityPage: React.FC = () => {
  const tables = DB_SERVICES.map(service =>
    serviceToParameters[service].dbTypes.map(db => {
      const { error, loading, data } = serviceToParameters[service].query();
      if (error) {
        LogError(error);
        return {
          status: "error",
          table: null,
        };
      }
      if (loading) {
        return {
          status: "loading",
          table: null,
        };
      }
      if (!data) {
        LogErrorMessage("Bad fetch");
        return {
          status: "error",
          table: null,
        };
      }
      if (!isKeyOf(db, data.organization)) {
        LogErrorMessage(`${db} not returned for query for ${service}`);
        return {
          status: "error",
          table: null,
        };
      }
      const parameters = serviceToParameters[service];
      const dbData = isSome(parameters.dataFilter)
        ? parameters.dataFilter(data)
        : (data.organization[db] as { edges: Array<{ node: any }> }).edges.map(
            e => e.node
          );
      if (!isSome(dbData) || dbData.length === 0) {
        return {
          status: "success",
          table: null,
        };
      }
      const { columnOrder, displayName, header, createRow } =
        parameters.dataTableParameters[db];
      return {
        status: "success",
        table: (
          <Container key={db}>
            <h3>{displayName}</h3>
            <DataTable
              key={db}
              columnOrder={columnOrder}
              createRow={createRow}
              data={dbData}
              header={header}
              emptyDefault={"No data available"}
            />
          </Container>
        ),
      };
    })
  ).flat();
  if (!tables.some(t => t.status === "success")) {
    return <FullPageSpinner />;
  }
  return (
    <VantaDashboardPage headingInfo={PageHeadingInfo.DATABASES}>
      {dropNothing(tables.map(t => t.table))}
    </VantaDashboardPage>
  );
};
