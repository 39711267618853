import { RISK_ID_TO_HEADING } from "common/schemas/riskRegister/risk-register-content";

import { LogErrorMessage } from "../../../errors";
import type { Domain } from "./risk-report";
const needsEscapingRegex = /[,"\n]/;

export enum RiskCSV {
  Task,
  Scoring,
}

export const escapeStringForCSV = (str: string) => {
  if (needsEscapingRegex.test(str)) {
    return `"${str.replace(/""/g, '""').replace(/\n/g, " ")}"`;
  } else {
    return str;
  }
};

const EVALUATION_COLUMNS = [
  "Name",
  "Type",
  "Likelihood",
  "Impact",
  "Updated timestamp",
  "Updated by",
];

const TASK_COLUMNS = ["Task", "Created", "Completed", "Due date", "Assignee"];

export class RiskReportCSVGenerator {
  private readonly domain: Domain;
  constructor(domain: Domain) {
    this.domain = domain;
  }

  public getCSV(type: RiskCSV) {
    switch (type) {
      case RiskCSV.Scoring:
        return this.getScoringContent();
      case RiskCSV.Task:
        return this.getTaskContent();
      default:
        LogErrorMessage("Unknown type passed for RiskReportCSV");
        return "Error generating CSV";
    }
  }

  private getScoringContent() {
    const evaluationInfo = this.domain.riskEvaluations.map(evaluation =>
      [
        evaluation.itemName,
        RISK_ID_TO_HEADING[evaluation.riskId],
        evaluation.likelihood ?? -1,
        evaluation.impact ?? -1,
        evaluation.updatedAt.toString(),
        evaluation.evaluator?.displayName ?? "",
      ]
        .map(item => `${item}`)
        .map(escapeStringForCSV)
        .join(",")
    );
    return `${EVALUATION_COLUMNS.join(",")}\r\n${evaluationInfo.join("\r\n")}`;
  }

  private getTaskContent() {
    const taskInfo = this.domain.riskMitigationTasks.map(task =>
      [
        task.description,
        task.createdAt.toString(),
        task.completedAt?.toString() ?? "not completed",
        task.dueDate?.toString() ?? "No due date",
        task.assignee?.displayName ?? "no assignee",
      ]
        .map(escapeStringForCSV)
        .join(",")
    );
    return `${TASK_COLUMNS.join(",")}\r\n${taskInfo.join("\r\n")}`;
  }
}
