import { Card, Callout, Intent, Spinner, Switch } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { Button } from "../../../../alpaca/components";
import {
  AllLinkedCredentialsDocument,
  useGetCredentialsQuery,
  useListAtlasProjectsQuery,
  useSetAtlasProjectsMutation,
  useSetCredentialsMutation,
} from "../../../../gen/components";
import { DefaultLink } from "../../../../helpers/links";
import { AppToaster } from "../../../../helpers/toaster";

export const SelectProjects: React.FC<{
  onSave: () => void;
  onFail: () => void;
  credentials: Maybe<{ privateKey: string; publicKey: string }>;
}> = props => {
  const { loading, error, data } = useListAtlasProjectsQuery({
    variables: {
      creds: isSome(props.credentials)
        ? {
            username: props.credentials.publicKey,
            password: props.credentials.privateKey,
          }
        : null,
    },
    fetchPolicy: "network-only",
  });
  const {
    loading: loadingCreds,
    error: errorCreds,
    data: dataCreds,
  } = useGetCredentialsQuery({
    variables: { services: ["mongoatlas"] },
    fetchPolicy: "network-only",
  });
  const [setCredentials, { loading: setCredentialsLoading }] =
    useSetCredentialsMutation();
  const [setProjects, { loading: setProjectsLoading }] =
    useSetAtlasProjectsMutation();
  const [trackedProjectIds, setTrackedProjectIds] = useState(new Set<string>());
  useEffect(() => {
    setTrackedProjectIds(
      new Set<string>(
        JSON.parse(dataCreds?.organization.credentials[0]?.metadata ?? "{}")
          .projectIds ?? []
      )
    );
  }, [dataCreds]);
  if (loading || loadingCreds) {
    return <Spinner />;
  }
  if (error || errorCreds) {
    props.onFail();
    return null;
  }

  return (
    <>
      <Callout>Select the projects that you would like Vanta to track.</Callout>
      {data?.organization.accessibleAtlasProjects.map(p => (
        <Card key={p.id}>
          <DefaultLink
            href={`https://cloud.mongodb.com/v2/${p.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {p.name}
          </DefaultLink>
          <ProjectSwitch
            checked={trackedProjectIds.has(p.id)}
            onChange={() =>
              setTrackedProjectIds(oldTracked => {
                const newTracked = new Set(oldTracked);
                if (newTracked.has(p.id)) {
                  newTracked.delete(p.id);
                } else {
                  newTracked.add(p.id);
                }
                return newTracked;
              })
            }
          />
        </Card>
      ))}
      <SaveButton
        disabled={trackedProjectIds.size === 0}
        loading={setCredentialsLoading || setProjectsLoading}
        intent={Intent.PRIMARY}
        onClick={async () => {
          const metadata = JSON.stringify({
            projectIds: Array.from(trackedProjectIds),
          });
          let successMessage = "Credentials saved";
          if (!isSome(props.credentials)) {
            await setProjects({
              variables: {
                metadata,
              },
              refetchQueries: [{ query: AllLinkedCredentialsDocument }],
              awaitRefetchQueries: true,
            });
            successMessage = "Projects updated";
          } else {
            const stringCreds = JSON.stringify({
              ...props.credentials,
              projectIds: Array.from(trackedProjectIds),
            });
            await setCredentials({
              variables: {
                credentials: stringCreds,
                service: "mongoatlas",
              },
              refetchQueries: [{ query: AllLinkedCredentialsDocument }],
              awaitRefetchQueries: true,
            });
          }

          AppToaster.show({
            icon: "tick",
            message: successMessage,
            timeout: 2500,
            intent: "success",
          });
          props.onSave();
        }}
      >
        Save
      </SaveButton>
    </>
  );
};

const SaveButton = styled(Button)`
  float: right;
  margin-top: 10px;
`;

const ProjectSwitch = styled(Switch)`
  float: right;
`;

gql`
  query ListAtlasProjects($creds: UsernamePassword) {
    organization {
      id
      accessibleAtlasProjects(mongoCredentials: $creds) {
        id
        name
      }
    }
  }
`;

gql`
  mutation setAtlasProjects($metadata: String!) {
    setCredentialMetadata(metadata: $metadata, service: "mongoatlas") {
      id
      credentials(services: ["mongoatlas"]) {
        id
        service
        lastUpdated
        metadata
      }
    }
  }
`;
