import { css } from "styled-components";

import { BASE_PALETTE } from "../../base/colors";

export const DialogClasses = {
  DIALOG: "alpaca-dialog",
  DIALOG_HEADER: "alpaca-dialog-header",
  DIALOG_BODY: "alpaca-dialog-body",
  DIALOG_FOOTER: "alpaca-dialog-footer",
  DIALOG_FOOTER_ACTIONS: "alpaca-dialog-footer-actions",
};

export const DIALOG_CLASS_STYLES = css`
  &.${DialogClasses.DIALOG} {
    background-color: ${BASE_PALETTE.SNOW};
    padding-bottom: 0px;
  }

  .${DialogClasses.DIALOG_HEADER} {
    margin: 32px 32px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .${DialogClasses.DIALOG_BODY} {
    margin: 24px 32px 32px;
  }

  .${DialogClasses.DIALOG_FOOTER_ACTIONS} {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
  }
`;
