import { Button, Dialog, Intent, Spinner } from "@blueprintjs/core";
import { PermissionLevel } from "common/base/types/gen";
import type { Maybe } from "common/base/types/maybe";
import {
  dropNothing,
  getTransformedOrElse,
  isSome,
} from "common/base/types/maybe";
import gql from "graphql-tag";
import React, { useState } from "react";
import styled from "styled-components";

import { BodyShortText, IconNames } from "../../../../alpaca/components";
import { LogError } from "../../../../errors";
import type {
  GetInProgressAndLinkableBgChecksQuery,
  GetUserInfoForOnboardingListQuery,
} from "../../../../gen/components";
import {
  useUserAccessQuery,
  GetLinkableBgChecksDocument,
  GetUserBackgroundCheckListDocument,
  GetUserInfoForOnboardingListDocument,
  useAssignBackgroundCheckMutation,
  useGetInProgressAndLinkableBgChecksQuery,
} from "../../../../gen/components";
import { AppToaster } from "../../../../helpers/toaster";
import { DataTable } from "../../components/data-table";
import { IconButton } from "../shared/icon-button";
import { sourceForService } from "../utils";
import { LinkBgCheckButtonWithDialog } from "./link-bg-check-button-with-dialog";

type User = NonNullable<GetUserInfoForOnboardingListQuery["user"]>;
type InProgressBgCheck = {
  id: Maybe<string>;
  status: Maybe<string>;
  service: Maybe<string>;
  completedAt: Maybe<string>;
  isLinkable: boolean;
  name: string;
  email: string;
};
interface IProps {
  isOpen: boolean;
  onClose(): void;
  user: User;
}

interface IWithName {
  givenName?: Maybe<string>;
  familyName?: Maybe<string>;
}

function nameFromCheck(check: IWithName): string {
  const name = dropNothing([check.givenName, check.familyName]).join(" ");
  return name === "" ? "No name information" : name;
}

const InternalDialogComponent: React.FC<IProps> = ({
  onClose,
  isOpen,
  user,
}) => {
  const userId = user.id;
  const { loading, data } = useGetInProgressAndLinkableBgChecksQuery({
    variables: { userId },
  });

  const [assignBackgroundCheck] = useAssignBackgroundCheckMutation({
    refetchQueries: [
      {
        query: GetUserInfoForOnboardingListDocument,
        variables: { userId: user.id },
      },
      {
        query: GetUserBackgroundCheckListDocument,
        variables: { userId: user.id },
      },
      {
        query: GetLinkableBgChecksDocument,
      },
    ],
  });

  if (loading || !data || !data.user) {
    return null;
  }

  if (
    data.organization.uiLinkableBackgroundChecks.length === 0 &&
    data.user.backgroundChecks.length === 0
  ) {
    return null;
  }

  const assignBackgroundCheckToUser = async (
    backgroundCheckId: string,
    isUnlink: boolean = false
  ) => {
    try {
      await assignBackgroundCheck({
        variables: {
          backgroundCheckId,
          userId: isUnlink ? null : user.id,
        },
      });

      const toastMessage = `Background check ${
        isUnlink ? ` unlinked` : `assigned to ${user.displayName}`
      }`;
      AppToaster.show({
        action: {
          onClick: async () => {
            await assignBackgroundCheckToUser(backgroundCheckId, !isUnlink);
          },
          text: "Undo",
        },
        icon: "tick",
        intent: Intent.SUCCESS,
        message: toastMessage,
        timeout: 2500,
      });
    } catch (e) {
      LogError(e);
    }
  };

  const getInProgressBackgroundCheckFromLinkable: (
    check: GetInProgressAndLinkableBgChecksQuery["organization"]["uiLinkableBackgroundChecks"][number]
  ) => InProgressBgCheck = check => {
    return {
      id: check.id,
      status: check.status,
      service: check.service,
      completedAt: check.completedAt,
      email: check.email,
      name: nameFromCheck(check),
      isLinkable: true,
    };
  };

  const getInProgressBackgroundCheckFrombgCheckDetails: (
    check: NonNullable<
      GetInProgressAndLinkableBgChecksQuery["user"]
    >["backgroundChecks"][number]
  ) => InProgressBgCheck = check => {
    return {
      id: check.linkedBackgroundCheckId,
      status: check.status,
      service: check.service,
      completedAt: check.completedAt,
      isLinkable: false,
      name: nameFromCheck(check),
      email: check.email ?? "No email information",
    };
  };

  const allChecks = data.user.backgroundChecks
    .map(getInProgressBackgroundCheckFrombgCheckDetails)
    .concat(
      data.organization.uiLinkableBackgroundChecks
        .filter(check => !check.ignored)
        .map(getInProgressBackgroundCheckFromLinkable)
    );

  return (
    <Dialog
      style={{ width: "750px" }}
      isOpen={isOpen}
      onClose={onClose}
      title={`Background check details for ${user.displayName}`}
    >
      <ScrollingDiv>
        <DataTable
          createRow={bgCheck => {
            const isLink = bgCheck.isLinkable;

            const linkOrUnlink = (
              <StyledButton
                intent={isLink ? Intent.NONE : Intent.DANGER}
                onClick={async () => {
                  if (isSome(bgCheck.id)) {
                    await assignBackgroundCheckToUser(bgCheck.id, !isLink);
                  }
                }}
              >
                {isLink ? "Link" : "Unlink"}
              </StyledButton>
            );

            return {
              linkOrUnlink,
              identifyingInfo: (
                <div>
                  Email: {bgCheck.email}
                  <br />
                  Name: {bgCheck.name}
                </div>
              ),
              status: (
                <BodyShortText>
                  {bgCheck.status === "COMPLETED"
                    ? `Completed on ${bgCheck.completedAt}`
                    : "In progress"}
                </BodyShortText>
              ),
              source: (
                <BodyShortText>
                  {sourceForService(bgCheck.service ?? "")}
                </BodyShortText>
              ),
            };
          }}
          data={allChecks}
          columnOrder={["identifyingInfo", "source", "status", "linkOrUnlink"]}
          header={{
            identifyingInfo: "Identifying info",
            source: "Source",
            status: "Status",
            linkOrUnlink: "",
          }}
        />
      </ScrollingDiv>
    </Dialog>
  );
};

interface IExternalProps {
  user: User;
  renderButton?: Maybe<(onClick: () => void) => JSX.Element>;
}

export const InProgressBgCheckButtonWithDialog: React.FC<IExternalProps> = ({
  user,
  renderButton,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { error, loading, data: viewerUserData } = useUserAccessQuery();
  if (error) {
    LogError(error);
    return null;
  }
  if (loading || !viewerUserData || !viewerUserData.user) {
    return <Spinner />;
  }
  // Only Admin can view BG check details, always can show Link BG Check
  const canViewBgCheckDetails =
    viewerUserData.user.permissionLevel === PermissionLevel.Admin;
  if (!canViewBgCheckDetails) {
    return <LinkBgCheckButtonWithDialog user={user} />;
  }
  const onClick = () => setIsOpen(true);
  const button = getTransformedOrElse(
    renderButton,
    render => render(onClick),
    <IconButton
      onClick={onClick}
      icon={IconNames.VIEW}
      tooltipContent={"View"}
    />
  );
  return (
    <>
      {button}
      <InternalDialogComponent
        isOpen={isOpen}
        user={user}
        onClose={() => setIsOpen(false)}
      />
    </>
  );
};

gql`
  query GetInProgressAndLinkableBGChecks($userId: ID!) {
    organization {
      id
      uiLinkableBackgroundChecks {
        id
        status
        service
        email
        familyName
        givenName
        completedAt
        ignored
      }
    }
    user(id: $userId) {
      id
      backgroundChecks {
        id
        email
        familyName
        givenName
        linkedBackgroundCheckId
        status
        service
        completedAt
        uniqueId
      }
    }
  }
`;

const StyledButton = styled(Button)`
  width: 60px;
`;

const ScrollingDiv = styled.div`
  max-height: 300px;
  overflow-y: scroll;
  flex: 1 1 auto;
  margin: 20px;
  line-height: 18px;
  table {
    width: 100%;
  }
`;
