import { ReportStandard } from "../base/types/gen";
import type { Maybe } from "../base/types/maybe";
import { isSome } from "../base/types/maybe";
import type { IStandard } from "./complianceTypes";
import { GDPR_STANDARD } from "./gdpr/standard";
import * as gdpr_1_0 from "./gdpr/standard-mappings/1.0.json";
import { HIPAA_STANDARD } from "./hipaa/standard";
import * as hipaa_1_0 from "./hipaa/standard-mappings/1.0.json";
import { ISO27001_STANDARD } from "./iso27001/standard";
import * as iso_1_0 from "./iso27001/standard-mappings/1.0.json";
import { PCI_DSS_SAQ_A_STANDARD } from "./pciSaqA/standard";
import * as pci_saq_a_1_0 from "./pciSaqA/standard-mappings/1.0.json";
import { PCI_DSS_SAQ_A_EP_STANDARD } from "./pciSaqAEP/standard";
import * as pci_saq_a_ep_1_0 from "./pciSaqAEP/standard-mappings/1.0.json";
import { PCI_DSS_SAQ_D_MERCHANT_STANDARD } from "./pciSaqDMerchant/standard";
import * as pci_saq_d_merchant_1_0 from "./pciSaqDMerchant/standard-mappings/1.0.json";
import { PCI_DSS_SAQ_D_SP_STANDARD } from "./pciSaqDSP/standard";
import * as pci_saq_d_sp_1_0 from "./pciSaqDSP/standard-mappings/1.0.json";
import { SOC2_STANDARD } from "./soc2/standard";
import * as soc2_1_0 from "./soc2/standard-mappings/1.0.json";
import type { StandardConfiguration } from "./standard";
import { Standard } from "./standard";
import type { StandardMapping } from "./standard-mapping";
import { VANTA_STANDARD } from "./vanta/standard";
import * as vanta_0_1 from "./vanta/standard-mappings/0.1.json";

// Since StandardMapping is generated from a JSON schema and is fed into
// a Standard object, by enforcing that the type of the imported file
// is a StandardMapping, Typescript will automatically validate the JSON schema for us!
export const allStandards: Standard[] = [
  new Standard(GDPR_STANDARD, gdpr_1_0),
  new Standard(HIPAA_STANDARD, hipaa_1_0),
  new Standard(ISO27001_STANDARD, iso_1_0),
  new Standard(PCI_DSS_SAQ_A_EP_STANDARD, pci_saq_a_ep_1_0),
  new Standard(PCI_DSS_SAQ_A_STANDARD, pci_saq_a_1_0),
  new Standard(PCI_DSS_SAQ_D_MERCHANT_STANDARD, pci_saq_d_merchant_1_0),
  new Standard(PCI_DSS_SAQ_D_SP_STANDARD, pci_saq_d_sp_1_0),
  new Standard(SOC2_STANDARD, soc2_1_0),
  new Standard(VANTA_STANDARD, vanta_0_1),

  // The below standards are commented out, because we do not guarantee backwards compatibility for their control/test mappings.
  // Accordingly, we don't want to run lint/validation tests against these.
  // They are kept here for posterity, but can be deleted if need be.
  // new Standard(SOC2_STANDARD, soc2_0_1),
  // new Standard(HIPAA_STANDARD, hipaa_0_1),
  // new Standard(ISO27001_STANDARD, iso_0_1),
];

const latestStandardMappings: {
  [key in ReportStandard]: StandardMapping;
} = {
  [ReportStandard.gdpr]: gdpr_1_0,
  [ReportStandard.hipaa]: hipaa_1_0,
  [ReportStandard.iso27001]: iso_1_0,
  [ReportStandard.pciSaqA]: pci_saq_a_1_0,
  [ReportStandard.pciSaqAEP]: pci_saq_a_ep_1_0,
  [ReportStandard.pciSaqDMerchant]: pci_saq_d_merchant_1_0,
  [ReportStandard.pciSaqDSP]: pci_saq_d_sp_1_0,
  [ReportStandard.soc2]: soc2_1_0,
  [ReportStandard.vanta]: vanta_0_1,
};

const latestStandardDefinitions: {
  [key in ReportStandard]: IStandard;
} = {
  [ReportStandard.gdpr]: GDPR_STANDARD,
  [ReportStandard.hipaa]: HIPAA_STANDARD,
  [ReportStandard.iso27001]: ISO27001_STANDARD,
  [ReportStandard.pciSaqA]: PCI_DSS_SAQ_A_STANDARD,
  [ReportStandard.pciSaqAEP]: PCI_DSS_SAQ_A_EP_STANDARD,
  [ReportStandard.pciSaqDMerchant]: PCI_DSS_SAQ_D_MERCHANT_STANDARD,
  [ReportStandard.pciSaqDSP]: PCI_DSS_SAQ_D_SP_STANDARD,
  [ReportStandard.soc2]: SOC2_STANDARD,
  [ReportStandard.vanta]: VANTA_STANDARD,
};

export type MappingVersion = {
  // Implement when we have standard versioning in place.
  // This may actually just be imported from StandardMapping types.
};

/**
 * Gets the standard mapping for a given mapping version and standard.
 * If no version is provided, defaults to returning latest.
 * @param standard The standard to get the mapping for
 * @param version The version of the mapping we want
 * @param config The configuration for the standard
 */
export const getStandardForVersion = (
  standard: ReportStandard,
  mappingVersion: Maybe<MappingVersion>,
  config?: Maybe<StandardConfiguration>
): Standard => {
  if (isSome(mappingVersion)) {
    throw new Error("Versioning is not yet implemented for standard mappings.");
  }

  return new Standard(
    latestStandardDefinitions[standard],
    latestStandardMappings[standard],
    config
  );
};
