import { Card, H3 } from "@blueprintjs/core";
import { dateStringToDate } from "common/base/dateUtils";
import { TestOutcome } from "common/base/types/gen";
import { isSome } from "common/base/types/maybe";
import moment from "moment";
import React, { useMemo } from "react";
import styled from "styled-components";

import { LogError } from "../../../errors";
import type { FetchRiskReportQuery } from "../../../gen/components";
import { useFetchRiskReportQuery } from "../../../gen/components";
import { UI_DATE_FORMAT } from "../../../helpers/common";
import { EvaluationIcon } from "../../helpers/EvaluationIcon";
import { FullPageSpinner } from "../../helpers/FullPageSpinner";
import {
  PageHeadingInfo,
  VantaDashboardPage,
} from "../../vanta-chrome/page-content/vanta-dashboard-page";
import { IMPACTS, LIKELIHOODS } from "./add-scenario-dialog";

type RiskReportTask = NonNullable<
  FetchRiskReportQuery["organization"]
>["riskRegister"]["scenarios"][number]["tasks"][number];

export const RiskReportPage: React.FC = () => {
  const { loading, error, data } = useFetchRiskReportQuery();
  const allTasks = useMemo<RiskReportTask[]>(
    () =>
      data?.organization.riskRegister?.scenarios?.reduce(
        (taskArray, scenario) => taskArray.concat(scenario.tasks),
        new Array<RiskReportTask>()
      ) ?? [],
    [data?.organization.riskRegister?.scenarios]
  );
  if (loading) {
    return <FullPageSpinner />;
  }
  if (error || !data) {
    LogError(error ?? new Error("Bad fetch"));
    return null;
  }

  const domain = data.organization;
  const scenarioCards = domain.riskRegister.scenarios.map(s => {
    const tasks = s.tasks.map(t => {
      const icon = isSome(t.completedAt)
        ? TestOutcome.PASS
        : isSome(t.dismissedAt)
        ? TestOutcome.FAIL
        : TestOutcome.IN_PROGRESS;

      const status = isSome(t.completedAt)
        ? `Completed ${moment(t.completedAt).format(UI_DATE_FORMAT)}`
        : isSome(t.dismissedAt)
        ? `Declined on ${moment(t.dismissedAt).format(
            UI_DATE_FORMAT
          )} with reason "${t.dismissReason}"`
        : "";
      return (
        <div key={t.id} className="spead-components">
          <span>
            <EvaluationIcon evaluation={icon} /> {t.text}
          </span>
          <span>{status}</span>
        </div>
      );
    });
    return (
      <Card key={s.id}>
        {isSome(s.mostRecentUpdate?.user) ? (
          <UpdateNotice>
            (Scenario last updated{" "}
            {moment(dateStringToDate(s.mostRecentUpdate!.timestamp)).format(
              UI_DATE_FORMAT
            )}{" "}
            by {s.mostRecentUpdate!.user.displayName})
          </UpdateNotice>
        ) : null}
        <H3>{s.text}</H3>
        <div className="spead-components scenario-card">
          <div>
            <b>Likelihood:</b> {LIKELIHOODS[s.likelihood]}
          </div>
          <div>
            <b>Impact:</b> {IMPACTS[s.impact]}
          </div>
        </div>
        <hr />
        {tasks}
      </Card>
    );
  });
  let remediationTaskCompletion = "N/A";

  if (allTasks.length > 0) {
    const proportionComplete =
      allTasks.filter(t => isSome(t.completedAt) || isSome(t.dismissedAt))
        .length / allTasks.length;
    remediationTaskCompletion = (proportionComplete * 100).toFixed() + "%";
  }

  return (
    <VantaDashboardPage headingInfo={PageHeadingInfo.RISK_REPORT}>
      <div>
        <p>
          The following is {domain.displayName}
          's risk report for {moment().format(UI_DATE_FORMAT)}
        </p>
      </div>
      <h2>Summary</h2>
      <Card>
        <div>Risks documented: {domain.riskRegister.scenarios.length}</div>
        <div>Remediation tasks complete: {remediationTaskCompletion}</div>
      </Card>
      <h2>Scenarios</h2>
      {scenarioCards}
    </VantaDashboardPage>
  );
};

const UpdateNotice = styled.div`
  float: right;
`;
