import { Intent } from "@blueprintjs/core";
import { simplePlural } from "common/grammar/plurals";
import React from "react";
import { Banner, IconNames } from "../../../alpaca/components";

export const ErrorToggle: React.FC<{
  onToggle: (toggle: boolean) => void;
  isError: boolean;
  errorCount: number;
  resource: string;
}> = ({ onToggle, isError, errorCount, resource }) => {
  const verb = isError ? "Hide" : "Show";

  return errorCount !== 0 ? (
    <Banner
      intent={Intent.WARNING}
      icon={IconNames.CAUTION}
      title={
        "We're having trouble getting information for some of these resources"
      }
      buttonProps={{
        text: `${verb} ${simplePlural(errorCount, resource)} with errors`,
        onClick: () => onToggle(!isError),
      }}
    />
  ) : null;
};
