import type { MIME_TYPES } from "common/base/types/helpers";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import React from "react";
import { FileDrop as ReactFileDrop } from "react-file-drop";
import styled from "styled-components";

import { BASE_PALETTE } from "../../../alpaca/base/colors";
import { checkFile } from "./file-input";

interface IProps {
  allowedMimeTypes: MIME_TYPES[];
  className?: Maybe<string>;
  onFileUploaded: (file: File) => void;
}

export const FileDrop: React.FC<IProps> = ({
  children,
  allowedMimeTypes,
  className,
  onFileUploaded,
}) => (
  <StyledFileDropContainer className={className ?? undefined}>
    <ReactFileDrop
      onDrop={(files, event) => {
        if (isSome(files) && files.length > 0) {
          if (checkFile(files[0], allowedMimeTypes)) {
            onFileUploaded(files[0]);
          }
        }
      }}
    >
      {children}
    </ReactFileDrop>
  </StyledFileDropContainer>
);

const StyledFileDropContainer = styled.div`
  .file-drop > .file-drop-target {
    background-color: ${BASE_PALETTE.VAPE}80;
    border: 1px dashed ${BASE_PALETTE.FOG};
    border-radius: 2px;
    padding: 12px;
  }

  .file-drop > .file-drop-target.file-drop-dragging-over-target {
    background-color: ${BASE_PALETTE.MINT}B3; // 70% transparency
    border: 1px dashed #61c193;
  }
`;
