import React from "react";

import type { GetDataForReportQuery } from "../../../../../gen/components";
import { ControlListItemDescription } from "../../../common";
import type { IDomainForReport } from "../../../helpers/helpers";
import { replaceVarsinReportText } from "../../../helpers/helpers";
import { VantaReportTestListItemStatus } from "./vanta-report-test-list-item-status";

interface IProps {
  test: NonNullable<
    GetDataForReportQuery["complianceStandard"]
  >["principles"][number]["requirements"][number]["controls"][number]["tests"][number];
  domain: IDomainForReport;
}

export const VantaReportTestListItem: React.FunctionComponent<IProps> = ({
  test,
  domain,
}) => (
  <div className="vrp-test-list-item">
    <ControlListItemDescription>
      <strong>{replaceVarsinReportText(test.name, domain)}</strong>:{" "}
      {replaceVarsinReportText(test.test, domain)}
    </ControlListItemDescription>
    <VantaReportTestListItemStatus
      testResult={domain.testIdToTestMap.get(test.testId)}
    />
  </div>
);
