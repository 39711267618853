import type { TestId } from "./test-ids";

export type TestVersion = {
  major: number;
  minor: number;
};

export type VersionedTest = {
  testId: TestId;
  version: TestVersion;
};

export const ZERO_VERSION: TestVersion = { major: 0, minor: 0 };

export const highestVersion = (vs: TestVersion[]) => {
  if (vs.length === 0) {
    return null;
  }

  const vsSorted = [...vs];
  vsSorted.sort(compareTestVersions);
  return vsSorted[vsSorted.length - 1];
};

export const testVersionsEqual = (v1: TestVersion, v2: TestVersion) =>
  compareTestVersions(v1, v2) === 0;

export const versionedTestsEqual = (t1: VersionedTest, t2: VersionedTest) =>
  t1.testId === t2.testId && testVersionsEqual(t1.version, t2.version);

/**
 * A compare function for the TestVersion type. Can be used to sort test
 * versions into ascending order. TestVersion types are compared by first
 * comparing their major version and then their minor version.
 *
 * @param v1 - A version to compare.
 * @param v2 - A version to compare.
 * @returns - A comparison of the version arguments.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort#description
 */
export const compareTestVersions = (v1: TestVersion, v2: TestVersion) => {
  for (const [a, b] of [
    [v1.major, v2.major],
    [v1.minor, v2.minor],
  ]) {
    if (a === b) {
      continue;
    }

    if (a > b) {
      return 1;
    }

    return -1;
  }

  return 0;
};

// ch-29380: We don't need to handle null testVersion after we migrate
// the testruns collection.
export const getTestRunVersionFilterForVersion = (version: TestVersion) =>
  version === ZERO_VERSION
    ? {
        $or: [
          { testVersion: null },
          { "testVersion.major": 0, "testVersion.minor": 0 },
        ],
      }
    : {
        "testVersion.major": version.major,
        "testVersion.minor": version.minor,
      };
