import {
  Button,
  Classes,
  Dialog,
  FormGroup,
  H4,
  Intent,
  Radio,
  RadioGroup,
  TextArea,
} from "@blueprintjs/core";
import type { FetchResult } from "apollo-link";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import React from "react";

import { LogError } from "../../../errors";
import type {
  AddRiskScenarioMutation,
  AddRiskScenarioMutationFn,
  FetchRiskRegisterQuery,
  UpdateRiskScenarioMutation,
  UpdateRiskScenarioMutationFn,
} from "../../../gen/components";
import {
  FetchRiskRegisterDocument,
  useAddRiskScenarioMutation,
  useUpdateRiskScenarioMutation,
} from "../../../gen/components";
import { createNewItemRenderer, renderTask, TaskSuggest } from "./suggest";

export const LIKELIHOODS: { [index: string]: string } = {
  "1": "Extremely likely",
  "2": "Very likely",
  "3": "Likely",
  "4": "Unlikely",
  "5": "Rare",
};

export const IMPACTS: { [index: string]: string } = {
  "1": "Annoyed",
  "2": "Angry",
  "3": "Furious",
};

interface IProps {
  edit?: Maybe<boolean>;
  question?: Maybe<
    NonNullable<
      FetchRiskRegisterQuery["organization"]
    >["riskRegister"]["questions"][0]
  >;
  scenarioToEdit?: Maybe<
    NonNullable<
      FetchRiskRegisterQuery["organization"]
    >["riskRegister"]["scenarios"][0]
  >;
  domain: NonNullable<FetchRiskRegisterQuery["organization"]>;
  onCloseDialog?: Maybe<() => void>;
}

interface IInternalProps extends IProps {
  addScenario: AddRiskScenarioMutationFn;
  updateScenario: UpdateRiskScenarioMutationFn;
}

interface IState {
  text: string;
  likelihood?: Maybe<number>;
  impact?: Maybe<number>;
  tasksToCreate: NonNullable<
    FetchRiskRegisterQuery["organization"]
  >["riskRegister"]["tasks"];
  tasksToAdd: NonNullable<
    FetchRiskRegisterQuery["organization"]
  >["riskRegister"]["tasks"];

  isOpen: boolean;
}

class AddScenario extends React.Component<IInternalProps, IState> {
  public constructor(props: IInternalProps) {
    super(props);
    const s = this.props.scenarioToEdit;
    this.state = {
      text: isSome(s) ? s.text : "",
      impact: isSome(s) ? s.impact : undefined,
      likelihood: isSome(s) ? s.likelihood : undefined,
      tasksToCreate: [],
      tasksToAdd: isSome(s) ? s.tasks : [],
      isOpen: true,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  public onClose() {
    if (isSome(this.props.onCloseDialog)) {
      this.props.onCloseDialog();
    }
  }

  public onSubmit() {
    const { addScenario, updateScenario, question } = this.props;
    const questionId = isSome(question) ? question.id : undefined;

    if (
      isSome(addScenario) &&
      isSome(updateScenario) &&
      isSome(this.state.impact) &&
      isSome(this.state.likelihood)
    ) {
      const variables = {
        text: this.state.text,
        impact: this.state.impact,
        likelihood: this.state.likelihood,
        questionId,
        newTasks: this.state.tasksToCreate.map(t => {
          const { text, id } = t;
          return { text, id };
        }),
        existingTasks: this.state.tasksToAdd.map(t => {
          const { text, id } = t;
          return { text, id };
        }),
      };

      // Would like to use AddRiskScenarioMutationResult | UpdateRiskScenarioMutationResult
      // But TypeScript won't allow it as it ends up with the union of two Promise<void | ...> types.
      let x: Promise<
        | void
        | FetchResult<AddRiskScenarioMutation>
        | FetchResult<UpdateRiskScenarioMutation>
      >;
      if (isSome(this.props.scenarioToEdit)) {
        x = updateScenario({
          variables: { ...variables, id: this.props.scenarioToEdit.id },
        });
      } else {
        x = addScenario({
          variables,
        });
      }

      x.then(() => this.setState({ isOpen: false })).catch(LogError);
    }
  }

  public render() {
    const { domain, question } = this.props;
    const { isOpen } = this.state;
    const canSubmit =
      this.state.text !== "" &&
      isSome(this.state.likelihood) &&
      isSome(this.state.impact);
    const addOrEdit = isSome(this.props.edit) ? "Edit" : "Add";
    const questionBlock = isSome(question) ? (
      <div className="scenario-dialog-header">
        <h4>{question.text}</h4>
        <Button
          intent={Intent.PRIMARY}
          text={question.affirmative ? "Yes" : "No"}
        />
        <i>
          This answer identifies a potential risk. Add potential scenarios and
          mitigation risks below.
        </i>
        <hr />
      </div>
    ) : undefined;
    return (
      <Dialog
        className="scenario-dialog"
        isOpen={isOpen}
        canEscapeKeyClose={true}
        canOutsideClickClose={true}
        title={`${addOrEdit} Scenario`}
        onClose={this.onClose}
        onClosed={this.onClose}
      >
        <div className={Classes.DIALOG_BODY}>
          {questionBlock}
          <div>
            <H4>What risk might this cause?</H4>
            <FormGroup label="Risk" className="full-width">
              <TextArea
                fill
                value={this.state.text}
                placeholder="Describe a problem this could cause"
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                  this.setState({ text: e.target.value })
                }
              />
            </FormGroup>
            <RadioGroup
              label="How likely is this to occur?"
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                this.setState({
                  likelihood: parseInt(e.currentTarget.value, 10),
                })
              }
              selectedValue={
                isSome(this.state.likelihood)
                  ? this.state.likelihood.toString()
                  : undefined
              }
              inline
            >
              {Object.keys(LIKELIHOODS).map(key => (
                <Radio label={LIKELIHOODS[key]} value={key} key={key} />
              ))}
            </RadioGroup>

            <RadioGroup
              label="If this happened, I would be:"
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                this.setState({
                  impact: parseInt(e.currentTarget.value, 10),
                })
              }
              selectedValue={
                isSome(this.state.impact)
                  ? this.state.impact.toString()
                  : undefined
              }
              inline
            >
              {Object.keys(IMPACTS).map(key => (
                <Radio label={IMPACTS[key]} value={key} key={key} />
              ))}
            </RadioGroup>
            <H4>Tasks</H4>
            <FormGroup
              label="What could we do to make this less likely to occur?"
              className="full-width"
            >
              <TaskSuggest
                inputProps={{
                  placeholder: "Search or create new task...",
                }}
                inputValueRenderer={item => item.text}
                createNewItemRenderer={createNewItemRenderer}
                selectedItem={null} // Blanks out the text box after an input is selected
                itemRenderer={renderTask}
                itemPredicate={(query, task) =>
                  task.text.toLowerCase().includes(query.toLowerCase())
                }
                items={domain.riskRegister.tasks}
                createNewItemFromQuery={(item: string) => {
                  return {
                    text: item,
                    id: "new",
                    completedAt: null,
                    dismissedAt: null,
                    dismissReason: null,
                  };
                }}
                onItemSelect={item => {
                  if (item.id === "new") {
                    this.setState(prevState => {
                      return {
                        tasksToCreate: [...prevState.tasksToCreate, item],
                      };
                    });
                  } else {
                    this.setState(prevState => {
                      return {
                        tasksToAdd: [...prevState.tasksToAdd, item],
                      };
                    });
                  }
                }}
              />
            </FormGroup>

            {this.state.tasksToCreate.map(task => (
              <div className="spead-components" key={task.text}>
                {task.text}
                <div className="fixed-column">
                  <Button
                    text="Remove"
                    onClick={() =>
                      this.setState(prevState => {
                        return {
                          tasksToCreate: prevState.tasksToCreate.filter(
                            t => t.text !== task.text
                          ),
                        };
                      })
                    }
                  />
                </div>
              </div>
            ))}
            {this.state.tasksToAdd.map(task => (
              <div className="spead-components" key={task.id}>
                {task.text}
                <div className="fixed-column">
                  <Button
                    text="Remove"
                    onClick={() =>
                      this.setState(prevState => {
                        return {
                          tasksToAdd: prevState.tasksToAdd.filter(
                            t => t.text !== task.text
                          ),
                        };
                      })
                    }
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              intent={Intent.NONE}
              onClick={() => {
                this.setState({ isOpen: false });
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={!canSubmit}
              intent={Intent.PRIMARY}
              onClick={this.onSubmit}
            >
              Submit
            </Button>
          </div>
        </div>
      </Dialog>
    );
  }
}

const options = {
  refetchQueries: [{ query: FetchRiskRegisterDocument }],
};
export const AddScenarioDialog: React.FC<IProps> = props => {
  const [addScenario] = useAddRiskScenarioMutation(options);
  const [updateScenario] = useUpdateRiskScenarioMutation(options);
  return (
    <AddScenario
      {...props}
      addScenario={addScenario}
      updateScenario={updateScenario}
    />
  );
};
