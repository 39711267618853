import { Button, Switch } from "@blueprintjs/core";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import React from "react";
import styled from "styled-components";

import { LogError } from "../../../errors";
import type { TestsPanelDataQuery } from "../../../gen/components";
import {
  TestsPanelDataDocument,
  useReleaseTestMutation,
  useReleaseUniversalTestsStopperMutation,
  useStopTestMutation,
  useStopTestsMutation,
  useTestsPanelDataQuery,
} from "../../../gen/components";
import { FullPageSpinner } from "../../helpers/FullPageSpinner";

gql`
  query TestsPanelData {
    areTestsStoppedUniversally
    internal {
      tests: allAutomatedTestMetadata {
        testId
        killSwitchId
      }
    }
  }

  mutation StopTest($testId: String!) {
    testResultKillSwitchIndividualStop(testResultKey: $testId)
  }

  mutation ReleaseTest($testId: String!) {
    testResultKillSwitchReleaseIndividualStop(testResultKey: $testId)
  }

  mutation StopTests {
    testResultKillSwitchUniversalStop
  }

  mutation ReleaseUniversalTestsStopper {
    testResultKillSwitchReleaseUniversalStop
  }
`;

const Panel = styled.div`
  padding: 2rem 2rem 2rem 0;
`;

const GlobalButton = styled(Button)`
  margin-bottom: 1rem;
  text-transform: uppercase;
`;

export const TestsPanel: React.FC = () => {
  const { data, error, loading } = useTestsPanelDataQuery();
  const [stopTests] = useStopTestsMutation({
    refetchQueries: [{ query: TestsPanelDataDocument }],
  });
  const [releaseTests] = useReleaseUniversalTestsStopperMutation({
    refetchQueries: [{ query: TestsPanelDataDocument }],
  });

  if (loading) {
    return <FullPageSpinner />;
  }

  if (!data || error) {
    if (error) LogError(error);
    return <div>Tests failed to load. {error?.message}</div>;
  }

  return (
    <Panel>
      <h1>Tests</h1>
      <ul>
        <li>
          Disabling an invidual test prevents the test runner from PERSISTING
          DATA from that test. It DOES NOT prevent test runner from running the
          test - the test still runs, it just isn't saved.
        </li>
        <li>
          If you want to prevent the test from being run at all, go to the "Cron
          filters" tab.
        </li>
        <li>
          Disabling globally prevents <b>ALL TEST RUNS</b> from persisting
        </li>
        <li>
          Enabling tests globally does not modify individual test disables
        </li>
      </ul>
      {data.areTestsStoppedUniversally ? (
        <GlobalButton
          intent="success"
          large
          onClick={async () => releaseTests()}
        >
          Release universal tests stopper
        </GlobalButton>
      ) : (
        <GlobalButton intent="danger" large onClick={async () => stopTests()}>
          Stop all tests
        </GlobalButton>
      )}
      {data.internal.tests.map(test => (
        <Test key={test.testId} test={test} />
      ))}
    </Panel>
  );
};

const Test: React.FC<{ test: TestsPanelDataQuery["internal"]["tests"][0] }> =
  props => {
    const { test } = props;

    const [releaseTest] = useReleaseTestMutation({
      refetchQueries: [{ query: TestsPanelDataDocument }],
      variables: { testId: test.testId },
    });
    const [stopTest] = useStopTestMutation({
      refetchQueries: [{ query: TestsPanelDataDocument }],
      variables: { testId: test.testId },
    });

    const onChangeSwitch = async () => {
      if (isSome(test.killSwitchId)) {
        return releaseTest();
      } else {
        return stopTest();
      }
    };

    return (
      <Switch
        checked={!isSome(test.killSwitchId)}
        label={test.testId}
        large
        onChange={onChangeSwitch}
      />
    );
  };
