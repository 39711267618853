import { Icon, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import type { Maybe } from "common/base/types/maybe";
import { isSome, nothing } from "common/base/types/maybe";
import React from "react";

import type { GetVaqAnswersQuery } from "../../gen/components";
import {
  vaqFormListIsNavigable,
  vendorCanSubmitVAQ,
} from "./schemas/vaq-state-helpers";

interface IProps {
  formList: Array<{ id: string; title: string; isCompleted: boolean }>;
  selectedId?: Maybe<string>;
  vaq: NonNullable<GetVaqAnswersQuery["vaqAnswers"]>;
  navigable?: Maybe<boolean>;
  onSelectItem(itemId: string): void;
}

export const VAQSelectableFormList = ({
  formList,
  selectedId,
  onSelectItem,
  vaq,
  navigable,
}: IProps) => {
  const selectedForm = formList.find(form => form.id === selectedId);
  const selectedIndex = isSome(selectedForm)
    ? formList.indexOf(selectedForm)
    : -1;

  const submitIcon = vendorCanSubmitVAQ(vaq) ? (
    <Icon icon={IconNames.SEND_TO} />
  ) : (
    <Icon icon={IconNames.WARNING_SIGN} intent={Intent.WARNING} />
  );
  const submitItem = vaqFormListIsNavigable(vaq) ? (
    <React.Fragment>
      <div className="vaq-list-separator" />
      <div
        onClick={() => onSelectItem("SUBMIT_VAQ")}
        className={
          selectedId === "SUBMIT_VAQ"
            ? "vaq-form-list-selected-item"
            : "vaq-form-list-item"
        }
      >
        {submitIcon}
        Submit answers
      </div>
    </React.Fragment>
  ) : (
    nothing
  );
  return (
    <div className="vaq-sidebar-form-list">
      {formList
        .map((form, index) => {
          const completedIcon = form.isCompleted ? (
            <Icon icon={IconNames.SYMBOL_CIRCLE} intent={Intent.SUCCESS} />
          ) : (
            <Icon icon={IconNames.SYMBOL_CIRCLE} intent={Intent.DANGER} />
          );
          const className =
            selectedIndex === index
              ? "vaq-form-list-selected-item"
              : "vaq-form-list-item";
          return (
            <div
              className={className}
              key={form.id}
              onClick={() => {
                if (navigable ?? true) onSelectItem(form.id);
              }}
            >
              {completedIcon}
              <span>{form.title}</span>
            </div>
          );
        })
        .reduce(
          (accu, elem) =>
            accu.length === 0
              ? [elem]
              : [
                  ...accu,
                  <div
                    key={`separator-${accu.length}`}
                    className="vaq-list-separator"
                  />,
                  elem,
                ],
          Array<JSX.Element>()
        )}

      {navigable ? submitItem : nothing}
    </div>
  );
};
