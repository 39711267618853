import { Feature } from "common/base/types/gen";
import React from "react";

import { FeatureGate } from "../../../../helpers/feature-gating/feature-gate";
import { DefaultLink } from "../../../../helpers/links";
import {
  InfoCalloutContainer,
  InfoCalloutHeading,
  InfoCalloutText,
} from "../common/components";

interface IProps {
  numMonitored: number;
}

export const VulnTableEmptyState: React.FC<IProps> = ({ numMonitored }) => (
  <FeatureGate features={[Feature.LegacyEnableServerAgent]}>
    <InfoCalloutContainer>
      <InfoCalloutHeading>No EC2 instances found</InfoCalloutHeading>
      <InfoCalloutText>
        EC2 instance vulnerabilities surfaced by AWS Inspector will appear here.
      </InfoCalloutText>
      <InfoCalloutText>
        <DefaultLink href="https://aws.amazon.com/premiumsupport/knowledge-center/set-up-amazon-inspector/">
          Learn more about AWS Inspector
        </DefaultLink>{" "}
      </InfoCalloutText>
    </InfoCalloutContainer>
  </FeatureGate>
);
