import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import React, { useContext } from "react";

import { LogError } from "../../errors";
import type { Feature } from "../../gen/components";
import { FeatureContext } from "./context";
import { domainHasFeature } from "./feature-check";

interface IInjectedProps {
  availableFeatures: Feature[];
}

interface IFeatureOptions {
  feature?: Maybe<Feature>;
  features?: Maybe<Feature[]>;
  render?: Maybe<(props: IInjectedProps) => JSX.Element>;
  otherwise?: Maybe<JSX.Element>;
}

export const FeatureGate: React.FunctionComponent<IFeatureOptions> = ({
  feature,
  features,
  render,
  children,
  otherwise,
}) => {
  const featuresToCheck = isSome(feature) ? [feature] : features;
  const featureContext = useContext(FeatureContext);
  if (!isSome(featuresToCheck)) {
    LogError(new Error("No features specified for feature gate"), false);
    return null;
  }
  const availableFeatures = featuresToCheck.filter(featureToCheck =>
    domainHasFeature(featureContext, featureToCheck)
  );

  const hasFeatures = featuresToCheck.every(f => availableFeatures.includes(f));

  if (isSome(render)) {
    return render({ availableFeatures });
  }
  return hasFeatures && isSome(children) ? (
    <React.Fragment>{children}</React.Fragment>
  ) : (
    otherwise ?? null
  );
};
