import React from "react";
import styled from "styled-components";

import { VANTA_COLORS } from "../../../alpaca/base/colors";
import { BodyShortText, H2, HelperText } from "../../../alpaca/components";

interface IProps {
  description: React.ReactNode;
  stepName: string;
  stepIndex: number;
  image: React.ReactNode;
}

export const StepIntroductionPanel: React.FC<IProps> = ({
  description,
  stepName,
  stepIndex,
  image,
}) => (
  <InformationalContainer>
    <ImageContainer>{image}</ImageContainer>
    <HelperText as="div">Step {stepIndex + 1}</HelperText>
    <H2 as="div">{stepName}</H2>
    <BodyShortText color={VANTA_COLORS.TEXT_DESCRIPTION}>
      {description}
    </BodyShortText>
  </InformationalContainer>
);

const styles = {
  CORNER_RADIUS: 6,
  IMAGE_BOTTOM_MARGIN: 24,
  IMAGE_DIMENSION: 64,
  TOP_PADDING: 55,
  SIDE_PADDING: 24,
};

export const ImageContainer = styled.div`
  margin: 0 auto;
  margin-bottom: ${styles.IMAGE_BOTTOM_MARGIN}px;
  height: ${styles.IMAGE_DIMENSION}px;
  width: ${styles.IMAGE_DIMENSION}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  & > *:first-child {
    width: 100%;
    height: 100%;
  }
`;

export const InformationalContainer = styled.div`
  padding: ${styles.TOP_PADDING}px ${styles.SIDE_PADDING}px;
  text-align: center;
  ${BodyShortText} {
    max-width: 70%;
    margin: 0 auto;
  }
  border: 1px solid ${VANTA_COLORS.BORDER_LIGHT};
  border-radius: ${styles.CORNER_RADIUS}px;
`;
