import "./task.scss";

import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import React from "react";
import * as Scroll from "react-scroll";

import type { Task } from "./task";
import { ITaskChildTypes } from "./task";

// we need this bc we need ids for scrolling to an element, but we could have multiple tasklists
let tasklistContainerId = 0;

interface IProps {
  isSequential?: Maybe<boolean>;
  onComplete?(): void;
}

interface IState {
  containerId: number;
  selectedIndex: number;
}

export class TaskList extends React.Component<IProps, IState> {
  public static childContextTypes = ITaskChildTypes;
  public static defaultProps: IProps = {
    isSequential: true,
  };

  public constructor(props: IProps) {
    super(props);

    this.state = {
      containerId: tasklistContainerId++,
      selectedIndex: this.getFirstIncompleteChildIndex(),
    };

    this.onClickChild = this.onClickChild.bind(this);
  }

  public componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (
      this.state.selectedIndex !== prevState.selectedIndex &&
      this.state.selectedIndex !== -1
    ) {
      setTimeout(() => {
        Scroll.scroller.scrollTo(String(this.state.selectedIndex), {
          containerId: "body",
          duration: 250,
          smooth: true,
        });
      }, 350);
    }
  }

  public getChildContext() {
    return {
      onClick: this.onClickChild,
    };
  }

  public getSelectedIndex(): number {
    return this.state.selectedIndex;
  }

  public render() {
    const firstIncompleteChild = this.getFirstIncompleteChildIndex();
    const children = React.Children.map(this.props.children, (child, index) => {
      const expanded = this.state.selectedIndex === index;

      const childAsElem = child as any as Maybe<JSX.Element>;
      if (!isSome(childAsElem)) {
        return undefined;
      }
      const cloned = React.cloneElement(childAsElem, {
        expanded,
        isFirstIncomplete: index === firstIncompleteChild,
        parentIndex: index,
        isSequential: this.props.isSequential,
      });

      return <Scroll.Element name={String(index)}>{cloned}</Scroll.Element>;
    });

    return (
      <div className="tasklist" id={this.getContainerId()}>
        {children}
      </div>
    );
  }

  public selectFirstIncompleteTask() {
    this.setState({
      selectedIndex: this.getFirstIncompleteChildIndex(),
    });
  }

  public setSelectedIndex(index: number) {
    this.setState({
      selectedIndex: index,
    });
  }

  private getContainerId() {
    return `tasklist-${this.state.containerId}`;
  }

  private getFirstIncompleteChildIndex(): number {
    let firstIncompleteChild = -1;
    React.Children.forEach(this.props.children, (child, index) => {
      const childAsTask = child as any as Maybe<Task>;
      if (
        isSome(childAsTask) &&
        !childAsTask.props.completed &&
        firstIncompleteChild === -1
      ) {
        firstIncompleteChild = index;
      }
    });

    return firstIncompleteChild;
  }

  private onClickChild(task: Task) {
    this.setState({
      selectedIndex: !isSome(task.props.parentIndex)
        ? -1
        : task.props.parentIndex,
    });
  }
}
