import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import React, { useContext } from "react";
import styled, { css } from "styled-components";

import { VANTA_COLORS } from "../../../alpaca/base/colors";
import { MaybeProfileImage } from "../../helpers/MaybeProfileImage";
import { UserContext } from "../../pages/user-context";
import { Dimensions } from "../constants";

interface IProps {
  description?: Maybe<string>;
}

export const UserInfo: React.FC<IProps> = ({ description }) => {
  const { user } = useContext(UserContext);
  if (!isSome(user)) {
    return null;
  }
  return (
    <Flex>
      <MaybeProfileImage
        circle={true}
        url={user.imageUrl}
        dimension={34}
        isSuperUser={user.isAssumedSuperUser}
      />
      <InnerFlex>
        <Strong>{user.displayName}</Strong>
        <Muted>{description ?? user.email}</Muted>
      </InnerFlex>
    </Flex>
  );
};

export const UserCompanyInfo: React.FC<IProps> = ({ description }) => {
  const { user } = useContext(UserContext);
  if (!isSome(user)) {
    return null;
  }
  const logoURL = isSome(user.domain.companyLogoSlug)
    ? `/doc?s=${user.domain.companyLogoSlug.slice(
        user.domain.companyLogoSlug.lastIndexOf("-") + 1
      )}`
    : undefined;

  return (
    <Flex>
      <ImageContainer>
        {isSome(user.domain.companyLogoSlug) ? (
          <Img src={logoURL} />
        ) : (
          <CapitalLetter>
            {user.domain.displayName.substring(0, 1)}
          </CapitalLetter>
        )}
      </ImageContainer>
      <InnerFlex>
        <Strong>{user.domain.displayName}</Strong>
        <Muted>{description ?? user.domain.name}</Muted>
      </InnerFlex>
    </Flex>
  );
};

const Flex = styled.div<{ topMargin?: Maybe<number> }>`
  display: flex;
  align-items: center;
  & > *:first-child {
    margin-right: 8px;
  }
  ${({ topMargin }) =>
    isSome(topMargin)
      ? css`
          margin-top: ${topMargin};
        `
      : ""}
`;

export const Muted = styled.div`
  color: ${VANTA_COLORS.TEXT_MUTED};
  line-height: 24px;
`;

const InnerFlex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Strong = styled.div`
  font-weight: 600;
`;

const ImageContainer = styled.div`
  height: ${Dimensions.ICON_DIMENSION}px;
  width: ${Dimensions.ICON_DIMENSION}px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Img = styled.img`
  max-height: ${Dimensions.ICON_DIMENSION}px;
  max-width: ${Dimensions.ICON_DIMENSION}px;
`;

const CapitalLetter = styled.div`
  border-radius: 2px;
  font-weight: 500;
  background-color: purple;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: ${VANTA_COLORS.BACKGROUND_WHITE};
  font-size: 24px;
  line-height: 24px;
  text-transform: uppercase;
`;
