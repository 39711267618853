import { getAccountIdFromArnAndRegions } from "common/resources/specific-resources/aws/aws-helpers-client-safe";
import gql from "graphql-tag";
import React from "react";

import { LogError } from "../../../errors";
import { useGetAccessAccountsQuery } from "../../../gen/components";
import { FullPageSpinner } from "../../helpers/FullPageSpinner";
import type { AccountsAccessCredential, VantaUser } from "./access-page";
import {
  accessPageCredentialToKey,
  getAWSAccountIdFromCredentialMetadata,
} from "./access-page-with-service";
import { AccountsAccessTable } from "./accounts-access-table";

interface IAccessContainerProps {
  credential: AccountsAccessCredential;
  users: VantaUser[];
}

export const AccountsAccessContainer: React.FC<IAccessContainerProps> = ({
  credential,
  users,
}) => {
  const { service } = credential;
  const { error, loading, data } = useGetAccessAccountsQuery({
    variables: { service },
  });

  if (loading) {
    return <FullPageSpinner />;
  }
  if (error || !data?.organization) {
    LogError(error ?? new Error("No domain fetched for access accounts query"));
    return null;
  }
  const domainInfo = data.organization;
  const { serviceAccounts } = domainInfo;
  let selectedServiceAccounts = serviceAccounts;
  if (service === "aws") {
    const awsAccount = getAWSAccountIdFromCredentialMetadata(
      credential.metadata!
    );
    selectedServiceAccounts = selectedServiceAccounts.filter(
      account =>
        awsAccount === getAWSAccountIdFromAwsAccountARN(account.uniqueId!) // aws credentials must have metadata, all new accounts have uniqueId
    );
  }
  let roleColumnName = "";
  switch (service) {
    case "aws": {
      roleColumnName = "IAM Groups";
      break;
    }
    case "jira": {
      roleColumnName = "Groups";
      break;
    }
    case "linear": {
      roleColumnName = "Teams";
      break;
    }
    default: {
      roleColumnName = "Role";
    }
  }
  return (
    <AccountsAccessTable
      credentialKey={accessPageCredentialToKey(credential)}
      serviceAccounts={selectedServiceAccounts}
      service={service}
      roleColumnName={roleColumnName}
      users={users}
    />
  );
};

// 5/10/2020
// Before we build first class support for multiple credentials,
// filter accounts per aws account on the front end as a stop gap.
// These utility functions should be removed when that happens.
export function getAWSAccountIdFromAwsAccountARN(arn: string) {
  if (isResourceFromGovcloud(arn)) {
    return "GovCloud account";
  }
  return getAccountIdFromArnAndRegions(arn, []);
}

function isResourceFromGovcloud(arn: string) {
  // https://docs.aws.amazon.com/general/latest/gr/aws-arns-and-namespaces.html#arns-syntax
  const govCloudPartition = "aws-us-gov";
  return arn.substr(4, govCloudPartition.length) === govCloudPartition;
}

gql`
  query getAccessAccounts($service: String!) {
    organization {
      id
      serviceAccounts(service: $service, includeDeleted: true) {
        id
        accountId
        accountName
        deactivationDate
        externalCreatedAt
        groupList
        isActive
        mfa
        owner {
          id
          displayName
          email
          familyName
          givenName
          hrUser {
            jobTitle
          }
          imageUrl
          isActive
          isContractor
        }
        role
        service
        teamList
        uniqueId
        userId
        userLinkDate
      }
    }
  }
`;
