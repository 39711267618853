import { InputGroup, Intent, Radio, RadioGroup } from "@blueprintjs/core";
import type { SpecificResource } from "common/base/types/gen";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import React, { useState } from "react";
import styled from "styled-components";

import { VANTA_COLORS } from "../../../../alpaca/base/colors";
import { HelperText } from "../../../../alpaca/components";
import { LogError } from "../../../../errors";
import { useSetTargetImageScanTagMutation } from "../../../../gen/components";
import { AppToaster } from "../../../../helpers/toaster";
import { CancelConfirmDialog } from "../../../helpers/CancelConfirmDialog";

const TAG_OPTION = "tag";
const LATEST_OPTION = "latest";

interface IProps {
  configuredTag?: Maybe<string>;
  specificResource: SpecificResource;
  resourceMongoId: string;
  isOpen: boolean;
  onClose(): void;
}

export const ConfigureImageDialog: React.FC<IProps> = ({
  configuredTag,
  specificResource,
  resourceMongoId,
  isOpen,
  onClose,
}) => {
  const [inputTag, setInputTag] = useState(configuredTag ?? "");
  const [selectedOption, setSelectedOption] = useState(
    isSome(configuredTag) ? TAG_OPTION : LATEST_OPTION
  );
  const [saveImageTag, mutationResult] = useSetTargetImageScanTagMutation({
    onCompleted: () => {
      AppToaster.show({
        intent: Intent.SUCCESS,
        message: "Image scanning configuration saved",
      });
      onClose();
    },
  });

  const body = (
    <>
      <p>
        Configure which container image Vanta pulls vulnerabilities from for
        this repository. This page should update within 15 minutes of saving.
      </p>
      <RadioGroup
        selectedValue={selectedOption}
        onChange={e => setSelectedOption(e.currentTarget.value)}
      >
        <Radio label="Latest" value={LATEST_OPTION}></Radio>
        <HelperTextContainer>
          <HelperText color={VANTA_COLORS.TEXT_DESCRIPTION}>
            Vanta will pull vulnerabilities from the latest scanned image
          </HelperText>
        </HelperTextContainer>
        <Radio label="Custom tag" value={TAG_OPTION} />
        <HelperTextContainer>
          <HelperText color={VANTA_COLORS.TEXT_DESCRIPTION}>
            Vanta will pull vulnerabilities from the latest scanned image with a
            tag that contains the specified text
          </HelperText>
        </HelperTextContainer>
      </RadioGroup>
      <InputGroup
        value={inputTag}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setInputTag(e.target.value)
        }
        placeholder={"prod"}
        disabled={selectedOption !== TAG_OPTION}
      />
    </>
  );

  return (
    <CancelConfirmDialog
      body={body}
      confirmText={"Save"}
      title={"Configure image scan settings"}
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={() => {
        saveImageTag({
          variables: {
            specificResourceKind: specificResource,
            resourceMongoId,
            value: selectedOption === TAG_OPTION ? inputTag.trim() : null,
          },
        }).catch(LogError);
      }}
      loading={mutationResult.loading}
      confirmDisabled={selectedOption === TAG_OPTION && inputTag.trim() === ""}
    />
  );
};

const HelperTextContainer = styled.div`
  margin-left: 28px;
  margin-bottom: 12px;
  margin-top: -6px;
`;

gql`
  mutation setTargetImageScanTag(
    $specificResourceKind: SpecificResource!
    $resourceMongoId: String!
    $value: String
  ) {
    setTargetImageScanTagForRepository(
      specificResourceKind: $specificResourceKind
      resourceMongoId: $resourceMongoId
      value: $value
    )
  }
`;
