export const SHARED_DATABASE_TABLE_HEADERS = {
  backups: "Continuous backups",
  creationTime: "Creation time",
  databaseNames: "Database names",
  encrypted: "Encrypted",
  id: "ID",
  name: "Name",
  retentionPeriod: "Retention period (days)",
  status: "Status",
  type: "Database type",
};
