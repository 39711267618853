import { Button, ControlGroup, FormGroup, Intent } from "@blueprintjs/core";
import React, { useState } from "react";

import { StyledInputGroupDeprecated } from "../../../alpaca/base/deprecated";
import type { NewAdminInfo } from "../../../gen/components";

interface IProps {
  onNewAdmin(admin: NewAdminInfo): void;
  domainName: string;
}

export const NewAdminForm: React.FC<IProps> = ({ onNewAdmin, domainName }) => {
  const [email, setEmail] = useState("");
  const [givenName, setGivenName] = useState("");
  const [familyName, setFamilyName] = useState("");
  const clearForm = () => {
    setEmail("");
    setGivenName("");
    setFamilyName("");
  };
  return (
    <div>
      <form>
        <FormGroup label="Email address">
          <ControlGroup style={{ alignItems: "baseline" }}>
            <StyledInputGroupDeprecated
              id="adminEmail"
              placeholder="admin"
              value={email}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setEmail(e.target.value)
              }
            ></StyledInputGroupDeprecated>
            <strong>@{domainName}</strong>
          </ControlGroup>
        </FormGroup>
        <FormGroup label="Given name">
          <StyledInputGroupDeprecated
            id="adminGivenName"
            placeholder="Fedora"
            value={givenName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setGivenName(e.target.value)
            }
          ></StyledInputGroupDeprecated>
        </FormGroup>
        <FormGroup label="Family name">
          <StyledInputGroupDeprecated
            id="adminFamilyName"
            placeholder="Rouge-chapeau"
            value={familyName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFamilyName(e.target.value)
            }
          ></StyledInputGroupDeprecated>
        </FormGroup>
        <Button onClick={() => clearForm()}>Clear form</Button>
        <Button
          disabled={
            email.trim() === "" ||
            givenName.trim() === "" ||
            familyName.trim() === ""
          }
          intent={Intent.PRIMARY}
          type="submit"
          onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
            e.preventDefault();
            onNewAdmin({
              email: `${email.trim()}`,
              givenName: givenName.trim(),
              familyName: familyName.trim(),
            });
            clearForm();
          }}
        >
          Add admin
        </Button>
      </form>
    </div>
  );
};
