import { Intent } from "@blueprintjs/core";
import type { VantaAttribute } from "common/base/types/gen";
import type { Maybe } from "common/base/types/maybe";
import { isSome, nothing } from "common/base/types/maybe";
import { deserializeVantaAttributes } from "common/utils/vantaAttributes";
import React from "react";
import { Tag, Tooltip } from "../../../../alpaca/components";

import {
  getVulnCountTags,
  NotReportingPackagesTag,
} from "../common/components";
import { VulnerabilityPageEntry } from "../common/vulnerability-page-entry";
import { getLastPingString, makeSubheadingString } from "../utils";

interface IProps {
  primaryId?: Maybe<string>;
  secondaryId?: Maybe<string>;
  vulnerabilityCounts: {
    low: number;
    medium: number;
    high: number;
  };
  reportsPackages: Maybe<boolean>;
  slaDeadline?: Maybe<Date>;
  lastPing?: Maybe<string>;
  vantaAttributes?: Maybe<VantaAttribute[]>;
  handleClick: Function;
}

const getOutOfScopeTag = (vantaAttributes: Maybe<VantaAttribute[]>) => {
  if (!isSome(vantaAttributes)) {
    return null;
  }

  const deserializedAttributes = deserializeVantaAttributes(vantaAttributes);
  let tooltipText = null;
  if (isSome(deserializedAttributes.noAlert)) {
    tooltipText = `This server has been tagged to not alert with reason: ${deserializedAttributes.noAlert}`;
  } else if (deserializedAttributes.nonProd ?? false) {
    tooltipText = `This server has been tagged as non-production`;
  } else if (deserializedAttributes.excluded ?? false) {
    tooltipText = `This server has been marked as out of scope.`;
  }

  if (isSome(tooltipText)) {
    return (
      <Tooltip content={tooltipText}>
        <Tag intent={Intent.NONE} text="Out of scope" />
      </Tooltip>
    );
  }
  return null;
};

export const ServerEntry: React.FC<IProps> = ({
  primaryId,
  secondaryId,
  vulnerabilityCounts,
  slaDeadline,
  lastPing,
  reportsPackages,
  vantaAttributes,
  handleClick,
}) => {
  const tags = [];
  const outOfScopeTag = getOutOfScopeTag(vantaAttributes);
  if (isSome(outOfScopeTag)) {
    tags.push(outOfScopeTag);
  }

  const { low, medium, high } = vulnerabilityCounts;
  const vulnCountTags =
    (reportsPackages ?? true) || low + medium + high > 0
      ? getVulnCountTags(vulnerabilityCounts)
      : [<NotReportingPackagesTag key="notReporting" />];
  tags.push(...vulnCountTags);

  const lastPingString = isSome(lastPing)
    ? `last pinged ${getLastPingString(lastPing)}`
    : nothing;

  const secondaryInfo = makeSubheadingString(secondaryId, lastPingString);

  return (
    <VulnerabilityPageEntry
      primaryInfo={primaryId}
      secondaryInfo={secondaryInfo}
      slaDeadline={slaDeadline}
      handleClick={handleClick}
      tags={tags}
    />
  );
};
