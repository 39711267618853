export enum AZURE_LINKING_STEPS {
  SUBSCRIPTIONS = "SUBSCRIPTIONS",
  CREDENTIALS = "CREDENTIALS",
  CERTIFICATE_AND_GRAPH = "CERTIFICATE_AND_GRAPH",
  LINK = "LINK",
}

export enum SUPPORTED_CLOUDS {
  Global = "Global",
  Government = "US Government",
}
