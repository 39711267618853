import { Spinner } from "@blueprintjs/core";
import { isSome } from "common/base/types/maybe";
import {
  specificResourceGraphQLTypeToEnumValue,
  SpecificResourceToInventoryType,
} from "common/utils/inventory";
import {
  deserializeVantaAttributes,
  hasExternallyManagedVantaAttributes,
} from "common/utils/vantaAttributes";
import gql from "graphql-tag";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import type { ComputeInstancesQuery } from "../../../../gen/components";
import {
  useComputeInstancesQuery,
  useSetInventoryResourceVantaAttributeMutation,
} from "../../../../gen/components";
import { InventoryCard } from "../inventory-card";
import {
  AccountTag,
  AutoscaleTag,
  NotRunningTag,
  RegionTag,
  VantaAgentTag,
} from "../tags";
import {
  fetchMoreResources,
  getDebounceContext,
  getResourceFilterParams,
  invalidInventoryType,
  PAGE_SIZE,
  setResourceVantaAttributeFn,
} from "../utils";
import { SearchResultsSummary } from "./search-results-summary";
import type { IInventoryTabProps } from "./shared-interface";

export const Compute: React.FC<IInventoryTabProps> = ({ searchString }) => {
  const { data, fetchMore, loading } = useComputeInstancesQuery({
    variables: {
      first: PAGE_SIZE,
      filterParams: getResourceFilterParams(searchString),
    },
    context: getDebounceContext("compute"),
  });

  if (loading) {
    return <Spinner />;
  }

  const cards = data?.organization.resources.edges?.map(item => (
    <ComputeCard key={item.node.id} item={item} domain={data.organization} />
  ));

  return (
    <>
      <SearchResultsSummary
        searchString={searchString}
        numberResults={data?.organization.resources?.totalCount ?? 0}
      />
      <InfiniteScroll
        className="inventory-list-card-group"
        dataLength={data?.organization.resources.edges.length ?? 0}
        next={async () => fetchMoreResources(data, fetchMore)}
        hasMore={data?.organization.resources.pageInfo.hasNextPage ?? false}
        loader={<Spinner />}
      >
        {cards}
      </InfiniteScroll>
    </>
  );
};

const ComputeCard: React.FC<{
  item: NonNullable<
    ComputeInstancesQuery["organization"]
  >["resources"]["edges"][number];
  domain: NonNullable<ComputeInstancesQuery["organization"]>;
}> = ({ item, domain }) => {
  const [setResourceVantaAttribute] =
    useSetInventoryResourceVantaAttributeMutation();

  const specificResourceKind = specificResourceGraphQLTypeToEnumValue(
    item.node.__typename
  );
  const type = SpecificResourceToInventoryType[specificResourceKind](
    item.node as any
  );
  const resourceMongoId = item.node.id;

  let labels;
  let name;
  let uid;
  switch (item.node.__typename) {
    case "SpecificEC2InstanceResource": {
      labels = [
        <VantaAgentTag key="agent" active={isSome(item.node.osquery)} />,
        <AccountTag key="account" cloudAccountId={item.node.account} />,
        <RegionTag key="region" region={item.node.region} />,
      ];
      if (item.node.state !== "running") {
        labels.push(<NotRunningTag />);
      }
      if (isSome(item.node.autoscaleGroupName)) {
        labels.push(
          <AutoscaleTag
            key="autoscale"
            asgName={item.node.autoscaleGroupName}
          />
        );
      }

      const idWithIp = `${item.node.instanceId} (${
        item.node.publicIPAddress ?? item.node.privateIPAddress
      })`;

      name =
        isSome(item.node.ec2Name) && item.node.ec2Name.length > 0
          ? `${item.node.ec2Name}: ${idWithIp}`
          : idWithIp;

      uid = item.node.instanceId;
      break;
    }
    case "SpecificGCPComputeInstanceResource": {
      labels = [
        // Should add "has vanta agent" and ssh tables once we support it on gcp
        <VantaAgentTag key="agent" active={isSome(item.node.osquery)} />,
        <AccountTag key="account" cloudAccountId={item.node.projectId} />,
        <RegionTag key="region" region={item.node.zone} />,
      ];
      name = item.node.gcpName;
      uid = item.node.instanceId;
      break;
    }
    case "SpecificAzureVirtualMachineResource": {
      labels = [
        <VantaAgentTag key="agent" active={isSome(item.node.osquery)} />,
        <RegionTag key="region" region={item.node.location} />,
      ];
      name = item.node.azureName;
      uid = item.node.instanceId;
      break;
    }
    case "SpecificAzureScaleSetVirtualMachineResource": {
      labels = [
        <VantaAgentTag key="agent" active={isSome(item.node.osquery)} />,
        <RegionTag key="region" region={item.node.location} />,
      ];
      name = item.node.azureName;
      uid = item.node.instanceId;
      break;
    }
    case "SpecificDigitalOceanDropletResource": {
      labels = [<RegionTag key="region" region={item.node.region} />];
      name = item.node.digitalOceanName;
      uid = item.node.instanceId;
      break;
    }
    default:
      return invalidInventoryType(item.node.__typename);
  }

  const vantaAttributes = deserializeVantaAttributes(
    item.node.vantaAttributes ?? []
  );
  const locked = hasExternallyManagedVantaAttributes(
    item.node.vantaAttributes ?? []
  );
  const setVantaAttribute = setResourceVantaAttributeFn(
    setResourceVantaAttribute,
    specificResourceKind,
    resourceMongoId,
    item.node.__typename
  );
  return (
    <InventoryCard
      type={type}
      uid={uid}
      labels={labels}
      key={item.node.id}
      name={name}
      description={vantaAttributes.description}
      owner={vantaAttributes.ownerId}
      canContainUserData={false}
      locked={locked}
      setVantaAttribute={setVantaAttribute}
    />
  );
};

gql`
  query computeInstances(
    $first: Int!
    $after: String
    $filterParams: filterParams
  ) {
    organization {
      id
      name
      resources(
        first: $first
        after: $after
        genericResourceType: ComputeInstance
        sortParams: { field: "createdAt", direction: -1 }
        filterParams: $filterParams
      ) {
        totalCount
        edges {
          node {
            id
            __typename
            uniqueId
            vantaAttributes {
              key
              value
              managedExternally
            }
            ... on SpecificEC2InstanceResource {
              tags {
                key
                value
              }
              region
              account
              instanceId
              state
              ec2Name: name
              publicIPAddress
              privateIPAddress
              autoscaleGroupName
              osquery {
                id
              }
            }
            ... on SpecificGCPComputeInstanceResource {
              zone
              projectId
              instanceId
              gcpName: name
              osquery {
                id
              }
            }
            ... on SpecificAzureVirtualMachineResource {
              instanceId
              azureName: name
              location
              osquery {
                id
              }
            }
            ... on SpecificAzureScaleSetVirtualMachineResource {
              instanceId
              azureName: name
              location
              osquery {
                id
              }
            }
            ... on SpecificDigitalOceanDropletResource {
              instanceId
              digitalOceanName: name
              region
            }
          }
        }
        pageInfo {
          hasNextPage
          endCursor
          startCursor
          hasPreviousPage
        }
      }
    }
  }
`;
