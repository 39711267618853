import type { SpecificCloudWatchMetricAlarmResource } from "common/base/types/gen";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import moment from "moment";

import { UI_DATE_FORMAT } from "../../../../helpers/common";
import { booleanToEvaluationIcon } from "../helpers";

export const awsDataTableParameters = {
  columnOrder: [
    "namespace",
    "name",
    "description",
    "configurationUpdated",
    "actionsEnabled",
    "metricName",
    "periodInSeconds",
    "evaluationPeriodsCount",
    "threshold",
    "comparisonOperator",
  ],
  displayName: "Cloudwatch Alarms",
  header: {
    name: "Alarm Name",
    description: "Description",
    configurationUpdated: "Configuration Last Updated",
    actionsEnabled: "Actions Enabled",
    metricName: "Metric Name",
    namespace: "namespace",
    periodInSeconds: "periodInSeconds (s)",
    evaluationPeriodsCount: "Evaluation periodInSeconds",
    threshold: "threshold",
    comparisonOperator: "Comparison Operator",
  },
  createRow: (alarm: Partial<SpecificCloudWatchMetricAlarmResource>) => {
    return {
      name: alarm.name,
      description: alarm.description,
      actionsEnabled: booleanToEvaluationIcon(alarm.actionsEnabled),
      metricName: alarm.metricName,
      namespace: alarm.namespace,
      periodInSeconds: alarm.periodInSeconds,
      evaluationPeriodsCount: alarm.evaluationPeriodsCount,
      threshold: alarm.threshold,
      comparisonOperator: alarm.comparisonOperator,
      configurationUpdated: isSome(alarm.configurationUpdated)
        ? moment(alarm.configurationUpdated.toString()).format(UI_DATE_FORMAT)
        : "",
    };
  },
};

gql`
  query fetchCloudwatchAlarmInfo {
    organization {
      id
      displayName
      alarms: resources(
        first: 1000
        specificResourceType: CloudWatchMetricAlarm
      ) {
        totalCount
        edges {
          node {
            id
            ... on SpecificCloudWatchMetricAlarmResource {
              name
              description
              actionsEnabled
              metricName
              namespace
              periodInSeconds
              evaluationPeriodsCount
              threshold
              comparisonOperator
              configurationUpdated
            }
          }
        }
      }
    }
  }
`;
