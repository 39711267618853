import {
  Callout,
  FormGroup,
  InputGroup,
  Intent,
  Spinner,
  Switch,
} from "@blueprintjs/core";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import { uniqBy } from "lodash";
import React, { useMemo, useState } from "react";
import styled from "styled-components";

import { BASE_PALETTE } from "../../alpaca/base/colors";
import { GRID_SPACING } from "../../alpaca/base/grid";
import { H2, H4 } from "../../alpaca/components";
import {
  GetTestSelectionInfoDocument,
  MutationStatus,
  useGetTestSelectionInfoQuery,
  useManuallyOptIntoTestsMutation,
  useRemoveManualOptInFromTestsMutation,
} from "../../gen/components";
import { AppToaster } from "../../helpers/toaster";
import { FullPageSpinner } from "../helpers/FullPageSpinner";
import { QueryPanelContainer } from "./query-panel-container";

interface IProps {
  domainId: string;
}

export const TestSelectionPanel: React.FC<IProps> = ({ domainId }) => {
  const [searchString, setSearchString] = useState("");
  const [loadingTestId, setLoadingTestId] = useState("");

  const { loading, data } = useGetTestSelectionInfoQuery({
    variables: { domainId: domainId! },
    skip: !isSome(domainId),
  });

  const [optInToTest] = useManuallyOptIntoTestsMutation({
    refetchQueries: [
      { query: GetTestSelectionInfoDocument, variables: { domainId } },
    ],
    awaitRefetchQueries: true,
    onCompleted: result => {
      const wasSuccess =
        result?.manuallyOptIntoTests?.status === MutationStatus.SUCCESS;
      AppToaster.show({
        message: wasSuccess
          ? `Opted in to ${loadingTestId}`
          : `Failed to opt in to ${loadingTestId}`,
        intent: wasSuccess ? Intent.SUCCESS : Intent.DANGER,
      });
      setLoadingTestId("");
    },
  });
  const [optOutFromTest] = useRemoveManualOptInFromTestsMutation({
    refetchQueries: [
      { query: GetTestSelectionInfoDocument, variables: { domainId } },
    ],
    awaitRefetchQueries: true,
    onCompleted: result => {
      const wasSuccess =
        result?.removeManualOptInFromTests?.status === MutationStatus.SUCCESS;
      AppToaster.show({
        message: wasSuccess
          ? `Opted out from ${loadingTestId}`
          : `Failed to opt out from ${loadingTestId}`,
        intent: wasSuccess ? Intent.SUCCESS : Intent.DANGER,
      });
      setLoadingTestId("");
    },
  });

  const selectedTestIds = useMemo(
    () =>
      new Set(
        (data?.internal.domainById.testSelection ?? []).map(t => t.testId)
      ),
    [data?.internal.domainById.testSelection]
  );

  const testData = useMemo(() => {
    const rawResult = data?.internal.allAutomatedTestMetadata ?? [];
    return uniqBy(rawResult, "testId").sort((t1, t2) =>
      t1.testId.localeCompare(t2.testId)
    );
  }, [data?.internal.allAutomatedTestMetadata]);

  const trimmedSearchString = searchString.trim().toLocaleLowerCase();

  if (loading || !data) {
    return <FullPageSpinner />;
  }

  return (
    <QueryPanelContainer>
      <H2>
        Tests for {data.internal.domainById.name}
        {data.internal.domainById.displayName !== data.internal.domainById.name
          ? ` (${data.internal.domainById.displayName})`
          : ""}
      </H2>
      {/* This callout can be removed once test selectioning is the law of the land */}
      <Callout intent={Intent.DANGER}>
        Do not use this page unless test selectioning is in active use for this
        customer.<br></br> If all the switches below are disabled, don't toggle
        any on unless you know what you are doing!
      </Callout>
      <FormGroup>
        <InputGroup
          type="search"
          value={searchString}
          placeholder="Search by test id or name"
          onChange={e => setSearchString(e.target.value)}
        />
      </FormGroup>
      {testData
        .filter(
          test =>
            trimmedSearchString === "" ||
            test.testId.includes(trimmedSearchString) ||
            test.name?.toLocaleLowerCase().includes(trimmedSearchString)
        )
        .map(test => {
          const domainHasTest = selectedTestIds.has(test.testId);

          return (
            <StyledDiv key={test.testId}>
              <div>
                <H4>{test.testId}</H4>
              </div>
              {loadingTestId === test.testId ? (
                <Spinner size={Spinner.SIZE_SMALL} />
              ) : (
                <Switch
                  large
                  checked={domainHasTest}
                  onChange={async e => {
                    const hasOptedIn = e.currentTarget.checked;
                    setLoadingTestId(test.testId);
                    if (hasOptedIn) {
                      await optInToTest({
                        variables: {
                          domainId,
                          input: { testIds: [test.testId] },
                        },
                      });
                    } else {
                      await optOutFromTest({
                        variables: {
                          domainId,
                          input: { testIds: [test.testId] },
                        },
                      });
                    }
                  }}
                />
              )}
            </StyledDiv>
          );
        })}
    </QueryPanelContainer>
  );
};

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${1 * GRID_SPACING}px 0;
  border-bottom: 1px solid ${BASE_PALETTE.FOG};
`;

gql`
  query GetTestSelectionInfo($domainId: ID!) {
    internal {
      allAutomatedTestMetadata {
        testId
        name
        version {
          major
          minor
        }
      }
      domainById(id: $domainId) {
        id
        displayName
        name
        testSelection {
          testId
        }
      }
    }
  }

  mutation manuallyOptIntoTests(
    $domainId: ID!
    $input: ManuallyOptIntoTestsInput!
  ) {
    manuallyOptIntoTests(domainId: $domainId, input: $input) {
      status
    }
  }

  mutation removeManualOptInFromTests(
    $domainId: ID!
    $input: RemoveManualOptInFromTestsInput!
  ) {
    removeManualOptInFromTests(domainId: $domainId, input: $input) {
      status
    }
  }
`;
