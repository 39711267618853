export enum TableColumnAlignment {
  LEFT,
  CENTER,
  RIGHT,
}

export const TABLE_ALIGNMENT_CLASSES: {
  [k in TableColumnAlignment]: string;
} = {
  [TableColumnAlignment.LEFT]: "vt-align-left",
  [TableColumnAlignment.CENTER]: "vt-align-center",
  [TableColumnAlignment.RIGHT]: "vt-align-right",
};
