import { Intent } from "@blueprintjs/core";
import * as clipboard from "clipboard-polyfill";

import { reportError } from "./components/query-page/reportError";
import { AppToaster } from "./helpers/toaster";

/**
 * Write content to the clipboard and show a toast on success/failure.
 *
 * This may fail in some older browsers.
 * @param content The string to be copied to the clipboard
 */
export const writeToClipboard = async (content: string) => {
  try {
    await clipboard.writeText(content);
  } catch (err) {
    reportError("Copy to clipboard is not supported in your browser.");
    return;
  }
  AppToaster.show({
    icon: "tick",
    intent: Intent.SUCCESS,
    message: "Successfully copied to clipboard",
    timeout: 1000,
  });
};

/**
 * STOP -- don't upgrade react-router before reading this
 *
 * Passing an encoded uri component into react-router's "history"
 * (Or react-rounter-dom's "link", which internally calls history)
 * attempts to decode the URI component. This is a bug that's fixed in the next major
 * release (see https://github.com/ReactTraining/history/issues/505) and when we upgrade to that release,
 * we should delete this helper function and replace it with normal encodeURIComponent.
 *
 * In the meantime, oof.
 * @param component the string to double-encode
 */
export const doubleEncodeURIComponent = (component: string) =>
  encodeURIComponent(encodeURIComponent(component));
