import React from "react";

import type { GetRoleNamesQuery } from "../../../gen/components";

type Groups = NonNullable<GetRoleNamesQuery["organization"]>["roles"];

interface IContext {
  groups: Groups;
}

export const GroupsContext = React.createContext<IContext>({ groups: [] });
