import { Intent } from "@blueprintjs/core";
import { parse } from "json2csv";
import moment from "moment";
import React, { useContext, useMemo, useState } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";

import { BodyText, Menu, MenuButton } from "../../../alpaca/components";
import { LogError } from "../../../errors";
import { useSendReminderEmailMutation } from "../../../gen/components";
import { FILE_TIMESTAMP_FORMAT } from "../../../helpers/common";
import { DefaultLink } from "../../../helpers/links";
import { AppToaster } from "../../../helpers/toaster";
import { Card } from "../components/card";
import { CSVExportButton } from "../components/csv-export-button";
import { COLUMN_CLASSES, DataTable } from "../components/data-table";
import { getAgentInstallationReminderMenuItems } from "../people/user-drawer/email-alert-button";
import {
  TAB_IDS,
  UserDetailDrawer,
} from "../people/user-drawer/user-detail-drawer";
import { groupForUser } from "../people/utils";
import { UserContext } from "../user-context";
import { InstallationInstructions } from "./installation-instructions";
import { UserProfile } from "./user-profile";
import type { UserFromFetchDomainEndpointsQuery } from "./utils";
import { COMPUTERS_PAGE_TAB_IDS } from "./utils";

const COLUMN_ORDER = ["user", "actions"];
const COLUMN_WIDTHS = ["940px", "60px"];

const InstallationInstructionsContainer = styled.div`
  margin: 12px;
  margin-top: 36px;
`;

interface IProps {
  usersWithoutWorkstations: UserFromFetchDomainEndpointsQuery[];
}
export const UsersWithoutComputersView: React.FC<IProps> = ({
  usersWithoutWorkstations,
}) => {
  const history = useHistory();
  const [searchString, setSearchString] = useState("");
  const { user: sendingUser } = useContext(UserContext);
  const [sendReminder] = useSendReminderEmailMutation({
    onCompleted() {
      AppToaster.show({
        message: "Email sent",
        intent: Intent.SUCCESS,
      });
    },
  });

  const trimmedSearch = searchString.trim().toLocaleLowerCase();
  const filteredUsers = useMemo(() => {
    if (trimmedSearch === "") {
      return usersWithoutWorkstations;
    } else {
      return usersWithoutWorkstations.filter(user =>
        user.displayName?.toLocaleLowerCase().includes(trimmedSearch)
      );
    }
  }, [searchString, usersWithoutWorkstations]);

  function getActionsButton(user: UserFromFetchDomainEndpointsQuery) {
    const menuItems = getAgentInstallationReminderMenuItems(user, flag => {
      sendReminder({
        variables: {
          userId: user.id,
          flag,
          domainId: sendingUser!.domain.id,
        },
      }).catch(LogError);
    });

    // Outer div prevents the user drawer from opening on click.
    return (
      <div
        onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) =>
          e.stopPropagation()
        }
      >
        <MenuButton menu={<Menu>{menuItems}</Menu>} small />
      </div>
    );
  }

  return (
    <>
      <Card>
        <DataTable
          useDefaultStyling
          stickyHeaders
          columnOrder={COLUMN_ORDER}
          columnWidths={COLUMN_WIDTHS}
          columnClasses={{
            actions: COLUMN_CLASSES.CENTER_ALIGN,
          }}
          createRow={user => {
            return {
              user: (
                <UserProfile
                  imageUrl={user.imageUrl}
                  primaryText={user.displayName}
                  secondaryText={user.email}
                />
              ),
              actions: getActionsButton(user),
            };
          }}
          customControls={{
            rightControls: [
              <CSVExportButton
                key="unmonitored-csv"
                disabled={filteredUsers.length === 0}
                data={
                  filteredUsers.length > 0
                    ? parse(
                        filteredUsers.map(user => {
                          return {
                            "User ID": user.id,
                            "Name": user.displayName,
                            "Email": user.email,
                            "Group": groupForUser(user),
                            "Device ID": "N/A",
                          };
                        })
                      )
                    : ""
                }
                filename={`Computers-unmonitored-${moment().format(
                  FILE_TIMESTAMP_FORMAT
                )}.csv`}
              />,
            ],
          }}
          controlledSearchParams={{
            searchString,
            onNewSearchString: setSearchString,
          }}
          data={filteredUsers}
          emptyDefault={
            <BodyText>
              All users have monitored computers! Head to the{" "}
              <DefaultLink
                href={`/computers?tab=${COMPUTERS_PAGE_TAB_IDS.MONITORED}`}
              >
                Monitored
              </DefaultLink>{" "}
              tab to view their details.
            </BodyText>
          }
          onRowClick={user => {
            const userId = user.id;
            history.push(
              `${window.location.pathname}?tab=${COMPUTERS_PAGE_TAB_IDS.UNMONITORED}&userId=${userId}`
            );
          }}
          paginate={{ paginationId: "users-without-computers-mtm" }}
        />
      </Card>
      <InstallationInstructionsContainer>
        <InstallationInstructions />
      </InstallationInstructionsContainer>
      <UserDetailDrawer initialTab={TAB_IDS.COMPUTERS} />
    </>
  );
};
