import type {
  ApolloQueryResult,
  FetchMoreOptions,
  FetchMoreQueryOptions,
} from "@apollo/client/core";
import { Button, ButtonGroup, Icon, Menu, MenuItem } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Popover2 } from "@blueprintjs/popover2";
import type { PageInfo } from "common/base/types/gen";
import type { Maybe } from "common/base/types/maybe";
import { isSome, nothing } from "common/base/types/maybe";
import React, { useEffect } from "react";
import styled from "styled-components";

import { BASE_PALETTE } from "../../alpaca/base/colors";

const ITEMS_PER_PAGE_OPTIONS = [10, 20, 50, 100];
export const DEFAULT_ITEMS_PER_PAGE_OPTION = ITEMS_PER_PAGE_OPTIONS[2];

export const applyPaginationParams = <T,>(
  data: T[],
  params: PaginationParams
): T[] =>
  data.slice(
    params.firstItemIndex,
    params.firstItemIndex + params.itemsPerPage
  );

// Parameters that are passed into the GraphQL fetchMore function.
export type FetchMoreFn = <T>(
  options: FetchMoreOptions &
    FetchMoreQueryOptions<
      {
        first?: Maybe<number>;
        last?: Maybe<number>;
        before?: Maybe<string>;
        after?: Maybe<string>;
      },
      "after" | "first" | "before" | "last"
    >
) => Promise<ApolloQueryResult<T>>;

export type PaginationParams = {
  // The index of the first item on the page, i.e. "The first item on this page is the nth item of the entire collection."
  firstItemIndex: number;

  // The number of items to try to load on each page.
  itemsPerPage: number;

  // Total items fetched so far
  itemsFetched: number;

  loading: boolean;
};

export const getInitialPaginationParams = (
  itemsPerPage: number = DEFAULT_ITEMS_PER_PAGE_OPTION,
  itemsFetched?: Maybe<number>
): PaginationParams => {
  if (!isSome(itemsFetched)) {
    itemsFetched = itemsPerPage;
  }
  return {
    firstItemIndex: 0,
    itemsPerPage,
    itemsFetched,
    loading: false,
  };
};

export interface IPaginatorProps {
  // PageInfo returned from the initial GraphQL query. Used to call fetchMore appropriately.
  pageInfo: Maybe<PageInfo>;
  // The number of items being displayed on page currently.
  // Note this is *different* than itemsPerPage: itemsPerPage is the number to try to load, whereas itemsLoaded is the number returned.
  // ex. I might ask for 10 items but only get 3 back if it's the last page of the collection.
  itemsLoaded: number;

  // The total number of items in the collection. Used to decide whether to allow pagination and display in the UI
  totalItems: number;

  // The current paginationParams, and a callback to set new ones based on how this element is clicked.
  paginationParams: PaginationParams;
  setPaginationParams: (params: PaginationParams) => void;

  // the fetchMore function from the GraphQL call. Might be called for more results.
  fetchMore: Maybe<FetchMoreFn>;

  // Font size for label. Default is 14px
  labelFontSize?: Maybe<number>;
  // Color for label. Default is #333 (global default text color)
  labelColor?: Maybe<string>;
  // Used for saving pagination preference in localstorage
  paginationId: `paginator-${string}`;
}

export const GenericPaginator: React.FC<IPaginatorProps> = ({
  pageInfo,
  itemsLoaded,
  totalItems,
  paginationParams,
  setPaginationParams,
  fetchMore,
  labelFontSize,
  labelColor,
  paginationId,
}) => {
  const setRowsPerPage = (nRows: number) => {
    localStorage.setItem(paginationId, nRows.toString());
    setPaginationParams(
      getInitialPaginationParams(nRows, paginationParams.itemsFetched)
    );
  };
  const { firstItemIndex, itemsPerPage, loading } = paginationParams;

  useEffect(() => {
    const storedRowsPerPage = localStorage.getItem(paginationId);
    if (isSome(storedRowsPerPage)) {
      const rowsPerPage = +storedRowsPerPage;
      if (rowsPerPage !== itemsPerPage) {
        setRowsPerPage(rowsPerPage);
      }
    }
  }, []);

  const start = itemsLoaded > 0 ? firstItemIndex + 1 : 0;
  const end = Math.min(firstItemIndex + itemsLoaded, totalItems);
  const label = loading
    ? `... of ${totalItems}`
    : `${start}-${end} of ${totalItems}`;

  const menu = (
    <Menu>
      {ITEMS_PER_PAGE_OPTIONS.map(count => (
        <MenuItem
          key={`menu-${count}`}
          text={`Show ${count} items`}
          onClick={() => {
            setRowsPerPage(count);
          }}
        />
      ))}
    </Menu>
  );

  return (
    <PaginateMenu>
      <Popover2 content={menu} placement={"bottom"} minimal={true}>
        <Label fontSize={labelFontSize} fontColor={labelColor}>
          {label}
        </Label>
      </Popover2>

      <ButtonGroup>
        <Button
          disabled={paginationParams.firstItemIndex === 0 || Boolean(loading)}
          onClick={() => {
            setPaginationParams({
              firstItemIndex: firstItemIndex - itemsPerPage,
              itemsPerPage,
              itemsFetched: paginationParams.itemsFetched,
              loading: false,
            });
          }}
          icon={
            <Icon icon={IconNames.CHEVRON_LEFT} iconSize={Icon.SIZE_LARGE} />
          }
          style={{ height: 36, width: 36 }}
        />
        <Button
          disabled={
            (paginationParams.firstItemIndex + paginationParams.itemsPerPage ??
              DEFAULT_ITEMS_PER_PAGE_OPTION) > totalItems || Boolean(loading)
          }
          onClick={async () => {
            let itemsFetched = paginationParams.itemsFetched;
            if (isSome(fetchMore)) {
              // fetchMore is silly and skips the cache.
              // https://github.com/apollographql/apollo-client/issues/7430#issuecomment-741884206
              // so only call fetchMore if we need to.
              const nextItemNeeded =
                paginationParams.firstItemIndex +
                  paginationParams.itemsPerPage ??
                DEFAULT_ITEMS_PER_PAGE_OPTION;
              if (itemsFetched <= nextItemNeeded) {
                setPaginationParams({
                  ...paginationParams,
                  loading: true,
                });
                await fetchMore({
                  variables: {
                    after: pageInfo?.endCursor,
                    first: itemsPerPage,
                    last: nothing,
                  },
                });
                itemsFetched += itemsPerPage;
              }
            }
            setPaginationParams({
              firstItemIndex: firstItemIndex + itemsPerPage,
              itemsPerPage,
              itemsFetched,
              loading: false,
            });
          }}
          icon={
            <Icon icon={IconNames.CHEVRON_RIGHT} iconSize={Icon.SIZE_LARGE} />
          }
          style={{ height: 36, width: 36 }}
        />
      </ButtonGroup>
    </PaginateMenu>
  );
};

const Label = styled.p<{ fontSize?: Maybe<number>; fontColor?: Maybe<string> }>`
  margin: 9px 12px;
  font-size: ${({ fontSize }) => fontSize ?? 14}px;
  ${({ fontColor }) => (isSome(fontColor) ? `color: ${fontColor};` : "")}
  text-align: center;
  &:hover {
    text-decoration: underline;
    color: ${BASE_PALETTE.INK};
    cursor: pointer;
  }
`;

const PaginateMenu = styled.div`
  display: flex;
  align-items: center;
`;
