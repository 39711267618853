import type { Maybe } from "common/base/types/maybe";
import { PREVIEW_HOSTNAME } from "common/constants/client-safe";
import React from "react";

import { LoginButton } from "../login";
import { GoogleIcon } from "./icon";

export const GoogleLoginButton: React.FC<{ loginLink?: Maybe<string> }> = ({
  loginLink,
}) => {
  const hostname = `${location.protocol}//${location.host}`;
  return PREVIEW_HOSTNAME.test(hostname) ? (
    <LoginButton text={"Use 'Sign in with email' instead"} Icon={GoogleIcon} />
  ) : (
    <LoginButton
      text={"Sign in with Google"}
      Icon={GoogleIcon}
      loginLink={loginLink}
    />
  );
};
