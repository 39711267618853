import type { Maybe } from "common/base/types/maybe";

import React from "react";
import styled from "styled-components";
import { BASE_PALETTE, VANTA_COLORS } from "../../../../alpaca/base/colors";
import { GRID_SPACING } from "../../../../alpaca/base/grid";
import {
  BodyText,
  EmptyStateCard,
  HelperText,
} from "../../../../alpaca/components";
import { DefaultLink } from "../../../../helpers/links";
import type { PolicyWizardError } from "../beta-policies-wizard-page";

import {
  PoliciesCondensedCardContent,
  PoliciesH5,
} from "../policies-v2-styles";

export const PoliciesWizardEmployeeAcceptanceStep: React.FC<{
  employeeGroups: string[];
  errors?: Maybe<PolicyWizardError[]>;
}> = ({ employeeGroups, errors }) => (
  <StyledCardContent>
    <PoliciesH5>Employee groups subject to policy acceptance</PoliciesH5>
    <EmployeeGroupList groups={employeeGroups} />
    <BodyText color={BASE_PALETTE.SMOKE}>
      To see or update policy acceptance settings for your employee groups, go
      to{" "}
      <DefaultLink href="/checklists/settings">onboarding settings</DefaultLink>
      .
    </BodyText>
    <HelperText color={VANTA_COLORS.VANTA_ALERT}>
      {errors?.[0]?.message}
    </HelperText>
  </StyledCardContent>
);

function EmployeeGroupList(props: { groups: string[] }) {
  const listItems = props.groups.map(group => <li key={group}>{group}</li>);
  return listItems.length === 0 ? (
    <EmptyStateCard
      mainText={"No employee groups"}
      style={{ margin: 3 * GRID_SPACING }}
    />
  ) : (
    <BodyText
      color={BASE_PALETTE.SMOKE}
      style={{ textAlign: "left", marginLeft: GRID_SPACING }}
    >
      <ul>{listItems}</ul>
    </BodyText>
  );
}

const StyledCardContent = styled(PoliciesCondensedCardContent)`
  text-align: center;
`;
