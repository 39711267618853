import type { ReportStandard } from "common/base/types/gen";
import type { Maybe } from "common/base/types/maybe";
import React, { useContext } from "react";

import type { UserContextQuery } from "../../gen/components";

interface IContext {
  domainDisplayName: string;
  domainStandards: ReportStandard[];
  userDisplayName: Maybe<string>;
  user: UserContextQuery["user"];
  error: Maybe<Error>;
}

export const UserContext = React.createContext<IContext>({
  domainDisplayName: "",
  domainStandards: [],
  userDisplayName: "",
  user: null,
  error: null,
});

export function useIsUserAuditorCheck(): boolean {
  const userContext = useContext(UserContext);
  return Boolean(userContext.user?.isAuditorAssumingUser);
}
