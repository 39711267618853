import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import moment from "moment";
import React, { useContext, useMemo, useState } from "react";

import { UI_DATE_FORMAT_WITHOUT_TIME } from "../../../helpers/common";
import { Ellipsify } from "../../../helpers/ellipsify";
import {
  compareNullableDates,
  compareNullableStrings,
  lastNameSort,
} from "../../../helpers/user-sort-functions";
import type { DataTableFilter } from "../components/data-table";
import { COLUMN_CLASSES, DataTable } from "../components/data-table";
import { DropdownButton } from "../components/dropdown-button";
import { HrServicesContext } from "../people/hr-services-context";
import { NotApplicable } from "../people/shared/not-applicable";
import { AccessOwner } from "./access-components/access-owner";
import { MaybeBooleanToIcon } from "./access-components/maybe-boolean-to-icon";
import {
  AccountDeactivated,
  FILTER_BUTTON_WIDTH,
  InfoNotAvailable,
  StyledRoleTagList,
  TableEmptyDefault,
} from "./access-components/styles";
import { ACCESS_PAGE_DEFAULT_PAGE_SIZE } from "./constants";
import { CredentialsContext } from "./credentials-context";
import { ServiceDropdown } from "./service-dropdown";
import type { AccessUser } from "./users-access-container";

interface IAccessTableProps {
  users: AccessUser[];
  credentialKey: string;
}

const ACCOUNTS_COLUMN_ORDER = [
  "email",
  "owner",
  "jobTitle",
  "role",
  "status",
  "mfa",
  "creationTime",
  "deactivatedAt",
];
const ACCOUNTS_COLUMN_HEADERS = {
  email: "Account name",
  owner: "Owner",
  jobTitle: "Job title",
  status: "Status",
  role: "Role",
  mfa: "MFA",
  creationTime: "Created",
  deactivatedAt: "Deactivated",
};

const TABLE_COLUMN_WIDTHS = [
  "250px",
  "175px",
  "175px",
  "150px",
  "125px",
  "125px",
  "150px",
  "150px",
];

const CUSTOM_FILTERS: Array<DataTableFilter<AccessUser>> = [
  ["Active accounts", user => !isSome(user.idp.deactivatedAt)],
  ["Deactivated accounts", user => isSome(user.idp.deactivatedAt)],
  [
    "MFA not enabled",
    user => !isSome(user.idp.deactivatedAt) && user.idp.hasMFA === false,
  ],
];

type UserSortFn = (u1: AccessUser, u2: AccessUser) => number;
const COLUMN_SORT_FUNCTIONS: { [k: string]: UserSortFn } = {
  email: (u1, u2) => u1.email.localeCompare(u2.email),
  creationTime: (u1, u2) =>
    compareNullableDates(u1.idp.creationTime, u2.idp.creationTime),
  deactivatedAt: (u1, u2) =>
    compareNullableDates(u1.idp.deactivatedAt, u2.idp.deactivatedAt),
  mfa: (u1, u2) => mfaSortValue(u1) - mfaSortValue(u2),
  owner: (u1, u2) => lastNameSort(u1, u2),
  role: (u1, u2) => getRoleString(u1).localeCompare(getRoleString(u2)),
  status: (u1, u2) => getStatusString(u1).localeCompare(getStatusString(u2)),
  jobTitle: (u1, u2) =>
    compareNullableStrings(u1.hrUser?.jobTitle, u2.hrUser?.jobTitle),
};

const userMatchesSearchString = (user: AccessUser, searchString: string) => {
  const lowerCased = searchString.trim().toLocaleLowerCase();
  return (
    user.email.toLocaleLowerCase().includes(lowerCased) ||
    user.displayName!.toLocaleLowerCase().includes(lowerCased) ||
    (user.hrUser?.jobTitle ?? "").toLocaleLowerCase().includes(lowerCased)
  );
};

function getStatusString(user: AccessUser) {
  return isSome(user.idp.deactivatedAt) ? "Deactivated" : "Active";
}

function getRoleString(user: AccessUser) {
  return isSome(user.idp.isAdmin) ? (user.idp.isAdmin ? "Admin" : "") : "NA";
}

function mfaSortValue(user: AccessUser) {
  if (!isSome(user.idp.deactivatedAt) && isSome(user.idp.hasMFA)) {
    return user.idp.hasMFA ? 2 : 1;
  }
  return 0;
}

export const UsersAccessTable: React.FC<IAccessTableProps> = ({
  credentialKey,
  users,
}) => {
  const { linkedHrServices } = useContext(HrServicesContext);
  const { credentials, defaultCredential } = useContext(CredentialsContext);

  const [selectedFilter, setSelectedFilter] = useState<Maybe<string>>(null);
  const filteredUsers = useMemo(() => {
    const maybeFilter = CUSTOM_FILTERS.find(f => f[0] === selectedFilter);
    if (!isSome(maybeFilter)) {
      return users;
    } else {
      return users.filter(maybeFilter[1]);
    }
  }, [users, selectedFilter]);

  const filterElement = (
    <DropdownButton
      key="filter-dropdown"
      options={CUSTOM_FILTERS.map(filter => filter[0])}
      selectedOption={selectedFilter}
      onOptionSelect={filter => {
        setSelectedFilter(filter);
      }}
      optionRenderer={filter => filter}
      defaultText={"All accounts"}
      buttonWidth={FILTER_BUTTON_WIDTH}
      menuWidth={FILTER_BUTTON_WIDTH}
      styleOnSelect
    />
  );

  const getRowForUser = (user: AccessUser) => {
    const { email, idp } = user;
    const { creationTime, deactivatedAt, hasMFA, isAdmin } = idp!;

    let role: string | JSX.Element = "";
    if (!isSome(isAdmin)) {
      role = <InfoNotAvailable />;
    } else if (isAdmin) {
      role = <StyledRoleTagList tags={["Admin"]} />;
    }

    const jobTitle = user.hrUser?.jobTitle;
    return {
      creationTime: isSome(creationTime)
        ? moment(creationTime).format(UI_DATE_FORMAT_WITHOUT_TIME)
        : undefined,
      email: <Ellipsify text={email} />,
      deactivatedAt: isSome(deactivatedAt) ? (
        moment(deactivatedAt).format(UI_DATE_FORMAT_WITHOUT_TIME)
      ) : (
        <NotApplicable />
      ),
      jobTitle: isSome(jobTitle) ? (
        <Ellipsify text={jobTitle} />
      ) : (
        <NotApplicable />
      ),
      mfa: !isSome(deactivatedAt) ? (
        <MaybeBooleanToIcon bool={hasMFA} />
      ) : (
        <AccountDeactivated />
      ),
      owner: <AccessOwner owner={user} />,
      role,
      status: getStatusString(user),
    };
  };

  return (
    <DataTable
      useDefaultStyling
      stickyHeaders
      paginate={{
        paginationId: "access-users",
        defaultPageSize: ACCESS_PAGE_DEFAULT_PAGE_SIZE,
      }}
      customControls={{
        leftControls: [
          <ServiceDropdown
            key="service-dropdown"
            credentials={credentials}
            defaultCredential={defaultCredential}
            onSelect={() => setSelectedFilter(null)}
          />,
          filterElement,
        ],
      }}
      columnOrder={ACCOUNTS_COLUMN_ORDER}
      columnSortFunctions={COLUMN_SORT_FUNCTIONS}
      columnClasses={{
        creationTime: COLUMN_CLASSES.CENTER_ALIGN,
        deactivatedAt: COLUMN_CLASSES.CENTER_ALIGN,
        mfa: COLUMN_CLASSES.CENTER_ALIGN,
      }}
      columnWidths={TABLE_COLUMN_WIDTHS}
      columnVisibilities={{ jobTitle: linkedHrServices.size > 0 }}
      defaultSortColumn={"email"}
      header={ACCOUNTS_COLUMN_HEADERS}
      data={filteredUsers}
      createRow={getRowForUser}
      resetTableStateKey={credentialKey}
      searchFilter={userMatchesSearchString}
      emptyDefault={TableEmptyDefault}
    />
  );
};
