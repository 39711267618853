import "./manage-vendors.scss";

import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import { PCI_STANDARD_SET } from "common/standards/pci";
import { capitalize } from "lodash";
import React, { useContext, useState } from "react";

import { AccordionRow, Tag, Tooltip } from "../../../alpaca/components";
import type { GetVendorsQuery } from "../../../gen/components";
import { ReportStandard } from "../../../gen/components";
import { UserContext } from "../user-context";

interface IProps {
  vendor: NonNullable<GetVendorsQuery["organization"]>["vendors"][number];
  deleteHandler(): void;
  startOpen?: Maybe<boolean>;
}

export const VendorTableRow: React.FC<IProps> = ({
  children,
  vendor,
  deleteHandler,
  startOpen,
}) => {
  const [isOpen, setIsOpen] = useState(Boolean(startOpen));
  const { domainStandards } = useContext(UserContext);
  const hasHipaaFeature = domainStandards.includes(ReportStandard.hipaa);
  const hasPciFeature = domainStandards.some(s => PCI_STANDARD_SET.has(s));

  const onDeleteClicked = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    deleteHandler();
  };

  const vendorNeedsSecurityAssessment =
    vendor.severity === "high" &&
    !isSome(vendor.assessmentFile) &&
    vendor.submittedVAQs.length === 0;
  const vendorNeedsBAA =
    hasHipaaFeature && Boolean(vendor.sharesEPHI) && !isSome(vendor.baaFile);
  const vendorNeedsAttestationOfCompliance =
    hasPciFeature &&
    vendor.severity === "high" &&
    !isSome(vendor.attestationOfComplianceFile);
  const severityIndicator =
    vendorNeedsSecurityAssessment ||
    vendorNeedsBAA ||
    vendorNeedsAttestationOfCompliance ? (
      <Tooltip
        key="sev-indicator"
        content={
          <div>
            {vendorNeedsSecurityAssessment ? (
              <p>
                Add security documents or an assessment for high severity
                vendors.
              </p>
            ) : null}
            {vendorNeedsBAA ? (
              <p>
                Add a business association agreement for vendors handling ePHI.
              </p>
            ) : null}
            {vendorNeedsAttestationOfCompliance ? (
              <p>
                Add an Attestation of Compliance, which serves as a declaration
                of compliance status with PCI DSS
              </p>
            ) : null}
          </div>
        }
        placement="top-start"
      >
        <Tag text="Needs document" intent={Intent.DANGER} />
      </Tooltip>
    ) : null;

  return (
    <AccordionRow
      stickyTitle
      controlledParams={{ isOpen, onChange: setIsOpen }}
      title={vendor.name}
      rightElements={[
        severityIndicator,
        <Tag
          key="tag"
          text={capitalize(vendor.severity)}
          intent={VENDOR_SEVERITY_TO_INTENT[vendor.severity] ?? Intent.NONE}
        />,
        <Button
          minimal
          key="delete"
          icon={IconNames.TRASH}
          large={true}
          onClick={onDeleteClicked}
        />,
      ]}
    >
      {children}
    </AccordionRow>
  );
};

const VENDOR_SEVERITY_TO_INTENT: { [k: string]: Maybe<Intent> } = {
  high: Intent.DANGER,
  medium: Intent.WARNING,
};
