import { Intent } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { keyBy } from "lodash";
import React from "react";
import styled from "styled-components";

import { BASE_PALETTE, VANTA_COLORS } from "../../../../alpaca/base/colors";
import { GRID_SPACING } from "../../../../alpaca/base/grid";
import { BodyText, HelperText } from "../../../../alpaca/components";
import type { IFormControlValue } from "../../../forms/interfaces";
import { SchemaFormControl } from "../../../forms/schema-form-control";
import type { IPolicyQuestionSchema } from "../../info/policies/policyDocSchema";
import type {
  AnswersMap,
  PolicyWizardError,
} from "../beta-policies-wizard-page";
import {
  PoliciesCondensedCardContent,
  PoliciesH5,
} from "../policies-v2-styles";

export const PoliciesWizardQuestionnaireStep: React.FC<{
  questionSchema: IPolicyQuestionSchema;
  questionnaireAnswers: AnswersMap;
  setQuestionnaireAnswers: (
    questionName: string
  ) => (newValue: IFormControlValue) => void;
  errors?: Maybe<PolicyWizardError[]>;
}> = ({
  questionSchema,
  questionnaireAnswers,
  setQuestionnaireAnswers,
  errors,
}) => {
  const errById = keyBy(errors, err => err.id);
  return (
    <>
      <StyledHeader>
        <PoliciesH5> Answer questions </PoliciesH5>
        <BodyText color={BASE_PALETTE.SMOKE}>
          Answer the following questions about the content included in your
          policy so that Vanta can continuously monitor your security and
          compliance according to your practices.
        </BodyText>
      </StyledHeader>

      <StyledCardMainContent>
        {questionSchema.questions.map(question => {
          const currentValue = questionnaireAnswers[question.name];
          return (
            <SchemaFormControl
              intent={Intent.NONE}
              key={question.id}
              question={question}
              currentValue={currentValue}
              onNewValue={setQuestionnaireAnswers(question.name)}
            >
              <HelperText color={VANTA_COLORS.VANTA_ALERT}>
                {errById[question.name]?.message}
              </HelperText>
            </SchemaFormControl>
          );
        })}
      </StyledCardMainContent>
    </>
  );
};

const StyledHeader = styled.div`
  text-align: center;
  padding-bottom: ${GRID_SPACING};
`;

const StyledCardMainContent = styled(PoliciesCondensedCardContent)`
  // overrides for bp3 styles
  & .bp3-html-select {
    width: 100%;
  }

  & .business-info .bp3-form-content {
    width: 100%;
  }

  & .bp3-form-helper-text {
    width: 100%;
  }
  // aligns checkboxes with other form elements
  & .bp3-control:not(.bp3-align-right) {
    margin-left: 0;
  }
`;
