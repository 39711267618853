import type { Maybe } from "common/base/types/maybe";

import type { ReportStandard } from "../../gen/components";
import { Feature } from "../../gen/components";
import type { IFeatureContext } from "../../helpers/feature-gating/context";
import { domainHasFeature } from "../../helpers/feature-gating/feature-check";
import { CHECKLIST_PAGE_ROOT_PATH } from "../pages/checklists/gql-and-types";
import { PATHS } from "./paths";
import { UserCompanyInfo, UserInfo } from "./top-bar/user-info";

export interface ILink {
  text: string;
  path: string;
  feature?: Maybe<Feature>;
}

export interface IMenuOption {
  link: ILink;
  auditorOnly?: Maybe<boolean>;
  subMenu?: Maybe<ILink[]>;
}

const makeLink: (
  text: string,
  path: string,
  feature?: Maybe<Feature>
) => ILink = (text, path, feature) => {
  return {
    text,
    path,
    feature,
  };
};

const AccountAccess = makeLink("Access", "/access");
const Alarms = makeLink("Alarms", "/alarms");
const AuditorDashboard = makeLink("Dashboard", "/");
const Audits = makeLink("Audits", "");
const AuditScheduler = makeLink("Audits", "/audit-schedule");
const Billing = makeLink("Billing", "/billing");
const Changes = makeLink("Code changes", "/changes");
const CompanyInfo = makeLink("Company Info", "/business-information");
const CompanyNotifications = makeLink(
  "Notifications",
  "/company-notifications",
  Feature.AutoEmployeeEmails
);
const Computers = makeLink("Computers", "/computers");
const Connections = makeLink("Connections", "/connections");
const Databases = makeLink("Databases", "/databases");
const EmailSettings = makeLink("Notifications", "/emailSettings");
const EngineeringInfo = makeLink("Engineering", "/business-engineering");
const Inventory = makeLink("Inventory", "/inventory");
const Monitoring = makeLink("Monitoring", "");
const NetworkConfig = makeLink("Network Config", "/network");
const Onboarding = makeLink("Onboarding", CHECKLIST_PAGE_ROOT_PATH);
const People = makeLink("People", "/people");
const Policies = makeLink("Policies", "/policies");
const Protocols = makeLink("Processes", "");
const Reports = makeLink("Reports", "");
const Risk = makeLink("Risks", "");
const RiskAssessment = makeLink("Risk Assessment", "/risk-assessment");
const RiskRegister = makeLink("Risk Register", "/risk-register");
const RiskReport = makeLink("Risk Reports", "/risk-report");
const SecurityReports = makeLink("Security Reports", "/reports");
const Subnets = makeLink("Subnets", "/subnets");
const Standards = makeLink("Standards", PATHS.STANDARDS);
const Tests = makeLink("Tests", "/activity");
const Testing = makeLink("Testing", "");
const Users = makeLink("Users", "/users");
const Vendors = makeLink("Vendors", "/vendors");
const Vulnerabilities = makeLink("Vulnerabilities", "/vulnerabilities");

export const NavLinks = (args: {
  featureContext: Maybe<IFeatureContext>;
  domainStandards: ReportStandard[];
}): IMenuOption[] => {
  const { featureContext } = args;
  const protocolsSubMenu = [Policies, Onboarding];

  return [
    {
      link: Testing,
      subMenu: [Alarms, Changes, Databases, NetworkConfig, Subnets],
      auditorOnly: true,
    },
    {
      link: Monitoring,
      subMenu: [Tests, Computers, People, Vulnerabilities],
    },
    {
      link: Protocols,
      subMenu: protocolsSubMenu,
    },
    {
      link: Risk,
      subMenu: [
        domainHasFeature(featureContext, Feature.RiskRegisterV3)
          ? RiskAssessment
          : RiskRegister,
        AccountAccess,
        Inventory,
        Vendors,
      ],
    },
    {
      link: Reports,
      subMenu: [RiskReport, SecurityReports, Standards],
    },
  ];
};

export const AuditorLinks: IMenuOption[] = [
  {
    link: Audits,
    subMenu: [AuditorDashboard],
  },
];

const CompanySettingsMenuLinks: ILink[] = [
  Connections,
  CompanyInfo,
  EngineeringInfo,
  Users,
  AuditScheduler,
  CompanyNotifications,
  Billing,
];

const AccountSettingsMenuLinks: ILink[] = [EmailSettings];

export const UserMenuPaths = new Set<string>(
  [...CompanySettingsMenuLinks, ...AccountSettingsMenuLinks].map(
    link => link.path
  )
);

const SIDE_NAV_MENUS = {
  ACCOUNT: {
    header: UserInfo,
    links: AccountSettingsMenuLinks,
  },
  COMPANY: {
    header: UserCompanyInfo,
    links: CompanySettingsMenuLinks,
  },
};

const CompanySettingsSubMenuPaths = new Set<string>(
  CompanySettingsMenuLinks.map(l => l.path)
);
const AccountSettingsSubMenuPaths = new Set<string>(
  AccountSettingsMenuLinks.map(l => l.path)
);

export const pathToSideMenu = (path: string) => {
  if (CompanySettingsSubMenuPaths.has(path)) {
    return SIDE_NAV_MENUS.COMPANY;
  } else if (AccountSettingsSubMenuPaths.has(path)) {
    return SIDE_NAV_MENUS.ACCOUNT;
  } else {
    return undefined;
  }
};
