import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import React from "react";

import { LogError } from "../../errors";
import { useUserContextQuery } from "../../gen/components";
import { UserContext } from "../pages/user-context";
import { FullPageSpinner } from "./FullPageSpinner";

export const UserContextWrapper: React.FC = ({ children }) => {
  const { loading, error, data } = useUserContextQuery();

  if (loading) {
    return <FullPageSpinner />;
  }
  if (error) {
    LogError(error, false);
  }

  if (!isSome(data) || !isSome(data.user)) {
    return <>{children}</>;
  }

  return (
    <UserContext.Provider
      value={{
        domainDisplayName: data.user.domain.displayName,
        domainStandards: data.user.domain.standards,
        userDisplayName: isSome(data.user.displayName)
          ? data.user.displayName
          : "You",
        user: data.user,
        error,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

gql`
  query userContext {
    user {
      chameleonHash
      createdAt
      displayName
      email
      hasAssumedWriteAccess
      id
      imageUrl
      isAssumedUser
      isAssumedSuperUser
      isAuditorAssumingUser
      isVantaTrusted
      permissionLevel
      auditorInfo {
        id
      }
      domain {
        betaFeatures
        companyLogoSlug
        createdAt
        displayName
        id
        lockoutDate
        name
        standards
        credentials {
          id
          service
        }
      }
    }
  }
`;
