import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import React from "react";
import { useHistory } from "react-router";

import { TextLinkLikeBlack } from "../../../../alpaca/components";
import { Ellipsify } from "../../../../helpers/ellipsify";
import { USER_NAMING_CONSTANTS } from "../../../../helpers/user-constants";

interface IAccessOwner {
  id: string;
  displayName?: Maybe<string>;
  email: string;
  isActive: boolean;
  isContractor: boolean;
  isNotHuman?: Maybe<boolean>;
  idp?: Maybe<{
    deactivatedAt?: Maybe<string>;
  }>;
}

/**
 * @serviceAccountUserId - if representing the owner of a service account, the serviceaccount's userId
 * @owner - for both service accounts and users, the owning user
 */
export const AccessOwner: React.FC<{
  serviceAccountUserId?: Maybe<string>;
  owner?: Maybe<IAccessOwner>;
}> = ({ serviceAccountUserId, owner }) => {
  const history = useHistory();
  let content: string | JSX.Element;
  if (serviceAccountUserId === "machine" || Boolean(owner?.isNotHuman)) {
    content = USER_NAMING_CONSTANTS.NOT_A_PERSON;
  } else if (serviceAccountUserId === "external") {
    content = USER_NAMING_CONSTANTS.EXT_USER;
  } else if (isSome(owner)) {
    const text =
      !owner.isActive || isSome(owner.idp?.deactivatedAt)
        ? `Previous owner was ${owner.displayName}`
        : owner.displayName!;
    content = (
      <TextLinkLikeBlack
        onClick={() => {
          const search = new URLSearchParams(location.search);
          search.set("userId", owner.id);
          history.push({
            search: search.toString(),
          });
        }}
      >
        <Ellipsify text={text} />
      </TextLinkLikeBlack>
    );
  } else {
    content = "No owner";
  }

  return <>{content}</>;
};
