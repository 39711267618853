import { dropNothing } from "common/base/types/maybe";
import React from "react";

import type { IQuestionSchemaFormProps } from "../../components/forms/question-schema-form";
import { QuestionSchemaForm } from "../../components/forms/question-schema-form";
import { FeatureGate } from "./feature-gate";

export const FeatureGatedForm: React.FunctionComponent<IQuestionSchemaFormProps> =
  props => {
    const features = dropNothing(
      props.questionSchema.questions.map(q => q.feature)
    );

    return (
      <FeatureGate
        features={features}
        render={({ availableFeatures }) => {
          const availableFeaturesSet = availableFeatures.reduce(
            (set, feature) => set.add(feature),
            new Set<string>()
          );
          return (
            <QuestionSchemaForm
              {...props}
              availableFeatures={availableFeaturesSet}
            />
          );
        }}
      />
    );
  };
