import {
  Button,
  Classes,
  Dialog,
  FormGroup,
  InputGroup,
  Intent,
} from "@blueprintjs/core";
import React from "react";

interface IProps {
  isOpen: boolean;
  onCloseDialog(): void;
  onAddVendor(name: string, url: string): void;
}

interface IState {
  nameText: string;
  urlText: string;
}

export class AddVendorDialog extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
    this.state = {
      nameText: "",
      urlText: "",
    };

    this.clearFields = this.clearFields.bind(this);
    this.onAddButtonClicked = this.onAddButtonClicked.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  public clearFields() {
    this.setState({
      nameText: "",
      urlText: "",
    });
  }

  public onAddButtonClicked() {
    const { nameText, urlText } = this.state;
    this.props.onAddVendor(nameText, urlText);
    this.clearFields();
  }

  public onClose() {
    this.clearFields();
    this.props.onCloseDialog();
  }

  public render() {
    const { isOpen } = this.props;
    const canSubmit = this.state.nameText !== "" && this.state.urlText !== "";
    return (
      <Dialog
        isOpen={isOpen}
        title={`Add vendor`}
        usePortal={true}
        onClose={this.onClose}
        canOutsideClickClose={true}
      >
        <div className={Classes.DIALOG_BODY}>
          <FormGroup label={<strong>Company name</strong>}>
            <InputGroup
              type="text"
              value={this.state.nameText}
              placeholder="Megabrantis Cluster Computing"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                this.setState({ nameText: e.target.value })
              }
            />
          </FormGroup>
          <FormGroup label={<strong>URL</strong>}>
            <InputGroup
              type="url"
              value={this.state.urlText}
              placeholder="https://zaphod.net"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                this.setState({ urlText: e.target.value })
              }
            />
          </FormGroup>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={this.onClose}>Cancel</Button>
            <Button
              onClick={this.onAddButtonClicked}
              intent={Intent.PRIMARY}
              disabled={!canSubmit}
            >
              Add
            </Button>
          </div>
        </div>
      </Dialog>
    );
  }
}
