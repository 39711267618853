import { Button, Checkbox, Divider, Intent, MenuItem } from "@blueprintjs/core";
import { Suggest } from "@blueprintjs/select";
import { SERVICES } from "common/base/types/helpers";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import React, { useState } from "react";
import styled from "styled-components";

import { Config } from "../../config";
import { LogError } from "../../errors";
import {
  useGetSpecificResourceKindsQuery,
  useTriggerDomainAgnosticResourceFetchMutation,
  useTriggerResourceFetchesByServiceMutation,
  useTriggerResourceFetchMutation,
} from "../../gen/components";
import { AppToaster } from "../../helpers/toaster";
import { FullPageSpinner } from "../helpers/FullPageSpinner";

interface IProps {
  domainId: string;
}

export const TriggerResourceFetchPanel: React.FC<IProps> = ({ domainId }) => {
  const { loading, data } = useGetSpecificResourceKindsQuery();
  const [triggerResourceFetch, mutationResult] =
    useTriggerResourceFetchMutation();

  const [selectedServiceId, setSelectedServiceId] =
    useState<Maybe<string>>(undefined);
  const [triggerResourceFetchesByService, serviceMutationResult] =
    useTriggerResourceFetchesByServiceMutation({
      onCompleted: () => {
        AppToaster.show({
          message: `Resource fetch for service ${selectedServiceId} queued.`,
          intent: Intent.SUCCESS,
        });
      },
    });

  const [triggerDomainAgnosticResourceFetch, domainAgnosticResult] =
    useTriggerDomainAgnosticResourceFetchMutation();

  if (loading || !data) {
    return <FullPageSpinner />;
  }

  const FetchCustomerResourcesElement = (
    <>
      <h2>Fetch a resource</h2>
      <p>
        Note: <code>EmptySpecificResource</code> and{" "}
        <code>EmptyDailySpecificResource</code>
        don't actually fetch data, but will trigger any tests with that resource
        dependency
      </p>
      <SelectAndFetchComponent
        resourceChoices={data.customerSpecificResourceKinds}
        triggerResourceFetch={async (specificResourceKind, partial) => {
          await triggerResourceFetch({
            variables: {
              domainId,
              specificResourceKind,
              partial,
            },
          });
        }}
        loading={mutationResult.loading}
      />
      <h2>Fetch all resources associated with service</h2>
      <FlexContainer>
        <Suggest
          items={SERVICES}
          selectedItem={selectedServiceId}
          onItemSelect={i => setSelectedServiceId(i)}
          itemRenderer={(i, { modifiers, handleClick }) => (
            <MenuItem
              key={i}
              text={i}
              active={modifiers.active}
              onClick={handleClick}
            />
          )}
          inputValueRenderer={i => i}
          itemPredicate={(query, item) =>
            item.toLowerCase().includes(query.toLowerCase())
          }
          popoverProps={{ minimal: true }}
          inputProps={{ placeholder: "Select a service" }}
          noResults={<div>No matching services.</div>}
        />
        <Button
          disabled={!isSome(selectedServiceId)}
          loading={serviceMutationResult.loading}
          intent={Intent.PRIMARY}
          onClick={async () => {
            if (isSome(selectedServiceId)) {
              await triggerResourceFetchesByService({
                variables: {
                  domainId,
                  service: selectedServiceId,
                },
              });
            }
          }}
        >
          Fetch resources
        </Button>
      </FlexContainer>
    </>
  );

  const FetchDomainAgnosticResourcesElement = (
    <>
      <h2>Fetch a domain agnostic resource</h2>
      <SelectAndFetchComponent
        resourceChoices={data.domainAgnosticResourceKinds}
        triggerResourceFetch={async (specificResourceKind, partial) => {
          await triggerDomainAgnosticResourceFetch({
            variables: { specificResourceKind, partial },
          });
        }}
        loading={domainAgnosticResult.loading}
        shouldShowWarning
      />
    </>
  );

  return (
    <Container>
      <SectionContainer>{FetchCustomerResourcesElement}</SectionContainer>
      <Divider />
      <SectionContainer>{FetchDomainAgnosticResourcesElement}</SectionContainer>
    </Container>
  );
};

interface SelectAndFetchProps {
  resourceChoices: string[];
  triggerResourceFetch: (
    specificResourceKind: string,
    partial: boolean
  ) => Promise<void>;
  loading: boolean;
  shouldShowWarning?: Maybe<boolean>;
}

const SelectAndFetchComponent: React.FC<SelectAndFetchProps> = ({
  resourceChoices,
  triggerResourceFetch,
  loading,
  shouldShowWarning,
}) => {
  const [selectedResourceKind, setSelectedResourceKind] =
    useState<Maybe<string>>(null);
  const [partial, setPartial] = useState<boolean>(false);
  const showWarning =
    Boolean(shouldShowWarning) &&
    !Config.isDev &&
    !Config.isStaging &&
    isSome(selectedResourceKind);

  return (
    <StyledSection>
      {showWarning && (
        <StyledSection>
          <StyledCallout>
            <StyledStrong>
              {`
                Running any domain agnostic fetch has a risk of crashing Resource Fetcher.
                We need to provision more RAM and CPU before running these fetches.
                Notify engineering before running these fetches.
                `}
            </StyledStrong>
          </StyledCallout>
        </StyledSection>
      )}
      <FlexContainer>
        <SelectContainer>
          <Suggest
            items={resourceChoices}
            selectedItem={selectedResourceKind}
            onItemSelect={i => setSelectedResourceKind(i)}
            itemRenderer={(i, { modifiers, handleClick }) => (
              <MenuItem
                key={i}
                text={i}
                active={modifiers.active}
                onClick={handleClick}
              />
            )}
            inputValueRenderer={i => i}
            itemPredicate={(query, item) =>
              item.toLowerCase().includes(query.toLowerCase())
            }
            popoverProps={{ minimal: true }}
            inputProps={{ placeholder: "Select a resource kind" }}
            noResults={<div>No matching resource kinds.</div>}
          />
          <Checkbox
            checked={partial}
            onClick={e => setPartial(e.currentTarget.checked)}
            label={"Partial fetch"}
          />
        </SelectContainer>
        <Button
          disabled={!isSome(selectedResourceKind)}
          loading={loading}
          intent={Intent.PRIMARY}
          onClick={() => {
            if (isSome(selectedResourceKind)) {
              triggerResourceFetch(selectedResourceKind, partial)
                .then(() => {
                  AppToaster.show({
                    message: `Resource fetch for ${selectedResourceKind} queued.`,
                    intent: Intent.SUCCESS,
                  });
                })
                .catch(e => {
                  LogError(e);
                });
            } else {
              // This should never happen, as the button should be disabled
              // if no specific resource kind is selected.
              throw new Error(
                "/query: Resource fetch triggered but no resource selected"
              );
            }
          }}
        >
          Fetch resource
        </Button>
      </FlexContainer>
    </StyledSection>
  );
};

export const Container = styled.div`
  padding: 40px;
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  && {
    margin-bottom: 12px;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 800px;
  && {
    margin-bottom: 24px;
  }
`;

const SelectContainer = styled.div`
  display: flex;
  width: 320px;
  justify-content: space-between;
`;

const StyledCallout = styled.div`
  background: #3c82e014;
  border-radius: 6px;
  padding: 12px;
  flex-grow: 1;
  color: #fff;
  background-color: #ed4848;
`;

const StyledStrong = styled.span`
  font-weight: 600;
`;

const StyledSection = styled.div`
  margin-bottom: 24px;
`;

gql`
  query getSpecificResourceKinds {
    customerSpecificResourceKinds: specificResourceKinds(
      customerResourcesOnly: true
    )
    domainAgnosticResourceKinds: specificResourceKinds(domainAgnosticOnly: true)
  }
`;

gql`
  mutation triggerResourceFetch(
    $domainId: ID!
    $specificResourceKind: String!
    $partial: Boolean
  ) {
    triggerResourceFetch(
      domainId: $domainId
      specificResourceKind: $specificResourceKind
      partial: $partial
    )
  }
`;

gql`
  mutation triggerResourceFetchesByService($domainId: ID!, $service: String!) {
    triggerResourceFetchesByService(domainId: $domainId, service: $service)
  }
`;

gql`
  mutation triggerDomainAgnosticResourceFetch(
    $specificResourceKind: String!
    $partial: Boolean
  ) {
    triggerDomainAgnosticResourceFetch(
      specificResourceKind: $specificResourceKind
      partial: $partial
    )
  }
`;
