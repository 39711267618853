import { Card, Elevation, H5 } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import React from "react";

import { DefaultLink } from "../../../helpers/links";

export const DebugClientInstallation: React.FC<{
  platformType: Maybe<
    "linuxWorkstationData" | "macosWorkstationData" | "windowsWorkstationData"
  >;
}> = props => {
  const boilerplateBeginning = (
    <div>
      <p>
        If a computer has recently connected to the internet but still isn’t
        updating on this page, here are some steps you can take to fix it:
      </p>

      <ul>
        <li>
          Reinstall Vanta from{" "}
          <DefaultLink href="/downloads">app.vanta.com/downloads</DefaultLink>
        </li>
        <li>Restart the computer</li>
      </ul>

      <p>
        If the computer still isn’t reporting about an hour after reinstalling,
        try running our built-in troubleshooter.
      </p>
    </div>
  );

  const boilerplateEnding = (
    <div>
      <p>
        If the output of vanta-cli doctor isn’t helpful, send an email to{" "}
        <DefaultLink href="mailto:support@vanta.com">
          support@vanta.com
        </DefaultLink>{" "}
        and include the full output of the doctor command you just ran!
      </p>
    </div>
  );
  let platformSpecific;
  switch (props.platformType) {
    case "linuxWorkstationData":
      platformSpecific = (
        <div>
          <ol>
            <li>Open a terminal window</li>
            <li>
              Run <code>sudo /var/vanta/vanta-cli doctor</code>
            </li>
            <li>You may have to enter your password</li>
          </ol>
        </div>
      );
      break;
    case "macosWorkstationData":
      platformSpecific = (
        <div>
          <ol>
            <li>
              Open a terminal window (open Spotlight and type in "terminal")
            </li>
            <li>
              Type <code>sudo /usr/local/vanta/vanta-cli doctor</code> and press{" "}
              <code>return</code>
            </li>
            <li>You may have to enter your password</li>
          </ol>
        </div>
      );
      break;
    case "windowsWorkstationData":
      platformSpecific = (
        <div>
          <ol>
            <li>
              Search for cmd.exe in the search bar. Right-click and select “run
              as administrator.”
            </li>
            <li>
              Type <code>C:\ProgramData\Vanta\vanta-cli doctor</code> and press{" "}
              <code>return</code>
            </li>
          </ol>
        </div>
      );
      break;
    default:
      platformSpecific = <div>No information found for platform.</div>;
  }
  return (
    <div>
      {boilerplateBeginning}
      {platformSpecific}
      {boilerplateEnding}
    </div>
  );
};

export const HostAgentTroubleshootingInstructions: React.FC = () => (
  <div>
    <Card elevation={Elevation.TWO}>
      <H5>Bundling the Vanta Agent into an image</H5>
      <p>
        If you’re bundling the Vanta Agent into an image, make sure the agent
        doesn’t start before taking the image. Once the agent starts, it will
        cache its registration information – so every subsequent machine will
        think it’s the original machine!
      </p>
      <p>
        Use the <pre>VANTA_NOSTART</pre> environment variable if you don’t want
        the agent to start. To force re-registration, if you’ve already deployed
        to several machines and don’t want to do so again, run:
      </p>
      <pre>sudo /var/vanta/vanta-cli reset</pre>
      <hr />

      <H5>Incorrect key</H5>
      <p>
        Make sure the key you’re using in your installation script is the same
        as the one on this page. If you've used the incorrect key, you can
        register using
        <pre>
          {"sudo /var/vanta/vanta-cli register --secret=<YOUR SECRET KEY HERE>"}
        </pre>
      </p>
      <hr />
      <H5>Race conditions</H5>
      <p>
        If you’ve modified the install-linux.sh script for your own purposes,
        make sure that /etc/vanta.conf is populated before the daemon starts.
      </p>
      <hr />
      <p>
        If none of the above apply to you and you’re still having trouble
        setting up the agent, run <pre>sudo /var/vanta/vanta-cli doctor</pre> on
        one of the misbehaving machines. Send the output to{" "}
        <DefaultLink href="mailto:support@vanta.com">
          support@vanta.com
        </DefaultLink>{" "}
        and we’ll help figure it out!
      </p>
    </Card>
  </div>
);
