import type { Maybe } from "common/base/types/maybe";
import { isSome, nothing } from "common/base/types/maybe";
import gql from "graphql-tag";
import React from "react";
import type { match } from "react-router";
import { Redirect } from "react-router";

import type { GetSubmittedVaqQuery } from "../../../gen/components";
import { useGetSubmittedVaqQuery } from "../../../gen/components";
import { QuestionSchemaForm } from "../../forms/question-schema-form";
import { FullPageSpinner } from "../../helpers/FullPageSpinner";
import { wrapInDashboardContainer } from "../../vanta-chrome/page-content/vanta-dashboard-page";
import {
  getSchemaWithVendorAndCompanyNames,
  vaqForms,
} from "../../vaq/schemas/vaq-state-helpers";

interface IProps {
  vaq: NonNullable<GetSubmittedVaqQuery["organization"]["vaqSubmission"]>;
}

class ReadOnlyVAQPageComponent extends React.Component<IProps> {
  public render() {
    const { answers } = this.props.vaq;
    const titleToFiles: { [k: string]: IProps["vaq"]["files"][number] } = {};
    this.props.vaq.files.forEach(file => {
      if (isSome(file.title)) {
        titleToFiles[file.title] = file;
      }
    });
    const vendorName = this.props.vaq.vendor.name;
    const domainName = this.props.vaq.domain.displayName;
    return (
      <div>
        <h1 className="vaq-display-title">
          Security information from {vendorName}
        </h1>
        {answers.map(schemaAnswer => (
          <div key={schemaAnswer.schemaId}>
            <h4>{vaqForms[schemaAnswer.schemaId].title}</h4>
            <QuestionSchemaForm
              readonly={true}
              priorInfo={{
                ...JSON.parse(schemaAnswer.contents),
                ...titleToFiles,
              }}
              questionSchema={getSchemaWithVendorAndCompanyNames(
                schemaAnswer.schemaId,
                domainName,
                vendorName
              )}
              onSubmit={() => {
                throw new Error("Can't submit read only form");
              }}
            />
            <hr />
          </div>
        ))}
      </div>
    );
  }
}

gql`
  query getSubmittedVAQ($id: String!) {
    organization {
      id
      vaqSubmission(id: $id) {
        id
        files {
          id
          filename
          url
          title
          createdAt
        }
        submittedDate
        answers {
          schemaId
          contents
        }
        vendor {
          id
          name
        }
        domain {
          id
          displayName
        }
      }
    }
  }
`;

interface IQueryProps {
  match: Maybe<match<{ id: string }>>;
}

const ReadOnlyVAQPageInternal = (props: IQueryProps) => {
  const id = isSome(props.match) ? props.match.params.id : nothing;
  const { data, loading } = useGetSubmittedVaqQuery({
    variables: { id: id ?? "NO_ID" },
    skip: !isSome(id),
  });

  if (!isSome(id)) {
    return <Redirect to="/" />;
  }

  if (loading || !isSome(data?.organization.vaqSubmission)) {
    return <FullPageSpinner />;
  }

  return <ReadOnlyVAQPageComponent vaq={data!.organization.vaqSubmission} />;
};

export const ReadOnlyVAQPage = wrapInDashboardContainer(
  ReadOnlyVAQPageInternal
);
