import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import gql from "graphql-tag";
import React, { useState } from "react";

import { LogError } from "../../../errors";
import { Feature, useGetAuditInfoQuery } from "../../../gen/components";
import { useFeatureCheck } from "../../../helpers/feature-gating/feature-check";
import { FullPageSpinner } from "../../helpers/FullPageSpinner";
import {
  PageHeadingInfo,
  VantaDashboardPage,
} from "../../vanta-chrome/page-content/vanta-dashboard-page";
import { AuditList } from "./audit-list";
import { AuditSchedulerDialog } from "./audit-scheduler-dialog";

export const AuditSchedulerPage: React.FC = () => {
  const [showScheduler, setShowScheduler] = useState(false);
  const { loading, error, data } = useGetAuditInfoQuery();

  // GA will involve giving all domains the feature
  const auditJourneyAuditorEnabled = useFeatureCheck(
    Feature.AuditJourneyAuditor
  );

  if (loading) {
    return <FullPageSpinner />;
  }

  if (error || !data) {
    LogError(error ?? new Error("Bad Fetch"));
    return null;
  }

  return (
    <VantaDashboardPage
      headingInfo={{
        ...PageHeadingInfo.AUDIT_SCHEDULER,
        description: auditJourneyAuditorEnabled
          ? ""
          : PageHeadingInfo.AUDIT_SCHEDULER.description,
      }}
    >
      <h3>Scheduled audits</h3>
      {auditJourneyAuditorEnabled ? null : (
        <Button
          intent={Intent.PRIMARY}
          onClick={() => setShowScheduler(true)}
          icon={IconNames.PLUS}
        >
          Add audit
        </Button>
      )}
      <AuditList
        audits={data.organization.audits
          .slice()
          .sort(
            (audit1, audit2) =>
              parseInt(audit2.auditStart, 10) - parseInt(audit1.auditStart, 10)
          )}
        auditorsWithAccess={
          new Set(data.organization.auditorsWithAccess.map(a => a.id))
        }
      />
      {showScheduler ? (
        <AuditSchedulerDialog onClose={() => setShowScheduler(false)} />
      ) : null}
    </VantaDashboardPage>
  );
};

gql`
  query GetAuditInfo {
    organization {
      id
      auditorsWithAccess {
        id
        email
        displayName
        firmName
      }
      audits(currentAudits: true, futureAudits: true, pastAudits: true) {
        id
        auditors {
          id
          email
          displayName
        }
        auditPeriodDays
        auditType
        auditStart
        auditFirmInfo {
          id
          firmName
        }
      }
    }
  }
`;
