import type { TabsProps } from "@blueprintjs/core";
import { Tab } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { getTransformedOrElse } from "common/base/types/maybe";
import React from "react";
import styled from "styled-components";

import { BASE_PALETTE } from "../../base/colors";
import { GRID_SPACING } from "../../base/grid";
import { SpacedList } from "../primitives/spaced-list";
import { Tabs } from "../tabs/tabs";
import { BodyText, H2 } from "../typography/typography";
import { BackButton } from "./back-button";
import { Breadcrumbs } from "./breadcrumbs";

export interface IHeaderRowProps {
  title: React.ReactNode;
  backLink?: Maybe<string>;
  breadcrumbs?: Maybe<Array<{ text: string; route: string }>>;
  /**
   * If passing an element, only include items that can be rendered inline, such as links
   */
  description?: Maybe<string | JSX.Element>;
  rightControls?: Maybe<React.ReactNode[]>;
  tabProps?: Maybe<{
    id: string;
    tabIds: Array<{ id: string; title: React.ReactNode }>;
    selectedTabId: string;
    onChange(newTabId: string): void;
  }>;
}

export const HeaderRow: React.FC<IHeaderRowProps> = ({
  title,
  backLink,
  breadcrumbs,
  description,
  rightControls,
  tabProps,
}) => {
  const header = <StyledH2 fontSize={36}>{title}</StyledH2>;
  const headerWithBack = getTransformedOrElse(
    backLink,
    link => (
      <SpacedList
        marginOverride={GRID_SPACING}
        style={{ alignItems: "flex-start" }}
      >
        <BackButton to={link} />
        {header}
      </SpacedList>
    ),
    header
  );

  return (
    <StyledContainerDiv>
      {getTransformedOrElse(
        breadcrumbs,
        crumbs => (
          <Breadcrumbs breadcrumbs={crumbs} />
        ),
        null
      )}
      {getTransformedOrElse(
        rightControls,
        controls => (
          <StyledFlexDiv>
            {headerWithBack}
            <SpacedList>{controls}</SpacedList>
          </StyledFlexDiv>
        ),
        headerWithBack
      )}
      {getTransformedOrElse(
        description,
        text => (
          <BodyText
            style={{ minHeight: 2 * GRID_SPACING, maxWidth: 90 * GRID_SPACING }}
            as="div"
            color={BASE_PALETTE.CHARCOAL}
            fontSize={14}
            lineHeight="16px"
          >
            {text}
          </BodyText>
        ),
        null
      )}
      {getTransformedOrElse(
        tabProps,
        props => (
          <PageHeaderTabs
            id={props.id}
            selectedTabId={props.selectedTabId}
            onChange={(newId: string) => {
              if (newId !== props.selectedTabId) {
                props.onChange(String(newId));
              }
            }}
          >
            {props.tabIds.map(tabId => (
              <Tab key={tabId.id} id={tabId.id} title={tabId.title} />
            ))}
          </PageHeaderTabs>
        ),
        null
      )}
    </StyledContainerDiv>
  );
};

const StyledH2 = styled(H2)`
  margin-bottom: 0;
  height: ${6 * GRID_SPACING}px;
  line-height: 36px;
`;

const StyledContainerDiv = styled.div`
  margin-bottom: ${3 * GRID_SPACING}px;
  width: 100%;
`;

const StyledFlexDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  & > *:first-child {
    flex-shrink: 0;
  }
`;

const PageHeaderTabs = styled<React.ComponentType<TabsProps>>(Tabs)`
  margin-top: ${GRID_SPACING}px;
`;

/**
 * A Blueprint Tabs component that doesn't render the tab list.
 * Primarily for page-level tabs where the list is rendered by HeaderRow
 */
export const HeaderlessTabs = styled<React.ComponentType<TabsProps>>(Tabs)`
  & > .bp3-tab-list {
    display: none;
  }
  & > .bp3-tab-panel {
    margin-top: 0;
  }
`;
