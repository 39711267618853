import { Spinner } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { isSome, nothing } from "common/base/types/maybe";
import deepequal from "fast-deep-equal";
import gql from "graphql-tag";
import React, { useEffect, useState } from "react";

import { LogError } from "../../errors";
import type { GetVaqAnswersQuery } from "../../gen/components";
import {
  GetVaqAnswersDocument,
  useSetVaqSchemaAnswersMutation,
} from "../../gen/components";
import { QuestionSchemaForm } from "../forms/question-schema-form";
import { FullPageSpinner } from "../helpers/FullPageSpinner";
import { getSchemaWithVendorAndCompanyNames } from "./schemas/vaq-state-helpers";
import { VAQInitialForm } from "./vaq-initial-form";
import { VAQSubmitForm } from "./vaq-submit-form";

interface IProps {
  selectedFormId?: Maybe<string>;
  vaq: NonNullable<GetVaqAnswersQuery["vaqAnswers"]>;
  gotoNextForm(): void;
}

export const VAQMainBody: React.FC<IProps> = props => {
  const [buttonPressed, setButtonPressed] = useState(false);

  useEffect(() => {
    if (buttonPressed) {
      setButtonPressed(false);
    }
  }, [props.selectedFormId]);

  return (
    <div className="vaq-main-body">
      {buttonPressed ? (
        <Spinner />
      ) : (
        <FormComponentInternal
          {...props}
          onSubmit={() => setButtonPressed(true)}
        />
      )}
    </div>
  );
};

const FormComponentInternal: React.FC<IProps & { onSubmit(): void }> =
  props => {
    const { selectedFormId, vaq } = props;
    const [setAnswers, { loading }] = useSetVaqSchemaAnswersMutation({
      refetchQueries: [
        {
          query: GetVaqAnswersDocument,
          variables: { uuid: props.vaq.uuid },
        },
      ],
    });
    if (!isSome(selectedFormId)) return null;
    if (selectedFormId === "SUBMIT_VAQ") return <VAQSubmitForm vaq={vaq} />;

    const titleToFiles: {
      [k: string]: NonNullable<
        GetVaqAnswersQuery["vaqAnswers"]
      >["files"][number];
    } = {};
    vaq.files.forEach(file => {
      if (isSome(file.title)) {
        titleToFiles[file.title] = file;
      }
    });
    const priorInfoJSON = isSome(selectedFormId)
      ? JSON.parse(vaq.answersJSON)[selectedFormId]
      : nothing;

    if (selectedFormId === "vendorBasicInformation")
      return (
        <VAQInitialForm gotoNextForm={props.gotoNextForm} vaq={props.vaq} />
      );
    return loading ? (
      <FullPageSpinner />
    ) : (
      <div className="vaq-form">
        <QuestionSchemaForm
          disabled={loading}
          nonStickySubmit={true}
          questionSchema={getSchemaWithVendorAndCompanyNames(
            selectedFormId,
            vaq.domainDisplayName ?? "the company",
            vaq.vendorLegalName ?? "vendor"
          )}
          priorInfo={
            isSome(priorInfoJSON)
              ? {
                  ...JSON.parse(priorInfoJSON),
                  ...titleToFiles,
                }
              : nothing
          }
          submitTextOverride="Continue"
          onSubmit={(answers, files) => {
            const fileFieldList = Object.keys(files).map(f => {
              return {
                fieldName: f,
                file: files[f],
              };
            });
            if (
              fileFieldList.length > 0 ||
              !isSome(priorInfoJSON) ||
              !deepequal(JSON.parse(priorInfoJSON), JSON.parse(answers))
            ) {
              setAnswers({
                variables: {
                  uuid: vaq.uuid,
                  schemaId: selectedFormId,
                  answers,
                  files: fileFieldList,
                },
              }).catch(LogError);
              props.onSubmit();
            } else {
              props.gotoNextForm();
            }
          }}
        />
      </div>
    );
  };

gql`
  mutation setVAQSchemaAnswers(
    $uuid: String!
    $schemaId: String!
    $answers: String!
    $files: [FileFieldName!]
  ) {
    setVAQAnswers(
      uuid: $uuid
      schemaId: $schemaId
      answers: $answers
      files: $files
    ) {
      id
    }
  }
`;
