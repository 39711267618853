import type { MIME_TYPES } from "common/base/types/helpers";
import { MIME_TYPE_FILE_EXTENSIONS } from "common/base/types/helpers";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import React from "react";
import styled from "styled-components";

import { BASE_PALETTE } from "../../../alpaca/base/colors";
import { BodyText, H4, Icon, IconNames } from "../../../alpaca/components";
import { Ellipsify } from "../../../helpers/ellipsify";
import { FileDrop } from "./file-drop";
import { FileUploadButton } from "./file-upload-button";

interface IProps {
  file?: Maybe<File>;
  titleText?: Maybe<string>;
  allowedMimeTypes: MIME_TYPES[];
  onFileSelected: (newFile: Maybe<File>) => void;
}

export const FileUploadCard: React.FC<IProps> = ({
  file,
  titleText,
  allowedMimeTypes,
  onFileSelected,
}) => {
  void 0;
  return (
    <FileDrop
      allowedMimeTypes={allowedMimeTypes}
      onFileUploaded={onFileSelected}
    >
      <StyledDiv>
        {isSome(file) ? (
          <>
            <BodyText
              style={{
                display: "flex",
                alignItems: "center",
                maxWidth: "80%",
              }}
              color={BASE_PALETTE.SMOKE}
            >
              <Icon icon={IconNames.COPY} />
              &nbsp;
              <Ellipsify text={file.name}></Ellipsify>
            </BodyText>
            <FileUploadButton
              allowedMimeTypes={allowedMimeTypes}
              onFileUploaded={onFileSelected}
              buttonText="Upload different file"
            />
          </>
        ) : (
          <>
            <H4>{titleText ?? "Upload File"}</H4>
            <BodyText color={BASE_PALETTE.SMOKE}>
              Drag and drop, or select a file
            </BodyText>
            <FileUploadButton
              allowedMimeTypes={allowedMimeTypes}
              onFileUploaded={onFileSelected}
            />
            <BodyText
              fontSize={12}
              lineHeight="14px"
              color={BASE_PALETTE.SMOKE}
            >
              Upload files up to 10 MB of the following types:{" "}
              {allowedMimeTypes
                .map(t => `.${MIME_TYPE_FILE_EXTENSIONS[t]}`)
                .join(", ")}
            </BodyText>
          </>
        )}
      </StyledDiv>
    </FileDrop>
  );
};

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  & > * {
    margin-bottom: 12px;
  }
`;
