import { TestOutcome } from "common/base/types/gen";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";

interface ITestResult {
  outcome: TestOutcome;
}

export const ShouldDisplayTestResult = (
  testResult: Maybe<ITestResult>,
  showSuccessOnly: boolean
) =>
  !showSuccessOnly ||
  (isSome(testResult) && testResult.outcome === TestOutcome.PASS);

gql`
  query GetComplianceStandard($standard: reportStandard!, $domainId: ID!) {
    complianceStandard(standard: $standard, domainId: $domainId) {
      canonical
      version
      standard
      principles {
        name
        shorthand
        section
        optional
        requirements {
          name
          section
          shorthand
          controls {
            name
            control
            key
            evidence
            tests(domainId: $domainId) {
              name
              defaultRemediation
              severity
              test
              testId
            }
          }
        }
      }
    }
  }
`;

gql`
  query GetDataForReport($standard: reportStandard!, $domainId: ID!) {
    user {
      id
      domain {
        id
        companyLogoSlug
        displayName
        name
        slug
        testResults {
          id
          outcome
          testId
        }
      }
    }
    complianceStandard(standard: $standard, domainId: $domainId) {
      canonical
      version
      standard
      principles {
        name
        shorthand
        section
        optional
        requirements {
          name
          section
          shorthand
          controls {
            name
            control
            key
            evidence
            tests(domainId: $domainId) {
              name
              defaultRemediation
              severity
              test
              testId
            }
          }
        }
      }
    }
  }
`;
