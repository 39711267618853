import type { Maybe } from "common/base/types/maybe";
import React from "react";
import styled, { css } from "styled-components";

import { Config } from "../../../config";
import { Dimensions } from "../constants";
import { AccountMenu } from "./account-menu";
import { HelpMenu } from "./help-menu";
import { MainNavMenu } from "./main-nav-menu";

interface IProps {
  wide?: Maybe<boolean>;
}

export const TopBar: React.FC<IProps> = ({ wide }) => {
  const theme = {
    background: "#ffffff",
  };
  if (Config.isDev) {
    theme.background = "#1aee88";
  }
  if (Config.isStaging) {
    theme.background = "#ff891c";
  }
  return (
    <FixedContainer theme={theme}>
      <MainContent wide={wide}>
        <Flex>
          <MainNavMenu />
          <InnerFlex>
            <HelpMenu />
            <AccountMenu />
          </InnerFlex>
        </Flex>
      </MainContent>
    </FixedContainer>
  );
};

const MainContent = styled.div<{ wide?: Maybe<boolean> }>`
  position: relative;
  padding: 0 ${Dimensions.SIDE_MARGINS}px;
  margin: 0 ${({ wide }) => (Boolean(wide) ? "" : "auto")};
  max-width: ${({ wide }) =>
    Boolean(wide)
      ? "100vw;"
      : `${Dimensions.MAX_CONTENT_WIDTH - 2 * Dimensions.SIDE_MARGINS}px;`};
  ${({ wide }) =>
    wide
      ? css`
          position: sticky;
          left: 0;
        `
      : ""}
`;

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  height: 100%;
`;

const InnerFlex = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const FixedContainer = styled.div`
  height: ${Dimensions.TOP_BAR_HEIGHT}px;
  position: sticky;
  top: 0;
  background: ${props => props.theme.background};
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.09);
  z-index: 15;
  width: 100%;
`;
