import { applyIfSome } from "common/base/types/maybe";
import React from "react";

import { IconButton } from "../../../../../alpaca/components";
import type { CompletionFieldsFragment } from "../../../../../gen/components";
import { CompleteTaskControl } from "../complete-task-control";
import { TaskListItem } from "./task-list-item";

interface IProps {
  taskCompletion: CompletionFieldsFragment;
  userId: string;
  isUserActive: boolean;
  roleCompletionId: string;
}

export const CustomTaskItem: React.FC<IProps> = ({
  taskCompletion,
  userId,
  isUserActive,
  roleCompletionId,
}) => {
  const { task } = taskCompletion;

  const actionButtons = isUserActive
    ? [
        <CompleteTaskControl
          key={`custom-task-control-${task.id}`}
          taskCompletion={taskCompletion}
          userId={userId}
          roleCompletionId={roleCompletionId}
          renderButton={(onClick, icon, tooltipContent) => (
            <IconButton
              onClick={onClick}
              icon={icon}
              tooltipProps={{ content: tooltipContent }}
              small
            />
          )}
        />,
      ]
    : null;

  return (
    <TaskListItem
      title={task.title}
      completionDate={applyIfSome(
        taskCompletion.completionDate,
        date => new Date(date)
      )}
      actionButtons={actionButtons}
    />
  );
};
