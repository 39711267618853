import { css } from "styled-components";

import { BASE_PALETTE } from "../../base/colors";
import { GRID_SPACING } from "../../base/grid";
import { BASE_TYPOGRAPHY } from "../../base/typography";

export const ButtonClasses = {
  /**
   * The default button class.
   */
  BUTTON: "alpaca-button",
  /**
   * Classes that manage the button's size. These classes override the
   * button's padding and dimensions, as well as the font size of the text.
   */
  LARGE: "alpaca-button-large",
  SMALL: "alpaca-button-small",
  /**
   * Classes that manage the colors of the buttons. These classes override
   * the background and border color of the button and the color of the text,
   * and can specify these properties for hover states as well.
   */
  PRIMARY: "alpaca-button-primary",
  DANGER: "alpaca-button-danger",
  SECONDARY: "alpaca-button-secondary",
  OUTLINED: "alpaca-button-outlined",
  DISABLED: "alpaca-button-disabled",
  FILTER_ACTIVE: "alpaca-button-filter-active",
};

export const BUTTON_CLASS_STYLES = css`
  && {
    &.${ButtonClasses.BUTTON} {
      /* default sizing properties */
      height: 36px;
      padding-left: ${2 * GRID_SPACING}px;
      padding-right: ${2 * GRID_SPACING}px;
      border-radius: 4px;

      .bp3-button-text {
        font-size: 12px;
        line-height: 16px;
        font-weight: ${BASE_TYPOGRAPHY.FONT_WEIGHTS.SLIGHTLY_BOLD};
      }

      /* default button color properties */
      border: 1px solid ${BASE_PALETTE.FOG};
      background-color: ${BASE_PALETTE.SNOW};
      background-image: none;
      color: ${BASE_PALETTE.CHARCOAL};

      svg {
        fill: ${BASE_PALETTE.SMOKE};
        color: ${BASE_PALETTE.SMOKE};
      }

      &:hover {
        color: ${BASE_PALETTE.INK};
        border: 1px solid ${BASE_PALETTE.INK};
        background-color: ${BASE_PALETTE.SNOW};
      }

      &.bp3-minimal {
        border: none;
        background-color: transparent;
        &:hover {
          background-color: ${BASE_PALETTE.FOG}80;
          border: none;
        }
      }
    }

    &.${ButtonClasses.SMALL} {
      min-height: ${3 * GRID_SPACING}px;
      height: ${3 * GRID_SPACING}px;
      padding-left: 10px;
      padding-right: 10px;

      .bp3-button-text {
        line-height: 12px;
        font-weight: ${BASE_TYPOGRAPHY.FONT_WEIGHTS.NORMAL};
      }
    }

    &.${ButtonClasses.LARGE} {
      height: ${6 * GRID_SPACING}px;

      .bp3-button-text {
        font-size: 14px;
      }
    }

    &.${ButtonClasses.PRIMARY} {
      border: none;
      background-color: ${BASE_PALETTE.GRAPE};
      color: ${BASE_PALETTE.SNOW};

      svg {
        fill: ${BASE_PALETTE.SNOW};
        color: ${BASE_PALETTE.SNOW};
      }

      &:hover {
        border: none;
        background-color: #4e25c0;
        color: ${BASE_PALETTE.SNOW};
      }
    }

    &.${ButtonClasses.DANGER} {
      border: none;
      background-color: ${BASE_PALETTE.TOMATO};
      color: ${BASE_PALETTE.SNOW};

      svg {
        fill: ${BASE_PALETTE.SNOW};
        color: ${BASE_PALETTE.SNOW};
      }

      &:hover {
        border: none;
        background-color: #c23030;
        color: ${BASE_PALETTE.SNOW};
      }
    }

    &.${ButtonClasses.SECONDARY} {
      border: none;
      background-color: ${BASE_PALETTE.CHARCOAL};
      color: ${BASE_PALETTE.SNOW};

      svg {
        fill: ${BASE_PALETTE.SNOW};
        color: ${BASE_PALETTE.SNOW};
      }

      &:hover {
        border: none;
        background-color: #43474d;
        color: ${BASE_PALETTE.SNOW};
      }
    }

    &.${ButtonClasses.OUTLINED} {
      border: 1px solid ${BASE_PALETTE.CHARCOAL};
      background-color: transparent;
      color: ${BASE_PALETTE.INK};

      &:hover {
        border: 1px solid ${BASE_PALETTE.INK};
        background-color: transparent;
        color: ${BASE_PALETTE.INK};
      }
    }

    &.${ButtonClasses.DISABLED} {
      border: 1px solid ${BASE_PALETTE.FOG}B0;
      background-color: ${BASE_PALETTE.SNOW};
      color: ${BASE_PALETTE.INK}66;
      cursor: not-allowed;

      svg {
        color: ${BASE_PALETTE.INK}66;
        fill: ${BASE_PALETTE.INK}66;
      }

      &:hover {
        border: 1px solid ${BASE_PALETTE.FOG}B0;
        background-color: ${BASE_PALETTE.SNOW};
        color: ${BASE_PALETTE.INK}66;
      }
    }

    &.${ButtonClasses.FILTER_ACTIVE}, &.${ButtonClasses.FILTER_ACTIVE}:hover {
      border: 1px solid #238f9d;
      background-color: #f2f9fa;
    }
  }
`;
