import { Tab } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";

import { BASE_PALETTE } from "../../../../alpaca/base/colors";
import { GRID_SPACING } from "../../../../alpaca/base/grid";
import { BodyText, Button, Tabs } from "../../../../alpaca/components";
import { LogError } from "../../../../errors";
import {
  CheckSuspendedOrRemovedDocument,
  Feature,
  useCheckSuspendedOrRemovedQuery,
  useSetNonHumanMutation,
} from "../../../../gen/components";
import { FeatureGate } from "../../../../helpers/feature-gating/feature-gate";
import { AccessTab } from "../../access/user-drawer/access-tab";
import { VantaDrawer } from "../../components/vanta-drawer";
import { ComputersTab } from "../../computers/user-drawer/computers-tab";
import { OffboardingTab } from "./offboarding-tab";
import { OnboardingTab } from "./onboarding-v2/onboarding-tab";
import { UserDetailHeading } from "./user-detail-heading";
import { UserOnboardingList } from "./user-onboarding-list";

export const TAB_IDS = {
  ONBOARDING: "personnel-detail-onboarding",
  ACCESS: "personnel-detail-access",
  OFFBOARDING: "personnel-detail-offboarding",
  COMPUTERS: "personnel-detail-computers",
};

interface IProps {
  initialTab?: Maybe<string>;
}

export const UserDetailDrawer: React.FC<IProps> = ({ initialTab }) => {
  const history = useHistory();
  const searchParams = new URLSearchParams(window.location.search);
  const maybeUserId = searchParams.get("userId");
  const [toggleNonHuman] = useSetNonHumanMutation({
    refetchQueries: [
      {
        query: CheckSuspendedOrRemovedDocument,
        variables: { userId: maybeUserId ?? "" },
      },
    ],
  });
  const { loading, data } = useCheckSuspendedOrRemovedQuery({
    variables: { userId: maybeUserId ?? "" },
    skip: !isSome(maybeUserId),
  });
  const initialTabIdForUser =
    initialTab ??
    (data?.user?.isActive ? TAB_IDS.ONBOARDING : TAB_IDS.OFFBOARDING);
  const [selectedTab, setSelectedTab] = useState(initialTabIdForUser);

  const [drawerIsOpen, setDrawerIsOpen] = useState(true);
  useEffect(() => {
    setDrawerIsOpen(isSome(maybeUserId));
  }, [maybeUserId]);

  useEffect(() => {
    setSelectedTab(initialTabIdForUser);
  }, [data?.user]);

  const closeDrawer = () => {
    const params = new URLSearchParams(window.location.search);
    params.delete("userId");
    history.push({ search: params.toString() });
  };

  let drawerContent;

  if (!isSome(maybeUserId) || loading || !data || !isSome(data.user)) {
    drawerContent = <div />;
  } else {
    drawerContent = (
      <Container>
        <StyledHeadingContainerDiv>
          <UserDetailHeading
            userId={maybeUserId}
            editable={data.user.isActive && !data.user.isNotHuman}
          />
        </StyledHeadingContainerDiv>
        {data.user.isNotHuman ? (
          <StyledNonHumanStateContainer>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <BodyText>Currently marked as not a person</BodyText>
              <div style={{ display: "inline", margin: "auto" }}>
                <Button
                  onClick={() => {
                    toggleNonHuman({
                      variables: {
                        userId: data.user!.id,
                        isNotHumanReason: "",
                        isNotHuman: false,
                      },
                    }).catch(LogError);
                  }}
                >
                  Mark as person
                </Button>
              </div>
            </div>
          </StyledNonHumanStateContainer>
        ) : (
          <TabsContainer>
            <Tabs
              id={`personnel-detail-${maybeUserId}`}
              selectedTabId={selectedTab}
              onChange={newId => setSelectedTab(String(newId))}
              renderActiveTabPanelOnly={true}
            >
              <Tab
                title="Onboarding"
                id={TAB_IDS.ONBOARDING}
                panel={
                  <StyledScrollableContainerDiv>
                    <StyledTabContainerDiv>
                      <FeatureGate
                        feature={Feature.BetaRecurringSAT}
                        otherwise={<UserOnboardingList userId={maybeUserId} />}
                      >
                        <OnboardingTab userId={maybeUserId} />
                      </FeatureGate>
                    </StyledTabContainerDiv>
                  </StyledScrollableContainerDiv>
                }
              />
              <Tab
                title="Access"
                id={TAB_IDS.ACCESS}
                panel={
                  <StyledScrollableContainerDiv>
                    <StyledTabContainerDiv>
                      <AccessTab userId={maybeUserId} />
                    </StyledTabContainerDiv>
                  </StyledScrollableContainerDiv>
                }
              />
              <Tab
                title="Offboarding"
                id={TAB_IDS.OFFBOARDING}
                panel={
                  <StyledScrollableContainerDiv>
                    <StyledTabContainerDiv>
                      <OffboardingTab user={data.user} />
                    </StyledTabContainerDiv>
                  </StyledScrollableContainerDiv>
                }
              />
              <Tab
                title="Computers"
                id={TAB_IDS.COMPUTERS}
                panel={
                  <StyledScrollableContainerDiv>
                    <StyledTabContainerDiv>
                      <ComputersTab userId={maybeUserId} />
                    </StyledTabContainerDiv>
                  </StyledScrollableContainerDiv>
                }
              />
            </Tabs>
          </TabsContainer>
        )}
      </Container>
    );
  }

  return (
    <>
      <VantaDrawer
        size={styles.WIDTH}
        isOpen={drawerIsOpen}
        hasBackdrop={false}
        canOutsideClickClose={false}
        enforceFocus={false}
        onClose={closeDrawer}
      >
        {drawerContent}
      </VantaDrawer>
    </>
  );
};

const styles = {
  WIDTH: 520,
  BOTTOM_PADDING: 100,
  TABS_MARGIN_LEFT: 3,
  HORIZONTAL_PADDING: 3 * GRID_SPACING,
  TAB_TOP_PADDING: 20,
  TAB_BOTTOM_PADDING: 200,
  CLOSE_BUTTON_OFFSET: GRID_SPACING,
  HEADING_MARGIN_TOP: 4 * GRID_SPACING,
  HEADING_MARGIN_BOTTOM: 2 * GRID_SPACING,
  HEADING_HEIGHT: 130,
  TAB_HEADER_HEIGHT: 4 * GRID_SPACING,
};

const Container = styled.div`
  overflow: hidden;
  padding-bottom: ${styles.BOTTOM_PADDING}px;
  padding-left: ${styles.HORIZONTAL_PADDING}px;
  padding-right: ${styles.HORIZONTAL_PADDING}px;
`;

const StyledHeadingContainerDiv = styled.div`
  margin-top: ${styles.HEADING_MARGIN_TOP}px;
  margin-bottom: ${styles.HEADING_MARGIN_BOTTOM}px;
  height: ${styles.HEADING_HEIGHT}px;
`;

const TabsContainer = styled.div`
  margin-left: ${styles.TABS_MARGIN_LEFT}px;
  & * .bp3-tab-panel {
    margin-top: 0px;
  }
  & * .bp3-tab-list {
    height: ${styles.TAB_HEADER_HEIGHT}px;
  }
`;

// A bit of a hack to put the scrollbar at the edge of the drawer (and not at
// the edge of the tab container, which is padded by the drawer container):
// extend the width beyond the parent container, then add the amount extended
// in padding.
const StyledScrollableContainerDiv = styled.div`
  width: calc(100% + 24px);
  padding-right: 24px;
  height: calc(
    100vh -
      ${styles.HEADING_MARGIN_TOP +
      styles.HEADING_HEIGHT +
      styles.HEADING_MARGIN_BOTTOM +
      styles.TAB_HEADER_HEIGHT}px
  );
  overflow-y: auto;
`;

const StyledTabContainerDiv = styled.div`
  padding-top: ${styles.TAB_TOP_PADDING}px;
  padding-bottom: ${styles.TAB_BOTTOM_PADDING}px;
`;

const StyledNonHumanStateContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  border-top: 1px solid ${BASE_PALETTE.FOG};
  padding-top: 36px;
`;

gql`
  query checkSuspendedOrRemoved($userId: ID!) {
    user(id: $userId) {
      id
      displayName
      email
      hrUser {
        isActive
      }
      isActive
      isFromScan
      isNotHuman
      permissionLevel
    }
  }
`;
