import {
  Classes,
  FormGroup,
  InputGroup,
  AnchorButton,
  Intent,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import React, { useState } from "react";

import { Config } from "../../../../config";
import { DefaultLink } from "../../../../helpers/links";

export const Bamboohr: React.FC = () => {
  const [inputValue, setInputValue] = useState<Maybe<string>>("");

  const onChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    setInputValue(e.currentTarget.value.trim());
  };

  const name = "domain";
  const displayName = "Enter your BambooHR Domain";
  const helperText = (
    <span>
      Take a look at the address bar when you are logged in to BambooHR. The
      text just before .bamboohr.com is your domain.
    </span>
  );
  const hostname = Config.isDev
    ? "http://127.0.0.1:8080"
    : Config.isStaging
    ? "https://app.staging.vanta.com"
    : "https://app.vanta.com";

  const redirectUri = encodeURIComponent(`${hostname}/auth/bamboohr/callback`);
  const clientId =
    Config.isDev || Config.isStaging
      ? "BambooHR_Vanta2_03.12.21"
      : "BambooHR_Vanta_03.08.21";

  let subdomain = inputValue;
  if (isSome(subdomain) && subdomain.endsWith(".bamboohr.com")) {
    subdomain = subdomain.replace(".bamboohr.com", "");
  }

  const url =
    `https://${subdomain}.bamboohr.com/authorize.php?` +
    "request=authorize" +
    `&state=${subdomain}` +
    "&response_type=code" +
    "&scope=openid+email" +
    `&client_id=${clientId}` +
    `&redirect_uri=${redirectUri}`;

  return (
    <div>
      <div className={Classes.DIALOG_HEADER}>Link BambooHR</div>

      <div className={Classes.DIALOG_BODY}>
        <FormGroup
          key={displayName}
          label={displayName}
          labelFor={name}
          helperText={helperText}
        >
          <InputGroup
            id={name}
            placeholder={"[yourdomain].bamboohr.com"}
            type={"text"}
            name={name}
            value={inputValue ?? ""}
            onChange={onChange}
          />
        </FormGroup>
        Ensure you have Full Admin permissions, or follow instructions{" "}
        <DefaultLink href="https://vanta.zendesk.com/hc/en-us/articles/360060070611">
          here
        </DefaultLink>{" "}
        to set up a custom permission set.
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <AnchorButton
            intent={Intent.PRIMARY}
            disabled={!isSome(inputValue) || inputValue === ""}
            icon={IconNames.LOG_IN}
            href={url}
          >
            Connect BambooHR
          </AnchorButton>
        </div>
      </div>
    </div>
  );
};
