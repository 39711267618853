import {
  Button,
  Checkbox,
  Classes,
  Dialog,
  FormGroup,
  InputGroup,
  Intent,
  Radio,
  RadioGroup,
} from "@blueprintjs/core";
import { PaymentInterval, ReportStandard } from "common/base/types/gen";
import { StandardToDisplayName } from "common/constants/displayNames";
import { PCI_STANDARDS, PCI_STANDARD_SET } from "common/standards/pci";
import { isSalesforceId } from "common/utils/salesforce";
import gql from "graphql-tag";
import { pick } from "lodash";
import React, { useState } from "react";
import { isEmail, isFQDN } from "validator";

import { LogErrorMessage } from "../../errors";
import type { CreateNewCustomerAndInvoiceInput } from "../../gen/components";
import {
  GetNewCustomersDocument,
  useBillNewCustomerMutation,
} from "../../gen/components";
import { AppToaster } from "../../helpers/toaster";

interface IProps {
  onClose(): void;
}

enum CreateNewCustomerStep {
  BillingInformation,
  DomainConfiguration,
}

const BILLING_FREQUENCY = {
  ANNUALLY: "ANNUALLY",
  QUARTERLY: "QUARTERLY",
};

const AVAILABLE_OPT_IN_STANDARDS = [
  ...PCI_STANDARDS,
  ReportStandard.hipaa,
  ReportStandard.iso27001,
  ReportStandard.soc2,
].sort();

const AVAILABLE_DEFAULT_STANDARDS = [ReportStandard.vanta];

export const CreateNewCustomerDialog: React.FC<IProps> = ({ onClose }) => {
  const [createCustomerInput, setCreateCustomerInput] = useState<
    CreateNewCustomerAndInvoiceInput & {
      annualPrice: number;
      billingFrequency: string;
    }
  >({
    annualPrice: 0,
    billingEmail: "",
    daysUntilDue: 45,
    domainName: "",
    domainStandards: [...AVAILABLE_DEFAULT_STANDARDS],
    billingFrequency: BILLING_FREQUENCY.ANNUALLY,
    intervalCount: 1,
    paymentInterval: PaymentInterval.year,
    pricesInDollarsPerPaymentInterval: {
      base: 0,
    },
    salesforceId: "",
    trialInDays: 0,
  });
  const [createNewCustomerStep, setCreateNewCustomerStep] = useState(
    CreateNewCustomerStep.BillingInformation
  );

  const [billCustomer, mutationResult] = useBillNewCustomerMutation({
    onCompleted: () => {
      AppToaster.show({
        message: `Stripe subscription created for ${createCustomerInput.domainName}`,
        intent: Intent.SUCCESS,
      });
      onClose();
    },
    onError: e => {
      AppToaster.show({
        message: (
          <div>
            <p>Something went wrong</p>
            <p>{e.message}</p>
          </div>
        ),
        intent: Intent.DANGER,
      });
      onClose();
    },
    refetchQueries: [{ query: GetNewCustomersDocument }],
  });
  // switch on state
  switch (createNewCustomerStep) {
    case CreateNewCustomerStep.BillingInformation:
      return (
        <Dialog
          isOpen={true}
          onClose={() => onClose()}
          title={`Invoice a new customer`}
        >
          <div className={Classes.DIALOG_BODY}>
            <FormGroup label="Name of domain">
              <InputGroup
                type="text"
                value={createCustomerInput.domainName}
                placeholder="vanta.com"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setCreateCustomerInput({
                    ...createCustomerInput,
                    domainName: e.target.value,
                  })
                }
              ></InputGroup>
            </FormGroup>
            <FormGroup label="Salesforce ID">
              <InputGroup
                type="text"
                value={createCustomerInput.salesforceId}
                placeholder="0012E00002XXXXXXXX"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setCreateCustomerInput({
                    ...createCustomerInput,
                    salesforceId: e.target.value,
                  })
                }
              ></InputGroup>
            </FormGroup>
            <FormGroup label="Billing email">
              <InputGroup
                placeholder="billing@vanta-billing.com"
                type="email"
                value={createCustomerInput.billingEmail}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setCreateCustomerInput({
                    ...createCustomerInput,
                    billingEmail: e.target.value,
                  })
                }
              ></InputGroup>
            </FormGroup>
            <FormGroup label="Annual price (in dollars)">
              <InputGroup
                type="number"
                value={`${createCustomerInput.annualPrice}`}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setCreateCustomerInput({
                    ...createCustomerInput,
                    annualPrice: +e.target.value,
                  })
                }
              ></InputGroup>
            </FormGroup>
            <FormGroup label="Payment interval">
              <RadioGroup
                selectedValue={createCustomerInput.billingFrequency}
                onChange={e =>
                  setCreateCustomerInput({
                    ...createCustomerInput,
                    billingFrequency: (e as React.ChangeEvent<HTMLInputElement>)
                      .target.value,
                  })
                }
              >
                <Radio label="Annually" value={BILLING_FREQUENCY.ANNUALLY} />
                <Radio label="Quarterly" value={BILLING_FREQUENCY.QUARTERLY} />
              </RadioGroup>
            </FormGroup>
            <FormGroup label="Trial period (in days)">
              <InputGroup
                type="number"
                value={`${createCustomerInput.trialInDays}`}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setCreateCustomerInput({
                    ...createCustomerInput,
                    trialInDays: +e.target.value,
                  })
                }
              ></InputGroup>
            </FormGroup>
            <FormGroup label="Days until due">
              <InputGroup
                type="number"
                value={`${createCustomerInput.daysUntilDue}`}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const daysUntilDue = +e.target.value;
                  // Due dates must be greater than 0, or the Stripe subscription is invalid
                  if (daysUntilDue < 1) return;
                  setCreateCustomerInput({
                    ...createCustomerInput,
                    daysUntilDue,
                  });
                }}
              ></InputGroup>
            </FormGroup>
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button onClick={() => onClose()}>Cancel</Button>
              <Button
                disabled={
                  !isEmail(createCustomerInput.billingEmail) ||
                  !isFQDN(createCustomerInput.domainName) ||
                  !isSalesforceId(createCustomerInput.salesforceId)
                }
                intent={Intent.PRIMARY}
                loading={false}
                onClick={() => {
                  setCreateNewCustomerStep(
                    CreateNewCustomerStep.DomainConfiguration
                  );
                }}
              >
                Next
              </Button>
            </div>
          </div>
        </Dialog>
      );
    case CreateNewCustomerStep.DomainConfiguration:
      return (
        <Dialog
          isOpen={true}
          onClose={() => onClose()}
          title={`Domain configuration`}
        >
          <div className={Classes.DIALOG_BODY}>
            <FormGroup label="Select any additional standards for this domain.">
              {[
                ...AVAILABLE_DEFAULT_STANDARDS,
                ...AVAILABLE_OPT_IN_STANDARDS,
              ].map(standard => (
                <Checkbox
                  checked={
                    AVAILABLE_DEFAULT_STANDARDS.includes(standard) ||
                    createCustomerInput.domainStandards.includes(standard)
                  }
                  disabled={AVAILABLE_DEFAULT_STANDARDS.includes(standard)}
                  onChange={e =>
                    e.currentTarget.checked
                      ? setCreateCustomerInput({
                          ...createCustomerInput,
                          domainStandards: Array.from(
                            new Set([
                              ...createCustomerInput.domainStandards,
                              standard,
                            ])
                          ),
                        })
                      : setCreateCustomerInput({
                          ...createCustomerInput,
                          domainStandards:
                            createCustomerInput.domainStandards.filter(
                              s => s !== standard
                            ),
                        })
                  }
                  key={standard}
                  label={StandardToDisplayName(standard)}
                />
              ))}
            </FormGroup>
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button onClick={() => onClose()}>Cancel</Button>
              <Button
                disabled={false}
                intent={Intent.PRIMARY}
                loading={mutationResult.called}
                onClick={async () => {
                  const standards = createCustomerInput.domainStandards;
                  if (
                    standards.length === 1 &&
                    standards[0] === ReportStandard.vanta
                  ) {
                    if (
                      !window.confirm(
                        "You haven't added any standards for this domain. Do you still want to create the customer?"
                      )
                    ) {
                      return;
                    }
                  }

                  if (
                    standards.filter(s => PCI_STANDARD_SET.has(s)).length > 1
                  ) {
                    AppToaster.show({
                      icon: "error",
                      intent: Intent.DANGER,
                      message:
                        "A domain cannot have more than one PCI SAQ standard. Please select only one.",
                      timeout: 2500,
                    });
                    return;
                  }

                  let options;

                  switch (createCustomerInput.billingFrequency) {
                    case BILLING_FREQUENCY.ANNUALLY:
                      options = {
                        basePrice: createCustomerInput.annualPrice,
                        intervalCount: 1,
                        paymentInterval: PaymentInterval.year,
                      };
                      break;
                    // Stripe provides quarterly billing via monthly billing every 3 months.
                    // @see https://stripe.com/docs/billing/subscriptions/examples#varying-pricing
                    case BILLING_FREQUENCY.QUARTERLY:
                      options = {
                        basePrice: createCustomerInput.annualPrice / 4,
                        intervalCount: 3,
                        paymentInterval: PaymentInterval.month,
                      };
                      break;
                    default:
                      throw new Error("Invalid billing frequency");
                  }

                  await billCustomer({
                    variables: {
                      input: {
                        ...pick(
                          createCustomerInput,
                          "billingEmail",
                          "domainStandards",
                          "daysUntilDue",
                          "domainName",
                          "salesforceId",
                          "trialInDays"
                        ),
                        pricesInDollarsPerPaymentInterval: {
                          base: options.basePrice,
                        },
                        ...pick(options, "paymentInterval", "intervalCount"),
                      },
                    },
                  });
                }}
              >
                Create
              </Button>
            </div>
          </div>
        </Dialog>
      );
    default:
      LogErrorMessage("Unknown onboarding step.");
      return null;
  }
};

gql`
  mutation billNewCustomer($input: CreateNewCustomerAndInvoiceInput!) {
    createNewCustomerAndInvoice(input: $input) {
      status
    }
  }
`;
