import React from "react";

import type { GetDataForReportQuery } from "../../../../../gen/components";
import { ReportHeading } from "../../../common";

interface IProps {
  principle: NonNullable<
    GetDataForReportQuery["complianceStandard"]
  >["principles"][number];
}

export const VantaReportPrincipleListItemHeading: React.FunctionComponent<IProps> =
  ({ principle }) => (
    <div className="vrp-principle-list-heading">
      <div className="vrp-principle-section">{principle.section}</div>
      <ReportHeading>{principle.name}</ReportHeading>
    </div>
  );
