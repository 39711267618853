import { Intent } from "@blueprintjs/core";
import { serviceToDisplayName } from "common/base/types/helpers";
import React from "react";
import { Banner } from "../../alpaca/components";

import { Feature } from "../../gen/components";
import { useFeatureCheck } from "../../helpers/feature-gating/feature-check";

/**
 * If disable tasks feature for GitLab/Azure DevOps is enabled, displays an explanatory callout.
 * This component does *not* check if a domain has the relevant service linked.
 */
export const MaybeDisabledIssuesCallout: React.FC<{
  service: "gitlab" | "azuredevops";
}> = ({ service }) => {
  const feature =
    service === "gitlab"
      ? Feature.DisableGitLabTasks
      : Feature.DisableAzureDevOpsTasks;
  const tasksAreDisabled = useFeatureCheck(feature);
  if (tasksAreDisabled) {
    const displayName = serviceToDisplayName(service);
    const serviceNameForTasks = service === "gitlab" ? "issues" : "work items";
    return (
      <Banner
        intent={Intent.SUCCESS}
        title={`Vanta's ${displayName} integration has been updated to include ${displayName}
            ${serviceNameForTasks}`}
        description={`Contact support@vanta.com to enable tracking ${displayName} ${serviceNameForTasks} in Vanta.`}
      />
    );
  }
  return null;
};
