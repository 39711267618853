import "./task.scss";

import { Icon, Intent } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import ReactPropTypes from "prop-types";
import React from "react";

// const HEIGHT_ANIMATION_TIME = 250;

interface IProps {
  completed: boolean;
  expanded?: Maybe<boolean>;
  isFirstIncomplete?: Maybe<boolean>;
  isSequential?: Maybe<boolean>;
  parentIndex?: Maybe<number>;
  title: string;
  titleSecondary?: Maybe<JSX.Element>;
}

interface ITaskChildContext {
  onClick?(t: Task): void;
}

export const ITaskChildTypes: React.ValidationMap<ITaskChildContext> = {
  onClick: ReactPropTypes.func.isRequired,
};

export class Task extends React.Component<IProps> {
  public static contextTypes = ITaskChildTypes;
  public static defaultProps: IProps = {
    completed: false,
    expanded: false,
    parentIndex: -1,
    title: "",
  };

  public context: Maybe<ITaskChildContext>;

  public constructor(props: IProps) {
    super(props);
    this.state = {};

    this.onClick = this.onClick.bind(this);
  }

  public render() {
    const iconOrBlank = this.props.completed ? (
      <Icon icon="tick-circle" intent={Intent.SUCCESS} className="pc-icon" />
    ) : (
      <span className="pc-icon-placeholder" />
    );

    const maybeSelectedClassName = this.props.expanded
      ? "pc-task-expanded"
      : "pc-task-collapsed";
    const maybeClickableClassName = this.isClickable() ? "clickable" : "";

    return (
      <div
        className={`pc-task ${maybeSelectedClassName} ${maybeClickableClassName}`}
        onClick={this.onClick}
      >
        <div className="pc-task-header">
          {iconOrBlank}
          <span className="pc-task-title">{this.props.title}</span>
          {this.props.titleSecondary}
        </div>
        <div className="pc-task-body">
          {this.props.expanded ? this.props.children : undefined}
        </div>
      </div>
    );
  }

  private isClickable() {
    return (
      !(this.props.isSequential ?? true) ||
      this.props.isFirstIncomplete ||
      this.props.completed
    );
  }

  private onClick() {
    if (isSome(this.context) && this.context.onClick && this.isClickable()) {
      this.context.onClick(this);
    }
  }
}
