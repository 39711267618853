import { Menu } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { dropNothing } from "common/base/types/maybe";
import { simplePlural } from "common/grammar/plurals";
import React from "react";

import { getVulnSeverityTag } from "../common/components";
import { VulnerabilityPageEntry } from "../common/vulnerability-page-entry";
import type { VulnSeverity } from "../utils";

interface IProps {
  title: string;
  numMachinesAffected: number;
  severity: VulnSeverity;
  slaDeadline: Maybe<Date>;
  handleClick: Function;
  handleIgnore: Function;
}

export const PackageVulnEntry: React.FC<IProps> = ({
  title,
  numMachinesAffected,
  severity,
  slaDeadline,
  handleClick,
  handleIgnore,
}) => {
  const numAffectedString = `${simplePlural(
    numMachinesAffected,
    "machine"
  )} affected`;

  return (
    <VulnerabilityPageEntry
      primaryInfo={title}
      secondaryInfo={numAffectedString}
      handleClick={handleClick}
      tags={dropNothing([getVulnSeverityTag(severity)])}
      slaDeadline={slaDeadline}
      actionMenu={
        <Menu>
          <Menu.Item
            icon="eye-off"
            onClick={() => handleIgnore()}
            text="Ignore"
          />
        </Menu>
      }
    />
  );
};
