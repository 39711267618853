import type { Maybe } from "common/base/types/maybe";
import React from "react";

import type { GetVaqAnswersQuery } from "../../gen/components";
import { DefaultLink } from "../../helpers/links";
import { PRIVACY_POLICY_URL } from "../../router";
import { vaqFormListIsNavigable } from "./schemas/vaq-state-helpers";
import { VAQSelectableFormList } from "./vaq-form-list";

interface IProps {
  formList: Array<{ id: string; title: string; isCompleted: boolean }>;
  selectedId?: Maybe<string>;
  vaq: NonNullable<GetVaqAnswersQuery["vaqAnswers"]>;
  onSelectItem(id: string): void;
}

export class VAQSidebar extends React.Component<IProps> {
  public render() {
    const sidebarFooter = (
      <div className="vaq-sidebar-footer">
        <strong>Powered by Vanta</strong>
        <p>Companies trust Vanta to manage their security and compliance.</p>
        <span>
          <DefaultLink href={PRIVACY_POLICY_URL}>Privacy Policy</DefaultLink>
          &nbsp;&nbsp;
          <DefaultLink href="mailto:support@vanta.com">Contact us</DefaultLink>
        </span>
      </div>
    );
    const formList = (
      <VAQSelectableFormList
        onSelectItem={this.props.onSelectItem}
        selectedId={this.props.selectedId}
        formList={this.props.formList}
        vaq={this.props.vaq}
        navigable={vaqFormListIsNavigable(this.props.vaq)}
      />
    );

    return (
      <div className="vaq-sidebar">
        <div className="vaq-sidebar-content">
          <div className="vaq-sidebar-body">
            <h3 className="vaq-sidebar-title">
              Demonstrate your security to {this.props.vaq.domainDisplayName}
            </h3>
            {formList}
          </div>

          {sidebarFooter}
        </div>
      </div>
    );
  }
}
