import {
  Checkbox,
  Classes,
  Dialog,
  FormGroup,
  InputGroup,
  Intent,
} from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import React from "react";

import { Button } from "../../alpaca/components";
import { LogError } from "../../errors";
import type {
  BulkWhitelistFailDataMutationMutationFn,
  TestEntity,
} from "../../gen/components";
import { useBulkWhitelistFailDataMutationMutation } from "../../gen/components";
import { updateTestPageQueryCacheWithNewTestResult } from "../pages/tests/update-cache";

interface IProps {
  acknowledgeAllEnabled?: Maybe<boolean>;
  acknowledgeAllCheckboxLabel?: Maybe<string>;
  entity: TestEntity;
  allAcknowledgeableEntities?: Maybe<TestEntity[]>;
  identifierDisplayText?: Maybe<string>;
  dialogText?: Maybe<string | JSX.Element>;
  testId: string;
}

interface IInternalProps extends IProps {
  mutate: BulkWhitelistFailDataMutationMutationFn;
}

interface IState {
  dialogOpen: boolean;
  inFlight: boolean;
  reason: string;
  validationError: Maybe<string>;
  acknowledgeAllChecked: boolean;
}

class Component extends React.Component<IInternalProps, IState> {
  public constructor(props: IInternalProps) {
    super(props);
    this.state = {
      dialogOpen: false,
      inFlight: false,
      reason: "",
      validationError: null,
      acknowledgeAllChecked: false,
    };

    this.onClick = this.onClick.bind(this);
    this.onClickClose = this.onClickClose.bind(this);
    this.onClickDialog = this.onClickDialog.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleReasonChange = this.handleReasonChange.bind(this);
    this.handleAcknowledgeAllCheckboxChange =
      this.handleAcknowledgeAllCheckboxChange.bind(this);
  }

  public componentDidUpdate(prevProps: IProps) {
    if (prevProps.entity !== this.props.entity) {
      this.setState({
        inFlight: false,
      });
    }
  }

  public render() {
    const dialogText =
      this.props.dialogText ??
      "Please provide a response in acknowledging how this issue was resolved.";
    const maybeAcknowledgeAllCheckbox = this.props.acknowledgeAllEnabled ? (
      <Checkbox
        checked={this.state.acknowledgeAllChecked}
        label={
          this.props.acknowledgeAllCheckboxLabel ??
          "Apply this reason to all other resolved items that require acknowledgement."
        }
        onChange={this.handleAcknowledgeAllCheckboxChange}
      />
    ) : null;
    const maybeDialog = this.state.dialogOpen ? (
      <div onClick={this.onClickDialog}>
        <Dialog
          isOpen={true}
          title="Acknowledge"
          usePortal={true}
          onClose={this.onClickClose}
        >
          <div className={Classes.DIALOG_BODY}>
            <p>{dialogText}</p>
            {isSome(this.props.identifierDisplayText) ? (
              <p className={`${Classes.TEXT_MUTED} font-small`}>
                {this.props.identifierDisplayText}
              </p>
            ) : null}
            <p>
              <FormGroup
                helperText={
                  !isSome(this.state.validationError)
                    ? undefined
                    : this.state.validationError
                }
                intent={
                  isSome(this.state.validationError) ? Intent.DANGER : undefined
                }
              >
                <InputGroup
                  onChange={this.handleReasonChange}
                  placeholder={"Write your response here."}
                  value={this.state.reason}
                />
                {maybeAcknowledgeAllCheckbox}
              </FormGroup>
            </p>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button onClick={this.onClickClose}>Cancel</Button>
              <Button intent={Intent.PRIMARY} onClick={this.onSubmit}>
                Acknowledge
              </Button>
            </div>
          </div>
        </Dialog>
      </div>
    ) : null;

    return (
      <div>
        <span>{maybeDialog}</span>
        <div className="fail-data-acknowledge-row">
          <div>{this.props.children}</div>
          <div className="fail-data-acknowledge-button">
            <Button
              intent={Intent.WARNING}
              onClick={this.onClick}
              loading={this.state.inFlight}
            >
              Acknowledge
            </Button>
          </div>
        </div>
      </div>
    );
  }

  private handleAcknowledgeAllCheckboxChange(
    e: React.ChangeEvent<HTMLInputElement>
  ) {
    this.setState({
      acknowledgeAllChecked: e.target.checked,
    });
  }

  private handleReasonChange(e: React.FormEvent<HTMLInputElement>) {
    this.setState({
      reason: (e.target as HTMLInputElement).value,
    });
  }

  private onClick(e: React.MouseEvent<any>) {
    e.stopPropagation();
    this.setState({
      dialogOpen: true,
      reason: "",
    });
  }

  private onClickClose(e: Maybe<React.SyntheticEvent<HTMLElement>>) {
    if (isSome(e)) {
      e.stopPropagation();
    }
    this.setState({
      dialogOpen: false,
    });
  }

  private onClickDialog(e: React.MouseEvent<HTMLElement>) {
    e.stopPropagation();
  }

  private onSubmit(e: React.MouseEvent<HTMLElement>) {
    e.stopPropagation();

    const reason = this.state.reason.trim();
    if (reason.length === 0) {
      this.setState({
        validationError: "You must give a reason when acknowledging this item",
      });
      return;
    }
    const identifiersToWhitelist = this.state.acknowledgeAllChecked
      ? this.props.allAcknowledgeableEntities ?? [this.props.entity]
      : [this.props.entity];
    this.props
      .mutate({
        variables: {
          entityType: identifiersToWhitelist[0].entityType,
          entityIds: identifiersToWhitelist.map(i => i.entityId),
          reason,
          testId: this.props.testId,
        },
      })
      .catch(LogError);

    this.setState({
      dialogOpen: false,
      inFlight: true,
    });
  }
}

export const FailDataRowAcknowledge: React.FC<IProps> = props => {
  const [acknowledgeMutation] = useBulkWhitelistFailDataMutationMutation({
    update: (cache, result) => {
      const updateFn = updateTestPageQueryCacheWithNewTestResult;
      if (result.data?.whitelistAddIdentifierBulk) {
        result.data.whitelistAddIdentifierBulk.map(tr => updateFn(cache, tr));
      }
    },
  });
  return <Component {...props} mutate={acknowledgeMutation} />;
};
