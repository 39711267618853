import React from "react";

import { DefaultLink } from "../../../../helpers/links";
import { SERVICE_DETAILS } from "../../../credentials/service-groups";
import { SimpleCredentialFormDialogBody } from "../simple-credential-form-dialog-body";
import type { IFormCredentialProps } from "./common-interface";

export const Shortcut: React.FunctionComponent<IFormCredentialProps> = ({
  onCredentialsLinked,
}) => (
  <SimpleCredentialFormDialogBody
    labels={[
      {
        name: "token",
        displayName: "API token",
        type: "password",
        helperText: (
          <span>
            Generate an API token from your{" "}
            <DefaultLink href="https://app.shortcut.com/settings/account/api-tokens">
              Shortcut account
            </DefaultLink>
          </span>
        ),
      },
    ]}
    service={SERVICE_DETAILS.clubhouse}
    onCredentialsLinked={onCredentialsLinked}
  />
);
