import React, { useContext } from "react";
import styled from "styled-components";

import { MaybeProfileImage } from "../../helpers/MaybeProfileImage";
import { UserContext } from "../../pages/user-context";
import { Dimensions } from "../constants";
import { AssumedUserStatus } from "./assumed-user-status";

export const UserIcon: React.FC = () => {
  const { user } = useContext(UserContext);
  const url = user?.imageUrl;
  return (
    <FlexContainer>
      <AssumedUserStatus />
      <MaybeProfileImage
        dimension={Dimensions.ICON_DIMENSION}
        url={url}
        circle={true}
        isSuperUser={user?.isAssumedSuperUser}
      />
    </FlexContainer>
  );
};

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;
