import type { Maybe } from "common/base/types/maybe";
import { isSome, nothing } from "common/base/types/maybe";
import React from "react";
import styled from "styled-components";

import { VANTA_COLORS } from "../../../../alpaca/base/colors";

type InfoItem = { key: string; value: string };

interface InfoItemsProps {
  infoItems: InfoItem[];
  columnWidths?: Maybe<string[]>;
}

const VulnerabilityPageInfoItems: React.FC<InfoItemsProps> = ({
  infoItems,
  columnWidths,
}) => {
  const widths = columnWidths ?? [undefined, undefined];
  return (
    <StyledTable>
      <tbody>
        {infoItems.map((infoItem, i) => (
          <tr key={`${infoItem.key}_${i}`}>
            <LeftCell width={widths[0]}>{infoItem.key}</LeftCell>
            <RightCell width={widths[1]}>{infoItem.value}</RightCell>
          </tr>
        ))}
      </tbody>
    </StyledTable>
  );
};

interface InfoProps {
  leftTablePairs: InfoItem[];
  rightTablePairs?: Maybe<InfoItem[]>;
  leftColumnWidths?: Maybe<string[]>;
  rightColumnWidths?: Maybe<string[]>;
}

export const VulnerabilityPageInfo: React.FC<InfoProps> = ({
  leftTablePairs,
  rightTablePairs,
  leftColumnWidths,
  rightColumnWidths,
}) => (
  <div>
    <FlexContainer>
      <LeftTableContainer>
        <VulnerabilityPageInfoItems
          infoItems={leftTablePairs}
          columnWidths={leftColumnWidths}
        />
      </LeftTableContainer>
      {isSome(rightTablePairs) ? (
        <FlexGrow>
          <VulnerabilityPageInfoItems
            infoItems={rightTablePairs}
            columnWidths={rightColumnWidths}
          />
        </FlexGrow>
      ) : (
        nothing
      )}
    </FlexContainer>
  </div>
);

const StyledTable = styled.table`
  font-size: 12px;
  line-height: 14px;
  font-family: monospace;
  &&&& {
    td {
      padding-bottom: 12px;
      border: none;
    }
    tr {
      background-color: transparent;
    }
  }
`;

const LeftCell = styled.td<{ width?: Maybe<string> }>`
  text-align: right;
  vertical-align: top;
  color: ${VANTA_COLORS.TEXT_DESCRIPTION};
  padding-right: 4px;
  width: ${props => props.width ?? "50%"};
`;

const RightCell = styled.td<{ width?: Maybe<string> }>`
  text-align: left;
  vertical-align: top;
  word-break: break-word;
  padding-left: 4px;
  width: ${props => props.width ?? "50%"};
`;

const FlexContainer = styled.div`
  display: flex;
`;

const LeftTableContainer = styled.div`
  margin-right: 48px;
  flex-shrink: 0;
`;

const FlexGrow = styled.div`
  flex-grow: 1;
`;
