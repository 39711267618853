import type { IQuestionSchema } from "../../forms/interfaces";

export const generalSecurity2: IQuestionSchema = {
  id: "generalSecurity2",
  title: "Incident detection and response",
  questions: [
    {
      id: "idrHeader-2018-09-10",
      label: "Incident detection and response",
      name: "idrHeader",
      required: false,
      type: "Header",
    },
    {
      id: "incidentDetectionEmail-2018_09_07",
      label: "Email to report potential incidents",
      name: "incidentDetectionEmail",
      required: false,
      type: "EmailInput",
    },
    {
      choices: [
        "Within 24 hours",
        "Within 48 hours",
        "Within 72 hours",
        "Within one week",
        "No commitment",
      ],
      id: "dataBreachResponseSLA-2018_09_07",
      label: "Time period to first response of a security incident",
      name: "incidentSLA",
      required: false,
      type: "SelectInput",
    },
    {
      id: "incidentResponseTeamRoles-2018_09_07",
      label: "Roles of Incident Response team members",
      name: "incidentResponseTeamRoles",
      required: false,
      type: "TextArea",
    },
    {
      choices: [
        "Immediately",
        "Within 24 hours",
        "Within 48 hours",
        "Within 72 hours",
        "Within one week",
        "Within one month",
        "Within one quarter",
        "No commitment",
      ],
      id: "dataBreachNotificationSLA-2018_09_07",
      label: "Time period to alert customer of an incident",
      name: "dataBreachNotificationSLA",
      required: false,
      type: "SelectInput",
    },
  ],
  submitText: "Submit incident response info",
  version: "2018-09-10",
};
