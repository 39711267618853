import { Spinner } from "@blueprintjs/core";
import { GenericResource } from "common/base/types/gen";
import { isSome } from "common/base/types/maybe";
import type {
  IRiskItem,
  IRiskSchema,
} from "common/schemas/riskRegister/risk-register-content";
import gql from "graphql-tag";
import React from "react";
import styled from "styled-components";

import { LogError } from "../../../errors";
import type { GetInventoryItemsForRiskAssessmentQuery } from "../../../gen/components";
import {
  GetInventoryItemsForRiskAssessmentDocument,
  useAddRiskItemMutation,
  useDeleteRiskItemMutation,
  useDismissRiskMutation,
} from "../../../gen/components";
import { MultiItemSelectPanel } from "../../master-detail-view/detail-panels/multi-item-select-panel";
import { MultiTextInputPanel } from "../../master-detail-view/detail-panels/multi-text-input-panel";
import { useFetchResourceNames } from "./use-fetch-resource-names";

interface IProps {
  riskSchema: IRiskSchema;
  items: IRiskItem[];
}

export const RiskItemSelectionFlow: React.FC<IProps> = ({
  riskSchema,
  items,
}) => {
  const maybeResourceQuery = useFetchResourceNames(riskSchema);
  const hasResource = isSome(riskSchema.resourceKind);
  const currentlyLoadingResources = hasResource && maybeResourceQuery?.loading;
  const resources = maybeResourceQuery?.data ?? [];

  const [dismissRisk] = useDismissRiskMutation({
    update: (cache, result) => {
      const newDismissal = result.data?.dismissRisk;
      if (!isSome(newDismissal)) {
        return;
      }
      const previousData =
        cache.readQuery<GetInventoryItemsForRiskAssessmentQuery>({
          query: GetInventoryItemsForRiskAssessmentDocument,
        });
      if (!isSome(previousData) || !isSome(previousData.organization)) {
        return;
      }

      cache.writeQuery<GetInventoryItemsForRiskAssessmentQuery>({
        query: GetInventoryItemsForRiskAssessmentDocument,
        data: {
          organization: {
            ...previousData.organization,
            riskDismissals:
              previousData.organization.riskDismissals.concat(newDismissal),
          },
        },
      });
    },
  });
  const [addRiskItem] = useAddRiskItemMutation({
    update: (cache, result) => {
      const itemToAdd = result.data?.addRiskItem;
      if (!isSome(itemToAdd)) {
        return;
      }
      const previousData =
        cache.readQuery<GetInventoryItemsForRiskAssessmentQuery>({
          query: GetInventoryItemsForRiskAssessmentDocument,
        });
      if (!isSome(previousData) || !isSome(previousData.organization)) {
        return;
      }

      cache.writeQuery<GetInventoryItemsForRiskAssessmentQuery>({
        query: GetInventoryItemsForRiskAssessmentDocument,
        data: {
          organization: {
            ...previousData.organization,
            riskEvaluations:
              previousData.organization.riskEvaluations.concat(itemToAdd),
          },
        },
      });
    },
  });
  const [deleteRiskItem] = useDeleteRiskItemMutation({
    update: (cache, result) => {
      const itemToDelete = result.data?.deleteRiskEvaluation;
      if (!isSome(itemToDelete)) {
        return;
      }
      const previousData =
        cache.readQuery<GetInventoryItemsForRiskAssessmentQuery>({
          query: GetInventoryItemsForRiskAssessmentDocument,
        });
      if (!isSome(previousData) || !isSome(previousData.organization)) {
        return;
      }

      cache.writeQuery<GetInventoryItemsForRiskAssessmentQuery>({
        query: GetInventoryItemsForRiskAssessmentDocument,
        data: {
          organization: {
            ...previousData.organization,
            riskEvaluations: previousData.organization.riskEvaluations.filter(
              item => item.id !== itemToDelete.id
            ),
          },
        },
      });
    },
  });

  const optOutText = "This does not apply to me";
  const onOptOut = () => {
    dismissRisk({ variables: { riskId: riskSchema.id } }).catch(LogError);
  };

  /**
   * Hack - Risk Register v2 predates container resource fetches.
   *
   * As resource-backed steps in v2 don't allow addition of arbitrary assets,
   * keep the original selection flow for now.
   */
  if (
    isSome(riskSchema.resourceKind) &&
    riskSchema.resourceKind !== GenericResource.ContainerRepository
  ) {
    if (currentlyLoadingResources) {
      return <Spinner />;
    }
    return (
      <MultiItemSelectPanel
        emptyState={
          <EmptyStateContainer>No data available</EmptyStateContainer>
        }
        selectedItems={items.map(item => item.itemName)}
        selectableItems={resources.map(r => r.name ?? r.uniqueId)}
        textContent={riskSchema.itemSelectionPrompt}
        onItemAdded={value => {
          addRiskItem({
            variables: { riskId: riskSchema.id, itemName: value },
          }).catch(LogError);
        }}
        onItemRemoved={value => {
          const itemToDelete = items.find(item => item.itemName === value);
          if (!isSome(itemToDelete)) {
            return;
          }
          deleteRiskItem({
            variables: { evaluationId: itemToDelete.id },
          }).catch(LogError);
        }}
        optOutTextContent={optOutText}
        onOptOut={onOptOut}
      />
    );
  }

  return (
    <MultiTextInputPanel
      onValueAdded={value => {
        addRiskItem({
          variables: { riskId: riskSchema.id, itemName: value },
        }).catch(LogError);
      }}
      onValueRemoved={value => {
        const itemToDelete = items.find(item => item.itemName === value);
        if (!isSome(itemToDelete)) {
          return;
        }
        deleteRiskItem({ variables: { evaluationId: itemToDelete.id } }).catch(
          LogError
        );
      }}
      values={items.map(item => item.itemName)}
      textContent={riskSchema.itemSelectionPrompt}
      placeholder={riskSchema.placeholderText ?? ""}
      optOutTextContent={optOutText}
      onOptOut={onOptOut}
    />
  );
};

const styles = {
  EMPTY_STATE_BOTTOM_MARGIN: 18,
};

const EmptyStateContainer = styled.div`
  margin-bottom: ${styles.EMPTY_STATE_BOTTOM_MARGIN}px;
`;

gql`
  mutation AddRiskItem($riskId: String!, $itemName: String!) {
    addRiskItem(riskId: $riskId, itemName: $itemName) {
      id
      itemName
      riskId
      likelihood
      impact
    }
  }
`;

gql`
  mutation deleteRiskItem($evaluationId: String!) {
    deleteRiskEvaluation(evaluationId: $evaluationId) {
      id
      itemName
      riskId
      likelihood
      impact
    }
  }
`;

gql`
  mutation dismissRisk($riskId: String!) {
    dismissRisk(riskId: $riskId) {
      id
      riskId
    }
  }
`;
