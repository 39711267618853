import { Button, Spinner } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { dateStringToDate } from "common/base/dateUtils";
import { SpecificResource } from "common/base/types/gen";
import type { Maybe } from "common/base/types/maybe";
import { dropNothing, isSome, nothing } from "common/base/types/maybe";
import {
  getValueForReservedVantaAttribute,
  ReservedVantaAttributes,
} from "common/utils/vantaAttributes";
import gql from "graphql-tag";
import React, { useState } from "react";
import { useHistory } from "react-router";

import { BASE_TYPOGRAPHY } from "../../../../alpaca/base/typography";
import {
  BodyText,
  Button as AlpacaButton,
  DefaultView,
} from "../../../../alpaca/components";
import { LogError } from "../../../../errors";
import type { FetchGcpRepositoryVulnerabilityInfoQuery } from "../../../../gen/components";
import {
  FetchGcpRepositoryVulnerabilityInfoDocument,
  useFetchGcpRepositoryVulnerabilityInfoQuery,
} from "../../../../gen/components";
import { DataTable } from "../../components/data-table";
import {
  LoadingContainer,
  PackageIdentifier,
  RightButton,
  TabContainer,
  TableContainer,
  VulnerabilityFindingsHeading,
} from "../common/components";
import { ConfigureImageDialog } from "../common/configure-image-dialog";
import { IgnoreContainerVulnDialog } from "../common/ignore-container-vuln-dialog";
import { LinksForCVEs } from "../common/link-for-cve";
import { SeverityPill } from "../common/severity-pill";
import { SLADeadlineWithDate } from "../common/sla-deadline-with-date";
import { slaDeadlineComparator } from "../utils";
import { TAB_IDS } from "../vulns-page";
import { GCPContainerRepositoryInfo } from "./gcp-container-repository-info";

interface IProps {
  resourceId: string;
}

type GCPContainerVulnerability = Extract<
  NonNullable<
    FetchGcpRepositoryVulnerabilityInfoQuery["organization"]
  >["resourcesByIds"][number],
  { __typename?: Maybe<"SpecificGCPContainerRepositoryResource"> }
>["vulnerabilities"][number];

export const GCPContainerRepositoryDetail: React.FC<IProps> = ({
  resourceId,
}) => {
  const history = useHistory();
  const { error, loading, data } = useFetchGcpRepositoryVulnerabilityInfoQuery({
    variables: { resourceId },
  });
  const [configureDialogIsOpen, setConfigureDialogIsOpen] = useState(false);
  const [vulnToIgnore, setVulnToIgnore] =
    useState<Maybe<GCPContainerVulnerability>>(null);

  if (error) {
    LogError(error);
    history.push(`/vulnerabilities/#${TAB_IDS.gcpContainers}`);
    return <div />;
  }

  if (loading) {
    return (
      <LoadingContainer>
        <Spinner />
      </LoadingContainer>
    );
  }

  const repository = data?.organization.resourcesByIds?.[0];

  if (
    !isSome(repository) ||
    repository.__typename !== "SpecificGCPContainerRepositoryResource"
  ) {
    LogError(
      new Error("GCP repository not found or found with incorrect __typename")
    );
    return <div />;
  }

  const vulnerabilities = repository.vulnerabilities;
  const imageDigest = vulnerabilities[0]?.fullDigest;
  const imageTags = vulnerabilities[0]?.imageTags;
  const repoUrl = repository.externalURL!;
  const vulnUrl = vulnerabilities[0]?.externalURL;
  const targetImageScanTag = getValueForReservedVantaAttribute(
    ReservedVantaAttributes.targetImageScanTag,
    repository.vantaAttributes ?? []
  );

  return (
    <DefaultView
      headerProps={{
        backLink: `/vulnerabilities#${TAB_IDS.gcpContainers}`,
        title: repository.name,
        description: repository.uniqueId,
        rightControls: [
          <AlpacaButton
            key="view-gcp"
            onClick={() => {
              window.open(repoUrl, "_blank");
            }}
          >
            View in GCP
          </AlpacaButton>,
        ],
      }}
    >
      <TabContainer>
        <RightButton>
          <Button icon="more" onClick={() => setConfigureDialogIsOpen(true)} />
        </RightButton>
        <GCPContainerRepositoryInfo
          repositoryName={repository.name}
          repositoryUniqueId={repository.uniqueId}
          projectId={repository.projectId}
          scannedImageDigest={imageDigest}
          imageTags={imageTags}
        />

        {vulnerabilities.length === 0 ? (
          <BodyText fontWeight={BASE_TYPOGRAPHY.FONT_WEIGHTS.BOLD}>
            Woohoo! No vulnerabilities have been identified in the image scan.
          </BodyText>
        ) : (
          <TableContainer>
            <VulnerabilityFindingsHeading
              numVulns={vulnerabilities.length}
              service="GCP"
              url={vulnUrl}
              tooltipText={`Vanta filters out vulnerabilities GCP indicates have
                no fix available, and groups together vulnerabilities
                corresponding to the same package`}
            />
            <DataTable
              columnWidths={["230px", "230px", "400px", "150px", "60px"]}
              columnOrder={["package", "slaDeadline", "cves", "severity"]}
              data={[...vulnerabilities].sort(slaDeadlineComparator)}
              header={{
                package: "Package",
                slaDeadline: "Time until SLA violation",
                cves: "CVEs",
                severity: "Severity",
              }}
              menuOptions={() => [
                { text: "Ignore", iconName: IconNames.EYE_OFF, flag: "IGNORE" },
              ]}
              onMenuItemClick={(flag, clickedVuln) => {
                if (flag === "IGNORE") {
                  setVulnToIgnore(clickedVuln);
                }
              }}
              createRow={vulnerability => {
                const {
                  packageName,
                  packageVersion,
                  slaDeadline,
                  occurrences,
                  severity,
                } = vulnerability;
                return {
                  package: (
                    <PackageIdentifier
                      packageName={packageName}
                      version={packageVersion}
                    />
                  ),
                  slaDeadline: isSome(slaDeadline) ? (
                    <SLADeadlineWithDate
                      slaDeadline={dateStringToDate(slaDeadline)}
                    />
                  ) : (
                    <div>-</div>
                  ),
                  // For now, every vulnerability short description from GCP seems to be a single CVE, so treat it that way.
                  cves: (
                    <LinksForCVEs
                      cves={dropNothing(
                        occurrences.map(occurrence =>
                          isSome(occurrence.shortDescription) &&
                          occurrence.shortDescription.trim().length > 0
                            ? { name: occurrence.shortDescription }
                            : nothing
                        )
                      )}
                    />
                  ),
                  severity: <SeverityPill severity={severity} />,
                };
              }}
            />
          </TableContainer>
        )}

        <ConfigureImageDialog
          configuredTag={targetImageScanTag}
          specificResource={SpecificResource.GCPContainerRepository}
          resourceMongoId={repository.id}
          isOpen={configureDialogIsOpen}
          onClose={() => setConfigureDialogIsOpen(false)}
        />

        {isSome(vulnToIgnore) ? (
          <IgnoreContainerVulnDialog
            refetchQueries={[
              {
                query: FetchGcpRepositoryVulnerabilityInfoDocument,
                variables: { resourceId },
              },
            ]}
            packageIdentifier={vulnToIgnore.packageIdentifier}
            entityId={vulnToIgnore.id}
            service={"gcp"}
            onClose={() => setVulnToIgnore(nothing)}
          />
        ) : null}
      </TabContainer>
    </DefaultView>
  );
};

gql`
  query fetchGCPRepositoryVulnerabilityInfo($resourceId: String!) {
    organization {
      id
      resourcesByIds(
        ids: [$resourceId]
        specificResourceType: GCPContainerRepository
      ) {
        id
        uniqueId
        vantaAttributes {
          key
          value
          managedExternally
        }
        ... on SpecificGCPContainerRepositoryResource {
          name
          projectId
          externalURL
          vulnerabilities {
            id
            fullDigest
            imageTags
            externalURL
            packageName
            packageVersion
            packageIdentifier
            slaDeadline
            severity
            occurrences {
              shortDescription
            }
          }
        }
      }
    }
  }
`;
