import "./query-page.scss";

import type { ApolloClient } from "@apollo/client";
import { ApolloConsumer } from "@apollo/client";
import type { IPanelProps } from "@blueprintjs/core";
import { Button, H5, InputGroup, Intent } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import moment from "moment";
import React from "react";

import { LogError, LogErrorMessage } from "../../errors";
import type {
  CreateAuditMutation,
  CreateAuditMutationVariables,
  GetAuditFirmsAndAuditTypesQuery,
  GetDomainByNameQuery,
  GetDomainByNameQueryVariables,
} from "../../gen/components";
import {
  CreateAuditDocument,
  GetDomainByNameDocument,
  useGetAuditFirmsAndAuditTypesQuery,
} from "../../gen/components";
import { FullPageSpinner } from "../helpers/FullPageSpinner";
import { AuditDatePicker } from "./audit-date-picker";
import { processError, reportError, reportSuccess } from "./reportError";

interface IProps {
  auditFirms?: Maybe<GetAuditFirmsAndAuditTypesQuery["allAuditFirms"]>;
  auditTypes?: Maybe<GetAuditFirmsAndAuditTypesQuery["auditTypes"]>;
}

interface IAuditor {
  id: string;
  name: string;
  firmId: string;
  firmName: string;
}

interface IState {
  loading: boolean;
  auditors: IAuditor[];
  selectedAuditor?: Maybe<IAuditor>;
  formInput: string;
  domain?: Maybe<GetDomainByNameQuery["internal"]["domainByName"]>;
  startDate?: Maybe<Date>;
  endDate?: Maybe<Date>;
  selectedAuditType?: Maybe<
    GetAuditFirmsAndAuditTypesQuery["auditTypes"][number]
  >;
}

type IExtendedProps = IProps & { client: ApolloClient<any> } & IPanelProps;

const LINFORD_DOMAIN_NAME = "linfordco.com";
class ScheduleAuditComponent extends React.Component<IExtendedProps, IState> {
  public constructor(props: IExtendedProps) {
    super(props);
    this.state = {
      loading: false,
      formInput: "",
      auditors: [],
    };
  }

  public componentDidMount() {
    this.setState({
      auditors: this.makeAuditors(),
    });
  }

  public render() {
    const handleInputChange = (event: React.FormEvent<HTMLInputElement>) => {
      this.setState({
        formInput: event.currentTarget.value,
        domain: undefined,
      });
    };

    const handleFormSubmit = (event: React.SyntheticEvent<HTMLFormElement>) => {
      event.preventDefault();
      return this.queryGetDomainByName(this.state.formInput);
    };

    const clearFormInput = (
      <Button
        icon="delete"
        minimal
        onClick={() =>
          this.setState({
            formInput: "",
            domain: undefined,
          })
        }
      />
    );

    const lookupDomainButton = (
      <Button
        type="button"
        intent={Intent.PRIMARY}
        disabled={this.state.formInput === ""}
        onClick={() => this.queryGetDomainByName(this.state.formInput)}
      >
        Lookup
      </Button>
    );

    const companyDomain = (
      <form onSubmit={handleFormSubmit} className="form-group">
        <label>
          <div>Domain</div>
          <InputGroup
            autoCapitalize={"off"}
            autoComplete={"off"}
            autoCorrect={"off"}
            autoFocus={true}
            name="formInput"
            placeholder="acme.com"
            required={false}
            type="text"
            onChange={handleInputChange}
            rightElement={clearFormInput}
            value={this.state.formInput}
          />
          {lookupDomainButton}
        </label>
      </form>
    );

    const onPickedDates = ([startDate, endDate]: [Maybe<Date>, Maybe<Date>]) =>
      this.setState({
        startDate,
        endDate,
      });

    const dateRangePicker = (
      <div>
        <H5>Audit dates</H5>
        <AuditDatePicker
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          onChange={onPickedDates}
        />
      </div>
    );

    const scheduleAuditButton = (
      <Button
        type="button"
        intent={Intent.PRIMARY}
        onClick={async () => this.scheduleAudit()}
      >
        Schedule Audit
      </Button>
    );

    const cancelButton = (
      <Button
        className="qp-align-right"
        type="button"
        intent={Intent.NONE}
        onClick={() => this.props.closePanel()}
      >
        Cancel
      </Button>
    );

    const midPanel = (
      <div>
        <H5>Schedule an audit</H5>
        {companyDomain}
        {this.renderAuditors()}
        {dateRangePicker}
        {this.renderAuditTypes()}
        {scheduleAuditButton}
        {cancelButton}
      </div>
    );
    return (
      <div className="panel-container">
        <div className="mid-panel qp-fit-width-panel">{midPanel}</div>
        <div className="right-panel" />
      </div>
    );
  }

  private makeAuditors(): IAuditor[] {
    if (!isSome(this.props.auditFirms)) {
      return [];
    }
    const auditors: IAuditor[] = [];
    this.props.auditFirms.forEach(firm => {
      if (isSome(firm) && isSome(firm.domain.users)) {
        firm.domain.users.forEach(user => {
          auditors.push({
            id: user.id,
            name: isSome(user.displayName) ? user.displayName : "missing name",
            firmId: firm.domain.id,
            firmName: firm.domain.name,
          });
        });
      }
    });
    auditors.sort((x, y) => {
      const diff = x.firmName.localeCompare(y.firmName);
      return diff !== 0 ? diff : x.name.localeCompare(y.name);
    });
    return auditors;
  }

  private queryGetDomainByName(name: string) {
    this.setState({ loading: true });
    this.props.client
      .query<GetDomainByNameQuery, GetDomainByNameQueryVariables>({
        query: GetDomainByNameDocument,
        variables: {
          name,
        },
      })
      .then((info): void => {
        if (!isSome(info.data)) {
          this.setState({
            loading: false,
            domain: undefined,
          });
          return reportError("Domain not found.");
        }
        this.setState({
          loading: false,
          domain: info.data.internal.domainByName,
        });
      })
      .catch((e: Error) => {
        this.setState({
          loading: false,
          domain: undefined,
        });
        processError(e, "Domain lookup error");
      });
  }

  private renderAuditors() {
    if (!isSome(this.state.auditors)) {
      return undefined;
    }
    const showAuditor = (auditor: IAuditor) => (
      <option key={auditor.id} value={auditor.id}>
        {auditor.firmName} - {auditor.name}
      </option>
    );

    const onSelectAuditor = (evt: React.ChangeEvent<HTMLSelectElement>) => {
      const auditorId = evt.target.value;
      if (!isSome(this.state.auditors)) {
        return;
      }
      const selectedAuditor = this.state.auditors.find(
        auditor => auditor.id === auditorId
      );
      this.setState({
        selectedAuditor,
      });
    };

    return (
      <div className="qp-selector-box">
        <H5>Auditor</H5>
        <select onChange={onSelectAuditor}>
          <option value="">Available auditors</option>
          {this.state.auditors.map(auditor => showAuditor(auditor))}
        </select>
      </div>
    );
  }

  private renderAuditTypes() {
    if (!isSome(this.props.auditTypes)) {
      return undefined;
    }
    const showAuditType = (
      auditType: Maybe<GetAuditFirmsAndAuditTypesQuery["auditTypes"][number]>
    ) =>
      isSome(auditType) ? (
        <option key={auditType.auditType} value={auditType.auditType}>
          {auditType.name}
        </option>
      ) : undefined;

    const onSelectAuditType = (evt: React.ChangeEvent<HTMLSelectElement>) => {
      const auditTypeValue = evt.target.value;
      if (!isSome(this.props.auditTypes)) {
        return;
      }
      const selectedAuditType = this.props.auditTypes.find(
        at => isSome(at) && at.auditType === auditTypeValue
      );
      this.setState({
        selectedAuditType,
      });
    };

    return (
      <div className="qp-selector-box">
        <H5>Audit Type</H5>
        <select onChange={onSelectAuditType}>
          <option value="">Available audit types</option>
          {this.props.auditTypes.map(at => showAuditType(at))}
        </select>
      </div>
    );
  }

  private async scheduleAudit() {
    if (!isSome(this.state.domain)) {
      return reportError("Domain is not set");
    }
    if (!isSome(this.state.selectedAuditor)) {
      return reportError("Auditor is not selected");
    }
    if (!isSome(this.state.selectedAuditType)) {
      return reportError("Audit type is not selected");
    }
    if (!isSome(this.state.startDate)) {
      return reportError("Audit start date is not selected");
    }
    if (!isSome(this.state.endDate)) {
      return reportError("Audit end date is not selected");
    }
    return this.props.client
      .mutate<CreateAuditMutation, CreateAuditMutationVariables>({
        mutation: CreateAuditDocument,
        variables: {
          domainId: this.state.domain.id,
          auditorId: this.state.selectedAuditor.id,
          auditorDomainId: this.state.selectedAuditor.firmId,
          auditType: this.state.selectedAuditType.auditType,
          auditStart: this.state.startDate.getTime(),
          auditLengthDays: moment(this.state.endDate).diff(
            moment(this.state.startDate),
            "days"
          ),
          isLinfordAuditor:
            this.state.selectedAuditor.firmName === LINFORD_DOMAIN_NAME,
        },
      })
      .then(() => {
        reportSuccess("Audit scheduled!");
        location.href = "/query/audit-calendar";
      })
      .catch(processError);
  }
}

gql`
  query GetAuditFirmsAndAuditTypes {
    allAuditFirms {
      id
      domain {
        id
        name
        users {
          id
          displayName
        }
      }
    }
    auditTypes {
      auditType
      name
    }
  }
`;

gql`
  query GetDomainByName($name: String!) {
    internal {
      domainByName(name: $name) {
        id
        name
      }
    }
  }
`;

gql`
  mutation CreateAudit(
    $domainId: ID!
    $auditorId: String!
    $auditorDomainId: String!
    $auditType: auditTypeEnum!
    $auditStart: Float!
    $auditLengthDays: Int!
    $isLinfordAuditor: Boolean!
  ) {
    startAuditWithoutCharging(
      auditStart: $auditStart
      auditorUserId: $auditorId
      auditorDomainId: $auditorDomainId
      auditLengthDays: $auditLengthDays
      domainId: $domainId
      auditType: $auditType
      isLinfordAuditor: $isLinfordAuditor
    ) {
      id
    }
  }
`;

const AuditPanel: React.FC<IExtendedProps> = props => {
  const { error, loading, data } = useGetAuditFirmsAndAuditTypesQuery();
  if (error) {
    LogError(error);
    return null;
  }
  if (loading) {
    return <FullPageSpinner />;
  }
  if (!data) {
    LogErrorMessage("Bad fetch");
    return null;
  }
  return (
    <ScheduleAuditComponent
      {...props}
      auditFirms={data.allAuditFirms}
      auditTypes={data.auditTypes}
    />
  );
};

export const ScheduleAuditPanel: React.FC<IProps & IPanelProps> = props => (
  <ApolloConsumer>
    {client => <AuditPanel client={client} {...props} />}
  </ApolloConsumer>
);
