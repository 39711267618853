import type { IconName } from "@blueprintjs/core";
import { Menu, MenuItem } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import classnames from "classnames";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import React from "react";
import type { FlattenSimpleInterpolation } from "styled-components";
import styled from "styled-components";

import { Button, Tooltip } from "../../../alpaca/components";
import type { IMenuOption } from "./data-table";

interface IProps<T> {
  columnClasses: { [k: string]: string };
  columnStyles: { [k: string]: Maybe<FlattenSimpleInterpolation> };
  columnStylesNative: { [k: string]: Maybe<React.CSSProperties> };
  columnOrder: readonly string[];
  columnVisibilities: { [k: string]: boolean };
  data: T;
  menuOptions?: Maybe<IMenuOption[]>;
  menuOptionsDisabledText: string;
  rowClass?: Maybe<string>;
  rowData: { [k: string]: Maybe<string | number | JSX.Element> };
  onMenuItemClick?(flag: string, data: T): void;
  onClick?(data: T, event: React.MouseEvent): void;
}

interface IState {
  menuPopoverIsOpen: boolean;
}

export class DataTableRow<T> extends React.Component<IProps<T>, IState> {
  public constructor(props: IProps<T>) {
    super(props);
    this.onMenuItemClick = this.onMenuItemClick.bind(this);
    this.state = {
      menuPopoverIsOpen: false,
    };
  }

  public render() {
    const menuItems = !isSome(this.props.menuOptions)
      ? undefined
      : this.props.menuOptions.map((opt, idx) => {
          const item = (
            <MenuItem
              icon={opt.iconName as IconName}
              key={idx}
              onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                e.stopPropagation();
                this.onMenuItemClick(idx);
              }}
              text={opt.text}
              disabled={Boolean(opt.disabled)}
            />
          );
          if (opt.disabled && isSome(opt.disabledTooltipText)) {
            return (
              <Tooltip
                key={idx}
                content={opt.disabledTooltipText}
                placement="left"
                openOnTargetFocus={false}
              >
                {item}
              </Tooltip>
            );
          } else {
            return item;
          }
        });

    const popoverCell = !isSome(menuItems) ? undefined : (
      <td>
        <Popover2
          placement={"left"}
          isOpen={this.state.menuPopoverIsOpen}
          onClose={() =>
            this.setState({
              menuPopoverIsOpen: false,
            })
          }
          content={<Menu>{menuItems}</Menu>}
        >
          <Button
            icon="more"
            disabled={menuItems.length === 0}
            tooltipContent={this.props.menuOptionsDisabledText}
            onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
              e.stopPropagation();
              this.setState({
                menuPopoverIsOpen: !this.state.menuPopoverIsOpen,
              });
            }}
            style={{ height: "24px", minHeight: "24px" }}
          />
        </Popover2>
      </td>
    );
    return (
      <tr
        onClick={e => {
          if (isSome(this.props.onClick)) {
            this.props.onClick(this.props.data, e);
          }
        }}
        className={classnames(this.props.rowClass)}
      >
        {this.props.columnOrder.map(c => {
          if (
            !(c in this.props.columnVisibilities) ||
            this.props.columnVisibilities[c]
          ) {
            const columnStyle = this.props.columnStyles[c];
            if (columnStyle) {
              // this call can be quite expensive in a large table where it gets called on every cell
              // this should be avoided when columnStyle is undefined (which is very common)
              const StyledTd = styleTd(columnStyle);
              return (
                <StyledTd
                  style={this.props.columnStylesNative[c] ?? undefined}
                  key={c}
                  className={this.props.columnClasses[c]}
                >
                  {this.props.rowData[c]}
                </StyledTd>
              );
            }
            return (
              <td
                style={this.props.columnStylesNative[c] ?? undefined}
                key={c}
                className={this.props.columnClasses[c]}
              >
                {this.props.rowData[c]}
              </td>
            );
          }
          return null;
        })}
        {popoverCell}
      </tr>
    );
  }

  private onMenuItemClick(idx: number) {
    if (!isSome(this.props.onMenuItemClick)) {
      throw new Error("onMenuItemClick undefined in manage-table-row");
    }
    if (!isSome(this.props.menuOptions)) {
      throw new Error("menuItems in manage-table-row");
    }

    this.props.onMenuItemClick(
      this.props.menuOptions[idx].flag,
      this.props.data
    );
  }
}

const styleTd = (styles: FlattenSimpleInterpolation) => styled.td`
  ${styles}
`;
