import type { Maybe } from "common/base/types/maybe";
import type { PolicyType } from "common/constants/policyInfoCommon";

// Policy template static files are kept in web/client/src/static/policy-templates
// Google doc links are an exception

// Google doc templates are found here: https://drive.google.com/drive/u/0/folders/1Q2WHu_7UmXpZPSG-IQRTQ9wVfmpa3LR1
// When adding/updating a google doc, get the link via:
// 1. Right click file in drive
// 2. Get link
// 3. Change Anyone with the link --> Viewer
// 4. Paste link here
interface PolicyTemplate {
  DOCX?: Maybe<string>; // path to static file
  MD?: Maybe<string>; // path to static file
  GDOC?: Maybe<string>; // url to google doc share link
  XLSX?: Maybe<string>; // path to static file
}

// We store the markdown files as .markdown files as a hack to get around require("*.md") loading the .md file in as raw text.
// On download, files are still formatted with the extension .md
export const POLICY_TEMPLATES: { [k in PolicyType]: PolicyTemplate } = {
  "acceptable-use-policy": {
    DOCX: require("url:../../../static/policy-templates/DOCX/acceptable-use-policy-vanta.docx"),
    MD: require("url:../../../static/policy-templates/MD/acceptable-use-policy-vanta.markdown"),
    GDOC: "https://docs.google.com/document/d/12sFB79AbwLiBeScsoq_jd6s8jm-umSaNtTmxTVGqTMw/edit?usp=sharing",
  },
  "asset-management-policy": {
    DOCX: require("url:../../../static/policy-templates/DOCX/asset-management-policy-vanta.docx"),
    MD: require("url:../../../static/policy-templates/MD/asset-management-policy-vanta.markdown"),
    GDOC: "https://docs.google.com/document/d/1cUT6MXF5oo5WT1B7Vtqs0fQRCRFAiJILH48KyMFF1nM/edit?usp=sharing",
  },
  "backup-policy": {
    DOCX: require("url:../../../static/policy-templates/DOCX/backup-policy-vanta.docx"),
    MD: require("url:../../../static/policy-templates/MD/backup-policy-vanta.markdown"),
    GDOC: "https://docs.google.com/document/d/19CbLF-0AywhGo0HTmldfp4E0t9zN1wysapZ_Db5c0Jo/edit?usp=sharing",
  },
  "business-continuity-plan": {
    DOCX: require("url:../../../static/policy-templates/DOCX/business-continuity-plan-vanta.docx"),
    MD: require("url:../../../static/policy-templates/MD/business-continuity-plan-vanta.markdown"),
    GDOC: "https://docs.google.com/document/d/1xuzrXi4hcy7a5BKULR04VeQ_EB7MhJkWOISVRsB2KlI/edit?usp=sharing",
  },
  "change-management-policy": {
    DOCX: require("url:../../../static/policy-templates/DOCX/change-management-policy-vanta.docx"),
    MD: require("url:../../../static/policy-templates/MD/change-management-policy-vanta.markdown"),
    GDOC: "https://docs.google.com/document/d/1JxtwiZHtRTnr9L6lapPkjUPG9gS9ySGKwKvTSHxC_Lc/edit?usp=sharing",
  },
  "code-of-conduct-policy": {
    DOCX: require("url:../../../static/policy-templates/DOCX/code-of-conduct-policy-vanta.docx"),
    MD: require("url:../../../static/policy-templates/MD/code-of-conduct-policy-vanta.markdown"),
    GDOC: "https://docs.google.com/document/d/1uaC_B4Y2j5xuciLBWNZsIb81JmO-ZJ9uQmcR1qTdzkc/edit?usp=sharing",
  },
  "cryptography-policy": {
    DOCX: require("url:../../../static/policy-templates/DOCX/cryptography-policy-vanta.docx"),
    MD: require("url:../../../static/policy-templates/MD/cryptography-policy-vanta.markdown"),
    GDOC: "https://docs.google.com/document/d/13Irdr7LTXuQVdLlSf5qXBq0-iK3Y5-VxtatbkNeH648/edit?usp=sharing",
  },
  "data-classification-policy": {
    DOCX: require("url:../../../static/policy-templates/DOCX/data-classification-policy-vanta.docx"),
    MD: require("url:../../../static/policy-templates/MD/data-classification-policy-vanta.markdown"),
    GDOC: "https://docs.google.com/document/d/15-5r3F0E4QTPOQ5avwgmqGCgnJ8yXnrIcSc3f7CXnNw/edit?usp=sharing",
  },
  "data-delete-policy": {
    DOCX: require("url:../../../static/policy-templates/DOCX/data-delete-policy-vanta.docx"),
    MD: require("url:../../../static/policy-templates/MD/data-delete-policy-vanta.markdown"),
    GDOC: "https://docs.google.com/document/d/1XwjnG3d-mFVSP2cuv1EYs3OOgBW6Hjccq4WyELsakR8/edit?usp=sharing",
  },
  "data-protection-policy": {
    DOCX: require("url:../../../static/policy-templates/DOCX/data-protection-policy-vanta.docx"),
    MD: require("url:../../../static/policy-templates/MD/data-protection-policy-vanta.markdown"),
    GDOC: "https://docs.google.com/document/d/1LO44nS1Vse0YE2btQQfLels8FbhjG_VBNfGprc__hU0/edit?usp=sharing",
  },
  "disaster-recovery-plan": {
    DOCX: require("url:../../../static/policy-templates/DOCX/disaster-recovery-plan-vanta.docx"),
    MD: require("url:../../../static/policy-templates/MD/disaster-recovery-plan-vanta.markdown"),
    GDOC: "https://docs.google.com/document/d/1gsZlEuLHwKlrKICr6GXjFi1X1I6pPeAD1qlz9ku-Sio/edit?usp=sharing",
  },
  "incident-response-plan": {
    DOCX: require("url:../../../static/policy-templates/DOCX/incident-response-plan-vanta.docx"),
    MD: require("url:../../../static/policy-templates/MD/incident-response-plan-vanta.markdown"),
    GDOC: "https://docs.google.com/document/d/1ZcS7N41sYVZMG4sXq2ktN6bMFWAOVAydOfouShjPAS8/edit?usp=sharing",
  },
  "information-security-policy": {
    DOCX: require("url:../../../static/policy-templates/DOCX/information-security-policy-vanta.docx"),
    MD: require("url:../../../static/policy-templates/MD/information-security-policy-vanta.markdown"),
    GDOC: "https://docs.google.com/document/d/1qGmpofAxRf24eWdglpdM17c19QbBDf7HWa15UvxZfh8/edit?usp=sharing",
  },
  "password-policy": {
    DOCX: require("url:../../../static/policy-templates/DOCX/password-policy-vanta.docx"),
    MD: require("url:../../../static/policy-templates/MD/password-policy-vanta.markdown"),
    GDOC: "https://docs.google.com/document/d/19vCPU_jxPzb5TAeyDwzl4E3pLD-vzJ_iE4pCbcpLOKM/edit?usp=sharing",
  },
  "physical-security-policy": {
    DOCX: require("url:../../../static/policy-templates/DOCX/physical-security-policy-vanta.docx"),
    MD: require("url:../../../static/policy-templates/MD/physical-security-policy-vanta.markdown"),
    GDOC: "https://docs.google.com/document/d/1-ktX8lYZJXeZYv1ycLjSmjFgSIm2fJGy9cD17NEXcLI/edit?usp=sharing",
  },
  "responsible-disclosure-policy": {
    DOCX: require("url:../../../static/policy-templates/DOCX/responsible-disclosure-policy-vanta.docx"),
    MD: require("url:../../../static/policy-templates/MD/responsible-disclosure-policy-vanta.markdown"),
    GDOC: "https://docs.google.com/document/d/12uZZgc62df83lTiE7AsOCP8UhVOZC5uMeBkmBIFAoPI/edit?usp=sharing",
  },
  "risk-assessment-program": {
    DOCX: require("url:../../../static/policy-templates/DOCX/risk-assessment-program-vanta.docx"),
    MD: require("url:../../../static/policy-templates/MD/risk-assessment-program-vanta.markdown"),
    GDOC: "https://docs.google.com/document/d/1V5bVo3zX1eLh-ZueQIVLtvJimkWXc7L7LP6-n0sY2LI/edit?usp=sharing",
  },
  "system-access-control-policy": {
    DOCX: require("url:../../../static/policy-templates/DOCX/system-access-control-policy-vanta.docx"),
    MD: require("url:../../../static/policy-templates/MD/system-access-control-policy-vanta.markdown"),
    GDOC: "https://docs.google.com/document/d/1fv8Clvuz-UgVsXKBd3OX4JdG1d-FseSPUEImgC_7zfM/edit?usp=sharing",
  },
  "vendor-management-policy": {
    DOCX: require("url:../../../static/policy-templates/DOCX/vendor-management-policy-vanta.docx"),
    MD: require("url:../../../static/policy-templates/MD/vendor-management-policy-vanta.markdown"),
    GDOC: "https://docs.google.com/document/d/1hBNPBvYEGD2ASZ0SS0-UxqVYD8chHjHR1VLlTPFHAD4/edit?usp=sharing",
  },
  "vulnerability-management-policy": {
    DOCX: require("url:../../../static/policy-templates/DOCX/vulnerability-management-policy-vanta.docx"),
    MD: require("url:../../../static/policy-templates/MD/vulnerability-management-policy-vanta.markdown"),
    GDOC: "https://docs.google.com/document/d/1ZQpPg9hM6f_SO05aPlxmiVah0EyHdwQiQreTDneVAPU/edit?usp=sharing",
  }, // BSI policies
  "access-control-policy-bsi": {
    DOCX: require("url:../../../static/policy-templates/DOCX/access-control-policy-bsi.docx"),
    GDOC: "https://docs.google.com/document/d/1crmTxb46FzcTnJAjNr1Em1Af-iFETqhGZkg5UJDay_o/copy?copyComments=true",
  },
  "asset-management-policy-bsi": {
    DOCX: require("url:../../../static/policy-templates/DOCX/asset-management-policy-bsi.docx"),
    GDOC: "https://docs.google.com/document/d/10qsxwfh51vshYoDgUDu39r7hLScxHN6dsvjT9mxi-tQ/copy?copyComments=true",
  },
  "business-continuity-and-disaster-recovery-plan-bsi": {
    DOCX: require("url:../../../static/policy-templates/DOCX/business-continuity-and-disaster-recovery-plan-bsi.docx"),
    GDOC: "https://docs.google.com/document/d/1XAkZZL88y7oHmX1Jz7VOFKVn1f9CgxhtgdaiXlU8D2k/copy?copyComments=true",
  },
  "code-of-conduct-bsi": {
    DOCX: require("url:../../../static/policy-templates/DOCX/code-of-conduct-policy-vanta.docx"), // we use the same code of conduct in our BSI policies set as in our vanta deprecated set
  },
  "cryptography-policy-bsi": {
    DOCX: require("url:../../../static/policy-templates/DOCX/cryptography-policy-bsi.docx"),
    GDOC: "https://docs.google.com/document/d/1fYhwHA5THjZM1g-vyz4nGxmysF_yndIjTqczHZJABvI/copy?copyComments=true",
  },
  "data-management-policy-bsi": {
    DOCX: require("url:../../../static/policy-templates/DOCX/data-management-policy-bsi.docx"),
    GDOC: "https://docs.google.com/document/d/1G_RsgmoaABPfdxDEmBrhKkL9pT89GAPE8KumAYVfiI4/copy?copyComments=true",
  },
  "hipaa-compliance-policy-bsi": {
    DOCX: require("url:../../../static/policy-templates/DOCX/HIPAA Compliance Policy v1.0.0.docx"),
    GDOC: "https://docs.google.com/document/d/1nB-_waMh8dz8v735R19hHsyDcoJqWt7hicpJ1JM7CcI/copy?copyComments=true",
  },
  "hipaa-workstation-security-policy-bsi": {
    DOCX: require("url:../../../static/policy-templates/DOCX/HIPAA Workstation Security Policy v3.1.docx"),
    GDOC: "https://docs.google.com/document/d/1lyGR8xK8gahWi8GzzLT3x6G5hd0TLFUaKluJsjMSGdk/copy?copyComments=true",
  },
  "human-resource-security-policy-bsi": {
    DOCX: require("url:../../../static/policy-templates/DOCX/human-resource-security-policy-bsi.docx"),
    GDOC: "https://docs.google.com/document/d/1bCs0fm1y0aFWqONa1XpnyXmIErIJpKq3kIqay4hFKog/copy?copyComments=true",
  },
  "incident-response-plan-bsi": {
    DOCX: require("url:../../../static/policy-templates/DOCX/incident-response-plan-bsi.docx"),
    GDOC: "https://docs.google.com/document/d/1VIRBmQ7GAQVNRikRJBQu2N7BrlZ6XTitIogAujneAMA/copy?copyComments=true",
  },
  "incident-response-plan-hipaa-addendum-with-breach-notification-procedures-bsi":
    {
      DOCX: require("url:../../../static/policy-templates/DOCX/Incident Response Plan HIPAA Addendum with Breach Notification Procedures v1.0.0.docx"),
      GDOC: "https://docs.google.com/document/d/1yq4Y7wtgAMCN1WhPYUHPwt8qjDJXmomWcdwIdbOzQkg/copy?copyComments=true",
    },
  "information-security-policy-bsi": {
    DOCX: require("url:../../../static/policy-templates/DOCX/information-security-policy-bsi.docx"),
    GDOC: "https://docs.google.com/document/d/10oSonS-abZ93ubnbE6Yd-sJooEnnRY1Gy5xT4cWRP68/copy?copyComments=true",
  },
  "information-security-roles-and-responsibilities-bsi": {
    DOCX: require("url:../../../static/policy-templates/DOCX/information-security-roles-and-responsibilities-bsi.docx"),
    GDOC: "https://docs.google.com/document/d/1Aq0_PYDnITtIpSscTH1hR3eSaE_4vCaVp-WZ85p8DGE/copy?copyComments=true",
  },
  "operations-security-policy-bsi": {
    DOCX: require("url:../../../static/policy-templates/DOCX/operations-security-policy-bsi.docx"),
    GDOC: "https://docs.google.com/document/d/19SeIiMWKeNEPI1ke2I5wgNrkNj_lhWuNrpOAgEeoAog/copy?copyComments=true",
  },
  "pci-dss-saq-a": {
    DOCX: require("url:../../../static/policy-templates/DOCX/pci-policies-saq-a.docx"),
    GDOC: "https://docs.google.com/document/d/1enlGnLlzPAovQrtc6T6_53rSlr9wslzPbXieWO-N9bQ/copy?comments=true",
  },
  "pci-dss-saq-a-ep": {
    DOCX: require("url:../../../static/policy-templates/DOCX/pci-policies-saq-a-ep.docx"),
    GDOC: "https://docs.google.com/document/d/1hr0SwpYvOfWQQsXr5xM9e-Xe4orSbXBOYNfF9r6ATZU/copy?comments=true",
  },
  "pci-dss-saq-d": {
    DOCX: require("url:../../../static/policy-templates/DOCX/pci-policies-saq-d.docx"),
    GDOC: "https://docs.google.com/document/d/1YFzil9MUHW6fbIGqBpEGmUv-51v4BVfdnq4EBl63NQk/copy?comments=true",
  },
  "physical-security-policy-bsi": {
    DOCX: require("url:../../../static/policy-templates/DOCX/physical-security-policy-bsi.docx"),
    GDOC: "https://docs.google.com/document/d/1MNVuBuFC_tx8MhF7f4lvMAlgovp5u0wDo8ZmMGOQHg4/copy?copyComments=true",
  },
  "risk-management-policy-bsi": {
    DOCX: require("url:../../../static/policy-templates/DOCX/risk-management-policy-bsi.docx"),
    GDOC: "https://docs.google.com/document/d/16oUEUjNzMnaT5tOHJDHd_g21-UTsf8oywEoZlxkzqTs/copy?copyComments=true",
  },
  "secure-development-policy-bsi": {
    DOCX: require("url:../../../static/policy-templates/DOCX/secure-development-policy-bsi.docx"),
    GDOC: "https://docs.google.com/document/d/1scECJRzAJIluIABBmtcWkXpaHYMZeRxtkyRJivbNczA/copy?copyComments=true",
  },
  "third-party-management-policy-bsi": {
    DOCX: require("url:../../../static/policy-templates/DOCX/third-party-management-policy-bsi.docx"),
    GDOC: "https://docs.google.com/document/d/1wugvLoWP0J93e94sMZW_V1-nvRLHambrx5r5MHy0cWI/copy?copyComments=true",
  },
  "00-isms-master-list-of-documents": {
    DOCX: require("url:../../../static/policy-templates/DOCX/00-ISMS Master List of Documents.docx"),
    GDOC: "https://docs.google.com/document/d/1kQ2Tt5HIkEB6mdz-sgsmTH55YqgtYmYrdnpvtkz1U5g/copy?copyComments=true",
  },
  "01-isms-scope-of-the-isms": {
    DOCX: require("url:../../../static/policy-templates/DOCX/01-ISMS Scope of the ISMS.docx"),
    GDOC: "https://docs.google.com/document/d/1qeebo5Ut7ZP7waF2PPayEcu0A7ckvky5VQp0RPx0kxE/copy?copyComments=true",
  },
  "02-isms-information-security-management-system-isms-policy": {
    DOCX: require("url:../../../static/policy-templates/DOCX/02-ISMS Information Security Management System (ISMS) Policy.docx"),
    GDOC: "https://docs.google.com/document/d/1AX_xGux6Cgj1QOvuj0GPoMXumwhJKm_fvFS9AGZ4U4I/copy?copyComments=true",
  },
  "03-isms-roles-responsibilities-and-authorities": {
    DOCX: require("url:../../../static/policy-templates/DOCX/03-ISMS Roles Responsibilities and Authorities.docx"),
    GDOC: "https://docs.google.com/document/d/1_TuKm3F0G3_fNYQ03kuv3WYurhI35xnIPNpwErRwiFI/copy?copyComments=true",
  },
  "04-isms-risk-assessment-and-risk-treatment-process": {
    DOCX: require("url:../../../static/policy-templates/DOCX/04-ISMS Risk Assessment and Risk Treatment Process.docx"),
    GDOC: "https://docs.google.com/document/d/1ylyhZgdXP_ZBFWJaPqdTwh9icutoV6u5XfX9NyetqO0/copy?copyComments=true",
  },
  "05-isms-procedure-for-the-control-of-documented-information": {
    DOCX: require("url:../../../static/policy-templates/DOCX/05-ISMS Procedure for the Control of Documented Information.docx"),
    GDOC: "https://docs.google.com/document/d/1AMDmbF59_wQCxRvjJGFEDg6fxDGwCYlu4EPxoKNeVLk/copy?copyComments=true",
  },
  "06-isms-information-security-communication-plan": {
    DOCX: require("url:../../../static/policy-templates/DOCX/06-ISMS Information Security Communication Plan.docx"),
    GDOC: "https://docs.google.com/document/d/1BaU993MRxRaoXBjXoL8YUuYCafr6Huscq_pcU5M4cbs/copy?copyComments=true",
  },
  "07-isms-procedure-for-internal-audits": {
    DOCX: require("url:../../../static/policy-templates/DOCX/07-ISMS Procedure for Internal Audits.docx"),
    GDOC: "https://docs.google.com/document/d/1igc_8Mti4eIX9_cxU6Jorslke-i4Y2jzUoceD85FJhA/copy?copyComments=true",
  },
  "08-isms-procedure-for-management-review": {
    DOCX: require("url:../../../static/policy-templates/DOCX/08-ISMS Procedure for Management Review.docx"),
    GDOC: "https://docs.google.com/document/d/1YPf9gbYetJlNQ70nUulcZNJnUxWvM1alH8ekXE1o1Us/copy?copyComments=true",
  },
  "09-isms-procedure-for-corrective-action-and-continual-improvement": {
    DOCX: require("url:../../../static/policy-templates/DOCX/09-ISMS Procedure for Corrective Action and Continual Improvement.docx"),
    GDOC: "https://docs.google.com/document/d/1uy_sXHRMVWy2be6uhIBHp_b70teqgJmTD5byks-4bMU/copy?copyComments=true",
  },
  "10-isms-information-security-objectives-plan": {
    DOCX: require("url:../../../static/policy-templates/DOCX/10-ISMS Information Security Objectives Plan.docx"),
    GDOC: "https://docs.google.com/document/d/1283TkGkj8hDFGwrfg-WgTdhfeBqF4dx_9YLI9tOu7eA/copy?copyComments=true",
  },
  "11-isms-statement-of-applicability": {
    XLSX: require("url:../../../static/policy-templates/XLSX/11-ISMS Statement of Applicability.xlsx"),
    GDOC: "https://docs.google.com/spreadsheets/d/1ZcDOOAykxPrRgS9iHarA7-YHI_b1N_wrNkjYCqM1bss/copy?copyComments=true",
  },
  "12-isms-relevant-laws-regulations-and-contractual-requirements": {
    DOCX: require("url:../../../static/policy-templates/DOCX/12-ISMS Relevant Laws Regulations and Contractual Requirements.docx"),
    GDOC: "https://docs.google.com/document/d/1AZ9l4RvXZRQir8nnEpZNIqPHXtlZGX9Z8DuLHKFVChE/copy?copyComments=true",
  },
} as const;
