// Test entity types are often grouped in lists together; these are headers that make sense for any
// test entity.

import { ReportStandard, AuditTypeEnum } from "../base/types/gen";
import { SERVICE_TO_HR_USER_KIND } from "../resources/generic-resources/hr-user-constants";
import { PCI_STANDARD_SET } from "../standards/pci";

// This should match the testEntityTypes from common/src/schemas/testEntities, and
// is enforced by automated testing.
// Maintained separately from as web/client imports from this file
const TEST_ENTITY_TYPES = [
  ...Object.values(SERVICE_TO_HR_USER_KIND),
  "Account",
  "AccountGroup",
  "AirtableTask",
  "Alarm",
  "ALB",
  "AmazonLinuxPackageChangelog",
  "AsanaAccount",
  "AsanaTask",
  "AtlasAccount",
  "AtlasCluster",
  "AutoScalingGroup",
  "AwsAccount",
  "AwsContainerVulnerability",
  "AwsCredentialReport",
  "AwsFlowLog",
  "AwsGroup",
  "AwsInspectorVulnerability",
  "AwsNetworkACL",
  "AwsPasswordPolicy",
  "AwsRole",
  "AwsRouteTable",
  "AwsSecurityGroup",
  "AwsSubnet",
  "AwsVPC",
  "AzureApplicationGateway",
  "AzureBlobContainer",
  "AzureContainerRepository",
  "AzureContainerVulnerability",
  "AzureCosmosDB",
  "AzureDatabaseForExternalSQLVariant",
  "AzureDevOpsAccount",
  "AzureDevOpsRepo",
  "AzureDevOpsTask",
  "AzureLoadBalancer",
  "AzureMetricAlertRule",
  "AzureQueue",
  "AzureRole",
  "AzureRoleAssignment",
  "AzureScaleSetVirtualMachine",
  "AzureSecurityGroup",
  "AzureSQLDatabase",
  "AzureSQLManagedInstance",
  "AzureSQLServerOnVMs",
  "AzureSynapseWarehouse",
  "AzureVirtualMachine",
  "AzureVirtualMachineScaleSet",
  "BackgroundCheck",
  "BigQueryDataset",
  "BigtableInstance",
  "BitbucketAccount",
  "BitbucketRepo",
  "CentosPackageChangelog",
  "CertnBackgroundCheck",
  "ClickupAccount",
  "ClickupTask",
  "CloudRole",
  "CloudRoleGrant",
  "CloudSQLInstance",
  "CloudTrail",
  "CloudWatchLogGroup",
  "CloudWatchMetricAlarm",
  "ClubhouseAccount",
  "ClubhouseTask",
  "ComputeInstance",
  "ContainerRepository",
  "CredentialReport",
  "CustomerCheckrBackgroundCheck",
  "CveRecord",
  "DatadogAccount",
  "DatadogMonitor",
  "DatastoreProject",
  "DataWarehouse",
  "DebianPackageChangelog",
  "DigitalOceanApp",
  "DigitalOceanContainerRepository",
  "DigitalOceanDroplet",
  "DigitalOceanFirewall",
  "DigitalOceanLoadBalancer",
  "DigitalOceanRedisCluster",
  "DigitalOceanRelationalDbCluster",
  "DigitalOceanSpace",
  "DriveBackgroundCheck",
  "DynamoDBTable",
  "EC2Instance",
  "ECRContainerRepository",
  "EmptyDailySpecificResource",
  "EmptyResource",
  "EmptySpecificResource",
  "FirestoreProject",
  "FlowLog",
  "GCPComputeInstance",
  "GCPContainerRepository",
  "GCPContainerVulnerability",
  "GCPLogBucket",
  "GCPLogSink",
  "GCPMonitoringPolicy",
  "GCPNetwork",
  "GCPRole",
  "GCPRoleGrant",
  "GCPStorageBucket",
  "GCPSubnet",
  "GCPSubscription",
  "GCPTopic",
  "GithubAccount",
  "GithubRepo",
  "GithubTask",
  "GitlabAccount",
  "GitlabGroup",
  "GitlabRepo",
  "GitlabTask",
  "GsuiteUser",
  "HerokuAccount",
  "HerokuApp",
  "HerokuDB",
  "HrUser",
  "JamfAccount",
  "JamfManagedComputer",
  "JiraAccount",
  "JiraTask",
  "KandjiManagedComputer",
  "KnowBe4Account",
  "KnowBe4TrainingCampaign",
  "KnowBe4TrainingEnrollment",
  "LinearAccount",
  "LinearTask",
  "LoadBalancer",
  "LogGroup",
  "LogTrail",
  "ManagedComputer",
  "ManagedInstanceGroup",
  "MicrosoftEndpointManagerManagedComputer",
  "Network",
  "NetworkACL",
  "NmapSsl",
  "NoSQLDatabase",
  "NvdCve",
  "OfficeUser",
  "OktaUser",
  "Osquery",
  "OsqueryVulnerability",
  "PaaSApp",
  "PackageChangelogResource",
  "PasswordPolicy",
  "PivotalTrackerAccount",
  "PivotalTrackerTask",
  "Policy",
  "Queue",
  "RDSInstance",
  "RedshiftCluster",
  "RiskScenario",
  "RouteTable",
  "S3",
  "SecurityGroup",
  "SlackAccount",
  "SnykAccount",
  "SnykProject",
  "SnykVulnerability",
  "SpannerInstance",
  "SQLDatabase",
  "SQS",
  "Ssl",
  "StorageBucket",
  "String",
  "Subnet",
  "TaskTrackerItem",
  "Topic",
  "Training",
  "TrainingRequirement",
  "TrelloAccount",
  "TrelloTask",
  "UbuntuPackageChangelog",
  "User",
  "UserGroup",
  "UserResource",
  "VantaCheckrBackgroundCheck",
  "Vendor",
  "VersionControlRepo",
  "VettyBackgroundCheck",
  "VPC",
  "Vulnerability",
  "VulnerabilityMonitoringTarget",
] as const;

export const TestEntityTypeDisplayNames: {
  [k in TestEntityTypeClientSafe]: string;
} = {
  Account: "Account",
  AccountGroup: "Account Group",
  AdpRunHrUser: "Employee",
  AdpWorkforceNowHrUser: "Employee",
  AirtableTask: "Airtable Tasks",
  Alarm: "Alarm",
  ALB: "Load Balancers",
  AmazonLinuxPackageChangelog: "Amazon Linux Package Changelog",
  AsanaAccount: "Asana Accounts",
  AsanaTask: "Asana Tasks",
  AtlasAccount: "MongoDB Atlas Accounts",
  AtlasCluster: "MongoDB Atlas Clusters",
  AutoScalingGroup: "Autoscaling Groups",
  AwsAccount: "AWS Accounts",
  AwsContainerVulnerability: "AWS ECR Container Vulnerabilities",
  AwsCredentialReport: "AWS Credential Reports",
  AwsFlowLog: "AWS Flow Logs",
  AwsGroup: "AWS Groups",
  AwsInspectorVulnerability: "AWS Inspector Vulnerabilities",
  AwsNetworkACL: "AWS Network ACLs",
  AwsPasswordPolicy: "AWS Password Policies",
  AwsRole: "AWS Roles",
  AwsRouteTable: "AWS Route Tables",
  AwsSecurityGroup: "AWS Security Groups",
  AwsSubnet: "AWS Subnets",
  AwsVPC: "AWS VPCs",
  AzureApplicationGateway: "Azure Application Gateways",
  AzureBlobContainer: "Azure Blob Containers",
  AzureContainerRepository: "Azure Container Repositories",
  AzureContainerVulnerability: "Azure Container Vulnerabilities",
  AzureCosmosDB: "Azure CosmosDBs",
  AzureDatabaseForExternalSQLVariant: "Azure Databases",
  AzureDevOpsAccount: "Azure DevOps Accounts",
  AzureDevOpsRepo: "Azure DevOps Repositories",
  AzureDevOpsTask: "Azure DevOps Work Items",
  AzureLoadBalancer: "Azure Load Balancers",
  AzureMetricAlertRule: "Azure Metric Alert Rules",
  AzureQueue: "Azure Queues",
  AzureRole: "Azure Roles",
  AzureRoleAssignment: "Azure Role Assignments",
  AzureScaleSetVirtualMachine: "Azure Scale Set Virtual Machines",
  AzureSecurityGroup: "Azure Security Groups",
  AzureSQLDatabase: "Azure SQL Databases",
  AzureSQLManagedInstance: "Azure SQL Managed Instances",
  AzureSQLServerOnVMs: "Azure SQL Server on Virtual Machines",
  AzureSynapseWarehouse: "Azure Synapse Warehouses",
  AzureVirtualMachine: "Azure Virtual Machines",
  AzureVirtualMachineScaleSet: "Azure Virtual Machine Scale Sets",
  BackgroundCheck: "Background Check",
  BambooHrHrUser: "Employee",
  BigQueryDataset: "BigQuery Datasets",
  BigtableInstance: "Bigtable Instances",
  BitbucketAccount: "Bitbucket Accounts",
  BitbucketRepo: "Bitbucket Repos",
  CentosPackageChangelog: "CentOS Package Changelog",
  CertnBackgroundCheck: "Certn Background Checks",
  ClickupAccount: "ClickUp Accounts",
  ClickupTask: "ClickUp Tasks",
  CloudRole: "Cloud Role",
  CloudRoleGrant: "Cloud Role Grant",
  CloudSQLInstance: "CloudSQL Instances",
  CloudTrail: "CloudTrails",
  CloudWatchLogGroup: "CloudWatch Log Groups",
  CloudWatchMetricAlarm: "CloudWatch Metric Alarms",
  ClubhouseAccount: "Shortcut Accounts",
  ClubhouseTask: "Shortcut Tasks",
  ComputeInstance: "Compute Instance",
  ContainerRepository: "Container Repository",
  CredentialReport: "Credential Report",
  CustomerCheckrBackgroundCheck: "Checkr Background Checks",
  CveRecord: "CVE Record",
  DatadogAccount: "Datadog Accounts",
  DatadogMonitor: "Datadog Monitors",
  DatastoreProject: "Datastore Projects",
  DataWarehouse: "Data Warehouse",
  DebianPackageChangelog: "Debian Package Changelog",
  DigitalOceanApp: "DigitalOcean App",
  DigitalOceanContainerRepository: "DigitalOcean Container Repositories",
  DigitalOceanDroplet: "DigitalOcean Droplet",
  DigitalOceanFirewall: "DigitalOcean Firewall",
  DigitalOceanLoadBalancer: "DigitalOcean Load Balancer",
  DigitalOceanRedisCluster: "DigitalOcean Redis Cluster",
  DigitalOceanRelationalDbCluster: "DigitalOcean Relational Database Cluster",
  DigitalOceanSpace: "DigitalOcean Space",
  DriveBackgroundCheck: "Background Checks",
  DynamoDBTable: "DynamoDB Tables",
  EC2Instance: "EC2 Instances",
  ECRContainerRepository: "ECR Container Repository",
  EmptyDailySpecificResource: "Identifiers",
  EmptyResource: "Empty Resource",
  EmptySpecificResource: "Identifiers",
  FirestoreProject: "Firestore Projects",
  FlowLog: "Flow Log",
  GCPComputeInstance: "GCP Compute Instances",
  GCPContainerRepository: "GCP Container Repositories",
  GCPContainerVulnerability: "GCP Container Vulnerabilities",
  GCPLogBucket: "GCP Log Buckets",
  GCPLogSink: "GCP Log Sinks",
  GCPMonitoringPolicy: "GCP Monitoring Policies",
  GCPNetwork: "GCP Networks",
  GCPRole: "GCP Roles",
  GCPRoleGrant: "GCP Role Grants",
  GCPStorageBucket: "GCP Storage Buckets",
  GCPSubnet: "GCP Subnets",
  GCPSubscription: "GCP Subscriptions",
  GCPTopic: "GCP Topics",
  GithubAccount: "GitHub Accounts",
  GithubRepo: "GitHub Repos",
  GithubTask: "GitHub Tasks",
  GitlabAccount: "GitLab Accounts",
  GitlabGroup: "GitLab Groups",
  GitlabRepo: "GitLab Repos",
  GitlabTask: "GitLab Tasks",
  GsuiteUser: "Google Workspace Users",
  GustoHrUser: "Employee",
  HerokuAccount: "Heroku Accounts",
  HerokuApp: "Heroku Apps",
  HerokuDB: "Heroku Databases",
  HrUser: "HR User",
  InsperityHrUser: "Employee",
  JamfAccount: "Jamf Accounts",
  JamfManagedComputer: "Jamf Managed Computers",
  JiraAccount: "Jira Accounts",
  JiraTask: "Jira Tasks",
  JustworksHrUser: "Employee",
  KandjiManagedComputer: "Kandji Managed Computers",
  KnowBe4Account: "KnowBe4 Accounts",
  KnowBe4TrainingCampaign: "KnowBe4 Training Campaign",
  KnowBe4TrainingEnrollment: "KnowBe4 Training Enrollment",
  LinearAccount: "Linear Accounts",
  LinearTask: "Linear Tasks",
  LoadBalancer: "Load Balancer",
  LogGroup: "Log Group",
  LogTrail: "Log Trail",
  ManagedComputer: "Managed Computer",
  ManagedInstanceGroup: "Managed Instance Group",
  MicrosoftEndpointManagerManagedComputer:
    "Microsoft Endpoint Manager Managed Computers",
  NamelyHrUser: "Employee",
  Network: "Network",
  NetworkACL: "Network ACL",
  NmapSsl: "URLs",
  NoSQLDatabase: "NoSQL Database",
  NvdCve: "NVD CVEs",
  OfficeUser: "Office Users",
  OktaUser: "Okta Users",
  Osquery: "Machines",
  OsqueryVulnerability: "Vulnerabilities",
  PaaSApp: "PaaS App",
  PackageChangelogResource: "Package Changelog",
  PasswordPolicy: "Password Policy",
  PaychexFlexHrUser: "Employee",
  PaycorHrUser: "Employee",
  PaylocityHrUser: "Employee",
  PivotalTrackerAccount: "Pivotal Tracker Accounts",
  PivotalTrackerTask: "Pivotal Tracker Tasks",
  Policy: "Policies",
  Queue: "Queue",
  QuickBooksHrUser: "Employee",
  RDSInstance: "RDS Instances",
  RedshiftCluster: "Redshift Clusters",
  RipplingHrUser: "Employee",
  RiskScenario: "Risk Scenarios",
  RouteTable: "Route Table",
  S3: "S3 Buckets",
  SecurityGroup: "Security Group",
  SlackAccount: "Slack Accounts",
  SnykAccount: "Snyk Accounts",
  SnykProject: "Snyk Project",
  SnykVulnerability: "Snyk Vulnerabilities",
  SpannerInstance: "Spanner Instances",
  SQLDatabase: "SQL Database",
  SQS: "SQS Queues",
  SquarePayrollHrUser: "Employee",
  Ssl: "Ssl",
  StorageBucket: "Storage Bucket",
  String: "Identifiers",
  Subnet: "Subnet",
  TaskTrackerItem: "Task Tracker Task",
  Topic: "Topic",
  Training: "Training",
  TrainingRequirement: "TrainingRequirement",
  TrelloAccount: "Trello Accounts",
  TrelloTask: "Trello Tasks",
  TrinetHrUser: "Employee",
  UbuntuPackageChangelog: "Ubuntu Package Changelog",
  User: "Users",
  UserGroup: "User Group",
  UserResource: "User Resource",
  VantaCheckrBackgroundCheck: "Checkr Background Checks",
  Vendor: "Vendors",
  VersionControlRepo: "Version Control Repo",
  VettyBackgroundCheck: "Vetty Background Checks",
  VPC: "VPC",
  Vulnerability: "Vulnerability",
  VulnerabilityMonitoringTarget: "Vulnerability Monitoring Target",
  ZenefitsHrUser: "Employee",
} as const;

const STANDARD_TO_DISPLAY_NAME_MAP = {
  [ReportStandard.gdpr]: "GDPR",
  [ReportStandard.hipaa]: "HIPAA",
  [ReportStandard.iso27001]: "ISO 27001",
  [ReportStandard.pciSaqA]: "PCI DSS (SAQ A)",
  [ReportStandard.pciSaqAEP]: "PCI DSS (SAQ A EP)",
  [ReportStandard.pciSaqDMerchant]: "PCI DSS (SAQ D Merchant)",
  [ReportStandard.pciSaqDSP]: "PCI DSS (SAQ D Service Provider)",
  [ReportStandard.soc2]: "SOC 2",
  [ReportStandard.vanta]: "Vanta",
} as const;

export function StandardToDisplayName(standard: ReportStandard) {
  return STANDARD_TO_DISPLAY_NAME_MAP[standard];
}

export function StandardToSmallDisplayName(standard: ReportStandard) {
  return PCI_STANDARD_SET.has(standard)
    ? "PCI DSS"
    : StandardToDisplayName(standard);
}

export const AuditTypeToHumanName: { [k in AuditTypeEnum]: string } = {
  gdpr: StandardToDisplayName(ReportStandard.gdpr),
  hipaa: StandardToDisplayName(ReportStandard.hipaa),
  iso27001: StandardToDisplayName(ReportStandard.iso27001),
  pciSaqA: StandardToDisplayName(ReportStandard.pciSaqA),
  pciSaqAEP: StandardToDisplayName(ReportStandard.pciSaqAEP),
  pciSaqDMerchant: StandardToDisplayName(ReportStandard.pciSaqDMerchant),
  pciSaqDSP: StandardToDisplayName(ReportStandard.pciSaqDSP),
  soc2type1: "SOC 2 Type I",
  soc2type2: "SOC 2 Type II",
};

export const AuditTypeToStandard: { [k in AuditTypeEnum]: ReportStandard } = {
  gdpr: ReportStandard.gdpr,
  hipaa: ReportStandard.hipaa,
  iso27001: ReportStandard.iso27001,
  pciSaqA: ReportStandard.pciSaqA,
  pciSaqAEP: ReportStandard.pciSaqAEP,
  pciSaqDMerchant: ReportStandard.pciSaqDMerchant,
  pciSaqDSP: ReportStandard.pciSaqDSP,
  soc2type1: ReportStandard.soc2,
  soc2type2: ReportStandard.soc2,
};

export const ReportStandardToAuditTypeEnum: {
  [k in ReportStandard]: AuditTypeEnum[];
} = {
  [ReportStandard.gdpr]: [AuditTypeEnum.gdpr],
  [ReportStandard.hipaa]: [AuditTypeEnum.hipaa],
  [ReportStandard.iso27001]: [AuditTypeEnum.iso27001],
  [ReportStandard.pciSaqA]: [AuditTypeEnum.pciSaqA],
  [ReportStandard.pciSaqAEP]: [AuditTypeEnum.pciSaqAEP],
  [ReportStandard.pciSaqDMerchant]: [AuditTypeEnum.pciSaqDMerchant],
  [ReportStandard.pciSaqDSP]: [AuditTypeEnum.pciSaqDSP],
  [ReportStandard.soc2]: [AuditTypeEnum.soc2type1, AuditTypeEnum.soc2type2],
  [ReportStandard.vanta]: [],
};

export type TestEntityTypeClientSafe = typeof TEST_ENTITY_TYPES[number];
