import { Collapse } from "@blueprintjs/core";
import React, { useContext, useState } from "react";

import type { GetDataForReportQuery } from "../../../../../gen/components";
import { renderControlDescription } from "../../../../../helpers/common";
import { ControlDescription } from "../../../common";
import type { IDomainForReport } from "../../../helpers/helpers";
import { VantaReportContext } from "../../../vanta-report-context";
import { CollapseIndicatorIcon } from "../../collapse-indicator/collapse-indicator-icon";
import { VantaReportControlListItemHeading } from "./vanta-report-control-list-item-heading";
import { ControlReportType, VantaReportList } from "./vanta-report-list";
import { VantaReportCountDisplay } from "./vanta-report-test-count-display";

interface IProps {
  domain: IDomainForReport;
  control: NonNullable<
    GetDataForReportQuery["complianceStandard"]
  >["principles"][number]["requirements"][number]["controls"][number];
}

export const VantaReportControlListItem: React.FunctionComponent<IProps> = ({
  control,
  domain,
}) => {
  const { isPrintView } = useContext(VantaReportContext);
  const [collapseIsOpen, setCollapseIsOpen] = useState(false);

  return (
    <div className="vrp-control-list-item">
      <div
        className="vrp-control-list-collapse-header"
        onClick={() => setCollapseIsOpen(!collapseIsOpen)}
      >
        <CollapseIndicatorIcon collapseIsOpen={collapseIsOpen} />
        <VantaReportControlListItemHeading control={control} domain={domain} />
      </div>
      <Collapse isOpen={collapseIsOpen || isPrintView}>
        <div className="vrp-control-list-collapse-content">
          <ControlDescription>
            {renderControlDescription(control.control, domain.displayName)}
          </ControlDescription>
          {control.tests.length > 0 ? (
            <>
              <VantaReportCountDisplay
                control={control}
                controlReportType={ControlReportType.Test}
              />
              <VantaReportList
                control={control}
                domain={domain}
                controlReportType={ControlReportType.Test}
              />
            </>
          ) : null}
          {control.evidence.length > 0 ? (
            <>
              <VantaReportCountDisplay
                control={control}
                controlReportType={ControlReportType.Evidence}
              />
              <VantaReportList
                control={control}
                domain={domain}
                controlReportType={ControlReportType.Evidence}
              />
            </>
          ) : null}
        </div>
      </Collapse>
    </div>
  );
};
