import { Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { isSome } from "common/base/types/maybe";
import React from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import { VANTA_COLORS } from "../../alpaca/base/colors";
import { FeatureGate } from "../../helpers/feature-gating/feature-gate";
import { Dimensions } from "./constants";
import type { ILink } from "./links";

interface IProps {
  links: ILink[];
}

// TODO feature

export const SideMenuLinkList: React.FC<IProps> = ({ links }) => (
  <Container>
    <UL>
      {links.map(link =>
        isSome(link.feature) ? (
          <FeatureGate feature={link.feature}>{renderLink(link)}</FeatureGate>
        ) : (
          renderLink(link)
        )
      )}
    </UL>
  </Container>
);

function renderLink(link: ILink) {
  return (
    <li key={`sub-menu-item-${link.text}`}>
      <StyledLink to={link.path}>
        <span>{link.text}</span>
        {window.location.pathname.startsWith(link.path) ? (
          <Icon icon={IconNames.CHEVRON_RIGHT}></Icon>
        ) : null}
      </StyledLink>
    </li>
  );
}

const StyledLink = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 12px 6px ${Dimensions.NAVBAR_SUBMENU_INDENT}px;
  ${({ to }) => {
    if (window.location.pathname.startsWith(String(to))) {
      return css`
        && {
          color: ${VANTA_COLORS.TEXT_NORMAL};
        }
      `;
    } else {
      return "";
    }
  }}
`;

const UL = styled.ul`
  padding: 4px 0px;
  list-style: none;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.04em;
  margin: 0;
  a {
    text-decoration: none;
    color: ${VANTA_COLORS.TEXT_MUTED};
    transition: background-color 0.25s, color 0.25s;
    &:hover {
      color: ${VANTA_COLORS.TEXT_NORMAL};
      background-color: ${VANTA_COLORS.BACKGROUND_MENU_HOVER};
    }
  }
  li {
    line-height: ${Dimensions.SIDE_MENU_LINE_HEIGHT}px;
    white-space: nowrap;
  }
`;

const Container = styled.div`
  background-color: #fff;
  font-size: 12px;
  padding: 8px 0;
`;
