import { PopoverInteractionKind } from "@blueprintjs/core";
import type { StandardSections } from "common/base/types/gen";
import { ReportStandard } from "common/base/types/gen";
import type { Maybe } from "common/base/types/maybe";
import { isSome, nothing } from "common/base/types/maybe";
import { StandardToSmallDisplayName } from "common/constants/displayNames";
import _ from "lodash";
import React, { useContext } from "react";
import styled, { css } from "styled-components";

import { GRID_SPACING } from "../../alpaca/base/grid";
import { Tooltip, Tag } from "../../alpaca/components";
import { UserContext } from "../pages/user-context";

// StandardLabels is a reusable component that displays a row of labels. Simply pass in
// the set of standards you'd like to have labels for and the component will determine
// whether to / how to show them.

interface IProps {
  // By default, Standard Labels will only show conditionally, depending on how many standards the domain has enabled.
  // Use this flag to always show standard labels, no matter what.
  alwaysShowLabels?: Maybe<boolean>;

  // Use this if all you have is a list of Standards.
  standards?: Maybe<ReportStandard[]>;

  // Use this if you have objects containing both standards + a set of specific sections those reference
  // If you include this, those standard sections will be displayed on hover
  standardSections?: Maybe<StandardSections[]>;

  // By default, we don't display a label for "Vanta" standard.
  // Pass this in if you want to include a label for the Vanta Standard, if it's present.
  includeVantaStandard?: Maybe<boolean>;

  // By default, we display a label passed into standards only if the domain has the standard enabled.
  // This parameter configures whether the component should additionally also filter
  // out standards that are not configured for this domain.
  includeStandardsDisabledOnDomain?: Maybe<boolean>;

  // Margin between tags -- defaults is 2 * GRID_SPACING
  tagMargin?: Maybe<number>;
}

const TagRow = styled.div<{
  tagMargin?: Maybe<number>;
}>`
  align-items: center;
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex-shrink: 0;

  ${({ tagMargin }) =>
    css`
      & > *:not(:last-child) {
        margin-right: ${tagMargin ?? 2 * GRID_SPACING}px;
      }
    `}
`;

const StyledDiv = styled.div`
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  display: flex;
  margin-top: -4px;
  & > * {
    margin-top: 4px;
    &:not(:last-child) {
      margin-right: ${GRID_SPACING}px;
    }
  }
`;

// Only show standard labels if the domain has opted into more than one.
const shouldShowStandardLabels = (standardsForDomain: ReportStandard[]) =>
  Array.from(standardsForDomain).filter(s => s !== ReportStandard.vanta)
    .length >= 2; // For now, Vanta standard doesn't count against the number needed

export const StandardLabels: React.FC<IProps> = ({
  standards,
  standardSections,
  includeVantaStandard,
  includeStandardsDisabledOnDomain,
  alwaysShowLabels,
  tagMargin,
}) => {
  const { domainStandards } = useContext(UserContext);
  const showLabels =
    alwaysShowLabels ?? shouldShowStandardLabels(domainStandards);
  if (!showLabels) {
    return null;
  }

  // Filter out standards we don't consider to be labels, and deduplicate + sort the whole list.
  const allStandards = [
    ...(standards ?? []),
    ...(standardSections ?? []).map(s => s.standard),
  ];
  const shouldIncludeVantaStandard = includeVantaStandard ?? false;
  const filtered = allStandards
    .filter(
      s =>
        Boolean(includeStandardsDisabledOnDomain) || domainStandards.includes(s)
    )
    .filter(s =>
      !shouldIncludeVantaStandard ? s !== ReportStandard.vanta : true
    );
  const dedupedAndSortedStandards = Array.from(new Set(filtered)).sort();

  // If we've been provided standard sections, create a mapping from standard to section
  const grouped = _.groupBy(standardSections ?? [], ss => ss.standard);
  const standardToSections = new Map(
    Object.keys(grouped).map(standard => [
      standard,
      [...new Set(grouped[standard].map(ss => ss.sections).flat())],
    ])
  );

  const maybeWrapInTooltip = (
    baseTag: JSX.Element,
    hoverTags: Maybe<JSX.Element>
  ) =>
    isSome(hoverTags) ? (
      <Tooltip
        interactionKind={PopoverInteractionKind.HOVER}
        content={hoverTags}
        placement="right"
        key={baseTag.key ?? undefined}
      >
        {baseTag}
      </Tooltip>
    ) : (
      baseTag
    );

  const getLabelForStandard = (standard: ReportStandard) => {
    const baseTag = (
      <Tag key={standard} text={StandardToSmallDisplayName(standard)} />
    );

    const sections = standardToSections.get(standard);
    const hoverTags = isSome(sections) ? (
      <StyledDiv>
        {sections.map(s => (
          <Tag key={s} text={s} />
        ))}
      </StyledDiv>
    ) : (
      nothing
    );

    return maybeWrapInTooltip(baseTag, hoverTags);
  };

  return (
    <TagRow tagMargin={tagMargin}>
      {dedupedAndSortedStandards.map(getLabelForStandard)}
    </TagRow>
  );
};
