import React from "react";
import styled from "styled-components";

import { BASE_PALETTE } from "../../../../alpaca/base/colors";
import { Tooltip } from "../../../../alpaca/components";
import Completion from "../../../../static/images/icons/completion-check.svg";

interface IProps {
  onClick(): void;
}

export const OffboardVendorButton: React.FC<IProps> = ({ onClick }) => (
  <Tooltip content={"Mark complete"} placement="top">
    <StyledButtonContainer onClick={onClick}>
      <Completion />
    </StyledButtonContainer>
  </Tooltip>
);

const StyledButtonContainer = styled.div`
  svg {
    circle {
      fill: ${BASE_PALETTE.VAPE};
      stroke: ${BASE_PALETTE.FOG};
    }
    path {
      stroke: ${BASE_PALETTE.FOG};
    }
  }

  &:hover {
    cursor: pointer;
    svg {
      circle {
        stroke: ${BASE_PALETTE.KALE};
      }
      path {
        stroke: ${BASE_PALETTE.KALE};
      }
    }
  }
`;
