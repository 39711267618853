import { Button, Classes, Dialog, Intent } from "@blueprintjs/core";
import React from "react";

import type { GetVendorsQuery } from "../../../gen/components";

interface IProps {
  vendor: NonNullable<GetVendorsQuery["organization"]>["vendors"][number];
  isOpen: boolean;
  onCancel(): void;
  onConfirmDelete(): void;
}

export class DeleteVendorDialog extends React.Component<IProps> {
  public constructor(props: IProps) {
    super(props);

    this.onConfirm = this.onConfirm.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  public onConfirm() {
    this.props.onConfirmDelete();
  }

  public onCancel() {
    this.props.onCancel();
  }

  public render() {
    const { isOpen, vendor } = this.props;
    return (
      <Dialog
        isOpen={isOpen}
        title={`Delete vendor`}
        usePortal={true}
        onClose={this.onCancel}
        canOutsideClickClose={true}
      >
        <div className={Classes.DIALOG_BODY}>
          <p>
            This will permanently remove {vendor.name} from the list of your
            company's vendors and will delete all information you've entered
            about {vendor.name}.
          </p>
          <p>This action cannot be undone.</p>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={this.onCancel}>Cancel</Button>
            <Button onClick={this.onConfirm} intent={Intent.DANGER}>
              Delete vendor
            </Button>
          </div>
        </div>
      </Dialog>
    );
  }
}
