import { Intent } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { dropNothing, isSome } from "common/base/types/maybe";
import moment from "moment";
import React, {
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";

import { LogError } from "../../../errors";
import type {
  AddUserMutation,
  GetAccessAccountsQuery,
} from "../../../gen/components";
import {
  GetAccessAccountsDocument,
  useSetServiceAccountMappingMutation,
} from "../../../gen/components";
import { Ellipsify } from "../../../helpers/ellipsify";
import { AppToaster } from "../../../helpers/toaster";
import {
  compareNullableDates,
  compareNullableStrings,
  compareStringForServiceAccountOwner,
} from "../../../helpers/user-sort-functions";
import { Card } from "../components/card";
import type { DataTableFilter } from "../components/data-table";
import { COLUMN_CLASSES, DataTable } from "../components/data-table";
import { DropdownButton } from "../components/dropdown-button";
import { CreateUserDialog } from "../people/create-user-dialog";
import { HrServicesContext } from "../people/hr-services-context";
import { DATE_FORMAT } from "../people/shared/common";
import { NotApplicable } from "../people/shared/not-applicable";
import { AccessAccountRole } from "./access-components/access-account-role";
import { AccessOwner } from "./access-components/access-owner";
import { MaybeBooleanToIcon } from "./access-components/maybe-boolean-to-icon";
import {
  AccountDeactivated,
  FILTER_BUTTON_WIDTH,
  InfoNotAvailable,
  TableEmptyDefault,
} from "./access-components/styles";
import type { User as SelectorUser } from "./access-components/user-selector-for-people-page";
import { UserSelectorForAccounts } from "./access-components/user-selector-for-people-page";
import type { VantaUser } from "./access-page";
import { ACCESS_PAGE_DEFAULT_PAGE_SIZE } from "./constants";
import { CredentialsContext } from "./credentials-context";
import { ServiceDropdown } from "./service-dropdown";

export type AccessServiceAccount = NonNullable<
  GetAccessAccountsQuery["organization"]
>["serviceAccounts"][number];

type AssignAccessTableUser = VantaUser | "extUser" | "newUser" | "nonHuman";
interface IAccessTableProps {
  users: VantaUser[];
  credentialKey: string;
  roleColumnName?: Maybe<string>;
  service: string;
  serviceAccounts: AccessServiceAccount[];
}

const ACCOUNTS_COLUMN_ORDER = [
  "accountName",
  "owner",
  "jobTitle",
  "role",
  "status",
  "mfa",
  "externalCreatedAt",
  "deactivationDate",
  "actions",
];
const ACCOUNTS_COLUMN_HEADERS = {
  accountName: "Account name",
  owner: "Owner",
  jobTitle: "Job title",
  role: "Role",
  status: "Status",
  mfa: "MFA",
  externalCreatedAt: "Created",
  deactivationDate: "Deactivated",
  actions: "Actions",
};

const TABLE_COLUMN_WIDTHS = [
  "200px",
  "150px",
  "190px",
  "220px",
  "110px",
  "90px",
  "150px",
  "150px",
  "100px",
];

const unassignedAccountsFilter = (account: AccessServiceAccount) =>
  account.isActive &&
  account.userId !== "machine" &&
  account.userId !== "external" &&
  !isSome(account.owner);

const UNASSIGNED_ACCOUNTS_FILTER_NAME = "Unassigned accounts";
const CUSTOM_FILTERS: Array<DataTableFilter<AccessServiceAccount>> = [
  [UNASSIGNED_ACCOUNTS_FILTER_NAME, unassignedAccountsFilter],
  ["Active accounts", account => account.isActive],
  ["Deactivated accounts", account => !account.isActive],
  ["MFA not enabled", account => account.isActive && account.mfa === false],
];

type ServiceAccountSortFn = (
  a1: AccessServiceAccount,
  a2: AccessServiceAccount
) => number;
const COLUMN_SORT_FUNCTIONS: { [k: string]: ServiceAccountSortFn } = {
  accountName: (a1, a2) => a1.accountName.localeCompare(a2.accountName),
  externalCreatedAt: (a1, a2) =>
    compareNullableDates(a1.externalCreatedAt, a2.externalCreatedAt),
  deactivationDate: (a1, a2) =>
    compareNullableDates(a1.deactivationDate, a2.deactivationDate),
  jobTitle: (a1, a2) =>
    compareNullableStrings(
      a1.owner?.hrUser?.jobTitle,
      a2.owner?.hrUser?.jobTitle
    ),
  mfa: (a1, a2) => mfaSortValue(a1) - mfaSortValue(a2),
  owner: (a1, a2) => {
    const s1 = compareStringForServiceAccountOwner(a1);
    const s2 = compareStringForServiceAccountOwner(a2);
    // sorts no owner value first
    return !isSome(s1) ? -1 : !isSome(s2) ? 1 : s1.localeCompare(s2);
  },
  role: (a1, a2) => {
    const r1 = getRoleString(a1);
    const r2 = getRoleString(a2);
    // sorts no role value last
    return !isSome(r1) ? 1 : !isSome(r2) ? -1 : r1.localeCompare(r2);
  },
  status: (a1, a2) => getStatusString(a1).localeCompare(getStatusString(a2)),
};

const accountMatchesSearchString = (
  account: AccessServiceAccount,
  searchString: string
) => {
  const lowerCased = searchString.trim().toLocaleLowerCase();
  return (
    account.accountName.toLocaleLowerCase().includes(lowerCased) ||
    (account.owner?.displayName ?? "")
      .toLocaleLowerCase()
      .includes(lowerCased) ||
    (account.owner?.hrUser?.jobTitle ?? "")
      .toLocaleLowerCase()
      .includes(lowerCased)
  );
};

function getStatusString(account: AccessServiceAccount) {
  return !isSome(account.deactivationDate) ? "Active" : "Deactivated";
}

function getRoleString(serviceAccount: AccessServiceAccount) {
  // any given account will only have one of these fields
  return (
    serviceAccount.role ??
    serviceAccount.groupList?.join(", ") ??
    serviceAccount.teamList?.join(", ")
  );
}

function mfaSortValue(account: AccessServiceAccount) {
  if (isSome(account.mfa) && account.isActive) {
    return account.mfa ? 2 : 1;
  }
  return 0;
}

export const AccountsAccessTable: React.FC<IAccessTableProps> = ({
  credentialKey,
  roleColumnName,
  service,
  serviceAccounts,
  users,
}) => {
  const { credentials, defaultCredential } = useContext(CredentialsContext);
  const { linkedHrServices } = useContext(HrServicesContext);
  const [inFlightUserAccountMappings, linkAccountDispatch] = useReducer(
    inFlightAccountLinkReducer,
    new Set<string>()
  );
  const [
    serviceAccountIdForContractorDialog,
    setServiceAccountIdForContractorDialog,
  ] = useState<Maybe<string>>(null);

  const [setUserAccountMapping, assignResult] =
    useSetServiceAccountMappingMutation();

  useEffect(() => {
    const updatedAccount = assignResult.data?.setServiceAccountMapping;
    if (
      isSome(updatedAccount) &&
      inFlightUserAccountMappings.has(updatedAccount)
    ) {
      linkAccountDispatch({
        type: "LINK_FINISH",
        serviceAccountId: updatedAccount,
      });
    }
  });

  const [selectedFilter, setSelectedFilter] = useState<Maybe<string>>(null);
  const filteredAccounts = useMemo(() => {
    const maybeFilter = CUSTOM_FILTERS.find(f => f[0] === selectedFilter);
    if (!isSome(maybeFilter)) {
      return serviceAccounts;
    } else {
      return serviceAccounts.filter(maybeFilter[1]);
    }
  }, [serviceAccounts, selectedFilter]);

  const filterElement = (
    <DropdownButton
      key="filter-dropdown"
      options={CUSTOM_FILTERS.map(filter => filter[0])}
      selectedOption={selectedFilter}
      onOptionSelect={filter => {
        setSelectedFilter(filter);
      }}
      optionRenderer={filter => filter}
      isFilter={true}
      defaultText={"Filter by status"}
      buttonWidth={FILTER_BUTTON_WIDTH}
      menuWidth={FILTER_BUTTON_WIDTH}
      styleOnSelect
    />
  );

  if (isSome(roleColumnName)) {
    ACCOUNTS_COLUMN_HEADERS.role = roleColumnName;
  }

  const assignServiceAccountToUser = async (
    serviceAccount: AccessServiceAccount,
    user: Maybe<VantaUser | "extUser" | "nonHuman">,
    previousUser: Maybe<VantaUser | "extUser" | "nonHuman">
  ) => {
    linkAccountDispatch({
      type: "LINK_START",
      serviceAccountId: serviceAccount.id,
    });
    try {
      const userId = getUserIdOnAssignAccount(user);
      await setUserAccountMapping({
        variables: {
          serviceAccountId: serviceAccount.id,
          userId,
        },
        update: (cache, result) => {
          if (Boolean(result.data?.setServiceAccountMapping)) {
            const domainToUpdate = cache.readQuery<GetAccessAccountsQuery>({
              query: GetAccessAccountsDocument,
              variables: {
                service: serviceAccount.service,
              },
            })?.organization;
            if (!isSome(domainToUpdate)) {
              return;
            }
            cache.writeQuery<GetAccessAccountsQuery>({
              query: GetAccessAccountsDocument,
              data: {
                organization: {
                  ...domainToUpdate,
                  serviceAccounts: updateServiceAccountsCache(
                    domainToUpdate.serviceAccounts,
                    user,
                    serviceAccount
                  ),
                },
              },
            });
          }
        },
      });

      AppToaster.show({
        action: {
          onClick: async () =>
            assignServiceAccountToUser(serviceAccount, previousUser, user),
          text: "Undo",
        },
        icon: "tick",
        intent: Intent.SUCCESS,
        message: getToastMessageOnAccountLink(serviceAccount, user),
        timeout: 2500,
      });
    } catch (e) {
      LogError(e);
    }
  };

  const onCloseContractorDialog = async (
    newUser: Maybe<AddUserMutation["createUser"]>
  ) => {
    if (isSome(newUser)) {
      AppToaster.show({
        icon: "tick",
        intent: Intent.SUCCESS,
        message: "Added a new person to your company",
        timeout: 2500,
      });

      const serviceAccountToLink = serviceAccounts.find(
        s => s.id === serviceAccountIdForContractorDialog
      );
      if (!serviceAccountToLink) {
        throw new Error("Service account disappeared when adding contractor");
      }

      try {
        await assignServiceAccountToUser(serviceAccountToLink, newUser, null);
      } catch (e) {
        LogError(e);
      }
      setServiceAccountIdForContractorDialog(null);
    } else {
      setServiceAccountIdForContractorDialog(null);
    }
  };

  const getRowForServiceAccount = (serviceAccount: AccessServiceAccount) => {
    const { accountName } = serviceAccount;
    const isInFlight = inFlightUserAccountMappings.has(serviceAccount.id);

    const onUserSelect = (user: AssignAccessTableUser) => {
      if (user === "newUser") {
        setServiceAccountIdForContractorDialog(serviceAccount.id);
      } else {
        assignServiceAccountToUser(serviceAccount, user, null).catch(e =>
          LogError(e)
        );
      }
    };
    const activeUsers = !isSome(users)
      ? []
      : users.filter(u => u.isActive && !u.isNotHuman);
    const buttonText =
      isSome(serviceAccount.owner) ||
      serviceAccount.userId === "external" ||
      serviceAccount.userId === "machine"
        ? "Reassign"
        : "Assign";
    const assignButton = !isSome(serviceAccount.deactivationDate) ? (
      <UserSelectorForAccounts
        rowId={serviceAccount.accountId}
        options={[
          "newUser",
          "extUser",
          "nonHuman",
          ...dropNothing(activeUsers),
        ]}
        onUserSelect={onUserSelect}
        isInFlight={isInFlight}
        buttonText={buttonText}
      />
    ) : null;
    const jobTitle = serviceAccount.owner?.hrUser?.jobTitle;
    return {
      accountName: <Ellipsify text={accountName} />,
      owner: (
        <AccessOwner
          serviceAccountUserId={serviceAccount.userId}
          owner={serviceAccount.owner}
        />
      ),
      jobTitle: isSome(jobTitle) ? (
        <Ellipsify text={jobTitle} />
      ) : (
        <NotApplicable />
      ),
      // any given service only has one field that informs the role column
      role: <AccessAccountRole serviceAccount={serviceAccount} />,
      status: getStatusString(serviceAccount),
      mfa: !isSome(serviceAccount.deactivationDate) ? (
        <MaybeBooleanToIcon bool={serviceAccount.mfa} />
      ) : (
        <AccountDeactivated />
      ),
      externalCreatedAt: isSome(serviceAccount.externalCreatedAt) ? (
        moment(serviceAccount.externalCreatedAt).format(DATE_FORMAT)
      ) : (
        <InfoNotAvailable />
      ),
      deactivationDate: isSome(serviceAccount.deactivationDate) ? (
        moment(serviceAccount.deactivationDate).format(DATE_FORMAT)
      ) : (
        <NotApplicable />
      ),
      actions: assignButton,
    };
  };

  return (
    <>
      <CreateUserDialog
        isOpen={isSome(serviceAccountIdForContractorDialog)}
        onClose={onCloseContractorDialog}
      />
      <Card>
        <DataTable
          useDefaultStyling
          stickyHeaders
          paginate={{
            paginationId: "access-accounts",
            defaultPageSize: ACCESS_PAGE_DEFAULT_PAGE_SIZE,
          }}
          customControls={{
            leftControls: [
              <ServiceDropdown
                key="service-dropdown"
                credentials={credentials}
                defaultCredential={defaultCredential}
                onSelect={() => setSelectedFilter(null)}
              />,
              filterElement,
            ],
          }}
          columnOrder={ACCOUNTS_COLUMN_ORDER}
          columnSortFunctions={COLUMN_SORT_FUNCTIONS}
          columnWidths={TABLE_COLUMN_WIDTHS}
          columnClasses={{
            mfa: COLUMN_CLASSES.CENTER_ALIGN,
            externalCreatedAt: COLUMN_CLASSES.CENTER_ALIGN,
            deactivationDate: COLUMN_CLASSES.CENTER_ALIGN,
            actions: COLUMN_CLASSES.CENTER_ALIGN,
          }}
          columnVisibilities={{ jobTitle: linkedHrServices.size > 0 }}
          defaultSortColumn={"accountName"}
          header={ACCOUNTS_COLUMN_HEADERS}
          data={filteredAccounts}
          createRow={getRowForServiceAccount}
          resetTableStateKey={credentialKey}
          searchFilter={accountMatchesSearchString}
          emptyDefault={TableEmptyDefault}
        />
      </Card>
    </>
  );
};

function updateServiceAccountsCache(
  serviceAccountsCache: AccessServiceAccount[],
  user: Maybe<VantaUser | "extUser" | "nonHuman">,
  serviceAccount: { id: string }
) {
  return serviceAccountsCache.map(account => {
    if (account.id === serviceAccount.id) {
      const owner =
        isSome(user) && typeof user === "object" && "id" in user ? user : null;

      const newUserId = getNewUserId(user);

      return {
        ...account,
        owner,
        userId: newUserId,
        userLinkDate: new Date().toISOString(),
      };
    } else {
      return account;
    }
  });
}

const inFlightAccountLinkReducer = (
  currentInFlightAccountLinkages: Set<string>,
  action: { type: "LINK_START" | "LINK_FINISH"; serviceAccountId: string }
) => {
  switch (action.type) {
    case "LINK_START": {
      const inFlightSet = new Set(currentInFlightAccountLinkages);
      inFlightSet.add(action.serviceAccountId);
      return inFlightSet;
    }
    default: {
      const inFlightSet = new Set(currentInFlightAccountLinkages);
      inFlightSet.delete(action.serviceAccountId);
      return inFlightSet;
    }
  }
};

function getUserIdOnAssignAccount(
  user: Maybe<SelectorUser | "extUser" | "nonHuman">
) {
  return user === "nonHuman"
    ? "NON_HUMAN"
    : user === "extUser"
    ? "EXT_USER"
    : isSome(user)
    ? user.id
    : null;
}

function getToastMessageOnAccountLink(
  serviceAccount: { accountName: string },
  user: Maybe<SelectorUser | "extUser" | "nonHuman">
) {
  let toastMessage: string;
  switch (user) {
    case "nonHuman":
      toastMessage = "marked as not a person";
      break;
    case "extUser":
      toastMessage = "marked as an external person";
      break;
    case undefined:
    case null:
      toastMessage = "unlinked";
      break;
    default:
      toastMessage = `assigned ${
        isSome(user.displayName) ? `to ${user.displayName}` : ""
      }`;
  }
  return `Account ${serviceAccount.accountName} ${toastMessage}`;
}

function getNewUserId(user?: Maybe<SelectorUser | "extUser" | "nonHuman">) {
  return typeof user === "object"
    ? user?.id ?? null
    : user === "nonHuman"
    ? "machine"
    : "external";
}
