import { isSome } from "common/base/types/maybe";
import { RISK_CATEGORIES } from "common/schemas/riskRegister/risk-register-content";
import { keyBy } from "lodash";
import moment from "moment";
import React from "react";
import styled from "styled-components";

import { VANTA_COLORS } from "../../../../alpaca/base/colors";
import { BodyText } from "../../../../alpaca/components";
import type { GetInfoForRiskAssessmentReportQuery } from "../../../../gen/components";
import { lastNameSort } from "../../../../helpers/user-sort-functions";
import { DataTable } from "../../components/data-table";
import {
  TABLE_STYLES,
  TableHeading,
  TableNormalText,
  TIME_FORMAT,
} from "../table-styles";
import { RiskTaskCompletionCheckbox } from "./risk-task-completion-checkbox";

type Scenario = NonNullable<
  GetInfoForRiskAssessmentReportQuery["organization"]
>["rrv3RiskScenarios"][number];

type Task = Scenario["tasks"][number];
type TaskForTable = Task & { scenario: string; section: string };

function addScenarioAndSection(
  task: Task,
  scenario: string,
  section: string
): TaskForTable {
  return { ...task, scenario, section };
}

interface IProps {
  scenarios: Scenario[];
}

const TABLE_COLUMN_ORDER = [
  "complete",
  "section",
  "scenario",
  "task",
  "created",
  "duedate",
  "assignee",
];

const TABLE_HEADERS = {
  complete: <TableHeading></TableHeading>,
  section: <TableHeading>Section</TableHeading>,
  scenario: <TableHeading>Risk Scenario</TableHeading>,
  task: <TableHeading>Task</TableHeading>,
  created: <TableHeading>Created</TableHeading>,
  duedate: <TableHeading>Due Date</TableHeading>,
  assignee: <TableHeading>Assignee</TableHeading>,
};

const TABLE_COLUMN_WIDTHS = [
  "60px",
  "150px",
  "240px",
  "240px",
  "100px",
  "100px",
  "130px",
];

type TaskSortFn = (t1: TaskForTable, t2: TaskForTable) => number;

const TABLE_SORT_FUNCTIONS: { [k: string]: TaskSortFn } = {
  scenario: (task1, task2) => task1.scenario.localeCompare(task2.scenario),
  section: (task1, task2) => task1.section.localeCompare(task2.section),
  task: (task1, task2) => task1.description.localeCompare(task2.description),
  created: (task1, task2) =>
    moment(task1.createdAt).valueOf() - moment(task2.createdAt).valueOf(),
  duedate: (task1, task2) =>
    moment(task1.dueDate ?? undefined).valueOf() -
    moment(task2.dueDate ?? undefined).valueOf(),
  assignee: (task1, task2) =>
    lastNameSort(
      task1.assignee ?? { displayName: "No user assigned" },
      task2.assignee ?? { displayName: "No user assigned" }
    ),
};

const rowCreator = (task: TaskForTable) => {
  const taskIsOpen = !isSome(task.completedAt);
  return {
    complete: <RiskTaskCompletionCheckbox task={task} />,
    section: (
      <TableNormalText active={taskIsOpen}>{task.section}</TableNormalText>
    ),
    scenario: (
      <TableNormalText active={taskIsOpen}>{task.scenario}</TableNormalText>
    ),
    task: (
      <TableNormalText active={taskIsOpen}>{task.description}</TableNormalText>
    ),
    created: (
      <TableNormalText>
        {moment(task.createdAt).format(TIME_FORMAT)}
      </TableNormalText>
    ),
    duedate: (
      <TableNormalText
        alert={
          taskIsOpen && moment(task.dueDate ?? undefined).isBefore(moment())
        }
      >
        {moment(task.dueDate ?? undefined).format(TIME_FORMAT)}
      </TableNormalText>
    ),
    assignee: <TableNormalText>{task.assignee?.displayName}</TableNormalText>,
  };
};

const riskIdToRisk = keyBy(
  Object.values(RISK_CATEGORIES).flatMap(c => c.risks),
  r => r.id
);

export const RiskTaskTable: React.FC<IProps> = ({ scenarios }) => {
  const tasks = scenarios
    .map(s =>
      s.tasks.map(t =>
        addScenarioAndSection(
          t,
          s.description,
          riskIdToRisk[s.riskCategoryId].shortHeading
        )
      )
    )
    .flat();

  const table =
    tasks.length > 0 ? (
      <DataTable
        data={tasks}
        header={TABLE_HEADERS}
        columnOrder={TABLE_COLUMN_ORDER}
        columnSortFunctions={TABLE_SORT_FUNCTIONS}
        columnWidths={TABLE_COLUMN_WIDTHS}
        createRow={rowCreator}
        rowClass={task =>
          isSome(task.completedAt) ? "risk-task-row-complete" : undefined
        }
      />
    ) : (
      <BodyText color={VANTA_COLORS.TEXT_DESCRIPTION}>
        No tasks have been created for this category
      </BodyText>
    );

  return <Container>{table}</Container>;
};

const Container = styled.div`
  table {
    ${TABLE_STYLES}
    tr.risk-task-row-complete {
      background-color: #fbfbfb;
    }
  }
`;
