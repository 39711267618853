import { Feature } from "../../base/types/gen";
import { isSome } from "../../base/types/maybe";

export const TSC_CODE_PREFIXES = {
  SECURITY: "CC",
  AVAILABILITY: "A",
  CONFIDENTIALITY: "C",
  PRIVACY: "P",
  PROCESSING_INTEGRITY: "PI",
};
export const TSC_CODE_PREFIX_REGEX = /^([A-Z]+) /;

/**
 * If you are removing the code below because we've made a new system for opting
 * customers into SOC 2 TSCs that doesn't leverage features, ensure
 * that filtering logic across product - including Standards View
 * and Reports - holds so that domains only see controls and sections
 * mapped to TSCs they opted into.
 */
export const getDomainTscs = (domainFeatures: Set<Feature>): string[] =>
  TSC_FEATURES.filter(feature => domainFeatures.has(feature as Feature)).concat(
    ...DEFAULT_TSCS
  );

export const TSC_FEATURES: string[] = [
  Feature.Soc2Availability,
  Feature.Soc2Confidentiality,
];

const OTHER_TSC_MARKERS = {
  SECURITY: "Security",
  PRIVACY: "Privacy",
  PROCESSING_INTEGRITY: "Processing Integrity",
};

const TSC_PREFIX_TO_FEATURE = {
  [TSC_CODE_PREFIXES.SECURITY]: OTHER_TSC_MARKERS.SECURITY,
  [TSC_CODE_PREFIXES.AVAILABILITY]: Feature.Soc2Availability,
  [TSC_CODE_PREFIXES.CONFIDENTIALITY]: Feature.Soc2Confidentiality,
  [TSC_CODE_PREFIXES.PRIVACY]: OTHER_TSC_MARKERS.PRIVACY,
  [TSC_CODE_PREFIXES.PROCESSING_INTEGRITY]:
    OTHER_TSC_MARKERS.PROCESSING_INTEGRITY,
};

export const DEFAULT_TSCS = [OTHER_TSC_MARKERS.SECURITY];
export const ALL_TSCS = [...DEFAULT_TSCS, ...TSC_FEATURES] as const;

export const sectionInEnabledTscs = (
  sectionId: string,
  enabledTscs: Set<string>
) => {
  const maybePrefixMatch = TSC_CODE_PREFIX_REGEX.exec(sectionId);
  if (
    isSome(maybePrefixMatch) &&
    maybePrefixMatch[1] in TSC_PREFIX_TO_FEATURE
  ) {
    return enabledTscs.has(TSC_PREFIX_TO_FEATURE[maybePrefixMatch[1]]);
  } else {
    return false;
  }
};
