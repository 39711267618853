import { INFRA_SERVICES } from "common/base/types/helpers";
import { isSome } from "common/base/types/maybe";
import { getAccountIdFromArnAndRegions } from "common/resources/specific-resources/aws/aws-helpers-client-safe";
import React, { useMemo } from "react";

import {
  TAB_IDS,
  UserDetailDrawer,
} from "../people/user-drawer/user-detail-drawer";
import type { AccountsAccessCredential, VantaUser } from "./access-page";
import { CredentialsContext } from "./credentials-context";
import { ServiceAccessContainer } from "./service-access-container";

export const AccessPageWithService: React.FC<{
  credentials: AccountsAccessCredential[];
  users: VantaUser[];
}> = ({ credentials, users }) => {
  const credentialKeyToCredentialMap = useMemo(
    () => new Map(credentials.map(c => [accessPageCredentialToKey(c), c])),
    [credentials]
  );
  const sortedCredentials = useMemo(
    () => credentials.sort((c1, c2) => c1.service.localeCompare(c2.service)),
    [credentials]
  );

  const urlCredentialKey = new URLSearchParams(location.search).get(
    "credentialKey"
  );
  const infraProviders: Set<string> = new Set(INFRA_SERVICES);
  const infraProvider = sortedCredentials.find(s =>
    infraProviders.has(s.service)
  );
  const defaultCredential = isSome(infraProvider)
    ? infraProvider
    : sortedCredentials[0];
  const selectedCredential =
    isSome(urlCredentialKey) &&
    credentialKeyToCredentialMap.has(urlCredentialKey)
      ? credentialKeyToCredentialMap.get(urlCredentialKey)!
      : defaultCredential;

  return (
    <CredentialsContext.Provider value={{ credentials, defaultCredential }}>
      <ServiceAccessContainer credential={selectedCredential} users={users} />
      <UserDetailDrawer initialTab={TAB_IDS.ACCESS} />
    </CredentialsContext.Provider>
  );
};

/**
 * Returns a string key that represents a given selected credential and
 * for aws accounts, the account id in the form:
 * For aws:
 * {service}-{accountId}
 * Otherwise:
 * {service}
 * @param credential
 */
export function accessPageCredentialToKey(
  credential: AccountsAccessCredential
) {
  if (credential.service === "aws" && isSome(credential.metadata)) {
    const accountId = getAWSAccountIdFromCredentialMetadata(
      credential.metadata
    );
    return `${credential.service}-${accountId}`;
  }
  return credential.service;
}

export function getAWSAccountIdFromCredentialMetadata(metadata: string) {
  const parsed = JSON.parse(metadata);
  const regions = parsed.regions;
  const arn = parsed?.assumeRoleArn; // arn doesn't exist for govcloud
  return getAccountIdFromArnAndRegions(arn, regions);
}
