import { Classes } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import React from "react";

import { DefaultLink } from "../../../helpers/links";

interface IProps {
  concern: "agentAsMalware" | "credentials" | "osquery" | "ssh";
  isDialogBody?: Maybe<boolean>;
}

const credentialsReassurance = (
  <div>
    <h5>Your credentials are secure</h5>
    <p>
      Vanta always uses read-only permissions where possible, never modifies
      your data, and encrypts all credentials at rest, restricting decryption
      access to an isolated scanning process that performs continuous
      monitoring.
    </p>
  </div>
);

const osqueryReassurance = (
  <div>
    <p>
      The Vanta Agent can be installed on employee computers and production
      servers. The agent is based on{" "}
      <DefaultLink href="https://osquery.io/">osquery</DefaultLink> – an
      open-source project maintained and vetted by the Linux Foundation. osquery
      was originally developed at Facebook, and still runs across Facebook’s
      fleet of employee laptops and servers.
    </p>

    <p>
      The Vanta Agent is read-only, which means it will not change anything on
      your machines. It has limited functionality to read data – Vanta deploys a
      modified version of osquery that doesn’t include tables that we find
      dangerous. Vanta does not read sensitive information like passwords,
      emails, or browsing history.
    </p>

    <p>
      Vanta has selected a subset of the queries that osquery provides that are
      relevant for compliance monitoring. These queries are published to your
      machines from Vanta’s server. The installation process ensures that our
      server’s certificate is pinned; that is, only our server is authorized to
      send or receive data from your machines. You can examine the certificate
      at{" "}
      <DefaultLink href="https://osquery.vanta.com/">
        https://osquery.vanta.com/
      </DefaultLink>{" "}
      by clicking on the lock icon in your browser’s URL bar.
    </p>

    <p>
      You can also monitor the exact set of queries that Vanta is running on
      your machines. We run each query multiple times a day, usually on an
      hourly basis. To see the exact schedule, run{" "}
      <code>sudo ./vanta-cli schedule</code> from the <code>vanta</code>{" "}
      directory on any machine running the Vanta Agent.
    </p>

    <p>
      Vanta uses{" "}
      <DefaultLink href="https://docs.docker.com/notary/service_architecture/#threat-model">
        Docker Notary
      </DefaultLink>{" "}
      to distribute binaries, which is the same technology that secures Docker
      Hub. An unauthorized update would require physical and logical access to
      multiple independent systems. To further decrease the likelihood of such
      an attack, Vanta takes precautions like requiring disk encryption and MFA
      access to third-party services.
    </p>

    <p>
      If you have any concerns about the security of the Vanta Agent, feel free
      to reach out to{" "}
      <DefaultLink href="mailto:support@vanta.com">
        support@vanta.com
      </DefaultLink>
      !
    </p>
  </div>
);

const agentAsMalwareReassurance = (
  <div>
    <p>
      The Vanta laptop app uses Osquery to check for common OSX and Linux
      malware including OSSEC’s rootkit library. The specific checks are
      available online at:
    </p>
    <p>
      <DefaultLink href="https://github.com/facebook/osquery/blob/master/packs/ossec-rootkit.conf">
        https://github.com/facebook/osquery/blob/master/packs/ossec-rootkit.conf
      </DefaultLink>
    </p>
    <p>
      <DefaultLink href="https://github.com/facebook/osquery/blob/master/packs/osx-attacks.conf">
        https://github.com/facebook/osquery/blob/master/packs/osx-attacks.conf
      </DefaultLink>
    </p>
    <p>
      Vanta’s agent monitors for malware on macOS and Linux, but doesn’t
      currently attempt to be a full-featured antivirus system. We run hourly
      checks to see if there are traces of malware in your fleet (we look for
      things like self-deleting executables and signatures of known malicious
      programs) but don’t constantly monitor program executions like those on
      your lab machine. We’ve found that antivirus systems are not as common on
      macOS and Linux endpoints, where malware is comparatively rare.
    </p>
    <p>
      If you’re looking for a more traditional antivirus solution, please
      contact{" "}
      <DefaultLink href="mailto:support@vanta.com">
        support@vanta.com
      </DefaultLink>{" "}
      and we can provide recommendations.
    </p>
  </div>
);

const sshReassurance = (
  <div>
    <h5>Absolutely not!</h5>
    <p>
      Vanta never collects any private keys and only collects short fingerprints
      of public keys.
    </p>
    <p>
      Vanta's agent looks for fingerprints of public keys so we can more
      accurately determine who in your organization has access to which
      resources. These fingerprints reveal no information about the private
      keys.
    </p>
    {/* <p>
      Something something about accessing the extensions on github if we open-source it
    </p> */}
  </div>
);

export const AMISecure: React.FC<IProps> = ({
  concern = "credentials",
  isDialogBody = true,
}) => {
  let reassurance;
  if (concern === "credentials") {
    reassurance = credentialsReassurance;
  } else if (concern === "osquery") {
    reassurance = osqueryReassurance;
  } else if (concern === "ssh") {
    reassurance = sshReassurance;
  } else if (concern === "agentAsMalware") {
    reassurance = agentAsMalwareReassurance;
  }

  return (
    <div className={isDialogBody ? Classes.DIALOG_BODY : undefined}>
      {reassurance}
    </div>
  );
};
