import { Intent } from "@blueprintjs/core";
import { TestOutcome } from "common/base/types/gen";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import moment from "moment";
import React, { useContext, useMemo, useState } from "react";
import { useHistory } from "react-router";

import { BASE_TYPOGRAPHY } from "../../../alpaca/base/typography";
import {
  BodyText,
  IconNames,
  Menu,
  MenuButton,
  MenuItem,
} from "../../../alpaca/components";
import { LogError } from "../../../errors";
import { useSendReminderEmailMutation } from "../../../gen/components";
import { DefaultLink } from "../../../helpers/links";
import { AppToaster } from "../../../helpers/toaster";
import { lastNameSort } from "../../../helpers/user-sort-functions";
import { booleanToEvaluationEnum } from "../../helpers/EvaluationIcon";
import { FullPageSpinner } from "../../helpers/FullPageSpinner";
import { Card } from "../components/card";
import { COLUMN_CLASSES, DataTable } from "../components/data-table";
import { DropdownButton } from "../components/dropdown-button";
import { ComputerOwnerAssignDialog } from "../computer-owner-assign-dialog";
import { getWorkstationReminderMenuItems } from "../people/user-drawer/email-alert-button";
import {
  TAB_IDS,
  UserDetailDrawer,
} from "../people/user-drawer/user-detail-drawer";
import { UserContext } from "../user-context";
import {
  AVProgramEvaluationIcon,
  EncryptedEvaluationIcon,
  PasswordManagerEvaluationIcon,
} from "./evaluation-icons";
import { MonitoredComputersCSVButton } from "./monitored-computers-csv-button";
import { OSInfo } from "./os-info";
import { RemoveComputerDialog } from "./remove-computer-dialog";
import { UserProfile } from "./user-profile";
import type { IUserWorkstation } from "./utils";
import {
  COMPUTERS_PAGE_TAB_IDS,
  ManagedComputerTypes,
  monitoredByFromWorkstation,
} from "./utils";

const COLUMN_ORDER = [
  "owner",
  "os",
  "passwordManager",
  "encrypted",
  "antivirus",
  "lastCheck",
  "actions",
];

const HEADERS = {
  owner: "Owner",
  os: "OS Version",
  passwordManager: "PW Manager",
  encrypted: "HD Encrypted",
  antivirus: "AV Installed",
  lastCheck: "Last Check",
  actions: "Actions",
};

const COLUMN_WIDTHS = [
  "320px",
  "240px",
  "160px",
  "160px",
  "160px",
  "160px",
  "100px",
];

const FILTER_NAMES = [
  "HD not encrypted",
  "PW manager not installed",
  "Antivirus not installed",
  "Last check > 14 days",
] as const;

const oldLastCheck = (lastCheck: Maybe<Date>) =>
  !isSome(lastCheck) || moment().diff(moment(lastCheck), "days") > 14;

const TABLE_FILTERS: {
  [k in typeof FILTER_NAMES[number]]: (
    workstation: IUserWorkstation
  ) => boolean;
} = {
  "HD not encrypted": workstation =>
    booleanToEvaluationEnum(workstation.isEncrypted) === TestOutcome.FAIL,
  "PW manager not installed": workstation =>
    workstation.passwordManagers.length === 0,
  "Antivirus not installed": workstation =>
    workstation.antivirusPrograms.length + workstation.avPolicies.length === 0,
  "Last check > 14 days": workstation =>
    isSome(workstation.lastData) ? oldLastCheck(workstation.lastData) : true,
};

const TABLE_SORT_FUNCTIONS: {
  [k: string]: (w1: IUserWorkstation, w2: IUserWorkstation) => number;
} = {
  owner: (w1, w2) => lastNameSort(w1.user, w2.user),
  os: (w1, w2) => (w1.osVersion ?? "").localeCompare(w2.osVersion ?? ""),
  passwordManager: (w1, w2) =>
    w1.passwordManagers.length - w2.passwordManagers.length,
  encrypted: (w1, w2) => {
    const w1Encrypted = w1.isEncrypted ?? false;
    const w2Encrypted = w2.isEncrypted ?? false;
    if (!w1Encrypted && !w2Encrypted) {
      return 1;
    } else if (w1Encrypted && !w2Encrypted) {
      return 1;
    } else if (w2Encrypted && !w1Encrypted) {
      return -1;
    }
    return 1;
  },
  antivirus: (w1, w2) =>
    w1.antivirusPrograms.length +
    w1.avPolicies.length -
    w2.antivirusPrograms.length -
    w2.avPolicies.length,
  lastCheck: (w1, w2) =>
    (w1.lastData?.getTime() ?? 0) - (w2.lastData?.getTime() ?? 0),
};

interface IProps {
  workstations: IUserWorkstation[];
  domainId: string;
}

interface IAssignOwnerProps {
  workstation: Maybe<IUserWorkstation>;
  isOpen: boolean;
}

interface IRemoveWorkstationProps {
  workstation: Maybe<IUserWorkstation>;
  isOpen: boolean;
}

export const AllComputersView: React.FC<IProps> = ({
  workstations,
  domainId,
}) => {
  const history = useHistory();
  const [selectedFilter, setSelectedFilter] =
    useState<Maybe<typeof FILTER_NAMES[number]>>(null);
  const [sortParams, setSortParams] = useState<{
    column: string;
    reverse: boolean;
  }>({ column: "owner", reverse: false });
  const { user: sendingUser } = useContext(UserContext);
  const [sendReminder] = useSendReminderEmailMutation({
    onCompleted() {
      AppToaster.show({
        message: "Email sent",
        intent: Intent.SUCCESS,
      });
    },
  });
  const [searchString, setSearchString] = useState("");
  const [assignOwnerProps, setAssignOwnerProps] = useState<IAssignOwnerProps>({
    workstation: null,
    isOpen: false,
  });
  const [removeWorkstationProps, setRemoveWorkstationProps] =
    useState<IRemoveWorkstationProps>({
      workstation: null,
      isOpen: false,
    });

  function getMenuItemsForEndpoints(workstation: IUserWorkstation) {
    const result: JSX.Element[] = [];

    result.push(
      ...getWorkstationReminderMenuItems(workstation, (flag, platform) => {
        sendReminder({
          variables: {
            userId: workstation.user.id,
            flag,
            domainId: sendingUser!.domain.id,
            platform,
          },
        }).catch(LogError);
      })
    );

    if (
      !ManagedComputerTypes.includes(workstation.__typename) &&
      workstation.isReassignable
    ) {
      result.push(
        <MenuItem
          alpacaIcon={IconNames.PERSON_ALERT}
          key="reassign"
          text="Reassign owner"
          onClick={() => {
            setAssignOwnerProps({
              workstation,
              isOpen: true,
            });
          }}
        />
      );
    }

    if (!ManagedComputerTypes.includes(workstation.__typename)) {
      result.push(
        <MenuItem
          alpacaIcon={IconNames.DELETE}
          key="remove"
          text="Remove"
          onClick={() => {
            setRemoveWorkstationProps({
              workstation,
              isOpen: true,
            });
          }}
        />
      );
    }

    return result;
  }

  function getActionsButton(workstation: IUserWorkstation) {
    const menuItems = getMenuItemsForEndpoints(workstation);

    const monitoredBy = monitoredByFromWorkstation(workstation);
    const disabledText = `This computer is managed by ${monitoredBy}. To edit it, update it in ${monitoredBy}.`;

    // Outer div prevents the user drawer from opening on click.
    return (
      <div
        onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) =>
          e.stopPropagation()
        }
      >
        <MenuButton
          disabled={menuItems.length === 0}
          menu={<Menu>{menuItems}</Menu>}
          small
          tooltipContent={disabledText}
        />
      </div>
    );
  }

  function filterAndSortTableData(tableData: IUserWorkstation[]) {
    const lowerCasedSearch = searchString.trim().toLocaleLowerCase();

    const filteredData = tableData
      .filter(
        isSome(selectedFilter) ? TABLE_FILTERS[selectedFilter] : () => true
      )
      .filter(filterBySearchString);

    if (isSome(sortParams) && sortParams.column in TABLE_SORT_FUNCTIONS) {
      filteredData.sort(
        (w1, w2) =>
          (sortParams.reverse ? -1 : 1) *
          TABLE_SORT_FUNCTIONS[sortParams.column](w1, w2)
      );
    }

    return filteredData;

    function filterBySearchString(workstation: IUserWorkstation) {
      if (lowerCasedSearch === "") {
        return true;
      }
      return (
        (isSome(workstation.user.displayName) &&
          workstation.user.displayName
            .toLocaleLowerCase()
            .includes(lowerCasedSearch)) ||
        (isSome(workstation.name) &&
          workstation.name.toLocaleLowerCase().includes(lowerCasedSearch))
      );
    }
  }

  const filteredWorkstations = useMemo(() => {
    if (!isSome(workstations)) {
      return null;
    }

    return filterAndSortTableData(workstations);
  }, [workstations, selectedFilter, sortParams, searchString]);

  if (!isSome(filteredWorkstations)) {
    return <FullPageSpinner />;
  }

  const allFilters = [...FILTER_NAMES];

  const filterElements = [
    <DropdownButton
      key={"filter-dropdown"}
      options={allFilters}
      defaultText={"Filter by status"}
      optionRenderer={filter => filter}
      isFilter={true}
      selectedOption={selectedFilter}
      onOptionSelect={filter => {
        const params = new URLSearchParams(window.location.search);
        setSelectedFilter(filter);
        history.push({ search: params.toString() });
      }}
      buttonWidth={240}
      menuWidth={240}
      styleOnSelect
    />,
  ];

  return (
    <>
      <Card>
        <DataTable
          useDefaultStyling
          stickyHeaders
          columnClasses={{
            passwordManager: COLUMN_CLASSES.CENTER_ALIGN,
            encrypted: COLUMN_CLASSES.CENTER_ALIGN,
            antivirus: COLUMN_CLASSES.CENTER_ALIGN,
            actions: COLUMN_CLASSES.CENTER_ALIGN,
          }}
          columnOrder={COLUMN_ORDER}
          columnWidths={COLUMN_WIDTHS}
          controlledSearchParams={{
            searchString,
            onNewSearchString: setSearchString,
          }}
          createRow={workstation => {
            return {
              owner: (
                <UserProfile
                  imageUrl={workstation.user.imageUrl}
                  primaryText={workstation.user.displayName}
                  secondaryText={workstation.serialNumber ?? ""}
                  isExEmployee={!workstation.user.isActive}
                />
              ),
              os: <OSInfo key={"os"} workstation={workstation} />,
              passwordManager: (
                <PasswordManagerEvaluationIcon
                  workstation={workstation}
                  key={workstation.id}
                />
              ),
              encrypted: (
                <EncryptedEvaluationIcon
                  key={"hdEncrypted"}
                  workstation={workstation}
                />
              ),
              antivirus: (
                <AVProgramEvaluationIcon
                  key={"avProgram"}
                  workstation={workstation}
                />
              ),
              lastCheck: (
                <BodyText
                  key={"lastCheck"}
                  fontWeight={
                    oldLastCheck(workstation.lastData)
                      ? BASE_TYPOGRAPHY.FONT_WEIGHTS.BOLD
                      : BASE_TYPOGRAPHY.FONT_WEIGHTS.NORMAL
                  }
                >
                  {isSome(workstation.lastData)
                    ? moment(workstation.lastData).fromNow()
                    : ""}
                </BodyText>
              ),
              actions: getActionsButton(workstation),
            };
          }}
          customControls={{
            leftControls: filterElements,
            rightControls: [
              <MonitoredComputersCSVButton
                key="workstation-csv"
                workstations={filteredWorkstations}
              />,
            ],
          }}
          data={filteredWorkstations}
          emptyDefault={
            <BodyText>
              No computers to display. Head to the{" "}
              <DefaultLink
                href={`/computers?tab=${COMPUTERS_PAGE_TAB_IDS.MONITORED}`}
              >
                Unmonitored
              </DefaultLink>{" "}
              tab to get started.
            </BodyText>
          }
          header={HEADERS}
          onRowClick={workstation => {
            const userId = workstation.user.id;
            history.push(
              `${window.location.pathname}?tab=${COMPUTERS_PAGE_TAB_IDS.MONITORED}&userId=${userId}`
            );
          }}
          paginate={{ paginationId: "all-computers-view-mtm" }}
          sortableColumns={Object.keys(TABLE_SORT_FUNCTIONS)}
          isReverseSort={sortParams.reverse}
          onHeaderSort={(column, reverse) => {
            setSortParams({ column, reverse });
          }}
        />
      </Card>

      <UserDetailDrawer initialTab={TAB_IDS.COMPUTERS} />

      {isSome(removeWorkstationProps.workstation) ? (
        <RemoveComputerDialog
          workstation={removeWorkstationProps.workstation}
          isOpen={removeWorkstationProps.isOpen}
          domainId={domainId}
          onClose={() => {
            setRemoveWorkstationProps({ isOpen: false, workstation: null });
          }}
        />
      ) : null}
      <ComputerOwnerAssignDialog
        workstation={assignOwnerProps.workstation}
        isOpen={assignOwnerProps.isOpen}
        onClose={() => {
          setAssignOwnerProps({ isOpen: false, workstation: null });
        }}
      />
    </>
  );
};
