import { Intent } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import {
  isSome,
  applyIfSome,
  dropNothing,
  getTransformedOrElse,
} from "common/base/types/maybe";
import React from "react";
import styled from "styled-components";

import { BASE_PALETTE } from "../../base/colors";
import { GRID_SPACING } from "../../base/grid";
import { BASE_TYPOGRAPHY } from "../../base/typography";
import { Button2 } from "../button2/button2";
import { Icon } from "../icon/icon";
import type { IconName } from "../icon/iconNames";
import { IconNames } from "../icon/iconNames";
import { SpacedList } from "../primitives/spaced-list";
import { BodyText } from "../typography/typography";

export type BannerIntent =
  | typeof Intent.SUCCESS
  | typeof Intent.WARNING
  | typeof Intent.NONE;

const INTENT_TO_BANNER_BG_COLOR: { [k in BannerIntent]: string } = {
  [Intent.SUCCESS]:
    "linear-gradient(0deg, rgba(47, 180, 116, 0.16), rgba(47, 180, 116, 0.16)), #FFFFFF;",
  [Intent.WARNING]:
    "linear-gradient(0deg, rgba(255, 205, 28, 0.16), rgba(255, 205, 28, 0.16)), #FFFFFF;",
  [Intent.NONE]: BASE_PALETTE.SNOW,
};

type BannerButtonProps = {
  text: string;
  onClick(): void;
};

interface IBannerProps {
  buttonProps?: Maybe<BannerButtonProps>;
  description?: Maybe<string>;
  icon?: Maybe<IconName>;
  intent?: Maybe<BannerIntent>;
  onClose?: Maybe<() => void>;
  title: React.ReactNode;
}

export const Banner: React.FC<IBannerProps> = ({
  buttonProps,
  children,
  description,
  icon,
  intent,
  onClose,
  title,
}) => {
  const rightContents = dropNothing([
    // Optional button
    applyIfSome(buttonProps, props => (
      <Button2 key="button" onClick={props.onClick}>
        {props.text}
      </Button2>
    )),
    // Close icon
    applyIfSome(onClose, handler => (
      <div key="close" style={{ height: 32, paddingTop: 10 }}>
        <CloseIcon iconSize={12} icon={IconNames.CLOSE} onClick={handler} />
      </div>
    )),
  ]);

  const hasNoContent = !isSome(description) && !isSome(children);

  return (
    <StyledDiv intent={intent ?? Intent.NONE}>
      <StyledFlexDiv centered={hasNoContent}>
        {getTransformedOrElse(
          icon,
          iconName => (
            <StyledIconContanerDiv noPadding={hasNoContent}>
              <Icon
                style={{ color: BASE_PALETTE.INK }}
                icon={iconName}
                iconSize={16}
              />
            </StyledIconContanerDiv>
          ),
          null
        )}
        <StyledMainContentDiv>
          <BodyText
            as="div"
            fontSize={16}
            lineHeight="24px"
            fontWeight={BASE_TYPOGRAPHY.FONT_WEIGHTS.SLIGHTLY_BOLD}
          >
            {title}
          </BodyText>
          {getTransformedOrElse(
            description,
            text => (
              <BodyText
                as="div"
                color={BASE_PALETTE.CHARCOAL}
                fontSize={14}
                lineHeight="20px"
                style={{ marginTop: `${0.5 * GRID_SPACING}px` }}
              >
                {text}
              </BodyText>
            ),
            null
          )}
          {getTransformedOrElse(
            children,
            content => (
              <>
                <HR />
                {content}
              </>
            ),
            null
          )}
        </StyledMainContentDiv>
        {rightContents.length > 0 ? (
          <StyledSpacedList marginOverride={3 * GRID_SPACING}>
            {rightContents}
          </StyledSpacedList>
        ) : null}
      </StyledFlexDiv>
    </StyledDiv>
  );
};

const HR = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${BASE_PALETTE.FOG};
  margin: ${2 * GRID_SPACING}px 0;
`;

const StyledDiv = styled.div<{ intent: BannerIntent }>`
  background: ${({ intent }) => INTENT_TO_BANNER_BG_COLOR[intent]};
  padding: ${2.5 * GRID_SPACING}px ${3 * GRID_SPACING}px;
  width: 100%;

  border: 1px solid ${BASE_PALETTE.WIND};
  box-sizing: border-box;

  box-shadow: 0px 1px 4px rgba(51, 51, 51, 0.08);
  border-radius: 4px;
`;

const StyledFlexDiv = styled.div<{ centered?: Maybe<boolean> }>`
  display: flex;
  align-items: ${({ centered }) =>
    Boolean(centered) ? "center" : "flex-start"};
`;

const StyledIconContanerDiv = styled.div<{ noPadding?: Maybe<boolean> }>`
  margin-right: ${2 * GRID_SPACING}px;
  padding-top: ${({ noPadding }) =>
    Boolean(noPadding) ? 0 : 0.5 * GRID_SPACING}px;
`;

const StyledMainContentDiv = styled.div`
  flex-grow: 1;
`;

const StyledSpacedList = styled(SpacedList)`
  margin-left: ${3 * GRID_SPACING}px;
`;

const CloseIcon = styled(Icon)`
  color: ${BASE_PALETTE.INK};
  &:hover {
    cursor: pointer;
  }
`;
