import "./credit-card-form.scss";

import { Button, Intent } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import React from "react";
import type { ReactStripeElements } from "react-stripe-elements";
import { CardElement, injectStripe } from "react-stripe-elements";

import { LogError } from "../../errors";
interface IPaymentFormProps {
  buttonText?: Maybe<string>;
  loading?: Maybe<boolean>;
  needsSubmit?: Maybe<boolean>;
  linkedCreditCardInfo?: Maybe<{
    lastFourDigits: string;
    type: string;
  }>;

  // eslint-disable-next-line vanta/optional-always-maybe
  stripe?: ReactStripeElements.StripeProps;

  onStateChange(loading: boolean, stripeToken?: Maybe<string>): void;
}

interface IPaymentFormState {
  ccError?: Maybe<string>;
}

class ReactCreditCardFormInternal extends React.Component<
  IPaymentFormProps,
  IPaymentFormState
> {
  public constructor(props: IPaymentFormProps) {
    super(props);
    this.state = {};

    this.submit = this.submit.bind(this);
  }

  public render() {
    const cardStyle = {
      base: {
        "::placeholder": {
          color: "#aab7c4",
        },
        "color": "#32325d",
        "fontFamily": '"Helvetica Neue", Helvetica, sans-serif',
        "fontSize": "18px",
        "fontSmoothing": "antialiased",
        "lineHeight": "18px",
      },
      invalid: {
        color: "#F55656",
        iconColor: "#F55656",
      },
    };

    const ccError = isSome(this.state.ccError) ? (
      <div>{this.state.ccError}</div>
    ) : null;

    const loading = isSome(this.props.loading) ? this.props.loading : false;

    // react's stripe elements have bad typing, so we need this
    const disabledAsAny = { disabled: loading } as any;

    return (
      <React.Fragment>
        <CardElement style={cardStyle} {...disabledAsAny} />
        {ccError}
        {isSome(this.props.buttonText) ? (
          <Button
            loading={loading}
            onClick={() => this.submit()}
            intent={Intent.PRIMARY}
            className="credit-card-form-link-button"
          >
            {this.props.buttonText}
          </Button>
        ) : null}
      </React.Fragment>
    );
  }

  public UNSAFE_componentWillReceiveProps(nextProps: IPaymentFormProps) {
    if (nextProps.needsSubmit && !this.props.needsSubmit) {
      this.submit();
    }
  }

  public submit() {
    this.props.onStateChange(true, null);

    if (!this.props.stripe) {
      throw new Error("Stripe not defined");
    }

    this.props.stripe
      .createToken()
      .then(result => {
        if (result.error) {
          this.setState({
            ccError: result.error.message,
          });
          this.props.onStateChange(false, null);
          return;
        }

        if (!isSome(result.token)) {
          LogError(new Error("Undefined stripe token"));
          this.props.onStateChange(false, null);
          return;
        }
        this.props.onStateChange(false, result.token.id);
      })
      .catch(e => LogError(e));
  }
}

export const ReactStripeForm = injectStripe(ReactCreditCardFormInternal);
