import type { Intent } from "@blueprintjs/core";
import { Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import type { Maybe } from "common/base/types/maybe";
import { dropNothing, isSome, nothing } from "common/base/types/maybe";
import moment from "moment";
import React, { useContext } from "react";

import { FeatureContext } from "../../helpers/feature-gating/context";
import { domainHasFeature } from "../../helpers/feature-gating/feature-check";
import { DefaultLink } from "../../helpers/links";
import { Checkbox } from "../form-controls/checkbox";
import { DateInput } from "../form-controls/date-input";
import { HTMLSelect } from "../form-controls/html-select";
import { HTMLSelectWithEditableOther } from "../form-controls/html-select-with-editable-other";
import { Radio } from "../form-controls/radio";
import { RadioGroup } from "../form-controls/radio-group";
import { TextArea } from "../form-controls/text-area";
import { TextInput } from "../form-controls/text-input";
import { UserSelector } from "../form-controls/user-selector";
import { VideoPlayer } from "../form-controls/videoplayer";
import { FileInput } from "../pages/components/file-input";
import { MarkdownRenderer } from "../pages/components/markdown-renderer";
import type {
  IChoiceWithId,
  IFormControlValue,
  IQuestion,
  IUploadedFile,
} from "./interfaces";
import { SchemaFormGroup } from "./schema-form-group";

const CONTROL_WITHOUT_FORM_GROUPS = ["Header", "Paragraph"];

interface ISchemaFormControlProps {
  intent: Intent;
  readOnly?: Maybe<boolean>;
  question: IQuestion;
  currentValue: IFormControlValue | IUploadedFile;
  onNewValue(newValue: Maybe<IFormControlValue>): void;
}
export class SchemaFormControl extends React.Component<ISchemaFormControlProps> {
  public render() {
    const controlComponent = <ControlComponent {...this.props} />;
    const componentMaybeWithFormGroup = CONTROL_WITHOUT_FORM_GROUPS.includes(
      this.props.question.type
    ) ? (
      controlComponent
    ) : (
      <SchemaFormGroup
        question={this.props.question}
        intent={this.props.intent}
      >
        {controlComponent}
        {this.props.children}
      </SchemaFormGroup>
    );

    return componentMaybeWithFormGroup;
  }
}

// (Introducing complexity rule, should still fix)
// eslint-disable-next-line complexity
const ControlComponent: React.FunctionComponent<ISchemaFormControlProps> = ({
  question,
  currentValue,
  onNewValue,
  readOnly,
}) => {
  const generalProps = {
    id: question.id,
    label: question.label,
    name: question.name,
  };
  const featureContext = useContext(FeatureContext);
  switch (question.type) {
    case "CheckboxInput":
      return (
        <Checkbox
          {...generalProps}
          checked={(currentValue as boolean) ?? false}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onNewValue(e.target.checked)
          }
        />
      );
    case "Date":
      return (
        <DateInput
          canClearSelection={false}
          formatDate={date => date.toLocaleDateString()}
          onChange={date => onNewValue(date)}
          parseDate={str => new Date(str)}
          maxDate={moment().add(20, "years").toDate()}
          value={isSome(currentValue) ? new Date(currentValue as string) : null}
        />
      );
    case "EmailInput":
      return (
        <TextInput
          {...generalProps}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onNewValue(e.target.value)
          }
          placeholder="sandra@askgroup.com"
          type="email"
          value={(currentValue as string) ?? ""}
        />
      );
    case "File":
      if (readOnly) {
        if (
          isSome(currentValue) &&
          typeof currentValue === "object" &&
          "url" in currentValue
        ) {
          return (
            <DefaultLink
              href={`${location.origin}/${currentValue.url}`}
              className="qsf-url-with-paperclip"
            >
              <Icon icon={IconNames.PAPERCLIP} />
              {currentValue.filename}
            </DefaultLink>
          );
        } else {
          return (
            <div>
              <em>No file uploaded</em>
            </div>
          );
        }
      }
      return (
        <FileInput
          {...generalProps}
          accept={question.accept}
          uploadedFile={
            isSome(currentValue) &&
            typeof currentValue === "object" &&
            "url" in currentValue
              ? currentValue
              : nothing
          }
          selectedFile={
            isSome(currentValue) &&
            typeof currentValue === "object" &&
            !("url" in currentValue)
              ? (currentValue as File)
              : nothing
          }
          fileUploaded={(name, file) => onNewValue({ name, file })}
        />
      );
    case "Header":
      return (
        <div>
          <h3>{MarkdownRenderer(question.label)}</h3>
          {isSome(question.helper) ? MarkdownRenderer(question.helper) : null}
        </div>
      );
    case "MultiUser":
      return (
        <UserSelector
          allowNonHumanUsers={
            question.extraMeta ? question.extraMeta.allowNonHuman : nothing
          }
          multiselect={true}
          onSelect={(selected: string[]) => onNewValue(selected)}
          selected={currentValue as string[]}
        />
      );
    case "Paragraph":
      return (
        <div>
          {MarkdownRenderer(question.label)}
          {isSome(question.helper)
            ? MarkdownRenderer(question.helper)
            : nothing}
        </div>
      );
    case "RadioGroupInput": {
      const preChoices = question.choices as Array<string | IChoiceWithId>;
      const choices = dropNothing(
        preChoices.map(choice => {
          if (typeof choice === "string") {
            return {
              label: choice,
              value: choice,
              disabled: false,
            };
          } else {
            const labelAndValue = {
              label: choice.label,
              value: choice.id,
              disabled: choice.disabled,
            };
            if (!isSome(choice.feature)) {
              return labelAndValue;
            }

            if (domainHasFeature(featureContext, choice.feature)) {
              return labelAndValue;
            } else {
              return null;
            }
          }
        })
      );

      return (
        <RadioGroup
          name={question.name}
          onChange={e => onNewValue(e.currentTarget.value)}
          selectedValue={currentValue as string}
        >
          {choices.map(choice => (
            <Radio
              key={choice.value}
              value={choice.value}
              disabled={choice.disabled ?? undefined}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onNewValue(e.target.value)
              }
              // Allows for deselecting an option if the radio is not required
              onClick={() => {
                if (!question.required && choice.value === currentValue) {
                  onNewValue(null);
                }
              }}
              checked={choice.value === currentValue}
              labelElement={MarkdownRenderer(choice.label)}
            />
          ))}
        </RadioGroup>
      );
    }
    case "SelectInput":
      if (
        readOnly &&
        (!isSome(currentValue) || currentValue.toString().trim() === "")
      ) {
        return (
          <div>
            <em>No option selected</em>
          </div>
        );
      }
      return (
        <HTMLSelect
          name={question.name}
          choices={question.choices as string[]}
          value={(currentValue as string) ?? "internal-unselected"}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
            onNewValue(e.target.value)
          }
        />
      );
    case "SelectInputWithEditableOther":
      if (
        readOnly &&
        (!isSome(currentValue) || currentValue.toString().trim() === "")
      ) {
        return (
          <div>
            <em>No option selected</em>
          </div>
        );
      }
      return (
        <HTMLSelectWithEditableOther
          name={question.name}
          choices={question.choices as string[]}
          value={(currentValue as string) ?? "internal-unselected"}
          onNewValue={onNewValue}
        />
      );
    case "TextArea":
      if (readOnly) {
        if (isSome(currentValue) && currentValue.toString().trim() !== "") {
          return (
            <div>
              <pre>{currentValue}</pre>
            </div>
          );
        } else {
          return (
            <div>
              <em>No response given.</em>
            </div>
          );
        }
      }
      return (
        <TextArea
          {...generalProps}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            onNewValue(e.target.value)
          }
          value={(currentValue as string) ?? ""}
        />
      );
    case "TextInput":
      return (
        <TextInput
          {...generalProps}
          value={(currentValue as string) ?? ""}
          onChange={(e: React.ChangeEvent<any>) => onNewValue(e.target.value)}
        />
      );
    case "URLInput":
      return (
        <TextInput
          {...generalProps}
          value={(currentValue as string) ?? ""}
          placeholder="https://"
          type="url"
          onChange={(e: React.ChangeEvent<any>) => onNewValue(e.target.value)}
        />
      );
    case "User":
      return (
        <UserSelector
          allowNonHumanUsers={
            question.extraMeta ? question.extraMeta.allowNonHuman : nothing
          }
          onSelect={(choice: Maybe<string[]>) =>
            onNewValue(choice ? choice[0] : null)
          }
          selected={currentValue as string}
        />
      );
    case "Yes/No":
      return (
        <RadioGroup
          name={question.name}
          inline={true}
          onChange={e => onNewValue(e.currentTarget.value)}
          selectedValue={currentValue as string}
        >
          {YES_NO_RADIO_OPTIONS.slice(0, 2).map(option => (
            <Radio
              inline={true}
              key={option.value}
              {...option}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onNewValue(e.target.value)
              }
              checked={option.value === currentValue}
            />
          ))}
        </RadioGroup>
      );
    case "Yes/No/NA":
      return (
        <RadioGroup
          name={question.name}
          inline={true}
          onChange={e => onNewValue(e.currentTarget.value)}
          selectedValue={currentValue as string}
        >
          {YES_NO_RADIO_OPTIONS.map(option => (
            <Radio
              inline={true}
              key={option.value}
              {...option}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onNewValue(e.target.value)
              }
              checked={option.value === currentValue}
            />
          ))}
        </RadioGroup>
      );
    case "Video":
      return (
        <VideoPlayer
          onNewValue={onNewValue}
          urlKey={question.urlKey}
          options={{
            autoplay: false,
            controls: true,
            height: 360,
            width: 640,
          }}
        />
      );

    default:
      return <h2>NOTHING!!!</h2>;
  }
};

const YES_NO_RADIO_OPTIONS = [
  { label: "Yes", value: "yes" },
  { label: "No", value: "no" },
  { label: "N/A", value: "n/a" },
];
