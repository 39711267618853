import { Card, Dialog as BpDialog, Elevation } from "@blueprintjs/core";
import styled from "styled-components";

import { GRID_SPACING } from "../../../alpaca/base/grid";
import { BASE_TYPOGRAPHY } from "../../../alpaca/base/typography";
import { BodyShortText, BodyText } from "../../../alpaca/components";
import { BORDER_LINE } from "../../../helpers/borders";

const PoliciesCard = styled(Card).attrs({
  elevation: Elevation.TWO,
})``;

export const PoliciesH5 = styled(BodyShortText).attrs({
  fontWeight: BASE_TYPOGRAPHY.FONT_WEIGHTS.BOLD,
})``;

export const Dialog = styled(BpDialog).attrs({
  canEscapeKeyClose: true,
  canOutsideClickClose: true,
  isCloseButtonShown: true,
})`
  width: ${100 * GRID_SPACING}px;

  .bp3-dialog-header {
    .bp3-heading {
      font-size: ${2 * GRID_SPACING}px;
    }
  }
`;

export const DialogBody = styled.div`
  margin: ${3 * GRID_SPACING}px 0;
  padding-left: ${10 * GRID_SPACING}px;
  padding-right: ${10 * GRID_SPACING}px;
  width: 100%;
`;

export const Row = styled.div`
  border-bottom: ${BORDER_LINE};
  display: flex;
  justify-content: space-between;
  padding: ${2 * GRID_SPACING}px 0;
`;

export const PoliciesPaddedCard = styled(PoliciesCard)`
  padding: ${9 * GRID_SPACING}px ${11 * GRID_SPACING}px;
`;

export const PoliciesCondensedCardContent = styled.div`
  margin: 0 auto;
  width: ${50 * GRID_SPACING}px;
`;

export const RowQuestion = styled(BodyText)`
  font-weight: ${BASE_TYPOGRAPHY.FONT_WEIGHTS.SLIGHTLY_BOLD};
  && {
    // override default p margins
    margin: 0;
  }
`;

export const RowAnswer = styled(BodyText)`
  font-weight: ${BASE_TYPOGRAPHY.FONT_WEIGHTS.LIGHT};
  && {
    // override default p margins
    margin: 0;
  }
`;
