import "video.js/dist/video-js.css";

import type { Maybe } from "common/base/types/maybe";
import { isSome, nothing } from "common/base/types/maybe";
import type { DependencyList } from "react";
import React, { useEffect, useState } from "react";
import type { VideoJsPlayer } from "video.js";
import videojs from "video.js";

import { useGenerateSignedUrlMutation } from "../../gen/components";
import type { IFormControlValue } from "../forms/interfaces";

interface IVideoPlayerProps {
  options: videojs.PlayerOptions;
  onNewValue(newValue: Maybe<IFormControlValue>): void;
  urlKey: string;
}

// Refresh at 50 minutes, which is a short while before the presigned url expires at 60 minutes
const DEFAULT_REFRESH_MS = 3_000_000;

export const VideoPlayer: React.FC<IVideoPlayerProps> = props => {
  const [player, setPlayer] = useState<Maybe<videojs.Player>>(nothing);
  const [videoNode, setVideoNode] = useState<Maybe<HTMLVideoElement>>(nothing);
  const [generatedUrl, setGeneratedUrl] = useState<string>("");
  const [generateSignedUrl] = useGenerateSignedUrlMutation({
    variables: { input: { key: props.urlKey } },
  });

  const invokeGenerateSignedUrl = async () => {
    const url = (await generateSignedUrl())?.data?.generateSignedUrl?.url;
    if (isSome(url)) {
      setGeneratedUrl(url);
    }
  };

  useEffect(() => {
    if (!isSome(videoNode) || generatedUrl.length === 0) {
      return;
    }
    if (isSome(player)) {
      // Changing the source clears the time and stops the video, so this allows
      // us to set the current time to the previous time, as well as keep
      // playing.
      const curTime = player.currentTime();
      const paused = player.paused();
      player.src(generatedUrl);
      player.currentTime(curTime);
      if (!paused) {
        player.autoplay(true);
      }
    } else {
      const newPlayer = videojs(videoNode, props.options);
      newPlayer.on("ended", () => props.onNewValue(true));
      newPlayer.src(generatedUrl);
      setPlayer(newPlayer);
    }
    const timeout = setTimeout(invokeGenerateSignedUrl, DEFAULT_REFRESH_MS);
    return () => clearTimeout(timeout);
  }, [videoNode, generatedUrl]);

  useDisableSkippingForward(player);

  useLoseFocusHandler(() => player?.pause(), [player]);

  useEffect(() => {
    setTimeout(invokeGenerateSignedUrl);
    return () => player?.dispose();
  }, []);

  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856
  return (
    <div className="c-player">
      <div className="c-player__screen" data-vjs-player="true">
        <video
          ref={async (node: HTMLVideoElement) => setVideoNode(node)}
          className="video-js vjs-big-play-centered"
        />
      </div>
    </div>
  );
};

/**
 * Disable skipping forward in the video, but allow for revisit parts that the
 * user already watched
 */
function useDisableSkippingForward(player?: Maybe<VideoJsPlayer>) {
  useEffect(() => {
    if (!player) return;
    let latestTime = 0;
    const onSeek = () => {
      if (player.currentTime() > latestTime) {
        player.currentTime(latestTime);
      }
    };
    player.on("seeking", onSeek);
    player.on("seeked", onSeek);
    const interval = setInterval(() => {
      if (!player.paused()) {
        latestTime = Math.max(player.currentTime(), latestTime);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [player]);
}

function useLoseFocusHandler(
  onLoseFocus: () => void,
  deps?: Maybe<DependencyList>
) {
  useEffect(() => {
    const onVisibilityChange = () => {
      if (document.hidden) {
        onLoseFocus();
      }
    };
    document.addEventListener("visibilitychange", onVisibilityChange);
    window.addEventListener("blur", onLoseFocus);
    return () => {
      window.removeEventListener("blur", onLoseFocus);
      document.removeEventListener("visibilitychange", onVisibilityChange);
    };
  }, deps ?? undefined);
}
