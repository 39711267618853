import "./accepted-security-policy-detail.scss";

import { Button, H5, Spinner } from "@blueprintjs/core";
import { assertSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import { partition } from "lodash";
import moment from "moment";
import React from "react";
import { CSVLink } from "react-csv";

import { GRID_SPACING } from "../../../alpaca/base/grid";
import { LogError, LogErrorMessage } from "../../../errors";
import {
  TestOutcome,
  useFetchUserPoliciesQuery,
} from "../../../gen/components";
import { UI_DATE_FORMAT } from "../../../helpers/common";
import { DefaultLink } from "../../../helpers/links";
import { compareNullableDates } from "../../../helpers/user-sort-functions";
import { EvaluationIcon } from "../../helpers/EvaluationIcon";

interface IProps {
  userId: string;
}

export const AcceptedSecurityPolicyDetail: React.FC<IProps> = ({ userId }) => {
  const { error, loading, data } = useFetchUserPoliciesQuery({
    variables: { userId },
  });
  if (error) {
    LogError(error);
    return null;
  }
  if (loading) {
    return <Spinner />;
  }
  if (!data || !data.user) {
    LogErrorMessage("Bad fetch");
    return null;
  }

  const { user } = data;

  if (user.policyRequirements?.length === 0) {
    return (
      <div>
        There are no applicable security policies. Go to the{" "}
        <DefaultLink href="/policies">policies page</DefaultLink> to create and
        approve policies.
      </div>
    );
  }

  const [accepted, missing] = partition(
    user.policyRequirements ?? [],
    r => r.acceptance
  );
  function policyCompare(
    p1: typeof accepted[number],
    p2: typeof accepted[number]
  ) {
    const titleComparison = p1.policy.title.localeCompare(p2.policy.title);
    return titleComparison === 0
      ? compareNullableDates(p1.acceptance?.createdAt, p2.acceptance?.createdAt)
      : titleComparison;
  }
  accepted.sort(policyCompare);
  missing.sort(policyCompare);

  const historyData = accepted.map(r => {
    assertSome(r.acceptance);
    return {
      title: r.policy.title,
      acceptanceDate: moment(r.acceptance.createdAt).format(),
    };
  });

  return (
    <div>
      {accepted.length > 0 ? <H5>Accepted policies</H5> : null}
      {accepted.map(r => {
        assertSome(r.acceptance);
        return (
          <div key={r.acceptance.id} className="spd-list-item">
            <EvaluationIcon
              className="security-policy-detail-icon"
              evaluation={TestOutcome.PASS}
            />
            <div>
              {r.policy.title}
              {" • "}
              <span className="text-muted font-small">
                {moment(r.acceptance.createdAt).format(UI_DATE_FORMAT)}
              </span>
            </div>
          </div>
        );
      })}
      {missing.length > 0 ? (
        <H5
          className={accepted.length > 0 ? "security-policy-detail-padded" : ""}
        >
          Policies not yet accepted
        </H5>
      ) : null}
      {missing.map(r => (
        <div key={r.policy.id} className="spd-list-item">
          <EvaluationIcon
            className="security-policy-detail-icon"
            evaluation={TestOutcome.FAIL}
          />
          <div>{r.policy.title}</div>
        </div>
      ))}
      <CSVLink
        data={historyData}
        filename={`Policy acceptance history - ${user.displayName}.csv`}
        target="_blank"
      >
        <Button style={{ marginTop: `${2 * GRID_SPACING}px` }}>
          Export full acceptance history as .csv
        </Button>
      </CSVLink>
    </div>
  );
};

gql`
  query fetchUserPolicies($userId: ID!) {
    user(id: $userId) {
      id
      displayName
      policyRequirements {
        acceptance {
          id
          createdAt
        }
        policy {
          id
          title
        }
      }
    }
  }
`;
