import "./question-schema-form.scss";

import { Button, Intent } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { isSome, nothing } from "common/base/types/maybe";
import deepequal from "fast-deep-equal";
import { debounce } from "lodash";
import React from "react";
import validator from "validator";

import type { DomainInfoFieldsFragment } from "../../gen/components";
import { AppToaster } from "../../helpers/toaster";
import type {
  IFormControlValue,
  IQuestion,
  IQuestionSchema,
  ISelectedFile,
} from "./interfaces";
import { SchemaFormControl } from "./schema-form-control";

const CURRENTLY_UPLOADING = "CURRENTLY_UPLOADING";

type PriorInfoTypes =
  | DomainInfoFieldsFragment["productDescriptionInfo"]
  | DomainInfoFieldsFragment["businessInfo"]
  | {
      screenshot: {
        filename: string;
        url: string;
        createdAt: string;
      };
    }
  | {
      [k: string]: any;
    };

export interface IQuestionSchemaFormProps {
  disabled?: Maybe<boolean>;
  availableFeatures?: Maybe<Set<string>>;
  priorInfo?: Maybe<PriorInfoTypes>;
  questionSchema: IQuestionSchema;
  hideSubmitButton?: Maybe<boolean>;
  isInFlight?: Maybe<boolean>;
  submitTextOverride?: Maybe<string>;
  nonStickySubmit?: Maybe<boolean>;
  readonly?: Maybe<true>;
  requireQuestionCompletion?: Maybe<boolean>;
  /**
   * If true, input form is validated while it gets filled out. (Validation happens after a short debounce.)
   */
  shouldValidateOnInteraction?: Maybe<true>;
  onSubmit(answers: string, files: { [k: string]: File }): void;
  onChange?(answers: { [k: string]: any }, files: { [k: string]: File }): void;
  /**
   * Triggers when form is in a valid state after an interaction. `shouldValidateOnInteraction` must be set
   */
  onValidInteraction?(
    answers: { [k: string]: any },
    files: { [k: string]: File }
  ): void;
}

interface IState {
  files: { [k: string]: File };
  isPaymentFormInFlight: boolean;
  submittedInfo: { [k: string]: any };
  validationErrors: { [k: string]: string };
  completedRequiredQuestions: { [k: string]: boolean };
}

function IsFilledOut(value: any) {
  return (
    isSome(value) &&
    value !== false &&
    (typeof value !== "string" ||
      (value.trim() !== "" && value !== "internal-unselected"))
  );
}

export class QuestionSchemaForm extends React.Component<
  IQuestionSchemaFormProps,
  IState
> {
  public constructor(props: IQuestionSchemaFormProps) {
    super(props);

    this.state = {
      files: {},
      isPaymentFormInFlight: false,
      submittedInfo: isSome(props.priorInfo) ? { ...props.priorInfo } : {},
      validationErrors: {},
      completedRequiredQuestions: {},
    };

    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.hasValidData = this.hasValidData.bind(this);
    this.isAnAttachedFile = this.isAnAttachedFile.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.renderQuestionType = this.renderQuestionType.bind(this);
    this.questionIsVisible = this.questionIsVisible.bind(this);
    this.setFilesToUploading = this.setFilesToUploading.bind(this);
    this.submit = this.submit.bind(this);
    this.updateStateOnChange = this.updateStateOnChange.bind(this);
    this.validateOnInteraction = debounce(
      this.validateOnInteraction.bind(this),
      500 // Recommended by https://ux.stackexchange.com/a/110444
    );
  }

  public componentDidUpdate(
    prevProps: IQuestionSchemaFormProps,
    prevState: IState
  ) {
    if (!deepequal(this.props.priorInfo, prevProps.priorInfo)) {
      this.setState({
        submittedInfo: isSome(this.props.priorInfo)
          ? { ...this.props.priorInfo }
          : {},
      });
    }
  }

  public render() {
    const { availableFeatures } = this.props;
    const questionIsAvailable = (question: IQuestion) => {
      if (!isSome(question.feature)) {
        return true;
      }
      return (
        isSome(availableFeatures) && availableFeatures.has(question.feature)
      );
    };
    let submissionDisabled = isSome(this.props.disabled)
      ? this.props.disabled
      : false;
    const questions: Array<Maybe<JSX.Element>> = [];
    this.props.questionSchema.questions
      .filter(questionIsAvailable)
      .forEach(question => {
        if (
          this.props.requireQuestionCompletion === true &&
          question.required &&
          this.state.completedRequiredQuestions[question.name] !== true
        ) {
          submissionDisabled = true;
        }

        questions.push(this.renderQuestionType(question));
      });

    const submitText =
      this.props.submitTextOverride ??
      this.props.questionSchema.submitText ??
      "Submit";

    const maybeButton =
      this.props.readonly ||
      this.props.hideSubmitButton ||
      (this.props.questionSchema.questions.length === 1 &&
        this.props.questionSchema.questions[0].type === "PaymentInput") ? (
        nothing
      ) : (
        <div
          className={`business-info-submit ${
            this.props.nonStickySubmit ?? false ? "" : "bis-sticky"
          }`}
        >
          <Button
            disabled={submissionDisabled ?? undefined}
            loading={this.props.isInFlight ?? undefined}
            intent={Intent.PRIMARY}
            onClick={this.onSubmit}
            text={submitText}
            type="submit"
          />
        </div>
      );

    const form = (
      <div style={{ marginBottom: 24 }}>
        {questions}
        {maybeButton}
      </div>
    );

    if (this.props.readonly) {
      return <div className="business-info-form-read-only-overlay">{form}</div>;
    }

    return form;
  }

  public submit() {
    this.doSubmit();
  }

  private doSubmit() {
    const errors = this.validateForm();
    this.setState({
      validationErrors: errors,
    });

    if (Object.keys(errors).length === 0) {
      this.props.onSubmit(
        JSON.stringify(this.state.submittedInfo),
        this.state.files
      );
      this.setFilesToUploading();
    } else {
      AppToaster.show({
        message: "Please fix the fields highlighted in red",
        intent: Intent.DANGER,
      });
    }
  }

  private setFilesToUploading() {
    const prior = this.state.submittedInfo;
    const uploadingFiles: { [k: string]: string } = {};
    const filenames = Object.keys(this.state.files);
    filenames.forEach(filename => {
      uploadingFiles[filename] = CURRENTLY_UPLOADING;
    });

    this.setState({
      files: {},
      submittedInfo: {
        ...prior,
        ...uploadingFiles,
      },
    });
  }

  private handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (!event.target.files || !event.target.validity.valid) {
      const newFiles = { ...this.state.files };
      delete newFiles[event.target.name];

      this.setState({
        files: newFiles,
      });
      this.updateStateOnChange(event.target.name, undefined);
    } else if (event.target.files.length > 0) {
      this.setState({
        files: {
          ...this.state.files,
          [event.target.name]: event.target.files[0],
        },
      });
      this.updateStateOnChange(event.target.name, event.target.files[0]);
    }
  }

  private handleFileUpload(name: string, file: Maybe<File>) {
    const newFiles = { ...this.state.files };
    if (!isSome(file)) {
      delete newFiles[name];
    } else {
      newFiles[name] = file;
    }

    this.setState({
      files: newFiles,
    });
    if (isSome(this.props.onChange)) {
      this.props.onChange(this.state.submittedInfo, newFiles);
    }
  }

  private isAnAttachedFile(filename: string) {
    return filename in this.state.files;
  }

  private onSubmit(event: { preventDefault: () => void }) {
    event.preventDefault();
    this.doSubmit();
  }

  private questionIsVisible(question: IQuestion) {
    if (isSome(question.visibleConditions)) {
      for (const condition of question.visibleConditions) {
        // If dependency is not visible, then this is not visible
        const dependency = this.props.questionSchema.questions.find(
          q => q.name === condition.name
        );
        if (dependency && !this.questionIsVisible(dependency)) {
          return false;
        }
        const fieldToCheck =
          condition.name in this.state.files
            ? this.state.files[condition.name]
            : this.state.submittedInfo[condition.name];
        if (isSome(condition.visibleValues)) {
          if (!condition.visibleValues.includes(fieldToCheck)) {
            return false;
          }
        } else if (
          !isSome(fieldToCheck) ||
          fieldToCheck === false ||
          fieldToCheck === "" ||
          fieldToCheck === "internal-unselected"
        ) {
          return Boolean(condition.visibleWhenNotSet);
        }
        return !Boolean(condition.visibleWhenNotSet);
      }
    }
    return true;
  }

  private renderQuestionType(question: IQuestion) {
    if (!this.questionIsVisible(question)) {
      return nothing;
    }

    let currentValue = this.state.submittedInfo[question.name];
    if (question.name in this.state.files) {
      currentValue = this.state.files[question.name];
    }

    const hasError = Boolean(this.state.validationErrors[question.name]);
    const formIntent = hasError ? Intent.DANGER : Intent.NONE;
    const errOrEmpty = hasError ? (
      <div className="bp3-form-helper-text">
        {this.state.validationErrors[question.name]}
      </div>
    ) : (
      nothing
    );

    return (
      <SchemaFormControl
        key={question.id}
        intent={formIntent}
        question={question}
        readOnly={this.props.readonly}
        currentValue={currentValue as IFormControlValue}
        onNewValue={newValue =>
          this.updateStateOnChange(question.name, newValue)
        }
      >
        {errOrEmpty}
      </SchemaFormControl>
    );
  }

  private updateStateOnChange(
    name: string,
    value: Maybe<string | string[] | boolean | Date | File | ISelectedFile>
  ) {
    if (
      isSome(value) &&
      typeof value === "object" &&
      "name" in value &&
      !("lastModified" in value)
    ) {
      this.handleFileUpload(value.name, value.file);
      return;
    }

    const completedRequiredQuestions: { [k: string]: boolean } = {};
    if (
      this.props.requireQuestionCompletion === true &&
      typeof value === "boolean"
    ) {
      completedRequiredQuestions[name] = value;
    }

    const prior = this.state.submittedInfo;
    const submittedInfo = { ...prior, [name]: value };

    this.setState({
      submittedInfo,
      completedRequiredQuestions,
    });

    if (isSome(this.props.onChange)) {
      this.props.onChange(submittedInfo, this.state.files);
    }

    if (this.props.shouldValidateOnInteraction) {
      this.validateOnInteraction();
    }
  }

  private validateOnInteraction() {
    const errors = this.validateForm();
    this.setState({ validationErrors: errors });
    if (Object.keys(errors).length === 0) {
      this.props.onValidInteraction?.(
        this.state.submittedInfo,
        this.state.files
      );
    }
  }

  private validateForm(): { [k: string]: string } {
    const errors: { [k: string]: string } = {};
    this.props.questionSchema.questions.forEach(q => {
      if (
        q.required &&
        this.questionIsVisible(q) &&
        !this.hasValidData(q.name)
      ) {
        errors[q.name] = "This field is required";
      } else if (q.type === "URLInput") {
        const maybeURL = this.state.submittedInfo[q.name];
        if (
          typeof maybeURL === "string" &&
          maybeURL !== "" &&
          !validator.isURL(maybeURL)
        ) {
          errors[q.name] = "Invalid url";
        }
      }
    });

    if (isSome(this.props.questionSchema.requiredOr)) {
      this.props.questionSchema.requiredOr.forEach(requirement => {
        const { questions, message } = requirement;
        if (questions.every(question => !this.hasValidData(question))) {
          questions.forEach(question => (errors[question] = message));
        }
      });
    }

    this.props.questionSchema.questions.forEach(q => {
      if (isSome(q.dependency) && this.hasValidData(q.name)) {
        const excludeValue =
          typeof q.dependency !== "string" && "excludeValue" in q.dependency
            ? q.dependency.excludeValue
            : undefined;

        if (this.state.submittedInfo[q.name] === excludeValue) {
          return;
        }

        const extractedDep =
          typeof q.dependency === "string"
            ? q.dependency
            : "to" in q.dependency
            ? q.dependency.to
            : q.dependency;

        const deps =
          typeof extractedDep === "string" ? [extractedDep] : extractedDep;
        deps.forEach(dep => {
          if (!this.hasValidData(dep)) {
            errors[dep] = "This field is required";
          }
        });
      }
    });

    return errors;
  }

  private hasValidData(element: string): boolean {
    return (
      IsFilledOut(this.state.submittedInfo[element]) ||
      this.isAnAttachedFile(element)
    );
  }
}
