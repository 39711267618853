import "./email-subscription.scss";

import { Checkbox, Spinner } from "@blueprintjs/core";
import gql from "graphql-tag";
import React from "react";
import styled from "styled-components";

import { LogError } from "../../../errors";
import type { UserEmailSubscriptionSettingQuery } from "../../../gen/components";
import { useSetEmailSubscriptionPreferenceMutation } from "../../../gen/components";

interface IProps {
  setting: NonNullable<
    NonNullable<
      UserEmailSubscriptionSettingQuery["userEmailSubscriptionSettings"]
    >[0]
  >;
}

const StyledDescriptionSpan = styled.span`
  color: #738694;
  font-size: 14px;
`;

const SensitiveEmailStyledNameSpan = styled.span`
  color: #fa755a;
`;

export const EmailSubscriptionBox: React.FC<IProps> = props => {
  const [mutationInFlight, setMutationInFlight] = React.useState(false);
  const [setEmailSubscription] = useSetEmailSubscriptionPreferenceMutation();
  const onCheckboxChange = async (evt: React.FormEvent<HTMLInputElement>) => {
    const shouldSend = evt.currentTarget.checked;
    setMutationInFlight(true);
    await setEmailSubscription({
      variables: {
        emailKey: props.setting.emailKey,
        shouldSend,
      },
    }).catch(LogError);
    setMutationInFlight(false);
  };

  let nameSpan;
  if (props.setting.sensitive) {
    nameSpan = (
      <SensitiveEmailStyledNameSpan>
        {props.setting.friendlyName}
      </SensitiveEmailStyledNameSpan>
    );
  } else {
    nameSpan = <span>{props.setting.friendlyName}</span>;
  }
  const descriptionSpan = (
    <StyledDescriptionSpan>{props.setting.description}</StyledDescriptionSpan>
  );

  const label = (
    <span>
      {nameSpan}
      <br></br>
      {descriptionSpan}
    </span>
  );

  const elem = !mutationInFlight ? (
    <Checkbox
      checked={props.setting.shouldSend}
      labelElement={label}
      onChange={onCheckboxChange}
    />
  ) : (
    <div>
      <Spinner className="email-subscription-spinner" />
      <span className="email-subscription-label">
        {props.setting.friendlyName}
      </span>
    </div>
  );
  return elem;
};

gql`
  mutation setEmailSubscriptionPreference(
    $emailKey: emailKey!
    $shouldSend: Boolean!
  ) {
    setUserEmailSubscriptionSetting(
      emailKey: $emailKey
      shouldSend: $shouldSend
    ) {
      id
      emailKey
      shouldSend
    }
  }
`;
