import { Spinner } from "@blueprintjs/core";
import type { HR_SERVICES } from "common/base/types/helpers";
import { HR_SERVICES_SET } from "common/base/types/helpers";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import qs from "qs";
import React, { useState } from "react";

import { LogError } from "../../../../errors";
import { useGetSingleCredentialQuery } from "../../../../gen/components";
import { CancelConfirmDialog } from "../../../helpers/CancelConfirmDialog";
import { HerokuAppsSelector } from "../heroku/heroku-apps-selector";
import { ConfigureHrDateFilter } from "./configure-hr-date-filter";
import { LinkOtherDigitalOcean } from "./link-other-digitalocean";
import { OrgSelect } from "./organization-selector";

interface IProps {
  service: string;
  onClose: () => void;
}

export const PostAuthForm: React.FunctionComponent<IProps> = ({
  service,
  onClose,
}) => {
  const { loading, error, data } = useGetSingleCredentialQuery({
    variables: { service },
    skip: !shouldShowPostAuthForm(service),
  });
  const [showRipplingRedirect, setShowRipplingRedirect] = useState(false);
  if (!shouldShowPostAuthForm(service)) {
    return null;
  }
  if (error) {
    LogError(error);
    return null;
  }
  if (loading) {
    return <Spinner />;
  }

  // A domain might not have credentials for the requisite service
  if (!data || data.organization.credentials.length === 0) {
    if (isSome(onClose)) {
      onClose();
    }
    return null;
  }

  const cred = data.organization.credentials[0];

  // these are organizations in the linked service, not in Vanta
  const parsedOrgs: {
    organization: string;
    organizations: string[];
  } = isSome(cred.metadata)
    ? JSON.parse(cred.metadata)
    : {
        organizations: [],
        organization: "",
      };

  // Rippling is special in that we need to show a 2nd post-auth dialog
  if (service === "rippling" && showRipplingRedirect) {
    // If there's a redirect URL, then an extra dialog is shown after the existing post auth step.
    const query = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });
    const redirectUri = query.redirect_uri;
    if (isSome(redirectUri) && redirectUri !== "") {
      return (
        <CancelConfirmDialog
          title="Redirect to Rippling"
          confirmText="Stay in Vanta"
          cancelText="Redirect to Rippling"
          // these are swapped because we want the user to stay inside
          // Vanta in most cases.
          onClose={() => window.location.replace(redirectUri)}
          onConfirm={isSome(onClose) ? onClose : () => {}}
          isOpen={true}
          body="You are about to be redirected to Rippling."
        />
      );
    } else {
      onClose();
    }
  }

  return getPostAuthFormForService();

  function getPostAuthFormForService() {
    if (HR_SERVICES_SET.has(service)) {
      const parsedEndDateCutoff = isSome(cred.metadata)
        ? JSON.parse(cred.metadata).endDateCutoff
        : null;
      const initialLinking =
        !isSome(cred.metadata) ||
        !("endDateCutoff" in JSON.parse(cred.metadata));
      return (
        <ConfigureHrDateFilter
          endDateCutoff={
            isSome(parsedEndDateCutoff) ? new Date(parsedEndDateCutoff) : null
          }
          initialLinking={initialLinking}
          isOpen={true}
          onClose={
            service === "rippling"
              ? () => setShowRipplingRedirect(true)
              : onClose
          }
          service={service as typeof HR_SERVICES[number]}
        />
      );
    } else if (service === "heroku") {
      return <HerokuAppsSelector onClose={onClose} />;
    } else if (
      service === "trello" ||
      service === "bitbucket" ||
      service === "gitlab" ||
      service === "azuredevops"
    ) {
      return (
        <OrgSelect
          selectedOrg={parsedOrgs.organization}
          organizations={parsedOrgs.organizations}
          onClose={onClose}
          service={service}
        />
      );
    } else if (service === "digitalocean" || service === "digitaloceanspaces") {
      return <LinkOtherDigitalOcean onClose={onClose} service={service} />;
    }

    LogError(
      new Error("No post auth form for service. Shouldn't ever get here.")
    );
    return null;
  }
};

const SERVICE_HAS_POSTAUTH_FORM: { [k: string]: boolean } = {
  trello: true,
  heroku: true,
  bitbucket: true,
  gitlab: true,
  azuredevops: true,
  digitalocean: true,
  digitaloceanspaces: true,
};

export const shouldShowPostAuthForm = (service: Maybe<string>): boolean => {
  if (!isSome(service)) {
    return false;
  }

  if (HR_SERVICES_SET.has(service)) {
    return true;
  }

  return Boolean(SERVICE_HAS_POSTAUTH_FORM[service]);
};

gql`
  query GetSingleCredential($service: String!) {
    organization {
      id
      credentials(services: [$service], includeIncompleteMetadata: true) {
        id
        metadata
      }
    }
  }
`;
