import styled from "styled-components";

import { GRID_SPACING } from "../../../../alpaca/base/grid";
import { NotApplicable } from "../../people/shared/not-applicable";
import { TagList } from "../shared/tag-list";

export const FILTER_BUTTON_WIDTH = 24 * GRID_SPACING;

export const StyledRoleTagList = styled(TagList).attrs({
  numTagsBeforeOverflow: 2,
})``;

export const TableEmptyDefault = "No accounts found";

export const InfoNotAvailable = styled(NotApplicable).attrs({
  tooltipContent: "This information is not available for this account",
})``;

export const AccountDeactivated = styled(NotApplicable).attrs({
  tooltipContent: "This account is inactive",
})``;
