import React, { useContext } from "react";

interface IContext {
  linkedSatServices: Set<string>;
}

export const SatServicesContext = React.createContext<IContext>({
  linkedSatServices: new Set(),
});

export function useIsExternalSatLinkedCheck(): boolean {
  const context = useContext(SatServicesContext);
  return context.linkedSatServices.size > 0;
}
