import React from "react";

import {
  PageHeadingInfo,
  VantaDashboardPage,
} from "../../vanta-chrome/page-content/vanta-dashboard-page";
import { AccessManagement } from "../business-info/access-management";

export const VantaUsersPage: React.FC = () => (
  <VantaDashboardPage headingInfo={PageHeadingInfo.VANTA_USERS}>
    <AccessManagement />
  </VantaDashboardPage>
);
