import { Button, Menu, MenuItem } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import type { Maybe } from "common/base/types/gen";
import { BINARY_MIME_TYPES } from "common/base/types/helpers";
import { isSome } from "common/base/types/maybe";
import type { PolicyType } from "common/constants/policyInfoCommon";
import { PolicyTypes } from "common/constants/policyInfoCommon";
import React from "react";
import styled from "styled-components";

import { VANTA_COLORS } from "../../../../alpaca/base/colors";
import { GRID_SPACING } from "../../../../alpaca/base/grid";
import { BodyText, HelperText } from "../../../../alpaca/components";
import { downloadStaticUrl } from "../../../../helpers/download-static-url";
import { FileUploadCard } from "../../components/file-upload-card";
import type { PolicyWizardError } from "../beta-policies-wizard-page";
import { PoliciesH5 } from "../policies-v2-styles";
import { POLICY_TEMPLATES } from "../policy-templates";

export const PoliciesWizardUploadStep: React.FC<{
  errors?: Maybe<PolicyWizardError[]>;
  onFileUploaded: (name: string, file?: Maybe<File>) => void;
  policyName: string;
  selectedFile?: Maybe<File>;
  policyType: string;
}> = ({ policyName, policyType, selectedFile, onFileUploaded, errors }) => {
  // PolicyTypes represents all built-in policy types with templates
  const template = PolicyTypes.includes(policyType as PolicyType)
    ? POLICY_TEMPLATES[policyType as PolicyType]
    : null;
  const {
    DOCX: docxURL,
    XLSX: xlsxURL,
    GDOC: gdocURL,
    MD: mdURL,
  } = template ?? { DOCX: null, XLSX: null, GDOC: null, MD: null };
  return (
    <div>
      <div>
        <PoliciesH5>Upload your policy</PoliciesH5>
        <BodyText>
          When you're ready, upload your {policyName} to get started.
        </BodyText>
        <FileUploadCard
          allowedMimeTypes={[BINARY_MIME_TYPES.PDF]}
          file={selectedFile}
          onFileSelected={file => onFileUploaded(file?.name ?? "", file)}
        />
        <HelperText color={VANTA_COLORS.VANTA_ALERT}>
          {errors?.[0]?.message}
        </HelperText>
      </div>
      {isSome(template) ? (
        <StyledTemplateDownloadContainer>
          <PoliciesH5>Use an Editable Template</PoliciesH5>
          <BodyText>
            If you don't already have a policy, download one of our
            compliance-ready editable templates.
          </BodyText>
          <Popover2
            placement="bottom"
            content={
              <Menu>
                {/* open Gdoc in new window since it's a GDrive link */}
                {isSome(gdocURL) && (
                  <MenuItem
                    text="Google Doc"
                    href={gdocURL}
                    target={"_blank"}
                  />
                )}
                {isSome(docxURL) && (
                  <MenuItem
                    text="Microsoft Word (.docx)"
                    href={docxURL}
                    onClick={async (
                      e: React.MouseEvent<HTMLElement, MouseEvent>
                    ) => {
                      e.preventDefault();
                      await downloadStaticUrl(docxURL, `${policyName}.docx`);
                    }}
                  />
                )}
                {isSome(xlsxURL) && (
                  <MenuItem
                    text="Microsoft Excel (.xlsx)"
                    href={xlsxURL}
                    onClick={async (
                      e: React.MouseEvent<HTMLElement, MouseEvent>
                    ) => {
                      e.preventDefault();
                      await downloadStaticUrl(xlsxURL, `${policyName}.xlsx`);
                    }}
                  />
                )}
                {isSome(mdURL) && (
                  <MenuItem
                    text="Markdown (.md)"
                    href={mdURL}
                    onClick={async (
                      e: React.MouseEvent<HTMLElement, MouseEvent>
                    ) => {
                      e.preventDefault();
                      await downloadStaticUrl(mdURL, `${policyName}.md`);
                    }}
                  />
                )}
              </Menu>
            }
          >
            <Button> Download Template </Button>
          </Popover2>
        </StyledTemplateDownloadContainer>
      ) : null}
    </div>
  );
};

const StyledTemplateDownloadContainer = styled.div`
  margin-top: ${4 * GRID_SPACING}px;
`;
