import type { IDrawerProps } from "@blueprintjs/core";
import { Drawer } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import classnames from "classnames";
import React from "react";
import styled, { createGlobalStyle } from "styled-components";

import { BASE_PALETTE } from "../../../alpaca/base/colors";
import { GRID_SPACING } from "../../../alpaca/base/grid";
import { Button } from "../../../alpaca/components";

// This class helps disable pointer events in the blueprint portal so that
// anything outside of the drawer is still clickable.
const DISABLE_POINTER_EVENTS_CLASSNAME = "disable-pointer-events";
const DisablePointerEventsStyle = createGlobalStyle`
  .${DISABLE_POINTER_EVENTS_CLASSNAME} {
    pointer-events: none;
  }
`;

const Styles = {
  WIDTH: 520,
};

export const VantaDrawer: React.FC<IDrawerProps> = ({ children, ...props }) => (
  <>
    <DisablePointerEventsStyle />
    <StyledDrawer
      {...props}
      size={props.size ?? Styles.WIDTH}
      portalClassName={classnames(
        props.portalClassName,
        DISABLE_POINTER_EVENTS_CLASSNAME
      )}
    >
      <CloseButton onClick={props.onClose} icon={IconNames.CROSS} minimal />
      {children}
    </StyledDrawer>
  </>
);

const StyledDrawer = styled(Drawer)`
  border: 0.2px solid ${BASE_PALETTE.FOG};
  box-shadow: 0px 2px 6px rgba(145, 150, 158, 0.3);
  border-radius: 4px 0px 0px 4px;

  // Re-enable pointer events within the drawer, as they're disabled
  // within the portal rendering this drawer.
  pointer-events: all;
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: ${GRID_SPACING}px;
  right: ${GRID_SPACING}px;
  z-index: 1;
`;
