import type { Maybe, Nothing } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import type { ReactElement } from "react";
import React from "react";
import styled from "styled-components";

import { TestOutcome } from "../../../gen/components";
import { EvaluationIcon } from "../../helpers/EvaluationIcon";

export const booleanToEvaluationIcon = (bool: Maybe<boolean>) =>
  isSome(bool) ? (
    bool ? (
      <EvaluationIcon evaluation={TestOutcome.PASS} />
    ) : (
      <EvaluationIcon evaluation={TestOutcome.FAIL} />
    )
  ) : (
    "N/A"
  );

export const enabledValueToEvaluationIcon = (str: Maybe<string>) =>
  isSome(str) ? (
    str === "ENABLED" ? (
      <EvaluationIcon evaluation={TestOutcome.PASS} />
    ) : (
      <EvaluationIcon evaluation={TestOutcome.FAIL} />
    )
  ) : (
    "NA"
  );

export type TableParameters = {
  displayName: string;
  columnOrder: string[];
  header: { [k: string]: string };
  createRow: (data: any) => {
    [k: string]: Nothing | number | string | ReactElement;
  };
};

export const Container = styled.div`
  padding: 10px;
`;
