import { Classes } from "@blueprintjs/core";
import { dropNothing, isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import React from "react";

import { LogError } from "../../../errors";
import { useHrUserTestEntitiesQuery } from "../../../gen/components";
import { FailDataRow } from "../fail-data-row";

export const HrUserTestEntities: React.FC<{
  entityIds: string[];
  testId: string;
  first: number;
}> = ({ entityIds, testId, first }) => {
  const { data, error, loading } = useHrUserTestEntitiesQuery();
  if (loading) {
    return <div />;
  }
  if (error) {
    LogError(error);
    return <div />;
  }
  if (!data?.organization) {
    return <div />;
  }
  const ids = new Set(entityIds);
  return (
    <>
      {dropNothing(data.organization.unlinkedHrUsers)
        .filter(hrUser => isSome(hrUser.id) && ids.has(hrUser.id))
        .slice(0, first)
        .map(hrUser => (
          <FailDataRow
            key={hrUser.id}
            entityId={hrUser.id}
            entityType={"HrUser"}
            testId={testId}
            allowWhitelisting={true}
          >
            <div className="fail-data-user-row">
              <div className="name-email-display">
                <div>{hrUser.displayName}</div>
                <div className={`${Classes.TEXT_MUTED}`}>
                  <small>{hrUser.email}</small>
                </div>
              </div>
            </div>
          </FailDataRow>
        ))}
    </>
  );
};

gql`
  query HrUserTestEntities {
    organization {
      id
      unlinkedHrUsers {
        id
        displayName
        email
      }
    }
  }
`;
