import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import React from "react";

import { LogError } from "../../../errors";
import { useFetchChangesQuery } from "../../../gen/components";
import { FullPageSpinner } from "../../helpers/FullPageSpinner";
import { ChangeTable } from "./change-table";

interface IProps {
  month: number;
  year: number;
  repo: {
    name: string;
    defaultBranch: string;
    service: string;
    uniqueId: string;
  };
}

export const ChangeTableByMonth: React.FC<IProps> = ({ month, year, repo }) => {
  const { loading, error, data, fetchMore } = useFetchChangesQuery({
    variables: {
      monthAndYear: { month, year },
      repoName: repo.name,
      repoId: repo.uniqueId,
      branch: repo.defaultBranch,
      service: repo.service,
      pageUrl: null,
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });
  if (loading) {
    return <FullPageSpinner />;
  }
  if (error) {
    LogError(error);
    return <div>An error occurred.</div>;
  }
  if (!data) {
    LogError(new Error("bad fetch for fetchDomainChangesForMonth"));
    return <div>Could not fetch data.</div>;
  }
  if (!isSome(data.changesForRepoInMonth)) {
    return <div>No data found for that repo in that month</div>;
  }

  return (
    <>
      <div>
        Showing {repo.service === "gitlab" ? "merge" : "pull"} requests merged
        to default branch <code>{repo.defaultBranch}</code>
      </div>
      <ChangeTable
        service={repo.service}
        changes={data.changesForRepoInMonth.changeList}
        nextPageUrl={data.changesForRepoInMonth.nextPageUrl}
        onLoadMore={async () =>
          fetchMore({
            variables: {
              pageUrl: data.changesForRepoInMonth.nextPageUrl,
            },
            updateQuery: (prev: any, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prev;
              return {
                changesForRepoInMonth: {
                  ...fetchMoreResult.changesForRepoInMonth,
                  changeList: [
                    ...prev.changesForRepoInMonth.changeList,
                    ...fetchMoreResult.changesForRepoInMonth.changeList,
                  ],
                },
              };
            },
          })
        }
      />
    </>
  );
};

gql`
  query fetchChanges(
    $monthAndYear: monthAndYear!
    $repoName: String!
    $repoId: String!
    $branch: String!
    $service: String!
    $pageUrl: String
  ) {
    changesForRepoInMonth(
      monthAndYear: $monthAndYear
      repoName: $repoName
      repoId: $repoId
      branch: $branch
      pageUrl: $pageUrl
      service: $service
    ) {
      changeList {
        id
        authorAccountId
        body
        createdAt
        closedAt
        reviewerAccountIds
        reviewBodies
        repo
        title
      }
      nextPageUrl
    }
  }
`;
