import type { ApolloError } from "@apollo/client";
import { isSome } from "common/base/types/maybe";

export function apolloErrorHasErrorCode(
  code: string,
  err: ApolloError
): boolean {
  return isSome(
    err.graphQLErrors.find(x => x.extensions && x.extensions.code === code)
  );
}
