import { Classes, Spinner } from "@blueprintjs/core";
import { isSome, nothing } from "common/base/types/maybe";
import gql from "graphql-tag";
import React, { useState } from "react";

import { LogError } from "../../errors";
import type { GetVaqAnswersQuery } from "../../gen/components";
import {
  GetVaqAnswersDocument,
  GetVaqAnswerStateDocument,
  useSetVaqSchemaAnswersMutation,
  useSubmitVendorAssessmentMutation,
} from "../../gen/components";
import { QuestionSchemaForm } from "../forms/question-schema-form";
import { getSchemaWithVendorAndCompanyNames } from "./schemas/vaq-state-helpers";
import { setVAQSubmissionSessionStorage } from "./vaq-submit-form";

interface IProps {
  vaq: NonNullable<GetVaqAnswersQuery["vaqAnswers"]>;
  gotoNextForm(): void;
}

export const VAQInitialForm: React.FC<IProps> = props => {
  const { vaq } = props;
  const [submitButtonPressed, setSubmitButtonPressed] = useState(false);
  const [hasAssessmentFile, setHasAssessmentFile] = useState(false);
  const [submitVendorAssessment, submitResult] =
    useSubmitVendorAssessmentMutation({
      refetchQueries: [
        {
          query: GetVaqAnswerStateDocument,
          variables: { uuid: vaq.uuid },
        },
      ],
    });
  const [setVaqSchema, setSchemaResult] = useSetVaqSchemaAnswersMutation({
    refetchQueries: [
      {
        query: GetVaqAnswersDocument,
        variables: { uuid: vaq.uuid },
      },
    ],
  });

  if (submitButtonPressed) {
    return <Spinner />;
  }

  const loading = submitResult.loading || setSchemaResult.loading;

  const titleToFiles: {
    [k: string]: NonNullable<GetVaqAnswersQuery["vaqAnswers"]>["files"][number];
  } = {};
  vaq.files.forEach(file => {
    if (isSome(file.title)) {
      titleToFiles[file.title] = file;
    }
  });

  const priorInfoJSON = JSON.parse(vaq.answersJSON).vendorBasicInformation;
  return (
    <React.Fragment>
      {renderIntroductionHeading()}
      <div className="vaq-form">
        <QuestionSchemaForm
          disabled={loading}
          nonStickySubmit={true}
          questionSchema={getSchemaWithVendorAndCompanyNames(
            "vendorBasicInformation",
            vaq.domainDisplayName ?? "DOMAIN",
            vaq.vendorLegalName ?? "VENDOR"
          )}
          priorInfo={
            isSome(priorInfoJSON)
              ? {
                  ...JSON.parse(priorInfoJSON),
                  ...titleToFiles,
                }
              : nothing
          }
          submitTextOverride={
            hasAssessmentFile
              ? "Upload assessment and complete form"
              : "Continue"
          }
          onSubmit={(answers, files) => {
            const fileFieldList = Object.keys(files).map(f => {
              return {
                fieldName: f,
                file: files[f],
              };
            });
            if (hasAssessmentFile && fileFieldList.length > 0) {
              submitVendorAssessment({
                variables: {
                  uuid: vaq.uuid,
                  file: fileFieldList[0],
                  assessmentType: JSON.parse(answers).assessmentSelect,
                },
              }).catch(LogError);
              setVAQSubmissionSessionStorage(vaq);
              setSubmitButtonPressed(true);
            } else if (
              !isSome(priorInfoJSON) ||
              answersHaveChanged(priorInfoJSON, answers)
            ) {
              setVaqSchema({
                variables: {
                  uuid: vaq.uuid,
                  schemaId: "vendorBasicInformation",
                  answers,
                },
              }).catch(LogError);
              setSubmitButtonPressed(true);
            } else {
              props.gotoNextForm();
            }
          }}
          onChange={(answers, files) => {
            const submitCheckboxIsChecked = Boolean(answers.assessmentCheckbox);
            const hasFile = Object.keys(files).length > 0;
            if (!hasAssessmentFile && submitCheckboxIsChecked && hasFile) {
              setHasAssessmentFile(true);
            } else if (hasAssessmentFile) {
              setHasAssessmentFile(false);
            }
          }}
        />
      </div>
    </React.Fragment>
  );

  function renderIntroductionHeading() {
    return (
      <div className={Classes.RUNNING_TEXT}>
        <p>
          {vaq.requesterName} at {vaq.domainDisplayName} would like to better
          understand your security practices and has asked you to complete this
          questionnaire.
        </p>
        <p>
          These questions:
          <ul>
            <li>take less than an hour to complete</li>
            <li>
              require some knowledge of engineering practices at your company
            </li>
            <li>include sections that may be completed in any order</li>
            <li>do not require answers to each question</li>
            <li>
              may be shared with other members of your team - just send them the
              link
            </li>
          </ul>
        </p>
        <hr />
      </div>
    );
  }
};

function answersHaveChanged(prevJSON: string, currentJSON: string) {
  const j1 = JSON.parse(prevJSON);
  const j2 = JSON.parse(currentJSON);
  const FIELDS_TO_CHECK = [
    "vendorLegalName",
    "urlVendor",
    "vendorContactSupportEmail",
    "assessmentCheckbox",
  ];
  return FIELDS_TO_CHECK.some(field => j1[field] !== j2[field]);
}

gql`
  mutation submitVendorAssessment(
    $uuid: String!
    $file: FileFieldName!
    $assessmentType: String!
  ) {
    submitVAQWithComplianceFile(
      uuid: $uuid
      file: $file
      assessmentType: $assessmentType
    )
  }
`;
