import { Spinner } from "@blueprintjs/core";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import React from "react";
import styled from "styled-components";

import { GRID_SPACING } from "../../../../alpaca/base/grid";
import { LogError } from "../../../../errors";
import type { GetUserAccessQuery } from "../../../../gen/components";
import { useGetUserAccessQuery } from "../../../../gen/components";
import { GSuiteSSOServiceList } from "./gsuite-sso-services-list";
import { LinkedServiceAccounts } from "./linked-service-accounts";
import { SSHAccessibleResources } from "./ssh-accessible-resources";

export type AccessTabUser = NonNullable<GetUserAccessQuery["user"]>;

interface IProps {
  userId: string;
}

export const AccessTab: React.FC<IProps> = ({ userId }) => {
  const { loading, error, data } = useGetUserAccessQuery({
    variables: { userId },
  });
  if (error) {
    LogError(error);
    return null;
  }
  if (loading || !isSome(data) || !isSome(data.user)) {
    return <Spinner />;
  }

  const user = data.user;
  return (
    <>
      <LinkedServiceAccounts user={user} />
      <GSuiteSSOServiceList user={user} />
      <StyledSectionContainerDiv>
        <SSHAccessibleResources workstations={user.workstations} />
      </StyledSectionContainerDiv>
    </>
  );
};

const StyledSectionContainerDiv = styled.div`
  margin-top: ${2 * GRID_SPACING}px;
`;

gql`
  query GetUserAccess($userId: ID!) {
    user(id: $userId) {
      id
      displayName
      email
      gsuiteId
      isActive
      serviceAccounts {
        id
        accountName
        deactivationDate
        service
      }
      workstations {
        id
        data {
          id
          ... on macosWorkstationData {
            sshKeys {
              machinesThisCanAccess(first: 10) {
                # only list the first 10 machines this can access
                totalCount
                edges {
                  node {
                    id
                    prettyName
                    cloudProviderData {
                      id
                      ... on SpecificEC2InstanceResource {
                        account
                        service
                      }
                    }
                  }
                }
              }
            }
          }
          ... on linuxWorkstationData {
            sshKeys {
              machinesThisCanAccess(first: 10) {
                # only list the first 10 machines this can access
                totalCount
                edges {
                  node {
                    id
                    prettyName
                    cloudProviderData {
                      id
                      ... on SpecificEC2InstanceResource {
                        account
                        service
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
