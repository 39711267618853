import { Classes } from "@blueprintjs/core";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import React from "react";

import { LogError } from "../../../errors";
import type { FetchEndpointsQuery } from "../../../gen/components";
import { useFetchEndpointsQuery } from "../../../gen/components";
import { MaybeProfileImage } from "../../helpers/MaybeProfileImage";
import { NameEmailDisplay } from "../../helpers/NameEmailDisplay";
import { FailDataRow } from "../fail-data-row";

const IMAGE_DIMENSION = 32;

export const EndpointTestEntities: React.FC<{
  entityIds: string[];
  testId: string;
  entityType: "Osquery";
  allowWhitelisting: boolean;
  first: number;
}> = ({ entityType, entityIds, testId, allowWhitelisting, first }) => {
  const { loading, error, data } = useFetchEndpointsQuery({
    variables: { endpointIds: entityIds },
  });

  if (loading) {
    return <div />;
  }
  if (error) {
    LogError(error);
    return null;
  }
  if (!data) {
    return <div />;
  }

  const elements = data.organization.osqueryEndpointsByIds
    ?.slice(0, first)
    .map(e => (
      <FailDataRow
        key={e.id}
        entityId={e.id}
        entityType={entityType}
        testId={testId}
        allowWhitelisting={allowWhitelisting}
      >
        <Endpoint endpoint={e} />
      </FailDataRow>
    ));

  return <>{elements}</>;
};

gql`
  query fetchEndpoints($endpointIds: [ID!]!) {
    organization {
      id
      osqueryEndpointsByIds(endpointIds: $endpointIds) {
        id
        hostIdentifier
        user {
          id
          displayName
          givenName
          email
          imageUrl
        }
        data {
          id
          hostname
          osVersion
          serialNumber
        }
      }
    }
  }
`;

const Endpoint: React.FC<{
  endpoint: NonNullable<
    FetchEndpointsQuery["organization"]["osqueryEndpointsByIds"]
  >[number];
}> = ({ endpoint }) => {
  const serialNumberOrHostIdentifier =
    endpoint.data.serialNumber ?? endpoint.hostIdentifier;

  return (
    <div className="fail-data-user-row">
      <div className="fail-data-user-image">
        <MaybeProfileImage
          url={isSome(endpoint.user) ? endpoint.user.imageUrl : ""}
          circle={true}
          dimension={IMAGE_DIMENSION}
        />
      </div>
      {isSome(endpoint.user) ? (
        <NameEmailDisplay
          displayName={
            endpoint.user.displayName !== ""
              ? endpoint.user.displayName
              : endpoint.user.email
          }
          email={serialNumberOrHostIdentifier}
        />
      ) : (
        <>
          <div>
            {!isSome(endpoint.data.hostname) ? (
              serialNumberOrHostIdentifier
            ) : (
              <>
                {isSome(endpoint.data.osVersion)
                  ? `${endpoint.data.hostname} (${endpoint.data.osVersion})`
                  : `${endpoint.data.hostname}`}
                <br />
                <span className={`${Classes.TEXT_MUTED}`}>
                  <small>{serialNumberOrHostIdentifier}</small>
                </span>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};
