import type {
  SpecificDigitalOceanRedisClusterResource,
  SpecificDigitalOceanRelationalDbClusterResource,
} from "common/base/types/gen";
import gql from "graphql-tag";

import type { TableParameters } from "../helpers";
import { booleanToEvaluationIcon } from "../helpers";
import { SHARED_DATABASE_TABLE_HEADERS } from "./helpers";

export const DIGITALOCEAN_DBS = [
  "digitalOceanRelationalDbClusters",
  "digitalOceanRedisClusters",
] as const;

export const digitaloceanDataTableParameters: { [k: string]: TableParameters } =
  {
    digitalOceanRelationalDbClusters: {
      displayName: "DigitalOcean Relational Database Clusters",
      columnOrder: [
        "name",
        "creationTime",
        "engine",
        "databaseNames",
        "encrypted",
      ],
      header: {
        name: SHARED_DATABASE_TABLE_HEADERS.name,
        creationTime: SHARED_DATABASE_TABLE_HEADERS.creationTime,
        engine: "Engine",
        databaseNames: SHARED_DATABASE_TABLE_HEADERS.databaseNames,
        encrypted: SHARED_DATABASE_TABLE_HEADERS.encrypted,
      },
      createRow: (
        instance: Partial<SpecificDigitalOceanRelationalDbClusterResource>
      ) => {
        return {
          name: instance.name,
          creationTime: instance.creationTime,
          engine: instance.engine,
          databaseNames: (instance.databaseNames ?? []).join(", "),
          // Provided by DigitalOcean by default
          encrypted: booleanToEvaluationIcon(true),
        };
      },
    },
    digitalOceanRedisClusters: {
      displayName: "DigitalOcean Redis Clusters",
      columnOrder: ["name", "creationTime", "databaseNames", "encrypted"],
      header: {
        name: SHARED_DATABASE_TABLE_HEADERS.name,
        creationTime: SHARED_DATABASE_TABLE_HEADERS.creationTime,
        databaseNames: SHARED_DATABASE_TABLE_HEADERS.databaseNames,
        encrypted: SHARED_DATABASE_TABLE_HEADERS.encrypted,
      },
      createRow: (
        instance: Partial<SpecificDigitalOceanRedisClusterResource>
      ) => {
        return {
          name: instance.name,
          creationTime: instance.creationTime,
          databaseNames: (instance.databaseNames ?? []).join(", "),
          // Provided by DigitalOcean by default
          encrypted: booleanToEvaluationIcon(true),
        };
      },
    },
  };

gql`
  query fetchDigitalOceanDBInfo {
    organization {
      id
      displayName
      digitalOceanRelationalDbClusters: resources(
        first: 1000
        specificResourceType: DigitalOceanRelationalDbCluster
      ) {
        totalCount
        edges {
          node {
            id
            ... on SpecificDigitalOceanRelationalDbClusterResource {
              creationTime
              databaseNames
              engine
              name
            }
          }
        }
      }
      digitalOceanRedisClusters: resources(
        first: 1000
        specificResourceType: DigitalOceanRedisCluster
      ) {
        totalCount
        edges {
          node {
            id
            ... on SpecificDigitalOceanRedisClusterResource {
              creationTime
              databaseNames
              name
            }
          }
        }
      }
    }
  }
`;
