import { isSome } from "common/base/types/maybe";
import React from "react";
import styled from "styled-components";

import { Dimensions } from "./constants";
import { pathToSideMenu } from "./links";
import { SideMenuLinkList } from "./side-menu-link-list";

export const SideNavMenu: React.FC = () => {
  const maybeSideMenu = pathToSideMenu(location.pathname);
  if (!isSome(maybeSideMenu)) {
    return null;
  }
  return (
    <Container>
      <MenuHeading>{React.createElement(maybeSideMenu.header)}</MenuHeading>
      <SideMenuLinkList links={maybeSideMenu.links} />
    </Container>
  );
};

const MenuHeading = styled.div`
  height: ${Dimensions.PAGE_HEADING_HEIGHT}px;
  margin-bottom: ${Dimensions.PAGE_HEADING_BOTTOM_MARGIN}px;
`;

const Container = styled.div`
  margin-right: ${Dimensions.SIDE_MENU_RIGHT_MARGIN}px;
  width: ${Dimensions.SIDE_MENU_WIDTH}px;
  flex-shrink: 0;
`;
