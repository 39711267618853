import { Icon, Intent, Spinner } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React from "react";
import styled from "styled-components";

/**
 * FetchCompletionStatusIndicator displays a tick circle icon if
 * fetchCompletionProportion is 1 (i.e. all fetches have been completed since
 * the credential in question was linked). Otherwise it displays a spinner
 * indicating how many of the fetches have been completed.
 *
 * @param fetchCompletionProportion: a number in the range 0-1 (inclusive).
 *    @see calculateFetchCompletionProportion.
 */
export const FetchCompletionStatusIndicator: React.FC<{
  fetchCompletionProportion: number;
}> = ({ fetchCompletionProportion }) => {
  const indicatorSize = 16;
  return fetchCompletionProportion === 1 ? (
    <FetchCompletionStatusIcon
      icon={IconNames.TICK_CIRCLE}
      iconSize={indicatorSize}
    />
  ) : (
    <FetchCompletionStatusSpinner
      intent={Intent.PRIMARY}
      size={indicatorSize}
      value={fetchCompletionProportion}
    />
  );
};

const FetchCompletionStatusStyles = {
  MARGIN_LEFT: 12,
};

const FetchCompletionStatusSpinner = styled(Spinner)`
  display: inline-block;
  margin-left: ${FetchCompletionStatusStyles.MARGIN_LEFT}px;
`;

const FetchCompletionStatusIcon = styled(Icon)`
  margin-left: ${FetchCompletionStatusStyles.MARGIN_LEFT}px;
  vertical-align: middle;
`;
