import { AnchorButton, Intent } from "@blueprintjs/core";
import { isSome } from "common/base/types/maybe";
import React from "react";
import { Redirect } from "react-router";
import styled from "styled-components";

export const LoginLinkLandingPage: React.FC = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const maybeCode = searchParams.get("code");
  if (!isSome(maybeCode)) {
    return <Redirect to="/" />;
  }
  const loginRedirect = `/auth/login/link?code=${maybeCode}`;
  return (
    <Container>
      <div>
        <AnchorButton intent={Intent.PRIMARY} href={loginRedirect} large>
          Click to continue to Vanta
        </AnchorButton>
      </div>
    </Container>
  );
};

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;
