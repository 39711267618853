import { DATADOG_REGIONS } from "common/constants/datadog-regions";
import React from "react";

import { DefaultLink } from "../../../../helpers/links";
import { SERVICE_DETAILS } from "../../../credentials/service-groups";
import type {
  InputLabel,
  OptionLabel,
} from "../simple-credential-form-dialog-body";
import { SimpleCredentialFormDialogBody } from "../simple-credential-form-dialog-body";
import type { IFormCredentialProps } from "./common-interface";

export const Datadog: React.FunctionComponent<IFormCredentialProps> = ({
  onCredentialsLinked,
}) => {
  const apiKeyHelper = (
    <span>
      Find your application and API keys on your{" "}
      <DefaultLink href="https://app.datadoghq.com/account/settings#api">
        account settings
      </DefaultLink>
      .
    </span>
  );
  const regionHelper = (
    <span>
      See{" "}
      <DefaultLink href="https://docs.datadoghq.com/getting_started/site/#how-do-i-tell-which-datadog-site-i-am-on">
        these instructions
      </DefaultLink>{" "}
      to determine what region you are on.
    </span>
  );

  const labels: Array<InputLabel | OptionLabel> = [
    { name: "appKey", displayName: "Application key" },
    { name: "apiKey", displayName: "API key", helperText: apiKeyHelper },
    {
      name: "region",
      displayName: "Region",
      defaultValue: "US",
      options: [...DATADOG_REGIONS],
      helperText: regionHelper,
    },
  ];
  return (
    <SimpleCredentialFormDialogBody
      labels={labels}
      onCredentialsLinked={onCredentialsLinked}
      service={SERVICE_DETAILS.datadog}
    />
  );
};
