import "./credential.scss";

import { Classes } from "@blueprintjs/core";
import { dropNothing, isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import React from "react";

import { LogError } from "../../../errors";
import { useAllLinkedCredentialsQuery } from "../../../gen/components";
import type { LinkableServiceId } from "../../credentials/service-groups";
import { SERVICE_DETAILS } from "../../credentials/service-groups";
import { FullPageSpinner } from "../../helpers/FullPageSpinner";

export const CredentialComponent: React.FunctionComponent<{
  service: LinkableServiceId;
  onCredentialsLinked(): void;
}> = props => {
  const { error, loading, data } = useAllLinkedCredentialsQuery();
  if (error) {
    LogError(error);
    return null;
  }
  if (loading) {
    return <FullPageSpinner />;
  }
  if (!data) {
    LogError(new Error("Bad data from fetch"));
    return null;
  }

  const service = props.service;
  const serviceDetails = SERVICE_DETAILS[service];
  const Component = serviceDetails.linkingComponent;

  const credentials = dropNothing(data.organization.credentials).filter(
    x => x.service === service
  );
  const credential = credentials.length === 1 ? credentials[0] : undefined;

  return (
    <div className={`${Classes.RUNNING_TEXT} app-connections-page`}>
      <Component
        credential={credential}
        credentials={credentials}
        domain={data.organization}
        service={serviceDetails}
        onCredentialsLinked={async () => {
          if (isSome(props.onCredentialsLinked)) {
            props.onCredentialsLinked();
          }
        }}
      />
    </div>
  );
};

// Still used in fetch-credentials.ts
gql`
  query getCredentials($services: [String!]) {
    organization {
      id
      displayName
      name
      credentials(services: $services) {
        id
        service
        lastUpdated
        metadata
      }
    }
  }
`;

gql`
  mutation setCredentials(
    $service: String!
    $credentials: String!
    $externalAccountId: String
  ) {
    setCredentials(
      externalAccountId: $externalAccountId
      service: $service
      credentials: $credentials
    ) {
      id
      name
      credentials {
        id
        lastUpdated
        service
        metadata
      }
    }
  }
`;
