/* eslint-disable vanta/prefer-maybe */
/* eslint-disable vanta/optional-always-maybe */
import styled from "styled-components";
import {
  color,
  fontFamily,
  fontSize,
  fontWeight,
  letterSpacing,
  lineHeight,
  system,
} from "styled-system";

const textTransform = system({ textTransform: true });
interface ITypographyProps {
  as?: keyof JSX.IntrinsicElements | undefined; // set the rendered HTML element
  fontFamily?: string | undefined;
  fontSize?: number | string | undefined;
  fontWeight?: number | undefined;
  lineHeight?: string | undefined;
  letterSpacing?: string | undefined;
  color?: string | undefined;
  textTransform?: string | undefined;
}
/**
 * The typography factory is a generator to create typography components.
 * This should NOT be imported and used outside of implementing new Alpaca typography components.
 * The typography components generated are are only
 * concerned with controlling:
 * - font family
 * - font size (defined as a pixel number value)
 * - font weight
 * - line height
 * - letter spacing
 * - color
 * - text transform
 * Please reference the alpaca typography components in alpaca/components for reusable
 * typography components.
 */
export const TypographyFactory = () => styled.div<ITypographyProps>`
  ${fontFamily}
  ${fontSize}
  ${fontWeight}
  ${lineHeight}
  ${letterSpacing}
  ${color}
  ${textTransform}
`;

export const BASE_TYPOGRAPHY = {
  // these are converted to px values by styled-system when passed in as props
  FONT_SIZES: {
    H1: 44,
    H2: 34,
    H3: 28,
    H4: 20,
    BODY_SHORT_TEXT: 16,
    BODY_TEXT: 14,
    META_TEXT: 12,
    HELPER_TEXT: 10,
  },
  LINE_HEIGHTS: {
    H1: "48px",
    H2: "48px",
    H3: "36px",
    H4: "24px",
    BODY_SHORT_TEXT: "20px",
    BODY_TEXT: "24px",
    META_TEXT: "16px",
    HELPER_TEXT: "15px",
  },
  FONT_FAMILIES: {
    INTER: "Inter",
    CHELTENHAM: "Cheltenham",
    MONACO: "Monaco",
  },
  FONT_WEIGHTS: {
    LIGHTER: 200,
    LIGHT: 300,
    NORMAL: 400,
    SLIGHTLY_BOLD: 500,
    BOLD: 600,
    BOLDER: 700,
    BOLDEST: 800,
  },
  LETTER_SPACINGS: {
    BODY_SHORT_TEXT: "0.01em",
    BODY_TEXT: "0.01em",
    META_TEXT: "0.01em",
    HELPER_TEXT: "0.448px",
  },
} as const;
