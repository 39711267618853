import { Button, Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import { parse } from "json2csv";
import { keyBy } from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { CSVLink } from "react-csv";

import { useGetAgentApplicationDataLazyQuery } from "../../../gen/components";
import { FILE_TIMESTAMP_FORMAT, UI_DATE_FORMAT } from "../../../helpers/common";
import { TABLE_CONTROLS_STYLES } from "../components/table-controls";
import { groupForUser } from "../people/utils";
import type { IUserWorkstation } from "./utils";
import { monitoredByFromWorkstation } from "./utils";

interface IProps {
  workstations: IUserWorkstation[];
}

export const MonitoredComputersCSVButton: React.FC<IProps> = ({
  workstations,
}) => {
  const [fetchData, queryResult] = useGetAgentApplicationDataLazyQuery();
  const [clicked, setClicked] = useState(false);
  const linkRef = useRef<any>();

  const agentApplicationsById = useMemo(() => {
    const users = queryResult.data?.organization.users;
    if (!isSome(users)) {
      return null;
    }
    const allWorkstationsWithApplication = users
      .flatMap(u => u.workstations)
      .map(w => {
        return {
          id: w.id,
          applications: "applications" in w.data ? w.data.applications : null,
        };
      })
      .filter(w => isSome(w.applications));
    return keyBy(allWorkstationsWithApplication, w => w.id);
  }, [queryResult?.data?.organization]);

  useEffect(() => {
    if (!clicked || !isSome(agentApplicationsById)) {
      return;
    } else {
      linkRef?.current?.link?.click();
      setClicked(false);
    }
  }, [clicked, agentApplicationsById]);
  return (
    <>
      <Button
        disabled={workstations.length === 0}
        loading={queryResult.loading}
        onClick={() => {
          if (!queryResult.called) {
            fetchData();
          }
          setClicked(true);
        }}
        style={{
          fontSize: TABLE_CONTROLS_STYLES.FONT_SIZE,
          height: TABLE_CONTROLS_STYLES.HEIGHT,
        }}
        icon={
          <Icon
            icon={IconNames.DOWNLOAD}
            iconSize={TABLE_CONTROLS_STYLES.ICON_SIZE}
          />
        }
      >
        Export
      </Button>
      <CSVLink
        style={{ display: "none" }}
        ref={linkRef}
        data={
          workstations.length > 0
            ? parse(
                workstations.map(ws => {
                  return {
                    "Device ID": ws.id,
                    "Serial Number": ws.serialNumber ?? null,
                    "OS Version": ws.osVersion,
                    "Owner ID": ws.user.id,
                    "Owner Email": ws.user.email,
                    "Group": groupForUser(ws.user),
                    "Ex-Employee":
                      !ws.user.isActive && !Boolean(ws.user.isNotHuman)
                        ? "Yes"
                        : "No",
                    "Status - Password Manager":
                      ws.passwordManagers.length > 0 ? "Yes" : "No",
                    "Status - HD Encrypted": ws.isEncrypted ? "Yes" : "No",
                    "Status - AV Installed":
                      ws.antivirusPrograms.length + ws.avPolicies.length > 0
                        ? "Yes"
                        : "No",
                    "Application Count": ws.numApplications,
                    "Applications": (
                      (agentApplicationsById ?? {})[ws.id]?.applications ??
                      ws.applicationNames ??
                      []
                    ).join(","),
                    "Monitored By": monitoredByFromWorkstation(ws),
                    "Last Checked Timestamp": isSome(ws.lastData)
                      ? moment(ws.lastData).format(UI_DATE_FORMAT)
                      : null,
                  };
                })
              )
            : ""
        }
        filename={`Computers-monitored-${moment().format(
          FILE_TIMESTAMP_FORMAT
        )}.csv`}
        target="_blank"
      />
    </>
  );
};

gql`
  query getAgentApplicationData {
    organization {
      id
      users(includeRemovedUsers: true, includeNonHumanUsers: true) {
        id
        workstations {
          id
          data {
            __typename
            id
            ... on macosWorkstationData {
              applications
            }
            ... on windowsWorkstationData {
              applications
            }
          }
        }
      }
    }
  }
`;
