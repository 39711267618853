import type { IconName } from "@blueprintjs/core";
import { Classes, Dialog, Icon, Spinner } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import type {
  SpecificAzureRoleAssignmentResource,
  SpecificAzureRoleResource,
} from "common/base/types/gen";
import { SpecificResource } from "common/base/types/gen";
import type { Service } from "common/base/types/helpers";
import type { Maybe } from "common/base/types/maybe";
import { isSome, nothing } from "common/base/types/maybe";
import type { azurePrincipalType } from "common/resources/specific-resources/azure/role-assignment";
import gql from "graphql-tag";
import _, { groupBy, keyBy, maxBy } from "lodash";
import moment from "moment";
import React, { useContext, useMemo, useState } from "react";
import styled from "styled-components";

import { BASE_TYPOGRAPHY } from "../../../alpaca/base/typography";
import {
  BodyText,
  H4,
  TextLinkLikeBlack,
  Tooltip,
} from "../../../alpaca/components";
import { LogError } from "../../../errors";
import type {
  SpecificGcpRoleGrantResource,
  SpecificGcpRoleResource,
} from "../../../gen/components";
import { useRoleGrantAccessDataQuery } from "../../../gen/components";
import { UI_DATE_FORMAT_WITHOUT_TIME } from "../../../helpers/common";
import { Ellipsify } from "../../../helpers/ellipsify";
import { Card } from "../components/card";
import type { DataTableFilter } from "../components/data-table";
import { COLUMN_CLASSES, DataTable } from "../components/data-table";
import { DropdownButton } from "../components/dropdown-button";
import { NotApplicable } from "../people/shared/not-applicable";
import {
  FILTER_BUTTON_WIDTH,
  InfoNotAvailable,
  StyledRoleTagList,
  TableEmptyDefault,
} from "./access-components/styles";
import type { AccountsAccessCredential } from "./access-page";
import { accessPageCredentialToKey } from "./access-page-with-service";
import { ACCESS_PAGE_DEFAULT_PAGE_SIZE } from "./constants";
import { CredentialsContext } from "./credentials-context";
import { ServiceDropdown } from "./service-dropdown";

type RoleGrant =
  | SpecificGcpRoleGrantResource
  | SpecificAzureRoleAssignmentResource;
type Role = SpecificGcpRoleResource | SpecificAzureRoleResource;

function getColumnHeaders(service: Service) {
  return {
    type: "Type",
    name: service === "gcp" ? "Member" : "Principal Name",
    status: "Status",
    roles: "Roles",
    deactivationDate: "Deactivated",
  };
}

const COLUMN_ORDER = ["type", "name", "roles", "status", "deactivationDate"];

const COLUMN_WIDTHS = ["100px", "300px", "300px", "150px", "200px"];

/**
 * getFinalDeletionDate returns the last deletion date among an array of grants
 * if every grant is deleted. Otherwise, it returns nothing.
 */
function getFinalDeletionDate(grants: RoleGrant[]): Maybe<Date> {
  if (grants.some(g => !isSome(g.deletedAt))) {
    return nothing;
  }
  return new Date(maxBy(grants, g => g.deletedAt)!.deletedAt!);
}

/**
 * The RoleGrantsAccessTable is different from the other AccountsAccessTables
 * because GCP and Azure's permissions model is somewhat different: service accounts are
 * associated with (often programmatic) functions rather than with users, so
 * Vanta's service account pattern doesn't apply neatly.
 *
 * This table roughly replicates the GCP/Azure IAM dashboard, but it includes all grants
 * in one flat view and shows info about deleted grants.
 *
 * NOTE: Using member/principal IDs as the row data here avoids using some merged
 * intermediate type representing a member, but it makes filter/sort operations
 * somewhat less efficient and requires declaring COLUMN_SORT_FUNCTIONS and
 * CUSTOM_FILTERS in the functional component body.
 */
export const RoleGrantsAccessTable: React.FC<{
  credential: AccountsAccessCredential;
}> = ({ credential }) => {
  const { credentials, defaultCredential } = useContext(CredentialsContext);
  const { service } = credential;
  const [selectedFilter, setSelectedFilter] = useState<Maybe<string>>(null);
  const specificRoleResource =
    service === "gcp" ? SpecificResource.GCPRole : SpecificResource.AzureRole;
  const specificRoleGrantResource =
    service === "gcp"
      ? SpecificResource.GCPRoleGrant
      : SpecificResource.AzureRoleAssignment;
  const { loading, data } = useRoleGrantAccessDataQuery({
    variables: {
      specificRole: specificRoleResource,
      specificRoleGrant: specificRoleGrantResource,
    },
    onError: LogError,
  });

  const allGrants = data?.organization.allGrants;
  const allRoles = data?.organization.allRoles;
  const grants = (allGrants?.edges.map(e => e.node) ?? []) as RoleGrant[];
  const roles = (allRoles?.edges.map(e => e.node) ?? []) as Role[];
  const grantsByMemberId = groupBy(grants, roleGrant =>
    "member" in roleGrant ? roleGrant.member : roleGrant.principalId
  );
  const members = Object.keys(grantsByMemberId);
  const rolesByUniqueId = keyBy(roles, role => role.uniqueId);

  const COLUMN_SORT_FUNCTIONS: {
    [k: string]: (memberId1: string, memberId2: string) => number;
  } = {
    type: (memberId1, memberId2) => {
      const roleGrant1 = grantsByMemberId[memberId1][0];
      const roleGrant2 = grantsByMemberId[memberId2][0];
      return getType(roleGrant1).localeCompare(getType(roleGrant2));
    },
    name: (memberId1, memberId2) => {
      const roleGrant1 = grantsByMemberId[memberId1][0];
      const roleGrant2 = grantsByMemberId[memberId2][0];
      return getName(roleGrant1).localeCompare(getName(roleGrant2));
    },
    status: (memberId1, memberId2) => {
      const status1 = grantsByMemberId[memberId1].every(g =>
        isSome(g.deletedAt)
      );
      const status2 = grantsByMemberId[memberId2].every(g =>
        isSome(g.deletedAt)
      );
      return +status1 - +status2;
    },
    roles: (memberId1, memberId2) =>
      // Just sort by number of granted roles.
      grantsByMemberId[memberId1].filter(g => !isSome(g.deletedAt)).length -
      grantsByMemberId[memberId2].filter(g => !isSome(g.deletedAt)).length,
    deactivationDate: (memberId1, memberId2) => {
      const deleted1 =
        getFinalDeletionDate(grantsByMemberId[memberId1]) ?? new Date(0);
      const deleted2 =
        getFinalDeletionDate(grantsByMemberId[memberId2]) ?? new Date(0);
      return deleted1.getTime() - deleted2.getTime();
    },
  };

  const CUSTOM_FILTERS: Array<DataTableFilter<string>> = [
    [
      "Active",
      memberId => !isSome(getFinalDeletionDate(grantsByMemberId[memberId])),
    ],
    [
      "Deactivated",
      memberId => isSome(getFinalDeletionDate(grantsByMemberId[memberId])),
    ],
  ];
  const filteredMembers = useMemo(() => {
    const maybeFilter = CUSTOM_FILTERS.find(f => f[0] === selectedFilter);
    if (!isSome(maybeFilter)) {
      return members;
    } else {
      return members.filter(maybeFilter[1]);
    }
  }, [members, selectedFilter]);

  if (loading || !isSome(data?.organization)) {
    return <Spinner />;
  }

  const filterElement = (
    <DropdownButton
      key="filter-dropdown"
      options={CUSTOM_FILTERS.map(filter => filter[0])}
      selectedOption={selectedFilter}
      onOptionSelect={filter => {
        setSelectedFilter(filter);
      }}
      optionRenderer={filter => filter}
      defaultText={"Filter by status"}
      isFilter={true}
      buttonWidth={FILTER_BUTTON_WIDTH}
      menuWidth={FILTER_BUTTON_WIDTH}
      styleOnSelect
    />
  );

  const getRowForMemberId = (memberId: string) => {
    const roleGrant = grantsByMemberId[memberId][0];
    const type = getType(roleGrant);
    const name = getName(roleGrant);

    const grantsForRow: Maybe<RoleGrant[]> = grantsByMemberId[memberId];
    if (!isSome(grantsForRow) || grantsForRow.length === 0) {
      LogError(new Error(`No grants correspond to member ID ${memberId}`));
    }

    const finalDeletionDate = getFinalDeletionDate(grantsForRow);
    const status = isSome(finalDeletionDate) ? "Deactivated" : "Active";

    // Member deactivation is the deactivation time of the last grant.
    const deactivationDate = isSome(finalDeletionDate) ? (
      moment(finalDeletionDate).format(UI_DATE_FORMAT_WITHOUT_TIME)
    ) : (
      <NotApplicable />
    );

    const activeGrants = grantsForRow.filter(g => !isSome(g.deletedAt));
    let rolesForRow = null;
    if (service === "gcp") {
      const grantsByProject = groupBy(
        activeGrants.filter(g => !isSome(g.deletedAt)),
        g => ("projectId" in g ? g.projectId : g.subscriptionId)
      );

      rolesForRow =
        Object.keys(grantsByProject).length === 0 ? (
          <InfoNotAvailable />
        ) : (
          <>
            {Object.entries(grantsByProject).map(
              ([projectId, projectGrants]) => (
                <div key={projectId}>
                  <BodyText
                    as={"span"}
                    fontWeight={BASE_TYPOGRAPHY.FONT_WEIGHTS.BOLD}
                  >
                    {projectId}
                  </BodyText>
                  <br />
                  <StyledRoleTagList
                    tags={projectGrants.map(g => {
                      const role = rolesByUniqueId[g.roleUniqueId];
                      return isSome(role) ? (
                        <RoleDetail role={role} />
                      ) : (
                        g.roleUniqueId
                      );
                    })}
                  />
                </div>
              )
            )}
          </>
        );
    } else {
      const roleTags = activeGrants.map(g => {
        const role = rolesByUniqueId[g.roleUniqueId];
        return isSome(role) ? role.name : g.roleUniqueId;
      });
      rolesForRow = <StyledRoleTagList tags={roleTags} />;
    }

    return {
      type: <TypeIndicator service={service as Service} type={type} />,
      name: <Ellipsify text={name} />,
      status,
      roles: rolesForRow,
      deactivationDate,
    };
  };

  const memberMatchesSearchString = (memberId: string, searchText: string) => {
    const lowerCased = searchText.trim().toLocaleLowerCase();
    const lowerCasedName = getName(
      grantsByMemberId[memberId][0]
    ).toLocaleLowerCase();
    return lowerCasedName.includes(lowerCased);
  };

  return (
    <Card>
      <DataTable
        useDefaultStyling
        stickyHeaders
        paginate={{
          paginationId: "access-role-grant",
          defaultPageSize: ACCESS_PAGE_DEFAULT_PAGE_SIZE,
        }}
        customControls={{
          leftControls: [
            <ServiceDropdown
              key="service-dropdown"
              credentials={credentials}
              defaultCredential={defaultCredential}
              onSelect={() => setSelectedFilter(null)}
            />,
            filterElement,
          ],
        }}
        columnOrder={COLUMN_ORDER}
        columnSortFunctions={COLUMN_SORT_FUNCTIONS}
        columnWidths={COLUMN_WIDTHS}
        columnClasses={{
          deactivationDate: COLUMN_CLASSES.CENTER_ALIGN,
        }}
        defaultSortColumn={"name"}
        header={getColumnHeaders(service as Service)}
        data={filteredMembers}
        createRow={getRowForMemberId}
        resetTableStateKey={accessPageCredentialToKey(credential)}
        searchFilter={memberMatchesSearchString}
        emptyDefault={TableEmptyDefault}
      />
    </Card>
  );
};

function getName(roleGrant: RoleGrant) {
  return "principalName" in roleGrant
    ? roleGrant.principalName
    : roleGrant.member.split(":")[1];
}

function getType(roleGrant: RoleGrant) {
  return "principalType" in roleGrant
    ? roleGrant.principalType
    : roleGrant.member.split(":")[0];
}
/**
 * TypeIndicator is a tooltipped icon indicating the type of a member. GCP/Azure
 * IAM role grants can grant roles to domains, groups, users, and service
 * accounts.
 *
 * This component loosely replicates the icon indicators in the GCP/Azure IAM
 * dashboard.
 */
const TypeIndicator: React.FC<{ service: Service; type: string }> = ({
  service,
  type,
}) => {
  let iconName: IconName;
  let tooltip: string;
  if (service === "gcp") {
    switch (type) {
      case "user":
        iconName = IconNames.USER;
        tooltip = "User";
        break;
      case "serviceAccount":
        iconName = IconNames.KEY;
        tooltip = "Service account";
        break;
      case "domain":
        iconName = IconNames.OFFICE;
        tooltip = "Domain";
        break;
      case "group":
        iconName = IconNames.PEOPLE;
        tooltip = "Group";
        break;
      default:
        return null;
    }
  } else {
    switch (type as azurePrincipalType) {
      case "User":
        iconName = IconNames.USER;
        tooltip = "User";
        break;
      case "ServicePrincipal":
        iconName = IconNames.KEY;
        tooltip = "Service Principal";
        break;
      case "Group":
        iconName = IconNames.PEOPLE;
        tooltip = "Group";
        break;
      default:
        iconName = IconNames.WRENCH;
        // converts camel case into delimited title case
        tooltip = _.startCase(type);
        return null;
    }
  }

  return (
    <Tooltip content={tooltip}>
      <Icon icon={iconName} />
    </Tooltip>
  );
};

/**
 * RoleDetail is a clickable role name. On click, it opens a dialog with the
 * role description and the included permissions.
 */
const RoleDetail: React.FC<{ role: Role }> = ({ role }) => {
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const permissionsList =
    "includedPermissions" in role ? (
      <GCPRoleDetailPermissions role={role} />
    ) : (
      <AzureRoleDetailPermissions role={role} />
    );
  const title =
    "isCustomRole" in role && !role.isCustomRole
      ? `Role: ${role.name}`
      : `Custom role: ${role.name}`;
  return (
    <>
      <TextLinkLikeBlack onClick={() => setDialogIsOpen(true)}>
        <Ellipsify text={role.name} />
      </TextLinkLikeBlack>
      <Dialog
        isOpen={dialogIsOpen}
        onClose={() => setDialogIsOpen(false)}
        title={title}
      >
        <div className={Classes.DIALOG_BODY}>
          {isSome(role.description) ? (
            <>
              <H4>Description</H4>
              <RoleDescription>{role.description}</RoleDescription>
            </>
          ) : null}
          {permissionsList}
        </div>
      </Dialog>
    </>
  );
};

const GCPRoleDetailPermissions: React.FC<{ role: SpecificGcpRoleResource }> = ({
  role,
}) => (
  <>
    <H4>Permissions</H4>
    <ul>
      {role.includedPermissions.map(p => (
        <li key={p}>
          <Ellipsify text={p} />
        </li>
      ))}
    </ul>
  </>
);

const AzureRoleDetailPermissions: React.FC<{
  role: SpecificAzureRoleResource;
}> = ({ role }) => (
  <>
    <H4>Action permissions</H4>
    <ul>
      {role.allowedActionPermissions.map(p => (
        <li key={p}>
          <Ellipsify text={p} />
        </li>
      ))}
    </ul>
    <H4>Excluded action permissions</H4>
    <ul>
      {role.deniedActionPermissions.map(p => (
        <li key={p}>
          <Ellipsify text={p} />
        </li>
      ))}
    </ul>
    <H4>Data permissions</H4>
    <ul>
      {role.allowedDataPermissions.map(p => (
        <li key={p}>
          <Ellipsify text={p} />
        </li>
      ))}
    </ul>
    <H4>Excluded data permissions</H4>
    <ul>
      {role.deniedDataPermissions.map(p => (
        <li key={p}>
          <Ellipsify text={p} />
        </li>
      ))}
    </ul>
  </>
);

// RoleDescription respects newlines in the description string from GCP.
const RoleDescription = styled.p`
  white-space: pre-wrap;
`;

gql`
  query roleGrantAccessData(
    $specificRoleGrant: SpecificResource!
    $specificRole: SpecificResource!
  ) {
    organization {
      id
      allGrants: resources(
        specificResourceType: $specificRoleGrant
        first: 1000
        options: { includeOutOfScope: true, includeDeleted: true }
      ) {
        totalCount
        edges {
          node {
            id
            deletedAt
            ... on SpecificGCPRoleGrantResource {
              roleUniqueId
              projectId
              member
            }
            ... on SpecificAzureRoleAssignmentResource {
              roleUniqueId
              principalId
              principalName
              principalType
              scope
            }
          }
        }
      }
      allRoles: resources(
        specificResourceType: $specificRole
        first: 1000
        options: { includeOutOfScope: true }
      ) {
        totalCount
        edges {
          node {
            id
            ... on SpecificGCPRoleResource {
              uniqueId
              description
              includedPermissions
              name
            }
            ... on SpecificAzureRoleResource {
              uniqueId
              description
              allowedActionPermissions
              allowedDataPermissions
              deniedActionPermissions
              deniedDataPermissions
              name
              isCustomRole
            }
          }
        }
      }
    }
  }
`;
