import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import React from "react";

import { BASE_PALETTE } from "../../../../alpaca/base/colors";
import { BodyText, Tooltip } from "../../../../alpaca/components";

interface IProps {
  tooltipContent?: Maybe<string>;
}

export const NotApplicable: React.FC<IProps> = ({ tooltipContent }) => (
  <Tooltip content={tooltipContent ?? ""} disabled={!isSome(tooltipContent)}>
    <BodyText as="div" lineHeight={"21px"} color={BASE_PALETTE.FOG}>
      N/A
    </BodyText>
  </Tooltip>
);
