import {
  Button,
  Classes,
  Dialog,
  FormGroup,
  InputGroup,
  Intent,
} from "@blueprintjs/core";
import { Suggest } from "@blueprintjs/select";
import React, { useState } from "react";

import type { GetAuditingInfoQuery } from "../../../../gen/components";
import { AppToaster } from "../../../../helpers/toaster";

interface IProps {
  auditableUsers: NonNullable<GetAuditingInfoQuery["user"]>["auditableUsers"];
  isOpen: boolean;
  onCloseDialog(): void;
  onCreateWorkPaper(workPaperName: string, customerDomainId: string): void;
}

const CustomerDomainSuggest =
  Suggest.ofType<
    NonNullable<GetAuditingInfoQuery["user"]>["auditableUsers"][number]
  >();

export const CreateWorkPaperDialog: React.FC<IProps> = ({
  auditableUsers,
  isOpen,
  onCloseDialog,
  onCreateWorkPaper,
}) => {
  const [workPaperNameText, setWorkPaperNameText] = useState("");
  const [selectedCustomerDomainId, setSelectedCustomerDomainId] = useState("");

  const onAddButtonClicked = () => {
    onCreateWorkPaper(workPaperNameText, selectedCustomerDomainId);
    AppToaster.show({
      intent: Intent.SUCCESS,
      message: "Work paper is generating.",
      timeout: 1000,
    });
    onClose();
  };

  const onClose = () => {
    setWorkPaperNameText("");
    onCloseDialog();
  };

  const canSubmit = workPaperNameText !== "" && selectedCustomerDomainId !== "";
  return (
    <Dialog
      isOpen={isOpen}
      title={`Create Work Paper`}
      usePortal={true}
      onClose={onClose}
      canOutsideClickClose={true}
    >
      <div className={Classes.DIALOG_BODY}>
        <FormGroup label={<strong>Document name</strong>}>
          <InputGroup
            type="text"
            value={workPaperNameText}
            placeholder="rocketship-work-paper"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setWorkPaperNameText(e.target.value)
            }
          />
        </FormGroup>
        <FormGroup label={<strong>Company name</strong>}>
          <CustomerDomainSuggest
            closeOnSelect
            resetOnQuery
            inputValueRenderer={d => d.domain.displayName}
            items={auditableUsers}
            itemsEqual={(d1, d2) => d1.id === d2.id}
            itemRenderer={(d, { modifiers, handleClick }) => (
              <div
                className={`domain-suggest-item${
                  modifiers.active ? " domain-suggest-active" : ""
                }`}
                onClick={handleClick}
                key={d.id}
              >
                {d.domain.displayName}
              </div>
            )}
            noResults={<div>No results.</div>}
            popoverProps={{ minimal: true }}
            onItemSelect={item => setSelectedCustomerDomainId(item.domain.id)}
          />
        </FormGroup>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            onClick={onAddButtonClicked}
            intent={Intent.PRIMARY}
            disabled={!canSubmit}
          >
            Create
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
