import type { IPanelProps } from "@blueprintjs/core";
import { AnchorButton, Classes, Intent } from "@blueprintjs/core";
import gql from "graphql-tag";
import React from "react";
import styled from "styled-components";

import { LogError } from "../../../../errors";
import { useEnsureOktaAppMutation } from "../../../../gen/components";
import { AppToaster } from "../../../../helpers/toaster";
import type { IProps, OktaPanel } from "./okta";
import { OktaContext } from "./okta";

const Padded = styled.div`
  padding: 40px;
  padding-top: 20px;
`;
const CreateOktaAppComponent: React.FC<IPanelProps & IProps> = props => {
  const [ensureOktaApp, { called }] = useEnsureOktaAppMutation();

  return (
    <OktaContext.Consumer>
      {({ domainId, onComplete }) => (
        <Padded>
          <p>Create a Vanta login app in your Okta account.</p>
          <p>
            Once the app has been successfully created, go to your Okta
            dashboard to configure its visibility to user groups in your
            organization.
          </p>
          <p>
            Users will need to be assigned the Vanta app in Okta to be able to
            log in to Vanta with Okta.
          </p>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <AnchorButton
                intent={Intent.PRIMARY}
                loading={called}
                disabled={called}
                onClick={async () => {
                  try {
                    await ensureOktaApp({
                      variables: { domainId },
                    });
                    AppToaster.show({
                      icon: "tick",
                      intent: Intent.SUCCESS,
                      message: "Okta login app created",
                      timeout: 2500,
                    });
                    onComplete();
                  } catch (e) {
                    LogError(e);
                  }
                }}
              >
                Create app
              </AnchorButton>
            </div>
          </div>
        </Padded>
      )}
    </OktaContext.Consumer>
  );
};

export const CreateOktaApp: OktaPanel = {
  title: "Create Okta App",
  component: CreateOktaAppComponent,
};

gql`
  mutation ensureOktaApp($domainId: ID!) {
    ensureOktaApp(domainId: $domainId)
  }
`;
