import { ReportStandard } from "../../base/types/gen";
import type { IStandard } from "../complianceTypes";

export const SOC2_STANDARD: IStandard = {
  standard: ReportStandard.soc2,
  version: "2017",
  updated: "4/7/2017", // <---- VERIFY DATE
  canonical: "TSP 100",
  principles: [
    {
      name: "Control Environment",
      section: "CC 1.0",
      optional: false,
      requirements: [
        {
          name: "COSO Principle 1: The entity demonstrates a commitment to integrity and ethical values.",
          section: "CC 1.1",
          shorthand:
            "Organization is committed to integrity and ethical values",
        },
        {
          name: "COSO Principle 2: The board of directors demonstrates independence from management and exercises oversight of the development and performance of internal control.",
          section: "CC 1.2",
          shorthand: "Company has an independent board of directors",
        },
        {
          name: "COSO Principle 3: Management establishes, with board oversight, structures, reporting lines, and appropriate authorities and responsibilities in the pursuit of objectives.",
          section: "CC 1.3",
          shorthand: "Organization has a defined structure",
        },
        {
          name: "COSO Principle 4: The entity demonstrates a commitment to attract, develop, and retain competent individuals in alignment with objectives.",
          section: "CC 1.4",
          shorthand: "Employees have the skills to do their jobs",
        },
        {
          name: "COSO Principle 5: The entity holds individuals accountable for their internal control responsibilities in the pursuit of objectives.",
          section: "CC 1.5",
          shorthand:
            "Information Security is empowered within the organization",
        },
      ],
    },
    {
      name: "Communication and Information",
      section: "CC 2.0",
      optional: false,
      requirements: [
        {
          name: "COSO Principle 13: The entity obtains or generates and uses relevant, quality information to support the functioning of internal control.",
          section: "CC 2.1",
          shorthand: "Company generates relevant, quality information",
        },
        {
          name: "COSO Principle 14: The entity internally communicates information, including objectives and responsibilities for internal control, necessary to support the functioning of internal control. ",
          section: "CC 2.2",
          shorthand:
            "Internal and external users understand their responsibilities in relation to the system",
        },
        {
          name: "COSO Principle 15: The entity communicates with external parties regarding matters affecting the functioning of internal control.",
          section: "CC 2.3",
          shorthand:
            "Employees and customers/external users can report problems",
        },
      ],
    },
    {
      name: "Risk Assessment",
      section: "CC 3.0",
      optional: false,
      requirements: [
        {
          name: "COSO Principle 6: The entity specifies objectives with sufficient clarity to enable the identification and assessment of risks relating to objectives.",
          section: "CC 3.1",
          shorthand: "Company can clearly identify risks",
        },
        {
          name: "COSO Principle 7: The entity identifies risks to the achievement of its objectives across the entity and analyzes risks as a basis for determining how the risks should be managed.",
          section: "CC 3.2",
          shorthand: "Risks are identified, analyzed, and mitigated",
        },
        {
          name: "COSO Principle 8: The entity considers the potential for fraud in assessing risks to the achievement of objectives.",
          section: "CC 3.3",
          shorthand: "Risks are identified, analyzed, and mititgated",
        },
        {
          name: "COSO Principle 9: The entity identifies and assesses changes that could significantly impact the system of internal control.",
          section: "CC 3.4",
          shorthand: "Company can take effective action on risks",
        },
      ],
    },
    {
      name: "Monitoring Activities",
      section: "CC 4.0",
      optional: false,
      requirements: [
        {
          name: "COSO Principle 16: The entity selects, develops, and performs ongoing and/or separate evaluations to ascertain whether the components of internal control are present and functioning.",
          section: "CC 4.1",
          shorthand: "Controls are reviewed on a regular basis",
        },
        {
          name: "COSO Principle 17: The entity evaluates and communicates internal control deficiencies in a timely manner to those parties responsible for taking corrective action, including senior management and the board of directors, as appropriate.",
          section: "CC 4.2",
          shorthand: "Controls are reviewed on a regular basis",
        },
      ],
    },
    {
      name: "Control Activities",
      section: "CC 5.0",
      optional: false,
      requirements: [
        {
          name: "COSO Principle 10: The entity selects and develops control activities that contribute to the mitigation of risks to the achievement of objectives to acceptable levels.",
          section: "CC 5.1",
          shorthand: "Policies and procedures are used to mitigate risks",
        },
        {
          name: "COSO Principle 11: The entity also selects and develops general control activities over technology to support the achievement of objectives.",
          section: "CC 5.2",
          shorthand: "Policies and procedures are used to mitigate risks",
        },
        {
          name: "COSO Principle 12: The entity deploys control activities through policies that establish what is expected and in procedures that put policies into action.",
          section: "CC 5.3",
          shorthand: "Policies are clear and followed",
        },
      ],
    },
    {
      name: "Logical and Physical Access Controls",
      section: "CC 6.0",
      optional: false,
      requirements: [
        {
          name: "The entity implements logical access security software, infrastructure, and architectures over protected information assets to protect them from security events to meet the entity's objectives.",
          section: "CC 6.1",
          shorthand:
            "Logical access identifies and authenticates users, restricts access, and disallows unauthenticated users",
        },
        {
          name: "Prior to issuing system credentials and granting system access, the entity registers and authorizes new internal and external users whose access is administered by the entity. For those users whose access is administered by the entity, user system credentials are removed when user access is no longer authorized.",
          section: "CC 6.2",
          shorthand:
            "User (employee) credentials are granted and removed appropriately",
        },
        {
          name: "The entity authorizes, modifies, or removes access to data, software, functions, and other protected information assets based on roles, responsibilities, or the system design and changes, giving consideration to the concepts of least privilege and segregation of duties, to meet the entity’s objectives.",
          section: "CC 6.3",
          shorthand:
            "Access is authenticated, modified, and removed based on system design and change",
        },
        {
          name: "The entity restricts physical access to facilities and protected information assets (for example, data center facilities,  back-up media storage, and other sensitive locations) to authorized personnel to meet the entity’s objectives.",
          section: "CC 6.4",
          shorthand: "Access to headquarters is controlled",
        },
        {
          name: "The entity discontinues logical and physical protections over physical assets only after the ability to read or recover data and software from those assets has been diminished and is no longer required to meet the entity’s objectives.",
          section: "CC 6.5",
          shorthand:
            "Data and software is deleted and disposed of appropriately",
        },
        {
          name: "The entity implements logical access security measures to protect against threats from sources outside its system boundaries.",
          section: "CC 6.6",
          shorthand: "Access protected from outsider threats",
        },
        {
          name: "The entity restricts the transmission, movement, and removal of information to authorized internal and external users and processes, and protects it during transmission, movement, or removal to meet the entity’s objectives.",
          section: "CC 6.7",
          shorthand: "Information is protected in transit and from deletion",
        },
        {
          name: "The entity implements controls to prevent or detect and act upon the introduction of unauthorized or malicious software to meet the entity’s objectives.",
          section: "CC 6.8",
          shorthand: "No malware, viruses",
        },
      ],
    },
    {
      name: "System Operations",
      section: "CC 7.0",
      optional: false,
      requirements: [
        {
          name: "To meet its objectives, the entity uses detection and monitoring procedures to identify (1) changes to configurations that result in the introduction of new vulnerabilities, and (2) susceptibilities to newly discovered vulnerabilities.",
          section: "CC 7.1",
          shorthand: "Company monitors its systems for vulnerabilities",
        },
        {
          name: "The entity monitors system components and the operation of those components for anomalies that are indicative of malicious acts, natural disasters, and errors affecting the entity's ability to meet its objectives; anomalies are analyzed to determine whether they represent security events.",
          section: "CC 7.2",
          shorthand: "Monitor and correct system vulnerabilities",
        },
        {
          name: "The entity evaluates security events to determine whether they could or have resulted in a failure of the entity to meet its objectives (security incidents) and, if so, takes actions to prevent or address such failures.",
          section: "CC 7.3",
          shorthand:
            "System is monitored for events, which are addressed according to predefined policies",
        },
        {
          name: "The entity responds to identified security incidents by executing a defined incident response program to understand, contain, remediate, and communicate security incidents, as appropriate.",
          section: "CC 7.4",
          shorthand:
            "System is monitored for events, which are addressed according to predefined policies",
        },
        {
          name: "The entity identifies, develops, and implements activities to recover from identified security incidents.",
          section: "CC 7.5",
          shorthand:
            "System is monitored for events, which are addressed according to predefined policies",
        },
      ],
    },
    {
      name: "Change Management",
      section: "CC 8.0",
      optional: false,
      requirements: [
        {
          name: "The entity authorizes, designs, develops or acquires, configures, documents, tests, approves, and implements changes to infrastructure, data, software, and procedures to meet its objectives.",
          section: "CC 8.1",
          shorthand: "System is changed with commitments in mind",
        },
      ],
    },
    {
      name: "Risk Mitigation",
      section: "CC 9.0",
      optional: false,
      requirements: [
        {
          name: "The entity identifies, selects, and develops risk mitigation activities for risks arising from potential business disruptions.",
          section: "CC 9.1",
          shorthand: "Company takes action to mitigate risks",
        },
        {
          name: "The entity assesses and manages risks associated with vendors and business partners.",
          section: "CC 9.2",
          shorthand:
            "Company assesses risks associated with vendors and business partners",
        },
      ],
    },
    {
      name: "Additional Criteria for Availability",
      section: "A 1",
      optional: true,
      requirements: [
        {
          name: "The entity maintains, monitors, and evaluates current processing capacity and use of system components (infrastructure, data, and software) to manage capacity demand and to enable the implementation of additional capacity to help meet its objectives.",
          section: "A 1.1",
        },
        {
          name: "The entity authorizes, designs, develops or acquires, implements, operates, approves, maintains, and monitors environmental protections, software, data back-up processes, and recovery infrastructure to meet its objectives.",
          section: "A 1.2",
        },
        {
          name: "The entity tests recovery plan procedures supporting system recovery to meet its objectives.",
          section: "A 1.3",
        },
      ],
    },
    {
      name: "Additional Criteria for Confidentiality",
      section: "C 1",
      optional: true,
      requirements: [
        {
          name: "The entity identifies and maintains confidential information to meet the entity’s objectives related to confidentiality.",
          section: "C 1.1",
        },
        {
          name: "The entity disposes of confidential information to meet the entity’s objectives related to confidentiality.",
          section: "C 1.2",
        },
      ],
    },
    {
      name: "Additional Criteria for Processing Integrity",
      section: "PI 1",
      optional: true,
      requirements: [
        {
          name: "The entity obtains or generates, uses, and communicates relevant, quality information regarding the objectives related to processing, including definitions of data processed and product and service specifications, to support the use of products and services.",
          section: "PI 1.1",
        },
        {
          name: "The entity implements policies and procedures over system inputs, including controls over completeness and accuracy, to result in products, services, and reporting to meet the entity’s objectives.",
          section: "PI 1.2",
        },
        {
          name: "The entity implements policies and procedures over system processing to result in products, services, and reporting to meet the entity’s objectives.",
          section: "PI 1.3",
        },
        {
          name: "The entity implements policies and procedures to make available or deliver output completely, accurately, and timely in accordance with specifications to meet the entity’s objectives.",
          section: "PI 1.4",
        },
        {
          name: "The entity implements policies and procedures to store inputs, items in processing, and outputs completely, accurately, and timely in accordance with system specifications to meet the entity’s objectives.",
          section: "PI 1.5",
        },
      ],
    },
    {
      name: "Privacy Criteria Related to Notice and Communication of Objectives Related to Privacy",
      section: "P 1.0",
      optional: true,
      requirements: [
        {
          name: "The entity provides notice to data subjects about its privacy practices to meet the entity’s objectives related to privacy. The notice is updated and communicated to data subjects in a timely manner for changes to the entity’s privacy practices, including changes in the use of personal information, to meet the entity’s objectives related to privacy.",
          section: "P 1.1",
        },
        {
          name: "The entity's privacy commitments are communicated to external users, as appropriate, and those commitments and the associated system requirements are communicated to internal users to enable them to carry out their responsibilities.",
          section: "P 1.2",
        },
      ],
    },
    {
      name: "Privacy Criteria Related to Choice and Consent",
      section: "P 2.0",
      optional: true,
      requirements: [
        {
          name: "The entity communicates choices available regarding the collection, use, retention, disclosure, and disposal of personal information to the data subjects and the consequences, if any, of each choice. Explicit consent for the collection, use, retention, disclosure, and disposal of personal information is obtained from data subjects or other authorized persons, if required. Such consent is obtained only for the intended purpose of the information to meet the entity’s objectives related to privacy. The entity’s basis for determining implicit consent for the collection, use, retention, disclosure, and disposal of personal information is documented.",
          section: "P 2.1",
        },
      ],
    },
    {
      name: "Privacy Criteria Related to Collection",
      section: "P 3.0",
      optional: true,
      requirements: [
        {
          name: "Personal information is collected consistent with the entity’s objectives related to privacy.",
          section: "P 3.1",
        },
        {
          name: "For information requiring explicit consent, the entity communicates the need for such consent, as well as the consequences of a failure to provide consent for the request for personal information, and obtains the consent prior to the collection of the information to meet the entity’s objectives related to privacy.",
          section: "P 3.2",
        },
      ],
    },
    {
      name: "Privacy Criteria Related to Use, Retention, and Disposal",
      section: "P 4.0",
      optional: true,
      requirements: [
        {
          name: "The entity limits the use of personal information to the purposes identified in the entity’s objectives related to privacy.",
          section: "P 4.1",
        },
        {
          name: "The entity retains personal information consistent with the entity’s objectives related to privacy.",
          section: "P 4.2",
        },
        {
          name: "The entity securely disposes of personal information to meet the entity’s objectives related to privacy.",
          section: "P 4.3",
        },
      ],
    },
    {
      name: "Privacy Criteria Related to Access",
      section: "P 5.0",
      optional: true,
      requirements: [
        {
          name: "The entity grants identified and authenticated data subjects the ability to access their stored personal information for review and, upon request, provides physical or electronic copies of that information to data subjects to meet the entity’s objectives related to privacy. If access is denied, data subjects are informed of the denial and reason for such denial, as required, to meet the entity’s objectives related to privacy.",
          section: "P 5.1",
        },
        {
          name: "The entity corrects, amends, or appends personal information based on information provided by data subjects and communicates such information to third parties, as committed or required, to meet the entity’s objectives related to privacy. If a request for correction is denied, data subjects are informed of the denial and reason for such denial to meet the entity’s objectives related to privacy.",
          section: "P 5.2",
        },
      ],
    },
    {
      name: "Privacy Criteria Related to Disclosure and Notification",
      section: "P 6.0",
      optional: true,
      requirements: [
        {
          name: "The entity discloses personal information to third parties with the explicit consent of data subjects, and such consent is obtained prior to disclosure to meet the entity’s objectives related to privacy.",
          section: "P 6.1",
        },
        {
          name: "The entity creates and retains a complete, accurate, and timely record of authorized disclosures of personal information to meet the entity’s objectives related to privacy.",
          section: "P 6.2",
        },
        {
          name: "The entity creates and retains a complete, accurate, and timely record of detected or reported unauthorized disclosures (including breaches) of personal information to meet the entity’s objectives related to privacy.",
          section: "P 6.3",
        },
        {
          name: "The entity obtains privacy commitments from vendors and other third parties who have access to personal information to meet the entity’s objectives related to privacy. The entity assesses those parties’ compliance on a periodic and as-needed basis and takes corrective action, if necessary.",
          section: "P 6.4",
        },
        {
          name: "The entity obtains commitments from vendors and other third parties with access to personal information to notify the entity in the event of actual or suspected unauthorized disclosures of personal information. Such notifications are reported to appropriate personnel and acted on in accordance with established incident response procedures to meet the entity’s objectives related to privacy.",
          section: "P 6.5",
        },
        {
          name: "The entity provides notification of breaches and incidents to affected data subjects, regulators, and others to meet the entity’s objectives related to privacy.",
          section: "P 6.6",
        },
        {
          name: "The entity provides data subjects with an accounting of the personal information held and disclosure of the data subjects’ personal information, upon the data subjects’ request, to meet the entity’s objectives related to privacy.",
          section: "P 6.7",
        },
      ],
    },
    {
      name: "Privacy Criteria Related to Quality",
      section: "P 7.0",
      optional: true,
      requirements: [
        {
          name: "The entity collects and maintains accurate, up-to-date, complete, and relevant personal information to meet the entity’s objectives related to privacy.",
          section: "P 7.1",
        },
      ],
    },
    {
      name: "Privacy Criteria Related to Monitoring and Enforcement",
      section: "P 8.0",
      optional: true,
      requirements: [
        {
          name: "The entity implements a process for receiving, addressing, resolving, and communicating the resolution of inquiries, complaints, and disputes from data subjects and others and periodically monitors compliance to meet the entity’s objectives related to privacy. Corrections and other necessary actions related to identified deficiencies are made or taken in a timely manner.",
          section: "P 8.1",
        },
      ],
    },
  ],
};
