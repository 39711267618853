import { ALLOWED_RENDER_MIME_TYPES } from "common/base/types/helpers";
import { VIDEO_NAME_TO_S3_KEY } from "common/constants/onboarding";

import type { IQuestionSchema } from "../../forms/interfaces";

export const SecurityTrainingInfo: IQuestionSchema = {
  id: "securityTraining",
  questions: [
    {
      accept: ALLOWED_RENDER_MIME_TYPES,
      helper: "",
      id: "security_training_screenshot-2018_05_29",
      label: "Screenshot upload",
      name: "screenshot",
      required: true,
      type: "File",
    },
  ],
  version: "2018-09-03",
};

export const GdprSecurityTrainingInfo: IQuestionSchema = {
  id: "gdprSecurityTraining",
  questions: [
    {
      accept: ALLOWED_RENDER_MIME_TYPES,
      helper: "",
      id: "security_training_screenshot_gdpr-2021_09_22",
      label: "Screenshot upload",
      name: "screenshot",
      required: true,
      type: "File",
    },
  ],
  version: "2021-09-22",
};

export const GdprGeneralSecurityTrainingInfo: IQuestionSchema = {
  id: "gdprGeneralSecurityTraining",
  questions: [
    {
      id: "security_training_video_gdpr-2021_09_22",
      label: "",
      name: "video1",
      type: "Video",
      urlKey: VIDEO_NAME_TO_S3_KEY.GDPR_GENERAL,
      required: true,
    },
  ],
  version: "2021-08-05",
};

export const PciSecurityTrainingInfo: IQuestionSchema = {
  id: "pciSecurityTraining",
  questions: [
    {
      accept: ALLOWED_RENDER_MIME_TYPES,
      helper: "",
      id: "security_training_screenshot_pci-2021-09-19",
      label: "Screenshot upload",
      name: "screenshot",
      required: true,
      type: "File",
    },
  ],
  version: "2021-09-19",
};

export const GeneralSecurityTrainingInfo: IQuestionSchema = {
  id: "generalSecurityTraining",
  questions: [
    {
      id: "security_training_video-2021_06_24",
      label: "",
      name: "video1",
      type: "Video",
      urlKey: VIDEO_NAME_TO_S3_KEY.GENERAL,
      required: true,
    },
  ],
  version: "2018-09-03",
};

export const HipaaSecurityTrainingInfo: IQuestionSchema = {
  id: "hipaaSecurityTraining",
  questions: [
    {
      accept: ALLOWED_RENDER_MIME_TYPES,
      helper: "",
      id: "security_training_screenshot_hipaa-2021_07_31",
      label: "Screenshot upload",
      name: "screenshot",
      required: true,
      type: "File",
    },
  ],
  version: "2021-08-05",
};

export const HipaaGeneralSecurityTrainingInfo: IQuestionSchema = {
  id: "hipaaGeneralSecurityTraining",
  questions: [
    {
      id: "security_training_video_hipaa-2021_07_29",
      label: "",
      name: "video1",
      type: "Video",
      urlKey: VIDEO_NAME_TO_S3_KEY.HIPAA_GENERAL,
      required: true,
    },
  ],
  version: "2021-08-05",
};
