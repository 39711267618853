import type { Maybe } from "common/base/types/maybe";
import { isSome, nothing } from "common/base/types/maybe";
import moment from "moment";
import React from "react";
import styled from "styled-components";

import { TableStylesDeprecated } from "../../../../alpaca/base/deprecated";
import type { FetchUnmonitoredCloudInstancesQuery } from "../../../../gen/components";
import { DataTable } from "../../components/data-table";
import { Caption, Heading } from "../common/components";
import {
  accountForMachine,
  primaryIdForMachine,
  privateIpForMachine,
  secondaryIdForMachine,
} from "../utils";

interface IProps {
  servers: Array<
    NonNullable<
      FetchUnmonitoredCloudInstancesQuery["organization"]
    >["cloudInstances"]["edges"][number]["node"]
  >;
  emptyState: JSX.Element;
}

const cloudProviderNameFromTypename = (typename: Maybe<string>): string => {
  switch (typename) {
    case "SpecificEC2InstanceResource":
      return "AWS";
    case "SpecificGCPComputeInstanceResource":
      return "GCP";
    case "SpecificAzureVirtualMachineResource":
    case "SpecificAzureScaleSetVirtualMachine":
      return "Azure";
    default:
      return "Other";
  }
};

export const BasicServerTable: React.FC<IProps> = ({ servers, emptyState }) => (
  <Container>
    <DataTable
      data={servers}
      columnOrder={["server", "ipaddress", "cloudaccount", "lastpingtime"]}
      columnWidths={["300px", "200px", "200px", "200px"]}
      createRow={s => {
        const cloudProvider = cloudProviderNameFromTypename(s?.__typename);
        const primaryId = primaryIdForMachine({ cloudProviderData: s });
        const secondaryId = secondaryIdForMachine({ cloudProviderData: s });
        const lastPing =
          s?.__typename === "SpecificEC2InstanceResource" ||
          s?.__typename === "SpecificGCPComputeInstanceResource" ||
          s?.__typename === "SpecificAzureVirtualMachineResource"
            ? s?.osquery?.data.lastPing
            : nothing;
        const ipAddress = privateIpForMachine({ cloudProviderData: s });
        const account = accountForMachine({ cloudProviderData: s });

        return {
          server: (
            <Flex>
              <div style={{ maxWidth: "100%" }}>
                <Heading noWrap>{primaryId}</Heading>
              </div>
              {isSome(secondaryId) ? (
                <Caption noWrap>&nbsp;({secondaryId})</Caption>
              ) : null}
            </Flex>
          ),
          ipaddress: <Primary>{ipAddress}</Primary>,
          cloudaccount: (
            <div>
              <Primary>{cloudProvider}</Primary>
              {isSome(account) ? (
                <Secondary>&nbsp;({account})</Secondary>
              ) : null}
            </div>
          ),
          lastpingtime: isSome(lastPing) ? (
            <Primary>{moment.unix(parseInt(lastPing, 10)).fromNow()}</Primary>
          ) : (
            <Caption>-</Caption>
          ),
        };
      }}
      emptyDefault={emptyState}
      header={{
        server: "Server",
        ipaddress: "IP address",
        cloudaccount: "Cloud Account",
        lastpingtime: "Last scan time",
      }}
    />
  </Container>
);

const Flex = styled.div`
  display: flex;
  align-items: baseline;
`;

const Container = styled.div`
  ${TableStylesDeprecated}
  td div {
    padding: 0;
  }
`;

const Primary = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #333333;
`;

const Secondary = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #828282;
`;
