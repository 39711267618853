{
  "standardInfo": {
    "standard": "pciSaqA"
  },
  "controls": [
    {
      "id": "2.1.a",
      "name": "Vendor defaults changed before installation",
      "description": "Are vendor-supplied defaults always changed before installing a system on the network?\n\nThis applies to ALL default passwords, including but not limited to those used by operating systems, software that provides security services, application and system accounts, point-of-sale (POS) terminals, payment applications, Simple Network Management Protocol (SNMP) community strings, etc.).",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "vendor-defaults-disabled"
        }
      ]
    },
    {
      "id": "2.1.b",
      "name": "Unnecessary default accounts removed",
      "description": "Are unnecessary default accounts removed or disabled before installing a system on the network?",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "vendor-defaults-disabled"
        }
      ]
    },
    {
      "id": "8.1.1",
      "name": "Users assigned unique ID",
      "description": "Are all users assigned a unique ID before allowing them to access system components or cardholder data?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "8.1.3",
      "name": "Terminated user access removed",
      "description": "Is access for any terminated users immediately deactivated or removed?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "access-removed-infra"
        },
        {
          "id": "access-removed-versioncontrol"
        },
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        },
        {
          "id": "asana-account-access-removed-on-termination"
        },
        {
          "id": "aws-account-access-removed-on-termination"
        },
        {
          "id": "azuredevops-account-access-removed-on-termination"
        },
        {
          "id": "bitbucket-account-access-removed-on-termination"
        },
        {
          "id": "clickup-account-access-removed-on-termination"
        },
        {
          "id": "clubhouse-account-access-removed-on-termination"
        },
        {
          "id": "datadog-account-access-removed-on-termination"
        },
        {
          "id": "github-account-access-removed-on-termination"
        },
        {
          "id": "gitlab-account-access-removed-on-termination"
        },
        {
          "id": "heroku-account-access-removed-on-termination"
        },
        {
          "id": "jamf-account-access-removed-on-termination"
        },
        {
          "id": "jira-account-access-removed-on-termination"
        },
        {
          "id": "knowbe4-account-access-removed-on-termination"
        },
        {
          "id": "linear-account-access-removed-on-termination"
        },
        {
          "id": "mongoatlas-account-access-removed-on-termination"
        },
        {
          "id": "pivotaltracker-account-access-removed-on-termination"
        },
        {
          "id": "slack-account-access-removed-on-termination"
        },
        {
          "id": "snyk-account-access-removed-on-termination"
        },
        {
          "id": "trello-account-access-removed-on-termination"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "8.2",
      "name": "Authentication methods",
      "description": "In addition to assigning a unique ID, is one or more of the following methods employed to authenticate all users?\n• Something you know, such as a password or passphrase\n• Something you have, such as a token device or smart card\n• Something you are, such as a biometric",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "internal-password-policy-config"
        },
        {
          "id": "internal-password-policy-config-heroku"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "8.5",
      "name": "Shared account methods secure",
      "description": "Are group, shared, or generic accounts, passwords, or other authentication methods prohibited as follows: \n• Generic user IDs and accounts are disabled or removed;\n• Shared user IDs for system administration activities and other critical functions do not exist; and\n• Shared and generic user IDs are not used to administer any system components?",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-ep-exists"
        },
        {
          "id": "approved-pci-dss-saq-a-exists"
        },
        {
          "id": "approved-pci-dss-saq-d-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "no-shared-generic-accounts"
        }
      ]
    },
    {
      "id": "9.5",
      "name": "Media physically secured",
      "description": "Are all media physically secured (including but not limited to computers, removable electronic media, paper receipts, paper reports, and faxes)?\n\nFor purposes of Requirement 9, “media” refers to all paper and electronic media containing cardholder data.",
      "categories": ["Physical"],
      "tests": [
        {
          "id": "vendors-attestation-of-compliance-documents"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "physical-security-covered-by-aoc"
        }
      ]
    },
    {
      "id": "9.6.a",
      "name": "Media distribution controlled",
      "description": "Is strict control maintained over the internal or external distribution of any kind of media?",
      "categories": ["Physical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-media-handled-securely"
        }
      ]
    },
    {
      "id": "9.6.1",
      "name": "Media classified",
      "description": "Is media classified so the sensitivity of the data can be determined?",
      "categories": ["Physical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-media-handled-securely"
        }
      ]
    },
    {
      "id": "9.6.2",
      "name": "Media securely delivered",
      "description": "Is media sent by secured courier or other delivery method that can be accurately tracked?",
      "categories": ["Physical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-media-handled-securely"
        }
      ]
    },
    {
      "id": "9.6.3",
      "name": "Media distribution authorized",
      "description": "Is management approval obtained prior to moving the media (especially when media is distributed to individuals)?",
      "categories": ["Physical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-media-handled-securely"
        }
      ]
    },
    {
      "id": "9.7",
      "name": "Media storage controls exist",
      "description": "Is strict control maintained over the storage and accessibility of media?",
      "categories": ["Physical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-media-handled-securely"
        }
      ]
    },
    {
      "id": "9.8.a",
      "name": "Stored media destroyed if no longer needed",
      "description": "Is all media destroyed when it is no longer needed for business or legal reasons?",
      "categories": ["Physical"],
      "tests": [
        {
          "id": "vendors-attestation-of-compliance-documents"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pci-media-handled-securely"
        }
      ]
    },
    {
      "id": "9.8.1.a",
      "name": "Hardcopy materials securely destroyed",
      "description": "Are hardcopy materials cross-cut shredded, incinerated, or pulped so that cardholder data cannot be reconstructed?",
      "categories": ["Physical"],
      "tests": [
        {
          "id": "vendors-attestation-of-compliance-documents"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pci-media-handled-securely"
        }
      ]
    },
    {
      "id": "12.8.1",
      "name": "List of service providers",
      "description": "Is a list of service providers maintained, including a description of the service(s) provided?",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-service-provider-inventory-exists"
        }
      ]
    },
    {
      "id": "12.8.2",
      "name": "Service providers' responsibility acknowledgement",
      "description": "Is a written agreement maintained that includes an acknowledgement that the service providers are responsible for the security of cardholder data the service providers possess or otherwise store, process, or transmit on behalf of the customer, or to the extent that they could impact the security of the customer’s cardholder data environment?\n\nNote: The exact wording of an acknowledgement will depend on the agreement between the two parties, the details of the service being provided, and the responsibilities assigned to each party. The acknowledgement does not have to include the exact wording provided in this requirement.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "vendors-attestation-of-compliance-documents"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "12.8.3",
      "name": "Service provider engagement process exists",
      "description": "Is there an established process for engaging service providers, including proper due diligence prior to engagement?",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-service-provider-inventory-exists"
        }
      ]
    },
    {
      "id": "12.8.4",
      "name": "Program exists to monitor service provider's PCI compliance status",
      "description": "Is a program maintained to monitor service providers’ PCI DSS compliance status at least annually?",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-service-provider-inventory-exists"
        }
      ]
    },
    {
      "id": "12.8.5",
      "name": "Separation of responsibilities",
      "description": "Is information maintained about which PCI DSS requirements are managed by each service provider, and which are managed by the entity?",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "pci-service-provider-inventory-exists"
        }
      ]
    },
    {
      "id": "12.10.1.a",
      "name": "Incident response plan exists",
      "description": "Has an incident response plan been created to be implemented in the event of system breach?",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "incident-response-plan-policies"
        }
      ],
      "manualEvidenceRequests": []
    }
  ],
  "sections": [
    {
      "id": "2.1.a",
      "controls": [
        {
          "id": "2.1.a"
        }
      ]
    },
    {
      "id": "2.1.b",
      "controls": [
        {
          "id": "2.1.b"
        }
      ]
    },
    {
      "id": "8.1.1",
      "controls": [
        {
          "id": "8.1.1"
        }
      ]
    },
    {
      "id": "8.1.3",
      "controls": [
        {
          "id": "8.1.3"
        }
      ]
    },
    {
      "id": "8.2",
      "controls": [
        {
          "id": "8.2"
        }
      ]
    },
    {
      "id": "8.5",
      "controls": [
        {
          "id": "8.5"
        }
      ]
    },
    {
      "id": "9.5",
      "controls": [
        {
          "id": "9.5"
        }
      ]
    },
    {
      "id": "9.6.1",
      "controls": [
        {
          "id": "9.6.1"
        }
      ]
    },
    {
      "id": "9.6.2",
      "controls": [
        {
          "id": "9.6.2"
        }
      ]
    },
    {
      "id": "9.6.3",
      "controls": [
        {
          "id": "9.6.3"
        }
      ]
    },
    {
      "id": "9.6.a",
      "controls": [
        {
          "id": "9.6.a"
        }
      ]
    },
    {
      "id": "9.7",
      "controls": [
        {
          "id": "9.7"
        }
      ]
    },
    {
      "id": "9.8.1.a",
      "controls": [
        {
          "id": "9.8.1.a"
        }
      ]
    },
    {
      "id": "9.8.a",
      "controls": [
        {
          "id": "9.8.a"
        }
      ]
    },
    {
      "id": "12.8.1",
      "controls": [
        {
          "id": "12.8.1"
        }
      ]
    },
    {
      "id": "12.8.2",
      "controls": [
        {
          "id": "12.8.2"
        }
      ]
    },
    {
      "id": "12.8.3",
      "controls": [
        {
          "id": "12.8.3"
        }
      ]
    },
    {
      "id": "12.8.4",
      "controls": [
        {
          "id": "12.8.4"
        }
      ]
    },
    {
      "id": "12.8.5",
      "controls": [
        {
          "id": "12.8.5"
        }
      ]
    },
    {
      "id": "12.10.1.a",
      "controls": [
        {
          "id": "12.10.1.a"
        }
      ]
    }
  ]
}
