import "./inventory-card.scss";

import {
  Card,
  Checkbox,
  EditableText,
  Elevation,
  FormGroup,
  Icon,
  Spinner,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import { replaceUTF8MultiByteHexEscapes } from "common/base/utils";
import type { ReservedVantaAttribute } from "common/utils/vantaAttributes";
import {
  ReservedVantaAttributes,
  VantaAttributeSentinelValues,
} from "common/utils/vantaAttributes";
import gql from "graphql-tag";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { Tooltip } from "../../../alpaca/components";
import { LogError } from "../../../errors";
import { UserSelector } from "../../form-controls/user-selector";

interface IProps {
  labels?: Maybe<JSX.Element[]>;
  locked?: Maybe<boolean>;
  type: string;
  uid: string;
  name: string;
  canSetName?: Maybe<boolean>;
  setName?: Maybe<(name: string) => Promise<void>>;
  description?: Maybe<string>;
  owner?: Maybe<string>; // may be mongoId or email
  lockOwner?: Maybe<boolean>; // overrides `locked` for the owner field.
  setOwner?: Maybe<(ownerId: string) => Promise<void>>;
  canContainUserData: boolean;
  containsUserData?: Maybe<boolean>;
  containsEPHI?: Maybe<boolean>;
  userDataDescription?: Maybe<string>;
  setVantaAttribute: (
    key: ReservedVantaAttribute,
    value: Maybe<string>
  ) => Promise<void>;
}

const Locked: React.FC = () => {
  const whyCantIEdit = (
    <div>
      <p>
        This resource’s metadata is managed through your cloud provider
        configuration.
      </p>
      <p>To modify its properties, update the bulk tags.</p>
    </div>
  );
  return (
    <div className="inventory-list-card-locked">
      <Tooltip content={whyCantIEdit}>
        <Icon icon={IconNames.DISABLE} color="grey" />
      </Tooltip>
    </div>
  );
};

const ItemName: React.FC<{
  name: string;
  uid: string;
  canSetName?: Maybe<boolean>;
  onChangeName: (name: string) => void;
}> = props => {
  const [nameText, setNameText] = useState(props.name);
  if (props.canSetName) {
    return (
      <EditableText
        onConfirm={props.onChangeName}
        multiline={false}
        placeholder="e.g. Netgear R7000P router ..."
        value={nameText}
        onChange={value => {
          setNameText(value);
        }}
      />
    );
  }
  return (
    <div>
      {replaceUTF8MultiByteHexEscapes(props.name)}
      <SmallCaption>{props.uid !== props.name ? props.uid : null}</SmallCaption>
    </div>
  );
};

export const SmallCaption = styled.div`
  font-size: 11px;
  line-height: 13px;
  color: #828282;
`;

export const InventoryCard: React.FC<IProps> = props => {
  const [description, setDescription] = useState(props.description);
  const [loading, setLoading] = useState(false);
  const [userDataStored, setUserDataStored] = useState(
    props.userDataDescription
  );

  useEffect(() => {
    setDescription(props.description);
    setUserDataStored(props.userDataDescription);
  }, [props.description, props.userDataDescription]);

  const setField = async (vantaAttribute: {
    key: ReservedVantaAttribute;
    value: Maybe<string>;
  }) => {
    setLoading(true);
    try {
      await props.setVantaAttribute(vantaAttribute.key, vantaAttribute.value);
    } catch (e) {
      LogError(e);
    }
    setLoading(false);
  };

  if (props.canSetName && !isSome(props.setName)) {
    LogError(
      new Error("setName function must be defined if canSetName is true")
    );
  }
  const nameElem = (
    <FormGroup label={props.type === "Other" ? "Name" : props.type}>
      <ItemName
        name={props.name}
        uid={props.uid}
        canSetName={props.canSetName}
        onChangeName={async name => {
          setLoading(true);
          await props.setName?.(name);
          setLoading(false);
        }}
      />
    </FormGroup>
  );

  const ownerElem = (
    <FormGroup helperText="The person responsible for this item" label="Owner">
      <UserSelector
        undeletable={true}
        disabled={props.locked || props.lockOwner}
        multiselect={false}
        onSelect={async userIds => {
          const userId = userIds![0]!; // must be defined since undeletable=true
          if (props.setOwner) {
            setLoading(true);
            await props.setOwner(userId);
            setLoading(false);
          } else {
            await setField({
              key: ReservedVantaAttributes.ownerId.key,
              value: userId,
            });
          }
        }}
        selected={props.owner}
      />
    </FormGroup>
  );

  const descriptionElem = (
    <FormGroup
      helperText="What this item does in layman's terms"
      label="Description"
    >
      <EditableText
        disabled={props.locked ?? undefined}
        onConfirm={async () =>
          setField({
            key: ReservedVantaAttributes.description.key,
            value: description,
          })
        }
        multiline={true}
        placeholder="e.g. corporate homepage, employee laptop, application logs ..."
        value={description ?? undefined}
        onChange={setDescription}
      />
    </FormGroup>
  );

  const userDataElem = (
    <FormGroup label="Data Stored">
      <Checkbox
        disabled={props.locked ?? false}
        checked={props.containsUserData ?? false}
        onChange={async () => {
          const togglingOff = props.containsUserData;
          await setField({
            key: ReservedVantaAttributes.containsUserData.key,
            value: togglingOff ? null : VantaAttributeSentinelValues.TRUE,
          });
          if (togglingOff) {
            // if setting contains user data to false, also set contains EPHI to false
            await setField({
              key: ReservedVantaAttributes.containsEPHI.key,
              value: null,
            });
          }
        }}
      >
        Contains user data
      </Checkbox>
      <Checkbox
        disabled={props.locked ?? false}
        checked={props.containsEPHI ?? false}
        onChange={async () => {
          const togglingOn = !props.containsEPHI;
          await setField({
            key: ReservedVantaAttributes.containsEPHI.key,
            value: togglingOn ? VantaAttributeSentinelValues.TRUE : null,
          });
          if (togglingOn) {
            // if setting contains EPHI to true, also set contains user data to true
            await setField({
              key: ReservedVantaAttributes.containsUserData.key,
              value: VantaAttributeSentinelValues.TRUE,
            });
          }
        }}
      >
        Contains ePHI
      </Checkbox>
      <EditableText
        onConfirm={async () => {
          const trimmed = userDataStored?.trim();
          if (trimmed !== props.userDataDescription) {
            await setField({
              key: ReservedVantaAttributes.userDataStored.key,
              value: trimmed,
            });
          }
        }}
        disabled={props.locked ?? false}
        multiline={true}
        placeholder="what data is stored (e.g passwords, phone numbers, user-generated documents ...)"
        onChange={setUserDataStored}
        value={userDataStored ?? ""}
      />
    </FormGroup>
  );

  const labels = props.labels?.map((t, i) => (
    <div key={i} className="inventory-list-card-float">
      {t}
    </div>
  ));
  return (
    <Card
      className="inventory-list-card"
      interactive={false}
      elevation={Elevation.ZERO}
    >
      {loading ? (
        <div className="inventory-list-card-overlay">
          <Spinner />
        </div>
      ) : undefined}
      {props.locked ? <Locked /> : null}
      <div>
        {labels}
        {nameElem}
        <hr />
        {ownerElem}
        <hr />
        {descriptionElem}
        {props.canContainUserData ? (
          <>
            <hr /> {userDataElem}
          </>
        ) : null}
        {props.children}
      </div>
    </Card>
  );
};

gql`
  mutation setInventoryResourceVantaAttribute(
    $specificResourceKind: SpecificResource!
    $resourceMongoId: String!
    $key: String!
    $value: String
  ) {
    setResourceVantaAttribute(
      specificResourceKind: $specificResourceKind
      resourceMongoId: $resourceMongoId
      key: $key
      value: $value
    ) {
      key
      value
      managedExternally
    }
  }
`;

gql`
  mutation setInventoryOsqueryVantaAttribute(
    $osqueryMongoId: String!
    $key: String!
    $value: String
  ) {
    setOsqueryVantaAttribute(
      osqueryMongoId: $osqueryMongoId
      key: $key
      value: $value
    ) {
      key
      value
      managedExternally
    }
  }
`;

gql`
  mutation disableOsqueryEndpointById($domainId: ID!, $id: String!) {
    disableOsqueriesByID(domainId: $domainId, ids: [$id])
  }
`;

gql`
  mutation setLaptopOwner($uuid: String!, $userId: ID!) {
    registerDevice(uuid: $uuid, userId: $userId)
  }
`;
