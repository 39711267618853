import type {
  IAnchorButtonProps as IBPAnchorButtonProps,
  IButtonProps as IBPButtonProps,
  IRefObject,
} from "@blueprintjs/core";
import {
  AnchorButton as BPAnchorButton,
  Button as BPButton,
  Intent,
} from "@blueprintjs/core";
import classnames from "classnames";
import { isSome } from "common/base/types/maybe";
import React from "react";
import styled from "styled-components";

import { Tooltip } from "../tooltip/tooltip";
import { BUTTON_CLASS_STYLES, ButtonClasses } from "./styles";

/**
 * The two components defined here contain styled Blueprint Buttons with an optional
 * "secondary" attribute that will provide secondary styling (white text on gray background)
 * as defined in the Alpaca Figma.
 *
 * If you use the "secondary" attribute, do not assign an intent to the button!
 *
 * Defining the "tooltipContent" attribute will wrap the button in an explanatory
 * tooltip if disabled
 */

interface IAdditionalProps {
  // eslint-disable-next-line vanta/optional-always-maybe, vanta/prefer-maybe
  secondary?: boolean | undefined;

  /**
   * Text to display in a tooltip if the button is disabled
   */
  // eslint-disable-next-line vanta/optional-always-maybe, vanta/prefer-maybe
  tooltipContent?: string | JSX.Element | undefined;
}

function getClassNameFromProps(props: IButtonProps | IAnchorButtonProps) {
  return classnames(props.className, ButtonClasses.BUTTON, {
    [ButtonClasses.LARGE]: props.large,
    [ButtonClasses.SMALL]: props.small,
    [ButtonClasses.PRIMARY]: props.intent === Intent.PRIMARY,
    [ButtonClasses.DANGER]: props.intent === Intent.DANGER,
    [ButtonClasses.SECONDARY]: props.secondary,
    [ButtonClasses.OUTLINED]: props.outlined,
    [ButtonClasses.DISABLED]: props.disabled,
  });
}

export type IButtonProps = IBPButtonProps &
  IAdditionalProps &
  React.ButtonHTMLAttributes<HTMLButtonElement>;

export const Button: React.FC<IButtonProps> = ({
  secondary,
  tooltipContent,
  ...props
}) => {
  if (props.disabled && isSome(tooltipContent)) {
    // Tooltip buttons use the AnchorButton element, so we need to re-cast the
    // ref. (This is not currently used anywhere)
    const newProps: IBPAnchorButtonProps = {
      ...props,
      elementRef: props.elementRef as unknown as IRefObject<HTMLAnchorElement>,
    };
    return <ButtonWithTooltip {...newProps} tooltipContent={tooltipContent} />;
  } else {
    return (
      <StyledButton
        {...props}
        className={getClassNameFromProps({ ...props, secondary })}
      />
    );
  }
};

const StyledButton = styled(BPButton)`
  ${BUTTON_CLASS_STYLES}
`;

export type IAnchorButtonProps = IBPAnchorButtonProps &
  IAdditionalProps &
  React.AnchorHTMLAttributes<HTMLAnchorElement>;

export const AnchorButton: React.FC<IAnchorButtonProps> = ({
  secondary,
  tooltipContent,
  ...props
}) => {
  if (props.disabled && isSome(tooltipContent)) {
    return <ButtonWithTooltip {...props} tooltipContent={tooltipContent} />;
  } else {
    return (
      <StyledAnchorButton {...props} className={getClassNameFromProps(props)} />
    );
  }
};

const StyledAnchorButton = styled(BPAnchorButton)`
  ${BUTTON_CLASS_STYLES}
`;

/**
 * Using a regular Blueprint button in a tooltip results in unreliable hover effect,
 * so we alway user an AnchorButton here.
 */
const ButtonWithTooltip: React.FC<IAnchorButtonProps & IAdditionalProps> = ({
  tooltipContent,
  ...props
}) => (
  <Tooltip
    content={tooltipContent ?? ""}
    disabled={!isSome(tooltipContent)}
    rootBoundary="document"
    interactionKind="hover"
    hoverCloseDelay={100}
  >
    <AnchorButton {...props} />
  </Tooltip>
);
