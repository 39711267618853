import { Button, Classes, Dialog, FormGroup, Intent } from "@blueprintjs/core";
import gql from "graphql-tag";
import React, { useContext } from "react";

import {
  useAssumeDomainAdminMutation,
  useUnassumeUserMutationMutation,
} from "../../gen/components";
import { AllDomainSuggest } from "../helpers/all-domain-suggest";
import { UserContext } from "../pages/user-context";

interface IProps {
  isOpen: boolean;
  onClose(): void;
}

export const AssumeAdminDialog: React.FC<IProps> = ({ isOpen, onClose }) => (
  <Dialog isOpen={isOpen} onClose={onClose}>
    <DialogBody onClose={onClose} />
  </Dialog>
);

gql`
  mutation AssumeDomainAdmin($domainId: ID!) {
    assumeDomainAdmin(domainId: $domainId)
  }
`;

interface IInternalProps {
  onClose(): void;
}

const DialogBody: React.FC<IInternalProps> = ({ onClose }) => {
  const [assumeDomainAdmin] = useAssumeDomainAdminMutation();
  const [unassumeUser] = useUnassumeUserMutationMutation();
  const { user } = useContext(UserContext);

  const onSelect = async (domainId: string) => {
    if (Boolean(user?.isAssumedUser)) {
      await unassumeUser();
    }
    await assumeDomainAdmin({ variables: { domainId } });
    location.href = "/";
  };
  return (
    <div>
      <div className={Classes.DIALOG_HEADER}>Select domain to assume</div>

      <div className={Classes.DIALOG_BODY}>
        <FormGroup>
          <AllDomainSuggest onSelect={onSelect} requestFocus />
        </FormGroup>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button intent={Intent.DANGER} onClick={onClose}>
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};
