import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import React, { useContext } from "react";
import styled, { css } from "styled-components";

import { VANTA_COLORS } from "../../alpaca/base/colors";
import { UserContext } from "../pages/user-context";
import { TopBar } from "./top-bar/top-bar";

const WIDE_ROUTES = new Set(["/alarms"]);

export const useCheckWideRoute = () => {
  const { user } = useContext(UserContext);

  // Auditor documents, viewed in their home page, is too large for the current grid
  const userIsAuditor =
    isSome(user?.auditorInfo) && !Boolean(user?.isAuditorAssumingUser);

  return WIDE_ROUTES.has(location.pathname) || userIsAuditor;
};

export const VantaChrome: React.FC = props => {
  const wide = useCheckWideRoute();

  return (
    <AppContainer wide={wide}>
      <TopBar wide={wide} />
      <div>{props.children}</div>
    </AppContainer>
  );
};

const AppContainer = styled.div<{ wide?: Maybe<boolean> }>`
  display: flex;
  flex-direction: column;
  min-width: fit-content;
  background-color: ${VANTA_COLORS.BACKGROUND_APP};
  ${({ wide }) => {
    if (wide) {
      return css`
        width: max-content;
        min-width: 100vw;
      `;
    } else {
      return "";
    }
  }}
`;
