import { Spinner } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { dropNothing } from "common/base/types/maybe";
import {
  specificResourceGraphQLTypeToEnumValue,
  SpecificResourceToInventoryType,
} from "common/utils/inventory";
import {
  deserializeVantaAttributes,
  hasExternallyManagedVantaAttributes,
} from "common/utils/vantaAttributes";
import gql from "graphql-tag";
import _ from "lodash";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import type { AutoscalesQuery } from "../../../../gen/components";
import {
  useAutoscalesQuery,
  useSetInventoryResourceVantaAttributeMutation,
} from "../../../../gen/components";
import { AutoscaleInstanceCollapse } from "../autoscale-instance-collapse";
import { InventoryCard } from "../inventory-card";
import { AccountTag, RegionTag, VantaAgentTag } from "../tags";
import {
  fetchMoreResources,
  getDebounceContext,
  getResourceFilterParams,
  invalidInventoryType,
  PAGE_SIZE,
  setResourceVantaAttributeFn,
} from "../utils";
import { SearchResultsSummary } from "./search-results-summary";
import type { IInventoryTabProps } from "./shared-interface";

export const Autoscales: React.FC<IInventoryTabProps> = ({ searchString }) => {
  const { data, fetchMore, loading } = useAutoscalesQuery({
    variables: {
      first: PAGE_SIZE,
      filterParams: getResourceFilterParams(searchString),
    },
    context: getDebounceContext("autoscale"),
  });

  if (loading) {
    return <Spinner />;
  }

  const agentInstanceIds = new Set(
    dropNothing(
      data?.organization.machines.edges.map(m => m.node.cloudProviderId) ?? []
    )
  );

  const cards = data?.organization.resources.edges?.map(item => (
    <AutoscaleCard
      key={item.node.id}
      item={item}
      domain={data.organization}
      agentInstanceIds={agentInstanceIds}
    />
  ));

  return (
    <>
      <SearchResultsSummary
        searchString={searchString}
        numberResults={data?.organization.resources?.totalCount ?? 0}
      />
      <InfiniteScroll
        className="inventory-list-card-group"
        dataLength={data?.organization.resources.edges.length ?? 0}
        next={async () => fetchMoreResources(data, fetchMore)}
        hasMore={data?.organization.resources.pageInfo.hasNextPage ?? false}
        loader={<Spinner />}
      >
        {cards}
      </InfiniteScroll>
    </>
  );
};

const AutoscaleCard: React.FC<{
  item: NonNullable<
    AutoscalesQuery["organization"]
  >["resources"]["edges"][number];
  domain: NonNullable<AutoscalesQuery["organization"]>;
  agentInstanceIds: Set<string>;
}> = ({ item, domain, agentInstanceIds }) => {
  const [setResourceVantaAttribute] =
    useSetInventoryResourceVantaAttributeMutation();

  const specificResourceKind = specificResourceGraphQLTypeToEnumValue(
    item.node.__typename
  );
  const type = SpecificResourceToInventoryType[specificResourceKind](
    item.node as any
  );
  const resourceMongoId = item.node.id;

  let labels;
  let uid;
  let maybeAutoscaleInstancesCollapse: Maybe<JSX.Element>;
  switch (item.node.__typename) {
    case "SpecificAutoScalingGroupResource": {
      const [instanceIdsWithAgent, instanceIdsWithoutAgent] = _.partition(
        item.node.instanceIds,
        i => agentInstanceIds.has(i)
      );
      labels = [
        <VantaAgentTag
          key="agent"
          active={instanceIdsWithoutAgent.length === 0}
        />,
        <AccountTag key="account" cloudAccountId={item.node.account} />,
        <RegionTag key="region" region={item.node.region} />,
      ];
      uid = item.node.name;
      maybeAutoscaleInstancesCollapse = (
        <AutoscaleInstanceCollapse
          instanceIdsWithAgent={instanceIdsWithAgent}
          instanceIdsWithoutAgent={instanceIdsWithoutAgent}
        />
      );
      break;
    }
    case "SpecificAzureVirtualMachineScaleSetResource": {
      labels = [<RegionTag key="region" region={item.node.location} />];
      uid = item.node.uniqueId;
      break;
    }
    default:
      return invalidInventoryType(item.node.__typename);
  }

  const vantaAttributes = deserializeVantaAttributes(
    item.node.vantaAttributes ?? []
  );
  const locked = hasExternallyManagedVantaAttributes(
    item.node.vantaAttributes ?? []
  );
  const name = item.node.name;
  const setVantaAttribute = setResourceVantaAttributeFn(
    setResourceVantaAttribute,
    specificResourceKind,
    resourceMongoId,
    item.node.__typename
  );
  return (
    <InventoryCard
      type={type}
      uid={uid}
      labels={labels}
      key={item.node.id}
      name={name}
      description={vantaAttributes.description}
      owner={vantaAttributes.ownerId}
      canContainUserData={false}
      locked={locked}
      setVantaAttribute={setVantaAttribute}
    >
      {maybeAutoscaleInstancesCollapse}
    </InventoryCard>
  );
};

gql`
  query autoscales($first: Int!, $after: String, $filterParams: filterParams) {
    organization {
      id
      resources(
        first: $first
        after: $after
        genericResourceType: ManagedInstanceGroup
        sortParams: { field: "createdAt", direction: -1 }
        filterParams: $filterParams
      ) {
        totalCount
        edges {
          node {
            id
            __typename
            uniqueId
            vantaAttributes {
              key
              value
              managedExternally
            }
            ... on SpecificAutoScalingGroupResource {
              instanceIds
              region
              account
              name
            }
            ... on SpecificAzureVirtualMachineScaleSetResource {
              subscriptionId
              location
              name
            }
          }
        }
        pageInfo {
          hasNextPage
          endCursor
          startCursor
          hasPreviousPage
        }
      }

      # to keep feature parity with the old autoscaling page, we need to load all
      # instanceIds on which we have osqueries. This is bad and annoying, but we should
      # discuss with product how we actually want this to work.
      machines(activeOnly: true, serversOnly: true, first: 2000) {
        edges {
          node {
            id
            cloudProviderId
          }
        }
      }
    }
  }
`;
