import { isSome } from "common/base/types/maybe";
import type {
  IRiskCategory,
  IRiskItem,
} from "common/schemas/riskRegister/risk-register-content";
import React from "react";

import { StepNavMenu } from "../../master-detail-view/step-nav-menu";
import type { RiskDismissal, RiskMitigationTask } from "./queries-and-types";
import type { RiskFormLocation } from "./risk-form-location";

interface IProps {
  riskItems: IRiskItem[];
  category: IRiskCategory;
  formLocation: RiskFormLocation;
  dismissals: RiskDismissal[];
  tasks: RiskMitigationTask[];
}

export const RiskCategoryNavList: React.FC<IProps> = ({
  category,
  dismissals,
  formLocation,
  riskItems,
  tasks,
}) => (
  <StepNavMenu
    steps={category.risks
      .map((risk, index) => {
        const isCurrentRisk = index === formLocation.getStepIndex();
        const isDismissed = dismissals.some(d => d.riskId === risk.id);
        const items = riskItems.filter(riskItem => riskItem.riskId === risk.id);
        return {
          text: risk.shortHeading,
          substeps: isCurrentRisk
            ? items.map(item => {
                return {
                  text: item.itemName,
                  completed: isSome(item.likelihood) && isSome(item.impact),
                };
              })
            : null,
          completed:
            isDismissed ||
            (items.length > 0 &&
              items.every(
                item => isSome(item.likelihood) && isSome(item.impact)
              )),
        };
      })
      .concat({
        text: "Tasks",
        substeps: null,
        completed: tasks.some(t => t.riskCategoryId === category.id),
      })}
    clickHandler={(stepIndex, substepIndex) =>
      formLocation.navigateToLocation(stepIndex, substepIndex)
    }
    activeStepIndex={formLocation.getStepIndex()}
    activeSubstepIndex={formLocation.getItemIndex()}
  />
);
