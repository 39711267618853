import { Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { isSome } from "common/base/types/maybe";
import type { IRiskSchema } from "common/schemas/riskRegister/risk-register-content";
import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { BodyShortText, Button } from "../../../alpaca/components";
import { LogError, LogErrorMessage } from "../../../errors";
import type { GetInfoForRiskAssessmentQuery } from "../../../gen/components";
import {
  GetInfoForRiskAssessmentDocument,
  useRrv3DeleteScenarioMutation,
} from "../../../gen/components";
import { DataTable } from "../components/data-table";
import type { RiskAssessmentDomain } from "./queries-and-types";

interface IProps {
  risk: IRiskSchema;
  domain: RiskAssessmentDomain;
}
const MENU_FLAGS = {
  EDIT_RISK: "edit-risk",
  VIEW_TASKS: "view-tasks",
  DELETE: "delete",
};

export const RiskScenarioList: React.FC<IProps> = ({ domain, risk }) => {
  const scenariosToDisplay = domain.rrv3RiskScenarios.filter(
    s => s.riskCategoryId === risk.id
  );
  const history = useHistory();
  const [deleteScenario] = useRrv3DeleteScenarioMutation({
    update(cache, result) {
      const scenarioToDelete = result.data?.rrv3DeleteScenario;
      if (!isSome(scenarioToDelete)) {
        return;
      }
      const previousData = cache.readQuery<GetInfoForRiskAssessmentQuery>({
        query: GetInfoForRiskAssessmentDocument,
      });
      if (!isSome(previousData) || !isSome(previousData.organization)) {
        return;
      }

      cache.writeQuery<GetInfoForRiskAssessmentQuery>({
        query: GetInfoForRiskAssessmentDocument,
        data: {
          organization: {
            ...previousData.organization,
            rrv3RiskScenarios:
              previousData.organization.rrv3RiskScenarios.filter(
                s => s.id !== scenarioToDelete.id
              ),
          },
        },
      });
    },
  });

  const table = (
    <DataTable
      data={scenariosToDisplay}
      columnOrder={["description", "tasks"]}
      columnWidths={["350px", "80px"]}
      header={{ description: "Risk", tasks: "Tasks" }}
      menuOptions={() => [
        {
          text: "Edit risk",
          flag: MENU_FLAGS.EDIT_RISK,
          iconName: IconNames.EDIT,
        },
        {
          text: "View/Edit tasks",
          flag: MENU_FLAGS.VIEW_TASKS,
          iconName: IconNames.CALENDAR,
        },
        { text: "Delete", flag: MENU_FLAGS.DELETE, iconName: IconNames.TRASH },
      ]}
      onMenuItemClick={(flag, scenario) => {
        switch (flag) {
          case MENU_FLAGS.EDIT_RISK:
            history.push(
              window.location.pathname + `?scenarioId=${scenario.id}`
            );
            break;
          case MENU_FLAGS.VIEW_TASKS:
            history.push(
              window.location.pathname + `?scenarioId=${scenario.id}&tasks=1`
            );
            break;
          case MENU_FLAGS.DELETE:
            deleteScenario({
              variables: { scenarioId: scenario.id },
            }).catch(LogError);
            break;
          default:
            LogErrorMessage(`Unrecognized menu option: ${flag}`);
        }
      }}
      emptyDefault={"No risks have been created"}
      createRow={scenario => {
        return {
          description: <BodyShortText>{scenario.description}</BodyShortText>,
          tasks: (
            <BodyShortText style={{ textAlign: "center" }}>
              {scenario.tasks.length}
            </BodyShortText>
          ),
        };
      }}
    />
  );
  return (
    <Container>
      <Button
        secondary
        intent={Intent.PRIMARY}
        onClick={() => {
          history.push(window.location.pathname + `?scenarioId=new`);
        }}
      >
        Add scenario
      </Button>
      {table}
    </Container>
  );
};

const Container = styled.div`
  table {
    width: 100%;
    margin-bottom: 8px;
  }
`;
