import { Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React from "react";
import styled from "styled-components";

import { VANTA_COLORS } from "../../../alpaca/base/colors";

interface IProps {
  text: string;
  onDelete(): void;
}

export const DeletableTag: React.FC<IProps> = ({ onDelete, text }) => (
  <TagContainer>
    <span>{text}</span>
    <HoverableIcon icon={IconNames.CROSS} onClick={onDelete} />
  </TagContainer>
);

const styles = {
  TAG_LABEL_RIGHT_MARGIN: 16,
  TAG_LABEL_FONT_WEIGHT: 600,
  TAG_BACKGROUND_COLOR: VANTA_COLORS.BACKGROUND_TAG,
  TAG_BORDER_RADIUS: 6,
  TAG_PADDING_TOP: 7,
  TAG_PADDING_SIDES: 14,
  TAG_FONT_SIZE: 14,
};

const TagContainer = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;

  padding: ${styles.TAG_PADDING_TOP}px ${styles.TAG_PADDING_SIDES}px;
  background-color: ${styles.TAG_BACKGROUND_COLOR};
  border-radius: ${styles.TAG_BORDER_RADIUS}px;
  color: ${VANTA_COLORS.VANTA_PURPLE};
  font-weight: ${styles.TAG_LABEL_FONT_WEIGHT};
  font-size: ${styles.TAG_FONT_SIZE}px;
  & > span:first-child {
    margin-right: ${styles.TAG_LABEL_RIGHT_MARGIN}px;
  }
`;

const HoverableIcon = styled(Icon)`
  &:hover {
    cursor: pointer;
  }
`;
