import type { IPanel } from "@blueprintjs/core";
import { PanelStack } from "@blueprintjs/core";
import React from "react";
import styled from "styled-components";

import type { IFormCredentialProps } from "../services/common-interface";
import { LinkOkta } from "./okta-creds";

export interface IProps {
  domainId: string;
  onComplete: () => void;
}

export type OktaPanel = IPanel<IProps>;
export const OktaContext = React.createContext<IProps>({
  domainId: "",
  // eslint-disable-next-line arrow-body-style
  onComplete: () => {
    return;
  },
});

export const Okta: React.FC<IFormCredentialProps> = ({
  domain,
  onCredentialsLinked,
}) => (
  <OktaContext.Provider
    value={{ domainId: domain.id, onComplete: onCredentialsLinked }}
  >
    <PanelContainer>
      <PanelStack initialPanel={LinkOkta} />
    </PanelContainer>
  </OktaContext.Provider>
);

const PanelContainer = styled.div`
  & .bp3-panel-stack {
    height: 400px;
  }
  margin-bottom: -20px;
  li .bp3-button {
    margin-top: -1px;
  }
`;
