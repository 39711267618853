import "./vanta-report-about-text.scss";

import { GENERIC_REPORT_DATA } from "common/standards/STARTER_REPORT_DATA";
import React from "react";

import { MarkdownRenderer } from "../../../../pages/components/markdown-renderer";
import { ReportContent, ReportHeading } from "../../../common";

export const VantaReportAboutText: React.FunctionComponent = () => {
  const aboutText = GENERIC_REPORT_DATA.ABOUT;

  return (
    <div id={aboutText.name} className="vrp-appendix">
      <ReportHeading>{aboutText.name}</ReportHeading>
      <ReportContent>{MarkdownRenderer(aboutText.text)}</ReportContent>
    </div>
  );
};
