import {
  Classes,
  FormGroup,
  Icon,
  InputGroup,
  Intent,
} from "@blueprintjs/core";
import { isSome } from "common/base/types/maybe";
import {
  AZURE_LINKING_STEPS,
  SUPPORTED_CLOUDS,
} from "common/constants/azure-constants";
import React, { useState } from "react";

import { Button, Tooltip } from "../../../../alpaca/components";
import { LogError } from "../../../../errors";
import {
  AllLinkedCredentialsDocument,
  useSetCredentialsMutation,
  useTestCredentialsMutation,
} from "../../../../gen/components";
import { DefaultLink } from "../../../../helpers/links";
import { AppToaster } from "../../../../helpers/toaster";
import { SERVICE_DETAILS } from "../../../credentials/service-groups";
import { DropdownButton } from "../../components/dropdown-button";
import { DisconnectCredentialDialog } from "../disconnect-credentials";
import { RenderCredential } from "../render-credential";
import type { IFormCredentialProps } from "./common-interface";

export const Azure: React.FunctionComponent<IFormCredentialProps> = ({
  credentials,
  onCredentialsLinked,
}) => {
  const [pane, setPane] = useState(
    credentials.length > 0
      ? AZURE_LINKING_STEPS.SUBSCRIPTIONS
      : AZURE_LINKING_STEPS.CREDENTIALS
  );

  const [formFields, setFormFields] = useState({
    cloud: SUPPORTED_CLOUDS.Global,
    applicationId: "",
    token: "",
    directoryId: "",
    subscriptionId: "",
  });

  const [testCredentials, { loading: testLoading, error: testError }] =
    useTestCredentialsMutation({
      variables: {
        credentials: JSON.stringify(formFields),
        service: SERVICE_DETAILS.azure.id,
        extraMetadata: pane,
      },
    });

  const [setCredentials, { loading, error }] = useSetCredentialsMutation({
    onCompleted: () => {
      AppToaster.show(
        { intent: Intent.SUCCESS, message: "Azure connection created" },
        SERVICE_DETAILS.azure.id
      );
      onCredentialsLinked();
    },
    refetchQueries: [{ query: AllLinkedCredentialsDocument }],
    variables: {
      credentials: JSON.stringify({ ...formFields, microsoftGraph: true }),
      service: SERVICE_DETAILS.azure.id,
      externalAccountId: formFields.subscriptionId,
    },
  });

  if (testError) {
    LogError(testError);
  }

  if (error) {
    LogError(error);
  }

  const isCredentialFilled = Object.values(formFields).every(
    value => value.length > 0
  );

  const [credentialId, setCredentialIdToDelete] = useState("");
  const [subscriptionIdToDelete, setSubscriptionId] = useState("");

  const [disconnectDialogOpen, setDisconnectDialogOpen] = useState(false);
  const disconnectDialog = (
    <DisconnectCredentialDialog
      externalAccountId={subscriptionIdToDelete}
      isOpen={disconnectDialogOpen}
      closeDialog={() => setDisconnectDialogOpen(false)}
      credentialId={credentialId}
      service={"azure"}
    />
  );

  const panes: {
    [k in AZURE_LINKING_STEPS]: {
      content: React.ReactNode;
      footer: React.ReactNode;
    };
  } = {
    SUBSCRIPTIONS: {
      content: (
        <div>
          <p>Connected Azure subscriptions</p>
          <hr></hr>
          {credentials.map(c => {
            const { subscriptionId, directoryId, applicationId, oldCloud } =
              JSON.parse(c.metadata!);
            return (
              <RenderCredential
                key={subscriptionId}
                credential={c}
                accountId={subscriptionId}
                editCredential={() => {
                  setFormFields({
                    cloud: oldCloud ?? SUPPORTED_CLOUDS.Global,
                    subscriptionId,
                    directoryId,
                    applicationId,
                    token: "",
                  });
                  setPane(AZURE_LINKING_STEPS.CREDENTIALS);
                }}
                deleteCredential={() => {
                  setSubscriptionId(subscriptionId);
                  setCredentialIdToDelete(c.id);
                  setDisconnectDialogOpen(true);
                }}
              />
            );
          })}
          {disconnectDialog}
        </div>
      ),
      footer: (
        <Button
          intent={Intent.PRIMARY}
          onClick={() => setPane(AZURE_LINKING_STEPS.CREDENTIALS)}
        >
          Add new subscription
        </Button>
      ),
    },
    CREDENTIALS: {
      content: (
        <>
          <ol>
            <li>
              Visit your Azure Portal and navigate to Azure Active Directory
            </li>
            <li>
              Navigate to{" "}
              <DefaultLink
                href="https://portal.azure.com/#blade/Microsoft_AAD_RegisteredApps/ApplicationsListBlade"
                target="_blank"
                rel="noopener noreferrer"
              >
                App Registration
              </DefaultLink>
            </li>
            <li>
              Add a new registration
              <ul>
                <li>Name: Vanta</li>
                <li>Leave all other settings as the defaults</li>
              </ul>
            </li>
            <li>Register</li>
            <li>
              Copy the Application ID and Directory ID into the fields below
            </li>
          </ol>
          <FormGroup label="Application ID">
            <InputGroup
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFormFields({
                  ...formFields,
                  applicationId: e.target.value.trim(),
                })
              }
              type="text"
              value={formFields.applicationId}
            />
          </FormGroup>
          <FormGroup label="Directory ID">
            <InputGroup
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFormFields({
                  ...formFields,
                  directoryId: e.target.value.trim(),
                });
              }}
              name="directoryId"
              value={formFields.directoryId}
            />
          </FormGroup>
          <FormGroup label="Cloud">
            <DropdownButton
              options={[SUPPORTED_CLOUDS.Global, SUPPORTED_CLOUDS.Government]}
              selectedOption={formFields.cloud}
              onOptionSelect={option => {
                setFormFields({
                  ...formFields,
                  cloud: option ?? SUPPORTED_CLOUDS.Global,
                });
              }}
              optionRenderer={option => option}
            />
          </FormGroup>
        </>
      ),
      footer: (
        <Button
          disabled={
            !(
              formFields.applicationId.length > 0 &&
              formFields.directoryId.length > 0
            )
          }
          intent={Intent.PRIMARY}
          onClick={() => setPane(AZURE_LINKING_STEPS.CERTIFICATE_AND_GRAPH)}
        >
          Next
        </Button>
      ),
    },
    CERTIFICATE_AND_GRAPH: {
      content: (
        <div>
          <ol>
            <li>Navigate to "Certificates & secrets"</li>
            <li>
              Add a new client secret
              <ul>
                <li>Description: Vanta</li>
                <li>Expires: 24 months</li>
              </ul>
            </li>
            <li>Add</li>
            <li>Copy the Value into the field below</li>
          </ol>
          <FormGroup label="Client Secret Value">
            <InputGroup
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFormFields({ ...formFields, token: e.target.value.trim() })
              }
              type="password"
              value={formFields.token}
            />
          </FormGroup>

          <ol start={5}>
            <li>Navigate to "API Permissions"</li>
            <li>
              Add a permission
              <ul>
                <li>Select "Microsoft Graph"</li>
                <li>Select "Application permissions"</li>
                <li>Check "Directory.Read.All" permission</li>
                <li>Click "Add permissions"</li>
              </ul>
            </li>
            <li>
              Grant Admin consent
              <ul>
                <li>Click "Grant admin consent"</li>
                <li>
                  Click "Yes" when prompted to grant requested permissions.
                </li>
              </ul>
            </li>
            <li>
              Navigate to{" "}
              <DefaultLink
                href="https://portal.azure.com/#blade/Microsoft_Azure_Billing/SubscriptionsBlade"
                target="_blank"
                rel="noopener noreferrer"
              >
                Subscriptions
              </DefaultLink>
            </li>
            <li>Copy the Subscription ID into the field below</li>
          </ol>
          <FormGroup label="Subscription ID">
            <InputGroup
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFormFields({
                  ...formFields,
                  subscriptionId: e.target.value.trim(),
                })
              }
              type="text"
              value={formFields.subscriptionId}
            />
          </FormGroup>
        </div>
      ),
      footer: (
        <>
          <Button onClick={() => setPane(AZURE_LINKING_STEPS.CREDENTIALS)}>
            Back
          </Button>
          <Button
            disabled={!isCredentialFilled}
            intent={Intent.PRIMARY}
            loading={testLoading || loading}
            onClick={async () => {
              const { data, errors } = await testCredentials();
              if ((errors && errors.length > 0) || !isSome(data)) {
                return;
              }
              if (!data.testCredentials.success) {
                AppToaster.show({
                  intent: Intent.WARNING,
                  message:
                    data.testCredentials.message ??
                    "Uh oh, something went wrong! Contact support@vanta.com for help.",
                  timeout: 5000,
                });
              } else {
                setPane(AZURE_LINKING_STEPS.LINK);
              }
            }}
          >
            Next
          </Button>
        </>
      ),
    },
    LINK: {
      content: (
        <div>
          <p>Create a read-only role and assign the "Vanta" app to it.</p>
          <ol>
            <li>
              Navigate to "Access control (IAM)" in your{" "}
              <DefaultLink
                href="https://portal.azure.com/#blade/Microsoft_Azure_Billing/SubscriptionsBlade"
                target="_blank"
                rel="noopener noreferrer"
              >
                subscription
              </DefaultLink>{" "}
              <Tooltip
                content='You can find "Access control (IAM)" in the left navigation
                of your Subscription after you select your subscription.'
              >
                <Icon
                  icon="help"
                  iconSize={16}
                  style={{ marginBottom: "2px" }}
                />
              </Tooltip>
            </li>
            <li>
              Add a role assignment
              <ul>
                <li>Role: Reader</li>
                <li>
                  Assign access to: Azure AD user, group, or service principal
                </li>
                <li>Select: Vanta</li>
              </ul>
            </li>
            <li>Save</li>
          </ol>
        </div>
      ),
      footer: (
        <>
          <Button
            onClick={() => setPane(AZURE_LINKING_STEPS.CERTIFICATE_AND_GRAPH)}
          >
            Back
          </Button>
          <Button
            intent={Intent.PRIMARY}
            loading={testLoading || loading}
            onClick={async () => {
              const { data, errors } = await testCredentials();
              if ((errors && errors.length > 0) || !isSome(data)) {
                return;
              }
              if (!data.testCredentials.success) {
                AppToaster.show({
                  icon: "cross",
                  intent: Intent.WARNING,
                  message: data.testCredentials.message,
                  timeout: 5000,
                });
                return;
              }
              await setCredentials();
            }}
          >
            Store credentials
          </Button>
        </>
      ),
    },
  };
  return (
    <div>
      <div className={Classes.DIALOG_HEADER}>Link Azure</div>
      <div className={Classes.DIALOG_BODY}>{panes[pane].content}</div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          {panes[pane].footer}
        </div>
      </div>
    </div>
  );
};
