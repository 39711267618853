/* eslint-disable vanta/null-or-undefined-check */
/* eslint-disable vanta/prefer-maybe */

/**
 * Maybe: Vanta's optional type.
 *
 * In general, unless there's a very good reason, we treat
 * null and undefined the same. Optional values should generally
 * get the type Maybe.
 *
 * If we want to get really clever about this, we could make it
 * monadic and never really have to check for undefined-ness. We could use
 * https://www.npmjs.com/package/prelude.ts
 */
export type Nothing = null | undefined;
// use nothing instead of null or undefined.
export const nothing: Nothing = undefined;
export type Maybe<T> = T | Nothing;

/**
 * This function checks a Maybe type to see if it's defined.
 * It also fancily informs TypeScript that the type can now assumed
 * to be T.
 *
 * We should (almost) never use undefined or null – prefer to use
 * a Maybe<> type and check for undefined-ness using isSome.
 *
 * @param arg the argument whose type to check
 */
export function isSome<T>(arg: Maybe<T> | void): arg is NonNullable<T> {
  return arg !== undefined && arg !== null;
}

/**
 * A utility function that returns arg if it's defined, else throws an
 * error. Equivalent to forcefully unwrapping Maybe<T> to T
 * when you're *really* sure.
 *
 *
 * examples:
 *  getOrThrow("hello") --> "hello"
 *  getOrThrow(null) --> *BOOM*
 *
 * @param arg The argument assumed to be defined.
 */
export function getOrThrow<T>(arg: Maybe<T>): T {
  if (isSome(arg)) {
    return arg;
  }
  throw new Error("Found nothing when calling getOrThrow");
}

/**
 * Given an item of type Maybe<T>, transform it into type U using the
 * provided transformer function T->U or return a default value of type
 * U if the item is nothing.
 *
 * @param arg The argument that may or may not be defined
 * @param transformer A function to call on arg if it is Some(T).
 * @param defaultVal The default value (transformer is not called on this)
 */
export function getTransformedOrElse<T, U>(
  arg: Maybe<T>,
  transformer: (x: T) => U,
  defaultVal: U
) {
  return isSome(arg) ? transformer(arg) : defaultVal;
}

/**
 * filter out values that are not Some(T)
 *
 * @param arrWithOptionals an array containing maybe types
 */
export function dropNothing<T>(arrWithOptionals: ReadonlyArray<Maybe<T>>): T[] {
  return arrWithOptionals.filter(elem => isSome(elem)) as T[];
}

/**
 * Apply a function f: T->U to arg if it is Some<T>; otherwise, return nothing.
 * @param arg
 * @param f the function to apply
 */
export function applyIfSome<T, U>(arg: Maybe<T>, f: (x: T) => U): Maybe<U> {
  return getTransformedOrElse(arg, f, nothing);
}

// Assertion function to ensure that arg is T. Use when you're sure that something
// is supposed to be defined; prefer this to using a "!" assertion.
export function assertSome<T>(arg: Maybe<T>): asserts arg is T {
  getOrThrow(arg);
}

/**
 * assertNonempty asserts that ARG is some and also that it is not the empty
 * string.
 *
 * Some APIs return empty strings for some string values when they
 * don't have any data; if data is required, and an empty string is an
 * invalid value (e.g. for computer encryption state from Jamf) we should error.
 */
export function assertNonEmpty(arg: Maybe<string>): asserts arg is string {
  assertSome(arg);
  if (arg === "") {
    throw new Error("Expected nonempty string data is empty");
  }
}

/**
 * assertNonEmptyString asserts that ARG is a string type, and non-empty.
 * This differs from assertNonEmpty, which does not check the javascript type.
 */
export function assertNonEmptyString(arg: any): asserts arg is string {
  if (typeof arg !== "string") {
    throw new Error("Expected string argument");
  }
  assertNonEmpty(arg);
}

export const emptyStringToNull = (s: Maybe<string>) => (s === "" ? nothing : s);

export function dropEmpty(strs: Maybe<string[]>) {
  if (!isSome(strs)) {
    return null;
  }

  return strs.reduce((acc, s) => {
    if (s.trim() !== "") {
      acc.push(s);
    }
    return acc;
  }, [] as string[]);
}

export type DiscriminateUnion<T, U> = T extends U ? T : never;
export type DiscriminateOnTypename<T, U extends string> = DiscriminateUnion<
  T,
  { __typename: U }
>;
