import * as Sentry from "@sentry/browser";
import type { Maybe } from "common/base/types/maybe";

let currentUserId: Maybe<string>;

export const SentryBreadcrumbs = {
  dashboardVisit({ userId, domainId }: { userId: string; domainId: string }) {
    if (currentUserId === userId) {
      return;
    }
    Sentry.configureScope(scope => {
      scope.setUser({ id: userId, domainId });
    });
    Sentry.addBreadcrumb({
      category: "auth",
      message: "dashboard visited",
      data: { userId, domainId },
      level: Sentry.Severity.Info,
    });
    currentUserId = userId;
  },
};
