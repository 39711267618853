/**
 * @deprecated Use DefaultView or HeaderRow from Alpaca
 */
export interface IHeadingInfo {
  title: string;
  description: string | JSX.Element;
}

const makeHeading: (t: string, d: string | JSX.Element) => IHeadingInfo = (
  title: string,
  description: string | JSX.Element
) => {
  return {
    title,
    description,
  };
};

/**
 * @deprecated Use DefaultView or HeaderRow from Alpaca
 */
export const PageHeadingInfo = {
  AUDITOR_DASHBOARD: makeHeading(
    "Companies under audit",
    "Review all customers in active audit period. Click on a customer to review and test their environment."
  ),
  AUDIT_SCHEDULER: makeHeading(
    "Audits",
    "Set your audit dates and grant auditors access to your Vanta account"
  ),
  ALARMS: makeHeading(
    "Monitoring alarms",
    "Review customer AWS CloudWatch monitoring alarms with metrics and configuration information."
  ),
  BILLING: makeHeading(
    "Billing & services",
    "Review and update your Vanta account plan, service add ons and billing contact information."
  ),
  CHANGES: makeHeading(
    "Code change history",
    "Review customer code changes in code repositories that serve business functions by month with author and review information."
  ),
  COMPANY_INFORMATION: makeHeading(
    "Company information",
    "Complete general information about your business and its physical location to inform policies and satisfy compliance requirements."
  ),
  CONNECTIONS: makeHeading(
    "Connections",
    "Review your integrations and enable new connections to activate automated evidence collection and monitoring."
  ),
  DATABASES: makeHeading(
    "Databases",
    "Review this customer’s databases and configuration settings including networking information, backup retention period and encryption status."
  ),
  ENGINEERING_INFORMATION: makeHeading(
    "Engineering information",
    "Complete information about your business’ engineering practices and tools to inform policies and satisfy compliance requirements."
  ),
  INVENTORY: makeHeading(
    "Inventory",
    "Conduct an assessment of all resources used to provide your business's services and take action to assign ownership and flag sensitive items to monitor their configuration."
  ),
  INSTALLATION_INSTRUCTIONS: makeHeading(
    "Install Vanta Agent",
    "Monitor your servers for package vulnerabilities."
  ),
  NETWORK: makeHeading(
    "Network configurations",
    "Review this customer’s AWS autoscale and EC2 instances and configuration. This information is shown grouped by VPC."
  ),
  NOTIFICATIONS: makeHeading(
    "Emails and notifications",
    "Review and update your email and notification settings."
  ),
  RISK_ASSESSMENT: makeHeading(
    "Risk assessment",
    "Conduct a risk assessment to identify and categorize risks to your business, describe their cause and potential impact, and outline mitigation steps tasks that reduce their impact or severity."
  ),
  RISK_REGISTER: makeHeading(
    "Risk register",
    "Conduct a risk assessment to identify and categorize risks to your business, describe their cause and potential impact, and outline mitigation steps tasks that reduce their impact or severity."
  ),
  RISK_REPORT: makeHeading(
    "Risk report",
    "Review results of your risk assessment and identified risks to your business."
  ),
  SUBNETS: makeHeading(
    "Subnet configurations",
    "Review this customer’s security network groups and their configuration."
  ),
  VANTA_USERS: makeHeading(
    "Vanta users",
    "Review and update the access level of users in your company who have access to Vanta data. "
  ),
  VENDORS: makeHeading(
    "Vendors",
    "Conduct an assessment of vendors essential to providing your business's services and take action to assign ownership and review each vendor's own security assessments."
  ),
} as const;
