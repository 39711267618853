import { DateInput } from "@blueprintjs/datetime";
import moment from "moment";
import React from "react";
import styled from "styled-components";
import { BASE_PALETTE } from "../../../alpaca/base/colors";
import { GRID_SPACING } from "../../../alpaca/base/grid";
import { Icon, IconNames } from "../../../alpaca/components";
import type { NonNullDateRange } from "./utils";

interface IProps {
  dateRange: NonNullDateRange;
  onNewValue(newDateRange: NonNullDateRange): void;
  // eslint-disable-next-line vanta/optional-always-maybe, vanta/prefer-maybe
  className?: string | undefined;
}

const DATE_FORMAT_STRING = "MM / DD / YYYY";
const DAYS_TO_ADD = 1;
const MAX_DATE = moment().add(10, "years").toDate();

export const DateInputPair: React.FC<IProps> = ({
  dateRange,
  onNewValue,
  className,
}) => (
  <StyledDiv className={className}>
    <StyledDateInput
      value={dateRange[0]}
      formatDate={d => moment(d).format(DATE_FORMAT_STRING)}
      maxDate={MAX_DATE}
      parseDate={s => new Date(s)}
      onChange={newStartDate => {
        const endDate = moment(newStartDate).isSameOrAfter(
          moment(dateRange[1]),
          "day"
        )
          ? moment(newStartDate)
              .add(DAYS_TO_ADD * 24 + 1, "hours")
              .toDate()
          : dateRange[1];
        onNewValue([newStartDate, endDate]);
      }}
    />
    <Icon
      icon={IconNames.ARROW_LEFT}
      style={{
        transform: "rotate(0.5turn)",
        margin: `0 ${3 * GRID_SPACING}px`,
      }}
    />
    <StyledDateInput
      value={dateRange[1]}
      formatDate={d => moment(d).format(DATE_FORMAT_STRING)}
      maxDate={MAX_DATE}
      parseDate={s => new Date(s)}
      onChange={newEndDate => {
        const startDate = moment(newEndDate).isSameOrBefore(
          moment(dateRange[0]),
          "day"
        )
          ? moment(newEndDate)
              .subtract(DAYS_TO_ADD * 24 + 1, "hours")
              .toDate()
          : dateRange[0];
        onNewValue([startDate, newEndDate]);
      }}
    />
  </StyledDiv>
);

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
`;

const StyledDateInput = styled(DateInput)`
  .bp3-input {
    text-align: center;
    height: ${5 * GRID_SPACING}px;
    color: ${BASE_PALETTE.SLATE};
  }
`;
