import type { Maybe } from "common/base/types/maybe";

export function canEditGroup(
  user: { isActive: boolean; isNotHuman?: Maybe<boolean> },
  groups: Array<{}>
): boolean {
  return !!user.isActive && !user.isNotHuman && groups.length > 0;
}

export function canMarkNotHuman(user: {
  hrUser?: Maybe<{}>;
  isNotHuman?: Maybe<boolean>;
}): boolean {
  return !user.isNotHuman && !user.hrUser;
}
