import { Classes, Dialog, Spinner } from "@blueprintjs/core";
import { isSome } from "common/base/types/maybe";
import { simplePlural } from "common/grammar/plurals";
import type { IRiskSchema } from "common/schemas/riskRegister/risk-register-content";
import React, { useState } from "react";

import { BodyText, TextLinkLike } from "../../../alpaca/components";
import { LogError } from "../../../errors";
import type { GetInfoForRiskAssessmentQuery } from "../../../gen/components";
import {
  GetInfoForRiskAssessmentDocument,
  useDismissRiskMutation,
  useRrv3AddAssetMutation,
  useRrv3DeleteAssetMutation,
} from "../../../gen/components";
import { MultiTextInputPanel } from "../../master-detail-view/detail-panels/multi-text-input-panel";
import type { RiskAssessmentDomain } from "./queries-and-types";
import { useFetchResourceNames } from "./use-fetch-resource-names";

interface IProps {
  riskSchema: IRiskSchema;
  assets: RiskAssessmentDomain["rrv3Assets"];
}

export const RiskAssetSelectionFlow: React.FC<IProps> = ({
  riskSchema,
  assets,
}) => {
  const [showDialog, setShowDialog] = useState(false);
  const maybeResourceQuery = useFetchResourceNames(riskSchema);
  const hasResource = isSome(riskSchema.resourceKind);
  const currentlyLoadingResources = hasResource && maybeResourceQuery?.loading;
  const resources = maybeResourceQuery?.data ?? [];

  const [dismissRisk] = useDismissRiskMutation({
    update: (cache, result) => {
      const newDismissal = result.data?.dismissRisk;
      if (!isSome(newDismissal)) {
        return;
      }
      const previousData = cache.readQuery<GetInfoForRiskAssessmentQuery>({
        query: GetInfoForRiskAssessmentDocument,
      });
      if (!isSome(previousData) || !isSome(previousData.organization)) {
        return;
      }

      cache.writeQuery<GetInfoForRiskAssessmentQuery>({
        query: GetInfoForRiskAssessmentDocument,
        data: {
          organization: {
            ...previousData.organization,
            riskDismissals:
              previousData.organization.riskDismissals.concat(newDismissal),
          },
        },
      });
    },
  });
  const [addRiskAsset] = useRrv3AddAssetMutation({
    update: (cache, result) => {
      const itemToAdd = result.data?.rrv3AddAsset;
      if (!isSome(itemToAdd)) {
        return;
      }
      const previousData = cache.readQuery<GetInfoForRiskAssessmentQuery>({
        query: GetInfoForRiskAssessmentDocument,
      });
      if (!isSome(previousData) || !isSome(previousData.organization)) {
        return;
      }

      cache.writeQuery<GetInfoForRiskAssessmentQuery>({
        query: GetInfoForRiskAssessmentDocument,
        data: {
          organization: {
            ...previousData.organization,
            rrv3Assets: previousData.organization.rrv3Assets.concat(itemToAdd),
          },
        },
      });
    },
  });
  const [deleteRiskAsset] = useRrv3DeleteAssetMutation({
    update: (cache, result) => {
      const itemToDelete = result.data?.rrv3DeleteAsset;
      if (!isSome(itemToDelete)) {
        return;
      }
      const previousData = cache.readQuery<GetInfoForRiskAssessmentQuery>({
        query: GetInfoForRiskAssessmentDocument,
      });
      if (!isSome(previousData) || !isSome(previousData.organization)) {
        return;
      }

      cache.writeQuery<GetInfoForRiskAssessmentQuery>({
        query: GetInfoForRiskAssessmentDocument,
        data: {
          organization: {
            ...previousData.organization,
            rrv3Assets: previousData.organization.rrv3Assets.filter(
              item => item.id !== itemToDelete.id
            ),
          },
        },
      });
    },
  });

  const optOutText = "This does not apply to me";
  const onOptOut = () => {
    dismissRisk({ variables: { riskId: riskSchema.id } }).catch(LogError);
  };

  if (isSome(riskSchema.resourceKind)) {
    if (currentlyLoadingResources) {
      return <Spinner />;
    }
    const hasResources = resources.length > 0;
    const content = (
      <div>
        List out any {riskSchema.shortHeading.toLocaleLowerCase()} not tracked
        in Vanta
        <BodyText>
          {simplePlural(resources.length, "asset")} currently tracked&nbsp;
          {hasResources ? (
            <>
              <TextLinkLike onClick={() => setShowDialog(true)}>
                Show
              </TextLinkLike>
              <Dialog
                title={`${riskSchema.shortHeading} tracked in Vanta`}
                isOpen={showDialog}
                onClose={() => setShowDialog(false)}
              >
                <div className={Classes.DIALOG_BODY}>
                  <ul>
                    {resources.map(resource => (
                      <li key={resource.uniqueId}>
                        {resource.name ?? resource.uniqueId}
                      </li>
                    ))}
                  </ul>
                </div>
              </Dialog>
            </>
          ) : null}
        </BodyText>
      </div>
    );
    return (
      <MultiTextInputPanel
        onValueAdded={value => {
          addRiskAsset({
            variables: { riskCategoryId: riskSchema.id, name: value },
          }).catch(LogError);
        }}
        onValueRemoved={value => {
          const itemToDelete = assets.find(item => item.name === value);
          if (!isSome(itemToDelete)) {
            return;
          }
          deleteRiskAsset({ variables: { itemId: itemToDelete.id } }).catch(
            LogError
          );
        }}
        values={assets.map(item => item.name)}
        textContent={content}
        placeholder={""}
        optOutTextContent={optOutText}
        onOptOut={onOptOut}
      />
    );
  }

  return (
    <MultiTextInputPanel
      onValueAdded={value => {
        addRiskAsset({
          variables: { riskCategoryId: riskSchema.id, name: value },
        }).catch(LogError);
      }}
      onValueRemoved={value => {
        const itemToDelete = assets.find(item => item.name === value);
        if (!isSome(itemToDelete)) {
          return;
        }
        deleteRiskAsset({ variables: { itemId: itemToDelete.id } }).catch(
          LogError
        );
      }}
      values={assets.map(item => item.name)}
      textContent={riskSchema.itemSelectionPrompt}
      placeholder={riskSchema.placeholderText ?? ""}
      optOutTextContent={optOutText}
      onOptOut={onOptOut}
    />
  );
};
