import { HTMLSelect, Intent, Radio, RadioGroup } from "@blueprintjs/core";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import React, { useContext, useState } from "react";
import styled from "styled-components";

import { LogError } from "../../../errors";
import type { GetInfoForRolesTableQuery } from "../../../gen/components";
import {
  GetInfoForRolesTableDocument,
  GetUserInfoForOnboardingListDocument,
  useSetRoleMutation,
} from "../../../gen/components";
import { AppToaster } from "../../../helpers/toaster";
import { CancelConfirmDialog } from "../../helpers/CancelConfirmDialog";
import { GroupsContext } from "./groups-context";
import type { DetailHeadingUser } from "./user-drawer/user-detail-heading";

interface IProps {
  isOpen: boolean;
  user: DetailHeadingUser;
  onClose(): void;
}

const NO_GROUPS_ID = "__NO_GROUPS__";
const NULL_GROUP_ID = "__NULL_GROUP__";

export const EditUserGroupDialog: React.FC<IProps> = ({
  isOpen,
  onClose,
  user,
}) => {
  const { groups } = useContext(GroupsContext);
  const [selectedRoleId, setSelectedRoleId] = useState(
    user.role?.id ?? groups[0]?.id ?? NO_GROUPS_ID
  );
  const [omitChecklist, setOmitChecklist] = useState(false);
  const [setRoleFn] = useSetRoleMutation({
    refetchQueries: [
      {
        query: GetUserInfoForOnboardingListDocument,
        variables: { userId: user.id },
      },
    ],
    awaitRefetchQueries: true,
    onCompleted() {
      AppToaster.show({
        message: "User updated",
        intent: Intent.SUCCESS,
      });
      onClose();
    },
    update(cache, result) {
      // Update roles table, but only if the data already exists in the cache
      // The read will throw an error if the cache contains partial data
      // from other queries.
      try {
        const rolesQuery = cache.readQuery<GetInfoForRolesTableQuery>({
          query: GetInfoForRolesTableDocument,
        });
        if (isSome(rolesQuery?.organization)) {
          const domain = rolesQuery!.organization;
          const originalRoleId = user.role?.id;
          cache.writeQuery<GetInfoForRolesTableQuery>({
            query: GetInfoForRolesTableDocument,
            data: {
              organization: {
                ...domain,
                roles: domain.roles.map(r => {
                  // Transfers the user from their former role (if they had one)
                  // to their new role.
                  if (r.id === originalRoleId) {
                    return {
                      ...r,
                      users: r.users.filter(u => u.id !== user.id),
                    };
                  } else if (r.id === selectedRoleId) {
                    return {
                      ...r,
                      users: r.users.concat({
                        __typename: "user",
                        id: user.id,
                        displayName: user.displayName,
                      }),
                    };
                  } else {
                    return r;
                  }
                }),
              },
            },
          });
        }
      } catch (e) {
        void 0;
      }
    },
  });

  if (groups.length <= 0) {
    return null;
  }

  const selectableGroups = groups.concat({
    id: NULL_GROUP_ID,
    name: "No group",
  });

  const roleHasChanged = selectedRoleId !== user?.role?.id;
  const canSubmit = selectedRoleId !== NO_GROUPS_ID && roleHasChanged;

  const isNullGroup = selectedRoleId === NULL_GROUP_ID;

  return (
    <CancelConfirmDialog
      title={`Change group for ${user.displayName ?? "person"}`}
      body={
        <Container>
          <HTMLSelect
            value={selectedRoleId}
            onChange={e => setSelectedRoleId(e.target.value)}
          >
            {selectableGroups.map(group => (
              <option key={group.id} value={group.id}>
                {group.name}
              </option>
            ))}
          </HTMLSelect>
          {isNullGroup || !roleHasChanged ? null : (
            <RadioGroup
              selectedValue={omitChecklist ? "omit" : "dont-omit"}
              onChange={e => {
                setOmitChecklist(e.currentTarget.value === "omit");
              }}
            >
              <Radio value="dont-omit" label="Include onboarding checklist" />
              <Radio
                value="omit"
                label="Don't include onboarding checklist. Only the group security requirements will be applied to this user."
              />
            </RadioGroup>
          )}
        </Container>
      }
      isOpen={isOpen}
      onConfirm={() => {
        setRoleFn({
          variables: {
            userId: user.id,
            roleId: isNullGroup ? null : selectedRoleId,
            omitChecklist,
          },
        }).catch(LogError);
      }}
      onClose={() => onClose()}
      confirmText="Save"
      confirmDisabled={!canSubmit}
      confirmDisabledMessage="Select a new group to save changes."
    />
  );
};

gql`
  mutation setRole($userId: ID!, $roleId: String, $omitChecklist: Boolean!) {
    addRoleToUser(
      userId: $userId
      roleId: $roleId
      omitChecklist: $omitChecklist
    ) {
      id
    }
  }
`;

const Container = styled.div`
  & > * {
    margin-bottom: 12px;
  }
`;
