import "./vanta-report-principle-list.scss";

import React from "react";

import type { IReportProps } from "../../../vanta-report-page";
import { VantaReportPrincipleListItem } from "./vanta-report-principle-list-item";

export class VantaReportPrincipleList extends React.Component<IReportProps> {
  public render() {
    const { domain, complianceStandard } = this.props;
    return (
      <div className="vrp-principle-list-container">
        {complianceStandard.principles.map((principle, index) => (
          <VantaReportPrincipleListItem
            key={`principle_${index}`}
            domain={domain}
            principle={principle}
          />
        ))}
      </div>
    );
  }
}
