import { Button, Collapse } from "@blueprintjs/core";
import React, { useState } from "react";

import type {
  EmployeeComputersQuery,
  OtherLinuxServersQuery,
} from "../../../../gen/components";
import { DialogWithButton } from "../../../helpers/dialog-w-button";
import { AMISecure } from "../../docs/AmISecure";
import { AuthorizedKeysTable } from "./authorized-keys-access-table";
import { SshConfigTables } from "./ssh-config-tables";
import { UserKeysTable } from "./user-keys-access-table";

export type EmployeeComputerItem = NonNullable<
  EmployeeComputersQuery["organization"]
>["machines"]["edges"][number]["node"]["data"];

export type LinuxServerItem = NonNullable<
  OtherLinuxServersQuery["organization"]
>["machines"]["edges"][number]["node"]["data"];

export const SshAccessTables: React.FC<{
  data: EmployeeComputerItem | LinuxServerItem;
  osqueryId: string;
}> = ({ data, osqueryId }) => {
  const [userKeysTableOpen, setUserKeysTableOpen] = useState(false);
  const [authorizedKeysTableOpen, setAuthorizedKeysTableOpen] = useState(false);
  const [configTablesOpen, setConfigTablesOpen] = useState(false);
  const [securityModalOpen, setSecurityModalOpen] = useState(false);

  if (!("authorizedKeys" in data)) {
    return null;
  }

  if (!Boolean(data.sshKeys?.length) && !Boolean(data.authorizedKeys?.length)) {
    return null;
  }

  const userKeysTable = (
    <div>
      <Button
        rightIcon={userKeysTableOpen ? "caret-down" : "caret-right"}
        text="Machines that this computer can access"
        onClick={() => setUserKeysTableOpen(prev => !prev)}
      />
      <Collapse isOpen={userKeysTableOpen}>
        <UserKeysTable osqueryId={osqueryId} />
      </Collapse>
    </div>
  );

  const authorizedKeysTable = (
    <div>
      <Button
        rightIcon={authorizedKeysTableOpen ? "caret-down" : "caret-right"}
        text="Machines that can access this computer"
        onClick={() => setAuthorizedKeysTableOpen(prev => !prev)}
      />
      <Collapse isOpen={authorizedKeysTableOpen}>
        <AuthorizedKeysTable osqueryId={osqueryId} />
      </Collapse>
    </div>
  );

  const configTables = (
    <div>
      <Button
        rightIcon={configTablesOpen ? "caret-down" : "caret-right"}
        text="SSH configuration recommendations"
        onClick={() => setConfigTablesOpen(prev => !prev)}
      />
      <Collapse isOpen={configTablesOpen}>
        <SshConfigTables data={data} />
      </Collapse>
    </div>
  );
  const dialog = (
    <DialogWithButton
      buttonText={"Security"}
      dialogContent={<AMISecure concern="ssh" />}
      dialogHeader={"Does Vanta know my SSH keys?"}
      icon={"lock"}
      isDialogOpen={securityModalOpen}
      buttonOnClick={() => setSecurityModalOpen(prev => !prev)}
      dialogClose={() => setSecurityModalOpen(false)}
    />
  );

  const hasOutgoingAccess = data.sshKeys?.some(k =>
    Boolean(k.machinesThisCanAccess?.totalCount)
  );

  const hasIncomingAccess = data.authorizedKeys?.some(k =>
    Boolean(k.machinesWithAccessToThisOne?.totalCount)
  );

  return (
    <div>
      {hasOutgoingAccess ? <div>{userKeysTable}</div> : null}
      {hasIncomingAccess ? <div>{authorizedKeysTable}</div> : null}
      <div>{configTables}</div>
      <div>{dialog}</div>
    </div>
  );
};
