import { AnchorButton, Classes } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React from "react";

import { DefaultLink } from "../../../../helpers/links";

export const MicrosoftEndpointManager: React.FC = () => (
  <div>
    <div className={Classes.DIALOG_HEADER}>Link Microsoft Endpoint Manager</div>
    <div className={Classes.DIALOG_BODY}>
      <p>Let's get started linking to Microsoft Endpoint Manager.</p>
      <p>First, verify that you are an admin of the Office organization.</p>
      <p>
        An easy way to check if your account is an admin is to visit the
        <DefaultLink href="https://endpoint.microsoft.com/?ref=AdminCenter#home/">
          {" "}
          Microsoft Endpoint Manager admin center{" "}
        </DefaultLink>
        and make sure that you can log in.
      </p>
      <p>
        This connection will take you through the Microsoft authorization flow.
      </p>
    </div>

    <div className={Classes.DIALOG_FOOTER}>
      <div className={Classes.DIALOG_FOOTER_ACTIONS}>
        <AnchorButton
          href="/auth/login/microsoft-endpoint-manager?continue=/credentials"
          icon={IconNames.LOG_IN}
        >
          Connect Microsoft Endpoint Manager
        </AnchorButton>
      </div>
    </div>
  </div>
);
