import { Icon, Spinner } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";

import { LogError } from "../../../errors";
import { useGetPolicyVersionHistoryQuery } from "../../../gen/components";
import { UI_DATE_FORMAT } from "../../../helpers/common";
import type { Policy } from "./common";
import {
  Dialog,
  DialogBody,
  Row,
  RowAnswer,
  RowQuestion,
} from "./policies-v2-styles";

export const PolicyVersionHistoryDialog: React.FC<{
  onClose: () => void;
  policy: Policy;
}> = ({ onClose, policy }) => {
  const { data, error, loading } = useGetPolicyVersionHistoryQuery({
    fetchPolicy: "network-only",
    variables: { policyType: policy.policyType },
  });

  if (error) {
    LogError(error);
    return null;
  }

  return (
    <Dialog
      isOpen={true}
      onClose={() => onClose()}
      title={`Version History for ${policy.title}`}
    >
      {loading ? (
        <Spinner />
      ) : (
        <DialogBody>
          {data?.organization.policyVersionHistory.map(version => {
            const Download = (
              <>
                {"  "}
                <Link
                  rel="noopener noreferrer"
                  target="_blank"
                  to={version.uploadedDoc.url}
                >
                  <Icon icon={IconNames.PAPERCLIP} />
                </Link>
              </>
            );
            // renewed policies have the same created and approval times
            // policy renewals dont show the created time, because it's a pseudo document
            // used to have a separate approval time
            const isRenewal =
              isSome(version.approvedAt) &&
              moment(new Date(version.approvedAt)).isSame(
                moment(version.createdAt)
              );
            return (
              <React.Fragment key={version.id}>
                {!isRenewal && (
                  <Row>
                    <RowQuestion>
                      {moment(version.createdAt).format(UI_DATE_FORMAT)}
                    </RowQuestion>
                    <RowAnswer>
                      {version.creatorName} created a new policy
                      {Download}
                    </RowAnswer>
                  </Row>
                )}
                {isSome(version.approvedAt) && (
                  <Row>
                    <RowQuestion>
                      {/* TODO - DZ mid migration. Remove the date wrapping when done */}
                      {moment(new Date(version.approvedAt)).format(
                        UI_DATE_FORMAT
                      )}
                    </RowQuestion>
                    <RowAnswer>
                      {version.approverName} approved the policy
                      {Download}
                    </RowAnswer>
                  </Row>
                )}
              </React.Fragment>
            );
          })}
        </DialogBody>
      )}
    </Dialog>
  );
};

gql`
  query GetPolicyVersionHistory($policyType: String!) {
    organization {
      id
      displayName
      name
      slug
      policyVersionHistory(policyType: $policyType) {
        id
        approvedAt
        createdAt
        generating
        uploadedDoc {
          id
          url
        }
        creatorName
        approverName
      }
    }
  }
`;
