export function getOS() {
  const userAgent = navigator.userAgent;

  // Check iPhone/iPad and Android first as their user agents also contain OS X and linux.
  if (userAgent.includes("iPhone") || userAgent.includes("iPad")) {
    return "iOS";
  } else if (userAgent.includes("Android")) {
    return "Android";
  } else if (userAgent.includes("OS X")) {
    return "Mac";
  } else if (userAgent.includes("Windows")) {
    return "Windows";
  } else if (userAgent.includes("Linux")) {
    return "Linux";
  }
  return "other";
}
