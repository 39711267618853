import { ReportStandard } from "../base/types/gen";
import type { Maybe } from "../base/types/maybe";
import { isSome } from "../base/types/maybe";
import { StandardToDisplayName } from "../constants/displayNames";
import type { IStandard } from "./complianceTypes";
import type { ALL_TSCS } from "./soc2/tsc-utils";
import { DEFAULT_TSCS, sectionInEnabledTscs } from "./soc2/tsc-utils";
import type { StandardControl, StandardMapping } from "./standard-mapping";

type MappedSection = StandardMapping["sections"][number];

// Most of the contents of this type are relics from half-baked standard configuration implementations (e.g. SOC 2 TSC features).
// We will likely migrate this to a more elegant system when we implement robust standard configuration.
export type StandardConfiguration = {
  soc2Tscs: Maybe<Array<typeof ALL_TSCS[number]>>; // The list of SOC 2 TSCs the standard should include.
};

/*
  A class representing a Standard.

  A Standard is constructed from two components:
  - The standard DEFINITION: This is the information about the standard.
      It contains the standard copy, requirements, etc (e.g. TSCs for SOC 2, or Administrative Safeguards for HIPAA).
      The Standard Definition is not controlled by Vanta - it's controlled by the organization that develops the standard.
      We just represent it internally, and update it when a new version comes out (e.g. SOC 2 2021).
  - A standard MAPPING: This is the Vanta-specific data about how a standard is mapped.
      It contains a list of controls, a mapping from standard sections to controls, and a mapping from controls to tests.
      The Standard Mapping is controlled by Vanta - we have the freedom to change our mapping when we adjust controls or tests.

  To construct this class, pair a Standard Definition with a Standard Mapping.
  The class will then "merge" the two where needed in queries.

  This class allows for pairing any two definitions and mappings for a given standard.
  (e.g. you could match the definition for "SOC 2 2018" with "Mapping version 1.1", or "SOC 2 2020" with "Mapping version 1.0").

  This is a class, rather than a type, because it contains internal merging logic and exposes utility methods.
*/
export class Standard {
  private readonly standardDefinition: IStandard;
  private readonly standardMapping: StandardMapping;

  // Used for quick internal lookups
  private readonly sectionsBySectionId: Map<string, MappedSection>;
  private readonly controlsByControlId: Map<string, StandardControl>;
  private readonly controlsByTestId: Map<string, StandardControl[]>;
  private readonly controlsByEvidenceRequestId: Map<string, StandardControl[]>;
  private readonly sectionsByControlId: Map<string, MappedSection[]>;

  private readonly soc2Tscs: Set<string>;
  public readonly hasRoles: boolean;

  constructor(
    rawStandardDefinition: IStandard,
    rawStandardMapping: StandardMapping,
    config?: Maybe<StandardConfiguration>
  ) {
    this.soc2Tscs = new Set(config?.soc2Tscs ?? DEFAULT_TSCS);

    this.standardDefinition = this.adjustDefinitionUsingConfiguration(
      rawStandardDefinition
    );
    this.standardMapping =
      this.adjustMappingUsingConfiguration(rawStandardMapping);

    this.sectionsBySectionId = new Map(
      this.standardMapping.sections.map(s => [s.id, s])
    );
    this.controlsByControlId = new Map(
      this.standardMapping.controls.map(c => [c.id, c])
    );

    this.controlsByTestId = this.standardMapping.controls.reduce(
      (acc, control) => {
        const { tests } = control;
        for (const testId of tests.map(t => t.id)) {
          const controls = acc.get(testId) ?? [];
          controls.push(control);
          acc.set(testId, controls);
        }
        return acc;
      },
      new Map<string, StandardControl[]>()
    );

    this.controlsByEvidenceRequestId = this.standardMapping.controls.reduce(
      (acc, control) => {
        const { manualEvidenceRequests } = control;
        for (const evidenceRequestId of (manualEvidenceRequests ?? []).map(
          er => er.id
        )) {
          const controls = acc.get(evidenceRequestId) ?? [];
          controls.push(control);
          acc.set(evidenceRequestId, controls);
        }
        return acc;
      },
      new Map<string, StandardControl[]>()
    );

    this.sectionsByControlId = this.createSectionsByControlIdMap(
      this.standardMapping
    );

    this.hasRoles = isSome(this.standardMapping.controls[0].role);
  }

  public getDisplayName(): string {
    return StandardToDisplayName(this.getStandard());
  }

  /* The ReportStandard this standard corresponds to. */
  public getStandard(): ReportStandard {
    return this.standardDefinition.standard;
  }

  public getStandardDefinition() {
    return this.standardDefinition;
  }

  public getStandardMapping() {
    return this.standardMapping;
  }

  public hasControl(controlId: string) {
    return this.controlsByControlId.has(controlId);
  }

  /* Returns the controls for a given section. */
  public controlsForSection(sectionId: string): StandardControl[] {
    const section = this.sectionsBySectionId.get(sectionId);
    if (!isSome(section)) {
      return [];
    }

    const sectionControlIds = section.controls.map(c => c.id);
    return sectionControlIds.map(
      controlId => this.controlsByControlId.get(controlId)!
    );
  }

  /* Returns the controls that have a given test ID. */
  public controlsContainingTestId(testId: string): StandardControl[] {
    return this.controlsByTestId.get(testId) ?? [];
  }

  public controlsContainingEvidenceRequestId(
    evidenceRequestId: string
  ): StandardControl[] {
    return this.controlsByEvidenceRequestId.get(evidenceRequestId) ?? [];
  }

  public hasEvidenceRequest(evidenceRequestId: string) {
    return this.controlsByEvidenceRequestId.has(evidenceRequestId);
  }

  /* Returns the sections that have a given control ID. */
  public sectionsContainingControlId(controlId: string): MappedSection[] {
    return this.sectionsByControlId.get(controlId) ?? [];
  }

  private createSectionsByControlIdMap(mapping: StandardMapping) {
    return mapping.sections.reduce((acc, section) => {
      const { controls } = section;
      for (const controlId of controls.map(c => c.id)) {
        const sections = acc.get(controlId) ?? [];
        sections.push(section);
        acc.set(controlId, sections);
      }
      return acc;
    }, new Map<string, MappedSection[]>());
  }

  /* Takes the Standard Mapping and filters out metadata that is not relevant based on the standard configuration.
  ex. If only the "Security" SOC 2 TSC is enabled, this function will filter out all mappings for sections that are not "Security".
  */
  private adjustMappingUsingConfiguration(
    oldMapping: StandardMapping
  ): StandardMapping {
    const standardId = oldMapping.standardInfo.standard;
    if (standardId !== ReportStandard.soc2) {
      return oldMapping; // No configurability for other standards at the moment
    }

    // Filter out sections that are not relevant
    const relevantSections = oldMapping.sections.filter(s =>
      sectionInEnabledTscs(s.id, this.soc2Tscs)
    );

    const mappingWithSectionsFiltered = {
      ...oldMapping,
      sections: relevantSections,
    };

    const sectionsByControlId = this.createSectionsByControlIdMap(
      mappingWithSectionsFiltered
    );

    // Filter out controls that no longer have sections referencing them
    const relevantControls = oldMapping.controls.filter(
      c =>
        sectionsByControlId.has(c.id) &&
        (sectionsByControlId.get(c.id) ?? []).length > 0
    );

    return {
      ...mappingWithSectionsFiltered,
      controls: relevantControls,
    };
  }

  /* Takes the Standard Definition and filters out metadata that is not relevant based on the standard configuration.
  ex. If only the "Security" SOC 2 TSC is enabled, this function will filter out all mappings for sections that are not "Security".
  */
  private adjustDefinitionUsingConfiguration(
    oldDefinition: IStandard
  ): IStandard {
    const standardId = oldDefinition.standard;
    if (standardId !== ReportStandard.soc2) {
      return oldDefinition; // No configurability for other stnadards at the moment
    }

    // Filter out principles that are not relevant
    const relevantPrinciples = oldDefinition.principles.filter(p =>
      sectionInEnabledTscs(p.section, this.soc2Tscs)
    );

    return {
      ...oldDefinition,
      principles: relevantPrinciples,
    };
  }
}
