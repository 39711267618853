import { isSome } from "common/base/types/maybe";
import { groupBy } from "lodash";
import React, { useMemo } from "react";

import { LogError, LogErrorMessage } from "../../../errors";
import { useAllLinkedCredentialsQuery } from "../../../gen/components";
import { useAvailableBetaFeatures } from "../../../helpers/feature-gating/feature-check";
import { FullPageSpinner } from "../../helpers/FullPageSpinner";
import { LinkedServicesContext } from "../../pages/credential/linked-services-context";
import { ServiceGroups } from "../service-groups";
import { ServiceGroup } from "./service-group";

const NO_GROUP = "No Group";

/**
 * AllLinkableServiceGroups is just a wrapper to issue a single query for all
 * credentials.
 *
 * It can be rolled into @see ManageCredentialsPage when it's no longer hidden
 * behind a feature flag.
 */
export const AllLinkableServiceGroups: React.FC = () => {
  const { error, loading, data } = useAllLinkedCredentialsQuery();
  const availableBetaFeatures = useAvailableBetaFeatures();

  const credentialsByGroup = useMemo(() => {
    const mapping = groupBy(
      data?.organization.credentials ?? [],
      c =>
        ServiceGroups.find(g =>
          g.services.some(service => service.id === c.service)
        )?.name ?? NO_GROUP
    );
    if (isSome(mapping[NO_GROUP]) && mapping[NO_GROUP].length > 0) {
      LogErrorMessage(
        `Found services without service groups: ${mapping[NO_GROUP].map(
          c => c.service
        ).join(",")}`
      );
    }
    return mapping;
  }, [data?.organization]);

  if (error) {
    LogError(error);
    return null;
  }
  if (loading) {
    return <FullPageSpinner />;
  }

  if (!data) {
    LogErrorMessage("Bad fetch");
    return null;
  }

  return (
    <LinkedServicesContext.Provider
      value={{
        linkedServices: new Set(
          data.organization.credentials.map(c => c.service)
        ),
      }}
    >
      <div>
        {ServiceGroups.filter(
          group =>
            !isSome(group.feature) || availableBetaFeatures?.has(group.feature)
        ).map(group => (
          <ServiceGroup
            key={group.name}
            serviceGroup={group}
            relevantCredentials={credentialsByGroup[group.name] ?? []}
          />
        ))}
      </div>
    </LinkedServicesContext.Provider>
  );
};
