import { Intent, Tab } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import {
  AuditTypeToHumanName,
  AuditTypeToStandard,
} from "common/constants/displayNames";
import { simplePlural } from "common/grammar/plurals";
import gql from "graphql-tag";
import { groupBy, partition } from "lodash";
import moment from "moment";
import React, { useState, useMemo } from "react";
import { Redirect, useHistory } from "react-router";
import {
  AccordionRow,
  BodyText,
  Button,
  ContentCard,
  DefaultView,
  Dialog,
  EmptyStateCard,
  HeaderlessTabs,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  SpacedList,
  Tag,
  Tooltip,
} from "../../../alpaca/components";
import { LogErrorMessage } from "../../../errors";
import {
  useAssumeUserMutationAuditorMutation,
  useGetAuditorEngagementInfoQuery,
  useDeleteEngagementMutation,
  useSetObservationPeriodMutation,
  GetAuditorEngagementInfoDocument,
} from "../../../gen/components";
import { AppToaster } from "../../../helpers/toaster";
import { FullPageSpinner } from "../../helpers/FullPageSpinner";
import { DropdownButton } from "../components/dropdown-button";
import { TableSearchInput } from "../components/table-controls";
import { TableCell, TableRow } from "../tests/shared/table-row";
import { CompleteEngagementDialog } from "./complete-engagement-dialog";
import { CreateEngagementDialog } from "./create-engagement-dialog";
import { DateInputPair } from "./date-input-pair";
import type { AuditorPageEngagement, NonNullDateRange } from "./utils";
import { STARTER_ENGAGEMENT_DATE_RANGE, StyledDialogFooterDiv } from "./utils";

const TAB_INFO = {
  ACTIVE: { title: "Active", id: "active" },
  COMPLETED: { title: "Completed", id: "completed" },
};

const SEARCH_PARAMS = {
  TAB: "tab",
  COMPANY: "company",
};

export const AuditorEngagementsPage: React.FC = () => {
  const { tabId, customerFilter } = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return {
      tabId: params.get(SEARCH_PARAMS.TAB),
      customerFilter: params.get(SEARCH_PARAMS.COMPANY),
    };
  }, [location.search]);
  const history = useHistory();

  const [assumeUser] = useAssumeUserMutationAuditorMutation();
  const [deleteEngagment] = useDeleteEngagementMutation({
    refetchQueries: [{ query: GetAuditorEngagementInfoDocument }],
    onCompleted: () => {
      AppToaster.show({
        intent: Intent.SUCCESS,
        message: "Engagement deleted",
      });
    },
  });
  const [setObservationPeriod] = useSetObservationPeriodMutation({
    onCompleted: () => {
      AppToaster.show({
        intent: Intent.SUCCESS,
        message: "Observation updated",
      });
    },
  });
  const { loading, data } = useGetAuditorEngagementInfoQuery();

  const [searchString, setSearchString] = useState("");
  const [engagementToEdit, setEngagmentToEdit] =
    useState<Maybe<AuditorPageEngagement>>(null);
  const [engagementToComplete, setEngagementToComplete] =
    useState<Maybe<AuditorPageEngagement>>(null);
  const [engagementToDelete, setEngagementToDelete] =
    useState<Maybe<AuditorPageEngagement>>(null);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [newDateRange, setNewDateRange] = useState<NonNullDateRange>(
    STARTER_ENGAGEMENT_DATE_RANGE
  );
  const [deleteInFlight, setDeleteInFlight] = useState(false);
  const [editInFlight, setEditInFlight] = useState(false);

  const lowerCasedSearch = searchString.toLocaleLowerCase().trim();
  const noSearch = lowerCasedSearch === "";

  const {
    sortedActiveEngagements,
    sortedCompletedEngagements,
    hasActiveEngagements,
    hasCompletedEngagements,
  } = useMemo(() => {
    const allEngagements = (data?.user?.auditEngagements ?? [])
      .slice()
      .sort(
        (e1, e2) =>
          new Date(e1.observationStartDate).valueOf() -
          new Date(e2.observationStartDate).valueOf()
      );

    const [activeEngagements, completedEngagements] = partition(
      allEngagements,
      e => !isSome(e.completionDate)
    );

    const allSortedActiveEngagements =
      sortAndFilterEngagementsByDomainId(activeEngagements);

    const allSortedCompletedEngagements =
      sortAndFilterEngagementsByDomainId(completedEngagements);

    return {
      sortedCompletedEngagements: allSortedCompletedEngagements,
      sortedActiveEngagements: allSortedActiveEngagements,
      hasActiveEngagements: activeEngagements.length > 0,
      hasCompletedEngagements: completedEngagements.length > 0,
    };

    function sortAndFilterEngagementsByDomainId(
      engagements: typeof allEngagements
    ) {
      const engagementsFilteredOnSearch = noSearch
        ? engagements
        : engagements.filter(
            e =>
              e.domainName.toLocaleLowerCase().includes(lowerCasedSearch) ||
              AuditTypeToHumanName[e.auditType]
                .toLocaleLowerCase()
                .includes(lowerCasedSearch)
          );

      const engagementsByDomainId = groupBy(
        engagementsFilteredOnSearch,
        e => e.domainId
      );
      return Object.values(engagementsByDomainId)
        .sort((e1, e2) => e1[0].domainName.localeCompare(e2[0].domainName))
        .filter(
          e => !isSome(customerFilter) || e[0].domainId === customerFilter
        );
    }
  }, [data?.user?.auditEngagements, searchString, customerFilter]);

  if (loading) {
    return <FullPageSpinner />;
  }

  const user = data?.user;
  if (!user) {
    return <Redirect to="login" />;
  }
  if (!user.auditorInfo) {
    throw new Error("No auditor info but we're on the auditor page");
  }

  const now = moment();

  return (
    <DefaultView
      headerProps={{
        title: "Engagements",
        rightControls: [
          <TableSearchInput
            key="search"
            onChange={e => setSearchString(e.target.value)}
            placeholder="Search all engagements"
            value={searchString}
            leftIcon={IconNames.SEARCH}
          />,
          <DropdownButton
            styleOnSelect
            buttonWidth={152}
            key="company-dropdown"
            selectedOption={user.auditableUsers.find(
              u => u.domain.id === customerFilter
            )}
            options={user.auditableUsers}
            optionRenderer={u => u.domain.displayName}
            defaultText="Company"
            onOptionSelect={option => {
              const params = new URLSearchParams(location.search);
              if (isSome(option)) {
                params.set(SEARCH_PARAMS.COMPANY, option.domain.id);
              } else {
                params.delete(SEARCH_PARAMS.COMPANY);
              }
              history.push({ search: params.toString() });
            }}
            nullOption={isSome(customerFilter) ? "Clear" : null}
            nullOptionLast
          />,
          <Button
            key="create"
            rightIcon="plus"
            disabled={user.auditableUsers.length === 0}
            onClick={() => setCreateDialogOpen(true)}
          >
            Create
          </Button>,
        ],
        tabProps: {
          id: "engagement-page-tabs",
          tabIds: [TAB_INFO.ACTIVE, TAB_INFO.COMPLETED],
          selectedTabId: tabId ?? TAB_INFO.ACTIVE.id,
          onChange: newTabId => {
            const params = new URLSearchParams(location.search);
            params.set(SEARCH_PARAMS.TAB, newTabId);
            history.push({ search: params.toString() });
          },
        },
      }}
    >
      <HeaderlessTabs
        id="engagement-tab-body"
        selectedTabId={tabId ?? TAB_INFO.ACTIVE.id}
      >
        <Tab
          id={TAB_INFO.ACTIVE.id}
          panel={
            <>
              {sortedActiveEngagements.length === 0 ? (
                <EmptyStateCard
                  mainText={
                    hasActiveEngagements
                      ? "No matching engagements"
                      : "No active engagements"
                  }
                />
              ) : null}
              {sortedActiveEngagements.map(engagementSet => {
                const { domainName, domainId } = engagementSet[0];
                return (
                  <ContentCard key={domainId}>
                    <AccordionRow
                      style={{ marginTop: -1 }}
                      stickyTitle
                      title={domainName}
                      rightElements={[
                        <Tag
                          key="sum"
                          intent={Intent.SUCCESS}
                          text={`${engagementSet.length} Active`}
                        />,
                      ]}
                    >
                      {engagementSet.map(e => {
                        let text: string;
                        let observationPeriodEnded = false;
                        const startMoment = moment(e.observationStartDate);
                        if (now.isBefore(startMoment)) {
                          const daysOut = startMoment.diff(now, "day");
                          text = `Observation begins ${
                            daysOut === 0
                              ? "today"
                              : `in ${simplePlural(
                                  startMoment.diff(now, "day"),
                                  "day"
                                )}`
                          }`;
                        } else {
                          const endMoment = moment(e.observationEndDate);
                          if (now.isBefore(endMoment)) {
                            text = `In observation`;
                          } else {
                            text = `Observation ended ${endMoment.format(
                              "M/D/YYYY"
                            )}`;
                            observationPeriodEnded = true;
                          }
                        }

                        async function handleAssumeDomain() {
                          const userToAssume = user?.auditableUsers.find(
                            u => u.domain.id === e.domainId
                          );
                          if (!userToAssume) {
                            LogErrorMessage("No user to assume");
                          } else {
                            const standard = AuditTypeToStandard[e.auditType];
                            await assumeUser({
                              variables: {
                                email: userToAssume.email,
                              },
                            });
                            location.href = `/standards/${standard}/controls`;
                          }
                        }

                        return (
                          <TableRow onClick={handleAssumeDomain} key={e.id}>
                            <TableCell style={{ flexGrow: 1 }}>
                              <div style={{ display: "inline-block" }}>
                                <Tag text={AuditTypeToHumanName[e.auditType]} />
                              </div>
                            </TableCell>
                            <TableCell style={{ flexShrink: 0 }}>
                              <SpacedList>
                                <BodyText as="div">{text}</BodyText>
                                {observationPeriodEnded ? (
                                  <Tooltip
                                    content="Mark complete"
                                    placement="top"
                                  >
                                    <Button
                                      style={{
                                        height: 24,
                                        width: 28,
                                        padding: "4px 8px",
                                        minHeight: 24,
                                        minWidth: 28,
                                      }}
                                      icon={
                                        <Icon icon="success" iconSize={12} />
                                      }
                                      onClick={event => {
                                        event.stopPropagation();
                                        setEngagementToComplete(e);
                                      }}
                                    />
                                  </Tooltip>
                                ) : null}
                                <MenuButton
                                  captureClickEvent
                                  small
                                  menu={
                                    <Menu>
                                      <MenuItem
                                        text="View evidence"
                                        onClick={handleAssumeDomain}
                                      />
                                      <MenuItem
                                        text="Change observation period"
                                        onClick={event => {
                                          event.stopPropagation();
                                          setEngagmentToEdit(e);
                                          setNewDateRange([
                                            new Date(e.observationStartDate),
                                            new Date(e.observationEndDate),
                                          ]);
                                        }}
                                      />
                                      <MenuItem
                                        text="Delete"
                                        onClick={event => {
                                          event.stopPropagation();
                                          setEngagementToDelete(e);
                                        }}
                                      />
                                    </Menu>
                                  }
                                />
                              </SpacedList>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </AccordionRow>
                  </ContentCard>
                );
              })}
            </>
          }
        />
        <Tab
          id={TAB_INFO.COMPLETED.id}
          panel={
            <>
              {sortedCompletedEngagements.length === 0 ? (
                <EmptyStateCard
                  mainText={
                    hasCompletedEngagements
                      ? "No matching engagements"
                      : "No completed engagements"
                  }
                />
              ) : null}
              {sortedCompletedEngagements.map(engagementSet => {
                const { domainId, domainName } = engagementSet[0];
                return (
                  <ContentCard key={domainId}>
                    <AccordionRow
                      style={{ marginTop: -1 }}
                      stickyTitle
                      title={domainName}
                      rightElements={[
                        <Tag
                          key="sum"
                          intent={Intent.NONE}
                          text={`${engagementSet.length} Completed`}
                        />,
                      ]}
                    >
                      {engagementSet.map(e => {
                        const text = `Completed ${moment(
                          e.completionDate!
                        ).format("M/D/YYYY")}`;
                        return (
                          <TableRow key={e.id}>
                            <TableCell style={{ flexGrow: 1 }}>
                              <div style={{ display: "inline-block" }}>
                                <Tag text={AuditTypeToHumanName[e.auditType]} />
                              </div>
                            </TableCell>
                            <TableCell style={{ flexShrink: 0 }}>
                              <SpacedList>
                                <BodyText as="div">{text}</BodyText>
                              </SpacedList>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </AccordionRow>
                  </ContentCard>
                );
              })}
            </>
          }
        />
      </HeaderlessTabs>
      <CreateEngagementDialog
        isOpen={createDialogOpen}
        onClose={() => setCreateDialogOpen(false)}
        assumableUsers={user.auditableUsers}
      />
      <CompleteEngagementDialog
        engagement={engagementToComplete}
        onClose={() => setEngagementToComplete(null)}
      />
      <Dialog
        isOpen={isSome(engagementToEdit)}
        onClose={closeEditDialog}
        title="Edit observation period"
      >
        <BodyText>Observation period</BodyText>
        <DateInputPair onNewValue={setNewDateRange} dateRange={newDateRange} />
        <StyledDialogFooterDiv>
          <Button
            intent={Intent.PRIMARY}
            loading={editInFlight}
            onClick={async () => {
              setEditInFlight(true);
              await setObservationPeriod({
                variables: {
                  input: {
                    auditId: engagementToEdit!.id,
                    customerDomainId: engagementToEdit!.domainId,
                    observationEndDate: newDateRange[1]!.toISOString(),
                    observationStartDate: newDateRange[0]!.toISOString(),
                  },
                },
              });
              closeEditDialog();
            }}
          >
            Save changes
          </Button>
        </StyledDialogFooterDiv>
      </Dialog>
      <Dialog
        isOpen={isSome(engagementToDelete)}
        onClose={closeDeleteDialog}
        title="Delete engagement"
      >
        <BodyText>Delete this engagement?</BodyText>
        <StyledDialogFooterDiv>
          <SpacedList>
            <Button disabled={deleteInFlight} onClick={closeDeleteDialog}>
              Cancel
            </Button>
            <Button
              loading={deleteInFlight}
              intent={Intent.PRIMARY}
              onClick={async () => {
                setDeleteInFlight(true);
                await deleteEngagment({
                  variables: {
                    input: {
                      auditId: engagementToDelete!.id,
                      customerDomainId: engagementToDelete!.domainId,
                    },
                  },
                });
                closeDeleteDialog();
              }}
            >
              Delete
            </Button>
          </SpacedList>
        </StyledDialogFooterDiv>
      </Dialog>
    </DefaultView>
  );
  function closeEditDialog() {
    setEngagmentToEdit(null);
    setNewDateRange(STARTER_ENGAGEMENT_DATE_RANGE);
    setEditInFlight(false);
  }
  function closeDeleteDialog() {
    setEngagementToDelete(null);
    setDeleteInFlight(false);
  }
};

gql`
  query getAuditorEngagementInfo {
    user {
      id
      auditableUsers {
        id
        domain {
          id
          displayName
          standards
        }
        email
      }
      auditEngagements {
        id
        auditType
        completionDate
        observationEndDate
        observationStartDate
        domainName
        domainId
      }
      auditorInfo {
        id
        firmName
      }
    }
  }

  mutation setObservationPeriod($input: SetAuditObservationPeriodInput!) {
    setAuditObservationPeriod(input: $input) {
      ... on SetAuditObservationPeriodSuccess {
        audit {
          id
          observationEndDate
          observationStartDate
        }
      }
    }
  }

  mutation deleteEngagement($input: DeleteAuditEngagementInput!) {
    deleteAuditEngagement(input: $input) {
      ... on DeleteAuditEngagementSuccess {
        audit {
          id
        }
      }
    }
  }
`;
