import type { PureQueryOptions } from "@apollo/client";
import { ErrorWithMetadata } from "common/base/types/errors";
import type { Maybe } from "common/base/types/maybe";
import { isSome, getTransformedOrElse } from "common/base/types/maybe";
import gql from "graphql-tag";
import React, { useState } from "react";
import { GRID_SPACING } from "../../../../alpaca/base/grid";
import type { IconName } from "../../../../alpaca/components";
import { IconNames } from "../../../../alpaca/components";
import { LogError } from "../../../../errors";
import {
  GetUserInfoForOnboardingListDocument,
  useDeleteUserSecurityTrainingInfoMutation,
} from "../../../../gen/components";
import { DefaultLink } from "../../../../helpers/links";
import { CancelConfirmDialog } from "../../../helpers/CancelConfirmDialog";
import { IconButton } from "../shared/icon-button";
import type { OnboardingListUser } from "./user-onboarding-list";

interface IProps {
  completion:
    | NonNullable<OnboardingListUser["mostRecentSecurityTraining"]>
    | NonNullable<OnboardingListUser["trainingRequirements"][number]>;
  userId: string;
  refetchQueries?: Maybe<PureQueryOptions[]>;
  renderButton?: Maybe<
    (onClick: () => void, icon: IconName, tooltipContent: string) => JSX.Element
  >;
}

const InAppCompletionDetail: React.FC<{
  completion: NonNullable<OnboardingListUser["mostRecentSecurityTraining"]>;
  userId: string;
  refetchQueries?: Maybe<PureQueryOptions[]>;
  renderButton?: Maybe<
    (onClick: () => void, icon: IconName, tooltipContent: string) => JSX.Element
  >;
}> = ({ completion, userId, refetchQueries, renderButton }) => {
  const [isOpen, setIsOpen] = useState(false);

  const [deleteUserSecurityTrainingInfo, mutationResult] =
    useDeleteUserSecurityTrainingInfoMutation({
      refetchQueries: refetchQueries ?? [
        {
          query: GetUserInfoForOnboardingListDocument,
          variables: { userId },
        },
      ],
    });

  if (completion.__typename !== "securityTrainingDocumentCompletion") {
    return null;
  }

  const onViewClick = () =>
    window.open(completion.uploadedDocument.url, "_blank");

  return (
    <div style={{ display: "flex" }}>
      <div style={{ marginRight: `${GRID_SPACING}px` }}>
        {getTransformedOrElse(
          renderButton,
          render => render(() => setIsOpen(true), IconNames.DELETE, "Delete"),
          <IconButton
            onClick={() => setIsOpen(true)}
            icon={IconNames.DELETE}
            tooltipContent={"Delete"}
          />
        )}
      </div>
      {getTransformedOrElse(
        renderButton,
        render => render(onViewClick, IconNames.VIEW, "View"),
        <IconButton
          onClick={onViewClick}
          icon={IconNames.VIEW}
          tooltipContent={"View"}
        />
      )}
      <CancelConfirmDialog
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onConfirm={async () => {
          try {
            await deleteUserSecurityTrainingInfo({
              variables: {
                uploadedDocumentId: completion.uploadedDocument.id,
              },
            });
          } catch (e) {
            LogError(e);
          }
          setIsOpen(false);
        }}
        loading={mutationResult.loading}
        title="Delete Evidence"
        confirmText="Delete"
        body={
          <div>
            <p>
              Are you sure you want to delete{" "}
              <DefaultLink
                href={completion.uploadedDocument.url}
                target={"_blank"}
              >
                this evidence
              </DefaultLink>
              ?
            </p>
          </div>
        }
      />
    </div>
  );
};

const IntegrationCompletionDetail: React.FC<{
  completion: NonNullable<OnboardingListUser["trainingRequirements"][number]>;
  renderButton?: Maybe<
    (onClick: () => void, icon: IconName, tooltipContent: string) => JSX.Element
  >;
}> = ({ completion, renderButton }) => {
  if (!isSome(completion.externalURL)) {
    return null;
  }
  const onViewClick = () => window.open(completion.externalURL!, "_blank");
  const viewButton = getTransformedOrElse(
    renderButton,
    render => render(onViewClick, IconNames.VIEW, "View"),
    <IconButton
      onClick={onViewClick}
      icon={IconNames.VIEW}
      tooltipContent={"View"}
    />
  );

  return <div style={{ display: "flex" }}>{viewButton}</div>;
};

export const SecurityTrainingDetail: React.FC<IProps> = ({
  completion,
  userId,
  refetchQueries,
  renderButton,
}) => {
  if (completion.__typename === "SpecificKnowBe4TrainingEnrollmentResource") {
    return (
      <IntegrationCompletionDetail
        completion={completion}
        renderButton={renderButton}
      />
    );
  } else if (
    completion.__typename === "securityTrainingDocumentCompletion" ||
    completion.__typename === "securityTrainingVideoCompletion"
  ) {
    return (
      <InAppCompletionDetail
        completion={completion}
        userId={userId}
        refetchQueries={refetchQueries}
        renderButton={renderButton}
      />
    );
  }
  LogError(
    new ErrorWithMetadata("Unknown type for SecurityTrainingDetail", {
      type: completion.__typename,
    })
  );
  return null;
};

gql`
  mutation deleteUserSecurityTrainingInfo($uploadedDocumentId: ID!) {
    deleteSecurityTrainingDocument(
      input: { uploadedDocumentId: $uploadedDocumentId }
    ) {
      __typename
    }
  }
`;
