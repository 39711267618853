import { Intent } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import React from "react";
import styled from "styled-components";

import { VantaButtonDeprecated } from "../../../alpaca/base/deprecated";
import { H2 } from "../../../alpaca/components";

type LabelValuePair = {
  label: string;
  value: string | number;
};

interface IProps {
  textContent: React.ReactNode;
  buttonValues: LabelValuePair[];
  selectedValue?: Maybe<string | number>;
  onNewValue: (value: string | number) => void;
}

export const ButtonGroupPanel: React.FC<IProps> = ({
  textContent,
  buttonValues,
  selectedValue,
  onNewValue,
}) => (
  <Container>
    <H2>{textContent}</H2>
    <ButtonGroupContainer>
      {buttonValues.map(buttonValue => (
        <VantaButtonDeprecated
          intent={
            selectedValue === buttonValue.value ? Intent.PRIMARY : undefined
          }
          key={buttonValue.value}
          onClick={() => {
            onNewValue(buttonValue.value);
          }}
        >
          {buttonValue.label}
        </VantaButtonDeprecated>
      ))}
    </ButtonGroupContainer>
  </Container>
);

const styles = {
  CONTAINER_PADDING_BOTTOM: 30,
  BUTTON_SIDE_MARGIN: 4,
  BUTTON_SIDE_PADDING: 4,
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 50%;
  padding-bottom: ${styles.CONTAINER_PADDING_BOTTOM}px;
`;

const ButtonGroupContainer = styled.div`
  display: flex;
  justify-content: space-between;
  & > ${VantaButtonDeprecated} {
    flex: 1 1 0px;
    margin-right: ${styles.BUTTON_SIDE_MARGIN}px;
    padding-left: ${styles.BUTTON_SIDE_PADDING}px;
    padding-right: ${styles.BUTTON_SIDE_PADDING}px;
    display: flex;
    justify-content: space-around;
  }
`;
