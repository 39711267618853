import { TestOutcome } from "common/base/types/gen";
import type { Maybe } from "common/base/types/maybe";
import { dropNothing, isSome } from "common/base/types/maybe";
import React from "react";

import type { GetDataForReportQuery } from "../../../../../gen/components";
import WhiteIcon from "../../../../../static/images/reports/icon_blank_space.svg";
import SuccessIcon from "../../../../../static/images/reports/icon_complete.svg";
import InProgressIcon from "../../../../../static/images/reports/icon_in_progress.svg";
import NeedsWorkIcon from "../../../../../static/images/reports/icon_needs_work.svg";
import type { PresentedReportEvidenceResults } from "../../../../pages/viewerAuth";
import type { IDomainForReport } from "../../../helpers/helpers";

interface IProps {
  control: NonNullable<
    GetDataForReportQuery["complianceStandard"]
  >["principles"][number]["requirements"][number]["controls"][number];
  domain: IDomainForReport;
}

export const VantaReportControlStatusIndicator: React.FunctionComponent<IProps> =
  ({ control, domain }) => {
    const testStatus = testStatusForControl(control, domain.testIdToTestMap);
    const evidenceStatus = evidenceStatusForControl(
      control,
      domain.evidenceIdToEvidenceMap
    );
    const status = combineStatuses(testStatus, evidenceStatus);
    const StatusSVG = svgForStatus(status);
    const svg = <StatusSVG color="red" />;
    const text = <div>{textForStatus(status)}</div>;
    return (
      <div
        className={`vrp-control-list-control-status ${classForStatus(status)}`}
      >
        {svg}
        {text}
      </div>
    );
  };

enum CONTROL_STATUS {
  COMPLETE,
  NEEDS_WORK,
  IN_PROGRESS,
  NA,
  PARTIALLY_VERIFIED,
  UNVERIFIED,
}

const testOutcomeFlagsForControl = (
  control: IProps["control"],
  testResultMap: Map<
    string,
    Maybe<
      NonNullable<
        GetDataForReportQuery["user"]
      >["domain"]["testResults"][number]
    >
  >
) => {
  let [hasPass, hasFail, hasInProgress, hasNA, hasNoData] = [
    false,
    false,
    false,
    false,
    false,
  ];

  control.tests.forEach(test => {
    const result = testResultMap.get(test.testId);
    if (!isSome(result)) {
      hasNoData = true;
    } else {
      switch (result.outcome) {
        case TestOutcome.PASS:
          hasPass = true;
          break;
        case TestOutcome.FAIL:
          hasFail = true;
          break;
        case TestOutcome.NA:
          hasNA = true;
          break;
        case TestOutcome.IN_PROGRESS:
          hasInProgress = true;
          break;
        default:
          break;
      }
    }
  });

  return { hasPass, hasFail, hasInProgress, hasNA, hasNoData };
};

const testStatusForControl = (
  control: IProps["control"],
  testResultMap: Map<
    string,
    Maybe<
      Maybe<
        NonNullable<
          GetDataForReportQuery["user"]
        >["domain"]["testResults"][number]
      >
    >
  >
) => {
  if (control.tests.length === 0) {
    return CONTROL_STATUS.NA;
  }
  const { hasPass, hasFail, hasInProgress, hasNoData } =
    testOutcomeFlagsForControl(control, testResultMap);

  if (hasInProgress || (hasPass && hasFail)) {
    return CONTROL_STATUS.IN_PROGRESS;
  }

  if (hasFail && !hasPass) {
    return CONTROL_STATUS.NEEDS_WORK;
  }

  if (hasNoData && hasPass) {
    return CONTROL_STATUS.PARTIALLY_VERIFIED;
  }

  if (hasPass) {
    return CONTROL_STATUS.COMPLETE;
  }

  return CONTROL_STATUS.UNVERIFIED;
};

const evidenceStatusForControl = (
  control: IProps["control"],
  evidenceMap: Map<string, PresentedReportEvidenceResults[number]>
) => {
  if (control.evidence.length === 0) {
    return CONTROL_STATUS.NA;
  }

  const evidenceArr = dropNothing(
    control.evidence
      .map(evidence => evidenceMap.get(evidence))
      .filter(evidence => isSome(evidence))
  );

  if (evidenceArr.every(evidence => evidence.outcome === TestOutcome.NA)) {
    return CONTROL_STATUS.NA;
  }

  const evidenceArrNoNA = evidenceArr.filter(
    evidence => evidence.outcome !== TestOutcome.NA
  );

  const allPass = evidenceArrNoNA.every(
    curr => curr.outcome === TestOutcome.PASS
  );
  const allFail = evidenceArrNoNA.every(
    curr => curr.outcome === TestOutcome.FAIL
  );

  if (allPass) {
    return CONTROL_STATUS.COMPLETE;
  } else if (allFail) {
    return CONTROL_STATUS.NEEDS_WORK;
  }
  return CONTROL_STATUS.IN_PROGRESS;
};

const combineStatuses = (
  testStatus: CONTROL_STATUS,
  evidenceStatus: CONTROL_STATUS
) => {
  if (testStatus === evidenceStatus) {
    return evidenceStatus;
  } else if (
    testStatus === CONTROL_STATUS.NA ||
    testStatus === CONTROL_STATUS.UNVERIFIED
  ) {
    return evidenceStatus;
  } else if (evidenceStatus === CONTROL_STATUS.NA) {
    return testStatus;
  }
  // Not possible to have FAIL and FAIL, so resulting status must be IN_PROGRESS
  return CONTROL_STATUS.IN_PROGRESS;
};

const classForStatus = (status: CONTROL_STATUS) => {
  switch (status) {
    case CONTROL_STATUS.COMPLETE:
    case CONTROL_STATUS.PARTIALLY_VERIFIED:
      return "vrp-completed";
    case CONTROL_STATUS.NEEDS_WORK:
      return "vrp-needs-work";
    case CONTROL_STATUS.IN_PROGRESS:
      return "vrp-in-progress";
    default:
      return "vrp-na";
  }
};

const textForStatus = (status: CONTROL_STATUS) => {
  switch (status) {
    case CONTROL_STATUS.COMPLETE:
      return "COMPLETE";
    case CONTROL_STATUS.PARTIALLY_VERIFIED:
      return "PARTIALLY VERIFIED";
    case CONTROL_STATUS.NEEDS_WORK:
      return "NEEDS WORK";
    case CONTROL_STATUS.IN_PROGRESS:
      return "IN PROGRESS";
    default:
      return "NOT TRACKED IN VANTA";
  }
};

const svgForStatus = (status: CONTROL_STATUS) => {
  switch (status) {
    case CONTROL_STATUS.COMPLETE:
      return SuccessIcon;
    case CONTROL_STATUS.NEEDS_WORK:
      return NeedsWorkIcon;
    case CONTROL_STATUS.IN_PROGRESS:
      return InProgressIcon;
    default:
      return WhiteIcon;
  }
};
