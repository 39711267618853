import React from "react";

import { DefaultLink } from "../../../../helpers/links";
import {
  InfoCalloutContainer,
  InfoCalloutHeading,
  InfoCalloutText,
} from "../common/components";

export const ECRRepositoryNoScanState: React.FC = () => (
  <InfoCalloutContainer>
    <InfoCalloutHeading>No vulnerability scan found</InfoCalloutHeading>
    <InfoCalloutText>
      This repository has not been scanned for vulnerabilities yet, so there are
      no findings to display.
    </InfoCalloutText>
    <InfoCalloutText>
      To enable vulnerability scanning on this repository, turn on{" "}
      <strong>Scan on Push</strong> by following the instructions{" "}
      <DefaultLink href="https://docs.aws.amazon.com/AmazonECR/latest/userguide/repository-create.html">
        here
      </DefaultLink>
      . Once enabled, AWS will scan for vulnerabilities the next time you push
      an image, and Vanta will display them.
    </InfoCalloutText>
    <InfoCalloutText>
      If you don't want to turn on Scan on Push, you can also manually trigger a
      scan by following the instructions{" "}
      <DefaultLink href="https://docs.aws.amazon.com/AmazonECR/latest/userguide/image-scanning.html#manual-scan">
        here
      </DefaultLink>
      .
    </InfoCalloutText>
    <InfoCalloutText>
      If this repository need not be scanned because it's not relevant for your
      audit, you can mark it out of scope by clicking "Configure Scope" for the
      AWS entry on the{" "}
      <DefaultLink href="/connections">Connections page</DefaultLink>.
    </InfoCalloutText>
  </InfoCalloutContainer>
);
