import { Button, H2, Intent, MenuItem } from "@blueprintjs/core";
import { Suggest } from "@blueprintjs/select";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import React, { useState } from "react";

import type { GetPolicyTitlesQuery } from "../../gen/components";
import {
  GetPolicyTitlesDocument,
  useGetPolicyTitlesQuery,
  useRemovePolicyMutation,
} from "../../gen/components";
import { AppToaster } from "../../helpers/toaster";
import { CancelConfirmDialog } from "../helpers/CancelConfirmDialog";
import { FullPageSpinner } from "../helpers/FullPageSpinner";
import { Container } from "./trigger-resource-fetch-panel";

interface IProps {
  domainId: string;
}

type PolicyType = NonNullable<
  NonNullable<
    NonNullable<GetPolicyTitlesQuery["internal"]["domainById"]>["policies"]
  >[number]
>;

const PolicySuggest = Suggest.ofType<PolicyType>();

export const PolicyPanel: React.FC<IProps> = ({ domainId }) => {
  const [policyToDelete, setPolicyToDelete] = useState<Maybe<PolicyType>>(null);
  const [removeClicked, setRemoveClicked] = useState<Maybe<Boolean>>(null);
  const { loading, data } = useGetPolicyTitlesQuery({
    variables: { domainId },
  });
  const [removePolicy] = useRemovePolicyMutation({
    refetchQueries: [
      {
        query: GetPolicyTitlesDocument,
        variables: { domainId: domainId! },
      },
    ],
    onCompleted: () => {
      AppToaster.show({
        message: `Policy is deleted succefully.`,
        intent: Intent.SUCCESS,
      });
    },
  });

  if (loading || !data) {
    return <FullPageSpinner />;
  }

  return (
    <Container>
      <H2>Selected a policy to remove:</H2>
      <PolicySuggest
        closeOnSelect
        resetOnQuery
        itemPredicate={(query, item) => {
          const policyTitle = item.title.toLowerCase();
          const queryLowerCased = query.toLowerCase();
          return (
            policyTitle.includes(queryLowerCased) || item.id === queryLowerCased
          );
        }}
        itemsEqual={(item1, item2) => item1.id === item2.id}
        inputValueRenderer={item =>
          `${item.title} — ${item.createdAt} — ${item.id}`
        }
        itemRenderer={(item, { handleClick }) => (
          <MenuItem
            key={item.id}
            text={`${item.title} — ${item.createdAt} — ${item.id}`}
            shouldDismissPopover={true}
            onClick={handleClick}
          />
        )}
        items={data.internal.domainById.policies ?? []}
        noResults={<div>No policies</div>}
        popoverProps={{ autoFocus: true }}
        onItemSelect={item => {
          setPolicyToDelete(item);
        }}
        fill={true}
      />
      <Button
        intent={Intent.DANGER}
        onClick={() => {
          setRemoveClicked(true);
        }}
      >
        Remove
      </Button>
      {isSome(removeClicked) && isSome(policyToDelete) ? (
        <CancelConfirmDialog
          body={<div>Are you sure you want to remove this policy?</div>}
          confirmText="Confirm"
          title={`Remove policy "${policyToDelete.title}"`}
          isOpen={true}
          onClose={() => {
            setPolicyToDelete(null);
            setRemoveClicked(null);
          }}
          onConfirm={() => {
            removePolicy({
              variables: {
                policyId: policyToDelete.id,
              },
            }).catch(e => {
              AppToaster.show({
                message: e.message,
                intent: Intent.WARNING,
              });
            });
            setPolicyToDelete(null);
            setRemoveClicked(null);
          }}
        />
      ) : null}
    </Container>
  );
};

gql`
  query GetPolicyTitles($domainId: ID!) {
    internal {
      domainById(id: $domainId) {
        id
        policies {
          id
          createdAt
          title
        }
      }
    }
  }
`;

gql`
  mutation removePolicy($policyId: String!) {
    removePolicy(policyId: $policyId) {
      id
      title
    }
  }
`;
