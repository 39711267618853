import {
  Button,
  FormGroup,
  Icon,
  InputGroup,
  Intent,
  Spinner,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import type { Maybe } from "common/base/types/maybe";
import { isSome, nothing } from "common/base/types/maybe";
import gql from "graphql-tag";
import moment from "moment";
import React, { useState } from "react";

import { LogError } from "../../../../errors";
import type { GetUserInfoForOnboardingQuery } from "../../../../gen/components";
import {
  GetUserInfoForOnboardingDocument,
  useCompleteOwnRoleTaskMutation,
} from "../../../../gen/components";
import { UI_DATE_FORMAT_WITHOUT_TIME } from "../../../../helpers/common";
import { DefaultLink } from "../../../../helpers/links";
import { FileInput } from "../../components/file-input";
import { MarkdownRenderer } from "../../components/markdown-renderer";

interface IProps {
  taskCompletionRecord: NonNullable<
    NonNullable<GetUserInfoForOnboardingQuery["user"]>["roleCompletionRecord"]
  >["employeeTaskCompletions"][number];
  userId: string;
}

export const OnboardingCheckableTaskV2: React.FC<IProps> = ({
  taskCompletionRecord,
  userId,
}) => {
  const [completeEmployeeOnboardingTask] = useCompleteOwnRoleTaskMutation({
    refetchQueries: [{ query: GetUserInfoForOnboardingDocument }],
  });
  const [fileToUpload, setFileToUpload] = useState<Maybe<File>>(nothing);
  const [requiredInfoValue, setRequiredInfoValue] = useState("");
  const [completionInFlight, setCompletionInFlight] = useState(false);

  const { task, file } = taskCompletionRecord;

  const taskIsCompleted = isSome(taskCompletionRecord.completionDate);
  const needsUpload = isSome(task.fileInputLabel) && !isSome(fileToUpload);
  const needsToEnterText =
    isSome(task.textInputLabel) && requiredInfoValue.trim() === "";

  const approvalElement = taskIsCompleted ? (
    <div>
      <Icon icon={IconNames.TICK} intent={Intent.SUCCESS} />
      {`Completed ${moment(taskCompletionRecord.completionDate!).format(
        UI_DATE_FORMAT_WITHOUT_TIME
      )}`}
    </div>
  ) : completionInFlight ? (
    <Spinner size={Spinner.SIZE_SMALL} />
  ) : (
    <Button
      onClick={handleApproval}
      disabled={needsUpload || needsToEnterText}
      className="ob-task-acknowledge-button"
    >
      Complete
    </Button>
  );

  const fileElement = isSome(task.fileInputLabel) ? (
    isSome(file) ? (
      <p>
        <DefaultLink href={`${location.origin}/${file.url}`}>
          <Icon icon={IconNames.PAPERCLIP} style={{ marginBottom: "3px" }} />
          &nbsp;
          {file.filename}
        </DefaultLink>
      </p>
    ) : (
      <FormGroup label={`Upload ${taskCompletionRecord.task.fileInputLabel}`}>
        <FileInput
          name={task.fileInputLabel}
          id={`${taskCompletionRecord.id}_${task.fileInputLabel}`}
          fileUploaded={(name, newFile) => setFileToUpload(newFile)}
          selectedFile={fileToUpload}
        />
      </FormGroup>
    )
  ) : (
    nothing
  );

  const responseTextElement = isSome(task.textInputLabel) ? (
    isSome(taskCompletionRecord.responseText) ? (
      `${task.textInputLabel}: ${taskCompletionRecord.responseText}`
    ) : (
      <FormGroup label={task.textInputLabel}>
        <InputGroup
          value={requiredInfoValue}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setRequiredInfoValue(e.target.value)
          }
        />
      </FormGroup>
    )
  ) : (
    nothing
  );

  const taskContent = (
    <div>
      {MarkdownRenderer(task.instructions ?? "")}
      {fileElement}
      {responseTextElement}
      {approvalElement}
    </div>
  );

  return <div>{taskContent}</div>;

  function handleApproval() {
    const requiredInfo = requiredInfoValue.trim();

    completeEmployeeOnboardingTask({
      variables: {
        taskCompletionRecordId: taskCompletionRecord.id,
        file: fileToUpload,
        fileTitle: fileToUpload?.name,
        requiredInfo: requiredInfo === "" ? null : requiredInfo,
      },
    }).catch(LogError);
    setCompletionInFlight(true);
  }
};

gql`
  mutation completeOwnRoleTask(
    $file: Upload
    $fileTitle: String
    $taskCompletionRecordId: String!
    $requiredInfo: String
  ) {
    completeRoleTaskByEmployee(
      file: $file
      fileTitle: $fileTitle
      taskCompletionRecordId: $taskCompletionRecordId
      requiredInfo: $requiredInfo
    ) {
      id
    }
  }
`;
