import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import React from "react";
import styled from "styled-components";

import { BASE_PALETTE } from "../../../../alpaca/base/colors";
import {
  severityLevelForNumber,
  textForSeverityNumber,
  VulnSeverity,
} from "../utils";

interface IProps {
  severity: Maybe<number>;
  excludeNumericSeverity?: Maybe<boolean>;
}

export const SeverityPill: React.FC<IProps> = ({
  severity,
  excludeNumericSeverity,
}) => {
  if (!isSome(severity)) {
    return <div />;
  }
  const severityLevel = severityLevelForNumber(severity);
  return (
    <Container level={severityLevel}>
      <Text align={excludeNumericSeverity ? "center" : "left"}>
        {textForSeverityNumber(severity)}
      </Text>
      {excludeNumericSeverity ? null : <NumText>{severity.toFixed(1)}</NumText>}
    </Container>
  );
};

const Container = styled.div<{ level: VulnSeverity }>`
  color: white;
  font-weight: 600;
  font-size: 12px;
  display: flex;
  padding: 0px;
  width: 100px;
  border-radius: 4px;
  & > div {
    padding: 8px;
  }
  background: ${({ level }) => {
    switch (level) {
      case VulnSeverity.LOW:
        return `${BASE_PALETTE.MANGO};`;
      case VulnSeverity.MEDIUM:
        return `${BASE_PALETTE.CITRUS};`;
      case VulnSeverity.HIGH:
      case VulnSeverity.CRITICAL:
        return `${BASE_PALETTE.TOMATO};`;
      default:
        return "white;";
    }
  }};
`;

const NumText = styled.div`
  border-left: 1px solid white;
  width: 36px;
  box-sizing: border-box;
  text-align: center;
  flex-shrink: 0;
`;

const Text = styled.div<{ align?: Maybe<string> }>`
  text-align: ${({ align }) => align ?? "left"};
  flex-grow: 1;
`;
