import React, { useMemo, useState } from "react";

import { Caption, Link } from "../common/components";

interface IProps {
  machines: string[];
}

const NUM_MACHINES_TO_DISPLAY_AT_FIRST = 10;
export const ExpandableMachineList: React.FC<IProps> = ({ machines }) => {
  const [expanded, setExpanded] = useState(false);
  const machineSlice = useMemo(
    () =>
      expanded ? machines : machines.slice(0, NUM_MACHINES_TO_DISPLAY_AT_FIRST),
    [expanded]
  );
  const machinesNotShowing = useMemo(
    () => machines.length - machineSlice.length,
    [machineSlice]
  );

  return (
    <div>
      {machineSlice.map((m, i) => (
        <Caption noWrap key={`${m}_${i}`}>
          {m}
        </Caption>
      ))}
      {!expanded && machinesNotShowing > 0 ? (
        <Link
          onClick={() => {
            setExpanded(true);
          }}
        >
          and {machinesNotShowing} more...
        </Link>
      ) : null}
    </div>
  );
};
