import { Spinner } from "@blueprintjs/core";
import type { Service } from "common/base/types/helpers";
import { serviceToDisplayName } from "common/base/types/helpers";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import moment from "moment";
import React, { useContext, useState } from "react";
import styled from "styled-components";

import { GRID_SPACING } from "../../../../alpaca/base/grid";
import {
  BodyText,
  H2,
  Menu,
  MenuButton,
  MenuItem,
} from "../../../../alpaca/components";
import type { GetDataForUserDetailHeadingQuery } from "../../../../gen/components";
import { useGetDataForUserDetailHeadingQuery } from "../../../../gen/components";
import { MaybeProfileImage } from "../../../helpers/MaybeProfileImage";
import { EditUserGroupDialog } from "../edit-user-group-dialog";
import { EditUserNameDialog } from "../edit-user-name-dialog";
import { GroupsContext } from "../groups-context";
import { NotHumanDialog } from "../not-human-dialog";
import { SetStartDateDialog } from "../set-start-date-dialog";
import { DATE_FORMAT_FULL_MONTH } from "../shared/common";
import { InfoIcon } from "../shared/info-icon";
import { canEditGroup, canMarkNotHuman } from "../shared/menu";
import { groupForUser } from "../utils";
import NON_HUMAN_ICON from "url:../../../../static/images/icons/non-human-user.png";

export type DetailHeadingUser = NonNullable<
  GetDataForUserDetailHeadingQuery["user"]
>;

interface IProps {
  userId: string;
  editable?: Maybe<boolean>;
}

export const UserDetailHeading: React.FC<IProps> = ({ userId, editable }) => {
  const { groups } = useContext(GroupsContext);

  const [editNameDialogOpen, setEditNameDialogOpen] = useState(false);
  const [editGroupDialogOpen, setEditGroupDialogOpen] = useState(false);
  const [startDateDialogOpen, setStartDateDialogOpen] = useState(false);
  const [notHumanDialogOpen, setNotHumanDialogOpen] = useState(false);

  const { loading, data } = useGetDataForUserDetailHeadingQuery({
    variables: { userId },
  });
  if (loading || !data || !data.user) {
    return <Spinner size={Spinner.SIZE_LARGE} />;
  }

  const user = data.user;
  const group = groupForUser(user, "No group");
  const jobTitle = user.hrUser?.jobTitle;
  const hasHrStartDate = isSome(user.hrUser?.startDate);

  const headingDetails = getHeadingDetailsElement();

  const menuActions = getMenuActions();
  const maybeMenuButton =
    !Boolean(user.isNotHuman) && menuActions.length > 0 ? (
      <MenuButton menu={<Menu>{menuActions}</Menu>} />
    ) : undefined;

  const editDialogs = [
    <EditUserNameDialog
      key="name-dialog"
      isOpen={editNameDialogOpen}
      onClose={() => setEditNameDialogOpen(false)}
      user={user}
    />,
    <EditUserGroupDialog
      key="group-dialog"
      isOpen={editGroupDialogOpen}
      onClose={() => setEditGroupDialogOpen(false)}
      user={user}
    />,
    <SetStartDateDialog
      key="start-date-dialog"
      isOpen={startDateDialogOpen}
      onClose={() => setStartDateDialogOpen(false)}
      user={user}
    />,
    <NotHumanDialog
      key="not-human-dialog"
      isOpen={notHumanDialogOpen}
      onClose={() => setNotHumanDialogOpen(false)}
      user={user}
    />,
  ];

  return (
    <>
      <StyledHeaderContainerDiv>
        {!Boolean(user.isNotHuman) ? (
          <MaybeProfileImage
            circle
            url={user.imageUrl}
            dimension={styles.PROFILE_IMAGE_DIMENSION}
          />
        ) : (
          <img
            src={NON_HUMAN_ICON}
            style={{
              height: styles.PROFILE_IMAGE_DIMENSION,
              width: styles.PROFILE_IMAGE_DIMENSION,
              borderRadius: styles.PROFILE_IMAGE_DIMENSION / 2,
            }}
          />
        )}
        <div style={{ display: "flex", alignItems: "flex-end" }}>
          <H2
            style={{
              marginBottom: "0px",
              marginLeft: `${2 * GRID_SPACING}px`,
              marginRight: "4px",
            }}
            lineHeight={"34px"}
          >
            {user.displayName}
          </H2>
          {user.isFromScan ? (
            <div style={{ marginBottom: "5px" }}>
              <InfoIcon
                tooltipContent={
                  "Name and profile picture are from your identity provider"
                }
              />
            </div>
          ) : null}
        </div>
      </StyledHeaderContainerDiv>
      <StyledBodyContainerDiv>
        {headingDetails}
        {maybeMenuButton}
      </StyledBodyContainerDiv>
      {editDialogs}
    </>
  );

  function getHeadingDetailsElement() {
    const isNotHuman = Boolean(user.isNotHuman);
    const emailRow = (
      <StyledRowDiv key="email_row">
        <StyledBodyText>{user.email}</StyledBodyText>
        {user.isFromScan ? (
          <InfoIcon tooltipContent={"Email from your identity provider"} />
        ) : null}
      </StyledRowDiv>
    );

    if (isNotHuman) {
      return <div style={{ marginLeft: "60px" }}>{emailRow}</div>;
    }

    const startDateAsMoment = hasHrStartDate
      ? moment(user.startDate).utc()
      : moment(user.startDate);

    const formattedStartDate = startDateAsMoment.format(DATE_FORMAT_FULL_MONTH);
    const datesAndMaybeJobTitle = isSome(jobTitle)
      ? `${jobTitle} joined ${formattedStartDate}`
      : `Joined ${formattedStartDate}`;
    return (
      <div>
        <StyledRowDiv key="date_row">
          <StyledBodyText>{datesAndMaybeJobTitle}</StyledBodyText>
          <InfoIcon
            tooltipContent={
              hasHrStartDate
                ? `Start date is from ${serviceToDisplayName(
                    user.hrUser?.service as Service
                  )}`
                : isSome(user.idp)
                ? `Start date is from ${serviceToDisplayName(user.idp.service)}`
                : "Start date is from Vanta"
            }
          />
        </StyledRowDiv>
        {emailRow}
        <StyledBodyText>
          {group === "No group" ? group : `Group: ${group}`}
        </StyledBodyText>
      </div>
    );
  }

  function getMenuActions() {
    const actions: JSX.Element[] = [];
    if (editable && !user.isFromScan) {
      actions.push(
        <MenuItem
          key="name-action"
          text={"Edit name"}
          onClick={() => setEditNameDialogOpen(true)}
        />
      );
    }
    if (editable && !hasHrStartDate) {
      actions.push(
        <MenuItem
          key="start-date-action"
          text={"Edit start date"}
          onClick={() => setStartDateDialogOpen(true)}
        />
      );
    }
    if (canEditGroup(user, groups)) {
      actions.push(
        <MenuItem
          key="group-action"
          text={"Edit group"}
          onClick={() => setEditGroupDialogOpen(true)}
        />
      );
    }
    if (canMarkNotHuman(user)) {
      actions.push(
        <MenuItem
          key="not-a-person-action"
          text={"Not a person"}
          onClick={() => setNotHumanDialogOpen(true)}
        />
      );
    }
    return actions;
  }
};

const styles = {
  PROFILE_IMAGE_DIMENSION: 46,
  HEADING_MARGIN_TOP: 4 * GRID_SPACING,
  HEADING_MARGIN_BOTTOM: 2 * GRID_SPACING,
  BODY_MARGIN_TOP: GRID_SPACING,
  BODY_MARGIN_LEFT: 3,
  ROW_MARGIN_BOTTOM: 2,
  MENU_BUTTON_WIDTH: 6 * GRID_SPACING,
  MENU_BUTTON_HEIGHT: 36,
  MENU_BUTTON_BORDER_RADIUS: 4,
  MENU_ICON_DIMENSION: 3 * GRID_SPACING,
};

const StyledHeaderContainerDiv = styled.div`
  display: flex;
  align-items: center;
`;

const StyledBodyContainerDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: top;
  margin-top: ${styles.BODY_MARGIN_TOP}px;
  margin-left: ${styles.BODY_MARGIN_LEFT}px;
`;

const StyledRowDiv = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${styles.ROW_MARGIN_BOTTOM}px;
`;

const StyledBodyText = styled(BodyText)`
  margin-bottom: 0px;
  margin-right: 4px;
`;

gql`
  query getDataForUserDetailHeading($userId: ID!) {
    user(id: $userId) {
      id
      displayName
      email
      familyName
      givenName
      hrUser {
        jobTitle
        service
        startDate
      }
      imageUrl
      idp {
        service
      }
      isActive
      isContractor
      isFromScan
      isNotHuman
      permissionLevel
      startDate
      role {
        id
        name
      }
    }
  }
`;
