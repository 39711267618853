import { ReportStandard } from "../base/types/gen";
import type { IGapData } from "./complianceTypes";

export const GENERIC_REPORT_DATA = {
  ABOUT: {
    id: "about",
    name: "About Vanta",
    text: `[Vanta](https://vanta.com) provides a set of security and compliance tools that scan, verify, and secure a company’s IT systems and processes. Our cloud-based technology identifies security flaws and privacy gaps in a company’s security posture, providing a comprehensive view across cloud infrastructure, endpoints, corporate procedures, enterprise risk, and employee accounts.\n\nVanta is based in San Francisco, California.`,
  },

  APPENDICES: {
    definitions: {
      id: "appendix-a",
      name: "Appendix A: Definitions",
      text: `**Bug bounty program**: A crowdsourcing initiative that rewards individuals for discovering and reporting software bugs, especially those that could cause security vulnerabilities or breaches.\n\n**DDoS**: Distributed denial of service. A DDoS attack is attack in which multiple compromised computer systems flood a target—such as a server, website, or other network resource—with messages or requests to cause a denial of service for users of the targeted resource.\n\n**Multifactor authentication (MFA)**: A security system that requires multiple methods of authentication using different types of credentials to verify users’ identities before they can access a service.\n\n**Penetration test**: The practice of testing a computer system, network, or web application to find vulnerabilities that an attacker might exploit. \n\n**Principle of least privilege**: The principle of giving a user or account only the privileges that are required to perform a job or necessary function.\n\n**Protected data**: Data that is protected from public view or use; includes personally identifiable information, sensitive data, HIPAA data, or financial data.\n\n**Sensitive data**: Any information a reasonable person considers private or would choose not to share with the public.\n\n**SSH**: Secure shell. A cryptographic network protocol for operating network services securely over an unsecured network.\n\n**SSL**: Secure sockets layer. The standard security technology for establishing an encrypted link between a web server and a browser.`,
    },
    history: {
      id: "appendix-b",
      name: "Appendix B: Document history",
      text: "Vanta continuously monitors the company's security and IT infrastructure to ensure the company complies with industry-standard security standards. Vanta tests the company’s security posture continuously, and this report is automatically updated to reflect the latest findings.",
    },
  },
} as const;

type IReportText = typeof GENERIC_REPORT_DATA & { SUMMARY: IGapData };

export const CONTINUOUS_INDICATION = `*This document is updated continuously. As %COMPANY improves its security posture, those efforts will be instantly visible.*`;

const VANTA_REPORT_DATA = {
  ...GENERIC_REPORT_DATA,
  SUMMARY: {
    id: "summary",
    name: "Summary",
    text: `
Vanta tested %COMPANY's security and IT infrastructure to ensure the company has a strong security posture, as defined by industry-standard security standards.

In this report, Vanta:

- Tests a complete set of security and infrastructure controls that may appear in an audit
- Identifies gaps and vulnerabilities in infrastructure and processes

### Intended use
This Vanta Report can be used by:

- %COMPANY to identify issues critical for remediation
- %COMPANY’s customers to understand the company's security posture

### Vanta Report approach: continuous monitoring
Vanta continuously monitors the company’s policies, procedures, and IT infrastructure to ensure the company adheres to industry-standard security, privacy, confidentiality, and availability standards.

To do this, Vanta connects directly to the company’s infrastructure accounts, version control tools, task trackers, endpoints, hosts, HR tools, and internal policies. Vanta then continuously monitors these resources to determine if %COMPANY meets the SOC 2 standard.

In compiling this report, Vanta took into account %COMPANY’s unique requirements and technical environment, including business model, products and services, and interactions with customer data.
`,
  },
};

const SOC2_REPORT_DATA = {
  ...GENERIC_REPORT_DATA,
  SUMMARY: {
    id: "summary",
    name: "Introduction",
    text: `
The American Institute of Certified Public Accountants (AICPA) defined the SOC (System and Organization Controls) reporting framework to help businesses manage risks. Their SOC 2 standard defines criteria for managing customer data based on five trust service principles: security, availability, processing integrity, confidentiality, and privacy.

Vanta performed a gap analysis of %COMPANY’s security and IT infrastructure in preparation for a SOC 2 audit. Vanta’s SOC 2 analysis identified gaps in %COMPANY’s infrastructure and provided steps to correct them.

In this report, Vanta:

- Tests a complete set of security and infrastructure controls that may appear in a SOC 2 audit
- Identifies gaps and vulnerabilities in infrastructure and processes

### Intended use
This gap assessment can be used by:

- %COMPANY to identify issues critical for remediation
- %COMPANY’s customers to understand the company's progress toward SOC 2 compliance

### Continuous gap assessment approach: continuous monitoring

Vanta continuously monitors the company’s policies, procedures, and IT infrastructure to ensure the company adheres to AICPA’s Trust Service Principles of security, availability, and confidentiality.

To do this, Vanta connects directly to the company’s infrastructure accounts, version control tools, task trackers, endpoints, hosts, HR tools, and internal policies. Vanta then continuously monitors these resources to determine if %COMPANY meets the SOC 2 standard.

In compiling this gap assessment, Vanta took into account %COMPANY’s unique requirements and technical environment, including business model, products and services, and interactions with customer data.
`,
  },
};

const PCI_REPORT_DATA = {
  ...GENERIC_REPORT_DATA,
  SUMMARY: {
    id: "summary",
    name: "Summary",
    text: `This gap analysis maps %COMPANY's compliance controls to the PCI-DSS Security Standard. This gap analysis can:\n- provide an illustrative set of controls appropriate for a PCI-DSS audit\n- identify control gaps\n- give advice on ways to satisfy the unimplemented controls.\n\nThe table to the right presents a summary of the gap analysis; the implemented controls are shown as such, while the unimplemented controls are broken down by their severity level, which can be used to prioritize fixes.`,
  },
};

const HIPAA_REPORT_DATA = {
  ...GENERIC_REPORT_DATA,
  SUMMARY: {
    id: "summary",
    name: "Summary",
    text: ``,
  },
};

const ISO27001_REPORT_DATA = {
  ...GENERIC_REPORT_DATA,
  SUMMARY: {
    id: "summary",
    name: "Summary",
    text: ``,
  },
};

const GDPR_REPORT_DATA = {
  ...GENERIC_REPORT_DATA,
  SUMMARY: {
    id: "summary",
    name: "Summary",
    text: ``,
  },
};

export const REPORT_TEXTS: {
  [k in ReportStandard]: IReportText;
} = {
  [ReportStandard.gdpr]: GDPR_REPORT_DATA,
  [ReportStandard.hipaa]: HIPAA_REPORT_DATA,
  [ReportStandard.iso27001]: ISO27001_REPORT_DATA,
  [ReportStandard.pciSaqA]: PCI_REPORT_DATA,
  [ReportStandard.pciSaqAEP]: PCI_REPORT_DATA,
  [ReportStandard.pciSaqDMerchant]: PCI_REPORT_DATA,
  [ReportStandard.pciSaqDSP]: PCI_REPORT_DATA,
  [ReportStandard.soc2]: SOC2_REPORT_DATA,
  [ReportStandard.vanta]: VANTA_REPORT_DATA,
};
