import type { Tooltip2Props } from "@blueprintjs/popover2";
import type { Maybe } from "common/base/types/maybe";
import React from "react";

import { BASE_PALETTE } from "../../../../alpaca/base/colors";
import { Icon, IconNames, Tooltip } from "../../../../alpaca/components";

interface IProps {
  tooltipContent: string | JSX.Element;
  tooltipProps?: Maybe<{
    hoverCloseDelay?: Maybe<number>;
    interactionKind?: Maybe<Tooltip2Props["interactionKind"]>;
  }>;
}

export const InfoIcon: React.FC<IProps> = ({
  tooltipContent,
  tooltipProps,
}) => (
  <Tooltip
    content={tooltipContent}
    hoverCloseDelay={tooltipProps?.hoverCloseDelay ?? 0}
    interactionKind={tooltipProps?.interactionKind ?? "hover-target"}
    placement="bottom-end"
  >
    <Icon icon={IconNames.INFO} color={BASE_PALETTE.FOG} />
  </Tooltip>
);
