import type { Service } from "common/base/types/helpers";
import { serviceToDisplayName } from "common/base/types/helpers";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import { keyBy } from "lodash";
import React, { useMemo, useState } from "react";
import styled from "styled-components";

import { GRID_SPACING } from "../../../../alpaca/base/grid";
import { AllAccessInfoDocument } from "../../../../gen/components";
import { Ellipsify } from "../../../../helpers/ellipsify";
import { lastNameSort } from "../../../../helpers/user-sort-functions";
import { DataTable } from "../../components/data-table";
import { SmallCaption } from "../../inventory-list/inventory-card";
import type { VantaUser } from "../access-page";
import { getAWSAccountIdFromAwsAccountARN } from "../accounts-access-container";
import { CreateUserDialog } from "./../../people/create-user-dialog";
import { AccountActionsDropdown } from "./account-actions-dropdown";
import type {
  AccountActionsMap,
  LinkableAccount,
} from "./bulk-link-account-dialog";

interface IProps {
  accounts: LinkableAccount[];
  linkableUsers: VantaUser[];
  accountIdsMap: AccountActionsMap;
  setMapping: (accountId: string, mapping: Maybe<string>) => void;
}

const COLUMN_SORT_FUNCTIONS: {
  [k: string]: (a1: LinkableAccount, a2: LinkableAccount) => number;
} = {
  name: (a1, a2) => a1.accountName.localeCompare(a2.accountName),
  service: (a1, a2) => a1.service.localeCompare(a2.service),
};

const getServiceDisplay = (account: LinkableAccount) => {
  if (account.service === "aws") {
    return `${serviceToDisplayName(
      account.service
    )} (${getAWSAccountIdFromAwsAccountARN(account.uniqueId!)})`;
  } else {
    return serviceToDisplayName(account.service as Service);
  }
};

export const BulkLinkAccountsTable: React.FC<IProps> = ({
  accounts,
  linkableUsers,
  accountIdsMap,
  setMapping,
}) => {
  const [addingUserForAccount, setAddingUserForAccount] =
    useState<Maybe<string>>(null);
  const linkableUsersById = useMemo(
    () => keyBy(linkableUsers, "id"),
    [linkableUsers]
  );
  const sortedLinkableUsers = useMemo(
    () => linkableUsers.sort(lastNameSort),
    [linkableUsers]
  );
  return (
    <>
      <StyledTableContainerDiv>
        <DataTable
          useDefaultStyling
          stickyHeaders="top"
          columnOrder={["name", "service", "actions"]}
          columnWidths={["350px", "200px", "560px"]}
          header={{
            name: "Account name",
            service: "Service",
            actions: "Link to person",
          }}
          data={accounts}
          createRow={account => {
            return {
              name: (
                <div>
                  <Ellipsify text={account.accountName!} />
                  <SmallCaption>
                    {account.accountId !== account.accountName
                      ? account.accountId
                      : null}
                  </SmallCaption>
                </div>
              ),
              service: getServiceDisplay(account),
              actions: (
                <AccountActionsDropdown
                  account={account}
                  linkableUsersById={linkableUsersById}
                  sortedLinkableUsers={sortedLinkableUsers}
                  accountIdsMap={accountIdsMap}
                  setAddingUserForAccount={setAddingUserForAccount}
                  setMapping={setMapping}
                />
              ),
            };
          }}
          columnSortFunctions={COLUMN_SORT_FUNCTIONS}
          defaultSortColumn="service"
        />
      </StyledTableContainerDiv>
      {isSome(addingUserForAccount) ? (
        <CreateUserDialog
          isOpen={true}
          onClose={user => {
            if (isSome(addingUserForAccount)) {
              setMapping(addingUserForAccount, user?.id);
              setAddingUserForAccount(null);
            }
          }}
          refetchQueries={[{ query: AllAccessInfoDocument }]}
        />
      ) : null}
    </>
  );
};

const StyledTableContainerDiv = styled.div`
  max-height: ${55 * GRID_SPACING}px;
  overflow-x: hidden;
  overflow-y: auto;
  width: 1296px;

  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  border-color: #e5e6e8;
`;
