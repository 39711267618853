import { Icon } from "@blueprintjs/core";
import React from "react";

import { Container, DropdownMenu, SimpleMenuList } from "./menu";

const HELP_MENU_OPTIONS = [
  {
    text: "Help Center",
    path: "https://vanta.zendesk.com/hc/en-us",
    isExternal: true,
  },
  {
    text: "Product updates", // this has no path, as trychameleon.com handles this action on click to show a product updates modal. test this via app.staging.vanta.com
  },
];

export const HelpMenu: React.FC = () => (
  <DropdownMenu
    icon={<Icon icon={helpIcon} iconSize={36} />}
    content={
      <Container>
        <SimpleMenuList menuOptions={HELP_MENU_OPTIONS} />
      </Container>
    }
    tooltipText={"Help & Updates"}
  />
);

const helpIcon = (
  <svg data-icon="help" width="24" height="24" viewBox="0 0 24 24">
    help
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.8062 15.1982H11.1937C11.1937 14.4726 11.3845 13.7684 11.7419 13.1449C12.0886 12.5402 12.5885 12.0349 13.1852 11.6721C13.2282 11.6479 13.2712 11.6237 13.3115 11.5995C14.064 11.1373 14.5128 10.3364 14.5128 9.45486C14.5128 8.06807 13.384 6.93661 11.9972 6.93661C10.6105 6.93661 9.48437 8.07614 9.48437 9.4468H7.87183C7.87183 7.18924 9.72356 5.32407 11.9999 5.32407C14.2763 5.32407 16.128 7.17849 16.128 9.45486C16.128 10.1778 15.9372 10.89 15.5798 11.5135C15.2331 12.1182 14.7332 12.6289 14.1366 12.989L14.107 13.0051C14.0801 13.0213 14.0505 13.032 14.0237 13.0481C13.2604 13.5104 12.8062 14.3113 12.8062 15.1982Z"
      fill="#63676D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.8064 16.9451H11.1938V18.5576H12.8064V16.9451Z"
      fill="#63676D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 24C10.4412 24 8.92273 23.7044 7.48488 23.1212C5.99597 22.5165 4.66025 21.6296 3.51534 20.4847C2.37044 19.3398 1.48354 18.004 0.878835 16.5151C0.295633 15.0773 0 13.5588 0 12C0 10.4412 0.295633 8.92273 0.878835 7.48488C1.48354 5.99597 2.37044 4.66025 3.51534 3.51534C4.66025 2.37044 5.99597 1.48354 7.48488 0.878835C8.92273 0.295633 10.4412 0 12 0C13.5588 0 15.0773 0.295633 16.5151 0.878835C18.004 1.48354 19.3398 2.37044 20.4847 3.51534C21.6296 4.66025 22.5165 5.99597 23.1212 7.48488C23.7044 8.92273 24 10.4412 24 12C24 13.5588 23.7044 15.0773 23.1212 16.5151C22.5165 18.004 21.6296 19.3398 20.4847 20.4847C19.3398 21.6296 18.004 22.5165 16.5151 23.1212C15.0773 23.7044 13.5588 24 12 24ZM12 1.61254C9.22643 1.61254 6.6168 2.69295 4.65487 4.65487C2.69295 6.6168 1.61254 9.22643 1.61254 12C1.61254 14.7736 2.69295 17.3832 4.65487 19.3451C8.70504 23.3953 15.295 23.3953 19.3451 19.3451C23.3953 15.295 23.3953 8.70504 19.3451 4.65487C17.3832 2.69295 14.7736 1.61254 12 1.61254Z"
      fill="#63676D"
    />
  </svg>
);
