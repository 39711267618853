import { AnchorButton, Classes } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React from "react";

import { DefaultLink } from "../../../../helpers/links";
import { IdentityProviderDisclaimer } from "../identity-provider-disclaimer";

export const OfficeLinkingInstructions: React.FC = () => {
  const url = `/auth/login/office?continue=/credentials`;
  return (
    <div>
      <div className={Classes.DIALOG_HEADER}>Link Office 365</div>
      <div className={Classes.DIALOG_BODY}>
        <IdentityProviderDisclaimer newProvider="office" />
        <p>Let's get started linking your Office 365 account.</p>
        <p>First, verify that you are an admin of the Office organization.</p>
        <p>
          An easy way to check if your account is an admin is to visit the
          <DefaultLink href="https://admin.microsoft.com/">
            {" "}
            Microsoft 365 admin center{" "}
          </DefaultLink>
          and make sure that you can log in.
        </p>
        <p>
          This connection will take you through the Microsoft authorization
          flow.
        </p>
      </div>

      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <AnchorButton href={url} icon={IconNames.LOG_IN}>
            Connect Office 365
          </AnchorButton>
        </div>
      </div>
    </div>
  );
};
