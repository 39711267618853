import React from "react";

import type { IPolicyWizardStep } from "../beta-policies-wizard-page";

// @see https://app.shortcut.com/vanta/story/12332/implement-styles-and-remove-inline-styling-for-the-policy-upload-flow
// To implement styles and remove inline styles
export const PoliciesWizardStepCount: React.FC<{
  currStepIdx: number;
  allSteps: IPolicyWizardStep[];
}> = ({ currStepIdx, allSteps }) => (
  <div>
    {currStepIdx + 1} of {allSteps.length}
  </div>
);
