import type { TabsProps } from "@blueprintjs/core";
import { Tabs as BPTabs } from "@blueprintjs/core";
import styled from "styled-components";

import { BASE_PALETTE } from "../../base/colors";
import { GRID_SPACING } from "../../base/grid";
import { BASE_TYPOGRAPHY } from "../../base/typography";

export const Tabs = styled<React.ComponentType<TabsProps>>(BPTabs)`
  & > .bp3-tab-list {
    border-bottom: 1px solid ${BASE_PALETTE.FOG};
    height: ${3 * GRID_SPACING}px;
    /* margin-bottom: -1px; */
    .bp3-tab-indicator-wrapper {
      height: ${3 * GRID_SPACING}px !important;
    }
    .bp3-tab-indicator {
      height: 2px;
      bottom: 0px;
    }
    .bp3-tab {
      top: 1px;
      font-size: 14px;
      line-height: 16px;
      height: ${3 * GRID_SPACING}px;
      &,
      &:not([aria-disabled="true"]):hover {
        color: ${BASE_PALETTE.CHARCOAL};
      }
      &:not([aria-selected="true"]):not([aria-disabled="true"]):hover {
        border-bottom: 2px solid ${BASE_PALETTE.SMOKE}88;
      }
      font-weight: ${BASE_TYPOGRAPHY.FONT_WEIGHTS.SLIGHTLY_BOLD};
      margin-right: ${3 * GRID_SPACING}px;
      &[aria-selected="true"] {
        color: ${BASE_PALETTE.INK};
        &:hover {
          color: ${BASE_PALETTE.INK};
          cursor: default;
        }
      }
    }
  }
`;
