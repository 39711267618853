import { parse } from "json2csv";
import type { Maybe } from "../base/types/maybe";

// rawData is a stringified JSON representation of a test run's raw data (this is stored zipped up in S3)
export function rawDataToCsvString(rawData: string) {
  const csvJson = JSON.parse(rawData);
  if (Array.isArray(csvJson) && csvJson.length > 0) {
    return parse(csvJson);
  } else {
    return "No relevant resources";
  }
}

export function linksToCsvString(
  links: Array<{ title: string; url: string; description?: Maybe<string> }>
) {
  if (links.length === 0) {
    return "No links provided";
  } else {
    const csvContent = links.map(link => {
      return {
        title: link.title,
        url: link.url,
        description: link.description ?? null,
      };
    });
    return parse(csvContent);
  }
}

export function evidenceTitleToFilePart(title: string) {
  return title.toLocaleLowerCase().replace(/ /g, "_");
}
