import "./dropdown-button.scss";

import { Menu, MenuItem } from "@blueprintjs/core";
import { IconNames as BPIconNames } from "@blueprintjs/icons";
import type { ItemListRenderer } from "@blueprintjs/select";
import { Select } from "@blueprintjs/select";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import React from "react";
import styled, { css } from "styled-components";

import { BASE_PALETTE } from "../../../alpaca/base/colors";
import { GRID_SPACING } from "../../../alpaca/base/grid";
import { BASE_TYPOGRAPHY } from "../../../alpaca/base/typography";
import type { IconName } from "../../../alpaca/components";
import { Button, Icon } from "../../../alpaca/components";

const DROPDOWN_MENU_POPOVER_CLASSNAME = "dropdown-menu-popover";

interface IProps<T> {
  options: T[];
  selectedOption: Maybe<T>;
  onOptionSelect: (option: Maybe<T>) => void;
  optionRenderer: (option: T) => Maybe<string>;
  isFilter?: Maybe<boolean>;
  defaultText?: Maybe<string>;
  nullIcon?: Maybe<IconName>;
  nullOption?: Maybe<string>;
  nullOptionLast?: Maybe<boolean>;
  additionalMenuItems?: Maybe<JSX.Element[]>;
  menuContentRenderer?: Maybe<ItemListRenderer<Maybe<T>>>;
  getIconForOption?: Maybe<(option: T) => Maybe<IconName>>;
  // Defaults to false
  filterable?: Maybe<boolean>;
  searchPlaceholderText?: Maybe<string>;
  onOpening?(): void;

  buttonWidth?: Maybe<number>;
  menuWidth?: Maybe<number>;
  // Whether to style the button differently when an option is selected
  styleOnSelect?: Maybe<boolean>;
}

// Include the trailing comma in the generics to avoid being interpreted as JSX
//
// Unfortunately this component can't be typed generically with
// React.FC<IProps<T>> since the type T is declared in another scope.
export const DropdownButton = <T,>({
  options,
  selectedOption,
  onOptionSelect,
  optionRenderer,
  isFilter,
  defaultText,
  nullIcon,
  nullOption,
  additionalMenuItems,
  menuContentRenderer,
  getIconForOption,
  filterable,
  searchPlaceholderText,
  onOpening,
  buttonWidth,
  menuWidth,
  styleOnSelect,
  nullOptionLast,
}: IProps<T>): JSX.Element => {
  const OptionSelect = Select.ofType<Maybe<T>>();
  const nullDefaultText = defaultText ?? nullOption;
  const items = isSome(nullOption)
    ? nullOptionLast
      ? [...options, null]
      : [null, ...options]
    : options;

  const isFilterDropdown = isFilter ?? false;
  const optionRendererForSelect = isFilterDropdown
    ? (option: T) => `Filter: ${optionRenderer(option)}`
    : optionRenderer;

  const renderMenu: ItemListRenderer<Maybe<T>> = args => {
    let menuContent: Maybe<JSX.Element>;
    const { filteredItems, itemsParentRef, renderItem } = args;
    if (isSome(menuContentRenderer)) {
      menuContent = menuContentRenderer(args);
    } else {
      const renderedItems = filteredItems.map(renderItem);
      menuContent = (
        <>
          {renderedItems}
          {isSome(additionalMenuItems) && additionalMenuItems.length > 0
            ? additionalMenuItems
            : null}
          {isFilterDropdown && isSome(selectedOption) ? (
            <StyledMenuLink onClick={() => onOptionSelect(null)} text="Clear" />
          ) : null}
        </>
      );
    }
    return (
      <StyledMenu width={menuWidth} ulRef={itemsParentRef}>
        {menuContent}
      </StyledMenu>
    );
  };
  return (
    <>
      <StyledContainerDiv
        active={Boolean(styleOnSelect) && isSome(selectedOption)}
      >
        <OptionSelect
          items={items}
          itemRenderer={(item, { handleClick, index }) => {
            const maybeIconName = !isSome(item)
              ? nullIcon
              : getIconForOption?.(item);
            return (
              <MenuItem
                key={
                  !isSome(item)
                    ? nullOption!
                    : `${optionRenderer(item)}-${index}`
                }
                text={!isSome(item) ? nullOption! : optionRenderer(item)}
                icon={
                  isSome(maybeIconName) ? (
                    <Icon icon={maybeIconName} color={BASE_PALETTE.SMOKE} />
                  ) : null
                }
                onClick={handleClick}
              />
            );
          }}
          itemListRenderer={renderMenu}
          onItemSelect={onOptionSelect}
          filterable={filterable ?? false}
          itemPredicate={
            Boolean(filterable)
              ? (query, option) => {
                  const lowercasedQuery = query.toLocaleLowerCase();
                  const renderedOption = isSome(option)
                    ? optionRenderer(option)
                    : nullOption;
                  return Boolean(
                    renderedOption
                      ?.toLocaleLowerCase()
                      .includes(lowercasedQuery)
                  );
                }
              : undefined
          }
          inputProps={{
            placeholder: searchPlaceholderText ?? "Search",
          }}
          popoverProps={{
            minimal: true,
            usePortal: true,
            placement: "bottom",
            onOpening,
            popoverClassName: DROPDOWN_MENU_POPOVER_CLASSNAME,
          }}
        >
          <StyledDropdownButton
            rightIcon={BPIconNames.CHEVRON_DOWN}
            width={buttonWidth}
          >
            {isSome(selectedOption)
              ? optionRendererForSelect(selectedOption)
              : nullDefaultText ?? "Select an option"}
          </StyledDropdownButton>
        </OptionSelect>
      </StyledContainerDiv>
    </>
  );
};

const SELECTED_BUTTON_STYLES = css`
  background-color: #f2f9fa;
  border: 1px solid #238f9d;
`;

const StyledContainerDiv = styled.div<{
  active?: Maybe<boolean>;
}>`
  &&& {
    && * .bp3-button {
      ${({ active }) => (Boolean(active) ? SELECTED_BUTTON_STYLES : "")}
    }

    && * .bp3-popover-open {
      * .bp3-button {
        background-color: ${BASE_PALETTE.SMOKE};
        & > .bp3-button-text {
          color: ${BASE_PALETTE.SNOW};
        }
        svg {
          fill: ${BASE_PALETTE.SNOW};
        }
        border: none;
      }
    }
  }
`;

const StyledMenuLink = styled(MenuItem)`
  font-size: 12px !important;
  text-decoration: underline;

  padding-bottom: 6px;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledMenu = styled(Menu)<{ width?: Maybe<number> }>`
  ${({ width }) => (isSome(width) ? `width: ${width}px;` : "")}

  & * .bp3-menu-item {
    font-size: 14px;
    line-height: 24px;
    align-items: center;
    padding-left: ${2 * GRID_SPACING}px;
    padding-right: ${2 * GRID_SPACING}px;

    .bp3-icon {
      margin-top: 0px;
    }
  }
`;

const StyledButton = styled(Button)`
  height: 36px;
  padding-left: ${2 * GRID_SPACING}px;
  padding-right: ${2 * GRID_SPACING}px;
  border-radius: 4px;

  & > .bp3-button-text {
    font-size: 12px;
    line-height: 12px;
    color: ${BASE_PALETTE.INK};
    font-weight: ${BASE_TYPOGRAPHY.FONT_WEIGHTS.SLIGHTLY_BOLD};

    * svg {
      fill: ${BASE_PALETTE.SMOKE};
    }
  }

  &&&.bp3-intent-primary {
    .bp3-button-text {
      color: ${BASE_PALETTE.SNOW};
    }

    &.bp3-disabled {
      .bp3-button-text {
        color: ${BASE_PALETTE.SNOW}66;
      }
    }
  }

  &&&.bp3-disabled {
    border: 1px solid ${BASE_PALETTE.FOG}B0;
    & > .bp3-button-text {
      color: ${BASE_PALETTE.INK}66;
    }
  }
`;

const StyledDropdownButton = styled(StyledButton)<{
  width?: Maybe<number>;
  applied?: Maybe<boolean>;
}>`
  width: ${({ width }) => (isSome(width) ? `${width}px` : "auto")};
  padding-right: 12px;
  display: flex;
  justify-content: space-between;

  & > .bp3-button-text {
    color: ${BASE_PALETTE.CHARCOAL};
    font-weight: 400;
    line-height: 16px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  && * svg {
    fill: ${BASE_PALETTE.CHARCOAL};
  }
`;
