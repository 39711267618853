import type { IInputGroupProps } from "@blueprintjs/core";
import { InputGroup } from "@blueprintjs/core";
import React from "react";

export class TextInput extends React.Component<IInputGroupProps> {
  public render() {
    return (
      <InputGroup
        dir="auto"
        type="text"
        {...this.props}
        value={this.props.value as string}
      />
    );
  }
}
