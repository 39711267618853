import { Intent } from "@blueprintjs/core";
import type { IconName, MaybeElement } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import React from "react";

import { Button } from "../index";
import { Dialog } from "./dialog";
import { DialogClasses } from "./styles";

export interface IActionDialogProps {
  cancelText?: Maybe<string>;
  /**
   * Default behavior is true
   */
  canClickOutsideToClose?: Maybe<boolean>;
  className?: Maybe<string>;
  confirmText: string;
  confirmIcon?: Maybe<IconName | MaybeElement>;
  confirmDisabled?: Maybe<boolean>;
  confirmDisabledMessage?: Maybe<string>;
  confirmIntent?: Maybe<Intent>;
  // Default behavior is true
  enforceFocus?: Maybe<boolean>;
  loading?: Maybe<boolean>;
  isOpen: boolean;
  title: string;
  /**
   * If provided, cancel button triggers this function instead of `onClose`.
   */
  onCancel?: Maybe<() => void>;
  onClose(): void;
  onConfirm: (() => void) | (() => Promise<void>);
  portalClassName?: Maybe<string>;
}

export const ActionDialog: React.FC<IActionDialogProps> = ({
  children,
  cancelText,
  canClickOutsideToClose,
  className,
  confirmText,
  confirmIcon,
  confirmDisabled,
  confirmDisabledMessage,
  confirmIntent,
  enforceFocus,
  loading,
  isOpen,
  title,
  onCancel,
  onClose,
  onConfirm,
  portalClassName,
}) => (
  <Dialog
    isOpen={isOpen}
    onClose={onClose}
    title={title}
    canOutsideClickClose={canClickOutsideToClose ?? undefined}
    enforceFocus={enforceFocus ?? undefined}
    className={className ?? undefined}
    portalClassName={portalClassName ?? undefined}
  >
    {children}
    <div className={DialogClasses.DIALOG_FOOTER_ACTIONS}>
      <Button onClick={onCancel ?? onClose} text={cancelText ?? "Cancel"} />
      <Button
        disabled={confirmDisabled ?? false}
        intent={confirmIntent ?? Intent.PRIMARY}
        icon={confirmIcon}
        loading={loading ?? undefined}
        onClick={async (e: React.SyntheticEvent<any>) => {
          await onConfirm();
        }}
        text={confirmText}
        tooltipContent={confirmDisabledMessage ?? undefined}
      />
    </div>
  </Dialog>
);
