import { Intent } from "@blueprintjs/core";
import { isSome } from "common/base/types/maybe";
import { toSingular } from "common/grammar/plurals";
import React, { useState } from "react";
import styled from "styled-components";

import { VANTA_COLORS } from "../../../alpaca/base/colors";
import { VantaButtonDeprecated } from "../../../alpaca/base/deprecated";
import { BASE_TYPOGRAPHY } from "../../../alpaca/base/typography";
import { useAvailableBetaFeatures } from "../../../helpers/feature-gating/feature-check";
import { ServiceBadge } from "../service-badge";
import { LinkServiceGroupDialog } from "./link-service-group-dialog";
import type { IServiceGroupProps } from "./service-group";

/**
 * InvitationToLinkServiceGroup renders a carousel inviting the user to link
 * any of the services belonging to serviceGroup.
 */
export const InvitationToLinkServiceGroup: React.FC<IServiceGroupProps> = ({
  serviceGroup,
}) => {
  const availableBetaFeatures = useAvailableBetaFeatures();
  const [linkingModalOpen, setLinkingModalOpen] = useState(false);
  const availableServiceGroupServices = serviceGroup.services.filter(
    service =>
      !isSome(service.feature) || availableBetaFeatures?.has(service.feature)
  );
  return (
    <ServiceGroupInvitationPseudocard>
      <ServiceBadgeArray>
        {availableServiceGroupServices.map(service => (
          <ServiceBadge
            key={service.id}
            service={service}
            large={true}
            clickToConnect={true}
          />
        ))}
      </ServiceBadgeArray>
      <InvitationToLinkText>
        Connect your {toSingular(serviceGroup.name.toLocaleLowerCase())}.
      </InvitationToLinkText>
      <InvitationToLinkButton
        intent={Intent.PRIMARY}
        onClick={() => setLinkingModalOpen(true)}
      >
        Connect
      </InvitationToLinkButton>
      <LinkServiceGroupDialog
        serviceGroup={serviceGroup}
        relevantCredentials={[]}
        isOpen={linkingModalOpen}
        onClose={() => setLinkingModalOpen(false)}
      />
    </ServiceGroupInvitationPseudocard>
  );
};

const ServiceGroupInvitationPseudocardStyles = {
  PADDING: 20,
  BORDER: `1px dashed ${VANTA_COLORS.BORDER_LIGHT}`,
  BORDER_RADIUS: 8,
};

const ServiceGroupInvitationPseudocard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  padding: ${ServiceGroupInvitationPseudocardStyles.PADDING}px;
  border: ${ServiceGroupInvitationPseudocardStyles.BORDER};
  box-sizing: border-box;
  border-radius: ${ServiceGroupInvitationPseudocardStyles.BORDER_RADIUS}px;
`;

const ServiceBadgeArray = styled.div`
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const InvitationToLinkTextStyles = { MARGIN_BOTTOM: 16 };

const InvitationToLinkText = styled.div`
  margin-bottom: ${InvitationToLinkTextStyles.MARGIN_BOTTOM}px;
`;

const InvitationToLinkButtonStyles = {
  PADDING_TOP_BOTTOM: 16,
  PADDING_RIGHT_LEFT: 32,
};

export const VantaButtonInter = styled(VantaButtonDeprecated)`
  font-family: ${BASE_TYPOGRAPHY.FONT_FAMILIES.INTER};
`;

const InvitationToLinkButton = styled(VantaButtonInter)`
  padding: ${InvitationToLinkButtonStyles.PADDING_TOP_BOTTOM}px
    ${InvitationToLinkButtonStyles.PADDING_RIGHT_LEFT}px;
  font-weight: ${BASE_TYPOGRAPHY.FONT_WEIGHTS.BOLD} !important;
`;
