import {
  Button,
  Classes,
  ControlGroup,
  Dialog,
  FormGroup,
  InputGroup,
  Intent,
} from "@blueprintjs/core";
import gql from "graphql-tag";
import React, { useState } from "react";
import validator from "validator";

import {
  GetNewCustomersDocument,
  useInviteNewCustomerToLoginMutation,
} from "../../gen/components";
import { AppToaster } from "../../helpers/toaster";

interface IProps {
  domainName: string;
  onClose(): void;
}

export const SendInvitationDialog: React.FC<IProps> = ({
  domainName,
  onClose,
}) => {
  const [contactEmail, setContactEmail] = useState("");
  const [contactGivenName, setContactGivenName] = useState("");
  const [contactFamilyName, setContactFamilyName] = useState("");
  const getEmail = () => `${contactEmail}@${domainName}`;
  const [sendInvite, mutationResult] = useInviteNewCustomerToLoginMutation({
    onCompleted: () => {
      AppToaster.show({
        message: `Invitation sent to ${getEmail()}`,
        intent: Intent.SUCCESS,
      });
      onClose();
    },
    refetchQueries: [{ query: GetNewCustomersDocument }],
  });
  return (
    <Dialog
      isOpen={true}
      onClose={() => onClose()}
      title={`Send a login invitation to ${domainName}`}
    >
      <div className={Classes.DIALOG_BODY}>
        <p>
          Sending an invitation will invalidate any pre-existing invitations for
          this customer.
        </p>
        <FormGroup label="Contact email">
          <ControlGroup style={{ alignItems: "baseline" }}>
            <InputGroup
              type="text"
              value={contactEmail}
              placeholder="newcustomer"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setContactEmail(e.target.value)
              }
            ></InputGroup>
            <strong>&nbsp;@{domainName}</strong>
          </ControlGroup>
        </FormGroup>
        <FormGroup label="Contact given name">
          <InputGroup
            placeholder="Shin"
            type="text"
            value={contactGivenName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setContactGivenName(e.target.value)
            }
          ></InputGroup>
        </FormGroup>
        <FormGroup label="Contact family name">
          <InputGroup
            type="text"
            value={contactFamilyName}
            placeholder="Alpacatta"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setContactFamilyName(e.target.value)
            }
          ></InputGroup>
        </FormGroup>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={() => onClose()}>Cancel</Button>
          <Button
            intent={Intent.PRIMARY}
            loading={mutationResult.called}
            disabled={
              !validator.isEmail(getEmail()) || contactFamilyName.trim() === ""
            }
            onClick={async () => {
              await sendInvite({
                variables: {
                  contactEmail: getEmail(),
                  contactFamilyName: contactFamilyName.trim(),
                  contactGivenName: contactGivenName.trim(),
                  domainName,
                },
              });
            }}
          >
            Confirm
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

gql`
  mutation inviteNewCustomerToLogin(
    $domainName: String!
    $contactEmail: String!
    $contactGivenName: String!
    $contactFamilyName: String!
  ) {
    sendLoginInvitationToNewCustomer(
      domainName: $domainName
      contactEmail: $contactEmail
      contactGivenName: $contactGivenName
      contactFamilyName: $contactFamilyName
    )
  }
`;
