import { Feature } from "common/base/types/gen";
import React from "react";
import { Link } from "react-router-dom";

import { FeatureGate } from "../../../../helpers/feature-gating/feature-gate";
import {
  InfoCalloutContainer,
  InfoCalloutHeading,
  InfoCalloutText,
} from "../common/components";

interface IProps {
  numUnmonitoredButLinked: number;
  numMonitored: number;
}

const maybeGetCallout = (
  numUnmonitoredButLinked: number,
  numMonitored: number
) => {
  if (numUnmonitoredButLinked > 0) {
    return (
      <InfoCalloutContainer>
        <InfoCalloutHeading>Install Vanta Agent</InfoCalloutHeading>
        <InfoCalloutText>
          {numUnmonitoredButLinked} of your servers{" "}
          {numUnmonitoredButLinked === 1 ? "is" : "are"} not being monitored.
          Install the Vanta Agent to automatically detect vulnerabilities across
          all of your machines.
        </InfoCalloutText>
        <Link to="/vulnerabilities/install">
          Installation instructions&nbsp;&#8594;
        </Link>
      </InfoCalloutContainer>
    );
  } else if (numMonitored === 0) {
    return (
      <InfoCalloutContainer>
        <InfoCalloutHeading>No servers monitored</InfoCalloutHeading>
        <InfoCalloutText>
          Vanta has no information about your servers. Visit the{" "}
          <Link to="/connections">Connections Page</Link> to link a cloud
          infrastructure account and the{" "}
          <Link to="/vulnerabilities/install">
            Vanta Agent install instructions
          </Link>{" "}
          to learn how to install Vanta Agent on your servers.
        </InfoCalloutText>
      </InfoCalloutContainer>
    );
  }
  return null;
};

export const VulnTableEmptyState: React.FC<IProps> = ({
  numUnmonitoredButLinked,
  numMonitored,
}) => (
  <FeatureGate features={[Feature.LegacyEnableServerAgent]}>
    {maybeGetCallout(numUnmonitoredButLinked, numMonitored)}
  </FeatureGate>
);
