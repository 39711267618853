import { Intent } from "@blueprintjs/core";
import React from "react";
import { isUUID } from "validator";

import { Button } from "../../../../alpaca/components";
import { DefaultLink } from "../../../../helpers/links";
import { SERVICE_DETAILS } from "../../../credentials/service-groups";
import type { IInputValues } from "../simple-credential-form-dialog-body";
import { SimpleCredentialFormDialogBody } from "../simple-credential-form-dialog-body";

interface IProps {
  onSubmitToken(token: string): void;
}

export const SubmitToken: React.FC<IProps> = ({ onSubmitToken }) => {
  const saveButton: React.FC<{
    canSubmit: boolean;
    inputValues: IInputValues;
  }> = ({ canSubmit, inputValues }) => (
    <Button
      intent={Intent.PRIMARY}
      disabled={!canSubmit}
      onClick={() => onSubmitToken(inputValues.token!)}
    >
      Validate and store
    </Button>
  );

  return (
    <SimpleCredentialFormDialogBody
      labels={[
        {
          name: "token",
          displayName: "API token",
          type: "password",
          helperText: (
            <span>
              Get the API token from your{" "}
              <DefaultLink href="https://app.snyk.io/account">
                Snyk account settings
              </DefaultLink>
            </span>
          ),
          valueIsValid: isUUID,
        },
      ]}
      service={SERVICE_DETAILS.snyk}
      OverrideSaveButton={saveButton}
      showTitle={false}
    />
  );
};
