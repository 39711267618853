import type { IQuestionSchema } from "../../forms/interfaces";

export const vendorProfessionalServices: IQuestionSchema = {
  id: "vendorProfessionalServices",
  title: "Professional services",
  questions: [
    {
      id: "professionalServicesHeader-2018-09-10",
      label: "Professional services",
      name: "professionalServicesHeader",
      required: false,
      type: "Header",
    },
    {
      id: "customerDataRequired-2018_07_23",
      label: "Access to customer data required",
      name: "customerDataRequired",
      required: false,
      type: "CheckboxInput",
    },
    {
      // if yes for customerDataRequired
      id: "piiControls-2018_07_23",
      label: "Protections for customer data that %COMPANY shares",
      name: "piiControls",
      required: true,
      type: "TextArea",
      visibleConditions: [{ name: "customerDataRequired" }],
    },
    {
      id: "codeRequired-2018_07_23",
      label: "Access to source code required",
      name: "codeRequired",
      required: false,
      type: "CheckboxInput",
    },
    {
      // if yes for codeRequired
      id: "sourceCodeControls-2018_07_23",
      label: "Protections for source code that %COMPANY shares",
      name: "sourceCodeControls",
      required: true,
      type: "TextArea",
      visibleConditions: [{ name: "codeRequired" }],
    },
    {
      // if yes for codeRequired
      id: "annualCodingTraining-2018_07_25",
      label:
        "Software developers trained at least annually in secure coding practices",
      name: "annualCodingTraining",
      required: false,
      type: "CheckboxInput",
      visibleConditions: [{ name: "codeRequired" }],
    },
    {
      // if yes for codeRequired
      id: "codeReviewCompleted-2018_07_25",
      label: "Peer code review required",
      name: "codeReviewCompleted",
      required: false,
      type: "CheckboxInput",
      visibleConditions: [{ name: "codeRequired" }],
    },
    {
      id: "infraAccessRequired-2018_07_23",
      label: "Access to infrastructure account required",
      name: "infraAccessRequired",
      required: false,
      type: "CheckboxInput",
    },
    {
      // if yes for infraAccessRequired
      id: "infraAcessControls-2018_07_23",
      label: "Protections for infrastructure account that %COMPANY shares",
      name: "infraAccessControls",
      required: true,
      type: "TextArea",
      visibleConditions: [{ name: "infraAccessRequired" }],
    },
    {
      choices: [
        "One week after contract ends",
        "One month after contract ends",
        "One quarter after contract ends",
        "One year after contract ends",
        "Until the company request deletion",
        "Indefinitely",
      ],
      id: "vendorRetention-2018_07_25",
      label: "How long is any data shared by %COMPANY kept?",
      name: "vendorRetention",
      required: true,
      type: "SelectInput",
    },
    {
      id: "vendorBackgroundChecks-2018_07_25",
      label:
        "Background checks performed on staff that handle %COMPANY data, code, or infrastructure",
      name: "vendorBackgroundChecks",
      required: false,
      type: "CheckboxInput",
    },
  ],
  submitText: "Submit professional services info",
  version: "2018-09-11",
};
