import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import moment from "moment";
import React from "react";

import { LogError } from "../../errors";
import type { AllEndpointsQuery } from "../../gen/components";
import {
  AllEndpointsDocument,
  useAllEndpointsQuery,
  useBumpEpochMutation,
  useSetOsqueryActiveStatusMutation,
  useUpdateOsqueryEndpointMutation,
} from "../../gen/components";
import { UI_DATE_FORMAT } from "../../helpers/common";
import { AppToaster } from "../../helpers/toaster";
import type { INonUserChoice } from "../form-controls/user-selector";
import { UserSelectorInternal } from "../form-controls/user-selector";
import { FullPageSpinner } from "../helpers/FullPageSpinner";
import { DataTable } from "../pages/components/data-table";
import { DeactivateCloudAgentsWidget } from "./deactivate-cloud-agents-widget";
import { QueryPanelContainer } from "./query-panel-container";

interface IProps {
  domainId: string;
}

export const OSQueryEndpointsPanel: React.FunctionComponent<IProps> = ({
  domainId,
}) => {
  const { loading, error, data } = useAllEndpointsQuery({
    variables: { domainId },
  });

  const [updateOsqueryEndpoint, { loading: updateOsqueryEndpointLoading }] =
    useUpdateOsqueryEndpointMutation({
      refetchQueries: [
        {
          query: AllEndpointsDocument,
          variables: {
            domainId,
          },
        },
      ],
    });

  const [bumpEpoch, { loading: bumpEpochLoading }] = useBumpEpochMutation({
    refetchQueries: [
      {
        query: AllEndpointsDocument,
        variables: {
          domainId,
        },
      },
    ],
  });

  const [setOsqueryActiveStatus, { loading: setToActiveLoading }] =
    useSetOsqueryActiveStatusMutation({
      refetchQueries: [
        {
          query: AllEndpointsDocument,
          variables: {
            domainId,
          },
        },
      ],
    });

  if (error) {
    LogError(error);
  }

  if (loading || !isSome(data)) {
    return <FullPageSpinner />;
  }

  const columnOrder = [
    "nodeKey",
    "hostIdentifier",
    "user",
    "createdAt",
    "lastPing",
    "platform",
    "hostname",
    "epoch",
    "active",
  ];

  const header = {
    nodeKey: "Node Key",
    hostIdentifier: "Host Identifier",
    user: "Owner or cloudprovider ID",
    createdAt: "Time created",
    lastPing: "Last seen?",
    platform: "Platform",
    hostname: "Host Details",
    epoch: "epoch",
    active: "active",
  };

  const agentTable = (
    <DataTable
      header={header}
      columnOrder={columnOrder}
      classes="manage-table"
      data={data.internal.domainById.osqueryEndpoints!.slice()}
      searchFilter={(endpoint, searchText) => {
        if (searchText.trim() === "") {
          return true;
        }
        const searchTextLowerCase = searchText.toLocaleLowerCase();
        const fields: Array<keyof typeof endpoint> = [
          "cloudProviderId",
          "hostIdentifier",
          "node_key",
          "platform",
        ];
        return fields.some(field =>
          String(endpoint[field])
            .toLocaleLowerCase()
            .includes(searchTextLowerCase)
        );
      }}
      tableFilters={{
        noFilterDropdownText: "All Endpoints",
        filters: [
          ["Servers", e => !isSome(e.user)],
          ["Clients", e => isSome(e.user)],
          ["Windows", e => e.platform === "windows"],
          ["Mac", e => e.platform === "darwin"],
          ["Linux Clients", e => e.platform === "linux" && isSome(e.user)],
          ["Linux Servers", e => e.platform === "linux" && !isSome(e.user)],
        ],
        emptyStateText: "No endpoints found",
      }}
      columnSortFunctions={{
        nodeKey: (e1, e2) => e1.node_key.localeCompare(e2.node_key),
        hostIdentifier: (e1, e2) =>
          e1.hostIdentifier.localeCompare(e2.hostIdentifier),
        platform: (e1, e2) => e1.platform.localeCompare(e2.platform),
        epoch: (e1, e2) => e1.epoch - e2.epoch,
        lastPing: (e1, e2) =>
          parseInt(e1.data.lastPing ?? "0", 10) -
          parseInt(e2.data.lastPing ?? "0", 10),
        createdAt: (e1, e2) =>
          new Date(e1.createdAt).getTime() - new Date(e2.createdAt).getTime(),
      }}
      defaultSortColumn={"nodeKey"}
      createRow={row => {
        return {
          nodeKey: row.node_key,
          hostIdentifier: row.hostIdentifier,
          user: (
            <UserSelectorInternal
              domainId={domainId}
              nonUserChoices={getNonUserChoice(row)}
              allowNonHumanUsers={false}
              disabled={updateOsqueryEndpointLoading}
              includeRemovedUsers={false}
              onSelect={async choice => {
                await updateOsqueryEndpoint({
                  variables: {
                    domainId: domainId!,
                    id: row.id,
                    user: choice![0]!,
                  },
                });
                const toastMessage =
                  choice![0]! === "host"
                    ? "Successfully converted to server"
                    : "Successfully updated agent owner";
                AppToaster.show({
                  icon: "tick",
                  intent: Intent.SUCCESS,
                  message: toastMessage,
                  timeout: 2500,
                });
              }}
              selected={row.user?.id ?? "host"}
              undeletable={true}
            />
          ),
          createdAt: moment(row.createdAt).format(UI_DATE_FORMAT),
          lastPing: isSome(row.data.lastPing)
            ? moment.unix(parseInt(row.data.lastPing, 10)).fromNow()
            : "No pings",
          platform: `${row.platform} (${row.data.osVersion})`,
          hostname: `${row.data.hostname} (${row.data.serialNumber})`,
          epoch: (
            <div>
              Current epoch: {row.epoch}{" "}
              <Button
                disabled={bumpEpochLoading}
                onClick={async () =>
                  bumpEpoch({ variables: { nodeKey: row.node_key } })
                }
              >
                Bump
              </Button>
            </div>
          ),
          active: (
            <div>
              {row.active.toString()}
              <Button
                disabled={setToActiveLoading}
                onClick={async () =>
                  setOsqueryActiveStatus({
                    variables: { active: !row.active, nodeKey: row.node_key },
                  })
                }
              >
                {row.active ? "Set inactive" : "Set active"}
              </Button>
            </div>
          ),
        };
      }}
    />
  );

  return (
    <QueryPanelContainer>
      <DeactivateCloudAgentsWidget domainId={domainId} />
      {agentTable}
    </QueryPanelContainer>
  );
};

function getNonUserChoice(
  row: NonNullable<
    AllEndpointsQuery["internal"]["domainById"]["osqueryEndpoints"]
  >[number]
): Maybe<INonUserChoice[]> {
  const icon = IconNames.CLOUD;
  const value = "host";
  if (!isSome(row.user) && isSome(row.cloudProviderId)) {
    // It's a server - show the cloudProviderId
    return [{ text: row.cloudProviderId, value, icon }];
  } else if (row.platform === "linux" && isSome(row.user)) {
    // It's a linux client - offer to convert it
    return [{ text: "Convert to server", value, icon }];
  }
  return undefined;
}

gql`
  query AllEndpoints($domainId: ID!) {
    internal {
      domainById(id: $domainId) {
        id
        osqueryEndpoints {
          id
          active
          node_key
          hostIdentifier
          user {
            id
          }
          platform
          cloudProviderId
          createdAt
          data {
            serialNumber
            hostname
            lastPing
            osVersion
            id
          }
          epoch
        }
      }
    }
  }
`;

gql`
  mutation BumpEpoch($nodeKey: String!) {
    bumpEpoch(nodeKey: $nodeKey)
  }
`;

gql`
  mutation UpdateOsqueryEndpoint($domainId: ID!, $id: ID!, $user: String!) {
    updateOsqueryEndpoint(domainId: $domainId, id: $id, user: $user) {
      id
      user {
        id
      }
    }
  }
`;

gql`
  mutation setOsqueryActiveStatus($active: Boolean!, $nodeKey: String!) {
    setOsqueryActiveStatus(active: $active, nodeKey: $nodeKey) {
      active
      id
    }
  }
`;
