import { Intent } from "@blueprintjs/core";
import { isSome } from "common/base/types/maybe";

import {
  isApolloError,
  isValidationError,
  LogError,
  LogErrorMessage,
} from "../../errors";
import { AppToaster } from "../../helpers/toaster";

function reportLogError(error: Error | string, log = true) {
  if (typeof error === "object") {
    if (log) {
      LogError(error, false);
    }
    error = error.message;
  } else if (log) {
    LogErrorMessage(error);
  }

  AppToaster.show({
    icon: "warning-sign",
    intent: Intent.DANGER,
    message: error,
    timeout: 2500,
  });
}

export function reportSuccess(message: string) {
  AppToaster.show({
    icon: "tick-circle",
    intent: Intent.SUCCESS,
    message,
    timeout: 2500,
  });
}

export function reportError(error: Error | string) {
  reportLogError(error, false);
}

export function processError(e: Error, defaultMessage = "Error") {
  if (isApolloError(e)) {
    const graphQlErrors = e.graphQLErrors;
    const validation = graphQlErrors.find(
      err => err.extensions.code === "ValidationError"
    );

    if (validation && isValidationError(validation)) {
      const keys = Object.keys(validation.fields);
      if (keys.includes("email")) {
        return reportError("User email validation error");
      }
      if (keys.includes("id")) {
        return reportError("Id validation error");
      }
      return reportError(`Validation error(s): ${keys.join()}`);
    }
    if (graphQlErrors.length > 0) {
      if (graphQlErrors[0].extensions.code === "RESOURCE_NOT_FOUND") {
        return reportError("User id not found");
      }
      const code = graphQlErrors[0].extensions.code;
      return reportError(
        isSome(e.message)
          ? e.message
          : (code as string) === "ServerError"
          ? defaultMessage
          : `${defaultMessage} - ${code}`
      );
    }
    reportError(isSome(e.message) ? e.message : defaultMessage);
  } else {
    // an actual exception
    reportLogError(e);
  }
}
