import { Button, Icon, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Feature } from "common/base/types/gen";
import { isSome } from "common/base/types/maybe";
import {
  FeatureDescriptions,
  FeatureKind,
} from "common/features/featureDescriptions";
import gql from "graphql-tag";
import { groupBy } from "lodash";
import React, { useState } from "react";
import { Tag, Tooltip } from "../../alpaca/components";

import { LogError } from "../../errors";
import type { FeaturePanelInfoQuery } from "../../gen/components";
import {
  ReportStandard,
  FeaturePanelInfoDocument,
  PresentedReportsDocument,
  useCreateReportForHypercomplyMutation,
  useFeaturePanelInfoQuery,
  useGrantBetaAccesssMutation,
  usePresentedReportsQuery,
  useRevokeBetaAccesssMutation,
} from "../../gen/components";
import { FullPageSpinner } from "../helpers/FullPageSpinner";
import { LoadingButton } from "./loading-button";
import {
  Container,
  Flex,
  FlexHeading,
  StandardSelector,
} from "./standard-selection";

const ALL_FEATURES = Object.keys(Feature) as Feature[];

interface IInternalProps {
  domain: NonNullable<FeaturePanelInfoQuery["internal"]["domainById"]>;
}

const getRefetchQueries = (domainId: string) => [
  {
    query: FeaturePanelInfoDocument,
    variables: { domainId },
  },
];

const FeaturePanelInternal: React.FC<IInternalProps> = ({ domain }) => {
  const { id: domainId } = domain;
  const availableFeatures = new Set(domain.betaFeatures);

  const GroupedFeatures = groupBy(
    ALL_FEATURES,
    f => FeatureDescriptions[f].kind
  );
  const BetaFeatures = GroupedFeatures[FeatureKind.Beta];
  const Soc2DomainFeatures = GroupedFeatures[FeatureKind.SOC2Domain];
  const DeprecatedFeatures = GroupedFeatures[FeatureKind.Deprecation];

  return (
    <Container>
      <h2>Standards</h2>
      <StandardSelector domainId={domainId} />
      <h2>HyperComply access</h2>
      <h4>
        Generate a HyperComply report for this customer if they are using
        HyperComply for security questionnaires.
      </h4>
      <GrantHypercomplyAccess domainId={domain.id} />
      {domain.standards.includes(ReportStandard.soc2) ? (
        <>
          <h2>SOC 2 domains</h2>
          {Soc2DomainFeatures.map(feature => (
            <FeatureComponent
              key={feature}
              feature={feature}
              hasFeature={availableFeatures.has(feature)}
              domainId={domainId}
            />
          ))}
        </>
      ) : null}
      <h2>Beta features</h2>
      {BetaFeatures.map(feature => (
        <FeatureComponent
          key={feature}
          feature={feature}
          hasFeature={availableFeatures.has(feature)}
          domainId={domainId}
        />
      ))}
      <h2>Deprecated features</h2>
      <p>
        These features were granted to all existing customers when the feature
        became deprecated; they should generally not be granted to customers who
        do not already have access to them.
      </p>
      <p>For exceptions to this rule, raise a question in Slack!</p>
      {DeprecatedFeatures.map(feature => (
        <FeatureComponent
          key={feature}
          feature={feature}
          hasFeature={availableFeatures.has(feature)}
          domainId={domainId}
        />
      ))}
    </Container>
  );
};

/**
 * FeatureComponent represents Beta and Deprecated features.
 *
 * If FEATURE is a deprecated feature, the button included here is disabled.
 */
const FeatureComponent: React.FC<{
  feature: Feature;
  hasFeature: boolean;
  domainId: string;
}> = ({ feature, hasFeature, domainId }) => {
  const description = FeatureDescriptions[feature];
  return (
    <React.Fragment key={feature}>
      <Flex>
        <div>
          <FlexHeading>
            <Icon
              iconSize={Icon.SIZE_LARGE}
              icon={hasFeature ? IconNames.TICK_CIRCLE : IconNames.DISABLE}
              intent={hasFeature ? Intent.SUCCESS : Intent.DANGER}
            />
            <span>{description.identifier}</span>
            <FeatureKindTag kind={description.kind} />
          </FlexHeading>
          <p>{description.description}</p>
        </div>
        <div>
          <GrantFeatureButton
            feature={feature}
            hasFeature={hasFeature}
            domainId={domainId}
          />
        </div>
      </Flex>
      <hr />
    </React.Fragment>
  );
};

const GrantFeatureButton: React.FC<{
  feature: Feature;
  hasFeature: boolean;
  domainId: string;
}> = ({ feature, hasFeature, domainId }) => {
  const featureKind = FeatureDescriptions[feature].kind;

  const REFETCH_QUERIES = getRefetchQueries(domainId);
  const [grantBetaAccesss] = useGrantBetaAccesssMutation({
    refetchQueries: REFETCH_QUERIES,
  });
  const [revokeBetaAccesss] = useRevokeBetaAccesssMutation({
    refetchQueries: REFETCH_QUERIES,
  });

  const button = (
    <LoadingButton
      intent={hasFeature ? Intent.DANGER : Intent.PRIMARY}
      onClick={() => {
        if (hasFeature) {
          revokeBetaAccesss({
            variables: { domainId, feature },
          }).catch(LogError);
        } else {
          grantBetaAccesss({
            variables: { domainId, feature },
          }).catch(LogError);
        }
      }}
      disabled={featureKind === FeatureKind.Deprecation}
    >
      {hasFeature ? "Revoke access" : "Grant access"}
    </LoadingButton>
  );

  return featureKind === FeatureKind.Deprecation && !hasFeature ? (
    <Tooltip content="Deprecated features can't be granted.">{button}</Tooltip>
  ) : (
    button
  );
};

const FeatureKindTag: React.FC<{ kind: FeatureKind }> = ({ kind }) => {
  switch (kind) {
    case FeatureKind.Beta:
      return <Tag intent={Intent.SUCCESS} text="Beta feature" />;
    case FeatureKind.Deprecation:
      return <Tag text="Deprecated feature" />;
    default:
      return null;
  }
};

interface IProps {
  domainId: string;
}

export const FeaturePanel: React.FC<IProps> = ({ domainId }) => {
  const { error, loading, data } = useFeaturePanelInfoQuery({
    variables: { domainId },
  });
  if (!isSome(domainId)) {
    return <div>No domain selected</div>;
  }
  if (error) {
    LogError(error);
    return null;
  }
  if (loading || !data) {
    return <FullPageSpinner />;
  }

  return <FeaturePanelInternal domain={data.internal.domainById} />;
};

const generateRandomStringWithSlightlyMoreEntropyThanUsingMathDotRandom =
  () => {
    const arr = new Uint32Array(5);
    crypto.getRandomValues(arr);
    return Array.from(arr, e => e.toString(36))
      .join("")
      .substr(0, 15);
  };

const GrantHypercomplyAccess: React.FC<IProps> = ({ domainId }) => {
  const [createReport] = useCreateReportForHypercomplyMutation();
  const { loading, error, data } = usePresentedReportsQuery({
    variables: { domainId },
  });
  const [mutationRunning, setMutationRunning] = useState(false);
  if (loading) {
    return <FullPageSpinner />;
  }
  if (error) {
    LogError(error);
    return null;
  }

  const existingHypercomplyReport =
    data?.internal.domainById.presentedReports.find(
      r =>
        r.viewerCompany.toLocaleLowerCase().includes("hypercomply") &&
        r.password.startsWith("hypercomply-")
    );
  if (!existingHypercomplyReport) {
    return (
      <Button
        onClick={async () => {
          const password = `hypercomply-${generateRandomStringWithSlightlyMoreEntropyThanUsingMathDotRandom()}`; // we search for hypercomply reports using this format, so don't change it!
          setMutationRunning(true);
          await createReport({
            variables: { domainId, password },
            refetchQueries: [
              { query: PresentedReportsDocument, variables: { domainId } },
            ],
            awaitRefetchQueries: true,
          });
          setMutationRunning(false);
        }}
        disabled={mutationRunning}
      >
        Create report for Hypercomply
      </Button>
    );
  }
  return (
    <>
      Report created for HyperComply:
      <p>
        <code>{`${location.origin}/${
          data?.internal.domainById.slug
        }/report/${encodeURIComponent(
          existingHypercomplyReport.viewerCompany
        )}-${existingHypercomplyReport.slugId}`}</code>{" "}
      </p>
      <p>
        Send HyperComply this password:{" "}
        <code>{existingHypercomplyReport.password}</code>
      </p>
    </>
  );
};

gql`
  mutation CreateReportForHypercomply($domainId: ID!, $password: String!) {
    createPresentedReport(
      domainId: $domainId
      password: $password
      showSuccessOnly: false
      standard: soc2 # Assuming hypercomply only uses soc2 at the moment
      viewerCompany: "Hypercomply (Integration)"
      skipNotificationOnView: true
    ) {
      id
    }
  }
`;

gql`
  query FeaturePanelInfo($domainId: ID!) {
    internal {
      domainById(id: $domainId) {
        id
        standards
        betaFeatures
      }
    }
  }
`;

gql`
  query PresentedReports($domainId: ID!) {
    internal {
      domainById(id: $domainId) {
        id
        presentedReports {
          id
          createdAt
          viewerCompany
          password
          showSuccessOnly
          slugId
          standard
          views {
            id
            email
            lastUpdated
          }
        }
        slug
      }
    }
  }
`;

gql`
  mutation grantBetaAccesss($domainId: ID!, $feature: feature!) {
    grantBetaFeatureAccess(domainIds: [$domainId], feature: $feature)
  }
`;

gql`
  mutation revokeBetaAccesss($domainId: ID!, $feature: feature!) {
    revokeBetaFeatureAccess(domainIds: [$domainId], feature: $feature)
  }
`;
