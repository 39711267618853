import { InputGroup } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import React from "react";
import styled from "styled-components";

import { BASE_PALETTE } from "../../../alpaca/base/colors";
import { GRID_SPACING } from "../../../alpaca/base/grid";
import type { IPaginatorProps } from "../../helpers/generic-paginator";
import { GenericPaginator } from "../../helpers/generic-paginator";

interface IProps {
  searchParams?: Maybe<{
    placeholder?: Maybe<string>;
    searchString: string;
    onNewSearchString: (searchString: string) => void;
  }>;

  /**
   * Appears on left side of controls, to the right of the search bar (if it exists)
   */
  leftElements?: Maybe<JSX.Element[]>;

  paginatorProps?: Maybe<IPaginatorProps>;
  /**
   * Appears on the right side of controls, to the right of the pagination control (if it exists)
   */
  rightElements?: Maybe<JSX.Element[]>;
}

export const TableControls: React.FC<IProps> = ({
  searchParams,
  leftElements,
  paginatorProps,
  rightElements,
}) => {
  const paginationElement = isSome(paginatorProps) ? (
    <div>
      <GenericPaginator {...paginatorProps} labelColor={BASE_PALETTE.SMOKE} />
    </div>
  ) : null;
  const searchInput = isSome(searchParams) ? (
    <TableSearchInput
      value={searchParams.searchString}
      placeholder={searchParams.placeholder ?? undefined}
      onChange={e => searchParams.onNewSearchString(e.target.value)}
      leftIcon={IconNames.SEARCH}
    />
  ) : null;
  return (
    <StyledContainerDiv>
      <StyledInnerDiv>
        {searchInput}
        {leftElements}
      </StyledInnerDiv>
      <StyledInnerDiv>
        {paginationElement}
        {rightElements}
      </StyledInnerDiv>
    </StyledContainerDiv>
  );
};

export const TABLE_CONTROLS_STYLES = {
  HEIGHT: 36,
  FONT_SIZE: 12,
  LINE_HEIGHT: 12,
  ICON_SIZE: 14,
};

const StyledContainerDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${2 * GRID_SPACING}px ${2.5 * GRID_SPACING}px;
`;

const StyledInnerDiv = styled.div`
  display: flex;
  align-items: center;
  & > *:not(:first-child) {
    margin-left: ${2 * GRID_SPACING}px;
  }
  & > * {
    flex-shrink: 0;
  }
`;

export const TableSearchInput = styled(InputGroup)`
  &&& .bp3-icon {
    position: absolute;
    top: 3px;
    left: 2px;
  }
  input {
    width: ${24 * GRID_SPACING}px !important;
    font-size: ${TABLE_CONTROLS_STYLES.FONT_SIZE}px;
    line-height: ${TABLE_CONTROLS_STYLES.LINE_HEIGHT}px;
    height: ${TABLE_CONTROLS_STYLES.HEIGHT}px;
    padding-left: ${4 * GRID_SPACING}px;
  }
`;
