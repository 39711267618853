import { Intent } from "@blueprintjs/core";
import React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";

import { AnchorButton, BodyText, H4 } from "../../../alpaca/components";
import { TabContainer } from "./common/components";

const ParentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 360px;
`;

const ZeroStateContainer = styled.div`
  text-align: center;
`;

export const VulnsEmptyState: React.FC = () => {
  const history = useHistory();

  return (
    <TabContainer>
      <ParentContainer>
        <ZeroStateContainer>
          <H4>No vulnerability scanners connected</H4>
          <BodyText>
            Vulnerabilities surfaced by vulnerability scanners will appear here
          </BodyText>
          <AnchorButton
            onClick={() => history.push("/connections")}
            intent={Intent.PRIMARY}
          >
            Connect scanner
          </AnchorButton>
        </ZeroStateContainer>
      </ParentContainer>
    </TabContainer>
  );
};
