import type { PureQueryOptions } from "@apollo/client";
import { FormGroup } from "@blueprintjs/core";
import { ALLOWED_RENDER_MIME_TYPES } from "common/base/types/helpers";
import type { Maybe } from "common/base/types/maybe";
import { getTransformedOrElse, isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import React, { useState } from "react";
import styled from "styled-components";

import { IconNames } from "../../../../alpaca/components";
import { LogError } from "../../../../errors";
import {
  GetUserInfoForOnboardingListDocument,
  useUploadEmployeeSecurityTrainingMutation,
} from "../../../../gen/components";
import { CancelConfirmDialog } from "../../../helpers/CancelConfirmDialog";
import { FileInput } from "../../components/file-input";
import { IconButton } from "../shared/icon-button";

interface IProps {
  trainingId: string;
  userId: string;
  renderButton?: Maybe<(onClick: () => void) => JSX.Element>;
  refetchQueries?: Maybe<PureQueryOptions[]>;
}

export const UploadSecurityTrainingButton: React.FC<IProps> = ({
  trainingId,
  userId,
  refetchQueries,
  renderButton,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<Maybe<File>>(null);

  const [uploadTraining] = useUploadEmployeeSecurityTrainingMutation({
    refetchQueries: refetchQueries ?? [
      {
        query: GetUserInfoForOnboardingListDocument,
        variables: { userId },
      },
    ],
  });

  const resetAndClose = () => {
    setSelectedFile(null);
    setIsOpen(false);
  };

  const onClick = () => setIsOpen(true);
  const button = getTransformedOrElse(
    renderButton,
    render => render(onClick),
    <IconButton
      onClick={onClick}
      icon={IconNames.UPLOAD}
      tooltipContent={"Upload"}
    />
  );

  return (
    <>
      {button}
      <CancelConfirmDialog
        onConfirm={() => {
          uploadTraining({
            variables: {
              userId,
              screenshot: selectedFile!,
              trainingId,
            },
          }).catch(LogError);
          resetAndClose();
        }}
        confirmText="Upload"
        confirmDisabled={!isSome(selectedFile)}
        onClose={resetAndClose}
        isOpen={isOpen}
        title={"Upload security training"}
        body={
          <>
            <div>
              Upload evidence that this employee has completed security training
            </div>
            <PaddedFormGroup>
              <FileInput
                selectedFile={selectedFile}
                fileUploaded={(name, file) => setSelectedFile(file)}
                id="file-confirm-upload"
                name="file-confirm-upload"
                accept={ALLOWED_RENDER_MIME_TYPES}
              />
            </PaddedFormGroup>
          </>
        }
      />
    </>
  );
};

const PaddedFormGroup = styled(FormGroup)`
  padding-top: 12px;
`;

gql`
  mutation uploadEmployeeSecurityTraining(
    $screenshot: Upload!
    $trainingId: String!
    $userId: ID!
  ) {
    setUserSecurityTrainingAttestation(
      screenshot: $screenshot
      trainingId: $trainingId
      userId: $userId
    ) {
      id
    }
  }
`;
