import React from "react";

import { DefaultLink } from "../../../../helpers/links";
import { SERVICE_DETAILS } from "../../../credentials/service-groups";
import { SimpleCredentialFormDialogBody } from "../simple-credential-form-dialog-body";
import type { IFormCredentialProps } from "./common-interface";

export const PivotalTracker: React.FunctionComponent<IFormCredentialProps> = ({
  onCredentialsLinked,
}) => {
  const helperText = (
    <span>
      Find your API token at the bottom of your{" "}
      <DefaultLink href="https://www.pivotaltracker.com/profile">
        profile
      </DefaultLink>
      .
    </span>
  );
  return (
    <SimpleCredentialFormDialogBody
      labels={[{ name: "token", displayName: "API Token", helperText }]}
      service={SERVICE_DETAILS.pivotaltracker}
      onCredentialsLinked={onCredentialsLinked}
    />
  );
};
