import { Intent } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import React from "react";

import { Icon, IconNames } from "../../../../alpaca/components";
import { InfoNotAvailable } from "./styles";

export const MaybeBooleanToIcon: React.FC<{ bool?: Maybe<boolean> }> = ({
  bool,
}) => {
  if (isSome(bool)) {
    if (bool) {
      return <Icon icon={IconNames.SUCCESS} intent={Intent.SUCCESS} />;
    }
    return <Icon icon={IconNames.DANGER} />;
  }
  return <InfoNotAvailable />;
};
