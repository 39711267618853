import { AnchorButton, Classes } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React from "react";

import type { IOAuthCredentialProps } from "./common-interface";

const details: {
  [k: string]: {
    termForOrg: string;
    termForAdmin: string;
  };
} = {
  azuredevops: {
    termForOrg: "organization",
    termForAdmin: "administrator",
  },
  bitbucket: {
    termForOrg: "workspace",
    termForAdmin: "administrator",
  },
  gitlab: {
    termForOrg: "group",
    termForAdmin: "owner",
  },
};

export const VersionControlLinkingInstructions: React.FunctionComponent<IOAuthCredentialProps> =
  ({ service }) => {
    const url = `/auth/login/${service.id}`;
    const { termForAdmin, termForOrg } = details[service.id];
    const displayService = service.displayName;

    return (
      <div>
        <div className={Classes.DIALOG_HEADER}>Link {displayService}</div>
        <div className={Classes.DIALOG_BODY}>
          <p>
            Let's get started linking your {displayService} {termForOrg}.
          </p>
          <p>
            First, verify that you are an {termForAdmin} of the {displayService}{" "}
            team housing your company's codebase.
          </p>
          <p>
            An easy way to check if your account is a {termForOrg}{" "}
            {termForAdmin} is to visit your {termForOrg}'s page and verify that
            Settings is an option in the sidebar.
          </p>
        </div>

        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <AnchorButton href={url} icon={IconNames.LOG_IN}>
              Connect {displayService}
            </AnchorButton>
          </div>
        </div>
      </div>
    );
  };
