import { Classes, Dialog, Intent } from "@blueprintjs/core";
import { TestOutcome } from "common/base/types/gen";
import {
  dropNothing,
  getTransformedOrElse,
  isSome,
} from "common/base/types/maybe";
import {
  AuditTypeToHumanName,
  StandardToDisplayName,
} from "common/constants/displayNames";
import gql from "graphql-tag";
import moment from "moment";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";

import { BASE_PALETTE } from "../../alpaca/base/colors";
import {
  AnchorButton,
  Button,
  DefaultView,
  Icon,
  IconNames,
  Menu,
  MenuButton,
  MenuItem,
  Tooltip,
} from "../../alpaca/components";
import { LogError } from "../../errors";
import type { GetCustomerMaterialsQuery } from "../../gen/components";
import {
  GetCustomerMaterialsDocument,
  useDeleteReportMutation,
  useGetCustomerMaterialsQuery,
  Feature,
} from "../../gen/components";
import { writeToClipboard } from "../../helpers";
import { UI_DATE_FORMAT_WITHOUT_TIME } from "../../helpers/common";
import { Ellipsify } from "../../helpers/ellipsify";
import { useFeatureCheck } from "../../helpers/feature-gating/feature-check";
import { DefaultLink } from "../../helpers/links";
import { AppToaster } from "../../helpers/toaster";
import { EvaluationIcon } from "../helpers/EvaluationIcon";
import { FullPageSpinner } from "../helpers/FullPageSpinner";
import { ViewsDisplay } from "../helpers/ViewsDisplay";
import { Card } from "../pages/components/card";
import { COLUMN_CLASSES, DataTable } from "../pages/components/data-table";
import { UserContext } from "../pages/user-context";
import { CreateReport } from "./create-report";

const TAB_IDS = {
  AUDIT_REPORTS: "audit-reports",
  GAP_ASSESSMENTS: "gap-assessments",
};

type Audit = NonNullable<
  GetCustomerMaterialsQuery["user"]
>["domain"]["audits"][number];

function toTableInfo(audit: Audit) {
  if (!isSome(audit.completionDate)) {
    return null;
  }
  const auditTypeReadable = AuditTypeToHumanName[audit.auditType];
  const auditFirm = audit.auditFirmInfo?.firmName ?? "No auditor information";
  return {
    completionDate: audit.completionDate,
    auditTypeReadable,
    auditors: audit.auditors,
    auditFirm,
    searchTexts: [auditTypeReadable, auditFirm].map(s => s.toLocaleLowerCase()),
    report: audit.report,
  };
}

type AuditTableData = NonNullable<ReturnType<typeof toTableInfo>>;

const AuditReportsTable: React.FC<{ audits: Audit[] }> = props => {
  function renderRow(audit: AuditTableData) {
    return {
      completed: moment(audit.completionDate).format(
        UI_DATE_FORMAT_WITHOUT_TIME
      ),
      auditor: (
        <>
          <div style={{ marginBottom: 8 }}>{audit.auditFirm}</div>
          {audit.auditors.length > 0
            ? audit.auditors.map(a => (
                <div key={a.id}>{`${a.displayName} (${a.email})`}</div>
              ))
            : null}
        </>
      ),
      standard: <div>{audit.auditTypeReadable}</div>,
      actions: getTransformedOrElse(
        audit.report,
        report => (
          <StyledAnchorButton
            small
            icon={IconNames.DOWNLOAD}
            href={`doc?s=${report.slugId}&download=true`}
          />
        ),
        null
      ),
    };
  }

  return (
    <DataTable
      columnOrder={["standard", "auditor", "completed", "actions"]}
      createRow={renderRow}
      data={dropNothing(props.audits.map(toTableInfo))}
      searchFilter={(audit, searchText) => {
        const lowerCased = searchText.toLocaleLowerCase().trim();
        return audit.searchTexts.some(s => s.includes(lowerCased));
      }}
      header={{
        auditor: "Auditor",
        completed: "Completed",
        standard: "Standard",
        actions: "Actions",
      }}
      paginate={{ paginationId: "audit-reports-table" }}
      columnClasses={{
        actions: COLUMN_CLASSES.CENTER_ALIGN,
      }}
      columnSortFunctions={{
        completed: (r1, r2) =>
          new Date(r1.completionDate).valueOf() -
          new Date(r2.completionDate).valueOf(),
        auditor: (r1, r2) => r1.auditFirm.localeCompare(r2.auditFirm),
        standard: (r1, r2) =>
          r1.auditTypeReadable.localeCompare(r2.auditTypeReadable),
      }}
      useDefaultStyling
      emptyDefault="Your audit report will be available here as soon as it's ready."
    />
  );
};

// Replace with AnchorButton2 when ready
const StyledAnchorButton = styled(AnchorButton)`
  &&& {
    padding-left: 16px;
    padding-right: 16px;
    svg {
      width: 12px;
      height: 12px;
    }
  }
`;

export const ReportsPage: React.FC = () => {
  const history = useHistory();
  const isAuditJourneyCustomer = useFeatureCheck(Feature.AuditJourneyCustomer);
  const [isCreateDialogOpen, setCreateDialogOpen] = useState(false);
  const [deletePresentedReport] = useDeleteReportMutation({
    refetchQueries: [{ query: GetCustomerMaterialsDocument }],
  });
  const { domainStandards } = useContext(UserContext);

  const { error, loading, data } = useGetCustomerMaterialsQuery();
  if (loading) {
    return <FullPageSpinner />;
  }
  if (error || !data || !data.user) {
    LogError(error ?? new Error("Bad fetch"));
    return null;
  }

  const user = data.user;

  const searchParams = new URLSearchParams(window.location.search);
  const selectedTab = searchParams.get("tab") ?? TAB_IDS.GAP_ASSESSMENTS;

  // only render if feature flag is enabled
  const isAuditReportsPage =
    isAuditJourneyCustomer && selectedTab === TAB_IDS.AUDIT_REPORTS;

  function renderRow(
    report: NonNullable<
      GetCustomerMaterialsQuery["user"]
    >["domain"]["presentedReports"][number]
  ) {
    const link = `${location.origin}/${
      user.domain.slug
    }/report/${encodeURIComponent(report.viewerCompany)}-${report.slugId}`;
    return {
      created: moment(report.createdAt).format(UI_DATE_FORMAT_WITHOUT_TIME),
      customer: (
        <DefaultLink href={link}>
          <Ellipsify text={report.viewerCompany} />
        </DefaultLink>
      ),
      password: (
        <StyledCode onClick={async () => writeToClipboard(report.password)}>
          <Ellipsify text={report.password} />
        </StyledCode>
      ),
      standard: StandardToDisplayName(report.standard),
      successes: report.showSuccessOnly ? (
        <EvaluationIcon evaluation={TestOutcome.PASS} />
      ) : null,
      viewers: <ViewsDisplay item={report} />,
      actions: (
        <MenuButton
          small
          menu={
            <Menu>
              <MenuItem
                text="Delete report"
                onClick={() => {
                  deletePresentedReport({
                    variables: {
                      presentedReportId: report.id,
                    },
                  }).catch(LogError);
                }}
              />
            </Menu>
          }
        />
      ),
    };
  }

  const reportTable = (
    <DataTable
      columnOrder={[
        "customer",
        "standard",
        "created",
        "successes",
        "password",
        "viewers",
        "actions",
      ]}
      columnWidths={[
        "250px",
        "180px",
        "120px",
        "200px",
        "180px",
        "160px",
        "80px",
      ]}
      columnStylesNative={{
        customer: { paddingRight: 24 },
        successes: { paddingRight: 20 },
        viewers: { paddingRight: 40 },
      }}
      columnClasses={{
        successes: COLUMN_CLASSES.CENTER_ALIGN,
        viewers: COLUMN_CLASSES.CENTER_ALIGN,
        actions: COLUMN_CLASSES.CENTER_ALIGN,
      }}
      createRow={renderRow}
      data={user.domain.presentedReports.filter(p =>
        domainStandards.includes(p.standard)
      )}
      searchFilter={(report, searchText) => {
        const lowerCased = searchText.toLocaleLowerCase();
        return (
          StandardToDisplayName(report.standard)
            .toLocaleLowerCase()
            .includes(lowerCased) ||
          report.viewerCompany.toLocaleLowerCase().includes(lowerCased)
        );
      }}
      header={{
        created: "Created",
        customer: "Company",
        password: (
          <span>
            Password
            <Tooltip
              placement="bottom"
              content="Share report with a password to help restrict access"
            >
              <StyledIcon iconSize={12} icon={IconNames.INFO} />
            </Tooltip>
          </span>
        ),
        standard: "Standard",
        successes: (
          <span>
            Success only
            <Tooltip
              placement="bottom"
              content="Only show passing tests in the report"
            >
              <StyledIcon iconSize={12} icon={IconNames.INFO} />
            </Tooltip>
          </span>
        ),
        viewers: "Views",
        actions: "Actions",
      }}
      paginate={{ paginationId: "sec-reports-table" }}
      columnSortFunctions={{
        created: (r1, r2) =>
          new Date(r1.createdAt).valueOf() - new Date(r2.createdAt).valueOf(),
        customer: (r1, r2) => r1.viewerCompany.localeCompare(r2.viewerCompany),
        standard: (r1, r2) =>
          StandardToDisplayName(r1.standard).localeCompare(r2.standard),
      }}
      useDefaultStyling
    />
  );

  const createDialog = isCreateDialogOpen ? (
    <Dialog
      title="Create a new report"
      isOpen={true}
      onClose={() => setCreateDialogOpen(false)}
    >
      <div className={Classes.DIALOG_BODY}>
        <CreateReport
          domain={user.domain}
          onReportCreated={() => {
            setCreateDialogOpen(false);
            AppToaster.show({
              icon: "tick",
              intent: Intent.SUCCESS,
              message: `Report created`,
              timeout: 2500,
            });
          }}
        />
      </div>
    </Dialog>
  ) : null;

  return (
    <DefaultView
      altMinWidth={1298}
      headerProps={{
        title: "Security Reports",
        tabProps: isAuditJourneyCustomer
          ? {
              id: "reports-tabs",
              selectedTabId: selectedTab,
              onChange: (newTabId: string) => {
                const newParams = new URLSearchParams();
                newParams.set("tab", String(newTabId));
                history.push({ search: newParams.toString() });
              },
              tabIds: [
                { id: TAB_IDS.GAP_ASSESSMENTS, title: "Gap Assessments" },
                { id: TAB_IDS.AUDIT_REPORTS, title: "Audit Reports" },
              ],
            }
          : undefined,
        rightControls: [
          <Button key="create" onClick={() => setCreateDialogOpen(true)}>
            Create {isAuditJourneyCustomer ? "assessment" : "report"}
          </Button>,
        ],
      }}
    >
      {createDialog}
      <Card>
        {isAuditReportsPage ? (
          <AuditReportsTable audits={user.domain.audits} />
        ) : (
          reportTable
        )}
      </Card>
    </DefaultView>
  );
};

gql`
  query GetCustomerMaterials {
    user {
      id
      domain {
        id
        displayName
        name
        presentedReports {
          id
          createdAt
          viewerCompany
          password
          showSuccessOnly
          slugId
          standard
          views {
            id
            email
            lastUpdated
          }
        }
        audits(pastAudits: true) {
          id
          auditors {
            id
            displayName
            email
          }
          auditFirmInfo {
            id
            firmName
          }
          auditType
          completionDate
          report {
            id
            slugId
          }
        }
        slug
      }
    }
  }
`;

gql`
  mutation deleteReport($presentedReportId: String!) {
    editPresentedReport(presentedReportId: $presentedReportId, active: false)
  }
`;

const StyledCode = styled.code`
  &:hover {
    cursor: pointer;
  }
`;

const StyledIcon = styled(Icon)`
  margin-left: 4px;
  position: relative;
  top: -4;
  && svg {
    fill: ${BASE_PALETTE.SMOKE};
  }
`;
