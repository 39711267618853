import { isSome } from "common/base/types/maybe";
import React, { useContext } from "react";

import { UserContext } from "../../pages/user-context";
import { UserMenuPaths } from "../links";
import { AdminMenu } from "./admin-menu";
import { AssumedUserDisplay } from "./assumed-user-display";
import {
  Container,
  DropdownMenu,
  Padded,
  SimpleMenuList,
  SmallSpacer,
  StyledLink,
} from "./menu";
import { UserIcon } from "./user-icon";
import { UserCompanyInfo, UserInfo } from "./user-info";

const ACCOUNT_MENU_OPTIONS = [
  {
    text: "Privacy policy",
    path: "https://vanta.com/privacy",
    isExternal: true,
  },
  {
    text: "Sign out",
    path: "/logout",
    isExternal: false,
  },
];

export const AccountMenu: React.FC = () => {
  const { user } = useContext(UserContext);
  if (!isSome(user)) {
    return null;
  }

  const userInfo = (
    <StyledLink to="/emailSettings">
      <UserInfo description="ACCOUNT SETTINGS" />
    </StyledLink>
  );

  const companyInfo = (
    <StyledLink to="/business-information">
      <UserCompanyInfo description="COMPANY SETTINGS" />
    </StyledLink>
  );

  const userAndCompany = user.isAssumedUser ? (
    <AssumedUserDisplay>
      {userInfo}
      {companyInfo}
    </AssumedUserDisplay>
  ) : (
    <Padded>
      {userInfo}
      <SmallSpacer />
      {companyInfo}
    </Padded>
  );

  const fullMenu = (
    <Container>
      {isSome(user.auditorInfo) ? null : userAndCompany}
      {user.isVantaTrusted ? <AdminMenu /> : null}
      <SmallSpacer />
      <SimpleMenuList menuOptions={ACCOUNT_MENU_OPTIONS} />
    </Container>
  );

  return (
    <DropdownMenu
      icon={<UserIcon />}
      content={fullMenu}
      activeOverride={UserMenuPaths.has(window.location.pathname)}
    />
  );
};
