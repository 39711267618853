import { Callout, Spinner } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { dateStringToDate } from "common/base/dateUtils";
import type { Maybe } from "common/base/types/maybe";
import { dropNothing, isSome, nothing } from "common/base/types/maybe";
import gql from "graphql-tag";
import moment from "moment";
import React, { useState } from "react";
import { Redirect, useHistory } from "react-router";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { BASE_TYPOGRAPHY } from "../../../../alpaca/base/typography";
import {
  BodyText,
  Button as AlpacaButton,
  DefaultView,
} from "../../../../alpaca/components";
import { LogError } from "../../../../errors";
import type { FetchEc2InstanceVulnerabilityInfoQuery } from "../../../../gen/components";
import {
  FetchEc2InstanceVulnerabilityInfoDocument,
  useFetchEc2InstanceVulnerabilityInfoQuery,
} from "../../../../gen/components";

import { DataTable } from "../../components/data-table";
import {
  Caption,
  LoadingContainer,
  TabContainer,
  TableContainer,
  VulnerabilityFindingsHeading,
} from "../common/components";
import { IgnoreContainerVulnDialog } from "../common/ignore-container-vuln-dialog";
import { LinksForCVEs } from "../common/link-for-cve";
import { SeverityPill } from "../common/severity-pill";
import { SLADeadlineWithDate } from "../common/sla-deadline-with-date";
import { makeSubheadingString, slaDeadlineComparator } from "../utils";
import { AWSInstanceNoScanState } from "./aws-instance-no-scan";
import { getInspectorRunStatus, InspectorRunStatus } from "./common";
import {
  awsInspectorVulnsInstancesPath,
  awsInspectorVulnsPath,
  MAX_CVES_TO_DISPLAY,
} from "./constants";
import { InstanceInfo } from "./instance-info";

const CalloutContainer = styled.div`
  margin-bottom: 24px;
  margin-top: 24px;
`;

interface IProps {
  // We actually want to use the object id here, not the `instanceId` field
  ec2ResourceId: string;
}

type AwsInspectorVulnerability = Extract<
  NonNullable<
    FetchEc2InstanceVulnerabilityInfoQuery["organization"]
  >["resourcesByIds"][number],
  { __typename?: Maybe<"SpecificEC2InstanceResource"> }
>["vulnerabilities"][number];

export type Instance = NonNullable<
  FetchEc2InstanceVulnerabilityInfoQuery["organization"]
>["resourcesByIds"][number];

export const InstanceDetail: React.FC<IProps> = ({ ec2ResourceId }) => {
  const history = useHistory();

  const { error, loading, data } = useFetchEc2InstanceVulnerabilityInfoQuery({
    variables: {
      ec2ResourceId,
    },
  });
  const [vulnToIgnore, setVulnToIgnore] =
    useState<Maybe<AwsInspectorVulnerability>>(null);

  if (error) {
    LogError(error);
    history.push(`${awsInspectorVulnsPath}/servers`);
    return <div />;
  }

  if (loading) {
    return (
      <LoadingContainer>
        <Spinner />
      </LoadingContainer>
    );
  }

  const instance = data?.organization.resourcesByIds?.[0];

  // Shouldn't happen, but guard for type narrowing on EC2 instance
  if (
    !isSome(instance) ||
    instance.__typename !== "SpecificEC2InstanceResource"
  ) {
    LogError(
      new Error("EC2 instance not found or found with incorrect __typename")
    );
    return <Redirect to={awsInspectorVulnsInstancesPath} />;
  }

  const vulns = instance.vulnerabilities;
  const inspectorRunStatus = getInspectorRunStatus(
    instance.inspector?.latestRun?.assessmentStartDate
  );
  const lastInspectorRanDate =
    instance.inspector?.latestRun?.assessmentStartDate;
  const lastAssessmentRanString =
    inspectorRunStatus === InspectorRunStatus.RECENT
      ? `last assessment ran ${moment(lastInspectorRanDate!).fromNow()}`
      : nothing;

  // We just need to pull off the external URL for first vuln because they all
  // share the same url (i.e., assessment run)
  const vulnUrl = vulns.length > 0 ? vulns[0].externalURL : nothing;

  return (
    <DefaultView
      headerProps={{
        backLink: awsInspectorVulnsInstancesPath,
        title: instance.instanceId ?? "",
        description: makeSubheadingString(
          instance.name ?? "",
          lastAssessmentRanString
        ),
        rightControls: [
          <AlpacaButton
            key="view-aws"
            onClick={() => {
              window.open(instance.externalURL, "_blank");
            }}
          >
            View in AWS
          </AlpacaButton>,
        ],
      }}
    >
      <TabContainer>
        <InstanceInfo instance={instance} />

        {inspectorRunStatus === InspectorRunStatus.STALE ? (
          <CalloutContainer>
            <Callout>
              Instance was scanned over a month ago. Vanta recommends setting up
              recurring weekly scans.{" "}
              <Link to="https://docs.aws.amazon.com/inspector/latest/userguide/inspector_assessments.html#create_assessment_via_console">
                Learn more
              </Link>
            </Callout>
          </CalloutContainer>
        ) : null}

        {inspectorRunStatus === InspectorRunStatus.NONE ? (
          <AWSInstanceNoScanState />
        ) : !isSome(vulns) || vulns.length === 0 ? (
          <BodyText fontWeight={BASE_TYPOGRAPHY.FONT_WEIGHTS.BOLD}>
            No vulnerable packages have been identified on this instance.
          </BodyText>
        ) : (
          <TableContainer>
            <VulnerabilityFindingsHeading
              numVulns={vulns.length}
              url={vulnUrl}
              service="AWS"
              tooltipText={`Vulnerabilities are from Inspector's “Common
                Vulnerabilities and Exposures” rule package`}
            />
            <DataTable
              columnWidths={["230px", "230px", "400px", "150px", "60px"]}
              columnOrder={["packages", "slaDeadline", "cves", "severity"]}
              data={[...vulns].sort(slaDeadlineComparator)}
              header={{
                packages: "Package(s)",
                slaDeadline: "Time until SLA violation",
                cves: "CVEs",
                severity: "Severity",
              }}
              menuOptions={() => [
                { text: "Ignore", iconName: IconNames.EYE_OFF, flag: "IGNORE" },
              ]}
              onMenuItemClick={(flag, clickedVuln) => {
                if (flag === "IGNORE") {
                  setVulnToIgnore(clickedVuln);
                }
              }}
              createRow={vuln => {
                const pkgIds = vuln.packageIdentifier;
                const packages =
                  pkgIds === "" ? null : (
                    <div>
                      {pkgIds.split(", ").map(pkgId => (
                        <Caption key={pkgId}>{pkgId}</Caption>
                      ))}
                    </div>
                  );
                const slaDeadline = vuln.slaDeadline;
                return {
                  packages,
                  slaDeadline: isSome(slaDeadline) ? (
                    <SLADeadlineWithDate
                      slaDeadline={dateStringToDate(slaDeadline)}
                    />
                  ) : (
                    <div>-</div>
                  ),
                  cves: (
                    <LinksForCVEs
                      cves={dropNothing(
                        vuln.findings.map(finding =>
                          isSome(finding.cveId)
                            ? {
                                name: finding.cveId,
                                description: finding.description,
                              }
                            : nothing
                        )
                      ).slice(0, MAX_CVES_TO_DISPLAY)}
                    />
                  ),
                  severity: <SeverityPill severity={vuln.severity} />,
                };
              }}
            />
          </TableContainer>
        )}

        {isSome(vulnToIgnore) ? (
          <IgnoreContainerVulnDialog
            refetchQueries={[
              {
                query: FetchEc2InstanceVulnerabilityInfoDocument,
                variables: { ec2ResourceId },
              },
            ]}
            packageIdentifier={vulnToIgnore.packageIdentifier}
            entityId={vulnToIgnore.id}
            service={"aws_inspector"}
            onClose={() => setVulnToIgnore(nothing)}
          />
        ) : null}
      </TabContainer>
    </DefaultView>
  );
};

gql`
  query fetchEC2InstanceVulnerabilityInfo($ec2ResourceId: String!) {
    organization {
      id
      resourcesByIds(ids: [$ec2ResourceId], specificResourceType: EC2Instance) {
        id
        uniqueId
        vantaAttributes {
          key
          value
          managedExternally
        }
        ... on SpecificEC2InstanceResource {
          instanceId
          name
          account
          inspector {
            latestRun {
              assessmentArn
              assessmentStartDate
            }
          }
          externalURL
          privateIPAddress
          publicIPAddress
          vulnerabilities {
            id
            externalURL
            slaDeadline
            packageIdentifier
            severity
            findings {
              cveId
              description
            }
          }
        }
      }
    }
  }
`;
