import { InputGroup, Intent, OL } from "@blueprintjs/core";
import React from "react";

import { DefaultLink } from "../../../../helpers/links";

export const LinkRole: React.FC<{
  arn: string;
  setArn: (arn: string) => void;
}> = ({ arn, setArn }) => (
  <div>
    <OL>
      <li>
        Navigate to the{" "}
        <DefaultLink href="https://console.aws.amazon.com/iam/home?#/roles/vanta-auditor">
          vanta-auditor role
        </DefaultLink>{" "}
        that you just created
      </li>
      <li>
        Copy the entire Role ARN here:{" "}
        <InputGroup
          intent={Intent.PRIMARY}
          placeholder="arn:aws:iam::123456789012:role/vanta-auditor"
          value={arn}
          onChange={(event: any) => {
            setArn(event.target.value);
          }}
        />
      </li>
    </OL>{" "}
  </div>
);
