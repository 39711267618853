import type { SpecificResource } from "../base/types/gen";
import type { SpecificResourceReturnTypes } from "../resources/resources";

const unexpectedInventoryType = (r: any) => {
  throw new Error(
    `Not a supported inventory item type: ${r.__t ?? r.__typename}`
  );
};

const employeeComputerMac = "Employee Computer (Mac)";

/**
 * A mapping from specific resource kind to a function that returns
 * the user-friendly type displayed on the inventory page.
 */
export const SpecificResourceToInventoryType: {
  [k in keyof SpecificResourceReturnTypes]: (
    resource: SpecificResourceReturnTypes[k]
  ) => string;
} = {
  AdpRunHrUser: unexpectedInventoryType,
  AdpWorkforceNowHrUser: unexpectedInventoryType,
  AirtableTask: unexpectedInventoryType,
  ALB: r => {
    if (r.type === "classic") {
      return "AWS Classic Load Balancer";
    }
    if (r.type === "network") {
      return "AWS Network Load Balancer";
    }
    if (r.type === "application") {
      return "AWS Application Load Balancer";
    }
    if (r.type === "gateway") {
      return "AWS Gateway Load Balancer";
    }
    throw new Error(`Unknown load balancer type: ${r.type}`);
  },
  AmazonLinuxPackageChangelog: unexpectedInventoryType,
  AsanaAccount: unexpectedInventoryType,
  AsanaTask: unexpectedInventoryType,
  AtlasAccount: unexpectedInventoryType,
  AtlasCluster: r => "MongoDB Atlas Cluster",
  AutoScalingGroup: r => "AWS Autoscale Group",
  AwsAccount: unexpectedInventoryType,
  AwsContainerVulnerability: unexpectedInventoryType,
  AwsCredentialReport: unexpectedInventoryType,
  AwsFlowLog: unexpectedInventoryType,
  AwsGroup: unexpectedInventoryType,
  AwsInspectorVulnerability: unexpectedInventoryType,
  AwsNetworkACL: unexpectedInventoryType,
  AwsPasswordPolicy: unexpectedInventoryType,
  AwsRole: unexpectedInventoryType,
  AwsRouteTable: unexpectedInventoryType,
  AwsSecurityGroup: unexpectedInventoryType,
  AwsSubnet: unexpectedInventoryType,
  AwsVPC: unexpectedInventoryType,
  AzureMetricAlertRule: unexpectedInventoryType,
  AzureApplicationGateway: r => "Azure Application Gateway",
  AzureBlobContainer: r => "Azure Blob Container",
  AzureContainerRepository: r => "Azure Container Repository",
  AzureContainerVulnerability: unexpectedInventoryType,
  AzureCosmosDB: r => "Azure Cosmos DB",
  AzureLoadBalancer: r => "Azure Load Balancer",
  AzureDatabaseForExternalSQLVariant: r => {
    if (r.databaseType === "mariadb") {
      return "Azure MariaDB Database";
    } else if (r.databaseType === "mysql") {
      return "Azure MySQL Database";
    } else if (r.databaseType === "postgresql") {
      return "Azure PostgreSQL Database";
    } else {
      throw new Error(`Unknown Azure database type: ${r.databaseType}`);
    }
  },
  AzureDevOpsAccount: unexpectedInventoryType,
  AzureDevOpsRepo: r => "Azure DevOps Repository",
  AzureDevOpsTask: unexpectedInventoryType,
  AzureQueue: r => "Azure Queue",
  AzureRole: unexpectedInventoryType,
  AzureRoleAssignment: unexpectedInventoryType,
  AzureScaleSetVirtualMachine: r => "Azure Scale Set Virtual Machine",
  AzureSecurityGroup: unexpectedInventoryType,
  AzureSQLDatabase: r => "Azure SQL Database",
  AzureSQLManagedInstance: r => "Azure SQL Managed Instance",
  AzureSQLServerOnVMs: r => "Azure SQL Server on Virtual Machines",
  AzureSynapseWarehouse: r => "Azure Synapse Warehouse",
  AzureVirtualMachine: r => "Azure Virtual Machine",
  AzureVirtualMachineScaleSet: r => "Azure Virtual Machine Scale Set",
  BambooHrHrUser: unexpectedInventoryType,
  BigQueryDataset: r => "GCP BigQuery Dataset",
  BigtableInstance: r => "GCP Bigtable Instance",
  BitbucketAccount: unexpectedInventoryType,
  BitbucketRepo: r => "Bitbucket Repository",
  CentosPackageChangelog: unexpectedInventoryType,
  CertnBackgroundCheck: unexpectedInventoryType,
  ClickupAccount: unexpectedInventoryType,
  ClickupTask: unexpectedInventoryType,
  CloudSQLInstance: r => "GCP Cloud SQL Instance",
  CloudTrail: unexpectedInventoryType,
  CloudWatchLogGroup: unexpectedInventoryType,
  CloudWatchMetricAlarm: unexpectedInventoryType,
  ClubhouseAccount: unexpectedInventoryType,
  ClubhouseTask: unexpectedInventoryType,
  CustomerCheckrBackgroundCheck: unexpectedInventoryType,
  DatadogAccount: unexpectedInventoryType,
  DatadogMonitor: unexpectedInventoryType,
  DatastoreProject: r => "GCP Datastore Project",
  DebianPackageChangelog: unexpectedInventoryType,
  DigitalOceanApp: r => "DigitalOcean App",
  DigitalOceanContainerRepository: r => "DigitalOcean Container Repository",
  DigitalOceanDroplet: r => "DigitalOcean Droplet",
  DigitalOceanFirewall: unexpectedInventoryType,
  DigitalOceanLoadBalancer: r => "DigitalOcean Load Balancer",
  DigitalOceanRedisCluster: r => "DigitalOcean Redis Cluster",
  DigitalOceanRelationalDbCluster: r =>
    "DigitalOcean Relational Database Cluster",
  DigitalOceanSpace: r => "DigitalOcean Space",
  DriveBackgroundCheck: unexpectedInventoryType,
  DynamoDBTable: r => "AWS DynamoDB Table",
  EC2Instance: r => "AWS Instance",
  ECRContainerRepository: r => "ECR Repository",
  EmptyDailySpecificResource: unexpectedInventoryType,
  EmptySpecificResource: unexpectedInventoryType,
  FirestoreProject: r => "GCP Firestore Collection",
  GCPComputeInstance: r => "GCP Compute Instance",
  GCPContainerRepository: r => "GCP Container Repository",
  GCPContainerVulnerability: unexpectedInventoryType,
  GCPLogBucket: unexpectedInventoryType,
  GCPLogSink: unexpectedInventoryType,
  GCPMonitoringPolicy: unexpectedInventoryType,
  GCPNetwork: unexpectedInventoryType,
  GCPRole: unexpectedInventoryType,
  GCPRoleGrant: unexpectedInventoryType,
  GCPStorageBucket: r => "GCP Storage Bucket",
  GCPSubnet: unexpectedInventoryType,
  GCPSubscription: r => "GCP Pub/Sub Subscription",
  GCPTopic: unexpectedInventoryType,
  GithubAccount: unexpectedInventoryType,
  GithubRepo: r => "GitHub Repository",
  GithubTask: unexpectedInventoryType,
  GitlabAccount: unexpectedInventoryType,
  GitlabGroup: unexpectedInventoryType,
  GitlabRepo: r => "GitLab Repository",
  GitlabTask: unexpectedInventoryType,
  GsuiteUser: unexpectedInventoryType,
  GustoHrUser: unexpectedInventoryType,
  HerokuAccount: unexpectedInventoryType,
  HerokuApp: r => "Heroku App",
  InsperityHrUser: unexpectedInventoryType,
  JamfAccount: unexpectedInventoryType,
  JamfManagedComputer: r => employeeComputerMac,
  JiraAccount: unexpectedInventoryType,
  JiraTask: unexpectedInventoryType,
  JustworksHrUser: unexpectedInventoryType,
  KandjiManagedComputer: r => employeeComputerMac,
  KnowBe4Account: unexpectedInventoryType,
  KnowBe4TrainingCampaign: unexpectedInventoryType,
  KnowBe4TrainingEnrollment: unexpectedInventoryType,
  LinearAccount: unexpectedInventoryType,
  LinearTask: unexpectedInventoryType,
  MicrosoftEndpointManagerManagedComputer: r =>
    `Employee Computer${r.operatingSystem.name === "macOS" ? " (Mac)" : ""}`,
  NamelyHrUser: unexpectedInventoryType,
  NmapSsl: unexpectedInventoryType,
  NvdCve: unexpectedInventoryType,
  OfficeUser: unexpectedInventoryType,
  OktaUser: unexpectedInventoryType,
  OsqueryVulnerability: unexpectedInventoryType,
  PaychexFlexHrUser: unexpectedInventoryType,
  PaycorHrUser: unexpectedInventoryType,
  PaylocityHrUser: unexpectedInventoryType,
  PivotalTrackerAccount: unexpectedInventoryType,
  PivotalTrackerTask: unexpectedInventoryType,
  RDSInstance: r =>
    r.engine === "docdb"
      ? "AWS DocumentDB Instance"
      : "AWS RDS Database Instance",
  QuickBooksHrUser: unexpectedInventoryType,
  RedshiftCluster: r => "AWS Redshift Cluster",
  RipplingHrUser: unexpectedInventoryType,
  S3: r => "AWS S3 Bucket",
  SlackAccount: unexpectedInventoryType,
  SnykAccount: unexpectedInventoryType,
  SnykProject: unexpectedInventoryType,
  SnykVulnerability: unexpectedInventoryType,
  SpannerInstance: r => "GCP Spanner Database",
  SQS: r => "AWS SQS Queue",
  SquarePayrollHrUser: unexpectedInventoryType,
  TrelloAccount: unexpectedInventoryType,
  TrelloTask: unexpectedInventoryType,
  TrinetHrUser: unexpectedInventoryType,
  UbuntuPackageChangelog: unexpectedInventoryType,
  VantaCheckrBackgroundCheck: unexpectedInventoryType,
  VettyBackgroundCheck: unexpectedInventoryType,
  ZenefitsHrUser: unexpectedInventoryType,
};

export function specificResourceGraphQLTypeToEnumValue(
  graphqlType: string
): SpecificResource {
  // GraphQL type names for specific resources take the form "Specific${SpecificResource}Resource"
  return graphqlType.slice(
    "Specific".length,
    -"Resource".length
  ) as SpecificResource;
}
