import type { IconName } from "@blueprintjs/core";
import { Button, Dialog } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import React, { useState } from "react";

interface IProps {
  buttonText: string;
  dialogContent: JSX.Element;
  dialogHeader?: Maybe<string>;
  icon: IconName;
}

export const UncontrolledDialogButton: React.FunctionComponent<IProps> =
  props => {
    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    return (
      <div>
        <Button icon={props.icon} onClick={() => setDialogIsOpen(true)}>
          {props.buttonText}
        </Button>
        <Dialog
          icon={props.icon}
          title={
            isSome(props.dialogHeader) ? props.dialogHeader : props.buttonText
          }
          isOpen={dialogIsOpen}
          onClose={() => setDialogIsOpen(false)}
        >
          {props.dialogContent}
        </Dialog>
      </div>
    );
  };
