import "./data-table.scss";

import type { IconName } from "@blueprintjs/core";
import { HTMLSelect, Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import classNames from "classnames";
import type { Maybe } from "common/base/types/maybe";
import { dropNothing, isSome, nothing } from "common/base/types/maybe";
import React from "react";
import type { FlattenSimpleInterpolation } from "styled-components";
import styled, { css } from "styled-components";

import { BASE_PALETTE } from "../../../alpaca/base/colors";
import { GRID_SPACING } from "../../../alpaca/base/grid";
import { Dimensions } from "../../vanta-chrome/constants";
import { DataTableRow } from "./data-table-row";
import { StickyTableHeaderContainer } from "./sticky-table-header-container";
import { TableControls } from "./table-controls";
import { TableEmptyState } from "./table-empty-state";

export const COLUMN_CLASSES = {
  CENTER_ALIGN: "mtm-column-align-center",
  LEFT_ALIGN: "mtm-column-align-left",
  RIGHT_ALIGN: "mtm-column-align-right",
};

export type DataTableFilterFn<T> = (data: T) => boolean;
export type DataTableFilter<T> = [string, DataTableFilterFn<T>];

type SortFn<T> = (d1: T, d2: T) => number;
interface IProps<T> {
  classes?: Maybe<string>;
  className?: Maybe<string>;
  columnClasses?: Maybe<{ [k: string]: string }>;
  columnOrder: readonly string[];
  columnSortFunctions?: Maybe<{ [k: string]: SortFn<T> }>;
  /**
   * This prop involves non-standard usage of styled-component and should be avoided.
   * Use columnStylesNative if you need to apply styles directly to a column
   */
  columnStyles?: Maybe<{ [k: string]: FlattenSimpleInterpolation }>;
  columnStylesNative?: Maybe<{ [k: string]: Maybe<React.CSSProperties> }>;
  columnWidths?: Maybe<string[]>;
  columnVisibilities?: Maybe<{ [k: string]: boolean }>;
  customControls?: Maybe<{
    leftControls?: Maybe<JSX.Element[]>;
    rightControls?: Maybe<JSX.Element[]>;
  }>;
  /**
   * When using controlled search, the client should handle filtering any data passed
   * to DataTable. Any filter defined in searchFilter will be ignored
   */
  controlledSearchParams?: Maybe<{
    searchString: string;
    onNewSearchString(searchString: string): void;
  }>;
  data: T[];
  defaultSortColumn?: Maybe<string>;
  emptyDefault?: Maybe<React.ReactNode>;
  header?: Maybe<{ [k: string]: Maybe<string | JSX.Element> }>;
  resetTableStateKey?: Maybe<string>;

  // specify a paginationId to be stored in localStorage if you want to paginate using the built-in Data Table paginator.
  // Otherwise, use the GenericPaginator and do not specify this prop.
  // @deprecated – use GenericPaginator instead.
  paginate?: Maybe<{ paginationId: string; defaultPageSize?: Maybe<number> }>;
  allSelected?: Maybe<boolean>;
  rowClass?: Maybe<(data: T) => Maybe<string>>;
  selectableRows?: Maybe<boolean>;
  selectedRows?: Maybe<any[]>;
  headerOptions?: Maybe<IHeaderOption>;
  tableFilters?: Maybe<{
    noFilterDropdownText?: Maybe<string>;
    /**
     * A filter is defined as a two-item tuple
     * ["Label for the filter select", filterFunction]
     */
    filters: Array<DataTableFilter<T>>;
    emptyStateText?: Maybe<string>;
  }>;
  tableStyle?: Maybe<FlattenSimpleInterpolation>;
  // eslint-disable-next-line vanta/prefer-maybe, vanta/optional-always-maybe
  useDefaultStyling?: boolean | undefined;
  /**
   * When combined with useDefaultStyling, causes table headers to stick
   * under the top bar when the user scrolls up.
   *
   * If "top" is specified, headers will stick to top of containing scroll window.
   */
  // eslint-disable-next-line vanta/prefer-maybe, vanta/optional-always-maybe
  stickyHeaders?: boolean | "top" | undefined;
  handleRowSelected?: Maybe<(row: any) => void>;
  handleSelectAll?: Maybe<() => void>;
  showHeadersOnEmptyState?: Maybe<boolean>;
  sticky?: Maybe<{ maxHeight?: Maybe<string>; minHeight?: Maybe<string> }>;
  createRow(
    data: T,
    index: number
  ): { [k: string]: Maybe<string | number | JSX.Element> };
  menuOptions?(data: T): IMenuOption[];
  menuOptionsDisabledText?(data: T): string;
  onMenuItemClick?(flag: string, data: T): void;
  onRowClick?(data: T, event: React.MouseEvent): void;
  onFilterSelected?(event: React.ChangeEvent<HTMLSelectElement>): void;
  searchFilter?(data: T, searchText: string): boolean;
  searchPlaceholder?: Maybe<string>;

  /**
   * When you do not want to sort client-side, indicate which columns are
   * sortable here instead of providing `columnSortFunctions`
   */
  sortableColumns?: Maybe<readonly string[]>;
  /**
   * Triggered when user wants to sort a column
   *
   * @param colName
   * @param isAscending - Determines which direction the user wants the sort
   */
  onHeaderSort?(colName: string, isReverseSort: boolean): void;
  /**
   * When not using client-side sort, manually supply the sort direction
   */
  isReverseSort?: Maybe<boolean>;
}

export interface IMenuOption {
  flag: string;
  iconName: IconName;
  text: string;
  disabled?: Maybe<boolean>;
  disabledTooltipText?: Maybe<string>;
}

interface IHeaderOption {
  filterLabel?: Maybe<string>;
  leadingAlignment?: Maybe<boolean>;
}

interface IState {
  currentPage: number;
  tableFilterIndex?: Maybe<number>;
  rowsPerPage: number;
  searchText: string;
  sortColumn?: Maybe<string>;
  sortInReverse: boolean;
}

export class DataTable<T> extends React.Component<IProps<T>, IState> {
  public constructor(props: IProps<T>) {
    super(props);
    let rowsPerPage = this.props.paginate?.defaultPageSize ?? 10;
    if (isSome(this.props.paginate)) {
      const item = localStorage.getItem(this.props.paginate.paginationId);
      if (isSome(item)) {
        rowsPerPage = +item;
      }
    }
    const defaultSortColumn: Maybe<string> = this.getDefaultSortColumn();
    this.state = {
      currentPage: 0,
      rowsPerPage,
      searchText: "",
      sortColumn: defaultSortColumn,
      sortInReverse: this.props.isReverseSort ?? false,
    };
  }

  private getDefaultSortColumn() {
    const {
      columnOrder,
      columnSortFunctions,
      defaultSortColumn,
      sortableColumns,
    } = this.props;

    const isProvidedValid =
      sortableColumns?.includes(defaultSortColumn ?? "") ||
      isSome(columnSortFunctions?.[defaultSortColumn ?? ""]);

    return isProvidedValid
      ? defaultSortColumn
      : columnOrder.find(c => isSome(columnSortFunctions?.[c]));
  }

  public componentDidUpdate(prevProps: IProps<T>) {
    // Reset internal pagination when data length changes
    if (prevProps?.data.length !== this.props.data.length) {
      this.setState({
        currentPage: 0,
      });
    }
    if (prevProps?.resetTableStateKey !== this.props?.resetTableStateKey) {
      this.setState({
        currentPage: 0,
        searchText: "",
        sortColumn: this.getDefaultSortColumn(),
        sortInReverse: this.props.isReverseSort ?? false,
        tableFilterIndex: nothing,
      });
    }
  }

  public getRowSlice = (data: T[]) => {
    if (this.props.paginate && this.state.rowsPerPage > 0) {
      const { start, end } = this.getStartAndEndItems(data);
      return data.slice(start, end);
    }
    return data;
  };

  public maybeFilterAndSortData = () => {
    let maybeFilteredAndSortedData = this.props.data.slice();
    if (
      (this.state.tableFilterIndex ?? -1) >= 0 &&
      isSome(this.props.tableFilters)
    ) {
      maybeFilteredAndSortedData = maybeFilteredAndSortedData.filter(
        this.props.tableFilters.filters[this.state.tableFilterIndex!][1]
      );
    }
    const { searchFilter, columnSortFunctions } = this.props;
    const searchString = this.state.searchText;
    if (isSome(searchFilter) && searchString.trim() !== "") {
      maybeFilteredAndSortedData = maybeFilteredAndSortedData.filter(d =>
        searchFilter(d, searchString)
      );
    }
    if (isSome(columnSortFunctions) && isSome(this.state.sortColumn)) {
      maybeFilteredAndSortedData.sort(
        (d1, d2) =>
          columnSortFunctions[this.state.sortColumn!](d1, d2) *
          (this.state.sortInReverse ? -1 : 1)
      );
    }
    return maybeFilteredAndSortedData;
  };

  public render() {
    const headerOrEmpty = this.renderHeader();

    const columnClasses = this.props.columnClasses ?? {};
    const columnStyles = this.props.columnStyles ?? {};
    const columnVisibilities = this.props.columnVisibilities ?? {};
    const maybeFilteredAndSortedData = this.maybeFilterAndSortData();
    const rowSlice = this.getRowSlice(maybeFilteredAndSortedData);

    const tableRows = rowSlice.map((item, index) => (
      <DataTableRow
        key={index}
        columnClasses={columnClasses}
        columnStyles={columnStyles}
        columnStylesNative={this.props.columnStylesNative ?? {}}
        columnOrder={this.props.columnOrder}
        columnVisibilities={columnVisibilities}
        data={item}
        menuOptions={
          this.props.menuOptions ? this.props.menuOptions(item) : undefined
        }
        menuOptionsDisabledText={
          this.props.menuOptionsDisabledText
            ? this.props.menuOptionsDisabledText(item)
            : "No actions available"
        }
        onMenuItemClick={this.props.onMenuItemClick}
        rowClass={
          isSome(this.props.rowClass) ? this.props.rowClass(item) : undefined
        }
        rowData={this.props.createRow(item, index)}
        onClick={this.props.onRowClick}
      />
    ));

    let maybeEmptyState: Maybe<JSX.Element>;
    if (rowSlice.length === 0) {
      const tableFiltersOn =
        (this.state.tableFilterIndex ?? -1) >= 0 ||
        (isSome(this.props.searchFilter) && this.state.searchText.length > 0);
      if (tableFiltersOn) {
        maybeEmptyState = (
          <TableEmptyState
            content={this.props.tableFilters?.emptyStateText ?? "No results"}
          />
        );
      } else if (isSome(this.props.emptyDefault)) {
        maybeEmptyState = <TableEmptyState content={this.props.emptyDefault} />;
      }
    }

    const TableComponent = this.props.useDefaultStyling
      ? StyledDefaultTable
      : StyledTable;

    const tableContent = maybeEmptyState ?? (
      <TableComponent
        clickable={isSome(this.props.onRowClick)}
        stickyHeaders={this.props.stickyHeaders}
        maybecss={this.props.tableStyle}
        className={classNames(
          // Included for legacy reasons.
          "bp3-html-table",
          this.props.classes,
          this.props.className
        )}
        style={
          isSome(this.props.columnWidths) ? { tableLayout: "fixed" } : undefined
        }
      >
        {isSome(this.props.columnWidths) ? (
          <colgroup>
            {this.props.columnWidths.map((width, index) => {
              const columnName = this.props.columnOrder[index];
              if (
                columnName in columnVisibilities &&
                !columnVisibilities[columnName]
              ) {
                return null;
              }
              return <col span={1} key={`colum_${index}`} style={{ width }} />;
            })}
          </colgroup>
        ) : null}
        {headerOrEmpty}
        <tbody>{tableRows}</tbody>
      </TableComponent>
    );

    return (
      <React.Fragment>
        {this.renderControls({
          numCurrentlyShowing: rowSlice.length,
          numTotalItems: maybeFilteredAndSortedData.length,
        })}
        {isSome(this.props.sticky) ? (
          <StickyTableHeaderContainer
            minHeight={this.props.sticky.minHeight}
            maxHeight={this.props.sticky.maxHeight}
          >
            {tableContent}
          </StickyTableHeaderContainer>
        ) : (
          tableContent
        )}
      </React.Fragment>
    );
  }

  public renderControls({
    numTotalItems,
    numCurrentlyShowing,
  }: {
    numTotalItems: number;
    numCurrentlyShowing: number;
  }) {
    const hasSearch =
      isSome(this.props.searchFilter) ||
      isSome(this.props.controlledSearchParams);
    const hasFilter = isSome(this.props.tableFilters);
    const maybePaginationId = this.props.paginate?.paginationId;

    if (
      !hasSearch &&
      !hasFilter &&
      !isSome(maybePaginationId) &&
      !isSome(this.props.customControls)
    ) {
      return null;
    }
    const maybeFilter = this.rendertableFilterselect();
    const leftElements = dropNothing([
      maybeFilter,
      ...(this.props.customControls?.leftControls ?? []),
    ]);
    return (
      <StyledControlsContainerDiv>
        <TableControls
          leftElements={leftElements}
          rightElements={this.props.customControls?.rightControls ?? []}
          searchParams={
            hasSearch
              ? {
                  searchString:
                    this.props.controlledSearchParams?.searchString ??
                    this.state.searchText,
                  placeholder: this.props.searchPlaceholder ?? "Search",
                  onNewSearchString:
                    this.props.controlledSearchParams?.onNewSearchString ??
                    (newSearchText =>
                      this.setState({
                        searchText: newSearchText,
                        currentPage: 0,
                      })),
                }
              : null
          }
          paginatorProps={
            isSome(maybePaginationId)
              ? {
                  paginationId:
                    `paginator-${maybePaginationId}` as `paginator-${string}`,
                  paginationParams: {
                    firstItemIndex:
                      this.state.currentPage * this.state.rowsPerPage,
                    itemsPerPage: this.state.rowsPerPage,
                    itemsFetched: numTotalItems,
                    loading: false,
                  },
                  pageInfo: {
                    hasPreviousPage: this.state.currentPage > 0,
                    hasNextPage:
                      this.state.rowsPerPage * (this.state.currentPage + 1) <
                      numTotalItems,
                  },
                  setPaginationParams: params =>
                    this.setState({
                      currentPage: Math.floor(
                        params.firstItemIndex / params.itemsPerPage
                      ),
                      rowsPerPage: params.itemsPerPage,
                    }),
                  totalItems: numTotalItems,
                  itemsLoaded: numCurrentlyShowing,
                  fetchMore: nothing,
                }
              : null
          }
        />
      </StyledControlsContainerDiv>
    );
  }

  public renderHeader() {
    const header = this.props.header;
    const columnVisibilities = this.props.columnVisibilities ?? {};
    const headerOrEmpty = header ? (
      <thead>
        <tr>
          {this.props.columnOrder.map((col, i) => {
            const { className, maybeClickHandler, maybeSortIcon, maybeStyles } =
              this.getTableHeaderPropsForMaybeSortableColumn(col);
            if (!(col in columnVisibilities) || columnVisibilities[col]) {
              const StyledTd = styleTd(maybeStyles);
              return (
                <StyledTd
                  key={i}
                  style={this.props.columnStylesNative?.[col] ?? undefined}
                  className={className}
                  onClick={maybeClickHandler}
                >
                  {header[col]}
                  {maybeSortIcon}
                </StyledTd>
              );
            }
            return null;
          })}
          {this.props.menuOptions ? <td /> : undefined}
        </tr>
      </thead>
    ) : undefined;
    return headerOrEmpty;
  }

  public rendertableFilterselect = () => {
    const { tableFilters, onFilterSelected } = this.props;
    if (!isSome(tableFilters)) {
      return null;
    }
    return (
      <div key="mtm-filter-select">
        {isSome(this.props.headerOptions?.filterLabel) ? (
          <FilterDropdownLabel>
            {this.props.headerOptions?.filterLabel}
          </FilterDropdownLabel>
        ) : null}
        <HTMLSelect
          value={this.state.tableFilterIndex ?? -1}
          onChange={e => {
            if (!isSome(onFilterSelected)) {
              const newValue = +e.currentTarget.value;
              this.setState({
                tableFilterIndex:
                  newValue === this.state.tableFilterIndex ? nothing : newValue,
                currentPage: 0,
              });
            } else {
              onFilterSelected(e);
            }
          }}
        >
          <option value={-1}>
            {tableFilters.noFilterDropdownText ?? "All"}
          </option>
          {tableFilters.filters.map(([title, fn], index) => (
            <option key={`select_${index}`} value={index}>
              {title}
            </option>
          ))}
        </HTMLSelect>
      </div>
    );
  };

  private readonly getStartAndEndItems = (data: T[]) => {
    const start =
      this.state.rowsPerPage > 0
        ? this.state.currentPage * this.state.rowsPerPage
        : 0;
    const end =
      this.state.rowsPerPage > 0
        ? Math.min(start + this.state.rowsPerPage, data.length)
        : data.length;

    return { start, end };
  };

  private readonly getTableHeaderPropsForMaybeSortableColumn = (
    col: string
  ) => {
    const isSortableColumn =
      this.props.sortableColumns?.includes(col) ||
      isSome(this.props.columnSortFunctions?.[col]);
    const isCurrentSortingColumn =
      isSortableColumn && col === this.state.sortColumn;

    const maybeSortIcon = isSortableColumn ? (
      <StyledIcon
        iconSize={10}
        className={
          !isCurrentSortingColumn
            ? // Allows showing the icon on hovering over the column header
              "mtm-column-header-show-on-hover"
            : undefined
        }
        icon={
          isCurrentSortingColumn
            ? this.state.sortInReverse
              ? IconNames.ARROW_UP
              : IconNames.ARROW_DOWN
            : IconNames.ARROW_DOWN
        }
      />
    ) : null;

    const maybeClickHandler = isSortableColumn
      ? (e: React.MouseEvent<HTMLElement>) => {
          if (isCurrentSortingColumn) {
            this.setState({
              sortInReverse: !this.state.sortInReverse,
              currentPage: 0,
            });
            this.props.onHeaderSort?.(col, !this.state.sortInReverse);
          } else {
            const isReverseSort = this.props.isReverseSort ?? false;
            this.setState({
              sortColumn: col,
              sortInReverse: isReverseSort,
              currentPage: 0,
            });
            this.props.onHeaderSort?.(col, isReverseSort);
          }
        }
      : undefined;

    const maybeClasses =
      isSome(this.props.columnClasses) && isSome(this.props.columnClasses[col])
        ? this.props.columnClasses[col]
        : "";

    const className = `${maybeClasses}${
      isSortableColumn ? " mtm-sortable-column-header" : ""
    }${isCurrentSortingColumn ? " mtm-selected-sort-column-header" : ""}`;

    const maybeStyles =
      isSome(this.props.columnStyles) && isSome(this.props.columnStyles[col])
        ? this.props.columnStyles[col]
        : undefined;

    return { maybeSortIcon, className, maybeClickHandler, maybeStyles };
  };
}

const StyledIcon = styled(Icon)`
  position: relative;
  top: -5px;
  left: ${GRID_SPACING}px;
`;

const FilterDropdownLabel = styled.span`
  margin-top: 3px;
  margin-left: ${2 * GRID_SPACING}px;
  margin-right: ${GRID_SPACING}px;
`;

const styleTd = (styles: Maybe<FlattenSimpleInterpolation>) => styled.td`
  ${styles ?? ""}
`;

const StyledControlsContainerDiv = styled.div`
  border-bottom: 1px solid rgba(214, 214, 214, 0.5);
`;

interface IStyledTableProps {
  maybecss?: Maybe<FlattenSimpleInterpolation>;
  clickable?: Maybe<boolean>;
  stickyHeaders?: Maybe<boolean | "top">;
}

const StyledTable = styled.table<IStyledTableProps>`
  ${({ maybecss }) => maybecss ?? ""}
`;

const StyledDefaultTable = styled.table<IStyledTableProps>`
  width: 100%;
  word-wrap: break-word;
  thead {
    td,
    th {
      border-bottom: 1px solid ${BASE_PALETTE.FOG}88;
      ${({ stickyHeaders }) =>
        isSome(stickyHeaders) && stickyHeaders !== false
          ? css`
              position: sticky;
              top: ${stickyHeaders === "top"
                ? "0"
                : `${Dimensions.TOP_BAR_HEIGHT}`}px;
              background-color: ${BASE_PALETTE.SNOW};
              z-index: 1;
            `
          : ""}
    }
  }
  td:first-child {
    padding-left: ${2.5 * GRID_SPACING}px;
  }
  && tbody {
    td {
      padding-top: ${2 * GRID_SPACING}px;
      padding-bottom: ${2 * GRID_SPACING}px;
    }
    tr:first-child {
      td {
        box-shadow: none !important;
      }
    }
    tr:nth-child(odd) {
      background-color: #f7f8fa88;
    }
    ${({ clickable }) =>
      Boolean(clickable)
        ? css`
            tr:hover {
              cursor: pointer;
              background-color: #f3f3f3;
            }
          `
        : ""}
  }
`;

export const OverflowTableStyle = css`
  // need this to make table scroll if it gets too big
  display: block;
  overflow-x: auto;
`;
