import "./vanta-report-page.scss";
import "./vanta-report-text-sizes.scss";

import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";

import { Button } from "../../alpaca/components";
import type { GetDataForReportQuery } from "../../gen/components";
import { VantaReportBanner } from "./components/banner/vanta-report-banner";
import { VantaReportMainBody } from "./components/main-body/vanta-report-main-body";
import { VantaReportSidebar } from "./components/sidebar/vanta-report-sidebar";
import type { IDomainForReport } from "./helpers/helpers";
import { VantaReportContext } from "./vanta-report-context";

export interface IReportProps {
  domain: IDomainForReport;
  complianceStandard: NonNullable<GetDataForReportQuery["complianceStandard"]>;
}

export const VantaReportPage: React.FC<IReportProps> = props => {
  const componentRef = useRef(null);
  const [isPrintView, setIsPrintView] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.title = `Vanta Report for ${props.domain.displayName}`;
  }, [props.domain]);

  const handleBeforeGetContent = async () => {
    setLoading(true);
    setIsPrintView(true);

    // UI hack: Introduce an arbitrary delay of a half-second in before showing the print view.
    // This is because Chrome's print view takes minimum half a second to process, so showing the
    // "loading" status for a minimum this time increases the feeling of responsiveness.
    return new Promise(resolve => setTimeout(resolve, 500));
  };

  const handleAfterPrint = React.useCallback(async () => {
    setIsPrintView(false);
  }, []);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: handleBeforeGetContent,
    onBeforePrint: () => setLoading(false),
    onAfterPrint: handleAfterPrint,
    pageStyle: `
              @page {
                margin-top: 7mm;
                margin-bottom: 7mm;
                margin-left: 1cm;
                margin-right: 1cm;
              }
              div.vrp-main-body {
                width: 20cm;
                max-width: 100%;
              }
            `,
  });

  const exportToPDFComponent = (
    <Button onClick={handlePrint} loading={loading}>
      Export to PDF
    </Button>
  );

  return (
    <VantaReportContext.Provider value={{ isPrintView }}>
      <div className="vrp-container" id="vrp-container">
        <VantaReportBanner />
        <VantaReportSidebar
          exportToPDFComponent={exportToPDFComponent}
          {...props}
        />
        <div ref={componentRef} className="vrp-main-body" id="vrp-main-body">
          <VantaReportMainBody {...props} />
        </div>
      </div>
    </VantaReportContext.Provider>
  );
};
