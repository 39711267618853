import type { Maybe } from "common/base/types/maybe";
import React from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import { VANTA_COLORS } from "../../alpaca/base/colors";
import { H2 } from "../../alpaca/components";

interface IBreadcrumb {
  text: string;
  route: string;
}

interface IProps {
  breadcrumbs: IBreadcrumb[];
  noMargin?: Maybe<boolean>;
}

export const Breadcrumbs: React.FC<IProps> = ({ breadcrumbs, noMargin }) => (
  <Container noMargin={noMargin}>
    {breadcrumbs.map((breadcrumb, index) => {
      const key = breadcrumb.route;
      const isLastBreadcrumb = index === breadcrumbs.length - 1;
      if (isLastBreadcrumb) {
        return (
          <H2 key={key}>
            <StyledLink to={breadcrumb.route}>{breadcrumb.text}</StyledLink>
          </H2>
        );
      } else {
        return (
          <React.Fragment key={key}>
            <H2 key={key} color={VANTA_COLORS.TEXT_DESCRIPTION}>
              <StyledLink to={breadcrumb.route}>{breadcrumb.text}</StyledLink>
              &nbsp;&nbsp;/&nbsp;&nbsp;
            </H2>
          </React.Fragment>
        );
      }
    })}
  </Container>
);

const styles = {
  MARGIN_BOTTOM: 24,
};

const StyledLink = styled(Link)`
  &,
  &:hover {
    color: inherit;
    text-decoration: none;
  }
`;

const Container = styled.div<{ noMargin?: Maybe<boolean> }>`
  display: flex;
  flex-direction: row;
  ${({ noMargin }) =>
    Boolean(noMargin)
      ? css`
          ${H2} {
            margin-bottom: 0;
            line-height: 36px;
          }
        `
      : css`
          border-bottom: ${styles.MARGIN_BOTTOM}px;
        `}
`;
