import {
  Button,
  Classes,
  Dialog,
  HTMLSelect,
  Intent,
  Tab,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";

import {
  DefaultView,
  Button as AlpacaButton,
  Tabs,
} from "../../../alpaca/components";
import type {
  InventoryCountsQuery,
  OtherInventoryItemsQuery,
} from "../../../gen/components";
import {
  Feature,
  OtherInventoryItemsDocument,
  useAddOtherInventoryItemMutation,
  useInventoryCountsQuery,
} from "../../../gen/components";
import { useAvailableBetaFeatures } from "../../../helpers/feature-gating/feature-check";
import { TableSearchInput } from "../components/table-controls";
import { DocsDigitalOceanLabel } from "../docs/digitalocean-tags";
import { DocsGCPLabel } from "../docs/gcp-label";
import { DocsTerraformTag } from "../docs/terraform-tag";
import { Autoscales } from "./tab-groups/autoscale";
import { Buckets } from "./tab-groups/bucket";
import { Compute } from "./tab-groups/compute";
import { ContainerRepository } from "./tab-groups/container-repository";
import { EmployeeComputers } from "./tab-groups/employee-computer";
import { GitRepositories } from "./tab-groups/git-repository";
import { LoadBalancers } from "./tab-groups/load-balancer";
import { NosqlDatabases } from "./tab-groups/nosql";
import {
  Other,
  updateOtherInventoryItemsCountInCache,
  updateOtherInventoryItemsInCache,
} from "./tab-groups/other";
import { LinuxServers } from "./tab-groups/other-servers";
import { PaaSApps } from "./tab-groups/paas-app";
import { Queues } from "./tab-groups/queue";
import type { IInventoryTabProps } from "./tab-groups/shared-interface";
import { SqlDatabases } from "./tab-groups/sql";
import { Warehouses } from "./tab-groups/warehouse";
import { PAGE_SIZE } from "./utils";

const TabSelectDiv = styled.div`
  margin-top: 20;
  margin-bottom: 40;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const TabSelectLabel = styled.div`
  margin-bottom: 3;
`;

const AddItemButton = styled(Button)`
  margin-left: 20;
`;

export enum TabGroupSlugs {
  EMPLOYEE_COMPUTERS = "employee-computers",
  GIT_REPOSITORY = "git-repository",
  PAAS_APPS = "paas-apps",
  LOAD_BALANCERS = "load-balancers",
  AUTOSCALE = "autoscale",
  COMPUTE = "compute",
  QUEUE = "queue",
  BUCKET = "bucket",
  NOSQL_DB = "nosql-db",
  RELATIONAL_DB = "relational-db",
  DATA_WAREHOUSE = "data-warehouse",
  CONTAINER_REPOSITORY = "container-repository",
  LINUX_SERVERS = "linux-servers",
  OTHER = "other",
}
interface InventoryTabGroup {
  name: string;
  slug: TabGroupSlugs;
  component: React.FC<IInventoryTabProps>;
  getCount: (data: InventoryCountsQuery) => Maybe<number>;
  getErrorCount: (data: InventoryCountsQuery) => Maybe<number>;
  feature?: Maybe<Feature>;
}
type ReadonlyTabGroups = Readonly<Readonly<InventoryTabGroup[]>>;

const TAB_GROUPS = [
  {
    name: "Employee Computer",
    slug: TabGroupSlugs.EMPLOYEE_COMPUTERS,
    component: EmployeeComputers,
    getCount: (data: InventoryCountsQuery) =>
      (data.organization.osqueryemployeecomputers.totalCount ?? 0) +
      (data.organization.managedemployeecomputers.totalCount ?? 0),
    getErrorCount: (data: InventoryCountsQuery) =>
      data.organization.managedemployeecomputererrors.totalCount,
  },
  {
    name: "Git Repository",
    slug: TabGroupSlugs.GIT_REPOSITORY,
    component: GitRepositories,
    getCount: (data: InventoryCountsQuery) =>
      data.organization.gitrepos.totalCount,
    getErrorCount: (data: InventoryCountsQuery) => null,
  },
  {
    name: "PaaS App",
    slug: TabGroupSlugs.PAAS_APPS,
    component: PaaSApps,
    getCount: (data: InventoryCountsQuery) =>
      data.organization.paasApp.totalCount,
    getErrorCount: (data: InventoryCountsQuery) => null,
  },
  {
    name: "Load Balancer",
    slug: TabGroupSlugs.LOAD_BALANCERS,
    component: LoadBalancers,
    getCount: (data: InventoryCountsQuery) =>
      data.organization.loadbalancers.totalCount,
    getErrorCount: (data: InventoryCountsQuery) => null,
  },
  {
    name: "Autoscale Group",
    slug: TabGroupSlugs.AUTOSCALE,
    component: Autoscales,
    getCount: (data: InventoryCountsQuery) =>
      data.organization.autoscale.totalCount,
    getErrorCount: (data: InventoryCountsQuery) => null,
  },
  {
    name: "Compute Instance",
    slug: TabGroupSlugs.COMPUTE,
    component: Compute,
    getCount: (data: InventoryCountsQuery) =>
      data.organization.compute.totalCount,
    getErrorCount: (data: InventoryCountsQuery) => null,
  },
  {
    name: "Queue",
    slug: TabGroupSlugs.QUEUE,
    component: Queues,
    getCount: (data: InventoryCountsQuery) =>
      data.organization.queue.totalCount,
    getErrorCount: (data: InventoryCountsQuery) =>
      data.organization.queueErrors.totalCount,
  },
  {
    name: "Storage Bucket",
    slug: TabGroupSlugs.BUCKET,
    component: Buckets,
    getCount: (data: InventoryCountsQuery) =>
      data.organization.bucket.totalCount,
    getErrorCount: (data: InventoryCountsQuery) =>
      data.organization.bucketErrors.totalCount,
  },
  {
    name: "NoSQL DB",
    slug: TabGroupSlugs.NOSQL_DB,
    component: NosqlDatabases,
    getCount: (data: InventoryCountsQuery) =>
      data.organization.nosqldb.totalCount,
    getErrorCount: (data: InventoryCountsQuery) => null,
  },
  {
    name: "Relational DB",
    slug: TabGroupSlugs.RELATIONAL_DB,
    component: SqlDatabases,
    getCount: (data: InventoryCountsQuery) =>
      data.organization.relationaldb.totalCount,
    getErrorCount: (data: InventoryCountsQuery) => null,
  },
  {
    name: "Data Warehouse",
    slug: TabGroupSlugs.DATA_WAREHOUSE,
    component: Warehouses,
    getCount: (data: InventoryCountsQuery) =>
      data.organization.datawarehouse.totalCount,
    getErrorCount: (data: InventoryCountsQuery) => null,
  },
  {
    name: "Container Repository",
    slug: TabGroupSlugs.CONTAINER_REPOSITORY,
    component: ContainerRepository,
    getCount: (data: InventoryCountsQuery) =>
      data.organization.containerrepository.totalCount,
    getErrorCount: (data: InventoryCountsQuery) => null,
  },
  {
    name: "Other Linux Server",
    slug: TabGroupSlugs.LINUX_SERVERS,
    component: LinuxServers,
    getCount: (data: InventoryCountsQuery) =>
      data.organization.servers.totalCount,
    getErrorCount: (data: InventoryCountsQuery) => null,
    feature: Feature.LegacyEnableServerAgent,
  },
  {
    name: "Other",
    slug: TabGroupSlugs.OTHER,
    component: Other,
    getCount: (data: InventoryCountsQuery) =>
      data.organization.otherinventoryitems.totalCount,
    getErrorCount: (data: InventoryCountsQuery) => null,
  },
] as const;

const slugs = TAB_GROUPS.map(t => t.slug);

export const InventoryListPage: React.FC = () => {
  const [searchString, setSearchString] = useState("");
  const [isTagDialogOpen, setTagDialogOpen] = useState(false);
  const [currentTab, setCurrentTab] = useState(
    TabGroupSlugs.EMPLOYEE_COMPUTERS
  );
  const history = useHistory();

  const availableBetaFeatures = useAvailableBetaFeatures();
  const gatedTabGroups = (TAB_GROUPS as ReadonlyTabGroups).filter(
    tabGroup =>
      !isSome(tabGroup.feature) || availableBetaFeatures?.has(tabGroup.feature)
  );

  useEffect(() => {
    const setTab = () => {
      // Check if we need to search for a resource given a tab group hash.
      const params = new URLSearchParams(window.location.search);
      const query = params.get("q");
      if (isSome(query)) {
        history.replace(`${window.location.pathname}${window.location.hash}`);
        setSearchString(query);
      }
      if (window.location.hash !== "") {
        const hash = window.location.hash.substr(1) as typeof slugs[number];
        if (slugs.includes(hash)) {
          setCurrentTab(hash);
        }
      }
    };
    setTab();
  }, [window.location.hash]);

  const { loading, data } = useInventoryCountsQuery();

  const [addOtherInventoryItem, addOtherInventoryItemResult] =
    useAddOtherInventoryItemMutation({
      update: (cache, result) => {
        const newItem = result.data?.addOtherInventoryItem;
        if (!isSome(newItem)) {
          return;
        }
        const previousData = cache.readQuery<OtherInventoryItemsQuery>({
          query: OtherInventoryItemsDocument,
          variables: {
            first: PAGE_SIZE,
            filterParams: null,
          },
        });
        if (isSome(previousData) && isSome(previousData.organization)) {
          const newEdge = {
            node: {
              ...newItem,
              name: null,
              vantaAttributes: [],
            },
            __typename: "OtherInventoryEdge" as const,
          };
          const newEdges = [
            newEdge,
            ...previousData.organization.otherInventoryItems.edges,
          ];
          updateOtherInventoryItemsInCache(
            cache,
            previousData.organization,
            newEdges
          );
        }

        updateOtherInventoryItemsCountInCache(cache, 1);
      },
    });

  const maybeAddButton =
    currentTab === TabGroupSlugs.OTHER ? (
      <AddItemButton
        key="add-item-button"
        intent={Intent.PRIMARY}
        large={true}
        loading={addOtherInventoryItemResult.loading}
        onClick={async () => {
          setSearchString("");
          await addOtherInventoryItem();
        }}
        text={"Add an item"}
      />
    ) : undefined;

  const currentTabGroup = gatedTabGroups.find(t => t.slug === currentTab)!;
  const CurrentInventoryTab = currentTabGroup.component;

  return (
    <DefaultView
      headerProps={{
        title: "Inventory",
        rightControls: [
          <TableSearchInput
            key="search"
            value={searchString}
            onChange={e => setSearchString(e.target.value)}
            placeholder="Search by name"
            leftIcon={IconNames.SEARCH}
          />,
          <AlpacaButton key="tag-button" onClick={() => setTagDialogOpen(true)}>
            Bulk tag
          </AlpacaButton>,
        ],
      }}
    >
      <Dialog
        title="Bulk tag inventory list"
        isOpen={isTagDialogOpen}
        onClose={() => setTagDialogOpen(false)}
      >
        <div className={Classes.DIALOG_BODY}>
          <Tabs id="tags-popup-tabs" defaultSelectedTabId="tags-aws">
            <Tab
              title="AWS"
              id="tags-aws"
              panel={<DocsTerraformTag service="AWS" />}
            />
            <Tab
              title="Azure"
              id="tags-azure"
              panel={<DocsTerraformTag service="Azure" />}
            />
            <Tab title="GCP" id="tags-gcp" panel={<DocsGCPLabel />} />
            <Tab
              title="DigitalOcean"
              id="tags-do"
              panel={<DocsDigitalOceanLabel />}
            />
          </Tabs>
        </div>
      </Dialog>

      <Link to="docs/inventory-list">What belongs on an inventory list?</Link>
      <TabSelectDiv>
        <div>
          <TabSelectLabel>Resource type</TabSelectLabel>
          <HTMLSelect
            large
            onChange={event => {
              setSearchString("");
              history.push(`#${event.currentTarget.value}`);
            }}
            value={currentTab}
          >
            {gatedTabGroups.map(t => {
              const count = isSome(data) ? t.getCount(data) : undefined;
              const countString = isSome(count)
                ? count.toString()
                : loading
                ? "..."
                : "?";
              return (
                <option key={t.slug} value={t.slug}>
                  {t.name}
                  {` (${countString})`}
                </option>
              );
            })}
          </HTMLSelect>
          {maybeAddButton}
        </div>
      </TabSelectDiv>

      <CurrentInventoryTab
        errorCount={isSome(data) ? currentTabGroup.getErrorCount(data) ?? 0 : 0}
        searchString={searchString}
      />
    </DefaultView>
  );
};

gql`
  query inventoryCounts {
    organization {
      id
      gitrepos: resources(first: 0, genericResourceType: VersionControlRepo) {
        totalCount
      }

      loadbalancers: resources(first: 0, genericResourceType: LoadBalancer) {
        totalCount
      }

      autoscale: resources(
        first: 0
        genericResourceType: ManagedInstanceGroup
      ) {
        totalCount
      }

      compute: resources(first: 0, genericResourceType: ComputeInstance) {
        totalCount
      }

      containerrepository: resources(
        first: 0
        genericResourceType: ContainerRepository
      ) {
        totalCount
      }

      queue: resources(
        first: 0
        genericResourceType: Queue
        options: { errorOption: ALL }
      ) {
        totalCount
      }

      queueErrors: resources(
        first: 0
        genericResourceType: Queue
        options: { errorOption: ERROR_ONLY }
      ) {
        totalCount
      }

      bucket: resources(
        first: 0
        genericResourceType: StorageBucket
        options: { errorOption: ALL }
      ) {
        totalCount
      }

      bucketErrors: resources(
        first: 0
        genericResourceType: StorageBucket
        options: { errorOption: ERROR_ONLY }
      ) {
        totalCount
      }

      nosqldb: resources(first: 0, genericResourceType: NoSQLDatabase) {
        totalCount
      }

      relationaldb: resources(first: 0, genericResourceType: SQLDatabase) {
        totalCount
      }

      datawarehouse: resources(first: 0, genericResourceType: DataWarehouse) {
        totalCount
      }

      paasApp: resources(first: 0, genericResourceType: PaaSApp) {
        totalCount
      }

      servers: machines(
        first: 0
        activeOnly: true
        serversOnly: true
        nonCloudOnly: true
      ) {
        totalCount
      }

      osqueryemployeecomputers: machines(
        first: 0
        activeOnly: true
        workstationsOnly: true
      ) {
        totalCount
      }

      managedemployeecomputers: resources(
        first: 0
        genericResourceType: ManagedComputer
        options: { errorOption: ALL }
      ) {
        totalCount
      }

      managedemployeecomputererrors: resources(
        first: 0
        genericResourceType: ManagedComputer
        options: { errorOption: ERROR_ONLY }
      ) {
        totalCount
      }

      otherinventoryitems: otherInventoryItems(first: 0) {
        totalCount
      }
    }
  }
`;

gql`
  mutation addOtherInventoryItem {
    addOtherInventoryItem {
      id
      name
      vantaAttributes {
        key
        value
        managedExternally
      }
    }
  }
`;
