import { Button, InputGroup, Intent } from "@blueprintjs/core";
import { DateInput } from "@blueprintjs/datetime";
import { IconNames } from "@blueprintjs/icons";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import moment from "moment";
import React, { useState } from "react";
import styled from "styled-components";

import { VANTA_COLORS } from "../../../alpaca/base/colors";
import { VantaButtonDeprecated } from "../../../alpaca/base/deprecated";
import {
  BodyShortText,
  BodyText,
  HelperText,
} from "../../../alpaca/components";
import { UI_DATE_FORMAT_WITHOUT_TIME } from "../../../helpers/common";
import { UserSelector } from "../../form-controls/user-selector";

interface IUser {
  id: string;
  displayName?: Maybe<string>;
}

interface ITask {
  id: string;
  description: string;
  assignee?: Maybe<IUser>;
  dueDate?: Maybe<string>;
}

interface IProps {
  tasks: ITask[];
  assigneeRequired?: Maybe<boolean>;
  textContent: React.ReactNode;
  onCreateTask: (
    description: string,
    assigneeId?: Maybe<string>,
    dueDate?: Maybe<Date>
  ) => void;
  onDeleteTask: (taskId: string) => void;
}

export const TaskEditorPanel: React.FC<IProps> = ({
  assigneeRequired,
  tasks,
  textContent,
  onCreateTask,
  onDeleteTask,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [assignee, setAssignee] = useState<Maybe<string>>(undefined);
  const [dueDate, setDueDate] = useState<Maybe<Date>>(null);

  const taskList =
    tasks.length > 0 ? (
      <TaskListContainer>
        <HelperText>
          {tasks.length} Task{tasks.length > 1 ? "s" : ""}
        </HelperText>
        {tasks.map(task => (
          <Flex key={task.id}>
            <div>
              <BodyShortText>{task.description}</BodyShortText>
              {isSome(task.assignee) ? (
                <BodyText color={VANTA_COLORS.TEXT_DESCRIPTION}>
                  {`Assigned to ${task.assignee.displayName}`}
                </BodyText>
              ) : null}
              {isSome(task.dueDate) ? (
                <BodyText
                  color={VANTA_COLORS.TEXT_DESCRIPTION}
                >{`Task deadline: ${moment(task.dueDate).format(
                  UI_DATE_FORMAT_WITHOUT_TIME
                )}`}</BodyText>
              ) : null}
            </div>
            <Button
              minimal
              icon={IconNames.CROSS}
              intent={Intent.DANGER}
              onClick={() => onDeleteTask(task.id)}
            />
          </Flex>
        ))}
      </TaskListContainer>
    ) : null;

  const taskEditor = (
    <div>
      <FormFlex>
        <InputGroup
          autoFocus
          fill
          type="text"
          value={inputValue}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setInputValue(e.target.value)
          }
          placeholder="Task description..."
        />
      </FormFlex>
      <FormFlex>
        <UserSelector
          selected={assignee}
          prompt="Assign owner"
          onSelect={choices => {
            if (!isSome(choices) || choices?.length < 1) {
              setAssignee(undefined);
            } else {
              setAssignee(choices[0]);
            }
          }}
        />
        <DateInput
          placeholder="Select due date"
          value={dueDate}
          onChange={date => setDueDate(date)}
          formatDate={date => moment(date).format(UI_DATE_FORMAT_WITHOUT_TIME)}
          parseDate={str => new Date(str)}
          maxDate={moment().add(5, "years").toDate()}
          minDate={new Date()}
        />
      </FormFlex>
      <StyledVantaButton
        intent={Intent.PRIMARY}
        disabled={
          !isSome(dueDate) ||
          inputValue.trim() === "" ||
          (Boolean(assigneeRequired) && !isSome(assignee))
        }
        onClick={() => {
          onCreateTask(inputValue, assignee, dueDate);
          setInputValue("");
          setAssignee(undefined);
          setDueDate(null);
        }}
      >
        Create task
      </StyledVantaButton>
    </div>
  );
  return (
    <BoxedContainer>
      {textContent}
      {taskEditor}
      {taskList}
    </BoxedContainer>
  );
};

const styles = {
  BOX_PADDING: 24,
  TASK_LIST_TOP_MARGIN: 18,
  BUTTON_SIDE_PADDING: 16,
  BUTTON_VERTICAL_PADDING: 8,
};

const BoxedContainer = styled.div`
  border: 1px solid ${VANTA_COLORS.BORDER_LIGHT};
  border-radius: 8px;
  padding: ${styles.BOX_PADDING}px;
`;

const StyledVantaButton = styled(VantaButtonDeprecated)`
  padding: ${styles.BUTTON_VERTICAL_PADDING}px ${styles.BUTTON_SIDE_PADDING}px;
`;

const TaskListContainer = styled.div`
  margin-top: 18px;
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

const FormFlex = styled.div`
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
