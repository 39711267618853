import AuthenticationSVG from "../../../static/images/icons/noun-project/authentication.svg";
import BankAccountSVG from "../../../static/images/icons/noun-project/bank_account.svg";
import ConfidentialSVG from "../../../static/images/icons/noun-project/confidential.svg";
import ContainerShipSVG from "../../../static/images/icons/noun-project/container_ship.svg";
import DatabaseSVG from "../../../static/images/icons/noun-project/database.svg";
import EncryptionSVG from "../../../static/images/icons/noun-project/encryption.svg";
import EntranceSVG from "../../../static/images/icons/noun-project/entrance.svg";
import IdeaSVG from "../../../static/images/icons/noun-project/idea.svg";
import InformationSVG from "../../../static/images/icons/noun-project/information.svg";
import PasswordLockSVG from "../../../static/images/icons/noun-project/password_lock.svg";
import RouterSVG from "../../../static/images/icons/noun-project/router.svg";
import ServerRackSVG from "../../../static/images/icons/noun-project/server_rack.svg";
import SingleServerSVG from "../../../static/images/icons/noun-project/server_single.svg";
import SpreadsheetSVG from "../../../static/images/icons/noun-project/spreadsheet.svg";
import StorageSVG from "../../../static/images/icons/noun-project/storage.svg";
import SuccessDatabaseSVG from "../../../static/images/icons/noun-project/success_database.svg";
import SystemSVG from "../../../static/images/icons/noun-project/system.svg";
import TwentyFourHoursSVG from "../../../static/images/icons/noun-project/twenty_four_hours.svg";

export const RISK_ICONS = {
  authentication: AuthenticationSVG,
  bankAccount: BankAccountSVG,
  confidential: ConfidentialSVG,
  containerShip: ContainerShipSVG,
  database: DatabaseSVG,
  encryption: EncryptionSVG,
  entrance: EntranceSVG,
  idea: IdeaSVG,
  information: InformationSVG,
  passwordLock: PasswordLockSVG,
  router: RouterSVG,
  serverRack: ServerRackSVG,
  singleServer: SingleServerSVG,
  spreadsheet: SpreadsheetSVG,
  storage: StorageSVG,
  successDatabase: SuccessDatabaseSVG,
  system: SystemSVG,
  twentyFourHours: TwentyFourHoursSVG,
};
