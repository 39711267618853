import "./vanta-report-test-list.scss";

import React from "react";

import type { GetDataForReportQuery } from "../../../../../gen/components";
import type { IDomainForReport } from "../../../helpers/helpers";
import { VantaReportEvidenceListItem } from "../evidence-list/vanta-report-evidence-list-item";
import { VantaReportTestListItem } from "../test-list/vanta-report-test-list-item";

interface IProps {
  control: NonNullable<
    GetDataForReportQuery["complianceStandard"]
  >["principles"][number]["requirements"][number]["controls"][number];
  domain: IDomainForReport;
  controlReportType: ControlReportType;
}

export enum ControlReportType {
  Test,
  Evidence,
}

export const VantaReportList: React.FunctionComponent<IProps> = ({
  control,
  domain,
  controlReportType,
}) => {
  const listElements =
    controlReportType === ControlReportType.Test
      ? control.tests.map(test => (
          <VantaReportTestListItem
            key={`${test.testId}`}
            test={test}
            domain={domain}
          />
        ))
      : control.evidence.map(evidenceId => (
          <VantaReportEvidenceListItem
            key={`${evidenceId}`}
            evidenceId={evidenceId}
            domain={domain}
          />
        ));
  return <div className="vrp-test-list-container">{listElements}</div>;
};
