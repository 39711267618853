import { Button, Callout, Intent, OL } from "@blueprintjs/core";
import React from "react";
import { isUUID } from "validator";

import { DefaultLink } from "../../../../helpers/links";
import { SERVICE_DETAILS } from "../../../credentials/service-groups";
import type { IInputValues } from "../simple-credential-form-dialog-body";
import { SimpleCredentialFormDialogBody } from "../simple-credential-form-dialog-body";

export const SubmitCredentials: React.FC<{
  onSubmit: () => void;
  setCredentials: (creds: { privateKey: string; publicKey: string }) => void;
}> = props => {
  const saveButton: React.FC<{
    canSubmit: boolean;
    inputValues: IInputValues;
  }> = ({ canSubmit, inputValues }) => (
    <Button
      intent={Intent.PRIMARY}
      disabled={!canSubmit}
      onClick={async () => {
        props.setCredentials({
          privateKey: inputValues.privateKey!,
          publicKey: inputValues.publicKey!,
        });
        props.onSubmit();
      }}
    >
      Validate and store
    </Button>
  );

  return (
    <>
      <Callout>
        <OL>
          <li>
            Navigate to your{" "}
            <DefaultLink href={"https://cloud.mongodb.com"}>
              MongoDB Atlas account
            </DefaultLink>{" "}
            and select the organization that you would like Vanta to monitor.
            You must be an{" "}
            <DefaultLink
              href={
                "https://docs.atlas.mongodb.com/reference/user-roles#mongodb-authrole-Organization-Owner"
              }
            >
              organization owner
            </DefaultLink>{" "}
            to generate a new API key
          </li>
          <li>
            Select <strong>Access Manager {"->"} Organization Access</strong>
          </li>
          <li>
            Click <strong>Create API key</strong> and create a new key with
            "Organization Read Only" permissions
          </li>
          <li>Copy the generated values below</li>
        </OL>
      </Callout>
      <SimpleCredentialFormDialogBody
        labels={[
          {
            name: "publicKey",
            displayName: "Public Key",
            placeholder: "X".repeat(8),
            helperText: (
              <span>The public key identifies your organization</span>
            ),
            valueIsValid: v => v.length === 8,
          },
          {
            name: "privateKey",
            displayName: "Private Key",
            type: "password",
            placeholder: "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx",
            helperText: (
              <span>
                The private key allows Vanta to authorize as a read-only user
              </span>
            ),
            valueIsValid: isUUID,
          },
        ]}
        service={SERVICE_DETAILS.mongoatlas}
        OverrideSaveButton={saveButton}
        showTitle={false}
      />
    </>
  );
};
