import { ApolloClient, ApolloLink, InMemoryCache } from "@apollo/client";
import { relayStylePagination } from "@apollo/client/utilities";
import DebounceLink from "apollo-link-debounce";
import ApolloUploadClient from "apollo-upload-client";
import { SCHEMA_VERSION } from "common/base/types/gen";
import { isSome } from "common/base/types/maybe";

import { Config } from "../config";
import introspectionResult from "../gen/fragment-introspection";

// https://www.npmjs.com/package/apollo-link-debounce
const DEFAULT_DEBOUNCE_TIMEOUT = 300;

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache({
    possibleTypes: introspectionResult.possibleTypes,
    typePolicies: {
      domain: {
        fields: {
          resources: relayStylePagination([
            "specificResourceType",
            "genericResourceType",
            "excludeWhitelistedTestIds",
            "filterParams",
            "sortParams",
            "options",
          ]),
          vulnerabilityTargets: relayStylePagination([
            "specificResourceType",
            "genericResourceType",
            "excludeWhitelistedTestIds",
            "filterParams",
            "sortParams",
            "options",
          ]),
          machines: relayStylePagination([
            "activeOnly",
            "serversOnly",
            "workstationsOnly",
            "nonCloudOnly",
            "ids",
            "sortParams",
            "filterParams",
          ]),
          packageVulnerabilities: relayStylePagination([
            "vulnerablePackageIds",
            "sortParams",
            "filterParams",
          ]),
        },
      },
    },
  }),
  connectToDevTools: Config.isDev,
  link: ApolloLink.from([
    // Are we actually using it? It's no longer maintained, and I'd like to get rid of this or
    // replace it with something else.
    new DebounceLink(DEFAULT_DEBOUNCE_TIMEOUT) as unknown as ApolloLink,
    ApolloUploadClient.createUploadLink({
      credentials: "same-origin",
      uri: "/graphql",
      headers: { "graphql-schema-version": SCHEMA_VERSION },
      fetch: customFetch,
    }) as unknown as ApolloLink,
  ]),
  // including this version parameter should automatically set
  // a version header, but it's not currently working with
  // ApolloUploadClient. If we move to HttpLink, we can get rid of
  // our custom header above and use the apollographql-client-version
  // header instead.
  // See https://www.apollographql.com/docs/platform/client-awareness/
  version: SCHEMA_VERSION,
});

// Based on code from https://github.com/jaydenseric/apollo-upload-client/issues/88#issuecomment-468318261
async function customFetch(
  uri: string,
  options: RequestInit & { onProgress: () => void }
) {
  if (isSome(options.onProgress)) {
    return uploadFetch(uri, options);
  }
  return fetch(uri, options);
}

async function uploadFetch(
  uri: string,
  options: RequestInit & { onProgress: () => void }
) {
  return new Promise<Response>((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
      const opts: any = {
        status: xhr.status,
        statusText: xhr.statusText,
        headers: parseHeaders(xhr.getAllResponseHeaders() ?? ""),
      };
      opts.url =
        "responseURL" in xhr
          ? xhr.responseURL
          : opts.headers.get("X-Request-URL");
      const body = "response" in xhr ? xhr.response : (xhr as any).responseText;
      resolve(new Response(body, opts));
    };
    xhr.onerror = () => {
      reject(new TypeError("Network request failed"));
    };
    xhr.ontimeout = () => {
      reject(new TypeError("Network request failed"));
    };
    xhr.open(options.method!, uri, true);

    Object.entries(options.headers!).forEach(([key, value]) => {
      xhr.setRequestHeader(key, value);
    });

    if (isSome(xhr.upload)) {
      xhr.upload.onprogress = options.onProgress;
    }

    xhr.send(options.body as XMLHttpRequestBodyInit);
  });
}

function parseHeaders(rawHeaders: string) {
  const headers = new Headers();
  // Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
  // https://tools.ietf.org/html/rfc7230#section-3.2
  const preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, " ");
  preProcessedHeaders.split(/\r?\n/).forEach((line: string) => {
    const parts = line.split(":");
    const key = parts.shift()?.trim();
    if (isSome(key) && key.length > 0) {
      const value = parts.join(":").trim();
      headers.append(key, value);
    }
  });
  return headers;
}
