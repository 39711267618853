import type { Maybe } from "common/base/types/maybe";
import React from "react";
import styled from "styled-components";
import { BASE_PALETTE } from "../../base/colors";
import { TextLinkLike } from "../typography/typography";

export type IPreRowProps = {
  text: string;
  className?: Maybe<string>;
};

const StyledPreText = styled.pre`
  font-family: Monaco;
  font-size: 14px;
  flex-grow: 1;
  line-height: 16px;
  margin: 8px;
`;

const StyledPreCopyButton = styled(TextLinkLike)`
  margin: 8px;
  font-size: 14px;
  line-height: 16px;
`;

const StyledPreRow = styled.div`
  background: ${BASE_PALETTE.VAPE};
  border: 1px solid ${BASE_PALETTE.FOG};
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex: none;
  flex-grow: 0;
  height: 32px;
  box-sizing: border-box;
  width: 100%;
`;

export const PreRow: React.FC<IPreRowProps> = ({
  text,
  className,
  ...props
}) => (
  <StyledPreRow className={className ?? undefined}>
    <StyledPreText>{text}</StyledPreText>
    <StyledPreCopyButton
      onClick={() => void navigator.clipboard.writeText(text)}
    >
      Copy
    </StyledPreCopyButton>
  </StyledPreRow>
);
