import { Button, Intent } from "@blueprintjs/core";
import gql from "graphql-tag";
import React, { useState } from "react";

import { LogError } from "../../../errors";
import type { EmailKey, GetBufferedEmailsQuery } from "../../../gen/components";
import {
  GetBufferedEmailsDocument,
  useDiscardBufferedEmailsMutation,
  useSendBufferedEmailsMutation,
} from "../../../gen/components";
import { AppToaster } from "../../../helpers/toaster";
import { FlexContainer } from "./email-kill-switch-panel";

interface IProps {
  emailKey: EmailKey;
  emails: Array<NonNullable<GetBufferedEmailsQuery["bufferedEmails"]>[number]>;
}

export const KilledEmailTypeDisplay: React.FC<IProps> = ({
  emailKey,
  emails,
}) => {
  const [sendInFlight, setSendInFlight] = useState(false);
  const [sendEmails] = useSendBufferedEmailsMutation({
    refetchQueries: [{ query: GetBufferedEmailsDocument }],
    onCompleted() {
      AppToaster.show({
        message: "Emails Sent",
        intent: Intent.SUCCESS,
      });
      setSendInFlight(false);
    },
  });
  const [discardEmails, discardResult] = useDiscardBufferedEmailsMutation({
    refetchQueries: [{ query: GetBufferedEmailsDocument }],
    onCompleted() {
      AppToaster.show({
        message: "Emails Discarded",
        intent: Intent.SUCCESS,
      });
    },
  });
  const distinctDomainIdSet = new Set(emails.map(email => email.domainId));

  return (
    <FlexContainer key={emailKey}>
      <span>
        {emailKey}: {emails.length} ({distinctDomainIdSet.size} domains)
      </span>
      <div>
        <Button
          loading={discardResult.called}
          disabled={sendInFlight}
          onClick={async () =>
            discardEmails({
              variables: { emailKey: emailKey as EmailKey },
            }).catch(LogError)
          }
        >
          Discard
        </Button>
        <Button
          loading={sendInFlight}
          disabled={discardResult.called}
          onClick={() => {
            setSendInFlight(true);
            sendEmails({
              variables: { emailKey: emailKey as EmailKey },
            }).catch(LogError);
          }}
        >
          Send
        </Button>
      </div>
    </FlexContainer>
  );
};

gql`
  mutation sendBufferedEmails($emailKey: emailKey!) {
    sendBufferedEmails(emailKey: $emailKey)
  }
`;

gql`
  mutation discardBufferedEmails($emailKey: emailKey!) {
    discardBufferedEmails(emailKey: $emailKey)
  }
`;
