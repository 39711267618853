import styled from "styled-components";

import { BASE_PALETTE } from "../../../alpaca/base/colors";
import { Button } from "../../../alpaca/components";
import type { ManagePoliciesV2Query } from "../../../gen/components";
import type { IFormControlValue } from "../../forms/interfaces";

export const POLICY_PAGE_STYLE_CONSTANTS = {
  BUTTON_HEIGHT: 24,
  BUTTON_WIDTH: 72,
  ACTION_BUTTON_WIDTH: 32,
};

export const ActionButton = styled(Button).attrs({ small: true })`
  width: ${POLICY_PAGE_STYLE_CONSTANTS.ACTION_BUTTON_WIDTH}px;
  &.bp3-active {
    background-color: ${BASE_PALETTE.SMOKE} !important;
    border: 1px solid ${BASE_PALETTE.SMOKE} !important;
    .bp3-icon {
      color: ${BASE_PALETTE.SNOW} !important;
    }
  }
`;

export const POLICY_PAGE_TAB_IDS = {
  POLICIES: "policies",
  SLAS: "slas",
};

export type Policy = NonNullable<
  ManagePoliciesV2Query["organization"]
>["policies"][number];

interface AnswersMap {
  [k: string]: IFormControlValue;
}

export interface PolicyInfoMap {
  [k: string]: AnswersMap;
}
