import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import { BASE_PALETTE, VANTA_COLORS } from "../../../alpaca/base/colors";
import type { UserContextQuery } from "../../../gen/components";
import { FeatureGate } from "../../../helpers/feature-gating/feature-gate";
import { UserContext } from "../../pages/user-context";
import { Dimensions } from "../constants";
import type { ILink } from "../links";

interface IProps {
  links: ILink[];
}

const HOME_LINK_TEXT = (user: UserContextQuery["user"]) => {
  if (!isSome(user)) {
    return "Tasks";
  }
  if (user.isAuditorAssumingUser) {
    return "SOC 2 Tests";
  }
  if (isSome(user.auditorInfo)) {
    return "Dashboard";
  }
  return "Tasks";
};

export const LinkList: React.FC<IProps> = ({ links }) => {
  const { user } = useContext(UserContext);
  return (
    <UL>
      {links.map(link => {
        const isHome =
          window.location.pathname === "/" &&
          link.text === HOME_LINK_TEXT(user);
        const linkComponent = (
          <LI isActive={isHome} key={`sub-menu-item-${link.text}`}>
            <StyledLink to={link.path}>{link.text}</StyledLink>
          </LI>
        );
        const maybeFeatureGatedLink = isSome(link.feature) ? (
          <FeatureGate
            key={`sub-menu-item-${link.text}`}
            feature={link.feature}
          >
            {linkComponent}
          </FeatureGate>
        ) : (
          linkComponent
        );
        return maybeFeatureGatedLink;
      })}
    </UL>
  );
};

const StyledLink = styled(Link)`
  display: block;
  padding: 6px ${Dimensions.NAVBAR_SUBMENU_INDENT}px;

  ${({ to }) => {
    if (window.location.pathname.startsWith(String(to))) {
      return css`
        && {
          background-color: ${VANTA_COLORS.VANTA_PURPLE};
          color: ${BASE_PALETTE.SNOW};
        }
      `;
    } else {
      return "";
    }
  }}
`;

const LI = styled.li<{ isActive?: Maybe<boolean> }>`
  ${({ isActive }) => {
    if (isActive) {
      return css`
        && a {
          background-color: ${VANTA_COLORS.VANTA_PURPLE};
          color: ${BASE_PALETTE.SNOW};
        }
      `;
    } else {
      return "";
    }
  }}
`;

const UL = styled.ul`
  padding: 4px 0px;
  list-style: none;
  text-transform: uppercase;
  a {
    text-decoration: none;
    color: ${VANTA_COLORS.TEXT_MUTED};
    transition: background-color 0.25s, color 0.25s;
    &:hover {
      background-color: ${VANTA_COLORS.BACKGROUND_PURPLE};
      color: ${VANTA_COLORS.VANTA_PURPLE};
    }
  }
  li {
    line-height: ${Dimensions.NAVBAR_SUBMENU_LINE_HEIGHT}px;
    white-space: nowrap;
  }
`;
