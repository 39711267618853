import type { Intent } from "@blueprintjs/core";
import { FormGroup } from "@blueprintjs/core";
import { isSome } from "common/base/types/maybe";
import React from "react";

import { MarkdownRenderer } from "../pages/components/markdown-renderer";
import type { IQuestion } from "./interfaces";

interface IProps {
  intent: Intent;
  question: IQuestion;
}
export const SchemaFormGroup: React.FunctionComponent<IProps> = ({
  question,
  intent,
  children,
}) => (
  <FormGroup
    className="business-info"
    label={question.type !== "CheckboxInput" ? question.label : undefined}
    helperText={
      isSome(question.helper) ? MarkdownRenderer(question.helper) : undefined
    }
    labelFor={question.id}
    intent={intent}
  >
    {children}
  </FormGroup>
);
