import type { InputGroupProps2 } from "@blueprintjs/core";
import { FormGroup, InputGroup, Intent } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { applyIfSome, getTransformedOrElse } from "common/base/types/maybe";
import React from "react";
import styled from "styled-components";

import { BASE_PALETTE } from "../../base/colors";
import { BASE_TYPOGRAPHY } from "../../base/typography";
import { Icon } from "../icon/icon";
import type { IconName } from "../icon/iconNames";

export interface IInputProps
  extends Omit<InputGroupProps2, "intent" | "leftIcon"> {
  error?: Maybe<boolean>;
  icon?: Maybe<IconName>;
  label?: Maybe<React.ReactNode>;
}

export const Input: React.FC<IInputProps> = ({
  error,
  icon,
  label,
  ...props
}) => {
  const input = (
    <StyledInputGroup
      {...props}
      intent={Boolean(error) ? Intent.DANGER : undefined}
      leftIcon={applyIfSome(icon, iconName => (
        <Icon icon={iconName} />
      ))}
    />
  );
  const inputMaybeWithLabel = getTransformedOrElse(
    label,
    l => (
      <StyledFormGroup label={l} disabled={props.disabled}>
        {input}
      </StyledFormGroup>
    ),
    input
  );
  return inputMaybeWithLabel;
};

const StyledInputGroup = styled(InputGroup)`
  &&.bp3-input-group {
    .bp3-input {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 6px 12px;
      height: 32px;

      border: 1px solid ${BASE_PALETTE.FOG};
      box-shadow: none;
      box-sizing: border-box;
      border-radius: 4px;

      font-size: 14px;
      line-height: 20px;
      color: ${BASE_PALETTE.INK};

      &::-webkit-input-placeholder {
        color: ${BASE_PALETTE.SLATE};
      }

      &:focus {
        box-shadow: 0px 0px 0px 4px rgba(135, 92, 255, 0.12);
      }

      &:disabled {
        background: ${BASE_PALETTE.SNOW};
        &::-webkit-input-placeholder {
          color: ${BASE_PALETTE.SMOKE};
        }
      }

      &:not(:first-child) {
        padding-left: 32px;
      }
    }

    .bp3-icon {
      margin-left: 12px;
      margin-top: 8px;
      margin-bottom: 8px;

      color: ${BASE_PALETTE.SLATE};
    }
  }

  &.bp3-input-group.bp3-large {
    .bp3-input {
      height: 40px;

      font-size: 14px;
      line-height: 20px;
    }

    .bp3-icon {
      margin: 12px;
    }
  }

  &.bp3-input-group.bp3-intent-danger .bp3-input {
    border: 1px solid ${BASE_PALETTE.TOMATO};
    box-shadow: 0px 0px 0px 4px rgba(237, 72, 72, 0.12);
  }
`;

const StyledFormGroup = styled(FormGroup)`
  width: auto;

  &.bp3-form-group label {
    margin-bottom: 8px;

    font-size: 14px;
    line-height: 16px;
    font-weight: ${BASE_TYPOGRAPHY.FONT_WEIGHTS.SLIGHTLY_BOLD};
    color: ${BASE_PALETTE.INK};
  }

  &.bp3-form-group.bp3-disabled label {
    /* !important needed to override blueprint's !important */
    color: ${BASE_PALETTE.SMOKE} !important;
  }
`;
