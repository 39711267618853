import type { Intent as IntentType } from "@blueprintjs/core";
import { Intent } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { getTransformedOrElse } from "common/base/types/maybe";
import React from "react";
import styled from "styled-components";

import { BASE_PALETTE } from "../../base/colors";

export type TagIntent = IntentType;

const COLOR_FOR_DOT: { [k in TagIntent]: string } = {
  [Intent.PRIMARY]: BASE_PALETTE.GRAPE,
  [Intent.DANGER]: BASE_PALETTE.TOMATO,
  [Intent.SUCCESS]: BASE_PALETTE.KALE,
  [Intent.WARNING]: BASE_PALETTE.CITRUS,
  [Intent.NONE]: BASE_PALETTE.CHARCOAL,
};

interface IProps {
  /**
   * If null or undefined, does not show a circle.
   */
  intent?: Maybe<TagIntent>;
  text: string;
}

export const Tag: React.FC<IProps> = ({ intent, text }) => (
  <StyledSpan>
    {getTransformedOrElse(
      intent,
      i => (
        <StyledDotSpan intent={i} />
      ),
      null
    )}
    {text}
  </StyledSpan>
);

const StyledDotSpan = styled.span<{ intent: TagIntent }>`
  height: 8px;
  width: 8px;
  border-radius: 100%;
  background-color: ${({ intent }) => COLOR_FOR_DOT[intent]};
  margin-right: 8px;
`;

const StyledSpan = styled.span`
  display: flex;
  align-items: center;
  padding: 4px 8px;
  background: ${BASE_PALETTE.SNOW};
  border: 1px solid ${BASE_PALETTE.FOG};
  box-sizing: border-box;
  border-radius: 52px;
  font-size: 14px;
  line-height: 16px;
  white-space: nowrap;
  height: 24px;
  flex-shrink: 0;

  color: ${BASE_PALETTE.CHARCOAL};
`;
