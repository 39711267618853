import type { Intent } from "@blueprintjs/core";
import { Spinner } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { isSome, nothing } from "common/base/types/maybe";
import React from "react";

interface IProps {
  text?: Maybe<string>;
  intent?: Maybe<Intent>;
}

export const FullPageSpinner = ({ text, intent }: IProps) => {
  const maybeText = isSome(text) ? <p>{text}</p> : nothing;
  return (
    <div className="spinner-box">
      <div>
        <Spinner size={Spinner.SIZE_LARGE} intent={intent ?? undefined} />
        {maybeText}
      </div>
    </div>
  );
};
