import "./vaq.scss";

import { Spinner } from "@blueprintjs/core";
import { VaqStatus } from "common/base/types/gen";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import React from "react";
import type { match } from "react-router";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";

import { useGetVaqAnswerStateQuery } from "../../gen/components";
import { TestVAQBanner } from "./test-vaq-banner";
import { VAQPage } from "./vaq";

interface IProps {
  match: match<{ uuid: string }>;
}

gql`
  query getVAQAnswerState($uuid: String!) {
    vaqAnswerStatus(uuid: $uuid)
  }
`;

export const VAQGateway: React.FC<IProps> = (props: IProps) => {
  const uuid = props.match!.params.uuid;
  const { loading, data } = useGetVaqAnswerStateQuery({
    variables: { uuid: uuid ?? "NO_ID" },
    skip: !isSome(uuid),
  });

  if (loading) {
    return <Spinner />;
  }
  if (!isSome(uuid) || !isSome(data?.vaqAnswerStatus)) {
    return <Redirect to="/" />;
  }

  const status = data!.vaqAnswerStatus;
  if (
    status === VaqStatus.submitted ||
    status === VaqStatus.submittedOnDryRun
  ) {
    if (
      window.sessionStorage.getItem(
        `vaq-${uuid.substring(0, 6)}-submission`
      ) === "submitted"
    ) {
      const companyName = window.sessionStorage.getItem(`vaq-requester`);

      return (
        <React.Fragment>
          {status === VaqStatus.submittedOnDryRun ? <TestVAQBanner /> : null}
          <div className="vaq-centered">
            <h3>
              Thanks! {companyName ?? "The company"} has received your
              responses.
            </h3>
          </div>
          <div className="vaq-fixed-footer">
            <strong>
              Powered by <Link to="/">Vanta</Link>
            </strong>
          </div>
        </React.Fragment>
      );
    } else {
      return <Redirect to="/" />;
    }
  }

  return <VAQPage uuid={uuid} />;
};
