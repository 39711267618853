import "./employee-onboarding.scss";

import { Button, Collapse, Intent } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { assertSome, isSome } from "common/base/types/maybe";
import type { VIDEO_NAMES_TYPE } from "common/constants/onboarding";
import {
  TRAINING_CATEGORIES,
  SECURITY_AWARENESS_TRAINING_INFO,
} from "common/constants/onboarding";
import { mustCompleteSat } from "common/employee-tasks/employee-tasks-utils";
import { isAfter } from "date-fns";
import React from "react";

import { GRID_SPACING } from "../../../../alpaca/base/grid";
import {
  AnchorButton,
  Banner,
  H1,
  Icon,
  IconNames,
  MetaText,
  SpacedList,
} from "../../../../alpaca/components";
import { LogError, LogErrorMessage } from "../../../../errors";
import type {
  AcceptSecurityPoliciesMutationFn,
  GetUserInfoForOnboardingQuery,
  TrainingPreference,
  TrainingAttestMutationFn,
  VideoCompletionMutationFn,
} from "../../../../gen/components";
import {
  Feature,
  GetMostRecentSecurityTrainingsDocument,
  GetUserInfoForOnboardingDocument,
  PermissionLevel,
  SecurityTrainingCategoryId,
  useAcceptSecurityPoliciesMutation,
  useGetUserInfoForOnboardingQuery,
  useTrainingAttestMutation,
  useVideoCompletionMutation,
} from "../../../../gen/components";
import { FeatureContext } from "../../../../helpers/feature-gating/context";
import { FeatureGate } from "../../../../helpers/feature-gating/feature-gate";
import { DefaultLink } from "../../../../helpers/links";
import type { IQuestionSchema } from "../../../forms/interfaces";
import { QuestionSchemaForm } from "../../../forms/question-schema-form";
import { FullPageSpinner } from "../../../helpers/FullPageSpinner";
import { Task } from "../../../tasks/task";
import { TaskList } from "../../../tasks/tasklist";
import { DownloadInfo } from "../../download";
import {
  GdprGeneralSecurityTrainingInfo,
  GdprSecurityTrainingInfo,
  GeneralSecurityTrainingInfo,
  HipaaGeneralSecurityTrainingInfo,
  HipaaSecurityTrainingInfo,
  PciSecurityTrainingInfo,
  SecurityTrainingInfo,
} from "../../info/securityTrainingSchema";
import { Lockout } from "../../lockout";
import { getCurrentTrainingTaskDetails } from "../../people/utils";
import { getTrainingPreferencesMap } from "./get-training-preferences";
import { OnboardingCheckableTaskV2 } from "./onboarding-checkable-task-v-2";

type User = NonNullable<GetUserInfoForOnboardingQuery["user"]>;

interface IProps {
  user: User;
  acceptSecurityPolicies: AcceptSecurityPoliciesMutationFn;
  setUserSecurityTrainingAttestation: TrainingAttestMutationFn;
  setUserSecurityTrainingVideoCompletion: VideoCompletionMutationFn;
}

interface IState {
  acceptedPoliciesListIsOpen: boolean;
}

class ManageOnboarding extends React.Component<IProps, IState> {
  private taskList: Maybe<TaskList> = null;

  public constructor(props: IProps) {
    super(props);
    this.renderSecurityPolicyAcceptance =
      this.renderSecurityPolicyAcceptance.bind(this);
    this.submitSecurityPolicyAcceptance =
      this.submitSecurityPolicyAcceptance.bind(this);
    this.showAcceptedPolicies = this.showAcceptedPolicies.bind(this);
    this.renderAcceptedPolicies = this.renderAcceptedPolicies.bind(this);
    this.renderNotAcceptedPolicies = this.renderNotAcceptedPolicies.bind(this);
    this.makePolicyList = this.makePolicyList.bind(this);
    this.state = { acceptedPoliciesListIsOpen: false };
  }

  public componentDidUpdate(prevProps: IProps) {
    if (this.taskList) {
      this.taskList.selectFirstIncompleteTask();
    }
  }

  public render() {
    const user = this.props.user;

    if (user.domain.lockedAndPastGracePeriod) {
      return (
        <Lockout
          domainName={user.domain.displayName}
          canTakeAction={user.permissionLevel !== PermissionLevel.Onboarding}
        />
      );
    }
    const userDisplayName = isSome(user.givenName)
      ? user.givenName
      : user.displayName;
    const admin = user.domain.onboardingAdmin;
    const maybeNecessaryPolicyTypes = user.necessaryPolicies ?? [];
    const necessaryPolicies =
      maybeNecessaryPolicyTypes.length === 0
        ? user.domain.policies
        : user.domain.policies.filter(policy =>
            maybeNecessaryPolicyTypes.includes(policy.policyType)
          );
    const notAcceptedPolicies = necessaryPolicies.filter(
      p =>
        !user.securityPolicyAcceptances.find(
          acceptance => acceptance.policyId === p.id
        )
    );
    const acceptedPolicies = necessaryPolicies.filter(p =>
      user.securityPolicyAcceptances.find(
        acceptance => acceptance.policyId === p.id
      )
    );

    const noExternalSATLinked = user.domain.externalSATIds.length === 0;
    const inRecurringSATBeta = user.domain.betaFeatures.includes(
      Feature.BetaRecurringSAT
    );
    const satPreferencesByCategory = getTrainingPreferencesMap(
      user.domain.productDescriptionInfo
    );
    const securityTrainingTasks = noExternalSATLinked
      ? TRAINING_CATEGORIES.map(category => {
          const pref = satPreferencesByCategory[category.id];
          if (
            !mustCompleteSat(user.securityRequirements, category.id) ||
            !isSome(pref)
          ) {
            return null;
          }
          const completion =
            category.id === SecurityTrainingCategoryId.general
              ? user.mostRecentSecurityTraining
              : category.id === SecurityTrainingCategoryId.hipaa
              ? user.mostRecentHipaaSecurityTraining
              : category.id === SecurityTrainingCategoryId.pci
              ? user.mostRecentPciSecurityTraining
              : category.id === SecurityTrainingCategoryId.gdpr
              ? user.mostRecentGdprSecurityTraining
              : null;
          const task = getSecurityTrainingTaskVM(
            pref,
            category.id,
            completion,
            inRecurringSATBeta
          );
          const maybeRecurringLabel = (
            <FeatureGate features={[Feature.BetaRecurringSAT]}>
              <SpacedList marginOverride={GRID_SPACING}>
                <Icon icon={IconNames.RENEW} iconSize={12} />
                <MetaText>Recurs annually</MetaText>
              </SpacedList>
            </FeatureGate>
          );
          return (
            <Task
              completed={task.completed}
              key={`security-training-${task.trainingId}`}
              title={task.title}
              titleSecondary={maybeRecurringLabel}
            >
              <div>
                <FeatureGate features={[Feature.BetaRecurringSAT]}>
                  <p>
                    Your organization requires completing security awareness
                    training on a regular basis.
                  </p>
                </FeatureGate>
                {isSome(task.trainingUrl) && (
                  <>
                    <p>
                      Please complete the security training by clicking on the
                      link below.
                    </p>
                    <p>
                      <AnchorButton
                        href={task.trainingUrl}
                        intent={Intent.PRIMARY}
                        target="_blank"
                        text="Go to your training"
                      />
                    </p>
                  </>
                )}
                {task.instructions.split("\n").map((line, idx) => (
                  <p key={`sat-instructions-${idx}`}>{line}</p>
                ))}
              </div>
              <QuestionSchemaForm
                key={`security-training-form-info-${task.trainingId}`}
                priorInfo={
                  task.priorScreenshot
                    ? { screenshot: task.priorScreenshot }
                    : null
                }
                onSubmit={(_, { screenshot }) => {
                  switch (task.type) {
                    case "custom":
                      if (isSome(screenshot)) {
                        this.props
                          .setUserSecurityTrainingAttestation({
                            variables: {
                              screenshot,
                              trainingId: task.trainingId,
                            },
                          })
                          .catch(LogError);
                      }
                      break;
                    case "video":
                      assertSome(task.videoName);
                      this.props
                        .setUserSecurityTrainingVideoCompletion({
                          variables: { videosCompleted: task.videoName },
                        })
                        .catch(LogError);
                      break;
                    default:
                      throw new Error(`type not supported: ${task.type}`);
                  }
                }}
                questionSchema={task.questionSchema}
                requireQuestionCompletion={task.type === "video"}
              />
            </Task>
          );
        })
      : null;

    const securityPoliciesTask = user.securityRequirements
      .mustAcceptPolicies ? (
      <Task
        completed={notAcceptedPolicies.length === 0}
        key="security-policies"
        title="Accept security policies"
      >
        {this.renderSecurityPolicyAcceptance(
          notAcceptedPolicies,
          acceptedPolicies
        )}
      </Task>
    ) : null;

    const downloadAppTask = user.securityRequirements
      .mustInstallLaptopMonitoring ? (
      <Task
        completed={user.hasOSQueryEndpoint}
        key="download-vanta"
        title="Download and Install the Vanta Agent"
      >
        <DownloadInfo />
      </Task>
    ) : null;

    // Employee tasks from the rolecompletionrecord
    const roleTasks = (
      user.roleCompletionRecord?.employeeTaskCompletions ?? []
    ).map(tc => (
      <Task
        completed={isSome(tc.completionDate)}
        key={tc.id}
        title={tc.task.title}
      >
        <OnboardingCheckableTaskV2 taskCompletionRecord={tc} userId={user.id} />
      </Task>
    ));

    return (
      <div className="light-background">
        <div className={`content-flexed`}>
          <H1>Security onboarding for {user.domain.displayName}</H1>
          <br />
          {isSome(user.onboardingCompletionDate) ? (
            <Banner
              icon={IconNames.CHECKMARK}
              intent={Intent.SUCCESS}
              title="Onboarding is complete"
              description={`You're all set! Thanks for helping to keep ${user.domain.displayName} secure.`}
            />
          ) : null}
          <br />
          <p>
            {userDisplayName}, help {user.domain.displayName} maintain security
            and compliance by completing these tasks.
          </p>
          <TaskList
            ref={taskList => (this.taskList = taskList)}
            isSequential={false}
          >
            {securityPoliciesTask}
            {downloadAppTask}
            {securityTrainingTasks}
            {roleTasks}
          </TaskList>
          <p style={{ marginTop: 32 }}>
            If you have questions, contact {admin.displayName} at{" "}
            <DefaultLink href={`mailto:${admin.email}`}>
              {admin.email}
            </DefaultLink>
            .
          </p>
          <p style={{ fontSize: "10pt", marginTop: 48, textAlign: "center" }}>
            <strong>Security onboarding by Vanta</strong>
            <br />
            <span>
              {user.domain.displayName} partners with Vanta for security and
              compliance tools.
            </span>
          </p>
        </div>
      </div>
    );
  }

  private renderSecurityPolicyAcceptance(
    notAcceptedPolicies: User["domain"]["policies"],
    acceptedPolicies: User["domain"]["policies"]
  ) {
    return (
      <div>
        {this.renderAcceptedPolicies(
          this.props.user.domain.displayName,
          acceptedPolicies,
          notAcceptedPolicies.length === 0
        )}
        {this.renderNotAcceptedPolicies(
          this.props.user.domain.displayName,
          notAcceptedPolicies,
          acceptedPolicies.length !== 0
        )}
      </div>
    );
  }

  private renderAcceptedPolicies(
    domainDisplayName: string,
    acceptedPolicies: User["domain"]["policies"],
    allAccepted: boolean
  ) {
    if (acceptedPolicies.length > 0) {
      const count = allAccepted ? "all" : acceptedPolicies.length;

      return (
        <div>
          <p>
            You have accepted {count} of {domainDisplayName}'s policies
            {allAccepted ? null : (
              <Button
                onClick={this.showAcceptedPolicies}
                minimal={true}
                icon={
                  this.state.acceptedPoliciesListIsOpen
                    ? "chevron-up"
                    : "chevron-down"
                }
              />
            )}
          </p>
          {allAccepted ? (
            this.makePolicyList(acceptedPolicies)
          ) : (
            <Collapse isOpen={this.state.acceptedPoliciesListIsOpen}>
              {this.makePolicyList(acceptedPolicies)}
            </Collapse>
          )}
        </div>
      );
    }
    return null;
  }

  private readonly showAcceptedPolicies = () => {
    this.setState({
      acceptedPoliciesListIsOpen: !this.state.acceptedPoliciesListIsOpen,
    });
  };

  private renderNotAcceptedPolicies(
    domainDisplayName: string,
    notAcceptedPolicies: User["domain"]["policies"],
    anyAccepted: boolean
  ) {
    if (notAcceptedPolicies.length > 0) {
      return (
        <div>
          <p>
            {domainDisplayName} has {notAcceptedPolicies.length}{" "}
            {anyAccepted ? "new " : ""}
            security&nbsp;
            {notAcceptedPolicies.length > 1 ? "policies" : "policy"} for you to
            accept:
          </p>
          {this.makePolicyList(notAcceptedPolicies)}
          <Button
            className="accept-policies-button"
            onClick={() =>
              this.submitSecurityPolicyAcceptance(notAcceptedPolicies)
            }
          >
            I have read and I accept these policies
          </Button>
        </div>
      );
    }
    return null;
  }

  private makePolicyList(policies: User["domain"]["policies"]) {
    return (
      <ul>
        {policies.map(policy => (
          <li key={policy.id}>
            {/**
             *  The standard url for the uploadedDocument Page
             *  `${location.origin}/${policy.uploadedDoc.url}`
             *  prevents users on mobile devices from viewing
             *  more than the first page.
             *
             */}
            <DefaultLink
              href={`${location.origin}/doc?s=${policy.uploadedDoc.slugId}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {policy.uploadedDoc.title}
            </DefaultLink>
          </li>
        ))}
      </ul>
    );
  }

  private submitSecurityPolicyAcceptance(policies: User["domain"]["policies"]) {
    this.props
      .acceptSecurityPolicies({
        variables: {
          policyIds: policies.map(p => p.id),
        },
      })
      .catch(LogError);
  }
}

const COMMON_OPTIONS = {
  refetchQueries: [{ query: GetUserInfoForOnboardingDocument }],
};

export const EmployeeOnboardingPage: React.FC = () => {
  const operations = {
    setUserSecurityTrainingAttestation: useTrainingAttestMutation({
      refetchQueries: [{ query: GetMostRecentSecurityTrainingsDocument }],
    })[0],
    acceptSecurityPolicies:
      useAcceptSecurityPoliciesMutation(COMMON_OPTIONS)[0],
    setUserSecurityTrainingVideoCompletion:
      useVideoCompletionMutation(COMMON_OPTIONS)[0],
  };

  const { error, loading, data } = useGetUserInfoForOnboardingQuery();
  if (error) {
    LogError(error);
    return null;
  }
  if (loading) {
    return <FullPageSpinner />;
  }
  if (!data) {
    LogErrorMessage("Bad fetch");
    return null;
  }
  const user = data.user;

  if (!isSome(user)) {
    window.location.href = "/onboarding";
    return <FullPageSpinner />;
  }

  return (
    <FeatureContext.Provider
      value={{
        betaFeatures: user.domain.betaFeatures,
      }}
    >
      <ManageOnboarding {...operations} user={user} />;
    </FeatureContext.Provider>
  );
};

type TrainingCompletion = User["mostRecentSecurityTraining"];

function getSecurityTrainingTaskVM(
  pref: TrainingPreference,
  categoryId: SecurityTrainingCategoryId,
  completion: Maybe<TrainingCompletion>,
  inRecurringSATBeta: boolean
) {
  let questionSchema: IQuestionSchema;
  let type: "custom" | "video";
  let videoName: Maybe<VIDEO_NAMES_TYPE> = null;
  switch (pref.preferredSecurityTraining) {
    case SECURITY_AWARENESS_TRAINING_INFO.GENERAL.ID:
      questionSchema = GeneralSecurityTrainingInfo;
      type = "video";
      videoName = "GENERAL";
      break;
    case SECURITY_AWARENESS_TRAINING_INFO.CUSTOM.ID:
      questionSchema = SecurityTrainingInfo;
      type = "custom";
      break;
    case SECURITY_AWARENESS_TRAINING_INFO.GDPR_GENERAL.ID:
      questionSchema = GdprGeneralSecurityTrainingInfo;
      type = "video";
      videoName = "GDPR_GENERAL";
      break;
    case SECURITY_AWARENESS_TRAINING_INFO.GDPR_CUSTOM.ID:
      questionSchema = GdprSecurityTrainingInfo;
      type = "custom";
      break;
    case SECURITY_AWARENESS_TRAINING_INFO.HIPAA_GENERAL.ID:
      questionSchema = HipaaGeneralSecurityTrainingInfo;
      type = "video";
      videoName = "HIPAA_GENERAL";
      break;
    case SECURITY_AWARENESS_TRAINING_INFO.HIPAA_CUSTOM.ID:
      questionSchema = HipaaSecurityTrainingInfo;
      type = "custom";
      break;
    case SECURITY_AWARENESS_TRAINING_INFO.PCI_CUSTOM.ID:
      questionSchema = PciSecurityTrainingInfo;
      type = "custom";
      break;
    default:
      throw new Error(
        `SAT type is not supported: ${pref.preferredSecurityTraining}`
      );
  }

  const { taskCreationDate } = getCurrentTrainingTaskDetails(
    completion?.completionDate,
    // These two arguments only used for calculating due date,
    // which we're not concerned about here.
    new Date(),
    null
  );
  // If the domain is in the beta, show the task as incomplete if it was
  // "created" before the current date.
  const completed = inRecurringSATBeta
    ? isAfter(taskCreationDate, new Date())
    : isSome(completion);
  let title = "";
  switch (categoryId) {
    case SecurityTrainingCategoryId.hipaa:
      title = "Complete HIPAA security awareness training";
      break;
    case SecurityTrainingCategoryId.gdpr:
      title = "Complete GDPR security awareness training";
      break;
    case SecurityTrainingCategoryId.pci:
      title = "Complete PCI DSS security awareness training";
      break;
    default:
      title = "Complete security awareness training";
      break;
  }

  return {
    completed,
    instructions:
      pref.securityTrainingInstructions ??
      "Please attach a screenshot of your completed security training.",
    priorScreenshot:
      completion?.__typename === "securityTrainingDocumentCompletion" &&
      completed
        ? {
            createdAt: completion.completionDate,
            filename: "Security Training Attestation",
            url: completion.uploadedDocument.url,
          }
        : null,
    questionSchema,
    title,
    trainingId: pref.preferredSecurityTraining,
    trainingUrl: !isSome(pref.securityTrainingUrl)
      ? null
      : pref.securityTrainingUrl.startsWith("https://") ||
        pref.securityTrainingUrl.startsWith("http://")
      ? pref.securityTrainingUrl
      : `https://${pref.securityTrainingUrl}`,
    type,
    videoName,
  };
}
