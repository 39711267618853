import React from "react";

import type { GetDataForReportQuery } from "../../../../../gen/components";
import type { IDomainForReport } from "../../../helpers/helpers";
import { VantaReportRequirementList } from "../requirement-list/vanta-report-requirement-list";
import { VantaReportPrincipleListItemHeading } from "./vanta-report-principle-list-item-heading";

interface IProps {
  domain: IDomainForReport;
  principle: NonNullable<
    GetDataForReportQuery["complianceStandard"]
  >["principles"][number];
}

export class VantaReportPrincipleListItem extends React.PureComponent<IProps> {
  public render() {
    const { principle, domain } = this.props;
    return (
      <div className="vrp-principle-list-item" id={principle.name}>
        <VantaReportPrincipleListItemHeading principle={principle} />
        <VantaReportRequirementList domain={domain} principle={principle} />
      </div>
    );
  }
}
