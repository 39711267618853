import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import React from "react";

import { VulnerabilityPageInfo } from "../common/vulnerability-page-info";

interface IProps {
  repositoryName: string;
  repositoryId: string;
  subscriptionId: string;
  imageDigest?: Maybe<string>;
  imageTags?: Maybe<string[]>;
}

export const AzureContainerRepositoryInfo: React.FC<IProps> = ({
  repositoryName,
  repositoryId,
  subscriptionId,
  imageDigest,
  imageTags,
}) => {
  const leftTablePairs = [
    { key: "repository name", value: repositoryName },
    { key: "repository id", value: repositoryId },
    { key: "subscription id", value: subscriptionId },
  ];
  if (isSome(imageDigest)) {
    leftTablePairs.push({ key: "image digest scanned", value: imageDigest });
  }
  if (isSome(imageTags) && imageTags.length > 0) {
    leftTablePairs.push({ key: "image tags", value: imageTags.join(", ") });
  }
  return (
    <VulnerabilityPageInfo
      leftTablePairs={leftTablePairs}
      leftColumnWidths={["160px", "600px"]}
    />
  );
};
