import {
  Button,
  Classes,
  FormGroup,
  Icon,
  InputGroup,
  Intent,
  Spinner,
  SpinnerSize,
} from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import React, { useState } from "react";
import styled from "styled-components";

import { BASE_PALETTE } from "../../alpaca/base/colors";
import { Dialog, Tooltip } from "../../alpaca/components";
import {
  useBulkWhitelistFailDataMutationMutation,
  WhitelistInformationFragmentDoc,
} from "../../gen/components";
import { updateTestPageQueryCacheWithNewTestResult } from "../pages/tests/update-cache";

interface IProps {
  entityId: string;
  entityType: string;
  identifierDisplayText?: Maybe<string>; // what the identifier will be displayed to the user as
  testId: string;
  allowWhitelisting: boolean;
}

gql`
  mutation BulkWhitelistFailDataMutation(
    $testId: String!
    $entityType: String!
    $entityIds: [String!]!
    $reason: String!
  ) {
    whitelistAddIdentifierBulk(
      testId: $testId
      entityType: $entityType
      entityIds: $entityIds
      reason: $reason
    ) {
      id
      ...whitelistInformation
    }
  }
  ${WhitelistInformationFragmentDoc}
`;

export const FailDataRow: React.FC<IProps> = props => {
  const [mutate] = useBulkWhitelistFailDataMutationMutation({
    update: (cache, result) => {
      const updateFn = updateTestPageQueryCacheWithNewTestResult;
      if (result.data?.whitelistAddIdentifierBulk) {
        result.data.whitelistAddIdentifierBulk.map(tr => updateFn(cache, tr));
      }
    },
  });

  const [dialogOpen, setDialogOpen] = useState(false);
  const [inFlight, setInFlight] = useState(false);
  const [reason, setReason] = useState("");
  const [validationError, setValidationError] = useState<Maybe<string>>(null);

  const maybeDialog = dialogOpen ? (
    <div>
      <Dialog
        isOpen={true}
        title="Disable monitoring"
        usePortal={true}
        onClose={() => setDialogOpen(false)}
      >
        <div className={Classes.DIALOG_BODY}>
          <h5>Why do you want to disable monitoring for this item?</h5>
          <p>
            If you disable monitoring, Vanta will not notify you about potential
            misconfigurations that may make you less secure.
          </p>
          {isSome(props.identifierDisplayText) ? (
            <p className={`${Classes.TEXT_MUTED} font-small`}>
              {props.identifierDisplayText}
            </p>
          ) : null}
          <div>
            <FormGroup
              helperText={
                !isSome(validationError) ? undefined : validationError
              }
              intent={isSome(validationError) ? Intent.DANGER : undefined}
            >
              <InputGroup
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setReason(e.currentTarget.value)
                }
                placeholder={"Not used in production"}
                value={reason}
              />
            </FormGroup>
          </div>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
            <Button
              intent={Intent.PRIMARY}
              onClick={async () => {
                if (reason.length === 0) {
                  setValidationError(
                    "You must give a reason for removing this item"
                  );
                  return;
                }
                setDialogOpen(false);
                setInFlight(true);

                await mutate({
                  variables: {
                    entityIds: [props.entityId],
                    entityType: props.entityType,
                    reason,
                    testId: props.testId,
                  },
                });

                setInFlight(false);
              }}
            >
              Disable
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  ) : null;

  const tooltipContent = "Disable monitoring";

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        marginBottom: 16,
        width: "100%",
      }}
    >
      {props.allowWhitelisting ? (
        <>
          {maybeDialog}
          <div>
            <Tooltip content={tooltipContent}>
              <div style={{ marginRight: 8, width: 20 }}>
                {inFlight ? (
                  <Spinner size={SpinnerSize.SMALL} />
                ) : (
                  <StyledIcon
                    icon="cross"
                    iconSize={20}
                    onClick={() => setDialogOpen(true)}
                  />
                )}
              </div>
            </Tooltip>
          </div>
        </>
      ) : null}
      <div style={{ width: "100%" }}>{props.children}</div>
    </div>
  );
};

const StyledIcon = styled(Icon)`
  & {
    svg {
      fill: ${BASE_PALETTE.CHARCOAL};
    }
    &:hover {
      cursor: pointer;
      svg {
        fill: ${BASE_PALETTE.INK};
      }
    }
  }
`;
