import { Spinner } from "@blueprintjs/core";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import React from "react";

import { GRID_SPACING } from "../../../../alpaca/base/grid";
import { BASE_TYPOGRAPHY } from "../../../../alpaca/base/typography";
import { BodyText } from "../../../../alpaca/components";
import { LogError } from "../../../../errors";
import { useGetGSuiteSsoServicesQuery } from "../../../../gen/components";
import { DefaultLink } from "../../../../helpers/links";
import { InfoIcon } from "../../people/shared/info-icon";
import type { AccessTabUser } from "./access-tab";

interface IProps {
  user: AccessTabUser;
}

export const GSuiteSSOServiceList: React.FC<IProps> = ({ user }) => {
  const { loading, error, data } = useGetGSuiteSsoServicesQuery({
    variables: { userId: user.id },
    skip: !isSome(user.gsuiteId) || !user.isActive,
  });
  if (!isSome(user.gsuiteId) || !user.isActive) {
    return null;
  }
  const heading = (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        marginBottom: "12px",
        marginTop: `${2 * GRID_SPACING}px`,
      }}
    >
      <BodyText
        style={{ marginBottom: "0px", marginRight: `${GRID_SPACING}px` }}
        fontWeight={BASE_TYPOGRAPHY.FONT_WEIGHTS.BOLD}
      >
        Linked via Google Workspace SSO
      </BodyText>
      <InfoIcon
        tooltipContent={
          <p style={{ marginBottom: "0px" }}>
            Google Workspace admins can revoke SSO grants through the&nbsp;
            <DefaultLink href="https://admin.google.com/AdminHome#UserList:org">
              Google Workspace admin console.
            </DefaultLink>
          </p>
        }
        tooltipProps={{ interactionKind: "hover", hoverCloseDelay: 200 }}
      />
    </div>
  );

  if (loading) {
    <>
      {heading}
      <Spinner />;
    </>;
  }

  if (error) {
    LogError(error, false);
    return (
      <>
        {heading}
        <BodyText>
          There was an error accessing information about connected applications
          for your Google Workspace account, which requires User Security
          Management privileges. To view this information, have an admin with
          these permissions relink Google Workspace on the{" "}
          <DefaultLink href="/connections">Connections page</DefaultLink>.
        </BodyText>
      </>
    );
  }

  if (!isSome(data) || !isSome(data.user)) {
    return null;
  }

  const services = data.user.gsuiteSSOServices;
  if (!isSome(services)) {
    return (
      <>
        {heading}
        <BodyText>
          You have not linked sufficient permissions on your Google Workspace
          account to show this information. Contact support@vanta.com for help.
        </BodyText>
      </>
    );
  }
  const sortedServices = services
    .slice()
    .sort((s1, s2) =>
      s1.toLocaleLowerCase().localeCompare(s2.toLocaleLowerCase())
    );

  return (
    <>
      {heading}
      {sortedServices.map((service, idx) => (
        <div
          key={`gsuite-service-${idx}`}
          style={{ marginBottom: `${2 * GRID_SPACING}px` }}
        >
          <BodyText as="div" key={`gsuite-service-${idx}`}>
            {service}
          </BodyText>
        </div>
      ))}
    </>
  );
};

gql`
  query getGSuiteSSOServices($userId: ID!) {
    user(id: $userId) {
      id
      gsuiteSSOServices
    }
  }
`;
