import type { PureQueryOptions } from "@apollo/client";
import { Button, Classes, Dialog, InputGroup, Intent } from "@blueprintjs/core";
import { dropNothing } from "common/base/types/maybe";
import React, { useState } from "react";

import { LogError } from "../../../../errors";
import { useBulkWhitelistFailDataMutationMutation } from "../../../../gen/components";
import { AppToaster } from "../../../../helpers/toaster";
import { vulnToTitle } from "../utils";
import type { VulnerablePackage } from "./agent-package-vulnerabilities-list";
import { ExpandableMachineList } from "./expandable-machines-list";

interface IProps {
  refetchQueries: PureQueryOptions[];
  vuln: VulnerablePackage;
  onClose(): void;
}

const VULN_TEST_ID =
  "infra-packages-checked-for-vulnerabilities-records-closed";

export const IgnoreVulnDialog: React.FC<IProps> = ({
  refetchQueries,
  vuln,
  onClose,
}) => {
  const [inputText, setInputText] = useState("");
  const [ignoreVuln, mutationResult] = useBulkWhitelistFailDataMutationMutation(
    {
      refetchQueries,
      onCompleted: () => {
        AppToaster.show({
          intent: Intent.SUCCESS,
          message: "Monitoring of this vulnerability has been disabled",
        });
        onClose();
      },
    }
  );
  const title = vulnToTitle(vuln);
  const machineNames = dropNothing(
    vuln.machineInstances?.map(i => i.vulnData?.osquery?.prettyName) ?? []
  );
  return (
    <Dialog
      isOpen={true}
      onClose={onClose}
      title={`Disable monitoring of this vulnerability?`}
    >
      <div className={Classes.DIALOG_BODY}>
        <p>
          Monitoring will be disabled for all machines with this vulnerability.
        </p>
        <h5>Remediation: </h5>
        <p>{title}</p>
        <h5>Affected machines</h5>
        <ExpandableMachineList machines={machineNames} />
        <h5>Provide a reason for disabling monitoring.</h5>
        <InputGroup
          autoFocus
          value={inputText}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setInputText(e.target.value)
          }
        />
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button
            intent={Intent.NONE}
            onClick={onClose}
            disabled={mutationResult.called}
          >
            Cancel
          </Button>
          <Button
            loading={mutationResult.called}
            intent={Intent.DANGER}
            onClick={() => {
              const reason = inputText.trim();
              ignoreVuln({
                variables: {
                  reason,
                  entityType: "OsqueryVulnerability",
                  entityIds:
                    vuln.machineInstances?.map(i => i.vulnData.id) ?? [],
                  testId: VULN_TEST_ID,
                },
              }).catch(LogError);
            }}
          >
            Disable Monitoring
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
