import { Button, Classes, Dialog, Intent } from "@blueprintjs/core";
import type { Service } from "common/base/types/helpers";
import { serviceToDisplayName } from "common/base/types/helpers";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import React from "react";

import {
  AllLinkedCredentialsDocument,
  useDisconnectCredentialMutation,
} from "../../../gen/components";
import { AppToaster } from "../../../helpers/toaster";

interface IDisconnectCredentialDialogProps {
  isOpen: boolean;
  closeDialog: () => void;
  credentialId: string;
  service: string;
  externalAccountId?: Maybe<string>;
}

export const DisconnectCredentialDialog: React.FC<IDisconnectCredentialDialogProps> =
  ({ isOpen, closeDialog, credentialId, service, externalAccountId }) => {
    const serviceDisplayName = serviceToDisplayName(service as Service);

    const [disconnectCredential, { loading }] = useDisconnectCredentialMutation(
      {
        variables: { service, credentialId },
        awaitRefetchQueries: true,
        refetchQueries: [{ query: AllLinkedCredentialsDocument }],
        onCompleted: () => {
          AppToaster.show({
            icon: "tick",
            intent: Intent.SUCCESS,
            message: "Credential successfully disconnected.",
            timeout: 2500,
          });
          closeDialog();
        },
        onError: () => {
          AppToaster.show({
            icon: "error",
            intent: Intent.DANGER,
            message: "Error disconnecting credential.",
            timeout: 2500,
          });
        },
      }
    );

    const maybeExternalAccountId = isSome(externalAccountId) ? (
      <>the {externalAccountId} account for </>
    ) : null;

    return (
      <Dialog isOpen={isOpen} onClose={closeDialog}>
        <div>
          <div className={Classes.DIALOG_HEADER}>
            Disconnect {serviceDisplayName}
          </div>
          <div className={Classes.DIALOG_BODY}>
            <p>
              Vanta will stop monitoring {maybeExternalAccountId}
              {serviceDisplayName} and delete all associated data. This action
              is not reversible.
            </p>
            <p>
              If you want to update your credential without deleting data, use
              the <strong>Edit</strong> button instead.
            </p>
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button
                text="Delete credential and data"
                onClick={async () => disconnectCredential()}
                loading={loading}
              />
            </div>
          </div>
        </div>
      </Dialog>
    );
  };

gql`
  mutation disconnectCredential($service: String!, $credentialId: String!) {
    deleteCredentialAndData(service: $service, credentialId: $credentialId) {
      id
    }
  }
`;
