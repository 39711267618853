import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import type {
  IRiskCategory,
  IRiskItem,
  IRiskSchema,
} from "common/schemas/riskRegister/risk-register-content";
import React from "react";

import { VANTA_COLORS } from "../../../alpaca/base/colors";
import TaskSVG from "../../../static/images/svg/task_writing.svg";
import { StepCompletionPanel } from "../../master-detail-view/detail-panels/step-completion-panel";
import { StepIntroductionPanel } from "../../master-detail-view/detail-panels/step-intro-panel";
import { DismissedRiskPanel } from "./dismissed-risk-panel";
import type { RiskDismissal, RiskMitigationTask } from "./queries-and-types";
import { RISK_ICONS } from "./risk-definitions/risks";
import type { RiskFormLocation } from "./risk-form-location";
import { RiskItemEvaluationFlow } from "./risk-item-evaluation-flow";
import { RiskItemSelectionFlow } from "./risk-item-selection-flow";
import { RiskTaskEditor } from "./risk-task-editor";

interface IProps {
  category: IRiskCategory;
  currentRiskItems: IRiskItem[];
  dismissals: RiskDismissal[];
  tasks: RiskMitigationTask[];
  formLocation: RiskFormLocation;
}

const TASK_INTRODUCTORY_COPY =
  "In this section you will create tasks —think of this like a to-do list— to help mitigate the risks you identified.";

const RiskIconForRisk = (risk?: Maybe<IRiskSchema>) => {
  if (!isSome(risk)) {
    return TaskSVG;
  } else {
    return RISK_ICONS[risk.icon as keyof typeof RISK_ICONS];
  }
};

export const RiskFormDetail: React.FC<IProps> = ({
  category,
  dismissals,
  currentRiskItems,
  tasks,
  formLocation,
}) => {
  const currentRisk = category.risks[formLocation.getStepIndex()];

  if (formLocation.isStepStart()) {
    const SVG = RiskIconForRisk(currentRisk);
    return (
      <StepIntroductionPanel
        stepName={isSome(currentRisk) ? currentRisk.shortHeading : "Tasks"}
        stepIndex={formLocation.getStepIndex()}
        description={currentRisk?.introductoryText ?? TASK_INTRODUCTORY_COPY}
        image={<SVG fill={VANTA_COLORS.VANTA_PURPLE} />}
      />
    );
  }

  if (formLocation.isStepCompletion()) {
    return (
      <StepCompletionPanel
        textContent={
          <span>
            Step {formLocation.getStepIndex() + 1} of your{" "}
            {category.breadcrumbText} assessment is complete!
          </span>
        }
      />
    );
  }

  if (formLocation.getStepIndex() === category.risks.length) {
    return (
      <RiskTaskEditor
        tasks={tasks.filter(task => task.riskCategoryId === category.id)}
        riskCategoryId={category.id}
      />
    );
  }

  if (!isSome(formLocation.getItemIndex())) {
    if (dismissals.find(d => d.riskId === currentRisk.id)) {
      return <DismissedRiskPanel riskId={currentRisk.id} />;
    }
    return (
      <RiskItemSelectionFlow
        riskSchema={currentRisk}
        items={currentRiskItems}
      />
    );
  } else {
    return (
      <RiskItemEvaluationFlow
        riskItem={currentRiskItems[formLocation.getItemIndex()!]}
        schema={currentRisk}
        onComplete={() => formLocation.gotoNextStep()}
      />
    );
  }
};
