import { Intent } from "@blueprintjs/core";
import { isSome } from "common/base/types/maybe";
import fileDownload from "js-file-download";
import { parse } from "json2csv";
import { LogErrorMessage } from "../errors";
import { AppToaster } from "./toaster";

/**
 * Programmatically triggers a file download for the given test raw data
 *
 * @param rawData The raw data to download to the client's machine
 * @param testName Name of the associated test
 * @param testId Test ID of the associated test
 */
export function downloadRawData(
  rawData: string,
  testName: string,
  testId: string
) {
  try {
    const csvStringJSON = JSON.parse(JSON.parse(rawData).csvString);
    if (!isSome(csvStringJSON)) {
      return;
    }

    let csvString = "";
    if (csvStringJSON.length > 0) {
      csvString = parse(csvStringJSON);
    }

    fileDownload(csvString, `${testName.replace(/ /g, "-")}-testRun.csv`);
  } catch (e) {
    LogErrorMessage(`Could not parse raw data for ${testId}`);
    AppToaster.show({
      message: "There was a problem accessing this data",
      intent: Intent.DANGER,
    });
  }
}
