import { Button, Dialog, Intent, Spinner } from "@blueprintjs/core";
import type { Service } from "common/base/types/helpers";
import { serviceToDisplayName } from "common/base/types/helpers";
import type { Maybe } from "common/base/types/maybe";
import { isSome, getTransformedOrElse } from "common/base/types/maybe";
import { simplePlural } from "common/grammar/plurals";
import gql from "graphql-tag";
import { partition } from "lodash";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { IconNames } from "../../../../alpaca/components";
import { LogError } from "../../../../errors";
import type {
  GetLinkableBgChecksQuery,
  GetUserInfoForOnboardingListQuery,
} from "../../../../gen/components";
import {
  GetLinkableBgChecksDocument,
  GetUserBackgroundCheckListDocument,
  GetUserInfoForOnboardingListDocument,
  useAssignBackgroundCheckMutation,
  useGetLinkableBgChecksQuery,
  useIgnoreBackgroundCheckMutation,
} from "../../../../gen/components";
import { AppToaster } from "../../../../helpers/toaster";
import { DataTable } from "../../components/data-table";
import { Link } from "../../vulns/common/components";
import { IconButton } from "../shared/icon-button";

type User = NonNullable<GetUserInfoForOnboardingListQuery["user"]>;
type UiLinkableBackgroundCheck = NonNullable<
  GetLinkableBgChecksQuery["organization"]
>["uiLinkableBackgroundChecks"][number];

interface IProps {
  isOpen: boolean;
  onClose(): void;
  unassignedBackgroundChecks: UiLinkableBackgroundCheck[];
  user: User;
}

const InternalDialogComponent: React.FC<IProps> = ({
  onClose,
  unassignedBackgroundChecks,
  isOpen,
  user,
}) => {
  const [
    inFlightUserBackgroundCheckMappings,
    setInFlightUserBackgroundCheckMappings,
  ] = useState<{ [k: string]: boolean }>({});
  const [showIgnoredChecks, setShowIgnoredChecks] = useState(false);
  const [assignBackgroundCheck, assignResult] =
    useAssignBackgroundCheckMutation({
      refetchQueries: [
        {
          query: GetUserInfoForOnboardingListDocument,
          variables: { userId: user.id },
        },
        {
          query: GetUserBackgroundCheckListDocument,
          variables: { userId: user.id },
        },
        {
          query: GetLinkableBgChecksDocument,
        },
      ],
    });
  const [ignoreBackgroundCheck, ignoreResult] =
    useIgnoreBackgroundCheckMutation({
      refetchQueries: [
        {
          query: GetUserInfoForOnboardingListDocument,
          variables: { userId: user.id },
        },
        {
          query: GetLinkableBgChecksDocument,
        },
      ],
    });

  useEffect(() => {
    const updatedCheck = assignResult.data?.setBackgroundCheckMapping;
    if (
      isSome(updatedCheck) &&
      Boolean(inFlightUserBackgroundCheckMappings[updatedCheck])
    ) {
      const newInFlight = { ...inFlightUserBackgroundCheckMappings };
      delete newInFlight[updatedCheck];
      setInFlightUserBackgroundCheckMappings(newInFlight);
    }
  }, [assignResult.data?.setBackgroundCheckMapping]);

  useEffect(() => {
    const updatedCheck = ignoreResult.data?.setBackgroundCheckToIgnored;
    if (
      isSome(updatedCheck) &&
      Boolean(inFlightUserBackgroundCheckMappings[updatedCheck])
    ) {
      const newInFlight = { ...inFlightUserBackgroundCheckMappings };
      delete newInFlight[updatedCheck];
      setInFlightUserBackgroundCheckMappings(newInFlight);
    }
  }, [ignoreResult.data?.setBackgroundCheckToIgnored]);

  const setIgnoreBackgroundCheck = async (backgroundCheckId: string) => {
    setInFlightUserBackgroundCheckMappings({
      ...inFlightUserBackgroundCheckMappings,
      [backgroundCheckId]: true,
    });
    await ignoreBackgroundCheck({
      variables: { backgroundCheckId },
    });
    const newInFlight = { ...inFlightUserBackgroundCheckMappings };
    delete newInFlight[backgroundCheckId];
    setInFlightUserBackgroundCheckMappings(newInFlight);
  };

  const assignBackgroundCheckToUser = async (
    backgroundCheck: UiLinkableBackgroundCheck,
    isUndo: boolean = false
  ) => {
    setInFlightUserBackgroundCheckMappings({
      ...inFlightUserBackgroundCheckMappings,
      [backgroundCheck.id]: true,
    });
    try {
      await assignBackgroundCheck({
        variables: {
          backgroundCheckId: backgroundCheck.id,
          userId: isUndo ? null : user.id,
        },
      });

      const toastMessage = `Background check for ${backgroundCheck.email} ${
        isUndo ? ` unlinked` : `assigned to ${user.displayName}`
      }`;
      AppToaster.show({
        action: {
          onClick: async () => {
            await assignBackgroundCheckToUser(
              backgroundCheck,
              !Boolean(isUndo)
            );
          },
          text: "Undo",
        },
        icon: "tick",
        intent: Intent.SUCCESS,
        message: toastMessage,
        timeout: 2500,
      });
    } catch (e) {
      LogError(e);
    }
  };

  const getRowUnassignedTable = (row: UiLinkableBackgroundCheck) => {
    const isInFlight = inFlightUserBackgroundCheckMappings[row.id];

    const onIgnore = () => {
      setIgnoreBackgroundCheck(row.id).catch(e => LogError);
    };

    const assignButton = (
      <Button
        onClick={async () => {
          await assignBackgroundCheckToUser(row);
        }}
      >
        Link
      </Button>
    );
    const ignoreButton = row.ignored ? null : (
      <Button onClick={onIgnore} disabled={isInFlight}>
        Ignore
      </Button>
    );

    return {
      assignButton,
      ignoreButton,
      identifyingInfo: (
        <div>
          Email: {row.email}
          <br />
          Name: {row.givenName} {row.familyName}
        </div>
      ),
      source: (
        <div>
          {serviceToDisplayName(row.service as Service)}
          <br />
          {row.status === "COMPLETED"
            ? "Completed " + row.completedAt!
            : "In progress"}
        </div>
      ),
    };
  };

  const [ignoredChecks, unlinkedChecks] = partition(
    unassignedBackgroundChecks,
    check => check.ignored
  );

  return (
    <Dialog
      style={{ width: "750px" }}
      isOpen={isOpen}
      onClose={onClose}
      title={`Link background check to ${user.displayName}`}
    >
      <ScrollingDiv>
        <DataTable
          columnOrder={[
            "identifyingInfo",
            "source",
            "assignButton",
            "ignoreButton",
          ]}
          createRow={getRowUnassignedTable}
          data={
            showIgnoredChecks
              ? [...unlinkedChecks, ...ignoredChecks]
              : unlinkedChecks
          }
          header={{
            identifyingInfo: "Identifying info",
            source: "Source",
          }}
        />
      </ScrollingDiv>
      {ignoredChecks.length > 0 ? (
        <ToggleDiv>
          {!showIgnoredChecks ? (
            <Link
              onClick={() => {
                setShowIgnoredChecks(true);
              }}
            >
              show {simplePlural(ignoredChecks.length, "ignored check")}
            </Link>
          ) : (
            <Link
              onClick={() => {
                setShowIgnoredChecks(false);
              }}
            >
              hide {simplePlural(ignoredChecks.length, "ignored check")}
            </Link>
          )}
        </ToggleDiv>
      ) : null}
    </Dialog>
  );
};

const ToggleDiv = styled.div`
  padding-left: 20px;
`;

const ScrollingDiv = styled.div`
  max-height: 300px;
  overflow-y: scroll;
  flex: 1 1 auto;
  margin: 20px;
  line-height: 18px;
  table {
    width: 100%;
  }
`;

interface IExternalProps {
  user: User;
  renderButton?: Maybe<(onClick: () => void) => JSX.Element>;
}

export const LinkBgCheckButtonWithDialog: React.FC<IExternalProps> = ({
  user,
  renderButton,
}) => {
  const { loading, data } = useGetLinkableBgChecksQuery();
  const [isOpen, setIsOpen] = useState(false);

  if (loading || !data) {
    return (
      <div>
        <Spinner size={Spinner.SIZE_SMALL} />
      </div>
    );
  }

  if (data.organization.uiLinkableBackgroundChecks.length === 0) {
    return null;
  }

  const onClick = () => setIsOpen(true);
  const button = getTransformedOrElse(
    renderButton,
    render => render(onClick),
    <IconButton
      onClick={onClick}
      icon={IconNames.LINK}
      tooltipContent={"Link"}
    />
  );

  return (
    <>
      {button}
      <InternalDialogComponent
        isOpen={isOpen}
        user={user}
        onClose={() => setIsOpen(false)}
        unassignedBackgroundChecks={
          data.organization.uiLinkableBackgroundChecks
        }
      />
    </>
  );
};

gql`
  query GetLinkableBGChecks {
    organization {
      id
      uiLinkableBackgroundChecks {
        id
        status
        service
        completedAt
        familyName
        givenName
        email
        ignored
      }
    }
  }
`;

gql`
  mutation assignBackgroundCheck($backgroundCheckId: String!, $userId: ID) {
    setBackgroundCheckMapping(
      backgroundCheckId: $backgroundCheckId
      userId: $userId
    )
  }
`;

gql`
  mutation ignoreBackgroundCheck($backgroundCheckId: String!) {
    setBackgroundCheckToIgnored(backgroundCheckId: $backgroundCheckId)
  }
`;
