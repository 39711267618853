import moment from "moment";

import type { Maybe } from "./types/maybe";
import { nothing, isSome } from "./types/maybe";

/**
 * This function is used for certain date fields
 * that were stored as unix timestamps in miliseconds before we started using
 * standard Date scalars. Hopefully we can get rid of it someday
 *
 * If you see this function used around a value you know to be a date string,
 * you can replace it with a simple date constructor.
 * @param date - a Date, a number (in ms), a string (in ms) or a string in format that moment can parse.
 */
export function dateStringToDate(date: string | number | Date) {
  if (date instanceof Date) {
    return date; // Just return the Date - useful during the migration
  } else if (typeof date === "number") {
    return new Date(date);
  } else if (/^\d+$/.test(date)) {
    // A string that's all numbers - parse it as a unix timestamp
    return moment(date, "x").toDate();
  } else {
    // https://momentjs.com/docs/#/parsing/string/
    // When creating a moment from a string, we first check if the string matches known
    // ISO 8601 formats, we then check if the string matches the RFC 2822 Date time format
    // before dropping to the fall back of new Date(string) if a known format is not found.
    return moment(date).toDate();
  }
}

/**
 * Gets the minimum of an array of Maybe<string> dates
 * where strings should be ISO8601 date strings.
 */
export function minMaybeISODateStrings(dateStrings: string[]) {
  const ds = dateStrings
    .map(s => moment(s, moment.ISO_8601))
    .filter(d => d.isValid());
  return ds.length > 0 ? moment.min(ds) : null; // moment.min returns current time if empty
}

export function minMaybeDates(dates: Array<Maybe<Date>>) {
  let earliestDate: Maybe<Date> = null;
  for (const d of dates) {
    if (!isSome(earliestDate)) {
      earliestDate = d;
    } else if (isSome(d) && d < earliestDate) {
      earliestDate = d;
    }
  }
  return earliestDate;
}

/**
 * Converts a mongo objectId into a date.
 * If you have an ObjectId in hand, then just call .getTimestamp() on it instead.
 */
export function dateFromObjectId(objectId: string) {
  return new Date(parseInt(objectId.substring(0, 8), 16) * 1000);
}

/**
 * Checks if the date object is valid.
 *
 * @param date - a Date, to verify as valid.
 */
export function isValidDate(date: Date) {
  return date instanceof Date && !isNaN(date.getTime());
}

export function toPossibleDate(input?: Maybe<string>) {
  return isSome(input) ? dateStringToDate(input) : nothing;
}

export function toPossibleISOString(date: Maybe<Date>) {
  return isSome(date) ? date.toISOString() : date;
}
