import { isSome } from "common/base/types/maybe";
import React from "react";

import type { AccessServiceAccount } from "../accounts-access-table";
import { InfoNotAvailable, StyledRoleTagList } from "./styles";

export const AccessAccountRole: React.FC<{
  serviceAccount: AccessServiceAccount;
}> = ({ serviceAccount }) => {
  // any given account will only have one of these fields
  const { role, groupList, teamList } = serviceAccount;
  if (isSome(role)) {
    return <StyledRoleTagList tags={[role]} />;
  }
  const roleDisplayList = groupList ?? teamList;
  if (isSome(roleDisplayList)) {
    return <StyledRoleTagList tags={roleDisplayList} />;
  }
  return <InfoNotAvailable />;
};
