import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import {
  ReservedVantaAttributes,
  VantaAttributeSentinelValues,
} from "common/utils/vantaAttributes";
import gql from "graphql-tag";
import React from "react";
import styled from "styled-components";

import { VANTA_COLORS } from "../../alpaca/base/colors";
import type {
  FetchSpecificKindScopeSettingsQuery,
  VantaAttribute,
} from "../../gen/components";
import {
  SpecificResource,
  useSetResourceScopeMutation,
} from "../../gen/components";
import { Ellipsify } from "../../helpers/ellipsify";
import { ConfigureScopesToggle } from "./configure-scopes-toggle";

interface IResourceItemProps {
  kind: SpecificResource;
  resource: FetchSpecificKindScopeSettingsQuery["organization"]["resources"]["edges"][number]["node"] & {
    prettyName: Maybe<string>;
    scopeSetting: Maybe<VantaAttribute>;
    secondaryText?: Maybe<string>;
  };
}

export const ConfigureScopesResourceItem: React.FC<IResourceItemProps> = ({
  kind,
  resource,
}) => {
  const [writeScopeSetting, { loading }] = useSetResourceScopeMutation({
    context: {
      debounceKey: `${kind}:${resource.uniqueId}`,
    },
    update: (cache, result) => {
      cache.modify({
        id: cache.identify(resource),
        fields: {
          vantaAttributes(cachedVantaAttributes) {
            const newVantaAttributes = [...(cachedVantaAttributes ?? [])];
            const excludedAttrIx = newVantaAttributes.findIndex(
              a => a.key === ReservedVantaAttributes.excluded.key
            );
            if (excludedAttrIx !== -1) {
              newVantaAttributes.splice(excludedAttrIx, 1);
            } else {
              newVantaAttributes.push({
                key: ReservedVantaAttributes.excluded.key,
                managedExternally: false,
                value: VantaAttributeSentinelValues.TRUE,
                __typename: "vantaAttribute",
              });
            }
            return newVantaAttributes;
          },
        },
      });
    },
  });

  return (
    <ScopeResourceCard>
      <ScopeResourceCardIdentifiers>
        <div>{resource.prettyName ?? resource.uniqueId}</div>
        {isSome(resource.secondaryText) ? (
          <ScopeResourceCardSecondaryIdentifier text={resource.secondaryText} />
        ) : null}
      </ScopeResourceCardIdentifiers>
      <ConfigureScopesToggle
        excluded={
          resource.scopeSetting?.value === VantaAttributeSentinelValues.TRUE
        }
        locked={resource.scopeSetting?.managedExternally === true || loading}
        // NOTE: Not all MDM providers support computer groups. If you find yourself
        // adding an item here, consider making a generic ManagedComputerTypesWithGroups util
        // instead of adding checks for specific kinds
        tooltipContent={getLockedScopeTooltip(kind)}
        setExcluded={async newExclusion => {
          await writeScopeSetting({
            variables: {
              specificResourceKind: kind,
              mongoId: resource.id,
              excluded: newExclusion,
            },
          });
        }}
      />
    </ScopeResourceCard>
  );
};

function getLockedScopeTooltip(kind: SpecificResource) {
  switch (kind) {
    case SpecificResource.JamfManagedComputer:
      return "Controlled with Jamf computer group";
    case SpecificResource.GsuiteUser:
    case SpecificResource.OfficeUser:
    case SpecificResource.OktaUser:
      return "This user is a Vanta admin and cannot be marked out of scope";
    default:
      return "Controlled with cloud provider tags or labels";
  }
}

const ScopeResourceCardStyles = {
  HEIGHT: 48,
  PADDING: 12,
  FONT_SIZE: 14,
};

const ScopeResourceCard = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;

  height: ${ScopeResourceCardStyles.HEIGHT}px;
  padding: ${ScopeResourceCardStyles.PADDING}px;
  font-size: ${ScopeResourceCardStyles.FONT_SIZE}px;
`;

const ScopeResourceCardIdentifiers = styled.div`
  flex-grow: 2;
  white-space: nowrap;
  overflow: hidden;
`;

const ScopeResourceCardSecondaryIdentifier = styled(Ellipsify)`
  color: ${VANTA_COLORS.TEXT_MUTED};
`;

gql`
  mutation setResourceScope(
    $specificResourceKind: SpecificResource!
    $mongoId: String!
    $excluded: Boolean!
  ) {
    setResourceScope(
      specificResourceKind: $specificResourceKind
      mongoId: $mongoId
      excluded: $excluded
    )
  }
`;
