import { FormGroup, HTMLSelect, InputGroup, Intent } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/gen";
import type { DocumentNode } from "graphql";
import gql from "graphql-tag";
import React, { useContext, useState } from "react";

import { BASE_PALETTE } from "../../../alpaca/base/colors";
import { GRID_SPACING } from "../../../alpaca/base/grid";
import { Button, Icon, IconNames } from "../../../alpaca/components";
import { LogError } from "../../../errors";
import {
  GetInfoForRolesTableDocument,
  useCreateGroupMutation,
} from "../../../gen/components";
import { AppToaster } from "../../../helpers/toaster";
import { CancelConfirmDialog } from "../../helpers/CancelConfirmDialog";
import { GroupsContext } from "./groups-context";

const NO_GROUP_SELECTED = "__internal-no-group";

interface IProps {
  refetchQueryDocument?: Maybe<DocumentNode>;
}

export const AddGroupButton: React.FC<IProps> = ({ refetchQueryDocument }) => {
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [groupsSelectValue, setGroupsSelectValue] =
    useState<string>(NO_GROUP_SELECTED);
  const { groups } = useContext(GroupsContext);
  const [inputText, setInputText] = useState("");
  const [createGroup] = useCreateGroupMutation({
    refetchQueries: [
      {
        query: refetchQueryDocument ?? GetInfoForRolesTableDocument,
      },
    ],
    onCompleted() {
      AppToaster.show({
        message: "Group added",
        intent: Intent.SUCCESS,
      });
      resetForm();
    },
  });

  const groupsSelect =
    groups.length > 0 ? (
      <FormGroup label="Copy checklist from the following group">
        <HTMLSelect
          value={groupsSelectValue}
          onChange={e => setGroupsSelectValue(e.target.value)}
        >
          <option value={NO_GROUP_SELECTED}>Don't copy a list</option>
          {groups.map(group => (
            <option key={group.id} value={group.id}>
              {group.name}
            </option>
          ))}
        </HTMLSelect>
      </FormGroup>
    ) : null;

  return (
    <>
      <Button onClick={() => setDialogIsOpen(true)}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Icon
            icon={IconNames.PERSON_INFO}
            color={BASE_PALETTE.INK}
            style={{ marginRight: `${GRID_SPACING}px` }}
          />
          <span>Add a group</span>
        </div>
      </Button>
      <CancelConfirmDialog
        body={
          <div>
            <FormGroup label="Group name">
              <InputGroup
                value={inputText}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setInputText(e.target.value)
                }
                placeholder="Engineering, Sales"
              />
            </FormGroup>
            {groupsSelect}
          </div>
        }
        title="Add a group"
        confirmText="Create group"
        onConfirm={() => {
          const roleIdToCopy =
            groupsSelectValue !== NO_GROUP_SELECTED ? groupsSelectValue : null;
          createGroup({
            variables: {
              name: inputText.trim(),
              roleIdToCopy,
            },
          }).catch(LogError);
        }}
        confirmDisabled={inputText.trim() === ""}
        isOpen={dialogIsOpen}
        onClose={resetForm}
      ></CancelConfirmDialog>
    </>
  );

  function resetForm() {
    setInputText("");
    setDialogIsOpen(false);
  }
};

gql`
  mutation createGroup($name: String!, $roleIdToCopy: String) {
    createRole(name: $name, roleIdToCopy: $roleIdToCopy) {
      id
    }
  }
`;
