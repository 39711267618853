import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import type { GetPoliciesV2DomainInfoQuery } from "../../../gen/components";

type PolicyGroupInfo = NonNullable<
  GetPoliciesV2DomainInfoQuery["organization"]
>["policies"][number];

type DefaultSecurityRequirements = NonNullable<
  GetPoliciesV2DomainInfoQuery["organization"]
>["defaultSecurityRequirements"];

type DefaultNecessaryPolicies = NonNullable<
  GetPoliciesV2DomainInfoQuery["organization"]
>["defaultNecessaryPolicies"];

export const getEmployeeGroupsForPolicy = (
  policy: Maybe<PolicyGroupInfo>,
  defaultSecurityRequirements: DefaultSecurityRequirements,
  defaultNecessaryPolicies: Maybe<DefaultNecessaryPolicies>
): string[] => {
  if (!isSome(policy)) {
    return [];
  }
  let employeeGroups = isSome(policy.rolesToAccept)
    ? policy.rolesToAccept.map(r => r.name)
    : [];

  if (
    defaultSecurityRequirements.mustAcceptPolicies &&
    // if defaultNecessaryPolicies is null, all policies are required
    (!isSome(defaultNecessaryPolicies) ||
      defaultNecessaryPolicies.includes(policy.policyType))
  ) {
    employeeGroups = employeeGroups.concat(
      "Default for all employees not assigned to a group"
    );
  }
  return employeeGroups;
};
