/* eslint-disable */
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * A Duration type - https://github.com/Urigo/graphql-scalars/blob/master/src/scalars/iso-date/Duration.ts
   *   Input:
   *     A string representing a duration conforming to the ISO8601 standard, such as: P1W1DT13H23M34S
   *       PnYnMnDTnHnMnS - P<date>T<time>
   *       The n is replaced by the value for each of the date and time elements that follow the n.
   *     The parsed value is a string that is valid per the standard
   *
   *   Output:
   *     A string representing a duration conforming to the ISO8601 standard as described above
   */
  Duration: string;
  /**
   * A EmailAddress type - https://github.com/Urigo/graphql-scalars/blob/master/src/scalars/EmailAddress.ts
   *   Input:
   *     A string representing a duration conforming to the RFC822 standard
   *     The parsed value is a string that is valid per the standard
   *
   *   Output:
   *     A string representing a duration conforming to the RFC822 standard as described above
   */
  EmailAddress: any;
  /**
   * A URL type - https://github.com/Urigo/graphql-scalars/blob/master/src/scalars/URL.ts
   *   Input:
   *     A string representing a URL conforming to the standard URL format as specified in RFC3986: https://www.ietf.org/rfc/rfc3986.txt.'
   *
   *   Output:
   *     A string representing a URL conforming to the RFC3986 standard as described above
   */
  URL: any;
  /**
   * A DateTime type - https://github.com/Urigo/graphql-scalars/blob/master/src/scalars/iso-date/DateTime.ts
   *   Input:
   *      This scalar takes an RFC 3339 date-time string as input and
   *      parses it to a javascript Date.
   *
   *   Output:
   *      This scalar serializes javascript Dates,
   *      RFC 3339 date-time strings and unix timestamps
   *      to RFC 3339 UTC date-time strings.
   */
  DateTime: any;
  /**
   * The `Upload` scalar type represents a file upload promise that resolves an
   * object containing `stream`, `filename`, `mimetype` and `encoding`.
   */
  Upload: any;
};



export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type ResourceEdge = {
  __typename?: 'ResourceEdge';
  cursor: Scalars['String'];
  node: Resource;
};

export type EndpointConnection = {
  __typename?: 'EndpointConnection';
  pageInfo: PageInfo;
  edges: Array<EndpointEdge>;
  totalCount: Scalars['Int'];
};

export type EndpointEdge = {
  __typename?: 'EndpointEdge';
  cursor: Scalars['String'];
  node: Osquery;
};

export type PackageVulnerabilityConnection = {
  __typename?: 'PackageVulnerabilityConnection';
  pageInfo: PageInfo;
  edges: Array<PackageVulnerabilityEdge>;
  totalCount: Scalars['Int'];
};

export type PackageVulnerabilityEdge = {
  __typename?: 'PackageVulnerabilityEdge';
  cursor: Scalars['String'];
  node: PackageVulnerability;
};

export type ComputeInstanceConnection = {
  __typename?: 'ComputeInstanceConnection';
  pageInfo: PageInfo;
  edges: Array<ComputeInstanceEdge>;
  totalCount: Scalars['Int'];
};

export type ComputeInstanceEdge = {
  __typename?: 'ComputeInstanceEdge';
  cursor: Scalars['String'];
  node?: Maybe<GenericComputeInstanceResource>;
};

export type OtherInventoryConnection = {
  __typename?: 'OtherInventoryConnection';
  pageInfo: PageInfo;
  edges: Array<OtherInventoryEdge>;
  totalCount: Scalars['Int'];
};

export type OtherInventoryEdge = {
  __typename?: 'OtherInventoryEdge';
  cursor: Scalars['String'];
  node: OtherInventoryItem;
};

export type VulnTargetConnection = {
  __typename?: 'VulnTargetConnection';
  pageInfo: PageInfo;
  edges: Array<VulnTargetEdge>;
  totalCount: Scalars['Int'];
};

export type VulnTargetEdge = {
  __typename?: 'VulnTargetEdge';
  cursor: Scalars['String'];
  node: VulnTargetNode;
};

export type VulnTargetNode = {
  __typename?: 'VulnTargetNode';
  resource: Resource;
  vulnSummary: VulnTargetVulnSummary;
};

export type VulnTargetVulnSummary = {
  __typename?: 'VulnTargetVulnSummary';
  totalVulnCount?: Maybe<Scalars['Int']>;
  maxSeverity?: Maybe<Scalars['Float']>;
  minSlaDeadline?: Maybe<Scalars['DateTime']>;
  totalLowSeverity?: Maybe<Scalars['Int']>;
  totalMidSeverity?: Maybe<Scalars['Int']>;
  totalHighSeverity?: Maybe<Scalars['Int']>;
};

export enum AuditTypeEnum {
  gdpr = 'gdpr',
  hipaa = 'hipaa',
  iso27001 = 'iso27001',
  pciSaqA = 'pciSaqA',
  pciSaqAEP = 'pciSaqAEP',
  pciSaqDMerchant = 'pciSaqDMerchant',
  pciSaqDSP = 'pciSaqDSP',
  soc2type1 = 'soc2type1',
  soc2type2 = 'soc2type2'
}

export enum ClientPlatform {
  darwin = 'darwin',
  linux = 'linux',
  windows = 'windows'
}

export enum TestRolloutStatus {
  past = 'past',
  current = 'current',
  inRollout = 'inRollout'
}

export enum ReportStandard {
  gdpr = 'gdpr',
  hipaa = 'hipaa',
  iso27001 = 'iso27001',
  pciSaqA = 'pciSaqA',
  pciSaqDMerchant = 'pciSaqDMerchant',
  pciSaqDSP = 'pciSaqDSP',
  pciSaqAEP = 'pciSaqAEP',
  soc2 = 'soc2',
  vanta = 'vanta'
}

export enum Feature {
  AuditJourneyAuditor = 'AuditJourneyAuditor',
  AuditJourneyCustomer = 'AuditJourneyCustomer',
  AuditorStandardExport = 'AuditorStandardExport',
  AutoEmployeeEmails = 'AutoEmployeeEmails',
  BetaAirtable = 'BetaAirtable',
  BetaBSIPolicies = 'BetaBSIPolicies',
  BetaHIPAA = 'BetaHIPAA',
  BetaKnowBe4 = 'BetaKnowBe4',
  BetaNewRiskRegister = 'BetaNewRiskRegister',
  BetaRecurringSAT = 'BetaRecurringSAT',
  BetaVOERecurrence = 'BetaVOERecurrence',
  DisableAzureDevOpsTasks = 'DisableAzureDevOpsTasks',
  DisableGitLabTasks = 'DisableGitLabTasks',
  EmployeeGroupsOnPoliciesPage = 'EmployeeGroupsOnPoliciesPage',
  GDPRStandard = 'GDPRStandard',
  ISOStandard = 'ISOStandard',
  LegacyEnableServerAgent = 'LegacyEnableServerAgent',
  LocationServicesColumnDeprecated = 'LocationServicesColumnDeprecated',
  OfficeUserSuspendedDeprecated = 'OfficeUserSuspendedDeprecated',
  OktaAllowIncompleteMetadata = 'OktaAllowIncompleteMetadata',
  PCISaqAEPStandard = 'PCISaqAEPStandard',
  PCISaqAStandard = 'PCISaqAStandard',
  PCISaqDMerchantStandard = 'PCISaqDMerchantStandard',
  PCISaqDSPStandard = 'PCISaqDSPStandard',
  RiskRegisterV3 = 'RiskRegisterV3',
  Soc2Availability = 'Soc2Availability',
  Soc2Confidentiality = 'Soc2Confidentiality'
}

export enum PermissionLevel {
  Admin = 'Admin',
  Dashboard = 'Dashboard',
  Onboarding = 'Onboarding'
}

export enum GdprRole {
  controller = 'controller',
  processor = 'processor',
  both = 'both'
}

export enum CronService {
  resourcefetcher = 'resourcefetcher',
  testrunner = 'testrunner'
}

export enum ServerOsFamily {
  Debian = 'Debian',
  Other = 'Other',
  RHEL = 'RHEL',
  Unknown = 'Unknown'
}

export enum PaymentInterval {
  month = 'month',
  year = 'year'
}

export enum TestCategory {
  ACCOUNTS_ACCESS = 'ACCOUNTS_ACCESS',
  COMPLIANCE = 'COMPLIANCE',
  DATA_STORAGE = 'DATA_STORAGE',
  INFRASTRUCTURE = 'INFRASTRUCTURE',
  IT = 'IT',
  LOGGING = 'LOGGING',
  MONITORING_ALERTS = 'MONITORING_ALERTS',
  PEOPLE = 'PEOPLE',
  POLICIES = 'POLICIES',
  RISK_ANALYSIS = 'RISK_ANALYSIS',
  SOFTWARE_DEVELOPMENT = 'SOFTWARE_DEVELOPMENT',
  VENDORS = 'VENDORS',
  VULNERABILITY_MANAGEMENT = 'VULNERABILITY_MANAGEMENT'
}

export enum EmailKey {
  AUTOMATED_EMAILS_KILL_SWITCH_KEY = 'AUTOMATED_EMAILS_KILL_SWITCH_KEY',
  AcceptPolicies = 'AcceptPolicies',
  AdminInvitation = 'AdminInvitation',
  Audit = 'Audit',
  AuditorAccessGranted = 'AuditorAccessGranted',
  AuditorAccessRevoked = 'AuditorAccessRevoked',
  DailyRedFlippedTestResults = 'DailyRedFlippedTestResults',
  DeprovisionAccounts = 'DeprovisionAccounts',
  EmployeeOnboarded = 'EmployeeOnboarded',
  EncryptReminder = 'EncryptReminder',
  Example = 'Example',
  FindMyMacReminder = 'FindMyMacReminder',
  FoundIdentities = 'FoundIdentities',
  HighPriImmediate = 'HighPriImmediate',
  InvoicePaymentFailed = 'InvoicePaymentFailed',
  InvoiceUnpaidThreatenCancel = 'InvoiceUnpaidThreatenCancel',
  LockoutContactSales = 'LockoutContactSales',
  LostIdentities = 'LostIdentities',
  NewAdminAdded = 'NewAdminAdded',
  NewCustomerLoginInvitation = 'NewCustomerLoginInvitation',
  NewDriveBackgroundChecks = 'NewDriveBackgroundChecks',
  NewMalwareDetected = 'NewMalwareDetected',
  NewTestsFailingReminder = 'NewTestsFailingReminder',
  NewUpcomingTests = 'NewUpcomingTests',
  PolicyExpiration = 'PolicyExpiration',
  QueryResults = 'QueryResults',
  ReportView = 'ReportView',
  SLADeadlineNotification = 'SLADeadlineNotification',
  SOC2ContactUs = 'SOC2ContactUs',
  SecurityTrainingRequest = 'SecurityTrainingRequest',
  UNIVERSAL_KILL_SWITCH_KEY = 'UNIVERSAL_KILL_SWITCH_KEY',
  UnlinkCredentials = 'UnlinkCredentials',
  UnlinkedCredentialsReminder = 'UnlinkedCredentialsReminder',
  VAQRequest = 'VAQRequest',
  VAQSubmission = 'VAQSubmission',
  VantaAppReminder = 'VantaAppReminder',
  VantaLoginLink = 'VantaLoginLink',
  Vulnerability = 'Vulnerability',
  WeeklyMFA = 'WeeklyMFA',
  WeeklyStatusTestResults = 'WeeklyStatusTestResults'
}

/**
 * email categories for the emailSettings page
 * if adding a type, update email-subscription.tsx
 */
export enum EmailCategoryForUnsubscribe {
  DAILY = 'DAILY',
  EVENT = 'EVENT',
  OVERVIEW = 'OVERVIEW',
  WEEKLY = 'WEEKLY'
}

/**
 * The different email queues/triggers that we have. Closely related to
 * emailCategoryForUnsubscribe, but only for automated emails.
 */
export enum TimeBasedEmailTriggers {
  monday = 'monday',
  tuesday = 'tuesday',
  wednesday = 'wednesday',
  daily = 'daily',
  dailyWithWeekends = 'dailyWithWeekends'
}

export enum FetchStatus {
  FAILED = 'FAILED',
  IN_PROGRESS = 'IN_PROGRESS',
  OBVIATED = 'OBVIATED',
  SUCCEEDED = 'SUCCEEDED'
}

export enum FetchTrigger {
  MANUAL = 'MANUAL',
  SCHEDULE = 'SCHEDULE',
  SERVICE_LINKAGE = 'SERVICE_LINKAGE'
}

/** Error filter options for getting resources */
export enum ErrorOption {
  ALL = 'ALL',
  ERROR_ONLY = 'ERROR_ONLY',
  NONERROR_ONLY = 'NONERROR_ONLY'
}

export enum TestOutcome {
  DISABLED = 'DISABLED',
  FAIL = 'FAIL',
  IN_PROGRESS = 'IN_PROGRESS',
  INVALID = 'INVALID',
  NA = 'NA',
  PASS = 'PASS'
}

export enum VaqStatus {
  open = 'open',
  submitted = 'submitted',
  submittedOnDryRun = 'submittedOnDryRun'
}

export enum FilterOperator {
  AND = 'AND',
  OR = 'OR'
}

export enum VendorDocumentType {
  assessmentFile = 'assessmentFile',
  attestationOfComplianceFile = 'attestationOfComplianceFile',
  baaFile = 'baaFile',
  dpaFile = 'dpaFile'
}

export enum IdentityProviderService {
  gsuiteadmin = 'gsuiteadmin',
  office = 'office',
  okta = 'okta'
}

export enum HrUserAction {
  linkToUser = 'linkToUser',
  markOutOfScope = 'markOutOfScope',
  unlinkUser = 'unlinkUser'
}

export enum EvidenceTimeSensitivity {
  mostRecent = 'mostRecent',
  duringAuditWindow = 'duringAuditWindow'
}

export enum SecurityTrainingCategoryId {
  general = 'general',
  gdpr = 'gdpr',
  hipaa = 'hipaa',
  pci = 'pci'
}

/** Base interface for any UserError */
export type UserError = {
  message: Scalars['String'];
};

export type CopyRoleOptions = {
  adminTaskIdsToCopy?: Maybe<Array<Scalars['String']>>;
  appendTasks?: Maybe<Scalars['Boolean']>;
  copyOnboardingTasks?: Maybe<Scalars['Boolean']>;
  copySecurityRequirements?: Maybe<Scalars['Boolean']>;
  employeeTaskIdsToCopy?: Maybe<Array<Scalars['String']>>;
};

export type UsernamePassword = {
  password: Scalars['String'];
  username: Scalars['String'];
};

export type WorkPaperTestInput = {
  commentsAuditor: Scalars['String'];
  commentsVanta: Scalars['String'];
  controlDescription: Scalars['String'];
  controlName: Scalars['String'];
  criterion: Scalars['String'];
  testApplied: Scalars['String'];
  testId: Scalars['String'];
  testResultsAuditor: Scalars['String'];
  testStatusVanta: Scalars['String'];
};

export type MonthAndYear = {
  month: Scalars['Int'];
  year: Scalars['Int'];
};

export type FileFieldName = {
  fieldName: Scalars['String'];
  file: Scalars['Upload'];
};

export type NewAdminInfo = {
  email: Scalars['String'];
  familyName: Scalars['String'];
  givenName: Scalars['String'];
};

export type RiskTaskInput = {
  id?: Maybe<Scalars['ID']>;
  text: Scalars['String'];
};

export type RoleSecurityRequirementsInput = {
  mustAcceptPolicies: Scalars['Boolean'];
  mustBeBackgroundChecked: Scalars['Boolean'];
  mustCompleteGdprSecurityTraining?: Maybe<Scalars['Boolean']>;
  mustCompleteHipaaSecurityTraining?: Maybe<Scalars['Boolean']>;
  mustCompletePciSecurityTraining?: Maybe<Scalars['Boolean']>;
  mustCompleteSecurityTraining: Scalars['Boolean'];
  mustInstallLaptopMonitoring: Scalars['Boolean'];
};

export type PlanPricesInput = {
  app?: Maybe<Scalars['Float']>;
  base?: Maybe<Scalars['Float']>;
  user?: Maybe<Scalars['Float']>;
};

export type ResourceFilters = {
  errorOption?: Maybe<ErrorOption>;
  includeDeleted?: Maybe<Scalars['Boolean']>;
  includeOutOfScope?: Maybe<Scalars['Boolean']>;
};

export type FilterParams = {
  filters: Array<Filter>;
  operator: FilterOperator;
};

export type Filter = {
  condition: FilterCondition;
  field: Scalars['String'];
};

export type FilterCondition = {
  BOOL_EQ?: Maybe<Scalars['Boolean']>;
  BOOL_NE?: Maybe<Scalars['Boolean']>;
  DATE_EQ?: Maybe<Scalars['DateTime']>;
  DATE_GT?: Maybe<Scalars['DateTime']>;
  DATE_GTE?: Maybe<Scalars['DateTime']>;
  DATE_LT?: Maybe<Scalars['DateTime']>;
  DATE_LTE?: Maybe<Scalars['DateTime']>;
  DATE_NE?: Maybe<Scalars['DateTime']>;
  FLOAT_GT?: Maybe<Scalars['Float']>;
  FLOAT_GTE?: Maybe<Scalars['Float']>;
  FLOAT_LT?: Maybe<Scalars['Float']>;
  FLOAT_LTE?: Maybe<Scalars['Float']>;
  INT_EQ?: Maybe<Scalars['Int']>;
  INT_GT?: Maybe<Scalars['Int']>;
  INT_GTE?: Maybe<Scalars['Int']>;
  INT_LT?: Maybe<Scalars['Int']>;
  INT_LTE?: Maybe<Scalars['Int']>;
  INT_NE?: Maybe<Scalars['Int']>;
  IS_NULL?: Maybe<Scalars['Boolean']>;
  STRING_EQ?: Maybe<Scalars['String']>;
  STRING_INCLUDES?: Maybe<Scalars['String']>;
  STRING_NE?: Maybe<Scalars['String']>;
};

export type TestVersionInput = {
  major: Scalars['Int'];
  minor: Scalars['Int'];
};

export type TestVersionAndIdInput = {
  testId: Scalars['String'];
  version: TestVersionInput;
};

export type SortParams = {
  direction: Scalars['Int'];
  field: Scalars['String'];
};

export type ControlsFilter = {
  standard?: Maybe<ReportStandard>;
};

/** _addRoleToUsers */
export type AddRoleToUsersInput = {
  userIds: Array<Scalars['ID']>;
  domainId: Scalars['String'];
  roleId?: Maybe<Scalars['String']>;
  omitChecklist: Scalars['Boolean'];
};

export type AddRoleToUsersPayload = MutationPayload & {
  __typename?: 'AddRoleToUsersPayload';
  status?: Maybe<MutationStatus>;
  roleCompletionRecords: Array<Maybe<RoleCompletionRecord>>;
};

/** createNewCustomerAndInvoice */
export type CreateNewCustomerAndInvoiceInput = {
  billingEmail: Scalars['String'];
  daysUntilDue?: Maybe<Scalars['Int']>;
  domainName: Scalars['String'];
  domainStandards: Array<ReportStandard>;
  intervalCount?: Maybe<Scalars['Int']>;
  paymentInterval: PaymentInterval;
  pricesInDollarsPerPaymentInterval: PlanPricesInput;
  salesforceId: Scalars['String'];
  trialInDays?: Maybe<Scalars['Int']>;
};

export type CreateNewCustomerAndInvoicePayload = MutationPayload & {
  __typename?: 'CreateNewCustomerAndInvoicePayload';
  status?: Maybe<MutationStatus>;
};

/** optIntoStandard */
export type OptIntoStandardInput = {
  standard: ReportStandard;
};

export type OptIntoStandardPayload = MutationPayload & {
  __typename?: 'OptIntoStandardPayload';
  status?: Maybe<MutationStatus>;
};

/** optOutOfStandard */
export type OptOutOfStandardInput = {
  standard: ReportStandard;
};

export type OptOutOfStandardPayload = MutationPayload & {
  __typename?: 'OptOutOfStandardPayload';
  status?: Maybe<MutationStatus>;
};

/** manuallyOptIntoTests */
export type ManuallyOptIntoTestsInput = {
  testIds: Array<Scalars['String']>;
};

export type ManuallyOptIntoTestsPayload = MutationPayload & {
  __typename?: 'ManuallyOptIntoTestsPayload';
  status?: Maybe<MutationStatus>;
};

/** removeManualOptInFromTests */
export type RemoveManualOptInFromTestsInput = {
  testIds: Array<Scalars['String']>;
};

export type RemoveManualOptInFromTestsPayload = MutationPayload & {
  __typename?: 'RemoveManualOptInFromTestsPayload';
  status?: Maybe<MutationStatus>;
};

/** input deleteVendorDocument */
export type DeleteVendorDocumentInput = {
  documentType: VendorDocumentType;
  vendorDocumentId: Scalars['String'];
  vendorId: Scalars['String'];
};

export type DeleteVendorDocumentPayload = MutationPayload & {
  __typename?: 'DeleteVendorDocumentPayload';
  status?: Maybe<MutationStatus>;
};

/** linkUserWithHrUser */
export type LinkUserWithHrUserInput = {
  hrUserUniqueId: Scalars['String'];
  userId: Scalars['ID'];
};

export type LinkUserWithHrUserPayload = MutationPayload & {
  __typename?: 'LinkUserWithHrUserPayload';
  status?: Maybe<MutationStatus>;
};

/** bulkApplyHrUserActions */
export type HrUserActionInput = {
  action: HrUserAction;
  hrUserUniqueId: Scalars['String'];
  userId?: Maybe<Scalars['ID']>;
};

export type BulkApplyHrUserActionsInput = {
  actions: Array<HrUserActionInput>;
};

export type BulkApplyHrUserActionsPayload = MutationPayload & {
  __typename?: 'BulkApplyHrUserActionsPayload';
  status?: Maybe<MutationStatus>;
};

/** deleteUser */
export type DeleteUserInput = {
  userId: Scalars['ID'];
};

export type DeleteUserPayload = MutationPayload & {
  __typename?: 'DeleteUserPayload';
  status?: Maybe<MutationStatus>;
  message?: Maybe<Scalars['String']>;
};

/** uploadEvidence */
export type UploadEvidenceInput = {
  evidenceRequestId: Scalars['String'];
  file: Scalars['Upload'];
  description?: Maybe<Scalars['String']>;
  effectiveAt?: Maybe<Scalars['DateTime']>;
  filename?: Maybe<Scalars['String']>;
};

export type UploadEvidencePayload = MutationPayload & {
  __typename?: 'UploadEvidencePayload';
  status?: Maybe<MutationStatus>;
  uploadedDocument?: Maybe<UploadedDocument>;
};

/** updateDocumentEvidenceMetadata */
export type UpdateDocumentEvidenceMetadataInput = {
  uploadedDocumentId: Scalars['String'];
  filename: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  effectiveAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateDocumentEvidenceMetadataPayload = MutationPayload & {
  __typename?: 'UpdateDocumentEvidenceMetadataPayload';
  status?: Maybe<MutationStatus>;
  uploadedDocument?: Maybe<UploadedDocument>;
};

/** addLinkEvidence */
export type AddLinkEvidenceInput = {
  evidenceRequestId: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  url: Scalars['String'];
  effectiveAt?: Maybe<Scalars['DateTime']>;
};

export type AddLinkEvidencePayload = MutationPayload & {
  __typename?: 'AddLinkEvidencePayload';
  status?: Maybe<MutationStatus>;
  linkEvidence?: Maybe<LinkEvidence>;
};

/** updateLinkEvidence */
export type UpdateLinkEvidenceInput = {
  linkEvidenceId: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  url: Scalars['String'];
  effectiveAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateLinkEvidencePayload = MutationPayload & {
  __typename?: 'UpdateLinkEvidencePayload';
  status?: Maybe<MutationStatus>;
  linkEvidence?: Maybe<LinkEvidence>;
};

/** deleteLinkEvidence */
export type DeleteLinkEvidenceInput = {
  linkEvidenceId: Scalars['String'];
};

export type DeleteLinkEvidencePayload = MutationPayload & {
  __typename?: 'DeleteLinkEvidencePayload';
  status?: Maybe<MutationStatus>;
  linkEvidence?: Maybe<LinkEvidence>;
};

/** setEvidenceRequestIgnoredStatus */
export type SetEvidenceRequestIgnoredStatusInput = {
  evidenceRequestId: Scalars['String'];
  reason?: Maybe<Scalars['String']>;
};

export type SetEvidenceRequestIgnoredStatusPayload = MutationPayload & {
  __typename?: 'SetEvidenceRequestIgnoredStatusPayload';
  status?: Maybe<MutationStatus>;
  ignoredStatus?: Maybe<EvidenceRequestIgnoredStatus>;
};

/** setTestStatus */
export type SetTestStatusToAvailableInput = {
  tests: Array<TestVersionAndIdInput>;
  skipRolloutPeriod?: Maybe<Scalars['Boolean']>;
};

export type SetTestStatusToAvailablePayload = MutationPayload & {
  __typename?: 'SetTestStatusToAvailablePayload';
  status?: Maybe<MutationStatus>;
};

export type SetTestStatusToPendingInput = {
  testIds: Array<Scalars['String']>;
};

export type SetTestStatusToPendingPayload = MutationPayload & {
  __typename?: 'SetTestStatusToPendingPayload';
  status?: Maybe<MutationStatus>;
};

export type MakeAllTestsAvailablePayload = MutationPayload & {
  __typename?: 'MakeAllTestsAvailablePayload';
  status?: Maybe<MutationStatus>;
};

export type SyncTestsWithAllAutomatedTestsPayload = MutationPayload & {
  __typename?: 'SyncTestsWithAllAutomatedTestsPayload';
  status?: Maybe<MutationStatus>;
};

export type SyncRolloutOptionsWithTestAvailabilityPayload = MutationPayload & {
  __typename?: 'SyncRolloutOptionsWithTestAvailabilityPayload';
  status?: Maybe<MutationStatus>;
};

/** releaseBetaFeature */
export type ReleaseBetaFeatureInput = {
  feature: Feature;
};

export type ReleaseBetaFeaturePayload = MutationPayload & {
  __typename?: 'ReleaseBetaFeaturePayload';
  status?: Maybe<MutationStatus>;
};

/** unreleaseBetaFeature */
export type UnreleaseBetaFeatureInput = {
  feature: Feature;
};

export type UnreleaseBetaFeaturePayload = MutationPayload & {
  __typename?: 'UnreleaseBetaFeaturePayload';
  status?: Maybe<MutationStatus>;
};

/** generateSignedUrl */
export type GenerateSignedUrlInput = {
  key: Scalars['String'];
};

export type GenerateSignedUrlPayload = MutationPayload & {
  __typename?: 'GenerateSignedUrlPayload';
  status?: Maybe<MutationStatus>;
  url?: Maybe<Scalars['String']>;
};

/** List of all graphql mutations */
export type Mutation = {
  __typename?: 'Mutation';
  _addRoleToUsers: AddRoleToUsersPayload;
  _bulkSetEmployeeComputerDescription: Array<Scalars['String']>;
  _consolidateUserRecords: Scalars['String'];
  _makeAllTestsAvailable: MakeAllTestsAvailablePayload;
  _manuallyAttemptHrUserLinkForDomain?: Maybe<ManuallyAttemptHrUserLinkForDomainPayload>;
  _manuallyRefreshOnboardingCompletion: Scalars['Boolean'];
  _markTestHistoryAsInvalid?: Maybe<MarkTestHistoryAsInvalidPayload>;
  _migrateVantaOnboardingInfoBetweenUsers: Scalars['String'];
  /**
   * Remove policy acceptance records for the most recent
   * versions of the given policy types
   */
  _removePolicyAcceptances?: Maybe<RemovePolicyAcceptancesPayload>;
  _removeRoleFromUsers?: Maybe<RemoveRoleFromUsersPayload>;
  /**
   * For setting Office 365 user scopes as a group, filtering by the domain name
   * suffic to their User Principal Names (UPNs). Returns the number of matched
   * users. Manual operation.
   */
  _setOfficeUserScopesByUPNDomain?: Maybe<Scalars['Int']>;
  _setUserSecurityTrainingVideoCompletion?: Maybe<SetUserSecurityTrainingVideoCompletionPayload>;
  _syncRolloutOptionsWithTestAvailability: SyncRolloutOptionsWithTestAvailabilityPayload;
  _syncTestsWithAllAutomatedTests: SyncTestsWithAllAutomatedTestsPayload;
  acceptPolicies?: Maybe<Scalars['Boolean']>;
  acceptTermsForUser?: Maybe<Scalars['Boolean']>;
  acknowledgeNoVendorAccessForOffboarding: VendorAccessRemoval;
  acknowledgeNoVendorAccessForOffboardingBulk: Array<VendorAccessRemoval>;
  acknowledgeOffboardingComplete?: Maybe<User>;
  addAuditorToAudit: Audit;
  addAuditorToAuditByEmail: Audit;
  addLinkEvidence: AddLinkEvidencePayload;
  addOtherInventoryItem: OtherInventoryItem;
  addPolicyDocStub: PolicyDocStub;
  addRiskItem?: Maybe<RiskEvaluation>;
  addRiskMitigationTask?: Maybe<RiskMitigationTask>;
  addRiskQuestion: Scalars['Boolean'];
  addRiskScenario: RiskScenario;
  addRiskTask: RiskTask;
  addRoleToUser?: Maybe<RoleCompletionRecord>;
  addTaskToRole: RoleTask;
  addVendor?: Maybe<Vendor>;
  assumeDomainAdmin: Scalars['Boolean'];
  assumeUser?: Maybe<Scalars['Boolean']>;
  blacklistForCronFilter?: Maybe<CronFilter>;
  bulkApplyHrUserActions: BulkApplyHrUserActionsPayload;
  bulkAssignRepoOwner: Array<Scalars['String']>;
  bulkMarkUnlinkedAccountsAsNonHuman: Array<Scalars['String']>;
  /**
   * bulkSetScope sets the scope of some set of resources based on
   * a filter.
   */
  bulkSetScope?: Maybe<BulkSetScopePayload>;
  bulkWhitelistVulnSLAMissByOsquery: Scalars['Boolean'];
  bumpEpoch?: Maybe<Scalars['Int']>;
  bumpEpochs: Array<Maybe<Scalars['Int']>>;
  changeAuditDates: Scalars['Boolean'];
  changeRoleName?: Maybe<Role>;
  changeUserStartDate?: Maybe<User>;
  clearDismissStatusForRiskQuestion: Scalars['Boolean'];
  clearEmailCircuitBreakers?: Maybe<Scalars['Boolean']>;
  clearEvidenceRequestRenewalDate?: Maybe<ClearEvidenceRequestRenewalDatePayload>;
  completeRiskTask?: Maybe<RiskTask>;
  completeRoleTask?: Maybe<RoleTaskCompletionRecord>;
  /** For employees completing custom tasks in onboarding */
  completeRoleTaskByEmployee?: Maybe<RoleTaskCompletionRecord>;
  copyRole: Scalars['Boolean'];
  copySubmittedVAQAnswers: Scalars['String'];
  createAuditEngagement?: Maybe<CreateAuditEngagementPayload>;
  createNewCustomerAndInvoice: CreateNewCustomerAndInvoicePayload;
  createPresentedReport?: Maybe<Domain>;
  createRole: Role;
  createUser: User;
  createWorkPaper: AuditorDocument;
  deactivateUnlinkedCloudAgents: Scalars['Int'];
  deleteAudit?: Maybe<Scalars['Boolean']>;
  deleteAuditEngagement?: Maybe<DeleteAuditEngagementPayload>;
  deleteCredentialAndData?: Maybe<Credentials>;
  deleteDomain?: Maybe<Scalars['Boolean']>;
  deleteLinkEvidence: DeleteLinkEvidencePayload;
  deleteNewCustomer?: Maybe<NewCustomer>;
  deleteOtherInventoryItem: Scalars['Boolean'];
  deletePoliciesByType: Scalars['Boolean'];
  deleteQuery: Scalars['Boolean'];
  deleteRiskEvaluation?: Maybe<RiskEvaluation>;
  deleteRiskMitigationTask?: Maybe<RiskMitigationTask>;
  deleteRiskQuestion: Scalars['Boolean'];
  deleteRiskScenario: Scalars['Boolean'];
  deleteSecurityTrainingDocument?: Maybe<DeleteSecurityTrainingDocumentPayload>;
  deleteSecurityTrainingsByDomain?: Maybe<DeleteSecurityTrainingsByDomainPayload>;
  deleteUploadedDocument?: Maybe<UploadedDocument>;
  deleteUser: DeleteUserPayload;
  deleteVendorDocument: DeleteVendorDocumentPayload;
  /**
   * Grant deprecation feature to all domains. Note: feature must correspond to a
   * description in featureDescriptions.ts with the kind FeatureKind.Deprecation.
   */
  deprecateFeature: Scalars['Boolean'];
  disableMonitoringOnTest: Scalars['Boolean'];
  disableMonitoringOnTestEntity: Scalars['Boolean'];
  disableOsqueries?: Maybe<Scalars['Boolean']>;
  disableOsqueriesByID?: Maybe<Scalars['Boolean']>;
  discardBufferedEmails: Scalars['Boolean'];
  dismissRisk?: Maybe<RiskDismissal>;
  dismissRiskQuestion?: Maybe<RiskQuestionForDomain>;
  dismissRiskTask?: Maybe<RiskTask>;
  editPresentedReport?: Maybe<Scalars['Boolean']>;
  editWorkPaper: Scalars['Boolean'];
  emailKillSwitchIndividualStop: EmailKey;
  emailKillSwitchReleaseIndividualStop?: Maybe<EmailKey>;
  emailKillSwitchReleaseUniversalStop?: Maybe<EmailKey>;
  emailKillSwitchUniversalStop: EmailKey;
  ensureOktaApp: Scalars['Boolean'];
  evaluateRiskItem?: Maybe<RiskEvaluation>;
  generatePolicyPacket: Policy;
  generateSignedUrl: GenerateSignedUrlPayload;
  grantAuditorDomainAccess?: Maybe<Auditor>;
  grantBetaFeatureAccess: Scalars['String'];
  /** Grant deprecation feature to specific domains. */
  grantLegacyFeatureAccess: Scalars['String'];
  gsuitePermissionOptBackIn: Scalars['Boolean'];
  gsuitePermissionOptOut: Scalars['Boolean'];
  linkUserWithHrUser: LinkUserWithHrUserPayload;
  lockDomain?: Maybe<Domain>;
  lockoutContactSales: Scalars['Boolean'];
  makeUserAuditor: Scalars['Boolean'];
  manuallyOptIntoTests?: Maybe<ManuallyOptIntoTestsPayload>;
  manuallyRemoveAccountsAndLinkedAccounts: Scalars['Boolean'];
  markInterestForStandard?: Maybe<MarkInterestForStandardPayload>;
  markRiskMitigationTaskCompleted?: Maybe<RiskMitigationTask>;
  mergeUserAfterDomainMigration?: Maybe<Scalars['String']>;
  migratePolicyAcceptances: Array<Scalars['String']>;
  optIntoStandard: OptIntoStandardPayload;
  optOutOfStandard: OptOutOfStandardPayload;
  publishRoleUpdates: Role;
  recomputeCachedFields: Scalars['Boolean'];
  reenableCredentials: Scalars['Boolean'];
  reenableMonitoringOnTest: Scalars['Boolean'];
  reenableMonitoringOnTestEntity: Scalars['Boolean'];
  registerDevice: Scalars['Boolean'];
  registerDeviceBySecret: Scalars['Boolean'];
  /** Grant a beta feature to all domains; used for project GA. */
  releaseBetaFeature: ReleaseBetaFeaturePayload;
  removeAuditorFromAudit?: Maybe<Audit>;
  removeManualOptInFromTests?: Maybe<RemoveManualOptInFromTestsPayload>;
  removePolicy: Policy;
  removeRiskDismissal?: Maybe<RiskDismissal>;
  removeRole?: Maybe<Role>;
  removeRoleFromUser?: Maybe<RoleCompletionRecord>;
  removeSecurityTrainingPreference: Scalars['Boolean'];
  removeSecurityTrainingPreferenceByTrainingId?: Maybe<RemoveSecurityTrainingPreferenceByTrainingIdPayload>;
  removeTaskFromRole?: Maybe<RoleTask>;
  removeUser: User;
  removeVendor: Scalars['Boolean'];
  renewPolicy: Policy;
  requestVAQSubmission: Scalars['Boolean'];
  resetVulnerablePackageSLA: Scalars['Boolean'];
  revokeAuditorDomainAccess: Scalars['Boolean'];
  revokeBetaFeatureAccess: Scalars['String'];
  rotateKey: Scalars['String'];
  rrv3AddAsset?: Maybe<RiskAssessmentAsset>;
  rrv3AddScenario?: Maybe<RiskAssessmentScenario>;
  rrv3AddTask?: Maybe<RiskAssessmentTask>;
  rrv3CompleteTask?: Maybe<RiskAssessmentTask>;
  rrv3DeleteAsset?: Maybe<RiskAssessmentAsset>;
  rrv3DeleteScenario?: Maybe<RiskAssessmentScenario>;
  rrv3DeleteTask?: Maybe<RiskAssessmentTask>;
  scheduleAudit: Audit;
  scheduleQuery: Scalars['Boolean'];
  sendAdminInvitations: Scalars['Boolean'];
  sendBufferedEmails: Scalars['Boolean'];
  sendHighPriEmail: Scalars['Boolean'];
  sendLoginInvitationToNewCustomer: Scalars['Boolean'];
  sendLoginLink?: Maybe<Scalars['Boolean']>;
  sendReminderEmail?: Maybe<Scalars['String']>;
  sendTestResultsEmailWithoutFlippingTimestamps?: Maybe<Scalars['String']>;
  sendWeeklyMFAEmail?: Maybe<Scalars['String']>;
  sendWeeklyStatusTestResultsResultsEmail?: Maybe<Scalars['String']>;
  setAccessSlas: AccessSlas;
  setAdminTaskIdsForRole?: Maybe<Role>;
  setAuditCompletionDate?: Maybe<SetAuditCompletionDatePayload>;
  setAuditObservationPeriod?: Maybe<SetAuditObservationPeriodPayload>;
  setBackgroundCheckCutoff: Domain;
  setBackgroundCheckMapping: Scalars['String'];
  setBackgroundCheckToIgnored: Scalars['String'];
  setBillingEmail: Domain;
  setCredentialMetadata?: Maybe<Domain>;
  setCredentials?: Maybe<Domain>;
  setDomainBusinessOrComplianceInfo?: Maybe<Domain>;
  setDomainDisplayName?: Maybe<Domain>;
  setDomainName?: Maybe<Domain>;
  setEmployeeDigestNotificationFrequency?: Maybe<SetEmployeeDigestNotificationFrequencyPayload>;
  setEmployeeTaskIdsForRole?: Maybe<Role>;
  setEvidenceRequestIgnoredStatus: SetEvidenceRequestIgnoredStatusPayload;
  setEvidenceRequestReminderWindow?: Maybe<SetEvidenceRequestReminderWindowPayload>;
  setEvidenceRequestRenewalCadence?: Maybe<SetEvidenceRequestRenewalCadencePayload>;
  setEvidenceRequestRenewalDate?: Maybe<SetEvidenceRequestRenewalDatePayload>;
  setKnowBe4TrainingTypes?: Maybe<SetKnowBe4TrainingTypesPayload>;
  setMdmSlas?: Maybe<Domain>;
  setNonHuman?: Maybe<User>;
  setOnboardingAdmin?: Maybe<User>;
  setOnboardingSla?: Maybe<Domain>;
  setOsqueryActiveStatus?: Maybe<Osquery>;
  setOsqueryTarget?: Maybe<Scalars['Boolean']>;
  setOsqueryVantaAttribute: Array<VantaAttribute>;
  setOsqueryVantaTarget?: Maybe<Scalars['Boolean']>;
  setOtherInventoryItemName: Scalars['Boolean'];
  setOtherInventoryItemVantaAttribute: Array<VantaAttribute>;
  setPermissionLevel?: Maybe<User>;
  setPolicyAnswers?: Maybe<Scalars['Boolean']>;
  setPolicyApproval?: Maybe<Policy>;
  setPreferredSecurityTraining: Domain;
  setResourceScope: Scalars['Boolean'];
  setResourceVantaAttribute: Array<VantaAttribute>;
  setRoleSecurityRequirements?: Maybe<Role>;
  setSecurityIssueSlas: SecurityIssueSlas;
  setSecurityTrainingInstructions: Scalars['Boolean'];
  setSecurityTrainingInstructionsByTrainingId?: Maybe<SetSecurityTrainingInstructionsByTrainingIdPayload>;
  setSecurityTrainingUrl: Scalars['Boolean'];
  setSecurityTrainingUrlByTrainingId?: Maybe<SetSecurityTrainingUrlByTrainingIdPayload>;
  setSeenRiskRegisterSplash: User;
  setServiceAccountMapping: Scalars['String'];
  setServiceAccountMappings: Array<Scalars['String']>;
  /** @deprecated Use bulkSetScope instead */
  setSpecificResourceKindScope: Scalars['Boolean'];
  setTargetImageScanTagForRepository: Scalars['Boolean'];
  setTestStatusToAvailable: SetTestStatusToAvailablePayload;
  setTestStatusToPending: SetTestStatusToPendingPayload;
  setUserEmailSubscriptionSetting?: Maybe<UserEmailSubscriptionSetting>;
  setUserName?: Maybe<User>;
  setUserSecurityTrainingAttestation?: Maybe<UploadedDocument>;
  setUserSecurityTrainingVideoCompletion: Scalars['Boolean'];
  setVAQAnswers: PublicVaqAnswers;
  setVendor: Vendor;
  setVulnerabilitySlas: VulnerabilitySlas;
  soc2ContactUs: Scalars['Boolean'];
  startAuditWithoutCharging: Audit;
  submitPolicyWithAnswers: SubmitPolicyResult;
  submitVAQ: Scalars['Boolean'];
  submitVAQWithComplianceFile: Scalars['Boolean'];
  syncStripeSubscription: Scalars['String'];
  testCredentials: TestCredentialsResult;
  testResultKillSwitchIndividualStop: Scalars['String'];
  testResultKillSwitchReleaseIndividualStop?: Maybe<Scalars['String']>;
  testResultKillSwitchReleaseUniversalStop?: Maybe<Scalars['String']>;
  testResultKillSwitchUniversalStop: Scalars['String'];
  triggerDomainAgnosticResourceFetch: Scalars['String'];
  triggerResourceFetch: Scalars['String'];
  triggerResourceFetchesByService: Array<Scalars['String']>;
  triggerTestRefresh: Array<Scalars['String']>;
  triggerTestRuns: Array<Scalars['String']>;
  triggerTestsForAllCustomerDomains: Array<Scalars['String']>;
  unapprovePolicy: Policy;
  unassumeUser?: Maybe<Scalars['Boolean']>;
  unblacklistForCronFilter?: Maybe<CronFilter>;
  unlinkCredentials: Domain;
  unlockDomain?: Maybe<Domain>;
  /** Revoke beta feature access for all domains. */
  unreleaseBetaFeature: UnreleaseBetaFeaturePayload;
  unremoveUser?: Maybe<User>;
  unwhitelistForCronFilter?: Maybe<CronFilter>;
  updateCard: Domain;
  updateCloudProviderId: Scalars['Int'];
  updateDocumentEvidenceMetadata: UpdateDocumentEvidenceMetadataPayload;
  updateLinkEvidence: UpdateLinkEvidencePayload;
  updateOsqueryEndpoint?: Maybe<Osquery>;
  updatePaymentInterval: Domain;
  updatePaymentMethod: Domain;
  updateRiskQuestion: Scalars['Boolean'];
  updateRiskScenario?: Maybe<RiskScenario>;
  updateRiskTask?: Maybe<RiskTask>;
  /** Update reason within the audit log for a deactivated test. */
  updateTestDeactivatedReason?: Maybe<UpdateTestDeactivatedReasonPayload>;
  uploadEvidence: UploadEvidencePayload;
  whitelistAddIdentifierBulk: Array<TestResult>;
  whitelistForCronFilter?: Maybe<CronFilter>;
  whitelistRemoveIdentifier: Array<TestResult>;
  whitelistTest: Array<TestResult>;
  whitelistVulnerabilityForAllRepositories: Array<Scalars['String']>;
};


/** List of all graphql mutations */
export type Mutation_AddRoleToUsersArgs = {
  input: AddRoleToUsersInput;
};


/** List of all graphql mutations */
export type Mutation_BulkSetEmployeeComputerDescriptionArgs = {
  domainId: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  dryRun?: Maybe<Scalars['Boolean']>;
};


/** List of all graphql mutations */
export type Mutation_ConsolidateUserRecordsArgs = {
  domainId: Scalars['ID'];
  usersToRemove: Array<Scalars['String']>;
  userToKeep: Scalars['String'];
  dryRun: Scalars['Boolean'];
};


/** List of all graphql mutations */
export type Mutation_ManuallyAttemptHrUserLinkForDomainArgs = {
  input: ManuallyAttemptHrUserLinkForDomainInput;
};


/** List of all graphql mutations */
export type Mutation_ManuallyRefreshOnboardingCompletionArgs = {
  domainId: Scalars['ID'];
};


/** List of all graphql mutations */
export type Mutation_MarkTestHistoryAsInvalidArgs = {
  input: MarkTestHistoryAsInvalidInput;
};


/** List of all graphql mutations */
export type Mutation_MigrateVantaOnboardingInfoBetweenUsersArgs = {
  domainId: Scalars['ID'];
  fromUserId?: Maybe<Scalars['String']>;
  toUserId?: Maybe<Scalars['String']>;
  fromEmail?: Maybe<Scalars['String']>;
  toEmail?: Maybe<Scalars['String']>;
};


/** List of all graphql mutations */
export type Mutation_RemovePolicyAcceptancesArgs = {
  input: RemovePolicyAcceptancesInput;
};


/** List of all graphql mutations */
export type Mutation_RemoveRoleFromUsersArgs = {
  input: RemoveRoleFromUsersInput;
};


/** List of all graphql mutations */
export type Mutation_SetOfficeUserScopesByUpnDomainArgs = {
  domainId: Scalars['ID'];
  upnDomain: Scalars['String'];
  exclude: Scalars['Boolean'];
};


/** List of all graphql mutations */
export type Mutation_SetUserSecurityTrainingVideoCompletionArgs = {
  input: SetUserSecurityTrainingVideoCompletionInput;
};


/** List of all graphql mutations */
export type MutationAcceptPoliciesArgs = {
  policyIds: Array<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
};


/** List of all graphql mutations */
export type MutationAcceptTermsForUserArgs = {
  userId?: Maybe<Scalars['ID']>;
  termsVersion?: Maybe<Scalars['Int']>;
};


/** List of all graphql mutations */
export type MutationAcknowledgeNoVendorAccessForOffboardingArgs = {
  employeeId: Scalars['String'];
  vendorId: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationAcknowledgeNoVendorAccessForOffboardingBulkArgs = {
  employeeId: Scalars['String'];
  vendorIds: Array<Scalars['String']>;
};


/** List of all graphql mutations */
export type MutationAcknowledgeOffboardingCompleteArgs = {
  employeeId: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationAddAuditorToAuditArgs = {
  domainId: Scalars['ID'];
  auditId: Scalars['String'];
  auditorUserId: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationAddAuditorToAuditByEmailArgs = {
  email: Scalars['String'];
  auditId: Scalars['String'];
  grantAccess?: Maybe<Scalars['Boolean']>;
};


/** List of all graphql mutations */
export type MutationAddLinkEvidenceArgs = {
  domainId?: Maybe<Scalars['ID']>;
  input: AddLinkEvidenceInput;
};


/** List of all graphql mutations */
export type MutationAddOtherInventoryItemArgs = {
  domainId?: Maybe<Scalars['ID']>;
};


/** List of all graphql mutations */
export type MutationAddPolicyDocStubArgs = {
  domainId: Scalars['ID'];
  title: Scalars['String'];
  description: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationAddRiskItemArgs = {
  riskId: Scalars['String'];
  itemName: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationAddRiskMitigationTaskArgs = {
  riskCategoryId: Scalars['String'];
  description: Scalars['String'];
  assignee?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['DateTime']>;
};


/** List of all graphql mutations */
export type MutationAddRiskQuestionArgs = {
  text: Scalars['String'];
  category: Scalars['String'];
  affirmative: Scalars['Boolean'];
};


/** List of all graphql mutations */
export type MutationAddRiskScenarioArgs = {
  domainId?: Maybe<Scalars['ID']>;
  questionId?: Maybe<Scalars['String']>;
  text: Scalars['String'];
  likelihood: Scalars['Int'];
  impact: Scalars['Int'];
  existingTasks: Array<RiskTaskInput>;
  newTasks: Array<RiskTaskInput>;
};


/** List of all graphql mutations */
export type MutationAddRiskTaskArgs = {
  domainId?: Maybe<Scalars['ID']>;
  text: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationAddRoleToUserArgs = {
  userId: Scalars['ID'];
  roleId?: Maybe<Scalars['String']>;
  omitChecklist: Scalars['Boolean'];
};


/** List of all graphql mutations */
export type MutationAddTaskToRoleArgs = {
  fileInputLabel?: Maybe<Scalars['String']>;
  textInputLabel?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  roleId: Scalars['String'];
  title: Scalars['String'];
  taskIdToReplace?: Maybe<Scalars['String']>;
  isAdminTask: Scalars['Boolean'];
};


/** List of all graphql mutations */
export type MutationAddVendorArgs = {
  domainId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  url: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationAssumeDomainAdminArgs = {
  domainId: Scalars['ID'];
};


/** List of all graphql mutations */
export type MutationAssumeUserArgs = {
  email: Scalars['String'];
  writeAccess: Scalars['Boolean'];
  isAuditor?: Maybe<Scalars['Boolean']>;
};


/** List of all graphql mutations */
export type MutationBlacklistForCronFilterArgs = {
  domainId?: Maybe<Scalars['ID']>;
  item?: Maybe<Scalars['String']>;
  queue: CronService;
};


/** List of all graphql mutations */
export type MutationBulkApplyHrUserActionsArgs = {
  domainId?: Maybe<Scalars['ID']>;
  input: BulkApplyHrUserActionsInput;
};


/** List of all graphql mutations */
export type MutationBulkAssignRepoOwnerArgs = {
  domainId: Scalars['ID'];
  userId: Scalars['ID'];
  dryRun?: Maybe<Scalars['Boolean']>;
};


/** List of all graphql mutations */
export type MutationBulkMarkUnlinkedAccountsAsNonHumanArgs = {
  domainId: Scalars['ID'];
  service: Scalars['String'];
  dryRun?: Maybe<Scalars['Boolean']>;
};


/** List of all graphql mutations */
export type MutationBulkSetScopeArgs = {
  input: BulkSetScopeInput;
};


/** List of all graphql mutations */
export type MutationBulkWhitelistVulnSlaMissByOsqueryArgs = {
  domainId?: Maybe<Scalars['ID']>;
  osqueryId: Scalars['String'];
  reason: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationBumpEpochArgs = {
  nodeKey: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationBumpEpochsArgs = {
  nodeKeys: Array<Scalars['String']>;
};


/** List of all graphql mutations */
export type MutationChangeAuditDatesArgs = {
  auditId: Scalars['ID'];
  auditStart: Scalars['Float'];
  auditLengthDays: Scalars['Int'];
  domainId?: Maybe<Scalars['ID']>;
};


/** List of all graphql mutations */
export type MutationChangeRoleNameArgs = {
  roleId: Scalars['String'];
  name: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationChangeUserStartDateArgs = {
  userId: Scalars['ID'];
  startDate: Scalars['DateTime'];
};


/** List of all graphql mutations */
export type MutationClearDismissStatusForRiskQuestionArgs = {
  domainId?: Maybe<Scalars['ID']>;
  questionId: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationClearEvidenceRequestRenewalDateArgs = {
  input: ClearEvidenceRequestRenewalDateInput;
};


/** List of all graphql mutations */
export type MutationCompleteRiskTaskArgs = {
  domainId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
};


/** List of all graphql mutations */
export type MutationCompleteRoleTaskArgs = {
  file?: Maybe<Scalars['Upload']>;
  fileTitle?: Maybe<Scalars['String']>;
  userId: Scalars['ID'];
  taskCompletionRecordId: Scalars['String'];
  roleCompletionRecordId: Scalars['String'];
  requiredInfo?: Maybe<Scalars['String']>;
  policyIds?: Maybe<Array<Scalars['String']>>;
};


/** List of all graphql mutations */
export type MutationCompleteRoleTaskByEmployeeArgs = {
  file?: Maybe<Scalars['Upload']>;
  fileTitle?: Maybe<Scalars['String']>;
  taskCompletionRecordId: Scalars['String'];
  requiredInfo?: Maybe<Scalars['String']>;
};


/** List of all graphql mutations */
export type MutationCopyRoleArgs = {
  sourceRoleId: Scalars['String'];
  targetRoleIds: Array<Scalars['String']>;
  options?: Maybe<CopyRoleOptions>;
};


/** List of all graphql mutations */
export type MutationCopySubmittedVaqAnswersArgs = {
  submittedAnswersUUID: Scalars['String'];
  unsubmittedUUID: Scalars['String'];
  overrideDifferentURLs?: Maybe<Scalars['Boolean']>;
};


/** List of all graphql mutations */
export type MutationCreateAuditEngagementArgs = {
  input: CreateAuditEngagementInput;
};


/** List of all graphql mutations */
export type MutationCreateNewCustomerAndInvoiceArgs = {
  input: CreateNewCustomerAndInvoiceInput;
};


/** List of all graphql mutations */
export type MutationCreatePresentedReportArgs = {
  domainId?: Maybe<Scalars['ID']>;
  password: Scalars['String'];
  showSuccessOnly: Scalars['Boolean'];
  standard: ReportStandard;
  viewerCompany: Scalars['String'];
  skipNotificationOnView?: Maybe<Scalars['Boolean']>;
};


/** List of all graphql mutations */
export type MutationCreateRoleArgs = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  roleIdToCopy?: Maybe<Scalars['String']>;
};


/** List of all graphql mutations */
export type MutationCreateUserArgs = {
  domainId?: Maybe<Scalars['ID']>;
  email: Scalars['String'];
  familyName: Scalars['String'];
  givenName?: Maybe<Scalars['String']>;
  hrUserUniqueId?: Maybe<Scalars['String']>;
  roleId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
};


/** List of all graphql mutations */
export type MutationCreateWorkPaperArgs = {
  domainId: Scalars['ID'];
  customerDomainId: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};


/** List of all graphql mutations */
export type MutationDeactivateUnlinkedCloudAgentsArgs = {
  domainId: Scalars['ID'];
  createdBefore: Scalars['DateTime'];
};


/** List of all graphql mutations */
export type MutationDeleteAuditArgs = {
  domainId?: Maybe<Scalars['ID']>;
  auditId: Scalars['String'];
  removeAccess?: Maybe<Scalars['Boolean']>;
};


/** List of all graphql mutations */
export type MutationDeleteAuditEngagementArgs = {
  input: DeleteAuditEngagementInput;
};


/** List of all graphql mutations */
export type MutationDeleteCredentialAndDataArgs = {
  domainId?: Maybe<Scalars['ID']>;
  service: Scalars['String'];
  credentialId: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationDeleteDomainArgs = {
  domainId: Scalars['ID'];
  dryRun: Scalars['Boolean'];
};


/** List of all graphql mutations */
export type MutationDeleteLinkEvidenceArgs = {
  domainId?: Maybe<Scalars['ID']>;
  input: DeleteLinkEvidenceInput;
};


/** List of all graphql mutations */
export type MutationDeleteNewCustomerArgs = {
  domainName: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationDeleteOtherInventoryItemArgs = {
  domainId?: Maybe<Scalars['ID']>;
  itemMongoId: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationDeletePoliciesByTypeArgs = {
  domainId: Scalars['ID'];
  policyType: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationDeleteQueryArgs = {
  id: Scalars['ID'];
};


/** List of all graphql mutations */
export type MutationDeleteRiskEvaluationArgs = {
  evaluationId: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationDeleteRiskMitigationTaskArgs = {
  taskId: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationDeleteRiskQuestionArgs = {
  id: Scalars['ID'];
};


/** List of all graphql mutations */
export type MutationDeleteRiskScenarioArgs = {
  domainId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
};


/** List of all graphql mutations */
export type MutationDeleteSecurityTrainingDocumentArgs = {
  input: DeleteSecurityTrainingDocumentInput;
};


/** List of all graphql mutations */
export type MutationDeleteSecurityTrainingsByDomainArgs = {
  input: DeleteSecurityTrainingsByDomainInput;
};


/** List of all graphql mutations */
export type MutationDeleteUploadedDocumentArgs = {
  domainId?: Maybe<Scalars['ID']>;
  uploadedDocumentId: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationDeleteUserArgs = {
  domainId: Scalars['ID'];
  input: DeleteUserInput;
};


/** List of all graphql mutations */
export type MutationDeleteVendorDocumentArgs = {
  domainId?: Maybe<Scalars['ID']>;
  input: DeleteVendorDocumentInput;
};


/** List of all graphql mutations */
export type MutationDeprecateFeatureArgs = {
  feature: Feature;
};


/** List of all graphql mutations */
export type MutationDisableMonitoringOnTestArgs = {
  domainId: Scalars['ID'];
  testId: Scalars['String'];
  reason: Scalars['String'];
  expiration?: Maybe<Scalars['String']>;
};


/** List of all graphql mutations */
export type MutationDisableMonitoringOnTestEntityArgs = {
  domainId: Scalars['ID'];
  entityId: Scalars['String'];
  entityType: Scalars['String'];
  testId?: Maybe<Scalars['String']>;
  reason: Scalars['String'];
  expiration?: Maybe<Scalars['String']>;
};


/** List of all graphql mutations */
export type MutationDisableOsqueriesArgs = {
  domainId: Scalars['ID'];
  nodeKeys: Array<Scalars['String']>;
};


/** List of all graphql mutations */
export type MutationDisableOsqueriesByIdArgs = {
  domainId: Scalars['ID'];
  ids: Array<Scalars['String']>;
};


/** List of all graphql mutations */
export type MutationDiscardBufferedEmailsArgs = {
  emailKey: EmailKey;
};


/** List of all graphql mutations */
export type MutationDismissRiskArgs = {
  riskId: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationDismissRiskQuestionArgs = {
  domainId?: Maybe<Scalars['ID']>;
  questionId: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationDismissRiskTaskArgs = {
  domainId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  dismissReason: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationEditPresentedReportArgs = {
  active: Scalars['Boolean'];
  domainId?: Maybe<Scalars['ID']>;
  presentedReportId: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationEditWorkPaperArgs = {
  workPaperId: Scalars['String'];
  workPaperTests: Array<WorkPaperTestInput>;
};


/** List of all graphql mutations */
export type MutationEmailKillSwitchIndividualStopArgs = {
  key: EmailKey;
};


/** List of all graphql mutations */
export type MutationEmailKillSwitchReleaseIndividualStopArgs = {
  key: EmailKey;
};


/** List of all graphql mutations */
export type MutationEnsureOktaAppArgs = {
  domainId: Scalars['ID'];
};


/** List of all graphql mutations */
export type MutationEvaluateRiskItemArgs = {
  evaluationId: Scalars['String'];
  likelihood: Scalars['Int'];
  impact: Scalars['Int'];
};


/** List of all graphql mutations */
export type MutationGeneratePolicyPacketArgs = {
  domainId: Scalars['ID'];
};


/** List of all graphql mutations */
export type MutationGenerateSignedUrlArgs = {
  input: GenerateSignedUrlInput;
};


/** List of all graphql mutations */
export type MutationGrantAuditorDomainAccessArgs = {
  domainId?: Maybe<Scalars['ID']>;
  auditorEmail?: Maybe<Scalars['String']>;
  auditorId?: Maybe<Scalars['String']>;
};


/** List of all graphql mutations */
export type MutationGrantBetaFeatureAccessArgs = {
  feature: Feature;
  domainIds: Array<Scalars['ID']>;
};


/** List of all graphql mutations */
export type MutationGrantLegacyFeatureAccessArgs = {
  feature: Feature;
  domainIds: Array<Scalars['ID']>;
};


/** List of all graphql mutations */
export type MutationGsuitePermissionOptBackInArgs = {
  domainId: Scalars['ID'];
};


/** List of all graphql mutations */
export type MutationGsuitePermissionOptOutArgs = {
  domainId: Scalars['ID'];
};


/** List of all graphql mutations */
export type MutationLinkUserWithHrUserArgs = {
  domainId?: Maybe<Scalars['ID']>;
  input: LinkUserWithHrUserInput;
};


/** List of all graphql mutations */
export type MutationLockDomainArgs = {
  domainId: Scalars['ID'];
};


/** List of all graphql mutations */
export type MutationMakeUserAuditorArgs = {
  displayName: Scalars['String'];
  email: Scalars['String'];
  domainId?: Maybe<Scalars['ID']>;
  maybeFirmName?: Maybe<Scalars['String']>;
};


/** List of all graphql mutations */
export type MutationManuallyOptIntoTestsArgs = {
  domainId?: Maybe<Scalars['ID']>;
  input: ManuallyOptIntoTestsInput;
};


/** List of all graphql mutations */
export type MutationManuallyRemoveAccountsAndLinkedAccountsArgs = {
  domainId: Scalars['ID'];
  service: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationMarkInterestForStandardArgs = {
  input: MarkInterestForStandardInput;
};


/** List of all graphql mutations */
export type MutationMarkRiskMitigationTaskCompletedArgs = {
  taskId: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationMergeUserAfterDomainMigrationArgs = {
  emailBefore: Scalars['String'];
  emailAfter: Scalars['String'];
  dryRun: Scalars['Boolean'];
};


/** List of all graphql mutations */
export type MutationMigratePolicyAcceptancesArgs = {
  domainId: Scalars['ID'];
  oldPolicyId: Scalars['String'];
  newPolicyId: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationOptIntoStandardArgs = {
  domainId?: Maybe<Scalars['ID']>;
  input: OptIntoStandardInput;
};


/** List of all graphql mutations */
export type MutationOptOutOfStandardArgs = {
  domainId?: Maybe<Scalars['ID']>;
  input: OptOutOfStandardInput;
};


/** List of all graphql mutations */
export type MutationPublishRoleUpdatesArgs = {
  roleId: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationRecomputeCachedFieldsArgs = {
  queryName: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationReenableCredentialsArgs = {
  domainId?: Maybe<Scalars['ID']>;
  service: Scalars['String'];
  externalAccountId?: Maybe<Scalars['String']>;
};


/** List of all graphql mutations */
export type MutationReenableMonitoringOnTestArgs = {
  domainId: Scalars['ID'];
  testId: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationReenableMonitoringOnTestEntityArgs = {
  domainId: Scalars['ID'];
  entityId: Scalars['String'];
  entityType: Scalars['String'];
  testId?: Maybe<Scalars['String']>;
};


/** List of all graphql mutations */
export type MutationRegisterDeviceArgs = {
  uuid: Scalars['String'];
  userId: Scalars['ID'];
};


/** List of all graphql mutations */
export type MutationRegisterDeviceBySecretArgs = {
  uuid: Scalars['String'];
  ownerEmail: Scalars['String'];
  secret: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationReleaseBetaFeatureArgs = {
  input: ReleaseBetaFeatureInput;
};


/** List of all graphql mutations */
export type MutationRemoveAuditorFromAuditArgs = {
  auditId: Scalars['String'];
  auditorId: Scalars['String'];
  removeAccess?: Maybe<Scalars['Boolean']>;
};


/** List of all graphql mutations */
export type MutationRemoveManualOptInFromTestsArgs = {
  domainId?: Maybe<Scalars['ID']>;
  input: RemoveManualOptInFromTestsInput;
};


/** List of all graphql mutations */
export type MutationRemovePolicyArgs = {
  policyId: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationRemoveRiskDismissalArgs = {
  riskId: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationRemoveRoleArgs = {
  roleId: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationRemoveRoleFromUserArgs = {
  userId: Scalars['ID'];
  roleId: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationRemoveSecurityTrainingPreferenceArgs = {
  domainId?: Maybe<Scalars['ID']>;
  standard: ReportStandard;
};


/** List of all graphql mutations */
export type MutationRemoveSecurityTrainingPreferenceByTrainingIdArgs = {
  input: RemoveSecurityTrainingPreferenceByTrainingIdInput;
};


/** List of all graphql mutations */
export type MutationRemoveTaskFromRoleArgs = {
  roleId: Scalars['String'];
  taskId: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationRemoveUserArgs = {
  userId: Scalars['ID'];
};


/** List of all graphql mutations */
export type MutationRemoveVendorArgs = {
  domainId?: Maybe<Scalars['ID']>;
  vendorId: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationRenewPolicyArgs = {
  policyId: Scalars['String'];
  majorChange: Scalars['Boolean'];
};


/** List of all graphql mutations */
export type MutationRequestVaqSubmissionArgs = {
  domainId?: Maybe<Scalars['ID']>;
  vendorId: Scalars['String'];
  subjectLine: Scalars['String'];
  message: Scalars['String'];
  dryRun?: Maybe<Scalars['Boolean']>;
};


/** List of all graphql mutations */
export type MutationResetVulnerablePackageSlaArgs = {
  domainId: Scalars['ID'];
  vulnerablePackageId: Scalars['String'];
  onlyResolvedVulns?: Maybe<Scalars['Boolean']>;
};


/** List of all graphql mutations */
export type MutationRevokeAuditorDomainAccessArgs = {
  domainId?: Maybe<Scalars['ID']>;
  auditorId: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationRevokeBetaFeatureAccessArgs = {
  feature: Feature;
  domainIds: Array<Scalars['ID']>;
};


/** List of all graphql mutations */
export type MutationRrv3AddAssetArgs = {
  name: Scalars['String'];
  riskCategoryId: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationRrv3AddScenarioArgs = {
  description: Scalars['String'];
  impact: Scalars['Int'];
  likelihood: Scalars['Int'];
  scenarioIdToUpdate?: Maybe<Scalars['String']>;
  riskCategoryId: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationRrv3AddTaskArgs = {
  assigneeId?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  dueDate: Scalars['DateTime'];
  scenarioId: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationRrv3CompleteTaskArgs = {
  taskId: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationRrv3DeleteAssetArgs = {
  itemId: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationRrv3DeleteScenarioArgs = {
  scenarioId: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationRrv3DeleteTaskArgs = {
  taskId: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationScheduleAuditArgs = {
  auditorEmail: Scalars['String'];
  auditStart: Scalars['Float'];
  auditEnd: Scalars['Float'];
  auditType: AuditTypeEnum;
  grantImmediateAccess?: Maybe<Scalars['Boolean']>;
};


/** List of all graphql mutations */
export type MutationScheduleQueryArgs = {
  query: Scalars['String'];
  nodeKey: Scalars['String'];
  queryName: Scalars['String'];
  notifyOnResult: Scalars['Boolean'];
};


/** List of all graphql mutations */
export type MutationSendAdminInvitationsArgs = {
  domainId?: Maybe<Scalars['ID']>;
  newAdmins: Array<NewAdminInfo>;
};


/** List of all graphql mutations */
export type MutationSendBufferedEmailsArgs = {
  emailKey: EmailKey;
};


/** List of all graphql mutations */
export type MutationSendHighPriEmailArgs = {
  testRunId: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationSendLoginInvitationToNewCustomerArgs = {
  domainName: Scalars['String'];
  contactEmail: Scalars['String'];
  contactGivenName: Scalars['String'];
  contactFamilyName: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationSendLoginLinkArgs = {
  email: Scalars['String'];
  hostname?: Maybe<Scalars['String']>;
};


/** List of all graphql mutations */
export type MutationSendReminderEmailArgs = {
  domainId: Scalars['ID'];
  flag: Scalars['String'];
  platform?: Maybe<ClientPlatform>;
  userId: Scalars['ID'];
};


/** List of all graphql mutations */
export type MutationSetAccessSlasArgs = {
  reviewed: Scalars['Float'];
  revoked: Scalars['Float'];
};


/** List of all graphql mutations */
export type MutationSetAdminTaskIdsForRoleArgs = {
  roleId: Scalars['String'];
  adminTaskIds: Array<Scalars['String']>;
};


/** List of all graphql mutations */
export type MutationSetAuditCompletionDateArgs = {
  input: SetAuditCompletionDateInput;
};


/** List of all graphql mutations */
export type MutationSetAuditObservationPeriodArgs = {
  input: SetAuditObservationPeriodInput;
};


/** List of all graphql mutations */
export type MutationSetBackgroundCheckCutoffArgs = {
  cutoffDate?: Maybe<Scalars['DateTime']>;
  domainId?: Maybe<Scalars['ID']>;
};


/** List of all graphql mutations */
export type MutationSetBackgroundCheckMappingArgs = {
  backgroundCheckId: Scalars['String'];
  userId?: Maybe<Scalars['ID']>;
  domainId?: Maybe<Scalars['ID']>;
};


/** List of all graphql mutations */
export type MutationSetBackgroundCheckToIgnoredArgs = {
  backgroundCheckId: Scalars['String'];
  domainId?: Maybe<Scalars['ID']>;
};


/** List of all graphql mutations */
export type MutationSetBillingEmailArgs = {
  domainId?: Maybe<Scalars['ID']>;
  email: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationSetCredentialMetadataArgs = {
  domainId?: Maybe<Scalars['ID']>;
  enable?: Maybe<Scalars['Boolean']>;
  externalAccountId?: Maybe<Scalars['String']>;
  merge?: Maybe<Scalars['Boolean']>;
  metadata: Scalars['String'];
  service: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationSetCredentialsArgs = {
  domainId?: Maybe<Scalars['ID']>;
  credentials: Scalars['String'];
  service: Scalars['String'];
  externalAccountId?: Maybe<Scalars['String']>;
};


/** List of all graphql mutations */
export type MutationSetDomainBusinessOrComplianceInfoArgs = {
  answers: Scalars['String'];
  domainId?: Maybe<Scalars['ID']>;
  files?: Maybe<Array<Maybe<FileFieldName>>>;
  section?: Maybe<Scalars['String']>;
};


/** List of all graphql mutations */
export type MutationSetDomainDisplayNameArgs = {
  displayName: Scalars['String'];
  domainId: Scalars['ID'];
};


/** List of all graphql mutations */
export type MutationSetDomainNameArgs = {
  domainName: Scalars['String'];
  domainId: Scalars['ID'];
};


/** List of all graphql mutations */
export type MutationSetEmployeeDigestNotificationFrequencyArgs = {
  input: SetEmployeeDigestNotificationFrequencyInput;
};


/** List of all graphql mutations */
export type MutationSetEmployeeTaskIdsForRoleArgs = {
  roleId: Scalars['String'];
  employeeTaskIds: Array<Scalars['String']>;
};


/** List of all graphql mutations */
export type MutationSetEvidenceRequestIgnoredStatusArgs = {
  domainId?: Maybe<Scalars['ID']>;
  input: SetEvidenceRequestIgnoredStatusInput;
};


/** List of all graphql mutations */
export type MutationSetEvidenceRequestReminderWindowArgs = {
  input: SetEvidenceRequestReminderWindowInput;
};


/** List of all graphql mutations */
export type MutationSetEvidenceRequestRenewalCadenceArgs = {
  input: SetEvidenceRequestRenewalCadenceInput;
};


/** List of all graphql mutations */
export type MutationSetEvidenceRequestRenewalDateArgs = {
  input: SetEvidenceRequestRenewalDateInput;
};


/** List of all graphql mutations */
export type MutationSetKnowBe4TrainingTypesArgs = {
  input: SetKnowBe4TrainingTypesInput;
};


/** List of all graphql mutations */
export type MutationSetMdmSlasArgs = {
  domainId?: Maybe<Scalars['ID']>;
  computerSetupInHours: Scalars['Int'];
};


/** List of all graphql mutations */
export type MutationSetNonHumanArgs = {
  domainId?: Maybe<Scalars['ID']>;
  userId: Scalars['ID'];
  isNotHuman: Scalars['Boolean'];
  isNotHumanReason: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationSetOnboardingAdminArgs = {
  domainId?: Maybe<Scalars['ID']>;
  userId: Scalars['ID'];
};


/** List of all graphql mutations */
export type MutationSetOnboardingSlaArgs = {
  domainId?: Maybe<Scalars['ID']>;
  gracePeriodInDays: Scalars['Int'];
};


/** List of all graphql mutations */
export type MutationSetOsqueryActiveStatusArgs = {
  active: Scalars['Boolean'];
  nodeKey: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationSetOsqueryTargetArgs = {
  newVersion: Scalars['String'];
  newVersionProbability: Scalars['Float'];
  oldVersion: Scalars['String'];
  os: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationSetOsqueryVantaAttributeArgs = {
  domainId?: Maybe<Scalars['ID']>;
  osqueryMongoId: Scalars['String'];
  key: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};


/** List of all graphql mutations */
export type MutationSetOsqueryVantaTargetArgs = {
  os: Scalars['String'];
  version: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationSetOtherInventoryItemNameArgs = {
  domainId?: Maybe<Scalars['ID']>;
  itemMongoId: Scalars['String'];
  name: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationSetOtherInventoryItemVantaAttributeArgs = {
  domainId?: Maybe<Scalars['ID']>;
  itemMongoId: Scalars['String'];
  key: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};


/** List of all graphql mutations */
export type MutationSetPermissionLevelArgs = {
  userId: Scalars['ID'];
  level: PermissionLevel;
};


/** List of all graphql mutations */
export type MutationSetPolicyAnswersArgs = {
  answers: Scalars['String'];
  domainId?: Maybe<Scalars['ID']>;
  policyType: Scalars['String'];
  userId?: Maybe<Scalars['ID']>;
};


/** List of all graphql mutations */
export type MutationSetPolicyApprovalArgs = {
  policyId: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationSetPreferredSecurityTrainingArgs = {
  domainId?: Maybe<Scalars['ID']>;
  standard?: Maybe<ReportStandard>;
  preferredTraining: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationSetResourceScopeArgs = {
  specificResourceKind: SpecificResource;
  mongoId: Scalars['String'];
  excluded: Scalars['Boolean'];
};


/** List of all graphql mutations */
export type MutationSetResourceVantaAttributeArgs = {
  domainId?: Maybe<Scalars['ID']>;
  specificResourceKind: SpecificResource;
  resourceMongoId: Scalars['String'];
  key: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};


/** List of all graphql mutations */
export type MutationSetRoleSecurityRequirementsArgs = {
  roleId: Scalars['String'];
  securityRequirements: RoleSecurityRequirementsInput;
  necessaryPolicies?: Maybe<Array<Scalars['String']>>;
};


/** List of all graphql mutations */
export type MutationSetSecurityIssueSlasArgs = {
  securityBugP0?: Maybe<Scalars['Float']>;
  securityBugP1?: Maybe<Scalars['Float']>;
  securityBugP2?: Maybe<Scalars['Float']>;
  securityBugP3?: Maybe<Scalars['Float']>;
};


/** List of all graphql mutations */
export type MutationSetSecurityTrainingInstructionsArgs = {
  domainId?: Maybe<Scalars['ID']>;
  standard: ReportStandard;
  securityTrainingInstructions: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationSetSecurityTrainingInstructionsByTrainingIdArgs = {
  input: SetSecurityTrainingInstructionsByTrainingIdInput;
};


/** List of all graphql mutations */
export type MutationSetSecurityTrainingUrlArgs = {
  domainId?: Maybe<Scalars['ID']>;
  standard: ReportStandard;
  securityTrainingUrl: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationSetSecurityTrainingUrlByTrainingIdArgs = {
  input: SetSecurityTrainingUrlByTrainingIdInput;
};


/** List of all graphql mutations */
export type MutationSetSeenRiskRegisterSplashArgs = {
  userId: Scalars['ID'];
  seenSplash: Scalars['Boolean'];
};


/** List of all graphql mutations */
export type MutationSetServiceAccountMappingArgs = {
  serviceAccountId: Scalars['String'];
  userId?: Maybe<Scalars['ID']>;
  domainId?: Maybe<Scalars['ID']>;
  isNotHumanReason?: Maybe<Scalars['String']>;
};


/** List of all graphql mutations */
export type MutationSetServiceAccountMappingsArgs = {
  serviceAccountIds: Array<Scalars['String']>;
  userIds: Array<Scalars['String']>;
  domainId?: Maybe<Scalars['ID']>;
};


/** List of all graphql mutations */
export type MutationSetSpecificResourceKindScopeArgs = {
  excluded: Scalars['Boolean'];
  resourceUniqueIds?: Maybe<Array<Scalars['String']>>;
  specificResourceKind: SpecificResource;
};


/** List of all graphql mutations */
export type MutationSetTargetImageScanTagForRepositoryArgs = {
  domainId?: Maybe<Scalars['ID']>;
  specificResourceKind: SpecificResource;
  resourceMongoId: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};


/** List of all graphql mutations */
export type MutationSetTestStatusToAvailableArgs = {
  input: SetTestStatusToAvailableInput;
};


/** List of all graphql mutations */
export type MutationSetTestStatusToPendingArgs = {
  input: SetTestStatusToPendingInput;
};


/** List of all graphql mutations */
export type MutationSetUserEmailSubscriptionSettingArgs = {
  emailKey: EmailKey;
  shouldSend: Scalars['Boolean'];
};


/** List of all graphql mutations */
export type MutationSetUserNameArgs = {
  domainId?: Maybe<Scalars['ID']>;
  userId: Scalars['ID'];
  familyName: Scalars['String'];
  givenName: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationSetUserSecurityTrainingAttestationArgs = {
  screenshot: Scalars['Upload'];
  trainingId: Scalars['String'];
  userId?: Maybe<Scalars['ID']>;
};


/** List of all graphql mutations */
export type MutationSetUserSecurityTrainingVideoCompletionArgs = {
  videosCompleted: Array<Scalars['String']>;
};


/** List of all graphql mutations */
export type MutationSetVaqAnswersArgs = {
  uuid: Scalars['String'];
  schemaId: Scalars['String'];
  answers: Scalars['String'];
  files?: Maybe<Array<FileFieldName>>;
};


/** List of all graphql mutations */
export type MutationSetVendorArgs = {
  assessmentComments?: Maybe<Scalars['String']>;
  assessmentFile?: Maybe<Scalars['Upload']>;
  attestationOfComplianceFile?: Maybe<Scalars['Upload']>;
  authMethod?: Maybe<Scalars['String']>;
  baaFile?: Maybe<Scalars['Upload']>;
  contactEmail?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  containsUserData?: Maybe<Scalars['Boolean']>;
  dpaFile?: Maybe<Scalars['Upload']>;
  dataStored?: Maybe<Scalars['String']>;
  domainId?: Maybe<Scalars['ID']>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  otherInformation?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  passwordMFA?: Maybe<Scalars['Boolean']>;
  passwordMinLength?: Maybe<Scalars['Int']>;
  passwordRequiresNumber?: Maybe<Scalars['Boolean']>;
  passwordRequiresSymbol?: Maybe<Scalars['Boolean']>;
  servicesProvided?: Maybe<Scalars['String']>;
  severity?: Maybe<Scalars['String']>;
  sharesEPHI?: Maybe<Scalars['Boolean']>;
  sharesEUPII?: Maybe<Scalars['Boolean']>;
  url?: Maybe<Scalars['String']>;
};


/** List of all graphql mutations */
export type MutationSetVulnerabilitySlasArgs = {
  low: Scalars['Float'];
  medium: Scalars['Float'];
  high: Scalars['Float'];
};


/** List of all graphql mutations */
export type MutationStartAuditWithoutChargingArgs = {
  auditStart: Scalars['Float'];
  auditType: AuditTypeEnum;
  auditorUserId: Scalars['String'];
  auditorDomainId: Scalars['String'];
  auditLengthDays: Scalars['Int'];
  domainId: Scalars['ID'];
  isLinfordAuditor: Scalars['Boolean'];
};


/** List of all graphql mutations */
export type MutationSubmitPolicyWithAnswersArgs = {
  answers: Scalars['String'];
  domainId?: Maybe<Scalars['ID']>;
  file: Scalars['Upload'];
  majorChange: Scalars['Boolean'];
  policyType: Scalars['String'];
  userId?: Maybe<Scalars['ID']>;
};


/** List of all graphql mutations */
export type MutationSubmitVaqArgs = {
  uuid: Scalars['String'];
  schemaIds: Array<Scalars['String']>;
  files: Array<Scalars['String']>;
};


/** List of all graphql mutations */
export type MutationSubmitVaqWithComplianceFileArgs = {
  uuid: Scalars['String'];
  file: FileFieldName;
  assessmentType: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationSyncStripeSubscriptionArgs = {
  domainId: Scalars['ID'];
};


/** List of all graphql mutations */
export type MutationTestCredentialsArgs = {
  domainId?: Maybe<Scalars['ID']>;
  credentials: Scalars['String'];
  service: Scalars['String'];
  extraMetadata?: Maybe<Scalars['String']>;
};


/** List of all graphql mutations */
export type MutationTestResultKillSwitchIndividualStopArgs = {
  testResultKey: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationTestResultKillSwitchReleaseIndividualStopArgs = {
  testResultKey: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationTriggerDomainAgnosticResourceFetchArgs = {
  specificResourceKind: Scalars['String'];
  partial?: Maybe<Scalars['Boolean']>;
};


/** List of all graphql mutations */
export type MutationTriggerResourceFetchArgs = {
  domainId: Scalars['ID'];
  specificResourceKind: Scalars['String'];
  partial?: Maybe<Scalars['Boolean']>;
};


/** List of all graphql mutations */
export type MutationTriggerResourceFetchesByServiceArgs = {
  domainId: Scalars['ID'];
  service: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationTriggerTestRefreshArgs = {
  domainId?: Maybe<Scalars['ID']>;
  testId: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationTriggerTestRunsArgs = {
  domainId: Scalars['ID'];
  testIds: Array<Scalars['String']>;
};


/** List of all graphql mutations */
export type MutationTriggerTestsForAllCustomerDomainsArgs = {
  testIds: Array<Scalars['String']>;
};


/** List of all graphql mutations */
export type MutationUnapprovePolicyArgs = {
  policyId: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationUnblacklistForCronFilterArgs = {
  domainId?: Maybe<Scalars['ID']>;
  item?: Maybe<Scalars['String']>;
  queue: CronService;
};


/** List of all graphql mutations */
export type MutationUnlinkCredentialsArgs = {
  cause: Scalars['String'];
  domainId?: Maybe<Scalars['ID']>;
  service: Scalars['String'];
  externalAccountId?: Maybe<Scalars['String']>;
};


/** List of all graphql mutations */
export type MutationUnlockDomainArgs = {
  domainId: Scalars['ID'];
};


/** List of all graphql mutations */
export type MutationUnreleaseBetaFeatureArgs = {
  input: UnreleaseBetaFeatureInput;
};


/** List of all graphql mutations */
export type MutationUnremoveUserArgs = {
  userId: Scalars['ID'];
};


/** List of all graphql mutations */
export type MutationUnwhitelistForCronFilterArgs = {
  domainId?: Maybe<Scalars['ID']>;
  item?: Maybe<Scalars['String']>;
  queue: CronService;
};


/** List of all graphql mutations */
export type MutationUpdateCardArgs = {
  domainId?: Maybe<Scalars['ID']>;
  stripeToken: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationUpdateCloudProviderIdArgs = {
  instanceId: Scalars['String'];
  hostIdentifier: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationUpdateDocumentEvidenceMetadataArgs = {
  domainId?: Maybe<Scalars['ID']>;
  input: UpdateDocumentEvidenceMetadataInput;
};


/** List of all graphql mutations */
export type MutationUpdateLinkEvidenceArgs = {
  domainId?: Maybe<Scalars['ID']>;
  input: UpdateLinkEvidenceInput;
};


/** List of all graphql mutations */
export type MutationUpdateOsqueryEndpointArgs = {
  domainId: Scalars['ID'];
  id: Scalars['ID'];
  user: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationUpdatePaymentIntervalArgs = {
  domainId?: Maybe<Scalars['ID']>;
  paymentInterval: PaymentInterval;
};


/** List of all graphql mutations */
export type MutationUpdatePaymentMethodArgs = {
  domainId?: Maybe<Scalars['ID']>;
  paymentMethod: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationUpdateRiskQuestionArgs = {
  id: Scalars['ID'];
  text: Scalars['String'];
  category: Scalars['String'];
  affirmative: Scalars['Boolean'];
};


/** List of all graphql mutations */
export type MutationUpdateRiskScenarioArgs = {
  domainId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  questionId?: Maybe<Scalars['String']>;
  text: Scalars['String'];
  likelihood: Scalars['Int'];
  impact: Scalars['Int'];
  existingTasks: Array<RiskTaskInput>;
  newTasks: Array<RiskTaskInput>;
};


/** List of all graphql mutations */
export type MutationUpdateRiskTaskArgs = {
  domainId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  title: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationUpdateTestDeactivatedReasonArgs = {
  input: UpdateTestDeactivatedReasonInput;
};


/** List of all graphql mutations */
export type MutationUploadEvidenceArgs = {
  domainId?: Maybe<Scalars['ID']>;
  input: UploadEvidenceInput;
};


/** List of all graphql mutations */
export type MutationWhitelistAddIdentifierBulkArgs = {
  domainId?: Maybe<Scalars['ID']>;
  entityType: Scalars['String'];
  entityIds: Array<Scalars['String']>;
  reason: Scalars['String'];
  testId: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationWhitelistForCronFilterArgs = {
  domainId?: Maybe<Scalars['ID']>;
  item?: Maybe<Scalars['String']>;
  queue: CronService;
};


/** List of all graphql mutations */
export type MutationWhitelistRemoveIdentifierArgs = {
  domainId?: Maybe<Scalars['ID']>;
  entityType: Scalars['String'];
  entityId: Scalars['String'];
  testId: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationWhitelistTestArgs = {
  domainId?: Maybe<Scalars['ID']>;
  file?: Maybe<Scalars['Upload']>;
  reason?: Maybe<Scalars['String']>;
  testId: Scalars['String'];
};


/** List of all graphql mutations */
export type MutationWhitelistVulnerabilityForAllRepositoriesArgs = {
  domainId?: Maybe<Scalars['ID']>;
  packageIdentifier: Scalars['String'];
  reason: Scalars['String'];
  service: Scalars['String'];
};

/**
 * Mutation inputs and payloads (deprecated)
 *
 * Mutations should have unique input and output types.
 *
 * Input types should be named <Mutationname>Input
 * Output types should be named <Mutationname>Payload
 *
 * See https://graphql-rules.com/rules/mutation
 */
export type MutationPayload = {
  status?: Maybe<MutationStatus>;
};

/** Similar to an HTTP response code, reports the status of a mutation. */
export enum MutationStatus {
  FAILURE = 'FAILURE',
  NO_CHANGE = 'NO_CHANGE',
  SUCCESS = 'SUCCESS'
}

/** List of all graphql queries */
export type Query = {
  __typename?: 'Query';
  /** @deprecated Move to `public` query */
  accessibleSnykOrganizations: AccessibleSnykOrganizationsResponse;
  /** @deprecated Move to `internal` query */
  allAuditFirms: Array<AuditorInfo>;
  /** @deprecated Move to `internal` query */
  areTestsStoppedUniversally: Scalars['Boolean'];
  /** @deprecated Move to `internal` query */
  auditTypes: Array<AuditType>;
  /** @deprecated Move to a namespace (TBD; possibly domain) */
  auditor?: Maybe<Auditor>;
  /** @deprecated Move to `internal` type and drop both params */
  audits: Array<Audit>;
  /** @deprecated Move to `internal` query */
  bufferedEmails: Array<BufferedEmail>;
  /** @deprecated Move to `domain` type and clean up input/return type */
  changeDetails: Scalars['String'];
  /** @deprecated Move to `domain` type and clean up input while you're at it */
  changesForRepoInMonth: PaginatedChanges;
  /** @deprecated Move to `domain` type- make sure to permission correctly */
  checkrReportFile: Scalars['String'];
  /** @deprecated Load controls instead */
  complianceStandard: ComplianceStandard;
  /** @deprecated Move to `internal` query */
  cronFilters: Array<CronFilter>;
  /** @deprecated Move to `internal` query */
  emailKillSwitchList: Array<EmailKey>;
  /** @deprecated Move to `internal` query */
  emails: Array<Email>;
  /** @deprecated Move to `internal` query */
  gsuitePermissionOptOutList: Array<Scalars['String']>;
  internal: Internal;
  isPermitted: Scalars['Boolean'];
  isRegistered: Scalars['Boolean'];
  /** @deprecated Move to `public` query */
  latestPolicy?: Maybe<PublicPolicy>;
  /** @deprecated Move to `domain` type */
  latestResourceFetches: Array<Maybe<ResourceFetch>>;
  /** @deprecated Move to `internal` query */
  newCustomers: Array<NewCustomer>;
  organization: Domain;
  /** @deprecated Move to `internal` query */
  osqueryEndpointMetadata: OsqueryMetadataForAgent;
  /** @deprecated Move to `public` query */
  presentedReport?: Maybe<PresentedReport>;
  public: Public;
  /** @deprecated Move to a namespace; TBD which */
  riskQuestions: Array<RiskQuestion>;
  /** @deprecated Move to `domain` type */
  serviceAccountsFromAccountIds: Array<ServiceAccount>;
  /** @deprecated Move to `internal` query */
  specificResourceKinds: Array<Scalars['String']>;
  /** @deprecated Move to `internal` query */
  testResultKillSwitchCanRunTest: Scalars['Boolean'];
  /** @deprecated Move to `internal` query */
  testResultKillSwitchList: Array<Scalars['String']>;
  /** @deprecated Move to `internal` query */
  testRunnerTestIds: Array<Scalars['String']>;
  /** @deprecated Move to `internal` query */
  tests: Array<Test>;
  /** @deprecated Move to `public` query */
  uploadedDocument?: Maybe<PublicUploadedDocument>;
  /** @deprecated use domain.userByID, domain.userByEmail, or the root `user` type instead */
  user?: Maybe<User>;
  /** @deprecated Move to `user` type */
  userEmailSubscriptionSettings: Array<UserEmailSubscriptionSetting>;
  /**
   * ValidateGcpCredentials is separate from the testCredentials resolver because
   * their types differ significantly: validateGCPCredentials returns structured
   * data about each GCP project. If rewriting one or the other, prefer
   * consolidating the patterns. (Nov. 5, 2020)
   * @deprecated Move to `public` query
   */
  validateGcpCredentials: GcpCredentialValidationOutcome;
  /** @deprecated Move to `public` query */
  vaqAnswerStatus?: Maybe<VaqStatus>;
  /** @deprecated Move to `public` query */
  vaqAnswers?: Maybe<PublicVaqAnswers>;
  /** @deprecated Move to `domain` type */
  workPaperContents: Array<WorkPaperTest>;
};


/** List of all graphql queries */
export type QueryAccessibleSnykOrganizationsArgs = {
  token: Scalars['String'];
};


/** List of all graphql queries */
export type QueryAuditorArgs = {
  email: Scalars['String'];
};


/** List of all graphql queries */
export type QueryAuditsArgs = {
  getPendingAudits?: Maybe<Scalars['Boolean']>;
  domainId?: Maybe<Scalars['ID']>;
};


/** List of all graphql queries */
export type QueryChangeDetailsArgs = {
  domainId?: Maybe<Scalars['ID']>;
  repo: Scalars['String'];
  prNumber: Scalars['String'];
  ref?: Maybe<Scalars['String']>;
  service: Scalars['String'];
};


/** List of all graphql queries */
export type QueryChangesForRepoInMonthArgs = {
  domainId?: Maybe<Scalars['ID']>;
  repoName: Scalars['String'];
  repoId: Scalars['String'];
  branch: Scalars['String'];
  service: Scalars['String'];
  monthAndYear: MonthAndYear;
  pageUrl?: Maybe<Scalars['String']>;
};


/** List of all graphql queries */
export type QueryCheckrReportFileArgs = {
  domainId?: Maybe<Scalars['ID']>;
  userId: Scalars['ID'];
};


/** List of all graphql queries */
export type QueryComplianceStandardArgs = {
  domainId: Scalars['ID'];
  standard: ReportStandard;
};


/** List of all graphql queries */
export type QueryIsPermittedArgs = {
  fieldName: Scalars['String'];
};


/** List of all graphql queries */
export type QueryIsRegisteredArgs = {
  hostIdentifier: Scalars['String'];
};


/** List of all graphql queries */
export type QueryLatestPolicyArgs = {
  staticPolicySlug: Scalars['String'];
  policyType: Scalars['String'];
};


/** List of all graphql queries */
export type QueryLatestResourceFetchesArgs = {
  specificKinds: Array<Scalars['String']>;
};


/** List of all graphql queries */
export type QueryOsqueryEndpointMetadataArgs = {
  nodeKey: Scalars['String'];
};


/** List of all graphql queries */
export type QueryPresentedReportArgs = {
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  slugId: Scalars['String'];
  viewerCompany: Scalars['String'];
};


/** List of all graphql queries */
export type QueryServiceAccountsFromAccountIdsArgs = {
  accountIds: Array<Scalars['String']>;
};


/** List of all graphql queries */
export type QuerySpecificResourceKindsArgs = {
  customerResourcesOnly?: Maybe<Scalars['Boolean']>;
  domainAgnosticOnly?: Maybe<Scalars['Boolean']>;
};


/** List of all graphql queries */
export type QueryTestResultKillSwitchCanRunTestArgs = {
  testResultKey: Scalars['String'];
};


/** List of all graphql queries */
export type QueryTestRunnerTestIdsArgs = {
  serviceId?: Maybe<Scalars['String']>;
};


/** List of all graphql queries */
export type QueryUploadedDocumentArgs = {
  maybeSlugId: Scalars['String'];
};


/** List of all graphql queries */
export type QueryUserArgs = {
  id?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
};


/** List of all graphql queries */
export type QueryValidateGcpCredentialsArgs = {
  credentials: Scalars['String'];
  metadata: Scalars['String'];
};


/** List of all graphql queries */
export type QueryVaqAnswerStatusArgs = {
  uuid: Scalars['String'];
};


/** List of all graphql queries */
export type QueryVaqAnswersArgs = {
  uuid: Scalars['String'];
};


/** List of all graphql queries */
export type QueryWorkPaperContentsArgs = {
  domainId: Scalars['ID'];
  workPaperId: Scalars['String'];
};

/**
 * The Internal type is a namespace that contains all of the internal queries
 * that are used by Vanta employees but not by the Vanta app itself.
 */
export type Internal = {
  __typename?: 'Internal';
  allAutomatedTestMetadata: Array<TestMetadata>;
  /**
   * Although this is a non-constant list, the number of domains is considered acceptably small to return all of them.
   * If the number of customers grows signifigantly, we should add pagination to this query.
   */
  allDomains?: Maybe<Array<Domain>>;
  domainById: Domain;
  domainByName: Domain;
};


/**
 * The Internal type is a namespace that contains all of the internal queries
 * that are used by Vanta employees but not by the Vanta app itself.
 */
export type InternalDomainByIdArgs = {
  id: Scalars['ID'];
};


/**
 * The Internal type is a namespace that contains all of the internal queries
 * that are used by Vanta employees but not by the Vanta app itself.
 */
export type InternalDomainByNameArgs = {
  name: Scalars['String'];
};

/**
 * The Public type is a namespace that contains all of the public queries
 * that can be publically accessed without any authentication.
 */
export type Public = {
  __typename?: 'Public';
  generatedReport: PresentedReport;
  generatedReportAutologin: GeneratedReportAutologinPayload;
};


/**
 * The Public type is a namespace that contains all of the public queries
 * that can be publically accessed without any authentication.
 */
export type PublicGeneratedReportArgs = {
  email: Scalars['EmailAddress'];
  password: Scalars['String'];
  slugId: Scalars['String'];
  viewerCompany: Scalars['String'];
};


/**
 * The Public type is a namespace that contains all of the public queries
 * that can be publically accessed without any authentication.
 */
export type PublicGeneratedReportAutologinArgs = {
  slugId: Scalars['String'];
  viewerCompany: Scalars['String'];
};




/** Enum containing classifications for VOE requests */
export enum EvidenceClassification {
  ADMINISTRATIVE = 'ADMINISTRATIVE',
  PHYSICAL = 'PHYSICAL',
  TECHNICAL = 'TECHNICAL'
}

/** Enum containing categories for VOE requests */
export enum EvidenceCategory {
  ACCOUNTS_ACCESS = 'ACCOUNTS_ACCESS',
  COMPLIANCE = 'COMPLIANCE',
  DATA_STORAGE = 'DATA_STORAGE',
  INFRASTRUCTURE = 'INFRASTRUCTURE',
  IT = 'IT',
  LOGGING = 'LOGGING',
  MONITORING_ALERTS = 'MONITORING_ALERTS',
  PEOPLE = 'PEOPLE',
  POLICIES = 'POLICIES',
  RISK_ANALYSIS = 'RISK_ANALYSIS',
  SOFTWARE_DEVELOPMENT = 'SOFTWARE_DEVELOPMENT',
  VENDORS = 'VENDORS',
  VULNERABILITY_MANAGEMENT = 'VULNERABILITY_MANAGEMENT'
}

/** Enum containing error codes for a UserError */
export enum ErrorCode {
  DISABLED_AUDIT_LOG_FOR_TEST_NOT_FOUND_ERROR = 'DISABLED_AUDIT_LOG_FOR_TEST_NOT_FOUND_ERROR',
  DOMAIN_NOT_FOUND_ERROR = 'DOMAIN_NOT_FOUND_ERROR',
  EVIDENCE_METADATA_NOT_FOUND_ERROR = 'EVIDENCE_METADATA_NOT_FOUND_ERROR',
  ILLEGAL_INPUT_ERROR = 'ILLEGAL_INPUT_ERROR',
  INVALID_TEST_ID_ERROR = 'INVALID_TEST_ID_ERROR',
  USER_NOT_FOUND_ERROR = 'USER_NOT_FOUND_ERROR',
  GENERATED_REPORT_AUTOLOGIN_FAILURE_ERROR = 'GENERATED_REPORT_AUTOLOGIN_FAILURE_ERROR'
}

/** Enum containing frequency choices for notifications */
export enum NotificationFrequency {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  NEVER = 'NEVER'
}

/** BaseUserError type - used when no other error fields need to be exposed */
export type BaseUserError = UserError & {
  __typename?: 'BaseUserError';
  message: Scalars['String'];
  code: ErrorCode;
};

/** Input for bulkSetScope mutation */
export type BulkSetScopeInput = {
  excluded: Scalars['Boolean'];
  filter?: Maybe<FilterParams>;
  specificResourceKind: SpecificResource;
};

/** Success type for bulkSetScope mutation */
export type BulkSetScopeSuccess = {
  __typename?: 'BulkSetScopeSuccess';
  nMatched: Scalars['Int'];
};

/** Payload type for bulkSetScope mutation */
export type BulkSetScopePayload = BulkSetScopeSuccess | BaseUserError;

/** Input for CreateAuditEngagement Mutation */
export type CreateAuditEngagementInput = {
  auditType: AuditTypeEnum;
  customerDomainId: Scalars['String'];
  observationStartDate: Scalars['DateTime'];
  observationEndDate: Scalars['DateTime'];
};

/** Success type for CreateAuditEngagement Mutation */
export type CreateAuditEngagementSuccess = {
  __typename?: 'CreateAuditEngagementSuccess';
  audit?: Maybe<Audit>;
};

/** Payload type for CreateAuditEngagement Mutation */
export type CreateAuditEngagementPayload = CreateAuditEngagementSuccess | BaseUserError;

/** Input for DeleteAuditEngagement Mutation */
export type DeleteAuditEngagementInput = {
  auditId: Scalars['ID'];
  customerDomainId: Scalars['String'];
};

/** Success type for DeleteAuditEngagement Mutation */
export type DeleteAuditEngagementSuccess = {
  __typename?: 'DeleteAuditEngagementSuccess';
  audit?: Maybe<Audit>;
};

/** Payload type for DeleteAuditEngagement Mutation */
export type DeleteAuditEngagementPayload = DeleteAuditEngagementSuccess | BaseUserError;

/** Input for deleteSecurityTrainingsByDomain mutation */
export type DeleteSecurityTrainingsByDomainInput = {
  domainId: Scalars['ID'];
  completedBefore: Scalars['DateTime'];
  startedBefore?: Maybe<Scalars['DateTime']>;
  category: Scalars['String'];
  excludeRemoved: Scalars['Boolean'];
  dryRun: Scalars['Boolean'];
};

/** Success for deleteSecurityTrainingsByDomain mutation */
export type DeleteSecurityTrainingsByDomainSuccess = {
  __typename?: 'DeleteSecurityTrainingsByDomainSuccess';
  dryRun: Scalars['Boolean'];
  deletedDocumentsCount: Scalars['Int'];
  deletedCompletionsCount: Scalars['Int'];
  modifiedUsersCount: Scalars['Int'];
};

/** Payload for deleteSecurityTrainingsByDomain mutation */
export type DeleteSecurityTrainingsByDomainPayload = DeleteSecurityTrainingsByDomainSuccess | BaseUserError;

export type RemoveSecurityTrainingPreferenceByTrainingIdInput = {
  trainingId: Scalars['String'];
};

/** Success for removeSecurityTrainingPreferenceByTrainingId mutation */
export type RemoveSecurityTrainingPreferenceByTrainingIdSuccess = {
  __typename?: 'RemoveSecurityTrainingPreferenceByTrainingIdSuccess';
  success: Scalars['Boolean'];
};

/** Payload for removeSecurityTrainingPreferenceByTrainingId mutation */
export type RemoveSecurityTrainingPreferenceByTrainingIdPayload = BaseUserError | RemoveSecurityTrainingPreferenceByTrainingIdSuccess;

/** Input for setKnowBe4TrainingTypes mutation */
export type SetKnowBe4TrainingTypesInput = {
  generalSatIds: Array<Scalars['ID']>;
  hipaaSatIds: Array<Scalars['ID']>;
  pciSatIds: Array<Scalars['ID']>;
};

export type SetSecurityTrainingInstructionsByTrainingIdInput = {
  instructions: Scalars['String'];
  trainingId: Scalars['String'];
};

/** Success for setSecurityTrainingInstructionsByTrainingId mutation */
export type SetSecurityTrainingInstructionsByTrainingIdSuccess = {
  __typename?: 'SetSecurityTrainingInstructionsByTrainingIdSuccess';
  success: Scalars['Boolean'];
};

/** Payload for setSecurityTrainingInstructionsByTrainingId mutation */
export type SetSecurityTrainingInstructionsByTrainingIdPayload = BaseUserError | SetSecurityTrainingInstructionsByTrainingIdSuccess;

export type SetSecurityTrainingUrlByTrainingIdInput = {
  trainingId: Scalars['String'];
  url: Scalars['URL'];
};

/** Success for setSecurityTrainingUrlByTrainingId mutation */
export type SetSecurityTrainingUrlByTrainingIdSuccess = {
  __typename?: 'SetSecurityTrainingUrlByTrainingIdSuccess';
  success: Scalars['Boolean'];
};

/** Payload for setSecurityTrainingUrlByTrainingId mutation */
export type SetSecurityTrainingUrlByTrainingIdPayload = BaseUserError | SetSecurityTrainingUrlByTrainingIdSuccess;

/** Input for setAuditCompletionDate */
export type SetAuditCompletionDateInput = {
  customerDomainId: Scalars['ID'];
  auditId: Scalars['String'];
  completionDate: Scalars['DateTime'];
  report: Scalars['Upload'];
};

/** Success type for setAuditCompletionDate */
export type SetAuditCompletionDateSuccess = {
  __typename?: 'SetAuditCompletionDateSuccess';
  audit: Audit;
};

/** Payload type for SetAuditCompletionDate */
export type SetAuditCompletionDatePayload = SetAuditCompletionDateSuccess | BaseUserError;

/** Input for SetAuditObservationPeriod Mutation */
export type SetAuditObservationPeriodInput = {
  auditId: Scalars['ID'];
  customerDomainId: Scalars['ID'];
  observationStartDate: Scalars['DateTime'];
  observationEndDate: Scalars['DateTime'];
};

/** Success type for SetAuditObservationPeriod Mutation */
export type SetAuditObservationPeriodSuccess = {
  __typename?: 'SetAuditObservationPeriodSuccess';
  audit?: Maybe<Audit>;
};

/** Payload type for SetAuditObservationPeriod Mutation */
export type SetAuditObservationPeriodPayload = SetAuditObservationPeriodSuccess | BaseUserError;

/** Success type for setKnowBe4TrainingTypes mutation */
export type SetKnowBe4TrainingTypesSuccess = {
  __typename?: 'SetKnowBe4TrainingTypesSuccess';
  success: Scalars['Boolean'];
};

/** Payload for setKnowBe4TrainingTypes mutation */
export type SetKnowBe4TrainingTypesPayload = SetKnowBe4TrainingTypesSuccess | BaseUserError;

/** Input for setEvidenceRequestRenewalCadence mutation */
export type SetEvidenceRequestRenewalCadenceInput = {
  evidenceRequestId: Scalars['String'];
  cadence: Scalars['Duration'];
};

/** SetEvidenceRequestRenewalCadenceSuccess type - fields available after a successful setEvidenceRequestRenewalCadence mutation */
export type SetEvidenceRequestRenewalCadenceSuccess = {
  __typename?: 'SetEvidenceRequestRenewalCadenceSuccess';
  renewalData: RenewalData;
};

/** Payload for setEvidenceRequestRenewalCadence mutation */
export type SetEvidenceRequestRenewalCadencePayload = SetEvidenceRequestRenewalCadenceSuccess | BaseUserError;

/** Input for setEvidenceRequestRenewalDate mutation */
export type SetEvidenceRequestRenewalDateInput = {
  evidenceRequestId: Scalars['String'];
  nextDate: Scalars['DateTime'];
};

/** SetEvidenceRequestRenewalDateSuccess type - fields available after a successful setEvidenceRequestRenewalDate mutation */
export type SetEvidenceRequestRenewalDateSuccess = {
  __typename?: 'SetEvidenceRequestRenewalDateSuccess';
  renewalData: RenewalData;
};

/** Payload for setEvidenceRequestRenewalDate mutation */
export type SetEvidenceRequestRenewalDatePayload = SetEvidenceRequestRenewalDateSuccess | BaseUserError;

/** Input for setEvidenceRequestReminderWindow mutation */
export type SetEvidenceRequestReminderWindowInput = {
  evidenceRequestId: Scalars['String'];
  reminderWindow: Scalars['Duration'];
};

/** SetEvidenceRequestReminderWindowSuccess type - fields available after a successful setEvidenceRequestReminderWindow mutation */
export type SetEvidenceRequestReminderWindowSuccess = {
  __typename?: 'SetEvidenceRequestReminderWindowSuccess';
  renewalData: RenewalData;
};

/** Payload for setEvidenceRequestReminderWindow mutation */
export type SetEvidenceRequestReminderWindowPayload = SetEvidenceRequestReminderWindowSuccess | BaseUserError;

/** Input for clearEvidenceRequestRenewalDate mutation */
export type ClearEvidenceRequestRenewalDateInput = {
  evidenceRequestId: Scalars['String'];
};

/** ClearEvidenceRequestRenewalDateSuccess type - fields available after a successful clearEvidenceRequestRenewalDate mutation */
export type ClearEvidenceRequestRenewalDateSuccess = {
  __typename?: 'ClearEvidenceRequestRenewalDateSuccess';
  renewalData: RenewalData;
};

/** Payload for clearEvidenceRequestRenewalDate mutation */
export type ClearEvidenceRequestRenewalDatePayload = ClearEvidenceRequestRenewalDateSuccess | BaseUserError;

/** Input for updateTestDeactivatedReason mutation */
export type UpdateTestDeactivatedReasonInput = {
  domainId: Scalars['String'];
  testId: Scalars['String'];
  newReason: Scalars['String'];
};

/** UpdateTestDeactivatedReasonSuccess type - fields available after a successful updateTestDeactivatedReason mutation */
export type UpdateTestDeactivatedReasonSuccess = {
  __typename?: 'UpdateTestDeactivatedReasonSuccess';
  testDisabledStatus: TestDisabledStatus;
};

/** Payload for updateTestDeactivatedReason mutation */
export type UpdateTestDeactivatedReasonPayload = UpdateTestDeactivatedReasonSuccess | BaseUserError;

/** deleteSecurityTrainingDocument */
export type DeleteSecurityTrainingDocumentInput = {
  uploadedDocumentId: Scalars['ID'];
};

/** Fields available after a successful deleteSecurityTrainingDocument */
export type DeleteSecurityTrainingDocumentSuccess = {
  __typename?: 'DeleteSecurityTrainingDocumentSuccess';
  uploadedDocument?: Maybe<UploadedDocument>;
};

/** Payload for deleteSecurityTrainingDocument mutation */
export type DeleteSecurityTrainingDocumentPayload = DeleteSecurityTrainingDocumentSuccess | BaseUserError;

/** Input for _manuallyAttemptHrUserLinkForDomain mutation */
export type ManuallyAttemptHrUserLinkForDomainInput = {
  domainId: Scalars['String'];
};

/** ManuallyAttemptHrUserLinkForDomainSuccess type - fields available after a successful _manuallyAttemptHrUserLinkForDomain mutation */
export type ManuallyAttemptHrUserLinkForDomainSuccess = {
  __typename?: 'ManuallyAttemptHrUserLinkForDomainSuccess';
  numAdded: Scalars['Int'];
  numTotal: Scalars['Int'];
};

/** Payload for _manuallyAttemptHrUserLinkForDomain mutation */
export type ManuallyAttemptHrUserLinkForDomainPayload = ManuallyAttemptHrUserLinkForDomainSuccess | BaseUserError;

/** Input for _markTestHistoryAsInvalid mutation */
export type MarkTestHistoryAsInvalidInput = {
  domainIds: Array<Scalars['String']>;
  testId: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  reason: Scalars['String'];
};

/** MarkTestHistoryAsInvalidSuccess type - fields available after a successful _markTestHistoryAsInvalid mutation */
export type MarkTestHistoryAsInvalidSuccess = {
  __typename?: 'MarkTestHistoryAsInvalidSuccess';
  success: Scalars['Boolean'];
};

/** Payload for _markTestHistoryAsInvalid mutation */
export type MarkTestHistoryAsInvalidPayload = MarkTestHistoryAsInvalidSuccess | BaseUserError;

/** Input for _removePolicyAcceptances mutation */
export type RemovePolicyAcceptancesInput = {
  domainId: Scalars['ID'];
  policyTypes: Array<Scalars['String']>;
  acceptedBefore?: Maybe<Scalars['DateTime']>;
  dryRun: Scalars['Boolean'];
};

/** Success type for _removePolicyAcceptances mutation */
export type RemovePolicyAcceptancesSuccess = {
  __typename?: 'RemovePolicyAcceptancesSuccess';
  nPolicyAcceptancesRemoved: Scalars['Int'];
  dryRun: Scalars['Boolean'];
};

/** Payload for _removePolicyAcceptances mutation */
export type RemovePolicyAcceptancesPayload = RemovePolicyAcceptancesSuccess | BaseUserError;

/** Input for _removeRoleFromUsers mutation */
export type RemoveRoleFromUsersInput = {
  userIds: Array<Scalars['String']>;
  removeAllUsers: Scalars['Boolean'];
  domainId: Scalars['String'];
  roleId: Scalars['String'];
};

/** RemoveRoleFromUsersSuccess type - fields available after a successful _removeRoleFromUsers mutation */
export type RemoveRoleFromUsersSuccess = {
  __typename?: 'RemoveRoleFromUsersSuccess';
  modifiedUsersCount: Scalars['Int'];
};

/** Payload for _removeRoleFromUsers mutation */
export type RemoveRoleFromUsersPayload = RemoveRoleFromUsersSuccess | BaseUserError;

/** Input for _setUserSecurityTrainingVideoCompletion mutation */
export type SetUserSecurityTrainingVideoCompletionInput = {
  categoryId: SecurityTrainingCategoryId;
  userId: Scalars['String'];
};

/** _setUserSecurityTrainingVideoCompletionSuccess type - fields available after a successful _setUserSecurityTrainingVideoCompletion mutation */
export type SetUserSecurityTrainingVideoCompletionSuccess = {
  __typename?: 'SetUserSecurityTrainingVideoCompletionSuccess';
  success: Scalars['Boolean'];
};

/** Payload for _setUserSecurityTrainingVideoCompletion mutation */
export type SetUserSecurityTrainingVideoCompletionPayload = SetUserSecurityTrainingVideoCompletionSuccess | BaseUserError;

/** Input for setEmployeeDigestNotificationFrequency mutation */
export type SetEmployeeDigestNotificationFrequencyInput = {
  frequency: NotificationFrequency;
};

/** SetEmployeeDigestNotificationFrequencySuccess type - fields available after a successful setEmployeeDigestNotificationFrequency mutation */
export type SetEmployeeDigestNotificationFrequencySuccess = {
  __typename?: 'SetEmployeeDigestNotificationFrequencySuccess';
  notificationSettings: NotificationSettings;
};

/** Payload for setEmployeeDigestNotificationFrequency */
export type SetEmployeeDigestNotificationFrequencyPayload = SetEmployeeDigestNotificationFrequencySuccess | BaseUserError;

/** Success for generatedReportAutologin */
export type GeneratedReportAutologinSuccess = {
  __typename?: 'GeneratedReportAutologinSuccess';
  report: PresentedReport;
};

/** Payload for generatedReportAutologin */
export type GeneratedReportAutologinPayload = GeneratedReportAutologinSuccess | BaseUserError;

/** Input for markInterestForStandard */
export type MarkInterestForStandardInput = {
  standard: ReportStandard;
};

/** Success for markInterestForStandard */
export type MarkInterestForStandardSuccess = {
  __typename?: 'MarkInterestForStandardSuccess';
  interestedStandards: Array<ReportStandard>;
};

/** Payload for markInterestForStandard */
export type MarkInterestForStandardPayload = MarkInterestForStandardSuccess | BaseUserError;

/** Base interface for any resource. Autogenerated. */
export type Resource = {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Base interface for any Account resource. Autogenerated. */
export type GenericAccountResource = {
  accountId: Scalars['String'];
  accountName: Scalars['String'];
  createDate?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mfa?: Maybe<Scalars['Boolean']>;
  role?: Maybe<Scalars['String']>;
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Base interface for any AccountGroup resource. Autogenerated. */
export type GenericAccountGroupResource = {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Base interface for any Alarm resource. Autogenerated. */
export type GenericAlarmResource = {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Base interface for any BackgroundCheck resource. Autogenerated. */
export type GenericBackgroundCheckResource = {
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  service: Scalars['String'];
  status: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Base interface for any CloudRole resource. Autogenerated. */
export type GenericCloudRoleResource = {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Base interface for any CloudRoleGrant resource. Autogenerated. */
export type GenericCloudRoleGrantResource = {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  roleUniqueId: Scalars['String'];
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Base interface for any ComputeInstance resource. Autogenerated. */
export type GenericComputeInstanceResource = {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  instanceId: Scalars['String'];
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Base interface for any ContainerRepository resource. Autogenerated. */
export type GenericContainerRepositoryResource = {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Base interface for any CredentialReport resource. Autogenerated. */
export type GenericCredentialReportResource = {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Base interface for any CveRecord resource. Autogenerated. */
export type GenericCveRecordResource = {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  score?: Maybe<Scalars['Float']>;
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Base interface for any DataWarehouse resource. Autogenerated. */
export type GenericDataWarehouseResource = {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Base interface for any EmptyResource resource. Autogenerated. */
export type GenericEmptyResourceResource = {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Base interface for any FlowLog resource. Autogenerated. */
export type GenericFlowLogResource = {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Base interface for any HrUser resource. Autogenerated. */
export type GenericHrUserResource = {
  autolinkedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  department?: Maybe<Scalars['String']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  employmentSubtype?: Maybe<Scalars['String']>;
  employmentType?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['DateTime']>;
  externalURL?: Maybe<Scalars['String']>;
  familyName: Scalars['String'];
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  givenName: Scalars['String'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  jobTitle?: Maybe<Scalars['String']>;
  service: Scalars['String'];
  startDate: Scalars['DateTime'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Base interface for any LoadBalancer resource. Autogenerated. */
export type GenericLoadBalancerResource = {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Base interface for any LogGroup resource. Autogenerated. */
export type GenericLogGroupResource = {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Base interface for any LogTrail resource. Autogenerated. */
export type GenericLogTrailResource = {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Base interface for any ManagedComputer resource. Autogenerated. */
export type GenericManagedComputerResource = {
  antivirusNames: Array<Scalars['String']>;
  applications: Array<ManagedComputer_Applications>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  hardware?: Maybe<ManagedComputer_Hardware>;
  hasScreenlock?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isEncrypted: Scalars['Boolean'];
  lastEnrollmentDate: Scalars['DateTime'];
  localUserAccounts: Array<ManagedComputer_LocalUserAccounts>;
  name: Scalars['String'];
  operatingSystem?: Maybe<ManagedComputer_OperatingSystem>;
  passwordManagers: Array<ManagedComputer_PasswordManagers>;
  service: Scalars['String'];
  udid?: Maybe<Scalars['String']>;
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Base interface for any ManagedInstanceGroup resource. Autogenerated. */
export type GenericManagedInstanceGroupResource = {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Base interface for any Network resource. Autogenerated. */
export type GenericNetworkResource = {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Base interface for any NetworkACL resource. Autogenerated. */
export type GenericNetworkAclResource = {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Base interface for any NoSQLDatabase resource. Autogenerated. */
export type GenericNoSqlDatabaseResource = {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Base interface for any PaaSApp resource. Autogenerated. */
export type GenericPaaSAppResource = {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Base interface for any PackageChangelogResource resource. Autogenerated. */
export type GenericPackageChangelogResourceResource = {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  dependentPackageSecurityVersions?: Maybe<Array<PackageChangelogResource_DependentPackageSecurityVersions>>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mostRecentSecurityVersion?: Maybe<PackageChangelogResource_MostRecentSecurityVersion>;
  name: Scalars['String'];
  os: Scalars['String'];
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
  versions: Array<PackageChangelogResource_Versions>;
};

/** Base interface for any PasswordPolicy resource. Autogenerated. */
export type GenericPasswordPolicyResource = {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Base interface for any Queue resource. Autogenerated. */
export type GenericQueueResource = {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Base interface for any RouteTable resource. Autogenerated. */
export type GenericRouteTableResource = {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Base interface for any SQLDatabase resource. Autogenerated. */
export type GenericSqlDatabaseResource = {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Base interface for any SecurityGroup resource. Autogenerated. */
export type GenericSecurityGroupResource = {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Base interface for any Ssl resource. Autogenerated. */
export type GenericSslResource = {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Base interface for any StorageBucket resource. Autogenerated. */
export type GenericStorageBucketResource = {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Base interface for any Subnet resource. Autogenerated. */
export type GenericSubnetResource = {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Base interface for any TaskTrackerItem resource. Autogenerated. */
export type GenericTaskTrackerItemResource = {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  owner?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['String']>;
  service: Scalars['String'];
  tags: Array<Scalars['String']>;
  taskClosed: Scalars['Boolean'];
  taskClosedAt?: Maybe<Scalars['DateTime']>;
  taskCreatedAt: Scalars['DateTime'];
  title: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Base interface for any Topic resource. Autogenerated. */
export type GenericTopicResource = {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Base interface for any Training resource. Autogenerated. */
export type GenericTrainingResource = {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Base interface for any TrainingRequirement resource. Autogenerated. */
export type GenericTrainingRequirementResource = {
  completionDate?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Base interface for any UserGroup resource. Autogenerated. */
export type GenericUserGroupResource = {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Base interface for any UserResource resource. Autogenerated. */
export type GenericUserResourceResource = {
  createdAt: Scalars['DateTime'];
  creationTime?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  email: Scalars['String'];
  externalURL?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  hasMFA?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  imageId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  lastLoginTime?: Maybe<Scalars['String']>;
  service: Scalars['String'];
  suspended?: Maybe<Scalars['Boolean']>;
  suspendedDate?: Maybe<Scalars['String']>;
  suspensionReason?: Maybe<Scalars['String']>;
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Base interface for any VPC resource. Autogenerated. */
export type GenericVpcResource = {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Base interface for any VersionControlRepo resource. Autogenerated. */
export type GenericVersionControlRepoResource = {
  createdAt: Scalars['DateTime'];
  defaultBranch: Scalars['String'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastChangeToRepo?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  pullRequestsTemplate?: Maybe<Scalars['String']>;
  requiredApprovalsToMergeToDefaultBranch: Scalars['Float'];
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Base interface for any Vulnerability resource. Autogenerated. */
export type GenericVulnerabilityResource = {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  resolvedWithinSLA?: Maybe<Scalars['Boolean']>;
  service: Scalars['String'];
  severity: Scalars['Float'];
  slaDeadline?: Maybe<Scalars['DateTime']>;
  slaMillis?: Maybe<Scalars['Float']>;
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Base interface for any VulnerabilityMonitoringTarget resource. Autogenerated. */
export type GenericVulnerabilityMonitoringTargetResource = {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a ALB resource. Autogenerated. */
export type SpecificAlbResource = GenericLoadBalancerResource & Resource & {
  __typename?: 'SpecificALBResource';
  account: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  listenerList?: Maybe<Array<Alb_ListenerList>>;
  name: Scalars['String'];
  prettyName: Scalars['String'];
  region: Scalars['String'];
  scheme?: Maybe<Scalars['String']>;
  service: Scalars['String'];
  tags: Array<Alb_Tags>;
  type: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a AdpRunHrUser resource. Autogenerated. */
export type SpecificAdpRunHrUserResource = GenericHrUserResource & Resource & {
  __typename?: 'SpecificAdpRunHrUserResource';
  autolinkedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  department?: Maybe<Scalars['String']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  employmentSubtype?: Maybe<Scalars['String']>;
  employmentType?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['DateTime']>;
  externalURL?: Maybe<Scalars['String']>;
  familyName: Scalars['String'];
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  givenName: Scalars['String'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  jobTitle?: Maybe<Scalars['String']>;
  service: Scalars['String'];
  startDate: Scalars['DateTime'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a AdpWorkforceNowHrUser resource. Autogenerated. */
export type SpecificAdpWorkforceNowHrUserResource = GenericHrUserResource & Resource & {
  __typename?: 'SpecificAdpWorkforceNowHrUserResource';
  autolinkedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  department?: Maybe<Scalars['String']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  employmentSubtype?: Maybe<Scalars['String']>;
  employmentType?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['DateTime']>;
  externalURL?: Maybe<Scalars['String']>;
  familyName: Scalars['String'];
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  givenName: Scalars['String'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  jobTitle?: Maybe<Scalars['String']>;
  service: Scalars['String'];
  startDate: Scalars['DateTime'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a AirtableTask resource. Autogenerated. */
export type SpecificAirtableTaskResource = GenericTaskTrackerItemResource & Resource & {
  __typename?: 'SpecificAirtableTaskResource';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  owner?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['String']>;
  service: Scalars['String'];
  tags: Array<Scalars['String']>;
  taskClosed: Scalars['Boolean'];
  taskClosedAt?: Maybe<Scalars['DateTime']>;
  taskCreatedAt: Scalars['DateTime'];
  title: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a AmazonLinuxPackageChangelog resource. Autogenerated. */
export type SpecificAmazonLinuxPackageChangelogResource = GenericPackageChangelogResourceResource & Resource & {
  __typename?: 'SpecificAmazonLinuxPackageChangelogResource';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  dependentPackageSecurityVersions?: Maybe<Array<PackageChangelogResource_DependentPackageSecurityVersions>>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mostRecentSecurityVersion?: Maybe<PackageChangelogResource_MostRecentSecurityVersion>;
  name: Scalars['String'];
  os: Scalars['String'];
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
  versions: Array<PackageChangelogResource_Versions>;
};

/** Defines the fields available on a AsanaAccount resource. Autogenerated. */
export type SpecificAsanaAccountResource = GenericAccountResource & Resource & {
  __typename?: 'SpecificAsanaAccountResource';
  accountId: Scalars['String'];
  accountName: Scalars['String'];
  createDate?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mfa?: Maybe<Scalars['Boolean']>;
  role?: Maybe<Scalars['String']>;
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
  workspaceId?: Maybe<Scalars['String']>;
};

/** Defines the fields available on a AsanaTask resource. Autogenerated. */
export type SpecificAsanaTaskResource = GenericTaskTrackerItemResource & Resource & {
  __typename?: 'SpecificAsanaTaskResource';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  owner?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['String']>;
  service: Scalars['String'];
  tags: Array<Scalars['String']>;
  taskClosed: Scalars['Boolean'];
  taskClosedAt?: Maybe<Scalars['DateTime']>;
  taskCreatedAt: Scalars['DateTime'];
  title: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a AtlasAccount resource. Autogenerated. */
export type SpecificAtlasAccountResource = GenericAccountResource & Resource & {
  __typename?: 'SpecificAtlasAccountResource';
  accountId: Scalars['String'];
  accountName: Scalars['String'];
  createDate?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mfa?: Maybe<Scalars['Boolean']>;
  role?: Maybe<Scalars['String']>;
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a AtlasCluster resource. Autogenerated. */
export type SpecificAtlasClusterResource = GenericNoSqlDatabaseResource & Resource & {
  __typename?: 'SpecificAtlasClusterResource';
  autoscalingComputeEnabled: Scalars['Boolean'];
  autoscalingDiskEnabled: Scalars['Boolean'];
  backupEnabled: Scalars['Boolean'];
  clusterTier: Scalars['String'];
  clusterType: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  diskSizeGB: Scalars['Float'];
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mongoDBVersion: Scalars['String'];
  name: Scalars['String'];
  pitEnabled: Scalars['Boolean'];
  projectId: Scalars['String'];
  replicationFactor: Scalars['Float'];
  restoreWindowDays?: Maybe<Scalars['Float']>;
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a AutoScalingGroup resource. Autogenerated. */
export type SpecificAutoScalingGroupResource = GenericManagedInstanceGroupResource & Resource & {
  __typename?: 'SpecificAutoScalingGroupResource';
  account: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  instanceIds: Array<Scalars['String']>;
  launchConfigurationName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  region: Scalars['String'];
  securityGroupIds: Array<Scalars['String']>;
  service: Scalars['String'];
  tags: Array<AutoScalingGroup_Tags>;
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a AwsAccount resource. Autogenerated. */
export type SpecificAwsAccountResource = GenericAccountResource & Resource & {
  __typename?: 'SpecificAwsAccountResource';
  account: Scalars['String'];
  accountId: Scalars['String'];
  accountName: Scalars['String'];
  attachedManagedPoliciesCount: Scalars['Float'];
  createDate?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  groupList: Array<Scalars['String']>;
  hasMfaDevice: Scalars['Boolean'];
  id: Scalars['ID'];
  mfa?: Maybe<Scalars['Boolean']>;
  passwordLastCreated?: Maybe<Scalars['DateTime']>;
  passwordLastUsed?: Maybe<Scalars['DateTime']>;
  role?: Maybe<Scalars['String']>;
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a AwsContainerVulnerability resource. Autogenerated. */
export type SpecificAwsContainerVulnerabilityResource = GenericVulnerabilityResource & Resource & {
  __typename?: 'SpecificAwsContainerVulnerabilityResource';
  account: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  findings: Array<AwsContainerVulnerability_Findings>;
  id: Scalars['ID'];
  imageDigest: Scalars['String'];
  imageTags?: Maybe<Array<Scalars['String']>>;
  inScope?: Maybe<Scalars['Boolean']>;
  packageIdentifier: Scalars['String'];
  packageName: Scalars['String'];
  packageVersion: Scalars['String'];
  region: Scalars['String'];
  repositoryArn: Scalars['String'];
  repositoryName: Scalars['String'];
  resolvedWithinSLA?: Maybe<Scalars['Boolean']>;
  service: Scalars['String'];
  severity: Scalars['Float'];
  slaDeadline?: Maybe<Scalars['DateTime']>;
  slaMillis?: Maybe<Scalars['Float']>;
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a AwsCredentialReport resource. Autogenerated. */
export type SpecificAwsCredentialReportResource = GenericCredentialReportResource & Resource & {
  __typename?: 'SpecificAwsCredentialReportResource';
  accesskey1LastRotated?: Maybe<Scalars['DateTime']>;
  accesskey1LastUsed?: Maybe<Scalars['DateTime']>;
  accesskey2LastRotated?: Maybe<Scalars['DateTime']>;
  accesskey2LastUsed?: Maybe<Scalars['DateTime']>;
  account: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mfaActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  passwordEnabled?: Maybe<Scalars['Boolean']>;
  passwordLastChanged?: Maybe<Scalars['DateTime']>;
  passwordLastUsed?: Maybe<Scalars['DateTime']>;
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userCreationDate: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a AwsFlowLog resource. Autogenerated. */
export type SpecificAwsFlowLogResource = GenericFlowLogResource & Resource & {
  __typename?: 'SpecificAwsFlowLogResource';
  account: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  region: Scalars['String'];
  resourceId?: Maybe<Scalars['String']>;
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a AwsGroup resource. Autogenerated. */
export type SpecificAwsGroupResource = GenericAccountGroupResource & Resource & {
  __typename?: 'SpecificAwsGroupResource';
  account: Scalars['String'];
  attachedManagedPolicies: Array<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  groupPolicies: Array<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a AwsInspectorVulnerability resource. Autogenerated. */
export type SpecificAwsInspectorVulnerabilityResource = GenericVulnerabilityResource & Resource & {
  __typename?: 'SpecificAwsInspectorVulnerabilityResource';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL: Scalars['String'];
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  findings: Array<AwsInspectorVulnerability_Findings>;
  id: Scalars['ID'];
  instanceId: Scalars['String'];
  packageIdentifier: Scalars['String'];
  packageName: Scalars['String'];
  resolvedWithinSLA?: Maybe<Scalars['Boolean']>;
  service: Scalars['String'];
  severity: Scalars['Float'];
  slaDeadline?: Maybe<Scalars['DateTime']>;
  slaMillis?: Maybe<Scalars['Float']>;
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a AwsNetworkACL resource. Autogenerated. */
export type SpecificAwsNetworkAclResource = GenericNetworkAclResource & Resource & {
  __typename?: 'SpecificAwsNetworkACLResource';
  account: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  region: Scalars['String'];
  service: Scalars['String'];
  subnetIds: Array<Scalars['String']>;
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a AwsPasswordPolicy resource. Autogenerated. */
export type SpecificAwsPasswordPolicyResource = GenericPasswordPolicyResource & Resource & {
  __typename?: 'SpecificAwsPasswordPolicyResource';
  account: Scalars['String'];
  allowUsersToChangePassword: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  expirePasswords: Scalars['Boolean'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  hardExpiry: Scalars['Boolean'];
  id: Scalars['ID'];
  maxPasswordAge?: Maybe<Scalars['Float']>;
  minimumPasswordLength?: Maybe<Scalars['Float']>;
  passwordReusePrevention?: Maybe<Scalars['Float']>;
  requireLowercaseCharacters: Scalars['Boolean'];
  requireNumbers: Scalars['Boolean'];
  requireSymbols: Scalars['Boolean'];
  requireUppercaseCharacters: Scalars['Boolean'];
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a AwsRole resource. Autogenerated. */
export type SpecificAwsRoleResource = GenericCloudRoleResource & Resource & {
  __typename?: 'SpecificAwsRoleResource';
  account: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a AwsRouteTable resource. Autogenerated. */
export type SpecificAwsRouteTableResource = GenericRouteTableResource & Resource & {
  __typename?: 'SpecificAwsRouteTableResource';
  account: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  region: Scalars['String'];
  routes: Array<AwsRouteTable_Routes>;
  service: Scalars['String'];
  subnetIds: Array<Scalars['String']>;
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
  vpcId: Scalars['String'];
};

/** Defines the fields available on a AwsSecurityGroup resource. Autogenerated. */
export type SpecificAwsSecurityGroupResource = GenericSecurityGroupResource & Resource & {
  __typename?: 'SpecificAwsSecurityGroupResource';
  account: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  inboundRules: Array<AwsSecurityGroup_InboundRules>;
  name: Scalars['String'];
  outboundRules: Array<AwsSecurityGroup_OutboundRules>;
  region: Scalars['String'];
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a AwsSubnet resource. Autogenerated. */
export type SpecificAwsSubnetResource = GenericSubnetResource & Resource & {
  __typename?: 'SpecificAwsSubnetResource';
  account: Scalars['String'];
  availabilityZone: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mapPublicIpOnLaunch: Scalars['Boolean'];
  name: Scalars['String'];
  region: Scalars['String'];
  service: Scalars['String'];
  state: Scalars['String'];
  tags: Array<AwsSubnet_Tags>;
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
  vpcId: Scalars['String'];
};

/** Defines the fields available on a AwsVPC resource. Autogenerated. */
export type SpecificAwsVpcResource = GenericVpcResource & Resource & {
  __typename?: 'SpecificAwsVPCResource';
  account: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  region: Scalars['String'];
  service: Scalars['String'];
  tags: Array<AwsVpc_Tags>;
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a AzureApplicationGateway resource. Autogenerated. */
export type SpecificAzureApplicationGatewayResource = GenericLoadBalancerResource & Resource & {
  __typename?: 'SpecificAzureApplicationGatewayResource';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName: Scalars['String'];
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  httpListeners: Array<AzureApplicationGateway_HttpListeners>;
  id: Scalars['ID'];
  location: Scalars['String'];
  name: Scalars['String'];
  redirectConfigurations: Array<Scalars['String']>;
  resourceGroupName: Scalars['String'];
  service: Scalars['String'];
  subscriptionId: Scalars['String'];
  tags: Array<AzureApplicationGateway_Tags>;
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a AzureBlobContainer resource. Autogenerated. */
export type SpecificAzureBlobContainerResource = GenericStorageBucketResource & Resource & {
  __typename?: 'SpecificAzureBlobContainerResource';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  kind?: Maybe<Scalars['String']>;
  location: Scalars['String'];
  loggingEnabled: Scalars['Boolean'];
  name: Scalars['String'];
  resourceGroupName: Scalars['String'];
  service: Scalars['String'];
  softDeleteRetentionDays: Scalars['Float'];
  storageAccountName: Scalars['String'];
  subscriptionId: Scalars['String'];
  tags: Array<AzureBlobContainer_Tags>;
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
  versioningEnabled: Scalars['Boolean'];
};

/** Defines the fields available on a AzureContainerRepository resource. Autogenerated. */
export type SpecificAzureContainerRepositoryResource = GenericContainerRepositoryResource & Resource & {
  __typename?: 'SpecificAzureContainerRepositoryResource';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  host: Scalars['String'];
  id: Scalars['ID'];
  location: Scalars['String'];
  name: Scalars['String'];
  resourceGroupName: Scalars['String'];
  service: Scalars['String'];
  subscriptionId: Scalars['String'];
  tags: Array<AzureContainerRepository_Tags>;
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
  vulnerabilities: Array<SpecificAzureContainerVulnerabilityResource>;
};

/** Defines the fields available on a AzureContainerVulnerability resource. Autogenerated. */
export type SpecificAzureContainerVulnerabilityResource = GenericVulnerabilityResource & Resource & {
  __typename?: 'SpecificAzureContainerVulnerabilityResource';
  createdAt: Scalars['DateTime'];
  cves: Array<AzureContainerVulnerability_Cves>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  imageDigest: Scalars['String'];
  imageTags?: Maybe<Array<Scalars['String']>>;
  inScope?: Maybe<Scalars['Boolean']>;
  packageIdentifier: Scalars['String'];
  repositoryId: Scalars['String'];
  repositoryName: Scalars['String'];
  resolvedWithinSLA?: Maybe<Scalars['Boolean']>;
  service: Scalars['String'];
  severity: Scalars['Float'];
  slaDeadline?: Maybe<Scalars['DateTime']>;
  slaMillis?: Maybe<Scalars['Float']>;
  subscriptionId: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a AzureCosmosDB resource. Autogenerated. */
export type SpecificAzureCosmosDbResource = GenericNoSqlDatabaseResource & Resource & {
  __typename?: 'SpecificAzureCosmosDBResource';
  createdAt: Scalars['DateTime'];
  dbAccountName: Scalars['String'];
  dbApi: Scalars['String'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  location: Scalars['String'];
  name: Scalars['String'];
  resourceGroupName: Scalars['String'];
  service: Scalars['String'];
  subscriptionId: Scalars['String'];
  tags: Array<AzureCosmosDb_Tags>;
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a AzureDatabaseForExternalSQLVariant resource. Autogenerated. */
export type SpecificAzureDatabaseForExternalSqlVariantResource = GenericSqlDatabaseResource & Resource & {
  __typename?: 'SpecificAzureDatabaseForExternalSQLVariantResource';
  backupRetentionDays: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  databaseType: Scalars['String'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  location: Scalars['String'];
  name: Scalars['String'];
  resourceGroupName: Scalars['String'];
  service: Scalars['String'];
  subscriptionId: Scalars['String'];
  tags: Array<AzureDatabaseForExternalSqlVariant_Tags>;
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a AzureDevOpsAccount resource. Autogenerated. */
export type SpecificAzureDevOpsAccountResource = GenericAccountResource & Resource & {
  __typename?: 'SpecificAzureDevOpsAccountResource';
  accountId: Scalars['String'];
  accountName: Scalars['String'];
  createDate?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mfa?: Maybe<Scalars['Boolean']>;
  role?: Maybe<Scalars['String']>;
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a AzureDevOpsRepo resource. Autogenerated. */
export type SpecificAzureDevOpsRepoResource = GenericVersionControlRepoResource & Resource & {
  __typename?: 'SpecificAzureDevOpsRepoResource';
  createdAt: Scalars['DateTime'];
  defaultBranch: Scalars['String'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastChangeToRepo?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  pullRequestsTemplate?: Maybe<Scalars['String']>;
  requiredApprovalsToMergeToDefaultBranch: Scalars['Float'];
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a AzureDevOpsTask resource. Autogenerated. */
export type SpecificAzureDevOpsTaskResource = GenericTaskTrackerItemResource & Resource & {
  __typename?: 'SpecificAzureDevOpsTaskResource';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  owner?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['String']>;
  service: Scalars['String'];
  tags: Array<Scalars['String']>;
  taskClosed: Scalars['Boolean'];
  taskClosedAt?: Maybe<Scalars['DateTime']>;
  taskCreatedAt: Scalars['DateTime'];
  title: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a AzureLoadBalancer resource. Autogenerated. */
export type SpecificAzureLoadBalancerResource = GenericLoadBalancerResource & Resource & {
  __typename?: 'SpecificAzureLoadBalancerResource';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName: Scalars['String'];
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  location: Scalars['String'];
  name: Scalars['String'];
  resourceGroupName: Scalars['String'];
  service: Scalars['String'];
  sku: Scalars['String'];
  subscriptionId: Scalars['String'];
  tags: Array<AzureLoadBalancer_Tags>;
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a AzureMetricAlertRule resource. Autogenerated. */
export type SpecificAzureMetricAlertRuleResource = GenericAlarmResource & Resource & {
  __typename?: 'SpecificAzureMetricAlertRuleResource';
  createdAt: Scalars['DateTime'];
  criteria: Array<AzureMetricAlertRule_Criteria>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  enabled: Scalars['Boolean'];
  evaluationFrequency: Scalars['String'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  location: Scalars['String'];
  name: Scalars['String'];
  resourceGroupName: Scalars['String'];
  scopes: Array<Scalars['String']>;
  service: Scalars['String'];
  subscriptionId: Scalars['String'];
  tags: Array<AzureMetricAlertRule_Tags>;
  targetResourceRegion?: Maybe<Scalars['String']>;
  targetResourceType?: Maybe<Scalars['String']>;
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
  windowSize: Scalars['String'];
};

/** Defines the fields available on a AzureQueue resource. Autogenerated. */
export type SpecificAzureQueueResource = GenericQueueResource & Resource & {
  __typename?: 'SpecificAzureQueueResource';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  location: Scalars['String'];
  name: Scalars['String'];
  resourceGroupName: Scalars['String'];
  service: Scalars['String'];
  storageAccountName: Scalars['String'];
  subscriptionId: Scalars['String'];
  tags: Array<AzureQueue_Tags>;
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a AzureRole resource. Autogenerated. */
export type SpecificAzureRoleResource = GenericCloudRoleResource & Resource & {
  __typename?: 'SpecificAzureRoleResource';
  allowedActionPermissions: Array<Scalars['String']>;
  allowedDataPermissions: Array<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  deniedActionPermissions: Array<Scalars['String']>;
  deniedDataPermissions: Array<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isCustomRole: Scalars['Boolean'];
  name: Scalars['String'];
  service: Scalars['String'];
  subscriptionId: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a AzureRoleAssignment resource. Autogenerated. */
export type SpecificAzureRoleAssignmentResource = GenericCloudRoleGrantResource & Resource & {
  __typename?: 'SpecificAzureRoleAssignmentResource';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  principalId: Scalars['String'];
  principalName: Scalars['String'];
  principalType: Scalars['String'];
  roleUniqueId: Scalars['String'];
  scope?: Maybe<Scalars['String']>;
  service: Scalars['String'];
  subscriptionId: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a AzureSQLDatabase resource. Autogenerated. */
export type SpecificAzureSqlDatabaseResource = GenericSqlDatabaseResource & Resource & {
  __typename?: 'SpecificAzureSQLDatabaseResource';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  encryptionEnabled: Scalars['Boolean'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  location: Scalars['String'];
  name: Scalars['String'];
  resourceGroupName: Scalars['String'];
  service: Scalars['String'];
  shortTermBackupRetentionDays: Scalars['Float'];
  subscriptionId: Scalars['String'];
  tags: Array<AzureSqlDatabase_Tags>;
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a AzureSQLManagedInstance resource. Autogenerated. */
export type SpecificAzureSqlManagedInstanceResource = GenericSqlDatabaseResource & Resource & {
  __typename?: 'SpecificAzureSQLManagedInstanceResource';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  location: Scalars['String'];
  name: Scalars['String'];
  resourceGroupName: Scalars['String'];
  service: Scalars['String'];
  shortTermBackupRetentionDays: Scalars['Float'];
  subscriptionId: Scalars['String'];
  tags: Array<AzureSqlManagedInstance_Tags>;
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a AzureSQLServerOnVMs resource. Autogenerated. */
export type SpecificAzureSqlServerOnVMsResource = GenericSqlDatabaseResource & Resource & {
  __typename?: 'SpecificAzureSQLServerOnVMsResource';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  location: Scalars['String'];
  name: Scalars['String'];
  resourceGroupName: Scalars['String'];
  service: Scalars['String'];
  subscriptionId: Scalars['String'];
  tags: Array<AzureSqlServerOnVMs_Tags>;
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a AzureScaleSetVirtualMachine resource. Autogenerated. */
export type SpecificAzureScaleSetVirtualMachineResource = GenericComputeInstanceResource & Resource & {
  __typename?: 'SpecificAzureScaleSetVirtualMachineResource';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  instanceId: Scalars['String'];
  location: Scalars['String'];
  name: Scalars['String'];
  osquery?: Maybe<Osquery>;
  privateIPAddress?: Maybe<Scalars['String']>;
  publicIPAddress?: Maybe<Scalars['String']>;
  resourceGroupName: Scalars['String'];
  scaleSetUniqueId: Scalars['String'];
  service: Scalars['String'];
  state: Scalars['String'];
  subscriptionId: Scalars['String'];
  tags: Array<AzureScaleSetVirtualMachine_Tags>;
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a AzureSecurityGroup resource. Autogenerated. */
export type SpecificAzureSecurityGroupResource = GenericSecurityGroupResource & Resource & {
  __typename?: 'SpecificAzureSecurityGroupResource';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  flowLogsEnabled: Scalars['Boolean'];
  flowLogsRetentionDays: Scalars['Float'];
  id: Scalars['ID'];
  location: Scalars['String'];
  name: Scalars['String'];
  resourceGroupName: Scalars['String'];
  securityRules: Array<AzureSecurityGroup_SecurityRules>;
  service: Scalars['String'];
  subnetIds: Array<Scalars['String']>;
  subscriptionId: Scalars['String'];
  tags?: Maybe<Array<AzureSecurityGroup_Tags>>;
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a AzureSynapseWarehouse resource. Autogenerated. */
export type SpecificAzureSynapseWarehouseResource = GenericDataWarehouseResource & Resource & {
  __typename?: 'SpecificAzureSynapseWarehouseResource';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  encryptionEnabled: Scalars['Boolean'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  location: Scalars['String'];
  name: Scalars['String'];
  resourceGroupName: Scalars['String'];
  service: Scalars['String'];
  subscriptionId: Scalars['String'];
  tags: Array<AzureSynapseWarehouse_Tags>;
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a AzureVirtualMachine resource. Autogenerated. */
export type SpecificAzureVirtualMachineResource = GenericComputeInstanceResource & Resource & {
  __typename?: 'SpecificAzureVirtualMachineResource';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  instanceId: Scalars['String'];
  location: Scalars['String'];
  name: Scalars['String'];
  osquery?: Maybe<Osquery>;
  privateIPAddress?: Maybe<Scalars['String']>;
  publicIPAddress?: Maybe<Scalars['String']>;
  resourceGroupName: Scalars['String'];
  service: Scalars['String'];
  state: Scalars['String'];
  subscriptionId: Scalars['String'];
  tags: Array<AzureVirtualMachine_Tags>;
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a AzureVirtualMachineScaleSet resource. Autogenerated. */
export type SpecificAzureVirtualMachineScaleSetResource = GenericManagedInstanceGroupResource & Resource & {
  __typename?: 'SpecificAzureVirtualMachineScaleSetResource';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  instanceIds: Array<Scalars['String']>;
  location: Scalars['String'];
  name: Scalars['String'];
  resourceGroupName: Scalars['String'];
  scaleSetUniqueId: Scalars['String'];
  service: Scalars['String'];
  subscriptionId: Scalars['String'];
  tags: Array<AzureVirtualMachineScaleSet_Tags>;
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a BambooHrHrUser resource. Autogenerated. */
export type SpecificBambooHrHrUserResource = GenericHrUserResource & Resource & {
  __typename?: 'SpecificBambooHrHrUserResource';
  autolinkedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  department?: Maybe<Scalars['String']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  employmentSubtype?: Maybe<Scalars['String']>;
  employmentType?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['DateTime']>;
  externalURL?: Maybe<Scalars['String']>;
  familyName: Scalars['String'];
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  givenName: Scalars['String'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  jobTitle?: Maybe<Scalars['String']>;
  service: Scalars['String'];
  startDate: Scalars['DateTime'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a BigQueryDataset resource. Autogenerated. */
export type SpecificBigQueryDatasetResource = GenericDataWarehouseResource & Resource & {
  __typename?: 'SpecificBigQueryDatasetResource';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  labels: Array<BigQueryDataset_Labels>;
  name: Scalars['String'];
  projectId: Scalars['String'];
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a BigtableInstance resource. Autogenerated. */
export type SpecificBigtableInstanceResource = GenericNoSqlDatabaseResource & Resource & {
  __typename?: 'SpecificBigtableInstanceResource';
  clusterIds: Array<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  labels: Array<BigtableInstance_Labels>;
  name: Scalars['String'];
  projectId: Scalars['String'];
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a BitbucketAccount resource. Autogenerated. */
export type SpecificBitbucketAccountResource = GenericAccountResource & Resource & {
  __typename?: 'SpecificBitbucketAccountResource';
  accountId: Scalars['String'];
  accountName: Scalars['String'];
  createDate?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mfa?: Maybe<Scalars['Boolean']>;
  role?: Maybe<Scalars['String']>;
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a BitbucketRepo resource. Autogenerated. */
export type SpecificBitbucketRepoResource = GenericVersionControlRepoResource & Resource & {
  __typename?: 'SpecificBitbucketRepoResource';
  createdAt: Scalars['DateTime'];
  defaultBranch: Scalars['String'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastChangeToRepo?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  pullRequestsTemplate?: Maybe<Scalars['String']>;
  requiredApprovalsToMergeToDefaultBranch: Scalars['Float'];
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a CentosPackageChangelog resource. Autogenerated. */
export type SpecificCentosPackageChangelogResource = GenericPackageChangelogResourceResource & Resource & {
  __typename?: 'SpecificCentosPackageChangelogResource';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  dependentPackageSecurityVersions?: Maybe<Array<PackageChangelogResource_DependentPackageSecurityVersions>>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mostRecentSecurityVersion?: Maybe<PackageChangelogResource_MostRecentSecurityVersion>;
  name: Scalars['String'];
  os: Scalars['String'];
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
  versions: Array<PackageChangelogResource_Versions>;
};

/** Defines the fields available on a CertnBackgroundCheck resource. Autogenerated. */
export type SpecificCertnBackgroundCheckResource = GenericBackgroundCheckResource & Resource & {
  __typename?: 'SpecificCertnBackgroundCheckResource';
  applicantId: Scalars['String'];
  applicationId: Scalars['String'];
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  email: Scalars['String'];
  externalURL?: Maybe<Scalars['String']>;
  familyName: Scalars['String'];
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  givenName: Scalars['String'];
  id: Scalars['ID'];
  result: Scalars['String'];
  service: Scalars['String'];
  status: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a ClickupAccount resource. Autogenerated. */
export type SpecificClickupAccountResource = GenericAccountResource & Resource & {
  __typename?: 'SpecificClickupAccountResource';
  accountId: Scalars['String'];
  accountName: Scalars['String'];
  createDate?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mfa?: Maybe<Scalars['Boolean']>;
  role?: Maybe<Scalars['String']>;
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a ClickupTask resource. Autogenerated. */
export type SpecificClickupTaskResource = GenericTaskTrackerItemResource & Resource & {
  __typename?: 'SpecificClickupTaskResource';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  owner?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['String']>;
  service: Scalars['String'];
  tags: Array<Scalars['String']>;
  taskClosed: Scalars['Boolean'];
  taskClosedAt?: Maybe<Scalars['DateTime']>;
  taskCreatedAt: Scalars['DateTime'];
  title: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a CloudSQLInstance resource. Autogenerated. */
export type SpecificCloudSqlInstanceResource = GenericSqlDatabaseResource & Resource & {
  __typename?: 'SpecificCloudSQLInstanceResource';
  backupsEnabled: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  labels: Array<CloudSqlInstance_Labels>;
  name: Scalars['String'];
  projectId: Scalars['String'];
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a CloudTrail resource. Autogenerated. */
export type SpecificCloudTrailResource = GenericLogTrailResource & Resource & {
  __typename?: 'SpecificCloudTrailResource';
  account: Scalars['String'];
  bucketName: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  multiRegion?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  region?: Maybe<Scalars['String']>;
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a CloudWatchLogGroup resource. Autogenerated. */
export type SpecificCloudWatchLogGroupResource = GenericLogGroupResource & Resource & {
  __typename?: 'SpecificCloudWatchLogGroupResource';
  account: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  region: Scalars['String'];
  retentionInDays?: Maybe<Scalars['Float']>;
  service: Scalars['String'];
  tags?: Maybe<Array<CloudWatchLogGroup_Tags>>;
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a CloudWatchMetricAlarm resource. Autogenerated. */
export type SpecificCloudWatchMetricAlarmResource = GenericAlarmResource & Resource & {
  __typename?: 'SpecificCloudWatchMetricAlarmResource';
  account: Scalars['String'];
  actionsEnabled: Scalars['Boolean'];
  comparisonOperator: Scalars['String'];
  configurationUpdated: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  dimensions: Array<CloudWatchMetricAlarm_Dimensions>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  evaluationPeriodsCount: Scalars['Float'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  metricName?: Maybe<Scalars['String']>;
  metrics: Array<CloudWatchMetricAlarm_Metrics>;
  name: Scalars['String'];
  namespace?: Maybe<Scalars['String']>;
  periodInSeconds?: Maybe<Scalars['Float']>;
  region: Scalars['String'];
  service: Scalars['String'];
  statistic?: Maybe<Scalars['String']>;
  threshold?: Maybe<Scalars['Float']>;
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a ClubhouseAccount resource. Autogenerated. */
export type SpecificClubhouseAccountResource = GenericAccountResource & Resource & {
  __typename?: 'SpecificClubhouseAccountResource';
  accountId: Scalars['String'];
  accountName: Scalars['String'];
  createDate?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mfa?: Maybe<Scalars['Boolean']>;
  role?: Maybe<Scalars['String']>;
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a ClubhouseTask resource. Autogenerated. */
export type SpecificClubhouseTaskResource = GenericTaskTrackerItemResource & Resource & {
  __typename?: 'SpecificClubhouseTaskResource';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  owner?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['String']>;
  service: Scalars['String'];
  tags: Array<Scalars['String']>;
  taskClosed: Scalars['Boolean'];
  taskClosedAt?: Maybe<Scalars['DateTime']>;
  taskCreatedAt: Scalars['DateTime'];
  title: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a CustomerCheckrBackgroundCheck resource. Autogenerated. */
export type SpecificCustomerCheckrBackgroundCheckResource = GenericBackgroundCheckResource & Resource & {
  __typename?: 'SpecificCustomerCheckrBackgroundCheckResource';
  candidateId: Scalars['String'];
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  email: Scalars['String'];
  externalURL?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  service: Scalars['String'];
  status: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a DatadogAccount resource. Autogenerated. */
export type SpecificDatadogAccountResource = GenericAccountResource & Resource & {
  __typename?: 'SpecificDatadogAccountResource';
  accountId: Scalars['String'];
  accountName: Scalars['String'];
  createDate?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mfa?: Maybe<Scalars['Boolean']>;
  role?: Maybe<Scalars['String']>;
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a DatadogMonitor resource. Autogenerated. */
export type SpecificDatadogMonitorResource = GenericAlarmResource & Resource & {
  __typename?: 'SpecificDatadogMonitorResource';
  created: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  message: Scalars['String'];
  modified: Scalars['DateTime'];
  name: Scalars['String'];
  overall_state: Scalars['String'];
  query: Scalars['String'];
  service: Scalars['String'];
  type: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a DatastoreProject resource. Autogenerated. */
export type SpecificDatastoreProjectResource = GenericNoSqlDatabaseResource & Resource & {
  __typename?: 'SpecificDatastoreProjectResource';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a DebianPackageChangelog resource. Autogenerated. */
export type SpecificDebianPackageChangelogResource = GenericPackageChangelogResourceResource & Resource & {
  __typename?: 'SpecificDebianPackageChangelogResource';
  codename: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  dependentPackageSecurityVersions?: Maybe<Array<PackageChangelogResource_DependentPackageSecurityVersions>>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mostRecentSecurityVersion?: Maybe<PackageChangelogResource_MostRecentSecurityVersion>;
  name: Scalars['String'];
  os: Scalars['String'];
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
  versions: Array<PackageChangelogResource_Versions>;
};

/** Defines the fields available on a DigitalOceanApp resource. Autogenerated. */
export type SpecificDigitalOceanAppResource = GenericPaaSAppResource & Resource & {
  __typename?: 'SpecificDigitalOceanAppResource';
  appDatabases: Array<DigitalOceanApp_AppDatabases>;
  appServices: Array<DigitalOceanApp_AppServices>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  ownerUuid: Scalars['String'];
  region: Scalars['String'];
  service: Scalars['String'];
  tierSlug: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a DigitalOceanContainerRepository resource. Autogenerated. */
export type SpecificDigitalOceanContainerRepositoryResource = GenericContainerRepositoryResource & Resource & {
  __typename?: 'SpecificDigitalOceanContainerRepositoryResource';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a DigitalOceanDroplet resource. Autogenerated. */
export type SpecificDigitalOceanDropletResource = GenericComputeInstanceResource & Resource & {
  __typename?: 'SpecificDigitalOceanDropletResource';
  createdAt: Scalars['DateTime'];
  creationTime: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  instanceId: Scalars['String'];
  name: Scalars['String'];
  rawTags: Array<Scalars['String']>;
  region: Scalars['String'];
  service: Scalars['String'];
  status: Scalars['String'];
  tags: Array<DigitalOceanDroplet_Tags>;
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
  vpcUuid: Scalars['String'];
};

/** Defines the fields available on a DigitalOceanFirewall resource. Autogenerated. */
export type SpecificDigitalOceanFirewallResource = GenericSecurityGroupResource & Resource & {
  __typename?: 'SpecificDigitalOceanFirewallResource';
  createdAt: Scalars['DateTime'];
  creationTime: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  dropletIds: Array<Scalars['String']>;
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  inboundRules: Array<DigitalOceanFirewall_InboundRules>;
  name: Scalars['String'];
  outboundRules: Array<DigitalOceanFirewall_OutboundRules>;
  service: Scalars['String'];
  status: Scalars['String'];
  tags: Array<Scalars['String']>;
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a DigitalOceanLoadBalancer resource. Autogenerated. */
export type SpecificDigitalOceanLoadBalancerResource = GenericLoadBalancerResource & Resource & {
  __typename?: 'SpecificDigitalOceanLoadBalancerResource';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName: Scalars['String'];
  domainId: Scalars['ID'];
  dropletIds: Array<Scalars['String']>;
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  forwardingRules: Array<DigitalOceanLoadBalancer_ForwardingRules>;
  id: Scalars['ID'];
  name: Scalars['String'];
  redirectHttpToHttps: Scalars['Boolean'];
  region: Scalars['String'];
  service: Scalars['String'];
  tag: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
  vpcUuid: Scalars['String'];
};

/** Defines the fields available on a DigitalOceanRedisCluster resource. Autogenerated. */
export type SpecificDigitalOceanRedisClusterResource = GenericNoSqlDatabaseResource & Resource & {
  __typename?: 'SpecificDigitalOceanRedisClusterResource';
  createdAt: Scalars['DateTime'];
  creationTime: Scalars['DateTime'];
  databaseNames: Array<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  rawTags: Array<Scalars['String']>;
  region: Scalars['String'];
  service: Scalars['String'];
  status: Scalars['String'];
  tags: Array<DigitalOceanRedisCluster_Tags>;
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
  vpcUuid: Scalars['String'];
};

/** Defines the fields available on a DigitalOceanRelationalDbCluster resource. Autogenerated. */
export type SpecificDigitalOceanRelationalDbClusterResource = GenericSqlDatabaseResource & Resource & {
  __typename?: 'SpecificDigitalOceanRelationalDbClusterResource';
  createdAt: Scalars['DateTime'];
  creationTime: Scalars['DateTime'];
  databaseNames: Array<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  engine: Scalars['String'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  rawTags: Array<Scalars['String']>;
  region: Scalars['String'];
  service: Scalars['String'];
  status: Scalars['String'];
  tags: Array<DigitalOceanRelationalDbCluster_Tags>;
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
  vpcUuid: Scalars['String'];
};

/** Defines the fields available on a DigitalOceanSpace resource. Autogenerated. */
export type SpecificDigitalOceanSpaceResource = GenericStorageBucketResource & Resource & {
  __typename?: 'SpecificDigitalOceanSpaceResource';
  acls: Array<DigitalOceanSpace_Acls>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  region: Scalars['String'];
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
  versioningEnabled: Scalars['Boolean'];
};

/** Defines the fields available on a DriveBackgroundCheck resource. Autogenerated. */
export type SpecificDriveBackgroundCheckResource = GenericBackgroundCheckResource & Resource & {
  __typename?: 'SpecificDriveBackgroundCheckResource';
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  filename: Scalars['String'];
  id: Scalars['ID'];
  service: Scalars['String'];
  status: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userId?: Maybe<Scalars['String']>;
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a DynamoDBTable resource. Autogenerated. */
export type SpecificDynamoDbTableResource = GenericNoSqlDatabaseResource & Resource & {
  __typename?: 'SpecificDynamoDBTableResource';
  account: Scalars['String'];
  continuousBackupsStatus: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  pointInTimeRecoveryStatus: Scalars['String'];
  region: Scalars['String'];
  service: Scalars['String'];
  tableCreated: Scalars['DateTime'];
  tableId: Scalars['String'];
  tableStatus: Scalars['String'];
  tags: Array<DynamoDbTable_Tags>;
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a EC2Instance resource. Autogenerated. */
export type SpecificEc2InstanceResource = GenericComputeInstanceResource & Resource & {
  __typename?: 'SpecificEC2InstanceResource';
  account: Scalars['String'];
  autoscaleGroupName?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL: Scalars['String'];
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<Ec2Instance_Image>;
  inspector?: Maybe<Ec2Instance_Inspector>;
  instanceId: Scalars['String'];
  launchTime: Scalars['DateTime'];
  name?: Maybe<Scalars['String']>;
  osquery?: Maybe<Osquery>;
  platform?: Maybe<Scalars['String']>;
  privateIPAddress?: Maybe<Scalars['String']>;
  publicIPAddress?: Maybe<Scalars['String']>;
  region: Scalars['String'];
  securityGroupIds: Array<Scalars['String']>;
  service: Scalars['String'];
  state: Scalars['String'];
  subnetId?: Maybe<Scalars['String']>;
  tags: Array<Ec2Instance_Tags>;
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
  vulnerabilities: Array<SpecificAwsInspectorVulnerabilityResource>;
};

/** Defines the fields available on a ECRContainerRepository resource. Autogenerated. */
export type SpecificEcrContainerRepositoryResource = GenericContainerRepositoryResource & Resource & {
  __typename?: 'SpecificECRContainerRepositoryResource';
  account: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  hasBeenScanned?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  region: Scalars['String'];
  scanOnPush: Scalars['Boolean'];
  service: Scalars['String'];
  tags: Array<EcrContainerRepository_Tags>;
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
  vulnerabilities: Array<SpecificAwsContainerVulnerabilityResource>;
};

/** Defines the fields available on a EmptyDailySpecificResource resource. Autogenerated. */
export type SpecificEmptyDailySpecificResourceResource = GenericEmptyResourceResource & Resource & {
  __typename?: 'SpecificEmptyDailySpecificResourceResource';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a EmptySpecificResource resource. Autogenerated. */
export type SpecificEmptySpecificResourceResource = GenericEmptyResourceResource & Resource & {
  __typename?: 'SpecificEmptySpecificResourceResource';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a FirestoreProject resource. Autogenerated. */
export type SpecificFirestoreProjectResource = GenericNoSqlDatabaseResource & Resource & {
  __typename?: 'SpecificFirestoreProjectResource';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a GCPComputeInstance resource. Autogenerated. */
export type SpecificGcpComputeInstanceResource = GenericComputeInstanceResource & Resource & {
  __typename?: 'SpecificGCPComputeInstanceResource';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  instanceId: Scalars['String'];
  interfaces: Array<GcpComputeInstance_Interfaces>;
  internalInstanceId?: Maybe<Scalars['String']>;
  labels: Array<GcpComputeInstance_Labels>;
  name: Scalars['String'];
  osquery?: Maybe<Osquery>;
  projectId: Scalars['String'];
  service: Scalars['String'];
  status: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
  zone: Scalars['String'];
};

/** Defines the fields available on a GCPContainerRepository resource. Autogenerated. */
export type SpecificGcpContainerRepositoryResource = GenericContainerRepositoryResource & Resource & {
  __typename?: 'SpecificGCPContainerRepositoryResource';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL: Scalars['String'];
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  projectId: Scalars['String'];
  region: Scalars['String'];
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
  vulnerabilities: Array<SpecificGcpContainerVulnerabilityResource>;
};

/** Defines the fields available on a GCPContainerVulnerability resource. Autogenerated. */
export type SpecificGcpContainerVulnerabilityResource = GenericVulnerabilityResource & Resource & {
  __typename?: 'SpecificGCPContainerVulnerabilityResource';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  fullDigest: Scalars['String'];
  id: Scalars['ID'];
  imageTags?: Maybe<Array<Scalars['String']>>;
  inScope?: Maybe<Scalars['Boolean']>;
  occurrences: Array<GcpContainerVulnerability_Occurrences>;
  packageIdentifier: Scalars['String'];
  packageName: Scalars['String'];
  packageVersion: Scalars['String'];
  projectId: Scalars['String'];
  repositoryId: Scalars['String'];
  repositoryName: Scalars['String'];
  resolvedWithinSLA?: Maybe<Scalars['Boolean']>;
  service: Scalars['String'];
  severity: Scalars['Float'];
  slaDeadline?: Maybe<Scalars['DateTime']>;
  slaMillis?: Maybe<Scalars['Float']>;
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a GCPLogBucket resource. Autogenerated. */
export type SpecificGcpLogBucketResource = GenericLogTrailResource & Resource & {
  __typename?: 'SpecificGCPLogBucketResource';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lifecycleState: Scalars['String'];
  location: Scalars['String'];
  name: Scalars['String'];
  projectId: Scalars['String'];
  retentionDays: Scalars['Float'];
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a GCPLogSink resource. Autogenerated. */
export type SpecificGcpLogSinkResource = GenericLogTrailResource & Resource & {
  __typename?: 'SpecificGCPLogSinkResource';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  destination?: Maybe<GcpLogSink_Destination>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  filter: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  projectId: Scalars['String'];
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a GCPMonitoringPolicy resource. Autogenerated. */
export type SpecificGcpMonitoringPolicyResource = GenericAlarmResource & Resource & {
  __typename?: 'SpecificGCPMonitoringPolicyResource';
  combiner: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  enabled: Scalars['Boolean'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  hasNonThresholdCondition: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
  notificationChannels: Array<Scalars['String']>;
  projectId: Scalars['String'];
  service: Scalars['String'];
  thresholdConditions: Array<GcpMonitoringPolicy_ThresholdConditions>;
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a GCPNetwork resource. Autogenerated. */
export type SpecificGcpNetworkResource = GenericNetworkResource & Resource & {
  __typename?: 'SpecificGCPNetworkResource';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  projectId: Scalars['String'];
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a GCPRole resource. Autogenerated. */
export type SpecificGcpRoleResource = GenericCloudRoleResource & Resource & {
  __typename?: 'SpecificGCPRoleResource';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  includedPermissions: Array<Scalars['String']>;
  name: Scalars['String'];
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a GCPRoleGrant resource. Autogenerated. */
export type SpecificGcpRoleGrantResource = GenericCloudRoleGrantResource & Resource & {
  __typename?: 'SpecificGCPRoleGrantResource';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  member: Scalars['String'];
  projectId: Scalars['String'];
  roleUniqueId: Scalars['String'];
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a GCPStorageBucket resource. Autogenerated. */
export type SpecificGcpStorageBucketResource = GenericStorageBucketResource & Resource & {
  __typename?: 'SpecificGCPStorageBucketResource';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  iamPolicy: Array<GcpStorageBucket_IamPolicy>;
  id: Scalars['ID'];
  labels: Array<GcpStorageBucket_Labels>;
  name: Scalars['String'];
  projectId: Scalars['String'];
  retentionPolicySeconds?: Maybe<Scalars['Float']>;
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
  versioningEnabled: Scalars['Boolean'];
};

/** Defines the fields available on a GCPSubnet resource. Autogenerated. */
export type SpecificGcpSubnetResource = GenericSubnetResource & Resource & {
  __typename?: 'SpecificGCPSubnetResource';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  flowLogsEnabled: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
  networkUniqueId: Scalars['String'];
  projectId: Scalars['String'];
  region: Scalars['String'];
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a GCPSubscription resource. Autogenerated. */
export type SpecificGcpSubscriptionResource = GenericQueueResource & Resource & {
  __typename?: 'SpecificGCPSubscriptionResource';
  bindings: Array<GcpSubscription_Bindings>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  labels: Array<GcpSubscription_Labels>;
  name: Scalars['String'];
  projectId: Scalars['String'];
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a GCPTopic resource. Autogenerated. */
export type SpecificGcpTopicResource = GenericTopicResource & Resource & {
  __typename?: 'SpecificGCPTopicResource';
  bindings: Array<GcpTopic_Bindings>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  projectId: Scalars['String'];
  service: Scalars['String'];
  topicSubscriptionUniqueIds: Array<Scalars['String']>;
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a GithubAccount resource. Autogenerated. */
export type SpecificGithubAccountResource = GenericAccountResource & Resource & {
  __typename?: 'SpecificGithubAccountResource';
  accountId: Scalars['String'];
  accountName: Scalars['String'];
  createDate?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mfa?: Maybe<Scalars['Boolean']>;
  role?: Maybe<Scalars['String']>;
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a GithubRepo resource. Autogenerated. */
export type SpecificGithubRepoResource = GenericVersionControlRepoResource & Resource & {
  __typename?: 'SpecificGithubRepoResource';
  createdAt: Scalars['DateTime'];
  defaultBranch: Scalars['String'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastChangeToRepo?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  pullRequestsTemplate?: Maybe<Scalars['String']>;
  requiredApprovalsToMergeToDefaultBranch: Scalars['Float'];
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

export type ResourceConnection = {
  __typename?: 'ResourceConnection';
  pageInfo: PageInfo;
  edges: Array<ResourceEdge>;
  totalCount: Scalars['Int'];
};

/** Defines the fields available on a GitlabAccount resource. Autogenerated. */
export type SpecificGitlabAccountResource = GenericAccountResource & Resource & {
  __typename?: 'SpecificGitlabAccountResource';
  accesses: Array<GitlabAccount_Accesses>;
  accountId: Scalars['String'];
  accountName: Scalars['String'];
  createDate?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mfa?: Maybe<Scalars['Boolean']>;
  role?: Maybe<Scalars['String']>;
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a GitlabGroup resource. Autogenerated. */
export type SpecificGitlabGroupResource = GenericUserGroupResource & Resource & {
  __typename?: 'SpecificGitlabGroupResource';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mfaRequired: Scalars['Boolean'];
  name: Scalars['String'];
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a GitlabRepo resource. Autogenerated. */
export type SpecificGitlabRepoResource = GenericVersionControlRepoResource & Resource & {
  __typename?: 'SpecificGitlabRepoResource';
  codeownersFile?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  defaultBranch: Scalars['String'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastChangeToRepo?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  pullRequestsTemplate?: Maybe<Scalars['String']>;
  requiredApprovalsToMergeToDefaultBranch: Scalars['Float'];
  requiresCodeownerApproval: Scalars['Boolean'];
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a GitlabTask resource. Autogenerated. */
export type SpecificGitlabTaskResource = GenericTaskTrackerItemResource & Resource & {
  __typename?: 'SpecificGitlabTaskResource';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  owner?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['String']>;
  service: Scalars['String'];
  tags: Array<Scalars['String']>;
  taskClosed: Scalars['Boolean'];
  taskClosedAt?: Maybe<Scalars['DateTime']>;
  taskCreatedAt: Scalars['DateTime'];
  title: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a GsuiteUser resource. Autogenerated. */
export type SpecificGsuiteUserResource = GenericUserResourceResource & Resource & {
  __typename?: 'SpecificGsuiteUserResource';
  createdAt: Scalars['DateTime'];
  creationTime?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  email: Scalars['String'];
  externalURL?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  hasMFA?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  imageId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  lastLoginTime?: Maybe<Scalars['String']>;
  service: Scalars['String'];
  suspended?: Maybe<Scalars['Boolean']>;
  suspendedDate?: Maybe<Scalars['String']>;
  suspensionReason?: Maybe<Scalars['String']>;
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a GustoHrUser resource. Autogenerated. */
export type SpecificGustoHrUserResource = GenericHrUserResource & Resource & {
  __typename?: 'SpecificGustoHrUserResource';
  autolinkedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  department?: Maybe<Scalars['String']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  employmentSubtype?: Maybe<Scalars['String']>;
  employmentType?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['DateTime']>;
  externalURL?: Maybe<Scalars['String']>;
  familyName: Scalars['String'];
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  givenName: Scalars['String'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  jobTitle?: Maybe<Scalars['String']>;
  service: Scalars['String'];
  startDate: Scalars['DateTime'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a HerokuAccount resource. Autogenerated. */
export type SpecificHerokuAccountResource = GenericAccountResource & Resource & {
  __typename?: 'SpecificHerokuAccountResource';
  accountId: Scalars['String'];
  accountName: Scalars['String'];
  createDate?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mfa?: Maybe<Scalars['Boolean']>;
  role?: Maybe<Scalars['String']>;
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a HerokuApp resource. Autogenerated. */
export type SpecificHerokuAppResource = GenericPaaSAppResource & Resource & {
  __typename?: 'SpecificHerokuAppResource';
  addons: Array<HerokuApp_Addons>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  logDrains: Array<HerokuApp_LogDrains>;
  name: Scalars['String'];
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a InsperityHrUser resource. Autogenerated. */
export type SpecificInsperityHrUserResource = GenericHrUserResource & Resource & {
  __typename?: 'SpecificInsperityHrUserResource';
  autolinkedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  department?: Maybe<Scalars['String']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  employmentSubtype?: Maybe<Scalars['String']>;
  employmentType?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['DateTime']>;
  externalURL?: Maybe<Scalars['String']>;
  familyName: Scalars['String'];
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  givenName: Scalars['String'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  jobTitle?: Maybe<Scalars['String']>;
  service: Scalars['String'];
  startDate: Scalars['DateTime'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a JamfAccount resource. Autogenerated. */
export type SpecificJamfAccountResource = GenericAccountResource & Resource & {
  __typename?: 'SpecificJamfAccountResource';
  accessLevel: Scalars['String'];
  accountId: Scalars['String'];
  accountName: Scalars['String'];
  createDate?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mfa?: Maybe<Scalars['Boolean']>;
  role?: Maybe<Scalars['String']>;
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a JamfManagedComputer resource. Autogenerated. */
export type SpecificJamfManagedComputerResource = GenericManagedComputerResource & Resource & {
  __typename?: 'SpecificJamfManagedComputerResource';
  antivirusNames: Array<Scalars['String']>;
  applications: Array<ManagedComputer_Applications>;
  assetTag?: Maybe<Scalars['String']>;
  computerGroups: Array<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  diskEncryption?: Maybe<JamfManagedComputer_DiskEncryption>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  hardware?: Maybe<ManagedComputer_Hardware>;
  hasScreenlock?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isEncrypted: Scalars['Boolean'];
  lastEnrollmentDate: Scalars['DateTime'];
  localUserAccounts: Array<ManagedComputer_LocalUserAccounts>;
  name: Scalars['String'];
  operatingSystem?: Maybe<ManagedComputer_OperatingSystem>;
  passwordManagers: Array<ManagedComputer_PasswordManagers>;
  screenlockConfigurations?: Maybe<Array<JamfManagedComputer_ScreenlockConfigurations>>;
  service: Scalars['String'];
  services: Array<Scalars['String']>;
  udid?: Maybe<Scalars['String']>;
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userAndLocation?: Maybe<JamfManagedComputer_UserAndLocation>;
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a JiraAccount resource. Autogenerated. */
export type SpecificJiraAccountResource = GenericAccountResource & Resource & {
  __typename?: 'SpecificJiraAccountResource';
  accountId: Scalars['String'];
  accountName: Scalars['String'];
  createDate?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mfa?: Maybe<Scalars['Boolean']>;
  role?: Maybe<Scalars['String']>;
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a JiraTask resource. Autogenerated. */
export type SpecificJiraTaskResource = GenericTaskTrackerItemResource & Resource & {
  __typename?: 'SpecificJiraTaskResource';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  owner?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['String']>;
  service: Scalars['String'];
  tags: Array<Scalars['String']>;
  taskClosed: Scalars['Boolean'];
  taskClosedAt?: Maybe<Scalars['DateTime']>;
  taskCreatedAt: Scalars['DateTime'];
  title: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
  vantaPriority?: Maybe<Scalars['String']>;
};

/** Defines the fields available on a JustworksHrUser resource. Autogenerated. */
export type SpecificJustworksHrUserResource = GenericHrUserResource & Resource & {
  __typename?: 'SpecificJustworksHrUserResource';
  autolinkedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  department?: Maybe<Scalars['String']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  employmentSubtype?: Maybe<Scalars['String']>;
  employmentType?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['DateTime']>;
  externalURL?: Maybe<Scalars['String']>;
  familyName: Scalars['String'];
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  givenName: Scalars['String'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  jobTitle?: Maybe<Scalars['String']>;
  service: Scalars['String'];
  startDate: Scalars['DateTime'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a KandjiManagedComputer resource. Autogenerated. */
export type SpecificKandjiManagedComputerResource = GenericManagedComputerResource & Resource & {
  __typename?: 'SpecificKandjiManagedComputerResource';
  antivirusNames: Array<Scalars['String']>;
  applications: Array<ManagedComputer_Applications>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  hardware?: Maybe<ManagedComputer_Hardware>;
  hasScreenlock?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  installedProfiles?: Maybe<Array<KandjiManagedComputer_InstalledProfiles>>;
  isEncrypted: Scalars['Boolean'];
  lastEnrollmentDate: Scalars['DateTime'];
  localUserAccounts: Array<ManagedComputer_LocalUserAccounts>;
  name: Scalars['String'];
  operatingSystem?: Maybe<ManagedComputer_OperatingSystem>;
  passwordManagers: Array<ManagedComputer_PasswordManagers>;
  service: Scalars['String'];
  udid?: Maybe<Scalars['String']>;
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a KnowBe4Account resource. Autogenerated. */
export type SpecificKnowBe4AccountResource = GenericAccountResource & Resource & {
  __typename?: 'SpecificKnowBe4AccountResource';
  accountId: Scalars['String'];
  accountName: Scalars['String'];
  createDate?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mfa?: Maybe<Scalars['Boolean']>;
  role?: Maybe<Scalars['String']>;
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a KnowBe4TrainingCampaign resource. Autogenerated. */
export type SpecificKnowBe4TrainingCampaignResource = GenericTrainingResource & Resource & {
  __typename?: 'SpecificKnowBe4TrainingCampaignResource';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  service: Scalars['String'];
  startDate: Scalars['DateTime'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a KnowBe4TrainingEnrollment resource. Autogenerated. */
export type SpecificKnowBe4TrainingEnrollmentResource = GenericTrainingRequirementResource & Resource & {
  __typename?: 'SpecificKnowBe4TrainingEnrollmentResource';
  completionDate?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  email: Scalars['String'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  modules: Array<KnowBe4TrainingEnrollment_Modules>;
  service: Scalars['String'];
  trainingUniqueId: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a LinearAccount resource. Autogenerated. */
export type SpecificLinearAccountResource = GenericAccountResource & Resource & {
  __typename?: 'SpecificLinearAccountResource';
  accountId: Scalars['String'];
  accountName: Scalars['String'];
  createDate?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mfa?: Maybe<Scalars['Boolean']>;
  role?: Maybe<Scalars['String']>;
  service: Scalars['String'];
  teamList: Array<Scalars['String']>;
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a LinearTask resource. Autogenerated. */
export type SpecificLinearTaskResource = GenericTaskTrackerItemResource & Resource & {
  __typename?: 'SpecificLinearTaskResource';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  owner?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['String']>;
  service: Scalars['String'];
  tags: Array<Scalars['String']>;
  taskClosed: Scalars['Boolean'];
  taskClosedAt?: Maybe<Scalars['DateTime']>;
  taskCreatedAt: Scalars['DateTime'];
  title: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a MicrosoftEndpointManagerManagedComputer resource. Autogenerated. */
export type SpecificMicrosoftEndpointManagerManagedComputerResource = GenericManagedComputerResource & Resource & {
  __typename?: 'SpecificMicrosoftEndpointManagerManagedComputerResource';
  antivirusNames: Array<Scalars['String']>;
  applications: Array<ManagedComputer_Applications>;
  avPolicies: Array<AvPolicy>;
  compliancePolicyStatuses: Array<MicrosoftEndpointManagerManagedComputer_CompliancePolicyStatuses>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  hardware?: Maybe<ManagedComputer_Hardware>;
  hasScreenlock?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isEncrypted: Scalars['Boolean'];
  lastEnrollmentDate: Scalars['DateTime'];
  localUserAccounts: Array<ManagedComputer_LocalUserAccounts>;
  name: Scalars['String'];
  operatingSystem?: Maybe<ManagedComputer_OperatingSystem>;
  passwordManagers: Array<ManagedComputer_PasswordManagers>;
  primaryUser?: Maybe<MicrosoftEndpointManagerManagedComputer_PrimaryUser>;
  service: Scalars['String'];
  udid?: Maybe<Scalars['String']>;
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a NamelyHrUser resource. Autogenerated. */
export type SpecificNamelyHrUserResource = GenericHrUserResource & Resource & {
  __typename?: 'SpecificNamelyHrUserResource';
  autolinkedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  department?: Maybe<Scalars['String']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  employmentSubtype?: Maybe<Scalars['String']>;
  employmentType?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['DateTime']>;
  externalURL?: Maybe<Scalars['String']>;
  familyName: Scalars['String'];
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  givenName: Scalars['String'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  jobTitle?: Maybe<Scalars['String']>;
  service: Scalars['String'];
  startDate: Scalars['DateTime'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a NmapSsl resource. Autogenerated. */
export type SpecificNmapSslResource = GenericSslResource & Resource & {
  __typename?: 'SpecificNmapSslResource';
  certificate?: Maybe<NmapSsl_Certificate>;
  cipherSuitesTlsVersionGroups?: Maybe<Array<NmapSsl_CipherSuitesTlsVersionGroups>>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  http?: Maybe<NmapSsl_Http>;
  https?: Maybe<NmapSsl_Https>;
  id: Scalars['ID'];
  otherIpAddresses?: Maybe<Array<Scalars['String']>>;
  queriedIp?: Maybe<Scalars['String']>;
  reverseDnsResult?: Maybe<Scalars['String']>;
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a NvdCve resource. Autogenerated. */
export type SpecificNvdCveResource = GenericCveRecordResource & Resource & {
  __typename?: 'SpecificNvdCveResource';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  score?: Maybe<Scalars['Float']>;
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a OfficeUser resource. Autogenerated. */
export type SpecificOfficeUserResource = GenericUserResourceResource & Resource & {
  __typename?: 'SpecificOfficeUserResource';
  createdAt: Scalars['DateTime'];
  creationTime?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  email: Scalars['String'];
  externalURL?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  hasMFA?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  imageId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  lastLoginTime?: Maybe<Scalars['String']>;
  service: Scalars['String'];
  suspended?: Maybe<Scalars['Boolean']>;
  suspendedDate?: Maybe<Scalars['String']>;
  suspensionReason?: Maybe<Scalars['String']>;
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userPrincipalName: Scalars['String'];
  userType: Scalars['String'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a OktaUser resource. Autogenerated. */
export type SpecificOktaUserResource = GenericUserResourceResource & Resource & {
  __typename?: 'SpecificOktaUserResource';
  createdAt: Scalars['DateTime'];
  creationTime?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  email: Scalars['String'];
  externalURL?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  hasMFA?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  imageId?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  lastLoginTime?: Maybe<Scalars['String']>;
  service: Scalars['String'];
  suspended?: Maybe<Scalars['Boolean']>;
  suspendedDate?: Maybe<Scalars['String']>;
  suspensionReason?: Maybe<Scalars['String']>;
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a OsqueryVulnerability resource. Autogenerated. */
export type SpecificOsqueryVulnerabilityResource = GenericVulnerabilityResource & Resource & {
  __typename?: 'SpecificOsqueryVulnerabilityResource';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  osqueryId?: Maybe<Scalars['String']>;
  resolvedWithinSLA?: Maybe<Scalars['Boolean']>;
  service: Scalars['String'];
  severity: Scalars['Float'];
  slaDeadline?: Maybe<Scalars['DateTime']>;
  slaMillis?: Maybe<Scalars['Float']>;
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
  vulnData: OsqueryVulnerability;
  vulnerablePackageId: Scalars['String'];
};

/** Defines the fields available on a PaychexFlexHrUser resource. Autogenerated. */
export type SpecificPaychexFlexHrUserResource = GenericHrUserResource & Resource & {
  __typename?: 'SpecificPaychexFlexHrUserResource';
  autolinkedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  department?: Maybe<Scalars['String']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  employmentSubtype?: Maybe<Scalars['String']>;
  employmentType?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['DateTime']>;
  externalURL?: Maybe<Scalars['String']>;
  familyName: Scalars['String'];
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  givenName: Scalars['String'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  jobTitle?: Maybe<Scalars['String']>;
  service: Scalars['String'];
  startDate: Scalars['DateTime'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a PaycorHrUser resource. Autogenerated. */
export type SpecificPaycorHrUserResource = GenericHrUserResource & Resource & {
  __typename?: 'SpecificPaycorHrUserResource';
  autolinkedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  department?: Maybe<Scalars['String']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  employmentSubtype?: Maybe<Scalars['String']>;
  employmentType?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['DateTime']>;
  externalURL?: Maybe<Scalars['String']>;
  familyName: Scalars['String'];
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  givenName: Scalars['String'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  jobTitle?: Maybe<Scalars['String']>;
  service: Scalars['String'];
  startDate: Scalars['DateTime'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a PaylocityHrUser resource. Autogenerated. */
export type SpecificPaylocityHrUserResource = GenericHrUserResource & Resource & {
  __typename?: 'SpecificPaylocityHrUserResource';
  autolinkedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  department?: Maybe<Scalars['String']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  employmentSubtype?: Maybe<Scalars['String']>;
  employmentType?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['DateTime']>;
  externalURL?: Maybe<Scalars['String']>;
  familyName: Scalars['String'];
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  givenName: Scalars['String'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  jobTitle?: Maybe<Scalars['String']>;
  service: Scalars['String'];
  startDate: Scalars['DateTime'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a PivotalTrackerAccount resource. Autogenerated. */
export type SpecificPivotalTrackerAccountResource = GenericAccountResource & Resource & {
  __typename?: 'SpecificPivotalTrackerAccountResource';
  accountId: Scalars['String'];
  accountName: Scalars['String'];
  createDate?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mfa?: Maybe<Scalars['Boolean']>;
  role?: Maybe<Scalars['String']>;
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a PivotalTrackerTask resource. Autogenerated. */
export type SpecificPivotalTrackerTaskResource = GenericTaskTrackerItemResource & Resource & {
  __typename?: 'SpecificPivotalTrackerTaskResource';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  owner?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['String']>;
  service: Scalars['String'];
  tags: Array<Scalars['String']>;
  taskClosed: Scalars['Boolean'];
  taskClosedAt?: Maybe<Scalars['DateTime']>;
  taskCreatedAt: Scalars['DateTime'];
  title: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a QuickBooksHrUser resource. Autogenerated. */
export type SpecificQuickBooksHrUserResource = GenericHrUserResource & Resource & {
  __typename?: 'SpecificQuickBooksHrUserResource';
  autolinkedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  department?: Maybe<Scalars['String']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  employmentSubtype?: Maybe<Scalars['String']>;
  employmentType?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['DateTime']>;
  externalURL?: Maybe<Scalars['String']>;
  familyName: Scalars['String'];
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  givenName: Scalars['String'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  jobTitle?: Maybe<Scalars['String']>;
  service: Scalars['String'];
  startDate: Scalars['DateTime'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a RDSInstance resource. Autogenerated. */
export type SpecificRdsInstanceResource = GenericSqlDatabaseResource & Resource & {
  __typename?: 'SpecificRDSInstanceResource';
  account: Scalars['String'];
  backupRetentionPeriod: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  dbClusterIdentifier?: Maybe<Scalars['String']>;
  dbInstanceIdentifier: Scalars['String'];
  dbInstanceStatus: Scalars['String'];
  dbName?: Maybe<Scalars['String']>;
  dbSecurityGroupNames: Array<Scalars['String']>;
  dbSubnetGroupName?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  engine: Scalars['String'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  publiclyAccessible: Scalars['Boolean'];
  readReplicaSourceDBInstanceIdentifier?: Maybe<Scalars['String']>;
  region: Scalars['String'];
  service: Scalars['String'];
  storageEncrypted: Scalars['Boolean'];
  tags: Array<RdsInstance_Tags>;
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
  vpcSecurityGroupIds: Array<Scalars['String']>;
};

/** Defines the fields available on a RedshiftCluster resource. Autogenerated. */
export type SpecificRedshiftClusterResource = GenericDataWarehouseResource & Resource & {
  __typename?: 'SpecificRedshiftClusterResource';
  account: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  encryptionEnabled: Scalars['Boolean'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  loggingEnabled: Scalars['Boolean'];
  name: Scalars['String'];
  region: Scalars['String'];
  service: Scalars['String'];
  tags: Array<RedshiftCluster_Tags>;
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a RipplingHrUser resource. Autogenerated. */
export type SpecificRipplingHrUserResource = GenericHrUserResource & Resource & {
  __typename?: 'SpecificRipplingHrUserResource';
  autolinkedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  department?: Maybe<Scalars['String']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  employmentSubtype?: Maybe<Scalars['String']>;
  employmentType?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['DateTime']>;
  externalURL?: Maybe<Scalars['String']>;
  familyName: Scalars['String'];
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  givenName: Scalars['String'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  jobTitle?: Maybe<Scalars['String']>;
  service: Scalars['String'];
  startDate: Scalars['DateTime'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a S3 resource. Autogenerated. */
export type SpecificS3Resource = GenericStorageBucketResource & Resource & {
  __typename?: 'SpecificS3Resource';
  account: Scalars['String'];
  acls: Array<S3_Acls>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  encryptionRulesCount: Scalars['Float'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lifecycleCount: Scalars['Float'];
  loggingBucket?: Maybe<Scalars['String']>;
  mfaDeleteEnabled: Scalars['Boolean'];
  name: Scalars['String'];
  publicAccessBlockConfig?: Maybe<S3_PublicAccessBlockConfig>;
  service: Scalars['String'];
  tags: Array<S3_Tags>;
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
  versioningEnabled: Scalars['Boolean'];
};

/** Defines the fields available on a SQS resource. Autogenerated. */
export type SpecificSqsResource = GenericQueueResource & Resource & {
  __typename?: 'SpecificSQSResource';
  account: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  kmsMasterKeyId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  region: Scalars['String'];
  service: Scalars['String'];
  tags: Array<Sqs_Tags>;
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a SlackAccount resource. Autogenerated. */
export type SpecificSlackAccountResource = GenericAccountResource & Resource & {
  __typename?: 'SpecificSlackAccountResource';
  accountId: Scalars['String'];
  accountName: Scalars['String'];
  createDate?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mfa?: Maybe<Scalars['Boolean']>;
  role?: Maybe<Scalars['String']>;
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a SnykAccount resource. Autogenerated. */
export type SpecificSnykAccountResource = GenericAccountResource & Resource & {
  __typename?: 'SpecificSnykAccountResource';
  accountId: Scalars['String'];
  accountName: Scalars['String'];
  createDate?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mfa?: Maybe<Scalars['Boolean']>;
  role?: Maybe<Scalars['String']>;
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a SnykProject resource. Autogenerated. */
export type SpecificSnykProjectResource = GenericVulnerabilityMonitoringTargetResource & Resource & {
  __typename?: 'SpecificSnykProjectResource';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  imageBaseImage?: Maybe<Scalars['String']>;
  imageId?: Maybe<Scalars['String']>;
  imageTag?: Maybe<Scalars['String']>;
  lastTestedDate: Scalars['DateTime'];
  name: Scalars['String'];
  organizationId: Scalars['String'];
  origin: Scalars['String'];
  service: Scalars['String'];
  testFrequency: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
  vulnerabilities: Array<SpecificSnykVulnerabilityResource>;
};

/** Defines the fields available on a SnykVulnerability resource. Autogenerated. */
export type SpecificSnykVulnerabilityResource = GenericVulnerabilityResource & Resource & {
  __typename?: 'SpecificSnykVulnerabilityResource';
  createdAt: Scalars['DateTime'];
  cvssScore?: Maybe<Scalars['Float']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  identifiers?: Maybe<Array<Scalars['String']>>;
  ignoreReasons?: Maybe<Array<SnykVulnerability_IgnoreReasons>>;
  inScope?: Maybe<Scalars['Boolean']>;
  isIgnored: Scalars['Boolean'];
  issueUrl: Scalars['String'];
  organizationId: Scalars['String'];
  packageName?: Maybe<Scalars['String']>;
  packageVersions: Array<Scalars['String']>;
  projectId: Scalars['String'];
  projectName: Scalars['String'];
  resolvedWithinSLA?: Maybe<Scalars['Boolean']>;
  service: Scalars['String'];
  severity: Scalars['Float'];
  slaDeadline?: Maybe<Scalars['DateTime']>;
  slaMillis?: Maybe<Scalars['Float']>;
  snykScore?: Maybe<Scalars['Float']>;
  title: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a SpannerInstance resource. Autogenerated. */
export type SpecificSpannerInstanceResource = GenericSqlDatabaseResource & Resource & {
  __typename?: 'SpecificSpannerInstanceResource';
  createdAt: Scalars['DateTime'];
  databaseIds: Array<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  labels: Array<SpannerInstance_Labels>;
  name: Scalars['String'];
  projectId: Scalars['String'];
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a SquarePayrollHrUser resource. Autogenerated. */
export type SpecificSquarePayrollHrUserResource = GenericHrUserResource & Resource & {
  __typename?: 'SpecificSquarePayrollHrUserResource';
  autolinkedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  department?: Maybe<Scalars['String']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  employmentSubtype?: Maybe<Scalars['String']>;
  employmentType?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['DateTime']>;
  externalURL?: Maybe<Scalars['String']>;
  familyName: Scalars['String'];
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  givenName: Scalars['String'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  jobTitle?: Maybe<Scalars['String']>;
  service: Scalars['String'];
  startDate: Scalars['DateTime'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a TrelloAccount resource. Autogenerated. */
export type SpecificTrelloAccountResource = GenericAccountResource & Resource & {
  __typename?: 'SpecificTrelloAccountResource';
  accountId: Scalars['String'];
  accountName: Scalars['String'];
  createDate?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mfa?: Maybe<Scalars['Boolean']>;
  role?: Maybe<Scalars['String']>;
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a TrelloTask resource. Autogenerated. */
export type SpecificTrelloTaskResource = GenericTaskTrackerItemResource & Resource & {
  __typename?: 'SpecificTrelloTaskResource';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  owner?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['String']>;
  service: Scalars['String'];
  tags: Array<Scalars['String']>;
  taskClosed: Scalars['Boolean'];
  taskClosedAt?: Maybe<Scalars['DateTime']>;
  taskCreatedAt: Scalars['DateTime'];
  title: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a TrinetHrUser resource. Autogenerated. */
export type SpecificTrinetHrUserResource = GenericHrUserResource & Resource & {
  __typename?: 'SpecificTrinetHrUserResource';
  autolinkedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  department?: Maybe<Scalars['String']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  employmentSubtype?: Maybe<Scalars['String']>;
  employmentType?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['DateTime']>;
  externalURL?: Maybe<Scalars['String']>;
  familyName: Scalars['String'];
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  givenName: Scalars['String'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  jobTitle?: Maybe<Scalars['String']>;
  service: Scalars['String'];
  startDate: Scalars['DateTime'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a UbuntuPackageChangelog resource. Autogenerated. */
export type SpecificUbuntuPackageChangelogResource = GenericPackageChangelogResourceResource & Resource & {
  __typename?: 'SpecificUbuntuPackageChangelogResource';
  codename: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  dependentPackageSecurityVersions?: Maybe<Array<PackageChangelogResource_DependentPackageSecurityVersions>>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mostRecentSecurityVersion?: Maybe<PackageChangelogResource_MostRecentSecurityVersion>;
  name: Scalars['String'];
  os: Scalars['String'];
  service: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
  versions: Array<PackageChangelogResource_Versions>;
};

/** Defines the fields available on a VantaCheckrBackgroundCheck resource. Autogenerated. */
export type SpecificVantaCheckrBackgroundCheckResource = GenericBackgroundCheckResource & Resource & {
  __typename?: 'SpecificVantaCheckrBackgroundCheckResource';
  candidateId: Scalars['String'];
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  reportId?: Maybe<Scalars['String']>;
  service: Scalars['String'];
  status: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  uri: Scalars['String'];
  userId: Scalars['String'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Defines the fields available on a VettyBackgroundCheck resource. Autogenerated. */
export type SpecificVettyBackgroundCheckResource = GenericBackgroundCheckResource & Resource & {
  __typename?: 'SpecificVettyBackgroundCheckResource';
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  email: Scalars['String'];
  externalURL?: Maybe<Scalars['String']>;
  familyName: Scalars['String'];
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  givenName: Scalars['String'];
  id: Scalars['ID'];
  service: Scalars['String'];
  status: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
  vettyStatus: Scalars['String'];
};

/** Defines the fields available on a ZenefitsHrUser resource. Autogenerated. */
export type SpecificZenefitsHrUserResource = GenericHrUserResource & Resource & {
  __typename?: 'SpecificZenefitsHrUserResource';
  autolinkedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  department?: Maybe<Scalars['String']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  employmentSubtype?: Maybe<Scalars['String']>;
  employmentType?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['DateTime']>;
  externalURL?: Maybe<Scalars['String']>;
  familyName: Scalars['String'];
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  givenName: Scalars['String'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  jobTitle?: Maybe<Scalars['String']>;
  service: Scalars['String'];
  startDate: Scalars['DateTime'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Helper type. Autogenerated. */
export type Alb_ListenerList = {
  __typename?: 'ALB_listenerList';
  arn: Scalars['String'];
  defaultActions: Array<Alb_ListenerList_DefaultActions>;
  protocol?: Maybe<Scalars['String']>;
};

/** Helper type. Autogenerated. */
export type Alb_ListenerList_DefaultActions = {
  __typename?: 'ALB_listenerList_defaultActions';
  redirectConfigProtocol?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

/** Helper type. Autogenerated. */
export type Alb_Tags = {
  __typename?: 'ALB_tags';
  key: Scalars['String'];
  value: Scalars['String'];
};

/** Helper type. Autogenerated. */
export type AutoScalingGroup_Tags = {
  __typename?: 'AutoScalingGroup_tags';
  key: Scalars['String'];
  value: Scalars['String'];
};

/** Helper type. Autogenerated. */
export type AwsContainerVulnerability_Findings = {
  __typename?: 'AwsContainerVulnerability_findings';
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  severity: Scalars['Float'];
  uri: Scalars['String'];
};

/** Helper type. Autogenerated. */
export type AwsInspectorVulnerability_Findings = {
  __typename?: 'AwsInspectorVulnerability_findings';
  confidence?: Maybe<Scalars['Float']>;
  cveId?: Maybe<Scalars['String']>;
  cvsV3Score?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  recommendation?: Maybe<Scalars['String']>;
  severity: Scalars['Float'];
  title: Scalars['String'];
};

/** Helper type. Autogenerated. */
export type AwsRouteTable_Routes = {
  __typename?: 'AwsRouteTable_routes';
  destinationId: Scalars['String'];
  destinationType: Scalars['String'];
  targetId: Scalars['String'];
  targetType: Scalars['String'];
};

/** Helper type. Autogenerated. */
export type AwsSecurityGroup_InboundRules = {
  __typename?: 'AwsSecurityGroup_inboundRules';
  fromPort?: Maybe<Scalars['Float']>;
  ipProtocol: Scalars['String'];
  ipRanges: Array<Scalars['String']>;
  ipv6Ranges?: Maybe<Array<Scalars['String']>>;
  securityGroupIds: Array<Scalars['String']>;
  toPort?: Maybe<Scalars['Float']>;
};

/** Helper type. Autogenerated. */
export type AwsSecurityGroup_OutboundRules = {
  __typename?: 'AwsSecurityGroup_outboundRules';
  fromPort?: Maybe<Scalars['Float']>;
  ipProtocol: Scalars['String'];
  ipRanges: Array<Scalars['String']>;
  ipv6Ranges?: Maybe<Array<Scalars['String']>>;
  securityGroupIds: Array<Scalars['String']>;
  toPort?: Maybe<Scalars['Float']>;
};

/** Helper type. Autogenerated. */
export type AwsSubnet_Tags = {
  __typename?: 'AwsSubnet_tags';
  key: Scalars['String'];
  value: Scalars['String'];
};

/** Helper type. Autogenerated. */
export type AwsVpc_Tags = {
  __typename?: 'AwsVPC_tags';
  key: Scalars['String'];
  value: Scalars['String'];
};

/** Helper type. Autogenerated. */
export type AzureApplicationGateway_HttpListeners = {
  __typename?: 'AzureApplicationGateway_httpListeners';
  name: Scalars['String'];
  protocol: Scalars['String'];
};

/** Helper type. Autogenerated. */
export type AzureApplicationGateway_Tags = {
  __typename?: 'AzureApplicationGateway_tags';
  key: Scalars['String'];
  value: Scalars['String'];
};

/** Helper type. Autogenerated. */
export type AzureBlobContainer_Tags = {
  __typename?: 'AzureBlobContainer_tags';
  key: Scalars['String'];
  value: Scalars['String'];
};

/** Helper type. Autogenerated. */
export type AzureContainerRepository_Tags = {
  __typename?: 'AzureContainerRepository_tags';
  key: Scalars['String'];
  value: Scalars['String'];
};

/** Helper type. Autogenerated. */
export type AzureContainerVulnerability_Cves = {
  __typename?: 'AzureContainerVulnerability_cves';
  name: Scalars['String'];
  uri?: Maybe<Scalars['String']>;
};

/** Helper type. Autogenerated. */
export type AzureCosmosDb_Tags = {
  __typename?: 'AzureCosmosDB_tags';
  key: Scalars['String'];
  value: Scalars['String'];
};

/** Helper type. Autogenerated. */
export type AzureDatabaseForExternalSqlVariant_Tags = {
  __typename?: 'AzureDatabaseForExternalSQLVariant_tags';
  key: Scalars['String'];
  value: Scalars['String'];
};

/** Helper type. Autogenerated. */
export type AzureLoadBalancer_Tags = {
  __typename?: 'AzureLoadBalancer_tags';
  key: Scalars['String'];
  value: Scalars['String'];
};

/** Helper type. Autogenerated. */
export type AzureMetricAlertRule_Criteria = {
  __typename?: 'AzureMetricAlertRule_criteria';
  dimensions: Array<AzureMetricAlertRule_Criteria_Dimensions>;
  metricName: Scalars['String'];
  metricNamespace?: Maybe<Scalars['String']>;
};

/** Helper type. Autogenerated. */
export type AzureMetricAlertRule_Criteria_Dimensions = {
  __typename?: 'AzureMetricAlertRule_criteria_dimensions';
  name: Scalars['String'];
  operator: Scalars['String'];
  values: Array<Scalars['String']>;
};

/** Helper type. Autogenerated. */
export type AzureMetricAlertRule_Tags = {
  __typename?: 'AzureMetricAlertRule_tags';
  key: Scalars['String'];
  value: Scalars['String'];
};

/** Helper type. Autogenerated. */
export type AzureQueue_Tags = {
  __typename?: 'AzureQueue_tags';
  key: Scalars['String'];
  value: Scalars['String'];
};

/** Helper type. Autogenerated. */
export type AzureSqlDatabase_Tags = {
  __typename?: 'AzureSQLDatabase_tags';
  key: Scalars['String'];
  value: Scalars['String'];
};

/** Helper type. Autogenerated. */
export type AzureSqlManagedInstance_Tags = {
  __typename?: 'AzureSQLManagedInstance_tags';
  key: Scalars['String'];
  value: Scalars['String'];
};

/** Helper type. Autogenerated. */
export type AzureSqlServerOnVMs_Tags = {
  __typename?: 'AzureSQLServerOnVMs_tags';
  key: Scalars['String'];
  value: Scalars['String'];
};

/** Helper type. Autogenerated. */
export type AzureScaleSetVirtualMachine_Tags = {
  __typename?: 'AzureScaleSetVirtualMachine_tags';
  key: Scalars['String'];
  value: Scalars['String'];
};

/** Helper type. Autogenerated. */
export type AzureSecurityGroup_SecurityRules = {
  __typename?: 'AzureSecurityGroup_securityRules';
  access: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  destinationAddressPrefixes: Array<Scalars['String']>;
  destinationApplicationSecurityGroupsIds?: Maybe<Array<Scalars['String']>>;
  destinationPortRanges: Array<Scalars['String']>;
  direction: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  priority: Scalars['Float'];
  protocol: Scalars['String'];
  sourceAddressPrefixes: Array<Scalars['String']>;
  sourceApplicationSecurityGroupsIds?: Maybe<Array<Scalars['String']>>;
  sourcePortRanges: Array<Scalars['String']>;
};

/** Helper type. Autogenerated. */
export type AzureSecurityGroup_Tags = {
  __typename?: 'AzureSecurityGroup_tags';
  key: Scalars['String'];
  value: Scalars['String'];
};

/** Helper type. Autogenerated. */
export type AzureSynapseWarehouse_Tags = {
  __typename?: 'AzureSynapseWarehouse_tags';
  key: Scalars['String'];
  value: Scalars['String'];
};

/** Helper type. Autogenerated. */
export type AzureVirtualMachineScaleSet_Tags = {
  __typename?: 'AzureVirtualMachineScaleSet_tags';
  key: Scalars['String'];
  value: Scalars['String'];
};

/** Helper type. Autogenerated. */
export type AzureVirtualMachine_Tags = {
  __typename?: 'AzureVirtualMachine_tags';
  key: Scalars['String'];
  value: Scalars['String'];
};

/** Helper type. Autogenerated. */
export type BigQueryDataset_Labels = {
  __typename?: 'BigQueryDataset_labels';
  key: Scalars['String'];
  value: Scalars['String'];
};

/** Helper type. Autogenerated. */
export type BigtableInstance_Labels = {
  __typename?: 'BigtableInstance_labels';
  key: Scalars['String'];
  value: Scalars['String'];
};

/** Helper type. Autogenerated. */
export type CloudSqlInstance_Labels = {
  __typename?: 'CloudSQLInstance_labels';
  key: Scalars['String'];
  value: Scalars['String'];
};

/** Helper type. Autogenerated. */
export type CloudWatchLogGroup_Tags = {
  __typename?: 'CloudWatchLogGroup_tags';
  key: Scalars['String'];
  value: Scalars['String'];
};

/** Helper type. Autogenerated. */
export type CloudWatchMetricAlarm_Dimensions = {
  __typename?: 'CloudWatchMetricAlarm_dimensions';
  name: Scalars['String'];
  value: Scalars['String'];
};

/** Helper type. Autogenerated. */
export type CloudWatchMetricAlarm_Metrics = {
  __typename?: 'CloudWatchMetricAlarm_metrics';
  dimensions: Array<CloudWatchMetricAlarm_Metrics_Dimensions>;
  metricName: Scalars['String'];
};

/** Helper type. Autogenerated. */
export type CloudWatchMetricAlarm_Metrics_Dimensions = {
  __typename?: 'CloudWatchMetricAlarm_metrics_dimensions';
  name: Scalars['String'];
  value: Scalars['String'];
};

/** Helper type. Autogenerated. */
export type DigitalOceanApp_AppDatabases = {
  __typename?: 'DigitalOceanApp_appDatabases';
  engine: Scalars['String'];
  name: Scalars['String'];
  production: Scalars['Boolean'];
};

/** Helper type. Autogenerated. */
export type DigitalOceanApp_AppServices = {
  __typename?: 'DigitalOceanApp_appServices';
  name: Scalars['String'];
  routes: Array<Scalars['String']>;
};

/** Helper type. Autogenerated. */
export type DigitalOceanDroplet_Tags = {
  __typename?: 'DigitalOceanDroplet_tags';
  key: Scalars['String'];
  value: Scalars['String'];
};

/** Helper type. Autogenerated. */
export type DigitalOceanFirewall_InboundRules = {
  __typename?: 'DigitalOceanFirewall_inboundRules';
  locations?: Maybe<DigitalOceanFirewall_InboundRules_Locations>;
  ports: Scalars['String'];
  protocol: Scalars['String'];
};

/** Helper type. Autogenerated. */
export type DigitalOceanFirewall_InboundRules_Locations = {
  __typename?: 'DigitalOceanFirewall_inboundRules_locations';
  addresses?: Maybe<Array<Scalars['String']>>;
  dropletIds?: Maybe<Array<Scalars['String']>>;
  loadBalancerUids?: Maybe<Array<Scalars['String']>>;
  tags?: Maybe<Array<Scalars['String']>>;
};

/** Helper type. Autogenerated. */
export type DigitalOceanFirewall_OutboundRules = {
  __typename?: 'DigitalOceanFirewall_outboundRules';
  locations?: Maybe<DigitalOceanFirewall_OutboundRules_Locations>;
  ports: Scalars['String'];
  protocol: Scalars['String'];
};

/** Helper type. Autogenerated. */
export type DigitalOceanFirewall_OutboundRules_Locations = {
  __typename?: 'DigitalOceanFirewall_outboundRules_locations';
  addresses?: Maybe<Array<Scalars['String']>>;
  dropletIds?: Maybe<Array<Scalars['String']>>;
  loadBalancerUids?: Maybe<Array<Scalars['String']>>;
  tags?: Maybe<Array<Scalars['String']>>;
};

/** Helper type. Autogenerated. */
export type DigitalOceanLoadBalancer_ForwardingRules = {
  __typename?: 'DigitalOceanLoadBalancer_forwardingRules';
  certificateId: Scalars['String'];
  entryPort: Scalars['Float'];
  entryProtocol: Scalars['String'];
  targetPort: Scalars['Float'];
  targetProtocol: Scalars['String'];
  tlsPassthrough: Scalars['Boolean'];
};

/** Helper type. Autogenerated. */
export type DigitalOceanRedisCluster_Tags = {
  __typename?: 'DigitalOceanRedisCluster_tags';
  key: Scalars['String'];
  value: Scalars['String'];
};

/** Helper type. Autogenerated. */
export type DigitalOceanRelationalDbCluster_Tags = {
  __typename?: 'DigitalOceanRelationalDbCluster_tags';
  key: Scalars['String'];
  value: Scalars['String'];
};

/** Helper type. Autogenerated. */
export type DigitalOceanSpace_Acls = {
  __typename?: 'DigitalOceanSpace_acls';
  displayName?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  permission: Scalars['String'];
  type: Scalars['String'];
  uri?: Maybe<Scalars['String']>;
};

/** Helper type. Autogenerated. */
export type DynamoDbTable_Tags = {
  __typename?: 'DynamoDBTable_tags';
  key: Scalars['String'];
  value: Scalars['String'];
};

/** Helper type. Autogenerated. */
export type Ec2Instance_Image = {
  __typename?: 'EC2Instance_image';
  imageId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** Helper type. Autogenerated. */
export type Ec2Instance_Inspector = {
  __typename?: 'EC2Instance_inspector';
  latestRun?: Maybe<Ec2Instance_Inspector_LatestRun>;
};

/** Helper type. Autogenerated. */
export type Ec2Instance_Inspector_LatestRun = {
  __typename?: 'EC2Instance_inspector_latestRun';
  assessmentArn: Scalars['String'];
  assessmentStartDate?: Maybe<Scalars['DateTime']>;
};

/** Helper type. Autogenerated. */
export type Ec2Instance_Tags = {
  __typename?: 'EC2Instance_tags';
  key: Scalars['String'];
  value: Scalars['String'];
};

/** Helper type. Autogenerated. */
export type EcrContainerRepository_Tags = {
  __typename?: 'ECRContainerRepository_tags';
  key: Scalars['String'];
  value: Scalars['String'];
};

/** Helper type. Autogenerated. */
export type GcpComputeInstance_Interfaces = {
  __typename?: 'GCPComputeInstance_interfaces';
  externalIP?: Maybe<Scalars['String']>;
  firewalls?: Maybe<GcpComputeInstance_Interfaces_Firewalls>;
  network: Scalars['String'];
  networkIP: Scalars['String'];
  subnetwork: Scalars['String'];
};

/** Helper type. Autogenerated. */
export type GcpComputeInstance_Interfaces_Firewalls = {
  __typename?: 'GCPComputeInstance_interfaces_firewalls';
  policyRules: Array<GcpComputeInstance_Interfaces_Firewalls_PolicyRules>;
  vpcRules: Array<GcpComputeInstance_Interfaces_Firewalls_VpcRules>;
};

/** Helper type. Autogenerated. */
export type GcpComputeInstance_Interfaces_Firewalls_PolicyRules = {
  __typename?: 'GCPComputeInstance_interfaces_firewalls_policyRules';
  name: Scalars['String'];
  rules: Array<GcpComputeInstance_Interfaces_Firewalls_PolicyRules_Rules>;
  type: Scalars['String'];
};

/** Helper type. Autogenerated. */
export type GcpComputeInstance_Interfaces_Firewalls_PolicyRules_Rules = {
  __typename?: 'GCPComputeInstance_interfaces_firewalls_policyRules_rules';
  action: Scalars['String'];
  description: Scalars['String'];
  direction: Scalars['String'];
  disabled: Scalars['Boolean'];
  kind: Scalars['String'];
  match?: Maybe<GcpComputeInstance_Interfaces_Firewalls_PolicyRules_Rules_Match>;
  priority: Scalars['Float'];
  targetResources?: Maybe<Array<Scalars['String']>>;
  targetServiceAccounts?: Maybe<Array<Scalars['String']>>;
};

/** Helper type. Autogenerated. */
export type GcpComputeInstance_Interfaces_Firewalls_PolicyRules_Rules_Match = {
  __typename?: 'GCPComputeInstance_interfaces_firewalls_policyRules_rules_match';
  destIpRanges: Array<Scalars['String']>;
  layer4Configs: Array<GcpComputeInstance_Interfaces_Firewalls_PolicyRules_Rules_Match_Layer4Configs>;
  srcIpRanges: Array<Scalars['String']>;
};

/** Helper type. Autogenerated. */
export type GcpComputeInstance_Interfaces_Firewalls_PolicyRules_Rules_Match_Layer4Configs = {
  __typename?: 'GCPComputeInstance_interfaces_firewalls_policyRules_rules_match_layer4Configs';
  ipProtocol: Scalars['String'];
  ports?: Maybe<Array<Scalars['String']>>;
};

/** Helper type. Autogenerated. */
export type GcpComputeInstance_Interfaces_Firewalls_VpcRules = {
  __typename?: 'GCPComputeInstance_interfaces_firewalls_vpcRules';
  allowed: Array<GcpComputeInstance_Interfaces_Firewalls_VpcRules_Allowed>;
  denied?: Maybe<Array<GcpComputeInstance_Interfaces_Firewalls_VpcRules_Denied>>;
  direction: Scalars['String'];
  disabled: Scalars['Boolean'];
  ruleDescription: Scalars['String'];
  ruleId: Scalars['String'];
  ruleName: Scalars['String'];
  rulePriority: Scalars['Float'];
  sourceRanges: Array<Scalars['String']>;
  sourceServiceAccounts?: Maybe<Array<Scalars['String']>>;
  sourceTags?: Maybe<Array<Scalars['String']>>;
  targetServiceAccounts?: Maybe<Array<Scalars['String']>>;
  targetTags?: Maybe<Array<Scalars['String']>>;
};

/** Helper type. Autogenerated. */
export type GcpComputeInstance_Interfaces_Firewalls_VpcRules_Allowed = {
  __typename?: 'GCPComputeInstance_interfaces_firewalls_vpcRules_allowed';
  ipProtocol: Scalars['String'];
  ports?: Maybe<Array<Scalars['String']>>;
};

/** Helper type. Autogenerated. */
export type GcpComputeInstance_Interfaces_Firewalls_VpcRules_Denied = {
  __typename?: 'GCPComputeInstance_interfaces_firewalls_vpcRules_denied';
  ipProtocol: Scalars['String'];
  ports?: Maybe<Array<Scalars['String']>>;
};

/** Helper type. Autogenerated. */
export type GcpComputeInstance_Labels = {
  __typename?: 'GCPComputeInstance_labels';
  key: Scalars['String'];
  value: Scalars['String'];
};

/** Helper type. Autogenerated. */
export type GcpContainerVulnerability_Occurrences = {
  __typename?: 'GCPContainerVulnerability_occurrences';
  longDescription?: Maybe<Scalars['String']>;
  relatedUrls: Array<Scalars['String']>;
  severity: Scalars['Float'];
  shortDescription?: Maybe<Scalars['String']>;
};

/** Helper type. Autogenerated. */
export type GcpLogSink_Destination = {
  __typename?: 'GCPLogSink_destination';
  id: Scalars['ID'];
  service: Scalars['String'];
};

/** Helper type. Autogenerated. */
export type GcpMonitoringPolicy_ThresholdConditions = {
  __typename?: 'GCPMonitoringPolicy_thresholdConditions';
  filters: Array<GcpMonitoringPolicy_ThresholdConditions_Filters>;
  name: Scalars['String'];
};

/** Helper type. Autogenerated. */
export type GcpMonitoringPolicy_ThresholdConditions_Filters = {
  __typename?: 'GCPMonitoringPolicy_thresholdConditions_filters';
  key: Scalars['String'];
  value: Scalars['String'];
};

/** Helper type. Autogenerated. */
export type GcpStorageBucket_IamPolicy = {
  __typename?: 'GCPStorageBucket_iamPolicy';
  members: Array<Scalars['String']>;
  role: Scalars['String'];
};

/** Helper type. Autogenerated. */
export type GcpStorageBucket_Labels = {
  __typename?: 'GCPStorageBucket_labels';
  key: Scalars['String'];
  value: Scalars['String'];
};

/** Helper type. Autogenerated. */
export type GcpSubscription_Bindings = {
  __typename?: 'GCPSubscription_bindings';
  members: Array<Scalars['String']>;
  role: Scalars['String'];
};

/** Helper type. Autogenerated. */
export type GcpSubscription_Labels = {
  __typename?: 'GCPSubscription_labels';
  key: Scalars['String'];
  value: Scalars['String'];
};

/** Helper type. Autogenerated. */
export type GcpTopic_Bindings = {
  __typename?: 'GCPTopic_bindings';
  members: Array<Scalars['String']>;
  role: Scalars['String'];
};

/** Helper type. Autogenerated. */
export type GitlabAccount_Accesses = {
  __typename?: 'GitlabAccount_accesses';
  accessLevel: Scalars['Float'];
  group: Scalars['String'];
};

/** Helper type. Autogenerated. */
export type HerokuApp_Addons = {
  __typename?: 'HerokuApp_addons';
  actionLabels: Array<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  planName: Scalars['String'];
  serviceName: Scalars['String'];
};

/** Helper type. Autogenerated. */
export type HerokuApp_LogDrains = {
  __typename?: 'HerokuApp_logDrains';
  id: Scalars['ID'];
  isAddon: Scalars['Boolean'];
};

/** Helper type. Autogenerated. */
export type JamfManagedComputer_DiskEncryption = {
  __typename?: 'JamfManagedComputer_diskEncryption';
  bootPartitionFileVault2State: Scalars['String'];
  bootPartitionName: Scalars['String'];
};

/** Helper type. Autogenerated. */
export type JamfManagedComputer_ScreenlockConfigurations = {
  __typename?: 'JamfManagedComputer_screenlockConfigurations';
  askForPassword?: Maybe<Scalars['Boolean']>;
  askForPasswordDelay?: Maybe<Scalars['Float']>;
  loginWindowIdleTime?: Maybe<Scalars['Float']>;
};

/** Helper type. Autogenerated. */
export type JamfManagedComputer_UserAndLocation = {
  __typename?: 'JamfManagedComputer_userAndLocation';
  email?: Maybe<Scalars['String']>;
  realname?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

/** Helper type. Autogenerated. */
export type KandjiManagedComputer_InstalledProfiles = {
  __typename?: 'KandjiManagedComputer_installedProfiles';
  name: Scalars['String'];
};

/** Helper type. Autogenerated. */
export type KnowBe4TrainingEnrollment_Modules = {
  __typename?: 'KnowBe4TrainingEnrollment_modules';
  completionDate?: Maybe<Scalars['DateTime']>;
  enrollmentId: Scalars['String'];
  name: Scalars['String'];
  status: Scalars['String'];
};

/** Helper type. Autogenerated. */
export type ManagedComputer_Applications = {
  __typename?: 'ManagedComputer_applications';
  bundleId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  path?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

/** Helper type. Autogenerated. */
export type ManagedComputer_Hardware = {
  __typename?: 'ManagedComputer_hardware';
  make: Scalars['String'];
  model: Scalars['String'];
  modelIdentifier?: Maybe<Scalars['String']>;
  serialNumber: Scalars['String'];
};

/** Helper type. Autogenerated. */
export type ManagedComputer_LocalUserAccounts = {
  __typename?: 'ManagedComputer_localUserAccounts';
  admin: Scalars['Boolean'];
  fileVault2Enabled?: Maybe<Scalars['Boolean']>;
  fullName: Scalars['String'];
  homeDirectory: Scalars['String'];
  uid: Scalars['String'];
};

/** Helper type. Autogenerated. */
export type ManagedComputer_OperatingSystem = {
  __typename?: 'ManagedComputer_operatingSystem';
  name: Scalars['String'];
  version: Scalars['String'];
};

/** Helper type. Autogenerated. */
export type ManagedComputer_PasswordManagers = {
  __typename?: 'ManagedComputer_passwordManagers';
  bundleId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  path?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

/** Helper type. Autogenerated. */
export type MicrosoftEndpointManagerManagedComputer_CompliancePolicyStatuses = {
  __typename?: 'MicrosoftEndpointManagerManagedComputer_compliancePolicyStatuses';
  attributes?: Maybe<MicrosoftEndpointManagerManagedComputer_CompliancePolicyStatuses_Attributes>;
  id: Scalars['ID'];
  name: Scalars['String'];
  status?: Maybe<Scalars['String']>;
};

/** Helper type. Autogenerated. */
export type MicrosoftEndpointManagerManagedComputer_CompliancePolicyStatuses_Attributes = {
  __typename?: 'MicrosoftEndpointManagerManagedComputer_compliancePolicyStatuses_attributes';
  antivirusRequired?: Maybe<Scalars['Boolean']>;
  passwordMinutesOfInactivityBeforeLock?: Maybe<Scalars['Float']>;
  passwordRequired?: Maybe<Scalars['Boolean']>;
  passwordRequiredToUnlockFromIdle?: Maybe<Scalars['Boolean']>;
};

/** Helper type. Autogenerated. */
export type MicrosoftEndpointManagerManagedComputer_PrimaryUser = {
  __typename?: 'MicrosoftEndpointManagerManagedComputer_primaryUser';
  displayName: Scalars['String'];
  emailAddress?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  principalName: Scalars['String'];
};

/** Helper type. Autogenerated. */
export type NmapSsl_Certificate = {
  __typename?: 'NmapSsl_certificate';
  domain: Scalars['String'];
  validFrom: Scalars['DateTime'];
  validTo: Scalars['DateTime'];
};

/** Helper type. Autogenerated. */
export type NmapSsl_CipherSuitesTlsVersionGroups = {
  __typename?: 'NmapSsl_cipherSuitesTlsVersionGroups';
  cipherSuites: Array<NmapSsl_CipherSuitesTlsVersionGroups_CipherSuites>;
  tlsVersion: Scalars['String'];
  warnings: Array<Scalars['String']>;
};

/** Helper type. Autogenerated. */
export type NmapSsl_CipherSuitesTlsVersionGroups_CipherSuites = {
  __typename?: 'NmapSsl_cipherSuitesTlsVersionGroups_cipherSuites';
  baseAlgorithm: Scalars['String'];
  grade: Scalars['String'];
  name: Scalars['String'];
};

/** Helper type. Autogenerated. */
export type NmapSsl_Http = {
  __typename?: 'NmapSsl_http';
  error?: Maybe<Scalars['String']>;
  response?: Maybe<NmapSsl_Http_Response>;
};

/** Helper type. Autogenerated. */
export type NmapSsl_Http_Response = {
  __typename?: 'NmapSsl_http_response';
  statusCode?: Maybe<Scalars['Float']>;
};

/** Helper type. Autogenerated. */
export type NmapSsl_Https = {
  __typename?: 'NmapSsl_https';
  error?: Maybe<Scalars['String']>;
  response?: Maybe<NmapSsl_Https_Response>;
};

/** Helper type. Autogenerated. */
export type NmapSsl_Https_Response = {
  __typename?: 'NmapSsl_https_response';
  statusCode?: Maybe<Scalars['Float']>;
};

/** Helper type. Autogenerated. */
export type PackageChangelogResource_DependentPackageSecurityVersions = {
  __typename?: 'PackageChangelogResource_dependentPackageSecurityVersions';
  packageName: Scalars['String'];
  version: Scalars['String'];
};

/** Helper type. Autogenerated. */
export type PackageChangelogResource_MostRecentSecurityVersion = {
  __typename?: 'PackageChangelogResource_mostRecentSecurityVersion';
  epoch?: Maybe<Scalars['String']>;
  release?: Maybe<Scalars['String']>;
  version: Scalars['String'];
};

/** Helper type. Autogenerated. */
export type PackageChangelogResource_Versions = {
  __typename?: 'PackageChangelogResource_versions';
  arch?: Maybe<Scalars['String']>;
  cves: Array<PackageChangelogResource_Versions_Cves>;
  date?: Maybe<Scalars['DateTime']>;
  epoch?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  release?: Maybe<Scalars['String']>;
  version: Scalars['String'];
};

/** Helper type. Autogenerated. */
export type PackageChangelogResource_Versions_Cves = {
  __typename?: 'PackageChangelogResource_versions_cves';
  id: Scalars['ID'];
  score?: Maybe<Scalars['Float']>;
};

/** Helper type. Autogenerated. */
export type RdsInstance_Tags = {
  __typename?: 'RDSInstance_tags';
  key: Scalars['String'];
  value: Scalars['String'];
};

/** Helper type. Autogenerated. */
export type RedshiftCluster_Tags = {
  __typename?: 'RedshiftCluster_tags';
  key: Scalars['String'];
  value: Scalars['String'];
};

/** Helper type. Autogenerated. */
export type S3_Acls = {
  __typename?: 'S3_acls';
  displayName?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  permission: Scalars['String'];
  type: Scalars['String'];
  uri?: Maybe<Scalars['String']>;
};

/** Helper type. Autogenerated. */
export type S3_PublicAccessBlockConfig = {
  __typename?: 'S3_publicAccessBlockConfig';
  blockPublicAcls: Scalars['Boolean'];
  blockPublicPolicy: Scalars['Boolean'];
  ignorePublicAcls: Scalars['Boolean'];
  restrictPublicBuckets: Scalars['Boolean'];
};

/** Helper type. Autogenerated. */
export type S3_Tags = {
  __typename?: 'S3_tags';
  key: Scalars['String'];
  value: Scalars['String'];
};

/** Helper type. Autogenerated. */
export type Sqs_Tags = {
  __typename?: 'SQS_tags';
  key: Scalars['String'];
  value: Scalars['String'];
};

/** Helper type. Autogenerated. */
export type SnykVulnerability_IgnoreReasons = {
  __typename?: 'SnykVulnerability_ignoreReasons';
  expires?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
};

/** Helper type. Autogenerated. */
export type SpannerInstance_Labels = {
  __typename?: 'SpannerInstance_labels';
  key: Scalars['String'];
  value: Scalars['String'];
};

/** Enum containing every SpecificResource. Autogenerated. */
export enum SpecificResource {
  ALB = 'ALB',
  AdpRunHrUser = 'AdpRunHrUser',
  AdpWorkforceNowHrUser = 'AdpWorkforceNowHrUser',
  AirtableTask = 'AirtableTask',
  AmazonLinuxPackageChangelog = 'AmazonLinuxPackageChangelog',
  AsanaAccount = 'AsanaAccount',
  AsanaTask = 'AsanaTask',
  AtlasAccount = 'AtlasAccount',
  AtlasCluster = 'AtlasCluster',
  AutoScalingGroup = 'AutoScalingGroup',
  AwsAccount = 'AwsAccount',
  AwsContainerVulnerability = 'AwsContainerVulnerability',
  AwsCredentialReport = 'AwsCredentialReport',
  AwsFlowLog = 'AwsFlowLog',
  AwsGroup = 'AwsGroup',
  AwsInspectorVulnerability = 'AwsInspectorVulnerability',
  AwsNetworkACL = 'AwsNetworkACL',
  AwsPasswordPolicy = 'AwsPasswordPolicy',
  AwsRole = 'AwsRole',
  AwsRouteTable = 'AwsRouteTable',
  AwsSecurityGroup = 'AwsSecurityGroup',
  AwsSubnet = 'AwsSubnet',
  AwsVPC = 'AwsVPC',
  AzureApplicationGateway = 'AzureApplicationGateway',
  AzureBlobContainer = 'AzureBlobContainer',
  AzureContainerRepository = 'AzureContainerRepository',
  AzureContainerVulnerability = 'AzureContainerVulnerability',
  AzureCosmosDB = 'AzureCosmosDB',
  AzureDatabaseForExternalSQLVariant = 'AzureDatabaseForExternalSQLVariant',
  AzureDevOpsAccount = 'AzureDevOpsAccount',
  AzureDevOpsRepo = 'AzureDevOpsRepo',
  AzureDevOpsTask = 'AzureDevOpsTask',
  AzureLoadBalancer = 'AzureLoadBalancer',
  AzureMetricAlertRule = 'AzureMetricAlertRule',
  AzureQueue = 'AzureQueue',
  AzureRole = 'AzureRole',
  AzureRoleAssignment = 'AzureRoleAssignment',
  AzureSQLDatabase = 'AzureSQLDatabase',
  AzureSQLManagedInstance = 'AzureSQLManagedInstance',
  AzureSQLServerOnVMs = 'AzureSQLServerOnVMs',
  AzureScaleSetVirtualMachine = 'AzureScaleSetVirtualMachine',
  AzureSecurityGroup = 'AzureSecurityGroup',
  AzureSynapseWarehouse = 'AzureSynapseWarehouse',
  AzureVirtualMachine = 'AzureVirtualMachine',
  AzureVirtualMachineScaleSet = 'AzureVirtualMachineScaleSet',
  BambooHrHrUser = 'BambooHrHrUser',
  BigQueryDataset = 'BigQueryDataset',
  BigtableInstance = 'BigtableInstance',
  BitbucketAccount = 'BitbucketAccount',
  BitbucketRepo = 'BitbucketRepo',
  CentosPackageChangelog = 'CentosPackageChangelog',
  CertnBackgroundCheck = 'CertnBackgroundCheck',
  ClickupAccount = 'ClickupAccount',
  ClickupTask = 'ClickupTask',
  CloudSQLInstance = 'CloudSQLInstance',
  CloudTrail = 'CloudTrail',
  CloudWatchLogGroup = 'CloudWatchLogGroup',
  CloudWatchMetricAlarm = 'CloudWatchMetricAlarm',
  ClubhouseAccount = 'ClubhouseAccount',
  ClubhouseTask = 'ClubhouseTask',
  CustomerCheckrBackgroundCheck = 'CustomerCheckrBackgroundCheck',
  DatadogAccount = 'DatadogAccount',
  DatadogMonitor = 'DatadogMonitor',
  DatastoreProject = 'DatastoreProject',
  DebianPackageChangelog = 'DebianPackageChangelog',
  DigitalOceanApp = 'DigitalOceanApp',
  DigitalOceanContainerRepository = 'DigitalOceanContainerRepository',
  DigitalOceanDroplet = 'DigitalOceanDroplet',
  DigitalOceanFirewall = 'DigitalOceanFirewall',
  DigitalOceanLoadBalancer = 'DigitalOceanLoadBalancer',
  DigitalOceanRedisCluster = 'DigitalOceanRedisCluster',
  DigitalOceanRelationalDbCluster = 'DigitalOceanRelationalDbCluster',
  DigitalOceanSpace = 'DigitalOceanSpace',
  DriveBackgroundCheck = 'DriveBackgroundCheck',
  DynamoDBTable = 'DynamoDBTable',
  EC2Instance = 'EC2Instance',
  ECRContainerRepository = 'ECRContainerRepository',
  EmptyDailySpecificResource = 'EmptyDailySpecificResource',
  EmptySpecificResource = 'EmptySpecificResource',
  FirestoreProject = 'FirestoreProject',
  GCPComputeInstance = 'GCPComputeInstance',
  GCPContainerRepository = 'GCPContainerRepository',
  GCPContainerVulnerability = 'GCPContainerVulnerability',
  GCPLogBucket = 'GCPLogBucket',
  GCPLogSink = 'GCPLogSink',
  GCPMonitoringPolicy = 'GCPMonitoringPolicy',
  GCPNetwork = 'GCPNetwork',
  GCPRole = 'GCPRole',
  GCPRoleGrant = 'GCPRoleGrant',
  GCPStorageBucket = 'GCPStorageBucket',
  GCPSubnet = 'GCPSubnet',
  GCPSubscription = 'GCPSubscription',
  GCPTopic = 'GCPTopic',
  GithubAccount = 'GithubAccount',
  GithubRepo = 'GithubRepo',
  GithubTask = 'GithubTask',
  GitlabAccount = 'GitlabAccount',
  GitlabGroup = 'GitlabGroup',
  GitlabRepo = 'GitlabRepo',
  GitlabTask = 'GitlabTask',
  GsuiteUser = 'GsuiteUser',
  GustoHrUser = 'GustoHrUser',
  HerokuAccount = 'HerokuAccount',
  HerokuApp = 'HerokuApp',
  InsperityHrUser = 'InsperityHrUser',
  JamfAccount = 'JamfAccount',
  JamfManagedComputer = 'JamfManagedComputer',
  JiraAccount = 'JiraAccount',
  JiraTask = 'JiraTask',
  JustworksHrUser = 'JustworksHrUser',
  KandjiManagedComputer = 'KandjiManagedComputer',
  KnowBe4Account = 'KnowBe4Account',
  KnowBe4TrainingCampaign = 'KnowBe4TrainingCampaign',
  KnowBe4TrainingEnrollment = 'KnowBe4TrainingEnrollment',
  LinearAccount = 'LinearAccount',
  LinearTask = 'LinearTask',
  MicrosoftEndpointManagerManagedComputer = 'MicrosoftEndpointManagerManagedComputer',
  NamelyHrUser = 'NamelyHrUser',
  NmapSsl = 'NmapSsl',
  NvdCve = 'NvdCve',
  OfficeUser = 'OfficeUser',
  OktaUser = 'OktaUser',
  OsqueryVulnerability = 'OsqueryVulnerability',
  PaychexFlexHrUser = 'PaychexFlexHrUser',
  PaycorHrUser = 'PaycorHrUser',
  PaylocityHrUser = 'PaylocityHrUser',
  PivotalTrackerAccount = 'PivotalTrackerAccount',
  PivotalTrackerTask = 'PivotalTrackerTask',
  QuickBooksHrUser = 'QuickBooksHrUser',
  RDSInstance = 'RDSInstance',
  RedshiftCluster = 'RedshiftCluster',
  RipplingHrUser = 'RipplingHrUser',
  S3 = 'S3',
  SQS = 'SQS',
  SlackAccount = 'SlackAccount',
  SnykAccount = 'SnykAccount',
  SnykProject = 'SnykProject',
  SnykVulnerability = 'SnykVulnerability',
  SpannerInstance = 'SpannerInstance',
  SquarePayrollHrUser = 'SquarePayrollHrUser',
  TrelloAccount = 'TrelloAccount',
  TrelloTask = 'TrelloTask',
  TrinetHrUser = 'TrinetHrUser',
  UbuntuPackageChangelog = 'UbuntuPackageChangelog',
  VantaCheckrBackgroundCheck = 'VantaCheckrBackgroundCheck',
  VettyBackgroundCheck = 'VettyBackgroundCheck',
  ZenefitsHrUser = 'ZenefitsHrUser'
}

/** Enum containing every GenericResource. Autogenerated. */
export enum GenericResource {
  Account = 'Account',
  AccountGroup = 'AccountGroup',
  Alarm = 'Alarm',
  BackgroundCheck = 'BackgroundCheck',
  CloudRole = 'CloudRole',
  CloudRoleGrant = 'CloudRoleGrant',
  ComputeInstance = 'ComputeInstance',
  ContainerRepository = 'ContainerRepository',
  CredentialReport = 'CredentialReport',
  CveRecord = 'CveRecord',
  DataWarehouse = 'DataWarehouse',
  EmptyResource = 'EmptyResource',
  FlowLog = 'FlowLog',
  HrUser = 'HrUser',
  LoadBalancer = 'LoadBalancer',
  LogGroup = 'LogGroup',
  LogTrail = 'LogTrail',
  ManagedComputer = 'ManagedComputer',
  ManagedInstanceGroup = 'ManagedInstanceGroup',
  Network = 'Network',
  NetworkACL = 'NetworkACL',
  NoSQLDatabase = 'NoSQLDatabase',
  PaaSApp = 'PaaSApp',
  PackageChangelogResource = 'PackageChangelogResource',
  PasswordPolicy = 'PasswordPolicy',
  Queue = 'Queue',
  RouteTable = 'RouteTable',
  SQLDatabase = 'SQLDatabase',
  SecurityGroup = 'SecurityGroup',
  Ssl = 'Ssl',
  StorageBucket = 'StorageBucket',
  Subnet = 'Subnet',
  TaskTrackerItem = 'TaskTrackerItem',
  Topic = 'Topic',
  Training = 'Training',
  TrainingRequirement = 'TrainingRequirement',
  UserGroup = 'UserGroup',
  UserResource = 'UserResource',
  VPC = 'VPC',
  VersionControlRepo = 'VersionControlRepo',
  Vulnerability = 'Vulnerability',
  VulnerabilityMonitoringTarget = 'VulnerabilityMonitoringTarget'
}

/** admin info */
export type AdminInfo = {
  __typename?: 'adminInfo';
  vantaAdmins?: Maybe<Array<Maybe<User>>>;
};

/** audit */
export type Audit = {
  __typename?: 'audit';
  id: Scalars['ID'];
  auditFirmInfo?: Maybe<AuditorInfo>;
  auditors: Array<Auditor>;
  auditorUserIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  auditPeriodDays: Scalars['Int'];
  auditStart: Scalars['String'];
  auditType: AuditTypeEnum;
  auditTypes: Array<AuditTypeEnum>;
  completionDate?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  observationEndDate: Scalars['DateTime'];
  observationStartDate: Scalars['DateTime'];
  domain: Domain;
  domainId: Scalars['String'];
  domainName: Scalars['String'];
  report?: Maybe<UploadedDocument>;
};

/** auditor */
export type Auditor = {
  __typename?: 'auditor';
  id: Scalars['ID'];
  displayName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firmName: Scalars['String'];
};

/** audit type */
export type AuditType = {
  __typename?: 'auditType';
  auditType: AuditTypeEnum;
  name?: Maybe<Scalars['String']>;
};

export type AuditorDocument = {
  __typename?: 'auditorDocument';
  id?: Maybe<Scalars['ID']>;
  domainId: Scalars['ID'];
  customerDomainId: Scalars['String'];
  customerDomainName: Scalars['String'];
  type: Scalars['String'];
  name: Scalars['String'];
  removedDate?: Maybe<Scalars['DateTime']>;
  created: ChangelogEntry;
  lastUpdate: ChangelogEntry;
};

/** auditor info */
export type AuditorInfo = {
  __typename?: 'auditorInfo';
  id: Scalars['ID'];
  firmName: Scalars['String'];
  hasBankAccountConnected: Scalars['Boolean'];
  domain: Domain;
};

/** emails buffered because of a kill switch */
export type BufferedEmail = {
  __typename?: 'bufferedEmail';
  id: Scalars['ID'];
  emailKey: Scalars['String'];
  domainId: Scalars['ID'];
};

/** compliance standard */
export type ComplianceStandard = {
  __typename?: 'complianceStandard';
  principles: Array<CompliancePrinciple>;
  standard: ReportStandard;
  canonical: Scalars['String'];
  version: Scalars['String'];
};

/** compliance principle */
export type CompliancePrinciple = {
  __typename?: 'compliancePrinciple';
  name: Scalars['String'];
  shorthand?: Maybe<Scalars['String']>;
  section: Scalars['String'];
  optional: Scalars['Boolean'];
  requirements: Array<ComplianceRequirement>;
};

/** compliance requirement */
export type ComplianceRequirement = {
  __typename?: 'complianceRequirement';
  name: Scalars['String'];
  section: Scalars['String'];
  shorthand?: Maybe<Scalars['String']>;
  controls: Array<ComplianceControl>;
};

/** compliance control */
export type ComplianceControl = {
  __typename?: 'complianceControl';
  key: Scalars['String'];
  control: Scalars['String'];
  name: Scalars['String'];
  evidence: Array<Scalars['String']>;
  tests: Array<ComplianceTest>;
};


/** compliance control */
export type ComplianceControlTestsArgs = {
  domainId: Scalars['ID'];
};

/** Some selection of sections from a standard */
export type StandardSections = {
  __typename?: 'standardSections';
  standard: ReportStandard;
  sections: Array<Scalars['String']>;
};

/** Information about a control */
export type Control = {
  __typename?: 'control';
  controlId: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['String'];
  standardSections: Array<StandardSections>;
};

/** compliance test */
export type ComplianceTest = {
  __typename?: 'complianceTest';
  name: Scalars['String'];
  defaultRemediation: Scalars['String'];
  severity: Scalars['String'];
  test: Scalars['String'];
  testId: Scalars['String'];
};

/** credentials */
export type Credentials = {
  __typename?: 'credentials';
  accountId?: Maybe<Scalars['String']>;
  externalAccountId?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  isDisabled: Scalars['Boolean'];
  disableCause?: Maybe<Scalars['String']>;
  disableHistory: Array<DisableHistoryEntry>;
  id: Scalars['ID'];
  lastUpdated: Scalars['DateTime'];
  metadata?: Maybe<Scalars['String']>;
  service: Scalars['String'];
};

export type DisableHistoryEntry = {
  __typename?: 'disableHistoryEntry';
  cause?: Maybe<Scalars['String']>;
  disableDate?: Maybe<Scalars['DateTime']>;
};

/** creditcard */
export type CreditCard = {
  __typename?: 'creditCard';
  brand: Scalars['String'];
  last4: Scalars['String'];
};

/** cronFilter */
export type CronFilter = {
  __typename?: 'cronFilter';
  id: Scalars['ID'];
  queueName: Scalars['String'];
  blacklistDomains: Array<Scalars['String']>;
  blacklistItems: Array<Scalars['String']>;
  whitelistDomains: Array<Scalars['String']>;
  whitelistItems: Array<Scalars['String']>;
  blacklistDomainsAndItems: Array<CronFilterDomainItemPair>;
};

/** cronFilterDomainItemPair */
export type CronFilterDomainItemPair = {
  __typename?: 'cronFilterDomainItemPair';
  domainId: Scalars['ID'];
  item: Scalars['String'];
};

/** view on a document (presented report or uploaded document) */
export type DocumentView = {
  __typename?: 'DocumentView';
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastUpdated: Scalars['DateTime'];
  name?: Maybe<Scalars['String']>;
};

export type PaginatedChanges = {
  __typename?: 'paginatedChanges';
  changeList: Array<Change>;
  nextPageUrl?: Maybe<Scalars['String']>;
};

export type Change = {
  __typename?: 'change';
  id: Scalars['String'];
  authorAccountId: Scalars['String'];
  body?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  closedAt?: Maybe<Scalars['String']>;
  reviewerAccountIds?: Maybe<Array<Scalars['String']>>;
  reviewBodies: Array<Scalars['String']>;
  repo: Scalars['String'];
  ref?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type AtlasProject = {
  __typename?: 'atlasProject';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type SnykOrganization = {
  __typename?: 'snykOrganization';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type AccessibleSnykOrganizationsResponse = {
  __typename?: 'accessibleSnykOrganizationsResponse';
  success: Scalars['Boolean'];
  statusCode: Scalars['String'];
  organizations: Array<SnykOrganization>;
};

export type TestRunStats = {
  __typename?: 'testRunStats';
  numPass: Scalars['Int'];
  numFail: Scalars['Int'];
  numNA: Scalars['Int'];
  numDisabled: Scalars['Int'];
  numInProgress: Scalars['Int'];
};

/** domain */
export type Domain = {
  __typename?: 'domain';
  /**
   * AccessibleAtlasProjects optionally takes mongo credentials. If provided,
   * it returns the list of projects accessible to those creds. If not, it makes an api-service
   * call to check if we have any stored creds in the database.
   */
  accessibleAtlasProjects: Array<AtlasProject>;
  accessSlasHistory: Array<AccessSlas>;
  accessSlas?: Maybe<AccessSlas>;
  admins?: Maybe<AdminInfo>;
  allLinkableHrUsers: Array<HrUser>;
  audits: Array<Audit>;
  auditorsWithAccess: Array<Auditor>;
  backgroundCheckCutoff?: Maybe<Scalars['DateTime']>;
  /** Note: this resolver returns all, not just beta features. The name is outdated */
  betaFeatures: Array<Feature>;
  businessInfo?: Maybe<DomainBusinessInfo>;
  cloudInstances: ComputeInstanceConnection;
  companyLogoSlug?: Maybe<Scalars['String']>;
  controls?: Maybe<Array<Control>>;
  createdAt: Scalars['DateTime'];
  credentials: Array<Credentials>;
  defaultSecurityRequirements: SecurityRequirements;
  defaultNecessaryPolicies?: Maybe<Array<Scalars['String']>>;
  displayName: Scalars['String'];
  distributedQueries: Array<DistributedQuery>;
  endpoints: Array<Osquery>;
  /** Each evidenceRequest also includes any evidence uploaded for that request. */
  evidenceRequests: Array<EvidenceRequest>;
  herokuApps: Array<HerokuApp>;
  hostEnrollSecret: Scalars['String'];
  id: Scalars['ID'];
  inAuditPeriod: Scalars['Boolean'];
  interestedStandards?: Maybe<Array<ReportStandard>>;
  lockedAndPastGracePeriod: Scalars['Boolean'];
  lockoutDate?: Maybe<Scalars['DateTime']>;
  machines: EndpointConnection;
  name: Scalars['String'];
  networkConfiguration?: Maybe<NetworkConfiguration>;
  notificationSettings?: Maybe<NotificationSettings>;
  numFailingTestResults: Scalars['Int'];
  numUnlinkedHrUsers: Scalars['Int'];
  mdmSlasHistory: Array<MdmSlas>;
  mdmSlas?: Maybe<MdmSlas>;
  onboardingAdmin: User;
  onboardingSla?: Maybe<Scalars['Int']>;
  onboardingSlasHistory: Array<OnboardingSlas>;
  optedOutOfGsuitePermission: Scalars['Boolean'];
  osqueryEndpointApplicationData?: Maybe<Array<Scalars['String']>>;
  osqueryEndpoints?: Maybe<Array<Osquery>>;
  osqueryEndpointsByIds: Array<Osquery>;
  otherInventoryItems: OtherInventoryConnection;
  packageVulnerabilities: PackageVulnerabilityConnection;
  passwordPolicies: Array<PasswordPolicy>;
  payment: PaymentInfo;
  penTestHistory: Array<UploadedDocument>;
  policies: Array<Policy>;
  policyDocStubs: Array<PolicyDocStub>;
  policyInfos?: Maybe<PolicyInfos>;
  policyPacket?: Maybe<Policy>;
  policyVersionHistory: Array<Policy>;
  presentedReports: Array<PresentedReport>;
  productDescriptionInfo?: Maybe<DomainDescriptionInfo>;
  repoInfos: Array<RepoInfo>;
  requiresLocationServices: Scalars['Boolean'];
  resources: ResourceConnection;
  resourcesByIds: Array<Resource>;
  riskDismissals: Array<RiskDismissal>;
  riskEvaluations: Array<RiskEvaluation>;
  riskMitigationTasks: Array<RiskMitigationTask>;
  riskRegister: RiskRegister;
  rrv3Assets: Array<RiskAssessmentAsset>;
  rrv3RiskScenarios: Array<RiskAssessmentScenario>;
  roles: Array<Role>;
  salesforceId?: Maybe<Scalars['String']>;
  securityIssueSlasHistory: Array<SecurityIssueSlas>;
  securityIssueSlas?: Maybe<SecurityIssueSlas>;
  serviceAccounts: Array<ServiceAccount>;
  slug?: Maybe<Scalars['String']>;
  standards: Array<ReportStandard>;
  testResults: Array<TestResult>;
  testRunStats: TestRunStats;
  testSelection?: Maybe<Array<TestSelectionEntry>>;
  uiLinkableBackgroundChecks: Array<UiLinkableBackgroundCheck>;
  unlinkedHrUsers: Array<HrUser>;
  unlinkedServiceAccounts: Array<ServiceAccount>;
  users: Array<User>;
  usersByIds: Array<Maybe<User>>;
  vaqSubmission?: Maybe<VaqSubmission>;
  vendors: Array<Vendor>;
  osqueryVulnerabilities: Array<OsqueryVulnerability>;
  /**
   * ValidateLinkedGcpCredentials differs from validateGcpCredentials (query) in
   * that it validates credentials currently linked for the parent domain rather
   * than pre-linking credentials. This isn't used in-product, but is useful for
   * manual diagnostics.
   */
  validateLinkedGcpCredentials: GcpCredentialValidationOutcome;
  vulnerabilitySlasHistory: Array<VulnerabilitySlas>;
  vulnerabilitySlas?: Maybe<VulnerabilitySlas>;
  /**
   * Vulnerability "targets" include the ContainerRepository and
   * VulnerabilityMonitoringTarget generic resources.
   *
   * This field performs a lookup aggregation over the vulnerabilities collections
   * to allow for sorting by total vuln count, sla deadlines, etc.
   * Only includes in-scope resources.
   */
  vulnerabilityTargets: VulnTargetConnection;
  auditorDocuments: Array<AuditorDocument>;
};


/** domain */
export type DomainAccessibleAtlasProjectsArgs = {
  mongoCredentials?: Maybe<UsernamePassword>;
};


/** domain */
export type DomainAuditsArgs = {
  currentAudits?: Maybe<Scalars['Boolean']>;
  futureAudits?: Maybe<Scalars['Boolean']>;
  pastAudits?: Maybe<Scalars['Boolean']>;
};


/** domain */
export type DomainCloudInstancesArgs = {
  instanceIds?: Maybe<Array<Scalars['String']>>;
  activeOnly?: Maybe<Scalars['Boolean']>;
  vantaAgentOnly?: Maybe<Scalars['Boolean']>;
  withoutVantaAgentOnly?: Maybe<Scalars['Boolean']>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
};


/** domain */
export type DomainControlsArgs = {
  filter?: Maybe<ControlsFilter>;
};


/** domain */
export type DomainCredentialsArgs = {
  services?: Maybe<Array<Scalars['String']>>;
  includeDisabled?: Maybe<Scalars['Boolean']>;
  includeIncompleteMetadata?: Maybe<Scalars['Boolean']>;
};


/** domain */
export type DomainEndpointsArgs = {
  activeOnly: Scalars['Boolean'];
  endpointIds?: Maybe<Array<Scalars['String']>>;
};


/** domain */
export type DomainEvidenceRequestsArgs = {
  evidenceRequestIds?: Maybe<Array<Scalars['String']>>;
  standard?: Maybe<ReportStandard>;
};


/** domain */
export type DomainMachinesArgs = {
  activeOnly?: Maybe<Scalars['Boolean']>;
  serversOnly?: Maybe<Scalars['Boolean']>;
  workstationsOnly?: Maybe<Scalars['Boolean']>;
  nonCloudOnly?: Maybe<Scalars['Boolean']>;
  ids?: Maybe<Array<Scalars['String']>>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  sortParams?: Maybe<SortParams>;
  filterParams?: Maybe<FilterParams>;
};


/** domain */
export type DomainNumUnlinkedHrUsersArgs = {
  service?: Maybe<Scalars['String']>;
};


/** domain */
export type DomainOsqueryEndpointApplicationDataArgs = {
  endpointId: Scalars['String'];
};


/** domain */
export type DomainOsqueryEndpointsByIdsArgs = {
  endpointIds: Array<Scalars['ID']>;
};


/** domain */
export type DomainOtherInventoryItemsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  sortParams?: Maybe<SortParams>;
  filterParams?: Maybe<FilterParams>;
};


/** domain */
export type DomainPackageVulnerabilitiesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  vulnerablePackageIds?: Maybe<Array<Scalars['String']>>;
  applyWhitelist?: Maybe<Scalars['Boolean']>;
  sortParams?: Maybe<SortParams>;
  filterParams?: Maybe<FilterParams>;
};


/** domain */
export type DomainPoliciesArgs = {
  onlyApproved?: Maybe<Scalars['Boolean']>;
};


/** domain */
export type DomainPolicyVersionHistoryArgs = {
  policyType: Scalars['String'];
};


/** domain */
export type DomainResourcesArgs = {
  specificResourceType?: Maybe<SpecificResource>;
  genericResourceType?: Maybe<GenericResource>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  excludeWhitelistedTestIds?: Maybe<Array<Scalars['String']>>;
  filterParams?: Maybe<FilterParams>;
  sortParams?: Maybe<SortParams>;
  options?: Maybe<ResourceFilters>;
};


/** domain */
export type DomainResourcesByIdsArgs = {
  ids: Array<Scalars['String']>;
  specificResourceType: SpecificResource;
};


/** domain */
export type DomainServiceAccountsArgs = {
  service?: Maybe<Scalars['String']>;
  includeDeleted?: Maybe<Scalars['Boolean']>;
};


/** domain */
export type DomainTestResultsArgs = {
  testIds?: Maybe<Array<Scalars['String']>>;
  testRunId?: Maybe<Scalars['String']>;
  filterNA?: Maybe<Scalars['Boolean']>;
  includeInRollout?: Maybe<Scalars['Boolean']>;
};


/** domain */
export type DomainTestRunStatsArgs = {
  severity?: Maybe<Scalars['String']>;
};


/** domain */
export type DomainUsersArgs = {
  includeRemovedUsers?: Maybe<Scalars['Boolean']>;
  includeNonHumanUsers?: Maybe<Scalars['Boolean']>;
};


/** domain */
export type DomainUsersByIdsArgs = {
  ids: Array<Scalars['ID']>;
};


/** domain */
export type DomainVaqSubmissionArgs = {
  id: Scalars['String'];
};


/** domain */
export type DomainOsqueryVulnerabilitiesArgs = {
  includeResolved?: Maybe<Scalars['Boolean']>;
  ids?: Maybe<Array<Scalars['String']>>;
};


/** domain */
export type DomainVulnerabilityTargetsArgs = {
  specificResourceType: SpecificResource;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  sortParams?: Maybe<SortParams>;
  filterParams?: Maybe<FilterParams>;
};

export type TestSelectionEntry = {
  __typename?: 'testSelectionEntry';
  sources: Array<Scalars['String']>;
  testId: Scalars['String'];
};

export type RepoInfo = {
  __typename?: 'repoInfo';
  name: Scalars['String'];
  defaultBranch: Scalars['String'];
  service: Scalars['String'];
  uniqueId: Scalars['String'];
};

export type RiskRegister = {
  __typename?: 'riskRegister';
  questions: Array<RiskQuestionForDomain>;
  scenarios: Array<RiskScenario>;
  tasks: Array<RiskTask>;
};

/** domain sla durations */
export type AccessSlas = {
  __typename?: 'accessSlas';
  accessReviewed: Scalars['Float'];
  accessRevoked: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  creatorName: Scalars['String'];
};

export type MdmSlas = {
  __typename?: 'mdmSlas';
  computerSetup: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  creatorName: Scalars['String'];
};

export type OnboardingSlas = {
  __typename?: 'onboardingSlas';
  employeesOnboarded: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  creatorName: Scalars['String'];
};

export type SecurityIssueSlas = {
  __typename?: 'securityIssueSlas';
  securityBugP0?: Maybe<Scalars['Float']>;
  securityBugP1?: Maybe<Scalars['Float']>;
  securityBugP2?: Maybe<Scalars['Float']>;
  securityBugP3?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  creatorName: Scalars['String'];
};

export type VulnerabilitySlas = {
  __typename?: 'vulnerabilitySlas';
  vulnHighSeverity: Scalars['Float'];
  vulnLowSeverity: Scalars['Float'];
  vulnMediumSeverity: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  creatorName: Scalars['String'];
};

/** domain business info */
export type DomainBusinessInfo = {
  __typename?: 'domainBusinessInfo';
  USCompany?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  displayName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  legalName?: Maybe<Scalars['String']>;
  mailingAddress?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  url?: Maybe<Scalars['String']>;
};

/** security training preference */
export type SecurityTrainingPreference = {
  __typename?: 'securityTrainingPreference';
  standard?: Maybe<ReportStandard>;
  preferredSecurityTraining: Scalars['String'];
  securityTrainingInstructions?: Maybe<Scalars['String']>;
  securityTrainingUrl?: Maybe<Scalars['String']>;
};

/** Security training preference */
export type TrainingPreference = {
  __typename?: 'TrainingPreference';
  preferredSecurityTraining: Scalars['String'];
  securityTrainingInstructions?: Maybe<Scalars['String']>;
  securityTrainingUrl?: Maybe<Scalars['String']>;
};

/** domain description info */
export type DomainDescriptionInfo = {
  __typename?: 'domainDescriptionInfo';
  bugBountyUrl?: Maybe<Scalars['String']>;
  companyInfoUrl?: Maybe<Scalars['String']>;
  companyLogo?: Maybe<UploadedDocument>;
  createdAt: Scalars['DateTime'];
  customerBillingTool?: Maybe<Scalars['String']>;
  customerSupportTool?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  gdprSatPreference?: Maybe<TrainingPreference>;
  generalSatPreference?: Maybe<TrainingPreference>;
  hipaaSatPreference?: Maybe<TrainingPreference>;
  hipaaSecurityOfficerId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  pciSatPreference?: Maybe<TrainingPreference>;
  penTestDate?: Maybe<Scalars['DateTime']>;
  penTestFile?: Maybe<UploadedDocument>;
  penTestFrequency?: Maybe<Scalars['String']>;
  preferredSecurityTraining?: Maybe<Scalars['String']>;
  securityTrainingInstructions?: Maybe<Scalars['String']>;
  securityTrainingPreferences: Array<SecurityTrainingPreference>;
  securityTrainingUrl?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  userId: Scalars['ID'];
};

/** user email subscription preference */
export type UserEmailSubscriptionSetting = {
  __typename?: 'userEmailSubscriptionSetting';
  domainId: Scalars['ID'];
  userId: Scalars['ID'];
  emailKey: EmailKey;
  shouldSend: Scalars['Boolean'];
  description: Scalars['String'];
  friendlyName: Scalars['String'];
  category: EmailCategoryForUnsubscribe;
  sensitive: Scalars['Boolean'];
  id: Scalars['ID'];
};

export type TestCredentialsResult = {
  __typename?: 'testCredentialsResult';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type SubmitPolicyResult = {
  __typename?: 'submitPolicyResult';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type NewCustomer = {
  __typename?: 'newCustomer';
  contactEmail?: Maybe<Scalars['String']>;
  domainName: Scalars['String'];
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type ResourceFetch = {
  __typename?: 'resourceFetch';
  id: Scalars['ID'];
  kind: Scalars['String'];
  partialFetch: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
  triggeredBy?: Maybe<FetchTrigger>;
  status?: Maybe<FetchStatus>;
  createdAt: Scalars['DateTime'];
};

export type RiskDismissal = {
  __typename?: 'riskDismissal';
  id: Scalars['ID'];
  riskId: Scalars['String'];
  dismissingUser?: Maybe<User>;
  createdAt: Scalars['DateTime'];
};

export type RiskEvaluation = {
  __typename?: 'riskEvaluation';
  id: Scalars['ID'];
  evaluator?: Maybe<User>;
  riskId: Scalars['String'];
  itemName: Scalars['String'];
  impact?: Maybe<Scalars['Int']>;
  likelihood?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
};

export type RiskMitigationTask = {
  __typename?: 'riskMitigationTask';
  id: Scalars['ID'];
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  riskCategoryId: Scalars['String'];
  description: Scalars['String'];
  assignee?: Maybe<User>;
  dueDate?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<User>;
  updatedAt: Scalars['DateTime'];
};

export type RiskQuestion = {
  __typename?: 'riskQuestion';
  id: Scalars['ID'];
  text: Scalars['String'];
  category: Scalars['String'];
  affirmative: Scalars['Boolean'];
};

export type RiskQuestionForDomain = {
  __typename?: 'riskQuestionForDomain';
  id: Scalars['ID'];
  text: Scalars['String'];
  category: Scalars['String'];
  affirmative: Scalars['Boolean'];
  domainId: Scalars['ID'];
  scenarios: Array<RiskScenario>;
  complete: Scalars['Boolean'];
  dismissed: Scalars['Boolean'];
};

export type RiskTask = {
  __typename?: 'riskTask';
  id: Scalars['ID'];
  domainId: Scalars['ID'];
  mostRecentUpdate?: Maybe<ChangelogEntry>;
  text: Scalars['String'];
  completedAt?: Maybe<Scalars['DateTime']>;
  dismissedAt?: Maybe<Scalars['DateTime']>;
  dismissReason?: Maybe<Scalars['String']>;
};

export type RiskScenario = {
  __typename?: 'riskScenario';
  id: Scalars['ID'];
  domainId: Scalars['ID'];
  questionId?: Maybe<Scalars['String']>;
  text: Scalars['String'];
  mostRecentUpdate?: Maybe<ChangelogEntry>;
  likelihood: Scalars['Int'];
  impact: Scalars['Int'];
  tasks: Array<RiskTask>;
};

export type RiskAssessmentAsset = {
  __typename?: 'riskAssessmentAsset';
  id: Scalars['ID'];
  domainId: Scalars['ID'];
  name: Scalars['String'];
  riskCategoryId: Scalars['String'];
};

export type RiskAssessmentScenario = {
  __typename?: 'riskAssessmentScenario';
  id: Scalars['ID'];
  description: Scalars['String'];
  evaluator?: Maybe<User>;
  impact: Scalars['Int'];
  likelihood: Scalars['Int'];
  riskCategoryId: Scalars['String'];
  tasks: Array<RiskAssessmentTask>;
  updatedAt: Scalars['DateTime'];
};

export type RiskAssessmentTask = {
  __typename?: 'riskAssessmentTask';
  id: Scalars['ID'];
  assignee?: Maybe<User>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  dueDate: Scalars['DateTime'];
  updatedBy?: Maybe<User>;
};

export type ChangelogEntry = {
  __typename?: 'changelogEntry';
  user?: Maybe<User>;
  timestamp: Scalars['DateTime'];
};

/** network configuration */
export type NetworkConfiguration = {
  __typename?: 'networkConfiguration';
  subnets?: Maybe<Array<Subnet>>;
  vpcs?: Maybe<Array<Vpc>>;
};

/** role */
export type Role = {
  __typename?: 'role';
  id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  adminTasks: Array<RoleTask>;
  employeeTasks: Array<RoleTask>;
  roleCompletionRecords: Array<RoleCompletionRecord>;
  users: Array<User>;
  securityRequirements: SecurityRequirements;
  necessaryPolicies?: Maybe<Array<Scalars['String']>>;
};

/** roleCompletionRecord */
export type RoleCompletionRecord = {
  __typename?: 'roleCompletionRecord';
  adminCompletionDate?: Maybe<Scalars['DateTime']>;
  adminTaskCompletions: Array<RoleTaskCompletionRecord>;
  approver?: Maybe<User>;
  employeeCompletionDate?: Maybe<Scalars['DateTime']>;
  employeeTaskCompletions: Array<RoleTaskCompletionRecord>;
  id: Scalars['ID'];
  hasNoTasks: Scalars['Boolean'];
  name: Scalars['String'];
  role: Role;
  user: User;
};

/** roleTask */
export type RoleTask = {
  __typename?: 'roleTask';
  id: Scalars['ID'];
  requiredInfo?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  fileInputLabel?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  textInputLabel?: Maybe<Scalars['String']>;
};

/** roleTaskCompletionRecord */
export type RoleTaskCompletionRecord = {
  __typename?: 'roleTaskCompletionRecord';
  id: Scalars['ID'];
  approver?: Maybe<User>;
  approverId?: Maybe<Scalars['String']>;
  completionDate?: Maybe<Scalars['DateTime']>;
  responseText?: Maybe<Scalars['String']>;
  file?: Maybe<UploadedDocument>;
  task: RoleTask;
  userId: Scalars['ID'];
};

export type OsqueryData = {
  agentVersion?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  hostIdentifier: Scalars['String'];
  hostname?: Maybe<Scalars['String']>;
  lastPing?: Maybe<Scalars['String']>;
  osVersion?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
};

export type MacosWorkstationData = OsqueryData & {
  __typename?: 'macosWorkstationData';
  id: Scalars['String'];
  agentVersion?: Maybe<Scalars['String']>;
  applications?: Maybe<Array<Scalars['String']>>;
  adminAccounts?: Maybe<Array<UserOnMachine>>;
  browserExtensions?: Maybe<Array<Scalars['String']>>;
  hostIdentifier: Scalars['String'];
  hostname?: Maybe<Scalars['String']>;
  installedAvPrograms?: Maybe<Array<Scalars['String']>>;
  isEncrypted?: Maybe<Scalars['Boolean']>;
  isLocationServicesEnabled?: Maybe<Scalars['Boolean']>;
  isPasswordManagerInstalled?: Maybe<Scalars['Boolean']>;
  installedPasswordManagers?: Maybe<Array<Scalars['String']>>;
  lastPing?: Maybe<Scalars['String']>;
  numApplications?: Maybe<Scalars['Int']>;
  numBrowserExtensions?: Maybe<Scalars['Int']>;
  osVersion?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  authorizedKeys?: Maybe<Array<SshAuthorizedKey>>;
  sshKeys?: Maybe<Array<SshUserKey>>;
};

export type LinuxWorkstationData = OsqueryData & {
  __typename?: 'linuxWorkstationData';
  id: Scalars['String'];
  adminAccounts?: Maybe<Array<UserOnMachine>>;
  agentVersion?: Maybe<Scalars['String']>;
  browserExtensions?: Maybe<Array<Scalars['String']>>;
  hostIdentifier: Scalars['String'];
  hostname?: Maybe<Scalars['String']>;
  installedAvPrograms?: Maybe<Array<Scalars['String']>>;
  isEncrypted?: Maybe<Scalars['Boolean']>;
  lastPing?: Maybe<Scalars['String']>;
  osVersion?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  authorizedKeys?: Maybe<Array<SshAuthorizedKey>>;
  sshKeys?: Maybe<Array<SshUserKey>>;
};

export type VulnSummary = {
  __typename?: 'VulnSummary';
  totalVulnCount: Scalars['Int'];
  maxSeverity?: Maybe<Scalars['Float']>;
  minSlaDeadline?: Maybe<Scalars['DateTime']>;
  totalLowSeverity: Scalars['Int'];
  totalMidSeverity: Scalars['Int'];
  totalHighSeverity: Scalars['Int'];
};

export type LinuxServerData = OsqueryData & {
  __typename?: 'linuxServerData';
  id: Scalars['String'];
  adminAccounts?: Maybe<Array<UserOnMachine>>;
  agentVersion?: Maybe<Scalars['String']>;
  hostIdentifier: Scalars['String'];
  hostname?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  instanceId?: Maybe<Scalars['String']>;
  instanceType?: Maybe<Scalars['String']>;
  kernelVersion?: Maybe<Scalars['String']>;
  lastPing?: Maybe<Scalars['String']>;
  osVersion?: Maybe<Scalars['String']>;
  osFamily?: Maybe<ServerOsFamily>;
  region?: Maybe<Scalars['String']>;
  reportsPackages?: Maybe<Scalars['Boolean']>;
  vulnSummary: VulnSummary;
  vulnerablePackages?: Maybe<Array<OsqueryVulnerability>>;
  authorizedKeys?: Maybe<Array<SshAuthorizedKey>>;
  sshKeys?: Maybe<Array<SshUserKey>>;
};


export type LinuxServerDataVulnSummaryArgs = {
  includeResolved?: Maybe<Scalars['Boolean']>;
};


export type LinuxServerDataVulnerablePackagesArgs = {
  includeResolved?: Maybe<Scalars['Boolean']>;
};

export type WindowsWorkstationData = OsqueryData & {
  __typename?: 'windowsWorkstationData';
  id: Scalars['String'];
  agentVersion?: Maybe<Scalars['String']>;
  applications?: Maybe<Array<Scalars['String']>>;
  browserExtensions?: Maybe<Array<Scalars['String']>>;
  hostIdentifier: Scalars['String'];
  hostname?: Maybe<Scalars['String']>;
  installedAvPrograms?: Maybe<Array<Scalars['String']>>;
  isEncrypted?: Maybe<Scalars['Boolean']>;
  isPasswordManagerInstalled?: Maybe<Scalars['Boolean']>;
  installedPasswordManagers?: Maybe<Array<Scalars['String']>>;
  lastPing?: Maybe<Scalars['String']>;
  numApplications?: Maybe<Scalars['Int']>;
  numBrowserExtensions?: Maybe<Scalars['Int']>;
  osVersion?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
};

/** Information about a single osquery client */
export type Osquery = {
  __typename?: 'osquery';
  active: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  domainId?: Maybe<Scalars['ID']>;
  hostIdentifier: Scalars['String'];
  id: Scalars['ID'];
  node_key: Scalars['String'];
  platform: Scalars['String'];
  target: Scalars['String'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['ID']>;
  cloudProvider: Scalars['String'];
  cloudProviderId?: Maybe<Scalars['String']>;
  cloudProviderData?: Maybe<GenericComputeInstanceResource>;
  prettyName: Scalars['String'];
  data: OsqueryData;
  epoch: Scalars['Int'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

/** Used by vanta-cli doctor and /query */
export type OsqueryMetadataForAgent = {
  __typename?: 'osqueryMetadataForAgent';
  isEnrolled: Scalars['Boolean'];
  domainName?: Maybe<Scalars['String']>;
  cloudProviderId?: Maybe<Scalars['String']>;
  cloudProvider?: Maybe<Scalars['String']>;
  userEmail?: Maybe<Scalars['String']>;
  platform?: Maybe<Scalars['String']>;
  hostIdentifier?: Maybe<Scalars['String']>;
  domainId?: Maybe<Scalars['ID']>;
};

/** password policy info */
export type PasswordPolicy = {
  __typename?: 'passwordPolicy';
  minLength: Scalars['Int'];
  requiresNumber: Scalars['Boolean'];
  requiresSymbol: Scalars['Boolean'];
  serviceName: Scalars['String'];
};

/** payment plan */
export type PaymentInfo = {
  __typename?: 'paymentInfo';
  billingEmail?: Maybe<Scalars['String']>;
  defaultCreditCard?: Maybe<CreditCard>;
  id: Scalars['ID'];
  vantaSubscription?: Maybe<PaymentPlan>;
};

export type PlanPrices = {
  __typename?: 'planPrices';
  base?: Maybe<Scalars['Float']>;
};

/** payment plan */
export type PaymentPlan = {
  __typename?: 'paymentPlan';
  billingPeriodEnd?: Maybe<Scalars['String']>;
  intervalCount: Scalars['Int'];
  paymentInterval: PaymentInterval;
  paymentMethod?: Maybe<Scalars['String']>;
  pricesPerPaymentInterval: PlanPrices;
  status: Scalars['String'];
  subscriptionKey: Scalars['String'];
};

/** policy document metadata */
export type Policy = {
  __typename?: 'policy';
  active: Scalars['Boolean'];
  approvedAt?: Maybe<Scalars['DateTime']>;
  approver?: Maybe<User>;
  approverName?: Maybe<Scalars['String']>;
  changeDescription?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  creator?: Maybe<User>;
  creatorName: Scalars['String'];
  domainId: Scalars['ID'];
  generating: Scalars['Boolean'];
  id: Scalars['ID'];
  majorChange?: Maybe<Scalars['Boolean']>;
  numUsers: Scalars['Int'];
  numUsersAccepted: Scalars['Int'];
  policyType: Scalars['String'];
  rolesToAccept?: Maybe<Array<Role>>;
  staticSlug: Scalars['String'];
  title: Scalars['String'];
  uploadedDoc: UploadedDocument;
};

export type PolicyRequirement = {
  __typename?: 'PolicyRequirement';
  acceptance?: Maybe<PolicyAcceptance>;
  policy: Policy;
};

/** policy doc stub definition */
export type PolicyDocStub = {
  __typename?: 'policyDocStub';
  policyType: Scalars['String'];
  standards?: Maybe<Array<ReportStandard>>;
  description: Scalars['String'];
  title: Scalars['String'];
};

/** user security policies acceptance */
export type PolicyAcceptance = {
  __typename?: 'policyAcceptance';
  createdAt: Scalars['DateTime'];
  domainId: Scalars['ID'];
  id: Scalars['ID'];
  policyId: Scalars['String'];
  policy?: Maybe<Policy>;
  updatedAt: Scalars['DateTime'];
  userId: Scalars['ID'];
};

/** policy info acceptable use */
export type PolicyInfoAcceptableUse = {
  __typename?: 'policyInfoAcceptableUse';
  anythingElseAcceptableUse?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  overallResponsibilityAcceptableUse: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

/** policy info asset management */
export type PolicyInfoAssetManagement = {
  __typename?: 'policyInfoAssetManagement';
  anythingElseAssetManagement?: Maybe<Scalars['String']>;
  approvedManufacturers: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  inventoryResponsibility: Scalars['String'];
  maybeAV?: Maybe<Scalars['Boolean']>;
  maybeBYOD?: Maybe<Scalars['Boolean']>;
  maybeLocationTracking?: Maybe<Scalars['Boolean']>;
  maybeNonApprovedManufacturers?: Maybe<Scalars['Boolean']>;
  maybePersonalFirewall?: Maybe<Scalars['Boolean']>;
  maybeScreensaverLock?: Maybe<Scalars['Boolean']>;
  maybeScreensaverLockTime?: Maybe<Scalars['String']>;
  overallResponsibilityAssetManagement: Scalars['String'];
  updateResponsibility: Scalars['String'];
  updateResponsibilityCommunal: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

/** policy info backup */
export type PolicyInfoBackup = {
  __typename?: 'policyInfoBackup';
  anythingElseBackup?: Maybe<Scalars['String']>;
  backupsTested?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  endpointBackups?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  overallResponsibilityBackup: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

/** policy info business continuity */
export type PolicyInfoBusinessContinuity = {
  __typename?: 'policyInfoBusinessContinuity';
  alternateDomainRegistrar?: Maybe<Scalars['String']>;
  alternateLocation?: Maybe<Scalars['String']>;
  alternateLocationRequired?: Maybe<Scalars['String']>;
  alternateMX?: Maybe<Scalars['String']>;
  alternatePayments?: Maybe<Scalars['String']>;
  alternateSSO?: Maybe<Scalars['String']>;
  anythingElseBusinessContinuity?: Maybe<Scalars['String']>;
  awsSupportContact?: Maybe<Scalars['String']>;
  azureSupportContact?: Maybe<Scalars['String']>;
  businessContinuityCadence?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  customerSupportTool?: Maybe<Scalars['String']>;
  customerBillingTool?: Maybe<Scalars['String']>;
  domainRegistrar?: Maybe<Scalars['String']>;
  gcpSupportContact?: Maybe<Scalars['String']>;
  herokuSupportContact?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  infrastructureAWS?: Maybe<Scalars['Boolean']>;
  infrastructureAzure?: Maybe<Scalars['Boolean']>;
  infrastructureGCP?: Maybe<Scalars['Boolean']>;
  infrastructureHeroku?: Maybe<Scalars['Boolean']>;
  overallResponsibilityBusinessContinuity?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

/** policy info change management */
export type PolicyInfoChangeManagement = {
  __typename?: 'policyInfoChangeManagement';
  anythingElseChangeManagement?: Maybe<Scalars['String']>;
  autoTestsOnMerge?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  hotfixBranchOrigin: Scalars['String'];
  hotfixBranchPermission?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  masterBranch: Scalars['String'];
  maybeCodeReviews?: Maybe<Scalars['Boolean']>;
  maybeCodeReviewsTime?: Maybe<Scalars['String']>;
  nextReleaseBranch: Scalars['String'];
  overallResponsibilityChangeManagement: Scalars['String'];
  prodBranch: Scalars['String'];
  pullRequestsUsed?: Maybe<Scalars['Boolean']>;
  stagingBranch: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

/** policy info code of conduct */
export type PolicyInfoCodeOfConduct = {
  __typename?: 'policyInfoCodeOfConduct';
  anythingElseCodeOfConduct?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  overallResponsibilityCodeOfConduct: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

/** policy info cryptography */
export type PolicyInfoCryptography = {
  __typename?: 'policyInfoCryptography';
  anythingElseCryptography?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  infrastructureAWS: Scalars['Boolean'];
  infrastructureGCP: Scalars['Boolean'];
  infrastructureHeroku: Scalars['Boolean'];
  maybeWifiEncryption?: Maybe<Scalars['Boolean']>;
  oldestAcceptableProtocolCryptography?: Maybe<Scalars['String']>;
  overallResponsibilityCryptography: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

/** policy info data classification */
export type PolicyInfoDataClassification = {
  __typename?: 'policyInfoDataClassification';
  anythingElseDataClassification?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  overallResponsibilityDataClassification: Scalars['String'];
  highestSensitivityDataClassification: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

/** policy info data delete */
export type PolicyInfoDataDelete = {
  __typename?: 'policyInfoDataDelete';
  anythingElseDataDelete?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  everDataDelete?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  maybeDataDelete?: Maybe<Scalars['Boolean']>;
  maybeStoreIndefinitelyReason?: Maybe<Scalars['String']>;
  overallResponsibilityDataDelete: Scalars['String'];
  slaDataDelete?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  whoDataDelete: Scalars['String'];
};

/** policy info data protection */
export type PolicyInfoDataProtection = {
  __typename?: 'policyInfoDataProtection';
  anythingElseDataProtection?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  overallResponsibilityDataProtection: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

/** policy info disaster recovery */
export type PolicyInfoDisasterRecovery = {
  __typename?: 'policyInfoDisasterRecovery';
  additionalCriticalSystems?: Maybe<Scalars['String']>;
  anythingElseDisasterRecovery?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  disasterRecoveryCadence: Scalars['String'];
  firstNotified?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  overallResponsibilityDisasterRecovery: Scalars['String'];
  notificationList?: Maybe<Scalars['String']>;
  retrospectiveRequired: Scalars['Boolean'];
  specificRecoveryProcedures?: Maybe<Scalars['String']>;
  timePeriodForCustomerNotification: Scalars['String'];
  timePeriodForDisaster: Scalars['String'];
  timePeriodForRetrospective: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

/** policy info incident response */
export type PolicyInfoIncidentResponse = {
  __typename?: 'policyInfoIncidentResponse';
  anythingElseIncidentResponse?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  incidentResponseAddressCritical: Scalars['String'];
  incidentResponseAddressHigh: Scalars['String'];
  incidentResponseAddressLow: Scalars['String'];
  incidentResponseEmail: Scalars['String'];
  incidentResponseExternal?: Maybe<Scalars['String']>;
  incidentResponseFollowUps?: Maybe<Scalars['Boolean']>;
  incidentResponseRetro?: Maybe<Scalars['Boolean']>;
  incidentResponseSlack?: Maybe<Scalars['String']>;
  incidentResponseTeam: Scalars['String'];
  overallResponsibilityIncidentResponse: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

/** policy info information security */
export type PolicyInfoInformationSecurity = {
  __typename?: 'policyInfoInformationSecurity';
  anythingElseInformationSecurity?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  maybeBackgroundCheck?: Maybe<Scalars['Boolean']>;
  maybeRemoteWork?: Maybe<Scalars['Boolean']>;
  maybeSecureCodingTraining?: Maybe<Scalars['Boolean']>;
  maybeSecurityTeamCEO?: Maybe<Scalars['Boolean']>;
  maybeSecurityTraining?: Maybe<Scalars['Boolean']>;
  maybeVPN?: Maybe<Scalars['Boolean']>;
  overallResponsibilityInformationSecurity: Scalars['String'];
  securityOfficerRole: Scalars['String'];
  securityTeamMembers: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

/** policy info password */
export type PolicyInfoPassword = {
  __typename?: 'policyInfoPassword';
  anythingElsePassword?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  maybeComplexPasswords?: Maybe<Scalars['Boolean']>;
  maybePassword2fa?: Maybe<Scalars['Boolean']>;
  maybePasswordManager?: Maybe<Scalars['Boolean']>;
  overallResponsibilityPassword: Scalars['String'];
  passwordManager?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

/** policy info physical security */
export type PolicyInfoPhysicalSecurity = {
  __typename?: 'policyInfoPhysicalSecurity';
  anythingElsePhysicalSecurity?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  employeeKeysResponsible: Scalars['String'];
  id: Scalars['ID'];
  locksOrBadges: Scalars['String'];
  maybeCleanDeskPolicy?: Maybe<Scalars['Boolean']>;
  maybeContractorBadges?: Maybe<Scalars['Boolean']>;
  maybeEmployeeBadges?: Maybe<Scalars['Boolean']>;
  maybeFireAlarms?: Maybe<Scalars['Boolean']>;
  maybeFireAlarmsBuilding?: Maybe<Scalars['Boolean']>;
  maybeKeysLost?: Maybe<Scalars['String']>;
  maybeLockOfficeDay?: Maybe<Scalars['Boolean']>;
  maybeLockOfficeNight?: Maybe<Scalars['Boolean']>;
  homeOfficeEmployees: Scalars['String'];
  maybeOutsourcedOfficeName?: Maybe<Scalars['String']>;
  outsourcedOfficeEmployees: Scalars['String'];
  maybeScreenProtectors?: Maybe<Scalars['Boolean']>;
  maybeSecurityAlarmsOffice?: Maybe<Scalars['Boolean']>;
  maybeSecurityCamerasBuilding?: Maybe<Scalars['Boolean']>;
  maybeSecurityCamerasOffice?: Maybe<Scalars['Boolean']>;
  maybeVisitorBadges?: Maybe<Scalars['Boolean']>;
  maybeVisitorSignInSystemName?: Maybe<Scalars['String']>;
  overallResponsibilityPhysicalSecurity: Scalars['String'];
  reviewBadgeCadence?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

/** policy info responsible disclosure */
export type PolicyInfoResponsibleDisclosure = {
  __typename?: 'policyInfoResponsibleDisclosure';
  anythingElseResponsibleDisclosure?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  disclosureEmail: Scalars['String'];
  disclosureRefrain?: Maybe<Scalars['String']>;
  disclosureSLA: Scalars['String'];
  disclosureSLAResolution: Scalars['String'];
  disclosureSupport: Scalars['String'];
  disclosureURL: Scalars['String'];
  id: Scalars['ID'];
  overallResponsibilityResponsibleDisclosure: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

/** policy info risk assessment */
export type PolicyInfoRiskAssessment = {
  __typename?: 'policyInfoRiskAssessment';
  anythingElseRiskAssessment?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  overallResponsibilityRiskAssessment: Scalars['String'];
  riskAssessmentCadence: Scalars['String'];
  riskAssessmentTeam: Scalars['String'];
  riskDomains: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

/** policy infos */
export type PolicyInfos = {
  __typename?: 'policyInfos';
  acceptableUse?: Maybe<PolicyInfoAcceptableUse>;
  assetManagement?: Maybe<PolicyInfoAssetManagement>;
  backup?: Maybe<PolicyInfoBackup>;
  businessContinuity?: Maybe<PolicyInfoBusinessContinuity>;
  changeManagement?: Maybe<PolicyInfoChangeManagement>;
  codeOfConduct?: Maybe<PolicyInfoCodeOfConduct>;
  cryptography?: Maybe<PolicyInfoCryptography>;
  dataClassification?: Maybe<PolicyInfoDataClassification>;
  dataDelete?: Maybe<PolicyInfoDataDelete>;
  dataProtection?: Maybe<PolicyInfoDataProtection>;
  disasterRecovery?: Maybe<PolicyInfoDisasterRecovery>;
  incidentResponse?: Maybe<PolicyInfoIncidentResponse>;
  informationSecurity?: Maybe<PolicyInfoInformationSecurity>;
  password?: Maybe<PolicyInfoPassword>;
  physicalSecurity?: Maybe<PolicyInfoPhysicalSecurity>;
  responsibleDisclosure?: Maybe<PolicyInfoResponsibleDisclosure>;
  riskAssessment?: Maybe<PolicyInfoRiskAssessment>;
  systemAccessControl?: Maybe<PolicyInfoSystemAccess>;
  vendorManagement?: Maybe<PolicyInfoVendorManagement>;
  vulnerabilityManagement?: Maybe<PolicyInfoVulnerabilityManagement>;
};

/** policy info system access */
export type PolicyInfoSystemAccess = {
  __typename?: 'policyInfoSystemAccess';
  accessRequest: Scalars['String'];
  accessTicketSystem?: Maybe<Scalars['String']>;
  anythingElseSystemAccess?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  logActions?: Maybe<Scalars['Boolean']>;
  logActionsTimeline?: Maybe<Scalars['String']>;
  overallResponsibilitySystemAccess?: Maybe<Scalars['String']>;
  policyAcceptance?: Maybe<Scalars['Boolean']>;
  resetWirelessPassword?: Maybe<Scalars['String']>;
  sshResponsibilitySystemAccess?: Maybe<Scalars['String']>;
  sslResponsibilitySystemAccess?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

/** policy info vendor management */
export type PolicyInfoVendorManagement = {
  __typename?: 'policyInfoVendorManagement';
  anythingElseVendorManagement?: Maybe<Scalars['String']>;
  complianceReports?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  maybeLegalReview?: Maybe<Scalars['Boolean']>;
  overallResponsibilityVendorManagement: Scalars['String'];
  paymentResponsible?: Maybe<Scalars['String']>;
  requireMFA?: Maybe<Scalars['Boolean']>;
  updatedAt: Scalars['DateTime'];
  vendorManagementCadence: Scalars['String'];
  vendorManagementOutput: Scalars['String'];
  vendorManagementReviewResponsible: Scalars['String'];
};

/** policy info vulnerability management */
export type PolicyInfoVulnerabilityManagement = {
  __typename?: 'policyInfoVulnerabilityManagement';
  anythingElseVulnerabilityManagement?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  maybeExternalVulnerabilityScanning?: Maybe<Scalars['Boolean']>;
  maybeExternalVulnerabilityScanningFrequency?: Maybe<Scalars['String']>;
  maybeExternalVulnerabilityScanningSurface?: Maybe<Scalars['String']>;
  maybeExternalVulnerabilityScanningTool?: Maybe<Scalars['String']>;
  overallResponsibilityVulnerabilityManagement: Scalars['String'];
  tacticalResponsibilityVulnerabilityManagement: Scalars['String'];
  ticketingSystem?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  vulnerabilitySources?: Maybe<Scalars['String']>;
};

/** customer report */
export type PresentedReport = {
  __typename?: 'presentedReport';
  active: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  domainDisplayName: Scalars['String'];
  domainId: Scalars['ID'];
  domainLogoSlug?: Maybe<Scalars['String']>;
  evidenceResults: Array<EvidenceResultForReport>;
  id: Scalars['ID'];
  latestTestResults: Array<TestResultForReport>;
  password: Scalars['String'];
  showSuccessOnly: Scalars['Boolean'];
  slugId: Scalars['String'];
  standard: ReportStandard;
  updatedAt: Scalars['DateTime'];
  userId: Scalars['ID'];
  viewerCompany: Scalars['String'];
  views?: Maybe<Array<DocumentView>>;
};

/** heroku platform api app */
export type HerokuApp = {
  __typename?: 'herokuApp';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type GcpCredentialValidationOutcome = {
  __typename?: 'gcpCredentialValidationOutcome';
  type: Scalars['String'];
  missingResourcesByProject: Array<GcpProjectMissingResources>;
};

export type GcpProjectMissingResources = {
  __typename?: 'gcpProjectMissingResources';
  projectId: Scalars['String'];
  ids: Array<Scalars['String']>;
};

export type DistributedQuery = {
  __typename?: 'distributedQuery';
  id: Scalars['ID'];
  queryName: Scalars['String'];
  node_key: Scalars['String'];
  domainId: Scalars['ID'];
  normalizedQuery: Scalars['String'];
  numAttempts: Scalars['Int'];
  result?: Maybe<Scalars['String']>;
  statusCode?: Maybe<Scalars['Int']>;
  succeeded?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  scheduler: User;
  scheduledBy: Scalars['String'];
  notifyOnResult: Scalars['Boolean'];
};

/** sample email */
export type SampleEmail = {
  __typename?: 'sampleEmail';
  identifier: Scalars['String'];
  subject: Scalars['String'];
  htmlBody: Scalars['String'];
  htmlComplete: Scalars['String'];
  text: Scalars['String'];
};

export type Email = {
  __typename?: 'email';
  emailKey: Scalars['String'];
  shouldBccVanta: Scalars['Boolean'];
  isAutomatic: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  unsubscribeInfo?: Maybe<UnsubscribeInfo>;
  circuitBreakerTime?: Maybe<Scalars['Int']>;
  sampleEmails: Array<SampleEmail>;
  timeBasedEmailTriggers: Array<TimeBasedEmailTriggers>;
};

export type UnsubscribeInfo = {
  __typename?: 'unsubscribeInfo';
  description: Scalars['String'];
  friendlyName: Scalars['String'];
  category: EmailCategoryForUnsubscribe;
  sensitive: Scalars['Boolean'];
};

/** security group */
export type SecurityGroup = {
  __typename?: 'securityGroup';
  inboundRules: Array<Maybe<SecurityGroupRule>>;
  name: Scalars['String'];
  outboundRules: Array<Maybe<SecurityGroupRule>>;
};

/** security group rule info */
export type SecurityGroupRule = {
  __typename?: 'securityGroupRule';
  fromPort?: Maybe<Scalars['Int']>;
  ipRanges: Array<Scalars['String']>;
  ipProtocol: Scalars['String'];
  toPort?: Maybe<Scalars['Int']>;
  securityGroupIds: Array<Scalars['String']>;
};

/** securityRequirements */
export type SecurityRequirements = {
  __typename?: 'securityRequirements';
  mustAcceptPolicies: Scalars['Boolean'];
  mustBeBackgroundChecked: Scalars['Boolean'];
  mustCompleteGdprSecurityTraining?: Maybe<Scalars['Boolean']>;
  mustCompleteHipaaSecurityTraining?: Maybe<Scalars['Boolean']>;
  mustCompletePciSecurityTraining?: Maybe<Scalars['Boolean']>;
  mustCompleteSecurityTraining: Scalars['Boolean'];
  mustInstallLaptopMonitoring: Scalars['Boolean'];
};

/** service account */
export type ServiceAccount = {
  __typename?: 'ServiceAccount';
  accountId: Scalars['String'];
  accountName: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deactivationDate?: Maybe<Scalars['DateTime']>;
  domainId: Scalars['ID'];
  externalCreatedAt?: Maybe<Scalars['DateTime']>;
  groupList?: Maybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  isNotHumanReason?: Maybe<Scalars['String']>;
  mfa?: Maybe<Scalars['Boolean']>;
  owner?: Maybe<User>;
  role?: Maybe<Scalars['String']>;
  service: Scalars['String'];
  teamList?: Maybe<Array<Scalars['String']>>;
  uniqueId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['ID']>;
  userLinkDate?: Maybe<Scalars['DateTime']>;
};

/** service with security groups */
export type ServiceWithSecurityGroups = {
  __typename?: 'serviceWithSecurityGroups';
  name: Scalars['String'];
  securityGroups: Array<Maybe<SecurityGroup>>;
};

export type SshAuthorizedKey = {
  __typename?: 'sshAuthorizedKey';
  algorithm: Scalars['String'];
  comment: Scalars['String'];
  error: Scalars['String'];
  key_file: Scalars['String'];
  key_type: Scalars['String'];
  sha256_fingerprint: Scalars['String'];
  machinesWithAccessToThisOne?: Maybe<EndpointConnection>;
};


export type SshAuthorizedKeyMachinesWithAccessToThisOneArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
};

export type SshUserKey = {
  __typename?: 'sshUserKey';
  comment: Scalars['String'];
  encrypted: Scalars['Boolean'];
  error: Scalars['String'];
  key_type: Scalars['String'];
  sha256_fingerprint: Scalars['String'];
  path: Scalars['String'];
  machinesThisCanAccess?: Maybe<EndpointConnection>;
};


export type SshUserKeyMachinesThisCanAccessArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
};

export type TestRolloutInfo = {
  __typename?: 'testRolloutInfo';
  rolloutStatus: TestRolloutStatus;
  rolloutStartDate?: Maybe<Scalars['DateTime']>;
  rolloutEndDate: Scalars['DateTime'];
};

/** testResult */
export type TestResult = {
  __typename?: 'testResult';
  category: TestCategory;
  controls: Array<Control>;
  description: Scalars['String'];
  disabledEntities: Array<DisabledTestEntity>;
  domainId: Scalars['ID'];
  errorEntities: Array<TestEntity>;
  failingEntities: Array<TestEntity>;
  failureDescription?: Maybe<Scalars['String']>;
  failMessage?: Maybe<Scalars['String']>;
  history: Array<TestResultHistory>;
  id: Scalars['ID'];
  isManuallyRefreshing: Scalars['Boolean'];
  isPolicyTest: Scalars['Boolean'];
  isSLATest: Scalars['Boolean'];
  isVantaAgentTest: Scalars['Boolean'];
  latestFlipTime?: Maybe<Scalars['DateTime']>;
  monitoringDisabledStatus: TestDisabledStatus;
  name: Scalars['String'];
  rolloutInfo: TestRolloutInfo;
  outcome: TestOutcome;
  policies?: Maybe<Array<Policy>>;
  rawData?: Maybe<Scalars['String']>;
  remediation: Scalars['String'];
  resourceFetches: Array<ResourceFetch>;
  services: Array<Scalars['String']>;
  severity: Scalars['String'];
  specificResourceDependencies: Array<Scalars['String']>;
  testUIRefreshable: Scalars['Boolean'];
  testId: Scalars['String'];
  timestamp: Scalars['DateTime'];
  version: TestVersionInfo;
  versionChangeDescription?: Maybe<TestVersionChangeDescription>;
  versionHistory: Array<TestVersionHistoryEntry>;
};


/** testResult */
export type TestResultHistoryArgs = {
  endTime?: Maybe<Scalars['Float']>;
  startTime?: Maybe<Scalars['Float']>;
};

export type TestEntity = {
  __typename?: 'TestEntity';
  entityId: Scalars['String'];
  entityType: Scalars['String'];
};

export type DisabledTestEntity = {
  __typename?: 'DisabledTestEntity';
  entityId: Scalars['String'];
  entityType: Scalars['String'];
  reason?: Maybe<Scalars['String']>;
};

export type TestDisabledStatus = {
  __typename?: 'TestDisabledStatus';
  id?: Maybe<Scalars['ID']>;
  evidenceDoc?: Maybe<UploadedDocument>;
  testWhitelistReason?: Maybe<Scalars['String']>;
  isDisabled: Scalars['Boolean'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  disabledBy?: Maybe<User>;
};

/**
 * A testResultForReport is a subset specifically for presentedReports. It
 * excludes information in the test result that aren't specifically displayed
 * in the report. Data behind report passwords are relatively exposed; we want
 * to expose the minimum.
 */
export type TestResultForReport = {
  __typename?: 'testResultForReport';
  controls: Array<Control>;
  id: Scalars['ID'];
  outcome: TestOutcome;
  testId: Scalars['String'];
};

/**
 * A evidenceResultForReport is a subset specifically for presentedReports. It
 * excludes information in the evidence request that aren't specifically displayed
 * in the report. Data behind report passwords are relatively exposed; we want
 * to expose the minimum.
 */
export type EvidenceResultForReport = {
  __typename?: 'evidenceResultForReport';
  evidenceRequestId: Scalars['String'];
  outcome: TestOutcome;
  title: Scalars['String'];
};

/** history for test results */
export type TestResultHistory = {
  __typename?: 'testResultHistory';
  flipTime: Scalars['String'];
  outcome: TestOutcome;
  invalidationReason?: Maybe<Scalars['String']>;
  testRun?: Maybe<TestResult>;
};

export type TestVersionInfo = {
  __typename?: 'testVersionInfo';
  major: Scalars['Int'];
  minor: Scalars['Int'];
};

export type TestVersionHistoryEntry = {
  __typename?: 'testVersionHistoryEntry';
  rolloutInfo: TestRolloutInfo;
  versionMetadata: TestMetadata;
};

export type TestVersionChangeDescription = {
  __typename?: 'testVersionChangeDescription';
  what: Scalars['String'];
  why: Scalars['String'];
};

export type TestAvailabilityInfo = {
  __typename?: 'testAvailabilityInfo';
  isAvailableToUsers: Scalars['Boolean'];
  latestAvailabilityDate?: Maybe<Scalars['DateTime']>;
};

/** testMetadata (or `Test`) */
export type TestMetadata = {
  __typename?: 'testMetadata';
  availability: TestAvailabilityInfo;
  defaultRemediation: Scalars['String'];
  failureDescription?: Maybe<Scalars['String']>;
  killSwitchId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  services: Array<Scalars['String']>;
  models: Array<Scalars['String']>;
  severity: Scalars['String'];
  testId: Scalars['String'];
  version: TestVersionInfo;
  versionChangeDescription?: Maybe<TestVersionChangeDescription>;
  detailedDescription: Scalars['String'];
};

export type Test = {
  __typename?: 'test';
  id: Scalars['String'];
  versions: Array<TestMetadata>;
};



/** uploaded document */
export type UploadedDocument = {
  __typename?: 'uploadedDocument';
  createdAt: Scalars['DateTime'];
  creator?: Maybe<User>;
  description?: Maybe<Scalars['String']>;
  domainDisplayName: Scalars['String'];
  domainLocked: Scalars['Boolean'];
  effectiveAt?: Maybe<Scalars['DateTime']>;
  filename?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mimetype: Scalars['String'];
  slugId: Scalars['String'];
  title: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
  userId?: Maybe<Scalars['ID']>;
};

export type SecurityTrainingCompletion = {
  completionDate: Scalars['DateTime'];
  category: SecurityTrainingCategoryId;
};

export type SecurityTrainingVideoCompletion = SecurityTrainingCompletion & {
  __typename?: 'securityTrainingVideoCompletion';
  completionDate: Scalars['DateTime'];
  category: SecurityTrainingCategoryId;
};

export type SecurityTrainingDocumentCompletion = SecurityTrainingCompletion & {
  __typename?: 'securityTrainingDocumentCompletion';
  completionDate: Scalars['DateTime'];
  category: SecurityTrainingCategoryId;
  uploadedDocument: UploadedDocument;
};

/** user */
export type User = {
  __typename?: 'user';
  allHrUsers: Array<EmbeddedHrUser>;
  auditableUsers: Array<User>;
  auditEngagements: Array<Audit>;
  auditorDocuments: Array<AuditorDocument>;
  auditorInfo?: Maybe<AuditorInfo>;
  backgroundChecks: Array<GenericBackgroundCheck>;
  chameleonHash?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  domain: Domain;
  email: Scalars['String'];
  endDate?: Maybe<Scalars['DateTime']>;
  endpointState: Scalars['String'];
  familyName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  gsuiteId?: Maybe<Scalars['String']>;
  gsuiteSSOServices?: Maybe<Array<Scalars['String']>>;
  hasAcceptedAllSecurityPolicies?: Maybe<Scalars['Boolean']>;
  hasAssumedWriteAccess?: Maybe<Scalars['Boolean']>;
  hasCompletedAnnualGdprTraining: Scalars['Boolean'];
  hasCompletedAnnualHipaaTraining: Scalars['Boolean'];
  hasCompletedAnnualPciTraining: Scalars['Boolean'];
  hasCompletedAnnualSecurityTraining: Scalars['Boolean'];
  hasCompletedBackgroundCheck?: Maybe<Scalars['Boolean']>;
  hasCompletedBGCheckTask: Scalars['Boolean'];
  hasCompletedSecurityTraining?: Maybe<Scalars['Boolean']>;
  hasOSQueryEndpoint: Scalars['Boolean'];
  hrUser?: Maybe<EmbeddedHrUser>;
  id: Scalars['ID'];
  idp?: Maybe<IdpInfo>;
  imageUrl?: Maybe<Scalars['String']>;
  inOnboardingSla: Scalars['Boolean'];
  isActive: Scalars['Boolean'];
  isAssumedSuperUser?: Maybe<Scalars['Boolean']>;
  isAssumedUser?: Maybe<Scalars['Boolean']>;
  isAuditorAssumingUser?: Maybe<Scalars['Boolean']>;
  isContractor: Scalars['Boolean'];
  isFromScan: Scalars['Boolean'];
  isNotHuman?: Maybe<Scalars['Boolean']>;
  isNotHumanReason?: Maybe<Scalars['String']>;
  isVantaTrusted: Scalars['Boolean'];
  lastTermsAccepted?: Maybe<Scalars['Int']>;
  lastTermsAcceptedDate?: Maybe<Scalars['DateTime']>;
  managedComputers: Array<GenericManagedComputerResource>;
  microsoftOfficeId?: Maybe<Scalars['String']>;
  mostRecentGdprSecurityTraining?: Maybe<SecurityTrainingCompletion>;
  mostRecentHipaaSecurityTraining?: Maybe<SecurityTrainingCompletion>;
  mostRecentPciSecurityTraining?: Maybe<SecurityTrainingCompletion>;
  mostRecentSecurityTraining?: Maybe<SecurityTrainingCompletion>;
  necessaryPolicies?: Maybe<Array<Scalars['String']>>;
  offboardedDate?: Maybe<Scalars['DateTime']>;
  offboardingAcknowledgedBy?: Maybe<User>;
  oktaId?: Maybe<Scalars['String']>;
  onboardingCompletionDate?: Maybe<Scalars['DateTime']>;
  permissionLevel: PermissionLevel;
  policyRequirements?: Maybe<Array<PolicyRequirement>>;
  requiresOffboarding: Scalars['Boolean'];
  role?: Maybe<Role>;
  roleCompletionRecord?: Maybe<RoleCompletionRecord>;
  /** A list of completions sorted in reverse chronological order by completion date. */
  securityTrainingCompletions: Array<SecurityTrainingCompletion>;
  seenRiskRegisterSplash?: Maybe<Scalars['Boolean']>;
  securityPolicyAcceptances: Array<PolicyAcceptance>;
  securityRequirements: SecurityRequirements;
  serviceAccounts: Array<ServiceAccount>;
  startDate: Scalars['DateTime'];
  trainingRequirements: Array<GenericTrainingRequirementResource>;
  vendorAccessRemovals: Array<VendorAccessRemoval>;
  workstations: Array<Osquery>;
};


/** user */
export type UserSecurityPolicyAcceptancesArgs = {
  onlyCurrent?: Maybe<Scalars['Boolean']>;
};


/** user */
export type UserServiceAccountsArgs = {
  includeDeleted?: Maybe<Scalars['Boolean']>;
};

export type HrUserBody = {
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['DateTime']>;
  familyName: Scalars['String'];
  givenName: Scalars['String'];
  isActive: Scalars['Boolean'];
  jobTitle?: Maybe<Scalars['String']>;
  linkedUser?: Maybe<User>;
  service: Scalars['String'];
  startDate: Scalars['DateTime'];
  uniqueId: Scalars['String'];
};

export type HrUser = HrUserBody & {
  __typename?: 'HrUser';
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['DateTime']>;
  familyName: Scalars['String'];
  givenName: Scalars['String'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  jobTitle?: Maybe<Scalars['String']>;
  linkedUser?: Maybe<User>;
  service: Scalars['String'];
  startDate: Scalars['DateTime'];
  uniqueId: Scalars['String'];
};

export type EmbeddedHrUser = HrUserBody & {
  __typename?: 'EmbeddedHrUser';
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['DateTime']>;
  familyName: Scalars['String'];
  givenName: Scalars['String'];
  isActive: Scalars['Boolean'];
  jobTitle?: Maybe<Scalars['String']>;
  linkedUser?: Maybe<User>;
  service: Scalars['String'];
  startDate: Scalars['DateTime'];
  uniqueId: Scalars['String'];
};

export type IdpInfo = {
  __typename?: 'IdpInfo';
  creationTime?: Maybe<Scalars['String']>;
  deactivatedAt?: Maybe<Scalars['DateTime']>;
  hasMFA?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  service: IdentityProviderService;
};

export type GenericBackgroundCheck = {
  __typename?: 'genericBackgroundCheck';
  id: Scalars['ID'];
  completedAt?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  linkedBackgroundCheckId?: Maybe<Scalars['String']>;
  service?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  uniqueId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
};

export type UiLinkableBackgroundCheck = {
  __typename?: 'uiLinkableBackgroundCheck';
  id: Scalars['String'];
  status: Scalars['String'];
  service: Scalars['String'];
  completedAt?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  ignored: Scalars['Boolean'];
};

export type UserOnMachine = {
  __typename?: 'userOnMachine';
  username: Scalars['String'];
  description: Scalars['String'];
};

/** vaqServices */
export type VaqServices = {
  __typename?: 'vaqServices';
  cloud?: Maybe<Scalars['Boolean']>;
  software?: Maybe<Scalars['Boolean']>;
  professional?: Maybe<Scalars['Boolean']>;
};

/** vaqSubmission */
export type VaqSubmission = {
  __typename?: 'vaqSubmission';
  id: Scalars['ID'];
  answers: Array<VaqSchema>;
  domain: Domain;
  domainId: Scalars['ID'];
  externalAssessmentType?: Maybe<Scalars['String']>;
  complianceUpload?: Maybe<UploadedDocument>;
  legalName: Scalars['String'];
  vendorId: Scalars['String'];
  files: Array<UploadedDocument>;
  submittedDate: Scalars['DateTime'];
  submittedAnswers: Scalars['String'];
  vendor: Vendor;
};

/** vaqSchema */
export type VaqSchema = {
  __typename?: 'vaqSchema';
  schemaId: Scalars['String'];
  contents: Scalars['String'];
};

/** vendor */
export type Vendor = {
  __typename?: 'vendor';
  authMethod?: Maybe<Scalars['String']>;
  assessmentComments?: Maybe<Scalars['String']>;
  assessmentDocumentId?: Maybe<Scalars['String']>;
  assessmentFile?: Maybe<UploadedDocument>;
  attestationOfComplianceDocumentId?: Maybe<Scalars['String']>;
  attestationOfComplianceFile?: Maybe<UploadedDocument>;
  associatedCredential?: Maybe<Scalars['String']>;
  baaDocumentId?: Maybe<Scalars['String']>;
  baaFile?: Maybe<UploadedDocument>;
  contactEmail?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  containsUserData?: Maybe<Scalars['Boolean']>;
  dpaFile?: Maybe<UploadedDocument>;
  dataStored?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  dpaDocumentId?: Maybe<Scalars['String']>;
  hasPasswordPoliciesSet: Scalars['Boolean'];
  name: Scalars['String'];
  id: Scalars['String'];
  isAssociatedWithCredentials: Scalars['Boolean'];
  otherInformation?: Maybe<Scalars['String']>;
  owner?: Maybe<User>;
  ownerId?: Maybe<Scalars['String']>;
  passwordMFA?: Maybe<Scalars['Boolean']>;
  passwordMinLength?: Maybe<Scalars['Int']>;
  passwordRequiresNumber?: Maybe<Scalars['Boolean']>;
  passwordRequiresSymbol?: Maybe<Scalars['Boolean']>;
  pendingVAQExpirationDate?: Maybe<Scalars['DateTime']>;
  servicesProvided?: Maybe<Scalars['String']>;
  severity: Scalars['String'];
  sharesEPHI?: Maybe<Scalars['Boolean']>;
  sharesEUPII?: Maybe<Scalars['Boolean']>;
  specifiedPasswordPolicy?: Maybe<PasswordPolicy>;
  submittedVAQs: Array<VaqSubmission>;
  url: Scalars['String'];
};

/** vendor access removal */
export type VendorAccessRemoval = {
  __typename?: 'vendorAccessRemoval';
  id: Scalars['ID'];
  vendorId: Scalars['String'];
  acknowledgedBy?: Maybe<User>;
  acknowledgeDate: Scalars['DateTime'];
};

/** only for subnet dashboard, aws route table route */
export type Route = {
  __typename?: 'route';
  destination: Scalars['String'];
  target: Scalars['String'];
};

/** only for subnet dashboard, describes an aws route table */
export type RouteTable = {
  __typename?: 'routeTable';
  id: Scalars['String'];
  routeList: Array<Route>;
  vpcId: Scalars['String'];
};

/** only for subnet dashboard, subnet info */
export type Subnet = {
  __typename?: 'subnet';
  AvailabilityZone: Scalars['String'];
  SubnetId: Scalars['String'];
  SubnetArn: Scalars['String'];
  VpcId: Scalars['String'];
  State: Scalars['String'];
  MapPublicIpOnLaunch: Scalars['Boolean'];
  RouteTable: RouteTable;
};

/** vpc info */
export type Vpc = {
  __typename?: 'vpc';
  autoscaleGroups?: Maybe<Array<Maybe<ServiceWithSecurityGroups>>>;
  instances?: Maybe<Array<Maybe<ServiceWithSecurityGroups>>>;
  name: Scalars['String'];
};

export type Cve = {
  __typename?: 'cve';
  cveId?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Float']>;
};

export type OsqueryVulnerability = {
  __typename?: 'osqueryVulnerability';
  id: Scalars['ID'];
  packageName: Scalars['String'];
  packageVersion: Scalars['String'];
  packageSource?: Maybe<Scalars['String']>;
  os: Scalars['String'];
  securityVersion: Scalars['String'];
  cves: Array<Cve>;
  maxSeverity?: Maybe<Scalars['Float']>;
  osquery?: Maybe<Osquery>;
  createdAt: Scalars['DateTime'];
  resolvedAt?: Maybe<Scalars['DateTime']>;
  isMonitoringDisabled: Scalars['Boolean'];
  severity: Scalars['Float'];
  slaDeadline?: Maybe<Scalars['DateTime']>;
  slaMillis?: Maybe<Scalars['Float']>;
  resolvedWithinSLA?: Maybe<Scalars['Boolean']>;
};

export type PackageVulnerability = {
  __typename?: 'packageVulnerability';
  id: Scalars['ID'];
  packageName: Scalars['String'];
  packageVersion: Scalars['String'];
  packageSource?: Maybe<Scalars['String']>;
  os: Scalars['String'];
  securityVersion: Scalars['String'];
  cves: Array<Cve>;
  maxSeverity: Scalars['Float'];
  machineInstances?: Maybe<Array<SpecificOsqueryVulnerabilityResource>>;
};


export type PackageVulnerabilityMachineInstancesArgs = {
  applyWhitelist?: Maybe<Scalars['Boolean']>;
};

export type AvPolicy = {
  __typename?: 'AvPolicy';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type WorkPaperTest = {
  __typename?: 'workPaperTest';
  domainId: Scalars['ID'];
  workPaperId: Scalars['String'];
  criterion: Scalars['String'];
  testId: Scalars['String'];
  controlName: Scalars['String'];
  controlDescription: Scalars['String'];
  testApplied: Scalars['String'];
  testResultsAuditor: Scalars['String'];
  commentsAuditor: Scalars['String'];
  testStatusVanta: Scalars['String'];
  commentsVanta: Scalars['String'];
};

export type VantaAttribute = {
  __typename?: 'vantaAttribute';
  key: Scalars['String'];
  managedExternally: Scalars['Boolean'];
  value: Scalars['String'];
};

export type OtherInventoryItem = {
  __typename?: 'otherInventoryItem';
  name?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  domainId: Scalars['ID'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

export type EvidenceRequest = {
  __typename?: 'evidenceRequest';
  category: EvidenceCategory;
  controls: Array<Control>;
  classification?: Maybe<EvidenceClassification>;
  description: Scalars['String'];
  documents: Array<UploadedDocument>;
  evidenceRequestId: Scalars['String'];
  ignoredStatus: EvidenceRequestIgnoredStatus;
  links: Array<LinkEvidence>;
  defaultRenewalCadence: Scalars['Duration'];
  renewalData: RenewalData;
  restricted?: Maybe<Scalars['Boolean']>;
  role?: Maybe<GdprRole>;
  timeSensitivity: EvidenceTimeSensitivity;
  title: Scalars['String'];
};

export type RenewalData = {
  __typename?: 'renewalData';
  cadence: Scalars['Duration'];
  nextDate?: Maybe<Scalars['DateTime']>;
  reminderWindow?: Maybe<Scalars['Duration']>;
};

export type LinkEvidence = {
  __typename?: 'linkEvidence';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  creator?: Maybe<User>;
  description?: Maybe<Scalars['String']>;
  effectiveAt?: Maybe<Scalars['DateTime']>;
  evidenceRequestId: Scalars['String'];
  title: Scalars['String'];
  url: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userId?: Maybe<Scalars['ID']>;
};

export type EvidenceRequestIgnoredStatus = {
  __typename?: 'evidenceRequestIgnoredStatus';
  isIgnored: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  reason?: Maybe<Scalars['String']>;
  creator?: Maybe<User>;
};

export type NotificationSettings = {
  __typename?: 'notificationSettings';
  employeeDigestFrequency?: Maybe<NotificationFrequency>;
};

export type PublicUploadedDocument = {
  __typename?: 'PublicUploadedDocument';
  id: Scalars['ID'];
  domainDisplayName: Scalars['String'];
  domainLocked: Scalars['Boolean'];
  slugId: Scalars['String'];
  title: Scalars['String'];
  filename?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  createdAt: Scalars['DateTime'];
};

export type PublicPolicy = {
  __typename?: 'PublicPolicy';
  id: Scalars['ID'];
  uploadedDoc: PublicUploadedDocument;
};

export type PublicVaqAnswers = {
  __typename?: 'PublicVaqAnswers';
  id: Scalars['ID'];
  answersJSON: Scalars['String'];
  domainDisplayName?: Maybe<Scalars['String']>;
  dryRun?: Maybe<Scalars['Boolean']>;
  expirationDate: Scalars['DateTime'];
  files: Array<PublicUploadedDocument>;
  servicesProvided?: Maybe<VaqServices>;
  infrastructureType?: Maybe<Scalars['String']>;
  requesterName?: Maybe<Scalars['String']>;
  submittedDate?: Maybe<Scalars['DateTime']>;
  uuid: Scalars['String'];
  url: Scalars['String'];
  vendorLegalName?: Maybe<Scalars['String']>;
};

/** Defines the fields available on a GithubTask resource. Autogenerated. */
export type SpecificGithubTaskResource = GenericTaskTrackerItemResource & Resource & {
  __typename?: 'SpecificGithubTaskResource';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayMetadata?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  domainId: Scalars['ID'];
  externalURL?: Maybe<Scalars['String']>;
  fetchError?: Maybe<Scalars['String']>;
  fetchId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  owner?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['String']>;
  service: Scalars['String'];
  tags: Array<Scalars['String']>;
  taskClosed: Scalars['Boolean'];
  taskClosedAt?: Maybe<Scalars['DateTime']>;
  taskCreatedAt: Scalars['DateTime'];
  title: Scalars['String'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
  vantaAttributes?: Maybe<Array<VantaAttribute>>;
};

export type SendFlippedTestEmailMutationVariables = Exact<{
  testRunId: Scalars['String'];
}>;


export type SendFlippedTestEmailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendHighPriEmail'>
);

export type SetCredentialMetadataMutationVariables = Exact<{
  domainId: Scalars['ID'];
  metadata: Scalars['String'];
  service: Scalars['String'];
}>;


export type SetCredentialMetadataMutation = (
  { __typename?: 'Mutation' }
  & { setCredentialMetadata?: Maybe<(
    { __typename?: 'domain' }
    & Pick<Domain, 'id'>
  )> }
);

export type PoliciesV2InfoSubQueryFragment = (
  { __typename?: 'domain' }
  & { policyInfos?: Maybe<(
    { __typename?: 'policyInfos' }
    & { acceptableUse?: Maybe<(
      { __typename?: 'policyInfoAcceptableUse' }
      & Pick<PolicyInfoAcceptableUse, 'id' | 'overallResponsibilityAcceptableUse'>
    )>, assetManagement?: Maybe<(
      { __typename?: 'policyInfoAssetManagement' }
      & Pick<PolicyInfoAssetManagement, 'id' | 'maybeAV' | 'maybePersonalFirewall' | 'maybeScreensaverLock' | 'overallResponsibilityAssetManagement'>
    )>, backup?: Maybe<(
      { __typename?: 'policyInfoBackup' }
      & Pick<PolicyInfoBackup, 'anythingElseBackup' | 'backupsTested' | 'endpointBackups' | 'id' | 'overallResponsibilityBackup'>
    )>, businessContinuity?: Maybe<(
      { __typename?: 'policyInfoBusinessContinuity' }
      & Pick<PolicyInfoBusinessContinuity, 'businessContinuityCadence' | 'id' | 'overallResponsibilityBusinessContinuity'>
    )>, changeManagement?: Maybe<(
      { __typename?: 'policyInfoChangeManagement' }
      & Pick<PolicyInfoChangeManagement, 'autoTestsOnMerge' | 'id' | 'maybeCodeReviews' | 'overallResponsibilityChangeManagement' | 'pullRequestsUsed'>
    )>, codeOfConduct?: Maybe<(
      { __typename?: 'policyInfoCodeOfConduct' }
      & Pick<PolicyInfoCodeOfConduct, 'id' | 'overallResponsibilityCodeOfConduct'>
    )>, cryptography?: Maybe<(
      { __typename?: 'policyInfoCryptography' }
      & Pick<PolicyInfoCryptography, 'id' | 'oldestAcceptableProtocolCryptography' | 'overallResponsibilityCryptography'>
    )>, dataClassification?: Maybe<(
      { __typename?: 'policyInfoDataClassification' }
      & Pick<PolicyInfoDataClassification, 'id' | 'overallResponsibilityDataClassification'>
    )>, dataDelete?: Maybe<(
      { __typename?: 'policyInfoDataDelete' }
      & Pick<PolicyInfoDataDelete, 'everDataDelete' | 'id' | 'maybeDataDelete' | 'overallResponsibilityDataDelete' | 'slaDataDelete'>
    )>, dataProtection?: Maybe<(
      { __typename?: 'policyInfoDataProtection' }
      & Pick<PolicyInfoDataProtection, 'id' | 'overallResponsibilityDataProtection'>
    )>, disasterRecovery?: Maybe<(
      { __typename?: 'policyInfoDisasterRecovery' }
      & Pick<PolicyInfoDisasterRecovery, 'disasterRecoveryCadence' | 'id' | 'overallResponsibilityDisasterRecovery'>
    )>, incidentResponse?: Maybe<(
      { __typename?: 'policyInfoIncidentResponse' }
      & Pick<PolicyInfoIncidentResponse, 'id' | 'incidentResponseFollowUps' | 'incidentResponseRetro' | 'incidentResponseTeam' | 'overallResponsibilityIncidentResponse'>
    )>, informationSecurity?: Maybe<(
      { __typename?: 'policyInfoInformationSecurity' }
      & Pick<PolicyInfoInformationSecurity, 'id' | 'maybeBackgroundCheck' | 'maybeSecurityTeamCEO' | 'maybeSecurityTraining' | 'maybeVPN' | 'overallResponsibilityInformationSecurity'>
    )>, password?: Maybe<(
      { __typename?: 'policyInfoPassword' }
      & Pick<PolicyInfoPassword, 'id' | 'maybeComplexPasswords' | 'maybePassword2fa' | 'maybePasswordManager' | 'overallResponsibilityPassword'>
    )>, physicalSecurity?: Maybe<(
      { __typename?: 'policyInfoPhysicalSecurity' }
      & Pick<PolicyInfoPhysicalSecurity, 'id' | 'maybeSecurityCamerasBuilding' | 'maybeSecurityCamerasOffice' | 'maybeSecurityAlarmsOffice' | 'maybeFireAlarms' | 'maybeCleanDeskPolicy' | 'overallResponsibilityPhysicalSecurity'>
    )>, responsibleDisclosure?: Maybe<(
      { __typename?: 'policyInfoResponsibleDisclosure' }
      & Pick<PolicyInfoResponsibleDisclosure, 'id' | 'overallResponsibilityResponsibleDisclosure'>
    )>, riskAssessment?: Maybe<(
      { __typename?: 'policyInfoRiskAssessment' }
      & Pick<PolicyInfoRiskAssessment, 'id' | 'overallResponsibilityRiskAssessment' | 'riskAssessmentCadence'>
    )>, systemAccessControl?: Maybe<(
      { __typename?: 'policyInfoSystemAccess' }
      & Pick<PolicyInfoSystemAccess, 'id' | 'overallResponsibilitySystemAccess'>
    )>, vendorManagement?: Maybe<(
      { __typename?: 'policyInfoVendorManagement' }
      & Pick<PolicyInfoVendorManagement, 'complianceReports' | 'id' | 'overallResponsibilityVendorManagement' | 'requireMFA' | 'vendorManagementCadence' | 'vendorManagementReviewResponsible'>
    )>, vulnerabilityManagement?: Maybe<(
      { __typename?: 'policyInfoVulnerabilityManagement' }
      & Pick<PolicyInfoVulnerabilityManagement, 'id' | 'overallResponsibilityVulnerabilityManagement'>
    )> }
  )> }
);

export type UnlinkCredentialsMutationVariables = Exact<{
  domainId: Scalars['ID'];
  service: Scalars['String'];
  cause: Scalars['String'];
  externalAccountId?: Maybe<Scalars['String']>;
}>;


export type UnlinkCredentialsMutation = (
  { __typename?: 'Mutation' }
  & { unlinkCredentials: (
    { __typename?: 'domain' }
    & Pick<Domain, 'id'>
  ) }
);

export const SCHEMA_VERSION = "c4a7c9";