import { Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import type { Maybe } from "common/base/types/maybe";
import { dropNothing, isSome } from "common/base/types/maybe";
import {
  PERSONNEL_PAGE_QUERY_PARAMS,
  StatusFilterId,
} from "common/constants/hr";
import { parse } from "json2csv";
import moment from "moment";
import React, { useContext, useMemo, useState } from "react";
import { useHistory } from "react-router";

import { Menu, MenuButton, MenuItem } from "../../../alpaca/components";
import { LogError } from "../../../errors";
import type {
  FetchDataForPeoplePageQueryHookResult,
  PeoplePageFieldsFragment,
} from "../../../gen/components";
import {
  FetchDataForPeoplePageDocument,
  useSendReminderEmailMutation,
  useSetNonHumanMutation,
} from "../../../gen/components";
import { FILE_TIMESTAMP_FORMAT, UI_DATE_FORMAT } from "../../../helpers/common";
import { Ellipsify } from "../../../helpers/ellipsify";
import { AppToaster } from "../../../helpers/toaster";
import {
  compareNullableDates,
  compareNullableStrings,
  compareStringForSortableUser,
} from "../../../helpers/user-sort-functions";
import { FullPageSpinner } from "../../helpers/FullPageSpinner";
import { Card } from "../components/card";
import { CSVExportButton } from "../components/csv-export-button";
import type { DataTableFilterFn } from "../components/data-table";
import { COLUMN_CLASSES, DataTable } from "../components/data-table";
import { DropdownButton } from "../components/dropdown-button";
import { UserContext } from "../user-context";
import { AuditWindowDropdown } from "./audit-window-dropdown";
import { EditUserGroupDialog } from "./edit-user-group-dialog";
import { GroupsContext } from "./groups-context";
import { HrServicesContext } from "./hr-services-context";
import { NotHumanDialog } from "./not-human-dialog";
import { useIsExternalSatLinkedCheck } from "./sat-services-context";
import { DATE_FORMAT, PEOPLE_PAGE_DEFAULT_PAGE_SIZE } from "./shared/common";
import { InfoIcon } from "./shared/info-icon";
import { canEditGroup, canMarkNotHuman } from "./shared/menu";
import { NotApplicable } from "./shared/not-applicable";
import { useTrainingCategoryAccess } from "./shared/use-hipaa-sat-enabled";
import { getOnboardingEmailReminderMenuItemsForUser } from "./user-drawer/email-alert-button";
import {
  asDateOnlyDate,
  jsxForStatus,
  sourceForService,
  textForStatus,
  UserStatus,
  withDateOnlyHrDates,
  withGroup,
  withStatus,
  withTableDisplayName,
} from "./utils";

interface IProps {
  query: FetchDataForPeoplePageQueryHookResult;
}

type TableRowData = PeoplePageFieldsFragment & {
  group: string;
  status: UserStatus;
  tableDisplayName: string;
  dateOnlyStartDate: Date;
  dateOnlyEndDate: Maybe<Date>;
};

interface Filter {
  /**
   * The filter function which determines what rows are visible
   */
  fn: DataTableFilterFn<TableRowData>;
  /**
   * Unique identifier for this table filter
   */
  id: StatusFilterId;
  name: string;
}

const STATUS_FILTERS: Filter[] = [
  {
    fn: user =>
      user.__typename === "user" && user.isActive && !Boolean(user.isNotHuman),
    id: StatusFilterId.CURRENTLY_EMPLOYEED,
    name: "Currently employed",
  },
  {
    fn: user =>
      [UserStatus.ONBOARDING_OVERDUE, UserStatus.CURRENTLY_ONBOARDING].includes(
        user.status
      ),
    id: StatusFilterId.INCOMPLETE_ONBOARDING,
    name: "Incomplete onboarding",
  },
  {
    fn: user => user.status === UserStatus.OFFBOARDING_INCOMPLETE,
    id: StatusFilterId.NEEDS_DEPROVISIONING,
    name: "Needs offboarding",
  },
  {
    fn: user =>
      user.__typename === "user" && !user.isActive && !Boolean(user.isNotHuman),
    id: StatusFilterId.PREVIOUSLY_UNEMPLOYED,
    name: "Previously employed",
  },
  {
    fn: user => user.__typename === "user" && Boolean(user.isNotHuman),
    id: StatusFilterId.NOT_PEOPLE,
    name: "Not people",
  },
];

const jobTitleForUser = (user: TableRowData) => user.hrUser?.jobTitle;

const COLUMN_SORT_FUNCTIONS: {
  [k: string]: (u1: TableRowData, u2: TableRowData) => number;
} = {
  name: (u1, u2) =>
    compareStringForSortableUser(u1).localeCompare(
      compareStringForSortableUser(u2)
    ),
  group: (u1, u2) => u1.group.localeCompare(u2.group),
  jobTitle: (u1, u2) =>
    compareNullableStrings(jobTitleForUser(u1), jobTitleForUser(u2)),
  status: (u1, u2) =>
    textForStatus[u1.status].localeCompare(textForStatus[u2.status]),
  startDate: (u1, u2) =>
    moment(u1.dateOnlyStartDate).diff(moment(u2.dateOnlyStartDate)),
  endDate: (u1, u2) =>
    !isSome(u1.dateOnlyEndDate)
      ? 1
      : !isSome(u2.dateOnlyEndDate)
      ? -1
      : moment(u1.dateOnlyEndDate).diff(moment(u2.dateOnlyEndDate)),
};

export const AllUsersView: React.FC<IProps> = ({ query }) => {
  const history = useHistory();
  const { user: sendingUser } = useContext(UserContext);
  const { groups } = useContext(GroupsContext);
  const { linkedHrServices } = useContext(HrServicesContext);
  const isExternalSatLinked = useIsExternalSatLinkedCheck();
  const access = useTrainingCategoryAccess();

  const { loading, data } = query;
  const [searchString, setSearchString] = useState("");
  const [sortParams, setSortParams] = useState<{
    column: string;
    reverse: boolean;
  }>({ column: "name", reverse: false });

  const { selectedStatusFilter, selectedAuditId, selectedGroupId } =
    useMemo(() => {
      const searchParams = new URLSearchParams(location.search);
      const selectedStatusFilterParam = searchParams.get(
        PERSONNEL_PAGE_QUERY_PARAMS.STATUS_FILTER
      );
      const selectedGroupIdParam = searchParams.get(
        PERSONNEL_PAGE_QUERY_PARAMS.GROUP_FILTER
      );

      return {
        selectedStatusFilter: STATUS_FILTERS.find(
          f => f.id === selectedStatusFilterParam
        ),
        selectedGroupId: groups.find(g => g.id === selectedGroupIdParam)?.id,
        selectedAuditId: searchParams.get(
          PERSONNEL_PAGE_QUERY_PARAMS.AUDIT_FILTER
        ),
      };
    }, [window.location.search]);

  // State for user menu actions that pop up dialogs
  const [userToEditGroupFor, setUserToEditGroupFor] =
    useState<Maybe<PeoplePageFieldsFragment>>(null);
  const [userToMarkAsNonhuman, setUserToMarkAsNonhuman] =
    useState<Maybe<PeoplePageFieldsFragment>>(null);

  const [toggleNonHuman] = useSetNonHumanMutation({
    refetchQueries: [{ query: FetchDataForPeoplePageDocument }],
  });
  const [sendReminder] = useSendReminderEmailMutation({
    onCompleted() {
      AppToaster.show({
        message: "Reminder email sent",
        intent: Intent.SUCCESS,
      });
    },
  });

  const actionMenuDialogs = useMemo(
    () =>
      dropNothing([
        isSome(userToEditGroupFor) ? (
          <EditUserGroupDialog
            isOpen={true}
            user={userToEditGroupFor}
            onClose={() => setUserToEditGroupFor(null)}
          />
        ) : null,
        isSome(userToMarkAsNonhuman) ? (
          <NotHumanDialog
            isOpen={true}
            user={userToMarkAsNonhuman}
            onClose={() => setUserToMarkAsNonhuman(null)}
          />
        ) : null,
      ]),
    [userToEditGroupFor, userToMarkAsNonhuman]
  );

  const sortedUsers = useMemo(
    () =>
      data?.organization.users
        .map(u =>
          withGroup(
            withStatus(withTableDisplayName(withDateOnlyHrDates(u)), access)
          )
        )
        .sort((u1, u2) =>
          compareStringForSortableUser(u2).localeCompare(
            compareStringForSortableUser(u1)
          )
        ),
    [data?.organization.users]
  );

  const filteredTableData = useMemo(() => {
    if (!isSome(sortedUsers)) {
      return null;
    }
    const allTableData: TableRowData[] = sortedUsers;
    return filterAndSortTableData(allTableData);
  }, [
    sortedUsers,
    sortParams,
    selectedStatusFilter,
    selectedGroupId,
    selectedAuditId,
    searchString,
  ]);

  if (loading || !isSome(filteredTableData)) {
    return <FullPageSpinner />;
  }

  const filterElements = [
    <DropdownButton
      key={"filter-dropdown"}
      options={STATUS_FILTERS}
      selectedOption={selectedStatusFilter}
      onOptionSelect={filter => {
        const filterId = filter?.id;
        const params = new URLSearchParams(window.location.search);
        if (isSome(filterId)) {
          params.set(PERSONNEL_PAGE_QUERY_PARAMS.STATUS_FILTER, filterId);
        } else {
          params.delete(PERSONNEL_PAGE_QUERY_PARAMS.STATUS_FILTER);
        }
        history.push({ search: params.toString() });
      }}
      optionRenderer={filter => filter.name}
      isFilter={true}
      buttonWidth={224}
      menuWidth={224}
      defaultText={"Filter by status"}
      styleOnSelect
    />,
    <DropdownButton
      key={"groups-dropdown"}
      options={groups}
      selectedOption={groups.find(g => g.id === selectedGroupId)}
      onOptionSelect={group => {
        const filterId = group?.id;
        const params = new URLSearchParams(window.location.search);
        if (isSome(filterId)) {
          params.set(PERSONNEL_PAGE_QUERY_PARAMS.GROUP_FILTER, filterId);
        } else {
          params.delete(PERSONNEL_PAGE_QUERY_PARAMS.GROUP_FILTER);
        }
        history.push({ search: params.toString() });
      }}
      optionRenderer={group => group.name}
      isFilter={true}
      defaultText={"Filter by group"}
      buttonWidth={224}
      menuWidth={224}
      styleOnSelect
    />,
    <AuditWindowDropdown
      key={"audits-dropdown"}
      audits={data?.organization.audits ?? []}
      onAuditSelect={audit => {
        const filterId = audit?.id;
        const params = new URLSearchParams(window.location.search);
        if (isSome(filterId)) {
          params.set(PERSONNEL_PAGE_QUERY_PARAMS.AUDIT_FILTER, filterId);
        } else {
          params.delete(PERSONNEL_PAGE_QUERY_PARAMS.AUDIT_FILTER);
        }
        history.push({ search: params.toString() });
      }}
      selectedAuditId={selectedAuditId}
    />,
  ];

  return (
    <Card>
      <DataTable
        useDefaultStyling
        stickyHeaders
        paginate={{
          paginationId: "people-page",
          defaultPageSize: PEOPLE_PAGE_DEFAULT_PAGE_SIZE,
        }}
        customControls={{
          leftControls: filterElements,
          rightControls: [
            <CSVExportButton
              disabled={filteredTableData.length === 0}
              key="people-csv"
              data={
                filteredTableData.length > 0
                  ? parse(filteredTableData.map(userToCSVRow))
                  : ""
              }
              filename={`People-${moment().format(FILE_TIMESTAMP_FORMAT)}.csv`}
            />,
          ],
        }}
        controlledSearchParams={{
          searchString,
          onNewSearchString: setSearchString,
        }}
        columnOrder={[
          "name",
          "group",
          "jobTitle",
          "status",
          "startDate",
          "endDate",
          "actions",
        ]}
        columnWidths={[
          "250px",
          "210px",
          "240px",
          "250px",
          "150px",
          "150px",
          "100px",
        ]}
        columnVisibilities={{ jobTitle: linkedHrServices.size > 0 }}
        header={{
          name: "Name",
          group: "Group",
          jobTitle: (
            <span>
              Job title
              <span style={{ verticalAlign: "middle", marginLeft: "4px" }}>
                <InfoIcon tooltipContent="Job titles are from your HR system" />
              </span>
            </span>
          ),
          status: "Status",
          startDate: "Start date",
          endDate: "End date",
          actions: "Actions",
        }}
        columnClasses={{
          startDate: COLUMN_CLASSES.CENTER_ALIGN,
          endDate: COLUMN_CLASSES.CENTER_ALIGN,
          actions: COLUMN_CLASSES.CENTER_ALIGN,
        }}
        data={filteredTableData}
        createRow={user => {
          const jobTitle = jobTitleForUser(user);
          return {
            name: <Ellipsify text={user.tableDisplayName} />,
            group: <Ellipsify text={user.group} />,
            jobTitle: isSome(jobTitle) ? (
              <Ellipsify text={jobTitle} />
            ) : (
              <NotApplicable />
            ),
            status: jsxForStatus(user.status),
            startDate: moment(user.dateOnlyStartDate).format(DATE_FORMAT),
            endDate: isSome(user.dateOnlyEndDate) ? (
              moment(user.dateOnlyEndDate).format(DATE_FORMAT)
            ) : (
              <NotApplicable />
            ),
            actions: getActionsButtonForUser(user),
          };
        }}
        sortableColumns={Object.keys(COLUMN_SORT_FUNCTIONS)}
        isReverseSort={sortParams.reverse}
        onHeaderSort={(column, reverse) => {
          setSortParams({ column, reverse });
        }}
        onRowClick={user => {
          const params = new URLSearchParams(window.location.search);
          params.set(PERSONNEL_PAGE_QUERY_PARAMS.USER_FILTER, user.id);
          history.push({ search: params.toString() });
        }}
        emptyDefault={"No people found"}
      />
      {actionMenuDialogs}
    </Card>
  );

  function filterAndSortTableData(tableData: TableRowData[]) {
    const lowerCaseSearch = searchString.toLocaleLowerCase();
    const selectedAudit = (data?.organization.audits ?? []).find(
      a => a.id === selectedAuditId
    );
    const auditStart = isSome(selectedAudit)
      ? asDateOnlyDate(new Date(+selectedAudit.auditStart), false)
      : null;
    const auditEnd = isSome(selectedAudit)
      ? moment(auditStart!).add(selectedAudit.auditPeriodDays, "days")
      : null;
    const filteredData = tableData.filter(
      u =>
        (selectedStatusFilter?.fn(u) ?? true) &&
        (!isSome(selectedGroupId) || u.role?.id === selectedGroupId) &&
        userMatchesSelectedAuditWindow(u) &&
        userMatchesSearchString(u)
    );
    if (isSome(sortParams) && sortParams.column in COLUMN_SORT_FUNCTIONS) {
      filteredData.sort(
        (u1, u2) =>
          (sortParams.reverse ? -1 : 1) *
          COLUMN_SORT_FUNCTIONS[sortParams.column](u1, u2)
      );
    }
    return filteredData;

    function userMatchesSelectedAuditWindow(user: TableRowData) {
      if (!isSome(selectedAudit)) {
        return true;
      }

      // A user matches the audit window if their start or end date falls in the window
      return (
        moment(user.dateOnlyStartDate).isBetween(
          auditStart!,
          auditEnd!,
          undefined,
          "[]"
        ) ||
        (isSome(user.dateOnlyEndDate) &&
          moment(user.dateOnlyEndDate).isBetween(
            auditStart!,
            auditEnd!,
            undefined,
            "[]"
          ))
      );
    }

    function userMatchesSearchString(user: TableRowData) {
      return (
        user.tableDisplayName.toLocaleLowerCase().includes(lowerCaseSearch) ||
        textForStatus[user.status]
          .toLocaleLowerCase()
          .includes(lowerCaseSearch) ||
        user.group.toLocaleLowerCase().includes(lowerCaseSearch) ||
        (jobTitleForUser(user) ?? "")
          .toLocaleLowerCase()
          .includes(lowerCaseSearch)
      );
    }
  }

  /**
   * Helper functions for rendering the actions button for a user
   */
  function getOnboardingReminderMenuItemsForUser(user: TableRowData) {
    if (user.__typename === "user") {
      if (
        ![
          UserStatus.CURRENTLY_ONBOARDING,
          UserStatus.ONBOARDING_OVERDUE,
        ].includes(user.status)
      ) {
        return [];
      }
      const sendEmail = (flag: string) => {
        sendReminder({
          variables: {
            userId: user.id,
            flag,
            domainId: sendingUser!.domain.id,
          },
        }).catch(LogError);
      };
      return getOnboardingEmailReminderMenuItemsForUser(
        user,
        isExternalSatLinked,
        sendEmail
      );
    }
    return [];
  }

  function getMenuItemsForUser(user: TableRowData) {
    if (user.isNotHuman) {
      return [
        <MenuItem
          key="mark-as-person"
          text={"Mark as a person"}
          icon={IconNames.PERSON}
          onClick={() => {
            toggleNonHuman({
              variables: {
                userId: user.id,
                isNotHumanReason: "",
                isNotHuman: false,
              },
            }).catch(LogError);
          }}
        />,
      ];
    }

    if (user.__typename === "user") {
      const items: JSX.Element[] = [];
      const reminderItems = getOnboardingReminderMenuItemsForUser(user);
      if (reminderItems.length > 0) {
        items.push(
          <MenuItem
            key="send-reminder"
            text={"Send a reminder"}
            icon={IconNames.ENVELOPE}
          >
            {reminderItems}
          </MenuItem>
        );
      }
      if (canEditGroup(user, groups)) {
        items.push(
          <MenuItem
            key="edit-group"
            text={"Edit group"}
            icon={IconNames.EDIT}
            onClick={() => setUserToEditGroupFor(user)}
          />
        );
      }
      if (canMarkNotHuman(user)) {
        items.push(
          <MenuItem
            key="not-a-person"
            text={"Not a person"}
            icon={IconNames.BLOCKED_PERSON}
            onClick={() => setUserToMarkAsNonhuman(user)}
          />
        );
      }
      return items;
    }

    return [];
  }

  function getActionsButtonForUser(user: TableRowData) {
    const menuItems = getMenuItemsForUser(user);
    // Outer div prevents the user drawer from opening on click.
    return menuItems.length > 0 ? (
      <div
        onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) =>
          e.stopPropagation()
        }
      >
        <MenuButton menu={<Menu>{menuItems}</Menu>} small />
      </div>
    ) : null;
  }

  function userToCSVRow(user: TableRowData) {
    const isExEmployee = !user.isActive && !Boolean(user.isNotHuman);
    const result: { [k: string]: Maybe<string> } = {
      "ID": user.id,
      "Name": user.displayName,
      "Email": user.email,
      "Ex-Employee": BooleanToYesNo(isExEmployee),
      "Group": user.group,
      ...(linkedHrServices.size > 0
        ? { "Job Title": user.hrUser?.jobTitle ?? null }
        : {}),
      "Status": textForStatus[user.status],
      "Start Date": moment(user.startDate).format(DATE_FORMAT),
      "End Date": isSome(user.endDate)
        ? moment(user.endDate).format(DATE_FORMAT)
        : null,
    };
    if (!isExEmployee) {
      if (user.securityRequirements.mustBeBackgroundChecked) {
        result["Onboarding - Completed Background Check"] = BooleanToYesNo(
          user.hasCompletedBackgroundCheck
        );
        const services = dropNothing(
          user.backgroundChecks
            .slice()
            .sort((a, b) => compareNullableDates(a.completedAt, b.completedAt))
            .map(check => check.service)
        );
        const sources = dropNothing(services.map(sourceForService));
        if (sources.length > 0) {
          result["Onboarding - Background Check Source"] =
            sources[sources.length - 1];
        }
      }
      if (user.securityRequirements.mustInstallLaptopMonitoring) {
        result["Onboarding - Vanta Agent Installed"] = BooleanToYesNo(
          user.hasOSQueryEndpoint
        );
      }
      if (user.securityRequirements.mustAcceptPolicies) {
        result["Onboarding - Accepted Policies"] = BooleanToYesNo(
          user.hasAcceptedAllSecurityPolicies
        );
      }

      if (
        user.securityRequirements.mustCompleteSecurityTraining &&
        isSome(user.mostRecentSecurityTraining)
      ) {
        result["Onboarding - General Security Training Complete"] =
          BooleanToYesNo(true);
        result["Onboarding - General Security Training Completed on"] = moment(
          user.mostRecentSecurityTraining.completionDate
        ).format(UI_DATE_FORMAT);
      }
      if (
        user.securityRequirements.mustCompleteHipaaSecurityTraining &&
        isSome(user.mostRecentHipaaSecurityTraining)
      ) {
        result["Onboarding - HIPAA Security Training Complete"] =
          BooleanToYesNo(true);
        result["Onboarding - HIPAA Security Training Completed on"] = moment(
          user.mostRecentHipaaSecurityTraining.completionDate
        ).format(UI_DATE_FORMAT);
      }
      if (
        user.securityRequirements.mustCompletePciSecurityTraining &&
        isSome(user.mostRecentPciSecurityTraining)
      ) {
        result["Onboarding - PCI DSS Security Training Complete"] =
          BooleanToYesNo(true);
        result["Onboarding - PCI DSS Security Training Completed on"] = moment(
          user.mostRecentPciSecurityTraining.completionDate
        ).format(UI_DATE_FORMAT);
      }
      if (
        user.securityRequirements.mustCompleteGdprSecurityTraining &&
        isSome(user.mostRecentGdprSecurityTraining)
      ) {
        result["Onboarding - GDPR Security Training Complete"] =
          BooleanToYesNo(true);
        result["Onboarding - GDPR Security Training Completed on"] = moment(
          user.mostRecentGdprSecurityTraining.completionDate
        ).format(UI_DATE_FORMAT);
      }
    }
    return result;

    function BooleanToYesNo(bool: Maybe<boolean>) {
      return Boolean(bool) ? "Yes" : "No";
    }
  }
};
