import { Button, Classes, Dialog, Intent, Spinner } from "@blueprintjs/core";
import { PermissionLevel } from "common/base/types/gen";
import type { Maybe } from "common/base/types/maybe";
import { getTransformedOrElse, isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import React, { useState } from "react";
import styled from "styled-components";

import { GRID_SPACING } from "../../../../alpaca/base/grid";
import { BodyShortText, IconNames } from "../../../../alpaca/components";
import { LogError, LogErrorMessage } from "../../../../errors";
import {
  GetUserBackgroundCheckListDocument,
  GetUserInfoForOnboardingListDocument,
  useAssignBackgroundCheckMutation,
  useGetReportLinkLazyQuery,
  useGetUserBackgroundCheckListQuery,
  useUserAccessQuery,
} from "../../../../gen/components";
import { AppToaster } from "../../../../helpers/toaster";
import { DataTable } from "../../components/data-table";
import { IconButton } from "../shared/icon-button";
import { sourceForService } from "../utils";

export const BackgroundCheckDetails: React.FC<{
  userId: string;
  userName: string;
  renderButton?: Maybe<(onClick: () => void) => JSX.Element>;
}> = ({ userId, userName, renderButton }) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const { error, loading, data: viewerUserData } = useUserAccessQuery();
  if (error) {
    LogError(error);
    return null;
  }
  if (loading) {
    return <Spinner />;
  }
  if (!viewerUserData || !viewerUserData.user) {
    LogErrorMessage("Bad fetch");
    return null;
  }

  const onClick = () => setDialogOpen(true);
  const button = getTransformedOrElse(
    renderButton,
    render => render(onClick),
    <IconButton
      onClick={onClick}
      icon={IconNames.VIEW}
      tooltipContent={"View"}
    />
  );

  return (
    <>
      {button}
      <Dialog
        title={`Background check details for ${userName}`}
        isOpen={dialogOpen}
        onClose={() => setDialogOpen(false)}
      >
        {viewerUserData.user.permissionLevel === PermissionLevel.Admin ? (
          <BackgroundCheckDetailsAdminView
            userId={userId}
            viewerIsAuditor={
              isSome(viewerUserData.user.isAuditorAssumingUser) &&
              viewerUserData.user.isAuditorAssumingUser
            }
          />
        ) : (
          <div className={`${Classes.RUNNING_TEXT} app-container-page`}>
            You do not have access to background check details; you are not an
            admin.
          </div>
        )}
      </Dialog>
    </>
  );
};

const Container = styled.div`
  margin: ${1.5 * GRID_SPACING}px;
  table {
    width: 100%;
  }
`;

const BackgroundCheckDetailsAdminView: React.FC<{
  userId: string;
  viewerIsAuditor: boolean;
}> = ({ userId, viewerIsAuditor }) => {
  const {
    error,
    loading,
    data: backgroundCheckData,
  } = useGetUserBackgroundCheckListQuery({
    variables: { userId },
  });

  const [assignBackgroundCheck] = useAssignBackgroundCheckMutation({
    refetchQueries: [
      {
        query: GetUserBackgroundCheckListDocument,
        variables: { userId },
      },
      {
        query: GetUserInfoForOnboardingListDocument,
        variables: { userId },
      },
    ],
  });

  if (error) {
    LogError(error);
    return null;
  }
  if (loading) {
    return <Spinner />;
  }
  if (!backgroundCheckData || !backgroundCheckData.user) {
    LogError(new Error("Bad fetch"));
    return null;
  }

  return (
    <Container>
      <DataTable
        header={{
          source: "Source",
          status: "Status",
          view: "",
          unlink: "",
        }}
        columnOrder={["source", "status", "unlink"]}
        data={backgroundCheckData.user.backgroundChecks}
        createRow={bgCheck => {
          const { linkedBackgroundCheckId, service, status, completedAt } =
            bgCheck;
          return {
            source: (
              <BodyShortText>{sourceForService(service ?? "")}</BodyShortText>
            ),
            status: (
              <BodyShortText>
                {status === "COMPLETED"
                  ? `Completed on ${completedAt}`
                  : "In progress"}
              </BodyShortText>
            ),
            view:
              status === "COMPLETED" &&
              service === "checkr" &&
              !viewerIsAuditor ? (
                <CheckrDetail userId={userId} />
              ) : null,
            unlink: isSome(linkedBackgroundCheckId) ? (
              <Button
                intent="danger"
                onClick={async () => {
                  await assignBackgroundCheck({
                    variables: {
                      backgroundCheckId: linkedBackgroundCheckId,
                      userId: null, // null userId unlinks a background check
                    },
                  });

                  const toastMessage = "Background check unlinked";
                  AppToaster.show({
                    icon: "tick",
                    intent: Intent.SUCCESS,
                    message: toastMessage,
                    timeout: 2500,
                  });
                }}
              >
                Unlink
              </Button>
            ) : null,
          };
        }}
      />
    </Container>
  );
};

gql`
  query getUserBackgroundCheckList($userId: ID!) {
    user(id: $userId) {
      id
      backgroundChecks {
        id
        linkedBackgroundCheckId
        status
        service
        completedAt
        uniqueId
      }
    }
  }
`;

const CheckrDetail: React.FC<{ userId: string }> = ({ userId }) => {
  const [getLink, { data, loading, error }] = useGetReportLinkLazyQuery({
    variables: { userId },
    fetchPolicy: "no-cache",
  });

  React.useEffect(() => {
    if (isSome(data)) {
      window.open(data.checkrReportFile, "_blank");
    }
  }, [data]);

  React.useEffect(() => {
    if (isSome(error)) {
      LogError(error, false);
      AppToaster.show({
        icon: "error",
        intent: Intent.DANGER,
        message:
          "Could not get report. User may have requested PII access removal.",
        timeout: 2500,
      });
    }
  }, [error]);

  const onClickView = () => {
    getLink();
  };

  return (
    <Button
      onClick={() => onClickView()}
      rightIcon="document-open"
      loading={loading}
    >
      View report PDF
    </Button>
  );
};

gql`
  query getReportLink($userId: ID!) {
    checkrReportFile(userId: $userId)
  }
`;

gql`
  query userAccess {
    user {
      id
      permissionLevel
      isAuditorAssumingUser
    }
  }
`;
