import { Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import React, { useContext } from "react";
import styled, { css } from "styled-components";

import { VANTA_COLORS } from "../../../alpaca/base/colors";
import type { UserContextQuery } from "../../../gen/components";
import { UserContext } from "../../pages/user-context";
import { Dimensions } from "../constants";
import type { IMenuOption } from "../links";
import { LinkList } from "./link-list";

interface IProps {
  menuOption: IMenuOption;
}

const HOME_PATH = (user: UserContextQuery["user"]) => {
  if (user?.isAuditorAssumingUser) {
    return "Testing";
  }
  if (isSome(user?.auditorInfo)) {
    return "Dashboard";
  }
  return "Monitoring";
};

export const NavSubMenu: React.FC<IProps> = ({ menuOption }) => {
  const { user } = useContext(UserContext);
  const isHome =
    window.location.pathname === "/" &&
    menuOption.link.text === HOME_PATH(user);
  return (
    <LI
      isActive={menuOption.subMenu?.some(
        item => isHome || window.location.pathname.startsWith(item.path)
      )}
    >
      <MenuHeading>
        <span>{menuOption.link.text}</span>
        <Icon icon={IconNames.CHEVRON_DOWN} />
      </MenuHeading>
      <SubmenuContainer>
        <LinkList links={menuOption.subMenu ?? []} />
      </SubmenuContainer>
    </LI>
  );
};

const LI = styled.li<{ isActive?: Maybe<boolean> }>`
  position: relative;
  & > div:last-child {
    visibility: hidden;
  }
  & > *:first-child {
    border-bottom: 2px solid transparent;
  }
  color: ${VANTA_COLORS.TEXT_MUTED};
  ${({ isActive = false }) => {
    if (isActive) {
      return css`
        color: ${VANTA_COLORS.TEXT_NORMAL};
        & > *:first-child {
          border-bottom: 2px solid ${VANTA_COLORS.VANTA_PURPLE};
        }
      `;
    } else {
      return "";
    }
  }}
  &:hover {
    color: ${VANTA_COLORS.TEXT_NORMAL};
    & > *:first-child {
      border-bottom: 2px solid ${VANTA_COLORS.TEXT_NORMAL};
    }
    & > div:last-child {
      visibility: visible;
    }
  }
`;

const MenuHeading = styled.div`
  padding-top: ${Dimensions.NAVBAR_MENU_HEADING_TOP_PADDING}px;
  margin: 0 ${Dimensions.NAVBAR_MENU_MARGIN}px;
  height: ${Dimensions.TOP_BAR_HEIGHT}px;
  display: flex;
  align-items: center;
  &:hover {
    cursor: default;
  }
`;

const SubmenuContainer = styled.div`
  min-width: ${Dimensions.SUBMENU_MIN_WIDTH}px;
  position: absolute;
  left: ${Dimensions.NAVBAR_MENU_MARGIN - Dimensions.NAVBAR_SUBMENU_INDENT}px;
  top: ${Dimensions.TOP_BAR_HEIGHT}px;
  background-color: ${VANTA_COLORS.BACKGROUND_WHITE};
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.08),
    inset 0 1px 0px rgba(0, 0, 0, 0.12);
`;
