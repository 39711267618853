import "./collapsible-section.scss";

import { Button, Classes, Collapse } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import React from "react";

interface IProps {
  content: Maybe<JSX.Element | string>;
  headline: Maybe<JSX.Element | string>;
  startsOpen: boolean;
}

interface IState {
  isOpen: boolean;
}

export class CollapsibleSection extends React.Component<IProps, IState> {
  public static defaultProps: IProps = {
    content: null,
    headline: null,
    startsOpen: true,
  };

  public constructor(props: IProps) {
    super(props);
    this.state = { isOpen: props.startsOpen };
    this.handleClick = this.handleClick.bind(this);
  }

  public render() {
    const chevron = this.state.isOpen ? "chevron-down" : "chevron-right";
    return (
      <section>
        <h4 className={"cursor-pointer"} onClick={this.handleClick}>
          <Button className={Classes.MINIMAL} icon={chevron} />
          {this.props.headline}
        </h4>
        <Collapse isOpen={this.state.isOpen}>{this.props.content}</Collapse>
      </section>
    );
  }

  private handleClick() {
    this.setState({ isOpen: !this.state.isOpen });
  }
}
