import styled from "styled-components";

import { VANTA_COLORS } from "../../../alpaca/base/colors";

export const Button = styled.button`
  background-color: ${VANTA_COLORS.BACKGROUND_WHITE};
  border-radius: 4px;
  border: 1px solid ${VANTA_COLORS.CHROME_BUTTON_BORDER};
  color: ${VANTA_COLORS.TEXT_NORMAL};
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  padding: 8px 23px;
  flex-shrink: 0;
  margin-left: 8px;
  float: right;

  &:hover {
    cursor: pointer;
    background-color: ${VANTA_COLORS.CHROME_BUTTON_HOVER};
  }
  &:active {
    background-color: ${VANTA_COLORS.CHROME_BUTTON_ACTIVE};
  }
`;
