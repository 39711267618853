import { Feature, ReportStandard } from "../base/types/gen";
import type { Maybe } from "../base/types/maybe";

// List of fields that all policies have for overall responsibility
export const baseResponsibilityChoices = [
  "CEO",
  "CTO",
  "COO",
  "VP of Engineering",
  "IT team",
  "Operations team",
  "Security officer",
  "Security team",
] as const;

// List of all fields that at least one policy has for overall responsibility
// Used for schema typing
export const responsibilityTypes = [
  ...baseResponsibilityChoices,
  "General Counsel", // from Data Classification policy
  "Other",
] as const;

export const responsibilityTypesWithEmployees = [
  ...responsibilityTypes,
  "Employees",
] as const;

/**
 * Policy types that are defined within Vanta must not be
 * prefixed with `custom-`.
 * Custom policies all use the `custom-` namespace. Otherwise, custom
 * policies may not be able to be created successfully if their types overlap.
 */
const HIPAAPolicyTypes = [
  "hipaa-compliance-policy-bsi",
  "incident-response-plan-hipaa-addendum-with-breach-notification-procedures-bsi",
  "hipaa-workstation-security-policy-bsi",
] as const;

// 12/16/2020 @chrismychen We are actively moving customers away from this set of policies
export const VantaDeprecatedPolicyTypes = [
  "acceptable-use-policy",
  "asset-management-policy",
  "backup-policy",
  "business-continuity-plan",
  "change-management-policy",
  "code-of-conduct-policy",
  "cryptography-policy",
  "data-classification-policy",
  "data-delete-policy",
  "data-protection-policy",
  "disaster-recovery-plan",
  "incident-response-plan",
  "information-security-policy",
  "physical-security-policy",
  "responsible-disclosure-policy",
  "risk-assessment-program",
  "system-access-control-policy",
  "password-policy",
  "vendor-management-policy",
  "vulnerability-management-policy",
] as const;

export type VantaDeprecatedPolicyType =
  typeof VantaDeprecatedPolicyTypes[number];

export const VantaDeprecatedPolicyTypesSet = new Set(
  VantaDeprecatedPolicyTypes
);

export const BSIPolicyTypes = [
  "access-control-policy-bsi",
  "asset-management-policy-bsi",
  "business-continuity-and-disaster-recovery-plan-bsi",
  "code-of-conduct-bsi",
  "cryptography-policy-bsi",
  "data-management-policy-bsi",
  "human-resource-security-policy-bsi",
  "incident-response-plan-bsi",
  "information-security-policy-bsi",
  "information-security-roles-and-responsibilities-bsi",
  "operations-security-policy-bsi",
  "physical-security-policy-bsi",
  "risk-management-policy-bsi",
  "secure-development-policy-bsi",
  "third-party-management-policy-bsi",
] as const;

export const ISOPolicyTypes = [
  "00-isms-master-list-of-documents",
  "01-isms-scope-of-the-isms",
  "02-isms-information-security-management-system-isms-policy",
  "03-isms-roles-responsibilities-and-authorities",
  "04-isms-risk-assessment-and-risk-treatment-process",
  "05-isms-procedure-for-the-control-of-documented-information",
  "06-isms-information-security-communication-plan",
  "07-isms-procedure-for-internal-audits",
  "08-isms-procedure-for-management-review",
  "09-isms-procedure-for-corrective-action-and-continual-improvement",
  "10-isms-information-security-objectives-plan",
  "11-isms-statement-of-applicability",
  "12-isms-relevant-laws-regulations-and-contractual-requirements",
] as const;

export const PCIPolicyTypes = [
  "pci-dss-saq-a",
  "pci-dss-saq-a-ep",
  "pci-dss-saq-d",
] as const;

export const BSIPolicyTypesSet = new Set(BSIPolicyTypes);

export type HIPAAPolicyType = typeof HIPAAPolicyTypes[number];
export type BSIPolicyType = typeof BSIPolicyTypes[number];
export type ISOPolicyType = typeof ISOPolicyTypes[number];
export type PCIPolicyType = typeof PCIPolicyTypes[number];

/**
 * Policy types that are defined internally within Vanta must not be
 * prefixed with `custom-`.
 * Custom policies all use the `custom-` namespace. Otherwise, custom
 * policies may not be able to be created successfully if their types overlap.
 */
export const PolicyTypes = [
  ...BSIPolicyTypes,
  ...HIPAAPolicyTypes,
  ...ISOPolicyTypes,
  ...PCIPolicyTypes,
  ...VantaDeprecatedPolicyTypes,
] as const;

export type PolicyType = typeof PolicyTypes[number];

export const PolicyTypesIncludingHiddenTypes = [
  ...PolicyTypes,
  "policy-packet",
] as const;

export interface IPolicyDocStub {
  policyType: PolicyType;
  // Defines which standards have requirements that are satisfied by the
  // policy. The listed standards will determine how the policy is labeled,
  // filtered, and presented to users.
  standards: ReportStandard[];
  description: string;
  title: string;
  features?: Maybe<Feature[]>;
}

export const VantaDeprecatedPolicyDocStubsMap: {
  [k in VantaDeprecatedPolicyType]: IPolicyDocStub;
} = {
  "acceptable-use-policy": {
    policyType: "acceptable-use-policy",
    standards: [
      ReportStandard.hipaa,
      ReportStandard.iso27001,
      ReportStandard.soc2,
      ReportStandard.gdpr,
    ],
    description:
      "Details the rules applied by %COMPANY that restrict the ways in which its network, systems, and \
    devices should be used.",
    title: "Acceptable Use Policy",
  },
  "asset-management-policy": {
    policyType: "asset-management-policy",
    standards: [
      ReportStandard.hipaa,
      ReportStandard.iso27001,
      ReportStandard.soc2,
      ReportStandard.gdpr,
    ],
    description:
      "Details how %COMPANY accounts for information technology assets (e.g. employee laptops) and outlines \
    what should be done if assets are lost, destroyed, or otherwise damaged.",
    title: "Asset Management Policy",
  },
  "backup-policy": {
    policyType: "backup-policy",
    standards: [
      ReportStandard.hipaa,
      ReportStandard.iso27001,
      ReportStandard.soc2,
      ReportStandard.gdpr,
    ],
    description: "Describes how often service and user data is backed up.",
    title: "Backup Policy",
  },

  "business-continuity-plan": {
    policyType: "business-continuity-plan",
    standards: [
      ReportStandard.hipaa,
      ReportStandard.iso27001,
      ReportStandard.soc2,
      ReportStandard.gdpr,
    ],
    description:
      "Details contingency plans for certain business-impacting outages and vendor \
    disruption of service.",
    title: "Business Continuity Plan",
  },
  "change-management-policy": {
    policyType: "change-management-policy",
    standards: [
      ReportStandard.hipaa,
      ReportStandard.iso27001,
      ReportStandard.soc2,
      ReportStandard.gdpr,
    ],
    description:
      "Describes how changes to the %COMPANY system are proposed, reviewed, deployed, and managed. This \
    policy covers all changes made to %COMPANY software, regardless of their size, scope, or potential impact.",
    title: "Change Management Policy",
  },
  "code-of-conduct-policy": {
    policyType: "code-of-conduct-policy",
    standards: [
      ReportStandard.iso27001,
      ReportStandard.soc2,
      ReportStandard.gdpr,
    ],
    description:
      "Develops and maintains a standard of conduct that is acceptable to the company and its employees, \
    customers, and vendors.",
    title: "Code of Conduct",
  },
  "cryptography-policy": {
    policyType: "cryptography-policy",
    standards: [
      ReportStandard.hipaa,
      ReportStandard.iso27001,
      ReportStandard.soc2,
      ReportStandard.gdpr,
    ],
    description:
      "Provides guidance on the encryption algorithms that may be used by the company to protect user \
    and company data.",
    title: "Cryptography Policy",
  },
  "data-classification-policy": {
    policyType: "data-classification-policy",
    standards: [
      ReportStandard.hipaa,
      ReportStandard.iso27001,
      ReportStandard.soc2,
      ReportStandard.gdpr,
    ],
    description:
      "Describes how company data is classified, and the levels of protection \
    required for each classification",
    title: "Data Classification Policy",
  },

  "data-delete-policy": {
    policyType: "data-delete-policy",
    standards: [
      ReportStandard.hipaa,
      ReportStandard.iso27001,
      ReportStandard.soc2,
      ReportStandard.gdpr,
    ],
    description:
      "Describes how user data is deleted in connection with the cancellation or termination of a \
    %COMPANY account.",
    title: "Data Deletion Policy",
  },
  "data-protection-policy": {
    policyType: "data-protection-policy",
    standards: [
      ReportStandard.hipaa,
      ReportStandard.iso27001,
      ReportStandard.soc2,
      ReportStandard.gdpr,
    ],
    description:
      "Describes the technical and organizational safeguards employed by %COMPANY to protect user \
    data.",
    title: "Data Protection Policy",
  },
  "disaster-recovery-plan": {
    policyType: "disaster-recovery-plan",
    standards: [
      ReportStandard.hipaa,
      ReportStandard.iso27001,
      ReportStandard.soc2,
      ReportStandard.gdpr,
    ],
    description:
      "Establishes procedures to recover the company's operations following a disruption resulting from a disaster",
    title: "Disaster Recovery Plan",
  },

  "incident-response-plan": {
    policyType: "incident-response-plan",
    standards: [
      ReportStandard.hipaa,
      ReportStandard.iso27001,
      ReportStandard.soc2,
      ReportStandard.gdpr,
    ],
    description:
      "Offers guidance to %COMPANY employees or incident responders who believe they have discovered or \
    are responding to a security incident.",
    title: "Incident Response Plan",
  },

  "information-security-policy": {
    policyType: "information-security-policy",
    standards: [
      ReportStandard.hipaa,
      ReportStandard.iso27001,
      ReportStandard.soc2,
      ReportStandard.gdpr,
    ],
    description:
      "Details the information security policy for the company, including naming the security team, and \
    common people operations practices.",
    title: "Information Security Policy",
  },

  "password-policy": {
    policyType: "password-policy",
    standards: [
      ReportStandard.hipaa,
      ReportStandard.iso27001,
      ReportStandard.soc2,
      ReportStandard.gdpr,
    ],
    description:
      "Describes how employees should generate, store, and retrieve their passwords for cloud services \
    they use on behalf of the company or personally.",
    title: "Password Policy",
  },
  "physical-security-policy": {
    policyType: "physical-security-policy",
    standards: [
      ReportStandard.hipaa,
      ReportStandard.iso27001,
      ReportStandard.soc2,
      ReportStandard.gdpr,
    ],
    description:
      "Describes how the %COMPANY office is secured and details how and when employees should permit \
    access to the office.",
    title: "Physical Security Policy",
  },

  "responsible-disclosure-policy": {
    policyType: "responsible-disclosure-policy",
    standards: [
      ReportStandard.hipaa,
      ReportStandard.iso27001,
      ReportStandard.soc2,
      ReportStandard.gdpr,
    ],
    description:
      "Specifies how external users are expected to disclose potential security vulnerabilities in \
    %COMPANY’s service.",
    title: "Responsible Disclosure Policy",
  },
  "risk-assessment-program": {
    policyType: "risk-assessment-program",
    standards: [
      ReportStandard.hipaa,
      ReportStandard.iso27001,
      ReportStandard.soc2,
      ReportStandard.gdpr,
    ],
    description:
      "Describes what principles, policies, and procedures %COMPANY has in place to identify new \
      business and technical risks and how those risks are mitigated.",
    title: "Risk Assessment Program",
  },
  "system-access-control-policy": {
    policyType: "system-access-control-policy",
    standards: [
      ReportStandard.hipaa,
      ReportStandard.iso27001,
      ReportStandard.soc2,
      ReportStandard.gdpr,
    ],
    description:
      "Describes the conditions under which %COMPANY employees are granted access to company systems, \
    with special attention to those systems that store user data.",
    title: "System Access Control Policy",
  },

  "vendor-management-policy": {
    policyType: "vendor-management-policy",
    standards: [
      ReportStandard.hipaa,
      ReportStandard.iso27001,
      ReportStandard.soc2,
      ReportStandard.gdpr,
    ],
    description:
      "Provides a framework for managing the lifecycle of vendor relationships that minimizes the risk \
    associated with engaging third parties to perform services.",
    title: "Vendor Management Policy",
  },
  "vulnerability-management-policy": {
    policyType: "vulnerability-management-policy",
    standards: [
      ReportStandard.hipaa,
      ReportStandard.iso27001,
      ReportStandard.soc2,
      ReportStandard.gdpr,
    ],
    description:
      "Outlines a series of steps that are taken on a regular cadence to keep code dependencies up to date \
    and identify vulnerabilities in code.",
    title: "Vulnerability Management Policy",
  },
};

export const HIPAAPolicyDocStubsMap: {
  [k in HIPAAPolicyType]: IPolicyDocStub;
} = {
  "hipaa-compliance-policy-bsi": {
    policyType: "hipaa-compliance-policy-bsi",
    standards: [ReportStandard.hipaa],
    description:
      "This HIPAA policy outlines %COMPANY's approach to compliance with HIPAA standards for personnel who have access to ePHI.",
    title: "HIPAA Compliance Policy",
    features: [Feature.BetaHIPAA],
  },
  "incident-response-plan-hipaa-addendum-with-breach-notification-procedures-bsi":
    {
      policyType:
        "incident-response-plan-hipaa-addendum-with-breach-notification-procedures-bsi",
      standards: [ReportStandard.hipaa],
      description:
        "This HIPAA policy addendum details %COMPANY's procedures when a potential breach of ePHI has been identified.",
      title:
        "Incident Response Plan HIPAA Addendum with Breach Notification Procedures",
      features: [Feature.BetaHIPAA],
    },
  "hipaa-workstation-security-policy-bsi": {
    policyType: "hipaa-workstation-security-policy-bsi",
    standards: [ReportStandard.hipaa],
    description:
      "This HIPAA policy addendum details %COMPANY's procedures and protocols for the use and security of end-user workstations and their connection to the network.",
    title: "HIPAA Workstation Security Policy",
    features: [Feature.BetaHIPAA],
  },
};

// BSI policies are a mutually exclusive set of policies from
// the VantaDeprecated policies AND the HIPAA policies
// BSI policies should never be displayed w/ hipaa or vanta deprecated
// policies

export const BSIPolicyDocStubsMap: { [k in BSIPolicyType]: IPolicyDocStub } = {
  "access-control-policy-bsi": {
    policyType: "access-control-policy-bsi",
    standards: [
      ReportStandard.hipaa,
      ReportStandard.iso27001,
      ReportStandard.soc2,
      ReportStandard.gdpr,
    ],
    description:
      "To limit access to information and information processing systems, networks, and facilities to authorized parties in accordance with business objectives.",
    title: "Access Control Policy",
    features: [Feature.BetaBSIPolicies],
  },
  "asset-management-policy-bsi": {
    policyType: "asset-management-policy-bsi",
    standards: [
      ReportStandard.hipaa,
      ReportStandard.iso27001,
      ReportStandard.soc2,
      ReportStandard.gdpr,
    ],
    description:
      "To identify organizational assets and define appropriate protection responsibilities. To ensure that information receives an appropriate level of protection in accordance with its importance to the organization. To prevent unauthorized disclosure, modification, removal, or destruction of information stored on media.",
    title: "Asset Management Policy",
    features: [Feature.BetaBSIPolicies],
  },
  "business-continuity-and-disaster-recovery-plan-bsi": {
    policyType: "business-continuity-and-disaster-recovery-plan-bsi",
    standards: [
      ReportStandard.hipaa,
      ReportStandard.iso27001,
      ReportStandard.soc2,
      ReportStandard.gdpr,
    ],
    description:
      "The purpose of this business continuity plan is to prepare %COMPANY in the event of extended service outages caused by factors beyond our control (e.g., natural disasters, man-made events), and to restore services to the widest extent possible in a minimum time frame. ",
    title: "Business Continuity and Disaster Recovery Plan",
    features: [Feature.BetaBSIPolicies],
  },
  "code-of-conduct-bsi": {
    policyType: "code-of-conduct-bsi",
    standards: [ReportStandard.soc2, ReportStandard.gdpr],
    description: "placeholder",
    title: "Code of Conduct",
    features: [Feature.BetaBSIPolicies],
  },
  "cryptography-policy-bsi": {
    policyType: "cryptography-policy-bsi",
    standards: [
      ReportStandard.hipaa,
      ReportStandard.iso27001,
      ReportStandard.soc2,
      ReportStandard.gdpr,
    ],
    description:
      "To ensure proper and effective use of cryptography to protect the confidentiality, authenticity and/or integrity of information. This policy establishes requirements for the use and protection of cryptographic keys throughout their entire lifecycle.",
    title: "Cryptography Policy",
    features: [Feature.BetaBSIPolicies],
  },
  "data-management-policy-bsi": {
    policyType: "data-management-policy-bsi",
    standards: [
      ReportStandard.hipaa,
      ReportStandard.iso27001,
      ReportStandard.soc2,
      ReportStandard.gdpr,
    ],
    description:
      "To ensure that information is classified, protected, retained and securely disposed of in accordance with its importance to the organization.",
    title: "Data Management Policy",
    features: [Feature.BetaBSIPolicies],
  },
  "human-resource-security-policy-bsi": {
    policyType: "human-resource-security-policy-bsi",
    standards: [
      ReportStandard.hipaa,
      ReportStandard.iso27001,
      ReportStandard.soc2,
      ReportStandard.gdpr,
    ],
    description:
      "To ensure that employees and contractors meet security requirements, understand their responsibilities, and are suitable for their roles.",
    title: "Human Resource Security Policy",
    features: [Feature.BetaBSIPolicies],
  },
  "incident-response-plan-bsi": {
    policyType: "incident-response-plan-bsi",
    standards: [
      ReportStandard.hipaa,
      ReportStandard.iso27001,
      ReportStandard.soc2,
      ReportStandard.gdpr,
    ],
    description:
      "This document establishes the plan for managing information security incidents and events, and offers guidance for employees or incident responders who believe they have discovered, or are responding to, a security incident.",
    title: "Incident Response Plan",
    features: [Feature.BetaBSIPolicies],
  },
  "information-security-policy-bsi": {
    policyType: "information-security-policy-bsi",
    standards: [
      ReportStandard.hipaa,
      ReportStandard.iso27001,
      ReportStandard.soc2,
      ReportStandard.gdpr,
    ],
    description:
      "The purpose of this policy is to communicate our information security policies and outline the acceptable use and protection of %COMPANY’s information and assets.",
    title: "Information Security Policy (AUP)",
    features: [Feature.BetaBSIPolicies],
  },
  "information-security-roles-and-responsibilities-bsi": {
    policyType: "information-security-roles-and-responsibilities-bsi",
    standards: [
      ReportStandard.hipaa,
      ReportStandard.iso27001,
      ReportStandard.soc2,
      ReportStandard.gdpr,
    ],
    description:
      "This policy and associated guidance establish the roles and responsibilities within %COMPANY, which is critical for effective communication of information security policies and standards.",
    title: "Information Security Roles and Responsibilities",
    features: [Feature.BetaBSIPolicies],
  },
  "operations-security-policy-bsi": {
    policyType: "operations-security-policy-bsi",
    standards: [
      ReportStandard.hipaa,
      ReportStandard.iso27001,
      ReportStandard.soc2,
      ReportStandard.gdpr,
    ],
    description:
      "To ensure the correct and secure operation of information processing systems and facilities.",
    title: "Operations Security Policy",
    features: [Feature.BetaBSIPolicies],
  },
  "physical-security-policy-bsi": {
    policyType: "physical-security-policy-bsi",
    standards: [
      ReportStandard.hipaa,
      ReportStandard.iso27001,
      ReportStandard.soc2,
      ReportStandard.gdpr,
    ],
    description:
      "To prevent unauthorized physical access or damage to the organization’s information and information processing facilities.",
    title: "Physical Security Policy",
    features: [Feature.BetaBSIPolicies],
  },
  "risk-management-policy-bsi": {
    policyType: "risk-management-policy-bsi",
    standards: [
      ReportStandard.hipaa,
      ReportStandard.iso27001,
      ReportStandard.soc2,
      ReportStandard.gdpr,
    ],
    description:
      "To define the methodology for assessing and managing %COMPANY’s information security risks in order to achieve the company’s business and information security objectives.",
    title: "Risk Management Policy",
    features: [Feature.BetaBSIPolicies],
  },
  "secure-development-policy-bsi": {
    policyType: "secure-development-policy-bsi",
    standards: [
      ReportStandard.hipaa,
      ReportStandard.iso27001,
      ReportStandard.soc2,
      ReportStandard.gdpr,
    ],
    description:
      "To ensure that information security is designed and implemented within the development lifecycle for applications and information systems.",
    title: "Secure Development Policy",
    features: [Feature.BetaBSIPolicies],
  },
  "third-party-management-policy-bsi": {
    policyType: "third-party-management-policy-bsi",
    standards: [
      ReportStandard.hipaa,
      ReportStandard.iso27001,
      ReportStandard.soc2,
      ReportStandard.gdpr,
    ],
    description:
      "To ensure protection of the organization's data and assets that are shared with, accessible to, or managed by suppliers, including external parties or third-party organizations such as service providers, vendors, and customers, and to maintain an agreed level of information security and service delivery in line with supplier agreements.",
    title: "Third-Party Management Policy",
    features: [Feature.BetaBSIPolicies],
  },
};

export const PCIPolicyDocStubsMap: { [k in PCIPolicyType]: IPolicyDocStub } = {
  "pci-dss-saq-a": {
    policyType: "pci-dss-saq-a",
    standards: [ReportStandard.pciSaqA],
    description:
      "The purpose of this policy is to establish processes, procedures, and standards that must be followed for the protection of Cardholder data (CHD) and systems in accordance with the Payment Card Industry Data Security Standard (PCI DSS).",
    title: "PCI DSS Policy - SAQ A",
    features: [Feature.PCISaqAStandard],
  },
  "pci-dss-saq-a-ep": {
    policyType: "pci-dss-saq-a-ep",
    standards: [ReportStandard.pciSaqAEP],
    description:
      "The purpose of this policy is to establish processes, procedures, and standards that must be followed for the protection of Cardholder data (CHD) and systems in accordance with the Payment Card Industry Data Security Standard (PCI DSS).",
    title: "PCI DSS Policy - SAQ A EP",
    features: [Feature.PCISaqAEPStandard],
  },
  "pci-dss-saq-d": {
    policyType: "pci-dss-saq-d",
    standards: [ReportStandard.pciSaqDSP, ReportStandard.pciSaqDMerchant],
    description:
      "The purpose of this policy is to establish processes, procedures, and standards that must be followed for the protection of Cardholder data (CHD) and systems in accordance with the Payment Card Industry Data Security Standard (PCI DSS).",
    title: "PCI DSS Policy - SAQ D",
    features: [Feature.PCISaqDMerchantStandard, Feature.PCISaqDSPStandard],
  },
};

export const ISOPolicyDocStubsMap: { [k in ISOPolicyType]: IPolicyDocStub } = {
  "00-isms-master-list-of-documents": {
    policyType: "00-isms-master-list-of-documents",
    standards: [ReportStandard.iso27001],
    description:
      "To identify, document, and maintain a list of the current policies and procedures relevant to %COMPANY’s ISMS, the document number, document name, and the current document version.",
    title: "00-ISMS Master List of Documents",
    features: [Feature.ISOStandard],
  },
  "01-isms-scope-of-the-isms": {
    policyType: "01-isms-scope-of-the-isms",
    standards: [ReportStandard.iso27001],
    description:
      "%COMPANY has established an Information Security Management System (“ISMS”) in accordance with ISO/IEC 27001 (“ISO 27001”) that governs the processes required to protect company and information assets. This document specifies the scope of the ISMS at %COMPANY.",
    title: "01-ISMS Scope of the ISMS",
    features: [Feature.ISOStandard],
  },
  "02-isms-information-security-management-system-isms-policy": {
    policyType: "02-isms-information-security-management-system-isms-policy",
    standards: [ReportStandard.iso27001],
    description:
      "This policy provides a framework to be applied when establishing, implementing, maintaining, and continually improving the information security management system (“ISMS”), as defined in 01-ISMS Scope of the ISMS, in accordance with the requirements of the ISO/IEC 27001 (“ISO 27001”) standard.",
    title: "02-ISMS Information Security Management System (ISMS) Policy",
    features: [Feature.ISOStandard],
  },
  "03-isms-roles-responsibilities-and-authorities": {
    policyType: "03-isms-roles-responsibilities-and-authorities",
    standards: [ReportStandard.iso27001],
    description:
      "The purpose of this document is to clearly define the roles, responsibilities, and authorities that are essential to the establishment, implementation, maintenance and continual improvement of %COMPANY’s Information Security Management System (“ISMS”).",
    title: "03-ISMS Roles, Responsibilities, and Authorities",
    features: [Feature.ISOStandard],
  },
  "04-isms-risk-assessment-and-risk-treatment-process": {
    policyType: "04-isms-risk-assessment-and-risk-treatment-process",
    standards: [ReportStandard.iso27001],
    description:
      "The purpose of the Risk Assessment and Risk Treatment process is to assess risks to the achievement of %COMPANY’s information security and business objectives.",
    title: "04-ISMS Risk Assessment and Risk Treatment Process",
    features: [Feature.ISOStandard],
  },
  "05-isms-procedure-for-the-control-of-documented-information": {
    policyType: "05-isms-procedure-for-the-control-of-documented-information",
    standards: [ReportStandard.iso27001],
    description:
      "Documents required in support of the Information Security Management System (“ISMS”) shall be protected and controlled.",
    title: "05-ISMS Procedure for the Control of Documented Information",
    features: [Feature.ISOStandard],
  },
  "06-isms-information-security-communication-plan": {
    policyType: "06-isms-information-security-communication-plan",
    standards: [ReportStandard.iso27001],
    description:
      "This document defines the considerations and steps to be used when communicating information relevant to %COMPANY’s Information Security Management System (“ISMS”), including its purpose and importance to internal and external parties.",
    title: "06-ISMS Information Security Communication Plan",
    features: [Feature.ISOStandard],
  },
  "07-isms-procedure-for-internal-audits": {
    policyType: "07-isms-procedure-for-internal-audits",
    standards: [ReportStandard.iso27001],
    description:
      "The purpose of this procedure is to establish a framework for planning, conducting, and reporting on an internal audit of the %COMPANY’s Information Security Management System (“ISMS”).",
    title: "07-ISMS Procedure for Internal Audits",
    features: [Feature.ISOStandard],
  },
  "08-isms-procedure-for-management-review": {
    policyType: "08-isms-procedure-for-management-review",
    standards: [ReportStandard.iso27001],
    description:
      "The purpose of this procedure document is to define the management process for the periodic review and evaluation of %COMPANY’s Information Security Management System (“ISMS”) to help ensure its continuing suitability, adequacy and effectiveness as defined in section 9.3 of ISO/IEC 27001 (“ISO 27001”).",
    title: "08-ISMS Procedure for Management Review",
    features: [Feature.ISOStandard],
  },
  "09-isms-procedure-for-corrective-action-and-continual-improvement": {
    policyType:
      "09-isms-procedure-for-corrective-action-and-continual-improvement",
    standards: [ReportStandard.iso27001],
    description:
      "The purpose of this procedure document is to establish and outline the process for identifying, documenting, analyzing, and implementing corrective actions needed to remediate non-conformities or to improve the Information Security Management System (“ISMS”).",
    title: "09-ISMS Procedure for Corrective Action and Continual Improvement",
    features: [Feature.ISOStandard],
  },
  "10-isms-information-security-objectives-plan": {
    policyType: "10-isms-information-security-objectives-plan",
    standards: [ReportStandard.iso27001],
    description:
      "The purpose of this workbook is to establish the %COMPANY’s Information Security Objectives and Information Security Objectives Plan to achieve them.",
    title: "10-ISMS Information Security Objectives Plan",
    features: [Feature.ISOStandard],
  },
  "11-isms-statement-of-applicability": {
    policyType: "11-isms-statement-of-applicability",
    standards: [ReportStandard.iso27001],
    description:
      "To identify, document, and maintain a list of all controls relevant to %COMPANY's ISMS, the control status, and the reasons for exclusion or implementation.",
    title: "11-ISMS Statement of Applicability",
    features: [Feature.ISOStandard],
  },
  "12-isms-relevant-laws-regulations-and-contractual-requirements": {
    policyType:
      "12-isms-relevant-laws-regulations-and-contractual-requirements",
    standards: [ReportStandard.iso27001],
    description:
      "The purpose of this workbook is to identify, update, and maintain a current list of all relevant laws, regulations, and contractual requirements and %COMPANY's approach to meeting these requirements.",
    title: "12-ISMS Relevant Laws, Regulations, and Contractual Requirements",
    features: [Feature.ISOStandard],
  },
};

export const HIPAAPolicyDocStubs: IPolicyDocStub[] = Object.values(
  HIPAAPolicyDocStubsMap
);

export const BSIPolicyDocStubs: IPolicyDocStub[] =
  Object.values(BSIPolicyDocStubsMap);

export const PCIPolicyDocStubs: IPolicyDocStub[] =
  Object.values(PCIPolicyDocStubsMap);

export const ISOPolicyDocStubs: IPolicyDocStub[] =
  Object.values(ISOPolicyDocStubsMap);

export const PolicyDocStubs = [
  ...Object.values(VantaDeprecatedPolicyDocStubsMap),
  ...HIPAAPolicyDocStubs,
  ...BSIPolicyDocStubs,
  ...ISOPolicyDocStubs,
  ...PCIPolicyDocStubs,
];
