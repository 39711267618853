import React from "react";

import { DefaultLink } from "../../../../helpers/links";
import {
  InfoCalloutContainer,
  InfoCalloutHeading,
  InfoCalloutText,
} from "../common/components";

export const AWSInstanceNoScanState: React.FC = () => (
  <InfoCalloutContainer>
    <InfoCalloutHeading>No recent AWS Inspector scan found</InfoCalloutHeading>
    <InfoCalloutText>
      This EC2 instance has not been scanned for vulnerabilities in the past six
      months. Vanta recommends using AWS Inspector to check for vulnerabilities.
    </InfoCalloutText>
    <InfoCalloutText>
      To start,{" "}
      <DefaultLink href="https://docs.aws.amazon.com/inspector/latest/userguide/inspector_getting-started.html">
        set up an AWS Inspector Host Assessment
      </DefaultLink>
      . When setting up:
      <ul>
        <li>Select the “Common Vulnerabilities and Exposures” rule package</li>
        <li>
          Ensure that “Host Assessments” and “Run weekly” options are selected
        </li>
      </ul>
      Once a scan is run, findings will appear here within a few hours.{" "}
    </InfoCalloutText>
    <InfoCalloutText>
      Note that AWS Inspector may incur costs to your AWS account. You can learn
      more on{" "}
      <DefaultLink href="https://aws.amazon.com/inspector/pricing/">
        AWS's website
      </DefaultLink>
      .
    </InfoCalloutText>
    <InfoCalloutText>
      If this EC2 instance doesn't need to be scanned mark it out of scope by
      clicking “Configure Scope” for the AWS entry on the{" "}
      <DefaultLink href="/connections">Connections page</DefaultLink>, or by
      marking it with the <code>VantaNonProd</code> or <code>VantaNoAlert</code>{" "}
      tag in AWS.
    </InfoCalloutText>
  </InfoCalloutContainer>
);
