import "./vanta-report-requirement-list.scss";

import React from "react";

import type { GetDataForReportQuery } from "../../../../../gen/components";
import type { IDomainForReport } from "../../../helpers/helpers";
import { VantaReportControlList } from "../control-list/vanta-report-control-list";
import { VantaReportRequirementListControlCountDisplay } from "./vanta-report-requirement-list-control-count-display";
import { VantaReportRequirementListItemHeading } from "./vanta-report-requirement-list-item-heading";

interface IProps {
  domain: IDomainForReport;
  requirement: NonNullable<
    GetDataForReportQuery["complianceStandard"]
  >["principles"][number]["requirements"][number];
}

export class VantaReportRequirementListItem extends React.PureComponent<IProps> {
  public render() {
    const { requirement, domain } = this.props;
    return (
      <div
        className="vrp-requirement-list-item-container"
        id={requirement.name}
      >
        <VantaReportRequirementListItemHeading requirement={requirement} />
        <div className="vrp-requirement-list-item-body">
          <VantaReportRequirementListControlCountDisplay
            requirement={requirement}
          />
          <VantaReportControlList
            domain={domain}
            controls={requirement.controls.sort((control1, control2) =>
              control1.name.localeCompare(control2.name)
            )}
          />
        </div>
      </div>
    );
  }
}
