import { isSome } from "common/base/types/maybe";
import React, { useContext } from "react";
import styled from "styled-components";

import { UserContext } from "../../pages/user-context";
import { AssumedUserReadWriteIndicator } from "./assumed-user-read-write-indicator";

export const AssumedUserStatus: React.FC = () => {
  const { user } = useContext(UserContext);

  if (!isSome(user) || !user.isAssumedUser) {
    return null;
  }

  const mainText = user.isAssumedSuperUser ? `Superuser` : user.displayName;

  return (
    <Container>
      {mainText}
      <AssumedUserReadWriteIndicator
        hasAssumedWriteAccess={user.hasAssumedWriteAccess}
      />
    </Container>
  );
};

const Container = styled.div`
  margin: 0px 8px;
  font-weight: 600;
  line-height: 24px;
  text-align: right;
`;
