import {
  Button,
  Classes,
  Dialog,
  FormGroup,
  InputGroup,
  Intent,
  TextArea,
} from "@blueprintjs/core";
import { isSome } from "common/base/types/maybe";
import { COMMENT_PREFIX } from "common/base/utils";
import React, { useState } from "react";
import styled from "styled-components";

import type { IWorkPaperRow } from "./work-paper-detail-container";

interface IEditWorkPaperDialogProps {
  isOpen: boolean;
  closeDialog: () => void;
  workPaperRow: IWorkPaperRow;
  updateWorkPaperTest: (test: IWorkPaperRow) => Promise<void>;
}
export const EditWorkPaperDialog: React.FC<IEditWorkPaperDialogProps> = ({
  isOpen,
  closeDialog,
  workPaperRow,
  updateWorkPaperTest,
}) => {
  const [controlDescription, setControlDescription] = useState(
    workPaperRow.controlDescription
  );
  const [testApplied, setTestApplied] = useState(workPaperRow.testApplied);
  const [testResultsAuditor, setTestResultsAuditor] = useState(
    workPaperRow.testResultsAuditor
  );
  const [commentsAuditor, setCommentsAuditor] = useState(
    workPaperRow.commentsAuditor
  );

  const onSave = async () => {
    closeDialog();
    if (isSome(workPaperRow)) {
      const updatedWorkPaperTest: IWorkPaperRow = {
        ...workPaperRow,
        controlDescription,
        testApplied,
        testResultsAuditor,
        commentsAuditor,
      };
      await updateWorkPaperTest(updatedWorkPaperTest);
    }
  };

  return (
    <div className={Classes.DIALOG_BODY}>
      <StyledDialog isOpen={isOpen} onClose={closeDialog}>
        <h3>
          {workPaperRow.criterion} {workPaperRow.controlName}
        </h3>
        <p> This will update all rows where this test data is used. </p>
        <StyledFormGroup label={"Control description"}>
          <StyledTextArea
            value={controlDescription}
            placeholder="Control description"
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              setControlDescription(e.target.value)
            }
          />
        </StyledFormGroup>
        <StyledFormGroup label={"Test applied"}>
          <StyledTextArea
            value={testApplied}
            placeholder="Test applied"
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              setTestApplied(e.target.value)
            }
          />
        </StyledFormGroup>
        <StyledFormGroup label={"Test results (auditor)"}>
          <InputGroup
            type="text"
            value={testResultsAuditor}
            placeholder="Test results (auditor)"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setTestResultsAuditor(e.target.value)
            }
          />
        </StyledFormGroup>
        <StyledFormGroup label={"Comments (auditor)"}>
          <InputGroup
            type="text"
            value={commentsAuditor}
            placeholder="Comments (auditor)"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setCommentsAuditor(e.target.value)
            }
          />
        </StyledFormGroup>
        <StyledFormGroup disabled inline={true}>
          {`Test status (Vanta): ${workPaperRow?.testStatusVanta}`}
        </StyledFormGroup>
        <StyledFormGroup disabled inline={true}>
          <p> Comments (Vanta) </p>
          {formatWhitelistingComments(workPaperRow?.commentsVanta ?? "")}
        </StyledFormGroup>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={closeDialog}>Cancel</Button>
            <Button intent={Intent.PRIMARY} onClick={onSave}>
              Save
            </Button>
          </div>
        </div>
      </StyledDialog>
    </div>
  );
};

export const formatWhitelistingComments = (comments: string) => {
  const splitComments = comments
    .split(COMMENT_PREFIX)
    .filter(comment => comment !== "");
  return (
    <ul>
      {splitComments.map((comment: string, i: number) => (
        <li key={`comment-${i}`}> {`${comment}`} </li>
      ))}
    </ul>
  );
};

const StyledFormGroup = styled(FormGroup)`
  &&& {
    width: 100%;
  }
`;

const StyledTextArea = styled(TextArea)`
  && {
    height: 100px;
    width: 100%;
  }
`;

const StyledDialog = styled(Dialog)`
  padding: 48px 72px;
  width: 760px;
  &,
  .bp3-dialog-header,
  .bp3-dialog-body,
  .bp3-dialog-footer {
    margin: 0;
  }
`;
