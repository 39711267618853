import type { IRadioProps } from "@blueprintjs/core";
import { Radio as BPRadio } from "@blueprintjs/core";
import React from "react";
import styled from "styled-components";

const StyledBPRadio = styled(BPRadio)`
  &.bp3-radio {
    display: flex;
    align-items: center;
  }
`;

export class Radio extends React.PureComponent<IRadioProps> {
  public render() {
    return <StyledBPRadio {...this.props} />;
  }
}
