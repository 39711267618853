import type { Maybe } from "./maybe";
import { isSome } from "./maybe";

export enum APIServiceErrorTypes {
  HerokuCredentialsInvalid = "HerokuCredentialsInvalid",
}

export enum CredentialsNotFound {
  CODE = "CredentialsNotFound",
}

export class APIServiceError extends Error {
  public code: Maybe<APIServiceErrorTypes>;
  public message: string;
  constructor({ code, message }: { code: Maybe<string>; message: string }) {
    super();
    // necessary for error type extensions in typescript
    Object.setPrototypeOf(this, APIServiceError.prototype);
    if (isSome(code)) {
      this.code = APIServiceErrorTypes.HerokuCredentialsInvalid;
    }
    this.message = message;
  }

  // used in order to allow revival of this object after JSON serialized
  public toJSON() {
    return {
      __className: "APIServiceError",
      code: this.code,
      message: this.message,
    };
  }

  public toString = () =>
    `API Service Error with code: ${this.code} and message: ${this.message}`;
}
export class CredentialsNotFoundError extends Error {
  public cause: Error;
  public code: string;
  public constructor(e: Error, code = CredentialsNotFound.CODE) {
    super(e.message);
    // necessary for error type extensions in typescript
    Object.setPrototypeOf(this, CredentialsNotFoundError.prototype);
    this.cause = e;
    this.code = code;
  }

  // used in order to allow revival of this object after JSON serialized
  public toJSON() {
    return {
      __className: "CredentialsNotFoundError",
      cause: this.cause,
      code: this.code,
      message: this.message,
    };
  }

  public toString = () =>
    `CredentialsNotFoundError with code: ${this.code} and message: ${this.message}. Error: ${this.cause}`;
}

export class ErrorWithMetadata extends Error {
  public metadata: any;
  public name = "ErrorWithMetadata";

  constructor(message: string, metadata: any) {
    super(message);
    this.metadata = metadata;
  }

  public toString(): string {
    return JSON.stringify({ message: this.message, metadata: this.metadata });
  }
}
