import { isSome } from "common/base/types/maybe";
import moment from "moment";
import React from "react";

import type { CompletionFieldsFragment } from "../../../../gen/components";
import { DATE_FORMAT_FULL_MONTH } from "../shared/common";
import { CompleteTaskControl } from "./complete-task-control";
import { GenericTaskItem } from "./generic-task-item";

interface IProps {
  taskCompletion: CompletionFieldsFragment;
  userId: string;
  isUserActive: boolean;
  roleCompletionId: string;
}

export const CustomTaskItem: React.FC<IProps> = ({
  taskCompletion,
  userId,
  isUserActive,
  roleCompletionId,
}) => {
  const { task } = taskCompletion;
  const isCompleted = isSome(taskCompletion.completionDate);

  const maybeTooltip =
    isSome(taskCompletion.completionDate) && isSome(taskCompletion.responseText)
      ? taskCompletion.responseText
      : null;

  return (
    <GenericTaskItem
      mainText={task.title}
      detailText={
        isCompleted
          ? `completed ${moment(taskCompletion.completionDate!).format(
              DATE_FORMAT_FULL_MONTH
            )}${
              isSome(taskCompletion.approver)
                ? ` by ${taskCompletion.approver.displayName}`
                : ""
            }`
          : undefined
      }
      isCompleted={isCompleted}
      rightControl={
        isUserActive ? (
          <CompleteTaskControl
            taskCompletion={taskCompletion}
            userId={userId}
            roleCompletionId={roleCompletionId}
          />
        ) : null
      }
      tooltipContent={maybeTooltip}
    />
  );
};
