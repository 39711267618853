import { dropNothing, isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import React from "react";

import { LogError } from "../../../errors";
import type {
  SpecificResource,
  TasksForSlAsQuery,
} from "../../../gen/components";
import { useTasksForSlAsQuery } from "../../../gen/components";
import { DefaultLink } from "../../../helpers/links";
import { FailDataFromSLAMiss } from "../fail-data-from-sla-miss";

function renderTask(
  task: NonNullable<
    TasksForSlAsQuery["organization"]
  >["resourcesByIds"][number],
  entityType: SpecificResource
) {
  if (!("taskCreatedAt" in task)) {
    return null; // invalid task
  }
  const url = task.url;

  return {
    element: <DefaultLink href={url}>{task.title}</DefaultLink>,
    entity: {
      entityId: task.id,
      entityType,
    },
    openDate: new Date(task.taskCreatedAt),
    closeDate: isSome(task.taskClosedAt) ? new Date(task.taskClosedAt) : null,
  };
}

export const TaskSLATestEntities: React.FC<{
  entityType: SpecificResource;
  entityIds: string[];
  testId: string;
  allowWhitelisting: boolean;
  renderingIndividually: boolean;
  first: number;
}> = ({
  entityType,
  entityIds,
  testId,
  allowWhitelisting,
  renderingIndividually,
  first,
}) => {
  const { loading, error, data } = useTasksForSlAsQuery({
    variables: { ids: entityIds, specificResourceType: entityType },
  });

  if (loading) {
    return <div />;
  }
  if (error) {
    LogError(error);
    return null;
  }
  if (!data?.organization) {
    return <div />;
  }

  const openItems = dropNothing(
    data.organization.resourcesByIds
      .filter(task => "taskCreatedAt" in task && !isSome(task.taskClosedAt))
      .map(t => renderTask(t, entityType))
  );

  const closedItems = dropNothing(
    data.organization.resourcesByIds
      .filter(task => "taskCreatedAt" in task && isSome(task.taskClosedAt))
      .map(t => renderTask(t, entityType))
  );

  return (
    <FailDataFromSLAMiss
      header={renderingIndividually ? undefined : "Tasks"}
      testId={testId}
      openItems={openItems}
      closedItems={closedItems}
      allowWhitelisting={allowWhitelisting}
      first={first}
    />
  );
};

gql`
  query tasksForSLAs(
    $ids: [String!]!
    $specificResourceType: SpecificResource!
  ) {
    organization {
      id
      resourcesByIds(ids: $ids, specificResourceType: $specificResourceType) {
        id
        createdAt
        deletedAt
        displayName
        domainId
        externalURL
        fetchId
        service
        uniqueId
        updatedAt
        # We should be able to do ... on GenericTaskTrackerItemResource
        # but graphql-code-generator doesn't currently support it.
        ... on SpecificAirtableTaskResource {
          taskCreatedAt
          taskClosedAt
          url
          title
        }
        ... on SpecificAsanaTaskResource {
          taskCreatedAt
          taskClosedAt
          url
          title
        }
        ... on SpecificClubhouseTaskResource {
          taskCreatedAt
          taskClosedAt
          url
          title
        }
        ... on SpecificGithubTaskResource {
          taskCreatedAt
          taskClosedAt
          url
          title
        }
        ... on SpecificJiraTaskResource {
          taskCreatedAt
          taskClosedAt
          url
          title
        }
        ... on SpecificLinearTaskResource {
          taskCreatedAt
          taskClosedAt
          url
          title
        }
        ... on SpecificPivotalTrackerTaskResource {
          taskCreatedAt
          taskClosedAt
          url
          title
        }
        ... on SpecificTrelloTaskResource {
          taskCreatedAt
          taskClosedAt
          url
          title
        }
      }
    }
  }
`;
