import { Button, ControlGroup, Divider, Icon, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React from "react";
import styled from "styled-components";
import { Tooltip } from "../../../alpaca/components";

import type { Credential } from "./services/common-interface";

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 8px;
`;

const InlineDisabledCredentialsCallout = styled.div`
  margin-right: 10px;
  padding: 4px 4px;
`;
const AccountIdFlexChild = styled.div`
  flex-grow: 1;
`;

const TooltipContent = styled.span`
  display: block;
  max-width: 600px;
`;

export const RenderCredential: React.FC<{
  credential: Credential;
  accountId: string;
  editCredential: () => void;
  deleteCredential: () => void;
}> = ({ credential, accountId, editCredential, deleteCredential }) => (
  <Flex key={accountId}>
    {credential.isDisabled ? (
      <Tooltip
        content={
          <TooltipContent>
            We're having some trouble getting data for this account.<br></br>{" "}
            {credential.disableCause}
          </TooltipContent>
        }
        placement="bottom-end"
      >
        <InlineDisabledCredentialsCallout>
          <Icon icon={IconNames.ERROR} />
        </InlineDisabledCredentialsCallout>
      </Tooltip>
    ) : null}
    <AccountIdFlexChild>{accountId}</AccountIdFlexChild>
    <ControlGroup>
      <Button
        minimal
        intent={Intent.PRIMARY}
        icon={IconNames.EDIT}
        onClick={editCredential}
      ></Button>
      <Divider></Divider>
      <Button
        minimal
        icon={IconNames.TRASH}
        intent={Intent.DANGER}
        onClick={deleteCredential}
      ></Button>
    </ControlGroup>
  </Flex>
);
