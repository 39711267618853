import type { DialogProps } from "@blueprintjs/core";
import { Dialog as BPDialog } from "@blueprintjs/core";
import classnames from "classnames";
import React from "react";
import styled from "styled-components";

import { BASE_PALETTE } from "../../base/colors";
import { BASE_TYPOGRAPHY } from "../../base/typography";
import { Button, H4, Icon, IconNames } from "../index";
import { DIALOG_CLASS_STYLES, DialogClasses } from "./styles";

export const Dialog: React.FC<DialogProps> = ({
  title,
  children,
  ...props
}) => (
  <StyledBPDialog
    {...props}
    className={classnames(props.className, DialogClasses.DIALOG)}
  >
    <div className={DialogClasses.DIALOG_HEADER}>
      <H4
        fontWeight={BASE_TYPOGRAPHY.FONT_WEIGHTS.SLIGHTLY_BOLD}
        style={{ marginBottom: "0px" }}
      >
        {title}
      </H4>
      {props.isCloseButtonShown ?? true ? (
        <StyledCloseButton
          minimal
          onClick={props.onClose}
          icon={
            <Icon
              icon={IconNames.CLOSE}
              iconSize={12}
              color={BASE_PALETTE.INK}
            />
          }
        />
      ) : null}
    </div>
    <div className={DialogClasses.DIALOG_BODY}>{children}</div>
  </StyledBPDialog>
);

const StyledCloseButton = styled(Button)`
  min-height: 20px;
  min-width: 20px;
`;

const StyledBPDialog = styled(BPDialog)`
  ${DIALOG_CLASS_STYLES}
`;
