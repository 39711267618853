import { gql } from "@apollo/client";
import { Intent } from "@blueprintjs/core";
import { isSome } from "common/base/types/maybe";
import fileDownload from "js-file-download";
import { parse } from "json2csv";
import React, { useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { Banner, Button, IconNames } from "../../../../alpaca/components";
import { LogError } from "../../../../errors";
import type {
  FetchVulnerablePackagesQuery,
  AgentWhitelistedVulnsQuery,
} from "../../../../gen/components";
import {
  FetchVulnerablePackagesDocument,
  AgentWhitelistedVulnsDocument,
} from "../../../../gen/components";
import { AppToaster } from "../../../../helpers/toaster";
import { apolloClient } from "../../../../reducers/index";

const getAllPackages = async () => {
  const allPackages = [];
  let hasMore = true;
  let endCursor;
  while (hasMore) {
    const response = await apolloClient.query<FetchVulnerablePackagesQuery>({
      query: FetchVulnerablePackagesDocument,
      variables: {
        first: 10000,
        after: endCursor,
        applyWhitelist: false,
      },
    });
    if (isSome(response.error)) {
      LogError(response.error);
      return [];
    }
    const vulns = response.data?.organization.packageVulnerabilities;
    // casted as any to not confuse the type system (endCursor is used while defining response)
    endCursor = vulns?.pageInfo.endCursor as any;
    hasMore = vulns?.pageInfo.hasNextPage;
    allPackages.push(...(vulns?.edges.map(e => e.node) ?? []));
  }
  return allPackages;
};

gql`
  query agentWhitelistedVulns {
    organization {
      id
      testResults(
        testIds: ["infra-packages-checked-for-vulnerabilities-records-closed"]
      ) {
        id
        disabledEntities {
          entityId
          reason
        }
      }
    }
  }
`;

const getWhitelisting = async () => {
  const response = await apolloClient.query<AgentWhitelistedVulnsQuery>({
    query: AgentWhitelistedVulnsDocument,
  });
  if (isSome(response.error)) {
    LogError(response.error);
    return [];
  }
  return response.data.organization.testResults
    .map(tr => tr.disabledEntities)
    .flat();
};

const ExportVulnerabilitiesButton: React.FC = () => {
  const [fetchingVulnData, setFetchingVulnData] = useState(false);

  return (
    <Button
      loading={fetchingVulnData}
      onClick={async () => {
        setFetchingVulnData(true);
        const allPackages = await getAllPackages();
        const allDisabledEntities = await getWhitelisting();
        const disabledEntities = new Map(
          allDisabledEntities.map(entity => [entity.entityId, entity.reason])
        );
        setFetchingVulnData(false);
        const packages = allPackages.flatMap(pkg =>
          pkg.machineInstances?.map(machineInstance => {
            const possibleHostId =
              machineInstance.vulnData.osquery?.cloudProviderId;
            const hostId =
              isSome(possibleHostId) &&
              possibleHostId !== "VANTA_HOST_WITHOUT_CLOUD_ID"
                ? possibleHostId
                : machineInstance.vulnData.osquery?.prettyName;
            return {
              "Source": "Vanta Agent",
              "Package Name": pkg.packageName,
              "Package Version": pkg.packageVersion,
              "Instance ID": hostId,
              "CVE Severity": pkg.maxSeverity,
              "Date Detected": machineInstance.createdAt,
              "SLA Deadline": machineInstance.vulnData.slaDeadline,
              "Ignored": disabledEntities.has(machineInstance.id)
                ? "Yes"
                : "No",
              "Ignore Reason":
                disabledEntities.get(machineInstance.id) ?? "N/A",
            };
          })
        );
        if (packages.length === 0) {
          AppToaster.show({
            message: "No open vulnerabilities to export",
            intent: Intent.PRIMARY,
          });
          setFetchingVulnData(false);
          return;
        }
        const csv = parse(packages);
        fileDownload(csv, "vulnerabilities.csv");
        setFetchingVulnData(false);
      }}
    >
      Export vulnerabilities
    </Button>
  );
};

interface IProps {
  hasAwsCredentials: boolean;
}

export const VantaAgentDeprecationCallout: React.FC<IProps> = ({
  hasAwsCredentials,
}) => {
  const history = useHistory();

  return (
    <Banner
      intent={Intent.NONE}
      title="Agent vulnerability scanning will be going away Dec 1"
      icon={IconNames.INFO}
      buttonProps={
        hasAwsCredentials
          ? {
              onClick: async () => {
                history.push("/vulnerabilities#aws-inspector-vulns");
              },
              text: "Go to AWS Inspector",
            }
          : null
      }
    >
      <p>
        Open vulnerabilities will be cleared on this date, but you can export
        them beforehand.
        <b> Previous vulnerability history will still be available.</b>
      </p>
      <p>
        Agent scanning has been replaced by integrations.{" "}
        <RouterLink
          to={{
            pathname:
              "https://vanta.zendesk.com/hc/en-us/articles/4407361201428",
          }}
          target="_blank"
        >
          Learn more
        </RouterLink>
      </p>
      <ExportVulnerabilitiesButton />
    </Banner>
  );
};
