import { Spinner } from "@blueprintjs/core";
import {
  specificResourceGraphQLTypeToEnumValue,
  SpecificResourceToInventoryType,
} from "common/utils/inventory";
import { deserializeVantaAttributes } from "common/utils/vantaAttributes";
import gql from "graphql-tag";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import type { GitRepositoriesQuery } from "../../../../gen/components";
import {
  useGitRepositoriesQuery,
  useSetInventoryResourceVantaAttributeMutation,
} from "../../../../gen/components";
import { GithubRepoCallout } from "../github-repo-callout";
import { InventoryCard } from "../inventory-card";
import {
  fetchMoreResources,
  getDebounceContext,
  getResourceFilterParams,
  invalidInventoryType,
  PAGE_SIZE,
  setResourceVantaAttributeFn,
} from "../utils";
import { SearchResultsSummary } from "./search-results-summary";
import type { IInventoryTabProps } from "./shared-interface";

export const GitRepositories: React.FC<IInventoryTabProps> = ({
  searchString,
}) => {
  const { data, fetchMore, loading } = useGitRepositoriesQuery({
    variables: {
      first: PAGE_SIZE,
      filterParams: getResourceFilterParams(searchString),
    },
    context: getDebounceContext("git-repo"),
  });

  if (loading) {
    return <Spinner />;
  }

  const cards = data?.organization.resources.edges?.map(item => (
    <GitRepoCard key={item.node.id} item={item} domain={data.organization} />
  ));

  return (
    <>
      <SearchResultsSummary
        searchString={searchString}
        numberResults={data?.organization.resources?.totalCount ?? 0}
      />
      <GithubRepoCallout />
      <InfiniteScroll
        className="inventory-list-card-group"
        dataLength={data?.organization.resources.edges.length ?? 0}
        next={async () => fetchMoreResources(data, fetchMore)}
        hasMore={data?.organization.resources.pageInfo.hasNextPage ?? false}
        loader={<Spinner />}
      >
        {cards}
      </InfiniteScroll>
    </>
  );
};

const GitRepoCard: React.FC<{
  item: NonNullable<
    GitRepositoriesQuery["organization"]
  >["resources"]["edges"][number];
  domain: NonNullable<GitRepositoriesQuery["organization"]>;
}> = ({ item, domain }) => {
  const [setResourceVantaAttribute] =
    useSetInventoryResourceVantaAttributeMutation();

  const specificResourceKind = specificResourceGraphQLTypeToEnumValue(
    item.node.__typename
  );
  const type = SpecificResourceToInventoryType[specificResourceKind](
    item.node as any
  );
  const resourceMongoId = item.node.id;

  if (
    item.node.__typename !== "SpecificGithubRepoResource" &&
    item.node.__typename !== "SpecificGitlabRepoResource" &&
    item.node.__typename !== "SpecificBitbucketRepoResource" &&
    item.node.__typename !== "SpecificAzureDevOpsRepoResource"
  ) {
    return invalidInventoryType(item.node.__typename);
  }

  const uid = item.node.name!;
  const vantaAttributes = deserializeVantaAttributes(
    item.node.vantaAttributes ?? []
  );
  const setVantaAttribute = setResourceVantaAttributeFn(
    setResourceVantaAttribute,
    specificResourceKind,
    resourceMongoId,
    item.node.__typename
  );

  return (
    <InventoryCard
      type={type}
      uid={uid}
      key={item.node.id}
      name={item.node.name}
      description={vantaAttributes.description ?? item.node.description}
      owner={vantaAttributes.ownerId}
      canContainUserData={false}
      setVantaAttribute={setVantaAttribute}
    />
  );
};

gql`
  query gitRepositories(
    $first: Int!
    $after: String
    $filterParams: filterParams
  ) {
    organization {
      id
      resources(
        first: $first
        after: $after
        genericResourceType: VersionControlRepo
        sortParams: { field: "createdAt", direction: -1 }
        filterParams: $filterParams
      ) {
        totalCount
        edges {
          node {
            id
            __typename
            uniqueId
            vantaAttributes {
              key
              value
              managedExternally
            }
            ... on SpecificGithubRepoResource {
              name
              description
            }
            ... on SpecificBitbucketRepoResource {
              name
              description
            }
            ... on SpecificGitlabRepoResource {
              name
              description
            }
            ... on SpecificAzureDevOpsRepoResource {
              name
              description
            }
          }
        }
        pageInfo {
          hasNextPage
          endCursor
          startCursor
          hasPreviousPage
        }
      }
    }
  }
`;
