export const SERVICE_TO_HR_USER_KIND = {
  adp_run: "AdpRunHrUser",
  adp_workforce_now: "AdpWorkforceNowHrUser",
  bamboo_hr: "BambooHrHrUser",
  gusto: "GustoHrUser",
  insperity: "InsperityHrUser",
  justworks: "JustworksHrUser",
  namely: "NamelyHrUser",
  paychex_flex: "PaychexFlexHrUser",
  paycor: "PaycorHrUser",
  paylocity: "PaylocityHrUser",
  quickbooks: "QuickBooksHrUser",
  rippling: "RipplingHrUser",
  square_payroll: "SquarePayrollHrUser",
  trinet: "TrinetHrUser",
  zenefits: "ZenefitsHrUser",
} as const;
