import { Classes } from "@blueprintjs/core";
import { Feature } from "common/base/types/gen";
import {
  VANTA_KEBAB_LABEL_KEYS,
  VANTA_KEBAB_OWNER_DOT_SUBSTITUTE,
} from "common/utils/tags";
import React from "react";
import styled from "styled-components";

import { FeatureGate } from "../../../helpers/feature-gating/feature-gate";
import { InfoIcon } from "../../helpers/InfoIcon";

const MultilineTooltipContent = styled.div`
  max-width: 400px;
`;

/**
 * DigitalOcean tags are a lot like GCP tags in terms of restrictions, but also
 * not key-value so we need to ask customers to fake it by including a colon
 *
 */
export const DocsDigitalOceanLabel: React.FC = () => (
  <div className={Classes.DIALOG_BODY}>
    <p>
      Apply these tags to your DigitalOcean resources, and Vanta will update
      your inventory list.
    </p>
    <code>
      tags &#123;
      <br />
      &nbsp; {VANTA_KEBAB_LABEL_KEYS.vantaOwner}:olivia
      <InfoIcon
        content={
          <MultilineTooltipContent>
            <>
              First part of the resource owner's company email address (e.g.{" "}
              <code>olivia</code> for <code>olivia@company.com</code>).
              <br />
              Replace dots in the email with{" "}
              <code>{VANTA_KEBAB_OWNER_DOT_SUBSTITUTE}</code> instead (e.g.{" "}
              <code>olivia.smith@company.com</code> becomes{" "}
              <code>olivia{VANTA_KEBAB_OWNER_DOT_SUBSTITUTE}smith</code>).
            </>
          </MultilineTooltipContent>
        }
      />
      <br />
      &nbsp; {VANTA_KEBAB_LABEL_KEYS.vantaNonProd}:true
      <InfoIcon
        content={
          <>
            <code>true</code> for staging/dev resources, <code>false</code> for
            prod
          </>
        }
      />
      <br />
      &nbsp; {VANTA_KEBAB_LABEL_KEYS.vantaDescription}
      :archive-of-ingested-events-handled-by-logs-queue
      <br />
      &nbsp; {VANTA_KEBAB_LABEL_KEYS.vantaUserData}:true
      <InfoIcon
        content={
          <>
            <code>true</code> if the resource contains user data
          </>
        }
      />
      <br />
      &nbsp; {VANTA_KEBAB_LABEL_KEYS.vantaDataStored}
      :user-emails-and-phone-numbers
      <InfoIcon
        content={"A description of what user data this resource stores"}
      />
      <br />
      <FeatureGate features={[Feature.BetaHIPAA]}>
        &nbsp; {VANTA_KEBAB_LABEL_KEYS.vantaEPHI}:true
        <InfoIcon
          content={
            <>
              <code>true</code> if the resource contains electronic Protected
              Health Information (ePHI)
            </>
          }
        />
        <br />
      </FeatureGate>
      &nbsp; {VANTA_KEBAB_LABEL_KEYS.vantaNoAlert}
      :this-stores-our-favorite-foods-and-isnt-part-of-our-production-systems
      <InfoIcon
        content={
          <MultilineTooltipContent>
            <p>
              Vanta will not monitor the resource and will exclude it from SOC 2
              scope. Only add this tag if you’re sure the resource is irrelevant
              to SOC 2, and please specify the reason that it's being excluded.
            </p>
          </MultilineTooltipContent>
        }
      />
      <br />
      &#125;
    </code>
    <br />
    <br />
    Note that DigitalOcean enforces these restrictions on tags:
    <br />
    Tags may contain letters, numbers, colons, dashes, and underscores. There is
    a limit of 255 characters per tag.
  </div>
);
