import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { isSome } from "common/base/types/maybe";
import React, { useState } from "react";
import { Tooltip } from "../../../alpaca/components";

import { LogError } from "../../../errors";
import type { GetAuditInfoQuery } from "../../../gen/components";
import {
  GetAuditInfoDocument,
  useGrantAccessToAuditorMutation,
  useRevokeAccessFromAuditorMutation,
} from "../../../gen/components";
import { AppToaster } from "../../../helpers/toaster";
import { CancelConfirmDialog } from "../../helpers/CancelConfirmDialog";

interface IProps {
  auditor: NonNullable<
    GetAuditInfoQuery["organization"]
  >["audits"][number]["auditors"][number];
  hasAccess: boolean;
}

export const AuditorAccessToggle: React.FC<IProps> = ({
  auditor,
  hasAccess,
}) => {
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [grantAccess] = useGrantAccessToAuditorMutation({
    refetchQueries: [{ query: GetAuditInfoDocument }],
  });
  const [removeAccess] = useRevokeAccessFromAuditorMutation({
    refetchQueries: [{ query: GetAuditInfoDocument }],
  });

  if (!isSome(auditor) || !isSome(auditor.email)) {
    return null;
  }

  const dialog = (
    <CancelConfirmDialog
      isOpen={dialogIsOpen}
      onClose={() => setDialogIsOpen(false)}
      title={hasAccess ? "Remove auditor access" : "Grant auditor access"}
      body={
        <div>
          {hasAccess ? (
            <div>
              <p>
                Are you sure you want to revoke this auditor's access to your
                account?
              </p>
              <p>
                This auditor will cease to have read-only access to your Vanta
                account.
              </p>
            </div>
          ) : (
            <div>
              <p>
                Are you sure you want to grant this auditor access to your
                account?
              </p>
              <p>
                This auditor will have read-only access to your Vanta account.
                This access is independent of audit observation dates.
              </p>
            </div>
          )}
        </div>
      }
      confirmText={"Confirm"}
      onConfirm={async () => {
        if (hasAccess) {
          removeAccess({
            variables: {
              auditorId: auditor.id,
            },
            update: () => {
              AppToaster.show({
                message: "Removed access to account",
                intent: Intent.SUCCESS,
              });
            },
          }).catch(LogError);
        } else {
          grantAccess({
            variables: {
              email: auditor.email,
            },
            update: () => {
              AppToaster.show({
                message: "Granted access to account",
                intent: Intent.SUCCESS,
              });
            },
          }).catch(LogError);
        }
      }}
    />
  );

  const text = hasAccess
    ? "This auditor has access to your Vanta account"
    : "This auditor does not have access to your Vanta account";
  return (
    <>
      <Tooltip content={text} hoverOpenDelay={300}>
        <Button
          minimal
          icon={hasAccess ? IconNames.EYE_OPEN : IconNames.EYE_OFF}
          intent={hasAccess ? Intent.PRIMARY : Intent.NONE}
          onClick={() => setDialogIsOpen(true)}
        />
      </Tooltip>
      {dialog}
    </>
  );
};
