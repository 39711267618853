import { Callout, Spinner } from "@blueprintjs/core";
import { dateStringToDate } from "common/base/dateUtils";
import { dropNothing, isSome, nothing } from "common/base/types/maybe";
import gql from "graphql-tag";
import React from "react";
import { Redirect, useHistory } from "react-router";
import styled from "styled-components";

import { StyledLinkDeprecated } from "../../../../alpaca/base/deprecated";
import { BASE_TYPOGRAPHY } from "../../../../alpaca/base/typography";
import { BodyText, DefaultView } from "../../../../alpaca/components";
import { LogError } from "../../../../errors";
import type { FetchMachineVulnerabilityInfoQuery } from "../../../../gen/components";
import { useFetchMachineVulnerabilityInfoQuery } from "../../../../gen/components";
import { DataTable } from "../../components/data-table";
import {
  LoadingContainer,
  TabContainer,
  TableContainer,
  VulnerabilityFindingsHeading,
} from "../common/components";
import { LinksForCVEs } from "../common/link-for-cve";
import { SeverityPill } from "../common/severity-pill";
import { SLADeadlineWithDate } from "../common/sla-deadline-with-date";
import {
  getLastPingString,
  makeSubheadingString,
  primaryIdForMachine,
  secondaryIdForMachine,
  slaDeadlineComparator,
  vulnToTitle,
} from "../utils";
import { CopyVulnCommandIcon } from "./copy-vuln-command-icon";
import { RemediationCommand } from "./remediation-command";
import { ServerInfo } from "./server-info";
import { VulnRemediation } from "./vuln-remediation";

interface IProps {
  serverId: string;
}

export type Machine = NonNullable<
  FetchMachineVulnerabilityInfoQuery["organization"]
>["machines"]["edges"][number]["node"];

// Sometimes, a customer has installed the agent on an AWS machine, but
// misconfigured credentials prevent us from fetching its metadata from
// AWS EC2. In that case, the cloudProvider is set to AWS but the
// cloud provider metadata is null.
const isUnlinkedAWSMachine = (machine: Machine) =>
  machine.cloudProvider === "aws" && !isSome(machine.cloudProviderData);

const CalloutContainer = styled.div`
  margin-bottom: 24px;
  margin-top: 24px;
`;

export const ServerDetail: React.FC<IProps> = ({ serverId }) => {
  const history = useHistory();

  const { error, loading, data } = useFetchMachineVulnerabilityInfoQuery({
    variables: {
      serverId,
    },
  });

  if (error) {
    LogError(error);
    history.push("/vulnerabilities/#agent-vulns/servers");
    return <div />;
  }

  if (loading) {
    return (
      <LoadingContainer>
        <Spinner />
      </LoadingContainer>
    );
  }

  const loadedServers =
    data?.organization.machines?.edges.map(e => e.node) ?? [];
  const server = loadedServers.length > 0 ? loadedServers[0] : nothing;

  if (!isSome(server) || server?.data.__typename !== "linuxServerData") {
    return <Redirect to="/vulnerabilities#agent-vulns/servers" />;
  }

  const vulns = server?.data?.vulnerablePackages ?? [];
  const lastPingString = isSome(server.data.lastPing)
    ? `last pinged ${getLastPingString(server.data.lastPing)}`
    : nothing;

  return (
    <DefaultView
      headerProps={{
        backLink: "/vulnerabilities#agent-vulns/servers",
        title: primaryIdForMachine(server) ?? "",
        description: makeSubheadingString(
          secondaryIdForMachine(server),
          lastPingString
        ),
      }}
    >
      <TabContainer>
        {isUnlinkedAWSMachine(server) ? (
          <CalloutContainer>
            <Callout title="Unlinked EC2 instance">
              This looks like an EC2 instance, but we don't see it in your
              linked AWS account.
              <br />
              Please go to the{" "}
              <StyledLinkDeprecated to="/connections">
                Connections Page
              </StyledLinkDeprecated>{" "}
              and verify that your account is linked and configured to scan all
              relevant regions.
              <br />
              If this was a recently created instance, it may take Vanta up to
              an hour to link it to your AWS account.
            </Callout>
          </CalloutContainer>
        ) : null}
        {!isSome(server.cloudProviderData) ? (
          <CalloutContainer>
            <Callout>
              If this is a cloud instance, it may take up to an hour for Vanta
              to receive metadata about this machine.
            </Callout>
          </CalloutContainer>
        ) : null}

        <ServerInfo server={server} />

        {!isSome(vulns) || vulns.length === 0 ? (
          <BodyText fontWeight={BASE_TYPOGRAPHY.FONT_WEIGHTS.BOLD}>
            No vulnerable packages have been identified on this server.
          </BodyText>
        ) : (
          <TableContainer>
            <RemediationCommand server={server} />
            <VulnerabilityFindingsHeading numVulns={vulns.length} />
            <DataTable
              columnWidths={["40px", "150px", "200px", "350px", "120px"]}
              data={[...vulns].sort(slaDeadlineComparator)}
              header={{
                icon: "",
                remediation: "Remediation",
                slaDeadline: "Time until SLA violation",
                cveDetail: "Vulnerability details",
                severity: "CVE Severity",
              }}
              columnOrder={[
                "icon",
                "remediation",
                "slaDeadline",
                "cveDetail",
                "severity",
              ]}
              createRow={vuln => {
                const slaDeadline = vuln.slaDeadline;
                return {
                  icon: <CopyVulnCommandIcon vuln={vuln} server={server} />,
                  remediation: <VulnRemediation text={vulnToTitle(vuln)} />,
                  slaDeadline: isSome(slaDeadline) ? (
                    <SLADeadlineWithDate
                      slaDeadline={dateStringToDate(slaDeadline)}
                    />
                  ) : (
                    <div>-</div>
                  ),
                  cveDetail: (
                    <LinksForCVEs
                      cves={dropNothing(
                        vuln.cves.map(cve =>
                          isSome(cve.cveId) ? { name: cve.cveId } : nothing
                        )
                      )}
                    />
                  ),
                  severity: <SeverityPill severity={vuln.severity} />,
                };
              }}
            />
          </TableContainer>
        )}
      </TabContainer>
    </DefaultView>
  );
};

gql`
  query fetchMachineVulnerabilityInfo($serverId: String!) {
    organization {
      id
      machines(first: 1, ids: [$serverId], activeOnly: true) {
        edges {
          node {
            id
            cloudProvider
            cloudProviderId
            hostIdentifier
            cloudProviderData {
              id
              instanceId
              ... on SpecificEC2InstanceResource {
                name
                publicIPAddress
                privateIPAddress
                account
                tags {
                  key
                  value
                }
              }

              ... on SpecificGCPComputeInstanceResource {
                gcpName: name
                projectId
                internalInstanceId
                interfaces {
                  networkIP
                  externalIP
                }
                labels {
                  key
                  value
                }
              }

              ... on SpecificAzureVirtualMachineResource {
                azureName: name
                subscriptionId
                instanceId
                privateIPAddress
                publicIPAddress
                uniqueId
                tags {
                  key
                  value
                }
              }

              ... on SpecificAzureScaleSetVirtualMachineResource {
                azureName: name
                subscriptionId
                instanceId
                privateIPAddress
                publicIPAddress
                uniqueId
                tags {
                  key
                  value
                }
              }
            }
            data {
              id
              ... on linuxServerData {
                kernelVersion
                hostname
                osFamily
                lastPing
                vulnerablePackages {
                  id
                  packageName
                  packageVersion
                  packageSource
                  os
                  securityVersion
                  cves {
                    cveId
                    score
                  }
                  slaDeadline
                  severity
                  createdAt
                  resolvedAt
                  isMonitoringDisabled
                }
              }
            }
          }
        }
      }
    }
  }
`;
