import { Classes, Dialog, Tab } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import React, { useState } from "react";
import styled from "styled-components";

import { BodyText, Tabs, TextLinkLike } from "../../../alpaca/components";
import { DebugClientInstallation } from "../components/debug-installation";
import { AMISecure } from "../docs/AmISecure";

const FooterText = styled(BodyText)`
  margin-top: 12px;
`;

export const DebuggingInfo: React.FC = () => {
  const [isSecureDialogOpen, setIsSecureDialogOpen] = useState(false);
  const [debuggingDialogType, setDebuggingDialogType] =
    useState<
      Maybe<
        | "linuxWorkstationData"
        | "macosWorkstationData"
        | "windowsWorkstationData"
      >
    >(undefined);

  return (
    <>
      <FooterText>
        <TextLinkLike
          onClick={() => setDebuggingDialogType("macosWorkstationData")}
        >
          Troubleshoot
        </TextLinkLike>{" "}
        Vanta Agent data and installations, or{" "}
        <TextLinkLike onClick={() => setIsSecureDialogOpen(true)}>
          learn more
        </TextLinkLike>{" "}
        about Vanta Agent security.
      </FooterText>

      <Dialog
        title="Is the Vanta app secure?"
        isOpen={isSecureDialogOpen}
        onClose={() => setIsSecureDialogOpen(false)}
      >
        <div className={Classes.DIALOG_BODY}>
          <Tabs id="agent-popup-tabs" defaultSelectedTabId="agent-tab-1">
            <Tab
              title="Security"
              id="agent-tab-1"
              panel={<AMISecure concern="osquery" isDialogBody={false} />}
            />
            <Tab
              title="Malware Detection on Mac and Linux"
              id="agent-tab-2"
              panel={
                <AMISecure concern="agentAsMalware" isDialogBody={false} />
              }
            />
          </Tabs>
        </div>
      </Dialog>

      <Dialog
        title="Vanta Agent troubleshooting"
        isOpen={isSome(debuggingDialogType)}
        onClose={() => setDebuggingDialogType(undefined)}
      >
        <div className={Classes.DIALOG_BODY}>
          <Tabs
            id="troubleshooting-tabs"
            defaultSelectedTabId={debuggingDialogType ?? "macosWorkstationData"}
          >
            <Tab
              title="macOS"
              id="macosWorkstationData"
              panel={
                <DebugClientInstallation platformType="macosWorkstationData" />
              }
            />
            <Tab
              title="Windows"
              id="windowsWorkstationData"
              panel={
                <DebugClientInstallation platformType="windowsWorkstationData" />
              }
            />
            <Tab
              title="Linux"
              id="linuxWorkstationData"
              panel={
                <DebugClientInstallation platformType="linuxWorkstationData" />
              }
            />
          </Tabs>
        </div>
      </Dialog>
    </>
  );
};
