import type { ApolloClient } from "@apollo/client";
import { Intent } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/gen";
import { dropNothing, isSome } from "common/base/types/maybe";
import {
  evidenceTitleToFilePart,
  linksToCsvString,
  rawDataToCsvString,
} from "common/utils/export-helpers";
import fileDownload from "js-file-download";
import JSZip from "jszip";

import { LogErrorMessage } from "../../../../errors";
import type { GetRawDataForTestsQuery } from "../../../../gen/components";
import {
  GetRawDataForTestsDocument,
  TestOutcome,
} from "../../../../gen/components";
import { AppToaster } from "../../../../helpers/toaster";
import type {
  ControlEvidenceManualEvidence,
  ControlEvidenceTestResult,
} from "./types";

export async function downloadControlEvidence({
  testResults,
  evidenceRequests,
  apolloClient,
  filePrefix,
}: {
  testResults: ControlEvidenceTestResult[];
  evidenceRequests: ControlEvidenceManualEvidence[];
  apolloClient: ApolloClient<object>;
  filePrefix: string;
}) {
  const allTestBlobs = dropNothing(
    await Promise.all(
      testResults.map(async tr => getTestResultBlobs(tr, apolloClient))
    )
  ).flat();

  const allEvidenceBlobs = dropNothing(
    await Promise.all(
      evidenceRequests.map(async er => getManualEvidenceBlobs(er))
    )
  ).flat();

  if (allTestBlobs.length + allEvidenceBlobs.length === 0) {
    showWarningToast();
    return;
  }

  const allContent = await blobsToZip([...allTestBlobs, ...allEvidenceBlobs]);
  fileDownload(allContent, `${filePrefix}.zip`);
}

export async function downloadEvidenceRequest(
  evidenceRequest: ControlEvidenceManualEvidence,
  filename: string
) {
  const blobs = await getManualEvidenceBlobs(evidenceRequest);

  if (!isSome(blobs) || blobs.length === 0) {
    showWarningToast();
    return;
  }

  const content = await blobsToZip(blobs);
  fileDownload(content, filename);
}

export async function downloadTestResult(
  testResult: ControlEvidenceTestResult,
  apolloClient: ApolloClient<object>,
  filename: string
) {
  const blob = await getTestResultBlobs(testResult, apolloClient);

  if (!isSome(blob) || blob.length === 0) {
    showWarningToast();
    return;
  }

  if (blob.length > 1) {
    const zipped = await blobsToZip(blob);
    fileDownload(zipped, `${filename}.zip`);
  } else {
    fileDownload(blob[0].blob, filename);
  }
}

async function getManualEvidenceBlobs(
  evidenceRequest: ControlEvidenceManualEvidence
): Promise<
  Maybe<
    Array<{
      blob: Blob;
      filename: string;
    }>
  >
> {
  if (evidenceRequest.ignoredStatus.isIgnored) {
    return null;
  }

  const linkBlobs =
    evidenceRequest.links.length > 0
      ? [
          {
            filename: `${evidenceTitleToFilePart(
              evidenceRequest.title
            )}-links.csv`,
            blob: new Blob([linksToCsvString(evidenceRequest.links)]),
          },
        ]
      : [];

  const documentBlobs = dropNothing(
    await Promise.all(
      evidenceRequest.documents.map(async (doc, index) =>
        downloadUrlToBlob(`/doc?download=1&s=${doc.slugId}`)
      )
    )
  );

  return [...linkBlobs, ...documentBlobs];
}

async function getTestResultBlobs(
  testResult: ControlEvidenceTestResult,
  apolloClient: ApolloClient<object>
) {
  if (testResult.isPolicyTest && testResult.outcome === TestOutcome.PASS) {
    const policies = testResult.policies ?? [];
    return policies.length > 0 ? getPolicyBlobs(policies) : null;
  } else {
    // Only get raw data for monitored tests
    if (testResult.outcome !== TestOutcome.DISABLED) {
      const csvBlob = await getRawDataBlob(testResult, apolloClient);
      return isSome(csvBlob) ? [csvBlob] : null;
      // Check for external evidence for disabled test
    } else if (isSome(testResult.monitoringDisabledStatus.evidenceDoc)) {
      const urlBlob = await downloadUrlToBlob(
        `/doc?download=1&s=${testResult.monitoringDisabledStatus.evidenceDoc.slugId}`
      );
      return isSome(urlBlob) ? [urlBlob] : null;
    }
  }
  return null;
}

async function getPolicyBlobs(
  policies: NonNullable<ControlEvidenceTestResult["policies"]>
) {
  const blobs = await Promise.all(
    policies.map(async p => {
      const blob = (
        await downloadUrlToBlob(`/doc?download=1&s=${p.uploadedDoc.slugId}`)
      )?.blob;
      if (!isSome(blob)) {
        LogErrorMessage(
          `Could not fetch policy document of type ${p.policyType}`
        );
        return null;
      }
      return {
        filename: `${p.policyType}.pdf`,
        blob,
      };
    })
  );

  return dropNothing(blobs);
}

async function getRawDataBlob(
  testResult: ControlEvidenceTestResult,
  apolloClient: ApolloClient<object>
) {
  const queryResult = await apolloClient.query<GetRawDataForTestsQuery>({
    query: GetRawDataForTestsDocument,
    variables: { testIds: [testResult.testId] },
  });

  const rawData = dropNothing(
    queryResult.data?.organization.testResults.map(tr => tr.rawData) ?? []
  );

  if (rawData.length < 1) {
    return null;
  }

  try {
    const csvString = rawDataToCsvString(JSON.parse(rawData[0]).csvString);
    if (!isSome(csvString)) {
      return null;
    }
    const csvBlob = {
      filename: `${evidenceTitleToFilePart(testResult.name)}.csv`,
      blob: new Blob([csvString]),
    };
    return csvBlob;
  } catch (e) {
    LogErrorMessage(`Could not parse raw data for ${testResult.testId}`);
    return null;
  }
}

async function downloadUrlToBlob(
  url: string
): Promise<Maybe<{ filename: string; blob: Blob }>> {
  try {
    const response = await fetch(url);
    const filenameMatch = response.headers
      .get("Content-Disposition")
      ?.match(/filename="(.+)"/);
    if (!isSome(filenameMatch) || filenameMatch.length < 2) {
      LogErrorMessage(`No filename for document`);
      return null;
    }
    const blob = await response.blob();
    if (!isSome(blob)) {
      LogErrorMessage("Could not convert fetched document into blob");
      return null;
    }
    return { filename: filenameMatch[1], blob };
  } catch (e) {
    return null;
  }
}

export async function blobsToZip(
  blobs: Array<{
    blob: Blob;
    filename: string;
  }>
) {
  const jszip = new JSZip();
  for (const blob of blobs) {
    jszip.file(blob.filename, blob.blob);
  }
  const finalBlob = await jszip.generateAsync({ type: "blob" });
  return finalBlob;
}

function showWarningToast() {
  AppToaster.show({
    message: "No data found to export",
    intent: Intent.WARNING,
  });
}
