import { AnchorButton, Classes } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React from "react";

import { DefaultLink } from "../../../../helpers/links";
import type { IOAuthCredentialProps } from "./common-interface";

export const Finch: React.FunctionComponent<IOAuthCredentialProps> = ({
  service,
}) => {
  const url = `/auth/login/${service.id}`;

  const displayName = service.displayName;
  return (
    <div>
      <div className={Classes.DIALOG_HEADER}>Link {displayName}</div>
      <div className={Classes.DIALOG_BODY}>
        <p>
          Vanta leverages Finch, a third party platform, to pull in your
          employee data from {displayName}. To learn more about Finch, visit our{" "}
          <DefaultLink href="https://vanta.zendesk.com/hc/en-us/articles/360059397112">
            help center
          </DefaultLink>
          .
        </p>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <AnchorButton href={url} icon={IconNames.LOG_IN}>
            Connect {displayName}
          </AnchorButton>
        </div>
      </div>
    </div>
  );
};
