import type { Credentials } from "common/base/types/gen";
import React from "react";

import { CredentialCard } from "./credential-card";
import type { ServiceDetails } from "./service-groups";
interface IMergedCredentialCardProps {
  service: ServiceDetails;
  credentials: Array<
    Pick<
      Credentials,
      | "id"
      | "lastUpdated"
      | "metadata"
      | "service"
      | "isDisabled"
      | "disableCause"
    >
  >;
}

/**
 * MergedCredentialCard is a bodge for representing a service with multiple
 * credential documents. Today, this only happens for AWS and Azure.
 *
 * MergedCredentialCard is an antipattern: it introduces ambiguities around
 * disabled credential states that need to be disambiguated. If you're thinking
 * of using MergedCredentialCard, don't: instead, separate the credential
 * configuration dialog into separate dialogs for each credential.
 *
 * @deprecated
 */
export const MergedCredentialCard: React.FC<IMergedCredentialCardProps> = ({
  service,
  credentials,
}) => {
  // Indicate we're having trouble fetching data for this service if that's
  // true for at least one credential in the bunch.
  const pseudoCredential = { ...credentials[0] };
  if (credentials.some(c => c.isDisabled)) {
    pseudoCredential.isDisabled = true;
    // don't add content to the main callout
    pseudoCredential.disableCause = "";
  }
  // Take the latest lastUpdated; this determines whether the scoping UI should
  // be available.
  pseudoCredential.lastUpdated = new Date(
    Math.max(...credentials.map(c => new Date(c.lastUpdated).getTime()))
  ).toISOString();

  return <CredentialCard service={service} credential={pseudoCredential} />;
};
