import { Intent } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import React from "react";
import styled from "styled-components";

import { GRID_SPACING } from "../../../../alpaca/base/grid";
import { Button } from "../../../../alpaca/components";
import { LogErrorMessage } from "../../../../errors";
import type { IPolicyWizardStep } from "../beta-policies-wizard-page";

// @see https://app.shortcut.com/vanta/story/12332/implement-styles-and-remove-inline-styling-for-the-policy-upload-flow
// To implement styles and remove inline styles
export const PoliciesWizardFooter: React.FC<{
  currStepIdx: number;
  allSteps: IPolicyWizardStep[];
  onNext?: Maybe<() => void>;
  onPrev?: Maybe<() => void>;
  onSubmit?: Maybe<() => void>;
}> = ({ currStepIdx, allSteps, onNext, onPrev, onSubmit }) => {
  const currStep = allSteps[currStepIdx];
  if (!isSome(currStep)) {
    LogErrorMessage(`Policies wizard step undefined for index: ${currStepIdx}`);
    throw new Error("Policies wizard step undefined");
  }
  const hasPrevious = isSome(onPrev) && currStepIdx > 0;
  const hasNext = isSome(onNext) && currStepIdx < allSteps.length - 1;
  // displaying submit and has next are mutually exclusive
  const hasSubmit = isSome(onSubmit) && currStepIdx === allSteps.length - 1;
  return (
    <StyledFooter>
      {hasPrevious && (
        <StyledButton large={true} onClick={onPrev!}>
          Previous
        </StyledButton>
      )}
      {hasNext && (
        <StyledButton intent={Intent.SUCCESS} large={true} onClick={onNext!}>
          Next
        </StyledButton>
      )}
      {hasSubmit && (
        <StyledButton intent={Intent.SUCCESS} large={true} onClick={onSubmit!}>
          Submit
        </StyledButton>
      )}
    </StyledFooter>
  );
};

const StyledButton = styled(Button)`
  margin-left: ${GRID_SPACING}px;
  margin-right: ${GRID_SPACING}px;
`;

const StyledFooter = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: ${4 * GRID_SPACING}px;
`;
