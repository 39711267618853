{
  "standardInfo": {
    "standard": "hipaa"
  },
  "controls": [
    {
      "id": "access-authorization",
      "name": "Access authorized",
      "description": "The company has implemented policies and procedures for granting access to electronic protected health information (for example, through access to a workstation, transaction, program, process, or other mechanism).",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "access-granted-infra"
        },
        {
          "id": "access-granted-versioncontrol"
        },
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "infra-access-requires-approval-config"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "access-requests"
        }
      ]
    },
    {
      "id": "access-control",
      "name": "Access controls applied",
      "description": "The company implements technical policies and procedures for electronic information systems that maintain electronic protected health information (ePHI) to allow access only to those persons or software programs that have been granted access rights.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "access-granted-infra"
        },
        {
          "id": "access-granted-versioncontrol"
        },
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "infra-access-requires-approval-config"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "access-requests"
        }
      ]
    },
    {
      "id": "access-control-and-validation-procedures",
      "name": "Access control and validation procedures implemented",
      "description": "The company has implemented procedures to control and validate a person's access to facilities based on their role or function, including visitor control, and control of access to software programs for testing and revision.",
      "categories": ["Physical"],
      "tests": [
        {
          "id": "approved-physical-security-policy-bsi-exists"
        },
        {
          "id": "approved-third-party-management-policy-bsi-exists"
        },
        {
          "id": "high-risk-vendors-have-business-associate-agreements"
        },
        {
          "id": "high-severity-vendor-compliance-reports"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "third-party-security-reviews"
        }
      ]
    },
    {
      "id": "access-established-reviewed-modified",
      "name": "Access established, reviewed and modified",
      "description": "The company has implemented policies and procedures that, based upon the entity’s access authorization policies, establish, document, review, and modify a user’s right of access to a workstation, transaction, program, or process.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "access-granted-infra"
        },
        {
          "id": "access-granted-versioncontrol"
        },
        {
          "id": "access-removed-infra"
        },
        {
          "id": "access-removed-versioncontrol"
        },
        {
          "id": "activity-log-blob-container-has-logging-azure"
        },
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "asana-account-linked-in-vanta"
        },
        {
          "id": "atlas-account-linked-in-vanta"
        },
        {
          "id": "azuredevops-account-linked-in-vanta"
        },
        {
          "id": "bitbucket-account-linked-in-vanta"
        },
        {
          "id": "clickup-account-linked-in-vanta"
        },
        {
          "id": "clubhouse-account-linked-in-vanta"
        },
        {
          "id": "datadog-account-linked-in-vanta"
        },
        {
          "id": "ephi-blob-containers-have-access-logs-azure"
        },
        {
          "id": "ephi-buckets-have-access-logs"
        },
        {
          "id": "github-account-linked-in-vanta"
        },
        {
          "id": "gitlab-account-linked-in-vanta"
        },
        {
          "id": "heroku-account-linked-in-vanta"
        },
        {
          "id": "hr-users-linked-in-vanta"
        },
        {
          "id": "infra-activity-tracked-config"
        },
        {
          "id": "infra-activity-tracked-config-azure"
        },
        {
          "id": "infra-activity-tracked-config-heroku"
        },
        {
          "id": "jamf-account-linked-in-vanta"
        },
        {
          "id": "jira-account-linked-in-vanta"
        },
        {
          "id": "knowbe4-account-linked-in-vanta"
        },
        {
          "id": "linear-account-linked-in-vanta"
        },
        {
          "id": "pivotaltracker-account-linked-in-vanta"
        },
        {
          "id": "slack-account-linked-in-vanta"
        },
        {
          "id": "snyk-account-linked-in-vanta"
        },
        {
          "id": "trello-account-linked-in-vanta"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "access-requests"
        },
        {
          "id": "access-reviews"
        }
      ]
    },
    {
      "id": "additional-breach-information",
      "name": "Additional breach information",
      "description": "A business associate provides the company, as a covered entity, with any other available information that the company is required to include in notification to the individual (described in 164.404(c)) at the time of the notification or promptly thereafter as information becomes available.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-incident-response-plan-bsi-exists"
        },
        {
          "id": "approved-incident-response-plan-hipaa-addendum-with-breach-notification-procedures-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "breach-reports"
        },
        {
          "id": "breach-reports-notifications"
        }
      ]
    },
    {
      "id": "application-and-data-criticality-analysis",
      "name": "Application and data criticality analyzed",
      "description": "The company assesses the relative criticality of specific applications and data in support of other contingency plan components.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-risk-management-policy-bsi-exists"
        },
        {
          "id": "hipaa-risk-assessment-questions-complete"
        },
        {
          "id": "hipaa-risk-register-questions-complete"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "risk-assessment-results"
        }
      ]
    },
    {
      "id": "audit-controls",
      "name": "Audit controls implemented",
      "description": "The company has implemented hardware, software, and/or procedural mechanisms that record and examine activity in information systems that contain or use electronic protected health information (ePHI).",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "activity-log-blob-container-has-logging-azure"
        },
        {
          "id": "ephi-blob-containers-have-access-logs-azure"
        },
        {
          "id": "ephi-buckets-have-access-logs"
        },
        {
          "id": "infra-activity-tracked-config"
        },
        {
          "id": "infra-activity-tracked-config-azure"
        },
        {
          "id": "infra-activity-tracked-config-heroku"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "authorization-supervision",
      "name": "Workforce authorized and/or supervised",
      "description": "The company has implemented procedures for the authorization and/or supervision of workforce members who work with electronic protected health information (ePHI) or in locations where it might be accessed.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "access-granted-infra"
        },
        {
          "id": "access-granted-versioncontrol"
        },
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "infra-access-requires-approval-config"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "access-requests"
        }
      ]
    },
    {
      "id": "automatic-log-off",
      "name": "Log-off automated",
      "description": "The company has implemented electronic procedures that terminate an electronic session after a predetermined time of inactivity.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "agent-macos-workstation-screenlock-enabled"
        },
        {
          "id": "agent-windows-workstation-screenlock-enabled"
        },
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "screenlock-configured-jamf"
        },
        {
          "id": "screenlock-configured-kandji"
        },
        {
          "id": "screenlock-configured-microsoft-endpoint-manager"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "application-session-timeout"
        }
      ]
    },
    {
      "id": "breach-identification-of-individuals",
      "name": "Breach notice identification of individuals",
      "description": "A business associate's breach notification includes, to the extent possible, the identification of each individual whose unsecured protected health information has been, or is reasonably believed by the business associate to have been, accessed, acquired, used, or disclosed during the breach.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-incident-response-plan-bsi-exists"
        },
        {
          "id": "approved-incident-response-plan-hipaa-addendum-with-breach-notification-procedures-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "breach-reports"
        },
        {
          "id": "breach-reports-notifications"
        }
      ]
    },
    {
      "id": "business-associate-agreements",
      "name": "Business associate agreements required",
      "description": "The company requires an agreement contract or other arrangement from business associates that meets administrative safeguards (§ 164.308(b)(3)) and the requirements of the organization (§ 164.314(a)(2)(i), (a)(2)(ii), or (a)(2)(iii)) as applicable.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-hipaa-compliance-policy-bsi-exists"
        },
        {
          "id": "employees-accepted-hipaa-compliance-policy-bsi"
        },
        {
          "id": "high-risk-vendors-have-business-associate-agreements"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "baa-template"
        }
      ]
    },
    {
      "id": "business-associate-agreements-comply",
      "name": "Business associate agreements comply",
      "description": "The company requires that business associate agreements include compliance with the applicable requirements.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-hipaa-compliance-policy-bsi-exists"
        },
        {
          "id": "employees-accepted-hipaa-compliance-policy-bsi"
        },
        {
          "id": "high-risk-vendors-have-business-associate-agreements"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "baa-template"
        }
      ]
    },
    {
      "id": "business-associate-agreements-security-incidents-reported",
      "name": "Business associate security incidents reported",
      "description": "The company requires business associates and subcontractors to report any security incident of which it becomes aware, including breaches of unsecured protected health information as required by the Breach Notification rules (§ 164.410).",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "baa-template"
        }
      ]
    },
    {
      "id": "business-associate-agreements-subcontractors",
      "name": "Business associate contracts with subcontractors established",
      "description": "The company, as a business associate, may permit a business associate that is a subcontractor to create, receive, maintain, or transmit electronic Protected Health Information (ePHI) on its behalf only if the company can obtain satisfactory assurances, in accordance with company policies, that the subcontractor will appropriately safeguard the information.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-hipaa-compliance-policy-bsi-exists"
        },
        {
          "id": "employees-accepted-hipaa-compliance-policy-bsi"
        },
        {
          "id": "high-risk-vendors-have-business-associate-agreements"
        },
        {
          "id": "vendor-management-records"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "baa-example"
        },
        {
          "id": "baa-template"
        }
      ]
    },
    {
      "id": "business-associate-agreements-with-subcontractors",
      "name": "Business associate agreements with subcontractors obtained",
      "description": "The company requires that the requirements described for § 164.314(a)(2)(i) and § 164.314(a)(2)(ii) apply to the agreement contract or other arrangements between a business associate and a subcontractor in the same manner as such requirements apply to agreement contracts or other arrangements between the company and the business associate.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-hipaa-compliance-policy-bsi-exists"
        },
        {
          "id": "employees-accepted-hipaa-compliance-policy-bsi"
        },
        {
          "id": "high-risk-vendors-have-business-associate-agreements"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "baa-template"
        }
      ]
    },
    {
      "id": "business-associate-agreements-with-vendors",
      "name": "Business associate contracts with vendors established",
      "description": "The company, as a covered entity, permits a business associate to create, receive, maintain, or transmit electronic Protected Health Information (ePHI) on the company's behalf only if it can obtain satisfactory assurances, in accordance with company policies, that the business associate will appropriately safeguard the information.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-hipaa-compliance-policy-bsi-exists"
        },
        {
          "id": "employees-accepted-hipaa-compliance-policy-bsi"
        },
        {
          "id": "high-risk-vendors-have-business-associate-agreements"
        },
        {
          "id": "vendor-management-records"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "baa-example"
        },
        {
          "id": "baa-template"
        }
      ]
    },
    {
      "id": "contingency-operations",
      "name": "Contingency operations established",
      "description": "The company has established (and implements as needed) procedures that allow facility access in support of restoration of lost data under the disaster recovery plan and emergency mode operations plan in the event of an emergency.",
      "categories": ["Physical"],
      "tests": [
        {
          "id": "approved-business-continuity-and-disaster-recovery-plan-bsi-exists"
        },
        {
          "id": "employees-accepted-business-continuity-and-disaster-recovery-plan-bsi"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "contingency-plan",
      "name": "Contingency plan established",
      "description": "The company has established (and implements as needed) policies and procedures for responding to an emergency or other occurrence (for example, fire, vandalism, system failure, and natural disaster) that damages systems that contain electronic Protected Health Information (ePHI).",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-business-continuity-and-disaster-recovery-plan-bsi-exists"
        },
        {
          "id": "employees-accepted-business-continuity-and-disaster-recovery-plan-bsi"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "disaster-recovery-tabletop"
        }
      ]
    },
    {
      "id": "contingency-plan-testing-and-revision-procedure",
      "name": "Contingency plan tested and revised",
      "description": "The company has implemented procedures for periodic testing and revision of contingency plans.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-business-continuity-and-disaster-recovery-plan-bsi-exists"
        },
        {
          "id": "employees-accepted-business-continuity-and-disaster-recovery-plan-bsi"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "data-restore-test"
        },
        {
          "id": "disaster-recovery-tabletop"
        }
      ]
    },
    {
      "id": "data-backup-and-storage",
      "name": "Data backed up and stored",
      "description": "The company creates a retrievable, exact copy of electronic protected health information (ePHI), when needed, before the movement of equipment.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-hipaa-compliance-policy-bsi-exists"
        },
        {
          "id": "aws-dynamodb-pitr-backups"
        },
        {
          "id": "azure-cosmosdb-backups"
        },
        {
          "id": "daily-app-database-backups-config-digitalocean"
        },
        {
          "id": "daily-database-backups-config"
        },
        {
          "id": "daily-database-backups-config-azure"
        },
        {
          "id": "daily-database-backups-config-digitalocean"
        },
        {
          "id": "daily-database-backups-config-gcp"
        },
        {
          "id": "daily-database-backups-config-heroku"
        },
        {
          "id": "employees-accepted-hipaa-compliance-policy-bsi"
        },
        {
          "id": "mongo-atlas-backups"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "data-backup-plan",
      "name": "Data backup plan implemented",
      "description": "The company has established and implements procedures to create and maintain retrievable exact copies of electronic protected health information (ePHI).",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-operations-security-policy-bsi-exists"
        },
        {
          "id": "aws-dynamodb-pitr-backups"
        },
        {
          "id": "azure-cosmosdb-backups"
        },
        {
          "id": "daily-app-database-backups-config-digitalocean"
        },
        {
          "id": "daily-database-backups-config"
        },
        {
          "id": "daily-database-backups-config-azure"
        },
        {
          "id": "daily-database-backups-config-digitalocean"
        },
        {
          "id": "daily-database-backups-config-gcp"
        },
        {
          "id": "daily-database-backups-config-heroku"
        },
        {
          "id": "mongo-atlas-backups"
        },
        {
          "id": "storage-buckets-are-versioned-config"
        },
        {
          "id": "storage-buckets-are-versioned-config-digitaloceanspaces"
        },
        {
          "id": "storage-buckets-are-versioned-config-gcp"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "data-encryption-decryption",
      "name": "Encryption and decryption controls implemented",
      "description": "The company has implemented a mechanism to encrypt and decrypt electronic protected health information (ePHI).",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-cryptography-policy-bsi-exists"
        },
        {
          "id": "aws-dynamodb-encryption"
        },
        {
          "id": "azure-cosmosdb-encryption"
        },
        {
          "id": "customer-data-encrypted-at-rest-config"
        },
        {
          "id": "customer-data-encrypted-at-rest-config-azure"
        },
        {
          "id": "customer-data-encrypted-at-rest-config-gcp"
        },
        {
          "id": "customer-data-encrypted-at-rest-config-heroku"
        },
        {
          "id": "digitalocean-redis-cluster-encryption"
        },
        {
          "id": "digitalocean-relational-database-encryption"
        },
        {
          "id": "gcp-bigtable-encryption"
        },
        {
          "id": "gcp-datastore-encryption"
        },
        {
          "id": "gcp-firestore-encryption"
        },
        {
          "id": "load-balancer-http-to-https"
        },
        {
          "id": "load-balancer-http-to-https-azure"
        },
        {
          "id": "load-balancer-http-to-https-digitalocean"
        },
        {
          "id": "mongo-atlas-cluster-encryption"
        },
        {
          "id": "ssl-good-ciphers"
        },
        {
          "id": "ssl-no-warnings"
        },
        {
          "id": "ssl-used-expiry"
        },
        {
          "id": "ssl-used-unittest"
        },
        {
          "id": "storage-buckets-are-encrypted-config"
        },
        {
          "id": "storage-buckets-are-encrypted-config-digitaloceanspaces"
        },
        {
          "id": "storage-buckets-are-encrypted-config-gcp"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "data-integrity",
      "name": "Data integrity maintained",
      "description": "The company has implemented policies and procedures to protect electronic protected health information (ePHI) from improper alteration or destruction.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "approved-information-security-policy-bsi-exists"
        },
        {
          "id": "employees-accepted-access-control-policy-bsi"
        },
        {
          "id": "employees-accepted-business-continuity-and-disaster-recovery-plan-bsi"
        },
        {
          "id": "employees-accepted-hipaa-compliance-policy-bsi"
        },
        {
          "id": "employees-accepted-information-security-policy-bsi"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "data-retention-time-limit",
      "name": "Data retention and time limit",
      "description": "The company retains the documentation of policies, procedures and action, activity or assessments as required by paragraph 316(b)(1) of the HIPAA rules for 6 years from the date of its creation or the date when it last was in effect, whichever is later.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-hipaa-compliance-policy-bsi-exists"
        },
        {
          "id": "employees-accepted-hipaa-compliance-policy-bsi"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "data-transmission-encrypted",
      "name": "Data transmission encrypted",
      "description": "The company has implemented a mechanism to encrypt electronic protected health information (ePHI) whenever deemed appropriate.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-cryptography-policy-bsi-exists"
        },
        {
          "id": "approved-data-management-policy-bsi-exists"
        },
        {
          "id": "aws-dynamodb-encryption"
        },
        {
          "id": "aws-s3-public-access-blocked"
        },
        {
          "id": "azure-cosmosdb-encryption"
        },
        {
          "id": "customer-data-encrypted-at-rest-config"
        },
        {
          "id": "customer-data-encrypted-at-rest-config-azure"
        },
        {
          "id": "customer-data-encrypted-at-rest-config-gcp"
        },
        {
          "id": "customer-data-encrypted-at-rest-config-heroku"
        },
        {
          "id": "digitalocean-app-relational-database-encryption"
        },
        {
          "id": "digitalocean-redis-cluster-encryption"
        },
        {
          "id": "digitalocean-relational-database-encryption"
        },
        {
          "id": "gcp-bigtable-encryption"
        },
        {
          "id": "gcp-datastore-encryption"
        },
        {
          "id": "gcp-firestore-encryption"
        },
        {
          "id": "load-balancer-http-to-https"
        },
        {
          "id": "load-balancer-http-to-https-azure"
        },
        {
          "id": "load-balancer-http-to-https-digitalocean"
        },
        {
          "id": "mongo-atlas-cluster-encryption"
        },
        {
          "id": "storage-buckets-are-encrypted-config"
        },
        {
          "id": "storage-buckets-are-encrypted-config-digitaloceanspaces"
        },
        {
          "id": "storage-buckets-are-encrypted-config-gcp"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "data-transmission-integrity",
      "name": "Data transmission integrity maintained",
      "description": "The company has implemented security measures to ensure that electronically transmitted electronic protected health information (ePHI) is not improperly modified without detection until disposed of.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-cryptography-policy-bsi-exists"
        },
        {
          "id": "approved-data-management-policy-bsi-exists"
        },
        {
          "id": "ephi-blob-containers-have-access-logs-azure"
        },
        {
          "id": "ephi-buckets-have-access-logs"
        },
        {
          "id": "load-balancer-http-to-https"
        },
        {
          "id": "load-balancer-http-to-https-azure"
        },
        {
          "id": "load-balancer-http-to-https-digitalocean"
        },
        {
          "id": "logs-centrally-stored-config"
        },
        {
          "id": "logs-centrally-stored-config-gcp"
        },
        {
          "id": "ssl-good-ciphers"
        },
        {
          "id": "ssl-no-warnings"
        },
        {
          "id": "ssl-used-expiry"
        },
        {
          "id": "ssl-used-unittest"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "data-transmission-security",
      "name": "Data transmission secured",
      "description": "The company has implemented technical security measures to guard against unauthorized access to electronic protected health information (ePHI) that is being transmitted over an electronic communications network.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-data-management-policy-bsi-exists"
        },
        {
          "id": "aws-ec2instances-ports-restricted"
        },
        {
          "id": "digitalocean-firewalls-ports-restricted"
        },
        {
          "id": "gcp-computeinstance-ports-restricted"
        },
        {
          "id": "load-balancer-http-to-https"
        },
        {
          "id": "load-balancer-http-to-https-azure"
        },
        {
          "id": "load-balancer-http-to-https-digitalocean"
        },
        {
          "id": "mfa-on-accounts-gsuite"
        },
        {
          "id": "mfa-on-accounts-infra"
        },
        {
          "id": "mfa-on-accounts-infra-root"
        },
        {
          "id": "mfa-on-accounts-okta"
        },
        {
          "id": "mfa-on-accounts-version-control"
        },
        {
          "id": "mfa-on-groups-version-control"
        },
        {
          "id": "ssl-good-ciphers"
        },
        {
          "id": "ssl-no-warnings"
        },
        {
          "id": "ssl-used-expiry"
        },
        {
          "id": "ssl-used-unittest"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "device-media-disposal",
      "name": "Device and media disposal policies implemented",
      "description": "The company has implemented policies and procedures to address the final disposition of electronic Protected Health Information (ePHI), and/or the hardware or electronic media on which it is stored.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-asset-management-policy-bsi-exists"
        },
        {
          "id": "approved-data-management-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "media-disposal"
        }
      ]
    },
    {
      "id": "device-media-movement",
      "name": "Device and media movement recorded",
      "description": "The company maintains a record of the movements of hardware and electronic media and any person responsible therefore.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-asset-management-policy-bsi-exists"
        },
        {
          "id": "approved-data-management-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "disaster-recovery-plan",
      "name": "Disaster recovery plan established",
      "description": "The company has established (and implements as needed) procedures to restore any loss of data.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-business-continuity-and-disaster-recovery-plan-bsi-exists"
        },
        {
          "id": "approved-operations-security-policy-bsi-exists"
        },
        {
          "id": "employees-accepted-business-continuity-and-disaster-recovery-plan-bsi"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "data-restore-test"
        }
      ]
    },
    {
      "id": "emergency-access-procedure",
      "name": "Emergency access procedures established",
      "description": "The company has established (and implements as needed) procedures for obtaining necessary electronic protected health information (ePHI) during an emergency.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-business-continuity-and-disaster-recovery-plan-bsi-exists"
        },
        {
          "id": "employees-accepted-business-continuity-and-disaster-recovery-plan-bsi"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "baa-template"
        }
      ]
    },
    {
      "id": "emergency-mode-operation-plan",
      "name": "Emergency mode operation plan established",
      "description": "The company has established (and implements as needed) procedures to enable the continuation of critical business processes for the protection and security of electronic Protected Health Information (ePHI) while operating in emergency mode.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-business-continuity-and-disaster-recovery-plan-bsi-exists"
        },
        {
          "id": "business-continuity-plan-policy"
        },
        {
          "id": "employees-accepted-business-continuity-and-disaster-recovery-plan-bsi"
        },
        {
          "id": "employees-accepted-business-continuity-plan"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "data-restore-test"
        },
        {
          "id": "disaster-recovery-tabletop"
        }
      ]
    },
    {
      "id": "establish-termination-procedures",
      "name": "Termination procedures established",
      "description": "The company has implement procedures for terminating access to electronic protected health information (ePHI) when the employment of a workforce member ends or as required when access is no longer appropriate.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "access-removed-infra"
        },
        {
          "id": "access-removed-versioncontrol"
        },
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "asana-account-access-removed-on-termination"
        },
        {
          "id": "aws-account-access-removed-on-termination"
        },
        {
          "id": "azuredevops-account-access-removed-on-termination"
        },
        {
          "id": "bitbucket-account-access-removed-on-termination"
        },
        {
          "id": "clickup-account-access-removed-on-termination"
        },
        {
          "id": "clubhouse-account-access-removed-on-termination"
        },
        {
          "id": "datadog-account-access-removed-on-termination"
        },
        {
          "id": "github-account-access-removed-on-termination"
        },
        {
          "id": "gitlab-account-access-removed-on-termination"
        },
        {
          "id": "heroku-account-access-removed-on-termination"
        },
        {
          "id": "jamf-account-access-removed-on-termination"
        },
        {
          "id": "jira-account-access-removed-on-termination"
        },
        {
          "id": "knowbe4-account-access-removed-on-termination"
        },
        {
          "id": "linear-account-access-removed-on-termination"
        },
        {
          "id": "mongoatlas-account-access-removed-on-termination"
        },
        {
          "id": "pivotaltracker-account-access-removed-on-termination"
        },
        {
          "id": "slack-account-access-removed-on-termination"
        },
        {
          "id": "snyk-account-access-removed-on-termination"
        },
        {
          "id": "trello-account-access-removed-on-termination"
        },
        {
          "id": "vanta-users-offboarded-within-sla"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "employee-exit-interview"
        },
        {
          "id": "policy-exists-termination"
        }
      ]
    },
    {
      "id": "facility-access-controls",
      "name": "Facility access controls implemented",
      "description": "The company has implemented policies and procedures to limit physical access to its electronic information systems and the facility or facilities in which they are housed while ensuring that properly authorized access is allowed.",
      "categories": ["Physical"],
      "tests": [
        {
          "id": "approved-physical-security-policy-bsi-exists"
        },
        {
          "id": "approved-third-party-management-policy-bsi-exists"
        },
        {
          "id": "high-risk-vendors-have-business-associate-agreements"
        },
        {
          "id": "high-severity-vendor-compliance-reports"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "third-party-security-reviews"
        }
      ]
    },
    {
      "id": "facility-security-plan",
      "name": "Facility security plan implemented",
      "description": "The company has implemented policies and procedures to safeguard the facility and the equipment therein from unauthorized physical access, tampering, and theft.",
      "categories": ["Physical"],
      "tests": [
        {
          "id": "approved-physical-security-policy-bsi-exists"
        },
        {
          "id": "approved-third-party-management-policy-bsi-exists"
        },
        {
          "id": "high-risk-vendors-have-business-associate-agreements"
        },
        {
          "id": "high-severity-vendor-compliance-reports"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "third-party-security-reviews"
        }
      ]
    },
    {
      "id": "group-health-plan-controls",
      "name": "Group health plan information controlled",
      "description": "The company has implemented administrative, physical, and technical safeguards that reasonably and appropriately protect the confidentiality, integrity, and availability of the electronic protected health information (ePHI) that it creates, receives, maintains, or transmits on behalf of the group health plan.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "approved-asset-management-policy-bsi-exists"
        },
        {
          "id": "approved-business-continuity-and-disaster-recovery-plan-bsi-exists"
        },
        {
          "id": "approved-cryptography-policy-bsi-exists"
        },
        {
          "id": "approved-data-management-policy-bsi-exists"
        },
        {
          "id": "approved-human-resource-security-policy-bsi-exists"
        },
        {
          "id": "approved-incident-response-plan-bsi-exists"
        },
        {
          "id": "approved-information-security-policy-bsi-exists"
        },
        {
          "id": "approved-information-security-roles-and-responsibilities-bsi-exists"
        },
        {
          "id": "approved-operations-security-policy-bsi-exists"
        },
        {
          "id": "approved-physical-security-policy-bsi-exists"
        },
        {
          "id": "approved-risk-management-policy-bsi-exists"
        },
        {
          "id": "approved-secure-development-policy-bsi-exists"
        },
        {
          "id": "approved-third-party-management-policy-bsi-exists"
        },
        {
          "id": "employees-accepted-business-continuity-and-disaster-recovery-plan-bsi"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "group-health-plan-information-protection",
      "name": "Group health plan information protected",
      "description": "The company ensures that any agent to whom it provides this information agrees to implement reasonable and appropriate security measures to protect the information.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-hipaa-compliance-policy-bsi-exists"
        },
        {
          "id": "employees-accepted-hipaa-compliance-policy-bsi"
        },
        {
          "id": "high-severity-vendor-compliance-reports"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "baa-example"
        },
        {
          "id": "baa-template"
        }
      ]
    },
    {
      "id": "group-health-plan-security-incidents",
      "name": "Group health plan security incidents reported",
      "description": "The company reports to the group health plan any security incident of which it becomes aware.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-incident-response-plan-bsi-exists"
        },
        {
          "id": "approved-incident-response-plan-hipaa-addendum-with-breach-notification-procedures-bsi-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "hipaa-security-officer",
      "name": "HIPAA security officer",
      "description": "The company has implemented policies and procedures to address the final disposition of electronic Protected Health Information (ePHI), and/or the hardware or electronic media on which it is stored.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "hipaa-security-officer-active-employee"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "information-access-management",
      "name": "Information access managed",
      "description": "The company has implemented policies and procedures for authorizing access to electronic protected health information (ePHI) that are consistent with the applicable privacy rule requirements (subpart E).",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-access-control-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "information-system-activity-reviewed",
      "name": "Information system activity reviewed",
      "description": "The company has implemented procedures to regularly review records of information system activity, such as audit logs, access reports, and security incident tracking reports.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "approved-incident-response-plan-bsi-exists"
        },
        {
          "id": "approved-incident-response-plan-hipaa-addendum-with-breach-notification-procedures-bsi-exists"
        },
        {
          "id": "approved-operations-security-policy-bsi-exists"
        },
        {
          "id": "ephi-blob-containers-have-access-logs-azure"
        },
        {
          "id": "ephi-buckets-have-access-logs"
        },
        {
          "id": "security-issues-assigned-records"
        },
        {
          "id": "security-issues-prioritized-records"
        },
        {
          "id": "security-issues-tracked-records"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "access-reviews"
        },
        {
          "id": "incident-report-example"
        },
        {
          "id": "system-activity-log-settings"
        }
      ]
    },
    {
      "id": "log-in-monitoring",
      "name": "Log-ins monitored",
      "description": "The company has implemented procedures for monitoring log-in attempts and reporting discrepancies.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "activity-log-blob-container-has-logging-azure"
        },
        {
          "id": "ephi-blob-containers-have-access-logs-azure"
        },
        {
          "id": "ephi-buckets-have-access-logs"
        },
        {
          "id": "infra-activity-tracked-config"
        },
        {
          "id": "infra-activity-tracked-config-azure"
        },
        {
          "id": "infra-activity-tracked-config-heroku"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "access-requests-denied-log"
        }
      ]
    },
    {
      "id": "maintenance-records",
      "name": "Maintenance records maintained",
      "description": "The company has implemented policies and procedures to document repairs and modifications to the physical components of a facility which are related to security (for example, hardware, walls, doors, and locks).",
      "categories": ["Physical"],
      "tests": [
        {
          "id": "approved-physical-security-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "mechanism-authenticate-information",
      "name": "Mechanism to authenticate ePHI implemented",
      "description": "The company has implemented electronic mechanisms to corroborate that electronic protected health information (ePHI) has not been altered or destroyed in an unauthorized manner.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "container-packages-checked-for-vulnerabilities-records-closed-aws"
        },
        {
          "id": "container-packages-checked-for-vulnerabilities-records-closed-azure"
        },
        {
          "id": "container-packages-checked-for-vulnerabilities-records-closed-gcp"
        },
        {
          "id": "infra-activity-tracked-config"
        },
        {
          "id": "infra-activity-tracked-config-azure"
        },
        {
          "id": "infra-activity-tracked-config-heroku"
        },
        {
          "id": "infra-packages-checked-for-vulnerabilities-records-closed"
        },
        {
          "id": "packages-checked-for-vulnerabilities-records-closed-snyk"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "vulnerabilities-remediated-sample"
        },
        {
          "id": "vulnerability-scan"
        }
      ]
    },
    {
      "id": "media-re-use",
      "name": "Media re-use procedures implemented",
      "description": "The company has implemented procedures for removal of electronic protected health information (ePHI) from electronic media before the media are made available for re-use.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-asset-management-policy-bsi-exists"
        },
        {
          "id": "approved-data-management-policy-bsi-exists"
        },
        {
          "id": "approved-hipaa-compliance-policy-bsi-exists"
        },
        {
          "id": "employees-accepted-hipaa-compliance-policy-bsi"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "media-removal",
      "name": "Media removal policies implemented",
      "description": "The company has implemented policies and procedures that govern the receipt and removal of hardware and electronic media that contain electronic Protected Health Information (ePHI) into and out of a facility, and the movement of these items within the facility.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-asset-management-policy-bsi-exists"
        },
        {
          "id": "approved-data-management-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "notification-of-breach",
      "name": "Notification of breach",
      "description": "The company, as a covered entity, requires all business associates, following the discovery of a breach of unsecured protected health information, to notify the company of such breach.\n\nA breach is treated as discovered by a business associate as of the first day on which such breach is known to the business associate or, by exercising reasonable diligence, would have been known to the business associate. A business associate shall be deemed to have knowledge of a breach if the breach is known, or by exercising reasonable due diligence would have been known, to any person, other than the person committing the breach, who is an employee, officer, or other agents of the business associate (determined in accordance with the Federal common law of agency).",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-incident-response-plan-bsi-exists"
        },
        {
          "id": "approved-incident-response-plan-hipaa-addendum-with-breach-notification-procedures-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "breach-reports"
        },
        {
          "id": "breach-reports-notifications"
        }
      ]
    },
    {
      "id": "password-management",
      "name": "Passwords managed",
      "description": "The company has implemented procedures for creating, changing, and safeguarding passwords.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "approved-information-security-policy-bsi-exists"
        },
        {
          "id": "password-manager-records"
        },
        {
          "id": "password-manager-records-jamf"
        },
        {
          "id": "password-manager-records-kandji"
        },
        {
          "id": "password-manager-records-microsoft-endpoint-manager"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "person-or-entity-authentication",
      "name": "Person or entities authenticated",
      "description": "The company has implemented procedures to verify that a person or entity seeking access to electronic protected health information is the one claimed.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "bitbucket-account-mfa-enabled"
        },
        {
          "id": "github-account-mfa-enabled"
        },
        {
          "id": "heroku-account-mfa-enabled"
        },
        {
          "id": "mfa-on-accounts-gsuite"
        },
        {
          "id": "mfa-on-accounts-infra"
        },
        {
          "id": "mfa-on-accounts-infra-root"
        },
        {
          "id": "mfa-on-accounts-okta"
        },
        {
          "id": "mfa-on-accounts-version-control"
        },
        {
          "id": "mfa-on-groups-version-control"
        },
        {
          "id": "office-user-mfa-enabled"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "policies-procedures-activity-documented",
      "name": "Policies and procedures activity documented",
      "description": "Where an action, activity or assessment is required (described in § 164.316) to be documented, the company maintains a written (which may be electronic) record of the action, activity, or assessment.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "approved-data-management-policy-bsi-exists"
        },
        {
          "id": "approved-hipaa-compliance-policy-bsi-exists"
        },
        {
          "id": "approved-information-security-policy-bsi-exists"
        },
        {
          "id": "employees-accepted-hipaa-compliance-policy-bsi"
        },
        {
          "id": "hipaa-risk-assessment-questions-complete"
        },
        {
          "id": "hipaa-risk-register-questions-complete"
        },
        {
          "id": "logs-retained-for-twelve-months-config"
        },
        {
          "id": "logs-retained-for-twelve-months-config-gcp"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "risk-assessment-results"
        }
      ]
    },
    {
      "id": "policies-procedures-available",
      "name": "Policies and procedures available",
      "description": "The company makes documentation available to those persons responsible for implementing the procedures to which the documentation pertains.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "acceptable-use-policy-agree"
        },
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "approved-asset-management-policy-bsi-exists"
        },
        {
          "id": "approved-business-continuity-and-disaster-recovery-plan-bsi-exists"
        },
        {
          "id": "approved-cryptography-policy-bsi-exists"
        },
        {
          "id": "approved-data-management-policy-bsi-exists"
        },
        {
          "id": "approved-hipaa-compliance-policy-bsi-exists"
        },
        {
          "id": "approved-human-resource-security-policy-bsi-exists"
        },
        {
          "id": "approved-incident-response-plan-bsi-exists"
        },
        {
          "id": "approved-incident-response-plan-hipaa-addendum-with-breach-notification-procedures-bsi-exists"
        },
        {
          "id": "approved-information-security-policy-bsi-exists"
        },
        {
          "id": "approved-information-security-roles-and-responsibilities-bsi-exists"
        },
        {
          "id": "approved-operations-security-policy-bsi-exists"
        },
        {
          "id": "approved-physical-security-policy-bsi-exists"
        },
        {
          "id": "approved-risk-management-policy-bsi-exists"
        },
        {
          "id": "approved-secure-development-policy-bsi-exists"
        },
        {
          "id": "approved-third-party-management-policy-bsi-exists"
        },
        {
          "id": "employees-accepted-access-control-policy-bsi"
        },
        {
          "id": "employees-accepted-asset-management-policy-bsi"
        },
        {
          "id": "employees-accepted-business-continuity-and-disaster-recovery-plan-bsi"
        },
        {
          "id": "employees-accepted-code-of-conduct-bsi"
        },
        {
          "id": "employees-accepted-cryptography-policy-bsi"
        },
        {
          "id": "employees-accepted-data-management-policy-bsi"
        },
        {
          "id": "employees-accepted-hipaa-compliance-policy-bsi"
        },
        {
          "id": "employees-accepted-human-resource-security-policy-bsi"
        },
        {
          "id": "employees-accepted-incident-response-plan-bsi"
        },
        {
          "id": "employees-accepted-information-security-policy-bsi"
        },
        {
          "id": "employees-accepted-information-security-roles-and-responsibilities-bsi"
        },
        {
          "id": "employees-accepted-operations-security-policy-bsi"
        },
        {
          "id": "employees-accepted-physical-security-policy-bsi"
        },
        {
          "id": "employees-accepted-risk-management-policy-bsi"
        },
        {
          "id": "employees-accepted-secure-development-policy-bsi"
        },
        {
          "id": "employees-accepted-third-party-management-policy-bsi"
        },
        {
          "id": "security-policies-accepted"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "privacy-policy"
        }
      ]
    },
    {
      "id": "policies-procedures-created",
      "name": "Policies and procedures created",
      "description": "The company, as a covered entity or business associate, must, in accordance with the HIPAA security rule (§ 164.306), implement reasonable and appropriate policies and procedures to comply with implementation specifications, or other requirements, taking into account those factors specified in the HIPAA security general rules (§ 164.306(b)(2)(i), (ii), (iii), and (iv)). \n\nThis standard is not to be construed to permit or excuse an action that violates any other standard, implementation specification, or other requirements (§164.316).\n\nA covered entity or business associate may change its policies and procedures at any time, provided that the changes are documented and are implemented in accordance with this subpart.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "acceptable-use-policy-policies"
        },
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "approved-asset-management-policy-bsi-exists"
        },
        {
          "id": "approved-business-continuity-and-disaster-recovery-plan-bsi-exists"
        },
        {
          "id": "approved-cryptography-policy-bsi-exists"
        },
        {
          "id": "approved-data-management-policy-bsi-exists"
        },
        {
          "id": "approved-hipaa-compliance-policy-bsi-exists"
        },
        {
          "id": "approved-human-resource-security-policy-bsi-exists"
        },
        {
          "id": "approved-incident-response-plan-bsi-exists"
        },
        {
          "id": "approved-incident-response-plan-hipaa-addendum-with-breach-notification-procedures-bsi-exists"
        },
        {
          "id": "approved-information-security-policy-bsi-exists"
        },
        {
          "id": "approved-information-security-roles-and-responsibilities-bsi-exists"
        },
        {
          "id": "approved-operations-security-policy-bsi-exists"
        },
        {
          "id": "approved-physical-security-policy-bsi-exists"
        },
        {
          "id": "approved-risk-management-policy-bsi-exists"
        },
        {
          "id": "approved-secure-development-policy-bsi-exists"
        },
        {
          "id": "approved-third-party-management-policy-bsi-exists"
        },
        {
          "id": "change-management-policy-policies"
        },
        {
          "id": "credential-keys-managed-policy"
        },
        {
          "id": "cryptography-policies-policies"
        },
        {
          "id": "customer-data-policies-database"
        },
        {
          "id": "customer-data-policies-policies"
        },
        {
          "id": "data-classification-policy-policies"
        },
        {
          "id": "data-protection-policy-policies"
        },
        {
          "id": "employee-disclosure-process-policies"
        },
        {
          "id": "employees-accepted-business-continuity-and-disaster-recovery-plan-bsi"
        },
        {
          "id": "employees-accepted-change-management-policy"
        },
        {
          "id": "employees-accepted-data-classification-policy"
        },
        {
          "id": "employees-accepted-data-delete-policy"
        },
        {
          "id": "employees-accepted-data-protection-policy"
        },
        {
          "id": "employees-accepted-hipaa-compliance-policy-bsi"
        },
        {
          "id": "employees-accepted-information-security-policy"
        },
        {
          "id": "employees-accepted-responsible-disclosure-policy"
        },
        {
          "id": "employees-accepted-vendor-management-policy"
        },
        {
          "id": "firewall-endpoint-policies"
        },
        {
          "id": "mfa-on-accounts-policy"
        },
        {
          "id": "responsible-disclosure-policy-policies"
        },
        {
          "id": "screensaver-lock-policies"
        },
        {
          "id": "secure-data-delete-policies"
        },
        {
          "id": "security-team-has-communication-channel-to-the-CEO-policies"
        },
        {
          "id": "security-team-policies"
        },
        {
          "id": "sla-for-security-bugs-policies"
        },
        {
          "id": "vendor-management-policy"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "policies-procedures-documented",
      "name": "Policies and procedures documented",
      "description": "The company maintains the policies and procedures implemented to comply with the HIPAA security safeguards in written (which may be electronic) form.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "acceptable-use-policy-policies"
        },
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "approved-asset-management-policy-bsi-exists"
        },
        {
          "id": "approved-business-continuity-and-disaster-recovery-plan-bsi-exists"
        },
        {
          "id": "approved-cryptography-policy-bsi-exists"
        },
        {
          "id": "approved-data-management-policy-bsi-exists"
        },
        {
          "id": "approved-hipaa-compliance-policy-bsi-exists"
        },
        {
          "id": "approved-human-resource-security-policy-bsi-exists"
        },
        {
          "id": "approved-incident-response-plan-bsi-exists"
        },
        {
          "id": "approved-incident-response-plan-hipaa-addendum-with-breach-notification-procedures-bsi-exists"
        },
        {
          "id": "approved-information-security-policy-bsi-exists"
        },
        {
          "id": "approved-information-security-roles-and-responsibilities-bsi-exists"
        },
        {
          "id": "approved-operations-security-policy-bsi-exists"
        },
        {
          "id": "approved-physical-security-policy-bsi-exists"
        },
        {
          "id": "approved-risk-management-policy-bsi-exists"
        },
        {
          "id": "approved-secure-development-policy-bsi-exists"
        },
        {
          "id": "approved-third-party-management-policy-bsi-exists"
        },
        {
          "id": "change-management-policy-policies"
        },
        {
          "id": "credential-keys-managed-policy"
        },
        {
          "id": "cryptography-policies-policies"
        },
        {
          "id": "customer-data-policies-database"
        },
        {
          "id": "customer-data-policies-policies"
        },
        {
          "id": "data-classification-policy-policies"
        },
        {
          "id": "data-protection-policy-policies"
        },
        {
          "id": "employee-disclosure-process-policies"
        },
        {
          "id": "employees-accepted-business-continuity-and-disaster-recovery-plan-bsi"
        },
        {
          "id": "employees-accepted-change-management-policy"
        },
        {
          "id": "employees-accepted-cryptography-policy"
        },
        {
          "id": "employees-accepted-data-classification-policy"
        },
        {
          "id": "employees-accepted-data-delete-policy"
        },
        {
          "id": "employees-accepted-data-protection-policy"
        },
        {
          "id": "employees-accepted-hipaa-compliance-policy-bsi"
        },
        {
          "id": "employees-accepted-information-security-policy"
        },
        {
          "id": "employees-accepted-responsible-disclosure-policy"
        },
        {
          "id": "employees-accepted-vendor-management-policy"
        },
        {
          "id": "firewall-endpoint-policies"
        },
        {
          "id": "mfa-on-accounts-policy"
        },
        {
          "id": "responsible-disclosure-policy-policies"
        },
        {
          "id": "screensaver-lock-policies"
        },
        {
          "id": "secure-data-delete-policies"
        },
        {
          "id": "security-team-has-communication-channel-to-the-CEO-policies"
        },
        {
          "id": "security-team-policies"
        },
        {
          "id": "sla-for-security-bugs-policies"
        },
        {
          "id": "vendor-management-policy"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "policies-procedures-updated",
      "name": "Policies and procedures updated",
      "description": "The company reviews documentation periodically, and updates as needed, in response to environmental or operational changes affecting the security of electronic protected health information (ePHI).",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "acceptable-use-policy-policies"
        },
        {
          "id": "approved-hipaa-compliance-policy-bsi-exists"
        },
        {
          "id": "approved-incident-response-plan-hipaa-addendum-with-breach-notification-procedures-bsi-exists"
        },
        {
          "id": "change-management-policy-policies"
        },
        {
          "id": "credential-keys-managed-policy"
        },
        {
          "id": "cryptography-policies-policies"
        },
        {
          "id": "customer-data-policies-database"
        },
        {
          "id": "customer-data-policies-policies"
        },
        {
          "id": "data-classification-policy-policies"
        },
        {
          "id": "data-protection-policy-policies"
        },
        {
          "id": "employee-disclosure-process-policies"
        },
        {
          "id": "employees-accepted-change-management-policy"
        },
        {
          "id": "employees-accepted-cryptography-policy"
        },
        {
          "id": "employees-accepted-data-classification-policy"
        },
        {
          "id": "employees-accepted-data-delete-policy"
        },
        {
          "id": "employees-accepted-data-protection-policy"
        },
        {
          "id": "employees-accepted-hipaa-compliance-policy-bsi"
        },
        {
          "id": "employees-accepted-information-security-policy"
        },
        {
          "id": "employees-accepted-responsible-disclosure-policy"
        },
        {
          "id": "employees-accepted-vendor-management-policy"
        },
        {
          "id": "firewall-endpoint-policies"
        },
        {
          "id": "mfa-on-accounts-policy"
        },
        {
          "id": "responsible-disclosure-policy-policies"
        },
        {
          "id": "screensaver-lock-policies"
        },
        {
          "id": "secure-data-delete-policies"
        },
        {
          "id": "security-team-has-communication-channel-to-the-CEO-policies"
        },
        {
          "id": "security-team-policies"
        },
        {
          "id": "sla-for-security-bugs-policies"
        },
        {
          "id": "vendor-management-policy"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "protection-from-malicious-software",
      "name": "Malicious software protection implemented",
      "description": "The company has implemented procedures for guarding against, detecting, and reporting malicious software.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-operations-security-policy-bsi-exists"
        },
        {
          "id": "approved-vulnerability-management-policy-exists"
        },
        {
          "id": "employees-accepted-vulnerability-management-policy"
        },
        {
          "id": "malware-detection-workstations"
        },
        {
          "id": "malware-detection-workstations-jamf"
        },
        {
          "id": "malware-detection-workstations-kandji"
        },
        {
          "id": "security-vulnerabilities-monitored-laptops"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "response-and-reporting",
      "name": "Security incidents identified and reported",
      "description": "The company identifies and responds to suspected or known security incidents, mitigates, to the extent practicable, harmful effects of security incidents that are known to the company, and documents security incidents and their outcomes.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-incident-response-plan-bsi-exists"
        },
        {
          "id": "approved-incident-response-plan-hipaa-addendum-with-breach-notification-procedures-bsi-exists"
        },
        {
          "id": "approved-information-security-policy-bsi-exists"
        },
        {
          "id": "security-issues-assigned-records"
        },
        {
          "id": "security-issues-prioritized-records"
        },
        {
          "id": "security-issues-tracked-records"
        },
        {
          "id": "sla-for-security-bugs-policies"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "incident-report-example"
        }
      ]
    },
    {
      "id": "risk-analysis",
      "name": "Risks analyzed",
      "description": "The company has conducted an accurate and thorough assessment of the potential risks and vulnerabilities to the confidentiality, integrity, and availability of electronic protected health information held in its system.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-risk-management-policy-bsi-exists"
        },
        {
          "id": "employees-accepted-risk-assessment-program"
        },
        {
          "id": "hipaa-risk-assessment-questions-complete"
        },
        {
          "id": "hipaa-risk-register-questions-complete"
        },
        {
          "id": "risk-assessment-policy-policies"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "risk-management",
      "name": "Risks managed",
      "description": "The company has implemented security measures sufficient to reduce risks and vulnerabilities to a reasonable and appropriate level to comply with the HIPAA security general requirements (described in § 164.306(a)).",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-risk-management-policy-bsi-exists"
        },
        {
          "id": "hipaa-risk-assessment-questions-complete"
        },
        {
          "id": "hipaa-risk-register-questions-complete"
        },
        {
          "id": "risk-register-scenario-tasks-created"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "sanction-policy",
      "name": "Sanction policy applied",
      "description": "The company applies appropriate sanctions against workforce members who fail to comply with the security policies and procedures.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-hipaa-compliance-policy-bsi-exists"
        },
        {
          "id": "approved-information-security-policy-bsi-exists"
        },
        {
          "id": "employees-accepted-hipaa-compliance-policy-bsi"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "security-awareness-training",
      "name": "Security awareness training implemented",
      "description": "The company has implemented a security awareness and training program for all members of its workforce, including management.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-human-resource-security-policy-bsi-exists"
        },
        {
          "id": "hipaa-security-training-records"
        },
        {
          "id": "hipaa-security-training-tracked-records"
        },
        {
          "id": "security-training-policies"
        },
        {
          "id": "security-training-records"
        },
        {
          "id": "security-training-tracked-records"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "sat-completion-bulk"
        }
      ]
    },
    {
      "id": "security-controls-evaluation",
      "name": "Security controls evaluated",
      "description": "The company performs a periodic technical and nontechnical evaluation, based initially upon the HIPAA security rule, and subsequently, in response to environmental or operational changes affecting the security of electronic Protected Health Information (ePHI), establishes the extent to which the company's security policies and procedures meet the requirements of the HIPAA security rule (subpart C).",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-risk-management-policy-bsi-exists"
        },
        {
          "id": "container-packages-checked-for-vulnerabilities-records-closed-aws"
        },
        {
          "id": "container-packages-checked-for-vulnerabilities-records-closed-azure"
        },
        {
          "id": "container-packages-checked-for-vulnerabilities-records-closed-gcp"
        },
        {
          "id": "hipaa-risk-assessment-questions-complete"
        },
        {
          "id": "hipaa-risk-register-questions-complete"
        },
        {
          "id": "infra-packages-checked-for-vulnerabilities-records-closed"
        },
        {
          "id": "packages-checked-for-vulnerabilities-records-closed-snyk"
        },
        {
          "id": "penetration-tests-records"
        },
        {
          "id": "security-issues-assigned-records"
        },
        {
          "id": "security-issues-prioritized-records"
        },
        {
          "id": "security-issues-tracked-records"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "risk-assessment-results"
        },
        {
          "id": "vulnerabilities-remediated-sample"
        },
        {
          "id": "vulnerability-scan"
        }
      ]
    },
    {
      "id": "security-incident-procedures",
      "name": "Security incident procedures implemented",
      "description": "The company has implemented policies and procedures to address security incidents.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-incident-response-plan-bsi-exists"
        },
        {
          "id": "approved-incident-response-plan-hipaa-addendum-with-breach-notification-procedures-bsi-exists"
        },
        {
          "id": "approved-information-security-policy-bsi-exists"
        },
        {
          "id": "employees-accepted-incident-response-plan-hipaa-addendum-with-breach-notification-procedures-bsi"
        },
        {
          "id": "security-issues-assigned-records"
        },
        {
          "id": "security-issues-prioritized-records"
        },
        {
          "id": "security-issues-tracked-records"
        },
        {
          "id": "sla-for-security-bugs-records"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "incident-report-example"
        }
      ]
    },
    {
      "id": "security-management-process",
      "name": "Security violations managed",
      "description": "The company has implemented policies and procedures to prevent, detect, contain, and correct security violations.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-incident-response-plan-bsi-exists"
        },
        {
          "id": "approved-incident-response-plan-hipaa-addendum-with-breach-notification-procedures-bsi-exists"
        },
        {
          "id": "approved-information-security-policy-bsi-exists"
        },
        {
          "id": "approved-operations-security-policy-bsi-exists"
        },
        {
          "id": "employees-accepted-incident-response-plan-hipaa-addendum-with-breach-notification-procedures-bsi"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "security-reminders",
      "name": "Security reminders updated",
      "description": "The company conducts periodic security updates.",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "public-change-log"
        },
        {
          "id": "system-update-internal-communication"
        }
      ]
    },
    {
      "id": "security-responsibility",
      "name": "Security responsibility assigned",
      "description": "The company has identified a security official to be responsible for the development and implementation of the policies and procedures required by the HIPAA Security rules for the company.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-hipaa-compliance-policy-bsi-exists"
        },
        {
          "id": "employees-accepted-hipaa-compliance-policy-bsi"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "subcontractor-business-associate-agreements-enforced",
      "name": "Subcontractor agreements enforced",
      "description": "The company, in accordance with administrative safeguards (§ 164.308(b)(2)), ensures that any subcontractors that create, receive, maintain, or transmit electronic Protected Health Information (ePHI) on behalf of the business associate agree to comply with the applicable requirements by entering into an agreement contract or other arrangement that complies with organization requirements.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-hipaa-compliance-policy-bsi-exists"
        },
        {
          "id": "employees-accepted-hipaa-compliance-policy-bsi"
        },
        {
          "id": "high-risk-vendors-have-business-associate-agreements"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "baa-example"
        },
        {
          "id": "baa-template"
        }
      ]
    },
    {
      "id": "timeliness-of-breach-notification",
      "name": "Timeliness of breach notification",
      "description": "Except in cases of a law enforcement delay ( § 164.412), a business associate provides the breach notification required by the company Breach Notification policy ( § 164.410(a)) without unreasonable delay and in no case later than 60 calendar days after the discovery of a breach.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-incident-response-plan-bsi-exists"
        },
        {
          "id": "approved-incident-response-plan-hipaa-addendum-with-breach-notification-procedures-bsi-exists"
        },
        {
          "id": "employees-accepted-incident-response-plan-hipaa-addendum-with-breach-notification-procedures-bsi"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "breach-reports"
        },
        {
          "id": "breach-reports-notifications"
        }
      ]
    },
    {
      "id": "unique-user-identification",
      "name": "Unique user identified",
      "description": "The company assigns a unique name and/or number for identifying and tracking user identity.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "infra-unique-accounts-group"
        },
        {
          "id": "infra-unique-accounts-records-email"
        },
        {
          "id": "infra-unique-accounts-records-infra"
        },
        {
          "id": "infra-unique-accounts-records-versioncontrol"
        },
        {
          "id": "infra-unique-accounts-roles"
        },
        {
          "id": "infra-unique-accounts-roles-gcp"
        },
        {
          "id": "infra-unique-accounts-roles-heroku"
        },
        {
          "id": "infra-unique-accounts-user"
        },
        {
          "id": "no-allusers-policy-bindings-gcp"
        },
        {
          "id": "no-nonindividual-primitive-roles-gcp"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "workforce-clearance-procedure",
      "name": "Workforce clearance procedures implemented",
      "description": "The company has implemented procedures to determine that the access of a workforce member to electronic protected health information (ePHI) is appropriate.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "access-granted-infra"
        },
        {
          "id": "access-granted-versioncontrol"
        },
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "infra-access-requires-approval-config"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "access-requests"
        },
        {
          "id": "access-reviews"
        }
      ]
    },
    {
      "id": "workforce-security",
      "name": "Workforce security implemented",
      "description": "The company has implemented policies and procedures to ensure that all members of its workforce have appropriate access to electronic protected health information (ePHI), and to prevent those workforce members who do not have access from obtaining access to ePHI.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "access-granted-infra"
        },
        {
          "id": "access-granted-versioncontrol"
        },
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "infra-access-requires-approval-config"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "access-requests"
        }
      ]
    },
    {
      "id": "workstation-security-implemented",
      "name": "Workstation security implemented",
      "description": "The company has implemented physical safeguards for all workstations that access electronic protected health information (ePHI), to restrict access to authorized users.",
      "categories": ["Physical"],
      "tests": [
        {
          "id": "approved-hipaa-workstation-security-policy-bsi-exists"
        },
        {
          "id": "employees-accepted-hipaa-workstation-security-policy-bsi"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "workstation-security-policies",
      "name": "Workstation security policies implemented",
      "description": "The company has implemented policies and procedures that specify the proper functions to be performed, the manner in which those functions are to be performed, and the physical attributes of the surroundings of a specific workstation or class of workstation that can access electronic Protected Health Information (ePHI).",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-hipaa-workstation-security-policy-bsi-exists"
        },
        {
          "id": "employees-accepted-hipaa-workstation-security-policy-bsi"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "written-business-associate-agreements",
      "name": "Business associate agreements documented",
      "description": "The company documents the satisfactory assurances required for business associates and business-associated contractors through a written contract or other arrangements with the business associate that meets the applicable requirements of company policies.",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "baa-example"
        },
        {
          "id": "baa-template"
        }
      ]
    }
  ],
  "sections": [
    {
      "id": "164.308(a)(1)(i)",
      "controls": [
        {
          "id": "security-management-process"
        }
      ]
    },
    {
      "id": "164.308(a)(1)(ii)(A)",
      "controls": [
        {
          "id": "risk-analysis"
        }
      ]
    },
    {
      "id": "164.308(a)(1)(ii)(B)",
      "controls": [
        {
          "id": "risk-management"
        }
      ]
    },
    {
      "id": "164.308(a)(1)(ii)(C)",
      "controls": [
        {
          "id": "sanction-policy"
        }
      ]
    },
    {
      "id": "164.308(a)(1)(ii)(D)",
      "controls": [
        {
          "id": "information-system-activity-reviewed"
        }
      ]
    },
    {
      "id": "164.308(a)(2)",
      "controls": [
        {
          "id": "hipaa-security-officer"
        },
        {
          "id": "security-responsibility"
        }
      ]
    },
    {
      "id": "164.308(a)(3)(i)",
      "controls": [
        {
          "id": "workforce-security"
        }
      ]
    },
    {
      "id": "164.308(a)(3)(ii)(A)",
      "controls": [
        {
          "id": "authorization-supervision"
        }
      ]
    },
    {
      "id": "164.308(a)(3)(ii)(B)",
      "controls": [
        {
          "id": "workforce-clearance-procedure"
        }
      ]
    },
    {
      "id": "164.308(a)(3)(ii)(C)",
      "controls": [
        {
          "id": "establish-termination-procedures"
        }
      ]
    },
    {
      "id": "164.308(a)(4)(i)",
      "controls": [
        {
          "id": "information-access-management"
        }
      ]
    },
    {
      "id": "164.308(a)(4)(ii)(A)",
      "controls": []
    },
    {
      "id": "164.308(a)(4)(ii)(B)",
      "controls": [
        {
          "id": "access-authorization"
        }
      ]
    },
    {
      "id": "164.308(a)(4)(ii)(C)",
      "controls": [
        {
          "id": "access-established-reviewed-modified"
        }
      ]
    },
    {
      "id": "164.308(a)(5)(i)",
      "controls": [
        {
          "id": "security-awareness-training"
        }
      ]
    },
    {
      "id": "164.308(a)(5)(ii)(A)",
      "controls": [
        {
          "id": "security-reminders"
        }
      ]
    },
    {
      "id": "164.308(a)(5)(ii)(B)",
      "controls": [
        {
          "id": "protection-from-malicious-software"
        }
      ]
    },
    {
      "id": "164.308(a)(5)(ii)(C)",
      "controls": [
        {
          "id": "log-in-monitoring"
        }
      ]
    },
    {
      "id": "164.308(a)(5)(ii)(D)",
      "controls": [
        {
          "id": "password-management"
        }
      ]
    },
    {
      "id": "164.308(a)(6)(i)",
      "controls": [
        {
          "id": "security-incident-procedures"
        }
      ]
    },
    {
      "id": "164.308(a)(6)(ii)",
      "controls": [
        {
          "id": "response-and-reporting"
        }
      ]
    },
    {
      "id": "164.308(a)(7)(i)",
      "controls": [
        {
          "id": "contingency-plan"
        }
      ]
    },
    {
      "id": "164.308(a)(7)(ii)(A)",
      "controls": [
        {
          "id": "data-backup-plan"
        }
      ]
    },
    {
      "id": "164.308(a)(7)(ii)(B)",
      "controls": [
        {
          "id": "disaster-recovery-plan"
        }
      ]
    },
    {
      "id": "164.308(a)(7)(ii)(C)",
      "controls": [
        {
          "id": "emergency-mode-operation-plan"
        }
      ]
    },
    {
      "id": "164.308(a)(7)(ii)(D)",
      "controls": [
        {
          "id": "contingency-plan-testing-and-revision-procedure"
        }
      ]
    },
    {
      "id": "164.308(a)(7)(ii)(E)",
      "controls": [
        {
          "id": "application-and-data-criticality-analysis"
        }
      ]
    },
    {
      "id": "164.308(a)(8)",
      "controls": [
        {
          "id": "security-controls-evaluation"
        }
      ]
    },
    {
      "id": "164.308(b)(1)",
      "controls": [
        {
          "id": "business-associate-agreements-with-vendors"
        }
      ]
    },
    {
      "id": "164.308(b)(2)",
      "controls": [
        {
          "id": "business-associate-agreements-subcontractors"
        }
      ]
    },
    {
      "id": "164.308(b)(3)",
      "controls": [
        {
          "id": "written-business-associate-agreements"
        }
      ]
    },
    {
      "id": "164.310(a)(1)",
      "controls": [
        {
          "id": "facility-access-controls"
        }
      ]
    },
    {
      "id": "164.310(a)(2)(i)",
      "controls": [
        {
          "id": "contingency-operations"
        }
      ]
    },
    {
      "id": "164.310(a)(2)(ii)",
      "controls": [
        {
          "id": "facility-security-plan"
        }
      ]
    },
    {
      "id": "164.310(a)(2)(iii)",
      "controls": [
        {
          "id": "access-control-and-validation-procedures"
        }
      ]
    },
    {
      "id": "164.310(a)(2)(iv)",
      "controls": [
        {
          "id": "maintenance-records"
        }
      ]
    },
    {
      "id": "164.310(b)",
      "controls": [
        {
          "id": "workstation-security-policies"
        }
      ]
    },
    {
      "id": "164.310(c)",
      "controls": [
        {
          "id": "workstation-security-implemented"
        }
      ]
    },
    {
      "id": "164.310(d)(1)",
      "controls": [
        {
          "id": "media-removal"
        }
      ]
    },
    {
      "id": "164.310(d)(2)(i)",
      "controls": [
        {
          "id": "device-media-disposal"
        }
      ]
    },
    {
      "id": "164.310(d)(2)(ii)",
      "controls": [
        {
          "id": "media-re-use"
        }
      ]
    },
    {
      "id": "164.310(d)(2)(iii)",
      "controls": [
        {
          "id": "device-media-movement"
        }
      ]
    },
    {
      "id": "164.310(d)(2)(iv)",
      "controls": [
        {
          "id": "data-backup-and-storage"
        }
      ]
    },
    {
      "id": "164.312(a)(1)",
      "controls": [
        {
          "id": "access-control"
        }
      ]
    },
    {
      "id": "164.312(a)(2)(i)",
      "controls": [
        {
          "id": "unique-user-identification"
        }
      ]
    },
    {
      "id": "164.312(a)(2)(ii)",
      "controls": [
        {
          "id": "emergency-access-procedure"
        }
      ]
    },
    {
      "id": "164.312(a)(2)(iii)",
      "controls": [
        {
          "id": "automatic-log-off"
        }
      ]
    },
    {
      "id": "164.312(a)(2)(iv)",
      "controls": [
        {
          "id": "data-encryption-decryption"
        }
      ]
    },
    {
      "id": "164.312(b)",
      "controls": [
        {
          "id": "audit-controls"
        }
      ]
    },
    {
      "id": "164.312(c)(1)",
      "controls": [
        {
          "id": "data-integrity"
        }
      ]
    },
    {
      "id": "164.312(c)(2)",
      "controls": [
        {
          "id": "mechanism-authenticate-information"
        }
      ]
    },
    {
      "id": "164.312(d)",
      "controls": [
        {
          "id": "person-or-entity-authentication"
        }
      ]
    },
    {
      "id": "164.312(e)(1)",
      "controls": [
        {
          "id": "data-transmission-security"
        }
      ]
    },
    {
      "id": "164.312(e)(2)(i)",
      "controls": [
        {
          "id": "data-transmission-integrity"
        }
      ]
    },
    {
      "id": "164.312(e)(2)(ii)",
      "controls": [
        {
          "id": "data-transmission-encrypted"
        }
      ]
    },
    {
      "id": "164.314(a)(1)",
      "controls": [
        {
          "id": "business-associate-agreements"
        }
      ]
    },
    {
      "id": "164.314(a)(2)(i)",
      "controls": [
        {
          "id": "business-associate-agreements-comply"
        },
        {
          "id": "business-associate-agreements-security-incidents-reported"
        },
        {
          "id": "subcontractor-business-associate-agreements-enforced"
        }
      ]
    },
    {
      "id": "164.314(a)(2)(ii)",
      "controls": []
    },
    {
      "id": "164.314(a)(2)(iii)",
      "controls": [
        {
          "id": "business-associate-agreements-with-subcontractors"
        }
      ]
    },
    {
      "id": "164.314(b)(1)",
      "controls": []
    },
    {
      "id": "164.314(b)(2)",
      "controls": []
    },
    {
      "id": "164.314(b)(2)(i)",
      "controls": [
        {
          "id": "group-health-plan-controls"
        }
      ]
    },
    {
      "id": "164.314(b)(2)(ii)",
      "controls": []
    },
    {
      "id": "164.314(b)(2)(iii)",
      "controls": [
        {
          "id": "group-health-plan-information-protection"
        }
      ]
    },
    {
      "id": "164.314(b)(2)(iv)",
      "controls": [
        {
          "id": "group-health-plan-security-incidents"
        }
      ]
    },
    {
      "id": "164.316(a)",
      "controls": [
        {
          "id": "policies-procedures-created"
        }
      ]
    },
    {
      "id": "164.316(b)(1)(i)",
      "controls": [
        {
          "id": "policies-procedures-documented"
        }
      ]
    },
    {
      "id": "164.316(b)(1)(ii)",
      "controls": [
        {
          "id": "policies-procedures-activity-documented"
        }
      ]
    },
    {
      "id": "164.316(b)(2)(i)",
      "controls": [
        {
          "id": "data-retention-time-limit"
        }
      ]
    },
    {
      "id": "164.316(b)(2)(ii)",
      "controls": [
        {
          "id": "policies-procedures-available"
        }
      ]
    },
    {
      "id": "164.316(b)(2)(iii)",
      "controls": [
        {
          "id": "policies-procedures-updated"
        }
      ]
    },
    {
      "id": "164.410(a)(1)",
      "controls": [
        {
          "id": "notification-of-breach"
        }
      ]
    },
    {
      "id": "164.410(a)(2)",
      "controls": [
        {
          "id": "notification-of-breach"
        }
      ]
    },
    {
      "id": "164.410(b)",
      "controls": [
        {
          "id": "timeliness-of-breach-notification"
        }
      ]
    },
    {
      "id": "164.410(c)(1)",
      "controls": [
        {
          "id": "breach-identification-of-individuals"
        }
      ]
    },
    {
      "id": "164.410(c)(2)",
      "controls": [
        {
          "id": "additional-breach-information"
        }
      ]
    },
    {
      "id": "164.412",
      "controls": []
    }
  ]
}
