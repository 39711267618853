import { Spinner } from "@blueprintjs/core";
import {
  specificResourceGraphQLTypeToEnumValue,
  SpecificResourceToInventoryType,
} from "common/utils/inventory";
import {
  deserializeVantaAttributes,
  hasExternallyManagedVantaAttributes,
} from "common/utils/vantaAttributes";
import gql from "graphql-tag";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import type { LoadBalancersQuery } from "../../../../gen/components";
import {
  useLoadBalancersQuery,
  useSetInventoryResourceVantaAttributeMutation,
} from "../../../../gen/components";
import { InventoryCard } from "../inventory-card";
import { AccountTag, RegionTag } from "../tags";
import {
  fetchMoreResources,
  getDebounceContext,
  getResourceFilterParams,
  invalidInventoryType,
  PAGE_SIZE,
  setResourceVantaAttributeFn,
} from "../utils";
import { SearchResultsSummary } from "./search-results-summary";
import type { IInventoryTabProps } from "./shared-interface";

export const LoadBalancers: React.FC<IInventoryTabProps> = ({
  searchString,
}) => {
  const { data, fetchMore, loading } = useLoadBalancersQuery({
    variables: {
      first: PAGE_SIZE,
      filterParams: getResourceFilterParams(searchString),
    },
    context: getDebounceContext("load-balancer"),
  });

  if (loading) {
    return <Spinner />;
  }

  const cards = data?.organization.resources.edges?.map(item => (
    <LoadBalancerCard
      key={item.node.id}
      item={item}
      domain={data.organization}
    />
  ));

  return (
    <>
      <SearchResultsSummary
        searchString={searchString}
        numberResults={data?.organization.resources?.totalCount ?? 0}
      />
      <InfiniteScroll
        className="inventory-list-card-group"
        dataLength={data?.organization.resources.edges.length ?? 0}
        next={async () => fetchMoreResources(data, fetchMore)}
        hasMore={data?.organization.resources.pageInfo.hasNextPage ?? false}
        loader={<Spinner />}
      >
        {cards}
      </InfiniteScroll>
    </>
  );
};

const LoadBalancerCard: React.FC<{
  item: NonNullable<
    LoadBalancersQuery["organization"]
  >["resources"]["edges"][number];
  domain: NonNullable<LoadBalancersQuery["organization"]>;
}> = ({ item, domain }) => {
  const [setResourceVantaAttribute] =
    useSetInventoryResourceVantaAttributeMutation();

  const specificResourceKind = specificResourceGraphQLTypeToEnumValue(
    item.node.__typename
  );
  const type = SpecificResourceToInventoryType[specificResourceKind](
    item.node as any
  );
  const resourceMongoId = item.node.id;

  let uid;
  let labels;
  switch (item.node.__typename) {
    case "SpecificALBResource": {
      labels = [
        <AccountTag key="account" cloudAccountId={item.node.account} />,
        <RegionTag key="region" region={item.node.region} />,
      ];
      uid = item.node.uniqueId;
      break;
    }
    case "SpecificAzureApplicationGatewayResource": {
      labels = [<RegionTag key="region" region={item.node.location} />];
      uid = item.node.uniqueId;
      break;
    }
    case "SpecificAzureLoadBalancerResource": {
      labels = [<RegionTag key="region" region={item.node.location} />];
      uid = item.node.uniqueId;
      break;
    }
    case "SpecificDigitalOceanLoadBalancerResource": {
      labels = [<RegionTag key="region" region={item.node.region} />];
      uid = item.node.uniqueId;
      break;
    }
    default:
      return invalidInventoryType(item.node.__typename);
  }

  const vantaAttributes = deserializeVantaAttributes(
    item.node.vantaAttributes ?? []
  );
  const locked = hasExternallyManagedVantaAttributes(
    item.node.vantaAttributes ?? []
  );
  const setVantaAttribute = setResourceVantaAttributeFn(
    setResourceVantaAttribute,
    specificResourceKind,
    resourceMongoId,
    item.node.__typename
  );

  return (
    <InventoryCard
      type={type}
      uid={uid}
      labels={labels}
      key={item.node.id}
      name={item.node.name}
      description={vantaAttributes.description}
      owner={vantaAttributes.ownerId}
      canContainUserData={false}
      locked={locked}
      setVantaAttribute={setVantaAttribute}
    />
  );
};

gql`
  query loadBalancers(
    $first: Int!
    $after: String
    $filterParams: filterParams
  ) {
    organization {
      id
      resources(
        first: $first
        after: $after
        genericResourceType: LoadBalancer
        sortParams: { field: "createdAt", direction: -1 }
        filterParams: $filterParams
      ) {
        totalCount
        edges {
          node {
            id
            __typename
            uniqueId
            vantaAttributes {
              key
              value
              managedExternally
            }
            ... on SpecificALBResource {
              name
              type
              region
              account
            }
            ... on SpecificAzureApplicationGatewayResource {
              name
              location
            }
            ... on SpecificAzureLoadBalancerResource {
              name
              location
            }
            ... on SpecificDigitalOceanLoadBalancerResource {
              name
              region
            }
          }
        }
        pageInfo {
          hasNextPage
          endCursor
          startCursor
          hasPreviousPage
        }
      }
    }
  }
`;
