import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import React from "react";

interface IProps {
  applicationNames: Maybe<string[]>;
}

export const ComputerApplicationList: React.FC<IProps> = ({
  applicationNames,
}) => {
  if (!isSome(applicationNames)) {
    return (
      <div>
        Application data for this machine has not yet been collected. Please
        wait and try again later.
      </div>
    );
  }

  return (
    <ul>
      {applicationNames.map((app, i) => (
        <li key={`${app}_${i}`}>{app}</li>
      ))}
    </ul>
  );
};
