export function isHerokuDb(addon: { serviceName: string }) {
  return addon.serviceName === "heroku-postgresql";
}

export function isHerokuDbBackedUp(db: {
  planName: string;
  actionLabels: string[];
}) {
  if (!db.planName.includes("hobby")) {
    return true;
  }
  return db.actionLabels.includes("Capture backup");
}

// CHECKME (6/4/2020): if you're reading this, check that this is still a
// "reasonable" way to see if the database is encrypted.
export function isHerokuDbEncrypted(db: { planName: string }) {
  return !db.planName.includes("hobby");
}
