import type { Maybe } from "common/base/types/maybe";
import {
  replaceHexEscapedSequences,
  replaceUTF8MultiByteHexEscapes,
} from "common/base/utils";
import React from "react";
import styled from "styled-components";

import { ClientPlatform } from "../../../gen/components";
import AppleIcon from "../../../static/images/icons/apple.svg";
import LinuxIcon from "../../../static/images/icons/linux.svg";
import WindowsIcon from "../../../static/images/icons/windows.svg";
import type { IUserWorkstation } from "./utils";
import { clientPlatformFromWorkstation } from "./utils";

interface IProps {
  iconOnRight?: Maybe<boolean>;
  workstation: IUserWorkstation;
}

const getOsIcon = (workstation: IUserWorkstation) => {
  const platform = clientPlatformFromWorkstation(workstation);
  return platform === ClientPlatform.darwin
    ? AppleIcon
    : platform === ClientPlatform.windows
    ? WindowsIcon
    : LinuxIcon;
};

const TextContainer = styled.span`
  margin-left: 8px;
  margin-right: 8px;
`;

const IconContainer = styled.span`
  height: 16px;
  width: 16px;
  position: relative;
  top: 1px;
`;

export const OSInfo: React.FC<IProps> = ({ workstation, iconOnRight }) => {
  const OSIcon = getOsIcon(workstation);
  const onRight = iconOnRight ?? false;
  return (
    <span>
      {!onRight ? (
        <IconContainer>
          <OSIcon />
        </IconContainer>
      ) : null}
      <TextContainer>
        {replaceHexEscapedSequences(
          replaceUTF8MultiByteHexEscapes(workstation.osVersion)
        ).replace("Microsoft Windows", "Win")}
      </TextContainer>
      {onRight ? (
        <IconContainer>
          <OSIcon />
        </IconContainer>
      ) : null}
    </span>
  );
};
