import { Button, Dialog, Intent, Menu, MenuItem } from "@blueprintjs/core";
import { IconNames as BPIconNames } from "@blueprintjs/icons";
import { Popover2 } from "@blueprintjs/popover2";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { VANTA_COLORS } from "../../alpaca/base/colors";
import { GRID_SPACING } from "../../alpaca/base/grid";
import { BASE_TYPOGRAPHY } from "../../alpaca/base/typography";
import { Banner, IconNames } from "../../alpaca/components";
import { LogError } from "../../errors";
import type {
  Credentials,
  PollLatestResourceFetchesQuery,
} from "../../gen/components";
import {
  FetchStatus,
  usePollLatestResourceFetchesQuery,
} from "../../gen/components";
import { BORDER_LINE } from "../../helpers/borders";
import { CredentialComponent } from "../pages/credential/credential";
import { DisconnectCredentialDialog } from "../pages/credential/disconnect-credentials";
import { ConfigureScopesDialog } from "./configure-scopes-dialog";
import { FetchCompletionStatusIndicator } from "./fetch-completion-status-indicator";
import { MaybeDisabledIssuesCallout } from "./maybe-disabled-issues-callout";
import { ServiceBadge } from "./service-badge";
import type { ServiceDetails } from "./service-groups";

export type Credential = Pick<
  Credentials,
  "id" | "lastUpdated" | "metadata" | "service" | "isDisabled" | "disableCause"
>;
interface ICredentialCardProps {
  calloutSection?: Maybe<React.ReactNode>;
  credential: Credential;
  customEditMenuItemText?: Maybe<string>;
  customMenuItems?: Maybe<React.ReactNode>;
  onConfigureScopesDialogClosed?: Maybe<() => void>;
  /**
   * Triggers when we detect that all resources for integration are successfully
   * fetched
   */
  onFullFetchProgressDetected?: Maybe<() => void>;
  service: ServiceDetails;
}

export const CredentialCard: React.FC<ICredentialCardProps> = ({
  calloutSection,
  credential,
  customEditMenuItemText,
  customMenuItems,
  onConfigureScopesDialogClosed,
  onFullFetchProgressDetected,
  service,
}) => {
  const { data: fetchData, stopPolling } = usePollLatestResourceFetchesQuery({
    variables: { specificKinds: service.scopableResources.map(r => r.kind) },
    pollInterval: 1000,
    onError: e => LogError(e),
  });

  const fetchCompletionProportion = calculateFetchCompletionProportion(
    fetchData,
    new Date(credential.lastUpdated)
  );

  useEffect(() => {
    if (fetchCompletionProportion === 1) {
      stopPolling();
      onFullFetchProgressDetected?.();
    }
  }, [fetchCompletionProportion]);

  // Menu items.
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const editCredentialDialog = (
    <Dialog isOpen={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
      <CredentialComponent
        service={service.id}
        onCredentialsLinked={() => setEditDialogOpen(false)}
      />
    </Dialog>
  );

  const [disconnectDialogOpen, setDisconnectDialogOpen] = useState(false);
  const disconnectDialog = (
    <DisconnectCredentialDialog
      isOpen={disconnectDialogOpen}
      closeDialog={() => setDisconnectDialogOpen(false)}
      credentialId={credential.id}
      service={service.id}
    />
  );

  const [scopeDialogOpen, setScopeDialogOpen] = useState(false);
  const scopeDialog = (
    <ConfigureScopesDialog
      fetchCompletionProportion={fetchCompletionProportion}
      isOpen={scopeDialogOpen}
      onClose={() => {
        setScopeDialogOpen(false);
        onConfigureScopesDialogClosed?.();
      }}
      scopableResources={service.scopableResources}
      tips={
        <p>
          Keep resources if they can access user data, sensitive information
          like keys, or if they are supporting your customer experience.
        </p>
      }
    />
  );
  // exception because AWS and Azure have their own unique multiple credential modification flow
  const maybeDeleteMenuItem =
    credential.service !== "aws" && credential.service !== "azure" ? (
      <MenuItem
        icon={BPIconNames.TRASH}
        text={"Delete"}
        onClick={async () => setDisconnectDialogOpen(true)}
      />
    ) : null;
  const menu = (
    <Menu>
      <MenuItem
        icon={BPIconNames.EDIT}
        text={customEditMenuItemText ?? "Edit"}
        onClick={() => setEditDialogOpen(true)}
      />
      {customMenuItems}
      {maybeDeleteMenuItem}
      {/* Render "scope resources" button iff there are resources to scope. */}
      {service.scopableResources.length > 0 ? (
        <MenuItem
          icon={BPIconNames.GROUP_OBJECTS}
          text={"Configure scope"}
          onClick={() => setScopeDialogOpen(true)}
        />
      ) : null}
    </Menu>
  );

  return (
    <CredentialsCardContainer>
      {credential.service === "gitlab" ||
      credential.service === "azuredevops" ? (
        <MaybeDisabledIssuesCallout service={credential.service} />
      ) : null}
      {credential.isDisabled ? (
        <StyledCalloutContainerDiv>
          <Banner
            icon={IconNames.CAUTION}
            description={credential.disableCause}
            intent={Intent.WARNING}
            title="We're having some trouble getting data for this account"
          />
        </StyledCalloutContainerDiv>
      ) : null}
      {isSome(calloutSection) ? (
        <StyledCalloutContainerDiv>{calloutSection}</StyledCalloutContainerDiv>
      ) : null}
      <CredentialInfoContainer>
        <ServiceBadge service={service} large={false} clickToConnect={false} />
        <CenterColumn>
          <ServiceHeader id={service.id}>
            {service.displayName}
            {credential.isDisabled ? null : (
              <FetchCompletionStatusIndicator
                fetchCompletionProportion={fetchCompletionProportion}
              />
            )}
          </ServiceHeader>
          <ServiceDescription>{service.explanation}</ServiceDescription>
        </CenterColumn>
        <RightColumn>
          <Popover2 content={menu} placement={"bottom-end"}>
            <CredentialMenuButton icon={BPIconNames.MORE} />
          </Popover2>
          {editCredentialDialog}
          {disconnectDialog}
          {scopeDialog}
        </RightColumn>
      </CredentialInfoContainer>
    </CredentialsCardContainer>
  );
};

/**
 * calculateFetchCompletionProportion returns the fetch completion proportion
 * for the credential in question: "what proportion of specific resources
 * associated with the credential service have been fetched successfully since
 * the credential was updated?"
 *
 * If there are no scopable resources for this credential, returns 1
 *
 * @param maybeData - response to the query for latest resource fetches for the
 *    service in question.
 * @param credentialLastUpdated - last update timestamp for the credentials for
 *    the service in question.
 */
function calculateFetchCompletionProportion(
  maybeData: Maybe<PollLatestResourceFetchesQuery>,
  credentialLastUpdated: Date
) {
  if (!isSome(maybeData?.latestResourceFetches)) {
    // No data from query; assume unfetched while loading.
    return 0;
  }
  const fetches = maybeData!.latestResourceFetches;
  if (fetches.length === 0) {
    // There are no scopable specific resource kinds for this service
    return 1;
  }
  const completedFetches = fetches.filter(
    f =>
      isSome(f) &&
      new Date(f.createdAt) > credentialLastUpdated &&
      f.status === FetchStatus.SUCCEEDED
  );
  return completedFetches.length / fetches.length;
}

const CredentialsCardContainer = styled.div`
  margin-bottom: ${GRID_SPACING}px;
  border: ${BORDER_LINE};
  border-radius: ${GRID_SPACING}px;
`;

const CredentialInfoContainer = styled.div`
  display: flex;
`;

const StyledCalloutContainerDiv = styled.div`
  margin-left: ${2 * GRID_SPACING}px;
  margin-right: ${2 * GRID_SPACING}px;
  margin-top: ${2 * GRID_SPACING}px;
`;

// Styles for the center column of the credentials card.

const CenterColumnStyles = {
  FLEX_GROW: 2, // Center column should fill available horizontal space.
  MARGIN_TOP_BOTTOM: 2 * GRID_SPACING,
};

const CenterColumn = styled.div`
  flex-grow: ${CenterColumnStyles.FLEX_GROW};
  margin-top: ${CenterColumnStyles.MARGIN_TOP_BOTTOM}px;
  margin-bottom: ${CenterColumnStyles.MARGIN_TOP_BOTTOM}px;
`;

const ServiceHeaderStyles = {
  FONT_WEIGHT: `${BASE_TYPOGRAPHY.FONT_WEIGHTS.BOLD} !important`,
  FONT_SIZE: 14,
  LINE_HEIGHT: 20,
  MARGIN_BOTTOM: GRID_SPACING,
};

const ServiceHeader = styled.h3`
  font-style: normal;
  font-weight: ${ServiceHeaderStyles.FONT_WEIGHT};
  font-size: ${ServiceHeaderStyles.FONT_SIZE}px;
  line-height: ${ServiceHeaderStyles.LINE_HEIGHT}px;
  margin-bottom: ${ServiceHeaderStyles.MARGIN_BOTTOM}px;
`;

const ServiceDescriptionStyles = {
  FONT_SIZE: 12,
  LINE_HEIGHT: 18,
  COLOR: VANTA_COLORS.TEXT_NORMAL,
};

const ServiceDescription = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: ${ServiceDescriptionStyles.FONT_SIZE}px;
  line-height: ${ServiceDescriptionStyles.LINE_HEIGHT}px;
  color: ${ServiceDescriptionStyles.COLOR};
`;

const RightColumn = styled.div`
  display: flex;
  align-items: center;
  margin: ${2 * GRID_SPACING}px;
`;

const CredentialMenuButtonStyles = { BORDER_RADIUS: 6 };

const CredentialMenuButton = styled(Button)`
  background: ${VANTA_COLORS.BACKGROUND_WHITE};
  border-radius: ${CredentialMenuButtonStyles.BORDER_RADIUS}px;
`;

gql`
  query pollLatestResourceFetches($specificKinds: [String!]!) {
    latestResourceFetches(specificKinds: $specificKinds) {
      id
      status
      createdAt
    }
  }
`;
