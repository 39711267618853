import type {
  SpecificDynamoDbTableResource,
  SpecificRdsInstanceResource,
  SpecificRedshiftClusterResource,
} from "common/base/types/gen";
import gql from "graphql-tag";

import type { TableParameters } from "../helpers";
import {
  booleanToEvaluationIcon,
  enabledValueToEvaluationIcon,
} from "../helpers";
import { SHARED_DATABASE_TABLE_HEADERS } from "./helpers";

export const AWS_DBS = [
  "rdsInstances",
  "dynamoDbs",
  "redshiftClusters",
] as const;
export const awsDataTableParameters: { [k: string]: TableParameters } = {
  rdsInstances: {
    displayName: "RDS Instances",
    columnOrder: [
      "DBName",
      "DBInstanceIdentifier",
      "DBInstanceStatus",
      "VPCSecurityGroups",
      "DBSecurityGroups",
      "DBSubnetGroup",
      "StorageEncrypted",
      "PubliclyAccessible",
      "BackupRetentionPeriod",
    ],
    header: {
      DBInstanceIdentifier: SHARED_DATABASE_TABLE_HEADERS.id,
      DBInstanceStatus: SHARED_DATABASE_TABLE_HEADERS.status,
      DBName: SHARED_DATABASE_TABLE_HEADERS.name,
      BackupRetentionPeriod: SHARED_DATABASE_TABLE_HEADERS.retentionPeriod,
      VPCSecurityGroups: "VPC security groups",
      DBSecurityGroups: "DB security groups",
      DBSubnetGroup: "Subnet groups",
      StorageEncrypted: "Storage encrypted",
      PubliclyAccessible: "Has public IP",
    },
    createRow: (instance: Partial<SpecificRdsInstanceResource>) => {
      return {
        DBInstanceIdentifier: instance.dbInstanceIdentifier,
        DBInstanceStatus: instance.dbInstanceStatus,
        DBName: instance.dbName,
        BackupRetentionPeriod: instance.backupRetentionPeriod,
        DBSecurityGroups: (instance.dbSecurityGroupNames ?? []).join(", "),
        DBSubnetGroup: instance.dbSubnetGroupName,
        StorageEncrypted: booleanToEvaluationIcon(instance.storageEncrypted),
        PubliclyAccessible: instance.publiclyAccessible ? "Yes" : "No",
        VPCSecurityGroups: (instance.vpcSecurityGroupIds ?? []).join(", "),
      };
    },
  },
  dynamoDbs: {
    displayName: "DynamoDB Instances",
    columnOrder: [
      "name",
      "tableId",
      "tableStatus",
      "tableCreated",
      "encrypted",
      "continuousBackupsStatus",
      "pointInTimeRecoveryDescription",
    ],
    header: {
      name: SHARED_DATABASE_TABLE_HEADERS.name,
      tableId: SHARED_DATABASE_TABLE_HEADERS.id,
      tableStatus: SHARED_DATABASE_TABLE_HEADERS.status,
      encrypted: SHARED_DATABASE_TABLE_HEADERS.encrypted,
      tableCreated: SHARED_DATABASE_TABLE_HEADERS.creationTime,
      continuousBackupsStatus: SHARED_DATABASE_TABLE_HEADERS.backups,
      pointInTimeRecoveryDescription: "Point in time recovery",
    },
    createRow: (instance: Partial<SpecificDynamoDbTableResource>) => {
      return {
        name: instance.name,
        tableId: instance.tableId,
        tableStatus: instance.tableStatus,
        tableCreated: instance.tableCreated,
        encrypted: booleanToEvaluationIcon(true), // DynamoDBs are always encrypted-at-rest and can't be disabled
        continuousBackupsStatus: enabledValueToEvaluationIcon(
          instance.continuousBackupsStatus
        ),
        pointInTimeRecoveryDescription: enabledValueToEvaluationIcon(
          instance.pointInTimeRecoveryStatus
        ),
      };
    },
  },
  redshiftClusters: {
    displayName: "Redshift Clusters",
    columnOrder: ["name", "uid", "encrypted"],
    header: {
      name: SHARED_DATABASE_TABLE_HEADERS.name,
      uid: SHARED_DATABASE_TABLE_HEADERS.id,
      encrypted: SHARED_DATABASE_TABLE_HEADERS.encrypted,
    },
    createRow: (instance: Partial<SpecificRedshiftClusterResource>) => {
      return {
        name: instance.name,
        uid: instance.id,
        encrypted: booleanToEvaluationIcon(instance.encryptionEnabled),
      };
    },
  },
};

gql`
  query fetchAwsDBInfo {
    organization {
      id
      displayName
      # 8/24/20 The first: 1000 below is an arbitrarily high number so that we load all the resources at once.
      # We should modify this query (and the others) to eventually be paginated, but we're holding off on doing this for now.
      rdsInstances: resources(first: 1000, specificResourceType: RDSInstance) {
        totalCount
        edges {
          node {
            id
            ... on SpecificRDSInstanceResource {
              backupRetentionPeriod
              dbInstanceIdentifier
              dbInstanceStatus
              dbName
              dbSecurityGroupNames
              dbSubnetGroupName
              publiclyAccessible
              storageEncrypted
              vpcSecurityGroupIds
            }
          }
        }
      }
      dynamoDbs: resources(first: 1000, specificResourceType: DynamoDBTable) {
        totalCount
        edges {
          node {
            id
            ... on SpecificDynamoDBTableResource {
              continuousBackupsStatus
              name
              pointInTimeRecoveryStatus
              tableCreated
              tableId
              tableStatus
            }
          }
        }
      }
      redshiftClusters: resources(
        first: 1000
        specificResourceType: RedshiftCluster
      ) {
        totalCount
        edges {
          node {
            id
            ... on SpecificRedshiftClusterResource {
              name
              encryptionEnabled
              id
            }
          }
        }
      }
    }
  }
`;
