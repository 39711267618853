import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import { useEffect, useRef, useState } from "react";

import { LogError } from "../../../errors";
import type { GetPolicyPacketQuery } from "../../../gen/components";
import { useGeneratePolicyPacketMutation } from "../../../gen/components";

/**
 * Generate a packet by issuing a mutation and start polling on the
 * `policyPacket`. Since we use long-polling, timeout if we don't get a packet
 * after a minute.
 *
 * @param domainId
 * @param packet - Existing packet
 * @param startPolling - Poll for a newly generated packet
 * @param stopPolling
 */
export const useGeneratePolicyPacket = (
  domainId: Maybe<string>,
  packet: Maybe<
    NonNullable<GetPolicyPacketQuery["organization"]>["policyPacket"]
  >,
  startPolling: (interval: number) => void,
  stopPolling: () => void
) => {
  const [generatePolicyPacket] = useGeneratePolicyPacketMutation();
  const [generatingTimeoutId, setGeneratingTimeoutId] =
    useState<Maybe<ReturnType<typeof setTimeout>>>(null);
  const [isGeneratingPacket, setIsGeneratingPacket] = useState<boolean>(false);
  const [oldPolicyPacketId, setOldPolicyPacketId] = useState<Maybe<string>>(
    packet?.id
  );
  const isGeneratingPacketRef = useRef(isGeneratingPacket);
  isGeneratingPacketRef.current = isGeneratingPacket;

  useEffect(() => {
    if (
      isGeneratingPacket &&
      packet &&
      !packet.generating &&
      packet.id !== oldPolicyPacketId
    ) {
      setIsGeneratingPacket(false);
      stopPolling();
      if (isSome(generatingTimeoutId)) {
        clearTimeout(generatingTimeoutId);
        setGeneratingTimeoutId(null);
      }
      setTimeout(() => {
        window.location.href = "/doc?download=1&s=" + packet.uploadedDoc.slugId;
      }, 250);
    }
  });

  return {
    generatePolicyPacket: () => {
      if (!isSome(domainId)) return;
      setOldPolicyPacketId(packet?.id);
      setIsGeneratingPacket(true);
      setGeneratingTimeoutId(
        setTimeout(() => {
          if (!isGeneratingPacketRef.current) return;
          setIsGeneratingPacket(false);
          setGeneratingTimeoutId(null);
          throw new Error("Timed out while trying to generate a policy packet");
        }, 60 * 1000)
      );
      generatePolicyPacket({ variables: { domainId } }).catch(LogError);
      startPolling(1000);
    },
    isGeneratingPacket,
  };
};

gql`
  mutation generatePolicyPacket($domainId: ID!) {
    generatePolicyPacket(domainId: $domainId) {
      id
      active
      policyType
      createdAt
      generating
      title
      uploadedDoc {
        id
        url
      }
    }
  }
`;
