import { Button, Intent } from "@blueprintjs/core";
import React from "react";
import type { RouteComponentProps } from "react-router-dom";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

import { DefaultLink } from "../../helpers/links";

const Textbox = styled.div`
  max-width: 400px;
  margin-bottom: 24px;
  font-size: 1em;

  a {
    font-size: 1em;
  }
`;

const CouldNotLogInComponent: React.FunctionComponent<RouteComponentProps> = ({
  history,
}) => (
  <div className="flex-center-container">
    <div className="flex-center-content">
      <h2 className="not-found-heading">Nice to meet you!</h2>
      <Textbox>
        We can't find you among our customers.
        <br /> If you're a Vanta customer and you've already been onboarded,
        please{" "}
        <DefaultLink href="mailto:support@vanta.com">
          contact support
        </DefaultLink>
        .
      </Textbox>
      <Button intent={Intent.PRIMARY} onClick={() => history.push("/")}>
        Go to the home page
      </Button>
    </div>
  </div>
);

export const CouldNotLogIn = withRouter(CouldNotLogInComponent);
