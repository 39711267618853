import type { Maybe } from "common/base/types/maybe";
import {
  applyIfSome,
  isSome,
  getTransformedOrElse,
} from "common/base/types/maybe";
import React, { useMemo, useState } from "react";
import { TABLE_ALIGNMENT_CLASSES } from "..";
import { Ellipsify } from "../../../helpers/ellipsify";
import { Icon } from "../icon/icon";
import { IconNames } from "../icon/iconNames";

import type { TableColumnAlignment } from "./constants";
import { PaginationRow, TABLE_PAGINATION_DEFAULT } from "./pagination-row";
import {
  StyledVantaTable,
  StyledVantaTableTh,
  StyledVantaTableTr,
} from "./styled-table-components";
import { TableControls } from "./table-controls";

const DEFAULT_COLUMN_MIN_WIDTH = 120;

interface IProps<DataType> {
  // eslint-disable-next-line vanta/optional-always-maybe, vanta/prefer-maybe
  className?: string | undefined;

  data: DataType[];

  columnInfo: Array<{
    alignment?: Maybe<TableColumnAlignment>;
    heading: string;
    hidden?: Maybe<boolean>;
    rowRenderer: (datum: DataType) => JSX.Element | string;
    gridColumns?: Maybe<number>;
    minWidth?: Maybe<number>;
    sortFn?: Maybe<(d1: DataType, d2: DataType) => number>;
  }>;

  controlsOptions?: Maybe<{
    searchOptions?: Maybe<{
      filter: (data: DataType, searchString: string) => boolean;
      placeholder?: Maybe<string>;
    }>;
    additionalControls?: Maybe<JSX.Element[]>;
  }>;

  rowOptions?: Maybe<{
    onRowClick?: Maybe<(data: DataType, index: number) => void>;
    rowIsDisabled?: Maybe<(data: DataType) => boolean>;
    tall?: Maybe<boolean>;
  }>;
}

export const Table = <T,>({
  controlsOptions,
  className,
  columnInfo,
  data,
  rowOptions,
}: IProps<T>) => {
  const { searchOptions, additionalControls } = controlsOptions ?? {};

  const { onRowClick, rowIsDisabled, tall } = rowOptions ?? {};

  const [sortColumn, setSortColumn] = useState(-1);
  const [sortAscending, setSortAscending] = useState(true);
  const [searchString, setSearchString] = useState("");
  const [startIndex, setStartIndex] = useState(0);

  const { dataSlice, totalNumberFilteredItems } = usePrepareTableData(data, {
    searchOptions: applyIfSome(searchOptions, options => {
      return {
        searchString,
        filter: options.filter,
      };
    }),
    paginationOptions: { startIndex, itemsPerPage: TABLE_PAGINATION_DEFAULT },
    sortOptions:
      sortColumn >= 0
        ? { fn: columnInfo[sortColumn].sortFn, ascending: sortAscending }
        : null,
  });

  const filteredColumnInfo = columnInfo.filter(i => !Boolean(i.hidden));

  return (
    <>
      <TableControls
        leftControls={additionalControls}
        searchOptions={applyIfSome(searchOptions, options => {
          return {
            value: searchString,
            onNewValue: newSearch => {
              setSearchString(newSearch);
              setStartIndex(0);
            },
            placeholder: options.placeholder,
          };
        })}
      />
      <StyledVantaTable
        className={className}
        tall={tall}
        columnWidths={filteredColumnInfo.map(info => {
          return {
            columnGridFr: info.gridColumns ?? 1,
            minWidthPx: info.minWidth ?? DEFAULT_COLUMN_MIN_WIDTH,
          };
        })}
      >
        <thead>
          <tr>
            {filteredColumnInfo.map((info, index) => (
              <StyledVantaTableTh
                className={
                  applyIfSome(
                    info.alignment,
                    alignment => TABLE_ALIGNMENT_CLASSES[alignment]
                  ) ?? undefined
                }
                key={`th-${index}`}
                onClick={
                  isSome(info.sortFn)
                    ? () => {
                        if (sortColumn === index) {
                          setSortAscending(!sortAscending);
                        } else {
                          setSortColumn(index);
                          setSortAscending(true);
                        }
                        setStartIndex(0);
                      }
                    : undefined
                }
              >
                <span style={{ position: "relative" }}>
                  {info.heading}
                  {index === sortColumn ? (
                    <Icon
                      style={{
                        marginLeft: 8,
                        transform: `rotateZ(${sortAscending ? 90 : -90}deg)`,
                        position: "absolute",
                        top: 1,
                      }}
                      icon={IconNames.ARROW_LEFT}
                      iconSize={12}
                    />
                  ) : null}
                </span>
              </StyledVantaTableTh>
            ))}
          </tr>
        </thead>
        <tbody>
          {dataSlice.map((datum, rowIdx) => {
            const clickHandler = getTransformedOrElse(
              onRowClick,
              handler =>
                (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
                  event.stopPropagation();
                  handler(datum, rowIdx);
                },
              undefined
            );

            const disabled = getTransformedOrElse(
              rowIsDisabled,
              fn => fn(datum),
              false
            );

            return (
              <StyledVantaTableTr
                key={`table-row-${rowIdx}`}
                onClick={!disabled ? clickHandler : undefined}
                disabled={disabled}
              >
                {filteredColumnInfo.map((colInfo, colIdx) => {
                  const content = colInfo.rowRenderer(datum);
                  return (
                    <td
                      className={
                        applyIfSome(
                          colInfo.alignment,
                          alignment => TABLE_ALIGNMENT_CLASSES[alignment]
                        ) ?? undefined
                      }
                      key={`${rowIdx}-${colIdx}`}
                    >
                      {typeof content === "string" ? (
                        <Ellipsify text={content} />
                      ) : (
                        content
                      )}
                    </td>
                  );
                })}
              </StyledVantaTableTr>
            );
          })}
        </tbody>
      </StyledVantaTable>
      <PaginationRow
        totalNumItems={totalNumberFilteredItems}
        startIndex={startIndex}
        onNextPage={() => {
          setStartIndex(
            Math.min(
              startIndex + TABLE_PAGINATION_DEFAULT,
              totalNumberFilteredItems - 1
            )
          );
        }}
        onPreviousPage={() => {
          setStartIndex(Math.max(startIndex - TABLE_PAGINATION_DEFAULT, 0));
        }}
      />
    </>
  );
};

function usePrepareTableData<T>(
  data: T[],
  options: {
    searchOptions?: Maybe<{
      filter: (datum: T, search: string) => boolean;
      searchString: string;
    }>;
    paginationOptions?: Maybe<{ startIndex: number; itemsPerPage: number }>;
    sortOptions?: Maybe<{
      fn?: Maybe<(d1: T, d2: T) => number>;
      ascending: boolean;
    }>;
  } = {}
): { dataSlice: T[]; totalNumberFilteredItems: number } {
  const { searchOptions, paginationOptions, sortOptions } = options;
  const filteredData = useMemo(() => {
    if (!isSome(searchOptions)) {
      return data;
    }
    return data.filter(d =>
      searchOptions.filter(d, searchOptions.searchString)
    );
  }, [data, searchOptions?.searchString]);

  const sortedData = useMemo(() => {
    if (!isSome(sortOptions)) {
      return filteredData;
    }
    const sortFn = sortOptions.fn;
    if (!isSome(sortFn)) {
      return filteredData;
    }

    return filteredData
      .slice()
      .sort((d1, d2) => (sortOptions.ascending ? 1 : -1) * sortFn(d1, d2));
  }, [filteredData, sortOptions?.fn, sortOptions?.ascending]);

  const dataSlice = useMemo(() => {
    if (!isSome(paginationOptions)) {
      return sortedData;
    } else {
      return sortedData.slice(
        paginationOptions.startIndex,
        paginationOptions.startIndex + paginationOptions.itemsPerPage
      );
    }
  }, [
    sortedData,
    paginationOptions?.startIndex,
    paginationOptions?.itemsPerPage,
  ]);

  return { dataSlice, totalNumberFilteredItems: filteredData.length };
}
