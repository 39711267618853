import { dateStringToDate } from "common/base/dateUtils";
import type { SecurityRequirements } from "common/base/types/gen";
import type { Maybe } from "common/base/types/maybe";
import { nothing, isSome } from "common/base/types/maybe";
import {
  DEFAULT_ONBOARDING_GRACE_PERIOD,
  DEFAULT_RECURRENCE_DUE_MONTHS,
  DEFAULT_RECURRENCE_TIME_TO_COMPLETE_MONTHS,
  TRAINING_CATEGORIES_BY_ID,
} from "common/constants/onboarding";
import type { TrainingCategoryAccess } from "common/schemas/betaFeatureAccess";
import {
  deserializeVantaAttributes,
  getValueForReservedVantaAttribute,
} from "common/utils/vantaAttributes";
import { addDays, addMonths } from "date-fns";
import { maxBy } from "lodash";
import moment from "moment";
import React from "react";

import { BASE_PALETTE } from "../../../alpaca/base/colors";
import { LogError } from "../../../errors";
import type { PeoplePageFieldsFragment } from "../../../gen/components";
import { SecurityTrainingCategoryId } from "../../../gen/components";
import { UI_DATE_FORMAT_WITHOUT_TIME } from "../../../helpers/common";
import type {
  CompletedTrainingRequirement,
  TrainingRequirement,
} from "./user-drawer/user-onboarding-list";

interface IMinimalRole {
  id: string;
  name: string;
}

type IMinimalUser = PeoplePageFieldsFragment;

interface IMinimalUserForGroup {
  role?: Maybe<IMinimalRole>;
  isNotHuman?: Maybe<boolean>;
  isContractor?: Maybe<boolean>;
}

export function withTableDisplayName<T extends IMinimalUser>(
  user: T
): T & { tableDisplayName: string } {
  const tableDisplayName = `${user.displayName!}${
    !Boolean(user.isNotHuman) && !user.isActive ? " (Ex-employee)" : ""
  }`;
  return { ...user, tableDisplayName };
}

export function groupForUser(
  user: IMinimalUserForGroup,
  noGroupDefault: string = ""
) {
  if (user.role) {
    return user.role.name;
  }
  if (user.isNotHuman) {
    return "Not a person";
  }
  if (user.isContractor) {
    return "Contractor (no group)";
  }
  return noGroupDefault;
}

/**
 * Takes a date and drops the time component, creating a reference to midnight of
 * that date in the local timezone. If readInUTC is true, reads date out in UTC,
 * otherwise reads as local date
 *    2021-05-01 00:00 UTC, readInUTC true => 2021-05-01 00:00 PT
 *    2021-05-01 10:00 PT, readInUTC false => 2021-05-01 00:00 PT
 */
export function asDateOnlyDate(date: Date, readInUTC: boolean) {
  const momentObj = readInUTC ? moment(date).utc() : moment(date);
  return new Date(momentObj.format(UI_DATE_FORMAT_WITHOUT_TIME));
}

/**
 * HR systems return only YYYY/MM/DD for start/end, which gets saved as UTC midnight.
 * In order to display and compare correctly to starts/ends which are true timestamps,
 * convert all start/end dates to date-only local-midnight
 */
export function withDateOnlyHrDates<T extends IMinimalUser>(
  user: T
): T & { dateOnlyStartDate: Date; dateOnlyEndDate: Maybe<Date> } {
  const dateOnlyStartDate = asDateOnlyDate(
    dateStringToDate(user.startDate),
    isSome(user.hrUser?.startDate)
  );

  const dateOnlyEndDate = isSome(user.endDate)
    ? asDateOnlyDate(
        dateStringToDate(user.endDate),
        isSome(user.hrUser?.endDate)
      )
    : nothing;

  return { ...user, dateOnlyStartDate, dateOnlyEndDate };
}

export function withGroup<T extends IMinimalUser>(
  user: T
): T & { group: string } {
  return { ...user, group: groupForUser(user) };
}

function userHasIncompleteCustomSecurityTraining<T extends IMinimalUser>(
  user: T,
  access: TrainingCategoryAccess
) {
  return (
    (access.hipaa &&
      user.securityRequirements.mustCompleteHipaaSecurityTraining &&
      !user.mostRecentHipaaSecurityTraining &&
      !getMostRecentlyCompletedTrainingRequirementForCategory(
        SecurityTrainingCategoryId.hipaa,
        user.trainingRequirements
      )) ||
    (access.pci &&
      user.securityRequirements.mustCompletePciSecurityTraining &&
      !user.mostRecentPciSecurityTraining &&
      !getMostRecentlyCompletedTrainingRequirementForCategory(
        SecurityTrainingCategoryId.pci,
        user.trainingRequirements
      )) ||
    (access.gdpr &&
      user.securityRequirements.mustCompleteGdprSecurityTraining &&
      !user.mostRecentGdprSecurityTraining &&
      !getMostRecentlyCompletedTrainingRequirementForCategory(
        SecurityTrainingCategoryId.gdpr,
        user.trainingRequirements
      ))
  );
}

function userHasIncompleteSecurityRequirements<T extends IMinimalUser>(
  user: T,
  access: TrainingCategoryAccess
) {
  return (
    (user.securityRequirements.mustInstallLaptopMonitoring &&
      !user.hasOSQueryEndpoint) ||
    (user.securityRequirements.mustAcceptPolicies &&
      !user.hasAcceptedAllSecurityPolicies) ||
    (access.general &&
      user.securityRequirements.mustCompleteSecurityTraining &&
      !user.mostRecentSecurityTraining &&
      !getMostRecentlyCompletedTrainingRequirementForCategory(
        SecurityTrainingCategoryId.general,
        user.trainingRequirements
      )) ||
    userHasIncompleteCustomSecurityTraining(user, access) ||
    (user.securityRequirements.mustBeBackgroundChecked &&
      !user.hasCompletedBackgroundCheck)
  );
}

function userHasIncompleteCustomOnboardingTasks<T extends IMinimalUser>(
  user: T
) {
  /**
   * Completion dates should be set for rolecompletion records without tasks,
   * but let's be safe
   */
  if (
    !isSome(user.roleCompletionRecord) ||
    user.roleCompletionRecord.hasNoTasks
  ) {
    return false;
  } else {
    return (
      !isSome(user.roleCompletionRecord.employeeCompletionDate) ||
      !isSome(user.roleCompletionRecord.adminCompletionDate)
    );
  }
}

export function withStatus<T extends IMinimalUser>(
  user: T & { dateOnlyStartDate: Date },
  access: TrainingCategoryAccess
): T & { status: UserStatus } {
  let status: Maybe<UserStatus>;
  if (Boolean(user.isNotHuman)) {
    status = UserStatus.OK;
  } else if (!user.isActive) {
    if (user.requiresOffboarding) {
      status = UserStatus.OFFBOARDING_INCOMPLETE;
    } else {
      status = UserStatus.OK;
    }
  } else if (moment().isBefore(user.dateOnlyStartDate)) {
    status = UserStatus.ONBOARDING_IN_FUTURE;
  } else {
    // onboardingCompletionDate is not a reliable indicator
    // of whether a user has completed everything related to onboarding.
    const hasIncompleteSecureRequirements =
      userHasIncompleteSecurityRequirements(user, access);
    const hasIncompleteCustomTasks =
      userHasIncompleteCustomOnboardingTasks(user);
    if (!hasIncompleteSecureRequirements && !hasIncompleteCustomTasks) {
      status = UserStatus.OK;
    } else if (!isSome(user.onboardingCompletionDate)) {
      if (user.inOnboardingSla) {
        status = UserStatus.CURRENTLY_ONBOARDING;
      } else {
        status = UserStatus.ONBOARDING_OVERDUE;
      }
    } else {
      status = hasIncompleteSecureRequirements
        ? UserStatus.SECURITY_REQUIREMENTS_INCOMPLETE
        : UserStatus.CURRENTLY_ONBOARDING;
    }
  }
  return { ...user, status: status ?? UserStatus.OK };
}

export enum UserStatus {
  OK,
  ONBOARDING_IN_FUTURE,
  CURRENTLY_ONBOARDING,
  ONBOARDING_OVERDUE,
  OFFBOARDING_INCOMPLETE,
  SECURITY_REQUIREMENTS_INCOMPLETE,
  UNMATCHED_HR_USER,
}

const svgCircleForColor = (color: string) => (
  <svg
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="4" cy="4" r="4" fill={color} />
  </svg>
);

const redCircle = svgCircleForColor(BASE_PALETTE.TOMATO);
const yellowCircle = svgCircleForColor(BASE_PALETTE.MANGO);
const greenCircle = svgCircleForColor(BASE_PALETTE.KALE);

export const textForStatus: { [k in UserStatus]: string } = {
  [UserStatus.OK]: "OK",
  [UserStatus.ONBOARDING_IN_FUTURE]: "New user",
  [UserStatus.CURRENTLY_ONBOARDING]: "Currently onboarding",
  [UserStatus.ONBOARDING_OVERDUE]: "Onboarding incomplete",
  [UserStatus.OFFBOARDING_INCOMPLETE]: "Needs offboarding",
  [UserStatus.SECURITY_REQUIREMENTS_INCOMPLETE]:
    "Security requirements incomplete",
  [UserStatus.UNMATCHED_HR_USER]: "No Vanta/IdP account",
};

const svgForStatus: { [k in UserStatus]: Maybe<JSX.Element> } = {
  [UserStatus.OK]: greenCircle,
  [UserStatus.ONBOARDING_IN_FUTURE]: greenCircle,
  [UserStatus.CURRENTLY_ONBOARDING]: yellowCircle,
  [UserStatus.ONBOARDING_OVERDUE]: redCircle,
  [UserStatus.OFFBOARDING_INCOMPLETE]: redCircle,
  [UserStatus.SECURITY_REQUIREMENTS_INCOMPLETE]: redCircle,
  [UserStatus.UNMATCHED_HR_USER]: redCircle,
};

export const jsxForStatus = (status: UserStatus) => (
  <div style={{ display: "flex", alignItems: "center" }}>
    {svgForStatus[status]}&nbsp;{textForStatus[status]}
  </div>
);

export function sourceForService(service: string) {
  switch (service) {
    case "backgroundChecks": // google drive
      return "Google Drive";
    case "certn": // customer Certn
      return "Certn";
    case "checkr": // Vanta checkr
      return "Checkr";
    case "customercheckr": // Customer checkr
      return "Checkr";
    case "vetty": // customer vetty
      return "Vetty";
    default:
      LogError(new Error("Unknown background check service"));
      return null;
  }
}

export function getCurrentTrainingTaskDetails(
  mostRecentTrainingCompletionDate: Maybe<string>,
  startDate: Date,
  onboardingSla: Maybe<number>
): { taskCreationDate: Date; taskDueDate: Date } {
  if (!isSome(mostRecentTrainingCompletionDate)) {
    return {
      taskDueDate: addDays(
        startDate,
        onboardingSla ?? DEFAULT_ONBOARDING_GRACE_PERIOD.asDays()
      ),
      taskCreationDate: startDate,
    };
  }

  const completionDate = dateStringToDate(mostRecentTrainingCompletionDate);
  return {
    taskDueDate: addMonths(completionDate, DEFAULT_RECURRENCE_DUE_MONTHS),
    taskCreationDate: addMonths(
      completionDate,
      DEFAULT_RECURRENCE_DUE_MONTHS - DEFAULT_RECURRENCE_TIME_TO_COMPLETE_MONTHS
    ),
  };
}

export function integrationTrainingTracked(
  requirementsMap: SecurityRequirements,
  trainingRequirement: TrainingRequirement
) {
  const deserialized = deserializeVantaAttributes(
    trainingRequirement.vantaAttributes ?? []
  );
  return (
    (requirementsMap.mustCompleteSecurityTraining &&
      Boolean(deserialized.isGeneralSat)) ||
    (requirementsMap.mustCompleteHipaaSecurityTraining &&
      Boolean(deserialized.isHipaaSat)) ||
    (requirementsMap.mustCompleteGdprSecurityTraining &&
      Boolean(deserialized.isGdprSat)) ||
    (requirementsMap.mustCompletePciSecurityTraining &&
      Boolean(deserialized.isPciSat))
  );
}

export function getTrainingCategories(
  trainingRequirement: TrainingRequirement
): string[] {
  const deserialized = deserializeVantaAttributes(
    trainingRequirement.vantaAttributes ?? []
  );
  const categoryIds: SecurityTrainingCategoryId[] = [];
  if (deserialized.isGeneralSat) {
    categoryIds.push(SecurityTrainingCategoryId.general);
  }
  if (deserialized.isHipaaSat) {
    categoryIds.push(SecurityTrainingCategoryId.hipaa);
  }
  if (deserialized.isGdprSat) {
    categoryIds.push(SecurityTrainingCategoryId.gdpr);
  }
  if (deserialized.isPciSat) {
    categoryIds.push(SecurityTrainingCategoryId.pci);
  }
  const displayNames = categoryIds.map(
    id => TRAINING_CATEGORIES_BY_ID.get(id)!.displayName
  );
  return displayNames;
}

export function getMostRecentlyCompletedTrainingRequirementForCategory(
  category: SecurityTrainingCategoryId,
  trainingRequirements: TrainingRequirement[]
): Maybe<CompletedTrainingRequirement> {
  const attribute = TRAINING_CATEGORIES_BY_ID.get(category)!.vantaAttribute;
  const relevantRequirements = trainingRequirements.filter(tr =>
    Boolean(
      getValueForReservedVantaAttribute(attribute, tr.vantaAttributes ?? [])
    )
  );
  const completedRelevantRequirements: CompletedTrainingRequirement[] =
    relevantRequirements.filter(req =>
      isSome(req.completionDate)
    ) as CompletedTrainingRequirement[];
  if (relevantRequirements.length !== completedRelevantRequirements.length) {
    return null;
  }

  return maxBy(completedRelevantRequirements, req => req.completionDate);
}
