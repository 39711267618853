import "normalize.css";
import "url-search-params-polyfill";
// https://flaviocopes.com/parcel-regeneratorruntime-not-defined/
import "regenerator-runtime/runtime";

import { ApolloProvider } from "@apollo/client";
import { datadogRum } from "@datadog/browser-rum";
import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Userpilot } from "userpilot";

import { UserContextWrapper } from "./components/helpers/UserContextWrapper";
import { ZendeskWidget } from "./components/zendesk-widget/zendesk-widget";
import { Config } from "./config";
import { ShowErrorToast } from "./errors";
import { apolloClient } from "./reducers/index";
import { AppRouter } from "./router";

window.addEventListener("error", e => {
  ShowErrorToast(e.error);
});

if (Config.isStaging) {
  datadogRum.init({
    applicationId: "fe147c92-a476-4efd-b733-24c0b712dd7c",
    clientToken: "pub5be58c436de630b41550fa3f1691dcfb",
    sampleRate: 100,
  });
} else if (!Config.isDev) {
  datadogRum.init({
    applicationId: "37f50307-46bd-4697-b21d-c039ffcb1aaf",
    clientToken: "pub043e3a57772658a58a4bb910ce747aa1",
    sampleRate: 100,
  });
}

const sentryDSN = "https://704484991a7a4d1d9c61a0104160d172@sentry.io/294906";
const sentryEnvironment = Config.isDev
  ? undefined
  : Config.isStaging
  ? "staging"
  : "prod";
Sentry.init({
  dsn: sentryDSN,
  environment: sentryEnvironment,
  debug: Config.isStaging,
  release: Config.sentryRelease,
  attachStacktrace: true,
  integrations: [
    new Integrations.CaptureConsole({
      levels: ["log", "warn", "error", "assert"],
    }),
  ],
  beforeSend: (event: any) => {
    if (Config.isDev) {
      // Uncomment this to debug sentry web-client logging
      // console.info(JSON.stringify(event, null, "\t")); // JSON prettyprint the event that would be sent to sentry, using info so it doesn't get captured.
      return null; // this drops the event and nothing will be send to sentry
    }
    return event;
  },
});

Userpilot.initialize("54lv1k5");

const App = (
  <ApolloProvider client={apolloClient}>
    <UserContextWrapper>
      <Router>
        <AppRouter />
      </Router>
      <ZendeskWidget />
    </UserContextWrapper>
  </ApolloProvider>
);

ReactDOM.render(App, document.getElementById("app"));
