import "./fail-data.scss";

import { Spinner } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import type { TestEntityTypeClientSafe } from "common/constants/displayNames";
import { TestEntityTypeDisplayNames } from "common/constants/displayNames";
import _ from "lodash";
import React, { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import { BASE_PALETTE } from "../../alpaca/base/colors";
import { BASE_TYPOGRAPHY } from "../../alpaca/base/typography";
import { LogErrorMessage } from "../../errors";
import type { FetchTestResultInfoForDetailQuery } from "../../gen/components";
import { SpecificResource } from "../../gen/components";
import { AccountSLATestEntities } from "./test-entity-renderers/account-sla";
import { DefaultResourceTestEntities } from "./test-entity-renderers/default-resource";
import { EndpointTestEntities } from "./test-entity-renderers/endpoint";
import { HerokuDbTestEntity } from "./test-entity-renderers/heroku-db";
import { HrUserTestEntities } from "./test-entity-renderers/hr-user";
import { OsqueryVulnerabilitySLATestEntities } from "./test-entity-renderers/osquery-vulnerability-sla";
import { PolicyTestEntities } from "./test-entity-renderers/policy";
import { RiskScenarioTestEntities } from "./test-entity-renderers/risk-scenario";
import { StringTestEntities } from "./test-entity-renderers/string";
import { TaskSLATestEntities } from "./test-entity-renderers/task-sla";
import { UserTestEntities } from "./test-entity-renderers/user";
import { VendorTestEntities } from "./test-entity-renderers/vendor";
import { VulnerabilitySLATestEntities } from "./test-entity-renderers/vulnerability-sla";
interface IProps {
  testResult: NonNullable<
    FetchTestResultInfoForDetailQuery["organization"]
  >["testResults"][number];
}

type NumFailEntitiesByType = {
  [key in TestEntityTypeClientSafe]?: number;
};

const ENTITY_PAGE_SIZE = 100;

export const FailData: React.FC<IProps> = ({ testResult }) => {
  const [numEntities, setNumEntities] = useState(ENTITY_PAGE_SIZE);

  const sortedEntities = [...testResult.failingEntities].sort((e1, e2) =>
    e1.entityType.localeCompare(e2.entityType)
  );
  const groupedEntities = _.groupBy(sortedEntities, "entityType");

  const slicedGroupEntities = _.groupBy(
    sortedEntities.slice(0, numEntities),
    "entityType"
  );
  const numEntitiesByType: NumFailEntitiesByType = {};
  for (const [entityType, entitiesOfType] of Object.entries(
    slicedGroupEntities
  )) {
    numEntitiesByType[entityType as TestEntityTypeClientSafe] =
      entitiesOfType.length;
  }

  const renderTestEntities = (
    entityType: string,
    numEntitiesOfType: number
  ) => (
    <TestEntities
      key={entityType}
      entityType={entityType as TestEntityTypeClientSafe}
      entityIds={groupedEntities[entityType].map(e => e.entityId)}
      testId={testResult.testId}
      isSLATest={testResult.isSLATest}
      allowWhitelisting={true}
      first={numEntitiesOfType}
    />
  );

  return (
    <InfiniteScroll
      dataLength={numEntities}
      next={() => {
        setNumEntities(numEntities + ENTITY_PAGE_SIZE);
      }}
      hasMore={numEntities < sortedEntities.length}
      loader={<Spinner />}
      scrollableTarget="scroll-div-fail-card"
    >
      {Object.entries(numEntitiesByType).map(
        ([entityType, numEntitiesOfType]) =>
          SLA_TEST_ENTITY_TYPES.has(entityType) ? (
            renderTestEntities(entityType, numEntitiesOfType)
          ) : (
            <div key={`${entityType}-wrapper`}>
              <h5>
                {
                  TestEntityTypeDisplayNames[
                    entityType as TestEntityTypeClientSafe
                  ]
                }
                <text
                  style={{
                    color: BASE_PALETTE.CHARCOAL,
                    fontWeight: BASE_TYPOGRAPHY.FONT_WEIGHTS.NORMAL,
                    marginLeft: "8px",
                  }}
                >
                  {groupedEntities[entityType].length}
                </text>
              </h5>
              {renderTestEntities(entityType, numEntitiesOfType)}
            </div>
          )
      )}
    </InfiniteScroll>
  );
};

export const TestEntities: React.FC<{
  entityType: TestEntityTypeClientSafe;
  entityIds: string[];
  testId: string;
  isSLATest: boolean;
  allowWhitelisting: boolean;
  first: number;
  renderingIndividually?: Maybe<boolean>; // true if you're rendering only one entity and don't want boilerplate that goes with a list
  // eslint-disable-next-line complexity
}> = ({
  entityType,
  entityIds,
  testId,
  isSLATest,
  allowWhitelisting,
  renderingIndividually,
  first,
}) => {
  switch (entityType) {
    // Non-resource entities
    case "HerokuDB":
      return (
        <HerokuDbTestEntity
          entityType={entityType}
          entityIds={entityIds}
          testId={testId}
          allowWhitelisting={allowWhitelisting}
          first={first}
        />
      );
    case "Osquery":
      return (
        <EndpointTestEntities
          entityType={entityType}
          entityIds={entityIds}
          testId={testId}
          allowWhitelisting={allowWhitelisting}
          first={first}
        />
      );
    case "Policy":
      return (
        <PolicyTestEntities
          entityType={entityType}
          entityIds={entityIds}
          testId={testId}
          allowWhitelisting={allowWhitelisting}
          first={first}
        />
      );
    case "RiskScenario":
      return (
        <RiskScenarioTestEntities
          entityType={entityType}
          entityIds={entityIds}
          testId={testId}
          allowWhitelisting={allowWhitelisting}
          first={first}
        />
      );
    case "String":
      return (
        <StringTestEntities
          entityType={entityType}
          entityIds={entityIds}
          testId={testId}
          allowWhitelisting={allowWhitelisting}
          first={first}
        />
      );
    case "User":
      return (
        <UserTestEntities
          entityType={entityType}
          entityIds={entityIds}
          testId={testId}
          allowWhitelisting={allowWhitelisting}
          first={first}
        />
      );
    case "Vendor":
      return (
        <VendorTestEntities
          entityType={entityType}
          entityIds={entityIds}
          testId={testId}
          allowWhitelisting={allowWhitelisting}
          first={first}
        />
      );

    // Resource entities with custom rendering logic
    case "AwsContainerVulnerability":
    case "AwsInspectorVulnerability":
    case "AzureContainerVulnerability":
    case "GCPContainerVulnerability":
    case "SnykVulnerability":
      if (isSLATest) {
        return (
          <VulnerabilitySLATestEntities
            entityType={entityType as SpecificResource}
            entityIds={entityIds}
            testId={testId}
            allowWhitelisting={allowWhitelisting}
            renderingIndividually={renderingIndividually ?? false}
            first={first}
          />
        );
      } else {
        LogErrorMessage("Trying to render vulnerability without SLA");
        return (
          <DefaultResourceTestEntities
            entityType={entityType as SpecificResource}
            entityIds={entityIds}
            testId={testId}
            allowWhitelisting={allowWhitelisting}
            first={first}
          />
        );
      }

    // Rendering agent vulnerabilities uses a different component
    // as it involves pulling data from other collections
    case "OsqueryVulnerability": {
      if (isSLATest) {
        return (
          <OsqueryVulnerabilitySLATestEntities
            entityType={entityType}
            entityIds={entityIds}
            testId={testId}
            allowWhitelisting={allowWhitelisting}
            renderingIndividually={renderingIndividually ?? false}
            first={first}
          />
        );
      } else {
        LogErrorMessage("Trying to render vulnerability without SLA");
        return (
          <DefaultResourceTestEntities
            entityType={entityType as SpecificResource}
            entityIds={entityIds}
            testId={testId}
            allowWhitelisting={allowWhitelisting}
            first={first}
          />
        );
      }
    }

    case "HrUser": {
      return (
        <HrUserTestEntities
          entityIds={entityIds}
          testId={testId}
          first={first}
        />
      );
    }

    // should just check membership in a list generated by
    // specificResourceByGenericKind
    case "AsanaAccount":
    case "AwsAccount":
    case "BitbucketAccount":
    case "ClubhouseAccount":
    case "DatadogAccount":
    case "GithubAccount":
    case "GitlabAccount":
    case "HerokuAccount":
    case "JiraAccount":
    case "LinearAccount":
    case "SlackAccount":
    case "TrelloAccount": {
      if (isSLATest) {
        return (
          <AccountSLATestEntities
            entityType={entityType as SpecificResource}
            entityIds={entityIds}
            testId={testId}
            allowWhitelisting={allowWhitelisting}
            renderingIndividually={renderingIndividually ?? false}
            first={first}
          />
        );
      } else {
        return (
          <DefaultResourceTestEntities
            entityType={entityType as SpecificResource}
            entityIds={entityIds}
            testId={testId}
            allowWhitelisting={allowWhitelisting}
            first={first}
          />
        );
      }
    }
    case "AirtableTask":
    case "AsanaTask":
    case "ClubhouseTask":
    case "GithubTask":
    case "JiraTask":
    case "LinearTask":
    case "PivotalTrackerTask":
    case "TrelloTask":
      if (isSLATest) {
        return (
          <TaskSLATestEntities
            entityType={entityType as SpecificResource}
            entityIds={entityIds}
            testId={testId}
            allowWhitelisting={allowWhitelisting}
            renderingIndividually={renderingIndividually ?? false}
            first={first}
          />
        );
      } else {
        return (
          <DefaultResourceTestEntities
            entityType={entityType as SpecificResource}
            entityIds={entityIds}
            testId={testId}
            allowWhitelisting={allowWhitelisting}
            first={first}
          />
        );
      }

    default: {
      if (entityType in SpecificResource) {
        return (
          <DefaultResourceTestEntities
            entityType={entityType as SpecificResource}
            entityIds={entityIds}
            testId={testId}
            allowWhitelisting={allowWhitelisting}
            first={first}
          />
        );
      }
      LogErrorMessage(`Trying to render unknown entity type: ${entityType}`);
      return (
        <StringTestEntities
          entityType={entityType}
          entityIds={entityIds}
          testId={testId}
          allowWhitelisting={allowWhitelisting}
          first={first}
        />
      );
    }
  }
};

const VULNERABILITY_SLA_TEST_TYPES = [
  "AwsContainerVulnerability",
  "AwsInspectorVulnerability",
  "AzureContainerVulnerability",
  "GCPContainerVulnerability",
  "SnykVulnerability",
];

const AGENT_VULNERABILITY_SLA_TEST_TYPES = ["OsqueryVulnerability"];

const ACCOUNT_SLA_TEST_TYPES = [
  "AsanaAccount",
  "AwsAccount",
  "BitbucketAccount",
  "ClubhouseAccount",
  "DatadogAccount",
  "GithubAccount",
  "GitlabAccount",
  "HerokuAccount",
  "JiraAccount",
  "LinearAccount",
  "SlackAccount",
  "TrelloAccount",
];

const TASK_SLA_TEST_TYPES = [
  "AirtableTask",
  "AsanaTask",
  "ClubhouseTask",
  "GithubTask",
  "JiraTask",
  "LinearTask",
  "PivotalTrackerTask",
  "TrelloTask",
];

// Should match the switch cases in TestEntities
const SLA_TEST_ENTITY_TYPES = new Set([
  ...VULNERABILITY_SLA_TEST_TYPES,
  ...AGENT_VULNERABILITY_SLA_TEST_TYPES,
  ...ACCOUNT_SLA_TEST_TYPES,
  ...TASK_SLA_TEST_TYPES,
]);
