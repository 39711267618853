import { Tab } from "@blueprintjs/core";
import { Feature } from "common/base/types/gen";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";

import { BASE_PALETTE } from "../../../alpaca/base/colors";
import { DefaultView, HeaderlessTabs } from "../../../alpaca/components";
import { LogError } from "../../../errors";
import type {
  FetchVulnConfigInfoQuery,
  OpenVulnerabilitiesCountQuery,
} from "../../../gen/components";
import {
  useFetchVulnConfigInfoQuery,
  useOpenVulnerabilitiesCountQuery,
} from "../../../gen/components";
import { useFeatureCheck } from "../../../helpers/feature-gating/feature-check";
import { AgentVulnDisplay } from "./agent/agent-vuln-display";
import { PackageVulnDetail } from "./agent/package-vuln-detail";
import { ServerDetail } from "./agent/server-detail";
import { AWSInspectorVulnDisplay } from "./aws-inspector/aws-inspector-vuln-display";
import { awsInspectorInstanceUrlSearchParamKey } from "./aws-inspector/constants";
import { InstanceDetail } from "./aws-inspector/instance-detail";
import { AWSContainerRepositoryDetail } from "./aws/aws-container-repository-detail";
import { AWSVulnDisplay } from "./aws/aws-vuln-display";
import { AzureContainerRepositoryDetail } from "./azure/azure-container-repository-detail";
import { AzureVulnDisplay } from "./azure/azure-vuln-display";
import { GCPContainerRepositoryDetail } from "./gcp/gcp-container-repository-detail";
import { GCPVulnDisplay } from "./gcp/gcp-vuln-display";
import {
  HistoryType,
  VulnHistoryDisplay,
} from "./history/vuln-history-display";
import { SnykProjectDetail } from "./snyk/snyk-project-detail";
import { SnykVulnDisplay } from "./snyk/snyk-vuln-display";
import { VulnsEmptyState } from "./vulns-empty-state";

// If you're changing the ID of a vulnerabilities tab, be sure to change
// the corresponding tab ID linked by the New Vulnerabilities email.
export const TAB_IDS = {
  agent: "agent-vulns",
  awsContainers: "aws-container-vulns",
  awsInspector: "aws-inspector-vulns",
  azureContainers: "azure-container-vulns",
  gcpContainers: "gcp-container-vulns",
  snyk: "snyk-vulns",
  slaViolations: "sla-violations",
  onTimeRemediations: "ontime-remediations",
  remediations: "remediations",
  zeroState: "zeroState",
};

type ConfigData = NonNullable<FetchVulnConfigInfoQuery>;
type VulnerabilityCountData = Maybe<OpenVulnerabilitiesCountQuery>;
type TabInfo = {
  id: string;
  title: React.ReactNode;
  panel: JSX.Element;
};

// Red dot for indicating a tab with open vulnerabilities
const TabIndicator = styled.span`
  width: 8px;
  height: 8px;
  background-color: ${BASE_PALETTE.TOMATO};
  margin-left: 4px;
  border-radius: 50%;
  display: inline-block;
`;

const getOpenVulnerabilityTabs = (
  configData: ConfigData,
  vulnerabilityCountData: VulnerabilityCountData,
  serverAgentEnabled: boolean
) => {
  const tabInfo: TabInfo[] = [];

  // As we add AWS, GCP, Azure, Snyk, etc, we'll add tabs to the list conditionally if they're linked.
  const hasAWSCredentials = configData.organization.credentials.some(
    credential => credential.service === "aws"
  );
  if (serverAgentEnabled) {
    // Agent vulnerabilities
    tabInfo.push({
      id: TAB_IDS.agent,
      title: (
        <span>
          Vanta Agent issues
          {(vulnerabilityCountData?.organization?.agentVulnerabilities
            .totalCount ?? 0) > 0 ? (
            <TabIndicator />
          ) : null}
        </span>
      ),
      panel: <AgentVulnDisplay hasAwsCredentials={hasAWSCredentials} />,
    });
  }

  if (hasAWSCredentials) {
    // AWS container vulnerabilities
    tabInfo.push({
      id: TAB_IDS.awsContainers,
      title: (
        <span>
          AWS container issues
          {(vulnerabilityCountData?.organization?.awsVulnerabilities
            .totalCount ?? 0) > 0 ? (
            <TabIndicator />
          ) : null}
        </span>
      ),
      panel: <AWSVulnDisplay />,
    });
    // Make it the first tab (`unshift`)
    tabInfo.unshift({
      id: TAB_IDS.awsInspector,
      title: (
        <span>
          AWS Inspector issues
          {(vulnerabilityCountData?.organization?.awsInspectorVulnerabilities
            .totalCount ?? 0) > 0 ? (
            <TabIndicator />
          ) : null}
        </span>
      ),
      panel: <AWSInspectorVulnDisplay />,
    });
  }

  const hasGCPCredentials = configData.organization.credentials.some(
    credential => credential.service === "gcp"
  );
  if (hasGCPCredentials) {
    // GCP Container vulnerabilities
    tabInfo.push({
      id: TAB_IDS.gcpContainers,
      title: (
        <span>
          GCP container issues
          {(vulnerabilityCountData?.organization?.gcpVulnerabilities
            .totalCount ?? 0) > 0 ? (
            <TabIndicator />
          ) : null}
        </span>
      ),
      panel: <GCPVulnDisplay />,
    });
  }

  const hasAzureCredentials = configData.organization.credentials.some(
    credential => credential.service === "azure"
  );
  if (hasAzureCredentials) {
    // Azure Container Vulnerabilities
    tabInfo.push({
      id: TAB_IDS.azureContainers,
      title: (
        <span>
          Azure container issues
          {(vulnerabilityCountData?.organization?.azureVulnerabilities
            .totalCount ?? 0) > 0 ? (
            <TabIndicator />
          ) : null}
        </span>
      ),
      panel: <AzureVulnDisplay />,
    });
  }

  const hasSnykCredentials = configData.organization.credentials.some(
    credential => credential.service === "snyk"
  );
  if (hasSnykCredentials) {
    // Snyk vulnerabilities
    tabInfo.push({
      id: TAB_IDS.snyk,
      title: (
        <span>
          Snyk issues
          {(vulnerabilityCountData?.organization?.snykVulnerabilities
            .totalCount ?? 0) > 0 ? (
            <TabIndicator />
          ) : null}
        </span>
      ),
      panel: <SnykVulnDisplay />,
    });
  }

  return tabInfo;
};

const getPastVulnerabilityTabs = (configData: ConfigData) => {
  const tabInfo: TabInfo[] = [];

  // If the domain has SLAs configured, we split the history into two tabs: violations and on-time remediations.
  // Otherwise, we display all vulns together until "Remediated vulnerabilities".
  if (isSome(configData?.organization.vulnerabilitySlas)) {
    // SLA violations
    tabInfo.push({
      id: TAB_IDS.slaViolations,
      title: "SLA violations",
      panel: <VulnHistoryDisplay historyType={HistoryType.SLA_VIOLATIONS} />,
    });

    // On-time remediations
    tabInfo.push({
      id: TAB_IDS.onTimeRemediations,
      title: "On-time remediations",
      panel: (
        <VulnHistoryDisplay historyType={HistoryType.ON_TIME_REMEDIATIONS} />
      ),
    });
  } else {
    // Historical vulnerabilities
    tabInfo.push({
      id: TAB_IDS.remediations,
      title: "Past remediations",
      panel: <VulnHistoryDisplay historyType={HistoryType.ALL_VULNS} />,
    });
  }

  return tabInfo;
};

export const VulnsPage: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState<string>("");
  const {
    error: configError,
    loading: configLoading,
    data: configData,
  } = useFetchVulnConfigInfoQuery({});
  const {
    error: countError,
    loading: countLoading,
    data: countData,
  } = useOpenVulnerabilitiesCountQuery();

  const history = useHistory();

  let openVulnerabilityTabs: TabInfo[] = [];
  let pastVulnerabilityTabs: TabInfo[] = [];
  useEffect(() => {
    if (location.hash.length > 1) {
      const topTabId = location.hash.slice(1).split("/")[0];
      if (Object.values(TAB_IDS).includes(topTabId)) {
        setSelectedTab(topTabId);
      }
    } else {
      if (openVulnerabilityTabs.length === 0) {
        setSelectedTab(TAB_IDS.zeroState);
      } else {
        setSelectedTab(openVulnerabilityTabs[0].id);
      }
    }
  }, [location.hash, openVulnerabilityTabs, pastVulnerabilityTabs]);

  const serverAgentEnabled = useFeatureCheck(Feature.LegacyEnableServerAgent);

  // Show package vulnerability details page if URL asks for individual package vulnerability
  const vulnerablePackageId = new URLSearchParams(location.search).get(
    "vulnId"
  );
  if (serverAgentEnabled && isSome(vulnerablePackageId)) {
    return <PackageVulnDetail vulnerablePackageId={vulnerablePackageId} />;
  }

  // Show server details page if URL asks for individual server
  const serverId = new URLSearchParams(location.search).get("serverId");
  if (serverAgentEnabled && isSome(serverId)) {
    return <ServerDetail serverId={serverId} />;
  }

  const awsInspectorEc2ResourceId = new URLSearchParams(location.search).get(
    awsInspectorInstanceUrlSearchParamKey
  );
  if (isSome(awsInspectorEc2ResourceId)) {
    return <InstanceDetail ec2ResourceId={awsInspectorEc2ResourceId} />;
  }

  // Show container details pages if URL asks for containers
  const maybeDetailPage = getDetailPageFromSearchParams();
  if (isSome(maybeDetailPage)) {
    return maybeDetailPage;
  }

  if (configError) {
    LogError(configError);
    return <div />;
  }

  if (countError) {
    LogError(countError);
  }

  if (
    configLoading ||
    !isSome(configData) ||
    countLoading ||
    !isSome(countData)
  ) {
    return <div />;
  }

  openVulnerabilityTabs = getOpenVulnerabilityTabs(
    configData,
    countData,
    serverAgentEnabled
  );
  pastVulnerabilityTabs = getPastVulnerabilityTabs(configData);

  let allTabInfo: TabInfo[] = [];

  // Handle case where there are no vulnerability providers linked
  if (openVulnerabilityTabs.length === 0) {
    const hasPastVulnerabilities =
      (countData?.organization.pastVulnerabilities.totalCount ?? 0) > 0;
    if (hasPastVulnerabilities) {
      allTabInfo = [
        {
          id: TAB_IDS.zeroState,
          title: "Open vulnerabilities",
          panel: <VulnsEmptyState />,
        },
        ...pastVulnerabilityTabs,
      ];
    }
  } else {
    allTabInfo = [...openVulnerabilityTabs, ...pastVulnerabilityTabs];
  }

  return (
    <DefaultView
      headerProps={{
        title: "Vulnerabilities",
        tabProps:
          allTabInfo.length > 0
            ? {
                id: "vuln-tabs",
                tabIds: allTabInfo,
                selectedTabId: selectedTab ?? allTabInfo[0].id,
                onChange: newId => {
                  history.push({
                    pathname: location.pathname,
                    hash: newId.toString(),
                  });
                },
              }
            : undefined,
      }}
    >
      {allTabInfo.length > 0 ? (
        <HeaderlessTabs
          id={"vuln-page-tabs-hidden"}
          animate={true}
          renderActiveTabPanelOnly={true}
          selectedTabId={selectedTab}
        >
          {allTabInfo.map(tabInfo => (
            <Tab
              id={tabInfo.id}
              key={tabInfo.id}
              title={tabInfo.title}
              panel={tabInfo.panel}
            />
          ))}
        </HeaderlessTabs>
      ) : (
        <VulnsEmptyState />
      )}
    </DefaultView>
  );
};

function getDetailPageFromSearchParams() {
  const searchParams = new URLSearchParams(location.search);
  const awsRepositoryId = searchParams.get("awsRepositoryId");
  if (isSome(awsRepositoryId)) {
    return <AWSContainerRepositoryDetail resourceId={awsRepositoryId} />;
  }

  const azureRepositoryId = searchParams.get("azureRepositoryId");
  if (isSome(azureRepositoryId)) {
    return <AzureContainerRepositoryDetail resourceId={azureRepositoryId} />;
  }

  const gcpRepositoryId = searchParams.get("gcpRepositoryId");
  if (isSome(gcpRepositoryId)) {
    return <GCPContainerRepositoryDetail resourceId={gcpRepositoryId} />;
  }

  const snykProjectId = searchParams.get("snykProjectId");
  if (isSome(snykProjectId)) {
    return <SnykProjectDetail resourceId={snykProjectId} />;
  }
  return null;
}

gql`
  query fetchVulnConfigInfo {
    organization {
      id
      credentials(
        services: ["aws", "azure", "gcp", "snyk"]
        includeDisabled: true
      ) {
        id
        service
      }
      vulnerabilitySlas {
        vulnHighSeverity
        vulnLowSeverity
        vulnMediumSeverity
      }
    }
  }

  query openVulnerabilitiesCount {
    organization {
      id
      agentVulnerabilities: resources(
        specificResourceType: OsqueryVulnerability
        first: 0
        excludeWhitelistedTestIds: [
          "infra-packages-checked-for-vulnerabilities-records-closed"
        ]
      ) {
        totalCount
      }
      gcpVulnerabilities: resources(
        specificResourceType: GCPContainerVulnerability
        first: 0
        excludeWhitelistedTestIds: [
          "container-packages-checked-for-vulnerabilities-records-closed-gcp"
        ]
      ) {
        totalCount
      }
      awsVulnerabilities: resources(
        specificResourceType: AwsContainerVulnerability
        first: 0
        excludeWhitelistedTestIds: [
          "container-packages-checked-for-vulnerabilities-records-closed-aws"
        ]
      ) {
        totalCount
      }
      awsInspectorVulnerabilities: resources(
        specificResourceType: AwsInspectorVulnerability
        first: 0
        excludeWhitelistedTestIds: [
          "aws-inspector-packages-checked-for-vulnerabilities-records-closed"
        ]
      ) {
        totalCount
      }
      azureVulnerabilities: resources(
        specificResourceType: AzureContainerVulnerability
        first: 0
        excludeWhitelistedTestIds: [
          "container-packages-checked-for-vulnerabilities-records-closed-azure"
        ]
      ) {
        totalCount
      }
      snykVulnerabilities: resources(
        specificResourceType: SnykVulnerability
        first: 0
        excludeWhitelistedTestIds: [
          "packages-checked-for-vulnerabilities-records-closed-snyk"
        ]
      ) {
        totalCount
      }

      # Used to look up if there is at least one past vulnerability
      pastVulnerabilities: resources(
        genericResourceType: Vulnerability
        first: 1
        options: { includeDeleted: true, includeOutOfScope: false }
        filterParams: {
          operator: AND
          filters: [{ field: "deletedAt", condition: { IS_NULL: false } }]
        }
      ) {
        totalCount
      }
    }
  }
`;
