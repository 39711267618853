import type { Maybe } from "common/base/types/maybe";
import { applyIfSome } from "common/base/types/maybe";
import React from "react";
import styled from "styled-components";
import { BASE_PALETTE } from "../../base/colors";
import { GRID_SPACING } from "../../base/grid";
import { BASE_TYPOGRAPHY } from "../../base/typography";
import { SpacedList } from "../primitives/spaced-list";

interface IProps {
  mainText: React.ReactNode;
  description?: Maybe<React.ReactNode>;
  buttons?: Maybe<JSX.Element[]>;
  // eslint-disable-next-line vanta/optional-always-maybe, vanta/prefer-maybe
  className?: string | undefined;
  // eslint-disable-next-line vanta/optional-always-maybe, vanta/prefer-maybe
  style?: React.CSSProperties | undefined;
}

export const EmptyStateCard: React.FC<IProps> = ({
  mainText,
  description,
  buttons,
  className,
  style,
}) => (
  <StyledContainerDiv className={className} style={style}>
    <StyledContentDiv as="div">{mainText}</StyledContentDiv>
    {applyIfSome(description, node => (
      <StyledDescriptionDiv>{node}</StyledDescriptionDiv>
    ))}
    {applyIfSome(buttons, elements => (
      <SpacedList style={{ marginTop: 2 * GRID_SPACING }}>
        {elements}
      </SpacedList>
    ))}
  </StyledContainerDiv>
);

const StyledContentDiv = styled.div`
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  max-width: 80%;
  font-weight: ${BASE_TYPOGRAPHY.FONT_WEIGHTS.SLIGHTLY_BOLD};
`;

const StyledDescriptionDiv = styled.div`
  margin-top: 4px;
  text-align: center;
  max-width: 80%;
  line-height: 20px;
  font-size: 14px;
  color: ${BASE_PALETTE.CHARCOAL};
`;

const StyledContainerDiv = styled.div`
  padding: 24px;
  background-color: ${BASE_PALETTE.SNOW};
  border-radius: 4px;
  border: 1px solid ${BASE_PALETTE.WIND};
  display: flex;
  flex-direction: column;
  align-items: center;
`;
