import "./toaster.scss";

import type { IToaster } from "@blueprintjs/core";
import { Position, Toaster } from "@blueprintjs/core";
import classnames from "classnames";

export const AppToaster: IToaster = Toaster.create({
  className: classnames("bp3-minimal", "vanta-toaster-fixed"),
  maxToasts: 1,
  position: Position.TOP_RIGHT,
});
