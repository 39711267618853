import type { ApolloCache } from "@apollo/client";
import { Button, Intent, Spinner } from "@blueprintjs/core";
import { isSome } from "common/base/types/maybe";
import { deserializeVantaAttributes } from "common/utils/vantaAttributes";
import gql from "graphql-tag";
import _ from "lodash";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import { LogError } from "../../../../errors";
import type {
  InventoryCountsQuery,
  OtherInventoryItemsQuery,
} from "../../../../gen/components";
import {
  InventoryCountsDocument,
  OtherInventoryItemsDocument,
  useDeleteOtherInventoryItemMutation,
  useOtherInventoryItemsQuery,
  useSetOtherInventoryItemNameMutation,
  useSetOtherInventoryItemVantaAttributeMutation,
} from "../../../../gen/components";
import { InventoryCard } from "../inventory-card";
import {
  getDebounceContext,
  getResourceFilterParams,
  PAGE_SIZE,
  setOtherInventoryItemVantaAttributeFn,
} from "../utils";
import { SearchResultsSummary } from "./search-results-summary";
import type { IInventoryTabProps } from "./shared-interface";

export function updateOtherInventoryItemsInCache(
  cache: ApolloCache<any>,
  previousDomainData: NonNullable<OtherInventoryItemsQuery["organization"]>,
  newEdges: NonNullable<
    OtherInventoryItemsQuery["organization"]
  >["otherInventoryItems"]["edges"]
) {
  cache.writeQuery<OtherInventoryItemsQuery>({
    query: OtherInventoryItemsDocument,
    variables: { first: PAGE_SIZE, filterParams: null },
    data: {
      organization: {
        ...previousDomainData,
        otherInventoryItems: {
          ...previousDomainData.otherInventoryItems,
          edges: newEdges,
        },
      },
    },
  });
}

export function updateOtherInventoryItemsCountInCache(
  cache: ApolloCache<any>,
  delta: number
) {
  const previousCounts = cache.readQuery<InventoryCountsQuery>({
    query: InventoryCountsDocument,
  });
  if (!isSome(previousCounts) || !isSome(previousCounts.organization)) {
    return;
  }
  cache.writeQuery<InventoryCountsQuery>({
    query: InventoryCountsDocument,
    data: {
      organization: {
        ...previousCounts.organization,
        otherinventoryitems: {
          totalCount:
            previousCounts.organization.otherinventoryitems.totalCount + delta,
          __typename: "OtherInventoryConnection",
        },
      },
    },
  });
}

export const Other: React.FC<IInventoryTabProps> = ({ searchString }) => {
  const { data, fetchMore, loading } = useOtherInventoryItemsQuery({
    variables: {
      first: PAGE_SIZE,
      filterParams: getResourceFilterParams(searchString),
    },
    context: getDebounceContext("other"),
  });

  if (loading) {
    return <Spinner />;
  }

  const cards = data?.organization.otherInventoryItems.edges.map(item => (
    <OtherInventoryItemCard
      key={item.node.id}
      item={item}
      domain={data.organization}
    />
  ));

  return (
    <>
      <SearchResultsSummary
        searchString={searchString}
        numberResults={data?.organization.otherInventoryItems?.totalCount ?? 0}
      />
      <InfiniteScroll
        className="inventory-list-card-group"
        dataLength={data?.organization.otherInventoryItems.edges.length ?? 0}
        next={async () =>
          fetchMore({
            variables: {
              after: data?.organization.otherInventoryItems.pageInfo.endCursor,
              first: PAGE_SIZE,
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
              if (
                !previousResult?.organization.otherInventoryItems.pageInfo
                  .hasNextPage
              ) {
                return previousResult;
              }
              const newResult = _.cloneDeep(previousResult);

              newResult.organization.otherInventoryItems.edges = [
                ...(previousResult.organization.otherInventoryItems.edges ??
                  []),
                ...(fetchMoreResult?.organization.otherInventoryItems.edges ??
                  []),
              ];
              newResult.organization.otherInventoryItems.pageInfo =
                fetchMoreResult!.organization.otherInventoryItems.pageInfo!;

              return newResult;
            },
          })
        }
        hasMore={
          data?.organization.otherInventoryItems.pageInfo.hasNextPage ?? false
        }
        loader={<Spinner />}
      >
        {cards}
      </InfiniteScroll>
    </>
  );
};

const OtherInventoryItemCard: React.FC<{
  item: NonNullable<
    OtherInventoryItemsQuery["organization"]
  >["otherInventoryItems"]["edges"][number];
  domain: NonNullable<OtherInventoryItemsQuery["organization"]>;
}> = ({ item, domain }) => {
  const [setOtherInventoryItemVantaAttribute] =
    useSetOtherInventoryItemVantaAttributeMutation();
  const [setOtherInventoryItemName] = useSetOtherInventoryItemNameMutation();
  const [deleteOtherInventoryItem] = useDeleteOtherInventoryItemMutation();

  const vantaAttributes = deserializeVantaAttributes(
    item.node.vantaAttributes ?? []
  );
  const setVantaAttribute = setOtherInventoryItemVantaAttributeFn(
    setOtherInventoryItemVantaAttribute,
    item.node.id
  );

  return (
    <InventoryCard
      type={"Other"}
      uid={item.node.id}
      key={item.node.id}
      name={item.node.name ?? ""}
      canSetName={true}
      setName={async (name: string) => {
        const result = await setOtherInventoryItemName({
          variables: {
            itemMongoId: item.node.id,
            name,
          },
        });
        if (result.errors) {
          LogError(new Error(JSON.stringify(result)));
        }
      }}
      description={vantaAttributes.description}
      owner={vantaAttributes.ownerId}
      canContainUserData={true}
      userDataDescription={vantaAttributes.userDataStored}
      containsUserData={vantaAttributes.containsUserData}
      setVantaAttribute={setVantaAttribute}
    >
      <div className="inventory-list-card-delete">
        <Button
          icon="trash"
          intent={Intent.DANGER}
          onClick={async () =>
            deleteOtherInventoryItem({
              variables: { itemMongoId: item.node.id },
              update: (cache, result) => {
                const previousData = cache.readQuery<OtherInventoryItemsQuery>({
                  query: OtherInventoryItemsDocument,
                  variables: {
                    first: PAGE_SIZE,
                    filterParams: null,
                  },
                });
                if (isSome(previousData) && isSome(previousData.organization)) {
                  updateOtherInventoryItemsInCache(
                    cache,
                    previousData.organization,
                    previousData.organization.otherInventoryItems.edges.filter(
                      edge => edge.node.id !== item.node.id
                    )
                  );
                }
                updateOtherInventoryItemsCountInCache(cache, -1);
              },
            })
          }
        >
          Remove
        </Button>
      </div>
    </InventoryCard>
  );
};

gql`
  query otherInventoryItems(
    $first: Int!
    $after: String
    $filterParams: filterParams
  ) {
    organization {
      id
      otherInventoryItems(
        first: $first
        after: $after
        sortParams: { field: "createdAt", direction: -1 }
        filterParams: $filterParams
      ) {
        totalCount
        edges {
          node {
            id
            name
            vantaAttributes {
              key
              value
              managedExternally
            }
          }
        }
        pageInfo {
          hasNextPage
          endCursor
          startCursor
          hasPreviousPage
        }
      }
    }
  }
`;

gql`
  mutation setOtherInventoryItemVantaAttribute(
    $itemMongoId: String!
    $key: String!
    $value: String
  ) {
    setOtherInventoryItemVantaAttribute(
      itemMongoId: $itemMongoId
      key: $key
      value: $value
    ) {
      key
      value
      managedExternally
    }
  }
`;

gql`
  mutation setOtherInventoryItemName($itemMongoId: String!, $name: String!) {
    setOtherInventoryItemName(itemMongoId: $itemMongoId, name: $name)
  }
`;

gql`
  mutation deleteOtherInventoryItem($itemMongoId: String!) {
    deleteOtherInventoryItem(itemMongoId: $itemMongoId)
  }
`;
