import { Button, Intent, Spinner } from "@blueprintjs/core";
import { isSome } from "common/base/types/maybe";
import { deserializeVantaAttributes } from "common/utils/vantaAttributes";
import gql from "graphql-tag";
import _ from "lodash";
import React, { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import type { InventoryCountsQuery } from "../../../../gen/components";
import {
  InventoryCountsDocument,
  useDisableOsqueryEndpointByIdMutation,
  useOtherLinuxServersQuery,
  useSetInventoryOsqueryVantaAttributeMutation,
} from "../../../../gen/components";
import { InventoryCard } from "../inventory-card";
import { SshAccessTables } from "../ssh-access/ssh-access-tables";
import { FullWidthFormGroup } from "../ssh-access/ssh-config-tables";
import {
  getDebounceContext,
  getResourceFilterParams,
  PAGE_SIZE,
  setOsqueryVantaAttributeFn,
} from "../utils";
import { SearchResultsSummary } from "./search-results-summary";
import type { IInventoryTabProps } from "./shared-interface";

export const LinuxServers: React.FC<IInventoryTabProps> = ({
  searchString,
}) => {
  const { data, fetchMore, loading } = useOtherLinuxServersQuery({
    variables: {
      first: PAGE_SIZE,
      filterParams: getResourceFilterParams(searchString),
    },
    context: getDebounceContext("linux-server"),
  });

  const [disableEndpoint] = useDisableOsqueryEndpointByIdMutation({
    update: (cache, result) => {
      // Update the linux servers count in the dropdown menu
      const previousCounts = cache.readQuery<InventoryCountsQuery>({
        query: InventoryCountsDocument,
      });
      if (!isSome(previousCounts) || !isSome(previousCounts.organization)) {
        return;
      }
      cache.writeQuery<InventoryCountsQuery>({
        query: InventoryCountsDocument,
        data: {
          organization: {
            ...previousCounts.organization,
            servers: {
              totalCount: previousCounts.organization.servers.totalCount - 1,
            },
          },
        },
      });
    },
  });
  const [setOsqueryVantaAttribute] =
    useSetInventoryOsqueryVantaAttributeMutation();

  // deactivate the "delete" button on those that have already been clicked.
  // best would be to remove the entry from the apollo cache, but that has proven
  // tricky. This is a hack to use our own logic instead of the apollo cache
  // for real-time feedback.
  const [deleting, setDeleting] = useState(new Set<string>());

  if (loading) {
    return <Spinner />;
  }

  const cards = data?.organization.machines.edges?.map(item => {
    const osqueryMongoId = item.node.id;

    const vantaAttributes = deserializeVantaAttributes(
      item.node.vantaAttributes ?? []
    );
    const setVantaAttribute = setOsqueryVantaAttributeFn(
      setOsqueryVantaAttribute,
      osqueryMongoId
    );

    if (deleting.has(item.node.id)) {
      return null;
    }

    const hasKeys =
      "authorizedKeys" in item.node.data &&
      (Boolean(item.node.data.sshKeys?.length) ||
        Boolean(item.node.data.authorizedKeys?.length));

    return (
      <InventoryCard
        type={"Linux Server"}
        uid={item.node.hostIdentifier}
        labels={[]}
        key={item.node.id}
        name={item.node.prettyName}
        description={vantaAttributes.description}
        owner={vantaAttributes.ownerId}
        canContainUserData={false}
        setVantaAttribute={setVantaAttribute}
      >
        {hasKeys ? (
          <div>
            <hr />
            <FullWidthFormGroup
              label="SSH Access"
              helperText="What machines can communicate with this one over SSH?"
            >
              <SshAccessTables data={item.node.data} osqueryId={item.node.id} />
            </FullWidthFormGroup>
          </div>
        ) : null}
        <div className="inventory-list-card-delete">
          <Button
            icon="trash"
            intent={Intent.DANGER}
            onClick={async () => {
              setDeleting(old => old.add(item.node.id));
              await disableEndpoint({
                variables: {
                  domainId: data.organization.id,
                  id: item.node.id,
                },
              });
            }}
          >
            Remove
          </Button>
        </div>
      </InventoryCard>
    );
  });

  return (
    <>
      <SearchResultsSummary
        searchString={searchString}
        numberResults={data?.organization.machines?.totalCount ?? 0}
      />
      <InfiniteScroll
        className="inventory-list-card-group"
        dataLength={data?.organization.machines.edges.length ?? 0}
        next={async () =>
          fetchMore({
            variables: {
              after: data?.organization.machines.pageInfo.endCursor,
              first: PAGE_SIZE,
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
              if (!previousResult?.organization.machines.pageInfo.hasNextPage) {
                return previousResult;
              }
              const newResult = _.cloneDeep(previousResult);

              newResult.organization.machines.edges = [
                ...(previousResult.organization.machines.edges ?? []),
                ...(fetchMoreResult?.organization.machines.edges ?? []),
              ];
              newResult.organization.machines.pageInfo =
                fetchMoreResult!.organization.machines.pageInfo!;

              return newResult;
            },
          })
        }
        hasMore={data?.organization.machines.pageInfo.hasNextPage ?? false}
        loader={<Spinner />}
      >
        {cards}
      </InfiniteScroll>
    </>
  );
};

gql`
  query otherLinuxServers(
    $first: Int!
    $after: String
    $filterParams: filterParams
  ) {
    organization {
      id
      name
      machines(
        activeOnly: true
        serversOnly: true
        nonCloudOnly: true
        first: $first
        after: $after
        filterParams: $filterParams
      ) {
        totalCount
        edges {
          node {
            id
            hostIdentifier
            node_key
            user {
              id
            }
            prettyName
            vantaAttributes {
              key
              value
              managedExternally
            }
            data {
              id
              ... on linuxServerData {
                hostname
                serialNumber
                authorizedKeys {
                  algorithm
                  comment
                  error
                  key_file
                  key_type
                  sha256_fingerprint
                  machinesWithAccessToThisOne(first: 0) {
                    totalCount
                  }
                }
                sshKeys {
                  comment
                  encrypted
                  error
                  key_type
                  sha256_fingerprint
                  path
                  machinesThisCanAccess(first: 0) {
                    totalCount
                  }
                }
              }
            }
          }
        }
        pageInfo {
          hasNextPage
          endCursor
          startCursor
          hasPreviousPage
        }
      }
    }
  }
`;
