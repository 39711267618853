import "./autoscale-instance-collapse.scss";

import { Button, Collapse, FormGroup, Icon, Intent } from "@blueprintjs/core";
import { nothing } from "common/base/types/maybe";
import React, { useState } from "react";

import { DataTable } from "../components/data-table";

interface IProps {
  instanceIdsWithAgent: string[];
  instanceIdsWithoutAgent: string[];
}

export const AutoscaleInstanceCollapse: React.FC<IProps> = ({
  instanceIdsWithAgent,
  instanceIdsWithoutAgent,
}) => {
  const [collapseIsOpen, setCollapseIsOpen] = useState(false);
  const renderCollapseContent = () => (
    <div className="aic-container">
      {instanceIdsWithAgent.length > 0 ? (
        <FormGroup
          label={
            <span className="aic-label">
              <Icon icon="tick-circle" intent={Intent.SUCCESS} />
              Instances running Vanta's agent
            </span>
          }
        >
          {tableForInstances(instanceIdsWithAgent)}
        </FormGroup>
      ) : (
        nothing
      )}
      {instanceIdsWithoutAgent.length > 0 ? (
        <FormGroup
          label={
            <span className="aic-label">
              <Icon icon="circle" intent={Intent.DANGER} />
              Instances not running Vanta's agent
            </span>
          }
        >
          {tableForInstances(instanceIdsWithoutAgent)}
        </FormGroup>
      ) : (
        nothing
      )}
    </div>
  );

  return (
    <div>
      <Button onClick={() => setCollapseIsOpen(!collapseIsOpen)}>
        Instances currently running on this group (
        {instanceIdsWithAgent.length + instanceIdsWithoutAgent.length})
      </Button>
      <Collapse isOpen={collapseIsOpen}>{renderCollapseContent()}</Collapse>
    </div>
  );
};

const tableForInstances = (instanceIds: string[]) => (
  <DataTable
    data={instanceIds}
    createRow={i => {
      return { id: i };
    }}
    columnOrder={["id"]}
    header={{ id: <strong>Instance Id</strong> }}
  />
);
