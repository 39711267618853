import "./alpaca/base/fonts.scss";
import "./App.scss";
import "./components/AppComponents.scss";

import { PermissionLevel } from "common/base/types/gen";
import { isSome } from "common/base/types/maybe";
import React, { useContext, useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import Analytics from "react-router-ga";
import { Userpilot } from "userpilot";

import { AgentInfoPage } from "./components/agent-management/agent-info";
import { AgentRegisterPage } from "./components/agent-management/agent-register";
import { FullPageSpinner } from "./components/helpers/FullPageSpinner";
import { LoginLinkLandingPage } from "./components/login-components/login-link-landing";
import { CouldNotLogIn } from "./components/pages/couldNotLogIn";
import { DownloadPage } from "./components/pages/download";
import { Home } from "./components/pages/home";
import { LoginPage } from "./components/pages/login";
import { AdminInvitePage } from "./components/pages/new-user-setup/admin-invites";
import { EmployeeOnboardingPage } from "./components/pages/onboarding/employee-onboarding/employee-onboarding";
import { OnboardingSignInPage } from "./components/pages/onboarding/employee-onboarding/employee-onboarding-signin";
import { ViewLatestPolicyPage } from "./components/pages/policies/view-latest-policy";
import { UploadedDocPage } from "./components/pages/uploadedDocPage";
import { UserContext } from "./components/pages/user-context";
import { ViewerAuthPage } from "./components/pages/viewerAuth";
import { QueryPage } from "./components/query-page/query-page";
import { VAQGateway } from "./components/vaq/vaq-gateway";
import { Config } from "./config";
import { LogError } from "./errors";
import type { UserContextQuery } from "./gen/components";

const LazyComponents = {
  AlpacaPlayground: React.lazy(
    async () => import("./alpaca/playground/alpaca-playground")
  ),
  DefaultViewExample: React.lazy(
    async () => import("./alpaca/playground/default-view-example")
  ),
};

const DISCLOSURE_POLICY_URL = "https://vanta.com/disclosure";
export const PRIVACY_POLICY_URL = "https://vanta.com/privacy";
const TERMS_URL = "https://vanta.com/terms";

// This event listener, coupled with the 'useEffect' below, ensures that all
// open tabs within a browser show pages from the current logged-in /assumed user.
const LOGIN_EVENT_KEY = "LIE";
window.addEventListener("storage", e => {
  if (e.key === LOGIN_EVENT_KEY && e.oldValue !== e.newValue) {
    window.location.href = "/";
  }
});

type UserInfo = NonNullable<UserContextQuery["user"]>;

const isUnassumedNonOnboardingUser = (user: UserInfo) =>
  (user.permissionLevel === PermissionLevel.Admin ||
    user.permissionLevel === PermissionLevel.Dashboard) &&
  !user.isAuditorAssumingUser &&
  !user.isAssumedUser &&
  !user.isAssumedSuperUser;

const NPS_PATHS_TO_AVOID = [/onboarding/i];
const shouldReceiveNPSSurvey = (user: UserInfo) =>
  isUnassumedNonOnboardingUser(user) &&
  // Don't need to check if environment is prod, because prod/dev/staging can be configured through Chameleon's app
  !NPS_PATHS_TO_AVOID.some(p => p.test(window.location.pathname));

const USERPILOT_PATHS_TO_AVOID = [/onboarding/i];
const shouldReceiveUserPilotOnboarding = (user: UserInfo) =>
  // Don't need to check if environment is prod, because prod/dev/staging can be configured through UserPilot's app
  isUnassumedNonOnboardingUser(user) &&
  !USERPILOT_PATHS_TO_AVOID.some(p => p.test(window.location.pathname));

export const AppRouter: React.FC = () => {
  const location = useLocation();
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (!isSome(user)) {
      return;
    }

    const currentUserId = user.id;
    if (isSome(currentUserId)) {
      // Make sure the value we set here is deterministic from the userId.
      // Otherwise, we'd have endless redirects if a user had multiple tabs open!
      window.localStorage.setItem(LOGIN_EVENT_KEY, btoa(currentUserId));
    }

    const userType = isSome(user.auditorInfo) ? "auditor" : "customer";

    // Initialize Chameleon - surveys and user experiences
    if (shouldReceiveNPSSurvey(user) && isSome(user.chameleonHash)) {
      try {
        const chmln = (window as any).chmln;
        chmln.identify(user.id, {
          email: user.email,
          created: user.createdAt,
          name: user.displayName,
          role: userType,
          company: {
            created: user.domain.createdAt,
            uid: user.domain.id,
            name: user.domain.name,
            salesforce_uid: user.domain.id,
          },
          uid_hash: user.chameleonHash,
        });
      } catch (err) {
        LogError(err, false);
      }
    }

    // Identify user to UserPilot - onboarding tools
    if (shouldReceiveUserPilotOnboarding(user)) {
      Userpilot.identify(user.id, {
        name: user.displayName,
        email: user.email,
        created_at: user.createdAt,
        company: {
          id: user.domain.id,
          name: user.domain.name,
          created_at: user.domain.createdAt,
        },
        user_type: userType,
      });
    }
  }, [user]);

  useEffect(() => {
    // Location changed
    Userpilot.reload(location);
  }, [location]);

  return (
    <>
      <ScrollToTop />
      <React.Suspense fallback={<FullPageSpinner />}>
        <Analytics id="UA-103678919-1" debug={Config.isDev}>
          <Switch>
            <Route
              exact={true}
              path="/privacy"
              component={PrivacyPolicyRedirect}
            />
            <Route exact={true} path="/loginError" component={CouldNotLogIn} />
            <Route exact={true} path="/download" component={DownloadPage} />
            <Route exact={true} path="/downloads" component={DownloadPage} />
            <Route exact={true} path="/query/:command?" component={QueryPage} />
            <Route
              exact={true}
              path="/onboarding"
              component={OnboardingSignInPage}
            />
            <Route
              exact={true}
              path="/:slug/onboarding"
              component={EmployeeOnboardingPage}
            />
            <Route
              exact={true}
              path="/:slug/doc/:title(.+)-:slugId"
              component={UploadedDocPage}
            />
            <Route
              exact={true}
              path="/policies/:staticPolicySlug/:policyType"
              component={ViewLatestPolicyPage}
            />
            <Route
              exact={true}
              path="/:slug/collection/:viewerCompany(.+)-:slugId"
              component={ViewerAuthPage}
            />
            <Route
              exact={true}
              path="/:slug/report/:viewerCompany(.+)-:slugId"
              component={ViewerAuthPage}
            />
            <Route exact={true} path="/login" component={LoginPage} />
            <Route
              exact={true}
              path="/login/verify"
              component={LoginLinkLandingPage}
            />
            <Route exact={true} path="/terms" component={TermsRedirect} />
            <Route
              exact={true}
              path="/disclosure"
              component={DisclosurePolicyRedirect}
            />
            <Route
              path="/:slug/vendorassessment/:uuid?"
              component={VAQGateway}
            />
            <Route exact={true} path="/agent/info" component={AgentInfoPage} />
            <Route
              exact={true}
              path="/agent/register"
              component={AgentRegisterPage}
            />
            <Route
              exact={true}
              path="/invite-admins"
              component={AdminInvitePage}
            />
            {Config.isDev || Config.isStaging
              ? [
                  <Route
                    key="apg"
                    exact
                    path="/alpaca/:component?"
                    component={LazyComponents.AlpacaPlayground}
                  />,
                  <Route
                    key="apg-default-view"
                    exact
                    path="/alpaca-views/default"
                    component={LazyComponents.DefaultViewExample}
                  />,
                ]
              : null}
            <Route path="/" component={Home} />
          </Switch>
        </Analytics>
      </React.Suspense>
    </>
  );
};

const ScrollToTop: React.FC = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};

const DisclosurePolicyRedirect: React.FC = () => {
  useEffect(() => {
    window.location.href = DISCLOSURE_POLICY_URL;
  });
  return <FullPageSpinner />;
};

const PrivacyPolicyRedirect: React.FC = () => {
  useEffect(() => {
    window.location.href = PRIVACY_POLICY_URL;
  });
  return <FullPageSpinner />;
};

const TermsRedirect: React.FC = () => {
  useEffect(() => {
    window.location.href = TERMS_URL;
  });
  return <FullPageSpinner />;
};
