import { Alignment, Switch } from "@blueprintjs/core";
import React from "react";
import styled from "styled-components";

import { Tooltip } from "../../alpaca/components";

interface IScopeToggleProps {
  excluded: boolean;
  locked: boolean;
  tooltipContent: string;
  setExcluded: (newExclusion: boolean) => void;
}

/**
 * A ScopeToggle is a switch that displays a scope for a particular resource.
 *
 * @param props.scope - the currently-set scope for this resource. Since this
 *    switch doesn't have a loading state, it's best to update scope onClick
 *    and mutate the scope asynchronously (or, even better, debounce it).
 * @param props.scopeCause - the scope cause from the scope setting document.
 *    If scopeCause is not ScopeCause.VANTA_SCOPING, this ScopeToggle is
 *    disabled and provides a tooltip explanation.
 * @param props.setScope - a mutation function for triggering a scope change.
 */
export const ConfigureScopesToggle: React.FC<IScopeToggleProps> = ({
  excluded,
  locked,
  tooltipContent,
  setExcluded,
}) => {
  const toggle = (
    <ScopeToggleSwitch
      alignIndicator={Alignment.RIGHT}
      disabled={locked}
      checked={!excluded}
      onChange={() => {
        setExcluded(!excluded);
      }}
    />
  );
  return locked ? (
    <Tooltip content={tooltipContent} placement={"left"}>
      {toggle}
    </Tooltip>
  ) : (
    toggle
  );
};

const ScopeToggleSwitch = styled(Switch)`
  margin: 0;
`;
