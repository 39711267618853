import { Spinner } from "@blueprintjs/core";
import gql from "graphql-tag";
import React from "react";
import styled from "styled-components";

import { LogError } from "../../../../errors";
import type { GetPenTestHistoryQuery } from "../../../../gen/components";
import { useGetPenTestHistoryQuery } from "../../../../gen/components";
import { PenTestHistoryTable } from "./pen-test-history-table";
import { PenTestUploadButton } from "./pen-test-upload-button";

export type PenTest = NonNullable<
  GetPenTestHistoryQuery["organization"]
>["penTestHistory"][number];

export const PenTestComponent: React.FC = () => {
  const { data, loading, error } = useGetPenTestHistoryQuery();
  if (loading) {
    return <Spinner />;
  }
  if (error || !data) {
    LogError(error ?? new Error("Bad fetch"));
    return null;
  }

  return (
    <Container>
      <label>Pen test records</label>
      <PenTestHistoryTable penTests={data.organization.penTestHistory} />
      <PenTestUploadButton />
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: 24px;
  & label {
    margin-bottom: 8px;
    display: block;
  }
`;

gql`
  query getPenTestHistory {
    organization {
      id
      penTestHistory {
        id
        createdAt
        creator {
          id
          displayName
        }
        effectiveAt
        filename
        slugId
        url
      }
    }
  }
`;
