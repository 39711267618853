import crypto from "crypto";
import moment from "moment";

/*
 * Utility methods useful across the codebase.
 */
export async function flatMapAsync<A, B>(
  xs: A[],
  fn: (x: A, index: number) => Promise<B[]>
): Promise<B[]> {
  return (await Promise.all(xs.map(fn))).flat();
}

/**
 * Deduplicate an array of objects by some key.
 * Prefer items that appear earlier in the array.
 *
 * @param xs The array of objects to deduplicate
 * @param k the key to deduplicate by.
 */
export function dedupByKey<T>(xs: T[], k: keyof T) {
  const seen: Set<T[typeof k]> = new Set();
  return xs.filter(x => {
    const dedupKey = x[k];
    if (seen.has(dedupKey)) {
      return false;
    }
    seen.add(dedupKey);
    return true;
  });
}

/**
 * A nice async version of setTimeout that we can `await` to sleep
 * for a number of milliseconds.
 *
 * Usage:
 *  console.log("printed immediately")
 *  await sleep(1000)
 *  console.log("printed after one second")
 * @param ms The number of milliseconds to sleep.
 */
export async function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

/**
 * Determine whether two sets are equal.
 *
 * @param a One set
 * @param b The other set
 * @returns
 */
export const setEqual = <T>(a: Set<T>, b: Set<T>): boolean =>
  a.size === b.size && [...a].every(value => b.has(value));

/**
 * Compute the set intersection of two sets of the same type
 *
 * @param a One set
 * @param b The other set
 */
export const setIntersection = <T>(a: Set<T>, b: Set<T>): Set<T> =>
  new Set([...a].filter(e => b.has(e)));

/**
 * Compute the set union of two sets of the same type
 *
 * @param a One set
 * @param b The other set
 */
export const setUnion = <T>(a: Set<T>, b: Set<T>): Set<T> =>
  new Set([...a, ...b]);

/**
 * Replaces escaped multi-byte sequences with their closest ASCII equivalents.
 *
 * Sometimes, we get strings that use multi-byte hex escapes where the backslashes
 * are themselves escaped. For example, osquery sometimes returns
 * "OnePassword \\xe2\\x80\\x93 Password Manager" as the program name.
 * In these cases, the easiest solution is to do a string replace.
 *
 * Note: this solution is not elegant, and should only be used in cases where the string
 * 1) contains escaped sequences mixed in with non-escaped ones and 2) contains multi-byte
 * escaped sequences. Avoid using this function if you don't have to.
 *
 * @param text The string to decode
 */
export function replaceUTF8MultiByteHexEscapes(text: string): string {
  return text
    .replace("\\xe2\\x80\\x99", "'")
    .replace("\\xc3\\xa9", "e")
    .replace("\\xe2\\x80\\x90", "-")
    .replace("\\xe2\\x80\\x91", "-")
    .replace("\\xe2\\x80\\x92", "-")
    .replace("\\xe2\\x80\\x93", "-")
    .replace("\\xe2\\x80\\x94", "-")
    .replace("\\xe2\\x80\\x94", "-")
    .replace("\\xe2\\x80\\x98", "'")
    .replace("\\xe2\\x80\\x9b", "'")
    .replace("\\xe2\\x80\\x9c", '"')
    .replace("\\xe2\\x80\\x9c", '"')
    .replace("\\xe2\\x80\\x9d", '"')
    .replace("\\xe2\\x80\\x9e", '"')
    .replace("\\xe2\\x80\\x9f", '"')
    .replace("\\xe2\\x80\\xa6", "...")
    .replace("\\xe2\\x80\\xb2", "'")
    .replace("\\xe2\\x80\\xb3", "'")
    .replace("\\xe2\\x80\\xb4", "'")
    .replace("\\xe2\\x80\\xb5", "'")
    .replace("\\xe2\\x80\\xb6", "'")
    .replace("\\xe2\\x80\\xb7", "'")
    .replace("\\xe2\\x81\\xba", "+")
    .replace("\\xe2\\x81\\xbb", "-")
    .replace("\\xe2\\x81\\xbc", "=")
    .replace("\\xe2\\x81\\xbd", "(")
    .replace("\\xe2\\x81\\xbe", ")")
    .replace("\\xE2\\x80\\x99", "'")
    .replace("\\xC3\\xA9", "E")
    .replace("\\xE2\\x80\\x90", "-")
    .replace("\\xE2\\x80\\x91", "-")
    .replace("\\xE2\\x80\\x92", "-")
    .replace("\\xE2\\x80\\x93", "-")
    .replace("\\xE2\\x80\\x94", "-")
    .replace("\\xE2\\x80\\x94", "-")
    .replace("\\xE2\\x80\\x98", "'")
    .replace("\\xE2\\x80\\x9b", "'")
    .replace("\\xE2\\x80\\x9C", '"')
    .replace("\\xE2\\x80\\x9C", '"')
    .replace("\\xE2\\x80\\x9D", '"')
    .replace("\\xE2\\x80\\x9E", '"')
    .replace("\\xE2\\x80\\x9F", '"')
    .replace("\\xE2\\x80\\xA6", "...")
    .replace("\\xE2\\x80\\xb2", "'")
    .replace("\\xE2\\x80\\xb3", "'")
    .replace("\\xE2\\x80\\xb4", "'")
    .replace("\\xE2\\x80\\xb5", "'")
    .replace("\\xE2\\x80\\xb6", "'")
    .replace("\\xE2\\x80\\xb7", "'")
    .replace("\\xE2\\x81\\xbA", "+")
    .replace("\\xE2\\x81\\xbb", "-")
    .replace("\\xE2\\x81\\xbC", "=")
    .replace("\\xE2\\x81\\xbD", "(")
    .replace("\\xE2\\x81\\xbE", ")");
}

const DEFAULT_ROLLOUT_DAYS = 28;

/*
  Returns the default timestamp a rollout period should end for a given test.
*/
export function getDefaultTestRolloutEndDate(): Date {
  const rolloutEndDate = moment()
    .utcOffset(+12)
    .add(DEFAULT_ROLLOUT_DAYS, "days")
    .endOf("day")
    .toDate();
  return rolloutEndDate;
}

// Sort function that takes a list of strings, and sorts
// elements lexicographically
// based on https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/charCodeAt)
// except with all numbers occurring AFTER all letters
// Letters are compared case insensitively
// This works with all alphanumeric strings.
// This may not work with all unicode character sets/internationalization depending on the unicode characters.
export function sortNumericalDigitsAfterLettersCaseInsensitive(arr: string[]) {
  // "0".charCodeAt(0) => 48
  // "9".charCodeAt(0) => 57
  // "A".charCodeAt(0) => 65,
  // "z".charCodeAt(0) => 122
  const charCodeZero = 48;
  const charCodeNine = 57;
  const charCodeUppercaseA = 65;
  const charCodeLowercaseZ = 122;

  function isCharCodeDigit(n: number) {
    return n >= charCodeZero && n <= charCodeNine;
  }
  function isCharCodeLetter(n: number) {
    return n >= charCodeUppercaseA && n <= charCodeLowercaseZ;
  }

  function charCodeToReorderedValue(c: number) {
    if (isCharCodeDigit(c)) {
      return charCodeLowercaseZ + c - charCodeZero - 10 + 1;
    }
    if (isCharCodeLetter(c)) {
      return c - 10;
    }
    return c;
  }

  function comparator(a: string, b: string) {
    const remappedA = String.fromCharCode(
      ...a
        .toLowerCase()
        .split("")
        .map(s => s.charCodeAt(0))
        .map(charCodeToReorderedValue)
    );
    const remappedB = String.fromCharCode(
      ...b
        .toLowerCase()
        .split("")
        .map(s => s.charCodeAt(0))
        .map(charCodeToReorderedValue)
    );
    return remappedA < remappedB ? -1 : remappedA > remappedB ? 1 : 0;
  }
  return arr.sort(comparator);
}

/**
 * Takes a string, and tries to return the same string but with any hex escaped characters replaced with their character equivalents.
 * ex. \\xD0\\x9C\\xD0\\xB0\\xD0\\xB9\\xD0\\xBA\\xD1\\x80\\xD0\\xBE\\xD1\\x81\\xD0\\xBE\\xD1\\x84\\xD1\\x82 Windows 10 -> Майкрософт Windows 10
 * @param text the string to decode
 */
export function replaceHexEscapedSequences(text: string): string {
  try {
    return decodeURIComponent(text.replace(/\\x/g, "%"));
  } catch {
    return text;
  }
}

export const generateSlugId = () =>
  crypto.randomBytes(8).readBigUInt64BE().toString(36).slice(2) +
  crypto.randomBytes(8).readBigUInt64BE().toString(36).slice(2);

// Obfuscate just keeps the first and last characters of a string
// and replaces the rest with asterisks.
// If there is an @ sign, it will keep the @ symbol and keep the first and last
// characters on each side of the @ sign.
// This is not to be used for actually sensitive data where
// the first and last characters and the length may leak information.
// E.g. "robbie@vanta.com" -> "r****e@v*******m"
export function obfuscateNotParticularlySensitiveString(
  notParticularlySensitiveString: string
): string {
  const components = notParticularlySensitiveString.split("@");

  const obfuscatedComponents = components.map(c => {
    const componentAsArr = Array.from(c);
    for (let ix = 1; ix < componentAsArr.length - 1; ix++) {
      componentAsArr[ix] = "*";
    }
    return componentAsArr.join("");
  });
  return obfuscatedComponents.join("@");
}

export const VANTA_AGENT_VERSION = "2.0.0";

export const COMMENT_PREFIX = "###XXX###";

export const CURRENT_TERMS_OF_SERVICE_VERSION = 0;
