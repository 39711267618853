/**
 * If you change any values here, be sure to update the
 * corresponding SASS variable in colors.scss in this directory.
 */
export const BASE_PALETTE = {
  /**
   * Brand color
   */
  MULBERRY: "#5230d7",

  /**
   * Functional colors
   */
  GRAPE: "#875cff",

  INK: "#333333",
  CHARCOAL: "#63676d",
  SLATE: "#7a7e85",
  SMOKE: "#91969e",
  FOG: "#d6d6d6",
  WIND: "#e5e6e8",
  VAPE: "#f7f8fa",
  SNOW: "#ffffff",

  /**
   * Feedback colors
   */
  KALE: "#2fb474",
  MINT: "#d0ecdf",
  MANGO: "#ffcd1c",
  BANANA: "#fff0bb",
  CITRUS: "#ff891c",
  PEACH: "#ffdcbb",
  TOMATO: "#ed4848",
  ROSE: "#fac8c8",
} as const;

/**
 *
 * VANTA_COLORS contains colors from design mocks not fully integrated into the Alpaca system.
 *
 * If you are working with a color not in the base palette but is generalizable enough to have
 * semantic value outside of its original component, add it below. Otherwise, maintain the color in the
 * component locally.
 *
 * Colors here should be in the six-digit hexadecimal format without alpha component
 *
 * #ffffff   - good
 * #ffffffff - bad
 *
 */
export const VANTA_COLORS = {
  VANTA_ALERT: BASE_PALETTE.TOMATO,
  VANTA_PURPLE: BASE_PALETTE.GRAPE,

  BACKGROUND_ALERT: "rgba(252, 96, 46, 0.07)",
  BACKGROUND_MENU_HOVER: "#efefef",
  BACKGROUND_PURPLE: "rgba(156, 78, 255, 0.08)",
  BACKGROUND_TAG: "#f6f6f6",
  BACKGROUND_WHITE: "#ffffff",
  BACKGROUND_APP: BASE_PALETTE.VAPE,

  BORDER_LIGHT: "#dfdfdf",

  CHROME_BUTTON_ACTIVE: "#ddd",
  CHROME_BUTTON_BORDER: "#d8d8d8",
  CHROME_BUTTON_HOVER: "#eee",

  LOGIN_BUTTON: BASE_PALETTE.MULBERRY,
  LOGIN_BUTTON_ACTIVE: "#7859f5",

  MENU_BORDER: "#d6c6c6",
  MENU_SEPARATOR: "#dbdbdc",

  TEXT_NORMAL: "#333333",
  TEXT_DARK: "#000000",
  TEXT_DESCRIPTION: "#888888",
  TEXT_MUTED: "#939393",
} as const;
