import "./sample-email-overlay.scss";

import { Button, Card, Classes, Intent, Overlay } from "@blueprintjs/core";
import React from "react";

interface IProps {
  title: string;
  subject: string;
  text: string;
  htmlBody: string;
  htmlComplete: string;
  onClose: () => void;
}

export class SampleEmailComponent extends React.Component<IProps> {
  public constructor(props: IProps) {
    super(props);
  }

  public render() {
    const emailElement = (
      <div>
        <h3>Subject: {this.props.subject}</h3>
        <hr />
        <h4>[HTML]</h4>
        <Card>
          <div dangerouslySetInnerHTML={{ __html: this.props.htmlBody }} />
        </Card>
        <hr />
        <h4>[Raw HTML]</h4>
        <Card>
          <div>
            {this.props.htmlBody.split("\n").map((line, i) => (
              <div key={i}>
                {line}
                &nbsp;
              </div>
            ))}
          </div>
        </Card>
        <hr />
        <h4>[Plain text]</h4>
        <Card>
          <div>
            {this.props.text.split("\n").map((line, i) => (
              <div key={i}>
                {line}
                &nbsp;
              </div>
            ))}
          </div>
        </Card>
        <hr />
        <h4>[Complete HTML email]</h4>
        <Card>
          <div dangerouslySetInnerHTML={{ __html: this.props.htmlComplete }} />
        </Card>
      </div>
    );

    return (
      <div>
        <Button
          className="se-overlay-button"
          text={this.props.title}
          onClick={this.props.onClose}
          intent={Intent.PRIMARY}
        />
        <Overlay
          className={Classes.OVERLAY_SCROLL_CONTAINER}
          isOpen={true}
          onClose={this.props.onClose}
        >
          <Card className="se-overlay-content">{emailElement}</Card>
        </Overlay>
      </div>
    );
  }
}
