import { TestOutcome } from "common/base/types/gen";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import React from "react";

import type { GetDataForReportQuery } from "../../../../../gen/components";
import WhiteIcon from "../../../../../static/images/reports/icon_blank_space.svg";
import SuccessIcon from "../../../../../static/images/reports/icon_complete.svg";
import InProgressIcon from "../../../../../static/images/reports/icon_in_progress.svg";
import NaIcon from "../../../../../static/images/reports/icon_na.svg";
import NeedsWorkIcon from "../../../../../static/images/reports/icon_needs_work.svg";

interface IProps {
  testResult?: Maybe<
    NonNullable<GetDataForReportQuery["user"]>["domain"]["testResults"][number]
  >;
}

export const VantaReportTestListItemStatus: React.FunctionComponent<IProps> = ({
  testResult,
}) => {
  const outcome = isSome(testResult) ? testResult.outcome : "untested";
  if (outcome === TestOutcome.NA) {
    return <span className="vrp-na-text">N/A</span>;
  }
  const outcomeSvg = (outcomeStr: string) => {
    switch (outcomeStr) {
      case TestOutcome.PASS:
        return SuccessIcon;
      case TestOutcome.FAIL:
        return NeedsWorkIcon;
      case TestOutcome.IN_PROGRESS:
        return InProgressIcon;
      case TestOutcome.NA:
        return NaIcon;
      default:
        return WhiteIcon;
    }
  };
  const Icon = outcomeSvg(outcome);
  return <Icon />;
};
