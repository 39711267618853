import { Button } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import moment from "moment";
import React from "react";
import styled from "styled-components";

import { BASE_PALETTE, VANTA_COLORS } from "../../../../alpaca/base/colors";
import { BASE_TYPOGRAPHY } from "../../../../alpaca/base/typography";
import { BodyText, SpacedList, Tooltip } from "../../../../alpaca/components";
import { ActionContainer } from "./components";

const SLA_DEADLINE_CLOSE_THRESHOLD_DAYS = 14;

interface IProps {
  primaryInfo?: Maybe<string>;
  secondaryInfo?: Maybe<string>;
  slaDeadline?: Maybe<Date>;
  tags?: Maybe<JSX.Element[]>;
  handleClick: Function;
  actionMenu?: Maybe<JSX.Element>;
}

const Container = styled.div`
  :hover {
    background-color: ${VANTA_COLORS.BACKGROUND_APP};
    cursor: pointer;
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ContentContainer = styled.div`
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  padding: 8px 12px;
  align-items: center;
  width: 100%;
`;

const TagContainer = styled(SpacedList)`
  align-items: baseline;
  justify-content: end;
`;

const IdentifierText = styled(BodyText)`
  word-break: break-word;
  padding: 0px;
  margin: 4px;
`;

const DeadlineText = styled(BodyText)<{
  deadlinePassed?: Maybe<boolean>;
  deadlineClose?: Maybe<boolean>;
}>`
  ${({ deadlineClose }) =>
    deadlineClose ? `color: ${BASE_PALETTE.CITRUS};` : ""}

  ${({ deadlinePassed }) =>
    deadlinePassed ? `color: ${BASE_PALETTE.TOMATO};` : ""}
`;

const LeftInfo = styled.div``;
const RightInfo = styled.div`
  margin-left: 24px;
  flex-shrink: 0;
`;

export const VulnerabilityPageEntry: React.FC<IProps> = ({
  primaryInfo,
  secondaryInfo,
  tags,
  slaDeadline,
  actionMenu,
  handleClick,
}) => (
  <Container>
    <ContentContainer onClick={() => handleClick()}>
      <LeftInfo>
        <IdentifierText
          lineHeight={BASE_TYPOGRAPHY.LINE_HEIGHTS.BODY_SHORT_TEXT}
          fontWeight={BASE_TYPOGRAPHY.FONT_WEIGHTS.SLIGHTLY_BOLD}
          color={VANTA_COLORS.TEXT_DARK}
        >
          {primaryInfo}
        </IdentifierText>
        <IdentifierText
          color={VANTA_COLORS.TEXT_DESCRIPTION}
          lineHeight={BASE_TYPOGRAPHY.LINE_HEIGHTS.BODY_SHORT_TEXT}
        >
          {secondaryInfo}
        </IdentifierText>
      </LeftInfo>
      <RightInfo>
        <TagContainer>
          {tags ?? []}

          {isSome(slaDeadline) ? (
            <Tooltip
              content={moment(slaDeadline).format("MMMM Do YYYY, h:mm:ss a")}
            >
              <DeadlineText
                deadlinePassed={moment(slaDeadline).isBefore(moment())}
                deadlineClose={moment(slaDeadline)
                  .subtract(SLA_DEADLINE_CLOSE_THRESHOLD_DAYS, "days")
                  .isBefore(moment())}
              >
                {`Due ${moment(slaDeadline).fromNow()}`}
              </DeadlineText>
            </Tooltip>
          ) : null}
        </TagContainer>
      </RightInfo>
    </ContentContainer>
    {isSome(actionMenu) ? (
      <ActionContainer>
        <Popover2 content={actionMenu}>
          <Button icon="more" />
        </Popover2>
      </ActionContainer>
    ) : null}
  </Container>
);
