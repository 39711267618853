import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { BASE_PALETTE } from "../../base/colors";
import { GRID_SPACING } from "../../base/grid";
import { BASE_TYPOGRAPHY } from "../../base/typography";
import { BodyText } from "../typography/typography";

interface IBreadcrumb {
  text: string;
  route: string;
}

interface IProps {
  breadcrumbs: IBreadcrumb[];
}

export const Breadcrumbs: React.FC<IProps> = ({ breadcrumbs }) => (
  <Container>
    {breadcrumbs.map((breadcrumb, index) => {
      const key = breadcrumb.route;
      const isLastBreadcrumb = index === breadcrumbs.length - 1;
      if (isLastBreadcrumb) {
        return (
          <StyledBodyText key={key} as="div" color={BASE_PALETTE.INK}>
            {breadcrumb.text}
          </StyledBodyText>
        );
      } else {
        return (
          <React.Fragment key={key}>
            <StyledBodyText key={key} color={BASE_PALETTE.CHARCOAL} as="div">
              <StyledLink to={breadcrumb.route}>{breadcrumb.text}</StyledLink>
            </StyledBodyText>
            {slash}
          </React.Fragment>
        );
      }
    })}
  </Container>
);

const StyledBodyText = styled(BodyText)`
  font-size: 14px;
  line-height: 20px;
  font-weight: ${BASE_TYPOGRAPHY.FONT_WEIGHTS.SLIGHTLY_BOLD};
  &:hover {
    cursor: default;
  }
`;

const StyledLink = styled(Link)`
  & {
    color: inherit;
  }
  &:hover {
    color: ${BASE_PALETTE.INK};
    text-decoration: none;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${GRID_SPACING}px;
`;

const slash = (
  <div
    style={{
      width: 6,
      height: 20,
      display: "inline-block",
      marginLeft: 8,
      marginRight: 8,
    }}
  >
    <svg
      width="7"
      height="20"
      viewBox="0 0 7 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 14L4.5 8"
        stroke={BASE_PALETTE.CHARCOAL}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);
