import gql from "graphql-tag";
import React from "react";

import { LogError } from "../../../errors";
import { useFetchRiskScenariosQuery } from "../../../gen/components";
import { FailDataRow } from "../fail-data-row";

export const RiskScenarioTestEntities: React.FC<{
  entityIds: string[];
  testId: string;
  entityType: "RiskScenario";
  allowWhitelisting: boolean;
  first: number;
}> = ({ entityType, entityIds, testId, allowWhitelisting, first }) => {
  const { loading, error, data } = useFetchRiskScenariosQuery();

  if (loading) {
    return <div />;
  }
  if (error) {
    LogError(error);
    return null;
  }
  if (!data?.organization) {
    return <div />;
  }

  const entityIdSet = new Set(entityIds);
  const elements = data.organization.riskRegister.scenarios
    .filter(s => entityIdSet.has(s.id))
    .slice(0, first)
    .map(s => (
      <FailDataRow
        key={s.id}
        entityId={s.id}
        entityType={entityType}
        testId={testId}
        allowWhitelisting={allowWhitelisting}
      >
        {s.text}
      </FailDataRow>
    ));

  return <>{elements}</>;
};

gql`
  query fetchRiskScenarios {
    organization {
      id
      riskRegister {
        scenarios {
          id
          text
        }
      }
    }
  }
`;
