import {
  Button,
  Classes,
  FormGroup,
  InputGroup,
  Intent,
} from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import React, { useState } from "react";
import styled from "styled-components";

import { LogError } from "../../../errors";
import {
  AllLinkedCredentialsDocument,
  useSetCredentialsMutation,
  useTestCredentialsMutation,
} from "../../../gen/components";
import { AppToaster } from "../../../helpers/toaster";
import type { ServiceDetails } from "../../credentials/service-groups";
import { DropdownButton } from "../components/dropdown-button";

export interface IInputValues {
  [k: string]: Maybe<string>;
}

interface Label {
  name: string;
  displayName: string;
  helperText?: Maybe<JSX.Element>;
  defaultValue?: Maybe<string>;
}

export interface InputLabel extends Label {
  placeholder?: Maybe<string>;
  type?: Maybe<string>;
  valueIsValid?: Maybe<(value: string) => boolean>;
}

export interface OptionLabel extends Label {
  defaultValue: string;
  options: string[];
}

function isOptionLabel(label: Label): label is OptionLabel {
  return "options" in label;
}

interface IProps {
  labels: Array<InputLabel | OptionLabel>;
  service: ServiceDetails;
  additionalContent?: Maybe<JSX.Element>;
  showTitle?: Maybe<boolean>;
  buttonLabel?: Maybe<string>;
  OverrideSaveButton?: Maybe<
    React.FC<{ canSubmit: boolean; inputValues: IInputValues }>
  >;
  shouldTestCredentials?: Maybe<boolean>;
  /**
   * onCredentialsLinked is called after credentials are stored in mongo iff
   * OverrideSaveButton is not defined.
   */
  onCredentialsLinked?: Maybe<() => void>;
}

export const SimpleCredentialFormDialogBody: React.FunctionComponent<IProps> =
  ({
    labels,
    service,
    additionalContent,
    showTitle,
    buttonLabel,
    OverrideSaveButton,
    shouldTestCredentials,
    onCredentialsLinked,
  }) => {
    const [inputValues, setInputValues] = useState<IInputValues>(
      labels.reduce((values, cur) => {
        if (isSome(cur.defaultValue)) {
          values[cur.name] = cur.defaultValue;
        }
        return values;
      }, {} as IInputValues)
    );

    const onChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
      const target = e.currentTarget;
      setInputValues({
        ...inputValues,
        [target.name]: e.currentTarget.value.trim(),
      });
    };

    const inputHasInvalidValue = (label: typeof labels[number]) => {
      const value = inputValues[label.name];
      if (isOptionLabel(label)) {
        return !isSome(value) || !label.options.includes(value);
      }
      if (isSome(value) && isSome(label.valueIsValid)) {
        return !label.valueIsValid(value);
      }
      return false;
    };

    // allInputsHaveValues returns true if each trimmed input is non-empty and passes
    // its corresponding validation function, if one is defined.
    const allInputsHaveValues = labels.every(label => {
      const value = inputValues[label.name];
      if (!isSome(value) || value === "") {
        return false;
      }
      return !inputHasInvalidValue(label);
    });

    const saveButton = isSome(OverrideSaveButton) ? (
      <OverrideSaveButton
        canSubmit={allInputsHaveValues}
        inputValues={inputValues}
      />
    ) : (
      <SaveCredentialsButton
        service={service}
        label={buttonLabel ?? "Store token"}
        inputValues={inputValues}
        canSubmit={allInputsHaveValues}
        shouldTestCredentials={Boolean(shouldTestCredentials)}
        onCredentialsLinked={onCredentialsLinked}
      />
    );

    return (
      <div>
        {showTitle !== false ? (
          <div className={Classes.DIALOG_HEADER}>
            Link {service.displayName}
          </div>
        ) : (
          <div />
        )}

        <div className={Classes.DIALOG_BODY}>
          <AdditionalContent>{additionalContent}</AdditionalContent>
          {labels.map(l => (
            <FormGroup
              key={l.displayName}
              label={l.displayName}
              labelFor={l.name}
              helperText={l.helperText}
              intent={inputHasInvalidValue(l) ? Intent.DANGER : undefined}
            >
              {isOptionLabel(l) ? (
                <DropdownButton
                  options={l.options}
                  selectedOption={inputValues[l.name]}
                  onOptionSelect={option => {
                    setInputValues({
                      ...inputValues,
                      [l.name]: option,
                    });
                  }}
                  optionRenderer={option => option}
                />
              ) : (
                <InputGroup
                  id={l.name}
                  placeholder={l.placeholder ?? undefined}
                  type={l.type ?? "text"}
                  name={l.name}
                  value={inputValues[l.name] ?? ""}
                  onChange={onChange}
                  intent={inputHasInvalidValue(l) ? Intent.DANGER : undefined}
                />
              )}
            </FormGroup>
          ))}
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>{saveButton}</div>
        </div>
      </div>
    );
  };

const AdditionalContentStyles = {
  MARGIN_BOTTOM: 28,
};

const AdditionalContent = styled.div`
  margin-bottom: ${AdditionalContentStyles.MARGIN_BOTTOM}px;
`;

const SaveCredentialsButton: React.FC<{
  service: ServiceDetails;
  label: string;
  inputValues: IInputValues;
  canSubmit: boolean;
  shouldTestCredentials: boolean;
  onCredentialsLinked?: Maybe<() => void>;
}> = ({
  service,
  label,
  inputValues,
  canSubmit,
  shouldTestCredentials,
  onCredentialsLinked,
}) => {
  const [setCredentials, { loading: setCredsLoading, error }] =
    useSetCredentialsMutation({
      variables: {
        service: service.id,
        credentials: JSON.stringify(inputValues),
      },
      onCompleted: () => {
        AppToaster.show(
          {
            message: `${service.displayName} connection created.`,
            intent: Intent.SUCCESS,
          },
          service.id
        );
        if (onCredentialsLinked) {
          onCredentialsLinked();
        }
      },
      refetchQueries: [{ query: AllLinkedCredentialsDocument }],
    });
  const [testCredentials, { loading: testCredsLoading }] =
    useTestCredentialsMutation();

  if (error) {
    LogError(error);
  }

  return (
    <Button
      loading={
        shouldTestCredentials
          ? setCredsLoading || testCredsLoading
          : setCredsLoading
      }
      intent={Intent.PRIMARY}
      disabled={!canSubmit}
      onClick={async () => {
        if (shouldTestCredentials) {
          const result = await testCredentials({
            variables: {
              credentials: JSON.stringify({ credentials: inputValues }),
              service: service.id,
            },
          });
          if (result.errors) {
            throw new Error(JSON.stringify(result.errors));
          }
          if (!result.data?.testCredentials.success) {
            AppToaster.show({
              icon: "cross",
              intent: Intent.WARNING,
              message: result.data?.testCredentials.message,
              timeout: 5000,
            });
            return;
          }
        }
        await setCredentials();
      }}
    >
      {label}
    </Button>
  );
};
