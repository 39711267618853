import type { Maybe } from "common/base/types/maybe";
import { getTransformedOrElse, isSome } from "common/base/types/maybe";
import type { IRiskCategory } from "common/schemas/riskRegister/risk-register-content";
import type { useHistory } from "react-router-dom";

import { LogError } from "../../../errors";
import type {
  RiskDismissal,
  RiskEvaluation,
  RiskMitigationTask,
} from "./queries-and-types";
import { RISK_REGISTER_URL } from "./risk-register-home";

const RISK_STEP_PARAM = "step";
const RISK_ITEM_PARAM = "item";
const RISK_STEP_START_PARAM = "start";
const RISK_STEP_COMPLETED_PARAM = "completed";

interface IArgs {
  urlSearchString: string;
  history: ReturnType<typeof useHistory>;
  category: IRiskCategory;
  items: RiskEvaluation[];
  dismissals: RiskDismissal[];
  tasks: RiskMitigationTask[];
}

export class RiskFormLocation {
  private readonly stepIndex: number = 0;
  private readonly itemIndex: Maybe<number> = null;
  private readonly isStart: boolean = false;
  private readonly isCompletion: boolean = false;
  private readonly history: ReturnType<typeof useHistory>;
  private readonly numberOfSteps: number = 0;
  private readonly itemsPerStep: { [key: number]: number } = {};
  private readonly stepIsCompleted: boolean = false;

  /**
   * Text to display in a tooltip over a disabled button if action is
   * required to continue.
   */
  public readonly tooltipText: Maybe<string> = null;

  constructor(args: IArgs) {
    const { history, urlSearchString, category, items, dismissals, tasks } =
      args;
    this.history = history;
    const searchParams = new URLSearchParams(urlSearchString);
    const parsedStepIndex = this.maybeNumberForParam(
      searchParams.get(RISK_STEP_PARAM)
    );
    if (!isSome(parsedStepIndex)) {
      throw new Error("Missing step index");
    }
    this.stepIndex = parsedStepIndex;

    const parsedItemIndex = this.maybeNumberForParam(
      searchParams.get(RISK_ITEM_PARAM)
    );
    const startParam = searchParams.get(RISK_STEP_START_PARAM);
    const completionParam = searchParams.get(RISK_STEP_COMPLETED_PARAM);

    if (isSome(parsedItemIndex)) {
      this.itemIndex = parsedItemIndex;
      if (isSome(startParam) || isSome(completionParam)) {
        throw new Error("Tried to specify too many parameters");
      }
    } else if (isSome(completionParam)) {
      this.isCompletion = true;
      if (isSome(startParam)) {
        throw new Error("Tried to specify too many parameters");
      }
    } else if (isSome(startParam)) {
      this.isStart = true;
    }

    this.numberOfSteps = category.risks.length + 1;
    category.risks.forEach((risk, index) => {
      this.itemsPerStep[index] = items.filter(
        item => item.riskId === risk.id
      ).length;
    });
    this.itemsPerStep[this.numberOfSteps] = 0;

    if (this.stepIndex === this.numberOfSteps - 1) {
      this.stepIsCompleted = tasks.some(t => t.riskCategoryId === category.id);
      if (!this.stepIsCompleted) {
        this.tooltipText = "Create a task to continue";
      }
    } else {
      if (!isSome(this.itemIndex)) {
        this.stepIsCompleted =
          dismissals.some(
            d => d.riskId === category.risks[this.stepIndex].id
          ) || this.itemsPerStep[this.stepIndex] > 0;

        if (!this.stepIsCompleted) {
          this.tooltipText =
            "You must list one item or dismiss risk to continue";
        }
      }
    }
  }

  public getItemIndex() {
    return this.itemIndex;
  }

  public getStepIndex() {
    return this.stepIndex;
  }

  public isStepStart() {
    return this.isStart;
  }

  public isStepCompletion() {
    return this.isCompletion;
  }

  public navigateToLocation(stepIndex: number, itemIndex?: Maybe<number>) {
    const maybeItemIndex = getTransformedOrElse(
      itemIndex,
      index => `&${RISK_ITEM_PARAM}=${index}`,
      "&start=1"
    );
    this.history.push(
      `${window.location.pathname}?${RISK_STEP_PARAM}=${stepIndex}${maybeItemIndex}`
    );
  }

  public canProceed() {
    return this.stepIsCompleted || this.isCompletion || this.isStart;
  }

  public gotoNextStep() {
    const search = this.getURLSearchStringForNextPage();
    if (isSome(search)) {
      this.history.push(`${window.location.pathname}${search}`);
    } else {
      this.exitForm();
    }
  }

  public exitForm() {
    this.history.push(RISK_REGISTER_URL);
  }

  private maybeNumberForParam(param: Maybe<string>) {
    if (!isSome(param)) {
      return null;
    }

    const parsedNumber = parseInt(param, 10);
    return isNaN(parsedNumber) ? null : parsedNumber;
  }

  private getURLSearchStringForNextPage() {
    const numberOfItemsForCurrentStep = this.itemsPerStep[this.getStepIndex()];

    if (this.isStepCompletion()) {
      const nextStepIndex = this.stepIndex + 1;
      if (nextStepIndex >= this.numberOfSteps) {
        return null;
      } else {
        return `?${RISK_STEP_PARAM}=${nextStepIndex}&${RISK_STEP_START_PARAM}=1`;
      }
    } else if (this.isStepStart()) {
      return `?${RISK_STEP_PARAM}=${this.stepIndex}`;
    } else if (!isSome(this.itemIndex)) {
      if (numberOfItemsForCurrentStep > 0) {
        return `?${RISK_STEP_PARAM}=${this.stepIndex}&${RISK_ITEM_PARAM}=0`;
      } else {
        return `?${RISK_STEP_PARAM}=${this.stepIndex}&${RISK_STEP_COMPLETED_PARAM}=1`;
      }
    } else if (isSome(this.itemIndex)) {
      const nextItemIndex = this.itemIndex + 1;
      if (nextItemIndex >= numberOfItemsForCurrentStep) {
        return `?${RISK_STEP_PARAM}=${this.stepIndex}&${RISK_STEP_COMPLETED_PARAM}=1`;
      } else {
        return `?${RISK_STEP_PARAM}=${this.stepIndex}&${RISK_ITEM_PARAM}=${nextItemIndex}`;
      }
    } else {
      LogError(
        new Error("Could not get URL search string for Risk register page"),
        false
      );
      return null;
    }
  }

  public static urlForFormStart() {
    return `${window.location.pathname}?${RISK_STEP_PARAM}=0&${RISK_STEP_START_PARAM}=1`;
  }
}
