import { OL } from "@blueprintjs/core";
import React from "react";

import { DefaultLink } from "../../../../helpers/links";
import {
  InfoCalloutContainer,
  InfoCalloutHeading,
  InfoCalloutText,
} from "../common/components";

export const AzureContainerRepositoriesEmptyState: React.FC = () => (
  <InfoCalloutContainer>
    <InfoCalloutHeading>No container repositories detected</InfoCalloutHeading>
    <InfoCalloutText>
      If you've enabled container scanning, vulnerabilities detected by Azure
      Defender for Container Registries will appear here.
    </InfoCalloutText>

    <InfoCalloutText>
      If you use Azure Container Registry but haven't enabled container
      scanning, Vanta recommends you start scanning your containers for
      vulnerabilities. You can do so by enabling Azure Defender for Container
      Registries in the{" "}
      <DefaultLink href="https://portal.azure.com/#blade/Microsoft_Azure_Security/SecurityMenuBlade/0">
        Azure Security Center
      </DefaultLink>
      .
      <OL>
        <li>
          Visit the{" "}
          <DefaultLink href="https://portal.azure.com/#blade/Microsoft_Azure_Security/SecurityMenuBlade/24">
            Pricing and Settings
          </DefaultLink>{" "}
          page of the Azure Security Center.
        </li>
        <li>
          Select the subscription you'd like to enable container scanning for.
        </li>
        <li>
          Under "Container registries", toggle the switch to "On". Scroll up and
          click "Save".
        </li>
      </OL>
      Within an hour of enabling, ACR repositories should start being displayed
      on this page.
    </InfoCalloutText>

    <InfoCalloutText>
      To learn more about Azure Defender for Container Registries, refer to
      Azure's{" "}
      <DefaultLink href="https://docs.microsoft.com/en-us/azure/security-center/defender-for-container-registries-introduction">
        overview
      </DefaultLink>
      .
    </InfoCalloutText>
  </InfoCalloutContainer>
);
