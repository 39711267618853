import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import React from "react";
import styled, { css } from "styled-components";

import { VANTA_COLORS } from "../../alpaca/base/colors";
import { BodyShortText, HelperText } from "../../alpaca/components";
import CompletionCheck from "../../static/images/icons/completion-check.svg";
import type { IStep, StepClickHandler } from "./step-nav-menu";

interface IProps {
  step: IStep;
  stepIndex: number;
  isActive: boolean;
  activeSubstepIndex?: Maybe<number>;
  clickHandler: StepClickHandler;
}

export const SingleStepList: React.FC<IProps> = ({
  step,
  stepIndex,
  isActive,
  activeSubstepIndex,
  clickHandler,
}) => {
  const substeps = step.substeps ?? [];
  const maybeSubstepList =
    substeps.length > 0 ? (
      <StepItemUL>
        {substeps.map((substep, substepIndex) => (
          <LI
            active={activeSubstepIndex === substepIndex}
            key={`${substep.text}_${substepIndex}`}
            onClick={() => clickHandler(stepIndex, substepIndex)}
          >
            {isSome(substep.completed) ? (
              substep.completed ? (
                <CompletionCheck />
              ) : (
                <Circle />
              )
            ) : (
              <Blank />
            )}
            <BodyShortText as="div" fontSize={14}>
              {substep.text}
            </BodyShortText>
          </LI>
        ))}
      </StepItemUL>
    ) : null;

  return (
    <div>
      <HelperText color={VANTA_COLORS.TEXT_DESCRIPTION}>
        Step {stepIndex + 1}
      </HelperText>
      <MainHeading active={isActive} onClick={() => clickHandler(stepIndex)}>
        <BodyShortText as="div">
          <FlexHeading>
            <span>{step.text}</span>
            {step.completed ? <CompletionCheck /> : null}
          </FlexHeading>
        </BodyShortText>
      </MainHeading>
      {maybeSubstepList}
    </div>
  );
};

const styles = {
  BLANK_WIDTH: 12,
  ICON_DIMENSIONS: 16,
  ICON_BORDER_COLOR: "#d9d9d9",
  ICON_RIGHT_MARGIN: 6,
  HEADING_ICON_LEFT_MARGIN: 6,
};

const MainHeading = styled.div<{ active?: Maybe<boolean> }>`
  ${({ active }) =>
    active
      ? css`
          color: ${VANTA_COLORS.TEXT_NORMAL};
        `
      : ""}
  &:hover {
    cursor: pointer;
    color: ${VANTA_COLORS.VANTA_PURPLE};
  }
`;

const StepItemUL = styled.ul`
  color: ${VANTA_COLORS.TEXT_NORMAL};
  list-style-type: none;
  padding-inline-start: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  & > li {
    display: flex;
    align-items: center;
  }
`;

const LI = styled.li<{ active?: Maybe<boolean> }>`
  color: ${({ active }) =>
    !active ? `${VANTA_COLORS.TEXT_NORMAL}88` : VANTA_COLORS.TEXT_NORMAL};
  &:hover {
    cursor: pointer;
    color: ${VANTA_COLORS.VANTA_PURPLE};
  }
  svg {
    margin-right: ${styles.ICON_RIGHT_MARGIN}px;
    flex-shrink: 0;
    height: ${styles.ICON_DIMENSIONS}px;
    width: ${styles.ICON_DIMENSIONS}px;
  }
`;

const Circle = styled.div`
  height: ${styles.ICON_DIMENSIONS}px;
  width: ${styles.ICON_DIMENSIONS}px;
  border: 1px solid ${styles.ICON_BORDER_COLOR};
  border-radius: 50%;
  margin-right: ${styles.ICON_RIGHT_MARGIN}px;
  flex-shrink: 0;
`;

const FlexHeading = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-left: ${styles.HEADING_ICON_LEFT_MARGIN}px;
  }
`;

const Blank = styled.div`
  height: ${styles.ICON_DIMENSIONS}px;
  width: ${styles.BLANK_WIDTH}px;
`;
