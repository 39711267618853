import { AnchorButton, Button, Icon, Spinner } from "@blueprintjs/core";
import type { ReportStandard } from "common/base/types/gen";
import type { Maybe } from "common/base/types/maybe";
import { dropNothing, isSome } from "common/base/types/maybe";
import { StandardToDisplayName } from "common/constants/displayNames";
import type { PolicyType } from "common/constants/policyInfoCommon";
import { PolicyDocStubs } from "common/constants/policyInfoCommon";
import gql from "graphql-tag";
import fileDownload from "js-file-download";
import moment from "moment";
import React, { useContext, useMemo, useState } from "react";
import styled, { css } from "styled-components";
import { GRID_SPACING } from "../../../alpaca/base/grid";
import { LogError } from "../../../errors";
import {
  useManagePoliciesV2Query,
  useSetPolicyApprovalMutation,
  Feature,
} from "../../../gen/components";
import { doubleEncodeURIComponent } from "../../../helpers";
import { useFeatureCheck } from "../../../helpers/feature-gating/feature-check";
import { FullPageSpinner } from "../../helpers/FullPageSpinner";
import { StandardLabels } from "../../helpers/StandardLabels";
import { COLUMN_CLASSES, DataTable } from "../components/data-table";
import { DropdownButton } from "../components/dropdown-button";
import { ProgressBar } from "../components/progress-bar";
import { TABLE_CONTROLS_STYLES } from "../components/table-controls";
import { blobsToZip } from "../standards/shared/file-utils";
import { UserContext } from "../user-context";
import { AddPolicyDialog } from "./add-policy-dialog";
import type { Policy } from "./common";
import { ActionButton, POLICY_PAGE_STYLE_CONSTANTS } from "./common";
import { DeletePolicyTypeDialog } from "./delete-policy-type-dialog";
import { PolicyActionMenu } from "./policy-action-menu";
import { POLICY_TEMPLATES } from "./policy-templates";
import { PolicyVersionHistoryDialog } from "./policy-version-history-dialog";
import { RenewPolicyDialog } from "./renew-policy-dialog";
import { getEmployeeGroupsForPolicy } from "./utils";
import { ViewAnswersDialog } from "./view-answers-dialog";

interface IProps {
  onGeneratePacket(): void;
}

export const PolicyTable: React.FC<IProps> = ({ onGeneratePacket }) => {
  const { data, loading } = useManagePoliciesV2Query();
  const [approvedPolicy, setApprovedPolicy] = useState<Maybe<string>>(null);
  const [policyToDelete, setPolicyToDelete] = useState<Maybe<Policy>>(null);
  const [policyToRenew, setPolicyToRenew] = useState<Maybe<Policy>>(null);
  const [selectedStandard, setSelectedStandard] =
    useState<Maybe<ReportStandard>>(null);
  const [policyHistoryToView, setPolicyHistoryToView] =
    useState<Maybe<Policy>>(null);
  const [policyAnswersToView, setPolicyAnswersToView] =
    useState<Maybe<Policy>>(null);

  const [addPolicyDialogOpen, setAddPolicyDialogOpen] = useState(false);
  const [approvePolicy] = useSetPolicyApprovalMutation({
    onCompleted() {
      setApprovedPolicy(null);
    },
  });
  const { user } = useContext(UserContext);
  const isEmployeeGroupsOnPoliciesPageCustomer = useFeatureCheck(
    Feature.EmployeeGroupsOnPoliciesPage
  );
  const domainStandards = user?.domain.standards ?? [];
  const [isGeneratingTemplateZip, setIsGeneratingTemplateZip] = useState(false);

  const stubsWithPolicies = useMemo(() => {
    const policies = data?.organization.policies ?? [];
    const policyDocStubs = data?.organization.policyDocStubs ?? [];

    return policyDocStubs.map(stub => {
      const policyType = stub.policyType;
      const policy = policies.find(p => p.policyType === policyType);
      return {
        ...stub,
        policy,
        titleLowerCased: stub.title.toLocaleLowerCase(),
      };
    });
  }, [data?.organization]);

  const possibleStandards = useMemo(
    () =>
      [...new Set(stubsWithPolicies.flatMap(s => s.standards ?? []))].filter(
        s => domainStandards.includes(s)
      ),
    [stubsWithPolicies, domainStandards]
  );

  if (loading || !data) {
    return <FullPageSpinner />;
  }

  const downloadPolicyZip = async () => {
    setIsGeneratingTemplateZip(true);
    const allPolicyBlobs = [];
    const policyDocStubs = dropNothing(
      data?.organization.policyDocStubs ?? []
    ).map(s => s.policyType);
    const policyTemplatesForDomain = PolicyDocStubs.filter(stub =>
      policyDocStubs.includes(stub.policyType)
    );

    for (const policy of policyTemplatesForDomain) {
      const policyTemplateUrls =
        POLICY_TEMPLATES[policy.policyType as PolicyType];
      const fetchUrl = policyTemplateUrls.DOCX ?? policyTemplateUrls.XLSX;
      const extension = isSome(policyTemplateUrls.DOCX) ? "docx" : "xlsx";
      // 08/26/21: All templates have a docx file, except one, which has
      // an xlsx. This is just to make TS happy
      if (isSome(fetchUrl)) {
        const fetchResponse = await fetch(fetchUrl);
        allPolicyBlobs.push({
          blob: await fetchResponse.blob(),
          filename: `${policy.policyType}.${extension}`,
        });
      } else {
        LogError(
          new Error("Policy templates has no docx or xlsx file type"),
          false
        );
      }
    }
    const zippedFile = await blobsToZip(allPolicyBlobs);
    fileDownload(zippedFile, "Vanta-Policy-Templates.zip");
    setIsGeneratingTemplateZip(false);
  };

  return (
    <div>
      <DataTable
        data={stubsWithPolicies.filter(
          stub =>
            !isSome(selectedStandard) ||
            (stub.standards ?? []).includes(selectedStandard)
        )}
        columnVisibilities={{
          employeeProgress: isEmployeeGroupsOnPoliciesPageCustomer,
        }}
        columnWidths={[
          "394px",
          "280px",
          "150px",
          "150px",
          "150px",
          "150px",
          "120px",
        ]}
        columnClasses={{
          approvedAt: COLUMN_CLASSES.CENTER_ALIGN,
          actions: COLUMN_CLASSES.CENTER_ALIGN,
        }}
        columnOrder={[
          "name",
          "standards",
          "employeeProgress",
          "approvedBy",
          "jobTitle",
          "approvedAt",
          "actions",
        ]}
        useDefaultStyling
        stickyHeaders
        header={{
          name: "Policy",
          standards: "Standards",
          employeeProgress: "Employee Progress",
          approvedBy: "Approved by",
          jobTitle: "Job title",
          approvedAt: "Approved date",
          actions: "Actions",
        }}
        customControls={{
          leftControls:
            possibleStandards.length > 0
              ? [
                  <DropdownButton
                    key="standard-filter"
                    defaultText="Filter by standard"
                    options={possibleStandards}
                    selectedOption={selectedStandard}
                    onOptionSelect={setSelectedStandard}
                    optionRenderer={standard => StandardToDisplayName(standard)}
                    isFilter={true}
                    styleOnSelect
                  />,
                ]
              : null,
          rightControls: [
            <TableButton
              key="add-policy"
              icon={<Icon icon="plus" iconSize={12} />}
              onClick={() => setAddPolicyDialogOpen(true)}
            >
              Add policy
            </TableButton>,
            <TableButton
              key="download-policy-templates"
              icon={
                isGeneratingTemplateZip ? null : (
                  <Icon icon="download" iconSize={12} />
                )
              }
              disabled={isGeneratingTemplateZip}
              onClick={downloadPolicyZip}
            >
              {isGeneratingTemplateZip ? (
                <Spinner size={16} />
              ) : (
                "Download all policy templates"
              )}
            </TableButton>,
            <TableButton
              key="download"
              icon={<Icon icon="download" iconSize={12} />}
              onClick={onGeneratePacket}
            >
              Download packet
            </TableButton>,
          ],
        }}
        createRow={stub => {
          const { policy } = stub;
          return {
            name: stub.title,
            standards: (
              <StandardLabels
                tagMargin={GRID_SPACING}
                standards={stub.standards}
              />
            ),
            employeeProgress: isSome(policy) ? (
              <ProgressBar
                numSatisfied={policy.numUsersAccepted}
                numTotal={policy.numUsers}
                noTotalText="No employees are required to accept this policy"
              />
            ) : null,
            approvedBy: isSome(policy) ? (
              isSome(policy.approverName) ? (
                policy.approverName
              ) : (
                <StyledButton
                  loading={approvedPolicy === policy.id}
                  disabled={
                    isSome(approvedPolicy) && approvedPolicy !== policy.id
                  }
                  onClick={async () => {
                    setApprovedPolicy(policy.id);
                    await approvePolicy({
                      variables: { policyId: policy.id },
                    });
                  }}
                >
                  Approve
                </StyledButton>
              )
            ) : null,
            jobTitle: policy?.approver?.hrUser?.jobTitle,
            approvedAt: isSome(policy?.approvedAt)
              ? moment(new Date(policy!.approvedAt)).format("M/D/YY")
              : null,
            actions: isSome(policy) ? (
              <StyledDiv>
                <ActionButton
                  onClick={() => {
                    window.location.href = `/doc?download=1&s=${policy.uploadedDoc.slugId}`;
                  }}
                  icon={<Icon icon="download" iconSize={12} />}
                />
                <PolicyActionMenu
                  policy={policy}
                  onDelete={() => setPolicyToDelete(policy)}
                  onRenew={() => setPolicyToRenew(policy)}
                  onViewAnswers={() => setPolicyAnswersToView(policy)}
                  onViewHistory={() => setPolicyHistoryToView(policy)}
                />
              </StyledDiv>
            ) : (
              <StyledAnchorButton
                href={`policies/${doubleEncodeURIComponent(stub.policyType)}`}
              >
                Create
              </StyledAnchorButton>
            ),
          };
        }}
        searchFilter={(stub, searchString) =>
          stub.titleLowerCased.includes(searchString.toLocaleLowerCase())
        }
      />
      <AddPolicyDialog
        domainId={data.organization.id}
        isOpen={addPolicyDialogOpen}
        onClose={() => setAddPolicyDialogOpen(false)}
        policyTypes={stubsWithPolicies.map(s => s.policyType)}
      />
      {isSome(policyToDelete) ? (
        <DeletePolicyTypeDialog
          domainId={data.organization.id}
          onClose={() => setPolicyToDelete(null)}
          policy={policyToDelete}
        />
      ) : null}
      {isSome(policyToRenew) ? (
        <RenewPolicyDialog
          policy={policyToRenew}
          groups={getEmployeeGroupsForPolicy(
            policyToRenew,
            data.organization.defaultSecurityRequirements,
            data.organization.defaultNecessaryPolicies
          )}
          onClose={() => setPolicyToRenew(null)}
        />
      ) : null}
      {isSome(policyHistoryToView) ? (
        <PolicyVersionHistoryDialog
          onClose={() => setPolicyHistoryToView(null)}
          policy={policyHistoryToView}
        />
      ) : null}
      {isSome(policyAnswersToView) ? (
        <ViewAnswersDialog
          onClose={() => setPolicyAnswersToView(null)}
          policy={policyAnswersToView}
          policyInfoMap={data.organization.policyInfos as any}
        />
      ) : null}
    </div>
  );
};

const StyledDiv = styled.div`
  width: ${POLICY_PAGE_STYLE_CONSTANTS.BUTTON_WIDTH}px;
  display: flex;
  justify-content: space-between;

  margin: 0 auto;
`;

const BUTTON_STYLES = css`
  width: ${POLICY_PAGE_STYLE_CONSTANTS.BUTTON_WIDTH}px;
  height: ${POLICY_PAGE_STYLE_CONSTANTS.BUTTON_HEIGHT}px;
  min-height: ${POLICY_PAGE_STYLE_CONSTANTS.BUTTON_HEIGHT}px;
  font-size: 12px;
`;

const StyledButton = styled(Button)`
  ${BUTTON_STYLES}
`;

const StyledAnchorButton = styled(AnchorButton)`
  ${BUTTON_STYLES}
`;

const TableButton = styled(Button)`
  height: ${TABLE_CONTROLS_STYLES.HEIGHT}px;
  font-size: ${TABLE_CONTROLS_STYLES.FONT_SIZE}px;
  padding-left: 16px;
  padding-right: 16px;
`;

gql`
  mutation setPolicyApproval($policyId: String!) {
    setPolicyApproval(policyId: $policyId) {
      id
      active
      approvedAt
      approverName
      createdAt
      title
    }
  }
`;
