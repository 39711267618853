import React from "react";

import type { GetDataForReportQuery } from "../../../../../gen/components";
import type { IDomainForReport } from "../../../helpers/helpers";
import { VantaReportRequirementListItem } from "./vanta-report-requirement-list-item";

interface IProps {
  domain: IDomainForReport;
  principle: NonNullable<
    GetDataForReportQuery["complianceStandard"]
  >["principles"][number];
}

export class VantaReportRequirementList extends React.Component<IProps> {
  public render() {
    const { domain, principle } = this.props;
    return (
      <div className="vrp-requirement-list">
        {principle.requirements.map((requirement, index) => (
          <VantaReportRequirementListItem
            key={`requirement_${index}`}
            domain={domain}
            requirement={requirement}
          />
        ))}
      </div>
    );
  }
}
