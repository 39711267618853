import { Intent, MenuItem, Spinner, SpinnerSize } from "@blueprintjs/core";
import { Suggest } from "@blueprintjs/select";
import type { Maybe } from "common/base/types/maybe";
import { isSome, nothing } from "common/base/types/maybe";
import type { TestEntityTypeClientSafe } from "common/constants/displayNames";
import { TestEntityTypeDisplayNames } from "common/constants/displayNames";
import gql from "graphql-tag";
import { groupBy } from "lodash";
import moment from "moment";
import React, { useState } from "react";
import styled from "styled-components";
import { BASE_PALETTE } from "../../alpaca/base/colors";
import { GRID_SPACING } from "../../alpaca/base/grid";
import { Button } from "../../alpaca/components";
import { LogError } from "../../errors";

import type { TestEntity } from "../../gen/components";
import {
  useGetHistoryForTestQuery,
  useGetDetailsForTestRunQuery,
  useGetTestRunnerTestIdsQuery,
} from "../../gen/components";
import { downloadRawData } from "../../helpers/download-raw-data";
import { AppToaster } from "../../helpers/toaster";
import { FullPageSpinner } from "../helpers/FullPageSpinner";
import { TestEntities } from "../test-entities/fail-data";
import TestHistoryBarGraph from "../test-entities/test-history-bar-graph";
import { Container, FlexContainer } from "./trigger-resource-fetch-panel";

const ONE_YEAR_AGO = moment().subtract(1, "year").toDate();

interface ITestRunDetailsProps {
  testId: string;
  testRunId: string;
}

interface ITestRunHistoryProps {
  testId: string;
}

interface IProps {
  domainId: string;
}

enum EntityState {
  failing = "failingEntities",
  disabled = "disabledEntities",
  error = "errorEntities",
}

const entityStateToDisplayName = {
  [EntityState.failing]: "Failing entities",
  [EntityState.disabled]: "Disabled entities",
  [EntityState.error]: "Erroring entities",
};

const RoundedDiv = styled.div`
  border: 1px solid ${BASE_PALETTE.FOG};
  border-radius: 10px;
  padding: ${2 * GRID_SPACING}px;
`;

const SpacedContainer = styled.div`
  margin-top: 10px;
`;

const FlexDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TestRunDetails: React.FC<ITestRunDetailsProps> = ({
  testId,
  testRunId,
}) => {
  const { loading, data, error } = useGetDetailsForTestRunQuery({
    variables: {
      testId,
      testRunId,
    },
  });

  if (loading) {
    return <Spinner size={SpinnerSize.LARGE} />;
  }
  if (isSome(error)) {
    return <div>Error fetching historical details.</div>;
  }

  const renderDownloadRawDataButton = () => {
    const testResult = data?.organization?.testResults[0];
    const rawData = testResult?.rawData;
    if (!isSome(testResult) || !isSome(rawData)) {
      return null;
    }

    return (
      <Button onClick={() => downloadRawData(rawData, testResult.name, testId)}>
        Download raw data for this run
      </Button>
    );
  };

  const renderEntities = (entityState: EntityState) => {
    const entities = (data?.organization?.testResults[0][entityState] ??
      []) as TestEntity[];
    if (entities.length === 0) {
      return (
        <RoundedDiv>
          No {entityStateToDisplayName[entityState].toLowerCase()} for this test
          at the selected time.
        </RoundedDiv>
      );
    }

    const groupedEntities = groupBy(entities, "entityType");
    const entityRenderers = Object.entries(groupedEntities)
      .sort(([type1], [type2]) => type1.localeCompare(type2))
      .map(([entityType, entitiesOfType]) => (
        <div key={entityType}>
          <h5>
            {TestEntityTypeDisplayNames[entityType as TestEntityTypeClientSafe]}
          </h5>
          <TestEntities
            key={entityType}
            entityType={entityType as TestEntityTypeClientSafe}
            entityIds={entitiesOfType.map(e => e.entityId)}
            testId={testId}
            isSLATest={data?.organization?.testResults[0].isSLATest ?? false}
            allowWhitelisting={true}
            first={entitiesOfType.length}
          />
        </div>
      ))
      .flat();

    return (
      <RoundedDiv>
        <h4>{entityStateToDisplayName[entityState]}</h4>
        {entityRenderers}
      </RoundedDiv>
    );
  };

  return (
    <>
      <FlexDiv>
        <div>Viewing failure data for {testId} test.</div>
        {renderDownloadRawDataButton()}
      </FlexDiv>
      <FlexDiv>
        <SpacedContainer>{renderEntities(EntityState.failing)}</SpacedContainer>
        <SpacedContainer>
          {renderEntities(EntityState.disabled)}
        </SpacedContainer>
        <SpacedContainer>{renderEntities(EntityState.error)}</SpacedContainer>
      </FlexDiv>
    </>
  );
};

const TestRunHistory: React.FC<ITestRunHistoryProps> = ({ testId }) => {
  const { loading, data, error } = useGetHistoryForTestQuery({
    variables: { testId },
  });
  const [selectedTestRunId, setSelectedTestRunId] =
    useState<Maybe<string>>(nothing);

  if (loading) {
    return <Spinner size={SpinnerSize.LARGE} />;
  }
  if (isSome(error)) {
    return <div>Error fetching history for test.</div>;
  }

  const testResultData = data?.organization?.testResults[0];
  if (!isSome(testResultData) || testResultData.history.length === 0) {
    return <div>No test history found for {testId} test.</div>;
  }

  const historyData = testResultData.history.map(h => {
    return {
      flipTimestamp: parseInt(h.flipTime, 10),
      outcome: h.outcome,
      testRun: h.testRun,
    };
  });

  const firstDatapoint = new Date(historyData[0].flipTimestamp);
  const startDate =
    firstDatapoint < ONE_YEAR_AGO ? ONE_YEAR_AGO : firstDatapoint;

  return (
    <>
      <TestHistoryBarGraph
        historyData={historyData}
        monitoringDisabledStatus={testResultData.monitoringDisabledStatus}
        dateRange={[startDate, new Date()]}
        onBarClick={(testRunId: string) => {
          setSelectedTestRunId(testRunId);
        }}
      />
      <SpacedContainer>
        {isSome(selectedTestRunId) ? (
          <TestRunDetails testId={testId} testRunId={selectedTestRunId} />
        ) : (
          "Click a section of the chart to see historical details."
        )}
      </SpacedContainer>
    </>
  );
};

export const TestRunHistoryPanel: React.FC<IProps> = ({ domainId }) => {
  const { loading, data, error } = useGetTestRunnerTestIdsQuery();

  const [selectedTestId, setSelectedTestId] = useState<Maybe<string>>(nothing);

  if (loading || !data) {
    return <FullPageSpinner />;
  }
  if (isSome(error)) {
    LogError(error ?? new Error("Bad fetch"));
    AppToaster.show({
      message: "There was a problem fetching test information",
      intent: Intent.DANGER,
    });
    return null;
  }

  return (
    <Container>
      <h2>View test history</h2>
      <FlexContainer>
        <Suggest
          items={data.testRunnerTestIds}
          selectedItem={selectedTestId}
          onItemSelect={testId => setSelectedTestId(testId)}
          itemRenderer={(i, { modifiers, handleClick }) => (
            <MenuItem
              key={i}
              text={i}
              active={modifiers.active}
              onClick={handleClick}
            />
          )}
          inputValueRenderer={i => i}
          itemPredicate={(query, item) => {
            const norm = (s: string) => s.replace("-", "").toLowerCase();
            return norm(item).includes(norm(query));
          }}
          popoverProps={{ minimal: true }}
          inputProps={{ placeholder: "Select a test ID" }}
          noResults={<div>No matching test IDs.</div>}
        />
      </FlexContainer>

      {isSome(selectedTestId) ? (
        <TestRunHistory testId={selectedTestId} />
      ) : (
        <div>Select a test to view history.</div>
      )}
    </Container>
  );
};

gql`
  query getHistoryForTest($testId: String!) {
    organization {
      id
      testResults(testIds: [$testId]) {
        id
        history {
          outcome
          flipTime
          testRun {
            id
          }
        }
        monitoringDisabledStatus {
          id
          evidenceDoc {
            id
            title
            url
          }
          testWhitelistReason
          isDisabled
          createdAt
          updatedAt
          disabledBy {
            id
            displayName
            imageUrl
          }
        }
      }
    }
  }
`;

gql`
  query getDetailsForTestRun($testId: String!, $testRunId: String!) {
    organization {
      id
      testResults(testIds: [$testId], testRunId: $testRunId) {
        id
        name
        rawData
        isSLATest
        failingEntities {
          entityId
          entityType
        }
        disabledEntities {
          entityId
          entityType
          reason
        }
        errorEntities {
          entityId
          entityType
        }
      }
    }
  }
`;
