import "./region-select.scss";

import { Callout, Intent, MenuItem, Position } from "@blueprintjs/core";
import type { ItemRenderer } from "@blueprintjs/select";
import { MultiSelect } from "@blueprintjs/select";
import React from "react";

import type { AllLinkedCredentialsQuery } from "../../../../gen/components";

interface IProps {
  arn: string;
  regions: string[];
  domain: NonNullable<AllLinkedCredentialsQuery["organization"]>;
  setRegions: (regions: string[]) => void;
}

const regionList = [
  "us-east-2",
  "us-east-1",
  "us-west-1",
  "us-west-2",
  "af-south-1",
  "ap-south-1",
  "ap-northeast-2",
  "ap-southeast-1",
  "ap-southeast-2",
  "ap-northeast-1",
  "ca-central-1",
  "eu-central-1",
  "eu-west-1",
  "eu-west-2",
  "eu-west-3",
  "eu-north-1",
  "me-south-1",
  "sa-east-1",
];
export const RegionSelect: React.FC<IProps> = ({
  regions,
  setRegions,
  domain,
  arn,
}) => {
  function selectRegion(region: string) {
    let nextRegions = regions.slice();
    nextRegions = [...nextRegions, region];
    setRegions(nextRegions);
  }

  function deselectRegion(index: number) {
    const nextRegions = regions.filter((_, i) => i !== index);
    setRegions(nextRegions);
  }

  function isRegionSelected(region: string) {
    return regions.includes(region);
  }

  const handleTagRemove = (tag: React.ReactNode, index: number) => {
    deselectRegion(index);
  };

  const renderRegion: ItemRenderer<string> = (
    region: string,
    { modifiers, handleClick }
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    return (
      <MenuItem
        active={modifiers.active}
        icon={isRegionSelected(region) ? "tick" : "blank"}
        key={region}
        onClick={handleClick}
        text={region}
        shouldDismissPopover={true}
      />
    );
  };

  function filterRegion(query: string, choice: string) {
    const val = choice;
    return val.toLowerCase().includes(query.toLowerCase());
  }

  return (
    <div className="aws-region-select-div">
      {regions.length === 0 ? (
        <Callout
          intent={Intent.WARNING}
          icon="warning-sign"
          title="Select at least one region"
        />
      ) : undefined}

      <p>
        Select all regions with {domain.displayName} production infrastructure
      </p>

      <MultiSelect
        itemRenderer={renderRegion}
        tagRenderer={(region: string) => region}
        tagInputProps={{
          onRemove: handleTagRemove,
          tagProps: { minimal: true, fill: true, large: true },
          className: "aws-region-selector",
        }}
        itemPredicate={filterRegion}
        items={regionList}
        selectedItems={regions}
        onItemSelect={(region: string) => {
          if (!isRegionSelected(region)) {
            selectRegion(region);
          } else {
            deselectRegion(regions.indexOf(region));
          }
        }}
        noResults={<MenuItem disabled={true} text="No results." />}
        popoverProps={{
          boundary: "window",
          position: Position.LEFT_TOP,
        }}
        fill
      />
    </div>
  );
};
