import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import type { IRiskSchema } from "common/schemas/riskRegister/risk-register-content";
import React, { useEffect, useState } from "react";

import { BASE_PALETTE } from "../../../alpaca/base/colors";
import { BodyShortText, H2 } from "../../../alpaca/components";
import { LogError } from "../../../errors";
import type { GetInfoForRiskAssessmentQuery } from "../../../gen/components";
import {
  GetInfoForRiskAssessmentDocument,
  useRrv3AddScenarioMutation,
} from "../../../gen/components";
import { ButtonGroupPanel } from "../../master-detail-view/detail-panels/button-group-panel";
import { TextAreaPanel } from "../../master-detail-view/detail-panels/text-area-panel";
import type { RiskAssessmentDomain } from "./queries-and-types";

interface IProps {
  scenario?: Maybe<RiskAssessmentDomain["rrv3RiskScenarios"][number]>;
  schema: IRiskSchema;
  onComplete: (scenarioId: string) => void;
}

enum EvaluationStep {
  DescribeScenario,
  SelectLikelihood,
  SelectImpact,
}

interface IScenarioData {
  description: Maybe<string>;
  likelihood: Maybe<number>;
  impact: Maybe<number>;
}

export const RiskScenarioEvaluationFlow: React.FC<IProps> = ({
  scenario,
  onComplete,
  schema,
}) => {
  const [evaluation, setEvaluation] = useState<IScenarioData>({
    description: scenario?.description,
    likelihood: scenario?.likelihood,
    impact: scenario?.impact,
  });
  const [evaluateScenario] = useRrv3AddScenarioMutation({
    update: (cache, result) => {
      const scenarioToUpdate = result.data?.rrv3AddScenario;
      if (!isSome(scenarioToUpdate)) {
        return;
      }
      const previousData = cache.readQuery<GetInfoForRiskAssessmentQuery>({
        query: GetInfoForRiskAssessmentDocument,
      });
      if (!isSome(previousData) || !isSome(previousData.organization)) {
        return;
      }

      const newScenarios = isSome(scenario)
        ? previousData.organization.rrv3RiskScenarios.map(s => {
            if (s.id === scenarioToUpdate.id) {
              return { ...s, ...scenarioToUpdate };
            } else {
              return s;
            }
          })
        : previousData.organization.rrv3RiskScenarios.concat({
            ...scenarioToUpdate,
            tasks: [],
          });

      cache.writeQuery<GetInfoForRiskAssessmentQuery>({
        query: GetInfoForRiskAssessmentDocument,
        data: {
          organization: {
            ...previousData.organization,
            rrv3RiskScenarios: newScenarios,
          },
        },
      });
      onComplete(scenarioToUpdate.id);
    },
  });
  const [evaluationStep, setEvaluationStep] = useState(
    EvaluationStep.DescribeScenario
  );
  useEffect(() => {
    setEvaluationStep(EvaluationStep.DescribeScenario);
  }, [scenario]);
  if (evaluationStep === EvaluationStep.DescribeScenario) {
    return (
      <TextAreaPanel
        textContent={
          <span>
            What might happen if a vulnerability in{" "}
            <span style={{ color: BASE_PALETTE.GRAPE }}>
              {schema.shortHeading}
            </span>{" "}
            was exploited?
          </span>
        }
        currentText={evaluation.description ?? undefined}
        onNewValue={newValue =>
          setEvaluation({ ...evaluation, description: newValue })
        }
        onContinue={() => setEvaluationStep(EvaluationStep.SelectLikelihood)}
        placeholder="Risk scenario description"
      />
    );
  } else if (evaluationStep === EvaluationStep.SelectLikelihood) {
    const textContent = (
      <>
        <H2>How likely is it that this scenario occurs?</H2>
        <BodyShortText>{evaluation.description}</BodyShortText>
      </>
    );
    return (
      <ButtonGroupPanel
        textContent={textContent}
        buttonValues={LIKELIHOOD_BUTTON_VALUES}
        selectedValue={evaluation.likelihood}
        onNewValue={newValue => {
          setEvaluation({
            ...evaluation,
            likelihood: +newValue,
          });
          setEvaluationStep(EvaluationStep.SelectImpact);
        }}
      />
    );
  } else {
    const textContent = (
      <>
        <H2>How impactful would it be if this scenario occurs?</H2>
        <BodyShortText>{evaluation.description}</BodyShortText>
      </>
    );
    return (
      <ButtonGroupPanel
        textContent={textContent}
        buttonValues={IMPACT_BUTTON_VALUES}
        selectedValue={evaluation.impact}
        onNewValue={newValue => {
          if (
            !isSome(evaluation.likelihood) ||
            !isSome(evaluation.description)
          ) {
            setEvaluationStep(EvaluationStep.DescribeScenario);
          } else {
            evaluateScenario({
              variables: {
                description: evaluation.description,
                likelihood: evaluation.likelihood,
                impact: +newValue,
                riskCategoryId: schema.id,
                scenarioIdToUpdate: scenario?.id,
              },
            }).catch(LogError);
          }
        }}
      />
    );
  }
};

const LIKELIHOOD_BUTTON_VALUES = [
  {
    label: "Not likely",
    value: 0,
  },
  {
    label: "Somewhat likely",
    value: 1,
  },
  {
    label: "Very likely",
    value: 2,
  },
];

const IMPACT_BUTTON_VALUES = [
  {
    label: "Not impactful",
    value: 0,
  },
  {
    label: "Somewhat impactful",
    value: 1,
  },
  {
    label: "Very impactful",
    value: 2,
  },
];
