import type { Maybe } from "common/base/types/maybe";
import React, { useRef, useState } from "react";
import styled from "styled-components";

import { BASE_PALETTE } from "../../../alpaca/base/colors";
import { GRID_SPACING } from "../../../alpaca/base/grid";
import { Dimensions } from "../../vanta-chrome/constants";

/**
 * Use css-style rules without semicolons, such as "200px"  or "calc(100vh - 10px)"
 */
interface IProps {
  minHeight?: Maybe<string>;
  maxHeight?: Maybe<string>;
}

/**
 * Wrapping a DataTable (or any table) in this component will apply Standard View styling
 * to the table and make the headers sticky.
 */
export const StickyTableHeaderContainer: React.FC<IProps> = ({
  minHeight,
  maxHeight,
  children,
}) => {
  const divRef = useRef<HTMLDivElement>(null);
  const [isScrolled, setIsScrolled] = useState(false);
  return (
    <StyledTableContainerDiv
      ref={divRef}
      isScrolled={isScrolled}
      minHeight={minHeight}
      maxHeight={maxHeight}
      onScroll={e => setIsScrolled((divRef?.current?.scrollTop ?? 0) > 0)}
    >
      {children}
    </StyledTableContainerDiv>
  );
};

const StyledTableContainerDiv = styled.div<{
  isScrolled?: Maybe<boolean>;
  minHeight?: Maybe<string>;
  maxHeight?: Maybe<string>;
}>`
  min-height: ${({ minHeight }) => minHeight ?? `${5 * GRID_SPACING}px`};
  max-height: ${({ maxHeight }) =>
    maxHeight ??
    `calc(
    100vh - ${Dimensions.TOP_BAR_HEIGHT}px - ${15 * GRID_SPACING}px
  )`};
  overflow-y: auto;
  position: relative;
  thead {
    td,
    th {
      z-index: 2;
      position: sticky;
      top: 0;
      background-color: ${BASE_PALETTE.SNOW};
      ${({ isScrolled }) =>
        Boolean(isScrolled)
          ? `box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.06);`
          : ``}
    }
  }
`;
