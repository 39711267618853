/**
 * Common styles and components used in the vulns page
 */
import { Icon, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import type { Maybe} from "common/base/types/maybe";
import { isSome, nothing } from "common/base/types/maybe";
import { simplePlural } from "common/grammar/plurals";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import styled, { css } from "styled-components";

import { BASE_PALETTE, VANTA_COLORS } from "../../../../alpaca/base/colors";
import { StyledLinkDeprecated } from "../../../../alpaca/base/deprecated";
import { BASE_TYPOGRAPHY } from "../../../../alpaca/base/typography";
import { BodyText, Button, H4, Tag, Tooltip } from "../../../../alpaca/components";
import { VulnSeverity } from "../utils";

interface IProps {
  // eslint-disable-next-line vanta/optional-always-maybe, vanta/prefer-maybe
  noWrap?: boolean | undefined;
  // eslint-disable-next-line vanta/optional-always-maybe, vanta/prefer-maybe
  inline?: boolean | undefined;
}

const NO_WRAP_CSS = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Heading = styled.div<IProps>`
  font-weight: 600;
  color: ${props => props.color ?? "#333333"};
  font-size: 12px;
  line-height: 14px;
  ${props => (props.noWrap ? NO_WRAP_CSS : "")}
  ${props => (props.inline ? "display:inline-block;" : "")}
`;

export const Caption = styled.div<IProps>`
  font-size: 11px;
  line-height: 13px;
  color: #828282;
  ${props => (props.noWrap ? NO_WRAP_CSS : "")}
  ${props => (props.inline ? "display:inline-block;" : "")}
`;

export const Strong = styled.span`
  font-weight: 600;
`;

export const Link = styled.div`
  color: ${VANTA_COLORS.VANTA_PURPLE};
  font-size: 14px;
  line-height: 16px;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const Section = styled.div`
  margin-bottom: 24px;
`;

export const LeftControls = styled.div`
  display: flex;
  align-items: center;
  > div:not(:first-child) {
    margin-left: 24px;
  }
`;

export const ControlsTopPanel = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 12px;
  margin-right: 12px;
  margin-bottom: 0px;
`;

export const SortFilterButton = styled(Button)`
  &&& {
    font-size: ${BASE_TYPOGRAPHY.FONT_SIZES.BODY_TEXT};
    border-radius: 500px;
    margin-left: 6px;
    padding: 10px 20px;
  }
`;

export const ControlLabel = styled.div`
  color: ${BASE_PALETTE.CHARCOAL};
  font-size: ${BASE_TYPOGRAPHY.FONT_SIZES.BODY_TEXT};
  font-weight: 400;
  display: inline-block;
`;

export const PageContainer = styled.div`
  width: 100%;
`;

export const TabContainer = styled.div`
  width: 100%;
  min-height: calc(100vh - 360px);
  background-color: ${BASE_PALETTE.SNOW};
  padding: 24px;
  border-radius: 4px;
  table {
    width: 100%;
    tr:nth-child(even) {
      background-color: #f7f8fa88; // This should be added to Alpaca
    }
  }
`;

export const InfoCalloutText = styled.p`
  margin: 6px 0;
  font-size: ${BASE_TYPOGRAPHY.FONT_SIZES.BODY_TEXT};
  font-weight: ${BASE_TYPOGRAPHY.FONT_WEIGHTS.SLIGHTLY_BOLD};
  line-height: ${BASE_TYPOGRAPHY.LINE_HEIGHTS.BODY_TEXT};
  color: #4f4f4f;
  max-width: 600px;
`;

export const InfoCalloutHeading = styled.div`
  font-size: 18px;
  line-height: 21px;
  font-weight: ${BASE_TYPOGRAPHY.FONT_WEIGHTS.SLIGHTLY_BOLD};
`;

export const InfoCalloutContainer = styled.div`
  padding: 12px;
  border-bottom: 1px solid ${VANTA_COLORS.BORDER_LIGHT};
`;

export const LoadingContainer = styled.div`
  margin-top: 10%;
  width: 100%;
  align-items: center;
`;

export const TableContainer = styled.div`
  margin: 0px;
  padding: 0px;
  margin-top: 24px;
`;

export const EntryListContainer = styled.div`
  margin-top: 24px;
  border: 1px solid ${VANTA_COLORS.MENU_SEPARATOR};
  border-radius: 4px;

  > div {
    border-bottom: 1px solid ${VANTA_COLORS.MENU_SEPARATOR};
  }

  > div:last-child {
    border: none;
  }
`;

export const TabDescription = styled.div`
  color: ${VANTA_COLORS.TEXT_DESCRIPTION};
  margin-bottom: 12px;
`;

export const ActionContainer = styled.div`
  flex-shrink: 0;
  margin: 12px;
`;

export const RightButton = styled.div`
  float: right;
`;

const IconContainer = styled.div`
  margin-right: 12px;
`;

export const getVulnCountTags = (vulnerabilityCounts: {
  low: number;
  medium: number;
  high: number;
}, stale: boolean = false) => {
  const { low, medium, high } = vulnerabilityCounts;
  if (low === 0 && medium === 0 && high === 0) {
    return [
      <IconContainer key="checkContainer">
        <Tooltip content={`No vulnerabilities detected`}>
          <Icon
            icon={stale? IconNames.WARNING_SIGN : IconNames.TICK_CIRCLE}
            intent={stale? Intent.WARNING : Intent.SUCCESS}
            iconSize={Icon.SIZE_LARGE}
          />
        </Tooltip>
      </IconContainer>,
    ];
  }

  const tagList = [];
  if (low > 0) {
    tagList.push(
      <Tag key="low" intent={Intent.NONE} text={`${low} Low`} />
    );
  }
  if (medium > 0) {
    tagList.push(
      <Tag key="medium"
        intent={Intent.WARNING}
        text={`${medium} Medium`}/>
    );
  }
  if (high > 0) {
    tagList.push(
      <Tag key="high" text={`${high} High`} intent={Intent.DANGER}/>
    );
  }

  return tagList;
};

export const NotBeenScannedTag: React.FC = () => (
  <IconContainer>
    <Tooltip
      content={`This repository hasn't been scanned for vulnerabilities yet. Click to view more details.`}
    >
      <Icon
        icon={IconNames.WARNING_SIGN}
        intent={Intent.WARNING}
        iconSize={Icon.SIZE_LARGE}
      />
    </Tooltip>
  </IconContainer>
);

export const NotReportingPackagesTag: React.FC = () => (
  <IconContainer>
    <Tooltip
      content={`This server hasn't reported package information to Vanta yet.`}
    >
      <Icon
        icon={IconNames.WARNING_SIGN}
        intent={Intent.WARNING}
        iconSize={Icon.SIZE_LARGE}
      />
    </Tooltip>
  </IconContainer>
);

export const getVulnSeverityTag = (severity: VulnSeverity) => {
  switch (severity) {
    case VulnSeverity.LOW:
      return <Tag intent={Intent.NONE} text="Low"/>;
    case VulnSeverity.MEDIUM:
      return <Tag intent={Intent.WARNING} text="Medium"/>;
    case VulnSeverity.HIGH:
      return <Tag intent={Intent.DANGER} text="High"/>;
    case VulnSeverity.CRITICAL:
      return <Tag intent={Intent.DANGER} text="High"/>; // Critical vulns are still just tagged as high
    default:
      return null;
  }
};

const BackLinkContainer = styled.div`
  display: flex;
  align-items: start;
  margin: 12px 0px;
`;

export const BackLink: React.FC<{
  destinationTitle: string;
  destinationLink: string;
}> = ({ destinationTitle, destinationLink }) => (
  <StyledLinkDeprecated to={destinationLink}>
    <BackLinkContainer>
      <BodyText color={VANTA_COLORS.VANTA_PURPLE}>
        <Icon iconSize={20} icon={IconNames.CHEVRON_LEFT} />
        Back to {destinationTitle}
      </BodyText>
    </BackLinkContainer>
  </StyledLinkDeprecated>
);

export enum AgentViewBy {
  SERVER = "servers",
  PACKAGE = "packages",
}

export const parseAgentViewBy = (): AgentViewBy => {
  if (location.hash.length > 1) {
    const hashElements = location.hash.slice(1).split("/");
    const viewByUrlParam = hashElements.length < 2 ? nothing : hashElements[1];
    return viewByUrlParam === AgentViewBy.PACKAGE
      ? AgentViewBy.PACKAGE
      : AgentViewBy.SERVER;
  }
  return AgentViewBy.SERVER;
};

/*
Why does this component exist, and why does it work via URL manipulation?

Answer: To implement "View by sever" vs "View by package" controls, it's easier to put them *within*
the view and make them adjust the URL, rather than make the controls a higher-level component that contains and conditionally
hides/shows each tab.
By putting it in the child, it's easier to make this sit horizontally alongside other controls in the top bar
(search, sort by, pagination, etc), without having to resort to CSS-hacks.
*/
export const AgentViewByButtons: React.FC = () => {
  const history = useHistory();
  const [viewBy, setViewBy] = useState(AgentViewBy.SERVER);

  useEffect(() => {
    setViewBy(parseAgentViewBy());
  }, [location.hash]);

  const titleForViewByOption = (viewByOption: string) => {
    switch (viewByOption) {
      case AgentViewBy.SERVER:
        return "Server";
      case AgentViewBy.PACKAGE:
        return "Package";
      default:
        return "";
    }
  };
  return (
    <div>
      <ControlLabel>View by:</ControlLabel>
      {Object.values(AgentViewBy).map(viewByOption => (
        <SortFilterButton
          key={viewByOption}
          secondary={viewByOption === viewBy}
          onClick={() => {
            history.push(`vulnerabilities#agent-vulns/${viewByOption}`);
          }}
        >
          {titleForViewByOption(viewByOption)}
        </SortFilterButton>
      ))}
    </div>
  );
};

const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
`;
const TooltipContainer = styled.div`
  margin-right: 8px;
  margin-bottom: 6px;
`;
const ViewButton = styled(Button)`
  margin-left: 12px;
  margin-bottom: 8px;
`;
export const VulnerabilityFindingsHeading: React.FC<{
  numVulns: number;
  service?: Maybe<string>;
  instructions?: Maybe<string>;
  url?: Maybe<string>;
  tooltipText?: Maybe<string>;
}> = ({ numVulns, service, instructions, url, tooltipText }) => (
  <>
    <HeadingContainer>
      {isSome(tooltipText) ? (
        <TooltipContainer>
          <Tooltip content={tooltipText}>
            <Icon icon={IconNames.INFO_SIGN} />
          </Tooltip>
        </TooltipContainer>
      ) : (
        nothing
      )}
      <H4 fontWeight={BASE_TYPOGRAPHY.FONT_WEIGHTS.SLIGHTLY_BOLD}>
        {`${simplePlural(numVulns, "Vulnerability")} Found`}
      </H4>
      {isSome(url) ? (
        <ViewButton onClick={() => window.open(url, "_blank")}>
          {`View in ${service}`}
        </ViewButton>
      ) : (
        nothing
      )}
    </HeadingContainer>
    {isSome(instructions) ? (
      <BodyText>{instructions}</BodyText>
    ) : isSome(service) ? (
      <BodyText>Go to {service} to view more vulnerability details. </BodyText>
    ) : (
      nothing
    )}
  </>
);

export const PackageIdentifier: React.FC<{
  packageName: string;
  version?: Maybe<string>;
}> = ({ packageName, version }) => (
  <>
    <Heading>{packageName}</Heading>
    {isSome(version) ? <Caption>{version}</Caption> : nothing}
  </>
);
