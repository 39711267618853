import { isSome } from "common/base/types/maybe";
import React from "react";
import styled from "styled-components";

import { BASE_TYPOGRAPHY } from "../../../alpaca/base/typography";
import type { Credentials } from "../../../gen/components";
import { useAvailableBetaFeatures } from "../../../helpers/feature-gating/feature-check";
import type { ServiceGroupData } from "../service-groups";
import { InvitationToLinkServiceGroup } from "./invitation-to-link-service-group";
import { ServiceGroupCredentialsList } from "./service-group-credentials-list";

export interface IServiceGroupProps {
  serviceGroup: ServiceGroupData;
  relevantCredentials: Array<
    Pick<
      Credentials,
      "id" | "lastUpdated" | "metadata" | "service" | "isDisabled"
    >
  >;
}

/**
 * ServiceGroup is the generic component for displaying a group of services on
 * the /connections page.
 *
 * If no credential belongs to this group of services, it renders
 * @see UnlinkedServiceGroup. Otherwise, it renders @see LinkedServiceGroup.
 */
export const ServiceGroup: React.FC<IServiceGroupProps> = ({
  serviceGroup,
  relevantCredentials,
}) => {
  const availableBetaFeatures = useAvailableBetaFeatures();
  const atLeastOneServiceLinked = relevantCredentials.length > 0;

  const groupComponent = atLeastOneServiceLinked ? (
    <ServiceGroupCredentialsList
      serviceGroup={serviceGroup}
      relevantCredentials={relevantCredentials}
    />
  ) : (
    <InvitationToLinkServiceGroup
      serviceGroup={serviceGroup}
      relevantCredentials={[]}
    />
  );

  // Display this service group if at least one member service is available.
  if (
    serviceGroup.services.find(
      service =>
        !isSome(service.feature) || availableBetaFeatures?.has(service.feature)
    )
  ) {
    return (
      <div>
        <ServiceGroupHeader>{serviceGroup.name}</ServiceGroupHeader>
        {groupComponent}
      </div>
    );
  }
  return <div />;
};

const ServiceGroupHeaderStyles = {
  FONT_WEIGHT: `${BASE_TYPOGRAPHY.FONT_WEIGHTS.BOLD} !important`,
  FONT_SIZE: 18,
  LINE_HEIGHT: 24,
  MARGIN_TOP: 20,
  MARGIN_BOTTOM: 12,
};

const ServiceGroupHeader = styled.h3`
  font-style: normal;
  font-weight: ${ServiceGroupHeaderStyles.FONT_WEIGHT};
  font-size: ${ServiceGroupHeaderStyles.FONT_SIZE}px;
  line-height: ${ServiceGroupHeaderStyles.LINE_HEIGHT}px;
  margin-top: ${ServiceGroupHeaderStyles.MARGIN_TOP}px;
  margin-bottom: ${ServiceGroupHeaderStyles.MARGIN_BOTTOM}px;
`;
