import { Classes } from "@blueprintjs/core";
import type { SpecificResource } from "common/base/types/gen";
import { isSome } from "common/base/types/maybe";
import type { TestEntityTypeClientSafe } from "common/constants/displayNames";
import gql from "graphql-tag";
import React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { BASE_TYPOGRAPHY } from "../../../alpaca/base/typography";
import { TextLinkLike, Tooltip } from "../../../alpaca/components";

import { LogError } from "../../../errors";
import { useResourcesByIdsQuery } from "../../../gen/components";
import { Ellipsify } from "../../../helpers/ellipsify";
import { DefaultLink } from "../../../helpers/links";
import { TabGroupSlugs } from "../../pages/inventory-list/inventory-list";
import { FailDataRow } from "../fail-data-row";

// Mapping of Entity types to inventory page tab group
const entityTypesToInventoryTabGroupMap: {
  [k in TestEntityTypeClientSafe]?: TabGroupSlugs;
} = {
  S3: TabGroupSlugs.BUCKET,
  AzureBlobContainer: TabGroupSlugs.BUCKET,
  GCPStorageBucket: TabGroupSlugs.BUCKET,
  DigitalOceanSpace: TabGroupSlugs.BUCKET,
  AutoScalingGroup: TabGroupSlugs.AUTOSCALE,
  AzureVirtualMachineScaleSet: TabGroupSlugs.AUTOSCALE,
  JamfManagedComputer: TabGroupSlugs.EMPLOYEE_COMPUTERS,
  KandjiManagedComputer: TabGroupSlugs.EMPLOYEE_COMPUTERS,
  MicrosoftEndpointManagerManagedComputer: TabGroupSlugs.EMPLOYEE_COMPUTERS,
  EC2Instance: TabGroupSlugs.COMPUTE,
  GCPComputeInstance: TabGroupSlugs.COMPUTE,
  AzureVirtualMachine: TabGroupSlugs.COMPUTE,
  AzureScaleSetVirtualMachine: TabGroupSlugs.COMPUTE,
  DigitalOceanDroplet: TabGroupSlugs.COMPUTE,
  AzureContainerRepository: TabGroupSlugs.CONTAINER_REPOSITORY,
  DigitalOceanContainerRepository: TabGroupSlugs.CONTAINER_REPOSITORY,
  ECRContainerRepository: TabGroupSlugs.CONTAINER_REPOSITORY,
  GCPContainerRepository: TabGroupSlugs.CONTAINER_REPOSITORY,
  GithubRepo: TabGroupSlugs.GIT_REPOSITORY,
  BitbucketRepo: TabGroupSlugs.GIT_REPOSITORY,
  GitlabRepo: TabGroupSlugs.GIT_REPOSITORY,
  AzureDevOpsRepo: TabGroupSlugs.GIT_REPOSITORY,
  AzureApplicationGateway: TabGroupSlugs.LOAD_BALANCERS,
  AzureLoadBalancer: TabGroupSlugs.LOAD_BALANCERS,
  DigitalOceanLoadBalancer: TabGroupSlugs.LOAD_BALANCERS,
  AzureCosmosDB: TabGroupSlugs.NOSQL_DB,
  DigitalOceanRedisCluster: TabGroupSlugs.NOSQL_DB,
  DynamoDBTable: TabGroupSlugs.NOSQL_DB,
  BigtableInstance: TabGroupSlugs.NOSQL_DB,
  DatastoreProject: TabGroupSlugs.NOSQL_DB,
  FirestoreProject: TabGroupSlugs.NOSQL_DB,
  AtlasCluster: TabGroupSlugs.NOSQL_DB,
  DigitalOceanApp: TabGroupSlugs.PAAS_APPS,
  HerokuApp: TabGroupSlugs.PAAS_APPS,
  AzureQueue: TabGroupSlugs.QUEUE,
  SQS: TabGroupSlugs.QUEUE,
  GCPSubscription: TabGroupSlugs.QUEUE,
  AzureDatabaseForExternalSQLVariant: TabGroupSlugs.RELATIONAL_DB,
  AzureSQLDatabase: TabGroupSlugs.RELATIONAL_DB,
  AzureSQLManagedInstance: TabGroupSlugs.RELATIONAL_DB,
  AzureSQLServerOnVMs: TabGroupSlugs.RELATIONAL_DB,
  CloudSQLInstance: TabGroupSlugs.RELATIONAL_DB,
  DigitalOceanRelationalDbCluster: TabGroupSlugs.RELATIONAL_DB,
  RDSInstance: TabGroupSlugs.RELATIONAL_DB,
  SpannerInstance: TabGroupSlugs.RELATIONAL_DB,
  BigQueryDataset: TabGroupSlugs.DATA_WAREHOUSE,
  RedshiftCluster: TabGroupSlugs.DATA_WAREHOUSE,
  AzureSynapseWarehouse: TabGroupSlugs.DATA_WAREHOUSE,
};

export const DefaultResourceTestEntities: React.FC<{
  entityType: SpecificResource;
  entityIds: string[];
  testId: string;
  allowWhitelisting: boolean;
  first: number;
}> = ({ entityType, entityIds, testId, allowWhitelisting, first }) => {
  const history = useHistory();
  const { loading, error, data } = useResourcesByIdsQuery({
    variables: { ids: entityIds, specificResourceType: entityType },
  });

  if (loading) {
    return <div />;
  }
  if (error) {
    LogError(error);
    return null;
  }
  if (!data?.organization) {
    return <div />;
  }

  return (
    <>
      {data.organization.resourcesByIds.slice(0, first).map(r => {
        const name = isSome(r.displayName) ? r.displayName : r.uniqueId;
        const resourceDisplayContainer = (
          <FailDataContentContainer>
            <Ellipsify text={name} />
            {isSome(r.displayMetadata) ? (
              <div className={`${Classes.TEXT_MUTED}`}>
                <small>{r.displayMetadata}</small>{" "}
              </div>
            ) : null}
          </FailDataContentContainer>
        );
        const entityLinkItems = [];
        if (
          isSome(r.externalURL) &&
          entityType in entityTypesToInventoryTabGroupMap &&
          isSome(r.displayName)
        ) {
          // Show "View Externally" and "View in inventory" options
          entityLinkItems.push(
            <StyledDefaultLink
              key={entityLinkItems.length}
              href={r.externalURL}
            >
              View externally
            </StyledDefaultLink>
          );
          const divider = <small key={entityLinkItems.length}> - </small>;
          entityLinkItems.push(divider);
          entityLinkItems.push(
            <StyledTextLinkLike
              key={entityLinkItems.length}
              onClick={() => {
                // query param consumed by inventory page to search
                const inventoryLink = `/inventory?q=${r.displayName}#${entityTypesToInventoryTabGroupMap[entityType]}`;
                history.push(inventoryLink);
              }}
            >
              View in inventory
            </StyledTextLinkLike>
          );
        } else if (isSome(r.externalURL)) {
          // Only show clickable content (to external).
          entityLinkItems.push(
            <DefaultLink href={r.externalURL} key={entityLinkItems.length}>
              {resourceDisplayContainer}
            </DefaultLink>
          );
        }

        const tooltipContent =
          r.uniqueId +
          (isSome(r.displayName) && r.displayName !== r.uniqueId
            ? ` (${r.displayName})`
            : "");
        return (
          <FailDataRow
            key={r.id}
            entityId={r.id}
            entityType={entityType}
            testId={testId}
            allowWhitelisting={allowWhitelisting}
          >
            {entityLinkItems.length > 0 ? (
              /** Either display the linkified container or the container
               * and the link items below.
               */
              <div>
                {entityLinkItems.length > 1 ? resourceDisplayContainer : null}
                {entityLinkItems}
              </div>
            ) : (
              <Tooltip
                content={tooltipContent}
                disabled={tooltipContent === name}
              >
                {resourceDisplayContainer}
              </Tooltip>
            )}
          </FailDataRow>
        );
      })}
    </>
  );
};

export const FailDataContentContainer = styled.div`
  max-width: 100%;
`;

const StyledDefaultLink = styled(DefaultLink)`
  font-size: ${BASE_TYPOGRAPHY.FONT_SIZES.HELPER_TEXT};
`;

const StyledTextLinkLike = styled(TextLinkLike)`
  font-size: ${BASE_TYPOGRAPHY.FONT_SIZES.HELPER_TEXT};
`;

gql`
  query resourcesByIds(
    $ids: [String!]!
    $specificResourceType: SpecificResource!
  ) {
    organization {
      id
      resourcesByIds(ids: $ids, specificResourceType: $specificResourceType) {
        id
        createdAt
        deletedAt
        displayName
        displayMetadata
        domainId
        externalURL
        fetchId
        service
        uniqueId
        updatedAt
      }
    }
  }
`;
