import React from "react";

import { getDateString } from "../utils";
import { Caption, Heading } from "./components";
import { Deadline } from "./deadline";

interface IProps {
  slaDeadline: Date;
}

export const SLADeadlineWithDate: React.FC<IProps> = ({ slaDeadline }) => (
  <div>
    <Heading>
      <Deadline date={slaDeadline} />
    </Heading>
    <Caption>{getDateString(slaDeadline)}</Caption>
  </div>
);
