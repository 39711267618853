import React from "react";

import { BodyText } from "../../../../alpaca/components";
import { TabContainer, TabDescription } from "../common/components";
import { AWSContainerRepositoryList } from "./aws-container-repository-list";

export const AWSVulnDisplay: React.FC = () => (
  <>
    <TabDescription>
      <BodyText>
        Monitor your ECR repositories for vulnerabilities surfaced by image
        scans.
      </BodyText>
    </TabDescription>
    <TabContainer>
      <AWSContainerRepositoryList />
    </TabContainer>
  </>
);
