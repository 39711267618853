import { Icon } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import React, { useState } from "react";
import RP_IMAGE from "url:../../static/images/icons/RP.png";

import { Tooltip } from "../../alpaca/components";

import InactiveUserIcon from "../../static/images/icons/inactive-user.svg";

interface IProps {
  circle: boolean;
  dimension: number;
  url: Maybe<string>;
  isSuperUser?: Maybe<boolean>;
  inactiveUser?: Maybe<boolean>;
}

export const MaybeProfileImage: React.FunctionComponent<IProps> = (
  props: IProps
) => {
  const [useDefaultIcon, setDefaultIcon] = useState(false);

  if (
    !props.isSuperUser &&
    (props.url === "" || !isSome(props.url) || useDefaultIcon)
  ) {
    return <Icon iconSize={props.dimension} icon="user" />;
  }

  const radius = props.circle ? props.dimension / 2 : 0;

  const isInactive = props.inactiveUser ?? false;
  if (isInactive) {
    return (
      <Tooltip large={false} content={"Ex-employee"} placement="top">
        <InactiveUserIcon
          style={{
            height: props.dimension,
            width: props.dimension,
            borderRadius: radius,
          }}
        />
      </Tooltip>
    );
  }

  return (
    <img
      onError={() => setDefaultIcon(true)}
      src={props.isSuperUser ? RP_IMAGE : props.url!}
      className="user-icon"
      style={{
        height: props.dimension,
        width: props.dimension,
        borderRadius: radius,
      }}
    />
  );
};

MaybeProfileImage.defaultProps = {
  circle: true,
  dimension: 20,
  url: "",
};
