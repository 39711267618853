import "./heroku-apps-selector.scss";

import { Intent } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import React from "react";

import { LogError } from "../../../../errors";
import {
  useAllLinkedCredentialsQuery,
  useSetCredentialMetadataForHerokuMutation,
} from "../../../../gen/components";
import { AppToaster } from "../../../../helpers/toaster";
import { FullPageSpinner } from "../../../helpers/FullPageSpinner";
import { HerokuAppsDataFetcher } from "./heroku-apps-data-fetcher";

export interface IHerokuApp {
  id: string;
  name: string;
}

interface IHerokuAppsSelectorProps {
  onClose?: Maybe<() => void>;
}

export interface IHerokuScanConfig {
  scanAllApps: boolean;
  appIdsToScan: string[];
}

export const HerokuAppsSelector: React.FunctionComponent<IHerokuAppsSelectorProps> =
  ({ onClose }) => {
    const { data, loading, error } = useAllLinkedCredentialsQuery();
    const [setHerokuScanConfig] = useSetCredentialMetadataForHerokuMutation();

    const updateHerokuScanConfig = async (scanConfig: IHerokuScanConfig) => {
      const { scanAllApps, appIdsToScan } = scanConfig;
      try {
        const didSetHerokuScanConfig = await setHerokuScanConfig({
          variables: {
            metadata: JSON.stringify({ scanAllApps, appIdsToScan }),
          },
        });
        if (didSetHerokuScanConfig.errors) {
          throw new Error(JSON.stringify(didSetHerokuScanConfig.errors));
        }
        AppToaster.show({
          icon: "tick",
          intent: Intent.SUCCESS,
          message: "Heroku apps to scan updated successfully!",
          timeout: 2500,
        });
        if (isSome(onClose)) {
          onClose();
        }
      } catch (e) {
        LogError(e);
      }
    };

    if (error) {
      LogError(error);
      throw error;
    }

    if (loading) {
      return <FullPageSpinner />;
    }

    if (data?.organization) {
      const herokuCredentials = data.organization.credentials.find(
        x => isSome(x) && x.service === "heroku"
      );
      if (isSome(herokuCredentials)) {
        return (
          <div className="heroku-apps-selector-container">
            <div className="heroku-apps-selector-text">
              <h3 className="heroku-apps-selector-heading">Add Heroku apps</h3>
              <div>Which apps should we monitor for vulnerabilities?</div>
            </div>
            <div className="heroku-apps-selector-table">
              <HerokuAppsDataFetcher
                onClose={onClose}
                herokuCredentials={herokuCredentials}
                updateHerokuScanConfig={updateHerokuScanConfig}
              />
            </div>
          </div>
        );
      }
    }
    // should never have no credentials
    throw new Error("Internal Server Error");
  };

gql`
  query fetchHerokuAppList {
    organization {
      id
      herokuApps {
        name
        id
      }
    }
  }
`;

gql`
  mutation setCredentialMetadataForHeroku($metadata: String!) {
    setCredentialMetadata(metadata: $metadata, service: "heroku", merge: true) {
      id
    }
  }
`;
