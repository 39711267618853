import { Spinner } from "@blueprintjs/core";
import { isSome } from "common/base/types/maybe";
import {
  specificResourceGraphQLTypeToEnumValue,
  SpecificResourceToInventoryType,
} from "common/utils/inventory";
import {
  deserializeVantaAttributes,
  hasExternallyManagedVantaAttributes,
} from "common/utils/vantaAttributes";
import gql from "graphql-tag";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import type { QueuesQuery } from "../../../../gen/components";
import {
  ErrorOption,
  useQueuesQuery,
  useSetInventoryResourceVantaAttributeMutation,
} from "../../../../gen/components";
import { ErrorToggle } from "../error-toggle";
import { InventoryCard } from "../inventory-card";
import { InventoryErrorCard } from "../inventory-error-card";
import { AccountTag, EncryptionTag, RegionTag } from "../tags";
import {
  fetchMoreResources,
  getDebounceContext,
  getResourceFilterParams,
  invalidInventoryType,
  PAGE_SIZE,
  setResourceVantaAttributeFn,
} from "../utils";
import { SearchResultsSummary } from "./search-results-summary";
import type { IInventoryTabProps } from "./shared-interface";

export const Queues: React.FC<IInventoryTabProps> = ({
  errorCount,
  searchString,
}) => {
  const [showError, setShowError] = React.useState(false);

  const { data, fetchMore, loading } = useQueuesQuery({
    variables: {
      first: PAGE_SIZE,
      errorOpt: showError ? ErrorOption.ERROR_ONLY : ErrorOption.NONERROR_ONLY,
      filterParams: getResourceFilterParams(searchString),
    },
    context: getDebounceContext("queue"),
  });

  if (loading) {
    return <Spinner />;
  }

  const cards = data?.organization.resources.edges?.map(item => (
    <QueueCard key={item.node.id} item={item} domain={data.organization} />
  ));

  return (
    <>
      <SearchResultsSummary
        searchString={searchString}
        numberResults={data?.organization.resources?.totalCount ?? 0}
      />
      <ErrorToggle
        onToggle={setShowError}
        isError={showError}
        errorCount={errorCount}
        resource={"queue"}
      />
      <InfiniteScroll
        className="inventory-list-card-group"
        dataLength={data?.organization.resources.edges.length ?? 0}
        next={async () => fetchMoreResources(data, fetchMore)}
        hasMore={data?.organization.resources.pageInfo.hasNextPage ?? false}
        loader={<Spinner />}
      >
        {cards}
      </InfiniteScroll>
    </>
  );
};

const QueueCard: React.FC<{
  item: NonNullable<QueuesQuery["organization"]>["resources"]["edges"][number];
  domain: NonNullable<QueuesQuery["organization"]>;
}> = ({ item, domain }) => {
  const [setResourceVantaAttribute] =
    useSetInventoryResourceVantaAttributeMutation();

  const specificResourceKind = specificResourceGraphQLTypeToEnumValue(
    item.node.__typename
  );
  const type = SpecificResourceToInventoryType[specificResourceKind](
    item.node as any
  );
  const resourceMongoId = item.node.id;

  let uid;
  let labels;
  switch (item.node.__typename) {
    case "SpecificAzureQueueResource": {
      uid = item.node.uniqueId;
      labels = [
        <AccountTag
          key="account"
          cloudAccountId={item.node.storageAccountName}
        />,
        <RegionTag key="region" region={item.node.location} />,
        <EncryptionTag
          key="encryption"
          encrypted={true} // Azure encrypts by default, and it can't be disabled
        />,
      ];
      break;
    }
    case "SpecificSQSResource": {
      uid = item.node.url;
      labels = [
        <AccountTag key="account" cloudAccountId={item.node.account} />,
        <RegionTag key="region" region={item.node.region} />,
        <EncryptionTag
          key="encryption"
          encrypted={isSome(item.node.kmsMasterKeyId)}
        />,
      ];
      break;
    }
    case "SpecificGCPSubscriptionResource": {
      uid = item.node.uniqueId;
      labels = [
        <AccountTag key="account" cloudAccountId={item.node.projectId} />,
        <EncryptionTag
          key="encryption"
          encrypted={true} // GCP encrypts by default. It can be replaced by a customer key, but there is still encryption no matter what
        />,
      ];
      break;
    }
    default:
      return invalidInventoryType(item.node.__typename);
  }

  const vantaAttributes = deserializeVantaAttributes(
    item.node.vantaAttributes ?? []
  );
  const locked = hasExternallyManagedVantaAttributes(
    item.node.vantaAttributes ?? []
  );
  const setVantaAttribute = setResourceVantaAttributeFn(
    setResourceVantaAttribute,
    specificResourceKind,
    resourceMongoId,
    item.node.__typename
  );
  return isSome(item.node.fetchError) ? (
    <InventoryErrorCard
      type={type}
      uid={uid}
      labels={labels}
      key={item.node.id}
      name={item.node.name}
      error={item.node.fetchError}
    ></InventoryErrorCard>
  ) : (
    <InventoryCard
      type={type}
      uid={uid}
      labels={labels}
      key={item.node.id}
      name={item.node.name}
      description={vantaAttributes.description}
      owner={vantaAttributes.ownerId}
      canContainUserData={true}
      containsUserData={vantaAttributes.containsUserData}
      userDataDescription={vantaAttributes.userDataStored}
      locked={locked}
      setVantaAttribute={setVantaAttribute}
    />
  );
};

gql`
  query queues(
    $first: Int!
    $after: String
    $errorOpt: errorOption
    $filterParams: filterParams
  ) {
    organization {
      id
      name
      resources(
        first: $first
        after: $after
        genericResourceType: Queue
        sortParams: { field: "createdAt", direction: -1 }
        filterParams: $filterParams
        options: { errorOption: $errorOpt }
      ) {
        totalCount
        edges {
          node {
            id
            __typename
            uniqueId
            fetchError
            vantaAttributes {
              key
              value
              managedExternally
            }
            ... on SpecificAzureQueueResource {
              location
              name
              storageAccountName
            }
            ... on SpecificSQSResource {
              region
              account
              url
              name
              kmsMasterKeyId
            }
            ... on SpecificGCPSubscriptionResource {
              projectId
              name
            }
          }
        }
        pageInfo {
          hasNextPage
          endCursor
          startCursor
          hasPreviousPage
        }
      }
    }
  }
`;
