import { isSome } from "common/base/types/maybe";

import type { FetchDomainInfoNetworkQuery } from "../../../gen/components";

export const COLUMN_ORDER_SECURITY_RULES = ["ports", "who", "protocol"];

export const HEADERS_SECURITY_RULES = {
  ports: "Ports",
  protocol: "Protocol",
  who: "For",
};

export const COLUMN_CLASSES_SECURITY_RULES = {
  ports: "manage-network-ports",
  protocol: "manage-network-protocol",
  who: "manage-network-who",
};

type Vpc = NonNullable<
  NonNullable<
    NonNullable<
      NonNullable<
        FetchDomainInfoNetworkQuery["organization"]
      >["networkConfiguration"]
    >["vpcs"]
  >[number]
>;

export function getRowDataForSecurityRule(
  rule:
    | NonNullable<
        NonNullable<
          NonNullable<
            NonNullable<
              NonNullable<
                NonNullable<Vpc["autoscaleGroups"]>[number]
              >["securityGroups"]
            >[number]
          >["outboundRules"]
        >[number]
      >
    | NonNullable<
        NonNullable<
          NonNullable<
            NonNullable<
              NonNullable<
                NonNullable<Vpc["instances"]>[number]
              >["securityGroups"]
            >[number]
          >["outboundRules"]
        >[number]
      >
) {
  const ports =
    !isSome(rule.fromPort) && !isSome(rule.toPort)
      ? "all"
      : rule.fromPort === rule.toPort
      ? String(rule.fromPort)
      : `${rule.fromPort} - ${rule.toPort}`;
  const protocol = rule.ipProtocol === "-1" ? "all" : rule.ipProtocol;
  const who =
    rule.ipRanges.length > 0
      ? JSON.stringify(rule.ipRanges)
      : rule.securityGroupIds.length > 0
      ? rule.securityGroupIds.join(", ")
      : null;

  return {
    ports,
    protocol,
    who,
  };
}
