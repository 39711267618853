import {
  Button,
  Callout,
  Divider,
  HTMLSelect,
  Intent,
} from "@blueprintjs/core";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import React, { useState } from "react";
import styled from "styled-components";

import { LogError } from "../../../errors";
import type { GetBufferedEmailsQuery } from "../../../gen/components";
import {
  EmailKey,
  GetKilledEmailKeysDocument,
  useDeactivateKillSwithMutation,
  useGetBufferedEmailsQuery,
  useGetKilledEmailKeysQuery,
  useKillSingleEmailMutation,
} from "../../../gen/components";
import { AppToaster } from "../../../helpers/toaster";
import { FullPageSpinner } from "../../helpers/FullPageSpinner";
import { KilledEmailTypeDisplay } from "./killed-email-type-display";

const UNSELECTED = "__unselected";

export const EmailKillSwitchPanel: React.FC = () => {
  const [deactivateKillSwith] = useDeactivateKillSwithMutation({
    refetchQueries: [{ query: GetKilledEmailKeysDocument }],
    onCompleted() {
      AppToaster.show({
        message: "Email kill switch deactivated",
        intent: Intent.SUCCESS,
      });
    },
  });
  const [emailKeyToKill, setEmailKeyToKill] = useState(UNSELECTED);
  const [killEmail] = useKillSingleEmailMutation({
    refetchQueries: [{ query: GetKilledEmailKeysDocument }],
    onCompleted() {
      AppToaster.show({
        message: "Email kill switch activated",
        intent: Intent.SUCCESS,
      });
    },
  });
  const { loading: killListLoading, data: killListData } =
    useGetKilledEmailKeysQuery();
  const { loading, data } = useGetBufferedEmailsQuery();

  if (loading || !data || killListLoading || !killListData) {
    return <FullPageSpinner />;
  }

  const unkilledEmailKeys = Object.keys(EmailKey).filter(
    key => !killListData.emailKillSwitchList.includes(key as EmailKey)
  );
  const killSwitch = (
    <div>
      <FlexContainer>
        <HTMLSelect onChange={e => setEmailKeyToKill(e.currentTarget.value)}>
          <option value={UNSELECTED}>Select email key</option>
          {unkilledEmailKeys
            .filter(key => key !== EmailKey.UNIVERSAL_KILL_SWITCH_KEY)
            .map(key => (
              <option key={key} value={key}>
                {key}
              </option>
            ))}
        </HTMLSelect>
        <Button
          intent={Intent.DANGER}
          disabled={emailKeyToKill === UNSELECTED}
          onClick={async () => {
            if (!isSome(emailKeyToKill)) {
              return;
            }
            killEmail({
              variables: { emailKey: emailKeyToKill as EmailKey },
            }).catch(LogError);

            setEmailKeyToKill(UNSELECTED);
          }}
        >
          Activate kill switch
        </Button>
      </FlexContainer>
      {unkilledEmailKeys.includes(EmailKey.AUTOMATED_EMAILS_KILL_SWITCH_KEY) ? (
        <div>
          <Button
            intent={Intent.DANGER}
            onClick={async () => {
              killEmail({
                variables: {
                  emailKey: EmailKey.AUTOMATED_EMAILS_KILL_SWITCH_KEY,
                },
              }).catch(LogError);
              setEmailKeyToKill(UNSELECTED);
            }}
          >
            Activate kill switch for automated emails
          </Button>
        </div>
      ) : null}
      {unkilledEmailKeys.includes(EmailKey.UNIVERSAL_KILL_SWITCH_KEY) ? (
        <div>
          <Button
            intent={Intent.DANGER}
            onClick={async () => {
              killEmail({
                variables: { emailKey: EmailKey.UNIVERSAL_KILL_SWITCH_KEY },
              }).catch(LogError);
              setEmailKeyToKill(UNSELECTED);
            }}
          >
            Activate kill switch for all emails
          </Button>
        </div>
      ) : null}
    </div>
  );

  const bufferedEmailMap = data.bufferedEmails.reduce((obj, email) => {
    obj[email.emailKey] = obj[email.emailKey] ?? [];
    obj[email.emailKey].push(email);
    return obj;
  }, {} as { [k: string]: NonNullable<GetBufferedEmailsQuery["bufferedEmails"]> });

  return (
    <Container>
      <h1>Email kill switching and releasing</h1>
      <Callout intent={Intent.WARNING}>
        All button clicks for email release and discard are final. There is no
        confirmation dialog!
      </Callout>
      <h3>Activate a kill switch</h3>
      {killSwitch}
      <Divider />
      <h3>Currently killed email types</h3>
      {killListData.emailKillSwitchList.map(emailKey => (
        <FlexContainer key="emailKey">
          <div>{emailKey}</div>
          <Button
            onClick={async () => {
              deactivateKillSwith({
                variables: { emailKey },
              }).catch(LogError);
            }}
          >
            Release kill switch
          </Button>
        </FlexContainer>
      ))}
      <Divider />
      <h3>Currently buffered emails</h3>
      <Callout intent={Intent.WARNING}>
        SES rate limits the number of emails we can send per second, so clicking
        "Send" once will send at most 10 emails
      </Callout>
      {Object.keys(bufferedEmailMap)
        .sort()
        .map(emailKey => (
          <KilledEmailTypeDisplay
            key={emailKey}
            emailKey={emailKey as EmailKey}
            emails={bufferedEmailMap[emailKey]}
          />
        ))}
    </Container>
  );
};

const Container = styled.div`
  padding: 40px;
`;

export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 500px;
  margin-bottom: 12px;
  button {
    margin-left: 12px;
  }
`;

gql`
  query getBufferedEmails {
    bufferedEmails {
      id
      domainId
      emailKey
    }
  }
`;

gql`
  query GetKilledEmailKeys {
    emailKillSwitchList
  }
`;

gql`
  mutation killSingleEmail($emailKey: emailKey!) {
    emailKillSwitchIndividualStop(key: $emailKey)
  }
`;

gql`
  mutation deactivateKillSwith($emailKey: emailKey!) {
    emailKillSwitchReleaseIndividualStop(key: $emailKey)
  }
`;
