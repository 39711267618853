import { OL } from "@blueprintjs/core";
import { makeExternalId } from "common/resources/specific-resources/aws/aws-helpers-client-safe";
import React from "react";

import { Config } from "../../../../config";
import { DefaultLink } from "../../../../helpers/links";

export const NewRole: React.FC<{ domainId: string }> = ({ domainId }) => {
  const accountId = Config.isStaging ? "483412410471" : "956993596390";
  return (
    <div>
      <OL>
        <li>
          Navigate to the{" "}
          <DefaultLink href="https://console.aws.amazon.com/iam/home?#/roles$new?step=type&roleType=crossAccount">
            AWS role creator
          </DefaultLink>{" "}
          and make sure <strong>Another AWS Account</strong> is selected.
        </li>
        <li>
          Paste the following value into the Account ID field:{" "}
          <pre>{accountId}</pre>
        </li>
        <li>
          Select <strong>Require external ID</strong> and enter the value return{" "}
          <pre>{makeExternalId(domainId)}</pre>
        </li>
        <li>
          Confirm that <strong>Require MFA</strong> is <em>not selected</em>
        </li>
        <li>
          Click <strong> Next: Permissions </strong>{" "}
        </li>
        <li>
          Search for <code>SecurityAudit</code> and check the box next to the{" "}
          <code>SecurityAudit</code> policy. Do the same for the{" "}
          <code>VantaAdditionalPermissions</code> policy that we just created.
        </li>
        <li>
          Click <strong> Next: Tags </strong>
        </li>
        <li>
          Click <strong> Next: Review </strong> and name the role{" "}
          <code>vanta-auditor</code>
        </li>
        <li>
          Click <strong>Create role</strong>
        </li>
      </OL>
      <p>
        If you use S3 bucket policies, be sure to{" "}
        <DefaultLink
          href="https://aws.amazon.com/blogs/security/how-to-restrict-amazon-s3-bucket-access-to-a-specific-iam-role/"
          target="_blank"
          rel="noopener noreferrer"
        >
          add access for this role
        </DefaultLink>
        .
      </p>
    </div>
  );
};
