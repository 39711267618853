import { Spinner } from "@blueprintjs/core";
import {
  HR_SERVICES_SET,
  IDENTITY_PROVIDER_SERVICES_SET,
  SERVICES_WITH_ACCOUNTS,
} from "common/base/types/helpers";
import type { Maybe } from "common/base/types/maybe";
import { getTransformedOrElse, isSome } from "common/base/types/maybe";
import moment from "moment";
import React from "react";
import styled from "styled-components";
import validator from "validator";

import { VANTA_COLORS } from "../../../../alpaca/base/colors";
import { BodyText, Tooltip } from "../../../../alpaca/components";
import { LogError } from "../../../../errors";
import type { GetUserInfoForOffboardingQuery } from "../../../../gen/components";
import {
  GetUserInfoForOffboardingDocument,
  useAcknowledgeNoVendorAccessMutation,
} from "../../../../gen/components";
import { DefaultLink, getAbsolutePathURL } from "../../../../helpers/links";
import Completion from "../../../../static/images/icons/completion-check.svg";
import EmptyCircle from "../../../../static/images/icons/empty-circle.svg";
import { DATETIME_FORMAT, IconContainer } from "../shared/common";
import { OffboardVendorButton } from "./offboard-vendor-button";
import type {
  AccessRemoval,
  OffboardingUser,
  Vendor,
} from "./offboarding-types";

export interface LinkedAccount {
  accountName?: Maybe<string>;
  deactivationDate?: Maybe<Date>;
  service: string;
}

interface IProps {
  accessRemoval?: Maybe<AccessRemoval>;
  employee: OffboardingUser;
  linkedAccount?: Maybe<LinkedAccount>;
  vendor: Vendor;
}

export const OffboardVendorDisplay: React.FC<IProps> = props => {
  const { employee, vendor } = props;

  const [acknowledgeNoAccess, mutationResult] =
    useAcknowledgeNoVendorAccessMutation({
      variables: {
        employeeId: employee.id,
        vendorId: vendor.id,
      },
      update: (cache, result) => {
        const newAccessRemoval =
          result.data?.acknowledgeNoVendorAccessForOffboarding;
        if (!isSome(newAccessRemoval)) {
          return;
        }
        const oldData = cache.readQuery<GetUserInfoForOffboardingQuery>({
          query: GetUserInfoForOffboardingDocument,
          variables: { userId: employee.id },
        });
        const user = oldData?.user;
        const organization = oldData?.organization;
        if (!isSome(organization) || !isSome(user)) {
          return;
        }
        cache.writeQuery<GetUserInfoForOffboardingQuery>({
          query: GetUserInfoForOffboardingDocument,
          data: {
            organization,
            user: {
              ...user,
              vendorAccessRemovals:
                user.vendorAccessRemovals.concat(newAccessRemoval),
            },
          },
        });
      },
    });

  const { editable, offboarded, text } = getViewModel(props);

  return (
    <Container>
      <Flex>
        <div style={{ display: "flex", alignItems: "top" }}>
          <IconContainer isCompleted={offboarded}>
            {offboarded ? (
              <Completion />
            ) : mutationResult.loading ? (
              <Spinner />
            ) : editable ? (
              <OffboardVendorButton
                onClick={async () => acknowledgeNoAccess().catch(LogError)}
              />
            ) : (
              <Tooltip
                content={`Deprovision in ${vendor.name}`}
                placement="top"
              >
                <EmptyCircle />
              </Tooltip>
            )}
          </IconContainer>
          <BodyText as="div">
            {validator.isURL(vendor.url) ? (
              <StyledDefaultLink href={getAbsolutePathURL(vendor.url)}>
                {vendor.name}
              </StyledDefaultLink>
            ) : (
              <span>{vendor.name}</span>
            )}
            {isSome(text) ? (
              <BodyText as={"span"} color={VANTA_COLORS.TEXT_MUTED}>
                {`: ${text}`}
              </BodyText>
            ) : null}
          </BodyText>
        </div>
      </Flex>
    </Container>
  );
};

function getViewModel(options: IProps): {
  editable: boolean;
  offboarded: boolean;
  text?: Maybe<String>;
} {
  const { accessRemoval, employee, linkedAccount, vendor } = options;
  if (isSome(vendor.associatedCredential)) {
    if (
      IDENTITY_PROVIDER_SERVICES_SET.has(vendor.associatedCredential) ||
      SERVICES_WITH_ACCOUNTS.has(vendor.associatedCredential)
    ) {
      // Case 1: Vendor uses Accounts (and LinkedAccounts).
      // IDP gets transformed into a 'linked account'
      return {
        editable: false,
        offboarded:
          !isSome(linkedAccount) || isSome(linkedAccount.deactivationDate),
        text: !isSome(linkedAccount)
          ? "No account associated with this vendor"
          : isSome(linkedAccount.deactivationDate)
          ? `${linkedAccount.accountName ?? ""} deactivated ${moment(
              linkedAccount.deactivationDate
            ).format(DATETIME_FORMAT)}`
          : `deactivate ${
              isSome(linkedAccount.accountName)
                ? `${linkedAccount.accountName} from`
                : "in"
            } ${vendor.name}`,
      };
    } else if (HR_SERVICES_SET.has(vendor.associatedCredential)) {
      // Case 2: Vendor is an HRIS
      const hrUser = employee.allHrUsers.find(
        u => u.service === vendor.associatedCredential
      );
      let text: Maybe<string> = null;
      if (isSome(hrUser)) {
        const fullName = `${hrUser.givenName} ${hrUser.familyName}`;
        text = hrUser.isActive
          ? `Terminate ${fullName} in ${vendor.name}`
          : `${fullName} terminated ${
              isSome(hrUser.endDate)
                ? moment(hrUser.endDate).format(DATETIME_FORMAT)
                : ""
            }`;
      } else {
        text = "No HR employee associated with this user";
      }
      return {
        editable: false,
        offboarded: !isSome(hrUser) || !hrUser.isActive,
        text,
      };
    } else {
      throw new Error(
        `Vendor ${vendor.associatedCredential} is in valid state`
      );
    }
  } else if (vendor.name === "Vanta") {
    // Case 3: Vendor is Vanta, automatically mark as de-provisioned.
    return {
      editable: false,
      offboarded: true,
      text: `${employee.displayName} deactivated${getTransformedOrElse(
        employee.endDate,
        endDate => ` on ${moment(endDate).format(DATETIME_FORMAT)}`,
        ""
      )}`,
    };
  } else {
    // Case 4: Vendor needs manual offboard confirmation
    return {
      editable: true,
      offboarded: isSome(accessRemoval),
      text: isSome(accessRemoval)
        ? `confirmed by ${
            accessRemoval.acknowledgedBy?.displayName ?? "(removed user)"
          } ${moment(accessRemoval.acknowledgeDate).format(DATETIME_FORMAT)}`
        : null,
    };
  }
}

const StyledDefaultLink = styled(DefaultLink)`
  text-decoration: underline;
  &,
  &:hover {
    color: inherit;
  }
`;

const Container = styled.div`
  display: flex;
  min-height: 36px;
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
`;
