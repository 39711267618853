import type { Maybe } from "common/base/types/maybe";
import React from "react";
import styled from "styled-components";

import { SingleStepList } from "./single-step-list";

export type StepClickHandler = (
  stepIndex: number,
  substepIndex?: Maybe<number>
) => void;

interface IStepSingleItem {
  text: string;
  completed?: Maybe<boolean>;
}

export interface IStep extends IStepSingleItem {
  substeps?: Maybe<IStepSingleItem[]>;
}

interface IProps {
  steps: IStep[];
  activeStepIndex: number;
  activeSubstepIndex?: Maybe<number>;
  clickHandler: StepClickHandler;
}

export const StepNavMenu: React.FC<IProps> = ({
  steps,
  activeStepIndex,
  activeSubstepIndex,
  clickHandler,
}) => (
  <MenuContainer>
    {steps.map((step, index) => {
      const isActiveStep = index === activeStepIndex;
      return (
        <SingleStepList
          key={`step-${index}`}
          step={step}
          stepIndex={index}
          isActive={isActiveStep}
          activeSubstepIndex={isActiveStep ? activeSubstepIndex : null}
          clickHandler={clickHandler}
        />
      );
    })}
  </MenuContainer>
);

const styles = {
  LEFT_PADDING: 30,
  STEP_ITEM_BOTTOM_MARGIN: 20,
};

const MenuContainer = styled.div`
  padding-left: ${styles.LEFT_PADDING}px;
  & > div {
    margin-bottom: ${styles.STEP_ITEM_BOTTOM_MARGIN}px;
  }
`;
