import { isSome } from "common/base/types/maybe";
import styled, { css } from "styled-components";

import { BASE_PALETTE } from "../../../../alpaca/base/colors";
import { GRID_SPACING } from "../../../../alpaca/base/grid";

export const TableRow = styled.div`
  padding: 0px ${GRID_SPACING}px;
  height: ${8 * GRID_SPACING}px;
  display: flex;
  align-items: center;
  &:not(:last-child) {
    border-bottom: 1px solid ${BASE_PALETTE.WIND};
  }
  ${({ onClick }) =>
    isSome(onClick)
      ? css`
          &:hover {
            cursor: pointer;
            background-color: ${BASE_PALETTE.VAPE};
          }
        `
      : ""}
`;

export const TableCell = styled.div`
  padding: 0px ${2 * GRID_SPACING}px;
`;
