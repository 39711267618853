import React from "react";

import type { GetDataForReportQuery } from "../../../../../gen/components";
import { ControlHeading } from "../../../common";
import type { IDomainForReport } from "../../../helpers/helpers";
import { VantaReportControlStatusIndicator } from "./vanta-report-control-status-indicator";

interface IProps {
  control: NonNullable<
    GetDataForReportQuery["complianceStandard"]
  >["principles"][number]["requirements"][number]["controls"][number];
  domain: IDomainForReport;
}

export const VantaReportControlListItemHeading: React.FunctionComponent<IProps> =
  ({ control, domain }) => (
    <div className="vrp-control-list-item-heading">
      <ControlHeading>{control.name}</ControlHeading>
      <VantaReportControlStatusIndicator control={control} domain={domain} />
    </div>
  );
