export const awsInspectorSetupInstructionsUrl =
  "https://aws.amazon.com/premiumsupport/knowledge-center/set-up-amazon-inspector/";

export const awsInspectorInstanceUrlSearchParamKey = "awsInspectorInstanceId";

export const makeInspectorInstanceSearchParam = (instanceId: string) =>
  `?${awsInspectorInstanceUrlSearchParamKey}=${instanceId}`;

export const awsInspectorVulnsPath = "vulnerabilities#aws-inspector-vulns";
export const awsInspectorVulnsInstancesPath = `${awsInspectorVulnsPath}/instances`;

export const MAX_CVES_TO_DISPLAY = 100;
