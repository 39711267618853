import type {
  FilterParams,
  GenericVulnerabilityResource,
  OsqueryVulnerability,
} from "common/base/types/gen";
import { FilterOperator } from "common/base/types/gen";
import type { Maybe } from "common/base/types/maybe";
import { isSome, nothing } from "common/base/types/maybe";
export {
  severityLevelForNumber,
  textForSeverityNumber,
  VulnSeverity,
} from "common/vulnerabilities/severity-utils";
import moment from "moment";

import { UI_DATE_FORMAT_TO_SECONDS } from "../../../helpers/common";
import type { Machine } from "./agent/server-detail";

export const getFilterParams = (
  searchString: Maybe<string>,
  fields: string[]
): Maybe<FilterParams> => {
  return {
    filters: fields.map(f => {
      return { condition: { STRING_INCLUDES: searchString }, field: f };
    }),
    operator: FilterOperator.OR,
  };
};

export const getDebounceContext = (key: string) => {
  return { debounceKey: `vulns-${key}`, debounceTimeout: 500 };
};

export const getDateString = (date: Date) =>
  moment(date).format(UI_DATE_FORMAT_TO_SECONDS);

export const getLastPingString = (lastPing: Maybe<string>) =>
  isSome(lastPing)
    ? `${moment(parseInt(lastPing, 10) * 1000).fromNow()}`
    : nothing;

export const makeSubheadingString = (
  secondaryId: Maybe<string>,
  lastPing: Maybe<string>
) => {
  if (isSome(secondaryId) && isSome(lastPing)) {
    return `${secondaryId} - ${lastPing}`;
  } else if (isSome(secondaryId)) {
    return secondaryId;
  } else if (isSome(lastPing)) {
    return lastPing;
  }
  return "";
};

export const vulnToTitle = (
  vuln: Pick<
    OsqueryVulnerability,
    "packageName" | "packageSource" | "packageVersion" | "securityVersion"
  >
) => {
  if (!isSome(vuln.packageSource) || vuln.packageName === vuln.packageSource) {
    return `Upgrade ${vuln.packageName} ${vuln.packageVersion} to ${vuln.securityVersion}`;
  } else {
    return `Upgrade ${vuln.packageName} ${vuln.packageVersion} to ${vuln.securityVersion}, or upgrade ${vuln.packageSource} to ${vuln.securityVersion} and remove ${vuln.packageName}`;
  }
};

export const textForDuration = (durationMS: number) => {
  const duration = moment.duration(durationMS, "millisecond");
  const days = Math.floor(duration.asDays());
  if (days >= 1) {
    return `${days} day${days === 1 ? "" : "s"}`;
  }
  const hours = duration.hours();
  return `${days > 0 ? `${days}d ` : ""}${
    hours > 0 ? `${hours}h ` : ""
  }${duration.minutes()}m`;
};

export const humanReadableCloudProvider = (cloudProvider: Maybe<string>) => {
  switch (cloudProvider) {
    case "aws":
      return "AWS";
    case "gcp":
      return "GCP";
    case "azure":
      return "Azure";
    default:
      return nothing;
  }
};

export const primaryIdForMachine = (machine: Partial<Machine>) => {
  const hostname =
    machine?.data?.__typename === "linuxServerData"
      ? machine?.data?.hostname ?? machine.hostIdentifier
      : machine.hostIdentifier;
  const cloudProviderData = machine?.cloudProviderData;
  switch (cloudProviderData?.__typename) {
    case "SpecificEC2InstanceResource":
      return (
        cloudProviderData?.instanceId ?? cloudProviderData?.name ?? hostname
      );
    case "SpecificGCPComputeInstanceResource":
      return (
        cloudProviderData?.instanceId ??
        cloudProviderData?.internalInstanceId ??
        hostname
      );
    case "SpecificAzureVirtualMachineResource":
    case "SpecificAzureScaleSetVirtualMachineResource":
      return (
        cloudProviderData?.azureName ??
        cloudProviderData?.instanceId ??
        hostname
      );
    default:
      return hostname;
  }
};

export const secondaryIdForMachine = (machine: Partial<Machine>) => {
  const hostname =
    machine?.data?.__typename === "linuxServerData"
      ? machine?.data?.hostname
      : nothing;
  const cloudProviderData = machine?.cloudProviderData;
  switch (cloudProviderData?.__typename) {
    case "SpecificEC2InstanceResource": {
      if (isSome(cloudProviderData?.instanceId)) {
        return cloudProviderData?.name ?? hostname;
      } else if (isSome(cloudProviderData?.name)) {
        return hostname;
      }
      return nothing;
    }
    case "SpecificGCPComputeInstanceResource": {
      if (isSome(cloudProviderData?.gcpName)) {
        return cloudProviderData?.internalInstanceId ?? hostname;
      } else if (isSome(cloudProviderData?.internalInstanceId)) {
        return hostname;
      }
      return nothing;
    }
    case "SpecificAzureVirtualMachineResource":
    case "SpecificAzureScaleSetVirtualMachineResource":
      if (isSome(cloudProviderData?.azureName)) {
        return cloudProviderData?.instanceId ?? hostname;
      } else if (isSome(cloudProviderData?.instanceId)) {
        return hostname;
      }
      return nothing;
    default:
      return nothing;
  }

  return nothing;
};

export const accountForMachine = (machine: Partial<Machine>) => {
  const cloudProviderData = machine?.cloudProviderData;
  switch (cloudProviderData?.__typename) {
    case "SpecificEC2InstanceResource":
      return cloudProviderData?.account;
    case "SpecificGCPComputeInstanceResource":
      return cloudProviderData?.projectId;
    case "SpecificAzureVirtualMachineResource":
    case "SpecificAzureScaleSetVirtualMachineResource":
      return cloudProviderData?.subscriptionId;
    default:
      return nothing;
  }
  return nothing;
};

export const privateIpForMachine = (machine: Partial<Machine>) => {
  const cloudProviderData = machine?.cloudProviderData;
  switch (cloudProviderData?.__typename) {
    case "SpecificEC2InstanceResource":
      return cloudProviderData?.privateIPAddress;
    case "SpecificGCPComputeInstanceResource":
      return cloudProviderData?.interfaces?.[0]?.networkIP;
    case "SpecificAzureVirtualMachineResource":
    case "SpecificAzureScaleSetVirtualMachineResource":
      return cloudProviderData?.privateIPAddress;
    default:
      return nothing;
  }
  return nothing;
};

export const uniqueIdForMachine = (machine: Partial<Machine>) => {
  const cloudProviderData = machine?.cloudProviderData;
  switch (cloudProviderData?.__typename) {
    case "SpecificAzureVirtualMachineResource":
    case "SpecificAzureScaleSetVirtualMachineResource":
      return cloudProviderData?.uniqueId;
    default:
      return nothing;
  }
};

export const publicIpForMachine = (machine: Partial<Machine>) => {
  const cloudProviderData = machine?.cloudProviderData;
  switch (cloudProviderData?.__typename) {
    case "SpecificEC2InstanceResource":
      return cloudProviderData?.publicIPAddress;
    case "SpecificGCPComputeInstanceResource":
      return cloudProviderData?.interfaces?.[0]?.externalIP;
    case "SpecificAzureVirtualMachineResource":
    case "SpecificAzureScaleSetVirtualMachineResource":
      return cloudProviderData?.publicIPAddress;
    default:
      return nothing;
  }
  return nothing;
};

export const earliestSlaDeadline = (
  vulns: Array<Pick<GenericVulnerabilityResource, "slaDeadline">>
) => {
  let earliestDeadline: Maybe<Date> = null;
  for (const v of vulns) {
    const slaDeadline = v.slaDeadline;
    if (!isSome(earliestDeadline)) {
      earliestDeadline = slaDeadline;
    } else if (isSome(slaDeadline) && slaDeadline < earliestDeadline) {
      earliestDeadline = slaDeadline;
    }
  }
  return earliestDeadline;
};

interface HasDeadline {
  // The type for a GraphQL `DateTime` field is `any` for some reason
  slaDeadline?: Maybe<any>;
}
export const slaDeadlineComparator = (v1: HasDeadline, v2: HasDeadline) =>
  !isSome(v2.slaDeadline)
    ? -1
    : !isSome(v1.slaDeadline)
    ? 1
    : new Date(v1.slaDeadline).valueOf() - new Date(v2.slaDeadline).valueOf();
