import { Collapse } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { getTransformedOrElse, isSome } from "common/base/types/maybe";
import React, { useState } from "react";
import styled, { css } from "styled-components";

import { Dimensions } from "../../../components/vanta-chrome/constants";
import { BASE_PALETTE } from "../../base/colors";
import { BASE_TYPOGRAPHY } from "../../base/typography";
import { Icon } from "../icon/icon";
import { IconNames } from "../icon/iconNames";
import { SpacedList } from "../primitives/spaced-list";
import { BodyShortText } from "../typography/typography";

interface IProps {
  titleTags?: Maybe<React.ReactNode[]>;
  // eslint-disable-next-line vanta/optional-always-maybe, vanta/prefer-maybe
  className?: string | undefined;
  // eslint-disable-next-line vanta/optional-always-maybe, vanta/prefer-maybe
  style?: React.CSSProperties | undefined;
  title: string;
  stickyTitle?: Maybe<boolean>;
  stickyExtraPadding?: Maybe<number>;
  controlledParams?: Maybe<{
    isOpen: boolean;
    onChange(newValue: boolean): void;
  }>;
  rightElements?: Maybe<React.ReactNode[]>;
  transitionDuration?: Maybe<number>;
}

export const AccordionRow: React.FC<IProps> = ({
  className,
  style,
  title,
  titleTags,
  stickyTitle,
  stickyExtraPadding,
  controlledParams,
  rightElements,
  children,
  transitionDuration,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const collapseIsOpen = controlledParams?.isOpen ?? isOpen;

  return (
    <StyledContainerDiv className={className} style={style}>
      <StyledClickableDiv
        onClick={() => {
          if (isSome(controlledParams)) {
            controlledParams.onChange(!controlledParams.isOpen);
          } else {
            setIsOpen(!isOpen);
          }
        }}
        sticky={stickyTitle}
        stickyPadding={stickyExtraPadding}
      >
        <SpacedList>
          <Icon
            icon={IconNames.CHEVRON_DOWN}
            iconSize={12}
            style={{
              transform: `rotateZ(${collapseIsOpen ? "0" : "-90"}deg)`,
              transition: "transform 0.1s",
            }}
          />
          <BodyShortText
            fontSize={16}
            lineHeight="24px"
            as="div"
            fontWeight={BASE_TYPOGRAPHY.FONT_WEIGHTS.SLIGHTLY_BOLD}
          >
            {title}
          </BodyShortText>
          {titleTags}
        </SpacedList>
        {getTransformedOrElse(
          rightElements,
          elements => (
            <SpacedList>{elements}</SpacedList>
          ),
          null
        )}
      </StyledClickableDiv>
      <Collapse
        isOpen={controlledParams?.isOpen ?? isOpen}
        transitionDuration={transitionDuration ?? undefined}
      >
        {children}
      </Collapse>
    </StyledContainerDiv>
  );
};

const StyledContainerDiv = styled.div`
  width: 100%;
  margin-top: -1px;
`;

const StyledClickableDiv = styled.div<{
  sticky?: Maybe<boolean>;
  stickyPadding?: Maybe<number>;
}>`
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: center;
  padding: 20px 24px;
  height: 64px;
  &:hover {
    cursor: pointer;
    background-color: ${BASE_PALETTE.VAPE};
  }
  border-bottom: 0.5px solid ${BASE_PALETTE.WIND};
  border-top: 0.5px solid ${BASE_PALETTE.WIND};
  margin-bottom: -1px;
  z-index: 2;
  background-color: ${BASE_PALETTE.SNOW};
  ${({ sticky, stickyPadding }) =>
    sticky
      ? css`
          position: sticky;
          top: ${Dimensions.TOP_BAR_HEIGHT + (stickyPadding ?? 0)}px;
        `
      : ""}
`;
