import type { Maybe } from "common/base/types/maybe";
import { isSome, nothing } from "common/base/types/maybe";
import moment from "moment";
import React from "react";

import { BASE_PALETTE } from "../../alpaca/base/colors";
import { BASE_TYPOGRAPHY } from "../../alpaca/base/typography";
import type { TestEntity } from "../../gen/components";
import { FailDataRow } from "./fail-data-row";
import { FailDataRowAcknowledge } from "./fail-data-row-acknowledge";

export interface ISLAMissRow {
  element: JSX.Element;
  openDate: Date;
  entity: TestEntity;
  identifierDisplayText?: Maybe<string>;
  closeDate?: Maybe<Date>;
}

interface IProps {
  testId: string;
  openItems: ISLAMissRow[];
  closedItems: ISLAMissRow[];
  header?: Maybe<string>;
  type?: Maybe<string>;
  allowWhitelisting: boolean;
  first: number;
}

export const FailDataFromSLAMiss: React.FC<IProps> = ({
  testId,
  openItems,
  closedItems,
  header,
  type,
  allowWhitelisting,
  first,
}) => {
  const numTotalItems = openItems.length + closedItems.length;
  const firstOpen = first > openItems.length ? openItems.length : first;
  const firstClosed =
    first > numTotalItems
      ? closedItems.length
      : first > openItems.length
      ? first - openItems.length
      : 0;

  const openLabelForType =
    type === "access-removed" || type === "offboarding"
      ? "Employee left"
      : "Opened";

  const closeLabelForType =
    type === "access-removed"
      ? "Account deactivated"
      : type === "offboarding"
      ? "Employee offboarded"
      : "Closed";

  const renderSLAItem = (item: ISLAMissRow) => {
    const maybeClosedDates = !isSome(item.closeDate) ? (
      nothing
    ) : (
      <div className="fd-sla-detail">
        <strong>{`${closeLabelForType}: `}</strong>
        {moment(item.closeDate).format("MMMM Do YYYY, h:mm:ss a")}
      </div>
    );

    const openDates = (
      <div className="fd-sla-detail">
        <strong>{`${openLabelForType}: `}</strong>
        {moment(item.openDate).format("MMMM Do YYYY, h:mm:ss a")}
      </div>
    );

    const content = (
      <div>
        {item.element}
        {openDates}
        {maybeClosedDates}
      </div>
    );

    const key = `${item.entity.entityId}_${item.entity.entityType}`;
    return !isSome(maybeClosedDates) || !allowWhitelisting ? (
      <FailDataRow
        testId={testId}
        entityId={item.entity.entityId}
        entityType={item.entity.entityType}
        key={key}
        identifierDisplayText={item.identifierDisplayText}
        allowWhitelisting={allowWhitelisting}
      >
        {content}
      </FailDataRow>
    ) : (
      <AcknowledgeDialog
        type={type}
        uniqueKey={key}
        key={key}
        entity={item.entity}
        allEntities={closedItems.map(i => i.entity)}
        identifierDisplayText={item.identifierDisplayText}
        testId={testId}
      >
        {content}
      </AcknowledgeDialog>
    );
  };

  const openHeaderForType =
    type === "access-removed"
      ? "Accounts that have not been deactivated"
      : type === "offboarding"
      ? "Employees that have not been offboarded"
      : `Open ${header}`;

  const closeHeaderForType =
    type === "access-removed"
      ? "Deactivated accounts"
      : type === "offboarding"
      ? "Offboarded employees"
      : `Closed ${header}`;

  const maybeOpenHeader = isSome(header) ? (
    <h5>
      {openHeaderForType}
      <text
        style={{
          color: BASE_PALETTE.CHARCOAL,
          fontWeight: BASE_TYPOGRAPHY.FONT_WEIGHTS.NORMAL,
          marginLeft: "10px",
        }}
      >
        {openItems.length}
      </text>
    </h5>
  ) : (
    nothing
  );

  const maybeClosedHeader = isSome(header) ? (
    <h5>
      {closeHeaderForType}
      <text
        style={{
          color: BASE_PALETTE.CHARCOAL,
          fontWeight: BASE_TYPOGRAPHY.FONT_WEIGHTS.NORMAL,
          marginLeft: "10px",
        }}
      >
        {closedItems.length}
      </text>
    </h5>
  ) : (
    nothing
  );

  return (
    <div>
      {openItems.length > 0 ? (
        <div>
          {maybeOpenHeader}
          {openItems.slice(0, firstOpen).map(item => renderSLAItem(item))}
        </div>
      ) : (
        nothing
      )}
      {closedItems.length > 0 ? (
        <div>
          {maybeClosedHeader}
          {closedItems.slice(0, firstClosed).map(item => renderSLAItem(item))}
        </div>
      ) : (
        nothing
      )}
    </div>
  );
};

const AcknowledgeDialog: React.FC<{
  type?: Maybe<string>;
  uniqueKey: string;
  testId: string;
  entity: TestEntity;
  allEntities?: Maybe<TestEntity[]>;
  identifierDisplayText?: Maybe<string>;
  children?: Maybe<React.ReactNode>;
}> = ({
  type,
  uniqueKey,
  testId,
  entity,
  allEntities,
  identifierDisplayText,
  children,
}) => {
  if (type === "vulnerability") {
    return (
      <FailDataRowAcknowledge
        key={uniqueKey}
        testId={testId}
        entity={entity}
        allAcknowledgeableEntities={allEntities}
        identifierDisplayText={identifierDisplayText}
        dialogText={
          "Please provide a reason that describes why the SLA was missed and how the issue was then resolved."
        }
        acknowledgeAllEnabled={true}
        acknowledgeAllCheckboxLabel={
          "Apply this reason to all other resolved vulnerabilities that require acknowledgement."
        }
      >
        {children}
      </FailDataRowAcknowledge>
    );
  }
  return (
    <FailDataRowAcknowledge
      key={uniqueKey}
      testId={testId}
      entity={entity}
      identifierDisplayText={identifierDisplayText}
    >
      {children}
    </FailDataRowAcknowledge>
  );
};
