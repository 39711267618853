import { Dialog as BPDialog } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import React from "react";
import styled from "styled-components";

import { BASE_PALETTE } from "../../../../alpaca/base/colors";
import { GRID_SPACING } from "../../../../alpaca/base/grid";
import { BASE_TYPOGRAPHY } from "../../../../alpaca/base/typography";
import { Button, H4, Icon, IconNames } from "../../../../alpaca/components";

interface IProps {
  body: React.ReactNode;
  enforceFocus?: Maybe<boolean>;
  footerActions?: Maybe<JSX.Element[]>;
  isOpen: boolean;
  onClose(): void;
  portalClassName?: Maybe<string>;
  title: string;
}

export const Dialog: React.FC<IProps> = ({
  body,
  enforceFocus,
  footerActions,
  isOpen,
  onClose,
  portalClassName,
  title,
}) => (
  <StyledBPDialog
    enforceFocus={enforceFocus ?? undefined}
    isOpen={isOpen}
    onClose={onClose}
    portalClassName={portalClassName ?? undefined}
  >
    <StyledDialogHeaderDiv>
      <H4
        fontWeight={BASE_TYPOGRAPHY.FONT_WEIGHTS.SLIGHTLY_BOLD}
        style={{ marginBottom: "0px " }}
      >
        {title}
      </H4>
      <Button minimal onClick={onClose}>
        <Icon icon={IconNames.CLOSE} />
      </Button>
    </StyledDialogHeaderDiv>
    <StyledDialogBodyDiv>{body}</StyledDialogBodyDiv>
    {isSome(footerActions) ? (
      <StyledDialogFooterDiv>{footerActions}</StyledDialogFooterDiv>
    ) : null}
  </StyledBPDialog>
);

const styles = {
  BODY_TOP_MARGIN: 3 * GRID_SPACING,
  FOOTER_BUTTON_SPACING: 12,
  FOOTER_TOP_MARGIN: 2 * GRID_SPACING,
  FOOTER_BOTTOM_MARGIN: 2 * GRID_SPACING,
  HORIZONTAL_MARGIN: 4 * GRID_SPACING,
  TOP_MARGIN: 4 * GRID_SPACING,
};

const StyledBPDialog = styled(BPDialog)`
  background: ${BASE_PALETTE.SNOW};
`;

const StyledDialogHeaderDiv = styled.div`
  margin-top: ${styles.TOP_MARGIN}px;
  margin-left: ${styles.HORIZONTAL_MARGIN}px;
  margin-right: ${styles.HORIZONTAL_MARGIN}px;

  display: flex;
  justify-content: space-between;
`;

const StyledDialogBodyDiv = styled.div`
  margin-top: ${styles.BODY_TOP_MARGIN}px;
  margin-left: ${styles.HORIZONTAL_MARGIN}px;
  margin-right: ${styles.HORIZONTAL_MARGIN}px;
`;

const StyledDialogFooterDiv = styled.div`
  margin-top: ${styles.FOOTER_TOP_MARGIN}px;
  margin-bottom: ${styles.FOOTER_BOTTOM_MARGIN}px;
  margin-right: ${styles.HORIZONTAL_MARGIN}px;
  display: flex;
  justify-content: flex-end;
  button {
    margin-left: ${styles.FOOTER_BUTTON_SPACING}px;
  }
`;
