import { InputGroup, Spinner } from "@blueprintjs/core";
import { toPossibleDate } from "common/base/dateUtils";
import type { SortParams } from "common/base/types/gen";
import { isSome, nothing } from "common/base/types/maybe";
import {
  SERVER_VULNERABILITY_SORT_PARAMS_BY_TYPE,
  ServerVulnerabilitySortType,
} from "common/vulnerabilities/vulnerability-sort";
import gql from "graphql-tag";
import React, { useState } from "react";
import { useHistory } from "react-router";

import { LogError, LogErrorMessage } from "../../../../errors";
import type { FetchAgentVulnerabilitiesForMonitoredServersQuery } from "../../../../gen/components";
import { useFetchAgentVulnerabilitiesForMonitoredServersQuery } from "../../../../gen/components";
import type { PaginationParams } from "../../../helpers/generic-paginator";
import {
  GenericPaginator,
  applyPaginationParams,
  getInitialPaginationParams,
  DEFAULT_ITEMS_PER_PAGE_OPTION,
} from "../../../helpers/generic-paginator";
import {
  AgentViewByButtons,
  ControlLabel,
  ControlsTopPanel,
  EntryListContainer,
  LeftControls,
  SortFilterButton,
  TableContainer,
} from "../common/components";
import {
  primaryIdForMachine,
  secondaryIdForMachine,
  getDebounceContext,
  getFilterParams,
} from "../utils";
import { VulnSearchResultSummary } from "../vuln-search-result-summary";
import { ServerEntry } from "./server-entry";
import { VulnTableEmptyState } from "./vuln-table-empty-state";

type Machine = NonNullable<
  NonNullable<
    FetchAgentVulnerabilitiesForMonitoredServersQuery["organization"]
  >["machines"]["edges"][number]["node"]
>;

const hasVuln = (m: Machine) => {
  if (m.data.__typename !== "linuxServerData") {
    // This should never happen, so log as a sanity check
    LogErrorMessage(
      "A non-server workstation has been loaded on the vulnerabilities page. This shouldn't happen."
    );
    return false;
  }
  return m.data.vulnSummary.totalVulnCount! > 0;
};

export const AgentMonitoredServerList: React.FC = () => {
  const history = useHistory();
  const [paginationParams, setPaginationParams] = useState<PaginationParams>(
    getInitialPaginationParams()
  );

  const [searchString, setSearchString] = useState<string>("");
  const [sortParams, setSortParams] = useState<SortParams>(
    SERVER_VULNERABILITY_SORT_PARAMS_BY_TYPE[
      ServerVulnerabilitySortType.DUE_DATE
    ]
  );

  const { error, loading, data, fetchMore } =
    useFetchAgentVulnerabilitiesForMonitoredServersQuery({
      variables: {
        first: DEFAULT_ITEMS_PER_PAGE_OPTION,
        sortParams,
        filterParams: getFilterParams(searchString, ["name", "machineNames"]),
      },
      context: getDebounceContext("agent-monitored-server-list"),
    });

  if (error) {
    LogError(error);
    return <div />;
  }

  const machines = applyPaginationParams(
    data?.organization.machines?.edges.map(e => e.node) ?? [],
    paginationParams
  );

  const numUnmonitored =
    data?.organization.unmonitoredInstances?.totalCount ?? 0;
  const numMonitored = data?.organization.machines.totalCount ?? 0;

  const serversWithVulnerabilities = machines.filter(
    m => isSome(m) && hasVuln(m)
  );
  const cleanServers = machines.filter(m => isSome(m) && !hasVuln(m));

  const pageInfo = data?.organization.machines?.pageInfo;

  return (
    <TableContainer>
      <ControlsTopPanel>
        <LeftControls>
          <InputGroup
            type="search"
            value={searchString}
            onChange={e => setSearchString(e.target.value)}
            placeholder="Search by name"
          />
          <AgentViewByButtons />
          <ControlLabel>Sort by:</ControlLabel>
          <SortFilterButton
            key="minSlaDeadline"
            secondary={
              sortParams ===
              SERVER_VULNERABILITY_SORT_PARAMS_BY_TYPE[
                ServerVulnerabilitySortType.DUE_DATE
              ]
            }
            onClick={() => {
              setSortParams(
                SERVER_VULNERABILITY_SORT_PARAMS_BY_TYPE[
                  ServerVulnerabilitySortType.DUE_DATE
                ]
              );
              setPaginationParams(getInitialPaginationParams());
            }}
          >
            Due date
          </SortFilterButton>
          <SortFilterButton
            key="totalVulnCount"
            secondary={
              sortParams ===
              SERVER_VULNERABILITY_SORT_PARAMS_BY_TYPE[
                ServerVulnerabilitySortType.MOST_ISSUES
              ]
            }
            onClick={() => {
              setSortParams(
                SERVER_VULNERABILITY_SORT_PARAMS_BY_TYPE[
                  ServerVulnerabilitySortType.MOST_ISSUES
                ]
              );
              setPaginationParams(getInitialPaginationParams());
            }}
          >
            Most Issues
          </SortFilterButton>
        </LeftControls>

        <GenericPaginator
          pageInfo={pageInfo}
          itemsLoaded={serversWithVulnerabilities.length + cleanServers.length}
          totalItems={numMonitored}
          paginationParams={paginationParams}
          setPaginationParams={setPaginationParams}
          paginationId={"paginator-agent-monitored-servers"}
          fetchMore={fetchMore}
        />
      </ControlsTopPanel>

      {loading || paginationParams.loading ? (
        <Spinner />
      ) : machines.length === 0 && searchString === "" ? (
        <VulnTableEmptyState
          numMonitored={numMonitored}
          numUnmonitoredButLinked={numUnmonitored}
        />
      ) : (
        <>
          <VulnSearchResultSummary
            searchString={searchString}
            numberResults={machines.length}
          />
          <EntryListContainer>
            {[...serversWithVulnerabilities, ...cleanServers].map(machine => {
              const lastPing =
                machine.data.__typename === "linuxServerData"
                  ? machine.data.lastPing
                  : nothing;
              const reportsPackages =
                machine.data.__typename === "linuxServerData"
                  ? machine?.data?.reportsPackages ?? true
                  : true;

              const vulnSummary =
                machine.data.__typename === "linuxServerData"
                  ? machine?.data?.vulnSummary
                  : nothing;
              const minSlaDeadline = vulnSummary?.minSlaDeadline;

              return (
                <ServerEntry
                  key={machine.id}
                  primaryId={primaryIdForMachine(machine)}
                  secondaryId={secondaryIdForMachine(machine)}
                  lastPing={lastPing}
                  slaDeadline={toPossibleDate(minSlaDeadline)}
                  vulnerabilityCounts={{
                    low: vulnSummary?.totalLowSeverity ?? 0,
                    medium: vulnSummary?.totalMidSeverity ?? 0,
                    high: vulnSummary?.totalHighSeverity ?? 0,
                  }}
                  reportsPackages={reportsPackages}
                  vantaAttributes={machine.vantaAttributes}
                  handleClick={() =>
                    history.push({ search: `?serverId=${machine.id}` })
                  }
                />
              );
            })}
          </EntryListContainer>
        </>
      )}
    </TableContainer>
  );
};

gql`
  query fetchAgentVulnerabilitiesForMonitoredServers(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $sortParams: sortParams
    $filterParams: filterParams
  ) {
    organization {
      id
      unmonitoredInstances: cloudInstances(
        first: 0
        activeOnly: true
        withoutVantaAgentOnly: true
      ) {
        totalCount
      }
      machines(
        first: $first
        after: $after
        last: $last
        before: $before
        serversOnly: true
        activeOnly: true
        sortParams: $sortParams
        filterParams: $filterParams
      ) {
        totalCount
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            id
            cloudProvider
            cloudProviderId
            hostIdentifier
            cloudProviderData {
              id
              instanceId
              ... on SpecificEC2InstanceResource {
                name
                publicIPAddress
                privateIPAddress
                account
                tags {
                  key
                  value
                }
              }

              ... on SpecificGCPComputeInstanceResource {
                gcpName: name
                projectId
                internalInstanceId
                interfaces {
                  networkIP
                  externalIP
                }
                labels {
                  key
                  value
                }
              }

              ... on SpecificAzureVirtualMachineResource {
                azureName: name
                subscriptionId
                instanceId
                publicIPAddress
                privateIPAddress
                uniqueId
                tags {
                  key
                  value
                }
              }

              ... on SpecificAzureScaleSetVirtualMachineResource {
                azureName: name
                subscriptionId
                instanceId
                publicIPAddress
                privateIPAddress
                uniqueId
                tags {
                  key
                  value
                }
              }
            }
            data {
              id
              lastPing
              ... on linuxServerData {
                kernelVersion
                hostname
                osFamily
                reportsPackages
                vulnSummary {
                  totalVulnCount
                  maxSeverity
                  minSlaDeadline
                  totalLowSeverity
                  totalMidSeverity
                  totalHighSeverity
                }
              }
            }
            vantaAttributes {
              key
              value
              managedExternally
            }
          }
        }
      }
    }
  }
`;
