import "./tooltip.scss";

import type { Tooltip2Props } from "@blueprintjs/popover2";
import { Tooltip2 } from "@blueprintjs/popover2";
import classNames from "classnames";
import type { Maybe } from "common/base/types/maybe";
import React from "react";
import { GRID_SPACING } from "../../base/grid";

export type TooltipTheme = "dark" | "light";

const TOOLTIP_THEME_TO_CLASS: { [k in TooltipTheme]: string } = {
  dark: "alpaca-tooltip-dark",
  light: "alpaca-tooltip-light",
};

type CustomizableTooltip2Props = Omit<
  Tooltip2Props,
  "inheritDarkTheme" | "minimal" | "position"
>;

export interface ITooltipProps extends CustomizableTooltip2Props {
  theme?: Maybe<TooltipTheme>;
  // eslint-disable-next-line vanta/optional-always-maybe, vanta/prefer-maybe
  large?: boolean | undefined;
}

export const Tooltip: React.FC<ITooltipProps> = ({
  theme,
  children,
  large,
  ...props
}) => (
  <Tooltip2
    {...props}
    modifiers={{
      offset: {
        options: {
          offset: [0, large ? GRID_SPACING : GRID_SPACING / 2],
        },
      },
      ...(props.modifiers ?? {}),
    }}
    popoverClassName={classNames(
      props.portalClassName,
      "alpaca-tooltip-popover",
      TOOLTIP_THEME_TO_CLASS[theme ?? "dark"],
      large ? "alpaca-tooltip-large" : undefined
    )}
  >
    {children}
  </Tooltip2>
);
