import type { Maybe } from "common/base/types/maybe";
import { getTransformedOrElse } from "common/base/types/maybe";
import React from "react";
import styled, { css } from "styled-components";

import { Dimensions } from "../../../components/vanta-chrome/constants";
import { BASE_PALETTE } from "../../base/colors";
import { GRID_SPACING } from "../../base/grid";
import { SpacedList } from "../primitives/spaced-list";
import { BodyText, H4 } from "../typography/typography";

export interface ICardTitleRowProps {
  title: string;
  sticky?: Maybe<boolean>;
  description?: Maybe<React.ReactNode>;
  leftElements?: Maybe<React.ReactNode[]>;
  rightElements?: Maybe<React.ReactNode[]>;
  // eslint-disable-next-line vanta/optional-always-maybe, vanta/prefer-maybe
  className?: string | undefined;
  // eslint-disable-next-line vanta/optional-always-maybe, vanta/prefer-maybe
  style?: React.CSSProperties | undefined;
}

export const CardTitleRow: React.FC<ICardTitleRowProps> = ({
  title,
  description,
  leftElements,
  rightElements,
  sticky,
  style,
  className,
}) => {
  const titleJSX = <H4 style={{ margin: 0 }}>{title}</H4>;
  const titleMaybeWithLeftElements = getTransformedOrElse(
    leftElements,
    elements => (
      <SpacedList>
        {titleJSX}
        {elements}
      </SpacedList>
    ),
    titleJSX
  );

  const titleWithLeftAndRightElements = getTransformedOrElse(
    rightElements,
    elements => (
      <StyledFlexDiv>
        {titleMaybeWithLeftElements}
        <SpacedList>{elements}</SpacedList>
      </StyledFlexDiv>
    ),
    titleMaybeWithLeftElements
  );

  const titleWithDescription = getTransformedOrElse(
    description,
    text => (
      <div>
        {titleWithLeftAndRightElements}
        <BodyText
          style={{ marginTop: 4, maxWidth: 83 * GRID_SPACING }}
          as="div"
          lineHeight="20px"
          fontSize={14}
          color={BASE_PALETTE.CHARCOAL}
        >
          {text}
        </BodyText>
      </div>
    ),
    titleWithLeftAndRightElements
  );

  return (
    <StyledTitleDiv style={style} className={className} sticky={sticky}>
      {titleWithDescription}
    </StyledTitleDiv>
  );
};

const StyledFlexDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledTitleDiv = styled.div<{ sticky?: Maybe<boolean> }>`
  width: 100%;
  padding: ${2.5 * GRID_SPACING}px ${3 * GRID_SPACING}px;
  min-height: ${8 * GRID_SPACING}px;
  background-color: ${BASE_PALETTE.SNOW};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  z-index: 3;
  ${({ sticky }) =>
    sticky
      ? css`
          position: sticky;
          top: ${Dimensions.TOP_BAR_HEIGHT}px;
          border-bottom: 1px solid ${BASE_PALETTE.WIND};
          margin-bottom: -1px;
        `
      : ""}
`;
