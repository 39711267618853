import { Spinner } from "@blueprintjs/core";
import type { DateRange } from "@blueprintjs/datetime";
import { DateRangeInput } from "@blueprintjs/datetime";
import { toPossibleISOString } from "common/base/dateUtils";
import { FilterOperator } from "common/base/types/gen";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import moment from "moment";
import React, { useState } from "react";
import styled from "styled-components";

import { BodyText } from "../../../../alpaca/components";
import { LogError } from "../../../../errors";
import { useFetchVulnHistoryQuery } from "../../../../gen/components";
import type { PaginationParams } from "../../../helpers/generic-paginator";
import {
  DEFAULT_ITEMS_PER_PAGE_OPTION,
  GenericPaginator,
  getInitialPaginationParams,
  applyPaginationParams,
} from "../../../helpers/generic-paginator";
import {
  ControlLabel,
  ControlsTopPanel,
  InfoCalloutContainer,
  InfoCalloutHeading,
  InfoCalloutText,
  LeftControls,
  TabContainer,
  TabDescription,
  TableContainer,
} from "../common/components";
import { VulnHistoryTable } from "./vuln-history-table";

export enum HistoryType {
  ALL_VULNS = "all",
  SLA_VIOLATIONS = "slaViolationsOnly",
  ON_TIME_REMEDIATIONS = "onTimeRemediationsOnly",
}

interface IProps {
  historyType: HistoryType;
}

const STARTING_DATE_RANGE: DateRange = getWholeDayDateRange([
  moment().subtract(6, "month").toDate(),
  new Date(),
]);

const VantaDateRangeInput = styled(DateRangeInput)`
  margin-left: 12px;
  input {
    width: 100px !important;
    text-align: center;
  }
`;

const getResolvedWithinSLAFilter = (historyType: HistoryType) => {
  switch (historyType) {
    case HistoryType.ALL_VULNS:
      return [];
    case HistoryType.SLA_VIOLATIONS:
      return [
        {
          field: "resolvedWithinSLA",
          condition: {
            BOOL_EQ: false,
          },
        },
      ];
    case HistoryType.ON_TIME_REMEDIATIONS:
      return [
        {
          field: "resolvedWithinSLA",
          condition: {
            BOOL_NE: false,
          },
        },
      ];
    default:
      return [];
  }
};

const getDescription = (historyType: HistoryType) => {
  switch (historyType) {
    case HistoryType.ALL_VULNS:
      return "View previously resolved vulnerabilities.";
    case HistoryType.SLA_VIOLATIONS:
      return "View past vulnerabilities that were not resolved by their SLA deadline.";
    case HistoryType.ON_TIME_REMEDIATIONS:
      return "View past vulnerabilities that were resolved by their SLA deadline.";
    default:
      return null;
  }
};

export const VulnHistoryDisplay: React.FC<IProps> = ({ historyType }) => {
  const [selectedDateRange, setSelectedDateRange] =
    useState(STARTING_DATE_RANGE);
  const [paginationParams, setPaginationParams] = useState<PaginationParams>(
    getInitialPaginationParams()
  );

  const {
    error: error,
    loading: loading,
    data: data,
    fetchMore: fetchMore,
  } = useFetchVulnHistoryQuery({
    variables: {
      first: DEFAULT_ITEMS_PER_PAGE_OPTION,
      filterParams: {
        operator: FilterOperator.AND,
        filters: [
          {
            field: "deletedAt",
            condition: {
              DATE_GTE: toPossibleISOString(selectedDateRange[0]),
              DATE_LT: toPossibleISOString(selectedDateRange[1]),
            },
          },
          ...getResolvedWithinSLAFilter(historyType),
        ],
      },
      sortParams: {
        field: "createdAt",
        direction: 1,
      },
    },
  });

  if (error) {
    LogError(error);
    return null;
  }

  const histories = applyPaginationParams(
    data?.organization.resources?.edges ?? [],
    paginationParams
  )
    .map(e => e.node)
    .filter(
      v =>
        v.__typename !== "SpecificOsqueryVulnerabilityResource" ||
        isSome(v.vulnData)
    )
    .sort(
      (vh1, vh2) =>
        new Date(vh1.createdAt).getTime() - new Date(vh2.createdAt).getTime()
    );

  const pageInfo = data?.organization.resources?.pageInfo;

  const showVulns =
    isSome(selectedDateRange[0]) &&
    isSome(selectedDateRange[1]) &&
    histories.length > 0;
  const vulnsInfo = showVulns ? (
    <VulnHistoryTable history={histories} />
  ) : (
    <InfoCalloutContainer>
      <InfoCalloutHeading>No vulnerabilities to display</InfoCalloutHeading>
      <InfoCalloutText>
        No vulnerabilities were recorded by Vanta during the selected dates.
      </InfoCalloutText>
    </InfoCalloutContainer>
  );

  return (
    <TabContainer>
      <TabDescription>
        <BodyText>{getDescription(historyType)}</BodyText>
      </TabDescription>
      <TableContainer>
        <ControlsTopPanel>
          <LeftControls>
            <ControlLabel>Discovered between:</ControlLabel>
            <VantaDateRangeInput
              formatDate={date => date.toLocaleDateString()}
              parseDate={str => new Date(str)}
              closeOnSelection={true}
              defaultValue={selectedDateRange}
              contiguousCalendarMonths={false}
              maxDate={moment().add(1, "years").toDate()}
              onChange={dateRange => {
                setSelectedDateRange(getWholeDayDateRange(dateRange));
                setPaginationParams(getInitialPaginationParams(50));
              }}
            />
          </LeftControls>

          <GenericPaginator
            pageInfo={pageInfo}
            itemsLoaded={histories.length}
            totalItems={data?.organization.resources?.totalCount ?? 0}
            paginationParams={paginationParams}
            setPaginationParams={setPaginationParams}
            paginationId={"paginator-vuln-history"}
            fetchMore={fetchMore}
          />
        </ControlsTopPanel>
        {loading || paginationParams.loading ? <Spinner /> : vulnsInfo}
      </TableContainer>
    </TabContainer>
  );
};

gql`
  query fetchVulnHistory(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $sortParams: sortParams
    $filterParams: filterParams
  ) {
    organization {
      id
      vulnerabilitySlas {
        vulnHighSeverity
        vulnLowSeverity
        vulnMediumSeverity
      }
      resources(
        genericResourceType: Vulnerability
        after: $after
        before: $before
        first: $first
        last: $last
        sortParams: $sortParams
        filterParams: $filterParams
        options: { includeDeleted: true }
      ) {
        totalCount
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            id
            createdAt
            deletedAt
            ... on SpecificOsqueryVulnerabilityResource {
              resolvedWithinSLA
              severity
              slaDeadline
              vulnData {
                id
                packageName
                packageSource
                osquery {
                  id
                  prettyName
                }
              }
            }
            ... on SpecificAwsContainerVulnerabilityResource {
              resolvedWithinSLA
              severity
              slaDeadline
              packageIdentifier
              repositoryName
            }
            ... on SpecificAwsInspectorVulnerabilityResource {
              resolvedWithinSLA
              severity
              slaDeadline
              packageName
              instanceId
            }
            ... on SpecificAzureContainerVulnerabilityResource {
              displayName
              resolvedWithinSLA
              severity
              slaDeadline
              packageIdentifier
              repositoryName
            }
            ... on SpecificGCPContainerVulnerabilityResource {
              repositoryName
              resolvedWithinSLA
              slaDeadline
              packageIdentifier
              severity
            }
            ... on SpecificSnykVulnerabilityResource {
              snykPackageName: packageName
              projectName
              resolvedWithinSLA
              severity
              slaDeadline
              title
            }
          }
        }
      }
    }
  }
`;

/**
 * Provide a date range that includes the whole day(s)
 */
function getWholeDayDateRange([start, end]: DateRange): DateRange {
  const newStart = start ? new Date(start.getTime()) : null;
  const newEnd = end ? new Date(end.getTime()) : null;
  if (newStart) newStart.setHours(0, 0, 0, 0);
  if (newEnd) newEnd.setHours(23, 59, 59, 999);
  return [newStart, newEnd];
}
