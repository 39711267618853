import type { MenuItemProps as IBPMenuItemProps } from "@blueprintjs/core";
import { Menu as BPMenu, MenuItem as BPMenuItem } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { applyIfSome } from "common/base/types/maybe";
import React from "react";
import styled from "styled-components";

import { BASE_PALETTE } from "../../base/colors";
import { GRID_SPACING } from "../../base/grid";
import { Icon } from "../icon/icon";
import type { IconName } from "../icon/iconNames";
import { SpacedList } from "../primitives/spaced-list";

export const Menu2 = styled(BPMenu)`
  &.bp3-menu,
  & * .bp3-menu {
    display: flex;
    flex-direction: column;
    padding: 0px;
    min-width: none;

    background: ${BASE_PALETTE.SNOW};
    border: 1px solid ${BASE_PALETTE.WIND};
    box-sizing: border-box;
    box-shadow: 0px 2px 6px rgba(51, 51, 51, 0.12);
    border-radius: 4px;

    // override Blueprint's styling on sub-menus to match top-level menu styling
    .bp3-submenu {
      .bp3-popover > .bp3-popover-content {
        box-shadow: none;
      }

      .bp3-popover-target.bp3-popover-open > .bp3-menu-item {
        background-color: ${BASE_PALETTE.VAPE};
      }
    }
  }
`;

interface IMenuItemProps extends Omit<IBPMenuItemProps, "icon"> {
  icon?: Maybe<IconName>;
  rightIcon?: Maybe<IconName>;
}

export const MenuItem2: React.FC<IMenuItemProps> = ({
  children,
  icon,
  rightIcon,
  text,
  ...props
}) => {
  const content = (
    <StyledFlexRow>
      <SpacedList marginOverride={GRID_SPACING}>
        {applyIfSome(icon, iconName => (
          <Icon icon={iconName} iconSize={12} color={BASE_PALETTE.CHARCOAL} />
        ))}
        <span>{text}</span>
      </SpacedList>
      {applyIfSome(rightIcon, iconName => (
        <Icon
          style={{ marginLeft: `${2 * GRID_SPACING}px` }}
          icon={iconName}
          iconSize={12}
          color={BASE_PALETTE.CHARCOAL}
        />
      ))}
    </StyledFlexRow>
  );

  return (
    <StyledBPMenuItem {...props} text={content}>
      {children}
    </StyledBPMenuItem>
  );
};

const StyledBPMenuItem = styled(BPMenuItem)<IBPMenuItemProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;

  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: ${BASE_PALETTE.INK};

  &:hover {
    background-color: ${BASE_PALETTE.VAPE};
  }

  ${({ disabled }) => (Boolean(disabled) ? "opacity: 0.5;" : "")}
`;

const StyledFlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
