import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";

import { USER_NAMING_CONSTANTS } from "./user-constants";

interface ISortableUser {
  displayName?: Maybe<string>;
  familyName?: Maybe<string>;
  givenName?: Maybe<string>;
}

interface ISortableServiceAccountOwner {
  owner?: Maybe<ISortableUser>;
  userId?: Maybe<string>;
}

export const compareStringForSortableUser = (user: ISortableUser) => {
  const familyName = user.familyName ?? "";
  if (familyName !== "") {
    return `${familyName} ${user.givenName ?? ""}`;
  } else {
    return user.displayName ?? "user";
  }
};

export const compareStringForServiceAccountOwner = ({
  owner,
  userId,
}: ISortableServiceAccountOwner) => {
  if (isSome(owner)) {
    return compareStringForSortableUser(owner);
  }
  if (userId === "machine") {
    return USER_NAMING_CONSTANTS.NOT_A_PERSON;
  }
  if (userId === "external") {
    return USER_NAMING_CONSTANTS.EXT_USER;
  }
  // only returns null when there is no owner
  return null;
};

export const lastNameSort = (user1: ISortableUser, user2: ISortableUser) =>
  compareStringForSortableUser(user1).localeCompare(
    compareStringForSortableUser(user2)
  );

/**
 * Compare function that orders null after any non-null strings
 */
export const compareNullableStrings = (s1: Maybe<string>, s2: Maybe<string>) =>
  !isSome(s1) ? 1 : !isSome(s2) ? -1 : s1.localeCompare(s2);

/**
 * Compare function that orders null before any non-null dates
 */
export const compareNullableDates = (
  d1: Maybe<Date | string>,
  d2: Maybe<Date | string>
) => new Date(d1 ?? 0).getTime() - new Date(d2 ?? 0).getTime();
