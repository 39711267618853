import { TestOutcome } from "common/base/types/gen";
import type { Maybe } from "common/base/types/maybe";
import { isSome, nothing } from "common/base/types/maybe";
import {
  replaceHexEscapedSequences,
  replaceUTF8MultiByteHexEscapes,
} from "common/base/utils";
import {
  ListCoordinator,
  toCommaSeparatedList,
} from "common/grammar/sequences";
import React from "react";

import type { EvaluationEnum } from "../../helpers/EvaluationIcon";
import {
  booleanToEvaluationEnum,
  EvaluationIcon,
} from "../../helpers/EvaluationIcon";
import type { IUserWorkstation } from "./utils";
import { ManagementMethod } from "./utils";

interface IProps {
  workstation: IUserWorkstation;
}

const OsqueryEvaluationIcon: React.FC<{ state: EvaluationEnum }> = ({
  state,
}) => {
  if (state === TestOutcome.IN_PROGRESS) {
    return (
      <EvaluationIcon
        evaluation={state}
        maybeTooltipText={"Waiting for data"}
      ></EvaluationIcon>
    );
  }
  return <EvaluationIcon evaluation={state}></EvaluationIcon>;
};

export const PasswordManagerEvaluationIcon: React.FC<IProps> = ({
  workstation,
}) => {
  if (
    workstation.__typename === "linuxServerData" ||
    workstation.__typename === "linuxWorkstationData"
  ) {
    return <EvaluationIcon evaluation={TestOutcome.NA} />;
  } else {
    const evaluation = booleanToEvaluationEnum(
      workstation.passwordManagers.length > 0
    );

    if (evaluation === TestOutcome.PASS) {
      return (
        <EvaluationIcon
          evaluation={evaluation}
          maybeTooltipText={`This user has ${replaceHexEscapedSequences(
            replaceUTF8MultiByteHexEscapes(
              toCommaSeparatedList(
                workstation.passwordManagers,
                ListCoordinator.And
              )
            )
          )} installed`}
        />
      );
    } else if (
      !isSome(workstation.numApplications) ||
      (workstation.managedVia === ManagementMethod.VantaAgent &&
        !isSome(workstation.numBrowserExtensions))
    ) {
      return (
        <EvaluationIcon
          evaluation={TestOutcome.IN_PROGRESS}
          maybeTooltipText={"Waiting for data"}
        />
      );
    } else {
      return <EvaluationIcon evaluation={evaluation} />;
    }
  }
};

export const AVProgramEvaluationIcon: React.FC<IProps> = ({ workstation }) => {
  let hasAvOutcome = TestOutcome.FAIL;
  let avTooltip: Maybe<string> = nothing;
  if (
    workstation.__typename === "linuxServerData" ||
    workstation.__typename === "linuxWorkstationData"
  ) {
    hasAvOutcome = TestOutcome.NA;
  } else if (!isSome(workstation.numApplications)) {
    hasAvOutcome = TestOutcome.IN_PROGRESS;
    avTooltip = "Waiting for data";
  } else {
    hasAvOutcome = booleanToEvaluationEnum(
      workstation.antivirusPrograms.length + workstation.avPolicies.length > 0
    );
    if (hasAvOutcome === TestOutcome.PASS) {
      avTooltip = `This user has ${replaceHexEscapedSequences(
        replaceUTF8MultiByteHexEscapes(
          toCommaSeparatedList(
            [
              ...workstation.antivirusPrograms,
              ...workstation.avPolicies.map(p => p.name),
            ],
            ListCoordinator.And
          )
        )
      )}`;
    }
  }

  return (
    <EvaluationIcon evaluation={hasAvOutcome} maybeTooltipText={avTooltip} />
  );
};

export const EncryptedEvaluationIcon: React.FC<IProps> = ({ workstation }) => (
  <OsqueryEvaluationIcon
    state={booleanToEvaluationEnum(workstation.isEncrypted)}
  />
);
