import {
  Button,
  Classes,
  FormGroup,
  InputGroup,
  Intent,
  Spinner,
  TextArea,
} from "@blueprintjs/core";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import React, { useState } from "react";

import { LogError } from "../../../errors";
import type { GetVendorsQuery } from "../../../gen/components";
import {
  GetVendorsDocument,
  useRequestVaqMutation,
} from "../../../gen/components";
import { AppToaster } from "../../../helpers/toaster";

interface IProps {
  vendor: NonNullable<GetVendorsQuery["organization"]>["vendors"][number];
  domainDisplayName: string;
  onClose(): void;
}

gql`
  mutation requestVAQ(
    $vendorId: String!
    $subjectLine: String!
    $message: String!
    $dryRun: Boolean
  ) {
    requestVAQSubmission(
      vendorId: $vendorId
      subjectLine: $subjectLine
      message: $message
      dryRun: $dryRun
    )
  }
`;

export const RequestVAQDialogForm: React.FC<IProps> = props => {
  const subjectPlaceholder = `Vendor security questionnaire for ${props.vendor.name}`;
  const messagePlaceholder = `${props.domainDisplayName} is committed to keeping our customers' data safe and secure, and we want to make sure that our partners and vendors do, too. Please complete our short security questionnaire to help us learn a little more about your business practices.\n\nThanks for your help!`;

  const [subjectLine, setSubjectLine] = useState(subjectPlaceholder);
  const [message, setMessage] = useState(messagePlaceholder);
  const [requestVaq, { loading }] = useRequestVaqMutation({
    refetchQueries: [{ query: GetVendorsDocument }],
  });

  const { vendor } = props;
  return (
    <React.Fragment>
      <div className={Classes.DIALOG_BODY}>
        <p>
          Send questionnaire to {vendor.contactName} ({vendor.contactEmail}) or,
          if you would like to review the questionnaire before sending it to
          this vendor, click send test to receive a preview.
        </p>
        <FormGroup label="Subject" className="vaq-request-form-group">
          <InputGroup
            className="vaq-request-form-subject-line"
            placeholder={subjectPlaceholder}
            value={subjectLine}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setSubjectLine(e.target.value)
            }
          />
        </FormGroup>
        <FormGroup label="Message" className="vaq-request-form-group">
          <TextArea
            className="vaq-request-form-editable-text"
            style={{ minHeight: "150px" }}
            placeholder={messagePlaceholder}
            value={message}
            onChange={e => setMessage(e.currentTarget.value)}
            fill={true}
          />
        </FormGroup>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>{renderBody()}</div>
      </div>
    </React.Fragment>
  );

  function renderBody() {
    if (loading) {
      return <Spinner />;
    }
    const clickHander = (dryRun: boolean) => {
      const trimmedSubject = subjectLine.trim();
      const trimmedMessage = message.trim();
      const subjectLineParam =
        trimmedSubject === "" ? subjectPlaceholder : trimmedSubject;
      const messageParam =
        trimmedMessage === "" ? messagePlaceholder : trimmedMessage;

      requestVaq({
        variables: {
          vendorId: vendor.id,
          subjectLine: subjectLineParam,
          message: messageParam,
          dryRun,
        },
      })
        .then(result => {
          if (isSome(result.data) && result.data.requestVAQSubmission) {
            const toastMessage = dryRun
              ? `We've sent you an email with a sample questionnare.`
              : `A questionnare has been sent to ${props.vendor.name}.`;
            AppToaster.show({
              icon: "tick",
              intent: Intent.SUCCESS,
              message: toastMessage,
              timeout: 2500,
            });
            props.onClose();
          }
        })
        .catch(LogError);
    };
    return (
      <React.Fragment>
        <Button intent={Intent.PRIMARY} onClick={() => clickHander(true)}>
          Send test
        </Button>
        <Button intent={Intent.PRIMARY} onClick={() => clickHander(false)}>
          Send email
        </Button>
      </React.Fragment>
    );
  }
};
