import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import styled, { css } from "styled-components";
import { BASE_PALETTE } from "../../base/colors";
import { BASE_TYPOGRAPHY } from "../../base/typography";

import { TableColumnAlignment, TABLE_ALIGNMENT_CLASSES } from "./constants";

/**
 * This table uses CSS grid layout -- you must specify a suitable grid-template-columns value
 * for the date displayed by the table.
 */
export const StyledVantaTable = styled.table<{
  tall?: Maybe<boolean>;
  columnWidths: Array<{ minWidthPx: number; columnGridFr: number }>;
}>`
  min-width: 100%;
  border-collapse: collapse;

  display: grid;
  grid-template-columns: ${({ columnWidths }) =>
    columnWidths
      .map(c => `minmax(${c.minWidthPx}px,${c.columnGridFr}fr)`)
      .join(" ")};

  thead,
  tbody,
  tr {
    display: contents;
  }

  td,
  th {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    // Center header if the row is centered
    &.${TABLE_ALIGNMENT_CLASSES[TableColumnAlignment.CENTER]} {
      justify-content: center;
    }

    padding: 8px 16px;
    &:first-child {
      padding-left: 24px;
    }
    &:last-child {
      padding-right: 24px;
    }
  }

  td {
    &.${TABLE_ALIGNMENT_CLASSES[TableColumnAlignment.LEFT]} {
      justify-content: flex-start;
    }
    &.${TABLE_ALIGNMENT_CLASSES[TableColumnAlignment.RIGHT]} {
      justify-content: flex-end;
    }

    height: 64px;
    border-top: 1px solid ${BASE_PALETTE.WIND};
    overflow: hidden;
    ${({ tall }) =>
      Boolean(tall)
        ? css`
            height: 96px;
            align-items: flex-start;
            padding-top: 16px;
          `
        : ""}
  }
  th {
    text-align: left;
    height: 56px;
    color: ${BASE_PALETTE.CHARCOAL};
    font-size: 12px;
    font-weight: ${BASE_TYPOGRAPHY.FONT_WEIGHTS.SLIGHTLY_BOLD};
    text-transform: uppercase;
    border-top: 1px solid ${BASE_PALETTE.WIND};
  }
`;

export const StyledVantaTableTh = styled.th`
  ${props =>
    isSome(props.onClick)
      ? css`
          cursor: pointer;
          &:hover {
            background-color: ${BASE_PALETTE.VAPE};
          }
        `
      : ""}
`;

export const StyledVantaTableTr = styled.tr<{ disabled?: Maybe<boolean> }>`
  ${props =>
    !Boolean(props.disabled) && isSome(props.onClick)
      ? css`
          cursor: pointer;
          &:hover {
            td {
              background-color: ${BASE_PALETTE.VAPE};
            }
          }
        `
      : ""}
  ${props =>
    Boolean(props.disabled)
      ? css`
          cursor: not-allowed;
          td {
            background-color: ${BASE_PALETTE.VAPE};
            & * {
              pointer-events: none;
              opacity: 0.5;
            }
          }
        `
      : ""}
`;
