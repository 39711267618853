import { AnchorButton, ButtonGroup, Checkbox, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import { AuditTypeToHumanName } from "common/constants/displayNames";
import gql from "graphql-tag";
import moment from "moment";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { ContentCard, Tooltip } from "../../../alpaca/components";
import { LogError } from "../../../errors";
import type { GetAuditInfoQuery } from "../../../gen/components";
import {
  Feature,
  GetAuditInfoDocument,
  useDeleteAuditMutation,
  useRemoveAuditorMutation,
} from "../../../gen/components";
import { UI_DATE_FORMAT_WITHOUT_TIME } from "../../../helpers/common";
import { useFeatureCheck } from "../../../helpers/feature-gating/feature-check";
import { AppToaster } from "../../../helpers/toaster";
import { CancelConfirmDialog } from "../../helpers/CancelConfirmDialog";
import { DataTable } from "../components/data-table";
import { AddAuditorForm } from "./add-auditor-form";
import { AuditorAccessToggle } from "./auditor-access-toggle";
import { ChangeAuditDateDialog } from "./change-audit-date-dialog";

type Audit = NonNullable<GetAuditInfoQuery["organization"]>["audits"][number];

interface IProps {
  audits: Audit[];
  auditorsWithAccess: Set<string>;
}

export const AuditList: React.FC<IProps> = ({ audits, auditorsWithAccess }) => {
  const [removeAccessChecked, setRemoveAccessChecked] = useState(true);
  const [auditToDelete, setAuditToDelete] = useState<Maybe<Audit>>(null);
  const [auditToModify, setAuditToModify] = useState<Maybe<Audit>>(null);
  const [deleteInFlight, setDeleteInFlight] = useState(false);
  const [removeAuditInfo, setRemoveAuditInfo] =
    useState<Maybe<{ auditId: string; auditorId: string }>>(null);
  const [removeAuditor] = useRemoveAuditorMutation({
    refetchQueries: [{ query: GetAuditInfoDocument }],
    onCompleted: () => {
      setDeleteInFlight(false);
      setRemoveAccessChecked(true);
      setRemoveAuditInfo(null);
      AppToaster.show({
        message: "Auditor removed from audit",
        intent: Intent.SUCCESS,
      });
    },
  });
  const [deleteAudit] = useDeleteAuditMutation({
    refetchQueries: [{ query: GetAuditInfoDocument }],
    onCompleted() {
      setDeleteInFlight(false);
      setRemoveAccessChecked(true);
      setAuditToDelete(null);
      AppToaster.show({
        message: "Audit period deleted",
        intent: Intent.SUCCESS,
      });
    },
  });

  // GA will involve giving all domains the feature
  const auditJourneyAuditorEnabled = useFeatureCheck(
    Feature.AuditJourneyAuditor
  );

  if (audits.length === 0) {
    return null;
  }

  const auditTable = (
    <DataTable
      useDefaultStyling
      data={audits}
      columnOrder={
        auditJourneyAuditorEnabled
          ? ["auditType", "startDate", "endDate", "auditFirm"]
          : ["auditType", "startDate", "endDate", "auditFirm", "auditors"]
      }
      header={{
        auditType: "Audit Type",
        startDate: "Start Date",
        endDate: "End Date",
        auditFirm: "Audit Firm",
        auditors: "Auditors",
      }}
      createRow={(audit, index) => {
        return {
          auditType: <div>{AuditTypeToHumanName[audit.auditType]}</div>,
          startDate: moment(+audit.auditStart).format(
            UI_DATE_FORMAT_WITHOUT_TIME
          ),
          endDate: moment(+audit.auditStart)
            .add(audit.auditPeriodDays, "days")
            .format(UI_DATE_FORMAT_WITHOUT_TIME),
          auditFirm: audit.auditFirmInfo?.firmName,
          auditors: (
            <UL>
              {audit.auditors.map((auditor, idx) => {
                const hasAccess = auditorsWithAccess.has(auditor.id);
                const canDelete = audit.auditors.length > 1;

                return (
                  <AuditorRow key={auditor.id ?? `${idx}`}>
                    <span>
                      {auditor.displayName} ({auditor.email})
                    </span>
                    <ButtonGroup>
                      <AuditorAccessToggle
                        auditor={auditor}
                        hasAccess={hasAccess}
                      />
                      <Tooltip
                        content={
                          canDelete
                            ? "Remove from audit"
                            : "An audit must have at least one auditor"
                        }
                        hoverOpenDelay={300}
                      >
                        <AnchorButton
                          intent={canDelete ? Intent.DANGER : Intent.NONE}
                          icon={IconNames.REMOVE}
                          minimal
                          disabled={!canDelete}
                          onClick={(
                            e: React.MouseEvent<HTMLElement, MouseEvent>
                          ) => {
                            e.preventDefault();
                            setRemoveAuditInfo({
                              auditId: audit.id,
                              auditorId: auditor.id,
                            });
                          }}
                        />
                      </Tooltip>
                    </ButtonGroup>
                  </AuditorRow>
                );
              })}
              <AddAuditorForm auditId={audit.id} />
            </UL>
          ),
        };
      }}
      menuOptions={
        auditJourneyAuditorEnabled
          ? undefined
          : () => [
              {
                flag: "changeDate",
                iconName: IconNames.CALENDAR,
                text: "Change Date",
              },
              {
                flag: "remove",
                iconName: IconNames.TRASH,
                text: "Delete",
              },
            ]
      }
      onMenuItemClick={(flag, auditInfo) => {
        if (flag === "changeDate") {
          setAuditToModify(auditInfo);
        } else if (flag === "remove") {
          setAuditToDelete(auditInfo);
        }
      }}
    />
  );

  const dialogs = (
    <>
      {isSome(removeAuditInfo) ? (
        <CancelConfirmDialog
          body={
            <div>
              Are you sure you want to remove this auditor from this audit?
              <Checkbox
                checked={removeAccessChecked}
                onClick={e => setRemoveAccessChecked(e.currentTarget.checked)}
                labelElement={
                  <span>
                    Revoke this auditor's access to my Vanta account
                    (alternatively, you can modify auditor access from the{" "}
                    <Link to="/users">Users page</Link> in the Company Settings
                    menu)
                  </span>
                }
              />
            </div>
          }
          confirmText="Confirm"
          title="Remove auditor"
          isOpen={true}
          loading={deleteInFlight}
          onClose={() => {
            setRemoveAccessChecked(true);
            setRemoveAuditInfo(null);
          }}
          onConfirm={() => {
            setDeleteInFlight(true);
            removeAuditor({
              variables: {
                ...removeAuditInfo!,
                removeAccess: removeAccessChecked,
              },
            }).catch(LogError);
            setRemoveAccessChecked(true);
            setRemoveAuditInfo(null);
          }}
        />
      ) : null}
      {isSome(auditToDelete) ? (
        <CancelConfirmDialog
          body={
            <div>
              Are you sure you want to delete this audit?
              <Checkbox
                checked={removeAccessChecked}
                onClick={e => setRemoveAccessChecked(e.currentTarget.checked)}
                labelElement={
                  <span>
                    Revoke access to my Vanta account for all auditors
                    associated with this audit (alternatively, you can modify
                    auditor access from the <Link to="/users">Users page</Link>{" "}
                    in the Company Settings menu)
                  </span>
                }
              />
            </div>
          }
          confirmText="Confirm"
          title="Delete audit"
          isOpen={true}
          loading={deleteInFlight}
          onClose={() => {
            setRemoveAccessChecked(true);
            setAuditToDelete(null);
          }}
          onConfirm={() => {
            setDeleteInFlight(true);
            deleteAudit({
              variables: {
                auditId: auditToDelete!.id,
                removeAccess: removeAccessChecked,
              },
            }).catch(LogError);
          }}
        />
      ) : null}
      {isSome(auditToModify) ? (
        <ChangeAuditDateDialog
          auditId={auditToModify.id}
          auditLength={auditToModify.auditPeriodDays}
          auditStartDate={new Date(+auditToModify.auditStart)}
          onClose={() => setAuditToModify(null)}
        />
      ) : null}
    </>
  );

  return (
    <Container>
      <ContentCard>{auditTable}</ContentCard>
      {dialogs}
    </Container>
  );
};

const styles = {
  AUDITOR_ROW_WIDTH: 240,
};

const Container = styled.div`
  table {
    width: 100%;
    td {
      vertical-align: center;
    }
  }
`;

const AuditorRow = styled.li`
  display: flex;
  width: ${styles.AUDITOR_ROW_WIDTH}px;
  justify-content: space-between;
  align-items: center;
`;

const UL = styled.ul`
  list-style: none;
  padding: 0;
`;

gql`
  mutation removeAuditor(
    $auditId: String!
    $auditorId: String!
    $removeAccess: Boolean
  ) {
    removeAuditorFromAudit(
      auditId: $auditId
      auditorId: $auditorId
      removeAccess: $removeAccess
    ) {
      id
    }
  }
`;
