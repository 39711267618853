import { FormGroup, InputGroup } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { getTransformedOrElse, isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import React, { useState } from "react";

import type { IconName } from "../../../../alpaca/components";
import { IconNames } from "../../../../alpaca/components";
import { LogError } from "../../../../errors";
import type { CompletionFieldsFragment } from "../../../../gen/components";
import {
  GetUserInfoForOnboardingListDocument,
  useCompleteRoleTaskMutation,
} from "../../../../gen/components";
import { CancelConfirmDialog } from "../../../helpers/CancelConfirmDialog";
import { FileInput } from "../../components/file-input";
import { IconButton } from "../shared/icon-button";

interface IProps {
  taskCompletion: CompletionFieldsFragment;
  userId: string;
  roleCompletionId: string;
  renderButton?: Maybe<
    (onClick: () => void, icon: IconName, tooltipContent: string) => JSX.Element
  >;
}

export const CompleteTaskControl: React.FC<IProps> = ({
  taskCompletion,
  userId,
  roleCompletionId,
  renderButton,
}) => {
  const { task } = taskCompletion;
  const [maybeFile, setMaybeFile] = useState<Maybe<File>>(null);
  const [maybeTextInfo, setMaybeTextInfo] = useState("");
  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  const [completeTask] = useCompleteRoleTaskMutation({
    refetchQueries: [
      { query: GetUserInfoForOnboardingListDocument, variables: { userId } },
    ],
  });

  const canSubmit =
    (!isSome(task.fileInputLabel) || isSome(maybeFile)) &&
    (!isSome(task.textInputLabel) || maybeTextInfo.trim() !== "");

  const maybeTextInput = isSome(task.textInputLabel) ? (
    <FormGroup label={task.textInputLabel}>
      <InputGroup
        value={maybeTextInfo}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setMaybeTextInfo(e.target.value)
        }
      />
    </FormGroup>
  ) : null;

  const maybeFileInput = isSome(task.fileInputLabel) ? (
    <FormGroup label={task.fileInputLabel}>
      <FileInput
        name={task.id}
        id={task.id}
        selectedFile={maybeFile}
        fileUploaded={(name, file) => {
          setMaybeFile(file);
        }}
      />
    </FormGroup>
  ) : null;

  const dialog = (
    <CancelConfirmDialog
      isOpen={dialogIsOpen}
      onConfirm={() => {
        handleCompletion();
        setMaybeFile(null);
        setMaybeTextInfo("");
        setDialogIsOpen(false);
      }}
      confirmDisabled={!canSubmit}
      title={task.title}
      confirmText="Mark completed"
      body={
        <div>
          <p>{task.instructions}</p>
          {maybeTextInput}
          {maybeFileInput}
        </div>
      }
      onClose={() => {
        setMaybeFile(null);
        setMaybeTextInfo("");
        setDialogIsOpen(false);
      }}
    />
  );

  if (isSome(taskCompletion.completionDate)) {
    if (isSome(taskCompletion.file)) {
      const onClick = () => window.open(taskCompletion.file!.url, "_blank");
      return getTransformedOrElse(
        renderButton,
        render => render(onClick, IconNames.VIEW, "View"),
        <IconButton
          onClick={onClick}
          icon={IconNames.VIEW}
          tooltipContent={"View"}
        />
      );
    } else {
      return null;
    }
  } else {
    const onClick = () => setDialogIsOpen(true);
    const buttonIcon = isSome(task.fileInputLabel)
      ? IconNames.UPLOAD
      : IconNames.SUCCESS;
    const button = getTransformedOrElse(
      renderButton,
      render => render(onClick, buttonIcon, "Complete"),
      <IconButton
        onClick={onClick}
        icon={buttonIcon}
        tooltipContent={"Complete"}
      />
    );
    return (
      <>
        {button}
        {dialog}
      </>
    );
  }

  function handleCompletion() {
    const requiredInfo = maybeTextInfo.trim();
    completeTask({
      variables: {
        file: maybeFile ?? undefined,
        fileTitle: maybeFile?.name ?? undefined,
        requiredInfo: isSome(task.textInputLabel) ? requiredInfo : undefined,
        userId,
        roleCompletionRecordId: roleCompletionId,
        taskCompletionRecordId: taskCompletion.id,
      },
    }).catch(LogError);
  }
};

gql`
  mutation completeRoleTask(
    $file: Upload
    $fileTitle: String
    $userId: ID!
    $taskCompletionRecordId: String!
    $roleCompletionRecordId: String!
    $requiredInfo: String
  ) {
    completeRoleTask(
      file: $file
      fileTitle: $fileTitle
      userId: $userId
      taskCompletionRecordId: $taskCompletionRecordId
      roleCompletionRecordId: $roleCompletionRecordId
      requiredInfo: $requiredInfo
    ) {
      id
    }
  }
`;
