import { dateStringToDate } from "common/base/dateUtils";
import { isSome } from "common/base/types/maybe";
import { TestEntityTypeDisplayNames } from "common/constants/displayNames";
import gql from "graphql-tag";
import React from "react";

import { LogError, LogErrorMessage } from "../../../errors";
import type {
  FetchServiceAccountsForAccountsRemovedQuery,
  SpecificResource,
} from "../../../gen/components";
import { useFetchServiceAccountsForAccountsRemovedQuery } from "../../../gen/components";
import { asDateOnlyDate } from "../../pages/people/utils";
import type { ISLAMissRow } from "../fail-data-from-sla-miss";
import { FailDataFromSLAMiss } from "../fail-data-from-sla-miss";

interface IInternalProps {
  serviceAccounts: FetchServiceAccountsForAccountsRemovedQuery["serviceAccountsFromAccountIds"];
  testId: string;
  entityType: SpecificResource;
  allowWhitelisting: boolean;
  renderingIndividually: boolean;
  first: number;
}

class FailDataFromAccessRemovedInternal extends React.Component<IInternalProps> {
  public constructor(props: IInternalProps) {
    super(props);
    this.state = {};

    this.renderAccount = this.renderAccount.bind(this);
  }

  public render() {
    if (!isSome(this.props.serviceAccounts)) {
      return <div />;
    }

    const activeAccounts: FetchServiceAccountsForAccountsRemovedQuery["serviceAccountsFromAccountIds"] =
      [];
    const deactivatedAccounts: FetchServiceAccountsForAccountsRemovedQuery["serviceAccountsFromAccountIds"] =
      [];

    this.props.serviceAccounts.forEach(account => {
      if (isSome(account.deactivationDate)) {
        deactivatedAccounts.push(account);
      } else {
        activeAccounts.push(account);
      }
    });

    const openItems: ISLAMissRow[] = activeAccounts.map((account, i) => {
      return {
        element: this.renderAccount(account),
        identifierDisplayText: `${this.getService(account)}: ${
          account.accountName
        }`,
        entity: { entityId: account.id, entityType: this.props.entityType },
        openDate: asDateOnlyDate(
          dateStringToDate(account.owner!.endDate!),
          isSome(account.owner?.hrUser?.endDate)
        ),
      };
    });

    const closedItems: ISLAMissRow[] = deactivatedAccounts.map((account, i) => {
      return {
        element: this.renderAccount(account),
        identifierDisplayText: `${this.getService(account)}: ${
          account.accountName
        }`,
        entity: { entityId: account.id, entityType: this.props.entityType },
        openDate: asDateOnlyDate(
          dateStringToDate(account.owner!.endDate!),
          isSome(account.owner?.hrUser?.endDate)
        ),
        closeDate: new Date(account.deactivationDate!),
      };
    });

    return (
      <div>
        <FailDataFromSLAMiss
          header={
            this.props.renderingIndividually
              ? undefined
              : TestEntityTypeDisplayNames[this.props.entityType]
          }
          testId={this.props.testId}
          openItems={openItems}
          closedItems={closedItems}
          type="access-removed"
          allowWhitelisting={this.props.allowWhitelisting}
          first={this.props.first}
        />
      </div>
    );
  }

  private readonly getService = (
    account: FetchServiceAccountsForAccountsRemovedQuery["serviceAccountsFromAccountIds"][number]
  ) => {
    if (account.service === "aws") {
      return "AWS";
    } else {
      return account.service.charAt(0).toUpperCase() + account.service.slice(1);
    }
  };

  private renderAccount(
    account: FetchServiceAccountsForAccountsRemovedQuery["serviceAccountsFromAccountIds"][number]
  ) {
    const ownedBy = ` (linked to ${account.owner!.displayName})`;
    const service = this.getService(account);
    return (
      <div>
        <strong>{service}</strong>
        {`: ${account.accountName}${ownedBy}`}
      </div>
    );
  }
}

gql`
  query fetchServiceAccountsForAccountsRemoved($accountIds: [String!]!) {
    serviceAccountsFromAccountIds(accountIds: $accountIds) {
      id
      service
      accountName
      createdAt
      deactivationDate
      owner {
        id
        displayName
        email
        endDate
        hrUser {
          endDate
        }
      }
    }
  }
`;

export const AccountSLATestEntities: React.FC<{
  entityType: SpecificResource;
  entityIds: string[];
  testId: string;
  allowWhitelisting: boolean;
  renderingIndividually: boolean;
  first: number;
}> = ({
  entityType,
  entityIds,
  testId,
  allowWhitelisting,
  renderingIndividually,
  first,
}) => {
  const { error, loading, data } =
    useFetchServiceAccountsForAccountsRemovedQuery({
      variables: { accountIds: entityIds },
    });
  if (error) {
    LogError(error);
    return <div />;
  }
  if (loading) {
    return <div />;
  }
  if (!data) {
    LogErrorMessage("Bad fetch");
    return <div />;
  }
  return (
    <FailDataFromAccessRemovedInternal
      testId={testId}
      serviceAccounts={data.serviceAccountsFromAccountIds}
      entityType={entityType}
      allowWhitelisting={allowWhitelisting}
      renderingIndividually={renderingIndividually}
      first={first}
    />
  );
};
