import { Card, HTMLSelect, Switch } from "@blueprintjs/core";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import React, { useState } from "react";
import { CSVLink } from "react-csv";

import { Button, Tag } from "../../alpaca/components";
import { LogErrorMessage } from "../../errors";
import { useGetAllTestMetadataQuery } from "../../gen/components";
import { FullPageSpinner } from "../helpers/FullPageSpinner";
import { MarkdownRenderer } from "../pages/components/markdown-renderer";

gql`
  query GetAllTestMetadata {
    internal {
      allAutomatedTestMetadata {
        testId
        failureDescription
        defaultRemediation
        severity
        name
        services
        models
        detailedDescription
      }
    }
  }
`;

const ALL = "All";

export const TestRemediationViewer: React.FC = () => {
  const [showLowSeverity, setShowLowSeverity] = useState(true);
  const [showMediumSeverity, setShowMediumSeverity] = useState(true);
  const [showHighSeverity, setShowHighSeverity] = useState(true);
  const [selectedService, setSelectedService] = useState(ALL);
  const [selectedModel, setSelectedModel] = useState(ALL);
  const { error, loading, data } = useGetAllTestMetadataQuery({});
  if (loading) {
    return <FullPageSpinner />;
  }

  if (error || !data || !isSome(data.internal.allAutomatedTestMetadata)) {
    LogErrorMessage("Bad fetch");
    return null;
  }

  const allTests = data.internal.allAutomatedTestMetadata;
  const allCount = allTests.length;
  const allServices = Array.from(
    new Set(allTests.flatMap(test => test.services))
  ).sort();
  const allModels = Array.from(
    new Set(allTests.flatMap(test => test.models))
  ).sort();

  const lowCount = allTests.filter(t => t.severity === "low").length;
  const mediumCount = allTests.filter(t => t.severity === "medium").length;
  const highCount = allTests.filter(t => t.severity === "high").length;
  const filteredTests = allTests
    .filter(
      test =>
        (test.severity === "high" && showHighSeverity) ||
        (test.severity === "medium" && showMediumSeverity) ||
        (test.severity === "low" && showLowSeverity)
    )
    .filter(test =>
      selectedService === ALL ? true : test.services.includes(selectedService)
    )
    .filter(test =>
      selectedModel === ALL ? true : test.models.includes(selectedModel)
    );

  const testCountByService = allTests.reduce((acc, cur) => {
    cur.services.forEach(service => {
      if (isSome(acc[service])) {
        acc[service] = acc[service] + 1;
      } else {
        acc[service] = 1;
      }
    });
    return acc;
  }, {} as { [k: string]: number });

  const testCountByModel = allTests.reduce((acc, cur) => {
    cur.models.forEach(model => {
      if (isSome(acc[model])) {
        acc[model] = acc[model] + 1;
      } else {
        acc[model] = 1;
      }
    });
    return acc;
  }, {} as { [k: string]: number });

  return (
    <React.Fragment>
      <CSVLink
        // This is a little grim. CSVLink doesn't handle embedded quotes properly
        // The CSV standard says to replace " with ""
        data={filteredTests.map(test =>
          Object.fromEntries(
            Object.entries(test).map(([k, v]) => [
              k,
              typeof v === "string" ? v.replace(/"/g, `""`) : v,
            ])
          )
        )}
        filename={`VantaTests-${selectedService}-${selectedModel}.csv`}
        target="_blank"
      >
        <Button>Export current list as .csv</Button>
      </CSVLink>
      <div>Total test count: {allCount}</div>
      <div>
        Filter By Service:
        <HTMLSelect
          options={[
            { value: ALL },
            ...allServices.map(service => {
              return {
                value: service,
                label: `${service} (${testCountByService[service]})`,
              };
            }),
          ]}
          value={selectedService}
          onChange={event => setSelectedService(event.currentTarget.value)}
        />
      </div>
      <div>
        Filter By Severity:
        <Switch
          checked={showLowSeverity}
          label={`Low Severity (${lowCount})`}
          inline={true}
          onChange={() => setShowLowSeverity(!showLowSeverity)}
        />
        <Switch
          checked={showMediumSeverity}
          label={`Medium Severity (${mediumCount})`}
          inline={true}
          onChange={() => setShowMediumSeverity(!showMediumSeverity)}
        />
        <Switch
          checked={showHighSeverity}
          label={`High Severity (${highCount})`}
          inline={true}
          onChange={() => setShowHighSeverity(!showHighSeverity)}
        />
      </div>
      <div>
        Filter By Model Dependency:
        <HTMLSelect
          options={[
            { value: ALL },
            ...allModels.map(model => {
              return {
                value: model,
                label: `${model} (${testCountByModel[model]})`,
              };
            }),
          ]}
          value={selectedModel}
          onChange={event => setSelectedModel(event.currentTarget.value)}
        />
      </div>
      {filteredTests.map((metadata, index) => (
        <div key={index}>
          <Card elevation={1}>
            <h3>Name: {metadata.name}</h3>
            <h3>TestId: {metadata.testId}</h3>

            <div className="test-result-remediation test-result-fail">
              <h4>Detailed Description</h4>
              <p>{metadata.detailedDescription}</p>
              <h4>What went wrong</h4>
              <p>{metadata.failureDescription}</p>
              <h4>How to fix</h4>
              <div style={{ marginTop: 6 }}>
                {MarkdownRenderer(metadata.defaultRemediation)}
              </div>
            </div>
            <h4>Severity: {metadata.severity}</h4>
            {metadata.services.map(service => (
              <Tag key={`${index}-${service}`} text={service} />
            ))}
          </Card>
        </div>
      ))}
    </React.Fragment>
  );
};
