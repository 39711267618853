import { AnchorButton, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import React from "react";
import styled from "styled-components";

import { VANTA_COLORS } from "../../alpaca/base/colors";
import { VantaButtonDeprecated } from "../../alpaca/base/deprecated";
import { Tooltip } from "../../alpaca/components";

interface IProps {
  nextIsDisabled?: Maybe<boolean>;
  disabledTooltipText?: Maybe<string>;
  onClickNext: () => void;
  onClickSaveAndClose?: Maybe<() => void>;
}

export const WizardFooter: React.FC<IProps> = ({
  nextIsDisabled,
  disabledTooltipText,
  onClickNext,
  onClickSaveAndClose,
}) => (
  <FlexContainer>
    {isSome(onClickSaveAndClose) ? (
      <VantaButtonDeprecated onClick={onClickSaveAndClose}>
        Save & Close
      </VantaButtonDeprecated>
    ) : (
      <div />
    )}
    <Tooltip
      content={
        isSome(disabledTooltipText) ? (
          <TooltipContent>{disabledTooltipText}</TooltipContent>
        ) : (
          ""
        )
      }
      disabled={!Boolean(nextIsDisabled) || !isSome(disabledTooltipText)}
    >
      <StyledButton
        disabled={Boolean(nextIsDisabled)}
        intent={Intent.PRIMARY}
        onClick={onClickNext}
        rightIcon={IconNames.ARROW_RIGHT}
      >
        Next
      </StyledButton>
    </Tooltip>
  </FlexContainer>
);

const styles = {
  PADDING_TOP: 20,
  PADDING_SIDES: 20,
};

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  padding: ${styles.PADDING_TOP}px ${styles.PADDING_SIDES}px;
  justify-content: space-between;
  background: ${VANTA_COLORS.BACKGROUND_WHITE};
  z-index: 3;
  box-shadow: 0px -2px 2px "#00000004";
`;

const TooltipContent = styled.span`
  display: block;
  max-width: 200px;
`;

const StyledButton = styled(AnchorButton)`
  width: 146px;
  padding: 11px 22px;
`;
