import {
  Button,
  Checkbox,
  Classes,
  Dialog,
  FormGroup,
  HTMLSelect,
  InputGroup,
  Intent,
} from "@blueprintjs/core";
import type { DateRange } from "@blueprintjs/datetime";
import { DateRangePicker } from "@blueprintjs/datetime";
import { ReportStandard, AuditTypeEnum } from "common/base/types/gen";
import { TypedObjectKeys } from "common/base/types/helpers";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import { AuditTypeToHumanName } from "common/constants/displayNames";
import gql from "graphql-tag";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import validator from "validator";

import {
  GetAuditInfoDocument,
  useCheckForAuditorLazyQuery,
  useScheduleAuditMutation,
} from "../../../gen/components";
import { UI_DATE_FORMAT_WITHOUT_TIME } from "../../../helpers/common";
import { AppToaster } from "../../../helpers/toaster";
import { UserContext } from "../user-context";

export const MAX_AUDIT_DATE = moment().add(30, "years").toDate();
export const MIN_AUDIT_DATE = new Date("Jan 1, 2017");

interface IProps {
  onClose(): void;
}

const auditTypeToStandard: { [k in AuditTypeEnum]: ReportStandard } = {
  [AuditTypeEnum.gdpr]: ReportStandard.gdpr,
  [AuditTypeEnum.hipaa]: ReportStandard.hipaa,
  [AuditTypeEnum.iso27001]: ReportStandard.iso27001,
  [AuditTypeEnum.pciSaqA]: ReportStandard.pciSaqA,
  [AuditTypeEnum.pciSaqAEP]: ReportStandard.pciSaqAEP,
  [AuditTypeEnum.pciSaqDMerchant]: ReportStandard.pciSaqDMerchant,
  [AuditTypeEnum.pciSaqDSP]: ReportStandard.pciSaqDSP,
  [AuditTypeEnum.soc2type1]: ReportStandard.soc2,
  [AuditTypeEnum.soc2type2]: ReportStandard.soc2,
};

export const AuditSchedulerDialog: React.FC<IProps> = ({ onClose }) => {
  const [checkAuditor, queryResult] = useCheckForAuditorLazyQuery();
  const [scheduleAudit, mutationResult] = useScheduleAuditMutation({
    refetchQueries: [{ query: GetAuditInfoDocument }],
    onCompleted() {
      AppToaster.show({
        message: "Audit period has been set",
        intent: Intent.SUCCESS,
      });
      onClose();
    },
  });
  const [grantAccessChecked, setGrantAccessChecked] = useState(true);
  const [inputText, setInputText] = useState("");
  const [auditorEmail, setAuditorEmail] = useState<Maybe<string>>(null);
  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);
  const [dateRange, setDateRange] = useState<DateRange>([
    new Date(),
    moment().add(moment.duration(3, "month")).toDate(),
  ]);
  const { domainStandards } = useContext(UserContext);
  const [auditType, setAuditType] = useState<Maybe<AuditTypeEnum>>(
    TypedObjectKeys(auditTypeToStandard).find(type =>
      domainStandards.includes(auditTypeToStandard[type])
    )
  );

  useEffect(() => {
    if (queryResult.data) {
      if (!isSome(queryResult.data.auditor)) {
        setErrorMessage(
          `We were unable to find an auditor associated with this email. Please contact support@vanta.com for further assistance.`
        );
      } else {
        setErrorMessage(null);
        setAuditorEmail(inputText);
      }
    }
  }, [queryResult.data]);

  const addAuditorForm = (
    <form>
      <div className={Classes.DIALOG_BODY}>
        <h4>Auditor contact</h4>
        <FormGroup
          label="Enter the email address of your auditor"
          helperText={errorMessage ?? undefined}
          intent={isSome(errorMessage) ? Intent.DANGER : undefined}
        >
          <InputGroup
            type="email"
            value={inputText}
            placeholder="auditor@example.com"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setInputText(e.target.value)
            }
          />
        </FormGroup>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button
            disabled={!validator.isEmail(inputText)}
            type="submit"
            onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
              e.preventDefault();
              checkAuditor({
                variables: {
                  email: inputText,
                },
              });
            }}
            intent={Intent.PRIMARY}
          >
            Verify auditor email
          </Button>
        </div>
      </div>
    </form>
  );

  const dateRangeString = stringForDateRange(dateRange);
  const firmName = queryResult.data?.auditor?.firmName;
  const auditorName = queryResult.data?.auditor?.displayName;
  return (
    <WideDialog isOpen={true} onClose={onClose} title="Create new audit">
      <div className={Classes.DIALOG_BODY}>
        <div>
          <div>
            <h4>Audit type</h4>
            <HTMLSelect
              value={auditType ?? undefined}
              onChange={e =>
                setAuditType(e.currentTarget.value as AuditTypeEnum)
              }
            >
              {TypedObjectKeys(AuditTypeEnum).map(auditTypeOption => (
                <option
                  value={auditTypeOption}
                  key={auditTypeOption}
                  hidden={
                    !domainStandards.includes(
                      auditTypeToStandard[auditTypeOption]
                    )
                  }
                >
                  {AuditTypeToHumanName[auditTypeOption]}
                </option>
              ))}
            </HTMLSelect>
          </div>
          <h4>Audit observation period</h4>
          <p>{dateRangeString}</p>
          <DateRangePicker
            maxDate={MAX_AUDIT_DATE}
            minDate={MIN_AUDIT_DATE}
            shortcuts={false}
            value={dateRange}
            onChange={setDateRange}
          />
        </div>
        {isSome(firmName) ? (
          <div>
            <h4>Auditor contact</h4>
            <p>
              Your audit is with{" "}
              <strong>
                {firmName} ({auditorName})
              </strong>
            </p>
            <FormGroup
              helperText={
                grantAccessChecked
                  ? "This auditor will have read-only access to your Vanta account. This access is independent of the dates selected for the audit observation period."
                  : undefined
              }
            >
              <Checkbox
                checked={grantAccessChecked}
                onChange={e => setGrantAccessChecked(e.currentTarget.checked)}
                label="Grant this auditor immediate access to my Vanta account"
              />
            </FormGroup>
          </div>
        ) : null}
      </div>
      {isSome(firmName) ? (
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={onClose}>Cancel</Button>
            <Button
              disabled={
                !isSome(auditType) ||
                !isSome(dateRange[0]) ||
                !isSome(dateRange[1]) ||
                !isSome(firmName)
              }
              loading={mutationResult.called}
              onClick={async () => {
                const [auditStart, auditEnd] = dateRange;
                if (
                  !isSome(auditType) ||
                  !isSome(auditStart) ||
                  !isSome(auditEnd) ||
                  !isSome(auditorEmail) ||
                  !isSome(firmName)
                ) {
                  return;
                }
                await scheduleAudit({
                  variables: {
                    auditorEmail,
                    auditStart: auditStart.valueOf(),
                    auditEnd: auditEnd.valueOf(),
                    auditType,
                    grantImmediateAccess: grantAccessChecked,
                  },
                });
              }}
              intent={Intent.PRIMARY}
            >
              Save audit
            </Button>
          </div>
        </div>
      ) : (
        addAuditorForm
      )}
    </WideDialog>
  );
};

export const WideDialog = styled(Dialog)`
  width: fit-content;
  h4 {
    margin-top: 18px;
  }
`;

export const stringForDateRange = (dateRange: DateRange) =>
  `${
    dateRange[0]
      ? moment(dateRange[0]).format(UI_DATE_FORMAT_WITHOUT_TIME)
      : " "
  } - ${
    dateRange[1]
      ? moment(dateRange[1]).format(UI_DATE_FORMAT_WITHOUT_TIME)
      : " "
  }`;

gql`
  query checkForAuditor($email: String!) {
    auditor(email: $email) {
      id
      displayName
      email
      firmName
    }
  }
`;

gql`
  mutation scheduleAudit(
    $auditorEmail: String!
    $auditStart: Float!
    $auditEnd: Float!
    $auditType: auditTypeEnum!
    $grantImmediateAccess: Boolean
  ) {
    scheduleAudit(
      auditorEmail: $auditorEmail
      auditStart: $auditStart
      auditEnd: $auditEnd
      auditType: $auditType
      grantImmediateAccess: $grantImmediateAccess
    ) {
      id
    }
  }
`;
