import { Intent } from "@blueprintjs/core";
import { simplePlural } from "common/grammar/plurals";
import React from "react";

import { Banner, IconNames } from "../../../../alpaca/components";

interface IProps {
  numUnlinked: number;
  onClick(): void;
}

export const UnlinkedAccountsCallout: React.FC<IProps> = ({
  numUnlinked,
  onClick,
}) => {
  if (numUnlinked === 0) {
    return null;
  }
  return (
    <Banner
      icon={IconNames.CAUTION}
      title={`Assign ${simplePlural(numUnlinked, "active account")} to ${
        numUnlinked === 1 ? "an owner" : "owners"
      }`}
      description="Active service accounts should be accounted for as part of your access review, either as being owned by active employees, contractors or external users or marked as non-human"
      intent={Intent.WARNING}
      buttonProps={{ text: "View", onClick }}
    />
  );
};
