import { Menu, MenuItem } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import React from "react";
import { useHistory } from "react-router";

import type { ManagePoliciesV2Query } from "../../../gen/components";
import { doubleEncodeURIComponent } from "../../../helpers";
import { getPolicyQuestionSchema } from "./beta-policies-wizard-page";
import { ActionButton } from "./common";

interface IProps {
  policy: NonNullable<
    ManagePoliciesV2Query["organization"]
  >["policies"][number];
  onDelete(): void;
  onViewAnswers(): void;
  onViewHistory(): void;
  onRenew(): void;
}

export const PolicyActionMenu: React.FC<IProps> = ({
  policy,
  onDelete,
  onViewAnswers,
  onViewHistory,
  onRenew,
}) => {
  const history = useHistory();
  const canViewAnswers =
    (getPolicyQuestionSchema(policy.policyType)?.questions.length ?? 0) > 0;

  return (
    <Popover2
      placement="left"
      content={
        <Menu>
          {canViewAnswers ? (
            <MenuItem text="View answers" onClick={onViewAnswers} />
          ) : null}
          <MenuItem
            text="View in browser"
            href={policy.uploadedDoc.url}
            target="_blank"
            rel="noopener noreferrer"
          />
          <MenuItem text="View history" onClick={onViewHistory} />
          <MenuItem text="Renew current version" onClick={onRenew} />
          <MenuItem
            text="Upload new version"
            onClick={() => {
              history.push(
                `policies/${doubleEncodeURIComponent(policy.policyType)}`
              );
            }}
          />
          <MenuItem text="Delete policy" onClick={onDelete} />
        </Menu>
      }
    >
      <ActionButton icon="more" />
    </Popover2>
  );
};
