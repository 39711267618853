import React from "react";

import { BodyText } from "../../../../alpaca/components";
import { TabContainer, TabDescription } from "../common/components";
import { SnykProjectList } from "./snyk-project-list";

export const SnykVulnDisplay: React.FC = () => (
  <>
    <TabDescription>
      <BodyText>
        Monitor your applications and infrastructure for vulnerabilities
        surfaced by Snyk.
      </BodyText>
    </TabDescription>
    <TabContainer>
      <SnykProjectList />
    </TabContainer>
  </>
);
