import { Button } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { VANTA_COLORS } from "../../../alpaca/base/colors";
import { BASE_TYPOGRAPHY } from "../../../alpaca/base/typography";
import { BodyShortText, BodyText, H2 } from "../../../alpaca/components";
import { MDMInstructionsTab } from "./mdm-instructions-tab";

const DOWNLOAD_URL = `${location.protocol}//${location.host}/downloads`;

const REMEDIATION_SECTION_STYLES = {
  MARGIN_BOTTOM: 24,
  PADDING_TOP_BOTTOM: 16,
  PADDING_LEFT_RIGHT: 24,
  BORDER_RADIUS: 4,
};

const RemediationSection = styled.div`
  display: flex;

  margin-bottom: ${REMEDIATION_SECTION_STYLES.MARGIN_BOTTOM}px;
  padding: ${REMEDIATION_SECTION_STYLES.PADDING_TOP_BOTTOM}px
    ${REMEDIATION_SECTION_STYLES.PADDING_LEFT_RIGHT}px;

  background: ${VANTA_COLORS.BACKGROUND_WHITE};
  border-radius: ${REMEDIATION_SECTION_STYLES.BORDER_RADIUS}px;
`;

const REMEDIATION_SECTION_HEADER_STYLES = {
  WIDTH: 35,
};

const RemediationSectionHeaderContainer = styled.div`
  width: ${REMEDIATION_SECTION_HEADER_STYLES.WIDTH}%;
`;

const REMEDIATION_SECTION_CHILDREN_CONTAINER_STYLES = {
  MAX_WIDTH: 65,
};

interface IRemediationInstructionOptionProps {
  header: string;
}

const RemediationSectionChildrenContainer = styled.div`
  max-width: ${REMEDIATION_SECTION_CHILDREN_CONTAINER_STYLES.MAX_WIDTH}%;
  flex-grow: 2;
`;

const RemediationInstructionOption: React.FC<IRemediationInstructionOptionProps> =
  ({ header, children }) => (
    <RemediationSection>
      <RemediationSectionHeaderContainer>
        <BodyShortText fontWeight={BASE_TYPOGRAPHY.FONT_WEIGHTS.SLIGHTLY_BOLD}>
          {header}
        </BodyShortText>
      </RemediationSectionHeaderContainer>
      <RemediationSectionChildrenContainer>
        {children}
      </RemediationSectionChildrenContainer>
    </RemediationSection>
  );

export const InstallationInstructions: React.FC = () => (
  <>
    <H2>Remediation instructions</H2>
    <RemediationInstructionOption header="Connect your MDM system">
      <BodyText>
        If you haven't done so already, connect your MDM provider to Vanta on
        the <Link to="/connections">Connections page</Link>. Computers should be
        reflected here within an hour of appearing in your MDM dashboard.
      </BodyText>
    </RemediationInstructionOption>
    <RemediationInstructionOption header="Remind employees to install the Vanta Agent">
      <BodyText>
        Use the <Button icon={IconNames.MORE} disabled={true} /> menu in the
        table above to send employees an installation reminder email, or direct
        your team to visit the download page at{" "}
        <Link to={"/downloads"}>{DOWNLOAD_URL}</Link>.
      </BodyText>
    </RemediationInstructionOption>
    <RemediationInstructionOption header="Use your MDM system to install the Vanta Agent">
      <MDMInstructionsTab />
    </RemediationInstructionOption>
  </>
);
