/* eslint-disable */

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "UserError": [
      "BaseUserError"
    ],
    "MutationPayload": [
      "AddRoleToUsersPayload",
      "CreateNewCustomerAndInvoicePayload",
      "OptIntoStandardPayload",
      "OptOutOfStandardPayload",
      "ManuallyOptIntoTestsPayload",
      "RemoveManualOptInFromTestsPayload",
      "DeleteVendorDocumentPayload",
      "LinkUserWithHrUserPayload",
      "BulkApplyHrUserActionsPayload",
      "DeleteUserPayload",
      "UploadEvidencePayload",
      "UpdateDocumentEvidenceMetadataPayload",
      "AddLinkEvidencePayload",
      "UpdateLinkEvidencePayload",
      "DeleteLinkEvidencePayload",
      "SetEvidenceRequestIgnoredStatusPayload",
      "SetTestStatusToAvailablePayload",
      "SetTestStatusToPendingPayload",
      "MakeAllTestsAvailablePayload",
      "SyncTestsWithAllAutomatedTestsPayload",
      "SyncRolloutOptionsWithTestAvailabilityPayload",
      "ReleaseBetaFeaturePayload",
      "UnreleaseBetaFeaturePayload",
      "GenerateSignedUrlPayload"
    ],
    "BulkSetScopePayload": [
      "BulkSetScopeSuccess",
      "BaseUserError"
    ],
    "CreateAuditEngagementPayload": [
      "CreateAuditEngagementSuccess",
      "BaseUserError"
    ],
    "DeleteAuditEngagementPayload": [
      "DeleteAuditEngagementSuccess",
      "BaseUserError"
    ],
    "DeleteSecurityTrainingsByDomainPayload": [
      "DeleteSecurityTrainingsByDomainSuccess",
      "BaseUserError"
    ],
    "RemoveSecurityTrainingPreferenceByTrainingIdPayload": [
      "BaseUserError",
      "RemoveSecurityTrainingPreferenceByTrainingIdSuccess"
    ],
    "SetSecurityTrainingInstructionsByTrainingIdPayload": [
      "BaseUserError",
      "SetSecurityTrainingInstructionsByTrainingIdSuccess"
    ],
    "SetSecurityTrainingUrlByTrainingIdPayload": [
      "BaseUserError",
      "SetSecurityTrainingUrlByTrainingIdSuccess"
    ],
    "SetAuditCompletionDatePayload": [
      "SetAuditCompletionDateSuccess",
      "BaseUserError"
    ],
    "SetAuditObservationPeriodPayload": [
      "SetAuditObservationPeriodSuccess",
      "BaseUserError"
    ],
    "SetKnowBe4TrainingTypesPayload": [
      "SetKnowBe4TrainingTypesSuccess",
      "BaseUserError"
    ],
    "SetEvidenceRequestRenewalCadencePayload": [
      "SetEvidenceRequestRenewalCadenceSuccess",
      "BaseUserError"
    ],
    "SetEvidenceRequestRenewalDatePayload": [
      "SetEvidenceRequestRenewalDateSuccess",
      "BaseUserError"
    ],
    "SetEvidenceRequestReminderWindowPayload": [
      "SetEvidenceRequestReminderWindowSuccess",
      "BaseUserError"
    ],
    "ClearEvidenceRequestRenewalDatePayload": [
      "ClearEvidenceRequestRenewalDateSuccess",
      "BaseUserError"
    ],
    "UpdateTestDeactivatedReasonPayload": [
      "UpdateTestDeactivatedReasonSuccess",
      "BaseUserError"
    ],
    "DeleteSecurityTrainingDocumentPayload": [
      "DeleteSecurityTrainingDocumentSuccess",
      "BaseUserError"
    ],
    "ManuallyAttemptHrUserLinkForDomainPayload": [
      "ManuallyAttemptHrUserLinkForDomainSuccess",
      "BaseUserError"
    ],
    "MarkTestHistoryAsInvalidPayload": [
      "MarkTestHistoryAsInvalidSuccess",
      "BaseUserError"
    ],
    "RemovePolicyAcceptancesPayload": [
      "RemovePolicyAcceptancesSuccess",
      "BaseUserError"
    ],
    "RemoveRoleFromUsersPayload": [
      "RemoveRoleFromUsersSuccess",
      "BaseUserError"
    ],
    "SetUserSecurityTrainingVideoCompletionPayload": [
      "SetUserSecurityTrainingVideoCompletionSuccess",
      "BaseUserError"
    ],
    "SetEmployeeDigestNotificationFrequencyPayload": [
      "SetEmployeeDigestNotificationFrequencySuccess",
      "BaseUserError"
    ],
    "GeneratedReportAutologinPayload": [
      "GeneratedReportAutologinSuccess",
      "BaseUserError"
    ],
    "MarkInterestForStandardPayload": [
      "MarkInterestForStandardSuccess",
      "BaseUserError"
    ],
    "resource": [
      "SpecificALBResource",
      "SpecificAdpRunHrUserResource",
      "SpecificAdpWorkforceNowHrUserResource",
      "SpecificAirtableTaskResource",
      "SpecificAmazonLinuxPackageChangelogResource",
      "SpecificAsanaAccountResource",
      "SpecificAsanaTaskResource",
      "SpecificAtlasAccountResource",
      "SpecificAtlasClusterResource",
      "SpecificAutoScalingGroupResource",
      "SpecificAwsAccountResource",
      "SpecificAwsContainerVulnerabilityResource",
      "SpecificAwsCredentialReportResource",
      "SpecificAwsFlowLogResource",
      "SpecificAwsGroupResource",
      "SpecificAwsInspectorVulnerabilityResource",
      "SpecificAwsNetworkACLResource",
      "SpecificAwsPasswordPolicyResource",
      "SpecificAwsRoleResource",
      "SpecificAwsRouteTableResource",
      "SpecificAwsSecurityGroupResource",
      "SpecificAwsSubnetResource",
      "SpecificAwsVPCResource",
      "SpecificAzureApplicationGatewayResource",
      "SpecificAzureBlobContainerResource",
      "SpecificAzureContainerRepositoryResource",
      "SpecificAzureContainerVulnerabilityResource",
      "SpecificAzureCosmosDBResource",
      "SpecificAzureDatabaseForExternalSQLVariantResource",
      "SpecificAzureDevOpsAccountResource",
      "SpecificAzureDevOpsRepoResource",
      "SpecificAzureDevOpsTaskResource",
      "SpecificAzureLoadBalancerResource",
      "SpecificAzureMetricAlertRuleResource",
      "SpecificAzureQueueResource",
      "SpecificAzureRoleResource",
      "SpecificAzureRoleAssignmentResource",
      "SpecificAzureSQLDatabaseResource",
      "SpecificAzureSQLManagedInstanceResource",
      "SpecificAzureSQLServerOnVMsResource",
      "SpecificAzureScaleSetVirtualMachineResource",
      "SpecificAzureSecurityGroupResource",
      "SpecificAzureSynapseWarehouseResource",
      "SpecificAzureVirtualMachineResource",
      "SpecificAzureVirtualMachineScaleSetResource",
      "SpecificBambooHrHrUserResource",
      "SpecificBigQueryDatasetResource",
      "SpecificBigtableInstanceResource",
      "SpecificBitbucketAccountResource",
      "SpecificBitbucketRepoResource",
      "SpecificCentosPackageChangelogResource",
      "SpecificCertnBackgroundCheckResource",
      "SpecificClickupAccountResource",
      "SpecificClickupTaskResource",
      "SpecificCloudSQLInstanceResource",
      "SpecificCloudTrailResource",
      "SpecificCloudWatchLogGroupResource",
      "SpecificCloudWatchMetricAlarmResource",
      "SpecificClubhouseAccountResource",
      "SpecificClubhouseTaskResource",
      "SpecificCustomerCheckrBackgroundCheckResource",
      "SpecificDatadogAccountResource",
      "SpecificDatadogMonitorResource",
      "SpecificDatastoreProjectResource",
      "SpecificDebianPackageChangelogResource",
      "SpecificDigitalOceanAppResource",
      "SpecificDigitalOceanContainerRepositoryResource",
      "SpecificDigitalOceanDropletResource",
      "SpecificDigitalOceanFirewallResource",
      "SpecificDigitalOceanLoadBalancerResource",
      "SpecificDigitalOceanRedisClusterResource",
      "SpecificDigitalOceanRelationalDbClusterResource",
      "SpecificDigitalOceanSpaceResource",
      "SpecificDriveBackgroundCheckResource",
      "SpecificDynamoDBTableResource",
      "SpecificEC2InstanceResource",
      "SpecificECRContainerRepositoryResource",
      "SpecificEmptyDailySpecificResourceResource",
      "SpecificEmptySpecificResourceResource",
      "SpecificFirestoreProjectResource",
      "SpecificGCPComputeInstanceResource",
      "SpecificGCPContainerRepositoryResource",
      "SpecificGCPContainerVulnerabilityResource",
      "SpecificGCPLogBucketResource",
      "SpecificGCPLogSinkResource",
      "SpecificGCPMonitoringPolicyResource",
      "SpecificGCPNetworkResource",
      "SpecificGCPRoleResource",
      "SpecificGCPRoleGrantResource",
      "SpecificGCPStorageBucketResource",
      "SpecificGCPSubnetResource",
      "SpecificGCPSubscriptionResource",
      "SpecificGCPTopicResource",
      "SpecificGithubAccountResource",
      "SpecificGithubRepoResource",
      "SpecificGitlabAccountResource",
      "SpecificGitlabGroupResource",
      "SpecificGitlabRepoResource",
      "SpecificGitlabTaskResource",
      "SpecificGsuiteUserResource",
      "SpecificGustoHrUserResource",
      "SpecificHerokuAccountResource",
      "SpecificHerokuAppResource",
      "SpecificInsperityHrUserResource",
      "SpecificJamfAccountResource",
      "SpecificJamfManagedComputerResource",
      "SpecificJiraAccountResource",
      "SpecificJiraTaskResource",
      "SpecificJustworksHrUserResource",
      "SpecificKandjiManagedComputerResource",
      "SpecificKnowBe4AccountResource",
      "SpecificKnowBe4TrainingCampaignResource",
      "SpecificKnowBe4TrainingEnrollmentResource",
      "SpecificLinearAccountResource",
      "SpecificLinearTaskResource",
      "SpecificMicrosoftEndpointManagerManagedComputerResource",
      "SpecificNamelyHrUserResource",
      "SpecificNmapSslResource",
      "SpecificNvdCveResource",
      "SpecificOfficeUserResource",
      "SpecificOktaUserResource",
      "SpecificOsqueryVulnerabilityResource",
      "SpecificPaychexFlexHrUserResource",
      "SpecificPaycorHrUserResource",
      "SpecificPaylocityHrUserResource",
      "SpecificPivotalTrackerAccountResource",
      "SpecificPivotalTrackerTaskResource",
      "SpecificQuickBooksHrUserResource",
      "SpecificRDSInstanceResource",
      "SpecificRedshiftClusterResource",
      "SpecificRipplingHrUserResource",
      "SpecificS3Resource",
      "SpecificSQSResource",
      "SpecificSlackAccountResource",
      "SpecificSnykAccountResource",
      "SpecificSnykProjectResource",
      "SpecificSnykVulnerabilityResource",
      "SpecificSpannerInstanceResource",
      "SpecificSquarePayrollHrUserResource",
      "SpecificTrelloAccountResource",
      "SpecificTrelloTaskResource",
      "SpecificTrinetHrUserResource",
      "SpecificUbuntuPackageChangelogResource",
      "SpecificVantaCheckrBackgroundCheckResource",
      "SpecificVettyBackgroundCheckResource",
      "SpecificZenefitsHrUserResource",
      "SpecificGithubTaskResource"
    ],
    "GenericAccountResource": [
      "SpecificAsanaAccountResource",
      "SpecificAtlasAccountResource",
      "SpecificAwsAccountResource",
      "SpecificAzureDevOpsAccountResource",
      "SpecificBitbucketAccountResource",
      "SpecificClickupAccountResource",
      "SpecificClubhouseAccountResource",
      "SpecificDatadogAccountResource",
      "SpecificGithubAccountResource",
      "SpecificGitlabAccountResource",
      "SpecificHerokuAccountResource",
      "SpecificJamfAccountResource",
      "SpecificJiraAccountResource",
      "SpecificKnowBe4AccountResource",
      "SpecificLinearAccountResource",
      "SpecificPivotalTrackerAccountResource",
      "SpecificSlackAccountResource",
      "SpecificSnykAccountResource",
      "SpecificTrelloAccountResource"
    ],
    "GenericAccountGroupResource": [
      "SpecificAwsGroupResource"
    ],
    "GenericAlarmResource": [
      "SpecificAzureMetricAlertRuleResource",
      "SpecificCloudWatchMetricAlarmResource",
      "SpecificDatadogMonitorResource",
      "SpecificGCPMonitoringPolicyResource"
    ],
    "GenericBackgroundCheckResource": [
      "SpecificCertnBackgroundCheckResource",
      "SpecificCustomerCheckrBackgroundCheckResource",
      "SpecificDriveBackgroundCheckResource",
      "SpecificVantaCheckrBackgroundCheckResource",
      "SpecificVettyBackgroundCheckResource"
    ],
    "GenericCloudRoleResource": [
      "SpecificAwsRoleResource",
      "SpecificAzureRoleResource",
      "SpecificGCPRoleResource"
    ],
    "GenericCloudRoleGrantResource": [
      "SpecificAzureRoleAssignmentResource",
      "SpecificGCPRoleGrantResource"
    ],
    "GenericComputeInstanceResource": [
      "SpecificAzureScaleSetVirtualMachineResource",
      "SpecificAzureVirtualMachineResource",
      "SpecificDigitalOceanDropletResource",
      "SpecificEC2InstanceResource",
      "SpecificGCPComputeInstanceResource"
    ],
    "GenericContainerRepositoryResource": [
      "SpecificAzureContainerRepositoryResource",
      "SpecificDigitalOceanContainerRepositoryResource",
      "SpecificECRContainerRepositoryResource",
      "SpecificGCPContainerRepositoryResource"
    ],
    "GenericCredentialReportResource": [
      "SpecificAwsCredentialReportResource"
    ],
    "GenericCveRecordResource": [
      "SpecificNvdCveResource"
    ],
    "GenericDataWarehouseResource": [
      "SpecificAzureSynapseWarehouseResource",
      "SpecificBigQueryDatasetResource",
      "SpecificRedshiftClusterResource"
    ],
    "GenericEmptyResourceResource": [
      "SpecificEmptyDailySpecificResourceResource",
      "SpecificEmptySpecificResourceResource"
    ],
    "GenericFlowLogResource": [
      "SpecificAwsFlowLogResource"
    ],
    "GenericHrUserResource": [
      "SpecificAdpRunHrUserResource",
      "SpecificAdpWorkforceNowHrUserResource",
      "SpecificBambooHrHrUserResource",
      "SpecificGustoHrUserResource",
      "SpecificInsperityHrUserResource",
      "SpecificJustworksHrUserResource",
      "SpecificNamelyHrUserResource",
      "SpecificPaychexFlexHrUserResource",
      "SpecificPaycorHrUserResource",
      "SpecificPaylocityHrUserResource",
      "SpecificQuickBooksHrUserResource",
      "SpecificRipplingHrUserResource",
      "SpecificSquarePayrollHrUserResource",
      "SpecificTrinetHrUserResource",
      "SpecificZenefitsHrUserResource"
    ],
    "GenericLoadBalancerResource": [
      "SpecificALBResource",
      "SpecificAzureApplicationGatewayResource",
      "SpecificAzureLoadBalancerResource",
      "SpecificDigitalOceanLoadBalancerResource"
    ],
    "GenericLogGroupResource": [
      "SpecificCloudWatchLogGroupResource"
    ],
    "GenericLogTrailResource": [
      "SpecificCloudTrailResource",
      "SpecificGCPLogBucketResource",
      "SpecificGCPLogSinkResource"
    ],
    "GenericManagedComputerResource": [
      "SpecificJamfManagedComputerResource",
      "SpecificKandjiManagedComputerResource",
      "SpecificMicrosoftEndpointManagerManagedComputerResource"
    ],
    "GenericManagedInstanceGroupResource": [
      "SpecificAutoScalingGroupResource",
      "SpecificAzureVirtualMachineScaleSetResource"
    ],
    "GenericNetworkResource": [
      "SpecificGCPNetworkResource"
    ],
    "GenericNetworkACLResource": [
      "SpecificAwsNetworkACLResource"
    ],
    "GenericNoSQLDatabaseResource": [
      "SpecificAtlasClusterResource",
      "SpecificAzureCosmosDBResource",
      "SpecificBigtableInstanceResource",
      "SpecificDatastoreProjectResource",
      "SpecificDigitalOceanRedisClusterResource",
      "SpecificDynamoDBTableResource",
      "SpecificFirestoreProjectResource"
    ],
    "GenericPaaSAppResource": [
      "SpecificDigitalOceanAppResource",
      "SpecificHerokuAppResource"
    ],
    "GenericPackageChangelogResourceResource": [
      "SpecificAmazonLinuxPackageChangelogResource",
      "SpecificCentosPackageChangelogResource",
      "SpecificDebianPackageChangelogResource",
      "SpecificUbuntuPackageChangelogResource"
    ],
    "GenericPasswordPolicyResource": [
      "SpecificAwsPasswordPolicyResource"
    ],
    "GenericQueueResource": [
      "SpecificAzureQueueResource",
      "SpecificGCPSubscriptionResource",
      "SpecificSQSResource"
    ],
    "GenericRouteTableResource": [
      "SpecificAwsRouteTableResource"
    ],
    "GenericSQLDatabaseResource": [
      "SpecificAzureDatabaseForExternalSQLVariantResource",
      "SpecificAzureSQLDatabaseResource",
      "SpecificAzureSQLManagedInstanceResource",
      "SpecificAzureSQLServerOnVMsResource",
      "SpecificCloudSQLInstanceResource",
      "SpecificDigitalOceanRelationalDbClusterResource",
      "SpecificRDSInstanceResource",
      "SpecificSpannerInstanceResource"
    ],
    "GenericSecurityGroupResource": [
      "SpecificAwsSecurityGroupResource",
      "SpecificAzureSecurityGroupResource",
      "SpecificDigitalOceanFirewallResource"
    ],
    "GenericSslResource": [
      "SpecificNmapSslResource"
    ],
    "GenericStorageBucketResource": [
      "SpecificAzureBlobContainerResource",
      "SpecificDigitalOceanSpaceResource",
      "SpecificGCPStorageBucketResource",
      "SpecificS3Resource"
    ],
    "GenericSubnetResource": [
      "SpecificAwsSubnetResource",
      "SpecificGCPSubnetResource"
    ],
    "GenericTaskTrackerItemResource": [
      "SpecificAirtableTaskResource",
      "SpecificAsanaTaskResource",
      "SpecificAzureDevOpsTaskResource",
      "SpecificClickupTaskResource",
      "SpecificClubhouseTaskResource",
      "SpecificGitlabTaskResource",
      "SpecificJiraTaskResource",
      "SpecificLinearTaskResource",
      "SpecificPivotalTrackerTaskResource",
      "SpecificTrelloTaskResource",
      "SpecificGithubTaskResource"
    ],
    "GenericTopicResource": [
      "SpecificGCPTopicResource"
    ],
    "GenericTrainingResource": [
      "SpecificKnowBe4TrainingCampaignResource"
    ],
    "GenericTrainingRequirementResource": [
      "SpecificKnowBe4TrainingEnrollmentResource"
    ],
    "GenericUserGroupResource": [
      "SpecificGitlabGroupResource"
    ],
    "GenericUserResourceResource": [
      "SpecificGsuiteUserResource",
      "SpecificOfficeUserResource",
      "SpecificOktaUserResource"
    ],
    "GenericVPCResource": [
      "SpecificAwsVPCResource"
    ],
    "GenericVersionControlRepoResource": [
      "SpecificAzureDevOpsRepoResource",
      "SpecificBitbucketRepoResource",
      "SpecificGithubRepoResource",
      "SpecificGitlabRepoResource"
    ],
    "GenericVulnerabilityResource": [
      "SpecificAwsContainerVulnerabilityResource",
      "SpecificAwsInspectorVulnerabilityResource",
      "SpecificAzureContainerVulnerabilityResource",
      "SpecificGCPContainerVulnerabilityResource",
      "SpecificOsqueryVulnerabilityResource",
      "SpecificSnykVulnerabilityResource"
    ],
    "GenericVulnerabilityMonitoringTargetResource": [
      "SpecificSnykProjectResource"
    ],
    "osqueryData": [
      "macosWorkstationData",
      "linuxWorkstationData",
      "linuxServerData",
      "windowsWorkstationData"
    ],
    "securityTrainingCompletion": [
      "securityTrainingVideoCompletion",
      "securityTrainingDocumentCompletion"
    ],
    "HrUserBody": [
      "HrUser",
      "EmbeddedHrUser"
    ]
  }
};
      export default result;
    