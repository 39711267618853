import {
  Button,
  Checkbox,
  Classes,
  ControlGroup,
  Dialog,
  FormGroup,
  InputGroup,
  Intent,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import React, { useState } from "react";
import validator from "validator";

import {
  GetAuditInfoDocument,
  useAddAuditorToAuditMutation,
} from "../../../gen/components";
import { AppToaster } from "../../../helpers/toaster";

interface IProps {
  auditId: string;
}

export const AddAuditorForm: React.FC<IProps> = ({ auditId }) => {
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [grantAccessChecked, setGrantAccessChecked] = useState(true);
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);

  const [addAuditor] = useAddAuditorToAuditMutation({
    refetchQueries: [{ query: GetAuditInfoDocument }],
    onCompleted() {
      AppToaster.show({
        message: `Added ${email} to audit`,
        intent: Intent.SUCCESS,
      });
      resetFormAndCloseDialog();
    },
  });

  const resetFormAndCloseDialog = () => {
    setEmail("");
    setGrantAccessChecked(true);
    setDialogIsOpen(false);
    setErrorMessage(null);
  };

  const handleAddAuditor = async () => {
    await addAuditor({
      variables: {
        auditId,
        email,
        grantAccess: grantAccessChecked,
      },
    }).catch(err => {
      setErrorMessage(
        `We were unable to add ${email} to your audit. Please contact support@vanta.com for further assistance.`
      );
      setEmail("");
    });
  };

  const textInput = (
    <>
      <FormGroup
        helperText={errorMessage}
        intent={isSome(errorMessage) ? Intent.DANGER : Intent.NONE}
      >
        <ControlGroup>
          <InputGroup
            type="text"
            value={email}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setEmail(e.target.value)
            }
            placeholder="auditor@example.com"
          ></InputGroup>
        </ControlGroup>
      </FormGroup>
      <FormGroup
        helperText={
          grantAccessChecked
            ? " This auditor will have read-only access to your Vanta account. This access is independent of the audit observation dates and can be granted later."
            : undefined
        }
      >
        <Checkbox
          label="Grant this auditor immediate access to my account."
          checked={grantAccessChecked}
          onChange={e => {
            setGrantAccessChecked(e.currentTarget.checked);
          }}
        />
      </FormGroup>
    </>
  );

  const dialog = (
    <Dialog
      title="Add auditor to audit"
      isOpen={dialogIsOpen}
      onClose={resetFormAndCloseDialog}
    >
      <form>
        <div className={Classes.DIALOG_BODY}>{textInput}</div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={resetFormAndCloseDialog}>Cancel</Button>
            <Button
              type="submit"
              onClick={async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                e.preventDefault();
                await handleAddAuditor();
              }}
              disabled={!validator.isEmail(email)}
              intent={Intent.PRIMARY}
            >
              Add
            </Button>
          </div>
        </div>
      </form>
    </Dialog>
  );

  return (
    <>
      <Button
        onClick={() => setDialogIsOpen(true)}
        icon={IconNames.PLUS}
        intent={Intent.PRIMARY}
      >
        Add auditor
      </Button>
      {dialog}
    </>
  );
};

gql`
  mutation AddAuditorToAudit(
    $email: String!
    $auditId: String!
    $grantAccess: Boolean
  ) {
    addAuditorToAuditByEmail(
      email: $email
      auditId: $auditId
      grantAccess: $grantAccess
    ) {
      id
    }
  }
`;
