import { gql } from "@apollo/client";
import { POLICY_RENDERABLE_MIME_TYPES } from "common/base/types/helpers";

import type { GetBusinessInformationQuery } from "../../../../gen/components";
import {
  Feature,
  GetBusinessInformationDocument,
} from "../../../../gen/components";
import type { IQuestionSchema } from "../../../forms/interfaces";
import type { ProfilePageInternalProps } from "../types";

const BUSINESS_INFORMATION_SCHEMA: IQuestionSchema = {
  id: "businessInformation",
  questions: [
    {
      id: "name-2017_12_28",
      label: "Display name",
      name: "displayName",
      required: false,
      type: "TextInput",
    },
    {
      id: "legal_name-2018_05_24",
      label: "Legal name",
      name: "legalName",
      required: false,
      type: "TextInput",
    },
    {
      id: "us-2017_12_28",
      label: "Incorporated in the US",
      name: "USCompany",
      required: false,
      type: "CheckboxInput",
    },
    {
      id: "url-2017_12_28",
      label: "URL",
      name: "url",
      required: false,
      type: "URLInput",
    },
    {
      id: "address-2017_12_28",
      label: "Mailing address",
      name: "mailingAddress",
      required: false,
      type: "TextArea",
    },
    {
      id: "telephone-2017_12_28",
      label: "Telephone",
      name: "telephone",
      required: false,
      type: "TextInput",
    },
    {
      helper: "e.g. https://rocketship.com/about",
      id: "company_url-2018_04_27",
      label: "Company information URL",
      name: "companyInfoUrl",
      required: false,
      type: "URLInput",
    },
    {
      accept: POLICY_RENDERABLE_MIME_TYPES,
      id: "company_logo-2019_01_11",
      label: "Company logo",
      name: "companyLogo",
      required: false,
      type: "File",
    },
    {
      feature: Feature.BetaHIPAA,
      id: "hipaa-2020_07_20",
      label: "HIPAA",
      name: "hipaa",
      required: false,
      type: "Header",
    },
    {
      feature: Feature.BetaHIPAA,
      helper:
        "Designate the HIPAA security officer for your company. This person will be responsible for the development and implementation of the policies and procedures required in the HIPAA Security Rule (45 CFR § 164.308(a)(2)).",
      id: "hipaa-security-officer-2020_07_20",
      label: "Security officer",
      name: "hipaaSecurityOfficerId",
      required: false,
      type: "User",
    },
  ],
  submitText: "Update information",
  version: "2020-1-15",
};

gql`
  query getBusinessInformation {
    organization {
      id
      businessInfo {
        id
        displayName
        legalName
        mailingAddress
        telephone
        url
        USCompany
      }
      productDescriptionInfo {
        id
        companyInfoUrl
        companyLogo {
          id
          createdAt
          filename
          url
        }
        hipaaSecurityOfficerId
      }
    }
  }
`;

export const BUSINESS_INFO_FORM_PROPS: ProfilePageInternalProps = {
  graphqlQuery: GetBusinessInformationDocument,
  questionSchema: BUSINESS_INFORMATION_SCHEMA,
  mapQueryToSchemaInfo: (
    domain: NonNullable<GetBusinessInformationQuery["organization"]>
  ) => {
    return {
      ...domain.businessInfo,
      ...domain.productDescriptionInfo,
      id: undefined,
    };
  },
};
