import { Intent } from "@blueprintjs/core";
import React from "react";

import { Banner, IconNames } from "../../../alpaca/components";

interface IProps {
  numUnlinkedHrUsers: number;
  onView(): void;
}

export const UnlinkedHrUsersCallout: React.FC<IProps> = ({
  numUnlinkedHrUsers,
  onView,
}) => {
  if (numUnlinkedHrUsers === 0) {
    return null;
  }
  return (
    <Banner
      icon={IconNames.CAUTION}
      title={`${numUnlinkedHrUsers} ${
        numUnlinkedHrUsers > 1 ? "people" : "person"
      } found in your HR system could not be matched to accounts in Vanta`}
      description="Either manually connect them to existing accounts in Vanta, create a new Vanta account for them, or do not monitor them by marking them as 'out of scope'"
      intent={Intent.WARNING}
      buttonProps={{ text: "View", onClick: onView }}
    />
  );
};
