import { Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import type { Maybe } from "common/base/types/maybe";
import { dropNothing, isSome } from "common/base/types/maybe";
import gql from "graphql-tag";
import React, { useContext, useState } from "react";
import type { match as routerMatch } from "react-router";
import { useHistory } from "react-router";
import styled from "styled-components";
import { GRID_SPACING } from "../../../alpaca/base/grid";
import { DefaultView } from "../../../alpaca/components";
import { LogError, LogErrorMessage } from "../../../errors";
import {
  Feature,
  useGetPoliciesV2DomainInfoQuery,
  useSubmitPolicyWithAnswersMutation,
} from "../../../gen/components";
import { useFeatureCheck } from "../../../helpers/feature-gating/feature-check";
import { AppToaster } from "../../../helpers/toaster";
import type { IFormControlValue } from "../../forms/interfaces";
import { FullPageSpinner } from "../../helpers/FullPageSpinner";
import type { IPolicyQuestionSchema } from "../info/policies/policyDocSchema";
import {
  AcceptableUsePolicyInfo,
  AssetManagementPolicyInfo,
  BackupPolicyInfo,
  BusinessContinuityPlanInfo,
  ChangeManagementPolicyInfo,
  CodeOfConductPolicyInfo,
  CryptographyPolicyInfo,
  DataClassificationPolicyInfo,
  DataDeletePolicyInfo,
  DataProtectionPolicyInfo,
  DisasterRecoveryPlanInfo,
  IncidentResponseInfo,
  InformationSecurityInfo,
  PasswordPolicyInfo,
  PhysicalSecurityPolicyInfo,
  ResponsibleDisclosurePolicyInfo,
  RiskAssessmentProgramInfo,
  SystemAccessPolicyInfo,
  VendorManagementInfo,
  VulnerabilityManagementInfo,
} from "../info/policies/policyDocSchema";
import { NotFound404 } from "../notfound404";
import { UserContext } from "../user-context";
import { UNSELECTED_VALUE } from "./manage-procedures";
import { PoliciesH5, PoliciesPaddedCard } from "./policies-v2-styles";
import { PoliciesWizardEmployeeAcceptanceStep } from "./policies-wizard/policies-wizard-employee-acceptance-step";
import { PoliciesWizardFooter } from "./policies-wizard/policies-wizard-footer";
import { PoliciesWizardQuestionnaireStep } from "./policies-wizard/policies-wizard-questionnaire-step";
import { PoliciesWizardReacceptanceStep } from "./policies-wizard/policies-wizard-reacceptance-step";
import { PoliciesWizardReviewStep } from "./policies-wizard/policies-wizard-review-step";
import { PoliciesWizardStepCount } from "./policies-wizard/policies-wizard-step-count";
import { PoliciesWizardStepProgress } from "./policies-wizard/policies-wizard-step-progress";
import { PoliciesWizardUploadStep } from "./policies-wizard/policies-wizard-upload-step";
import { getEmployeeGroupsForPolicy } from "./utils";

interface IPoliciesWizardPageProps {
  match: routerMatch<{ policyType: string }>;
}

export interface IPolicyWizardStep {
  stepName: string;
  stepTitle: string;
  component: React.ReactNode;
  getValidationErrors?: Maybe<() => PolicyWizardError[]>;
}

export type PolicyWizardError = { id: string; message: string };

export interface AnswersMap {
  [k: string]: IFormControlValue;
}

const POLICY_TYPES_TO_BETA_SCHEMAS: {
  [x: string]: Maybe<IPolicyQuestionSchema>;
} = {
  "acceptable-use-policy": AcceptableUsePolicyInfo,
  "asset-management-policy": AssetManagementPolicyInfo,
  "backup-policy": BackupPolicyInfo,
  "business-continuity-plan": BusinessContinuityPlanInfo,
  "change-management-policy": ChangeManagementPolicyInfo,
  "code-of-conduct-policy": CodeOfConductPolicyInfo,
  "cryptography-policy": CryptographyPolicyInfo,
  "data-classification-policy": DataClassificationPolicyInfo,
  "data-delete-policy": DataDeletePolicyInfo,
  "data-protection-policy": DataProtectionPolicyInfo,
  "disaster-recovery-plan": DisasterRecoveryPlanInfo,
  "incident-response-plan": IncidentResponseInfo,
  "information-security-policy": InformationSecurityInfo,
  "password-policy": PasswordPolicyInfo,
  "physical-security-policy": PhysicalSecurityPolicyInfo,
  "responsible-disclosure-policy": ResponsibleDisclosurePolicyInfo,
  "risk-assessment-program": RiskAssessmentProgramInfo,
  "system-access-control-policy": SystemAccessPolicyInfo,
  "vendor-management-policy": VendorManagementInfo,
  "vulnerability-management-policy": VulnerabilityManagementInfo,
};

export function getPolicyQuestionSchema(
  policyType: Maybe<string>
): Maybe<IPolicyQuestionSchema> {
  return isSome(policyType) ? POLICY_TYPES_TO_BETA_SCHEMAS[policyType] : null;
}

/**
 * The BetaPoliciesWizardPage is the exported component that renders the policies
 * upload wizard/flow. This component itself is only concerned with feature gating
 * that can be removed with Policies v2 GA.
 * @see https://app.shortcut.com/vanta/story/12377/remove-beta-feature-gates-for-policies-v2-ga
 *
 */
export const BetaPoliciesWizardPage: React.FC<IPoliciesWizardPageProps> = ({
  match,
}) => {
  const policyType = decodeURIComponent(match.params.policyType);
  return <PolicyWizardPage policyType={policyType} />;
};

/**
 * The PoliciesWizard is the main logic container for the policy wizard flow.
 * It manages the data persistence and state management for all parts of the multistep form data,
 * validation (soon), and navigation.
 */
const PolicyWizardPage: React.FC<{
  policyType: string;
}> = ({ policyType }) => {
  const history = useHistory();
  const { user } = useContext(UserContext);
  const [submitPolicy] = useSubmitPolicyWithAnswersMutation();
  const { error, loading, data } = useGetPoliciesV2DomainInfoQuery({
    fetchPolicy: "network-only",
  });
  const [currStepIdx, setCurrStepIdx] = useState(0);

  const [policyFile, setPolicyFile] = useState<Maybe<File>>(null);
  const [questionnaireAnswers, setQuestionnaireAnswers] = useState<AnswersMap>(
    {}
  );
  const [isMajorChange, setMajorChange] = useState<Maybe<boolean>>(null);

  const [errors, setErrors] = useState<PolicyWizardError[]>([]);

  const isEmployeeGroupsOnPoliciesPageCustomer = useFeatureCheck(
    Feature.EmployeeGroupsOnPoliciesPage
  );

  if (!isSome(user)) {
    LogErrorMessage("No user context");
    return null;
  }
  if (error) {
    LogError(error);
    return null;
  }
  if (loading) {
    return <FullPageSpinner />;
  }
  if (!data) {
    LogErrorMessage("Bad fetch: policies wizard");
    return null;
  }
  const policyDocStub = data.organization.policyDocStubs.find(
    stub => stub.policyType === policyType
  );
  if (!isSome(policyDocStub)) {
    return <NotFound404 />;
  }
  const { title } = policyDocStub;
  const priorPolicyVersionExists = data.organization.policies.some(
    policy => policy.policyType === policyType
  );
  const policy = data.organization.policies.find(
    stub => stub.policyType === policyType
  );

  const policyQuestionSchema = getPolicyQuestionSchema(policyType);

  const policyWizardSteps: IPolicyWizardStep[] = dropNothing([
    {
      stepName: "Upload",
      stepTitle: "Upload your policy",
      component: (
        <PoliciesWizardUploadStep
          key={"policy-wizard-upload"}
          policyName={title}
          selectedFile={policyFile}
          policyType={policyType}
          onFileUploaded={(name, file) => {
            setPolicyFile(file);
          }}
          errors={errors}
        />
      ),
      getValidationErrors: () =>
        !isSome(policyFile)
          ? [{ id: "policyFile", message: "Please upload a PDF file" }]
          : [],
    },
    isEmployeeGroupsOnPoliciesPageCustomer
      ? {
          stepName: "Groups",
          stepTitle: "Employee acceptance",
          component: (
            <PoliciesWizardEmployeeAcceptanceStep
              key={"policy-wizard-employee-acceptance"}
              employeeGroups={getEmployeeGroupsForPolicy(
                policy,
                data.organization.defaultSecurityRequirements,
                data.organization.defaultNecessaryPolicies
              )}
            />
          ),
          getValidationErrors: () =>
            !isSome(policyFile)
              ? [{ id: "policyFile", message: "Please upload a PDF file" }]
              : [],
        }
      : null,
    isSome(policyQuestionSchema) && policyQuestionSchema.questions.length > 0
      ? {
          stepName: "Questions",
          stepTitle: "Answer Questions",
          component: (
            <PoliciesWizardQuestionnaireStep
              key={"policy-wizard-questionnaire"}
              questionSchema={policyQuestionSchema}
              questionnaireAnswers={questionnaireAnswers}
              setQuestionnaireAnswers={questionName => newValue =>
                setQuestionnaireAnswers({
                  ...questionnaireAnswers,
                  [questionName]: newValue,
                })}
              errors={errors}
            />
          ),
          getValidationErrors: () =>
            getQuestionnaireErrors(policyQuestionSchema, questionnaireAnswers),
        }
      : null,
    priorPolicyVersionExists
      ? {
          stepName: "Reacceptance",
          stepTitle: "Reacceptance",
          component: (
            <PoliciesWizardReacceptanceStep
              isMajorChange={isMajorChange}
              setMajorChange={majorChange => {
                setMajorChange(majorChange);
              }}
              errors={errors}
            />
          ),
          getValidationErrors: () =>
            !isSome(isMajorChange)
              ? [{ id: "policyFile", message: "Please select Yes or No" }]
              : [],
        }
      : null,
    {
      stepName: "Review",
      stepTitle: "Review",
      component: (
        <PoliciesWizardReviewStep
          key={"policy-wizard-review"}
          policyFile={policyFile!} // this will be non null from input validation
          policyTitle={title}
          questionSchema={policyQuestionSchema}
          questionnaireAnswers={questionnaireAnswers}
          isMajorChange={isMajorChange}
        />
      ),
    },
  ]);

  const onSubmit = async () => {
    const result = await submitPolicy({
      variables: {
        answers: JSON.stringify(questionnaireAnswers),
        policyType,
        domainId: user.domain.id,
        file: policyFile,
        majorChange: isMajorChange ?? true, // default to true for first time a policy is uploaded
      },
    });
    const success = result.data?.submitPolicyWithAnswers.success;
    const intent = success ? Intent.SUCCESS : Intent.DANGER;
    const message = success
      ? "Your policy was submitted successfully."
      : result.data?.submitPolicyWithAnswers.message;
    const icon = success ? null : IconNames.CROSS;
    AppToaster.show({
      icon,
      intent,
      message,
      timeout: 5000,
    });

    if (success) {
      // 11/16/2020 @chrismychen
      // legacy behavior that we should maintain until procedures are redesigned holistically
      // on a major change to a policy with related SLAs, send people to the procedures page
      const redirectLocation = [
        "system-access-control-policy",
        "vulnerability-management-policy",
        "change-management-policy",
      ].includes(policyType)
        ? "/policies#slas"
        : "/policies";
      history.push(redirectLocation);
      window.scrollTo(0, 0); // want to be at the top of the tab
    }
  };

  // @see https://app.shortcut.com/vanta/story/12332/implement-styles-and-remove-inline-styling-for-the-policy-upload-flow
  // To implement styles and remove inline styles
  return (
    <DefaultView
      headerProps={{
        breadcrumbs: [
          { text: "Policies", route: "/policies" },
          { text: title, route: "#" },
        ],
        title,
        rightControls: [
          <PoliciesWizardStepCount
            key="step-count"
            currStepIdx={currStepIdx}
            allSteps={policyWizardSteps}
          />,
        ],
      }}
    >
      <PoliciesWizardStepProgress
        allSteps={policyWizardSteps}
        currStepIdx={currStepIdx}
        onStepClick={step => setCurrStepIdx(step)}
      />
      <StyledMainContent>
        <PoliciesH5>{policyWizardSteps[currStepIdx].stepTitle}</PoliciesH5>
        <PoliciesPaddedCard>
          {policyWizardSteps[currStepIdx].component}
        </PoliciesPaddedCard>
      </StyledMainContent>
      <PoliciesWizardFooter
        currStepIdx={currStepIdx}
        allSteps={policyWizardSteps}
        onNext={() => {
          const stepValidationErrors =
            policyWizardSteps[currStepIdx]?.getValidationErrors?.() ?? [];
          setErrors(stepValidationErrors);
          if (
            !isSome(stepValidationErrors) ||
            stepValidationErrors.length === 0
          ) {
            setCurrStepIdx(currStepIdx + 1);
          }
        }}
        onPrev={() => setCurrStepIdx(currStepIdx - 1)}
        onSubmit={onSubmit}
      />
    </DefaultView>
  );
};

export function getQuestionnaireErrors(
  schema: IPolicyQuestionSchema,
  answers: AnswersMap
) {
  return schema.questions
    .filter(q => {
      // checkbox fields equate undefined/null with false acceptably
      if (q.type === "CheckboxInput") return false;
      if (!isSome(answers[q.name])) return true;
      const answer = answers[q.name].toString().trim();
      if (answer.length === 0) return true;
      if (answer === UNSELECTED_VALUE) return true;
      return false;
    })
    .map(q => {
      return {
        id: q.name,
        message: "This field is required",
      };
    });
}

const StyledMainContent = styled.div`
  margin: auto;
  max-width: ${100 * GRID_SPACING}px;
`;

gql`
  mutation submitPolicyWithAnswers(
    $domainId: ID!
    $answers: String!
    $policyType: String!
    $file: Upload!
    $majorChange: Boolean!
  ) {
    submitPolicyWithAnswers(
      domainId: $domainId
      answers: $answers
      policyType: $policyType
      file: $file
      majorChange: $majorChange
    ) {
      success
      message
    }
  }
`;

gql`
  query GetPoliciesV2DomainInfo {
    organization {
      id
      displayName
      ...PoliciesV2InfoSubQuery
      credentials {
        id
        service
      }
      productDescriptionInfo {
        id
        customerBillingTool
        customerSupportTool
      }
      defaultSecurityRequirements {
        mustAcceptPolicies
      }
      defaultNecessaryPolicies
      policies {
        id
        policyType
        rolesToAccept {
          id
          name
        }
      }
      policyDocStubs {
        policyType
        title
        description
      }
    }
  }
`;
