import {
  PenTestVendors,
  toMarkdownLink,
} from "common/constants/referral-links";
import {
  ListCoordinator,
  toCommaSeparatedList,
} from "common/grammar/sequences";
import gql from "graphql-tag";
import React from "react";

import type { GetEngineeringInformationQuery } from "../../../../gen/components";
import { GetEngineeringInformationDocument } from "../../../../gen/components";
import type { IQuestionSchema } from "../../../forms/interfaces";
import { PenTestComponent } from "../../business-info/pen-test-uploader/pen-test-component";
import type { ProfilePageInternalProps } from "../types";

const ENGINEERING_SCHEMA: IQuestionSchema = {
  id: "engineeringInfo",
  questions: [
    {
      id: "pen-test-frequency-2019_10_15",
      label: "Pen test frequency",
      name: "penTestFrequency",
      required: false,
      type: "SelectInputWithEditableOther",
      choices: ["Annually", "Semi-annually", "Quarterly"],
      helper: `Vanta recommends hiring an independent third-party to conduct penetration testing, at least once a year, to detect and respond to security vulnerabilities. Our pen testing partners include ${toCommaSeparatedList(
        PenTestVendors.map(toMarkdownLink),
        ListCoordinator.And
      )}.`,
    },
    {
      helper:
        "A bug bounty program is a way to incentivize security researchers to find bugs and security vulnerabilities in public-facing web applications. Companies and security researchers typically work through an intermediary bounty platform, such as Hackerone, Bugcrowd, or Federacy. Bug Bounty programs are not necessary for SOC 2 compliance but do provide additional security assurance.",
      id: "bug_bounty_url-2018_06_08",
      label: "Bug bounty program",
      name: "bugBountyUrl",
      required: false,
      type: "URLInput",
    },
  ],
  submitText: "Update information",
  version: "2020-1-15",
};

gql`
  query getEngineeringInformation {
    organization {
      id
      productDescriptionInfo {
        id
        bugBountyUrl
        penTestFrequency
      }
    }
  }
`;

export const ENGINEERING_INFO_FORM_PROPS: ProfilePageInternalProps = {
  additionalContent: <PenTestComponent />,
  graphqlQuery: GetEngineeringInformationDocument,
  questionSchema: ENGINEERING_SCHEMA,
  mapQueryToSchemaInfo: (
    domain: NonNullable<GetEngineeringInformationQuery["organization"]>
  ) => {
    return {
      ...domain.productDescriptionInfo,
      id: undefined,
    };
  },
};
