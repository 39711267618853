import gql from "graphql-tag";
import React from "react";

import { LogError } from "../../../errors";
import { useFetchVendorsQuery } from "../../../gen/components";
import { FailDataRow } from "../fail-data-row";

export const VendorTestEntities: React.FC<{
  entityIds: string[];
  testId: string;
  entityType: "Vendor";
  allowWhitelisting: boolean;
  first: number;
}> = ({ entityType, entityIds, testId, allowWhitelisting, first }) => {
  const { loading, error, data } = useFetchVendorsQuery();

  if (loading) {
    return <div />;
  }
  if (error) {
    LogError(error);
    return null;
  }
  if (!data?.organization) {
    return <div />;
  }

  const entityIdSet = new Set(entityIds);
  const elements = data.organization.vendors
    .filter(v => entityIdSet.has(v.id))
    .slice(0, first)
    .map(v => (
      <FailDataRow
        key={v.id}
        entityId={v.id}
        entityType={entityType}
        testId={testId}
        allowWhitelisting={allowWhitelisting}
      >
        {v.name}
      </FailDataRow>
    ));

  return <>{elements}</>;
};

gql`
  query fetchVendors {
    organization {
      id
      vendors {
        id
        name
      }
    }
  }
`;
