import { Checkbox as BPCheckbox } from "@blueprintjs/core";
import type { ICheckboxProps as IBPCheckboxProps } from "@blueprintjs/core";
import { isSome } from "common/base/types/maybe";
import type { Maybe } from "graphql/jsutils/Maybe";
import React from "react";
import styled from "styled-components";
import { BASE_PALETTE, VANTA_COLORS } from "../../base/colors";
import { Icon } from "../icon/icon";
import { IconNames } from "../icon/iconNames";
import { Tooltip } from "../tooltip/tooltip";
interface AdditionalProps {
  /**
   * Text to display in a tooltip
   */
  tooltipContent?: Maybe<string | JSX.Element>;
}

export type ICheckboxProps = IBPCheckboxProps & AdditionalProps;

// Checkmark from ALPACA_ICON_PATHS, but inverted, and encoded to fit in CSS
const checkmarkSVG = `%3Csvg xmlns='http://www.w3.org/2000/svg' fill='${encodeURIComponent(
  VANTA_COLORS.BACKGROUND_WHITE
)}'  viewBox='0 0 16 16' %3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.7557 6.65493C12.1174 6.23758 12.0723 5.60602 11.6549 5.24431C11.2376 4.8826 10.606 4.92771 10.2443 5.34507L6.73801 9.39081L5.81068 8.10682C5.48732 7.65909 4.86224 7.55827 4.41451 7.88163C3.96678 8.20499 3.86596 8.83007 4.18932 9.2778L5.11665 10.5618C5.87182 11.6074 7.40465 11.6754 8.24938 10.7007L11.7557 6.65493Z'/%3E%3C/svg%3E`;

const StyledCheckbox = styled(BPCheckbox)`
  line-height: 16px;

  & .bp3-control-indicator {
    background-color: ${VANTA_COLORS.BACKGROUND_WHITE};
    border-radius: 4px;
    border: 1px solid ${BASE_PALETTE.FOG};
    box-shadow: none;
  }

  &.bp3-control input:checked ~ .bp3-control-indicator {
    background-color: ${BASE_PALETTE.GRAPE};
    background-image: none;
    border: 1px solid ${BASE_PALETTE.GRAPE};
    box-shadow: none;
    background-clip: border-box;

    ::before {
      top: -1px;
      left: -1px;
      position: absolute;
      background-image: url("data:image/svg+xml,${checkmarkSVG}");
    }
  }

  input:not(:disabled):active ~ .bp3-control-indicator {
    background: ${BASE_PALETTE.GRAPE};
    box-shadow: none;
  }

  &.bp3-control input:disabled ~ .bp3-control-indicator {
    opacity: 0.5;
  }

  &.bp3-control input:disabled:not(:checked) ~ .bp3-control-indicator {
    background-color: ${VANTA_COLORS.BACKGROUND_WHITE};
  }

  &:hover {
    .bp3-control-indicator {
      background-color: ${VANTA_COLORS.BACKGROUND_WHITE};
    }

    input:checked ~ .bp3-control-indicator {
      background-color: ${BASE_PALETTE.GRAPE} !important;
      box-shadow: none;
    }
  }

  .bp3-popover2-target {
    margin-left: 8px;
    position: absolute;
    top: 0;
  }
`;

export const Checkbox: React.FC<ICheckboxProps> = ({
  tooltipContent,
  ...props
}) => {
  if (isSome(tooltipContent)) {
    return (
      <StyledCheckbox {...props}>
        <Tooltip content={tooltipContent} placement="bottom">
          <Icon
            icon={IconNames.INFO}
            color={props.disabled ? BASE_PALETTE.FOG : BASE_PALETTE.CHARCOAL}
          ></Icon>
        </Tooltip>
      </StyledCheckbox>
    );
  }
  return <StyledCheckbox {...props} />;
};
