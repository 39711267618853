import React from "react";

import type { GetDataForReportQuery } from "../../../../../gen/components";
import { RequirementHeading } from "../../../common";

interface IProps {
  requirement: NonNullable<
    GetDataForReportQuery["complianceStandard"]
  >["principles"][number]["requirements"][number];
}

export const VantaReportRequirementListItemHeading: React.FunctionComponent<IProps> =
  ({ requirement }) => (
    <div className="vrp-requirement-heading">
      <div className="vrp-requirement-section">{requirement.section}</div>
      <RequirementHeading>{requirement.name}</RequirementHeading>
    </div>
  );
