import { Button, Classes, HTMLSelect, Intent, Switch } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import { StandardToDisplayName } from "common/constants/displayNames";
import gql from "graphql-tag";
import React, { useContext, useState } from "react";
import styled from "styled-components";

import { LogError } from "../../errors";
import type {
  GetCustomerMaterialsQuery,
  ReportStandard,
} from "../../gen/components";
import { useCreatePresentedReportMutationMutation } from "../../gen/components";
import { InfoIcon } from "../helpers/InfoIcon";
import { UserContext } from "../pages/user-context";

interface IProps {
  domain: NonNullable<GetCustomerMaterialsQuery["user"]>["domain"];
  onReportCreated(): void;
}

export const CreateReport: React.FC<IProps> = ({ domain, onReportCreated }) => {
  const [createPresentedReport, mutationResult] =
    useCreatePresentedReportMutationMutation({
      onCompleted: () => onReportCreated(),
    });
  const { domainStandards } = useContext(UserContext);
  const [password, setPassword] = useState("");
  const [standard, setStandard] = useState<Maybe<ReportStandard>>(null);
  const [viewerCompany, setViewCompany] = useState("");
  const [showMetControlsOnly, setShowMetControlsOnly] = useState(true);
  const [showStandardError, setShowStandardError] = useState(false);

  function handleSubmit(event: React.SyntheticEvent<HTMLFormElement>) {
    event.preventDefault();

    if (!isSome(standard)) {
      setShowStandardError(true);
      return;
    }

    createPresentedReport({
      variables: {
        domainId: domain.id,
        password,
        showSuccessOnly: showMetControlsOnly,
        standard,
        viewerCompany,
      },
    }).catch(LogError);
  }

  const maybeError = showStandardError ? (
    <div className="error">Please select a report standard.</div>
  ) : undefined;

  const metControlsOnlyExplanation = (
    <div style={{ maxWidth: 500 }}>
      You may want to show only successful test results if you're sending the
      report to a prospect or customer with whom you do not yet have an
      established relationship.
    </div>
  );

  const metControlsOnlyExplanationElement = (
    <label>
      <div>
        Show successful test results only
        <InfoIcon content={metControlsOnlyExplanation} />
      </div>
    </label>
  );

  return (
    <StyledForm className="form-group" onSubmit={handleSubmit}>
      <label>
        <div>Customer's company</div>
        <input
          type="text"
          value={viewerCompany}
          name="viewerCompany"
          placeholder="Lofty Inc."
          onChange={e => setViewCompany(e.currentTarget.value)}
          required={true}
        />
      </label>

      <label>
        <div>Password</div>
        <input
          type="text"
          value={password}
          name="password"
          onChange={e => setPassword(e.currentTarget.value)}
          required={true}
        />
      </label>

      <label>
        <div>Report standard</div>
        {domainStandards.length > 0 ? (
          <HTMLSelect
            name="standard"
            onChange={e => setStandard(e.target.value as ReportStandard)}
            value={standard ?? undefined}
          >
            <option value={undefined}>Choose a standard</option>
            {domainStandards.map(s => (
              <option key={s} value={s}>
                {StandardToDisplayName(s)}
              </option>
            ))}
          </HTMLSelect>
        ) : (
          <div>No standards available</div>
        )}
      </label>
      {maybeError}

      <label>
        <Switch
          checked={showMetControlsOnly}
          className={Classes.ALIGN_RIGHT}
          labelElement={metControlsOnlyExplanationElement}
          onChange={() => setShowMetControlsOnly(!showMetControlsOnly)}
        />
      </label>

      <Button
        loading={mutationResult.called}
        type="submit"
        intent={Intent.PRIMARY}
        fill
      >
        Make new report
      </Button>
    </StyledForm>
  );
};

const StyledForm = styled.form`
  && {
    margin: 0;
    button {
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }
`;

gql`
  mutation CreatePresentedReportMutation(
    $domainId: ID
    $viewerCompany: String!
    $password: String!
    $standard: reportStandard!
    $showSuccessOnly: Boolean!
  ) {
    createPresentedReport(
      domainId: $domainId
      viewerCompany: $viewerCompany
      password: $password
      standard: $standard
      showSuccessOnly: $showSuccessOnly
    ) {
      id
      name
      presentedReports {
        id
        createdAt
        viewerCompany
        password
        showSuccessOnly
        slugId
        standard
        views {
          id
          email
          lastUpdated
        }
      }
    }
  }
`;
