import type { IQuestionSchema } from "../../forms/interfaces";

export const generalSecurity3: IQuestionSchema = {
  id: "generalSecurity3",
  title: "Vulnerability management",
  questions: [
    {
      id: "vulnmgmtHeader-2018-09-10",
      label: "Vulnerability management",
      name: "vulnmgmtHeader",
      required: false,
      type: "Header",
    },
    {
      helper: "Summary and/or redacted results suffice",
      id: "penTestFile-2018_09_10",
      label: "Most recent pen test",
      name: "vaqPenTestFile",
      required: false,
      type: "File",
    },
    {
      id: "penTestDate-2018_09_10",
      label: "Date of last pen test",
      name: "penTestDate",
      required: true,
      type: "Date",
      visibleConditions: [{ name: "vaqPenTestFile" }],
    },
    {
      id: "bugBounty-2018_09_10",
      label: "Bug bounty URL or email to report security incidents",
      name: "bugBountyURL",
      required: false,
      type: "TextInput",
    },
    {
      id: "vulnScanTools-2018_09_10",
      label: "Vulnerability scanning tools that run at least weekly",
      name: "vulnScanTools",
      required: false,
      type: "TextArea",
    },
    {
      id: "riskAssessmentDate-2018_09_10",
      label: "Date of last risk assessment",
      name: "riskAssessmentDate",
      required: false,
      type: "Date",
    },
  ],
  submitText: "Submit vulnerability testing info",
  version: "2018-09-10",
};
