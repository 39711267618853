import { Collapse } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import React, { useState } from "react";
import styled from "styled-components";

import { BodyText, Button } from "../../../../alpaca/components";
import { Checkbox } from "../../../form-controls/checkbox";
import { InfoIcon } from "../../../helpers/InfoIcon";
import { DropdownButton } from "../../components/dropdown-button";
import { useTrainingCategoryAccess } from "../../people/shared/use-hipaa-sat-enabled";
import type {
  Campaign,
  CampaignCollapseState,
  CategoryLabels,
} from "./id-trainings";

interface IProps {
  onToggleExpand: (isOpen: boolean) => void;
  onDelete: () => void;
  isOpen: boolean;
  campaigns: Campaign[];
  currentSelections: Set<string>;
  updateCampaignLabels(
    currentSelection: Maybe<Campaign>,
    categories: CategoryLabels
  ): void;
  startState: CampaignCollapseState;
}

const MultilineTooltipContent = styled.div`
  min-width: 400px;
`;

const genSatLabel = (
  <>
    General security training
    <InfoIcon
      content={
        <MultilineTooltipContent>
          General Security Awareness Training should cover topics like email
          scams, malware, password security, removable media, etc.
          <br></br>
          As security best practice, Vanta recommends tracking these trainings
          regardless of any security certifications your organization is
          pursuing.
        </MultilineTooltipContent>
      }
    />
  </>
);

export const TrainingLabeller: React.FC<IProps> = ({
  onToggleExpand,
  onDelete,
  isOpen,
  currentSelections,
  campaigns,
  updateCampaignLabels,
  startState,
}) => {
  const [selectedCampaign, setSelectedCampaign] = useState<Maybe<Campaign>>(
    startState.campaign
  );
  const [isGeneral, setIsGeneral] = useState(startState.categories.isGeneral);
  const [isHipaa, setIsHipaa] = useState(startState.categories.isHipaa);
  const [isPci, setIsPci] = useState(startState.categories.isPci);
  const access = useTrainingCategoryAccess();

  // don't include options selected in other dropdowns
  const menuOptions: Campaign[] = campaigns.filter(
    c => c === selectedCampaign || !currentSelections.has(c.node.uniqueId)
  );

  const optionRenderer = (option: Campaign) => option.node.displayName;

  const select = (
    <DropdownButton
      options={menuOptions}
      selectedOption={selectedCampaign}
      onOptionSelect={option => {
        updateCampaignLabels(option, { isGeneral, isHipaa, isPci });
        setSelectedCampaign(option);
      }}
      optionRenderer={optionRenderer}
      filterable={true}
      buttonWidth={400}
      menuWidth={400}
      styleOnSelect
    />
  );

  const generalCheck = (
    <Checkbox
      checked={isGeneral}
      onChange={e => {
        setIsGeneral(e.currentTarget.checked);
        if (isSome(selectedCampaign)) {
          updateCampaignLabels(selectedCampaign, {
            isGeneral: e.currentTarget.checked,
            isHipaa,
            isPci,
          });
        }
      }}
      labelElement={genSatLabel}
    />
  );

  const hipaaCheck = access.hipaa ? (
    <Checkbox
      checked={isHipaa}
      onChange={e => {
        setIsHipaa(e.currentTarget.checked);
        if (isSome(selectedCampaign)) {
          updateCampaignLabels(selectedCampaign, {
            isGeneral,
            isHipaa: e.currentTarget.checked,
            isPci,
          });
        }
      }}
      label="HIPAA"
    />
  ) : null;

  const pciCheck = access.pci ? (
    <Checkbox
      checked={isPci}
      onChange={e => {
        setIsPci(e.currentTarget.checked);
        if (isSome(selectedCampaign)) {
          updateCampaignLabels(selectedCampaign, {
            isGeneral,
            isPci: e.currentTarget.checked,
            isHipaa,
          });
        }
      }}
      label="PCI DSS"
    />
  ) : null;

  const header = (
    <BodyText
      as="div"
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
      onClick={() => {
        onToggleExpand(isOpen);
      }}
    >
      {isOpen
        ? "Campaign details"
        : isSome(selectedCampaign)
        ? selectedCampaign.node.displayName
        : "Campaign details"}
      <div>
        <span
          onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) =>
            e.stopPropagation()
          }
        >
          <Popover2
            content={<Button onClick={onDelete}>Delete</Button>}
            placement={"bottom-end"}
          >
            <Button rightIcon={"more"} minimal={true} />
          </Popover2>
        </span>
        <Button
          rightIcon={isOpen ? "caret-down" : "caret-right"}
          minimal={true}
        />
      </div>
    </BodyText>
  );

  return (
    <div>
      {header}
      <Collapse isOpen={isOpen}>
        {select}
        <div style={{ marginTop: "16px" }}>
          {generalCheck}
          {hipaaCheck}
          {pciCheck}
        </div>
      </Collapse>
    </div>
  );
};
