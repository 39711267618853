import { isSome } from "common/base/types/maybe";
import { RISK_CATEGORIES } from "common/schemas/riskRegister/risk-register-content";
import React, { useMemo } from "react";
import { Redirect, useRouteMatch } from "react-router";
import styled from "styled-components";

import { VANTA_COLORS } from "../../../alpaca/base/colors";
import { useGetInventoryItemsForRiskAssessmentQuery } from "../../../gen/components";
import { useAvailableBetaFeatures } from "../../../helpers/feature-gating/feature-check";
import { FullPageSpinner } from "../../helpers/FullPageSpinner";
import { PageHeading } from "../../vanta-chrome/page-content/page-heading";
import {
  PageHeadingInfo,
  wrapInDashboardContainer,
} from "../../vanta-chrome/page-content/vanta-dashboard-page";
import { RiskCategoryCard } from "./risk-category-card";
import { RiskCategoryForm } from "./risk-category-form";

export const RISK_REGISTER_URL = "/risk-register";

const RiskRegisterHomeInternal: React.FC = () => {
  const { data } = useGetInventoryItemsForRiskAssessmentQuery();

  // eslint-disable-next-line vanta/optional-always-maybe, vanta/prefer-maybe
  const match = useRouteMatch<{ category?: string | undefined }>();
  const categoryFromParams = match?.params.category;
  const availableBetaFeatures = useAvailableBetaFeatures();
  const gatedRiskCategories = Object.values(RISK_CATEGORIES).filter(
    category =>
      !isSome(category.feature) || availableBetaFeatures?.has(category.feature)
  );
  const riskCategory = Object.values(gatedRiskCategories).find(
    info => info.url === categoryFromParams
  );

  const dismissals = useMemo(
    () => data?.organization.riskDismissals ?? [],
    [data?.organization.riskDismissals]
  );

  const evaluations = useMemo(
    () => data?.organization.riskEvaluations ?? [],
    [data?.organization.riskEvaluations]
  );

  const tasks = useMemo(
    () => data?.organization.riskMitigationTasks ?? [],
    [data?.organization.riskMitigationTasks]
  );

  if (isSome(categoryFromParams) && !isSome(riskCategory)) {
    return <Redirect to={RISK_REGISTER_URL} />;
  }

  if (!isSome(data)) {
    return <FullPageSpinner />;
  }

  if (isSome(riskCategory)) {
    return (
      <RiskCategoryForm
        category={riskCategory}
        dismissals={dismissals}
        items={evaluations}
        tasks={tasks}
      />
    );
  }

  // When there are more than 6 categories, arrange the cards in
  // rows of 4 instead of rows of 3.
  const cardWidth =
    gatedRiskCategories.length > 6
      ? styles.CARD_WIDTH_SHORT
      : styles.CARD_WIDTH_LONG;

  return (
    <PageContainer>
      <PageHeading headingInfo={PageHeadingInfo.RISK_REGISTER} />
      <CardSet>
        {Object.values(gatedRiskCategories).map(category => {
          const riskIdSet = new Set(category.risks.map(r => r.id));

          const categoryHasBegun =
            dismissals.some(d => riskIdSet.has(d.riskId)) ||
            evaluations.some(e => riskIdSet.has(e.riskId));

          const taskStepCompleted =
            category.risks.every(r =>
              dismissals.some(d => d.riskId === r.id)
            ) || tasks.some(t => t.riskCategoryId === category.id);

          const numberOfCompletedSteps =
            category.risks.filter(risk => {
              if (dismissals.some(d => d.riskId === risk.id)) {
                return true;
              }
              const evaluationsForRisk = evaluations.filter(
                e => e.riskId === risk.id
              );
              return (
                evaluationsForRisk.length > 0 &&
                evaluationsForRisk.every(
                  e => isSome(e.impact) && isSome(e.likelihood)
                )
              );
            }).length + (taskStepCompleted ? 1 : 0);

          return (
            <CardContainer key={category.url} width={cardWidth}>
              <RiskCategoryCard
                category={category}
                visited={categoryHasBegun}
                stepsCompleted={numberOfCompletedSteps}
              />
            </CardContainer>
          );
        })}
      </CardSet>
    </PageContainer>
  );
};

const styles = {
  CARD_PADDING: 24,
  CARD_HEIGHT: 290,
  CARD_WIDTH_LONG: 274,
  CARD_WIDTH_SHORT: 264,
  CARD_MARGIN_BOTTOM: 18,
  CARD_MARGIN_RIGHT: 18,
};

const PageContainer = styled.div`
  height: 100%;
`;

const CardSet = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

interface ICardContainerProps {
  width: number;
}

const CardContainer = styled.div<ICardContainerProps>`
  background-color: ${VANTA_COLORS.BACKGROUND_WHITE};
  padding: ${styles.CARD_PADDING}px;
  height: ${styles.CARD_HEIGHT}px;
  width: ${props => props.width}px;
  margin-bottom: ${styles.CARD_MARGIN_BOTTOM}px;
  margin-right: ${styles.CARD_MARGIN_RIGHT}px;
`;

export const RiskRegisterHome = wrapInDashboardContainer(
  RiskRegisterHomeInternal
);
