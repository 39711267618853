import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import React from "react";
import styled from "styled-components";

import { CopyClipboardIcon } from "./CopyClipboardIcon";

const CopyableCodeOuterContainer = styled.div`
  position: relative;
`;

const CopyableCodeCopyIconContainerStyles = {
  RIGHT: 0.2,
  TOP: 0.2,
};

const CopyableCodeCopyIconContainer = styled.div`
  position: absolute;
  right: ${CopyableCodeCopyIconContainerStyles.RIGHT}em;
  top: ${CopyableCodeCopyIconContainerStyles.RIGHT}em;
`;

const CopyableCodeText = styled.pre<{ maxHeight?: Maybe<number> }>`
  overflow: scroll;
  ${({ maxHeight }) =>
    isSome(maxHeight) ? `max-height: ${maxHeight}vh;` : undefined};
`;

/**
 * CopyableCode is a pre component with a copy-to-clipboard icon for displaying
 * large blocks of users are expected to run themselves.
 *
 * @param code - the code to display/copy.
 * @param maxHeight - the maximum height of this div, in vh. If unset, the
 *    height is unbounded.
 */
export const CopyableCode: React.FC<{
  code: string;
  maxHeight?: Maybe<number>;
}> = ({ code, maxHeight }) => (
  <CopyableCodeOuterContainer>
    <CopyableCodeCopyIconContainer>
      <CopyClipboardIcon text={code} />
    </CopyableCodeCopyIconContainer>
    <CopyableCodeText maxHeight={maxHeight}>{code}</CopyableCodeText>
  </CopyableCodeOuterContainer>
);
