import type { IDateInputProps } from "@blueprintjs/datetime";
import { DateInput as BPDateInput } from "@blueprintjs/datetime";
import classnames from "classnames";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import React from "react";
import styled from "styled-components";

import { BASE_PALETTE } from "../../base/colors";
import { Icon, IconNames } from "../index";
import { FormGroup } from "./form-group";
import { FormClasses, INPUT_GROUP_STYLES } from "./styles";

interface IAdditionalProps {
  label?: Maybe<React.ReactNode>;
  helperText?: Maybe<React.ReactNode>;
}

export const DateInput: React.FC<IDateInputProps & IAdditionalProps> = ({
  label,
  helperText,
  inputProps,
  ...props
}) => {
  const dateInput = (
    <StyledBPDateInput
      {...props}
      inputProps={{
        ...inputProps,
        className: classnames(FormClasses.INPUT_GROUP, inputProps?.className),
        leftIcon: <Icon icon={IconNames.CALENDAR} color={BASE_PALETTE.SMOKE} />,
      }}
    />
  );
  if (!isSome(label) && !isSome(helperText)) {
    return dateInput;
  }
  return (
    <FormGroup
      label={label}
      helperText={helperText}
      intent={inputProps?.intent}
      className={props.className}
    >
      {dateInput}
    </FormGroup>
  );
};

const StyledBPDateInput = styled(BPDateInput)`
  width: 100%;
  span.bp3-popover-target {
    width: 100%;
  }

  &&& {
    .bp3-input-group .bp3-icon {
      position: absolute;
      left: 3px;
      top: 5px;
    }
  }

  & * {
    ${INPUT_GROUP_STYLES}
  }
`;
