import { dateStringToDate } from "common/base/dateUtils";
import type { Service } from "common/base/types/helpers";
import { serviceToDisplayName } from "common/base/types/helpers";
import { dropNothing, isSome } from "common/base/types/maybe";
import { TRAINING_CATEGORIES_BY_ID } from "common/constants/onboarding";
import {
  ListCoordinator,
  toCommaSeparatedList,
} from "common/grammar/sequences";
import { format, getYear } from "date-fns";
import { groupBy, uniqBy } from "lodash";
import moment from "moment";
import React, { useMemo } from "react";
import styled from "styled-components";

import { BASE_PALETTE } from "../../../../../alpaca/base/colors";
import { BASE_TYPOGRAPHY } from "../../../../../alpaca/base/typography";
import {
  BodyText,
  IconButton,
  IconNames,
} from "../../../../../alpaca/components";
import { GetUserInfoForOnboardingTabDocument } from "../../../../../gen/components";
import { asDateOnlyDate, getTrainingCategories } from "../../utils";
import { BackgroundCheckDetails } from "../background-check-details";
import { PolicyAcceptanceDetail } from "../policy-acceptance-detail";
import { SecurityTrainingDetail } from "../security-training-detail";
import { CustomTaskItem } from "./custom-task-item";
import type { OnboardingTabUser } from "./onboarding-tab";
import { OnboardingTasksCard } from "./onboarding-tasks-card";
import { TOOLTIP_DATE_FORMAT } from "./security-requirements-list";
import { TaskListItem } from "./task-list-item";

const tooltipForType = (
  t: OnboardingTabUser["securityTrainingCompletions"][number]["__typename"]
) => (t === "securityTrainingDocumentCompletion" ? "File uploaded" : "In app");
interface CompletedTaskRow {
  completionDate: Date;
  completionYear: number;
  rowElement: JSX.Element;
}

interface IProps {
  user: OnboardingTabUser;
}

export const TaskCompletionHistory: React.FC<IProps> = ({ user }) => {
  const {
    id,
    backgroundChecks,
    displayName,
    isActive,
    securityPolicyAcceptances,
    securityTrainingCompletions,
    trainingRequirements,
    roleCompletionRecord,
    workstations,
  } = user;

  // Background checks
  const backgroundCheckCompletions = useMemo(
    () =>
      backgroundChecks
        .filter(check => isSome(check.completedAt))
        .map(check => {
          // Unfortunately for historical reasons, completedAt is formatted on the backend
          const completionDate = moment(
            check.completedAt!,
            "MMM Do YYYY"
          ).toDate();
          const actionButtons = [
            <BackgroundCheckDetails
              key={`bg-check-details-${check.id}`}
              userId={user.id}
              userName={user.displayName ?? ""}
              renderButton={onClick => (
                <IconButton
                  onClick={onClick}
                  icon={IconNames.VIEW}
                  tooltipProps={{ content: "View" }}
                  small
                />
              )}
            />,
          ];
          const rowElement = (
            <TaskListItem
              key={`bg-check-${check.id}`}
              title="Background check completed"
              completionDate={completionDate}
              actionButtons={actionButtons}
            />
          );
          return {
            rowElement,
            completionDate,
            completionYear: getYear(completionDate),
          };
        }),
    [backgroundChecks, id, displayName]
  );

  // Agent install
  const agentInstallCompletions = useMemo(
    () =>
      workstations.map(workstation => {
        const completionDate = dateStringToDate(workstation.createdAt);
        const rowElement = (
          <TaskListItem
            key={`agent-install-${workstation.id}`}
            title="Vanta Agent installed"
            completionDate={completionDate}
          />
        );
        return {
          rowElement,
          completionDate,
          completionYear: getYear(completionDate),
        };
      }),
    [workstations]
  );

  // Policy acceptances
  const policyAcceptances = useMemo(() => {
    const policyAcceptanceDates = uniqBy(
      securityPolicyAcceptances,
      acceptance =>
        asDateOnlyDate(
          dateStringToDate(acceptance.createdAt),
          false
        ).toDateString()
    ).map(acceptance => dateStringToDate(acceptance.createdAt));
    const policyAcceptanceRows = policyAcceptanceDates.map(completionDate => {
      const actionButtons = [
        <PolicyAcceptanceDetail
          key={`policy-acceptance-detail-${completionDate}`}
          userId={id}
          userName={displayName ?? ""}
          renderButton={onClick => (
            <IconButton
              onClick={onClick}
              icon={IconNames.VIEW}
              tooltipProps={{ content: "View" }}
              small
            />
          )}
        />,
      ];
      const rowElement = (
        <TaskListItem
          key={`policy-acceptance-${completionDate}`}
          title="Accepted policies"
          completionDate={completionDate}
          actionButtons={actionButtons}
        />
      );
      return {
        rowElement,
        completionDate,
        completionYear: getYear(completionDate),
      };
    });
    return policyAcceptanceRows;
  }, [securityPolicyAcceptances, id, displayName]);

  // sat completions
  const satCompletions = useMemo(
    () =>
      securityTrainingCompletions.map((completion, i) => {
        const trainingName = TRAINING_CATEGORIES_BY_ID.get(
          completion.category
        )!.displayName;
        const completionDate = dateStringToDate(completion.completionDate);
        const actionButtons = [
          <SecurityTrainingDetail
            key={`training-detail-${i}`}
            completion={completion}
            userId={user.id}
            refetchQueries={[
              {
                query: GetUserInfoForOnboardingTabDocument,
                variables: { userId: user.id },
              },
            ]}
            renderButton={(onClick, icon, tooltipContent) => (
              <IconButton
                onClick={onClick}
                icon={icon}
                tooltipProps={{ content: tooltipContent }}
                small
              />
            )}
          />,
        ];
        const tooltipContent = (
          <div>
            <BodyText
              as="div"
              lineHeight="20px"
              fontWeight={BASE_TYPOGRAPHY.FONT_WEIGHTS.BOLD}
            >
              Completed {trainingName} - {tooltipForType(completion.__typename)}
            </BodyText>
            <BodyText as="div" lineHeight="20px">
              Completed on {format(completionDate, TOOLTIP_DATE_FORMAT)}
            </BodyText>
          </div>
        );
        const rowElement = (
          <TaskListItem
            title={`Completed ${trainingName}`}
            completionDate={completionDate}
            dateTooltipProps={{ content: tooltipContent }}
            actionButtons={actionButtons}
          />
        );
        return {
          rowElement,
          completionDate,
          completionYear: getYear(completionDate),
        };
      }),
    [securityTrainingCompletions, id]
  );

  const trainingCompletions = useMemo(
    () =>
      dropNothing(
        trainingRequirements.map((req, i) => {
          if (!isSome(req.completionDate)) {
            return null;
          }
          const categories = getTrainingCategories(req);
          if (categories.length === 0) {
            return null;
          }
          const trainingDetail = `Completed ${
            req.displayName ?? "training"
          } in ${serviceToDisplayName(req.service as Service)}`;
          const categoryDetail = `Completed for ${toCommaSeparatedList(
            categories,
            ListCoordinator.And
          )}`;
          const completionDate = dateStringToDate(req.completionDate);
          const actionButtons = [
            <SecurityTrainingDetail
              key={`training-detail-${i}`}
              completion={req}
              userId={user.id}
              renderButton={(onClick, icon, tooltipContent) => (
                <IconButton
                  onClick={onClick}
                  icon={icon}
                  tooltipProps={{ content: tooltipContent }}
                  small
                />
              )}
            />,
          ];
          const tooltipContent = (
            <div>
              <BodyText
                as="div"
                lineHeight="20px"
                fontWeight={BASE_TYPOGRAPHY.FONT_WEIGHTS.BOLD}
              >
                {categoryDetail}
              </BodyText>
              <BodyText as="div" lineHeight="20px">
                Completed on {format(completionDate, TOOLTIP_DATE_FORMAT)}
              </BodyText>
            </div>
          );
          const rowElement = (
            <TaskListItem
              key={`training-req-${i}`}
              title={trainingDetail}
              completionDate={completionDate}
              dateTooltipProps={{ content: tooltipContent }}
              actionButtons={actionButtons}
            />
          );
          return {
            rowElement,
            completionDate,
            completionYear: getYear(completionDate),
          };
        })
      ),
    [trainingRequirements, id]
  );
  // Custom role tasks
  const customRoleTasks = useMemo(
    () =>
      [
        ...(roleCompletionRecord?.adminTaskCompletions ?? []),
        ...(roleCompletionRecord?.employeeTaskCompletions ?? []),
      ]
        .filter(tc => isSome(tc.completionDate))
        .map(tc => {
          const completionDate = dateStringToDate(tc.completionDate!);
          const rowElement = (
            <CustomTaskItem
              key={`custom-task-${tc.id}`}
              taskCompletion={tc}
              userId={id}
              isUserActive={user.isActive}
              roleCompletionId={roleCompletionRecord!.id}
            />
          );
          return {
            rowElement,
            completionDate,
            completionYear: getYear(completionDate),
          };
        }),
    [roleCompletionRecord, id, isActive]
  );

  const allCompletedTasks: CompletedTaskRow[] = [
    ...backgroundCheckCompletions,
    ...agentInstallCompletions,
    ...policyAcceptances,
    ...satCompletions,
    ...trainingCompletions,
    ...customRoleTasks,
  ].sort(
    (rowA, rowB) =>
      rowB.completionDate.getTime() - rowA.completionDate.getTime()
  );
  if (allCompletedTasks.length === 0) {
    return null;
  }

  const allCompletedTasksByYear = Object.entries(
    groupBy(allCompletedTasks, task => task.completionYear)
  ).sort(([yearA], [yearB]) => yearB.localeCompare(yearA));

  return (
    <OnboardingTasksCard title="Completed Tasks">
      {allCompletedTasksByYear.map(([year, completedTaskList]) => (
        <React.Fragment key={year}>
          <StyledYearContainer>
            <BodyText as="div" lineHeight="16px" color={BASE_PALETTE.SLATE}>
              {year}
            </BodyText>
          </StyledYearContainer>
          {completedTaskList.map(task => task.rowElement)}
        </React.Fragment>
      ))}
    </OnboardingTasksCard>
  );
};

const StyledYearContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 20px;
  height: 40px;

  border-top: 1px solid ${BASE_PALETTE.WIND};
`;
