import type { Maybe } from "../../base/types/maybe";
import { isSome } from "../../base/types/maybe";

export enum UploadedDocumentType {
  AUDIT_REPORT = "audit-report",
  COMPANY_LOGO = "company-logo",
  CUSTOM_SECURITY_AWARENESS_TRAINING_ATTESTATION = "custom-security-awareness-training-attestation",
  CYBRARY_END_USER_SECURITY_AWARENESS_ATTESTATION = "cybrary-end-user-security-awareness-attestation",
  EEST_CYBERSECURITY_AWARENESS_ATTESTATION = "eest-cybersecurity-awareness-attestation",
  EMPLOYEE_TASK = "employeeTask",
  GDPR_CUSTOM_SECURITY_AWARENESS_TRAINING_ATTESTATION = "gdpr-custom-security-awareness-training-attestation",
  GENERAL_SECURITY_AWARENESS_TRAINING_ATTESTATION = "general-security-awareness-training-attestation",
  HIPAA_CUSTOM_SECURITY_AWARENESS_TRAINING_ATTESTATION = "hipaa-custom-security-awareness-training-attestation",
  MANUAL_EVIDENCE = "manual-evidence",
  ORG_CHART = "org-chart",
  PCI_CUSTOM_SECURITY_AWARENESS_TRAINING_ATTESTATION = "pci-custom-security-awareness-training-attestation",
  PEN_TEST = "pen-test",
  POLICY = "policy",
  VAQ_ANSWERS_ID = "vaqAnswersId",
  VENDOR_ASSESSMENT = "vendor-assessment",
  VENDOR_ASSESSMENT_ATTESTATION_OF_COMPLIANCE = "attestation-of-compliance", // PCI specific: https://paper.dropbox.com/doc/PRD-PCI-DSS-V1-pUrf0eDlyufkNlcrSAl2K
  VENDOR_ASSESSMENT_BAA = "vendor-assessment-baa",
  VENDOR_ASSESSMENT_DPA = "vendor-assessment-dpa",
}

export const VALID_TYPES = Object.values(UploadedDocumentType);
const VALID_TYPES_SET = new Set(VALID_TYPES);

export function isUploadedDocumentType(
  type: string
): type is UploadedDocumentType {
  return VALID_TYPES_SET.has(type as unknown as UploadedDocumentType);
}

const PUBLICLY_ACCESSIBLE_UPLOADED_DOC_TYPES_SET = new Set([
  UploadedDocumentType.COMPANY_LOGO,
]);

export function isPubliclyAccessibleUploadedDocumentType(
  type: Maybe<UploadedDocumentType>
) {
  return isSome(type) && PUBLICLY_ACCESSIBLE_UPLOADED_DOC_TYPES_SET.has(type);
}
