import "./EvaluationIcon.scss";

import { Classes, Icon, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { TestOutcome } from "common/base/types/gen";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import React from "react";

import {
  Icon as AlpacaIcon,
  IconNames as AlpacaIconNames,
  Tooltip,
} from "../../alpaca/components";
import { InfoNotAvailable } from "../pages/access/access-components/styles";
import { VantaIcon } from "./VantaIcon";

export type EvaluationEnum = TestOutcome | "Vanta" | "???" | "NEW";

export function booleanToEvaluationEnum(bool: Maybe<boolean>) {
  if (isSome(bool)) {
    if (bool) {
      return TestOutcome.PASS;
    } else {
      return TestOutcome.FAIL;
    }
  }
  return TestOutcome.IN_PROGRESS;
}

interface IProps {
  className?: Maybe<string>;
  evaluation: EvaluationEnum;
  size?: Maybe<"LARGE" | "STANDARD">;
  text?: Maybe<string>;
  maybeTooltipText?: Maybe<string>;
}

interface IState {
  size: number;
}

export class EvaluationIcon extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
    this.state = {
      size:
        !isSome(props.size) || props.size === "STANDARD"
          ? Icon.SIZE_STANDARD
          : Icon.SIZE_LARGE,
    };
  }

  public render() {
    const iconSpan = this.getIconSpan();

    const tooltip = isSome(this.props.maybeTooltipText)
      ? this.props.maybeTooltipText
      : this.props.evaluation === TestOutcome.IN_PROGRESS
      ? "In progress"
      : null;
    if (isSome(tooltip)) {
      return (
        <Tooltip
          content={<span>{tooltip}</span>}
          hoverCloseDelay={200}
          interactionKind="hover"
        >
          {iconSpan}
        </Tooltip>
      );
    }

    return iconSpan;
  }

  private getIconSpan() {
    const maybeClass = !isSome(this.props.className)
      ? ""
      : this.props.className;
    const maybeText = !isSome(this.props.text) ? null : (
      <span>{this.props.text}</span>
    );
    const maybeEvalClass = isSome(this.props.evaluation)
      ? `evaluation-${this.props.evaluation}`
      : "";
    const className = `${maybeClass} evaluation-icon ${maybeEvalClass}`;

    switch (this.props.evaluation) {
      case TestOutcome.PASS:
        return (
          <span className={className}>
            <Icon
              icon={IconNames.TICK_CIRCLE}
              intent={Intent.SUCCESS}
              iconSize={this.state.size}
            />
          </span>
        );
      case TestOutcome.FAIL:
        return (
          <span className={className}>
            <AlpacaIcon
              icon={AlpacaIconNames.DANGER}
              iconSize={this.state.size}
            />
            {maybeText}
          </span>
        );
      case "???":
        return (
          <span className={className}>
            <h5>?</h5>
          </span>
        );
      case TestOutcome.IN_PROGRESS:
        return (
          <span className={`${className} ${Classes.TEXT_MUTED}`}>
            <Icon
              icon={IconNames.TIME}
              intent={Intent.NONE}
              iconSize={this.state.size}
            />
            {maybeText}
          </span>
        );
      case "Vanta":
        return <VantaIcon />;
      case "NEW":
        return (
          <span className={className}>
            <Icon
              icon={IconNames.TIME}
              intent={Intent.WARNING}
              iconSize={this.state.size}
            />
            {maybeText}
          </span>
        );
      case TestOutcome.NA:
      default:
        return <InfoNotAvailable />;
    }
  }
}
