import "./email-subscription.scss";

import { Classes } from "@blueprintjs/core";
import type { UserEmailSubscriptionSetting } from "common/base/types/gen";
import { EmailCategoryForUnsubscribe } from "common/base/types/gen";
import gql from "graphql-tag";
import React from "react";

import { LogError, LogErrorMessage } from "../../../errors";
import { useUserEmailSubscriptionSettingQuery } from "../../../gen/components";
import { FullPageSpinner } from "../../helpers/FullPageSpinner";
import { EmailSubscriptionCategory } from "./email-subscription-category";

export const EmailSubscriptionDetailsView: React.FC = () => {
  const { error, loading, data } = useUserEmailSubscriptionSettingQuery();
  if (error) {
    LogError(error);
    return null;
  }
  if (loading) {
    return <FullPageSpinner />;
  }
  if (!data) {
    LogErrorMessage("Bad fetch");
    return null;
  }

  const dailyEmails: UserEmailSubscriptionSetting[] = [];
  const eventEmails: UserEmailSubscriptionSetting[] = [];
  const weeklyEmails: UserEmailSubscriptionSetting[] = [];
  const overviewEmails: UserEmailSubscriptionSetting[] = [];

  data.userEmailSubscriptionSettings.map(setting => {
    switch (setting.category) {
      case EmailCategoryForUnsubscribe.DAILY:
        dailyEmails.push(setting);
        break;
      case EmailCategoryForUnsubscribe.WEEKLY:
        weeklyEmails.push(setting);
        break;
      case EmailCategoryForUnsubscribe.EVENT:
        eventEmails.push(setting);
        break;
      case EmailCategoryForUnsubscribe.OVERVIEW:
        overviewEmails.push(setting);
        break;
      default:
        LogErrorMessage("Unknown email category");
    }
  });

  return (
    <div className={`${Classes.RUNNING_TEXT} app-container-page`}>
      <EmailSubscriptionCategory
        settings={eventEmails}
        category={EmailCategoryForUnsubscribe.EVENT}
      ></EmailSubscriptionCategory>
      <EmailSubscriptionCategory
        settings={dailyEmails}
        category={EmailCategoryForUnsubscribe.DAILY}
      ></EmailSubscriptionCategory>
      <EmailSubscriptionCategory
        settings={weeklyEmails}
        category={EmailCategoryForUnsubscribe.WEEKLY}
      ></EmailSubscriptionCategory>
      <EmailSubscriptionCategory
        settings={overviewEmails}
        category={EmailCategoryForUnsubscribe.OVERVIEW}
      ></EmailSubscriptionCategory>
    </div>
  );
};

gql`
  query userEmailSubscriptionSetting {
    userEmailSubscriptionSettings {
      id
      description
      friendlyName
      category
      sensitive
      domainId
      userId
      emailKey
      shouldSend
    }
  }
`;
