import { PCI_STANDARDS } from "common/standards/pci";
import { ReportStandard } from "../../../../gen/components";

/**
 * This control what standards we show information for in the standard view.
 * The ordering determines the layout of the cards in the landing page.
 */
const STANDARDS_TO_DISPLAY_FOR_ALL_DOMAINS = [
  ReportStandard.soc2,
  ReportStandard.iso27001,
  ReportStandard.hipaa,
];

// Use this if when you're developing a new standard and don't want it to be visible to all customers.
const STANDARDS_TO_DISPLAY_IF_OPTED_IN = [
  ...PCI_STANDARDS,
  ReportStandard.gdpr,
];

export const STANDARDS_TO_DISPLAY = [
  ...STANDARDS_TO_DISPLAY_FOR_ALL_DOMAINS,
  ...STANDARDS_TO_DISPLAY_IF_OPTED_IN,
];

export const VIEWABLE_STANDARDS_IDS = new Set(STANDARDS_TO_DISPLAY);

export const VIEWABLE_STANDARD_IDS_FOR_ALL_DOMAINS = new Set(
  STANDARDS_TO_DISPLAY_FOR_ALL_DOMAINS
);
