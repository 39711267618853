import type {
  IRadioGroupProps as IBPRadioGroupProps,
  IRadioProps as IBPRadioProps,
} from "@blueprintjs/core";
import {
  RadioGroup as BPRadioGroup,
  Radio as BPRadio,
} from "@blueprintjs/core";
import React from "react";
import styled from "styled-components";
import { BASE_PALETTE, VANTA_COLORS } from "../../base/colors";

type IRadioProps = IBPRadioProps;

const StyledRadio = styled(BPRadio)`
  line-height: 16px;

  &.bp3-control {
    .bp3-control-indicator {
      background-color: ${VANTA_COLORS.BACKGROUND_WHITE};
      border: 1px solid ${BASE_PALETTE.FOG};
      box-shadow: 0px 1px 4px rgba(51, 51, 51, 0.08);
    }

    input:checked ~ .bp3-control-indicator {
      background-color: ${VANTA_COLORS.BACKGROUND_WHITE};
      box-shadow: none;
      background-image: none;
      border: 5px solid ${BASE_PALETTE.GRAPE};
      border-style: solid inset solid solid;

      &::before {
        background-image: none;
      }
    }

    input:focus ~ .bp3-control-indicator {
      box-shadow: 0px 0px 0px 3px rgba(135, 92, 255, 0.16);
    }

    &.bp3-control input:disabled ~ .bp3-control-indicator {
      opacity: 0.5;
    }

    &.bp3-control input:disabled:not(:checked) ~ .bp3-control-indicator {
      background-color: ${VANTA_COLORS.BACKGROUND_WHITE};
    }

    input:not(:disabled):active ~ .bp3-control-indicator {
      background: ${BASE_PALETTE.GRAPE};
      box-shadow: none;
    }
  }

  &:hover {
    .bp3-control-indicator {
      background-color: ${VANTA_COLORS.BACKGROUND_WHITE};
    }

    input:checked ~ .bp3-control-indicator {
      background-color: ${VANTA_COLORS.BACKGROUND_WHITE} !important;
    }
  }
`;

interface AdditionalProps {
  name: string;
  options: IRadioProps[];
}

export type IRadioGroupProps = IBPRadioGroupProps & AdditionalProps;

export const RadioGroup: React.FC<IRadioGroupProps> = ({
  options,
  ...props
}) => {
  const getRadioProps = (childProps: IRadioProps): IRadioProps => {
    return {
      checked: childProps.value === props.selectedValue,
      disabled: childProps.disabled || props.disabled,
      name: props.name,
      onChange: props.onChange,
      ...childProps,
    };
  };

  return (
    <BPRadioGroup {...props}>
      {options.map((childProps, index) => (
        <StyledRadio {...getRadioProps(childProps)} key={index} />
      ))}
    </BPRadioGroup>
  );
};
