import { SpecificResource } from "common/base/types/gen";
import type { SERVICES_WITH_CUSTOMER_CREDENTIALS } from "common/base/types/helpers";
import {
  BACKGROUND_CHECK_SERVICES_WITH_CUSTOMER_CREDENTIALS,
  IDENTITY_PROVIDER_SERVICES,
} from "common/base/types/helpers";
import type { Maybe } from "common/base/types/maybe";

import { Feature } from "../../gen/components";
import { AWSCredentialLinker } from "../pages/credential/aws/aws-credentials";
import { GCP } from "../pages/credential/gcp/gcp-credentials";
import { KnowBe4 } from "../pages/credential/knowbe4/knowbe4";
import { MongoAtlas } from "../pages/credential/mongoatlas/mongoatlas";
import { Okta } from "../pages/credential/okta/okta";
import { Airtable } from "../pages/credential/services/airtable";
import { Azure } from "../pages/credential/services/azure";
import { Bamboohr } from "../pages/credential/services/bamboohr";
import { Certn } from "../pages/credential/services/certn";
import type {
  IFormCredentialProps,
  IOAuthCredentialProps,
} from "../pages/credential/services/common-interface";
import { Datadog } from "../pages/credential/services/datadog";
import { DigitalOceanSpaces } from "../pages/credential/services/digitaloceanspaces";
import { Finch } from "../pages/credential/services/finch";
import { GenericOauth } from "../pages/credential/services/generic-oauth";
import { GithubLinkingInstructions } from "../pages/credential/services/github-linking-instructions";
import { Jamf } from "../pages/credential/services/jamf";
import { Kandji } from "../pages/credential/services/kandji";
import { Linear } from "../pages/credential/services/linear";
import { MicrosoftEndpointManager } from "../pages/credential/services/microsoft-endpoint-manager";
import { OfficeLinkingInstructions } from "../pages/credential/services/office-365-linking";
import { PivotalTracker } from "../pages/credential/services/pivotaltracker";
import { Shortcut } from "../pages/credential/services/shortcut";
import { VersionControlLinkingInstructions } from "../pages/credential/services/version-control-linking";
import { Vetty } from "../pages/credential/services/vetty";
import { Snyk } from "../pages/credential/snyk/snyk";

/**
 * @fileoverview This file contains metadata for Vanta's integrated services
 * so that we can roughly group them by their functionality when calling the
 * user to add credentials or when displaying credential status.
 *
 * These mappings only control grouping and appearance in the UI; they have no
 * impact on how these services' resources are treated in tests.
 *
 * If resources share generic resource kinds, they should probably be grouped
 * together in a ServiceGroup; @see ServiceGroups.
 */
export type LinkableServiceId =
  typeof SERVICES_WITH_CUSTOMER_CREDENTIALS[number];

export type ServiceDetails = {
  id: LinkableServiceId;
  abbreviation?: Maybe<string>;
  displayName: string;
  explanation: string;
  feature?: Maybe<Feature>;
  linkingComponent:
    | React.ComponentType<IOAuthCredentialProps>
    | React.ComponentType<IFormCredentialProps>;
  logo?: Maybe<any>;
  scopableResources: readonly ScopableResourceMetadata[];
};

export type ScopableResourceMetadata = {
  kind: SpecificResource;
  pluralDisplayName: string;
};

const HR_EXPLANATION =
  "Connect to find people and use onboarding/offboarding dates from your HR system";

const MDM_EXPLANATION = "Show that employee computers are configured securely.";

const HR_SERVICE_DETAILS = {
  adp_run: {
    id: "adp_run",
    displayName: "Run Powered by ADP",
    logo: require("url:../../static/images/services/adpIcon.png"),
    explanation: HR_EXPLANATION,
    linkingComponent: Finch,
    scopableResources: [
      {
        kind: SpecificResource.AdpRunHrUser,
        pluralDisplayName: "Run Powered by ADP Employees",
      },
    ],
  },
  adp_workforce_now: {
    id: "adp_workforce_now",
    displayName: "ADP Workforce Now",
    logo: require("url:../../static/images/services/adpWorkforceNow.png"),
    explanation: HR_EXPLANATION,
    linkingComponent: Finch,
    scopableResources: [
      {
        kind: SpecificResource.AdpWorkforceNowHrUser,
        pluralDisplayName: "ADP Workforce Now Employees",
      },
    ],
  },
  bamboo_hr: {
    id: "bamboo_hr",
    displayName: "BambooHR",
    logo: require("url:../../static/images/services/bambooHrIcon.png"),
    explanation: HR_EXPLANATION,
    linkingComponent: Bamboohr,
    scopableResources: [
      {
        kind: SpecificResource.BambooHrHrUser,
        pluralDisplayName: "BambooHR Employees",
      },
    ],
  },
  gusto: {
    id: "gusto",
    displayName: "Gusto",
    logo: require("url:../../static/images/services/gustoIcon.png"),
    explanation: HR_EXPLANATION,
    linkingComponent: GenericOauth,
    scopableResources: [
      {
        kind: SpecificResource.GustoHrUser,
        pluralDisplayName: "Gusto Employees",
      },
    ],
  },
  insperity: {
    id: "insperity",
    displayName: "Insperity",
    logo: require("url:../../static/images/services/insperityIcon.png"),
    explanation: HR_EXPLANATION,
    linkingComponent: Finch,
    scopableResources: [
      {
        kind: SpecificResource.InsperityHrUser,
        pluralDisplayName: "Insperity Employees",
      },
    ],
  },
  justworks: {
    id: "justworks",
    displayName: "Justworks",
    logo: require("url:../../static/images/services/justworksIcon.png"),
    explanation: HR_EXPLANATION,
    linkingComponent: Finch,
    scopableResources: [
      {
        kind: SpecificResource.JustworksHrUser,
        pluralDisplayName: "Justworks Employees",
      },
    ],
  },
  namely: {
    id: "namely",
    displayName: "Namely",
    logo: require("url:../../static/images/services/namelyIcon.png"),
    explanation: HR_EXPLANATION,
    linkingComponent: Finch,
    scopableResources: [
      {
        kind: SpecificResource.NamelyHrUser,
        pluralDisplayName: "Namely Employees",
      },
    ],
  },
  paychex_flex: {
    id: "paychex_flex",
    displayName: "Paychex Flex",
    logo: require("url:../../static/images/services/paychexFlex.png"),
    explanation: HR_EXPLANATION,
    linkingComponent: Finch,
    scopableResources: [
      {
        kind: SpecificResource.PaychexFlexHrUser,
        pluralDisplayName: "Paychex Flex Employees",
      },
    ],
  },
  paycor: {
    id: "paycor",
    displayName: "Paycor",
    logo: require("url:../../static/images/services/paycorIcon.png"),
    explanation: HR_EXPLANATION,
    linkingComponent: Finch,
    scopableResources: [
      {
        kind: SpecificResource.PaycorHrUser,
        pluralDisplayName: "Paycor Employees",
      },
    ],
  },
  paylocity: {
    id: "paylocity",
    displayName: "Paylocity",
    logo: require("url:../../static/images/services/paylocityIcon.png"),
    explanation: HR_EXPLANATION,
    linkingComponent: Finch,
    scopableResources: [
      {
        kind: SpecificResource.PaylocityHrUser,
        pluralDisplayName: "Paylocity Employees",
      },
    ],
  },
  rippling: {
    id: "rippling",
    displayName: "Rippling",
    logo: require("url:../../static/images/services/ripplingIcon.png"),
    explanation: HR_EXPLANATION,
    linkingComponent: GenericOauth,
    scopableResources: [
      {
        kind: SpecificResource.RipplingHrUser,
        pluralDisplayName: "Rippling Employees",
      },
    ],
  },
  quickbooks: {
    id: "quickbooks",
    displayName: "QuickBooks",
    logo: require("url:../../static/images/services/quickbooksIcon.png"),
    explanation: HR_EXPLANATION,
    linkingComponent: GenericOauth,
    scopableResources: [
      {
        kind: SpecificResource.QuickBooksHrUser,
        pluralDisplayName: "QuickBooks Employees",
      },
    ],
  },
  square_payroll: {
    id: "square_payroll",
    displayName: "Square Payroll",
    logo: require("url:../../static/images/services/squareIcon.png"),
    explanation: HR_EXPLANATION,
    linkingComponent: Finch,
    scopableResources: [
      {
        kind: SpecificResource.SquarePayrollHrUser,
        pluralDisplayName: "Square Payroll Employees",
      },
    ],
  },
  trinet: {
    id: "trinet",
    displayName: "TriNet",
    logo: require("url:../../static/images/services/trinetIcon.png"),
    explanation: HR_EXPLANATION,
    linkingComponent: Finch,
    scopableResources: [
      {
        kind: SpecificResource.TrinetHrUser,
        pluralDisplayName: "TriNet Employees",
      },
    ],
  },
  zenefits: {
    id: "zenefits",
    displayName: "Zenefits",
    logo: require("url:../../static/images/services/zenefitsIcon.png"),
    explanation: HR_EXPLANATION,
    linkingComponent: Finch,
    scopableResources: [
      {
        kind: SpecificResource.ZenefitsHrUser,
        pluralDisplayName: "Zenefits Employees",
      },
    ],
  },
} as const;

/**
 * SERVICE_DETAILS includes linking metadata for every service with customer
 * credentials.
 * Feature should specify a BetaFeature (as opposed to a base feature) today. Otherwise
 * this gating may not work properly as the logic does not encode hierarchies between features.
 */
export const SERVICE_DETAILS: {
  [k in LinkableServiceId]: ServiceDetails;
} = {
  ...HR_SERVICE_DETAILS,
  airtable: {
    id: "airtable",
    displayName: "Airtable",
    logo: require("url:../../static/images/services/airtable.png"),
    feature: Feature.BetaAirtable,
    explanation: "Show that your security issues are closed out on time.",
    linkingComponent: Airtable,
    scopableResources: [],
  },
  asana: {
    id: "asana",
    displayName: "Asana",
    logo: require("url:../../static/images/services/asana.png"),
    explanation: "Show that your security issues are closed out on time.",
    linkingComponent: GenericOauth,
    scopableResources: [],
  },
  aws: {
    id: "aws",
    abbreviation: "AWS",
    displayName: "Amazon Web Services",
    logo: require("url:../../static/images/services/aws.png"),
    explanation:
      "Compare your infrastructure set-up to industry standards and best practices.",
    linkingComponent: AWSCredentialLinker,
    scopableResources: [
      {
        kind: SpecificResource.ALB,
        pluralDisplayName: "Application Load Balancers",
      },
      {
        kind: SpecificResource.CloudWatchLogGroup,
        pluralDisplayName: "CloudWatch Log Groups",
      },
      {
        kind: SpecificResource.AwsVPC,
        pluralDisplayName: "Virtual Private Clouds",
      },
      {
        kind: SpecificResource.DynamoDBTable,
        pluralDisplayName: "DynamoDB Tables",
      },
      {
        kind: SpecificResource.EC2Instance,
        pluralDisplayName: "EC2 Instances",
      },
      {
        kind: SpecificResource.ECRContainerRepository,
        pluralDisplayName: "ECR Container Repositories",
      },
      {
        kind: SpecificResource.RDSInstance,
        pluralDisplayName: "RDS Instances",
      },
      { kind: SpecificResource.S3, pluralDisplayName: "S3 Buckets" },
      { kind: SpecificResource.SQS, pluralDisplayName: "SQS Queues" },
      { kind: SpecificResource.AwsAccount, pluralDisplayName: "IAM Users" },
    ],
  },
  azure: {
    id: "azure",
    abbreviation: "Azure",
    displayName: "Azure",
    logo: require("url:../../static/images/services/azure.png"),
    explanation:
      "Compare your infrastructure set-up to industry standards and best practices.",
    linkingComponent: Azure,
    scopableResources: [
      {
        kind: SpecificResource.AzureVirtualMachineScaleSet,
        pluralDisplayName: "Virtual Machine Scale Sets",
      },
      {
        kind: SpecificResource.AzureVirtualMachine,
        pluralDisplayName: "Virtual Machines",
      },
      {
        kind: SpecificResource.AzureBlobContainer,
        pluralDisplayName: "Blob Containers",
      },
      {
        kind: SpecificResource.AzureCosmosDB,
        pluralDisplayName: "Cosmos Databases",
      },
      {
        kind: SpecificResource.AzureApplicationGateway,
        pluralDisplayName: "Application Gateways",
      },
      {
        kind: SpecificResource.AzureLoadBalancer,
        pluralDisplayName: "Load Balancers",
      },
      {
        kind: SpecificResource.AzureDatabaseForExternalSQLVariant,
        pluralDisplayName: "Azure Databases for MariaDB, MySQL, and PostgreSQL",
      },
      {
        kind: SpecificResource.AzureSecurityGroup,
        pluralDisplayName: "Network Security Groups",
      },
      {
        kind: SpecificResource.AzureSQLDatabase,
        pluralDisplayName: "SQL Databases",
      },
      {
        kind: SpecificResource.AzureSQLManagedInstance,
        pluralDisplayName: "SQL Managed Instances",
      },
      {
        kind: SpecificResource.AzureSQLServerOnVMs,
        pluralDisplayName: "SQL Servers on Virtual Machines",
      },
      {
        kind: SpecificResource.AzureSynapseWarehouse,
        pluralDisplayName: "Synapse Warehouses",
      },
      { kind: SpecificResource.AzureQueue, pluralDisplayName: "Queues" },
    ],
  },
  azuredevops: {
    id: "azuredevops",
    displayName: "Azure DevOps",
    logo: require("url:../../static/images/services/azure-devops.png"),
    explanation:
      "Demonstrate the appropriate personnel have access to your \
      repositories, code changes are subject to review, branches are protected and pull requests are well formed.",
    linkingComponent: VersionControlLinkingInstructions,
    scopableResources: [
      {
        kind: SpecificResource.AzureDevOpsRepo,
        pluralDisplayName: "Azure DevOps Repositories",
      },
    ],
  },
  bitbucket: {
    id: "bitbucket",
    displayName: "Bitbucket",
    logo: require("url:../../static/images/services/bitbucket.png"),
    explanation:
      "Demonstrate the appropriate personnel have access to your \
      repositories, code changes are subject to review, branches are protected and pull requests are well formed.",
    linkingComponent: VersionControlLinkingInstructions,
    scopableResources: [
      {
        kind: SpecificResource.BitbucketRepo,
        pluralDisplayName: "Bitbucket Repositories",
      },
    ],
  },
  certn: {
    id: "certn",
    displayName: "Certn",
    logo: require("url:../../static/images/services/certn.png"),
    explanation: "Ensure that your employees are background checked.",
    linkingComponent: Certn,
    scopableResources: [],
  },
  clickup: {
    id: "clickup",
    displayName: "ClickUp",
    logo: require("url:../../static/images/services/clickup.png"),
    explanation: "Show that your security issues are closed out on time.",
    linkingComponent: GenericOauth,
    scopableResources: [],
  },
  clubhouse: {
    id: "clubhouse",
    displayName: "Shortcut",
    logo: require("url:../../static/images/services/shortcut.png"),
    explanation: "Show that your security issues are closed out on time.",
    linkingComponent: Shortcut,
    scopableResources: [],
  },
  customercheckr: {
    id: "customercheckr",
    displayName: "Checkr",
    logo: require("url:../../static/images/services/checkr.png"),
    explanation: "Ensure that your employees are background checked.",
    linkingComponent: GenericOauth,
    scopableResources: [],
  },
  datadog: {
    id: "datadog",
    displayName: "Datadog",
    logo: require("url:../../static/images/services/datadog.png"),
    explanation: "Demonstrate that your system is monitored.",
    linkingComponent: Datadog,
    scopableResources: [],
  },
  digitalocean: {
    id: "digitalocean",
    displayName: "DigitalOcean",
    logo: require("url:../../static/images/services/digitalocean.png"),
    explanation:
      "Compare your infrastructure set-up to industry standards and best practices.",
    linkingComponent: GenericOauth,
    scopableResources: [
      {
        kind: SpecificResource.DigitalOceanApp,
        pluralDisplayName: "DigitalOcean Apps",
      },
      {
        kind: SpecificResource.DigitalOceanContainerRepository,
        pluralDisplayName: "DigitalOcean Container Repositories",
      },
      {
        kind: SpecificResource.DigitalOceanDroplet,
        pluralDisplayName: "DigitalOcean Droplets",
      },
      {
        kind: SpecificResource.DigitalOceanLoadBalancer,
        pluralDisplayName: "DigitalOcean Load Balancers",
      },
      {
        kind: SpecificResource.DigitalOceanRedisCluster,
        pluralDisplayName: "DigitalOcean Redis Clusters",
      },
      {
        kind: SpecificResource.DigitalOceanRelationalDbCluster,
        pluralDisplayName: "DigitalOcean Relational Database Clusters",
      },
    ],
  },
  digitaloceanspaces: {
    id: "digitaloceanspaces",
    displayName: "DigitalOcean Spaces",
    logo: require("url:../../static/images/services/digitalocean.png"),
    explanation:
      "Compare your object storage set-up to industry standards and best practices.",
    linkingComponent: DigitalOceanSpaces,
    scopableResources: [
      {
        kind: SpecificResource.DigitalOceanSpace,
        pluralDisplayName: "DigitalOcean Spaces",
      },
    ],
  },
  github: {
    id: "github",
    displayName: "GitHub",
    logo: require("url:../../static/images/services/github.png"),
    explanation:
      "Verify your GitHub organization and prove branches are protected, pull requests are well-formed, and \
    security issues closed in a timely manner.",
    linkingComponent: GithubLinkingInstructions,
    scopableResources: [
      {
        kind: SpecificResource.GithubRepo,
        pluralDisplayName: "GitHub Repositories",
      },
    ],
  },
  gitlab: {
    id: "gitlab",
    displayName: "GitLab",
    logo: require("url:../../static/images/services/gitlab.png"),
    explanation:
      "Demonstrate the appropriate personnel have access to your \
    repositories, code changes are subject to review, branches are protected and pull requests are well formed.",
    linkingComponent: VersionControlLinkingInstructions,
    scopableResources: [
      {
        kind: SpecificResource.GitlabRepo,
        pluralDisplayName: "GitLab Repositories",
      },
    ],
  },
  gcp: {
    id: "gcp",
    abbreviation: "GCP",
    displayName: "Google Cloud Platform",
    logo: require("url:../../static/images/services/gcp.png"),
    explanation:
      "Compare your infrastructure set-up to industry standards and best practices.",
    linkingComponent: GCP,
    scopableResources: [
      {
        kind: SpecificResource.BigQueryDataset,
        pluralDisplayName: "BigQuery Datasets",
      },
      {
        kind: SpecificResource.BigtableInstance,
        pluralDisplayName: "Bigtable Instances",
      },
      {
        kind: SpecificResource.CloudSQLInstance,
        pluralDisplayName: "Cloud SQL Instances",
      },
      {
        kind: SpecificResource.DatastoreProject,
        pluralDisplayName: "Datastore Projects",
      },
      {
        kind: SpecificResource.FirestoreProject,
        pluralDisplayName: "Firestore Projects",
      },
      {
        kind: SpecificResource.GCPComputeInstance,
        pluralDisplayName: "Compute Instances",
      },
      {
        kind: SpecificResource.GCPContainerRepository,
        pluralDisplayName: "GCP Container Repositories",
      },
      {
        kind: SpecificResource.SpannerInstance,
        pluralDisplayName: "Cloud Spanner Instances",
      },
      {
        kind: SpecificResource.GCPStorageBucket,
        pluralDisplayName: "Cloud Storage Buckets",
      },
      {
        kind: SpecificResource.GCPSubscription,
        pluralDisplayName: "Cloud Subscriptions",
      },
    ],
  },
  gsuiteadmin: {
    id: "gsuiteadmin",
    displayName: "Google Workspace",
    logo: require("url:../../static/images/services/gsuite.png"),
    explanation: "View your employee list from the Google Workspace Admin SDK.",
    linkingComponent: GenericOauth,
    scopableResources: [
      {
        kind: SpecificResource.GsuiteUser,
        pluralDisplayName: "Google Workspace Users",
      },
    ],
  },
  heroku: {
    id: "heroku",
    displayName: "Heroku",
    logo: require("url:../../static/images/services/heroku.png"),
    explanation:
      "Compare your infrastructure set-up to industry standards and best practices.",
    linkingComponent: GenericOauth,
    scopableResources: [
      { kind: SpecificResource.HerokuApp, pluralDisplayName: "Heroku Apps" },
    ],
  },
  jamf: {
    id: "jamf",
    displayName: "Jamf Pro",
    logo: require("url:../../static/images/services/jamf.png"),
    explanation: MDM_EXPLANATION,
    linkingComponent: Jamf,
    scopableResources: [
      {
        kind: SpecificResource.JamfManagedComputer,
        pluralDisplayName: "Computers",
      },
    ],
  },
  jira: {
    id: "jira",
    displayName: "Jira",
    logo: require("url:../../static/images/services/jira.png"),
    explanation: "Show that your security issues are closed out on time.",
    linkingComponent: GenericOauth,
    scopableResources: [],
  },
  kandji: {
    id: "kandji",
    displayName: "Kandji",
    logo: require("url:../../static/images/services/kandjiIcon.png"),
    explanation: MDM_EXPLANATION,
    linkingComponent: Kandji,
    scopableResources: [
      {
        kind: SpecificResource.KandjiManagedComputer,
        pluralDisplayName: "Computers",
      },
    ],
  },
  knowbe4: {
    id: "knowbe4",
    displayName: "KnowBe4",
    logo: require("url:../../static/images/services/knowbe4.png"),
    explanation:
      "Show that your employees have completed security awareness training",
    linkingComponent: KnowBe4,
    feature: Feature.BetaKnowBe4,
    scopableResources: [],
  },
  linear: {
    id: "linear",
    displayName: "Linear",
    logo: require("url:../../static/images/services/linear.png"),
    explanation: "Show that your security issues are closed out on time.",
    linkingComponent: Linear,
    scopableResources: [],
  },
  microsoft_endpoint_manager: {
    id: "microsoft_endpoint_manager",
    displayName: "Microsoft Endpoint Manager",
    logo: require("url:../../static/images/services/azure.png"),
    explanation: MDM_EXPLANATION,
    linkingComponent: MicrosoftEndpointManager,
    scopableResources: [
      {
        kind: SpecificResource.MicrosoftEndpointManagerManagedComputer,
        pluralDisplayName: "Computers",
      },
    ],
  },
  mongoatlas: {
    id: "mongoatlas",
    displayName: "MongoDB Atlas",
    logo: require("url:../../static/images/services/mongodbatlas.png"),
    explanation: "Ensure that your databases are set up securely",
    linkingComponent: MongoAtlas,
    scopableResources: [
      {
        kind: SpecificResource.AtlasCluster,
        pluralDisplayName: "MongoDB Atlas Clusters",
      },
    ],
  },
  office: {
    id: "office",
    displayName: "Office 365",
    logo: require("url:../../static/images/services/office.png"),
    explanation: "View your employee lists using Office 365.",
    linkingComponent: OfficeLinkingInstructions,
    scopableResources: [
      {
        kind: SpecificResource.OfficeUser,
        pluralDisplayName: "Office 365 Users",
      },
    ],
  },
  okta: {
    id: "okta",
    displayName: "Okta",
    logo: require("url:../../static/images/services/okta.png"),
    explanation: "View your employee lists using Okta.",
    linkingComponent: Okta,
    scopableResources: [
      { kind: SpecificResource.OktaUser, pluralDisplayName: "Okta Users" },
    ],
  },
  pivotaltracker: {
    id: "pivotaltracker",
    displayName: "Pivotal Tracker",
    logo: require("url:../../static/images/services/pivotaltracker.png"),
    explanation: "Show that your security issues are closed out on time.",
    linkingComponent: PivotalTracker,
    scopableResources: [],
  },
  slack: {
    id: "slack",
    displayName: "Slack",
    logo: require("url:../../static/images/services/slack.png"),
    explanation:
      "Review your Slack team and team members settings and list all installed bots.",
    linkingComponent: GenericOauth,
    scopableResources: [
      {
        kind: SpecificResource.SlackAccount,
        pluralDisplayName: "Slack Accounts",
      },
    ],
  },
  snyk: {
    id: "snyk",
    displayName: "Snyk",
    logo: require("url:../../static/images/services/snyk.png"),
    explanation: "Show that security vulnerabilities are remediated on time.",
    linkingComponent: Snyk,
    scopableResources: [
      {
        kind: SpecificResource.SnykProject,
        pluralDisplayName: "Snyk Projects",
      },
    ],
  },
  trello: {
    id: "trello",
    displayName: "Trello",
    logo: require("url:../../static/images/services/trello.jpg"),
    explanation: "Show that your security issues are closed out on time.",
    linkingComponent: GenericOauth,
    scopableResources: [],
  },
  vetty: {
    id: "vetty",
    displayName: "Vetty",
    logo: require("url:../../static/images/services/vetty.png"),
    explanation: "Ensure that your employees are background checked.",
    linkingComponent: Vetty,
    scopableResources: [],
  },
} as const;

export enum ServiceGroupName {
  BackgroundCheckServices = "Background check services",
  CloudProviders = "Cloud providers",
  DatastoreProviders = "Datastore providers",
  HRProviders = "Human Resource Information Systems",
  IdentityProviders = "Identity providers",
  MessagingApplications = "Messaging applications",
  MobileDeviceManagementTools = "Mobile device management tools",
  MonitoringServices = "Monitoring services",
  SecurityAwarenessTraining = "Security awareness training providers",
  TaskTrackers = "Task trackers",
  VersionControlSystems = "Version control systems",
  VulnerabilityScanners = "Vulnerability scanners",
}

export type ServiceGroupData = {
  name: ServiceGroupName;
  services: ServiceDetails[];
  feature?: Maybe<Feature>;
};

/**
 * ServiceGroups groups functionally-similar services with credentials for
 * grouped display. A service may appear in multiple service groups (e.g.
 * GitHub appears both among the version control systems and among the cloud
 * providers).
 */
export const ServiceGroups: ServiceGroupData[] = [
  {
    name: ServiceGroupName.CloudProviders,
    services: [
      SERVICE_DETAILS.aws,
      SERVICE_DETAILS.gcp,
      SERVICE_DETAILS.azure,
      SERVICE_DETAILS.heroku,
      SERVICE_DETAILS.digitalocean,
      SERVICE_DETAILS.digitaloceanspaces,
    ],
  },
  {
    name: ServiceGroupName.IdentityProviders,
    services: IDENTITY_PROVIDER_SERVICES.map(
      service => SERVICE_DETAILS[service]
    ),
  },
  {
    name: ServiceGroupName.VersionControlSystems,
    services: [
      SERVICE_DETAILS.github,
      SERVICE_DETAILS.bitbucket,
      SERVICE_DETAILS.gitlab,
      SERVICE_DETAILS.azuredevops,
    ],
  },
  {
    name: ServiceGroupName.TaskTrackers,
    services: [
      SERVICE_DETAILS.airtable,
      SERVICE_DETAILS.asana,
      SERVICE_DETAILS.clubhouse,
      SERVICE_DETAILS.clickup,
      SERVICE_DETAILS.jira,
      SERVICE_DETAILS.linear,
      SERVICE_DETAILS.pivotaltracker,
      SERVICE_DETAILS.trello,
    ],
  },
  {
    name: ServiceGroupName.HRProviders,
    services: Object.values(HR_SERVICE_DETAILS),
  },
  {
    name: ServiceGroupName.DatastoreProviders,
    services: [SERVICE_DETAILS.mongoatlas],
  },
  {
    name: ServiceGroupName.MobileDeviceManagementTools,
    services: [
      SERVICE_DETAILS.jamf,
      SERVICE_DETAILS.kandji,
      SERVICE_DETAILS.microsoft_endpoint_manager,
    ],
  },
  {
    name: ServiceGroupName.VulnerabilityScanners,
    services: [SERVICE_DETAILS.snyk],
  },
  {
    name: ServiceGroupName.BackgroundCheckServices,
    services: BACKGROUND_CHECK_SERVICES_WITH_CUSTOMER_CREDENTIALS.map(
      service => SERVICE_DETAILS[service]
    ),
  },
  {
    name: ServiceGroupName.SecurityAwarenessTraining,
    services: [SERVICE_DETAILS.knowbe4],
  },
  {
    name: ServiceGroupName.MonitoringServices,
    services: [SERVICE_DETAILS.datadog],
  },
  {
    name: ServiceGroupName.MessagingApplications,
    services: [SERVICE_DETAILS.slack],
  },
];
