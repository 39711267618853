import React from "react";

import { DefaultLink } from "../../../../helpers/links";
import { SERVICE_DETAILS } from "../../../credentials/service-groups";
import { SimpleCredentialFormDialogBody } from "../simple-credential-form-dialog-body";
import type { IFormCredentialProps } from "./common-interface";

export const Vetty: React.FunctionComponent<IFormCredentialProps> = ({
  onCredentialsLinked,
}) => {
  const helperText = (
    <div>
      <span>
        Create a Vetty API Key in your Vetty{" "}
        <DefaultLink href="https://client.vetty.co/client/settings/myaccount">
          dashboard
        </DefaultLink>
        .
        <br />
        For IP address, use the following IP: <strong>34.227.127.165/32</strong>
      </span>
    </div>
  );
  return (
    <SimpleCredentialFormDialogBody
      labels={[{ name: "token", displayName: "API Token", helperText }]}
      service={SERVICE_DETAILS.vetty}
      onCredentialsLinked={onCredentialsLinked}
      shouldTestCredentials={true}
      buttonLabel={"Validate and store"}
    />
  );
};
