import type { Maybe } from "common/base/types/maybe";
import moment from "moment";
import React from "react";
import styled from "styled-components";

import { BASE_PALETTE } from "../../../../alpaca/base/colors";
import { textForDuration } from "../utils";

interface IProps {
  date: Date;
}

export const Deadline: React.FC<IProps> = ({ date }) => {
  const dateAsMoment = moment(date);
  const now = moment();
  const isPassed = dateAsMoment.isBefore(now);
  const duration = isPassed ? now.diff(dateAsMoment) : dateAsMoment.diff(now);
  return (
    <Container isPassed={isPassed}>
      {textForDuration(duration)}
      {isPassed ? " ago" : ""}
    </Container>
  );
};

const Container = styled.div<{ isPassed?: Maybe<boolean> }>`
  font-weight: 600;
  ${({ isPassed }) =>
    isPassed
      ? `
  color: ${BASE_PALETTE.TOMATO};
  `
      : ""}
`;
