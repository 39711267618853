import { Classes, Divider, Intent, Spinner } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { dropNothing, isSome, nothing } from "common/base/types/maybe";
import {
  getValueForReservedVantaAttribute,
  ReservedVantaAttributes,
} from "common/utils/vantaAttributes";
import gql from "graphql-tag";
import React, { useEffect, useState } from "react";

import { Button, TextLinkLike } from "../../../../alpaca/components";
import { LogError } from "../../../../errors";
import type {
  GetTrainingsQuery,
  PollLatestResourceFetchesQuery,
} from "../../../../gen/components";
import {
  FetchStatus,
  AllLinkedCredentialsDocument,
  GetTrainingsDocument,
  useGetTrainingsQuery,
  usePollLatestResourceFetchesQuery,
  useSetTrainingTypesMutation,
} from "../../../../gen/components";
import { TrainingLabeller } from "./training-labeller";

export type Campaign = NonNullable<
  GetTrainingsQuery["organization"]
>["resources"]["edges"][number];

export type CategoryLabels = {
  isGeneral: boolean;
  isHipaa: boolean;
  isPci: boolean;
};

export type CampaignCollapseState = {
  campaign: Maybe<Campaign>;
  categories: CategoryLabels;
};

function getStartState(queryData: GetTrainingsQuery) {
  const startAccordions: CampaignCollapseState[] = dropNothing(
    queryData.organization.resources.edges.map(e => {
      const generalAttr = getValueForReservedVantaAttribute(
        ReservedVantaAttributes.isGeneralSat,
        e.node.vantaAttributes ?? []
      );
      const hipaaAttr = getValueForReservedVantaAttribute(
        ReservedVantaAttributes.isHipaaSat,
        e.node.vantaAttributes ?? []
      );
      const pciAttr = getValueForReservedVantaAttribute(
        ReservedVantaAttributes.isPciSat,
        e.node.vantaAttributes ?? []
      );
      if (isSome(generalAttr) || isSome(hipaaAttr) || isSome(pciAttr)) {
        return {
          campaign: e,
          categories: {
            isGeneral: generalAttr ?? false,
            isHipaa: hipaaAttr ?? false,
            isPci: pciAttr ?? false,
          },
        };
      } else {
        return nothing;
      }
    })
  );
  if (startAccordions.length === 0) {
    startAccordions.push({
      campaign: null,
      categories: {
        isGeneral: false,
        isHipaa: false,
        isPci: false,
      },
    });
  }

  return startAccordions;
}

const TrainingSelector: React.FC<{
  onSave: () => void;
  onBack: () => void;
}> = props => {
  const { loading, error, data } = useGetTrainingsQuery();
  const [setTrainingTypes, { loading: setTrainingsLoading }] =
    useSetTrainingTypesMutation({
      refetchQueries: [{ query: GetTrainingsDocument }],
    });
  const [accordionData, setAccordionData] = useState<CampaignCollapseState[]>(
    []
  );
  const [openIndex, setOpenIndex] = useState(0);

  useEffect(() => {
    if (isSome(data)) {
      setAccordionData(getStartState(data));
    }
  }, [data]);

  if (loading) {
    return <Spinner />;
  }
  if (error) {
    LogError(error);
    return null;
  }
  if (!isSome(data)) {
    LogError(new Error("No data for KnowBe4 training selector"));
    return null;
  }

  const updateAccordions = (
    idx: number,
    currentSelection: Maybe<Campaign>,
    categories: CategoryLabels
  ) => {
    const newAccordionData = accordionData;
    newAccordionData[idx] = {
      campaign: currentSelection,
      categories,
    };
    setAccordionData(newAccordionData);
  };

  const addAccordion = () => {
    const newAccordionData = [
      ...accordionData,
      {
        campaign: null,
        categories: { isGeneral: false, isHipaa: false, isPci: false },
      },
    ];
    setAccordionData(newAccordionData);
    setOpenIndex(newAccordionData.length - 1);
  };

  const deleteAccordion = (idx: number) => {
    const newAccordionData = accordionData.filter((_a, i) => i !== idx);
    setAccordionData(newAccordionData);
    if (openIndex === idx) {
      setOpenIndex(0);
    }
  };

  const currentSelections = new Set<string>(
    dropNothing(
      accordionData.map(accordion => accordion.campaign?.node.uniqueId)
    )
  );

  const addAnother =
    accordionData.length < data.organization.resources.totalCount ? (
      <TextLinkLike onClick={addAccordion}>+ Add another campaign</TextLinkLike>
    ) : null;

  return (
    <>
      <div className={Classes.DIALOG_BODY}>
        {accordionData.map((accordion, idx) => (
          <React.Fragment
            key={`training-label-${idx}-${accordion.campaign?.node.uniqueId}`}
          >
            <TrainingLabeller
              isOpen={openIndex === idx}
              onToggleExpand={(isOpen: boolean) =>
                setOpenIndex(isOpen ? -1 : idx)
              }
              onDelete={() => deleteAccordion(idx)}
              campaigns={data.organization.resources.edges}
              currentSelections={currentSelections}
              updateCampaignLabels={(campaign, categories) =>
                updateAccordions(idx, campaign, categories)
              }
              startState={accordion}
            />
            <Divider />
          </React.Fragment>
        ))}
        {addAnother}
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button intent={Intent.NONE} onClick={props.onBack}>
            Back
          </Button>
          <Button
            intent={Intent.PRIMARY}
            onClick={async () => {
              const generalSatIds: string[] = dropNothing(
                accordionData
                  .filter(accordion => accordion.categories.isGeneral)
                  .map(accordion => accordion.campaign?.node.uniqueId)
              );
              const hipaaSatIds: string[] = dropNothing(
                accordionData
                  .filter(accordion => accordion.categories.isHipaa)
                  .map(accordion => accordion.campaign?.node.uniqueId)
              );
              const pciSatIds: string[] = dropNothing(
                accordionData
                  .filter(accordion => accordion.categories.isPci)
                  .map(accordion => accordion.campaign?.node.uniqueId)
              );

              await setTrainingTypes({
                variables: {
                  input: {
                    generalSatIds,
                    hipaaSatIds,
                    pciSatIds,
                  },
                },
                // refetch so that the credentials page knows there's a credential now
                refetchQueries: [{ query: AllLinkedCredentialsDocument }],
              });
              props.onSave();
            }}
            loading={setTrainingsLoading}
          >
            Save
          </Button>
        </div>
      </div>
    </>
  );
};

export const IdTrainings: React.FC<{
  pollStart: Date;
  onFail: () => void;
  onSave: () => void;
  onBack: () => void;
}> = props => {
  const [waitingForFetch, setWaitingForFetch] = useState(true);
  const { data: fetchData, stopPolling } = usePollLatestResourceFetchesQuery({
    variables: { specificKinds: "KnowBe4TrainingCampaign" },
    pollInterval: 1000,
    onError: e => LogError(e),
  });

  const fetchStatus = checkFetchStatus(fetchData, props.pollStart);

  useEffect(() => {
    if (
      fetchStatus === FetchStatus.SUCCEEDED ||
      fetchStatus === FetchStatus.FAILED
    ) {
      stopPolling();
      setWaitingForFetch(false);
      if (fetchStatus !== FetchStatus.SUCCEEDED) {
        props.onFail();
      }
    }
  }, [fetchStatus]);

  return waitingForFetch ? (
    <Spinner />
  ) : (
    <TrainingSelector onSave={props.onSave} onBack={props.onBack} />
  );
};

function checkFetchStatus(
  maybeData: Maybe<PollLatestResourceFetchesQuery>,
  credentialLastUpdated: Date
): Maybe<FetchStatus> {
  if (!isSome(maybeData?.latestResourceFetches)) {
    // No data from query; assume unfetched while loading.
    return nothing;
  }
  const fetches = maybeData!.latestResourceFetches;
  const completedFetches = fetches.filter(
    f => isSome(f) && new Date(f.createdAt) > credentialLastUpdated
  );
  return completedFetches[0]?.status;
}

gql`
  query getTrainings {
    organization {
      id
      resources(
        specificResourceType: KnowBe4TrainingCampaign
        first: 100 # arbitrary limit
        options: { includeOutOfScope: false }
      ) {
        totalCount
        pageInfo {
          hasNextPage
        }
        edges {
          node {
            id
            uniqueId
            displayName
            vantaAttributes {
              key
              value
              managedExternally
            }
          }
        }
      }
    }
  }
`;

gql`
  mutation setTrainingTypes($input: SetKnowBe4TrainingTypesInput!) {
    setKnowBe4TrainingTypes(input: $input) {
      ... on SetKnowBe4TrainingTypesSuccess {
        __typename
      }
      ... on BaseUserError {
        message
      }
    }
  }
`;
