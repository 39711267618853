import { Icon, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import type { Maybe } from "common/base/types/maybe";
import { isSome, nothing } from "common/base/types/maybe";
import moment from "moment";
import React from "react";
import styled from "styled-components";
import { Tooltip } from "../../../../alpaca/components";

import { getVulnCountTags } from "../common/components";
import { VulnerabilityPageEntry } from "../common/vulnerability-page-entry";
import { InspectorRunStatus } from "./common";

const IconContainer = styled.div`
  margin-right: 12px;
`;

const NoInspectorScansTag: React.FC = () => (
  <IconContainer>
    <Tooltip
      content={
        "This EC2 instance hasn't been scanned for vulnerabilities recently. Click to view more details."
      }
    >
      <Icon
        icon={IconNames.WARNING_SIGN}
        intent={Intent.WARNING}
        iconSize={Icon.SIZE_LARGE}
      />
    </Tooltip>
  </IconContainer>
);

interface IProps {
  primaryId?: Maybe<string>;
  secondaryId?: Maybe<string>;
  vulnerabilityCounts: {
    low: number;
    medium: number;
    high: number;
  };
  inspectorStatus: InspectorRunStatus;
  slaDeadline?: Maybe<Date>;
  lastAssessmentRanDateString?: Maybe<string>;
  handleClick: Function;
}

const makeSubheadingString = (
  secondaryId: Maybe<string>,
  lastAssessmentRanDate: Maybe<string>
) => {
  // Don't let row collapse if we don't have these values
  if (!isSome(secondaryId) && !isSome(lastAssessmentRanDate)) {
    return "\u00A0"; // &nbsp
  }
  const infos = [];
  if (isSome(secondaryId)) {
    infos.push(secondaryId);
  }
  if (isSome(lastAssessmentRanDate)) {
    infos.push(lastAssessmentRanDate);
  }
  return infos.length > 0 ? infos.join(" - ") : "";
};

export const InstanceEntry: React.FC<IProps> = ({
  primaryId,
  secondaryId,
  vulnerabilityCounts,
  slaDeadline,
  lastAssessmentRanDateString,
  inspectorStatus,
  handleClick,
}) => {
  const tags = [];

  let vulnCountTags = [];
  switch (inspectorStatus) {
    case InspectorRunStatus.RECENT:
    case InspectorRunStatus.STALE:
      vulnCountTags = getVulnCountTags(
        vulnerabilityCounts,
        inspectorStatus === InspectorRunStatus.STALE
      );
      break;
    case InspectorRunStatus.NONE:
    default:
      vulnCountTags = [<NoInspectorScansTag key="noInspectorScans" />];
      break;
  }
  tags.push(...vulnCountTags);

  const lastAssessmentRan =
    inspectorStatus === InspectorRunStatus.RECENT
      ? `last assessment ran ${moment(lastAssessmentRanDateString!).fromNow()}`
      : nothing;

  const secondaryInfo = makeSubheadingString(secondaryId, lastAssessmentRan);

  return (
    <VulnerabilityPageEntry
      primaryInfo={primaryId}
      secondaryInfo={secondaryInfo}
      slaDeadline={slaDeadline}
      handleClick={handleClick}
      tags={tags}
    />
  );
};
