import type { Maybe } from "common/base/types/maybe";
import { dropNothing, applyIfSome } from "common/base/types/maybe";
import React from "react";
import styled from "styled-components";
import { BASE_PALETTE } from "../../base/colors";
import { Input } from "../forms/input";
import { IconNames } from "../icon/iconNames";
import { SpacedList } from "../primitives/spaced-list";

interface ITableControlsProps {
  leftControls?: Maybe<JSX.Element[]>;
  searchOptions?: Maybe<{
    placeholder?: Maybe<string>;
    value: string;
    onNewValue(newValue: string): void;
  }>;
}

export const TableControls: React.FC<ITableControlsProps> = ({
  searchOptions,
  leftControls,
}) => {
  const leftControlsArray = dropNothing([
    applyIfSome(searchOptions, ({ value, onNewValue, placeholder }) => (
      <Input
        key="alpaca-th-search"
        value={value}
        onChange={e => onNewValue(e.currentTarget.value)}
        placeholder={placeholder ?? "Search"}
        icon={IconNames.SEARCH}
      />
    )),
    ...(leftControls ?? []),
  ]);

  if (leftControlsArray.length === 0) {
    return null;
  }

  return (
    <StyledDiv>{maybeWrapInSpacedList(leftControlsArray, <div />)}</StyledDiv>
  );

  function maybeWrapInSpacedList(
    elements: JSX.Element[],
    emptyDefault: Maybe<JSX.Element> = null
  ) {
    if (elements.length === 0) {
      return emptyDefault;
    } else {
      return elements.length === 1 ? (
        elements
      ) : (
        <SpacedList>{elements}</SpacedList>
      );
    }
  }
};

const StyledDiv = styled.div`
  padding: 20px 24px;
  display: flex;
  align-items: center;
  border-top: 1px solid ${BASE_PALETTE.WIND};
  justify-content: space-between;
`;
