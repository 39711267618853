import { AnchorButton, Classes } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import type { IdentityProviderService } from "common/base/types/helpers";
import { IDENTITY_PROVIDER_SERVICES_SET } from "common/base/types/helpers";
import React from "react";

import { IdentityProviderDisclaimer } from "../identity-provider-disclaimer";
import type { IOAuthCredentialProps } from "./common-interface";

export const GenericOauth: React.FunctionComponent<IOAuthCredentialProps> = ({
  service,
}) => {
  const url = `/auth/login/${service.id}`;
  const isIdentityProviderService = IDENTITY_PROVIDER_SERVICES_SET.has(
    service.id
  );
  const additionalContent = isIdentityProviderService ? (
    <IdentityProviderDisclaimer
      newProvider={service.id as IdentityProviderService}
    />
  ) : null;

  const displayName = service.displayName;
  return (
    <div>
      <div className={Classes.DIALOG_HEADER}>Link {displayName}</div>
      <div className={Classes.DIALOG_BODY}>
        {additionalContent}
        <p>
          {displayName} uses OAuth to authorize Vanta to monitor your account.
        </p>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <AnchorButton href={url} icon={IconNames.LOG_IN}>
            Connect {displayName}
          </AnchorButton>
        </div>
      </div>
    </div>
  );
};
