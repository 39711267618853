import React from "react";
import { useHistory } from "react-router";

import { DefaultLink } from "../../../../helpers/links";
import { SERVICE_DETAILS } from "../../../credentials/service-groups";
import type {
  InputLabel,
  OptionLabel,
} from "../simple-credential-form-dialog-body";
import { SimpleCredentialFormDialogBody } from "../simple-credential-form-dialog-body";
import type { IFormCredentialProps } from "./common-interface";

export const DigitalOceanSpaces: React.FunctionComponent<IFormCredentialProps> =
  ({ onCredentialsLinked }) => {
    const history = useHistory();
    const accessKeyHelper = (
      <span>
        Create a Spaces Access Key on the{" "}
        <DefaultLink href="https://cloud.digitalocean.com/account/access/tokens">
          Access page in the control panel.
        </DefaultLink>
      </span>
    );

    const labels: Array<InputLabel | OptionLabel> = [
      {
        name: "accessKeyId",
        displayName: "Access key ID",
      },
      {
        name: "accessKeySecret",
        displayName: "Access key secret",
        helperText: accessKeyHelper,
      },
    ];
    return (
      <SimpleCredentialFormDialogBody
        labels={labels}
        onCredentialsLinked={() => {
          onCredentialsLinked();
          history.push(`/connections?service=digitaloceanspaces`);
        }}
        shouldTestCredentials={true}
        service={SERVICE_DETAILS.digitaloceanspaces}
      />
    );
  };
