import type {
  SpecificBigQueryDatasetResource,
  SpecificBigtableInstanceResource,
  SpecificCloudSqlInstanceResource,
  SpecificDatastoreProjectResource,
  SpecificFirestoreProjectResource,
  SpecificSpannerInstanceResource,
} from "common/base/types/gen";
import gql from "graphql-tag";

import type { TableParameters } from "../helpers";
import { booleanToEvaluationIcon } from "../helpers";
import { SHARED_DATABASE_TABLE_HEADERS } from "./helpers";

export const GCP_DBS = [
  "cloudSQLInstances",
  "spannerInstances",
  "bigtableInstances",
  "datastoreProjects",
  "firestoreProjects",
  "bigQueryDatasets",
] as const;

export const gcpDataTableParameters: { [k: string]: TableParameters } = {
  cloudSQLInstances: {
    displayName: "CloudSQL Instances",
    columnOrder: ["name", "backupsEnabled"],
    header: {
      name: SHARED_DATABASE_TABLE_HEADERS.name,
      backupsEnabled: SHARED_DATABASE_TABLE_HEADERS.backups,
    },
    createRow: (instance: Partial<SpecificCloudSqlInstanceResource>) => {
      return {
        name: instance.name,
        backupsEnabled: booleanToEvaluationIcon(instance.backupsEnabled),
      };
    },
  },
  spannerInstances: {
    displayName: "Spanner Instances",
    columnOrder: ["name"],
    header: {
      name: SHARED_DATABASE_TABLE_HEADERS.name,
    },
    createRow: (instance: Partial<SpecificSpannerInstanceResource>) => {
      return {
        name: instance.name,
      };
    },
  },
  bigtableInstances: {
    displayName: "BigTable Instances",
    columnOrder: ["name", "encrypted"],
    header: {
      name: SHARED_DATABASE_TABLE_HEADERS.name,
      encrypted: SHARED_DATABASE_TABLE_HEADERS.encrypted,
    },
    createRow: (instance: Partial<SpecificBigtableInstanceResource>) => {
      return {
        name: instance.name,
        encrypted: booleanToEvaluationIcon(true),
      };
    },
  },
  datastoreProjects: {
    displayName: "Datastore Projects",
    columnOrder: ["name", "encrypted"],
    header: {
      name: SHARED_DATABASE_TABLE_HEADERS.name,
      encrypted: SHARED_DATABASE_TABLE_HEADERS.encrypted,
    },
    createRow: (instance: Partial<SpecificDatastoreProjectResource>) => {
      return {
        name: instance.name,
        encrypted: booleanToEvaluationIcon(true),
      };
    },
  },
  firestoreProjects: {
    displayName: "Firestore Projects",
    columnOrder: ["name", "encrypted"],
    header: {
      name: SHARED_DATABASE_TABLE_HEADERS.name,
      encrypted: SHARED_DATABASE_TABLE_HEADERS.encrypted,
    },
    createRow: (instance: Partial<SpecificFirestoreProjectResource>) => {
      return {
        name: instance.name,
        encrypted: booleanToEvaluationIcon(true),
      };
    },
  },
  bigQueryDatasets: {
    displayName: "BigQuery Datasets",
    columnOrder: ["name"],
    header: {
      name: SHARED_DATABASE_TABLE_HEADERS.name,
    },
    createRow: (instance: Partial<SpecificBigQueryDatasetResource>) => {
      return {
        name: instance.name,
      };
    },
  },
};

gql`
  query fetchGCPDBInfo {
    organization {
      id
      displayName
      cloudSQLInstances: resources(
        first: 1000
        specificResourceType: CloudSQLInstance
      ) {
        totalCount
        edges {
          node {
            id
            ... on SpecificCloudSQLInstanceResource {
              name
              backupsEnabled
            }
          }
        }
      }
      spannerInstances: resources(
        first: 1000
        specificResourceType: SpannerInstance
      ) {
        totalCount
        edges {
          node {
            id
            ... on SpecificSpannerInstanceResource {
              name
            }
          }
        }
      }
      bigtableInstances: resources(
        first: 1000
        specificResourceType: BigtableInstance
      ) {
        totalCount
        edges {
          node {
            id
            ... on SpecificBigtableInstanceResource {
              name
            }
          }
        }
      }
      datastoreProjects: resources(
        first: 1000
        specificResourceType: DatastoreProject
      ) {
        totalCount
        edges {
          node {
            id
            ... on SpecificDatastoreProjectResource {
              name
            }
          }
        }
      }
      firestoreProjects: resources(
        first: 1000
        specificResourceType: FirestoreProject
      ) {
        totalCount
        edges {
          node {
            id
            ... on SpecificFirestoreProjectResource {
              name
            }
          }
        }
      }
      bigQueryDatasets: resources(
        first: 1000
        specificResourceType: BigQueryDataset
      ) {
        totalCount
        edges {
          node {
            id
            ... on SpecificBigQueryDatasetResource {
              name
            }
          }
        }
      }
    }
  }
`;
