import { IconSize, Intent, Spinner } from "@blueprintjs/core";
import classnames from "classnames";
import type { Maybe } from "common/base/types/maybe";
import { applyIfSome, isSome } from "common/base/types/maybe";
import React from "react";
import styled from "styled-components";

import { GRID_SPACING } from "../../base/grid";
import { Icon } from "../icon/icon";
import type { IconName } from "../icon/iconNames";
import { SpacedList } from "../primitives/spaced-list";
import { BUTTON_CLASS_STYLES, ButtonClasses } from "./styles";

export type ButtonIntent =
  | typeof Intent.PRIMARY
  | typeof Intent.DANGER
  | typeof Intent.NONE;

interface IBaseButtonProps {
  className?: Maybe<string>;
  /**
   * Props that control content of the button.
   */
  text?: Maybe<React.ReactNode>;
  icon?: Maybe<IconName>;
  rightIcon?: Maybe<IconName>;
  loading?: Maybe<boolean>;
  // Displays a number on the right of the button. Useful for filtering.
  number?: Maybe<number>;

  /**
   * Props that control styling of the button.
   */
  intent?: Maybe<ButtonIntent>;
  large?: Maybe<boolean>;
  small?: Maybe<boolean>;
  disabled?: Maybe<boolean>;
}

function getClassNamesFromProps(props: IBaseButtonProps) {
  return classnames(props.className, ButtonClasses.BUTTON, {
    [ButtonClasses.LARGE]: props.large,
    [ButtonClasses.SMALL]: props.small,
    [ButtonClasses.PRIMARY]: props.intent === Intent.PRIMARY,
    [ButtonClasses.DANGER]: props.intent === Intent.DANGER,
    [ButtonClasses.DISABLED]: props.disabled,
    [ButtonClasses.LOADING]: props.loading,
  });
}

function renderContentFromProps(
  props: IBaseButtonProps,
  children: React.ReactNode
): React.ReactNode {
  const { text, icon, intent, rightIcon, loading, number } = props;
  return (
    <>
      {Boolean(loading) ? (
        <Spinner
          key="loading"
          className={ButtonClasses.SPINNER}
          size={IconSize.STANDARD}
        />
      ) : null}
      <SpacedList marginOverride={GRID_SPACING}>
        {applyIfSome(icon, iconName => (
          <Icon key="leftIcon" className={ButtonClasses.ICON} icon={iconName} />
        ))}
        {isSome(text) || isSome(children) ? (
          <span key="text" className={ButtonClasses.TEXT}>
            {text}
            {children}
          </span>
        ) : null}
        {applyIfSome(rightIcon, iconName => (
          <Icon
            key="rightIcon"
            className={ButtonClasses.ICON}
            icon={iconName}
          />
        ))}
        {
          // Can only use the number prop with buttons without intent
          !isSome(intent) || intent === Intent.NONE
            ? applyIfSome(number, num => (
                <div key="number" className={ButtonClasses.NUMBER}>
                  {num}
                </div>
              ))
            : null
        }
      </SpacedList>
    </>
  );
}

// Omit the fields whose types conflict with their type in IBaseButtonProps.
type HTMLButtonProps = Omit<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  "className" | "disabled"
>;
export type IButton2Props = IBaseButtonProps & HTMLButtonProps;

export const Button2: React.FC<IButton2Props> = ({ children, ...props }) => (
  <StyledButton
    {...props}
    className={getClassNamesFromProps(props)}
    disabled={props.disabled ?? undefined}
  >
    {renderContentFromProps(props, children)}
  </StyledButton>
);

const StyledButton = styled.button`
  ${BUTTON_CLASS_STYLES}
`;

export type IAnchorButton2Props = IBaseButtonProps &
  React.AnchorHTMLAttributes<HTMLAnchorElement>;

export const AnchorButton2: React.FC<IAnchorButton2Props> = ({
  children,
  ...props
}) => (
  <StyledAnchorButton
    {...props}
    className={getClassNamesFromProps(props)}
    href={Boolean(props.disabled) ? undefined : props.href}
  >
    {renderContentFromProps(props, children)}
  </StyledAnchorButton>
);

const StyledAnchorButton = styled.a`
  ${BUTTON_CLASS_STYLES}
`;
