import { Button, HTMLSelect, Intent, MenuItem } from "@blueprintjs/core";
import { Suggest } from "@blueprintjs/select";
import { SERVICES } from "common/base/types/helpers";
import type { Maybe } from "common/base/types/maybe";
import { isSome, nothing } from "common/base/types/maybe";
import gql from "graphql-tag";
import React, { useState } from "react";

import { LogError } from "../../errors";
import {
  useGetTestRunnerTestIdsForServiceLazyQuery,
  useGetTestRunnerTestIdsQuery,
  useTriggerTestRunsMutation,
} from "../../gen/components";
import { AppToaster } from "../../helpers/toaster";
import { FullPageSpinner } from "../helpers/FullPageSpinner";
import { Container, FlexContainer } from "./trigger-resource-fetch-panel";

interface IProps {
  domainId: string;
}

const UNSELECTED_SERVICE = "__internal-unselected";

export const RunTestPanel: React.FC<IProps> = ({ domainId }) => {
  const { loading, data } = useGetTestRunnerTestIdsQuery();
  const [
    getTestRunnerTestIdsForService,
    { loading: testIdsLoading, data: testIdsForServiceData },
  ] = useGetTestRunnerTestIdsForServiceLazyQuery();

  const [testRunInFlight, setTestRunInFlight] = useState(false);
  const [selectedTestId, setSelectedTestId] = useState<Maybe<string>>(nothing);
  const [selectedServiceId, setSelectedServiceId] =
    useState(UNSELECTED_SERVICE);
  const [triggerTestRuns] = useTriggerTestRunsMutation({
    onCompleted() {
      const message = testIdsForServiceData
        ? `${testIdsForServiceData?.testRunnerTestIds.length} tests related to ${selectedServiceId} queued`
        : `test ${selectedTestId} queued`;
      AppToaster.show({
        message,
        intent: Intent.SUCCESS,
      });

      setTestRunInFlight(false);
      setSelectedTestId(nothing);
      setSelectedServiceId(UNSELECTED_SERVICE);
    },
    onError: LogError,
  });
  if (loading || !data) {
    return <FullPageSpinner />;
  }
  return (
    <Container>
      <h2>Run a test</h2>
      <FlexContainer>
        <Suggest
          items={data.testRunnerTestIds}
          selectedItem={selectedTestId}
          onItemSelect={i => setSelectedTestId(i)}
          itemRenderer={(i, { modifiers, handleClick }) => (
            <MenuItem
              key={i}
              text={i}
              active={modifiers.active}
              onClick={handleClick}
            />
          )}
          inputValueRenderer={i => i}
          itemPredicate={(query, item) => {
            const norm = (s: string) => s.replace("-", "").toLowerCase();
            return norm(item).includes(norm(query));
          }}
          popoverProps={{ minimal: true }}
          inputProps={{ placeholder: "Select a test ID" }}
          noResults={<div>No matching test IDs.</div>}
        />
        <Button
          disabled={!isSome(selectedTestId)}
          loading={testRunInFlight}
          intent={Intent.PRIMARY}
          onClick={async () => {
            setTestRunInFlight(true);
            await triggerTestRuns({
              variables: { domainId, testIds: [selectedTestId!] },
            });
          }}
        >
          Run test
        </Button>
      </FlexContainer>
      <h2>Run all tests associated with service</h2>
      <FlexContainer>
        <HTMLSelect
          value={selectedServiceId}
          onChange={e => {
            setSelectedServiceId(e.target.value);
            getTestRunnerTestIdsForService({
              variables: { serviceId: e.target.value },
            });
          }}
        >
          <option value={UNSELECTED_SERVICE}>Select a service id</option>
          {SERVICES.map(serviceId => (
            <option value={serviceId} key={serviceId}>
              {serviceId}
            </option>
          ))}
        </HTMLSelect>
        <Button
          disabled={selectedServiceId === UNSELECTED_SERVICE || testIdsLoading}
          loading={testRunInFlight}
          intent={Intent.PRIMARY}
          onClick={async () => {
            if (testIdsForServiceData) {
              setTestRunInFlight(true);
              await triggerTestRuns({
                variables: {
                  domainId,
                  testIds: testIdsForServiceData.testRunnerTestIds,
                },
              });
            }
          }}
        >
          Run tests
        </Button>
      </FlexContainer>
      {testIdsForServiceData ? (
        <div>
          <strong>Tests that will be run:</strong>
          {testIdsForServiceData?.testRunnerTestIds.map(testId => (
            <p key={testId}>{testId}</p>
          ))}
        </div>
      ) : (
        <strong>Select a service to view tests that will be run for it</strong>
      )}
    </Container>
  );
};

gql`
  query getTestRunnerTestIds {
    testRunnerTestIds
  }
`;

gql`
  query getTestRunnerTestIdsForService($serviceId: String!) {
    testRunnerTestIds(serviceId: $serviceId)
  }
`;

gql`
  mutation triggerTestRuns($domainId: ID!, $testIds: [String!]!) {
    triggerTestRuns(domainId: $domainId, testIds: $testIds)
  }
`;
