{
  "standardInfo": {
    "standard": "iso27001"
  },
  "controls": [
    {
      "id": "A.10.1.1",
      "name": "Policy on the use of cryptographic controls",
      "description": "A policy on the use of cryptographic controls for protection of information shall be developed and implemented.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-cryptography-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "A.10.1.2",
      "name": "Key management",
      "description": "A policy on the use, protection and lifetime of cryptographic keys shall be developed and implemented through their whole lifecycle.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-cryptography-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "A.11.1.1",
      "name": "Physical security perimeter",
      "description": "Security perimeters shall be defined and used to protect areas that contain either sensitive or critical information and information process facilities.",
      "categories": ["Physical"],
      "tests": [
        {
          "id": "approved-physical-security-policy-bsi-exists"
        },
        {
          "id": "approved-third-party-management-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "A.11.1.2",
      "name": "Physical entry controls",
      "description": "Secure areas shall be protected by appropriate entry controls to ensure that only authorized personnel are allowed access.",
      "categories": ["Physical"],
      "tests": [
        {
          "id": "approved-physical-security-policy-bsi-exists"
        },
        {
          "id": "approved-third-party-management-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "office-visitor-log"
        }
      ]
    },
    {
      "id": "A.11.1.3",
      "name": "Securing offices, rooms and facilities",
      "description": "Physical security for offices, rooms, and facilities shall be designed and applied.",
      "categories": ["Physical"],
      "tests": [
        {
          "id": "approved-physical-security-policy-bsi-exists"
        },
        {
          "id": "approved-third-party-management-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "A.11.1.4",
      "name": "Protecting against external and environmental threats",
      "description": "Physical protection against natural disasters, malicious attack, or accidents shall be designed and applied.",
      "categories": ["Physical"],
      "tests": [
        {
          "id": "approved-physical-security-policy-bsi-exists"
        },
        {
          "id": "approved-third-party-management-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "A.11.1.5",
      "name": "Working in secure areas",
      "description": "Procedures for working in secure areas shall be designed and applied.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-physical-security-policy-bsi-exists"
        },
        {
          "id": "approved-third-party-management-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "A.11.1.6",
      "name": "Delivery and loading areas",
      "description": "Access points such as delivery and loading areas and other points where unauthorized persons could enter the premises shall be controlled and, if possible, isolated from information processing facilities to avoid unauthorized access.",
      "categories": ["Physical"],
      "tests": [
        {
          "id": "approved-physical-security-policy-bsi-exists"
        },
        {
          "id": "approved-third-party-management-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "iso-cert-infra"
        }
      ]
    },
    {
      "id": "A.11.2.1",
      "name": "Equipment siting and protection",
      "description": "Equipment shall be sited and protected to reduce the risks from environmental threats and hazards, and opportunities for unauthorized access.",
      "categories": ["Physical"],
      "tests": [
        {
          "id": "approved-physical-security-policy-bsi-exists"
        },
        {
          "id": "approved-third-party-management-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "iso-cert-infra"
        }
      ]
    },
    {
      "id": "A.11.2.2",
      "name": "Supporting utilities",
      "description": "Equipment shall be protected from power failures and other disruptions caused by failures in supporting utilities.",
      "categories": ["Physical"],
      "tests": [
        {
          "id": "approved-physical-security-policy-bsi-exists"
        },
        {
          "id": "approved-third-party-management-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "iso-cert-infra"
        }
      ]
    },
    {
      "id": "A.11.2.3",
      "name": "Cabling security",
      "description": "Power and telecommunications cabling carrying data or supporting information servers shall be protected from interception, interference, or damage.",
      "categories": ["Physical"],
      "tests": [
        {
          "id": "approved-physical-security-policy-bsi-exists"
        },
        {
          "id": "approved-third-party-management-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "iso-cert-infra"
        }
      ]
    },
    {
      "id": "A.11.2.4",
      "name": "Equipment maintenance",
      "description": "Equipment shall be correctly maintained to ensure its continued availability and integrity.",
      "categories": ["Physical"],
      "tests": [
        {
          "id": "approved-physical-security-policy-bsi-exists"
        },
        {
          "id": "approved-third-party-management-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "iso-cert-infra"
        }
      ]
    },
    {
      "id": "A.11.2.5",
      "name": "Removal of assets",
      "description": "Equipment, information or software shall not be taken off-site without prior authorization.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-asset-management-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "iso-cert-infra"
        }
      ]
    },
    {
      "id": "A.11.2.6",
      "name": "Security of equipment and assets off-premises",
      "description": "Security shall be applied to off-site assets taking into account the different risks of working outside the organization’s premises.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-asset-management-policy-bsi-exists"
        },
        {
          "id": "approved-information-security-policy-bsi-exists"
        },
        {
          "id": "full-disk-encryption-records"
        },
        {
          "id": "full-disk-encryption-records-jamf"
        },
        {
          "id": "full-disk-encryption-records-kandji"
        },
        {
          "id": "full-disk-encryption-records-microsoft-endpoint-manager"
        },
        {
          "id": "high-severity-vendor-compliance-reports"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "iso-cert-infra"
        }
      ]
    },
    {
      "id": "A.11.2.7",
      "name": "Secure disposal or re-use of equipment",
      "description": "All items of equipment containing storage media shall be verified to ensure that any sensitive data and licensed software has been removed or securely overwritten prior to disposal or re-use.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-asset-management-policy-bsi-exists"
        },
        {
          "id": "approved-data-management-policy-bsi-exists"
        },
        {
          "id": "high-severity-vendor-compliance-reports"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "iso-cert-infra"
        },
        {
          "id": "media-disposal"
        }
      ]
    },
    {
      "id": "A.11.2.8",
      "name": "Unattended user equipment",
      "description": "Users shall ensure that unattended equipment has appropriate protection.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "agent-macos-workstation-screenlock-enabled"
        },
        {
          "id": "agent-windows-workstation-screenlock-enabled"
        },
        {
          "id": "approved-asset-management-policy-bsi-exists"
        },
        {
          "id": "approved-data-management-policy-bsi-exists"
        },
        {
          "id": "approved-information-security-policy-bsi-exists"
        },
        {
          "id": "full-disk-encryption-records"
        },
        {
          "id": "full-disk-encryption-records-jamf"
        },
        {
          "id": "full-disk-encryption-records-kandji"
        },
        {
          "id": "screenlock-configured-jamf"
        },
        {
          "id": "screenlock-configured-kandji"
        },
        {
          "id": "screenlock-configured-microsoft-endpoint-manager"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "A.11.2.9",
      "name": "Clear desk and clear screen policy",
      "description": "A clear desk policy for papers and removable storage media and a clear screen policy for information processing facilities shall be adopted.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "agent-macos-workstation-screenlock-enabled"
        },
        {
          "id": "agent-windows-workstation-screenlock-enabled"
        },
        {
          "id": "approved-information-security-policy-bsi-exists"
        },
        {
          "id": "approved-physical-security-policy-bsi-exists"
        },
        {
          "id": "screenlock-configured-jamf"
        },
        {
          "id": "screenlock-configured-kandji"
        },
        {
          "id": "screenlock-configured-microsoft-endpoint-manager"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "application-session-timeout"
        }
      ]
    },
    {
      "id": "A.12.1.1",
      "name": "Documented operating procedures",
      "description": "Operating procedures shall be documented and made available to all users who need them.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-operations-security-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "eng-operating-procedures"
        }
      ]
    },
    {
      "id": "A.12.1.2",
      "name": "Change management",
      "description": "Changes to the organization, business processes, information processing facilities and systems that affect information security shall be controlled.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-operations-security-policy-bsi-exists"
        },
        {
          "id": "code-review-application-config"
        },
        {
          "id": "code-review-template-present-azuredevops"
        },
        {
          "id": "code-review-template-present-gitlab"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "change-management-procedures"
        }
      ]
    },
    {
      "id": "A.12.1.3",
      "name": "Capacity management",
      "description": "The use of resources shall be monitored, tuned and projections made of future capacity requirements to ensure the required system performance.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-operations-security-policy-bsi-exists"
        },
        {
          "id": "bigtable-cluster-monitored-and-alarmed-config-cpu-gcp"
        },
        {
          "id": "bigtable-cluster-monitored-and-alarmed-config-storage-gcp"
        },
        {
          "id": "load-balancers-monitored-and-alarmed-config-healthy"
        },
        {
          "id": "load-balancers-monitored-and-alarmed-config-healthy-azure"
        },
        {
          "id": "load-balancers-monitored-and-alarmed-config-latency"
        },
        {
          "id": "load-balancers-monitored-and-alarmed-config-latency-azure"
        },
        {
          "id": "load-balancers-monitored-and-alarmed-config-servererrors"
        },
        {
          "id": "load-balancers-monitored-and-alarmed-config-servererrors-azure"
        },
        {
          "id": "mysql-database-monitored-and-alarmed-config-cpu"
        },
        {
          "id": "mysql-database-monitored-and-alarmed-config-cpu-gcp"
        },
        {
          "id": "mysql-database-monitored-and-alarmed-config-free-memory"
        },
        {
          "id": "mysql-database-monitored-and-alarmed-config-free-memory-gcp"
        },
        {
          "id": "mysql-database-monitored-and-alarmed-config-io"
        },
        {
          "id": "mysql-database-monitored-and-alarmed-config-io-gcp"
        },
        {
          "id": "mysql-database-monitored-and-alarmed-config-storage-space"
        },
        {
          "id": "mysql-database-monitored-and-alarmed-config-storage-space-gcp"
        },
        {
          "id": "nosql-database-monitored-and-alarmed-config-read"
        },
        {
          "id": "nosql-database-monitored-and-alarmed-config-write"
        },
        {
          "id": "nosql-datastore-monitored-and-alarmed-config-read-gcp"
        },
        {
          "id": "nosql-datastore-monitored-and-alarmed-config-write-gcp"
        },
        {
          "id": "nosql-firestore-monitored-and-alarmed-config-read-gcp"
        },
        {
          "id": "nosql-firestore-monitored-and-alarmed-config-write-gcp"
        },
        {
          "id": "pubsub-subscriptions-monitored-and-alarmed-config-age"
        },
        {
          "id": "servers-monitored-and-alarmed-config-cpu"
        },
        {
          "id": "servers-monitored-and-alarmed-config-cpu-azure"
        },
        {
          "id": "servers-monitored-and-alarmed-config-cpu-azure-vm-scaleset"
        },
        {
          "id": "servers-monitored-and-alarmed-config-cpu-gcp"
        },
        {
          "id": "sql-database-monitored-and-alarmed-config-cpu-azure"
        },
        {
          "id": "sql-database-monitored-and-alarmed-config-free-memory-azure"
        },
        {
          "id": "sql-database-monitored-and-alarmed-config-io-azure"
        },
        {
          "id": "sql-database-monitored-and-alarmed-config-storage-space-azure"
        },
        {
          "id": "sqs-queues-monitored-and-alarmed-config-age"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "capacity-increase-request"
        },
        {
          "id": "capacity-monitoring"
        }
      ]
    },
    {
      "id": "A.12.1.4",
      "name": "Separation of development, testing and operational environments",
      "description": "Development, testing, and operational environments shall be separated to reduce the risks of unauthorized access or changes to the operational environment.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-operations-security-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "network-diagram"
        }
      ]
    },
    {
      "id": "A.12.2.1",
      "name": "Controls against malware",
      "description": "Detection, prevention and recovery controls to protect against malware shall be implemented, combined with appropriate user awareness.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-operations-security-policy-bsi-exists"
        },
        {
          "id": "malware-detection-workstations"
        },
        {
          "id": "malware-detection-workstations-jamf"
        },
        {
          "id": "malware-detection-workstations-kandji"
        },
        {
          "id": "malware-detection-workstations-microsoft-endpoint-manager"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "A.12.3.1",
      "name": "Information backup",
      "description": "Backup copies of information, software and system images shall be taken and tested regularly in accordance with an agreed backup policy.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-operations-security-policy-bsi-exists"
        },
        {
          "id": "aws-dynamodb-pitr-backups"
        },
        {
          "id": "azure-cosmosdb-backups"
        },
        {
          "id": "daily-app-database-backups-config-digitalocean"
        },
        {
          "id": "daily-database-backups-config"
        },
        {
          "id": "daily-database-backups-config-azure"
        },
        {
          "id": "daily-database-backups-config-digitalocean"
        },
        {
          "id": "daily-database-backups-config-gcp"
        },
        {
          "id": "daily-database-backups-config-heroku"
        },
        {
          "id": "daily-database-backups-policy"
        },
        {
          "id": "mongo-atlas-backups"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "data-restore-test"
        },
        {
          "id": "disaster-recovery-tabletop"
        }
      ]
    },
    {
      "id": "A.12.4.1",
      "name": "Event logging",
      "description": "Event logs recording user activities, exceptions, faults and information security events shall be produced, kept and regularly reviewed.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-operations-security-policy-bsi-exists"
        },
        {
          "id": "flow-logs-on-azure"
        },
        {
          "id": "flow-logs-on-config"
        },
        {
          "id": "flow-logs-on-config-gcp"
        },
        {
          "id": "logging-bucket-permissions"
        },
        {
          "id": "logs-centrally-stored-authorization"
        },
        {
          "id": "logs-centrally-stored-authorization-gcp"
        },
        {
          "id": "logs-centrally-stored-config"
        },
        {
          "id": "logs-centrally-stored-config-gcp"
        },
        {
          "id": "logs-retained-for-twelve-months-config"
        },
        {
          "id": "logs-retained-for-twelve-months-config-gcp"
        },
        {
          "id": "logs-sink-destinations-tracked-gcp"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "log-dashboard"
        }
      ]
    },
    {
      "id": "A.12.4.2",
      "name": "Protection of log information",
      "description": "Logging facilities and log information shall be protected against tampering and unauthorized access.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "activity-log-blob-container-has-logging-azure"
        },
        {
          "id": "activity-log-blob-containers-are-versioned-azure"
        },
        {
          "id": "activity-log-blob-containers-have-soft-delete-config-azure"
        },
        {
          "id": "approved-operations-security-policy-bsi-exists"
        },
        {
          "id": "logging-buckets-have-MFA-delete-config"
        },
        {
          "id": "logs-centrally-stored-authorization"
        },
        {
          "id": "logs-centrally-stored-authorization-gcp"
        },
        {
          "id": "logs-centrally-stored-config"
        },
        {
          "id": "logs-centrally-stored-config-gcp"
        },
        {
          "id": "logs-sink-destinations-authorization-gcp"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "log-dashboard"
        }
      ]
    },
    {
      "id": "A.12.4.3",
      "name": "Administrator and operator logs",
      "description": "System administrator and system operator activities shall be logged and the logs protected and regularly reviewed.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "activity-log-blob-container-has-logging-azure"
        },
        {
          "id": "activity-log-blob-containers-are-versioned-azure"
        },
        {
          "id": "activity-log-blob-containers-have-soft-delete-config-azure"
        },
        {
          "id": "approved-operations-security-policy-bsi-exists"
        },
        {
          "id": "infra-activity-tracked-config"
        },
        {
          "id": "infra-activity-tracked-config-azure"
        },
        {
          "id": "infra-activity-tracked-config-heroku"
        },
        {
          "id": "logging-buckets-have-MFA-delete-config"
        },
        {
          "id": "logs-centrally-stored-authorization"
        },
        {
          "id": "logs-centrally-stored-authorization-gcp"
        },
        {
          "id": "logs-centrally-stored-config"
        },
        {
          "id": "logs-centrally-stored-config-gcp"
        },
        {
          "id": "logs-sink-destinations-tracked-gcp"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "A.12.4.4",
      "name": "Clock synchronization",
      "description": "The clocks of all relevant information processing systems within an organization or security domain shall be synchronized to a single reference time source.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-operations-security-policy-bsi-exists"
        },
        {
          "id": "infrastructure-timesync-config"
        },
        {
          "id": "infrastructure-timesync-config-heroku"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "A.12.5.1",
      "name": "Installation of software on operational systems",
      "description": "Procedures shall be implemented to control the installation of software on operational systems.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-information-security-policy-bsi-exists"
        },
        {
          "id": "approved-operations-security-policy-bsi-exists"
        },
        {
          "id": "change-management-policy-policies"
        },
        {
          "id": "employees-accepted-change-management-policy"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "A.12.6.1",
      "name": "Management of technical vulnerabilities",
      "description": "Information about technical vulnerabilities of information systems being used shall be obtained in a timely fashion, the organization’s exposure to such vulnerabilities evaluated and appropriate measures taken to address the associated risk.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-operations-security-policy-bsi-exists"
        },
        {
          "id": "approved-vulnerability-management-policy-exists"
        },
        {
          "id": "container-packages-checked-for-vulnerabilities-records-closed-aws"
        },
        {
          "id": "container-packages-checked-for-vulnerabilities-records-closed-azure"
        },
        {
          "id": "container-packages-checked-for-vulnerabilities-records-closed-gcp"
        },
        {
          "id": "employees-accepted-vulnerability-management-policy"
        },
        {
          "id": "infra-packages-checked-for-vulnerabilities-records-closed"
        },
        {
          "id": "packages-checked-for-vulnerabilities-records-closed-snyk"
        },
        {
          "id": "penetration-tests-records"
        },
        {
          "id": "security-vulnerabilities-monitored-laptops"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "pen-test-remediation"
        },
        {
          "id": "vulnerabilities-remediated-sample"
        },
        {
          "id": "vulnerability-scan"
        }
      ]
    },
    {
      "id": "A.12.6.2",
      "name": "Restrictions on software installation",
      "description": "Rules governing the installation of software by users shall be established and implemented.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-information-security-policy-bsi-exists"
        },
        {
          "id": "approved-operations-security-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "A.12.7.1",
      "name": "Information systems audit controls",
      "description": "Audit requirements and activities involving verification of operational systems shall be carefully planned and agreed to minimize disruptions to business processes.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-operations-security-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "A.13.1.1",
      "name": "Network controls",
      "description": "Networks shall be managed and controlled to protect information in systems and applications.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-operations-security-policy-bsi-exists"
        },
        {
          "id": "aws-ec2instances-ports-restricted"
        },
        {
          "id": "digitalocean-droplets-firewalls-restrict-port-22"
        },
        {
          "id": "digitalocean-droplets-firewalls-used"
        },
        {
          "id": "digitalocean-firewalls-ports-restricted"
        },
        {
          "id": "firewall-config-acl"
        },
        {
          "id": "firewall-config-acl-gcp"
        },
        {
          "id": "firewall-config-acl-heroku"
        },
        {
          "id": "firewall-config-default-deny"
        },
        {
          "id": "firewall-config-default-deny-gcp"
        },
        {
          "id": "firewall-config-default-deny-heroku"
        },
        {
          "id": "firewall-config-server"
        },
        {
          "id": "firewall-config-server-heroku"
        },
        {
          "id": "firewall-endpoint-policies"
        },
        {
          "id": "flow-logs-on-azure"
        },
        {
          "id": "flow-logs-on-config"
        },
        {
          "id": "flow-logs-on-config-gcp"
        },
        {
          "id": "gcp-computeinstance-ports-restricted"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "network-diagram"
        },
        {
          "id": "vulnerability-scan"
        }
      ]
    },
    {
      "id": "A.13.1.2",
      "name": "Security of network services",
      "description": "Security mechanisms, service levels and management requirements of all network services shall be identified and included in network services agreements, whether these services are provided in-house or outsourced.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-third-party-management-policy-bsi-exists"
        },
        {
          "id": "load-balancer-http-to-https"
        },
        {
          "id": "load-balancer-http-to-https-azure"
        },
        {
          "id": "load-balancer-http-to-https-digitalocean"
        },
        {
          "id": "ssl-good-ciphers"
        },
        {
          "id": "ssl-no-warnings"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "cloud-service-provider-agreements"
        },
        {
          "id": "network-service-provider-agreements"
        }
      ]
    },
    {
      "id": "A.13.1.3",
      "name": "Segregation in networks",
      "description": "Groups of information services, users and information systems shall be segregated on networks.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-operations-security-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "network-diagram"
        },
        {
          "id": "network-segregation"
        }
      ]
    },
    {
      "id": "A.13.2.1",
      "name": "Information transfer policies",
      "description": "Formal transfer policies, procedures and controls shall be in place to protect the transfer of information through the use of all types of communication facilities.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-cryptography-policy-bsi-exists"
        },
        {
          "id": "approved-third-party-management-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "A.13.2.2",
      "name": "Agreements on information transfer",
      "description": "Agreements shall address the secure transfer of business information between the organization and external parties.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-cryptography-policy-bsi-exists"
        },
        {
          "id": "approved-third-party-management-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "cloud-service-provider-agreements"
        },
        {
          "id": "msa-template"
        },
        {
          "id": "terms-of-service"
        }
      ]
    },
    {
      "id": "A.13.2.3",
      "name": "Electronic messaging",
      "description": "Information involved in electronic messaging shall be appropriately protected.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-cryptography-policy-bsi-exists"
        },
        {
          "id": "ssl-good-ciphers"
        },
        {
          "id": "ssl-no-warnings"
        },
        {
          "id": "ssl-used-expiry"
        },
        {
          "id": "ssl-used-unittest"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "A.13.2.4",
      "name": "Confidentiality or non-disclosure agreements",
      "description": "Requirements for confidentiality or non-disclosure agreements reflecting the organization’s needs for the protection of information shall be identified, regularly reviewed and documented.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-data-management-policy-bsi-exists"
        },
        {
          "id": "approved-human-resource-security-policy-bsi-exists"
        },
        {
          "id": "approved-third-party-management-policy-bsi-exists"
        },
        {
          "id": "employees-accepted-responsible-disclosure-policy"
        },
        {
          "id": "responsible-disclosure-policy-policies"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "nda-template-external"
        },
        {
          "id": "nda-template-internal"
        }
      ]
    },
    {
      "id": "A.14.1.1",
      "name": "Information security requirements analysis and specification",
      "description": "The information security related requirements shall be included in the requirements for new information systems or enhancements to existing information systems.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-operations-security-policy-bsi-exists"
        },
        {
          "id": "approved-risk-management-policy-bsi-exists"
        },
        {
          "id": "code-review-template-present-azuredevops"
        },
        {
          "id": "code-review-template-present-gitlab"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "A.14.1.2",
      "name": "Securing application services on public networks",
      "description": "Information involved in application services passing over public networks shall be protected from fraudulent activity, contract dispute and unauthorized disclosure and modification.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-cryptography-policy-bsi-exists"
        },
        {
          "id": "aws-s3-public-access-blocked"
        },
        {
          "id": "require-admin-encrypted-iaas"
        },
        {
          "id": "require-admin-encrypted-iaas-gcp"
        },
        {
          "id": "require-admin-encrypted-iaas-heroku"
        },
        {
          "id": "ssl-good-ciphers"
        },
        {
          "id": "ssl-no-warnings"
        },
        {
          "id": "ssl-used-expiry"
        },
        {
          "id": "ssl-used-unittest"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "A.14.1.3",
      "name": "Protecting application services transactions",
      "description": "Information involved in application service transactions shall be protected to prevent incomplete transmission, mis-routing, unauthorized message alteration, unauthorized disclosure, unauthorized message duplication or replay.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-cryptography-policy-bsi-exists"
        },
        {
          "id": "require-admin-encrypted-iaas"
        },
        {
          "id": "require-admin-encrypted-iaas-gcp"
        },
        {
          "id": "require-admin-encrypted-iaas-heroku"
        },
        {
          "id": "ssl-good-ciphers"
        },
        {
          "id": "ssl-no-warnings"
        },
        {
          "id": "ssl-used-expiry"
        },
        {
          "id": "ssl-used-unittest"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "A.14.2.1",
      "name": "Secure development policy",
      "description": "Rules for the development of software and systems shall be established and applied to developments within the organization.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-secure-development-policy-bsi-exists"
        },
        {
          "id": "employees-accepted-secure-development-policy-bsi"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "change-management-procedures"
        }
      ]
    },
    {
      "id": "A.14.2.2",
      "name": "System change control procedures",
      "description": "Changes to systems within the development lifecycle shall be controlled by the use of formal change control procedures.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-operations-security-policy-bsi-exists"
        },
        {
          "id": "approved-secure-development-policy-bsi-exists"
        },
        {
          "id": "version-control-tool-records"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "A.14.2.3",
      "name": "Technical review of applications after operating platform changes",
      "description": "When operating platforms are changed, business critical applications shall be reviewed and tested to ensure there is no adverse impact on organizational operations or security.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-operations-security-policy-bsi-exists"
        },
        {
          "id": "approved-secure-development-policy-bsi-exists"
        },
        {
          "id": "version-control-tool-records"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "A.14.2.4",
      "name": "Restrictions on changes to software packages",
      "description": "Modifications to software packages shall be discouraged, limited to necessary changes and all changes shall be strictly controlled.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-operations-security-policy-bsi-exists"
        },
        {
          "id": "approved-secure-development-policy-bsi-exists"
        },
        {
          "id": "version-control-tool-records"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "A.14.2.5",
      "name": "Secure system engineering principles",
      "description": "Principles for engineering secure systems shall be established, documented, maintained and applied to any information system implementation efforts.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-operations-security-policy-bsi-exists"
        },
        {
          "id": "approved-secure-development-policy-bsi-exists"
        },
        {
          "id": "code-review-template-present-azuredevops"
        },
        {
          "id": "code-review-template-present-gitlab"
        },
        {
          "id": "version-control-tool-records"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "change-management-procedures"
        },
        {
          "id": "ci-cd-implemented"
        }
      ]
    },
    {
      "id": "A.14.2.6",
      "name": "Secure development environment",
      "description": "Organizations shall establish and appropriately protect secure development environments for system development and integration efforts that cover the entire system development lifecycle.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-operations-security-policy-bsi-exists"
        },
        {
          "id": "approved-secure-development-policy-bsi-exists"
        },
        {
          "id": "version-control-tool-records"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "A.14.2.7",
      "name": "Outsourced development",
      "description": "The organization shall supervise and monitor the activity of outsourced system development.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-third-party-management-policy-bsi-exists"
        },
        {
          "id": "employees-accepted-vendor-management-policy"
        },
        {
          "id": "high-severity-vendor-compliance-reports"
        },
        {
          "id": "vendor-management-policy"
        },
        {
          "id": "vendor-management-records"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "outsourced-development-contracts"
        }
      ]
    },
    {
      "id": "A.14.2.8",
      "name": "System security testing",
      "description": "Testing of security functionality shall be carried out during development.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-operations-security-policy-bsi-exists"
        },
        {
          "id": "approved-secure-development-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "security-test-example"
        }
      ]
    },
    {
      "id": "A.14.2.9",
      "name": "System acceptance testing",
      "description": "Acceptance testing programs and related criteria shall be established for new information systems, upgrades and new versions.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-operations-security-policy-bsi-exists"
        },
        {
          "id": "approved-secure-development-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "qa-process"
        }
      ]
    },
    {
      "id": "A.14.3.1",
      "name": "Protection of test data",
      "description": "Test data shall be selected carefully, protected and controlled.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-operations-security-policy-bsi-exists"
        },
        {
          "id": "approved-secure-development-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "A.15.1.1",
      "name": "Information security policy for supplier relationships",
      "description": "Information security requirements for mitigating the risks associated with supplier’s access to the organization’s assets shall be agreed with the supplier and documented.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-third-party-management-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "A.15.1.2",
      "name": "Addressing security within supplier agreements",
      "description": "All relevant information security requirements shall be established and agreed with each supplier that may access, process, store, communicate, or provide IT infrastructure components for, the organization’s information.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-third-party-management-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "cloud-service-provider-agreements"
        },
        {
          "id": "supplier-agreements"
        }
      ]
    },
    {
      "id": "A.15.1.3",
      "name": "Information and communication technology supply chain",
      "description": "Agreements with suppliers shall include requirements to address the information security risks associated with information and communications technology services and product supply chain.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-third-party-management-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "A.15.2.1",
      "name": "Monitoring and review of supplier services",
      "description": "Organizations shall regularly monitor, review and audit supplier service delivery.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-third-party-management-policy-bsi-exists"
        },
        {
          "id": "high-severity-vendor-compliance-reports"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "third-party-security-reviews"
        }
      ]
    },
    {
      "id": "A.15.2.2",
      "name": "Managing changes to supplier services",
      "description": "Changes to the provision of services by suppliers, including maintaining and improving existing information security policies, procedures and controls, shall be managed, taking account of the criticality of business information, systems and processes involved and re-assessment of risks.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-third-party-management-policy-bsi-exists"
        },
        {
          "id": "high-severity-vendor-compliance-reports"
        },
        {
          "id": "subservice-organizations-soc-policies"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "third-party-security-reviews"
        }
      ]
    },
    {
      "id": "A.16.1.1",
      "name": "Responsibilities and procedures",
      "description": "Management responsibilities and procedures shall be established to ensure a quick, effective and orderly response to information security incidents.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-incident-response-plan-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "incident-report-example"
        }
      ]
    },
    {
      "id": "A.16.1.2",
      "name": "Reporting information security events",
      "description": "Information security events shall be reported through appropriate management channels as quickly as possible.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-incident-response-plan-bsi-exists"
        },
        {
          "id": "sla-for-security-bugs-records"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "incident-report-example"
        }
      ]
    },
    {
      "id": "A.16.1.3",
      "name": "Reporting information security weaknesses",
      "description": "Employees and contractors using the organization’s information systems and services shall be required to note and report any inspected or suspected information security weaknesses in systems or services.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-incident-response-plan-bsi-exists"
        },
        {
          "id": "approved-information-security-policy-bsi-exists"
        },
        {
          "id": "security-issues-tracked-records"
        },
        {
          "id": "security-training-records"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "sat-completion-bulk"
        }
      ]
    },
    {
      "id": "A.16.1.4",
      "name": "Assessment of and decision on information security events",
      "description": "Information security events shall be assessed and it shall be decided if they are to be classified as information security incidents.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-incident-response-plan-bsi-exists"
        },
        {
          "id": "security-issues-prioritized-records"
        },
        {
          "id": "security-training-records"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "incident-report-example"
        }
      ]
    },
    {
      "id": "A.16.1.5",
      "name": "Response to information security incidents",
      "description": "Information security incidents shall be responded to in accordance with the documented procedures.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-incident-response-plan-bsi-exists"
        },
        {
          "id": "security-issues-assigned-records"
        },
        {
          "id": "security-issues-prioritized-records"
        },
        {
          "id": "security-issues-tracked-records"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "A.16.1.6",
      "name": "Learning from information security incidents",
      "description": "Knowledge gained from analyzing and resolving information security incidents shall be used to reduce the likelihood or impact of future incidents.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-incident-response-plan-bsi-exists"
        },
        {
          "id": "security-issues-assigned-records"
        },
        {
          "id": "security-issues-prioritized-records"
        },
        {
          "id": "security-issues-tracked-records"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "incident-report-example"
        }
      ]
    },
    {
      "id": "A.16.1.7",
      "name": "Collection of evidence",
      "description": "The organization shall define and apply procedures for the identification, collection, acquisition and preservation of information, which can serve as evidence.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-incident-response-plan-bsi-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "A.17.1.1",
      "name": "Planning information security continuity",
      "description": "The organization shall determine its requirements for information security and the continuity of information security management in adverse situations, e.g. during a crisis or disaster.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-business-continuity-and-disaster-recovery-plan-bsi-exists"
        },
        {
          "id": "employees-accepted-business-continuity-and-disaster-recovery-plan-bsi"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "A.17.1.2",
      "name": "Implementing information security continuity",
      "description": "The organization shall establish, document, implement and maintain processes, procedures and controls to ensure the required level of continuity for information security during an adverse situation.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-business-continuity-and-disaster-recovery-plan-bsi-exists"
        },
        {
          "id": "employees-accepted-business-continuity-and-disaster-recovery-plan-bsi"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "A.17.1.3",
      "name": "Verify, review and evaluate information security continuity",
      "description": "The organization shall verify the established and implemented information security continuity controls at regular intervals in order to ensure that they are valid and effective during adverse situations.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-business-continuity-and-disaster-recovery-plan-bsi-exists"
        },
        {
          "id": "employees-accepted-business-continuity-and-disaster-recovery-plan-bsi"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "disaster-recovery-tabletop"
        }
      ]
    },
    {
      "id": "A.17.2.1",
      "name": "Availability of information processing facilities",
      "description": "Information processing facilities shall be implemented with redundancy sufficient to meet availability requirements.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-business-continuity-and-disaster-recovery-plan-bsi-exists"
        },
        {
          "id": "employees-accepted-business-continuity-and-disaster-recovery-plan-bsi"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "network-diagram"
        }
      ]
    },
    {
      "id": "A.18.1.1",
      "name": "Identification of applicable legislation and contractual requirements",
      "description": "All relevant legislative statutory, regulatory, contractual requirements and the organization’s approach to meet these requirements shall be explicitly identified, documented and kept up to date for each information system and the organization.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-12-isms-relevant-laws-regulations-and-contractual-requirements-exists"
        },
        {
          "id": "employees-accepted-12-isms-relevant-laws-regulations-and-contractual-requirements"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "A.18.1.2",
      "name": "Intellectual property rights",
      "description": "Appropriate procedures shall be implemented to ensure compliance with legislative, regulatory, and contractual requirements related to intellectual property rights and use of proprietary software products.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-12-isms-relevant-laws-regulations-and-contractual-requirements-exists"
        },
        {
          "id": "employees-accepted-12-isms-relevant-laws-regulations-and-contractual-requirements"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "employee-agreements"
        },
        {
          "id": "msa-template"
        }
      ]
    },
    {
      "id": "A.18.1.3",
      "name": "Protection of records",
      "description": "Records shall be protected from loss, destruction, falsification, unauthorized access and unauthorized release, in accordance with legislatory, regulatory, contractual, and business requirements.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-05-isms-procedure-for-the-control-of-documented-information-exists"
        },
        {
          "id": "approved-operations-security-policy-bsi-exists"
        },
        {
          "id": "aws-dynamodb-pitr-backups"
        },
        {
          "id": "azure-cosmosdb-backups"
        },
        {
          "id": "daily-app-database-backups-config-digitalocean"
        },
        {
          "id": "daily-database-backups-config"
        },
        {
          "id": "daily-database-backups-config-azure"
        },
        {
          "id": "daily-database-backups-config-digitalocean"
        },
        {
          "id": "daily-database-backups-config-gcp"
        },
        {
          "id": "daily-database-backups-config-heroku"
        },
        {
          "id": "employees-accepted-05-isms-procedure-for-the-control-of-documented-information"
        },
        {
          "id": "mongo-atlas-backups"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "A.18.1.4",
      "name": "Privacy and protection of personally identifiable information",
      "description": "Privacy and protection of personally identifiable information shall be ensured as required in relevant legislation and regulation where applicable.",
      "categories": ["Technical"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "employee-agreements"
        },
        {
          "id": "privacy-policy"
        }
      ]
    },
    {
      "id": "A.18.1.5",
      "name": "Regulation of cryptographic controls",
      "description": "Cryptographic controls shall be used in compliance with all relevant agreements, legislation and regulations.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-cryptography-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "A.18.2.1",
      "name": "Independent review of information security",
      "description": "The organization’s approach to managing information security and its implementation (i.e. control objectives, controls, policies, processes and procedures for information security) shall be reviewed independently at planned intervals or when significant changes occur.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-07-isms-procedure-for-internal-audits-exists"
        },
        {
          "id": "approved-08-isms-procedure-for-management-review-exists"
        },
        {
          "id": "approved-risk-management-policy-bsi-exists"
        },
        {
          "id": "employees-accepted-07-isms-procedure-for-internal-audits"
        },
        {
          "id": "employees-accepted-08-isms-procedure-for-management-review"
        },
        {
          "id": "internal-control-tool-vanta"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "internal-audit-report"
        },
        {
          "id": "risk-assessment-results"
        }
      ]
    },
    {
      "id": "A.18.2.2",
      "name": "Compliance with security policies and standards",
      "description": "Managers shall regularly review the compliance of information processing and procedures within their area of responsibility with the appropriate security policies, standards and any other security requirements.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-07-isms-procedure-for-internal-audits-exists"
        },
        {
          "id": "approved-08-isms-procedure-for-management-review-exists"
        },
        {
          "id": "approved-risk-management-policy-bsi-exists"
        },
        {
          "id": "employees-accepted-07-isms-procedure-for-internal-audits"
        },
        {
          "id": "employees-accepted-08-isms-procedure-for-management-review"
        },
        {
          "id": "internal-control-tool-vanta"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "internal-audit-report"
        }
      ]
    },
    {
      "id": "A.18.2.3",
      "name": "Technical compliance review",
      "description": "Information systems shall be regularly reviewed for compliance with the organization’s information security policies and standards.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-risk-management-policy-bsi-exists"
        },
        {
          "id": "container-packages-checked-for-vulnerabilities-records-closed-aws"
        },
        {
          "id": "container-packages-checked-for-vulnerabilities-records-closed-azure"
        },
        {
          "id": "container-packages-checked-for-vulnerabilities-records-closed-gcp"
        },
        {
          "id": "infra-packages-checked-for-vulnerabilities-records-closed"
        },
        {
          "id": "packages-checked-for-vulnerabilities-records-closed-snyk"
        },
        {
          "id": "penetration-tests-records"
        },
        {
          "id": "sla-for-security-bugs-records"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "vulnerabilities-remediated-sample"
        },
        {
          "id": "vulnerability-scan"
        }
      ]
    },
    {
      "id": "A.5.1.1",
      "name": "Policies for information security",
      "description": "A set of policies for information security shall be defined, approved by management, published and communicated to employees and relevant external parties.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "approved-asset-management-policy-bsi-exists"
        },
        {
          "id": "approved-business-continuity-and-disaster-recovery-plan-bsi-exists"
        },
        {
          "id": "approved-cryptography-policy-bsi-exists"
        },
        {
          "id": "approved-data-management-policy-bsi-exists"
        },
        {
          "id": "approved-human-resource-security-policy-bsi-exists"
        },
        {
          "id": "approved-incident-response-plan-bsi-exists"
        },
        {
          "id": "approved-information-security-policy-bsi-exists"
        },
        {
          "id": "approved-information-security-roles-and-responsibilities-bsi-exists"
        },
        {
          "id": "approved-operations-security-policy-bsi-exists"
        },
        {
          "id": "approved-physical-security-policy-bsi-exists"
        },
        {
          "id": "approved-risk-management-policy-bsi-exists"
        },
        {
          "id": "approved-secure-development-policy-bsi-exists"
        },
        {
          "id": "approved-third-party-management-policy-bsi-exists"
        },
        {
          "id": "employees-accepted-access-control-policy-bsi"
        },
        {
          "id": "employees-accepted-asset-management-policy-bsi"
        },
        {
          "id": "employees-accepted-business-continuity-and-disaster-recovery-plan-bsi"
        },
        {
          "id": "employees-accepted-cryptography-policy-bsi"
        },
        {
          "id": "employees-accepted-data-management-policy-bsi"
        },
        {
          "id": "employees-accepted-human-resource-security-policy-bsi"
        },
        {
          "id": "employees-accepted-incident-response-plan-bsi"
        },
        {
          "id": "employees-accepted-information-security-policy-bsi"
        },
        {
          "id": "employees-accepted-information-security-roles-and-responsibilities-bsi"
        },
        {
          "id": "employees-accepted-operations-security-policy-bsi"
        },
        {
          "id": "employees-accepted-physical-security-policy-bsi"
        },
        {
          "id": "employees-accepted-risk-management-policy-bsi"
        },
        {
          "id": "employees-accepted-secure-development-policy-bsi"
        },
        {
          "id": "employees-accepted-third-party-management-policy-bsi"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "policies-storage-location-and-view"
        }
      ]
    },
    {
      "id": "A.5.1.2",
      "name": "Review of the policies for information security",
      "description": "The policies for information security shall be reviewed at planned intervals or if significant changes occur to ensure their continuing suitability, adequacy and effectiveness.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "approved-asset-management-policy-bsi-exists"
        },
        {
          "id": "approved-business-continuity-and-disaster-recovery-plan-bsi-exists"
        },
        {
          "id": "approved-cryptography-policy-bsi-exists"
        },
        {
          "id": "approved-data-management-policy-bsi-exists"
        },
        {
          "id": "approved-human-resource-security-policy-bsi-exists"
        },
        {
          "id": "approved-incident-response-plan-bsi-exists"
        },
        {
          "id": "approved-information-security-policy-bsi-exists"
        },
        {
          "id": "approved-information-security-roles-and-responsibilities-bsi-exists"
        },
        {
          "id": "approved-operations-security-policy-bsi-exists"
        },
        {
          "id": "approved-physical-security-policy-bsi-exists"
        },
        {
          "id": "approved-risk-management-policy-bsi-exists"
        },
        {
          "id": "approved-secure-development-policy-bsi-exists"
        },
        {
          "id": "approved-third-party-management-policy-bsi-exists"
        },
        {
          "id": "employees-accepted-business-continuity-and-disaster-recovery-plan-bsi"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "A.6.1.1",
      "name": "Information security roles and responsibilities",
      "description": "All information security responsibilities shall be defined and allocated.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-03-isms-roles-responsibilities-and-authorities-exists"
        },
        {
          "id": "approved-information-security-roles-and-responsibilities-bsi-exists"
        },
        {
          "id": "approved-risk-management-policy-bsi-exists"
        },
        {
          "id": "employees-accepted-03-isms-roles-responsibilities-and-authorities"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "A.6.1.2",
      "name": "Segregation of duties",
      "description": "Conflicting duties and areas of responsibilities shall be segregated to reduce opportunities for unauthorized or unintentional modification or misuse of the organization's assets.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "activity-log-blob-container-has-logging-azure"
        },
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "approved-operations-security-policy-bsi-exists"
        },
        {
          "id": "code-review-application-config"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "org-chart"
        }
      ]
    },
    {
      "id": "A.6.1.3",
      "name": "Contact with authorities",
      "description": "Appropriate contacts with relevant authorities shall be maintained.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-incident-response-plan-bsi-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "A.6.1.4",
      "name": "Contact with special interest groups",
      "description": "Appropriate contacts with special interest groups or other specialist security forums and professional associations shall be maintained.",
      "categories": ["Administrative"],
      "tests": [],
      "manualEvidenceRequests": [
        {
          "id": "security-association-memberships"
        },
        {
          "id": "security-distribution-lists"
        }
      ]
    },
    {
      "id": "A.6.1.5",
      "name": "Information security in project management",
      "description": "Information security shall be addressed in project management, regardless of the type of project.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-risk-management-policy-bsi-exists"
        },
        {
          "id": "code-review-template-present-azuredevops"
        },
        {
          "id": "code-review-template-present-gitlab"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "risk-assessment-project-results"
        }
      ]
    },
    {
      "id": "A.6.2.1",
      "name": "Mobile device policy",
      "description": "A policy and supporting security measures shall be adopted to manage the risks introduced by using mobile devices.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-information-security-policy-bsi-exists"
        },
        {
          "id": "full-disk-encryption-records"
        },
        {
          "id": "full-disk-encryption-records-jamf"
        },
        {
          "id": "full-disk-encryption-records-kandji"
        },
        {
          "id": "malware-detection-workstations"
        },
        {
          "id": "malware-detection-workstations-jamf"
        },
        {
          "id": "malware-detection-workstations-kandji"
        },
        {
          "id": "packages-checked-for-vulnerabilities-records-closed-snyk"
        },
        {
          "id": "security-vulnerabilities-monitored-laptops"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "A.6.2.2",
      "name": "Teleworking",
      "description": "A policy and supporting security measures shall be implemented to protect information accessed, protected or stored at teleworking sites.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "approved-information-security-policy-bsi-exists"
        },
        {
          "id": "mfa-on-accounts-gsuite"
        },
        {
          "id": "mfa-on-accounts-infra"
        },
        {
          "id": "mfa-on-accounts-infra-root"
        },
        {
          "id": "mfa-on-accounts-version-control"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "A.7.1.1",
      "name": "Screening",
      "description": "Background verification checks on all candidates for employment shall be carried out in accordance with relevant laws, regulations, and ethics and shall be proportional to the business requirements, the classification of the information to be accessed and the perceived risks.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-human-resource-security-policy-bsi-exists"
        },
        {
          "id": "background-checks-records"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "bulk-background-check"
        }
      ]
    },
    {
      "id": "A.7.1.2",
      "name": "Terms and conditions of employment",
      "description": "The contractual agreements with employees and contractors shall state their and the organization’s responsibilities for information security.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "acceptable-use-policy-policies"
        },
        {
          "id": "approved-03-isms-roles-responsibilities-and-authorities-exists"
        },
        {
          "id": "approved-human-resource-security-policy-bsi-exists"
        },
        {
          "id": "approved-information-security-policy-bsi-exists"
        },
        {
          "id": "employees-accepted-03-isms-roles-responsibilities-and-authorities"
        },
        {
          "id": "employees-accepted-code-of-conduct-bsi"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "contractor-agreement"
        },
        {
          "id": "employee-agreements"
        },
        {
          "id": "employee-signed-confidentiality"
        }
      ]
    },
    {
      "id": "A.7.2.1",
      "name": "Management responsibilities",
      "description": "Management shall require all employees and contractors to apply information security in accordance with the established policies and procedures of the organization.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "approved-asset-management-policy-bsi-exists"
        },
        {
          "id": "approved-business-continuity-and-disaster-recovery-plan-bsi-exists"
        },
        {
          "id": "approved-cryptography-policy-bsi-exists"
        },
        {
          "id": "approved-data-management-policy-bsi-exists"
        },
        {
          "id": "approved-human-resource-security-policy-bsi-exists"
        },
        {
          "id": "approved-incident-response-plan-bsi-exists"
        },
        {
          "id": "approved-information-security-policy-bsi-exists"
        },
        {
          "id": "approved-information-security-roles-and-responsibilities-bsi-exists"
        },
        {
          "id": "approved-operations-security-policy-bsi-exists"
        },
        {
          "id": "approved-physical-security-policy-bsi-exists"
        },
        {
          "id": "approved-risk-management-policy-bsi-exists"
        },
        {
          "id": "approved-secure-development-policy-bsi-exists"
        },
        {
          "id": "approved-third-party-management-policy-bsi-exists"
        },
        {
          "id": "employees-accepted-access-control-policy-bsi"
        },
        {
          "id": "employees-accepted-asset-management-policy-bsi"
        },
        {
          "id": "employees-accepted-business-continuity-and-disaster-recovery-plan-bsi"
        },
        {
          "id": "employees-accepted-cryptography-policy-bsi"
        },
        {
          "id": "employees-accepted-data-management-policy-bsi"
        },
        {
          "id": "employees-accepted-human-resource-security-policy-bsi"
        },
        {
          "id": "employees-accepted-incident-response-plan-bsi"
        },
        {
          "id": "employees-accepted-information-security-policy-bsi"
        },
        {
          "id": "employees-accepted-information-security-roles-and-responsibilities-bsi"
        },
        {
          "id": "employees-accepted-operations-security-policy-bsi"
        },
        {
          "id": "employees-accepted-physical-security-policy-bsi"
        },
        {
          "id": "employees-accepted-risk-management-policy-bsi"
        },
        {
          "id": "employees-accepted-secure-development-policy-bsi"
        },
        {
          "id": "employees-accepted-third-party-management-policy-bsi"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "A.7.2.2",
      "name": "Information security awareness, education and training",
      "description": "All employees of the organization and, where relevant, contractors shall receive appropriate awareness education and training and regular updates in organizational policies and procedures, as relevant for their job function.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-human-resource-security-policy-bsi-exists"
        },
        {
          "id": "security-training-policies"
        },
        {
          "id": "security-training-records"
        },
        {
          "id": "security-training-tracked-records"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "sat-completion-bulk"
        }
      ]
    },
    {
      "id": "A.7.2.3",
      "name": "Disciplinary process",
      "description": "There shall be a formal and communicated disciplinary process in place to take action against employees who have committed an information security breach.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-human-resource-security-policy-bsi-exists"
        },
        {
          "id": "approved-information-security-policy-bsi-exists"
        },
        {
          "id": "employees-accepted-human-resource-security-policy-bsi"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "A.7.3.1",
      "name": "Termination or change of employment responsibilities",
      "description": "Information security responsibilities and duties that remain valid after termination or change of employment shall be defined, communicated to the employee or contractor and enforced.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-human-resource-security-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "employee-exit-interview"
        },
        {
          "id": "policy-exists-termination"
        }
      ]
    },
    {
      "id": "A.8.1.1",
      "name": "Inventory of assets",
      "description": "Assets associated with information and information processing facilities shall be identified and an inventory of these assets shall be drawn up and maintained.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "inventory-list-descriptions"
        },
        {
          "id": "inventory-list-owners"
        },
        {
          "id": "inventory-list-user-data"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "A.8.1.2",
      "name": "Ownership of assets",
      "description": "Assets maintained in the inventory shall be owned.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "inventory-list-owners"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "A.8.1.3",
      "name": "Acceptable use of assets",
      "description": "Rules for the acceptable use of information and of assets associated with information and information processing facilities shall be identified, documented and implemented.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "acceptable-use-policy-policies"
        },
        {
          "id": "approved-information-security-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "A.8.1.4",
      "name": "Return of assets",
      "description": "All employees and external party users shall return all of the organizational assets in their possession upon termination of their employment, contract or agreement.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-asset-management-policy-bsi-exists"
        },
        {
          "id": "approved-human-resource-security-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "employee-exit-interview"
        }
      ]
    },
    {
      "id": "A.8.2.1",
      "name": "Classification of information",
      "description": "Information shall be classified in terms of legal requirements, value, criticality and sensitivity to unauthorized disclosure or modification.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-data-management-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "A.8.2.2",
      "name": "Labelling of information",
      "description": "An appropriate set of procedures for information labeling shall be developed and implemented in accordance with the information classification scheme adopted by the organization.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-data-management-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "A.8.2.3",
      "name": "Handling of assets",
      "description": "Procedures for handling assets shall be developed and implemented in accordance with the information classification scheme adopted by the organization.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "acceptable-use-policy-policies"
        },
        {
          "id": "approved-asset-management-policy-bsi-exists"
        },
        {
          "id": "approved-data-management-policy-bsi-exists"
        },
        {
          "id": "approved-information-security-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "A.8.3.1",
      "name": "Management of removable media",
      "description": "Procedures shall be implemented for the management of removable media in accordance with the classification scheme adopted by the organization.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-data-management-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "A.8.3.2",
      "name": "Disposal of media",
      "description": "Media shall be disposed of securely when no longer required, using formal procedures.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-asset-management-policy-bsi-exists"
        },
        {
          "id": "approved-data-management-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "media-disposal"
        }
      ]
    },
    {
      "id": "A.8.3.3",
      "name": "Physical media transfer",
      "description": "Media containing information shall be protected against unauthorized access, misuse or corruption during transportation.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-asset-management-policy-bsi-exists"
        },
        {
          "id": "approved-data-management-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "A.9.1.1",
      "name": "Access control policy",
      "description": "An access control policy shall be established, documented and reviewed based on business and information security requirements.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-access-control-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "A.9.1.2",
      "name": "Access to networks and network services",
      "description": "Users shall only be provided with access to the network and network services that they have been specifically authorized to use.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "access-granted-infra"
        },
        {
          "id": "access-granted-versioncontrol"
        },
        {
          "id": "approved-access-control-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "access-requests"
        },
        {
          "id": "access-reviews"
        }
      ]
    },
    {
      "id": "A.9.2.1",
      "name": "User registration and de-registration",
      "description": "A formal user registration and de-registration process shall be implemented to enable assignment of access rights.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "access-granted-infra"
        },
        {
          "id": "access-granted-versioncontrol"
        },
        {
          "id": "access-removed-infra"
        },
        {
          "id": "access-removed-versioncontrol"
        },
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "asana-account-access-removed-on-termination"
        },
        {
          "id": "aws-account-access-removed-on-termination"
        },
        {
          "id": "azuredevops-account-access-removed-on-termination"
        },
        {
          "id": "bitbucket-account-access-removed-on-termination"
        },
        {
          "id": "clickup-account-access-removed-on-termination"
        },
        {
          "id": "clubhouse-account-access-removed-on-termination"
        },
        {
          "id": "datadog-account-access-removed-on-termination"
        },
        {
          "id": "github-account-access-removed-on-termination"
        },
        {
          "id": "gitlab-account-access-removed-on-termination"
        },
        {
          "id": "heroku-account-access-removed-on-termination"
        },
        {
          "id": "jamf-account-access-removed-on-termination"
        },
        {
          "id": "jira-account-access-removed-on-termination"
        },
        {
          "id": "knowbe4-account-access-removed-on-termination"
        },
        {
          "id": "linear-account-access-removed-on-termination"
        },
        {
          "id": "mongoatlas-account-access-removed-on-termination"
        },
        {
          "id": "pivotaltracker-account-access-removed-on-termination"
        },
        {
          "id": "slack-account-access-removed-on-termination"
        },
        {
          "id": "snyk-account-access-removed-on-termination"
        },
        {
          "id": "trello-account-access-removed-on-termination"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "access-requests"
        }
      ]
    },
    {
      "id": "A.9.2.2",
      "name": "User access provisioning",
      "description": "A formal user access provisioning process shall be implemented to assign or revoke access rights for all user types to all systems and services.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "access-granted-infra"
        },
        {
          "id": "access-granted-versioncontrol"
        },
        {
          "id": "access-removed-infra"
        },
        {
          "id": "access-removed-versioncontrol"
        },
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "asana-account-access-removed-on-termination"
        },
        {
          "id": "aws-account-access-removed-on-termination"
        },
        {
          "id": "azuredevops-account-access-removed-on-termination"
        },
        {
          "id": "bitbucket-account-access-removed-on-termination"
        },
        {
          "id": "clickup-account-access-removed-on-termination"
        },
        {
          "id": "clubhouse-account-access-removed-on-termination"
        },
        {
          "id": "datadog-account-access-removed-on-termination"
        },
        {
          "id": "github-account-access-removed-on-termination"
        },
        {
          "id": "gitlab-account-access-removed-on-termination"
        },
        {
          "id": "heroku-account-access-removed-on-termination"
        },
        {
          "id": "jamf-account-access-removed-on-termination"
        },
        {
          "id": "jira-account-access-removed-on-termination"
        },
        {
          "id": "knowbe4-account-access-removed-on-termination"
        },
        {
          "id": "linear-account-access-removed-on-termination"
        },
        {
          "id": "mongoatlas-account-access-removed-on-termination"
        },
        {
          "id": "pivotaltracker-account-access-removed-on-termination"
        },
        {
          "id": "slack-account-access-removed-on-termination"
        },
        {
          "id": "snyk-account-access-removed-on-termination"
        },
        {
          "id": "trello-account-access-removed-on-termination"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "access-requests"
        }
      ]
    },
    {
      "id": "A.9.2.3",
      "name": "Management of privileged access rights",
      "description": "The allocation and use of privileged access rights shall be restricted and controlled.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "access-granted-infra"
        },
        {
          "id": "access-granted-versioncontrol"
        },
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "employees-accepted-system-access-control-policy"
        },
        {
          "id": "infra-access-requires-approval-config"
        },
        {
          "id": "least-privileged-policy-policies"
        },
        {
          "id": "no-allusers-policy-bindings-gcp"
        },
        {
          "id": "no-nonindividual-primitive-roles-gcp"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "access-requests"
        }
      ]
    },
    {
      "id": "A.9.2.4",
      "name": "Management of secret authentication information of users",
      "description": "The allocation of secret authentication information shall be controlled through a formal management process.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "acceptable-use-policy-policies"
        },
        {
          "id": "approved-information-security-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "A.9.2.5",
      "name": "Review of user access rights",
      "description": "Asset owners shall review users’ access rights at regular intervals.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "access-removed-infra"
        },
        {
          "id": "access-removed-versioncontrol"
        },
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "asana-account-linked-in-vanta"
        },
        {
          "id": "atlas-account-linked-in-vanta"
        },
        {
          "id": "azuredevops-account-linked-in-vanta"
        },
        {
          "id": "bitbucket-account-linked-in-vanta"
        },
        {
          "id": "clickup-account-linked-in-vanta"
        },
        {
          "id": "clubhouse-account-linked-in-vanta"
        },
        {
          "id": "datadog-account-linked-in-vanta"
        },
        {
          "id": "github-account-linked-in-vanta"
        },
        {
          "id": "gitlab-account-linked-in-vanta"
        },
        {
          "id": "heroku-account-linked-in-vanta"
        },
        {
          "id": "hr-users-linked-in-vanta"
        },
        {
          "id": "jamf-account-linked-in-vanta"
        },
        {
          "id": "jira-account-linked-in-vanta"
        },
        {
          "id": "knowbe4-account-linked-in-vanta"
        },
        {
          "id": "linear-account-linked-in-vanta"
        },
        {
          "id": "pivotaltracker-account-linked-in-vanta"
        },
        {
          "id": "slack-account-linked-in-vanta"
        },
        {
          "id": "snyk-account-linked-in-vanta"
        },
        {
          "id": "trello-account-linked-in-vanta"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "access-reviews"
        },
        {
          "id": "access-reviews-planned"
        }
      ]
    },
    {
      "id": "A.9.2.6",
      "name": "Removal or adjustment of access rights",
      "description": "The access rights of all employees and external party users to information and information processing facilities shall be removed upon termination of their employment, contract or agreement, or adjusted upon change.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "access-removed-infra"
        },
        {
          "id": "access-removed-versioncontrol"
        },
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "asana-account-access-removed-on-termination"
        },
        {
          "id": "aws-account-access-removed-on-termination"
        },
        {
          "id": "azuredevops-account-access-removed-on-termination"
        },
        {
          "id": "bitbucket-account-access-removed-on-termination"
        },
        {
          "id": "clickup-account-access-removed-on-termination"
        },
        {
          "id": "clubhouse-account-access-removed-on-termination"
        },
        {
          "id": "datadog-account-access-removed-on-termination"
        },
        {
          "id": "github-account-access-removed-on-termination"
        },
        {
          "id": "gitlab-account-access-removed-on-termination"
        },
        {
          "id": "heroku-account-access-removed-on-termination"
        },
        {
          "id": "jamf-account-access-removed-on-termination"
        },
        {
          "id": "jira-account-access-removed-on-termination"
        },
        {
          "id": "knowbe4-account-access-removed-on-termination"
        },
        {
          "id": "linear-account-access-removed-on-termination"
        },
        {
          "id": "mongoatlas-account-access-removed-on-termination"
        },
        {
          "id": "pivotaltracker-account-access-removed-on-termination"
        },
        {
          "id": "slack-account-access-removed-on-termination"
        },
        {
          "id": "snyk-account-access-removed-on-termination"
        },
        {
          "id": "trello-account-access-removed-on-termination"
        },
        {
          "id": "vanta-users-offboarded-within-sla"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "employee-termination-checklist"
        }
      ]
    },
    {
      "id": "A.9.3.1",
      "name": "Use of secret authentication information",
      "description": "Users shall be required to follow the organization’s practices in the use of secret authentication information.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "acceptable-use-policy-policies"
        },
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "approved-information-security-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "A.9.4.1",
      "name": "Information access restriction",
      "description": "Access to information and application system functions shall be restricted in accordance with the access control policy.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "access-granted-infra"
        },
        {
          "id": "access-granted-versioncontrol"
        },
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "infra-access-requires-approval-config"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "access-application-admin"
        },
        {
          "id": "access-requests"
        }
      ]
    },
    {
      "id": "A.9.4.2",
      "name": "Secure log-on procedures",
      "description": "Where required by the access control policy, access to systems and applications shall be controlled by a secure log-on procedure.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "bitbucket-account-mfa-enabled"
        },
        {
          "id": "github-account-mfa-enabled"
        },
        {
          "id": "heroku-account-mfa-enabled"
        },
        {
          "id": "mfa-on-accounts-gsuite"
        },
        {
          "id": "mfa-on-accounts-infra"
        },
        {
          "id": "mfa-on-accounts-infra-root"
        },
        {
          "id": "mfa-on-accounts-okta"
        },
        {
          "id": "mfa-on-accounts-policy"
        },
        {
          "id": "mfa-on-accounts-version-control"
        },
        {
          "id": "mfa-on-groups-version-control"
        },
        {
          "id": "office-user-mfa-enabled"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "A.9.4.3",
      "name": "Password management system",
      "description": "Password management systems shall be interactive and shall ensure quality passwords.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "internal-password-policy-config"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "product-password-complexity"
        }
      ]
    },
    {
      "id": "A.9.4.4",
      "name": "Use of privileged utility programs",
      "description": "The use of utility programs that might be capable of overriding system and application controls shall be restricted and tightly controlled.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "approved-access-control-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "A.9.4.5",
      "name": "Access control to program source code",
      "description": "Access to program source code shall be restricted.",
      "categories": ["Technical"],
      "tests": [
        {
          "id": "access-granted-versioncontrol"
        },
        {
          "id": "access-removed-versioncontrol"
        },
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "infra-unique-accounts-records-versioncontrol"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "C.10.1",
      "name": "Nonconformity and corrective action",
      "description": "When a nonconformity occurs, the organization shall:\n- (a) react to the nonconformity, and as applicable: (1) take action to control and correct it; and (2) deal with the consequences;\n- (b) evaluate the need for action to eliminate the causes of nonconformity, in order that it does not recur or occur elsewhere, by: (1) reviewing the nonconformity; (2) determining the causes of the nonconformity; and (3) determining if similar nonconformities exist, or could potentially occur;\n- (c) implement any action needed;\n- (d) review the effectiveness of any corrective action taken; and\n- (e) make changes to the information security management system, if necessary.\n\nCorrective actions shall be appropriate to the effects of the nonconformities encountered. The organization shall retain documented information as evidence of:\n- (f) the nature of the nonconformities and any subsequent actions taken, and\n- (g) the results of any corrective action.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-09-isms-procedure-for-corrective-action-and-continual-improvement-exists"
        },
        {
          "id": "employees-accepted-09-isms-procedure-for-corrective-action-and-continual-improvement"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "C.10.2",
      "name": "Continual improvement implemented",
      "description": "The organization shall continually improve the suitability, adequacy and effectiveness of the information security management system.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-09-isms-procedure-for-corrective-action-and-continual-improvement-exists"
        },
        {
          "id": "employees-accepted-09-isms-procedure-for-corrective-action-and-continual-improvement"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "C.4.1",
      "name": "Understanding the organization and its context",
      "description": "The organization determines external and internal issues that are relevant to its purpose and that affect its ability to achieve the intended outcome(s) of its information security management system.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-01-isms-scope-of-the-isms-exists"
        },
        {
          "id": "employees-accepted-01-isms-scope-of-the-isms"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "C.4.2",
      "name": "Understanding the needs of interested parties",
      "description": "The organization determines:\n- (a) interested parties that are relevant to the information security management system; and\n- (b) the requirements of these interested parties relevant to information security.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-01-isms-scope-of-the-isms-exists"
        },
        {
          "id": "employees-accepted-01-isms-scope-of-the-isms"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "C.4.3",
      "name": "Determining the scope of the information security management system",
      "description": "The organization determines the boundaries and applicability of the information security management system to establish its scope and record this as documented information.\n\nWhen determining this scope, the organization considers:\n- (a) the external and internal issues within the organization and its context (C.4.1);\n- (b) the needs and expectations of interested parties (C.4.2); and\n- (c) interfaces and dependencies between activities performed by the organization, and those that are performed by other organizations.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-01-isms-scope-of-the-isms-exists"
        },
        {
          "id": "employees-accepted-01-isms-scope-of-the-isms"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "C.4.4",
      "name": "Information security management system",
      "description": "The organization shall establish, implement, maintain and continually improve an information security management system, in accordance with the requirements of this international standard.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-01-isms-scope-of-the-isms-exists"
        },
        {
          "id": "approved-02-isms-information-security-management-system-isms-policy-exists"
        },
        {
          "id": "employees-accepted-01-isms-scope-of-the-isms"
        },
        {
          "id": "employees-accepted-02-isms-information-security-management-system-isms-policy"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "C.5.1",
      "name": "Leadership and commitment",
      "description": "Top management shall demonstrate leadership and commitment with respect to the information security management system by:\n- (a) ensuring the information security policy and the information security objectives are established and are compatible with the strategic direction of the organization;\n- (b) ensuring the integration of the information security management system requirements into the organization's processes;\n- (c) ensuring that the resources needed for the information security management system are available;\n- (d) communicating the importance of effective information security management and of conforming to the information security management system requirements;\n- (e) ensuring that the information security management system achieves its intended outcome(s);\n- (f) directing and supporting persons to contribute to the effectiveness of the information security management system;\n- (g) promoting continual improvement; and\n- (h) supporting other relevant management roles to demonstrate their leadership as it applies to their areas of responsibility.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-02-isms-information-security-management-system-isms-policy-exists"
        },
        {
          "id": "approved-03-isms-roles-responsibilities-and-authorities-exists"
        },
        {
          "id": "approved-information-security-policy-bsi-exists"
        },
        {
          "id": "employees-accepted-02-isms-information-security-management-system-isms-policy"
        },
        {
          "id": "employees-accepted-03-isms-roles-responsibilities-and-authorities"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "C.5.2",
      "name": "Policy",
      "description": "Top management shall establish an information security policy that:\n- (a) is appropriate to the purpose of the organization\n- (b) includes information security objectives (see C.6.2) or provides the framework for setting information security objectives;\n- (c) includes a commitment to satisfy applicable requirements related to information security; and\n- (d) includes a commitment to continual improvement of the information security management system.\n\nThe information security policy shall:\n- (e) be available as documented information;\n- (f) be communicated within the organization; and\n- (g) be available to interested parties, as appropriate.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-02-isms-information-security-management-system-isms-policy-exists"
        },
        {
          "id": "approved-10-isms-information-security-objectives-plan-exists"
        },
        {
          "id": "approved-access-control-policy-bsi-exists"
        },
        {
          "id": "approved-asset-management-policy-bsi-exists"
        },
        {
          "id": "approved-business-continuity-and-disaster-recovery-plan-bsi-exists"
        },
        {
          "id": "approved-cryptography-policy-bsi-exists"
        },
        {
          "id": "approved-data-management-policy-bsi-exists"
        },
        {
          "id": "approved-human-resource-security-policy-bsi-exists"
        },
        {
          "id": "approved-incident-response-plan-bsi-exists"
        },
        {
          "id": "approved-information-security-policy-bsi-exists"
        },
        {
          "id": "approved-information-security-roles-and-responsibilities-bsi-exists"
        },
        {
          "id": "approved-operations-security-policy-bsi-exists"
        },
        {
          "id": "approved-physical-security-policy-bsi-exists"
        },
        {
          "id": "approved-risk-management-policy-bsi-exists"
        },
        {
          "id": "approved-secure-development-policy-bsi-exists"
        },
        {
          "id": "approved-third-party-management-policy-bsi-exists"
        },
        {
          "id": "employees-accepted-10-isms-information-security-objectives-plan"
        },
        {
          "id": "employees-accepted-access-control-policy-bsi"
        },
        {
          "id": "employees-accepted-asset-management-policy-bsi"
        },
        {
          "id": "employees-accepted-code-of-conduct-bsi"
        },
        {
          "id": "employees-accepted-cryptography-policy-bsi"
        },
        {
          "id": "employees-accepted-data-management-policy-bsi"
        },
        {
          "id": "employees-accepted-human-resource-security-policy-bsi"
        },
        {
          "id": "employees-accepted-incident-response-plan-bsi"
        },
        {
          "id": "employees-accepted-information-security-policy-bsi"
        },
        {
          "id": "employees-accepted-information-security-roles-and-responsibilities-bsi"
        },
        {
          "id": "employees-accepted-operations-security-policy-bsi"
        },
        {
          "id": "employees-accepted-physical-security-policy-bsi"
        },
        {
          "id": "employees-accepted-risk-management-policy-bsi"
        },
        {
          "id": "employees-accepted-secure-development-policy-bsi"
        },
        {
          "id": "employees-accepted-third-party-management-policy-bsi"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "C.5.3",
      "name": "Organizational roles, responsibilities and authorities",
      "description": "Top management shall ensure that the responsibilities and authorities for roles relevant to information security are assigned and communicated. \n\nTop management shall assign the responsibility and authority for:\n- (a) ensuring that the information security management system conforms to the requirements of this International Standard; and\n- (b) reporting on the performance of the information security management system to top management.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-03-isms-roles-responsibilities-and-authorities-exists"
        },
        {
          "id": "employees-accepted-03-isms-roles-responsibilities-and-authorities"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "C.6.1.1",
      "name": "General actions to address risks and opportunities",
      "description": "When planning for the information security management system, the organization shall consider the issues in the context of the organization (C.4.1) and the requirements for the organization (C.4.2) to reach its objectives and determine the risks and opportunities that need to be addressed to:\n- (a) ensure the information security management system can achieve its intended outcome(s);\n- (b) prevent, or reduce, undesired effects; and\n- (c) achieve continual improvement.\n\nThe organization shall plan:\n- (d) actions to address these risks and opportunities; and\n- (e) how to: (1) integrate and implement the actions into its information security management systems processes; and (2) evaluate the effectiveness of these actions.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-00-isms-master-list-of-documents-exists"
        },
        {
          "id": "approved-01-isms-scope-of-the-isms-exists"
        },
        {
          "id": "approved-03-isms-roles-responsibilities-and-authorities-exists"
        },
        {
          "id": "approved-04-isms-risk-assessment-and-risk-treatment-process-exists"
        },
        {
          "id": "approved-09-isms-procedure-for-corrective-action-and-continual-improvement-exists"
        },
        {
          "id": "approved-10-isms-information-security-objectives-plan-exists"
        },
        {
          "id": "employees-accepted-01-isms-scope-of-the-isms"
        },
        {
          "id": "risk-assessment-complete"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "risk-assessment-results"
        }
      ]
    },
    {
      "id": "C.6.1.2",
      "name": "Information security risk assessment",
      "description": "The organization shall define and apply an information security risk assessment process that:\n- (a) establishes and maintains information security risk criteria that include: (1) the risk acceptance criteria; and (2) criteria for performing information security risk assessments;\n- (b) ensures that repeated information security risk assessments produce consistent, valid, and complete results;\n- (c) identifies the information security risks: (1) apply the information security risk assessment process to identify risks associated with the loss of confidentiality, integrity, and availability for information security management system; and (2) identify the risk owners;\n- (d) analyzes the information security risks: (1) assess the potential consequences that would result if the risks identified in 6.1.2(c)(1) were to materialize; (2) assess the realistic likelihood of the occurrence of the risks identified in 6.1.2(c)(1); and (3) determine the levels of risk; and\n- (e) evaluates the information security risks: (1) compare the results of risk analysis with the risk criteria established in 6.1.2(a); and (2) prioritize the analyzed risks for risk treatment.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-04-isms-risk-assessment-and-risk-treatment-process-exists"
        },
        {
          "id": "approved-risk-management-policy-bsi-exists"
        },
        {
          "id": "employees-accepted-04-isms-risk-assessment-and-risk-treatment-process"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "risk-assessment-results"
        }
      ]
    },
    {
      "id": "C.6.1.3",
      "name": "Information security risk treatment",
      "description": "The organization shall define and apply an information security risk treatment process to:\n- (a) determine all controls that are necessary to implement the information security risk treatment option(s) chosen;\n- (b) compare the controls determined in 6.1.3(a) above with those in Annex A and verify that no necessary controls have been omitted;\n- (c) produce a Statement of Applicability that contains the necessary controls (see 6.1.3(a) and (b)) and justification for inclusions, whether they are implemented or not, and the justification for exclusions of controls from Annex A;\n- (d) formulate an information security risk treatment plan; and\n- (e) obtain risk owners' approval of the information security risk treatment plan and acceptance of the residual information security risks.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-04-isms-risk-assessment-and-risk-treatment-process-exists"
        },
        {
          "id": "approved-11-isms-statement-of-applicability-exists"
        },
        {
          "id": "employees-accepted-04-isms-risk-assessment-and-risk-treatment-process"
        },
        {
          "id": "employees-accepted-11-isms-statement-of-applicability"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "risk-assessment-results"
        }
      ]
    },
    {
      "id": "C.6.2",
      "name": "Information security objective and planning to achieve them",
      "description": "The organization shall establish information security objectives at relevant functions and levels.\nThe information security objectives shall:\n- (a) be consistent with the information security policy; \n- (b) be measurable (if practicable);\n- (c) take into account applicable information security requirements, and results from risk assessment and risk treatment;\n- (d) be communicated; and\n- (e) be updated as appropriate.\n\nThe organization shall retain documented information on the information security objectives. When planning how to achieve its information security objectives, the organization shall determine:\n- (f) what will be done;\n- (g) what resources will be required;\n- (h) who will be responsible;\n- (i) when it will be completed; and\n- (j) how the results will be evaluated.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-10-isms-information-security-objectives-plan-exists"
        },
        {
          "id": "employees-accepted-10-isms-information-security-objectives-plan"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "C.7.1",
      "name": "Resources",
      "description": "The organization shall determine and provide the resources needed for the establishment, implementation, maintenance and continual improvement of the information security management system.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-09-isms-procedure-for-corrective-action-and-continual-improvement-exists"
        },
        {
          "id": "approved-human-resource-security-policy-bsi-exists"
        },
        {
          "id": "employees-accepted-09-isms-procedure-for-corrective-action-and-continual-improvement"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "C.7.2",
      "name": "Competence",
      "description": "The organization shall:\n- (a) determine the necessary competence of person(s) doing work under its control that affects its information security performance;\n- (b) ensure that these persons are competent on the basis of appropriate education, training, or experience;\n- (c) where applicable, take actions to acquire the necessary competence, and evaluate the effectiveness of the actions taken; and\n- (d) retain appropriate documented information as evidence of competence.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-human-resource-security-policy-bsi-exists"
        },
        {
          "id": "approved-information-security-policy-bsi-exists"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "employee-performance-evaluations"
        }
      ]
    },
    {
      "id": "C.7.3",
      "name": "Awareness",
      "description": "Persons doing work under the organization's control shall be aware of:\n- (a) the information security policy;\n- (b) their contribution to the effectiveness of the information security management system, including the benefits of improved information security performance; and\n- (c) the implications of not conforming with the information security management system requirements.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-06-isms-information-security-communication-plan-exists"
        },
        {
          "id": "approved-human-resource-security-policy-bsi-exists"
        },
        {
          "id": "approved-information-security-policy-bsi-exists"
        },
        {
          "id": "employees-accepted-06-isms-information-security-communication-plan"
        },
        {
          "id": "security-training-records"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "C.7.4",
      "name": "Communication",
      "description": "The organization shall determine the need for internal and external communications relevant to the information security management system including:\n- (a) on what to communicate;\n- (b) when to communicate;\n- (c) with whom to communicate;\n- (d) who shall communicate; and\n- (e) the process by which communication shall be effected.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-06-isms-information-security-communication-plan-exists"
        },
        {
          "id": "employees-accepted-06-isms-information-security-communication-plan"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "C.7.5.1",
      "name": "Documented information",
      "description": "The organization's information security management system shall include:\n- (a) documented information required by the ISO27001 International Standard; and\n- (b) documented information determined by the organization as being necessary for the effectiveness of the information security management system.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-05-isms-procedure-for-the-control-of-documented-information-exists"
        },
        {
          "id": "employees-accepted-05-isms-procedure-for-the-control-of-documented-information"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "C.7.5.2",
      "name": "Documented information creating and updating",
      "description": "When creating and updating documented information the organization shall ensure appropriate:\n- (a) identification and description (e.g. a title, date, author, or reference number);\n- (b) format (e.g. language, software version, graphics) and media (e.g. paper, electronic); and\n- (c) review and approval for suitability and adequacy.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-05-isms-procedure-for-the-control-of-documented-information-exists"
        },
        {
          "id": "employees-accepted-05-isms-procedure-for-the-control-of-documented-information"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "C.7.5.3",
      "name": "Control of documented information",
      "description": "Documented information required by the information security management system and by this International Standard shall be controlled to ensure that:\n- (a) it is available and suitable for use, where and when it is needed; and\n- (b) it is adequately protected (e.g. from loss of confidentiality, improper use, or loss of integrity).\n\nFor the control of documented information, the organization shall address the following activities, as applicable:\n- (c) distribution, access, retrieval, and use;\n- (d) storage and preservation, including the preservation of legibility;\n- (e) control of changes (e.g. version control); and\n- (f) retention and disposition.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-05-isms-procedure-for-the-control-of-documented-information-exists"
        },
        {
          "id": "employees-accepted-05-isms-procedure-for-the-control-of-documented-information"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "C.8.1",
      "name": "Operation planning and control",
      "description": "The organization shall:\n- plan, implement and control the processes needed to meet information security requirements, and to implement the actions determined in C.6.1;\n- implement plans to achieve information security objectives determined in C.6.2;\n- keep documented information to the extent necessary to have confidence that the processes have been carried out as planned;\n- control planned changes and review the consequences of unintended changes, taking action to mitigate any adverse effects, as necessary; and\n- ensure that outsourced processes are determined and controlled.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-02-isms-information-security-management-system-isms-policy-exists"
        },
        {
          "id": "approved-08-isms-procedure-for-management-review-exists"
        },
        {
          "id": "approved-risk-management-policy-bsi-exists"
        },
        {
          "id": "employees-accepted-02-isms-information-security-management-system-isms-policy"
        },
        {
          "id": "employees-accepted-08-isms-procedure-for-management-review"
        }
      ],
      "manualEvidenceRequests": []
    },
    {
      "id": "C.8.2",
      "name": "Information security risk assessment",
      "description": "The organization shall:\n- perform information security risk assessments at planned intervals or when significant changes are proposed or occur, taking account of the criteria established in 6.1.2 (a); and\n- retain documented information on the results of the information security risk assessments.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "risk-assessment-complete"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "risk-assessment-results"
        }
      ]
    },
    {
      "id": "C.8.3",
      "name": "Information security risk treatment",
      "description": "The organization shall:\n- implement the information security risk treatment plan; and\n- retain documented information of the results of the information security risk treatment.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-risk-management-policy-bsi-exists"
        },
        {
          "id": "risk-assessment-complete"
        },
        {
          "id": "risk-register-complete"
        },
        {
          "id": "risk-register-questions-complete"
        },
        {
          "id": "risk-register-scenario-tasks-created"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "risk-assessment-results"
        }
      ]
    },
    {
      "id": "C.9.1",
      "name": "Monitoring, measurement, analysis, and evaluation",
      "description": "The organization shall evaluate the information security performance and the effectiveness of the information security management system and determine:\n- (a) what needs to be monitored and measured, including information security processes and controls;\n- (b) the methods for monitoring, measurement, analysis, and evaluation, as applicable, to ensure valid results;\n- (c) when the monitoring and measuring shall be performed;\n- (d) who shall monitor and measure;\n- (e) when the results from monitoring and measurement shall be analyzed and evaluated; and\n- (f) who shall analyze and evaluate these results.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-10-isms-information-security-objectives-plan-exists"
        },
        {
          "id": "approved-human-resource-security-policy-bsi-exists"
        },
        {
          "id": "employees-accepted-10-isms-information-security-objectives-plan"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "employee-performance-evaluations"
        }
      ]
    },
    {
      "id": "C.9.2",
      "name": "Internal audit",
      "description": "The organization shall conduct internal audits at planned intervals to provide information on whether the information security management system:\n- (a) conforms to: (1) the organization's own requirements for its information security management system; and (2) the requirements of this International Standard; and\n- (b) is effectively implemented and maintained.\n\nThe organization shall:\n- (c) plan, establish, implement and maintain an audit program(s), including the frequency, methods, responsibilities, planning requirements, and reporting. The audit program(s) shall take into consideration the importance of the processes concerned and the results of previous audits;\n- (d) define the audit criteria and scope for each audit;\n- (e) select auditors and conduct audits that ensure objectivity and the impartiality of the audit process;\n- (f) ensure that the results of the audits are reported to relevant management; and\n- (g) retain documented information as evidence of the audit program(s) and the audit results.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-07-isms-procedure-for-internal-audits-exists"
        },
        {
          "id": "employees-accepted-07-isms-procedure-for-internal-audits"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "internal-audit-report"
        }
      ]
    },
    {
      "id": "C.9.3",
      "name": "Management review",
      "description": "Top management shall review the organization's information security management system at planned intervals to ensure its continuing suitability, adequacy, and effectiveness. The management review shall include consideration of:\n- (a) the status of actions from previous management reviews;\n- (b) changes in external and internal issues that are relevant to the information security management system;\n- (c) feedback on the information security performance, including trends in: (1) nonconformities and corrective actions; (2) monitoring and measurement results; (3) audit results; and (4) fulfillment of information security objectives;\n- (d) feedback from interested parties;\n- (e) results of risk assessment and status of risk treatment plan; and\n- (f) opportunities for continual improvement.\n\nThe outputs of the management review shall include decisions related to continual improvement opportunities and any needs for changes to the information security management system.",
      "categories": ["Administrative"],
      "tests": [
        {
          "id": "approved-08-isms-procedure-for-management-review-exists"
        },
        {
          "id": "employees-accepted-08-isms-procedure-for-management-review"
        }
      ],
      "manualEvidenceRequests": [
        {
          "id": "isms-management-review"
        }
      ]
    }
  ],
  "sections": [
    {
      "id": "A.5.1.1",
      "controls": [
        {
          "id": "A.5.1.1"
        }
      ]
    },
    {
      "id": "A.5.1.2",
      "controls": [
        {
          "id": "A.5.1.2"
        }
      ]
    },
    {
      "id": "A.6.1.1",
      "controls": [
        {
          "id": "A.6.1.1"
        }
      ]
    },
    {
      "id": "A.6.1.2",
      "controls": [
        {
          "id": "A.6.1.2"
        }
      ]
    },
    {
      "id": "A.6.1.3",
      "controls": [
        {
          "id": "A.6.1.3"
        }
      ]
    },
    {
      "id": "A.6.1.4",
      "controls": [
        {
          "id": "A.6.1.4"
        }
      ]
    },
    {
      "id": "A.6.1.5",
      "controls": [
        {
          "id": "A.6.1.5"
        }
      ]
    },
    {
      "id": "A.6.2.1",
      "controls": [
        {
          "id": "A.6.2.1"
        }
      ]
    },
    {
      "id": "A.6.2.2",
      "controls": [
        {
          "id": "A.6.2.2"
        }
      ]
    },
    {
      "id": "A.7.1.1",
      "controls": [
        {
          "id": "A.7.1.1"
        }
      ]
    },
    {
      "id": "A.7.1.2",
      "controls": [
        {
          "id": "A.7.1.2"
        }
      ]
    },
    {
      "id": "A.7.2.1",
      "controls": [
        {
          "id": "A.7.2.1"
        }
      ]
    },
    {
      "id": "A.7.2.2",
      "controls": [
        {
          "id": "A.7.2.2"
        }
      ]
    },
    {
      "id": "A.7.2.3",
      "controls": [
        {
          "id": "A.7.2.3"
        }
      ]
    },
    {
      "id": "A.7.3.1",
      "controls": [
        {
          "id": "A.7.3.1"
        }
      ]
    },
    {
      "id": "A.8.1.1",
      "controls": [
        {
          "id": "A.8.1.1"
        }
      ]
    },
    {
      "id": "A.8.1.2",
      "controls": [
        {
          "id": "A.8.1.2"
        }
      ]
    },
    {
      "id": "A.8.1.3",
      "controls": [
        {
          "id": "A.8.1.3"
        }
      ]
    },
    {
      "id": "A.8.1.4",
      "controls": [
        {
          "id": "A.8.1.4"
        }
      ]
    },
    {
      "id": "A.8.2.1",
      "controls": [
        {
          "id": "A.8.2.1"
        }
      ]
    },
    {
      "id": "A.8.2.2",
      "controls": [
        {
          "id": "A.8.2.2"
        }
      ]
    },
    {
      "id": "A.8.2.3",
      "controls": [
        {
          "id": "A.8.2.3"
        }
      ]
    },
    {
      "id": "A.8.3.1",
      "controls": [
        {
          "id": "A.8.3.1"
        }
      ]
    },
    {
      "id": "A.8.3.2",
      "controls": [
        {
          "id": "A.8.3.2"
        }
      ]
    },
    {
      "id": "A.8.3.3",
      "controls": [
        {
          "id": "A.8.3.3"
        }
      ]
    },
    {
      "id": "A.9.1.1",
      "controls": [
        {
          "id": "A.9.1.1"
        }
      ]
    },
    {
      "id": "A.9.1.2",
      "controls": [
        {
          "id": "A.9.1.2"
        }
      ]
    },
    {
      "id": "A.9.2.1",
      "controls": [
        {
          "id": "A.9.2.1"
        }
      ]
    },
    {
      "id": "A.9.2.2",
      "controls": [
        {
          "id": "A.9.2.2"
        }
      ]
    },
    {
      "id": "A.9.2.3",
      "controls": [
        {
          "id": "A.9.2.3"
        }
      ]
    },
    {
      "id": "A.9.2.4",
      "controls": [
        {
          "id": "A.9.2.4"
        }
      ]
    },
    {
      "id": "A.9.2.5",
      "controls": [
        {
          "id": "A.9.2.5"
        }
      ]
    },
    {
      "id": "A.9.2.6",
      "controls": [
        {
          "id": "A.9.2.6"
        }
      ]
    },
    {
      "id": "A.9.3.1",
      "controls": [
        {
          "id": "A.9.3.1"
        }
      ]
    },
    {
      "id": "A.9.4.1",
      "controls": [
        {
          "id": "A.9.4.1"
        }
      ]
    },
    {
      "id": "A.9.4.2",
      "controls": [
        {
          "id": "A.9.4.2"
        }
      ]
    },
    {
      "id": "A.9.4.3",
      "controls": [
        {
          "id": "A.9.4.3"
        }
      ]
    },
    {
      "id": "A.9.4.4",
      "controls": [
        {
          "id": "A.9.4.4"
        }
      ]
    },
    {
      "id": "A.9.4.5",
      "controls": [
        {
          "id": "A.9.4.5"
        }
      ]
    },
    {
      "id": "A.10.1.1",
      "controls": [
        {
          "id": "A.10.1.1"
        }
      ]
    },
    {
      "id": "A.10.1.2",
      "controls": [
        {
          "id": "A.10.1.2"
        }
      ]
    },
    {
      "id": "A.11.1.1",
      "controls": [
        {
          "id": "A.11.1.1"
        }
      ]
    },
    {
      "id": "A.11.1.2",
      "controls": [
        {
          "id": "A.11.1.2"
        }
      ]
    },
    {
      "id": "A.11.1.3",
      "controls": [
        {
          "id": "A.11.1.3"
        }
      ]
    },
    {
      "id": "A.11.1.4",
      "controls": [
        {
          "id": "A.11.1.4"
        }
      ]
    },
    {
      "id": "A.11.1.5",
      "controls": [
        {
          "id": "A.11.1.5"
        }
      ]
    },
    {
      "id": "A.11.1.6",
      "controls": [
        {
          "id": "A.11.1.6"
        }
      ]
    },
    {
      "id": "A.11.2.1",
      "controls": [
        {
          "id": "A.11.2.1"
        }
      ]
    },
    {
      "id": "A.11.2.2",
      "controls": [
        {
          "id": "A.11.2.2"
        }
      ]
    },
    {
      "id": "A.11.2.3",
      "controls": [
        {
          "id": "A.11.2.3"
        }
      ]
    },
    {
      "id": "A.11.2.4",
      "controls": [
        {
          "id": "A.11.2.4"
        }
      ]
    },
    {
      "id": "A.11.2.5",
      "controls": [
        {
          "id": "A.11.2.5"
        }
      ]
    },
    {
      "id": "A.11.2.6",
      "controls": [
        {
          "id": "A.11.2.6"
        }
      ]
    },
    {
      "id": "A.11.2.7",
      "controls": [
        {
          "id": "A.11.2.7"
        }
      ]
    },
    {
      "id": "A.11.2.8",
      "controls": [
        {
          "id": "A.11.2.8"
        }
      ]
    },
    {
      "id": "A.11.2.9",
      "controls": [
        {
          "id": "A.11.2.9"
        }
      ]
    },
    {
      "id": "A.12.1.1",
      "controls": [
        {
          "id": "A.12.1.1"
        }
      ]
    },
    {
      "id": "A.12.1.2",
      "controls": [
        {
          "id": "A.12.1.2"
        }
      ]
    },
    {
      "id": "A.12.1.3",
      "controls": [
        {
          "id": "A.12.1.3"
        }
      ]
    },
    {
      "id": "A.12.1.4",
      "controls": [
        {
          "id": "A.12.1.4"
        }
      ]
    },
    {
      "id": "A.12.2.1",
      "controls": [
        {
          "id": "A.12.2.1"
        }
      ]
    },
    {
      "id": "A.12.3.1",
      "controls": [
        {
          "id": "A.12.3.1"
        }
      ]
    },
    {
      "id": "A.12.4.1",
      "controls": [
        {
          "id": "A.12.4.1"
        }
      ]
    },
    {
      "id": "A.12.4.2",
      "controls": [
        {
          "id": "A.12.4.2"
        }
      ]
    },
    {
      "id": "A.12.4.3",
      "controls": [
        {
          "id": "A.12.4.3"
        }
      ]
    },
    {
      "id": "A.12.4.4",
      "controls": [
        {
          "id": "A.12.4.4"
        }
      ]
    },
    {
      "id": "A.12.5.1",
      "controls": [
        {
          "id": "A.12.5.1"
        }
      ]
    },
    {
      "id": "A.12.6.1",
      "controls": [
        {
          "id": "A.12.6.1"
        }
      ]
    },
    {
      "id": "A.12.6.2",
      "controls": [
        {
          "id": "A.12.6.2"
        }
      ]
    },
    {
      "id": "A.12.7.1",
      "controls": [
        {
          "id": "A.12.7.1"
        }
      ]
    },
    {
      "id": "A.13.1.1",
      "controls": [
        {
          "id": "A.13.1.1"
        }
      ]
    },
    {
      "id": "A.13.1.2",
      "controls": [
        {
          "id": "A.13.1.2"
        }
      ]
    },
    {
      "id": "A.13.1.3",
      "controls": [
        {
          "id": "A.13.1.3"
        }
      ]
    },
    {
      "id": "A.13.2.1",
      "controls": [
        {
          "id": "A.13.2.1"
        }
      ]
    },
    {
      "id": "A.13.2.2",
      "controls": [
        {
          "id": "A.13.2.2"
        }
      ]
    },
    {
      "id": "A.13.2.3",
      "controls": [
        {
          "id": "A.13.2.3"
        }
      ]
    },
    {
      "id": "A.13.2.4",
      "controls": [
        {
          "id": "A.13.2.4"
        }
      ]
    },
    {
      "id": "A.14.1.1",
      "controls": [
        {
          "id": "A.14.1.1"
        }
      ]
    },
    {
      "id": "A.14.1.2",
      "controls": [
        {
          "id": "A.14.1.2"
        }
      ]
    },
    {
      "id": "A.14.1.3",
      "controls": [
        {
          "id": "A.14.1.3"
        }
      ]
    },
    {
      "id": "A.14.2.1",
      "controls": [
        {
          "id": "A.14.2.1"
        }
      ]
    },
    {
      "id": "A.14.2.2",
      "controls": [
        {
          "id": "A.14.2.2"
        }
      ]
    },
    {
      "id": "A.14.2.3",
      "controls": [
        {
          "id": "A.14.2.3"
        }
      ]
    },
    {
      "id": "A.14.2.4",
      "controls": [
        {
          "id": "A.14.2.4"
        }
      ]
    },
    {
      "id": "A.14.2.5",
      "controls": [
        {
          "id": "A.14.2.5"
        }
      ]
    },
    {
      "id": "A.14.2.6",
      "controls": [
        {
          "id": "A.14.2.6"
        }
      ]
    },
    {
      "id": "A.14.2.7",
      "controls": [
        {
          "id": "A.14.2.7"
        }
      ]
    },
    {
      "id": "A.14.2.8",
      "controls": [
        {
          "id": "A.14.2.8"
        }
      ]
    },
    {
      "id": "A.14.2.9",
      "controls": [
        {
          "id": "A.14.2.9"
        }
      ]
    },
    {
      "id": "A.14.3.1",
      "controls": [
        {
          "id": "A.14.3.1"
        }
      ]
    },
    {
      "id": "A.15.1.1",
      "controls": [
        {
          "id": "A.15.1.1"
        }
      ]
    },
    {
      "id": "A.15.1.2",
      "controls": [
        {
          "id": "A.15.1.2"
        }
      ]
    },
    {
      "id": "A.15.1.3",
      "controls": [
        {
          "id": "A.15.1.3"
        }
      ]
    },
    {
      "id": "A.15.2.1",
      "controls": [
        {
          "id": "A.15.2.1"
        }
      ]
    },
    {
      "id": "A.15.2.2",
      "controls": [
        {
          "id": "A.15.2.2"
        }
      ]
    },
    {
      "id": "A.16.1.1",
      "controls": [
        {
          "id": "A.16.1.1"
        }
      ]
    },
    {
      "id": "A.16.1.2",
      "controls": [
        {
          "id": "A.16.1.2"
        }
      ]
    },
    {
      "id": "A.16.1.3",
      "controls": [
        {
          "id": "A.16.1.3"
        }
      ]
    },
    {
      "id": "A.16.1.4",
      "controls": [
        {
          "id": "A.16.1.4"
        }
      ]
    },
    {
      "id": "A.16.1.5",
      "controls": [
        {
          "id": "A.16.1.5"
        }
      ]
    },
    {
      "id": "A.16.1.6",
      "controls": [
        {
          "id": "A.16.1.6"
        }
      ]
    },
    {
      "id": "A.16.1.7",
      "controls": [
        {
          "id": "A.16.1.7"
        }
      ]
    },
    {
      "id": "A.17.1.1",
      "controls": [
        {
          "id": "A.17.1.1"
        }
      ]
    },
    {
      "id": "A.17.1.2",
      "controls": [
        {
          "id": "A.17.1.2"
        }
      ]
    },
    {
      "id": "A.17.1.3",
      "controls": [
        {
          "id": "A.17.1.3"
        }
      ]
    },
    {
      "id": "A.17.2.1",
      "controls": [
        {
          "id": "A.17.2.1"
        }
      ]
    },
    {
      "id": "A.18.1.1",
      "controls": [
        {
          "id": "A.18.1.1"
        }
      ]
    },
    {
      "id": "A.18.1.2",
      "controls": [
        {
          "id": "A.18.1.2"
        }
      ]
    },
    {
      "id": "A.18.1.3",
      "controls": [
        {
          "id": "A.18.1.3"
        }
      ]
    },
    {
      "id": "A.18.1.4",
      "controls": [
        {
          "id": "A.18.1.4"
        }
      ]
    },
    {
      "id": "A.18.1.5",
      "controls": [
        {
          "id": "A.18.1.5"
        }
      ]
    },
    {
      "id": "A.18.2.1",
      "controls": [
        {
          "id": "A.18.2.1"
        }
      ]
    },
    {
      "id": "A.18.2.2",
      "controls": [
        {
          "id": "A.18.2.2"
        }
      ]
    },
    {
      "id": "A.18.2.3",
      "controls": [
        {
          "id": "A.18.2.3"
        }
      ]
    },
    {
      "id": "C.4.1",
      "controls": [
        {
          "id": "C.4.1"
        }
      ]
    },
    {
      "id": "C.4.2",
      "controls": [
        {
          "id": "C.4.2"
        }
      ]
    },
    {
      "id": "C.4.3",
      "controls": [
        {
          "id": "C.4.3"
        }
      ]
    },
    {
      "id": "C.4.4",
      "controls": [
        {
          "id": "C.4.4"
        }
      ]
    },
    {
      "id": "C.5.1",
      "controls": [
        {
          "id": "C.5.1"
        }
      ]
    },
    {
      "id": "C.5.2",
      "controls": [
        {
          "id": "C.5.2"
        }
      ]
    },
    {
      "id": "C.5.3",
      "controls": [
        {
          "id": "C.5.3"
        }
      ]
    },
    {
      "id": "C.6.1.1",
      "controls": [
        {
          "id": "C.6.1.1"
        }
      ]
    },
    {
      "id": "C.6.1.2",
      "controls": [
        {
          "id": "C.6.1.2"
        }
      ]
    },
    {
      "id": "C.6.1.3",
      "controls": [
        {
          "id": "C.6.1.3"
        }
      ]
    },
    {
      "id": "C.6.2",
      "controls": [
        {
          "id": "C.6.2"
        }
      ]
    },
    {
      "id": "C.7.1",
      "controls": [
        {
          "id": "C.7.1"
        }
      ]
    },
    {
      "id": "C.7.2",
      "controls": [
        {
          "id": "C.7.2"
        }
      ]
    },
    {
      "id": "C.7.3",
      "controls": [
        {
          "id": "C.7.3"
        }
      ]
    },
    {
      "id": "C.7.4",
      "controls": [
        {
          "id": "C.7.4"
        }
      ]
    },
    {
      "id": "C.7.5.1",
      "controls": [
        {
          "id": "C.7.5.1"
        }
      ]
    },
    {
      "id": "C.7.5.2",
      "controls": [
        {
          "id": "C.7.5.2"
        }
      ]
    },
    {
      "id": "C.7.5.3",
      "controls": [
        {
          "id": "C.7.5.3"
        }
      ]
    },
    {
      "id": "C.8.1",
      "controls": [
        {
          "id": "C.8.1"
        }
      ]
    },
    {
      "id": "C.8.2",
      "controls": [
        {
          "id": "C.8.2"
        }
      ]
    },
    {
      "id": "C.8.3",
      "controls": [
        {
          "id": "C.8.3"
        }
      ]
    },
    {
      "id": "C.9.1",
      "controls": [
        {
          "id": "C.9.1"
        }
      ]
    },
    {
      "id": "C.9.2",
      "controls": [
        {
          "id": "C.9.2"
        }
      ]
    },
    {
      "id": "C.9.3",
      "controls": [
        {
          "id": "C.9.3"
        }
      ]
    },
    {
      "id": "C.10.1",
      "controls": [
        {
          "id": "C.10.1"
        }
      ]
    },
    {
      "id": "C.10.2",
      "controls": [
        {
          "id": "C.10.2"
        }
      ]
    }
  ]
}
