import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { BASE_PALETTE } from "../../base/colors";
import { Button } from "../button/button";
import { Icon } from "../icon/icon";
import { IconNames } from "../icon/iconNames";

interface IProps {
  to: string;
}

export const BackButton: React.FC<IProps> = ({ to }) => {
  const history = useHistory();
  // To be replaced by the standard icon button when built
  return (
    <StyledButton
      style={{ height: 32, width: 32 }}
      onClick={() => history.push(to)}
      icon={<StyledIcon icon={IconNames.ARROW_LEFT} iconSize={24} />}
      minimal
    />
  );
};

const StyledIcon = styled(Icon)`
  position: relative;
  top: 4px;
  left: 3px;
  color: ${BASE_PALETTE.INK} !important;
  fill-opacity: 0.6;
  svg {
    fill: ${BASE_PALETTE.INK} !important;
    color: ${BASE_PALETTE.INK} !important;
  }
`;

const StyledButton = styled(Button)`
  &:hover {
    ${StyledIcon} {
      path {
        fill-opacity: 1;
      }
    }
  }
`;
