import type { TrainingCategoryAccess } from "common/schemas/betaFeatureAccess";
import { PCI_STANDARD_SET } from "common/standards/pci";
import { useContext } from "react";
import { ReportStandard } from "../../../../gen/components";
import { UserContext } from "../../user-context";

export function useTrainingCategoryAccess(): TrainingCategoryAccess {
  const { domainStandards } = useContext(UserContext);
  return {
    general: true, // The 'general' training is accessible to every customer
    hipaa: domainStandards.includes(ReportStandard.hipaa),
    pci: domainStandards.some(s => PCI_STANDARD_SET.has(s)),
    gdpr: domainStandards.includes(ReportStandard.gdpr),
  };
}
