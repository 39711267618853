import type { IRiskCategory } from "common/schemas/riskRegister/risk-register-content";
import React from "react";

import { StepNavMenu } from "../../master-detail-view/step-nav-menu";
import type { RiskAssessmentDomain } from "./queries-and-types";
import type { RiskFormController } from "./risk-form-controller";

interface IProps {
  category: IRiskCategory;
  formController: RiskFormController;
  domain: RiskAssessmentDomain;
}

export const RiskCategoryNavList: React.FC<IProps> = ({
  category,
  domain,
  formController,
}) => (
  <StepNavMenu
    steps={category.risks.map((risk, index) => {
      const isCurrentRisk = index === formController.currentStepIndex;
      const isDismissed = domain.riskDismissals.some(d => d.riskId === risk.id);
      const hasScenario = domain.rrv3RiskScenarios.some(
        s => s.riskCategoryId === risk.id
      );
      return {
        text: risk.shortHeading,
        substeps: isCurrentRisk
          ? [
              {
                text: "Assets",
              },
              {
                text: "Risks",
              },
            ]
          : null,
        completed: hasScenario || isDismissed,
      };
    })}
    clickHandler={(stepIndex, substepIndex) =>
      formController.navigateToLocation(stepIndex, substepIndex)
    }
    activeStepIndex={formController.currentStepIndex}
    activeSubstepIndex={
      formController.showAssetList
        ? 0
        : formController.showScenarioList
        ? 1
        : null
    }
  />
);
