import type { Service } from "common/base/types/helpers";
import { serviceToDisplayName } from "common/base/types/helpers";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import React, { useMemo } from "react";
import { useHistory } from "react-router";

import { DropdownButton } from "../components/dropdown-button";
import { FILTER_BUTTON_WIDTH } from "./access-components/styles";
import type { AccountsAccessCredential } from "./access-page";
import {
  accessPageCredentialToKey,
  getAWSAccountIdFromCredentialMetadata,
} from "./access-page-with-service";

interface IProps {
  credentials: AccountsAccessCredential[];
  defaultCredential?: Maybe<AccountsAccessCredential>;
  onSelect?(credential: AccountsAccessCredential): void;
}

export const ServiceDropdown: React.FC<IProps> = ({
  credentials,
  defaultCredential,
  onSelect,
}) => {
  const history = useHistory();
  const credentialKeyToCredentialMap = useMemo(
    () => new Map(credentials.map(c => [accessPageCredentialToKey(c), c])),
    [credentials]
  );
  const sortedCredentials = useMemo(
    () => credentials.sort((c1, c2) => c1.service.localeCompare(c2.service)),
    [credentials]
  );
  const urlCredentialKey = new URLSearchParams(location.search).get(
    "credentialKey"
  );
  const selectedCredential =
    isSome(urlCredentialKey) &&
    credentialKeyToCredentialMap.has(urlCredentialKey)
      ? credentialKeyToCredentialMap.get(urlCredentialKey)!
      : defaultCredential ?? credentials[0];

  return (
    <DropdownButton
      options={sortedCredentials}
      selectedOption={selectedCredential}
      onOptionSelect={credential => {
        if (isSome(credential)) {
          history.push(
            `?credentialKey=${accessPageCredentialToKey(credential)}`
          );
          onSelect?.(credential);
        }
      }}
      optionRenderer={credentialToDisplayName}
      buttonWidth={FILTER_BUTTON_WIDTH}
      menuWidth={FILTER_BUTTON_WIDTH}
      styleOnSelect
    />
  );
};

function credentialToDisplayName(credential: AccountsAccessCredential) {
  const serviceDisplayName = serviceToDisplayName(
    credential.service as Service
  );
  switch (credential.service) {
    case "aws":
      return `${serviceDisplayName} (${getAWSAccountIdFromCredentialMetadata(
        credential.metadata!
      )})`;
    default:
      return serviceDisplayName;
  }
}
