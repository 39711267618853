import "./vanta-report-control-list.scss";

import React from "react";

import type { GetDataForReportQuery } from "../../../../../gen/components";
import type { IDomainForReport } from "../../../helpers/helpers";
import { VantaReportControlListItem } from "./vanta-report-control-list-item";

interface IProps {
  domain: IDomainForReport;
  controls: NonNullable<
    GetDataForReportQuery["complianceStandard"]
  >["principles"][number]["requirements"][number]["controls"];
}

export class VantaReportControlList extends React.Component<IProps> {
  public render() {
    const { domain, controls } = this.props;
    return (
      <div className="vrp-control-list">
        {controls.map((control, index) => (
          <React.Fragment key={`control_${index}`}>
            <VantaReportControlListItem domain={domain} control={control} />
            <div className="vrp-control-list-separator" />
          </React.Fragment>
        ))}
      </div>
    );
  }
}
