// these are non-existent ids for a non existent role that are just to be used
// to identify vanta tasks in the db human-readably
export enum VantaTasks {
  ROLE_COMPLETION_ID = "vantaTasksRoleCompletionId",
  ROLE_ID = "vantaTasksRoleId",
}

export enum VantaTaskId {
  AGENT_INSTALLED_TASK_ID = "vantaAgentInstalledTaskId",
  BACKGROUND_CHECK_TASK_ID = "backgroundCheckTaskId",
  GDPR_SECURITY_TRAINING_TASK_ID = "gdprSecurityTrainingTaskId",
  HIPAA_SECURITY_TRAINING_TASK_ID = "hipaaSecurityTrainingTaskId",
  PCI_SECURITY_TRAINING_TASK_ID = "pciSecurityTrainingTaskId",
  POLICY_ACCEPTANCE_TASK_ID = "policyAcceptanceTaskId",
  SECURITY_TRAINING_TASK_ID = "securityTrainingTaskId",
}

export const BACKGROUND_CHECK_STATUSES = {
  IN_PROGRESS: "IN PROGRESS",
  COMPLETED: "COMPLETED",
} as const;

export const DEFAULT_COMPUTER_SETUP_SLA = 3;
