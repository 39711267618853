import type { IFormGroupProps } from "@blueprintjs/core";
import { FormGroup as BPFormGroup } from "@blueprintjs/core";
import classnames from "classnames";
import React from "react";
import styled from "styled-components";

import { FORM_GROUP_STYLES, FormClasses } from "./styles";

export const FormGroup: React.FC<IFormGroupProps> = ({
  children,
  ...props
}) => (
  <StyledBPFormGroup
    {...props}
    className={classnames(FormClasses.FORM_GROUP, props.className)}
  >
    {children}
  </StyledBPFormGroup>
);

const StyledBPFormGroup = styled(BPFormGroup)`
  ${FORM_GROUP_STYLES}
`;
