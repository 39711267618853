import type {
  ApolloQueryResult,
  FetchMoreOptions,
  FetchMoreQueryOptions,
} from "@apollo/client";
import type { FilterParams, SpecificResource } from "common/base/types/gen";
import { FilterOperator } from "common/base/types/gen";
import type { Maybe } from "common/base/types/maybe";
import type { ReservedVantaAttribute } from "common/utils/vantaAttributes";
import gql from "graphql-tag";

import { LogError } from "../../../errors";
import type {
  useSetInventoryOsqueryVantaAttributeMutation,
  useSetInventoryResourceVantaAttributeMutation,
  useSetOtherInventoryItemVantaAttributeMutation,
} from "../../../gen/components";
import {
  OsqueryVantaAttributesFragmentDoc,
  OtherInventoryItemVantaAttributesFragmentDoc,
  ResourceVantaAttributesFragmentDoc,
} from "../../../gen/components";

export const PAGE_SIZE = 20;

interface IResources {
  pageInfo: {
    hasNextPage: boolean;
    endCursor?: Maybe<string>;
  };
  edges: Maybe<any[]>;
}

interface IResourceQueryResult {
  organization: { resources: IResources };
}

/**
 * Call this function to fetch more resources in a query of the form
 * domain.resources that selects exactly one set of resources.
 *
 * @param data The data returned by the initial apollo useQuery hook
 * @param fetchMoreFn The fetchMore function returned by the apollo useQuery hook
 */
export const fetchMoreResources = async <T extends Maybe<IResourceQueryResult>>(
  data: T,
  fetchMoreFn: (
    options: FetchMoreOptions &
      FetchMoreQueryOptions<
        { after: Maybe<string>; first: number },
        "after" | "first"
      >
  ) => Promise<ApolloQueryResult<T>>,
  pageSize: number = PAGE_SIZE
) =>
  fetchMoreFn({
    variables: {
      after: data?.organization.resources.pageInfo.endCursor,
      first: pageSize,
    },
  });

/**
 * Returns a wrapper function around the mutation to set an OSQuery's
 * Vanta Attributes with the osqueryMongoId statically set.
 */
export function setOsqueryVantaAttributeFn(
  setOsqueryVantaAttributeMutation: ReturnType<
    typeof useSetInventoryOsqueryVantaAttributeMutation
  >[0],
  osqueryMongoId: string
): (key: ReservedVantaAttribute, value: Maybe<string>) => Promise<void> {
  const setVantaAttribute = async (key: string, value: Maybe<string>) => {
    const result = await setOsqueryVantaAttributeMutation({
      variables: {
        osqueryMongoId,
        key,
        value,
      },
      update: (dataProxy, mutationResult) => {
        const newAttributes = mutationResult.data?.setOsqueryVantaAttribute;
        const id = `osquery:${osqueryMongoId}`;
        const newData = {
          __typename: "osquery",
          id: osqueryMongoId,
          vantaAttributes: newAttributes,
        };
        dataProxy.writeFragment({
          fragment: OsqueryVantaAttributesFragmentDoc,
          id,
          data: newData,
        });
      },
    });
    if (result.errors) {
      LogError(new Error(JSON.stringify(result)));
    }
  };
  return setVantaAttribute;
}

/**
 * Returns a wrapper function around the mutation to set an
 * OtherInventoryItem's Vanta Attributes with the mongoId
 * statically set.
 */
export function setOtherInventoryItemVantaAttributeFn(
  setOtherInventoryItemVantaAttributeMutation: ReturnType<
    typeof useSetOtherInventoryItemVantaAttributeMutation
  >[0],
  itemMongoId: string
): (key: ReservedVantaAttribute, value: Maybe<string>) => Promise<void> {
  const setVantaAttribute = async (key: string, value: Maybe<string>) => {
    const result = await setOtherInventoryItemVantaAttributeMutation({
      variables: {
        itemMongoId,
        key,
        value,
      },
      update: (dataProxy, mutationResult) => {
        const newAttributes =
          mutationResult.data?.setOtherInventoryItemVantaAttribute;
        const id = `otherInventoryItem:${itemMongoId}`;
        const newData = {
          __typename: "otherInventoryItem",
          id: itemMongoId,
          vantaAttributes: newAttributes,
        };
        dataProxy.writeFragment({
          fragment: OtherInventoryItemVantaAttributesFragmentDoc,
          id,
          data: newData,
        });
      },
    });
    if (result.errors) {
      LogError(new Error(JSON.stringify(result)));
    }
  };
  return setVantaAttribute;
}

/**
 * Returns a wrapper function around the mutation to set a resource's
 * Vanta Attributes with the specificResourceKind and resourceMongoId
 * statically set.
 */
export function setResourceVantaAttributeFn(
  setResourceVantaAttributeMutation: ReturnType<
    typeof useSetInventoryResourceVantaAttributeMutation
  >[0],
  specificResourceKind: SpecificResource,
  resourceMongoId: string,
  graphqlType: string
): (key: ReservedVantaAttribute, value: Maybe<string>) => Promise<void> {
  const setVantaAttribute = async (key: string, value: Maybe<string>) => {
    const result = await setResourceVantaAttributeMutation({
      variables: {
        specificResourceKind,
        resourceMongoId,
        key,
        value,
      },
      update: (dataProxy, mutationResult) => {
        const newAttributes = mutationResult.data?.setResourceVantaAttribute;
        const id = `${graphqlType}:${resourceMongoId}`;
        const newData = {
          __typename: graphqlType,
          id: resourceMongoId,
          vantaAttributes: newAttributes,
        };
        dataProxy.writeFragment({
          fragment: ResourceVantaAttributesFragmentDoc,
          id,
          data: newData,
        });
      },
    });
    if (result.errors) {
      LogError(new Error(JSON.stringify(result)));
    }
  };
  return setVantaAttribute;
}

export function invalidInventoryType(typename: string) {
  LogError(
    new Error(`Unknown type when rendering inventory list: ${typename}`),
    false
  );
  return null;
}

/**
 * Get filter parameters for a query to domain.resource
 * which matches common search fields on a resource type.
 */
export function getResourceFilterParams(
  searchString: Maybe<string>
): Maybe<FilterParams> {
  const trimmedString = searchString?.trim() ?? "";
  if (trimmedString === "") {
    return null;
  } else {
    return {
      filters: [
        { condition: { STRING_INCLUDES: searchString }, field: "name" },
        { condition: { STRING_INCLUDES: searchString }, field: "uniqueId" },
        { condition: { STRING_INCLUDES: searchString }, field: "displayName" },
        { condition: { STRING_INCLUDES: searchString }, field: "email" },
      ],
      operator: FilterOperator.OR,
    };
  }
}

export function getDebounceContext(key: string) {
  return { debounceKey: `inventory-${key}`, debounceTimeout: 500 };
}

gql`
  fragment osqueryVantaAttributes on osquery {
    id
    vantaAttributes {
      key
      value
      managedExternally
    }
  }
`;

gql`
  fragment otherInventoryItemVantaAttributes on otherInventoryItem {
    id
    vantaAttributes {
      key
      value
      managedExternally
    }
  }
`;

gql`
  fragment resourceVantaAttributes on resource {
    id
    vantaAttributes {
      key
      value
      managedExternally
    }
  }
`;
