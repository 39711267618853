import { Callout, Intent } from "@blueprintjs/core";
import React from "react";
import styled from "styled-components";

export const TestVAQBanner: React.FC = () => (
  <Container>
    <Callout intent={Intent.WARNING}>
      This is a test questionnaire. Submitted data will be disregarded.
    </Callout>
  </Container>
);

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 10;
  background: #fff;
`;
