import "./LoggedOutHomepage.scss";

import { datadogRum } from "@datadog/browser-rum";
import {
  IDENTITY_PROVIDER_SERVICES_SET,
  INFRA_SERVICES,
} from "common/base/types/helpers";
import type { Maybe } from "common/base/types/maybe";
import { dropNothing, isSome } from "common/base/types/maybe";
import React, { useContext, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { identify } from "../../analytics";
import type { UserContextQuery } from "../../gen/components";
import { Feature, PermissionLevel } from "../../gen/components";
import { FeatureContext } from "../../helpers/feature-gating/context";
import { useFeatureCheck } from "../../helpers/feature-gating/feature-check";
import { GatedRoute } from "../../helpers/feature-gating/gated-route";
import { SentryBreadcrumbs } from "../../sentry/breadcrumbs";
import { FullPageSpinner } from "../helpers/FullPageSpinner";
import { ReportsPage } from "../reports/reports-page";
import { PATHS } from "../vanta-chrome/paths";
import { VantaChrome } from "../vanta-chrome/vanta-chrome";
import { AccessPage } from "./access/access-page";
import { AuditSchedulerPage } from "./audit-scheduler/audit-scheduler-page";
import { AuditorEngagementsPage } from "./auditor-engagements/auditor-engagements-page";
import { AuditorHomepage } from "./auditor-homepage";
import { ManageAlarmsPage } from "./auditor-view/manage-alarm";
import { ManageDBSecurityPage } from "./auditor-view/manage-db-security";
import { ManageNetworkPage } from "./auditor-view/manage-network";
import { ManageSubnetSecurityPage } from "./auditor-view/manage-subnets";
import { ChangesByMonth } from "./changes/changes-by-month";
import { CHECKLIST_PAGE_ROOT_PATH } from "./checklists/gql-and-types";
import { ComputersPage } from "./computers/computers-page";
import { ConnectToGovCloudPage } from "./credential/aws/connect-to-govcloud";
import { DocsInventoryList } from "./docs/inventory-list";
import { EmailSubscriptionPage } from "./email-subscription-settings/email-subscription";
import { InventoryListPage } from "./inventory-list/inventory-list";
import { Lockout } from "./lockout";
import { ManageBillingPage } from "./manage-billing";
import { ManageCredentialsPage } from "./manage-credentials";
import { NotFound404 } from "./notfound404";
import { ManageNotificationsPage } from "./notifications/manage-notifications-page";
import { PeoplePage } from "./people/people-page";
import { BetaPoliciesWizardPage } from "./policies/beta-policies-wizard-page";
import { PoliciesPage } from "./policies/policies-page";
import { ProfilePage } from "./profile-pages/profile-page";
import { ProfilePageType } from "./profile-pages/types";
import { RiskRegisterHome } from "./risk-register-beta/risk-register-home";
import { RiskRegisterV3Home } from "./risk-register-v3/risk-register-home";
import { RiskReport as NewRiskReport } from "./risk-report/risk-report";
import { RiskReport as RiskAssessmentReport } from "./risk-report/v3/risk-report";
import { ManageRisksPage } from "./riskregister/manage-risks";
import { ManageQuestionPage } from "./riskregister/question";
import { RiskReportPage } from "./riskregister/report";
import { UserContext } from "./user-context";
import { VantaUsersPage } from "./vanta-users/vanta-users-page";
import { ManageVendorsPage } from "./vendors/manage-vendors";
import { ReadOnlyVAQPage } from "./vendors/read-only-vaq-page";
import { AgentInstallInstructions } from "./vulns/agent/agent-install-instructions";
import { VulnsPage } from "./vulns/vulns-page";

const LazyComponents = {
  ChecklistsPage: React.lazy(
    async () => import("./checklists/checklists-page")
  ),
  StandardControlDetailPage: React.lazy(
    async () => import("./standards/standard-control-detail-page")
  ),
  StandardControlListPage: React.lazy(
    async () => import("./standards/standard-control-list-page")
  ),
  StandardsPage: React.lazy(async () => import("./standards/standards-page")),
  TestsPage: React.lazy(async () => import("./tests/tests-page")),
};

const LEGACY_URLS_WITH_REDIRECT: Array<{
  oldUrl: string;
  redirectUrl: string | ((url: string) => string);
  /**
   * Defaults to true
   */
  exact?: Maybe<boolean>;
}> = [
  {
    oldUrl: "/credentials",
    redirectUrl: "/connections",
  },
  {
    oldUrl: "/personnel",
    redirectUrl: url => url.replace("/personnel", "/people"),
  },
  {
    oldUrl: "/laptops",
    redirectUrl: "/computers",
  },
  {
    oldUrl: "/onboardingroles",
    redirectUrl: url =>
      url.replace("/onboardingroles", CHECKLIST_PAGE_ROOT_PATH),
    exact: false,
  },
  {
    oldUrl: "/riskRegister",
    redirectUrl: "/risk-register",
  },
  {
    oldUrl: "/riskRegister/report",
    redirectUrl: "/risk-report",
  },
  {
    oldUrl: "/riskRegister/question/:question",
    redirectUrl: url =>
      url.replace("/riskRegister/question", "/risk-register/question"),
  },
  {
    oldUrl: "/settings/business",
    redirectUrl: "/business-information",
  },
  { oldUrl: "/soc2tests", redirectUrl: PATHS.STANDARDS },
  { oldUrl: "/monitoring", redirectUrl: "/activity" },
];

interface IProps {
  user: NonNullable<UserContextQuery["user"]>;
}

const Component: React.FC<IProps> = props => {
  const hasRRV2 = useFeatureCheck(Feature.BetaNewRiskRegister);
  const hasRRV3 = useFeatureCheck(Feature.RiskRegisterV3);
  const hasAuditorJourneyAuditor = useFeatureCheck(Feature.AuditJourneyAuditor);

  const user = props.user;
  if (user.auditorInfo) {
    return wrapInVantaChrome(
      hasAuditorJourneyAuditor ? (
        <AuditorEngagementsPage />
      ) : (
        <AuditorHomepage />
      )
    );
  }

  const domain = props.user.domain;

  const riskRegisterRoutes = hasRRV3
    ? [
        <Route
          key="rr-v3-base"
          exact={true}
          path={`${PATHS.RISK_ASSESSMENT}/:category?/:stepNumber?/:substepNumber?`}
          component={RiskRegisterV3Home}
        />,
        <Route
          key="rr-v3-report"
          component={RiskAssessmentReport}
          exact={true}
          path="/risk-report"
        />,
      ]
    : hasRRV2
    ? [
        <Route
          key="new-rr-base"
          exact={true}
          path={`${PATHS.RISK_REGISTER}/:category?`}
          component={RiskRegisterHome}
        />,
        <Route
          key="new-rr-report"
          component={NewRiskReport}
          exact={true}
          path="/risk-report"
        />,
      ]
    : [
        <Route
          key="rr-base"
          exact={true}
          path={PATHS.RISK_REGISTER}
          component={ManageRisksPage}
        />,
        <Route
          key="rr-question"
          exact={true}
          path={`${PATHS.RISK_REGISTER}/question/:question`}
          component={ManageQuestionPage}
        />,
        <Route
          key="rr-report"
          component={RiskReportPage}
          exact={true}
          path="/risk-report"
        />,
      ];

  // locked domains only get to see billing page
  if (isSome(domain.lockoutDate)) {
    const ALLOWED_URLS = ["/billing", "/logout"];
    if (!isSome(ALLOWED_URLS.find(u => u === location.pathname))) {
      return wrapInVantaChrome(
        <Lockout
          domainName={domain.displayName}
          canTakeAction={user.permissionLevel !== PermissionLevel.Onboarding}
        />
      );
    }
  }

  // onboarding/downloads only
  if (user.permissionLevel === PermissionLevel.Onboarding) {
    const ALLOWED_URLS = [
      "/onboarding",
      "/employee/onboarding",
      "/downloads",
      "/download",
    ];
    if (!isSome(ALLOWED_URLS.find(u => u === location.pathname))) {
      return <Redirect to="/employee/onboarding" />;
    }
  }

  // show the user the credentials page if they haven't linked at least two of (idp, infra) services
  const NUM_NECESSARY_CREDENTIALS = 2;
  const indexRedirect =
    !isSome(domain.credentials) ||
    dropNothing(domain.credentials).filter(
      cr =>
        IDENTITY_PROVIDER_SERVICES_SET.has(cr.service) ||
        INFRA_SERVICES.includes(cr.service as typeof INFRA_SERVICES[number])
    ).length < NUM_NECESSARY_CREDENTIALS
      ? "/connections"
      : "/activity";

  return wrapInVantaChrome(
    <React.Suspense fallback={<FullPageSpinner />}>
      <React.Fragment>
        <Switch>
          <Route exact={true} path="/changes" component={ChangesByMonth} />
          <Route exact={true} path="/access" component={AccessPage} />
          <Route
            exact={true}
            path="/activity"
            component={LazyComponents.TestsPage}
          />
          <Route exact={true} path="/inventory" component={InventoryListPage} />
          <Route
            exact={true}
            path="/connections"
            component={ManageCredentialsPage}
          />
          <Route path="/docs/inventory-list" component={DocsInventoryList} />
          <Route exact={true} path="/billing" component={ManageBillingPage} />
          <GatedRoute
            exact={true}
            feature={Feature.LegacyEnableServerAgent}
            path="/vulnerabilities/install"
            component={AgentInstallInstructions}
          />
          <Route exact={false} path="/vulnerabilities" component={VulnsPage} />
          <Route exact={true} path="/vendors" component={ManageVendorsPage} />
          <Route
            exact={true}
            path="/vendors/assessment/:id"
            component={ReadOnlyVAQPage}
          />
          <Route path="/computers" component={ComputersPage} />
          <Route
            path={PATHS.STANDARDS}
            component={LazyComponents.StandardsPage}
            exact={true}
          />
          <Route
            path={`${PATHS.STANDARDS}/:standardId/:tabId?`}
            component={LazyComponents.StandardControlListPage}
            exact={true}
          />
          <Route
            path={`${PATHS.STANDARDS}/:standardId/controls/:controlId?`}
            component={LazyComponents.StandardControlDetailPage}
            exact={false}
          />
          <Route path="/alarms" component={ManageAlarmsPage} />
          <Route path="/databases" component={ManageDBSecurityPage} />
          <Route path="/subnets" component={ManageSubnetSecurityPage} />
          <Route path="/network" component={ManageNetworkPage} />
          <Route path="/policies" component={PoliciesPage} exact={true} />
          <Route
            path="/policies/:policyType"
            component={BetaPoliciesWizardPage}
            exact={true}
          />
          <Route path="/reports" component={ReportsPage} />
          {riskRegisterRoutes}
          <Route
            path={CHECKLIST_PAGE_ROOT_PATH}
            component={LazyComponents.ChecklistsPage}
          />
          ,
          <Route path="/people" exact={true} component={PeoplePage} />
          <Route
            exact={true}
            path="/business-information"
            component={() => <ProfilePage page={ProfilePageType.INFORMATION} />}
          />
          <Route
            exact={true}
            path="/business-engineering"
            component={() => <ProfilePage page={ProfilePageType.ENGINEERING} />}
          />
          <Route exact={true} path="/users" component={VantaUsersPage} />
          <Route exact path="/audit-schedule" component={AuditSchedulerPage} />
          <Route path="/emailSettings" component={EmailSubscriptionPage} />
          <GatedRoute
            exact
            path="/company-notifications"
            component={ManageNotificationsPage}
            feature={Feature.AutoEmployeeEmails}
          />
          <Route
            exact={true}
            path="/logout"
            render={() => {
              window.location.href = "/logout";
              return null;
            }}
          />
          <Route
            exact={true}
            path="/connectgovcloud"
            component={ConnectToGovCloudPage}
          />
          {LEGACY_URLS_WITH_REDIRECT.map(({ oldUrl, redirectUrl, exact }) => (
            <Route
              key={oldUrl}
              exact={exact ?? true}
              path={oldUrl}
              component={() => (
                <Redirect
                  to={
                    typeof redirectUrl === "function"
                      ? redirectUrl(
                          `${location.pathname}${location.search}${location.hash}`
                        )
                      : redirectUrl
                  }
                />
              )}
            />
          ))}
          <Route
            exact={true}
            path="/"
            component={() => (
              <Redirect
                to={
                  user.isAuditorAssumingUser ? PATHS.STANDARDS : indexRedirect
                }
              />
            )}
          />
          <Route path="*" component={NotFound404} />
        </Switch>
      </React.Fragment>
    </React.Suspense>
  );

  function wrapInVantaChrome(elem: JSX.Element) {
    return <VantaChrome>{elem}</VantaChrome>;
  }
};

export const Home: React.FC = () => {
  const path = window.location.pathname;
  if (path.startsWith("/manage/")) {
    return <Redirect to={path.replace(/\/manage\//, "/")} />;
  } else {
    return <HomeInternal />;
  }
};

const HomeInternal: React.FC = () => {
  const { user, error } = useContext(UserContext);

  useEffect(() => {
    if (
      !isSome(user) ||
      user.isAssumedUser ||
      user.isAssumedSuperUser ||
      user.isAuditorAssumingUser
    ) {
      return;
    }
    const userId = user.id;
    const userEmail = user.email;
    const domainId = user.domain.id;
    const domainName = user.domain.name;
    const userType = isSome(user.auditorInfo)
      ? "auditor"
      : user.permissionLevel === PermissionLevel.Admin ||
        user.permissionLevel === PermissionLevel.Dashboard
      ? "customer"
      : "employee";
    const betaFeatures = user.domain.betaFeatures ?? [];
    identify(userId, userEmail, domainId, domainName, userType, betaFeatures);
  }, [user]);

  if (error) {
    return null;
  }
  if (!user) {
    // preserve the URL we came from
    return (
      <Redirect
        to={`/login?continue=${encodeURIComponent(window.location.href)}`}
      />
    );
  }
  SentryBreadcrumbs.dashboardVisit({
    userId: user.id,
    domainId: user.domain.id,
  });

  datadogRum.addRumGlobalContext("userId", user.id);
  datadogRum.addRumGlobalContext("domainId", user.domain.id);

  return (
    <FeatureContext.Provider
      value={{
        betaFeatures: user.domain.betaFeatures,
      }}
    >
      <Component user={user} />
    </FeatureContext.Provider>
  );
};
