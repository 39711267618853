import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import React from "react";

import { HeaderRow } from "../../../alpaca/components";
import { Button } from "./button";
import type { IHeadingInfo } from "./info";

export interface IButtonProps {
  text: string;
  onClick(event: React.MouseEvent): void;
}

interface IProps {
  headingInfo: IHeadingInfo;
  buttonProps?: Maybe<IButtonProps>;
  backLink?: Maybe<string>;
}

/**
 * @deprecated Use HeaderRow directly instead
 */
export const PageHeading: React.FC<IProps> = ({
  headingInfo: { title, description },
  buttonProps,
  backLink,
}) => {
  const maybeButton = isSome(buttonProps)
    ? [
        <Button key="button" onClick={buttonProps.onClick}>
          {buttonProps.text}
        </Button>,
      ]
    : null;
  return (
    <HeaderRow
      backLink={backLink}
      title={title}
      rightControls={maybeButton}
      description={description}
    />
  );
};
