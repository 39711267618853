import type { IHTMLSelectProps } from "@blueprintjs/core";
import { HTMLSelect as BPHTMLSelect } from "@blueprintjs/core";
import type { Maybe } from "common/base/types/maybe";
import { isSome } from "common/base/types/maybe";
import React from "react";

interface IProps {
  choices?: Maybe<Array<string | { id: string; label: string }>>;
}

export class HTMLSelect extends React.PureComponent<IHTMLSelectProps & IProps> {
  public render() {
    return (
      <BPHTMLSelect {...this.props}>
        <option value="internal-unselected">Choose</option>
        {isSome(this.props.choices)
          ? this.props.choices.map((choice, i) => (
              <option
                key={i}
                value={typeof choice === "string" ? choice : choice.id}
              >
                {typeof choice === "string" ? choice : choice.label}
              </option>
            ))
          : null}
      </BPHTMLSelect>
    );
  }
}
