import type { MIME_TYPES } from "common/base/types/helpers";
import { mimeTypesToAcceptProp } from "common/base/types/helpers";
import type { Maybe } from "common/base/types/maybe";
import React, { useRef } from "react";

import { Button, Icon, IconNames } from "../../../alpaca/components";
import { fileUploadHandler } from "./file-input";

interface IProps {
  allowedMimeTypes: MIME_TYPES[];
  onFileUploaded: (file: File) => void;
  buttonText?: Maybe<string>;
}

export const FileUploadButton: React.FC<IProps> = ({
  allowedMimeTypes,
  onFileUploaded,
  buttonText,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  return (
    <>
      <input
        onChange={event => {
          fileUploadHandler(event, allowedMimeTypes, onFileUploaded);
        }}
        accept={mimeTypesToAcceptProp(allowedMimeTypes)}
        ref={fileInputRef}
        type="file"
        hidden
      />
      <Button
        icon={<Icon icon={IconNames.UPLOAD} />}
        onClick={() => fileInputRef!.current!.click()}
      >
        {buttonText ?? "Upload"}
      </Button>
    </>
  );
};
