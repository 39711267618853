import { Callout } from "@blueprintjs/core";
import React from "react";
import styled from "styled-components";

import { BASE_TYPOGRAPHY } from "../../alpaca/base/typography";
import { CopyClipboardIcon } from "./CopyClipboardIcon";

interface IProps {
  text: string;
}

export const CalloutWithClipBoard: React.FC<IProps> = ({ text }) => (
  <StyledCallout>
    <div>{text}</div>
    <CopyClipboardIcon text={text} />
  </StyledCallout>
);

const StyledCallout = styled(Callout)`
  font-family: ${BASE_TYPOGRAPHY.FONT_FAMILIES.MONACO};
  font-size: 0.9em;
  line-height: 1.3em;
  padding: 11px;
  padding-left: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
